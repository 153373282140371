"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.search");
require("core-js/modules/es6.number.constructor");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _default2 = exports.default = {
  name: 'InviteList',
  components: {
    Pagination: _Pagination.default
  },
  filters: {
    statusFilter: function statusFilter(status) {
      var statusMap = {
        success: 'success',
        pending: 'warning',
        cancelled: 'danger'
      };
      return statusMap[status];
    },
    orderNoFilter: function orderNoFilter(str) {
      return str.substring(0, 30);
    }
  },
  props: {
    invites: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    total: {
      type: Number,
      default: 1
    }
  },
  data: function data() {
    return {
      search: '',
      listQuery: {
        page: 1,
        limit: 30
      }
    };
  },
  computed: {
    filteredInvites: function filteredInvites() {
      var self = this;
      return this.invites.filter(function (invite) {
        return invite.email.toLowerCase().indexOf(self.search.toLowerCase()) > -1 || invite.first_name.toLowerCase().indexOf(self.search.toLowerCase()) > -1 || invite.last_name.toLowerCase().indexOf(self.search.toLowerCase()) > -1 || invite.department.toLowerCase().indexOf(self.search.toLowerCase()) > -1;
      });
    }
  },
  created: function created() {},
  methods: {
    isNotEmpty: function isNotEmpty(id) {
      if (typeof id !== 'undefined') {
        return true;
      }
      return false;
    },
    createNewInvite: function createNewInvite() {
      this.$emit('new');
    },
    inviteUser: function inviteUser(index, row) {
      this.$emit('invite', index);
    },
    resendInvite: function resendInvite(index, row) {
      this.$emit('resend', index);
    },
    copyInvite: function copyInvite(index, row, event) {
      this.$emit('copy', index, event);
    },
    deleteInvite: function deleteInvite(index, row) {
      this.$emit('delete', index);
    },
    getInviteList: function getInviteList(pagination) {
      var temp_pagination = Object.assign({}, pagination);
      var page_no = parseInt(temp_pagination.page) - 1;
      this.$emit('paginate', page_no);
    }
  }
};