"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _default2 = exports.default = {
  name: 'TouchpointPopover',
  components: {},
  props: {
    x: {
      type: Number,
      default: 0
    },
    y: {
      type: Number,
      default: 0
    },
    width: {
      type: Number,
      default: 0
    },
    touchpointIndex: {
      type: Number,
      default: 0
    },
    phaseIndex: {
      type: Number,
      default: 0
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    journeyMap: {
      type: Object,
      required: true,
      default: function _default() {
        return {
          id: '',
          title: '',
          description: '',
          additionalattributes: {},
          linkedJourneyExperiences: [],
          linkedJourneyPhases: [],
          linkedJourneySteps: []
        };
      }
    },
    touchpoint: {
      type: Object,
      default: function _default() {
        return {
          title: '',
          description: '',
          type: ''
        };
      }
    }
  },
  data: function data() {
    return {
      isHover: false
    };
  },
  computed: {},
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    generateMarker: function generateMarker() {
      var x_start = this.x + 38;
      var y_start = this.y + 8;
      var x_mid = this.x + 50;
      var y_mid = this.y + 3;
      var x_last = this.x + 50;
      var y_last = this.y + 13;
      return 'M ' + x_start + ' ' + y_start + ' L ' + x_mid + ' ' + y_mid + ' L ' + x_last + ' ' + y_last + '';
    },
    closeTouchpointDetailsPopover: function closeTouchpointDetailsPopover() {
      this.ishover = false;
      this.$emit('close');
    },
    selectTouchpoint: function selectTouchpoint() {
      this.$emit('click', this.phaseIndex, this.touchpoint.index);
    },
    deleteTouchpoint: function deleteTouchpoint() {
      var _this = this;
      this.$confirm('This will permanently delete touchpoint. Continue?', 'Warning', {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(function () {
        _this.ishover = false;

        // Temp Patch - Event not triggered in parent. So handling in component itself.
        _this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[_this.phaseIndex].touchpoints.splice(_this.touchpointIndex, 1);
        _this.$store.dispatch('customerTypes/updateJourneyMap', _this.journeyMap);
        // Send a global event to update customer type of journeymap change
        _this.$eventHub.$emit('touchpoint-deleted', _this.journeyMap);

        // Temp patch end
        _this.$emit('delete-touchpoint', _this.touchpoint.title);
      }).catch(function (e) {
        console.log(e);
        _this.$message({
          type: 'info',
          message: 'Delete canceled'
        });
      });
    },
    emitOnMouseOver: function emitOnMouseOver() {
      this.$emit('mouseover');
    },
    emitOnMouseLeave: function emitOnMouseLeave() {
      this.$emit('mouseleave');
    },
    truncateString: function truncateString(string, length) {
      return this._.truncate(string, {
        'length': length,
        'separator': '...'
      });
    }
  }
};