"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    style: {
      height: _vm.height + "px",
      zIndex: _vm.zIndex
    }
  }, [_c("div", {
    class: _vm.className,
    style: {
      top: _vm.isSticky ? _vm.stickyTop + "px" : "",
      zIndex: _vm.zIndex,
      position: _vm.position,
      width: _vm.width,
      height: _vm.height + "px"
    }
  }, [_vm._t("default", function () {
    return [_c("div", [_vm._v("sticky")])];
  })], 2)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;