"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-card", {
    staticClass: "mt-10"
  }, [_c("div", {
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("span", {
    staticStyle: {
      "font-weight": "bold"
    }
  }, [_vm._v("Manage an evolving problem and solution")])]), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 4
    }
  }, [_c("h4", {
    staticClass: "sub-title-rounded"
  }, [_vm._v("Problem Revisions\n          "), _c("el-tooltip", {
    attrs: {
      "popper-class": "el-tooltip",
      effect: "dark",
      content: "All historical problem statements are shown here. Click through each one to see how the problem space evolved",
      placement: "right"
    }
  }, [_vm._v("\n            >\n            "), _c("i", {
    staticClass: "el-icon-info fr"
  })])], 1), _vm._v(" "), _c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.problem
    },
    on: {
      "row-click": _vm.selectRefinement
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "version",
      label: "#",
      width: "20"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "refinement",
      label: "Refinement"
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 20
    }
  }, [_vm.selected_refinement.is_valid ? _c("div", {
    staticClass: "ml-5"
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 14
    }
  }, [_c("h4", {
    staticClass: "sub-title-rounded",
    staticStyle: {
      "background-color": "aliceblue",
      color: "navy"
    }
  }, [_vm._v("Current Problem Statement")]), _vm._v(" "), _c("p", {
    staticClass: "ml-5"
  }, [_vm._v(_vm._s(_vm.selected_refinement.description))]), _vm._v(" "), _c("h4", {
    staticClass: "sub-title-rounded",
    staticStyle: {
      "background-color": "aliceblue",
      color: "navy"
    }
  }, [_vm._v("Current Solution")]), _vm._v(" "), _c("p", {
    staticClass: "ml-5"
  }, [_vm._v(_vm._s(_vm.selected_refinement.solution))])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("div", {
    staticClass: "text-right"
  }, [_c("el-button", {
    staticClass: "mt-20 fl",
    attrs: {
      icon: "el-icon-edit",
      size: "mini",
      type: "secondary"
    },
    on: {
      click: function click($event) {
        _vm.refineProblemDialog = true;
      }
    }
  }, [_vm._v("\n                  Refine Problem Hypothesis\n                ")])], 1)])], 1)], 1) : _c("div", {
    staticClass: "ml-5"
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 14
    }
  }, [_c("h4", {
    staticClass: "sub-title-rounded",
    staticStyle: {
      "background-color": "navajowhite",
      color: "darkslategrey"
    }
  }, [_vm._v("Problem Statement")]), _vm._v(" "), _c("p", {
    staticClass: "ml-5"
  }, [_vm._v(_vm._s(_vm.selected_refinement.description))]), _vm._v(" "), _c("h5", {
    staticClass: "sub-title-rounded",
    staticStyle: {
      "background-color": "navajowhite",
      color: "darkslategrey"
    }
  }, [_vm._v("Solution")]), _vm._v(" "), _c("p", {
    staticClass: "ml-5"
  }, [_vm._v(_vm._s(_vm.selected_refinement.solution))])]), _vm._v(" "), _c("el-col", {
    staticClass: "pt-5",
    attrs: {
      span: 8
    }
  }, [_c("h4", {
    staticClass: "mt-20"
  }, [_vm._v(" Revision Details")]), _vm._v(" "), _c("el-row", {
    staticClass: "mt-10"
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_vm._v("\n                  Revised by:\n                ")]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 14
    }
  }, [_vm._v("\n                  " + _vm._s(_vm.selected_refinement.created_by.first_name) + " " + _vm._s(_vm.selected_refinement.created_by.last_name) + "  @ " + _vm._s(_vm.selected_refinement.created_at) + "\n                ")])], 1), _vm._v(" "), _c("el-row", {
    staticClass: "mt-10"
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_vm._v("\n                  Process\n                ")]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 14
    }
  }, [_vm._v("\n                  " + _vm._s(_vm.selected_refinement.process) + "\n                ")])], 1), _vm._v(" "), _c("el-row", {
    staticClass: "mt-10"
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_vm._v("\n                  Refinement:\n                ")]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 14
    }
  }, [_vm._v("\n                  " + _vm._s(_vm.selected_refinement.refinement) + "\n                ")])], 1), _vm._v(" "), _c("el-row", {
    staticClass: "mt-10"
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_vm._v("\n                  Source:\n                ")]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 14
    }
  }, [_c("router-link", {
    attrs: {
      to: "/processes/create-user-value/mockup-storyboards"
    }
  }, [_vm._v(_vm._s(_vm.selected_refinement.source))])], 1)], 1)], 1)], 1)], 1)])], 1), _vm._v(" "), _c("el-divider"), _vm._v(" "), _c("div", {
    staticClass: "text-right"
  }, [_c("el-button-group", {
    attrs: {
      size: "mini"
    }
  }, [_c("el-tooltip", {
    attrs: {
      effect: "dark",
      content: "Go To Business Roadmap",
      placement: "top-start"
    }
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      icon: "el-icon-arrow-left"
    },
    on: {
      click: _vm.onClickBusinessRoadmap
    }
  }, [_vm._v("Previous")])], 1)], 1)], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Add new Problem",
      visible: _vm.refineProblemDialog,
      width: "50%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.refineProblemDialog = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.problem_refinement_form,
      "label-width": "350px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Reason for change"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      placeholder: "What is the reason or new knowledge that requires refinement of the problem/solution?"
    },
    model: {
      value: _vm.problem_refinement_form.refinement,
      callback: function callback($$v) {
        _vm.$set(_vm.problem_refinement_form, "refinement", $$v);
      },
      expression: "problem_refinement_form.refinement"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "What is changing?"
    }
  }, [_c("el-radio-group", {
    model: {
      value: _vm.problem_refinement_form.changetype,
      callback: function callback($$v) {
        _vm.$set(_vm.problem_refinement_form, "changetype", $$v);
      },
      expression: "problem_refinement_form.changetype"
    }
  }, [_c("el-radio", {
    attrs: {
      label: "problem"
    }
  }, [_vm._v("Problem")]), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "solution"
    }
  }, [_vm._v("Solution")]), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "both"
    }
  }, [_vm._v("Both")])], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Which area identified this?"
    }
  }, [_c("el-tooltip", {
    attrs: {
      effect: "dark",
      content: "Where was the refinement identified?",
      placement: "right"
    }
  }, [_vm._v("\n          >\n          "), _c("el-select", {
    attrs: {
      placeholder: "Choose below"
    },
    model: {
      value: _vm.problem_refinement_form.source,
      callback: function callback($$v) {
        _vm.$set(_vm.problem_refinement_form, "source", $$v);
      },
      expression: "problem_refinement_form.source"
    }
  }, _vm._l(_vm.sources, function (source, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: source.label,
        value: source.value
      }
    });
  }), 1)], 1)], 1), _vm._v(" "), _vm.problem_refinement_form.changetype === "problem" || _vm.problem_refinement_form.changetype === "both" ? _c("el-form-item", {
    attrs: {
      label: "New Problem Statement"
    }
  }, [_c("el-input", {
    staticStyle: {
      "word-break": "keep-all"
    },
    attrs: {
      type: "textarea",
      placeholder: "Only enter a new problem statement if is proven to have changed."
    },
    model: {
      value: _vm.problem_refinement_form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.problem_refinement_form, "description", $$v);
      },
      expression: "problem_refinement_form.description"
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.problem_refinement_form.changetype === "solution" || _vm.problem_refinement_form.changetype === "both" ? _c("el-form-item", {
    attrs: {
      label: "Solution"
    }
  }, [_c("el-input", {
    staticStyle: {
      "word-break": "keep-all"
    },
    attrs: {
      type: "textarea",
      placeholder: "If the problem has not changed, but the solution has, update the solution here."
    },
    model: {
      value: _vm.problem_refinement_form.solution,
      callback: function callback($$v) {
        _vm.$set(_vm.problem_refinement_form, "solution", $$v);
      },
      expression: "problem_refinement_form.solution"
    }
  })], 1) : _vm._e(), _vm._v(" "), _c("el-form-item", [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.refineProblem
    }
  }, [_vm._v("Refine Problem Hypothesis")]), _vm._v(" "), _c("el-button", {
    on: {
      click: function click($event) {
        _vm.refineProblemDialog = false;
      }
    }
  }, [_vm._v("Cancel")])], 1)], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;