"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/taggedTemplateLiteral.js"));
require("core-js/modules/web.dom.iterable");
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7;
var _default = exports.default = {
  name: 'OfferingCreate',
  components: {},
  props: {
    isVariant: {
      type: Boolean,
      default: false
    },
    customerTypeId: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      loading: false,
      createOption: 'manual',
      selectedOffering: {},
      offering_form: {
        title: '',
        description: '',
        offeringtype: 'Journey Driven',
        offeringcategory: 'Primary',
        customerTypeId: ''
      },
      offering_variant_form: {
        offering_id: '',
        title: '',
        description: '',
        makeprimary: false,
        offeringtype: 'Journey Driven',
        offeringcategory: 'Primary',
        customerTypeId: ''
      },
      offering_clone_form: {
        offering_id: '',
        type: 'Enabler',
        customerTypeId: ''
      },
      customer_types: [],
      filtered_customer_types: [{
        label: 'Customers with Offerings',
        options: []
      }, {
        label: 'Customer without Offerings',
        options: []
      }],
      offerings: [],
      variantOfferingRules: {
        offering_id: [{
          required: true,
          message: 'Please input offering',
          trigger: 'blur'
        }]
      },
      addOfferingRules: {
        title: [{
          required: true,
          message: 'Please input title',
          trigger: 'blur'
        }],
        description: [{
          required: true,
          message: 'Please input description',
          trigger: 'blur'
        }],
        offeringtype: [{
          required: true,
          message: 'Please input offeringtype',
          trigger: 'blur'
        }],
        customerTypeId: [{
          required: true,
          message: 'Please select Customer Type',
          trigger: 'blur'
        }],
        offeringcategory: [{
          required: false,
          message: 'Please select Offering Category',
          trigger: 'blur'
        }]
      },
      cloneOfferingRules: {
        offering_id: [{
          required: true,
          message: 'Please input offering',
          trigger: 'blur'
        }],
        customerTypeId: [{
          required: true,
          message: 'Please select Customer Type',
          trigger: 'blur'
        }]
      }
    };
  },
  computed: {
    groupedCustomerTypes: function groupedCustomerTypes() {
      var customer_types = [{
        label: 'Customers with Offerings',
        options: []
      }, {
        label: 'Customer without Offerings',
        options: []
      }];
      window._.forEach(this.customer_types, function (customer_type, key) {
        if (customer_type.linkedOfferings && customer_type.linkedOfferings.length > 0) {
          customer_types[0].options.push(customer_type);
        } else {
          customer_types[1].options.push(customer_type);
        }
      });
      if (customer_types[0] !== undefined && customer_types[0].options.length === 0) {
        customer_types.splice(0, 1);
      }
      if (customer_types[1] !== undefined && customer_types[1].options.length === 0) {
        customer_types.splice(1, 1);
      }
      return customer_types;
    }
  },
  watch: {
    isVariant: function isVariant(val) {
      if (val) {
        this.offering_form.offeringtype === 'Journey Driven';
        this.offering_form.customerTypeId = this.customerTypeId;
        this.offering_form.offeringcategory = 'Secondary';
      }
    }
  },
  created: function created() {},
  mounted: function mounted() {
    this.$eventHub.$on('offering-changed', this.reloadOfferings);
    this.retrieveOfferings();
    this.resetOfferingForm();
    this.resetVariantForm();
    this.retrieveCustomerTypes();
  },
  methods: {
    filteredGroupedCustomerTypes: function filteredGroupedCustomerTypes(offering_id) {
      this.filtered_customer_types = [{
        label: 'Customers with Offerings',
        options: []
      }, {
        label: 'Customer without Offerings',
        options: []
      }];
      for (var j = 0; j < this.customer_types.length; j++) {
        if (this.customer_types[j].linkedOfferings && this.customer_types[j].linkedOfferings.length > 0) {
          var flag = false;
          for (var i = 0; i < this.customer_types[j].linkedOfferings.length; i++) {
            if (this.customer_types[j].linkedOfferings[i].offering.id === offering_id) {
              flag = true;
            }
          }
          if (!flag) {
            this.filtered_customer_types[0].options.push(this.customer_types[j]);
          }
        } else {
          this.filtered_customer_types[1].options.push(this.customer_types[j]);
        }
      }
      if (this.filtered_customer_types[0] !== undefined && this.filtered_customer_types[0].options.length === 0) {
        this.filtered_customer_types.splice(0, 1);
      }
      if (this.filtered_customer_types[1] !== undefined && this.filtered_customer_types[1].options.length === 0) {
        this.filtered_customer_types.splice(1, 1);
      }
    },
    checkIfVariantVisible: function checkIfVariantVisible() {
      for (var i = 0; i < this.customer_types.length; i++) {
        if (this.customer_types[i].id === this.offering_form.customerTypeId && this.customer_types[i].linkedOfferings.length === 0) {
          this.offering_form.offeringcategory = 'Primary';
          return false;
        }
      }
      return true;
    },
    trimString: function trimString(string, length) {
      if (string.length > length) {
        return string.substring(0, length) + '...';
      } else {
        return string;
      }
    },
    retrieveCustomerTypeIdFromOfferingId: function retrieveCustomerTypeIdFromOfferingId(offering_id) {
      for (var i = 0; i < this.offerings.length; i++) {
        if (this.offerings[i].id === offering_id) {
          return this.offerings[i].linkedCustomerTypes[0].customerType.id;
        }
      }
      return '';
    },
    copyJourneyMap: function copyJourneyMap() {
      var _this = this;
      this.$refs.cloneOfferingForm.validate(function (valid) {
        if (valid) {
          _this.loading = true;
          _this.$apollo.mutate({
            // Query
            mutation: window._gql(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["mutation cloneOffering($offering: CloneOfferingInput!)\n            {\n              cloneOffering(input: $offering) {\n                id\n                title\n                description\n                offeringtype\n                offeringcategory\n                iscloned\n                clonedobjectid\n                linkedCustomerTypes(optional: true) {\n                  customerType(optional: true){\n                    id\n                  }\n                }\n              }\n            }"]))),
            // Parameters
            variables: {
              'offering': {
                'offeringId': _this.offering_clone_form.offering_id,
                'customerTypeId': _this.offering_clone_form.customerTypeId
              }
            }
          }).then(function (data) {
            _this.$notify({
              title: 'Success',
              message: 'Created Successfully',
              type: 'success',
              duration: 2000
            });
            _this.$store.dispatch('offerings/updateOfferingId', data.data.cloneOffering.id);
            _this.$emit('create-offering', data.data.cloneOffering);
            _this.loading = false;
          }).catch(function (error) {
            console.log(error);
            _this.loading = false;
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    retrieveOfferings: function retrieveOfferings() {
      var _this2 = this;
      this.loading = true;
      this.offerings = [];
      this.$apollo.query({
        // Query
        query: window._gql(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)([" query Offerings($page: Page)\n        {\n        Offerings(page: $page)\n        {\n          pages\n          total\n          select{\n            id\n            title\n            description\n            offeringtype\n            offeringcategory\n            iscloned\n            clonedobjectid\n            linkedCustomerTypes(optional: true) {\n              customerType(optional: true){\n                id\n                title\n                description\n                additionalattributes\n                tiers\n              }\n            }\n          }\n        }\n      }"]))),
        fetchPolicy: 'no-cache',
        variables: {
          'page': {
            'limit': 200,
            'start': 1
          }
        }
      }).then(function (data) {
        _this2.offerings = data.data.Offerings.select.reverse();
        _this2.loading = false;
      }).catch(function (error) {
        console.log(error);
        _this2.loading = false;
      });
    },
    createManual: function createManual() {
      this.resetOfferingForm();
      this.resetOfferingCloneForm();
      this.createOption = 'manual';
    },
    createVariant: function createVariant() {
      this.selectedOffering = {};
      this.resetVariantForm();
      this.createOption = 'variant';
    },
    copyOffering: function copyOffering() {
      this.resetOfferingForm();
      this.resetOfferingCloneForm();
      this.createOption = 'copy';
    },
    onCopyOfferingChange: function onCopyOfferingChange(val) {
      this.filteredGroupedCustomerTypes(val);
    },
    onVariantOfferingChange: function onVariantOfferingChange(val) {
      for (var i = 0; i < this.offerings.length; i++) {
        if (this.offerings[i].id === val) {
          this.selectedOffering = this.offerings[i];
          if (this.offerings[i].linkedCustomerTypes.length > 0) {
            this.offering_variant_form.customerTypeId = this.offerings[i].linkedCustomerTypes[0].customerType.id;
            this.offering_variant_form.offeringtype = 'Journey Driven';
          } else {
            this.offering_variant_form.offeringtype = 'No Journey';
          }
        }
      }
    },
    checkIfPrimaryOffering: function checkIfPrimaryOffering(val) {
      if (this.offering_form.customerTypeId !== '') {
        this.offering_form.offeringcategory = 'Primary';
        for (var i = 0; i < this.customer_types.length; i++) {
          if (this.customer_types[i].id === val) {
            this.offering_form.offeringcategory = this.customer_types[i].linkedOfferings.length === 0 ? 'Primary' : 'Secondary';
          }
        }
      } else {
        this.offering_form.offeringcategory = 'Primary';
      }
    },
    reloadOfferings: function reloadOfferings() {
      this.retrieveCustomerTypes();
    },
    retrieveCustomerTypes: function retrieveCustomerTypes() {
      var _this3 = this;
      this.loading = true;
      this.customer_types = [];
      this.$apollo.query({
        // Query
        query: window._gql(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)([" query CustomerTypes($page: Page)\n        {\n        CustomerTypes(page: $page)\n        {\n          pages\n          total\n          select{\n            id\n            title\n            description\n            linkedOfferings(optional: true) {\n              offering(optional: true) {\n                id\n                title\n              }\n            }\n          }\n        }\n      }"]))),
        fetchPolicy: 'no-cache',
        variables: {
          'page': {
            'limit': 200,
            'start': 1
          }
        }
      }).then(function (data) {
        _this3.customer_types = data.data.CustomerTypes.select;
        if (_this3.customer_types.length === 0) {
          _this3.offering_form.offeringtype = 'No Journey';
        }
        if (_this3.isVariant) {
          _this3.offering_form.customerTypeId = _this3.customerTypeId;
          _this3.offering_form.offeringcategory = 'Secondary';
        }
        _this3.loading = false;
      }).catch(function (error) {
        // Error
        _this3.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this3.loading = false;
      });
    },
    updateVariantOfferingCategory: function updateVariantOfferingCategory(val) {
      if (val) {
        this.offering_variant_form.offeringcategory = 'Primary';
      } else {
        this.offering_variant_form.offeringcategory = 'Secondary';
      }
    },
    createVariantOffering: function createVariantOffering() {
      var _this4 = this;
      this.$refs.addVariantOfferingForm.validate(function (valid) {
        if (valid) {
          _this4.loading = true;
          _this4.$apollo.mutate({
            // Query
            mutation: window._gql(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2.default)(["mutation cloneOffering($offering: CloneOfferingInput!)\n            {\n              cloneOffering(input: $offering) {\n                id\n                title\n                description\n                offeringtype\n                offeringcategory\n                iscloned\n                clonedobjectid\n                linkedCustomerTypes(optional: true) {\n                  customerType(optional: true){\n                    id\n                  }\n                }\n              }\n            }"]))),
            // Parameters
            variables: {
              'offering': {
                'offeringId': _this4.offering_variant_form.offering_id,
                'customerTypeId': _this4.offering_variant_form.customerTypeId
              }
            }
          }).then(function (data) {
            _this4.$notify({
              title: 'Success',
              message: 'Created Successfully',
              type: 'success',
              duration: 2000
            });
            _this4.$store.dispatch('offerings/updateOfferingId', data.data.cloneOffering.id);
            _this4.$emit('create-offering', data.data.cloneOffering);
            _this4.loading = false;
          }).catch(function (error) {
            console.log(error);
            _this4.loading = false;
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    createOffering: function createOffering() {
      var _this5 = this;
      this.$refs.addOfferingForm.validate(function (valid) {
        if (valid) {
          _this5.loading = true;
          _this5.$apollo.mutate({
            // Query
            mutation: window._gql(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2.default)(["mutation createOffering($offering: CreateOfferingInput!)\n            {\n              createOffering(input: $offering) {\n                id\n                title\n                description\n                offeringtype\n                offeringcategory\n                iscloned\n                clonedobjectid\n                linkedCustomerTypes(optional: true) {\n                  customerType(optional: true){\n                    id\n                  }\n                }\n              }\n            }"]))),
            // Parameters
            variables: {
              'offering': {
                'customerTypeId': _this5.offering_form.customerTypeId,
                'title': _this5.offering_form.title,
                'description': _this5.offering_form.description,
                'offeringtype': _this5.offering_form.offeringtype,
                'offeringcategory': _this5.offering_form.offeringcategory,
                'offeringcategorydesc': _this5.offering_form.offeringcategory,
                'additionalattributes': {
                  'productLane': {
                    'isEnablersVisible': true
                  },
                  'serviceLane': {
                    'isEnablersVisible': true
                  },
                  'entityLane': {
                    'isEnablersVisible': true,
                    'isProductsVisible': true,
                    'isServicesVisible': true,
                    'isUnmappedVisible': true
                  },
                  'productsSortOrder': [],
                  'servicesSortOrder': [],
                  'entitiesSortOrder': [],
                  'relations': []
                }
              }
            }
          }).then(function (data) {
            _this5.$notify({
              title: 'Success',
              message: 'Created Successfully',
              type: 'success',
              duration: 2000
            });
            // if category is primary and customer has existing offering, update all offerings to secondary
            if (_this5.offering_form.offeringcategory === 'Primary' && _this5.offering_form.customerTypeId !== '') {
              _this5.checkAndUpdateExistingOfferingCategory(data.data.createOffering.id, _this5.offering_form.customerTypeId);
            }
            _this5.resetOfferingForm();
            // Dont create enabler by default
            // this.createJourneyMapServiceType('Enabler', data.data.createOffering.id)
            _this5.$store.dispatch('offerings/updateOfferingId', data.data.createOffering.id);
            _this5.$emit('create-offering', data.data.createOffering);
            // this.$router.push('/offerings/overview')
            _this5.loading = false;
          }).catch(function (error) {
            // Error
            _this5.$notify({
              title: 'Error',
              message: error,
              type: 'error',
              duration: 2000
            });
            _this5.loading = false;
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    checkAndUpdateExistingOfferingCategory: function checkAndUpdateExistingOfferingCategory(exception_offering_id, customer_id) {
      var _this6 = this;
      this.$apollo.query({
        // Query
        query: window._gql(_templateObject6 || (_templateObject6 = (0, _taggedTemplateLiteral2.default)([" query CustomerType($id: String)\n        {\n        CustomerType(id: $id)\n        {\n          id\n          title\n          description\n          linkedOfferings(optional: true) {\n            offering(optional: true) {\n              id\n              title\n              offeringcategory\n            }\n          }\n        }\n      }"]))),
        fetchPolicy: 'no-cache',
        variables: {
          'id': customer_id
        }
      }).then(function (data) {
        if (data.data.CustomerType) {
          for (var i = 0; i < data.data.CustomerType.linkedOfferings.length; i++) {
            if (data.data.CustomerType.linkedOfferings[i].offering.id !== exception_offering_id && data.data.CustomerType.linkedOfferings[i].offering.offeringcategory === 'Primary') {
              _this6.updateOfferingCategoryToSecondary(data.data.CustomerType.linkedOfferings[i].offering.id);
            }
          }
        }
      }).catch(function (error) {
        // Error
        _this6.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
      });
    },
    updateOfferingCategoryToSecondary: function updateOfferingCategoryToSecondary(offering_id) {
      var _this7 = this;
      this.$apollo.mutate({
        // Query
        mutation: window._gql(_templateObject7 || (_templateObject7 = (0, _taggedTemplateLiteral2.default)(["mutation updateOffering($offering: UpdateOfferingInput!)\n        {\n          updateOffering(input: $offering) {\n            id\n          }\n        }"]))),
        // Parameters
        variables: {
          'offering': {
            'id': offering_id,
            'offeringcategory': 'Secondary',
            'offeringcategorydesc': 'Secondary'
          }
        }
      }).then(function (data) {
        console.log('updated');
      }).catch(function (error) {
        // Error
        _this7.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
      });
    },
    /* createJourneyMapServiceType(type, offering_id) {
      this.loading = true
      this.$apollo.mutate({
        // Query
        mutation: window._gql `mutation createService($customerType: CreateServiceInput!)
        {
          createService(input: $customerType) {
            id
          }
        }`,
        // Parameters
        variables: {
          'customerType': {
            'offeringId': offering_id,
            'title': type,
            'description': type,
            'servicetype': type,
            'additionalattributes': {
              'minimized': true
            }
          }
        }
      }).then((data) => {
        this.$store.dispatch('offerings/updateOfferingId', offering_id)
        this.$router.push('/offerings/overview')
        this.loading = false
      }).catch((error) => {
        // Error
        this.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        })
        this.loading = false
      })
    },*/
    closeDialog: function closeDialog() {
      this.$emit('close');
    },
    resetOfferingForm: function resetOfferingForm() {
      this.offering_form = {
        title: '',
        description: '',
        offeringtype: 'Journey Driven',
        offeringcategory: 'Primary',
        customerTypeId: ''
      };
    },
    resetVariantForm: function resetVariantForm() {
      this.offering_variant_form = {
        offering_id: '',
        title: '',
        description: '',
        makeprimary: false,
        offeringtype: 'Journey Driven',
        offeringcategory: 'Primary',
        customerTypeId: ''
      };
    },
    resetOfferingCloneForm: function resetOfferingCloneForm() {
      this.offering_clone_form = {
        offering_id: '',
        type: 'Enabler',
        customerTypeId: ''
      };
    }
  }
};