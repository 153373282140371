"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("g", [_c("rect", {
    attrs: {
      x: _vm.x + 142,
      y: _vm.y,
      rx: "0",
      width: _vm.width - 10,
      height: _vm.height,
      fill: "white",
      "stroke-width": "1",
      stroke: "#eee"
    },
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.resetSettingsAndMenu();
      }
    }
  }), _vm._v(" "), !_vm.isMinimized ? _c("g", {
    attrs: {
      refs: "model-offering-service-workspace-matrix-grid"
    }
  }, _vm._l(_vm.computeTotalGridLanes, function (y_datum, y_index) {
    return _c("g", {
      key: "y_" + y_index,
      attrs: {
        refs: "y-axis"
      }
    }, _vm._l(100, function (x_datum, x_index) {
      return _c("g", {
        key: "x_" + x_index,
        attrs: {
          refs: "x-axis"
        }
      }, [_c("path", {
        attrs: {
          d: _vm.generateDiamondPath(120 + x_datum * 50, _vm.y + (y_datum - 1) * 50 - 1),
          "stroke-opacity": "0.5",
          stroke: "#ccc",
          "stroke-width": "1",
          fill: "none"
        }
      })]);
    }), 0);
  }), 0) : _vm._e(), _vm._v(" "), _c("g", {
    attrs: {
      refs: "model-offering-service-workspace-item-header"
    },
    on: {
      click: function click($event) {
        return _vm.resetSettingsAndMenu();
      }
    }
  }, [_c("rect", {
    attrs: {
      x: "41",
      y: _vm.y + 22,
      rx: "0",
      fill: "rgba(200,200,200,0.1)",
      width: "104",
      height: _vm.height - 22,
      "stroke-width": "1",
      stroke: "#f1f4ff"
    }
  }), _vm._v(" "), _c("g", {
    attrs: {
      refs: "model-offering-service-workspace-item-header-tools"
    }
  }, [_c("foreignObject", {
    directives: [{
      name: "dragged",
      rawName: "v-dragged.prevent",
      value: _vm.onServiceApplicationAutomatedToolDragged,
      expression: "onServiceApplicationAutomatedToolDragged",
      modifiers: {
        prevent: true
      }
    }],
    attrs: {
      id: _vm.generateUniqueServiceToolID(_vm.index),
      x: _vm.tools.service_application_automated.x,
      y: _vm.tools.service_application_automated.y,
      width: 40,
      height: 40,
      fill: "none"
    }
  }, [!_vm.isMinimized ? _c("div", {
    staticClass: "ujm-swimlane-resize-icon",
    staticStyle: {
      "padding-left": "5px",
      "padding-top": "5px"
    }
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "29px",
      color: "#304174"
    },
    attrs: {
      "icon-class": "process-auto"
    }
  })], 1) : _vm._e()]), _vm._v(" "), _c("foreignObject", {
    directives: [{
      name: "dragged",
      rawName: "v-dragged.prevent",
      value: _vm.onServiceApplicationSemiAutomatedToolDragged,
      expression: "onServiceApplicationSemiAutomatedToolDragged",
      modifiers: {
        prevent: true
      }
    }],
    attrs: {
      id: _vm.generateUniqueServiceToolID(_vm.index),
      x: _vm.tools.service_application_semi_automated.x,
      y: _vm.tools.service_application_semi_automated.y,
      width: 40,
      height: 40,
      fill: "none"
    }
  }, [!_vm.isMinimized ? _c("div", {
    staticClass: "ujm-swimlane-resize-icon",
    staticStyle: {
      "padding-left": "5px",
      "padding-top": "8px"
    }
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "29px",
      color: "#304174"
    },
    attrs: {
      "icon-class": "process-mixed"
    }
  })], 1) : _vm._e()]), _vm._v(" "), _c("foreignObject", {
    directives: [{
      name: "dragged",
      rawName: "v-dragged.prevent",
      value: _vm.onServiceApplicationManualToolDragged,
      expression: "onServiceApplicationManualToolDragged",
      modifiers: {
        prevent: true
      }
    }],
    attrs: {
      id: _vm.generateUniqueServiceToolID(_vm.index),
      x: _vm.tools.service_application_manual.x,
      y: _vm.tools.service_application_manual.y,
      width: 40,
      height: 40,
      fill: "none"
    }
  }, [!_vm.isMinimized ? _c("div", {
    staticClass: "ujm-swimlane-resize-icon",
    staticStyle: {
      "padding-left": "5px",
      "padding-top": "5px"
    }
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "29px",
      color: "#304174"
    },
    attrs: {
      "icon-class": "process-manual"
    }
  })], 1) : _vm._e()]), _vm._v(" "), _c("foreignObject", {
    directives: [{
      name: "dragged",
      rawName: "v-dragged.prevent",
      value: _vm.onServiceApplicationThirdpartyToolDragged,
      expression: "onServiceApplicationThirdpartyToolDragged",
      modifiers: {
        prevent: true
      }
    }],
    attrs: {
      id: _vm.generateUniqueServiceToolID(_vm.index),
      x: _vm.tools.service_application_thirdparty.x,
      y: _vm.tools.service_application_thirdparty.y,
      width: 40,
      height: 40,
      fill: "none"
    }
  }, [!_vm.isMinimized ? _c("div", {
    staticClass: "ujm-swimlane-resize-icon",
    staticStyle: {
      "padding-left": "5px",
      "padding-top": "5px"
    }
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "29px",
      color: "#304174"
    },
    attrs: {
      "icon-class": "process-3rdparty"
    }
  })], 1) : _vm._e()]), _vm._v(" "), _c("foreignObject", {
    attrs: {
      x: _vm.tools.idea.x,
      y: _vm.tools.idea.y,
      width: 40,
      height: 40,
      fill: "none"
    },
    on: {
      click: function click($event) {
        return _vm.createServiceIdea();
      }
    }
  }, [!_vm.isMinimized ? _c("div", {
    staticClass: "ujm-swimlane-resize-icon"
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "18px",
      color: "#304174"
    },
    attrs: {
      "icon-class": "ideas"
    }
  })], 1) : _vm._e()]), _vm._v(" "), _vm.hasPermissionForModule("Risk Managment") ? _c("foreignObject", {
    directives: [{
      name: "dragged",
      rawName: "v-dragged.prevent",
      value: _vm.onServiceRiskDragged,
      expression: "onServiceRiskDragged",
      modifiers: {
        prevent: true
      }
    }],
    attrs: {
      id: _vm.generateUniqueServiceToolID(_vm.index),
      x: _vm.tools.risk.x,
      y: _vm.tools.risk.y,
      width: 40,
      height: 40,
      fill: "none"
    }
  }, [!_vm.isMinimized ? _c("div", {
    staticClass: "ujm-swimlane-resize-icon",
    staticStyle: {
      "padding-left": "8px",
      "padding-top": "6px"
    }
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "16px",
      color: "#F56C6C"
    },
    attrs: {
      "icon-class": "fire"
    }
  })], 1) : _vm._e()]) : _vm._e()]), _vm._v(" "), _c("rect", {
    attrs: {
      x: 5,
      y: _vm.y,
      width: "35",
      height: _vm.height,
      fill: "rgba(200,200,200,0.35)",
      "stroke-width": "1",
      stroke: "#eee"
    }
  }), _vm._v(" "), !_vm.isMinimized ? _c("g", {
    attrs: {
      refs: "service-settings-tool"
    }
  }, [_c("foreignObject", {
    attrs: {
      x: 17,
      y: _vm.y + 30,
      width: 15,
      height: 15
    }
  }, [_c("div", [_c("svg-icon", {
    staticStyle: {
      "font-size": "10px",
      color: "#304174"
    },
    attrs: {
      "icon-class": "gear"
    },
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.displayServiceSetting(_vm.index);
      }
    }
  })], 1)]), _vm._v(" "), _c("foreignObject", {
    attrs: {
      x: 17,
      y: _vm.y + 50,
      width: 15,
      height: 15
    }
  }, [_c("div", [_c("i", {
    staticClass: "el-icon-video-play",
    staticStyle: {
      "font-size": "10px",
      color: "#304174",
      transform: "rotate(270deg)"
    },
    on: {
      click: _vm.moveUpEntitySortOrder
    }
  })])]), _vm._v(" "), _c("foreignObject", {
    attrs: {
      x: 17,
      y: _vm.y + 70,
      width: 15,
      height: 15
    }
  }, [_c("div", [_c("i", {
    staticClass: "el-icon-video-play",
    staticStyle: {
      "font-size": "10px",
      color: "#304174",
      transform: "rotate(90deg)"
    },
    on: {
      click: _vm.moveDownEntitySortOrder
    }
  })])])]) : _vm._e(), _vm._v(" "), _c("rect", {
    attrs: {
      x: 5,
      y: _vm.y,
      rx: "3",
      width: "140",
      height: 22,
      fill: "#305497",
      "stroke-width": "1",
      stroke: "#305497"
    }
  }), _vm._v(" "), _c("g", {
    attrs: {
      refs: "service-collapse-tool"
    }
  }, [!_vm.isMinimized ? _c("foreignObject", {
    attrs: {
      x: 127,
      y: _vm.y + 5,
      width: 14,
      height: 12
    }
  }, [_c("div", [_c("svg-icon", {
    attrs: {
      "icon-class": "arrow-shrink-w",
      title: "Shrink Lane"
    },
    on: {
      click: function click($event) {
        return _vm.resizeServiceSwimlane("shrink");
      }
    }
  })], 1)]) : _vm._e(), _vm._v(" "), _vm.isMinimized ? _c("foreignObject", {
    attrs: {
      x: 127,
      y: _vm.y + 5,
      width: 14,
      height: 12
    }
  }, [_c("div", [_c("svg-icon", {
    attrs: {
      "icon-class": "arrow-expand-w",
      title: "Expand Lane"
    },
    on: {
      click: function click($event) {
        return _vm.resizeServiceSwimlane("expand");
      }
    }
  })], 1)]) : _vm._e()]), _vm._v(" "), _c("foreignObject", {
    attrs: {
      x: 13,
      y: _vm.y + 2,
      width: 20,
      height: 20
    }
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "16px",
      color: "#fff"
    },
    attrs: {
      "icon-class": "pdg-service"
    }
  })], 1), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "#fff",
      "font-weight": "400",
      "font-variant-caps": "petite-caps"
    },
    attrs: {
      x: 39,
      y: _vm.y + 14,
      "text-anchor": "start",
      "font-size": "0.9em"
    }
  }, [_vm._v("\n      " + _vm._s(_vm.trimString(_vm.service.title, 15)) + "\n    ")])]), _vm._v(" "), !_vm.isMinimized && _vm.serviceHasUnmappedFeatures ? _c("g", {
    attrs: {
      refs: "model-offering-service-workspace-un-mapped"
    }
  }, [_c("line", {
    attrs: {
      x1: 110 + 28 + 32,
      y1: _vm.y + 115 - 15 + (_vm.serviceHasEnablerFeatures ? 50 : 0),
      x2: _vm.width - 10,
      y2: _vm.y + 115 - 15 + (_vm.serviceHasEnablerFeatures ? 50 : 0),
      stroke: "rgba(200,200,200,0.7)",
      "stroke-width": "1",
      fill: "none"
    }
  }), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "grey",
      "font-weight": "400",
      "font-variant-caps": "petite-caps"
    },
    attrs: {
      x: 110 + 28 + 32,
      y: _vm.y + 115 - 15 + 14 + (_vm.serviceHasEnablerFeatures ? 50 : 0),
      "text-anchor": "start",
      "font-size": "0.95em"
    }
  }, [_vm._v("\n      Unmapped\n    ")])]) : _vm._e(), _vm._v(" "), !_vm.isMinimized && _vm.serviceHasEnablerFeatures ? _c("g", {
    attrs: {
      refs: "model-offering-service-workspace-enabler"
    }
  }, [_c("line", {
    attrs: {
      x1: 151,
      y1: _vm.y + 115 - 15,
      x2: _vm.width - 10,
      y2: _vm.y + 115 - 15,
      stroke: "rgba(200,200,200,0.7)",
      "stroke-width": "1",
      fill: "none"
    }
  }), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "grey",
      "font-weight": "400",
      "font-variant-caps": "petite-caps"
    },
    attrs: {
      x: 151,
      y: _vm.y + 115 - 15 + 14,
      "text-anchor": "start",
      "font-size": "0.95em"
    }
  }, [_vm._v("\n      Enabler\n    ")])]) : _vm._e(), _vm._v(" "), !_vm.isMinimized ? _c("g", {
    attrs: {
      refs: "service-applications"
    }
  }, _vm._l(_vm.service.linkedServiceApplications, function (service_application_datum, service_application_index) {
    return _c("g", {
      key: "service_application_" + service_application_index,
      attrs: {
        refs: "service-application"
      },
      on: {
        mouseover: function mouseover($event) {
          return _vm.onServiceApplicationMouseOver(service_application_index, service_application_datum.serviceApplication.id);
        },
        mouseleave: function mouseleave($event) {
          return _vm.onServiceApplicationMouseLeave(service_application_index, service_application_datum.serviceApplication.id);
        }
      }
    }, [_vm.isVisible(service_application_datum) ? _c("g", [_c("rect", {
      attrs: {
        x: _vm.computeServiceApplicationPosX(service_application_index, service_application_datum.serviceApplication.id),
        y: _vm.computeServiceApplicationPosY(service_application_index, service_application_datum.serviceApplication.id) + 3,
        rx: "2",
        width: _vm.isMinimized ? 28 : 38,
        height: _vm.isMinimized ? 15 : 23,
        fill: "none",
        "stroke-width": "1",
        "stroke-dasharray": _vm.computeServiceApplicationStrokeDasharray(service_application_datum),
        stroke: _vm.computeServiceApplicationStrokeColor(service_application_datum)
      }
    }), _vm._v(" "), service_application_datum.serviceApplication.additionalattributes.type === "automated" ? _c("automated-component", {
      directives: [{
        name: "dragged",
        rawName: "v-dragged.prevent",
        value: _vm.onServiceApplicationDragged,
        expression: "onServiceApplicationDragged",
        modifiers: {
          prevent: true
        }
      }],
      attrs: {
        id: _vm.generateUniqueServiceApplicationID(service_application_index),
        index: _vm.index,
        "sub-index": service_application_index,
        x: _vm.computeServiceApplicationPosX(service_application_index, service_application_datum.serviceApplication.id),
        y: _vm.computeServiceApplicationPosY(service_application_index, service_application_datum.serviceApplication.id),
        label: service_application_datum.serviceApplication.title,
        source: service_application_datum.serviceApplication.additionalattributes.issueSource,
        "show-label": true,
        minimized: _vm.isMinimized
      },
      on: {
        click: _vm.openServiceApplicationDetailDialog
      }
    }) : _vm._e(), _vm._v(" "), service_application_datum.serviceApplication.additionalattributes.type === "semi_automated" || service_application_datum.serviceApplication.additionalattributes.type === "semi-automated" ? _c("semi-automated-component", {
      directives: [{
        name: "dragged",
        rawName: "v-dragged.prevent",
        value: _vm.onServiceApplicationDragged,
        expression: "onServiceApplicationDragged",
        modifiers: {
          prevent: true
        }
      }],
      attrs: {
        id: _vm.generateUniqueServiceApplicationID(service_application_index),
        index: _vm.index,
        "sub-index": service_application_index,
        x: _vm.computeServiceApplicationPosX(service_application_index, service_application_datum.serviceApplication.id),
        y: _vm.computeServiceApplicationPosY(service_application_index, service_application_datum.serviceApplication.id),
        label: service_application_datum.serviceApplication.title,
        source: service_application_datum.serviceApplication.additionalattributes.issueSource,
        "show-label": true,
        minimized: _vm.isMinimized
      },
      on: {
        click: _vm.openServiceApplicationDetailDialog
      }
    }) : _vm._e(), _vm._v(" "), service_application_datum.serviceApplication.additionalattributes.type === "manual" ? _c("manual-component", {
      directives: [{
        name: "dragged",
        rawName: "v-dragged.prevent",
        value: _vm.onServiceApplicationDragged,
        expression: "onServiceApplicationDragged",
        modifiers: {
          prevent: true
        }
      }],
      attrs: {
        id: _vm.generateUniqueServiceApplicationID(service_application_index),
        index: _vm.index,
        "sub-index": service_application_index,
        x: _vm.computeServiceApplicationPosX(service_application_index, service_application_datum.serviceApplication.id),
        y: _vm.computeServiceApplicationPosY(service_application_index, service_application_datum.serviceApplication.id),
        label: service_application_datum.serviceApplication.title,
        source: service_application_datum.serviceApplication.additionalattributes.issueSource,
        "show-label": true,
        minimized: _vm.isMinimized
      },
      on: {
        click: _vm.openServiceApplicationDetailDialog
      }
    }) : _vm._e(), _vm._v(" "), service_application_datum.serviceApplication.additionalattributes.type === "thirdparty" ? _c("third-party-component", {
      directives: [{
        name: "dragged",
        rawName: "v-dragged.prevent",
        value: _vm.onServiceApplicationDragged,
        expression: "onServiceApplicationDragged",
        modifiers: {
          prevent: true
        }
      }],
      attrs: {
        id: _vm.generateUniqueServiceApplicationID(service_application_index),
        index: _vm.index,
        "sub-index": service_application_index,
        x: _vm.computeServiceApplicationPosX(service_application_index, service_application_datum.serviceApplication.id),
        y: _vm.computeServiceApplicationPosY(service_application_index, service_application_datum.serviceApplication.id),
        label: service_application_datum.serviceApplication.title,
        source: service_application_datum.serviceApplication.additionalattributes.issueSource,
        "show-label": true,
        minimized: _vm.isMinimized
      },
      on: {
        click: _vm.openServiceApplicationDetailDialog
      }
    }) : _vm._e(), _vm._v(" "), service_application_datum.serviceApplication.additionalattributes.type === "undefined" ? _c("orphan-service-application-component", {
      directives: [{
        name: "dragged",
        rawName: "v-dragged.prevent",
        value: _vm.onServiceApplicationDragged,
        expression: "onServiceApplicationDragged",
        modifiers: {
          prevent: true
        }
      }],
      attrs: {
        id: _vm.generateUniqueServiceApplicationID(service_application_index),
        index: _vm.index,
        "sub-index": service_application_index,
        x: _vm.computeServiceApplicationPosX(service_application_index, service_application_datum.serviceApplication.id),
        y: _vm.computeServiceApplicationPosY(service_application_index, service_application_datum.serviceApplication.id),
        label: service_application_datum.serviceApplication.title,
        source: service_application_datum.serviceApplication.additionalattributes.issueSource,
        "show-label": true,
        minimized: _vm.isMinimized
      },
      on: {
        click: _vm.openServiceApplicationDetailDialog
      }
    }) : _vm._e()], 1) : _vm._e()]);
  }), 0) : _vm._e(), _vm._v(" "), _vm.drop_hover_grid.hover ? _c("g", {
    attrs: {
      refs: "hover_highlight"
    }
  }, [_c("rect", {
    staticClass: "gaphighlighter-anim",
    attrs: {
      x: _vm.drop_hover_grid.x,
      y: _vm.drop_hover_grid.y,
      rx: "5",
      ry: "5",
      width: "50",
      height: "50",
      fill: "rgba(64,150,238, 0.5)",
      "fill-opacity": "0.5",
      "stroke-dasharray": "5,5",
      stroke: "#4096EE",
      "stroke-width": 1,
      "stroke-opacity": "0.9"
    }
  })]) : _vm._e()]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;