"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("g", {
    attrs: {
      refs: "increment_lane"
    },
    on: {
      mouseover: function mouseover($event) {
        $event.preventDefault();
        $event.stopPropagation();
        _vm.isHover = true;
      },
      mouseleave: function mouseleave($event) {
        $event.preventDefault();
        $event.stopPropagation();
        _vm.isHover = false;
      }
    }
  }, [_c("g", {
    attrs: {
      refs: ""
    }
  }, [_c("rect", {
    attrs: {
      x: _vm.x + 2,
      y: _vm.y + 10,
      width: _vm.width - 4,
      height: 10,
      fill: _vm.computeFillColor,
      "fill-opacity": _vm.computeFillOpacity,
      stroke: _vm.computeFillColor,
      "stroke-width": "1"
    }
  }), _vm._v(" "), _c("rect", {
    attrs: {
      x: _vm.x + 2,
      y: _vm.y,
      width: _vm.width - 4,
      height: 20,
      rx: 10,
      fill: _vm.computeFillColor,
      "fill-opacity": _vm.computeFillOpacity,
      stroke: _vm.computeFillColor,
      "stroke-width": "1"
    }
  }), _vm._v(" "), _c("foreignObject", {
    staticClass: "text-left",
    staticStyle: {
      overflow: "hidden",
      "border-radius": "2pt"
    },
    attrs: {
      x: _vm.x,
      y: _vm.y + 4,
      width: _vm.width,
      height: 20
    },
    on: {
      click: _vm.onClick
    }
  }, [_c("div", {
    style: _vm.computeTitleStyle,
    attrs: {
      slot: "reference"
    },
    slot: "reference"
  }, [_vm._v("\n        " + _vm._s(_vm.truncateString(_vm.increment.title, 20)) + "\n      ")])]), _vm._v(" "), _vm.isHover && _vm.settings.increment.delete !== "disabled" ? _c("foreignObject", {
    staticClass: "text-left",
    attrs: {
      x: _vm.x + _vm.width - 15,
      y: _vm.y + 3,
      width: 13,
      height: 13
    },
    on: {
      click: _vm.deleteIncrement
    }
  }, [_c("i", {
    staticClass: "el-icon-delete",
    style: _vm.computeIconStyle
  })]) : _vm._e()]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "container"
    }
  }, [_c("rect", {
    attrs: {
      x: _vm.x + 2,
      y: _vm.y + 20,
      width: _vm.width - 4,
      height: _vm.height - 10,
      rx: "2",
      fill: _vm.computeFillColor + "22",
      stroke: _vm.computeFillColor + "FF",
      "stroke-width": "0.5"
    }
  })]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "increment_details"
    }
  }, [_vm._t("default")], 2)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;