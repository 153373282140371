"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/taggedTemplateLiteral.js"));
var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));
var _templateObject, _templateObject2, _templateObject3;
var _default = exports.default = {
  name: 'BusinessModelCanvas',
  components: {
    Tinymce: _Tinymce.default
  },
  props: {},
  data: function data() {
    return {
      business_model: {},
      loading: false,
      partnersDialog: false,
      competitorsDialog: false,
      keyResourcesDialog: false,
      keyActivitiesDialog: false,
      valuePropositionDialog: false,
      customerRelationsDialog: false,
      channelsDialog: false,
      customerSegmentsDialog: false,
      costStructureDialog: false,
      revenueStreamDialog: false,
      competitors: []
    };
  },
  created: function created() {},
  mounted: function mounted() {
    var _this = this;
    this.loading = true;
    this.retrieveBMC(1);
    /* this.$store.dispatch('offerings/setBusinessModel', this.$store.getters.product.id).then((data) => {
      this.loading = false
      this.business_model = data.data
    }).catch((e) => {
      this.loading = false
      this.$message({
        type: 'error',
        message: e.message
      })
    })*/

    this.$store.dispatch('getProductFeatures').then(function (data) {
      _this.loading = false;
      _this.competitors = data.data;
    }).catch(function (e) {
      _this.loading = false;
      _this.$message({
        type: 'error',
        message: e.message
      });
    });
  },
  methods: {
    updateRevenueStream: function updateRevenueStream(value) {
      this.business_model.revenuestreams = value;
    },
    updateCompetitors: function updateCompetitors(value) {
      this.business_model.competitors = value;
    },
    updateKeyResources: function updateKeyResources(value) {
      this.business_model.keyresources = value;
    },
    updatePartner: function updatePartner(value) {
      this.business_model.partners = value;
    },
    updateKeyActivities: function updateKeyActivities(value) {
      this.business_model.keyactivities = value;
    },
    updateValueProposition: function updateValueProposition(value) {
      this.business_model.valueproposition = value;
    },
    updateCustomerRelationship: function updateCustomerRelationship(value) {
      this.business_model.customerrelationship = value;
    },
    updateChannels: function updateChannels(value) {
      this.business_model.channel = value;
    },
    updateCostStructure: function updateCostStructure(value) {
      this.business_model.coststructure = value;
    },
    updateCustomerSegments: function updateCustomerSegments(value) {
      this.business_model.customersegment = value;
    },
    onSubmit: function onSubmit() {},
    gotoMonitorCompetitor: function gotoMonitorCompetitor() {
      this.$router.push({
        name: 'Monitor Competitors'
      });
    },
    // TODO Saving BMC Values
    createBMC: function createBMC() {
      var _this2 = this;
      this.loading = true;
      this.$apollo.mutate({
        // Query
        mutation: window._gql(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["mutation createBusinessModelCanvas($businessmodelcanvas: CreateBusinessModelCanvasInput!)\n        {\n          createBusinessModelCanvas(input: $businessmodelcanvas) {\n            additionalattributes\n            channel\n            competitors\n            coststructure\n            createdat\n            createdby\n            customerrelationship\n            customersegment\n            description\n            id\n            keyactivities\n            keyresources\n            modifiedat\n            modifiedby\n            partners\n            revenuestreams\n            title\n            valueproposition\n          }\n        }"]))),
        // Parameters
        variables: {
          'businessmodelcanvas': {
            'channel': this.business_model.channel,
            'competitors': this.business_model.competitors,
            'coststructure': this.business_model.coststructure,
            'customerrelationship': this.business_model.customerrelationship,
            'customersegment': this.business_model.customersegment,
            'keyactivities': this.business_model.keyactivities,
            'keyresources': this.business_model.keyresources,
            'partners': this.business_model.partners,
            'revenuestreams': this.business_model.revenuestreams,
            'valueproposition': this.business_model.valueproposition,
            'title': 'BMC Title',
            'description': 'BMC Description'
          }
        }
      }).then(function (data) {
        _this2.business_model = data.data.createBusinessModelCanvas;
        _this2.retrieveBMC(1);
        _this2.loading = false;
      }).catch(function (error) {
        // Error
        _this2.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this2.loading = false;
      });
    },
    updateBMC: function updateBMC() {
      var _this3 = this;
      this.loading = true;
      this.$apollo.mutate({
        // Query
        mutation: window._gql(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["mutation updateBusinessModelCanvas($businessmodelcanvas: UpdateBusinessModelCanvasInput!)\n        {\n          updateBusinessModelCanvas(input: $businessmodelcanvas) {\n            additionalattributes\n            channel\n            competitors\n            coststructure\n            createdat\n            createdby\n            customerrelationship\n            customersegment\n            description\n            id\n            keyactivities\n            keyresources\n            modifiedat\n            modifiedby\n            partners\n            revenuestreams\n            title\n            valueproposition\n          }\n        }"]))),
        // Parameters
        variables: {
          'businessmodelcanvas': {
            'id': this.business_model.id,
            'channel': this.business_model.channel,
            'competitors': this.business_model.competitors,
            'coststructure': this.business_model.coststructure,
            'customerrelationship': this.business_model.customerrelationship,
            'customersegment': this.business_model.customersegment,
            'keyactivities': this.business_model.keyactivities,
            'keyresources': this.business_model.keyresources,
            'partners': this.business_model.partners,
            'valueproposition': this.business_model.valueproposition,
            'revenuestreams': this.business_model.revenuestreams
          }
        }
      }).then(function (data) {
        _this3.$notify({
          title: 'Success',
          message: 'Updated Successfully',
          type: 'success',
          duration: 2000
        });
        _this3.resetDialogs();
        _this3.business_model = data.data.updateBusinessModelCanvas;
        _this3.loading = false;
      }).catch(function (error) {
        // Error
        _this3.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this3.loading = false;
      });
    },
    retrieveBMC: function retrieveBMC(page_number) {
      var _this4 = this;
      this.loading = true;
      this.business_model = {};
      this.$apollo.query({
        // Query
        query: window._gql(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)([" query BusinessModelCanvases($page: Page)\n        {\n        BusinessModelCanvases(page: $page)\n        {\n          pages\n          total\n          select{\n            additionalattributes\n            channel\n            competitors\n            coststructure\n            createdat\n            createdby\n            customerrelationship\n            customersegment\n            description\n            id\n            keyactivities\n            keyresources\n            modifiedat\n            modifiedby\n            partners\n            revenuestreams\n            title\n            valueproposition\n          }\n        }\n      }"]))),
        fetchPolicy: 'no-cache',
        variables: {
          'page': {
            'limit': 20,
            'start': page_number
          }
        }
      }).then(function (data) {
        _this4.$notify({
          title: 'Success',
          message: 'Retrieved Successfully',
          type: 'success',
          duration: 2000
        });
        if (data.data.BusinessModelCanvases.select.length > 0) {
          _this4.business_model = data.data.BusinessModelCanvases.select[0];
        } else {
          _this4.createBMC();
        }
        _this4.loading = false;
      }).catch(function (error) {
        // Error
        _this4.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this4.loading = false;
      });
    },
    resetDialogs: function resetDialogs() {
      this.partnersDialog = false;
      this.competitorsDialog = false;
      this.keyResourcesDialog = false;
      this.keyActivitiesDialog = false;
      this.valuePropositionDialog = false;
      this.customerRelationsDialog = false;
      this.channelsDialog = false;
      this.customerSegmentsDialog = false;
      this.costStructureDialog = false;
      this.revenueStreamDialog = false;
    }
  }
};