"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.set");
var _toConsumableArray2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/toConsumableArray.js"));
var _default2 = exports.default = {
  name: 'Needs',
  components: {},
  props: {
    customer: {
      type: Object,
      required: true,
      default: function _default() {
        return {
          id: '',
          title: '',
          description: '',
          tiers: '',
          rationale: '',
          priority: '',
          persona: '',
          additionalattributes: {},
          linkedJourneyMaps: []
        };
      }
    },
    journeyMap: {
      type: Object,
      required: true,
      default: function _default() {
        return {
          id: '',
          title: '',
          description: '',
          additionalattributes: {},
          linkedJourneyExperiences: [],
          linkedJourneyPhases: [],
          linkedJourneySteps: []
        };
      }
    },
    offering: {
      type: Object,
      required: true,
      default: function _default() {
        return {
          id: '',
          title: '',
          description: '',
          additionalattributes: {},
          linkedProducts: [],
          linkedServices: []
        };
      }
    }
  },
  data: function data() {
    return {};
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    computeRelatedProductToPhase: function computeRelatedProductToPhase(phase) {
      var products = [];
      for (var i = 0; i < phase.relations.length; i++) {
        var _products;
        (_products = products).push.apply(_products, (0, _toConsumableArray2.default)(this.computeRelatedProductToStep(phase.relations[i])));
      }
      products = (0, _toConsumableArray2.default)(new Set(products));
      return products;
    },
    computeRelatedServiceToPhase: function computeRelatedServiceToPhase(phase) {
      var services = [];
      for (var i = 0; i < phase.relations.length; i++) {
        var _services;
        (_services = services).push.apply(_services, (0, _toConsumableArray2.default)(this.computeRelatedServiceToStep(phase.relations[i])));
      }
      services = (0, _toConsumableArray2.default)(new Set(services));
      return services;
    },
    computeRelatedProductToStep: function computeRelatedProductToStep(step_id) {
      var products = [];
      for (var i = 0; i < this.offering.linkedProducts.length; i++) {
        for (var j = 0; j < this.offering.linkedProducts[i].product.linkedFeatures.length; j++) {
          if (this.offering.linkedProducts[i].product.linkedFeatures[j].productFeature.relations && this.offering.linkedProducts[i].product.linkedFeatures[j].productFeature.relations.steps && this.offering.linkedProducts[i].product.linkedFeatures[j].productFeature.relations.steps.length > 0) {
            for (var k = 0; k < this.offering.linkedProducts[i].product.linkedFeatures[j].productFeature.relations.steps.length; k++) {
              if (this.offering.linkedProducts[i].product.linkedFeatures[j].productFeature.relations.steps[k].id === step_id) {
                if (products.indexOf(this.offering.linkedProducts[i].product.title) === -1) {
                  products.push(this.offering.linkedProducts[i].product.title);
                }
              }
            }
          }
        }
      }
      return products;
    },
    computeRelatedServiceToStep: function computeRelatedServiceToStep(step_id) {
      var services = [];
      for (var i = 0; i < this.offering.linkedServices.length; i++) {
        for (var j = 0; j < this.offering.linkedServices[i].service.linkedServiceApplications.length; j++) {
          if (this.offering.linkedServices[i].service.linkedServiceApplications[j].serviceApplication.relations && this.offering.linkedServices[i].service.linkedServiceApplications[j].serviceApplication.relations.steps && this.offering.linkedServices[i].service.linkedServiceApplications[j].serviceApplication.relations.steps.length > 0) {
            for (var k = 0; k < this.offering.linkedServices[i].service.linkedServiceApplications[j].serviceApplication.relations.steps.length; k++) {
              if (this.offering.linkedServices[i].service.linkedServiceApplications[j].serviceApplication.relations.steps[k].id === step_id) {
                if (!services.indexOf(this.offering.linkedServices[i].service.title) === -1) {
                  services.push(this.offering.linkedServices[i].service.title);
                }
              }
            }
          }
        }
      }
      return services;
    },
    computeStyle: function computeStyle() {
      return 'background-color:lightslategray';
    },
    computeExperienceForPhase: function computeExperienceForPhase(phase) {
      var experiences = [];
      for (var i = 0; i < this.journeyMap.linkedJourneyExperiences.length; i++) {
        if (this.journeyMap.linkedJourneyExperiences[i].journeyExperience.relations && this.journeyMap.linkedJourneyExperiences[i].journeyExperience.relations.phase && this.journeyMap.linkedJourneyExperiences[i].journeyExperience.relations.phase === phase.id) {
          experiences.push(this.journeyMap.linkedJourneyExperiences[i]);
        }
      }
      return experiences;
    },
    computeExperienceForStep: function computeExperienceForStep(step) {
      var experiences = [];
      for (var i = 0; i < this.journeyMap.linkedJourneyExperiences.length; i++) {
        if (this.journeyMap.linkedJourneyExperiences[i].journeyExperience.relations && this.journeyMap.linkedJourneyExperiences[i].journeyExperience.relations.step && this.journeyMap.linkedJourneyExperiences[i].journeyExperience.relations.step === step.id) {
          experiences.push(this.journeyMap.linkedJourneyExperiences[i]);
        }
      }
      return experiences;
    },
    computeRelatedSteps: function computeRelatedSteps(phase) {
      var step_ids = phase.relations;
      var steps = [];
      for (var i = 0; i < this.journeyMap.linkedJourneySteps.length; i++) {
        if (step_ids.indexOf(this.journeyMap.linkedJourneySteps[i].journeyStep.id) > -1) {
          steps.push(this.journeyMap.linkedJourneySteps[i]);
        }
      }
      return steps;
    }
  }
};