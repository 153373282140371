"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.array.fill");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("g", [_c("filter", {
    attrs: {
      id: "shadow",
      "color-interpolation-filters": "sRGB"
    }
  }, [_c("feDropShadow", {
    attrs: {
      dx: "2",
      gy: "2",
      "std-deviation": "3",
      "flood-opacity": "0.3",
      "flood-color": "lightslategrey"
    }
  })], 1), _vm._v(" "), _c("rect", {
    attrs: {
      x: _vm.x + 142,
      y: _vm.y,
      rx: "0",
      width: _vm.width - 142,
      height: _vm.height,
      fill: "white",
      "stroke-width": "1",
      stroke: "#eee"
    },
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.resetSettingsAndMenu();
      }
    }
  }), _vm._v(" "), !_vm.isMinimized ? _c("g", {
    attrs: {
      refs: "model-offering-product-workspace-matrix-grid"
    }
  }, _vm._l(_vm.computeTotalGridLanes, function (y_datum, y_index) {
    return _c("g", {
      key: "y_" + y_index,
      attrs: {
        refs: "y-axis"
      }
    }, _vm._l(100, function (x_datum, x_index) {
      return _c("g", {
        key: "x_" + x_index,
        attrs: {
          refs: "x-axis"
        }
      }, [_c("path", {
        attrs: {
          d: _vm.generateDiamondPath(120 + x_datum * 50, _vm.y + (y_datum - 1) * 50 - 1),
          "stroke-opacity": "0.5",
          stroke: "#ccc",
          "stroke-width": "1",
          fill: "none"
        }
      })]);
    }), 0);
  }), 0) : _vm._e(), _vm._v(" "), _c("g", {
    attrs: {
      refs: "model-offering-product-workspace-item-header"
    },
    on: {
      click: function click($event) {
        return _vm.resetSettingsAndMenu();
      }
    }
  }, [_c("rect", {
    attrs: {
      x: "41",
      y: _vm.y + 22,
      rx: "0",
      fill: "rgba(200,200,200,0.1)",
      width: "104",
      height: _vm.height - 22,
      "stroke-width": "1",
      stroke: "#f1f4ff"
    }
  }), _vm._v(" "), _c("g", {
    attrs: {
      refs: "model-offering-product-workspace-item-header-tools"
    }
  }, [_c("foreignObject", {
    directives: [{
      name: "dragged",
      rawName: "v-dragged.prevent",
      value: _vm.onFeatureToolDragged,
      expression: "onFeatureToolDragged",
      modifiers: {
        prevent: true
      }
    }],
    attrs: {
      id: _vm.generateUniqueProductToolID(_vm.index),
      x: _vm.tools.feature.x,
      y: _vm.tools.feature.y,
      width: 40,
      height: 40,
      fill: "none"
    }
  }, [!_vm.isMinimized ? _c("div", {
    staticClass: "ujm-swimlane-resize-icon",
    staticStyle: {
      "padding-left": "5px",
      "padding-top": "5px"
    }
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "29px",
      color: "#304174"
    },
    attrs: {
      "icon-class": "feature"
    }
  })], 1) : _vm._e()]), _vm._v(" "), _c("foreignObject", {
    attrs: {
      x: _vm.tools.idea.x,
      y: _vm.tools.idea.y,
      width: 40,
      height: 40,
      fill: "none"
    },
    on: {
      click: function click($event) {
        return _vm.createProductIdea();
      }
    }
  }, [!_vm.isMinimized ? _c("div", {
    staticClass: "ujm-swimlane-resize-icon"
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "28px",
      color: "#304174"
    },
    attrs: {
      "icon-class": "ideas"
    }
  })], 1) : _vm._e()]), _vm._v(" "), _vm.hasPermissionForModule("Risk Managment") ? _c("foreignObject", {
    directives: [{
      name: "dragged",
      rawName: "v-dragged.prevent",
      value: _vm.onProductRiskToolDragged,
      expression: "onProductRiskToolDragged",
      modifiers: {
        prevent: true
      }
    }],
    attrs: {
      id: _vm.generateUniqueProductToolID(_vm.index),
      x: _vm.tools.risk.x,
      y: _vm.tools.risk.y,
      width: 40,
      height: 40,
      fill: "none"
    }
  }, [!_vm.isMinimized ? _c("div", {
    staticClass: "ujm-swimlane-resize-icon",
    staticStyle: {
      "padding-left": "8px",
      "padding-top": "6px"
    }
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "24px",
      color: "#F56C6C"
    },
    attrs: {
      "icon-class": "fire"
    }
  })], 1) : _vm._e()]) : _vm._e()]), _vm._v(" "), _c("rect", {
    attrs: {
      x: 5,
      y: _vm.y,
      width: "35",
      height: _vm.height,
      fill: "rgba(200,200,200,0.35)",
      "stroke-width": "1",
      stroke: "#eee"
    }
  }), _vm._v(" "), _c("rect", {
    attrs: {
      x: 5,
      y: _vm.y,
      rx: "3",
      width: "140",
      height: 22,
      fill: "#305497",
      "stroke-width": "1",
      stroke: "#305497"
    }
  }), _vm._v(" "), !_vm.isMinimized ? _c("g", {
    attrs: {
      refs: "product-settings-tool"
    }
  }, [_c("foreignObject", {
    attrs: {
      x: 17,
      y: _vm.y + 30,
      width: 15,
      height: 15
    }
  }, [_c("div", [_c("svg-icon", {
    staticStyle: {
      "font-size": "10px",
      color: "#304174"
    },
    attrs: {
      "icon-class": "gear"
    },
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.displayProductSetting();
      }
    }
  })], 1)]), _vm._v(" "), _c("foreignObject", {
    attrs: {
      x: 17,
      y: _vm.y + 50,
      width: 15,
      height: 15
    }
  }, [_c("div", [_c("i", {
    staticClass: "el-icon-video-play",
    staticStyle: {
      "font-size": "10px",
      color: "#304174",
      transform: "rotate(270deg)"
    },
    on: {
      click: _vm.moveUpEntitySortOrder
    }
  })])]), _vm._v(" "), _c("foreignObject", {
    attrs: {
      x: 17,
      y: _vm.y + 70,
      width: 15,
      height: 15
    }
  }, [_c("div", [_c("i", {
    staticClass: "el-icon-video-play",
    staticStyle: {
      "font-size": "10px",
      color: "#304174",
      transform: "rotate(90deg)"
    },
    on: {
      click: _vm.moveDownEntitySortOrder
    }
  })])])]) : _vm._e(), _vm._v(" "), _c("foreignObject", {
    attrs: {
      x: 13,
      y: _vm.y + 2,
      width: 20,
      height: 20
    }
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "16px",
      color: "#fff"
    },
    attrs: {
      "icon-class": "pdg-product"
    }
  })], 1), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "#fff",
      "font-weight": "400",
      "font-variant-caps": "petite-caps"
    },
    attrs: {
      x: 39,
      y: _vm.y + 14,
      "text-anchor": "start",
      "font-size": "0.9em"
    }
  }, [_vm._v("\n      " + _vm._s(_vm.trimString(_vm.product.title, 20)) + "\n    ")])]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "product-collapse-tool"
    }
  }, [!_vm.isMinimized ? _c("foreignObject", {
    attrs: {
      x: 127,
      y: _vm.y + 5,
      width: 14,
      height: 12
    }
  }, [_c("div", [_c("svg-icon", {
    attrs: {
      "icon-class": "arrow-shrink-w",
      title: "Shrink Lane"
    },
    on: {
      click: function click($event) {
        return _vm.resizeProductSwimlane("shrink");
      }
    }
  })], 1)]) : _vm._e(), _vm._v(" "), _vm.isMinimized ? _c("foreignObject", {
    attrs: {
      x: 127,
      y: _vm.y + 5,
      width: 14,
      height: 12
    }
  }, [_c("div", [_c("svg-icon", {
    attrs: {
      "icon-class": "arrow-expand-w",
      title: "Expand Lane"
    },
    on: {
      click: function click($event) {
        return _vm.resizeProductSwimlane("expand");
      }
    }
  })], 1)]) : _vm._e()]), _vm._v(" "), !_vm.isMinimized && _vm.productHasUnmappedFeatures ? _c("g", {
    attrs: {
      refs: "model-offering-product-workspace-un-mapped"
    }
  }, [_c("line", {
    attrs: {
      x1: 151,
      y1: _vm.y + 115 - 15 + (_vm.productHasEnablerFeatures ? 50 : 0),
      x2: _vm.width - 10,
      y2: _vm.y + 115 - 15 + (_vm.productHasEnablerFeatures ? 50 : 0),
      stroke: "rgba(200,200,200,0.7)",
      "stroke-width": "1",
      fill: "none"
    }
  }), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "grey",
      "font-weight": "400",
      "font-variant-caps": "petite-caps"
    },
    attrs: {
      x: 151,
      y: _vm.y + 115 - 15 + 14 + (_vm.productHasEnablerFeatures ? 50 : 0),
      "text-anchor": "start",
      "font-size": "0.95em"
    }
  }, [_vm._v("\n      Unmapped\n    ")])]) : _vm._e(), _vm._v(" "), !_vm.isMinimized && _vm.productHasEnablerFeatures ? _c("g", {
    attrs: {
      refs: "model-offering-product-workspace-enabler"
    }
  }, [_c("line", {
    attrs: {
      x1: 151,
      y1: _vm.y + 115 - 15,
      x2: _vm.width - 10,
      y2: _vm.y + 115 - 15,
      stroke: "rgba(200,200,200,0.7)",
      "stroke-width": "1",
      fill: "none"
    }
  }), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "grey",
      "font-weight": "400",
      "font-variant-caps": "petite-caps"
    },
    attrs: {
      x: 151,
      y: _vm.y + 115 - 15 + 14,
      "text-anchor": "start",
      "font-size": "0.95em"
    }
  }, [_vm._v("\n      Enabler\n    ")])]) : _vm._e(), _vm._v(" "), !_vm.isMinimized ? _c("g", {
    attrs: {
      refs: "product-features"
    }
  }, _vm._l(_vm.product.linkedFeatures, function (feature, feature_index) {
    return _c("g", {
      key: "feature_" + feature_index,
      attrs: {
        refs: "product-feature"
      },
      on: {
        mouseover: function mouseover($event) {
          return _vm.onFeatureMouseOver(feature_index, feature.productFeature.id);
        },
        mouseleave: function mouseleave($event) {
          return _vm.onFeatureMouseLeave(feature_index, feature.productFeature.id);
        }
      }
    }, [_vm.isVisible(feature) ? _c("g", [_c("circle", {
      attrs: {
        cx: _vm.computeFeaturePosX(feature_index, feature.productFeature.id) + 17,
        cy: _vm.computeFeaturePosY(feature_index, feature.productFeature.id) + 15,
        r: 12,
        fill: "#304174",
        "stroke-width": "1",
        "stroke-dasharray": _vm.computeFeatureStrokeDasharray(feature),
        stroke: _vm.computeFeatureStrokeColor(feature)
      }
    }), _vm._v(" "), _c("foreignObject", {
      directives: [{
        name: "dragged",
        rawName: "v-dragged.prevent",
        value: _vm.onFeatureDragged,
        expression: "onFeatureDragged",
        modifiers: {
          prevent: true
        }
      }],
      staticClass: "text-center",
      attrs: {
        id: _vm.generateUniqueFeatureID(feature_index),
        x: _vm.computeFeaturePosX(feature_index, feature.productFeature.id),
        y: _vm.computeFeaturePosY(feature_index, feature.productFeature.id),
        width: 38,
        height: 38
      }
    }, [feature.productFeature.additionalattributes.issueSource === "JIRA" ? _c("svg-icon", {
      staticClass: "active",
      staticStyle: {
        position: "fixed",
        left: "25px"
      },
      attrs: {
        "icon-class": "jira-3"
      }
    }) : _vm._e(), _vm._v(" "), _c("svg-icon", {
      staticClass: "active feature_icon",
      style: _vm.computeFeatureIconStyle(feature_index),
      attrs: {
        id: _vm.generateUniqueFeatureID(feature_index),
        "icon-class": "feature"
      },
      on: {
        click: function click($event) {
          $event.preventDefault();
          return _vm.openFeatureDetailDialog(feature, feature_index);
        }
      }
    }), _c("br")], 1), _vm._v(" "), !_vm.isMinimized ? _c("text", {
      attrs: {
        x: _vm.computeFeaturePosX(feature_index, feature.productFeature.id) + 17,
        y: _vm.computeFeaturePosY(feature_index, feature.productFeature.id) + 36,
        "text-anchor": "middle",
        "font-size": ".7em",
        fill: "#4672c4;"
      }
    }, [_c("tspan", {
      attrs: {
        x: _vm.computeFeaturePosX(feature_index, feature.productFeature.id) + 17,
        dy: "0em",
        fill: "#4672c4;"
      }
    }, [_vm._v(_vm._s(_vm.trimFeatureString(feature.productFeature.title, 20)[0]))]), _vm._v(" "), _c("tspan", {
      attrs: {
        x: _vm.computeFeaturePosX(feature_index, feature.productFeature.id) + 17,
        dy: "0.9em",
        fill: "#4672c4;"
      }
    }, [_vm._v(_vm._s(_vm.trimFeatureString(feature.productFeature.title, 20)[1]))])]) : _vm._e()]) : _vm._e()]);
  }), 0) : _vm._e(), _vm._v(" "), _vm.drop_hover_grid.hover ? _c("g", {
    attrs: {
      refs: "hover_highlight"
    }
  }, [_c("rect", {
    staticClass: "gaphighlighter-anim",
    attrs: {
      x: _vm.drop_hover_grid.x,
      y: _vm.drop_hover_grid.y,
      rx: "5",
      ry: "5",
      width: "50",
      height: "50",
      fill: _vm.drop_hover_grid.fill,
      "fill-opacity": "0.5",
      "stroke-dasharray": "5,5",
      stroke: _vm.drop_hover_grid.stroke,
      "stroke-width": 1,
      "stroke-opacity": "0.9"
    }
  })]) : _vm._e()]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;