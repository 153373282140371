"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "sidebar-logo-container",
    class: {
      collapse: _vm.collapse
    }
  }, [_c("transition", {
    attrs: {
      name: "sidebarLogoFade"
    }
  }, [_vm.collapse ? _c("router-link", {
    key: "collapse",
    staticClass: "sidebar-logo-link",
    attrs: {
      to: "/"
    }
  }, [_c("svg-icon", {
    staticClass: "spinit-back",
    staticStyle: {
      "vertical-align": "middle",
      "font-size": "14pt"
    },
    attrs: {
      "icon-class": "prodiggi-logo-cl"
    }
  })], 1) : _c("router-link", {
    key: "expand",
    staticClass: "sidebar-logo-link",
    attrs: {
      to: "/"
    }
  }, [_c("svg-icon", {
    directives: [{
      name: "popover",
      rawName: "v-popover:versionPop",
      arg: "versionPop"
    }],
    staticClass: "spinit",
    staticStyle: {
      "vertical-align": "middle",
      "font-size": "14pt"
    },
    attrs: {
      "icon-class": "prodiggi-logo-cl"
    }
  }), _vm._v(" "), _c("el-popover", {
    ref: "versionPop",
    attrs: {
      placement: "right",
      width: "180",
      trigger: "hover",
      "open-delay": 400,
      "close-delay": 400
    }
  }, [_c("table", {
    attrs: {
      id: "build_details"
    }
  }, [_c("tr", [_c("th"), _vm._v(" "), _c("th", [_vm._v("Version")]), _vm._v(" "), _c("th", [_vm._v("Date")])]), _vm._v(" "), _c("tr", [_c("td", [_vm._v("Frontend")]), _vm._v(" "), _c("td", [_c("el-tag", {
    attrs: {
      size: "mini"
    }
  }, [_vm._v(_vm._s(_vm.frontend_build_no))])], 1), _vm._v(" "), _c("td", [_vm._v(_vm._s(_vm.frontend_build_date))])]), _vm._v(" "), _c("tr", [_c("td", [_vm._v("Backend")]), _vm._v(" "), _c("td", [_c("el-tag", {
    attrs: {
      size: "mini"
    }
  }, [_vm._v(_vm._s(_vm.backend_build_no))])], 1), _vm._v(" "), _c("td", [_vm._v(_vm._s(_vm.backend_build_date))])])])]), _vm._v(" "), _c("h1", {
    staticClass: "sidebar-title",
    staticStyle: {
      "font-variant-caps": "petite-caps",
      "font-size": "14pt"
    }
  }, [_vm._v(_vm._s(_vm.title))])], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;