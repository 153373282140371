"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.search");
require("core-js/modules/es6.number.constructor");
var _Search = _interopRequireDefault(require("@/components/Search"));
var _fuse = _interopRequireDefault(require("fuse.js"));
var _default2 = exports.default = {
  name: 'ListItems',
  components: {
    Search: _Search.default
  },
  props: {
    title: {
      type: String,
      required: true,
      default: 'Item'
    },
    createTitle: {
      type: String,
      default: ''
    },
    total: {
      type: Number,
      default: 0
    },
    pageSize: {
      type: Number,
      default: 8
    },
    createItem: {
      type: Boolean,
      default: true
    },
    fuzzySearch: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      required: true,
      default: function _default() {
        return [];
      }
    },
    selectedItem: {
      type: Object,
      required: true,
      default: function _default() {
        return {
          id: ''
        };
      }
    }
  },
  data: function data() {
    return {
      userSelectedItem: {},
      search_term: '',
      searchPool: [],
      fuse: undefined
    };
  },
  computed: {
    filteredItems: function filteredItems() {
      if (this.fuzzySearch) {
        if (this.search_term !== '') {
          return this.fuse.search(this.search_term);
        } else {
          return this.items;
        }
      } else {
        var self = this;
        return this.items.filter(function (item) {
          return item.title.toLowerCase().indexOf(self.search_term.toLowerCase()) > -1;
        });
      }
    }
  },
  watch: {
    searchPool: function searchPool(list) {
      this.initFuse(list);
    }
  },
  mounted: function mounted() {
    this.searchPool = this.items;
  },
  methods: {
    initFuse: function initFuse(list) {
      this.fuse = new _fuse.default(list, {
        shouldSort: true,
        threshold: 0.4,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: [{
          name: 'title',
          weight: 0.7
        }, {
          name: 'description',
          weight: 0.3
        }]
      });
    },
    loadPage: function loadPage(page_number) {
      this.$emit('load-page', page_number);
    },
    createNewItem: function createNewItem() {
      this.$emit('create');
    },
    truncateDescription: function truncateDescription(description, length) {
      return this._.truncate(description, {
        'length': length,
        'separator': ' '
      });
    },
    searchItem: function searchItem(search_string) {
      this.search_term = search_string;
      this.$emit('search', search_string);
    },
    selectItem: function selectItem(item, index) {
      this.userSelectedItem = item;
      this.$emit('click', item, index);
    }
  }
};