"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/taggedTemplateLiteral.js"));
require("core-js/modules/es6.array.fill");
var _vuedraggable = _interopRequireDefault(require("vuedraggable"));
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12, _templateObject13, _templateObject14, _templateObject15, _templateObject16, _templateObject17, _templateObject18, _templateObject19, _templateObject20;
var _default2 = exports.default = {
  name: 'JobsToBeDone',
  components: {
    draggable: _vuedraggable.default
  },
  props: {
    customer: {
      type: Object,
      required: true,
      default: function _default() {
        return {
          id: '',
          description: '',
          persona: '',
          priority: '',
          rationale: '',
          problemstatement: '',
          valueproposition: '',
          offering: {
            primary: {
              title: 'Primary Offering',
              url: 'http://url'
            },
            secondary: [{
              title: 'Secondary Offering1',
              url: 'http://url'
            }, {
              title: 'Secondary Offering2',
              url: 'http://url'
            }]
          }
        };
      }
    }
  },
  data: function data() {
    var validateTitle = function validateTitle(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter title'));
      } else {
        callback();
      }
    };
    var validateDescription = function validateDescription(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter description'));
      } else {
        callback();
      }
    };
    var validateAction = function validateAction(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter action'));
      } else {
        callback();
      }
    };
    var validateMet = function validateMet(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter met'));
      } else {
        callback();
      }
    };
    var validateValue = function validateValue(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter value'));
      } else {
        callback();
      }
    };
    return {
      stageRules: {
        title: [{
          required: true,
          trigger: 'blur',
          validator: validateTitle
        }, {
          min: 5,
          max: 80,
          message: 'Length should be 5 to 80',
          trigger: 'blur'
        }],
        description: [{
          required: true,
          trigger: 'blur',
          validator: validateDescription
        }]
      },
      stepRules: {
        title: [{
          required: true,
          trigger: 'blur',
          validator: validateTitle
        }, {
          min: 5,
          max: 80,
          message: 'Length should be 5 to 80',
          trigger: 'blur'
        }],
        description: [{
          required: true,
          trigger: 'blur',
          validator: validateDescription
        }]
      },
      needRules: {
        title: [{
          required: true,
          trigger: 'blur',
          validator: validateTitle
        }, {
          min: 5,
          max: 80,
          message: 'Length should be 5 to 80',
          trigger: 'blur'
        }],
        description: [{
          required: true,
          trigger: 'blur',
          validator: validateDescription
        }]
      },
      functionalNeedRules: {
        title: [{
          required: true,
          trigger: 'blur',
          validator: validateTitle
        }, {
          min: 5,
          max: 80,
          message: 'Length should be 5 to 80',
          trigger: 'blur'
        }],
        description: [{
          required: true,
          trigger: 'blur',
          validator: validateDescription
        }],
        action: [{
          required: true,
          trigger: 'blur',
          validator: validateAction
        }],
        met: [{
          required: true,
          trigger: 'blur',
          validator: validateMet
        }],
        value: [{
          required: true,
          trigger: 'blur',
          validator: validateValue
        }]
      },
      loading: false,
      addStageDialog: false,
      addStepDialog: false,
      addEmotionalNeedDialog: false,
      addSocialNeedDialog: false,
      editFunctionalNeedDialog: false,
      addFunctionalActionDialog: false,
      isCollapseAll: false,
      activeStageValues: [],
      activeStageIndex: 0,
      activeStepIndex: 0,
      activeNeedIndex: 0,
      functionalGoalCollapser: ['1'],
      functional_need_form: {
        title: '',
        description: '',
        action: '',
        met: '',
        value: ''
      },
      need_form: {
        title: '',
        description: ''
      },
      stage_form: {
        title: '',
        description: '',
        steps: []
      },
      step_form: {
        title: '',
        description: '',
        actions: []
      },
      activeStage: {},
      activeStep: {},
      needs: {
        functional: {},
        emotional: {
          id: '',
          linkedNeeds: []
        },
        social: {
          id: '',
          linkedNeeds: []
        }
      }
    };
  },
  computed: {},
  watch: {},
  created: function created() {},
  mounted: function mounted() {
    this.$eventHub.$on('customer-types-changed', this.retrieveCustomerTypeNeeds);
    this.retrieveNeeds(1);
  },
  methods: {
    trimString: function trimString(string, length) {
      if (string.length > length) {
        return string.substring(0, length) + '...';
      } else {
        return string;
      }
    },
    startDrag: function startDrag(evt, originalEvent) {
      this.activeStageValues = [];
      return false;
    },
    goTo: function goTo(page) {
      this.$emit('goTo', page);
    },
    retrieveCustomerTypeNeeds: function retrieveCustomerTypeNeeds() {
      this.retrieveNeeds(1);
    },
    openAddEmotionalNeedDialog: function openAddEmotionalNeedDialog() {
      this.resetNeedForm();
      this.addEmotionalNeedDialog = true;
    },
    openAddSocialNeedDialog: function openAddSocialNeedDialog() {
      this.resetNeedForm();
      this.addSocialNeedDialog = true;
    },
    retrieveStageIds: function retrieveStageIds() {
      var sortedArr = [];
      for (var i = 0; i < this.needs.functional.linkedStages.length; i++) {
        sortedArr.push(this.needs.functional.linkedStages[i].stage.id);
      }
      return sortedArr;
    },
    handleStageChange: function handleStageChange() {},
    onStageChange: function onStageChange(type) {
      this.updateStageSortPosition(this.retrieveStageIds());
    },
    inputStageChanged: function inputStageChanged(value) {
      this.activeStage = value;
    },
    getStageComponentData: function getStageComponentData() {
      return {
        on: {
          change: this.handleStageChange,
          input: this.inputStageChanged,
          move: false,
          start: false
        },
        attrs: {
          wrap: true
        },
        props: {
          value: this.isCollapseAll ? [] : Array(this.needs.functional.linkedStages.length).fill(null).map(function (_, i) {
            return i;
          }) // [0, 1, 2] - index of all elements in stages array(stage name is defined as index)]
          // value: this.activeStageValues
        }
      };
    },
    handleStepChange: function handleStepChange() {
      console.log('changed');
    },
    inputStepChanged: function inputStepChanged(value) {
      this.activeStep = value;
    },
    getStepComponentData: function getStepComponentData(steps) {
      return {
        on: {
          change: this.handleStepChange,
          input: this.inputStepChanged
        },
        attrs: {
          wrap: true
        },
        props: {
          value: Array(steps.length).fill(null).map(function (_, i) {
            return i;
          })
        }
      };
    },
    createFunctionalGoal: function createFunctionalGoal() {
      var _this = this;
      this.loading = true;
      this.$apollo.mutate({
        // Query
        mutation: window._gql(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["mutation createNeedDefinition($needDefinition: CreateNeedDefinitionInput!)\n        {\n          createNeedDefinition(input: $needDefinition) {\n            id\n          }\n        }"]))),
        // Parameters
        variables: {
          'needDefinition': {
            'customerTypeId': this.$store.getters.customerType.id,
            'title': '',
            'description': '',
            'goal': this.needs.functional.goal,
            'needType': 'functional'
          }
        }
      }).then(function (data) {
        _this.$notify({
          title: 'Success',
          message: 'Created Successfully',
          type: 'success',
          duration: 2000
        });
        _this.retrieveNeeds(1);
        _this.loading = false;
      }).catch(function (error) {
        // Error
        _this.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this.loading = false;
      });
    },
    createEmotionalGoal: function createEmotionalGoal() {
      var _this2 = this;
      this.loading = true;
      this.$apollo.mutate({
        // Query
        mutation: window._gql(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["mutation createNeedDefinition($needDefinition: CreateNeedDefinitionInput!)\n        {\n          createNeedDefinition(input: $needDefinition) {\n            id\n          }\n        }"]))),
        // Parameters
        variables: {
          'needDefinition': {
            'customerTypeId': this.$store.getters.customerType.id,
            'title': '',
            'description': '',
            'goal': '',
            'needType': 'emotional'
          }
        }
      }).then(function (data) {
        // No need to retrieve all the data again for now.
        // this.retrieveNeeds(1)

        // Just attach ID to data
        _this2.needs.emotional.id = data.data.createNeedDefinition.id;
        _this2.loading = false;
      }).catch(function (error) {
        // Error
        _this2.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this2.loading = false;
      });
    },
    createSocialGoal: function createSocialGoal() {
      var _this3 = this;
      this.loading = true;
      this.$apollo.mutate({
        // Query
        mutation: window._gql(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["mutation createNeedDefinition($needDefinition: CreateNeedDefinitionInput!)\n        {\n          createNeedDefinition(input: $needDefinition) {\n            id\n          }\n        }"]))),
        // Parameters
        variables: {
          'needDefinition': {
            'customerTypeId': this.$store.getters.customerType.id,
            'title': '',
            'description': '',
            'goal': '',
            'needType': 'social'
          }
        }
      }).then(function (data) {
        // No need to retrieve all the data again for now.
        // this.retrieveNeeds(1)

        // Just attach ID to data
        _this3.needs.social.id = data.data.createNeedDefinition.id;
        _this3.loading = false;
      }).catch(function (error) {
        // Error
        _this3.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this3.loading = false;
      });
    },
    updateFunctionalGoal: function updateFunctionalGoal() {
      var _this4 = this;
      this.loading = true;
      this.$apollo.mutate({
        // Query
        mutation: window._gql(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2.default)(["mutation updateNeedDefinition($needDefinition: UpdateNeedDefinitionInput!)\n        {\n          updateNeedDefinition(input: $needDefinition) {\n            id\n          }\n        }"]))),
        // Parameters
        variables: {
          'needDefinition': {
            'id': this.needs.functional.id,
            'title': '',
            'description': '',
            'goal': this.needs.functional.goal
          }
        }
      }).then(function (data) {
        _this4.$notify({
          title: 'Success',
          message: 'Updated Successfully',
          type: 'success',
          duration: 2000
        });
        _this4.loading = false;
      }).catch(function (error) {
        // Error
        _this4.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this4.loading = false;
      });
    },
    retrieveNeeds: function retrieveNeeds(page_number) {
      var _this5 = this;
      this.loading = true;
      this.$apollo.query({
        // Query
        query: window._gql(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2.default)(["query NeedDefinitions($page: Page, $customertypescriteriaexpression: CustomerTypesCriteriaExpression!)\n        {\n        NeedDefinitions(page: $page)\n        {\n          pages\n          total\n          select{\n            id\n            title\n            description\n            additionalattributes\n            goal\n            needtype\n            linkedCustomerType(optional: true) {\n              customerType(optional: true, where: $customertypescriteriaexpression) {\n                id\n                title\n              }\n            }\n            linkedNeeds(optional: true) {\n              need(optional: true) {\n                id\n                title\n                description\n              }\n            }\n            linkedStages(optional: true) {\n              stage(optional: true) {\n                id\n                title\n                description\n                linkedSteps(optional: true) {\n                  step(optional: true) {\n                    id\n                    title\n                    description\n                    linkedNeeds(optional: true) {\n                      need(optional: true) {\n                        id\n                        title\n                        description\n                        action\n                        met\n                        value\n                      }\n                    }\n                  }\n                }\n              }\n            }\n          }\n        }\n      }"]))),
        fetchPolicy: 'no-cache',
        variables: {
          'page': {
            'limit': 20,
            'start': page_number
          },
          'customertypescriteriaexpression': {
            'id': {
              'EQ': this.$store.getters.customerType.id
            }
          }
        }
      }).then(function (data) {
        var needs_data = data.data.NeedDefinitions.select;
        // Test code to clear out all the needs data
        // this.deleteNeedsDefinitions(needs_data)
        for (var i = 0; i < needs_data.length; i++) {
          if (needs_data[i].needtype === 'functional') {
            _this5.needs.functional = _this5.computeStageSorting(needs_data[i]);
            _this5.activeStageValues = [];
          }
          if (needs_data[i].needtype === 'emotional') {
            _this5.needs.emotional = needs_data[i];
          }
          if (needs_data[i].needtype === 'social') {
            _this5.needs.social = needs_data[i];
          }
        }

        // Validate data
        // If no emotional / social need definiations are found create one
        // Unable to attach needs without need definitions
        if (_this5.needs.emotional.id === '') {
          _this5.createEmotionalGoal();
        }
        if (_this5.needs.social.id === '') {
          _this5.createSocialGoal();
        }
        _this5.loading = false;
      }).catch(function (error) {
        // Error
        _this5.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this5.loading = false;
      });
    },
    /*
    TEST Code for clearning out needs data
    deleteNeedsDefinitions(needs_data) {
      for(var i=0;i<needs_data.length;i++) {
        this.$apollo.mutate({
          // Query
          mutation: window._gql `mutation deleteNeedDefinition($deleteNeedDefinition: DeleteNeedDefinitionInput!)
          {
            deleteNeedDefinition(input: $deleteNeedDefinition) {
              id
            }
          }`,
          // Parameters
          variables: {
            'deleteNeedDefinition': {
              'id': needs_data[i].id
            }
          }
        }).then((data) => {
        }).catch((error) => {
          // Error
          this.$notify({
            title: 'Error',
            message: error,
            type: 'error',
            duration: 2000
          })
        })
      }
    },*/
    openAddStepDialog: function openAddStepDialog(index) {
      this.activeStageIndex = index;
      this.addStepDialog = true;
    },
    addStage: function addStage() {
      var _this6 = this;
      this.$refs.stageForm.validate(function (valid) {
        if (valid) {
          _this6.loading = true;
          _this6.$apollo.mutate({
            // Query
            mutation: window._gql(_templateObject6 || (_templateObject6 = (0, _taggedTemplateLiteral2.default)(["mutation createStage($createStage: CreateStageInput!)\n            {\n              createStage(input: $createStage) {\n                id\n                title\n                description\n                linkedSteps(optional: true) {\n                  step(optional: true) {\n                    id\n                    title\n                    description\n                    linkedNeeds(optional: true) {\n                      need(optional: true) {\n                        id\n                        title\n                        description\n                        action\n                        met\n                        value\n                      }\n                    }\n                  }\n                }\n              }\n            }"]))),
            // Parameters
            variables: {
              'createStage': {
                'needDefinitionId': _this6.needs.functional.id,
                'title': _this6.stage_form.title,
                'description': _this6.stage_form.description
              }
            }
          }).then(function (data) {
            _this6.$notify({
              title: 'Success',
              message: 'Created Successfully',
              type: 'success',
              duration: 2000
            });
            _this6.needs.functional.linkedStages.push({
              stage: data.data.createStage
            });
            _this6.addStageDialog = false;
            _this6.resetStageForm();
            _this6.loading = false;
            _this6.updateStageSortPosition(_this6.retrieveStageIds());
          }).catch(function (error) {
            // Error
            _this6.$notify({
              title: 'Error',
              message: error,
              type: 'error',
              duration: 2000
            });
            _this6.loading = false;
          });
        } else {
          // this.loading = false
          return false;
        }
      });
    },
    addStep: function addStep() {
      var _this7 = this;
      this.$refs.stepForm.validate(function (valid) {
        if (valid) {
          _this7.loading = true;
          _this7.$apollo.mutate({
            // Query
            mutation: window._gql(_templateObject7 || (_templateObject7 = (0, _taggedTemplateLiteral2.default)(["mutation createStep($createStep: CreateStepInput!)\n            {\n              createStep(input: $createStep) {\n                id\n                title\n                description\n              }\n            }"]))),
            // Parameters
            variables: {
              'createStep': {
                'stageId': _this7.needs.functional.linkedStages[_this7.activeStageIndex].stage.id,
                'title': _this7.step_form.title,
                'description': _this7.step_form.description
              }
            }
          }).then(function (data) {
            _this7.$notify({
              title: 'Success',
              message: 'Created Successfully',
              type: 'success',
              duration: 2000
            });
            var step = data.data.createStep;
            step.linkedNeeds = [];
            _this7.needs.functional.linkedStages[_this7.activeStageIndex].stage.linkedSteps.push({
              step: step
            });
            _this7.addStepDialog = false;
            _this7.resetStepForm();
            _this7.loading = false;
          }).catch(function (error) {
            // Error
            _this7.$notify({
              title: 'Error',
              message: error,
              type: 'error',
              duration: 2000
            });
            _this7.loading = false;
          });
        } else {
          // this.loading = false
          return false;
        }
      });
    },
    addEmotionalNeed: function addEmotionalNeed() {
      var _this8 = this;
      this.$refs.needForm.validate(function (valid) {
        if (valid) {
          _this8.loading = true;
          _this8.$apollo.mutate({
            // Query
            mutation: window._gql(_templateObject8 || (_templateObject8 = (0, _taggedTemplateLiteral2.default)(["mutation createNeed($createNeed: CreateNeedInput!)\n            {\n              createNeed(input: $createNeed) {\n                id\n                title\n                description\n              }\n            }"]))),
            // Parameters
            variables: {
              'createNeed': {
                'needDefinitionId': _this8.needs.emotional.id,
                'title': _this8.need_form.title,
                'description': _this8.need_form.description
              }
            }
          }).then(function (data) {
            _this8.$notify({
              title: 'Success',
              message: 'Created Successfully',
              type: 'success',
              duration: 2000
            });
            _this8.needs.emotional.linkedNeeds.push({
              need: data.data.createNeed
            });
            _this8.addEmotionalNeedDialog = false;
            _this8.resetNeedForm();
            _this8.loading = false;
          }).catch(function (error) {
            // Error
            _this8.$notify({
              title: 'Error',
              message: error,
              type: 'error',
              duration: 2000
            });
            _this8.loading = false;
          });
        } else {
          // this.loading = false
          return false;
        }
      });
    },
    updateEmotionalNeed: function updateEmotionalNeed(index, need) {
      var _this9 = this;
      this.loading = true;
      this.$apollo.mutate({
        // Query
        mutation: window._gql(_templateObject9 || (_templateObject9 = (0, _taggedTemplateLiteral2.default)(["mutation updateNeed($updateNeed: UpdateNeedInput!)\n        {\n          updateNeed(input: $updateNeed) {\n            id\n            title\n            description\n          }\n        }"]))),
        // Parameters
        variables: {
          'updateNeed': {
            'id': need.id,
            'title': need.title,
            'description': need.description
          }
        }
      }).then(function (data) {
        _this9.$notify({
          title: 'Success',
          message: 'Updated Successfully',
          type: 'success',
          duration: 2000
        });
        _this9.loading = false;
      }).catch(function (error) {
        // Error
        _this9.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this9.loading = false;
      });
    },
    addSocialNeed: function addSocialNeed() {
      var _this10 = this;
      this.$refs.needForm.validate(function (valid) {
        if (valid) {
          _this10.loading = true;
          _this10.$apollo.mutate({
            // Query
            mutation: window._gql(_templateObject10 || (_templateObject10 = (0, _taggedTemplateLiteral2.default)(["mutation createNeed($createNeed: CreateNeedInput!)\n            {\n              createNeed(input: $createNeed) {\n                id\n                title\n                description\n              }\n            }"]))),
            // Parameters
            variables: {
              'createNeed': {
                'needDefinitionId': _this10.needs.social.id,
                'title': _this10.need_form.title,
                'description': _this10.need_form.description
              }
            }
          }).then(function (data) {
            _this10.$notify({
              title: 'Success',
              message: 'Created Successfully',
              type: 'success',
              duration: 2000
            });
            _this10.needs.social.linkedNeeds.push({
              need: data.data.createNeed
            });
            _this10.addSocialNeedDialog = false;
            _this10.resetNeedForm();
            _this10.loading = false;
          }).catch(function (error) {
            // Error
            _this10.$notify({
              title: 'Error',
              message: error,
              type: 'error',
              duration: 2000
            });
            _this10.loading = false;
          });
        } else {
          // this.loading = false
          return false;
        }
      });
    },
    updateSocialNeed: function updateSocialNeed(index, need) {
      var _this11 = this;
      this.loading = true;
      this.$apollo.mutate({
        // Query
        mutation: window._gql(_templateObject11 || (_templateObject11 = (0, _taggedTemplateLiteral2.default)(["mutation updateNeed($updateNeed: UpdateNeedInput!)\n        {\n          updateNeed(input: $updateNeed) {\n            id\n            title\n            description\n          }\n        }"]))),
        // Parameters
        variables: {
          'updateNeed': {
            'id': need.id,
            'title': need.title,
            'description': need.description
          }
        }
      }).then(function (data) {
        _this11.$notify({
          title: 'Success',
          message: 'Updated Successfully',
          type: 'success',
          duration: 2000
        });
        _this11.loading = false;
      }).catch(function (error) {
        // Error
        _this11.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this11.loading = false;
      });
    },
    editFunctionalNeed: function editFunctionalNeed(index, subIndex, actionIndex) {
      this.activeStageIndex = index;
      this.activeStepIndex = subIndex;
      this.activeNeedIndex = actionIndex;
      this.functional_need_form = this.needs.functional.linkedStages[index].stage.linkedSteps[subIndex].step.linkedNeeds[actionIndex].need;
      this.editFunctionalNeedDialog = true;
    },
    updateFunctionalNeed: function updateFunctionalNeed() {
      var _this12 = this;
      var need = Object.assign(this.functional_need_form, {});
      this.loading = true;
      this.$apollo.mutate({
        // Query
        mutation: window._gql(_templateObject12 || (_templateObject12 = (0, _taggedTemplateLiteral2.default)(["mutation updateNeed($updateNeed: UpdateNeedInput!)\n        {\n          updateNeed(input: $updateNeed) {\n            id\n            title\n            description\n            action\n            met\n            value\n          }\n        }"]))),
        // Parameters
        variables: {
          'updateNeed': {
            'id': this.needs.functional.linkedStages[this.activeStageIndex].stage.linkedSteps[this.activeStepIndex].step.linkedNeeds[this.activeNeedIndex].need.id,
            'title': this.functional_need_form.title,
            'description': this.functional_need_form.description,
            'action': this.functional_need_form.action,
            'met': this.functional_need_form.met,
            'value': this.functional_need_form.value
          }
        }
      }).then(function (data) {
        _this12.$notify({
          title: 'Success',
          message: 'Created Successfully',
          type: 'success',
          duration: 2000
        });
        _this12.needs.functional.linkedStages[_this12.activeStageIndex].stage.linkedSteps[_this12.activeStepIndex].step.linkedNeeds[_this12.activeNeedIndex].need = need;
        _this12.editFunctionalNeedDialog = false;
        _this12.resetFunctionalNeedForm();
        _this12.loading = false;
      }).catch(function (error) {
        // Error
        _this12.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this12.loading = false;
      });
    },
    addFunctionalAction: function addFunctionalAction(index, subIndex) {
      this.activeStageIndex = index;
      this.activeStepIndex = subIndex;
      this.resetFunctionalNeedForm();
      this.addFunctionalActionDialog = true;
    },
    createFunctionalNeed: function createFunctionalNeed() {
      var _this13 = this;
      this.$refs.functionalNeedForm.validate(function (valid) {
        if (valid) {
          _this13.loading = true;
          _this13.$apollo.mutate({
            // Query
            mutation: window._gql(_templateObject13 || (_templateObject13 = (0, _taggedTemplateLiteral2.default)(["mutation createNeed($createNeed: CreateNeedInput!)\n            {\n              createNeed(input: $createNeed) {\n                id\n                title\n                description\n                action\n                met\n                value\n              }\n            }"]))),
            // Parameters
            variables: {
              'createNeed': {
                'stepId': _this13.needs.functional.linkedStages[_this13.activeStageIndex].stage.linkedSteps[_this13.activeStepIndex].step.id,
                'title': _this13.functional_need_form.title,
                'description': _this13.functional_need_form.description,
                'action': _this13.functional_need_form.action,
                'met': _this13.functional_need_form.met,
                'value': _this13.functional_need_form.value
              }
            }
          }).then(function (data) {
            _this13.$notify({
              title: 'Success',
              message: 'Created Successfully',
              type: 'success',
              duration: 2000
            });
            if (!_this13.needs.functional.linkedStages[_this13.activeStageIndex].stage.linkedSteps[_this13.activeStepIndex].step.linkedNeeds) {
              _this13.needs.functional.linkedStages[_this13.activeStageIndex].stage.linkedSteps[_this13.activeStepIndex].step.linkedNeeds = [];
            }
            _this13.needs.functional.linkedStages[_this13.activeStageIndex].stage.linkedSteps[_this13.activeStepIndex].step.linkedNeeds.push({
              need: data.data.createNeed
            });
            _this13.addFunctionalActionDialog = false;
            _this13.resetFunctionalNeedForm();
            _this13.loading = false;
          }).catch(function (error) {
            // Error
            _this13.$notify({
              title: 'Error',
              message: error,
              type: 'error',
              duration: 2000
            });
            _this13.loading = false;
          });
        } else {
          // this.loading = false
          return false;
        }
      });
    },
    deleteStage: function deleteStage(index, id) {
      var _this14 = this;
      this.$confirm('This will permanently delete the stage. Continue?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(function () {
        _this14.loading = true;
        _this14.$apollo.mutate({
          // Query
          mutation: window._gql(_templateObject14 || (_templateObject14 = (0, _taggedTemplateLiteral2.default)(["mutation deleteStage($deleteStage: DeleteStageInput!)\n          {\n            deleteStage(input: $deleteStage) {\n              id\n            }\n          }"]))),
          // Parameters
          variables: {
            'deleteStage': {
              'id': id
            }
          }
        }).then(function (data) {
          _this14.needs.functional.linkedStages.splice(index, 1);
          _this14.deleteStageSortPosition(_this14.retrieveStageIds());
          _this14.loading = false;
        }).catch(function (error) {
          // Error
          _this14.$notify({
            title: 'Error',
            message: error,
            type: 'error',
            duration: 2000
          });
          _this14.loading = false;
        });
      }).catch(function () {
        _this14.$message({
          type: 'info',
          message: 'Delete canceled'
        });
      });
    },
    deleteStep: function deleteStep(index, subIndex, id) {
      var _this15 = this;
      this.$confirm('This will permanently delete the step. Continue?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(function () {
        _this15.loading = true;
        _this15.$apollo.mutate({
          // Query
          mutation: window._gql(_templateObject15 || (_templateObject15 = (0, _taggedTemplateLiteral2.default)(["mutation deleteStep($deleteStep: DeleteStepInput!)\n          {\n            deleteStep(input: $deleteStep) {\n              id\n            }\n          }"]))),
          // Parameters
          variables: {
            'deleteStep': {
              'id': id
            }
          }
        }).then(function (data) {
          _this15.needs.functional.linkedStages[index].stage.linkedSteps.splice(subIndex, 1);
          _this15.loading = false;
        }).catch(function (error) {
          // Error
          _this15.$notify({
            title: 'Error',
            message: error,
            type: 'error',
            duration: 2000
          });
          _this15.loading = false;
        });
      }).catch(function () {
        _this15.$message({
          type: 'info',
          message: 'Delete canceled'
        });
      });
    },
    deleteAction: function deleteAction(index, subIndex, actionIndex, id) {
      var _this16 = this;
      this.$confirm('This will permanently delete the action. Continue?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(function () {
        _this16.loading = true;
        _this16.$apollo.mutate({
          // Query
          mutation: window._gql(_templateObject16 || (_templateObject16 = (0, _taggedTemplateLiteral2.default)(["mutation deleteNeed($deleteNeed: DeleteNeedInput!)\n          {\n            deleteNeed(input: $deleteNeed) {\n              id\n            }\n          }"]))),
          // Parameters
          variables: {
            'deleteNeed': {
              'id': id
            }
          }
        }).then(function (data) {
          _this16.needs.functional.linkedStages[index].stage.linkedSteps[subIndex].step.linkedNeeds.splice(actionIndex, 1);
          _this16.loading = false;
        }).catch(function (error) {
          // Error
          _this16.$notify({
            title: 'Error',
            message: error,
            type: 'error',
            duration: 2000
          });
          _this16.loading = false;
        });
      }).catch(function () {
        _this16.$message({
          type: 'info',
          message: 'Delete canceled'
        });
      });
    },
    deleteEmotionalNeed: function deleteEmotionalNeed(index, id) {
      var _this17 = this;
      this.$confirm('This will permanently delete the need. Continue?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(function () {
        _this17.loading = true;
        _this17.$apollo.mutate({
          // Query
          mutation: window._gql(_templateObject17 || (_templateObject17 = (0, _taggedTemplateLiteral2.default)(["mutation deleteNeed($deleteNeed: DeleteNeedInput!)\n          {\n            deleteNeed(input: $deleteNeed) {\n              id\n            }\n          }"]))),
          // Parameters
          variables: {
            'deleteNeed': {
              'id': id
            }
          }
        }).then(function (data) {
          _this17.needs.emotional.linkedNeeds.splice(index, 1);
          _this17.loading = false;
        }).catch(function (error) {
          // Error
          _this17.$notify({
            title: 'Error',
            message: error,
            type: 'error',
            duration: 2000
          });
          _this17.loading = false;
        });
      }).catch(function () {
        _this17.$message({
          type: 'info',
          message: 'Delete canceled'
        });
      });
    },
    deleteSocialNeed: function deleteSocialNeed(index, id) {
      var _this18 = this;
      this.$confirm('This will permanently delete the need. Continue?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(function () {
        _this18.loading = true;
        _this18.$apollo.mutate({
          // Query
          mutation: window._gql(_templateObject18 || (_templateObject18 = (0, _taggedTemplateLiteral2.default)(["mutation deleteNeed($deleteNeed: DeleteNeedInput!)\n          {\n            deleteNeed(input: $deleteNeed) {\n              id\n            }\n          }"]))),
          // Parameters
          variables: {
            'deleteNeed': {
              'id': id
            }
          }
        }).then(function (data) {
          _this18.needs.social.linkedNeeds.splice(index, 1);
          _this18.loading = false;
        }).catch(function (error) {
          // Error
          _this18.$notify({
            title: 'Error',
            message: error,
            type: 'error',
            duration: 2000
          });
          _this18.loading = false;
        });
      }).catch(function () {
        _this18.$message({
          type: 'info',
          message: 'Delete canceled'
        });
      });
    },
    updateStageSortPosition: function updateStageSortPosition(stageSortOrder) {
      var _this19 = this;
      this.loading = true;
      this.$apollo.mutate({
        // Query
        mutation: window._gql(_templateObject19 || (_templateObject19 = (0, _taggedTemplateLiteral2.default)(["mutation updateNeedDefinition($updateNeedDefinition: UpdateNeedDefinitionInput!)\n        {\n          updateNeedDefinition(input: $updateNeedDefinition) {\n            id\n          }\n        }"]))),
        // Parameters
        variables: {
          'updateNeedDefinition': {
            'id': this.needs.functional.id,
            'title': this.needs.functional.title,
            'description': this.needs.functional.description,
            'additionalattributes': {
              'stageSortOrder': stageSortOrder
            }
          }
        }
      }).then(function (data) {
        _this19.loading = false;
        _this19.retrieveNeeds(1);
      }).catch(function (error) {
        // Error
        _this19.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this19.loading = false;
      });
    },
    deleteStageSortPosition: function deleteStageSortPosition(stageSortOrder) {
      var _this20 = this;
      this.loading = true;
      this.$apollo.mutate({
        // Query
        mutation: window._gql(_templateObject20 || (_templateObject20 = (0, _taggedTemplateLiteral2.default)(["mutation updateNeedDefinition($updateNeedDefinition: UpdateNeedDefinitionInput!)\n        {\n          updateNeedDefinition(input: $updateNeedDefinition) {\n            id\n          }\n        }"]))),
        // Parameters
        variables: {
          'updateNeedDefinition': {
            'id': this.needs.functional.id,
            'title': this.needs.functional.title,
            'description': this.needs.functional.description,
            'additionalattributes': {
              'stageSortOrder': stageSortOrder
            }
          }
        }
      }).then(function (data) {
        _this20.loading = false;
        _this20.retrieveNeeds(1);
      }).catch(function (error) {
        // Error
        _this20.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this20.loading = false;
      });
    },
    // TO Check
    // Data binding is resetting data
    computeStageSorting: function computeStageSorting(functional_needs) {
      var sorted = [];
      if (functional_needs.linkedStages.length === 0) {
        return functional_needs;
      } else if (functional_needs.additionalattributes && functional_needs.additionalattributes.stageSortOrder.length > 0) {
        for (var i = 0; i < functional_needs.additionalattributes.stageSortOrder.length; i++) {
          for (var j = 0; j < functional_needs.linkedStages.length; j++) {
            if (functional_needs.additionalattributes.stageSortOrder[i] === functional_needs.linkedStages[j].stage.id) {
              sorted.push(functional_needs.linkedStages[j]);
            }
          }
        }
        functional_needs.linkedStages = sorted;
        return functional_needs;
      } else {
        functional_needs.linkedStages = sorted;
        return functional_needs;
      }
    },
    resetStageForm: function resetStageForm() {
      this.stage_form = {
        title: '',
        description: '',
        steps: []
      };
    },
    resetStepForm: function resetStepForm() {
      this.step_form = {
        title: '',
        description: '',
        actions: []
      };
    },
    resetNeedForm: function resetNeedForm() {
      this.need_form = {
        title: '',
        description: ''
      };
    },
    resetFunctionalNeedForm: function resetFunctionalNeedForm() {
      this.functional_need_form = {
        title: '',
        description: '',
        action: '',
        met: '',
        value: ''
      };
    }
  }
};