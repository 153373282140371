"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/taggedTemplateLiteral.js"));
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es7.object.values");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _objectSpread2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/objectSpread2.js"));
require("core-js/modules/es6.number.constructor");
var _interopRequireWildcard2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _vuex = require("vuex");
var _resolution = require("@/utils/resolution");
var _templateObject;
var _default2 = exports.default = {
  name: 'JourneyHorizontal',
  components: {
    PhaseComponent: function PhaseComponent() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("../journey/Phase.vue"));
      });
    },
    TaskComponent: function TaskComponent() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("../journey/Task.vue"));
      });
    },
    TaskGridComponent: function TaskGridComponent() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("../journey/TaskV2.vue"));
      });
    },
    IdeaComponent: function IdeaComponent() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("../journey/Idea.vue"));
      });
    },
    RiskComponent: function RiskComponent() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("../journey/Risk.vue"));
      });
    },
    ExperienceComponent: function ExperienceComponent() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("../journey/Experience.vue"));
      });
    },
    ThoughtsComponent: function ThoughtsComponent() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("../journey/Thoughts.vue"));
      });
    },
    TouchpointsComponent: function TouchpointsComponent() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("../journey/Touchpoints.vue"));
      });
    },
    PhaseToolComponent: function PhaseToolComponent() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("../journey/PhaseTool.vue"));
      });
    },
    PhasePopoverComponent: function PhasePopoverComponent() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("../journey/PhasePopover.vue"));
      });
    },
    TaskPopoverComponent: function TaskPopoverComponent() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("../journey/TaskPopover.vue"));
      });
    },
    TouchpointPopoverComponent: function TouchpointPopoverComponent() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("../journey/TouchpointPopover.vue"));
      });
    },
    RelationsComponent: function RelationsComponent() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("../journey-layouts/Horizontal/Relations.vue"));
      });
    }
  },
  props: {
    customer: {
      type: Object,
      required: true,
      default: function _default() {
        return {
          id: '',
          title: '',
          description: '',
          tiers: '',
          rationale: '',
          priority: '',
          persona: '',
          additionalattributes: {},
          linkedJourneyMaps: []
        };
      }
    },
    journeyZoom: {
      type: Number,
      default: 0
    },
    isThoughtLaneVisible: {
      type: Boolean,
      default: true
    },
    isTouchPointLaneVisible: {
      type: Boolean,
      default: true
    },
    isEntityPopoverVisible: {
      type: Boolean,
      default: true
    },
    resetLayout: {
      type: Number,
      default: 0
    },
    stepLayout: {
      type: String,
      default: 'horizontal'
    }
  },
  data: function data() {
    return {
      // Canvas
      relationsUpdate: false,
      canvasDragCoefficientX: 0,
      layout: {
        phase: {
          x: 0,
          y: 0,
          width: 0,
          height: 0
        },
        step: {
          x: 5,
          y: 100,
          width: 0,
          height: 260
        }
      },
      toolbox: {
        phase: {
          x: 50,
          y: 38
        },
        task: {
          x: 55,
          y: 130
        },
        menu_phase: {
          x: 160,
          y: 2
        },
        menu_task: {
          x: 260,
          y: 2
        },
        idea: {
          x: 360,
          y: 2
        },
        risk: {
          x: 460,
          y: 2
        }
      },
      // JourneyMap
      phaseStepRelation: [],
      isJourneyHighlight: false,
      isCompactMenubar: false,
      selectedEntityId: null,
      fetchTimeStamp: '',
      timer: null,
      // Phase
      selectedPhaseIndex: null,
      selectedPhase: {},
      phaseToolHover: false,
      addPhaseDialog: false,
      isPhaseEdit: false,
      isPhaseFocusMode: false,
      phaseHover: false,
      phaseLongPress: false,
      phaseGapHighlighter: [],
      phaseHighlighter: [],
      activePhaseGapHighlighter: {
        x: 0,
        y: 0,
        width: 0,
        height: 0
      },
      activePhaseHighlighter: {
        x: 0,
        y: 0,
        width: 0,
        height: 0
      },
      phase_pos_x: [],
      phase_length: [],
      phaseDropDetails: {
        index: 0,
        isBefore: false // Only used to insert before first phase
      },
      phaseRelationTree: [],
      // Step
      selectedTaskIndex: null,
      selectedTask: {},
      taskToolHover: false,
      taskHover: false,
      task_pos_x: [],
      task_pos_y: [],
      relationsHoverTaskID: '',
      tempTaskRelationPath: {
        x1: 0,
        y1: 0,
        x2: 0,
        y2: 0
      },
      // Experience
      experienceHover: false,
      isExperienceEdit: false,
      selectedExperienceIndex: null,
      selectedExperience: {},
      experience_pos_x: [],
      experience_pos_y: [],
      // Idea
      ideaToolHover: false,
      taskHoverID: '',
      phaseHoverID: '',
      // Risk
      riskToolHover: false,
      // Touchpoint
      selectedTouchpointIndex: null,
      touchpointHover: false,
      selectedTouchpoint: {},
      selectedTouchpointPos: {
        x: 0,
        y: 0
      },
      refreshKey: 0
    };
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['journeyMap', 'journeyMapFetchStatus', 'modules'])), {}, {
    computeExperienceTitleTransform: function computeExperienceTitleTransform() {
      return this.isCompactMenubar ? 'rotate(270, 20, ' + (this.layout.step.height + 255) + ')' : '';
    },
    // number of steps per column.
    // Dynamically handled to work with flexible step lane height
    computedTasksPerColumn: function computedTasksPerColumn() {
      return Math.round(this.layout.step.height / 40);
    },
    // Matrix grid computation
    computedGridRows: function computedGridRows() {
      return Math.round(this.layout.step.height / 25);
    },
    computeScreenWidth: function computeScreenWidth() {
      return (0, _resolution.resolution)()[0];
    },
    computedStepRelations: function computedStepRelations() {
      var step_relations = [];
      if (!this.journeyMap.additionalattributes.step_relations) {
        return step_relations;
      }
      for (var i = 0; i < this.journeyMap.additionalattributes.step_relations.length; i++) {
        if (!this.journeyMap.additionalattributes.step_relations[i].isDeleted) {
          step_relations.push(this.journeyMap.additionalattributes.step_relations[i]);
        }
      }
      return step_relations;
    },
    computedRelations: function computedRelations() {
      var relations = [];
      for (var i = 0; i < this.journeyMap.additionalattributes.relations.length; i++) {
        if (!this.journeyMap.additionalattributes.relations[i].isDeleted) {
          relations.push(this.journeyMap.additionalattributes.relations[i]);
        }
      }
      return relations;
    },
    sortedStages: function sortedStages() {
      var stagesSortOrder = [];
      if (this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages.length > 0 && this.journeyMap.linkedJourneyPhases.length > 0) {
        for (var i = 0; i < this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages.length; i++) {
          for (var j = 0; j < this.journeyMap.linkedJourneyPhases.length; j++) {
            if (this.journeyMap.linkedJourneyPhases[j].journeyPhase.id === this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[i].id && !this.journeyMap.linkedJourneyPhases[j].journeyPhase.isDeleted) {
              stagesSortOrder.push(this.journeyMap.linkedJourneyPhases[j]);
              break;
            }
          }
        }
      } else {
        for (var k = 0; k < this.journeyMap.linkedJourneyPhases.length; k++) {
          if (!this.journeyMap.linkedJourneyPhases[j].journeyPhase.hasOwnProperty('isDeleted')) {
            stagesSortOrder.push(this.journeyMap.linkedJourneyPhases[j]);
          } else if (this.journeyMap.linkedJourneyPhases[j].journeyPhase.hasOwnProperty('isDeleted') && this.journeyMap.linkedJourneyPhases[j].journeyPhase.isDeleted === false) {
            stagesSortOrder.push(this.journeyMap.linkedJourneyPhases[j]);
            break;
          }
        }
      }
      return stagesSortOrder;
    },
    computedTaskList: function computedTaskList() {
      this.refreshKey;
      if (this.phaseStepRelation.length === 0) {
        this.computePhaseStepRelationArray();
      }
      var stepList = [];
      if (this.journeyMap && this.journeyMap.linkedJourneySteps) {
        for (var i = 0; i < this.journeyMap.linkedJourneySteps.length; i++) {
          if (this.computeJourneyStepDisplayCondition(this.journeyMap.linkedJourneySteps[i], i)) {
            stepList.push(this.journeyMap.linkedJourneySteps[i]);
          }
        }
      }
      return stepList;
    },
    computedExperienceList: function computedExperienceList() {
      this.refreshKey;
      var experienceList = [];
      if (this.journeyMap && this.journeyMap.linkedJourneyExperiences) {
        for (var j = 0; j < this.journeyMap.linkedJourneyExperiences.length; j++) {
          if (!this.journeyMap.linkedJourneyExperiences[j].journeyExperience.hasOwnProperty('isDeleted')) {
            experienceList.push(this.journeyMap.linkedJourneyExperiences[j]);
          } else if (this.journeyMap.linkedJourneyExperiences[j].journeyExperience.hasOwnProperty('isDeleted') && this.journeyMap.linkedJourneyExperiences[j].journeyExperience.isDeleted === false) {
            experienceList.push(this.journeyMap.linkedJourneyExperiences[j]);
            break;
          }
        }
      }
      return experienceList;
    },
    computeExperienceLaneHeight: function computeExperienceLaneHeight() {
      if (this.phaseRelationTree.length > 0) {
        var experience_max_count = 0;
        for (var l = 0; l < this.phaseRelationTree.length; l++) {
          if (this.phaseRelationTree[l].experiences.length > experience_max_count) {
            experience_max_count = this.phaseRelationTree[l].experiences.length;
          }
        }
        for (var i = 0; i < this.phaseRelationTree.length; i++) {
          var experience_count = 0;
          for (var j = 0; j < this.phaseRelationTree[i].steps.length; j++) {
            experience_count += this.phaseRelationTree[i].steps[j].experiences.length;
          }
          if (experience_count > experience_max_count) {
            experience_max_count = experience_count;
          }
        }
        if (experience_max_count < 7) {
          experience_max_count = 7;
        }
        return experience_max_count * 30 + 25;
      } else {
        return 240;
      }
    },
    computeCanvasWidth: function computeCanvasWidth() {
      return 1750 * (0, _resolution.screenRatio)();
    },
    computeCanvasHeight: function computeCanvasHeight() {
      // TODO Refactor
      if (this.journeyMap.linkedJourneyPhases === undefined) {
        return 800;
      }
      return 60 + this.layout.step.height + this.computeTouchpointsHeight + 175 + this.computeExperienceLaneHeight;
    },
    computeTouchPointY: function computeTouchPointY() {
      return this.computeExperienceLaneHeight + 60 + this.layout.step.height + 25 + 25;
    },
    computeThoughtY: function computeThoughtY() {
      if (this.isTouchPointLaneVisible) {
        return this.computeTouchPointY + this.computeTouchpointsHeight + 5;
      }
      return this.computeTouchPointY;
    },
    computeTouchpointsHeight: function computeTouchpointsHeight() {
      if (this.journeyMap.additionalattributes && this.journeyMap.additionalattributes.layouts) {
        var max = 4;
        for (var i = 0; i < this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages.length; i++) {
          if (this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[i].touchpoints && this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[i].touchpoints.length) {
            if (max < this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[i].touchpoints.length) {
              max = this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[i].touchpoints.length;
            }
          }
        }
        return Math.round(max / 2) * 35 + 20;
      }
      return 100;
    },
    computedIdeasForJourney: function computedIdeasForJourney() {
      this.refreshKey;
      var ideas = [];
      if (this.journeyMap.title && this.journeyMap.linkedBusinessIdeas) {
        for (var i = 0; i < this.journeyMap.linkedBusinessIdeas.length; i++) {
          if (this.journeyMap.linkedBusinessIdeas[i].businessIdea.scope === 'Journey' && this.journeyMap.linkedBusinessIdeas[i].businessIdea.scopename === this.journeyMap.title) {
            ideas.push(this.journeyMap.linkedBusinessIdeas[i].businessIdea);
          }
        }
      }
      return ideas;
    }
  }),
  watch: {
    journeyMap: {
      handler: function handler(newValue) {
        if (newValue.id) {
          this.refreshKey++;
          this.computePhaseX();
          this.computePhaseLengthArray();
          this.computePhaseStepRelationArray();
          this.computePhaseRelationTree();
          this.computeTaskX();
          this.computeTaskY();
          this.computeExperienceX();
          this.computeExperienceY();
        }
        if (newValue.linkedJourneyPhases) {
          this.refreshKey++;
          this.computePhaseX();
          this.computePhaseLengthArray();
          this.computePhaseStepRelationArray();
          this.computePhaseRelationTree();
          this.computeTaskX();
          this.computeExperienceX();
          this.computeExperienceY();
        }
        if (newValue.linkedJourneySteps) {
          this.refreshKey++;
          this.computePhaseStepRelationArray();
          this.computePhaseRelationTree();
          this.computePhaseLengthArray();
          this.computeTaskX();
          this.computeTaskY();
          this.computeExperienceX();
          this.computeExperienceY();
        }
        if (newValue.linkedJourneyExperiences) {
          this.refreshKey++;
          this.computePhaseStepRelationArray();
          this.computePhaseRelationTree();
          this.computePhaseLengthArray();
          this.computeExperienceX();
          this.computeExperienceY();
        }
        if (newValue.linkedBusinessIdeas) {
          this.refreshKey++;
        }
      },
      deep: true
    },
    isCompactMenubar: function isCompactMenubar(old_val, new_val) {
      this.computePhaseX();
      this.computePhaseLengthArray();
      this.computePhaseStepRelationArray();
      this.computePhaseRelationTree();
      this.computeTaskX();
      this.computeTaskY();
      this.computeExperienceX();
      this.computeExperienceY();
      this.resetTools();
    },
    resetLayout: function resetLayout(old_val, new_val) {
      this.computePhaseX();
      this.computePhaseLengthArray();
      this.computePhaseStepRelationArray();
      this.computePhaseRelationTree();
      this.computeTaskX();
      this.computeTaskY();
      this.computeExperienceX();
      this.computeExperienceY();
    },
    stepLayout: function stepLayout(old_val, new_val) {
      this.computePhaseX();
      this.computePhaseLength();
      this.computePhaseLengthArray();
      this.computeTaskX();
      this.computeTaskY();
    },
    sortedStages: function sortedStages(old_val, new_val) {
      if (this.phaseStepRelation.length === 0) {
        this.computePhaseStepRelationArray();
        this.computePhaseRelationTree();
      }
    }
  },
  beforeDestroy: function beforeDestroy() {
    clearInterval(this.timer);
  },
  created: function created() {},
  mounted: function mounted() {
    var _this = this;
    this.timer = setInterval(function () {
      _this.computeFetchTimeStamp();
    }, 5000);

    // this.runRelationCleanup()
    this.computePhaseStepRelationArray();
    this.computePhaseRelationTree();
    this.resetTools();
    this.computePhaseLengthArray();
    this.computeExperienceX();
    this.computeExperienceY();
  },
  methods: {
    hasPermissionForModule: function hasPermissionForModule(entity) {
      return this.modules.includes(entity);
    },
    computeFetchTimeStamp: function computeFetchTimeStamp() {
      this.fetchTimeStamp = this.$moment(this.journeyMapFetchStatus.timestamp).fromNow();
    },
    selectRelation: function selectRelation(relation, index, type) {
      this.$emit('select-relation', relation, index, type);
    },
    updateStepRelation: function updateStepRelation(start_step_id, end_step_id) {
      this.$emit('update-task-to-workflow', start_step_id, end_step_id);
    },
    selectTask: function selectTask(index) {
      var tab = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'step_details';
      this.selectedTaskIndex = index;
      this.resetHoverDetailsDialog();
      this.$emit('select-task', this.selectedTaskIndex, this.computedTaskList[index].journeyStep, tab);
    },
    editExperienceDetails: function editExperienceDetails(experience_index, experience) {
      this.resetHoverDetailsDialog();
      this.selectedExperienceIndex = experience_index;
      this.selectedExperience = Object.assign({}, experience.journeyExperience);
      this.experienceHover = false;
      this.isExperienceEdit = true;
      this.$emit('initiate-edit-experience', this.isExperienceEdit, this.selectedExperience, this.selectedExperienceIndex);
    },
    editPhaseDetails: function editPhaseDetails(phase_index) {
      var tab = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'details';
      this.resetHoverDetailsDialog();
      this.selectedPhaseIndex = phase_index;
      var phase = Object.assign({}, this.sortedStages[this.selectedPhaseIndex].journeyPhase);
      this.phaseHover = false;
      this.isPhaseEdit = true;
      this.$emit('initiate-edit-phase', this.isPhaseEdit, phase, phase_index, tab);
    },
    toggleFocusMode: function toggleFocusMode(phase, index) {
      this.resetHoverDetailsDialog();
      this.selectedPhaseIndex = index;
      this.selectedPhase = phase.journeyPhase;
      this.isPhaseFocusMode = !this.isPhaseFocusMode;
    },
    onExperienceMouseOver: function onExperienceMouseOver(x, y, title, experience_relations, index) {
      this.selectedExperienceIndex = index;
      this.selectedExperience = this.computedExperienceList[index];
      this.selectedEntityId = this.computedExperienceList[index].journeyExperience.id;
      this.resetHoverDetailsDialog();
      this.experienceHover = true;
    },
    onExperienceMouseLeave: function onExperienceMouseLeave() {
      this.selectedExperienceIndex = null;
      this.selectedEntityId = null;
      this.selectedExperience = {};
      this.experienceHover = false;
    },
    onTaskMouseOver: function onTaskMouseOver(task, index) {
      this.selectedTaskIndex = index;
      this.selectedTask = task.journeyStep;
      this.selectedEntityId = task.journeyStep.id;
      this.resetHoverDetailsDialog();
      this.taskHover = true;
    },
    onTaskMouseLeave: function onTaskMouseLeave(task, index) {
      this.selectedTaskIndex = null;
      this.selectedEntityId = null;
      this.selectedTask = {};
      this.taskHover = false;
    },
    onPhaseMouseOver: function onPhaseMouseOver(phase, index) {
      this.selectedPhaseIndex = index;
      this.selectedPhase = phase.journeyPhase;
      this.selectedEntityId = phase.journeyPhase.id;
      this.resetHoverDetailsDialog();
      this.phaseHover = true;
    },
    onPhaseMouseLeave: function onPhaseMouseLeave() {
      this.selectedPhaseIndex = null;
      this.selectedEntityId = null;
      this.selectedPhase = {};
      this.resetHoverDetailsDialog();
      this.phaseHover = false;
    },
    onTouchpointMouseOver: function onTouchpointMouseOver(stage_index, touchpoint_index, x, y) {
      this.selectedTouchpointIndex = touchpoint_index;
      this.selectedPhaseIndex = stage_index;
      this.selectedTouchpoint = this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[stage_index].touchpoints[touchpoint_index];
      this.selectedTouchpoint.index = touchpoint_index;
      this.selectedTouchpointPos.x = x;
      this.selectedTouchpointPos.y = y;
      this.touchpointHover = true;
    },
    onTouchpointMouseLeave: function onTouchpointMouseLeave() {
      this.selectedPhaseIndex = null;
      this.selectedTouchpointIndex = null;
      this.selectedTouchpoint = {};
      this.selectedTouchpointPos.x = 0;
      this.selectedTouchpointPos.y = 0;
      this.touchpointHover = false;
    },
    selectTouchpoint: function selectTouchpoint(stage_index, touchpoint_index) {
      this.$emit('select-touchpoint', stage_index, touchpoint_index);
    },
    onPhaseLongPressStart: function onPhaseLongPressStart(phase, index) {
      this.selectedPhaseIndex = index;
      this.selectedPhase = phase.journeyPhase;
      this.resetHoverDetailsDialog();
      if (this.sortedStages.length > 0) {
        this.highlightAllPhaseBlockGap();
      }
      this.phaseLongPress = true;
    },
    onPhaseLongPressStop: function onPhaseLongPressStop(phase, index) {
      this.resetHoverDetailsDialog();
      this.resetHighlightAllPhaseBlockGap();
      this.phaseLongPress = false;
    },
    createTaskFromPhase: function createTaskFromPhase() {
      this.$emit('initiate-create-task', this.selectedPhase, this.selectedPhaseIndex);
    },
    initiateCreatePhaseIdea: function initiateCreatePhaseIdea(title) {
      this.$emit('initiate-create-phase-idea', title);
    },
    initiateCreatePhaseExperience: function initiateCreatePhaseExperience(experience_type) {
      this.$emit('initiate-create-experience-from-phase', experience_type, this.selectedPhase, this.selectedPhaseIndex);
    },
    initiateCreateStepExperience: function initiateCreateStepExperience(experience_type) {
      this.$emit('initiate-create-experience-from-step', experience_type, this.selectedTask, this.selectedTaskIndex);
    },
    initiateCreateStepIdea: function initiateCreateStepIdea(title) {
      this.$emit('initiate-create-step-idea', title);
    },
    deletePhase: function deletePhase(phase_id) {
      this.$emit('delete-phase', phase_id);
      this.resetHoverDetailsDialog();
    },
    deleteStep: function deleteStep(step_id) {
      this.$emit('delete-step', step_id);
      this.resetHoverDetailsDialog();
    },
    deleteExperience: function deleteExperience(experience_id) {
      this.$emit('delete-experience', experience_id);
      this.resetHoverDetailsDialog();
    },
    deleteTouchpoint: function deleteTouchpoint(title) {
      // this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[this.selectedPhaseIndex].touchpoints.splice(this.selectedTouchpointIndex, 1)
      // this.$store.dispatch('customerTypes/updateJourneyMap', this.journeyMap)
      this.resetHoverDetailsDialog();
    },
    persistTaskPositions: function persistTaskPositions() {
      for (var i = 0; i < this.computedTaskList.length; i++) {
        for (var j = 0; j < this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages.length; j++) {
          for (var k = 0; k < this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[j].steps.length; k++) {
            if (this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[j].steps[k].id === this.computedTaskList[i].journeyStep.id) {
              if (this.stepLayout === 'horizontal') {
                this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[j].steps[k].pos.horizontal.x = this.task_pos_x[i];
                this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[j].steps[k].pos.horizontal.y = this.task_pos_y[i];
              } else if (this.stepLayout === 'matrix') {
                this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[j].steps[k].pos.matrix.x = this.task_pos_x[i];
                this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[j].steps[k].pos.matrix.y = this.task_pos_y[i];
              }
            }
          }
        }
      }
      this.$store.dispatch('customerTypes/updateJourneyMap', this.journeyMap);
    },
    // DRAG METHODS
    onTaskHorizontalDragged: function onTaskHorizontalDragged(index, el, deltaX, deltaY, offsetX, offsetY, clientX, clientY, first, last) {
      var response = this.isStepRelatedToPhase(this.computedTaskList[index].journeyStep.id);
      if (last !== undefined && last) {
        this.relationsUpdate = !this.relationsUpdate;
        for (var j = 0; j < this.sortedStages.length; j++) {
          var phase_temp_x = this.computePhasePosX(j);
          var phase_temp_y = 40;
          var phase_temp_length = this.computePhaseLength(this.sortedStages[j].journeyPhase, j);
          if (this.task_pos_x[index] > phase_temp_x && this.task_pos_x[index] < phase_temp_x + phase_temp_length) {
            if (this.task_pos_y[index] > phase_temp_y && this.task_pos_y[index] < 300) {
              this.highlightActivePhase(j);
              if (this.sortedStages[j].journeyPhase.id !== response[1]) {
                this.$emit('swap-phase-step-relation', this.computedTaskList[index].journeyStep.id, response[1], this.sortedStages[j].journeyPhase.id);
              }
            }
          }
        }
        this.resetHighlightAllPhaseHighlighter();
        this.resetHighlightActivePhaseBlock();
        this.persistTaskPositions();
      }
      if (deltaX === undefined || deltaY === undefined) {
        return;
      }
      this.resetHoverDetailsDialog();
      this.task_pos_x[index] += this.computeZoomCoefficient(deltaX);
      this.task_pos_y[index] += this.computeZoomCoefficient(deltaY);
      this.$forceUpdate();
      if (this.sortedStages.length > 0) {
        this.highlightAllPhase();
      }
      for (var l = 0; l < this.sortedStages.length; l++) {
        var phase_x = this.computePhasePosX(l);
        var phase_y = 40;
        var phase_length = this.computePhaseLength(this.sortedStages[l].journeyPhase, l);
        if (this.task_pos_x[index] > phase_x && this.task_pos_x[index] < phase_x + phase_length) {
          if (this.task_pos_y[index] > phase_y && this.task_pos_y[index] < 300) {
            this.highlightActivePhase(l);
          }
        }
      }
    },
    onTaskMatrixDragged: function onTaskMatrixDragged(index, el, deltaX, deltaY, offsetX, offsetY, clientX, clientY, first, last) {
      if (last !== undefined && last) {
        this.relationsUpdate = !this.relationsUpdate;
        // Update position to be persistent
        this.persistTaskPositions();
      }
      if (deltaX === undefined || deltaY === undefined) {
        return;
      }
      this.resetHoverDetailsDialog();
      this.task_pos_x[index] += this.computeZoomCoefficient(deltaX);
      this.task_pos_y[index] += this.computeZoomCoefficient(deltaY);
      this.$forceUpdate();
    },
    onTaskWorkFlowDrag: function onTaskWorkFlowDrag(index, el, deltaX, deltaY, offsetX, offsetY, clientX, clientY, first, last) {
      if (first !== undefined && first) {
        this.relationsHoverTaskID = '';
        this.resetTempTaskRelation();
      }
      if (last !== undefined && last) {
        if (this.tempTaskRelationPath.x1 > 0 && this.tempTaskRelationPath.y1 > 0) {
          // check if overlap with any existing task
          for (var i = 0; i < this.computedTaskList.length; i++) {
            if (this.tempTaskRelationPath.x2 > this.computeTaskPosX(i) && this.tempTaskRelationPath.x2 < this.computeTaskPosX(i) + 140 && this.tempTaskRelationPath.y2 > this.computeTaskPosY(i) && this.tempTaskRelationPath.y2 < this.computeTaskPosY(i) + 50 && this.computedTaskList[i].journeyStep.id !== this.computedTaskList[index].journeyStep.id) {
              this.updateStepRelation(this.computedTaskList[index].journeyStep.id, this.computedTaskList[i].journeyStep.id);
            }
          }
        }
        this.relationsHoverTaskID = '';
        this.resetTempTaskRelation();
      }
      if (deltaX === undefined || deltaY === undefined) {
        return;
      }
      this.tempTaskRelationPath = {
        x1: this.computeTaskPosX(index) + 15,
        y1: this.computeTaskPosY(index),
        x2: this.computeTaskPosX(index) + 15 + offsetX,
        y2: this.computeTaskPosY(index) + offsetY
      };
      if (this.tempTaskRelationPath.x1 > 0 && this.tempTaskRelationPath.y1 > 0) {
        // check if overlap with any existing task
        for (var j = 0; j < this.computedTaskList.length; j++) {
          if (this.tempTaskRelationPath.x2 > this.computeTaskPosX(j) && this.tempTaskRelationPath.x2 < this.computeTaskPosX(j) + 140 && this.tempTaskRelationPath.y2 > this.computeTaskPosY(j) && this.tempTaskRelationPath.y2 < this.computeTaskPosY(j) + 50 && this.computedTaskList[j].journeyStep.id !== this.computedTaskList[index].journeyStep.id) {
            this.relationsHoverTaskID = this.computedTaskList[j].journeyStep.id;
          }
        }
        //
      }
    },
    onPhaseDragged: function onPhaseDragged(_ref) {
      var el = _ref.el,
        deltaX = _ref.deltaX,
        deltaY = _ref.deltaY,
        offsetX = _ref.offsetX,
        offsetY = _ref.offsetY,
        clientX = _ref.clientX,
        clientY = _ref.clientY,
        first = _ref.first,
        last = _ref.last;
      var index = parseInt(el.id.replace('phase_', ''));
      if (last !== undefined && last) {
        this.relationsUpdate = !this.relationsUpdate;
        this.resetHighlightActivePhaseBlockGap();
        var isPosChange = false;
        var newPos = 0;
        // if dragged phase is between any other phase
        // replace position
        var originalPhaseX = [];
        var expandSpacing = 112;
        for (var i = 0; i < this.sortedStages.length; i++) {
          if (i === 0) {
            originalPhaseX.push(this.canvasDragCoefficientX + 75 + expandSpacing);
          } else {
            var length = 0;
            for (var j = 0; j < i; j++) {
              length += this.computePhaseLength(this.sortedStages[j].journeyPhase, j);
              length += 75;
            }
            originalPhaseX.push(this.canvasDragCoefficientX + 75 + length + expandSpacing);
          }
        }
        for (var k = 0; k < originalPhaseX.length; k++) {
          if (this.phase_pos_x[index] > originalPhaseX[k] + this.computePhaseLength(this.sortedStages[k].journeyPhase, k) && this.phase_pos_x[index] < originalPhaseX[k + 1] && k < originalPhaseX.length - 1) {
            isPosChange = true;
            newPos = k;
          } else if (this.phase_pos_x[index] > originalPhaseX[k] + this.computePhaseLength(this.sortedStages[k].journeyPhase, k) && k === originalPhaseX.length - 1) {
            isPosChange = true;
            newPos = k;
          }
        }
        if (isPosChange) {
          var stagesSortOrder = [];
          if (this.journeyMap.additionalattributes) {
            stagesSortOrder = Object.values(this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages);
            var temp = stagesSortOrder[index];
            if (newPos > index) {
              stagesSortOrder.splice(index, 1);
              stagesSortOrder.splice(newPos, 0, temp);
            } else {
              stagesSortOrder.splice(index, 1);
              stagesSortOrder.splice(newPos + 1, 0, temp);
            }
          } else {
            stagesSortOrder.push(this.sortedStages[newPos].journeyPhase.id);
            stagesSortOrder.push(this.sortedStages[index].journeyPhase.id);
          }
          this.$emit('edit-phase-sort-order', this.journeyMap, stagesSortOrder);
        } else {
          // else reset position
          this.computePhaseX();
        }
      }
      if (this.phaseLongPress) {
        if (deltaX === undefined || deltaY === undefined) {
          return;
        }
        this.phase_pos_x[index] += deltaX;
        var originalPhaseXTemp = [];
        var expandSpacingTemp = 112;
        for (var l = 0; l < this.sortedStages.length; l++) {
          if (l === 0) {
            originalPhaseXTemp.push(this.canvasDragCoefficientX + 75 + expandSpacingTemp);
          } else {
            var lengthTemp = 0;
            for (var m = 0; m < l; m++) {
              lengthTemp += this.computePhaseLength(this.sortedStages[m].journeyPhase, m);
              lengthTemp += 75;
            }
            originalPhaseXTemp.push(this.canvasDragCoefficientX + 75 + lengthTemp + expandSpacingTemp);
          }
        }
        for (var n = 0; n < originalPhaseXTemp.length; n++) {
          if (this.phase_pos_x[index] > originalPhaseXTemp[n] + this.computePhaseLength(this.sortedStages[n].journeyPhase, n) && this.phase_pos_x[index] < originalPhaseXTemp[n + 1] && n < originalPhaseXTemp.length - 1) {
            this.highlightActivePhaseGap(originalPhaseXTemp[n] + this.computePhaseLength(this.sortedStages[n].journeyPhase, n) + 25, 25);
          } else if (this.phase_pos_x[index] > originalPhaseXTemp[n] + this.computePhaseLength(this.sortedStages[n].journeyPhase, n) && n === originalPhaseXTemp.length - 1) {
            this.highlightActivePhaseGap(originalPhaseXTemp[n] + this.computePhaseLength(this.sortedStages[n].journeyPhase, n) + 25, 1000);
          }
        }
      }
    },
    onPhaseMenuToolDragged: function onPhaseMenuToolDragged(_ref2) {
      var el = _ref2.el,
        deltaX = _ref2.deltaX,
        deltaY = _ref2.deltaY,
        offsetX = _ref2.offsetX,
        offsetY = _ref2.offsetY,
        clientX = _ref2.clientX,
        clientY = _ref2.clientY,
        first = _ref2.first,
        last = _ref2.last;
      if (last !== undefined && last) {
        this.resetHighlightActivePhaseBlockGap();
        this.resetHighlightAllPhaseBlockGap();
        if (this.toolbox.menu_phase.x > 120 && this.toolbox.menu_phase.x < 2920 && this.toolbox.menu_phase.y > 0 && this.toolbox.menu_phase.y < 600) {
          this.$emit('initiate-create-phase', this.phaseDropDetails);
        }
        this.resetTools();
      }
      if (deltaX === undefined || deltaY === undefined) {
        return;
      }
      this.toolbox.menu_phase.x += this.computeZoomCoefficient(deltaX);
      this.toolbox.menu_phase.y += this.computeZoomCoefficient(deltaY);
      var start_x = 0;
      if (this.sortedStages.length > 0) {
        this.highlightAllPhaseBlockGap();
        start_x = this.computePhasePosX(0);
        if (this.toolbox.menu_phase.x > start_x - 50 && this.toolbox.menu_phase.x < start_x) {
          if (this.toolbox.menu_phase.y > 0 && this.toolbox.menu_phase.y < 600) {
            this.highlightActivePhaseGap(start_x - 50, 25);
            this.phaseDropDetails.index = 0;
            this.phaseDropDetails.isBefore = true;
          }
        }
        for (var i = 0; i < this.sortedStages.length; i++) {
          var phase_x = this.computePhasePosX(i);
          var phase_y = 0;
          var phase_length = this.computePhaseLength(this.sortedStages[i].journeyPhase, i);
          if (this.toolbox.menu_phase.x > phase_x + phase_length && this.toolbox.menu_phase.x < phase_x + phase_length + 50) {
            if (this.toolbox.menu_phase.y > phase_y && this.toolbox.menu_phase.y < 600 && i < this.sortedStages.length - 1) {
              this.highlightActivePhaseGap(phase_x + phase_length + 25, 25);
              this.phaseDropDetails.index = i;
              this.phaseDropDetails.isBefore = false;
            }
          }
          if (this.toolbox.menu_phase.x > phase_x + phase_length && this.toolbox.menu_phase.x < phase_x + phase_length + 1000) {
            if (this.toolbox.menu_phase.y > phase_y && this.toolbox.menu_phase.y < 600 && i === this.sortedStages.length - 1) {
              this.highlightActivePhaseGap(phase_x + phase_length + 25, 1000);
              this.phaseDropDetails.index = i;
              this.phaseDropDetails.isBefore = false;
            }
          }
        }
      } else if (this.toolbox.menu_phase.x > 120 && this.toolbox.menu_phase.x < 2920 && this.toolbox.menu_phase.y > 0 && this.toolbox.menu_phase.y < 600) {
        this.highlightActivePhaseGap(125, this.computeCanvasWidth);
        this.phaseDropDetails.index = 0;
        this.phaseDropDetails.isBefore = false;
      }
    },
    onPhaseToolDragged: function onPhaseToolDragged(_ref3) {
      var el = _ref3.el,
        deltaX = _ref3.deltaX,
        deltaY = _ref3.deltaY,
        offsetX = _ref3.offsetX,
        offsetY = _ref3.offsetY,
        clientX = _ref3.clientX,
        clientY = _ref3.clientY,
        first = _ref3.first,
        last = _ref3.last;
      if (last !== undefined && last) {
        this.resetHighlightActivePhaseBlockGap();
        this.resetHighlightAllPhaseBlockGap();
        if (this.toolbox.phase.x > 120 && this.toolbox.phase.x < 2920 && this.toolbox.phase.y > 0 && this.toolbox.phase.y < 600) {
          this.$emit('initiate-create-phase', this.phaseDropDetails);
        }
        this.resetTools();
      }
      if (deltaX === undefined || deltaY === undefined) {
        return;
      }
      this.toolbox.phase.x += this.computeZoomCoefficient(deltaX);
      this.toolbox.phase.y += this.computeZoomCoefficient(deltaY);
      var start_x = 0;
      if (this.sortedStages.length > 0) {
        this.highlightAllPhaseBlockGap();
        start_x = this.computePhasePosX(0);
        if (this.toolbox.phase.x > start_x - 50 && this.toolbox.phase.x < start_x) {
          if (this.toolbox.phase.y > 0 && this.toolbox.phase.y < 600) {
            this.highlightActivePhaseGap(start_x - 50, 25);
            this.phaseDropDetails.index = 0;
            this.phaseDropDetails.isBefore = true;
          }
        }
        for (var i = 0; i < this.sortedStages.length; i++) {
          var phase_x = this.computePhasePosX(i);
          var phase_y = 0;
          var phase_length = this.computePhaseLength(this.sortedStages[i].journeyPhase, i);
          if (this.toolbox.phase.x > phase_x + phase_length && this.toolbox.phase.x < phase_x + phase_length + 50) {
            if (this.toolbox.phase.y > phase_y && this.toolbox.phase.y < 600 && i < this.sortedStages.length - 1) {
              this.highlightActivePhaseGap(phase_x + phase_length + 25, 25);
              this.phaseDropDetails.index = i;
              this.phaseDropDetails.isBefore = false;
            }
          }
          if (this.toolbox.phase.x > phase_x + phase_length && this.toolbox.phase.x < phase_x + phase_length + 1000) {
            if (this.toolbox.phase.y > phase_y && this.toolbox.phase.y < 600 && i === this.sortedStages.length - 1) {
              this.highlightActivePhaseGap(phase_x + phase_length + 25, 1000);
              this.phaseDropDetails.index = i;
              this.phaseDropDetails.isBefore = false;
            }
          }
        }
      } else if (this.toolbox.phase.x > 120 && this.toolbox.phase.x < 2920 && this.toolbox.phase.y > 0 && this.toolbox.phase.y < 600) {
        this.highlightActivePhaseGap(125, this.computeCanvasWidth);
        this.phaseDropDetails.index = 0;
        this.phaseDropDetails.isBefore = false;
      }
    },
    onTaskMenuToolDragged: function onTaskMenuToolDragged(_ref4) {
      var el = _ref4.el,
        deltaX = _ref4.deltaX,
        deltaY = _ref4.deltaY,
        offsetX = _ref4.offsetX,
        offsetY = _ref4.offsetY,
        clientX = _ref4.clientX,
        clientY = _ref4.clientY,
        first = _ref4.first,
        last = _ref4.last;
      if (last !== undefined && last) {
        this.resetHighlightAllPhaseHighlighter();
        this.resetHighlightActivePhaseBlock();
        var step_creation_flag = false;
        for (var j = 0; j < this.sortedStages.length; j++) {
          var phase_end_x = this.computePhasePosX(j);
          var phase_end_y = 40;
          var phase_end_length = this.computePhaseLength(this.sortedStages[j].journeyPhase, j);
          if (this.toolbox.menu_task.x > phase_end_x && this.toolbox.menu_task.x < phase_end_x + phase_end_length) {
            if (this.toolbox.menu_task.y > phase_end_y && this.toolbox.menu_task.y < 300) {
              step_creation_flag = true;
              this.$emit('initiate-create-task', this.sortedStages[j].journeyPhase, j);
            }
          }
        }
        if (!step_creation_flag) {
          this.$message('Please drag the step below a stage, not outside of one');
        }
        this.resetTools();
      }
      if (deltaX === undefined || deltaY === undefined) {
        return;
      }
      this.toolbox.menu_task.x += this.computeZoomCoefficient(deltaX);
      this.toolbox.menu_task.y += this.computeZoomCoefficient(deltaY);
      if (this.sortedStages.length > 0) {
        this.highlightAllPhase();
      }
      for (var i = 0; i < this.sortedStages.length; i++) {
        var phase_x = this.computePhasePosX(i);
        var phase_y = 40;
        var phase_length = this.computePhaseLength(this.sortedStages[i].journeyPhase, i);
        if (this.toolbox.menu_task.x > phase_x && this.toolbox.menu_task.x < phase_x + phase_length) {
          if (this.toolbox.menu_task.y > phase_y && this.toolbox.menu_task.y < 300) {
            this.highlightActivePhase(i);
          }
        }
      }
    },
    onTaskToolDragged: function onTaskToolDragged(_ref5) {
      var el = _ref5.el,
        deltaX = _ref5.deltaX,
        deltaY = _ref5.deltaY,
        offsetX = _ref5.offsetX,
        offsetY = _ref5.offsetY,
        clientX = _ref5.clientX,
        clientY = _ref5.clientY,
        first = _ref5.first,
        last = _ref5.last;
      if (last !== undefined && last) {
        this.resetHighlightAllPhaseHighlighter();
        this.resetHighlightActivePhaseBlock();
        var step_creation_flag = false;
        for (var j = 0; j < this.sortedStages.length; j++) {
          var phase_end_x = this.computePhasePosX(j);
          var phase_end_y = 40;
          var phase_end_length = this.computePhaseLength(this.sortedStages[j].journeyPhase, j);
          if (this.toolbox.task.x > phase_end_x && this.toolbox.task.x < phase_end_x + phase_end_length) {
            if (this.toolbox.task.y > phase_end_y && this.toolbox.task.y < 300) {
              step_creation_flag = true;
              this.$emit('initiate-create-task', this.sortedStages[j].journeyPhase, j);
            }
          }
        }
        if (!step_creation_flag) {
          this.$message('Please drag the step below a stage, not outside of one');
        }
        this.resetTools();
      }
      if (deltaX === undefined || deltaY === undefined) {
        return;
      }
      this.toolbox.task.x += this.computeZoomCoefficient(deltaX);
      this.toolbox.task.y += this.computeZoomCoefficient(deltaY);
      if (this.sortedStages.length > 0) {
        this.highlightAllPhase();
      }
      for (var i = 0; i < this.sortedStages.length; i++) {
        var phase_x = this.computePhasePosX(i);
        var phase_y = 40;
        var phase_length = this.computePhaseLength(this.sortedStages[i].journeyPhase, i);
        if (this.toolbox.task.x > phase_x && this.toolbox.task.x < phase_x + phase_length) {
          if (this.toolbox.task.y > phase_y && this.toolbox.task.y < 300) {
            this.highlightActivePhase(i);
          }
        }
      }
    },
    onIdeaToolDragged: function onIdeaToolDragged(_ref6) {
      var el = _ref6.el,
        deltaX = _ref6.deltaX,
        deltaY = _ref6.deltaY,
        offsetX = _ref6.offsetX,
        offsetY = _ref6.offsetY,
        clientX = _ref6.clientX,
        clientY = _ref6.clientY,
        first = _ref6.first,
        last = _ref6.last;
      if (last !== undefined && last) {
        var ideaHover = '';
        this.taskHoverID = '';
        this.phaseHoverID = '';
        this.isJourneyHighlight = false;
        for (var j = 0; j < this.computedTaskList.length; j++) {
          if (this.toolbox.idea.x > this.computeTaskPosX(j) && this.toolbox.idea.x < this.computeTaskPosX(j) + 100 && this.toolbox.idea.y > this.computeTaskPosY(j) - 50 && this.toolbox.idea.y < this.computeTaskPosY(j)) {
            ideaHover = 'step';
            this.$emit('initiate-create-step-idea', this.computedTaskList[j].journeyStep.title);
          }
        }
        // Run code only if step overlap does not occur
        // Save some CPU
        if (ideaHover === '') {
          for (var k = 0; k < this.sortedStages.length; k++) {
            if (this.toolbox.idea.x > this.computePhasePosX(k) && this.toolbox.idea.x < this.computePhasePosX(k) + this.computePhaseLength(this.sortedStages[k].journeyPhase, k) && this.toolbox.idea.y > 10 && this.toolbox.idea.y < 95) {
              ideaHover = 'phase';
              this.$emit('initiate-create-phase-idea', this.sortedStages[k].journeyPhase.title);
            }
          }
        }
        if (ideaHover === '') {
          this.$emit('initiate-create-journey-idea', this.journeyMap.title);
        }
        this.resetTools();
      }
      if (deltaX === undefined || deltaY === undefined) {
        return;
      }
      this.resetHoverDetailsDialog();
      this.toolbox.idea.x += this.computeZoomCoefficient(deltaX);
      this.toolbox.idea.y += this.computeZoomCoefficient(deltaY);
      var ideaDynamicHover = '';
      this.taskHoverID = '';
      this.phaseHoverID = '';
      this.isJourneyHighlight = false;
      for (var l = 0; l < this.computedTaskList.length; l++) {
        if (this.toolbox.idea.x > this.computeTaskPosX(l) && this.toolbox.idea.x < this.computeTaskPosX(l) + 100 && this.toolbox.idea.y > this.computeTaskPosY(l) - 50 && this.toolbox.idea.y < this.computeTaskPosY(l)) {
          ideaDynamicHover = 'step';
          this.taskHoverID = this.computedTaskList[l].journeyStep.id;
        }
      }
      // Run code only if step overlap does not occur
      // Save some CPU
      if (ideaDynamicHover === '') {
        for (var m = 0; m < this.sortedStages.length; m++) {
          if (this.toolbox.idea.x > this.computePhasePosX(m) && this.toolbox.idea.x < this.computePhasePosX(m) + this.computePhaseLength(this.sortedStages[m].journeyPhase, m) && this.toolbox.idea.y > 10 && this.toolbox.idea.y < 95) {
            ideaDynamicHover = 'phase';
            this.phaseHoverID = this.sortedStages[m].journeyPhase.id;
          }
        }
      }
      if (ideaDynamicHover === '') {
        this.isJourneyHighlight = true;
      }
    },
    onRiskToolDragged: function onRiskToolDragged(_ref7) {
      var el = _ref7.el,
        deltaX = _ref7.deltaX,
        deltaY = _ref7.deltaY,
        offsetX = _ref7.offsetX,
        offsetY = _ref7.offsetY,
        clientX = _ref7.clientX,
        clientY = _ref7.clientY,
        first = _ref7.first,
        last = _ref7.last;
      if (last !== undefined && last) {
        var riskHover = '';
        this.taskHoverID = '';
        this.phaseHoverID = '';
        this.isJourneyHighlight = false;
        for (var j = 0; j < this.computedTaskList.length; j++) {
          if (this.toolbox.risk.x > this.computeTaskPosX(j) && this.toolbox.risk.x < this.computeTaskPosX(j) + 100 && this.toolbox.risk.y > this.computeTaskPosY(j) - 50 && this.toolbox.risk.y < this.computeTaskPosY(j)) {
            riskHover = 'step';
            this.$emit('initiate-create-step-risk', this.computedTaskList[j].journeyStep.title);
          }
        }
        // Run code only if step overlap does not occur
        // Save some CPU
        if (riskHover === '') {
          for (var k = 0; k < this.sortedStages.length; k++) {
            if (this.toolbox.risk.x > this.computePhasePosX(k) && this.toolbox.risk.x < this.computePhasePosX(k) + this.computePhaseLength(this.sortedStages[k].journeyPhase, k) && this.toolbox.risk.y > 10 && this.toolbox.risk.y < 95) {
              riskHover = 'phase';
              this.$emit('initiate-create-phase-risk', this.sortedStages[k].journeyPhase.title);
            }
          }
        }
        if (riskHover === '') {
          this.$emit('initiate-create-journey-risk', this.journeyMap.title);
        }
        this.resetTools();
      }
      if (deltaX === undefined || deltaY === undefined) {
        return;
      }
      this.resetHoverDetailsDialog();
      this.toolbox.risk.x += this.computeZoomCoefficient(deltaX);
      this.toolbox.risk.y += this.computeZoomCoefficient(deltaY);
      var riskDynamicHover = '';
      this.taskHoverID = '';
      this.phaseHoverID = '';
      this.isJourneyHighlight = false;
      for (var l = 0; l < this.computedTaskList.length; l++) {
        if (this.toolbox.risk.x > this.computeTaskPosX(l) && this.toolbox.risk.x < this.computeTaskPosX(l) + 100 && this.toolbox.risk.y > this.computeTaskPosY(l) - 50 && this.toolbox.risk.y < this.computeTaskPosY(l)) {
          riskDynamicHover = 'step';
          this.taskHoverID = this.computedTaskList[l].journeyStep.id;
        }
      }
      // Run code only if step overlap does not occur
      // Save some CPU
      if (riskDynamicHover === '') {
        for (var m = 0; m < this.sortedStages.length; m++) {
          if (this.toolbox.risk.x > this.computePhasePosX(m) && this.toolbox.risk.x < this.computePhasePosX(m) + this.computePhaseLength(this.sortedStages[m].journeyPhase, m) && this.toolbox.risk.y > 10 && this.toolbox.risk.y < 95) {
            riskDynamicHover = 'phase';
            this.phaseHoverID = this.sortedStages[m].journeyPhase.id;
          }
        }
      }
      if (riskDynamicHover === '') {
        this.isJourneyHighlight = true;
      }
    },
    onTouchPointToolDragged: function onTouchPointToolDragged(index, type, x, y, first, last) {
      if (last !== undefined && last) {
        this.resetHighlightAllPhaseHighlighter();
        this.resetHighlightActivePhaseBlock();
        for (var i = 0; i < this.sortedStages.length; i++) {
          var phase_x = this.computePhasePosX(i);
          var phase_length = this.computePhaseLength(this.sortedStages[i].journeyPhase, i);
          if (x > phase_x && x < phase_x + phase_length) {
            if (y > this.computeTouchPointY && y < this.computeTouchPointY + this.computeTouchpointsHeight) {
              if (!this.hasMaxTouchpoints(this.sortedStages[i].journeyPhase.id)) {
                this.$emit('initiate-create-touchpoint', this.sortedStages[i].journeyPhase, i, type);
              }
            }
          }
        }
        return;
      }
      if (first !== undefined && first) {
        return;
      }
      if (this.sortedStages.length > 0) {
        this.highlightAllPhase();
      }
      for (var j = 0; j < this.sortedStages.length; j++) {
        var phase_x1 = this.computePhasePosX(j);
        var phase_length1 = this.computePhaseLength(this.sortedStages[j].journeyPhase, j);
        if (x > phase_x1 && x < phase_x1 + phase_length1) {
          if (y > this.computeTouchPointY && y < this.computeTouchPointY + this.computeTouchpointsHeight) {
            if (!this.hasMaxTouchpoints(this.sortedStages[j].journeyPhase.id)) {
              // this.highlightActivePhase(j, this.computeTouchPointY, this.computeTouchpointsHeight)
            }
          }
        }
      }
    },
    onStepContainerResizeDragged: function onStepContainerResizeDragged(_ref8) {
      var el = _ref8.el,
        deltaX = _ref8.deltaX,
        deltaY = _ref8.deltaY,
        offsetX = _ref8.offsetX,
        offsetY = _ref8.offsetY,
        clientX = _ref8.clientX,
        clientY = _ref8.clientY,
        first = _ref8.first,
        last = _ref8.last;
      if (last !== undefined && last) {
        this.computePhaseX();
        this.computeTaskX();
        this.computeTaskY();
        this.computeExperienceX();
        this.computeExperienceY();
        return;
      }
      if (deltaX === undefined || deltaY === undefined) {
        return;
      }
      this.layout.step.height += deltaY;
      if (this.layout.step.height < 260) {
        this.layout.step.height = 260;
      }
      if (this.layout.step.height > 750) {
        this.layout.step.height = 750;
      }
      this.computePhaseX();
      this.computeTaskX();
      this.computeTaskY();
      this.computeExperienceX();
      this.computeExperienceY();
    },
    onStepContainerDragged: function onStepContainerDragged(_ref9) {
      var el = _ref9.el,
        deltaX = _ref9.deltaX,
        deltaY = _ref9.deltaY,
        offsetX = _ref9.offsetX,
        offsetY = _ref9.offsetY,
        clientX = _ref9.clientX,
        clientY = _ref9.clientY,
        first = _ref9.first,
        last = _ref9.last;
      if (last !== undefined && last) {
        return;
      }
      if (deltaX === undefined || deltaY === undefined) {
        return;
      }
      this.canvasDragCoefficientX += deltaX;
      this.computePhaseX();
      this.computeTaskX();
      this.computeExperienceX();
    },
    // END DRAG METHODS
    // UI METHODS
    rearrangeSteps: function rearrangeSteps(journey_map) {
      this.journeyMap = journey_map;
      this.computeTaskY();
    },
    highlightActivePhase: function highlightActivePhase(phase_index) {
      var y = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 100;
      var height = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 260;
      this.activePhaseHighlighter = {
        x: this.computePhasePosX(phase_index) - 3,
        y: y,
        width: this.computePhaseLength(this.sortedStages[phase_index].journeyPhase, phase_index) + 26,
        height: height
      };
    },
    highlightAllPhase: function highlightAllPhase() {
      this.phaseHighlighter = [];
      for (var i = 0; i < this.sortedStages.length; i++) {
        var highlight_object = {};
        highlight_object = {
          x: this.computePhasePosX(i) - 5,
          y: 35,
          width: this.computePhaseLength(this.sortedStages[i].journeyPhase, i) + 50,
          height: this.computeCanvasHeight
        };
        this.phaseHighlighter.push(highlight_object);
      }
    },
    highlightActivePhaseGap: function highlightActivePhaseGap(start_x) {
      var width = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 25;
      this.activePhaseGapHighlighter = {
        x: start_x + 20,
        y: 35,
        width: width,
        height: this.computeCanvasHeight
      };
    },
    highlightAllPhaseBlockGap: function highlightAllPhaseBlockGap() {
      if (this.phaseGapHighlighter.length === 0) {
        var start_x = this.computePhasePosX(0);
        var gap_start_object = {};
        gap_start_object = {
          x: start_x - 30,
          y: 35,
          width: 25,
          height: this.computeCanvasHeight
        };
        this.phaseGapHighlighter.push(gap_start_object);
        for (var i = 0; i < this.sortedStages.length; i++) {
          var phase_x = this.computePhasePosX(i);
          var phase_length = this.computePhaseLength(this.sortedStages[i].journeyPhase, i);
          var gap_object = {};
          gap_object = {
            x: phase_x + phase_length + 45,
            y: 35,
            width: i === this.sortedStages.length - 1 ? 1000 : 25,
            height: this.computeCanvasHeight
          };
          this.phaseGapHighlighter.push(gap_object);
        }
      }
    },
    onPhaseToolMouseOver: function onPhaseToolMouseOver(index) {
      this.phaseToolHover = true;
    },
    onPhaseToolMouseLeave: function onPhaseToolMouseLeave(index) {
      this.phaseToolHover = false;
    },
    onRiskToolMouseOver: function onRiskToolMouseOver(index) {
      this.riskToolHover = true;
    },
    onRiskToolMouseLeave: function onRiskToolMouseLeave(index) {
      this.riskToolHover = false;
    },
    onIdeaToolMouseOver: function onIdeaToolMouseOver(index) {
      this.ideaToolHover = true;
    },
    onIdeaToolMouseLeave: function onIdeaToolMouseLeave(index) {
      this.ideaToolHover = false;
    },
    onTaskToolMouseOver: function onTaskToolMouseOver() {
      this.taskToolHover = true;
    },
    onTaskToolMouseLeave: function onTaskToolMouseLeave() {
      this.taskToolHover = false;
    },
    // END UI METHODS
    // HELPER METHODS
    retrieveExperienceFromID: function retrieveExperienceFromID(experience_id) {
      for (var i = 0; i < this.computedExperienceList.length; i++) {
        if (this.computedExperienceList[i].journeyExperience.id === experience_id) {
          return this.computedExperienceList[i].journeyExperience;
        }
      }

      // Return empty values to prevent crash
      return {
        'title': '',
        'experiencetype': ''
      };
    },
    // Compute Phase relation structure (including steps, experience and experience within steps)
    computePhaseRelationTree: function computePhaseRelationTree() {
      this.phaseRelationTree = this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages;
    },
    computeStepsForPhase: function computeStepsForPhase(phase_id) {
      var steps = [];
      for (var i = 0; i < this.computedRelations.length; i++) {
        // No need for end_id since phase is always start id
        if (this.computedRelations[i].start_id === phase_id && this.computedRelations[i].start_type === 'Phase' && this.computedRelations[i].end_type === 'Step') {
          steps.push(this.computedRelations[i].end_id);
        }
      }
      return steps;
    },
    computePhaseStepRelationArray: function computePhaseStepRelationArray() {
      this.phaseStepRelation = [];
      for (var i = 0; i < this.sortedStages.length; i++) {
        var steps = this.computeStepsForPhase(this.sortedStages[i].journeyPhase.id);
        this.phaseStepRelation[i] = new Array(steps.length);
        this.phaseStepRelation[i] = steps;
      }
    },
    computePhaseIndexforTask: function computePhaseIndexforTask(task, index) {
      for (var i = 0; i < this.computedRelations.length; i++) {
        if (this.computedRelations[i].end_id === task.journeyStep.id) {
          var phase_index = this.getPhaseAndIndexFromID(this.computedRelations[i].start_id)[1];
          return this.phaseStepRelation[phase_index].indexOf(task.journeyStep.id) + 1;
        }
      }
    },
    computeJourneyStepDisplayCondition: function computeJourneyStepDisplayCondition(task, index) {
      // Handle Focus Mode
      if (this.isPhaseFocusMode) {
        // TO BE UPDATED to new relation
        return this.selectedPhase.relations.includes(task.journeyStep.id);
      }
      if (task.journeyStep.hasOwnProperty('isDeleted') && task.journeyStep.isDeleted === true) {
        return false;
      }
      var flag = false;
      for (var i = 0; i < this.journeyMap.additionalattributes.relations.length; i++) {
        if (this.journeyMap.additionalattributes.relations[i].end_id === task.journeyStep.id && this.journeyMap.additionalattributes.relations[i].start_type === 'Phase') {
          flag = true;
          break;
        }
      }
      return flag;
    },
    computedIdeasForPhase: function computedIdeasForPhase(phase) {
      var ideas = [];
      if (phase.title) {
        for (var i = 0; i < this.journeyMap.linkedBusinessIdeas.length; i++) {
          if (this.journeyMap.linkedBusinessIdeas[i].businessIdea.scope === 'Phase' && this.journeyMap.linkedBusinessIdeas[i].businessIdea.scopename === phase.title) {
            ideas.push(this.journeyMap.linkedBusinessIdeas[i].businessIdea);
          }
        }
      }
      return ideas;
    },
    computedIdeasForStep: function computedIdeasForStep(step) {
      var ideas = [];
      if (step.title) {
        for (var i = 0; i < this.journeyMap.linkedBusinessIdeas.length; i++) {
          if (this.journeyMap.linkedBusinessIdeas[i].businessIdea.scope === 'Step' && this.journeyMap.linkedBusinessIdeas[i].businessIdea.scopename === step.title) {
            ideas.push(this.journeyMap.linkedBusinessIdeas[i].businessIdea);
          }
        }
      }
      return ideas;
    },
    isParentTaskActive: function isParentTaskActive(experience_id) {
      for (var i = 0; i < this.computedRelations.length; i++) {
        if (this.computedRelations[i].end_id === experience_id && this.computedRelations[i].start_id === this.selectedTask.id) {
          return true;
        }
      }
      return true;
    },
    isStageRelation: function isStageRelation(experience_index) {
      return this.isExperienceRelatedToPhase(this.computedExperienceList[experience_index].journeyExperience.id)[0];
    },
    computeExperienceStartX: function computeExperienceStartX(index) {
      if (this.sortedStages.length === 0) {
        return 0;
      }
      var phase_experience_relation = this.isExperienceRelatedToPhase(this.computedExperienceList[index].journeyExperience.id);
      var step_experience_relation = this.isExperienceRelatedToStep(this.computedExperienceList[index].journeyExperience.id);
      if (phase_experience_relation[0]) {
        var phase_index = this.getPhaseAndIndexFromID(phase_experience_relation[1])[1];
        var phase_start_x = this.computePhasePosX(phase_index);
        return phase_start_x;
      } else if (step_experience_relation[0]) {
        var step_index = this.getStepAndIndexFromID(step_experience_relation[1])[1];
        var step_start_x = this.computeTaskPosX(step_index);
        return step_start_x; // + ((Math.floor(index / 7)) * 120)
      }
    },
    computeExperienceStartY: function computeExperienceStartY(index) {
      var phase_experience_relation = this.isExperienceRelatedToPhase(this.computedExperienceList[index].journeyExperience.id);
      var step_experience_relation = this.isExperienceRelatedToStep(this.computedExperienceList[index].journeyExperience.id);
      if (phase_experience_relation[0]) {
        return this.layout.step.height + 110 + this.computeExperienceIndexInPhaseRelationTree(phase_experience_relation[1], this.computedExperienceList[index].journeyExperience.id) * 30;
      } else if (step_experience_relation[0]) {
        var experience_index_list = this.computeExperienceIndexInStepRelationTree(step_experience_relation[1], this.computedExperienceList[index].journeyExperience.id);
        return this.layout.step.height + 110 + experience_index_list[3] * 30;
      }
    },
    computeExperienceIndexInPhaseRelationTree: function computeExperienceIndexInPhaseRelationTree(phase_id, experience_id) {
      for (var i = 0; i < this.phaseRelationTree.length; i++) {
        if (this.phaseRelationTree[i].id === phase_id) {
          for (var j = 0; j < this.phaseRelationTree[i].experiences.length; j++) {
            if (this.phaseRelationTree[i].experiences[j].id === experience_id) {
              return j;
            }
          }
        }
      }
      return 0;
    },
    // Needs tweaking. dosent count the actual experience count yt.
    // update experience_count logic to count number of experiences above current calculated experience
    computeExperienceIndexInStepRelationTree: function computeExperienceIndexInStepRelationTree(step_id, experience_id) {
      for (var i = 0; i < this.phaseRelationTree.length; i++) {
        if (this.isStepRelatedToPhaseID(this.phaseRelationTree[i].id, step_id)) {
          var experience_count = 0;
          for (var j = 0; j < this.phaseRelationTree[i].steps.length; j++) {
            if (this.phaseRelationTree[i].steps[j].id === step_id) {
              for (var k = 0; k < this.phaseRelationTree[i].steps[j].experiences.length; k++) {
                if (this.phaseRelationTree[i].steps[j].experiences[k].id === experience_id) {
                  experience_count += k;
                  return [i, j, k, experience_count];
                }
              }
            }
            experience_count += this.phaseRelationTree[i].steps[j].experiences.length;
          }
        }
      }
      return [0, 0, 0, 0];
    },
    computePopoverPosition: function computePopoverPosition(index) {
      if (this.stepLayout === 'horizontal' && this.computeTaskPosX(index) + 140 + 410 > this.computeScreenWidth) {
        return 'left';
      }
      return 'right';
    },
    computeTaskPopoverPosX: function computeTaskPopoverPosX(index) {
      if (this.stepLayout === 'horizontal' && this.computeTaskPosX(index) + 140 + 410 > this.computeScreenWidth) {
        return this.computeTaskPosX(index) - 590;
      }
      return this.computeTaskPosX(index) + (this.stepLayout === 'horizontal' ? 175 : 70);
    },
    computeTaskPosX: function computeTaskPosX(index) {
      var recompute = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      if (this.computedTaskList.length === 0) {
        return 0;
      }
      /* Temp remove persistent positioning*/
      /*
      var response = this.isStepRelatedToPhase(this.computedTaskList[index].journeyStep.id)
      if (response[0]) {
        var phase = this.getPhaseAndIndexFromID(response[1])
        for (var i = 0; i < this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[phase[1]].steps.length; i++) {
          if (this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[phase[1]].steps[i].id === this.computedTaskList[index].journeyStep.id) {
            if (this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[phase[1]].steps[i].pos.horizontal.x > 0 && this.stepLayout === 'horizontal') {
              return this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[phase[1]].steps[i].pos.horizontal.x
            }
            if (this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[phase[1]].steps[i].pos.matrix.x > 0 && this.stepLayout === 'matrix') {
              return this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[phase[1]].steps[i].pos.matrix.x
            }
          }
        }
      }
      */

      if (this.task_pos_x.length === 0 || recompute) {
        this.computeTaskX();
      }
      return this.task_pos_x[index];
    },
    computeTaskPosY: function computeTaskPosY(index) {
      if (this.computedTaskList.length === 0) {
        return 0;
      }
      var response = this.isStepRelatedToPhase(this.computedTaskList[index].journeyStep.id);
      if (response[0]) {
        var phase = this.getPhaseAndIndexFromID(response[1]);
        for (var i = 0; i < this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[phase[1]].steps.length; i++) {
          if (this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[phase[1]].steps[i].id === this.computedTaskList[index].journeyStep.id) {
            if (this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[phase[1]].steps[i].pos.horizontal.y > 0 && this.stepLayout === 'horizontal') {
              return this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[phase[1]].steps[i].pos.horizontal.y;
            }
            if (this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[phase[1]].steps[i].pos.matrix.y > 0 && this.stepLayout === 'matrix') {
              return this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[phase[1]].steps[i].pos.matrix.y;
            }
          }
        }
      }
      if (this.task_pos_y.length === 0) {
        this.computeTaskY();
      }
      return this.task_pos_y[index];
    },
    computeTaskX: function computeTaskX() {
      this.task_pos_x = [];
      var focus_mode_phase_start_x = 0;
      var phase_index = 0;
      var phase_start_x = 0;
      var relation_index = 0;
      // HORIZONTAL LAYOUT
      if (this.stepLayout === 'horizontal') {
        if (this.isPhaseFocusMode && this.journeyMap && this.computedTaskList) {
          for (var i = 0; i < this.computedTaskList.length; i++) {
            if (this.selectedPhase.relations && this.selectedPhase.relations.length > 0) {
              focus_mode_phase_start_x = this.computePhasePosX(this.selectedPhaseIndex) + 38;
              for (var k = 0; k < this.selectedPhase.relations.length; k++) {
                if (this.selectedPhase.relations[k] === this.computedTaskList[i].journeyStep.id) {
                  this.task_pos_x.push(focus_mode_phase_start_x + 50 + k * 135);
                }
              }
            }
          }
        } else if (this.journeyMap && this.computedTaskList) {
          for (var n = 0; n < this.computedTaskList.length; n++) {
            for (var m = 0; m < this.computedRelations.length; m++) {
              if (this.computedRelations[m].end_type === 'Step' && this.computedRelations[m].start_type === 'Phase' && this.computedRelations[m].end_id === this.computedTaskList[n].journeyStep.id) {
                phase_index = this.getPhaseAndIndexFromID(this.computedRelations[m].start_id)[1];
                phase_start_x = this.computePhasePosX(phase_index) + 38;
                relation_index = this.phaseStepRelation[phase_index].indexOf(this.computedRelations[m].end_id);
                this.task_pos_x.push(phase_start_x + 90 + Math.floor(relation_index / this.computedTasksPerColumn) * 140);
              }
            }
          }
        }
      } else {
        // GRID LAYOUT
        if (this.isPhaseFocusMode && this.journeyMap && this.computedTaskList) {
          for (var o = 0; o < this.computedTaskList.length; o++) {
            if (this.selectedPhase.relations && this.selectedPhase.relations.length > 0) {
              focus_mode_phase_start_x = this.computePhasePosX(this.selectedPhaseIndex) + 38;
              for (var p = 0; p < this.selectedPhase.relations.length; p++) {
                if (this.selectedPhase.relations[p] === this.computedTaskList[o].journeyStep.id) {
                  this.task_pos_x.push(focus_mode_phase_start_x + 50 + p * 135);
                }
              }
            }
          }
        } else if (this.journeyMap && this.computedTaskList && this.phaseStepRelation.length > 0) {
          for (var q = 0; q < this.computedTaskList.length; q++) {
            for (var r = 0; r < this.computedRelations.length; r++) {
              if (this.computedRelations[r].end_type === 'Step' && this.computedRelations[r].start_type === 'Phase' && this.computedRelations[r].end_id === this.computedTaskList[q].journeyStep.id) {
                phase_index = this.getPhaseAndIndexFromID(this.computedRelations[r].start_id)[1];
                phase_start_x = this.computePhasePosX(phase_index) + 38;
                relation_index = this.phaseStepRelation[phase_index].indexOf(this.computedRelations[r].end_id);
                this.task_pos_x.push(phase_start_x + 90 + Math.floor(relation_index / 4) * 4 * 15);
              }
            }
          }
        }
      }
    },
    computeTaskY: function computeTaskY() {
      this.task_pos_y = [];
      // HORIZONTAL LAYOUT
      if (this.stepLayout === 'horizontal') {
        var temp_relations = [];
        for (var n = 0; n < this.computedTaskList.length; n++) {
          for (var m = 0; m < this.computedRelations.length; m++) {
            // Filter out relations from phase to step.
            // This has all the steps and its corresponding phases. Easier to group under phases
            if (this.computedRelations[m].end_type === 'Step' && this.computedRelations[m].start_type === 'Phase' && this.computedRelations[m].end_id === this.computedTaskList[n].journeyStep.id && this.phaseStepRelation.length > 0) {
              temp_relations.push(this.computedRelations[m]);
            }
          }
        }
        for (var p = 0; p < temp_relations.length; p++) {
          var phase_index = this.getPhaseAndIndexFromID(temp_relations[p].start_id)[1];
          // var relation_index = this.phaseStepRelation[phase_index].indexOf(temp_relations[p].end_id)
          for (var r = 0; r < this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[phase_index].steps.length; r++) {
            if (this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[phase_index].steps[r].id === temp_relations[p].end_id) {
              this.task_pos_y.push(100 + r % this.computedTasksPerColumn * 35);
            }
          }
        }
      } else {
        // GRID LAYOUT
        // Split array to sub array based on row size
        var size = 4;
        var phaseStepArray = [];
        for (var i = 0; i < this.phaseStepRelation.length; i++) {
          phaseStepArray[i] = [];
          for (var j = 0; j < this.phaseStepRelation[i].length; j += size) {
            phaseStepArray[i].push(this.phaseStepRelation[i].slice(j, j + size));
          }
        }

        // Phase
        for (var k = 0; k < phaseStepArray.length; k++) {
          // Step columns for each phase
          for (var l = 0; l < phaseStepArray[k].length; l++) {
            if (phaseStepArray[k][l].length % 2 === 0) {
              var half_length = phaseStepArray[k][l].length / 2;
              for (var o = 0; o < phaseStepArray[k][l].length; o++) {
                var pos1 = 260 + (o - half_length) * 55;
                this.updateTaskPosY(pos1, phaseStepArray[k][l][o]);
              }
            } else {
              var middle_index = (phaseStepArray[k][l].length - 1) / 2;
              for (var q = 0; q < phaseStepArray[k][l].length; q++) {
                var pos3 = 232 + (q - middle_index) * 55;
                this.updateTaskPosY(pos3, phaseStepArray[k][l][q]);
              }
            }
          }
        }
      }
    },
    updateTaskPosY: function updateTaskPosY(pos, step_id) {
      for (var i = 0; i < this.computedTaskList.length; i++) {
        if (this.computedTaskList[i].journeyStep.id === step_id) {
          this.task_pos_y[i] = pos;
        }
      }
    },
    computePhasePopoverPosX: function computePhasePopoverPosX(index) {
      if (this.computePhasePosX(index) + this.computePhaseLength(index) + 650 > this.computeScreenWidth) {
        var x = this.computePhasePosX(index) + this.computePhaseLength(index) + 650 - this.computeScreenWidth;
        return this.computePhasePosX(index) - x;
      }
      return this.computePhasePosX(index);
    },
    computePhasePosX: function computePhasePosX(index) {
      if (this.isPhaseFocusMode) {
        return 50 + this.isCompactMenubar ? 0 : 112;
      }
      if (this.phase_pos_x.length === 0) {
        this.computePhaseX();
      }
      return this.phase_pos_x[index];
    },
    computePhaseX: function computePhaseX() {
      this.phase_pos_x = [];
      var expandSpacing = this.isCompactMenubar ? 0 : 112;
      if (this.sortedStages) {
        for (var i = 0; i < this.sortedStages.length; i++) {
          if (i === 0) {
            this.phase_pos_x.push(this.canvasDragCoefficientX + 75 + expandSpacing);
          } else {
            var length = 0;
            for (var j = 0; j < i; j++) {
              length += this.computePhaseLength(this.sortedStages[j].journeyPhase, j);
              length += 75;
            }
            this.phase_pos_x.push(this.canvasDragCoefficientX + 75 + length + expandSpacing);
          }
        }
      }
    },
    computePhaseLengthArray: function computePhaseLengthArray() {
      this.phase_length = [];
      if (this.sortedStages) {
        for (var i = 0; i < this.sortedStages.length; i++) {
          this.phase_length.push(this.computePhaseLength(this.sortedStages[i].journeyPhase, i));
        }
      }
    },
    computePhaseLength: function computePhaseLength(phase, index) {
      // full width phase if focus mode is active
      if (this.isPhaseFocusMode) {
        return 1250;
      }
      // get max number of features in product for given phase
      // if less than 2 use default 175, else use length off features
      // var max_count = 0
      var max_step_count = 0;
      // var max_experience_count = 0
      // Get an array of pains/gains for current phase
      if (typeof phase === 'undefined') {
        return 225;
      }
      // var step_experience_list = []

      // Compute list of all stps for given phase
      // Also take a list of all ids of end points. This will be used to compute experience count for step and phase
      for (var i = 0; i < this.computedRelations.length; i++) {
        if (this.computedRelations[i].start_type === 'Phase' && this.computedRelations[i].end_type === 'Step' && this.computedRelations[i].start_id === phase.id) {
          /* if (this.computedRelations[i].start_type === 'Phase') {
            step_experience_list.push(this.computedRelations[i].end_id)
          }*/
          max_step_count++;
        }
      }
      /* // compute experience count for phase and step
      // step_experience_list contains all ids which are releated to given phase. (could be step or experience)
      for (var j = 0; j < this.computedRelations.length; j++) {
        if (this.computedRelations[j].end_type === 'Experience' &&
          step_experience_list.indexOf(this.computedRelations[j].end_id) !== -1) {
            max_experience_count++
        }
        if (this.computedRelations[j].end_type === 'Step') {
          // phase related to given step, get list of experiences for given step
          for (var k = 0; k < this.computedRelations.length; k++) {
            if (this.computedRelations[k].start_type === 'Step' &&
            this.computedRelations[k].end_type === 'Experience' &&
            this.computedRelations[k].start_id === this.computedRelations[j].end_id &&
          step_experience_list.indexOf(this.computedRelations[j].end_id) !== -1) {
              max_experience_count++
            }
          }
        }
      }
      if (max_step_count > max_experience_count) {
        max_count = max_step_count
      } else {
        max_count = max_experience_count
      }*/
      var additional_columns = 0;
      var first_column = 0;
      additional_columns = Math.floor(max_step_count / (this.computedTasksPerColumn + 1)) - 1;
      first_column = 150;
      return 225 + first_column + additional_columns * 150;
    },
    computeExperienceX: function computeExperienceX() {
      this.experience_pos_x = [];
      for (var i = 0; i < this.computedExperienceList.length; i++) {
        this.experience_pos_x.push(this.computeExperienceStartX(i));
      }
    },
    computeExperienceY: function computeExperienceY() {
      this.experience_pos_y = [];
      for (var i = 0; i < this.computedExperienceList.length; i++) {
        this.experience_pos_y.push(this.computeExperienceStartY(i));
      }
    },
    computeExperiencePosX: function computeExperiencePosX(index) {
      if (this.experience_pos_x.length === 0) {
        this.computeExperienceX();
      }
      return this.experience_pos_x[index];
    },
    computeExperiencePosY: function computeExperiencePosY(index) {
      if (this.experience_pos_y.length === 0) {
        this.computeExperienceY();
      }
      return this.experience_pos_y[index];
    },
    computeZoomCoefficient: function computeZoomCoefficient(pos) {
      return pos + Math.round(pos * ((100 - this.journeyZoom) / 100));
    },
    getPhaseAndIndexFromID: function getPhaseAndIndexFromID(phase_id) {
      for (var i = 0; i < this.sortedStages.length; i++) {
        if (this.sortedStages[i].journeyPhase.id === phase_id) {
          return [this.sortedStages[i].journeyPhase, i];
        }
      }
      return [{}, null];
    },
    getStepAndIndexFromID: function getStepAndIndexFromID(step_id) {
      for (var i = 0; i < this.computedTaskList.length; i++) {
        if (this.computedTaskList[i].journeyStep.id === step_id) {
          return [this.computedTaskList[i].journeyStep, i];
        }
      }
      return [{}, null];
    },
    isExperienceRelatedToPhase: function isExperienceRelatedToPhase(experience_id) {
      for (var i = 0; i < this.computedRelations.length; i++) {
        if (this.computedRelations[i].end_id === experience_id && this.computedRelations[i].start_type === 'Phase') {
          return [true, this.computedRelations[i].start_id];
        }
      }
      return [false, null];
    },
    isExperienceRelatedToStep: function isExperienceRelatedToStep(experience_id) {
      for (var i = 0; i < this.computedRelations.length; i++) {
        if (this.computedRelations[i].end_id === experience_id && this.computedRelations[i].start_type === 'Step') {
          return [true, this.computedRelations[i].start_id];
        }
      }
      return [false, null];
    },
    isStepRelatedToPhase: function isStepRelatedToPhase(step_id) {
      for (var i = 0; i < this.computedRelations.length; i++) {
        if (this.computedRelations[i].end_id === step_id && this.computedRelations[i].start_type === 'Phase') {
          return [true, this.computedRelations[i].start_id];
        }
      }
      return [false, null];
    },
    isStepRelatedToPhaseID: function isStepRelatedToPhaseID(phase_id, step_id) {
      for (var i = 0; i < this.computedRelations.length; i++) {
        if (this.computedRelations[i].end_id === step_id && this.computedRelations[i].start_id === phase_id) {
          return true;
        }
      }
      return false;
    },
    generateDiamondPath: function generateDiamondPath(x, y) {
      var x1 = x + 1;
      var y1 = y + 1;
      var x2 = x + 2;
      var y2 = y + 2;
      return 'M ' + x1 + ' ' + y + ' L ' + x + ' ' + y1 + ' L ' + x1 + ' ' + y2 + ' L ' + x2 + ' ' + y1 + ' Z';
    },
    computeTempTaskRelation: function computeTempTaskRelation() {
      return this.generateBezierPath(this.tempTaskRelationPath.x1, this.tempTaskRelationPath.y1, this.tempTaskRelationPath.x2, this.tempTaskRelationPath.y2);
    },
    generateBezierPath: function generateBezierPath(start_x1, start_y1, start_x2, start_y2) {
      var end_x1 = start_x1;
      var end_y1 = start_y1 + (start_y2 - start_y1) / 2;
      var end_x2 = start_x2;
      var end_y2 = start_y2 - (start_y2 - start_y1) / 2;
      if (start_x1 !== undefined && start_x2 !== undefined) {
        return 'M' + start_x1 + ',' + start_y1 + ' C' + end_x1 + ',' + end_y1 + ' ' + end_x2 + ',' + end_y2 + ' ' + start_x2 + ',' + start_y2;
      } else {
        return 'M 0,0';
      }
    },
    runRelationCleanup: function runRelationCleanup() {
      var _this2 = this;
      var resetcontrolpoints = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      var additionalattributes = this.journeyMap.additionalattributes;
      for (var i = 0; i < additionalattributes.relations.length; i++) {
        additionalattributes.relations[i].styleonhover.color = '#000080';
      }
      for (var j = 0; j < additionalattributes.step_relations.length; j++) {
        additionalattributes.step_relations[j].styleonhover.color = '#000080';
      }
      this.loading = true;
      this.$apollo.mutate({
        // Query
        mutation: window._gql(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["mutation updateJourneyMap($journeymap: UpdateJourneyMapInput!)\n        {\n          updateJourneyMap(input: $journeymap) {\n            id\n          }\n        }"]))),
        // Parameters
        variables: {
          'journeymap': {
            'id': this.journeyMap.id,
            'additionalattributes': additionalattributes
          }
        }
      }).then(function (data) {
        _this2.loading = false;
      }).catch(function (error) {
        // Error
        _this2.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this2.loading = false;
      });
    },
    hasMaxTouchpoints: function hasMaxTouchpoints(stage_id) {
      for (var i = 0; i < this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages.length; i++) {
        if (this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[i].id === stage_id) {
          return this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[i].touchpoints.length >= 6;
        }
      }
      return false;
    },
    // END HELPER METHODS
    // RESET
    resetTempTaskRelation: function resetTempTaskRelation() {
      this.tempTaskRelationPath = {
        x1: 0,
        y1: 0,
        x2: 0,
        y2: 0
      };
    },
    resetHighlightActivePhaseBlockGap: function resetHighlightActivePhaseBlockGap() {
      this.activePhaseGapHighlighter = {
        x: 0,
        y: 0,
        width: 0,
        height: 0
      };
    },
    resetHighlightAllPhaseBlockGap: function resetHighlightAllPhaseBlockGap() {
      this.phaseGapHighlighter = [];
    },
    resetHighlightActivePhaseBlock: function resetHighlightActivePhaseBlock() {
      this.activePhaseHighlighter = {
        x: 0,
        y: 0,
        width: 0,
        height: 0
      };
    },
    resetHighlightAllPhaseHighlighter: function resetHighlightAllPhaseHighlighter() {
      this.phaseHighlighter = [];
    },
    resetTools: function resetTools() {
      if (this.isCompactMenubar) {
        this.toolbox = {
          phase: {
            x: 50,
            y: 43
          },
          task: {
            x: 55,
            y: 130
          },
          menu_phase: {
            x: 50,
            y: 2
          },
          menu_task: {
            x: 150,
            y: 2
          },
          idea: {
            x: 250,
            y: 2
          },
          risk: {
            x: 350,
            y: 2
          }
        };
      } else {
        this.toolbox = {
          phase: {
            x: 50,
            y: 38
          },
          task: {
            x: 55,
            y: 130
          },
          menu_phase: {
            x: 160,
            y: 2
          },
          menu_task: {
            x: 260,
            y: 2
          },
          idea: {
            x: 360,
            y: 2
          },
          risk: {
            x: 460,
            y: 2
          }
        };
      }
    },
    resetHoverDetailsDialog: function resetHoverDetailsDialog() {
      this.phaseHover = false;
      this.taskHover = false;
      this.experienceHover = false;
      this.touchpointHover = false;
    } // END RESET
  }
};