"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _DomainProto = _interopRequireDefault(require("./components/DomainProto.vue"));
var _OneWayArrowGroup = _interopRequireDefault(require("./components/OneWayArrowGroup.vue"));
var _vueSvgPanZoom = _interopRequireDefault(require("vue-svg-pan-zoom"));
var _utils = require("@/utils");
/*
        Formula to add new domains to assisted nav.
        'a' in radians 0 - 2pi
        cx = 400
        cy = 500
        r = 400
        x = cx + r * cos(a)
        y = cy + r * sin(a)
      */
// import { TweenLite, TimelineLite, TweenMax, CSSPlugin, ScrollToPlugin, Draggable, Elastic } from 'gsap/all'
var _default = exports.default = {
  name: 'HomeNew',
  components: {
    DomainComponent: _DomainProto.default,
    OneWayArrowGroupComponent: _OneWayArrowGroup.default,
    SvgPanZoom: _vueSvgPanZoom.default
  },
  data: function data() {
    return {
      loading: false,
      domainDocumentsEditDialog: false,
      activeDomain: null,
      svgpanzoom: null,
      legendVisible: false,
      expandDomains: false,
      size: {
        width: '1700px',
        height: '800px'
      },
      arrow: {
        count: 5,
        start_x: 0,
        start_y: 0,
        increment_size: 75
      },
      assistedNav: {
        domains: [{
          key: 1,
          textLine1: 'Plan',
          textLine2: 'Product',
          cx: '19.57',
          cy: '396.39',
          color: '#000080',
          status: 'Completed',
          processes: [{
            key: 1,
            type: 'linear',
            textLine1: 'Business',
            textLine2: 'Strategy',
            owner: 'Justin Hume',
            path: 'processes/plan-business/business-strategy',
            documentation: {
              name: 'Business Strategy',
              type: 'Confluence',
              url: 'https://knowledgeflux.atlassian.net/wiki/spaces/proddev17/pages/74514452/Business+Strategy'
            }
          }, {
            key: 2,
            type: 'ongoing',
            textLine1: 'Segment &',
            textLine2: 'Customer Types',
            owner: 'Justin Hume',
            path: 'processes/plan-business/segment-customer-types',
            documentation: {
              name: 'Segment & Customer Types',
              type: 'Confluence',
              url: 'https://knowledgeflux.atlassian.net/wiki/spaces/proddev17/pages/496992284/Market+Segments+Customer+Types'
            }
          }, {
            key: 3,
            type: 'ongoing',
            textLine1: 'Strategic',
            textLine2: 'Features',
            owner: 'Justin Hume',
            path: 'processes/plan-business/strategic-features',
            documentation: {
              name: 'Strategic Features',
              type: 'Confluence',
              url: 'https://confluence.com'
            }
          }, {
            key: 4,
            type: 'linear',
            textLine1: 'Monitor',
            textLine2: 'Competitors',
            owner: 'Justin Hume',
            path: 'processes/plan-business/monitor-competitors',
            documentation: {
              name: 'Monitor Competitors',
              type: 'Confluence',
              url: 'https://knowledgeflux.atlassian.net/wiki/spaces/proddev17/pages/501252241/Competing+products'
            }
          }, {
            key: 5,
            type: 'linear',
            textLine1: 'Model Revenue',
            textLine2: '(NYS)',
            owner: 'Justin Hume',
            path: 'processes/plan-business/model-revenue',
            documentation: {
              name: 'Model Revenue (NYS)',
              type: 'Confluence',
              url: 'https://knowledgeflux.atlassian.net/wiki/spaces/proddev17/pages/503906305/Revenue+Modelling'
            }
          }
          /* APPROVALS AND DECISION FUNCTIONALITY TBC TASK ENGINE.
          {
            key: 8,
            type: 'linear',
            textLine1: 'Approvals &',
            textLine2: 'Decesions',
            owner: 'Justin Hume',
            path: ''
          }*/],
          documents: [{
            name: 'Document 1',
            type: 'Word',
            url: 'https://word.com'
          }, {
            name: 'Document 2',
            type: 'Confluence',
            url: 'https://confluence.com'
          }]
        }, {
          key: 2,
          textLine1: 'Create',
          textLine2: 'User Value',
          cx: '164.88',
          cy: '843.60',
          color: '#008000',
          status: 'Completed',
          processes: [{
            key: 1,
            type: 'linear',
            textLine1: 'Identify',
            textLine2: 'Users',
            owner: 'Justin Hume',
            path: 'processes/create-user-value/identify-user',
            documentation: {
              name: 'Identify Users',
              type: 'Confluence',
              url: 'https://knowledgeflux.atlassian.net/wiki/spaces/proddev17/pages/47421007/User+Types+Value+Journeys'
            }
          }, {
            key: 2,
            type: 'linear',
            textLine1: 'Manage User',
            textLine2: 'Experience',
            owner: 'Justin Hume',
            path: 'processes/create-user-value/manage-user-experience',
            documentation: {
              name: 'User Types, Value & Journeys',
              type: 'Confluence',
              url: 'https://knowledgeflux.atlassian.net/wiki/spaces/proddev17/pages/47421007/User+Types+Value+Journeys'
            }
          }, {
            key: 3,
            type: 'linear',
            textLine1: 'Ideate',
            textLine2: 'Features',
            owner: 'Justin Hume',
            path: 'processes/create-user-value/ideate-features',
            documentation: {
              name: 'Ideate Features',
              type: 'Confluence',
              url: 'https://knowledgeflux.atlassian.net/wiki/spaces/proddev17/pages/506429448/Mockups+Storyboards+And+a+little+more...'
            }
          }, {
            key: 4,
            type: 'linear',
            textLine1: 'Refine',
            textLine2: 'Features',
            owner: 'Justin Hume',
            path: 'processes/create-user-value/refine-features',
            documentation: {
              name: 'Refine Features',
              type: 'Confluence',
              url: 'https://knowledgeflux.atlassian.net/wiki/spaces/proddev17/pages/47420673/Feature+Management'
            }
          }, {
            key: 5,
            type: 'linear',
            textLine1: 'Roadmap',
            textLine2: 'Features',
            owner: 'Justin Hume',
            path: 'processes/create-user-value/roadmap-features',
            documentation: {
              name: 'Feature Roadmap',
              type: 'Confluence',
              url: 'https://knowledgeflux.atlassian.net/wiki/spaces/proddev17/pages/513212677/Feature+Roadmap'
            }
          }, {
            key: 6,
            type: 'linear',
            textLine1: 'Mockup &',
            textLine2: 'Storyboards',
            owner: 'Justin Hume',
            path: 'processes/create-user-value/mockup-storyboards',
            documentation: {
              name: 'Mockup & Storyboards',
              type: 'Confluence',
              url: 'https://knowledgeflux.atlassian.net/wiki/spaces/proddev17/pages/506429448/Mockups+Storyboards+And+a+little+more...'
            }
          }, {
            key: 7,
            type: 'linear',
            textLine1: 'Validate',
            textLine2: 'Features',
            owner: 'Justin Hume',
            path: '',
            documentation: {
              name: 'Validate With Users',
              type: 'Confluence',
              url: 'https://knowledgeflux.atlassian.net/wiki/spaces/proddev17/pages/75071510/User+Validation'
            }
          }],
          documents: [{
            name: 'Document 3',
            type: 'Word',
            url: 'https://word.com'
          }, {
            name: 'Document 4',
            type: 'Confluence',
            url: 'https://confluence.com'
          }]
        }, {
          key: 3,
          textLine1: 'Design',
          textLine2: 'Product',
          cx: '635.11',
          cy: '843.60',
          color: '#F26D14',
          status: 'In Progress',
          processes: [{
            key: 1,
            type: 'linear',
            textLine1: 'Define User',
            textLine2: 'Interactions',
            owner: 'Justin Hume',
            path: 'processes/design-product/define-user-interactions',
            documentation: {
              name: 'Define User Interactions',
              type: 'Confluence',
              url: 'https://knowledgeflux.atlassian.net/wiki/spaces/proddev17/pages/47421222/Define+User+Interactions'
            }
          }, {
            key: 2,
            type: 'linear',
            textLine1: 'Navigation &',
            textLine2: 'Wireframes',
            owner: 'Justin Hume',
            path: 'processes/design-product/navigation-and-wireframe',
            documentation: {
              name: 'Navigation & Wireframes',
              type: 'Confluence',
              url: 'https://knowledgeflux.atlassian.net/wiki/spaces/proddev17/pages/560202230/Navigation+Wireframes'
            }
          }, {
            key: 3,
            type: 'linear',
            textLine1: 'Design',
            textLine2: 'Data',
            owner: 'Justin Hume',
            path: 'processes/design-product/data-design',
            documentation: {
              name: 'Design Data Layer',
              type: 'Confluence',
              url: 'https://knowledgeflux.atlassian.net/wiki/spaces/proddev17/pages/512753862/Design+Data+Layer'
            }
          }, {
            key: 4,
            type: 'linear',
            textLine1: 'Components',
            textLine2: '& APIs',
            owner: 'Justin Hume',
            path: 'processes/design-product/components-and-apis',
            documentation: {
              name: 'Design Components & Services',
              type: 'Confluence',
              url: 'https://knowledgeflux.atlassian.net/wiki/spaces/proddev17/pages/514359630/Design+Components+Services'
            }
          }],
          documents: [{
            name: 'Document 5',
            type: 'Word',
            url: 'https://word.com'
          }, {
            name: 'Document 6',
            type: 'Confluence',
            url: 'https://confluence.com'
          }]
        }, {
          key: 4,
          textLine1: 'Build',
          textLine2: '& Test',
          cx: '780.42',
          cy: '396.39',
          color: '#FF0000',
          status: 'Pending',
          processes: [],
          documents: []
        }, {
          key: 5,
          textLine1: 'Operate',
          textLine2: '& Improve',
          cx: '400',
          cy: '120',
          color: '#9FA39C',
          status: 'Pending',
          processes: [{
            key: 1,
            type: 'linear',
            textLine1: 'Analyse',
            textLine2: 'Usage',
            owner: 'Justin Hume',
            path: 'processes/operate-product/analyse-usage',
            documentation: {
              name: 'Analyse Usage',
              type: 'Confluence',
              url: 'https://confluence.com'
            }
          }],
          documents: [{
            name: 'Document 7',
            type: 'Word',
            url: 'https://word.com'
          }, {
            name: 'Document 8',
            type: 'Confluence',
            url: 'https://confluence.com'
          }]
        }, {
          key: 6,
          textLine1: 'Market',
          textLine2: '& Grow',
          cx: '400',
          cy: '520',
          color: '#DD33FF',
          status: 'Pending',
          processes: [{
            key: 1,
            type: 'linear',
            textLine1: 'Dummy 1',
            textLine2: '',
            owner: 'Justin Hume',
            path: 'processes/market-grow/dummy1',
            documentation: {
              name: 'Dummy 1',
              type: 'Confluence',
              url: 'https://confluence.com'
            }
          }, {
            key: 2,
            type: 'linear',
            textLine1: 'Dummy 2',
            textLine2: '',
            owner: 'Justin Hume',
            path: 'processes/market-grow/dummy2',
            documentation: {
              name: 'Dummy 2',
              type: 'Confluence',
              url: 'https://confluence.com'
            }
          }],
          documents: [{
            name: 'Document 9',
            type: 'Word',
            url: 'https://word.com'
          }, {
            name: 'Document 10',
            type: 'Confluence',
            url: 'https://confluence.com'
          }]
        }],
        relations: []
      },
      activeProcessDocuments: [],
      activeProcessAdditionalDocuments: [],
      assistedNavDocs: [{
        processes: [{
          key: 1,
          type: 'linear',
          textLine1: 'Vision Goals',
          textLine2: '& Roadmap',
          owner: 'Justin Hume',
          path: 'ideas',
          documentation: {
            name: 'Vision Goals & Roadmap',
            type: 'Confluence',
            url: 'https://confluence.com'
          }
        }, {
          key: 2,
          type: 'ongoing',
          textLine1: 'Ideation',
          textLine2: '',
          owner: 'Justin Hume',
          path: 'ideas',
          documentation: {
            name: 'Ideation',
            type: 'Confluence',
            url: 'https://confluence.com'
          }
        }, {
          key: 3,
          type: 'ongoing',
          textLine1: 'Customers',
          textLine2: '',
          owner: 'Justin Hume',
          path: 'processes/customers',
          documentation: {
            name: 'Customers',
            type: 'Confluence',
            url: 'https://confluence.com'
          }
        }, {
          key: 4,
          type: 'linear',
          textLine1: 'Monitor',
          textLine2: 'Competitors',
          owner: 'Justin Hume',
          path: 'processes/plan-product/monitor-competitors',
          documentation: {
            name: 'Monitor Competitors',
            type: 'Confluence',
            url: 'https://confluence.com'
          }
        }],
        documents: [{
          name: 'Document 1',
          type: 'Word',
          url: 'https://word.com'
        }, {
          name: 'Document 2',
          type: 'Confluence',
          url: 'https://confluence.com'
        }]
      }, {
        processes: [{
          key: 1,
          type: 'linear',
          textLine1: 'Product',
          textLine2: 'Strategy',
          owner: 'Justin Hume',
          path: 'processes/plan-product/product-strategy',
          documentation: {
            name: 'Product Strategy',
            type: 'Confluence',
            url: 'https://confluence.com'
          }
        }, {
          key: 2,
          type: 'ongoing',
          textLine1: 'Product',
          textLine2: 'Competitors',
          owner: 'Justin Hume',
          path: 'processes/plan-product/product-competitors',
          documentation: {
            name: 'Product Competitors',
            type: 'Confluence',
            url: 'https://confluence.com'
          }
        }, {
          key: 3,
          type: 'ongoing',
          textLine1: 'Strategic',
          textLine2: 'Features',
          owner: 'Justin Hume',
          path: 'processes/plan-product/strategic-features',
          documentation: {
            name: 'Strategic Features',
            type: 'Confluence',
            url: 'https://confluence.com'
          }
        }, {
          key: 4,
          type: 'linear',
          textLine1: 'Financials',
          textLine2: '',
          owner: 'Justin Hume',
          path: 'processes/plan-product/financials',
          documentation: {
            name: 'Financials',
            type: 'Confluence',
            url: 'https://confluence.com'
          }
        }],
        documents: [{
          name: 'Document 1',
          type: 'Word',
          url: 'https://word.com'
        }, {
          name: 'Document 2',
          type: 'Confluence',
          url: 'https://confluence.com'
        }]
      }, {
        processes: [{
          key: 1,
          type: 'linear',
          textLine1: 'Define',
          textLine2: 'User',
          owner: 'Justin Hume',
          path: 'processes/create-user-value/define-user-value',
          documentation: {
            name: 'Define user',
            type: 'Confluence',
            url: 'https://knowledgeflux.atlassian.net/wiki/spaces/proddev17/pages/47421007/User+Types+Value+Journeys'
          }
        }, {
          key: 2,
          type: 'linear',
          textLine1: 'Refine',
          textLine2: 'Features',
          owner: 'Justin Hume',
          path: 'processes/create-user-value/refine-features',
          documentation: {
            name: 'Refine Features',
            type: 'Confluence',
            url: 'https://knowledgeflux.atlassian.net/wiki/spaces/proddev17/pages/47420673/Feature+Management'
          }
        }, {
          key: 3,
          type: 'linear',
          textLine1: 'Mockup',
          textLine2: 'Features',
          owner: 'Justin Hume',
          path: 'processes/create-user-value/mockup-storyboards',
          documentation: {
            name: 'Mockup Features',
            type: 'Confluence',
            url: 'https://knowledgeflux.atlassian.net/wiki/spaces/proddev17/pages/506429448/Mockups+Storyboards+And+a+little+more...'
          }
        }, {
          key: 4,
          type: 'linear',
          textLine1: 'Roadmap',
          textLine2: 'Features',
          owner: 'Justin Hume',
          path: 'processes/create-user-value/roadmap-features',
          documentation: {
            name: 'Feature Roadmap',
            type: 'Confluence',
            url: 'https://knowledgeflux.atlassian.net/wiki/spaces/proddev17/pages/513212677/Feature+Roadmap'
          }
        }, {
          key: 5,
          type: 'linear',
          textLine1: 'Validate',
          textLine2: 'Features',
          owner: 'Justin Hume',
          path: '',
          documentation: {
            name: 'Validate Features',
            type: 'Confluence',
            url: 'https://knowledgeflux.atlassian.net/wiki/spaces/proddev17/pages/75071510/User+Validation'
          }
        }],
        documents: [{
          name: 'Document 3',
          type: 'Word',
          url: 'https://word.com'
        }, {
          name: 'Document 4',
          type: 'Confluence',
          url: 'https://confluence.com'
        }]
      }, {
        processes: [{
          key: 1,
          type: 'linear',
          textLine1: 'Define User',
          textLine2: 'Interactions',
          owner: 'Justin Hume',
          path: 'processes/design-product/define-user-interactions',
          documentation: {
            name: 'Define User Interactions',
            type: 'Confluence',
            url: 'https://knowledgeflux.atlassian.net/wiki/spaces/proddev17/pages/47421222/Define+User+Interactions'
          }
        }, {
          key: 2,
          type: 'linear',
          textLine1: 'Navigation &',
          textLine2: 'Wireframes',
          owner: 'Justin Hume',
          path: 'processes/design-product/navigation-and-wireframe',
          documentation: {
            name: 'Navigation & Wireframes',
            type: 'Confluence',
            url: 'https://knowledgeflux.atlassian.net/wiki/spaces/proddev17/pages/560202230/Navigation+Wireframes'
          }
        }, {
          key: 3,
          type: 'linear',
          textLine1: 'Components',
          textLine2: '& APIs',
          owner: 'Justin Hume',
          path: 'processes/design-product/components-and-apis',
          documentation: {
            name: 'Design Components & Services',
            type: 'Confluence',
            url: 'https://knowledgeflux.atlassian.net/wiki/spaces/proddev17/pages/514359630/Design+Components+Services'
          }
        }, {
          key: 4,
          type: 'linear',
          textLine1: 'Design',
          textLine2: 'Data',
          owner: 'Justin Hume',
          path: 'processes/design-product/data-design',
          documentation: {
            name: 'Design Data Layer',
            type: 'Confluence',
            url: 'https://knowledgeflux.atlassian.net/wiki/spaces/proddev17/pages/512753862/Design+Data+Layer'
          }
        }],
        documents: [{
          name: 'Document 5',
          type: 'Word',
          url: 'https://word.com'
        }, {
          name: 'Document 6',
          type: 'Confluence',
          url: 'https://confluence.com'
        }]
      }, {
        processes: [{
          key: 1,
          type: 'linear',
          textLine1: 'CICD',
          textLine2: '',
          owner: 'Justin Hume',
          path: 'processes/build-software/analyse-usage',
          documentation: {
            name: 'CICD',
            type: 'Confluence',
            url: 'https://confluence.com'
          }
        }, {
          key: 2,
          type: 'linear',
          textLine1: 'Quality',
          textLine2: '',
          owner: 'Justin Hume',
          path: 'processes/build-software/analyse-usage',
          documentation: {
            name: 'Quality',
            type: 'Confluence',
            url: 'https://confluence.com'
          }
        }, {
          key: 3,
          type: 'linear',
          textLine1: 'Tracking',
          textLine2: '',
          owner: 'Justin Hume',
          path: 'processes/build-software/analyse-usage',
          documentation: {
            name: 'Tracking',
            type: 'Confluence',
            url: 'https://confluence.com'
          }
        }, {
          key: 4,
          type: 'linear',
          textLine1: 'Versions',
          textLine2: '',
          owner: 'Justin Hume',
          path: 'processes/build-software/analyse-usage',
          documentation: {
            name: 'Versions',
            type: 'Confluence',
            url: 'https://confluence.com'
          }
        }],
        documents: [{
          name: 'Document 7',
          type: 'Word',
          url: 'https://word.com'
        }, {
          name: 'Document 8',
          type: 'Confluence',
          url: 'https://confluence.com'
        }]
      }, {
        processes: [],
        documents: []
      }, {
        processes: [{
          key: 1,
          type: 'linear',
          textLine1: 'Campaigns',
          textLine2: '',
          owner: 'Justin Hume',
          path: 'processes/market-grow/dummy1',
          documentation: {
            name: 'Campaigns',
            type: 'Confluence',
            url: 'https://confluence.com'
          }
        }, {
          key: 2,
          type: 'linear',
          textLine1: 'Value Eng',
          textLine2: '',
          owner: 'Justin Hume',
          path: 'processes/market-grow/dummy2',
          documentation: {
            name: 'Value Eng',
            type: 'Confluence',
            url: 'https://confluence.com'
          }
        }, {
          key: 3,
          type: 'linear',
          textLine1: 'Collateral &',
          textLine2: 'Brand',
          owner: 'Justin Hume',
          path: 'processes/market-grow/dummy2',
          documentation: {
            name: 'Collateral & Brand',
            type: 'Confluence',
            url: 'https://confluence.com'
          }
        }, {
          key: 4,
          type: 'linear',
          textLine1: 'GTM',
          textLine2: 'Strategy',
          owner: 'Justin Hume',
          path: 'processes/market-grow/dummy2',
          documentation: {
            name: 'GTM Strategy',
            type: 'Confluence',
            url: 'https://confluence.com'
          }
        }],
        documents: [{
          name: 'Document 9',
          type: 'Word',
          url: 'https://word.com'
        }, {
          name: 'Document 10',
          type: 'Confluence',
          url: 'https://confluence.com'
        }]
      }]
    };
  },
  mounted: function mounted() {
    // if no active product selected move to product selection screen
    if (this.$store.getters.product.title === undefined) {
      this.$message({
        message: 'No active product found. Select a product to continue',
        type: 'error'
      });
      this.$router.push({
        path: "/portfolio-manager"
      });
    }
    window._gsap.from('#domain_0', {
      opacity: 0,
      delay: 1,
      x: 120,
      y: 50,
      ease: 'power3.out(5)'
    });
    window._gsap.from('#domain_1', {
      opacity: 0,
      delay: 1,
      x: 50,
      y: -120,
      ease: 'power3.out(5)'
    });
    window._gsap.from('#domain_2', {
      opacity: 0,
      delay: 1,
      x: -50,
      y: -120,
      ease: 'power3.out(5)'
    });
    window._gsap.from('#domain_3', {
      opacity: 0,
      delay: 1,
      x: -120,
      y: 50,
      ease: 'power3.out(5)'
    });
    window._gsap.from('#domain_4', {
      opacity: 0,
      delay: 1,
      x: 0,
      y: 120,
      ease: 'power3.out(5)'
    });
    window._gsap.from('#domain_5', {
      opacity: 0,
      delay: 1,
      x: 0,
      y: 0,
      ease: 'power3.out(5)'
    });
    window._gsap.from('#my_business_to_plan_product', {
      opacity: 0,
      delay: 2,
      scale: 0,
      ease: 'power3.out(5)'
    });
    window._gsap.from('#operate_observe_to_plan_product', {
      opacity: 0,
      delay: 2,
      scale: 0,
      ease: 'power3.out(5)'
    });
    window._gsap.from('#plan_product_to_create_user_value', {
      opacity: 0,
      delay: 2,
      scale: 0,
      ease: 'power3.out(5)'
    });
    window._gsap.from('#plan_product_to_market_grow', {
      opacity: 0,
      delay: 2,
      scale: 0,
      ease: 'power3.out(5)'
    });
    window._gsap.from('#market_grow_to_build_software', {
      opacity: 0,
      delay: 2,
      scale: 0,
      ease: 'power3.out(5)'
    });
    window._gsap.from('#create_user_value_to_design_software', {
      opacity: 0,
      delay: 2,
      scale: 0,
      ease: 'power3.out(5)'
    });
    window._gsap.from('#create_user_value_to_market_grow', {
      opacity: 0,
      delay: 2,
      scale: 0,
      ease: 'power3.out(5)'
    });
    window._gsap.from('#design_software_to_build_and_test', {
      opacity: 0,
      delay: 2,
      scale: 0,
      ease: 'power3.out(5)'
    });
    window._gsap.from('#build_software_to_operate_observe', {
      opacity: 0,
      delay: 2,
      scale: 0,
      ease: 'power3.out(5)'
    });
    window._gsap.from('#operate_observe_to_market_grow', {
      opacity: 0,
      delay: 2,
      scale: 0,
      ease: 'power3.out(5)'
    });
    window._gsap.from('#market_grow_to_design_software', {
      opacity: 0,
      delay: 2,
      scale: 0,
      ease: 'power3.out(5)'
    });
  },
  created: function created() {
    // Listen to event
    this.$eventHub.$on('domain-document-edit', this.openDomainDocumentEditModal);
  },
  methods: {
    registerSvgPanZoom: function registerSvgPanZoom(svgpanzoom) {
      this.svgpanzoom = svgpanzoom;
    },
    onMouseOver: function onMouseOver(id) {
      this.activeDomain = id;
    },
    onMouseLeave: function onMouseLeave(id) {
      this.activeDomain = null;
    },
    isDomainActive: function isDomainActive(id) {
      return this.activeDomain === id;
    },
    checkIsTouchDevice: function checkIsTouchDevice() {
      return (0, _utils.isTouchDevice)();
    },
    openDomainDocumentEditModal: function openDomainDocumentEditModal(documents) {
      this.domainDocumentsEditDialog = true;
      this.activeProcessDocuments = documents.processes;
      this.activeProcessAdditionalDocuments = documents.additional_documents;
    },
    addAdditionalDocument: function addAdditionalDocument() {
      this.activeProcessAdditionalDocuments.push({
        name: '',
        type: 'Confluence',
        url: ''
      });
    },
    handleDomainAdditionalDocumentDelete: function handleDomainAdditionalDocumentDelete(index, row) {
      this.activeProcessAdditionalDocuments.splice(index, 1);
    },
    isConfluenceLink: function isConfluenceLink(url) {
      return url.includes('wiki');
    },
    computeDomainTitle: function computeDomainTitle(domain) {
      return domain.textLine1 + ' ' + domain.textLine2;
    },
    computeIcon: function computeIcon(status) {
      switch (status) {
        case 'Pending':
          return 'el-icon-more';
        case 'Completed':
          return 'el-icon-success';
        case 'In Progress':
          return 'el-icon-edit';
      }
    }
  }
};