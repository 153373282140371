import { render, staticRenderFns } from "./AddRelease.vue?vue&type=template&id=3c97b1ca&scoped=true"
import script from "./AddRelease.vue?vue&type=script&lang=js"
export * from "./AddRelease.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c97b1ca",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\Sastha\\Documents\\GitHub\\prodiggiapp-frontend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3c97b1ca')) {
      api.createRecord('3c97b1ca', component.options)
    } else {
      api.reload('3c97b1ca', component.options)
    }
    module.hot.accept("./AddRelease.vue?vue&type=template&id=3c97b1ca&scoped=true", function () {
      api.rerender('3c97b1ca', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/delivery/roadmap/AddRelease.vue"
export default component.exports