"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/taggedTemplateLiteral.js"));
require("core-js/modules/es6.array.sort");
var _objectSpread2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _axios = _interopRequireDefault(require("axios"));
var _vuex = require("vuex");
var _templateObject, _templateObject2;
var _default = exports.default = {
  name: 'ExternalData',
  data: function data() {
    return {
      loading: false,
      journeyMap: {},
      featureAndProcessList: [],
      showEnablers: true,
      showUnmapped: true,
      filterDialog: false,
      search_tickets: {
        q: '',
        filters: {
          title: '',
          title_operator: '',
          description: '',
          description_operator: '',
          status: [],
          type: [],
          parent: [],
          priority: []
        },
        sort: {
          title: 'a-z'
        }
      },
      filter_build_query: [{
        title: 'Title',
        type: 'String'
      }, {
        title: 'Description',
        type: 'String'
      }, {
        title: 'Parent',
        type: 'Select',
        values: ['Product', 'Service']
      }, {
        title: 'Type',
        type: 'Select',
        values: ['Feature', 'Process']
      }, {
        title: 'Status',
        type: 'Select',
        values: ['Backlog', 'In Progress', 'To Do']
      }, {
        title: 'Priority',
        type: 'Select',
        values: ['Highest', 'High', 'Medium', 'Low', 'Lowest']
      }],
      filter: {
        external_tickets: true,
        external_projects: true,
        external_statuses: true,
        feature_process: true,
        status: true,
        type: true,
        priority: true,
        unmapped: true,
        enabler: true
      }
    };
  },
  watch: {
    offering: {
      handler: function handler(newValue) {
        // this.retrieveOfferingParentCustomerTypeOffering()
        this.featureAndProcessList = [];
        this.mergedFeaturesAndProcesses();
      },
      deep: true
    }
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['offering'])), {}, {
    filteredTickets: function filteredTickets() {
      var self = this;
      return this.mergedEntityList.filter(function (ticket) {
        return ticket.title.toLowerCase().indexOf(self.search_tickets.q.toLowerCase()) > -1;
      });
    },
    mergedEntityList: function mergedEntityList() {
      var list = [];
      if (this.showEnablers && this.showUnmapped) {
        return this.featureAndProcessList;
      }
      if (this.showEnablers) {
        for (var i = 0; i < this.featureAndProcessList.length; i++) {
          if (this.featureAndProcessList[i].enabler) {
            list.push(this.featureAndProcessList[i]);
          }
        }
        return list;
      }
      if (this.showUnmapped) {
        for (var j = 0; j < this.featureAndProcessList.length; j++) {
          if (this.featureAndProcessList[j].unmapped) {
            list.push(this.featureAndProcessList[j]);
          }
        }
        return list;
      }
      return list;
    }
  }),
  mounted: function mounted() {
    this.retrieveOfferingParentCustomerTypeOffering();
    this.featureAndProcessList = [];
    this.mergedFeaturesAndProcesses();
    this.retrieveTicketData();
  },
  methods: {
    toggleSort: function toggleSort() {
      if (this.search_tickets.sort.title === 'a-z') {
        this.search_tickets.sort.title = 'z-a';
      } else {
        this.search_tickets.sort.title = 'a-z';
      }
      this.retrieveTicketData();
    },
    removeSearchFilterTag: function removeSearchFilterTag(type) {
      var index = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      switch (type) {
        case 'title':
          this.search_tickets.filters.title = '';
          this.search_tickets.filters.title_operator = '';
          break;
        case 'description':
          this.search_tickets.filters.description = '';
          this.search_tickets.filters.description_operator = '';
          break;
        case 'status':
          this.search_tickets.filters.status.splice(index, 1);
          break;
        case 'priority':
          this.search_tickets.filters.priority.splice(index, 1);
          break;
        case 'type':
          this.search_tickets.filters.type.splice(index, 1);
          break;
        case 'parent':
          this.search_tickets.filters.parent.splice(index, 1);
          break;
      }
      this.retrieveTicketData();
    },
    // Currently Not in use, since sorting is set to default.
    removeSearchSortTag: function removeSearchSortTag(type) {
      switch (type) {
        case 'title':
          this.search_tickets.sort.title = 'a-z';
          break;
      }
    },
    displayFilterDialog: function displayFilterDialog() {
      this.filterDialog = true;
    },
    closeFilterDialog: function closeFilterDialog() {
      this.filterDialog = false;
    },
    filterTicketList: function filterTicketList() {},
    retrieveTicketData: function retrieveTicketData() {
      for (var i = 0; i < this.featureAndProcessList.length; i++) {
        this.retrieveTicket(i, this.featureAndProcessList[i].id);
      }
    },
    retrieveTicket: function retrieveTicket(entity_index, entity_id) {
      var _this = this;
      this.featureAndProcessList[entity_index].externalSystemName = '';
      this.featureAndProcessList[entity_index].externalId = '';
      this.featureAndProcessList[entity_index].externalStatus = '';
      this.featureAndProcessList[entity_index].externalProjectId = '';
      this.featureAndProcessList[entity_index].status = '';
      this.featureAndProcessList[entity_index].child_tickets = [];
      var headers = {
        'Content-Type': 'application/json',
        'x-party-id': this.$store.getters.partyId,
        'x-user-id': this.$store.getters.id
      };
      _axios.default.get(process.env.VUE_APP_REST_API_ENDPOINT + 'ticket?extended=LEVEL0&entityId=' + entity_id, {
        headers: headers
      }).then(function (response) {
        if (response.data.data[0].externalSystemName) {
          _this.featureAndProcessList[entity_index].external = true;
          _this.featureAndProcessList[entity_index].externalSystemName = response.data.data[0].externalSystemName;
          _this.featureAndProcessList[entity_index].externalId = response.data.data[0].externalId;
          _this.featureAndProcessList[entity_index].externalProjectId = response.data.data[0].externalProjectId;
          _this.featureAndProcessList[entity_index].externalStatus = response.data.data[0].externalStatus;
          _this.featureAndProcessList[entity_index].status = response.data.data[0].status;
          _this.retrieveExternalChildTickets(entity_index, response.data.data[0].externalId);
        } else {
          _this.featureAndProcessList[entity_index].external = false;
          _this.retrieveChildTickets(entity_index, response.data.data[0].id);
        }
      }).catch(function (error) {
        if (error.response.status === 400) {
          /* console.log(error.response.data.data.description)
          this.$notify({
            title: 'Error',
            message: error.response.data.data.description,
            type: 'error',
            duration: 2000
          })*/
        } else {
          console.log(error);
        }
      });
    },
    retrieveExternalChildTickets: function retrieveExternalChildTickets(entity_index, ticket_id) {
      var _this2 = this;
      this.loading = true;
      // Retrieve Ticket and populate ticket modal
      var headers = {
        'Content-Type': 'application/json',
        'x-party-id': this.$store.getters.partyId,
        'x-user-id': this.$store.getters.id
      };
      _axios.default.get(process.env.VUE_APP_REST_API_ENDPOINT + 'ticket/' + ticket_id + '/link?external=true', {
        headers: headers
      }).then(function (response) {
        _this2.loading = false;
        _this2.featureAndProcessList[entity_index].child_tickets = response.data.data.tickets;
      }).catch(function (error) {
        _this2.loading = false;
        console.log(error);
      });
    },
    retrieveChildTickets: function retrieveChildTickets(entity_index, ticket_id) {
      var _this3 = this;
      this.loading = true;
      // Retrieve Ticket and populate ticket modal
      var headers = {
        'Content-Type': 'application/json',
        'x-party-id': this.$store.getters.partyId,
        'x-user-id': this.$store.getters.id
      };
      _axios.default.get(process.env.VUE_APP_REST_API_ENDPOINT + 'ticket/' + ticket_id + '/link', {
        headers: headers
      }).then(function (response) {
        _this3.loading = false;
        _this3.featureAndProcessList[entity_index].child_tickets = response.data.data.tickets;
      }).catch(function (error) {
        _this3.loading = false;
        if (error.response.status !== 400) {
          console.log(error);
        }
      });
    },
    mergedFeaturesAndProcesses: function mergedFeaturesAndProcesses() {
      var entity = {};
      for (var i = 0; i < this.offering.linkedProducts.length; i++) {
        for (var j = 0; j < this.offering.linkedProducts[i].product.linkedFeatures.length; j++) {
          entity = this.offering.linkedProducts[i].product.linkedFeatures[j].productFeature;
          entity.unmapped = false;
          if (entity.relations.steps[0].id === null) {
            entity.unmapped = true;
          }
          entity.parent = this.offering.linkedProducts[i].product.title;
          entity.priority = 0;
          this.featureAndProcessList.push(entity);
        }
      }
      for (var k = 0; k < this.offering.linkedServices.length; k++) {
        for (var l = 0; l < this.offering.linkedServices[k].service.linkedServiceApplications.length; l++) {
          entity = this.offering.linkedServices[k].service.linkedServiceApplications[l].serviceApplication;
          entity.unmapped = false;
          if (entity.relations.id === null) {
            entity.unmapped = true;
          }
          entity.parent = this.offering.linkedServices[k].service.title;
          entity.priority = 0;
          this.featureAndProcessList.push(entity);
        }
      }
      return this.featureAndProcessList;
    },
    computeSmartPriority: function computeSmartPriority() {
      for (var m = 0; m < this.featureAndProcessList.length; m++) {
        this.retrieveJourneyMapPriority(m, this.featureAndProcessList[m].id);
      }
    },
    retrieveJourneyMapPriority: function retrieveJourneyMapPriority(index, entity_id) {
      for (var i = 0; i < this.offering.additionalattributes.relations.length; i++) {
        if (entity_id === this.offering.additionalattributes.relations[i].end_id) {
          if (this.offering.additionalattributes.relations[i].start_type === 'Phase') {
            this.featureAndProcessList[index].priority += this.retrievePhasePriority(this.offering.additionalattributes.relations[i].start_id);
          }
          if (this.offering.additionalattributes.relations[i].start_type === 'Step') {
            this.featureAndProcessList[index].priority += this.retrieveStepPriority(this.offering.additionalattributes.relations[i].start_id);
            this.featureAndProcessList[index].priority += this.retrievePhasePriorityForStep(this.offering.additionalattributes.relations[i].start_id);
          }
        }
      }
    },
    retrievePhasePriorityForStep: function retrievePhasePriorityForStep(step_id) {
      for (var i = 0; i < this.journeyMap.additionalattributes.relations.length; i++) {
        if (step_id === this.journeyMap.additionalattributes.relations[i].end_id && this.journeyMap.additionalattributes.relations[i].start_type === 'Phase') {
          return this.retrievePhasePriority(this.journeyMap.additionalattributes.relations[i].start_id);
        }
      }
    },
    retrievePhasePriority: function retrievePhasePriority(phase_id) {
      for (var i = 0; i < this.journeyMap.linkedJourneyPhases.length; i++) {
        if (this.journeyMap.linkedJourneyPhases[i].journeyPhase.id === phase_id) {
          return this.computeJourneyMapNumericalPriority(this.journeyMap.linkedJourneyPhases[i].journeyPhase.priority);
        }
      }
    },
    retrieveStepPriority: function retrieveStepPriority(step_id) {
      for (var i = 0; i < this.journeyMap.linkedJourneySteps.length; i++) {
        if (this.journeyMap.linkedJourneySteps[i].journeyStep.id === step_id) {
          return this.computeJourneyMapNumericalPriority(this.journeyMap.linkedJourneySteps[i].journeyStep.priority);
        }
      }
    },
    retrieveTicketPriority: function retrieveTicketPriority(index, entity_id) {
      var _this4 = this;
      // Retrieve Ticket and populate ticket modal
      var headers = {
        'Content-Type': 'application/json',
        'x-party-id': this.$store.getters.partyId,
        'x-user-id': this.$store.getters.id
      };
      _axios.default.get(process.env.VUE_APP_REST_API_ENDPOINT + 'ticket?extended=LEVEL0&entityId=' + entity_id, {
        headers: headers
      }).then(function (response) {
        var ticket = {};
        ticket = response.data.data[0];
        if (ticket.priority) {
          _this4.featureAndProcessList[index].priority += _this4.computePriority(ticket.priority);
        }
      }).catch(function (error) {
        console.log(error);
      });
    },
    computeJourneyMapNumericalPriority: function computeJourneyMapNumericalPriority(priority) {
      switch (priority) {
        case 'Very Low':
        case 'Lowest':
          return 1;
        case 'Low':
          return 2;
        case 'Medium':
          return 3;
        case 'High':
          return 4;
        case 'Very High':
        case 'Highest':
          return 5;
      }
    },
    computePriority: function computePriority(priority) {
      switch (priority) {
        case 'Lowest':
        case 'Very Low':
          return 1;
        case 'Low':
          return 2;
        case 'Medium':
          return 3;
        case 'High':
          return 4;
        case 'Very High':
        case 'Highest':
          return 5;
      }
    },
    retrieveOfferingParentCustomerTypeOffering: function retrieveOfferingParentCustomerTypeOffering() {
      var _this5 = this;
      this.$apollo.query({
        // Query
        query: window._gql(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)([" query CustomerTypes($page: Page, $offeringcriteriaexpression: OfferingsCriteriaExpression!)\n        {\n        CustomerTypes(page: $page)\n        {\n          pages\n          total\n          select{\n            id\n            title\n            linkedOfferings(optional: true) {\n              offering(optional: true, where: $offeringcriteriaexpression) {\n                id\n                title\n              }\n            }\n          }\n        }\n      }"]))),
        fetchPolicy: 'no-cache',
        variables: {
          'page': {
            'limit': 100,
            'start': 1
          },
          'offeringcriteriaexpression': {
            'id': {
              'EQ': this.offering.id
            }
          }
        }
      }).then(function (data) {
        if (data.data.CustomerTypes.select.length > 0) {
          _this5.retrieveOfferingParentCustomerTypeJourneyMap(data.data.CustomerTypes.select[0].id);
        }
      }).catch(function (error) {
        // Error
        console.log(error);
      });
    },
    retrieveOfferingParentCustomerTypeJourneyMap: function retrieveOfferingParentCustomerTypeJourneyMap(customer_id) {
      var _this6 = this;
      this.$apollo.query({
        // Query
        query: window._gql(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)([" query CustomerType($id: String)\n        {\n        CustomerType(id: $id)\n        {\n          id\n          title\n          linkedJourneyMaps(optional: true) {\n            journeyMap(optional: true) {\n              id\n              title\n              description\n              additionalattributes\n              linkedJourneyPhases(optional: true) {\n                journeyPhase(optional: true) {\n                  id\n                  title\n                  description\n                  priority\n                  importance\n                  goal\n                  emotions\n                  duration\n                  challenges\n                  additionalattributes\n                  relations\n                }\n              }\n              linkedJourneySteps(optional: true) {\n                journeyStep(optional: true) {\n                  id\n                  title\n                  description\n                  goal\n                  importance\n                  intention\n                  relations\n                  priority\n                  additionalattributes\n                }\n              }\n            }\n          }\n        }\n      }"]))),
        fetchPolicy: 'no-cache',
        variables: {
          'id': customer_id
        }
      }).then(function (data) {
        if (data.data.CustomerType.linkedJourneyMaps.length > 0) {
          _this6.journeyMap = data.data.CustomerType.linkedJourneyMaps[0].journeyMap;
          _this6.computeSmartPriority();
        }
      }).catch(function (error) {
        // Error
        console.log(error);
      });
    },
    truncateDescription: function truncateDescription(description, length) {
      return this._.truncate(description, {
        'length': length,
        'separator': ' '
      });
    }
  }
};