"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("g", {
    attrs: {
      id: "legend"
    }
  }, [_c("g", {
    attrs: {
      id: "legend-title"
    }
  }, [_c("rect", {
    staticStyle: {
      fill: "white",
      stroke: "silver",
      "stroke-width": "2"
    },
    attrs: {
      x: "102",
      y: "10",
      rx: "10",
      ry: "10",
      width: "400",
      height: "250"
    }
  }), _vm._v(" "), _c("text", {
    attrs: {
      x: "125",
      y: "37",
      fill: "black",
      "font-size": "20",
      "font-weight": "bold"
    }
  }, [_vm._v("Legend")])]), _vm._v(" "), _c("g", {
    attrs: {
      id: "legend-buttons"
    }
  }, [_c("process-component", {
    attrs: {
      x: parseInt(110),
      y: parseInt(60),
      process: "linear",
      text1: "Linear",
      text2: "Process",
      owner: "Test User",
      path: ""
    }
  }), _vm._v(" "), _c("process-component", {
    attrs: {
      x: parseInt(110),
      y: parseInt(130),
      process: "ongoing",
      text1: "OnGoing",
      text2: "Process",
      owner: "Test User",
      path: ""
    }
  }), _vm._v(" "), _c("process-component", {
    attrs: {
      x: parseInt(110),
      y: parseInt(200),
      process: "urgent",
      text1: "Urgent",
      text2: "Process",
      owner: "Test User",
      path: ""
    }
  }), _vm._v(" "), _c("avatar-component", {
    attrs: {
      x: parseInt(325),
      y: parseInt(65),
      "my-process": true,
      owner: "Test User"
    }
  }), _vm._v(" "), _c("text", {
    attrs: {
      x: parseInt(325) + 30,
      y: parseInt(65) + 10,
      fill: "black",
      dy: "0",
      "font-size": "10"
    }
  }, [_c("tspan", {
    attrs: {
      x: parseInt(325) + 40,
      dy: ".6em"
    }
  }, [_vm._v("Indicates the process is")]), _vm._v(" "), _c("tspan", {
    attrs: {
      x: parseInt(325) + 40,
      dy: "1.2em"
    }
  }, [_vm._v("owned by you")])]), _vm._v(" "), _c("avatar-component", {
    attrs: {
      x: parseInt(325),
      y: parseInt(135),
      "my-process": false,
      owner: "Test User"
    }
  }), _vm._v(" "), _c("text", {
    attrs: {
      x: parseInt(325) + 30,
      y: parseInt(135) + 10,
      fill: "black",
      dy: "0",
      "font-size": "10"
    }
  }, [_c("tspan", {
    attrs: {
      x: parseInt(325) + 40,
      dy: ".6em"
    }
  }, [_vm._v("Indicates the process is")]), _vm._v(" "), _c("tspan", {
    attrs: {
      x: parseInt(325) + 40,
      dy: "1.2em"
    }
  }, [_vm._v("owned by someone else")])])], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;