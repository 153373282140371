"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _default2 = exports.default = {
  props: {
    preview: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    contenteditable: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    itemIndex: {
      type: Number,
      default: 0
    },
    item: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      hVisible: false
    };
  },
  methods: {
    changeToHeading: function changeToHeading(data) {
      var updatedHeadingSelection = window._.map(this.item.headings, function (item, key) {
        return key === data.headingKey;
      });
      this.$emit('save-heading', {
        values: updatedHeadingSelection,
        itemIndex: this.itemIndex
      });
    },
    save: function save(e) {
      this.item.title = e.target.innerText;
      this.$emit('save-item', {
        item: this.item,
        index: this.itemIndex
      });
    }
  }
};