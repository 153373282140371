"use strict";

var _interopRequireWildcard = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es6.object.keys");
require("core-js/modules/web.dom.iterable");
require("C:\\Users\\Sastha\\Documents\\GitHub\\prodiggiapp-frontend\\node_modules\\core-js\\modules\\es6.array.iterator.js");
require("C:\\Users\\Sastha\\Documents\\GitHub\\prodiggiapp-frontend\\node_modules\\core-js\\modules\\es6.promise.js");
require("C:\\Users\\Sastha\\Documents\\GitHub\\prodiggiapp-frontend\\node_modules\\core-js\\modules\\es6.object.assign.js");
require("C:\\Users\\Sastha\\Documents\\GitHub\\prodiggiapp-frontend\\node_modules\\core-js\\modules\\es7.promise.finally.js");
var _vue = _interopRequireDefault(require("vue"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
require("normalize.css/normalize.css");
var _elementUi = _interopRequireDefault(require("element-ui"));
require("./styles/element-variables.scss");
require("element-ui/lib/theme-chalk/display.css");
require("@/styles/index.scss");
var _vueMasonryCss = _interopRequireDefault(require("vue-masonry-css"));
var _vue2Transitions = _interopRequireDefault(require("vue2-transitions"));
var _vueSvgPanZoom = _interopRequireDefault(require("vue-svg-pan-zoom"));
var _vueNumeric = _interopRequireDefault(require("vue-numeric"));
var _vDragged = _interopRequireDefault(require("v-dragged"));
var _vCalendar = _interopRequireDefault(require("v-calendar"));
var _vueJsonViewer = _interopRequireDefault(require("vue-json-viewer"));
var _vueGridLayout = _interopRequireDefault(require("vue-grid-layout"));
var _vueDummy = _interopRequireDefault(require("vue-dummy"));
var _vueMoment = _interopRequireDefault(require("vue-moment"));
var _momentTimezone = _interopRequireDefault(require("moment-timezone"));
var _vueSwatches = _interopRequireDefault(require("vue-swatches"));
var _vueTheMask = _interopRequireDefault(require("vue-the-mask"));
var _vueUuid = _interopRequireDefault(require("vue-uuid"));
var _vueHorizontalList = _interopRequireDefault(require("vue-horizontal-list"));
var _vueHorizontal = _interopRequireDefault(require("vue-horizontal"));
var _vueSwal = _interopRequireDefault(require("vue-swal"));
var _vueLodash = _interopRequireDefault(require("vue-lodash"));
var _lodash = _interopRequireDefault(require("lodash"));
var _en = _interopRequireDefault(require("element-ui/lib/locale/lang/en"));
var _vueContenteditable = _interopRequireDefault(require("vue-contenteditable"));
var _vueDraggable = _interopRequireDefault(require("vue-draggable"));
var _vueDirectiveLongPress = _interopRequireDefault(require("vue-directive-long-press"));
var _vueKonva = _interopRequireDefault(require("vue-konva"));
require("vue-swatches/dist/vue-swatches.min.css");
var _gsap = _interopRequireDefault(require("gsap"));
var _graphqlTag = _interopRequireDefault(require("graphql-tag"));
var _jquery = _interopRequireDefault(require("jquery"));
var _App = _interopRequireDefault(require("./App"));
var _store = _interopRequireDefault(require("./store"));
var _router = _interopRequireDefault(require("./router"));
require("./icons");
require("./permission");
var filters = _interopRequireWildcard(require("./filters"));
var _vueApollo = require("./vue-apollo");
var microsoftTeams = _interopRequireWildcard(require("@microsoft/teams-js"));
window._Cookies = _jsCookie.default;

// a modern alternative to CSS resets

// global css

_vue.default.use(_vueMasonryCss.default);
_vue.default.use(_vue2Transitions.default);
_vue.default.use(_vueSvgPanZoom.default);
_vue.default.use(_vueNumeric.default);
_vue.default.use(_vDragged.default);
_vue.default.use(_vCalendar.default);
_vue.default.use(_vueJsonViewer.default);
_vue.default.use(_vueGridLayout.default);
_vue.default.use(_vueDummy.default);
_vue.default.use(_vueMoment.default, {
  moment: _momentTimezone.default
});
_vue.default.use(_vueSwatches.default);
_vue.default.use(_vueTheMask.default);
_vue.default.use(_vueUuid.default);
_vue.default.use(_vueHorizontalList.default);
_vue.default.use(_vueHorizontal.default);
_vue.default.use(_vueSwal.default);
_vue.default.use(_elementUi.default, {
  locale: _en.default
});
_vue.default.use(_vueContenteditable.default);
_vue.default.use(_vueDraggable.default);
_vue.default.directive('long-press', _vueDirectiveLongPress.default);

// name is optional
_vue.default.use(_vueLodash.default, {
  lodash: _lodash.default
});
_vue.default.use(_vueKonva.default);
var VueTouch = require('vue-touch');
_vue.default.use(VueTouch, {
  name: 'v-touch'
});

// Import the styles too, globally

window._gsap = _gsap.default;
window._gql = _graphqlTag.default;
window._jquery = _jquery.default;

// icon

// permission control

_vue.default.prototype.$eventHub = new _vue.default(); // Global event bus

// global filters

microsoftTeams.initialize();
_vue.default.use(_elementUi.default, {
  size: window._Cookies.get('size') || 'medium' // set element-ui default size
});

// register global utility filters
Object.keys(filters).forEach(function (key) {
  _vue.default.filter(key, filters[key]);
});
_vue.default.config.productionTip = false;

// Remove loading screen
document.getElementById('loader').remove();
new _vue.default({
  el: '#app',
  router: _router.default,
  store: _store.default,
  apolloProvider: (0, _vueApollo.createProvider)(),
  render: function render(h) {
    return h(_App.default);
  }
});