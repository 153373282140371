"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("h4", [_vm._v("Related Customer Types")]), _vm._v(" "), _c("p", [_vm._v("Related customer jounrey and types are shown below. This include journeys based on this offering's journey and if this offering's journey was copied, where it was copied from. ")]), _vm._v(" "), _c("el-table", {
    attrs: {
      data: _vm.related_customer_types,
      size: "mini",
      stripe: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "customerType.title",
      label: "Customer Type"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Relationship"
    }
  }, [[_c("el-tag", {
    attrs: {
      size: "mini",
      type: "primary"
    }
  }, [_vm._v(_vm._s(_vm.offering.offeringcategory))])]], 2), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Created"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-tag", {
          attrs: {
            size: "mini",
            type: "primary"
          }
        }, [_vm._v(_vm._s(_vm.computeHumanReadableFormat(scope.row.customerType.createdat)))])];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "customerType.createdby",
      label: "Created by"
    }
  })], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;