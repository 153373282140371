"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.function.name");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("g", {
    attrs: {
      id: _vm.id
    }
  }, [_c("g", {
    attrs: {
      refs: "tabs"
    }
  }, _vm._l(_vm.option.categories, function (category, index) {
    return _c("g", {
      key: index,
      attrs: {
        refs: "tabCategory"
      },
      on: {
        click: function click($event) {
          _vm.activeTab = category;
        }
      }
    }, [_c("rect", {
      attrs: {
        x: _vm.computeCategoryShapeX(index),
        y: _vm.component.y,
        width: 65,
        height: 20,
        stroke: "rgb(159, 193, 245)",
        "stroke-width": "1",
        fill: _vm.computeOptionFillColor(category.name)
      }
    }), _vm._v(" "), _c("text", {
      attrs: {
        x: _vm.computeCategoryTextX(index),
        y: _vm.component.y + 12,
        "font-family": "Verdana",
        "font-size": "8",
        fill: "black",
        "text-anchor": "middle"
      }
    }, [_vm._v("\n        " + _vm._s(category.name) + "\n      ")])]);
  }), 0), _vm._v(" "), _c("g", {
    attrs: {
      refs: "tabCategoryDetails"
    }
  }, [_c("g", {
    attrs: {
      refs: "tasksTabDetails"
    }
  }, [_c("rect", {
    attrs: {
      x: _vm.component.x + 225,
      y: _vm.component.y + 22,
      width: 199,
      height: 200,
      stroke: "rgb(159, 193, 245)",
      "stroke-width": "1",
      fill: "rgb(222, 234, 252)"
    }
  }), _vm._v(" "), _vm._l(_vm.activeTab.values, function (value, cat_index) {
    return _c("text", {
      key: cat_index,
      attrs: {
        x: _vm.component.x + 325,
        y: _vm.component.y + 35 + cat_index * 15,
        "font-family": "Verdana",
        "font-size": "8",
        fill: "black",
        "text-anchor": "middle"
      }
    }, [_vm._v("\n        " + _vm._s(value.name) + "\n      ")]);
  }), _vm._v(" "), _c("g", {
    attrs: {
      refs: "addnew"
    },
    on: {
      click: _vm.addCategoryDetail
    }
  }, [_c("rect", {
    attrs: {
      x: _vm.component.x + 290,
      y: _vm.component.y + 178,
      width: 70,
      height: 20,
      stroke: "rgb(159, 193, 245)",
      "stroke-width": "1",
      fill: "rgb(159, 193, 245)"
    }
  }), _vm._v(" "), _c("text", {
    attrs: {
      x: _vm.component.x + 325,
      y: _vm.component.y + 190,
      "font-family": "Verdana",
      "font-size": "8",
      fill: "black",
      "text-anchor": "middle"
    }
  }, [_vm._v("\n          + Add " + _vm._s(_vm.activeTab.name) + "\n        ")])])], 2)])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;