"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "text-center"
  }, [_c("el-switch", {
    attrs: {
      "inactive-text": "Display MVP components",
      "active-text": "Display Post MVP component"
    },
    on: {
      change: _vm.broadcastEvent
    },
    model: {
      value: _vm.displayPostMVPComponents,
      callback: function callback($$v) {
        _vm.displayPostMVPComponents = $$v;
      },
      expression: "displayPostMVPComponents"
    }
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;