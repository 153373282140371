"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _Action = _interopRequireDefault(require("@/components/Action"));
var _Comment = _interopRequireDefault(require("@/components/Comment"));
var _FileUpload = _interopRequireDefault(require("@/components/FileUpload"));
var _axios = _interopRequireDefault(require("axios"));
var _utils = require("@/utils");
var _default2 = exports.default = {
  name: 'ChildTicket',
  components: {
    TinymceComponent: function TinymceComponent() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/components/Tinymce'));
      });
    },
    ActionComponent: _Action.default,
    CommentComponent: _Comment.default,
    FileUploadComponent: _FileUpload.default
  },
  props: {
    readOnly: {
      type: Boolean,
      default: false
    },
    entity: {
      type: Object,
      default: function _default() {
        return {
          id: '',
          title: '',
          description: ''
        };
      }
    },
    child: {
      type: Object,
      default: function _default() {
        return {
          id: '',
          title: '',
          description: '',
          relations: []
        };
      }
    }
  },
  data: function data() {
    return {
      /** Importance has been renamed to value.*/
      description: '',
      loading: false,
      child_ticket_loading: false,
      retrieve_ticket_loading: false,
      update_processing: false,
      attachment_loading: false,
      isEnabled: false,
      addTicketDialog: false,
      activeTab: 'ticket_details',
      ticket: {},
      tickets: [],
      files: [],
      edit: {
        mode: false,
        index: 0
      },
      comment: '',
      child_tickets: [],
      tickettypes: [{
        label: 'FEATURE',
        value: 'FEATURE'
      }, {
        label: 'EPIC',
        value: 'EPIC'
      }, {
        label: 'STORY',
        value: 'STORY'
      }, {
        label: 'TASK',
        value: 'TASK'
      }],
      statuses: [{
        label: 'Candidate',
        value: 'Candidate'
      }, {
        label: 'Approved',
        value: 'Approved'
      }, {
        label: 'Functional Design',
        value: 'Functional Design'
      }, {
        label: 'Technical Design',
        value: 'Technical Design'
      }, {
        label: 'Delivery',
        value: 'Delivery'
      }, {
        label: 'Ready for Release',
        value: 'Ready for Release'
      }, {
        label: 'Released',
        value: 'Released'
      }, {
        label: 'Enhancement',
        value: 'Enhancement'
      }, {
        label: 'Retired',
        value: 'Retired'
      }],
      priorities: [{
        label: 'Highest',
        value: 'Highest'
      }, {
        label: 'High',
        value: 'High'
      }, {
        label: 'Medium',
        value: 'Medium'
      }, {
        label: 'Low',
        value: 'Low'
      }, {
        label: 'Lowest',
        value: 'Lowest'
      }],
      external_tickets: [{
        id: '1234',
        type: 'Task',
        title: 'Ticket Title',
        status: 'Medium'
      }]
    };
  },
  computed: {
    filteredTickets: function filteredTickets() {
      var child_ticket_ids = [];
      var filterdTickets = [];
      for (var i = 0; i < this.child_tickets.length; i++) {
        child_ticket_ids.push(this.child_tickets[i].id);
      }
      for (var j = 0; j < this.tickets.length; j++) {
        if (child_ticket_ids.indexOf(this.tickets[i].id) === -1) {
          filterdTickets.push(this.tickets[j]);
        }
      }
      return filterdTickets;
    }
  },
  watch: {
    entity: {
      handler: function handler(newValue) {
        this.selectTicket();
      },
      deep: true
    }
  },
  created: function created() {},
  mounted: function mounted() {
    this.selectTicket();
  },
  methods: {
    updateTicketDescription: function updateTicketDescription(description) {
      this.ticket.description = description;
    },
    displayAddTicketDialog: function displayAddTicketDialog() {
      var _this = this;
      this.retrieve_ticket_loading = true;
      // Retrieve Ticket List
      var headers = {
        'Content-Type': 'application/json',
        'x-party-id': this.$store.getters.partyId,
        'x-user-id': this.$store.getters.id
      };
      _axios.default.get(process.env.VUE_APP_REST_API_ENDPOINT + 'ticket?createdBy=' + this.$store.getters.id, {
        headers: headers
      }).then(function (response) {
        _this.retrieve_ticket_loading = false;
        _this.tickets = [];
        _this.tickets = response.data.data;
        _this.addTicketDialog = true;
      }).catch(function (error) {
        _this.retrieve_ticket_loading = false;
        if (error.response.status === 400) {
          _this.$notify({
            title: 'Error',
            message: error.response.message,
            type: 'error',
            duration: 2000
          });
        }
      });
    },
    emitCloseEvent: function emitCloseEvent() {
      this.$emit('close');
    },
    updateTicket: function updateTicket() {
      var _this2 = this;
      this.update_processing = true;
      var headers = {
        'Content-Type': 'application/json'
        // 'Authorization': 'Bearer '+getJWTToken()
      };
      var body = {
        data: [{
          'id': this.ticket.id,
          'title': this.ticket.title,
          'description': this.ticket.description,
          'type': this.ticket.type,
          'enabler': this.ticket.enabler,
          'priority': this.ticket.priority,
          'status': this.ticket.status,
          'approvalVia': this.ticket.approvalVia
        }],
        'header': {
          'userId': this.$store.getters.id,
          'partyId': this.$store.getters.partyId
        }
      };
      _axios.default.patch(process.env.VUE_APP_REST_API_ENDPOINT + 'ticket', body, {
        headers: headers
      }).then(function (response) {
        _this2.update_processing = false;
        _this2.$notify({
          title: 'Success',
          message: 'Updated',
          type: 'success',
          duration: 2000
        });
        /* if (response.data.data.status === 'SUCCESS') {
           this.selectTicket()
         }
         */
      }).catch(function (error) {
        _this2.update_processing = false;
        _this2.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
      });
    },
    addUpdateChildTicket: function addUpdateChildTicket(child_ticket) {
      var _this3 = this;
      if (child_ticket.id === '') {
        // Create
        this.child_ticket_loading = true;
        var headers = {
          'Content-Type': 'application/json'
          // 'Authorization': 'Bearer '+getJWTToken()
        };
        var create_body = {
          data: [{
            'entityId': this.ticket.id,
            'entityName': 'TICKET',
            'title': child_ticket.title,
            'type': 'FEATURE',
            'priority': child_ticket.priority,
            'status': child_ticket.status,
            'importance': 'Medium',
            'slug': this.generateUniqueSlug(this.ticket),
            'approvalVia': 'test'
          }],
          'header': {
            'userId': this.$store.getters.id,
            'partyId': this.$store.getters.partyId
          }
        };
        _axios.default.post(process.env.VUE_APP_REST_API_ENDPOINT + 'ticket', create_body, {
          headers: headers
        }).then(function (response) {
          _this3.child_ticket_loading = false;
          if (response.data.data.status === 'SUCCESS') {
            // Link Child ticket to parent
            _this3.linkChildTicketToParent(response.data.data.id, _this3.ticket.id);
            // this.retrieveChildTickets()
          }
        }).catch(function (error) {
          _this3.child_ticket_loading = false;
          console.log(error);
        });
      } else {
        // Update

        this.child_ticket_loading = true;
        var _headers = {
          'Content-Type': 'application/json'
        };
        var update_body = {
          'data': {
            'title': child_ticket.title,
            'priority': child_ticket.priority,
            'status': child_ticket.status
          },
          'header': {
            'userId': this.$store.getters.id,
            'partyId': this.$store.getters.partyId
          }
        };
        _axios.default.patch(process.env.VUE_APP_REST_API_ENDPOINT + 'ticket/' + child_ticket.id, update_body, {
          headers: _headers
        }).then(function (response) {
          _this3.child_ticket_loading = false;
          _this3.retrieveChildTickets();
        }).catch(function (error) {
          _this3.child_ticket_loading = false;
          console.log(error);
        });
      }
    },
    linkChildTicketToParent: function linkChildTicketToParent(child_ticket_id, parent_ticket_id) {
      var _this4 = this;
      this.child_ticket_loading = true;
      var headers = {
        'Content-Type': 'application/json'
        // 'Authorization': 'Bearer '+getJWTToken()
      };
      var create_body = {
        data: [{
          'ticketId': child_ticket_id,
          'relationship': 'childLink1'
        }],
        'header': {
          'userId': this.$store.getters.id,
          'partyId': this.$store.getters.partyId
        }
      };
      _axios.default.post(process.env.VUE_APP_REST_API_ENDPOINT + 'ticket/' + parent_ticket_id + '/link', create_body, {
        headers: headers
      }).then(function (response) {
        _this4.child_ticket_loading = false;
        if (response.data.data.status === 'SUCCESS') {
          _this4.addTicketDialog = false;
          _this4.retrieveChildTickets();
        }
      }).catch(function (error) {
        _this4.child_ticket_loading = false;
        console.log(error);
      });
    },
    /*    selectTicket() {
            this.computeUserNameFromUserID(this.ticket.createdBy, 'createdBy')
            this.computeUserNameFromUserID(this.ticket.modifiedBy, 'modifiedBy')
            this.retrieveChildTickets()
            this.retrieveTicketAttachements()
        },*/
    selectTicket: function selectTicket() {
      var _this5 = this;
      this.loading = true;
      // Retrieve Ticket and populate ticket modal
      var headers = {
        'Content-Type': 'application/json',
        'x-party-id': this.$store.getters.partyId,
        'x-user-id': this.$store.getters.id
      };
      _axios.default.get(process.env.VUE_APP_REST_API_ENDPOINT + 'ticket/' + this.entity.id + '?extended=LEVEL0', {
        headers: headers
      }).then(function (response) {
        _this5.loading = false;
        _this5.ticket = {};
        _this5.ticket = response.data.data;
        // Emit ticket title and id to update dialog popover
        // this.$emit('updateTicketDialogTitle', this.ticket)
        _this5.computeUserNameFromUserID(_this5.ticket.createdBy, 'createdBy');
        _this5.computeUserNameFromUserID(_this5.ticket.modifiedBy, 'modifiedBy');
        _this5.retrieveChildTickets();
        _this5.retrieveTicketAttachements();
        // this.computeValueStreamDetails()
      }).catch(function (error) {
        _this5.loading = false;
        if (error.response.status === 400) {
          _this5.$notify({
            title: 'Error',
            message: error.response.message,
            type: 'error',
            duration: 2000
          });
        }
      });
    },
    retrieveChildTickets: function retrieveChildTickets() {
      var _this6 = this;
      this.child_ticket_loading = true;
      // Retrieve Ticket and populate ticket modal
      var headers = {
        'Content-Type': 'application/json',
        'x-party-id': this.$store.getters.partyId,
        'x-user-id': this.$store.getters.id
      };
      _axios.default.get(process.env.VUE_APP_REST_API_ENDPOINT + 'ticket/' + this.ticket.id + '/link', {
        headers: headers
      }).then(function (response) {
        _this6.child_ticket_loading = false;
        _this6.child_tickets = [];
        _this6.child_tickets = response.data.data[0].tickets;
      }).catch(function (error) {
        _this6.child_ticket_loading = false;
        if (error.response.status === 400) {
          _this6.child_tickets = [];
          _this6.$notify({
            title: 'Error',
            message: error.response.message,
            type: 'error',
            duration: 2000
          });
        }
      });
    },
    retrieveTicketAttachements: function retrieveTicketAttachements() {
      var _this7 = this;
      this.attachment_loading = true;
      // Retrieve Ticket and populate ticket modal
      var headers = {
        'Content-Type': 'application/json',
        'x-party-id': this.$store.getters.partyId,
        'x-user-id': this.$store.getters.id,
        'x-entity-id': this.ticket.id
      };
      _axios.default.get(process.env.VUE_APP_REST_API_ENDPOINT + 'file?entityId=' + this.ticket.id, {
        headers: headers
      }).then(function (response) {
        _this7.files = [];
        _this7.attachment_loading = false;
        if (Array.isArray(response.data.data) === true && !response.data.data.length) {
          _this7.files = response.data.data;
        }
      }).catch(function (error) {
        _this7.attachment_loading = false;
        if (error.response.status === 400) {
          _this7.files = [];
          _this7.$notify({
            title: 'Error',
            message: error.response.message,
            type: 'error',
            duration: 2000
          });
        }
      });
    },
    addAction: function addAction(action) {
      // this.artefact_actions.push(action)
    },
    addComment: function addComment() {
      this.discussions.push({
        id: '2',
        author: this.$store.getters.firstName + ' ' + this.$store.getters.lastName,
        image_url: 'https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif',
        message: this.comment,
        date_time: '2021-07-20 12:12:12'
      });
      this.comment = '';
    },
    computeIcon: function computeIcon(type) {
      switch (type) {
        case 'PNG':
        case 'JPG':
          return 'el-icon-picture-outline';
        default:
          return 'el-icon-document';
      }
    },
    computeMessengerAvatar: function computeMessengerAvatar(image_url) {
      return 'background-image: url(' + image_url + ')';
    },
    deleteDiscussion: function deleteDiscussion(discussion, index) {
      var _this8 = this;
      this.$confirm('This will permanently delete the comment. Continue?', 'Warning', {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(function () {
        _this8.discussions.splice(index, 1);
      }).catch(function () {
        _this8.$message({
          type: 'info',
          message: 'Delete canceled'
        });
      });
    },
    editDiscussion: function editDiscussion(discussion, index) {
      this.edit.mode = true;
      this.edit.index = index;
    },
    updateDiscussion: function updateDiscussion(discussion, index) {
      this.edit.mode = false;
      this.edit.index = 0;
    },
    computeUserNameFromUserID: function computeUserNameFromUserID(user_id, type) {
      var _this9 = this;
      if (user_id !== undefined) {
        var headers = {
          'Content-Type': 'application/json',
          'x-party-id': this.$store.getters.partyId,
          'x-user-id': this.$store.getters.id
        };
        _axios.default.get(process.env.VUE_APP_REST_API_ENDPOINT + 'user/' + user_id, {
          headers: headers
        }).then(function (response) {
          if (type === 'createdBy') {
            _this9.ticket.createdBy = response.data.data.firstName + ' ' + response.data.data.lastName;
          }
          if (type === 'modifiedBy') {
            _this9.ticket.modifiedBy = response.data.data.firstName + ' ' + response.data.data.lastName;
          }
        }).catch(function (error) {
          if (error.response.status === 400) {
            _this9.$notify({
              title: 'Error',
              message: error.response.message,
              type: 'error',
              duration: 2000
            });
          }
        });
      }
    },
    computeHumanFriendlyDate: function computeHumanFriendlyDate(date) {
      return this.$moment(date, 'YYYY-MM-DD HH:mm:ss').format('ll');
    },
    computeHumanFriendlyDateDifference: function computeHumanFriendlyDateDifference(date) {
      var today = this.$moment();
      return this.$moment.tz(date, 'Australia/Sydney').from(today);
    },
    checkIfUserIsAuthor: function checkIfUserIsAuthor(author) {
      var username = this.$store.getters.firstName + ' ' + this.$store.getters.lastName;
      if (username === author) {
        return true;
      }
    },
    attachFiles: function attachFiles() {
      this.$refs.fileInput.click();
    },
    addChildTicket: function addChildTicket() {
      var child_ticket = {
        id: '',
        title: '',
        type: '',
        status: '',
        priority: ''
      };
      this.child_tickets.push(child_ticket);
    },
    unlinkChildTicketFromParent: function unlinkChildTicketFromParent(child_ticket_id, parent_ticket_id) {
      var _this10 = this;
      this.child_ticket_loading = true;
      var headers = {
        'Content-Type': 'application/json'
        // 'Authorization': 'Bearer '+getJWTToken()
      };
      var create_body = {
        data: [{
          'ticketId': child_ticket_id,
          'relationship': 'childLink1'
        }],
        'header': {
          'userId': this.$store.getters.id,
          'partyId': this.$store.getters.partyId
        }
      };
      _axios.default.delete(process.env.VUE_APP_REST_API_ENDPOINT + 'ticket/' + parent_ticket_id + '/link', {
        headers: headers,
        data: create_body
      }).then(function (response) {
        _this10.child_ticket_loading = false;
        if (response.data.status === 'SUCCESS') {
          _this10.retrieveChildTickets();
        }
      }).catch(function (error) {
        _this10.child_ticket_loading = false;
        console.log(error);
      });
    },
    deleteChildTicket: function deleteChildTicket(child_ticket_id, parent_ticket_id) {
      var _this11 = this;
      this.$confirm('This will permanently delete ticket. Continue?', 'Warning', {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(function () {
        _this11.child_ticket_loading = true;
        var headers = {
          'Content-Type': 'application/json'
          // 'Authorization': 'Bearer '+getJWTToken()
        };
        var create_body = {
          data: [{
            'ticketId': child_ticket_id,
            'relationship': 'childLink1'
          }],
          'header': {
            'userId': _this11.$store.getters.id,
            'partyId': _this11.$store.getters.partyId
          }
        };
        _axios.default.delete(process.env.VUE_APP_REST_API_ENDPOINT + 'ticket/' + parent_ticket_id + '/link', {
          headers: headers,
          data: create_body
        }).then(function (response) {
          _this11.child_ticket_loading = false;
          if (response.data.status === 'SUCCESS') {
            // DELETE START*/
            _this11.child_ticket_loading = true;
            var _headers2 = {
              'Content-Type': 'application/json'
              // 'Authorization': 'Bearer '+getJWTToken()
            };
            var create_body1 = {
              data: [{
                'id': child_ticket_id
              }],
              'header': {
                'userId': _this11.$store.getters.id,
                'partyId': _this11.$store.getters.partyId
              }
            };
            _axios.default.delete(process.env.VUE_APP_REST_API_ENDPOINT + 'ticket', {
              headers: _headers2,
              data: create_body1
            }).then(function (response1) {
              _this11.child_ticket_loading = false;
              if (response1.data.data.status === 'SUCCESS') {
                _this11.retrieveChildTickets();
              }
            }).catch(function (error) {
              _this11.child_ticket_loading = false;
              console.log(error);
            });
            // DELETE END
          }
        }).catch(function (error) {
          _this11.child_ticket_loading = false;
          console.log(error);
        });
      }).catch(function () {
        _this11.$message({
          type: 'info',
          message: 'Delete canceled'
        });
      });
    },
    generateUniqueSlug: function generateUniqueSlug(ticket) {
      // Temp Algo - To be updated to better algo similar to JIRA
      var entity = ticket.entityName.slice(0, 3).toUpperCase();
      var title = ticket.title.slice(0, 3).toUpperCase();
      var unique = (0, _utils.createUniqueString)().slice(0, 3).toUpperCase();
      return entity + '-' + title + '-' + unique;
    }
  }
};