"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "zoomable",
    attrs: {
      id: "svg",
      "element-loading-text": "Loading User Journey Map...",
      "element-loading-spinner": "el-icon-loading",
      "element-loading-background": "rgba(0, 0, 0, 0.8)"
    }
  }, [_vm.legendVisible ? _c("svg", {
    staticClass: "legend-container",
    attrs: {
      height: "260",
      width: "502",
      xmlns: "http://www.w3.org/2000/svg",
      version: "1.1"
    }
  }, [_vm.legendVisible ? _c("legend-component") : _vm._e()], 1) : _vm._e(), _vm._v(" "), _c("svg-pan-zoom", {
    staticStyle: {
      width: "100%",
      height: "900pt"
    },
    attrs: {
      "zoom-enabled": !_vm.checkIsTouchDevice(),
      "pan-enabled": !_vm.checkIsTouchDevice(),
      "control-icons-enabled": !_vm.checkIsTouchDevice(),
      fit: false,
      center: true
    },
    on: {
      svgpanzoom: _vm.registerSvgPanZoom
    }
  }, [_c("svg", {
    attrs: {
      height: "100%",
      width: "95%",
      xmlns: "http://www.w3.org/2000/svg",
      version: "1.1"
    }
  }, [_c("g", {
    attrs: {
      id: "scene",
      transform: "translate(50,-50) scale(0.8,0.8)"
    }
  }, [_c("one-way-arrow-group-component", {
    attrs: {
      id: "operate_improve_to_plan_product",
      count: "8",
      "increment-size": "80",
      transform: "\n          scale(0.5)\n          rotate(144, 469.57, 376.4)\n          translate(270, 270)"
    }
  }), _vm._v(" "), _c("one-way-arrow-group-component", {
    attrs: {
      id: "plan_product_to_create_user_value",
      count: "8",
      "increment-size": "80",
      transform: "\n          scale(0.5)\n          rotate(72, 469.57, 376.4)\n          translate(890, 890)"
    }
  }), _vm._v(" "), _c("one-way-arrow-group-component", {
    attrs: {
      id: "plan_product_to_market_grow",
      count: "8",
      "increment-size": "80",
      transform: "\n          scale(0.5)\n          rotate(18, 469.57, 376.4)\n          translate(300, 880)"
    }
  }), _vm._v(" "), _c("one-way-arrow-group-component", {
    attrs: {
      id: "market_grow_to_build_test",
      count: "8",
      "increment-size": "80",
      transform: "\n          scale(0.5)\n          rotate(162, 469.57, 376.4)\n          translate(-350, -380)"
    }
  }), _vm._v(" "), _c("one-way-arrow-group-component", {
    attrs: {
      id: "create_user_value_to_design_product",
      count: "8",
      "increment-size": "80",
      transform: "\n          scale(0.5)\n          translate(500,1660)"
    }
  }), _vm._v(" "), _c("one-way-arrow-group-component", {
    attrs: {
      id: "create_user_value_to_market_grow",
      count: "8",
      "increment-size": "80",
      transform: "\n          scale(0.5)\n          rotate(-54, 614.89, 823.606)\n          translate(-120,1080)"
    }
  }), _vm._v(" "), _c("one-way-arrow-group-component", {
    attrs: {
      id: "design_product_to_build_and_test",
      count: "8",
      "increment-size": "80",
      transform: "\n          scale(0.5)\n          rotate(-72, 1085.11, 823.606)\n          translate(470, 1240)"
    }
  }), _vm._v(" "), _c("one-way-arrow-group-component", {
    attrs: {
      id: "build_test_to_operate_improve",
      count: "8",
      "increment-size": "80",
      transform: "\n          scale(0.5)\n          rotate(216, 1230.42, 376.4)\n          translate(900, 205)"
    }
  }), _vm._v(" "), _c("one-way-arrow-group-component", {
    attrs: {
      id: "operate_improve_to_market_grow",
      count: "8",
      "increment-size": "80",
      transform: "\n          scale(0.5)\n          rotate(90, 1230.42, 376.4)\n          translate(1180, 780)"
    }
  }), _vm._v(" "), _c("one-way-arrow-group-component", {
    attrs: {
      id: "market_grow_to_design_product",
      count: "8",
      "increment-size": "80",
      transform: "\n          scale(0.5)\n          rotate(233, 1230.42, 376.4)\n          translate(250, -400)"
    }
  }), _vm._v(" "), _c("domain-component", {
    attrs: {
      id: "domain_0",
      cx: "19.57",
      cy: "396.39",
      "text-line1": "Plan",
      "text-line2": "Business",
      color: "#000080",
      processes: _vm.assistedNavDocs[0].processes,
      documents: _vm.assistedNavDocs[0].documents,
      expand: _vm.expandDomains
    },
    on: {
      click: function click($event) {
        return _vm.panTo(19.57, 396.39);
      },
      "mouse-over": function mouseOver($event) {
        return _vm.onMouseOver(0);
      },
      "mouse-leave": function mouseLeave($event) {
        return _vm.onMouseLeave(0);
      }
    }
  }), _vm._v(" "), _c("domain-component", {
    attrs: {
      id: "domain_1",
      cx: "164.88",
      cy: "843.60",
      "text-line1": "Create",
      "text-line2": "User Value",
      color: "#008000",
      processes: _vm.assistedNavDocs[1].processes,
      documents: _vm.assistedNavDocs[1].documents,
      expand: _vm.expandDomains
    },
    on: {
      click: function click($event) {
        return _vm.panTo(164.88, 843.6);
      },
      "mouse-over": function mouseOver($event) {
        return _vm.onMouseOver(1);
      },
      "mouse-leave": function mouseLeave($event) {
        return _vm.onMouseLeave(1);
      }
    }
  }), _vm._v(" "), _c("domain-component", {
    attrs: {
      id: "domain_2",
      cx: "635.11",
      cy: "843.60",
      "text-line1": "Design",
      "text-line2": "Product",
      color: "#F26D14",
      processes: _vm.assistedNavDocs[2].processes,
      documents: _vm.assistedNavDocs[2].documents,
      expand: _vm.expandDomains
    },
    on: {
      click: function click($event) {
        return _vm.panTo(635.11, 843.6);
      },
      "mouse-over": function mouseOver($event) {
        return _vm.onMouseOver(2);
      },
      "mouse-leave": function mouseLeave($event) {
        return _vm.onMouseLeave(2);
      }
    }
  }), _vm._v(" "), _c("domain-component", {
    attrs: {
      id: "domain_3",
      cx: "780.42",
      cy: "396.39",
      "text-line1": "Build",
      "text-line2": "& Test",
      color: "#FF0000",
      processes: _vm.assistedNavDocs[3].processes,
      documents: _vm.assistedNavDocs[3].documents
    },
    on: {
      click: function click($event) {
        return _vm.panTo(780.42, 396.39);
      },
      "mouse-over": function mouseOver($event) {
        return _vm.onMouseOver(3);
      },
      "mouse-leave": function mouseLeave($event) {
        return _vm.onMouseLeave(3);
      }
    }
  }), _vm._v(" "), _c("domain-component", {
    attrs: {
      id: "domain_4",
      cx: "400",
      cy: "120",
      "text-line1": "Operate",
      "text-line2": "& Improve",
      color: "#9FA39C",
      processes: _vm.assistedNavDocs[4].processes,
      documents: _vm.assistedNavDocs[4].documents,
      expand: _vm.expandDomains
    },
    on: {
      click: function click($event) {
        return _vm.panTo(400, 120);
      },
      "mouse-over": function mouseOver($event) {
        return _vm.onMouseOver(4);
      },
      "mouse-leave": function mouseLeave($event) {
        return _vm.onMouseLeave(4);
      }
    }
  }), _vm._v(" "), _c("domain-component", {
    attrs: {
      id: "domain_5",
      cx: "400",
      cy: "520",
      "text-line1": "Market",
      "text-line2": "& Grow",
      color: "#DD33FF",
      processes: _vm.assistedNavDocs[5].processes,
      documents: _vm.assistedNavDocs[5].documents,
      expand: _vm.expandDomains
    },
    on: {
      click: function click($event) {
        return _vm.panTo(400, 520);
      },
      "mouse-over": function mouseOver($event) {
        return _vm.onMouseOver(5);
      },
      "mouse-leave": function mouseLeave($event) {
        return _vm.onMouseLeave(5);
      }
    }
  })], 1)])]), _vm._v(" "), _c("el-steps", {
    staticStyle: {
      position: "fixed",
      bottom: "0px",
      width: "100%"
    },
    attrs: {
      active: 2,
      simple: ""
    }
  }, [_vm._l(_vm.assistedNav.domains, function (domain, index) {
    return [_c("el-step", {
      key: index,
      staticClass: "domain_status",
      attrs: {
        icon: _vm.computeIcon(domain.status)
      }
    }, [_c("el-popover", {
      attrs: {
        slot: "title",
        title: _vm.computeDomainTitle(domain),
        "popper-class": "custom-popover",
        transition: "",
        placement: "top",
        width: "400",
        trigger: "hover"
      },
      slot: "title"
    }, [_vm.isDomainActive(index) ? _c("span", {
      staticClass: "animated highlight faster",
      attrs: {
        slot: "reference"
      },
      slot: "reference"
    }, [_vm._v(_vm._s(_vm.computeDomainTitle(domain)))]) : _c("span", {
      attrs: {
        slot: "reference"
      },
      slot: "reference"
    }, [_vm._v(_vm._s(_vm.computeDomainTitle(domain)))]), _vm._v(" "), _c("div", {
      staticClass: "custom-popover-container"
    }, [_c("el-row", {
      attrs: {
        gutter: 20
      }
    }, [_c("el-col", {
      attrs: {
        span: 12
      }
    }, [_c("div", {
      staticClass: "sub-title-left"
    }, [_vm._v("\n                  Description\n                ")]), _vm._v(" "), _c("div", [_c("ul", [_c("li", [_vm._v("\n                      Item 1\n                    ")]), _vm._v(" "), _c("li", [_vm._v("\n                      Item 2\n                    ")])])])]), _vm._v(" "), _c("el-col", {
      attrs: {
        span: 12
      }
    }, [_c("div", {
      staticClass: "sub-title-right"
    }, [_vm._v("\n                  Tasks\n                ")]), _vm._v(" "), _c("div", [_c("ul", [_c("li", [_vm._v("\n                      Item 1\n                    ")]), _vm._v(" "), _c("li", [_vm._v("\n                      Item 2\n                    ")])])])])], 1), _vm._v(" "), _c("el-row", {
      attrs: {
        gutter: 20
      }
    }, [_c("el-col", {
      attrs: {
        span: 12
      }
    }, [_c("div", {
      staticClass: "sub-title-left"
    }, [_vm._v("\n                  Alerts\n                ")]), _vm._v(" "), _c("div", [_c("ul", [_c("li", [_vm._v("\n                      Item 1\n                    ")]), _vm._v(" "), _c("li", [_vm._v("\n                      Item 2\n                    ")])])])]), _vm._v(" "), _c("el-col", {
      attrs: {
        span: 12
      }
    }, [_c("div", {
      staticClass: "sub-title-right"
    }, [_vm._v("\n                  Status\n                ")]), _vm._v(" "), _c("div", [_c("ul", [_c("li", [_vm._v("\n                      Item 1\n                    ")]), _vm._v(" "), _c("li", [_vm._v("\n                      Item 2\n                    ")])])])])], 1)], 1)])], 1)];
  })], 2), _vm._v(" "), !_vm.legendVisible ? _c("el-tooltip", {
    staticStyle: {
      margin: "4px"
    },
    attrs: {
      effect: "dark",
      content: "Show Legend",
      placement: "bottom"
    }
  }, [_c("el-button", {
    staticStyle: {
      position: "absolute",
      top: "10pt",
      left: "10pt"
    },
    attrs: {
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.legendVisible = !_vm.legendVisible;
      }
    }
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "eye-open"
    }
  })], 1)], 1) : _c("el-tooltip", {
    staticStyle: {
      margin: "4px"
    },
    attrs: {
      effect: "dark",
      content: "Hide Legend",
      placement: "bottom"
    }
  }, [_c("el-button", {
    staticStyle: {
      position: "absolute",
      top: "10pt",
      left: "10pt"
    },
    attrs: {
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.legendVisible = !_vm.legendVisible;
      }
    }
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "eye"
    }
  })], 1)], 1), _vm._v(" "), !_vm.expandDomains ? _c("el-tooltip", {
    staticStyle: {
      margin: "4px"
    },
    attrs: {
      effect: "dark",
      content: "Expand Navigation",
      placement: "bottom"
    }
  }, [_c("el-button", {
    staticStyle: {
      position: "absolute",
      top: "40pt",
      left: "10pt"
    },
    attrs: {
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.expandDomains = !_vm.expandDomains;
      }
    }
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "tree-table"
    }
  })], 1)], 1) : _c("el-tooltip", {
    staticStyle: {
      margin: "4px"
    },
    attrs: {
      effect: "dark",
      content: "Collapse Navigation",
      placement: "bottom"
    }
  }, [_c("el-button", {
    staticStyle: {
      position: "absolute",
      top: "40pt",
      left: "10pt"
    },
    attrs: {
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.expandDomains = !_vm.expandDomains;
      }
    }
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "circle"
    }
  })], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Domain Documents",
      visible: _vm.domainDocumentsEditDialog,
      width: "70%",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.domainDocumentsEditDialog = $event;
      }
    }
  }, [_c("div", {
    staticClass: "app-container",
    staticStyle: {
      "min-height": "0pt"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    staticClass: "text-center",
    attrs: {
      span: 12
    }
  }, [_c("div", {
    staticClass: "sub-title-left"
  }, [_vm._v("\n            Process Document\n          ")]), _vm._v(" "), _vm.activeProcessDocuments ? _c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.activeProcessDocuments
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "documentation.name",
      label: "Name",
      width: "250"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "URL"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-input", {
          attrs: {
            size: "mini"
          },
          model: {
            value: scope.row.documentation.url,
            callback: function callback($$v) {
              _vm.$set(scope.row.documentation, "url", $$v);
            },
            expression: "scope.row.documentation.url"
          }
        }, [_vm.isConfluenceLink(scope.row.documentation.url) ? _c("svg-icon", {
          attrs: {
            slot: "prefix",
            "icon-class": "confluence"
          },
          slot: "prefix"
        }) : _c("svg-icon", {
          attrs: {
            slot: "prefix",
            "icon-class": "word"
          },
          slot: "prefix"
        })], 1)];
      }
    }], null, false, 2345794723)
  })], 1) : _vm._e()], 1), _vm._v(" "), _c("el-col", {
    staticClass: "text-center",
    attrs: {
      span: 12
    }
  }, [_c("div", {
    staticClass: "sub-title-right"
  }, [_vm._v("\n            Additional Document\n          ")]), _vm._v(" "), _vm.activeProcessAdditionalDocuments ? _c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.activeProcessAdditionalDocuments
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Name",
      width: "250"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-input", {
          attrs: {
            size: "mini"
          },
          model: {
            value: scope.row.name,
            callback: function callback($$v) {
              _vm.$set(scope.row, "name", $$v);
            },
            expression: "scope.row.name"
          }
        })];
      }
    }], null, false, 2224058498)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "URL"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-input", {
          attrs: {
            size: "mini"
          },
          model: {
            value: scope.row.url,
            callback: function callback($$v) {
              _vm.$set(scope.row, "url", $$v);
            },
            expression: "scope.row.url"
          }
        }, [_vm.isConfluenceLink(scope.row.url) ? _c("svg-icon", {
          attrs: {
            slot: "prefix",
            "icon-class": "confluence"
          },
          slot: "prefix"
        }) : _c("svg-icon", {
          attrs: {
            slot: "prefix",
            "icon-class": "word"
          },
          slot: "prefix"
        })], 1)];
      }
    }], null, false, 3369225123)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "",
      width: "200"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-button", {
          attrs: {
            size: "mini",
            type: "danger"
          },
          on: {
            click: function click($event) {
              return _vm.handleDomainAdditionalDocumentDelete(scope.$index, scope.row);
            }
          }
        }, [_vm._v("Delete")])];
      }
    }], null, false, 3438364265)
  })], 1) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "fr mt-5"
  }, [_c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: _vm.addAdditionalDocument
    }
  }, [_vm._v(" Add Documents ")])], 1)], 1)], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.domainDocumentsEditDialog = false;
      }
    }
  }, [_vm._v("Close")])], 1)])], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;