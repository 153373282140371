"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.array.sort");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 5
    }
  }, [_c("div", {
    staticClass: "competitors-header"
  }, [_c("div", {
    staticClass: "flex",
    staticStyle: {
      "border-bottom": "2pt solid #304156",
      "padding-left": "10pt",
      "margin-bottom": "0",
      "background-color": "rgba(222,235,246,.5)"
    }
  }, [_c("el-row", [_c("div", {
    staticClass: "text-left pl-10"
  }, [_c("div", {
    staticClass: "title-caps mt-10"
  }, [_c("strong", [_vm._v("Competitors")])])])]), _vm._v(" "), _c("el-row", [_c("div", {
    staticClass: "pt-5 ml-5 text-right",
    staticStyle: {
      "margin-bottom": "5pt",
      "margin-right": "8px"
    }
  }, [_c("el-button", {
    staticStyle: {
      top: "1pt",
      "border-radius": "3pt"
    },
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.openAddCompetitorDialog();
      }
    }
  }, [_vm._v("Add")])], 1)])], 1), _vm._v(" "), _c("el-row", {
    staticStyle: {
      padding: "10px 5px 5px 10px",
      "background-color": "rgba(0,0,0,.03)"
    }
  }, [_c("el-col", {
    attrs: {
      span: 18
    }
  }, [_c("div", {
    staticClass: "ml-5 mr-5"
  }, [_c("el-input", {
    staticClass: "mr-5",
    attrs: {
      type: "text",
      size: "mini",
      placeholder: "Search Competitors",
      "prefix-icon": "el-icon-search"
    },
    model: {
      value: _vm.search_competitor.q,
      callback: function callback($$v) {
        _vm.$set(_vm.search_competitor, "q", $$v);
      },
      expression: "search_competitor.q"
    }
  })], 1)]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "ml-5 mr-5 text-right"
  }, [_c("el-button-group", [_c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Filter",
      placement: "right"
    }
  }, [_c("el-button", {
    staticStyle: {
      "border-radius": "3pt"
    },
    attrs: {
      size: "micro",
      type: "secondary"
    },
    on: {
      click: _vm.showFilterDialog
    }
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "1.2em"
    },
    attrs: {
      "icon-class": "filter-left"
    }
  })], 1)], 1), _vm._v(" "), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Sort",
      placement: "right"
    }
  }, [_c("el-button", {
    staticStyle: {
      "border-radius": "3pt"
    },
    attrs: {
      size: "micro",
      type: "secondary"
    },
    on: {
      click: function click($event) {
        return _vm.toggleSort();
      }
    }
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "1.2em"
    },
    attrs: {
      "icon-class": _vm.search_competitor.sort.title === "a-z" ? "sort-alpha-down" : "sort-alpha-up"
    }
  })], 1)], 1)], 1)], 1)])], 1), _vm._v(" "), _c("div", {
    staticClass: "flex flex-wrap pt-5",
    staticStyle: {
      padding: "1pt 1pt 4pt 16pt",
      display: "inline-block",
      "background-color": "rgba(0,0,0,.03)"
    }
  }, [_vm.search_competitor.filters.title !== "" ? _c("el-tag", {
    attrs: {
      slot: "reference",
      size: "mini",
      closable: ""
    },
    on: {
      close: function close($event) {
        return _vm.removeSearchFilterTag("title");
      }
    },
    slot: "reference"
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "1.2em"
    },
    attrs: {
      "icon-class": "filter-left"
    }
  }), _vm._v("Name:" + _vm._s(_vm.search_competitor.filters.title_operator) + " " + _vm._s(_vm.search_competitor.filters.title) + "\n          ")], 1) : _vm._e(), _vm._v(" "), _vm.search_competitor.filters.description !== "" ? _c("el-tag", {
    attrs: {
      size: "mini",
      closable: ""
    },
    on: {
      close: function close($event) {
        return _vm.removeSearchFilterTag("description");
      }
    }
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "1.2em"
    },
    attrs: {
      "icon-class": "filter-left"
    }
  }), _vm._v("Description:" + _vm._s(_vm.search_competitor.filters.description_operator) + " " + _vm._s(_vm.search_competitor.filters.description))], 1) : _vm._e(), _vm._v(" "), _vm._l(_vm.search_competitor.filters.category, function (category_datum, index) {
    return _c("el-tag", {
      key: index,
      attrs: {
        size: "mini",
        closable: ""
      },
      on: {
        close: function close($event) {
          return _vm.removeSearchFilterTag("category", index);
        }
      }
    }, [_c("svg-icon", {
      staticStyle: {
        "font-size": "1.2em"
      },
      attrs: {
        "icon-class": "filter-left"
      }
    }), _vm._v("Category:\n            " + _vm._s(category_datum) + "\n          ")], 1);
  }), _vm._v(" "), _vm._l(_vm.search_competitor.filters.risk, function (risk_datum, index) {
    return _c("el-tag", {
      key: index,
      attrs: {
        size: "mini",
        closable: ""
      },
      on: {
        close: function close($event) {
          return _vm.removeSearchFilterTag("threat", index);
        }
      }
    }, [_c("svg-icon", {
      staticStyle: {
        "font-size": "1.2em"
      },
      attrs: {
        "icon-class": "filter-left"
      }
    }), _vm._v("Threat:" + _vm._s(risk_datum) + "\n          ")], 1);
  }), _vm._v(" "), _vm.search_competitor.sort.title !== "" ? _c("el-tag", {
    attrs: {
      type: "info",
      size: "mini",
      closable: ""
    },
    on: {
      close: function close($event) {
        return _vm.removeSearchSortTag("title");
      }
    }
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "1.2em"
    },
    attrs: {
      "icon-class": _vm.search_competitor.sort.title === "a-z" ? "sort-alpha-down" : "sort-alpha-up"
    }
  }), _vm._v("\n            Name:" + _vm._s(_vm.search_competitor.sort.title) + "\n          ")], 1) : _vm._e(), _vm._v(" "), _vm.search_competitor.filters.active !== "" ? _c("el-tag", {
    attrs: {
      size: "mini",
      closable: ""
    },
    on: {
      close: function close($event) {
        return _vm.removeSearchFilterTag("active");
      }
    }
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "1.2em"
    },
    attrs: {
      "icon-class": "filter-left"
    }
  }), _vm._v("Active:" + _vm._s(_vm.search_competitor.filters.active))], 1) : _vm._e(), _vm._v(" "), _vm.search_competitor.filters.acquired !== "" ? _c("el-tag", {
    attrs: {
      size: "mini",
      closable: ""
    },
    on: {
      close: function close($event) {
        return _vm.removeSearchFilterTag("acquired");
      }
    }
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "1.2em"
    },
    attrs: {
      "icon-class": "filter-left"
    }
  }), _vm._v("Acquired:" + _vm._s(_vm.search_competitor.filters.acquired))], 1) : _vm._e()], 2)], 1), _vm._v(" "), _c("div", {
    staticClass: "mt-10"
  }, [_vm.filteredCompetitors.length > 0 ? _c("div", {
    staticStyle: {
      height: "100%"
    }
  }, _vm._l(_vm.filteredCompetitors, function (competitor, index) {
    return _c("div", {
      key: index,
      class: _vm.activeCompetitor && _vm.activeCompetitor.id === competitor.id ? "list_item is-item-active" : "list_item",
      on: {
        click: function click($event) {
          return _vm.selectCompetitor(competitor, index);
        }
      }
    }, [_c("table", {
      staticStyle: {
        width: "100%",
        "font-variant-caps": "small-caps",
        "margin-left": "6px"
      }
    }, [_c("colgroup", [_c("col", {
      staticStyle: {
        width: "70%"
      },
      attrs: {
        span: "1"
      }
    }), _vm._v(" "), _c("col", {
      staticStyle: {
        width: "30%"
      },
      attrs: {
        span: "1"
      }
    })]), _vm._v(" "), _c("tr", [_c("td", [_c("strong", [_vm._v(_vm._s(_vm.truncateDescription(competitor.title, "30")))])]), _vm._v(" "), _c("td", {
      staticClass: "text-right",
      staticStyle: {
        "font-size": "1.65em"
      }
    }, [_c("el-tooltip", {
      staticClass: "item",
      attrs: {
        effect: "dark",
        content: "Acquired",
        placement: "left"
      }
    }, [competitor.acquired === true ? _c("svg-icon", {
      staticStyle: {
        color: "darkgreen"
      },
      attrs: {
        "icon-class": "acquired",
        size: "mini"
      },
      model: {
        value: competitor.acquired,
        callback: function callback($$v) {
          _vm.$set(competitor, "acquired", $$v);
        },
        expression: "competitor.acquired"
      }
    }) : _vm._e()], 1), _vm._v(" "), _c("el-tooltip", {
      staticClass: "item",
      attrs: {
        effect: "dark",
        content: "No longer active",
        placement: "bottom"
      }
    }, [competitor.active === false ? _c("svg-icon", {
      staticStyle: {
        color: "darkslategrey"
      },
      attrs: {
        "icon-class": "tombstone",
        size: "mini"
      },
      model: {
        value: competitor.active,
        callback: function callback($$v) {
          _vm.$set(competitor, "active", $$v);
        },
        expression: "competitor.active"
      }
    }) : _vm._e()], 1), _vm._v(" "), _c("el-tooltip", {
      staticClass: "item",
      attrs: {
        effect: "dark",
        content: "Highest threat",
        placement: "right"
      }
    }, [competitor.risk === "Highest" ? _c("svg-icon", {
      staticStyle: {
        color: "darkred",
        "font-weight": "900"
      },
      attrs: {
        "icon-class": "caution",
        size: "mini"
      },
      model: {
        value: competitor.risk,
        callback: function callback($$v) {
          _vm.$set(competitor, "risk", $$v);
        },
        expression: "competitor.risk"
      }
    }) : _vm._e()], 1), _vm._v(" "), _c("el-tooltip", {
      staticClass: "item",
      attrs: {
        effect: "dark",
        content: "High threat",
        placement: "right"
      }
    }, [competitor.risk === "High" ? _c("svg-icon", {
      staticStyle: {
        color: "#FF6600"
      },
      attrs: {
        "icon-class": "caution",
        size: "mini",
        placeholder: "Select"
      },
      model: {
        value: competitor.risk,
        callback: function callback($$v) {
          _vm.$set(competitor, "risk", $$v);
        },
        expression: "competitor.risk"
      }
    }) : _vm._e()], 1), _vm._v(" "), _c("el-tooltip", {
      staticClass: "item",
      attrs: {
        effect: "dark",
        content: "Medium threat",
        placement: "right"
      }
    }, [competitor.risk === "Medium" ? _c("svg-icon", {
      staticStyle: {
        color: "grey"
      },
      attrs: {
        "icon-class": "caution",
        size: "mini",
        placeholder: "Select"
      },
      model: {
        value: competitor.risk,
        callback: function callback($$v) {
          _vm.$set(competitor, "risk", $$v);
        },
        expression: "competitor.risk"
      }
    }) : _vm._e()], 1), _vm._v(" "), _c("el-tooltip", {
      staticClass: "item",
      attrs: {
        effect: "dark",
        content: "Low threat",
        placement: "right"
      }
    }, [competitor.risk === "Low" ? _c("svg-icon", {
      staticStyle: {
        color: "grey"
      },
      attrs: {
        "icon-class": "rabbit",
        size: "mini",
        placeholder: "Select"
      },
      model: {
        value: competitor.risk,
        callback: function callback($$v) {
          _vm.$set(competitor, "risk", $$v);
        },
        expression: "competitor.risk"
      }
    }) : _vm._e()], 1), _vm._v(" "), _c("el-tooltip", {
      staticClass: "item",
      attrs: {
        effect: "dark",
        content: "Lowest threat",
        placement: "right"
      }
    }, [competitor.risk === "Lowest" ? _c("svg-icon", {
      staticStyle: {
        color: "deeppink"
      },
      attrs: {
        "icon-class": "rabbit",
        size: "mini",
        placeholder: "Select"
      },
      model: {
        value: competitor.risk,
        callback: function callback($$v) {
          _vm.$set(competitor, "risk", $$v);
        },
        expression: "competitor.risk"
      }
    }) : _vm._e()], 1)], 1)])])]);
  }), 0) : _c("el-empty", {
    attrs: {
      description: "No Competitors Found"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "text-center",
    staticStyle: {
      width: "calc(100%/24 * 4)",
      position: "fixed",
      bottom: "0px",
      "background-color": "rgba(255,255,255,1)"
    }
  }, [_c("el-pagination", {
    attrs: {
      layout: "prev, pager, next",
      small: true,
      "hide-on-single-page": true,
      total: _vm.total_count
    },
    on: {
      "current-change": _vm.loadPage,
      "prev-click": _vm.loadPage,
      "next-click": _vm.loadPage
    }
  })], 1)]), _vm._v(" "), _vm.competitors.length > 0 ? _c("el-col", {
    attrs: {
      span: 19
    }
  }, [_c("transition", {
    attrs: {
      name: "fade"
    }
  }, [!_vm.loading ? [_vm.activeCompetitor.title ? _c("div", [_c("el-card", {
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      id: "competitor_details_container"
    }
  }, [_c("div", {
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 18
    }
  }, [_vm.editMode ? _c("el-input", {
    attrs: {
      type: "text",
      size: "small"
    },
    model: {
      value: _vm.activeCompetitor.title,
      callback: function callback($$v) {
        _vm.$set(_vm.activeCompetitor, "title", $$v);
      },
      expression: "activeCompetitor.title"
    }
  }) : _c("strong", {
    staticStyle: {
      "font-variant-caps": "small-caps"
    }
  }, [_vm._v(_vm._s(_vm.truncateDescription(_vm.activeCompetitor.title, 90)) + " Details")])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "fr",
    staticStyle: {
      "margin-top": "-5px"
    }
  }, [!_vm.editMode ? _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Edit",
      placement: "top"
    }
  }, [_c("el-button", {
    attrs: {
      type: "secondary",
      circle: "",
      size: "mini",
      icon: "el-icon-edit"
    },
    on: {
      click: function click($event) {
        _vm.editMode = true;
      }
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.editMode ? _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Cancel",
      placement: "top"
    }
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      circle: "",
      label: "Cancel"
    },
    on: {
      click: function click($event) {
        _vm.editMode = false;
      }
    }
  }, [_c("i", {
    staticClass: "fa fa-ban"
  })])], 1) : _vm._e(), _vm._v(" "), _vm.editMode ? _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Update",
      placement: "top"
    }
  }, [_c("el-button", {
    attrs: {
      type: "success",
      circle: "",
      size: "mini",
      icon: "el-icon-check"
    },
    on: {
      click: function click($event) {
        return _vm.updateCompetitorCollection();
      }
    }
  })], 1) : _vm._e(), _vm._v(" "), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Delete",
      placement: "top"
    }
  }, [_c("el-button", {
    attrs: {
      type: "danger",
      circle: "",
      size: "mini",
      icon: "el-icon-delete"
    },
    nativeOn: {
      click: function click($event) {
        $event.preventDefault();
        return _vm.deleteCompetitor(_vm.activeCompetitorIndex);
      }
    }
  })], 1)], 1)])], 1)], 1), _vm._v(" "), _c("table", {
    attrs: {
      width: "100%"
    }
  }, [_c("colgroup", [_c("col", {
    staticStyle: {
      width: "10%"
    },
    attrs: {
      span: "1"
    }
  }), _vm._v(" "), _c("col", {
    staticStyle: {
      width: "40%"
    },
    attrs: {
      span: "1"
    }
  }), _vm._v(" "), _c("col", {
    staticStyle: {
      width: "5%"
    },
    attrs: {
      span: "1"
    }
  }), _vm._v(" "), _c("col", {
    staticStyle: {
      width: "10%"
    },
    attrs: {
      span: "1"
    }
  }), _vm._v(" "), _c("col", {
    staticStyle: {
      width: "35%"
    },
    attrs: {
      span: "1"
    }
  })]), _vm._v(" "), _c("tr", {
    staticClass: "text-left"
  }, [_c("th", [_vm._v("Head Office")]), _vm._v(" "), _c("td", [_vm.editMode ? _c("el-input", {
    attrs: {
      type: "text",
      size: "mini"
    },
    model: {
      value: _vm.activeCompetitor.headoffice,
      callback: function callback($$v) {
        _vm.$set(_vm.activeCompetitor, "headoffice", $$v);
      },
      expression: "activeCompetitor.headoffice"
    }
  }) : _c("span", [_vm._v(_vm._s(_vm.activeCompetitor.headoffice))])], 1), _vm._v(" "), _c("td"), _vm._v(" "), _c("th", [_vm._v("Category")]), _vm._v(" "), _c("td", [_vm.editMode ? _c("el-select", {
    attrs: {
      size: "mini",
      placeholder: "Select"
    },
    model: {
      value: _vm.activeCompetitor.category,
      callback: function callback($$v) {
        _vm.$set(_vm.activeCompetitor, "category", $$v);
      },
      expression: "activeCompetitor.category"
    }
  }, _vm._l(_vm.categories, function (category) {
    return _c("el-option", {
      key: category.title,
      attrs: {
        label: category.title,
        value: category.title
      }
    });
  }), 1) : _c("span", [_vm._v(_vm._s(_vm.activeCompetitor.category))])], 1)]), _vm._v(" "), _c("tr", {
    staticClass: "text-left"
  }, [_c("th", [_vm._v("Competitor Offering")]), _vm._v(" "), _c("td", [_vm.editMode ? _c("el-input", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.activeCompetitor.theiroffering,
      callback: function callback($$v) {
        _vm.$set(_vm.activeCompetitor, "theiroffering", $$v);
      },
      expression: "activeCompetitor.theiroffering"
    }
  }) : _c("el-tag", {
    attrs: {
      size: "mini",
      tyle: "text-align:justify"
    }
  }, [_vm._v(_vm._s(_vm.activeCompetitor.theiroffering))])], 1), _vm._v(" "), _c("td"), _vm._v(" "), _c("th", [_vm._v("Threat")]), _vm._v(" "), _c("td", [_vm.editMode ? _c("el-select", {
    attrs: {
      size: "mini",
      placeholder: "Select"
    },
    model: {
      value: _vm.activeCompetitor.risk,
      callback: function callback($$v) {
        _vm.$set(_vm.activeCompetitor, "risk", $$v);
      },
      expression: "activeCompetitor.risk"
    }
  }, _vm._l(_vm.threats, function (threat) {
    return _c("el-option", {
      key: threat.title,
      attrs: {
        label: threat.title,
        value: threat.title
      }
    });
  }), 1) : _c("span", [_c("el-tag", {
    attrs: {
      type: _vm.computeRisk(_vm.activeCompetitor.risk),
      size: "mini"
    }
  }, [_vm._v("\n                        " + _vm._s(_vm.activeCompetitor.risk) + " ")])], 1)], 1)]), _vm._v(" "), _c("tr", {
    staticClass: "text-left"
  }, [_c("th", [_vm._v("Users")]), _vm._v(" "), _c("td", [_vm.editMode ? _c("el-input", {
    attrs: {
      type: "number",
      size: "mini"
    },
    model: {
      value: _vm.activeCompetitor.users,
      callback: function callback($$v) {
        _vm.$set(_vm.activeCompetitor, "users", $$v);
      },
      expression: "activeCompetitor.users"
    }
  }) : _c("span", [_vm._v(_vm._s(_vm.activeCompetitor.users))])], 1)])]), _vm._v(" "), _c("el-tabs", {
    staticClass: "mt-20",
    attrs: {
      value: "competitor_feature_tab"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "Competitor Features",
      name: "competitor_feature_tab"
    }
  }, [_c("div", {
    staticClass: "fr"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.openAddFeatureDialog();
      }
    }
  }, [_vm._v("Add Feature")])], 1), _vm._v(" "), _c("el-table", {
    attrs: {
      data: _vm.activeCompetitor.linkedFeatures,
      stripe: "",
      size: "mini"
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "competitorFeature.title",
      label: "Competitor Feature",
      "min-width": 180
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Challenged?"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("span", [_vm._v(_vm._s(scope.row.competitorFeature.challenged))])];
      }
    }], null, false, 3049691131)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "competitorFeature.maturity",
      label: "Maturity"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Threat"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-tag", {
          attrs: {
            type: _vm.computeRisk(scope.row.competitorFeature.risk),
            size: "mini"
          }
        }, [_vm._v("\n                          " + _vm._s(scope.row.competitorFeature.risk) + "\n                        ")])];
      }
    }], null, false, 3398936719)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "strength"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-tag", {
          attrs: {
            size: "mini",
            type: _vm.computeStrength(scope.row.competitorFeature.strength)
          }
        }, [_vm._v("\n                          " + _vm._s(scope.row.competitorFeature.strength) + "\n                        ")])];
      }
    }], null, false, 1465718793)
  }), _vm._v(" "), _c("el-table-column", {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-tooltip", {
          staticClass: "item",
          attrs: {
            effect: "dark",
            content: "Edit",
            placement: "top"
          }
        }, [_c("el-button", {
          attrs: {
            type: "secondary",
            circle: "",
            size: "mini",
            icon: "el-icon-edit"
          },
          on: {
            click: function click($event) {
              return _vm.openEditFeatureDialog(scope.row, scope.$index);
            }
          }
        })], 1), _vm._v(" "), _c("el-button", {
          attrs: {
            type: "danger",
            circle: "",
            size: "mini",
            icon: "el-icon-delete"
          },
          nativeOn: {
            click: function click($event) {
              $event.preventDefault();
              return _vm.deleteCompetitorFeature(scope.row.competitorFeature.id, scope.row.$index);
            }
          }
        })];
      }
    }], null, false, 3967255286)
  })], 1)], 1), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: "Strategy",
      name: "strategy_tab"
    }
  }, [_c("table", {
    attrs: {
      width: "100%"
    }
  }, [_c("colgroup", [_c("col", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      span: "1"
    }
  }), _vm._v(" "), _c("col", {
    staticStyle: {
      width: "5%"
    },
    attrs: {
      span: "1"
    }
  }), _vm._v(" "), _c("col", {
    staticStyle: {
      width: "45%"
    },
    attrs: {
      span: "1"
    }
  })]), _vm._v(" "), _c("tr", [_c("td", [_c("strong", [_vm._v("What they do well")]), _c("br"), _vm._v(" "), _vm.editMode ? _c("el-input", {
    attrs: {
      type: "textarea",
      rows: 6
    },
    model: {
      value: _vm.activeCompetitor.strength,
      callback: function callback($$v) {
        _vm.$set(_vm.activeCompetitor, "strength", $$v);
      },
      expression: "activeCompetitor.strength"
    }
  }) : _c("div", {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v(_vm._s(_vm.activeCompetitor.strength))])], 1), _vm._v(" "), _c("td"), _vm._v(" "), _c("td", [_c("table", [_c("colgroup", [_c("col", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      span: "1"
    }
  }), _vm._v(" "), _c("col", {
    staticStyle: {
      width: "30%"
    },
    attrs: {
      span: "1"
    }
  })]), _vm._v(" "), _c("tr", {
    staticClass: "text-left"
  }, [_c("th", [_vm._v("Active")]), _vm._v(" "), _c("td", [_vm.editMode ? _c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.activeCompetitor.active,
      callback: function callback($$v) {
        _vm.$set(_vm.activeCompetitor, "active", $$v);
      },
      expression: "activeCompetitor.active"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: true
    }
  }, [_vm._v("Yes")]), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: false
    }
  }, [_vm._v("No")])], 1) : _c("div", [!_vm.activeCompetitor.active ? _c("el-tag", {
    attrs: {
      size: "mini",
      type: "danger"
    }
  }, [_vm._v("No")]) : _vm._e(), _vm._v(" "), _vm.activeCompetitor.active ? _c("el-tag", {
    attrs: {
      size: "mini",
      type: "success"
    }
  }, [_vm._v("Yes")]) : _vm._e()], 1)], 1)]), _vm._v(" "), _c("tr", {
    staticClass: "text-left"
  }, [_c("th", [_vm._v("Acquired")]), _vm._v(" "), _c("td", [_vm.editMode ? _c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.activeCompetitor.acquired,
      callback: function callback($$v) {
        _vm.$set(_vm.activeCompetitor, "acquired", $$v);
      },
      expression: "activeCompetitor.acquired"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: true
    }
  }, [_vm._v("Yes")]), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: false
    }
  }, [_vm._v("No")])], 1) : _c("div", [!_vm.activeCompetitor.acquired ? _c("el-tag", {
    attrs: {
      size: "mini",
      type: "danger"
    }
  }, [_vm._v("No")]) : _vm._e(), _vm._v(" "), _vm.activeCompetitor.acquired ? _c("el-tag", {
    attrs: {
      size: "mini",
      type: "success"
    }
  }, [_vm._v("Yes")]) : _vm._e()], 1)], 1)]), _vm._v(" "), _c("tr", {
    staticClass: "text-left"
  }, [_vm.activeCompetitor.acquired ? _c("th", [_vm._v("Date")]) : _vm._e(), _vm._v(" "), _c("td", [_vm.editMode ? _c("el-date-picker", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      type: "date",
      size: "mini",
      placeholder: "Select",
      "value-format": "dd-MM-yyyy"
    },
    model: {
      value: _vm.activeCompetitor.date,
      callback: function callback($$v) {
        _vm.$set(_vm.activeCompetitor, "date", $$v);
      },
      expression: "activeCompetitor.date"
    }
  }) : _vm.activeCompetitor.acquired ? _c("span", [_vm._v(_vm._s(_vm.activeCompetitor.date))]) : _c("span")], 1)])])])])]), _vm._v(" "), _c("table", {
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      width: "100%"
    }
  }, [_c("colgroup", [_c("col", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      span: "1"
    }
  }), _vm._v(" "), _c("col", {
    staticStyle: {
      width: "5%"
    },
    attrs: {
      span: "1"
    }
  }), _vm._v(" "), _c("col", {
    staticStyle: {
      width: "45%"
    },
    attrs: {
      span: "1"
    }
  })]), _vm._v(" "), _c("tr", [_c("td", [_c("strong", [_vm._v("What we do well")]), _c("br"), _vm._v(" "), _vm.editMode ? _c("el-input", {
    attrs: {
      type: "textarea",
      rows: 6
    },
    model: {
      value: _vm.activeCompetitor.mystrength,
      callback: function callback($$v) {
        _vm.$set(_vm.activeCompetitor, "mystrength", $$v);
      },
      expression: "activeCompetitor.mystrength"
    }
  }) : _c("div", {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v(_vm._s(_vm.activeCompetitor.mystrength))])], 1), _vm._v(" "), _c("td"), _vm._v(" "), _c("td", [_vm.editMode || _vm.activeCompetitor.acquired ? _c("strong", [_vm._v("Acquisition details")]) : _vm._e(), _c("br"), _vm._v(" "), _vm.editMode && _vm.activeCompetitor.acquired ? _c("el-input", {
    attrs: {
      type: "textarea",
      rows: 6
    },
    model: {
      value: _vm.activeCompetitor.acquitision_details,
      callback: function callback($$v) {
        _vm.$set(_vm.activeCompetitor, "acquitision_details", $$v);
      },
      expression: "activeCompetitor.acquitision_details"
    }
  }) : _vm.activeCompetitor.acquired ? _c("div", {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_vm._v(_vm._s(_vm.activeCompetitor.acquitision_details))]) : _c("div")], 1)])])])], 1)], 1)], 1) : _vm._e()] : _vm._e()], 2)], 1) : _c("el-col", {
    attrs: {
      span: 19
    }
  }, [_c("el-empty", {
    attrs: {
      description: "No Competitors found"
    }
  })], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Filter List",
      visible: _vm.filterDialog,
      width: "30%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.filterDialog = $event;
      }
    }
  }, [_c("table", {
    staticStyle: {
      "table-layout": "fixed",
      width: "100%"
    }
  }, [_c("colgroup", [_c("col", {
    staticStyle: {
      width: "20%"
    },
    attrs: {
      span: "1"
    }
  }), _vm._v(" "), _c("col", {
    staticStyle: {
      width: "80%"
    },
    attrs: {
      span: "1"
    }
  })]), _vm._v(" "), _c("tr", [_c("th", {
    staticClass: "text-left"
  }, [_vm._v("Name")]), _vm._v(" "), _c("td", {
    staticClass: "flex"
  }, [_c("el-select", {
    staticStyle: {
      width: "150px"
    },
    attrs: {
      clearable: "",
      placeholder: "Select",
      size: "mini"
    },
    model: {
      value: _vm.search_competitor.filters.title_operator,
      callback: function callback($$v) {
        _vm.$set(_vm.search_competitor.filters, "title_operator", $$v);
      },
      expression: "search_competitor.filters.title_operator"
    }
  }, [_c("el-option", {
    attrs: {
      label: "EXACT",
      value: "EXACT"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "LIKE",
      value: "LIKE"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "NOT LIKE",
      value: "NOT LIKE"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "EMPTY",
      value: "EMPTY"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "NOT EMPTY",
      value: "NOT EMPTY"
    }
  })], 1), _vm._v(" "), _c("el-input", {
    staticClass: "ml-5",
    attrs: {
      type: "text",
      size: "mini"
    },
    model: {
      value: _vm.search_competitor.filters.title,
      callback: function callback($$v) {
        _vm.$set(_vm.search_competitor.filters, "title", $$v);
      },
      expression: "search_competitor.filters.title"
    }
  })], 1)]), _vm._v(" "), _c("tr", [_c("th", {
    staticClass: "text-left"
  }, [_vm._v("Description")]), _vm._v(" "), _c("td", {
    staticClass: "flex"
  }, [_c("el-select", {
    staticStyle: {
      width: "150px"
    },
    attrs: {
      clearable: "",
      placeholder: "Select",
      size: "mini"
    },
    model: {
      value: _vm.search_competitor.filters.description_operator,
      callback: function callback($$v) {
        _vm.$set(_vm.search_competitor.filters, "description_operator", $$v);
      },
      expression: "search_competitor.filters.description_operator"
    }
  }, [_c("el-option", {
    attrs: {
      label: "EXACT",
      value: "EXACT"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "LIKE",
      value: "LIKE"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "NOT LIKE",
      value: "NOT LIKE"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "EMPTY",
      value: "EMPTY"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "NOT EMPTY",
      value: "NOT EMPTY"
    }
  })], 1), _vm._v(" "), _c("el-input", {
    staticClass: "ml-5",
    attrs: {
      type: "text",
      size: "mini"
    },
    model: {
      value: _vm.search_competitor.filters.description,
      callback: function callback($$v) {
        _vm.$set(_vm.search_competitor.filters, "description", $$v);
      },
      expression: "search_competitor.filters.description"
    }
  })], 1)]), _vm._v(" "), _c("tr", [_c("th", {
    staticClass: "text-left"
  }, [_vm._v("Category")]), _vm._v(" "), _c("td", [_c("el-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      clearable: "",
      placeholder: "Select",
      size: "mini",
      multiple: ""
    },
    model: {
      value: _vm.search_competitor.filters.category,
      callback: function callback($$v) {
        _vm.$set(_vm.search_competitor.filters, "category", $$v);
      },
      expression: "search_competitor.filters.category"
    }
  }, [_c("el-option", {
    attrs: {
      label: "Direct",
      value: "Direct"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Close",
      value: "Close"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Peripheral",
      value: "Peripheral"
    }
  })], 1)], 1)]), _vm._v(" "), _c("tr", [_c("th", {
    staticClass: "text-left"
  }, [_vm._v("Threat")]), _vm._v(" "), _c("td", [_c("el-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      clearable: "",
      placeholder: "Select",
      size: "mini",
      multiple: ""
    },
    model: {
      value: _vm.search_competitor.filters.risk,
      callback: function callback($$v) {
        _vm.$set(_vm.search_competitor.filters, "risk", $$v);
      },
      expression: "search_competitor.filters.risk"
    }
  }, [_c("el-option", {
    attrs: {
      label: "Highest",
      value: "Highest"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "High",
      value: "High"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Med",
      value: "Med"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Low",
      value: "Low"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Lowest",
      value: "Lowest"
    }
  })], 1)], 1)]), _vm._v(" "), _c("tr", [_c("th", {
    staticClass: "text-left"
  }, [_vm._v("Active")]), _vm._v(" "), _c("td", [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.search_competitor.filters.active,
      callback: function callback($$v) {
        _vm.$set(_vm.search_competitor.filters, "active", $$v);
      },
      expression: "search_competitor.filters.active"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: true
    }
  }, [_vm._v("Yes")]), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: false
    }
  }, [_vm._v("No")])], 1)], 1)]), _vm._v(" "), _c("tr", [_c("th", {
    staticClass: "text-left"
  }, [_vm._v("Acquired")]), _vm._v(" "), _c("td", [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.search_competitor.filters.acquired,
      callback: function callback($$v) {
        _vm.$set(_vm.search_competitor.filters, "acquired", $$v);
      },
      expression: "search_competitor.filters.acquired"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: true
    }
  }, [_vm._v("Yes")]), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: false
    }
  }, [_vm._v("No")])], 1)], 1)])]), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.filterCompetitorList
    }
  }, [_vm._v("Filter")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: _vm.closeFilterDialog
    }
  }, [_vm._v("Cancel")])], 1)]), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: _vm.editFeatureMode ? "Edit Feature" : "Add Feature",
      visible: _vm.addFeatureDialog,
      width: "40%",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addFeatureDialog = $event;
      }
    }
  }, [_c("el-form", {
    ref: "feature_form",
    attrs: {
      model: _vm.feature_form,
      "label-width": "200px",
      rules: _vm.featureRules
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Feature",
      prop: "title"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.feature_form.title,
      callback: function callback($$v) {
        _vm.$set(_vm.feature_form, "title", $$v);
      },
      expression: "feature_form.title"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Description",
      prop: "description"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.feature_form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.feature_form, "description", $$v);
      },
      expression: "feature_form.description"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Maturity",
      prop: "maturity"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.feature_form.maturity,
      callback: function callback($$v) {
        _vm.$set(_vm.feature_form, "maturity", $$v);
      },
      expression: "feature_form.maturity"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: "high"
    }
  }, [_vm._v(" High ")]), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "medium"
    }
  }, [_vm._v(" Medium ")]), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "low"
    }
  }, [_vm._v(" Low ")])], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Challenged?",
      prop: "challenged"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.feature_form.challenged,
      callback: function callback($$v) {
        _vm.$set(_vm.feature_form, "challenged", $$v);
      },
      expression: "feature_form.challenged"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: "Yes"
    }
  }, [_vm._v(" Yes ")]), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "No"
    }
  }, [_vm._v(" No ")]), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Unknown"
    }
  }, [_vm._v(" Unknown ")])], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Threat",
      prop: "risk"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.feature_form.risk,
      callback: function callback($$v) {
        _vm.$set(_vm.feature_form, "risk", $$v);
      },
      expression: "feature_form.risk"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: "high"
    }
  }, [_vm._v(" High ")]), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "medium"
    }
  }, [_vm._v(" Medium ")]), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "low"
    }
  }, [_vm._v(" Low ")]), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "no-threat"
    }
  }, [_vm._v(" No Threat ")])], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Strength",
      prop: "strength"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.feature_form.strength,
      callback: function callback($$v) {
        _vm.$set(_vm.feature_form, "strength", $$v);
      },
      expression: "feature_form.strength"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: "strong"
    }
  }, [_vm._v(" Strong ")]), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "medium"
    }
  }, [_vm._v(" Medium ")]), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "weak"
    }
  }, [_vm._v("  Weak")])], 1)], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.addFeatureDialog = false;
      }
    }
  }, [_vm._v("Cancel")]), _vm._v(" "), _vm.editFeatureMode ? _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.updateFeatureCollection
    }
  }, [_vm._v("Update")]) : _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.addFeature
    }
  }, [_vm._v("Create")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Add Competitor",
      visible: _vm.addCompetitorDialog,
      width: "70%",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addCompetitorDialog = $event;
      }
    }
  }, [_c("el-form", {
    ref: "competitor_form",
    attrs: {
      model: _vm.competitor_form,
      "label-width": "200px",
      rules: _vm.competitorRules
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Name",
      prop: "title"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.competitor_form.title,
      callback: function callback($$v) {
        _vm.$set(_vm.competitor_form, "title", $$v);
      },
      expression: "competitor_form.title"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Description",
      prop: "description"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      size: "mini"
    },
    model: {
      value: _vm.competitor_form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.competitor_form, "description", $$v);
      },
      expression: "competitor_form.description"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Head Office",
      prop: "headoffice"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.competitor_form.headoffice,
      callback: function callback($$v) {
        _vm.$set(_vm.competitor_form, "headoffice", $$v);
      },
      expression: "competitor_form.headoffice"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Strengths",
      prop: "strength"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      size: "mini"
    },
    model: {
      value: _vm.competitor_form.strength,
      callback: function callback($$v) {
        _vm.$set(_vm.competitor_form, "strength", $$v);
      },
      expression: "competitor_form.strength"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Competitor Offering",
      prop: "theiroffering"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.competitor_form.theiroffering,
      callback: function callback($$v) {
        _vm.$set(_vm.competitor_form, "theiroffering", $$v);
      },
      expression: "competitor_form.theiroffering"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Number of Users",
      prop: "users"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.competitor_form.users,
      callback: function callback($$v) {
        _vm.$set(_vm.competitor_form, "users", $$v);
      },
      expression: "competitor_form.users"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Category",
      prop: "category"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.competitor_form.category,
      callback: function callback($$v) {
        _vm.$set(_vm.competitor_form, "category", $$v);
      },
      expression: "competitor_form.category"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: "Direct"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Close"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Peripheral"
    }
  })], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Threat",
      prop: "risk"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.competitor_form.risk,
      callback: function callback($$v) {
        _vm.$set(_vm.competitor_form, "risk", $$v);
      },
      expression: "competitor_form.risk"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: "Lowest"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Low"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Medium"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "High"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Highest"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Active",
      prop: "active"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.competitor_form.active,
      callback: function callback($$v) {
        _vm.$set(_vm.competitor_form, "active", $$v);
      },
      expression: "competitor_form.active"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: true
    }
  }, [_vm._v("Yes")]), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: false
    }
  }, [_vm._v("No")])], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Acquired",
      prop: "acquired"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.competitor_form.acquired,
      callback: function callback($$v) {
        _vm.$set(_vm.competitor_form, "acquired", $$v);
      },
      expression: "competitor_form.acquired"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: true
    }
  }, [_vm._v("Yes")]), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: false
    }
  }, [_vm._v("No")])], 1)], 1), _vm._v(" "), _vm.competitor_form.acquired ? _c("el-form-item", {
    attrs: {
      label: "Date Acquired"
    }
  }, [_c("el-input", {
    attrs: {
      type: "date",
      size: "mini"
    },
    model: {
      value: _vm.competitor_form.date,
      callback: function callback($$v) {
        _vm.$set(_vm.competitor_form, "date", $$v);
      },
      expression: "competitor_form.date"
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.competitor_form.acquired ? _c("el-form-item", {
    attrs: {
      label: "Acquisition Details"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      size: "mini"
    },
    model: {
      value: _vm.competitor_form.acquisitiondetails,
      callback: function callback($$v) {
        _vm.$set(_vm.competitor_form, "acquisitiondetails", $$v);
      },
      expression: "competitor_form.acquisitiondetails"
    }
  })], 1) : _vm._e()], 1)], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.addCompetitorDialog = false;
      }
    }
  }, [_vm._v("Cancel")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.createCompetitor
    }
  }, [_vm._v("Create")])], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;