"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "offering-idea-header pl-10"
  }, [_vm.editMode ? _c("el-input", {
    staticClass: "mt-5",
    attrs: {
      type: "text",
      size: "small"
    },
    model: {
      value: _vm.idea.title,
      callback: function callback($$v) {
        _vm.$set(_vm.idea, "title", $$v);
      },
      expression: "idea.title"
    }
  }) : _c("h2", [_vm._v("Offering Improvement: " + _vm._s(_vm.idea.title))]), _vm._v(" "), _c("div", {
    staticClass: "ideaVotes"
  }, [_vm._v("Votes: "), _c("span", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.vote_loading,
      expression: "vote_loading"
    }]
  }, [_vm._v(_vm._s(_vm.idea.score))])])], 1), _vm._v(" "), _c("div", {
    staticClass: "flex"
  }, [_c("div", {
    staticClass: "mt-10 ml-10 mr-20"
  }, [_vm._m(0), _vm._v(" "), _vm.editMode ? _c("el-input", {
    staticClass: "mt-5",
    attrs: {
      type: "textarea",
      rows: 4,
      size: "small"
    },
    model: {
      value: _vm.idea.description,
      callback: function callback($$v) {
        _vm.$set(_vm.idea, "description", $$v);
      },
      expression: "idea.description"
    }
  }) : _c("div", {
    staticClass: "mt-5 pl-5 ml-5 mr-20 text-justify scroll-y idea-text-areas"
  }, [_vm._v(_vm._s(_vm.idea.description))])], 1), _vm._v(" "), _c("div", {
    staticClass: "mt-20 ml-10"
  }, [_c("table", {
    staticStyle: {
      width: "100%"
    }
  }, [_vm._m(1), _vm._v(" "), _c("tr", [_c("th", {
    staticClass: "text-right pr-5"
  }, [_vm._v("Existing Offering")]), _vm._v(" "), _c("td", [_vm.editMode ? _c("el-input", {
    attrs: {
      type: "text",
      size: "mini"
    },
    model: {
      value: _vm.idea.offering,
      callback: function callback($$v) {
        _vm.$set(_vm.idea, "offering", $$v);
      },
      expression: "idea.offering"
    }
  }) : _c("div", {
    staticClass: "mt-5 pl-10 text-justify",
    staticStyle: {
      color: "rgb(96, 98, 102)"
    }
  }, [_vm._v(_vm._s(_vm.idea.offering))])], 1)]), _vm._v(" "), _c("tr", [_c("th", {
    staticClass: "text-right pr-5"
  }, [_vm._v("Status")]), _vm._v(" "), _c("td", {
    staticStyle: {
      width: "100%"
    }
  }, [_vm.editMode ? _c("el-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      clearable: "",
      size: "mini"
    },
    model: {
      value: _vm.idea.status,
      callback: function callback($$v) {
        _vm.$set(_vm.idea, "status", $$v);
      },
      expression: "idea.status"
    }
  }, _vm._l(_vm.statuses, function (status, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: status.label,
        value: status.value
      }
    });
  }), 1) : _c("div", {
    staticClass: "mt-5 pl-10 text-justify",
    staticStyle: {
      color: "rgb(96, 98, 102)"
    }
  }, [_vm._v(_vm._s(_vm.idea.status))])], 1)]), _vm._v(" "), _c("tr", [_c("th", {
    staticClass: "text-right pr-5"
  }, [_vm._v("Idea Scope")]), _vm._v(" "), _c("td", [_vm.editMode ? _c("el-select", {
    staticClass: "mr-5",
    staticStyle: {
      width: "100%"
    },
    attrs: {
      placeholder: "Select Scope",
      size: "mini"
    },
    model: {
      value: _vm.idea.scope,
      callback: function callback($$v) {
        _vm.$set(_vm.idea, "scope", $$v);
      },
      expression: "idea.scope"
    }
  }, [_c("el-option", {
    attrs: {
      label: "Offering",
      value: "Offering"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Product",
      value: "Product"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Service",
      value: "Service"
    }
  })], 1) : _c("div", {
    staticClass: "mt-5 pl-10 text-justify",
    staticStyle: {
      color: "rgb(96, 98, 102)"
    }
  }, [_vm._v(_vm._s(_vm.idea.scope))])], 1)]), _vm._v(" "), _c("tr", [_c("th", {
    staticClass: "text-right pr-5"
  }, [_c("strong", [_vm._v(_vm._s(_vm.idea.scope))])]), _vm._v(" "), _c("td", [_c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.idea.scope === "Offering" && _vm.editMode ? _c("el-select", {
    staticClass: "mr-5",
    staticStyle: {
      width: "100%"
    },
    attrs: {
      placeholder: "Select Offering/Variant",
      size: "mini",
      filterable: "",
      remote: "",
      "remote-method": _vm.retrieveOfferings
    },
    model: {
      value: _vm.idea.scopename,
      callback: function callback($$v) {
        _vm.$set(_vm.idea, "scopename", $$v);
      },
      expression: "idea.scopename"
    }
  }, _vm._l(_vm.offerings, function (offering) {
    return _c("el-option", {
      key: offering.id,
      attrs: {
        label: offering.title,
        value: offering.id
      }
    });
  }), 1) : _vm._e(), _vm._v(" "), _vm.idea.scope === "Product" && _vm.editMode ? _c("el-select", {
    staticClass: "mr-5",
    staticStyle: {
      width: "100%"
    },
    attrs: {
      placeholder: "Select Product Name",
      size: "mini",
      filterable: "",
      remote: "",
      "remote-method": _vm.retrieveProducts
    },
    model: {
      value: _vm.idea.scopename,
      callback: function callback($$v) {
        _vm.$set(_vm.idea, "scopename", $$v);
      },
      expression: "idea.scopename"
    }
  }, _vm._l(_vm.products, function (product) {
    return _c("el-option", {
      key: product.id,
      attrs: {
        label: product.title,
        value: product.id
      }
    });
  }), 1) : _vm._e()], 1), _vm._v(" "), _c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.idea.scope === "Service" && _vm.editMode ? _c("el-select", {
    staticClass: "mr-5",
    staticStyle: {
      width: "100%"
    },
    attrs: {
      placeholder: "Select Service Name",
      size: "mini",
      filterable: "",
      remote: "",
      "remote-method": _vm.retrieveServices
    },
    model: {
      value: _vm.idea.scopename,
      callback: function callback($$v) {
        _vm.$set(_vm.idea, "scopename", $$v);
      },
      expression: "idea.scopename"
    }
  }, _vm._l(_vm.services, function (service) {
    return _c("el-option", {
      key: service.id,
      attrs: {
        label: service.title,
        value: service.id
      }
    });
  }), 1) : _vm._e()], 1), _vm._v(" "), !_vm.editMode ? _c("div", {
    staticClass: "mt-5 pl-10 text-justify",
    staticStyle: {
      color: "rgb(96, 98, 102)"
    }
  }, [_vm._v(_vm._s(_vm.idea.scopename))]) : _vm._e()], 1)])])])]), _vm._v(" "), _c("div", {
    staticClass: "flex"
  }, [_c("div", {
    staticClass: "mt-10 ml-10 mr-20"
  }, [_vm._m(2), _vm._v(" "), _vm.editMode ? _c("el-input", {
    staticClass: "mt-5",
    attrs: {
      type: "textarea",
      rows: 8
    },
    model: {
      value: _vm.idea.problemstatement,
      callback: function callback($$v) {
        _vm.$set(_vm.idea, "problemstatement", $$v);
      },
      expression: "idea.problemstatement"
    }
  }) : _c("div", {
    staticClass: "mt-5 pl-5 ml-5 mr-20 text-justify scroll-y idea-text-areas"
  }, [_vm._v(_vm._s(_vm.idea.problemstatement))])], 1), _vm._v(" "), _c("div", {
    staticClass: "mt-10 mr-10"
  }, [_vm._m(3), _vm._v(" "), _vm.editMode ? _c("el-input", {
    staticClass: "mt-5",
    attrs: {
      type: "textarea",
      rows: 8
    },
    model: {
      value: _vm.idea.solution,
      callback: function callback($$v) {
        _vm.$set(_vm.idea, "solution", $$v);
      },
      expression: "idea.solution"
    }
  }) : _c("div", {
    staticClass: "mt-5 pl-5 ml-5 mr-20 text-justify scroll-y idea-text-areas"
  }, [_vm._v(_vm._s(_vm.idea.solution))])], 1)]), _vm._v(" "), _c("div", {
    staticClass: "flex"
  }, [_c("div", {
    staticClass: "mt-10 ml-10 mr-20"
  }, [_vm._m(4), _vm._v(" "), _vm.editMode ? _c("el-input", {
    staticClass: "mt-5",
    attrs: {
      type: "textarea",
      rows: 8
    },
    model: {
      value: _vm.idea.valueproposition,
      callback: function callback($$v) {
        _vm.$set(_vm.idea, "valueproposition", $$v);
      },
      expression: "idea.valueproposition"
    }
  }) : _c("div", {
    staticClass: "mt-5 pl-5 ml-5 mr-20 text-justify scroll-y idea-text-areas"
  }, [_vm._v(_vm._s(_vm.idea.valueproposition))])], 1), _vm._v(" "), _c("div", {
    staticClass: "mt-10 mr-10"
  }, [_vm._m(5), _vm._v(" "), _c("file-upload", {
    attrs: {
      "is-edit": _vm.editMode,
      "file-list": _vm.idea.fileobject,
      "entity-id": _vm.idea.id,
      "display-type": "table",
      "entity-name": "OfferingIdeation"
    },
    on: {
      "file-uploaded": _vm.addFile,
      "file-deleted": _vm.deleteFile
    }
  })], 1)]), _vm._v(" "), _c("div", {
    staticClass: "flex mt-10"
  }, [!_vm.editMode ? _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.voteUp
    }
  }, [_vm._v("Vote Up")]) : _vm._e(), _vm._v(" "), !_vm.editMode ? _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.voteDown
    }
  }, [_vm._v("Vote Down")]) : _vm._e(), _vm._v(" "), !_vm.editMode ? _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.editMode = true;
      }
    }
  }, [_vm._v("Edit")]) : _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.updateOffering
    }
  }, [_vm._v("Update")]), _vm._v(" "), !_vm.editMode ? _c("el-button", {
    attrs: {
      type: "danger",
      size: "mini"
    },
    on: {
      click: _vm.deleteIdea
    }
  }, [_vm._v("Delete")]) : _vm._e()], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "pl-10"
  }, [_c("strong", [_vm._v("Idea Description")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("colgroup", [_c("col", {
    staticStyle: {
      width: "35%"
    },
    attrs: {
      span: "1"
    }
  }), _vm._v(" "), _c("col", {
    staticStyle: {
      width: "65%"
    },
    attrs: {
      span: "1"
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "pl-10"
  }, [_c("strong", [_vm._v("Problem")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "pl-10"
  }, [_c("strong", [_vm._v("Solution")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "pl-10"
  }, [_c("strong", [_vm._v("Value Proposition")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "pl-10"
  }, [_c("strong", [_vm._v("Files")])]);
}];
render._withStripped = true;