"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _default2 = exports.default = {
  name: 'SubEntity',
  components: {},
  props: {
    x: {
      type: Number,
      required: true,
      default: 0
    },
    y: {
      type: Number,
      required: true,
      default: 0
    },
    width: {
      type: Number,
      required: true,
      default: 40
    },
    height: {
      type: Number,
      required: true,
      default: 10
    },
    entityIndex: {
      type: Number,
      required: true,
      default: 0
    },
    index: {
      type: Number,
      required: true,
      default: 0
    },
    objective: {
      type: Object,
      default: function _default() {
        return {
          title: '',
          description: '',
          childTickets: []
        };
      }
    }
  },
  data: function data() {
    return {
      loading: false,
      isHover: false
    };
  },
  computed: {
    computeStrokeColor: function computeStrokeColor() {
      if (this.objective.startdate !== null) {
        return '#ccc';
      }
      return this.objective.__typename === 'ProductFeature' ? '#6da1d8' : '#008080';
    },
    computeFillColor: function computeFillColor() {
      if (this.objective.startdate !== null) {
        return '#eee';
      }
      return this.objective.__typename === 'ProductFeature' ? '#f0f8ff' : '#cce5e5';
    }
  },
  watch: {
    'objective.id': {
      deep: true,
      handler: function handler(val) {}
    },
    objective: function objective(val) {}
  },
  mounted: function mounted() {},
  created: function created() {},
  methods: {
    computeSubEntityClass: function computeSubEntityClass(objective) {
      if (objective.__typename === 'ProductFeature' && this.isHover) {
        return 'offering_roadmap_shadow_feature_highlight';
      } else if (objective.__typename === 'ProductFeature' && !this.isHover) {
        return 'offering_roadmap_shadow_feature';
      } else if (objective.__typename === 'ServiceApplication' && this.isHover) {
        return 'offering_roadmap_shadow_process_highlight';
      } else if (objective.__typename === 'ServiceApplication' && !this.isHover) {
        return 'offering_roadmap_shadow_process';
      }
    },
    computeDragObjectiveID: function computeDragObjectiveID() {
      return 'objective_drag_' + this.entityIndex + '_' + this.index;
    },
    truncateString: function truncateString(string) {
      var length = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 20;
      if (this.objective.childTickets && this.objective.childTickets.length > 0) {
        length = 15;
      }
      return this._.truncate(string, {
        'length': length
      });
    },
    onObjectiveDragged: function onObjectiveDragged(_ref) {
      var el = _ref.el,
        deltaX = _ref.deltaX,
        deltaY = _ref.deltaY,
        offsetX = _ref.offsetX,
        offsetY = _ref.offsetY,
        clientX = _ref.clientX,
        clientY = _ref.clientY,
        first = _ref.first,
        last = _ref.last;
      if (last !== undefined && last) {
        this.$emit('drag-stop', this.objective.id, this.entityIndex, this.index, parseInt(deltaX), parseInt(deltaY));
        return;
      }
      if (deltaX === undefined || deltaY === undefined) {
        this.$emit('drag-start', this.objective.id, this.entityIndex, this.index, parseInt(deltaX), parseInt(deltaY));
        return;
      }
      // this.x += parseInt(deltaX)
      this.$emit('drag', this.objective.id, this.entityIndex, this.index, parseInt(deltaX), parseInt(deltaY));
    },
    onClick: function onClick() {
      this.$emit('click', this.objective, this.entityIndex, this.index);
    }
  }
};