"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-card", {
    staticClass: "text-center"
  }, [_c("el-switch", {
    attrs: {
      "active-text": "Progress with external status",
      "inactive-text": "Progress with prodiggi status"
    },
    model: {
      value: _vm.isExternalStatus,
      callback: function callback($$v) {
        _vm.isExternalStatus = $$v;
      },
      expression: "isExternalStatus"
    }
  })], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-card", {
    staticClass: "text-center"
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_vm._v("\n          Overall progress\n        ")]), _vm._v(" "), _c("el-progress", {
    attrs: {
      type: "circle",
      percentage: _vm.total
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-card", {
    staticClass: "text-center"
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_vm._v("\n          Product progress\n        ")]), _vm._v(" "), _c("el-progress", {
    attrs: {
      type: "circle",
      percentage: _vm.product.total
    }
  })], 1)], 1), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-card", {
    staticClass: "text-center"
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_vm._v("\n          Service progress\n        ")]), _vm._v(" "), _c("el-progress", {
    attrs: {
      type: "circle",
      percentage: _vm.service.total
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-tabs", {
    attrs: {
      value: "first"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "Product/Service",
      name: "first"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-table", {
    attrs: {
      height: "227",
      size: "micro",
      stripe: "",
      border: "",
      data: _vm.productList
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Product Title",
      prop: "title"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      sortable: "",
      label: "Progress"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-progress", {
          attrs: {
            percentage: scope.row.progress
          }
        })];
      }
    }])
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-table", {
    attrs: {
      height: "227",
      size: "micro",
      stripe: "",
      border: "",
      data: _vm.serviceList
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Service Title",
      prop: "title"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      sortable: "",
      label: "Progress"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-progress", {
          attrs: {
            percentage: scope.row.progress
          }
        })];
      }
    }])
  })], 1)], 1)], 1)], 1), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: "Statuses",
      name: "second"
    }
  }, [_c("pie-chart-component", {
    attrs: {
      abandoned: _vm.generatePercentage(_vm.statuses["ABANDONED"]),
      fixed: _vm.generatePercentage(_vm.statuses["FIXED"]),
      releaseready: _vm.generatePercentage(_vm.statuses["RELEASE READY"]),
      backlog: _vm.generatePercentage(_vm.statuses["Backlog"]),
      todo: _vm.generatePercentage(_vm.statuses["To Do"]),
      indesign: _vm.generatePercentage(_vm.statuses["IN DESIGN"]),
      indev: _vm.generatePercentage(_vm.statuses["IN DEV"]),
      inprogress: _vm.generatePercentage(_vm.statuses["In Progress"]),
      uibuild: _vm.generatePercentage(_vm.statuses["UI BUILD"]),
      backendbuild: _vm.generatePercentage(_vm.statuses["BACKEND BUILD"]),
      uicheck: _vm.generatePercentage(_vm.statuses["UI CHECK"]),
      uiwiring: _vm.generatePercentage(_vm.statuses["UI WIRING"]),
      uipolish: _vm.generatePercentage(_vm.statuses["UI POLISH"]),
      integrationcheck: _vm.generatePercentage(_vm.statuses["INTEGRATION CHECK"]),
      uat: _vm.generatePercentage(_vm.statuses["UAT"]),
      uatpolish: _vm.generatePercentage(_vm.statuses["UAT POLISH"]),
      height: "300pt",
      width: "1000pt"
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;