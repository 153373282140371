"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-card", {
    staticClass: "mt-10"
  }, [_c("el-tabs", {
    attrs: {
      value: "vision_roadmap"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "Product Vision",
      name: "vision_roadmap"
    }
  }, [_c("div", {
    staticClass: "pl-10 pr-10"
  }, [_c("el-row", [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("h4", [_vm._v("Vision Description")]), _vm._v(" "), _c("el-input", {
    attrs: {
      rows: 12,
      type: "textarea",
      placeholder: "Describe your product vision in no more than three sentences (600 characters)",
      maxlength: "600",
      "show-word-limit": ""
    },
    model: {
      value: _vm.vision_and_goals.product_vision,
      callback: function callback($$v) {
        _vm.$set(_vm.vision_and_goals, "product_vision", $$v);
      },
      expression: "vision_and_goals.product_vision"
    }
  })], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "text-right"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    }
  }, [_vm._v(" Update Vision ")])], 1)], 1)]), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: "Goal & Actions",
      name: "strategic_goal"
    }
  }, [_c("el-row", {
    staticClass: "no-padding",
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 11
    }
  }, [_c("el-row", [_c("el-col", {
    staticClass: "sub-title-rounded",
    attrs: {
      span: 24
    }
  }, [_vm._v("\n                Strategic Goals\n\n                "), _c("el-button", {
    staticClass: "fr",
    staticStyle: {
      margin: "-2pt 4pt -2pt 4pt"
    },
    attrs: {
      size: "mini",
      type: "secondary"
    },
    on: {
      click: function click($event) {
        _vm.addGoalDialog = true;
      }
    }
  }, [_vm._v("\n                  Add Goal\n                ")])], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "pl-10"
  }, [_c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.vision_and_goals.strategic_goals
    },
    on: {
      "row-click": _vm.selectGoal
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Goal",
      width: "410"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", {
          staticClass: "truncated-rows"
        }, [_vm._v("\n                      " + _vm._s(_vm.computeTableGoal(scope.row.goal)) + "\n                    ")])];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "priority",
      label: "Priority",
      width: "80"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      fixed: "right",
      label: "Operations"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [!scope.row.achieved ? _c("el-button-group", [_c("el-tooltip", {
          staticClass: "item",
          attrs: {
            effect: "dark",
            content: "Mark as done",
            placement: "top"
          }
        }, [_c("el-button", {
          staticStyle: {
            "font-size": "1.4em",
            padding: "2pt 5pt 2pt 5pt",
            border: "none",
            color: "darkgreen"
          },
          attrs: {
            type: "secondary",
            size: "mini",
            icon: "el-icon-success"
          },
          nativeOn: {
            click: function click($event) {
              $event.preventDefault();
              return _vm.finishGoal(scope.$index);
            }
          }
        })], 1), _vm._v(" "), _c("el-tooltip", {
          staticClass: "item",
          attrs: {
            effect: "dark",
            content: "Remove",
            placement: "top"
          }
        }, [_c("el-button", {
          staticStyle: {
            "font-size": "1.4em",
            padding: "2pt 5pt 2pt 5pt",
            border: "none",
            color: "darkred"
          },
          attrs: {
            type: "secondary",
            size: "mini",
            icon: "el-icon-error"
          },
          nativeOn: {
            click: function click($event) {
              $event.preventDefault();
              return _vm.deleteGoal(scope.$index);
            }
          }
        }, [_c("el-icon")], 1)], 1)], 1) : _c("el-button-group", [_c("el-button", {
          attrs: {
            disabled: "",
            type: "success",
            icon: "el-icon-check",
            size: "mini"
          }
        }, [_vm._v("Done")]), _vm._v(" "), _c("el-tooltip", {
          staticClass: "item",
          attrs: {
            effect: "dark",
            content: "Revert",
            placement: "top"
          }
        }, [_c("el-button", {
          attrs: {
            type: "warning",
            icon: "el-icon-d-arrow-left",
            size: "mini"
          },
          nativeOn: {
            click: function click($event) {
              $event.preventDefault();
              return _vm.revertGoal(scope.$index);
            }
          }
        })], 1)], 1)];
      }
    }])
  })], 1)], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-row", [_c("el-col", {
    staticClass: "sub-title-rounded",
    staticStyle: {
      "min-height": "30pt"
    },
    attrs: {
      span: 24
    }
  }, [_vm._v("\n                Goal Details\n              ")])], 1), _vm._v(" "), _c("div", {
    staticClass: "pl-10 pr-5",
    class: {
      "goal-achieved": _vm.selectedGoal.achieved
    },
    staticStyle: {
      "background-color": "ghostwhite"
    }
  }, [_c("el-row", {
    staticClass: "p-5",
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 16
    }
  }, [_c("span", [_c("b", [_vm._v("Description:")])]), _vm._v(" "), _c("el-input", {
    staticStyle: {
      "font-size": "0.95em"
    },
    attrs: {
      rows: 5,
      type: "textarea",
      placeholder: "Describe your product vision in no more than three sentences (600 characters)",
      maxlength: "600",
      "show-word-limit": ""
    },
    model: {
      value: _vm.selectedGoal.goal,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedGoal, "goal", $$v);
      },
      expression: "selectedGoal.goal"
    }
  })], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-row", {
    attrs: {
      span: 24
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("span", [_c("b", [_vm._v("Priority:")])])]), _vm._v(" "), _c("el-col", {
    staticStyle: {
      "font-weight": "bold",
      "margin-top": "-2pt"
    },
    attrs: {
      span: 8
    }
  }, [_vm.selectedGoal.priority === "High" ? _c("el-tag", {
    staticStyle: {
      border: "solid red 1pt"
    },
    attrs: {
      type: "danger",
      size: "small"
    }
  }, [_vm._v("\n                        " + _vm._s(_vm.selectedGoal.priority) + "\n                      ")]) : _vm._e(), _vm._v(" "), _vm.selectedGoal.priority === "Medium" ? _c("el-tag", {
    staticStyle: {
      border: "solid 1pt #ff6600"
    },
    attrs: {
      type: "warning",
      size: "small"
    }
  }, [_vm._v("\n                        " + _vm._s(_vm.selectedGoal.priority) + "\n                      ")]) : _vm._e(), _vm._v(" "), _vm.selectedGoal.priority === "Low" ? _c("el-tag", {
    staticStyle: {
      border: "solid 1pt slategray"
    },
    attrs: {
      type: "info",
      size: "small"
    }
  }, [_vm._v("\n                        " + _vm._s(_vm.selectedGoal.priority) + "\n                      ")]) : _vm._e()], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      span: 24
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("p", {
    staticStyle: {
      "margin-top": "36pt"
    }
  }, [_c("b", [_vm._v("Change priority")])]), _vm._v(" "), _c("el-radio-group", {
    staticClass: "el-radio-group",
    staticStyle: {
      display: "block"
    },
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.selectedGoal.priority,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedGoal, "priority", $$v);
      },
      expression: "selectedGoal.priority"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: "Low",
      value: "How"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Medium",
      value: "Medium"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "High",
      value: "High"
    }
  })], 1)], 1)], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    staticClass: "mt-5 p-5",
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 16
    }
  }, [_c("span", {
    staticClass: "mt-20"
  }, [_c("b", [_vm._v("Strategy to achieve this goal")])]), _vm._v(" "), _c("el-input", {
    staticStyle: {
      "font-size": "0.95em"
    },
    attrs: {
      rows: 5,
      type: "textarea",
      placeholder: "What is the strategy to achieve this goal?",
      maxlength: "600",
      "show-word-limit": ""
    },
    model: {
      value: _vm.selectedGoal.strategy,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedGoal, "strategy", $$v);
      },
      expression: "selectedGoal.strategy"
    }
  })], 1), _vm._v(" "), _c("el-col")], 1), _vm._v(" "), _c("el-row", [_c("el-col", {
    staticClass: "sub-title-rounded",
    attrs: {
      span: 24
    }
  }, [_vm._v("\n                  Actions\n                  "), _c("el-button", {
    staticClass: "fr",
    staticStyle: {
      margin: "-2pt 4pt -2pt 4pt"
    },
    attrs: {
      size: "mini",
      type: "secondary"
    },
    on: {
      click: function click($event) {
        _vm.addActionDialog = true;
      }
    }
  }, [_vm._v("\n                    Add Action\n                  ")])], 1)], 1), _vm._v(" "), _c("el-table", {
    staticStyle: {
      width: "100%",
      "margin-bottom": "16pt"
    },
    attrs: {
      data: _vm.selectedGoal.actions
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "action",
      label: "Action"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "assigned_to",
      label: "Assigned To"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "priority",
      label: "Priority"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      fixed: "right",
      label: "Operations"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [!scope.row.achieved ? _c("el-button-group", [_c("el-tooltip", {
          staticClass: "item",
          attrs: {
            effect: "dark",
            content: "Mark as done",
            placement: "top"
          }
        }, [_c("el-button", {
          staticStyle: {
            "font-size": "1.4em",
            padding: "2pt 5pt 2pt 5pt",
            border: "none",
            color: "darkgreen"
          },
          attrs: {
            type: "secondary",
            size: "mini",
            icon: "el-icon-success"
          },
          nativeOn: {
            click: function click($event) {
              $event.preventDefault();
              return _vm.finishAction(scope.$index);
            }
          }
        })], 1), _vm._v(" "), _c("el-tooltip", {
          staticClass: "item",
          attrs: {
            effect: "dark",
            content: "Remove",
            placement: "top"
          }
        }, [_c("el-button", {
          staticStyle: {
            "font-size": "1.4em",
            padding: "2pt 5pt 2pt 5pt",
            border: "none",
            color: "darkred"
          },
          attrs: {
            type: "secondary",
            size: "mini",
            icon: "el-icon-error"
          },
          nativeOn: {
            click: function click($event) {
              $event.preventDefault();
              return _vm.deleteAction(scope.$index);
            }
          }
        }, [_c("el-icon")], 1)], 1)], 1) : _c("el-button-group", [_c("el-button", {
          attrs: {
            disabled: "",
            type: "success",
            icon: "el-icon-check",
            size: "mini"
          }
        }, [_vm._v("Done")]), _vm._v(" "), _c("el-tooltip", {
          staticClass: "item",
          attrs: {
            effect: "dark",
            content: "Revert",
            placement: "top"
          }
        }, [_c("el-button", {
          attrs: {
            type: "warning",
            icon: "el-icon-d-arrow-left",
            size: "mini"
          },
          nativeOn: {
            click: function click($event) {
              $event.preventDefault();
              return _vm.revertAction(scope.$index);
            }
          }
        })], 1)], 1)];
      }
    }])
  })], 1)], 1)], 1)], 1)], 1)], 1), _vm._v(" "), _c("el-divider"), _vm._v(" "), _c("div", {
    staticClass: "text-right"
  }, [_c("el-button-group", {
    attrs: {
      size: "mini"
    }
  }, [_c("el-tooltip", {
    attrs: {
      effect: "dark",
      content: "Go To Business Roadmap",
      placement: "top-start"
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.gotoBusinessRoadMap
    }
  }, [_vm._v("Next"), _c("i", {
    staticClass: "el-icon-arrow-right"
  })])], 1)], 1)], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Add new action",
      visible: _vm.addActionDialog,
      width: "30%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addActionDialog = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.action_form,
      "label-width": "120px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Action"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.action_form.action,
      callback: function callback($$v) {
        _vm.$set(_vm.action_form, "action", $$v);
      },
      expression: "action_form.action"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Description"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.action_form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.action_form, "description", $$v);
      },
      expression: "action_form.description"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Assigned to"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Select Assignee"
    },
    model: {
      value: _vm.action_form.assigned_to,
      callback: function callback($$v) {
        _vm.$set(_vm.action_form, "assigned_to", $$v);
      },
      expression: "action_form.assigned_to"
    }
  }, [_c("el-option", {
    attrs: {
      label: "John",
      value: "john"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Doe",
      value: "doe"
    }
  })], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Priority"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "small"
    },
    model: {
      value: _vm.action_form.priority,
      callback: function callback($$v) {
        _vm.$set(_vm.action_form, "priority", $$v);
      },
      expression: "action_form.priority"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: "Low",
      value: "Low"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Medium",
      value: "Medium"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "High",
      value: "High"
    }
  })], 1)], 1), _vm._v(" "), _c("el-form-item", [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.addAction
    }
  }, [_vm._v("Create")]), _vm._v(" "), _c("el-button", {
    on: {
      click: function click($event) {
        _vm.addActionDialog = false;
      }
    }
  }, [_vm._v("Cancel")])], 1)], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Add new goal",
      visible: _vm.addGoalDialog,
      width: "30%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addGoalDialog = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.goal_form,
      "label-width": "120px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Goal"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.goal_form.goal,
      callback: function callback($$v) {
        _vm.$set(_vm.goal_form, "goal", $$v);
      },
      expression: "goal_form.goal"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Strategy"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.goal_form.strategy,
      callback: function callback($$v) {
        _vm.$set(_vm.goal_form, "strategy", $$v);
      },
      expression: "goal_form.strategy"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Priority"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "small"
    },
    model: {
      value: _vm.goal_form.priority,
      callback: function callback($$v) {
        _vm.$set(_vm.goal_form, "priority", $$v);
      },
      expression: "goal_form.priority"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: "Low",
      value: "Low"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Medium",
      value: "Medium"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "High",
      value: "High"
    }
  })], 1)], 1), _vm._v(" "), _c("el-form-item", [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.addGoal
    }
  }, [_vm._v("Create")]), _vm._v(" "), _c("el-button", {
    on: {
      click: function click($event) {
        _vm.addGoalDialog = false;
      }
    }
  }, [_vm._v("Cancel")])], 1)], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;