"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/taggedTemplateLiteral.js"));
var _templateObject, _templateObject2, _templateObject3;
var _default2 = exports.default = {
  name: 'ObjectiveDetail',
  components: {},
  props: {
    objective: {
      type: Object,
      default: function _default() {
        return {
          title: '',
          description: '',
          linkedSubInitiatives: []
        };
      }
    }
  },
  data: function data() {
    return {
      loading: false,
      offering_progress_internal: 0,
      offering_progress_external: 0,
      offerings_reports: [],
      products_reports: [],
      services_reports: []
    };
  },
  computed: {},
  watch: {
    'objective.id': function objectiveId(newVal, oldVal) {
      if (this.objective.type === 'Operational' && this.objective.additionalattributes.hasOwnProperty('offeringId')) {
        this.retrieveReports();
      }
    }
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    retrieveReports: function retrieveReports() {
      this.retrieveOfferingReports();
      // this.retrieveProductReports()
      // this.retrieveServiceReports()
    },
    computeProgress: function computeProgress(status_list) {
      var length = status_list.length;
      var total_ticket_count = 0;
      var progress_count = 0;
      var max_progress = 0;
      for (var i = 0; i < status_list.length; i++) {
        total_ticket_count += status_list[i].noOfTickets;
        progress_count = (i + 1) * status_list[i].noOfTickets;
      }
      max_progress = total_ticket_count * length;
      if (total_ticket_count === 0 || max_progress === 0 || progress_count === 0) {
        return 0;
      }
      return Math.floor(progress_count / max_progress * 100);
    },
    retrieveOfferingReports: function retrieveOfferingReports() {
      var _this = this;
      this.loading = true;
      this.offerings_reports = [];
      this.$apollo.query({
        // Query
        query: window._gql(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["query {ReportData(type: Offering)}"])))
      }).then(function (data) {
        _this.loading = false;
        _this.offerings_reports = data.data.ReportData;
        for (var i = 0; i < _this.offerings_reports.length; i++) {
          if (_this.offerings_reports[i].id === _this.objective.additionalattributes.offeringId) {
            _this.offering_progress_internal = _this.computeProgress(_this.offerings_reports[i].ticketStats.internal);
            _this.offering_progress_external = _this.computeProgress(_this.offerings_reports[i].ticketStats.external);
          }
        }
      }).catch(function (error) {
        _this.loading = false;
        // Error
        _this.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
      });
    },
    retrieveProductReports: function retrieveProductReports() {
      var _this2 = this;
      this.loading = true;
      this.products_reports = [];
      this.$apollo.query({
        // Query
        query: window._gql(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["query {ReportData(type: Product)}"])))
      }).then(function (data) {
        _this2.loading = false;
        _this2.products_reports = data.data.ReportData;
      }).catch(function (error) {
        _this2.loading = false;
        // Error
        _this2.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
      });
    },
    retrieveServiceReports: function retrieveServiceReports() {
      var _this3 = this;
      this.loading = true;
      this.services_reports = [];
      this.$apollo.query({
        // Query
        query: window._gql(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["query {ReportData(type: Service)}"])))
      }).then(function (data) {
        _this3.loading = false;
        _this3.services_reports = data.data.ReportData;
      }).catch(function (error) {
        _this3.loading = false;
        // Error
        _this3.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
      });
    },
    closeDialog: function closeDialog() {
      this.$emit('close');
    },
    formatDate: function formatDate(date) {
      return this.$moment(date).format('ll');
    }
  }
};