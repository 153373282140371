"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.regexp.split");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("g", {
    attrs: {
      refs: "semi-automated-component"
    },
    on: {
      click: _vm.emitClick,
      mouseover: function mouseover($event) {
        return _vm.onMouseOver();
      },
      mouseleave: function mouseleave($event) {
        return _vm.onMouseLeave();
      }
    }
  }, [_c("filter", {
    attrs: {
      id: "shadow",
      "color-interpolation-filters": "sRGB"
    }
  }, [_c("feDropShadow", {
    attrs: {
      dx: "2",
      gy: "2",
      "std-deviation": "3",
      "flood-opacity": "0.3",
      "flood-color": "lightslategrey"
    }
  })], 1), _vm._v(" "), _c("foreignObject", {
    attrs: {
      x: _vm.x,
      y: _vm.y,
      width: _vm.minimized ? 30 : 40,
      height: _vm.minimized ? 30 : 40
    }
  }, [_c("div", {
    class: {
      active: _vm.highlight === "pain_gain"
    },
    attrs: {
      id: _vm.generateUniqueID(),
      draggable: ""
    },
    on: {
      drag: _vm.onDrag
    }
  }, [_vm.source === "JIRA" ? _c("svg-icon", {
    staticClass: "active",
    staticStyle: {
      position: "fixed",
      left: "30px"
    },
    attrs: {
      "icon-class": "jira-3"
    }
  }) : _vm._e(), _vm._v(" "), _c("svg-icon", {
    style: _vm.computeStyle(),
    attrs: {
      "icon-class": "process-mixed"
    }
  })], 1)]), _vm._v(" "), !_vm.minimized ? _c("g", [_vm.showLabel && _vm.label.indexOf(" ") === -1 ? _c("text", {
    style: _vm.computeTextStyle(),
    attrs: {
      x: _vm.x + 20,
      y: _vm.computeY(),
      "text-anchor": "middle",
      "font-size": "0.8em"
    }
  }, [_vm._v(_vm._s(_vm.label))]) : _vm._e(), _vm._v(" "), _vm.showLabel && _vm.label.indexOf(" ") >= 0 ? _c("text", {
    style: _vm.computeTextStyle(),
    attrs: {
      x: _vm.x,
      y: _vm.computeY(),
      "text-anchor": "middle",
      "font-size": "0.8em"
    }
  }, [_c("tspan", {
    attrs: {
      x: _vm.x + 20
    }
  }, [_vm._v(_vm._s(_vm.label.split(" ")[0]) + " ")]), _vm._v(" "), _c("tspan", {
    attrs: {
      x: _vm.x + 20,
      dy: _vm.minimized ? 7 : 10
    }
  }, [_vm._v(_vm._s(_vm.label.split(" ")[1]) + " ")])]) : _vm._e()]) : _vm._e()]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;