"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var _defineProperty2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/defineProperty.js"));
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    staticClass: "text-center",
    staticStyle: {
      "border-right": "dotted gray 1px",
      "min-height": "280pt"
    },
    attrs: {
      xs: {
        span: 24
      },
      sm: {
        span: 24
      },
      md: {
        span: 12
      },
      lg: {
        span: 12
      },
      xl: {
        span: 12
      }
    }
  }, [_c("div", [_c("transition", {
    attrs: {
      name: "fade-transform",
      mode: "out-in"
    }
  }, [_c("div", {
    staticStyle: {
      "margin-bottom": "40px"
    }
  }, [_c("h4", {
    staticClass: "text-left ml-20"
  }, [_vm._v("New, Copy, or Generate?")]), _vm._v(" "), _c("div", {
    staticClass: "text-left ml-20",
    staticStyle: {
      "font-size": "12px"
    }
  }, [_c("el-row", {
    staticClass: "mt-5"
  }, [_c("el-col", {
    attrs: {
      xs: {
        span: 24
      },
      sm: {
        span: 12
      },
      md: {
        span: 12
      },
      lg: {
        span: 10
      },
      xl: {
        span: 7
      }
    }
  }, [_c("el-button", {
    staticStyle: {
      width: "120px"
    },
    attrs: {
      type: _vm.createOption === "manual" ? "primary" : "secondary",
      size: "micro"
    },
    on: {
      click: function click($event) {
        return _vm.createManual();
      }
    }
  }, [_vm._v("Blank Journey")])], 1), _vm._v(" "), _c("el-col", {
    staticStyle: {
      "padding-top": "4pt"
    },
    attrs: {
      xs: {
        span: 24
      },
      sm: {
        span: 24
      },
      md: {
        span: 12
      },
      lg: {
        span: 14
      },
      xl: {
        span: 16
      }
    }
  }, [_vm._v("\n                  Work with a blank canvas.\n                ")])], 1), _vm._v(" "), _c("el-row", {
    staticClass: "mt-5"
  }, [_c("el-col", {
    attrs: {
      xs: {
        span: 24
      },
      sm: {
        span: 12
      },
      md: {
        span: 12
      },
      lg: {
        span: 10
      },
      xl: {
        span: 7
      }
    }
  }, [_c("el-button", {
    staticStyle: {
      width: "120px"
    },
    attrs: {
      type: _vm.createOption === "copy" ? "primary" : "secondary",
      size: "micro"
    },
    on: {
      click: function click($event) {
        return _vm.copyJourney();
      }
    }
  }, [_vm._v("Copy Journey")])], 1), _vm._v(" "), _c("el-col", {
    staticStyle: {
      "padding-top": "4pt"
    },
    attrs: {
      xs: {
        span: 24
      },
      sm: {
        span: 24
      },
      md: {
        span: 12
      },
      lg: {
        span: 14
      },
      xl: {
        span: 16
      }
    }
  }, [_vm._v("\n                  Quickly create news journeys by duplicating them.\n                ")])], 1), _vm._v(" "), _vm.needs && _vm.needs.linkedStages && _vm.needs.linkedStages.length > 0 ? _c("el-row", {
    staticClass: "mt-5"
  }, [_c("el-col", {
    attrs: {
      xs: {
        span: 24
      },
      sm: {
        span: 12
      },
      md: {
        span: 12
      },
      lg: {
        span: 10
      },
      xl: {
        span: 7
      }
    }
  }, [_c("el-button", {
    staticStyle: {
      width: "120px"
    },
    attrs: (0, _defineProperty2.default)((0, _defineProperty2.default)({
      type: _vm.createOption === "generate" ? "primary" : "secondary",
      size: "micro"
    }, "type", "success"), "plain", ""),
    on: {
      click: function click($event) {
        return _vm.displayGenerateJourneyUI();
      }
    }
  }, [_vm._v("\n                      Generate Journey\n                  ")])], 1), _vm._v(" "), _c("el-col", {
    staticStyle: {
      "padding-top": "4pt",
      "word-break": "normal",
      color: "green"
    },
    attrs: {
      xs: {
        span: 24
      },
      sm: {
        span: 24
      },
      md: {
        span: 12
      },
      lg: {
        span: 14
      },
      xl: {
        span: 16
      }
    }
  }, [_vm._v("\n                    Ensure "), _c("a", {
    staticStyle: {
      color: "darkgreen",
      "font-weight": "500"
    }
  }, [_vm._v("Jobs to Be Done")]), _vm._v(" is complete.\n                ")])], 1) : _c("el-row", {
    staticClass: "mt-5"
  }, [_c("el-col", {
    attrs: {
      xs: {
        span: 24
      },
      sm: {
        span: 12
      },
      md: {
        span: 12
      },
      lg: {
        span: 10
      },
      xl: {
        span: 7
      }
    }
  }, [_c("el-button", {
    staticStyle: {
      width: "120px"
    },
    attrs: {
      size: "micro",
      type: "warning",
      disabled: ""
    }
  }, [_vm._v("Generate Journey\n                    ")])], 1), _vm._v(" "), _c("el-col", {
    staticStyle: {
      "word-break": "normal",
      "padding-top": "4pt",
      color: "orange"
    },
    attrs: {
      xs: {
        span: 24
      },
      sm: {
        span: 24
      },
      md: {
        span: 12
      },
      lg: {
        span: 14
      },
      xl: {
        span: 16
      }
    }
  }, [_vm._v("\n                  Complete "), _c("a", {
    staticStyle: {
      color: "darkorange",
      "font-weight": "500"
    }
  }, [_vm._v("Jobs to be Done")]), _vm._v(" first.\n                ")])], 1)], 1)])])], 1), _vm._v(" "), _c("div", {
    staticStyle: {
      float: "bottom",
      "word-break": "normal"
    }
  }, [_vm.createOption === "manual" || _vm.createOption === "generate" ? _c("div", {
    staticClass: "text-left mt-10 ml-20 mr-20"
  }) : _c("div", {
    staticClass: "text-left mt-10 ml-20 mr-20",
    staticStyle: {
      "border-radius": "7pt",
      "background-color": "aliceblue",
      padding: "6pt",
      color: "darkslategrey",
      "font-size": "12px"
    }
  }, [_c("span", [_c("b", [_vm._v("Copy journeys to define niche segments and needs.")])]), _vm._v(" "), _c("ul", [_c("li", [_vm._v("Copying creates a full duplicate of an existing journey.")]), _vm._v(" "), _c("li", [_vm._v("Changes are made to the copied journey only.")])])])])]), _vm._v(" "), _c("el-col", {
    staticClass: "text-left",
    attrs: {
      xs: {
        span: 24
      },
      sm: {
        span: 24
      },
      md: {
        span: 12
      },
      lg: {
        span: 12
      },
      xl: {
        span: 12
      }
    }
  }, [_c("transition", {
    attrs: {
      name: "fade-transform",
      mode: "out-in"
    }
  }, [_vm.createOption === "generate" && _vm.needs && _vm.needs.linkedStages && _vm.needs.linkedStages.length > 0 ? _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }]
  }, [_c("div", {
    staticStyle: {
      margin: "0",
      padding: "0",
      "line-height": "1em"
    }
  }, [_c("strong", [_vm._v("Jobs To Be Done")])]), _vm._v(" "), _c("el-form", {
    ref: "journey_map_form",
    staticClass: "journey_map_form",
    attrs: {
      model: _vm.journey_map_form,
      rules: _vm.JourneyMapRules,
      "label-position": "top"
    }
  }, [_c("div", {
    staticClass: "no-word-breaks",
    staticStyle: {
      "margin-top": "40px",
      "margin-left": "30pt"
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "title",
      label: "Journey Name",
      "label-width": "100px"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "What is your journey name",
      size: "mini"
    },
    model: {
      value: _vm.journey_map_form.title,
      callback: function callback($$v) {
        _vm.$set(_vm.journey_map_form, "title", $$v);
      },
      expression: "journey_map_form.title"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      prop: "description",
      label: "Journey Description",
      "label-width": "100px"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      rows: "4",
      placeholder: "Describe your journey",
      size: "mini"
    },
    model: {
      value: _vm.journey_map_form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.journey_map_form, "description", $$v);
      },
      expression: "journey_map_form.description"
    }
  })], 1)], 1)]), _vm._v(" "), _vm.needs && _vm.needs.linkedStages && _vm.needs.linkedStages.length > 0 ? _c("el-table", {
    staticStyle: {
      "margin-top": "10px",
      "padding-left": "30pt",
      width: "100%"
    },
    attrs: {
      data: _vm.needs.linkedStages,
      stripe: "",
      size: "mini"
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "stage.title",
      label: "Stage"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Steps"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.stage.linkedSteps.length === 0 ? _c("span", [_vm._v(" No steps yet")]) : _c("el-tag", {
          attrs: {
            size: "mini"
          }
        }, [_vm._v(_vm._s(scope.row.stage.linkedSteps.length) + " step(s)")])];
      }
    }], null, false, 1859143535)
  })], 1) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "fr mt-10"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.generateJourney();
      }
    }
  }, [_vm._v("Generate")])], 1)], 1) : _vm.createOption === "generate" && _vm.needs && _vm.needs.linkedStages && _vm.needs.linkedStages.length === 0 ? _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "text-center",
    staticStyle: {
      "margin-top": "60px",
      "line-height": "1em"
    }
  }, [_c("strong", [_vm._v("No 'Jobs To Be Done' found")]), _vm._v(" "), _c("br"), _vm._v(" "), _c("div", {
    staticClass: "mt-10"
  }, [_c("el-button", {
    staticStyle: {
      width: "120px"
    },
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.redirectToJTBD();
      }
    }
  }, [_vm._v("Create JTBD")]), _vm._v(" "), _c("el-button", {
    staticStyle: {
      width: "120px"
    },
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.createManual();
      }
    }
  }, [_vm._v("Create Manually")])], 1)]) : _vm.createOption === "manual" ? _c("el-form", {
    ref: "journey_map_form",
    staticClass: "journey_map_form",
    attrs: {
      model: _vm.journey_map_form,
      rules: _vm.JourneyMapRules,
      "label-position": "top"
    }
  }, [_c("div", {
    staticClass: "no-word-breaks",
    staticStyle: {
      "margin-top": "40px",
      "margin-left": "30pt"
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "title",
      label: "Journey Name",
      "label-width": "100px"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "What is your journey name",
      size: "mini"
    },
    model: {
      value: _vm.journey_map_form.title,
      callback: function callback($$v) {
        _vm.$set(_vm.journey_map_form, "title", $$v);
      },
      expression: "journey_map_form.title"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      prop: "description",
      label: "Journey Description",
      "label-width": "100px"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      rows: "4",
      placeholder: "Describe your journey",
      size: "mini"
    },
    model: {
      value: _vm.journey_map_form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.journey_map_form, "description", $$v);
      },
      expression: "journey_map_form.description"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "text-left no-word-breaks fr"
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.createJourneyMap
    }
  }, [_vm._v("Create")])], 1)], 1)]) : _c("div", {
    staticStyle: {
      "margin-top": "60px"
    }
  }, [_c("div", {
    staticClass: "text-left"
  }, [_c("strong", [_vm._v("Existing Customer Journey")]), _vm._v(" "), _c("el-select", {
    staticStyle: {
      "padding-left": "20px"
    },
    attrs: {
      clearable: "",
      placeholder: "Select Customer",
      size: "mini"
    },
    on: {
      change: _vm.retrieveJourneyMapListForCustomer
    },
    model: {
      value: _vm.selectedCustomerId,
      callback: function callback($$v) {
        _vm.selectedCustomerId = $$v;
      },
      expression: "selectedCustomerId"
    }
  }, _vm._l(_vm.filteredCustomers, function (customer) {
    return _c("el-option", {
      key: customer.id,
      attrs: {
        label: customer.title,
        value: customer.id
      }
    });
  }), 1), _vm._v(" "), _vm.journey_maps.length > 0 ? _c("div", {
    staticClass: "text-right mt-10"
  }, [_c("strong", [_vm._v("Existing Journeys")]), _vm._v(" "), _c("el-select", {
    staticStyle: {
      "padding-left": "20px"
    },
    attrs: {
      clearable: "",
      placeholder: "Select Journeymap",
      size: "mini"
    },
    model: {
      value: _vm.selectedJourneyMapId,
      callback: function callback($$v) {
        _vm.selectedJourneyMapId = $$v;
      },
      expression: "selectedJourneyMapId"
    }
  }, _vm._l(_vm.journey_maps, function (journey_map) {
    return _c("el-option", {
      key: journey_map.journeyMap.id,
      attrs: {
        label: journey_map.journeyMap.title,
        value: journey_map.journeyMap.id
      }
    });
  }), 1)], 1) : _vm._e(), _vm._v(" "), _vm.selectedCustomerId && _vm.journey_maps.length === 0 && !_vm.loading ? _c("div", [_vm._v("This customer does not have any journeymap to copy from. Select a different customer")]) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "text-right"
  }, [_c("el-button", {
    staticClass: "ml-20 mt-10",
    attrs: {
      type: "primary",
      size: "mini",
      disabled: _vm.selectedCustomerId === "" || _vm.selectedJourneyMapId === ""
    },
    on: {
      click: _vm.copyJourneyMap
    }
  }, [_vm._v("Copy")])], 1)], 1)])], 1)], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;