"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default2 = exports.default = {
  name: 'StoryList',
  components: {},
  props: {
    stories: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      loading: false,
      selectedFeatureProcess: []
    };
  },
  computed: {},
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    closeDialog: function closeDialog() {
      this.$emit('close');
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.selectedFeatureProcess = val;
    },
    addToIncrement: function addToIncrement() {}
  }
};