"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _echarts = _interopRequireDefault(require("echarts"));
var _resize = _interopRequireDefault(require("./mixins/resize"));
var _default = exports.default = {
  mixins: [_resize.default],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    id: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '200px'
    },
    height: {
      type: String,
      default: '200px'
    }
  },
  data: function data() {
    return {
      chart: null
    };
  },
  mounted: function mounted() {
    this.initChart();
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart: function initChart() {
      this.chart = _echarts.default.init(document.getElementById(this.id));
      this.chart.setOption({
        title: {
          text: 'Customer - User Type relation'
        },
        tooltip: {},
        animationDurationUpdate: 1500,
        animationEasingUpdate: 'quinticInOut',
        series: [{
          type: 'graph',
          layout: 'none',
          symbolSize: 50,
          roam: true,
          label: {
            show: true
          },
          edgeSymbol: ['circle', 'arrow'],
          edgeSymbolSize: [4, 4],
          edgeLabel: {
            fontSize: 20
          },
          data: [{
            name: 'Individual',
            x: 300,
            y: 300
          }, {
            name: 'Couple',
            x: 300,
            y: 310
          }, {
            name: 'Family',
            x: 300,
            y: 320
          }, {
            name: 'Individual User Type',
            x: 500,
            y: 300
          }, {
            name: 'Group',
            x: 500,
            y: 350
          }],
          // links: [],
          links: [{
            source: 'Individual',
            target: 'Individual User Type',
            symbolSize: [2, 10]
          }, {
            source: 'Couple',
            target: 'Individual User Type',
            symbolSize: [2, 10]
          }, {
            source: 'Family',
            target: 'Group',
            symbolSize: [2, 10]
          }, {
            source: 'Individual',
            target: 'Group',
            symbolSize: [2, 10]
          }],
          lineStyle: {
            opacity: 0.9,
            width: 2
          }
        }]
      });
    }
  }
};