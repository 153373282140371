"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/taggedTemplateLiteral.js"));
var _templateObject, _templateObject2;
var _default2 = exports.default = {
  name: 'OfferingIdeaCreate',
  components: {},
  props: {
    ideas: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    var validateTitle = function validateTitle(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter a valid title'));
      } else {
        callback();
      }
    };
    var validateDescription = function validateDescription(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter a valid description'));
      } else {
        callback();
      }
    };
    var validateProblemStatement = function validateProblemStatement(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter a valid background'));
      } else {
        callback();
      }
    };
    var validateSolution = function validateSolution(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter a valid justification'));
      } else {
        callback();
      }
    };
    var validateValueProposition = function validateValueProposition(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter a valid valueproposition'));
      } else {
        callback();
      }
    };
    var validateScope = function validateScope(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter a valid scope'));
      } else {
        callback();
      }
    };
    return {
      offeringRules: {
        title: [{
          required: true,
          trigger: 'blur',
          validator: validateTitle
        }],
        description: [{
          required: true,
          trigger: 'blur',
          validator: validateDescription
        }],
        problemstatement: [{
          required: true,
          trigger: 'blur',
          validator: validateProblemStatement
        }],
        solution: [{
          required: true,
          trigger: 'blur',
          validator: validateSolution
        }],
        valueproposition: [{
          required: true,
          trigger: 'blur',
          validator: validateValueProposition
        }],
        scope: [{
          required: true,
          trigger: 'blur',
          validator: validateScope
        }]
      },
      loading: false,
      activeStep: 'step1',
      selectedCustomerType: '',
      form: {
        parent_offering_idea_id: '',
        title: '',
        description: '',
        problemstatement: '',
        valueproposition: '',
        status: '',
        scope: ''
      },
      offerings: [],
      statuses: [{
        label: 'Proposed',
        value: 'Proposed'
      }, {
        label: 'Evaluation',
        value: 'Evaluation'
      }, {
        label: 'Approved',
        value: 'Approved'
      }, {
        label: 'Starting Up',
        value: 'Starting Up'
      }, {
        label: 'Launched',
        value: 'Launched'
      }, {
        label: 'Abandoned',
        value: 'Abandoned'
      }]
    };
  },
  computed: {},
  mounted: function mounted() {
    this.retrieveOfferings(1);
  },
  methods: {
    createOffering: function createOffering() {
      var _this = this;
      this.loading = true;
      this.$apollo.mutate({
        // Query
        mutation: window._gql(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["mutation createBusinessIdea($busIdea: CreateBusinessIdeaInput!)\n        {\n          createBusinessIdea(input: $busIdea) {\n            id\n            title\n            description\n            problemstatement\n            solution\n            valueproposition\n            additionalattributes\n            fileobject\n            status\n            score\n            customer_type\n            scope\n            scopename\n            type\n            linkedCoreParties(optional: true){\n              coreParty(optional: true){\n                id\n                businessname\n              }\n            }\n            linkedOfferings(optional: true){\n              offering(optional: true){\n                id\n                title\n                description\n              }\n            }\n            linkedJourneyMaps(optional: true){\n              journeyMap(optional: true){\n                id\n                title\n                description\n              }\n            }\n            linkedCustomerTypes(optional: true){\n              customerType(optional: true){\n                id\n                title\n                description\n              }\n            }\n          }\n        }"]))),
        // Parameters
        variables: {
          'busIdea': {
            'title': this.form.title,
            'description': this.form.description,
            'problemstatement': this.form.problemstatement,
            'solution': this.form.solution,
            'valueproposition': this.form.valueproposition,
            'fileobject': [],
            'status': 'Proposed',
            'scope': this.form.scope,
            'score': 0,
            'type': 'offering',
            'linkEntityId': this.form.parent_offering_idea_id,
            'linkEntityName': 'OFFERING'
          }
        }
      }).then(function (data) {
        _this.$notify({
          title: 'Success',
          message: 'Created Successfully',
          type: 'success',
          duration: 2000
        });
        var idea = data.data.createBusinessIdea;
        _this.$emit('create', idea);
        _this.resetForm();
        _this.loading = false;
        _this.$emit('close');
      }).catch(function (error) {
        // Error
        _this.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this.loading = false;
      });
    },
    retrieveOfferings: function retrieveOfferings(page_number) {
      var _this2 = this;
      this.loading = true;
      this.offerings = [];
      this.$apollo.query({
        // Query
        query: window._gql(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)([" query Offerings($page: Page)\n          {\n          Offerings(page: $page)\n          {\n            pages\n            total\n            select{\n              id\n              title\n              description\n            }\n          }\n        }"]))),
        fetchPolicy: 'no-cache',
        variables: {
          'page': {
            'limit': 20,
            'start': page_number
          }
        }
      }).then(function (data) {
        _this2.$notify({
          title: 'Success',
          message: 'Retrieved Successfully',
          type: 'success',
          duration: 2000
        });
        _this2.offerings = data.data.Offerings.select;
        _this2.loading = false;
      }).catch(function (error) {
        // Error
        _this2.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this2.loading = false;
      });
    },
    selectOffering: function selectOffering(offering) {
      this.form.parent_offering_idea_id = offering.id;
      this.goToStep('step2');
    },
    goToStep: function goToStep(step) {
      var _this3 = this;
      switch (step) {
        case 'step1':
          this.$emit('title', 'Existing Offering Improvement', 'All Offerings are shown below');
          this.activeStep = 'step1';
          break;
        case 'step2':
          this.$emit('title', 'Idea Details', 'How can this Offering be improved');
          this.activeStep = 'step2';
          break;
        case 'step3':
          this.$refs.form.validate(function (valid) {
            if (valid) {
              _this3.$emit('title', 'Confirm Offering Idea', 'Click Next to save your idea');
              _this3.activeStep = 'step3';
            } else {
              _this3.loading = false;
              return false;
            }
          });
          break;
      }
    },
    resetForm: function resetForm() {
      this.activeStep = 'step1';
      this.form = {
        parent_offering_idea_id: '',
        title: '',
        description: '',
        problemstatement: '',
        valueproposition: '',
        status: 'Proposed',
        scope: ''
      };
    }
  }
};