"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _axios = _interopRequireDefault(require("axios"));
var _default2 = exports.default = {
  name: 'FeaturePopover',
  components: {},
  props: {
    x: {
      type: Number,
      default: 0
    },
    y: {
      type: Number,
      default: 0
    },
    width: {
      type: Number,
      default: 0
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    feature: {
      type: Object,
      default: function _default() {
        return {
          title: '',
          description: '',
          type: ''
        };
      }
    }
  },
  data: function data() {
    return {
      isHover: false,
      ticket: {},
      child_tickets: []
    };
  },
  computed: {
    computeHeight: function computeHeight() {
      if (this.child_tickets.length > 0) {
        return this.child_tickets.length * 20 + 100;
      }
      return 100;
    }
  },
  watch: {
    feature: {
      handler: function handler(newValue) {
        this.selectTicket();
      },
      deep: true
    }
  },
  created: function created() {},
  mounted: function mounted() {
    this.selectTicket();
  },
  methods: {
    selectTicket: function selectTicket() {
      var _this = this;
      this.loading = true;
      // Retrieve Ticket and populate ticket modal
      var headers = {
        'Content-Type': 'application/json',
        'x-party-id': this.$store.getters.partyId,
        'x-user-id': this.$store.getters.id
      };
      _axios.default.get(process.env.VUE_APP_REST_API_ENDPOINT + 'ticket?extended=LEVEL0&entityId=' + this.feature.id, {
        headers: headers
      }).then(function (response) {
        _this.loading = false;
        _this.ticket = response.data.data[0];
        if (_this.ticket.externalSystemName) {
          _this.retrieveExternalChildTickets(response.data.data[0].externalId);
        } else {
          _this.retrieveChildTickets(response.data.data[0].id);
        }
      }).catch(function (error) {
        _this.loading = false;
        if (error.response.status === 400) {
          /* console.log(error.response.data.data.description)
          this.$notify({
            title: 'Error',
            message: error.response.data.data.description,
            type: 'error',
            duration: 2000
          })*/
          /* title, status, priority,*/
        } else {
          _this.$notify({
            title: 'Error',
            message: error.response.message,
            type: 'error',
            duration: 2000
          });
        }
      });
    },
    retrieveExternalChildTickets: function retrieveExternalChildTickets(ticket_external_id) {
      var _this2 = this;
      this.loading = true;
      // Retrieve Ticket and populate ticket modal
      var headers = {
        'Content-Type': 'application/json',
        'x-party-id': this.$store.getters.partyId,
        'x-user-id': this.$store.getters.id
      };
      _axios.default.get(process.env.VUE_APP_REST_API_ENDPOINT + 'ticket/' + ticket_external_id + '/link?external=true', {
        headers: headers
      }).then(function (response) {
        _this2.loading = false;
        _this2.child_tickets = [];
        _this2.child_tickets = response.data.data.tickets;
      }).catch(function (error) {
        _this2.loading = false;
        if (error.response.status === 400) {
          _this2.child_tickets = [];
        } else {
          _this2.$notify({
            title: 'Error',
            message: error.response.message,
            type: 'error',
            duration: 2000
          });
        }
      });
    },
    retrieveChildTickets: function retrieveChildTickets(ticket_id) {
      var _this3 = this;
      this.loading = true;
      // Retrieve Ticket and populate ticket modal
      var headers = {
        'Content-Type': 'application/json',
        'x-party-id': this.$store.getters.partyId,
        'x-user-id': this.$store.getters.id
      };
      _axios.default.get(process.env.VUE_APP_REST_API_ENDPOINT + 'ticket/' + ticket_id + '/link', {
        headers: headers
      }).then(function (response) {
        _this3.loading = false;
        _this3.child_tickets = [];
        _this3.child_tickets = response.data.data.tickets;
      }).catch(function (error) {
        _this3.loading = false;
        if (error.response.status === 400) {
          _this3.child_tickets = [];
        } else {
          _this3.$notify({
            title: 'Error',
            message: error.response.message,
            type: 'error',
            duration: 2000
          });
        }
      });
    },
    generateMarker: function generateMarker() {
      var x_start = this.x - 12;
      var y_start = this.y + 8;
      var x_mid = this.x;
      var y_mid = this.y + 3;
      var x_last = this.x;
      var y_last = this.y + 13;
      return 'M ' + x_start + ' ' + y_start + ' L ' + x_mid + ' ' + y_mid + ' L ' + x_last + ' ' + y_last + '';
    },
    closeFeatureDetailsPopover: function closeFeatureDetailsPopover() {
      this.ishover = false;
      this.$emit('close');
    },
    deleteFeature: function deleteFeature() {
      var _this4 = this;
      this.$confirm('This will permanently delete feature. Continue?', 'Warning', {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(function () {
        _this4.$emit('delete');
      }).catch(function (e) {
        console.log(e);
        _this4.$message({
          type: 'info',
          message: 'Delete canceled'
        });
      });
    },
    emitOnMouseOver: function emitOnMouseOver() {
      this.$emit('mouseover');
    },
    emitOnMouseLeave: function emitOnMouseLeave() {
      this.$emit('mouseleave');
    },
    truncateString: function truncateString(string, length) {
      return this._.truncate(string, {
        'length': length,
        'separator': '...'
      });
    }
  }
};