"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _default = exports.default = {
  name: 'SegmentCompetitors',
  components: {},
  props: {},
  data: function data() {
    return {
      loading: false,
      addFeatureDialog: false,
      addCompetitorDialog: false,
      competitorSearch: '',
      companyCategory: [],
      activeCompetitor: {},
      activeCompetitorIndex: 0,
      activeFeatureTypeIndex: '',
      competitiveDetailsTab: 'first',
      continents: [{
        name: 'Asia'
      }, {
        name: 'Europe'
      }, {
        name: 'Africa'
      }, {
        name: 'South America'
      }, {
        name: 'North America'
      }, {
        name: 'Australia'
      }, {
        name: 'Global'
      }],
      competitor_form: {
        name: '',
        category: '',
        head_office: '',
        segment: '',
        markets: [],
        strengths: '',
        weaknesses: '',
        pricing: '',
        users: 0,
        estimate_value: '',
        market_share: '',
        how_we_compete: '',
        features: [{
          type: 'common',
          feature_list: []
        }, {
          type: 'unique-competitor',
          feature_list: []
        }, {
          type: 'unique',
          feature_list: []
        }]
      },
      feature_form: {
        name: '',
        description: '',
        maturity: '',
        risk: ''
      },
      competitors: [{
        id: 1,
        name: 'Mendix',
        category: 'Direct',
        head_office: 'London',
        segment: 'Methodology',
        markets: ['North America', 'EU', 'Australia', 'Global'],
        strengths: 'Help create applications faster, more collaborative. Projects fail due to unclear or rigid requirements and lack of end users involvement. ',
        weaknesses: 'Not product driven, ie users or pain points.  UI is horrible. Dont offer review services. Bad company name..',
        pricing: '$1,650 pp',
        users: 1000,
        estimate_value: '',
        market_share: '',
        features: [{
          type: 'common',
          feature_list: [{
            name: 'UI Models',
            description: 'Clarify.',
            maturity: 'high',
            risk: 'high'
          }, {
            name: 'Full SDLC',
            description: 'Software, ie design, build, deploy, operate. Not product centric.',
            maturity: 'medium',
            risk: 'medium'
          }, {
            name: 'Open APIs',
            description: 'Clarify.',
            maturity: 'low',
            risk: 'low'
          }, {
            name: 'Multi platform deployment',
            description: 'Clarify.',
            maturity: 'low',
            risk: 'low'
          }]
        }, {
          type: 'unique-competitor',
          feature_list: [{
            name: 'Unique D',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            maturity: 'high',
            risk: 'high'
          }, {
            name: 'Unique E',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            maturity: 'medium',
            risk: 'medium'
          }, {
            name: 'Unique F',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            maturity: 'low',
            risk: 'low'
          }]
        }, {
          type: 'unique',
          feature_list: [{
            name: 'KFX Feature G',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            maturity: 'high',
            risk: 'high'
          }, {
            name: 'KFX Feature H',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            maturity: 'medium',
            risk: 'medium'
          }, {
            name: 'KFX Feature I',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            maturity: 'low',
            risk: 'low'
          }]
        }],
        how_we_compete: 'Product centric ideas, user pain and features, journey, better workflow.'
      }, {
        id: 2,
        name: 'Blueprint Storyteller',
        category: 'Direct',
        segment: 'Methodology',
        head_office: 'London',
        markets: ['North America', 'EU', 'APAC'],
        strengths: 'joins business to technical solution. Implements scaled agile. Bridges tech/business.Collaborative visual tool.',
        weaknesses: 'Product is complex, targetted at IT. incomplete product mgt. No design driven components. No UML. No production monitoring.',
        pricing: '',
        users: 1000,
        estimate_value: '',
        market_share: '',
        features: [{
          type: 'common',
          feature_list: [{
            name: 'Epic decomposition',
            description: 'Allows epics to be broken into stories.',
            maturity: 'low',
            risk: 'low'
          }, {
            name: 'Enterprise workflows',
            description: 'Clarify',
            maturity: 'low',
            risk: 'low'
          }, {
            name: 'Verb Noun Glossary',
            description: 'Clarify',
            maturity: 'High',
            risk: 'High'
          }, {
            name: 'Re-usable components',
            description: 'Clarify',
            maturity: 'Medium',
            risk: 'Medium'
          }]
        }, {
          type: 'unique-competitor',
          feature_list: [{
            name: 'Unique D',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            maturity: 'high',
            risk: 'high'
          }, {
            name: 'Unique E',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            maturity: 'medium',
            risk: 'medium'
          }, {
            name: 'Unique F',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            maturity: 'low',
            risk: 'low'
          }]
        }, {
          type: 'unique',
          feature_list: [{
            name: 'KFX Unique G',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            maturity: 'high',
            risk: 'high'
          }, {
            name: 'KFX Unique H',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            maturity: 'medium',
            risk: 'medium'
          }, {
            name: 'KFX Unique I',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            maturity: 'low',
            risk: 'low'
          }]
        }],
        how_we_compete: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
      }, {
        id: 3,
        name: 'Code Robots',
        category: 'Direct',
        head_office: 'Brisbane',
        segment: 'Methodology',
        markets: ['Australa'],
        strengths: 'Model driven code generation with strong visuals (WebGL).',
        weaknesses: 'It centric, not product centric. No Scaled Agile.',
        pricing: '',
        users: 1000,
        estimate_value: '',
        market_share: '',
        features: [{
          type: 'common',
          feature_list: [{
            name: 'Persona Drive',
            description: 'Supports different personas to use the app, and design different personas.',
            maturity: 'medium',
            risk: 'medium'
          }, {
            name: 'AI Driven MDG',
            description: 'Automates 95% of code generation',
            maturity: 'High',
            risk: 'High'
          }, {
            name: 'Backlog',
            description: 'Clarify.',
            maturity: 'low',
            risk: 'low'
          }]
        }, {
          type: 'unique-competitor',
          feature_list: [{
            name: 'Unique E',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            maturity: 'medium',
            risk: 'medium'
          }, {
            name: 'Unique F',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            maturity: 'low',
            risk: 'low'
          }]
        }, {
          type: 'unique',
          feature_list: [{
            name: 'KFC UniqueG',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            maturity: 'high',
            risk: 'high'
          }, {
            name: 'KFX Unique H',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            maturity: 'medium',
            risk: 'medium'
          }, {
            name: 'KFX Unique I',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
            maturity: 'low',
            risk: 'low'
          }]
        }],
        how_we_compete: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
      }, {
        id: 4,
        name: 'Balsamiq',
        category: 'Peripheral',
        head_office: 'Europe',
        segment: 'UX Tools',
        markets: ['Australa'],
        strengths: 'Wireframe modelling',
        weaknesses: 'Not end to end.',
        pricing: '$30 pp',
        users: 1000,
        estimate_value: '',
        market_share: '',
        features: [{
          type: 'common',
          feature_list: [{
            name: 'Wireframing',
            description: 'Allows wireframes to be built',
            maturity: 'low',
            risk: 'low'
          }, {
            name: 'Reviews',
            description: 'Allows designs to be reviewed',
            maturity: 'High',
            risk: 'High'
          }]
        }, {
          type: 'unique-competitor',
          feature_list: [{
            name: 'Confluence Integ.',
            description: 'Can integrate to Confluence.',
            maturity: 'medium',
            risk: 'medium'
          }]
        }, {
          type: 'unique',
          feature_list: [{
            name: 'WOPL Collaboration',
            description: 'Real time collaboration with customers',
            maturity: 'low',
            risk: 'low'
          }]
        }],
        how_we_compete: 'End to end product.'
      }]
    };
  },
  mounted: function mounted() {
    this.$eventHub.$emit('process-step-load', 'view-segment-competitors');
    this.activeCompetitor = this.competitors[0];
    this.activeCompetitorIndex = 0;
  },
  methods: {
    createCompetitor: function createCompetitor() {
      var competitor_form = Object.assign({}, this.competitor_form);
      this.competitors.push(competitor_form);
      this.addCompetitorDialog = false;
    },
    computeRisk: function computeRisk(risk) {
      switch (risk) {
        case 'low':
        case 'Low':
          return 'info';
        case 'medium':
        case 'Medium':
          return 'warning';
        case 'high':
        case 'High':
          return 'danger';
      }
    },
    rowClick: function rowClick(selection, col, event) {
      var activeCompetitorIndex = 0;
      this.activeCompetitor = selection;
      this.competitors.forEach(function (competitor, index) {
        if (competitor.name === selection.name) {
          activeCompetitorIndex = index;
        }
      });
      this.activeCompetitorIndex = activeCompetitorIndex;
    },
    hasActiveCompetitor: function hasActiveCompetitor() {
      return !window._.isEmpty(this.activeCompetitor);
    },
    computeTitle: function computeTitle(featureName) {
      switch (featureName) {
        case 'common':
          return 'We both offer';
        case 'unique-competitor':
          return 'They uniquely offer';
        case 'unique':
          return 'What we uniquely offer';
        default:
          return featureName;
      }
    },
    openAddFeatureDialog: function openAddFeatureDialog(index) {
      this.activeFeatureTypeIndex = index;
      this.addFeatureDialog = true;
    },
    addFeature: function addFeature() {
      this.competitors[this.activeCompetitorIndex].features[this.activeFeatureTypeIndex].feature_list.push(this.feature_form);
      this.addFeatureDialog = false;
    },
    convertArrayToString: function convertArrayToString(array) {
      return array.toString();
    },
    onClickAssessCompetitorFeatures: function onClickAssessCompetitorFeatures() {
      this.$emit('go-next');
    }
  }
};