"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  name: 'PricePackages',
  components: {},
  props: {},
  data: function data() {
    return {};
  },
  mounted: function mounted() {
    this.$eventHub.$emit('process-step-load', 'price-packages');
  },
  methods: {
    goToPackageFeatures: function goToPackageFeatures() {
      this.$emit('go-prev');
    },
    goToStep3: function goToStep3() {
      this.$emit('go-next');
    }
  }
};