"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-container"
  }, [_vm.selectedOffering && _vm.selectedOffering.id && _vm.activeRoute === "portfolio" ? _c("portfolio-component") : _c("el-empty", {
    attrs: {
      description: "Select an offering to view its portfolio"
    }
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;