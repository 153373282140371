"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/taggedTemplateLiteral.js"));
var _templateObject, _templateObject2, _templateObject3;
var _default = exports.default = {
  name: 'ValueChainCapabilties',
  components: {},
  props: {},
  data: function data() {
    return {
      loading: false,
      addValueStreamDialog: false,
      addProductDialog: false,
      addServiceDialog: false,
      addRequirementDialog: false,
      valueStreamDetailDialog: false,
      offeringDetailDialog: false,
      requirmentDetailDialog: false,
      competitors: {
        competitorOffering: 'c_off'
      },
      products: [],
      services: [],
      selectedCompetitorId: '',
      selectedCompetitor: {},
      selectedValueStreamIndex: '',
      selectedValueStepIndex: '',
      selectedValueStream: {},
      selectedOffering: {},
      selectedService: {},
      selectedRequirement: {},
      offeringHoverId: '',
      value_stream_form: {
        title: '',
        description: ''
      },
      requirement_form: {
        id: '',
        title: ''
      },
      product_form: {
        ids: []
      },
      service_form: {
        ids: []
      },
      tools: {
        valuestep: {
          x: 30,
          y: 60
        },
        valuestream: {
          x: 50,
          y: 190
        },
        product: {
          x: 50,
          y: 330
        },
        service: {
          x: 50,
          y: 490
        },
        requirement: {
          x: 50,
          y: 630
        }
      },
      value_steps: [{
        value_step_title: 'Market to Purchase',
        value_streams: [{
          title: 'Value Step',
          description: 'Value Step',
          offerings: [{
            id: '1',
            title: 'Product',
            type: 'product'
          }, {
            id: '12',
            title: 'Product 1',
            type: 'product'
          }, {
            id: 'Service',
            title: 'Service',
            type: 'service'
          }, {
            id: 'Service1',
            title: 'Service 1',
            type: 'service'
          }]
        }]
      }, {
        value_step_title: 'Injury to Workforce Return',
        value_streams: [{
          title: 'Value Step',
          description: 'Value Step',
          offerings: [{
            id: '21',
            title: 'Product',
            type: 'product'
          }, {
            id: '212',
            title: 'Product 1',
            type: 'product'
          }, {
            id: 'Service2123',
            title: 'Service',
            type: 'service'
          }, {
            id: 'Service2221',
            title: 'Service 1',
            type: 'service'
          }]
        }, {
          title: 'Claims Optimisation',
          description: 'Value Stream 1',
          offerings: [{
            id: '123',
            title: 'Product',
            type: 'product'
          }, {
            id: '1234',
            title: 'Product 1',
            type: 'product'
          }, {
            id: '1235',
            title: 'Service',
            type: 'service'
          }, {
            id: '123456',
            title: 'Service 1',
            type: 'service'
          }]
        }, {
          title: 'Value Step 2',
          description: 'Value Step 2',
          offerings: [{
            id: 'Product123',
            title: 'Product',
            type: 'product'
          }]
        }]
      }],
      regulatory: [{
        id: '1',
        title: 'Policy A'
      }, {
        id: '2',
        title: 'Insurance Act'
      }, {
        id: '3',
        title: 'RG160'
      }],
      drop_hover_details: {
        x: -100,
        y: -100,
        width: 60,
        height: 70,
        hover: false
      },
      featureDetails: {
        hover: false,
        x: 0,
        y: 0,
        width: 0,
        height: 0
      }
    };
  },
  computed: {
    lowRiskCompetitors: function lowRiskCompetitors() {
      if (this.competitors.length > 0) {
        return this.competitors.filter(function (competitor) {
          return competitor.risk.toLowerCase() === 'lowest' || competitor.risk.toLowerCase() === 'low';
        });
      } else {
        return [];
      }
    },
    mediumRiskCompetitors: function mediumRiskCompetitors() {
      if (this.competitors.length > 0) {
        return this.competitors.filter(function (competitor) {
          return competitor.risk.toLowerCase() === 'medium';
        });
      } else {
        return [];
      }
    },
    highRiskCompetitors: function highRiskCompetitors() {
      if (this.competitors.length > 0) {
        return this.competitors.filter(function (competitor) {
          return competitor.risk.toLowerCase() === 'highest' || competitor.risk.toLowerCase() === 'high';
        });
      } else {
        return [];
      }
    }
  },
  mounted: function mounted() {
    this.retrieveCompetitors(1);
  },
  methods: {
    onMouseOver: function onMouseOver(x, y, width) {
      this.featureDetails = {
        hover: true,
        x: x,
        y: y,
        width: width,
        height: 0
      };
    },
    onMouseLeave: function onMouseLeave(x, y, width) {
      this.featureDetails = {
        hover: false,
        x: x,
        y: y,
        width: width,
        height: 0
      };
    },
    trimString: function trimString(string, length) {
      if (string.length > length) {
        return string.substring(0, length) + '...';
      } else {
        return string;
      }
    },
    computeCompetitorY: function computeCompetitorY(competitor) {
      switch (competitor.risk.toLowerCase()) {
        case 'low':
        case 'lowest':
          return 54;
        case 'medium':
          return 88;
        case 'high':
        case 'highest':
          return 120;
      }
    },
    deleteOffering: function deleteOffering(valuestep_index, valuestream_index, product_index) {
      var _this = this;
      this.$confirm('This will permanently delete the product. Continue?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(function () {
        _this.value_steps[valuestep_index].value_streams[valuestream_index].products.splice(product_index, 1);
      }).catch(function () {
        _this.$message({
          type: 'info',
          message: 'Delete canceled'
        });
      });
    },
    deleteService: function deleteService(valuestep_index, valuestream_index, service_index) {
      var _this2 = this;
      this.$confirm('This will permanently delete the service. Continue?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(function () {
        _this2.value_steps[valuestep_index].value_streams[valuestream_index].services.splice(service_index, 1);
      }).catch(function () {
        _this2.$message({
          type: 'info',
          message: 'Delete canceled'
        });
      });
    },
    selectValueStream: function selectValueStream(valuestream) {
      this.selectedValueStream = valuestream;
      this.valueStreamDetailDialog = true;
    },
    selectOffering: function selectOffering(offering) {
      this.selectedOffering = offering;
      this.offeringDetailDialog = true;
    },
    selectRequirement: function selectRequirement(requirement) {
      this.selectedRequirement = requirement;
      this.requirmentDetailDialog = true;
    },
    addValueStream: function addValueStream() {
      var value_stream = Object.assign(this.value_stream_form, {});
      this.value_steps[this.selectedValueStepIndex].value_streams.push(value_stream);
      this.resetValueStreamForm();
      this.addValueStreamDialog = false;
    },
    addRequirement: function addRequirement() {
      var requirement = Object.assign(this.requirement_form, {});
      this.regulatory.push(requirement);
      this.resetRequirementForm();
      this.addRequirementDialog = false;
    },
    addProduct: function addProduct() {
      var product_form = Object.assign(this.product_form, {});
      for (var i = 0; i < this.products.length; i++) {
        if (product_form.ids.indexOf(this.products[i].id) !== -1) {
          this.products[i].type = 'product';
          this.value_steps[this.selectedValueStepIndex].value_streams[this.selectedValueStreamIndex].offerings.push(this.products[i]);
        }
      }
      this.resetProductForm();
      this.addProductDialog = false;
    },
    addService: function addService() {
      var service_form = Object.assign(this.service_form, {});
      for (var i = 0; i < this.services.length; i++) {
        if (service_form.ids.indexOf(this.services[i].id) !== -1) {
          this.services[i].type = 'service';
          this.value_steps[this.selectedValueStepIndex].value_streams[this.selectedValueStreamIndex].offerings.push(this.services[i]);
        }
      }
      this.resetProductForm();
      this.addServiceDialog = false;
    },
    onValueStepToolDragged: function onValueStepToolDragged(_ref) {
      var el = _ref.el,
        deltaX = _ref.deltaX,
        deltaY = _ref.deltaY,
        offsetX = _ref.offsetX,
        offsetY = _ref.offsetY,
        clientX = _ref.clientX,
        clientY = _ref.clientY,
        first = _ref.first,
        last = _ref.last;
      if (last !== undefined && last) {
        if (this.tools.valuestep.x > 160 && this.tools.valuestep.x < 1500 && this.tools.valuestep.y > 160 && this.tools.valuestep.y < this.value_steps.length * 175) {
          var valuestep = {
            value_streams: []
          };
          this.value_steps.push(valuestep);
        }
        this.resetTools();
        this.resetDropHover();
        return;
      }
      if (deltaX === undefined || deltaY === undefined) {
        return;
      }
      this.tools.valuestep.x += deltaX;
      this.tools.valuestep.y += deltaY;

      // Check if overlap with valuechain lane
      if (this.tools.valuestep.x > 155 && this.tools.valuestep.x < 1500 && this.tools.valuestep.y > 160 && this.tools.valuestep.y < this.value_steps.length * 175) {
        this.drop_hover_details.x = 160;
        this.drop_hover_details.y = 190;
        this.drop_hover_details.width = 1500;
        this.drop_hover_details.height = this.value_steps.length * 175;
        this.drop_hover_details.hover = true;
      }
    },
    onValueStreamToolDragged: function onValueStreamToolDragged(_ref2) {
      var el = _ref2.el,
        deltaX = _ref2.deltaX,
        deltaY = _ref2.deltaY,
        offsetX = _ref2.offsetX,
        offsetY = _ref2.offsetY,
        clientX = _ref2.clientX,
        clientY = _ref2.clientY,
        first = _ref2.first,
        last = _ref2.last;
      if (last !== undefined && last) {
        for (var j = 0; j < this.value_steps.length; j++) {
          if (this.tools.valuestream.x > 160 && this.tools.valuestream.x < 1500 && this.tools.valuestream.y > j * 175 + 160 && this.tools.valuestream.y < j * 175 + 320) {
            this.selectedValueStepIndex = j;
            this.resetValueStreamForm();
            this.addValueStreamDialog = true;
          }
        }
        this.resetTools();
        this.resetDropHover();
        return;
      }
      if (deltaX === undefined || deltaY === undefined) {
        return;
      }
      this.tools.valuestream.x += deltaX;
      this.tools.valuestream.y += deltaY;

      // Check if overlap with valuechain lane
      for (var i = 0; i < this.value_steps.length; i++) {
        if (this.tools.valuestream.x > 160 && this.tools.valuestream.x < 1500 && this.tools.valuestream.y > i * 175 + 160 && this.tools.valuestream.y < i * 180 + 320) {
          this.drop_hover_details.x = 160;
          this.drop_hover_details.y = i * 175 + 190;
          this.drop_hover_details.width = 1500;
          this.drop_hover_details.height = 170;
          this.drop_hover_details.hover = true;
        }
      }
    },
    onProductToolDragged: function onProductToolDragged(_ref3) {
      var el = _ref3.el,
        deltaX = _ref3.deltaX,
        deltaY = _ref3.deltaY,
        offsetX = _ref3.offsetX,
        offsetY = _ref3.offsetY,
        clientX = _ref3.clientX,
        clientY = _ref3.clientY,
        first = _ref3.first,
        last = _ref3.last;
      if (last !== undefined && last) {
        for (var k = 0; k < this.value_steps.length; k++) {
          for (var l = 0; l < this.value_steps[k].value_streams.length; l++) {
            var length1 = 300;
            var x1 = l * (length1 + 30) + 170;
            var y1 = 190 + k * 175;
            var point3_x1 = x1 + length1 + 70;
            if (this.tools.product.x > x1 && this.tools.product.x < point3_x1 && this.tools.product.y > y1 && this.tools.product.y < y1 + 110) {
              this.selectedValueStepIndex = k;
              this.selectedValueStreamIndex = l;
              this.retrieveProducts(1);
              this.resetProductForm();
              this.addProductDialog = true;
            }
          }
        }
        this.resetTools();
        this.resetDropHover();
        return;
      }
      if (deltaX === undefined || deltaY === undefined) {
        return;
      }
      this.tools.product.x += deltaX;
      this.tools.product.y += deltaY;
      for (var i = 0; i < this.value_steps.length; i++) {
        for (var j = 0; j < this.value_steps[i].value_streams.length; j++) {
          var length = 300;
          var x = j * (length + 30) + 170;
          var y = 230 + i * 175;
          var point3_x = x + length + 70;
          if (this.tools.product.x > x && this.tools.product.x < point3_x && this.tools.product.y > y && this.tools.product.y < y + 110) {
            this.drop_hover_details.x = x;
            this.drop_hover_details.y = y;
            this.drop_hover_details.width = length + 35;
            this.drop_hover_details.height = 110;
            this.drop_hover_details.hover = true;
          }
        }
      }
    },
    onServiceToolDragged: function onServiceToolDragged(_ref4) {
      var el = _ref4.el,
        deltaX = _ref4.deltaX,
        deltaY = _ref4.deltaY,
        offsetX = _ref4.offsetX,
        offsetY = _ref4.offsetY,
        clientX = _ref4.clientX,
        clientY = _ref4.clientY,
        first = _ref4.first,
        last = _ref4.last;
      if (last !== undefined && last) {
        for (var k = 0; k < this.value_steps.length; k++) {
          for (var l = 0; l < this.value_steps[k].value_streams.length; l++) {
            var length1 = 300;
            var x1 = l * (length1 + 30) + 170;
            var y1 = 190 + k * 175;
            var point3_x1 = x1 + length1 + 70;
            if (this.tools.service.x > x1 && this.tools.service.x < point3_x1 && this.tools.service.y > y1 && this.tools.service.y < y1 + 110) {
              this.selectedValueStepIndex = k;
              this.selectedValueStreamIndex = l;
              this.retrieveServices(1);
              this.resetServiceForm();
              this.addServiceDialog = true;
            }
          }
        }
        this.resetTools();
        this.resetDropHover();
        return;
      }
      if (deltaX === undefined || deltaY === undefined) {
        return;
      }
      this.tools.service.x += deltaX;
      this.tools.service.y += deltaY;
      for (var i = 0; i < this.value_steps.length; i++) {
        for (var j = 0; j < this.value_steps[i].value_streams.length; j++) {
          var length = 300;
          var x = j * (length + 30) + 170;
          var y = 230 + i * 175;
          var point3_x = x + length + 70;
          if (this.tools.service.x > x && this.tools.service.x < point3_x && this.tools.service.y > y && this.tools.service.y < y + 110) {
            this.drop_hover_details.x = x;
            this.drop_hover_details.y = y;
            this.drop_hover_details.width = length + 35;
            this.drop_hover_details.height = 110;
            this.drop_hover_details.hover = true;
          }
        }
      }
    },
    onRequirementToolDragged: function onRequirementToolDragged(_ref5) {
      var el = _ref5.el,
        deltaX = _ref5.deltaX,
        deltaY = _ref5.deltaY,
        offsetX = _ref5.offsetX,
        offsetY = _ref5.offsetY,
        clientX = _ref5.clientX,
        clientY = _ref5.clientY,
        first = _ref5.first,
        last = _ref5.last;
      if (last !== undefined && last) {
        if (this.tools.requirement.x > 160 && this.tools.requirement.x < 1500 && this.tools.requirement.y > 315 + this.value_steps.length * 175 && this.tools.requirement.y < 465 + this.value_steps.length * 175) {
          this.resetRequirementForm();
          this.addRequirementDialog = true;
        }
        this.resetTools();
        this.resetDropHover();
        return;
      }
      if (deltaX === undefined || deltaY === undefined) {
        return;
      }
      this.tools.requirement.x += deltaX;
      this.tools.requirement.y += deltaY;
      if (this.tools.requirement.x > 160 && this.tools.requirement.x < 1500 && this.tools.requirement.y > 315 + this.value_steps.length * 175 && this.tools.requirement.y < 465 + this.value_steps.length * 175) {
        this.drop_hover_details.x = 160;
        this.drop_hover_details.y = 375 + this.value_steps.length * 175;
        this.drop_hover_details.width = 1500;
        this.drop_hover_details.height = 150;
        this.drop_hover_details.hover = true;
      }
    },
    retrieveProducts: function retrieveProducts(page_number) {
      var _this3 = this;
      this.loading = true;
      this.products = [];
      this.$apollo.query({
        // Query
        query: window._gql(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["query Products($page: Page)\n        {\n        Products(page: $page)\n        {\n          pages\n          total\n          select{\n            id\n            title\n            description\n          }\n        }\n      }"]))),
        fetchPolicy: 'network-only',
        variables: {
          'page': {
            'limit': 100,
            'start': page_number
          }
        }
      }).then(function (data) {
        _this3.products = data.data.Products.select;
        _this3.loading = false;
      }).catch(function (error) {
        // Error
        _this3.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this3.loading = false;
      });
    },
    retrieveServices: function retrieveServices(page_number) {
      var _this4 = this;
      this.loading = true;
      this.services = [];
      this.$apollo.query({
        // Query
        query: window._gql(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["query Services($page: Page)\n        {\n        Services(page: $page)\n        {\n          pages\n          total\n          select{\n            id\n            title\n            description\n          }\n        }\n      }"]))),
        fetchPolicy: 'network-only',
        variables: {
          'page': {
            'limit': 100,
            'start': page_number
          }
        }
      }).then(function (data) {
        _this4.services = data.data.Services.select;
        _this4.loading = false;
      }).catch(function (error) {
        // Error
        _this4.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this4.loading = false;
      });
    },
    changeCompetitor: function changeCompetitor(competitor, competitor_id) {
      this.selectedCompetitor = competitor;
      this.selectedCompetitorId = competitor_id;
    },
    selectCompetitor: function selectCompetitor(competitor_id) {
      for (var i = 0; i < this.competitors.length; i++) {
        if (this.competitors[i].id === competitor_id) {
          this.selectedCompetitor = this.competitors[i];
        }
      }
    },
    retrieveCompetitors: function retrieveCompetitors(page_number) {
      var _this5 = this;
      this.loading = true;
      this.competitors = [];
      this.$apollo.query({
        // Query
        query: window._gql(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)([" query Competitors($page: Page)\n        {\n        Competitors(page: $page)\n        {\n          pages\n          total\n          select{\n            id\n            title\n            risk\n            linkedFeatures(optional: true) {\n              competitorFeature(optional: true) {\n                id\n                title\n                description\n                priority\n                risk\n              }\n            }\n          }\n        }\n      }"]))),
        fetchPolicy: 'no-cache',
        variables: {
          'page': {
            'limit': 20,
            'start': page_number
          }
        }
      }).then(function (data) {
        _this5.competitors = data.data.Competitors.select;
        if (_this5.competitors.length > 0) {
          _this5.selectedCompetitor = _this5.competitors[0];
        }
        _this5.loading = false;
      }).catch(function (error) {
        // Error
        _this5.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this5.loading = false;
      });
    },
    computeOfferingX: function computeOfferingX(valuestep_index, valuestream_index, product_index) {
      var length = 300;
      var x = valuestream_index * (length + 30) + 160 + product_index * 50 + 90;
      return x;
    },
    computeOfferingY: function computeOfferingY(valuestep_index, valuestream_index, product_index) {
      // var length = 300
      var y = 200;
      if (product_index % 4 === 0) {
        y += product_index * 20;
      } else if (product_index % 4 === 1) {
        y += (product_index - 1) * 20;
      } else if (product_index % 4 === 2) {
        y += (product_index - 2) * 20;
      } else if (product_index % 4 === 3) {
        y += (product_index - 3) * 20;
      }
      return y + valuestep_index * 180 + 25;
    },
    computeServiceX: function computeServiceX(valuestep_index, valuestream_index, product_index) {
      var length = 300;
      var x = valuestream_index * (length + 30) + 160 + product_index * 50 + 70;
      return x;
    },
    computeServiceY: function computeServiceY(valuestep_index, valuestream_index, service_index) {
      // var length = 300
      var y = 275;
      if (service_index % 4 === 0) {
        y += service_index * 40;
      } else if (service_index % 4 === 1) {
        y += (service_index - 1) * 40;
      } else if (service_index % 4 === 2) {
        y += (service_index - 2) * 40;
      } else if (service_index % 4 === 3) {
        y += (service_index - 3) * 40;
      }
      return y + valuestep_index * 180;
    },
    generateValueStreamToolPath: function generateValueStreamToolPath() {
      var length = 60;
      var x = this.tools.valuestream.x - 10;
      var y = this.tools.valuestream.y;
      var point2_x = x + length;
      var point3_x = x + length + 10;
      var point6_x = x + 10;
      var point3_y = y + 23;
      var point4_y = y + 46;
      return x + ',' + y + ' ' + point2_x + ',' + y + ' ' + point3_x + ',' + point3_y + ' ' + point2_x + ',' + point4_y + ' ' + x + ',' + point4_y + ' ' + point6_x + ',' + point3_y;
    },
    generatePath: function generatePath(stepindex, index) {
      var length = 300;
      var x = index * (length + 30) + 190;
      var y = 230 + stepindex * 175;
      var point2_x = x + length;
      var point3_x = x + length + 30;
      var point6_x = x + 30;
      var point3_y = y + 55;
      var point4_y = y + 110;
      return x + ',' + y + ' ' + point2_x + ',' + y + ' ' + point3_x + ',' + point3_y + ' ' + point2_x + ',' + point4_y + ' ' + x + ',' + point4_y + ' ' + point6_x + ',' + point3_y;
    },
    generateUniqueID: function generateUniqueID(index) {
      return 'value_stream_' + index;
    },
    canvasClick: function canvasClick() {},
    resetDropHover: function resetDropHover() {
      window._jquery('#hover_highlight > rect').attr('x', -100);
      window._jquery('#hover_highlight > rect').attr('y', -100);
      this.drop_hover_details = {
        x: 0,
        y: 0,
        width: 60,
        height: 70,
        hover: false
      };
    },
    resetTools: function resetTools() {
      this.tools = {
        valuestep: {
          x: 30,
          y: 50
        },
        valuestream: {
          x: 50,
          y: 190
        },
        product: {
          x: 50,
          y: 330
        },
        service: {
          x: 50,
          y: 490
        },
        requirement: {
          x: 50,
          y: 630
        }
      };
    },
    resetValueStreamForm: function resetValueStreamForm() {
      this.value_stream_form = {
        title: '',
        description: ''
      };
    },
    resetProductForm: function resetProductForm() {
      this.product_form = {
        ids: []
      };
    },
    resetServiceForm: function resetServiceForm() {
      this.service_form = {
        ids: []
      };
    },
    resetRequirementForm: function resetRequirementForm() {
      this.requirement_form = {
        id: '',
        title: ''
      };
    }
  }
};