import { render, staticRenderFns } from "./dummy1.vue?vue&type=template&id=37f0e130&scoped=true"
import script from "./dummy1.vue?vue&type=script&lang=js"
export * from "./dummy1.vue?vue&type=script&lang=js"
import style0 from "./dummy1.vue?vue&type=style&index=0&id=37f0e130&rel=stylesheet%2Fscss&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37f0e130",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\Sastha\\Documents\\GitHub\\prodiggiapp-frontend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('37f0e130')) {
      api.createRecord('37f0e130', component.options)
    } else {
      api.reload('37f0e130', component.options)
    }
    module.hot.accept("./dummy1.vue?vue&type=template&id=37f0e130&scoped=true", function () {
      api.rerender('37f0e130', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/processes/deliver_build/dummy1.vue"
export default component.exports