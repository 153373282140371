"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("g", {
    attrs: {
      refs: "CTA"
    }
  }, [_c("defs", [_c("pattern", {
    attrs: {
      id: "pattern",
      x: "0",
      y: "0",
      width: "20",
      height: "20",
      "pattern-units": "userSpaceOnUse",
      "pattern-transform": "translate(10, 10) rotate(0) skewX(0)"
    }
  }, [_c("svg", {
    attrs: {
      width: "10",
      height: "10",
      viewBox: "0 0 100 100"
    }
  }, [_c("g", {
    attrs: {
      fill: "#fefefe",
      opacity: "1"
    }
  }, [_c("path", {
    attrs: {
      d: "M60 0H40V40H0V60H40V100H60V60H100V40H60V0Z"
    }
  })])])])]), _vm._v(" "), _c("rect", {
    attrs: {
      x: _vm.x,
      y: _vm.y,
      rx: 5,
      width: _vm.width,
      height: _vm.height,
      fill: "rgba(200,200,200,0.15)",
      "stroke-width": "1.0",
      stroke: "#ededed"
    }
  }), _vm._v(" "), _c("rect", {
    attrs: {
      x: _vm.x,
      y: _vm.y,
      rx: 5,
      width: _vm.width,
      height: _vm.height,
      fill: "white",
      "stroke-width": "1.0",
      stroke: "lightgrey"
    }
  }), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "black",
      "font-weight": "600",
      "font-variant-caps": "small-caps"
    },
    attrs: {
      x: _vm.computeTextX,
      y: _vm.computeTextY - 60,
      "text-anchor": "start",
      "font-size": "1.2em"
    }
  }, [_vm._v("\n    " + _vm._s(_vm.title) + "\n  ")]), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "grey",
      "font-weight": "600",
      "font-variant-caps": "small-caps"
    },
    attrs: {
      x: _vm.computeTextX,
      y: _vm.computeTextY - 40,
      "text-anchor": "start",
      "font-size": ".95em"
    }
  }, [_vm._v("\n    " + _vm._s(_vm.description) + "\n  ")]), _vm._v(" "), _vm._l(_vm.buttonText, function (button, index) {
    return _c("foreignObject", {
      key: index,
      attrs: {
        x: _vm.computeButtonPosX(index) - 100,
        y: _vm.computeTextY - 30,
        width: 200,
        height: 50,
        fill: "none"
      }
    }, [_c("el-button", {
      staticStyle: {
        width: "200px"
      },
      attrs: {
        type: "primary",
        size: "mini"
      },
      on: {
        click: function click($event) {
          return _vm.responseCTA(button);
        }
      }
    }, [_vm._v(_vm._s(button))])], 1);
  })], 2);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;