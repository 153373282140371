"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/taggedTemplateLiteral.js"));
var _FileUpload = _interopRequireDefault(require("@/components/FileUpload"));
var _axios = _interopRequireDefault(require("axios"));
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8;
var _default2 = exports.default = {
  name: 'OfferingIdea',
  components: {
    FileUpload: _FileUpload.default
  },
  props: {
    idea: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      loading: false,
      vote_loading: false,
      editMode: false,
      products: [],
      product_pagination: {
        pages: 0,
        total: 0
      },
      service_pagination: {
        pages: 0,
        total: 0
      },
      services: [],
      offering_pagination: {
        pages: 0,
        total: 0
      },
      offerings: [],
      statuses: [{
        label: 'Proposed',
        value: 'Proposed'
      }, {
        label: 'Evaluation',
        value: 'Evaluation'
      }, {
        label: 'Approved',
        value: 'Approved'
      }, {
        label: 'Starting Up',
        value: 'Starting Up'
      }, {
        label: 'Launched',
        value: 'Launched'
      }, {
        label: 'Abandoned',
        value: 'Abandoned'
      }]
    };
  },
  computed: {},
  mounted: function mounted() {
    this.retrieveProducts();
    this.retrieveServices();
    this.retrieveOfferings();
  },
  methods: {
    retrieveOfferings: function retrieveOfferings() {
      var _this = this;
      var query = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
      this.offerings = [];
      this.$apollo.query({
        // Query
        query: window._gql(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["query Offerings($page: Page,$offeringcriteriaexpression: OfferingsCriteriaExpression!)\n          {\n          Offerings(page: $page, where: $offeringcriteriaexpression)\n          {\n            pages\n            total\n            select{\n              id\n              title\n            }\n          }\n        }"]))),
        fetchPolicy: 'no-cache',
        variables: {
          'page': {
            'limit': 20,
            'start': 1
          },
          'offeringcriteriaexpression': {
            'title': {
              'LIKE': query
            }
          }
        }
      }).then(function (data) {
        _this.offerings = data.data.Offerings.select;
        _this.offering_pagination.pages = data.data.Offerings.pages;
        _this.offering_pagination.total = data.data.Offerings.total;
      }).catch(function (error) {
        // Error
        _this.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
      });
    },
    retrieveProducts: function retrieveProducts(query) {
      var _this2 = this;
      this.products = [];
      this.$apollo.query({
        // Query
        query: window._gql(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["query Products($page: Page, $productscriteriaexpression: ProductsCriteriaExpression!)\n          {\n          Products(page: $page, where: $productscriteriaexpression)\n          {\n            pages\n            total\n            select{\n              id\n              title\n            }\n          }\n        }"]))),
        fetchPolicy: 'no-cache',
        variables: {
          'page': {
            'limit': 20,
            'start': 1
          },
          'productscriteriaexpression': {
            'title': {
              'LIKE': query
            }
          }
        }
      }).then(function (data) {
        _this2.products = data.data.Products.select;
        _this2.product_pagination.pages = data.data.Products.pages;
        _this2.product_pagination.total = data.data.Products.total;
      }).catch(function (error) {
        // Error
        _this2.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
      });
    },
    retrieveServices: function retrieveServices(query) {
      var _this3 = this;
      this.services = [];
      this.$apollo.query({
        // Query
        query: window._gql(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["query Services($page: Page, $servicescriteriaexpression: ServicesCriteriaExpression!)\n          {\n          Services(page: $page, where: $servicescriteriaexpression)\n          {\n            pages\n            total\n            select{\n              id\n              title\n            }\n          }\n        }"]))),
        fetchPolicy: 'no-cache',
        variables: {
          'page': {
            'limit': 20,
            'start': 1
          },
          'servicescriteriaexpression': {
            'title': {
              'LIKE': query
            }
          }
        }
      }).then(function (data) {
        _this3.services = data.data.Services.select;
        _this3.service_pagination.pages = data.data.Services.pages;
        _this3.service_pagination.total = data.data.Services.total;
      }).catch(function (error) {
        // Error
        _this3.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
      });
    },
    retrieveVotes: function retrieveVotes() {
      var _this4 = this;
      this.vote_loading = true;
      _axios.default.get(process.env.VUE_APP_REST_API_ENDPOINT + 'social/reaction?entityId=' + this.idea.id + '&extended=LEVEL0', {
        crossdomain: true,
        headers: {
          'Content-Type': 'application/json',
          'x-party-id': this.$store.getters.partyId,
          'x-user-id': this.$store.getters.id,
          'x-entity-id': this.idea.id,
          'x-entity-name': this.idea.title
        }
      }).then(function (data) {
        // handle success
        _this4.vote_loading = false;
        _this4.idea.score = data.data.data.voteup;
        _this4.updateOfferingScore();
        // this.idea.votesDown = data.data.data.votedown
        // this.idea.score = data.data.data.totalReactions
      }).catch(function (data) {
        // handle error
        _this4.vote_loading = false;
      }).finally(function () {
        // always executed
        _this4.vote_loading = false;
      });
    },
    voteUp: function voteUp() {
      var _this5 = this;
      this.vote_loading = true;
      var headers = {
        'Content-Type': 'application/json',
        'x-party-id': this.$store.getters.partyId,
        'x-user-id': this.$store.getters.id,
        'x-entity-id': this.idea.id,
        'x-entity-name': this.idea.title
      };
      var data = {
        header: {
          userId: this.$store.getters.id,
          partyId: this.$store.getters.partyId
        },
        data: {
          reactionType: 'VOTEUP',
          entityId: this.idea.id,
          entityName: 'OfferingIdeation'
        }
      };
      _axios.default.post(process.env.VUE_APP_REST_API_ENDPOINT + 'social/reaction', data, {
        headers: headers
      }).then(function (response) {
        _this5.vote_loading = false;
        _this5.$notify({
          title: 'Success',
          message: 'Voted',
          type: 'success',
          duration: 2000
        });
        _this5.retrieveVotes();
      }).catch(function (error) {
        if (error.response.status === 400) {
          _this5.vote_loading = false;
          _this5.$notify({
            title: 'Error',
            message: 'Already voted',
            type: 'error',
            duration: 2000
          });
        }
      });
    },
    voteDown: function voteDown() {
      var _this6 = this;
      this.vote_loading = true;
      var headers = {
        'Content-Type': 'application/json',
        'x-party-id': this.$store.getters.partyId,
        'x-user-id': this.$store.getters.id,
        'x-entity-id': this.idea.id,
        'x-entity-name': this.idea.title
      };
      var data = {
        header: {
          userId: this.$store.getters.id,
          partyId: this.$store.getters.partyId
        },
        data: {
          reactionType: 'VOTEDOWN',
          entityId: this.idea.id,
          entityName: 'OfferingIdeation'
        }
      };
      _axios.default.post(process.env.VUE_APP_REST_API_ENDPOINT + 'social/reaction', data, {
        headers: headers
      }).then(function (response) {
        _this6.vote_loading = false;
        _this6.$notify({
          title: 'Success',
          message: 'Voted',
          type: 'success',
          duration: 2000
        });
        _this6.retrieveVotes();
      }).catch(function (error) {
        _this6.vote_loading = false;
        if (error.response.status === 400) {
          _this6.$notify({
            title: 'Error',
            message: 'Already voted',
            type: 'error',
            duration: 2000
          });
        }
      });
    },
    addFile: function addFile(file) {
      var _this7 = this;
      var fileobject = this.idea.fileobject;
      fileobject.push(file);
      this.loading = true;
      this.$apollo.mutate({
        // Query
        mutation: window._gql(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2.default)(["mutation updateBusinessIdea($busIdea: UpdateBusinessIdeaInput!)\n          {\n            updateBusinessIdea(input: $busIdea) {\n              id\n              title\n              description\n              problemstatement\n              solution\n              valueproposition\n              additionalattributes\n              fileobject\n              status\n              score\n              customer_type\n              scope\n              scopename\n              type\n              linkedCoreParties(optional: true){\n                coreParty(optional: true){\n                  id\n                  businessname\n                }\n              }\n            }\n          }"]))),
        // Parameters
        variables: {
          'busIdea': {
            'id': this.idea.id,
            'fileobject': fileobject
          }
        }
      }).then(function (data) {
        _this7.$notify({
          title: 'Success',
          message: 'Created Successfully',
          type: 'success',
          duration: 2000
        });
        _this7.idea = data.data.updateBusinessIdea;
        _this7.idea.fileobject = fileobject;
        // Avoid page reload if possible
        // this.$emit('update', idea)
        _this7.loading = false;
      }).catch(function (error) {
        // Error
        _this7.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this7.loading = false;
      });
    },
    deleteFile: function deleteFile(index) {
      var _this8 = this;
      this.idea.fileobject.splice(index, 1);
      this.loading = true;
      this.$apollo.mutate({
        // Query
        mutation: window._gql(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2.default)(["mutation updateBusinessIdea($busIdea: UpdateBusinessIdeaInput!)\n          {\n            updateBusinessIdea(input: $busIdea) {\n              id\n              title\n              description\n              problemstatement\n              solution\n              valueproposition\n              additionalattributes\n              fileobject\n              status\n              score\n              customer_type\n              scope\n              scopename\n              type\n              linkedCoreParties(optional: true){\n                coreParty(optional: true){\n                  id\n                  businessname\n                }\n              }\n            }\n          }"]))),
        // Parameters
        variables: {
          'busIdea': {
            'id': this.idea.id,
            'fileobject': this.idea.fileobject
          }
        }
      }).then(function (data) {
        _this8.$notify({
          title: 'Success',
          message: 'Created Successfully',
          type: 'success',
          duration: 2000
        });
        _this8.idea = data.data.updateBusinessIdea;
        // this.idea.fileobject = fileobject
        // Avoid page reload if possible
        // this.$emit('update', idea)
        _this8.loading = false;
      }).catch(function (error) {
        // Error
        _this8.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this8.loading = false;
      });
    },
    updateOfferingScore: function updateOfferingScore() {
      var _this9 = this;
      this.loading = true;
      this.$apollo.mutate({
        // Query
        mutation: window._gql(_templateObject6 || (_templateObject6 = (0, _taggedTemplateLiteral2.default)(["mutation updateBusinessIdea($busIdea: UpdateBusinessIdeaInput!)\n          {\n            updateBusinessIdea(input: $busIdea) {\n              id\n              title\n              description\n              problemstatement\n              solution\n              valueproposition\n              additionalattributes\n              fileobject\n              status\n              score\n              customer_type\n              scope\n              scopename\n              type\n              linkedCoreParties(optional: true){\n                coreParty(optional: true){\n                  id\n                  businessname\n                }\n              }\n            }\n          }"]))),
        // Parameters
        variables: {
          'busIdea': {
            'id': this.idea.id,
            'score': this.idea.score
          }
        }
      }).then(function (data) {
        /* this.$notify({
          title: 'Success',
          message: 'Created Successfully',
          type: 'success',
          duration: 2000
        })*/
        _this9.idea = data.data.updateBusinessIdea;
        // this.$emit('update', idea)
        _this9.loading = false;
      }).catch(function (error) {
        // Error
        _this9.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this9.loading = false;
      });
    },
    updateOffering: function updateOffering() {
      var _this10 = this;
      this.loading = true;
      this.$apollo.mutate({
        // Query
        mutation: window._gql(_templateObject7 || (_templateObject7 = (0, _taggedTemplateLiteral2.default)(["mutation updateBusinessIdea($busIdea: UpdateBusinessIdeaInput!)\n          {\n            updateBusinessIdea(input: $busIdea) {\n              id\n              title\n              description\n              problemstatement\n              solution\n              valueproposition\n              additionalattributes\n              fileobject\n              status\n              score\n              customer_type\n              scope\n              scopename\n              type\n              linkedCoreParties(optional: true){\n                coreParty(optional: true){\n                  id\n                  businessname\n                }\n              }\n            }\n          }"]))),
        // Parameters
        variables: {
          'busIdea': {
            'id': this.idea.id,
            'title': this.idea.title,
            'description': this.idea.description,
            'problemstatement': this.idea.problemstatement,
            'solution': this.idea.solution,
            'valueproposition': this.idea.valueproposition,
            'status': this.idea.status,
            'scope': this.idea.scope,
            'scopename': this.idea.scopename
          }
        }
      }).then(function (data) {
        _this10.$notify({
          title: 'Success',
          message: 'Created Successfully',
          type: 'success',
          duration: 2000
        });
        _this10.idea = data.data.updateBusinessIdea;
        _this10.$emit('update', data.data.updateBusinessIdea);
        _this10.editMode = false;
        _this10.loading = false;
      }).catch(function (error) {
        // Error
        _this10.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this10.editMode = false;
        _this10.loading = false;
      });
    },
    deleteIdea: function deleteIdea() {
      var _this11 = this;
      this.$confirm('This will permanently delete the Idea. Continue?', 'Warning', {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(function () {
        _this11.loading = true;
        _this11.$apollo.mutate({
          // Query
          mutation: window._gql(_templateObject8 || (_templateObject8 = (0, _taggedTemplateLiteral2.default)(["mutation deleteBusinessIdea($busIdea: DeleteBusinessIdeaInput!)\n          {\n            deleteBusinessIdea(input: $busIdea) {\n            id\n            }\n          }"]))),
          // Parameters
          variables: {
            'busIdea': {
              'id': _this11.idea.id
            }
          }
        }).then(function (data) {
          _this11.$notify({
            title: 'Success',
            message: 'Deleted Successfully',
            type: 'success',
            duration: 2000
          });
          _this11.$emit('delete', _this11.idea.id);
          _this11.loading = false;
        }).catch(function (error) {
          // Error
          _this11.$notify({
            title: 'Error',
            message: error,
            type: 'error',
            duration: 2000
          });
          _this11.loading = false;
        });
      }).catch(function () {
        _this11.$message({
          type: 'info',
          message: 'Delete canceled'
        });
      });
    }
  }
};