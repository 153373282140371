import { render, staticRenderFns } from "./compare-features.vue?vue&type=template&id=62732101&scoped=true"
import script from "./compare-features.vue?vue&type=script&lang=js"
export * from "./compare-features.vue?vue&type=script&lang=js"
import style0 from "./compare-features.vue?vue&type=style&index=0&id=62732101&rel=stylesheet%2Fscss&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62732101",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\Sastha\\Documents\\GitHub\\prodiggiapp-frontend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('62732101')) {
      api.createRecord('62732101', component.options)
    } else {
      api.reload('62732101', component.options)
    }
    module.hot.accept("./compare-features.vue?vue&type=template&id=62732101&scoped=true", function () {
      api.rerender('62732101', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/processes/plan_product/components/monitor_competitors/compare-features.vue"
export default component.exports