"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("g", {
    attrs: {
      refs: "text-component"
    }
  }, [_vm.label.length < _vm.length ? _c("text", {
    style: _vm.textStyle,
    attrs: {
      x: _vm.x,
      y: _vm.y,
      "text-anchor": _vm.textAnchor,
      "font-size": _vm.fontSize
    }
  }, [_vm._v("\n    " + _vm._s(_vm.truncateString(_vm.label)) + "\n  ")]) : _c("text", {
    style: _vm.textStyle,
    attrs: {
      x: _vm.x,
      y: _vm.y,
      "text-anchor": _vm.textAnchor,
      "font-size": _vm.fontSize
    }
  }, [_c("tspan", {
    attrs: {
      x: _vm.x,
      dy: "0"
    }
  }, [_vm._v(_vm._s(_vm.truncateString(_vm.label.slice(0, 30))))]), _vm._v(" "), _c("tspan", {
    attrs: {
      x: _vm.x,
      dy: "10"
    }
  }, [_vm._v(_vm._s(_vm.truncateString(_vm.label.slice(30, 100))))])])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;