"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default2 = exports.default = {
  name: 'ProductServiceFilter',
  components: {},
  props: {
    entities: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      loading: false
    };
  },
  computed: {},
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    closeFilterDialog: function closeFilterDialog() {
      this.$emit('close');
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.$emit('filtered-list', val);
    },
    truncateString: function truncateString(string, length) {
      return this._.truncate(string, {
        'length': length,
        'separator': '...'
      });
    }
  }
};