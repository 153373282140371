"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _taggedTemplateLiteral2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/taggedTemplateLiteral.js"));
require("core-js/modules/es6.array.sort");
var _templateObject, _templateObject2;
var _default = exports.default = {
  name: 'JourneyIdeaList',
  components: {},
  props: {},
  data: function data() {
    return {
      loading: false,
      filterDialog: false,
      total_count: 0,
      selectedIdea: {},
      journeyIdea: [],
      customers: [],
      statuses: [{
        label: 'All Statuses',
        value: 'All Statuses'
      }, {
        label: 'Proposed',
        value: 'Proposed'
      }, {
        label: 'Evaluation',
        value: 'Evaluation'
      }, {
        label: 'Approved',
        value: 'Approved'
      }, {
        label: 'Starting Up',
        value: 'Starting Up'
      }, {
        label: 'Launched',
        value: 'Launched'
      }, {
        label: 'Abandoned',
        value: 'Abandoned'
      }],
      search_ideas: {
        q: '',
        filters: {
          title: '',
          title_operator: '',
          description: '',
          description_operator: '',
          status: [],
          customer_types: [],
          offerings: [],
          score: ''
        },
        sort: {
          title: 'a-z'
        }
      }
    };
  },
  computed: {},
  mounted: function mounted() {
    this.retrieveIdeas(1);
  },
  methods: {
    toggleSort: function toggleSort() {
      if (this.search_ideas.sort.title === 'a-z') {
        this.search_ideas.sort.title = 'z-a';
      } else {
        this.search_ideas.sort.title = 'a-z';
      }
      this.retrieveIdeas(1);
    },
    filterIdeaList: function filterIdeaList() {
      this.selectedIdea = {};
      this.retrieveIdeas(1);
      this.filterDialog = false;
    },
    loadPage: function loadPage(page_number) {
      this.retrieveIdeas(page_number);
    },
    truncateDescription: function truncateDescription(description) {
      return this._.truncate(description, {
        'length': 150,
        'separator': ' '
      });
    },
    openNewJourneyDialog: function openNewJourneyDialog() {
      this.$emit('create');
    },
    showFilterDialog: function showFilterDialog() {
      this.resetSearchFilter();
      this.retrieveCustomerTypes(1);
      this.filterDialog = true;
    },
    closeFilterDialog: function closeFilterDialog() {
      this.resetSearchFilter();
      this.filterDialog = false;
    },
    resetFilterSort: function resetFilterSort() {
      this.resetSearchFilter();
      this.resetSearchSort();
      this.retrieveIdeas(1);
    },
    resetSearchFilter: function resetSearchFilter() {
      this.search_ideas.filters = {
        title: '',
        title_operator: '',
        description: '',
        description_operator: '',
        status: [],
        customer_types: [],
        offerings: [],
        score: ''
      };
    },
    resetSearchSort: function resetSearchSort() {
      this.search_ideas.sort = {
        title: 'a-z'
      };
    },
    retrieveIdeas: function retrieveIdeas(page_number) {
      var _this = this;
      this.loading = true;
      this.journeyIdea = [];
      this.$apollo.query({
        // Query
        query: window._gql(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)([" query BusinessIdeas($page: Page, $where: BusinessIdeasCriteriaExpression)\n        {\n        BusinessIdeas(page: $page, where: $where)\n        {\n          pages\n          total\n          select{\n            id\n            title(orderBy: ", ")\n            description\n            problemstatement\n            solution\n            valueproposition\n            additionalattributes\n            fileobject\n            status\n            score\n            customer_type\n            scope\n            scopename\n            type\n            linkedCoreParties(optional: true){\n              coreParty(optional: true){\n                id\n                businessname\n              }\n            }\n            linkedOfferings(optional: true){\n              offering(optional: true){\n                id\n                title\n                description\n              }\n            }\n            linkedJourneyMaps(optional: true){\n              journeyMap(optional: true){\n                id\n                title\n                description\n              }\n            }\n            linkedCustomerTypes(optional: true){\n              customerType(optional: true){\n                id\n                title\n                description\n              }\n            }\n          }\n        }\n      }"])), this.computeSortTitleQuery()),
        fetchPolicy: 'network-only',
        variables: {
          'page': {
            'limit': 10,
            'start': page_number
          },
          'where': this.computeSearchQuery()
        }
      }).then(function (data) {
        _this.journeyIdea = data.data.BusinessIdeas.select;
        _this.total_count = data.data.BusinessIdeas.total;
        _this.loading = false;
      }).catch(function (error) {
        // Error
        _this.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this.loading = false;
      });
    },
    retrieveCustomerTypes: function retrieveCustomerTypes(page_number) {
      var _this2 = this;
      this.loading = true;
      this.customers = [];
      this.$apollo.query({
        // Query
        query: window._gql(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)([" query CustomerTypes($page: Page)\n          {\n          CustomerTypes(page: $page)\n          {\n            pages\n            total\n            select{\n              id\n              title\n              description\n              linkedJourneyMaps(optional: true) {\n                journeyMap(optional: true) {\n                  id\n                  title\n                  description\n                  additionalattributes\n                }\n              }\n            }\n          }\n        }"]))),
        fetchPolicy: 'no-cache',
        variables: {
          'page': {
            'limit': 20,
            'start': page_number
          }
        }
      }).then(function (data) {
        _this2.customers = data.data.CustomerTypes.select;
        _this2.loading = false;
      }).catch(function (error) {
        // Error
        _this2.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this2.loading = false;
      });
    },
    removeSearchFilterTag: function removeSearchFilterTag(type) {
      var index = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      switch (type) {
        case 'title':
          this.search_ideas.filters.title = '';
          this.search_ideas.filters.title_operator = '';
          break;
        case 'description':
          this.search_ideas.filters.description = '';
          this.search_ideas.filters.description_operator = '';
          break;
        case 'status':
          this.search_ideas.filters.status.splice(index, 1);
          break;
        case 'customer_types':
          this.search_ideas.filters.customer_types.splice(index, 1);
          break;
        case 'offerings':
          this.search_ideas.filters.offerings.splice(index, 1);
          break;
        case 'score':
          this.search_ideas.filters.score = '';
          break;
      }
      this.retrieveIdeas(1);
    },
    removeSearchSortTag: function removeSearchSortTag(type) {
      switch (type) {
        case 'title':
          this.search_ideas.sort.title = 'a-z';
          break;
        case 'status':
          this.search_ideas.sort.status = 'inprogress-achieved';
          break;
        case 'score':
          this.search_ideas.sort.score = 'low-high';
          break;
      }
      this.retrieveIdeas(1);
    },
    computeSortTitleQuery: function computeSortTitleQuery() {
      if (this.search_ideas.sort.title === 'a-z') {
        return 'ASC';
      } else {
        return 'DESC';
      }
    },
    computeSearchQuery: function computeSearchQuery() {
      var query = {};
      query.type = {
        'EQ': 'journey'
      };
      if (this.search_ideas.q !== '') {
        query.title = {
          'LIKE_': this.search_ideas.q
        };
      }
      if (this.search_ideas.filters.title !== '') {
        switch (this.search_ideas.filters.title_operator) {
          case 'EXACT':
            query.title = {
              'EQ_': this.search_ideas.filters.title
            };
            break;
          case 'LIKE':
            query.title = {
              'LIKE_': this.search_ideas.filters.title
            };
            break;
          case 'EMPTY':
            query.title = {
              'IS_NULL': this.search_ideas.filters.title
            };
            break;
          case 'NOT EMPTY':
            query.title = {
              'NOT_NULL': this.search_ideas.filters.title
            };
            break;
          case 'STARTS':
            query.title = {
              'STARTS_': this.search_ideas.filters.title
            };
            break;
          case 'ENDS':
            query.title = {
              'ENDS_': this.search_ideas.filters.title
            };
            break;
          default:
            query.title = {
              'LIKE_': this.search_ideas.filters.title
            };
            break;
        }
      }
      if (this.search_ideas.filters.description !== '') {
        switch (this.search_ideas.filters.description_operator) {
          case 'EXACT':
            query.description = {
              'EQ_': this.search_ideas.filters.description
            };
            break;
          case 'LIKE':
            query.description = {
              'LIKE_': this.search_ideas.filters.description
            };
            break;
          case 'EMPTY':
            query.description = {
              'IS_NULL': this.search_ideas.filters.description
            };
            break;
          case 'NOT EMPTY':
            query.description = {
              'NOT_NULL': this.search_ideas.filters.description
            };
            break;
          case 'STARTS':
            query.description = {
              'STARTS_': this.search_ideas.filters.description
            };
            break;
          case 'ENDS':
            query.description = {
              'ENDS_': this.search_ideas.filters.description
            };
            break;
          default:
            query.description = {
              'LIKE_': this.search_ideas.filters.description
            };
            break;
        }
      }
      if (this.search_ideas.filters.status.length > 0 && !this.search_ideas.filters.status.includes('All Statuses')) {
        query.status = {
          'IN': this.search_ideas.filters.status
        };
      }
      if (this.search_ideas.filters.offerings.length > 0) {
        query.offerings = {
          'IN': this.search_ideas.filters.offerings
        };
      }
      if (this.search_ideas.filters.customer_types.length > 0) {
        query.customer_type = {
          'IN': this.search_ideas.filters.customer_types
        };
      }
      if (this.search_ideas.filters.score !== '') {
        query.score = {
          'EQ': this.search_ideas.filters.score
        };
      }
      return query;
    },
    selectJourney: function selectJourney(idea) {
      this.selectedIdea = idea;
      this.$emit('select', idea);
    }
  }
};