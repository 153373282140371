"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.sort");
var state = {
  goals: {
    q: '',
    filters: {
      title: '',
      title_operator: '',
      description: '',
      description_operator: '',
      status: [],
      priority: []
    },
    sort: {
      title: 'a-z'
    },
    ids: []
  }
};
var mutations = {
  UPDATE_BUSINESS_GOALS_SEARCH: function UPDATE_BUSINESS_GOALS_SEARCH(state, goal) {
    state.goals.q = goal.q;
    state.goals.filters.title = goal.filters.title;
    state.goals.filters.title_operator = goal.filters.title_operator;
    state.goals.filters.description = goal.filters.description;
    state.goals.filters.description_operator = goal.filters.description_operator;
    state.goals.filters.status = goal.filters.status;
    state.goals.filters.priority = goal.filters.priority;
  },
  UPDATE_BUSINESS_GOALS_SORT: function UPDATE_BUSINESS_GOALS_SORT(state, goal) {
    state.goals.sort.title = goal.sort.title;
  },
  UPDATE_BUSINESS_GOALS_IDS: function UPDATE_BUSINESS_GOALS_IDS(state, goal_ids) {
    state.goals.ids = goal_ids;
  }
};
var actions = {
  updateBusinessGoalsIds: function updateBusinessGoalsIds(_ref, goal_ids) {
    var commit = _ref.commit;
    commit('UPDATE_BUSINESS_GOALS_IDS', goal_ids);
  },
  updateBusinessGoalsSearchQuery: function updateBusinessGoalsSearchQuery(_ref2, goal) {
    var commit = _ref2.commit;
    commit('UPDATE_BUSINESS_GOALS_SEARCH', goal);
  },
  updateBusinessGoalsSortQuery: function updateBusinessGoalsSortQuery(_ref3, goal_filter) {
    var commit = _ref3.commit;
    commit('UPDATE_BUSINESS_GOALS_SORT', goal_filter);
  },
  resetBusinessGoalsSearchQuery: function resetBusinessGoalsSearchQuery(_ref4) {
    var commit = _ref4.commit;
    var goal_filter = {
      q: '',
      filters: {
        title: '',
        title_operator: '',
        description: '',
        description_operator: '',
        status: [],
        priority: []
      }
    };
    commit('UPDATE_BUSINESS_GOALS_SEARCH', goal_filter);
  },
  resetBusinessGoalsSortQuery: function resetBusinessGoalsSortQuery(_ref5) {
    var commit = _ref5.commit;
    var goal_filter = {
      sort: {
        title: 'a-z'
      }
    };
    commit('UPDATE_BUSINESS_GOALS_SORT', goal_filter);
  },
  resetBusinessGoalsIds: function resetBusinessGoalsIds(_ref6) {
    var commit = _ref6.commit;
    var goal_ids = [];
    commit('UPDATE_BUSINESS_GOALS_IDS', goal_ids);
  }
};
var _default = exports.default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};