"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _default2 = exports.default = {
  name: 'Initiative',
  components: {},
  props: {
    x: {
      type: Number,
      required: true,
      default: 0
    },
    y: {
      type: Number,
      required: true,
      default: 0
    },
    width: {
      type: Number,
      required: true,
      default: 0
    },
    height: {
      type: Number,
      required: true,
      default: 0
    },
    entityIndex: {
      type: Number,
      required: true,
      default: 0
    },
    objectiveIndex: {
      type: Number,
      required: true,
      default: 0
    },
    index: {
      type: Number,
      required: true,
      default: 0
    },
    isMinimized: {
      type: Boolean,
      default: false
    },
    initiative: {
      type: Object,
      default: function _default() {
        return {
          title: '',
          description: ''
        };
      }
    }
  },
  data: function data() {
    return {
      loading: false,
      isHover: false
    };
  },
  computed: {},
  watch: {},
  mounted: function mounted() {},
  created: function created() {},
  methods: {
    onInitiativeDragged: function onInitiativeDragged(_ref) {
      var el = _ref.el,
        deltaX = _ref.deltaX,
        deltaY = _ref.deltaY,
        offsetX = _ref.offsetX,
        offsetY = _ref.offsetY,
        clientX = _ref.clientX,
        clientY = _ref.clientY,
        first = _ref.first,
        last = _ref.last;
      if (last !== undefined && last) {
        this.$emit('drag-stop', this.entityIndex, this.objectiveIndex, this.index, parseInt(deltaX));
        return;
      }
      if (deltaX === undefined || deltaY === undefined) {
        this.$emit('drag-start', this.entityIndex, this.objectiveIndex, this.index, parseInt(deltaX));
        return;
      }
      // this.x += parseInt(deltaX)
      this.$emit('drag', this.entityIndex, this.objectiveIndex, this.index, parseInt(deltaX));
    },
    calcInitClass: function calcInitClass(status) {
      if (status === 'Proposed') {
        return 'subInit_Proposed';
      } else if (status === 'Accepted') {
        return 'subInit_Accepted';
      } else if (status === 'In progress') {
        return 'subInit_InProgress';
      } else if (status === 'On hold') {
        return 'subInit_OnHold';
      } else if (status === 'Overdue') {
        return 'subInit_Overdue';
      } else if (status === 'Achieved') {
        return 'subInit_Achieved';
      } else {
        return 'subInit_proposed';
      }
    },
    truncateString: function truncateString(string, length) {
      return this._.truncate(string, {
        'length': length
      });
    }
  }
};