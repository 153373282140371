"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "text-center"
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 22
    }
  }, _vm._l(_vm.search_entities.filters.status, function (status, index) {
    return _c("el-tag", {
      key: index,
      attrs: {
        size: "mini",
        closable: ""
      },
      on: {
        close: function close($event) {
          return _vm.removeSearchFilterTag("status", index);
        }
      }
    }, [_c("svg-icon", {
      staticStyle: {
        "font-size": "1.2em"
      },
      attrs: {
        "icon-class": "filter-left"
      }
    }), _vm._v("Status:\n          "), status === "Proposed" ? _c("svg-icon", {
      staticStyle: {
        color: "grey",
        "background-color": "white",
        "border-radius": "50%",
        "font-size": "1.0em"
      },
      attrs: {
        "icon-class": "hand-holding-solid"
      }
    }) : _vm._e(), _vm._v(" "), status === "Accepted" ? _c("svg-icon", {
      staticStyle: {
        color: "grey",
        "background-color": "cornflowerblue",
        width: "14pt",
        "border-radius": "10pt",
        "font-size": "1.0em"
      },
      attrs: {
        "icon-class": "thumbs-up"
      }
    }) : _vm._e(), _vm._v(" "), status === "In progress" ? _c("svg-icon", {
      staticStyle: {
        color: "white",
        "background-color": "#2c64b5",
        "border-radius": "10pt",
        padding: "1pt",
        "font-size": "1.0em"
      },
      attrs: {
        "icon-class": "delivery"
      }
    }) : _vm._e(), _vm._v(" "), status === "On hold" ? _c("svg-icon", {
      staticStyle: {
        color: "#f09a0d",
        "font-size": "1.0em"
      },
      attrs: {
        "icon-class": "pause-circle-fill-w"
      }
    }) : _vm._e(), _vm._v(" "), status === "Overdue" ? _c("svg-icon", {
      staticStyle: {
        color: "#eb4a49",
        "font-size": "1.0em"
      },
      attrs: {
        "icon-class": "exclamation-circle-fill"
      }
    }) : _vm._e(), _vm._v(" "), status === "Achieved" ? _c("svg-icon", {
      staticStyle: {
        color: "#92b853",
        "font-size": "1.0em"
      },
      attrs: {
        "icon-class": "check-circle-fill"
      }
    }) : _vm._e(), _vm._v("\n          " + _vm._s(status) + "\n        ")], 1);
  }), 1), _vm._v(" "), _c("el-col", {
    staticClass: "fr",
    attrs: {
      span: 2
    }
  }, [_c("el-button", {
    attrs: {
      size: "micro",
      type: "primary",
      icon: "el-icon-more-outline"
    },
    on: {
      click: function click($event) {
        _vm.filterDisplay = !_vm.filterDisplay;
      }
    }
  })], 1)], 1)], 1), _vm._v(" "), _vm.filterDisplay ? _c("div", [_c("el-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      clearable: "",
      placeholder: "Select",
      size: "mini",
      multiple: ""
    },
    model: {
      value: _vm.search_entities.filters.status,
      callback: function callback($$v) {
        _vm.$set(_vm.search_entities.filters, "status", $$v);
      },
      expression: "search_entities.filters.status"
    }
  }, _vm._l(_vm.statuses, function (status, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: status.label,
        value: status.value
      }
    });
  }), 1)], 1) : _vm._e(), _vm._v(" "), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      data: _vm.entities,
      stripe: "",
      size: "mini"
    },
    on: {
      "selection-change": _vm.handleSelectionChange
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "selection",
      width: "55"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "title",
      label: "Title",
      sortable: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("span", [_vm._v(_vm._s(_vm.truncateString(scope.row.title, 90)))])];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "When",
      sortable: "",
      width: "150"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("span", [_vm._v(_vm._s(scope.row.duewhen.date) + " " + _vm._s(scope.row.duewhen.date_detail))])];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Status",
      prop: "status",
      align: "right"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "text-right mt-10"
  }, [_c("el-button-group", [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.filterList();
      }
    }
  }, [_vm._v("Filter")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "secondary",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.closeFilterDialog();
      }
    }
  }, [_vm._v("Close")])], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;