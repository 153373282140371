"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
var _prosemirrorModel = require("prosemirror-model");
var _traverse = require("@atlaskit/adf-utils/traverse");
var _schemaDefault = require("@atlaskit/adf-schema/schema-default");
var _schemaConfluence = require("@atlaskit/adf-schema/schema-confluence");
var _editorJsonTransformer = require("@atlaskit/editor-json-transformer");
var _editorJiraTransformer = require("@atlaskit/editor-jira-transformer");
var _editorConfluenceTransformer = require("@atlaskit/editor-confluence-transformer");
// Reference
// https://codesandbox.io/s/html-to-adf-to-html-y07eqv?file=/src/App.js:6385-6393
// import { doc, p, emoji } from '@atlaskit/adf-utils/builders';
// import { jiraSchema } from "@atlaskit/adf-schema/schema-jira"
var _default = exports.default = {
  name: 'ADF',
  components: {
    // TinymceComponent: () => import(/* webpackPrefetch: true */ '@/components/Tinymce')
  },
  data: function data() {
    return {
      loading: false,
      activeName: 'first',
      docSample: '<strong>qwe</strong>',
      adfJira: '',
      adfConfluence: '',
      htmlJira: '',
      adfSample: {
        'version': 1,
        'type': 'doc',
        'content': [{
          'type': 'paragraph',
          'content': [{
            'type': 'text',
            'text': 'Test'
          }]
        }, {
          'type': 'paragraph',
          'content': [{
            'type': 'text',
            'text': 'Test',
            'marks': [{
              'type': 'strong'
            }]
          }]
        }, {
          'type': 'paragraph',
          'content': [{
            'type': 'text',
            'text': 'Test',
            'marks': [{
              'type': 'em'
            }]
          }]
        }, {
          'type': 'paragraph',
          'content': [{
            'type': 'text',
            'text': 'Test',
            'marks': [{
              'type': 'underline'
            }]
          }]
        }, {
          'type': 'paragraph',
          'content': [{
            'type': 'text',
            'text': 'Test',
            'marks': [{
              'type': 'strike'
            }]
          }]
        }, {
          'type': 'paragraph',
          'content': [{
            'type': 'text',
            'text': 'Test',
            'marks': [{
              'type': 'code'
            }]
          }]
        }, {
          'type': 'paragraph',
          'content': [{
            'type': 'text',
            'text': 'Test',
            'marks': [{
              'type': 'subsup',
              'attrs': {
                'type': 'sub'
              }
            }]
          }]
        }, {
          'type': 'paragraph',
          'content': [{
            'type': 'text',
            'text': 'Test',
            'marks': [{
              'type': 'subsup',
              'attrs': {
                'type': 'sup'
              }
            }]
          }]
        }, {
          'type': 'bulletList',
          'content': [{
            'type': 'listItem',
            'content': [{
              'type': 'paragraph',
              'content': [{
                'type': 'text',
                'text': 'Test'
              }]
            }]
          }]
        }, {
          'type': 'orderedList',
          'attrs': {
            'order': 1
          },
          'content': [{
            'type': 'listItem',
            'content': [{
              'type': 'paragraph',
              'content': [{
                'type': 'text',
                'text': 'Test'
              }]
            }]
          }]
        }, {
          'type': 'paragraph',
          'content': [{
            'type': 'text',
            'text': 'Test'
          }, {
            'type': 'emoji',
            'attrs': {
              'shortName': ':grinning:',
              'id': '1f600',
              'text': '😀'
            }
          }, {
            'type': 'text',
            'text': ' '
          }]
        }, {
          'type': 'paragraph',
          'content': [{
            'type': 'mention',
            'attrs': {
              'id': '0',
              'text': '@Carolyn',
              'accessLevel': ''
            }
          }, {
            'type': 'text',
            'text': ' '
          }]
        }]
      }
    };
  },
  computed: {},
  watch: {
    /*    docSample: {
          handler: function(newValue) {
            const jiraTransformer = new JIRATransformer(jiraSchema);
            const adfText = jiraTransformer.parse(this.docSample);
             console.log(adfTransformer.encode(adfText))
            var serializer = new JSONTransformer()
              var test = serializer.encode(this.docSample)
              console.log('test')
              console.log(test)
          },
          deep: true
        }*/
  },
  mounted: function mounted() {
    (0, _traverse.traverse)(this.adfSample, {
      // emoji visitor, matches all nodes with type === 'emoji'
      emoji: function emoji(node, parent) {
        // console.log('emoji')
        // console.log(node)
        // console.log(parent)
      },
      mention: function mention(node, parent) {
        // console.log('mention')
        // console.log(node)
        // console.log(parent)
      },
      taskList: function taskList(node, parent) {
        // do something with taskList
        // console.log('taskList')
      },
      paragraph: function paragraph(node, parent) {
        /* console.log('paragraph')
        console.log(node)
        console.log(parent)*/
      }
    });
    var serializer = new _editorJsonTransformer.JSONTransformer();
    var test = serializer.encode(this.docSample);
    console.log('test');
    console.log(test);
  },
  methods: {
    computeADF: function computeADF() {
      var adfTransformer = new _editorJsonTransformer.JSONTransformer();
      var jiraTransformer = new _editorJiraTransformer.JIRATransformer(_schemaDefault.defaultSchema);
      var adfJIRAText = jiraTransformer.parse(this.docSample);
      this.adfJira = adfTransformer.encode(adfJIRAText);
      var confluenceTransformer = new _editorConfluenceTransformer.ConfluenceTransformer(_schemaConfluence.confluenceSchema);
      var adfConfluenceText = confluenceTransformer.parse(this.docSample);
      this.adfConfluence = adfTransformer.encode(adfConfluenceText);
    },
    adfToHtml: function adfToHtml() {
      if (this.adfSample == null) return '';
      var jsdoc = window.document;
      var el = jsdoc.createElement('div');
      console.warn(this.adfSample);
      var node = JSON.parse(JSON.stringify(this.adfSample)).content;
      var fragment = this.nodeToFragment(node);
      el.append(_prosemirrorModel.DOMSerializer.fromSchema(_schemaConfluence.confluenceSchema).serializeFragment(fragment, {
        document: jsdoc
      }));
      this.htmlJira = this.replaceHtmlTags(el.innerHTML);
    },
    nodeToFragment: function nodeToFragment(node) {
      return _prosemirrorModel.Fragment.fromJSON(_schemaDefault.defaultSchema, node);
    },
    replaceHtmlTags: function replaceHtmlTags(html) {
      var tags = [{
        from: /<em>/g,
        to: '<i>'
      }, {
        from: /<\/em>/g,
        to: '</i>'
      },
      // ensure break tags are self-closing
      {
        from: /<br>/g,
        to: '<br />'
      }];
      for (var _i = 0, _tags = tags; _i < _tags.length; _i++) {
        var tag = _tags[_i];
        html = html.replace(tag.from, tag.to);
      }
      return html;
    }
  }
};