"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "text-center"
  }, [_c("el-form", {
    attrs: {
      model: _vm.offering,
      inline: true,
      "label-position": "right",
      "label-width": "100px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Title"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "Title",
      size: "mini"
    },
    model: {
      value: _vm.offering.title,
      callback: function callback($$v) {
        _vm.$set(_vm.offering, "title", $$v);
      },
      expression: "offering.title"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Description"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "Description",
      size: "mini",
      type: "textarea"
    },
    model: {
      value: _vm.offering.description,
      callback: function callback($$v) {
        _vm.$set(_vm.offering, "description", $$v);
      },
      expression: "offering.description"
    }
  })], 1), _vm._v(" "), _c("el-form-item", [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.renameOfferingDetails
    }
  }, [_vm._v("Update")])], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-table", {
    attrs: {
      data: _vm.offeringSettings,
      stripe: "",
      size: "small"
    }
  }, [_c("el-table-column", {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("strong", [_vm._v(_vm._s(scope.row.title))]), _c("br"), _vm._v(" "), _c("i", {
          staticStyle: {
            "word-break": "keep-all"
          }
        }, [_vm._v(_vm._s(scope.row.description))])];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.note.type ? _c("el-alert", {
          staticStyle: {
            "font-size": "10pt",
            "word-break": "keep-all",
            padding: "2pt"
          },
          attrs: {
            title: scope.row.note.message,
            type: scope.row.note.type,
            closable: false,
            "show-icon": ""
          }
        }) : _vm._e()];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      width: "180"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.type === "delete" ? _c("el-button", {
          staticStyle: {
            width: "100px"
          },
          attrs: {
            type: "danger",
            size: "mini"
          },
          on: {
            click: _vm.deleteOfferingConfirmation
          }
        }, [_vm._v("Delete")]) : _vm._e(), _vm._v(" "), scope.row.type === "version" ? _c("el-button", {
          staticStyle: {
            width: "100px"
          },
          attrs: {
            type: "primary",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.openJourneyVersionsDialog();
            }
          }
        }, [_vm._v("Versions")]) : _vm._e()];
      }
    }])
  })], 1)], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "text-right mt-10"
  }, [_c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: _vm.closeUI
    }
  }, [_vm._v("Close")])], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Delete Confirmation",
      visible: _vm.deleteConfirmationDialog,
      width: "30%",
      "append-to-body": true,
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.deleteConfirmationDialog = $event;
      }
    }
  }, [_c("el-card", [_c("div", [_c("strong", [_vm._v("Are you sure you want to delete the Offering "), _c("el-tag", {
    attrs: {
      size: "mini",
      type: "danger"
    }
  }, [_vm._v(_vm._s(_vm.offering.title))])], 1)]), _vm._v(" "), _c("ul", [_c("li", [_vm._v("All related entities will be deleted")]), _vm._v(" "), _c("li", [_vm._v("Products and Services will be deleted")]), _vm._v(" "), _c("li", [_vm._v("Journeymap, Phase, Steps and Experiences are preserved ")])])]), _vm._v(" "), _c("el-form", {
    attrs: {
      "label-position": "top",
      "label-width": "100px",
      model: _vm.deleteConfirmationForm
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Type the offering name to confirm deletion"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.deleteConfirmationForm.title,
      callback: function callback($$v) {
        _vm.$set(_vm.deleteConfirmationForm, "title", $$v);
      },
      expression: "deleteConfirmationForm.title"
    }
  })], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "secondary"
    },
    on: {
      click: function click($event) {
        _vm.deleteConfirmationDialog = false;
      }
    }
  }, [_vm._v("Cancel")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini",
      type: "danger",
      disabled: _vm.deleteConfirmationForm.title !== _vm.offering.title
    },
    on: {
      click: _vm.deleteOffering
    }
  }, [_vm._v("Delete")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Select Offering version",
      "append-to-body": true,
      visible: _vm.offeringVersionsDialog,
      width: "50%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.offeringVersionsDialog = $event;
      }
    }
  }, [_c("el-table", {
    attrs: {
      data: _vm.offeringVersion,
      stripe: "",
      size: "mini"
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "title",
      label: "Title"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "version",
      label: "Version"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-tag", {
          attrs: {
            size: "mini",
            type: "primary"
          }
        }, [_vm._v(_vm._s(scope.row.version))])];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "#"
    }
  }, [[_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    }
  }, [_vm._v("Select")])]], 2)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.offeringVersionsDialog = false;
      }
    }
  }, [_vm._v("Close")])], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;