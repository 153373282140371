"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  name: 'SideBar',
  components: {},
  props: {
    journeyMapStatus: {
      type: String,
      default: 'unapproved'
    }
  },
  data: function data() {
    return {
      loading: false
    };
  },
  computed: {},
  created: function created() {},
  methods: {
    setActiveDragElement: function setActiveDragElement(element) {
      this.$emit('set-active-drawing-tool-drag-element', element);
    },
    approveJourneyMap: function approveJourneyMap() {
      this.$emit('approve-journeymap');
    },
    revertJourneyMap: function revertJourneyMap() {
      this.$emit('revert-journeymap');
    },
    viewNotes: function viewNotes() {
      this.$emit('view-notes');
    },
    simpleView: function simpleView() {
      this.$eventHub.$emit('switch-ujm-view');
    }
  }
};