"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default2 = exports.default = {
  name: 'FeatureManagmentPainPointsPleasersDetails',
  props: {
    painPointPleaser: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {};
  },
  computed: {},
  mounted: function mounted() {},
  methods: {
    getPriorityIcon: function getPriorityIcon(priority) {
      return 'priority-' + priority;
    },
    getValueIcon: function getValueIcon(value) {
      return 'dollar-' + value;
    }
  }
};