"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.array.fill");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-tabs", {
    model: {
      value: _vm.activeTabName,
      callback: function callback($$v) {
        _vm.activeTabName = $$v;
      },
      expression: "activeTabName"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "Manage Journey",
      name: "journey"
    }
  }, [_c("el-row", {
    staticClass: "mt-10 mb-0"
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("div", {
    staticClass: "ml-10",
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v("Copy, re-generate, or delete your journey.")]), _vm._v(" "), _c("el-table", {
    attrs: {
      data: _vm.journeySettings,
      stripe: "",
      size: "small"
    }
  }, [_c("el-table-column", {
    attrs: {
      width: "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.type === "copy" ? _c("el-button", {
          staticStyle: {
            width: "100px"
          },
          attrs: {
            type: "primary",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.copyJourneyMap();
            }
          }
        }, [_vm._v("Copy")]) : _vm._e(), _vm._v(" "), scope.row.type === "generate" ? _c("el-button", {
          staticStyle: {
            width: "100px"
          },
          attrs: {
            type: "primary",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.regenerateJourneyMapConfirmation();
            }
          }
        }, [_vm._v("Generate")]) : _vm._e(), _vm._v(" "), scope.row.type === "delete" ? _c("el-button", {
          staticStyle: {
            width: "100px"
          },
          attrs: {
            type: "danger",
            size: "mini"
          },
          on: {
            click: _vm.deleteJourneyConfirmation
          }
        }, [_vm._v("Delete")]) : _vm._e()];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-row", [_c("el-col", {
          staticStyle: {
            "word-break": "keep-all"
          },
          attrs: {
            xs: 12,
            sm: 10,
            md: 11,
            lg: 11,
            xl: 11
          }
        }, [_vm._v(_vm._s(scope.row.description) + " ")])], 1)];
      }
    }])
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10,
      offset: 1
    }
  }, [_c("el-form", {
    attrs: {
      inline: true,
      model: _vm.journeyMap
    }
  }, [_c("div", {
    staticStyle: {
      "font-weight": "500"
    }
  }, [_vm._v("Rename Journey Map")]), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: ""
    }
  }, [_c("p"), _vm._v(" "), _c("el-input", {
    attrs: {
      placeholder: "Title",
      size: "mini"
    },
    model: {
      value: _vm.journeyMap.title,
      callback: function callback($$v) {
        _vm.$set(_vm.journeyMap, "title", $$v);
      },
      expression: "journeyMap.title"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    staticClass: "mt-10"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.renameJourneyMapTitle
    }
  }, [_vm._v("Rename")])], 1)], 1)], 1)], 1)], 1), _vm._v(" "), _vm.relationView === "advanced" ? _c("el-tab-pane", {
    attrs: {
      label: "Step Relations",
      name: "step_relations"
    }
  }, [_c("el-table", {
    attrs: {
      data: _vm.journeyMap.additionalattributes.step_relations,
      stripe: "",
      size: "mini"
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Relation",
      width: "180"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-tag", {
          attrs: {
            size: "mini"
          }
        }, [_vm._v(_vm._s(scope.row.start_type) + " - " + _vm._s(_vm.computeEntityTitle(scope.row.start_type, scope.row.start_id)))]), _vm._v(" -> "), _c("el-tag", {
          attrs: {
            size: "mini"
          }
        }, [_vm._v(_vm._s(scope.row.end_type) + " - " + _vm._s(_vm.computeEntityTitle(scope.row.end_type, scope.row.end_id)))])];
      }
    }], null, false, 1802952716)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Styling"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("table", {
          attrs: {
            width: "100%"
          }
        }, [_c("colgroup", [_c("col", {
          staticStyle: {
            width: "20%",
            "padding-left": "5px",
            "padding-right": "5px"
          },
          attrs: {
            span: "1"
          }
        }), _vm._v(" "), _c("col", {
          staticStyle: {
            width: "40%",
            "padding-left": "5px",
            "padding-right": "5px"
          },
          attrs: {
            span: "1"
          }
        }), _vm._v(" "), _c("col", {
          staticStyle: {
            width: "40%",
            "padding-left": "5px",
            "padding-right": "5px"
          },
          attrs: {
            span: "1"
          }
        })]), _vm._v(" "), _c("tr", [_c("th", [_vm._v("Param")]), _vm._v(" "), _c("th", [_vm._v("Value")]), _vm._v(" "), _c("th", [_vm._v("On hover")])]), _vm._v(" "), _c("tr", [_c("td", [_vm._v("Opacity")]), _vm._v(" "), _c("td", {
          staticStyle: {
            "padding-left": "10px",
            "padding-right": "10px"
          }
        }, [_c("el-slider", {
          attrs: {
            min: 0,
            max: 1,
            step: 0.1
          },
          model: {
            value: scope.row.style.opacity,
            callback: function callback($$v) {
              _vm.$set(scope.row.style, "opacity", $$v);
            },
            expression: "scope.row.style.opacity"
          }
        })], 1), _vm._v(" "), _c("td", {
          staticStyle: {
            "padding-left": "10px",
            "padding-right": "10px"
          }
        }, [_c("el-slider", {
          attrs: {
            min: 0,
            max: 1,
            step: 0.1
          },
          model: {
            value: scope.row.styleonhover.opacity,
            callback: function callback($$v) {
              _vm.$set(scope.row.styleonhover, "opacity", $$v);
            },
            expression: "scope.row.styleonhover.opacity"
          }
        })], 1)]), _vm._v(" "), _c("tr", [_c("td", [_vm._v("Fill")]), _vm._v(" "), _c("td", {
          staticStyle: {
            "padding-left": "10px",
            "padding-right": "10px"
          }
        }, [_c("el-color-picker", {
          attrs: {
            size: "mini"
          },
          model: {
            value: scope.row.style.fill,
            callback: function callback($$v) {
              _vm.$set(scope.row.style, "fill", $$v);
            },
            expression: "scope.row.style.fill"
          }
        })], 1), _vm._v(" "), _c("td", {
          staticStyle: {
            "padding-left": "10px",
            "padding-right": "10px"
          }
        }, [_c("el-color-picker", {
          attrs: {
            size: "mini"
          },
          model: {
            value: scope.row.styleonhover.fill,
            callback: function callback($$v) {
              _vm.$set(scope.row.styleonhover, "fill", $$v);
            },
            expression: "scope.row.styleonhover.fill"
          }
        })], 1)]), _vm._v(" "), _c("tr", [_c("td", [_vm._v("Dasharray")]), _vm._v(" "), _c("td", {
          staticStyle: {
            "padding-left": "10px",
            "padding-right": "10px"
          }
        }, [_c("el-select", {
          attrs: {
            placeholder: "Select"
          },
          model: {
            value: scope.row.style.dasharray,
            callback: function callback($$v) {
              _vm.$set(scope.row.style, "dasharray", $$v);
            },
            expression: "scope.row.style.dasharray"
          }
        }, [_c("el-option", {
          attrs: {
            label: "3,1",
            value: "3,1"
          }
        }), _vm._v(" "), _c("el-option", {
          attrs: {
            label: "3,3",
            value: "3,3"
          }
        }), _vm._v(" "), _c("el-option", {
          attrs: {
            label: "4,1",
            value: "4,1"
          }
        }), _vm._v(" "), _c("el-option", {
          attrs: {
            label: "4,2",
            value: "4,2"
          }
        })], 1)], 1), _vm._v(" "), _c("td", {
          staticStyle: {
            "padding-left": "10px",
            "padding-right": "10px"
          }
        }, [_c("el-select", {
          attrs: {
            placeholder: "Select"
          },
          model: {
            value: scope.row.styleonhover.dasharray,
            callback: function callback($$v) {
              _vm.$set(scope.row.styleonhover, "dasharray", $$v);
            },
            expression: "scope.row.styleonhover.dasharray"
          }
        }, [_c("el-option", {
          attrs: {
            label: "3,1",
            value: "3,1"
          }
        }), _vm._v(" "), _c("el-option", {
          attrs: {
            label: "3,3",
            value: "3,3"
          }
        }), _vm._v(" "), _c("el-option", {
          attrs: {
            label: "4,1",
            value: "4,1"
          }
        }), _vm._v(" "), _c("el-option", {
          attrs: {
            label: "4,2",
            value: "4,2"
          }
        })], 1)], 1)]), _vm._v(" "), _c("tr", [_c("td", [_vm._v("Width")]), _vm._v(" "), _c("td", {
          staticStyle: {
            "padding-left": "10px",
            "padding-right": "10px"
          }
        }, [_c("el-slider", {
          attrs: {
            min: 0,
            max: 5,
            step: 1
          },
          model: {
            value: scope.row.style.width,
            callback: function callback($$v) {
              _vm.$set(scope.row.style, "width", $$v);
            },
            expression: "scope.row.style.width"
          }
        })], 1), _vm._v(" "), _c("td", {
          staticStyle: {
            "padding-left": "10px",
            "padding-right": "10px"
          }
        }, [_c("el-slider", {
          attrs: {
            min: 0,
            max: 5,
            step: 1
          },
          model: {
            value: scope.row.styleonhover.width,
            callback: function callback($$v) {
              _vm.$set(scope.row.styleonhover, "width", $$v);
            },
            expression: "scope.row.styleonhover.width"
          }
        })], 1)]), _vm._v(" "), _c("tr", [_c("td", [_vm._v("Color")]), _vm._v(" "), _c("td", {
          staticStyle: {
            "padding-left": "10px",
            "padding-right": "10px"
          }
        }, [_c("el-color-picker", {
          attrs: {
            size: "mini"
          },
          model: {
            value: scope.row.style.color,
            callback: function callback($$v) {
              _vm.$set(scope.row.style, "color", $$v);
            },
            expression: "scope.row.style.color"
          }
        })], 1), _vm._v(" "), _c("td", {
          staticStyle: {
            "padding-left": "10px",
            "padding-right": "10px"
          }
        }, [_c("el-color-picker", {
          attrs: {
            size: "mini"
          },
          model: {
            value: scope.row.styleonhover.color,
            callback: function callback($$v) {
              _vm.$set(scope.row.styleonhover, "color", $$v);
            },
            expression: "scope.row.styleonhover.color"
          }
        })], 1)]), _vm._v(" "), _c("tr", [_c("td", [_vm._v("Path Algorithm")]), _vm._v(" "), _c("td", {
          staticStyle: {
            "padding-left": "10px",
            "padding-right": "10px"
          }
        }, [_vm._v("\n                      Bezier\n                    ")]), _vm._v(" "), _c("td", {
          staticStyle: {
            "padding-left": "10px",
            "padding-right": "10px"
          }
        }, [_vm._v("\n                      Bezier\n                    ")])])])];
      }
    }], null, false, 707342594)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "#",
      width: "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-button", {
          attrs: {
            type: "primary",
            size: "mini",
            disabled: _vm.loading
          },
          on: {
            click: function click($event) {
              return _vm.updateSpecificRelations();
            }
          }
        }, [_vm._v("Update")])];
      }
    }], null, false, 2777906251)
  })], 1)], 1) : _vm._e(), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: "Technical",
      name: "settings"
    }
  }, [_c("table", {
    staticClass: "journey-map-settings",
    attrs: {
      width: "100%"
    }
  }, [_c("colgroup", [_c("col", {
    staticStyle: {
      width: "20%",
      "padding-left": "5px",
      "padding-right": "5px",
      "text-align": "center"
    },
    attrs: {
      span: "1"
    }
  }), _vm._v(" "), _c("col", {
    staticStyle: {
      width: "80%",
      "padding-left": "5px",
      "padding-right": "5px",
      "text-align": "center"
    },
    attrs: {
      span: "1"
    }
  })]), _vm._v(" "), _c("tr", [_c("th", [_vm._v("Param")]), _vm._v(" "), _c("th", [_vm._v("Value")])]), _vm._v(" "), _c("tr", [_c("td", {
    staticStyle: {
      "padding-left": "10px",
      "padding-right": "10px",
      "text-align": "center"
    }
  }, [_vm._v("Sync Frequency")]), _vm._v(" "), _c("td", {
    staticStyle: {
      "padding-left": "10px",
      "padding-right": "10px",
      "text-align": "center"
    }
  }, [_c("el-input-number", {
    attrs: {
      step: 5000,
      size: "mini"
    },
    model: {
      value: _vm.settings.sync_frequency,
      callback: function callback($$v) {
        _vm.$set(_vm.settings, "sync_frequency", $$v);
      },
      expression: "settings.sync_frequency"
    }
  })], 1)]), _vm._v(" "), _vm.journeyMap.iscloned ? _c("tr", [_c("td", {
    staticStyle: {
      "padding-left": "10px",
      "padding-right": "10px",
      "text-align": "center"
    }
  }, [_vm._v("Cloned From")]), _vm._v(" "), _c("td", {
    staticStyle: {
      "padding-left": "10px",
      "padding-right": "10px",
      "text-align": "center"
    }
  }, [_c("el-tag", {
    attrs: {
      size: "mini",
      type: "primary"
    }
  }, [_vm._v(_vm._s(_vm.parentJourneyMapTitle))])], 1)]) : _vm._e()])])], 1)], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "text-right mt-10"
  }, [(_vm.activeTabName === "relations" || _vm.activeTabName === "step_relations") && _vm.relationView === "simple" ? _c("el-button", {
    attrs: {
      type: "warning",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.setRelationView("advanced");
      }
    }
  }, [_vm._v("Advanced")]) : _vm._e(), _vm._v(" "), (_vm.activeTabName === "relations" || _vm.activeTabName === "step_relations") && _vm.relationView === "advanced" ? _c("el-button", {
    attrs: {
      type: "warning",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.setRelationView("simple");
      }
    }
  }, [_vm._v("Simple")]) : _vm._e(), _vm._v(" "), _vm.activeTabName === "settings" ? _c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.updateSyncTiming
    }
  }, [_vm._v("Update")]) : _vm._e(), _vm._v(" "), _vm.activeTabName === "relations" || _vm.activeTabName === "step_relations" ? _c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.updateAllRelations
    }
  }, [_vm._v("Update")]) : _vm._e(), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: _vm.closeUI
    }
  }, [_vm._v("Close")])], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Delete Confirmation",
      visible: _vm.deleteConfirmationDialog,
      width: "30%",
      "append-to-body": true,
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.deleteConfirmationDialog = $event;
      }
    }
  }, [_c("el-card", [_c("div", [_c("strong", [_vm._v("Are you sure you want to delete the Journey "), _c("el-tag", {
    attrs: {
      size: "mini",
      type: "danger"
    }
  }, [_vm._v(_vm._s(_vm.journeyMap.title))])], 1)]), _vm._v(" "), _c("ul", [_c("li", [_vm._v("All related entities will be deleted")]), _vm._v(" "), _c("li", [_vm._v("Phase, Steps and Experience will be deleted")]), _vm._v(" "), _c("li", [_vm._v("Offering, Product and Services are preserved ")])])]), _vm._v(" "), _c("el-form", {
    attrs: {
      "label-position": "top",
      "label-width": "100px",
      model: _vm.deleteConfirmationForm
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Type the journey name to confirm deletion"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.deleteConfirmationForm.title,
      callback: function callback($$v) {
        _vm.$set(_vm.deleteConfirmationForm, "title", $$v);
      },
      expression: "deleteConfirmationForm.title"
    }
  })], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "secondary"
    },
    on: {
      click: function click($event) {
        _vm.deleteConfirmationDialog = false;
      }
    }
  }, [_vm._v("Cancel")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini",
      type: "danger",
      disabled: _vm.deleteConfirmationForm.title !== _vm.journeyMap.title
    },
    on: {
      click: _vm.deleteJourney
    }
  }, [_vm._v("Delete")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Regenerate Confirmation",
      "append-to-body": true,
      visible: _vm.regenerateConfirmationDialog,
      width: "30%",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.regenerateConfirmationDialog = $event;
      }
    }
  }, [_c("el-card", [_c("div", [_c("strong", [_vm._v("Are you sure you want to regenerate the Journey "), _c("el-tag", {
    attrs: {
      size: "mini",
      type: "danger"
    }
  }, [_vm._v(_vm._s(_vm.journeyMap.title))])], 1)]), _vm._v(" "), _c("ul", [_c("li", [_vm._v("All related entities will be overwritten")]), _vm._v(" "), _c("li", [_vm._v("Phase, Steps and Experience will be overwritten")])])]), _vm._v(" "), _c("el-form", {
    attrs: {
      "label-position": "top",
      "label-width": "100px",
      model: _vm.generationConfirmationForm
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Type the journey name to confirm regeneration"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.generationConfirmationForm.title,
      callback: function callback($$v) {
        _vm.$set(_vm.generationConfirmationForm, "title", $$v);
      },
      expression: "generationConfirmationForm.title"
    }
  })], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "secondary"
    },
    on: {
      click: function click($event) {
        _vm.regenerateConfirmationDialog = false;
      }
    }
  }, [_vm._v("Cancel")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini",
      type: "primary",
      disabled: _vm.generationConfirmationForm.title !== _vm.journeyMap.title
    },
    on: {
      click: _vm.regenerateJourney
    }
  }, [_vm._v("Regenerate")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Select Journey version",
      "append-to-body": true,
      visible: _vm.journeyVersionsDialog,
      width: "50%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.journeyVersionsDialog = $event;
      }
    }
  }, [_c("el-table", {
    attrs: {
      data: _vm.journeyVersion,
      stripe: "",
      size: "mini"
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "title",
      label: "Title"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "version",
      label: "Version"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-tag", {
          attrs: {
            size: "mini",
            type: "primary"
          }
        }, [_vm._v(_vm._s(scope.row.version))])];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "#"
    }
  }, [[_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    }
  }, [_vm._v("Select")])]], 2)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.journeyVersionsDialog = false;
      }
    }
  }, [_vm._v("Close")])], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;