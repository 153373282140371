"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.replace");
var _User = _interopRequireDefault(require("./optimise-interactions/User"));
var _Feature = _interopRequireDefault(require("./optimise-interactions/Feature"));
var _default = exports.default = {
  name: 'OptimiseInteractions',
  components: {
    UserComponent: _User.default,
    FeatureComponent: _Feature.default
  },
  props: {},
  data: function data() {
    return {
      loading: false,
      addDescriptionDialog: false,
      addDataTypeDialog: false,
      featureDescription: '',
      users: [{
        title: 'User1',
        key: 1,
        user_group: [],
        journey_map: null,
        user_type: null,
        segment: null,
        design_goals: null,
        user_count: 0,
        revenue_strategy: null,
        pain_points: [{
          key: 0,
          point: '',
          priority: 0,
          value: 0,
          relations: [{
            to: 'candidate_feature',
            key: 0
          }, {
            to: 'candidate_feature',
            key: 1
          }]
        }],
        pleasers: [{
          key: 1,
          point: '',
          priority: 0,
          value: 0,
          relations: [{
            to: 'candidate_feature',
            key: 0
          }, {
            to: 'candidate_feature',
            key: 1
          }]
        }],
        candidate_features: [{
          key: 0,
          feature: 'user 1, feature 1',
          revenue_type: '1',
          forecase_revenue: '1',
          priority: '2',
          status: '3'
        }, {
          key: 1,
          feature: 'user 1, feature 2',
          revenue_type: '1',
          forecase_revenue: '1',
          priority: '2',
          status: '3'
        }]
      }, {
        title: 'User2',
        key: 1,
        user_group: [],
        journey_map: null,
        user_type: null,
        segment: null,
        design_goals: null,
        user_count: 0,
        revenue_strategy: null,
        pain_points: [{
          key: 0,
          point: '',
          priority: 0,
          value: 0,
          relations: [{
            to: 'candidate_feature',
            key: 0
          }, {
            to: 'candidate_feature',
            key: 1
          }]
        }],
        pleasers: [{
          key: 1,
          point: '',
          priority: 0,
          value: 0,
          relations: [{
            to: 'candidate_feature',
            key: 0
          }, {
            to: 'candidate_feature',
            key: 1
          }]
        }],
        candidate_features: [{
          key: 0,
          feature: 'user 2, feature 3',
          revenue_type: '1',
          forecase_revenue: '1',
          priority: '2',
          status: '3'
        }, {
          key: 1,
          feature: 'user 2, feature 4',
          revenue_type: '1',
          forecase_revenue: '1',
          priority: '2',
          status: '3'
        }]
      }],
      activeUser: {},
      activeDragElement: {},
      activeDragElementType: '',
      activeDragElementIndex: 0,
      userInteractions: [],
      featureInteractions: [],
      userFeatureLinks: [],
      dataTypeDialogType: 'create',
      dataTypeIndex: null,
      dataTypeForm: {
        title: '',
        description: ''
      },
      activeUsers: [],
      activeFeatures: []
    };
  },
  created: function created() {
    // Listen to event
    this.$eventHub.$on('create-data-type', this.openDataTypeCreateDialog);
    this.$eventHub.$on('edit-data-type', this.openDataTypeEditDialog);
  },
  mounted: function mounted() {
    this.$eventHub.$emit('process-step-load', 'optimize-interactions');
    this.activeUser = this.users[0];
  },
  methods: {
    openDataTypeCreateDialog: function openDataTypeCreateDialog() {
      this.dataTypeDialogType = 'create';
      this.addDataTypeDialog = true;
    },
    openDataTypeEditDialog: function openDataTypeEditDialog(data, index) {
      this.dataTypeDialogType = 'edit';
      this.dataTypeIndex = index;
      this.dataTypeForm.title = data.title;
      this.dataTypeForm.description = data.description;
      this.addDataTypeDialog = true;
    },
    addNewDataType: function addNewDataType() {
      this.$eventHub.$emit('add-data-type', this.dataTypeForm);
      this.addDataTypeDialog = false;
    },
    updateDataType: function updateDataType() {
      this.$eventHub.$emit('update-data-type', this.dataTypeForm, this.dataTypeIndex);
      this.dataTypeIndex = null;
      this.addDataTypeDialog = false;
    },
    addAllUsers: function addAllUsers() {
      for (var i = 0; i < this.users.length; i++) {
        this.activeUsers.push(this.users[i]);
        this.addUserToCanvas(this.users[i].title, 100, 100 * i, this.users[i].key);
      }
      this.users = [];
      this.resetActiveElements();
      this.renderLinks();
    },
    addAllFeatures: function addAllFeatures() {
      for (var i = 0; i < this.activeUser.candidate_features.length; i++) {
        this.activeFeatures.push(this.activeUser.candidate_features[i]);
        this.addFeatureToCanvas(this.activeUser.candidate_features[i].feature, 300, 100 * i + 100, this.activeUser.candidate_features[i].key, this.activeUser.key);
      }
      this.activeUser.candidate_features = [];
      this.resetActiveElements();
      this.renderLinks();
    },
    computePathPoints: function computePathPoints(userFeatureLink) {
      var start_x1 = userFeatureLink.x1 + 15;
      var start_y1 = userFeatureLink.y1;
      var start_x2 = userFeatureLink.x2;
      var start_y2 = userFeatureLink.y2;
      var end_x1 = start_x1 + (start_x2 - start_x1) / 2;
      var end_y1 = start_y1;
      var end_x2 = start_x2 - (start_x2 - start_x1) / 2;
      var end_y2 = start_y2;
      return 'M' + start_x1 + ',' + start_y1 + ' C' + end_x1 + ',' + end_y1 + ' ' + end_x2 + ',' + end_y2 + ' ' + start_x2 + ',' + start_y2;
    },
    setActiveDragElement: function setActiveDragElement(elementType, element, index) {
      this.activeDragElement = element;
      this.activeDragElementType = elementType;
      this.activeDragElementIndex = index;
    },
    addUserToCanvas: function addUserToCanvas(title, x, y, key) {
      var user = {
        title: title,
        x: x,
        y: y,
        key: key
      };
      this.userInteractions.push(user);
    },
    addFeatureToCanvas: function addFeatureToCanvas(feature, cx, cy, key, parent_key) {
      var feature_obj = {
        cx: cx,
        cy: cy,
        feature: feature,
        key: key,
        parent_key: parent_key
      };
      this.featureInteractions.push(feature_obj);
    },
    handleDrop: function handleDrop(event) {
      event.preventDefault(); // !important
      if (this.activeDragElementType === 'user') {
        this.addUserToCanvas(this.activeDragElement.title, event.offsetX, event.offsetY, this.activeDragElement.key);
        this.activeUsers.push(this.users[this.activeDragElementIndex]);
        this.users.splice(this.activeDragElementIndex, 1);
      } else if (this.activeDragElementType === 'feature') {
        this.addFeatureToCanvas(this.activeDragElement.feature, event.offsetX, event.offsetY, this.activeDragElement.key, this.activeUser.key);
        this.activeFeatures.push(this.activeUser.candidate_features[this.activeDragElementIndex]);
        this.activeUser.candidate_features.splice(this.activeDragElementIndex, 1);
      }
      this.renderLinks();
      this.resetActiveElements();
    },
    handleDragOver: function handleDragOver(event) {
      event.preventDefault(); // !important
    },
    resetActiveElements: function resetActiveElements() {
      this.activeDragElement = {};
      this.activeDragElementType = null;
      this.activeDragElementIndex = 0;
    },
    generateUserInterationID: function generateUserInterationID(index) {
      return 'user_' + index;
    },
    generateFeatureInterationID: function generateFeatureInterationID(index) {
      return 'feature_' + index;
    },
    startUserMove: function startUserMove(_ref) {
      var el = _ref.el,
        deltaX = _ref.deltaX,
        deltaY = _ref.deltaY,
        offsetX = _ref.offsetX,
        offsetY = _ref.offsetY,
        clientX = _ref.clientX,
        clientY = _ref.clientY,
        first = _ref.first,
        last = _ref.last;
      if (deltaX === undefined || deltaY === undefined) {
        return;
      }
      var index = el.id.replace('user_', '');
      this.userInteractions[index].x += parseInt(deltaX);
      this.userInteractions[index].y += parseInt(deltaY);
      this.renderLinks();
    },
    startFeatureMove: function startFeatureMove(_ref2) {
      var el = _ref2.el,
        deltaX = _ref2.deltaX,
        deltaY = _ref2.deltaY,
        offsetX = _ref2.offsetX,
        offsetY = _ref2.offsetY,
        clientX = _ref2.clientX,
        clientY = _ref2.clientY,
        first = _ref2.first,
        last = _ref2.last;
      if (deltaX === undefined || deltaY === undefined) {
        return;
      }
      var index = el.id.replace('feature_', '');
      this.featureInteractions[index].cx += parseInt(deltaX);
      this.featureInteractions[index].cy += parseInt(deltaY);
      this.renderLinks();
    },
    getStartPointX: function getStartPointX(feature) {
      this.userInteractions.forEach(function (userInteraction) {
        if (userInteraction.key === feature.parent_key) {
          return userInteraction.x;
        }
      });
    },
    getStartPointY: function getStartPointY(feature) {
      this.userInteractions.forEach(function (userInteraction) {
        if (userInteraction.key === feature.parent_key) {
          return userInteraction.y;
        }
      });
    },
    renderLinks: function renderLinks() {
      this.userFeatureLinks = [];
      for (var i = 0; i < this.featureInteractions.length; i++) {
        for (var j = 0; j < this.userInteractions.length; j++) {
          if (this.userInteractions[j].key === this.featureInteractions[i].parent_key) {
            var feature_link = {
              x1: this.userInteractions[j].x + 30,
              y1: this.userInteractions[j].y + 25,
              x2: this.featureInteractions[i].cx - 55,
              y2: this.featureInteractions[i].cy
            };
            this.userFeatureLinks.push(feature_link);
          }
        }
      }
    },
    removeActiveUser: function removeActiveUser(user, index) {
      this.activeUsers.splice(index, 1);
      this.users.push(user);
      var userInteractionIndex = null;
      for (var j = 0; j < this.userInteractions.length; j++) {
        if (this.userInteractions[j].key === user.key) {
          userInteractionIndex = j;
        }
      }
      if (userInteractionIndex != null) {
        this.userInteractions.splice(userInteractionIndex, 1);
      }
      this.renderLinks();
    },
    removeActiveFeature: function removeActiveFeature(feature, index) {
      this.activeFeatures.splice(index, 1);
      this.activeUser.candidate_features.push(feature);
      var featureInteractionIndex = null;
      for (var j = 0; j < this.featureInteractions.length; j++) {
        if (this.featureInteractions[j].key === feature.key) {
          featureInteractionIndex = j;
        }
      }
      if (featureInteractionIndex != null) {
        this.featureInteractions.splice(featureInteractionIndex, 1);
      }
      this.renderLinks();
    },
    goToRefineStories: function goToRefineStories() {
      this.$emit('go-next');
    }
  }
};