"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _default2 = exports.default = {
  name: 'RoadmapSwimLaneComponent',
  props: {
    x: {
      type: Number,
      required: true,
      default: 0
    },
    y: {
      type: Number,
      required: true,
      default: 0
    },
    index: {
      type: Number,
      required: true,
      default: 0
    },
    lane: {
      type: Object,
      default: function _default() {
        return {
          name: '',
          description: '',
          highlight: false,
          color: '#000000'
        };
      }
    }
  },
  data: function data() {
    return {};
  },
  methods: {
    computeFill: function computeFill() {
      if (this.lane.highlight) {
        return 'orange';
      } else {
        return 'gray';
      }
    },
    generateTransform: function generateTransform() {
      var x = 0 + 35;
      var y = this.y + 75;
      return 'rotate(-90 ' + x + ',' + y + ')';
    },
    emitClick: function emitClick() {
      this.$emit('click');
    }
  }
};