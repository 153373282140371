"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/taggedTemplateLiteral.js"));
var _objectSpread2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _axios = _interopRequireDefault(require("axios"));
var _vuex = require("vuex");
var _templateObject, _templateObject2;
var _default = exports.default = {
  name: 'FeatureBacklog',
  components: {},
  data: function data() {
    return {
      loading: false,
      journeyMap: {},
      featureAndProcessList: []
    };
  },
  watch: {
    /* offering: {
     handler: function(newValue) {
       this.retrieveOfferingParentCustomerTypeOffering()
       this.featureAndProcessList = []
       this.mergedFeaturesAndProcesses()
     },
     deep: true
    }*/
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['offering'])),
  mounted: function mounted() {
    this.retrieveOfferingParentCustomerTypeOffering();
    this.featureAndProcessList = [];
    this.mergedFeaturesAndProcesses();
  },
  methods: {
    trimString: function trimString(string, length) {
      if (string.length > length) {
        return string.substring(0, length) + '...';
      } else {
        return string;
      }
    },
    mergedFeaturesAndProcesses: function mergedFeaturesAndProcesses() {
      for (var i = 0; i < this.offering.linkedProducts.length; i++) {
        for (var j = 0; j < this.offering.linkedProducts[i].product.linkedFeatures.length; j++) {
          this.featureAndProcessList.push(this.offering.linkedProducts[i].product.linkedFeatures[j].productFeature);
        }
      }
      for (var k = 0; k < this.offering.linkedServices.length; k++) {
        for (var l = 0; l < this.offering.linkedServices[k].service.linkedServiceApplications.length; l++) {
          this.featureAndProcessList.push(this.offering.linkedServices[k].service.linkedServiceApplications[l].serviceApplication);
        }
      }
      for (var m = 0; m < this.featureAndProcessList.length; m++) {
        this.featureAndProcessList[m].priority = 0;
        this.featureAndProcessList[m].smart_priority = 0;
        this.retrieveTicketPriority(m, this.featureAndProcessList[m].id);
      }
      return this.featureAndProcessList;
    },
    computeSmartPriority: function computeSmartPriority() {
      for (var m = 0; m < this.featureAndProcessList.length; m++) {
        this.retrieveJourneyMapPriority(m, this.featureAndProcessList[m].id);
      }
    },
    retrieveJourneyMapPriority: function retrieveJourneyMapPriority(index, entity_id) {
      for (var i = 0; i < this.offering.additionalattributes.relations.length; i++) {
        if (entity_id === this.offering.additionalattributes.relations[i].end_id) {
          if (this.offering.additionalattributes.relations[i].start_type === 'Phase') {
            this.featureAndProcessList[index].smart_priority += this.retrievePhasePriority(this.offering.additionalattributes.relations[i].start_id);
          }
          if (this.offering.additionalattributes.relations[i].start_type === 'Step') {
            this.featureAndProcessList[index].smart_priority += this.retrieveStepPriority(this.offering.additionalattributes.relations[i].start_id);
            this.featureAndProcessList[index].smart_priority += this.retrievePhasePriorityForStep(this.offering.additionalattributes.relations[i].start_id);
          }
        }
      }
    },
    retrievePhasePriorityForStep: function retrievePhasePriorityForStep(step_id) {
      for (var i = 0; i < this.journeyMap.additionalattributes.relations.length; i++) {
        if (step_id === this.journeyMap.additionalattributes.relations[i].end_id && this.journeyMap.additionalattributes.relations[i].start_type === 'Phase') {
          return this.retrievePhasePriority(this.journeyMap.additionalattributes.relations[i].start_id);
        }
      }
    },
    retrievePhasePriority: function retrievePhasePriority(phase_id) {
      for (var i = 0; i < this.journeyMap.linkedJourneyPhases.length; i++) {
        if (this.journeyMap.linkedJourneyPhases[i].journeyPhase.id === phase_id) {
          return this.computeJourneyMapNumericalPriority(this.journeyMap.linkedJourneyPhases[i].journeyPhase.priority);
        }
      }
    },
    retrieveStepPriority: function retrieveStepPriority(step_id) {
      for (var i = 0; i < this.journeyMap.linkedJourneySteps.length; i++) {
        if (this.journeyMap.linkedJourneySteps[i].journeyStep.id === step_id) {
          return this.computeJourneyMapNumericalPriority(this.journeyMap.linkedJourneySteps[i].journeyStep.priority);
        }
      }
    },
    retrieveTicketPriority: function retrieveTicketPriority(index, entity_id) {
      var _this = this;
      // Retrieve Ticket and populate ticket modal
      var headers = {
        'Content-Type': 'application/json',
        'x-party-id': this.$store.getters.partyId,
        'x-user-id': this.$store.getters.id
      };
      _axios.default.get(process.env.VUE_APP_REST_API_ENDPOINT + 'ticket?extended=LEVEL0&entityId=' + entity_id, {
        headers: headers
      }).then(function (response) {
        var ticket = {};
        ticket = response.data.data[0];
        if (ticket.priority) {
          _this.featureAndProcessList[index].priority += _this.computePriority(ticket.priority);
        }
      }).catch(function (error) {
        console.log(error);
      });
    },
    computeJourneyMapNumericalPriority: function computeJourneyMapNumericalPriority(priority) {
      switch (priority) {
        case 'Very Low':
        case 'Lowest':
          return 1;
        case 'Low':
          return 2;
        case 'Medium':
          return 3;
        case 'High':
          return 4;
        case 'Very High':
        case 'Highest':
          return 5;
        default:
          return 0;
      }
    },
    computePriority: function computePriority(priority) {
      switch (priority) {
        case 'Lowest':
        case 'Very Low':
          return 1;
        case 'Low':
          return 2;
        case 'Medium':
          return 3;
        case 'High':
          return 4;
        case 'Very High':
        case 'Highest':
          return 5;
        default:
          return 0;
      }
    },
    retrieveOfferingParentCustomerTypeOffering: function retrieveOfferingParentCustomerTypeOffering() {
      var _this2 = this;
      this.$apollo.query({
        // Query
        query: window._gql(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)([" query CustomerTypes($page: Page, $offeringcriteriaexpression: OfferingsCriteriaExpression!)\n        {\n        CustomerTypes(page: $page)\n        {\n          pages\n          total\n          select{\n            id\n            title\n            linkedOfferings(optional: true) {\n              offering(optional: true, where: $offeringcriteriaexpression) {\n                id\n                title\n              }\n            }\n          }\n        }\n      }"]))),
        fetchPolicy: 'no-cache',
        variables: {
          'page': {
            'limit': 100,
            'start': 1
          },
          'offeringcriteriaexpression': {
            'id': {
              'EQ': this.offering.id
            }
          }
        }
      }).then(function (data) {
        if (data.data.CustomerTypes.select.length > 0) {
          _this2.retrieveOfferingParentCustomerTypeJourneyMap(data.data.CustomerTypes.select[0].id);
        }
      }).catch(function (error) {
        // Error
        console.log(error);
      });
    },
    retrieveOfferingParentCustomerTypeJourneyMap: function retrieveOfferingParentCustomerTypeJourneyMap(customer_id) {
      var _this3 = this;
      this.$apollo.query({
        // Query
        query: window._gql(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)([" query CustomerType($id: String)\n        {\n        CustomerType(id: $id)\n        {\n          id\n          title\n          linkedJourneyMaps(optional: true) {\n            journeyMap(optional: true) {\n              id\n              title\n              description\n              additionalattributes\n              linkedJourneyPhases(optional: true) {\n                journeyPhase(optional: true) {\n                  id\n                  title\n                  description\n                  priority\n                  importance\n                  goal\n                  emotions\n                  duration\n                  challenges\n                  additionalattributes\n                  relations\n                }\n              }\n              linkedJourneySteps(optional: true) {\n                journeyStep(optional: true) {\n                  id\n                  title\n                  description\n                  goal\n                  importance\n                  intention\n                  relations\n                  priority\n                  additionalattributes\n                }\n              }\n            }\n          }\n        }\n      }"]))),
        fetchPolicy: 'no-cache',
        variables: {
          'id': customer_id
        }
      }).then(function (data) {
        if (data.data.CustomerType.linkedJourneyMaps.length > 0) {
          _this3.journeyMap = data.data.CustomerType.linkedJourneyMaps[0].journeyMap;
          _this3.computeSmartPriority();
        }
      }).catch(function (error) {
        // Error
        console.log(error);
      });
    },
    truncateDescription: function truncateDescription(description, length) {
      return this._.truncate(description, {
        'length': length,
        'separator': ' '
      });
    }
  }
};