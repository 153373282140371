"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("g", {
    attrs: {
      refs: "goal-container"
    }
  }, [_c("g", {
    attrs: {
      refs: "goal"
    }
  }, [_c("rect", {
    attrs: {
      x: 315 + _vm.index * (_vm.phaseParam.width + 25),
      y: 110 + 175 + _vm.goalSubIndex * (_vm.phaseParam.title_height + 5),
      rx: "10",
      ry: "10",
      width: _vm.width,
      height: _vm.height,
      fill: "gray",
      stroke: _vm.selectedActivityIndex === _vm.subIndex && _vm.selectedPhaseIndex === _vm.index ? "black" : "gray",
      "fill-opacity": "0.8",
      "stroke-opacity": "1",
      "stroke-width": "2"
    }
  }), _vm._v(" "), _c("text", {
    attrs: {
      x: 320 + _vm.index * (_vm.phaseParam.width + 25),
      y: 130 + 175 + _vm.goalSubIndex * (_vm.phaseParam.title_height + 5),
      "font-size": "0.9em",
      fill: "white",
      "font-weight": "normal",
      "text-anchor": "start"
    }
  }, [_vm._v(_vm._s(_vm.goal.name))]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "deleteGoalIcon"
    },
    on: {
      click: function click($event) {
        $event.preventDefault();
        return _vm.deleteGoal(_vm.index, _vm.subIndex, _vm.goalSubIndex);
      }
    }
  }, [_c("circle", {
    attrs: {
      cx: 470 + _vm.index * (_vm.phaseParam.width + 25),
      cy: 130 + 175 + _vm.goalSubIndex * (_vm.phaseParam.title_height + 5),
      r: "6",
      stroke: "white",
      "stroke-width": "1",
      fill: "#F56C6C"
    }
  })])])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;