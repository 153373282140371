"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.number.constructor");
// import PieChart from './PieChart'
var _default2 = exports.default = {
  name: 'TaskPopover',
  components: {
    // PieChart
  },
  props: {
    journeyMap: {
      type: Object,
      required: true,
      default: function _default() {
        return {
          id: '',
          title: '',
          description: '',
          additionalattributes: {},
          linkedJourneyExperiences: [],
          linkedJourneyPhases: [],
          linkedJourneySteps: []
        };
      }
    },
    position: {
      type: String,
      default: 'right'
    },
    x: {
      type: Number,
      default: 0
    },
    y: {
      type: Number,
      default: 0
    },
    width: {
      type: Number,
      default: 0
    },
    index: {
      type: Number,
      default: 0
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    step: {
      type: Object,
      default: function _default() {
        return {
          title: '',
          description: '',
          relations: []
        };
      }
    },
    experiences: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    ideas: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      isHover: false,
      gainHover: false,
      painHover: false,
      wowHover: false,
      challengeHover: false,
      hasConfirmOpen: false,
      experienceChunkSize: 5,
      activeExperienceChunk: 0
    };
  },
  computed: {
    experiencesList: function experiencesList() {
      var experience_ids = [];
      var experiences = [];
      for (var i = 0; i < this.journeyMap.additionalattributes.relations.length; i++) {
        if (this.journeyMap.additionalattributes.relations[i].start_id === this.step.id) {
          experience_ids.push(this.journeyMap.additionalattributes.relations[i].end_id);
        }
      }
      for (var j = 0; j < this.journeyMap.linkedJourneyExperiences.length; j++) {
        if (experience_ids.includes(this.journeyMap.linkedJourneyExperiences[j].journeyExperience.id)) {
          experiences.push(this.journeyMap.linkedJourneyExperiences[j]);
        }
      }
      return this._.chunk(experiences, this.experienceChunkSize);
    },
    computeHeight: function computeHeight() {
      if (this.step && this.step.linkedJourneySubsteps && this.experiencesList[this.activeExperienceChunk]) {
        var sub_step_height = this.step.linkedJourneySubsteps.length * 35;
        var experience_height = this.experiencesList[this.activeExperienceChunk].length * 35;
        return sub_step_height + experience_height + 220;
      } else {
        return 220;
      }
    }
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    trimString: function trimString(string, length) {
      if (string.length > length) {
        return string.substring(0, length) + '...';
      } else {
        return string;
      }
    },
    generateMarker: function generateMarker() {
      if (this.position === 'right') {
        var x_start_right = this.x - 32;
        var y_start_right = this.y + 113;
        var x_mid_right = this.x - 22;
        var y_mid_right = this.y + 108;
        var x_last_right = this.x - 22;
        var y_last_right = this.y + 118;
        return 'M ' + x_start_right + ' ' + y_start_right + ' L ' + x_mid_right + ' ' + y_mid_right + ' L ' + x_last_right + ' ' + y_last_right + '';
      } else {
        var x_start_left = this.x + this.width + 10 - 32;
        var y_start_left = this.y + 108;
        var x_mid_left = this.x + this.width + 10 - 22;
        var y_mid_left = this.y + 113;
        var x_last_left = this.x + this.width + 10 - 32;
        var y_last_left = this.y + 118;
        return 'M ' + x_start_left + ' ' + y_start_left + ' L ' + x_mid_left + ' ' + y_mid_left + ' L ' + x_last_left + ' ' + y_last_left + '';
      }
    },
    computeCustomerValueIcon: function computeCustomerValueIcon(imp) {
      switch (imp) {
        case 'Very Low':
          return 'low-value';
        case 'Low':
          return 'low-value';
        case 'Medium':
          return 'dollar-1';
        case 'High':
          return 'dollar-2';
        case 'Very High':
          return 'dollar-3';
      }
    },
    computeValueFontSize: function computeValueFontSize(value1) {
      if (value1 === 'Very Low') {
        return 'lowestValue';
      } else if (value1 === 'Low') {
        return 'lowValue';
      }
    },
    closeTaskDetailsPopover: function closeTaskDetailsPopover() {
      this.ishover = false;
      if (!this.hasConfirmOpen) {
        this.$emit('close');
      }
    },
    displayTaskDetails: function displayTaskDetails() {
      this.$emit('details', this.index);
    },
    displayTaskProgress: function displayTaskProgress() {
      this.$emit('details', this.index, 'step_progress');
    },
    initiateCreateStepIdea: function initiateCreateStepIdea() {
      this.$emit('initiate-create-step-idea', this.step.title);
    },
    createExperienceFromStep: function createExperienceFromStep(experience_type) {
      this.$emit('initiate-create-experience', experience_type);
    },
    deleteStep: function deleteStep() {
      var _this = this;
      this.hasConfirmOpen = true;
      this.$confirm('This will permanently delete step. Continue?', 'Warning', {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(function () {
        _this.hasConfirmOpen = false;
        _this.$emit('delete-step', _this.step.id);
      }).catch(function (e) {
        _this.hasConfirmOpen = false;
        console.log(e);
        _this.$message({
          type: 'info',
          message: 'Delete canceled'
        });
      });
    },
    emitOnMouseOver: function emitOnMouseOver() {
      this.$emit('mouseover', this.index);
    },
    emitOnMouseLeave: function emitOnMouseLeave() {
      this.$emit('mouseleave', this.index);
    },
    truncateString: function truncateString(string, length) {
      return this._.truncate(string, {
        'length': length,
        'separator': '...'
      });
    }
  }
};