"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _default2 = exports.default = {
  name: 'Phase',
  components: {},
  props: {
    x: {
      type: Number,
      default: 0
    },
    y: {
      type: Number,
      default: 0
    },
    index: {
      type: Number,
      default: 0
    },
    length: {
      type: Number,
      default: 0
    },
    label: {
      type: String,
      default: ''
    },
    showLabel: {
      type: Boolean,
      default: false
    },
    focusMode: {
      type: Boolean,
      default: false
    },
    hover: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    isIdeaHover: {
      type: Boolean,
      default: false
    },
    collapse: {
      type: Boolean,
      default: false
    },
    highlight: {
      type: String,
      default: null
    },
    priority: {
      type: String,
      default: 'Medium'
    },
    value: {
      type: String,
      default: 'Medium'
    },
    phase: {
      type: Object,
      default: function _default() {
        return {
          title: '',
          description: '',
          relations: []
        };
      }
    },
    ideas: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      isHover: false,
      isCollapse: false,
      isLongPress: false
    };
  },
  /** Computed uses 7.5 pixels as average character-width
   * Scenarios are a A) string longer than width needing dynamic length before crlf, B) String shorter than phase 'length'
   * 'length'' is dynamic based on number of steps
   * Inconsistent bug noted when line 2 is shorter than line 1: First two letters of Line 1, eg AB, appear as A for line 1 and B for line 2, with no other chars.
   * **/
  computed: {
    computedLabel: function computedLabel() {
      var line1;
      var line2;
      var line2truncated;
      if (this.label.length > this.length / 5) {
        line1 = this.label.substring(0, this.length / 5);
        line2 = this.label.substring(this.length / 5, this.label.length);
        // truncate line 2 if longer than line 1
        if (line2.length > line1.length) {
          line2truncated = line2.substring(0, line1.length - 2).concat('...');
          return [line1, line2truncated];
        }
        return [line1, line2];
      } else {
        return [this.label, ''];
      }
    },
    computedCollapseLabel: function computedCollapseLabel() {
      return this.label.substring(0, 15).concat('...');
    }
  },
  watch: {
    collapse: function collapse(after, before) {
      this.$emit('updatelayout');
    },
    hover: function hover(val) {
      if (!val) {
        this.isHover = false;
      }
    }
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    computePriority: function computePriority() {
      switch (this.phase.journeyPhase.priority) {
        case 'Very Low':
          return 1;
        case 'Low':
          return 2;
        case 'Medium':
          return 3;
        case 'High':
          return 4;
        case 'Very High':
          return 5;
      }
    },
    computeCustomerValueIcon: function computeCustomerValueIcon() {
      switch (this.value) {
        case 'Very Low':
          return 'dollar';
        case 'Low':
          return 'dollar';
        case 'Medium':
          return 'dollar-2';
        case 'High':
          return 'dollar-2';
        case 'Very High':
          return 'dollar-3';
        default:
          return 'dollar';
      }
    },
    computePhaseStyle: function computePhaseStyle() {
      if (this.highlight === 'pain_gain' && !this.collapse) {
        return 'active phase_links';
      } else if (this.highlight === 'pain_gain' && this.collapse) {
        return 'active phase_links_collapsed';
      } else if (this.highlight !== 'pain_gain' && !this.collapse) {
        return 'phase_links';
      } else if (this.highlight !== 'pain_gain' && this.collapse) {
        return 'phase_links_collapsed';
      }
    },
    computeStyle: function computeStyle() {
      if (this.isLongPress) {
        return 'cursor:move';
      }
      return '';
    },
    generateValuePath: function generateValuePath() {
      var point1_x = this.x + 15;
      var point2_x = this.x + 30;
      var point1_y = this.y + 25;
      var point2_y = this.y + 50;
      return point1_x + ',' + point1_y + ' ' + point2_x + ',' + point2_y + ' ' + this.x + ',' + point2_y;
    },
    generatePriorityPath: function generatePriorityPath() {
      var point2_x = this.x + 30;
      var point3_x = this.x + 15;
      var point3_y = this.y + 25;
      return this.x + ',' + this.y + ' ' + point2_x + ',' + this.y + ' ' + point3_x + ',' + point3_y;
    },
    generatePath: function generatePath() {
      var point2_x = this.x + this.length + 25;
      var point3_x = this.x + this.length + 40;
      var point6_x = this.x + 15;
      var point3_y = this.y + 25;
      var point4_y = this.y + 50;
      return this.x + ',' + this.y + ' ' + point2_x + ',' + this.y + ' ' + point3_x + ',' + point3_y + ' ' + point2_x + ',' + point4_y + ' ' + this.x + ',' + point4_y + ' ' + point6_x + ',' + point3_y;
    },
    generatePathHighlighter: function generatePathHighlighter() {
      var point1_x = this.x - 10;
      var point2_x = this.x + this.length + 30;
      var point3_x = this.x + this.length + 50;
      var point6_x = this.x + 10;
      var point1_y = this.y - 5;
      var point3_y = this.y + 25;
      var point4_y = this.y + 55;
      return point1_x + ',' + point1_y + ' ' + point2_x + ',' + point1_y + ' ' + point3_x + ',' + point3_y + ' ' + point2_x + ',' + point4_y + ' ' + point1_x + ',' + point4_y + ' ' + point6_x + ',' + point3_y;
    },
    generateUniqueID: function generateUniqueID() {
      return 'phase_' + this.index + '_0';
    },
    onMouseOver: function onMouseOver() {
      this.isHover = true;
      this.$emit('mouseover', this.index);
    },
    onMouseLeave: function onMouseLeave() {
      this.isHover = false;
      this.$emit('mouseleave', this.index);
    },
    showPhaseDetails: function showPhaseDetails() {
      if (!this.isLongPress) {
        this.$emit('click', this.index);
      }
    },
    onLongPressStart: function onLongPressStart() {
      if (!this.readOnly) {
        this.isLongPress = true;
        this.$emit('longpressstart', this.index);
      }
    },
    onLongPressStop: function onLongPressStop() {
      if (!this.readOnly) {
        this.isLongPress = false;
        this.$emit('longpressstop', this.index);
      }
    },
    collapsePhase: function collapsePhase() {
      this.isCollapse = true;
      this.$emit('collapsephase', this.index);
    },
    expandPhase: function expandPhase() {
      this.isCollapse = false;
      this.$emit('expandphase', this.index);
    },
    toggleFocusMode: function toggleFocusMode() {
      this.$emit('focusmode', this.phase, this.index);
    }
  }
};