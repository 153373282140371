"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-form", {
    ref: "form_step1",
    attrs: {
      model: _vm.form,
      "label-width": "210px",
      rules: _vm.ventureRulesStep1
    }
  }, [_c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.activeStep === "step1" ? _c("div", [_c("el-form-item", {
    attrs: {
      label: "Venture Name",
      prop: "title"
    }
  }, [_c("el-input", {
    attrs: {
      type: "TextField",
      maxlength: "48",
      size: "small"
    },
    model: {
      value: _vm.form.title,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "title", $$v);
      },
      expression: "form.title"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Venture Description",
      prop: "description"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      size: "small"
    },
    model: {
      value: _vm.form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "description", $$v);
      },
      expression: "form.description"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Proposed Customer Type",
      prop: "customer_type"
    }
  }, [_c("el-input", {
    attrs: {
      type: "TextField",
      maxlength: "48",
      size: "small"
    },
    model: {
      value: _vm.form.customer_type,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "customer_type", $$v);
      },
      expression: "form.customer_type"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Customer Type Description",
      prop: "customer_type_description"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      size: "small"
    },
    model: {
      value: _vm.form.customer_type_description,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "customer_type_description", $$v);
      },
      expression: "form.customer_type_description"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "text-right"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.goToStep("step2");
      }
    }
  }, [_vm._v("Next")])], 1)], 1) : _vm._e()])], 1), _vm._v(" "), _c("el-form", {
    ref: "form_step2",
    attrs: {
      model: _vm.form,
      "label-width": "200px",
      rules: _vm.ventureRulesStep2
    }
  }, [_c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.activeStep === "step2" ? _c("div", {
    staticStyle: {
      "animation-fill-mode": "forwards"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Problem",
      prop: "problemstatement"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      size: "small"
    },
    model: {
      value: _vm.form.problemstatement,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "problemstatement", $$v);
      },
      expression: "form.problemstatement"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Solution",
      prop: "solution"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      size: "small"
    },
    model: {
      value: _vm.form.solution,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "solution", $$v);
      },
      expression: "form.solution"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Value Proposition",
      prop: "valueproposition"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      size: "small"
    },
    model: {
      value: _vm.form.valueproposition,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "valueproposition", $$v);
      },
      expression: "form.valueproposition"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "text-right"
  }, [_c("el-button", {
    attrs: {
      type: "secondary",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.goToStep("step1");
      }
    }
  }, [_vm._v("Previous")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.goToStep("step3");
      }
    }
  }, [_vm._v("Next")])], 1)], 1) : _vm._e()]), _vm._v(" "), _c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.activeStep === "step3" ? _c("div", {
    staticStyle: {
      "animation-fill-mode": "forwards"
    }
  }, [_c("table", {
    staticStyle: {
      "table-layout": "fixed"
    },
    attrs: {
      width: "100%"
    }
  }, [_c("tr", {
    staticClass: "mt-10"
  }, [_c("th", {
    staticClass: "text-right summaryLabel pr-10"
  }, [_vm._v("Venture Title:")]), _vm._v(" "), _c("td", {
    staticClass: "summaryData"
  }, [_vm._v(_vm._s(_vm.form.title))])]), _vm._v(" "), _c("tr", {
    staticClass: "mt-10"
  }, [_c("th", {
    staticClass: "text-right summaryLabel pr-10"
  }, [_vm._v("Venture Description:")]), _vm._v(" "), _c("td", {
    staticClass: "summaryData"
  }, [_vm._v(_vm._s(_vm.form.description))])]), _vm._v(" "), _c("tr", {
    staticClass: "mt-10"
  }, [_c("th", {
    staticClass: "text-right summaryLabel pr-10"
  }, [_vm._v("Proposed Customer Type:")]), _vm._v(" "), _c("td", {
    staticClass: "summaryData"
  }, [_vm._v(_vm._s(_vm.form.customer_type))])]), _vm._v(" "), _c("tr", {
    staticClass: "mt-10"
  }, [_c("th", {
    staticClass: "text-right summaryLabel pr-10"
  }, [_vm._v("Customer Type Description:")]), _vm._v(" "), _c("td", {
    staticClass: "summaryData"
  }, [_vm._v(_vm._s(_vm.form.customer_type_description))])]), _vm._v(" "), _c("tr", {
    staticClass: "mt-10"
  }, [_c("th", {
    staticClass: "text-right summaryLabel pr-10"
  }, [_vm._v("Problem:")]), _vm._v(" "), _c("td", {
    staticClass: "summaryData"
  }, [_vm._v(_vm._s(_vm.form.problemstatement))])]), _vm._v(" "), _c("tr", {
    staticClass: "mt-10"
  }, [_c("th", {
    staticClass: "text-right summaryLabel pr-10"
  }, [_vm._v("Solution:")]), _vm._v(" "), _c("td", {
    staticClass: "summaryData"
  }, [_vm._v(_vm._s(_vm.form.solution))])]), _vm._v(" "), _c("tr", {
    staticClass: "mt-10"
  }, [_c("th", {
    staticClass: "text-right summaryLabel pr-10"
  }, [_vm._v("Value Proposition:")]), _vm._v(" "), _c("td", {
    staticClass: "summaryData summaryDataLastLine"
  }, [_vm._v(_vm._s(_vm.form.valueproposition))])])]), _vm._v(" "), _c("div", {
    staticClass: "text-right mt-20",
    staticStyle: {
      "margin-right": "0"
    }
  }, [_c("el-button", {
    attrs: {
      type: "secondary",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.goToStep("step2");
      }
    }
  }, [_vm._v("Previous")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.createVenture();
      }
    }
  }, [_vm._v("Create")])], 1)]) : _vm._e()])], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;