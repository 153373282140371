"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/taggedTemplateLiteral.js"));
var _axios = _interopRequireDefault(require("axios"));
var _templateObject;
var _default = exports.default = {
  name: 'AddRoadmap',
  components: {},
  props: {
    offeringId: {
      type: String,
      required: true,
      default: ''
    }
  },
  data: function data() {
    var validateTitle = function validateTitle(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter title'));
      } else {
        callback();
      }
    };
    var validateDescription = function validateDescription(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter description'));
      } else {
        callback();
      }
    };
    var validatePriority = function validatePriority(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter priority'));
      } else {
        callback();
      }
    };
    var validateApprover = function validateApprover(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter approver'));
      } else {
        callback();
      }
    };
    var validateStatus = function validateStatus(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter status'));
      } else {
        callback();
      }
    };
    var validateDate = function validateDate(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter date'));
      } else {
        callback();
      }
    };
    return {
      users: [],
      roadmapRules: {
        title: [{
          required: true,
          trigger: 'blur',
          validator: validateTitle
        }],
        description: [{
          required: true,
          trigger: 'blur',
          validator: validateDescription
        }],
        //priority: [{ required: true, trigger: 'blur', validator: validatePriority }],
        //status: [{ required: true, trigger: 'blur', validator: validateStatus }],
        //approvers: [{ required: true, trigger: 'blur', validator: validateApprover }],
        startdate: [{
          required: true,
          trigger: 'blur',
          validator: validateDate
        }],
        duedate: [{
          required: true,
          trigger: 'blur',
          validator: validateDate
        }]
      },
      loading: false,
      roadmap_form: {
        title: '',
        description: '',
        priority: 'Low',
        status: 'NoStatus',
        approvers: [],
        startdate: '',
        duedate: ''
      }
    };
  },
  computed: {},
  created: function created() {},
  mounted: function mounted() {
    this.resetForm();
    this.retrieveUsers();
  },
  methods: {
    closeDialog: function closeDialog() {
      this.$emit('close');
    },
    create: function create() {
      var _this = this;
      this.$refs['addRoadmapForm'].validate(function (valid) {
        if (valid) {
          _this.loading = true;
          var additionalattributes = {
            setting: {
              version: 1,
              roadmap: {},
              release: {
                timeline: 6,
                color: {
                  title: 'rgba(51, 152, 219, 1.0)',
                  column: 'rgba(51, 152, 219, 0.01)'
                }
              },
              increment: {
                prefix: 'increment',
                timeline: 1,
                delete: 'disabled',
                color: {
                  title: 'rgba(189, 195, 200, 1.0)',
                  column: 'rgba(15, 82, 186, 0.01)'
                }
              }
            }
          };
          _this.$apollo.mutate({
            // Query
            mutation: window._gql(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["mutation createOfferingRoadmap($roadmap: CreateOfferingRoadmapInput!)\n            {\n              createOfferingRoadmap(input: $roadmap) {\n                id\n                title\n                description\n                additionalattributes\n                approvers\n                status\n                priority\n                startdate\n                duedate\n                createdby\n                createdat\n                modifiedby\n                modifiedat\n                linkedReleases(optional: true) {\n                  offeringRoadmapRelease(optional: true) {\n                    id\n                    title\n                    description\n                    additionalattributes\n                    approvers\n                    status\n                    priority\n                    startdate\n                    duedate\n                    createdby\n                    createdat\n                    modifiedby\n                    modifiedat\n                  }\n                }\n                linkedOfferings(optional: true) {\n                  offering(optional: true) {\n                    id\n                  }\n                }\n              }\n            }"]))),
            // Parameters
            variables: {
              'roadmap': {
                'offeringId': _this.offeringId,
                'title': _this.roadmap_form.title,
                'description': _this.roadmap_form.description,
                'status': _this.roadmap_form.status,
                'priority': _this.roadmap_form.priority,
                'startdate': _this.roadmap_form.startdate !== '' ? _this.$moment(_this.roadmap_form.startdate, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss') : '',
                'duedate': _this.roadmap_form.duedate !== '' ? _this.$moment(_this.roadmap_form.duedate, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss') : '',
                'additionalattributes': additionalattributes
              }
            }
          }).then(function (data) {
            _this.$emit('create', data.data.createOfferingRoadmap);
            _this.resetForm();
            _this.loading = false;
          }).catch(function (error) {
            // Error
            _this.$notify({
              title: 'Error',
              message: error,
              type: 'error',
              duration: 2000
            });
            _this.loading = false;
          });
        } else {
          // this.loading = false
          return false;
        }
      });
    },
    retrieveUsers: function retrieveUsers() {
      var _this2 = this;
      this.loading = true;
      var headers = {
        'Content-Type': 'application/json',
        'x-party-id': this.$store.getters.partyId,
        'x-user-id': this.$store.getters.id
      };
      _axios.default.get(process.env.VUE_APP_REST_API_ENDPOINT + 'user/?extended=LEVEL0&deleted=false', {
        headers: headers
      }).then(function (response) {
        _this2.loading = false;
        _this2.users = response.data.data;
      }).catch(function (error) {
        if (error.response.status === 400) {
          _this2.loading = false;
          _this2.$notify({
            title: 'Error',
            message: error.response.message,
            type: 'error',
            duration: 2000
          });
        }
      });
    },
    resetForm: function resetForm() {
      this.roadmap_form = {
        title: '',
        description: '',
        priority: 'Low',
        status: 'NoStatus',
        approvers: [],
        startdate: '',
        duedate: ''
      };
    }
  }
};