"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  name: 'IdeateFeature',
  data: function data() {
    return {
      addFeatureDialog: false,
      activeArtefactDrag: {
        element: '',
        index: 0
      },
      activeDragElement: {},
      feature_form: {
        key: 0,
        candidate_feature: '',
        value: 0,
        priority: 0,
        pain_points: [],
        pleasers: []
      },
      pain_points: [{
        key: 0,
        pain_point: 'Pain Point A',
        description: 'Description',
        value: 3,
        priority: 1,
        relations: []
      }, {
        key: 1,
        pain_point: 'Pain Point B',
        description: 'Description',
        value: 2,
        priority: 5,
        relations: []
      }, {
        key: 2,
        pain_point: 'Pain Point C',
        description: 'Description',
        value: 1,
        priority: 1,
        relations: []
      }],
      pleasers: [{
        key: 0,
        pleaser: 'Pleaser D',
        description: 'Description',
        value: 3,
        priority: 1,
        relations: []
      }, {
        key: 1,
        pleaser: 'Pleaser E',
        description: 'Description',
        value: 2,
        priority: 1,
        relations: []
      }, {
        key: 2,
        pleaser: 'Pleaser F',
        description: 'Description',
        value: 1,
        priority: 1,
        relations: []
      }],
      candidate_features: [{
        key: 0,
        candidate_feature: 'Feature G',
        value: 3,
        priority: 1,
        pain_points: [],
        pleasers: []
      }, {
        key: 1,
        candidate_feature: 'Feature H',
        value: 2,
        priority: 1,
        pain_points: [],
        pleasers: []
      }, {
        key: 2,
        candidate_feature: 'Feature I',
        value: 1,
        priority: 1,
        pain_points: [],
        pleasers: []
      }]
    };
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    removeFeatureCard: function removeFeatureCard(index) {
      // Update count in painpoint/pleaser table
      if (this.candidate_features[index].pain_points.length > 0) {
        for (var j = 0; j < this.pain_points.length; j++) {
          if (this.pain_points[j].relations.length > 0) {
            for (var k = 0; k < this.pain_points[j].relations.length; k++) {
              if (this.candidate_features[index].key === this.pain_points[j].relations[k].candidate_feature_key) {
                this.pain_points[j].relations.splice(k, 1);
              }
            }
          }
        }
      }
      if (this.candidate_features[index].pleasers.length > 0) {
        for (var l = 0; l < this.pleasers.length; l++) {
          if (this.pleasers[l].relations.length > 0) {
            for (var m = 0; m < this.pleasers[l].relations.length; m++) {
              if (this.candidate_features[index].key === this.pleasers[l].relations[m].candidate_feature_key) {
                this.pleasers[l].relations.splice(m, 1);
              }
            }
          }
        }
      }

      // Remove feature card
      this.candidate_features.splice(index, 1);
    },
    addFeature: function addFeature() {
      var feature = Object.assign({}, this.feature_form);
      feature.key = this.candidate_features.length;
      this.candidate_features.push(feature);
      this.addFeatureDialog = false;
    },
    onSubmit: function onSubmit() {},
    setActiveArtefactDragElement: function setActiveArtefactDragElement(element, index) {
      this.activeDragElement = {};
      this.activeArtefactDrag.element = element;
      this.activeArtefactDrag.index = index;
      if (this.activeArtefactDrag.element === 'pain_point') {
        this.activeDragElement = this.pain_points[index];
      } else {
        this.activeDragElement = this.pleasers[index];
      }
    },
    getPriorityIcon: function getPriorityIcon(priority) {
      return 'priority-' + priority;
    },
    getValueIcon: function getValueIcon(value) {
      return 'dollar-' + value;
    },
    handleDrop: function handleDrop(event) {
      event.preventDefault(); // !important
      if (this.activeArtefactDrag.element === 'pain_point') {
        this.candidate_features[event.toElement.id].pain_points.push(this.activeDragElement);
        this.pain_points[this.activeArtefactDrag.index].relations.push({
          candidate_feature_key: this.candidate_features[event.toElement.id].key
        });
      } else {
        this.candidate_features[event.toElement.id].pleasers.push(this.activeDragElement);
        this.pleasers[this.activeArtefactDrag.index].relations.push({
          candidate_feature_key: this.candidate_features[event.toElement.id].key
        });
      }
    },
    handleDragOver: function handleDragOver(event) {
      event.preventDefault(); // !important
    },
    removePainPoint: function removePainPoint(candidate_feature_index, pain_point_index) {
      this.candidate_features[candidate_feature_index].pain_points.splice(pain_point_index, 1);
      for (var i = 0; i < this.pain_points[pain_point_index].relations.length; i++) {
        if (this.pain_points[pain_point_index].relations[i].candidate_feature_key === this.candidate_features[candidate_feature_index].key) {
          this.pain_points[pain_point_index].relations.splice(i, 1);
        }
      }
    },
    removePleaser: function removePleaser(candidate_feature_index, pleaser_index) {
      this.candidate_features[candidate_feature_index].pleasers.splice(pleaser_index, 1);
      for (var i = 0; i < this.pleasers[pleaser_index].relations.length; i++) {
        if (this.pleasers[pleaser_index].relations[i].candidate_feature_key === this.candidate_features[candidate_feature_index].key) {
          this.pleasers[pleaser_index].relations.splice(i, 1);
        }
      }
    }
  }
};