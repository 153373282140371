"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/taggedTemplateLiteral.js"));
var _ListItems = _interopRequireDefault(require("@/components/ListItems"));
var _templateObject;
var _default = exports.default = {
  name: 'DeliverRisk',
  components: {
    ItemList: _ListItems.default
  },
  props: {
    offering: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      loading: false,
      risks: [],
      selectedRisk: {},
      risks_total: 0
    };
  },
  mounted: function mounted() {
    this.$eventHub.$on('offering-changed', this.reloadOfferings);
    this.retrieveRisk(1);
  },
  methods: {
    reloadOfferings: function reloadOfferings() {
      this.retrieveRisk(1);
    },
    selectRisk: function selectRisk(risk, index) {
      this.selectedRisk = risk;
    },
    retrieveRisk: function retrieveRisk(page_number) {
      var _this = this;
      this.loading = true;
      this.$apollo.query({
        // Query
        query: window._gql(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)([" query Risks($page: Page)\n        {\n        Risks(page: $page)\n        {\n          pages\n          total\n          select{\n            id\n            title\n            description\n            severity\n            occuranceprobability\n            mitigationplan\n            impactlevel\n            additionalattributes\n          }\n        }\n      }"]))),
        fetchPolicy: 'no-cache',
        variables: {
          'page': {
            'limit': 20,
            'start': page_number
          }
        }
      }).then(function (data) {
        _this.risks = data.data.Risks.select;
        _this.risks_total = data.data.Risks.total;
        _this.selectedRisk = _this.risks[0];
        _this.loading = false;
      }).catch(function (error) {
        // Error
        _this.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this.loading = false;
      });
    }
  }
};