"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-card", {
    staticStyle: {
      "min-height": "800px",
      margin: "-16pt -0pt 0pt -10pt",
      padding: "2pt"
    }
  }, [_c("div", {
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("el-row", [_c("el-col", {
    staticStyle: {
      "padding-top": "10px"
    },
    attrs: {
      span: 4
    }
  }, [_c("strong", [_vm._v("Business Process Designer")])]), _vm._v(" "), _c("el-col", {
    staticClass: "text-right",
    attrs: {
      span: 20
    }
  }, [_c("ul", {
    staticClass: "buttons text-right"
  }, [_c("li", [_c("a", {
    ref: "saveDiagram",
    attrs: {
      href: "javascript:",
      title: "download BPMN diagram"
    }
  }, [_c("i", {
    staticClass: "el-icon-download"
  }), _vm._v(" BPMN diagram")]), _vm._v(" "), _c("a", {
    ref: "saveSvg",
    attrs: {
      href: "javascript:",
      title: "download as SVG image"
    }
  }, [_c("i", {
    staticClass: "el-icon-download"
  }), _vm._v(" SVG image")])]), _vm._v(" "), _c("li", [_c("input", {
    ref: "file",
    staticStyle: {
      display: "none"
    },
    attrs: {
      type: "file",
      accept: ".bpmn"
    },
    on: {
      change: function change($event) {
        return _vm.uploadFile();
      }
    }
  }), _vm._v(" "), _c("el-dropdown", {
    attrs: {
      size: "mini",
      "split-button": "",
      type: "primary"
    }
  }, [_vm._v("\n              Menu\n              "), _c("el-dropdown-menu", {
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, [_c("el-dropdown-item", [_c("el-button", {
    attrs: {
      type: "text",
      title: "New Diagram",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.createNewDiagram();
      }
    }
  }, [_vm._v("New diagram")])], 1), _vm._v(" "), _c("el-dropdown-item", [_c("el-button", {
    attrs: {
      type: "text",
      title: "Save to Prodiggi",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.confirmAndSaveBPMNToProdiggi();
      }
    }
  }, [_vm._v("Save to prodiggi")])], 1), _vm._v(" "), _c("el-dropdown-item", [_c("el-button", {
    attrs: {
      type: "text",
      size: "mini",
      title: "Open file from local"
    },
    on: {
      click: function click($event) {
        return _vm.$refs.file.click();
      }
    }
  }, [_vm._v("Open file from local ")])], 1), _vm._v(" "), _c("el-dropdown-item", [_c("el-button", {
    attrs: {
      type: "text",
      size: "mini",
      title: "Load Template"
    },
    on: {
      click: function click($event) {
        return _vm.loadDiagram();
      }
    }
  }, [_vm._v("Load sample file ")])], 1)], 1)], 1), _vm._v(" "), _vm.filterBPMNFiles.length > 0 ? _c("el-dropdown", {
    attrs: {
      size: "mini",
      "split-button": "",
      type: "primary"
    }
  }, [_vm._v("\n              Open file from prodiggi\n              "), _c("el-dropdown-menu", {
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, _vm._l(_vm.filterBPMNFiles, function (file, index) {
    return _c("el-dropdown-item", {
      key: index
    }, [_c("el-button-group", [_c("el-button", {
      attrs: {
        size: "mini",
        type: "text",
        icon: "el-icon-picture-outline"
      },
      on: {
        click: function click($event) {
          return _vm.openFileFromS3(file);
        }
      }
    }, [_vm._v(_vm._s(file.fileName))]), _vm._v(" "), _c("el-button", {
      attrs: {
        size: "mini",
        type: "text",
        icon: "el-icon-edit"
      },
      on: {
        click: function click($event) {
          return _vm.openFileFromS3(file);
        }
      }
    }), _vm._v(" "), _c("el-button", {
      attrs: {
        size: "mini",
        type: "text",
        icon: "el-icon-delete"
      },
      on: {
        click: function click($event) {
          return _vm.deleteFileFromS3AndCheckConfirmation(file, true);
        }
      }
    })], 1)], 1);
  }), 1)], 1) : _vm._e()], 1), _vm._v(" "), _c("li"), _vm._v(" "), _c("li", [_c("el-button-group", [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.zoomOut();
      }
    }
  }, [_vm._v("Zoom Out")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.zoomIn();
      }
    }
  }, [_vm._v("Zoom In")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.zoomReset();
      }
    }
  }, [_vm._v("Reset")])], 1)], 1)])])], 1)], 1), _vm._v(" "), _c("div", {
    ref: "content",
    staticClass: "containers",
    staticStyle: {
      zoom: "75%",
      border: "solid 1pt lightgrey",
      "border-radius": "3pt",
      padding: "0",
      margin: "0"
    }
  }, [_c("div", {
    ref: "canvas",
    staticClass: "canvas"
  })])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;