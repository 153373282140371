"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default2 = exports.default = {
  name: 'SubEntityDetail',
  components: {},
  props: {
    subEntity: {
      type: Object,
      default: function _default() {
        return {
          title: '',
          description: '',
          style: {
            color: ''
          }
        };
      }
    }
  },
  data: function data() {
    return {
      loading: false
    };
  },
  computed: {},
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    unlink: function unlink() {
      var _this = this;
      this.$confirm('This will permanently unlink feature/process from timeline. Continue?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(function () {
        _this.$emit('unlink', _this.subEntity.id, _this.subEntity.__typename);
      }).catch(function () {
        _this.$message({
          type: 'info',
          message: 'Delete canceled'
        });
      });
    },
    computeDate: function computeDate(date) {
      return this.$moment(date).format('DD-MMM-YYYY');
    },
    closeDialog: function closeDialog() {
      this.$emit('close');
    },
    truncateString: function truncateString(string, length) {
      return this._.truncate(string, {
        'length': length,
        'separator': '...'
      });
    }
  }
};