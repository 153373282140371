"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _tinymceVue = _interopRequireDefault(require("@tinymce/tinymce-vue"));
var _default2 = exports.default = {
  name: 'Tinymce',
  components: {
    Editor: _tinymceVue.default
  },
  props: {
    id: {
      type: String,
      default: function _default() {
        return 'vue-tinymce-' + +new Date() + ((Math.random() * 1000).toFixed(0) + '');
      }
    },
    value: {
      type: String,
      default: ''
    },
    upload: {
      type: Boolean,
      required: false,
      default: true
    },
    toolbar: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    menubar: {
      type: String,
      default: 'file edit insert view format table'
    },
    height: {
      type: [Number, String],
      required: false,
      default: 360
    },
    width: {
      type: [Number, String],
      required: false,
      default: 'auto'
    }
  },
  data: function data() {
    return {
      hasChange: false,
      hasInit: false,
      tinymceId: this.id,
      fullscreen: false,
      languageTypeList: {
        'en': 'en'
      }
    };
  },
  computed: {
    computeInit: function computeInit() {
      return {
        toolbar: 'undo redo | blocks fontsize | H1 H2 H3 P HR |bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | help',
        menubar: '',
        height: this.height,
        content_style: "p {font-size: 10pt !important;} .mce-content-body{background-color: #fffef8; color: darkslategrey}",
        setup: function setup(editor) {
          editor.ui.registry.addButton('hr', {
            icon: 'horizontal-rule',
            // You can customize the icon if needed
            tooltip: 'Insert Horizontal Line',
            onAction: function onAction() {
              editor.execCommand('InsertHorizontalRule');
            }
          });
          editor.ui.registry.addButton('h1', {
            text: 'H1',
            onAction: function onAction() {
              editor.execCommand('FormatBlock', false, 'h1');
            }
          });
          editor.ui.registry.addButton('h2', {
            text: 'H2',
            onAction: function onAction() {
              editor.execCommand('FormatBlock', false, 'h2');
            }
          });
          editor.ui.registry.addButton('h3', {
            text: 'H3',
            onAction: function onAction() {
              editor.execCommand('FormatBlock', false, 'h3');
            }
          });
          editor.ui.registry.addButton('p', {
            text: 'P',
            onAction: function onAction() {
              editor.execCommand('FormatBlock', false, 'p');
            }
          });
        }
      };
    },
    containerWidth: function containerWidth() {
      var width = this.width;
      if (/^[\d]+(\.[\d]+)?$/.test(width)) {
        // matches `100`, `'100'`
        return "".concat(width, "px");
      }
      return width;
    },
    textvalue: {
      // getter
      get: function get() {
        return this.value;
      },
      // setter
      set: function set(newValue) {
        this.value = newValue;
        this.$emit('update', newValue);
      }
    }
  },
  mounted: function mounted() {},
  activated: function activated() {},
  deactivated: function deactivated() {},
  destroyed: function destroyed() {},
  methods: {
    handlerFunction: function handlerFunction(value) {
      console.log(value);
    }
  }
};