"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "errPage-container"
  }, [_c("el-button", {
    staticClass: "pan-back-btn",
    attrs: {
      icon: "el-icon-arrow-left"
    },
    on: {
      click: _vm.back
    }
  }, [_vm._v("\n    返回\n  ")]), _vm._v(" "), _c("el-row", [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("h1", {
    staticClass: "text-jumbo text-ginormous"
  }, [_vm._v("\n        Oops!\n      ")]), _vm._v("\n      gif source"), _c("a", {
    attrs: {
      href: "https://zh.airbnb.com/",
      target: "_blank"
    }
  }, [_vm._v("airbnb")]), _vm._v("page\n      "), _c("h2", [_vm._v("You don't have permission to go to this page")]), _vm._v(" "), _c("h6", [_vm._v("If you are not satisfied, please contact admin:")]), _vm._v(" "), _c("ul", {
    staticClass: "list-unstyled"
  }, [_c("li", {
    staticClass: "link-type"
  }, [_c("router-link", {
    attrs: {
      to: "/"
    }
  }, [_vm._v("\n            Back to homepage\n          ")])], 1), _vm._v(" "), _c("li", [_c("a", {
    attrs: {
      href: "#"
    },
    on: {
      click: function click($event) {
        $event.preventDefault();
        _vm.dialogVisible = true;
      }
    }
  }, [_vm._v("Click to see the picture")])])])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("img", {
    attrs: {
      src: _vm.errGif,
      width: "313",
      height: "428",
      alt: "Girl has dropped her ice cream."
    }
  })])], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      visible: _vm.dialogVisible,
      title: "Hi"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("img", {
    staticClass: "pan-img",
    attrs: {
      src: _vm.ewizardClap
    }
  })])], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;