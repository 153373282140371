"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.array.find");
var _konva = _interopRequireDefault(require("konva"));
var width = window.innerWidth;
var height = window.innerHeight;
var _default = exports.default = {
  data: function data() {
    return {
      stageSize: {
        width: width,
        height: height
      },
      rectangles: [{
        rotation: 0,
        x: 10,
        y: 10,
        width: 100,
        height: 100,
        scaleX: 1,
        scaleY: 1,
        fill: 'red',
        name: 'rect1',
        draggable: true
      }, {
        rotation: 0,
        x: 150,
        y: 150,
        width: 100,
        height: 100,
        scaleX: 1,
        scaleY: 1,
        fill: 'green',
        name: 'rect2',
        draggable: true
      }],
      selectedShapeName: ''
    };
  },
  methods: {
    handleTransformEnd: function handleTransformEnd(e) {
      var _this = this;
      // shape is transformed, let us save new attrs back to the node
      // find element in our state
      var rect = this.rectangles.find(function (r) {
        return r.name === _this.selectedShapeName;
      });
      // update the state
      rect.x = e.target.x();
      rect.y = e.target.y();
      rect.rotation = e.target.rotation();
      rect.scaleX = e.target.scaleX();
      rect.scaleY = e.target.scaleY();

      // change fill
      rect.fill = _konva.default.Util.getRandomColor();
    },
    handleStageMouseDown: function handleStageMouseDown(e) {
      // clicked on stage - clear selection
      if (e.target === e.target.getStage()) {
        this.selectedShapeName = '';
        this.updateTransformer();
        return;
      }

      // clicked on transformer - do nothing
      var clickedOnTransformer = e.target.getParent().className === 'Transformer';
      if (clickedOnTransformer) {
        return;
      }

      // find clicked rect by its name
      var name = e.target.name();
      var rect = this.rectangles.find(function (r) {
        return r.name === name;
      });
      if (rect) {
        this.selectedShapeName = name;
      } else {
        this.selectedShapeName = '';
      }
      this.updateTransformer();
    },
    updateTransformer: function updateTransformer() {
      // here we need to manually attach or detach Transformer node
      var transformerNode = this.$refs.transformer.getNode();
      var stage = transformerNode.getStage();
      var selectedShapeName = this.selectedShapeName;
      var selectedNode = stage.findOne('.' + selectedShapeName);
      // do nothing if selected node is already attached
      if (selectedNode === transformerNode.node()) {
        return;
      }
      if (selectedNode) {
        // attach to another node
        transformerNode.nodes([selectedNode]);
      } else {
        // remove transformer
        transformerNode.nodes([]);
      }
    }
  }
};