"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _default2 = exports.default = {
  name: 'Task',
  components: {},
  props: {
    x: {
      type: Number,
      default: 0
    },
    y: {
      type: Number,
      default: 0
    },
    index: {
      type: Number,
      default: 0
    },
    phaseIndex: {
      type: Number,
      default: 0
    },
    label: {
      type: String,
      default: ''
    },
    showLabel: {
      type: Boolean,
      default: false
    },
    priority: {
      type: String,
      default: 'Medium'
    },
    value: {
      type: String,
      default: 'Medium'
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ''
    },
    stepId: {
      type: String,
      default: ''
    },
    hover: {
      type: Boolean,
      default: false
    },
    isHighlight: {
      type: Boolean,
      default: false
    },
    ideas: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    isTool: {
      type: Boolean,
      default: false
    },
    position: {
      type: String,
      default: 'toolbox'
    },
    task: {
      type: Object,
      default: function _default() {
        return {
          title: '',
          description: '',
          relations: []
        };
      }
    }
  },
  data: function data() {
    return {
      isHover: false,
      isToolHover: false,
      isLongPress: false,
      isWorkflowLongPress: false
    };
  },
  computed: {
    computePosX: function computePosX() {
      if (this.isTool && this.position !== 'menubar') {
        return this.x - 20;
      } else if (this.isTool && this.position === 'menubar') {
        return this.x - 10;
      } else {
        return this.x;
      }
    },
    computeIconWidth: function computeIconWidth() {
      if (this.isTool && this.position !== 'menubar') {
        return 100;
      } else if (this.isTool && this.position === 'menubar') {
        return 90;
      }
      return 140;
    }
  },
  watch: {
    hover: function hover(val) {
      if (!val) {
        this.isHover = false;
      }
    }
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    computeBGStyle: function computeBGStyle() {
      if (this.isToolHover) {
        return 'background-color:rgba(200,200,200,0.1);border-radius:15px;border:1px solid #4672c4;';
      } else {
        return 'background-color:white;border-radius:15px;';
      }
    },
    computePriority: function computePriority() {
      switch (this.task.journeyStep.priority) {
        case 'Very Low':
          return 1;
        case 'Low':
          return 2;
        case 'Medium':
          return 3;
        case 'High':
          return 4;
        case 'Very High':
          return 5;
      }
    },
    computeCustomerValueIcon: function computeCustomerValueIcon() {
      switch (this.value) {
        case 'Very Low':
          return 'dollar';
        case 'Low':
          return 'dollar';
        case 'Medium':
          return 'dollar-2';
        case 'High':
          return 'dollar-2';
        case 'Very High':
          return 'dollar-3';
        default:
          return 'dollar';
      }
    },
    onTaskDragged: function onTaskDragged(_ref) {
      var el = _ref.el,
        deltaX = _ref.deltaX,
        deltaY = _ref.deltaY,
        offsetX = _ref.offsetX,
        offsetY = _ref.offsetY,
        clientX = _ref.clientX,
        clientY = _ref.clientY,
        first = _ref.first,
        last = _ref.last;
      // this.isLongPress is set to false before taskdragged ends. so end event is not being captured.
      // As long as drag is started in longpress end event capture without longpress chk should be fine
      if (last && !this.readOnly) {
        this.$emit('drag-task', this.index, el, deltaX, deltaY, offsetX, offsetY, clientX, clientY, first, last);
      }
      if (!this.readOnly && this.isLongPress) {
        this.$emit('drag-task', this.index, el, deltaX, deltaY, offsetX, offsetY, clientX, clientY, first, last);
      }
    },
    onSubmit: function onSubmit() {},
    emitClick: function emitClick() {
      this.$emit('click', this.index);
    },
    emitOnMouseOver: function emitOnMouseOver() {
      this.isHover = true;
      this.isToolHover = true;
      this.$emit('mouseover', this.index);
    },
    emitOnToolMouseLeave: function emitOnToolMouseLeave() {
      if (this.isTool) {
        this.isToolHover = false;
        this.$emit('toolmouseover', this.index);
      }
    },
    emitOnMouseLeave: function emitOnMouseLeave(emit) {
      this.isHover = false;
      if (emit) {
        this.$emit('mouseleave', this.index);
      }
    },
    generateUniqueID: function generateUniqueID() {
      return 'task_' + this.index;
    },
    onTaskWorkFlowDragged: function onTaskWorkFlowDragged(_ref2) {
      var el = _ref2.el,
        deltaX = _ref2.deltaX,
        deltaY = _ref2.deltaY,
        offsetX = _ref2.offsetX,
        offsetY = _ref2.offsetY,
        clientX = _ref2.clientX,
        clientY = _ref2.clientY,
        first = _ref2.first,
        last = _ref2.last;
      if (!this.readOnly) {
        this.$emit('drag-workflow', this.index, el, deltaX, deltaY, offsetX, offsetY, clientX, clientY, first, last);
      }
    },
    onLongPressStart: function onLongPressStart() {
      this.isLongPress = true;
    },
    onLongPressStop: function onLongPressStop() {
      this.isLongPress = false;
    },
    onLongPressWorkflowStart: function onLongPressWorkflowStart() {
      this.isWorkflowLongPress = true;
    },
    onLongPressWorkflowStop: function onLongPressWorkflowStop() {
      this.isWorkflowLongPress = false;
    },
    computeArrowStyle: function computeArrowStyle() {
      var opacity = 'opacity:1;';
      if (this.isWorkflowLongPress) {
        return 'font-size: 25px;color:red;' + opacity;
      } else {
        return 'font-size: 25px;color:#4672c4;' + opacity;
      }
    },
    /* This method determines the VIEW of the STEP  the user sees on the screen. Div sits inside RECT. */computeStyle: function computeStyle() {
      if (this.isTool) {
        if (this.isLongPress && !this.position === 'menubar') {
          return 'border-radius: 3pt; height: 20pt; background-color: aliceblue; width:65pt; color:#4672c4;border-color: aliceblue;border-style: solid;';
        } else {
          if (this.position === 'menubar') {
            return 'font-size: 16px; padding: 1pt; border-radius:15px; width: 27px; min-height: 20px';
          }
          return 'border-radius: 3pt; height: 20pt; background: rgb(255,255,255);background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(245,245,245,1) 100%); width:65pt; color:#4672c4';
        }
      } else {
        if (this.isLongPress) {
          return 'border-radius: 3pt; height: 20pt; background-color: aliceblue; width:95pt; color:#4672c4;';
        } else {
          return 'border-radius: 3pt; height: 20pt; background: rgb(255,255,255);background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(245,245,245,1) 100%); width:95pt; color:#4672c4;';
        }
      }
    },
    truncateString: function truncateString(string, length) {
      return this._.truncate(string, {
        'length': length
      });
    }
  }
};