"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.replace");
var _track = _interopRequireDefault(require("./components/roadmap/track"));
var _release = _interopRequireDefault(require("./components/roadmap/release"));
var _sprint = _interopRequireDefault(require("./components/roadmap/sprint"));
var _vueSwatches = _interopRequireDefault(require("vue-swatches"));
var _default = exports.default = {
  name: 'ProductRoadmap1',
  components: {
    RoadmapTrackComponent: _track.default,
    RoadmapReleaseComponent: _release.default,
    RoadmapSprintComponent: _sprint.default,
    Swatches: _vueSwatches.default
  },
  data: function data() {
    return {
      loading: false,
      addReleaseDialog: false,
      addTrackDialog: false,
      addSprintDialog: false,
      sprintDetailsDialog: false,
      addFeatureEnablerDialog: false,
      featureEnablersSearch: '',
      showOnlyStratergicFeatures: false,
      releaseDetailsDialog: false,
      activeRelease: {},
      activeSprint: {},
      features: [{
        name: 'Mendix',
        description: 'Cauliflower cheese cheesy grin stinking bishop. Boursin cheese and biscuits rubber cheese stilton cauliflower cheese dolcelatte swiss cheese and biscuits. Squirty cheese monterey jack danish fontina taleggio cream cheese mozzarella when the cheese comes out everybodys happy monterey jack. Manchego cheese and biscuits cheeseburger everyone loves.Cheese triangles babybel stinking bishop. Cheese on toast airedale cut the cheese melted cheese who moved my cheese babybel cheesy grin paneer. Bavarian bergkase edam queso stilton cheese and biscuits cheese on toast croque monsieur mozzarella. Brie pecorino cheddar pepper jack cheesecake',
        has_parent: true,
        enabler: true,
        value: 1,
        priority: 1,
        is_stratergic: true,
        status: 'Operational',
        first_added: '10-12-2016',
        improved_in: '10-12-2016',
        prime_user: {
          name: 'John Doe',
          design_goal: 'Lorem Ipsum',
          mockups: [{
            name: 'mockup 1'
          }, {
            name: 'mockup 2'
          }]
        },
        additional_users: [{
          name: 'Add John',
          design_goal: 'Lorem Design Ipsum'
        }, {
          name: 'Add John',
          design_goal: 'Lorem Design Ipsum'
        }],
        competitors: 'Dont Offer',
        other_users: '',
        created_by: '',
        assigned_to: '',
        assign_history: [{
          name: 'John Doe',
          status: 'QC',
          assigned_on: '11-10-2019'
        }, {
          name: 'John Doe',
          status: 'Business Design',
          assigned_on: '11-10-2019'
        }],
        prioritisation: {
          moscow: '123',
          wsjf: '456',
          ark: '789'
        },
        business_details: {
          scarcity: 'scarce',
          offered_by: [{
            name: 'Competitor A',
            quality: 'High'
          }, {
            name: 'Competitor B',
            quality: 'Medium'
          }],
          revenue: {
            method: 'type',
            monetary_potential: 'high'
          }
        },
        stories: [{
          name: 'Story A',
          description: 'Lorem Ipsum',
          enabler: true,
          value: 2,
          priority: 1,
          is_stratergic: true,
          status: 'Operational',
          first_added: '10-12-2016',
          improved_in: '10-12-2016',
          prime_user: {
            name: 'John Doe',
            design_goal: 'Lorem Ipsum'
          },
          other_users: '',
          created_by: 'john doe',
          assigned_to: 'justin',
          assign_history: [{
            name: 'John Doe',
            status: 'QC',
            assigned_on: '11-10-2019'
          }, {
            name: 'John Doe',
            status: 'Business Design',
            assigned_on: '11-10-2019'
          }],
          steps: [{
            name: 'Step A'
          }, {
            name: 'Step B'
          }, {
            name: 'Step C'
          }]
        }]
      }, {
        name: 'CodeRobots',
        description: 'Cauliflower cheese cheesy grin stinking bishop. Boursin cheese and biscuits rubber cheese stilton cauliflower cheese dolcelatte swiss cheese and biscuits. Squirty cheese monterey jack danish fontina taleggio cream cheese mozzarella when the cheese comes out everybodys happy monterey jack. Manchego cheese and biscuits cheeseburger everyone loves.Cheese triangles babybel stinking bishop. Cheese on toast airedale cut the cheese melted cheese who moved my cheese babybel cheesy grin paneer. Bavarian bergkase edam queso stilton cheese and biscuits cheese on toast croque monsieur mozzarella. Brie pecorino cheddar pepper jack cheesecake',
        has_parent: false,
        enabler: true,
        value: 2,
        priority: 5,
        is_stratergic: false,
        status: 'Candidate',
        first_added: '10-12-2016',
        improved_in: '10-12-2016',
        prime_user: {
          name: 'John Doe',
          design_goal: 'Lorem Ipsum',
          mockups: [{
            name: 'mockup 1'
          }, {
            name: 'mockup 2'
          }]
        },
        additional_users: [{
          name: 'Add John',
          design_goal: 'Lorem Design Ipsum'
        }, {
          name: 'Add John',
          design_goal: 'Lorem Design Ipsum'
        }],
        competitors: 'Commodity',
        other_users: '',
        created_by: '',
        assigned_to: '',
        assign_history: [{
          name: 'John Doe',
          status: 'QC',
          assigned_on: '11-10-2019'
        }, {
          name: 'John Doe',
          status: 'Business Design',
          assigned_on: '11-10-2019'
        }],
        prioritisation: {
          moscow: '',
          wsjf: '',
          ark: ''
        },
        business_details: {
          scarcity: 'scarce',
          offered_by: [{
            name: 'Competitor A',
            quality: 'High'
          }, {
            name: 'Competitor B',
            quality: 'Medium'
          }],
          revenue: {
            method: 'type',
            monetary_potential: 'high'
          }
        },
        stories: [{
          name: '',
          description: '',
          enabler: true,
          value: 1,
          priority: 1,
          is_stratergic: true,
          status: 'Operational',
          first_added: '10-12-2016',
          improved_in: '10-12-2016',
          prime_user: {
            name: 'John Doe',
            design_goal: 'Lorem Ipsum'
          },
          other_users: '',
          created_by: '',
          assigned_to: '',
          assign_history: [{
            name: 'John Doe',
            status: 'QC',
            assigned_on: '11-10-2019'
          }, {
            name: 'John Doe',
            status: 'Business Design',
            assigned_on: '11-10-2019'
          }],
          steps: [{
            name: 'Step A'
          }, {
            name: 'Step B'
          }, {
            name: 'Step C'
          }]
        }]
      }, {
        name: 'Story Teller',
        description: 'Cauliflower cheese cheesy grin stinking bishop. Boursin cheese and biscuits rubber cheese stilton cauliflower cheese dolcelatte swiss cheese and biscuits. Squirty cheese monterey jack danish fontina taleggio cream cheese mozzarella when the cheese comes out everybodys happy monterey jack. Manchego cheese and biscuits cheeseburger everyone loves.Cheese triangles babybel stinking bishop. Cheese on toast airedale cut the cheese melted cheese who moved my cheese babybel cheesy grin paneer. Bavarian bergkase edam queso stilton cheese and biscuits cheese on toast croque monsieur mozzarella. Brie pecorino cheddar pepper jack cheesecake',
        has_parent: true,
        enabler: true,
        value: 1,
        priority: 3,
        is_stratergic: true,
        status: 'Build',
        first_added: '10-12-2016',
        improved_in: '10-12-2016',
        prime_user: {
          name: 'John Doe',
          design_goal: 'Lorem Ipsum',
          mockups: [{
            name: 'mockup 1'
          }, {
            name: 'mockup 2'
          }]
        },
        additional_users: [{
          name: 'Add John',
          design_goal: 'Lorem Design Ipsum'
        }, {
          name: 'Add John',
          design_goal: 'Lorem Design Ipsum'
        }],
        competitors: 'Scarce',
        other_users: '',
        created_by: '',
        assigned_to: '',
        assign_history: [{
          name: 'John Doe',
          status: 'QC',
          assigned_on: '11-10-2019'
        }, {
          name: 'John Doe',
          status: 'Business Design',
          assigned_on: '11-10-2019'
        }],
        prioritisation: {
          moscow: '',
          wsjf: '',
          ark: ''
        },
        business_details: {
          scarcity: 'scarce',
          offered_by: [{
            name: 'Competitor A',
            quality: 'High'
          }, {
            name: 'Competitor B',
            quality: 'Medium'
          }],
          revenue: {
            method: 'type',
            monetary_potential: 'high'
          }
        },
        stories: [{
          name: 'Story C',
          description: 'Lorem ipsum',
          enabler: true,
          value: 3,
          priority: 1,
          is_stratergic: true,
          status: 'Operational',
          first_added: '10-12-2016',
          improved_in: '10-12-2016',
          prime_user: {
            name: 'John Doe',
            design_goal: 'Lorem Ipsum'
          },
          other_users: '',
          created_by: '',
          assigned_to: '',
          assign_history: [{
            name: 'John Doe',
            status: 'QC',
            assigned_on: '11-10-2019'
          }, {
            name: 'John Doe',
            status: 'Business Design',
            assigned_on: '11-10-2019'
          }],
          steps: [{
            name: 'Step A'
          }, {
            name: 'Step B'
          }, {
            name: 'Step C'
          }]
        }]
      }],
      release_form: {
        name: '',
        description: '',
        color: '',
        highlight: false,
        range: null
      },
      track_form: {
        name: '',
        description: '',
        color: '',
        highlight: false
      },
      sprint_form: {
        name: '',
        description: '',
        release: '',
        track: '',
        goal: '',
        mode: '',
        is_open: true,
        stage: '',
        range: [],
        daterange: {
          start: '',
          end: ''
        },
        features: [],
        enablers: []
      },
      roadmap_settings: {
        x_padding: 100,
        y_padding: 100,
        track_width: {
          Weekly: 75,
          Monthly: 75,
          Quaterly: 75,
          Yearly: 75
        },
        track_count: {
          Weekly: 16,
          Monthly: 16,
          Quaterly: 16,
          Yearly: 16
        }
      },
      track_names: [],
      roadmap_original_start_date: '',
      roadmap_mode: 'Monthly',
      roadmap: {
        start_date: '01-01-2021',
        releases: [{
          name: 'Release 1',
          description: 'lorem ipsum',
          start_date: '01-01-2021',
          end_date: '01-04-2021'
        }],
        tracks: [{
          name: 'Polish',
          description: 'lorem ipsum',
          color: '#ffffff',
          highlight: false
        }, {
          name: 'Backend',
          description: 'lorem ipsum',
          color: '#ffffff',
          highlight: false
        }, {
          name: 'API',
          description: 'lorem ipsum',
          color: '#ffffff',
          highlight: false
        }],
        sprints: [{
          name: 'Sprint 3',
          description: 'Lorem Ipsum',
          release: 'Release 1',
          track: 'Polish',
          goal: 'Lorem Ipsum',
          mode: 'manual',
          is_open: true,
          stage: 'alpha',
          daterange: {
            start: '01-01-2021',
            end: '01-03-2021'
          },
          features: [{
            id: 7,
            name: 'Feature 7'
          }, {
            id: 8,
            name: 'Feature 8'
          }, {
            id: 9,
            name: 'Feature 9'
          }],
          enablers: [{
            id: 7,
            name: 'Enabler 7'
          }, {
            id: 8,
            name: 'Enabler 8'
          }, {
            id: 9,
            name: 'Enabler 9'
          }]
        }]
      }
    };
  },
  computed: {
    computeTrackCount: function computeTrackCount() {
      return this.roadmap_settings.track_count[this.roadmap_mode];
    },
    computeTrackWidth: function computeTrackWidth() {
      return this.roadmap_settings.track_width[this.roadmap_mode];
    },
    filteredFeatures: function filteredFeatures() {
      var features = '';
      features = this.features.filter(this.searchFeatureFilter);
      features = features.filter(this.stratergicFilter);
      return features;
    }
  },
  watch: {
    roadmap_mode: function roadmap_mode(val) {
      this.computeTrackNames();
    }
  },
  mounted: function mounted() {
    this.computeTrackNames();
  },
  created: function created() {},
  methods: {
    // UI METHDOS
    setActiveDragElement: function setActiveDragElement() {
      console.log('Drag');
    },
    startReleaseMove: function startReleaseMove(_ref) {
      var el = _ref.el,
        deltaX = _ref.deltaX,
        deltaY = _ref.deltaY,
        offsetX = _ref.offsetX,
        offsetY = _ref.offsetY,
        clientX = _ref.clientX,
        clientY = _ref.clientY,
        first = _ref.first,
        last = _ref.last;
      var index = el.id.replace('release_', '');
      if (first) {
        this.original_start_date = this.roadmap.releases[index].start_date;
        this.original_end_date = this.roadmap.releases[index].end_date;
      }
      if (last) {
        this.original_start_date = '00-00-0000';
        this.original_end_date = '00-00-0000';
      }
      if (deltaX === undefined || deltaY === undefined) {
        return;
      }
      if (offsetX > 75 && offsetX / 75 - Math.floor(offsetX / 75) === 0 && Math.floor(offsetX / 75) > 0) {
        var updated_release_start_date = this.$moment(this.original_start_date, 'DD-MM-YYYY').add(Math.floor(offsetX / 75), 'M');
        var updated_release_end_date = this.$moment(this.original_end_date, 'DD-MM-YYYY').add(Math.floor(offsetX / 75), 'M');
        switch (this.roadmap_mode) {
          case 'Weekly':
            updated_release_start_date = this.$moment(this.original_start_date, 'DD-MM-YYYY').add(Math.floor(offsetX / 75), 'w');
            updated_release_end_date = this.$moment(this.original_end_date, 'DD-MM-YYYY').add(Math.floor(offsetX / 75), 'w');
            break;
          case 'Monthly':
            updated_release_start_date = this.$moment(this.original_start_date, 'DD-MM-YYYY').add(Math.floor(offsetX / 75), 'M');
            updated_release_end_date = this.$moment(this.original_end_date, 'DD-MM-YYYY').add(Math.floor(offsetX / 75), 'M');
            break;
          case 'Quaterly':
            updated_release_start_date = this.$moment(this.original_start_date, 'DD-MM-YYYY').add(Math.floor(offsetX / 75), 'Q');
            updated_release_end_date = this.$moment(this.original_end_date, 'DD-MM-YYYY').add(Math.floor(offsetX / 75), 'Q');
            break;
          case 'Yearly':
            updated_release_start_date = this.$moment(this.original_start_date, 'DD-MM-YYYY').add(Math.floor(offsetX / 75), 'y');
            updated_release_end_date = this.$moment(this.original_end_date, 'DD-MM-YYYY').add(Math.floor(offsetX / 75), 'y');
            break;
        }
        if (this.noReleaseOverlapForward(updated_release_end_date)) {
          this.roadmap.releases[index].start_date = updated_release_start_date;
          this.roadmap.releases[index].end_date = updated_release_end_date;
        }
      } else if (offsetX < -75 && offsetX / 75 - Math.floor(offsetX / 75) === 0 && Math.floor(offsetX / 75) < 0) {
        switch (this.roadmap_mode) {
          case 'Weekly':
            this.roadmap.releases[index].start_date = this.$moment(this.original_start_date, 'DD-MM-YYYY').subtract(Math.floor(Math.abs(offsetX) / 75), 'w');
            this.roadmap.releases[index].end_date = this.$moment(this.original_end_date, 'DD-MM-YYYY').subtract(Math.floor(Math.abs(offsetX) / 75), 'w');
            break;
          case 'Monthly':
            this.roadmap.releases[index].start_date = this.$moment(this.original_start_date, 'DD-MM-YYYY').subtract(Math.floor(Math.abs(offsetX) / 75), 'M');
            this.roadmap.releases[index].end_date = this.$moment(this.original_end_date, 'DD-MM-YYYY').subtract(Math.floor(Math.abs(offsetX) / 75), 'M');
            break;
          case 'Quaterly':
            this.roadmap.releases[index].start_date = this.$moment(this.original_start_date, 'DD-MM-YYYY').subtract(Math.floor(Math.abs(offsetX) / 75), 'Q');
            this.roadmap.releases[index].end_date = this.$moment(this.original_end_date, 'DD-MM-YYYY').subtract(Math.floor(Math.abs(offsetX) / 75), 'Q');
            break;
          case 'Yearly':
            this.roadmap.releases[index].start_date = this.$moment(this.original_start_date, 'DD-MM-YYYY').subtract(Math.floor(Math.abs(offsetX) / 75), 'y');
            this.roadmap.releases[index].end_date = this.$moment(this.original_end_date, 'DD-MM-YYYY').subtract(Math.floor(Math.abs(offsetX) / 75), 'y');
            break;
        }
      }
    },
    noReleaseOverlapForward: function noReleaseOverlapForward(date) {
      var month = parseInt(date.month()) + 2;
      var formatted_date = date.year() + '-' + month + '-' + date.day();
      for (var i = 0; i < this.roadmap.releases.length; i++) {
        if (this.$moment(formatted_date, 'YYYY-MM-DD').isBetween(this.roadmap.releases[i].start_date, this.roadmap.releases[i].end_date)) {
          return false;
        }
      }
      return true;
    },
    addFeatureEnabler: function addFeatureEnabler(index) {
      if (this.features[index].enabler) {
        if (!this.sprintHasEnabler(this.features[index].name)) {
          this.activeSprint.features.push({
            id: this.activeSprint.enablers.length,
            name: this.features[index].name
          });
        }
      } else {
        if (!this.sprintHasFeature(this.features[index].name)) {
          this.activeSprint.enablers.push({
            id: this.activeSprint.features.length,
            name: this.features[index].name
          });
        }
      }
    },
    getPriorityIcon: function getPriorityIcon(priority) {
      return 'priority-' + priority;
    },
    getValueIcon: function getValueIcon(value) {
      return 'dollar-' + value;
    },
    searchFeatureFilter: function searchFeatureFilter(data) {
      return !this.featureEnablersSearch || data.name.toLowerCase().includes(this.featureEnablersSearch.toLowerCase());
    },
    stratergicFilter: function stratergicFilter(data) {
      if (this.showOnlyStratergicFeatures) {
        return data.is_stratergic === true;
      } else {
        return true;
      }
    },
    removeEnabler: function removeEnabler(index) {
      this.activeSprint.enablers.splice(index, 1);
    },
    removeFeature: function removeFeature(index) {
      this.activeSprint.features.splice(index, 1);
    },
    showSprintDetailsDialog: function showSprintDetailsDialog(sprint) {
      this.activeSprint = sprint;
      this.sprintDetailsDialog = true;
    },
    getSprintX: function getSprintX(sprint, index) {
      for (var i = 0; i < this.roadmap.releases.length; i++) {
        if (this.roadmap.releases[i].name === sprint.release) {
          return this.computeReleaseX(i);
        }
      }
    },
    getSprintY: function getSprintY(sprint, index) {
      for (var i = 0; i < this.roadmap.tracks.length; i++) {
        if (this.roadmap.tracks[i].name === sprint.track) {
          return 100 + i * 175;
        }
      }
    },
    getSprintWidth: function getSprintWidth(sprint, index) {
      for (var i = 0; i < this.roadmap.releases.length; i++) {
        if (this.roadmap.releases[i].name === sprint.release) {
          return this.computeReleaseWidth(i);
        }
      }
    },
    addSprint: function addSprint() {
      var sprint_data = Object.assign({}, this.sprint_form);
      sprint_data.daterange.start = sprint_data.range[0];
      sprint_data.daterange.end = sprint_data.range[1];
      this.roadmap.sprints.push(sprint_data);
      this.resetSprintForm();
      this.addSprintDialog = false;
    },
    addTrack: function addTrack() {
      var track_data = Object.assign({}, this.track_form);
      var track = {
        name: track_data.name,
        description: track_data.description,
        color: track_data.color,
        highlight: false
      };
      this.roadmap.tracks.push(track);
      this.resetTrackForm();
      this.addTrackDialog = false;
    },
    addRelease: function addRelease() {
      var release_data = Object.assign({}, this.release_form);
      var release = {
        name: release_data.name,
        description: release_data.description,
        color: release_data.color,
        highlight: false,
        start_date: release_data.range[0],
        end_date: release_data.range[1]
      };
      this.roadmap.releases.push(release);
      this.resetReleaseForm();
      this.addReleaseDialog = false;
    },
    openReleaseDetailDialog: function openReleaseDetailDialog(release) {
      this.activeRelease = release;
      this.releaseDetailsDialog = true;
    },
    deleteRelease: function deleteRelease(index) {
      // Remove Any Release Elements
      this.deleteReleaseElements(index);
      // Remove Release
      this.roadmap.releases.splice(index, 1);
    },
    deleteTrack: function deleteTrack(index) {
      // Remove Any Track Elements
      this.deleteTrackElements(index);
      // Remove Track
      this.roadmap.tracks.splice(index, 1);
    },
    deleteReleaseElements: function deleteReleaseElements(release_index) {
      for (var i = 0; i < this.roadmap.sprints.length; i++) {
        if (this.roadmap.sprints[i].release === this.roadmap.releases[release_index].name) {
          this.roadmap.sprints.splice(i, 1);
        }
      }
    },
    deleteTrackElements: function deleteTrackElements(track_index) {
      for (var i = 0; i < this.roadmap.sprints.length; i++) {
        if (this.roadmap.sprints[i].track === this.roadmap.tracks[track_index].name) {
          this.roadmap.sprints.splice(i, 1);
        }
      }
    },
    deleteSprint: function deleteSprint(index) {
      this.roadmap.sprints.splice(index, 1);
    },
    scrollLeft: function scrollLeft() {
      switch (this.roadmap_mode) {
        case 'Weekly':
          this.roadmap.start_date = this.$moment(this.roadmap.start_date, 'DD-MM-YYYY').subtract(1, 'w').format('DD-MM-YYYY');
          break;
        case 'Monthly':
          this.roadmap.start_date = this.$moment(this.roadmap.start_date, 'DD-MM-YYYY').subtract(1, 'month').format('DD-MM-YYYY');
          break;
        case 'Quaterly':
          this.roadmap.start_date = this.$moment(this.roadmap.start_date, 'DD-MM-YYYY').subtract(1, 'Q').format('DD-MM-YYYY');
          break;
        case 'Yearly':
          this.roadmap.start_date = this.$moment(this.roadmap.start_date, 'DD-MM-YYYY').subtract(1, 'y').format('DD-MM-YYYY');
          break;
      }
      this.computeTrackNames();
    },
    scrollRight: function scrollRight() {
      switch (this.roadmap_mode) {
        case 'Weekly':
          this.roadmap.start_date = this.$moment(this.roadmap.start_date, 'DD-MM-YYYY').add(1, 'w').format('DD-MM-YYYY');
          break;
        case 'Monthly':
          this.roadmap.start_date = this.$moment(this.roadmap.start_date, 'DD-MM-YYYY').add(1, 'month').format('DD-MM-YYYY');
          break;
        case 'Quaterly':
          this.roadmap.start_date = this.$moment(this.roadmap.start_date, 'DD-MM-YYYY').add(1, 'Q').format('DD-MM-YYYY');
          break;
        case 'Yearly':
          this.roadmap.start_date = this.$moment(this.roadmap.start_date, 'DD-MM-YYYY').add(1, 'y').format('DD-MM-YYYY');
          break;
      }
      this.computeTrackNames();
    },
    resetScroll: function resetScroll() {
      this.roadmap.start_date = this.roadmap_original_start_date;
      this.computeTrackNames();
    },
    handleDrop: function handleDrop(event) {
      event.preventDefault(); // !important
      this.addSprintDialog = true;
    },
    handleDragOver: function handleDragOver(event) {
      event.preventDefault(); // !important
    },
    // UI METHODS END
    // GENERATED CONTENT
    generateReleaseID: function generateReleaseID(index) {
      return 'release_' + index;
    },
    // GENERATED CONTENT END
    // COMPUTED DETAILS
    computeReleaseX: function computeReleaseX(index) {
      var release_start = this.$moment(this.roadmap.releases[index].start_date, 'DD-MM-YYYY');
      var roadmap_start = this.$moment(this.roadmap.start_date, 'DD-MM-YYYY');
      return this.diffBetweenDates(roadmap_start, release_start) * 75 + 100;
    },
    computeReleaseWidth: function computeReleaseWidth(index) {
      var release_end = this.$moment(this.roadmap.releases[index].end_date, 'DD-MM-YYYY');
      var release_start = this.$moment(this.roadmap.releases[index].start_date, 'DD-MM-YYYY');
      var width = this.diffBetweenDates(release_start, release_end);
      if (width > 0 && width < this.roadmap_settings.track_count[this.roadmap_mode]) {
        return width * 75;
      } else {
        return 0;
      }
    },
    computeHorizontalTrackY: function computeHorizontalTrackY(index) {
      var y = this.roadmap_settings.y_padding;
      if (index > 0) {
        y = 0;
        for (var i = 0; i < index; i++) {
          y += this.computeTrackHeight(i) + 25;
        }
        y += this.roadmap_settings.y_padding;
      }
      return y;
    },
    computeTrackX1: function computeTrackX1(index) {
      return this.roadmap_settings.x_padding + this.roadmap_settings.track_width[this.roadmap_mode] * index;
    },
    computeTrackX2: function computeTrackX2(index) {
      return this.roadmap_settings.x_padding + this.roadmap_settings.track_width[this.roadmap_mode] * index;
    },
    computeReleaseHeight: function computeReleaseHeight() {
      var height = 0;
      for (var i = 0; i < this.roadmap.tracks.length; i++) {
        height += this.computeTrackHeight(i);
      }
      return height + 175;
    },
    computeTrackHeight: function computeTrackHeight(index) {
      // TODO add counter
      return 150;
    },
    computeTrackNames: function computeTrackNames() {
      var track_count = this.roadmap_settings.track_count[this.roadmap_mode];
      this.track_names = [];
      var start_date = this.$moment(this.roadmap.start_date, 'DD-MM-YYYY');
      switch (this.roadmap_mode) {
        case 'Weekly':
          this.track_names.push({
            line1: start_date.format('YYYY'),
            line2: start_date.format('W')
          });
          break;
        case 'Monthly':
          this.track_names.push({
            line1: start_date.format('YYYY'),
            line2: start_date.format('MMM')
          });
          break;
        case 'Quaterly':
          this.track_names.push({
            line1: start_date.format('YYYY'),
            line2: start_date.format('Qo')
          });
          break;
        case 'Yearly':
          this.track_names.push({
            line1: '',
            line2: start_date.format('YYYY')
          });
          break;
      }
      for (var i = 0; i < track_count; i++) {
        var name = '';
        switch (this.roadmap_mode) {
          case 'Weekly':
            name = start_date.add(1, 'w');
            this.track_names.push({
              line1: '',
              line2: name.format('W')
            });
            break;
          case 'Monthly':
            name = start_date.add(1, 'M');
            if (name.format('MMM') === 'Jan') {
              this.track_names.push({
                line1: name.format('YYYY'),
                line2: name.format('MMM')
              });
            } else {
              this.track_names.push({
                line1: '',
                line2: name.format('MMM')
              });
            }
            break;
          case 'Quaterly':
            name = start_date.add(1, 'Q');
            if (name.format('Qo') === '1st') {
              this.track_names.push({
                line1: name.format('YYYY'),
                line2: name.format('Qo')
              });
            } else {
              this.track_names.push({
                line1: '',
                line2: name.format('Qo')
              });
            }
            break;
          case 'Yearly':
            name = start_date.add(1, 'y').format('YYYY');
            this.track_names.push({
              line1: '',
              line2: name
            });
            break;
        }
      }
    },
    computeDefaultDateRange: function computeDefaultDateRange() {
      return this.$moment(this.roadmap.start_date, 'DD-MM-YYYY').format('YYYY-MM-DD');
    },
    generateSprintID: function generateSprintID(index) {
      return 'sprint_' + index;
    },
    // COMPUTED DETAILS END
    // HELPER CODE
    sprintHasEnabler: function sprintHasEnabler(enabler_name) {
      var has_enabler = false;
      for (var i = 0; i < this.activeSprint.enablers.length; i++) {
        if (this.activeSprint.enablers[i].name === enabler_name) {
          has_enabler = true;
        }
      }
      return has_enabler;
    },
    sprintHasFeature: function sprintHasFeature(feature_name) {
      var has_feature = false;
      for (var i = 0; i < this.activeSprint.features.length; i++) {
        if (this.activeSprint.features[i].name === feature_name) {
          has_feature = true;
        }
      }
      return has_feature;
    },
    diffBetweenDates: function diffBetweenDates(start_date, end_date) {
      switch (this.roadmap_mode) {
        case 'Weekly':
          return end_date.diff(start_date, 'w');
        case 'Monthly':
          return end_date.diff(start_date, 'M');
        case 'Quaterly':
          return end_date.diff(start_date, 'Q');
        case 'Yearly':
          return end_date.diff(start_date, 'y');
      }
    },
    resetTrackForm: function resetTrackForm() {
      this.track_form = {
        name: '',
        description: '',
        color: '',
        highlight: false
      };
    },
    resetReleaseForm: function resetReleaseForm() {
      this.release_form = {
        name: '',
        description: '',
        color: '',
        highlight: false,
        range: null
      };
    },
    resetSprintForm: function resetSprintForm() {
      this.sprint_form = {
        name: '',
        description: '',
        release: '',
        track: '',
        goal: '',
        mode: '',
        is_open: true,
        stage: '',
        range: [],
        daterange: {
          start: '',
          end: ''
        },
        features: [],
        enablers: []
      };
    } // HELPER CODE END
  }
};