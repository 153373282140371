"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-row", [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("div", {
    staticClass: "palette-header text-center"
  }, [_c("strong", [_vm._v("Journey "), _c("br"), _vm._v(" Artefacts")])])])], 1), _vm._v(" "), _c("div", {
    staticClass: "ujm-sidebar-container"
  }, [_c("div", {
    staticClass: "palette-tools"
  }, [_c("el-popover", {
    ref: "notespopover",
    attrs: {
      placement: "top",
      title: "Notes",
      width: "380",
      trigger: "hover",
      "popper-class": "word-break-keep-all",
      "open-delay": 400,
      "close-delay": 400,
      content: "Notes can be dragged on to phase/lanes or just any location on canvas"
    }
  }), _vm._v(" "), _c("i", {
    directives: [{
      name: "popover",
      rawName: "v-popover:notespopover",
      arg: "notespopover"
    }],
    staticClass: "el-icon-document mt-10",
    staticStyle: {
      "font-size": "21pt",
      color: "gold"
    },
    attrs: {
      draggable: ""
    },
    on: {
      drag: function drag($event) {
        return _vm.setActiveDragElement("notes");
      }
    }
  }), _vm._v(" "), _c("br"), _vm._v(" "), _c("span", {
    staticClass: "palette-text"
  }, [_vm._v(" Add Note ")])], 1), _vm._v(" "), _c("el-divider"), _vm._v(" "), _c("div", {
    staticClass: "palette-tools"
  }, [_c("el-popover", {
    ref: "phasespopover",
    attrs: {
      placement: "top",
      title: "Phases",
      width: "380",
      trigger: "hover",
      "popper-class": "word-break-keep-all",
      "open-delay": 400,
      "close-delay": 400,
      content: "Lorem Ipsum"
    }
  }), _vm._v(" "), _c("i", {
    directives: [{
      name: "popover",
      rawName: "v-popover:phasespopover",
      arg: "phasespopover"
    }],
    staticClass: "el-icon-s-unfold rotate90 mt-10",
    staticStyle: {
      "font-size": "21pt"
    },
    attrs: {
      draggable: ""
    },
    on: {
      drag: function drag($event) {
        return _vm.setActiveDragElement("phase");
      }
    }
  }), _vm._v(" "), _c("br"), _vm._v(" "), _c("span", {
    staticClass: "palette-text"
  }, [_vm._v(" Add Phase ")])], 1), _vm._v(" "), _c("el-divider"), _vm._v(" "), _c("div", {
    staticClass: "palette-tools"
  }, [_c("el-popover", {
    ref: "lanespopover",
    attrs: {
      placement: "top",
      title: "Lanes",
      width: "380",
      trigger: "hover",
      "popper-class": "word-break-keep-all",
      "open-delay": 400,
      "close-delay": 400,
      content: "Lorem Ipsum"
    }
  }), _vm._v(" "), _c("i", {
    directives: [{
      name: "popover",
      rawName: "v-popover:lanespopover",
      arg: "lanespopover"
    }],
    staticClass: "el-icon-s-unfold mt-10",
    staticStyle: {
      "font-size": "21pt"
    },
    attrs: {
      draggable: ""
    },
    on: {
      drag: function drag($event) {
        return _vm.setActiveDragElement("lane");
      }
    }
  }), _vm._v(" "), _c("br"), _vm._v(" "), _c("span", {
    staticClass: "palette-text"
  }, [_vm._v(" Add Row")])], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "ujm-sidebar-container mt-10"
  }, [_c("div", {
    staticClass: "palette-tools"
  }, [_c("el-popover", {
    ref: "approvalpopover",
    attrs: {
      placement: "top",
      title: "Notes",
      width: "380",
      trigger: "hover",
      "popper-class": "word-break-keep-all",
      "open-delay": 400,
      "close-delay": 400,
      content: "Approve/Reject Journey Map"
    }
  }), _vm._v(" "), _vm.journeyMapStatus === "unapproved" ? _c("i", {
    directives: [{
      name: "popover",
      rawName: "v-popover:approvalpopover",
      arg: "approvalpopover"
    }],
    staticClass: "el-icon-check mt-10",
    staticStyle: {
      "font-size": "21pt",
      color: "#13ce66"
    },
    on: {
      click: _vm.approveJourneyMap
    }
  }) : _c("i", {
    directives: [{
      name: "popover",
      rawName: "v-popover:approvalpopover",
      arg: "approvalpopover"
    }],
    staticClass: "el-icon-close mt-10",
    staticStyle: {
      "font-size": "21pt",
      color: "gold"
    },
    on: {
      click: _vm.revertJourneyMap
    }
  }), _vm._v(" "), _c("br"), _vm._v(" "), _vm.journeyMapStatus === "unapproved" ? _c("span", {
    staticClass: "palette-text"
  }, [_vm._v(" Approve ")]) : _c("span", {
    staticClass: "palette-text"
  }, [_vm._v(" Revert Approval ")])], 1), _vm._v(" "), _c("el-divider"), _vm._v(" "), _c("div", {
    staticClass: "palette-tools"
  }, [_c("el-popover", {
    ref: "notesviewpopover",
    attrs: {
      placement: "top",
      title: "Notes",
      width: "380",
      trigger: "hover",
      "popper-class": "word-break-keep-all",
      "open-delay": 400,
      "close-delay": 400,
      content: "View Notes list"
    }
  }), _vm._v(" "), _c("i", {
    directives: [{
      name: "popover",
      rawName: "v-popover:notesviewpopover",
      arg: "notesviewpopover"
    }],
    staticClass: "el-icon-document mt-10",
    staticStyle: {
      "font-size": "21pt",
      color: "#304174"
    },
    on: {
      click: _vm.viewNotes
    }
  }), _vm._v(" "), _c("br"), _vm._v(" "), _c("span", {
    staticClass: "palette-text"
  }, [_vm._v(" View Notes ")])], 1), _vm._v(" "), _c("el-divider"), _vm._v(" "), _c("div", {
    staticClass: "palette-tools"
  }, [_c("el-popover", {
    ref: "viewpopover",
    attrs: {
      placement: "top",
      title: "Notes",
      width: "380",
      trigger: "hover",
      "popper-class": "word-break-keep-all",
      "open-delay": 400,
      "close-delay": 400,
      content: "Change Map view to simple view"
    }
  }), _vm._v(" "), _c("i", {
    directives: [{
      name: "popover",
      rawName: "v-popover:viewpopover",
      arg: "viewpopover"
    }],
    staticClass: "el-icon-s-unfold mt-10",
    staticStyle: {
      "font-size": "21pt",
      color: "#304174"
    },
    on: {
      click: function click($event) {
        return _vm.simpleView();
      }
    }
  }), _vm._v(" "), _c("br"), _vm._v(" "), _c("span", {
    staticClass: "palette-text"
  }, [_vm._v(" Simple View ")])], 1), _vm._v(" "), _c("el-divider")], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;