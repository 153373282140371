"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("g", _vm._l(parseInt(_vm.count), function (arrow_index) {
    return _c("polygon", {
      key: arrow_index,
      staticClass: "domain_links",
      attrs: {
        points: _vm.computeArrowPoints(arrow_index)
      }
    });
  }), 0);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;