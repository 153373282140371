"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "pl-10 pr-10"
  }, [_c("el-row", [_c("el-col", {
    attrs: {
      xl: 12,
      lg: 24,
      md: 22,
      sm: 23,
      xs: 24
    }
  }, [_c("div", {
    staticClass: "pr-20"
  }, [_c("el-collapse", [_c("el-collapse-item", {
    attrs: {
      name: "1"
    }
  }, [_c("template", {
    slot: "title"
  }, [_c("span", {
    staticClass: "text-left"
  }, [_c("b", [_vm._v("Create an Executable Vision")])]), _vm._v(" "), _c("span", {
    staticClass: "text-left ml-10"
  }, [_c("i", {
    staticClass: "fa fa-question-circle"
  })])]), _vm._v(" "), _c("el-row", [_c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("div", {
    staticClass: "pr-50"
  }, [_c("h4", {
    staticStyle: {
      "margin-bottom": "4pt"
    }
  }, [_vm._v("\n                      Overview\n                    ")]), _vm._v(" "), _c("p", {
    staticClass: "vision-advice",
    staticStyle: {
      "margin-bottom": "4pt"
    }
  }, [_vm._v("\n                      The Strategy module defines high level vision, goals, objectives and initiatives. Once linked to design and roadmap delivery, strategy\n                      execution becomes transparent, measurable, reportable, and traceable\n                    ")]), _vm._v(" "), _c("p")])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 14
    }
  }, [_c("h4", {
    staticClass: "ml-10",
    staticStyle: {
      "margin-bottom": "4pt"
    }
  }, [_vm._v("\n                    Steps to create a strategy\n                  ")]), _vm._v(" "), _c("ul", [_c("li", {
    staticStyle: {
      "padding-bottom": "4pt",
      "padding-top": "0"
    }
  }, [_vm._v("\n                      Define the long term "), _c("b", [_vm._v("Vision")]), _vm._v(" of your business.\n                    ")]), _vm._v(" "), _c("li", {
    staticStyle: {
      "padding-bottom": "4pt",
      "padding-top": "0"
    }
  }, [_vm._v("\n                      Create "), _c("b", [_vm._v("Goals")]), _vm._v(" to describe specific parts of the vision. They may take years to achieve.\n                    ")]), _vm._v(" "), _c("li", {
    staticStyle: {
      "padding-bottom": "4pt",
      "padding-top": "0"
    }
  }, [_vm._v("\n                      Make goals more achievable through creating "), _c("b", [_vm._v("Objectives")]), _vm._v(".  Try to keep these under two years.\n                    ")]), _vm._v(" "), _c("li", {
    staticStyle: {
      "padding-bottom": "4pt",
      "padding-top": "0"
    }
  }, [_vm._v("\n                      Use "), _c("b", [_vm._v("Initiatives")]), _vm._v(" to create your goals. Try to make these at least 2 months.\n                    ")]), _vm._v(" "), _c("li", {
    staticStyle: {
      "padding-bottom": "4pt",
      "padding-top": "0"
    }
  }, [_vm._v("\n                      Link Objectives to "), _c("b", [_vm._v("Offerings")]), _vm._v(" and use Offering Roadmaps to track progress in more detail\n                    ")]), _vm._v(" "), _c("li", {
    staticStyle: {
      "padding-bottom": "4pt",
      "padding-top": "0"
    }
  }, [_vm._v("\n                      Use the "), _c("b", [_vm._v("Business Goal Roadmap")]), _vm._v(" to organise intiatives\n                    ")])])])], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      span: 24
    }
  }, [_c("h4", [_vm._v("\n                  Link Strategy with Design, Roadmaps and Delivery\n                ")])]), _vm._v(" "), _c("svg", {
    staticStyle: {
      "user-select": "none"
    },
    attrs: {
      height: "265px",
      width: "100%",
      xmlns: "http://www.w3.org/2000/svg",
      version: "1.1"
    }
  }, [_c("marker", {
    attrs: {
      id: "triangle",
      viewBox: "0 0 10 10",
      refX: "5",
      refY: "5",
      markerUnits: "strokeWidth",
      markerWidth: "10",
      markerHeight: "10",
      orient: "auto"
    }
  }, [_c("path", {
    attrs: {
      d: "M 0 0 L 10 5 L 0 10 ",
      fill: "#888",
      "fill-opacity": "1"
    }
  })]), _vm._v(" "), _c("marker", {
    attrs: {
      id: "dot",
      viewBox: "0 0 10 10",
      refX: "5",
      refY: "5",
      markerWidth: "12",
      markerHeight: "12",
      orient: "auto-start-reverse"
    }
  }, [_c("circle", {
    attrs: {
      cx: "5",
      cy: "5",
      r: "1",
      fill: "#888",
      "fill-opacity": "1"
    }
  })]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "vision"
    }
  }, [_c("rect", {
    attrs: {
      x: 10,
      y: 10,
      width: 100,
      height: 170,
      rx: "10",
      fill: "#fff",
      stroke: _vm.hover.type === "vision" ? "#2793b7" : "#2793b7",
      "stroke-width": _vm.hover.type === "vision" ? "5" : "3"
    },
    on: {
      mouseover: function mouseover($event) {
        return _vm.activeHover("vision", 0, 0);
      },
      mouseleave: function mouseleave($event) {
        return _vm.activeHover("", 0, 0);
      }
    }
  }), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "#000"
    },
    attrs: {
      x: 60,
      y: 30,
      "font-size": "1em",
      "text-anchor": "middle",
      "font-weight": "bold"
    },
    on: {
      mouseover: function mouseover($event) {
        return _vm.activeHover("vision", 0, 0);
      },
      mouseleave: function mouseleave($event) {
        return _vm.activeHover("", 0, 0);
      }
    }
  }, [_vm._v("\n                    Vision\n                  ")]), _vm._v(" "), _c("circle", {
    attrs: {
      cx: 60,
      cy: 100,
      r: 40,
      stroke: _vm.hover.type === "goal" ? "#2793b7" : "#2793b7",
      "stroke-width": _vm.hover.type === "goal" ? "3" : "1",
      fill: "#2793b7"
    },
    on: {
      mouseover: function mouseover($event) {
        return _vm.activeHover("goal", 0, 0);
      },
      mouseleave: function mouseleave($event) {
        return _vm.activeHover("", 0, 0);
      }
    }
  }), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "#000",
      color: "white"
    },
    attrs: {
      x: 60,
      y: 105,
      "font-size": "1em",
      "text-anchor": "middle",
      "font-weight": "bold"
    },
    on: {
      mouseover: function mouseover($event) {
        return _vm.activeHover("goal", 0, 0);
      },
      mouseleave: function mouseleave($event) {
        return _vm.activeHover("", 0, 0);
      }
    }
  }, [_vm._v("\n                    Goals\n                  ")])]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "goal-initiative-path"
    }
  }, [_c("path", {
    attrs: {
      d: _vm.generateBezierPath(100, 93, 195, 70),
      stroke: "#000",
      "stroke-width": "1",
      fill: "none",
      "marker-start": "url(#dot)",
      "marker-end": "url(#triangle)",
      "stroke-opacity": "0.9"
    }
  }), _vm._v(" "), _c("path", {
    attrs: {
      d: _vm.generateBezierPath(100, 107, 195, 130),
      stroke: "#000",
      "stroke-width": "1",
      fill: "none",
      "marker-start": "url(#dot)",
      "marker-end": "url(#triangle)",
      "stroke-opacity": "0.9"
    }
  })]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "vision_goal_details"
    }
  }, [_c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.hover.type === "vision" ? _c("foreignObject", {
    attrs: {
      x: 10,
      y: 200,
      width: 270,
      height: 200,
      fill: "none"
    }
  }, [_c("div", {
    staticClass: "svg-text fade-in"
  }, [_c("span", [_vm._v("The "), _c("strong", [_vm._v("Vision")]), _vm._v(" sets the long term strategic scene. The timeframe to achieve is likely to be multiple years")])])]) : _vm._e()]), _vm._v(" "), _c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.hover.type === "goal" ? _c("foreignObject", {
    attrs: {
      x: 10,
      y: 200,
      width: 270,
      height: 200,
      fill: "none"
    }
  }, [_c("div", {
    staticClass: "svg-text fade-in"
  }, [_c("span", [_c("strong", [_vm._v("Goals")]), _vm._v(" describe specific parts of the vision. The timeframe to achieve this is likely to be multiple years")])])]) : _vm._e()])], 1), _vm._v(" "), _c("g", {
    attrs: {
      refs: "initiatives"
    }
  }, [_c("g", {
    attrs: {
      refs: "initiative"
    }
  }, [_c("rect", {
    attrs: {
      x: 200,
      y: 10,
      width: 235,
      height: 80,
      rx: "10",
      fill: "#79d597",
      stroke: _vm.hover.type === "initiative1" ? "#36AB5D" : "#79d597",
      "stroke-width": _vm.hover.type === "initiative1" ? "3" : "1"
    },
    on: {
      mouseover: function mouseover($event) {
        return _vm.activeHover("initiative1", 0, 0);
      },
      mouseleave: function mouseleave($event) {
        return _vm.activeHover("", 0, 0);
      }
    }
  }), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "#000"
    },
    attrs: {
      x: 240,
      y: 55,
      "font-size": "1em",
      "font-weight": "bold",
      "text-anchor": "middle"
    },
    on: {
      mouseover: function mouseover($event) {
        return _vm.activeHover("initiative1", 0, 0);
      },
      mouseleave: function mouseleave($event) {
        return _vm.activeHover("", 0, 0);
      }
    }
  }, [_vm._v("\n                      Objective\n                    ")]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "sub_initiatives"
    }
  }, [_c("rect", {
    attrs: {
      x: 300,
      y: 13,
      width: 130,
      height: 35,
      rx: "10",
      fill: "#e6f7eb",
      stroke: _vm.hover.type === "sub_initiative1" ? "#36AB59" : "#e6f7eb",
      "stroke-width": _vm.hover.type === "sub_initiative1" ? "3" : "1"
    },
    on: {
      mouseover: function mouseover($event) {
        return _vm.activeHover("sub_initiative1", 0, 0);
      },
      mouseleave: function mouseleave($event) {
        return _vm.activeHover("", 0, 0);
      }
    }
  }), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "#000"
    },
    attrs: {
      x: 345,
      y: 35,
      "font-size": "0.9em",
      "font-weight": "bold",
      "text-anchor": "middle"
    },
    on: {
      mouseover: function mouseover($event) {
        return _vm.activeHover("sub_initiative1", 0, 0);
      },
      mouseleave: function mouseleave($event) {
        return _vm.activeHover("", 0, 0);
      }
    }
  }, [_vm._v("\n                        Initiative\n                      ")]), _vm._v(" "), _c("rect", {
    attrs: {
      x: 300,
      y: 52,
      width: 130,
      height: 35,
      rx: "10",
      fill: "#e6f7eb",
      stroke: _vm.hover.type === "sub_initiative2" ? "#36AB59" : "#e6f7eb",
      "stroke-width": _vm.hover.type === "sub_initiative2" ? "3" : "1"
    },
    on: {
      mouseover: function mouseover($event) {
        return _vm.activeHover("sub_initiative2", 0, 0);
      },
      mouseleave: function mouseleave($event) {
        return _vm.activeHover("", 0, 0);
      }
    }
  }), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "#000"
    },
    attrs: {
      x: 345,
      y: 75,
      "font-size": "0.9em",
      "font-weight": "bold",
      "text-anchor": "middle"
    },
    on: {
      mouseover: function mouseover($event) {
        return _vm.activeHover("sub_initiative2", 0, 0);
      },
      mouseleave: function mouseleave($event) {
        return _vm.activeHover("", 0, 0);
      }
    }
  }, [_vm._v("\n                        Initiative\n                      ")])])]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "initiative"
    }
  }, [_c("g", {
    attrs: {
      refs: "initiative"
    }
  }, [_c("rect", {
    attrs: {
      x: 200,
      y: 100,
      width: 235,
      height: 80,
      rx: "10",
      fill: "#79d597",
      stroke: _vm.hover.type === "initiative2" ? "#36AB5D" : "#79d597",
      "stroke-width": _vm.hover.type === "initiative2" ? "3" : "1"
    },
    on: {
      mouseover: function mouseover($event) {
        return _vm.activeHover("initiative2", 0, 0);
      },
      mouseleave: function mouseleave($event) {
        return _vm.activeHover("", 0, 0);
      }
    }
  }), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "#000"
    },
    attrs: {
      x: 240,
      y: 145,
      "font-size": "1em",
      "font-weight": "bold",
      "text-anchor": "middle"
    },
    on: {
      mouseover: function mouseover($event) {
        return _vm.activeHover("initiative2", 0, 0);
      },
      mouseleave: function mouseleave($event) {
        return _vm.activeHover("", 0, 0);
      }
    }
  }, [_vm._v("\n                        Objective\n                      ")]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "sub_initiatives"
    }
  }, [_c("rect", {
    attrs: {
      x: 300,
      y: 103,
      width: 130,
      height: 35,
      rx: "10",
      fill: "#e6f7eb",
      stroke: _vm.hover.type === "sub_initiative3" ? "#36AB59" : "#e6f7eb",
      "stroke-width": _vm.hover.type === "sub_initiative3" ? "3" : "1"
    },
    on: {
      mouseover: function mouseover($event) {
        return _vm.activeHover("sub_initiative3", 0, 0);
      },
      mouseleave: function mouseleave($event) {
        return _vm.activeHover("", 0, 0);
      }
    }
  }), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "#000"
    },
    attrs: {
      x: 345,
      y: 125,
      "font-size": "0.9em",
      "font-weight": "bold",
      "text-anchor": "middle"
    },
    on: {
      mouseover: function mouseover($event) {
        return _vm.activeHover("sub_initiative3", 0, 0);
      },
      mouseleave: function mouseleave($event) {
        return _vm.activeHover("", 0, 0);
      }
    }
  }, [_vm._v("\n                          Initiative\n                        ")]), _vm._v(" "), _c("rect", {
    attrs: {
      x: 300,
      y: 142,
      width: 130,
      height: 35,
      rx: "10",
      fill: "#e6f7eb",
      stroke: _vm.hover.type === "sub_initiative4" ? "#36AB59" : "#e6f7eb",
      "stroke-width": _vm.hover.type === "sub_initiative4" ? "3" : "1"
    },
    on: {
      mouseover: function mouseover($event) {
        return _vm.activeHover("sub_initiative4", 0, 0);
      },
      mouseleave: function mouseleave($event) {
        return _vm.activeHover("", 0, 0);
      }
    }
  }), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "#000"
    },
    attrs: {
      x: 345,
      y: 165,
      "font-size": "0.9em",
      "font-weight": "bold",
      "text-anchor": "middle"
    },
    on: {
      mouseover: function mouseover($event) {
        return _vm.activeHover("sub_initiative4", 0, 0);
      },
      mouseleave: function mouseleave($event) {
        return _vm.activeHover("", 0, 0);
      }
    }
  }, [_vm._v("\n                          Initiative\n                        ")])])])])]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "initiative-to-experience-offering-path"
    }
  }, [_c("path", {
    attrs: {
      d: _vm.generateBezierPath(435, 50, 530, 50),
      stroke: "#000",
      "stroke-width": "1",
      fill: "none",
      "marker-start": "url(#dot)",
      "marker-end": "url(#triangle)",
      "stroke-opacity": "0.9"
    }
  }), _vm._v(" "), _c("path", {
    attrs: {
      d: _vm.generateBezierPath(435, 50, 530, 140),
      stroke: "#000",
      "stroke-width": "1",
      fill: "none",
      "marker-start": "url(#dot)",
      "marker-end": "url(#triangle)",
      "stroke-opacity": "0.9"
    }
  })]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "initiatives_details"
    }
  }, [_c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.hover.type === "initiative1" ? _c("foreignObject", {
    attrs: {
      x: 200,
      y: 200,
      width: 237,
      height: 200,
      fill: "none"
    }
  }, [_c("div", {
    staticClass: "svg-text fade-in"
  }, [_c("span", [_c("strong", [_vm._v("Objectives")]), _vm._v(" are used to break goals into smaller, tactical parts. The timeframe is approximately a year")])])]) : _vm._e()]), _vm._v(" "), _c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.hover.type === "initiative2" ? _c("foreignObject", {
    attrs: {
      x: 200,
      y: 200,
      width: 237,
      height: 200,
      fill: "none"
    }
  }, [_c("div", {
    staticClass: "svg-text"
  }, [_c("span", [_c("strong", [_vm._v("Objectives")]), _vm._v(" are used to break goals into smaller, tactical parts. The timeframe is approximately a year")])])]) : _vm._e()]), _vm._v(" "), _c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.hover.type === "sub_initiative1" ? _c("foreignObject", {
    attrs: {
      x: 200,
      y: 200,
      width: 237,
      height: 200,
      fill: "none"
    }
  }, [_c("div", {
    staticClass: "svg-text"
  }, [_c("span", [_c("strong", [_vm._v("Initiatives")]), _vm._v(" break Objectives into day-to-day operations. They can be linked to design and delivery items")])])]) : _vm._e()]), _vm._v(" "), _c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.hover.type === "sub_initiative2" ? _c("foreignObject", {
    attrs: {
      x: 200,
      y: 200,
      width: 237,
      height: 200,
      fill: "none"
    }
  }, [_c("div", {
    staticClass: "svg-text"
  }, [_c("span", [_c("strong", [_vm._v("Initiatives")]), _vm._v(" break Objectives into day-to-day operations. They can be linked to design and delivery items")])])]) : _vm._e()]), _vm._v(" "), _c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.hover.type === "sub_initiative3" ? _c("foreignObject", {
    attrs: {
      x: 200,
      y: 200,
      width: 237,
      height: 200,
      fill: "none"
    }
  }, [_c("div", {
    staticClass: "svg-text"
  }, [_c("span", [_c("strong", [_vm._v("Initiatives")]), _vm._v(" break Objectivess into day-to-day operations. They can be linked to design and delivery items")])])]) : _vm._e()]), _vm._v(" "), _c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.hover.type === "sub_initiative4" ? _c("foreignObject", {
    attrs: {
      x: 200,
      y: 200,
      width: 237,
      height: 200,
      fill: "none"
    }
  }, [_c("div", {
    staticClass: "svg-text"
  }, [_c("span", [_c("strong", [_vm._v("Initiatives")]), _vm._v(" break Objectivess into day-to-day operations. They can be linked to design and delivery items")])])]) : _vm._e()])], 1), _vm._v(" "), _c("g", {
    attrs: {
      refs: "experience_offering"
    }
  }, [_c("g", [_c("rect", {
    attrs: {
      x: 535,
      y: 10,
      width: 235,
      height: 80,
      rx: "10",
      fill: "#b7dde8",
      stroke: _vm.hover.type === "experience" ? "#64B7CE" : "#b7dde8",
      "stroke-width": _vm.hover.type === "experience" ? "3" : "1"
    },
    on: {
      mouseover: function mouseover($event) {
        return _vm.activeHover("experience", 0, 0);
      },
      mouseleave: function mouseleave($event) {
        return _vm.activeHover("", 0, 0);
      }
    }
  }), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "#000"
    },
    attrs: {
      x: 530,
      y: 55,
      "font-size": "0.9em",
      "font-weight": "bold",
      "text-anchor": "start"
    },
    on: {
      mouseover: function mouseover($event) {
        return _vm.activeHover("experience", 0, 0);
      },
      mouseleave: function mouseleave($event) {
        return _vm.activeHover("", 0, 0);
      }
    }
  }, [_c("tspan", {
    attrs: {
      x: "550",
      dy: "-20"
    }
  }, [_vm._v("Experience")]), _vm._v(" "), _c("tspan", {
    attrs: {
      x: "550",
      dy: "20"
    }
  }, [_vm._v("Design &")]), _vm._v(" "), _c("tspan", {
    attrs: {
      x: "550",
      dy: "20"
    }
  }, [_vm._v("Research")])]), _vm._v(" "), _c("g", [_c("rect", {
    attrs: {
      x: 635,
      y: 13,
      width: 130,
      height: 35,
      rx: "10",
      fill: "#f1f9fb",
      stroke: _vm.hover.type === "needs_exploration" ? "#90D0E0" : "#f1f9fb",
      "stroke-width": _vm.hover.type === "needs_exploration" ? "3" : "1"
    },
    on: {
      mouseover: function mouseover($event) {
        return _vm.activeHover("needs_exploration", 0, 0);
      },
      mouseleave: function mouseleave($event) {
        return _vm.activeHover("", 0, 0);
      }
    }
  }), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "#000"
    },
    attrs: {
      x: 640,
      y: 35,
      "font-size": "0.9em",
      "font-weight": "bold",
      "text-anchor": "start"
    },
    on: {
      mouseover: function mouseover($event) {
        return _vm.activeHover("needs_exploration", 0, 0);
      },
      mouseleave: function mouseleave($event) {
        return _vm.activeHover("", 0, 0);
      }
    }
  }, [_vm._v("\n                        Needs Exploration\n                      ")]), _vm._v(" "), _c("rect", {
    attrs: {
      x: 635,
      y: 52,
      width: 130,
      height: 35,
      rx: "10",
      fill: "#f1f9fb",
      stroke: _vm.hover.type === "journey_design" ? "#90D0E0" : "#f1f9fb",
      "stroke-width": _vm.hover.type === "journey_design" ? "3" : "1"
    },
    on: {
      mouseover: function mouseover($event) {
        return _vm.activeHover("journey_design", 0, 0);
      },
      mouseleave: function mouseleave($event) {
        return _vm.activeHover("", 0, 0);
      }
    }
  }), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "#000"
    },
    attrs: {
      x: 640,
      y: 75,
      "font-size": "0.9em",
      "font-weight": "bold",
      "text-anchor": "start"
    },
    on: {
      mouseover: function mouseover($event) {
        return _vm.activeHover("journey_design", 0, 0);
      },
      mouseleave: function mouseleave($event) {
        return _vm.activeHover("", 0, 0);
      }
    }
  }, [_vm._v("\n                        Journey Design\n                      ")])])]), _vm._v(" "), _c("g", [_c("rect", {
    attrs: {
      x: 535,
      y: 100,
      width: 235,
      height: 80,
      rx: "10",
      fill: "#b7dde8",
      stroke: _vm.hover.type === "offering" ? "#64B7CE" : "#b7dde8",
      "stroke-width": _vm.hover.type === "offering" ? "3" : "1"
    },
    on: {
      mouseover: function mouseover($event) {
        return _vm.activeHover("offering", 0, 0);
      },
      mouseleave: function mouseleave($event) {
        return _vm.activeHover("", 0, 0);
      }
    }
  }), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "#000"
    },
    attrs: {
      x: 550,
      y: 145,
      "font-size": "0.9em",
      "font-weight": "bold",
      "text-anchor": "start"
    },
    on: {
      mouseover: function mouseover($event) {
        return _vm.activeHover("offering", 0, 0);
      },
      mouseleave: function mouseleave($event) {
        return _vm.activeHover("", 0, 0);
      }
    }
  }, [_c("tspan", {
    attrs: {
      x: "550",
      dy: "-20"
    }
  }, [_vm._v("Offerings:")]), _vm._v(" "), _c("tspan", {
    attrs: {
      x: "550",
      dy: "20"
    }
  }, [_vm._v("New or")]), _vm._v(" "), _c("tspan", {
    attrs: {
      x: "550",
      dy: "20"
    }
  }, [_vm._v("Improved")])]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "offering_design"
    }
  }, [_c("rect", {
    attrs: {
      x: 635,
      y: 103,
      width: 130,
      height: 35,
      rx: "10",
      fill: "#f1f9fb",
      stroke: _vm.hover.type === "offering_design" ? "#90D0E0" : "#f1f9fb",
      "stroke-width": _vm.hover.type === "offering_design" ? "3" : "1"
    },
    on: {
      mouseover: function mouseover($event) {
        return _vm.activeHover("offering_design", 0, 0);
      },
      mouseleave: function mouseleave($event) {
        return _vm.activeHover("", 0, 0);
      }
    }
  }), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "#000"
    },
    attrs: {
      x: 640,
      y: 130,
      "font-size": "0.9em",
      "font-weight": "bold",
      "text-anchor": "start"
    },
    on: {
      mouseover: function mouseover($event) {
        return _vm.activeHover("offering_design", 0, 0);
      },
      mouseleave: function mouseleave($event) {
        return _vm.activeHover("", 0, 0);
      }
    }
  }, [_vm._v("\n                        Offering Design\n                      ")]), _vm._v(" "), _c("rect", {
    attrs: {
      x: 635,
      y: 142,
      width: 130,
      height: 35,
      rx: "10",
      fill: "#f1f9fb",
      stroke: _vm.hover.type === "offering_design" ? "#90D0E0" : "#f1f9fb",
      "stroke-width": _vm.hover.type === "offering_design" ? "3" : "1"
    },
    on: {
      mouseover: function mouseover($event) {
        return _vm.activeHover("offering_design", 0, 0);
      },
      mouseleave: function mouseleave($event) {
        return _vm.activeHover("", 0, 0);
      }
    }
  }), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "#000"
    },
    attrs: {
      x: 640,
      y: 165,
      "font-size": "0.9em",
      "font-weight": "bold",
      "text-anchor": "start"
    },
    on: {
      mouseover: function mouseover($event) {
        return _vm.activeHover("feature_Story", 0, 0);
      },
      mouseleave: function mouseleave($event) {
        return _vm.activeHover("", 0, 0);
      }
    }
  }, [_vm._v("\n                        Features & Processes\n                      ")])])])]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "experience_offering_path"
    }
  }, [_c("path", {
    attrs: {
      d: _vm.generateBezierPath(690, 43, 690, 53),
      stroke: "#000",
      "stroke-width": "1",
      fill: "none",
      "marker-start": "url(#dot)",
      "marker-end": "url(#triangle)",
      "stroke-opacity": "0.9"
    }
  }), _vm._v(" "), _c("path", {
    attrs: {
      d: _vm.generateBezierPath(690, 85, 690, 110),
      stroke: "#000",
      "stroke-width": "1",
      fill: "none",
      "marker-start": "url(#dot)",
      "marker-end": "url(#triangle)",
      "stroke-opacity": "0.9"
    }
  })]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "experience_offering_details"
    }
  }, [_c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.hover.type === "experience" ? _c("foreignObject", {
    attrs: {
      x: 515,
      y: 200,
      width: 280,
      height: 200,
      fill: "none"
    }
  }, [_c("div", {
    staticClass: "svg-text fade-in"
  }, [_c("span", [_c("strong", [_vm._v("Experience")]), _vm._v(" (journey) research or design can be achieved with or without offering changes.")])])]) : _vm._e()]), _vm._v(" "), _c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.hover.type === "offering" ? _c("foreignObject", {
    attrs: {
      x: 515,
      y: 200,
      width: 280,
      height: 200,
      fill: "none"
    }
  }, [_c("div", {
    staticClass: "svg-text fade-in"
  }, [_c("span", [_c("strong", [_vm._v("Offerings")]), _vm._v(" consist of Products and or Services which are designed, then delivered using Offering Roadmaps. ")])])]) : _vm._e()]), _vm._v(" "), _c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.hover.type === "journey_design" ? _c("foreignObject", {
    attrs: {
      x: 515,
      y: 200,
      width: 280,
      height: 200,
      fill: "none"
    }
  }, [_c("div", {
    staticClass: "svg-text fade-in"
  }, [_c("span", [_c("strong", [_vm._v("Journey Design")]), _vm._v(" involves changing a journey, adding new stages, steps, or removing them.")])])]) : _vm._e()]), _vm._v(" "), _c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.hover.type === "needs_exploration" ? _c("foreignObject", {
    attrs: {
      x: 515,
      y: 200,
      width: 280,
      height: 200,
      fill: "none"
    }
  }, [_c("div", {
    staticClass: "svg-text fade-in"
  }, [_c("span", [_c("strong", [_vm._v("Needs Exploration")]), _vm._v(" represents researching the customer job to be done, which can change over time.")])])]) : _vm._e()]), _vm._v(" "), _c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.hover.type === "offering_design" ? _c("foreignObject", {
    attrs: {
      x: 515,
      y: 200,
      width: 280,
      height: 200,
      fill: "none"
    }
  }, [_c("div", {
    staticClass: "svg-text fade-in"
  }, [_c("span", [_c("strong", [_vm._v("Offering Design")]), _vm._v(" represents any activity related to improving an offering, or the product and services within it.")])])]) : _vm._e()]), _vm._v(" "), _c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.hover.type === "feature_Story" ? _c("foreignObject", {
    attrs: {
      x: 515,
      y: 200,
      width: 280,
      height: 200,
      fill: "none"
    }
  }, [_c("div", {
    staticClass: "svg-text fade-in"
  }, [_c("span", [_c("strong", [_vm._v("A full list")]), _vm._v(" of features & processes show 'what' the offering will have.")])])]) : _vm._e()])], 1), _vm._v(" "), _c("g", {
    attrs: {
      refs: "roadmaps"
    }
  }, [_c("rect", {
    attrs: {
      x: 870,
      y: 10,
      width: 235,
      height: 170,
      rx: "10",
      fill: "#bfbfd9",
      stroke: _vm.hover.type === "offeringroadmap" ? "#A5A5CA" : "#f3f3f8",
      "stroke-width": _vm.hover.type === "offeringroadmap" ? "3" : "1"
    },
    on: {
      mouseover: function mouseover($event) {
        return _vm.activeHover("offeringroadmap", 0, 0);
      },
      mouseleave: function mouseleave($event) {
        return _vm.activeHover("", 0, 0);
      }
    }
  }), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "#000"
    },
    attrs: {
      x: 1090,
      y: 85,
      "font-size": "0.9em",
      "font-weight": "bold",
      "text-anchor": "start"
    },
    on: {
      mouseover: function mouseover($event) {
        return _vm.activeHover("offeringroadmap", 0, 0);
      },
      mouseleave: function mouseleave($event) {
        return _vm.activeHover("", 0, 0);
      }
    }
  }, [_c("tspan", {
    attrs: {
      x: "880",
      dy: "0"
    }
  }, [_vm._v("Offering")]), _vm._v(" "), _c("tspan", {
    attrs: {
      x: "880",
      dy: "20"
    }
  }, [_vm._v("Roadmaps")])]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "roadmap_release"
    }
  }, [_c("rect", {
    attrs: {
      x: 970,
      y: 20,
      width: 130,
      height: 35,
      rx: "10",
      fill: "#f3f3f8",
      stroke: _vm.hover.type === "releases" ? "#A5A5CA" : "#f3f3f8",
      "stroke-width": _vm.hover.type === "releases" ? "3" : "1"
    },
    on: {
      mouseover: function mouseover($event) {
        return _vm.activeHover("releases", 0, 0);
      },
      mouseleave: function mouseleave($event) {
        return _vm.activeHover("", 0, 0);
      }
    }
  }), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "#000"
    },
    attrs: {
      x: 975,
      y: 40,
      "font-size": "0.9em",
      "font-weight": "bold",
      "text-anchor": "start"
    },
    on: {
      mouseover: function mouseover($event) {
        return _vm.activeHover("releases", 0, 0);
      },
      mouseleave: function mouseleave($event) {
        return _vm.activeHover("", 0, 0);
      }
    }
  }, [_vm._v("\n                      Roadmap Release\n                    ")])]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "roadmap_increment"
    }
  }, [_c("rect", {
    attrs: {
      x: 970,
      y: 75,
      width: 130,
      height: 35,
      rx: "10",
      fill: "#f3f3f8",
      stroke: _vm.hover.type === "increments" ? "#A5A5CA" : "#f3f3f8",
      "stroke-width": _vm.hover.type === "increments" ? "3" : "1"
    },
    on: {
      mouseover: function mouseover($event) {
        return _vm.activeHover("increments", 0, 0);
      },
      mouseleave: function mouseleave($event) {
        return _vm.activeHover("", 0, 0);
      }
    }
  }), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "#000"
    },
    attrs: {
      x: 975,
      y: 95,
      "font-size": "0.9em",
      "font-weight": "bold",
      "text-anchor": "start"
    },
    on: {
      mouseover: function mouseover($event) {
        return _vm.activeHover("increments", 0, 0);
      },
      mouseleave: function mouseleave($event) {
        return _vm.activeHover("", 0, 0);
      }
    }
  }, [_vm._v("\n                      Roadmap Increment\n                    ")])]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "feature_stories"
    }
  }, [_c("rect", {
    attrs: {
      x: 970,
      y: 130,
      width: 130,
      height: 35,
      rx: "10",
      fill: "#f3f3f8",
      stroke: _vm.hover.type === "features" ? "#A5A5CA" : "#f3f3f8",
      "stroke-width": _vm.hover.type === "features" ? "3" : "1"
    },
    on: {
      mouseover: function mouseover($event) {
        return _vm.activeHover("features", 0, 0);
      },
      mouseleave: function mouseleave($event) {
        return _vm.activeHover("", 0, 0);
      }
    }
  }), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "#000"
    },
    attrs: {
      x: 975,
      y: 155,
      "font-size": "0.9em",
      "font-weight": "bold",
      "text-anchor": "start"
    },
    on: {
      mouseover: function mouseover($event) {
        return _vm.activeHover("features", 0, 0);
      },
      mouseleave: function mouseleave($event) {
        return _vm.activeHover("", 0, 0);
      }
    }
  }, [_vm._v("\n                      Epics & Stories\n                    ")])])]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "release-decomposition-path"
    }
  }, [_c("path", {
    attrs: {
      d: _vm.generateBezierPath(1035, 50, 1035, 70),
      stroke: "#000",
      "stroke-width": "1",
      fill: "none",
      "marker-start": "url(#dot)",
      "marker-end": "url(#triangle)",
      "stroke-opacity": "0.9"
    }
  }), _vm._v(" "), _c("path", {
    attrs: {
      d: _vm.generateBezierPath(1035, 105, 1035, 125),
      stroke: "#000",
      "stroke-width": "1",
      fill: "none",
      "marker-start": "url(#dot)",
      "marker-end": "url(#triangle)",
      "stroke-opacity": "0.9"
    }
  })]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "roadmap_details"
    }
  }, [_c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.hover.type === "offeringroadmap" ? _c("foreignObject", {
    attrs: {
      x: 850,
      y: 200,
      width: 280,
      height: 200,
      fill: "none"
    }
  }, [_c("div", {
    staticClass: "svg-text fade-in"
  }, [_c("span", [_c("strong", [_vm._v("Offering Roadmaps")]), _vm._v(" show 'when' the offering is delivered in peices. It consists of "), _c("strong", [_vm._v("Releases")]), _vm._v(" and "), _c("strong", [_vm._v("Increments")]), _vm._v(".")])])]) : _vm._e()]), _vm._v(" "), _c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.hover.type === "releases" ? _c("foreignObject", {
    attrs: {
      x: 850,
      y: 200,
      width: 280,
      height: 200,
      fill: "none"
    }
  }, [_c("div", {
    staticClass: "svg-text fade-in"
  }, [_c("span", [_c("strong", [_vm._v("Roadmap Releases")]), _vm._v(" represent major new version, eg Windows 10 to 11. Releases are made up of "), _c("strong", [_vm._v("Increments")]), _vm._v(".")])])]) : _vm._e()]), _vm._v(" "), _c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.hover.type === "increments" ? _c("foreignObject", {
    attrs: {
      x: 850,
      y: 200,
      width: 280,
      height: 200,
      fill: "none"
    }
  }, [_c("div", {
    staticClass: "svg-text fade-in"
  }, [_c("span", [_c("strong", [_vm._v("Increments")]), _vm._v(" represent the smaller stages within a Release (2-6 months long). They are made up of  "), _c("strong", [_vm._v("Features")]), _vm._v(" and "), _c("strong", [_vm._v(" Stories.")])])])]) : _vm._e()]), _vm._v(" "), _c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.hover.type === "features" ? _c("foreignObject", {
    attrs: {
      x: 850,
      y: 200,
      width: 280,
      height: 200,
      fill: "none"
    }
  }, [_c("div", {
    staticClass: "svg-text fade-in"
  }, [_c("span", [_c("strong", [_vm._v("Integration")]), _vm._v(" with external systems  'Epics & Stories', linked to Prodiggi Features & Processes, can be incrementally delivered .")])])]) : _vm._e()])], 1), _vm._v(" "), _c("g", {
    attrs: {
      refs: "offering-design-to-offering-roadmap-path"
    }
  }, [_c("path", {
    attrs: {
      d: _vm.generateBezierPath(766, 160, 964, 147),
      stroke: "#000",
      "stroke-width": "1",
      fill: "none",
      "marker-start": "url(#dot)",
      "marker-end": "url(#triangle)",
      "stroke-opacity": "0.9"
    }
  })])])], 2)], 1)], 1)])], 1), _vm._v(" "), _c("el-row", [_c("el-col", {
    attrs: {
      xl: 10,
      lg: 14,
      md: 18,
      sm: 20,
      xs: 24
    }
  }, [_c("h4", [_vm._v("Business Vision")]), _vm._v(" "), _c("tinymce", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      value: _vm.vision_and_goals.product_vision,
      height: 500
    },
    on: {
      update: _vm.createUpdateVisionContent
    }
  }), _vm._v(" "), _c("el-button", {
    staticClass: "mt-10 fr",
    attrs: {
      type: "primary",
      size: "mini",
      icon: _vm.loading ? "el-icon-loading" : "el-icon-edit"
    },
    on: {
      click: _vm.createUpdateVision
    }
  }, [_vm._v("Update")])], 1)], 1)], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;