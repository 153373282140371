"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _objectSpread2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _vuex = require("vuex");
var _default2 = exports.default = {
  name: 'ExperienceBacklog',
  components: {},
  props: {
    customer: {
      type: Object,
      required: true,
      default: function _default() {
        return {
          id: '',
          title: '',
          description: '',
          tiers: '',
          rationale: '',
          priority: '',
          persona: '',
          additionalattributes: {},
          linkedJourneyMaps: []
        };
      }
    }
  },
  data: function data() {
    return {
      loading: false,
      viewType: 'steps',
      experiencesList: ['PAIN', 'GAIN', 'WOW'],
      refreshKey: 0,
      q: ''
    };
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['journeyMap'])), {}, {
    filteredStages: function filteredStages() {
      if (this.q !== '') {
        var self = this;
        return this.sortedStages.filter(function (stage) {
          return stage.title.indexOf(self.q) > -1;
        });
      }
      return this.sortedStages;
    },
    sortedStages: function sortedStages() {
      this.refreshKey;
      var stagesSortOrder = [];
      if (this.journeyMap.additionalattributes.layouts && this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages.length > 0 && this.journeyMap.linkedJourneyPhases.length > 0) {
        for (var i = 0; i < this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages.length; i++) {
          for (var j = 0; j < this.journeyMap.linkedJourneyPhases.length; j++) {
            if (this.journeyMap.linkedJourneyPhases[j].journeyPhase.id === this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[i].id && !this.journeyMap.linkedJourneyPhases[j].journeyPhase.isDeleted) {
              // eslint-disable-next-line vue/no-side-effects-in-computed-properties
              this.journeyMap.linkedJourneyPhases[j].journeyPhase.autorank = this.stageAutoRank(this.journeyMap.linkedJourneyPhases[j].journeyPhase.importance, this.journeyMap.linkedJourneyPhases[j].journeyPhase.priority);
              stagesSortOrder.push(this.journeyMap.linkedJourneyPhases[j].journeyPhase);
              break;
            }
          }
        }
      } else {
        for (var k = 0; k < this.journeyMap.linkedJourneyPhases.length; k++) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.journeyMap.linkedJourneyPhases[k].journeyPhase.autorank = this.stageAutoRank(this.journeyMap.linkedJourneyPhases[k].journeyPhase.importance, this.journeyMap.linkedJourneyPhases[k].journeyPhase.priority);
          if (!this.journeyMap.linkedJourneyPhases[k].journeyPhase.hasOwnProperty('isDeleted')) {
            stagesSortOrder.push(this.journeyMap.linkedJourneyPhases[k].journeyPhase);
          } else if (this.journeyMap.linkedJourneyPhases[k].journeyPhase.hasOwnProperty('isDeleted') && this.journeyMap.linkedJourneyPhases[k].journeyPhase.isDeleted === false) {
            stagesSortOrder.push(this.journeyMap.linkedJourneyPhases[k].journeyPhase);
            break;
          }
        }
      }
      return stagesSortOrder;
    },
    filteredTasks: function filteredTasks() {
      if (this.q !== '') {
        var self = this;
        return this.computedTaskList.filter(function (task) {
          return task.title.indexOf(self.q) > -1;
        });
      }
      return this.computedTaskList;
    },
    computedTaskList: function computedTaskList() {
      this.refreshKey;
      var stepList = [];
      if (this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages.length > 0 && this.journeyMap.linkedJourneyPhases.length > 0) {
        for (var i = 0; i < this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages.length; i++) {
          for (var j = 0; j < this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[i].steps.length; j++) {
            for (var k = 0; k < this.journeyMap.linkedJourneySteps.length; k++) {
              if (this.journeyMap.linkedJourneySteps[k].journeyStep.id === this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[i].steps[j].id && !this.journeyMap.linkedJourneySteps[k].journeyStep.isDeleted) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.journeyMap.linkedJourneySteps[k].journeyStep.autorank = this.stepAutoRank(this.journeyMap.linkedJourneySteps[k].journeyStep);
                stepList.push(this.journeyMap.linkedJourneySteps[k].journeyStep);
                break;
              }
            }
          }
        }
      } else {
        if (this.journeyMap && this.journeyMap.linkedJourneySteps) {
          for (var l = 0; l < this.journeyMap.linkedJourneySteps.length; l++) {
            if (!this.journeyMap.linkedJourneySteps[l].journeyStep.hasOwnProperty('isDeleted')) {
              // eslint-disable-next-line vue/no-side-effects-in-computed-properties
              this.journeyMap.linkedJourneySteps[l].journeyStep.autorank = this.stepAutoRank(this.journeyMap.linkedJourneySteps[l].journeyStep);
              stepList.push(this.journeyMap.linkedJourneySteps[l].journeyStep);
            } else if (this.journeyMap.linkedJourneySteps[l].journeyStep.hasOwnProperty('isDeleted') && this.journeyMap.linkedJourneySteps[l].journeyStep.isDeleted === false) {
              // eslint-disable-next-line vue/no-side-effects-in-computed-properties
              this.journeyMap.linkedJourneySteps[l].journeyStep.autorank = this.stepAutoRank(this.journeyMap.linkedJourneySteps[l].journeyStep);
              stepList.push(this.journeyMap.linkedJourneySteps[l].journeyStep);
              break;
            }
          }
        }
      }
      return stepList;
    },
    computedExperienceList: function computedExperienceList() {
      this.refreshKey;
      var experienceList = [];
      if (this.journeyMap && this.journeyMap.linkedJourneyExperiences) {
        for (var j = 0; j < this.journeyMap.linkedJourneyExperiences.length; j++) {
          if (!this.journeyMap.linkedJourneyExperiences[j].journeyExperience.hasOwnProperty('isDeleted')) {
            experienceList.push(this.journeyMap.linkedJourneyExperiences[j]);
          } else if (this.journeyMap.linkedJourneyExperiences[j].journeyExperience.hasOwnProperty('isDeleted') && this.journeyMap.linkedJourneyExperiences[j].journeyExperience.isDeleted === false) {
            experienceList.push(this.journeyMap.linkedJourneyExperiences[j]);
            break;
          }
        }
      }
      return experienceList;
    },
    filteredExperiences: function filteredExperiences() {
      var experiences = [];
      for (var i = 0; i < this.computedExperienceList.length; i++) {
        if (this.experiencesList.includes(this.computedExperienceList[i].journeyExperience.experiencetype)) {
          experiences.push(this.computedExperienceList[i]);
        }
      }
      return experiences;
    }
  }),
  watch: {
    journeyMap: {
      handler: function handler(newValue) {
        this.refreshKey++;
        if (newValue.id) {
          this.refreshKey++;
        }
        if (newValue.linkedJourneyPhases) {
          this.refreshKey++;
        }
        if (newValue.linkedJourneySteps) {
          this.refreshKey++;
        }
        if (newValue.linkedJourneyExperiences) {
          this.refreshKey++;
        }
        if (newValue.linkedBusinessIdeas) {
          this.refreshKey++;
        }
      },
      deep: true
    }
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    resetSearch: function resetSearch(val) {
      this.q = '';
    },
    deleteExperience: function deleteExperience(experience_id) {
      var _this = this;
      this.$confirm('This will permanently delete experience. Continue?', 'Warning', {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(function () {
        _this.$emit('delete-experience', experience_id);
      }).catch(function () {
        _this.$message({
          type: 'info',
          message: 'Delete canceled'
        });
      });
    },
    computeExperienceListForStep: function computeExperienceListForStep(step_id) {
      var experiences = [];
      for (var i = 0; i < this.journeyMap.additionalattributes.relations.length; i++) {
        if (this.journeyMap.additionalattributes.relations[i].end_type === 'Experience' && this.journeyMap.additionalattributes.relations[i].start_type === 'Step' && this.journeyMap.additionalattributes.relations[i].start_id === step_id) {
          experiences.push(this.getExperienceFromID(this.journeyMap.additionalattributes.relations[i].end_id));
        }
      }
      return experiences;
    },
    computeExperienceListForPhase: function computeExperienceListForPhase(phase_id) {
      var experiences = [];
      for (var i = 0; i < this.journeyMap.additionalattributes.relations.length; i++) {
        if (this.journeyMap.additionalattributes.relations[i].end_type === 'Experience' && this.journeyMap.additionalattributes.relations[i].start_type === 'Phase' && this.journeyMap.additionalattributes.relations[i].start_id === phase_id) {
          experiences.push(this.getExperienceFromID(this.journeyMap.additionalattributes.relations[i].end_id));
        }
      }
      return experiences;
    },
    getExperienceFromID: function getExperienceFromID(experience_id) {
      for (var i = 0; i < this.computedExperienceList.length; i++) {
        if (this.computedExperienceList[i].journeyExperience.id === experience_id) {
          return this.computedExperienceList[i];
        }
      }
    },
    getPhaseFromID: function getPhaseFromID(phase_id) {
      for (var i = 0; i < this.sortedStages.length; i++) {
        if (this.sortedStages[i].id === phase_id) {
          return this.sortedStages[i];
        }
      }
    },
    getPhaseIndexFromID: function getPhaseIndexFromID(phase_id) {
      for (var i = 0; i < this.sortedStages.length; i++) {
        if (this.sortedStages[i].id === phase_id) {
          return i;
        }
      }
    },
    movePhaseRelationStageUp: function movePhaseRelationStageUp(phase_index) {
      var temp = this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[phase_index - 1];
      this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[phase_index - 1] = this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[phase_index];
      this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[phase_index] = temp;
      this.refreshKey++;
      this.$store.dispatch('customerTypes/updateJourneyMap', this.journeyMap);
    },
    movePhaseRelationStageDown: function movePhaseRelationStageDown(phase_index) {
      var temp = this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[phase_index + 1];
      this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[phase_index + 1] = this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[phase_index];
      this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[phase_index] = temp;
      this.refreshKey++;
      this.$store.dispatch('customerTypes/updateJourneyMap', this.journeyMap);
    },
    moveStepRelationStageUp: function moveStepRelationStageUp(step_index, step_id) {
      var phase_index = this.computeStageIndexFromStepID(step_id);
      for (var i = 0; i < this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[phase_index].steps.length; i++) {
        if (this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[phase_index].steps[i].id === step_id) {
          var temp = this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[phase_index].steps[i - 1];
          this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[phase_index].steps[i - 1] = this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[phase_index].steps[i];
          this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[phase_index].steps[i] = temp;
          this.refreshKey++;
          this.$store.dispatch('customerTypes/updateJourneyMap', this.journeyMap);
          break;
        }
      }
    },
    moveStepRelationStageDown: function moveStepRelationStageDown(step_index, step_id) {
      var phase_index = this.computeStageIndexFromStepID(step_id);
      for (var i = 0; i < this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[phase_index].steps.length; i++) {
        if (this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[phase_index].steps[i].id === step_id) {
          var temp = this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[phase_index].steps[i + 1];
          this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[phase_index].steps[i + 1] = this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[phase_index].steps[i];
          this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[phase_index].steps[i] = temp;
          this.refreshKey++;
          this.$store.dispatch('customerTypes/updateJourneyMap', this.journeyMap);
          break;
        }
      }
    },
    moveExperienceRelationStageUp: function moveExperienceRelationStageUp(experience_index) {},
    moveExperienceRelationStageDown: function moveExperienceRelationStageDown(experience_index) {},
    computeExperienceTagType: function computeExperienceTagType(type) {
      switch (type) {
        case 'PAIN':
          return 'danger';
        case 'GAIN':
          return 'success';
        case 'WOW':
          return '';
      }
    },
    truncateDescription: function truncateDescription(description, length) {
      return this._.truncate(description, {
        'length': length,
        'separator': ' '
      });
    },
    computeStageTitleFromStepID: function computeStageTitleFromStepID(step_id) {
      for (var i = 0; i < this.journeyMap.additionalattributes.relations.length; i++) {
        if (this.journeyMap.additionalattributes.relations[i].start_type === 'Phase' && this.journeyMap.additionalattributes.relations[i].end_type === 'Step' && this.journeyMap.additionalattributes.relations[i].end_id === step_id) {
          return this.getPhaseFromID(this.journeyMap.additionalattributes.relations[i].start_id).title;
        }
      }
      return '';
    },
    computeStageIDFromStepID: function computeStageIDFromStepID(step_id) {
      for (var i = 0; i < this.journeyMap.additionalattributes.relations.length; i++) {
        if (this.journeyMap.additionalattributes.relations[i].start_type === 'Phase' && this.journeyMap.additionalattributes.relations[i].end_type === 'Step' && this.journeyMap.additionalattributes.relations[i].end_id === step_id) {
          return this.getPhaseFromID(this.journeyMap.additionalattributes.relations[i].start_id).id;
        }
      }
      return '';
    },
    computeStageFromStepID: function computeStageFromStepID(step_id) {
      for (var i = 0; i < this.journeyMap.additionalattributes.relations.length; i++) {
        if (this.journeyMap.additionalattributes.relations[i].start_type === 'Phase' && this.journeyMap.additionalattributes.relations[i].end_type === 'Step' && this.journeyMap.additionalattributes.relations[i].end_id === step_id) {
          return this.getPhaseFromID(this.journeyMap.additionalattributes.relations[i].start_id);
        }
      }
      return '';
    },
    computeStageIndexFromStepID: function computeStageIndexFromStepID(step_id) {
      for (var i = 0; i < this.journeyMap.additionalattributes.relations.length; i++) {
        if (this.journeyMap.additionalattributes.relations[i].start_type === 'Phase' && this.journeyMap.additionalattributes.relations[i].end_type === 'Step' && this.journeyMap.additionalattributes.relations[i].end_id === step_id) {
          return this.getPhaseIndexFromID(this.journeyMap.additionalattributes.relations[i].start_id);
        }
      }
      return 0;
    },
    stageAutoRank: function stageAutoRank(value, priority) {
      return this.computeValueToNumber(value) + this.computeValueToNumber(priority);
    },
    stepAutoRank: function stepAutoRank(step) {
      // var phase = this.computeStageFromStepID(step.id)
      return this.computeValueToNumber(step.importance) + this.computeValueToNumber(step.priority);
    },
    computeValueToNumber: function computeValueToNumber(value) {
      switch (value) {
        case 'Very High':
          return 5;
        case 'High':
          return 4;
        case 'Medium':
          return 3;
        case 'Low':
          return 2;
        case 'Very Low':
          return 1;
        default:
          return 0;
      }
    },
    computeTagType: function computeTagType(value) {
      switch (value) {
        case 'Very High':
          return 'danger';
        case 'High':
          return 'warning';
        case 'Medium':
          return 'info';
        case 'Low':
          return '';
        case 'Very Low':
          return 'success';
        default:
          return 'danger';
      }
    }
  }
};