"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/taggedTemplateLiteral.js"));
require("core-js/modules/es6.function.name");
var _objectSpread2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _interopRequireWildcard2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _vuex = require("vuex");
var _templateObject;
var _default = exports.default = {
  name: 'Delivery',
  components: {
    RoadmapComponent: function RoadmapComponent() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./roadmap.vue"));
      });
    },
    CurrentReleaseComponent: function CurrentReleaseComponent() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./current_release.vue"));
      });
    },
    ReleaseHistoryComponent: function ReleaseHistoryComponent() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./release_history.vue"));
      });
    },
    IntegratedDataComponent: function IntegratedDataComponent() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./integrated_data.vue"));
      });
    },
    RiskComponent: function RiskComponent() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./risk.vue"));
      });
    }
  },
  data: function data() {
    return {
      loading: false,
      activeRoute: 'roadmap',
      offerings: [],
      offerings_total: 0,
      selectedOffering: {},
      selectedOfferingIndex: 0
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['offeringId'])),
  created: function created() {},
  mounted: function mounted() {
    switch (this.$route.name) {
      case 'Roadmap':
        this.activeRoute = 'roadmap';
        break;
      case 'CurrentRelease':
        this.activeRoute = 'currentrelease';
        break;
      case 'PastRelease':
        this.activeRoute = 'pastrelease';
        break;
      case 'IntegratedData':
        this.activeRoute = 'integrateddata';
        break;
      case 'DeliverRisks':
        this.activeRoute = 'risk';
        break;
    }
    this.retrieveOfferings(1);
    this.$eventHub.$on('offering-changed', this.reloadOfferings);
  },
  methods: {
    reloadOfferings: function reloadOfferings() {
      this.retrieveOfferings(1);
    },
    retrieveOfferings: function retrieveOfferings(page_number) {
      var _this = this;
      this.$apollo.query({
        // Query
        query: window._gql(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)([" query Offerings($page: Page)\n\t\t\t\t{\n\t\t\t\tOfferings(page: $page)\n\t\t\t\t{\n\t\t\t\t\tpages\n\t\t\t\t\ttotal\n\t\t\t\t\tselect{\n\t\t\t\t\t\tid\n\t\t\t\t\t\ttitle\n\t\t\t\t\t\tdescription\n\t\t\t\t\t\tofferingtype\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}"]))),
        fetchPolicy: 'no-cache',
        variables: {
          'page': {
            'limit': 100,
            'start': page_number
          }
        }
      }).then(function (data) {
        _this.offerings = [];
        _this.offerings = data.data.Offerings.select;
        _this.offerings_total = data.data.Offerings.total;
        if (_this.offeringId) {
          for (var i = 0; i < _this.offerings.length; i++) {
            if (_this.offerings[i].id === _this.offeringId) {
              _this.selectedOffering = _this.offerings[i];
              _this.selectedOfferingIndex = i;
            }
          }
        } else {
          _this.selectedOffering = _this.offerings[_this.selectedOfferingIndex];
        }
      }).catch(function (error) {
        // Error
        _this.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
      });
    }
  }
};