"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  name: 'PostMVP',
  components: {},
  data: function data() {
    return {
      loading: false,
      displayPostMVPComponents: false
    };
  },
  computed: {},
  mounted: function mounted() {},
  methods: {
    broadcastEvent: function broadcastEvent(showMVP) {
      this.$store.dispatch('settings/changeSetting', {
        key: 'showMVP',
        value: this.displayPostMVPComponents
      });
    }
  }
};