"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_vm.isEdit && _vm.displayType === "card" ? _c("div", {
    staticClass: "text-right"
  }, [!_vm.fileLoading ? _c("input", {
    ref: "fileInput",
    staticStyle: {
      display: "none"
    },
    attrs: {
      id: "file",
      type: "file",
      accept: "image/*,application/pdf,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation",
      multiple: "multiple"
    },
    on: {
      change: function change($event) {
        return _vm.uploadFile();
      }
    }
  }) : _vm._e(), _vm._v(" "), _c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.fileLoading,
      expression: "fileLoading"
    }],
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.attachFiles
    }
  }, [_vm._v("Attach Files")]), _vm._v(" "), _vm.fileLoading ? _c("span", [_vm._v("Uploading...")]) : _vm._e()], 1) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "text-center"
  }, [_vm.fileList.length > 0 && _vm.displayType === "card" ? _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, _vm._l(_vm.fileList, function (file, index) {
    return _c("el-col", {
      key: index,
      attrs: {
        span: 4
      }
    }, [_c("el-card", {
      attrs: {
        "body-style": {
          padding: "0px"
        }
      }
    }, [_c("img", {
      staticClass: "image",
      attrs: {
        src: _vm.generateUrl(file)
      }
    }), _vm._v(" "), _c("div", {
      staticStyle: {
        padding: "14px"
      }
    }, [_c("span", {
      on: {
        click: function click($event) {
          return _vm.downloadFile(file);
        }
      }
    }, [_vm._v(_vm._s(file.fileName))]), _vm._v(" "), _c("div", {
      staticClass: "bottom clearfix"
    }, [_c("el-button", {
      staticClass: "button",
      attrs: {
        type: "text",
        size: "mini"
      },
      on: {
        click: function click($event) {
          return _vm.deleteFile(index, file);
        }
      }
    }, [_vm._v("Delete")])], 1)])])], 1);
  }), 1) : _vm.fileList.length > 0 && _vm.displayType === "table" ? _c("div", [_c("el-table", {
    attrs: {
      data: _vm.fileList,
      stripe: "",
      size: "mini"
    }
  }, [_c("el-table-column", {
    attrs: {
      width: "30"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("img", {
          staticClass: "image",
          staticStyle: {
            height: "20px"
          },
          attrs: {
            src: _vm.generateUrl(scope.row)
          }
        })];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Name"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("span", {
          on: {
            click: function click($event) {
              return _vm.downloadFile(scope.row);
            }
          }
        }, [_vm._v(_vm._s(scope.row.fileName))])];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      width: "120",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm.isEdit ? _c("i", {
          staticClass: "el-icon-delete",
          staticStyle: {
            color: "red"
          },
          on: {
            click: function click($event) {
              return _vm.deleteFile(scope.$index, scope.row);
            }
          }
        }) : _vm._e()];
      }
    }])
  }, [_c("template", {
    slot: "header"
  }, [_vm.isEdit ? _c("div", {
    staticClass: "text-right"
  }, [!_vm.fileLoading ? _c("input", {
    ref: "fileInput",
    staticStyle: {
      display: "none"
    },
    attrs: {
      id: "file",
      type: "file",
      accept: "image/*,application/pdf,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation",
      multiple: "multiple"
    },
    on: {
      change: function change($event) {
        return _vm.uploadFile();
      }
    }
  }) : _vm._e(), _vm._v(" "), _c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.fileLoading,
      expression: "fileLoading"
    }],
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.attachFiles
    }
  }, [_vm._v("Attach Files")]), _vm._v(" "), _vm.fileLoading ? _c("span", [_vm._v("Uploading...")]) : _vm._e()], 1) : _vm._e()])], 2)], 1)], 1) : _c("div", {
    staticClass: "text-center",
    staticStyle: {
      margin: "0pt 8pt 8pt 8pt",
      color: "darkred"
    }
  }, [_c("el-empty", {
    attrs: {
      "image-size": 50
    }
  }, [_vm.isEdit ? _c("div", {
    staticClass: "text-right"
  }, [!_vm.fileLoading ? _c("input", {
    ref: "fileInput",
    staticStyle: {
      display: "none"
    },
    attrs: {
      id: "file",
      type: "file",
      accept: "image/*,application/pdf,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation",
      multiple: "multiple"
    },
    on: {
      change: function change($event) {
        return _vm.uploadFile();
      }
    }
  }) : _vm._e(), _vm._v(" "), _c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.fileLoading,
      expression: "fileLoading"
    }],
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.attachFiles
    }
  }, [_vm._v("Attach Files")]), _vm._v(" "), _vm.fileLoading ? _c("span", [_vm._v("Uploading...")]) : _vm._e()], 1) : _vm._e()])], 1)], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;