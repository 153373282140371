"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _settings = _interopRequireDefault(require("@/settings"));
var title = _settings.default.title;
var state = {
  title: title
};
var _default = exports.default = {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data: function data() {
    return {
      title: state.title
    };
  },
  computed: {
    backend_build_no: function backend_build_no() {
      return process.env.VUE_APP_BACKEND_BUILD_NO;
    },
    backend_build_date: function backend_build_date() {
      return process.env.VUE_APP_BACKEND_BUILD_DATE;
    },
    frontend_build_no: function frontend_build_no() {
      return process.env.VUE_APP_FRONTEND_BUILD_NO;
    },
    frontend_build_date: function frontend_build_date() {
      return process.env.VUE_APP_FRONTEND_BUILD_DATE;
    }
  }
};