"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "home-dashboard-container",
    style: _vm.computeDashboardHeight
  }, [_c("el-row", {
    staticClass: "cards-no-bg",
    staticStyle: {
      "padding-top": "15px",
      "padding-bottom": "15px",
      "padding-left": "15px"
    }
  }, [_c("el-col", {
    staticStyle: {
      height: "10px"
    },
    attrs: {
      span: 12
    }
  }, [_c("div", [_c("span", {
    staticStyle: {
      "mix-blend-mode": "pass-through",
      "font-size": "24px",
      "font-weight": "500"
    }
  }, [_vm._v("Welcome " + _vm._s(_vm.firstName) + " " + _vm._s(_vm.lastName))])])]), _vm._v(" "), _c("el-col", {
    staticStyle: {
      height: "10px"
    },
    attrs: {
      span: 12
    }
  }, [_c("div")])], 1), _vm._v(" "), _c("el-row", {
    staticClass: "cards-no-bg",
    staticStyle: {
      "padding-top": "15px",
      "padding-bottom": "15px"
    }
  }, [_c("el-col", {
    staticStyle: {
      height: "100px"
    },
    attrs: {
      span: 6
    }
  }, [_c("div", {
    style: _vm.computeStyle("customertype", "#F5F0FA"),
    on: {
      click: function click($event) {
        return _vm.switchEntities("customertype");
      },
      mouseover: function mouseover($event) {
        _vm.customer_type_mouseover = true;
      },
      mouseleave: function mouseleave($event) {
        _vm.customer_type_mouseover = false;
      }
    }
  }, [_c("table", {
    staticStyle: {
      "border-spacing": "0px"
    }
  }, [_c("tr", [_c("td", {
    style: _vm.computeTotalCardStyle("customertype", "rebeccapurple")
  }, [_c("span", {
    staticStyle: {
      "font-weight": "500",
      "font-size": "60px",
      color: "#FFF"
    }
  }, [_vm._v(_vm._s(_vm.customer_types.length))])]), _vm._v(" "), _c("td", {
    staticStyle: {
      height: "100px",
      "border-radius": "5px",
      "text-align": "center",
      width: "100%"
    }
  }, [_c("span", {
    staticStyle: {
      color: "rebeccapurple",
      "font-weight": "500",
      "font-size": "20px"
    }
  }, [_vm._v("Customer Types")])])])])])]), _vm._v(" "), _c("el-col", {
    staticStyle: {
      height: "100px"
    },
    attrs: {
      span: 6
    }
  }, [_c("div", {
    style: _vm.computeStyle("offering", "#EDFEFF"),
    on: {
      click: function click($event) {
        return _vm.switchEntities("offering");
      },
      mouseover: function mouseover($event) {
        _vm.offering_mouseover = true;
      },
      mouseleave: function mouseleave($event) {
        _vm.offering_mouseover = false;
      }
    }
  }, [_c("table", {
    staticStyle: {
      "border-spacing": "0px"
    }
  }, [_c("tr", [_c("td", {
    style: _vm.computeTotalCardStyle("offering", "#2C7E7F")
  }, [_c("span", {
    staticStyle: {
      "font-weight": "500",
      "font-size": "60px",
      color: "#FFF"
    }
  }, [_vm._v(_vm._s(_vm.offerings.length))])]), _vm._v(" "), _c("td", {
    staticStyle: {
      height: "100px",
      "border-radius": "5px",
      "text-align": "center",
      width: "100%"
    }
  }, [_c("span", {
    staticStyle: {
      color: "#2C7E7F",
      "font-weight": "500",
      "font-size": "20px"
    }
  }, [_vm._v("Offerings")])])])])])]), _vm._v(" "), _c("el-col", {
    staticStyle: {
      height: "100px"
    },
    attrs: {
      span: 6
    }
  }, [_c("div", {
    style: _vm.computeStyle("product", "#FCDECD"),
    on: {
      click: function click($event) {
        return _vm.switchEntities("product");
      },
      mouseover: function mouseover($event) {
        _vm.product_mouseover = true;
      },
      mouseleave: function mouseleave($event) {
        _vm.product_mouseover = false;
      }
    }
  }, [_c("table", {
    staticStyle: {
      "border-spacing": "0px"
    }
  }, [_c("tr", [_c("td", {
    style: _vm.computeTotalCardStyle("product", "#FF6600")
  }, [_c("span", {
    staticStyle: {
      "font-weight": "500",
      "font-size": "60px",
      color: "#FFF"
    }
  }, [_vm._v(_vm._s(_vm.products.length))])]), _vm._v(" "), _c("td", {
    staticStyle: {
      height: "100px",
      "border-radius": "5px",
      "text-align": "center",
      width: "100%"
    }
  }, [_c("span", {
    staticStyle: {
      color: "#FF6600",
      "font-weight": "500",
      "font-size": "20px"
    }
  }, [_vm._v("Products")])])])])])]), _vm._v(" "), _c("el-col", {
    staticStyle: {
      height: "100px"
    },
    attrs: {
      span: 6
    }
  }, [_c("div", {
    style: _vm.computeStyle("service", "#FCDECD"),
    on: {
      click: function click($event) {
        return _vm.switchEntities("service");
      },
      mouseover: function mouseover($event) {
        _vm.service_mouseover = true;
      },
      mouseleave: function mouseleave($event) {
        _vm.service_mouseover = false;
      }
    }
  }, [_c("table", {
    staticStyle: {
      "border-spacing": "0px"
    }
  }, [_c("tr", [_c("td", {
    style: _vm.computeTotalCardStyle("service", "#FF6600")
  }, [_c("span", {
    staticStyle: {
      "font-weight": "500",
      "font-size": "60px",
      color: "#FFF"
    }
  }, [_vm._v(_vm._s(_vm.services.length))])]), _vm._v(" "), _c("td", {
    staticStyle: {
      height: "100px",
      "border-radius": "5px",
      "text-align": "center",
      width: "100%"
    }
  }, [_c("span", {
    staticStyle: {
      color: "#FF6600",
      "font-weight": "500",
      "font-size": "20px"
    }
  }, [_vm._v("Services")])])])])])])], 1), _vm._v(" "), _c("div", {
    staticClass: "cards-no-bg text-right menu",
    staticStyle: {
      "padding-right": "15px"
    }
  }, [_c("el-input", {
    staticStyle: {
      "padding-left": "15px"
    },
    attrs: {
      size: "mini",
      placeholder: "Search"
    },
    model: {
      value: _vm.filter.q,
      callback: function callback($$v) {
        _vm.$set(_vm.filter, "q", $$v);
      },
      expression: "filter.q"
    }
  }, [_c("i", {
    staticClass: "el-input__icon el-icon-search",
    staticStyle: {
      "padding-left": "15px"
    },
    attrs: {
      slot: "prefix"
    },
    slot: "prefix"
  }), _vm._v(" "), _c("el-button", {
    attrs: {
      slot: "append",
      icon: "el-icon-menu"
    },
    on: {
      click: function click($event) {
        return _vm.compactLayout();
      }
    },
    slot: "append"
  }), _vm._v(" "), _c("el-button", {
    attrs: {
      slot: "append",
      icon: "el-icon-s-grid"
    },
    on: {
      click: function click($event) {
        return _vm.expandedLayout();
      }
    },
    slot: "append"
  })], 1)], 1), _vm._v(" "), _vm.selectedEntity === "customertype" ? _c("el-row", {
    staticClass: "cards-no-bg",
    staticStyle: {
      "padding-left": "7.5px",
      "padding-right": "7.5px"
    }
  }, _vm._l(_vm.filteredCustomerTypes, function (customer_type, index) {
    return _c("el-col", {
      key: "customer_type_" + index,
      staticStyle: {
        height: "250px",
        "margin-top": "7.5px",
        "margin-bottom": "7.5px"
      },
      attrs: {
        span: _vm.layout.customer_type_compact ? 6 : 12
      }
    }, [_c("div", {
      staticStyle: {
        height: "250px",
        "background-color": "#F5F0FA",
        "border-radius": "5px",
        margin: "0px 7.5px 0px 7.5px",
        filter: "drop-shadow(0px 4px 4px #00000077)"
      }
    }, [_c("el-row", {
      staticStyle: {
        padding: "7.5px 7.5px 0px 7.5px"
      }
    }, [_c("el-col", {
      staticClass: "text-left",
      attrs: {
        span: 24
      }
    }, [_c("div", {
      style: _vm.computeCardTitleStyle("customertype"),
      on: {
        click: function click($event) {
          return _vm.selectCustomer(customer_type);
        }
      }
    }, [_vm._v(_vm._s(_vm.truncateString(customer_type.title, 25))), _c("svg-icon", {
      staticClass: "fr mr-5",
      staticStyle: {
        color: "white"
      },
      attrs: {
        "icon-class": "peoples"
      }
    })], 1)])], 1), _vm._v(" "), _c("el-row", {
      staticStyle: {
        height: "217px",
        padding: "7.5px"
      }
    }, [_c("el-col", {
      staticClass: "text-left",
      attrs: {
        span: _vm.layout.customer_type_compact ? 24 : 12
      }
    }, [_c("div", {
      staticStyle: {
        height: "87px",
        "font-weight": "400",
        "font-size": "11px",
        "text-align": "left",
        "padding-left": "10px",
        "padding-right": "10px"
      }
    }, [_vm._v(_vm._s(_vm.truncateString(customer_type.description, 200)))]), _vm._v(" "), _c("div", {
      staticStyle: {
        "padding-left": "10pt",
        color: "darkslategrey",
        "text-align": "center"
      }
    }, [_c("span", [_vm._v("Journey Map")]), _vm._v(" "), customer_type.active_status === "prodiggi" ? _c("half-doughnut-prodiggi-status-component", {
      staticClass: "text-center",
      attrs: {
        status: customer_type.prodiggi_status,
        height: "100px",
        width: "100px"
      }
    }) : _vm._e()], 1)]), _vm._v(" "), !_vm.layout.customer_type_compact ? _c("el-col", {
      staticClass: "text-center",
      staticStyle: {
        height: "100%",
        "font-weight": "500",
        "border-radius": "5px",
        "background-color": "#f8f9fa",
        "padding-top": "5px",
        "overflow-y": "auto",
        filter: "drop-shadow(0px 1px 4px #00000077)"
      },
      attrs: {
        span: 12
      }
    }, [_c("strong", {
      staticStyle: {
        "font-size": "14px",
        "font-weight": "500"
      }
    }, [_vm._v("Offerings")]), _vm._v(" "), customer_type.linkedOfferings.length > 0 ? _c("table", {
      staticClass: "homepage-cards-table",
      staticStyle: {
        width: "100%"
      }
    }, [_c("tr", [_c("th", {
      staticStyle: {
        width: "80%"
      }
    }, [_vm._v("Title")]), _vm._v(" "), _c("th", {
      staticStyle: {
        width: "20%"
      }
    }, [_vm._v("Type")])]), _vm._v(" "), _vm._l(customer_type.linkedOfferings, function (offering, sub_index) {
      return _c("tr", {
        key: "offering_sub_index_" + sub_index,
        staticStyle: {
          cursor: "pointer",
          "font-size": "0.9em"
        },
        on: {
          click: function click($event) {
            return _vm.selectOffering(offering.offering);
          }
        }
      }, [_c("td", [_vm._v(_vm._s(_vm.truncateString(offering.offering.title, 25)))]), _vm._v(" "), _c("td", [offering.offering.offeringcategory === "Primary" ? _c("span", [_vm._v("Primary")]) : _vm._e(), _vm._v(" "), offering.offering.offeringcategory === "Secondary" ? _c("span", [_vm._v("Variant")]) : _vm._e()])]);
    })], 2) : _c("div", {
      staticClass: "text-center",
      staticStyle: {
        "font-weight": "300"
      }
    }, [_c("i", [_vm._v("No offerings")])])]) : _vm._e()], 1)], 1)]);
  }), 1) : _vm._e(), _vm._v(" "), _vm.selectedEntity === "offering" ? _c("el-row", {
    staticClass: "cards-no-bg",
    staticStyle: {
      "padding-left": "7.5px",
      "padding-right": "7.5px"
    }
  }, _vm._l(_vm.filteredOfferings, function (offering, index) {
    return _c("el-col", {
      key: "offering_" + index,
      staticStyle: {
        height: "250px",
        "margin-top": "7.5px",
        "margin-bottom": "7.5px"
      },
      attrs: {
        span: _vm.layout.offering_compact ? 6 : 12
      }
    }, [_c("div", {
      staticStyle: {
        height: "250px",
        "background-color": "#EDFEFF",
        "border-radius": "5px",
        margin: "0px 7.5px 0px 7.5px",
        filter: "drop-shadow(0px 4px 4px #00000077)"
      }
    }, [_c("el-row", {
      staticStyle: {
        padding: "7.5px 7.5px 0px 7.5px"
      }
    }, [_c("el-col", {
      staticClass: "text-left",
      attrs: {
        span: 24
      }
    }, [_c("div", {
      style: _vm.computeCardTitleStyle("offering"),
      on: {
        click: function click($event) {
          return _vm.selectOffering(offering);
        }
      }
    }, [_vm._v(_vm._s(_vm.truncateString(offering.title, 25))), _c("svg-icon", {
      staticClass: "fr mr-5",
      staticStyle: {
        color: "white"
      },
      attrs: {
        "icon-class": "product"
      }
    })], 1)])], 1), _vm._v(" "), _c("el-row", {
      staticStyle: {
        height: "217px",
        padding: "7.5px"
      }
    }, [_c("el-col", {
      staticClass: "text-left",
      attrs: {
        span: _vm.layout.offering_compact ? 24 : 12
      }
    }, [_c("div", {
      staticStyle: {
        height: "87px"
      }
    }, [_c("div", {
      staticStyle: {
        "font-weight": "400",
        "font-size": "11px",
        "text-align": "justify",
        "padding-left": "10px",
        "padding-right": "10px"
      }
    }, [_vm._v(_vm._s(_vm.truncateString(offering.description, 200)))])]), _vm._v(" "), _c("div", {
      staticStyle: {
        height: "120px"
      }
    }, [offering.active_status === "prodiggi" ? _c("half-doughnut-prodiggi-status-component", {
      staticClass: "text-center",
      attrs: {
        status: offering.prodiggi_status,
        height: "100px",
        width: "100px"
      }
    }) : _vm._e()], 1)]), _vm._v(" "), !_vm.layout.offering_compact ? _c("el-col", {
      staticClass: "text-center mb-10",
      staticStyle: {
        height: "100%",
        "font-weight": "500",
        "border-radius": "5px",
        "background-color": "#f8f9fa",
        "padding-top": "5px",
        "overflow-y": "auto",
        filter: "drop-shadow(0px 1px 4px #00000077)"
      },
      attrs: {
        span: 12
      }
    }, [_c("table", [_c("tr", [_c("th", {
      staticStyle: {
        width: "33%"
      }
    }, [_c("el-button", {
      attrs: {
        type: offering.active_status === "prodiggi" ? "primary" : "text",
        size: "micro"
      },
      on: {
        click: function click($event) {
          return _vm.updateOfferingStatus(offering, "prodiggi");
        }
      }
    }, [_vm._v("Prodiggi status")])], 1), _vm._v(" "), _c("th", {
      staticStyle: {
        width: "33%"
      }
    }), _vm._v(" "), _c("th", {
      staticStyle: {
        width: "33%"
      }
    }, [_c("el-button", {
      attrs: {
        type: offering.active_status === "external" ? "primary" : "text",
        size: "micro"
      },
      on: {
        click: function click($event) {
          return _vm.updateOfferingStatus(offering, "external");
        }
      }
    }, [_vm._v("External status")])], 1)])]), _vm._v(" "), _vm._l(offering.linkedProducts, function (product, product_index) {
      return _c("div", {
        key: "offering_product_" + product_index,
        staticStyle: {
          "text-align": "left",
          "padding-left": "10px",
          "padding-right": "10px",
          "font-weight": "500",
          "font-size": "0.9em"
        }
      }, [_c("span", [_c("svg-icon", {
        attrs: {
          "icon-class": "pdg-product"
        }
      }), _vm._v(" " + _vm._s(product.product.title))], 1), _vm._v(" "), _c("el-progress", {
        attrs: {
          percentage: product.product.external_progress
        }
      })], 1);
    }), _vm._v(" "), _vm._l(offering.linkedServices, function (service, service_index) {
      return _c("div", {
        key: "offering_service_" + service_index,
        staticStyle: {
          "text-align": "left",
          "padding-left": "10px",
          "padding-right": "10px",
          "font-weight": "500",
          "font-size": "0.9em"
        }
      }, [_c("span", [_c("svg-icon", {
        attrs: {
          "icon-class": "pdg-service"
        }
      }), _vm._v(" " + _vm._s(service.service.title))], 1), _vm._v(" "), _c("el-progress", {
        attrs: {
          percentage: service.service.external_progress
        }
      })], 1);
    }), _vm._v(" "), offering.linkedProducts.length === 0 && offering.linkedServices.length === 0 ? _c("div", {
      staticClass: "text-center",
      staticStyle: {
        "font-weight": "300"
      }
    }, [_c("i", [_vm._v("No Product/Service")])]) : _vm._e()], 2) : _vm._e()], 1)], 1)]);
  }), 1) : _vm._e(), _vm._v(" "), _vm.selectedEntity === "product" ? _c("el-row", {
    staticClass: "cards-no-bg",
    staticStyle: {
      "padding-left": "7.5px",
      "padding-right": "7.5px"
    }
  }, _vm._l(_vm.filteredProducts, function (product, index) {
    return _c("el-col", {
      key: "product_" + index,
      staticStyle: {
        height: "250px",
        "margin-top": "7.5px",
        "margin-bottom": "7.5px"
      },
      attrs: {
        span: _vm.layout.product_compact ? 6 : 12
      }
    }, [_c("div", {
      staticStyle: {
        height: "250px",
        "background-color": "#FCDECD",
        "border-radius": "5px",
        margin: "0px 7.5px 0px 7.5px",
        filter: "drop-shadow(0px 4px 4px #00000077)"
      }
    }, [_c("el-row", {
      staticStyle: {
        padding: "7.5px 7.5px 0px 7.5px"
      }
    }, [_c("el-col", {
      staticClass: "text-left",
      attrs: {
        span: 24
      }
    }, [_c("div", {
      style: _vm.computeCardTitleStyle("product")
    }, [_vm._v(_vm._s(_vm.truncateString(product.title, 25))), _c("svg-icon", {
      staticClass: "fr mr-5",
      staticStyle: {
        color: "white"
      },
      attrs: {
        "icon-class": "pdg-product"
      }
    })], 1)])], 1), _vm._v(" "), _c("el-row", {
      staticStyle: {
        height: "217px",
        padding: "7.5px"
      }
    }, [_c("el-col", {
      attrs: {
        span: _vm.layout.product_compact ? 24 : 12
      }
    }, [_c("div", {
      staticClass: "text-left",
      staticStyle: {
        height: "87px"
      }
    }, [_c("div", {
      staticStyle: {
        "font-weight": "400",
        "font-size": "10px",
        "text-align": "justify",
        "padding-left": "10px",
        "padding-right": "10px"
      }
    }, [_vm._v(_vm._s(_vm.truncateString(product.description, 200)))])]), _vm._v(" "), _c("div", {
      staticClass: "text-center"
    }, [product.active_status === "external" ? _c("pie-chart-external-status-component", {
      staticClass: "text-center",
      attrs: {
        status: product.external_status,
        height: "100px",
        width: "100px"
      }
    }) : _vm._e(), _vm._v(" "), product.active_status === "prodiggi" ? _c("pie-chart-prodiggi-status-component", {
      staticClass: "text-center",
      attrs: {
        status: product.prodiggi_status,
        height: "100px",
        width: "100px"
      }
    }) : _vm._e()], 1)]), _vm._v(" "), !_vm.layout.product_compact ? _c("el-col", {
      staticClass: "text-center",
      staticStyle: {
        height: "100%",
        "font-weight": "500",
        "border-radius": "5px",
        "background-color": "#f8f9fa",
        "padding-top": "5px",
        "overflow-y": "auto",
        filter: "drop-shadow(0px 1px 4px #00000077)"
      },
      attrs: {
        span: 12
      }
    }, [_c("table", [_c("tr", [_c("th", {
      staticStyle: {
        width: "33%"
      }
    }, [_c("el-button", {
      attrs: {
        type: product.active_status === "prodiggi" ? "primary" : "text",
        size: "micro"
      },
      on: {
        click: function click($event) {
          return _vm.updateProductStatus(product, "prodiggi");
        }
      }
    }, [_vm._v("Prodiggi status")])], 1), _vm._v(" "), _c("th", {
      staticStyle: {
        width: "33%"
      }
    }), _vm._v(" "), _c("th", {
      staticStyle: {
        width: "33%"
      }
    }, [_c("el-button", {
      attrs: {
        type: product.active_status === "external" ? "primary" : "text",
        size: "micro"
      },
      on: {
        click: function click($event) {
          return _vm.updateProductStatus(product, "external");
        }
      }
    }, [_vm._v("External status")])], 1)])]), _vm._v(" "), product.linkedFeatures.length > 0 && product.active_status === "prodiggi" ? _c("table", {
      staticClass: "homepage-cards-table",
      staticStyle: {
        width: "100%"
      }
    }, [_c("tr", [_c("th", {
      staticStyle: {
        width: "80%"
      }
    }, [_vm._v("Status")]), _vm._v(" "), _c("th", {
      staticStyle: {
        width: "20%"
      }
    }, [_vm._v("#")])]), _vm._v(" "), _vm._l(product.prodiggi_status, function (status, product_internal_index) {
      return _c("tr", {
        key: "product_internal_" + product_internal_index
      }, [_c("td", [_vm._v(_vm._s(status.status))]), _vm._v(" "), _c("td", [_vm._v(_vm._s(status.noOfTickets))])]);
    })], 2) : product.linkedFeatures.length > 0 && product.active_status === "external" ? _c("table", {
      staticClass: "homepage-cards-table",
      staticStyle: {
        width: "100%"
      }
    }, [_c("tr", [_c("th", {
      staticStyle: {
        width: "80%"
      }
    }, [_vm._v("Status")]), _vm._v(" "), _c("th", {
      staticStyle: {
        width: "20%"
      }
    }, [_vm._v("#")])]), _vm._v(" "), _vm._l(product.external_status, function (status, product_external_index) {
      return _c("tr", {
        key: "product_external_" + product_external_index
      }, [_c("td", [_vm._v(_vm._s(status.status))]), _vm._v(" "), _c("td", [_vm._v(_vm._s(status.noOfTickets))])]);
    })], 2) : _c("div", {
      staticClass: "text-center",
      staticStyle: {
        "font-weight": "300"
      }
    }, [_c("i", [_vm._v("No features")])])]) : _vm._e()], 1)], 1)]);
  }), 1) : _vm._e(), _vm._v(" "), _vm.selectedEntity === "service" ? _c("el-row", {
    staticClass: "cards-no-bg",
    staticStyle: {
      "padding-left": "7.5px",
      "padding-right": "7.5px"
    }
  }, _vm._l(_vm.filteredServices, function (service, index) {
    return _c("el-col", {
      key: "service_" + index,
      staticStyle: {
        height: "250px",
        "margin-top": "7.5px",
        "margin-bottom": "7.5px"
      },
      attrs: {
        span: _vm.layout.service_compact ? 6 : 12
      }
    }, [_c("div", {
      staticStyle: {
        height: "250px",
        "background-color": "#FCDECD",
        "border-radius": "5px",
        margin: "0px 7.5px 0px 7.5px",
        filter: "drop-shadow(0px 4px 4px #00000077)"
      }
    }, [_c("el-row", {
      staticStyle: {
        padding: "7.5px 7.5px 0px 7.5px"
      }
    }, [_c("el-col", {
      staticClass: "text-left",
      attrs: {
        span: 24
      }
    }, [_c("div", {
      style: _vm.computeCardTitleStyle("service")
    }, [_vm._v(_vm._s(_vm.truncateString(service.title, 25))), _c("svg-icon", {
      staticClass: "fr mr-5",
      staticStyle: {
        color: "white"
      },
      attrs: {
        "icon-class": "pdg-service"
      }
    })], 1)])], 1), _vm._v(" "), _c("el-row", {
      staticStyle: {
        height: "217px",
        padding: "7.5px"
      }
    }, [_c("el-col", {
      attrs: {
        span: _vm.layout.service_compact ? 24 : 12
      }
    }, [_c("div", {
      staticClass: "text-left",
      staticStyle: {
        height: "87px"
      }
    }, [_c("div", {
      staticStyle: {
        "font-weight": "400",
        "font-size": "10px",
        "text-align": "justify",
        "padding-left": "10px",
        "padding-right": "10px"
      }
    }, [_vm._v(_vm._s(_vm.truncateString(service.description, 200)))])]), _vm._v(" "), _c("div", {
      staticClass: "text-center"
    }, [service.active_status === "external" ? _c("pie-chart-external-status-component", {
      staticClass: "text-center",
      attrs: {
        status: service.external_status,
        height: "100px",
        width: "100px"
      }
    }) : _vm._e(), _vm._v(" "), service.active_status === "prodiggi" ? _c("pie-chart-prodiggi-status-component", {
      staticClass: "text-center",
      attrs: {
        status: service.prodiggi_status,
        height: "100px",
        width: "100px"
      }
    }) : _vm._e()], 1)]), _vm._v(" "), !_vm.layout.service_compact ? _c("el-col", {
      staticClass: "text-center",
      staticStyle: {
        height: "100%",
        "font-weight": "500",
        "border-radius": "5px",
        "background-color": "#f8f9fa",
        "padding-top": "5px",
        "overflow-y": "auto",
        filter: "drop-shadow(0px 1px 4px #00000077)"
      },
      attrs: {
        span: 12
      }
    }, [_c("table", [_c("tr", [_c("th", {
      staticStyle: {
        width: "33%"
      }
    }, [_c("el-button", {
      attrs: {
        type: service.active_status === "prodiggi" ? "primary" : "text",
        size: "micro"
      },
      on: {
        click: function click($event) {
          return _vm.updateServiceStatus(service, "prodiggi");
        }
      }
    }, [_vm._v("Prodigise Status")])], 1), _vm._v(" "), _c("th", {
      staticStyle: {
        width: "33%"
      }
    }), _vm._v(" "), _c("th", {
      staticStyle: {
        width: "33%"
      }
    }, [_c("el-button", {
      attrs: {
        type: service.active_status === "external" ? "primary" : "text",
        size: "micro"
      },
      on: {
        click: function click($event) {
          return _vm.updateServiceStatus(service, "external");
        }
      }
    }, [_vm._v("External")])], 1)])]), _vm._v(" "), service.linkedServiceApplications.length > 0 && service.active_status === "prodiggi" ? _c("table", {
      staticClass: "homepage-cards-table",
      staticStyle: {
        width: "100%"
      }
    }, _vm._l(service.prodiggi_status, function (status, service_internal_index) {
      return _c("tr", {
        key: "service_internal_" + service_internal_index
      }, [_c("td", [_vm._v(_vm._s(status.status))]), _vm._v(" "), _c("td", [_vm._v(_vm._s(status.noOfTickets))])]);
    }), 0) : service.linkedServiceApplications.length > 0 && service.active_status === "external" ? _c("table", {
      staticClass: "homepage-cards-table",
      staticStyle: {
        width: "100%"
      }
    }, _vm._l(service.external_status, function (status, service_external_index) {
      return _c("tr", {
        key: "service_external_" + service_external_index
      }, [_c("td", [_vm._v(_vm._s(status.status))]), _vm._v(" "), _c("td", [_vm._v(_vm._s(status.noOfTickets))])]);
    }), 0) : _c("div", {
      staticClass: "text-center",
      staticStyle: {
        "font-weight": "300"
      }
    }, [_c("i", [_vm._v("No processes")])])]) : _vm._e()], 1)], 1)]);
  }), 1) : _vm._e()], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;