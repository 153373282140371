"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _groupCustomers = _interopRequireDefault(require("./components/identify_users/group-customers"));
var _defineUserDetails = _interopRequireDefault(require("./components/identify_users/define-user-details"));
var _customerLink = _interopRequireDefault(require("./components/identify_users/customer-link"));
var _ProcessSteps = _interopRequireDefault(require("@/components/ProcessSteps"));
var _default = exports.default = {
  name: 'IdentifyUser',
  components: {
    GroupCustomersComponent: _groupCustomers.default,
    DefineUserDetailsComponent: _defineUserDetails.default,
    // added JH 4/8/20
    CustomerLinkComponent: _customerLink.default,
    // added JH 4/8/20
    ProcessSteps: _ProcessSteps.default
  },
  data: function data() {
    return {
      active_step: 0,
      openCreateGroupDialog: false,
      steps: [{
        name: 'Group Similar Customers',
        shortDescription: 'Sort customers into user types',
        longDescription: 'A user type gives access to different features.  If all users will use the product in the same, there will be one user type (eg Admin user vs general user). ' + 'Use this page to separate customers that will use the product differently.'
      }, {
        name: 'Describe User Types',
        shortDescription: 'How do user types differ?',
        longDescription: 'Describing user types help drive customer value creation. Define the user problem and solution, set their priority, and view their pains and gains.'
      },
      /*
      {
        name: 'TBC: Link Customer to User Type',
        shortDescription: '',
        longDescription: ''
      },
       */
      {
        name: 'View user - Customer Relationships',
        shortDescription: 'Check Customer to User Type mappings',
        longDescription: 'Use this screen to view the mappings from segments and customers types to user types.'
      }],
      segments: [{
        id: 1,
        name: 'Medium Business',
        salesMethod: ['Direct', 'Partner', 'Channel', 'Whitelabel'],
        description: 'This segment can afford $250K-$2M spend on digital products. They might have a small IT department who know what they are doing.',
        revenue_notes: 'Revenue per customer is high, but volume is lower as sales is harder. Licensing is cost if $50K-$100K.',
        geography: ['OA', 'SEA'],
        pricingTier: ['Premium'],
        numCustomers: 100,
        desiredMarketPercent: 100,
        newCustomersPerMonth: 10,
        numTargetCustomers: 10,
        avgCustValue: 1000000,
        serviceFee: 200000,
        project: 20000,
        annualProjectService: 750000,
        annualLicenseRevenuePC: 50000,
        annualRevenueRecurring: 250000,
        annualLicenseRevenueTotal: 5000000,
        annualSupportRevenueTotal: 5000000,
        annualSegmentValue: 5000000,
        value: 75000000,
        segment_needs: [{
          id: 1,
          name: 'Integrated method and tools',
          complete: false
        }, {
          id: 2,
          name: 'Detailed reports and metrics',
          complete: true
        }, {
          id: 3,
          name: 'Transparency of process',
          complete: false
        }, {
          id: 4,
          name: 'Clarity over outsourcers',
          complete: false
        }, {
          id: 5,
          name: 'Process guidance',
          complete: false
        }],
        customers: [{
          id: 1,
          name: 'Individual',
          description: 'Lorem Ipsum',
          problem_statement: 'Lorem Ipsum',
          opportunity_statement: 'Lorem Ipsum',
          psychometric_notes: 'Lorem Ipsum',
          sales_strategy: 'Lorem Ipsum',
          product_user: true,
          purchaser: true,
          map_persona: true
        }, {
          id: 2,
          name: 'Couple',
          description: 'Lorem Ipsum',
          problem_statement: 'Lorem Ipsum',
          opportunity_statement: 'Lorem Ipsum',
          psychometric_notes: 'Lorem Ipsum',
          sales_strategy: 'Lorem Ipsum',
          product_user: true,
          purchaser: true,
          map_persona: true
        }, {
          id: 3,
          name: 'Family',
          description: 'Lorem Ipsum',
          problem_statement: 'Lorem Ipsum',
          opportunity_statement: 'Lorem Ipsum',
          psychometric_notes: 'Lorem Ipsum',
          sales_strategy: 'Lorem Ipsum',
          product_user: true,
          purchaser: true,
          map_persona: true
        }
        /* Commented out: JH. Siva bros requested clearer user types in dummy data.
        ,
          {
          id: 4,
          name: 'Chief Int Officer',
          description: 'Lorem Ipsum',
          problem_statement: 'Lorem Ipsum',
          opportunity_statement: 'Lorem Ipsum',
          psychometric_notes: 'Lorem Ipsum',
          sales_strategy: 'Lorem Ipsum',
          product_user: false,
          purchaser: false,
          map_persona: false
        },
        {
          id: 5,
          name: 'Head of Architech',
          description: 'Lorem Ipsum',
          problem_statement: 'Lorem Ipsum',
          opportunity_statement: 'Lorem Ipsum',
          psychometric_notes: 'Lorem Ipsum',
          sales_strategy: 'Lorem Ipsum',
          product_user: false,
          purchaser: false,
          map_persona: false
        },
        {
          id: 6,
          name: 'Program Manager',
          description: 'Lorem Ipsum',
          problem_statement: 'Lorem Ipsum',
          opportunity_statement: 'Lorem Ipsum',
          psychometric_notes: 'Lorem Ipsum',
          sales_strategy: 'Lorem Ipsum',
          product_user: false,
          purchaser: false,
          map_persona: false
        },
        {
          id: 7,
          name: 'Practice Lead',
          description: 'Lorem Ipsum',
          problem_statement: 'Lorem Ipsum',
          opportunity_statement: 'Lorem Ipsum',
          psychometric_notes: 'Lorem Ipsum',
          sales_strategy: 'Lorem Ipsum',
          product_user: false,
          purchaser: false,
          map_persona: false
        },
        {
          id: 8,
          name: 'Head of Software',
          description: 'Lorem Ipsum',
          problem_statement: 'Lorem Ipsum',
          opportunity_statement: 'Lorem Ipsum',
          psychometric_notes: 'Lorem Ipsum',
          sales_strategy: 'Lorem Ipsum',
          product_user: false,
          purchaser: false,
          map_persona: false
        }
        */]
      }, {
        id: 2,
        name: 'Small Business',
        salesMethod: ['Direct', 'Partner', 'Online'],
        description: 'This segment cannot afford an IT department. Average project is $50K-$100K, and is complex and risky due to lack of skills. Quality outcomes are not assured.',
        revenue_notes: 'Service revenue is low <$100K, licensing also low ($15K), but volume and scale it higher.',
        geography: ['OA', 'SEA'],
        pricingTier: ['Intermediate'],
        numCustomers: 500,
        desiredMarketPercent: 100,
        newCustomersPerMonth: 10,
        numTargetCustomers: 10,
        avgCustValue: 115000,
        serviceFee: 200000,
        project: 20000,
        annualProjectService: 750000,
        annualLicenseRevenuePC: 15000,
        annualRevenueRecurring: 250000,
        annualLicenseRevenueTotal: 7500000,
        annualSupportRevenueTotal: 7500000,
        annualSegmentValue: 7500000,
        value: 57500000,
        segment_needs: [{
          name: 'DIY delivery method with guidance',
          complete: false
        }, {
          name: 'One tool to manage the whole process',
          complete: true
        }, {
          name: 'Access to cheap off development',
          complete: true
        }, {
          name: 'Access to quality outsourcing process',
          complete: true
        }, {
          name: 'Process clarity and transparency',
          complete: false
        }],
        customers: [{
          id: 1,
          name: 'Individual',
          opportunity_statement: 'Lorem Ipsum',
          psychometric_notes: 'Lorem Ipsum',
          sales_strategy: 'Lorem Ipsum',
          product_user: false,
          purchaser: false,
          map_persona: false
        }]
      }, {
        id: 3,
        name: 'Startups',
        salesMethod: ['Direct', 'Partner', 'Online'],
        description: 'Product centric startups are a good fit for this tool, but do not have deep pockets.  Pricing will be lower.',
        revenue_notes: 'Service revenue near zero, though KFX Advisor hourly rate possible. $500 per year <5, $1500 <10 $5,000 <20- atlassian competitive. Equity stakes in certain scenarios',
        geography: ['OA'],
        pricingTier: ['Budget'],
        numCustomers: 1500,
        desiredMarketPercent: 100,
        newCustomersPerMonth: 10,
        numTargetCustomers: 10,
        avgCustValue: 1500,
        serviceFee: 200000,
        project: 20000,
        annualProjectService: 750000,
        annualLicenseRevenuePC: 1500,
        annualRevenueRecurring: 250000,
        annualLicenseRevenueTotal: 2250000,
        annualSupportRevenueTotal: 2250000,
        annualSegmentValue: 2250000,
        value: 2250000,
        segment_needs: [{
          name: 'DIY delivery method with guidance',
          complete: false
        }, {
          name: 'One tool to manage the whole process',
          complete: true
        }, {
          name: 'Access to cheap development',
          complete: true
        }, {
          name: 'Access to quality development',
          complete: true
        }],
        customers: [{
          id: 1,
          name: 'Individual',
          opportunity_statement: 'Lorem Ipsum',
          psychometric_notes: 'Lorem Ipsum',
          sales_strategy: 'Lorem Ipsum',
          product_user: false,
          purchaser: false,
          map_persona: false
        }]
      }, {
        id: 4,
        name: 'Incubators &  VCs',
        salesMethod: ['Direct', 'Online'],
        description: 'Allows VCs and incubators to track their portfolio companies in detail.',
        revenue_notes: 'Bulk licensing / OEM license plus licenses for each portfolio company.  Training and certification licenses to VCs and Incubators.',
        geography: ['OA'],
        pricingTier: ['Budget'],
        numCustomers: 100,
        desiredMarketPercent: 100,
        newCustomersPerMonth: 10,
        numTargetCustomers: 10,
        avgCustValue: 30000,
        serviceFee: 200000,
        project: 20000,
        annualProjectService: 750000,
        annualLicenseRevenuePC: 30000,
        annualRevenueRecurring: 250000,
        annualLicenseRevenueTotal: 3000000,
        annualSupportRevenueTotal: 3000000,
        annualSegmentValue: 3000000,
        value: 3000000,
        segment_needs: [{
          name: 'Manage portfolio companies',
          complete: false
        }, {
          name: 'One tool to manage the whole process',
          complete: true
        }, {
          name: 'Provide cheap, quality offshoring',
          complete: true
        }, {
          name: 'DIY delivery method with guidance',
          complete: true
        }],
        customers: [{
          id: 1,
          name: 'Individual',
          opportunity_statement: 'Lorem Ipsum',
          psychometric_notes: 'Lorem Ipsum',
          sales_strategy: 'Lorem Ipsum',
          product_user: false,
          purchaser: false,
          map_persona: false
        }]
      }, {
        id: 5,
        name: 'Large Partner/OEM',
        salesMethod: ['Partner', 'Whitelabel', 'Channel'],
        description: 'These are large service companies that cannot tap the small market due to costs. This reduces project cost up to 10x through one process coupled with quality offhosre dev. White labelling is an option. ',
        revenue_notes: 'Service revenue in training,>$2K per day to partner employees, and employee customers projects (Project Advisor). Licensing revenue for each client.',
        geography: ['OA', 'SEA'],
        pricingTier: ['Premium'],
        numCustomers: 500,
        desiredMarketPercent: 100,
        newCustomersPerMonth: 10,
        numTargetCustomers: 10,
        avgCustValue: 45000,
        serviceFee: 200000,
        project: 20000,
        annualProjectService: 750000,
        annualLicenseRevenuePC: 35000,
        annualRevenueRecurring: 250000,
        annualLicenseRevenueTotal: 7500000,
        annualSupportRevenueTotal: 7500000,
        annualSegmentValue: 7500000,
        value: 7500000,
        segment_needs: [{
          name: 'DIY delivery method with guidance',
          complete: false
        }, {
          name: 'One tool to manage the whole process',
          complete: true
        }, {
          name: 'Access to cheap off development',
          complete: true
        }, {
          name: 'Access to quality outsourcing process',
          complete: true
        }, {
          name: 'Process clarity and transparency',
          complete: false
        }],
        customers: [{
          id: 1,
          name: 'Individual',
          opportunity_statement: 'Lorem Ipsum',
          psychometric_notes: 'Lorem Ipsum',
          sales_strategy: 'Lorem Ipsum',
          product_user: false,
          purchaser: false,
          map_persona: false
        }]
      }, {
        id: 6,
        name: 'Partner Ecosystem',
        salesMethod: ['Partner', 'Channel'],
        description: 'This segment are SME partners who help deliver digital products to customers.',
        revenue_notes: 'Initially no revenue. As product demand grows, training and certification will be charged. Partners can take a cut of licensing, eg 35%',
        geography: ['OA', 'SEA'],
        pricingTier: ['Intermediate'],
        numCustomers: 500,
        desiredMarketPercent: 100,
        newCustomersPerMonth: 10,
        numTargetCustomers: 10,
        avgCustValue: 115000,
        serviceFee: 200000,
        project: 20000,
        annualProjectService: 750000,
        annualLicenseRevenuePC: 15000,
        annualRevenueRecurring: 250000,
        annualLicenseRevenueTotal: 7500000,
        annualSupportRevenueTotal: 7500000,
        annualSegmentValue: 7500000,
        value: 57500000,
        segment_needs: [{
          name: 'DIY delivery method with guidance',
          complete: false
        }, {
          name: 'One tool to manage the whole process',
          complete: true
        }, {
          name: 'Access to cheap off development',
          complete: true
        }, {
          name: 'Access to quality outsourcing process',
          complete: true
        }, {
          name: 'Process clarity and transparency',
          complete: false
        }],
        customers: [{
          id: 1,
          name: 'Individual',
          opportunity_statement: 'Lorem Ipsum',
          psychometric_notes: 'Lorem Ipsum',
          sales_strategy: 'Lorem Ipsum',
          product_user: false,
          purchaser: false,
          map_persona: false
        }]
      }, {
        id: 7,
        name: 'Enterprise & Government',
        salesMethod: ['Direct', 'Channel', 'Partner', 'Online'],
        description: 'This segment has entrenched IT that may be hard to break.  Should focus on the business players, eg CMO, Head of Products, etc. Goal is to reign in IT.',
        revenue_notes: 'Licensing revenue will be high, $>200K. Training high. Services High.',
        geography: ['OA', 'SEA'],
        pricingTier: ['Premium'],
        numCustomers: 100,
        desiredMarketPercent: 100,
        newCustomersPerMonth: 10,
        numTargetCustomers: 10,
        avgCustValue: 2500000,
        serviceFee: 200000,
        project: 20000,
        annualProjectService: 750000,
        annualLicenseRevenuePC: 250000,
        annualRevenueRecurring: 250000,
        annualLicenseRevenueTotal: 25000000,
        annualSupportRevenueTotal: 25000000,
        annualSegmentValue: 25000000,
        value: 50000000,
        segment_needs: [{
          name: 'Business process changes',
          complete: false
        }, {
          name: 'One tool to manage the whole process',
          complete: true
        }, {
          name: 'Access to cheap off development',
          complete: true
        }, {
          name: 'Access to quality outsourcing process',
          complete: true
        }, {
          name: 'Process clarity and transparency',
          complete: false
        }],
        customers: [{
          id: 1,
          name: 'Individual',
          opportunity_statement: 'Lorem Ipsum',
          psychometric_notes: 'Lorem Ipsum',
          sales_strategy: 'Lorem Ipsum',
          product_user: false,
          purchaser: false,
          map_persona: false
        }]
      }

      /* END SEGMENT DUMMY DATA*/],
      groups: []
    };
  },
  mounted: function mounted() {},
  created: function created() {},
  methods: {
    goToGroupSimilarCustomer: function goToGroupSimilarCustomer(groups) {
      this.groups = groups;
      this.active_step = 0;
    },
    goDefineUserDetails: function goDefineUserDetails(groups) {
      this.groups = groups;
      this.active_step = 1;
    },
    goCustomerLink: function goCustomerLink(groups) {
      this.groups = groups;
      this.active_step = 2;
    },
    updateStep: function updateStep(active_step) {
      this.active_step = active_step;
    }
  }
};