"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/taggedTemplateLiteral.js"));
require("core-js/modules/es6.function.name");
var _objectSpread2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/objectSpread2.js"));
require("core-js/modules/es6.number.constructor");
var _interopRequireWildcard2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _axios = _interopRequireDefault(require("axios"));
var _report_offering = _interopRequireDefault(require("@/mixins/report_offering"));
var _utils = require("@/utils");
var _vuex = require("vuex");
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9;
var _default2 = exports.default = {
  name: 'ModelOffering',
  components: {
    JourneyMapComponent: function JourneyMapComponent() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./model-offering/JourneyMap.vue"));
      });
    },
    PhaseDetailsComponent: function PhaseDetailsComponent() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./model-offering/PhaseDetails.vue"));
      });
    },
    TaskDetailsComponent: function TaskDetailsComponent() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./model-offering/TaskDetails.vue"));
      });
    },
    EntityListComponent: function EntityListComponent() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./model-offering/EntityList.vue"));
      });
    },
    CreateOfferingIdeaComponent: function CreateOfferingIdeaComponent() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./model-offering/CreateOfferingIdea.vue"));
      });
    },
    SVGCTAComponent: function SVGCTAComponent() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/components/SVGCTA'));
      });
    },
    IntegrationComponent: function IntegrationComponent() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./model-offering/Integration.vue"));
      });
    },
    TouchpointsComponent: function TouchpointsComponent() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("../../../customer_types/components/journey/Touchpoints.vue"));
      });
    },
    TouchpointCreateComponent: function TouchpointCreateComponent() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("../../../customer_types/components/journey/TouchpointCreate.vue"));
      });
    },
    TouchpointPopoverComponent: function TouchpointPopoverComponent() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("../../../customer_types/components/journey/TouchpointPopover.vue"));
      });
    },
    FeaturePopoverComponent: function FeaturePopoverComponent() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./model-offering/FeaturePopover.vue"));
      });
    },
    ServiceApplicationPopoverComponent: function ServiceApplicationPopoverComponent() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./model-offering/ServiceApplicationPopover.vue"));
      });
    },
    ExternalTicketListComponent: function ExternalTicketListComponent() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./model-offering/ExternalTicketList.vue"));
      });
    },
    TicketComponent: function TicketComponent() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/components/Ticket'));
      });
    },
    RelationsComponent: function RelationsComponent() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./model-offering/Relations.vue"));
      });
    },
    SettingsComponent: function SettingsComponent() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./model-offering/Settings.vue"));
      });
    }
  },
  mixins: [_report_offering.default],
  props: {
    customer: {
      type: Object,
      required: true,
      default: function _default() {
        return {
          id: '',
          title: '',
          description: '',
          tiers: '',
          rationale: '',
          priority: '',
          persona: '',
          additionalattributes: {},
          linkedJourneyMaps: []
        };
      }
    },
    offering: {
      type: Object,
      required: true,
      default: function _default() {
        return {
          id: '',
          title: '',
          description: '',
          offeringcategory: '',
          offeringcategorydesc: '',
          additionalattributes: {},
          linkedProducts: [],
          linkedServices: []
        };
      }
    },
    journeyMap: {
      type: Object,
      required: true,
      default: function _default() {
        return {
          id: '',
          title: '',
          description: '',
          additionalattributes: {},
          linkedJourneyExperiences: [],
          linkedJourneyPhases: [],
          linkedJourneySteps: []
        };
      }
    },
    totalOfferingsCount: {
      type: Number,
      required: true,
      default: 0
    },
    showGrid: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    var validateTitle = function validateTitle(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter a valid title'));
      } else {
        callback();
      }
    };
    var validateDescription = function validateDescription(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter a valid description'));
      } else {
        callback();
      }
    };
    var validateStatus = function validateStatus(rule, value, callback) {
      if (!value) {
        callback(new Error('Please select a status'));
      } else {
        callback();
      }
    };
    var validatePriority = function validatePriority(rule, value, callback) {
      if (!value) {
        callback(new Error('Please select a priority'));
      } else {
        callback();
      }
    };
    var validateManager = function validateManager(rule, value, callback) {
      if (!value) {
        callback(new Error('Please select a manager'));
      } else {
        callback();
      }
    };
    var validateSeverity = function validateSeverity(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter a valid severity'));
      } else {
        callback();
      }
    };
    var validateOccuranceProbability = function validateOccuranceProbability(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter a valid occuranceprobability'));
      } else {
        callback();
      }
    };
    var validateImpactLevel = function validateImpactLevel(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter a valid impactlevel'));
      } else {
        callback();
      }
    };
    var validateMitigationPlan = function validateMitigationPlan(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter a valid mitigation plan'));
      } else {
        callback();
      }
    };
    var validateServiceApplicationFeature = function validateServiceApplicationFeature(rule, value, callback) {
      if (!value) {
        callback(new Error('Please select a Feature'));
      } else {
        callback();
      }
    };
    var validateServiceApplicationStep = function validateServiceApplicationStep(rule, value, callback) {
      if (!value) {
        callback(new Error('Please select a Step'));
      } else {
        callback();
      }
    };
    return {
      addServiceApplicationRules: {
        title: [{
          required: true,
          message: 'Please input ServiceApplication title',
          trigger: 'blur'
        }],
        description: [{
          required: true,
          message: 'Please input ServiceApplication description',
          trigger: 'blur'
        }],
        priority: [{
          required: true,
          message: 'Please input ServiceApplication priority',
          trigger: 'blur'
        }],
        type: [{
          required: true,
          message: 'Please input ServiceApplication type',
          trigger: 'blur'
        }],
        status: [{
          required: true,
          message: 'Please input ServiceApplication status',
          trigger: 'blur'
        }],
        enabler: [{
          required: true,
          message: 'Please Select is enabler',
          trigger: 'blur'
        }],
        featureId: [{
          validator: validateServiceApplicationFeature,
          trigger: 'blur'
        }],
        stepId: [{
          validator: validateServiceApplicationStep,
          trigger: 'blur'
        }],
        serviceId: [{
          required: true,
          message: 'Please Select Service Lane',
          trigger: 'blur'
        }]
      },
      riskRules: {
        title: [{
          required: true,
          trigger: 'blur',
          validator: validateTitle
        }],
        description: [{
          required: true,
          trigger: 'blur',
          validator: validateDescription
        }],
        severity: [{
          required: true,
          trigger: 'blur',
          validator: validateSeverity
        }],
        occuranceprobability: [{
          required: true,
          trigger: 'blur',
          validator: validateOccuranceProbability
        }],
        impactlevel: [{
          required: true,
          trigger: 'blur',
          validator: validateImpactLevel
        }],
        mitigationplan: [{
          required: true,
          trigger: 'blur',
          validator: validateMitigationPlan
        }]
      },
      productRules: {
        title: [{
          required: true,
          trigger: 'blur',
          validator: validateTitle
        }],
        description: [{
          required: true,
          trigger: 'blur',
          validator: validateDescription
        }],
        manager: [{
          required: true,
          trigger: 'blur',
          validator: validateManager
        }]
      },
      serviceRules: {
        title: [{
          required: true,
          trigger: 'blur',
          validator: validateTitle
        }],
        description: [{
          required: true,
          trigger: 'blur',
          validator: validateDescription
        }]
      },
      offeringRules: {
        title: [{
          required: true,
          trigger: 'blur',
          validator: validateTitle
        }],
        description: [{
          required: true,
          trigger: 'blur',
          validator: validateDescription
        }]
      },
      featureRules: {
        title: [{
          required: true,
          trigger: 'blur',
          validator: validateTitle
        }],
        description: [{
          required: true,
          trigger: 'blur',
          validator: validateDescription
        }],
        status: [{
          required: true,
          trigger: 'blur',
          validator: validateStatus
        }],
        priority: [{
          required: true,
          trigger: 'blur',
          validator: validatePriority
        }]
      },
      settings: {
        container: {
          journeymap: {
            border_start_pos: 3,
            border: '#447799'
          },
          offering: {
            entity: {
              border_start_pos: 3,
              border: '#447799'
            }
          }
        },
        relationpath: {
          stroke: {
            dasharray: '0,0',
            dasharray_highlight: '3,3',
            width: '1',
            opacity: '0.2'
          },
          marker: {
            opacity: '0.2'
          }
        }
      },
      IntegrationSidebarCollapsed: false,
      externalTicketLayout: 'compact',
      loading: {
        container: false,
        offering: false,
        product: false,
        service: false,
        feature: false,
        risk: false,
        idea: false,
        ticket: false,
        data_import: false,
        general: false
      },
      offerings: [],
      variants: [],
      journeyZoom: 100,
      resetJourneyMap: false,
      canvasDragCoefficientX: 0,
      settingsDialog: false,
      isEntityPopoverVisible: false,
      // Idea
      createOfferingIdeaDialog: false,
      idea: {
        scope: '',
        scopename: '',
        scopeid: ''
      },
      // Risk
      risk_form: {
        title: '',
        description: '',
        severity: 'Low',
        occuranceprobability: 'Low',
        impactlevel: 'Low',
        mitigationplan: '',
        entityname: '',
        entityid: ''
      },
      addRiskDialog: false,
      // Offering
      createOfferingDialog: false,
      isUnmappedVisible: true,
      // Phase
      phaseDetailsDialog: false,
      phaseHover: false,
      selectedPhase: {},
      selectedPhaseIndex: null,
      selectedPhaseX: 0,
      selectedPhaseWidth: 0,
      phase_pos_x: [],
      phase_width: [],
      // Task
      taskDetailsDialog: false,
      taskHover: false,
      selectedTask: {},
      selectedTaskIndex: null,
      selectedTaskX: 0,
      selectedTaskY: 0,
      stepHighlighter: [],
      task_pos_x: [],
      task_pos_y: [],
      // Touchpoint
      selectedTouchpointIndex: null,
      addTouchpointDialog: false,
      touchpointHover: false,
      touchpointType: '',
      selectedTouchpoint: {},
      selectedTouchpointPos: {
        x: 0,
        y: 0
      },
      isTouchpointVisible: true,
      isTouchpointEdit: false,
      isTouchpointReadOnly: true,
      touchpointDetailsDialog: false,
      phaseChannelGapHighlighter: [],
      maxedPhaseChannelGapHighlighter: [],
      touchpointApplications: [{
        name: 'App',
        icon: 'app'
      }, {
        name: 'Email',
        icon: 'email'
      }, {
        name: 'SMS',
        icon: 'sms'
      }],
      touchpoints: [{
        'touchpoint': {
          'id': '2c928082853eb11701853ecc0c220006',
          'title': 'Test',
          'description': 'Test',
          'relations': null,
          'additionalattributes': {
            'minimized': false
          },
          'linkedTouchpointApplications': [{
            'touchpointApplication': {
              'id': '2c92808285d1339d0185d1498e4b0000',
              'title': 'App',
              'description': 'App',
              'type': 'app',
              'status': null,
              'complexity': null,
              'additionalattributes': {
                'posX': 183,
                'posY': 795,
                'type': 'mobile',
                'minimized': false
              },
              '__typename': 'TouchpointApplication'
            },
            '__typename': 'LinkTouchpointApplicationService'
          }, {
            'touchpointApplication': {
              'id': '2c92808285d1339d0185d1498e4b0001',
              'title': 'Email',
              'description': 'Email',
              'type': 'email',
              'status': null,
              'complexity': null,
              'additionalattributes': {
                'posX': 183,
                'posY': 795,
                'type': 'mobile',
                'minimized': false
              },
              '__typename': 'TouchpointApplication'
            },
            '__typename': 'LinkTouchpointApplicationService'
          }, {
            'touchpointApplication': {
              'id': '2c92808285d1339d0185d1498e4b0002',
              'title': 'Sms',
              'description': 'Sms',
              'type': 'sms',
              'status': null,
              'complexity': null,
              'additionalattributes': {
                'posX': 183,
                'posY': 795,
                'type': 'mobile',
                'minimized': false
              },
              '__typename': 'TouchpointApplication'
            },
            '__typename': 'LinkTouchpointApplicationService'
          }],
          '__typename': 'Touchpoint'
        },
        '__typename': 'LinkTouchpointOffering'
      }],
      // Entity
      selectedEntityIndex: null,
      selectedEntityId: null,
      // Handles hover of phase, step, feature and service application
      selectedEntity: {},
      // handles product and service
      mergedEntities: [],
      // Relations
      isRelationsVisible: true,
      // Product
      addProductDialog: false,
      productDetailsDialog: false,
      displayIntegrationSettingsDrawer: false,
      displayImportedTicketsDrawer: false,
      showImportProductUI: false,
      productReportDialog: false,
      isProductsVisible: true,
      products: [],
      selectedProductIdeas: [],
      selectedProductRisks: [],
      product_form: {
        title: '',
        description: '',
        offeringId: '',
        manager: '',
        owner: ''
      },
      // Service
      addServiceDialog: false,
      serviceDetailsDialog: false,
      serviceReportDialog: false,
      showImportServiceUI: false,
      isServicesVisible: true,
      selectedServiceIdeas: [],
      selectedServiceRisks: [],
      services: [],
      service_form: {
        title: '',
        description: '',
        offeringId: '',
        manager: '',
        owner: ''
      },
      // Feature
      addFeatureDialog: false,
      recomputeFeaturePos: false,
      featureHighlighter: [],
      feature: {
        hover: false,
        x: 0,
        y: 0,
        index: 0,
        sub_index: 0,
        entity: {},
        product_id: null,
        offering_id: null,
        step_id: null
      },
      feature_form: {
        key: '',
        title: '',
        description: '',
        status: 'Backlog',
        priority: 'Medium',
        productName: '',
        productId: '',
        offeringId: '',
        posX: 0,
        issue_source: 'prodiggi',
        step_id: ''
      },
      feature_pos_x: [],
      feature_pos_y: [],
      // ServiceApplication
      serviceApplicationHighlighter: [],
      addServiceApplicationDialog: false,
      recomputeServiceApplicationPos: false,
      serviceApplicationHover: false,
      service_application: {
        hover: false,
        x: 0,
        y: 0,
        index: 0,
        sub_index: 0,
        entity: {},
        service_id: null,
        offering_id: null,
        step_id: null
      },
      service_application_form: {
        id: '',
        title: '',
        description: '',
        priority: 'Medium',
        status: 'Backlog',
        serviceId: '',
        serviceTitle: '',
        featureId: '',
        stepId: '',
        enabler: false,
        type: 'automated',
        posX: 0,
        posY: 0
      },
      // Ticket
      ticket: {
        id: '',
        title: ''
      },
      hasTicket: false,
      ticketDetailsDialog: false,
      displayImportTicketsDialog: false,
      activeTicketParent: {},
      selectedTicket: {},
      selectedParent: {},
      selectedParentIndex: null,
      selectedParentDetailType: '',
      displaySyncTicketsDialog: false,
      syncTicketProgress: {
        loading: false,
        loading_message: ''
      },
      import_ticket_form: {
        external_ticket: '',
        import_type: 'epic'
      },
      integrations: [],
      // Hover
      drop_hover_details: {
        x: -100,
        y: -100,
        width: 60,
        height: 70,
        hover: false
      },
      // Form
      offering_form: {
        title: '',
        description: '',
        offeringtype: 'External',
        offeringcategory: 'PRIMARY',
        variant: 'variant',
        customerTypeId: '',
        type: 'new'
      },
      statuses: [{
        label: 'Candidate',
        value: 'Candidate'
      }, {
        label: 'Approved',
        value: 'Approved'
      }, {
        label: 'Backlog',
        value: 'Backlog'
      }, {
        label: 'In-Progress',
        value: 'In-Progress'
      }, {
        label: 'Finalised',
        value: 'Finalised'
      }, {
        label: 'Released',
        value: 'Released'
      }, {
        label: 'Improvement',
        value: 'Improvement'
      }, {
        label: 'Retired',
        value: 'Retired'
      }],
      // Context Menu
      product_menu: {
        visible: false,
        x: 0,
        y: 0,
        path: '',
        has_unmapped: false,
        values: [{
          title: 'Mapped'
        }, {
          title: 'Reports'
        }, {
          title: 'Idea Details'
        }, {
          title: 'Risk Details'
        }]
      },
      touchpoint_settings: {
        visible: false,
        x: 0,
        y: 0,
        path: '',
        values: [{
          title: 'Configure Touchpoints'
        }]
      },
      product_settings: {
        visible: false,
        x: 0,
        y: 0,
        path: '',
        values: [{
          title: 'Product Details'
        }, {
          title: 'Integration Settings'
        }, {
          title: 'Manage imported tickets'
        }, {
          title: 'Sync Tickets'
        }, {
          title: 'Prioritise External Backlog'
        }, {
          title: 'Auto arrange'
        }, {
          title: 'Unlink'
        }
        /* {
          title: 'Delete'
        }*/]
      },
      service_menu: {
        visible: false,
        x: 0,
        y: 0,
        path: '',
        has_unmapped: false,
        values: [{
          title: 'Mapped'
        }, {
          title: 'Reports'
        }, {
          title: 'Idea Details'
        }, {
          title: 'Risk Details'
        }]
      },
      service_settings: {
        visible: false,
        x: 0,
        y: 0,
        path: '',
        values: [{
          title: 'Service Details'
        }, {
          title: 'Integration Settings'
        }, {
          title: 'Manage imported tickets'
        }, {
          title: 'Sync Tickets'
        }, {
          title: 'Prioritise External Backlog'
        }, {
          title: 'Auto arrange'
        }, {
          title: 'Unlink'
        }
        /* {
          title: 'Delete'
        }*/]
      },
      users: [],
      pagination: {
        pages: 0,
        total: 0
      },
      refreshKey: 0,
      relationRefreshKey: 0,
      loop: 6,
      timer: null,
      subentitydragged: false
    };
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['sidebar', 'firstName', 'middleName', 'lastName'])), {}, {
    computeLoggedInUserName: function computeLoggedInUserName() {
      var name = '';
      if (this.firstName !== undefined) {
        name = this.firstName;
      }
      if (this.middleName !== undefined) {
        name += ' ' + this.middleName;
      }
      if (this.lastName !== undefined) {
        name += ' ' + this.lastName;
      }
      return name;
    },
    filteredProductList: function filteredProductList() {
      var self = this;
      return this.products.filter(function (product) {
        if (self.sortedProductIds.indexOf(product.id) > -1) {
          return false;
        }
        return true;
      });
    },
    filteredServiceList: function filteredServiceList() {
      var self = this;
      return this.services.filter(function (service) {
        if (self.sortedServiceIds.indexOf(service.id) > -1) {
          return false;
        }
        return true;
      });
    },
    computeHeaderStyle: function computeHeaderStyle() {
      this.refreshKey;
      if (this.sidebar.opened) {
        return 'background-color: ghostwhite; padding: 10pt 0 0 6pt; height: 38pt; border-radius: 4pt; box-shadow: 3px 3px 3px 2px rgba(0,0,0,0.5); width: calc(100% - 215px); margin-top:3pt';
      } else {
        return 'background-color: ghostwhite; padding: 10pt 0 0 6pt; height: 38pt; border-radius: 4pt; box-shadow: 3px 3px 3px 2px rgba(0,0,0,0.5); width: calc(100% - 50px); margin-top:3pt';
      }
    },
    sortedProductIds: function sortedProductIds() {
      var sorted = [];
      if (this._.isEmpty(this.offering)) {
        return sorted;
      } else if (this.offering.additionalattributes && this.offering.additionalattributes.entitiesSortOrder && this.offering.additionalattributes.entitiesSortOrder.length > 0) {
        for (var k = 0; k < this.offering.additionalattributes.entitiesSortOrder.length; k++) {
          for (var l = 0; l < this.offering.linkedProducts.length; l++) {
            if (this.offering.linkedProducts[l].product.id === this.offering.additionalattributes.entitiesSortOrder[k].id && this.offering.additionalattributes.entitiesSortOrder[k].type === 'product') {
              sorted.push(this.offering.linkedProducts[l].product.id);
              break;
            }
          }
        }
      } else if (this.offering.additionalattributes && this.offering.additionalattributes.productsSortOrder && this.offering.additionalattributes.productsSortOrder.length > 0) {
        for (var i = 0; i < this.offering.additionalattributes.productsSortOrder.length; i++) {
          for (var j = 0; j < this.offering.linkedProducts.length; j++) {
            if (this.offering.linkedProducts[j].product.id === this.offering.additionalattributes.productsSortOrder[i]) {
              sorted.push(this.offering.linkedProducts[j].product.id);
              break;
            }
          }
        }
      } else {
        for (var m = 0; m < this.offering.linkedProducts.length; m++) {
          sorted.push(this.offering.linkedProducts[m].product.id);
        }
      }
      return sorted;
    },
    sortedProducts: function sortedProducts() {
      var sorted = [];
      if (this._.isEmpty(this.offering)) {
        return sorted;
      } else if (this.offering.additionalattributes && this.offering.additionalattributes.entitiesSortOrder && this.offering.additionalattributes.entitiesSortOrder.length > 0) {
        for (var k = 0; k < this.offering.additionalattributes.entitiesSortOrder.length; k++) {
          for (var l = 0; l < this.offering.linkedProducts.length; l++) {
            if (this.offering.linkedProducts[l].product.id === this.offering.additionalattributes.entitiesSortOrder[k].id && this.offering.additionalattributes.entitiesSortOrder[k].type === 'product') {
              sorted.push(this.offering.linkedProducts[l]);
              break;
            }
          }
        }
      } else if (this.offering.additionalattributes && this.offering.additionalattributes.productsSortOrder && this.offering.additionalattributes.productsSortOrder.length > 0) {
        for (var i = 0; i < this.offering.additionalattributes.productsSortOrder.length; i++) {
          for (var j = 0; j < this.offering.linkedProducts.length; j++) {
            if (this.offering.linkedProducts[j].product.id === this.offering.additionalattributes.productsSortOrder[i]) {
              sorted.push(this.offering.linkedProducts[j]);
              break;
            }
          }
        }
      } else {
        sorted = this.offering.linkedProducts;
      }
      return sorted;
    },
    sortedServiceIds: function sortedServiceIds() {
      var sorted = [];
      if (this._.isEmpty(this.offering)) {
        return sorted;
      } else if (this.offering.additionalattributes !== null && this.offering.additionalattributes.entitiesSortOrder && this.offering.additionalattributes.entitiesSortOrder.length > 0) {
        for (var l = 0; l < this.offering.additionalattributes.entitiesSortOrder.length; l++) {
          for (var m = 0; m < this.offering.linkedServices.length; m++) {
            if (this.offering.linkedServices[m].service.id === this.offering.additionalattributes.entitiesSortOrder[l].id && this.offering.additionalattributes.entitiesSortOrder[l].type === 'service') {
              sorted.push(this.offering.linkedServices[m].service.id);
              break;
            }
          }
        }
      } else if (this.offering.additionalattributes !== null && this.offering.additionalattributes.servicesSortOrder && this.offering.additionalattributes.servicesSortOrder.length > 0) {
        for (var i = 0; i < this.offering.additionalattributes.servicesSortOrder.length; i++) {
          for (var j = 0; j < this.offering.linkedServices.length; j++) {
            if (this.offering.linkedServices[j].service.id === this.offering.additionalattributes.servicesSortOrder[i]) {
              sorted.push(this.offering.linkedServices[j].service.id);
              break;
            }
          }
        }
      } else {
        for (var k = 0; k < this.offering.linkedServices.length; k++) {
          sorted.push(this.offering.linkedServices[k].service.id);
        }
      }
      return sorted;
    },
    sortedServices: function sortedServices() {
      var sorted = [];
      if (this._.isEmpty(this.offering)) {
        return sorted;
      } else if (this.offering.additionalattributes !== null && this.offering.additionalattributes.entitiesSortOrder && this.offering.additionalattributes.entitiesSortOrder.length > 0) {
        for (var k = 0; k < this.offering.additionalattributes.entitiesSortOrder.length; k++) {
          for (var l = 0; l < this.offering.linkedServices.length; l++) {
            if (this.offering.linkedServices[l].service.id === this.offering.additionalattributes.entitiesSortOrder[k].id && this.offering.additionalattributes.entitiesSortOrder[k].type === 'service') {
              sorted.push(this.offering.linkedServices[l]);
              break;
            }
          }
        }
      } else if (this.offering.additionalattributes !== null && this.offering.additionalattributes.servicesSortOrder && this.offering.additionalattributes.servicesSortOrder.length > 0) {
        for (var i = 0; i < this.offering.additionalattributes.servicesSortOrder.length; i++) {
          for (var j = 0; j < this.offering.linkedServices.length; j++) {
            if (this.offering.linkedServices[j].service.id === this.offering.additionalattributes.servicesSortOrder[i]) {
              sorted.push(this.offering.linkedServices[j]);
              break;
            }
          }
        }
      } else {
        sorted = this.offering.linkedServices;
      }
      return sorted;
    },
    computeAddRiskDialogTitle: function computeAddRiskDialogTitle() {
      return 'Add Risk to ' + (0, _utils.capitalize)(this.risk_form.entityname.toLowerCase());
    },
    computeExternalTicketDrawerSize: function computeExternalTicketDrawerSize() {
      // var screenSize = screenRatio()
      if (this.externalTicketLayout === 'detailed') {
        return '80%';
      }
      return '80%';
    },
    computedAddFeatureDialogTitle: function computedAddFeatureDialogTitle() {
      if (!this.feature.product_id) {
        return 'Add Feature to Step';
      } else {
        return 'Add Feature to Product';
      }
    },
    computeOfferingHeight: function computeOfferingHeight() {
      var height = 0;
      for (var i = 0; i < this.mergedEntities.length; i++) {
        height += 110;
        if (this.mergedEntities[i].__typename === 'LinkProductOffering') {
          if (this.offering.additionalattributes.entitiesSortOrder[i].minimized) {
            height += 25;
          }
          if (this.productHasUnmappedFeatures(this.mergedEntities[i].product)) {
            height += 50;
          }
          if (this.productHasEnablerFeatures(this.mergedEntities[i].product)) {
            height += 50;
          }
        }
        if (this.mergedEntities[i].__typename === 'LinkServiceOffering') {
          if (this.offering.additionalattributes.entitiesSortOrder[i].minimized) {
            height += 25;
          }
          if (this.serviceHasUnmappedFeatures(this.mergedEntities[i].service)) {
            height += 50;
          }
          if (this.serviceHasEnablerFeatures(this.mergedEntities[i].service)) {
            height += 50;
          }
        }
      }
      height += this.computeJourneyMapHeight + 100; // touchpoint lane height
      if (height < 2000) {
        return 2000;
      }
      return height;
    },
    computeJourneyMapWidth: function computeJourneyMapWidth() {
      if (this._.isEmpty(this.journeyMap)) {
        return 3000;
      }
      var length = 0;
      var buffer = 500;
      for (var i = 0; i < this.journeyMap.linkedJourneyPhases.length; i++) {
        length += this.computePhaseLength(this.journeyMap.linkedJourneyPhases[i].journeyPhase, i);
        length += 75;
      }
      length += buffer;
      if (length > 3000) {
        return length;
      }
      return 3000;
    },
    computeJourneyMapHeight: function computeJourneyMapHeight() {
      if (this._.isEmpty(this.journeyMap)) {
        return 0;
      }
      // Pseudo code
      // 1. Create an object array of all stages and step IDs with count set to 0

      var size_array = [];
      var size_object = {};
      for (var j = 0; j < this.sortedStages.length; j++) {
        size_object = {};
        size_object.id = this.sortedStages[j].journeyPhase.id;
        size_object.count = 0;
        size_array.push(size_object);
      }
      for (var m = 0; m < this.sortedStages.length; m++) {
        for (var i = 0; i < this.journeyMap.additionalattributes.relations.length; i++) {
          if (this.journeyMap.additionalattributes.relations[i].start_type === 'Phase' && this.journeyMap.additionalattributes.relations[i].end_type === 'Step' && this.journeyMap.additionalattributes.relations[i].start_id === this.sortedStages[m].journeyPhase.id) {
            size_array[m].count++;
          }
        }
      }
      var max_count = 0;
      for (var n = 0; n < size_array.length; n++) {
        if (size_array[n].count > max_count) {
          max_count = size_array[n].count;
        }
      }
      // limit max_count to total rows for steps
      if (max_count > 7) {
        max_count = 7;
      }
      return 140 + max_count * 30;
    },
    computeTouchpointHeight: function computeTouchpointHeight() {
      if (this._.isEmpty(this.journeyMap)) {
        return 0;
      }
      return 100;
    },
    sortedStages: function sortedStages() {
      var stagesSortOrder = [];
      if (this._.isEmpty(this.journeyMap)) {
        return stagesSortOrder;
      }
      if (this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages.length > 0 && this.journeyMap.linkedJourneyPhases.length > 0) {
        for (var i = 0; i < this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages.length; i++) {
          for (var j = 0; j < this.journeyMap.linkedJourneyPhases.length; j++) {
            if (this.journeyMap.linkedJourneyPhases[j].journeyPhase.id === this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[i].id && !this.journeyMap.linkedJourneyPhases[j].journeyPhase.isDeleted) {
              stagesSortOrder.push(this.journeyMap.linkedJourneyPhases[j]);
              break;
            }
          }
        }
      } else {
        for (var k = 0; k < this.journeyMap.linkedJourneyPhases.length; k++) {
          if (!this.journeyMap.linkedJourneyPhases[j].journeyPhase.hasOwnProperty('isDeleted')) {
            stagesSortOrder.push(this.journeyMap.linkedJourneyPhases[j]);
          } else if (this.journeyMap.linkedJourneyPhases[j].journeyPhase.hasOwnProperty('isDeleted') && this.journeyMap.linkedJourneyPhases[j].journeyPhase.isDeleted === false) {
            stagesSortOrder.push(this.journeyMap.linkedJourneyPhases[j]);
            break;
          }
        }
      }
      return stagesSortOrder;
    },
    computedTaskList: function computedTaskList() {
      var stepList = [];
      if (this._.isEmpty(this.journeyMap)) {
        return stepList;
      }
      if (this.journeyMap && this.journeyMap.linkedJourneySteps) {
        for (var i = 0; i < this.journeyMap.linkedJourneySteps.length; i++) {
          if (this.computeJourneyStepDisplayCondition(this.journeyMap.linkedJourneySteps[i], i)) {
            stepList.push(this.journeyMap.linkedJourneySteps[i]);
          }
        }
      }
      return stepList;
    },
    computeEntityPosY: function computeEntityPosY() {
      if (this._.isEmpty(this.journeyMap)) {
        return 20;
      }
      if (this.journeyMap.additionalattributes.layouts.horizontal.isTouchpointVisible) {
        if (this.touchpoints[0].touchpoint.additionalattributes.minimized) {
          return this.computeJourneyMapHeight + 70;
        } else {
          return this.computeJourneyMapHeight + 120;
        }
      }
      return this.computeJourneyMapHeight + 20;
    }
  }),
  watch: {
    'offering.id': function offeringId(newVal, oldVal) {
      this.retrieveVariants();
    },
    offering: {
      deep: true,
      handler: function handler() {
        this.recomputeServiceApplicationPos = !this.recomputeServiceApplicationPos;
        this.recomputeFeaturePos = !this.recomputeFeaturePos;
        this.relationRefreshKey++;
      }
    },
    mergedEntities: {
      deep: true,
      handler: function handler() {
        if (!this.subentitydragged) {
          this.computeAllFeaturePos();
        }
      }
    },
    sidebar: function sidebar(val) {
      this.refreshKey++;
    }
  },
  created: function created() {},
  mounted: function mounted() {
    this.isProductsVisible = this.offering.additionalattributes.entityLane.isProductsVisible;
    this.isServicesVisible = this.offering.additionalattributes.entityLane.isServicesVisible;
    this.isUnmappedVisible = this.offering.additionalattributes.entityLane.isUnmappedVisible;
    this.isTouchpointVisible = this.journeyMap.additionalattributes.layouts.horizontal.isTouchpointVisible;
    this.upgradeLegacyDataJourneyMap();
    this.upgradeLegacySorting();
    this.retrieveVariants();
    // this.$_computeOfferingProgress(this.offering)
    // console.log(this.$_offering)
    this.$_computeJourneymapProgress(this.offering, this.journeyMap);
  },
  methods: {
    updateOfferingAdditionalAttributes: function updateOfferingAdditionalAttributes(additionalattributes, refresh) {
      this.$emit('update-offering-additionalattributes', additionalattributes, refresh);
    },
    recomputeMergedEntities: function recomputeMergedEntities() {
      var sorted = [];
      var products = [];
      var services = [];
      if (this._.isEmpty(this.offering)) {
        return sorted;
      }
      products = this.offering.linkedProducts;
      services = this.offering.linkedServices;
      if (this.offering.additionalattributes.entityLane.isProductsVisible && this.offering.additionalattributes.entityLane.isServicesVisible) {
        if (this.offering.additionalattributes.entitiesSortOrder) {
          for (var i = 0; i < this.offering.additionalattributes.entitiesSortOrder.length; i++) {
            if (this.offering.additionalattributes.entitiesSortOrder[i].type === 'product') {
              for (var j = 0; j < products.length; j++) {
                if (products[j].product.id === this.offering.additionalattributes.entitiesSortOrder[i].id) {
                  sorted.push(products[j]);
                }
              }
            } else if (this.offering.additionalattributes.entitiesSortOrder[i].type === 'service') {
              for (var k = 0; k < services.length; k++) {
                if (services[k].service.id === this.offering.additionalattributes.entitiesSortOrder[i].id) {
                  sorted.push(services[k]);
                }
              }
            }
          }
        } else {
          sorted = sorted.concat(products.concat(services));
        }
      }
      if (!this.offering.additionalattributes.entityLane.isProductsVisible) {
        sorted = sorted.concat(services);
      }
      if (!this.offering.additionalattributes.entityLane.isServicesVisible) {
        sorted = sorted.concat(products);
      }
      return sorted;
    },
    recomputeSubEntityPos: function recomputeSubEntityPos() {
      var entitiesSortOrder = [];
      var mergedEntities = this.recomputeMergedEntities();
      // Compute positions.
      var feature_pos_x = new Array(mergedEntities.length);
      var feature_pos_y = new Array(mergedEntities.length);
      for (var l = 0; l < mergedEntities.length; l++) {
        if (mergedEntities[l].__typename === 'LinkProductOffering') {
          feature_pos_x[l] = new Array(mergedEntities[l].product.linkedFeatures.length);
          feature_pos_y[l] = new Array(mergedEntities[l].product.linkedFeatures.length);
          for (var q = 0; q < mergedEntities[l].product.linkedFeatures.length; q++) {
            // X
            if (mergedEntities[l].product.additionalattributes.minimized) {
              feature_pos_x[l][q] = q % 100 * 30 + 168;
            } else {
              if (mergedEntities[l].product.linkedFeatures[q].productFeature.relations.steps[0].id === null && !this.hasFeatureRelation(mergedEntities[l].product.linkedFeatures[q].productFeature.id)) {
                feature_pos_x[l][q] = this.computeLegacyProductUnmappedIndex(mergedEntities, l, q) % 50 * 50 + 100;
              } else if (mergedEntities[l].product.linkedFeatures[q].productFeature.enabler) {
                feature_pos_x[l][q] = this.computeProductEnablerParentX(mergedEntities[l].product.linkedFeatures[q].productFeature.id) + this.computeLegacyProductEnablerIndex(mergedEntities, l, q) % 50 * 50;
              } else {
                feature_pos_x[l][q] = this.computePhaseXFromStepID(mergedEntities[l].product.linkedFeatures[q].productFeature.relations.steps[0].id) + this.computeFilteredProductFeatureIndex(mergedEntities[l].product, q) % 50 * 50;
              }
            }

            // Y
            if (mergedEntities[l].product.additionalattributes.minimized) {
              feature_pos_y[l][q] = this.computeEntityPosY + this.computeLegacyEntityLaneHeightCompounded(mergedEntities, l) + 5;
            } else {
              if (mergedEntities[l].product.linkedFeatures[q].productFeature.enabler) {
                feature_pos_y[l][q] = this.computeEntityPosY + this.computeLegacyEntityLaneHeightCompounded(mergedEntities, l) + 5 + 105;
              } else if (mergedEntities[l].product.linkedFeatures[q].productFeature.relations.steps[0].id === null) {
                feature_pos_y[l][q] = this.computeEntityPosY + this.computeLegacyEntityLaneHeightCompounded(mergedEntities, l) + 5 + 155;
              } else {
                feature_pos_y[l][q] = this.computeEntityPosY + this.computeLegacyEntityLaneHeightCompounded(mergedEntities, l) + 5 + 5;
              }
            }
          }
        }
        if (mergedEntities[l].__typename === 'LinkServiceOffering') {
          feature_pos_x[l] = new Array(mergedEntities[l].service.linkedServiceApplications.length);
          feature_pos_y[l] = new Array(mergedEntities[l].service.linkedServiceApplications.length);
          for (var r = 0; r < mergedEntities[l].service.linkedServiceApplications.length; r++) {
            // X
            if (mergedEntities[l].service.additionalattributes.minimized) {
              feature_pos_x[l][r] = r % 100 * 30 + 168;
            } else {
              if (mergedEntities[l].service.linkedServiceApplications[r].serviceApplication.relations.id === null && !mergedEntities[l].service.linkedServiceApplications[r].serviceApplication.enabler) {
                feature_pos_x[l][r] = 175 + this.computeLegacyServiceUnmappedIndex(mergedEntities, l, r) % 50 * 50 + 215;
              } else if (mergedEntities[l].service.linkedServiceApplications[r].serviceApplication.relations.id !== null && mergedEntities[l].service.linkedServiceApplications[r].serviceApplication.enabler) {
                feature_pos_x[l][r] = this.computePhaseXFromStepID(mergedEntities[l].service.linkedServiceApplications[r].serviceApplication.relations.id) + this.computeServiceEnablerIndex(l, r) % 50 * 50;
              } else {
                feature_pos_x[l][r] = this.computePhaseXFromStepID(mergedEntities[l].service.linkedServiceApplications[r].serviceApplication.relations.id) + this.computeFilteredServiceApplicationIndex(mergedEntities[l].service, r) % 50 * 50;
              }
            }
            // Y
            if (mergedEntities[l].service.additionalattributes.minimized) {
              feature_pos_y[l][r] = this.computeEntityPosY + this.computeLegacyEntityLaneHeightCompounded(mergedEntities, l) + 5;
            } else {
              if (mergedEntities[l].service.linkedServiceApplications[r].serviceApplication.enabler) {
                feature_pos_y[l][r] = this.computeEntityPosY + this.computeLegacyEntityLaneHeightCompounded(mergedEntities, l) + 5 + 110;
              } else if (mergedEntities[l].service.linkedServiceApplications[r].serviceApplication.relations.id === null || !this.hasServiceApplicationRelation(mergedEntities[l].service.linkedServiceApplications[r].serviceApplication.id)) {
                feature_pos_y[l][r] = this.computeEntityPosY + this.computeLegacyEntityLaneHeightCompounded(mergedEntities, l) + 5 + 160;
              } else {
                feature_pos_y[l][r] = this.computeEntityPosY + this.computeLegacyEntityLaneHeightCompounded(mergedEntities, l) + 5;
              }
            }
          }
        }
      }
      // End compute positions

      for (var m = 0; m < mergedEntities.length; m++) {
        if (mergedEntities[m].__typename === 'LinkProductOffering') {
          var features = [];
          for (var n = 0; n < mergedEntities[m].product.linkedFeatures.length; n++) {
            var feature = {};
            feature.id = mergedEntities[m].product.linkedFeatures[n].productFeature.id;
            feature.posX = feature_pos_x[m][n];
            feature.posY = feature_pos_y[m][n];
            features.push(feature);
          }
          entitiesSortOrder.push({
            type: 'product',
            minimized: false,
            id: mergedEntities[m].product.id,
            'features': features
          });
        }
        if (mergedEntities[m].__typename === 'LinkServiceOffering') {
          var service_applications = [];
          for (var o = 0; o < mergedEntities[m].service.linkedServiceApplications.length; o++) {
            var service_application = {};
            service_application.id = mergedEntities[m].service.linkedServiceApplications[o].serviceApplication.id;
            service_application.posX = feature_pos_x[m][o];
            service_application.posY = feature_pos_y[m][o];
            service_applications.push(service_application);
          }
          entitiesSortOrder.push({
            type: 'service',
            minimized: false,
            id: mergedEntities[m].service.id,
            'service_applications': service_applications
          });
        }
      }
      var additionalattributes = this.offering.additionalattributes;
      additionalattributes.entitiesSortOrder = entitiesSortOrder;
      this.$emit('update-offering-additionalattributes', additionalattributes, true);
    },
    upgradeLegacyDataJourneyMap: function upgradeLegacyDataJourneyMap() {
      if (!this.journeyMap.additionalattributes.layouts.horizontal.hasOwnProperty('isTouchpointVisible')) {
        var additionalattributes = this.journeyMap.additionalattributes;
        additionalattributes.layouts.horizontal.isTouchpointVisible = true;
        this.$emit('update-journeymap-additionalattributes', additionalattributes, true);
      }
    },
    upgradeLegacySorting: function upgradeLegacySorting() {
      // this.computeAllFeaturePos()
      this.computePhaseX();
      if (!this.offering.additionalattributes.hasOwnProperty('entitiesSortOrder') || this.offering.additionalattributes.entitiesSortOrder.length === 0 && (this.offering.linkedProducts.length > 0 || this.offering.linkedServices.length > 0) || this.offering.additionalattributes.entitiesSortOrder.length > 0 && !this.offering.additionalattributes.entitiesSortOrder[0].hasOwnProperty('features') && !this.offering.additionalattributes.entitiesSortOrder[0].hasOwnProperty('service_applications')) {
        var entitiesSortOrder = [];
        var products = [];
        var services = [];
        var mergedEntities = [];
        // Generate merged entities
        products = this.offering.linkedProducts;
        services = this.offering.linkedServices;
        mergedEntities = mergedEntities.concat(products.concat(services));
        // MergedEntities generated

        // Compute positions.
        var feature_pos_x = new Array(mergedEntities.length);
        var feature_pos_y = new Array(mergedEntities.length);
        for (var l = 0; l < mergedEntities.length; l++) {
          if (mergedEntities[l].__typename === 'LinkProductOffering') {
            feature_pos_x[l] = new Array(mergedEntities[l].product.linkedFeatures.length);
            feature_pos_y[l] = new Array(mergedEntities[l].product.linkedFeatures.length);
            for (var q = 0; q < mergedEntities[l].product.linkedFeatures.length; q++) {
              // X
              if (mergedEntities[l].product.additionalattributes.minimized) {
                feature_pos_x[l][q] = q % 100 * 30 + 168;
              } else {
                if (mergedEntities[l].product.linkedFeatures[q].productFeature.relations.steps[0].id === null && !this.hasFeatureRelation(mergedEntities[l].product.linkedFeatures[q].productFeature.id)) {
                  feature_pos_x[l][q] = this.computeLegacyProductUnmappedIndex(mergedEntities, l, q) % 50 * 50 + 100;
                } else if (mergedEntities[l].product.linkedFeatures[q].productFeature.enabler) {
                  feature_pos_x[l][q] = this.computeProductEnablerParentX(mergedEntities[l].product.linkedFeatures[q].productFeature.id) + this.computeLegacyProductEnablerIndex(mergedEntities, l, q) % 50 * 50;
                } else {
                  feature_pos_x[l][q] = this.computePhaseXFromStepID(mergedEntities[l].product.linkedFeatures[q].productFeature.relations.steps[0].id) + this.computeFilteredProductFeatureIndex(mergedEntities[l].product, q) % 50 * 50;
                }
              }

              // Y
              if (mergedEntities[l].product.additionalattributes.minimized) {
                feature_pos_y[l][q] = this.computeEntityPosY + this.computeLegacyEntityLaneHeightCompounded(mergedEntities, l) + 5;
              } else {
                if (mergedEntities[l].product.linkedFeatures[q].productFeature.enabler) {
                  feature_pos_y[l][q] = this.computeEntityPosY + this.computeLegacyEntityLaneHeightCompounded(mergedEntities, l) + 5 + 105;
                } else if (mergedEntities[l].product.linkedFeatures[q].productFeature.relations.steps[0].id === null) {
                  feature_pos_y[l][q] = this.computeEntityPosY + this.computeLegacyEntityLaneHeightCompounded(mergedEntities, l) + 5 + 155;
                } else {
                  feature_pos_y[l][q] = this.computeEntityPosY + this.computeLegacyEntityLaneHeightCompounded(mergedEntities, l) + 5 + 5;
                }
              }
            }
          }
          if (mergedEntities[l].__typename === 'LinkServiceOffering') {
            feature_pos_x[l] = new Array(mergedEntities[l].service.linkedServiceApplications.length);
            feature_pos_y[l] = new Array(mergedEntities[l].service.linkedServiceApplications.length);
            for (var r = 0; r < mergedEntities[l].service.linkedServiceApplications.length; r++) {
              // X
              if (mergedEntities[l].service.additionalattributes.minimized) {
                feature_pos_x[l][r] = r % 100 * 30 + 168;
              } else {
                if (mergedEntities[l].service.linkedServiceApplications[r].serviceApplication.relations.id === null && !mergedEntities[l].service.linkedServiceApplications[r].serviceApplication.enabler) {
                  feature_pos_x[l][r] = 175 + this.computeLegacyServiceUnmappedIndex(mergedEntities, l, r) % 50 * 50 + 215;
                } else if (mergedEntities[l].service.linkedServiceApplications[r].serviceApplication.relations.id !== null && mergedEntities[l].service.linkedServiceApplications[r].serviceApplication.enabler) {
                  feature_pos_x[l][r] = this.computePhaseXFromStepID(mergedEntities[l].service.linkedServiceApplications[r].serviceApplication.relations.id) + this.computeServiceEnablerIndex(l, r) % 50 * 50;
                } else {
                  feature_pos_x[l][r] = this.computePhaseXFromStepID(mergedEntities[l].service.linkedServiceApplications[r].serviceApplication.relations.id) + this.computeFilteredServiceApplicationIndex(mergedEntities[l].service, r) % 50 * 50;
                }
              }
              // Y
              if (mergedEntities[l].service.additionalattributes.minimized) {
                feature_pos_y[l][r] = this.computeEntityPosY + this.computeLegacyEntityLaneHeightCompounded(mergedEntities, l) + 5;
              } else {
                if (mergedEntities[l].service.linkedServiceApplications[r].serviceApplication.enabler) {
                  feature_pos_y[l][r] = this.computeEntityPosY + this.computeLegacyEntityLaneHeightCompounded(mergedEntities, l) + 5 + 110;
                } else if (mergedEntities[l].service.linkedServiceApplications[r].serviceApplication.relations.id === null || !this.hasServiceApplicationRelation(mergedEntities[l].service.linkedServiceApplications[r].serviceApplication.id)) {
                  feature_pos_y[l][r] = this.computeEntityPosY + this.computeLegacyEntityLaneHeightCompounded(mergedEntities, l) + 5 + 160;
                } else {
                  feature_pos_y[l][r] = this.computeEntityPosY + this.computeLegacyEntityLaneHeightCompounded(mergedEntities, l) + 5;
                }
              }
            }
          }
        }
        // End compute positions

        for (var m = 0; m < mergedEntities.length; m++) {
          if (mergedEntities[m].__typename === 'LinkProductOffering') {
            var features = [];
            for (var n = 0; n < mergedEntities[m].product.linkedFeatures.length; n++) {
              var feature = {};
              feature.id = mergedEntities[m].product.linkedFeatures[n].productFeature.id;
              feature.posX = feature_pos_x[m][n];
              feature.posY = feature_pos_y[m][n];
              feature.enabler = mergedEntities[m].product.linkedFeatures[n].productFeature.enabler;
              feature.unmapped = mergedEntities[m].product.linkedFeatures[n].productFeature.relations.steps[0].id === null;
              features.push(feature);
            }
            entitiesSortOrder.push({
              type: 'product',
              minimized: false,
              id: mergedEntities[m].product.id,
              'features': features
            });
          }
          if (mergedEntities[m].__typename === 'LinkServiceOffering') {
            var service_applications = [];
            for (var o = 0; o < mergedEntities[m].service.linkedServiceApplications.length; o++) {
              var service_application = {};
              service_application.id = mergedEntities[m].service.linkedServiceApplications[o].serviceApplication.id;
              service_application.posX = feature_pos_x[m][o];
              service_application.posY = feature_pos_y[m][o];
              service_application.enabler = mergedEntities[m].service.linkedServiceApplications[o].serviceApplication.enabler;
              service_application.unmapped = mergedEntities[m].service.linkedServiceApplications[o].serviceApplication.relations.id === null;
              service_applications.push(service_application);
            }
            entitiesSortOrder.push({
              type: 'service',
              minimized: false,
              id: mergedEntities[m].service.id,
              'service_applications': service_applications
            });
          }
        }
        var additionalattributes = this.offering.additionalattributes;
        additionalattributes.entitiesSortOrder = entitiesSortOrder;
        additionalattributes.entityLane = {
          'isEnablersVisible': true,
          'isProductsVisible': true,
          'isServicesVisible': true,
          'isUnmappedVisible': true
        };
        this.$emit('update-offering-additionalattributes', additionalattributes, true);
      }
      if (this.offering.additionalattributes.entitiesSortOrder.length > 0 && !this.offering.additionalattributes.entitiesSortOrder[0].hasOwnProperty('minimized')) {
        for (var p = 0; p < this.offering.additionalattributes.entitiesSortOrder.length; p++) {
          this.offering.additionalattributes.entitiesSortOrder[p].minimized = false;
        }
        this.$emit('update-offering-additionalattributes', this.offering.additionalattributes, true);
      }
      if (!this.offering.additionalattributes.entityLane.hasOwnProperty('isProductsVisible')) {
        var additionalattributes_temp = this.offering.additionalattributes;
        additionalattributes_temp.entityLane = {
          'isEnablersVisible': true,
          'isProductsVisible': true,
          'isServicesVisible': true,
          'isUnmappedVisible': true
        };
        this.$emit('update-offering-additionalattributes', additionalattributes_temp, true);
      }
    },
    swapEntitiesPositions: function swapEntitiesPositions() {
      var additionalattributes = this.offering.additionalattributes;
      additionalattributes.entitiesSortOrder = additionalattributes.entitiesSortOrder.reverse();
      this.$emit('update-offering-additionalattributes', additionalattributes, true);
    },
    cleanupSortOrder: function cleanupSortOrder() {
      var additionalattributes = this.offering.additionalattributes;
      additionalattributes.entitiesSortOrder = additionalattributes.entitiesSortOrder.filter(function (n) {
        return n;
      });
      this.$emit('update-offering-additionalattributes', additionalattributes, true);
    },
    computeUserNameFromId: function computeUserNameFromId(user_id) {
      if (user_id === 'admin' || user_id === '' || user_id === null) {
        return user_id;
      }
      for (var i = 0; i < this.users.length; i++) {
        if (this.users[i].id === user_id) {
          return this.users[i].firstName + ' ' + this.users[i].lastName;
        }
      }
      return '';
    },
    trimString: function trimString(string, length) {
      if (string.length > length) {
        return string.substring(0, length) + '...';
      } else {
        return string;
      }
    },
    openExternalTicketListDialog: function openExternalTicketListDialog() {
      this.displayImportTicketsDialog = false;
      this.displayImportedTicketsDrawer = true;
    },
    openImportTicketDialog: function openImportTicketDialog() {
      this.retrieveIntegrations();
      this.displayImportTicketsDialog = true;
      this.displayImportedTicketsDrawer = false;
    },
    isLocalProject: function isLocalProject(project_name) {
      for (var i = 0; i < this.integrations.length; i++) {
        for (var j = 0; j < this.integrations[i].projects.length; j++) {
          if (this.integrations[i].projects[j].name === project_name) {
            return this.integrations[i].projects[j].isLocalProject;
          }
        }
      }
    },
    importProjectTickets: function importProjectTickets() {
      var _this = this;
      for (var i = 0; i < this.integrations.length; i++) {
        for (var j = 0; j < this.integrations[i].projects.length; j++) {
          if (this.integrations[i].projects[j].name === this.import_ticket_form.external_ticket) {
            this.loading.data_import = true;
            var headers = {
              'Content-Type': 'application/json',
              'x-party-id': this.$store.getters.partyId,
              'x-user-id': this.$store.getters.id
            };
            _axios.default.get(process.env.VUE_APP_REST_API_ENDPOINT + 'tprty/' + this.integrations[i].id + '/sync/project/' + this.integrations[i].projects[j].id + '/ticket', {
              headers: headers
            }).then(function (response) {
              _this.loading.data_import = false;
              if (response.data.status === 'SUCCESS') {
                _this.displayImportTicketsDialog = false;
                _this.displayImportedTicketsDrawer = true;
                console.log('Synced');
              }
            }).catch(function (error) {
              _this.loading.data_import = false;
              console.log(error);
            });
          }
        }
      }
    },
    updateProductDetails: function updateProductDetails() {
      var product = Object.assign({}, this.selectedEntity);
      this.$emit('edit-product', product);
    },
    updateServiceDetails: function updateServiceDetails() {
      var service = Object.assign({}, this.selectedEntity);
      this.$emit('edit-service', service);
    },
    updateProjectSelection: function updateProjectSelection(val) {
      this.retrieveExternalTicketList(val);
    },
    initialiseSyncDialog: function initialiseSyncDialog() {
      this.loop = 6;
      this.timer = null;
      this.displaySyncTicketsDialog = true;
    },
    syncTickets: function syncTickets() {
      var _this2 = this;
      this.syncTicketProgress.loading = true;
      if (!this.timer) {
        this.timer = setInterval(function () {
          if (_this2.loop > 0) {
            _this2.syncTicketProgress.loading = true;
            if (_this2.loop === 6) {
              _this2.syncTicketProgress.loading_message = 'Authenticating with JIRA';
            }
            if (_this2.loop === 5) {
              _this2.syncTicketProgress.loading_message = 'Authenticated';
            }
            if (_this2.loop === 4) {
              _this2.syncTicketProgress.loading_message = 'Consolidating Changes...';
            }
            if (_this2.loop === 3) {
              _this2.syncTicketProgress.loading_message = 'Uploading Changes....';
            }
            if (_this2.loop === 2) {
              _this2.syncTicketProgress.loading_message = 'Downloading Changes....';
            }
            if (_this2.loop === 1) {
              _this2.syncTicketProgress.loading_message = 'Synced';
            }
            _this2.loop--;
          } else {
            clearInterval(_this2.timer);
            _this2.syncTicketProgress.loading = false;
            _this2.resetTimer();
          }
        }, 1000);
      }
    },
    resetTimer: function resetTimer() {
      this.timer = null;
    },
    retrieveVariants: function retrieveVariants() {
      var _this3 = this;
      this.variants = [];
      this.$apollo.query({
        // Query
        query: window._gql(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["query Offerings($page: Page, $where: OfferingsCriteriaExpression, $customertypewhere: CustomerTypesCriteriaExpression)\n        {\n        Offerings(page: $page, where: $where)\n        {\n          pages\n          total\n          select{\n            id\n            title\n            description\n            linkedCustomerTypes(optional: true) {\n              customerType(optional: true, where: $customertypewhere){\n                id\n              }\n            }\n          }\n        }\n      }"]))),
        fetchPolicy: 'no-cache',
        variables: {
          'page': {
            'limit': 20,
            'start': 1
          },
          'where': this.computeSearchQuery(),
          'customertypewhere': this.computeCustomerTypeWhereQuery()
        }
      }).then(function (data) {
        _this3.$notify({
          title: 'Success',
          message: 'Retrieved Successfully',
          type: 'success',
          duration: 2000
        });
        _this3.variants = data.data.Offerings.select;
        _this3.offering_loading = false;
      }).catch(function (error) {
        // Error
        _this3.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this3.offering_loading = false;
      });
    },
    computeSearchQuery: function computeSearchQuery() {
      var query = {};
      query.offeringcategory = {
        'EQ': 'Secondary'
      };
      return query;
    },
    computeCustomerTypeWhereQuery: function computeCustomerTypeWhereQuery() {
      var query = {};
      query.id = {
        'LIKE': this.customer.id
      };
      return query;
    },
    computeJourneyStepDisplayCondition: function computeJourneyStepDisplayCondition(task, index) {
      if (task.journeyStep.hasOwnProperty('isDeleted') && task.journeyStep.isDeleted === true) {
        return false;
      }
      return true;
    },
    toggleTouchpointLaneVisibility: function toggleTouchpointLaneVisibility() {
      this.isTouchpointVisible = !this.isTouchpointVisible;
      this.recomputeFeatureAndServiceApplicationPos();
    },
    toggleProductLaneVisibility: function toggleProductLaneVisibility() {
      this.isProductsVisible = !this.isProductsVisible;
      this.recomputeFeatureAndServiceApplicationPos();
    },
    toggleServiceLaneVisibility: function toggleServiceLaneVisibility() {
      this.isServicesVisible = !this.isServicesVisible;
      this.recomputeFeatureAndServiceApplicationPos();
    },
    createOfferingIdea: function createOfferingIdea() {
      this.idea.scope = 'Offering';
      this.idea.scopename = this.offering.title;
      this.idea.scopeid = this.offering.id;
      this.createOfferingIdeaDialog = true;
    },
    updateJourneyMapDragCoefficient: function updateJourneyMapDragCoefficient(coefficient) {
      this.canvasDragCoefficientX = coefficient;
    },
    updateEntityList: function updateEntityList(entities) {
      this.mergedEntities = entities;
    },
    updateFeatureEnabler: function updateFeatureEnabler(enabler) {
      this.feature_form.id = this.selectedTicket.id;
      this.feature_form.enabler = enabler;
      var feature = Object.assign({}, this.feature_form);
      this.$emit('edit-feature-enabler', feature);
      this.resetFeatureForm();
    },
    updateStepFeatureRelation: function updateStepFeatureRelation(feature_id, relations) {
      this.$emit('update-step-to-feature-relation', feature_id, relations);
    },
    updateServiceApplicationRelation: function updateServiceApplicationRelation(service_application_id, relations) {
      this.$emit('update-service-application-relation', service_application_id, relations);
    },
    createServiceApplication: function createServiceApplication() {
      var _this4 = this;
      // this.journey.services.automated.push(system1)
      this.$refs.addServiceApplicationform.validate(function (valid) {
        if (valid) {
          var service_details = _this4.computeServiceFromServiceID(_this4.service_application_form.serviceId);
          var temp_service_application_ids = [];
          for (var j = 0; j < service_details[0].service.linkedServiceApplications.length; j++) {
            if (!service_details[0].service.linkedServiceApplications[j].serviceApplication.enabler) {
              temp_service_application_ids.push(service_details[0].service.linkedServiceApplications[j].serviceApplication.id);
            }
          }
          var existing_service_application_length = temp_service_application_ids.length;
          var service_application_form = Object.assign({}, _this4.service_application_form);
          if (service_application_form.enabler) {
            service_application_form.parent_type = 'feature';
            service_application_form.parent_id = service_application_form.featureId;

            // this.service_application_form.posX = this.getFeaturePosFromIDX(service_application_form.featureId)
            service_application_form.posX = _this4.computePhaseXFromFeatureID(service_application_form.featureId) + existing_service_application_length % 50 * 50;
            service_application_form.posY = _this4.computeEntityPosY + _this4.computeEntityLaneHeightCompounded(service_details[1]) + 10;
          } else {
            service_application_form.parent_type = 'step';
            service_application_form.parent_id = service_application_form.stepId;
            service_application_form.posX = _this4.computePhaseXFromStepID(service_application_form.stepId) + existing_service_application_length % 50 * 50;
            service_application_form.posY = _this4.computeEntityPosY + _this4.computeEntityLaneHeightCompounded(service_details[1]) + 10;
            // this.service_application_form.posX = this.getTaskX(service_application_form.stepId)
          }
          service_application_form.ticket_type = 'Process Epic';
          _this4.$emit('add-service-application', service_application_form);
          _this4.addServiceApplicationDialog = false;
          _this4.resetServiceApplicationForm();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    updateTicketDialogTitle: function updateTicketDialogTitle(ticket) {
      this.ticket = ticket;
    },
    updateTicketParentType: function updateTicketParentType(type) {
      this.selectedParentDetailType = type;
    },
    updateTicketActiveParent: function updateTicketActiveParent(parent_id) {
      var flag = false;
      for (var i = 0; i < this.offering.linkedProducts.length; i++) {
        if (this.offering.linkedProducts[i].product.id === parent_id) {
          flag = true;
          this.activeTicketParent = this.offering.linkedProducts[i].product;
        }
      }
      if (!flag) {
        for (var j = 0; j < this.offering.linkedServices.length; j++) {
          if (this.offering.linkedServices[j].service.id === parent_id) {
            this.activeTicketParent = this.offering.linkedServices[j].service;
          }
        }
      }
    },
    updateChildTypeFromTicket: function updateChildTypeFromTicket(additionalattributes, id) {
      this.$emit('edit-service-application-type', id, additionalattributes);
      this.addServiceApplicationDialog = false;
      this.resetServiceApplicationForm();
    },
    updateChildTitleDescriptionFromTicket: function updateChildTitleDescriptionFromTicket(title, description, parent_type) {
      if (parent_type === 'Product') {
        this.feature_form.id = this.selectedTicket.id;
        this.feature_form.title = title;
        this.feature_form.description = description;
        var feature = Object.assign({}, this.feature_form);
        this.$emit('edit-feature-title-description', feature);
        this.addFeatureDialog = false;
        this.resetFeatureForm();
      } else if (parent_type === 'Service') {
        this.service_application_form.id = this.selectedTicket.id;
        this.service_application_form.title = title;
        this.service_application_form.description = description;
        var service_application = Object.assign({}, this.service_application_form);
        this.$emit('edit-service-application-title-description', service_application);
        this.addServiceApplicationDialog = false;
        this.resetServiceApplicationForm();
      }
    },
    computeTicketDetailTitle: function computeTicketDetailTitle() {
      if (this.ticket.externalId !== undefined) {
        return this.ticket.externalId + '-' + this.ticket.title;
      }
      return this.ticket.title;
    },
    openTicketDetailDialog: function openTicketDetailDialog(ticket, parent, ticket_index) {
      var type = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'Product';
      if (type === 'Product') {
        /*
          Ticket Component - Feature Mapping
          ticket = feature
          parent_index = product_index
          ticket_index = feature_index
        */
        this.selectedProductIndex = this.computeProductIndexFromProduct(parent);
        this.selectedTicket = ticket;
        this.selectedFeatureIndex = ticket_index;
        this.selectedParentDetailType = type;
        /* if (feature.productFeature.additionalattributes.issueSource === 'jira') {
          // TODO
        }*/
        this.activeTicketParent = parent;
        this.ticketDetailsDialog = true;
      } else if (type === 'Service') {
        // TODO add support for Service
        /*
          Ticket Component -  Service Application Mapping
          ticket = service application
          parent_index = service_index
          ticket_index = service_application_index
        */

        this.selectedTicket = ticket;
        this.selectedFeatureIndex = ticket_index;
        this.selectedServiceIndex = this.computeServiceIndexFromService(parent);
        this.selectedParentDetailType = type;
        this.activeTicketParent = parent;
        this.ticketDetailsDialog = true;
      }
    },
    externalTicketImported: function externalTicketImported() {
      this.$emit('reload');
      this.recomputeFeatureAndServiceApplicationPos();
    },
    updateExternalTicketDrawerLayout: function updateExternalTicketDrawerLayout(layout) {
      this.externalTicketLayout = layout;
    },
    deleteFeature: function deleteFeature(feature_id) {
      var _this5 = this;
      this.$confirm('This will permanently delete the feature. Continue?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(function () {
        _this5.$emit('delete-feature', feature_id);
      }).catch(function () {
        _this5.$message({
          type: 'info',
          message: 'Delete canceled'
        });
      });
    },
    deleteIdea: function deleteIdea(idea_id) {
      var _this6 = this;
      this.$confirm('This will permanently delete the idea. Continue?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(function () {
        _this6.$emit('delete-idea', idea_id);
      }).catch(function () {
        _this6.$message({
          type: 'info',
          message: 'Delete canceled'
        });
      });
    },
    deleteRisk: function deleteRisk(risk_id) {
      var _this7 = this;
      this.$confirm('This will permanently delete the risk. Continue?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(function () {
        _this7.$emit('delete-risk', risk_id);
      }).catch(function () {
        _this7.$message({
          type: 'info',
          message: 'Delete canceled'
        });
      });
    },
    createRisk: function createRisk() {
      var _this8 = this;
      this.$refs.risk_form.validate(function (valid) {
        if (valid) {
          var risk = Object.assign({}, _this8.risk_form);
          _this8.$emit('add-risk', risk);
          _this8.resetRiskForm();
          _this8.addRiskDialog = false;
        } else {
          _this8.loading.risk = false;
          return false;
        }
      });
    },
    selectCreateFeatureFromProductDialog: function selectCreateFeatureFromProductDialog(product_id) {
      this.feature_form.productId = product_id;
      this.feature_form.offeringId = this.offering.id;
      this.addFeatureDialog = true;
    },
    selectCreateRiskFromProductDialog: function selectCreateRiskFromProductDialog(product_id) {
      this.risk_form.entityname = 'PRODUCT';
      this.risk_form.entityid = product_id;
      this.addRiskDialog = true;
    },
    selectCreateRiskFromServiceDialog: function selectCreateRiskFromServiceDialog(service_id) {
      this.risk_form.entityname = 'SERVICE';
      this.risk_form.entityid = service_id;
      this.addRiskDialog = true;
    },
    selectCreateProcessFromServiceDialog: function selectCreateProcessFromServiceDialog(service_id, service_title, type) {
      this.service_application_form.enabler = false;
      this.service_application_form.serviceId = service_id;
      this.service_application_form.stepId = '';
      this.service_application_form.type = type;
      this.addServiceApplicationDialog = true;
    },
    selectServiceSetting: function selectServiceSetting(row, column, event) {
      var _this9 = this;
      // this.resetProductSettings()
      this.service_settings.visible = false;
      switch (row.title) {
        case 'Service Details':
          if (this.users.length === 0) {
            this.retrieveUserList();
          }
          this.serviceDetailsDialog = true;
          break;
        case 'Integration Settings':
          this.displayIntegrationSettingsDrawer = true;
          break;
        case 'Import Tickets':
          this.retrieveIntegrations();
          this.displayImportTicketsDialog = true;
          break;
        case 'Manage imported tickets':
          this.displayImportedTicketsDrawer = true;
          break;
        case 'Sync Tickets':
          this.initialiseSyncDialog();
          break;
        case 'Unlink':
          this.$confirm('This will permanently unlink the service. Continue?', 'Warning', {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            type: 'warning'
          }).then(function () {
            _this9.$emit('unlink-service', _this9.selectedEntity.id, _this9.offering.id);
          }).catch(function () {
            _this9.$message({
              type: 'info',
              message: 'Unlink canceled'
            });
          });
          break;
        case 'Delete':
          this.$confirm('This will permanently delete the service. Continue?', 'Warning', {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            type: 'warning'
          }).then(function () {
            _this9.$emit('delete-service', _this9.selectedEntity.id);
          }).catch(function () {
            _this9.$message({
              type: 'info',
              message: 'Delete canceled'
            });
          });
          break;
        case 'Move up':
          this.moveUpEntitySortOrder(this.selectedEntity.id);
          break;
        case 'Move down':
          this.moveDownEntitySortOrder(this.selectedEntity.id);
          break;
        default:
          this.$swal({
            title: row.title,
            text: 'To be updated',
            icon: 'info'
          }).then(function (response) {});
          break;
      }
    },
    selectProductMenu: function selectProductMenu(row, column, event) {
      // this.resetProductSettings()
      this.product_menu.visible = false;
      switch (row.title) {
        case 'Mapped':
          break;
        case 'Reports':
          this.displayProductReport();
          break;
        case 'Idea Details':
          this.displayProductDetails();
          break;
        case 'Risk Details':
          this.displayProductDetails();
          break;
        default:
          this.$swal({
            title: row.title,
            text: 'To be updated',
            icon: 'info'
          }).then(function (response) {});
          break;
      }
    },
    selectServiceMenu: function selectServiceMenu(row, column, event) {
      // this.resetProductSettings()
      this.service_menu.visible = false;
      switch (row.title) {
        case 'Mapped':
          break;
        case 'Reports':
          this.displayServiceReport();
          break;
        case 'Idea Details':
          if (this.users.length === 0) {
            this.retrieveUserList();
          }
          this.serviceDetailsDialog = true;
          break;
        case 'Risk Details':
          if (this.users.length === 0) {
            this.retrieveUserList();
          }
          this.serviceDetailsDialog = true;
          break;
        default:
          this.$swal({
            title: row.title,
            text: 'To be updated',
            icon: 'info'
          }).then(function (response) {});
          break;
      }
    },
    selectTouchpointSetting: function selectTouchpointSetting(row, column, event) {
      this.touchpoint_settings.visible = false;
      switch (row.title) {
        case 'Configure Touchpoints':
          this.touchpointDetailsDialog = true;
          break;
        default:
          this.$swal({
            title: row.title,
            text: 'To be updated',
            icon: 'info'
          }).then(function (response) {});
          break;
      }
    },
    selectProductSetting: function selectProductSetting(row, column, event) {
      var _this10 = this;
      // this.resetProductSettings()
      this.product_settings.visible = false;
      switch (row.title) {
        case 'Product Details':
          if (this.users.length === 0) {
            this.retrieveUserList();
          }
          this.productDetailsDialog = true;
          break;
        case 'Integration Settings':
          this.displayIntegrationSettingsDrawer = true;
          break;
        case 'Import Tickets':
          this.retrieveIntegrations();
          this.displayImportTicketsDialog = true;
          break;
        case 'Manage imported tickets':
          this.displayImportedTicketsDrawer = true;
          break;
        case 'Sync Tickets':
          this.initialiseSyncDialog();
          break;
        case 'Unlink':
          this.$confirm('This will permanently unlink the product. Continue?', 'Warning', {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            type: 'warning'
          }).then(function () {
            _this10.$emit('unlink-product', _this10.selectedEntity.id, _this10.offering.id);
          }).catch(function () {
            _this10.$message({
              type: 'info',
              message: 'Unlink canceled'
            });
          });
          break;
        case 'Delete':
          this.$confirm('This will permanently delete the product. Continue?', 'Warning', {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            type: 'warning'
          }).then(function () {
            _this10.$emit('delete-product', _this10.selectedEntity.id);
          }).catch(function () {
            _this10.$message({
              type: 'info',
              message: 'Delete canceled'
            });
          });
          break;
        case 'Move up':
          this.moveUpEntitySortOrder(this.selectedEntity.id);
          break;
        case 'Move down':
          this.moveDownEntitySortOrder(this.selectedEntity.id);
          break;
        default:
          this.$swal({
            title: row.title,
            text: 'To be updated',
            icon: 'info'
          }).then(function (response) {});
          break;
      }
    },
    moveUpEntitySortOrder: function moveUpEntitySortOrder(entity_id) {
      var entity_index = null;
      var entity_height = 0;
      var prev_entity_height = 0;
      for (var i = 0; i < this.offering.additionalattributes.entitiesSortOrder.length; i++) {
        if (this.offering.additionalattributes.entitiesSortOrder[i].id === entity_id) {
          entity_index = i;
          break;
        }
      }
      if (entity_index !== null && entity_index > 0) {
        var additionalattributes = this.offering.additionalattributes;
        var tmp = {};
        tmp = this.offering.additionalattributes.entitiesSortOrder[entity_index - 1];
        this.offering.additionalattributes.entitiesSortOrder[entity_index - 1] = this.offering.additionalattributes.entitiesSortOrder[entity_index];
        this.offering.additionalattributes.entitiesSortOrder[entity_index] = tmp;
        entity_height = this.computeEntityLaneHeight(entity_index);
        prev_entity_height = this.computeEntityLaneHeight(entity_index - 1);
        if (this.offering.additionalattributes.entitiesSortOrder[entity_index].type === 'product') {
          for (var k = 0; k < this.offering.additionalattributes.entitiesSortOrder[entity_index].features.length; k++) {
            this.offering.additionalattributes.entitiesSortOrder[entity_index].features[k].posY += entity_height;
          }
        }
        if (this.offering.additionalattributes.entitiesSortOrder[entity_index].type === 'service') {
          for (var l = 0; l < this.offering.additionalattributes.entitiesSortOrder[entity_index].service_applications.length; l++) {
            this.offering.additionalattributes.entitiesSortOrder[entity_index].service_applications[l].posY += entity_height;
          }
        }
        if (this.offering.additionalattributes.entitiesSortOrder[entity_index - 1].type === 'product') {
          for (var m = 0; m < this.offering.additionalattributes.entitiesSortOrder[entity_index - 1].features.length; m++) {
            this.offering.additionalattributes.entitiesSortOrder[entity_index - 1].features[m].posY -= prev_entity_height;
          }
        }
        if (this.offering.additionalattributes.entitiesSortOrder[entity_index - 1].type === 'service') {
          for (var n = 0; n < this.offering.additionalattributes.entitiesSortOrder[entity_index - 1].service_applications.length; n++) {
            this.offering.additionalattributes.entitiesSortOrder[entity_index - 1].service_applications[n].posY -= prev_entity_height;
          }
        }
        this.relationRefreshKey++;
        this.$emit('update-offering-additionalattributes', additionalattributes, true);
      }
    },
    moveDownEntitySortOrder: function moveDownEntitySortOrder(entity_id) {
      var entity_index = null;
      var entity_height = 0;
      var next_entity_height = 0;
      for (var i = 0; i < this.offering.additionalattributes.entitiesSortOrder.length; i++) {
        if (this.offering.additionalattributes.entitiesSortOrder[i].id === entity_id) {
          entity_index = i;
          break;
        }
      }
      if (entity_index !== null && entity_index < this.offering.additionalattributes.entitiesSortOrder.length - 1) {
        var additionalattributes = this.offering.additionalattributes;
        var tmp = {};
        tmp = this.offering.additionalattributes.entitiesSortOrder[entity_index + 1];
        this.offering.additionalattributes.entitiesSortOrder[entity_index + 1] = this.offering.additionalattributes.entitiesSortOrder[entity_index];
        this.offering.additionalattributes.entitiesSortOrder[entity_index] = tmp;
        entity_height = this.computeEntityLaneHeight(entity_index);
        next_entity_height = this.computeEntityLaneHeight(entity_index + 1);
        if (this.offering.additionalattributes.entitiesSortOrder[entity_index].type === 'product') {
          for (var k = 0; k < this.offering.additionalattributes.entitiesSortOrder[entity_index].features.length; k++) {
            this.offering.additionalattributes.entitiesSortOrder[entity_index].features[k].posY -= entity_height;
          }
        }
        if (this.offering.additionalattributes.entitiesSortOrder[entity_index].type === 'service') {
          for (var l = 0; l < this.offering.additionalattributes.entitiesSortOrder[entity_index].service_applications.length; l++) {
            this.offering.additionalattributes.entitiesSortOrder[entity_index].service_applications[l].posY -= entity_height;
          }
        }
        if (this.offering.additionalattributes.entitiesSortOrder[entity_index + 1].type === 'product') {
          for (var m = 0; m < this.offering.additionalattributes.entitiesSortOrder[entity_index + 1].features.length; m++) {
            this.offering.additionalattributes.entitiesSortOrder[entity_index + 1].features[m].posY += next_entity_height;
          }
        }
        if (this.offering.additionalattributes.entitiesSortOrder[entity_index + 1].type === 'service') {
          for (var n = 0; n < this.offering.additionalattributes.entitiesSortOrder[entity_index + 1].service_applications.length; n++) {
            this.offering.additionalattributes.entitiesSortOrder[entity_index + 1].service_applications[n].posY += next_entity_height;
          }
        }
        this.relationRefreshKey++;
        this.$emit('update-offering-additionalattributes', additionalattributes, true);
      }
    },
    displayProductDetails: function displayProductDetails() {
      this.selectedEntityIndex = this.computeProductIndexFromProduct(this.selectedEntity);
      this.productDetailsDialog = true;
    },
    displayProductReport: function displayProductReport() {
      this.selectedEntityIndex = this.computeProductIndexFromProduct(this.selectedEntity);
      this.productReportDialog = true;
    },
    displayServiceReport: function displayServiceReport() {
      this.selectedEntityIndex = this.computeServiceIndexFromService(this.selectedEntity);
      this.serviceReportDialog = true;
    },
    computeServiceIndexFromService: function computeServiceIndexFromService(service) {
      for (var i = 0; i < this.offering.linkedServices.length; i++) {
        if (this.offering.linkedServices[i].service.id === service.id) {
          return i;
        }
      }
    },
    computeProductIndexFromProduct: function computeProductIndexFromProduct(product) {
      for (var i = 0; i < this.offering.linkedProducts.length; i++) {
        if (this.offering.linkedProducts[i].product.id === product.id) {
          return i;
        }
      }
    },
    addService: function addService() {
      this.resetServiceForm();
      if (this.users.length === 0) {
        this.retrieveUserList();
      }
      if (this.users.length > 0) {
        this.service_form.manager = this.users[0].firstName + ' ' + this.users[0].lastName;
        this.service_form.owner = this.users[0].firstName + ' ' + this.users[0].lastName;
      }
      this.showImportServiceUI = false;
      this.addServiceDialog = true;
    },
    createService: function createService() {
      var _this11 = this;
      this.$refs.service_form.validate(function (valid) {
        if (valid) {
          _this11.service_form.offeringId = _this11.offering.id;
          var service_function = Object.assign({}, _this11.service_form);
          _this11.$emit('add-service', service_function);
          _this11.addServiceDialog = false;
          _this11.resetServiceForm();
        } else {
          _this11.loading.service = false;
          return false;
        }
      });
    },
    importService: function importService(type, index, service) {
      var service_applications = [];
      switch (type) {
        case 'link':
          service.offeringId = this.offering.id;
          service_applications = this.computeServiceApplicationPosDetails(service);
          this.$emit('link-service', service, service_applications);
          this.addServiceDialog = false;
          this.resetServiceForm();
          break;
        case 'copy':
          service.offeringId = this.offering.id;
          var params = ['SERVICE_APPLICATIONS', 'SUB_SERVICES'];
          // Invoke only if params has 'PRODUCT_FEATURES (its hardcoded now)'
          service_applications = this.computeServiceApplicationPosDetails(service);
          this.$emit('clone-service', service, params, service_applications);
          this.addServiceDialog = false;
          this.resetServiceForm();
          break;
      }
    },
    computeServiceApplicationPosDetails: function computeServiceApplicationPosDetails(service) {
      var service_applications = [];
      for (var i = 0; i < service.linkedServiceApplications.length; i++) {
        var service_application = {};
        var posX = i % 50 * 50 + 150;
        var posY = this.computeEntityPosY + this.computeEntityLaneHeightCompounded(this.mergedEntities.length) + 5 + 100;
        service_application.id = service.linkedServiceApplications[i].serviceApplication.id;
        service_application.posX = posX;
        service_application.posY = posY;
        service_application.unmapped = true;
        service_application.enabler = false;
        service_applications.push(service_application);
      }
      return service_applications;
    },
    paginationServiceCurrentChange: function paginationServiceCurrentChange(page) {
      this.loadServices(page);
    },
    loadServices: function loadServices(page_number) {
      var _this12 = this;
      this.showImportServiceUI = true;
      this.loading.service = true;
      this.services = [];
      this.$apollo.query({
        // Query
        query: window._gql(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["query Services($page: Page)\n        {\n        Services(page: $page)\n        {\n          pages\n          total\n          select{\n            id\n            title\n            description\n            keypersonnel\n            linkedServiceApplications(optional: true) {\n              serviceApplication(optional: true) {\n                id\n                title\n                description\n                priority\n                status\n                enabler\n                complexity\n                relations\n                startdate\n                enddate\n                additionalattributes\n              }\n            }\n          }\n        }\n      }"]))),
        fetchPolicy: 'no-cache',
        variables: {
          'page': {
            'limit': 20,
            'start': page_number
          }
        }
      }).then(function (data) {
        _this12.$notify({
          title: 'Success',
          message: 'Retrieved Successfully',
          type: 'success',
          duration: 2000
        });
        _this12.services = data.data.Services.select;
        _this12.pagination.pages = data.data.Services.pages;
        _this12.pagination.total = data.data.Services.total;
        _this12.loading.service = false;
      }).catch(function (error) {
        // Error
        _this12.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this12.loading.service = false;
      });
    },
    importProduct: function importProduct(type, index, product) {
      var features = [];
      switch (type) {
        case 'link':
          this.product_form.offeringId = this.offering.id;
          product.offeringId = this.offering.id;
          features = this.computeFeaturePosDetails(product);
          this.$emit('link-product', product, features);
          this.addProductDialog = false;
          this.resetProductForm();
          break;
        case 'copy':
          this.product_form.offeringId = this.offering.id;
          product.offeringId = this.offering.id;
          var params = ['PRODUCT_FEATURES', 'PRODUCT_RISKS'];
          // Invoke only if params has 'PRODUCT_FEATURES (its hardcoded now)'
          features = this.computeFeaturePosDetails(product);
          this.$emit('clone-product', product, params, features);
          this.addProductDialog = false;
          this.resetProductForm();
          break;
      }
    },
    computeFeaturePosDetails: function computeFeaturePosDetails(product) {
      var features = [];
      for (var i = 0; i < product.linkedFeatures.length; i++) {
        var feature = {};
        var posX = i % 50 * 50 + 150;
        var posY = this.computeEntityPosY + this.computeEntityLaneHeightCompounded(this.mergedEntities.length) + 5 + 100;
        feature.id = product.linkedFeatures[i].productFeature.id;
        feature.posX = posX;
        feature.posY = posY;
        feature.unmapped = true;
        feature.enabler = false;
        features.push(feature);
      }
      return features;
    },
    paginationProductCurrentChange: function paginationProductCurrentChange(page) {
      this.loadProducts(page);
    },
    loadProducts: function loadProducts(page_number) {
      var _this13 = this;
      this.showImportProductUI = true;
      this.loading.product = true;
      this.products = [];
      this.$apollo.query({
        // Query
        query: window._gql(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["query Products($page: Page)\n        {\n        Products(page: $page)\n        {\n          pages\n          total\n          select{\n            id\n            title\n            description\n            keypersonnel\n            linkedFeatures(optional: true) {\n              productFeature(optional: true){\n                id\n                title\n                description\n                status\n                priority\n                relations\n                startdate\n                enddate\n                additionalattributes\n                enabler\n              }\n            }\n          }\n        }\n      }"]))),
        fetchPolicy: 'no-cache',
        variables: {
          'page': {
            'limit': 20,
            'start': page_number
          }
        }
      }).then(function (data) {
        _this13.$notify({
          title: 'Success',
          message: 'Retrieved Successfully',
          type: 'success',
          duration: 2000
        });
        _this13.products = data.data.Products.select;
        _this13.pagination.pages = data.data.Products.pages;
        _this13.pagination.total = data.data.Products.total;
        _this13.loading.product = false;
      }).catch(function (error) {
        // Error
        _this13.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this13.loading.product = false;
      });
    },
    addProduct: function addProduct() {
      this.resetProductForm();
      if (this.users.length === 0) {
        this.retrieveUserList();
      }
      if (this.users.length > 0) {
        this.product_form.manager = this.users[0].firstName + ' ' + this.users[0].lastName;
        this.product_form.owner = this.users[0].firstName + ' ' + this.users[0].lastName;
      }
      this.showImportProductUI = false;
      this.addProductDialog = true;
    },
    createProduct: function createProduct() {
      var _this14 = this;
      this.$refs.product_form.validate(function (valid) {
        if (valid) {
          _this14.product_form.offeringId = _this14.offering.id;
          var product = Object.assign({}, _this14.product_form);
          _this14.$emit('add-product', product);
          _this14.addProductDialog = false;
          _this14.resetProductForm();
        } else {
          _this14.loading.product = false;
          return false;
        }
      });
    },
    retrieveUserList: function retrieveUserList() {
      var _this15 = this;
      this.loading.user = true;
      var headers = {
        'Content-Type': 'application/json',
        'x-party-id': this.$store.getters.partyId,
        'x-user-id': this.$store.getters.id
      };
      _axios.default.get(process.env.VUE_APP_REST_API_ENDPOINT + 'user/?extended=LEVEL0', {
        headers: headers
      }).then(function (response) {
        _this15.loading.user = false;
        _this15.users = response.data.data;
      }).catch(function (error) {
        if (error.response.status === 400) {
          _this15.loading.user = false;
          _this15.$notify({
            title: 'Error',
            message: error.response.message,
            type: 'error',
            duration: 2000
          });
        }
      });
    },
    displayTouchpointSettings: function displayTouchpointSettings(x, y) {
      this.resetSettingsAndMenu();
      this.touchpoint_settings.visible = true;
      this.touchpoint_settings.x = 47;
      this.touchpoint_settings.y = y + 27;
      var touchpoint_x_start = this.touchpoint_settings.x - 15;
      var touchpoint_y_start = this.touchpoint_settings.y + 10;
      var touchpoint_x_mid = this.touchpoint_settings.x;
      var touchpoint_y_mid = this.touchpoint_settings.y;
      var touchpoint_x_last = this.touchpoint_settings.x;
      var touchpoint_y_last = this.touchpoint_settings.y + 20;
      this.touchpoint_settings.path = 'M ' + touchpoint_x_start + ' ' + touchpoint_y_start + ' L ' + touchpoint_x_mid + ' ' + touchpoint_y_mid + ' L ' + touchpoint_x_last + ' ' + touchpoint_y_last + '';
    },
    displayEntitySettings: function displayEntitySettings(entity_type, entity, index, x, y) {
      this.resetSettingsAndMenu();
      switch (entity_type) {
        case 'product':
          this.selectedEntityIndex = this.computeProductIndexFromProduct(entity);
          this.selectedEntity = entity;
          this.selectedParentDetailType = 'Product';
          this.selectedParentIndex = this.computeProductIndexFromProduct(entity);
          this.selectedParent = entity;
          this.product_settings.visible = true;
          this.product_settings.x = 43;
          this.product_settings.y = y + 28;
          var product_x_start = this.product_settings.x - 15;
          var product_y_start = this.product_settings.y + 10;
          var product_x_mid = this.product_settings.x;
          var product_y_mid = this.product_settings.y;
          var product_x_last = this.product_settings.x;
          var product_y_last = this.product_settings.y + 20;
          this.product_settings.path = 'M ' + product_x_start + ' ' + product_y_start + ' L ' + product_x_mid + ' ' + product_y_mid + ' L ' + product_x_last + ' ' + product_y_last + '';
          break;
        case 'service':
          this.selectedEntityIndex = this.computeServiceIndexFromService(entity);
          this.selectedEntity = entity;
          this.selectedParentDetailType = 'Service';
          this.selectedParentIndex = this.computeServiceIndexFromService(entity);
          this.selectedParent = entity;
          this.service_settings.visible = true;
          this.service_settings.x = 43;
          this.service_settings.y = y + 28;
          var service_x_start = this.service_settings.x - 15;
          var service_y_start = this.service_settings.y + 10;
          var service_x_mid = this.service_settings.x;
          var service_y_mid = this.service_settings.y;
          var service_x_last = this.service_settings.x;
          var service_y_last = this.service_settings.y + 20;
          this.service_settings.path = 'M ' + service_x_start + ' ' + service_y_start + ' L ' + service_x_mid + ' ' + service_y_mid + ' L ' + service_x_last + ' ' + service_y_last + '';
          break;
      }
    },
    displayEntityMenu: function displayEntityMenu(entity_type, entity, index, x, y, has_unmapped) {
      this.resetSettingsAndMenu();
      switch (entity_type) {
        case 'product':
          this.selectedParentDetailType = 'Product';
          this.selectedParentIndex = index;
          this.selectedParent = entity;
          this.product_menu.visible = true;
          this.product_menu.x = 43;
          this.product_menu.y = y + 48;
          this.product_menu.has_unmapped = has_unmapped;
          var product_x_start = this.product_menu.x - 15;
          var product_y_start = this.product_menu.y + 10;
          var product_x_mid = this.product_menu.x;
          var product_y_mid = this.product_menu.y;
          var product_x_last = this.product_menu.x;
          var product_y_last = this.product_menu.y + 20;
          this.product_menu.path = 'M ' + product_x_start + ' ' + product_y_start + ' L ' + product_x_mid + ' ' + product_y_mid + ' L ' + product_x_last + ' ' + product_y_last + '';
          break;
        case 'service':
          this.selectedParentDetailType = 'Service';
          this.selectedParentIndex = index;
          this.selectedParent = entity;
          this.service_menu.visible = true;
          this.service_menu.x = 43;
          this.service_menu.y = y + 48;
          this.service_menu.has_unmapped = has_unmapped;
          var service_x_start = this.service_menu.x - 15;
          var service_y_start = this.service_menu.y + 10;
          var service_x_mid = this.service_menu.x;
          var service_y_mid = this.service_menu.y;
          var service_x_last = this.service_menu.x;
          var service_y_last = this.service_menu.y + 20;
          this.service_menu.path = 'M ' + service_x_start + ' ' + service_y_start + ' L ' + service_x_mid + ' ' + service_y_mid + ' L ' + service_x_last + ' ' + service_y_last + '';
          break;
      }
      this.selectedEntityIndex = index;
      this.selectedEntity = entity;
    },
    createEntityIdea: function createEntityIdea(scope, entity_id, entity_title) {
      this.idea.scope = scope;
      this.idea.scopename = entity_title;
      this.idea.scopeid = entity_id;
      this.createOfferingIdeaDialog = true;
    },
    displayOfferingList: function displayOfferingList() {
      this.retrieveOfferings();
      this.offering_form.type = 'existing';
    },
    retrieveOfferings: function retrieveOfferings() {
      var _this16 = this;
      this.loading.offering = true;
      this.$apollo.query({
        // Query
        query: window._gql(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2.default)(["query Offerings($page: Page)\n        {\n        Offerings(page: $page)\n        {\n          pages\n          total\n          select{\n            id\n            title\n            description\n            offeringcategory\n            linkedProducts(optional: true) {\n              product(optional: true) {\n                id\n                title\n                description\n                vision\n                keypersonnel\n                additionalattributes\n                linkedFeatures(optional: true) {\n                  productFeature(optional: true){\n                    id\n                    title\n                    description\n                    status\n                    priority\n                    additionalattributes\n                  }\n                }\n              }\n            }\n            linkedServices(optional: true) {\n              service(optional: true) {\n                id\n                title\n                description\n                servicetype\n                servicecosts\n                revenuedetails\n                relations\n                design\n                additionalattributes\n                linkedServiceApplications(optional: true) {\n                  serviceApplication(optional: true) {\n                    id\n                    title\n                    description\n                    priority\n                    status\n                    enabler\n                    complexity\n                    relations\n                    additionalattributes\n                  }\n                }\n              }\n            }\n          }\n        }\n      }"]))),
        fetchPolicy: 'no-cache',
        variables: {
          'page': {
            'limit': 20,
            'start': 1
          }
        }
      }).then(function (data) {
        _this16.$notify({
          title: 'Success',
          message: 'Retrieved Successfully',
          type: 'success',
          duration: 2000
        });
        _this16.offerings = [];
        _this16.offerings = data.data.Offerings.select;
        _this16.loading.offering = false;
      }).catch(function (error) {
        // Error
        _this16.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this16.loading.offering = false;
      });
    },
    createOffering: function createOffering() {
      var _this17 = this;
      this.$refs.offering_form.validate(function (valid) {
        if (valid) {
          _this17.offering_form.customerTypeId = _this17.customer.id;
          if (_this17.offering_form.variant === 'variant') {
            _this17.offering_form.offeringcategory = 'SECONDARY';
          } else {
            _this17.offering_form.offeringcategory = 'PRIMARY';
          }
          var offering = Object.assign({}, _this17.offering_form);
          _this17.$emit('add-offering', offering);
          _this17.resetOfferingForm();
          _this17.createOfferingDialog = false;
        } else {
          _this17.loading.offering = false;
          return false;
        }
      });
    },
    displayPhaseDetails: function displayPhaseDetails(phase, index) {
      this.resetHoverDetailsDialog();
      this.selectedPhase = phase;
      this.selectedPhaseIndex = index;
      this.phaseDetailsDialog = true;
    },
    phaseMouseOver: function phaseMouseOver(phase, index, x, width) {
      this.resetHoverDetailsDialog();
      if (this.isEntityPopoverVisible) {
        this.phaseHover = true;
      }
      this.selectedPhase = phase;
      this.selectedEntityId = phase.journeyPhase.id;
      this.selectedPhaseIndex = index;
      this.selectedPhaseX = x;
      this.selectedPhaseWidth = width;
    },
    phaseMouseLeave: function phaseMouseLeave() {
      this.phaseHover = false;
      this.selectedPhase = {};
      this.selectedPhaseIndex = null;
      this.selectedEntityId = null;
      this.selectedPhaseX = 0;
      this.selectedPhaseWidth = 0;
    },
    onTouchpointMouseOver: function onTouchpointMouseOver(stage_index, touchpoint_index, x, y) {
      this.selectedTouchpointIndex = touchpoint_index;
      this.selectedPhaseIndex = stage_index;
      this.selectedTouchpoint = this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[stage_index].touchpoints[touchpoint_index];
      this.selectedTouchpointPos.x = x;
      this.selectedTouchpointPos.y = y;
      this.touchpointHover = true;
    },
    onTouchpointMouseLeave: function onTouchpointMouseLeave() {
      this.selectedPhaseIndex = null;
      this.selectedTouchpointIndex = null;
      this.selectedTouchpoint = {};
      this.selectedTouchpointPos.x = 0;
      this.selectedTouchpointPos.y = 0;
      this.touchpointHover = false;
    },
    selectTouchpoint: function selectTouchpoint(stage_index, touchpoint_index) {
      this.selectedTouchpointIndex = touchpoint_index;
      this.selectedPhaseIndex = stage_index;
      this.selectedTouchpoint = this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[stage_index].touchpoints[touchpoint_index];
      this.touchpointType = this.selectedTouchpoint.type;
      this.isTouchpointReadOnly = true;
      this.isTouchpointEdit = true;
      this.addTouchpointDialog = true;
    },
    displayTaskDetails: function displayTaskDetails(task, index) {
      this.resetHoverDetailsDialog();
      this.selectedTask = task;
      this.selectedTaskIndex = index;
      this.taskDetailsDialog = true;
    },
    taskMouseOver: function taskMouseOver(task, index, x, y) {
      this.resetHoverDetailsDialog();
      if (this.isEntityPopoverVisible) {
        this.taskHover = true;
      }
      this.selectedTask = task;
      this.selectedEntityId = task.journeyStep.id;
      this.selectedTaskIndex = index;
      this.selectedTaskX = x + 170;
      this.selectedTaskY = y - 95;
    },
    taskMouseLeave: function taskMouseLeave() {
      this.taskHover = false;
      this.selectedTask = {};
      this.selectedTaskIndex = null;
      this.selectedEntityId = null;
      this.selectedTaskX = 0;
      this.selectedTaskY = 0;
    },
    onFeatureMouseOver: function onFeatureMouseOver(feature, feature_index, x, y) {
      this.feature.hover = true;
      this.feature.x = x;
      this.feature.y = y;
      this.feature.entity = feature;
      this.selectedEntityId = feature.id;
    },
    onFeatureMouseLeave: function onFeatureMouseLeave() {
      this.feature.hover = false;
      this.feature.x = 0;
      this.feature.y = 0;
      this.feature.entity = {};
      this.selectedEntityId = null;
    },
    onServiceApplicationMouseOver: function onServiceApplicationMouseOver(service_application, service_application_index, x, y) {
      this.service_application.hover = true;
      this.service_application.x = x;
      this.service_application.y = y;
      this.service_application.entity = service_application;
      this.selectedEntityId = service_application.id;
    },
    onServiceApplicationMouseLeave: function onServiceApplicationMouseLeave() {
      this.service_application.hover = false;
      this.service_application.x = 0;
      this.service_application.y = 0;
      this.service_application.entity = {};
      this.selectedEntityId = null;
    },
    // Helper Functions
    computedIdeasForPhase: function computedIdeasForPhase(phase) {
      var ideas = [];
      if (phase.title) {
        for (var i = 0; i < this.journeyMap.linkedBusinessIdeas.length; i++) {
          if (this.journeyMap.linkedBusinessIdeas[i].businessIdea.scope === 'Phase' && this.journeyMap.linkedBusinessIdeas[i].businessIdea.scopename === phase.title) {
            ideas.push(this.journeyMap.linkedBusinessIdeas[i].businessIdea);
          }
        }
      }
      return ideas;
    },
    computedIdeasForStep: function computedIdeasForStep(step) {
      var ideas = [];
      if (step.title) {
        for (var i = 0; i < this.journeyMap.linkedBusinessIdeas.length; i++) {
          if (this.journeyMap.linkedBusinessIdeas[i].businessIdea.scope === 'Step' && this.journeyMap.linkedBusinessIdeas[i].businessIdea.scopename === step.title) {
            ideas.push(this.journeyMap.linkedBusinessIdeas[i].businessIdea);
          }
        }
      }
      return ideas;
    },
    computePhaseLength: function computePhaseLength(phase, index) {
      // get max number of features in product for given phase
      // if less than 2 use default 175, else use length off features
      var max_count = 0;

      // Get an array of pains/gains for current phase
      if (typeof phase === 'undefined') {
        return 225;
      }
      if (phase.additionalattributes && phase.additionalattributes.ui.isCollapse) {
        return 50;
      }
      if (phase.relations !== null && phase.relations !== undefined) {
        max_count = phase.relations.length;
      }
      var additional_columns = Math.floor(max_count / 5) - 1;
      var first_column = 150;
      return 225 + first_column + additional_columns * 150;
    },
    zoomJourney: function zoomJourney() {
      var root = document.querySelector(':root');
      root.style.setProperty('--cssZoomVar', this.journeyZoom / 100);
    },
    onTouchPointToolDragged: function onTouchPointToolDragged(index, type, x, y, first, last) {
      if (last !== undefined && last) {
        this.resetHighlightAllPhaseChannelGap();
        this.resetDropHover();
        for (var i = 0; i < this.sortedStages.length; i++) {
          var phase_x = this.phase_pos_x[i];
          var phase_length = this.computePhaseLength(this.sortedStages[i].journeyPhase, i);
          if (x > phase_x && x < phase_x + phase_length) {
            if (y > this.computeJourneyMapHeight + 15 && y < this.computeJourneyMapHeight + 15 + 100) {
              if (!this.hasMaxTouchpoints(this.sortedStages[i].journeyPhase.id)) {
                this.showAddTouchPointDialog(this.sortedStages[i].journeyPhase, i, type);
              }
            }
          }
        }
        return;
      }
      if (first !== undefined && first) {
        return;
      }
      if (this.sortedStages.length > 0) {
        this.highlightAllPhaseChannelGap();
      }
      for (var j = 0; j < this.sortedStages.length; j++) {
        var phase_x1 = this.phase_pos_x[j];
        var phase_length1 = this.computePhaseLength(this.sortedStages[j].journeyPhase, j);
        if (x > phase_x1 && x < phase_x1 + phase_length1) {
          if (y > this.computeJourneyMapHeight + 15 && y < this.computeJourneyMapHeight + 15 + 100) {
            if (!this.hasMaxTouchpoints(this.sortedStages[j].journeyPhase.id)) {
              this.highlightActivePhase(j, this.computeJourneyMapHeight + 15, 100);
            }
          }
        }
      }
    },
    hasMaxTouchpoints: function hasMaxTouchpoints(stage_id) {
      for (var i = 0; i < this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages.length; i++) {
        if (this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[i].id === stage_id) {
          return this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[i].touchpoints.length >= 6;
        }
      }
      return false;
    },
    addTouchpoint: function addTouchpoint(touchpoint) {
      this.$emit('add-touchpoint', touchpoint, this.selectedPhase);
      for (var i = 0; i < this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages.length; i++) {
        if (this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[i].id === this.selectedPhase.id) {
          if (!this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[i].hasOwnProperty('touchpoints')) {
            this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[i].touchpoints = [];
          }
          var flag = false;
          for (var j = 0; j < this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[i].touchpoints.length; j++) {
            if (this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[i].touchpoints[j].type === touchpoint.type) {
              flag = true;
            }
          }
          if (!flag) {
            this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[i].touchpoints.push(touchpoint);
            this.updateJourneyMapParamAndAdditionalAttributesServer();
          }
        }
      }
      this.closeAddTouchpointDialog();
    },
    deleteTouchpoint: function deleteTouchpoint() {
      this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[this.selectedPhaseIndex].touchpoints.splice(this.selectedTouchpointIndex, 1);
      this.updateJourneyMapParamAndAdditionalAttributesServer();
      this.resetHoverDetailsDialog();
    },
    updateJourneyMapParamAndAdditionalAttributesServer: function updateJourneyMapParamAndAdditionalAttributesServer() {
      var _this18 = this;
      var additionalattributes = this.journeyMap.additionalattributes;
      this.loading.general = true;
      this.$apollo.mutate({
        // Query
        mutation: window._gql(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2.default)(["mutation updateJourneyMap($journeymap: UpdateJourneyMapInput!)\n        {\n          updateJourneyMap(input: $journeymap) {\n            id\n          }\n        }"]))),
        // Parameters
        variables: {
          'journeymap': {
            'id': this.journeyMap.id,
            'additionalattributes': additionalattributes
          }
        }
      }).then(function (data) {
        _this18.loading.general = false;
      }).catch(function (error) {
        // Error
        console.log(error);
        _this18.loading.general = false;
      });
    },
    showAddTouchPointDialog: function showAddTouchPointDialog(phase, phase_index, touchpoint_type) {
      this.selectedPhase = phase;
      this.selectedPhaseIndex = phase_index;
      this.touchpointType = touchpoint_type;
      this.isTouchpointEdit = false;
      this.isTouchpointReadOnly = false;
      this.addTouchpointDialog = true;
    },
    closeAddTouchpointDialog: function closeAddTouchpointDialog() {
      this.isTouchpointEdit = true;
      this.isTouchpointReadOnly = true;
      this.addTouchpointDialog = false;
    },
    onServiceApplicationDragged: function onServiceApplicationDragged(service_id, service_index, service_application_id, service_application_index, x, y) {
      for (var i = 0; i < this.mergedEntities.length; i++) {
        if (this.mergedEntities[i].__typename === 'LinkServiceOffering' && this.mergedEntities[i].service.id === service_id) {
          for (var j = 0; j < this.mergedEntities[i].service.linkedServiceApplications.length; j++) {
            if (this.mergedEntities[i].service.linkedServiceApplications[j].serviceApplication.id === service_application_id) {
              this.feature_pos_x[i][j] = x;
              this.feature_pos_y[i][j] = y;
            }
          }
        }
      }
      this.relationRefreshKey++;
      this.subentitydragged = true;
    },
    onServiceApplicationDraggedStart: function onServiceApplicationDraggedStart(service_id, service_index, service_application_id, service_application_index, x, y) {
      this.subentitydragged = true;
    },
    onServiceApplicationDraggedStop: function onServiceApplicationDraggedStop(service_id, service_index, service_application_id, service_application_index, x, y) {
      for (var i = 0; i < this.mergedEntities.length; i++) {
        if (this.mergedEntities[i].__typename === 'LinkServiceOffering' && this.mergedEntities[i].service.id === service_id) {
          for (var j = 0; j < this.mergedEntities[i].service.linkedServiceApplications.length; j++) {
            if (this.mergedEntities[i].service.linkedServiceApplications[j].serviceApplication.id === service_application_id) {
              this.feature_pos_x[i][j] = x;
              this.feature_pos_y[i][j] = y;
              this.updateServiceApplicationPosToOfferingAdditionalAttributes(service_id, service_application_id, x, y);
            }
          }
        }
      }
      this.relationRefreshKey++;
      this.subentitydragged = true;
    },
    updateServiceApplicationServiceApplicationRelation: function updateServiceApplicationServiceApplicationRelation(start_id, start_type, end_id, end_type) {
      this.$emit('update-service-application-service-application-relation', start_id, start_type, end_id, end_type);
    },
    onServiceApplicationToolDragged: function onServiceApplicationToolDragged(service_id, x, y, type) {
      this.highlightAllSteps();
      this.highlightAllFeatures();
      for (var k = 0; k < this.computedTaskList.length; k++) {
        if (x > this.task_pos_x[k] + 5 && x < this.task_pos_x[k] + 140 && y > this.task_pos_y[k] - 10 && y < this.task_pos_y[k] + 30) {
          this.drop_hover_details.x = this.task_pos_x[k] - 5;
          this.drop_hover_details.y = this.task_pos_y[k] - 2;
          this.drop_hover_details.width = 140;
          this.drop_hover_details.height = 40;
          this.drop_hover_details.hover = true;
          window._jquery('#hover_highlight > rect').attr('x', this.drop_hover_details.x);
          window._jquery('#hover_highlight > rect').attr('y', this.drop_hover_details.y);
          break;
        } else {
          this.resetDropHover();
        }
      }
      if (!this.drop_hover_details.hover) {
        this.resetDropHover();
        var breakCheck = false;
        for (var l = 0; l < this.feature_pos_x.length; l++) {
          for (var m = 0; m < this.feature_pos_x[l].length; m++) {
            if (x > this.feature_pos_x[l][m] && x < this.feature_pos_x[l][m] + 38 && y > this.feature_pos_y[l][m] && y < this.feature_pos_y[l][m] + 38) {
              this.drop_hover_details.x = this.feature_pos_x[l][m];
              this.drop_hover_details.y = this.feature_pos_y[l][m];
              this.drop_hover_details.width = 40;
              this.drop_hover_details.height = 40;
              this.drop_hover_details.hover = true;
              window._jquery('#hover_highlight > rect').attr('x', this.drop_hover_details.x);
              window._jquery('#hover_highlight > rect').attr('y', this.drop_hover_details.y);
              breakCheck = true;
              break;
            } else {
              this.resetDropHover();
            }
          }
          if (breakCheck) break;
        }
      }
    },
    onServiceApplicationToolDraggedStart: function onServiceApplicationToolDraggedStart(service_id, x, y, type) {
      this.feature_pos_x = this.computeAllFeaturePos()[0];
      this.feature_pos_y = this.computeAllFeaturePos()[1];
    },
    onServiceApplicationToolDraggedStop: function onServiceApplicationToolDraggedStop(service_id, x, y, type) {
      for (var k = 0; k < this.computedTaskList.length; k++) {
        if (x > this.task_pos_x[k] + 5 && x < this.task_pos_x[k] + 140 && y > this.task_pos_y[k] - 10 && y < this.task_pos_y[k] + 30) {
          this.service_application_form.enabler = false;
          this.service_application_form.serviceId = service_id;
          this.service_application_form.stepId = this.computedTaskList[k].journeyStep.id;
          this.service_application_form.type = type;
          this.addServiceApplicationDialog = true;
          window._jquery('#hover_highlight > rect').attr('x', this.drop_hover_details.x);
          window._jquery('#hover_highlight > rect').attr('y', this.drop_hover_details.y);
          break;
        } else {
          this.resetDropHover();
        }
      }
      if (!this.addServiceApplicationDialog) {
        this.resetDropHover();
        var breakCheck = false;
        for (var l = 0; l < this.feature_pos_x.length; l++) {
          for (var m = 0; m < this.feature_pos_x[l].length; m++) {
            if (x > this.feature_pos_x[l][m] && x < this.feature_pos_x[l][m] + 38 && y > this.feature_pos_y[l][m] && y < this.feature_pos_y[l][m] + 38) {
              this.service_application_form.enabler = true;
              this.service_application_form.serviceId = service_id;
              this.service_application_form.featureId = this.mergedEntities[l].product.linkedFeatures[m].productFeature.id;
              this.service_application_form.type = type;
              this.addServiceApplicationDialog = true;
              breakCheck = true;
              break;
            } else {
              this.resetDropHover();
            }
          }
          if (breakCheck) break;
        }
      }
      this.resetHighlightAllSteps();
      this.resetHighlightAllProductFeatures();
    },
    onFeatureToolDragged: function onFeatureToolDragged(product_id, x, y) {
      for (var k = 0; k < this.computedTaskList.length; k++) {
        if (x > this.task_pos_x[k] + 5 && x < this.task_pos_x[k] + 140 && y > this.task_pos_y[k] - 10 && y < this.task_pos_y[k] + 30) {
          this.drop_hover_details.x = this.task_pos_x[k] - 5;
          this.drop_hover_details.y = this.task_pos_y[k] - 2;
          this.drop_hover_details.width = 140;
          this.drop_hover_details.height = 40;
          this.drop_hover_details.hover = true;
          window._jquery('#hover_highlight > rect').attr('x', this.drop_hover_details.x);
          window._jquery('#hover_highlight > rect').attr('y', this.drop_hover_details.y);
          break;
        } else {
          this.resetDropHover();
        }
      }
    },
    onFeatureToolDraggedStart: function onFeatureToolDraggedStart(product_id, x, y) {
      this.highlightAllSteps();
      this.feature.step_id = null;
      this.feature.product_id = null;
    },
    onFeatureToolDraggedStop: function onFeatureToolDraggedStop(product_id, x, y) {
      this.resetFeatureForm();
      this.resetHighlightAllSteps();
      for (var j = 0; j < this.computedTaskList.length; j++) {
        if (x > this.task_pos_x[j] + 5 && x < this.task_pos_x[j] + 140 && y > this.task_pos_y[j] - 10 && y < this.task_pos_y[j] + 30) {
          this.feature_form.offeringId = this.offering.id;
          this.feature_form.productId = product_id;
          this.feature_form.step_id = this.computedTaskList[j].journeyStep.id;
          this.addFeatureDialog = true;
          this.feature.step_id = this.computedTaskList[j].journeyStep.id;
          this.feature_form.step_id = this.computedTaskList[j].journeyStep.id;
          this.feature.product_id = product_id;
          break;
        }
      }
      this.resetDropHover();
    },
    onFeatureDragged: function onFeatureDragged(product_id, product_index, feature_id, feature_index, x, y) {
      for (var i = 0; i < this.mergedEntities.length; i++) {
        // Only compute for product features
        if (this.mergedEntities[i].__typename === 'LinkProductOffering' && this.mergedEntities[i].product.id === product_id) {
          for (var j = 0; j < this.mergedEntities[i].product.linkedFeatures.length; j++) {
            if (this.mergedEntities[i].product.linkedFeatures[j].productFeature.id === feature_id) {
              this.feature_pos_x[i][j] = x;
              this.feature_pos_y[i][j] = y;
            }
          }
        }
      }
      this.relationRefreshKey++;
      this.subentitydragged = true;
    },
    onFeatureDraggedStart: function onFeatureDraggedStart(product_id, product_index, feature_id, feature_index, x, y) {
      this.subentitydragged = true;
    },
    onFeatureDraggedStop: function onFeatureDraggedStop(product_id, product_index, feature_id, feature_index, x, y) {
      for (var i = 0; i < this.mergedEntities.length; i++) {
        // Only compute for product features
        if (this.mergedEntities[i].__typename === 'LinkProductOffering' && this.mergedEntities[i].product.id === product_id) {
          for (var j = 0; j < this.mergedEntities[i].product.linkedFeatures.length; j++) {
            if (this.mergedEntities[i].product.linkedFeatures[j].productFeature.id === feature_id) {
              this.feature_pos_x[i][j] = x;
              this.feature_pos_y[i][j] = y;
              this.updateFeaturePosToOfferingAdditionalAttributes(product_id, feature_id, x, y);
            }
          }
        }
      }
      this.relationRefreshKey++;
      this.subentitydragged = true;
    },
    updateFeaturePosToOfferingAdditionalAttributes: function updateFeaturePosToOfferingAdditionalAttributes(product_id, feature_id, x, y) {
      var additionalattributes = this.offering.additionalattributes;
      for (var i = 0; i < additionalattributes.entitiesSortOrder.length; i++) {
        if (additionalattributes.entitiesSortOrder[i].id === product_id) {
          for (var j = 0; j < additionalattributes.entitiesSortOrder[i].features.length; j++) {
            if (additionalattributes.entitiesSortOrder[i].features[j].id === feature_id) {
              additionalattributes.entitiesSortOrder[i].features[j].posX = x;
              additionalattributes.entitiesSortOrder[i].features[j].posY = y;
              this.$emit('update-offering-additionalattributes', additionalattributes, true);
            }
          }
        }
      }
    },
    updateServiceApplicationPosToOfferingAdditionalAttributes: function updateServiceApplicationPosToOfferingAdditionalAttributes(service_id, service_application_id, x, y) {
      var additionalattributes = this.offering.additionalattributes;
      for (var i = 0; i < additionalattributes.entitiesSortOrder.length; i++) {
        if (additionalattributes.entitiesSortOrder[i].id === service_id) {
          for (var j = 0; j < additionalattributes.entitiesSortOrder[i].service_applications.length; j++) {
            if (additionalattributes.entitiesSortOrder[i].service_applications[j].id === service_application_id) {
              additionalattributes.entitiesSortOrder[i].service_applications[j].posX = x;
              additionalattributes.entitiesSortOrder[i].service_applications[j].posY = y;
              this.$emit('update-offering-additionalattributes', additionalattributes, true);
            }
          }
        }
      }
    },
    updateFeatureFeatureRelation: function updateFeatureFeatureRelation(start_id, start_type, end_id, end_type) {
      this.$emit('update-feature-feature-relation', start_id, start_type, end_id, end_type);
    },
    deleteFeatureFeatureRelation: function deleteFeatureFeatureRelation(feature) {
      this.$emit('delete-feature-feature-relation', feature.id);
      var additionalattributes = feature.additionalattributes;
      additionalattributes.posY += 50;
      this.$emit('update-feature-location', feature, additionalattributes.posX, additionalattributes.posY);
    },
    deleteServiceApplicationServiceApplicationRelation: function deleteServiceApplicationServiceApplicationRelation(service_application) {
      this.$emit('delete-service-application-service-application-relation', service_application.id);
      var additionalattributes = service_application.additionalattributes;
      additionalattributes.posY += 50;
      this.$emit('update-service-application-location', service_application, additionalattributes.posX, additionalattributes.posY);
    },
    onProductRiskToolDragged: function onProductRiskToolDragged(product, x, y, feature_pos_x, feature_pos_y) {
      this.highlightAllProductFeatures(product, feature_pos_x, feature_pos_y);
      for (var m = 0; m < product.linkedFeatures.length; m++) {
        if (x > feature_pos_x[m] - 19 && x < feature_pos_x[m] + 19 && y > feature_pos_y[m] - 19 && y < feature_pos_y[m] + 19) {
          this.drop_hover_details.x = feature_pos_x[m];
          this.drop_hover_details.y = feature_pos_y[m];
          this.drop_hover_details.width = 40;
          this.drop_hover_details.height = 40;
          this.drop_hover_details.hover = true;
          window._jquery('#hover_highlight > rect').attr('x', this.drop_hover_details.x);
          window._jquery('#hover_highlight > rect').attr('y', this.drop_hover_details.y);
          break;
        } else {
          this.resetDropHover();
        }
      }
    },
    onProductRiskToolDraggedStart: function onProductRiskToolDraggedStart(product, x, y, feature_pos_x, feature_pos_y) {},
    onProductRiskToolDraggedStop: function onProductRiskToolDraggedStop(product, x, y, feature_pos_x, feature_pos_y) {
      this.resetHighlightAllProductFeatures();
      for (var m = 0; m < product.linkedFeatures.length; m++) {
        if (x > feature_pos_x[m] - 19 && x < feature_pos_x[m] + 19 && y > feature_pos_y[m] - 19 && y < feature_pos_y[m] + 19) {
          this.resetDropHover();
          this.risk_form.entityname = 'FEATURE';
          this.risk_form.entityid = product.linkedFeatures[m].productFeature.id;
          this.addRiskDialog = true;
        }
      }
      if (!this.addRiskDialog) {
        this.risk_form.entityname = 'PRODUCT';
        this.risk_form.entityid = product.id;
        this.addRiskDialog = true;
      }
    },
    onServiceRiskToolDragged: function onServiceRiskToolDragged(service, x, y, service_application_pos_x, service_application_pos_y) {
      for (var m = 0; m < service.linkedServiceApplications.length; m++) {
        if (x > service_application_pos_x[m] && x < service_application_pos_x[m] + 38 && y > service_application_pos_y[m] && y < service_application_pos_y[m] + 38) {
          this.drop_hover_details.x = service_application_pos_x[m];
          this.drop_hover_details.y = service_application_pos_y[m];
          this.drop_hover_details.width = 40;
          this.drop_hover_details.height = 40;
          this.drop_hover_details.hover = true;
          window._jquery('#hover_highlight > rect').attr('x', this.drop_hover_details.x);
          window._jquery('#hover_highlight > rect').attr('y', this.drop_hover_details.y);
          break;
        } else {
          this.resetDropHover();
        }
      }
    },
    onServiceRiskToolDraggedStart: function onServiceRiskToolDraggedStart(service, x, y, service_application_pos_x, service_application_pos_y) {
      this.highlightAllServiceApplications(service, service_application_pos_x, service_application_pos_y);
    },
    onServiceRiskToolDraggedStop: function onServiceRiskToolDraggedStop(service, x, y, service_application_pos_x, service_application_pos_y) {
      this.resetHighlightAllServiceApplications();
      for (var m = 0; m < service.linkedServiceApplications.length; m++) {
        if (x > service_application_pos_x[m] && x < service_application_pos_x[m] + 38 && y > service_application_pos_y[m] && y < service_application_pos_y[m] + 38) {
          this.resetDropHover();
          this.risk_form.entityname = 'SERVICEAPPLICATION';
          this.risk_form.entityid = service.linkedServiceApplications[m].serviceApplication.id;
          this.addRiskDialog = true;
        }
      }
      if (!this.addRiskDialog) {
        this.risk_form.entityname = 'SERVICE';
        this.risk_form.entityid = service.id;
        this.addRiskDialog = true;
      }
    },
    highlightAllServiceApplications: function highlightAllServiceApplications(service, service_application_pos_x, service_application_pos_y) {
      this.serviceApplicationHighlighter = [];
      for (var l = 0; l < service.linkedServiceApplications.length; l++) {
        var highlight_object = {};
        highlight_object = {
          x: service_application_pos_x[l],
          y: service_application_pos_y[l],
          width: 38,
          height: 38
        };
        this.serviceApplicationHighlighter.push(highlight_object);
      }
    },
    highlightAllFeatures: function highlightAllFeatures() {
      // Compute all feature points and invoke highlight object
      this.featureHighlighter = [];
      /* this.feature_pos_x = this.computeAllFeaturePos()[0]
      this.feature_pos_y = this.computeAllFeaturePos()[1]*/
      for (var l = 0; l < this.feature_pos_x.length; l++) {
        for (var m = 0; m < this.feature_pos_x[l].length; m++) {
          var highlight_object = {};
          highlight_object = {
            x: this.feature_pos_x[l][m],
            y: this.feature_pos_y[l][m],
            width: 38,
            height: 38
          };
          this.featureHighlighter.push(highlight_object);
        }
      }
    },
    highlightAllProductFeatures: function highlightAllProductFeatures(product, feature_pos_x, feature_pos_y) {
      this.featureHighlighter = [];
      for (var l = 0; l < product.linkedFeatures.length; l++) {
        var highlight_object = {};
        highlight_object = {
          x: feature_pos_x[l],
          y: feature_pos_y[l],
          width: 38,
          height: 38
        };
        this.featureHighlighter.push(highlight_object);
      }
    },
    highlightAllSteps: function highlightAllSteps() {
      this.stepHighlighter = [];
      for (var k = 0; k < this.computedTaskList.length; k++) {
        var highlight_object = {};
        highlight_object = {
          x: this.task_pos_x[k] - 5,
          y: this.task_pos_y[k],
          width: 140,
          height: 35
        };
        this.stepHighlighter.push(highlight_object);
      }
    },
    highlightAllPhaseChannelGap: function highlightAllPhaseChannelGap() {
      this.phaseChannelGapHighlighter = [];
      this.maxedPhaseChannelGapHighlighter = [];
      for (var i = 0; i < this.phase_width.length; i++) {
        if (!this.hasMaxTouchpoints(this.sortedStages[i].journeyPhase.id)) {
          var highlight_object = {};
          highlight_object = {
            x: this.phase_pos_x[i],
            y: this.computeJourneyMapHeight + 10,
            width: this.phase_width[i] + 40,
            height: 105
          };
          this.phaseChannelGapHighlighter.push(highlight_object);
        } else {
          var highlight_object_max = {};
          highlight_object_max = {
            x: this.phase_pos_x[i],
            y: this.computeJourneyMapHeight + 10,
            width: this.phase_width[i] + 40,
            height: 105
          };
          this.maxedPhaseChannelGapHighlighter.push(highlight_object_max);
        }
      }
    },
    highlightActivePhase: function highlightActivePhase(phase_index) {
      var y = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 100;
      var height = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 260;
      this.drop_hover_details.x = this.phase_pos_x[phase_index];
      this.drop_hover_details.y = this.computeJourneyMapHeight + 10;
      this.drop_hover_details.width = this.phase_width[phase_index];
      this.drop_hover_details.height = 105;
      this.drop_hover_details.hover = true;
      window._jquery('#hover_highlight > rect').attr('x', this.drop_hover_details.x);
      window._jquery('#hover_highlight > rect').attr('y', this.drop_hover_details.y);
    },
    hasFeatureRelation: function hasFeatureRelation(feature_id) {
      for (var i = 0; i < this.offering.additionalattributes.relations.length; i++) {
        if (this.offering.additionalattributes.relations[i].start_id === feature_id && this.offering.additionalattributes.relations[i].end_type === 'Feature') {
          return true;
        }
      }
      return false;
    },
    hasServiceApplicationRelation: function hasServiceApplicationRelation(service_application_id) {
      for (var i = 0; i < this.offering.additionalattributes.relations.length; i++) {
        if (this.offering.additionalattributes.relations[i].end_id === service_application_id) {
          return true;
        }
      }
      return false;
    },
    computeProductUnmappedIndex: function computeProductUnmappedIndex(product_index, feature_index) {
      var count = 0;
      for (var j = 0; j < feature_index; j++) {
        if (this.isFeatureUnmapped(this.mergedEntities[product_index].product.linkedFeatures[j].productFeature.id) || !this.hasFeatureRelation(this.mergedEntities[product_index].product.linkedFeatures[j].productFeature.id)) {
          count++;
        }
      }
      return count;
    },
    computeProductEnablerParentX: function computeProductEnablerParentX(feature_id) {
      for (var i = 0; i < this.offering.additionalattributes.relations.length; i++) {
        if (this.offering.additionalattributes.relations[i].start_id === feature_id) {
          for (var j = 0; j < this.offering.additionalattributes.relations.length; j++) {
            if (this.offering.additionalattributes.relations[i].end_id === this.offering.additionalattributes.relations[j].end_id && this.offering.additionalattributes.relations[j].end_type === 'Feature') {
              return this.computePhaseXFromStepID(this.offering.additionalattributes.relations[j].start_id);
            }
          }
        }
      }
      return 0;
    },
    computeServiceUnmappedIndex: function computeServiceUnmappedIndex(service_index, service_application_index) {
      var count = 0;
      for (var j = 0; j < service_application_index; j++) {
        if (this.isServiceApplicationUnmapped(this.mergedEntities[service_index].service.linkedServiceApplications[j].serviceApplication.id) || !this.hasServiceApplicationRelation(this.mergedEntities[service_index].service.linkedServiceApplications[j].serviceApplication.id)) {
          count++;
        }
      }
      return count;
    },
    computeProductEnablerIndex: function computeProductEnablerIndex(product_index, feature_index) {
      var count = 0;
      for (var j = 0; j < feature_index; j++) {
        if (this.isFeatureEnabler(this.mergedEntities[product_index].product.linkedFeatures[j].productFeature.id)) {
          count++;
        }
      }
      return count;
    },
    computeServiceEnablerIndex: function computeServiceEnablerIndex(service_index, service_application_index) {
      var count = 0;
      for (var j = 0; j < service_application_index; j++) {
        if (this.isServiceApplicationEnabler(this.mergedEntities[service_index].service.linkedServiceApplications[j].id)) {
          count++;
        }
      }
      return count;
    },
    computeAllFeaturePos: function computeAllFeaturePos() {
      this.feature_pos_x = new Array(this.mergedEntities.length);
      this.feature_pos_y = new Array(this.mergedEntities.length);
      for (var i = 0; i < this.mergedEntities.length; i++) {
        // var y = this.computeEntityPosY + this.computeEntityLaneHeightCompounded(i)
        if (this.mergedEntities[i].__typename === 'LinkProductOffering') {
          this.feature_pos_x[i] = new Array(this.mergedEntities[i].product.linkedFeatures.length);
          this.feature_pos_y[i] = new Array(this.mergedEntities[i].product.linkedFeatures.length);
          for (var j = 0; j < this.mergedEntities[i].product.linkedFeatures.length; j++) {
            if (this.offering.additionalattributes.entitiesSortOrder && this.offering.additionalattributes.entitiesSortOrder.length > 0) {
              for (var k = 0; k < this.offering.additionalattributes.entitiesSortOrder.length; k++) {
                if (this.offering.additionalattributes.entitiesSortOrder[k].type === 'product') {
                  for (var l = 0; l < this.offering.additionalattributes.entitiesSortOrder[k].features.length; l++) {
                    if (this.offering.additionalattributes.entitiesSortOrder[k].features[l].id === this.mergedEntities[i].product.linkedFeatures[j].productFeature.id) {
                      this.feature_pos_x[i][j] = this.offering.additionalattributes.entitiesSortOrder[k].features[l].posX;
                      this.feature_pos_y[i][j] = this.offering.additionalattributes.entitiesSortOrder[k].features[l].posY;
                    }
                  }
                }
              }
            }

            // X
            /* if (this.mergedEntities[i].product.additionalattributes.minimized) {
               this.feature_pos_x[i][j] = (j % 100) * 30 + 168
             } else {
               if (this.mergedEntities[i].product.linkedFeatures[j].productFeature.relations.steps[0].id === null && !this.hasFeatureRelation(this.mergedEntities[i].product.linkedFeatures[j].productFeature.id)) {
                 this.feature_pos_x[i][j] = (this.computeProductUnmappedIndex(i, j) % 50) * 50 + 100
               } else if (this.mergedEntities[i].product.linkedFeatures[j].productFeature.enabler) {
                 this.feature_pos_x[i][j] = this.computeProductEnablerParentX(this.mergedEntities[i].product.linkedFeatures[j].productFeature.id) + (this.computeProductEnablerIndex(i, j) % 50) * 50
               } else {
                 this.feature_pos_x[i][j] = this.computePhaseXFromStepID(this.mergedEntities[i].product.linkedFeatures[j].productFeature.relations.steps[0].id) + (this.computeFilteredProductFeatureIndex(this.mergedEntities[i].product, j) % 50) * 50
               }
             }
               // Y
               if (this.mergedEntities[i].product.additionalattributes.minimized) {
               this.feature_pos_y[i][j] = y + this.computeEntityLaneHeightCompounded(i) + 5
             } else {
               if (this.mergedEntities[i].product.linkedFeatures[j].productFeature.enabler) {
                 this.feature_pos_y[i][j] = y + this.computeEntityLaneHeightCompounded(i) + 5 + 105
               } else if (this.mergedEntities[i].product.linkedFeatures[j].productFeature.relations.steps[0].id === null) {
                 this.feature_pos_y[i][j] = y + this.computeEntityLaneHeightCompounded(i) + 5 + 155
               } else if (!this.hasFeatureRelation(this.mergedEntities[i].product.linkedFeatures[j].productFeature.id)) {
                 this.feature_pos_y[i][j] = y + this.computeEntityLaneHeightCompounded(i) + (this.productHasEnablerFeatures(this.mergedEntities[i].product) ? 155 : 105)
               } else {
                 this.feature_pos_y[i][j] = y + this.computeEntityLaneHeightCompounded(i) + 5 + 5
               }
             }*/
          }
        }
        if (this.mergedEntities[i].__typename === 'LinkServiceOffering') {
          this.feature_pos_x[i] = new Array(this.mergedEntities[i].service.linkedServiceApplications.length);
          this.feature_pos_y[i] = new Array(this.mergedEntities[i].service.linkedServiceApplications.length);
          for (var m = 0; m < this.mergedEntities[i].service.linkedServiceApplications.length; m++) {
            if (this.offering.additionalattributes.entitiesSortOrder && this.offering.additionalattributes.entitiesSortOrder.length > 0) {
              for (var n = 0; n < this.offering.additionalattributes.entitiesSortOrder.length; n++) {
                if (this.offering.additionalattributes.entitiesSortOrder[n].type === 'service') {
                  for (var o = 0; o < this.offering.additionalattributes.entitiesSortOrder[n].service_applications.length; o++) {
                    if (this.offering.additionalattributes.entitiesSortOrder[n].service_applications[o].id === this.mergedEntities[i].service.linkedServiceApplications[m].serviceApplication.id) {
                      this.feature_pos_x[i][m] = this.offering.additionalattributes.entitiesSortOrder[n].service_applications[o].posX;
                      this.feature_pos_y[i][m] = this.offering.additionalattributes.entitiesSortOrder[n].service_applications[o].posY;
                    }
                  }
                }
              }
            }

            // X
            /* if (this.mergedEntities[i].service.additionalattributes.minimized) {
              this.feature_pos_x[i][k] = (k % 100) * 30 + 168
            } else {
              if (this.mergedEntities[i].service.linkedServiceApplications[k].serviceApplication.relations.id === null && !this.mergedEntities[i].service.linkedServiceApplications[k].serviceApplication.enabler) {
                this.feature_pos_x[i][k] = 175 + (this.computeServiceUnmappedIndex(i, k) % 50) * 50 + 215
              } else if (this.mergedEntities[i].service.linkedServiceApplications[k].serviceApplication.relations.id !== null && this.mergedEntities[i].service.linkedServiceApplications[k].serviceApplication.enabler) {
                this.feature_pos_x[i][k] = this.computePhaseXFromStepID(this.mergedEntities[i].service.linkedServiceApplications[k].serviceApplication.relations.id) + (this.computeServiceEnablerIndex(i, k) % 50) * 50
              } else {
                this.feature_pos_x[i][k] = this.computePhaseXFromStepID(this.mergedEntities[i].service.linkedServiceApplications[k].serviceApplication.relations.id) + (this.computeFilteredServiceApplicationIndex(this.mergedEntities[i].service, k) % 50) * 50
              }
            }
              // Y
            if (this.mergedEntities[i].service.additionalattributes.minimized) {
              this.feature_pos_y[i][k] = y + this.computeEntityLaneHeightCompounded(i) + 5
            } else {
              if (this.mergedEntities[i].service.linkedServiceApplications[k].serviceApplication.enabler) {
                this.feature_pos_y[i][k] = y + this.computeEntityLaneHeightCompounded(i) + 5 + 110
              } else if (this.mergedEntities[i].service.linkedServiceApplications[k].serviceApplication.relations.id === null || !this.hasServiceApplicationRelation(this.mergedEntities[i].service.linkedServiceApplications[k].serviceApplication.id)) {
                this.feature_pos_y[i][k] = y + this.computeEntityLaneHeightCompounded(i) + 5 + 160
              } else {
                this.feature_pos_y[i][k] = y + this.computeEntityLaneHeightCompounded(i) + 5
              }
            }*/
          }
        }
      }
      return [this.feature_pos_x, this.feature_pos_y];
    },
    computeEntityLaneHeight: function computeEntityLaneHeight(index) {
      var height = 0;
      if (this.mergedEntities[index].__typename === 'LinkProductOffering') {
        height += 100;
        if (this.productHasUnmappedFeatures(this.mergedEntities[index].product)) {
          height += 50;
        }
        if (this.productHasEnablerFeatures(this.mergedEntities[index].product)) {
          height += 50;
        }
      } else if (this.mergedEntities[index].__typename === 'LinkServiceOffering') {
        height += 100;
        if (this.serviceHasUnmappedFeatures(this.mergedEntities[index].service)) {
          height += 50;
        }
        if (this.serviceHasEnablerFeatures(this.mergedEntities[index].service)) {
          height += 50;
        }
      } else {
        height += 100;
      }
      return height;
    },
    computeEntityLaneHeightCompounded: function computeEntityLaneHeightCompounded(index) {
      var height = 0;
      for (var i = 0; i < index; i++) {
        if (this.offering.additionalattributes.entitiesSortOrder[i].minimized) {
          height += 25;
        } else {
          if (this.mergedEntities[i].__typename === 'LinkProductOffering') {
            height += 100;
            if (this.productHasUnmappedFeatures(this.mergedEntities[i].product)) {
              height += 50;
            }
            if (this.productHasEnablerFeatures(this.mergedEntities[i].product)) {
              height += 50;
            }
          } else if (this.mergedEntities[i].__typename === 'LinkServiceOffering') {
            height += 100;
            if (this.serviceHasUnmappedFeatures(this.mergedEntities[i].service)) {
              height += 50;
            }
            if (this.serviceHasEnablerFeatures(this.mergedEntities[i].service)) {
              height += 50;
            }
          } else {
            height += 100;
          }
        }
      }
      return height + index * 5;
    },
    isFeatureEnabler: function isFeatureEnabler(sub_entity_id) {
      return this.isEnabler('features', sub_entity_id);
    },
    isServiceApplicationEnabler: function isServiceApplicationEnabler(sub_entity_id) {
      return this.isEnabler('service_applications', sub_entity_id);
    },
    isEnabler: function isEnabler(type, sub_entity_id) {
      for (var i = 0; i < this.offering.additionalattributes.entitiesSortOrder.length; i++) {
        if (this.offering.additionalattributes.entitiesSortOrder[i].type === 'product') {
          for (var j = 0; j < this.offering.additionalattributes.entitiesSortOrder[i].features.length; j++) {
            if (this.offering.additionalattributes.entitiesSortOrder[i].features[j].id === sub_entity_id) {
              return this.offering.additionalattributes.entitiesSortOrder[i].features[j].enabler;
            }
          }
        }
        if (this.offering.additionalattributes.entitiesSortOrder[i].type === 'service') {
          for (var k = 0; k < this.offering.additionalattributes.entitiesSortOrder[i].service_applications.length; k++) {
            if (this.offering.additionalattributes.entitiesSortOrder[i].service_applications[k].id === sub_entity_id) {
              return this.offering.additionalattributes.entitiesSortOrder[i].service_applications[k].enabler;
            }
          }
        }
      }
      return false;
    },
    isFeatureUnmapped: function isFeatureUnmapped(sub_entity_id) {
      return this.isUnmapped('features', sub_entity_id);
    },
    isServiceApplicationUnmapped: function isServiceApplicationUnmapped(sub_entity_id) {
      return this.isUnmapped('service_applications', sub_entity_id);
    },
    isUnmapped: function isUnmapped(type, sub_entity_id) {
      for (var i = 0; i < this.offering.additionalattributes.entitiesSortOrder.length; i++) {
        if (this.offering.additionalattributes.entitiesSortOrder[i].type === 'product') {
          for (var j = 0; j < this.offering.additionalattributes.entitiesSortOrder[i].features.length; j++) {
            if (this.offering.additionalattributes.entitiesSortOrder[i].features[j].id === sub_entity_id) {
              return this.offering.additionalattributes.entitiesSortOrder[i].features[j].unmapped;
            }
          }
        }
        if (this.offering.additionalattributes.entitiesSortOrder[i].type === 'service') {
          for (var k = 0; k < this.offering.additionalattributes.entitiesSortOrder[i].service_applications.length; k++) {
            if (this.offering.additionalattributes.entitiesSortOrder[i].service_applications[k].id === sub_entity_id) {
              return this.offering.additionalattributes.entitiesSortOrder[i].service_applications[k].unmapped;
            }
          }
        }
      }
      return false;
    },
    productHasUnmappedFeatures: function productHasUnmappedFeatures(product) {
      var has_unmapped = false;
      if (!this.isUnmappedVisible) {
        return has_unmapped;
      }
      for (var i = 0; i < this.offering.additionalattributes.entitiesSortOrder.length; i++) {
        if (this.offering.additionalattributes.entitiesSortOrder[i].id === product.id) {
          for (var j = 0; j < this.offering.additionalattributes.entitiesSortOrder[i].features.length; j++) {
            if (this.offering.additionalattributes.entitiesSortOrder[i].features[j].unmapped) {
              return true;
            }
          }
        }
      }
      return has_unmapped;
    },
    productHasEnablerFeatures: function productHasEnablerFeatures(product) {
      for (var i = 0; i < this.offering.additionalattributes.entitiesSortOrder.length; i++) {
        if (this.offering.additionalattributes.entitiesSortOrder[i].id === product.id) {
          for (var j = 0; j < this.offering.additionalattributes.entitiesSortOrder[i].features.length; j++) {
            if (this.offering.additionalattributes.entitiesSortOrder[i].features[j].enabler) {
              return true;
            }
          }
        }
      }
      return false;
    },
    serviceHasUnmappedFeatures: function serviceHasUnmappedFeatures(service) {
      var has_unmapped = false;
      if (!this.isUnmappedVisible) {
        return has_unmapped;
      }
      for (var i = 0; i < this.offering.additionalattributes.entitiesSortOrder.length; i++) {
        if (this.offering.additionalattributes.entitiesSortOrder[i].id === service.id) {
          for (var j = 0; j < this.offering.additionalattributes.entitiesSortOrder[i].service_applications.length; j++) {
            if (this.offering.additionalattributes.entitiesSortOrder[i].service_applications[j].unmapped) {
              return true;
            }
          }
        }
      }
      return has_unmapped;
    },
    serviceHasEnablerFeatures: function serviceHasEnablerFeatures(service) {
      for (var i = 0; i < this.offering.additionalattributes.entitiesSortOrder.length; i++) {
        if (this.offering.additionalattributes.entitiesSortOrder[i].id === service.id) {
          for (var j = 0; j < this.offering.additionalattributes.entitiesSortOrder[i].service_applications.length; j++) {
            if (this.offering.additionalattributes.entitiesSortOrder[i].service_applications[j].enabler) {
              return true;
            }
          }
        }
      }
      return false;
    },
    updatePhaseX: function updatePhaseX(pos) {
      this.phase_pos_x = pos;
    },
    updatePhaseWidth: function updatePhaseWidth(width) {
      this.phase_width = width;
    },
    updateTaskX: function updateTaskX(pos) {
      this.task_pos_x = pos;
    },
    updateTaskY: function updateTaskY(pos) {
      this.task_pos_y = pos;
    },
    computeProductFromProductID: function computeProductFromProductID(product_id) {
      for (var i = 0; i < this.mergedEntities.length; i++) {
        if (this.mergedEntities[i].__typename === 'LinkProductOffering' && this.mergedEntities[i].product.id === product_id) {
          return [this.mergedEntities[i], i];
        }
      }
    },
    computeServiceFromServiceID: function computeServiceFromServiceID(service_id) {
      for (var i = 0; i < this.mergedEntities.length; i++) {
        if (this.mergedEntities[i].__typename === 'LinkServiceOffering' && this.mergedEntities[i].service.id === service_id) {
          return [this.mergedEntities[i], i];
        }
      }
    },
    computePhaseXFromStepID: function computePhaseXFromStepID(step_id) {
      if (this._.isEmpty(this.journeyMap)) {
        return 170;
      }
      if (this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages.length > 0 && this.journeyMap.linkedJourneyPhases.length > 0) {
        for (var i = 0; i < this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages.length; i++) {
          for (var j = 0; j < this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[i].steps.length; j++) {
            if (this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[i].steps[j].id === step_id) {
              return this.phase_pos_x[i];
            }
          }
        }
      }
      return 170;
    },
    computePhaseXFromFeatureID: function computePhaseXFromFeatureID(feature_id) {
      if (this._.isEmpty(this.journeyMap)) {
        return 170;
      }
      if (this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages.length > 0 && this.journeyMap.linkedJourneyPhases.length > 0) {
        for (var i = 0; i < this.mergedEntities.length; i++) {
          if (this.mergedEntities[i].__typename === 'LinkProductOffering') {
            for (var j = 0; j < this.mergedEntities[i].product.linkedFeatures.length; j++) {
              if (this.mergedEntities[i].product.linkedFeatures[j].productFeature.id === feature_id) {
                for (var k = 0; k < this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages.length; k++) {
                  if (this.mergedEntities[i].product.id === this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[k].id) {
                    return k;
                  }
                }
              }
            }
          }
        }
      }
    },
    computeFilteredProductFeatureIndex: function computeFilteredProductFeatureIndex(product, index) {
      var temp_feature_ids = [];
      for (var j = 0; j < product.linkedFeatures.length; j++) {
        if (this.isFeatureUnmapped(product.linkedFeatures[j].productFeature.id) && !this.isFeatureEnabler(product.linkedFeatures[j].productFeature.id)) {
          temp_feature_ids.push(product.linkedFeatures[j].productFeature.id);
        }
      }
      return temp_feature_ids.indexOf(product.linkedFeatures[index].productFeature.id);
    },
    computeFilteredServiceApplicationIndex: function computeFilteredServiceApplicationIndex(service, index) {
      var temp_Service_application_ids = [];
      for (var j = 0; j < service.linkedServiceApplications.length; j++) {
        if (this.isServiceApplicationUnmapped(service.linkedServiceApplications[j].serviceApplication.id) && !this.isServiceApplicationEnabler(service.linkedServiceApplications[j].serviceApplication.id)) {
          temp_Service_application_ids.push(service.linkedServiceApplications[j].serviceApplication.id);
        }
      }
      return temp_Service_application_ids.indexOf(service.linkedServiceApplications[index].serviceApplication.id);
    },
    createFeature: function createFeature() {
      var _this19 = this;
      this.$refs.feature_form.validate(function (valid) {
        if (valid) {
          var product_details = _this19.computeProductFromProductID(_this19.feature_form.productId);
          var temp_feature_ids = [];
          for (var j = 0; j < product_details[0].product.linkedFeatures.length; j++) {
            if (_this19.isFeatureUnmapped(product_details[0].product.linkedFeatures[j].productFeature.id) && !_this19.isFeatureEnabler(product_details[0].product.linkedFeatures[j].productFeature.id)) {
              temp_feature_ids.push(product_details[0].product.linkedFeatures[j].productFeature.id);
            }
          }
          var existing_features_length = temp_feature_ids.length;
          _this19.feature_form.posX = _this19.computePhaseXFromStepID(_this19.feature_form.step_id) + existing_features_length % 50 * 50;
          _this19.feature_form.posY = _this19.computeEntityPosY + _this19.computeEntityLaneHeightCompounded(product_details[1]) + 10;
          _this19.feature_form.ticket_type = 'Product Epic';
          var feature = Object.assign({}, _this19.feature_form);
          _this19.$emit('add-feature', feature);
          _this19.addFeatureDialog = false;
          _this19.resetFeatureForm();
        } else {
          _this19.loading.feature = false;
          return false;
        }
      });
    },
    retrieveProductIdeas: function retrieveProductIdeas(page_number) {
      var _this20 = this;
      this.loading.idea = true;
      this.selectedProductIdeas = [];
      this.$apollo.query({
        // Query
        query: window._gql(_templateObject6 || (_templateObject6 = (0, _taggedTemplateLiteral2.default)(["query Product($id: String!)\n        {\n          Product(id: $id) {\n            id\n            title\n            linkedBusinessIdeas(optional: true) {\n              businessIdea(optional: true) {\n                id\n                title\n                description\n              }\n            }\n          }\n        }"]))),
        fetchPolicy: 'no-cache',
        variables: {
          'id': this.selectedEntity.id
        }
      }).then(function (data) {
        _this20.selectedProductIdeas = data.data.Product.linkedBusinessIdeas;
        _this20.loading.idea = false;
      }).catch(function (error) {
        // Error
        _this20.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this20.loading.idea = false;
      });
    },
    retrieveProductRisks: function retrieveProductRisks(page_number) {
      var _this21 = this;
      this.loading.risk = true;
      this.selectedProductRisks = [];
      this.$apollo.query({
        // Query
        query: window._gql(_templateObject7 || (_templateObject7 = (0, _taggedTemplateLiteral2.default)(["query Product($id: String!)\n        {\n          Product(id: $id) {\n            id\n            title\n            linkedRisks(optional: true) {\n              risk(optional: true) {\n                id\n                title\n                description\n              }\n            }\n          }\n        }"]))),
        fetchPolicy: 'no-cache',
        variables: {
          'id': this.selectedEntity.id
        }
      }).then(function (data) {
        _this21.selectedProductRisks = data.data.Product.linkedRisks;
        _this21.loading.risk = false;
      }).catch(function (error) {
        // Error
        _this21.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this21.loading.risk = false;
      });
    },
    retrieveServiceIdeas: function retrieveServiceIdeas(page_number) {
      var _this22 = this;
      this.loading.idea = true;
      this.selectedServiceIdeas = [];
      this.$apollo.query({
        // Query
        query: window._gql(_templateObject8 || (_templateObject8 = (0, _taggedTemplateLiteral2.default)(["query Service($id: String!)\n        {\n          Service(id: $id) {\n            id\n            title\n            linkedBusinessIdeas(optional: true) {\n              businessIdea(optional: true) {\n                id\n                title\n                description\n              }\n            }\n          }\n        }"]))),
        fetchPolicy: 'no-cache',
        variables: {
          'id': this.selectedEntity.id
        }
      }).then(function (data) {
        _this22.selectedServiceIdeas = data.data.Service.linkedBusinessIdeas;
        _this22.loading.idea = false;
      }).catch(function (error) {
        // Error
        _this22.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this22.loading.idea = false;
      });
    },
    retrieveServiceRisks: function retrieveServiceRisks(page_number) {
      var _this23 = this;
      this.loading.risk = true;
      this.selectedServiceRisks = [];
      this.$apollo.query({
        // Query
        query: window._gql(_templateObject9 || (_templateObject9 = (0, _taggedTemplateLiteral2.default)(["query Service($id: String!)\n        {\n          Service(id: $id) {\n            id\n            title\n            linkedRisks(optional: true) {\n              risk(optional: true) {\n                id\n                title\n                description\n              }\n            }\n          }\n        }"]))),
        fetchPolicy: 'no-cache',
        variables: {
          'id': this.selectedEntity.id
        }
      }).then(function (data) {
        _this23.selectedServiceRisks = data.data.Service.linkedRisks;
        _this23.loading.risk = false;
      }).catch(function (error) {
        // Error
        _this23.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this23.loading.risk = false;
      });
    },
    handleSelectedProductDialogTabClick: function handleSelectedProductDialogTabClick(tab, event) {
      switch (tab.name) {
        case 'ideas':
          this.retrieveProductIdeas(1);
          break;
        case 'risks':
          this.retrieveProductRisks(1);
          break;
      }
    },
    handleSelectedServiceDialogTabClick: function handleSelectedServiceDialogTabClick(tab, event) {
      switch (tab.name) {
        case 'ideas':
          this.retrieveServiceIdeas(1);
          break;
        case 'risks':
          this.retrieveServiceRisks(1);
          break;
      }
    },
    computeUserName: function computeUserName(user) {
      return user.firstName + ' ' + user.lastName;
    },
    computeHumanFriendlyDate: function computeHumanFriendlyDate(date) {
      return this.$moment(date, 'YYYY-MM-DD HH:mm:ss').format('ll');
    },
    recomputeFeatureAndServiceApplicationPos: function recomputeFeatureAndServiceApplicationPos() {
      this.recomputeServiceApplicationPos = !this.recomputeServiceApplicationPos;
      this.recomputeFeaturePos = !this.recomputeFeaturePos;
      this.relationRefreshKey++;
    },
    handleMenuDropDownCommand: function handleMenuDropDownCommand(command) {
      switch (command) {
        case 'showTouchpoints':
          this.resetHoverDetailsDialog();
          this.journeyMap.additionalattributes.layouts.horizontal.isTouchpointVisible = true;
          this.$emit('update-journeymap-additionalattributes', this.journeyMap.additionalattributes, true);
          this.isTouchpointVisible = true;
          this.recomputeSubEntityPos();
          break;
        case 'hideTouchPoints':
          this.resetHoverDetailsDialog();
          this.journeyMap.additionalattributes.layouts.horizontal.isTouchpointVisible = false;
          this.$emit('update-journeymap-additionalattributes', this.journeyMap.additionalattributes, true);
          this.isTouchpointVisible = false;
          this.recomputeSubEntityPos();
          break;
        case 'showProducts':
          this.resetHoverDetailsDialog();
          this.isProductsVisible = true;
          this.offering.additionalattributes.entityLane.isProductsVisible = true;
          this.updateOfferingAdditionalAttributes(this.offering.additionalattributes, true);
          this.recomputeSubEntityPos();
          break;
        case 'hideProducts':
          this.resetHoverDetailsDialog();
          this.isProductsVisible = false;
          this.offering.additionalattributes.entityLane.isProductsVisible = false;
          this.updateOfferingAdditionalAttributes(this.offering.additionalattributes, true);
          this.recomputeSubEntityPos();
          break;
        case 'showServices':
          this.resetHoverDetailsDialog();
          this.isServicesVisible = true;
          this.offering.additionalattributes.entityLane.isServicesVisible = true;
          this.updateOfferingAdditionalAttributes(this.offering.additionalattributes, true);
          this.recomputeSubEntityPos();
          break;
        case 'hideServices':
          this.resetHoverDetailsDialog();
          this.isServicesVisible = false;
          this.offering.additionalattributes.entityLane.isServicesVisible = false;
          this.updateOfferingAdditionalAttributes(this.offering.additionalattributes, true);
          this.recomputeSubEntityPos();
          break;
        case 'showUnmapped':
          this.resetHoverDetailsDialog();
          this.isUnmappedVisible = true;
          this.offering.additionalattributes.entityLane.isUnmappedVisible = true;
          this.updateOfferingAdditionalAttributes(this.offering.additionalattributes, true);
          this.recomputeSubEntityPos();
          break;
        case 'hideUnmapped':
          this.resetHoverDetailsDialog();
          this.isUnmappedVisible = false;
          this.offering.additionalattributes.entityLane.isUnmappedVisible = false;
          this.updateOfferingAdditionalAttributes(this.offering.additionalattributes, true);
          this.recomputeSubEntityPos();
          break;
        case 'showRelations':
          this.resetHoverDetailsDialog();
          this.isRelationsVisible = true;
          break;
        case 'hideRelations':
          this.resetHoverDetailsDialog();
          this.isRelationsVisible = false;
          break;
        case 'externalTicket':
          break;
        case 'addIdeas':
          this.createOfferingIdea();
          break;
        case 'approve':
          break;
        case 'version':
          break;
        case 'recomputePos':
          this.recomputeFeatureAndServiceApplicationPos();
          break;
        case 'resetLayout':
          this.resetCanvasLayout();
          break;
        case 'settings':
          this.showSettingsDialog();
          break;
      }
    },
    handleVariantsDropDownCommand: function handleVariantsDropDownCommand(command) {
      for (var i = 0; i < this.variants.length; i++) {
        if (this.variants[i].id === command) {
          this.$store.dispatch('offerings/updateOfferingId', this.variants[i].id);
          this.$eventHub.$emit('offering-changed');
        }
      }
    },
    closeSettingsDialog: function closeSettingsDialog() {
      this.settingsDialog = false;
    },
    showSettingsDialog: function showSettingsDialog() {
      this.settingsDialog = true;
    },
    deleteOffering: function deleteOffering(offering_id) {
      this.$emit('delete-offering', offering_id);
    },
    renameOffering: function renameOffering(offering) {
      this.$emit('rename-offering', offering);
      this.closeSettingsDialog();
    },
    filteredProjectsByImport: function filteredProjectsByImport(projects) {
      var filtered_projects = [];
      for (var i = 0; i < projects.length; i++) {
        if (projects[i].isImported && !projects[i].isLocalProject) {
          filtered_projects.push(projects[i]);
        }
      }
      return filtered_projects;
    },
    loadProjects: function loadProjects(tenancy, index) {
      var _this24 = this;
      this.loading.data_import = true;
      var headers = {
        'Content-Type': 'application/json',
        'x-party-id': this.$store.getters.partyId,
        'x-user-id': this.$store.getters.id
      };
      _axios.default.get(process.env.VUE_APP_REST_API_ENDPOINT + 'tprty/' + tenancy.id + '/project', {
        headers: headers
      }).then(function (response) {
        _this24.loading.data_import = false;
        if (response.data.status === 'SUCCESS') {
          _this24.integrations[index].projects = response.data.data;
          _this24.$nextTick();
          _this24.$forceUpdate();
        }
      }).catch(function (error) {
        _this24.loading.data_import = false;
        console.log(error);
      });
    },
    retrieveExternalTicketList: function retrieveExternalTicketList(val) {
      var _this25 = this;
      this.hasTicket = false;
      var query = '';
      query = 'ticket?externalType=Epic';
      var headers = {
        'Content-Type': 'application/json',
        'x-party-id': this.$store.getters.partyId,
        'x-user-id': this.$store.getters.id
      };
      _axios.default.get(process.env.VUE_APP_REST_API_ENDPOINT + query + '&page=' + 1 + '&q=' + val, {
        headers: headers
      }).then(function (response) {
        if (response.data.status === 'SUCCESS' && response.data.data.length > 0) {
          _this25.hasTicket = true;
          _this25.$confirm('This Project has ticket imported already. Would you like to resync?', 'Warning', {
            confirmButtonText: 'OK',
            cancelButtonText: 'Cancel',
            type: 'warning'
          }).then(function () {
            _this25.importProjectTickets();
          }).catch(function () {
            _this25.hasTicket = false;
            console.log('cancelled');
          });
        }
      }).catch(function (error) {
        console.log(error);
        _this25.hasTicket = false;
      });
    },
    retrieveIntegrations: function retrieveIntegrations() {
      var _this26 = this;
      this.loading.data_import = true;
      var headers = {
        'Content-Type': 'application/json',
        'x-party-id': this.$store.getters.partyId,
        'x-user-id': this.$store.getters.id
      };
      _axios.default.get(process.env.VUE_APP_REST_API_ENDPOINT + 'tprty/setup?platform=true&extended=LEVEL0', {
        headers: headers
      }).then(function (response) {
        _this26.loading.data_import = false;
        _this26.integrations = [];
        if (response.data.status === 'SUCCESS') {
          _this26.integrations = response.data.data;
          for (var i = 0; i < _this26.integrations.length; i++) {
            _this26.integrations[i].projects = [];
            _this26.integrations[i].enabled = false;
            _this26.loadProjects(_this26.integrations[i], i);
            _this26.isEnabled(_this26.integrations[i].id, i);
          }
          // console.log(this.integrations)
        } else {
          _this26.loading.data_import = false;
          _this26.$notify({
            title: 'Error',
            message: response.data.data.description,
            type: 'error',
            duration: 2000
          });
        }
      }).catch(function (error) {
        _this26.integrations = [];
        _this26.loading.data_import = false;
        console.log(error);
      });
    },
    isEnabled: function isEnabled(integration_id, integration_index) {
      var _this27 = this;
      this.loading.data_import = true;
      var headers = {
        'Content-Type': 'application/json',
        'x-party-id': this.$store.getters.partyId,
        'x-user-id': this.$store.getters.id
      };
      _axios.default.get(process.env.VUE_APP_REST_API_ENDPOINT + 'tprty/' + integration_id + '/auth/isenabled', {
        headers: headers
      }).then(function (response) {
        _this27.loading.data_import = false;
        if (response.data.status === 'SUCCESS') {
          _this27.integrations[integration_index].enabled = response.data.data.enabled;
          _this27.integrations[integration_index].isTokenActive = response.data.data.isTokenActive;
          _this27.integrations[integration_index].isSync = false;
          _this27.$nextTick();
          _this27.$forceUpdate();
          // this.enabledStatus = response.data.data
        }
      }).catch(function (error) {
        console.log(error);
        _this27.loading.data_import = false;
      });
    },
    computePhaseX: function computePhaseX() {
      this.phase_pos_x = [];
      if (!this.sortedStages) {
        return this.phase_pos_x;
      }
      for (var i = 0; i < this.sortedStages.length; i++) {
        if (i === 0) {
          this.phase_pos_x.push(175);
        } else {
          var length = 0;
          for (var j = 0; j < i; j++) {
            length += this.computeLegacyPhaseLength(this.sortedStages[j].journeyPhase, j);
            length += 50;
          }
          this.phase_pos_x.push(175 + length);
        }
      }

      // send back both phase pos and width
    },
    computeLegacyPhaseLength: function computeLegacyPhaseLength(phase, index) {
      // get max number of features in product for given phase
      // if less than 2 use default 175, else use length off features
      var max_count = 0;

      // Get an array of pains/gains for current phase
      if (typeof phase === 'undefined') {
        return 225;
      }
      for (var i = 0; i < this.journeyMap.additionalattributes.relations.length; i++) {
        if (this.journeyMap.additionalattributes.relations[i].start_type === 'Phase' && this.journeyMap.additionalattributes.relations[i].end_type === 'Step' && this.journeyMap.additionalattributes.relations[i].start_id === phase.id) {
          max_count++;
        }
      }
      var additional_columns = Math.floor(max_count / 7) - 1;
      var first_column = 150;
      return 225 + first_column + additional_columns * 150;
    },
    // Used only for legacy support code
    computeLegacyServiceUnmappedIndex: function computeLegacyServiceUnmappedIndex(mergedEntities, service_index, service_application_index) {
      var count = 0;
      for (var j = 0; j < service_application_index; j++) {
        if (mergedEntities[service_index].service.linkedServiceApplications[j].serviceApplication.relations.id === null || !this.hasServiceApplicationRelation(mergedEntities[service_index].service.linkedServiceApplications[j].serviceApplication.id)) {
          count++;
        }
      }
      return count;
    },
    computeLegacyProductUnmappedIndex: function computeLegacyProductUnmappedIndex(mergedEntities, product_index, feature_index) {
      var count = 0;
      for (var j = 0; j < feature_index; j++) {
        if (mergedEntities[product_index].product.linkedFeatures[j].productFeature.relations.steps[0].id === null || !this.hasFeatureRelation(mergedEntities[product_index].product.linkedFeatures[j].productFeature.id)) {
          count++;
        }
      }
      return count;
    },
    computeLegacyProductEnablerIndex: function computeLegacyProductEnablerIndex(mergedEntities, product_index, feature_index) {
      var count = 0;
      for (var j = 0; j < feature_index; j++) {
        if (mergedEntities[product_index].product.linkedFeatures[j].productFeature.enabler) {
          count++;
        }
      }
      return count;
    },
    computeLegacyEntityLaneHeightCompounded: function computeLegacyEntityLaneHeightCompounded(mergedEntities, index) {
      var height = 0;
      for (var i = 0; i < index; i++) {
        if (mergedEntities[i].__typename === 'LinkProductOffering' && mergedEntities[i].product.additionalattributes.minimized) {
          height += 25;
        } else if (mergedEntities[i].__typename === 'LinkServiceOffering' && mergedEntities[i].service.additionalattributes.minimized) {
          height += 25;
        } else {
          if (mergedEntities[i].__typename === 'LinkProductOffering') {
            height += 100;
            if (this.productHasUnmappedFeatures(mergedEntities[i].product)) {
              height += 50;
            }
            if (this.productHasEnablerFeatures(mergedEntities[i].product)) {
              height += 50;
            }
          } else if (mergedEntities[i].__typename === 'LinkServiceOffering') {
            height += 100;
            if (this.serviceHasUnmappedFeatures(mergedEntities[i].service)) {
              height += 50;
            }
            if (this.serviceHasEnablerFeatures(mergedEntities[i].service)) {
              height += 50;
            }
          } else {
            height += 100;
          }
        }
      }
      return height + index * 5;
    },
    // Reset
    resetServiceApplicationForm: function resetServiceApplicationForm() {
      this.service_application_form = {
        id: '',
        title: '',
        description: '',
        priority: 'Medium',
        status: 'Backlog',
        serviceId: '',
        serviceTitle: '',
        featureId: '',
        stepId: '',
        enabler: false,
        type: 'automated',
        posX: 0,
        posY: 0
      };
    },
    resetRiskForm: function resetRiskForm() {
      this.risk_form = {
        title: '',
        description: '',
        severity: 'Low',
        occuranceprobability: 'Low',
        impactlevel: 'Low',
        mitigationplan: '',
        entityname: '',
        entityid: ''
      };
    },
    resetFeatureForm: function resetFeatureForm() {
      this.feature_form = {
        key: '',
        title: '',
        description: '',
        priority: 'Medium',
        status: 'Backlog',
        productName: '',
        productId: '',
        offeringId: '',
        posX: 0,
        issue_source: 'prodiggi',
        step_id: ''
      };
    },
    resetHighlightAllPhaseChannelGap: function resetHighlightAllPhaseChannelGap() {
      this.phaseChannelGapHighlighter = [];
      this.maxedPhaseChannelGapHighlighter = [];
    },
    resetHighlightAllSteps: function resetHighlightAllSteps() {
      this.stepHighlighter = [];
    },
    resetHighlightAllProductFeatures: function resetHighlightAllProductFeatures() {
      this.featureHighlighter = [];
    },
    resetHighlightAllServiceApplications: function resetHighlightAllServiceApplications() {
      this.serviceApplicationHighlighter = [];
    },
    resetSettingsAndMenu: function resetSettingsAndMenu() {
      this.resetEntitySettings();
      this.resetEntityMenu();
      // this.selectedParent = {}
      // this.selectedEntity = {}
      // this.selectedEntityIndex = null
      // this.selectedParentIndex = null
    },
    resetOfferingForm: function resetOfferingForm() {
      this.offering_form = {
        title: '',
        description: '',
        offeringtype: 'External',
        offeringcategory: 'PRIMARY',
        variant: 'variant',
        customerTypeId: '',
        type: 'new'
      };
    },
    resetHoverDetailsDialog: function resetHoverDetailsDialog() {
      this.phaseHover = false;
      this.taskHover = false;
      this.touchpointHover = false;
      this.feature.hover = false;
      this.service_application.hover = false;
      this.phaseDetailsDialog = false;
      this.taskDetailsDialog = false;
    },
    resetHover: function resetHover() {
      this.phaseHover = false;
      this.taskHover = false;
      this.touchpointHover = false;
      this.feature.hover = false;
      this.service_application.hover = false;
    },
    resetCanvas: function resetCanvas() {
      this.resetHover();
      this.resetSettingsAndMenu();
      this.resetDropHover();
      this.resetEntityMenu();
      this.resetEntitySettings();
    },
    resetCanvasLayout: function resetCanvasLayout() {
      this.resetJourneyMap = !this.resetJourneyMap;
    },
    resetDropHover: function resetDropHover() {
      window._jquery('#hover_highlight > rect').attr('x', -100);
      window._jquery('#hover_highlight > rect').attr('y', -100);
      this.drop_hover_details = {
        x: 0,
        y: 0,
        width: 60,
        height: 70,
        hover: false
      };
    },
    resetServiceForm: function resetServiceForm() {
      this.service_form = {
        title: '',
        description: '',
        offeringId: '',
        manager: '',
        owner: ''
      };
    },
    resetProductForm: function resetProductForm() {
      this.product_form = {
        title: '',
        description: '',
        offeringId: '',
        manager: '',
        owner: ''
      };
    },
    resetEntityMenu: function resetEntityMenu() {
      this.service_menu = {
        visible: false,
        x: 0,
        y: 0,
        path: '',
        has_unmapped: false,
        values: [{
          title: 'Mapped'
        }, {
          title: 'Reports'
        }, {
          title: 'Idea Details'
        }, {
          title: 'Risk Details'
        }]
      };
      this.product_menu = {
        visible: false,
        x: 0,
        y: 0,
        path: '',
        has_unmapped: false,
        values: [{
          title: 'Mapped'
        }, {
          title: 'Reports'
        }, {
          title: 'Idea Details'
        }, {
          title: 'Risk Details'
        }]
      };
    },
    resetEntitySettings: function resetEntitySettings() {
      this.touchpoint_settings = {
        visible: false,
        x: 0,
        y: 0,
        path: '',
        values: [{
          title: 'Configure Touchpoints'
        }]
      };
      this.product_settings = {
        visible: false,
        x: 0,
        y: 0,
        path: '',
        values: [{
          title: 'Product Details'
        }, {
          title: 'Integration Settings'
        }, {
          title: 'Manage imported tickets'
        }, {
          title: 'Sync Tickets'
        }, {
          title: 'Prioritise External Backlog'
        }, {
          title: 'Auto arrange'
        }, {
          title: 'Unlink'
        }
        /* {
          title: 'Delete'
        }*/]
      };
      this.service_settings = {
        visible: false,
        x: 0,
        y: 0,
        path: '',
        values: [{
          title: 'Service Details'
        }, {
          title: 'Integration Settings'
        }, {
          title: 'Manage imported tickets'
        }, {
          title: 'Sync Tickets'
        }, {
          title: 'Prioritise External Backlog'
        }, {
          title: 'Auto arrange'
        }, {
          title: 'Unlink'
        }
        /* {
          title: 'Delete'
        }*/]
      };
    }
  }
};