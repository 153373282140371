"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
var _interopRequireWildcard3 = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _taggedTemplateLiteral2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/taggedTemplateLiteral.js"));
var _objectSpread2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _interopRequireWildcard2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var htmlToImage = _interopRequireWildcard3(require("html-to-image"));
var _vuex = require("vuex");
var _templateObject, _templateObject2; // import { toPng } from 'html-to-image';
var _default2 = exports.default = {
  name: 'CustomerJourney',
  components: {
    SVGCTAComponent: function SVGCTAComponent() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/components/SVGCTA'));
      });
    },
    JourneyHorizontalComponent: function JourneyHorizontalComponent() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./journey-layouts/horizontal.vue"));
      });
    },
    PhaseCreateComponent: function PhaseCreateComponent() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./journey/PhaseCreate.vue"));
      });
    },
    TaskCreateComponent: function TaskCreateComponent() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./journey/TaskCreate.vue"));
      });
    },
    ExperienceCreateComponent: function ExperienceCreateComponent() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./journey/ExperienceCreate.vue"));
      });
    },
    TouchpointCreateComponent: function TouchpointCreateComponent() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./journey/TouchpointCreate.vue"));
      });
    },
    IdeaCreateComponent: function IdeaCreateComponent() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./journey/IdeaCreate.vue"));
      });
    },
    RiskCreateComponent: function RiskCreateComponent() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./journey/RiskCreate.vue"));
      });
    },
    TaskDetailComponent: function TaskDetailComponent() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./journey/TaskDetail.vue"));
      });
    },
    SettingsComponent: function SettingsComponent() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./journey/Settings.vue"));
      });
    },
    RelationDetailsComponent: function RelationDetailsComponent() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./journey/RelationDetails.vue"));
      });
    }
  },
  props: {
    customer: {
      type: Object,
      required: true,
      default: function _default() {
        return {
          id: '',
          title: '',
          description: '',
          tiers: '',
          rationale: '',
          priority: '',
          persona: '',
          additionalattributes: {},
          linkedJourneyMaps: []
        };
      }
    }
  },
  data: function data() {
    return {
      loading: false,
      // Important!
      // Adding a version parameter to handle crashes due to changing data format
      // any changes to major version will need creation of new journeymap
      // minor versions are only for futureproofing. no actual use now.
      // This is currently hardcoded values, to be changes everytime data object isupdated with no backward support

      fetchTimeStamp: '',
      dataVersion: {
        major: 4,
        minor: 1
      },
      // Journey Map
      journeyZoom: 100,
      layoutType: 'horizontal',
      settingsDialog: false,
      isThoughtVisible: true,
      isTouchPointLaneVisible: true,
      isEntityPopoverVisible: true,
      resetLayoutFlag: 0,
      // Phase
      addPhaseDialog: false,
      phase: {
        edit: false,
        selected: {},
        selected_index: null,
        selected_tab: 'details',
        drop_details: {
          index: 0,
          isBefore: false
        }
      },
      // Task
      addTaskDialog: false,
      taskDetailsDialog: false,
      task: {
        selected: {},
        selected_index: null,
        selected_tab: 'step_details'
      },
      stepLayoutType: 'horizontal',
      // Experience
      createExperienceDialog: false,
      experience: {
        edit: false,
        selected: {
          experiencetype: '',
          dialog_title: '',
          dialog_description: '',
          title: '',
          description: '',
          priority: 'Low',
          additionalattributes: {
            customer_value: 'Medium'
          }
        },
        selected_index: null
      },
      // Idea
      createIdeaDialog: false,
      idea: {
        scope: '',
        scopename: ''
      },
      // Risk
      createRiskDialog: false,
      risk: {
        scope: '',
        scopename: ''
      },
      // Relation
      relationDetailsDialog: false,
      selectedRelation: {},
      selectedRelationIndex: null,
      selectedRelationType: '',
      // Touchpoint
      addTouchpointDialog: false,
      isTouchpointEdit: false,
      touchpoint: {
        title: '',
        description: '',
        type: '',
        index: ''
      },
      timer: null,
      refreshKey: 0
    };
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['journeyMap', 'sidebar', 'journeyMapFetchStatus'])), {}, {
    computeHeaderStyle: function computeHeaderStyle() {
      this.refreshKey;
      if (this.sidebar.opened) {
        return 'background-color: ghostwhite; padding: 10pt 0 0 6pt; height: 38pt; border-radius: 4pt; box-shadow: 3px 3px 3px 2px rgba(0,0,0,0.5); width: calc(99%); margin-top:3pt';
      } else {
        return 'background-color: ghostwhite; padding: 10pt 0 0 6pt; height: 38pt; border-radius: 4pt; box-shadow: 3px 3px 3px 2px rgba(0,0,0,0.5); width: calc(100% - 50px); margin-top:3pt';
      }
    },
    computeCanvasWidth: function computeCanvasWidth() {
      return 3000;
    },
    computeCanvasHeight: function computeCanvasHeight() {
      return 2000;
    },
    isSupportedVersion: function isSupportedVersion() {
      if (!this.journeyMap.additionalattributes.version) {
        return [false, 'legacy'];
      }
      if (this.journeyMap.additionalattributes.layouts && this.journeyMap.additionalattributes.relations && this.journeyMap.additionalattributes.version.major === this.dataVersion.major) {
        return [true, ''];
      } else if (this.journeyMap.additionalattributes.layouts && this.journeyMap.additionalattributes.relations && this.journeyMap.additionalattributes.version.major === this.dataVersion.major) {
        return [false, 'version'];
      }
      return [false, 'legacy'];
    },
    sortedStages: function sortedStages() {
      var stagesSortOrder = [];
      if (this.journeyMap.additionalattributes.layouts && this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages.length > 0 && this.journeyMap.linkedJourneyPhases.length > 0) {
        for (var i = 0; i < this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages.length; i++) {
          for (var j = 0; j < this.journeyMap.linkedJourneyPhases.length; j++) {
            if (this.journeyMap.linkedJourneyPhases[j].journeyPhase.id === this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[i].id && !this.journeyMap.linkedJourneyPhases[j].journeyPhase.isDeleted) {
              stagesSortOrder.push(this.journeyMap.linkedJourneyPhases[j]);
              break;
            }
          }
        }
      } else {
        for (var k = 0; k < this.journeyMap.linkedJourneyPhases.length; k++) {
          if (!this.journeyMap.linkedJourneyPhases[k].journeyPhase.hasOwnProperty('isDeleted')) {
            stagesSortOrder.push(this.journeyMap.linkedJourneyPhases[k]);
          } else if (this.journeyMap.linkedJourneyPhases[k].journeyPhase.hasOwnProperty('isDeleted') && this.journeyMap.linkedJourneyPhases[k].journeyPhase.isDeleted === false) {
            stagesSortOrder.push(this.journeyMap.linkedJourneyPhases[k]);
            break;
          }
        }
      }
      return stagesSortOrder;
    }
  }),
  watch: {
    sidebar: function sidebar(val) {
      this.refreshKey++;
    }
  },
  created: function created() {
    // window.addEventListener('beforeunload', this.confirmTabClosing)
  },
  beforeDestroy: function beforeDestroy() {
    // window.removeEventListener('beforeunload', this.confirmTabClosing)
    // console.log('BeforeDestroy')
    clearInterval(this.timer);
  },
  activated: function activated() {
    // console.log('activated');
  },
  deactivated: function deactivated() {
    this.syncJourneyMap();
  },
  unmounted: function unmounted() {
    // console.log('unmounted')
  },
  mounted: function mounted() {
    var _this = this;
    this.computeFetchTimeStamp();
    this.timer = setInterval(function () {
      // TODO add more logic to calling sync
      _this.syncJourneyMap();
      _this.computeFetchTimeStamp();
    }, this.journeyMap.additionalattributes.settings.sync_frequency);
  },
  methods: {
    trimString: function trimString(string, length) {
      if (string.length > length) {
        return string.substring(0, length) + '...';
      } else {
        return string;
      }
    },
    computeFetchTimeStamp: function computeFetchTimeStamp() {
      this.fetchTimeStamp = 'Last updated ' + this.$moment(this.journeyMapFetchStatus.timestamp).fromNow();
    },
    isDevEnv: function isDevEnv() {
      return process.env.VUE_APP_ENV === 'development';
    },
    computeStepLayoutStyleHorizontal: function computeStepLayoutStyleHorizontal() {
      if (this.stepLayoutType === 'horizontal') {
        return 'color:white;stroke:white;';
      } else {
        return 'color:black;stroke:black;';
      }
    },
    computeStepLayoutStyleMatrix: function computeStepLayoutStyleMatrix() {
      if (this.stepLayoutType === 'matrix') {
        return 'color:white;stroke:white;';
      } else {
        return 'color:black;stroke:black;';
      }
    },
    syncJourneyMap: function syncJourneyMap() {
      this.$emit('sync-journeymap');
    },
    syncTouchpoints: function syncTouchpoints() {
      this.$emit('sync-touchpoints');
    },
    confirmTabClosing: function confirmTabClosing(event) {
      event.preventDefault();
      // Customize the message to display in the confirmation dialog
      event.returnValue = 'Are you sure you want to leave this page?';
    },
    selectRelation: function selectRelation(relation, index, type) {
      this.selectedRelation = relation;
      this.selectedRelationIndex = index;
      this.selectedRelationType = type;
      this.relationDetailsDialog = true;
    },
    closeRelationDetailsDialog: function closeRelationDetailsDialog() {
      this.selectedRelation = {};
      this.selectedRelationType = '';
      this.selectedRelationIndex = null;
      this.relationDetailsDialog = false;
    },
    addIdea: function addIdea(idea) {
      // Event trigerred after idea creation via emit from component
      // Currently its a passthro event to just reload the journeymap,
      // Can be used for future flexibility
      this.$emit('add-idea', idea);
    },
    addRisk: function addRisk() {
      // Event trigerred after idea creation via emit from component
      // Currently its a passthro event to just reload the journeymap,
      // Can be used for future flexibility
      this.$emit('add-risk');
    },
    addExperience: function addExperience(experience) {
      if (this.task.selected.id !== undefined) {
        this.$emit('add-step-experience', this.task.selected.id, experience, this.layoutType);
      } else {
        this.$emit('add-phase-experience', this.phase.selected.id, experience, this.layoutType);
      }
      this.closeAddExperienceDialog();
    },
    updateExperience: function updateExperience(experience) {
      this.$emit('edit-experience', experience);
      this.closeAddExperienceDialog();
    },
    addJourneyMapTask: function addJourneyMapTask(task) {
      this.$emit('add-step', task, this.phase.selected, this.layoutType);
      this.closeAddTaskDialog();
    },
    addTouchpoint: function addTouchpoint(touchpoint) {
      this.$emit('add-touchpoint', touchpoint, this.phase.selected, this.layoutType);
      this.closeAddTouchpointDialog();
    },
    updateTouchpoint: function updateTouchpoint(touchpoint, touchpoint_index) {
      this.$emit('edit-touchpoint', touchpoint, touchpoint_index, this.phase.selected, this.layoutType);
      this.resetTouchpoint();
      this.closeAddTouchpointDialog();
    },
    addJourneyMapPhase: function addJourneyMapPhase(phase) {
      this.$emit('add-phase', phase, this.phase.drop_details, this.layoutType);
      this.closeAddPhaseDialog();
    },
    editJourneyMapPhaseSortOrder: function editJourneyMapPhaseSortOrder(journey_map, stage_sort_order) {
      this.$emit('edit-phase-sort-order', journey_map, stage_sort_order, this.layoutType);
    },
    editJourneyMapPhase: function editJourneyMapPhase(phase) {
      this.$emit('edit-phase', phase);
      this.closeAddPhaseDialog();
    },
    updateTaskToWorkflow: function updateTaskToWorkflow(start_step_id, end_step_id) {
      this.$emit('update-task-to-workflow', start_step_id, end_step_id);
    },
    swapPhaseStepRelation: function swapPhaseStepRelation(step_id, old_phase_id, new_phase_id) {
      this.$emit('swap-phase-step-relation', step_id, old_phase_id, new_phase_id);
    },
    // UI METHODS
    updateStep: function updateStep(step) {
      var _this2 = this;
      this.$apollo.mutate({
        // Query
        mutation: window._gql(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["mutation updateJourneyStep($journeyStep: UpdateJourneyStepInput!)\n        {\n          updateJourneyStep(input: $journeyStep) {\n            id\n          }\n        }"]))),
        // Parameters
        variables: {
          'journeyStep': {
            'id': step.id,
            'title': step.title,
            'description': step.description,
            'intention': step.intention,
            'importance': step.importance,
            'goal': step.goal
          }
        }
      }).then(function (data) {
        _this2.$notify({
          title: 'Success',
          message: 'Updated Successfully',
          type: 'success',
          duration: 2000
        });
        _this2.closeTaskDetailsDialog();
      }).catch(function (error) {
        // Error
        _this2.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
      });
    },
    renameJourneymap: function renameJourneymap(journey_map) {
      this.$emit('rename-journeymap', journey_map);
      this.closeSettingsDialog();
    },
    regenerateJourneymap: function regenerateJourneymap(journey_map) {
      this.$emit('regenerate-journeymap', journey_map);
      this.closeSettingsDialog();
    },
    deleteJourney: function deleteJourney(journey_id) {
      this.$emit('delete-journeymap', journey_id);
    },
    deleteExperience: function deleteExperience(experience_id) {
      this.$emit('delete-experience', experience_id);
      this.closeAddExperienceDialog();
    },
    deleteStep: function deleteStep(step_id) {
      this.$emit('delete-step', step_id);
      this.closeTaskDetailsDialog();
    },
    deletePhase: function deletePhase(phase_id) {
      this.addPhaseDialog = false;
      this.$emit('delete-phase', phase_id);
    },
    createExperienceFromEntity: function createExperienceFromEntity(experience_type) {
      this.resetExperienceDetails();
      switch (experience_type) {
        case 'GAIN':
          this.experience.selected.experiencetype = 'Gain';
          this.experience.selected.dialog_title = 'Gain';
          this.experience.selected.dialog_description = 'Positive experiences felt by the customer.';
          break;
        case 'PAIN':
          this.experience.selected.experiencetype = 'Pain';
          this.experience.selected.dialog_title = 'Pain';
          this.experience.selected.dialog_description = 'Negative experiences felt by the customer.';
          break;
        case 'WOW':
          this.experience.selected.experiencetype = 'Wow';
          this.experience.selected.dialog_title = 'Wow';
          this.experience.selected.dialog_description = 'Breakthrough experiences that show new possibilities to the customer.';
          break;
        case 'CHALLENGE':
          this.experience.selected.experiencetype = 'Challenge';
          this.experience.selected.dialog_title = 'Obscurity';
          this.experience.selected.dialog_description = 'Hidden complexities the customer is not aware of but must overcome (expert knowledge)';
          break;
      }
      this.createExperienceDialog = true;
    },
    showEditExperienceDialog: function showEditExperienceDialog(edit, experience, experience_index) {
      this.resetExperienceDetails();
      this.experience.edit = edit;
      this.experience.selected = experience;
      this.experience.selected_index = experience_index;
      this.createExperienceDialog = true;
    },
    showEditPhaseDialog: function showEditPhaseDialog(edit, phase, phase_index, tab) {
      this.resetPhaseDetails();
      this.phase.edit = edit;
      this.phase.selected = phase;
      this.phase.selected_index = phase_index;
      this.addPhaseDialog = true;
      this.phase.selected_tab = tab;
    },
    showAddPhaseDialog: function showAddPhaseDialog(phase_drop_details) {
      this.resetPhaseDetails();
      this.phase.drop_details = phase_drop_details;
      this.addPhaseDialog = true;
    },
    showAddTaskDialog: function showAddTaskDialog(phase, phase_index) {
      this.phase.selected = phase;
      this.phase.selected_index = phase_index;
      this.addTaskDialog = true;
    },
    showAddTouchPointDialog: function showAddTouchPointDialog(phase, phase_index, touchpoint_type) {
      this.resetTouchpoint();
      this.phase.selected = phase;
      this.phase.selected_index = phase_index;
      this.touchpoint.title = '';
      this.touchpoint.description = '';
      this.touchpoint.index = '';
      this.touchpoint.type = touchpoint_type;
      this.isTouchpointEdit = false;
      this.addTouchpointDialog = true;
    },
    showTaskDetailsDialog: function showTaskDetailsDialog(index, task, tab) {
      this.resetTaskDetails();
      this.task.selected_index = index;
      this.task.selected = task;
      this.taskDetailsDialog = true;
      this.task.selected_tab = tab;
    },
    showTouchPointDetailsDialog: function showTouchPointDetailsDialog(stage_index, touchpoint_index) {
      var phase = this.retrievePhaseFromPhaseId(this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[stage_index].id);
      var touchpoint = this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[stage_index].touchpoints[touchpoint_index];
      this.phase.selected = phase;
      this.phase.selected_index = stage_index;
      this.touchpoint = touchpoint;
      this.touchpoint.index = touchpoint_index;
      this.isTouchpointEdit = true;
      this.addTouchpointDialog = true;
    },
    retrievePhaseFromPhaseId: function retrievePhaseFromPhaseId(phase_id) {
      for (var i = 0; i < this.journeyMap.linkedJourneyPhases.length; i++) {
        if (this.journeyMap.linkedJourneyPhases[i].journeyPhase.id === phase_id) {
          return this.journeyMap.linkedJourneyPhases[i].journeyPhase;
        }
      }
      return {};
    },
    showAddExperienceFromPhaseDialog: function showAddExperienceFromPhaseDialog(experience_type, phase, phase_index) {
      this.resetTaskDetails();
      this.phase.selected = phase;
      this.phase.selected_index = phase_index;
      this.createExperienceFromEntity(experience_type);
    },
    showAddExperienceFromStepDialog: function showAddExperienceFromStepDialog(experience_type, task, task_index) {
      this.resetPhaseDetails();
      this.task.selected = task;
      this.task.selected_index = task_index;
      this.createExperienceFromEntity(experience_type);
    },
    showSettingsDialog: function showSettingsDialog() {
      this.settingsDialog = true;
    },
    closeAddPhaseDialog: function closeAddPhaseDialog() {
      this.resetPhaseDetails();
      this.addPhaseDialog = false;
    },
    closeAddTouchpointDialog: function closeAddTouchpointDialog() {
      this.isTouchpointEdit = false;
      this.addTouchpointDialog = false;
    },
    closeAddTaskDialog: function closeAddTaskDialog() {
      this.addTaskDialog = false;
    },
    closeAddExperienceDialog: function closeAddExperienceDialog() {
      this.createExperienceDialog = false;
    },
    closeAddIdeaDialog: function closeAddIdeaDialog() {
      this.createIdeaDialog = false;
    },
    closeAddRiskDialog: function closeAddRiskDialog() {
      this.createRiskDialog = false;
    },
    closeSettingsDialog: function closeSettingsDialog() {
      this.settingsDialog = false;
    },
    closeTaskDetailsDialog: function closeTaskDetailsDialog() {
      this.resetTaskDetails();
      this.taskDetailsDialog = false;
    },
    showPhaseIdeaDialog: function showPhaseIdeaDialog(scopename) {
      this.idea.scope = 'Phase';
      this.idea.scopename = scopename;
      this.createIdeaDialog = true;
    },
    showStepIdeaDialog: function showStepIdeaDialog(scopename) {
      this.idea.scope = 'Step';
      this.idea.scopename = scopename;
      this.createIdeaDialog = true;
    },
    showJourneyIdeaDialog: function showJourneyIdeaDialog(scopename) {
      this.idea.scope = 'Journey';
      this.idea.scopename = scopename;
      this.createIdeaDialog = true;
    },
    showPhaseRiskDialog: function showPhaseRiskDialog(scopename) {
      this.risk.scope = 'Phase';
      this.risk.scopename = scopename;
      this.createRiskDialog = true;
    },
    showStepRiskDialog: function showStepRiskDialog(scopename) {
      this.risk.scope = 'Step';
      this.risk.scopename = scopename;
      this.createRiskDialog = true;
    },
    showJourneyRiskDialog: function showJourneyRiskDialog(scopename) {
      this.risk.scope = 'Journey';
      this.risk.scopename = scopename;
      this.createRiskDialog = true;
    },
    updateStepLayout: function updateStepLayout(type) {
      this.stepLayoutType = type;
    },
    fetchJourneyMap: function fetchJourneyMap() {
      this.$emit('fetch-journeymap');
    },
    uploadJourneyMap: function uploadJourneyMap() {
      this.$emit('upload-journeymap');
    },
    zoomJourney: function zoomJourney() {
      var root = document.querySelector(':root');
      // var rootStyles = getComputedStyle(root)
      // var zoom = rootStyles.getPropertyValue('--cssZoomVar')
      root.style.setProperty('--cssZoomVar', this.journeyZoom / 100);
    },
    handleCTAResponse: function handleCTAResponse(title) {
      if (title.indexOf('update') !== -1) {
        this.$swal({
          title: 'Journeymap Updation',
          text: 'To be updated',
          icon: 'info'
        });
      } else if (title.indexOf('new') !== -1) {
        this.$emit('create-new-journeymap');
      } else {
        this.$router.push({
          name: "Journey"
        });
      }
    },
    // UI METHODS STOP
    // DRAG DROP METHODS
    handleDrop: function handleDrop(event) {
      event.preventDefault(); // !important
    },
    handleDragOver: function handleDragOver(event) {
      event.preventDefault(); // !important
    },
    // DRAG DROP METHODS STOP
    handleDropDownCommand: function handleDropDownCommand(command) {
      switch (command) {
        case 'createJourneyIdea':
          this.showJourneyIdeaDialog();
          break;
        case 'Approve':
          break;
        case 'showSettingsDialog':
          this.showSettingsDialog();
          break;
        case 'resetCanvasLayout':
          this.resetCanvasLayout();
          break;
        case 'runCleanup':
          this.runCleanup();
          break;
        case 'hideThoughts':
          this.isThoughtVisible = false;
          break;
        case 'showThoughts':
          this.isThoughtVisible = true;
          break;
        case 'showTouchPoints':
          this.isTouchPointLaneVisible = true;
          break;
        case 'hideTouchPoints':
          this.isTouchPointLaneVisible = false;
          break;
        case 'exportPNG':
          this.exportAsPNG();
          break;
        case 'exportSVG':
          this.exportAsSVG();
          break;
        case 'syncJourneyMap':
          this.syncJourneyMap();
          break;
        case 'syncTouchpoints':
          this.syncTouchpoints();
          break;
      }
    },
    exportAsPNG: function exportAsPNG() {
      /* const input = document.querySelector('#journeyMapSVG')
        const svgData = new XMLSerializer().serializeToString(input)
      const svgDataBase64 = btoa(unescape(encodeURIComponent(svgData)))
      const svgDataUrl = `data:image/svg+xml;charset=utf-8;base64,${svgDataBase64}`
        var link = document.createElement('a')
      link.download = 'test'
      link.href = svgDataUrl
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)*/
      var node = document.getElementById('journeyMapSVG');
      htmlToImage.toPng(node, {
        quality: 1,
        pixelRatio: 4
      }).then(function (dataUrl) {
        var link = document.createElement('a');
        link.download = 'export';
        link.href = dataUrl;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }).catch(function (error) {
        console.error('oops, something went wrong!', error);
      });
    },
    exportAsSVG: function exportAsSVG() {
      var svgElement = document.getElementById('journeyMapSVG'); // Replace with the ID of your SVG element
      var serializer = new XMLSerializer();
      var svgString = serializer.serializeToString(svgElement);
      var dataURL = 'data:image/svg+xml,' + encodeURIComponent(svgString);
      var downloadLink = document.createElement('a');
      downloadLink.href = dataURL;
      downloadLink.download = 'exported.svg'; // Set the desired filename here

      downloadLink.click();
    },
    // HELPER FUNCTIONS
    resetCanvasLayout: function resetCanvasLayout() {
      this.resetLayoutFlag++;
    },
    resetPhaseDetails: function resetPhaseDetails() {
      this.phase.edit = false;
      this.phase.selected = {};
      this.phase.selected_index = null;
      this.phase.selected_tab = 'details';
      this.phase.drop_details = {
        index: 0,
        isBefore: false
      };
    },
    resetTouchpoint: function resetTouchpoint() {
      this.touchpoint = {
        title: '',
        description: '',
        type: '',
        index: ''
      };
    },
    resetTaskDetails: function resetTaskDetails() {
      this.task.selected = {};
      this.task.selected_index = null;
      this.task.selected_tab = 'step_details';
    },
    resetExperienceDetails: function resetExperienceDetails() {
      this.experience = {
        edit: false,
        selected: {
          experiencetype: '',
          dialog_title: '',
          dialog_description: '',
          title: '',
          description: '',
          priority: 'Low',
          additionalattributes: {
            customer_value: 'Medium'
          }
        },
        selected_index: null
      };
    },
    // HELPER FUNCTIONS STOP
    runCleanup: function runCleanup() {
      var _this3 = this;
      // Cleanup Relations, Phase Sort order, Step Sort order
      var additionalattributes = this.journeyMap.additionalattributes;
      var entity_ids = [];
      var relations = [];
      var phase_ids = [];
      var step_ids = [];
      for (var j = 0; j < this.journeyMap.linkedJourneyPhases.length; j++) {
        entity_ids.push(this.journeyMap.linkedJourneyPhases[j].journeyPhase.id);
        phase_ids.push(this.journeyMap.linkedJourneyPhases[j].journeyPhase.id);
      }
      for (var k = 0; k < this.journeyMap.linkedJourneySteps.length; k++) {
        entity_ids.push(this.journeyMap.linkedJourneySteps[k].journeyStep.id);
        step_ids.push(this.journeyMap.linkedJourneySteps[k].journeyStep.id);
      }
      for (var l = 0; l < this.journeyMap.linkedJourneyExperiences.length; l++) {
        entity_ids.push(this.journeyMap.linkedJourneyExperiences[l].journeyExperience.id);
      }
      for (var i = 0; i < additionalattributes.relations.length; i++) {
        if (!additionalattributes.relations[i].id.includes('undefined') && !additionalattributes.relations[i].id.includes('temp') && (entity_ids.includes(additionalattributes.relations[i].start_id) || entity_ids.includes(additionalattributes.relations[i].end_id))) {
          relations.push(additionalattributes.relations[i]);
        }
      }
      additionalattributes.relations = relations;
      additionalattributes.layouts.horizontal.sortOrder.stages = phase_ids;
      this.loading = true;
      this.$apollo.mutate({
        // Query
        mutation: window._gql(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["mutation updateJourneyMap($journeymap: UpdateJourneyMapInput!)\n        {\n          updateJourneyMap(input: $journeymap) {\n            id\n          }\n        }"]))),
        // Parameters
        variables: {
          'journeymap': {
            'id': this.journeyMap.id,
            'additionalattributes': additionalattributes
          }
        }
      }).then(function (data) {
        _this3.loading = false;
      }).catch(function (error) {
        // Error
        _this3.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this3.loading = false;
      });
    }
  }
};