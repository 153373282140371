"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/taggedTemplateLiteral.js"));
require("core-js/modules/es6.function.name");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _objectSpread2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _interopRequireWildcard2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _vuex = require("vuex");
var _path = _interopRequireDefault(require("path"));
var _validate = require("@/utils/validate");
var _Item = _interopRequireDefault(require("./Item"));
var _Link = _interopRequireDefault(require("./Link"));
var _FixiOSBug = _interopRequireDefault(require("./FixiOSBug"));
var _templateObject, _templateObject2;
var _default = exports.default = {
  name: 'SidebarItem',
  components: {
    Item: _Item.default,
    AppLink: _Link.default,
    CreateCustomerComponent: function CreateCustomerComponent() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/customer_types/components/create-customer'));
      });
    },
    CreateOfferingComponent: function CreateOfferingComponent() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/offering/create'));
      });
    }
  },
  mixins: [_FixiOSBug.default],
  props: {
    // route object
    item: {
      type: Object,
      required: true
    },
    isNest: {
      type: Boolean,
      default: false
    },
    basePath: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    // To fix https://github.com/PanJiaChen/vue-admin-template/issues/237
    // TODO: refactor with render function
    this.onlyOneChild = null;
    return {
      loading: false,
      createCustomerTypeDialog: false,
      createOfferingDialog: false,
      offeringCreatedFlag: true,
      offerings: [],
      customer_types: [],
      selectedOfferingId: '',
      selectedCustomerTypeId: '',
      selectedCustomerTypeTitle: '',
      customer_menu: ['Overview', 'Jobs To Be Done', 'JourneyArchive', 'Journey', 'Experience Backlog', 'Journey Ideas'],
      offering_menu: ['Strategy', /* 'Mockup',*/
      'Design', 'FeatureBacklog', 'Need', 'RefineFeatures', 'Feature', 'ExternalDelivery', 'Overview', 'Risks', 'Ideas'],
      delivery_menu: ['OfferingRoadmap', 'CurrentRelease', 'PastRelease', 'DeliveryProgress', 'DeliverRisks']
    };
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['offeringId', 'modules', 'customerType', 'showMVP'])), {}, {
    computeMenuItemClass: function computeMenuItemClass() {
      if (this.isNest && this.offering_menu.includes(this.item.name) && this.item.meta.module !== 'Customer Needs') {
        return this.item.meta.type === 'primary' ? 'offering-submenu-item' : 'offering-addon-item';
      } else if (this.isNest && this.delivery_menu.includes(this.item.name) && this.item.meta.module !== 'Customer Needs') {
        return this.item.meta.type === 'primary' ? 'deliver-submenu-item' : 'deliver-addon-item';
      } else if (this.isNest && this.customer_menu.includes(this.item.name) && this.item.meta.module === 'Customer Needs') {
        return this.item.meta.type === 'primary' ? 'customer-submenu-item' : 'customer-addon-item';
      } else if (this.isNest && this.item.meta.module === 'Report') {
        return this.item.meta.type === 'primary' ? 'report-submenu-item' : 'report-addon-item';
      } else {
        return 'submenu-title-noDropdown';
      }
    },
    filteredPrimary: function filteredPrimary() {
      return this.item.children.filter(function (child) {
        return child.meta.type === 'primary';
      });
    },
    filteredAddOn: function filteredAddOn() {
      return this.item.children.filter(function (child) {
        return child.meta.type === 'addon';
      });
    }
  }),
  watch: {
    offeringId: function offeringId(val) {
      if (val !== null && val !== '') {
        this.retrieveOfferings(false);
        this.selectedOfferingId = val;
      } else if (val === '') {
        if (this.offerings.length > 0) {
          this.retrieveOfferings(true);
        }
        // TO handle scenario when no other offering is left
        // Ideal to show create offering UI
      }
    },
    customerType: {
      handler: function handler(val) {
        if (val !== null) {
          this.selectedCustomerTypeId = val.id;
          this.selectedCustomerTypeTitle = val.title;
          if (val.title) {
            for (var i = 0; i < this.customer_types.length; i++) {
              if (this.customer_types[i].id === val.id) {
                this.customer_types[i].title = val.title;
              }
            }
          }
        }
      },
      deep: true
    }
  },
  mounted: function mounted() {
    // TODO move it into offering menu click event.
    // Menu select event not being triggered. To be checked
    this.retrieveOfferings(true);
    this.$eventHub.$on('reload-customer-list', this.reloadCustomerList);
    this.$eventHub.$on('offering-created', this.retrieveOfferings);
    this.$eventHub.$on('delete-offering', this.deleteOffering);
    this.$eventHub.$on('delete-customer', this.deleteCustomerType);
    if (this.customerType) {
      this.retrieveCustomerTypes({
        id: this.customerType.id
      });
    } else {
      this.retrieveCustomerTypes({
        id: ''
      });
    }
  },
  beforeDestroy: function beforeDestroy() {
    // Stop listening
    this.$eventHub.$off('reload-customer-list');
    this.$eventHub.$off('offering-created');
    this.$eventHub.$off('delete-offering');
    this.$eventHub.$off('delete-customer');
  },
  methods: {
    trimString: function trimString(string, length) {
      if (string.length > length) {
        return string.substring(0, length) + '...';
      } else {
        return string;
      }
    },
    closeCustomerCreateDialog: function closeCustomerCreateDialog() {
      if (this.customer_types.length > 0) {
        this.selectedCustomerTypeId = this.customer_types[0].id;
        this.updateCustomerType(this.customer_types[0].id);
      }
      this.createCustomerTypeDialog = false;
    },
    closeOfferingCreateDialog: function closeOfferingCreateDialog() {
      if (this.offerings.length > 0 && this.offeringCreatedFlag === false) {
        this.selectedOfferingId = this.offerings[0].id;
        this.updateOfferingID(this.offerings[0].id);
      }
      this.createOfferingDialog = false;
    },
    deleteOffering: function deleteOffering(offering_id) {
      if (this.selectedOfferingId === offering_id) {
        this.retrieveOfferings(true);
      }
    },
    deleteCustomerType: function deleteCustomerType(customer_type_id) {
      if (this.selectedCustomerTypeId === customer_type_id) {
        this.retrieveCustomerTypes({
          id: ''
        });
      }
    },
    hasPermissionForRoute: function hasPermissionForRoute(route) {
      if (route.name === 'JourneyArchive') {
        // Display only on preview mode
        return this.showMVP;
      } else {
        if (route && route.meta && this.modules) {
          return this.modules.includes(route.meta.module) || route.meta.module === 'Administration';
        } else {
          // return true
        }
      }
      return false;
    },
    createCustomer: function createCustomer(customer) {
      this.customer_types.push(customer);
      this.updateCustomerType(customer.id);
      this.createCustomerTypeDialog = false;
    },
    createOffering: function createOffering(offering) {
      this.offeringCreatedFlag = true;
      this.offerings.push(offering);
      this.updateOfferingID(offering.id);
      this.createOfferingDialog = false;
    },
    reloadCustomerList: function reloadCustomerList(customer) {
      this.selectedCustomerTypeId = customer.id;
      /* var customerType = {
        id: customer.id,
        title: customer.title
      }*/
      // this.$store.dispatch('customerTypes/updateCustomerType', customerType)
      this.retrieveCustomerTypes(customer);
    },
    updateOfferingID: function updateOfferingID(offering_id) {
      if (offering_id === 'create_new_offering') {
        // this.$router.push('/offerings/create')
        this.offeringCreatedFlag = false;
        this.createOfferingDialog = true;
        this.selectedOfferingId = '';
      } else {
        this.$store.dispatch('offerings/updateOfferingId', offering_id);
        if (this.$router.currentRoute.fullPath.includes('portfolio') || this.$router.currentRoute.fullPath.includes('delivery') || this.$router.currentRoute.fullPath.includes('offerings') && !this.$router.currentRoute.fullPath.includes('offerings/create')) {
          // Test this and uncomment if needed
          // this.$router.go()
          this.$eventHub.$emit('offering-changed');
        } else {
          this.$router.push('/offerings/overview');
        }
      }
    },
    updateCustomerType: function updateCustomerType(customer_type_id) {
      var _this = this;
      if (customer_type_id === 'create_new_customer_type') {
        // this.$router.push('/customer-types/create')
        this.createCustomerTypeDialog = true;
        this.selectedCustomerTypeId = '';
      } else {
        var customer = {
          id: customer_type_id,
          title: ''
        };
        for (var i = 0; i < this.customer_types.length; i++) {
          if (this.customer_types[i].id === customer_type_id) {
            customer.title = this.customer_types[i].title;
          }
        }
        this.$store.dispatch('customerTypes/updateCustomerType', customer).then(function (res) {
          if (_this.$router.currentRoute.fullPath.includes('customer-types')) {
            // Test this and uncomment if needed
            // this.$router.go()
            _this.$eventHub.$emit('customer-types-changed');
          } else {
            _this.$router.push('/customer-types/overview');
          }
        }).catch(function (err) {
          console.log(err);
        });
      }
    },
    retrieveCustomerTypes: function retrieveCustomerTypes(customer) {
      var _this2 = this;
      this.loading = true;
      this.customer_types = [];
      this.$apollo.query({
        // Query
        query: window._gql(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)([" query CustomerTypes($page: Page)\n        {\n        CustomerTypes(page: $page)\n        {\n          pages\n          total\n          select{\n            id\n            title\n            description\n          }\n        }\n      }"]))),
        fetchPolicy: 'no-cache',
        variables: {
          'page': {
            'limit': 200,
            'start': 1
          }
        }
      }).then(function (data) {
        _this2.customer_types = data.data.CustomerTypes.select.reverse();
        _this2.$forceUpdate();
        if (_this2.customer_types.length === 0) {
          _this2.selectedCustomerTypeId = '';
          _this2.selectedCustomerTypeTitle = '';
        }
        if (_this2.customer_types.length > 0 && customer.id === '') {
          var customerType = {
            id: _this2.customer_types[0].id,
            title: _this2.customer_types[0].title
          };
          _this2.$store.dispatch('customerTypes/updateCustomerType', customerType);
        }
        if (_this2.customerType && _this2.customerType.id !== '' && customer.id === '') {
          _this2.selectedCustomerTypeId = _this2.customerType.id;
          _this2.selectedCustomerTypeTitle = _this2.customerType.title;
        } else if (customer.id !== '') {
          _this2.selectedCustomerTypeId = customer.id;
          _this2.selectedCustomerTypeTitle = customer.title;
          _this2.$store.dispatch('customerTypes/updateCustomerType', customer);
        }
        // this.offerings_total = data.data.Offerings.total
        _this2.loading = false;
      }).catch(function (error) {
        // Error
        /* this.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        })*/
        console.log(error);
        _this2.loading = false;
      });
    },
    retrieveOfferings: function retrieveOfferings() {
      var _this3 = this;
      var updateStore = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      this.loading = true;
      this.offerings = [];
      this.$apollo.query({
        // Query
        query: window._gql(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)([" query Offerings($page: Page, $where: OfferingsCriteriaExpression)\n        {\n        Offerings(page: $page, where: $where)\n        {\n          pages\n          total\n          select{\n            id\n            title\n            description\n            offeringtype\n            offeringcategory\n            iscloned\n            clonedobjectid\n            linkedCustomerTypes(optional: true) {\n              customerType(optional: true){\n                id\n              }\n            }\n          }\n        }\n      }"]))),
        fetchPolicy: 'no-cache',
        variables: {
          'page': {
            'limit': 200,
            'start': 1
          },
          'where': this.computeSearchQuery()
        }
      }).then(function (data) {
        _this3.offerings = data.data.Offerings.select.reverse();
        if (_this3.offerings.length === 0) {
          _this3.selectedOfferingId = '';
        }
        if (_this3.offeringId && _this3.offerings.length > 0) {
          _this3.selectedOfferingId = _this3.offeringId;
        } else if (_this3.offerings.length > 0) {
          _this3.selectedOfferingId = _this3.offerings[0].id;
        }
        if (updateStore) {
          _this3.$store.dispatch('offerings/updateOfferingId', _this3.selectedOfferingId);
        }
        // this.offerings_total = data.data.Offerings.total
        _this3.loading = false;
      }).catch(function (error) {
        // Error
        /* this.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        })*/
        console.log(error);
        _this3.loading = false;
      });
    },
    computeSearchQuery: function computeSearchQuery() {
      var query = {};
      /* query.offeringcategory = {
        'EQ': 'Primary'
      }*/
      return query;
    },
    computeTitle: function computeTitle(title) {
      if (title === 'Current Home') {
        return this.$store.getters.product.title + ' Home';
      } else {
        return title;
      }
    },
    hasOneShowingChild: function hasOneShowingChild() {
      var _this4 = this;
      var children = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      var parent = arguments.length > 1 ? arguments[1] : undefined;
      var showingChildren = children.filter(function (item) {
        if (item.hidden) {
          return false;
        } else {
          // Temp set(will be used if only has one showing child)
          _this4.onlyOneChild = item;
          return true;
        }
      });

      // When there is only one child router, the child router is displayed by default
      if (showingChildren.length === 1) {
        return true;
      }

      // Show parent if there are no child router to display
      if (showingChildren.length === 0) {
        this.onlyOneChild = (0, _objectSpread2.default)((0, _objectSpread2.default)({}, parent), {}, {
          path: '',
          noShowingChildren: true
        });
        return true;
      }
      return false;
    },
    resolvePath: function resolvePath(routePath) {
      if ((0, _validate.isExternal)(routePath)) {
        return routePath;
      }
      if ((0, _validate.isExternal)(this.basePath)) {
        return this.basePath;
      }
      return _path.default.resolve(this.basePath, routePath);
    }
  }
};