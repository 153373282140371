"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.array.sort");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticStyle: {
      "margin-left": "-10pt"
    }
  }, [_c("el-row", {}, [_c("el-col", {
    staticStyle: {
      "padding-right": "0 !important",
      "border-radius": "5pt",
      border: "0.75pt solid rgba(48,65,86,0.3)"
    },
    attrs: {
      xs: _vm.isSidebarCollapse ? 2 : 6,
      sm: _vm.isSidebarCollapse ? 2 : 6,
      md: _vm.isSidebarCollapse ? 2 : 6,
      lg: _vm.isSidebarCollapse ? 2 : 6,
      xl: _vm.isSidebarCollapse ? 1 : 4
    }
  }, [!_vm.isSidebarCollapse ? _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }]
  }, [_c("div", {
    staticClass: "refine-feature-tab-pane-header"
  }, [_c("el-row", {
    staticStyle: {
      "border-bottom": "2pt solid #304156",
      "padding-left": "10pt",
      "margin-bottom": "0",
      "background-color": "rgba(222,235,246,.5)"
    }
  }, [_c("el-col", {
    staticClass: "text-left",
    staticStyle: {
      height: "34pt"
    },
    attrs: {
      xs: 12,
      sm: 14,
      md: 14,
      lg: 14,
      xl: 15
    }
  }, [_c("div", {
    staticClass: "mt-10",
    staticStyle: {
      "font-variant-caps": "small-caps"
    }
  }, [_c("strong", [_vm._v("Design Tickets")])])]), _vm._v(" "), _c("el-col", {
    staticClass: "pt-5 text-right",
    staticStyle: {
      "margin-bottom": "5pt",
      "margin-right": "8px"
    },
    attrs: {
      xs: 12,
      sm: 14,
      md: 7,
      lg: 8,
      xl: 8
    }
  }, [_c("el-button-group", {
    staticStyle: {
      "max-height": "28pt"
    }
  }, [_c("el-button", {
    staticStyle: {
      top: "1pt",
      "border-radius": "3pt",
      "padding-left": "5pt",
      "padding-right": "5pt"
    },
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.addProductFeatureDialog = true;
      }
    }
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "create"
    }
  })], 1), _vm._v(" "), _c("el-button", {
    staticStyle: {
      "margin-left": "4pt",
      top: "1pt",
      "border-radius": "3pt",
      "padding-left": "5pt",
      "padding-right": "5pt"
    },
    attrs: {
      icon: "el-icon-back",
      size: "mini",
      type: "primary",
      title: "Collapse Sidebar"
    },
    on: {
      click: function click($event) {
        return _vm.collapseSidebar();
      }
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    staticStyle: {
      padding: "5px 5px 1px 5px",
      "background-color": "rgba(0,0,0,.03)"
    }
  }, [_c("el-col", {
    attrs: {
      xs: 12,
      sm: 12,
      md: 16,
      lg: 15,
      xl: 16
    }
  }, [_c("div", {}, [_c("el-input", {
    staticClass: "mr-5",
    staticStyle: {
      width: "100%"
    },
    attrs: {
      type: "text",
      size: "mini",
      placeholder: "Search Tickets",
      "prefix-icon": "el-icon-search"
    },
    model: {
      value: _vm.search_entities.q,
      callback: function callback($$v) {
        _vm.$set(_vm.search_entities, "q", $$v);
      },
      expression: "search_entities.q"
    }
  })], 1)]), _vm._v(" "), _c("el-col", {
    attrs: {
      xs: 12,
      sm: 12,
      md: 8,
      lg: 9,
      xl: 8
    }
  }, [_c("div", {
    staticClass: "ml-5 text-right"
  }, [_c("el-button-group", [_c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Filter",
      placement: "top"
    }
  }, [_c("el-button", {
    staticStyle: {
      "border-radius": "3pt",
      "margin-right": "1pt",
      "margin-bottom": "0px"
    },
    attrs: {
      size: "micro",
      type: "secondary"
    },
    on: {
      click: _vm.showFilterDialog
    }
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "1.2em"
    },
    attrs: {
      "icon-class": "filter"
    }
  })], 1)], 1), _vm._v(" "), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Sort",
      placement: "top"
    }
  }, [_c("el-button", {
    staticStyle: {
      "border-radius": "3pt",
      "margin-bottom": "0px"
    },
    attrs: {
      size: "micro",
      type: "secondary"
    },
    on: {
      click: function click($event) {
        return _vm.toggleSort();
      }
    }
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "1.2em"
    },
    attrs: {
      "icon-class": _vm.search_entities.sort.title === "a-z" ? "sort-alpha-down" : "sort-alpha-up"
    }
  })], 1)], 1)], 1)], 1)])], 1), _vm._v(" "), _c("el-row", {
    staticStyle: {
      "padding-left": "5px",
      "padding-right": "5px",
      "background-color": "rgba(0,0,0,.03)"
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      clearable: "",
      placeholder: "Products",
      size: "mini",
      multiple: ""
    },
    on: {
      "remove-tag": _vm.reloadOfferings,
      "visible-change": _vm.updateProductServiceDropDown
    },
    model: {
      value: _vm.search_entities.filters.products,
      callback: function callback($$v) {
        _vm.$set(_vm.search_entities.filters, "products", $$v);
      },
      expression: "search_entities.filters.products"
    }
  }, _vm._l(_vm.products, function (product) {
    return _c("el-option", {
      key: product.id,
      staticClass: "slim-list",
      attrs: {
        label: product.title,
        value: product.id
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      clearable: "",
      placeholder: "Services",
      size: "mini",
      multiple: ""
    },
    on: {
      "remove-tag": _vm.reloadOfferings,
      "visible-change": _vm.updateProductServiceDropDown
    },
    model: {
      value: _vm.search_entities.filters.services,
      callback: function callback($$v) {
        _vm.$set(_vm.search_entities.filters, "services", $$v);
      },
      expression: "search_entities.filters.services"
    }
  }, _vm._l(_vm.services, function (service) {
    return _c("el-option", {
      key: service.id,
      staticClass: "slim-list",
      attrs: {
        label: service.title,
        value: service.id
      }
    });
  }), 1)], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "flex flex-wrap",
    staticStyle: {
      padding: "1pt 1pt 5pt 5pt",
      display: "inline-block",
      "background-color": "rgba(0,0,0,.03)",
      width: "100%"
    }
  }, [_vm.search_entities.filters.title !== "" ? _c("el-tag", {
    attrs: {
      slot: "reference",
      size: "mini",
      closable: ""
    },
    on: {
      close: function close($event) {
        return _vm.removeSearchFilterTag("title");
      }
    },
    slot: "reference"
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "1.2em"
    },
    attrs: {
      "icon-class": "filter-left"
    }
  }), _vm._v("Name:" + _vm._s(_vm.search_entities.filters.title_operator) + " " + _vm._s(_vm.search_entities.filters.title) + "\n            ")], 1) : _vm._e(), _vm._v(" "), _vm.search_entities.filters.description !== "" ? _c("el-tag", {
    attrs: {
      size: "mini",
      closable: ""
    },
    on: {
      close: function close($event) {
        return _vm.removeSearchFilterTag("description");
      }
    }
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "1.2em"
    },
    attrs: {
      "icon-class": "filter-left"
    }
  }), _vm._v("Description:" + _vm._s(_vm.search_entities.filters.description_operator) + " " + _vm._s(_vm.search_entities.filters.description))], 1) : _vm._e(), _vm._v(" "), _vm._l(_vm.search_entities.filters.status, function (status, index) {
    return _c("el-tag", {
      key: index,
      attrs: {
        size: "mini",
        closable: ""
      },
      on: {
        close: function close($event) {
          return _vm.removeSearchFilterTag("status", index);
        }
      }
    }, [_c("svg-icon", {
      staticStyle: {
        "font-size": "1.2em"
      },
      attrs: {
        "icon-class": "filter-left"
      }
    }), _vm._v("Status:\n              "), status === "Proposed" ? _c("svg-icon", {
      staticStyle: {
        color: "rgba(8,16,8,0.27)",
        "font-size": "1.0em",
        border: "solid 0.5pt lightgrey"
      },
      attrs: {
        "icon-class": "hand-holding-solid"
      }
    }) : _vm._e(), _vm._v(" "), status === "Accepted" ? _c("svg-icon", {
      staticStyle: {
        color: "rgba(8,16,8,0.27)",
        width: "16pt",
        "padding-bottom": "1pt",
        "border-radius": "10pt",
        "background-color": "cornflowerblue",
        "font-size": "1.0em"
      },
      attrs: {
        "icon-class": "thumbs-up"
      }
    }) : _vm._e(), _vm._v(" "), status === "In progress" ? _c("svg-icon", {
      staticStyle: {
        color: "white",
        "background-color": "#2c64b5",
        "border-radius": "10pt",
        width: "16pt",
        padding: "1pt",
        "font-size": "1.0em"
      },
      attrs: {
        "icon-class": "delivery"
      }
    }) : _vm._e(), _vm._v(" "), status === "On hold" ? _c("svg-icon", {
      staticStyle: {
        color: "#f09a0d",
        "font-size": "1.0em"
      },
      attrs: {
        "icon-class": "pause-circle-fill"
      }
    }) : _vm._e(), _vm._v(" "), status === "Overdue" ? _c("svg-icon", {
      staticStyle: {
        color: "#eb4a49",
        "font-size": "1.0em"
      },
      attrs: {
        "icon-class": "exclamation-circle-fill"
      }
    }) : _vm._e(), _vm._v(" "), status === "Achieved" ? _c("svg-icon", {
      staticStyle: {
        color: "#92b853",
        "font-size": "1.0em"
      },
      attrs: {
        "icon-class": "check-circle-fill"
      }
    }) : _vm._e(), _vm._v("\n              " + _vm._s(status) + "\n            ")], 1);
  }), _vm._v(" "), _vm._l(_vm.search_entities.filters.priority, function (priority, index) {
    return _c("el-tag", {
      key: index,
      attrs: {
        size: "mini",
        closable: ""
      },
      on: {
        close: function close($event) {
          return _vm.removeSearchFilterTag("priority", index);
        }
      }
    }, [_c("svg-icon", {
      staticStyle: {
        "font-size": "1.2em"
      },
      attrs: {
        "icon-class": "filter-left"
      }
    }), _vm._v("Priority:" + _vm._s(priority) + "\n            ")], 1);
  }), _vm._v(" "), _vm._l(_vm.search_entities.filters.products, function (product, index) {
    return _c("el-tag", {
      key: index,
      attrs: {
        size: "mini",
        closable: ""
      },
      on: {
        close: function close($event) {
          return _vm.removeSearchFilterTag("products", index);
        }
      }
    }, [_c("svg-icon", {
      staticStyle: {
        "font-size": "1.2em",
        "margin-right": "5px"
      },
      attrs: {
        "icon-class": "pdg-product"
      }
    }), _vm._v(_vm._s(_vm.getProductTitleFromId(product)) + "\n            ")], 1);
  }), _vm._v(" "), _vm._l(_vm.search_entities.filters.services, function (service, index) {
    return _c("el-tag", {
      key: index,
      attrs: {
        size: "mini",
        closable: ""
      },
      on: {
        close: function close($event) {
          return _vm.removeSearchFilterTag("services", index);
        }
      }
    }, [_c("svg-icon", {
      staticStyle: {
        "font-size": "1.2em",
        "margin-right": "5px"
      },
      attrs: {
        "icon-class": "pdg-service"
      }
    }), _vm._v(_vm._s(_vm.getServiceTitleFromId(service)) + "\n            ")], 1);
  }), _vm._v(" "), _vm.search_entities.sort.title !== "" ? _c("el-tag", {
    attrs: {
      type: "info",
      size: "mini",
      closable: ""
    },
    on: {
      close: function close($event) {
        return _vm.removeSearchSortTag("title");
      }
    }
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "1.2em"
    },
    attrs: {
      "icon-class": _vm.search_entities.sort.title === "a-z" ? "sort-alpha-down" : "sort-alpha-up"
    }
  }), _vm._v("\n              Name:" + _vm._s(_vm.search_entities.sort.title) + "\n            ")], 1) : _vm._e()], 2)], 1), _vm._v(" "), _vm.filteredEntities.length > 0 ? _c("div", {
    staticClass: "mt-5"
  }, _vm._l(_vm.filteredEntities, function (entity, index) {
    return _c("div", {
      key: index,
      class: _vm.selectedEntity && _vm.selectedEntity.id === entity.id ? "list_item_feature is-item_feature-active" : "list_item_feature",
      on: {
        click: function click($event) {
          return _vm.selectEntity(entity, index);
        }
      }
    }, [_c("table", {
      staticStyle: {
        width: "100%",
        "font-variant-caps": "small-caps"
      }
    }, [_c("tr", [_c("td", [_c("div", {
      staticStyle: {
        "background-color": "white",
        "border-radius": "15pt",
        "margin-right": "4pt",
        "margin-left": "-2pt",
        display: "inline-block",
        border: "solid 0.5pt #2793b7"
      }
    }, [entity.__typename === "ProductFeature" ? _c("svg-icon", {
      staticStyle: {
        "font-size": "18pt"
      },
      attrs: {
        "icon-class": "feature"
      }
    }) : _vm._e(), _vm._v(" "), entity.__typename === "ServiceApplication" ? _c("svg-icon", {
      staticStyle: {
        "font-size": "18pt"
      },
      attrs: {
        "icon-class": "process-auto"
      }
    }) : _vm._e()], 1), _vm._v(" "), _c("span", {
      staticStyle: {
        "font-size": "1.25em"
      }
    }, [_c("strong", [_vm._v(_vm._s(entity.title))])])]), _vm._v(" "), _c("td", {
      staticClass: "text-right"
    }, [_c("svg-icon", {
      staticStyle: {
        color: "#92b853",
        "font-size": "1.5em"
      },
      attrs: {
        "icon-class": _vm.computeStatusIcon(entity.status)
      }
    })], 1)])]), _vm._v(" "), _c("table", {
      staticStyle: {
        width: "100%",
        "padding-left": "24pt"
      }
    }, [_c("tr", [_c("div", {
      staticStyle: {
        "text-align": "justify",
        "text-justify": "inter-word",
        "font-size": "12px"
      }
    }, [_c("i", [_vm._v(_vm._s(_vm.truncateDescription(entity.description, 40)))])])])]), _vm._v(" "), _c("table", {
      staticStyle: {
        width: "100%",
        "font-variant-caps": "small-caps",
        "padding-left": "22pt"
      }
    }, [_c("tr", [_c("td", [entity.status ? _c("el-tag", {
      staticStyle: {
        width: "80px",
        "text-align": "center"
      },
      attrs: {
        size: "small",
        type: "secondary",
        effect: "plain"
      }
    }, [_c("strong", [_vm._v(_vm._s(entity.status))])]) : _vm._e()], 1), _vm._v(" "), _c("td", {
      staticClass: "text-right"
    }), _vm._v(" "), _c("td", {
      staticClass: "text-right"
    }, [entity.priority === "Highest" ? _c("el-tag", {
      staticStyle: {
        "background-color": "#AD0E30",
        color: "white"
      },
      attrs: {
        size: "small",
        placeholder: "Select"
      },
      model: {
        value: entity.priority,
        callback: function callback($$v) {
          _vm.$set(entity, "priority", $$v);
        },
        expression: "entity.priority"
      }
    }, [_vm._v(_vm._s(entity.priority))]) : _vm._e(), _vm._v(" "), entity.priority === "High" ? _c("el-tag", {
      staticStyle: {
        "background-color": "#6A0DAD",
        color: "white"
      },
      attrs: {
        size: "small",
        placeholder: "Select"
      },
      model: {
        value: entity.priority,
        callback: function callback($$v) {
          _vm.$set(entity, "priority", $$v);
        },
        expression: "entity.priority"
      }
    }, [_vm._v(_vm._s(entity.priority))]) : _vm._e(), _vm._v(" "), entity.priority === "Medium" ? _c("el-tag", {
      staticStyle: {
        "background-color": "#067AED",
        color: "white"
      },
      attrs: {
        size: "small",
        placeholder: "Select"
      },
      model: {
        value: entity.priority,
        callback: function callback($$v) {
          _vm.$set(entity, "priority", $$v);
        },
        expression: "entity.priority"
      }
    }, [_vm._v(_vm._s(entity.priority))]) : _vm._e(), _vm._v(" "), entity.priority === "Low" ? _c("el-tag", {
      staticStyle: {
        "background-color": "lightslategrey",
        color: "white"
      },
      attrs: {
        size: "small",
        placeholder: "Select"
      },
      model: {
        value: entity.priority,
        callback: function callback($$v) {
          _vm.$set(entity, "priority", $$v);
        },
        expression: "entity.priority"
      }
    }, [_vm._v(_vm._s(entity.priority))]) : _vm._e(), _vm._v(" "), entity.priority === "Lowest" ? _c("el-tag", {
      attrs: {
        size: "small",
        placeholder: "Select"
      },
      model: {
        value: entity.priority,
        callback: function callback($$v) {
          _vm.$set(entity, "priority", $$v);
        },
        expression: "entity.priority"
      }
    }, [_vm._v(_vm._s(entity.priority))]) : _vm._e()], 1)])])]);
  }), 0) : _c("el-empty", [_c("p", {
    attrs: {
      slot: "description"
    },
    slot: "description"
  }, [_vm._v("No Data")])]), _vm._v(" "), _c("div", {
    staticClass: "text-center",
    staticStyle: {
      "background-color": "rgba(255,255,255,1)"
    }
  }, [_c("el-pagination", {
    attrs: {
      layout: "prev, pager, next",
      small: true,
      "hide-on-single-page": true,
      total: _vm.total_features_count + _vm.total_service_applications_count
    },
    on: {
      "current-change": _vm.loadPage,
      "prev-click": _vm.loadPage,
      "next-click": _vm.loadPage
    }
  })], 1)], 1) : _c("div", [_c("el-card", {
    staticStyle: {
      height: "100%",
      "background-color": "rgba(222,235,246,.5)"
    }
  }, [_c("el-row", [_c("div", {
    staticClass: "text-center"
  }, [_c("div", {
    staticClass: "title-caps"
  }, [_c("strong", [_vm._v("Show")])]), _vm._v(" "), _c("div", {
    staticClass: "title-caps"
  }, [_c("strong", [_vm._v("Features")])]), _vm._v(" "), _c("el-button", {
    staticClass: "mt-10",
    attrs: {
      type: "primary",
      icon: "el-icon-right",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.expandSidebar();
      }
    }
  })], 1)])], 1)], 1)]), _vm._v(" "), _c("el-col", {
    attrs: {
      xs: _vm.isSidebarCollapse ? 22 : 18,
      sm: _vm.isSidebarCollapse ? 22 : 18,
      md: _vm.isSidebarCollapse ? 22 : 18,
      lg: _vm.isSidebarCollapse ? 22 : 18,
      xl: _vm.isSidebarCollapse ? 23 : 20
    }
  }, [_c("transition", {
    attrs: {
      name: "fade"
    }
  }, [!_vm.loading ? [_vm.ticket.title ? _c("div", [_c("el-card", {
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      id: "refine_feature_details_container"
    }
  }, [_c("div", {
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("div", [_c("strong", [_vm._v(_vm._s(_vm.ticket.title) + " Details")])])]), _vm._v(" "), _c("el-col", {
    staticClass: "text-right",
    attrs: {
      span: 12
    }
  })], 1)], 1), _vm._v(" "), _c("el-tabs", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.tab_loading,
      expression: "tab_loading"
    }],
    staticClass: "ml-10"
  }, [_c("el-tab-pane", {
    attrs: {
      label: "Details"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    staticClass: "mt-10 text-left ml-10 mr-10",
    attrs: {
      xs: 12,
      sm: 12,
      md: 12,
      lg: 13,
      xl: 13
    }
  }, [_c("table", {
    staticStyle: {
      width: "100%"
    }
  }, [_c("colgroup", [_c("col", {
    staticStyle: {
      width: "25%"
    },
    attrs: {
      span: "1"
    }
  })]), _vm._v(" "), _vm.selectedEntity.__typename === "ProductFeature" && _vm.selectedEntity.linkedProducts.length > 0 ? _c("tr", [_c("th", [_vm._v("Product")]), _vm._v(" "), _c("td", [_c("el-select", {
    attrs: {
      placeholder: "Select Product",
      size: "mini"
    },
    on: {
      change: _vm.handleProductListChange
    },
    model: {
      value: _vm.selectedEntity.linkedProducts[0].product.id,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedEntity.linkedProducts[0].product, "id", $$v);
      },
      expression: "selectedEntity.linkedProducts[0].product.id"
    }
  }, _vm._l(_vm.products, function (product) {
    return _c("el-option", {
      key: product.id,
      staticClass: "slim-list",
      attrs: {
        label: product.title,
        value: product.id
      }
    });
  }), 1)], 1)]) : _vm._e(), _vm._v(" "), _vm.selectedEntity.__typename === "ServiceApplication" && _vm.selectedEntity.linkedServices.length > 0 ? _c("tr", [_c("th", [_vm._v("Service")]), _vm._v(" "), _c("td", [_c("el-select", {
    attrs: {
      placeholder: "Select Service",
      size: "mini"
    },
    on: {
      change: _vm.handleServiceListChange
    },
    model: {
      value: _vm.selectedEntity.linkedServices[0].service.id,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedEntity.linkedServices[0].service, "id", $$v);
      },
      expression: "selectedEntity.linkedServices[0].service.id"
    }
  }, _vm._l(_vm.services, function (service) {
    return _c("el-option", {
      key: service.id,
      staticClass: "slim-list",
      attrs: {
        label: service.title,
        value: service.id
      }
    });
  }), 1)], 1)]) : _vm._e(), _vm._v(" "), _c("tr", [_c("th", [_vm._v("Name")]), _vm._v(" "), _c("td", [_c("el-input", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.ticket.title,
      callback: function callback($$v) {
        _vm.$set(_vm.ticket, "title", $$v);
      },
      expression: "ticket.title"
    }
  })], 1)]), _vm._v(" "), _c("tr", [_c("th", [_vm._v("Ticket ID")]), _vm._v(" "), _c("td", [_c("el-input", {
    attrs: {
      size: "mini",
      disabled: ""
    },
    model: {
      value: _vm.ticket.slug,
      callback: function callback($$v) {
        _vm.$set(_vm.ticket, "slug", $$v);
      },
      expression: "ticket.slug"
    }
  })], 1)])]), _vm._v(" "), _c("el-divider"), _vm._v(" "), _c("H4", [_vm._v("Detailed Description")]), _vm._v(" "), _c("tinymce-component", {
    staticClass: "mt-5",
    attrs: {
      value: _vm.ticket.description,
      height: 400,
      upload: false,
      menubar: "",
      toolbar: ["bold italic underline | alignleft aligncenter alignright | bullist numlist | outdent indent"]
    },
    on: {
      update: _vm.updateTicketDescription
    }
  })], 1), _vm._v(" "), _c("el-col", {
    staticClass: "mt-10",
    attrs: {
      "xs:": 10,
      sm: 10,
      md: 10,
      lg: 9,
      xl: 8,
      offset: 1
    }
  }, [_c("table", {
    staticClass: "text-left"
  }, [_c("tr", [_c("th", [_vm._v("Enabler")]), _vm._v(" "), _c("td", [_c("el-checkbox", {
    on: {
      change: _vm.updateEnabledStatus
    },
    model: {
      value: _vm.selectedEntity.enabler,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedEntity, "enabler", $$v);
      },
      expression: "selectedEntity.enabler"
    }
  })], 1)]), _vm._v(" "), _c("tr", [_c("th", [_vm._v("Status")]), _vm._v(" "), _c("td", [_c("el-select", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.ticket.status,
      callback: function callback($$v) {
        _vm.$set(_vm.ticket, "status", $$v);
      },
      expression: "ticket.status"
    }
  }, _vm._l(_vm.statuses, function (status) {
    return _c("el-option", {
      key: status.value,
      staticClass: "slim-list",
      attrs: {
        label: status.label,
        value: status.value,
        width: "60%"
      }
    });
  }), 1)], 1)]), _vm._v(" "), _c("tr", [_c("th", [_vm._v("User Priority")]), _vm._v(" "), _c("td", [_c("el-select", {
    attrs: {
      placeholder: "Select Priority",
      size: "mini"
    },
    model: {
      value: _vm.ticket.priority,
      callback: function callback($$v) {
        _vm.$set(_vm.ticket, "priority", $$v);
      },
      expression: "ticket.priority"
    }
  }, _vm._l(_vm.priorities, function (priority) {
    return _c("el-option", {
      key: priority.value,
      staticClass: "slim-list",
      attrs: {
        label: priority.label,
        value: priority.value
      }
    });
  }), 1)], 1)]), _vm._v(" "), _c("tr", [_c("th", [_vm._v("Value")]), _vm._v(" "), _c("td", [_c("el-select", {
    attrs: {
      placeholder: "Select Value",
      size: "mini"
    },
    model: {
      value: _vm.ticket.importance,
      callback: function callback($$v) {
        _vm.$set(_vm.ticket, "importance", $$v);
      },
      expression: "ticket.importance"
    }
  }, [_c("el-option", {
    staticClass: "slim-list",
    staticStyle: {
      "font-size": "9pt"
    },
    attrs: {
      label: "Lowest",
      value: "Lowest"
    }
  }), _vm._v(" "), _c("el-option", {
    staticClass: "slim-list",
    staticStyle: {
      "font-size": "9pt"
    },
    attrs: {
      label: "Low",
      value: "Low"
    }
  }), _vm._v(" "), _c("el-option", {
    staticClass: "slim-list",
    staticStyle: {
      "font-size": "9pt"
    },
    attrs: {
      label: "Medium",
      value: "Medium"
    }
  }), _vm._v(" "), _c("el-option", {
    staticClass: "slim-list",
    staticStyle: {
      "font-size": "9pt"
    },
    attrs: {
      label: "High",
      value: "High"
    }
  }), _vm._v(" "), _c("el-option", {
    staticClass: "slim-list",
    staticStyle: {
      "font-size": "9pt"
    },
    attrs: {
      label: "Highest",
      value: "Highest"
    }
  })], 1)], 1)]), _vm._v(" "), _c("tr", [_c("th", [_vm._v("Smart Priority")]), _vm._v(" "), _c("td", [_c("el-input", {
    staticClass: "slim-list",
    attrs: {
      disabled: true,
      size: "mini",
      width: "80%"
    },
    model: {
      value: _vm.ticket.priority,
      callback: function callback($$v) {
        _vm.$set(_vm.ticket, "priority", $$v);
      },
      expression: "ticket.priority"
    }
  })], 1)]), _vm._v(" "), _c("tr", [_c("th", [_vm._v("Cost Estimate")]), _vm._v(" "), _c("td", [_c("el-input", {
    attrs: {
      value: "",
      size: "mini"
    }
  })], 1)]), _vm._v(" "), _c("tr", [_c("th", [_vm._v("Approved Via")]), _vm._v(" "), _c("td", [_c("el-input", {
    attrs: {
      size: "mini",
      disabled: ""
    },
    model: {
      value: _vm.ticket.approvedVia,
      callback: function callback($$v) {
        _vm.$set(_vm.ticket, "approvedVia", $$v);
      },
      expression: "ticket.approvedVia"
    }
  })], 1)])]), _vm._v(" "), _c("el-divider"), _vm._v(" "), _c("table", {
    staticClass: "text-left"
  }, [_c("tr", [_c("th", [_vm._v("Created")]), _vm._v(" "), _c("td", [_vm._v(_vm._s(_vm.ticket.createdBy) + " @ " + _vm._s(_vm.computeHumanFriendlyDate(_vm.ticket.createdAt)))])]), _vm._v(" "), _c("tr", {
    staticStyle: {
      "margin-top": "20pt"
    }
  }, [_c("th", [_vm._v("Last Modified")]), _vm._v(" "), _c("td", [_vm._v(_vm._s(_vm.ticket.modifiedBy) + " @ " + _vm._s(_vm.computeHumanFriendlyDate(_vm.ticket.modifiedAt)))])])]), _vm._v(" "), _c("el-divider"), _vm._v(" "), _c("h4", [_vm._v("Attachments")]), _vm._v(" "), _c("div", {
    staticClass: "mt-10",
    staticStyle: {
      border: "solid 1pt lightgrey",
      "border-radius": "5pt",
      "margin-bottom": "20pt"
    }
  }, [_c("file-upload-component", {
    attrs: {
      "is-edit": true,
      "file-list": _vm.files,
      "entity-id": _vm.ticket.id,
      "entity-name": "TICKET",
      "display-type": "table"
    },
    on: {
      "file-uploaded": _vm.retrieveTicketAttachements
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "text-right mr-10"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.actionDiscussionDrawer = true;
      }
    }
  }, [_vm._v("Actions/Discussions")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.updateTicket();
      }
    }
  }, [_vm._v("Update")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "danger",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.deleteTicket();
      }
    }
  }, [_vm._v("Delete")])], 1)], 1), _vm._v(" "), _vm.selectedEntity.__typename === "ServiceApplication" ? _c("el-tab-pane", {
    attrs: {
      label: "BPMN",
      name: "bpmn"
    }
  }, [_c("div", [_c("b-p-m-n-component", {
    attrs: {
      "entity-id": _vm.ticket.id,
      "entity-name": "TICKET"
    },
    on: {
      "file-uploaded": _vm.retrieveTicketAttachements
    }
  })], 1)]) : _vm._e(), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: "Stories & Tasks"
    }
  }, [_c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.child_ticket_loading,
      expression: "child_ticket_loading"
    }]
  }, [_c("div", {
    staticClass: "ml-5 mt-5"
  }, [_c("el-button", {
    staticClass: "fr mr-5",
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.addChildTicket
    }
  }, [_vm._v("Add ")])], 1), _vm._v(" "), _c("div", {
    staticClass: "ml-5 mr-5"
  }, [_c("el-table", {
    attrs: {
      data: _vm.child_tickets,
      size: "mini",
      stripe: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Title"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-input", {
          attrs: {
            type: "text",
            size: "mini"
          },
          model: {
            value: scope.row.title,
            callback: function callback($$v) {
              _vm.$set(scope.row, "title", $$v);
            },
            expression: "scope.row.title"
          }
        })];
      }
    }], null, false, 644953238)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Status",
      width: "140"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-select", {
          attrs: {
            placeholder: "Select Status",
            size: "mini"
          },
          model: {
            value: scope.row.status,
            callback: function callback($$v) {
              _vm.$set(scope.row, "status", $$v);
            },
            expression: "scope.row.status"
          }
        }, _vm._l(_vm.statuses, function (status) {
          return _c("el-option", {
            key: status.value,
            staticClass: "slim-list",
            attrs: {
              label: status.label,
              value: status.value,
              width: "60%"
            }
          });
        }), 1)];
      }
    }], null, false, 1963372486)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Priority",
      width: "100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-select", {
          attrs: {
            placeholder: "Select Priority",
            size: "mini"
          },
          model: {
            value: scope.row.priority,
            callback: function callback($$v) {
              _vm.$set(scope.row, "priority", $$v);
            },
            expression: "scope.row.priority"
          }
        }, _vm._l(_vm.priorities, function (priority) {
          return _c("el-option", {
            key: priority.value,
            staticClass: "slim-list",
            attrs: {
              label: priority.label,
              value: priority.value
            }
          });
        }), 1)];
      }
    }], null, false, 696847523)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "#",
      width: "135"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-button", {
          staticClass: "slim-button",
          attrs: {
            type: "primary",
            icon: "far fa-save"
          },
          on: {
            click: function click($event) {
              return _vm.addUpdateChildTicket(scope.row);
            }
          }
        }), _vm._v(" "), _c("el-button", {
          staticClass: "slim-button",
          attrs: {
            type: "warning",
            icon: "fas fa-minus-circle",
            title: "Unlink"
          },
          on: {
            click: function click($event) {
              return _vm.unlinkChildTicketFromParent(scope.row.id, _vm.ticket.id);
            }
          }
        }), _vm._v(" "), _c("el-button", {
          staticClass: "slim-button",
          attrs: {
            type: "danger",
            icon: "el-icon-delete",
            title: "Delete"
          },
          on: {
            click: function click($event) {
              return _vm.deleteChildTicket(scope.row.id, _vm.ticket.id);
            }
          }
        })];
      }
    }], null, false, 1274889458)
  })], 1)], 1)])]), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: "Related Tickets"
    }
  }, [_c("el-tabs", {
    attrs: {
      "tab-position": "left"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "Linked tickets"
    }
  }, [_c("div", [_c("el-table", {
    attrs: {
      data: _vm.computedRelatedTicketsDesign,
      stripe: "",
      size: "micro"
    },
    on: {
      "current-change": _vm.handleRelatedTicketSelection
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Title",
      prop: "title"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Description",
      prop: "description"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Status",
      prop: "status",
      width: "80"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Priority",
      prop: "priority",
      width: "80"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "#",
      width: "175"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("i", {
          staticClass: "el-icon-link",
          on: {
            click: function click($event) {
              return _vm.deleteRelation.stop.prevent(scope.row.__typename, scope.row.id, _vm.ticket.id);
            }
          }
        })];
      }
    }], null, false, 903243223)
  })], 1)], 1)]), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: "External tickets"
    }
  }, [_vm._v("TODO")])], 1)], 1)], 1)], 1)], 1) : _vm._e()] : _vm._e()], 2)], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Add Feature or Process",
      visible: _vm.addProductFeatureDialog,
      width: "60%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addProductFeatureDialog = $event;
      }
    }
  }, [_c("el-form", {
    ref: "addFeatureform",
    attrs: {
      model: _vm.feature_form,
      "label-width": "200px",
      rules: _vm.addFeatureRules
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Product or Service",
      prop: "title"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.feature_form.parent_type,
      callback: function callback($$v) {
        _vm.$set(_vm.feature_form, "parent_type", $$v);
      },
      expression: "feature_form.parent_type"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: "Product"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Service"
    }
  })], 1)], 1), _vm._v(" "), _vm.feature_form.parent_type === "Product" ? _c("el-form-item", {
    attrs: {
      label: "Product",
      prop: "product"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Select Product",
      size: "mini"
    },
    model: {
      value: _vm.feature_form.productId,
      callback: function callback($$v) {
        _vm.$set(_vm.feature_form, "productId", $$v);
      },
      expression: "feature_form.productId"
    }
  }, _vm._l(_vm.products, function (product) {
    return _c("el-option", {
      key: product.id,
      staticClass: "slim-list",
      attrs: {
        label: product.title,
        value: product.id
      }
    });
  }), 1)], 1) : _vm._e(), _vm._v(" "), _vm.feature_form.parent_type === "Service" ? _c("el-form-item", {
    attrs: {
      label: "Service",
      prop: "service"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Select Service",
      size: "mini"
    },
    model: {
      value: _vm.feature_form.serviceId,
      callback: function callback($$v) {
        _vm.$set(_vm.feature_form, "serviceId", $$v);
      },
      expression: "feature_form.serviceId"
    }
  }, _vm._l(_vm.services, function (service) {
    return _c("el-option", {
      key: service.id,
      staticClass: "slim-list",
      attrs: {
        label: service.title,
        value: service.id
      }
    });
  }), 1)], 1) : _vm._e(), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Name",
      prop: "title"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.feature_form.title,
      callback: function callback($$v) {
        _vm.$set(_vm.feature_form, "title", $$v);
      },
      expression: "feature_form.title"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Description",
      prop: "description"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      size: "mini"
    },
    model: {
      value: _vm.feature_form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.feature_form, "description", $$v);
      },
      expression: "feature_form.description"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Enabler",
      prop: "enabler"
    }
  }, [_c("el-checkbox", {
    model: {
      value: _vm.feature_form.enabler,
      callback: function callback($$v) {
        _vm.$set(_vm.feature_form, "enabler", $$v);
      },
      expression: "feature_form.enabler"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Status",
      prop: "status"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Select Status",
      size: "mini"
    },
    model: {
      value: _vm.feature_form.status,
      callback: function callback($$v) {
        _vm.$set(_vm.feature_form, "status", $$v);
      },
      expression: "feature_form.status"
    }
  }, _vm._l(_vm.statuses, function (status) {
    return _c("el-option", {
      key: status.value,
      attrs: {
        label: status.label,
        value: status.value
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Priority",
      prop: "priority"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.feature_form.priority,
      callback: function callback($$v) {
        _vm.$set(_vm.feature_form, "priority", $$v);
      },
      expression: "feature_form.priority"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: "Lowest"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Low"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Medium"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "High"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Highest"
    }
  })], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Value",
      prop: "importance"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Select Value",
      size: "mini"
    },
    model: {
      value: _vm.feature_form.importance,
      callback: function callback($$v) {
        _vm.$set(_vm.feature_form, "importance", $$v);
      },
      expression: "feature_form.importance"
    }
  }, [_c("el-option", {
    staticClass: "slim-list",
    staticStyle: {
      "font-size": "9pt"
    },
    attrs: {
      label: "Lowest",
      value: "Lowest"
    }
  }), _vm._v(" "), _c("el-option", {
    staticClass: "slim-list",
    staticStyle: {
      "font-size": "9pt"
    },
    attrs: {
      label: "Low",
      value: "Low"
    }
  }), _vm._v(" "), _c("el-option", {
    staticClass: "slim-list",
    staticStyle: {
      "font-size": "9pt"
    },
    attrs: {
      label: "Medium",
      value: "Medium"
    }
  }), _vm._v(" "), _c("el-option", {
    staticClass: "slim-list",
    staticStyle: {
      "font-size": "9pt"
    },
    attrs: {
      label: "High",
      value: "High"
    }
  }), _vm._v(" "), _c("el-option", {
    staticClass: "slim-list",
    staticStyle: {
      "font-size": "9pt"
    },
    attrs: {
      label: "Highest",
      value: "Highest"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.addSubEntity
    }
  }, [_vm._v("Add " + _vm._s(_vm.feature_form.parent_type === "Product" ? "Feature" : "Process"))]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: _vm.closeProductFeatureDialog
    }
  }, [_vm._v("Cancel")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Filter List",
      visible: _vm.filterDialog,
      width: "60%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.filterDialog = $event;
      }
    }
  }, [_c("table", {
    staticStyle: {
      "table-layout": "fixed",
      width: "100%"
    }
  }, [_c("colgroup", [_c("col", {
    staticStyle: {
      width: "20%"
    },
    attrs: {
      span: "1"
    }
  }), _vm._v(" "), _c("col", {
    staticStyle: {
      width: "80%"
    },
    attrs: {
      span: "1"
    }
  })]), _vm._v(" "), _c("tr", [_c("th", {
    staticClass: "text-left"
  }, [_vm._v("Name")]), _vm._v(" "), _c("td", {
    staticClass: "flex"
  }, [_c("el-select", {
    staticStyle: {
      width: "150px"
    },
    attrs: {
      clearable: "",
      placeholder: "Select",
      size: "mini"
    },
    model: {
      value: _vm.search_entities.filters.title_operator,
      callback: function callback($$v) {
        _vm.$set(_vm.search_entities.filters, "title_operator", $$v);
      },
      expression: "search_entities.filters.title_operator"
    }
  }, [_c("el-option", {
    attrs: {
      label: "EXACT",
      value: "EXACT"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "LIKE",
      value: "LIKE"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "NOT LIKE",
      value: "NOT LIKE"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "EMPTY",
      value: "EMPTY"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "NOT EMPTY",
      value: "NOT EMPTY"
    }
  })], 1), _vm._v(" "), _c("el-input", {
    staticClass: "ml-5",
    attrs: {
      type: "text",
      size: "mini"
    },
    model: {
      value: _vm.search_entities.filters.title,
      callback: function callback($$v) {
        _vm.$set(_vm.search_entities.filters, "title", $$v);
      },
      expression: "search_entities.filters.title"
    }
  })], 1)]), _vm._v(" "), _c("tr", [_c("th", {
    staticClass: "text-left"
  }, [_vm._v("Description")]), _vm._v(" "), _c("td", {
    staticClass: "flex"
  }, [_c("el-select", {
    staticStyle: {
      width: "150px"
    },
    attrs: {
      clearable: "",
      placeholder: "Select",
      size: "mini"
    },
    model: {
      value: _vm.search_entities.filters.description_operator,
      callback: function callback($$v) {
        _vm.$set(_vm.search_entities.filters, "description_operator", $$v);
      },
      expression: "search_entities.filters.description_operator"
    }
  }, [_c("el-option", {
    attrs: {
      label: "EXACT",
      value: "EXACT"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "LIKE",
      value: "LIKE"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "NOT LIKE",
      value: "NOT LIKE"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "EMPTY",
      value: "EMPTY"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "NOT EMPTY",
      value: "NOT EMPTY"
    }
  })], 1), _vm._v(" "), _c("el-input", {
    staticClass: "ml-5",
    attrs: {
      type: "text",
      size: "mini"
    },
    model: {
      value: _vm.search_entities.filters.description,
      callback: function callback($$v) {
        _vm.$set(_vm.search_entities.filters, "description", $$v);
      },
      expression: "search_entities.filters.description"
    }
  })], 1)]), _vm._v(" "), _c("tr", [_c("th", {
    staticClass: "text-left"
  }, [_vm._v("Status")]), _vm._v(" "), _c("td", [_c("el-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      clearable: "",
      placeholder: "Select",
      size: "mini",
      multiple: ""
    },
    model: {
      value: _vm.search_entities.filters.status,
      callback: function callback($$v) {
        _vm.$set(_vm.search_entities.filters, "status", $$v);
      },
      expression: "search_entities.filters.status"
    }
  }, [_c("el-option", {
    attrs: {
      label: "Candidate",
      value: "Candidate"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Approved",
      value: "Approved"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Backlog",
      value: "Backlog"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "In Progress",
      value: "In Progress"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Finalised",
      value: "Finalised"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Released",
      value: "Released"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Improvement",
      value: "Improvement"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Retired",
      value: "Retired"
    }
  })], 1)], 1)]), _vm._v(" "), _c("tr", [_c("th", {
    staticClass: "text-left"
  }, [_vm._v("Priority")]), _vm._v(" "), _c("td", [_c("el-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      clearable: "",
      placeholder: "Select",
      size: "mini",
      multiple: ""
    },
    model: {
      value: _vm.search_entities.filters.priority,
      callback: function callback($$v) {
        _vm.$set(_vm.search_entities.filters, "priority", $$v);
      },
      expression: "search_entities.filters.priority"
    }
  }, [_c("el-option", {
    attrs: {
      label: "Highest",
      value: "Highest"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "High",
      value: "High"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Medium",
      value: "Medium"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Low",
      value: "Low"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Lowest",
      value: "Lowest"
    }
  })], 1)], 1)]), _vm._v(" "), _c("tr", [_c("th", {
    staticClass: "text-left"
  }, [_vm._v("Products")]), _vm._v(" "), _c("td", [_c("el-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      clearable: "",
      placeholder: "Select",
      size: "mini",
      multiple: ""
    },
    model: {
      value: _vm.search_entities.filters.products,
      callback: function callback($$v) {
        _vm.$set(_vm.search_entities.filters, "products", $$v);
      },
      expression: "search_entities.filters.products"
    }
  }, _vm._l(_vm.products, function (product) {
    return _c("el-option", {
      key: product.id,
      staticClass: "slim-list",
      attrs: {
        label: product.title,
        value: product.id
      }
    });
  }), 1)], 1)]), _vm._v(" "), _c("tr", [_c("th", {
    staticClass: "text-left"
  }, [_vm._v("Services")]), _vm._v(" "), _c("td", [_c("el-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      clearable: "",
      placeholder: "Select",
      size: "mini",
      multiple: ""
    },
    model: {
      value: _vm.search_entities.filters.services,
      callback: function callback($$v) {
        _vm.$set(_vm.search_entities.filters, "services", $$v);
      },
      expression: "search_entities.filters.services"
    }
  }, _vm._l(_vm.services, function (service) {
    return _c("el-option", {
      key: service.id,
      staticClass: "slim-list",
      attrs: {
        label: service.title,
        value: service.id
      }
    });
  }), 1)], 1)])]), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.filterFeatureProcessList
    }
  }, [_vm._v("Filter")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: _vm.closeFilterDialog
    }
  }, [_vm._v("Cancel")])], 1)]), _vm._v(" "), _c("el-drawer", {
    attrs: {
      title: "Collaborate: " + _vm.ticket.title,
      visible: _vm.actionDiscussionDrawer,
      direction: "rtl",
      size: "50%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.actionDiscussionDrawer = $event;
      }
    }
  }, [_c("el-tabs", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.tab_loading,
      expression: "tab_loading"
    }],
    attrs: {
      type: ""
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "Comments"
    }
  }, [_c("comment-component", {
    attrs: {
      "entity-id": _vm.ticket.id,
      "entity-name": _vm.ticket.title
    }
  })], 1), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: "Actions"
    }
  }, [_c("action-component", {
    attrs: {
      entity: {
        id: _vm.ticket.id,
        type: "EPIC"
      }
    },
    on: {
      add_action: _vm.addAction
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: _vm.computeChildTicketDetailTitle(),
      visible: _vm.childTicketDetailsDialog,
      "append-to-body": true,
      width: "80%",
      top: "1vh"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.childTicketDetailsDialog = $event;
      }
    }
  }, [_c("child-ticket-component", {
    attrs: {
      entity: _vm.selectedChildTicket
    },
    on: {
      close: function close($event) {
        _vm.childTicketDetailsDialog = false;
      }
    }
  })], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;