"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("search", {
    attrs: {
      title: _vm.title,
      "create-item": _vm.createItem,
      "create-title": _vm.createTitle
    },
    on: {
      create: _vm.createNewItem,
      search: _vm.searchItem
    }
  }), _vm._v(" "), _vm._t("default", function () {
    return [_vm.filteredItems.length > 0 ? _c("div", _vm._l(_vm.filteredItems, function (item, index) {
      return _c("div", {
        key: index,
        class: _vm.selectedItem && _vm.selectedItem.id === item.id || _vm.userSelectedItem && _vm.userSelectedItem.id === item.id ? "list_item is-item-active" : "list_item",
        on: {
          click: function click($event) {
            return _vm.selectItem(item, index);
          }
        }
      }, [_c("table", {
        staticStyle: {
          width: "100%",
          "font-variant-caps": "small-caps"
        }
      }, [_c("tr", [_c("td", [_c("strong", [_vm._v(_vm._s(item.title))])])])]), _vm._v(" "), _c("div", {
        staticStyle: {
          "font-size": "12px"
        }
      }, [_c("i", [_vm._v(_vm._s(_vm.truncateDescription(item.description, 30)))])])]);
    }), 0) : _vm._e()];
  }), _vm._v(" "), _vm.filteredItems.length === 0 ? _c("el-empty", [_c("p", {
    attrs: {
      slot: "description"
    },
    on: {
      click: function click($event) {
        return _vm.createNewItem();
      }
    },
    slot: "description"
  }, [_vm._v("No Data")])]) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "text-center",
    staticStyle: {
      width: "calc(100%/24 * 4)",
      position: "fixed",
      bottom: "0px",
      "background-color": "rgba(255,255,255,1)"
    }
  }, [_vm.total > 0 ? _c("el-pagination", {
    attrs: {
      layout: "prev, pager, next",
      small: true,
      "hide-on-single-page": true,
      "page-size": _vm.pageSize,
      total: _vm.total
    },
    on: {
      "current-change": _vm.loadPage,
      "prev-click": _vm.loadPage,
      "next-click": _vm.loadPage
    }
  }) : _vm._e()], 1)], 2);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;