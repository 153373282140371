"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.to-string");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("process-steps", {
    attrs: {
      steps: _vm.steps,
      "current-step": _vm.active_step
    },
    on: {
      "update-step": _vm.updateStep
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "app-container"
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 5
    }
  }, [_c("el-card", {
    staticClass: "mt-10"
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("strong", [_vm._v(" Market Segments ")]), _vm._v(" "), _c("a", {
    staticClass: "fr",
    on: {
      click: function click($event) {
        _vm.marketSegmentsHelpDialog = true;
      }
    }
  }, [_vm._v("Help")])]), _vm._v(" "), _c("el-row", [_c("el-col", {
    staticClass: "text-center mt-10",
    attrs: {
      span: 24
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini",
      title: "Add Segment",
      icon: "el-icon-plus"
    },
    on: {
      click: _vm.openAddSegmentModal
    }
  }, [_vm._v("\n                Add Segment\n              ")])], 1)], 1), _vm._v(" "), _c("el-row", [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-menu", {
    staticClass: "segment-menu-inner",
    attrs: {
      "default-active": "0"
    }
  }, _vm._l(_vm.segments, function (segment, index) {
    return _c("el-menu-item", {
      key: index,
      staticClass: "item",
      attrs: {
        index: index.toString()
      },
      on: {
        click: function click($event) {
          return _vm.selectSegment(segment, index);
        }
      }
    }, [_c("span", [_vm._v(_vm._s(segment.name))])]);
  }), 1), _vm._v(" "), _c("el-divider", {
    staticStyle: {
      margin: "4px 0"
    }
  }), _vm._v(" "), _c("el-menu", {
    staticClass: "segment-menu-inner",
    attrs: {
      "default-active": "0"
    }
  }, [_c("el-menu-item", {
    staticClass: "item",
    attrs: {
      index: "0"
    },
    on: {
      click: function click($event) {
        _vm.totalMarketSelected = true;
      }
    }
  }, [_vm._v("Totals")])], 1)], 1)], 1)], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 19
    }
  }, [_vm.totalMarketSelected ? [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-card", {
    staticClass: "mt-10"
  }, [_c("div", {
    staticClass: "text-center"
  }, [_c("p", [_vm._v("Content to be updated")])])])], 1)], 1)] : [_vm.active_step === 0 && _vm.segments.length > 0 ? [_c("segment-overview-component", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      "active-segment": _vm.activeSegment,
      "active-segment-index": _vm.activeSegmentIndex
    },
    on: {
      "go-next": function goNext($event) {
        _vm.active_step = 1;
      },
      openAddSegmentModal: _vm.openAddSegmentModal
    }
  })] : _vm._e(), _vm._v(" "), _vm.active_step === 1 && _vm.segments.length > 0 ? [_c("segment-financials-component", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      "active-segment": _vm.activeSegment,
      "active-segment-index": _vm.activeSegmentIndex
    },
    on: {
      "go-next": function goNext($event) {
        _vm.active_step = 2;
      },
      "go-prev": function goPrev($event) {
        _vm.active_step = 0;
      }
    }
  })] : _vm._e(), _vm._v(" "), _vm.active_step === 2 && _vm.segments.length > 0 ? [_c("customer-types-component", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      "active-segment": _vm.activeSegment,
      "active-segment-index": _vm.activeSegmentIndex
    },
    on: {
      "go-prev": function goPrev($event) {
        _vm.active_step = 1;
      }
    }
  })] : _vm._e()]], 2)], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Market Segments Help",
      visible: _vm.marketSegmentsHelpDialog,
      width: "50%",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.marketSegmentsHelpDialog = $event;
      }
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_vm._v("\n        To be populated\n      ")])], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.marketSegmentsHelpDialog = false;
      }
    }
  }, [_vm._v("Cancel")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Add Segment",
      visible: _vm.addSegmentDialog,
      width: "60%",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addSegmentDialog = $event;
      }
    }
  }, [_c("el-form", {
    ref: "segment_form",
    attrs: {
      model: _vm.segment_form,
      "label-width": "200px"
    }
  }, [_c("el-steps", {
    staticClass: "no-word-breaks",
    attrs: {
      active: _vm.addSegmentModalStepCounter,
      "finish-status": "success",
      "align-center": ""
    }
  }, [_c("el-step", {
    attrs: {
      title: "Segment Overview"
    }
  }, [_c("div", {
    attrs: {
      slot: "description"
    },
    slot: "description"
  }, [_vm._v("Describe high level information about this segment ")])]), _vm._v(" "), _c("el-step", {
    attrs: {
      title: "Needs"
    }
  }, [_c("div", {
    attrs: {
      slot: "description"
    },
    slot: "description"
  }, [_vm._v("What are the needs of this segment?")])]), _vm._v(" "), _c("el-step", {
    attrs: {
      title: "Revenue Types"
    }
  }, [_c("div", {
    attrs: {
      slot: "description"
    },
    slot: "description"
  }, [_vm._v("How are you making money?")])]), _vm._v(" "), _c("el-step", {
    attrs: {
      title: "Segment Size"
    }
  }, [_c("div", {
    attrs: {
      slot: "description"
    },
    slot: "description"
  }, [_vm._v("How many customers are in this segment. What are their needs?")])])], 1), _vm._v(" "), _c("el-divider"), _vm._v(" "), _vm.addSegmentModalStepCounter === 0 ? [_c("el-row", {
    staticClass: "mt-10",
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 18
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Segment Name"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.segment_form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.segment_form, "name", $$v);
      },
      expression: "segment_form.name"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-row", [_c("el-col", {
    attrs: {
      span: 18
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Segment Description"
    }
  }, [_c("el-input", {
    attrs: {
      rows: 4,
      type: "textarea"
    },
    model: {
      value: _vm.segment_form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.segment_form, "description", $$v);
      },
      expression: "segment_form.description"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    staticClass: "text-left",
    attrs: {
      label: "Revenue Notes"
    }
  }, [_c("el-input", {
    attrs: {
      rows: 4,
      type: "textarea"
    },
    model: {
      value: _vm.segment_form.revenue_notes,
      callback: function callback($$v) {
        _vm.$set(_vm.segment_form, "revenue_notes", $$v);
      },
      expression: "segment_form.revenue_notes"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-divider"), _vm._v(" "), _c("el-row", {
    staticStyle: {
      padding: "2pt 0 2pt 0",
      margin: "0 0 0 0"
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    staticClass: "text-left",
    attrs: {
      label: "Sales Method"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Select Type",
      multiple: ""
    },
    model: {
      value: _vm.segment_form.salesMethod,
      callback: function callback($$v) {
        _vm.$set(_vm.segment_form, "salesMethod", $$v);
      },
      expression: "segment_form.salesMethod"
    }
  }, [_c("el-option", {
    attrs: {
      label: "direct",
      value: "Direct"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "partner",
      value: "Partner"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "channel",
      value: "Channel"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "online",
      value: "Online"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "whiteLabel",
      value: "White Label"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    staticClass: "text-left",
    attrs: {
      label: "Pricing Tier"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Select Type",
      multiple: ""
    },
    model: {
      value: _vm.segment_form.pricingTier,
      callback: function callback($$v) {
        _vm.$set(_vm.segment_form, "pricingTier", $$v);
      },
      expression: "segment_form.pricingTier"
    }
  }, [_c("el-option", {
    attrs: {
      label: "Premium",
      value: "Premium"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Mid",
      value: "Mid"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Value",
      value: "Value"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Geography"
    }
  }, [_c("el-select", {
    attrs: {
      multiple: "",
      placeholder: "Select"
    },
    model: {
      value: _vm.segment_form.geography,
      callback: function callback($$v) {
        _vm.$set(_vm.segment_form, "geography", $$v);
      },
      expression: "segment_form.geography"
    }
  }, _vm._l(_vm.countries, function (country) {
    return _c("el-option", {
      key: country.code,
      attrs: {
        label: country.name,
        value: country.code
      }
    });
  }), 1)], 1)], 1)], 1)] : _vm._e(), _vm._v(" "), _vm.addSegmentModalStepCounter === 1 ? [_c("el-row", [_c("el-col", {
    attrs: {
      span: 14
    }
  }, [_c("el-form-item", {
    staticClass: "text-center",
    attrs: {
      label: "Segment Needs"
    }
  }, [_c("table", [_c("tbody", {
    staticStyle: {
      "vertical-align": "top"
    }
  }, _vm._l(_vm.segment_form.segment_needs, function (segment_need, index) {
    return _c("tr", {
      key: index
    }, [_c("td", {
      staticStyle: {
        "padding-bottom": "2pt"
      }
    }, [_c("el-row", [_c("el-col", {
      attrs: {
        span: 24
      }
    }, [_c("el-input", {
      attrs: {
        placeholder: "Describe this market need"
      },
      model: {
        value: segment_need.name,
        callback: function callback($$v) {
          _vm.$set(segment_need, "name", $$v);
        },
        expression: "segment_need.name"
      }
    }, [_c("template", {
      slot: "prepend"
    }, [_c("el-checkbox", {
      model: {
        value: segment_need.complete,
        callback: function callback($$v) {
          _vm.$set(segment_need, "complete", $$v);
        },
        expression: "segment_need.complete"
      }
    })], 1)], 2)], 1)], 1)], 1)]);
  }), 0)])])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 4
    }
  }, [_c("el-button", {
    staticStyle: {
      margin: "5pt 10pt 5pt 8pt",
      padding: "3pt"
    },
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.addSegmentNeeds
    }
  }, [_vm._v("\n              Add Need\n            ")])], 1)], 1)] : _vm._e(), _vm._v(" "), _vm.addSegmentModalStepCounter === 2 ? [_c("el-row", {
    staticClass: "mt-10",
    attrs: {
      gutter: 20,
      span: 24
    }
  }, [_c("el-col", {
    staticStyle: {
      "margin-left": "100pt"
    },
    attrs: {
      span: 6
    }
  }, [_c("span", {
    staticStyle: {
      "font-weight": "bold",
      "font-size": "1.2em",
      color: "black"
    }
  }, [_vm._v("Revenue Mix")]), _vm._v(" "), _c("el-radio-group", {
    model: {
      value: _vm.segment_form.services,
      callback: function callback($$v) {
        _vm.$set(_vm.segment_form, "services", $$v);
      },
      expression: "segment_form.services"
    }
  }, [_c("el-radio", {
    staticClass: "mt-10",
    staticStyle: {
      width: "181.3px"
    },
    attrs: {
      label: "Licensing",
      border: ""
    }
  }, [_vm._v("Licensing")]), _c("br"), _vm._v(" "), _c("el-radio", {
    staticClass: "mt-10",
    staticStyle: {
      width: "181.3px"
    },
    attrs: {
      label: "Services",
      border: ""
    }
  }, [_vm._v("Services")]), _c("br"), _vm._v(" "), _c("el-radio", {
    staticClass: "mt-10",
    staticStyle: {
      width: "181.3px"
    },
    attrs: {
      label: "Service & Licensing",
      border: ""
    }
  }, [_vm._v("Service & Licensing")])], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 14
    }
  }, [_c("span", {
    staticStyle: {
      "font-weight": "bold",
      "font-size": "1.2em",
      color: "black"
    }
  }, [_vm._v("Annual Revenue Per Customer (Estimated)")]), _vm._v(" "), _vm.segment_form.services === "Licensing" || _vm.segment_form.services === "Service & Licensing" ? _c("el-form", [_c("el-form-item", {
    staticClass: "text-center",
    attrs: {
      label: "Licensing"
    }
  }, [_c("vue-numeric", {
    staticClass: "el-input__inner",
    attrs: {
      separator: ",",
      currency: "$",
      min: 0,
      max: 1000
    },
    model: {
      value: _vm.segment_form.annualLicenseRevenuePC,
      callback: function callback($$v) {
        _vm.$set(_vm.segment_form, "annualLicenseRevenuePC", $$v);
      },
      expression: "segment_form.annualLicenseRevenuePC"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    staticClass: "text-center",
    attrs: {
      label: "Premium Support"
    }
  }, [_c("vue-numeric", {
    staticClass: "el-input__inner",
    attrs: {
      separator: ",",
      currency: "$",
      min: 0,
      max: 1000
    },
    model: {
      value: _vm.segment_form.annualSupportRevenue,
      callback: function callback($$v) {
        _vm.$set(_vm.segment_form, "annualSupportRevenue", $$v);
      },
      expression: "segment_form.annualSupportRevenue"
    }
  })], 1)], 1) : _vm._e(), _vm._v(" "), _vm.segment_form.services === "Services" || _vm.segment_form.services === "Service & Licensing" ? _c("el-form", [_c("el-form-item", {
    staticClass: "text-center",
    attrs: {
      label: "Professional Services"
    }
  }, [_c("vue-numeric", {
    staticClass: "el-input__inner",
    attrs: {
      separator: ",",
      currency: "$",
      min: 0,
      max: 1000
    },
    model: {
      value: _vm.segment_form.annualProjectServicer,
      callback: function callback($$v) {
        _vm.$set(_vm.segment_form, "annualProjectServicer", $$v);
      },
      expression: "segment_form.annualProjectServicer"
    }
  })], 1)], 1) : _vm._e(), _vm._v(" "), _vm.segment_form.services === "Services & Licensing" ? _c("el-form", [_c("el-form-item", {
    staticClass: "text-center",
    attrs: {
      label: "Licensing"
    }
  }, [_c("vue-numeric", {
    staticClass: "el-input__inner",
    attrs: {
      separator: ",",
      currency: "$",
      min: 0,
      max: 1000
    },
    model: {
      value: _vm.segment_form.annualLicenseRevenuePC,
      callback: function callback($$v) {
        _vm.$set(_vm.segment_form, "annualLicenseRevenuePC", $$v);
      },
      expression: "segment_form.annualLicenseRevenuePC"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    staticClass: "text-center",
    attrs: {
      label: "Premium Support"
    }
  }, [_c("vue-numeric", {
    staticClass: "el-input__inner",
    attrs: {
      separator: ",",
      currency: "$",
      min: 0,
      max: 1000
    },
    model: {
      value: _vm.segment_form.annualSupportRevenue,
      callback: function callback($$v) {
        _vm.$set(_vm.segment_form, "annualSupportRevenue", $$v);
      },
      expression: "segment_form.annualSupportRevenue"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    staticClass: "text-center",
    attrs: {
      label: "Professional Services"
    }
  }, [_c("vue-numeric", {
    staticClass: "el-input__inner",
    attrs: {
      separator: ",",
      currency: "$",
      min: 0,
      max: 1000
    },
    model: {
      value: _vm.segment_form.annualProjectServicer,
      callback: function callback($$v) {
        _vm.$set(_vm.segment_form, "annualProjectServicer", $$v);
      },
      expression: "segment_form.annualProjectServicer"
    }
  })], 1)], 1) : _vm._e()], 1)], 1)] : _vm._e(), _vm._v(" "), _vm.addSegmentModalStepCounter === 3 ? [_c("el-row", {
    staticClass: "mt-10",
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-tooltip", {
    attrs: {
      effect: "dark",
      content: "How many POTENTIAL customers are in THIS segment?",
      placement: "right"
    }
  }, [_c("el-form-item", {
    staticClass: "text-center",
    attrs: {
      label: "Total Segment Size"
    }
  }, [_c("vue-numeric", {
    staticClass: "el-input__inner",
    attrs: {
      separator: ",",
      min: 0,
      max: 10000
    },
    model: {
      value: _vm.segment_form.num_customers,
      callback: function callback($$v) {
        _vm.$set(_vm.segment_form, "num_customers", $$v);
      },
      expression: "segment_form.num_customers"
    }
  })], 1)], 1), _vm._v(" "), _c("el-tooltip", {
    attrs: {
      effect: "dark",
      content: "How many potential customers do you want to CONVERT into customers MONTHLY?",
      placement: "right"
    }
  }, [_c("el-form-item", {
    staticClass: "text-center",
    attrs: {
      label: "New customer per month"
    }
  }, [_c("vue-numeric", {
    staticClass: "el-input__inner",
    attrs: {
      separator: ",",
      min: 0,
      max: 10000
    },
    model: {
      value: _vm.segment_form.newCustomerPerMonth,
      callback: function callback($$v) {
        _vm.$set(_vm.segment_form, "newCustomerPerMonth", $$v);
      },
      expression: "segment_form.newCustomerPerMonth"
    }
  })], 1)], 1)], 1)], 1)] : _vm._e()], 2), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_vm.addSegmentModalStepCounter >= 3 ? [_c("el-button", {
    attrs: {
      size: "small"
    },
    on: {
      click: function click($event) {
        _vm.addSegmentModalStepCounter--;
      }
    }
  }, [_vm._v(" Previous ")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "small"
    },
    on: {
      click: function click($event) {
        _vm.addSegmentDialog = false;
      }
    }
  }, [_vm._v("Cancel")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary",
      size: "small"
    },
    on: {
      click: _vm.addSegment
    }
  }, [_vm._v("Save")])] : [_vm.addSegmentModalStepCounter > 0 ? _c("el-button", {
    attrs: {
      size: "small"
    },
    on: {
      click: function click($event) {
        _vm.addSegmentModalStepCounter--;
      }
    }
  }, [_vm._v(" Previous ")]) : _vm._e(), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "small",
      type: "primary"
    },
    on: {
      click: function click($event) {
        _vm.addSegmentModalStepCounter++;
      }
    }
  }, [_vm._v(" Next ")])]], 2)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;