"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _vuex = require("vuex");
var _default = exports.default = {
  name: 'Hamburger',
  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      hover: false
    };
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['device'])), {}, {
    // a computed getter
    fillColor: function fillColor() {
      if (this.hover) {
        return 'black';
      } else {
        return 'white';
      }
    }
  }),
  methods: {
    toggleClick: function toggleClick() {
      this.$emit('toggleClick');
    }
  }
};