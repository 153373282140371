"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default2 = exports.default = {
  name: 'MockupStoryboardDetail',
  props: {
    isClicked: {
      type: Boolean,
      default: false
    },
    mockupStoryboard: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    resetAnnotation: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      selectedVersion: {},
      selectedVersionName: '',
      selectedVersionIndex: 0,
      selectedTask: {},
      selectedTaskIndex: null,
      draw: false,
      tempAnnotation: {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
        style: 'fill:rgba(0,0,0);stroke-width:0;stroke:rgb(0,0,0);fill-opacity:0.2;'
      }
    };
  },
  computed: {
    isModalSelected: function isModalSelected() {
      return this.isClicked;
    }
  },
  watch: {
    isModalSelected: function isModalSelected(newVal, oldVal) {
      // watch it
      if (newVal === true) {
        this.$refs.mockupStoryboardDetailsModal.open();
      }
    },
    resetAnnotation: function resetAnnotation(newVal, oldVal) {
      if (newVal === true) {
        this.resetAddTask();
      }
    }
  },
  mounted: function mounted() {
    this.selectedVersion = this.mockupStoryboard.versions[0];
    this.selectedVersionName = this.mockupStoryboard.versions[0].version;
  },
  created: function created() {},
  methods: {
    generateVersionTaskKey: function generateVersionTaskKey(index, sub_index) {
      return 'version_task_' + index + '_' + sub_index;
    },
    onDragged: function onDragged(_ref) {
      var el = _ref.el,
        deltaX = _ref.deltaX,
        deltaY = _ref.deltaY,
        offsetX = _ref.offsetX,
        offsetY = _ref.offsetY,
        clientX = _ref.clientX,
        clientY = _ref.clientY,
        first = _ref.first,
        last = _ref.last;
      if (this.draw) {
        if (first) {
          this.tempAnnotation.x = clientX - 147;
          this.tempAnnotation.y = clientY - 200;
        }
        if (last) {
          this.addTaskDialog = true;
          this.$emit('add-task', this.selectedVersionIndex, this.tempAnnotation);
        }
        if (deltaX === undefined || deltaY === undefined) {
          return;
        }
        this.tempAnnotation.width = offsetX;
        this.tempAnnotation.height = offsetY;
      }
    },
    removeTask: function removeTask(index) {
      this.selectedVersion.tasks.splice(index, 1);
    },
    selectAnnotation: function selectAnnotation(task, index) {
      if (this.selectedTaskIndex !== null && this.selectedTaskIndex === index) {
        this.selectedTaskIndex = null;
        this.selectedTask = {};
      } else {
        this.selectedTask = task;
        this.selectedTaskIndex = index;
      }
    },
    updateVersionChange: function updateVersionChange(version, index) {
      this.selectedVersion = version;
      this.selectedVersionIndex = index;
    },
    modalClose: function modalClose() {
      this.isClicked = false;
    },
    getPriorityIcon: function getPriorityIcon(priority) {
      return 'priority-' + priority;
    },
    resetAddTask: function resetAddTask() {
      this.tempAnnotation = {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
        style: 'fill:rgba(0,0,0);stroke-width:0;stroke:rgb(0,0,0);fill-opacity:0.2;'
      };
    }
  }
};