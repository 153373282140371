"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "app-container"
  }, [_c("el-card", [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      offset: 18,
      span: 6
    }
  }, [_c("div", {
    staticClass: "text-right"
  }, [_c("el-input", {
    attrs: {
      type: "text",
      size: "mini",
      placeholder: "Search Permission"
    },
    model: {
      value: _vm.searchPermission,
      callback: function callback($$v) {
        _vm.searchPermission = $$v;
      },
      expression: "searchPermission"
    }
  }, [_c("el-button", {
    attrs: {
      slot: "append",
      type: "primary",
      icon: "el-icon-plus",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.createPermission();
      }
    },
    slot: "append"
  }, [_vm._v("Create Permission")])], 1)], 1)])], 1), _vm._v(" "), _vm.filteredPermissionList.length > 0 ? _c("div", [_c("el-table", {
    staticStyle: {
      width: "100%",
      "margin-top": "30px"
    },
    attrs: {
      data: _vm.filteredPermissionList,
      size: "mini",
      stripe: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      align: "center",
      label: "Title"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v("\n            " + _vm._s(scope.row.title) + "\n          ")];
      }
    }], null, false, 321964893)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "header-center",
      label: "Description"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v("\n            " + _vm._s(scope.row.description) + "\n          ")];
      }
    }], null, false, 2896220923)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "header-center",
      label: "Action"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v("\n            " + _vm._s(scope.row.action) + "\n          ")];
      }
    }], null, false, 2709347683)
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "text-right"
  }, [_c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.listQuery.page,
      limit: _vm.listQuery.limit,
      layout: "prev, pager, next",
      background: false
    },
    on: {
      "update:page": function updatePage($event) {
        return _vm.$set(_vm.listQuery, "page", $event);
      },
      "update:limit": function updateLimit($event) {
        return _vm.$set(_vm.listQuery, "limit", $event);
      },
      pagination: _vm.getPermissionList
    }
  })], 1)], 1) : _c("div", {
    staticClass: "text-center"
  }, [_c("span", [_vm._v("No Permissions Found")])])], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;