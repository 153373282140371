"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }]
  }, [_c("h1", {
    staticStyle: {
      color: "rebeccapurple"
    }
  }, [_vm._v("Customer Priorities")]), _vm._v(" "), _c("div", {
    staticClass: "text-left",
    staticStyle: {
      color: "rebeccapurple"
    }
  }, [_vm._v("\n    Identify the customer problems that matter.\n  ")]), _vm._v(" "), _c("el-row", {
    staticStyle: {
      "margin-bottom": "10px"
    },
    attrs: {
      gutter: 20,
      type: "flex",
      justify: "center"
    }
  }, [_c("el-col", {
    attrs: {
      span: 6
    }
  }), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("el-input", {
    attrs: {
      type: "text",
      placeholder: "Search",
      size: "mini"
    },
    model: {
      value: _vm.q,
      callback: function callback($$v) {
        _vm.q = $$v;
      },
      expression: "q"
    }
  }, [_c("i", {
    staticClass: "el-input__icon el-icon-search",
    attrs: {
      slot: "prefix"
    },
    slot: "prefix"
  })])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    on: {
      change: _vm.resetSearch
    },
    model: {
      value: _vm.viewType,
      callback: function callback($$v) {
        _vm.viewType = $$v;
      },
      expression: "viewType"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: "steps"
    }
  }, [_vm._v("Steps")]), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "stages"
    }
  }, [_vm._v("Stages")])], 1)], 1)], 1), _vm._v(" "), _c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.journeyMap.additionalattributes.layouts && _vm.filteredStages && _vm.filteredStages.length > 0 && _vm.viewType === "stages" ? _c("el-table", {
    staticClass: "value_priority_tables ml-10",
    attrs: {
      data: _vm.filteredStages,
      "row-key": "id",
      stripe: "",
      border: true,
      size: "micro",
      "default-sort": {
        prop: "autorank",
        order: "descending"
      }
    }
  }, [_c("el-table-column", {
    staticStyle: {
      "vertical-align": "top"
    },
    attrs: {
      label: "Stage",
      sortable: "",
      width: "90"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("span", {
          staticStyle: {
            "border-radius": "15pt",
            padding: "1pt 3pt 1pt 3pt",
            "margin-right": "5pt",
            "background-color": "lightslategrey",
            color: "white",
            width: "22pt",
            height: "22pt"
          }
        }, [_vm._v(" " + _vm._s(scope.$index + 1))])];
      }
    }], null, false, 916697093)
  }), _vm._v(" "), _c("el-table-column", {
    staticStyle: {
      "vertical-align": "top"
    },
    attrs: {
      label: "Stage Name"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v("\n          " + _vm._s(_vm.truncateDescription(scope.row.title, 60)) + "\n        ")];
      }
    }], null, false, 2291117310)
  }), _vm._v(" "), _c("el-table-column", {
    staticStyle: {
      "vertical-align": "top"
    },
    attrs: {
      label: "Value",
      sortable: "",
      prop: "importance",
      width: "90"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-tag", {
          attrs: {
            size: "mini",
            type: _vm.computeTagType(scope.row.importance)
          }
        }, [_vm._v(_vm._s(scope.row.importance))])];
      }
    }], null, false, 2417323142)
  }), _vm._v(" "), _c("el-table-column", {
    staticStyle: {
      "vertical-align": "top"
    },
    attrs: {
      label: "Priority",
      sortable: "",
      prop: "priority",
      width: "110"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-tag", {
          attrs: {
            size: "mini",
            type: _vm.computeTagType(scope.row.priority)
          }
        }, [_vm._v(_vm._s(scope.row.priority))])];
      }
    }], null, false, 2238514374)
  }), _vm._v(" "), _c("el-table-column", {
    staticStyle: {
      "vertical-align": "top"
    },
    attrs: {
      label: "Autorank",
      sortable: "",
      prop: "autorank",
      width: "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-tag", {
          attrs: {
            size: "mini"
          }
        }, [_vm._v(_vm._s(scope.row.autorank))])];
      }
    }], null, false, 3818172303)
  }), _vm._v(" "), _c("el-table-column", {
    staticStyle: {
      "vertical-align": "top"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn(scope) {
        return [_c("el-row", {
          attrs: {
            gutter: 20
          }
        }, [_c("el-col", {
          attrs: {
            span: 1
          }
        }), _vm._v(" "), _c("el-col", {
          attrs: {
            span: 14
          }
        }, [_vm._v("Experience")])], 1)];
      }
    }, {
      key: "default",
      fn: function fn(scope) {
        return [_vm.computeExperienceListForPhase(scope.row.id).length > 0 ? _c("div", {}, _vm._l(_vm.computeExperienceListForPhase(scope.row.id), function (experience, experience_index) {
          return _c("div", {
            staticClass: "phase-step-experience-item",
            attrs: {
              index: experience_index
            }
          }, [_c("el-row", {
            attrs: {
              gutter: 20
            }
          }, [_c("el-col", {
            attrs: {
              span: 1
            }
          }, [experience.journeyExperience.experiencetype === "GAIN" ? _c("svg-icon", {
            staticStyle: {
              "font-size": "18px",
              color: "green"
            },
            attrs: {
              "icon-class": "smiley"
            }
          }) : _vm._e(), _vm._v(" "), experience.journeyExperience.experiencetype === "PAIN" ? _c("svg-icon", {
            staticStyle: {
              "font-size": "18px"
            },
            attrs: {
              "icon-class": "danger-circle"
            }
          }) : _vm._e(), _vm._v(" "), experience.journeyExperience.experiencetype === "WOW" ? _c("svg-icon", {
            staticStyle: {
              "font-size": "18px"
            },
            attrs: {
              "icon-class": "wow_blue"
            }
          }) : _vm._e(), _vm._v(" "), experience.journeyExperience.experiencetype === "CHALLENGE" ? _c("svg-icon", {
            staticStyle: {
              "font-size": "18px"
            },
            attrs: {
              "icon-class": "oscurity"
            }
          }) : _vm._e()], 1), _vm._v(" "), _c("el-col", {
            attrs: {
              span: 20
            }
          }, [_vm._v("\n                  " + _vm._s(_vm.truncateDescription(experience.journeyExperience.title, 30)) + " - "), _c("el-tag", {
            attrs: {
              size: "mini"
            }
          }, [_vm._v("Priority:" + _vm._s(experience.journeyExperience.priority))]), _vm._v(" - "), _c("el-tag", {
            attrs: {
              size: "mini"
            }
          }, [_vm._v(" Value: " + _vm._s(experience.journeyExperience.additionalattributes.customer_value))])], 1), _vm._v(" "), _c("el-col", {
            attrs: {
              span: 2
            }
          }, [_c("i", {
            staticClass: "el-icon-delete",
            staticStyle: {
              color: "#F56C6C"
            },
            on: {
              click: function click($event) {
                return _vm.deleteExperience(experience.journeyExperience.id);
              }
            }
          })])], 1)], 1);
        }), 0) : _c("div", [_c("span", {
          staticStyle: {
            "font-style": "italic",
            color: "grey"
          }
        }, [_vm._v("No experiences for this stage")])])];
      }
    }], null, false, 190788005)
  })], 1) : _vm._e(), _vm._v(" "), _vm.filteredStages && _vm.filteredStages.length === 0 && _vm.viewType === "stages" ? _c("el-empty") : _vm._e()], 1), _vm._v(" "), _c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.journeyMap.id && _vm.filteredTasks && _vm.filteredTasks.length > 0 && _vm.viewType === "steps" ? _c("el-table", {
    staticClass: "value_priority_tables ml-10",
    attrs: {
      data: _vm.filteredTasks,
      border: true,
      stripe: "",
      size: "micro",
      "default-sort": {
        prop: "autorank",
        order: "descending"
      }
    }
  }, [_c("el-table-column", {
    staticStyle: {
      "vertical-align": "top"
    },
    attrs: {
      label: "Step",
      align: "center",
      width: "90"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("span", {
          staticStyle: {
            "border-radius": "15pt",
            padding: "1pt 3pt 1pt 3pt",
            "margin-right": "5pt",
            "background-color": "lightslategrey",
            color: "white",
            width: "22pt",
            height: "22pt"
          }
        }, [_vm._v(" " + _vm._s(scope.$index + 1))])];
      }
    }], null, false, 916697093)
  }), _vm._v(" "), _c("el-table-column", {
    staticStyle: {
      "vertical-align": "top"
    },
    attrs: {
      label: "Stage Name"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v("\n          " + _vm._s(_vm.truncateDescription(_vm.computeStageTitleFromStepID(scope.row.id), 50)) + "\n        ")];
      }
    }], null, false, 1622833321)
  }), _vm._v(" "), _c("el-table-column", {
    staticStyle: {
      "vertical-align": "top"
    },
    attrs: {
      prop: "title",
      label: "Step Name"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v("\n          " + _vm._s(_vm.truncateDescription(scope.row.title, 50)) + "\n        ")];
      }
    }], null, false, 3425772989)
  }), _vm._v(" "), _c("el-table-column", {
    staticStyle: {
      "vertical-align": "top"
    },
    attrs: {
      label: "Value",
      sortable: "",
      prop: "importance",
      width: "90"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-tag", {
          attrs: {
            size: "mini",
            type: _vm.computeTagType(scope.row.importance)
          }
        }, [_vm._v(_vm._s(scope.row.importance))])];
      }
    }], null, false, 2417323142)
  }), _vm._v(" "), _c("el-table-column", {
    staticStyle: {
      "vertical-align": "top"
    },
    attrs: {
      label: "Priority",
      sortable: "",
      prop: "priority",
      width: "110"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-tag", {
          attrs: {
            size: "mini",
            type: _vm.computeTagType(scope.row.priority)
          }
        }, [_vm._v(_vm._s(scope.row.priority))])];
      }
    }], null, false, 2238514374)
  }), _vm._v(" "), _c("el-table-column", {
    staticStyle: {
      "vertical-align": "top"
    },
    attrs: {
      label: "Autorank",
      sortable: "",
      prop: "autorank",
      width: "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-tag", {
          attrs: {
            size: "mini"
          }
        }, [_vm._v(_vm._s(scope.row.autorank))])];
      }
    }], null, false, 3818172303)
  }), _vm._v(" "), _c("el-table-column", {
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn(scope) {
        return [_c("el-row", {
          attrs: {
            gutter: 20
          }
        }, [_c("el-col", {
          attrs: {
            span: 1
          }
        }), _vm._v(" "), _c("el-col", {
          attrs: {
            span: 14
          }
        }, [_vm._v("Experience")])], 1)];
      }
    }, {
      key: "default",
      fn: function fn(scope) {
        return [_vm.computeExperienceListForStep(scope.row.id).length > 0 ? _c("div", {}, _vm._l(_vm.computeExperienceListForStep(scope.row.id), function (experience, experience_index) {
          return _c("div", {
            staticClass: "phase-step-experience-item",
            attrs: {
              index: experience_index
            }
          }, [_c("el-row", {
            attrs: {
              gutter: 20
            }
          }, [_c("el-col", {
            attrs: {
              span: 1
            }
          }, [experience.journeyExperience.experiencetype === "GAIN" ? _c("svg-icon", {
            staticStyle: {
              "font-size": "18px",
              "margin-right": "5px",
              color: "green"
            },
            attrs: {
              "icon-class": "smiley"
            }
          }) : _vm._e(), _vm._v(" "), experience.journeyExperience.experiencetype === "PAIN" ? _c("svg-icon", {
            staticStyle: {
              "font-size": "18px",
              "margin-right": "5px"
            },
            attrs: {
              "icon-class": "danger-circle"
            }
          }) : _vm._e(), _vm._v(" "), experience.journeyExperience.experiencetype === "WOW" ? _c("svg-icon", {
            staticStyle: {
              "font-size": "18px",
              "margin-right": "5px"
            },
            attrs: {
              "icon-class": "wow_blue"
            }
          }) : _vm._e(), _vm._v(" "), experience.journeyExperience.experiencetype === "CHALLENGE" ? _c("svg-icon", {
            staticStyle: {
              "font-size": "18px",
              "margin-right": "5px"
            },
            attrs: {
              "icon-class": "oscurity"
            }
          }) : _vm._e()], 1), _vm._v(" "), _c("el-col", {
            attrs: {
              span: 20
            }
          }, [_vm._v("\n                  " + _vm._s(experience.journeyExperience.title) + " - "), _c("el-tag", {
            attrs: {
              size: "mini"
            }
          }, [_vm._v("Priority:" + _vm._s(experience.journeyExperience.priority))]), _vm._v(" - "), _c("el-tag", {
            attrs: {
              size: "mini"
            }
          }, [_vm._v(" Value: " + _vm._s(experience.journeyExperience.additionalattributes.customer_value))])], 1), _vm._v(" "), _c("el-col", {
            attrs: {
              span: 2
            }
          }, [_c("i", {
            staticClass: "el-icon-delete",
            staticStyle: {
              color: "#F56C6C"
            },
            on: {
              click: function click($event) {
                return _vm.deleteExperience(experience.journeyExperience.id);
              }
            }
          })])], 1)], 1);
        }), 0) : _c("div", [_c("span", {
          staticStyle: {
            "font-style": "italic",
            color: "grey"
          }
        }, [_vm._v("No experiences for this step")])])];
      }
    }], null, false, 4244439285)
  })], 1) : _vm._e(), _vm._v(" "), !_vm.journeyMap.id && _vm.viewType === "steps" || _vm.journeyMap.id && _vm.filteredTasks && _vm.filteredTasks.length === 0 && _vm.viewType === "steps" ? _c("el-empty") : _vm._e()], 1), _vm._v(" "), _c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.journeyMap.id && _vm.computedExperienceList && _vm.computedExperienceList.length > 0 && _vm.viewType === "experiences" ? _c("el-table", {
    staticClass: "value_priority_tables ml-10",
    attrs: {
      data: _vm.filteredExperiences,
      stripe: "",
      size: "mini"
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "journeyExperience.title",
      label: "Experience Name"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Experience Description"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v("\n          " + _vm._s(_vm.truncateDescription(scope.row.journeyExperience.description, 15)) + "\n        ")];
      }
    }], null, false, 2793318586)
  }), _vm._v(" "), _c("el-table-column", {
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn(scope) {
        return [_c("span", [_vm._v("Experience Type")]), _vm._v(" "), _c("span", [_c("el-checkbox-group", {
          attrs: {
            size: "mini"
          },
          model: {
            value: _vm.experiencesList,
            callback: function callback($$v) {
              _vm.experiencesList = $$v;
            },
            expression: "experiencesList"
          }
        }, [_c("el-checkbox-button", {
          attrs: {
            label: "PAIN"
          }
        }), _vm._v(" "), _c("el-checkbox-button", {
          attrs: {
            label: "GAIN"
          }
        }), _vm._v(" "), _c("el-checkbox-button", {
          attrs: {
            label: "WOW"
          }
        })], 1)], 1)];
      }
    }, {
      key: "default",
      fn: function fn(scope) {
        return [_c("el-tag", {
          attrs: {
            type: _vm.computeExperienceTagType(scope.row.journeyExperience.experiencetype),
            effect: "dark",
            size: "mini"
          }
        }, [_vm._v(_vm._s(scope.row.journeyExperience.experiencetype))])];
      }
    }], null, false, 91280254)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "journeyExperience.priority",
      label: "Experience Priority",
      sortable: ""
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "#"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-button-group", {
          staticStyle: {
            float: "left"
          },
          attrs: {
            size: "mini"
          }
        }, [_c("el-button", {
          attrs: {
            size: "mini",
            circle: "",
            icon: "el-icon-top",
            disabled: scope.$index === 0
          },
          on: {
            click: function click($event) {
              return _vm.moveExperienceRelationStageUp(scope.$index);
            }
          }
        }), _vm._v(" "), _c("el-button", {
          attrs: {
            size: "mini",
            circle: "",
            icon: "el-icon-bottom",
            disabled: scope.$index === _vm.filteredExperiences.length - 1
          },
          on: {
            click: function click($event) {
              return _vm.moveExperienceRelationStageDown(scope.$index);
            }
          }
        })], 1)];
      }
    }], null, false, 2282175998)
  })], 1) : _vm._e(), _vm._v(" "), !_vm.journeyMap.id && _vm.viewType === "experiences" || _vm.journeyMap.id && _vm.computedExperienceList && _vm.computedExperienceList.length === 0 && _vm.viewType === "experiences" ? _c("el-empty") : _vm._e()], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;