"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  name: 'DefineStrategicFeatures',
  components: {},
  props: {},
  data: function data() {
    return {
      loading: false,
      addFeatureDialog: false,
      addFeatureCompetingProductDialog: false,
      featureStepActive: 0,
      selectedFeature: {},
      identityDifferentiators: {
        value_proposition: 'Our product makes end-to-end digital product management must easier to manage. It gives non technical people more transparency and control of the design, build and operation of digital products.',
        vision_statement: 'Our vision is to make digital product management more accessible to anyone. This includes SMEs startups, as well as government and enterprise',
        selection_criterias: [{
          name: 'Transparency',
          description: 'Enable Transparency',
          goals: [{
            goal: 'Prow scuttle parrel provost Sail ho shrouds spirits boom mizzenmast yardarm. Pinnace holystone mizzenmast quarter crows nest nipperkin grog yardarm hempen halter furl. Swab barque interloper chantey doubloon starboard grog black jack gangway rutters.',
            strategy: 'Prow scuttle parrel provost Sail ho shrouds spirits boom mizzenmast yardarm. Pinnace holystone mizzenmast quarter crows nest nipperkin grog yardarm hempen halter furl. Swab barque interloper chantey doubloon starboard grog black jack gangway rutters.',
            priority: 'high',
            actions: [{
              action: '123',
              description: '1234',
              assigned_to: 'John',
              priority: 'high'
            }],
            achieved: false
          }, {
            goal: 'Trysail Sail ho Corsair red ensign hulk smartly boom jib rum gangway. Case shot Shiver me timbers gangplank crack Jennys tea cup ballast Blimey lee snow crows nest rutters. Fluke jib scourge of the seven seas boatswain schooner gaff booty Jack Tar transom spirits',
            strategy: 'Trysail Sail ho Corsair red ensign hulk smartly boom jib rum gangway. Case shot Shiver me timbers gangplank crack Jennys tea cup ballast Blimey lee snow crows nest rutters. Fluke jib scourge of the seven seas boatswain schooner gaff booty Jack Tar transom spirits',
            priority: 'low',
            actions: [{
              action: '123',
              description: '1234',
              assigned_to: 'John',
              priority: 'high'
            }],
            achieved: false
          }]
        }, {
          name: 'Usability',
          description: 'Enable usability',
          goals: [{
            goal: 'Prow scuttle parrel provost Sail ho shrouds spirits boom mizzenmast yardarm. Pinnace holystone mizzenmast quarter crows nest nipperkin grog yardarm hempen halter furl. Swab barque interloper chantey doubloon starboard grog black jack gangway rutters.',
            strategy: 'Prow scuttle parrel provost Sail ho shrouds spirits boom mizzenmast yardarm. Pinnace holystone mizzenmast quarter crows nest nipperkin grog yardarm hempen halter furl. Swab barque interloper chantey doubloon starboard grog black jack gangway rutters.',
            priority: 'high',
            actions: [{
              action: '123',
              description: '1234',
              assigned_to: 'John',
              priority: 'high'
            }],
            achieved: false
          }, {
            goal: 'Trysail Sail ho Corsair red ensign hulk smartly boom jib rum gangway. Case shot Shiver me timbers gangplank crack Jennys tea cup ballast Blimey lee snow crows nest rutters. Fluke jib scourge of the seven seas boatswain schooner gaff booty Jack Tar transom spirits',
            strategy: 'Trysail Sail ho Corsair red ensign hulk smartly boom jib rum gangway. Case shot Shiver me timbers gangplank crack Jennys tea cup ballast Blimey lee snow crows nest rutters. Fluke jib scourge of the seven seas boatswain schooner gaff booty Jack Tar transom spirits',
            priority: 'low',
            actions: [{
              action: '123',
              description: '1234',
              assigned_to: 'John',
              priority: 'high'
            }],
            achieved: false
          }]
        }],
        strategic_differentiating_feature: [{
          id: 1,
          name: 'Pageless, assisted navigation for usability',
          description: 'Corporis nostrum yet veritatis elit. Adipisicing minima and quis or sunt for est magni ratione. Tempor doloremque and excepteur so aspernatur but dolor, or dolore. Ut rem so velitesse aut. Sint aliquip and veritatis amet for cillum proident. Minima laborum ullam nor eum autem. Quae.',
          selling_proposition: 'Corporis nostrum yet veritatis elit. Adipisicing minima and quis or sunt for est magni ratione. Tempor doloremque and excepteur so aspernatur but dolor, or dolore. Ut rem so velitesse aut. Sint aliquip and veritatis amet for cillum proident. Minima laborum ullam nor eum autem. Quae.',
          tags: ['Cost reduction', 'Disruptive'],
          strategic_reason: 'Competitors do not offer this, and it is a high entry barrier, so we will invest in this.',
          revenue_strategy: 'Feature Group',
          /* 'Feature', 'None'*/
          competing_products: [{
            product: 'Product A',
            company: 'Company A',
            description: 'Product A details'
          }, {
            product: 'Product B',
            company: 'Company B',
            description: 'Product B details'
          }, {
            product: 'Product C',
            company: 'Company C',
            description: 'Product C details'
          }],
          complexity: '4',
          barriers: ['Barrier 1', 'Barrier 2']
        }]
      },
      feature_product_form: {
        product: '',
        description: '',
        company: ''
      },
      competingProductsData: [{
        product: 'Product A',
        description: 'Product A Description',
        company: 'Company A'
      }, {
        product: 'Product B',
        description: 'Product B Description',
        company: 'Company B'
      }],
      barriers: [{
        value: 'Barrier 1',
        label: 'Barrier 1'
      }],
      tags: [{
        value: 'Cost reduction',
        label: 'Cost reduction'
      }, {
        value: 'Quality Improvement',
        label: 'Quality Improvement'
      }, {
        value: 'Efficiency',
        label: 'Efficiency'
      }, {
        value: 'Disruptive',
        label: 'Disruptive'
      }, {
        value: 'New Markets',
        label: 'New Markets'
      }],
      feature_form: {
        name: '',
        description: '',
        strategic_reason: '',
        selling_proposition: '',
        tags: [],
        revenue_strategy: [],
        competing_products: [],
        complexity: '',
        barriers: []
      }
    };
  },
  mounted: function mounted() {
    this.$eventHub.$emit('process-step-load', 'define-strategic-features');
    this.selectedFeature = this.identityDifferentiators.strategic_differentiating_feature[0];
  },
  methods: {
    selectFeature: function selectFeature(row, column, event) {
      this.selectedFeature = row;
    },
    featureNextStep: function featureNextStep() {
      if (this.featureStepActive++ > 4) this.featureStepActive = 0;
    },
    getComplexity: function getComplexity(complexity) {
      switch (parseInt(complexity)) {
        case 1:
          return 'simple';
        case 2:
          return 'slightly complex';
        case 3:
          return 'complex';
        case 4:
          return 'very complex';
        case 5:
          return 'not feasible';
      }
    },
    onFeatureSubmit: function onFeatureSubmit() {
      var feature = Object.assign({}, this.feature_form);
      this.identityDifferentiators.strategic_differentiating_feature.push(feature);
      this.addFeatureDialog = false;
    },
    openAddFeatureDialog: function openAddFeatureDialog() {
      this.feature_form = {
        name: '',
        description: '',
        strategic_reason: '',
        selling_proposition: '',
        tags: [],
        revenue_strategy: [],
        competing_products: [],
        complexity: '',
        barriers: []
      };
      this.featureStepActive = 0;
      this.addFeatureDialog = true;
    },
    openAddFeatureCompetingProductDialog: function openAddFeatureCompetingProductDialog() {
      this.feature_product_form = {
        product: '',
        description: '',
        company: ''
      };
      this.addFeatureCompetingProductDialog = true;
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.feature_form.competing_products = val;
    },
    addFeatureCompetingProduct: function addFeatureCompetingProduct() {
      var feature_product = Object.assign({}, this.feature_product_form);
      this.competingProductsData.push(feature_product);
      this.addFeatureCompetingProductDialog = false;
    }
  }
};