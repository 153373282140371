"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _interopRequireWildcard2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _default2 = exports.default = {
  name: 'JourneyMap',
  components: {
    PhaseComponent: function PhaseComponent() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./Phase.vue"));
      });
    },
    TaskComponent: function TaskComponent() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./Task.vue"));
      });
    }
  },
  props: {
    journeyMap: {
      type: Object,
      required: true,
      default: function _default() {
        return {
          id: '',
          title: '',
          description: '',
          additionalattributes: {},
          linkedJourneyExperiences: [],
          linkedJourneyPhases: [],
          linkedJourneySteps: []
        };
      }
    },
    journeyMapHeight: {
      type: Number,
      required: true,
      default: 0
    },
    journeyMapWidth: {
      type: Number,
      required: true,
      default: 0
    },
    reset: {
      type: Boolean,
      default: false
    },
    settings: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      phase_pos_x: [],
      task_pos_x: [],
      task_pos_y: [],
      // Data
      phaseStepRelation: [],
      selectedPhaseIndex: null,
      selectedTaskIndex: null,
      selectedPhase: {},
      selectedTask: {},
      phaseHover: false,
      taskHover: false,
      titleMouseHover: false,
      canvasDragCoefficientX: 0,
      refreshKey: 0
    };
  },
  computed: {
    sortedStages: function sortedStages() {
      var stagesSortOrder = [];
      if (this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages.length > 0 && this.journeyMap.linkedJourneyPhases.length > 0) {
        for (var i = 0; i < this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages.length; i++) {
          for (var j = 0; j < this.journeyMap.linkedJourneyPhases.length; j++) {
            if (this.journeyMap.linkedJourneyPhases[j].journeyPhase.id === this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[i].id && !this.journeyMap.linkedJourneyPhases[j].journeyPhase.isDeleted) {
              stagesSortOrder.push(this.journeyMap.linkedJourneyPhases[j]);
              break;
            }
          }
        }
      } else {
        for (var k = 0; k < this.journeyMap.linkedJourneyPhases.length; k++) {
          if (!this.journeyMap.linkedJourneyPhases[j].journeyPhase.hasOwnProperty('isDeleted')) {
            stagesSortOrder.push(this.journeyMap.linkedJourneyPhases[j]);
          } else if (this.journeyMap.linkedJourneyPhases[j].journeyPhase.hasOwnProperty('isDeleted') && this.journeyMap.linkedJourneyPhases[j].journeyPhase.isDeleted === false) {
            stagesSortOrder.push(this.journeyMap.linkedJourneyPhases[j]);
            break;
          }
        }
      }
      return stagesSortOrder;
    },
    computedTaskList: function computedTaskList() {
      this.refreshKey;
      var stepList = [];
      if (this.journeyMap && this.journeyMap.linkedJourneySteps) {
        for (var i = 0; i < this.journeyMap.linkedJourneySteps.length; i++) {
          if (this.computeJourneyStepDisplayCondition(this.journeyMap.linkedJourneySteps[i], i)) {
            stepList.push(this.journeyMap.linkedJourneySteps[i]);
          }
        }
      }
      return stepList;
    },
    computeStepTitleTransform: function computeStepTitleTransform() {
      var pos = 81 + (parseInt(this.journeyMapHeight) - 45) / 2;
      return 'rotate(270, 80, ' + pos + ')';
    },
    computedRelations: function computedRelations() {
      var relations = [];
      for (var i = 0; i < this.journeyMap.additionalattributes.relations.length; i++) {
        if (!this.journeyMap.additionalattributes.relations[i].isDeleted) {
          relations.push(this.journeyMap.additionalattributes.relations[i]);
        }
      }
      return relations;
    }
  },
  watch: {
    reset: function reset(newVal, oldVal) {
      // watch it
      this.resetJourneyMap();
    }
  },
  created: function created() {},
  mounted: function mounted() {
    this.computePhaseX();
    this.computePhaseStepRelationArray();
    this.computeTaskX();
    this.computeTaskY();
  },
  methods: {
    computeJourneyStepDisplayCondition: function computeJourneyStepDisplayCondition(task, index) {
      if (task.journeyStep.hasOwnProperty('isDeleted') && task.journeyStep.isDeleted === true) {
        return false;
      }
      var flag = false;
      for (var i = 0; i < this.journeyMap.additionalattributes.relations.length; i++) {
        if (this.journeyMap.additionalattributes.relations[i].end_id === task.journeyStep.id && this.journeyMap.additionalattributes.relations[i].start_type === 'Phase') {
          flag = true;
          break;
        }
      }
      return flag;
    },
    displayPhaseDetails: function displayPhaseDetails(phase, index) {
      this.selectedPhaseIndex = index;
      this.selectedPhase = phase;
      this.$emit('phaseclick', this.selectedPhase, this.selectedPhaseIndex);
    },
    displayTaskDetails: function displayTaskDetails(task, index) {
      this.selectedTaskIndex = index;
      this.selectedTask = task;
      this.$emit('taskclick', this.selectedTask, this.selectedTaskIndex);
    },
    onPhaseMouseOver: function onPhaseMouseOver(phase, index) {
      this.selectedPhaseIndex = index;
      this.selectedPhase = phase;
      this.resetHoverDetailsDialog();
      // setTimeout(() => {
      this.phaseHover = true;
      this.$emit('phasemouseover', this.selectedPhase, this.selectedPhaseIndex, this.computePhaseStartX(this.selectedPhase, this.selectedPhaseIndex), this.computePhaseLength(this.selectedPhase, this.selectedPhaseIndex));
      // }, 600)
    },
    onPhaseMouseLeave: function onPhaseMouseLeave() {
      this.selectedPhaseIndex = null;
      this.selectedPhase = {};
      this.resetHoverDetailsDialog();
      // setTimeout(() => {
      this.phaseHover = false;
      this.$emit('phasemouseleave');
      // }, 600)
    },
    onTaskMouseOver: function onTaskMouseOver(task, index) {
      this.selectedTaskIndex = index;
      this.selectedTask = task;
      this.resetHoverDetailsDialog();
      // setTimeout(() => {
      this.taskHover = true;
      this.$emit('taskmouseover', this.selectedTask, this.selectedTaskIndex, this.computeTaskPosX(this.selectedTaskIndex), this.computeTaskPosY(this.selectedTaskIndex));
      // }, 600)
    },
    onTaskMouseLeave: function onTaskMouseLeave(task, index) {
      this.taskHover = false;
      this.$emit('taskmouseleave');
    },
    onJourneyContainerDragged: function onJourneyContainerDragged(_ref) {
      var el = _ref.el,
        deltaX = _ref.deltaX,
        deltaY = _ref.deltaY,
        offsetX = _ref.offsetX,
        offsetY = _ref.offsetY,
        clientX = _ref.clientX,
        clientY = _ref.clientY,
        first = _ref.first,
        last = _ref.last;
      if (last !== undefined && last) {
        return;
      }
      if (deltaX === undefined || deltaY === undefined) {
        return;
      }
      this.canvasDragCoefficientX += deltaX;
      this.$emit('drag-coefficient', this.canvasDragCoefficientX);
      this.computePhaseX();
      this.computeTaskX();
    },
    computePhaseStartX: function computePhaseStartX(phase, index) {
      if (this.phase_pos_x.length === 0) {
        this.computePhaseX();
      }
      return this.phase_pos_x[index];
    },
    // Temp code
    // To be merged with computePhaseStartX
    computePhasePosX: function computePhasePosX(index) {
      if (this.phase_pos_x.length === 0) {
        this.computePhaseX();
      }
      return this.phase_pos_x[index];
    },
    computePhaseX: function computePhaseX() {
      this.phase_pos_x = [];
      if (!this.sortedStages) {
        return this.phase_pos_x;
      }
      for (var i = 0; i < this.sortedStages.length; i++) {
        if (i === 0) {
          this.phase_pos_x.push(this.canvasDragCoefficientX + 175);
        } else {
          var length = 0;
          for (var j = 0; j < i; j++) {
            length += this.computePhaseLength(this.sortedStages[j].journeyPhase, j);
            length += 50;
          }
          this.phase_pos_x.push(this.canvasDragCoefficientX + 175 + length);
        }
      }

      // send back both phase pos and width
      this.computePhaseLengthArray();
      this.$emit('computedphasex', this.phase_pos_x);
    },
    computePhaseLength: function computePhaseLength(phase, index) {
      // get max number of features in product for given phase
      // if less than 2 use default 175, else use length off features
      var max_count = 0;

      // Get an array of pains/gains for current phase
      if (typeof phase === 'undefined') {
        return 225;
      }
      for (var i = 0; i < this.journeyMap.additionalattributes.relations.length; i++) {
        if (this.journeyMap.additionalattributes.relations[i].start_type === 'Phase' && this.journeyMap.additionalattributes.relations[i].end_type === 'Step' && this.journeyMap.additionalattributes.relations[i].start_id === phase.id) {
          max_count++;
        }
      }
      var additional_columns = Math.floor(max_count / 7) - 1;
      var first_column = 150;
      return 225 + first_column + additional_columns * 150;
    },
    computePhaseLengthArray: function computePhaseLengthArray() {
      var phase_width = [];
      for (var i = 0; i < this.sortedStages.length; i++) {
        phase_width.push(this.computePhaseLength(this.sortedStages[i].journeyPhase, i));
      }
      this.$emit('computedphasewidth', phase_width);
    },
    computeTaskX: function computeTaskX() {
      this.task_pos_x = [];
      if (this.journeyMap && this.journeyMap.linkedJourneySteps) {
        for (var n = 0; n < this.computedTaskList.length; n++) {
          for (var m = 0; m < this.journeyMap.additionalattributes.relations.length; m++) {
            if (this.journeyMap.additionalattributes.relations[m].end_type === 'Step' && this.journeyMap.additionalattributes.relations[m].start_type === 'Phase' && this.journeyMap.additionalattributes.relations[m].end_id === this.computedTaskList[n].journeyStep.id) {
              var phase_index = this.getPhaseAndIndexFromID(this.journeyMap.additionalattributes.relations[m].start_id)[1];
              var phase_start_x = this.computePhasePosX(phase_index) + 38;
              var relation_index = this.phaseStepRelation[phase_index].indexOf(this.journeyMap.additionalattributes.relations[m].end_id);
              this.task_pos_x.push(phase_start_x + 60 + Math.floor(relation_index / 7) * 7 * 21);
            }
          }
        }
      }
      this.$emit('computedtaskx', this.task_pos_x);
    },
    computeTaskY: function computeTaskY() {
      this.task_pos_y = [];
      var temp_relations = [];
      for (var n = 0; n < this.computedTaskList.length; n++) {
        for (var m = 0; m < this.journeyMap.additionalattributes.relations.length; m++) {
          // Filter out relations from phase to step.
          // This has all the steps and its corresponding phases. Easier to group under phases
          if (this.journeyMap.additionalattributes.relations[m].end_type === 'Step' && this.journeyMap.additionalattributes.relations[m].start_type === 'Phase' && this.journeyMap.additionalattributes.relations[m].end_id === this.computedTaskList[n].journeyStep.id && this.phaseStepRelation.length > 0) {
            temp_relations.push(this.journeyMap.additionalattributes.relations[m]);
          }
        }
      }
      for (var p = 0; p < temp_relations.length; p++) {
        var phase_index = this.getPhaseAndIndexFromID(temp_relations[p].start_id)[1];
        var relation_index = this.phaseStepRelation[phase_index].indexOf(temp_relations[p].end_id);
        this.task_pos_y.push(100 + relation_index % 7 * 35);
      }
      this.$emit('computedtasky', this.task_pos_y);
    },
    computeTaskPosX: function computeTaskPosX(index) {
      var recompute = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      if (this.computedTaskList.length === 0) {
        return 0;
      }
      if (this.task_pos_x.length === 0 || recompute) {
        this.computeTaskX();
      }
      return this.task_pos_x[index];
    },
    computeTaskPosY: function computeTaskPosY(index) {
      if (this.task_pos_y.length === 0) {
        this.computeTaskY();
      }
      return this.task_pos_y[index];
    },
    getTaskX: function getTaskX(task_id) {
      for (var j = 0; j < this.computedTaskList.length; j++) {
        if (this.computedTaskList[j].journeyStep.id === task_id) {
          var x = this.computeTaskPosX(j);
          return x + 20;
        }
      }
    },
    getTaskY: function getTaskY(task_id) {
      for (var j = 0; j < this.computedTaskList.length; j++) {
        if (this.computedTaskList[j].journeyStep.id === task_id) {
          var y = this.computeTaskPosY(j);
          return y + 40;
        }
      }
    },
    computeStepsForPhase: function computeStepsForPhase(phase_id) {
      var steps = [];
      for (var i = 0; i < this.computedRelations.length; i++) {
        // No need for end_id since phase is always start id
        if (this.computedRelations[i].start_id === phase_id && this.computedRelations[i].start_type === 'Phase' && this.computedRelations[i].end_type === 'Step') {
          steps.push(this.computedRelations[i].end_id);
        }
      }
      return steps;
    },
    computePhaseIndexforTask: function computePhaseIndexforTask(task, index) {
      for (var i = 0; i < this.computedRelations.length; i++) {
        if (this.computedRelations[i].end_id === task.journeyStep.id) {
          var phase_index = this.getPhaseAndIndexFromID(this.computedRelations[i].start_id)[1];
          return this.phaseStepRelation[phase_index].indexOf(task.journeyStep.id) + 1;
        }
      }
    },
    getPhaseAndIndexFromID: function getPhaseAndIndexFromID(phase_id) {
      for (var i = 0; i < this.sortedStages.length; i++) {
        if (this.sortedStages[i].journeyPhase.id === phase_id) {
          return [this.sortedStages[i].journeyPhase, i];
        }
      }
      return [{}, null];
    },
    computePhaseStepRelationArray: function computePhaseStepRelationArray() {
      this.phaseStepRelation = [];
      for (var i = 0; i < this.sortedStages.length; i++) {
        var steps = this.computeStepsForPhase(this.sortedStages[i].journeyPhase.id);
        this.phaseStepRelation[i] = new Array(steps.length);
        this.phaseStepRelation[i] = steps;
      }
    },
    resetHoverDetailsDialog: function resetHoverDetailsDialog() {},
    resetJourneyMap: function resetJourneyMap() {
      this.canvasDragCoefficientX = 0;
      this.$emit('drag-coefficient', this.canvasDragCoefficientX);
      this.computePhaseX();
      this.computeTaskX();
    }
  }
};