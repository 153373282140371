"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("g", {
    on: {
      click: _vm.emitClick
    }
  }, [_c("rect", {
    attrs: {
      x: _vm.x + 5,
      y: _vm.y + 5 + _vm.index * 35,
      width: _vm.width - 10,
      height: "30",
      rx: "5",
      fill: "white",
      stroke: "navy",
      "stroke-width": "2"
    }
  }), _vm._v(" "), _c("text", {
    attrs: {
      x: _vm.x + 5 + (_vm.width - 10) / 2,
      y: _vm.y + 22 + _vm.index * 35,
      "text-anchor": "middle",
      "font-size": "0.8em",
      fill: "black"
    }
  }, [_vm._v(_vm._s(_vm.computeGoalText()))])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;