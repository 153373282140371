"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("g", {
    attrs: {
      refs: "touchpoints-lane"
    }
  }, [_c("g", {
    attrs: {
      refs: "touchpoints-lane-background"
    }
  }, [_c("g", {
    attrs: {
      refs: "touchpoints-lane-background-border"
    }
  }, [_c("rect", {
    staticClass: "shadow",
    attrs: {
      x: _vm.x - 2,
      y: _vm.y - 1,
      width: 2,
      height: _vm.height + 2,
      fill: "#447799",
      "stroke-width": "1",
      stroke: "#eee"
    }
  }), _vm._v(" "), _c("rect", {
    staticClass: "shadow",
    attrs: {
      x: _vm.x,
      y: _vm.y,
      rx: "5",
      width: _vm.width,
      height: _vm.height,
      fill: "white",
      "stroke-width": "1",
      stroke: "#eee"
    }
  })]), _vm._v(" "), _c("rect", {
    attrs: {
      x: _vm.x,
      y: _vm.y,
      width: _vm.width - 5,
      height: _vm.height,
      fill: "rgba(254,254,255,0.9)",
      "stroke-width": "1.0",
      stroke: "#f1f4ff"
    }
  }), _vm._v(" "), _c("g", {
    attrs: {
      refs: "touchpoints-lane-header"
    }
  }, [_c("rect", {
    attrs: {
      x: _vm.x,
      y: _vm.y,
      width: _vm.isCompactMenubar ? 28 : 140,
      height: _vm.height,
      fill: "rgba(200,200,200,0.15)",
      "stroke-width": "0",
      stroke: "#aaa"
    }
  }), _vm._v(" "), !_vm.isCompactMenubar ? _c("rect", {
    attrs: {
      x: _vm.x,
      y: _vm.y,
      rx: "2",
      width: 140,
      height: 22,
      fill: "rebeccapurple",
      "stroke-width": "1",
      stroke: "rebeccapurple"
    }
  }) : _vm._e(), _vm._v(" "), _c("text", {
    style: _vm.computeStyle(),
    attrs: {
      x: _vm.x + 15,
      y: _vm.isCompactMenubar ? _vm.y + 85 : _vm.y + 15,
      "text-anchor": "start",
      "font-size": ".95em",
      transform: _vm.computeTransform()
    },
    on: {
      click: function click($event) {
        return _vm.displayLabelPrompt();
      }
    }
  }, [_vm._v("\n        " + _vm._s(_vm.label) + "\n      ")])]), _vm._v(" "), !_vm.isCompactMenubar ? _c("g", {
    attrs: {
      refs: "touchpoints-tools"
    }
  }, _vm._l(_vm.touchpoints, function (touchpoint, index) {
    return _c("g", {
      key: index
    }, [touchpoint.type === "sms" ? _c("foreignObject", {
      directives: [{
        name: "dragged",
        rawName: "v-dragged",
        value: _vm.onTouchpointToolDragged,
        expression: "onTouchpointToolDragged"
      }],
      attrs: {
        id: "tool_sms_" + index,
        x: _vm.tools_pos_x[index],
        y: _vm.tools_pos_y[index],
        width: 20,
        height: 20,
        fill: "none"
      }
    }, [_c("div", {
      staticClass: "ujm-swimlane-resize-icon"
    }, [_c("svg-icon", {
      staticStyle: {
        "font-size": "20px",
        color: "#304174"
      },
      attrs: {
        "icon-class": "sms"
      }
    })], 1)]) : _vm._e(), _vm._v(" "), touchpoint.type === "web" ? _c("foreignObject", {
      directives: [{
        name: "dragged",
        rawName: "v-dragged",
        value: _vm.onTouchpointToolDragged,
        expression: "onTouchpointToolDragged"
      }],
      attrs: {
        id: "tool_web_" + index,
        x: _vm.tools_pos_x[index],
        y: _vm.tools_pos_y[index],
        width: 20,
        height: 20,
        fill: "none"
      }
    }, [_c("div", {
      staticClass: "ujm-swimlane-resize-icon"
    }, [_c("svg-icon", {
      staticStyle: {
        "font-size": "20px",
        color: "#304174"
      },
      attrs: {
        "icon-class": "international"
      }
    })], 1)]) : _vm._e(), _vm._v(" "), touchpoint.type === "chat" ? _c("foreignObject", {
      directives: [{
        name: "dragged",
        rawName: "v-dragged",
        value: _vm.onTouchpointToolDragged,
        expression: "onTouchpointToolDragged"
      }],
      attrs: {
        id: "tool_chat_" + index,
        x: _vm.tools_pos_x[index],
        y: _vm.tools_pos_y[index],
        width: 20,
        height: 20,
        fill: "none"
      }
    }, [_c("div", {
      staticClass: "ujm-swimlane-resize-icon"
    }, [_c("svg-icon", {
      staticStyle: {
        "font-size": "20px",
        color: "#304174"
      },
      attrs: {
        "icon-class": "wechat"
      }
    })], 1)]) : _vm._e(), _vm._v(" "), touchpoint.type === "social" ? _c("foreignObject", {
      directives: [{
        name: "dragged",
        rawName: "v-dragged",
        value: _vm.onTouchpointToolDragged,
        expression: "onTouchpointToolDragged"
      }],
      attrs: {
        id: "tool_social_" + index,
        x: _vm.tools_pos_x[index],
        y: _vm.tools_pos_y[index],
        width: 20,
        height: 20,
        fill: "none"
      }
    }, [_c("div", {
      staticClass: "ujm-swimlane-resize-icon"
    }, [_c("svg-icon", {
      staticStyle: {
        "font-size": "20px",
        color: "#304174"
      },
      attrs: {
        "icon-class": "channel"
      }
    })], 1)]) : _vm._e(), _vm._v(" "), touchpoint.type === "phone" ? _c("foreignObject", {
      directives: [{
        name: "dragged",
        rawName: "v-dragged",
        value: _vm.onTouchpointToolDragged,
        expression: "onTouchpointToolDragged"
      }],
      attrs: {
        id: "tool_phone_" + index,
        x: _vm.tools_pos_x[index],
        y: _vm.tools_pos_y[index],
        width: 20,
        height: 20,
        fill: "none"
      }
    }, [_c("div", {
      staticClass: "ujm-swimlane-resize-icon"
    }, [_c("i", {
      staticClass: "el-icon-phone",
      staticStyle: {
        "font-size": "20px",
        color: "#304174"
      }
    })])]) : _vm._e(), _vm._v(" "), touchpoint.type === "app" ? _c("foreignObject", {
      directives: [{
        name: "dragged",
        rawName: "v-dragged",
        value: _vm.onTouchpointToolDragged,
        expression: "onTouchpointToolDragged"
      }],
      attrs: {
        id: "tool_app_" + index,
        x: _vm.tools_pos_x[index],
        y: _vm.tools_pos_y[index],
        width: 20,
        height: 20,
        fill: "none"
      }
    }, [_c("div", {
      staticClass: "ujm-swimlane-resize-icon"
    }, [_c("svg-icon", {
      staticStyle: {
        "font-size": "20px",
        color: "#304174"
      },
      attrs: {
        "icon-class": "app"
      }
    })], 1)]) : _vm._e(), _vm._v(" "), touchpoint.type === "email" ? _c("foreignObject", {
      directives: [{
        name: "dragged",
        rawName: "v-dragged",
        value: _vm.onTouchpointToolDragged,
        expression: "onTouchpointToolDragged"
      }],
      attrs: {
        id: "tool_email_" + index,
        x: _vm.tools_pos_x[index],
        y: _vm.tools_pos_y[index],
        width: 20,
        height: 20,
        fill: "none"
      }
    }, [_c("div", {
      staticClass: "ujm-swimlane-resize-icon"
    }, [_c("svg-icon", {
      staticStyle: {
        "font-size": "20px",
        color: "#304174"
      },
      attrs: {
        "icon-class": "email"
      }
    })], 1)]) : _vm._e()]);
  }), 0) : _vm._e()]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "touchpoints-container"
    }
  }, _vm._l(_vm.stages, function (stage, index) {
    return _c("g", {
      key: index,
      attrs: {
        refs: "touchpoint"
      }
    }, [_vm.journeyMap.additionalattributes && _vm.journeyMap.additionalattributes.layouts && _vm.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[index].touchpoints && _vm.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[index].touchpoints.length > 0 ? _c("g", _vm._l(_vm.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[index].touchpoints, function (touchpoint, touchpoint_index) {
      return _c("g", {
        key: touchpoint_index,
        attrs: {
          refs: "touchpoint"
        }
      }, [_c("touchpoint-component", {
        attrs: {
          touchpoint: touchpoint,
          x: 5 + _vm.phaseX[index] + 10 + _vm.touchpointColIncrement(touchpoint_index) * 119,
          y: 5 + _vm.y + _vm.touchpointRowIncrement(touchpoint_index) * 34,
          "stage-index": index,
          "touchpoint-index": touchpoint_index
        },
        on: {
          mouseover: _vm.onTouchpointMouseOver,
          mouseleave: _vm.onTouchpointMouseLeave,
          click: _vm.showTouchpointDetails
        }
      })], 1);
    }), 0) : _vm._e()]);
  }), 0)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;