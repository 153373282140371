"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _axios = _interopRequireDefault(require("axios"));
// import CommentComponent from '@/components/Comment'
var _default2 = exports.default = {
  name: 'Actions',
  components: {
    // CommentComponent
  },
  props: {
    entity: {
      type: Object,
      default: function _default() {
        return {
          id: '',
          type: '',
          assignedTo: '',
          createdBy: ''
        };
      }
    },
    columns: {
      type: Object,
      default: function _default() {
        return {
          id: true,
          name: true,
          version: true,
          status: true,
          priority: true,
          due: true,
          created: true,
          assigned: true
        };
      }
    }
  },
  data: function data() {
    return {
      addArtefactActionDialog: false,
      actions: [],
      users: [],
      discussions: [],
      selectedAction: {},
      comment: '',
      artefactActionForm: {
        name: '',
        version: '',
        status: '',
        priority: 'MEDIUM',
        assignedTo: '',
        dueBy: ''
      }
    };
  },
  computed: {},
  watch: {
    entity: {
      handler: function handler(newValue) {
        this.reload();
      },
      deep: true
    }
  },
  mounted: function mounted() {
    this.reload();
  },
  created: function created() {},
  methods: {
    reload: function reload() {
      this.retrieveActions();
      this.retrieveUserList();
    },
    getUserFromUserID: function getUserFromUserID(user_id) {
      for (var i = 0; i < this.users.length; i++) {
        if (this.users[i].id === user_id) {
          return this.computeUserName(this.users[i]);
        }
      }
    },
    computeUserName: function computeUserName(user) {
      return user.firstName + ' ' + user.lastName;
    },
    retrieveUserList: function retrieveUserList() {
      var _this = this;
      this.loading = true;
      var headers = {
        'Content-Type': 'application/json',
        'x-party-id': this.$store.getters.partyId,
        'x-user-id': this.$store.getters.id
      };
      _axios.default.get(process.env.VUE_APP_REST_API_ENDPOINT + 'user/?extended=LEVEL0', {
        headers: headers
      }).then(function (response) {
        _this.loading = false;
        _this.users = response.data.data;
      }).catch(function (error) {
        if (error.response.status === 400) {
          _this.loading = false;
          _this.$notify({
            title: 'Error',
            message: error.response.message,
            type: 'error',
            duration: 2000
          });
        }
      });
    },
    retrieveActions: function retrieveActions() {
      var _this2 = this;
      this.loading = true;
      var headers = {
        'Content-Type': 'application/json',
        'x-party-id': this.$store.getters.partyId,
        'x-user-id': this.$store.getters.id
      };
      _axios.default.get(process.env.VUE_APP_REST_API_ENDPOINT + 'action/?extended=LEVEL0&' + this.computeActionRetrivalQuery(), {
        headers: headers
      }).then(function (response) {
        _this2.loading = false;
        _this2.actions = response.data.data;
        _this2.selectedAction = _this2.actions[0];
        _this2.retrieveActionComments();
      }).catch(function (error) {
        if (error.response.status === 400) {
          _this2.loading = false;
          _this2.actions = [];
        } else {
          _this2.$notify({
            title: 'Error',
            message: error.response.message,
            type: 'error',
            duration: 2000
          });
        }
      });
    },
    computeActionRetrivalQuery: function computeActionRetrivalQuery() {
      var query = '';
      if (this.entity.id) {
        query += 'entityId=' + this.entity.id + '&';
      }
      if (this.entity.type) {
        query += 'entityName=' + this.entity.type + '&';
      }
      if (this.entity.assignedTo) {
        query += 'assignedTo=' + this.entity.assignedTo + '&';
      }
      if (this.entity.createdBy) {
        query += 'createdBy=' + this.entity.createdBy + '&';
      }
      return query.substring(0, query.length - 1);
    },
    selectAction: function selectAction(row, column, event) {
      this.selectedAction = row;
      this.retrieveActionComments();
    },
    showArtefactActionDialog: function showArtefactActionDialog() {
      this.addArtefactActionDialog = true;
    },
    addArtefactAction: function addArtefactAction() {
      var _this3 = this;
      var action = Object.assign({}, this.artefactActionForm);
      action.status = 'NOTSTARTED';
      action.createdAt = this.$moment().format('YYYY-MM-DD HH:mm:ss');
      this.loading = true;
      var headers = {
        'Content-Type': 'application/json'
        // 'Authorization': 'Bearer '+getJWTToken()
      };
      var create_body = {
        data: [{
          'entityId': this.entity.id,
          'entityName': 'TICKET',
          'name': action.name,
          'version': action.version,
          'status': action.status,
          'priority': action.priority,
          'assignedTo': action.assignedTo,
          'dueBy': this.$moment(action.dueBy).format('DD/MM/YYYY')
        }],
        'header': {
          'userId': this.$store.getters.id,
          'partyId': this.$store.getters.partyId
        }
      };
      _axios.default.post(process.env.VUE_APP_REST_API_ENDPOINT + 'action', create_body, {
        headers: headers
      }).then(function (response) {
        _this3.loading = false;
        if (response.data.data.status === 'SUCCESS') {
          // this.$emit('add_action', action)
          _this3.retrieveActions();
          _this3.addArtefactActionDialog = false;
          _this3.resetArtefactActionForm();
        }
      }).catch(function (error) {
        _this3.loading = false;
        console.log(error);
      });
    },
    resetArtefactActionForm: function resetArtefactActionForm() {
      this.artefactActionForm = {
        name: '',
        version: '',
        status: '',
        priority: 'MEDIUM',
        assignedTo: '',
        dueBy: ''
      };
    },
    computeActionStatusTagType: function computeActionStatusTagType(status) {
      switch (status) {
        case 'Not started':
        case 'Discussion':
          return 'info';
        case 'In progress':
          return '';
        case 'Blocked':
          return 'danger';
        case 'Monitoring':
          return 'warning';
        case 'Done':
          return 'success';
      }
    },
    computePriorityTagType: function computePriorityTagType(priority) {
      switch (priority) {
        case 'HIGH':
        case 'CRITICAL':
          return 'danger';
        case 'LOW':
        case 'MEDIUM':
          return 'info';
      }
    },
    computeHumanFriendlyDate: function computeHumanFriendlyDate(date) {
      return this.$moment(date, 'YYYY-MM-DD HH:mm:ss').format('ll');
    },
    checkIfUserIsAuthor: function checkIfUserIsAuthor(author) {
      var username = this.$store.getters.firstName + ' ' + this.$store.getters.lastName;
      if (username === author) {
        return true;
      }
    },
    addComment: function addComment() {
      var _this4 = this;
      var headers = {
        'Content-Type': 'application/json'
        // 'Authorization': 'Bearer '+getJWTToken()
      };
      var create_body = {
        data: [{
          'entityId': this.selectedAction.id,
          'entityName': 'ACTION',
          'comment': this.comment
        }],
        'header': {
          'userId': this.$store.getters.id,
          'partyId': this.$store.getters.partyId
        }
      };
      _axios.default.post(process.env.VUE_APP_REST_API_ENDPOINT + 'social/comment', create_body, {
        headers: headers
      }).then(function (response) {
        if (response.data.data.status === 'SUCCESS') {
          _this4.comment = '';
          _this4.retrieveActionComments();
        }
      }).catch(function (error) {
        console.log(error);
      });
    },
    retrieveActionComments: function retrieveActionComments() {
      var _this5 = this;
      this.loading = true;
      var headers = {
        'Content-Type': 'application/json',
        'x-party-id': this.$store.getters.partyId,
        'x-user-id': this.$store.getters.id
      };
      _axios.default.get(process.env.VUE_APP_REST_API_ENDPOINT + 'social/comment/entity/' + this.selectedAction.id + '?extended=LEVEL0&page=1', {
        headers: headers
      }).then(function (response) {
        _this5.loading = false;
        _this5.discussions = response.data.data;
      }).catch(function (error) {
        if (error.response.status === 400) {
          _this5.loading = false;
          _this5.discussions = [];
        } else {
          _this5.$notify({
            title: 'Error',
            message: error.response.message,
            type: 'error',
            duration: 2000
          });
        }
      });
    },
    computeMessengerAvatar: function computeMessengerAvatar(user_id) {
      for (var i = 0; i < this.users.length; i++) {
        if (this.users[i].id === user_id) {
          if (this.users[i].imageURL) {
            return 'background-image: url(' + this.users[i].imageURL + ')';
          }
        }
      }
      return '';
    },
    computeHumanFriendlyDateDifference: function computeHumanFriendlyDateDifference(date) {
      var today = this.$moment();
      return this.$moment.tz(date, 'Australia/Sydney').from(today);
    }
  }
};