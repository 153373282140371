"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("g", {
    attrs: {
      refs: "phase_details_popover_container"
    },
    on: {
      mouseleave: _vm.closePhaseDetailsPopover
    }
  }, [_c("filter", {
    attrs: {
      id: "blur"
    }
  }, [_c("feDropShadow", {
    attrs: {
      dx: "5",
      dy: "5",
      "std-deviation": "10",
      "flood-opacity": "0.5"
    }
  })], 1), _vm._v(" "), _c("rect", {
    staticClass: "backdrop",
    attrs: {
      x: _vm.x,
      y: _vm.y,
      rx: 3,
      width: _vm.width,
      height: _vm.computeHeight,
      fill: "#126661",
      stroke: "#126661",
      "stroke-width": "0"
    }
  }), _vm._v(" "), _c("rect", {
    attrs: {
      x: _vm.x,
      y: _vm.y,
      width: _vm.width,
      height: _vm.computeHeight,
      fill: "#fff"
    }
  }), _vm._v(" "), _c("g", {
    attrs: {
      refs: "phase_details_popover_title"
    }
  }, [_c("rect", {
    staticClass: "phase_title",
    attrs: {
      x: _vm.x,
      y: _vm.y,
      width: _vm.width,
      height: 30,
      fill: "#112266"
    }
  }), _vm._v(" "), _c("svg-icon", {
    staticStyle: {
      "font-size": "12px",
      fill: "#fff"
    },
    attrs: {
      "icon-class": "phase",
      x: _vm.x + 5,
      y: _vm.y + 2,
      width: 26,
      height: "26"
    }
  }), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "#FFF"
    },
    attrs: {
      x: _vm.x + 36,
      y: _vm.y + 20,
      "text-anchor": "start",
      "font-size": "1.05em",
      "font-weight": "bold"
    }
  }, [_vm._v(_vm._s(_vm.truncateString(_vm.phase.title, 40)))]), _vm._v(" "), !_vm.readOnly ? _c("g", {
    attrs: {
      refs: "phase_ideas_popover_title_detail"
    },
    on: {
      click: function click($event) {
        $event.preventDefault();
        return _vm.initiateCreatePhaseIdea();
      }
    }
  }, [_c("rect", {
    staticClass: "buttonhover",
    attrs: {
      x: _vm.x + _vm.width - 210,
      y: _vm.y + 5.5,
      width: 54,
      height: 18,
      rx: 3,
      ry: 3,
      "stroke-width": "1",
      stroke: "lightgrey",
      fill: "ghostwhite"
    }
  }), _vm._v(" "), _c("text", {
    staticClass: "textpointer",
    staticStyle: {
      fill: "#304174"
    },
    attrs: {
      x: _vm.x + _vm.width - 185,
      y: _vm.y + 19,
      "text-anchor": "middle",
      "font-size": "0.9em",
      "font-weight": "normal"
    }
  }, [_vm._v("⊕ Idea")])]) : _vm._e(), _vm._v(" "), !_vm.readOnly ? _c("g", {
    attrs: {
      refs: "phase_progress_popover_title_detail"
    },
    on: {
      click: function click($event) {
        $event.preventDefault();
        return _vm.displayPhaseProgress();
      }
    }
  }, [_c("rect", {
    staticClass: "buttonhover",
    attrs: {
      x: _vm.x + _vm.width - 150,
      y: _vm.y + 5.5,
      width: 54,
      height: 18,
      rx: 3,
      ry: 3,
      "stroke-width": "1",
      stroke: "lightgrey",
      fill: "ghostwhite"
    }
  }), _vm._v(" "), _c("text", {
    staticClass: "textpointer",
    staticStyle: {
      fill: "#304174"
    },
    attrs: {
      x: _vm.x + _vm.width - 122,
      y: _vm.y + 19,
      "text-anchor": "middle",
      "font-size": "0.9em",
      "font-weight": "normal"
    }
  }, [_vm._v("Progress")])]) : _vm._e(), _vm._v(" "), _c("g", {
    attrs: {
      refs: "phase_details_popover_title_details"
    }
  }, [_c("foreignObject", {
    attrs: {
      x: _vm.x + _vm.width - 90,
      y: _vm.y + 2,
      width: 24,
      height: 24
    }
  }, [_c("el-button", {
    staticStyle: {
      width: "18pt",
      height: "18pt",
      "font-size": "1.1em",
      padding: "1pt 0 0 0"
    },
    attrs: {
      type: "secondary",
      size: "mini",
      circle: "",
      icon: "el-icon-edit"
    },
    on: {
      click: function click($event) {
        $event.preventDefault();
        return _vm.displayPhaseDetails();
      }
    }
  })], 1)]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "phase_details_popover_title_close"
    },
    on: {
      click: function click($event) {
        $event.preventDefault();
        return _vm.closePhaseDetailsPopover();
      }
    }
  }, [_c("rect", {
    attrs: {
      x: _vm.x + _vm.width - 30,
      y: _vm.y + 2.5,
      rx: 15,
      ry: 15,
      width: 24,
      height: 24,
      fill: "darkred"
    }
  }), _vm._v(" "), _c("foreignObject", {
    attrs: {
      x: _vm.x + _vm.width - 30,
      y: _vm.y + 5.5,
      width: 24,
      height: 24
    }
  }, [_c("div", {
    staticClass: "text-center textpointer",
    staticStyle: {
      color: "#FFF",
      "padding-top": "2pt",
      "padding-left": "-1pt"
    }
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "12px"
    },
    attrs: {
      "icon-class": "cross"
    }
  })], 1)])]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "phase_details_popover_title_delete"
    }
  }, [_c("foreignObject", {
    attrs: {
      x: _vm.x + _vm.width - 60,
      y: _vm.y + 2,
      width: 24,
      height: 24
    }
  }, [_c("el-button", {
    staticStyle: {
      "background-color": "darkred",
      "border-color": "darkred",
      width: "18pt",
      height: "18pt",
      "font-size": "1.1em",
      padding: "1pt 0 0 0"
    },
    attrs: {
      type: "danger",
      size: "mini",
      circle: "",
      icon: "el-icon-delete"
    },
    on: {
      click: function click($event) {
        $event.preventDefault();
        return _vm.deletePhase();
      }
    }
  })], 1)])], 1), _vm._v(" "), _c("g", {
    attrs: {
      refs: "phase_details_popover_tools"
    }
  }, [_c("g", {
    attrs: {
      refs: "experience_details_popover_tools_experience_pain"
    }
  }, [_c("text", {
    staticStyle: {
      fill: "#0070c0"
    },
    attrs: {
      x: _vm.x + 10,
      y: _vm.y + 50,
      "text-anchor": "start",
      "font-size": "1em",
      "font-weight": "bold"
    }
  }, [_vm._v("Stage Description:")]), _vm._v(" "), _c("foreignObject", {
    attrs: {
      x: _vm.x + 10,
      y: _vm.y + 60,
      width: _vm.width * 0.57,
      height: "90"
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "0.9em",
      fill: "#0070c0",
      "font-weight": "normal",
      color: "#0070c0",
      height: "70px",
      "max-height": "70pt",
      "overflow-y": "hidden",
      "text-align": "justify"
    }
  }, [_vm._v("\n          " + _vm._s(_vm.trimString(_vm.phase.description, 340)) + "\n        ")])])]), _vm._v(" "), _c("g", [_c("foreignObject", {
    staticStyle: {
      "text-align": "center",
      "font-size": "1.0em",
      "font-weight": "normal"
    },
    attrs: {
      x: _vm.x + _vm.width - 150,
      y: _vm.y + 33.5,
      width: 40,
      height: 22
    }
  }, [_vm.ideas.length > 0 ? _c("div", {
    staticStyle: {
      "margin-top": "0px",
      color: "#E6A23C",
      "font-size": "1.0em",
      "font-weight": "100"
    }
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "14px",
      color: "#E6A23C",
      "margin-top": "4px"
    },
    attrs: {
      "icon-class": "idea"
    }
  }), _vm._v(" "), _c("span", {
    staticStyle: {
      "margin-left": "2px",
      "font-size": "1.0em"
    }
  }, [_vm._v(_vm._s(_vm.ideas.length))])], 1) : _vm._e()]), _vm._v(" "), _c("foreignObject", {
    staticStyle: {
      "text-align": "center",
      "font-size": "1.0em",
      "font-weight": "normal"
    },
    attrs: {
      x: _vm.x + _vm.width - 100,
      y: _vm.y + 37,
      width: 48,
      height: 22
    }
  }, [_c("div", {
    staticStyle: {
      "border-radius": "10pt",
      border: "solid 1pt lightgrey",
      height: "10pt",
      "font-size": "0.8em"
    }
  }, [_vm._v(" " + _vm._s(_vm.phase.priority) + " ")])]), _vm._v(" "), _c("foreignObject", {
    staticStyle: {
      "text-align": "center",
      "font-size": "1.3em",
      "font-weight": "normal"
    },
    attrs: {
      x: _vm.x + _vm.width - 50,
      y: _vm.y + 37,
      width: 40,
      height: 22
    }
  }, [_c("svg-icon", {
    class: _vm.computeValueFontSize(_vm.phase.importance),
    staticStyle: {
      color: "blue",
      "margin-top": "1px"
    },
    attrs: {
      "icon-class": _vm.computeCustomerValueIcon(_vm.phase.importance)
    }
  })], 1)])]), _vm._v(" "), _c("line", {
    attrs: {
      x1: _vm.x,
      y1: _vm.y + 140,
      x2: _vm.x + _vm.width,
      y2: _vm.y + 140,
      fill: "#126661",
      "stroke-width": "1",
      stroke: "#126661"
    }
  }), _vm._v(" "), _c("g", {
    attrs: {
      refs: "phase_details_popover_details"
    }
  }, [_c("g", {
    attrs: {
      refs: "phase_details_popover_details_steps"
    }
  }, [_c("text", {
    staticStyle: {
      fill: "#0070c0"
    },
    attrs: {
      x: _vm.x + 10,
      y: _vm.y + 162,
      "text-anchor": "start",
      "font-size": "1.0em",
      "font-weight": "bolder"
    }
  }, [_vm._v("Steps within Stage")]), _vm._v(" "), _vm.stepsList.length > 1 ? _c("g", {
    attrs: {
      refs: "phase_details_popover_tools_step_pagination"
    }
  }, _vm._l(_vm.stepsList, function (stepChunk, step_chunk_index) {
    return _c("g", {
      key: step_chunk_index,
      attrs: {
        refs: "phase_details_popover_tools_step_pagination_items"
      }
    }, [_c("g", {
      attrs: {
        refs: "phase_details_popover_tools_step_pagination_arrow_nav"
      }
    }, [_c("foreignObject", {
      attrs: {
        x: _vm.x + _vm.width / 2 - 50,
        y: _vm.y + 150,
        width: 15,
        height: 15
      }
    }, [_vm.activeStepChunk > 0 ? _c("div", {
      staticClass: "text-center",
      on: {
        click: function click($event) {
          $event.preventDefault();
          $event.stopPropagation();
          _vm.activeStepChunk = _vm.activeStepChunk - 1;
        }
      }
    }, [_c("i", {
      staticClass: "el-icon-caret-left",
      on: {
        click: function click($event) {
          $event.preventDefault();
          $event.stopPropagation();
          _vm.activeStepChunk = _vm.activeStepChunk - 1;
        }
      }
    })]) : _vm._e()]), _vm._v(" "), _c("text", {
      staticStyle: {
        cursor: "pointer"
      },
      attrs: {
        x: _vm.x + _vm.width / 2,
        y: _vm.y + 160,
        "text-anchor": "middle",
        "font-size": "1em",
        "font-weight": "normal"
      }
    }, [_vm._v("\n              Page " + _vm._s(_vm.activeStepChunk + 1) + " / " + _vm._s(_vm.stepsList.length) + "\n            ")]), _vm._v(" "), _c("foreignObject", {
      attrs: {
        x: _vm.x + _vm.width / 2 + 50,
        y: _vm.y + 150,
        width: 15,
        height: 15
      }
    }, [_vm.activeStepChunk < _vm.stepsList.length - 1 ? _c("div", {
      staticClass: "text-center",
      on: {
        click: function click($event) {
          $event.stopPropagation();
          $event.preventDefault();
          _vm.activeStepChunk = _vm.activeStepChunk + 1;
        }
      }
    }, [_c("i", {
      staticClass: "el-icon-caret-right",
      on: {
        click: function click($event) {
          $event.stopPropagation();
          $event.preventDefault();
          _vm.activeStepChunk = _vm.activeStepChunk + 1;
        }
      }
    })]) : _vm._e()])])]);
  }), 0) : _vm._e(), _vm._v(" "), !_vm.readOnly ? _c("g", {
    attrs: {
      refs: "phase_details_popover_tools_step"
    },
    on: {
      mouseover: function mouseover($event) {
        _vm.stepHover = true;
      },
      mouseleave: function mouseleave($event) {
        _vm.stepHover = false;
      },
      click: _vm.createTask
    }
  }, [_c("rect", {
    staticClass: "buttonhover",
    attrs: {
      x: _vm.x + _vm.width * 0.8,
      y: _vm.y + 144,
      rx: 3,
      ry: 3,
      width: 100,
      height: 26,
      stroke: "lightgrey",
      "stroke-width": "1",
      fill: "ghostwhite"
    }
  }), _vm._v(" "), _c("foreignObject", {
    attrs: {
      x: _vm.x + _vm.width * 0.8 + 2,
      y: _vm.y + 145,
      width: 30,
      height: 30
    }
  }, [_c("div", {
    staticClass: "text-center buttonhover",
    staticStyle: {
      color: "#4672c4"
    }
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "24px"
    },
    attrs: {
      "icon-class": "ujm-step"
    }
  })], 1)]), _vm._v(" "), _c("text", {
    staticClass: "textpointer",
    staticStyle: {
      fill: "#000"
    },
    attrs: {
      x: _vm.x + _vm.width * 0.8 + 35,
      y: _vm.y + 162,
      "text-anchor": "start",
      "font-size": "0.9em",
      "font-weight": "normal"
    }
  }, [_vm._v("Add Step")])]) : _vm._e(), _vm._v(" "), _vm._l(_vm.stepsList, function (stepChunk, step_chunk_index) {
    return _c("g", {
      key: step_chunk_index,
      attrs: {
        refs: "phase_details_popover_details_steps_chunk_list"
      }
    }, _vm._l(stepChunk, function (step, step_index) {
      return step_chunk_index === _vm.activeStepChunk ? _c("g", {
        key: step_chunk_index + _vm._ + step_index,
        attrs: {
          refs: "phase_details_popover_details_steps_list"
        }
      }, [_c("rect", {
        attrs: {
          x: _vm.x + 10,
          y: _vm.y + 175 + step_index * 30,
          width: 30,
          height: 25,
          rx: 15,
          ry: 15,
          "stroke-width": "0.5",
          stroke: "lightgrey",
          fill: "#FFF"
        }
      }), _vm._v(" "), _c("text", {
        staticStyle: {
          fill: "grey"
        },
        attrs: {
          x: _vm.x + 25,
          y: _vm.y + 192 + step_index * 30,
          "text-anchor": "middle",
          "font-size": "1em",
          "font-weight": "normal"
        }
      }, [_vm._v(_vm._s(step_chunk_index * 5 + step_index + 1))]), _vm._v(" "), _c("rect", {
        attrs: {
          x: _vm.x + 50,
          y: _vm.y + 175 + step_index * 30,
          width: _vm.width - 113,
          height: 25,
          rx: 1,
          ry: 1,
          "stroke-width": "0.5",
          stroke: "lightgrey",
          fill: "#fff"
        }
      }), _vm._v(" "), _c("foreignObject", {
        staticStyle: {
          fill: "#0070c0"
        },
        attrs: {
          x: _vm.x + 60,
          y: _vm.y + 169 + step_index * 30,
          width: _vm.width - 140,
          height: "25",
          "font-weight": "normal"
        }
      }, [_c("div", {
        staticStyle: {
          "margin-top": "10pt",
          color: "#0070c0",
          overflow: "hidden",
          "font-size": "0.9em"
        }
      }, [_vm._v("\n              " + _vm._s(_vm.truncateString(step.journeyStep.title, 60)) + "\n            ")])]), _vm._v(" "), _c("g", {
        attrs: {
          refs: "phase_details_popover_details_steps_down"
        }
      }, [_c("foreignObject", {
        attrs: {
          x: _vm.x + _vm.width - 60,
          y: _vm.y + 190 + step_index * 30,
          width: 18,
          height: 18
        }
      }, [_c("div", {
        staticClass: "text-center",
        staticStyle: {
          stroke: "#fff",
          height: "18pt",
          padding: "1.5pt 0 0 1pt",
          "font-size": "1.0em"
        }
      }, [_c("svg-icon", {
        staticClass: "textNoClick",
        attrs: {
          opacity: "0.1",
          "icon-class": "arrow-expand"
        }
      })], 1)]), _vm._v(" "), step_index < stepChunk.length - 1 ? _c("foreignObject", {
        attrs: {
          x: _vm.x + _vm.width - 60,
          y: _vm.y + 190 + step_index * 30,
          width: 18,
          height: 18
        }
      }, [_c("div", {
        staticClass: "text-center",
        staticStyle: {
          color: "#FFF",
          height: "18pt",
          padding: "1.5pt 0 0 1pt",
          "font-size": "1.0em"
        }
      }, [_c("svg-icon", {
        staticClass: "textpointer",
        attrs: {
          "icon-class": "arrow-expand"
        },
        on: {
          click: function click($event) {
            $event.preventDefault();
            return _vm.moveStepDown(step_index);
          }
        }
      })], 1)]) : _vm._e()]), _vm._v(" "), _c("g", {
        attrs: {
          refs: "phase_details_popover_details_steps_up"
        }
      }, [step_index === 0 ? _c("foreignObject", {
        attrs: {
          x: _vm.x + _vm.width - 60,
          y: _vm.y + 178 + step_index * 30,
          width: 18,
          height: 18
        }
      }, [_c("div", {
        staticClass: "text-center",
        staticStyle: {
          stroke: "#fff",
          padding: "1.5pt 0 0 1pt",
          "font-size": "1.0em"
        }
      }, [_c("svg-icon", {
        staticClass: "textNoClick",
        attrs: {
          opacity: "0.1",
          "icon-class": "arrow-shrink"
        }
      })], 1)]) : _vm._e(), _vm._v(" "), step_index > 0 ? _c("foreignObject", {
        attrs: {
          x: _vm.x + _vm.width - 60,
          y: _vm.y + 178 + step_index * 30,
          width: 18,
          height: 18
        }
      }, [_c("div", {
        staticClass: "text-center",
        staticStyle: {
          color: "#FFF",
          height: "18pt",
          padding: "1.5pt 0 0 1pt",
          "font-size": "1.0em"
        }
      }, [_c("svg-icon", {
        staticClass: "textpointer",
        attrs: {
          "icon-class": "arrow-shrink"
        },
        on: {
          click: function click($event) {
            $event.preventDefault();
            return _vm.moveStepUp(step_index);
          }
        }
      })], 1)]) : _vm._e()]), _vm._v(" "), !_vm.readOnly ? _c("g", {
        attrs: {
          refs: "phase_details_popover_details_steps_delete"
        }
      }, [_c("foreignObject", {
        attrs: {
          x: _vm.x + _vm.width - 33.5,
          y: _vm.y + 178 + step_index * 30,
          width: 20,
          height: 20
        }
      }, [_c("el-button", {
        staticStyle: {
          "background-color": "white",
          "border-color": "darkred",
          color: "darkred",
          width: "15pt",
          height: "15pt",
          "font-size": "1.1em",
          padding: "1pt 0 0 0"
        },
        attrs: {
          type: "danger",
          size: "mini",
          circle: "",
          icon: "el-icon-delete"
        },
        on: {
          click: function click($event) {
            $event.preventDefault();
            return _vm.deleteStep(step.journeyStep.id);
          }
        }
      })], 1)]) : _vm._e()]) : _vm._e();
    }), 0);
  })], 2), _vm._v(" "), !_vm.readOnly ? _c("line", {
    attrs: {
      x1: _vm.x,
      y1: _vm.y + 180 + _vm.computePhaseDetailsStepListHeight,
      x2: _vm.x + _vm.width,
      y2: _vm.y + 181 + _vm.computePhaseDetailsStepListHeight,
      fill: "#126661",
      "stroke-width": "1",
      stroke: "#126661"
    }
  }) : _vm._e(), _vm._v(" "), !_vm.readOnly ? _c("g", {
    attrs: {
      refs: "phase_details_popover_details_experience"
    }
  }, [_c("text", {
    staticStyle: {
      fill: "#0070c0"
    },
    attrs: {
      x: _vm.x + 10,
      y: _vm.y + 205 + _vm.computePhaseDetailsStepListHeight,
      "text-anchor": "start",
      "font-size": "1.0em",
      "font-weight": "bolder"
    }
  }, [_vm._v("Stage Experiences")]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "phase_details_popover_tools_experience"
    }
  }, [_c("g", {
    attrs: {
      refs: "phase_details_popover_tools_experience_challenge"
    },
    on: {
      mouseover: function mouseover($event) {
        _vm.gainHover = true;
      },
      mouseleave: function mouseleave($event) {
        _vm.gainHover = false;
      },
      click: function click($event) {
        return _vm.createExperienceFromPhase("CHALLENGE");
      }
    }
  }, [_c("rect", {
    staticClass: "buttonhover",
    attrs: {
      x: _vm.x + _vm.width * 0.2 + 60,
      y: _vm.y + 188 + _vm.computePhaseDetailsStepListHeight,
      rx: 3,
      ry: 3,
      width: 103,
      height: 24,
      stroke: "lightgrey",
      "stroke-width": "1",
      fill: "ghostwhite"
    }
  }), _vm._v(" "), _c("circle", {
    attrs: {
      cx: _vm.x + _vm.width * 0.2 + 60,
      cy: _vm.y + 188 + _vm.computePhaseDetailsStepListHeight + 12,
      r: "12",
      fill: "white",
      "stroke-width": "1.5px",
      stroke: "lightgrey"
    }
  }), _vm._v(" "), _c("foreignObject", {
    attrs: {
      x: _vm.x + _vm.width * 0.2 + 45,
      y: _vm.y + 189 + _vm.computePhaseDetailsStepListHeight,
      width: 30,
      height: 30
    }
  }, [_c("div", {
    staticClass: "text-center buttonhover",
    staticStyle: {
      color: "#4672c4"
    }
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "20px",
      color: "rebeccapurple"
    },
    attrs: {
      "icon-class": "oscurity"
    }
  })], 1)]), _vm._v(" "), _c("text", {
    staticClass: "textpointer",
    staticStyle: {
      fill: "#000"
    },
    attrs: {
      x: _vm.x + _vm.width * 0.2 + 80,
      y: _vm.y + 205 + _vm.computePhaseDetailsStepListHeight,
      "text-anchor": "start",
      "font-size": "0.9em",
      "font-weight": "normal"
    }
  }, [_vm._v("Add Obscurity")])]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "phase_details_popover_tools_experience_gain"
    },
    on: {
      mouseover: function mouseover($event) {
        _vm.gainHover = true;
      },
      mouseleave: function mouseleave($event) {
        _vm.gainHover = false;
      },
      click: function click($event) {
        return _vm.createExperienceFromPhase("GAIN");
      }
    }
  }, [_c("rect", {
    staticClass: "buttonhover",
    attrs: {
      x: _vm.x + _vm.width * 0.4 + 70,
      y: _vm.y + 188 + _vm.computePhaseDetailsStepListHeight,
      rx: 3,
      ry: 3,
      width: 75,
      height: 24,
      stroke: "lightgrey",
      "stroke-width": "1",
      fill: "ghostwhite"
    }
  }), _vm._v(" "), _c("foreignObject", {
    attrs: {
      x: _vm.x + _vm.width * 0.4 + 56,
      y: _vm.y + 188 + _vm.computePhaseDetailsStepListHeight,
      width: 30,
      height: 30
    }
  }, [_c("div", {
    staticClass: "text-center buttonhover",
    staticStyle: {
      color: "#4672c4"
    }
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "24px",
      color: "green"
    },
    attrs: {
      "icon-class": "smiley"
    }
  })], 1)]), _vm._v(" "), _c("text", {
    staticClass: "textpointer",
    staticStyle: {
      fill: "#000"
    },
    attrs: {
      x: _vm.x + _vm.width * 0.4 + 88,
      y: _vm.y + 205 + _vm.computePhaseDetailsStepListHeight,
      "text-anchor": "start",
      "font-size": "0.9em",
      "font-weight": "normal"
    }
  }, [_vm._v("Add Gain")])]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "phase_details_popover_tools_experience_pain"
    },
    on: {
      mouseover: function mouseover($event) {
        _vm.painHover = true;
      },
      mouseleave: function mouseleave($event) {
        _vm.painHover = false;
      },
      click: function click($event) {
        return _vm.createExperienceFromPhase("PAIN");
      }
    }
  }, [_c("rect", {
    staticClass: "buttonhover",
    attrs: {
      x: _vm.x + _vm.width * 0.6 + 48,
      y: _vm.y + 188 + _vm.computePhaseDetailsStepListHeight,
      rx: 3,
      ry: 3,
      width: 75,
      height: 24,
      stroke: "lightgrey",
      "stroke-width": "1",
      fill: "ghostwhite"
    }
  }), _vm._v(" "), _c("foreignObject", {
    attrs: {
      x: _vm.x + _vm.width * 0.6 + 36,
      y: _vm.y + 188 + _vm.computePhaseDetailsStepListHeight,
      width: 30,
      height: 30
    }
  }, [_c("div", {
    staticClass: "text-center buttonhover",
    staticStyle: {
      color: "#4672c4"
    }
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "24px"
    },
    attrs: {
      "icon-class": "danger-circle"
    }
  })], 1)]), _vm._v(" "), _c("text", {
    staticClass: "textpointer",
    staticStyle: {
      fill: "#000"
    },
    attrs: {
      x: _vm.x + _vm.width * 0.6 + 33 + 34,
      y: _vm.y + 205 + _vm.computePhaseDetailsStepListHeight,
      "text-anchor": "start",
      "font-size": "0.9em",
      "font-weight": "normal"
    }
  }, [_vm._v("Add Pain")])]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "phase_details_popover_tools_experience_wow"
    },
    on: {
      mouseover: function mouseover($event) {
        _vm.wowHover = true;
      },
      mouseleave: function mouseleave($event) {
        _vm.wowHover = false;
      },
      click: function click($event) {
        return _vm.createExperienceFromPhase("WOW");
      }
    }
  }, [_c("rect", {
    staticClass: "buttonhover",
    attrs: {
      x: _vm.x + _vm.width * 0.8 + 24,
      y: _vm.y + 188 + _vm.computePhaseDetailsStepListHeight,
      rx: 3,
      ry: 3,
      width: 75,
      height: 24,
      stroke: "lightgrey",
      "stroke-width": "1",
      fill: "ghostwhite"
    }
  }), _vm._v(" "), _c("foreignObject", {
    attrs: {
      x: _vm.x + _vm.width * 0.8 + 10,
      y: _vm.y + 188 + _vm.computePhaseDetailsStepListHeight,
      width: 30,
      height: 30
    }
  }, [_c("div", {
    staticClass: "text-center buttonhover",
    staticStyle: {
      color: "#4672c4"
    }
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "24px"
    },
    attrs: {
      "icon-class": "wow_blue"
    }
  })], 1)]), _vm._v(" "), _c("text", {
    staticClass: "textpointer",
    staticStyle: {
      fill: "#000"
    },
    attrs: {
      x: _vm.x + _vm.width * 0.8 + 33 + 8,
      y: _vm.y + 205 + _vm.computePhaseDetailsStepListHeight,
      "text-anchor": "start",
      "font-size": "0.9em",
      "font-weight": "normal"
    }
  }, [_vm._v("Add Wow")])])]), _vm._v(" "), _vm.experiencesList.length > 1 ? _c("g", {
    attrs: {
      refs: "phase_details_popover_tools_experience_pagination"
    }
  }, _vm._l(_vm.experiencesList, function (experienceChunk, experience_chunk_index) {
    return _c("g", {
      key: experience_chunk_index,
      attrs: {
        refs: "phase_details_popover_tools_experience_pagination_items"
      }
    }, [_c("g", {
      attrs: {
        refs: "phase_details_popover_tools_experience_pagination_arrow_nav"
      }
    }, [_c("foreignObject", {
      attrs: {
        x: _vm.x + _vm.width / 2 - 50,
        y: _vm.y + 215 + _vm.computePhaseDetailsStepListHeight,
        width: 15,
        height: 15
      }
    }, [_vm.activeExperienceChunk > 0 ? _c("div", {
      staticClass: "text-center",
      on: {
        click: function click($event) {
          $event.preventDefault();
          $event.stopPropagation();
          _vm.activeExperienceChunk = _vm.activeExperienceChunk - 1;
        }
      }
    }, [_c("i", {
      staticClass: "el-icon-caret-left",
      on: {
        click: function click($event) {
          $event.preventDefault();
          $event.stopPropagation();
          _vm.activeExperienceChunk = _vm.activeExperienceChunk - 1;
        }
      }
    })]) : _vm._e()]), _vm._v(" "), _c("text", {
      staticStyle: {
        cursor: "pointer"
      },
      attrs: {
        x: _vm.x + _vm.width / 2,
        y: _vm.y + 225 + _vm.computePhaseDetailsStepListHeight,
        "text-anchor": "middle",
        "font-size": "1em",
        "font-weight": "normal"
      }
    }, [_vm._v("\n              Page " + _vm._s(_vm.activeExperienceChunk + 1) + " / " + _vm._s(_vm.experiencesList.length) + "\n            ")]), _vm._v(" "), _c("foreignObject", {
      attrs: {
        x: _vm.x + _vm.width / 2 + 50,
        y: _vm.y + 215 + _vm.computePhaseDetailsStepListHeight,
        width: 15,
        height: 15
      }
    }, [_vm.activeExperienceChunk < _vm.experiencesList.length - 1 ? _c("div", {
      staticClass: "text-center",
      on: {
        click: function click($event) {
          $event.stopPropagation();
          $event.preventDefault();
          _vm.activeExperienceChunk = _vm.activeExperienceChunk + 1;
        }
      }
    }, [_c("i", {
      staticClass: "el-icon-caret-right",
      on: {
        click: function click($event) {
          $event.stopPropagation();
          $event.preventDefault();
          _vm.activeExperienceChunk = _vm.activeExperienceChunk + 1;
        }
      }
    })]) : _vm._e()])])]);
  }), 0) : _vm._e(), _vm._v(" "), _vm._l(_vm.experiencesList, function (experienceChunk, experience_chunk_index) {
    return _c("g", {
      key: experience_chunk_index,
      attrs: {
        refs: "phase_details_popover_details_experience_chunk_list"
      }
    }, _vm._l(experienceChunk, function (experience, experience_index) {
      return experience_chunk_index === _vm.activeExperienceChunk ? _c("g", {
        key: experience_chunk_index + "_" + experience_index,
        attrs: {
          refs: "phase_details_popover_details_experience_list"
        }
      }, [experienceChunk.length > 1 ? _c("line", {
        attrs: {
          x1: _vm.x + 45,
          y1: _vm.y + 235 + _vm.computePhaseDetailsStepListHeight + experience_index * 32,
          x2: _vm.x + _vm.width - 50,
          y2: _vm.y + 235 + _vm.computePhaseDetailsStepListHeight + experience_index * 32,
          fill: "#126661",
          "stroke-width": "0.25",
          stroke: "#126661"
        }
      }) : _vm._e(), _vm._v(" "), _c("rect", {
        attrs: {
          x: _vm.x + 5,
          y: _vm.y + 235 + _vm.computePhaseDetailsStepListHeight + experience_index * 32,
          width: _vm.width - 10,
          height: 32,
          rx: 1,
          ry: 1,
          "stroke-width": "0",
          stroke: "lightgrey",
          fill: "#fff"
        }
      }), _vm._v(" "), experience.journeyExperience.experiencetype === "CHALLENGE" ? _c("foreignObject", {
        attrs: {
          x: _vm.x + 10,
          y: _vm.y + 238 + _vm.computePhaseDetailsStepListHeight + experience_index * 32,
          width: 30,
          height: 30
        }
      }, [_c("div", {
        staticClass: "text-center",
        staticStyle: {
          color: "#4672c4"
        }
      }, [_c("svg-icon", {
        staticStyle: {
          "font-size": "16px"
        },
        attrs: {
          "icon-class": "oscurity"
        }
      })], 1)]) : _vm._e(), _vm._v(" "), experience.journeyExperience.experiencetype === "WOW" ? _c("foreignObject", {
        attrs: {
          x: _vm.x + 10,
          y: _vm.y + 238 + _vm.computePhaseDetailsStepListHeight + experience_index * 32,
          width: 30,
          height: 30
        }
      }, [_c("div", {
        staticClass: "text-center",
        staticStyle: {
          color: "#4672c4"
        }
      }, [_c("svg-icon", {
        staticStyle: {
          "font-size": "16px"
        },
        attrs: {
          "icon-class": "wow_blue"
        }
      })], 1)]) : _vm._e(), _vm._v(" "), experience.journeyExperience.experiencetype === "GAIN" ? _c("foreignObject", {
        attrs: {
          x: _vm.x + 10,
          y: _vm.y + 238 + _vm.computePhaseDetailsStepListHeight + experience_index * 32,
          width: 30,
          height: 30
        }
      }, [_c("div", {
        staticClass: "text-center",
        staticStyle: {
          color: "#4672c4"
        }
      }, [_c("svg-icon", {
        staticStyle: {
          "font-size": "16px"
        },
        attrs: {
          "icon-class": "smiley"
        }
      })], 1)]) : _vm._e(), _vm._v(" "), experience.journeyExperience.experiencetype === "PAIN" ? _c("foreignObject", {
        attrs: {
          x: _vm.x + 10,
          y: _vm.y + 238 + _vm.computePhaseDetailsStepListHeight + experience_index * 32,
          width: 30,
          height: 30
        }
      }, [_c("div", {
        staticClass: "text-center",
        staticStyle: {
          color: "#4672c4"
        }
      }, [_c("svg-icon", {
        staticStyle: {
          "font-size": "16px"
        },
        attrs: {
          "icon-class": "danger-circle"
        }
      })], 1)]) : _vm._e(), _vm._v(" "), _c("foreignObject", {
        staticStyle: {
          fill: "#0070c0"
        },
        attrs: {
          x: _vm.x + 45,
          y: _vm.y + 225 + _vm.computePhaseDetailsStepListHeight + experience_index * 32,
          width: _vm.width - 80,
          height: "40",
          "font-weight": "normal"
        }
      }, [_c("div", {
        staticStyle: {
          "margin-top": "10pt",
          color: "#0070c0",
          overflow: "hidden",
          "font-size": "0.9em"
        }
      }, [_vm._v("\n              " + _vm._s(_vm.truncateString(experience.journeyExperience.title, 220)) + "\n            ")])]), _vm._v(" "), _c("g", {
        attrs: {
          refs: "phase_details_popover_details_experience_delete"
        }
      }, [_c("foreignObject", {
        attrs: {
          x: _vm.x + _vm.width - 35,
          y: _vm.y + 240 + _vm.stepListActiveChunkLength * 35 + experience_index * 32,
          width: 20,
          height: 20
        }
      }, [_c("el-button", {
        staticStyle: {
          "background-color": "white",
          "border-color": "darkred",
          color: "darkred",
          width: "15pt",
          height: "15pt",
          "font-size": "1.1em",
          padding: "1pt 0 0 0"
        },
        attrs: {
          type: "danger",
          size: "mini",
          circle: "",
          icon: "el-icon-delete"
        },
        on: {
          click: function click($event) {
            $event.preventDefault();
            return _vm.deleteExperience(experience.journeyExperience.id);
          }
        }
      })], 1)]), _vm._v(" "), _vm.experiencesList[_vm.activeExperienceChunk].length === experience_index + 1 ? _c("line", {
        attrs: {
          x1: _vm.x + 15,
          y1: _vm.y + 238 + _vm.computePhaseDetailsStepListHeight + (experience_index + 1) * 32,
          x2: _vm.x + _vm.width - 12,
          y2: _vm.y + 238 + _vm.stepListActiveChunkLength * 35 + (experience_index + 1) * 32,
          fill: "#fff",
          "stroke-width": "1.5",
          stroke: "#ccc"
        }
      }) : _vm._e()]) : _vm._e();
    }), 0);
  })], 2) : _vm._e()]), _vm._v(" "), _c("linearGradient", {
    attrs: {
      id: "phaseGradient",
      x2: "1",
      y2: "1"
    }
  }, [_c("stop", {
    attrs: {
      offset: "0%",
      "stop-color": "#447799"
    }
  }), _vm._v(" "), _c("stop", {
    attrs: {
      offset: "50%",
      "stop-color": "#224488"
    }
  }), _vm._v(" "), _c("stop", {
    attrs: {
      offset: "100%",
      "stop-color": "#112266"
    }
  })], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;