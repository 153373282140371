"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _default2 = exports.default = {
  props: {
    display: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    x: {
      type: Number,
      default: 0
    },
    y: {
      type: Number,
      default: 0
    },
    width: {
      type: Number,
      default: 2000
    },
    height: {
      type: Number,
      default: 300
    },
    buttonText: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {};
  },
  computed: {
    computeTextX: function computeTextX() {
      return parseInt(this.x + 100);
    },
    computeTextY: function computeTextY() {
      return parseInt(this.y + parseInt(this.height / 2));
    }
  },
  mounted: function mounted() {},
  created: function created() {},
  methods: {
    computeButtonPosX: function computeButtonPosX(index) {
      if (this.buttonText.length % 2 === 0) {
        return parseInt(this.x + 325) + (index - this.buttonText.length / 2) * 250 + 125;
      } else {
        // Update algo for odd numbers
        return parseInt(this.x + 200) + (index - (this.buttonText.length - 1) / 2) * 250;
      }
    },
    responseCTA: function responseCTA(button) {
      this.$emit('response', button);
    }
  }
};