"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _generateComponentDiagram = _interopRequireDefault(require("./components/components_and_apis/generate-component-diagram"));
var _ProcessSteps = _interopRequireDefault(require("@/components/ProcessSteps"));
var _default = exports.default = {
  name: 'ComponentAPIs',
  components: {
    GenerateComponentDiagramComponent: _generateComponentDiagram.default,
    ProcessSteps: _ProcessSteps.default
  },
  data: function data() {
    return {
      active_step: 0,
      steps: [{
        name: 'Generate Diagram',
        shortDescription: 'Generate Diagram',
        longDescription: 'The component diagram can be generated from the sytax of your features and stories.  Generating components from results in a simpler diagram, but may miss design items.'
      }, {
        name: 'Design & Organise ',
        shortDescription: 'Design & Organise',
        longDescription: 'Once generated, use the verb/noun list as a prompt for design.  Create components and subcomponents automatically from the list. Also view how much of the requirements is covered by your design.'
      }, {
        name: 'Versions & Approvals',
        shortDescription: 'Version Approvals',
        longDescription: 'Each design version is mapped to a delivery release.  Once approved, "task" tickets that map to UI components can be generated, and progress tracked.'
      }, {
        name: 'Progress & Quality',
        shortDescription: 'Ticketing & Progress',
        longDescription: 'View the progress of each version can be viewed on this screen. Also view defects related to tickets (requires ticketing systems to be setup properly).'
      }]
    };
  },
  mounted: function mounted() {},
  methods: {
    updateStep: function updateStep(active_step) {
      this.active_step = active_step;
    }
  }
};