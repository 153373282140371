"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("g", [_c("g", {
    attrs: {
      refs: "touchpoint-top-collider"
    }
  }, [_c("rect", {
    attrs: {
      x: _vm.x,
      y: _vm.y - 5,
      width: 40,
      height: 5,
      rx: 5,
      fill: "transparent"
    },
    on: {
      mouseover: function mouseover($event) {
        $event.preventDefault();
        return _vm.onMouseLeave.apply(null, arguments);
      }
    }
  })]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "touchpoint-bottom-collider"
    }
  }, [_c("rect", {
    attrs: {
      x: _vm.x,
      y: _vm.y + 35,
      width: 40,
      height: 5,
      rx: 5,
      fill: "transparent"
    },
    on: {
      mouseover: function mouseover($event) {
        $event.preventDefault();
        return _vm.onMouseLeave.apply(null, arguments);
      }
    }
  })]), _vm._v(" "), _vm.touchpoint.type === "sms" ? _c("foreignObject", {
    staticStyle: {
      "background-color": "aliceblue",
      "border-radius": "5pt",
      padding: "3pt 2pt 2pt 2pt"
    },
    attrs: {
      x: _vm.x - 15,
      y: _vm.y,
      width: 115,
      height: 30,
      fill: "none"
    },
    on: {
      mouseover: _vm.onMouseOver,
      click: _vm.onSelect
    }
  }, [_c("div", {
    staticStyle: {
      display: "inline-block"
    }
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "20px",
      color: "#304174",
      float: "left"
    },
    attrs: {
      "icon-class": "sms"
    }
  }), _vm._v(" "), _c("span", {
    staticClass: "touchpoint-title ml-5"
  }, [_vm._v(_vm._s(_vm.truncateDescription(_vm.touchpoint.title, 55)))])], 1)]) : _vm._e(), _vm._v(" "), _vm.touchpoint.type === "web" ? _c("foreignObject", {
    staticStyle: {
      "background-color": "aliceblue",
      "border-radius": "5pt",
      padding: "3pt 2pt 2pt 2pt"
    },
    attrs: {
      x: _vm.x - 15,
      y: _vm.y,
      width: 115,
      height: 30,
      fill: "none"
    },
    on: {
      mouseover: _vm.onMouseOver,
      click: _vm.onSelect
    }
  }, [_c("div", {
    staticStyle: {
      display: "inline-block"
    }
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "20px",
      color: "#304174",
      float: "left"
    },
    attrs: {
      "icon-class": "international"
    }
  }), _vm._v(" "), _c("span", {
    staticClass: "touchpoint-title ml-5"
  }, [_vm._v(_vm._s(_vm.truncateDescription(_vm.touchpoint.title, 55)))])], 1)]) : _vm._e(), _vm._v(" "), _vm.touchpoint.type === "chat" ? _c("foreignObject", {
    staticStyle: {
      "background-color": "aliceblue",
      "border-radius": "5pt",
      padding: "3pt 2pt 2pt 2pt"
    },
    attrs: {
      x: _vm.x - 15,
      y: _vm.y,
      width: 115,
      height: 30,
      fill: "none"
    },
    on: {
      mouseover: _vm.onMouseOver,
      click: _vm.onSelect
    }
  }, [_c("div", {
    staticStyle: {
      display: "inline-block"
    }
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "20px",
      color: "#304174",
      float: "left"
    },
    attrs: {
      "icon-class": "wechat"
    }
  }), _vm._v(" "), _c("span", {
    staticClass: "touchpoint-title ml-5"
  }, [_vm._v(_vm._s(_vm.truncateDescription(_vm.touchpoint.title, 55)))])], 1)]) : _vm._e(), _vm._v(" "), _vm.touchpoint.type === "social" ? _c("foreignObject", {
    staticStyle: {
      "background-color": "aliceblue",
      "border-radius": "5pt",
      padding: "3pt 2pt 2pt 2pt"
    },
    attrs: {
      x: _vm.x - 15,
      y: _vm.y,
      width: 115,
      height: 30,
      fill: "none"
    },
    on: {
      mouseover: _vm.onMouseOver,
      click: _vm.onSelect
    }
  }, [_c("div", {
    staticStyle: {
      display: "inline-block"
    }
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "20px",
      color: "#304174",
      float: "left"
    },
    attrs: {
      "icon-class": "channel"
    }
  }), _vm._v(" "), _c("span", {
    staticClass: "touchpoint-title ml-5"
  }, [_vm._v(_vm._s(_vm.truncateDescription(_vm.touchpoint.title, 55)))])], 1)]) : _vm._e(), _vm._v(" "), _vm.touchpoint.type === "phone" ? _c("foreignObject", {
    staticStyle: {
      "background-color": "aliceblue",
      "border-radius": "5pt",
      padding: "3pt 2pt 2pt 2pt"
    },
    attrs: {
      x: _vm.x - 15,
      y: _vm.y,
      width: 115,
      height: 30,
      fill: "none"
    },
    on: {
      mouseover: _vm.onMouseOver,
      click: _vm.onSelect
    }
  }, [_c("div", {
    staticStyle: {
      display: "inline-block"
    }
  }, [_c("i", {
    staticClass: "el-icon-phone",
    staticStyle: {
      "font-size": "20px",
      color: "#304174",
      float: "left"
    }
  }), _vm._v(" "), _c("span", {
    staticClass: "touchpoint-title ml-5"
  }, [_vm._v(_vm._s(_vm.truncateDescription(_vm.touchpoint.title, 55)))])])]) : _vm._e(), _vm._v(" "), _vm.touchpoint.type === "app" ? _c("foreignObject", {
    staticStyle: {
      "background-color": "aliceblue",
      "border-radius": "5pt",
      padding: "3pt 2pt 2pt 2pt"
    },
    attrs: {
      x: _vm.x - 15,
      y: _vm.y,
      width: 115,
      height: 30,
      fill: "none"
    },
    on: {
      mouseover: _vm.onMouseOver,
      click: _vm.onSelect
    }
  }, [_c("div", {
    staticStyle: {
      display: "inline-block"
    }
  }, [_c("i", {
    staticClass: "el-icon-phone",
    staticStyle: {
      "font-size": "20px",
      color: "#304174",
      float: "left"
    }
  }), _vm._v(" "), _c("span", {
    staticClass: "touchpoint-title ml-5"
  }, [_vm._v(_vm._s(_vm.truncateDescription(_vm.touchpoint.title, 55)))])])]) : _vm._e(), _vm._v(" "), _vm.touchpoint.type === "email" ? _c("foreignObject", {
    staticStyle: {
      "background-color": "aliceblue",
      "border-radius": "5pt",
      padding: "3pt 2pt 2pt 2pt"
    },
    attrs: {
      x: _vm.x - 15,
      y: _vm.y,
      width: 115,
      height: 30,
      fill: "none"
    },
    on: {
      mouseover: _vm.onMouseOver,
      click: _vm.onSelect
    }
  }, [_c("div", {
    staticStyle: {
      display: "inline-block"
    }
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "20px",
      color: "#304174",
      float: "left"
    },
    attrs: {
      "icon-class": "email"
    }
  }), _vm._v(" "), _c("span", {
    staticClass: "touchpoint-title ml-5"
  }, [_vm._v(_vm._s(_vm.truncateDescription(_vm.touchpoint.title, 55)))])], 1)]) : _vm._e()]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;