"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("g", {
    attrs: {
      refs: "experience-container"
    }
  }, [_c("g", {
    attrs: {
      refs: "experience"
    }
  }, [_c("rect", {
    attrs: {
      x: 315 + _vm.index * (_vm.phaseParam.width + 25),
      y: 110 + 350 + _vm.experienceSubIndex * (_vm.phaseParam.title_height + 5),
      rx: "10",
      ry: "10",
      width: _vm.width,
      height: _vm.height,
      fill: _vm.experience.type === "pain" ? "#13ce66" : "#F56C6C",
      stroke: _vm.selectedActivityIndex === _vm.subIndex && _vm.selectedPhaseIndex === _vm.index ? "black" : _vm.experience.type === "pain" ? "#13ce66" : "#F56C6C",
      "fill-opacity": "0.8",
      "stroke-opacity": "1",
      "stroke-width": "2"
    }
  }), _vm._v(" "), _c("text", {
    attrs: {
      x: 320 + _vm.index * (_vm.phaseParam.width + 25),
      y: 130 + 350 + _vm.experienceSubIndex * (_vm.phaseParam.title_height + 5),
      "font-size": "0.9em",
      fill: "white",
      "font-weight": "normal",
      "text-anchor": "start"
    }
  }, [_vm._v(_vm._s(_vm.experience.name))]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "priorityIcon"
    }
  }, [_vm.experience.priority === "low" ? [_c("path", {
    attrs: {
      d: _vm.generatePriorityPath(_vm.index, _vm.experienceSubIndex, _vm.experience.priority),
      stroke: "white",
      "stroke-width": "2",
      fill: "none"
    }
  })] : _vm.experience.priority === "medium" ? [_c("path", {
    attrs: {
      d: _vm.generatePriorityPath(_vm.index, _vm.experienceSubIndex, _vm.experience.priority),
      stroke: "white",
      "stroke-width": "2",
      fill: "none"
    }
  })] : _vm.experience.priority === "high" ? [_c("path", {
    attrs: {
      d: _vm.generatePriorityPath(_vm.index, _vm.experienceSubIndex, _vm.experience.priority),
      stroke: "white",
      "stroke-width": "2",
      fill: "none"
    }
  })] : _vm._e()], 2), _vm._v(" "), _c("g", {
    attrs: {
      refs: "deleteExperienceIcon"
    },
    on: {
      click: function click($event) {
        return _vm.deleteExperience(_vm.index, _vm.subIndex, _vm.experienceSubIndex);
      }
    }
  }, [_c("circle", {
    attrs: {
      cx: 470 + _vm.index * (_vm.phaseParam.width + 25),
      cy: 130 + 350 + _vm.experienceSubIndex * (_vm.phaseParam.title_height + 5),
      r: "6",
      stroke: "white",
      "stroke-width": "1",
      fill: "#F56C6C"
    }
  })])])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;