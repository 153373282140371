"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-descriptions", {
    attrs: {
      direction: "vertical",
      column: 4,
      size: "mini",
      border: ""
    }
  }, [_c("el-descriptions-item", {
    attrs: {
      label: "Title"
    }
  }, [_vm._v(_vm._s(_vm.subEntity.title))]), _vm._v(" "), _c("el-descriptions-item", {
    attrs: {
      label: "Description"
    }
  }, [_vm._v(_vm._s(_vm.subEntity.description))]), _vm._v(" "), _vm.subEntity.startdate !== undefined && _vm.subEntity.startdate !== null && _vm.subEntity.startdate !== "" ? _c("el-descriptions-item", {
    attrs: {
      label: "Start Date"
    }
  }, [_vm._v(_vm._s(_vm.computeDate(_vm.subEntity.startdate)))]) : _vm._e(), _vm._v(" "), _vm.subEntity.enddate !== undefined && _vm.subEntity.enddate !== null && _vm.subEntity.enddate !== "" ? _c("el-descriptions-item", {
    attrs: {
      label: "End Date"
    }
  }, [_vm._v(_vm._s(_vm.computeDate(_vm.subEntity.enddate)))]) : _vm._e()], 1), _vm._v(" "), _vm.subEntity.childTickets && _vm.subEntity.childTickets.length > 0 ? _c("div", [_c("h4", [_vm._v("Stories")]), _vm._v(" "), _c("el-table", {
    attrs: {
      data: _vm.subEntity.childTickets,
      size: "mini",
      border: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Title",
      prop: "title"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Status",
      prop: "status"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-tag", {
          attrs: {
            size: "mini",
            type: "primary"
          }
        }, [_vm._v(_vm._s(scope.row.status))])];
      }
    }], null, false, 3053647104)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Priority",
      prop: "priority"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-tag", {
          attrs: {
            size: "mini",
            type: "primary"
          }
        }, [_vm._v(_vm._s(scope.row.priority))])];
      }
    }], null, false, 1427040070)
  })], 1)], 1) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "text-right mt-10"
  }, [_c("el-button-group", [_vm.subEntity.startdate !== undefined && _vm.subEntity.startdate !== null && _vm.subEntity.startdate !== "" ? _c("el-button", {
    attrs: {
      type: "danger",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.unlink();
      }
    }
  }, [_vm._v("Unlink from roadmap")]) : _vm._e(), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "secondary",
      icon: "el-icon-close",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.closeDialog();
      }
    }
  }, [_vm._v("Close")])], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;