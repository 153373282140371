"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineStrategicFeatures = _interopRequireDefault(require("./components/strategic_features/define-strategic-features"));
var _strategicCriteria = _interopRequireDefault(require("./components/strategic_features/strategic-criteria"));
var _myProductFeatures = _interopRequireDefault(require("./components/strategic_features/my-product-features"));
var _default = exports.default = {
  name: 'StrategicFeatures',
  components: {
    DefineStrategicFeaturesComponent: _defineStrategicFeatures.default,
    StrategicCriteriaComponent: _strategicCriteria.default,
    MyProductFeaturesComponent: _myProductFeatures.default
  },
  data: function data() {
    return {};
  },
  mounted: function mounted() {},
  methods: {}
};