"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.item.type == "title" ? _c("div", [_c("div", {
    staticStyle: {
      position: "absolute",
      top: "5px",
      right: "1%"
    }
  }, [!_vm.preview ? _c("span", {
    staticStyle: {
      cursor: "pointer"
    },
    on: {
      click: function click($event) {
        _vm.hVisible = !_vm.hVisible;
      }
    }
  }, [_c("i", {
    staticClass: "el-icon-more",
    attrs: {
      "aria-hidden": "true"
    }
  })]) : _vm._e(), _vm._v(" "), !_vm.preview && _vm.hVisible ? [_c("el-button-group", {
    attrs: {
      size: "mini",
      title: "Change Heading"
    }
  }, _vm._l(_vm.item.headings, function (heading, key) {
    return _c("el-button", {
      key: key,
      staticStyle: {
        cursor: "pointer"
      },
      attrs: {
        type: "primary",
        size: "mini"
      },
      on: {
        click: function click($event) {
          return _vm.changeToHeading({
            headingKey: key
          });
        }
      }
    }, [_vm._v(" " + _vm._s(key) + " ")]);
  }), 1)] : _vm._e()], 2), _vm._v(" "), _c("div", {
    class: [{
      heading1: _vm.item.headings.h1,
      heading2: _vm.item.headings.h2,
      heading3: _vm.item.headings.h3
    }],
    staticStyle: {
      padding: "6px 10px"
    },
    attrs: {
      id: _vm.item.i,
      contenteditable: _vm.contenteditable
    },
    on: {
      paste: function paste($event) {
        $event.preventDefault();
        return _vm.pasteData.apply(null, arguments);
      },
      blur: _vm.save
    }
  }, [_vm._v("\n    " + _vm._s(_vm.item.title) + "\n  ")])]) : _vm._e();
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;