"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _default2 = exports.default = {
  name: 'RoadmapGoalComponent',
  props: {
    x: {
      type: Number,
      required: true,
      default: 0
    },
    y: {
      type: Number,
      required: true,
      default: 0
    },
    width: {
      type: Number,
      required: true,
      default: 0
    },
    index: {
      type: Number,
      required: true,
      default: 0
    },
    goal: {
      type: Object,
      default: function _default() {
        return {
          goal: '',
          strategy: '',
          priority: '',
          actions: [],
          achieved: false
        };
      }
    }
  },
  data: function data() {
    return {};
  },
  methods: {
    computeGoalText: function computeGoalText() {
      return this.goal.goal.substring(0, 20) + '...';
    },
    emitClick: function emitClick() {
      this.$emit('clickGoal', this.goal);
    },
    generateTransform: function generateTransform() {
      var x = 0 + 35;
      var y = this.y + 75;
      return 'rotate(-90 ' + x + ',' + y + ')';
    }
  }
};