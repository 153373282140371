"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
var _Wireframe = _interopRequireDefault(require("../wireframe/Wireframe"));
var _WireframeDesigner = _interopRequireDefault(require("../wireframe/WireframeDesigner"));
var _default = exports.default = {
  name: 'GenerateWireframe',
  components: {
    WireframeComponent: _Wireframe.default,
    WireframeDesignerComponent: _WireframeDesigner.default
  },
  props: {},
  data: function data() {
    return {
      active_step: 0,
      loading: false,
      editWireframeDialog: false,
      users: [{
        title: 'User1',
        key: 1,
        user_group: [],
        journey_map: null,
        user_type: null,
        segment: null,
        design_goals: null,
        user_count: 0,
        revenue_strategy: null,
        pain_points: [{
          key: 0,
          point: '',
          priority: 0,
          value: 0,
          relations: [{
            to: 'candidate_feature',
            key: 0
          }, {
            to: 'candidate_feature',
            key: 1
          }]
        }],
        pleasers: [{
          key: 1,
          point: '',
          priority: 0,
          value: 0,
          relations: [{
            to: 'candidate_feature',
            key: 0
          }, {
            to: 'candidate_feature',
            key: 1
          }]
        }],
        candidate_features: [{
          key: 0,
          feature: 'user 1, feature 1',
          revenue_type: '1',
          forecase_revenue: '1',
          priority: '2',
          status: '3'
        }, {
          key: 1,
          feature: 'user 1, feature 2',
          revenue_type: '1',
          forecase_revenue: '1',
          priority: '2',
          status: '3'
        }]
      }, {
        title: 'User2',
        key: 1,
        user_group: [],
        journey_map: null,
        user_type: null,
        segment: null,
        design_goals: null,
        user_count: 0,
        revenue_strategy: null,
        pain_points: [{
          key: 0,
          point: '',
          priority: 0,
          value: 0,
          relations: [{
            to: 'candidate_feature',
            key: 0
          }, {
            to: 'candidate_feature',
            key: 1
          }]
        }],
        pleasers: [{
          key: 1,
          point: '',
          priority: 0,
          value: 0,
          relations: [{
            to: 'candidate_feature',
            key: 0
          }, {
            to: 'candidate_feature',
            key: 1
          }]
        }],
        candidate_features: [{
          key: 0,
          feature: 'user 2, feature 3',
          revenue_type: '1',
          forecase_revenue: '1',
          priority: '2',
          status: '3'
        }, {
          key: 1,
          feature: 'user 2, feature 4',
          revenue_type: '1',
          forecase_revenue: '1',
          priority: '2',
          status: '3'
        }]
      }],
      activeUser: {},
      activeDragElement: {},
      activeDragElementType: {},
      activeDragElementIndex: {},
      activeWireframe: {},
      wireframes: [{
        key: 1,
        title: 'Feature 1',
        x: 0,
        y: 0,
        type: 'wireframe',
        layout: [{
          'x': 0,
          'y': 0,
          'w': 12,
          'h': 2,
          'i': '1',
          'type': 'title',
          'title': 'Heading 1',
          'headings': {
            'h1': false,
            'h2': false,
            'h3': false
          },
          'moved': false
        }, {
          'x': 0,
          'y': 2,
          'w': 4,
          'h': 12,
          'i': '2',
          'type': 'content',
          'content': 'Content goes here',
          'moved': false
        }, {
          'x': 8,
          'y': 2,
          'w': 4,
          'h': 12,
          'i': '3',
          'type': 'image',
          'location': 'http://via.placeholder.com/600x350',
          'moved': false
        }, {
          'x': 4,
          'y': 2,
          'w': 4,
          'h': 12,
          'i': 3,
          'type': 'title',
          'title': 'Heading 1',
          'headings': {
            'h1': false,
            'h2': false,
            'h3': false
          },
          'moved': false
        }, {
          'x': 0,
          'y': 14,
          'w': 12,
          'h': 2,
          'i': 4,
          'type': 'title',
          'title': 'Heading 1',
          'headings': {
            'h1': false,
            'h2': false,
            'h3': false
          },
          'moved': false
        }],
        link: [2, 3]
      }, {
        key: 2,
        title: 'Feature 2',
        x: 10,
        y: 50,
        type: 'wireframe',
        layout: [{
          'x': 0,
          'y': 0,
          'w': 12,
          'h': 1,
          'i': '1',
          'type': 'title',
          'title': 'Heading 1',
          'headings': {
            'h1': false,
            'h2': false,
            'h3': false
          },
          'moved': false
        }, {
          'x': 0,
          'y': 1,
          'w': 6,
          'h': 9,
          'i': '2',
          'type': 'content',
          'content': 'Content goes here',
          'moved': false
        }, {
          'x': 6,
          'y': 1,
          'w': 6,
          'h': 9,
          'i': '3',
          'type': 'image',
          'location': 'http://via.placeholder.com/600x350',
          'moved': false
        }],
        link: []
      }, {
        key: 3,
        title: 'Feature 3',
        x: 10,
        y: 50,
        type: 'wireframe',
        layout: [{
          'x': 0,
          'y': 0,
          'w': 12,
          'h': 1,
          'i': '1',
          'type': 'title',
          'title': 'Heading 1',
          'headings': {
            'h1': false,
            'h2': false,
            'h3': false
          },
          'moved': false
        }, {
          'x': 0,
          'y': 1,
          'w': 6,
          'h': 3,
          'i': '2',
          'type': 'content',
          'content': 'Content goes here',
          'moved': false
        }, {
          'x': 6,
          'y': 1,
          'w': 6,
          'h': 3,
          'i': '3',
          'type': 'image',
          'location': 'http://via.placeholder.com/600x350',
          'moved': false
        }, {
          'x': 0,
          'y': 4,
          'w': 6,
          'h': 5,
          'i': 3,
          'type': 'title',
          'title': 'Heading 1',
          'headings': {
            'h1': false,
            'h2': false,
            'h3': false
          },
          'moved': false
        }, {
          'x': 6,
          'y': 4,
          'w': 4,
          'h': 5,
          'i': 4,
          'type': 'title',
          'title': 'Heading 1',
          'headings': {
            'h1': false,
            'h2': false,
            'h3': false
          },
          'moved': false
        }, {
          'x': 10,
          'y': 4,
          'w': 2,
          'h': 5,
          'i': 5,
          'type': 'title',
          'title': 'Heading 1',
          'headings': {
            'h1': false,
            'h2': false,
            'h3': false
          },
          'moved': false
        }],
        link: []
      }]
    };
  },
  mounted: function mounted() {
    this.$eventHub.$emit('process-step-load', 'generate-wireframes');
    this.activeUser = this.users[0];
  },
  methods: {
    setActiveDragElement: function setActiveDragElement(elementType, element, index) {
      this.activeDragElement = element;
      this.activeDragElementType = elementType;
      this.activeDragElementIndex = index;
    },
    handleDrop: function handleDrop(event) {
      event.preventDefault(); // !important
      this.wireframes = [];
      this.addWireframeCanvas(event.offsetX, event.offsetY);
    },
    handleDragOver: function handleDragOver(event) {
      event.preventDefault(); // !important
    },
    startWireframeMove: function startWireframeMove(_ref) {
      var el = _ref.el,
        deltaX = _ref.deltaX,
        deltaY = _ref.deltaY,
        offsetX = _ref.offsetX,
        offsetY = _ref.offsetY,
        clientX = _ref.clientX,
        clientY = _ref.clientY,
        first = _ref.first,
        last = _ref.last;
      if (deltaX === undefined || deltaY === undefined) {
        return;
      }
      var index = el.id.replace('wireframe_', '');
      this.wireframes[index].x += parseInt(deltaX);
      this.wireframes[index].y += parseInt(deltaY);
    },
    getEndPointX: function getEndPointX(link, index) {
      for (var i = 0; i < this.wireframes.length; i++) {
        if (this.wireframes[i].key === link) {
          return this.wireframes[i].x + index * 200;
        }
      }
    },
    getEndPointY: function getEndPointY(link, index) {
      for (var i = 0; i < this.wireframes.length; i++) {
        if (this.wireframes[i].key === link) {
          return this.wireframes[i].y + index * 50;
        }
      }
    },
    computePathPoints: function computePathPoints(start_x, start_y, end_key) {
      var end_wireframe = {};
      for (var i = 0; i < this.wireframes.length; i++) {
        if (this.wireframes[i].key === end_key) {
          end_wireframe = this.wireframes[i];
        }
      }
      var start_x1 = start_x + 110;
      var start_y1 = start_y + 75;

      // For X2, Y2 the start point is point of wireframe creation.
      var start_x2 = end_wireframe.x + end_wireframe.key * 200 - 10;
      var start_y2 = end_wireframe.y + end_wireframe.key * 50 + 75;
      var end_x1 = start_x1 + (start_x2 - start_x1) / 2;
      var end_y1 = start_y + 75;
      var end_x2 = start_x2 - (start_x2 - start_x1) / 2;
      var end_y2 = start_y2;
      return 'M' + start_x1 + ',' + start_y1 + ' C' + end_x1 + ',' + end_y1 + ' ' + end_x2 + ',' + end_y2 + ' ' + start_x2 + ',' + start_y2;
    },
    generateWireframeID: function generateWireframeID(index) {
      return 'wireframe_' + index;
    },
    addWireframeCanvas: function addWireframeCanvas(x, y) {
      var wireframes = [{
        key: 1,
        title: 'Feature 1',
        x: 0,
        y: 0,
        type: 'wireframe',
        layout: [{
          'x': 0,
          'y': 0,
          'w': 4,
          'h': 2,
          'i': '1',
          'type': 'title',
          'title': 'Heading 1',
          'headings': {
            'h1': false,
            'h2': false,
            'h3': false
          }
        }, {
          'x': 4,
          'y': 0,
          'w': 4,
          'h': 3,
          'i': '2',
          'type': 'content',
          'content': 'Content goes here'
        }, {
          'x': 8,
          'y': 0,
          'w': 4,
          'h': 4,
          'i': '3',
          'type': 'image',
          'location': 'http://via.placeholder.com/600x350'
        }],
        link: [2, 3]
      }, {
        key: 2,
        title: 'Feature 2',
        x: 50,
        y: 50,
        type: 'wireframe',
        layout: [{
          'x': 0,
          'y': 0,
          'w': 12,
          'h': 1,
          'i': '1',
          'type': 'title',
          'title': 'Heading 1',
          'headings': {
            'h1': false,
            'h2': false,
            'h3': false
          },
          'moved': false
        }, {
          'x': 0,
          'y': 1,
          'w': 6,
          'h': 9,
          'i': '2',
          'type': 'content',
          'content': 'Content goes here',
          'moved': false
        }, {
          'x': 6,
          'y': 1,
          'w': 6,
          'h': 9,
          'i': '3',
          'type': 'image',
          'location': 'http://via.placeholder.com/600x350',
          'moved': false
        }],
        link: []
      }, {
        key: 3,
        title: 'Feature 3',
        x: 50,
        y: 50,
        type: 'wireframe',
        layout: [{
          'x': 0,
          'y': 0,
          'w': 4,
          'h': 2,
          'i': '1',
          'type': 'title',
          'title': 'Heading 1',
          'headings': {
            'h1': false,
            'h2': false,
            'h3': false
          }
        }, {
          'x': 4,
          'y': 0,
          'w': 4,
          'h': 3,
          'i': '2',
          'type': 'content',
          'content': 'Content goes here'
        }, {
          'x': 8,
          'y': 0,
          'w': 4,
          'h': 4,
          'i': '3',
          'type': 'image',
          'location': 'http://via.placeholder.com/600x350'
        }],
        link: []
      }, {
        key: 4,
        title: 'Feature 4',
        x: 50,
        y: 50,
        type: 'wireframe',
        layout: [{
          'x': 0,
          'y': 0,
          'w': 4,
          'h': 2,
          'i': '1',
          'type': 'title',
          'title': 'Heading 1',
          'headings': {
            'h1': false,
            'h2': false,
            'h3': false
          }
        }, {
          'x': 4,
          'y': 0,
          'w': 4,
          'h': 3,
          'i': '2',
          'type': 'content',
          'content': 'Content goes here'
        }, {
          'x': 8,
          'y': 0,
          'w': 4,
          'h': 4,
          'i': '3',
          'type': 'image',
          'location': 'http://via.placeholder.com/600x350'
        }],
        link: []
      }];
      this.wireframes = wireframes;
    },
    editWireframe: function editWireframe(key) {
      for (var i = 0; i < this.wireframes.length; i++) {
        if (this.wireframes[i].key === key) {
          this.activeWireframe = this.wireframes[i];
        }
      }
      this.editWireframeDialog = true;
    },
    deleteWireframe: function deleteWireframe(key) {
      for (var i = 0; i < this.wireframes.length; i++) {
        if (this.wireframes[i].key === key) {
          this.wireframes.splice(i, 1);
        }
      }
    },
    updateLayout: function updateLayout(layout) {
      this.activeWireframe.layout = layout;
    },
    goToDataAndServiceRequirements: function goToDataAndServiceRequirements() {
      this.$emit('go-next');
    }
  }
};