"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default2 = exports.default = {
  name: 'ViewMockupStoryboard',
  components: {},
  props: {
    userTypes: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      activeUserType: {},
      scenarioDetailsDialog: false,
      addScenarioDialog: false,
      addStepDialog: false,
      addStoryBoardDialog: false,
      addMockupDialog: false,
      storyboards: [{
        name: '123'
      }],
      mockups: [{
        name: '123'
      }],
      scenarios: [{
        name: 'Scenario 1',
        goal: 'To have an efficient method to resolve the problem',
        storyboards: [{
          name: '123'
        }],
        mockups: [{
          name: '123'
        }],
        steps: [{
          name: 'Scenario Step 1',
          mockups: ['123'],
          storyboards: ['123']
        }]
      }],
      scenario_form: {
        name: '',
        goal: ''
      },
      mockup_form: {
        name: ''
      },
      storyboard_form: {
        name: ''
      },
      step_form: {
        name: '',
        mockups: [],
        storyboards: []
      },
      activeScenario: {},
      activeScenarioIndex: 0
    };
  },
  mounted: function mounted() {
    this.$eventHub.$emit('process-step-load', 'view-mockup-&-storyboard');
  },
  methods: {
    handleClick: function handleClick(tab, event) {
      this.setActiveUserType(this.userTypes[tab.index]);
    },
    setActiveUserType: function setActiveUserType(user_type) {
      this.activeUserType = user_type;
    },
    getPriorityIcon: function getPriorityIcon(priority) {
      return 'priority-white-' + priority;
    },
    addScenario: function addScenario() {
      var scenario = Object.assign({}, this.scenario_form);
      this.scenarios.push(scenario);
      this.addScenarioDialog = false;
    },
    removeScenarioCard: function removeScenarioCard(index) {
      this.scenarios.splice(index, 1);
    },
    openScenarioDetailsModal: function openScenarioDetailsModal(scenario, index) {
      this.activeScenario = scenario;
      this.activeScenarioIndex = index;
      this.scenarioDetailsDialog = true;
    },
    openAddStepModal: function openAddStepModal(scenario, index) {
      this.activeScenario = scenario;
      this.activeScenarioIndex = index;
      this.addStepDialog = true;
    },
    openAddStoryBoardModal: function openAddStoryBoardModal(scenario, index) {
      this.activeScenario = scenario;
      this.activeScenarioIndex = index;
      this.addStoryBoardDialog = true;
    },
    openAddMockupModal: function openAddMockupModal(scenario, index) {
      this.activeScenario = scenario;
      this.activeScenarioIndex = index;
      this.addMockupDialog = true;
    },
    addStep: function addStep() {
      var step = Object.assign({}, this.step_form);
      this.activeScenario.steps.push(step);
      this.addStepDialog = false;
    },
    addStoryBoard: function addStoryBoard() {
      var storyboard = Object.assign({}, this.storyboard_form);
      this.activeScenario.storyboards.push(storyboard);
      this.addStoryBoardDialog = false;
    },
    addMockup: function addMockup() {
      var mockup = Object.assign({}, this.mockup_form);
      this.activeScenario.mockups.push(mockup);
      this.addMockupDialog = false;
    },
    gotoDefineUserJourney: function gotoDefineUserJourney() {
      this.$emit('go-prev');
    },
    gotoDefineUserValueProposition: function gotoDefineUserValueProposition() {
      this.$emit('go-next');
    }
  }
};