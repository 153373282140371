"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _create = _interopRequireDefault(require("./capture-research/create.vue"));
var _default = exports.default = {
  name: 'CaptureResearch',
  components: {
    CreateResearchComponent: _create.default
  },
  props: {},
  data: function data() {
    return {
      loading: false,
      createResearchModal: false,
      research: [{
        title: 'Research Note 1',
        description: 'Bills Plumbing is a traditional business with no digital competitors.  An opporuntity exists to create a digital plumbing DIY business that takes Bills know how, digitises, and puts it into a SAAS product',
        problem: 'Prow scuttle parrel provost Sail ho shrouds spirits boom mizzenmast yardarm. Pinnace holystone mizzenmast quarter crows nest nipperkin grog yardarm hempen halter furl. Swab barque interloper chantey doubloon starboard grog black jack gangway rutters.',
        research: 'Cupcake ipsum dolor. Sit amet marshmallow topping cheesecake muffin. Halvah croissant candy canes bonbon candy. Apple pie jelly beans topping carrot cake danish tart cake cheesecake. Muffin danish chocolate soufflé pastry icing bonbon oat cake. Powder cake jujubes oat cake. Lemon drops tootsie roll marshmallow halvah carrot cake.',
        tags: ['General', 'Business Idea', 'SME'],
        files: [{
          name: 'File',
          url: ''
        }, {
          name: 'File 2',
          url: ''
        }, {
          name: 'File 3',
          url: ''
        }],
        created_at: '2018-02-10T09:30Z',
        modified_at: '2018-02-10T09:30Z',
        changed_user: 'some text',
        guid: 'some text'
      }, {
        title: 'Research Note 2',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip',
        problem: 'Prow scuttle parrel provost Sail ho shrouds spirits boom mizzenmast yardarm. Pinnace holystone mizzenmast quarter crows nest nipperkin grog yardarm hempen halter furl. Swab barque interloper chantey doubloon starboard grog black jack gangway rutters.',
        research: 'Cupcake ipsum dolor. Sit amet marshmallow topping cheesecake muffin. Halvah croissant candy canes bonbon candy. Apple pie jelly beans topping carrot cake danish tart cake cheesecake. Muffin danish chocolate soufflé pastry icing bonbon oat cake. Powder cake jujubes oat cake. Lemon drops tootsie roll marshmallow halvah carrot cake.',
        tags: ['Low Priority', 'Marketing', 'Government'],
        files: [{
          name: 'File',
          url: ''
        }, {
          name: 'File 2',
          url: ''
        }, {
          name: 'File 3',
          url: ''
        }],
        created_at: '2018-02-10T09:30Z',
        modified_at: '2018-02-10T09:30Z',
        changed_user: 'some text',
        guid: 'some text'
      }, {
        title: 'Research Note 3',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip',
        problem: 'Prow scuttle parrel provost Sail ho shrouds spirits boom mizzenmast yardarm. Pinnace holystone mizzenmast quarter crows nest nipperkin grog yardarm hempen halter furl. Swab barque interloper chantey doubloon starboard grog black jack gangway rutters.',
        research: 'Cupcake ipsum dolor. Sit amet marshmallow topping cheesecake muffin. Halvah croissant candy canes bonbon candy. Apple pie jelly beans topping carrot cake danish tart cake cheesecake. Muffin danish chocolate soufflé pastry icing bonbon oat cake. Powder cake jujubes oat cake. Lemon drops tootsie roll marshmallow halvah carrot cake.',
        tags: ['Tag 1', 'Tag 2', 'Tag 3'],
        files: [{
          name: 'File',
          url: ''
        }, {
          name: 'File 2',
          url: ''
        }, {
          name: 'File 3',
          url: ''
        }],
        created_at: '2018-02-10T09:30Z',
        modified_at: '2018-02-10T09:30Z',
        changed_user: 'some text',
        guid: 'some text'
      }, {
        title: 'Research Note 4',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip',
        problem: 'Prow scuttle parrel provost Sail ho shrouds spirits boom mizzenmast yardarm. Pinnace holystone mizzenmast quarter crows nest nipperkin grog yardarm hempen halter furl. Swab barque interloper chantey doubloon starboard grog black jack gangway rutters.',
        research: 'Cupcake ipsum dolor. Sit amet marshmallow topping cheesecake muffin. Halvah croissant candy canes bonbon candy. Apple pie jelly beans topping carrot cake danish tart cake cheesecake. Muffin danish chocolate soufflé pastry icing bonbon oat cake. Powder cake jujubes oat cake. Lemon drops tootsie roll marshmallow halvah carrot cake.',
        tags: ['Tag 1', 'Tag 2', 'Tag 3'],
        files: [{
          name: 'File',
          url: ''
        }, {
          name: 'File 2',
          url: ''
        }, {
          name: 'File 3',
          url: ''
        }],
        created_at: '2018-02-10T09:30Z',
        modified_at: '2018-02-10T09:30Z',
        changed_user: 'some text',
        guid: 'some text'
      }, {
        title: 'Research Note 5',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip',
        problem: 'Prow scuttle parrel provost Sail ho shrouds spirits boom mizzenmast yardarm. Pinnace holystone mizzenmast quarter crows nest nipperkin grog yardarm hempen halter furl. Swab barque interloper chantey doubloon starboard grog black jack gangway rutters.',
        research: 'Cupcake ipsum dolor. Sit amet marshmallow topping cheesecake muffin. Halvah croissant candy canes bonbon candy. Apple pie jelly beans topping carrot cake danish tart cake cheesecake. Muffin danish chocolate soufflé pastry icing bonbon oat cake. Powder cake jujubes oat cake. Lemon drops tootsie roll marshmallow halvah carrot cake.',
        tags: ['Tag 1', 'Tag 2', 'Tag 3'],
        files: [{
          name: 'File',
          url: ''
        }, {
          name: 'File 2',
          url: ''
        }, {
          name: 'File 3',
          url: ''
        }],
        created_at: '2018-02-10T09:30Z',
        modified_at: '2018-02-10T09:30Z',
        changed_user: 'some text',
        guid: 'some text'
      }, {
        title: 'Research Note 6',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip',
        problem: 'Prow scuttle parrel provost Sail ho shrouds spirits boom mizzenmast yardarm. Pinnace holystone mizzenmast quarter crows nest nipperkin grog yardarm hempen halter furl. Swab barque interloper chantey doubloon starboard grog black jack gangway rutters.',
        research: 'Cupcake ipsum dolor. Sit amet marshmallow topping cheesecake muffin. Halvah croissant candy canes bonbon candy. Apple pie jelly beans topping carrot cake danish tart cake cheesecake. Muffin danish chocolate soufflé pastry icing bonbon oat cake. Powder cake jujubes oat cake. Lemon drops tootsie roll marshmallow halvah carrot cake.',
        tags: ['Tag 1', 'Tag 2', 'Tag 3'],
        files: [{
          name: 'File',
          url: ''
        }, {
          name: 'File 2',
          url: ''
        }, {
          name: 'File 3',
          url: ''
        }],
        created_at: '2018-02-10T09:30Z',
        modified_at: '2018-02-10T09:30Z',
        changed_user: 'some text',
        guid: 'some text'
      }],
      selected_research: {},
      research_name: ''
    };
  },
  computed: {
    filteredResearch: function filteredResearch() {
      var self = this;
      return this.research.filter(function (research_data) {
        return research_data.title.indexOf(self.research_name) > -1;
      });
    }
  },
  mounted: function mounted() {
    this.$eventHub.$emit('process-step-load', 'capture-research');
    this.selected_research = this.research[0];
  },
  methods: {
    onClickAssessTrends: function onClickAssessTrends() {
      this.$emit('go-prev');
    },
    selectResearch: function selectResearch(research) {
      this.selected_research = research;
    },
    openCreateResearchModal: function openCreateResearchModal() {
      this.createResearchModal = true;
    },
    deleteResearch: function deleteResearch(research) {
      for (var i = 0; i < this.research.length; i++) {
        if (research.title === this.research[i].title) {
          this.research.splice(i, 1);
          this.selected_research = this.research[i];
        }
      }
    },
    createResearch: function createResearch(research) {
      var research_template = {
        title: research.name,
        description: research.description,
        problem: research.problem,
        research: research.research,
        created_at: '2018-02-10T09:30Z',
        modified_at: '2018-02-10T09:30Z',
        changed_user: 'some text',
        guid: 'some text',
        tags: research.tags
      };
      this.research.push(research_template);
      this.createResearchModal = false;
    }
  }
};