"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _default2 = exports.default = {
  name: 'SegmentOverview',
  components: {},
  props: {
    activeSegment: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    activeSegmentIndex: {
      type: Number,
      default: 0
    }
  },
  data: function data() {
    return {
      loading: false,
      addSegmentDialog: false
    };
  },
  mounted: function mounted() {
    this.$eventHub.$emit('process-step-load', 'define-segments');
  },
  methods: {
    openAddSegmentModal: function openAddSegmentModal() {
      var type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      this.$emit('openAddSegmentModal', type, this.activeSegmentIndex);
    },
    addSegmentNeeds: function addSegmentNeeds() {
      this.segment_form.segment_needs.push({
        name: '',
        complete: false
      });
    },
    onClickDefineRevenueTypes: function onClickDefineRevenueTypes() {
      this.$emit('go-next');
    }
  }
};