"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-card", {
    staticClass: "mt-10 text-center"
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("span", [_vm._v("Feature Threats")]), _vm._v(" "), _c("el-select", {
    attrs: {
      placeholder: "Select Feature"
    },
    model: {
      value: _vm.activeFeature,
      callback: function callback($$v) {
        _vm.activeFeature = $$v;
      },
      expression: "activeFeature"
    }
  }, _vm._l(_vm.features, function (feature) {
    return _c("el-option", {
      key: feature.value,
      attrs: {
        label: feature.label,
        value: feature.value
      }
    });
  }), 1)], 1), _vm._v(" "), _c("force-directed-tree-component", {
    attrs: {
      nodes: _vm.activeFeatureThreat.nodes,
      links: _vm.activeFeatureThreat.links,
      options: _vm.activeFeatureThreat.options
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-card", {
    staticClass: "mt-10 text-center"
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("span", [_vm._v("Corporate Threats")])]), _vm._v(" "), _c("force-directed-tree-component", {
    attrs: {
      nodes: _vm.corporate_threats.nodes,
      links: _vm.corporate_threats.links,
      options: _vm.corporate_threats.options,
      "custom-forces": _vm.custom_forces
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "text-right"
  }, [_c("el-button-group", {
    attrs: {
      size: "mini"
    }
  }, [_c("el-tooltip", {
    attrs: {
      effect: "dark",
      content: "Go To Assess Competitor Features",
      placement: "top-start"
    }
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      icon: "el-icon-arrow-left"
    },
    on: {
      click: _vm.onClickAssessCompetitorFeatures
    }
  }, [_vm._v("Previous")])], 1), _vm._v(" "), _c("el-tooltip", {
    attrs: {
      effect: "dark",
      content: "Go To Capture Research",
      placement: "top-start"
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.onClickCaptureResearch
    }
  }, [_vm._v("Next"), _c("i", {
    staticClass: "el-icon-arrow-right"
  })])], 1)], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;