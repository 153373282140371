"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
/** When your routing table is too long, you can split it into small modules**/

var processRouter = {
  path: '/processes',
  component: _layout.default,
  hidden: true,
  name: 'Process',
  meta: {
    title: 'Process',
    icon: 'chart'
  },
  children: [
  // PLAN BUSINESS
  {
    path: 'plan-product/product-strategy',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/processes/plan_product/product_strategy'));
      });
    },
    name: 'Product Strategy',
    meta: {
      title: 'Product Strategy',
      noCache: true
    }
  }, {
    path: 'plan-product/segment-customer-types',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/processes/plan_product/segment_customer_types'));
      });
    },
    name: 'Segment Customer Types',
    meta: {
      title: 'Segment Customer Types',
      noCache: true
    }
  }, {
    path: 'plan-product/strategic-features',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/processes/plan_product/strategic_features'));
      });
    },
    name: 'Strategic Features',
    meta: {
      title: 'Strategic Features',
      noCache: true
    }
  }, {
    path: 'plan-product/monitor-competitors',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/processes/plan_product/monitor_competitors'));
      });
    },
    name: 'Monitor Competitors',
    meta: {
      title: 'Monitor Competitors',
      noCache: true
    }
  }, {
    path: 'plan-product/model-revenue',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/processes/plan_product/model_revenue'));
      });
    },
    name: 'Model Revenue',
    meta: {
      title: 'Model Revenue',
      noCache: true
    }
  },
  // CREATE USER VALUE
  {
    path: 'create-user-value/identify-user',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/processes/create_user_value/identify_user'));
      });
    },
    name: 'Identify User',
    meta: {
      title: 'Identify User',
      noCache: true
    }
  }, {
    path: 'create-user-value/manage-user-experience',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/processes/create_user_value/manage_user_experience'));
      });
    },
    name: 'Manage User Experience',
    meta: {
      title: 'Manage User Experience',
      noCache: true
    }
  }, {
    path: 'create-user-value/refine-features',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/processes/create_user_value/refine_features'));
      });
    },
    name: 'Refine Features',
    meta: {
      title: 'Refine Features',
      noCache: true
    }
  }, {
    path: 'create-user-value/mockup-storyboards',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/processes/create_user_value/mockup_storyboards'));
      });
    },
    name: 'Mockup Storyboards',
    meta: {
      title: 'Mockup Storyboards',
      noCache: true
    }
  }, {
    path: 'create-user-value/roadmap-features',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/processes/create_user_value/roadmap_features1'));
      });
    },
    name: 'Roadmap Feature',
    meta: {
      title: 'Roadmap Feature',
      noCache: true
    }
  }, {
    path: 'create-user-value/ideate-features',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/processes/create_user_value/ideate_features'));
      });
    },
    name: 'Ideate Feature',
    meta: {
      title: 'Ideate Feature',
      noCache: true
    }
  },
  // OPERATE PRODUCT
  {
    path: 'operate-product/analyse-usage',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/processes/operate_product/analyse_usage'));
      });
    },
    name: 'Analyse Usage',
    meta: {
      title: 'Analyse Usage',
      noCache: true
    }
  },
  // MARKET GROW
  {
    path: 'market-grow/dummy1',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/processes/market_grow/dummy1'));
      });
    },
    name: 'Dummy1',
    meta: {
      title: 'Dummy1',
      noCache: true
    }
  }, {
    path: 'market-grow/dummy2',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/processes/market_grow/dummy2'));
      });
    },
    name: 'Dummy2',
    meta: {
      title: 'Dummy2',
      noCache: true
    }
  },
  /*    // MARKET GROW
      {
        path: 'my-business',
        component: () => import('@/views/processes/my_business/index'),
        name: 'My Business',
        meta: { title: 'My Business', noCache: true }
      },
      {
        path: 'my-business/customer-value',
        component: () => import('@/views/processes/my_business/customer_value'),
        name: 'Customers',
        meta: { title: 'Customers', noCache: true }
      },*/

  // DESIGN PRODUCT
  {
    path: 'software-design/define-user-interactions',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/processes/software_design/define_user_interactions'));
      });
    },
    name: 'Define User Interactions',
    meta: {
      title: 'Define User Interactions',
      noCache: true
    }
  }, {
    path: 'software-design/navigation-and-wireframe',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/processes/software_design/navigation_and_wireframe'));
      });
    },
    name: 'Navigation and Wireframe',
    meta: {
      title: 'Navigation and Wireframe',
      noCache: true
    }
  }, {
    path: 'software-design/data-design',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/processes/software_design/data_design'));
      });
    },
    name: 'Data Design',
    meta: {
      title: 'Data Design',
      noCache: true
    }
  }, {
    path: 'software-design/components-and-apis',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/processes/software_design/components_and_apis'));
      });
    },
    name: 'Components And APIS',
    meta: {
      title: 'Components And APIS',
      noCache: true
    }
  },
  // Sync To Ticketing
  {
    path: 'deliver_build/dummy1',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/processes/deliver_build/dummy1'));
      });
    },
    name: 'Integration Settings',
    meta: {
      title: 'Integration Settings',
      noCache: true
    }
  }, {
    path: 'deliver_build/dummy2',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/processes/deliver_build/dummy2'));
      });
    },
    name: 'Configure Integration',
    meta: {
      title: 'Configure Integration',
      noCache: true
    }
  }, {
    path: 'deliver_build/dummy3',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/processes/deliver_build/dummy3'));
      });
    },
    name: 'View Progress',
    meta: {
      title: 'View Progress',
      noCache: true
    }
  }]
};
var _default = exports.default = processRouter;