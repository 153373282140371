"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/taggedTemplateLiteral.js"));
require("core-js/modules/es6.number.constructor");
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9;
var _default2 = exports.default = {
  name: 'Overview',
  components: {},
  props: {
    customer: {
      type: Object,
      required: true,
      default: function _default() {
        return {
          id: '',
          title: '',
          /* added JH 25/3/22*/
          description: '',
          priority: '',
          rationale: '',
          problemstatement: '',
          valueproposition: '',
          createdat: '',
          /* added JH 25/3/22*/
          createdby: '',
          /* added JH 25/3/22*/
          linkedOfferings: []
        };
      }
    },
    index: {
      type: Number,
      default: 0
    },
    overviewLoading: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      loading: false,
      selected_primary_offering: '',
      selected_secondary_offering: '',
      primary_offerings: [],
      secondary_offerings: [],
      orphan_offerings: []
    };
  },
  computed: {
    primaryOfferingList: function primaryOfferingList() {
      var offerings = [];
      if (this.customer.linkedOfferings.length > 0) {
        for (var i = 0; i < this.customer.linkedOfferings.length; i++) {
          if (this.customer.linkedOfferings[i].offering.offeringcategory === 'Primary') {
            offerings.push(this.customer.linkedOfferings[i]);
          }
        }
      }
      return offerings;
    },
    secondaryOfferingList: function secondaryOfferingList() {
      var offerings = [];
      if (this.customer.linkedOfferings.length > 0) {
        for (var i = 0; i < this.customer.linkedOfferings.length; i++) {
          if (this.customer.linkedOfferings[i].offering.offeringcategory === 'Secondary') {
            offerings.push(this.customer.linkedOfferings[i]);
          }
        }
      }
      return offerings;
    }
  },
  created: function created() {},
  mounted: function mounted() {
    this.$eventHub.$on('customer-types-changed', this.reloadCustomerTypesOverview);
    this.retrievePrimaryOffering(1);
    this.retrieveSecondaryOffering(1);
    this.retrieveOrphanOffering(1);
  },
  methods: {
    goTo: function goTo(page) {
      this.$emit('goTo', page);
    },
    reloadCustomerTypesOverview: function reloadCustomerTypesOverview() {
      this.retrievePrimaryOffering(1);
      this.retrieveSecondaryOffering(1);
      this.retrieveOrphanOffering(1);
    },
    retrievePrimaryOfferingUrl: function retrievePrimaryOfferingUrl() {
      if (this.customer.linkedOfferings.length > 0) {
        for (var i = 0; i < this.customer.linkedOfferings.length; i++) {
          if (this.customer.linkedOfferings[i].offering.offeringcategory.toLowerCase() === 'primary') {
            return this.customer.linkedOfferings[i].offering.url;
          }
        }
      } else {
        return '';
      }
    },
    retrievePrimaryOfferingTitle: function retrievePrimaryOfferingTitle() {
      if (this.customer.linkedOfferings.length > 0) {
        for (var i = 0; i < this.customer.linkedOfferings.length; i++) {
          if (this.customer.linkedOfferings[i].offering.offeringcategory.toLowerCase() === 'primary') {
            return this.customer.linkedOfferings[i].offering.title;
          }
        }
      } else {
        return '';
      }
    },
    retrievePrimaryOfferingID: function retrievePrimaryOfferingID() {
      if (this.customer.linkedOfferings.length > 0) {
        for (var i = 0; i < this.customer.linkedOfferings.length; i++) {
          if (this.customer.linkedOfferings[i].offering.offeringcategory.toLowerCase() === 'primary') {
            return this.customer.linkedOfferings[i].offering.id;
          }
        }
      } else {
        return '';
      }
    },
    updatePrimaryOffering: function updatePrimaryOffering(offering_id) {
      var _this = this;
      this.loading = true;
      this.$apollo.mutate({
        // Query
        mutation: window._gql(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["mutation linkOfferingToCustomerType($offeringId: String!, $customerTypeId: String!)\n        {\n          linkOfferingToCustomerType(offeringId: $offeringId, customerTypeId: $customerTypeId) {\n            id\n          }\n        }"]))),
        // Parameters
        variables: {
          'offeringId': offering_id,
          'customerTypeId': this.customer.id
        }
      }).then(function (data) {
        console.log(data);
        _this.loading = true;
        _this.$apollo.mutate({
          // Query
          mutation: window._gql(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["mutation updateOffering($offering: UpdateOfferingInput!)\n          {\n            updateOffering(input: $offering) {\n              id\n            }\n          }"]))),
          // Parameters
          variables: {
            'offering': {
              'id': offering_id,
              'offeringcategory': 'Primary',
              'offeringcategorydesc': 'Primary description'
            }
          }
        }).then(function (data) {
          _this.$emit('refresh');
          _this.loading = false;
        }).catch(function (error) {
          // Error
          _this.$notify({
            title: 'Error',
            message: error,
            type: 'error',
            duration: 2000
          });
          _this.loading = false;
        });
        _this.loading = false;
      }).catch(function (error) {
        // Error
        _this.loading = false;
        _this.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
      });
    },
    updateSecondaryOffering: function updateSecondaryOffering(offering_id) {
      var _this2 = this;
      this.loading = true;
      this.$apollo.mutate({
        // Query
        mutation: window._gql(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["mutation linkOfferingToCustomerType($offeringId: String!, $customerTypeId: String!)\n        {\n          linkOfferingToCustomerType(offeringId: $offeringId, customerTypeId: $customerTypeId) {\n            id\n          }\n        }"]))),
        // Parameters
        variables: {
          'offeringId': offering_id,
          'customerTypeId': this.customer.id
        }
      }).then(function (data) {
        _this2.loading = true;
        _this2.$apollo.mutate({
          // Query
          mutation: window._gql(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2.default)(["mutation updateOffering($offering: UpdateOfferingInput!)\n          {\n            updateOffering(input: $offering) {\n              id\n            }\n          }"]))),
          // Parameters
          variables: {
            'offering': {
              'id': offering_id,
              'offeringcategory': 'Secondary',
              'offeringcategorydesc': 'Secondary description'
            }
          }
        }).then(function (data) {
          _this2.$emit('refresh');
          _this2.loading = false;
        }).catch(function (error) {
          // Error
          _this2.$notify({
            title: 'Error',
            message: error,
            type: 'error',
            duration: 2000
          });
          _this2.loading = false;
        });
        _this2.loading = false;
      }).catch(function (error) {
        // Error
        _this2.loading = false;
        _this2.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
      });
    },
    retrieveOrphanOffering: function retrieveOrphanOffering(page_number) {
      var _this3 = this;
      this.loading = true;
      this.offerings = [];
      this.$apollo.query({
        // Query
        query: window._gql(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2.default)(["query Offerings($page: Page, $where: OfferingsCriteriaExpression)\n        {\n          Offerings(page: $page, where: $where)\n          {\n            pages\n            total\n            select{\n              id\n              title\n              description\n              offeringtype\n              offeringcategory\n            }\n          }\n        }"]))),
        fetchPolicy: 'no-cache',
        variables: {
          'page': {
            'limit': 100,
            'start': page_number
          },
          'where': this.computeSearchQuery('ORPHAN')
        }
      }).then(function (data) {
        _this3.orphan_offerings = data.data.Offerings.select;
        _this3.loading = false;
      }).catch(function (error) {
        // Error
        _this3.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this3.loading = false;
      });
    },
    retrieveSecondaryOffering: function retrieveSecondaryOffering(page_number) {
      var _this4 = this;
      this.loading = true;
      this.offerings = [];
      this.$apollo.query({
        // Query
        query: window._gql(_templateObject6 || (_templateObject6 = (0, _taggedTemplateLiteral2.default)(["query Offerings($page: Page, $where: OfferingsCriteriaExpression, $customertypewhere: CustomerTypesCriteriaExpression)\n        {\n          Offerings(page: $page, where: $where)\n          {\n            pages\n            total\n            select{\n              id\n              title\n              description\n              offeringtype\n              offeringcategory\n              linkedCustomerTypes(optional : true) {\n                customerType(optional : true, where: $customertypewhere) {\n                  id\n                }\n              }\n            }\n          }\n        }"]))),
        fetchPolicy: 'no-cache',
        variables: {
          'page': {
            'limit': 100,
            'start': page_number
          },
          'where': this.computeSearchQuery('Secondary'),
          'customertypewhere': this.computeCustomerTypeWhereQuery()
        }
      }).then(function (data) {
        _this4.secondary_offerings = data.data.Offerings.select;
        _this4.loading = false;
      }).catch(function (error) {
        // Error
        _this4.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this4.loading = false;
      });
    },
    retrievePrimaryOffering: function retrievePrimaryOffering(page_number) {
      var _this5 = this;
      this.loading = true;
      this.offerings = [];
      this.$apollo.query({
        // Query
        query: window._gql(_templateObject7 || (_templateObject7 = (0, _taggedTemplateLiteral2.default)(["query Offerings($page: Page, $where: OfferingsCriteriaExpression, $customertypewhere: CustomerTypesCriteriaExpression)\n        {\n          Offerings(page: $page, where: $where)\n          {\n            pages\n            total\n            select{\n              id\n              title\n              description\n              offeringtype\n              offeringcategory\n              linkedCustomerTypes(optional : true) {\n                customerType(optional : true, where: $customertypewhere) {\n                  id\n                }\n              }\n            }\n          }\n        }"]))),
        fetchPolicy: 'no-cache',
        variables: {
          'page': {
            'limit': 100,
            'start': page_number
          },
          'where': this.computeSearchQuery('Primary'),
          'customertypewhere': this.computeCustomerTypeWhereQuery()
        }
      }).then(function (data) {
        _this5.primary_offerings = data.data.Offerings.select;
        _this5.loading = false;
      }).catch(function (error) {
        // Error
        _this5.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this5.loading = false;
      });
    },
    computeSearchQuery: function computeSearchQuery(category) {
      var query = {};
      query.offeringcategory = {
        'EQ': category
      };
      return query;
    },
    computeCustomerTypeWhereQuery: function computeCustomerTypeWhereQuery() {
      var query = {};
      query.id = {
        'LIKE': this.customer.id
      };
      return query;
    },
    removePrimaryOffering: function removePrimaryOffering() {
      var _this6 = this;
      if (this.secondaryOfferingList.length === 0) {
        this.$confirm('This will permanently unlink the offering. Continue?', 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(function () {
          // Unlink Primary
          _this6.$apollo.mutate({
            // Query
            mutation: window._gql(_templateObject8 || (_templateObject8 = (0, _taggedTemplateLiteral2.default)(["mutation deLinkOfferingFromCustomerType($offeringId: String!, $customerTypeId: String!)\n            {\n              deLinkOfferingFromCustomerType(offeringId: $offeringId, customerTypeId: $customerTypeId) {\n                id\n              }\n            }"]))),
            // Parameters
            variables: {
              'offeringId': _this6.retrievePrimaryOfferingID,
              'customerTypeId': _this6.customer.id
            }
          }).then(function (data) {
            console.log(data);
          }).catch(function (error) {
            // Error
            _this6.$notify({
              title: 'Error',
              message: error,
              type: 'error',
              duration: 2000
            });
          });
        }).catch(function () {
          _this6.$message({
            type: 'info',
            message: 'Delete canceled'
          });
        });
      } else {
        this.$message({
          type: 'info',
          message: 'Cannot delete Primary offerings when Secondary offerings exists.'
        });
      }
    },
    removeSecondaryOffering: function removeSecondaryOffering(index) {
      var _this7 = this;
      this.$confirm('This will permanently unlink the offering. Continue?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(function () {
        // Unlink Secondary
        _this7.$apollo.mutate({
          // Query
          mutation: window._gql(_templateObject9 || (_templateObject9 = (0, _taggedTemplateLiteral2.default)(["mutation deLinkOfferingFromCustomerType($offeringId: String!, $customerTypeId: String!)\n          {\n            deLinkOfferingFromCustomerType(offeringId: $offeringId, customerTypeId: $customerTypeId) {\n              id\n            }\n          }"]))),
          // Parameters
          variables: {
            'offeringId': _this7.secondaryOfferingList[index].offering.id,
            'customerTypeId': _this7.customer.id
          }
        }).then(function (data) {
          _this7.$emit('refresh');
        }).catch(function (error) {
          // Error
          _this7.$notify({
            title: 'Error',
            message: error,
            type: 'error',
            duration: 2000
          });
        });
      }).catch(function () {
        _this7.$message({
          type: 'info',
          message: 'Delete canceled'
        });
      });
    },
    updateOverview: function updateOverview() {
      this.$emit('update', this.customer, this.index);
    },
    deleteCustomerType: function deleteCustomerType() {
      this.$emit('delete', this.customer.id, this.customer.title, this.index);
    }
  }
};