"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
var _default = exports.default = {
  name: 'ProblemHypothesis',
  components: {},
  props: {},
  data: function data() {
    return {
      refineProblemDialog: false,
      processes: [{
        value: 'POC Validation',
        label: 'POC'
      }, {
        value: 'WOPL',
        label: 'WOPL'
      }, {
        value: 'RoadMap',
        label: 'RoadMap'
      }],
      sources: [{
        value: 'Business Strategy',
        label: 'Business Strategy'
      }, {
        value: 'GTM Strategy',
        label: 'GTM Strategy'
      }, {
        value: 'Value Engineering',
        label: 'Value Engineering'
      }, {
        value: 'User Journey Map',
        label: 'User Journey Map'
      }, {
        value: 'Feature Refinment',
        label: 'Feature Refinment'
      }, {
        value: 'User Validation',
        label: 'User Validation'
      }, {
        value: 'Software Design',
        label: 'Software Design'
      }, {
        value: 'uUild & Test',
        label: 'Build & Test'
      }, {
        value: 'Customer Support',
        label: 'Customer SUpport'
      }],
      problem_refinement_form: {
        refinement: '',
        description: '',
        process: '',
        solution: '',
        source: '',
        changetype: 'problem',
        version: 0,
        created_at: '2020/03/10',
        created_by: {
          id: '',
          first_name: '',
          last_name: ''
        },
        is_valid: true
      },
      problem: [{
        version: 3,
        refinement: 'Streamline Product Creation',
        description: 'Prow scuttle parrel provost Sail ho shrouds spirits boom mizzenmast yardarm. Pinnace holystone mizzenmast quarter crows nest nipperkin grog yardarm hempen halter furl. Swab barque interloper chantey doubloon starboard grog black jack gangway rutters.',
        solution: 'Prow scuttle parrel provost Sail ho shrouds spirits boom mizzenmast yardarm. Pinnace holystone mizzenmast quarter crows nest nipperkin grog yardarm hempen halter furl. Swab barque interloper chantey doubloon starboard grog black jack gangway rutters.',
        source: 'mockup',
        changetype: 'both',
        created_at: '2020/03/10',
        created_by: {
          id: 1,
          first_name: 'John',
          last_name: 'Doe'
        },
        process: 'POC',
        is_valid: true
      }, {
        version: 2,
        refinement: 'Streamline Product Creation',
        description: 'Prow scuttle parrel provost Sail ho shrouds spirits boom mizzenmast yardarm. Pinnace holystone mizzenmast quarter crows nest nipperkin grog yardarm hempen halter furl. Swab barque interloper chantey doubloon starboard grog black jack gangway rutters.',
        solution: 'Prow scuttle parrel provost Sail ho shrouds spirits boom mizzenmast yardarm. Pinnace holystone mizzenmast quarter crows nest nipperkin grog yardarm hempen halter furl. Swab barque interloper chantey doubloon starboard grog black jack gangway rutters.',
        source: 'pains',
        changetype: 'problem',
        created_at: '2020/03/10',
        created_by: {
          id: 1,
          first_name: 'John',
          last_name: 'Doe'
        },
        process: 'Roadmap',
        is_valid: false
      }, {
        version: 1,
        refinement: 'Streamline Product Creation',
        description: 'Prow scuttle parrel provost Sail ho shrouds spirits boom mizzenmast yardarm. Pinnace holystone mizzenmast quarter crows nest nipperkin grog yardarm hempen halter furl. Swab barque interloper chantey doubloon starboard grog black jack gangway rutters.',
        solution: 'Prow scuttle parrel provost Sail ho shrouds spirits boom mizzenmast yardarm. Pinnace holystone mizzenmast quarter crows nest nipperkin grog yardarm hempen halter furl. Swab barque interloper chantey doubloon starboard grog black jack gangway rutters.',
        source: 'mockup',
        changetype: 'solution',
        created_at: '2020/03/10',
        created_by: {
          id: 1,
          first_name: 'John',
          last_name: 'Doe'
        },
        process: 'WOPL',
        is_valid: false
      }],
      selected_refinement: {
        version: 0,
        refinement: '',
        description: '',
        solution: '',
        source: '',
        created_at: '2020/03/10',
        created_by: {
          id: 1,
          first_name: 'John',
          last_name: 'Doe'
        },
        process: '',
        is_valid: true
      }
    };
  },
  mounted: function mounted() {
    this.$eventHub.$emit('process-step-load', 'problem-&-hypothesis');
    this.selected_refinement = this.problem[0];
  },
  methods: {
    refineProblem: function refineProblem() {
      var problem_refinement = Object.assign({}, this.problem_refinement_form);
      var currentDateWithFormat = new Date().toJSON().slice(0, 10).replace(/-/g, '/');
      problem_refinement.version = this.problem.length + 1;
      problem_refinement.created_at = currentDateWithFormat;
      this.problem.unshift(problem_refinement);
      this.refineProblemDialog = false;
    },
    onClickBusinessRoadmap: function onClickBusinessRoadmap() {
      this.$emit('go-prev');
    },
    selectRefinement: function selectRefinement(row, column, event) {
      this.selected_refinement = row;
    }
  }
};