"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("g", [_vm.mergedEntities.length > 0 ? _c("g", [_c("g", {
    attrs: {
      refs: "model-offering-entities-workspace-border"
    }
  }, [_c("rect", {
    staticClass: "shadow",
    attrs: {
      x: _vm.x,
      y: _vm.y,
      width: 15,
      height: _vm.computeLaneTotalHeight,
      fill: _vm.settings.container.offering.entity.border,
      "stroke-width": "1",
      stroke: "#eee"
    }
  })]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "model-offering-entities-workspace-container"
    }
  }, [_c("rect", {
    staticClass: "shadow",
    attrs: {
      x: "5",
      y: _vm.y,
      width: _vm.width,
      height: _vm.computeLaneTotalHeight,
      fill: "white",
      "stroke-width": "1",
      stroke: "#eee"
    }
  })]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "offering-entities-list"
    }
  }, _vm._l(_vm.mergedEntities, function (entity, entity_index) {
    return _c("g", {
      key: entity_index
    }, [entity.__typename === "LinkProductOffering" ? _c("g", [_c("product-component", {
      attrs: {
        product: entity.product,
        x: _vm.x,
        y: _vm.y + _vm.computeCompundedEntityLaneHeight(entity_index) + 5,
        height: _vm.computeProductLaneHeight(entity),
        width: _vm.width,
        index: entity_index,
        recompute: _vm.recompute,
        "recompute-and-update": _vm.recomputeFeaturePos,
        "phase-x": _vm.phaseX,
        "journey-map": _vm.journeyMap,
        offering: _vm.offering,
        relations: _vm.offering.additionalattributes.relations,
        "is-unmapped-visible": _vm.isUnmappedVisible
      },
      on: {
        settings: _vm.displayProductSettings,
        menu: _vm.displayProductMenu,
        toggle: _vm.resizeEntitySwimlane,
        click: _vm.selectFeatureOrProcess,
        "create-idea": _vm.createProductIdea,
        "feature-tool-dragged": _vm.onFeatureToolDragged,
        "feature-tool-dragged-start": _vm.onFeatureToolDraggedStart,
        "feature-tool-dragged-stop": _vm.onFeatureToolDraggedStop,
        "feature-dragged": _vm.onFeatureDragged,
        "feature-dragged-start": _vm.onFeatureDraggedStart,
        "feature-dragged-stop": _vm.onFeatureDraggedStop,
        "feature-mouse-over": _vm.onFeatureMouseOver,
        "feature-mouse-leave": _vm.onFeatureMouseLeave,
        "update-feature-feature-relation": _vm.updateFeatureFeatureRelation,
        "product-risk-tool-dragged": _vm.onProductRiskToolDragged,
        "product-risk-tool-dragged-start": _vm.onProductRiskToolDraggedStart,
        "product-risk-tool-dragged-stop": _vm.onProductRiskToolDraggedStop,
        "move-entity-up": _vm.moveUpEntitySortOrder,
        "move-entity-down": _vm.moveDownEntitySortOrder,
        reset: _vm.reset
      }
    })], 1) : _vm._e(), _vm._v(" "), entity.__typename === "LinkServiceOffering" ? _c("g", [_c("service-component", {
      attrs: {
        service: entity.service,
        x: _vm.x,
        y: _vm.y + _vm.computeCompundedEntityLaneHeight(entity_index) + 5,
        height: _vm.computeServiceLaneHeight(entity),
        width: _vm.width,
        index: entity_index,
        recompute: _vm.recompute,
        "recompute-and-update": _vm.recomputeServiceApplicationPos,
        "phase-x": _vm.phaseX,
        "journey-map": _vm.journeyMap,
        offering: _vm.offering,
        relations: _vm.offering.additionalattributes.relations,
        "is-unmapped-visible": _vm.isUnmappedVisible
      },
      on: {
        settings: _vm.displayServiceSettings,
        menu: _vm.displayServiceMenu,
        toggle: _vm.resizeEntitySwimlane,
        click: _vm.selectFeatureOrProcess,
        "create-idea": _vm.createServiceIdea,
        "service-application-tool-dragged": _vm.onServiceApplicationToolDragged,
        "service-application-tool-dragged-start": _vm.onServiceApplicationToolDraggedStart,
        "service-application-tool-dragged-stop": _vm.onServiceApplicationToolDraggedStop,
        "service-application-dragged": _vm.onServiceApplicationDragged,
        "service-application-dragged-start": _vm.onServiceApplicationDraggedStart,
        "service-application-dragged-stop": _vm.onServiceApplicationDraggedStop,
        "update-service-application-service-application-relation": _vm.updateServiceApplicationServiceApplicationRelation,
        "service-application-mouse-over": _vm.onServiceApplicationMouseOver,
        "service-application-mouse-leave": _vm.onServiceApplicationMouseLeave,
        "service-risk-tool-dragged": _vm.onServiceRiskToolDragged,
        "service-risk-tool-dragged-start": _vm.onServiceRiskToolDraggedStart,
        "service-risk-tool-dragged-stop": _vm.onServiceRiskToolDraggedStop,
        "move-entity-up": _vm.moveUpEntitySortOrder,
        "move-entity-down": _vm.moveDownEntitySortOrder,
        reset: _vm.reset
      }
    })], 1) : _vm._e()]);
  }), 0)]) : _c("g", [_c("s-v-g-c-t-a-component", {
    attrs: {
      display: _vm.mergedEntities.length === 0,
      title: "No products & services Found",
      description: "Would you like to add new product / service",
      "button-text": ["Add product", "Add service"],
      x: 5,
      y: _vm.y,
      width: _vm.width,
      height: 300
    },
    on: {
      response: _vm.addEntity
    }
  })], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;