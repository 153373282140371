"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("v-stage", {
    ref: "stage",
    attrs: {
      config: _vm.stageSize
    },
    on: {
      mousedown: _vm.handleStageMouseDown,
      touchstart: _vm.handleStageMouseDown
    }
  }, [_c("v-layer", {
    ref: "layer"
  }, [_vm._l(_vm.rectangles, function (item) {
    return _c("v-rect", {
      key: item.id,
      attrs: {
        config: item
      },
      on: {
        transformend: _vm.handleTransformEnd
      }
    });
  }), _vm._v(" "), _c("v-transformer", {
    ref: "transformer"
  })], 2)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;