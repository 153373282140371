"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.number.constructor");
var _default2 = exports.default = {
  name: 'Note',
  components: {},
  props: {
    note: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    index: {
      type: Number,
      default: 0
    }
  },
  data: function data() {
    return {
      loading: false
    };
  },
  computed: {},
  created: function created() {},
  methods: {
    generateNoteColor: function generateNoteColor(color) {
      return 'color:' + color + ';font-size:25px;';
    },
    generateNoteID: function generateNoteID(index) {
      return 'note_' + index;
    },
    startNoteMove: function startNoteMove(_ref) {
      var el = _ref.el,
        deltaX = _ref.deltaX,
        deltaY = _ref.deltaY,
        offsetX = _ref.offsetX,
        offsetY = _ref.offsetY,
        clientX = _ref.clientX,
        clientY = _ref.clientY,
        first = _ref.first,
        last = _ref.last;
      if (deltaX === undefined || deltaY === undefined) {
        return;
      }
      var index = el.id.replace('note_', '');
      this.$emit('note-move', parseInt(deltaX), parseInt(deltaY), index);
    }
  }
};