"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/taggedTemplateLiteral.js"));
var _interopRequireWildcard2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _templateObject, _templateObject2, _templateObject3;
var _default = exports.default = {
  name: 'Vision',
  components: {
    Tinymce: function Tinymce() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/components/Tinymce'));
      });
    }
  },
  props: {},
  data: function data() {
    return {
      otherOptionsDialog: false,
      loading: false,
      vision_and_goals: {
        product_vision: '',
        id: ''
      },
      hover: {
        type: '',
        x: 0,
        y: 0
      }
    };
  },
  mounted: function mounted() {
    this.$eventHub.$emit('process-step-load', 'define-business');
    this.retrieveVision(1);
  },
  methods: {
    activeHover: function activeHover(type, x, y) {
      this.hover.type = type;
      this.hover.x = x;
      this.hover.y = y;
    },
    retrieveVision: function retrieveVision(page_number) {
      var _this = this;
      this.loading = true;
      this.$apollo.query({
        // Query
        query: window._gql(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)([" query BusinessVisions($page: Page)\n        {\n        BusinessVisions(page: $page)\n        {\n          pages\n          total\n          select{\n            id\n            title\n            description\n            vision\n          }\n        }\n      }"]))),
        variables: {
          'page': {
            'limit': 20,
            'start': page_number
          }
        }
      }).then(function (data) {
        if (data.data.BusinessVisions.select.length > 0) {
          _this.vision_and_goals.product_vision = data.data.BusinessVisions.select[0].vision;
          _this.vision_and_goals.id = data.data.BusinessVisions.select[0].id;
        }
        _this.loading = false;
      }).catch(function (error) {
        // Error
        _this.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this.loading = false;
      });
    },
    createUpdateVisionContent: function createUpdateVisionContent(vision) {
      this.vision_and_goals.product_vision = vision;
    },
    createUpdateVision: function createUpdateVision() {
      if (this.vision_and_goals.id) {
        this.updateVision(this.vision_and_goals.product_vision);
      } else {
        this.createVision(this.vision_and_goals.product_vision);
      }
    },
    createVision: function createVision(vision) {
      var _this2 = this;
      this.loading = true;
      this.$apollo.mutate({
        // Query
        mutation: window._gql(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["mutation createBusinessVision($busVision: CreateBusinessVisionInput!)\n        {\n          createBusinessVision(input: $busVision) {\n            id,\n            title,\n            description,\n            vision\n          }\n        }"]))),
        // Parameters
        variables: {
          'busVision': {
            'vision': vision
          }
        }
      }).then(function (data) {
        _this2.loading = false;
        _this2.vision_and_goals.product_vision = data.data.createBusinessVision.vision;
        _this2.vision_and_goals.id = data.data.createBusinessVision.id;
      }).catch(function (error) {
        _this2.loading = false;
        // Error
        _this2.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
      });
    },
    updateVision: function updateVision(vision) {
      var _this3 = this;
      this.loading = true;
      this.$apollo.mutate({
        // Query
        mutation: window._gql(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["mutation updateBusinessVision($busVision: UpdateBusinessVisionInput!)\n        {\n          updateBusinessVision(input: $busVision) {\n            id,\n            title,\n            description,\n            vision\n          }\n        }"]))),
        // Parameters
        variables: {
          'busVision': {
            'id': this.vision_and_goals.id,
            'vision': vision
          }
        }
      }).then(function (data) {
        _this3.loading = false;
        _this3.vision_and_goals.product_vision = data.data.updateBusinessVision.vision;
      }).catch(function (error) {
        _this3.loading = false;
        // Error
        _this3.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
      });
    },
    generateBezierPath: function generateBezierPath(start_x1, start_y1, start_x2, start_y2) {
      var end_x1 = start_x1 + (start_x2 - start_x1) / 2;
      var end_y1 = start_y1;
      var end_x2 = start_x2 - (start_x2 - start_x1) / 2;
      var end_y2 = start_y2;
      return 'M' + start_x1 + ',' + start_y1 + ' C' + end_x1 + ',' + end_y1 + ' ' + end_x2 + ',' + end_y2 + ' ' + start_x2 + ',' + start_y2;
    },
    generateCubicBezierPath: function generateCubicBezierPath(start_x1, start_y1, start_x2, start_y2) {
      var end_x1 = start_x1 - 25;
      var end_y1 = start_y1;
      var end_x2 = start_x2 - 25;
      var end_y2 = start_y2;
      return 'M' + start_x1 + ',' + start_y1 + ' C' + end_x1 + ',' + end_y1 + ' ' + end_x2 + ',' + end_y2 + ' ' + start_x2 + ',' + start_y2;
    }
  }
};