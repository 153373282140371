"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.width > 0 ? _c("g", {
    on: {
      mouseover: _vm.onMouseOver,
      mouseleave: _vm.onMouseLeave
    }
  }, [_c("defs", [_c("linearGradient", {
    attrs: {
      id: "release_title_fill_gradient",
      x1: "0%",
      y1: "0%",
      x2: "100%",
      y2: "0%"
    }
  }, [_c("stop", {
    attrs: {
      offset: "0%",
      "stop-color": "#447799"
    }
  }), _vm._v(" "), _c("stop", {
    attrs: {
      offset: "50%",
      "stop-color": "#224488"
    }
  }), _vm._v(" "), _c("stop", {
    attrs: {
      offset: "100%",
      "stop-color": "#112266"
    }
  })], 1)], 1), _vm._v(" "), _c("rect", {
    attrs: {
      x: _vm.x,
      y: "0",
      rx: "10px",
      ry: "10px",
      width: _vm.width,
      height: "30",
      fill: "url(#release_title_fill_gradient)"
    },
    on: {
      click: function click($event) {
        $event.preventDefault();
        return _vm.emitClick.apply(null, arguments);
      }
    }
  }), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "#EEEEEE"
    },
    attrs: {
      x: _vm.x + _vm.width / 2,
      y: "15",
      "text-anchor": "middle",
      "font-size": "1.0em",
      "font-weight": "bold"
    }
  }, [_vm._v(_vm._s(_vm.release.name))]), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "#EEEEEE"
    },
    attrs: {
      x: _vm.x + _vm.width / 2,
      y: "25",
      "text-anchor": "middle",
      "font-size": "0.7em",
      "font-weight": "lighter"
    }
  }, [_vm._v(_vm._s(_vm.computeTrimmedText(_vm.release.description)))]), _vm._v(" "), _c("rect", {
    attrs: {
      x: _vm.x,
      y: "20",
      width: _vm.width,
      height: _vm.height,
      fill: _vm.computeFill(),
      "fill-opacity": 0.1
    }
  }), _vm._v(" "), _c("line", {
    staticStyle: {
      stroke: "gray",
      "stroke-width": "2",
      "stroke-opacity": "0.2"
    },
    attrs: {
      x1: _vm.x,
      y1: "30",
      x2: _vm.x + _vm.width,
      y2: "30"
    }
  }), _vm._v(" "), _vm.isHover ? _c("g", {
    attrs: {
      refs: "delete_icon"
    },
    on: {
      click: function click($event) {
        $event.preventDefault();
        return _vm.removeRelease.apply(null, arguments);
      }
    }
  }, [_c("foreignObject", {
    attrs: {
      x: _vm.x + _vm.width - 25,
      y: 7,
      width: 15,
      height: 15
    }
  }, [_c("div", {
    staticClass: "text-center"
  }, [_c("svg-icon", {
    staticStyle: {
      "'background-color": "red",
      width: "15px",
      height: "15px"
    },
    attrs: {
      "icon-class": "gradient-circle-error"
    }
  })], 1)])]) : _vm._e()]) : _vm._e();
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;