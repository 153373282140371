"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _optimiseInteractions = _interopRequireDefault(require("./components/define_user_interactions/optimise-interactions"));
var _ProcessSteps = _interopRequireDefault(require("@/components/ProcessSteps"));
// import AddUsersFeaturesComponent from './components/define_user_interactions/add-users-features'
// import RefineStoriesComponent from './components/define_user_interactions/refine-stories'
// import IdentifyDataComponent from './components/define_user_interactions/identify-data'
// import FinaliseDiagramVersionComponent from './components/define_user_interactions/finalise-diagram-version'
var _default = exports.default = {
  name: 'DefineUserInteraction',
  components: {
    // AddUsersFeaturesComponent,
    OptimiseInteractionsComponent: _optimiseInteractions.default,
    // RefineStoriesComponent,
    // IdentifyDataComponent,
    // FinaliseDiagramVersionComponent,
    ProcessSteps: _ProcessSteps.default
  },
  data: function data() {
    return {
      active_step: 0,
      steps: [{
        name: 'Generate & Tailor',
        shortDescription: 'Generate & Tailor',
        longDescription: 'This screen shows all of your users and what features they can access (who can do what).  To save time, it is generated from the journey maps, features, and stories. You can then tailor it as you desire - but avoid adding features or stories as this should be done in the journey map,  feature ideation, or refine features.'
      }, {
        name: 'Functional Design',
        shortDescription: 'Functional Design',
        longDescription: 'This step allows features to functionally described in more detail. This includes adding additional stories as well as adding mockups (uploaded).'
      }, {
        name: 'Versions & Approvals',
        shortDescription: 'Versions & Approvals',
        longDescription: 'Each feature (use case) can be managed using versions, allowing backlog activities to be driven directly from a UI design.  Once approved, "task" tickets that map to UI components can be generated, and progress tracked.'
      }, {
        name: 'Progress & Quality',
        shortDescription: 'Ticketing & Progress',
        longDescription: 'View the progress of each version can be viewed on this screen. Also view defects related to tickets (requires ticketing systems to be setup properly).'
      }]
    };
  },
  mounted: function mounted() {},
  methods: {
    updateStep: function updateStep(active_step) {
      this.active_step = active_step;
    }
  }
};