"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _default2 = exports.default = {
  name: 'Goal',
  components: {},
  props: {
    goal: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    phaseParam: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    index: {
      type: Number,
      default: 0
    },
    selectedActivityIndex: {
      type: Number,
      default: 0
    },
    selectedPhaseIndex: {
      type: Number,
      default: 0
    },
    subIndex: {
      type: Number,
      default: 0
    },
    goalSubIndex: {
      type: Number,
      default: 0
    },
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    }
  },
  data: function data() {
    return {
      loading: false
    };
  },
  computed: {},
  created: function created() {},
  methods: {
    deleteGoal: function deleteGoal(index, activity_index, goal_index) {
      this.$emit('delete-goal', index, activity_index, goal_index);
    }
  }
};