"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _default2 = exports.default = {
  props: {
    preview: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    contenteditable: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    itemIndex: {
      type: Number,
      default: 0
    },
    item: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      editImage: false
    };
  },
  computed: {
    getStyle: function getStyle() {
      return 'background-image:url(' + this.item.location + '); height: 100%; background-size: contain; background-repeat: no-repeat; border-radius: 5px; background-position: center;';
    }
  },
  methods: {
    save: function save(e) {
      this.item.location = e.target.value;
      this.$emit('save-item', {
        item: this.item,
        index: this.itemIndex
      });
    }
  }
};