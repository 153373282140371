"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("g", [_c("transition", {
    attrs: {
      name: "fade",
      mode: "out-in"
    }
  }, [_vm.isUserSelected ? _c("g", [_c("g", {
    on: {
      click: _vm.addPainPoint
    }
  }, [_c("rect", {
    staticStyle: {
      fill: "whitesmoke",
      stroke: "black",
      "stroke-width": "1",
      opacity: "0.5"
    },
    attrs: {
      x: parseInt(_vm.x) - 85,
      y: parseInt(_vm.y) - 10,
      width: "100",
      height: "30"
    }
  }), _vm._v(" "), _c("text", {
    attrs: {
      x: parseInt(_vm.x) - 35,
      y: parseInt(_vm.y) + 10,
      "font-family": "Verdana",
      "font-size": "10",
      fill: "gray",
      "text-anchor": "middle"
    }
  }, [_vm._v("\n          Pain Point\n        ")])]), _vm._v(" "), _c("g", {
    on: {
      click: _vm.addDesignGoals
    }
  }, [_c("rect", {
    staticStyle: {
      fill: "whitesmoke",
      stroke: "black",
      "stroke-width": "1",
      opacity: "0.5"
    },
    attrs: {
      x: parseInt(_vm.x) - 85,
      y: parseInt(_vm.y) + 30,
      width: "100",
      height: "30"
    }
  }), _vm._v(" "), _c("text", {
    attrs: {
      x: parseInt(_vm.x) - 35,
      y: parseInt(_vm.y) + 50,
      "font-family": "Verdana",
      "font-size": "10",
      fill: "gray",
      "text-anchor": "middle"
    }
  }, [_vm._v("\n          Design Goals\n        ")])])]) : _vm._e()]), _vm._v(" "), _c("circle", {
    attrs: {
      cx: parseInt(_vm.x) + 15,
      cy: parseInt(_vm.y) + 25,
      fill: "none",
      stroke: "white",
      "stroke-width": "1",
      r: "25",
      "fill-opacity": "0.5"
    }
  }), _vm._v(" "), _c("g", {
    on: {
      click: function click($event) {
        _vm.isUserSelected = !_vm.isUserSelected;
      }
    }
  }, [_c("circle", {
    attrs: {
      cx: parseInt(_vm.x) + 15,
      cy: parseInt(_vm.y) + 25,
      fill: "gray",
      stroke: "none",
      "stroke-width": "1",
      r: "25"
    }
  }), _vm._v(" "), _c("circle", {
    attrs: {
      cx: parseInt(_vm.x) + 15,
      cy: parseInt(_vm.y) + 15,
      fill: "none",
      stroke: "white",
      "stroke-width": "1",
      r: "6"
    }
  }), _vm._v(" "), _c("path", {
    attrs: {
      d: _vm.describeArc(parseInt(_vm.x) + 15, parseInt(_vm.y) + 33, 12, 270, 90),
      fill: "none",
      stroke: "white",
      "stroke-width": "1"
    }
  }), _vm._v(" "), _c("text", {
    attrs: {
      x: parseInt(_vm.x) + 2,
      y: parseInt(_vm.y) + 45,
      fill: "white",
      "font-size": "10",
      "font-weight": "bold"
    }
  }, [_vm._v(_vm._s(_vm.user) + " ")])])], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;