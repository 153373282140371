"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticStyle: {
      border: "solid 0.5pt lightgrey",
      "border-radius": "5pt",
      width: "664pt",
      "margin-left": "10pt",
      "padding-bottom": "10pt  x"
    }
  }, [_c("div", {
    staticStyle: {
      padding: "0",
      "border-bottom": "solid 0.5pt lightgrey",
      "border-radius": "5pt 5pt 0 0"
    }
  }), _vm._v(" "), _vm._l(_vm.discussions, function (discussion, index) {
    return _c("div", {
      key: index,
      class: _vm.checkIfUserIsAuthor(_vm.getUserFromUserID(discussion.detail.createdby)) ? "msg right-msg" : "msg left-msg",
      staticStyle: {
        "margin-left": "5pt !important",
        "margin-right": "5pt !important",
        margin: "20pt 10pt 20pt 5pt !important",
        padding: "2pt"
      }
    }, [_c("div", {
      staticClass: "msg-bubble",
      staticStyle: {
        "min-width": "600pt !important",
        "margin-bottom": "0pt !important",
        "box-shadow": "2pt 2pt 3pt 1pt lightgrey"
      }
    }, [_c("div", {
      staticClass: "msg-info fr msg-details p-5",
      staticStyle: {
        "border-radius": "8pt 8pt 0 8pt",
        display: "inline",
        width: "15%",
        "margin-bottom": "0"
      }
    }, [_c("span", {
      staticClass: "msg-info-name",
      staticStyle: {
        display: "inline-block"
      }
    }, [_vm._v(_vm._s(_vm.getUserFromUserID(discussion.detail.createdby)))]), _c("br"), _vm._v(" "), _c("span", {
      staticClass: "msg-info-time",
      staticStyle: {
        display: "inline-block"
      }
    }, [_vm._v(_vm._s(_vm.computeHumanFriendlyDateDifference(discussion.detail.createdat)))])]), _vm._v(" "), _c("div", {
      staticClass: "msg-info fl msg-comment p-5",
      staticStyle: {
        "border-radius": "5pt 5pt 0 5pt",
        display: "inline",
        width: "80%",
        "margin-bottom": "0"
      }
    }, [_c("div", {
      staticClass: "msg-text"
    }, [_vm._v("\n          " + _vm._s(discussion.detail.comment) + "\n        ")])])])]);
  }), _vm._v(" "), _c("div", {
    staticClass: "mb-20",
    staticStyle: {
      width: "660pt",
      display: "inline-block"
    }
  }, [_c("el-button", {
    staticClass: "fr ml-10",
    attrs: {
      slot: "append",
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.addComment
    },
    slot: "append"
  }, [_vm._v("Send")]), _vm._v(" "), _c("el-input", {
    staticClass: "comment-msger-input fr",
    staticStyle: {
      width: "80%",
      "border-radius": "10pt"
    },
    attrs: {
      size: "mini",
      type: "text",
      placeholder: "Enter your message..."
    },
    model: {
      value: _vm.comment,
      callback: function callback($$v) {
        _vm.comment = $$v;
      },
      expression: "comment"
    }
  })], 1)], 2);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;