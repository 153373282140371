"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-row", {
    staticClass: "mt-10",
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-card", [_c("el-tabs", {
    attrs: {
      value: "Individual"
    },
    on: {
      "tab-click": _vm.handleClick
    }
  }, _vm._l(_vm.userTypes, function (user_type, index) {
    return _c("el-tab-pane", {
      key: index,
      attrs: {
        label: user_type.name,
        name: user_type.name
      }
    }, [_c("el-alert", {
      staticClass: "help_note",
      attrs: {
        title: "Scenarios",
        description: "Optional. Create scenarios for this user type, describing it with steps, mockups, and a storyboard.",
        type: "info",
        "show-icon": "",
        effect: "dark"
      }
    }), _vm._v(" "), _c("el-row", {
      staticClass: "mt-5"
    }, [_c("el-col", {
      attrs: {
        span: 24
      }
    }, [_c("el-row", {
      attrs: {
        gutter: 20
      }
    }, [_c("el-col", {
      attrs: {
        span: 6
      }
    }, [_c("el-card", {
      staticClass: "mt-10 item-new"
    }, [_c("div", {
      attrs: {
        slot: "header"
      },
      slot: "header"
    }, [_c("el-row", [_c("el-col", {
      attrs: {
        span: 18
      }
    }, [_c("div", [_c("strong", [_vm._v("Create Scenario")])])])], 1)], 1), _vm._v(" "), _c("div", {
      staticClass: "text-center",
      on: {
        click: function click($event) {
          _vm.addScenarioDialog = true;
        }
      }
    }, [_c("i", {
      staticClass: "el-icon-circle-plus-outline icon-large"
    })]), _vm._v(" "), _c("el-divider"), _vm._v(" "), _c("div", {
      staticClass: "text-center"
    }, [_vm._v("Create a new Scenario")])], 1)], 1), _vm._v(" "), _vm._l(_vm.scenarios, function (scenario, sub_index) {
      return _c("el-col", {
        key: sub_index,
        attrs: {
          span: 6
        }
      }, [_c("el-card", {
        staticClass: "mt-10",
        attrs: {
          id: index
        }
      }, [_c("div", {
        attrs: {
          slot: "header"
        },
        slot: "header"
      }, [_c("strong", [_vm._v(" " + _vm._s(scenario.name) + " ")]), _vm._v(" "), _c("i", {
        staticClass: "el-icon-edit fr",
        on: {
          click: function click($event) {
            return _vm.openScenarioDetailsModal(scenario, sub_index);
          }
        }
      }), _vm._v(" "), _c("i", {
        staticClass: "el-icon-close fr",
        on: {
          click: function click($event) {
            return _vm.removeScenarioCard(sub_index);
          }
        }
      })]), _vm._v("\n                      " + _vm._s(scenario.goal) + "\n                    ")])], 1);
    })], 2)], 1)], 1)], 1);
  }), 1)], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    staticClass: "mt-10"
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-card", [_c("div", {
    staticClass: "text-right"
  }, [_c("el-button-group", {
    attrs: {
      size: "mini"
    }
  }, [_c("el-tooltip", {
    attrs: {
      effect: "dark",
      content: "Go To Define User Journey",
      placement: "top-start"
    }
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      icon: "el-icon-arrow-left"
    },
    on: {
      click: _vm.gotoDefineUserJourney
    }
  }, [_vm._v("Previous")])], 1), _vm._v(" "), _c("el-tooltip", {
    attrs: {
      effect: "dark",
      content: "Go To Define User Value Proposition",
      placement: "top-start"
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.gotoDefineUserValueProposition
    }
  }, [_vm._v("Next"), _c("i", {
    staticClass: "el-icon-arrow-right"
  })])], 1)], 1)], 1)])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Add Scenario",
      visible: _vm.addScenarioDialog,
      width: "40%",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addScenarioDialog = $event;
      }
    }
  }, [_c("el-form", {
    ref: "addScenarioForm",
    staticClass: "mt-5 container",
    attrs: {
      model: _vm.scenario_form,
      "label-width": "120px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Name"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.scenario_form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.scenario_form, "name", $$v);
      },
      expression: "scenario_form.name"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Goal"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.scenario_form.goal,
      callback: function callback($$v) {
        _vm.$set(_vm.scenario_form, "goal", $$v);
      },
      expression: "scenario_form.goal"
    }
  })], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.addScenarioDialog = false;
      }
    }
  }, [_vm._v("Cancel")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.addScenario
    }
  }, [_vm._v("Add")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Scenario Details",
      visible: _vm.scenarioDetailsDialog,
      width: "90%",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.scenarioDetailsDialog = $event;
      }
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("div", {
    staticClass: "sub-title-left"
  }, [_vm._v("Name")]), _vm._v(" "), _c("div", {
    staticClass: "container"
  }, [_c("el-input", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.activeScenario.name,
      callback: function callback($$v) {
        _vm.$set(_vm.activeScenario, "name", $$v);
      },
      expression: "activeScenario.name"
    }
  })], 1)]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("div", {
    staticClass: "sub-title-right"
  }, [_vm._v("Goal")]), _vm._v(" "), _c("div", {
    staticClass: "container"
  }, [_c("el-input", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.activeScenario.goal,
      callback: function callback($$v) {
        _vm.$set(_vm.activeScenario, "goal", $$v);
      },
      expression: "activeScenario.goal"
    }
  })], 1)])], 1), _vm._v(" "), _c("el-row", {
    staticClass: "mt-10",
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-row", [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("div", {
    staticClass: "sub-title-left"
  }, [_vm._v("Storyboard")]), _vm._v(" "), _c("br"), _vm._v(" "), _c("div", {
    staticClass: "container"
  }, _vm._l(_vm.activeScenario.storyboards, function (storyboard, index) {
    return _c("el-tag", {
      key: index
    }, [_vm._v(_vm._s(storyboard.name))]);
  }), 1)])], 1), _vm._v(" "), _c("el-row", {
    staticClass: "fr"
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini",
      icon: "el-icon-plus"
    },
    on: {
      click: function click($event) {
        return _vm.openAddStoryBoardModal(_vm.activeScenario, _vm.activeScenarioIndex);
      }
    }
  }, [_vm._v(" Upload Storyboard")])], 1)], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-row", [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("div", {
    staticClass: "sub-title-right"
  }, [_vm._v("Mockups")]), _vm._v(" "), _c("br"), _vm._v(" "), _c("div", {
    staticClass: "container"
  }, _vm._l(_vm.activeScenario.mockups, function (mockup, index) {
    return _c("el-tag", {
      key: index
    }, [_vm._v(_vm._s(mockup.name))]);
  }), 1)])], 1), _vm._v(" "), _c("el-row", {
    staticClass: "fr"
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini",
      icon: "el-icon-plus"
    },
    on: {
      click: function click($event) {
        return _vm.openAddMockupModal(_vm.activeScenario, _vm.activeScenarioIndex);
      }
    }
  }, [_vm._v(" Upload Mockup")])], 1)], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    staticClass: "mt-10",
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("div", {
    staticClass: "sub-title-right"
  }, [_vm._v("Scenarios")]), _vm._v(" "), _c("br"), _vm._v(" "), _c("div", {
    staticClass: "container"
  }, [_c("el-table", {
    attrs: {
      data: _vm.activeScenario.steps
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      width: "50"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "name",
      label: "Name"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "storyboards",
      label: "Storyboard"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "mockups",
      label: "Mockup"
    }
  })], 1)], 1), _vm._v(" "), _c("el-row", {
    staticClass: "fr"
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      icon: "el-icon-plus",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.openAddStepModal(_vm.activeScenario, _vm.activeScenarioIndex);
      }
    }
  }, [_vm._v("Add Step")])], 1)], 1)], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.scenarioDetailsDialog = false;
      }
    }
  }, [_vm._v("Cancel")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Add Step",
      visible: _vm.addStepDialog,
      width: "80%",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addStepDialog = $event;
      }
    }
  }, [_c("el-form", {
    ref: "addStepForm",
    staticClass: "mt-5 container",
    attrs: {
      model: _vm.step_form,
      "label-width": "120px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Name"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.step_form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.step_form, "name", $$v);
      },
      expression: "step_form.name"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Mockup"
    }
  }, [_c("el-select", {
    attrs: {
      multiple: "",
      placeholder: "Select"
    },
    model: {
      value: _vm.step_form.mockups,
      callback: function callback($$v) {
        _vm.$set(_vm.step_form, "mockups", $$v);
      },
      expression: "step_form.mockups"
    }
  }, _vm._l(_vm.mockups, function (mockup) {
    return _c("el-option", {
      key: mockup.name,
      attrs: {
        label: mockup.name,
        value: mockup.name
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Storyboard"
    }
  }, [_c("el-select", {
    attrs: {
      multiple: "",
      placeholder: "Select"
    },
    model: {
      value: _vm.step_form.storyboards,
      callback: function callback($$v) {
        _vm.$set(_vm.step_form, "storyboards", $$v);
      },
      expression: "step_form.storyboards"
    }
  }, _vm._l(_vm.storyboards, function (storyboard) {
    return _c("el-option", {
      key: storyboard.name,
      attrs: {
        label: storyboard.name,
        value: storyboard.name
      }
    });
  }), 1)], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.addStepDialog = false;
      }
    }
  }, [_vm._v("Cancel")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.addStep
    }
  }, [_vm._v("Add")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Add Storyboard",
      visible: _vm.addStoryBoardDialog,
      width: "80%",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addStoryBoardDialog = $event;
      }
    }
  }, [_c("el-form", {
    ref: "addStoryBoardForm",
    staticClass: "mt-5 container",
    attrs: {
      model: _vm.storyboard_form,
      "label-width": "120px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Name"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.storyboard_form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.storyboard_form, "name", $$v);
      },
      expression: "storyboard_form.name"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Storyboard"
    }
  }, [_c("el-upload", {
    ref: "upload",
    staticClass: "upload-demo",
    attrs: {
      "auto-upload": false,
      action: ""
    }
  }, [_c("el-button", {
    attrs: {
      slot: "trigger",
      size: "small",
      type: "primary"
    },
    slot: "trigger"
  }, [_vm._v("select file")]), _vm._v(" "), _c("el-button", {
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      size: "small",
      type: "success"
    }
  }, [_vm._v("upload to server")]), _vm._v(" "), _c("div", {
    staticClass: "el-upload__tip",
    attrs: {
      slot: "tip"
    },
    slot: "tip"
  }, [_vm._v("jpg/png files with a size less than 500kb")])], 1)], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.addStoryBoardDialog = false;
      }
    }
  }, [_vm._v("Cancel")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.addStoryBoard
    }
  }, [_vm._v("Add")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Add Mockup",
      visible: _vm.addMockupDialog,
      width: "80%",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addMockupDialog = $event;
      }
    }
  }, [_c("el-form", {
    ref: "addMockupForm",
    staticClass: "mt-5 container",
    attrs: {
      model: _vm.mockup_form,
      "label-width": "120px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Name"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.mockup_form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.mockup_form, "name", $$v);
      },
      expression: "mockup_form.name"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Mockup"
    }
  }, [_c("el-upload", {
    ref: "upload",
    staticClass: "upload-demo",
    attrs: {
      "auto-upload": false,
      action: ""
    }
  }, [_c("el-button", {
    attrs: {
      slot: "trigger",
      size: "small",
      type: "primary"
    },
    slot: "trigger"
  }, [_vm._v("select file")]), _vm._v(" "), _c("el-button", {
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      size: "small",
      type: "success"
    }
  }, [_vm._v("upload to server")]), _vm._v(" "), _c("div", {
    staticClass: "el-upload__tip",
    attrs: {
      slot: "tip"
    },
    slot: "tip"
  }, [_vm._v("jpg/png files with a size less than 500kb")])], 1)], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.addMockupDialog = false;
      }
    }
  }, [_vm._v("Cancel")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.addMockup
    }
  }, [_vm._v("Add")])], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;