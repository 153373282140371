"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "ticket-modal"
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("table", {
    staticClass: "ml-10",
    staticStyle: {
      "margin-bottom": "10pt",
      "margin-right": "20pt"
    },
    attrs: {
      width: "96%"
    }
  }, [_c("col", {
    staticStyle: {
      width: "30%"
    }
  }), _vm._v(" "), _c("col", {
    staticStyle: {
      width: "60%"
    }
  }), _vm._v(" "), _c("tr", {
    staticClass: "text-left"
  }, [_c("th", [_vm._v("Name")]), _vm._v(" "), _c("td", [_c("el-input", {
    staticStyle: {
      "margin-top": "1pt",
      "margin-bottom": "1pt"
    },
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.ticket.title,
      callback: function callback($$v) {
        _vm.$set(_vm.ticket, "title", $$v);
      },
      expression: "ticket.title"
    }
  })], 1)]), _vm._v(" "), _c("tr", {
    staticClass: "text-left"
  }, [_c("th", [_vm._v("Ticket ID")]), _vm._v(" "), _c("td", [_c("el-input", {
    staticClass: "mt-5",
    staticStyle: {
      "margin-top": "1pt",
      "margin-bottom": "1pt"
    },
    attrs: {
      size: "mini",
      disabled: ""
    },
    model: {
      value: _vm.ticket.slug,
      callback: function callback($$v) {
        _vm.$set(_vm.ticket, "slug", $$v);
      },
      expression: "ticket.slug"
    }
  })], 1)]), _vm._v(" "), _c("tr", {
    staticClass: "text-left"
  }, [_c("th", [_vm._v("Type")]), _vm._v(" "), _c("td", [_c("el-select", {
    staticStyle: {
      "margin-top": "1pt",
      "margin-bottom": "1pt"
    },
    attrs: {
      placeholder: "Ticket Type",
      size: "mini"
    },
    model: {
      value: _vm.ticket.type,
      callback: function callback($$v) {
        _vm.$set(_vm.ticket, "type", $$v);
      },
      expression: "ticket.type"
    }
  }, _vm._l(_vm.tickettypes, function (type) {
    return _c("el-option", {
      key: type.value,
      attrs: {
        label: type.label,
        value: type.value
      }
    });
  }), 1)], 1)]), _vm._v(" "), _c("tr", {
    staticClass: "text-left"
  }, [_c("th", [_vm._v("Enabler")]), _vm._v(" "), _c("td", [_c("el-checkbox", {
    model: {
      value: _vm.ticket.enabler,
      callback: function callback($$v) {
        _vm.$set(_vm.ticket, "enabler", $$v);
      },
      expression: "ticket.enabler"
    }
  })], 1)])])])], 1), _vm._v(" "), _c("el-tabs", {
    attrs: {
      type: ""
    },
    model: {
      value: _vm.activeTab,
      callback: function callback($$v) {
        _vm.activeTab = $$v;
      },
      expression: "activeTab"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "Ticket Details",
      name: "ticket_details"
    }
  }, [_c("el-row", {
    staticStyle: {
      "background-color": "ghostwhite",
      "border-radius": "5pt",
      border: "solid 0.5pt lightgrey",
      "box-shadow": "3px 3px 3px 1px lightgrey"
    }
  }, [_c("el-col", {
    staticClass: "mt-5",
    attrs: {
      span: 12
    }
  }, [_c("div", {}, [_c("strong", [_vm._v("Detailed Description")])]), _vm._v(" "), _c("tinymce-component", {
    staticClass: "mt-5",
    attrs: {
      value: _vm.ticket.description,
      height: 300,
      upload: false,
      menubar: "",
      toolbar: ["bold italic underline | alignleft aligncenter alignright | bullist numlist | outdent indent"]
    },
    on: {
      update: _vm.updateTicketDescription
    }
  }), _vm._v(" "), _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.attachment_loading,
      expression: "attachment_loading"
    }]
  }, [_c("div", {
    staticClass: "mt-10"
  }, [_c("strong", [_vm._v("Attachments")])]), _vm._v(" "), _c("div", {
    staticClass: "mt-10",
    staticStyle: {
      "margin-bottom": "10pt",
      width: "85%",
      display: "inline-block"
    }
  }, [_c("file-upload-component", {
    attrs: {
      "is-edit": true,
      "file-list": _vm.files,
      "entity-id": _vm.ticket.id,
      "entity-name": "TICKET",
      "display-type": "table"
    },
    on: {
      "file-uploaded": _vm.retrieveTicketAttachements
    }
  })], 1)]), _vm._v(" "), _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.child_ticket_loading,
      expression: "child_ticket_loading"
    }],
    staticStyle: {
      border: "solid 0.5pt lightgrey",
      margin: "6pt 10pt 6pt 10pt",
      "border-radius": "5pt",
      width: "96%",
      "background-color": "white",
      "box-shadow": "inset 0px 0px 3px 1px lightgrey"
    }
  }, [_c("div", {
    staticClass: "ml-5 mt-5"
  }, [_c("strong", [_vm._v("Child Tickets")]), _vm._v(" "), _c("el-button", {
    staticClass: "fr mr-5",
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.addChildTicket
    }
  }, [_vm._v("Add ")])], 1), _vm._v(" "), _c("div", {
    staticClass: "ml-5 mr-5"
  }, [_c("el-table", {
    attrs: {
      data: _vm.child_tickets,
      size: "mini",
      stripe: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Title",
      width: "310"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-input", {
          attrs: {
            type: "text",
            size: "mini"
          },
          model: {
            value: scope.row.title,
            callback: function callback($$v) {
              _vm.$set(scope.row, "title", $$v);
            },
            expression: "scope.row.title"
          }
        })];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Status",
      width: "140"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-select", {
          attrs: {
            placeholder: "Select Status",
            size: "mini"
          },
          model: {
            value: scope.row.status,
            callback: function callback($$v) {
              _vm.$set(scope.row, "status", $$v);
            },
            expression: "scope.row.status"
          }
        }, _vm._l(_vm.statuses, function (status) {
          return _c("el-option", {
            key: status.value,
            staticClass: "slim-list",
            attrs: {
              label: status.label,
              value: status.value,
              width: "60%"
            }
          });
        }), 1)];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Priority",
      width: "100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-select", {
          attrs: {
            placeholder: "Select Priority",
            size: "mini"
          },
          model: {
            value: scope.row.priority,
            callback: function callback($$v) {
              _vm.$set(scope.row, "priority", $$v);
            },
            expression: "scope.row.priority"
          }
        }, _vm._l(_vm.priorities, function (priority) {
          return _c("el-option", {
            key: priority.value,
            staticClass: "slim-list",
            attrs: {
              label: priority.label,
              value: priority.value
            }
          });
        }), 1)];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "#",
      width: "135"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-button", {
          staticClass: "slim-button",
          attrs: {
            type: "primary",
            icon: "far fa-save"
          },
          on: {
            click: function click($event) {
              return _vm.addUpdateChildTicket(scope.row);
            }
          }
        }), _vm._v(" "), _c("el-button", {
          staticClass: "slim-button",
          attrs: {
            type: "warning",
            icon: "fas fa-minus-circle",
            title: "Unlink"
          },
          on: {
            click: function click($event) {
              return _vm.unlinkChildTicketFromParent(scope.row.id, _vm.ticket.id);
            }
          }
        }), _vm._v(" "), _c("el-button", {
          staticClass: "slim-button",
          attrs: {
            type: "danger",
            icon: "el-icon-delete",
            title: "Delete"
          },
          on: {
            click: function click($event) {
              return _vm.deleteChildTicket(scope.row.id, _vm.ticket.id);
            }
          }
        })];
      }
    }])
  })], 1)], 1)])], 1), _vm._v(" "), _c("el-col", {
    staticClass: "fr mr-20 mt-10",
    staticStyle: {
      "margin-bottom": "10pt"
    },
    attrs: {
      span: 11
    }
  }, [_c("table", {
    staticClass: "ml-10",
    staticStyle: {
      "margin-bottom": "10pt",
      "margin-right": "20pt"
    },
    attrs: {
      width: "96%"
    }
  }, [_c("col", {
    staticStyle: {
      width: "30%"
    }
  }), _vm._v(" "), _c("col", {
    staticStyle: {
      width: "60%"
    }
  }), _vm._v(" "), _c("tr", {
    staticClass: "text-left"
  }, [_c("th", [_vm._v("Status")]), _vm._v(" "), _c("td", [_c("el-select", {
    attrs: {
      placeholder: "Select Status",
      size: "mini"
    },
    model: {
      value: _vm.ticket.status,
      callback: function callback($$v) {
        _vm.$set(_vm.ticket, "status", $$v);
      },
      expression: "ticket.status"
    }
  }, _vm._l(_vm.statuses, function (status) {
    return _c("el-option", {
      key: status.value,
      staticClass: "slim-list",
      attrs: {
        label: status.label,
        value: status.value
      }
    });
  }), 1)], 1)]), _vm._v(" "), _c("tr", {
    staticClass: "text-left"
  }, [_c("th", [_vm._v("Priority")]), _vm._v(" "), _c("td", [_c("el-select", {
    attrs: {
      placeholder: "Select Priority",
      size: "mini"
    },
    model: {
      value: _vm.ticket.priority,
      callback: function callback($$v) {
        _vm.$set(_vm.ticket, "priority", $$v);
      },
      expression: "ticket.priority"
    }
  }, _vm._l(_vm.priorities, function (priority) {
    return _c("el-option", {
      key: priority.value,
      staticClass: "slim-list",
      attrs: {
        label: priority.label,
        value: priority.value
      }
    });
  }), 1)], 1)]), _vm._v(" "), _c("tr", {
    staticClass: "text-left"
  }, [_c("th", [_vm._v("Technical Priority")]), _vm._v(" "), _c("td", [_c("el-select", {
    attrs: {
      placeholder: "Select Priority",
      size: "mini"
    },
    model: {
      value: _vm.ticket.priority,
      callback: function callback($$v) {
        _vm.$set(_vm.ticket, "priority", $$v);
      },
      expression: "ticket.priority"
    }
  }, _vm._l(_vm.priorities, function (priority) {
    return _c("el-option", {
      key: priority.value,
      staticClass: "slim-list",
      attrs: {
        label: priority.label,
        value: priority.value
      }
    });
  }), 1)], 1)]), _vm._v(" "), _c("tr", {
    staticClass: "text-left",
    staticStyle: {
      height: "28px",
      "margin-top": "1pt",
      "margin-bottom": "1pt"
    }
  }, [_c("th", [_vm._v("Smart Priority")]), _vm._v(" "), _c("td", {
    staticStyle: {
      "font-size": "9pt"
    }
  }, [_vm._v('\n                [[Algorithm "x/y"]]\n                '), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Smart Priority automatically orders tickets based on business and customer value",
      placement: "right"
    }
  }, [_c("i", {
    staticClass: "fa fa-question pl-5",
    staticStyle: {
      color: "#ff6600"
    }
  })])], 1)]), _vm._v(" "), _c("tr", {
    staticClass: "text-left",
    staticStyle: {
      height: "28px"
    }
  }, [_c("th", [_vm._v("Value")]), _vm._v(" "), _c("td", [_c("el-select", {
    attrs: {
      placeholder: "Select Value",
      size: "mini"
    },
    model: {
      value: _vm.ticket.importance,
      callback: function callback($$v) {
        _vm.$set(_vm.ticket, "importance", $$v);
      },
      expression: "ticket.importance"
    }
  }, [_c("el-option", {
    staticClass: "slim-list",
    staticStyle: {
      "font-size": "9pt"
    },
    attrs: {
      label: "Lowest",
      value: "Lowest"
    }
  }), _vm._v(" "), _c("el-option", {
    staticClass: "slim-list",
    staticStyle: {
      "font-size": "9pt"
    },
    attrs: {
      label: "Low",
      value: "Low"
    }
  }), _vm._v(" "), _c("el-option", {
    staticClass: "slim-list",
    staticStyle: {
      "font-size": "9pt"
    },
    attrs: {
      label: "Medium",
      value: "Medium"
    }
  }), _vm._v(" "), _c("el-option", {
    staticClass: "slim-list",
    staticStyle: {
      "font-size": "9pt"
    },
    attrs: {
      label: "High",
      value: "High"
    }
  }), _vm._v(" "), _c("el-option", {
    staticClass: "slim-list",
    staticStyle: {
      "font-size": "9pt"
    },
    attrs: {
      label: "Highest",
      value: "Highest"
    }
  })], 1)], 1)]), _vm._v(" "), _c("tr", {
    staticClass: "text-left",
    staticStyle: {
      height: "28px"
    }
  }, [_c("th", [_vm._v("Approved via")]), _vm._v(" "), _c("td", [_c("el-input", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.ticket.approvalVia,
      callback: function callback($$v) {
        _vm.$set(_vm.ticket, "approvalVia", $$v);
      },
      expression: "ticket.approvalVia"
    }
  })], 1)]), _vm._v(" "), _c("tr", {
    staticClass: "text-left",
    staticStyle: {
      height: "28px"
    }
  }, [_c("th", [_vm._v("Created")]), _vm._v(" "), _c("td", [_vm._v(_vm._s(_vm.ticket.createdBy) + " @ " + _vm._s(_vm.computeHumanFriendlyDate(_vm.ticket.createdAt)))])]), _vm._v(" "), _c("tr", {
    staticClass: "text-left",
    staticStyle: {
      height: "28px"
    }
  }, [_c("th", [_vm._v("Last Modified")]), _vm._v(" "), _c("td", [_vm._v(_vm._s(_vm.ticket.modifiedBy) + " @ " + _vm._s(_vm.computeHumanFriendlyDate(_vm.ticket.modifiedAt)))])])]), _vm._v(" "), _c("div", {
    staticStyle: {
      "border-radius": "5pt",
      border: "solid 1pt lightgrey",
      "padding-top": "8pt"
    }
  }, [_c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Mappings to external tickets can be changed on the Integrated Data tab under Offering, Delivery.",
      placement: "right"
    }
  }, [_c("span", {
    staticClass: "ml-10 mt-10"
  }, [_c("strong", [_vm._v("External Tickets")])])]), _vm._v(" "), _c("el-table", {
    staticClass: "mt-10",
    staticStyle: {
      width: "95%",
      "margin-left": "10pt",
      "padding-right": "10pt",
      "padding-bottom": "10pt"
    },
    attrs: {
      data: _vm.external_tickets,
      stripe: "",
      size: "mini"
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Ext ID",
      prop: "id",
      width: "60"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Type",
      prop: "type",
      width: "60"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Title",
      prop: "title"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Status",
      prop: "status",
      width: "80"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "External System",
      prop: "system",
      width: "160"
    }
  })], 1)], 1)])], 1)], 1), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: "Actions & Comments",
      name: "actions_comments"
    }
  }, [_c("el-tabs", {
    attrs: {
      "tab-position": "left"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "Discussion"
    }
  }, [_c("comment-component", {
    attrs: {
      "entity-id": _vm.entity.id,
      "entity-name": _vm.entity.title
    }
  })], 1), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: "Actions"
    }
  }, [_c("action-component", {
    attrs: {
      entity: {
        id: _vm.ticket.id,
        type: ""
      }
    },
    on: {
      add_action: _vm.addAction
    }
  })], 1), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: "History"
    }
  }, [_c("div", {
    staticStyle: {
      "border-radius": "5pt",
      border: "solid 1pt lightgrey",
      "padding-top": "4pt"
    }
  }, [_c("span", {
    staticClass: "ml-10",
    staticStyle: {
      "padding-left": "2pt",
      "font-size": "16pt"
    }
  }), _c("strong", [_vm._v("Ticket History")]), _vm._v(" "), _c("div", {
    staticClass: "ml-10"
  }, [_vm._v("TBD")])])])], 1)], 1)], 1), _vm._v(" "), _c("div", {
    staticStyle: {
      "padding-top": "10px",
      "text-align": "right"
    }
  }, [_c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.update_processing,
      expression: "update_processing"
    }],
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.updateTicket
    }
  }, [_vm._v("Update")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "secondary",
      size: "mini"
    },
    on: {
      click: _vm.emitCloseEvent
    }
  }, [_vm._v("Close")])], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Add Tickets",
      visible: _vm.addTicketDialog,
      "append-to-body": true,
      width: "40%",
      top: "1vh"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addTicketDialog = $event;
      }
    }
  }, [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.retrieve_ticket_loading,
      expression: "retrieve_ticket_loading"
    }],
    staticStyle: {
      width: "99%",
      margin: "10pt"
    },
    attrs: {
      data: _vm.filteredTickets,
      height: "550",
      border: "",
      stripe: "",
      size: "mini"
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "title",
      label: "Title"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "type",
      label: "Type"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "#"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-button", {
          attrs: {
            type: "primary",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.linkChildTicketToParent(scope.row.id, _vm.ticket.id);
            }
          }
        }, [_vm._v("Add")])];
      }
    }])
  })], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;