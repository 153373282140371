"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("svg", {
    attrs: {
      id: "journeyMapSVG",
      height: "2000px",
      width: "1700px",
      xmlns: "http://www.w3.org/2000/svg",
      version: "1.1"
    },
    on: {
      click: function click($event) {
        return _vm.canvasClick();
      }
    }
  }, [_c("g", {
    attrs: {
      refs: "container"
    }
  }, [_c("g", {
    attrs: {
      refs: "competitors"
    }
  }, [_c("rect", {
    attrs: {
      x: 180,
      y: 5,
      rx: "0",
      ry: "0",
      width: 1500,
      height: 150,
      fill: "rgba(235,102,0,0.01)",
      "stroke-width": "1",
      stroke: "rgba(235,102,0,0.25)"
    }
  }), _vm._v(" "), _c("g", {
    attrs: {
      refs: "competitors-header"
    }
  }, [_c("text", {
    staticStyle: {
      fill: "#ff6600"
    },
    attrs: {
      x: 190,
      y: 28,
      "text-anchor": "Start",
      "font-size": "1.2em",
      "font-weight": "600",
      "font-variant": "small-caps"
    }
  }, [_vm._v("\n              Competitive Pressure\n            ")])]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "competitor-select"
    }
  }, [_c("foreignObject", {
    attrs: {
      x: 380,
      y: 10,
      width: 160,
      height: 30
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Select Competitors",
      size: "mini"
    },
    on: {
      change: _vm.selectCompetitor
    },
    model: {
      value: _vm.selectedCompetitorId,
      callback: function callback($$v) {
        _vm.selectedCompetitorId = $$v;
      },
      expression: "selectedCompetitorId"
    }
  }, _vm._l(_vm.competitors, function (competitor) {
    return _c("el-option", {
      key: competitor.id,
      attrs: {
        label: competitor.title,
        value: competitor.id
      }
    });
  }), 1)], 1)]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "competitor-feature-view"
    }
  }, [_c("rect", {
    attrs: {
      x: 192,
      y: 50,
      rx: "0",
      ry: "0",
      width: 1475,
      height: 95,
      fill: "white",
      "stroke-width": "2",
      stroke: "rgba(200,200,200,0.1)"
    }
  }), _vm._v(" "), _c("g", {
    attrs: {
      refs: "competitor-feature-view-header"
    }
  }, [_c("text", {
    staticStyle: {
      fill: "#444"
    },
    attrs: {
      x: 195,
      y: 45,
      "text-anchor": "start",
      "font-size": "1.0em",
      "font-weight": "500"
    }
  }, [_vm._v("\n                Feature Threat Profile\n              ")])]), _vm._v(" "), _c("rect", {
    attrs: {
      x: 192,
      y: 50,
      rx: "0",
      ry: "0",
      width: 1475,
      height: 32,
      fill: "ivory",
      stroke: "none"
    }
  }), _vm._v(" "), _c("rect", {
    attrs: {
      x: 192,
      y: 84,
      rx: "0",
      ry: "0",
      width: 1475,
      height: 32,
      "stroke-width": "1",
      fill: "rgba(255, 165, 0, 0.35)"
    }
  }), _vm._v(" "), _c("rect", {
    attrs: {
      x: 192,
      y: 116,
      rx: "0",
      ry: "0",
      width: 1475,
      height: 32,
      "stroke-width": "1",
      fill: "lightsalmon"
    }
  }), _vm._v(" "), _vm._l(_vm.lowRiskCompetitors, function (competitor, low_risk_competitor_index) {
    return _c("g", {
      key: "low_risk_" + low_risk_competitor_index,
      attrs: {
        refs: "competitor-view-list-feature"
      }
    }, [_c("rect", {
      attrs: {
        x: 195 + low_risk_competitor_index * 105,
        y: _vm.computeCompetitorY(competitor),
        rx: "5",
        ry: "5",
        width: 100,
        height: 25,
        fill: "rgba(200,200,200,0.1)",
        "stroke-width": "1",
        stroke: _vm.selectedCompetitor.id === competitor.id ? "rgba(0,0,0,1)" : "rgba(255, 255, 235, 1)"
      },
      on: {
        click: function click($event) {
          return _vm.changeCompetitor(competitor, competitor.id);
        }
      }
    }), _vm._v(" "), _c("text", {
      staticStyle: {
        fill: "#444"
      },
      attrs: {
        x: 245 + low_risk_competitor_index * 105,
        y: _vm.computeCompetitorY(competitor) + 17,
        "text-anchor": "middle",
        "font-size": "1.0em",
        "font-weight": "500"
      },
      on: {
        click: function click($event) {
          return _vm.changeCompetitor(competitor, competitor.id);
        }
      }
    }, [_vm._v("\n                " + _vm._s(_vm.trimString(competitor.title, 13)) + "\n              ")])]);
  }), _vm._v(" "), _vm._l(_vm.mediumRiskCompetitors, function (competitor, med_risk_competitor_index) {
    return _c("g", {
      key: "med_risk_" + med_risk_competitor_index,
      attrs: {
        refs: "competitor-view-list-feature"
      }
    }, [_c("rect", {
      attrs: {
        x: 195 + med_risk_competitor_index * 105,
        y: _vm.computeCompetitorY(competitor),
        rx: "5",
        ry: "5",
        width: 100,
        height: 25,
        fill: "rgba(200,200,200,0.1)",
        "stroke-width": "1",
        stroke: _vm.selectedCompetitor.id === competitor.id ? "rgba(0,0,0,1)" : "rgba(255, 165, 0, 1)"
      },
      on: {
        click: function click($event) {
          return _vm.changeCompetitor(competitor, competitor.id);
        }
      }
    }), _vm._v(" "), _c("text", {
      staticStyle: {
        fill: "#444"
      },
      attrs: {
        x: 245 + med_risk_competitor_index * 105,
        y: _vm.computeCompetitorY(competitor) + 17,
        "text-anchor": "middle",
        "font-size": "1.0em",
        "font-weight": "500"
      },
      on: {
        click: function click($event) {
          return _vm.changeCompetitor(competitor, competitor.id);
        }
      }
    }, [_vm._v("\n                " + _vm._s(_vm.trimString(competitor.title, 13)) + "\n              ")])]);
  }), _vm._v(" "), _vm._l(_vm.highRiskCompetitors, function (competitor, high_risk_competitor_index) {
    return _c("g", {
      key: "high_risk_" + high_risk_competitor_index,
      attrs: {
        refs: "competitor-view-list-feature"
      }
    }, [_c("rect", {
      attrs: {
        x: 195 + high_risk_competitor_index * 105,
        y: _vm.computeCompetitorY(competitor),
        rx: "5",
        ry: "5",
        width: 100,
        height: 25,
        fill: "rgba(200,200,200,0.1)",
        "stroke-width": "1",
        stroke: _vm.selectedCompetitor.id === competitor.id ? "rgba(0,0,0,1)" : "rgba(255, 123, 71, 1)"
      },
      on: {
        click: function click($event) {
          return _vm.changeCompetitor(competitor, competitor.id);
        }
      }
    }), _vm._v(" "), _c("text", {
      staticStyle: {
        fill: "#444"
      },
      attrs: {
        x: 245 + high_risk_competitor_index * 105,
        y: _vm.computeCompetitorY(competitor) + 17,
        "text-anchor": "middle",
        "font-size": "1.0em",
        "font-weight": "500"
      },
      on: {
        click: function click($event) {
          return _vm.changeCompetitor(competitor, competitor.id);
        }
      }
    }, [_vm._v("\n                " + _vm._s(_vm.trimString(competitor.title, 13)) + "\n              ")])]);
  })], 2)]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "value-steps"
    }
  }, _vm._l(_vm.value_steps, function (value_step, stepindex) {
    return _c("g", {
      key: "step" + stepindex,
      attrs: {
        id: "step_" + stepindex,
        refs: "value-chain"
      }
    }, [_c("rect", {
      attrs: {
        x: 180,
        y: stepindex * 175 + 190,
        rx: "0",
        ry: "0",
        width: 1500,
        height: 170,
        fill: "white",
        "stroke-width": "1",
        stroke: "rgba(100,149,237,0.25)"
      }
    }), _vm._v(" "), _c("text", {
      staticStyle: {
        fill: "darkblue"
      },
      attrs: {
        x: 190,
        y: 210 + stepindex * 175,
        "text-anchor": "start",
        "font-size": "1.2em",
        "font-variant": "small-caps",
        "font-weight": "600"
      }
    }, [_vm._v("\n              " + _vm._s(value_step.value_step_title) + "\n            ")]), _vm._v(" "), _c("g", {
      attrs: {
        refs: "value-streams"
      }
    }, _vm._l(value_step.value_streams, function (valuestream, index) {
      return _c("g", {
        key: index,
        attrs: {
          refs: "value-stream"
        },
        on: {
          click: function click($event) {
            $event.preventDefault();
            return _vm.selectValueStream(valuestream);
          }
        }
      }, [_c("polygon", {
        attrs: {
          id: _vm.generateUniqueID(index),
          points: _vm.generatePath(stepindex, index),
          stroke: "none",
          fill: "aliceblue",
          "stroke-width": "1px"
        }
      }, [_c("filter", {
        attrs: {
          id: "shadow",
          "color-interpolation-filters": "sRGB"
        }
      }, [_c("feDropShadow", {
        attrs: {
          dx: "2",
          gy: "2",
          "std-deviation": "3",
          "flood-opacity": "0.5"
        }
      })], 1)]), _vm._v(" "), _c("g", {
        attrs: {
          refs: "value-stream-header"
        }
      }, [_c("text", {
        staticStyle: {
          fill: "darkblue"
        },
        attrs: {
          x: index * (300 + 30) + 210,
          y: 245 + stepindex * 175,
          "text-anchor": "start",
          "font-size": "1.05em",
          "font-weight": "400",
          "font-variant": "small-caps"
        }
      }, [_vm._v("\n                    " + _vm._s(valuestream.title) + "\n                  ")])]), _vm._v(" "), _c("g", {
        attrs: {
          refs: "offerings"
        }
      }, _vm._l(valuestream.offerings, function (offering, offering_index) {
        return _c("g", {
          key: offering_index,
          attrs: {
            refs: "offering"
          },
          on: {
            mouseover: function mouseover($event) {
              _vm.offeringHoverId = offering.id;
            },
            mouseleave: function mouseleave($event) {
              _vm.offeringHoverId = "";
            }
          }
        }, [_vm.offeringHoverId === offering.id ? _c("g", {
          attrs: {
            refs: "offering-delete"
          }
        }, [_c("circle", {
          attrs: {
            cx: _vm.computeOfferingX(stepindex, index, offering_index) + 30,
            cy: _vm.computeOfferingY(stepindex, index, offering_index) + 40,
            r: 10,
            fill: "#8b0000"
          }
        }), _vm._v(" "), _c("foreignObject", {
          attrs: {
            x: _vm.computeOfferingX(stepindex, index, offering_index) + 25,
            y: _vm.computeOfferingY(stepindex, index, offering_index) + 32,
            width: 20,
            height: 20
          }
        }, [_c("svg-icon", {
          staticStyle: {
            "font-size": "10px",
            color: "#fff"
          },
          attrs: {
            "icon-class": "cross"
          },
          on: {
            click: function click($event) {
              return _vm.deleteOffering(stepindex, index, offering_index);
            }
          }
        })], 1)]) : _vm._e(), _vm._v(" "), _c("g", {
          attrs: {
            refs: "offering-detail"
          },
          on: {
            click: function click($event) {
              $event.preventDefault();
              return _vm.selectOffering(offering);
            }
          }
        }, [_c("foreignObject", {
          attrs: {
            x: _vm.computeOfferingX(stepindex, index, offering_index) - 5,
            y: _vm.computeOfferingY(stepindex, index, offering_index) + 40,
            width: 40,
            height: 40
          }
        }, [offering.type === "product" ? _c("svg-icon", {
          staticStyle: {
            "font-size": "30px",
            color: "#023047"
          },
          attrs: {
            "icon-class": "pdg-product"
          }
        }) : _c("svg-icon", {
          staticStyle: {
            "font-size": "30px",
            color: "#3a86ff"
          },
          attrs: {
            "icon-class": "pdg-service"
          }
        })], 1), _vm._v(" "), _c("text", {
          staticStyle: {
            fill: "#444"
          },
          attrs: {
            x: _vm.computeOfferingX(stepindex, index, offering_index) - 5,
            y: _vm.computeOfferingY(stepindex, index, offering_index) + 80,
            "text-anchor": "start",
            "font-size": "0.8em",
            "font-weight": "400"
          }
        }, [_vm._v("\n                        " + _vm._s(offering.title) + "\n                      ")])])]);
      }), 0)]);
    }), 0)]);
  }), 0), _vm._v(" "), _c("g", {
    attrs: {
      refs: "backoffice-enablers"
    }
  }, [_c("rect", {
    attrs: {
      x: 180,
      y: 190 + _vm.value_steps.length * 175,
      rx: "0",
      ry: "0",
      width: 1500,
      height: 150,
      fill: "white",
      "stroke-width": "1",
      stroke: "lightgrey",
      "stroke-dasharray": "5,5"
    }
  }), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "grey"
    },
    attrs: {
      x: 190,
      y: 210 + _vm.value_steps.length * 175,
      "text-anchor": "start",
      "font-size": "1.2em",
      "font-weight": "500",
      "font-variant": "Small-caps"
    }
  }, [_vm._v("\n              Enablers\n            ")])])]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "regulatory-pressure"
    }
  }, [_c("rect", {
    attrs: {
      x: 180,
      y: 375 + _vm.value_steps.length * 175,
      rx: "0",
      ry: "0",
      width: 1500,
      height: 150,
      fill: "lightyellow",
      "stroke-width": "1",
      stroke: "rgba(200,200,200,0.1)"
    }
  }), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "teal"
    },
    attrs: {
      x: 190,
      y: 395 + _vm.value_steps.length * 175,
      "text-anchor": "start",
      "font-size": "1.2em",
      "font-weight": "600",
      "font-variant": "Small-caps"
    }
  }, [_vm._v("\n              Regulatory Pressure\n            ")])]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "requirements"
    }
  }, _vm._l(_vm.regulatory, function (regulatory_datum, index) {
    return _c("g", {
      key: regulatory_datum.id,
      attrs: {
        refs: "requirment"
      },
      on: {
        click: function click($event) {
          return _vm.selectRequirement(regulatory_datum);
        }
      }
    }, [_c("foreignObject", {
      attrs: {
        x: 200 + index * 70,
        y: 425 + _vm.value_steps.length * 175,
        width: 50,
        height: 60
      }
    }, [_c("svg-icon", {
      staticStyle: {
        "font-size": "30px",
        color: "teal"
      },
      attrs: {
        "icon-class": "documentation"
      }
    })], 1), _vm._v(" "), _c("text", {
      staticStyle: {
        fill: "teal"
      },
      attrs: {
        x: 200 + index * 70,
        y: 475 + _vm.value_steps.length * 175,
        "text-anchor": "start",
        "font-size": "0.8em",
        "font-weight": "400"
      }
    }, [_vm._v("\n                " + _vm._s(regulatory_datum.title) + "\n              ")])]);
  }), 0), _vm._v(" "), _vm.drop_hover_details.hover ? _c("g", {
    attrs: {
      id: "hover_highlight",
      refs: "hover_highlight"
    }
  }, [_c("rect", {
    attrs: {
      id: "hover_highlight_rect",
      x: _vm.drop_hover_details.x + 18,
      y: _vm.drop_hover_details.y,
      rx: "0",
      ry: "0",
      width: _vm.drop_hover_details.width + 4,
      height: _vm.drop_hover_details.height + 4,
      fill: "rgba(127,255,212, 0.02)",
      "stroke-width": "2",
      stroke: "aquamarine",
      "stroke-dasharray": "6"
    }
  })]) : _vm._e(), _vm._v(" "), _c("g", {
    attrs: {
      refs: "temp_highlights"
    }
  }, [_c("rect", {
    attrs: {
      x: 235,
      y: 440,
      rx: "0",
      ry: "0",
      width: 50,
      height: 50,
      fill: "rgba(255, 255, 255, 0.1)",
      "stroke-width": "1",
      stroke: "green"
    },
    on: {
      mouseover: function mouseover($event) {
        return _vm.onMouseOver(235, 440, 50);
      },
      mouseleave: function mouseleave($event) {
        return _vm.onMouseLeave(235, 440, 50);
      }
    }
  }), _vm._v(" "), _c("rect", {
    attrs: {
      x: 290,
      y: 440,
      rx: "0",
      ry: "0",
      width: 150,
      height: 50,
      fill: "rgba(255, 255, 255, 0.1)",
      "stroke-width": "1",
      stroke: "green"
    },
    on: {
      mouseover: function mouseover($event) {
        return _vm.onMouseOver(290, 440, 150);
      },
      mouseleave: function mouseleave($event) {
        return _vm.onMouseLeave(290, 440, 150);
      }
    }
  }), _vm._v(" "), _c("rect", {
    attrs: {
      x: 570,
      y: 440,
      rx: "0",
      ry: "0",
      width: 200,
      height: 50,
      fill: "rgba(255, 255, 255, 0.1)",
      "stroke-width": "1",
      stroke: "green"
    },
    on: {
      mouseover: function mouseover($event) {
        return _vm.onMouseOver(570, 440, 200);
      },
      mouseleave: function mouseleave($event) {
        return _vm.onMouseLeave(570, 440, 200);
      }
    }
  }), _vm._v(" "), _c("rect", {
    attrs: {
      x: 895,
      y: 440,
      rx: "0",
      ry: "0",
      width: 50,
      height: 50,
      fill: "rgba(255, 255, 255, 0.1)",
      "stroke-width": "1",
      stroke: "green"
    },
    on: {
      mouseover: function mouseover($event) {
        return _vm.onMouseOver(895, 440, 50);
      },
      mouseleave: function mouseleave($event) {
        return _vm.onMouseLeave(895, 440, 50);
      }
    }
  }), _vm._v(" "), _vm.featureDetails.hover ? _c("g", {
    attrs: {
      refs: "feature-details"
    }
  }, [_c("g", {
    attrs: {
      refs: "high-priority-features"
    }
  }, [_c("rect", {
    attrs: {
      x: _vm.featureDetails.x + _vm.featureDetails.width + 5,
      y: _vm.featureDetails.y - 100,
      width: 200,
      height: 100,
      fill: "#f2555b"
    }
  }), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "#fff"
    },
    attrs: {
      x: _vm.featureDetails.x + _vm.featureDetails.width + 100,
      y: _vm.featureDetails.y - 65,
      "text-anchor": "middle",
      "font-size": "1.5em",
      "font-weight": "400"
    }
  }, [_vm._v("\n              Feature A\n            ")]), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "#fff"
    },
    attrs: {
      x: _vm.featureDetails.x + _vm.featureDetails.width + 100,
      y: _vm.featureDetails.y - 25,
      "text-anchor": "middle",
      "font-size": "1.5em",
      "font-weight": "400"
    }
  }, [_vm._v("\n              Feature B\n            ")])]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "med-priority-features"
    }
  }, [_c("rect", {
    attrs: {
      x: _vm.featureDetails.x + _vm.featureDetails.width + 5,
      y: _vm.featureDetails.y,
      width: 200,
      height: 100,
      fill: "#fbd54a"
    }
  }), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "#fff"
    },
    attrs: {
      x: _vm.featureDetails.x + _vm.featureDetails.width + 100,
      y: _vm.featureDetails.y + 35,
      "text-anchor": "middle",
      "font-size": "1.5em",
      "font-weight": "400"
    }
  }, [_vm._v("\n              Feature C\n            ")]), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "#fff"
    },
    attrs: {
      x: _vm.featureDetails.x + _vm.featureDetails.width + 100,
      y: _vm.featureDetails.y + 75,
      "text-anchor": "middle",
      "font-size": "1.5em",
      "font-weight": "400"
    }
  }, [_vm._v("\n              Feature D\n            ")])]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "med-priority-features"
    }
  }, [_c("rect", {
    attrs: {
      x: _vm.featureDetails.x + _vm.featureDetails.width + 5,
      y: _vm.featureDetails.y + 100,
      width: 200,
      height: 100,
      fill: "#f3ebc4"
    }
  }), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "#fff"
    },
    attrs: {
      x: _vm.featureDetails.x + _vm.featureDetails.width + 100,
      y: _vm.featureDetails.y + 135,
      "text-anchor": "middle",
      "font-size": "1.5em",
      "font-weight": "400"
    }
  }, [_vm._v("\n              Feature E\n            ")]), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "#fff"
    },
    attrs: {
      x: _vm.featureDetails.x + _vm.featureDetails.width + 100,
      y: _vm.featureDetails.y + 175,
      "text-anchor": "middle",
      "font-size": "1.5em",
      "font-weight": "400"
    }
  }, [_vm._v("\n              Feature F\n            ")])])]) : _vm._e()]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "toolbar"
    }
  }, [_c("g", {
    attrs: {
      refs: "toolbar_header"
    }
  }, [_c("rect", {
    attrs: {
      x: 5,
      y: 5,
      rx: "0",
      ry: "0",
      width: 150,
      height: 30,
      fill: "rgba(200,200,200,0.2)",
      "stroke-width": "2",
      stroke: "rgba(200,200,200,0.2)"
    }
  }), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "#444"
    },
    attrs: {
      x: 75,
      y: 25,
      "text-anchor": "middle",
      "font-size": "1.2em",
      "font-weight": "600"
    }
  }, [_vm._v("\n            Toolbar\n          ")])]), _vm._v(" "), _c("rect", {
    attrs: {
      x: 5,
      y: 5,
      rx: "0",
      ry: "0",
      width: 150,
      height: 920,
      fill: "rgba(200,200,200,0.1)",
      "stroke-width": "2",
      stroke: "rgba(200,200,200,0.2)"
    }
  }), _vm._v(" "), _c("g", {
    attrs: {
      refs: "tools"
    }
  }, [_c("g", {
    directives: [{
      name: "dragged",
      rawName: "v-dragged.prevent",
      value: _vm.onValueStepToolDragged,
      expression: "onValueStepToolDragged",
      modifiers: {
        prevent: true
      }
    }],
    attrs: {
      refs: "valuestep"
    }
  }, [_c("rect", {
    attrs: {
      x: _vm.tools.valuestep.x,
      y: _vm.tools.valuestep.y,
      rx: "0",
      ry: "0",
      width: 90,
      height: 50,
      fill: "white",
      "stroke-width": "1",
      stroke: "cornflowerblue"
    }
  }), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "#444"
    },
    attrs: {
      x: _vm.tools.valuestep.x + 4,
      y: _vm.tools.valuestep.y + 10,
      "text-anchor": "start",
      "font-size": "0.6em",
      "font-weight": "500",
      "font-style": "italic",
      "font-variant": "small-caps"
    }
  }, [_vm._v("\n              Value Stream\n            ")]), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "#444"
    },
    attrs: {
      x: _vm.tools.valuestep.x + 40,
      y: _vm.tools.valuestep.y + 75,
      "text-anchor": "middle",
      "font-size": "1.0em",
      "font-weight": "600"
    }
  }, [_vm._v("\n              Value Stream\n            ")])]), _vm._v(" "), _c("g", {
    directives: [{
      name: "dragged",
      rawName: "v-dragged.prevent",
      value: _vm.onValueStreamToolDragged,
      expression: "onValueStreamToolDragged",
      modifiers: {
        prevent: true
      }
    }],
    attrs: {
      refs: "valuestream"
    }
  }, [_c("polygon", {
    attrs: {
      id: "tool_value_stream",
      points: _vm.generateValueStreamToolPath(),
      stroke: "cornflowerblue",
      fill: "aliceblue",
      "stroke-width": "1px"
    }
  }, [_c("filter", {
    attrs: {
      id: "shadow",
      "color-interpolation-filters": "sRGB"
    }
  }, [_c("feDropShadow", {
    attrs: {
      dx: "2",
      gy: "2",
      "std-deviation": "3",
      "flood-opacity": "0.5"
    }
  })], 1)]), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "#444"
    },
    attrs: {
      x: _vm.tools.valuestream.x + 25,
      y: _vm.tools.valuestream.y + 75,
      "text-anchor": "middle",
      "font-size": "1.0em",
      "font-weight": "600"
    }
  }, [_vm._v("\n              Stream Step\n            ")])]), _vm._v(" "), _c("line", {
    attrs: {
      x1: 5,
      y1: 150,
      x2: 150,
      y2: 150,
      stroke: "rgba(200,200,200,0.2)",
      "stroke-width": "2px",
      "stroke-dasharray": "4"
    }
  }), _vm._v(" "), _c("g", {
    directives: [{
      name: "dragged",
      rawName: "v-dragged.prevent",
      value: _vm.onProductToolDragged,
      expression: "onProductToolDragged",
      modifiers: {
        prevent: true
      }
    }],
    attrs: {
      refs: "product"
    }
  }, [_c("foreignObject", {
    attrs: {
      x: _vm.tools.product.x,
      y: _vm.tools.product.y,
      width: 100,
      height: 100
    }
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "40px",
      color: "#023047"
    },
    attrs: {
      "icon-class": "pdg-product"
    }
  })], 1), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "#23047"
    },
    attrs: {
      x: _vm.tools.product.x + 25,
      y: _vm.tools.product.y + 55,
      "text-anchor": "middle",
      "font-size": "1.0em",
      "font-weight": "600"
    }
  }, [_vm._v("\n              Product\n            ")])]), _vm._v(" "), _c("line", {
    attrs: {
      x1: 5,
      y1: 300,
      x2: 150,
      y2: 300,
      stroke: "rgba(200,200,200,0.2)",
      "stroke-width": "2px",
      "stroke-dasharray": "4"
    }
  }), _vm._v(" "), _c("g", {
    directives: [{
      name: "dragged",
      rawName: "v-dragged.prevent",
      value: _vm.onServiceToolDragged,
      expression: "onServiceToolDragged",
      modifiers: {
        prevent: true
      }
    }],
    attrs: {
      refs: "product"
    }
  }, [_c("foreignObject", {
    attrs: {
      x: _vm.tools.service.x,
      y: _vm.tools.service.y,
      width: 75,
      height: 75
    }
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "40px",
      color: "#3a86ff"
    },
    attrs: {
      "icon-class": "pdg-service"
    }
  })], 1), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "#3a86ff"
    },
    attrs: {
      x: _vm.tools.service.x + 25,
      y: _vm.tools.service.y + 55,
      "text-anchor": "middle",
      "font-size": "1.0em",
      "font-weight": "600"
    }
  }, [_vm._v("\n              Service\n            ")])]), _vm._v(" "), _c("line", {
    attrs: {
      x1: 5,
      y1: 450,
      x2: 150,
      y2: 450,
      stroke: "rgba(200,200,200,0.2)",
      "stroke-width": "2px",
      "stroke-dasharray": "4"
    }
  }), _vm._v(" "), _c("g", {
    directives: [{
      name: "dragged",
      rawName: "v-dragged.prevent",
      value: _vm.onRequirementToolDragged,
      expression: "onRequirementToolDragged",
      modifiers: {
        prevent: true
      }
    }],
    attrs: {
      refs: "requirements"
    }
  }, [_c("foreignObject", {
    attrs: {
      x: _vm.tools.requirement.x,
      y: _vm.tools.requirement.y,
      width: 75,
      height: 75
    }
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "40px",
      color: "teal"
    },
    attrs: {
      "icon-class": "documentation"
    }
  })], 1), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "teal"
    },
    attrs: {
      x: _vm.tools.requirement.x + 25,
      y: _vm.tools.requirement.y + 55,
      "text-anchor": "middle",
      "font-size": "1.0em",
      "font-weight": "600"
    }
  }, [_vm._v("\n              Regulation\n            ")])]), _vm._v(" "), _c("line", {
    attrs: {
      x1: 5,
      y1: 600,
      x2: 150,
      y2: 600,
      stroke: "rgba(200,200,200,0.2)",
      "stroke-width": "2px",
      "stroke-dasharray": "4"
    }
  })])])]), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Add Step to Stream",
      visible: _vm.addValueStreamDialog,
      width: "40%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addValueStreamDialog = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.value_stream_form,
      "label-width": "100px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Name"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.value_stream_form.title,
      callback: function callback($$v) {
        _vm.$set(_vm.value_stream_form, "title", $$v);
      },
      expression: "value_stream_form.title"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Description"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      size: "mini"
    },
    model: {
      value: _vm.value_stream_form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.value_stream_form, "description", $$v);
      },
      expression: "value_stream_form.description"
    }
  })], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.addValueStreamDialog = false;
      }
    }
  }, [_vm._v("Close")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.addValueStream
    }
  }, [_vm._v("Create")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Add Regulation",
      visible: _vm.addRequirementDialog,
      width: "40%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addRequirementDialog = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.requirement_form,
      "label-width": "100px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Name"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.requirement_form.title,
      callback: function callback($$v) {
        _vm.$set(_vm.requirement_form, "title", $$v);
      },
      expression: "requirement_form.title"
    }
  })], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.addRequirementDialog = false;
      }
    }
  }, [_vm._v("Close")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.addRequirement
    }
  }, [_vm._v("Create")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Add Product",
      visible: _vm.addProductDialog,
      width: "30%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addProductDialog = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.product_form,
      "label-width": "150px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Select Products"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Select Products",
      size: "mini",
      multiple: ""
    },
    model: {
      value: _vm.product_form.ids,
      callback: function callback($$v) {
        _vm.$set(_vm.product_form, "ids", $$v);
      },
      expression: "product_form.ids"
    }
  }, _vm._l(_vm.products, function (product) {
    return _c("el-option", {
      key: product.id,
      attrs: {
        label: product.title,
        value: product.id
      }
    });
  }), 1)], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.addProductDialog = false;
      }
    }
  }, [_vm._v("Close")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.addProduct
    }
  }, [_vm._v("Add to ValueChain")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Add Service",
      visible: _vm.addServiceDialog,
      width: "30%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addServiceDialog = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.service_form,
      "label-width": "150px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Select Services"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Select Services",
      size: "mini",
      multiple: ""
    },
    model: {
      value: _vm.service_form.ids,
      callback: function callback($$v) {
        _vm.$set(_vm.service_form, "ids", $$v);
      },
      expression: "service_form.ids"
    }
  }, _vm._l(_vm.services, function (service) {
    return _c("el-option", {
      key: service.id,
      attrs: {
        label: service.title,
        value: service.id
      }
    });
  }), 1)], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.addServiceDialog = false;
      }
    }
  }, [_vm._v("Close")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.addService
    }
  }, [_vm._v("Add to ValueChain")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Value Stream Details",
      visible: _vm.valueStreamDetailDialog,
      width: "25%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.valueStreamDetailDialog = $event;
      }
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    staticClass: "text-right",
    attrs: {
      span: 8
    }
  }, [_c("strong", [_vm._v("Name")])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 16
    }
  }, [_vm._v(_vm._s(_vm.selectedValueStream.title))])], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    staticClass: "text-right",
    attrs: {
      span: 8
    }
  }, [_c("strong", [_vm._v("Description")])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 16
    }
  }, [_vm._v(_vm._s(_vm.selectedValueStream.description))])], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.valueStreamDetailDialog = false;
      }
    }
  }, [_vm._v("Close")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Offering Details",
      visible: _vm.offeringDetailDialog,
      width: "25%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.offeringDetailDialog = $event;
      }
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    staticClass: "text-right",
    attrs: {
      span: 8
    }
  }, [_c("strong", [_vm._v("Name")])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 16
    }
  }, [_vm._v(_vm._s(_vm.selectedOffering.id))])], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    staticClass: "text-right",
    attrs: {
      span: 8
    }
  }, [_c("strong", [_vm._v("Description")])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 16
    }
  }, [_vm._v(_vm._s(_vm.selectedOffering.title))])], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.offeringDetailDialog = false;
      }
    }
  }, [_vm._v("Close")])], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;