"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/taggedTemplateLiteral.js"));
var _axios = _interopRequireDefault(require("axios"));
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;
var _default = exports.default = {
  name: 'AddProductService',
  components: {},
  props: {},
  data: function data() {
    var validateTitle = function validateTitle(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter a valid title'));
      } else {
        callback();
      }
    };
    var validateDescription = function validateDescription(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter a valid description'));
      } else {
        callback();
      }
    };
    /* const validateManager = (rule, value, callback) => {
      if (!value) {
        callback(new Error('Please select a manager'))
      } else {
        callback()
      }
    }*/
    var validateType = function validateType(rule, value, callback) {
      if (!value) {
        callback(new Error('Please select a Type'));
      } else {
        callback();
      }
    };
    var validateOfferingId = function validateOfferingId(rule, value, callback) {
      if (!value) {
        callback(new Error('Please select a Offering'));
      } else {
        callback();
      }
    };
    return {
      productServiceRules: {
        title: [{
          required: true,
          trigger: 'blur',
          validator: validateTitle
        }],
        description: [{
          required: true,
          trigger: 'blur',
          validator: validateDescription
        }],
        /* manager: [{ required: true, trigger: 'blur', validator: validateManager }],*/
        type: [{
          required: true,
          trigger: 'blur',
          validator: validateType
        }],
        offeringId: [{
          required: true,
          trigger: 'blur',
          validator: validateOfferingId
        }]
      },
      loading: {
        general: false,
        user: false,
        offering: false
      },
      users: [],
      offerings: [],
      product_service_form: {
        type: 'Product',
        title: '',
        description: '',
        offeringId: '',
        manager: '',
        owner: ''
      }
    };
  },
  computed: {},
  watch: {},
  created: function created() {},
  mounted: function mounted() {
    this.resetProductServiceForm();
    if (this.users.length === 0) {
      this.retrieveUserList();
    }
    if (this.offerings.length === 0) {
      this.retrieveOfferingList();
    }
    if (this.users.length > 0) {
      this.product_service_form.manager = this.users[0].firstName + ' ' + this.users[0].lastName;
      this.product_service_form.owner = this.users[0].firstName + ' ' + this.users[0].lastName;
    }
  },
  methods: {
    addProduct: function addProduct(product) {
      var _this = this;
      this.loading.general = true;
      var keypersonnel = {
        'manager': product.manager,
        'owner': product.owner
      };
      this.$apollo.mutate({
        // Query
        mutation: window._gql(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["mutation createProduct($product: CreateProductInput!)\n        {\n          createProduct(input: $product) {\n            id\n            additionalattributes\n            createdat\n            createdby\n            description\n            keypersonnel\n            licensedetails\n            modifiedat\n            modifiedby\n            productcosts\n            revenuenotes\n            roadmap\n            title\n            vision\n            linkedFeatures(optional: true) {\n              productFeature(optional: true){\n                id\n                title\n                description\n                status\n                priority\n                relations\n                additionalattributes\n                enabler\n                startdate\n                enddate\n              }\n            }\n            linkedOfferings(optional: true) {\n              offering(optional: true){\n                id\n                title\n                description\n                additionalattributes\n                offeringtype\n                offeringcategory\n                offeringcategorydesc\n              }\n            }\n          }\n        }"]))),
        // Parameters
        variables: {
          'product': {
            'offeringId': product.offeringId,
            'title': product.title,
            'description': product.description,
            'keypersonnel': keypersonnel,
            'additionalattributes': {
              'minimized': false
            }
          }
        }
      }).then(function (data) {
        _this.$notify({
          title: 'Success',
          message: 'Created Successfully',
          type: 'success',
          duration: 2000
        });
        _this.loading.general = false;
        _this.addProductToOfferingSortOrder(product.offeringId, data.data.createProduct);
      }).catch(function (error) {
        // Error
        _this.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this.loading.general = false;
      });
    },
    addProductToOfferingSortOrder: function addProductToOfferingSortOrder(offering_id, product) {
      var _this2 = this;
      var servicesSortOrder = [];
      var productsSortOrder = [];
      var relations = [];
      var offering = {};
      for (var i = 0; i < this.offerings.length; i++) {
        if (this.offerings[i].id === offering_id) {
          offering = this.offerings[i];
        }
      }
      if (offering.additionalattributes && offering.additionalattributes.relations && offering.additionalattributes.relations.length > 0) {
        relations = offering.additionalattributes.relations;
      }
      if (offering.additionalattributes && offering.additionalattributes.servicesSortOrder && offering.additionalattributes.servicesSortOrder.length > 0) {
        servicesSortOrder = offering.additionalattributes.servicesSortOrder;
      }
      if (offering.additionalattributes && offering.additionalattributes.productsSortOrder && offering.additionalattributes.productsSortOrder.length > 0) {
        productsSortOrder = offering.additionalattributes.productsSortOrder;
      }
      productsSortOrder.push(product.id);
      this.loading.general = true;
      this.$apollo.mutate({
        // Query
        mutation: window._gql(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["mutation updateOffering($offering: UpdateOfferingInput!)\n        {\n          updateOffering(input: $offering) {\n            id\n          }\n        }"]))),
        // Parameters
        variables: {
          'offering': {
            'id': offering_id,
            'additionalattributes': {
              'productLane': {
                'isEnablersVisible': offering.additionalattributes.productLane.isEnablersVisible
              },
              'serviceLane': {
                'isEnablersVisible': offering.additionalattributes.serviceLane.isEnablersVisible
              },
              'servicesSortOrder': servicesSortOrder,
              'productsSortOrder': productsSortOrder,
              'relations': relations
            }
          }
        }
      }).then(function (data) {
        _this2.loading.general = false;
        _this2.$emit('add-product', product);
      }).catch(function (error) {
        // Error
        _this2.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this2.loading.general = false;
      });
    },
    addService: function addService(service) {
      var _this3 = this;
      this.loading.general = true;
      this.$apollo.mutate({
        // Query
        mutation: window._gql(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["mutation createService($service: CreateServiceInput!)\n        {\n          createService(input: $service) {\n            id\n            title\n            description\n            servicetype\n            servicecosts\n            revenuedetails\n            relations\n            design\n            additionalattributes\n            linkedServiceApplications(optional: true) {\n              serviceApplication(optional: true) {\n                id\n                title\n                description\n                priority\n                status\n                complexity\n                relations\n                enabler\n                startdate\n                enddate\n                additionalattributes\n              }\n            }\n            linkedOfferings(optional: true) {\n              offering(optional: true){\n                id\n                title\n                description\n                additionalattributes\n                offeringtype\n                offeringcategory\n                offeringcategorydesc\n              }\n            }\n          }\n        }"]))),
        // Parameters
        variables: {
          'service': {
            'offeringId': service.offeringId,
            'title': service.title,
            'description': service.description,
            'additionalattributes': {
              'minimized': false
            }
          }
        }
      }).then(function (data) {
        _this3.$notify({
          title: 'Success',
          message: 'Created Successfully',
          type: 'success',
          duration: 2000
        });
        _this3.loading.general = false;
        _this3.addServiceToOfferingSortOrder(service.offeringId, data.data.createService);
      }).catch(function (error) {
        // Error
        _this3.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this3.loading.general = false;
      });
    },
    addServiceToOfferingSortOrder: function addServiceToOfferingSortOrder(offering_id, service) {
      var _this4 = this;
      var servicesSortOrder = [];
      var productsSortOrder = [];
      var relations = [];
      var offering = {};
      for (var i = 0; i < this.offerings.length; i++) {
        if (this.offerings[i].id === offering_id) {
          offering = this.offerings[i];
        }
      }
      if (offering.additionalattributes && offering.additionalattributes.servicesSortOrder && offering.additionalattributes.servicesSortOrder.length > 0) {
        servicesSortOrder = offering.additionalattributes.servicesSortOrder;
      }
      servicesSortOrder.push(service.id);
      if (offering.additionalattributes && offering.additionalattributes.productsSortOrder && offering.additionalattributes.productsSortOrder.length > 0) {
        productsSortOrder = offering.additionalattributes.productsSortOrder;
      }
      if (offering.additionalattributes && offering.additionalattributes.relations && offering.additionalattributes.relations.length > 0) {
        relations = offering.additionalattributes.relations;
      }
      this.loading.general = true;
      this.$apollo.mutate({
        // Query
        mutation: window._gql(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2.default)(["mutation updateOffering($offering: UpdateOfferingInput!)\n        {\n          updateOffering(input: $offering) {\n            id\n          }\n        }"]))),
        // Parameters
        variables: {
          'offering': {
            'id': offering_id,
            'additionalattributes': {
              'productLane': {
                'isEnablersVisible': offering.additionalattributes.productLane.isEnablersVisible
              },
              'serviceLane': {
                'isEnablersVisible': offering.additionalattributes.serviceLane.isEnablersVisible
              },
              'servicesSortOrder': servicesSortOrder,
              'productsSortOrder': productsSortOrder,
              'relations': relations
            }
          }
        }
      }).then(function (data) {
        _this4.loading.general = false;
        _this4.$emit('add-service', service);
      }).catch(function (error) {
        // Error
        _this4.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this4.loading.general = false;
      });
    },
    computeUserName: function computeUserName(user) {
      return user.firstName + ' ' + user.lastName;
    },
    emitCloseEvent: function emitCloseEvent() {
      this.$emit('close');
    },
    createProductService: function createProductService() {
      var _this5 = this;
      this.$refs.product_service_form.validate(function (valid) {
        if (valid) {
          var product_service = Object.assign({}, _this5.product_service_form);
          if (product_service.type === 'Product') {
            _this5.addProduct(product_service);
          }
          if (product_service.type === 'Service') {
            _this5.addService(product_service);
          }
          _this5.resetProductServiceForm();
        } else {
          return false;
        }
      });
    },
    // Reset
    resetProductServiceForm: function resetProductServiceForm() {
      this.product_service_form = {
        type: 'Product',
        title: '',
        description: '',
        offeringId: '',
        manager: '',
        owner: ''
      };
    },
    // Helper Functions
    retrieveOfferingList: function retrieveOfferingList() {
      var _this6 = this;
      this.loading.offering = true;
      this.offerings = [];
      this.$apollo.query({
        // Query
        query: window._gql(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2.default)([" query Offerings($page: Page)\n        {\n        Offerings(page: $page)\n        {\n          pages\n          total\n          select{\n            id\n            title\n            additionalattributes\n          }\n        }\n      }"]))),
        fetchPolicy: 'no-cache',
        variables: {
          'page': {
            'limit': 100,
            'start': 1
          }
        }
      }).then(function (data) {
        _this6.loading.offering = false;
        _this6.offerings = data.data.Offerings.select;
      }).catch(function (error) {
        // Error
        _this6.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this6.loading.offering = false;
      });
    },
    retrieveUserList: function retrieveUserList() {
      var _this7 = this;
      this.loading.user = true;
      var headers = {
        'Content-Type': 'application/json',
        'x-party-id': this.$store.getters.partyId,
        'x-user-id': this.$store.getters.id
      };
      _axios.default.get(process.env.VUE_APP_REST_API_ENDPOINT + 'user/?extended=LEVEL0', {
        headers: headers
      }).then(function (response) {
        _this7.loading.user = false;
        _this7.users = response.data.data;
      }).catch(function (error) {
        if (error.response.status === 400) {
          _this7.loading.user = false;
          _this7.$notify({
            title: 'Error',
            message: error.response.message,
            type: 'error',
            duration: 2000
          });
        }
      });
    }
  }
};