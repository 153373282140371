"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-card", {
    staticClass: "mt-10"
  }, [_c("div", {
    staticClass: "text-left",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("span", {
    staticStyle: {
      "font-size": "1.1em",
      "font-weight": "bold"
    }
  }, [_vm._v("Describe Customers In This Segment")])]), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-tabs", {
    attrs: {
      type: "card",
      closable: ""
    },
    on: {
      "tab-remove": _vm.removeCustomerType
    },
    model: {
      value: _vm.activeCustomerTypeTab,
      callback: function callback($$v) {
        _vm.activeCustomerTypeTab = $$v;
      },
      expression: "activeCustomerTypeTab"
    }
  }, _vm._l(_vm.activeSegment.customers, function (customer, index) {
    return _c("el-tab-pane", {
      key: index,
      attrs: {
        label: customer.name,
        name: customer.name
      }
    }, [_c("el-row", {
      attrs: {
        gutter: 20
      }
    }, [_c("el-col", {
      attrs: {
        span: 24
      }
    }, [_c("p", {
      staticClass: "sub-title-rounded"
    }, [_vm._v("Customer Type Details")]), _vm._v(" "), _c("el-row", {
      attrs: {
        gutter: 20
      }
    }, [_c("el-col", {
      attrs: {
        span: 12
      }
    }, [_c("el-input", {
      attrs: {
        type: "textarea"
      },
      model: {
        value: customer.description,
        callback: function callback($$v) {
          _vm.$set(customer, "description", $$v);
        },
        expression: "customer.description"
      }
    })], 1), _vm._v(" "), _c("el-col", {
      attrs: {
        span: 12
      }
    }, [customer.product_user ? _c("el-tag", {
      attrs: {
        type: "success"
      }
    }, [_vm._v("Candidate User Type")]) : _vm._e(), _vm._v(" "), customer.purchaser ? _c("el-tag", {
      attrs: {
        type: "success"
      }
    }, [_vm._v("Purchaser")]) : _vm._e(), _vm._v(" "), customer.map_persona ? _c("el-tag", {
      attrs: {
        type: "success"
      }
    }, [_vm._v("Map Persona")]) : _vm._e()], 1)], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
      attrs: {
        gutter: 20
      }
    }, [_c("el-col", {
      attrs: {
        span: 12
      }
    }, [_c("p", {
      staticClass: "sub-title-rounded"
    }, [_vm._v("Problem Statement")]), _vm._v(" "), _c("el-input", {
      attrs: {
        type: "textarea"
      },
      model: {
        value: customer.problem_statement,
        callback: function callback($$v) {
          _vm.$set(customer, "problem_statement", $$v);
        },
        expression: "customer.problem_statement"
      }
    })], 1), _vm._v(" "), _c("el-col", {
      attrs: {
        span: 12
      }
    }, [_c("p", {
      staticClass: "sub-title-rounded"
    }, [_vm._v("Oppurtunity Statement")]), _vm._v(" "), _c("el-input", {
      attrs: {
        type: "textarea"
      },
      model: {
        value: customer.opportunity_statement,
        callback: function callback($$v) {
          _vm.$set(customer, "opportunity_statement", $$v);
        },
        expression: "customer.opportunity_statement"
      }
    })], 1)], 1), _vm._v(" "), _c("el-row", {
      attrs: {
        gutter: 20
      }
    }, [_c("el-col", {
      attrs: {
        span: 12
      }
    }, [_c("p", {
      staticClass: "sub-title-rounded"
    }, [_vm._v("Psychometric Notes")]), _vm._v(" "), _c("el-input", {
      attrs: {
        type: "textarea"
      },
      model: {
        value: customer.psychometric_notes,
        callback: function callback($$v) {
          _vm.$set(customer, "psychometric_notes", $$v);
        },
        expression: "customer.psychometric_notes"
      }
    })], 1), _vm._v(" "), _c("el-col", {
      attrs: {
        span: 12
      }
    }, [_c("p", {
      staticClass: "sub-title-rounded"
    }, [_vm._v("Sales Strategy")]), _vm._v(" "), _c("el-input", {
      attrs: {
        type: "textarea"
      },
      model: {
        value: customer.sales_strategy,
        callback: function callback($$v) {
          _vm.$set(customer, "sales_strategy", $$v);
        },
        expression: "customer.sales_strategy"
      }
    })], 1)], 1)], 1);
  }), 1)], 1)], 1), _vm._v(" "), _c("el-divider"), _vm._v(" "), _c("el-row", [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("div", {
    staticClass: "text-left"
  }, [_c("el-button", {
    attrs: {
      type: "secondary",
      size: "mini"
    },
    on: {
      click: _vm.openAddCustomerDialog
    }
  }, [_vm._v("Create New Customer Type")])], 1)]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("div", {
    staticClass: "text-right"
  }, [_c("el-button-group", {
    attrs: {
      size: "mini"
    }
  }, [_c("el-tooltip", {
    attrs: {
      effect: "dark",
      content: "Go To Define Revenue Types",
      placement: "top-start"
    }
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      icon: "el-icon-arrow-left"
    },
    on: {
      click: _vm.onClickDefineRevenueTypes
    }
  }, [_vm._v("Previous")])], 1)], 1)], 1)]), _vm._v(" "), _c("div", {
    staticClass: "text-right"
  }, [_c("el-button", {
    attrs: {
      type: "text"
    },
    on: {
      click: function click($event) {
        _vm.otherOptionsDialog = true;
      }
    }
  }, [_c("small", [_vm._v("Other options")])])], 1)], 1)], 1)], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Navigation Options",
      visible: _vm.otherOptionsDialog,
      width: "30%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.otherOptionsDialog = $event;
      }
    }
  }, [_c("div", {
    staticClass: "text-center"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.onClickDefineUsers
    }
  }, [_vm._v("Define User")])], 1)]), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Add Customer Type",
      visible: _vm.addCustomerDialog,
      width: "60%",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addCustomerDialog = $event;
      },
      open: _vm.initializeAddCustomerModal
    }
  }, [_c("el-form", {
    ref: "customer_form",
    attrs: {
      model: _vm.customer_form,
      "label-width": "180px"
    }
  }, [_c("el-row", [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-steps", {
    attrs: {
      active: _vm.addCustomerModalStepCounter,
      "finish-status": "success",
      "align-center": ""
    }
  }, [_c("el-step", {
    attrs: {
      title: "Name & Desc"
    }
  }), _vm._v(" "), _c("el-step", {
    attrs: {
      title: "Details"
    }
  })], 1)], 1)], 1), _vm._v(" "), _vm.addCustomerModalStepCounter === 0 ? [_c("el-row", {
    staticClass: "mt-10",
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 18,
      offset: 3
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Name"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.customer_form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.customer_form, "name", $$v);
      },
      expression: "customer_form.name"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Description"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.customer_form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.customer_form, "description", $$v);
      },
      expression: "customer_form.description"
    }
  })], 1), _vm._v(" "), _c("el-row", [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Candidate User Type"
    }
  }, [_c("el-tooltip", {
    attrs: {
      theme: "dark",
      content: "If this customer will be a user, tick this.",
      placement: "bottom"
    }
  }, [_c("el-switch", {
    model: {
      value: _vm.customer_form.product_user,
      callback: function callback($$v) {
        _vm.$set(_vm.customer_form, "product_user", $$v);
      },
      expression: "customer_form.product_user"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Purchaser"
    }
  }, [_c("el-tooltip", {
    attrs: {
      theme: "dark",
      content: "Only tick this if this customer will authorise payments. NOTE: some customers will NEVER be users.",
      placement: "bottom"
    }
  }, [_c("el-switch", {
    model: {
      value: _vm.customer_form.purchaser,
      callback: function callback($$v) {
        _vm.$set(_vm.customer_form, "purchaser", $$v);
      },
      expression: "customer_form.purchaser"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1)] : _vm._e(), _vm._v(" "), _vm.addCustomerModalStepCounter === 1 ? [_c("el-row", {
    staticClass: "mt-10",
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 18,
      offset: 3
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Problem Statement"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.customer_form.problem_statement,
      callback: function callback($$v) {
        _vm.$set(_vm.customer_form, "problem_statement", $$v);
      },
      expression: "customer_form.problem_statement"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Opportunity Statement"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.customer_form.opportunity_statement,
      callback: function callback($$v) {
        _vm.$set(_vm.customer_form, "opportunity_statement", $$v);
      },
      expression: "customer_form.opportunity_statement"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Psychometric Notes"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.customer_form.psychometric_notes,
      callback: function callback($$v) {
        _vm.$set(_vm.customer_form, "psychometric_notes", $$v);
      },
      expression: "customer_form.psychometric_notes"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Sales Strategy"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.customer_form.sales_strategy,
      callback: function callback($$v) {
        _vm.$set(_vm.customer_form, "sales_strategy", $$v);
      },
      expression: "customer_form.sales_strategy"
    }
  })], 1)], 1)], 1)] : _vm._e()], 2), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_vm.addCustomerModalStepCounter >= 1 ? [_c("el-button", {
    attrs: {
      size: "small"
    },
    on: {
      click: function click($event) {
        _vm.addCustomerDialog = false;
      }
    }
  }, [_vm._v("Cancel")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary",
      size: "small"
    },
    on: {
      click: _vm.addCustomer
    }
  }, [_vm._v("Save")])] : [_c("el-button", {
    attrs: {
      size: "small"
    },
    on: {
      click: function click($event) {
        _vm.addCustomerModalStepCounter++;
      }
    }
  }, [_vm._v(" Next ")])]], 2)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;