"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("g", {
    attrs: {
      refs: "risk-component"
    },
    on: {
      mouseover: _vm.onMouseOver,
      mouseleave: _vm.onMouseLeave
    }
  }, [_vm.toolbar ? _c("foreignObject", {
    attrs: {
      x: _vm.x - 15,
      y: _vm.y + 5,
      width: 90,
      height: 40
    }
  }, [_c("div", {
    staticClass: "text-center",
    style: _vm.computeBGStyle(),
    attrs: {
      id: "risk-tool-component"
    }
  }, [_c("svg-icon", {
    style: _vm.computeStyle(),
    attrs: {
      id: _vm.generateUniqueID(),
      "icon-class": "fire"
    }
  }), _vm._v(" "), _vm.showLabel ? _c("span", {
    staticStyle: {
      "font-size": "10px",
      color: "#4672c4"
    }
  }, [_vm._v(_vm._s(_vm.label))]) : _vm._e()], 1)]) : _c("foreignObject", {
    attrs: {
      x: _vm.x - 15,
      y: _vm.y,
      width: 60,
      height: 50
    }
  }, [_c("div", {
    staticClass: "text-center"
  }, [_c("svg-icon", {
    style: _vm.computeStyle(),
    attrs: {
      id: _vm.generateUniqueID(),
      "icon-class": "fire"
    }
  }), _vm._v(" "), _vm.showLabel ? _c("span", {
    staticStyle: {
      "font-size": "10px",
      color: "#4672c4"
    }
  }, [_vm._v(_vm._s(_vm.label))]) : _vm._e()], 1)])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;