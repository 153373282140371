"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _axios = _interopRequireDefault(require("axios"));
// import { getUserInfo } from '@/api/user'
var _default = exports.default = {
  data: function data() {
    return {
      $_users: []
    };
  },
  mounted: function mounted() {},
  beforeDestroy: function beforeDestroy() {},
  methods: {
    $_retrieveUserList: function $_retrieveUserList() {
      var _this = this;
      var headers = {
        'Content-Type': 'application/json',
        'x-party-id': this.$store.getters.partyId,
        'x-user-id': this.$store.getters.id
      };
      _axios.default.get(process.env.VUE_APP_REST_API_ENDPOINT + 'user', {
        headers: headers
      }).then(function (response) {
        _this.$_users = response.data.data;
      }).catch(function (error) {
        console.log(error);
      });
    },
    $_computeUserName: function $_computeUserName(user) {
      return user.firstName + ' ' + user.lastName;
    },
    $_getUserNameFromID: function $_getUserNameFromID(user_id) {
      if (user_id === 'admin' || user_id === '' || user_id === null) {
        return user_id;
      }
      for (var i = 0; i < this.$_users.length; i++) {
        if (this.$_users[i].id === user_id) {
          return this.$_users[i].firstName + ' ' + this.$_users[i].lastName;
        }
      }
      /* return new Promise((resolve, reject) => {
        getUserInfo(user_id).then(response => {
          resolve(response.data.data.firstName + ' ' + response.data.data.middleName + ' ' + response.data.data.lastName)
        }).catch(error => {
          console.log(error)
        })
      })*/
    }
  }
};