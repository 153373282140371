"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("g", {
    attrs: {
      refs: "phase-container"
    }
  }, [_c("g", {
    attrs: {
      refs: "phase"
    }
  }, [_c("rect", {
    attrs: {
      x: 310 + _vm.index * (_vm.phaseParam.width + 25),
      y: "13",
      width: _vm.phaseParam.width,
      height: _vm.phaseParam.title_height,
      fill: _vm.phaseParam.title_bg_color
    },
    on: {
      click: function click($event) {
        return _vm.editPhase();
      }
    }
  }), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "#EEEEEE"
    },
    attrs: {
      x: 315 + _vm.index * (_vm.phaseParam.width + 25),
      y: "28",
      fill: "black",
      "text-anchor": "start",
      "font-size": "1.0em",
      "font-weight": "bold"
    }
  }, [_vm._v("\n      " + _vm._s(_vm.phase.name) + "\n    ")]), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "#EEEEEE"
    },
    attrs: {
      x: 315 + _vm.index * (_vm.phaseParam.width + 25),
      y: "38",
      "text-anchor": "start",
      "font-size": "0.7em",
      "font-weight": "lighter"
    }
  }, [_vm._v("\n      " + _vm._s(_vm.computeTrimmedText(_vm.phase.description)) + "\n    ")]), _vm._v(" "), _c("rect", {
    attrs: {
      x: 310 + _vm.index * (_vm.phaseParam.width + 25),
      y: "55",
      width: _vm.phaseParam.width,
      height: _vm.phaseHeight,
      fill: "white",
      "fill-opacity": 0.6,
      "stroke-width": 2,
      stroke: "gray",
      "stroke-opacity": 0.2
    }
  }), _vm._v(" "), _c("g", {
    attrs: {
      refs: "deleteLaneIcon"
    },
    on: {
      click: function click($event) {
        return _vm.deletePhase(_vm.index);
      }
    }
  }, [_c("circle", {
    attrs: {
      cx: 320 + _vm.index * (_vm.phaseParam.width + 25) + _vm.phaseParam.width - 28,
      cy: 35,
      r: "12",
      stroke: "black",
      "stroke-width": "1",
      fill: "#F56C6C"
    }
  }), _vm._v(" "), _c("line", {
    attrs: {
      x1: 315 + _vm.index * (_vm.phaseParam.width + 25) + _vm.phaseParam.width - 28,
      y1: 35,
      x2: 325 + _vm.index * (_vm.phaseParam.width + 25) + _vm.phaseParam.width - 28,
      y2: 35,
      stroke: "white",
      "stroke-width": "2",
      fill: "none",
      "fill-opacity": "1",
      "stroke-opacity": "0.9"
    }
  })]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "progress"
    }
  }, [_c("g", {
    attrs: {
      refs: "progress_percentage"
    },
    on: {
      click: function click($event) {
        $event.preventDefault();
        return _vm.openPhaseProgressDetail();
      }
    }
  }, [_c("text", {
    staticClass: "element-icon",
    attrs: {
      x: 320 + _vm.index * (_vm.phaseParam.width + 25) + _vm.phaseParam.width - 75,
      y: "90",
      stroke: "golo",
      "stroke-width": "0.2",
      fill: "gold",
      "text-anchor": "middle",
      "font-size": "3.5em",
      "font-weight": "bold"
    }
  }, [_vm._v("\n          🗠\n        ")]), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "#222222"
    },
    attrs: {
      x: 320 + _vm.index * (_vm.phaseParam.width + 25) + _vm.phaseParam.width - 75,
      y: "88",
      fill: "black",
      "text-anchor": "middle",
      "font-size": "1.2em",
      "font-weight": "bold"
    }
  }, [_vm._v("\n          " + _vm._s(_vm.phaseProgress) + "\n        ")])])]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "notes_list_icon"
    },
    on: {
      click: function click($event) {
        $event.preventDefault();
        return _vm.openNotesList.apply(null, arguments);
      }
    }
  }, [_c("text", {
    staticClass: "element-icon",
    attrs: {
      x: 320 + _vm.index * (_vm.phaseParam.width + 25) + _vm.phaseParam.width - 28,
      y: "90",
      fill: "gold",
      "text-anchor": "middle",
      "font-size": "2.8em",
      "font-weight": "lighter"
    }
  }, [_vm._v("\n        🗎\n      ")]), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "#222222"
    },
    attrs: {
      x: 320 + _vm.index * (_vm.phaseParam.width + 25) + _vm.phaseParam.width - 27,
      y: "88",
      fill: "white",
      "text-anchor": "middle",
      "font-size": "1.2em",
      "font-weight": "bold"
    }
  }, [_vm._v("\n        " + _vm._s(_vm.noteCount) + "\n      ")])])])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;