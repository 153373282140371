"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", [_c("span", {
    staticClass: "ml-10",
    staticStyle: {
      "padding-left": "2pt",
      "font-size": "14pt"
    }
  }, [_vm._v("Actions")]), _vm._v(" "), _c("el-button", {
    staticClass: "fr mr-10",
    attrs: {
      type: "primary",
      size: "mini",
      icon: "el-icon-refresh"
    },
    on: {
      click: function click($event) {
        return _vm.reload();
      }
    }
  }), _vm._v(" "), _c("el-button", {
    staticClass: "fr mr-10",
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.showArtefactActionDialog();
      }
    }
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "create"
    }
  }), _vm._v(" Add Action")], 1)], 1), _vm._v(" "), _c("el-table", {
    staticClass: "mt-10 ml-10",
    staticStyle: {
      width: "98%"
    },
    attrs: {
      data: _vm.actions,
      size: "micro"
    },
    on: {
      "row-click": _vm.selectAction
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      align: "center"
    }
  }), _vm._v(" "), _vm.columns.name ? _c("el-table-column", {
    attrs: {
      prop: "name",
      label: "Name"
    }
  }) : _vm._e(), _vm._v(" "), _vm.columns.status ? _c("el-table-column", {
    attrs: {
      prop: "status",
      label: "Status",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-tag", {
          attrs: {
            size: "mini",
            type: _vm.computeActionStatusTagType(scope.row.status)
          }
        }, [_vm._v(_vm._s(scope.row.status))])];
      }
    }], null, false, 3014130668)
  }) : _vm._e(), _vm._v(" "), _vm.columns.created ? _c("el-table-column", {
    attrs: {
      prop: "createdAt",
      label: "Created"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v("\n        " + _vm._s(_vm.computeHumanFriendlyDate(scope.row.createdAt)) + "\n      ")];
      }
    }], null, false, 1835545488)
  }) : _vm._e(), _vm._v(" "), _vm.columns.priority ? _c("el-table-column", {
    attrs: {
      prop: "priority",
      label: "Priority",
      width: "140px",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-tag", {
          attrs: {
            size: "mini",
            type: _vm.computePriorityTagType(scope.row.priority)
          }
        }, [scope.row.priority === "HIGH" ? _c("i", {
          staticClass: "el-icon-arrow-up",
          staticStyle: {
            color: "red"
          },
          attrs: {
            title: "High"
          }
        }) : _vm._e(), _vm._v(" "), scope.row.priority === "LOW" ? _c("i", {
          staticClass: "el-icon-arrow-down",
          staticStyle: {
            color: "green"
          },
          attrs: {
            title: "Low"
          }
        }) : _vm._e(), _vm._v(" "), scope.row.priority === "MEDIUM" ? _c("i", {
          staticClass: "el-icon-minus",
          staticStyle: {
            color: "gray"
          },
          attrs: {
            title: "Medium"
          }
        }) : _vm._e(), _vm._v(" "), scope.row.priority === "CRITICAL" ? _c("i", {
          staticClass: "el-icon-warning-outline",
          staticStyle: {
            color: "red"
          },
          attrs: {
            title: "Critical"
          }
        }) : _vm._e(), _vm._v("\n          " + _vm._s(scope.row.priority) + "\n        ")])];
      }
    }], null, false, 329234938)
  }) : _vm._e(), _vm._v(" "), _vm.columns.due ? _c("el-table-column", {
    attrs: {
      prop: "dueBy",
      label: "Due"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v("\n        " + _vm._s(_vm.computeHumanFriendlyDate(scope.row.dueBy)) + "\n      ")];
      }
    }], null, false, 2521186122)
  }) : _vm._e(), _vm._v(" "), _vm.columns.assigned ? _c("el-table-column", {
    attrs: {
      label: "Assigned To"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v("\n        " + _vm._s(_vm.getUserFromUserID(scope.row.assignedTo)) + "\n      ")];
      }
    }], null, false, 1873815946)
  }) : _vm._e()], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Add Action",
      visible: _vm.addArtefactActionDialog,
      width: "40%",
      "append-to-body": ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addArtefactActionDialog = $event;
      }
    }
  }, [_c("el-form", {
    ref: "artefactActionForm",
    attrs: {
      model: _vm.artefactActionForm,
      "label-width": "150px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Action name"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.artefactActionForm.name,
      callback: function callback($$v) {
        _vm.$set(_vm.artefactActionForm, "name", $$v);
      },
      expression: "artefactActionForm.name"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Action priority"
    }
  }, [_c("el-radio-group", {
    model: {
      value: _vm.artefactActionForm.priority,
      callback: function callback($$v) {
        _vm.$set(_vm.artefactActionForm, "priority", $$v);
      },
      expression: "artefactActionForm.priority"
    }
  }, [_c("el-radio", {
    attrs: {
      label: "LOW"
    }
  }), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "MEDIUM"
    }
  }), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "HIGH"
    }
  })], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Action Assigned To"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      placeholder: "Select",
      size: "mini"
    },
    model: {
      value: _vm.artefactActionForm.assignedTo,
      callback: function callback($$v) {
        _vm.$set(_vm.artefactActionForm, "assignedTo", $$v);
      },
      expression: "artefactActionForm.assignedTo"
    }
  }, _vm._l(_vm.users, function (user, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: _vm.computeUserName(user),
        value: user.id
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Action due by"
    }
  }, [_c("el-date-picker", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      type: "date",
      placeholder: "Pick a day",
      size: "mini"
    },
    model: {
      value: _vm.artefactActionForm.dueBy,
      callback: function callback($$v) {
        _vm.$set(_vm.artefactActionForm, "dueBy", $$v);
      },
      expression: "artefactActionForm.dueBy"
    }
  })], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.addArtefactAction();
      }
    }
  }, [_vm._v("Add Action")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.addArtefactActionDialog = false;
      }
    }
  }, [_vm._v("Cancel")])], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;