"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _default2 = exports.default = {
  name: 'UMLComponentOption',
  components: {},
  props: {
    id: {
      type: String,
      default: ''
    },
    component: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    option: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      activeTab: {}
    };
  },
  computed: {
    // a computed getter
  },
  watch: {
    option: function option(val) {
      if (this.option.hasOwnProperty('categories') && this.option.categories.length > 0) {
        this.activeTab = this.option.categories[0];
      } else {
        this.activeTab = {};
      }
    }
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    computeOptionFillColor: function computeOptionFillColor(category_name) {
      if (this.activeTab.name === category_name) {
        return 'rgb(159, 193, 245)';
      } else {
        return 'rgb(222, 234, 252)';
      }
    },
    computeCategoryShapeX: function computeCategoryShapeX(index) {
      switch (index) {
        case 0:
          return this.component.x + 225;
        case 1:
          return this.component.x + 292;
        case 2:
          return this.component.x + 359;
        default:
          return this.component.x + 225;
      }
    },
    computeCategoryTextX: function computeCategoryTextX(index) {
      switch (index) {
        case 0:
          return this.component.x + 257;
        case 1:
          return this.component.x + 324;
        case 2:
          return this.component.x + 391;
        default:
          return this.component.x + 257;
      }
    },
    addCategoryDetail: function addCategoryDetail() {
      this.$emit('add-category-detail', this.activeTab);
    }
  }
};