"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _default2 = exports.default = {
  name: 'Activity',
  components: {},
  props: {
    activity: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    index: {
      type: Number,
      default: 0
    },
    subIndex: {
      type: Number,
      default: 0
    },
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
    activityProgress: {
      type: String,
      default: ''
    },
    bgColor: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      loading: false
    };
  },
  computed: {},
  created: function created() {},
  methods: {
    generateActivityID: function generateActivityID(phase_index, activity_index) {
      return 'activity_' + phase_index + '_' + activity_index;
    },
    selectActivity: function selectActivity(activity, phase_index, activity_index) {
      this.$emit('select-activity', activity, phase_index, activity_index);
    },
    openActivityProgressDetailDialog: function openActivityProgressDetailDialog(phase_index, activity_index) {
      this.$emit('activity-progress', phase_index, activity_index);
    },
    createGoal: function createGoal(phase_index, activity_index) {
      this.$emit('create-goal', phase_index, activity_index);
    },
    deleteActivity: function deleteActivity(phase_index, activity_index) {
      this.$emit('delete-activity', phase_index, activity_index);
    },
    startActivityMove: function startActivityMove(_ref) {
      var el = _ref.el,
        deltaX = _ref.deltaX,
        deltaY = _ref.deltaY,
        offsetX = _ref.offsetX,
        offsetY = _ref.offsetY,
        clientX = _ref.clientX,
        clientY = _ref.clientY,
        first = _ref.first,
        last = _ref.last;
      this.$emit('move-activity', el, deltaX, deltaY, offsetX, offsetY, clientX, clientY, first, last);
    },
    activityMouseOver: function activityMouseOver(phase_index, activity_index) {
      this.$emit('mouse-over', phase_index, activity_index);
    },
    activityMouseLeave: function activityMouseLeave(phase_index, activity_index) {
      this.$emit('mouse-leave', phase_index, activity_index);
    }
  }
};