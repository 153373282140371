"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
var _axios = _interopRequireDefault(require("axios"));
var _default = exports.default = {
  name: 'JIRAIntegrationStatus',
  data: function data() {
    return {
      loading: false
    };
  },
  mounted: function mounted() {
    if (this.$route.query.error) {
      // User denied request
      var error_status = this.$route.query.error;
      var error_state = this.$route.query.state;
      this.consentFailure(error_status, error_state);
    }
    if (this.$route.query.code) {
      // User accept request
      var code = this.$route.query.code;
      var success_state = this.$route.query.state;
      this.consentSuccess(code, success_state.split('-')[0], success_state.split('-')[1]);
    }
  },
  methods: {
    consentSuccess: function consentSuccess(code, user_id, instance_id) {
      var _this = this;
      this.loading = true;
      var headers = {
        'Content-Type': 'application/json',
        'x-party-id': this.$store.getters.partyId,
        'x-user-id': this.$store.getters.id
      };
      _axios.default.get(process.env.VUE_APP_REST_API_ENDPOINT + 'tprty/' + instance_id + '/auth?state=' + user_id + '&code=' + code, {
        headers: headers
      }).then(function (response) {
        _this.loading = false;
        _this.response = response.data;
        if (response.data.status === 'SUCCESS') {
          // Redirect back to admin / user settings page
          _this.$router.push({
            name: "Administration"
          });
        } else {
          window.open(response.data.data.description);
          /*          this.$notify({
                      title: 'Error',
                      message: response.data.data.description,
                      type: 'error',
                      duration: 2000
                    })*/
        }
      }).catch(function (error) {
        _this.loading = false;
        _this.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
      });
    },
    consentFailure: function consentFailure(error_status, state) {
      // Redirect back to admin / user settings page
      this.$router.push({
        name: "Administration"
      });
    }
  }
};