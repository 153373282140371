"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_vm.steps.length > 0 ? _c("sticky", {
    attrs: {
      "class-name": "sub-stepbar"
    }
  }, [_c("el-steps", {
    staticClass: "simple_step",
    attrs: {
      active: _vm.currentStep,
      space: 200,
      "finish-status": "process",
      simple: ""
    }
  }, _vm._l(_vm.steps, function (step, index) {
    return _c("el-step", {
      key: index,
      staticStyle: {
        cursor: "pointer"
      }
    }, [_c("el-popover", {
      attrs: {
        slot: "title",
        title: step.shortDescription,
        transition: "",
        placement: "bottom",
        trigger: "click",
        "close-delay": 6000,
        "popper-class": "process-steps",
        disabled: step.shortDescription === ""
      },
      slot: "title",
      model: {
        value: step.visible,
        callback: function callback($$v) {
          _vm.$set(step, "visible", $$v);
        },
        expression: "step.visible"
      }
    }, [_c("span", {
      attrs: {
        slot: "reference"
      },
      slot: "reference"
    }, [_c("div", {
      attrs: {
        slot: "title"
      },
      on: {
        click: function click($event) {
          return _vm.updateActiveStep(index);
        }
      },
      slot: "title"
    }, [_vm._v(_vm._s(step.name))])]), _vm._v(" "), _c("div", {
      attrs: {
        slot: ""
      },
      slot: "default"
    }, [_vm._v("\n            " + _vm._s(step.longDescription) + "\n            "), _c("div", {
      staticClass: "text-right",
      staticStyle: {
        "padding-top": "4pt"
      }
    }, [_c("el-button", {
      attrs: {
        type: "text",
        size: "mini"
      },
      on: {
        click: function click($event) {
          _vm.processStepDialog = true;
        }
      }
    }, [_vm._v("Learn more")])], 1)])])], 1);
  }), 1)], 1) : _vm._e(), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: _vm.activeStep.shortDescription,
      visible: _vm.processStepDialog,
      width: "30%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.processStepDialog = $event;
      }
    }
  }, [_c("div", [_vm._v("\n      " + _vm._s(_vm.activeStep.longDescription) + "\n      "), _c("div", {
    staticStyle: {
      height: "300px",
      "line-height": "300px",
      width: "100%",
      border: "dotted grey 1pt",
      "border-radius": "7pt",
      "margin-top": "15pt",
      "text-align": "center",
      "vertical-align": "center"
    }
  }, [_c("span", {
    staticStyle: {
      height: "100%",
      "vertical-align": "middle"
    }
  }, [_c("i", [_vm._v("Video coming...")])])])])])], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;