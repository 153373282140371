"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "app-container"
  }, [_c("el-row", [_c("el-col", {
    staticClass: "pr-5 img-overlay-wrap",
    attrs: {
      span: 18
    }
  }, [_c("img", {
    staticClass: "image",
    attrs: {
      src: _vm.selectedVersion.url
    }
  }), _vm._v(" "), _c("svg", {
    directives: [{
      name: "dragged",
      rawName: "v-dragged.prevent",
      value: _vm.onDragged,
      expression: "onDragged",
      modifiers: {
        prevent: true
      }
    }]
  }, [_vm._l(_vm.selectedVersion.tasks, function (task, index) {
    return _c("g", {
      key: index,
      attrs: {
        refs: "annotation"
      }
    }, [_c("g", {
      attrs: {
        refs: "annotation_box"
      },
      on: {
        click: function click($event) {
          return _vm.selectAnnotation(task, index);
        }
      }
    }, [_c("rect", {
      style: task.annotation.style,
      attrs: {
        x: task.annotation.x,
        y: task.annotation.y,
        rx: "10",
        ry: "10",
        width: task.annotation.width,
        height: task.annotation.height
      }
    }), _vm._v(" "), task.status === "Completed" ? _c("rect", {
      attrs: {
        rx: "10",
        ry: "10",
        x: task.annotation.x,
        y: task.annotation.y,
        width: task.annotation.width,
        height: task.annotation.height,
        stroke: "#80ffdb",
        "stroke-width": "2px",
        fill: "none"
      }
    }) : _vm._e(), _vm._v(" "), task.status === "Pending" ? _c("rect", {
      attrs: {
        rx: "10",
        ry: "10",
        x: task.annotation.x,
        y: task.annotation.y,
        width: task.annotation.width,
        height: task.annotation.height,
        stroke: "#e63946",
        "stroke-width": "2px",
        fill: "none"
      }
    }) : _vm._e(), _vm._v(" "), _vm.selectedTaskIndex !== null && _vm.selectedTaskIndex === index ? _c("g", {
      attrs: {
        refs: "selected_tools"
      },
      on: {
        click: function click($event) {
          $event.preventDefault();
          return _vm.removeTask(index);
        }
      }
    }, [_c("foreignObject", {
      attrs: {
        x: task.annotation.x + task.annotation.width + 5,
        y: task.annotation.y,
        width: task.annotation.height,
        height: task.annotation.height
      }
    }, [_c("div", {
      staticClass: "text-center"
    }, [_c("svg-icon", {
      staticStyle: {
        "'background-color": "red",
        width: "20px",
        height: "20px"
      },
      attrs: {
        "icon-class": "gradient-circle-error"
      }
    })], 1)])]) : _vm._e()]), _vm._v(" "), _c("g", {
      attrs: {
        refs: "annoation_text"
      }
    }, [_c("text", {
      attrs: {
        x: task.annotation.x + task.annotation.width / 2,
        y: task.annotation.y + task.annotation.height + 10,
        fill: "black",
        "font-size": "smaller",
        "text-anchor": "middle"
      }
    }, [_vm._v(_vm._s(task.name))])])]);
  }), _vm._v(" "), _c("g", {
    attrs: {
      refs: "temp_annotation"
    }
  }, [_c("rect", {
    style: _vm.tempAnnotation.style,
    attrs: {
      x: _vm.tempAnnotation.x,
      y: _vm.tempAnnotation.y,
      rx: "10",
      ry: "10",
      width: _vm.tempAnnotation.width,
      height: _vm.tempAnnotation.height
    }
  }), _vm._v(" "), _c("rect", {
    attrs: {
      rx: "10",
      ry: "10",
      x: _vm.tempAnnotation.x,
      y: _vm.tempAnnotation.y,
      width: _vm.tempAnnotation.width,
      height: _vm.tempAnnotation.height,
      stroke: "#e63946",
      "stroke-width": "2px",
      fill: "none"
    }
  })])], 2)]), _vm._v(" "), _c("el-col", {
    staticClass: "pl-5",
    attrs: {
      span: 6
    }
  }, [_c("el-row", {
    staticStyle: {
      "margin-bottom": "10px"
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("div", {
    staticClass: "text-right"
  }, [_c("el-button", {
    staticClass: "fr",
    attrs: {
      size: "mini",
      icon: "el-icon-plus"
    }
  }, [_vm._v("Upload new version")])], 1)])], 1), _vm._v(" "), _c("el-row", [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-card", [_c("div", {
    staticClass: "text-center",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("strong", [_vm._v("Tools")])]), _vm._v(" "), _c("div", {
    staticClass: "text-center"
  }, [_c("el-checkbox-button", {
    attrs: {
      label: "Draw Mode",
      name: "draw",
      size: "mini"
    },
    model: {
      value: _vm.draw,
      callback: function callback($$v) {
        _vm.draw = $$v;
      },
      expression: "draw"
    }
  })], 1)])], 1)], 1), _vm._v(" "), _c("el-row", [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-card", [_c("div", {
    staticClass: "text-center",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("strong", [_vm._v("Tasks")])]), _vm._v(" "), _c("table", {
    staticClass: "text-center",
    attrs: {
      width: "100%"
    }
  }, [_c("tr", [_c("th", [_vm._v("Name")]), _vm._v(" "), _c("th", [_vm._v("Status")]), _vm._v(" "), _c("th", [_vm._v("Version")]), _vm._v(" "), _c("th", [_vm._v("Priority")]), _vm._v(" "), _c("th", [_vm._v("#")])]), _vm._v(" "), _vm._l(_vm.mockupStoryboard.versions, function (version, index) {
    return _vm._l(version.tasks, function (task, sub_index) {
      return _c("tr", {
        key: _vm.generateVersionTaskKey(index, sub_index),
        class: {
          completed: task.status === "Completed"
        }
      }, [_c("td", [_vm._v(_vm._s(task.name))]), _vm._v(" "), _c("td", [_vm._v(_vm._s(task.status))]), _vm._v(" "), _c("td", [_c("el-button", {
        attrs: {
          size: "mini"
        },
        on: {
          click: function click($event) {
            return _vm.updateVersionChange(version, index);
          }
        }
      }, [_vm._v(_vm._s(version.version))])], 1), _vm._v(" "), _c("td", [task.priority ? _c("svg-icon", {
        attrs: {
          "icon-class": _vm.getPriorityIcon(task.priority),
          title: "Priority"
        }
      }) : _vm._e()], 1), _vm._v(" "), _c("td", [task.status === "Pending" ? _c("i", {
        staticClass: "el-icon-check",
        staticStyle: {
          color: "#13ce66"
        },
        on: {
          click: function click($event) {
            task.status = "Completed";
          }
        }
      }) : _c("i", {
        staticClass: "el-icon-close",
        staticStyle: {
          color: "#ff4949"
        },
        on: {
          click: function click($event) {
            task.status = "Pending";
          }
        }
      }), _vm._v(" "), _c("i", {
        staticClass: "el-icon-delete",
        staticStyle: {
          color: "red"
        },
        on: {
          click: function click($event) {
            $event.preventDefault();
            return _vm.removeTask(sub_index);
          }
        }
      })])]);
    });
  })], 2)])], 1)], 1)], 1)], 1)], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;