"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-card", {
    staticClass: "mt-10"
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 14
    }
  }, [_c("p", {
    staticClass: "sub-title-rounded"
  }, [_vm._v("Segment Description")]), _vm._v(" "), _c("div", {
    staticClass: "container"
  }, [_c("el-input", {
    attrs: {
      rows: 5,
      type: "textarea"
    },
    model: {
      value: _vm.activeSegment.description,
      callback: function callback($$v) {
        _vm.$set(_vm.activeSegment, "description", $$v);
      },
      expression: "activeSegment.description"
    }
  })], 1), _vm._v(" "), _c("p", {
    staticClass: "sub-title-left"
  }, [_vm._v("Revenue Notes")]), _vm._v(" "), _c("div", {
    staticClass: "container"
  }, [_c("el-input", {
    attrs: {
      rows: 5,
      type: "textarea"
    },
    model: {
      value: _vm.activeSegment.revenue_notes,
      callback: function callback($$v) {
        _vm.$set(_vm.activeSegment, "revenue_notes", $$v);
      },
      expression: "activeSegment.revenue_notes"
    }
  })], 1)]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("p", {
    staticClass: "sub-title-right"
  }, [_vm._v("Segment Needs")]), _vm._v(" "), _vm.activeSegment.segment_needs.length > 0 ? _c("el-table", {
    staticClass: "container",
    attrs: {
      data: _vm.activeSegment.segment_needs,
      "show-header": "",
      size: "mini"
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Met",
      width: "55"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-checkbox", {
          on: {
            change: function change($event) {
              return _vm.segmentNeedChange(scope.row);
            }
          },
          model: {
            value: scope.row.complete,
            callback: function callback($$v) {
              _vm.$set(scope.row, "complete", $$v);
            },
            expression: "scope.row.complete"
          }
        })];
      }
    }], null, false, 3754623983)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      property: "name",
      label: "Needs"
    }
  })], 1) : _c("p", [_vm._v("No needs founds. Add some needs")])], 1)], 1), _vm._v(" "), _c("el-divider"), _vm._v(" "), _c("el-row", [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("div", {
    staticClass: "text-left"
  }, [_c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.openAddSegmentModal("edit");
      }
    }
  }, [_c("b", [_vm._v("Edit Segment")])])], 1)]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("div", {
    staticClass: "text-right"
  }, [_c("el-button-group", {
    attrs: {
      size: "mini"
    }
  }, [_c("el-tooltip", {
    attrs: {
      effect: "dark",
      content: "",
      placement: "top-start"
    }
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      disabled: "",
      icon: "el-icon-arrow-left"
    }
  }, [_vm._v("Previous")])], 1), _vm._v(" "), _c("el-tooltip", {
    attrs: {
      effect: "dark",
      content: "Go To Define Revenue Types",
      placement: "top-start"
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.onClickDefineRevenueTypes
    }
  }, [_vm._v("Next"), _c("i", {
    staticClass: "el-icon-arrow-right"
  })])], 1)], 1)], 1)])], 1)], 1)], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;