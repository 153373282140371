"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("g", {
    attrs: {
      id: _vm.id
    },
    on: {
      drop: _vm.handleDrop,
      dragover: _vm.handleDragOver,
      mouseover: function mouseover($event) {
        _vm.hover = true;
      },
      mouseleave: function mouseleave($event) {
        _vm.hover = false;
      }
    }
  }, [_c("rect", {
    attrs: {
      x: _vm.component.x,
      y: _vm.component.y,
      width: _vm.computeWidth,
      height: _vm.computeHeight,
      stroke: _vm.strokeColor,
      "stroke-width": "1",
      fill: _vm.fillColor
    }
  }), _vm._v(" "), _c("g", {
    attrs: {
      id: "deleteComponent"
    },
    on: {
      click: _vm.deleteComponent
    }
  }, [_c("circle", {
    attrs: {
      cx: _vm.component.x + _vm.computeWidth,
      cy: _vm.component.y,
      r: 5,
      fill: "#ff000087"
    }
  }), _vm._v(" "), _c("g", {
    attrs: {
      id: "deleteComponentIcon"
    }
  }, [_c("line", {
    attrs: {
      x1: _vm.component.x + _vm.computeWidth - 2,
      y1: _vm.component.y + 2,
      x2: _vm.component.x + _vm.computeWidth + 2,
      y2: _vm.component.y - 2,
      stroke: "white",
      "stroke-width": "1"
    }
  }), _vm._v(" "), _c("line", {
    attrs: {
      x1: _vm.component.x + _vm.computeWidth - 2,
      y1: _vm.component.y - 2,
      x2: _vm.component.x + _vm.computeWidth + 2,
      y2: _vm.component.y + 2,
      stroke: "white",
      "stroke-width": "1"
    }
  })])]), _vm._v(" "), _c("rect", {
    attrs: {
      x: _vm.component.x + _vm.computeWidth - 10,
      y: _vm.component.y + 20,
      width: "20",
      height: "5",
      stroke: "gray",
      "stroke-width": "1",
      fill: "white"
    }
  }), _vm._v(" "), _c("rect", {
    attrs: {
      x: _vm.component.x + _vm.computeWidth - 10,
      y: _vm.component.y + 30,
      width: "20",
      height: "5",
      stroke: "gray",
      "stroke-width": "1",
      fill: "white"
    }
  }), _vm._v(" "), _c("text", {
    attrs: {
      x: _vm.component.x + 5,
      y: _vm.component.y + 15,
      "font-family": "Verdana",
      "font-size": "12",
      "font-weight": _vm.fontWeight,
      fill: "gray",
      "text-anchor": "left"
    }
  }, [_vm._v("\n    " + _vm._s(_vm.component.title) + "\n  ")]), _vm._v(" "), _vm._l(_vm.component.services, function (service, index) {
    return _c("g", {
      key: index,
      attrs: {
        refs: "services"
      }
    }, [_c("text", {
      attrs: {
        x: _vm.component.x + 5,
        y: _vm.component.y + 30 + index * 10,
        "font-family": "Verdana",
        "font-size": "10",
        fill: "gray",
        "text-anchor": "left"
      }
    }, [_vm._v("\n      + " + _vm._s(service.name) + "\n    ")]), _vm._v(" "), _c("g", {
      attrs: {
        id: "deleteService"
      },
      on: {
        click: function click($event) {
          return _vm.deleteService(index, service);
        }
      }
    }, [_c("circle", {
      attrs: {
        cx: _vm.component.x + 180,
        cy: _vm.component.y + index * 10 + 27,
        r: 5,
        fill: "#ff000087"
      }
    }), _vm._v(" "), _c("g", {
      attrs: {
        id: "deleteServiceIcon"
      }
    }, [_c("line", {
      attrs: {
        x1: _vm.component.x + 178,
        y1: _vm.component.y + index * 10 + 25,
        x2: _vm.component.x + 182,
        y2: _vm.component.y + index * 10 + 29,
        stroke: "white",
        "stroke-width": "1"
      }
    }), _vm._v(" "), _c("line", {
      attrs: {
        x1: _vm.component.x + 178,
        y1: _vm.component.y + index * 10 + 29,
        x2: _vm.component.x + 182,
        y2: _vm.component.y + index * 10 + 25,
        stroke: "white",
        "stroke-width": "1"
      }
    })])])]);
  }), _vm._v(" "), _vm.hover && _vm.component.type !== "parent" && _vm.component.options.length > 0 ? _c("g", {
    attrs: {
      refs: "componentOptions"
    }
  }, _vm._l(_vm.component.options, function (option, index) {
    return _c("g", {
      key: index,
      on: {
        click: function click($event) {
          return _vm.clicked(option);
        }
      }
    }, [_c("circle", {
      attrs: {
        cx: _vm.component.x + 200,
        cy: _vm.computeOptionsCircleY(index),
        stroke: "rgb(159, 193, 245)",
        fill: "rgb(222, 234, 252)",
        r: "12"
      }
    }), _vm._v(" "), _c("text", {
      attrs: {
        x: _vm.component.x + 200,
        y: _vm.computeOptionsTextY(index),
        "font-family": "Verdana",
        "font-size": "8",
        fill: "black",
        "text-anchor": "middle"
      }
    }, [_vm._v("\n        " + _vm._s(option.name) + "\n      ")])]);
  }), 0) : _vm._e()], 2);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;