"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _color = require("@/utils/color");
var _default2 = exports.default = {
  name: 'Release',
  components: {},
  props: {
    x: {
      type: Number,
      required: true,
      default: 0
    },
    y: {
      type: Number,
      required: true,
      default: 0
    },
    width: {
      type: Number,
      required: true,
      default: 0
    },
    headerHeight: {
      type: Number,
      default: 40
    },
    height: {
      type: Number,
      required: true,
      default: 0
    },
    index: {
      type: Number,
      required: true,
      default: 0
    },
    release: {
      type: Object,
      default: function _default() {
        return {
          title: '',
          description: ''
        };
      }
    },
    timeframe: {
      type: Number,
      default: 0
    }
  },
  data: function data() {
    return {
      isHover: false,
      isDeleteHover: false
    };
  },
  computed: {
    computeReleaseResizeControlWidth: function computeReleaseResizeControlWidth() {
      var width = 10;
      return width;
    },
    computeFillColor: function computeFillColor() {
      if (this.release.additionalattributes.style.color.length === 8) {
        return '#' + this.release.additionalattributes.style.color.slice(0, -2);
      }
      return this.release.additionalattributes.style.color;
    },
    computeFillOpacity: function computeFillOpacity() {
      if (this.release.additionalattributes.style.color.length === 8) {
        return (0, _color.hexOpacityToRGBAlpha)(this.release.additionalattributes.style.color.slice(-2));
      }
      return 0.7;
    },
    computeTitleColor: function computeTitleColor() {
      return 'font-size:1em;font-weight:100; text-align: center; color:' + (0, _color.getContrastYIQ)(this.computeFillColor.substring(1)) + '; word-wrap: break-word;-webkit-font-smoothing: antialiased;letter-spacing: 1.5px;';
    },
    computeIconStyle: function computeIconStyle() {
      return 'font-size:1em;color:' + (0, _color.getContrastYIQ)(this.computeFillColor.substring(1));
    }
  },
  watch: {},
  mounted: function mounted() {},
  created: function created() {},
  methods: {
    truncateString: function truncateString(string, length) {
      return this._.truncate(string, {
        'length': length / this.timeframe
      });
    },
    onReleaseDragged: function onReleaseDragged(_ref) {
      var el = _ref.el,
        deltaX = _ref.deltaX,
        deltaY = _ref.deltaY,
        offsetX = _ref.offsetX,
        offsetY = _ref.offsetY,
        clientX = _ref.clientX,
        clientY = _ref.clientY,
        first = _ref.first,
        last = _ref.last;
      if (last !== undefined && last) {
        this.$emit('drag-stop', this.index, parseInt(deltaX));
        return;
      }
      if (deltaX === undefined || deltaY === undefined) {
        return;
      }
      this.$emit('drag', this.index, parseInt(deltaX));
    },
    onReleaseLeftResized: function onReleaseLeftResized(_ref2) {
      var el = _ref2.el,
        deltaX = _ref2.deltaX,
        deltaY = _ref2.deltaY,
        offsetX = _ref2.offsetX,
        offsetY = _ref2.offsetY,
        clientX = _ref2.clientX,
        clientY = _ref2.clientY,
        first = _ref2.first,
        last = _ref2.last;
      if (last !== undefined && last) {
        this.$emit('resize-stop', 'left', this.index, parseInt(deltaX));
        return;
      }
      if (deltaX === undefined || deltaY === undefined) {
        return;
      }
      this.$emit('resize', 'left', this.index, parseInt(deltaX));
    },
    onReleaseRightResized: function onReleaseRightResized(_ref3) {
      var el = _ref3.el,
        deltaX = _ref3.deltaX,
        deltaY = _ref3.deltaY,
        offsetX = _ref3.offsetX,
        offsetY = _ref3.offsetY,
        clientX = _ref3.clientX,
        clientY = _ref3.clientY,
        first = _ref3.first,
        last = _ref3.last;
      if (last !== undefined && last) {
        this.$emit('resize-stop', 'right', this.index, parseInt(deltaX));
        return;
      }
      if (deltaX === undefined || deltaY === undefined) {
        return;
      }
      this.$emit('resize', 'right', this.index, parseInt(deltaX));
    },
    onClick: function onClick() {
      this.$emit('release-click', this.index);
    },
    createIncrement: function createIncrement() {
      this.$emit('create-increment', this.index);
    },
    deleteRelease: function deleteRelease() {
      var _this = this;
      this.$confirm('This will permanently delete the release. Continue?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(function () {
        _this.$emit('delete', _this.release.id, _this.index);
      }).catch(function () {
        _this.$message({
          type: 'info',
          message: 'Delete canceled'
        });
      });
    }
  }
};