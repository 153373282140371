"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "app-container"
  }, [_c("el-card", [_c("el-tabs", {
    attrs: {
      value: "refine_feature"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "Refine Features",
      name: "refine_feature"
    }
  }, [_c("refine-feature-component", {
    on: {
      clicked: _vm.onFeatureSelect
    }
  })], 1), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: "Pain Points",
      name: "pain_points"
    }
  }, [_c("feature-managment-pain-point-pleasers-component", {
    on: {
      clicked: _vm.onPainPointPleaserSelect
    }
  })], 1), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: "Progress",
      name: "progress"
    }
  }, [_c("feature-managment-progress-component")], 1)], 1)], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "PainPoint Pleaser Details",
      visible: _vm.painPointsPleasersDetailsDialog,
      width: "90%",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.painPointsPleasersDetailsDialog = $event;
      }
    }
  }, [_c("feature-managment-pain-point-pleasers-details-component", {
    attrs: {
      "pain-point-pleaser": _vm.selectedPainPointPleaser
    }
  })], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Feature Details",
      visible: _vm.featureDetailsDialog,
      width: "90%",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.featureDetailsDialog = $event;
      }
    }
  }, [_c("feature-details-component", {
    attrs: {
      "selected-feature": _vm.selectedFeature
    }
  }), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      slot: "button",
      name: "closeButton",
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.featureDetailsDialog = false;
      }
    },
    slot: "button"
  }, [_vm._v("\n        Close\n      ")])], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;