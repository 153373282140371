"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _echarts = _interopRequireDefault(require("echarts"));
var _utils = require("@/utils");
require('echarts/theme/macarons'); // echarts theme
var _default2 = exports.default = {
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '20px'
    },
    height: {
      type: String,
      default: '20px'
    },
    status: {
      type: Array,
      default: function _default() {
        return [0, 0, 0, 0, 0, 0, 0, 0];
      }
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      chart: null
    };
  },
  watch: {
    'status': {
      handler: function handler(after, before) {
        this.initChart();
      },
      deep: true
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.initChart();
    this.__resizeHandler = (0, _utils.debounce)(function () {
      if (_this.chart) {
        _this.chart.resize();
      }
    }, 100);
    window.addEventListener('resize', this.__resizeHandler, {
      passive: true
    });
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    window.removeEventListener('resize', this.__resizeHandler);
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart: function initChart() {
      this.chart = _echarts.default.init(this.$el, 'macarons');
      var status_data = [];
      for (var i = 0; i < this.status.length; i++) {
        status_data.push({
          value: this.status[i].noOfTickets,
          name: this.status[i].status
        });
      }
      this.chart.setOption({
        calculable: false,
        animation: true,
        color: ['#2196f3', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4'],
        series: [{
          type: 'pie',
          label: {
            show: false
          },
          data: status_data
        }]
      });
    }
  }
};