"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _default2 = exports.default = {
  name: 'RoadmapReleaseComponent',
  props: {
    x: {
      type: Number,
      required: true,
      default: 0
    },
    height: {
      type: Number,
      required: true,
      default: 0
    },
    width: {
      type: Number,
      required: true,
      default: 0
    },
    release: {
      type: Object,
      default: function _default() {
        return {
          name: '',
          description: '',
          highlight: false,
          color: '#000080'
        };
      }
    }
  },
  data: function data() {
    return {
      isHover: false
    };
  },
  methods: {
    computeTrimmedText: function computeTrimmedText(text) {
      if (text.length > 30) {
        return text.substring(0, 30) + ' ...';
      } else {
        return text;
      }
    },
    computeFill: function computeFill() {
      if (this.release.highlight) {
        return 'orange';
      } else {
        return 'gray';
      }
    },
    generateBackArrowPath: function generateBackArrowPath() {
      var start_point_x = this.x + 20;
      var start_point_y = 7;
      var mid_point_x = this.x + 10;
      var mid_point_y = 15;
      var end_point_x = this.x + 20;
      var end_point_y = 22;
      return 'M ' + start_point_x + ' ' + start_point_y + ' L ' + mid_point_x + ' ' + mid_point_y + ' L ' + end_point_x + ' ' + end_point_y + '';
    },
    generateFrontArrowPath: function generateFrontArrowPath() {
      var start_point_x = this.x + this.width - 20;
      var start_point_y = 7;
      var mid_point_x = this.x + this.width - 10;
      var mid_point_y = 15;
      var end_point_x = this.x + this.width - 20;
      var end_point_y = 22;
      return 'M ' + start_point_x + ' ' + start_point_y + ' L ' + mid_point_x + ' ' + mid_point_y + ' L ' + end_point_x + ' ' + end_point_y + '';
    },
    moveBack: function moveBack() {
      this.$emit('move-back');
    },
    moveFront: function moveFront() {
      this.$emit('move-front');
    },
    emitClick: function emitClick() {
      this.$emit('click');
    },
    onMouseOver: function onMouseOver() {
      this.isHover = true;
      this.$emit('mouse-over');
    },
    onMouseLeave: function onMouseLeave() {
      this.isHover = false;
      this.$emit('mouse-leave');
    },
    removeRelease: function removeRelease() {
      this.$emit('delete');
    }
  }
};