"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _default2 = exports.default = {
  name: 'RoadmapSwimLaneComponent',
  props: {
    x: {
      type: Number,
      required: true,
      default: 0
    },
    y: {
      type: Number,
      required: true,
      default: 0
    },
    height: {
      type: Number,
      required: true,
      default: 150
    },
    index: {
      type: Number,
      required: true,
      default: 0
    },
    track: {
      type: Object,
      default: function _default() {
        return {
          name: '',
          description: '',
          color: '#000080'
        };
      }
    }
  },
  data: function data() {
    return {
      isHover: false
    };
  },
  methods: {
    computeFill: function computeFill() {
      if (this.track.highlight) {
        return 'orange';
      } else {
        return 'yellow';
      }
    },
    generateTransform: function generateTransform(x, y) {
      return 'rotate(-90 ' + x + ',' + y + ')';
    },
    emitClick: function emitClick() {
      this.$emit('click');
    },
    onMouseOver: function onMouseOver() {
      this.isHover = true;
      this.$emit('mouse-over');
    },
    onMouseLeave: function onMouseLeave() {
      this.isHover = false;
      this.$emit('mouse-leave');
    },
    removeTrack: function removeTrack() {
      this.$emit('delete');
    }
  }
};