"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _echarts = _interopRequireDefault(require("echarts"));
var _utils = require("@/utils");
require('echarts/theme/macarons'); // echarts theme
var _default = exports.default = {
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '600px'
    },
    height: {
      type: String,
      default: '300px'
    },
    avgCustomerValue: {
      type: Number,
      default: 0
    },
    newCustomersPerMonth: {
      type: Number,
      default: 0
    }
  },
  data: function data() {
    return {
      chart: null
    };
  },
  watch: {
    'avgCustomerValue': function avgCustomerValue(newVal, oldVal) {
      // watch it
      this.initChart();
    },
    'newCustomersPerMonth': function newCustomersPerMonth(newVal, oldVal) {
      // watch it
      this.initChart();
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.initChart();
    this.__resizeHandler = (0, _utils.debounce)(function () {
      if (_this.chart) {
        _this.chart.resize();
      }
    }, 100);
    window.addEventListener('resize', this.__resizeHandler);
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    window.removeEventListener('resize', this.__resizeHandler);
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart: function initChart() {
      this.chart = _echarts.default.init(this.$el, 'macarons');
      this.chart.setOption({
        color: ['#3398DB'],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            lineStyle: {
              color: '#57617B'
            }
          }
        },
        grid: {
          top: '2%',
          left: '2%',
          right: '2%',
          bottom: '2%',
          containLabel: true
        },
        xAxis: [{
          type: 'category',
          data: ['2020', '2021', '2022', '2023'],
          axisTick: {
            alignWithLabel: true
          }
        }],
        yAxis: [{
          type: 'value'
        }],
        series: [{
          name: 'Forecast annual growth',
          type: 'line',
          smooth: true,
          symbol: 'circle',
          symbolSize: 5,
          showSymbol: false,
          lineStyle: {
            normal: {
              width: 1
            }
          },
          areaStyle: {
            normal: {
              color: new _echarts.default.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(24, 144, 255, 0.3)'
              }, {
                offset: 0.8,
                color: 'rgba(24, 144, 255, 0)'
              }], false),
              shadowColor: 'rgba(0, 0, 0, 0.1)',
              shadowBlur: 10
            }
          },
          itemStyle: {
            normal: {
              color: 'rgb(24,144,255)',
              borderColor: 'rgba(24,144,2,0.255)',
              borderWidth: 12
            }
          },
          data: [this.avgCustomerValue * (this.newCustomersPerMonth * 12), this.avgCustomerValue * (this.newCustomersPerMonth * 24), this.avgCustomerValue * (this.newCustomersPerMonth * 36), this.avgCustomerValue * (this.newCustomersPerMonth * 48)]
        }]
      });
    }
  }
};