"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineUserJourney = _interopRequireDefault(require("./components/define_user_value/define-user-journey"));
var _viewMockupStoryboard = _interopRequireDefault(require("./components/define_user_value/view-mockup-storyboard"));
var _ProcessSteps = _interopRequireDefault(require("@/components/ProcessSteps"));
var _default = exports.default = {
  name: 'DefineUserValue',
  components: {
    DefineUserJourneyComponent: _defineUserJourney.default,
    ViewMockupStoryBoardComponent: _viewMockupStoryboard.default,
    ProcessSteps: _ProcessSteps.default
  },
  data: function data() {
    return {
      active_step: 0,
      user_types: [{
        name: 'Individual',
        user_priority: 5
      }, {
        name: 'Group',
        user_priority: 5
      }],
      steps: [
      /*
      {
        name: 'Describe User Types',
        shortDescription: 'How do user types differ?',
        longDescription: 'Describing user types help drive customer value creation. Define the user problem and solution, set their priority, and view their pains and gains.'
      },*/
      {
        name: 'Define User Journey',
        shortDescription: 'Journey Map',
        longDescription: 'The users journey consists of steps grouped into phases. Steps have pain points or pleasers, which resolved through your products candidate features.'
      }, {
        name: 'View Mockup & Storyboard',
        shortDescription: 'Scenarios',
        longDescription: 'Optional. Create scenarios for this user type, describing it with steps, mockups, and a storyboard.'
      }
      /*,
      {
        name: 'Customer Link',
        shortDescription: '',
        longDescription: ''
      }*/]
    };
  },
  mounted: function mounted() {},
  created: function created() {},
  methods: {
    updateStep: function updateStep(active_step) {
      this.active_step = active_step;
    }
  }
};