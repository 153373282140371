"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("g", {
    on: {
      click: _vm.emitClick
    }
  }, [_c("rect", {
    attrs: {
      x: _vm.x,
      y: "0",
      width: _vm.width,
      height: "30",
      fill: _vm.phase.color
    }
  }), _vm._v(" "), _c("path", {
    staticStyle: {
      fill: "#EEEEEE"
    },
    attrs: {
      d: _vm.generateBackArrowPath()
    },
    on: {
      click: _vm.moveBack
    }
  }), _vm._v(" "), _c("path", {
    staticStyle: {
      fill: "#EEEEEE"
    },
    attrs: {
      d: _vm.generateFrontArrowPath()
    },
    on: {
      click: _vm.moveFront
    }
  }), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "#EEEEEE"
    },
    attrs: {
      x: _vm.x + _vm.width / 2,
      y: "15",
      "text-anchor": "middle",
      "font-size": "1.0em",
      "font-weight": "bold"
    }
  }, [_vm._v(_vm._s(_vm.phase.name))]), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "#EEEEEE"
    },
    attrs: {
      x: _vm.x + _vm.width / 2,
      y: "25",
      "text-anchor": "middle",
      "font-size": "0.7em",
      "font-weight": "lighter"
    }
  }, [_vm._v(_vm._s(_vm.computeTrimmedText(_vm.phase.description)))]), _vm._v(" "), _c("rect", {
    attrs: {
      x: _vm.x,
      y: "30",
      width: _vm.width,
      height: _vm.height,
      fill: _vm.computeFill(),
      "fill-opacity": 0.1
    }
  }), _vm._v(" "), _c("line", {
    staticStyle: {
      stroke: "gray",
      "stroke-width": "2",
      "stroke-opacity": "0.2"
    },
    attrs: {
      x1: _vm.x,
      y1: "30",
      x2: _vm.x + _vm.width,
      y2: "30"
    }
  })]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;