"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default2 = exports.default = {
  name: 'ExperienceCreate',
  components: {},
  props: {
    edit: {
      type: Boolean,
      default: false
    },
    experience: {
      type: Object,
      default: function _default() {
        return {
          experiencetype: '',
          title: '',
          description: ''
        };
      }
    }
  },
  data: function data() {
    return {
      experience_form: {
        title: '',
        description: '',
        type: '',
        priority: 'Low',
        customervalue: 'Medium'
      },
      addExperiencerules: {
        title: [{
          required: true,
          message: 'Please input Step title',
          trigger: 'blur'
        }, {
          min: 5,
          max: 80,
          message: 'Length should be 5 to 80',
          trigger: 'blur'
        }],
        description: [{
          required: true,
          message: 'Please input Step description',
          trigger: 'blur'
        }],
        priority: [{
          required: true,
          message: 'Please input Step Priority',
          trigger: 'blur'
        }],
        customervalue: [{
          required: true,
          message: 'Please select importance',
          trigger: 'change'
        }]
      }
    };
  },
  computed: {
    computeLabelHeader: function computeLabelHeader() {
      if (this.experience_form.experiencetype === 'Challenge') {
        return 'Obscurity';
      }
      return this.experience_form.experiencetype;
    }
  },
  watch: {
    experience: {
      deep: true,
      handler: function handler() {
        this.experience_form = this.experience;
        this.experience_form.type = this.experience.experiencetype;
        // this.experience_form.customervalue = this.experience.additionalattributes.customer_value
      }
    }
  },
  created: function created() {},
  mounted: function mounted() {
    this.resetForm();
    this.experience_form = this.experience;
    this.experience_form.type = this.experience.experiencetype;
    // this.experience_form.customervalue = this.experience.additionalattributes.customer_value
  },
  methods: {
    createExperience: function createExperience() {
      var _this = this;
      this.$refs.addExperienceform.validate(function (valid) {
        if (valid) {
          var experience = Object.assign({}, _this.experience_form);
          _this.$emit('add-experience', experience);
          _this.resetForm();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    updateExperience: function updateExperience() {
      var experience = Object.assign({}, this.experience_form);
      this.$emit('update-experience', experience);
    },
    deleteExperience: function deleteExperience() {
      var _this2 = this;
      this.$confirm('This will permanently delete experience. Continue?', 'Warning', {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(function () {
        _this2.$emit('delete-experience', _this2.experience.id);
      }).catch(function () {
        _this2.$message({
          type: 'info',
          message: 'Delete canceled'
        });
      });
    },
    closeUI: function closeUI() {
      this.resetForm();
      this.$emit('close');
    },
    resetForm: function resetForm() {
      this.experience_form = {
        title: '',
        description: '',
        type: '',
        priority: 'Low',
        customervalue: 'Medium'
      };
    }
  }
};