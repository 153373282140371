"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default2 = exports.default = {
  name: 'Setting',
  components: {},
  props: {
    roadmap: {
      type: Object,
      default: function _default() {
        return {
          title: '',
          description: '',
          style: {
            color: ''
          }
        };
      }
    }
  },
  data: function data() {
    return {
      loading: false,
      activeTabName: 'roadmap',
      setting: {
        version: 1,
        roadmap: {},
        release: {
          timeline: 6,
          color: {
            title: 'rgba(189, 195, 200, 1.0)',
            column: 'rgba(189, 195, 200, 1.0)'
          }
        },
        increment: {
          prefix: 'increment',
          timeline: 1,
          delete: 'disabled',
          color: {
            title: 'rgba(189, 195, 200, 1.0)',
            column: 'rgba(189, 195, 200, 1.0)'
          }
        }
      },
      roadmapSettings: [
      /*
      //Exclusion 1: Removed from MVP as a result of moving workflow
      {
        title: 'Status',
        description: 'Update roadmap status',
        type: 'status',
        note: {
          type: 'info"',
          title: '',
          description: 'Update roadmap status'
        }
      },*/
      {
        title: 'Delete Roadmap',
        description: 'Delete the current Roadmap',
        type: 'delete',
        note: {
          type: 'info"',
          title: '',
          description: 'The current Roadmap will be saved as a prior version, and new blank version created.'
        }
      }],
      releaseSettings: [{
        title: 'Release length',
        description: 'Set release length in months.',
        type: 'default',
        note: {
          type: 'info"',
          title: '',
          description: 'Default is 6.'
        }
      }, {
        title: 'Color',
        description: 'Release color',
        type: 'color',
        note: {
          type: 'info"',
          title: '',
          description: 'Default color for title and column.'
        }
      }],
      incrementSettings: [{
        title: 'Title prefix',
        description: 'Increment nickname',
        type: 'prefix',
        note: {
          type: 'info"',
          title: '',
          description: 'Default is increment.  Alternatives are Iterations, PIs, etc'
        }
      }, {
        title: 'Increment Size',
        description: 'Define timeline size',
        type: 'default',
        note: {
          type: 'info"',
          title: '',
          description: 'Default is 1 month'
        }
      }, {
        title: 'Delete Increment Behaviour',
        description: 'Define how timeline should be handled on deletion of increment',
        type: 'delete',
        note: {
          type: 'info"',
          title: '',
          description: '<strong>Shrink </strong>resizes releases to increments. <strong>Resize </strong> resizes adjacent increments. <strong>Disabled</strong> turns off deleting increments.'
        }
      }, {
        title: 'Color',
        description: 'Increment color',
        type: 'color',
        note: {
          type: 'info"',
          title: '',
          description: 'Define default color for title and column.'
        }
      }]
    };
  },
  computed: {},
  created: function created() {},
  mounted: function mounted() {
    this.setting = this.roadmap.additionalattributes.setting;
  },
  methods: {
    updateRoadmapStatus: function updateRoadmapStatus(status) {
      var roadmap = Object.assign({}, this.roadmap);
      roadmap.status = status;
      this.$emit('update-roadmap', roadmap);
    },
    update: function update() {
      var roadmap = Object.assign({}, this.roadmap);
      roadmap.additionalattributes.setting = this.setting;
      this.$emit('update-roadmap', roadmap);
    },
    renameRoadmapTitle: function renameRoadmapTitle() {
      var roadmap = Object.assign({}, this.roadmap);
      this.$emit('rename-roadmap', roadmap);
    },
    deleteRoadmapConfirmation: function deleteRoadmapConfirmation() {
      this.$emit('delete-roadmap');
      this.closeDialog();
    },
    deleteReleaseConfirmation: function deleteReleaseConfirmation() {},
    deleteIncrementConfirmation: function deleteIncrementConfirmation() {},
    closeDialog: function closeDialog() {
      this.$emit('close');
    }
  }
};