"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("g", {
    attrs: {
      refs: "journey-horizontal"
    }
  }, [_c("g", {
    attrs: {
      refs: "canvas"
    }
  }, [_c("g", {
    attrs: {
      refs: "menu-background"
    }
  }, [_c("g", {
    attrs: {
      refs: "menu-background-border"
    }
  }, [_c("rect", {
    staticClass: "shadow",
    attrs: {
      x: 3,
      y: 5,
      width: 10,
      height: 25,
      fill: "#447799",
      "stroke-width": "1",
      stroke: "#eee"
    }
  }), _vm._v(" "), _c("rect", {
    staticClass: "shadow",
    attrs: {
      x: "5",
      y: "5",
      width: _vm.computeCanvasWidth,
      height: 25,
      fill: "white",
      "stroke-width": "1",
      stroke: "#eee"
    }
  })]), _vm._v(" "), _vm.isCompactMenubar ? _c("g", {
    attrs: {
      refs: "menu"
    }
  }, [_c("rect", {
    attrs: {
      id: "customer-journey-workspace_0",
      x: "5",
      y: 5,
      width: 28,
      height: 25,
      fill: "rebeccapurple",
      "stroke-width": "0",
      stroke: "#aaa"
    },
    on: {
      click: function click($event) {
        _vm.isCompactMenubar = !_vm.isCompactMenubar;
      }
    }
  }), _vm._v(" "), _c("foreignObject", {
    staticStyle: {
      color: "white"
    },
    attrs: {
      x: "10",
      y: "5",
      width: 25,
      height: "25"
    },
    on: {
      click: function click($event) {
        _vm.isCompactMenubar = !_vm.isCompactMenubar;
      }
    }
  }, [_c("i", {
    staticClass: "el-icon-d-arrow-right",
    staticStyle: {
      "padding-top": "3px",
      "font-size": "1.5em"
    }
  })])]) : _c("g", [_c("rect", {
    attrs: {
      x: "5",
      y: "5",
      rx: "3",
      width: "140",
      height: "25",
      fill: "rebeccapurple",
      "stroke-width": "1",
      stroke: "#f1f4ff"
    }
  }), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "white",
      "font-weight": "600",
      "font-variant-caps": "small-caps"
    },
    attrs: {
      x: "12",
      y: "21",
      "text-anchor": "start",
      "font-size": "0.9em"
    }
  }, [_vm._v("\n          Customer Journey\n        ")]), _vm._v(" "), _c("foreignObject", {
    staticStyle: {
      color: "white"
    },
    attrs: {
      x: "120",
      y: "5",
      width: "25",
      height: "25"
    },
    on: {
      click: function click($event) {
        _vm.isCompactMenubar = !_vm.isCompactMenubar;
      }
    }
  }, [_c("i", {
    staticClass: "el-icon-d-arrow-left",
    staticStyle: {
      "padding-top": "3px",
      "font-size": "1.5em"
    }
  })])]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "ideas-notification"
    }
  }, [_vm.computedIdeasForJourney.length > 0 ? _c("g", {
    attrs: {
      refs: "customer-journey-idea-count"
    }
  }, [_c("foreignObject", {
    staticStyle: {
      "background-color": "white",
      "border-radius": "5px",
      color: "red",
      "text-align": "left",
      "font-size": "1.0em",
      "font-weight": "bold"
    },
    attrs: {
      x: 550,
      y: "8",
      width: "200",
      height: "22"
    }
  }, [_c("div", {
    staticStyle: {
      color: "#E6A23C",
      "margin-left": "-3px",
      "margin-top": "1pt"
    }
  }, [_c("div", {
    staticStyle: {
      display: "inline-block",
      "border-radius": "15pt",
      "background-color": "white",
      "box-shadow": "0 0 0.5px 0.5px lightgrey",
      border: "solid 1pt lightgrey",
      "margin-left": "5pt",
      padding: "0 4pt 0 4pt"
    }
  }, [_c("svg-icon", {
    staticStyle: {
      "padding-left": "1pt",
      "font-size": "14px",
      color: "#E6A23C"
    },
    attrs: {
      "icon-class": "ideas"
    }
  }), _vm._v(" "), _c("span", {
    staticStyle: {
      "font-size": "0.9em"
    }
  }, [_vm._v(_vm._s(_vm.computedIdeasForJourney.length))])], 1), _vm._v(" "), _c("span", {
    staticStyle: {
      "margin-left": "1px",
      "font-weight": "100",
      "font-size": "0.9em"
    }
  }, [_vm._v(" whole of journey ideas exists")])])])]) : _vm._e()])]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "stage-lane-background"
    }
  }, [_c("g", {
    attrs: {
      refs: "stage-lane-background-border"
    }
  }, [_c("rect", {
    staticClass: "shadow",
    attrs: {
      x: 3,
      y: 35,
      width: 10,
      height: 60,
      fill: "#447799",
      "stroke-width": "1",
      stroke: "#eee"
    }
  }), _vm._v(" "), _c("rect", {
    staticClass: "shadow",
    attrs: {
      x: "5",
      y: "35",
      width: _vm.computeCanvasWidth,
      height: 60,
      fill: "white",
      "stroke-width": "1",
      stroke: "#eee"
    }
  })]), _vm._v(" "), _c("rect", {
    attrs: {
      id: "customer-journey-workspace_0",
      x: "5",
      y: 35,
      width: _vm.computeCanvasWidth - 10,
      height: 60,
      fill: "rgba(230,230,230,0.1)",
      "stroke-width": "1",
      stroke: "#f1f4ff"
    }
  }), _vm._v(" "), _c("g", {
    attrs: {
      refs: "stage-lane-header"
    }
  }, [_c("rect", {
    attrs: {
      id: "customer-journey-workspace_0",
      x: "5",
      y: 35,
      width: _vm.isCompactMenubar ? 28 : 140,
      height: 60,
      fill: "rgba(200,200,200,0.15)",
      "stroke-width": "0",
      stroke: "#aaa"
    }
  }), _vm._v(" "), _c("rect", {
    attrs: {
      x: "5",
      y: 35,
      rx: "2",
      width: _vm.isCompactMenubar ? 28 : 140,
      height: _vm.isCompactMenubar ? 60 : 22,
      fill: "rebeccapurple",
      "stroke-width": "0",
      stroke: "#aaa"
    }
  }), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "white",
      "font-weight": "200",
      "font-variant-caps": "small-caps"
    },
    attrs: {
      x: "20",
      y: _vm.isCompactMenubar ? 85 : 50,
      "text-anchor": "start",
      "font-size": ".95em",
      transform: _vm.isCompactMenubar ? "rotate(270, 20, 85)" : ""
    }
  }, [_vm._v("\n          Stages\n        ")])])]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "step-lane-background"
    }
  }, [_c("g", {
    attrs: {
      refs: "step-lane-background-border"
    }
  }, [_c("rect", {
    staticClass: "shadow",
    attrs: {
      x: _vm.layout.step.x - 2,
      y: _vm.layout.step.y,
      width: 10,
      height: _vm.layout.step.height,
      fill: "#447799",
      "stroke-width": "1",
      stroke: "#eee"
    }
  }), _vm._v(" "), _c("rect", {
    staticClass: "shadow",
    attrs: {
      x: _vm.layout.step.x,
      y: _vm.layout.step.y,
      width: _vm.computeCanvasWidth,
      height: _vm.layout.step.height,
      fill: "white",
      "stroke-width": "1",
      stroke: "#eee"
    }
  })]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "step-lane-background-border"
    }
  }, [_c("rect", {
    directives: [{
      name: "dragged",
      rawName: "v-dragged.prevent",
      value: _vm.onStepContainerResizeDragged,
      expression: "onStepContainerResizeDragged",
      modifiers: {
        prevent: true
      }
    }],
    staticClass: "shadow",
    staticStyle: {
      cursor: "row-resize"
    },
    attrs: {
      x: _vm.layout.step.x,
      y: _vm.layout.step.y + _vm.layout.step.height,
      width: _vm.computeCanvasWidth,
      height: 5,
      fill: "white",
      "stroke-width": "1",
      stroke: "#eee"
    }
  })]), _vm._v(" "), _vm.stepLayout === "horizontal" ? _c("rect", {
    directives: [{
      name: "dragged",
      rawName: "v-dragged.prevent",
      value: _vm.onStepContainerDragged,
      expression: "onStepContainerDragged",
      modifiers: {
        prevent: true
      }
    }],
    staticStyle: {
      cursor: "grab"
    },
    attrs: {
      id: "customer-journey-workspace_0",
      x: "5",
      y: _vm.layout.step.y,
      width: _vm.computeCanvasWidth - 10,
      height: _vm.layout.step.height,
      fill: "rgba(254,254,255,0.9)",
      "stroke-width": "1.0",
      stroke: "#f1f4ff"
    }
  }) : _c("g", {
    attrs: {
      refs: "bg_matrix"
    }
  }, [_vm._l(_vm.computedGridRows, function (y_datum, y_index) {
    return _c("g", {
      key: "y_" + y_index,
      attrs: {
        refs: "y-axis"
      }
    }, _vm._l(100, function (x_datum, x_index) {
      return _c("g", {
        key: "x_" + x_index,
        attrs: {
          refs: "x-axis"
        }
      }, [_c("path", {
        attrs: {
          d: _vm.generateDiamondPath((_vm.isCompactMenubar ? 34 : 146) + (x_datum - 1) * 25, 100 + (y_datum - 1) * 25),
          "stroke-opacity": "0.5",
          stroke: "#ccc",
          "stroke-width": "1",
          fill: "none"
        }
      })]);
    }), 0);
  }), _vm._v(" "), _vm._l(_vm.computedGridRows, function (y_datum, y_index) {
    return _c("g", {
      key: "y_line_" + y_index,
      attrs: {
        refs: "y-axis"
      }
    }, [_c("line", {
      attrs: {
        x1: _vm.isCompactMenubar ? 33 : 145,
        y1: _vm.layout.step.y + (y_datum - 1) * 25,
        x2: _vm.computeCanvasWidth,
        y2: _vm.layout.step.y + (y_datum - 1) * 25,
        stroke: "rgba(224,224,224,0.3)",
        "stroke-width": "1",
        fill: "none"
      }
    })]);
  }), _vm._v(" "), _vm._l(100, function (x_datum, x_index) {
    return _c("g", {
      key: "x_line_" + x_index,
      attrs: {
        refs: "x-axis"
      }
    }, [_c("line", {
      attrs: {
        x1: (_vm.isCompactMenubar ? 35 : 147) + (x_datum - 1) * 25,
        y1: _vm.layout.step.y,
        x2: (_vm.isCompactMenubar ? 35 : 147) + (x_datum - 1) * 25,
        y2: _vm.layout.step.y + _vm.layout.step.height,
        stroke: "rgba(224,224,224,0.3)",
        "stroke-width": "1",
        fill: "none"
      }
    })]);
  })], 2), _vm._v(" "), _c("g", {
    attrs: {
      refs: "step-lane-header"
    }
  }, [_c("rect", {
    attrs: {
      id: "customer-journey-workspace_0",
      x: _vm.layout.step.x,
      y: _vm.layout.step.y,
      width: _vm.isCompactMenubar ? 28 : 140,
      height: _vm.layout.step.height,
      fill: "rgba(200,200,200,0.15)",
      "stroke-width": "0",
      stroke: "#aaa"
    }
  }), _vm._v(" "), _c("rect", {
    attrs: {
      x: _vm.layout.step.x,
      y: _vm.layout.step.y,
      rx: "2",
      width: _vm.isCompactMenubar ? 28 : 140,
      height: _vm.isCompactMenubar ? _vm.layout.step.height : 22,
      fill: "rebeccapurple",
      "stroke-width": "0",
      stroke: "#aaa"
    }
  }), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "white",
      "font-weight": "200",
      "font-variant-caps": "small-caps"
    },
    attrs: {
      x: "20",
      y: _vm.isCompactMenubar ? 230 : 115,
      "text-anchor": "start",
      "font-size": ".95em",
      transform: _vm.isCompactMenubar ? "rotate(270, 20, 230)" : ""
    }
  }, [_vm._v("\n          Steps\n        ")])])]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "experience-lane-background"
    }
  }, [_c("g", {
    attrs: {
      refs: "experience-lane-background-border"
    }
  }, [_c("rect", {
    staticClass: "shadow",
    attrs: {
      x: 3,
      y: _vm.layout.step.height + 105,
      width: 10,
      height: _vm.computeExperienceLaneHeight,
      fill: "#447799",
      "stroke-width": "1",
      stroke: "#eee"
    }
  }), _vm._v(" "), _c("rect", {
    staticClass: "shadow",
    attrs: {
      x: "5",
      y: _vm.layout.step.height + 105,
      width: _vm.computeCanvasWidth,
      height: _vm.computeExperienceLaneHeight,
      fill: "white",
      "stroke-width": "1",
      stroke: "#eee"
    }
  })]), _vm._v(" "), _c("rect", {
    attrs: {
      id: "customer-journey-workspace_0",
      x: "5",
      y: _vm.layout.step.height + 105,
      width: _vm.computeCanvasWidth - 5,
      height: _vm.computeExperienceLaneHeight,
      fill: "rgba(254,254,255,0.9)",
      "stroke-width": "1.0",
      stroke: "#f1f4ff"
    }
  }), _vm._v(" "), _c("g", {
    attrs: {
      refs: "experience-lane-header"
    }
  }, [_c("rect", {
    attrs: {
      id: "customer-journey-workspace_0",
      x: "5",
      y: _vm.layout.step.height + 105,
      width: _vm.isCompactMenubar ? 28 : 140,
      height: _vm.computeExperienceLaneHeight,
      fill: "rgba(200,200,200,0.15)",
      "stroke-width": "0",
      stroke: "#aaa"
    }
  }), _vm._v(" "), _c("rect", {
    attrs: {
      x: "5",
      y: _vm.layout.step.height + 105,
      rx: "2",
      width: _vm.isCompactMenubar ? 28 : 140,
      height: _vm.isCompactMenubar ? _vm.computeExperienceLaneHeight : 22,
      fill: "rebeccapurple",
      "stroke-width": "0",
      stroke: "#aaa"
    }
  }), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "white",
      "font-weight": "200",
      "font-variant-caps": "small-caps"
    },
    attrs: {
      x: "20",
      y: _vm.isCompactMenubar ? _vm.layout.step.height + 255 : _vm.layout.step.height + 120,
      "text-anchor": "start",
      "font-size": ".95em",
      transform: _vm.computeExperienceTitleTransform
    }
  }, [_vm._v("\n          Experience\n        ")])])])]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "stages"
    }
  }, _vm._l(_vm.sortedStages, function (phase, index) {
    return _c("phase-component", {
      directives: [{
        name: "dragged",
        rawName: "v-dragged.prevent",
        value: _vm.onPhaseDragged,
        expression: "onPhaseDragged",
        modifiers: {
          prevent: true
        }
      }],
      key: index,
      attrs: {
        id: "phase_" + index,
        index: index,
        x: _vm.computePhasePosX(index),
        y: 40,
        length: _vm.computePhaseLength(phase.journeyPhase, index),
        label: phase.journeyPhase.title,
        "show-label": true,
        hover: _vm.phaseHover,
        priority: phase.journeyPhase.priority,
        value: phase.journeyPhase.importance,
        phase: phase,
        ideas: _vm.computedIdeasForPhase(phase.journeyPhase),
        "is-highlight": _vm.phaseHoverID === phase.journeyPhase.id
      },
      on: {
        click: _vm.editPhaseDetails,
        focusmode: function focusmode($event) {
          return _vm.toggleFocusMode(phase, index);
        },
        mouseover: function mouseover($event) {
          return _vm.onPhaseMouseOver(phase, index);
        },
        mouseleave: function mouseleave($event) {
          return _vm.onPhaseMouseLeave();
        },
        longpressstart: function longpressstart($event) {
          return _vm.onPhaseLongPressStart(phase, index);
        },
        longpressstop: function longpressstop($event) {
          return _vm.onPhaseLongPressStop(phase, index);
        },
        updatelayout: function updatelayout($event) {
          return _vm.computePhaseX();
        }
      }
    });
  }), 1), _vm._v(" "), _vm.stepLayout === "horizontal" ? _c("g", {
    attrs: {
      refs: "steps"
    }
  }, _vm._l(_vm.computedTaskList, function (task, index) {
    return _c("task-component", {
      key: index,
      attrs: {
        id: "task_" + index,
        "step-id": task.journeyStep.id,
        x: _vm.computeTaskPosX(index),
        y: _vm.computeTaskPosY(index),
        index: index,
        "phase-index": _vm.computePhaseIndexforTask(task, index),
        "show-label": true,
        hover: _vm.taskHover,
        label: task.journeyStep.title,
        priority: task.journeyStep.priority,
        value: task.journeyStep.importance,
        ideas: _vm.computedIdeasForStep(task.journeyStep),
        "is-highlight": _vm.taskHoverID === task.journeyStep.id
      },
      on: {
        click: _vm.selectTask,
        mouseover: function mouseover($event) {
          return _vm.onTaskMouseOver(task, index);
        },
        mouseleave: function mouseleave($event) {
          return _vm.onTaskMouseLeave(task, index);
        },
        "drag-task": _vm.onTaskHorizontalDragged
      }
    });
  }), 1) : _vm._e(), _vm._v(" "), _vm.stepLayout === "matrix" ? _c("g", {
    attrs: {
      refs: "steps"
    }
  }, _vm._l(_vm.computedTaskList, function (task, index) {
    return _c("task-grid-component", {
      key: index,
      attrs: {
        id: "task_" + index,
        "step-id": task.journeyStep.id,
        x: _vm.computeTaskPosX(index),
        y: _vm.computeTaskPosY(index),
        index: index,
        "phase-index": _vm.computePhaseIndexforTask(task, index),
        "show-label": true,
        hover: _vm.taskHover,
        label: task.journeyStep.title,
        priority: task.journeyStep.priority,
        value: task.journeyStep.importance,
        ideas: _vm.computedIdeasForStep(task.journeyStep),
        "is-highlight": _vm.taskHoverID === task.journeyStep.id,
        "is-relation-hover": _vm.relationsHoverTaskID === task.journeyStep.id
      },
      on: {
        click: _vm.selectTask,
        mouseover: function mouseover($event) {
          return _vm.onTaskMouseOver(task, index);
        },
        mouseleave: function mouseleave($event) {
          return _vm.onTaskMouseLeave(task, index);
        },
        "drag-workflow": _vm.onTaskWorkFlowDrag,
        "drag-task": _vm.onTaskMatrixDragged
      }
    });
  }), 1) : _vm._e(), _vm._v(" "), _c("g", {
    attrs: {
      refs: "experiences"
    }
  }, _vm._l(_vm.computedExperienceList, function (experience, index) {
    return _c("g", {
      key: index
    }, [_vm.computeExperiencePosX(index) !== undefined && _vm.computeExperiencePosX(index) > 0 ? _c("experience-component", {
      attrs: {
        type: experience.journeyExperience.experiencetype,
        x: _vm.computeExperiencePosX(index),
        y: _vm.computeExperiencePosY(index),
        "is-stage-relation": _vm.isStageRelation(index),
        index: index,
        "show-label": true,
        label: experience.journeyExperience.title,
        description: experience.journeyExperience.description,
        priority: experience.journeyExperience.priority,
        value: experience.journeyExperience.additionalattributes.customer_value,
        "is-parent-active": !_vm.isParentTaskActive(experience.journeyExperience.id)
      },
      on: {
        mouseover: _vm.onExperienceMouseOver,
        mouseleave: _vm.onExperienceMouseLeave,
        click: function click($event) {
          return _vm.editExperienceDetails(index, experience);
        }
      }
    }) : _vm._e()], 1);
  }), 0), _vm._v(" "), _vm.isTouchPointLaneVisible ? _c("g", {
    attrs: {
      refs: "touchpoints"
    }
  }, [_c("touchpoints-component", {
    attrs: {
      x: 5,
      y: _vm.computeTouchPointY,
      width: _vm.computeCanvasWidth - 5,
      height: _vm.computeTouchpointsHeight,
      stages: _vm.sortedStages,
      "phase-x": _vm.phase_pos_x,
      "is-compact-menubar": _vm.isCompactMenubar,
      "journey-map": _vm.journeyMap
    },
    on: {
      mouseover: _vm.onTouchpointMouseOver,
      mouseleave: _vm.onTouchpointMouseLeave,
      click: _vm.selectTouchpoint,
      "drag-tool": _vm.onTouchPointToolDragged
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.isThoughtLaneVisible ? _c("g", {
    attrs: {
      refs: "notes"
    }
  }, [_c("thoughts-component", {
    attrs: {
      x: 5,
      y: _vm.computeThoughtY,
      stages: _vm.sortedStages,
      width: _vm.computeCanvasWidth - 5,
      height: 150,
      "phase-x": _vm.phase_pos_x,
      "phase-length": _vm.phase_length,
      "is-compact-menubar": _vm.isCompactMenubar,
      "journey-map": _vm.journeyMap
    }
  })], 1) : _vm._e(), _vm._v(" "), _c("g", {
    attrs: {
      refs: "custom_lanes"
    }
  }), _vm._v(" "), _c("g", {
    attrs: {
      refs: "relations"
    }
  }, [_c("relations-component", {
    attrs: {
      relations: _vm.computedRelations,
      "journey-map": _vm.journeyMap,
      "canvas-drag-coefficient-x": _vm.canvasDragCoefficientX,
      "phase-x": _vm.phase_pos_x,
      "phase-y": 90,
      "task-x": _vm.task_pos_x,
      "task-y": _vm.task_pos_y,
      "experience-x": _vm.experience_pos_x,
      "experience-y": _vm.experience_pos_y,
      "selected-entity-id": _vm.selectedEntityId,
      update: _vm.relationsUpdate,
      "step-layout": _vm.stepLayout
    },
    on: {
      "select-relation": _vm.selectRelation
    }
  }), _vm._v(" "), _vm.stepLayout === "matrix" ? _c("relations-component", {
    attrs: {
      name: "StepRelations",
      relations: _vm.computedStepRelations,
      "journey-map": _vm.journeyMap,
      "canvas-drag-coefficient-x": _vm.canvasDragCoefficientX,
      "task-x": _vm.task_pos_x,
      "task-y": _vm.task_pos_y,
      "selected-entity-id": _vm.selectedEntityId,
      "relation-type": "Step",
      "step-layout": _vm.stepLayout,
      update: _vm.relationsUpdate
    },
    on: {
      "select-relation": _vm.selectRelation
    }
  }) : _vm._e(), _vm._v(" "), _c("g", {
    attrs: {
      refs: "temp-task-relation"
    }
  }, [_vm.tempTaskRelationPath.x1 > 0 ? _c("path", {
    attrs: {
      d: _vm.computeTempTaskRelation(),
      stroke: "#ccc",
      "stroke-width": "1",
      "stroke-dasharray": "3,3",
      fill: "none",
      "marker-start": "url(#dot)",
      "marker-end": "url(#triangle)",
      "stroke-opacity": "0.9"
    }
  }) : _vm._e()])], 1), _vm._v(" "), _c("g", {
    attrs: {
      refs: "highlight"
    }
  }, [_vm.phaseHighlighter.length > 0 ? _c("g", {
    attrs: {
      refs: "task_drag_highlighter"
    }
  }, _vm._l(_vm.phaseHighlighter, function (gap, index) {
    return _c("rect", {
      key: index,
      staticClass: "gaphighlighter-anim",
      attrs: {
        x: gap.x,
        y: gap.y,
        rx: 5,
        ry: 5,
        width: gap.width,
        height: gap.height,
        fill: "#eee",
        "fill-opacity": "0.1",
        "stroke-dasharray": "5,5",
        stroke: "lightseagreen",
        "stroke-width": 1,
        "stroke-opacity": "0.5"
      }
    });
  }), 0) : _vm._e(), _vm._v(" "), _c("g", {
    attrs: {
      refs: "task_drag_phase_highlighter"
    }
  }, [_c("rect", {
    staticClass: "gaphighlighter-anim",
    attrs: {
      x: _vm.activePhaseHighlighter.x,
      y: _vm.activePhaseHighlighter.y,
      rx: 5,
      ry: 5,
      width: _vm.activePhaseHighlighter.width + 20,
      height: _vm.activePhaseHighlighter.height,
      fill: "rgba(64,150,238, 0.5)",
      "fill-opacity": "0.5",
      "stroke-dasharray": "5,5",
      stroke: "lightseagreen",
      "stroke-width": 1,
      "stroke-opacity": "0.9"
    }
  })]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "gap_highlighter"
    }
  }, [_vm.phaseGapHighlighter.length > 0 ? _c("g", {
    attrs: {
      refs: "gap_drag_highlighter"
    }
  }, _vm._l(_vm.phaseGapHighlighter, function (gap, index) {
    return _c("rect", {
      key: index,
      staticClass: "gaphighlighter-anim",
      attrs: {
        x: gap.x,
        y: gap.y,
        rx: 5,
        ry: 5,
        width: gap.width,
        height: gap.height - 5,
        fill: "#eee",
        "fill-opacity": "0.1",
        "stroke-dasharray": "5,5",
        stroke: "lightseagreen",
        "stroke-width": 1,
        "stroke-opacity": "0.5"
      }
    });
  }), 0) : _vm._e(), _vm._v(" "), _c("g", {
    attrs: {
      refs: "phase_gap_drag_hover_highligher"
    }
  }, [_c("rect", {
    staticClass: "gaphighlighter-anim",
    attrs: {
      x: _vm.activePhaseGapHighlighter.x,
      y: _vm.activePhaseGapHighlighter.y,
      rx: 5,
      ry: 5,
      width: _vm.activePhaseGapHighlighter.width,
      height: _vm.activePhaseGapHighlighter.height - 5,
      fill: "rgba(64,150,238, 0.5)",
      "fill-opacity": "0.5",
      "stroke-dasharray": "5,5",
      stroke: "#4096EE",
      "stroke-width": 1,
      "stroke-opacity": "0.9"
    }
  })])]), _vm._v(" "), _vm.isJourneyHighlight ? _c("g", {
    attrs: {
      refs: "journey_highlighter"
    }
  }, [_c("rect", {
    staticClass: "gaphighlighter-anim",
    attrs: {
      id: "customer-journey-workspace-highlight_0",
      x: "140",
      y: "5",
      width: _vm.computeCanvasWidth,
      height: _vm.computeCanvasHeight,
      fill: "rgba(38,66,139,0.1)",
      "stroke-width": "1",
      stroke: "rgba(0,128, 128, 0.65)",
      "stroke-dasharray": "4"
    }
  }), _vm._v(" "), _c("rect", {
    attrs: {
      x: "700",
      y: "7",
      width: "205",
      height: 21,
      fill: "rgba(0,128, 128, 0.95)",
      rx: "5"
    }
  }), _vm._v(" "), _c("text", {
    attrs: {
      id: "customer-journey-workspace-highlight_guidetext",
      x: "710",
      y: "22",
      width: "220",
      height: 30,
      fill: "white",
      "font-style": "italic",
      "font-size": "0.9em"
    }
  }, [_vm._v("\n        Drag whole-of-journey ideas/risks here.\n      ")])]) : _vm._e()]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "menu-bar-tools"
    }
  }, [_c("g", {
    attrs: {
      refs: "phase"
    }
  }, [_c("phase-tool-component", {
    directives: [{
      name: "dragged",
      rawName: "v-dragged.prevent",
      value: _vm.onPhaseMenuToolDragged,
      expression: "onPhaseMenuToolDragged",
      modifiers: {
        prevent: true
      }
    }],
    attrs: {
      x: _vm.toolbox.menu_phase.x,
      y: _vm.toolbox.menu_phase.y,
      length: 90,
      label: "Stage",
      "show-label": true,
      position: "menubar"
    },
    on: {
      mouseover: _vm.onPhaseToolMouseOver,
      mouseleave: _vm.onPhaseToolMouseLeave
    }
  })], 1), _vm._v(" "), _c("g", {
    attrs: {
      refs: "task"
    }
  }, [_c("task-component", {
    directives: [{
      name: "dragged",
      rawName: "v-dragged.prevent",
      value: _vm.onTaskMenuToolDragged,
      expression: "onTaskMenuToolDragged",
      modifiers: {
        prevent: true
      }
    }],
    attrs: {
      id: "tool_task",
      x: _vm.toolbox.menu_task.x,
      y: _vm.toolbox.menu_task.y,
      label: "Step",
      "show-label": true,
      "is-tool": true,
      position: "menubar"
    },
    on: {
      mouseover: _vm.onTaskToolMouseOver,
      toolmouseover: _vm.onTaskToolMouseLeave
    }
  })], 1), _vm._v(" "), _c("g", {
    attrs: {
      refs: "idea"
    }
  }, [_c("idea-component", {
    directives: [{
      name: "dragged",
      rawName: "v-dragged.prevent",
      value: _vm.onIdeaToolDragged,
      expression: "onIdeaToolDragged",
      modifiers: {
        prevent: true
      }
    }],
    attrs: {
      x: _vm.toolbox.idea.x,
      y: _vm.toolbox.idea.y,
      label: "Idea",
      "show-label": true,
      toolbar: true
    },
    on: {
      mouseover: _vm.onIdeaToolMouseOver,
      mouseleave: _vm.onIdeaToolMouseLeave
    }
  })], 1), _vm._v(" "), _c("g", {
    attrs: {
      refs: "risk"
    }
  }, [_vm.hasPermissionForModule("Risk Managment") ? _c("risk-component", {
    directives: [{
      name: "dragged",
      rawName: "v-dragged.prevent",
      value: _vm.onRiskToolDragged,
      expression: "onRiskToolDragged",
      modifiers: {
        prevent: true
      }
    }],
    attrs: {
      x: _vm.toolbox.risk.x,
      y: _vm.toolbox.risk.y,
      label: "Risk",
      "show-label": true,
      toolbar: true
    },
    on: {
      mouseover: _vm.onRiskToolMouseOver,
      mouseleave: _vm.onRiskToolMouseLeave
    }
  }) : _vm._e()], 1)]), _vm._v(" "), !_vm.isCompactMenubar ? _c("g", {
    attrs: {
      refs: "toolbar"
    }
  }, [_c("g", {
    attrs: {
      refs: "customer-journey-toolbox-tools"
    }
  }, [_c("g", {
    attrs: {
      refs: "phase"
    }
  }, [_c("phase-tool-component", {
    directives: [{
      name: "dragged",
      rawName: "v-dragged.prevent",
      value: _vm.onPhaseToolDragged,
      expression: "onPhaseToolDragged",
      modifiers: {
        prevent: true
      }
    }],
    attrs: {
      x: _vm.toolbox.phase.x - 5,
      y: _vm.toolbox.phase.y + 2,
      length: 120,
      label: "Stage",
      "show-label": false
    },
    on: {
      mouseover: _vm.onPhaseToolMouseOver,
      mouseleave: _vm.onPhaseToolMouseLeave
    }
  })], 1), _vm._v(" "), _c("g", {
    attrs: {
      refs: "task"
    }
  }, [_vm.stepLayout === "horizontal" ? _c("task-component", {
    directives: [{
      name: "dragged",
      rawName: "v-dragged.prevent",
      value: _vm.onTaskToolDragged,
      expression: "onTaskToolDragged",
      modifiers: {
        prevent: true
      }
    }],
    attrs: {
      id: "tool_task",
      x: _vm.toolbox.task.x - 5,
      y: _vm.toolbox.task.y,
      label: "Step",
      "show-label": true,
      "is-tool": true
    },
    on: {
      mouseover: _vm.onTaskToolMouseOver,
      toolmouseover: _vm.onTaskToolMouseLeave
    }
  }) : _c("task-grid-component", {
    directives: [{
      name: "dragged",
      rawName: "v-dragged.prevent",
      value: _vm.onTaskToolDragged,
      expression: "onTaskToolDragged",
      modifiers: {
        prevent: true
      }
    }],
    attrs: {
      id: "tool_task",
      x: _vm.toolbox.task.x - 5,
      y: _vm.toolbox.task.y + 20,
      label: "Step",
      "show-label": true,
      "is-tool": true
    },
    on: {
      mouseover: _vm.onTaskToolMouseOver,
      toolmouseover: _vm.onTaskToolMouseLeave
    }
  })], 1)])]) : _vm._e(), _vm._v(" "), _c("g", {
    attrs: {
      refs: "popover"
    }
  }, [_c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.phaseHover && !_vm.phaseLongPress && _vm.isEntityPopoverVisible ? _c("g", {
    attrs: {
      refs: "phase_details_popover"
    }
  }, [_c("phase-popover-component", {
    attrs: {
      x: _vm.isPhaseFocusMode ? 575 : _vm.computePhasePopoverPosX(_vm.selectedPhaseIndex),
      y: 100,
      width: 600,
      phasex: _vm.computePhasePosX(_vm.selectedPhaseIndex),
      phasewidth: _vm.computePhaseLength(_vm.selectedPhase, _vm.selectedPhaseIndex),
      phase: _vm.selectedPhase,
      steps: _vm.computedTaskList,
      experiences: _vm.computedExperienceList,
      index: _vm.selectedPhaseIndex,
      ideas: _vm.computedIdeasForPhase(_vm.selectedPhase),
      "journey-map": _vm.journeyMap
    },
    on: {
      "create-task": _vm.createTaskFromPhase,
      "update-steps-sortorder": _vm.rearrangeSteps,
      "initiate-create-experience": _vm.initiateCreatePhaseExperience,
      "focus-mode": _vm.toggleFocusMode,
      details: _vm.editPhaseDetails,
      close: function close($event) {
        _vm.phaseHover = false;
      },
      "delete-phase": _vm.deletePhase,
      "delete-step": _vm.deleteStep,
      "delete-experience": _vm.deleteExperience,
      "initiate-create-phase-idea": _vm.initiateCreatePhaseIdea
    }
  })], 1) : _vm._e()]), _vm._v(" "), _c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.taskHover && _vm.isEntityPopoverVisible ? _c("g", {
    attrs: {
      refs: "task_details_popover"
    }
  }, [_c("task-popover-component", {
    attrs: {
      x: _vm.computeTaskPopoverPosX(_vm.selectedTaskIndex),
      y: _vm.computeTaskPosY(_vm.selectedTaskIndex) - (_vm.stepLayout === "horizontal" ? 95 : 115),
      width: 600,
      position: _vm.computePopoverPosition(_vm.selectedTaskIndex),
      step: _vm.selectedTask,
      experiences: _vm.computedExperienceList,
      index: _vm.selectedTaskIndex,
      ideas: _vm.computedIdeasForStep(_vm.selectedTask),
      "journey-map": _vm.journeyMap
    },
    on: {
      "initiate-create-experience": _vm.initiateCreateStepExperience,
      "delete-step": _vm.deleteStep,
      close: function close($event) {
        _vm.taskHover = false;
      },
      details: _vm.selectTask,
      "initiate-create-step-idea": _vm.initiateCreateStepIdea
    }
  })], 1) : _vm._e()]), _vm._v(" "), _c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.touchpointHover && _vm.isEntityPopoverVisible ? _c("g", {
    attrs: {
      refs: "touchpoint_details_popover"
    }
  }, [_c("touchpoint-popover-component", {
    attrs: {
      x: _vm.selectedTouchpointPos.x,
      y: _vm.selectedTouchpointPos.y,
      width: 300,
      touchpoint: _vm.selectedTouchpoint,
      "journey-map": _vm.journeyMap,
      "phase-index": _vm.selectedPhaseIndex,
      "touchpoint-index": _vm.selectedTouchpointIndex
    },
    on: {
      "delete-touchpoint": _vm.deleteTouchpoint,
      click: _vm.selectTouchpoint,
      close: function close($event) {
        _vm.touchpointHover = false;
      }
    }
  })], 1) : _vm._e()])], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;