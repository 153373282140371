"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-container"
  }, [_vm.selectedCustomer && _vm.selectedCustomer.title ? _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.activeTabPaneName === "overview" && !_vm.loading && _vm.selectedCustomer && _vm.selectedCustomer.title ? _c("el-card", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }]
  }, [_c("overview-component", {
    attrs: {
      customer: _vm.selectedCustomer,
      index: _vm.selectedCustomerIndex,
      "overview-loading": _vm.overview_loading
    },
    on: {
      update: _vm.updateCustomerOverview,
      refresh: function refresh($event) {
        return _vm.retrieveCustomerTypes(1);
      },
      delete: _vm.openDeleteCustomerTypeConfirmation,
      goTo: _vm.navigateTo
    }
  })], 1) : _vm._e()], 1), _vm._v(" "), _c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.activeTabPaneName === "jobs-to-be-done" ? _c("el-card", [_c("jobs-to-be-done-component", {
    attrs: {
      customer: _vm.selectedCustomer
    },
    on: {
      goTo: _vm.navigateTo
    }
  })], 1) : _vm._e()], 1), _vm._v(" "), _c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.selectedCustomer.linkedJourneyMaps && _vm.selectedCustomer.linkedJourneyMaps.length > 0 && _vm.activeTabPaneName === "journey" ? _c("div", [_c("keep-alive", [_vm.loadingAllAPIsCompleted ? _c("customer-journey-component", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: !_vm.loadingAllAPIsCompleted,
      expression: "!loadingAllAPIsCompleted"
    }],
    attrs: {
      customer: _vm.selectedCustomer
    },
    on: {
      "add-phase": _vm.addJourneyMapPhaseLocal,
      "add-step": _vm.addJourneyMapStepLocal,
      "add-sub-step": _vm.addJourneyMapSubStepLocal,
      "add-step-experience": _vm.addJourneyMapExperienceToStepLocal,
      "add-phase-experience": _vm.addJourneyMapExperienceToPhaseLocal,
      "add-idea": _vm.addJourneyMapIdeaLocal,
      "add-risk": _vm.addJourneyMapRiskLocal,
      "add-touchpoint": _vm.addJourneyMapTouchpointLocal,
      "edit-touchpoint": _vm.editJourneyMapTouchpointLocal,
      "edit-phase": _vm.editJourneyMapPhaseLocal,
      "edit-step": _vm.editJourneyMapStepLocal,
      "edit-experience": _vm.editJourneyMapExperienceLocal,
      "edit-phase-sort-order": _vm.editJourneyMapPhaseSortOrderLocal,
      "collapse-phase": _vm.collapsePhaseLocal,
      "clone-journeymap": _vm.cloneJourneyMap,
      "expand-phase": _vm.expandPhaseLocal,
      "swap-phase-step-relation": _vm.swapPhaseStepRelationLocal,
      "delete-journeymap": _vm.deleteJourneyMap,
      "delete-phase": _vm.deletePhaseLocal,
      "delete-step": _vm.deleteStepLocal,
      "delete-sub-step": _vm.deleteSubStepLocal,
      "delete-experience": _vm.deleteExperienceLocal,
      "update-task-to-workflow": _vm.updateTaskToWorkflowLocal,
      "regenerate-journeymap": _vm.regenerateJourneyMap,
      "rename-journeymap": _vm.renameJourneyMap,
      "upload-journeymap": _vm.uploadJourneyMap,
      "fetch-journeymap": _vm.fetchLatestJourneyMap,
      "sync-journeymap": _vm.syncJourneyMap,
      "sync-touchpoints": _vm.syncTouchpoints,
      "create-new-journeymap": function createNewJourneymap($event) {
        return _vm.createCustomerTypeJourneyMap();
      }
    }
  }) : _vm._e()], 1)], 1) : _vm._e()]), _vm._v(" "), _c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.selectedCustomer.linkedJourneyMaps && _vm.selectedCustomer.linkedJourneyMaps.length === 0 && _vm.activeTabPaneName === "journey" ? _c("el-card", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }]
  }, [_c("div", {
    staticClass: "text-center",
    staticStyle: {
      "font-size": "1.3em"
    }
  }, [_c("p", [_c("b", [_c("a", {
    staticStyle: {
      color: "teal"
    }
  }, [_vm._v('Customer Type "' + _vm._s(_vm.selectedCustomer.title) + '"')])]), _vm._v(" doesn't have a journey.")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.createCustomerTypeJourneyMap();
      }
    }
  }, [_vm._v("Create Journey")])], 1)]) : _vm._e()], 1), _vm._v(" "), _c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.activeTabPaneName === "experience_backlog" ? _c("el-card", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }]
  }, [_c("div", [_vm.activeTabPaneName === "experience_backlog" ? _c("experience-backlog-component", {
    attrs: {
      customer: _vm.selectedCustomer
    },
    on: {
      "delete-experience": _vm.deleteExperienceLocal
    }
  }) : _vm._e()], 1)]) : _vm._e()], 1), _vm._v(" "), _c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.activeTabPaneName === "journey_idea" ? _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }]
  }, [_c("customer-journey-idea-component")], 1) : _vm._e()]), _vm._v(" "), _c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.activeTabPaneName === "create" ? _c("el-row", [_c("el-col", {
    attrs: {
      span: 8,
      offset: 8
    }
  }, [_c("el-card", [_c("create-customer-component", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    on: {
      "create-customer": _vm.createCustomer
    }
  })], 1)], 1)], 1) : _vm._e()], 1)], 1)], 1) : _vm._e(), _vm._v(" "), !_vm.selectedCustomer ? _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-empty", {
    attrs: {
      description: "No customer type found. Create new customer type"
    }
  })], 1)], 1) : _vm._e(), _vm._v(" "), _c("el-dialog", {
    attrs: {
      visible: _vm.createJourneyMapDialog,
      width: "50%",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.createJourneyMapDialog = $event;
      }
    }
  }, [_c("div", {
    staticClass: "text-left",
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_vm._v("Create Customer Journey")]), _vm._v(" "), _vm.createJourneyMapDialog ? _c("create-journey-map-component", {
    attrs: {
      "customer-types": _vm.customers,
      "customer-id": _vm.selectedCustomer.id
    },
    on: {
      "create-journey-map": _vm.createJourneyMap,
      "copy-journey-map": _vm.cloneJourneyMap,
      "generate-journey-map": _vm.generateJourneyFromNeedsLocal
    }
  }) : _vm._e()], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Warning",
      visible: _vm.deleteCustomerTypeConfirmationDialog,
      width: "40%",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.deleteCustomerTypeConfirmationDialog = $event;
      }
    }
  }, [_c("el-card", [_c("div", [_c("strong", [_vm._v("The following will be deleted: ")]), _vm._v(" "), _c("i", {
    staticStyle: {
      color: "darkred"
    }
  })]), _vm._v(" "), _c("ul", {
    staticStyle: {
      "margin-top": "6pt"
    }
  }, [_c("li", [_vm._v("Customer description, problem, and value")]), _vm._v(" "), _c("li", [_vm._v("Customer needs")]), _vm._v(" "), _c("li", [_vm._v("Customer journey")])]), _vm._v(" "), _c("div", {
    staticStyle: {
      "font-size": "0.9em"
    }
  }, [_vm._v("Offerings, Products and Services will not be deleted.")])]), _vm._v(" "), _c("div", {
    staticClass: "mt-10"
  }, [_vm._v("Type "), _c("span", {
    staticStyle: {
      color: "blue"
    }
  }, [_vm._v("'" + _vm._s(_vm.customerTypeForm.titleOriginal) + "'")]), _vm._v(" below to confirm deletion.")]), _vm._v(" "), _c("el-form", {
    attrs: {
      "label-position": "top",
      "label-width": "100px",
      model: _vm.customerTypeForm
    },
    nativeOn: {
      submit: function submit($event) {
        $event.preventDefault();
        return _vm.deleteCustomerType.apply(null, arguments);
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      label: ""
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.customerTypeForm.title,
      callback: function callback($$v) {
        _vm.$set(_vm.customerTypeForm, "title", $$v);
      },
      expression: "customerTypeForm.title"
    }
  })], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "secondary",
      icon: "el-icon-close"
    },
    on: {
      click: _vm.cancelCustomerTypeDelete
    }
  }, [_vm._v("Cancel")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini",
      type: "danger",
      disabled: _vm.customerTypeForm.title !== _vm.customerTypeForm.titleOriginal || _vm.loading,
      icon: _vm.loading ? "el-icon-loading" : "el-icon-delete"
    },
    on: {
      click: _vm.deleteCustomerType
    }
  }, [_vm._v("Delete")])], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;