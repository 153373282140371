"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.array.sort");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "features-container"
  }, [_c("el-row", {
    staticClass: "no-padding",
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticStyle: {
      "padding-right": "0 !important",
      "border-bottom-right-radius": "5pt",
      "border-top-right-radius": "5pt",
      "border-right": "1pt solid rgba(48,65,86,0.3)",
      "border-top": "1pt solid rgba(48,65,86,0.3)",
      "border-bottom": "1pt solid rgba(48,65,86,0.3)"
    },
    attrs: {
      span: _vm.isSidebarCollapse ? 1 : 5
    }
  }, [!_vm.isSidebarCollapse ? _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }]
  }, [_c("div", {
    staticClass: "features-header"
  }, [_c("div", {
    staticClass: "flex",
    staticStyle: {
      "border-bottom": "1.5pt solid #304156",
      "padding-left": "10pt",
      "margin-bottom": "0",
      "background-color": "rgba(222,235,246,.5)",
      "min-height": "50px",
      "max-height": "50px"
    }
  }, [_c("div", {
    staticClass: "text-left pl-10"
  }, [_c("div", {
    staticClass: "mt-10"
  }, [_c("strong", [_vm._v("Offering Requirements")])])]), _vm._v(" "), _c("div", {
    staticClass: "pt-5 ml-5 text-right",
    staticStyle: {
      "margin-bottom": "5pt",
      "margin-right": "8px"
    }
  }, [_c("el-button-group", [_c("el-button", {
    staticStyle: {
      top: "1pt",
      "border-radius": "3pt"
    },
    attrs: {
      id: "test_my_business_add_goal",
      size: "mini",
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.openCreateFeatureDialog();
      }
    }
  }, [_vm._v("\n                  Add\n                ")]), _vm._v(" "), _c("el-button", {
    staticStyle: {
      "margin-left": "4pt",
      top: "1pt",
      "border-radius": "3pt"
    },
    attrs: {
      icon: "el-icon-back",
      size: "mini",
      type: "primary",
      title: "Collapse Sidebar"
    },
    on: {
      click: function click($event) {
        return _vm.collapseSidebar();
      }
    }
  })], 1)], 1)]), _vm._v(" "), _c("el-row", {
    staticStyle: {
      padding: "10px 5px 0px 10px",
      "background-color": "rgba(0,0,0,.03)"
    }
  }, [_c("el-col", {
    attrs: {
      span: 18
    }
  }, [_c("div", {
    staticClass: "ml-5 mr-5"
  }, [_c("el-input", {
    staticClass: "mr-5",
    attrs: {
      type: "text",
      size: "mini",
      placeholder: "Search Features",
      "prefix-icon": "el-icon-search"
    },
    model: {
      value: _vm.search_features.q,
      callback: function callback($$v) {
        _vm.$set(_vm.search_features, "q", $$v);
      },
      expression: "search_features.q"
    }
  })], 1)]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "ml-5 mr-5 text-right"
  }, [_c("el-button-group", [_c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Filter",
      placement: "top"
    }
  }, [_c("el-button", {
    staticStyle: {
      "border-radius": "3pt"
    },
    attrs: {
      size: "micro",
      type: "secondary"
    },
    on: {
      click: _vm.showFilterDialog
    }
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "1.2em"
    },
    attrs: {
      "icon-class": "filter"
    }
  })], 1)], 1), _vm._v(" "), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Sort",
      placement: "top"
    }
  }, [_c("el-button", {
    staticStyle: {
      "border-radius": "3pt",
      "margin-left": "2pt"
    },
    attrs: {
      size: "micro",
      type: "secondary"
    },
    on: {
      click: function click($event) {
        return _vm.toggleSort();
      }
    }
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "1.2em"
    },
    attrs: {
      "icon-class": _vm.search_features.sort.title === "a-z" ? "sort-alpha-down" : "sort-alpha-up"
    }
  })], 1)], 1)], 1)], 1)])], 1), _vm._v(" "), _c("div", {
    staticClass: "flex flex-wrap",
    staticStyle: {
      padding: "1pt 1pt 5pt 16pt",
      display: "inline-block",
      "background-color": "rgba(0,0,0,.03)",
      width: "100%"
    }
  }, [_vm.search_features.filters.title !== "" ? _c("el-tag", {
    attrs: {
      slot: "reference",
      size: "mini",
      closable: ""
    },
    on: {
      close: function close($event) {
        return _vm.removeSearchFilterTag("title");
      }
    },
    slot: "reference"
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "1.2em"
    },
    attrs: {
      "icon-class": "filter-left"
    }
  }), _vm._v("Name:" + _vm._s(_vm.search_features.filters.title_operator) + " " + _vm._s(_vm.search_features.filters.title) + "\n            ")], 1) : _vm._e(), _vm._v(" "), _vm.search_features.filters.description !== "" ? _c("el-tag", {
    attrs: {
      size: "mini",
      closable: ""
    },
    on: {
      close: function close($event) {
        return _vm.removeSearchFilterTag("description");
      }
    }
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "1.2em"
    },
    attrs: {
      "icon-class": "filter-left"
    }
  }), _vm._v("Description:" + _vm._s(_vm.search_features.filters.description_operator) + " " + _vm._s(_vm.search_features.filters.description))], 1) : _vm._e(), _vm._v(" "), _vm._l(_vm.search_features.filters.status, function (status, index) {
    return _c("el-tag", {
      key: index,
      attrs: {
        size: "mini",
        closable: ""
      },
      on: {
        close: function close($event) {
          return _vm.removeSearchFilterTag("status", index);
        }
      }
    }, [_c("svg-icon", {
      staticStyle: {
        "font-size": "1.2em"
      },
      attrs: {
        "icon-class": "filter-left"
      }
    }), _vm._v("Status:\n              "), status === "Proposed" ? _c("svg-icon", {
      staticStyle: {
        color: "rgba(8,16,8,0.27)",
        "font-size": "1.0em"
      },
      attrs: {
        "icon-class": "hand-holding-solid"
      }
    }) : _vm._e(), _vm._v(" "), status === "In progress" ? _c("svg-icon", {
      staticStyle: {
        color: "white",
        "background-color": "#2c64b5",
        "border-radius": "50%",
        "font-size": "1.0em"
      },
      attrs: {
        "icon-class": "three-dots"
      }
    }) : _vm._e(), _vm._v(" "), status === "On hold" ? _c("svg-icon", {
      staticStyle: {
        color: "#f09a0d",
        "font-size": "1.0em"
      },
      attrs: {
        "icon-class": "pause-circle-fill"
      }
    }) : _vm._e(), _vm._v(" "), status === "Overdue" ? _c("svg-icon", {
      staticStyle: {
        color: "#eb4a49",
        "font-size": "1.0em"
      },
      attrs: {
        "icon-class": "exclamation-circle-fill"
      }
    }) : _vm._e(), _vm._v(" "), status === "Achieved" ? _c("svg-icon", {
      staticStyle: {
        color: "#92b853",
        "font-size": "1.0em"
      },
      attrs: {
        "icon-class": "check-circle-fill"
      }
    }) : _vm._e(), _vm._v("\n              " + _vm._s(status) + "\n            ")], 1);
  }), _vm._v(" "), _vm._l(_vm.search_features.filters.priority, function (priority, index) {
    return _c("el-tag", {
      key: index,
      attrs: {
        size: "mini",
        closable: ""
      },
      on: {
        close: function close($event) {
          return _vm.removeSearchFilterTag("priority", index);
        }
      }
    }, [_c("svg-icon", {
      staticStyle: {
        "font-size": "1.2em"
      },
      attrs: {
        "icon-class": "filter-left"
      }
    }), _vm._v("Priority:" + _vm._s(priority) + "\n            ")], 1);
  }), _vm._v(" "), _vm.search_features.sort.title !== "" ? _c("el-tag", {
    attrs: {
      type: "info",
      size: "mini",
      closable: ""
    },
    on: {
      close: function close($event) {
        return _vm.removeSearchSortTag("title");
      }
    }
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "1.2em"
    },
    attrs: {
      "icon-class": _vm.search_features.sort.title === "a-z" ? "sort-alpha-down" : "sort-alpha-up"
    }
  }), _vm._v("\n              Name:" + _vm._s(_vm.search_features.sort.title) + "\n            ")], 1) : _vm._e()], 2)], 1), _vm._v(" "), _c("div", {
    staticClass: "mt-10"
  }, [_vm.filteredFeatures.length > 0 ? _c("div", {
    style: _vm.computeFeatureListStyle
  }, [_vm._l(_vm.filteredFeatures, function (feature, index) {
    return _c("div", {
      key: index,
      class: _vm.selectedFeature && _vm.selectedFeature.id === feature.id ? "list_item_feature is-item_feature-active" : "list_item_feature",
      on: {
        click: function click($event) {
          return _vm.selectFeature(feature, index);
        }
      }
    }, [_c("table", {
      staticStyle: {
        width: "100%",
        "font-variant-caps": "small-caps",
        "margin-left": "6px"
      }
    }, [_c("tr", [_c("td", [_c("strong", [_vm._v(_vm._s(_vm.truncateDescription(feature.title, "45")))])])])]), _vm._v(" "), _c("div", {
      staticStyle: {
        "font-size": "12px",
        "margin-left": "6px",
        "word-wrap": "break-word",
        "padding-right": "60pt"
      }
    }, [_c("i", [_vm._v(_vm._s(_vm.truncateDescription(feature.description, "180")))])]), _vm._v(" "), _c("table", {
      staticStyle: {
        width: "100%",
        "font-variant-caps": "small-caps",
        "margin-left": "6px"
      }
    }, [_c("tr", [_c("td", {
      staticClass: "fl",
      staticStyle: {
        "vertical-align": "bottom",
        "font-size": "1.5em",
        padding: "2pt 0 0 0",
        margin: "0"
      }
    }, [_c("el-tooltip", {
      staticClass: "item",
      attrs: {
        effect: "dark",
        content: "Proposed",
        placement: "top"
      }
    }, [feature.status === "Proposed" ? _c("svg-icon", {
      staticStyle: {
        "border-radius": "15pt",
        "padding-bottom": "4pt",
        "background-color": "white",
        color: "grey",
        "font-size": "1.0em"
      },
      attrs: {
        "icon-class": "hand-holding-solid"
      }
    }) : _vm._e()], 1), _vm._v(" "), _c("el-tooltip", {
      staticClass: "item",
      attrs: {
        effect: "dark",
        content: "In progress",
        placement: "top"
      }
    }, [feature.status === "In progress" ? _c("svg-icon", {
      staticStyle: {
        color: "white",
        "background-color": "#2c64b5",
        "border-radius": "50%",
        "font-size": "1.0em"
      },
      attrs: {
        "icon-class": "three-dots"
      }
    }) : _vm._e()], 1), _vm._v(" "), _c("el-tooltip", {
      staticClass: "item",
      attrs: {
        effect: "dark",
        content: "On hold",
        placement: "top"
      }
    }, [feature.status === "On hold" ? _c("svg-icon", {
      staticStyle: {
        color: "#ffa200",
        "font-size": "1.0em"
      },
      attrs: {
        "icon-class": "pause-circle-fill-w"
      }
    }) : _vm._e()], 1), _vm._v(" "), _c("el-tooltip", {
      staticClass: "item",
      attrs: {
        effect: "dark",
        content: "Overdue",
        placement: "top"
      }
    }, [feature.status === "Overdue" ? _c("svg-icon", {
      staticStyle: {
        color: "darkred",
        "font-size": "1.0em"
      },
      attrs: {
        "icon-class": "exclamation-circle-fill"
      }
    }) : _vm._e()], 1), _vm._v(" "), _c("el-tooltip", {
      staticClass: "item",
      attrs: {
        effect: "dark",
        content: "Achieved",
        placement: "top"
      }
    }, [feature.status === "Achieved" ? _c("svg-icon", {
      staticStyle: {
        color: "darkgreen",
        "font-size": "1.0em"
      },
      attrs: {
        "icon-class": "check-circle-fill"
      }
    }) : _vm._e()], 1)], 1), _vm._v(" "), _c("td", {
      staticClass: "fr",
      staticStyle: {
        "vertical-align": "bottom"
      }
    }, [feature.priority === "Highest" ? _c("el-tag", {
      staticStyle: {
        "background-color": "#AD0E30",
        color: "white"
      },
      attrs: {
        size: "small",
        placeholder: "Select"
      },
      model: {
        value: feature.priority,
        callback: function callback($$v) {
          _vm.$set(feature, "priority", $$v);
        },
        expression: "feature.priority"
      }
    }, [_vm._v(_vm._s(feature.priority))]) : _vm._e(), _vm._v(" "), feature.priority === "High" ? _c("el-tag", {
      staticStyle: {
        "background-color": "#6A0DAD",
        color: "white"
      },
      attrs: {
        size: "small",
        placeholder: "Select"
      },
      model: {
        value: feature.priority,
        callback: function callback($$v) {
          _vm.$set(feature, "priority", $$v);
        },
        expression: "feature.priority"
      }
    }, [_vm._v(_vm._s(feature.priority))]) : _vm._e(), _vm._v(" "), feature.priority === "Medium" ? _c("el-tag", {
      staticStyle: {
        "background-color": "#067AED",
        color: "white"
      },
      attrs: {
        size: "small",
        placeholder: "Select"
      },
      model: {
        value: feature.priority,
        callback: function callback($$v) {
          _vm.$set(feature, "priority", $$v);
        },
        expression: "feature.priority"
      }
    }, [_vm._v(_vm._s(feature.priority))]) : _vm._e(), _vm._v(" "), feature.priority === "Low" ? _c("el-tag", {
      staticStyle: {
        "background-color": "lightslategrey",
        color: "white"
      },
      attrs: {
        size: "small",
        placeholder: "Select"
      },
      model: {
        value: feature.priority,
        callback: function callback($$v) {
          _vm.$set(feature, "priority", $$v);
        },
        expression: "feature.priority"
      }
    }, [_vm._v(_vm._s(feature.priority))]) : _vm._e(), _vm._v(" "), feature.priority === "Lowest" ? _c("el-tag", {
      attrs: {
        size: "small",
        placeholder: "Select"
      },
      model: {
        value: feature.priority,
        callback: function callback($$v) {
          _vm.$set(feature, "priority", $$v);
        },
        expression: "feature.priority"
      }
    }, [_vm._v(_vm._s(feature.priority))]) : _vm._e()], 1)])])]);
  }), _vm._v(" "), _c("div", {
    staticClass: "text-center",
    staticStyle: {
      width: "calc(100%/24 * 4)",
      position: "fixed",
      bottom: "0px",
      "background-color": "rgba(255,255,255,1)"
    }
  }, [_c("el-pagination", {
    attrs: {
      layout: "prev, pager, next",
      small: "",
      "hide-on-single-page": true,
      total: _vm.total_count,
      "page-size": _vm.page_size,
      "current-page": _vm.active_page
    },
    on: {
      "current-change": _vm.loadPage,
      "prev-click": _vm.loadPage,
      "next-click": _vm.loadPage
    }
  })], 1)], 2) : _c("el-empty", {
    attrs: {
      description: "No Features Found"
    }
  })], 1)]) : _c("div", [_c("el-card", {
    staticStyle: {
      height: "100%",
      "background-color": "rgba(222,235,246,.5)"
    }
  }, [_c("el-row", [_c("div", {
    staticClass: "text-center"
  }, [_c("div", {
    staticClass: "title-caps"
  }, [_c("strong", [_vm._v("Show")])]), _vm._v(" "), _c("div", {
    staticClass: "title-caps"
  }, [_c("strong", [_vm._v("Features")])]), _vm._v(" "), _c("el-button", {
    staticClass: "mt-10",
    attrs: {
      type: "primary",
      icon: "el-icon-right",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.expandSidebar();
      }
    }
  })], 1)])], 1)], 1)]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: _vm.isSidebarCollapse ? 23 : 19
    }
  }, [_c("transition", {
    attrs: {
      name: "fade"
    }
  }, [!_vm.loading ? [_vm.selectedFeature.title ? _c("div", [_c("el-card", {
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      id: "product_feature_details_container"
    }
  }, [_c("div", {
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 18
    }
  }, [_vm.edit.feature ? _c("el-input", {
    attrs: {
      type: "text",
      size: "small"
    },
    model: {
      value: _vm.selectedFeature.title,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedFeature, "title", $$v);
      },
      expression: "selectedFeature.title"
    }
  }) : _c("strong", {
    staticStyle: {
      "font-variant-caps": "small-caps"
    }
  }, [_vm._v(_vm._s(_vm.truncateDescription(_vm.selectedFeature.title, 90)))])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "fr mr-10",
    staticStyle: {
      "margin-top": "-5px"
    }
  }, [_vm.hasPermissionForModule("Risk Managment") ? [!_vm.edit.feature ? _c("a", {
    staticStyle: {
      color: "#ffba00"
    }
  }, [_vm._v(_vm._s(_vm.selectedFeature.linkedRisks.length))]) : _vm._e(), _vm._v(" "), !_vm.edit.feature ? _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Risks",
      placement: "top"
    }
  }, [_c("div", {
    attrs: {
      slot: "content"
    },
    slot: "content"
  }, [_vm._v("\n                            " + _vm._s(_vm.selectedFeature.linkedRisks.length) + " Risks Identified\n                          ")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "warning",
      size: "mini",
      circle: ""
    },
    on: {
      click: function click($event) {
        return _vm.openRiskDialog(_vm.selectedFeature, "PRODUCT_FEATURE");
      }
    }
  }, [_c("i", {
    staticClass: "fa fa-fire"
  })])], 1) : _vm._e()] : _vm._e(), _vm._v(" "), !_vm.edit.feature ? _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Edit",
      placement: "top"
    }
  }, [_c("el-button", {
    attrs: {
      type: "secondary",
      size: "mini",
      circle: "",
      icon: "el-icon-edit"
    },
    on: {
      click: function click($event) {
        return _vm.editGoal();
      }
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.edit.feature ? _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Cancel",
      placement: "top"
    }
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      circle: "",
      label: "Cancel"
    },
    on: {
      click: function click($event) {
        return _vm.cancelGoalUpdate();
      }
    }
  }, [_c("i", {
    staticClass: "fa fa-ban"
  })])], 1) : _vm._e(), _vm._v(" "), _vm.edit.feature ? _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Update",
      placement: "top"
    }
  }, [_c("el-button", {
    attrs: {
      type: "success",
      size: "mini",
      circle: "",
      label: "Save"
    },
    on: {
      click: function click($event) {
        return _vm.updateGoal();
      }
    }
  }, [_c("i", {
    staticClass: "fa fa-save"
  })])], 1) : _vm._e(), _vm._v(" "), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Delete",
      placement: "top"
    }
  }, [_c("el-button", {
    staticStyle: {
      "background-color": "darkred",
      "border-color": "darkred"
    },
    attrs: {
      type: "danger",
      size: "mini",
      circle: "",
      icon: "el-icon-delete"
    },
    nativeOn: {
      click: function click($event) {
        $event.preventDefault();
        return _vm.deleteGoal(_vm.selectedGoalIndex);
      }
    }
  })], 1)], 2)])], 1)], 1), _vm._v(" "), _c("el-tabs", {
    attrs: {
      value: "details"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "Details",
      name: "details"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("\n                      Retrieve and display ticket details\n                      "), _c("el-row", {
    attrs: {
      span: 24
    }
  }, [_c("el-col", {
    attrs: {
      span: 6
    }
  }, [_vm._v("Name")]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 18
    }
  })], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      span: 24
    }
  }, [_c("el-col", {
    attrs: {
      span: 6
    }
  }, [_vm._v("Ticket")]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 18
    }
  })], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      span: 24
    }
  }, [_c("el-col", {
    attrs: {
      span: 6
    }
  }, [_vm._v("Product/Service")]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 18
    }
  })], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      span: 24
    }
  }, [_c("el-col", {
    attrs: {
      span: 6
    }
  }, [_vm._v("Enabler")]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 18
    }
  })], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      span: 24
    }
  }, [_c("el-col", {
    attrs: {
      span: 6
    }
  }, [_vm._v("Status")]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 18
    }
  })], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      span: 24
    }
  }, [_c("el-col", {
    attrs: {
      span: 6
    }
  }, [_vm._v("Releases")]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 18
    }
  }, [_vm._v("ADV MODULE")])], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      span: 24
    }
  }, [_c("el-col", {
    attrs: {
      span: 6
    }
  }, [_vm._v("Priority")]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 18
    }
  })], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      span: 24
    }
  }, [_c("el-col", {
    attrs: {
      span: 6
    }
  }, [_vm._v("Tech. Priority")]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 18
    }
  })], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      span: 24
    }
  }, [_c("el-col", {
    attrs: {
      span: 6
    }
  }, [_vm._v("Smart Priority")]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 18
    }
  })], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      span: 24
    }
  }, [_c("el-col", {
    attrs: {
      span: 6
    }
  }, [_vm._v("Tech. Priority")]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 18
    }
  })], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      span: 24
    }
  }, [_c("el-col", {
    attrs: {
      span: 6
    }
  }, [_vm._v("Value")]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 18
    }
  })], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      span: 24
    }
  }, [_c("el-col", {
    attrs: {
      span: 6
    }
  }, [_vm._v("Cost Est.")]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 18
    }
  })], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      span: 24
    }
  }, [_c("el-col", {
    attrs: {
      span: 6
    }
  }, [_vm._v("Approved Via")]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 18
    }
  })], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      span: 24
    }
  }, [_c("el-col", {
    attrs: {
      span: 6
    }
  }, [_vm._v("Created")]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 18
    }
  })], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      span: 24
    }
  }, [_c("el-col", {
    attrs: {
      span: 6
    }
  }, [_vm._v("Last Modified")]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 18
    }
  })], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      span: 24
    }
  }, [_c("el-col", {
    attrs: {
      span: 6
    }
  }, [_vm._v("Attachements")]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 18
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("\n                      Description\n                    ")])], 1), _vm._v(" "), _c("el-tabs", {
    attrs: {
      value: "discussion"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "Discussion",
      name: "discussion"
    }
  }, [_c("div", {
    staticStyle: {
      "border-radius": "5pt",
      border: "solid 1pt lightgrey",
      "padding-top": "4pt",
      margin: "auto",
      width: "802px"
    }
  }, [_c("span", {
    staticClass: "ml-10",
    staticStyle: {
      "padding-left": "2pt",
      "font-size": "16pt"
    }
  }), _c("strong", [_vm._v("Discussion")]), _vm._v(" "), _c("main", {
    staticClass: "msger-chat"
  }, _vm._l(_vm.discussions, function (discussion, index) {
    return _c("div", {
      key: index,
      class: _vm.checkIfUserIsAuthor(discussion.author) ? "msg right-msg" : "msg left-msg"
    }, [_c("div", {
      staticClass: "msg-img",
      style: _vm.computeMessengerAvatar(discussion.image_url)
    }), _vm._v(" "), _c("div", {
      staticClass: "msg-bubble",
      staticStyle: {
        "max-width": "600pt"
      }
    }, [_c("div", {
      staticClass: "msg-info"
    }, [_c("div", {
      staticClass: "msg-info-name"
    }, [_vm._v(_vm._s(discussion.author))]), _vm._v(" "), _c("div", {
      staticClass: "msg-info-time"
    }, [_vm._v(_vm._s(_vm.computeHumanFriendlyDateDifference(discussion.date_time)) + "\n                                  "), _vm.checkIfUserIsAuthor(discussion.author) ? _c("div", [_vm.edit.discussion.mode && _vm.edit.discussion.index === index ? _c("i", {
      staticClass: "el-icon-check",
      on: {
        click: function click($event) {
          return _vm.updateDiscussion(discussion, index);
        }
      }
    }) : _c("i", {
      staticClass: "el-icon-edit",
      on: {
        click: function click($event) {
          return _vm.editDiscussion(discussion, index);
        }
      }
    }), _vm._v(" "), _c("i", {
      staticClass: "el-icon-close",
      on: {
        click: function click($event) {
          return _vm.deleteDiscussion(discussion, index);
        }
      }
    })]) : _vm._e()])]), _vm._v(" "), _vm.edit.discussion.mode && _vm.edit.discussion.index === index ? _c("div", {
      staticClass: "msg-text"
    }, [_c("el-input", {
      staticClass: "msger-input",
      staticStyle: {
        "font-size": "11px"
      },
      attrs: {
        type: "textarea"
      },
      model: {
        value: discussion.message,
        callback: function callback($$v) {
          _vm.$set(discussion, "message", $$v);
        },
        expression: "discussion.message"
      }
    }), _vm._v(" "), _c("el-button", {
      attrs: {
        type: "primary",
        size: "micro",
        icon: "el-icon-check"
      },
      on: {
        click: function click($event) {
          return _vm.updateDiscussion(discussion, index);
        }
      }
    }, [_vm._v("Update")])], 1) : _c("div", [_c("div", {
      staticClass: "msg-text"
    }, [_vm._v("\n                                  " + _vm._s(discussion.message) + "\n                                ")]), _vm._v(" "), _c("div", {
      staticClass: "msg-text text-left"
    }, _vm._l(discussion.files, function (file, file_index) {
      return _c("el-link", {
        key: file_index,
        staticClass: "file",
        attrs: {
          icon: _vm.computeIcon(file.type),
          href: file.url
        }
      }, [_vm._v("\n                                    " + _vm._s(file.name) + "\n                                  ")]);
    }), 1)])])]);
  }), 0), _vm._v(" "), _c("div", {
    staticClass: "msger-inputarea",
    staticStyle: {
      "max-width": "600pt",
      width: "600pt"
    }
  }, [!_vm.fileLoading ? _c("input", {
    ref: "fileInput",
    staticStyle: {
      display: "none"
    },
    attrs: {
      id: "file",
      type: "file",
      accept: "image/*",
      multiple: "multiple"
    },
    on: {
      change: function change($event) {
        return _vm.uploadFile();
      }
    }
  }) : _vm._e(), _vm._v(" "), _c("el-input", {
    staticClass: "msger-input",
    staticStyle: {
      padding: "0px",
      "background-color": "#ddd"
    },
    attrs: {
      type: "text",
      placeholder: "Enter your message..."
    },
    model: {
      value: _vm.comment,
      callback: function callback($$v) {
        _vm.comment = $$v;
      },
      expression: "comment"
    }
  }, [_c("el-button", {
    staticStyle: {
      "padding-top": "0px",
      "padding-bottom": "0px"
    },
    attrs: {
      slot: "append",
      icon: "el-icon-paperclip"
    },
    on: {
      click: _vm.attachFiles
    },
    slot: "append"
  })], 1), _vm._v(" "), _c("button", {
    staticClass: "msger-send-btn",
    on: {
      click: _vm.addComment
    }
  }, [_vm._v("Send")])], 1)])]), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: "Actions",
      name: "action"
    }
  }, [_c("action-component", {
    attrs: {
      entity: {
        id: _vm.selectedFeature.id,
        type: "PRODUCTFEATURE"
      }
    },
    on: {
      add_action: _vm.addAction
    }
  })], 1), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: "History",
      name: "history"
    }
  }, [_c("div", {
    staticStyle: {
      "border-radius": "5pt",
      border: "solid 1pt lightgrey",
      "padding-top": "4pt"
    }
  }, [_c("span", {
    staticClass: "ml-10",
    staticStyle: {
      "padding-left": "2pt",
      "font-size": "16pt"
    }
  }), _c("strong", [_vm._v("Ticket History")]), _vm._v(" "), _c("el-table", {
    staticStyle: {
      width: "99%",
      margin: "10pt"
    },
    attrs: {
      data: _vm.history,
      height: "550",
      border: "",
      stripe: "",
      size: "mini"
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Date/Time",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v("\n                              " + _vm._s(_vm.computeHumanFriendlyDate(scope.row.date_time)) + "\n                            ")];
      }
    }], null, false, 1458272987)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "artefact",
      label: "Artefact"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "event",
      label: "Event"
    }
  })], 1)], 1)])], 1)], 1), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: "Stories & Tasks",
      name: "stories_task"
    }
  }, [_c("el-table", {
    attrs: {
      data: _vm.feature_stories,
      size: "mini",
      stripe: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Title",
      width: "310"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-input", {
          attrs: {
            type: "text",
            size: "mini"
          },
          model: {
            value: scope.row.title,
            callback: function callback($$v) {
              _vm.$set(scope.row, "title", $$v);
            },
            expression: "scope.row.title"
          }
        })];
      }
    }], null, false, 644953238)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Status",
      width: "140"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-select", {
          attrs: {
            placeholder: "Select Status",
            size: "mini"
          },
          model: {
            value: scope.row.status,
            callback: function callback($$v) {
              _vm.$set(scope.row, "status", $$v);
            },
            expression: "scope.row.status"
          }
        }, _vm._l(_vm.statuses, function (status) {
          return _c("el-option", {
            key: status.value,
            staticClass: "slim-list",
            attrs: {
              label: status.label,
              value: status.value,
              width: "60%"
            }
          });
        }), 1)];
      }
    }], null, false, 1963372486)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Priority",
      width: "100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-select", {
          attrs: {
            placeholder: "Select",
            size: "mini"
          },
          model: {
            value: scope.row.priority,
            callback: function callback($$v) {
              _vm.$set(scope.row, "priority", $$v);
            },
            expression: "scope.row.priority"
          }
        }, [_c("el-option", {
          staticClass: "slim-list",
          staticStyle: {
            "font-size": "9pt"
          },
          attrs: {
            label: "Lowest",
            value: "Lowest"
          }
        }), _vm._v(" "), _c("el-option", {
          staticStyle: {
            "font-size": "9pt"
          },
          attrs: {
            label: "Low",
            value: "Low"
          }
        }), _vm._v(" "), _c("el-option", {
          staticClass: "slim-list",
          staticStyle: {
            "font-size": "9pt"
          },
          attrs: {
            label: "Medium",
            value: "Medium"
          }
        }), _vm._v(" "), _c("el-option", {
          staticClass: "slim-list",
          staticStyle: {
            "font-size": "9pt"
          },
          attrs: {
            label: "High",
            value: "High"
          }
        }), _vm._v(" "), _c("el-option", {
          staticClass: "slim-list",
          staticStyle: {
            "font-size": "9pt"
          },
          attrs: {
            label: "Highest",
            value: "Highest"
          }
        })], 1)];
      }
    }], null, false, 1187427597)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "#",
      width: "135"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-button", {
          staticClass: "slim-button",
          attrs: {
            type: "primary",
            icon: "far fa-save"
          }
        }), _vm._v(" "), _c("el-button", {
          staticClass: "slim-button",
          attrs: {
            type: "warning",
            icon: "el-icon-delete",
            title: "Remove"
          },
          on: {
            click: function click($event) {
              return _vm.deleteFeatureStory(scope.row.$index);
            }
          }
        }), _vm._v(" "), _c("el-button", {
          staticClass: "slim-button",
          attrs: {
            type: "danger",
            icon: "fas fa-minus-circle",
            title: "Delete"
          },
          on: {
            click: function click($event) {
              return _vm.deleteFeatureStory(scope.row.$index);
            }
          }
        })];
      }
    }], null, false, 3954068372)
  })], 1)], 1), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: "Value Stream",
      name: "value_stream"
    }
  }, [_c("div", {
    staticStyle: {
      margin: "20pt 10pt 10pt 20pt",
      border: "solid 1pt lightgrey",
      "border-radius": "5pt",
      "box-shadow": "3px 3px 3px 3px lightgrey",
      padding: "10pt"
    }
  }, [_c("el-row", {}, [_c("el-col", {
    staticClass: "value-stream-column-header",
    attrs: {
      span: 2
    }
  }, [_c("div", {}, [_c("Span", [_vm._v("Drivers")])], 1)]), _vm._v(" "), _c("el-col", {
    staticClass: "value-stream-column-header",
    attrs: {
      span: 7
    }
  }, [_c("div", {}, [_c("Span", [_vm._v("Customer Needs")])], 1)]), _vm._v(" "), _c("el-col", {
    staticClass: "value-stream-column-header",
    attrs: {
      span: 7
    }
  }, [_c("div", {}, [_c("Span", [_vm._v("Offering Design")])], 1)]), _vm._v(" "), _c("el-col", {
    staticClass: "value-stream-column-header",
    attrs: {
      span: 7
    }
  }, [_c("div", {}, [_c("Span", [_vm._v("Offering Delivery")])], 1)]), _vm._v(" "), _c("el-col", {
    staticClass: "value-stream-column-header",
    attrs: {
      span: 1
    }
  }, [_c("div", {})])], 1), _vm._v(" "), _c("el-row", {
    staticClass: "value-stream-major-row"
  }, [_c("el-col", {
    staticStyle: {
      "vertical-align": "middle"
    },
    attrs: {
      span: 2
    }
  }, [_c("div", {
    staticClass: "value-stream-driver module-core"
  }, [_c("Span", [_vm._v("Customers")])], 1)]), _vm._v(" "), _c("el-col", {
    staticClass: "value-stream-major-cell",
    attrs: {
      span: 7
    }
  }, [_c("div", {
    staticClass: "value-stream-area flex"
  }, [_c("div", {
    staticClass: "value-stream-element text-center"
  }, [_c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Customer Type",
      placement: "left"
    }
  }, [_c("svg-icon", {
    staticClass: "value-stream-icon",
    staticStyle: {
      fill: "cornflowerblue"
    },
    attrs: {
      "icon-class": "peoples"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "value-stream-label text-center"
  }, [_vm._v(_vm._s(_vm.customer.title))])], 1), _vm._v(" "), _c("svg-icon", {
    staticClass: "value-stream-icon value-stream-arrow",
    attrs: {
      "icon-class": "arrow-right"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "value-stream-element text-center"
  }, [_c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Journey Stage",
      placement: "top"
    }
  }, [_c("svg-icon", {
    staticClass: "value-stream-icon",
    staticStyle: {
      fill: "#304174"
    },
    attrs: {
      "icon-class": "phase"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "value-stream-label text-center"
  }, [_vm._v("[journey.phase.title]")])], 1), _vm._v(" "), _c("svg-icon", {
    staticClass: "value-stream-icon value-stream-arrow",
    attrs: {
      "icon-class": "arrow-right"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "value-stream-element text-center"
  }, [_c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Journey Step",
      placement: "top"
    }
  }, [_c("svg-icon", {
    staticClass: "value-stream-icon",
    staticStyle: {
      fill: "#304174"
    },
    attrs: {
      "icon-class": "ujm-step"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "value-stream-label text-center"
  }, [_vm._v("[journey.phase.step.title]")])], 1), _vm._v(" "), _c("svg-icon", {
    staticClass: "value-stream-icon value-stream-arrow",
    attrs: {
      "icon-class": "arrow-right"
    }
  }, [_vm._v("(If story)")])], 1)]), _vm._v(" "), _c("el-col", {
    staticClass: "value-stream-major-cell",
    attrs: {
      span: 7
    }
  }, [_c("div", {
    staticClass: "value-stream-area flex"
  }, [_c("div", {
    staticClass: "value-stream-element text-center"
  }, [_c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Feature or Service/Process",
      placement: "top"
    }
  }, [_c("svg-icon", {
    staticClass: "value-stream-icon",
    staticStyle: {
      fill: "#304174"
    },
    attrs: {
      "icon-class": "feature"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "value-stream-label text-center"
  }, [_vm._v("[child.title||service.title }}")])], 1), _vm._v(" "), _c("svg-icon", {
    staticClass: "value-stream-icon value-stream-arrow",
    attrs: {
      "icon-class": "arrow-right"
    }
  }, [_vm._v("(If story)")]), _vm._v(" "), _c("div", {
    staticClass: "value-stream-element text-center"
  }, [_c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Feature Story or Process Story",
      placement: "top"
    }
  }, [_c("svg-icon", {
    staticClass: "value-stream-icon",
    staticStyle: {
      fill: "#304174"
    },
    attrs: {
      "icon-class": "story"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "value-stream-label text-center"
  }, [_vm._v("[child.title||service.title }}")])], 1)], 1)]), _vm._v(" "), _c("el-col", {
    staticClass: "value-stream-major-cell",
    attrs: {
      span: 7
    }
  }, [_c("div", {
    staticClass: "value-stream-area flex"
  }, [_c("div", {
    staticClass: "value-stream-element text-center"
  }, [_c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Feature or Service/Process",
      placement: "top"
    }
  }, [_c("svg-icon", {
    staticClass: "value-stream-icon",
    staticStyle: {
      fill: "#304174"
    },
    attrs: {
      "icon-class": "roadmap"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "value-stream-label text-center"
  }, [_vm._v("[Release Name] [Ordered]")])], 1), _vm._v(" "), _c("svg-icon", {
    staticClass: "value-stream-icon value-stream-arrow",
    attrs: {
      "icon-class": "arrow-right"
    }
  }, [_vm._v("(If story)")]), _vm._v(" "), _c("div", {
    staticClass: "value-stream-element text-center"
  }, [_c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Feature Story or Process Story",
      placement: "top"
    }
  }, [_c("svg-icon", {
    staticClass: "value-stream-icon",
    staticStyle: {
      fill: "#304174"
    },
    attrs: {
      "icon-class": "increment"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "value-stream-label text-center"
  }, [_vm._v("[Increment Name] [Ordered]]")])], 1)], 1)]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 1
    }
  }, [_c("div", {
    attrs: {
      id: "trapezoid_endarrow"
    }
  })])], 1), _vm._v(" "), _c("el-row", [_c("el-col", {
    attrs: {
      span: 20,
      offset: 2
    }
  }, [_c("el-row", [_c("el-col", {
    attrs: {
      span: 12,
      offset: 0
    }
  }, [_c("div", {
    staticClass: "mt-10"
  }, [_c("strong", [_c("u", [_vm._v("Value Stream Data")])]), _vm._v("\n                              [Shows data when element above is hovered over. ]\n                              "), _c("el-table", {
    staticClass: "mt-10",
    attrs: {
      data: _vm.feature_stories,
      stripe: "",
      border: "",
      size: "mini"
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Title",
      prop: "title"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Description",
      prop: "description"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Status",
      prop: "status"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Release",
      prop: "release"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Priority",
      prop: "priority"
    }
  })], 1)], 1)])], 1)], 1)], 1)], 1)]), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: "Process Design",
      name: "process_design"
    }
  }, [_vm._v("Process Design")]), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: "Related Tickets",
      name: "related_tickets"
    }
  }, [_c("el-tabs", {
    attrs: {
      "tab-position": "left"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "Prodiggi Tickets"
    }
  }, [_c("div", {
    staticStyle: {
      "border-radius": "5pt",
      border: "solid 1pt lightgrey",
      "padding-top": "8pt"
    }
  }, [_c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "These include dependancies, blockers, etc.",
      placement: "right"
    }
  }, [_c("span", {
    staticClass: "ml-10 mt-10"
  }, [_c("strong", [_vm._v("Related Prodiggi Tickets ")])])]), _vm._v(" "), _c("div", {
    staticClass: "mt-10"
  }, [_c("el-button", {
    staticClass: "fr",
    staticStyle: {
      position: "absolute",
      "z-index": "100",
      right: "10pt",
      top: "4pt"
    },
    attrs: {
      type: "primary",
      size: "mini"
    }
  }, [_vm._v("Add Ticket")]), _vm._v(" "), _c("el-table", {
    staticStyle: {
      width: "99%",
      "margin-left": "10pt",
      "padding-right": "10pt",
      "padding-bottom": "10pt"
    },
    attrs: {
      id: "linkTicketsTable",
      data: _vm.linkedTickets,
      "header-cell-class-name": "linkedTicketTableHeader",
      "cell-class-name": "linkedTicketTableCell"
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "linkedTicketID",
      label: "ID",
      width: "80"
    }
  }), _vm._v(" "), _c("el-table-column", {
    staticClass: "link-type",
    attrs: {
      prop: "linkedTicketName",
      label: "Prodiggi Ticket Name",
      width: ""
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "linkedTicketLinkType",
      label: "Link Type",
      width: "300"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "linkedTicketLinkType",
      label: "",
      width: "60"
    }
  }, [_c("i", {
    staticClass: "el-icon-delete",
    staticStyle: {
      color: "darkred",
      cursor: "pointer"
    }
  })])], 1)], 1)], 1)]), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: "External Systems"
    }
  }, [_c("div", {
    staticStyle: {
      "border-radius": "5pt",
      border: "solid 1pt lightgrey",
      "padding-top": "8pt"
    }
  }, [_c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Mappings to external tickets can be changed on the Integrated Data tab under Offering, Delivery.",
      placement: "right"
    }
  }, [_c("span", {
    staticClass: "ml-10 mt-10"
  }, [_c("strong", [_vm._v("Tickets in External Systems")])])]), _vm._v(" "), _c("el-table", {
    staticClass: "mt-10",
    staticStyle: {
      width: "99%",
      "margin-left": "10pt",
      "padding-right": "10pt",
      "padding-bottom": "10pt"
    },
    attrs: {
      data: _vm.external_tickets,
      stripe: "",
      size: "mini"
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Ext ID",
      prop: "id",
      width: "60"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Type",
      prop: "type",
      width: "60"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Title",
      prop: "title"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Status",
      prop: "status",
      width: "80"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "External System",
      prop: "system",
      width: "160"
    }
  })], 1)], 1)])], 1)], 1), _vm._v(" "), _vm.hasPermissionForModule("Risk Managment") ? _c("el-tab-pane", {
    attrs: {
      label: "Risks",
      name: "risks"
    }
  }, [_vm._v("Risks")]) : _vm._e(), _vm._v(" "), _vm.hasPermissionForModule("Innovation Hub") ? _c("el-tab-pane", {
    attrs: {
      label: "Ideas",
      name: "ideas"
    }
  }, [_vm._v("Ideas")]) : _vm._e()], 1)], 1)], 1) : _c("div", {
    staticClass: "text-center"
  }, [_c("span", [_vm._v("Select Feature to view details")])])] : _vm._e()], 2)], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Offering Requirements Filter",
      visible: _vm.filterDialog,
      width: "30%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.filterDialog = $event;
      }
    }
  }, [_c("h4", [_vm._v("Products & Services")]), _vm._v(" "), _c("table", {
    staticStyle: {
      "table-layout": "fixed",
      width: "100%"
    }
  }, [_c("colgroup", [_c("col", {
    staticStyle: {
      width: "15%"
    },
    attrs: {
      span: "1"
    }
  }), _vm._v(" "), _c("col", {
    staticStyle: {
      width: "85%"
    },
    attrs: {
      span: "1"
    }
  })]), _vm._v(" "), _c("tr", [_c("th", {
    staticClass: "text-right pr-10"
  }, [_vm._v("Name")]), _vm._v(" "), _c("td", {
    staticClass: "flex"
  }, [_c("el-select", {
    attrs: {
      clearble: "",
      placeholder: "Select",
      size: "mini"
    }
  })], 1)])]), _vm._v(" "), _c("el-divider"), _vm._v(" "), _c("h4", [_vm._v("Features & Processes")]), _vm._v(" "), _c("table", {
    staticStyle: {
      "table-layout": "fixed",
      width: "100%"
    }
  }, [_c("colgroup", [_c("col", {
    staticStyle: {
      width: "15%"
    },
    attrs: {
      span: "1"
    }
  }), _vm._v(" "), _c("col", {
    staticStyle: {
      width: "85%"
    },
    attrs: {
      span: "1"
    }
  })]), _vm._v(" "), _c("tr", [_c("th", {
    staticClass: "text-right pr-10"
  }, [_vm._v("Name")]), _vm._v(" "), _c("td", {
    staticClass: "flex"
  }, [_c("el-select", {
    staticStyle: {
      width: "150px"
    },
    attrs: {
      clearable: "",
      placeholder: "Select",
      size: "mini"
    },
    model: {
      value: _vm.search_features.filters.title_operator,
      callback: function callback($$v) {
        _vm.$set(_vm.search_features.filters, "title_operator", $$v);
      },
      expression: "search_features.filters.title_operator"
    }
  }, [_c("el-option", {
    attrs: {
      label: "EXACT",
      value: "EXACT"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "LIKE",
      value: "LIKE"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "NOT LIKE",
      value: "NOT LIKE"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "EMPTY",
      value: "EMPTY"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "NOT EMPTY",
      value: "NOT EMPTY"
    }
  })], 1), _vm._v(" "), _c("el-input", {
    staticClass: "ml-5",
    attrs: {
      type: "text",
      size: "mini"
    },
    model: {
      value: _vm.search_features.filters.title,
      callback: function callback($$v) {
        _vm.$set(_vm.search_features.filters, "title", $$v);
      },
      expression: "search_features.filters.title"
    }
  })], 1)]), _vm._v(" "), _c("tr", [_c("th", {
    staticClass: "text-right pr-10"
  }, [_vm._v("Description")]), _vm._v(" "), _c("td", {
    staticClass: "flex"
  }, [_c("el-select", {
    staticStyle: {
      width: "150px"
    },
    attrs: {
      clearable: "",
      placeholder: "Select",
      size: "mini"
    },
    model: {
      value: _vm.search_features.filters.description_operator,
      callback: function callback($$v) {
        _vm.$set(_vm.search_features.filters, "description_operator", $$v);
      },
      expression: "search_features.filters.description_operator"
    }
  }, [_c("el-option", {
    attrs: {
      label: "EXACT",
      value: "EXACT"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "LIKE",
      value: "LIKE"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "NOT LIKE",
      value: "NOT LIKE"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "EMPTY",
      value: "EMPTY"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "NOT EMPTY",
      value: "NOT EMPTY"
    }
  })], 1), _vm._v(" "), _c("el-input", {
    staticClass: "ml-5",
    attrs: {
      type: "text",
      size: "mini"
    },
    model: {
      value: _vm.search_features.filters.description,
      callback: function callback($$v) {
        _vm.$set(_vm.search_features.filters, "description", $$v);
      },
      expression: "search_features.filters.description"
    }
  })], 1)]), _vm._v(" "), _c("tr", [_c("th", {
    staticClass: "text-right pr-10"
  }, [_vm._v("Status")]), _vm._v(" "), _c("td", [_c("el-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      clearable: "",
      placeholder: "Select",
      size: "mini",
      multiple: ""
    },
    model: {
      value: _vm.search_features.filters.status,
      callback: function callback($$v) {
        _vm.$set(_vm.search_features.filters, "status", $$v);
      },
      expression: "search_features.filters.status"
    }
  }, [_c("el-option", {
    attrs: {
      label: "Proposed",
      value: "Proposed"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "In progress",
      value: "In progress"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "On hold",
      value: "On hold"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Overdue",
      value: "Overdue"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Achieved",
      value: "Achieved"
    }
  })], 1)], 1)]), _vm._v(" "), _c("tr", [_c("th", {
    staticClass: "text-right pr-10"
  }, [_vm._v("Priority")]), _vm._v(" "), _c("td", [_c("el-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      clearable: "",
      placeholder: "Select",
      size: "mini",
      multiple: ""
    },
    model: {
      value: _vm.search_features.filters.priority,
      callback: function callback($$v) {
        _vm.$set(_vm.search_features.filters, "priority", $$v);
      },
      expression: "search_features.filters.priority"
    }
  }, [_c("el-option", {
    attrs: {
      label: "Highest",
      value: "Highest"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "High",
      value: "High"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Medium",
      value: "Medium"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Low",
      value: "Low"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Lowest",
      value: "Lowest"
    }
  })], 1)], 1)])]), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.filterFeatureList
    }
  }, [_vm._v("Filter")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: _vm.closeFilterDialog
    }
  }, [_vm._v("Cancel")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Add Feature or Process",
      visible: _vm.addFeatureDialog,
      "custom-class": "create-feature-process-d",
      width: "40%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addFeatureDialog = $event;
      }
    }
  }, [_c("div", {
    staticClass: "text-left",
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_c("div", {
    staticStyle: {
      "font-weight": "600",
      "font-size": "1.2em"
    }
  }, [_vm._v("Add Feature or Process")]), _vm._v(" "), _c("Span", {
    staticStyle: {
      "font-size": "0.9em",
      "font-weight": "normal"
    }
  }, [_vm._v("Large requirements made up of stories "), _c("a", {
    staticStyle: {
      color: "gold"
    }
  }, [_vm._v(" @Sastha, dynamic text for Product/Service")])])], 1), _vm._v(" "), _c("el-form", {
    ref: "feature_form",
    attrs: {
      model: _vm.feature_form,
      "label-width": "180px",
      rules: _vm.featureRules
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Product or Service",
      prop: "productId"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "60%"
    },
    attrs: {
      placeholder: "Select Product or Service",
      size: "mini"
    },
    model: {
      value: _vm.feature_form.productId,
      callback: function callback($$v) {
        _vm.$set(_vm.feature_form, "productId", $$v);
      },
      expression: "feature_form.productId"
    }
  }, _vm._l(_vm.offering.linkedProducts, function (product, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: product.product.title,
        value: product.product.id
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-divider"), _vm._v(" "), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "0"
    },
    attrs: {
      label: "Journey Link",
      prop: "stepId"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "60%"
    },
    attrs: {
      placeholder: "Features and Processes should link to a customer journey step",
      size: "mini"
    },
    model: {
      value: _vm.feature_form.stepId,
      callback: function callback($$v) {
        _vm.$set(_vm.feature_form, "stepId", $$v);
      },
      expression: "feature_form.stepId"
    }
  }, _vm._l(_vm.journeyMap.linkedJourneySteps, function (step, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: step.journeyStep.title,
        value: step.journeyStep.id
      }
    });
  }), 1)], 1), _vm._v(" "), !_vm.createOrphan ? _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "0"
    },
    attrs: {
      label: "Enabler"
    }
  }, [_c("el-switch", {
    attrs: {
      "active-color": "navy",
      "inactive-color": "grey",
      "active-text": "",
      "inactive-text": ""
    },
    model: {
      value: _vm.createEnabler,
      callback: function callback($$v) {
        _vm.createEnabler = $$v;
      },
      expression: "createEnabler"
    }
  }), _vm._v(" "), _vm.createEnabler ? _c("span", {
    staticStyle: {
      color: "Navy",
      "font-size": "0.85em"
    }
  }, [_vm._v("Enablers help Features/Services perform their function but themselves don't provide direct customer value.\n        ")]) : _vm._e()], 1) : _vm._e(), _vm._v(" "), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "0"
    },
    attrs: {
      label: "Orphan"
    }
  }, [_c("el-switch", {
    attrs: {
      "active-color": "darkred",
      "inactive-color": "grey",
      "active-text": "",
      "inactive-text": ""
    },
    model: {
      value: _vm.createOrphan,
      callback: function callback($$v) {
        _vm.createOrphan = $$v;
      },
      expression: "createOrphan"
    }
  }), _vm._v(" "), _vm.createOrphan ? _c("span", {
    staticStyle: {
      color: "darkred",
      "font-size": "0.85em"
    }
  }, [_vm._v("An orphan does not trace to a customer need.  Use with caution.\n        ")]) : _vm._e()], 1), _vm._v(" "), _c("el-divider"), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Feature Name",
      prop: "title"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "What's the Feature Name",
      size: "mini"
    },
    model: {
      value: _vm.feature_form.title,
      callback: function callback($$v) {
        _vm.$set(_vm.feature_form, "title", $$v);
      },
      expression: "feature_form.title"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Process Name",
      prop: "title"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "What's the Process Name",
      size: "mini"
    },
    model: {
      value: _vm.feature_form.title,
      callback: function callback($$v) {
        _vm.$set(_vm.feature_form, "title", $$v);
      },
      expression: "feature_form.title"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Status",
      prop: "status"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Select Status",
      size: "mini"
    },
    model: {
      value: _vm.feature_form.status,
      callback: function callback($$v) {
        _vm.$set(_vm.feature_form, "status", $$v);
      },
      expression: "feature_form.status"
    }
  }, _vm._l(_vm.statuses, function (status) {
    return _c("el-option", {
      key: status.value,
      attrs: {
        label: status.label,
        value: status.value
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Description",
      prop: "description"
    }
  }, [_c("el-input", {
    staticStyle: {
      "white-space": "pre-line",
      "font-size": "12px",
      "word-break": "keep-all"
    },
    attrs: {
      rows: 6,
      size: "mini",
      type: "textarea"
    },
    model: {
      value: _vm.feature_form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.feature_form, "description", $$v);
      },
      expression: "feature_form.description"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Priority",
      prop: "priority"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.feature_form.priority,
      callback: function callback($$v) {
        _vm.$set(_vm.feature_form, "priority", $$v);
      },
      expression: "feature_form.priority"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: "Lowest",
      value: "Lowest"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Low",
      value: "Low"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Medium",
      value: "Medium"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "High",
      value: "High"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Highest",
      value: "Highest"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.addFeature
    }
  }, [_vm._v("Create")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.addFeatureDialog = false;
      }
    }
  }, [_vm._v("Cancel")])], 1)], 1), _vm._v(".\n  "), _c("risk-details", {
    attrs: {
      risks: _vm.selectedEntity.risks,
      visible: _vm.displayRiskDialog,
      "entity-id": _vm.selectedEntity.id,
      "entity-name": _vm.selectedEntity.name,
      "entity-title": _vm.selectedEntity.title
    },
    on: {
      delete: _vm.deleteEntityRisk,
      close: function close($event) {
        _vm.displayRiskDialog = false;
      }
    }
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;