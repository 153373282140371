"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.match");
var _axios = _interopRequireDefault(require("axios"));
var _default = exports.default = {
  name: 'ResetPassword',
  data: function data() {
    var _this = this;
    var validatePassword = function validatePassword(rule, value, callback) {
      if (value === '') {
        callback(new Error('Please enter a password'));
      } else if (value.length < 8) {
        callback(new Error('The password can not be less than 8 digits'));
      } else if (!value.match(/[a-z]/) || !value.match(/[A-Z]/)) {
        callback(new Error('The password should have atleast one Uppercase and Lowercase letter'));
      } else if (!value.match(/\d/)) {
        callback(new Error('The password should have atleast one number'));
      } else {
        callback();
      }
    };
    var validateConfirmPassword = function validateConfirmPassword(rule, value, callback) {
      if (value === '') {
        callback(new Error('Please enter a password'));
      } else if (value !== _this.resetForm.password) {
        callback(new Error('The passwords do not match. Please re-enter password'));
      } else {
        callback();
      }
    };
    return {
      reset_loading: false,
      resetPasswordRules: {
        password: [{
          required: true,
          trigger: 'blur',
          validator: validatePassword
        }],
        confirmPassword: [{
          required: true,
          trigger: 'blur',
          validator: validateConfirmPassword
        }]
      },
      capsTooltip: false,
      resetForm: {
        password: '',
        confirmPassword: ''
      },
      password_type: 'password',
      user: {},
      passwordRulesPopover: false,
      passwordRules: [{
        valid: false,
        rule: '8 or more characters'
      }, {
        valid: false,
        rule: 'Upper and lower case letters'
      }, {
        valid: false,
        rule: 'Numbers'
      }]
    };
  },
  watch: {
    'resetForm.password': function resetFormPassword(newValue, oldValue) {
      if (newValue.length > 7) {
        this.passwordRules[0].valid = true;
      } else {
        this.passwordRules[0].valid = false;
      }
      if (newValue.match(/[a-z]/) && newValue.match(/[A-Z]/)) {
        this.passwordRules[1].valid = true;
      } else {
        this.passwordRules[1].valid = false;
      }
      if (newValue.match(/\d/)) {
        this.passwordRules[2].valid = true;
      } else {
        this.passwordRules[2].valid = false;
      }
    }
  },
  mounted: function mounted() {
    // Verify with server
    if (this.$route.query.verification_code) {
      this.getUserDetails();
    }
  },
  created: function created() {},
  destroyed: function destroyed() {},
  methods: {
    goToLoginPage: function goToLoginPage() {
      this.$router.push('login');
    },
    checkCapslock: function checkCapslock() {
      var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        shiftKey = _ref.shiftKey,
        key = _ref.key;
      if (key && key.length === 1) {
        if (shiftKey && key >= 'a' && key <= 'z' || !shiftKey && key >= 'A' && key <= 'Z') {
          this.capsTooltip = true;
        } else {
          this.capsTooltip = false;
        }
      }
      if (key === 'CapsLock' && this.capsTooltip === true) {
        this.capsTooltip = false;
      }
    },
    getUserDetails: function getUserDetails() {
      var _this2 = this;
      this.reset_loading = true;
      var headers = {
        'Content-Type': 'application/json',
        'x-user-id': 'hello',
        'x-party-id': 'hello'
      };
      _axios.default.get(process.env.VUE_APP_REST_API_ENDPOINT + 'user/token/' + this.$route.query.verification_code, {
        headers: headers
      }).then(function (response) {
        _this2.reset_loading = false;
        _this2.user = response.data.data;
      }).catch(function (error) {
        _this2.reset_loading = false;
        _this2.$notify({
          title: 'Error',
          message: error.response.data.data.description,
          type: 'error',
          duration: 2000
        });
      });
    },
    handleReset: function handleReset() {
      var _this3 = this;
      this.$refs.resetForm.validate(function (valid) {
        if (valid) {
          _this3.reset_loading = true;
          var headers = {
            'Content-Type': 'application/json'
          };
          var body = {
            'data': {
              'password': _this3.resetForm.password
            },
            'header': {
              'userId': _this3.user.id,
              'partyId': _this3.user.partyId
            }
          };
          _axios.default.patch(process.env.VUE_APP_REST_API_ENDPOINT + 'user/' + _this3.user.id + '/reset', body, {
            headers: headers
          }).then(function (response) {
            _this3.reset_loading = false;
            if (response.data.status === 'SUCCESS') {
              _this3.$notify({
                title: 'SUCCESS',
                message: 'Password reset success',
                type: 'success',
                duration: 2000
              });
              _this3.$router.push("/login");
            } else {
              _this3.$notify({
                title: 'Error',
                message: response.data.data.description,
                type: 'error',
                duration: 2000
              });
            }
          }).catch(function (error) {
            _this3.reset_loading = false;
            _this3.$notify({
              title: 'Error',
              message: error.message,
              type: 'error',
              duration: 2000
            });
          });
        } else {
          _this3.reset_loading = false;
          _this3.$notify({
            title: 'Error',
            message: 'Password validation failed',
            type: 'error'
          });
        }
      });
    },
    showPwd: function showPwd() {
      var _this4 = this;
      if (this.password_type === 'password') {
        this.password_type = '';
      } else {
        this.password_type = 'password';
      }
      this.$nextTick(function () {
        _this4.$refs.password.focus();
      });
    }
  }
};