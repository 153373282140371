"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _PieChart = _interopRequireDefault(require("./PieChart"));
var _default2 = exports.default = {
  name: 'TaskDetail',
  components: {
    PieChart: _PieChart.default
  },
  props: {
    x: {
      type: Number,
      default: 0
    },
    y: {
      type: Number,
      default: 0
    },
    width: {
      type: Number,
      default: 0
    },
    index: {
      type: Number,
      default: 0
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    step: {
      type: Object,
      default: function _default() {
        return {
          title: '',
          description: '',
          relations: []
        };
      }
    },
    experiences: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    ideas: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      isHover: false,
      gainHover: false,
      painHover: false,
      wowHover: false,
      challengeHover: false,
      experienceChunkSize: 5,
      activeExperienceChunk: 0
    };
  },
  computed: {
    experiencesList: function experiencesList() {
      var experienceArray = [];
      for (var i = 0; i < this.experiences.length; i++) {
        if (this.experiences[i].journeyExperience.relations.step === this.step.id) {
          experienceArray.push(this.experiences[i]);
        }
      }
      return this._.chunk(experienceArray, this.experienceChunkSize);
    },
    computeHeight: function computeHeight() {
      if (this.step && this.step.linkedJourneySubsteps && this.experiencesList[this.activeExperienceChunk]) {
        var sub_step_height = this.step.linkedJourneySubsteps.length * 35;
        var experience_height = this.experiencesList[this.activeExperienceChunk].length * 35;
        return sub_step_height + experience_height + 220;
      } else {
        return 220;
      }
    }
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    generateMarker: function generateMarker() {
      var x_start = this.x - 35;
      var y_start = this.y + 113;
      var x_mid = this.x - 25;
      var y_mid = this.y + 108;
      var x_last = this.x - 25;
      var y_last = this.y + 118;
      return 'M ' + x_start + ' ' + y_start + ' L ' + x_mid + ' ' + y_mid + ' L ' + x_last + ' ' + y_last + '';
    },
    computeCustomerValueIcon: function computeCustomerValueIcon(imp) {
      switch (imp) {
        case 'Very Low':
          return 'low-value';
        case 'Low':
          return 'low-value';
        case 'Medium':
          return 'dollar-1';
        case 'High':
          return 'dollar-2';
        case 'Very High':
          return 'dollar-3';
      }
    },
    computeValueFontSize: function computeValueFontSize(value1) {
      if (value1 === 'Very Low') {
        return 'lowestValue';
      } else if (value1 === 'Low') {
        return 'lowValue';
      }
    },
    closeTaskDetailsPopover: function closeTaskDetailsPopover() {
      this.ishover = false;
      this.$emit('close');
    },
    displayTaskDetails: function displayTaskDetails() {
      this.$emit('details', this.index);
    },
    createSubTask: function createSubTask() {
      this.$emit('create-sub-task', this.index);
    },
    createStepIdea: function createStepIdea() {
      this.$emit('create-step-idea', this.step.title);
    },
    createPain: function createPain() {
      this.$emit('create-pain', this.index);
    },
    createGain: function createGain() {
      this.$emit('create-gain', this.index);
    },
    createWow: function createWow() {
      this.$emit('create-wow', this.index);
    },
    createChallenge: function createChallenge() {
      this.$emit('create-challenge', this.index);
    },
    deleteStep: function deleteStep() {
      this.$emit('delete-step', this.index);
    },
    emitOnMouseOver: function emitOnMouseOver() {
      this.$emit('mouseover', this.index);
    },
    emitOnMouseLeave: function emitOnMouseLeave() {
      this.$emit('mouseleave', this.index);
    },
    truncateString: function truncateString(string, length) {
      return this._.truncate(string, {
        'length': length,
        'separator': '...'
      });
    }
  }
};