"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _viewSegmentCompetitors = _interopRequireDefault(require("./components/monitor_competitors/view-segment-competitors"));
var _compareFeatures = _interopRequireDefault(require("./components/monitor_competitors/compare-features"));
var _competitiveLandscape = _interopRequireDefault(require("./components/monitor_competitors/competitive-landscape"));
var _captureResearch = _interopRequireDefault(require("./components/monitor_competitors/capture-research"));
var _ProcessSteps = _interopRequireDefault(require("@/components/ProcessSteps"));
var _default = exports.default = {
  name: 'MonitorCompetitors',
  components: {
    ViewSegmentCompetitorsComponent: _viewSegmentCompetitors.default,
    CompareFeaturesComponent: _compareFeatures.default,
    CompetitiveLandscapeComponent: _competitiveLandscape.default,
    CaptureResearchComponent: _captureResearch.default,
    ProcessSteps: _ProcessSteps.default
  },
  data: function data() {
    return {
      active_step: 0,
      steps: [{
        name: 'View Segment Competitors',
        shortDescription: 'Identify and create',
        longDescription: 'Identify your competitors, and create competitive feature profiles'
      }, {
        name: 'Compare Features',
        shortDescription: 'Compare features',
        longDescription: 'For each of your products features, see what competitors also offer that feature, and how well they do it.'
      }, {
        name: 'Competitive Landscape',
        shortDescription: '',
        longDescription: ''
      }, {
        name: 'Capture Research',
        shortDescription: '',
        longDescription: ''
      }]
    };
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    updateStep: function updateStep(active_step) {
      this.active_step = active_step;
    }
  }
};