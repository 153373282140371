"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: _vm.featureDetailDialog ? 16 : 24
    }
  }, [_c("div", {
    staticClass: "flex mt-5"
  }, [_c("span", {
    staticClass: "pl-5 pr-5 mt-5"
  }, [_c("strong", [_vm._v("Filters")])]), _vm._v(" "), _c("el-select", {
    staticClass: "pl-5 pr-5",
    attrs: {
      placeholder: "Offering",
      size: "mini"
    },
    model: {
      value: _vm.filter.offering,
      callback: function callback($$v) {
        _vm.$set(_vm.filter, "offering", $$v);
      },
      expression: "filter.offering"
    }
  }, [_c("el-option", {
    attrs: {
      label: "Offering Name",
      value: "Offering Name"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Offering Name 2",
      value: "Offering Name 2"
    }
  })], 1), _vm._v(" "), _c("el-select", {
    staticClass: "pl-5 pr-5",
    attrs: {
      placeholder: "Product",
      size: "mini"
    },
    model: {
      value: _vm.filter.product,
      callback: function callback($$v) {
        _vm.$set(_vm.filter, "product", $$v);
      },
      expression: "filter.product"
    }
  }, [_c("el-option", {
    attrs: {
      label: "Product Name",
      value: "Product Name"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Product Name 2",
      value: "Product Name 2"
    }
  })], 1), _vm._v(" "), _c("el-select", {
    staticClass: "pl-5 pr-5",
    attrs: {
      placeholder: "Released",
      size: "mini"
    },
    model: {
      value: _vm.filter.release,
      callback: function callback($$v) {
        _vm.$set(_vm.filter, "release", $$v);
      },
      expression: "filter.release"
    }
  }, [_c("el-option", {
    attrs: {
      label: "2022 H1",
      value: "2022 H1"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "2022 H2",
      value: "2022 H2"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "2022 Q1",
      value: "2022 Q1"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "2022 Q2",
      value: "2022 Q2"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "2022 Q3",
      value: "2022 Q3"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "2022 Q4",
      value: "2022 Q4"
    }
  })], 1), _vm._v(" "), _c("el-select", {
    staticClass: "pl-5 pr-5",
    attrs: {
      placeholder: "Release Version",
      size: "mini"
    },
    model: {
      value: _vm.filter.version,
      callback: function callback($$v) {
        _vm.$set(_vm.filter, "version", $$v);
      },
      expression: "filter.version"
    }
  }, [_c("el-option", {
    attrs: {
      label: "1.1",
      value: "1.1"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "1.2",
      value: "1.2"
    }
  })], 1), _vm._v(" "), _c("el-select", {
    staticClass: "pl-5 pr-5",
    attrs: {
      placeholder: "Feature Status",
      size: "mini"
    },
    model: {
      value: _vm.filter.status,
      callback: function callback($$v) {
        _vm.$set(_vm.filter, "status", $$v);
      },
      expression: "filter.status"
    }
  }, [_c("el-option", {
    attrs: {
      label: "In Development",
      value: "In Development"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "On Hold",
      value: "On Hold"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Completed",
      value: "Completed"
    }
  })], 1), _vm._v(" "), _c("el-select", {
    staticClass: "pl-5 pr-5",
    attrs: {
      placeholder: "Journey Phase",
      size: "mini"
    },
    model: {
      value: _vm.filter.phase,
      callback: function callback($$v) {
        _vm.$set(_vm.filter, "phase", $$v);
      },
      expression: "filter.phase"
    }
  }, [_c("el-option", {
    attrs: {
      label: "Phase 1",
      value: "Phase 1"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Phase 2",
      value: "Phase 2"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Phase 3",
      value: "Phase 3"
    }
  })], 1), _vm._v(" "), _c("el-select", {
    staticClass: "pl-5 pr-5",
    attrs: {
      placeholder: "Enabler",
      size: "mini"
    },
    model: {
      value: _vm.filter.enabler,
      callback: function callback($$v) {
        _vm.$set(_vm.filter, "enabler", $$v);
      },
      expression: "filter.enabler"
    }
  }, [_c("el-option", {
    attrs: {
      label: "Enabled",
      value: "true"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Disabled",
      value: "false"
    }
  })], 1), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini",
      icon: "el-icon-refresh-left"
    },
    on: {
      click: function click($event) {
        return _vm.resetFilter();
      }
    }
  }, [_vm._v("Reset filter")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini",
      icon: "el-icon-refresh-left"
    },
    on: {
      click: function click($event) {
        _vm.landscapeViewDialog = true;
      }
    }
  }, [_vm._v("Reports")])], 1), _vm._v(" "), _vm.filteredFeatures.length > 0 ? _c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.filteredFeatures,
      stripe: "",
      size: "mini"
    },
    on: {
      "row-click": _vm.selectFeature
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "title",
      label: "Title",
      width: "150"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Release",
      width: "150"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return _vm._l(scope.row.releases, function (release, index) {
          return _c("el-tag", {
            key: index,
            attrs: {
              size: "mini",
              type: "primary"
            }
          }, [_vm._v(_vm._s(release))]);
        });
      }
    }], null, false, 2545940757)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "version",
      label: "Version",
      width: "150"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Enabler",
      width: "150"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.enabler ? _c("span", [_vm._v("Yes")]) : _c("span", [_vm._v("No")])];
      }
    }], null, false, 3330639393)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Competitors"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", {
          staticClass: "flex competitor-tag-list"
        }, _vm._l(scope.row.competitor_features, function (competitor, index) {
          return _c("el-card", {
            key: index,
            style: _vm.computeStyle(competitor.category),
            attrs: {
              "body-style": {
                height: "30px",
                "padding-top": "0px"
              },
              shadow: "hover"
            }
          }, [_c("el-row", {
            attrs: {
              gutter: 20
            }
          }, [_c("el-col", {
            attrs: {
              span: 18
            }
          }, [_c("div", {
            on: {
              click: function click($event) {
                $event.preventDefault();
                $event.stopPropagation();
                return _vm.openCompetitorDialog();
              }
            }
          }, [_vm._v(_vm._s(competitor.competitor.title) + " "), _c("small", [_c("i", [_vm._v(_vm._s(competitor.category))])])])]), _vm._v(" "), _c("el-col", {
            attrs: {
              span: 6
            }
          }, [_c("div", {
            staticClass: "box"
          }, [_c("div", {
            class: _vm.computeHighThreatStyle(competitor.threat)
          }), _vm._v(" "), _c("div", {
            class: _vm.computeMediumThreatStyle(competitor.threat)
          }), _vm._v(" "), _c("div", {
            class: _vm.computeLowThreatStyle(competitor.threat)
          })])])], 1)], 1);
        }), 1)];
      }
    }], null, false, 2593702329)
  })], 1) : _c("el-empty", {
    attrs: {
      description: "No data found"
    }
  })], 1), _vm._v(" "), _c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.featureDetailDialog ? _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-collapse", {
    attrs: {
      id: "competitor_landscape_drawer",
      value: "our_feature"
    }
  }, [_c("el-collapse-item", {
    attrs: {
      name: "our_feature"
    }
  }, [_c("template", {
    slot: "title"
  }, [_c("el-row", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 16
    }
  }, [_vm._v("Our Feature")]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-button", {
    staticClass: "text-right",
    attrs: {
      type: "secondary",
      size: "micro",
      icon: "el-icon-close"
    },
    on: {
      click: function click($event) {
        _vm.featureDetailDialog = false;
      }
    }
  }, [_vm._v("Close Sidebar")])], 1)], 1)], 1), _vm._v(" "), _vm.selectedFeature ? _c("table", {
    staticClass: "competitor-landscape-table",
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      width: "100%"
    }
  }, [_c("colgroup", [_c("col", {
    staticStyle: {
      width: "30%"
    },
    attrs: {
      span: "1"
    }
  }), _vm._v(" "), _c("col", {
    staticStyle: {
      width: "70%"
    },
    attrs: {
      span: "1"
    }
  })]), _vm._v(" "), _c("tr", [_c("th", [_vm._v("Feature Name")]), _vm._v(" "), _c("td", [_vm._v(_vm._s(_vm.selectedFeature.title))])]), _vm._v(" "), _c("tr", [_c("th", [_vm._v("Customer Type")]), _vm._v(" "), _c("td", _vm._l(_vm.selectedFeature.customer_types, function (customer_type, index) {
    return _c("el-tag", {
      key: index,
      staticClass: "mr-5",
      attrs: {
        size: "mini"
      }
    }, [_vm._v(_vm._s(customer_type.title))]);
  }), 1)]), _vm._v(" "), _c("tr", [_c("th", [_vm._v("Phase")]), _vm._v(" "), _c("td", _vm._l(_vm.selectedFeature.phases, function (phase, index) {
    return _c("el-tag", {
      key: index,
      staticClass: "mr-5",
      attrs: {
        size: "mini"
      }
    }, [_vm._v(_vm._s(phase.title))]);
  }), 1)]), _vm._v(" "), _c("tr", [_c("th", [_vm._v("Step")]), _vm._v(" "), _c("td", _vm._l(_vm.selectedFeature.steps, function (step, index) {
    return _c("el-tag", {
      key: index,
      staticClass: "mr-5",
      attrs: {
        size: "mini"
      }
    }, [_vm._v(_vm._s(step.title))]);
  }), 1)]), _vm._v(" "), _c("tr", [_c("th", [_vm._v("Offering")]), _vm._v(" "), _c("td", [_vm._v(_vm._s(_vm.selectedFeature.offering.title))])]), _vm._v(" "), _vm.selectedFeature.product.id ? _c("tr", [_c("th", [_vm._v("Product")]), _vm._v(" "), _c("td", [_vm._v(_vm._s(_vm.selectedFeature.product.title))])]) : _vm._e(), _vm._v(" "), _vm.selectedFeature.service && _vm.selectedFeature.service.id ? _c("tr", [_c("th", [_vm._v("Service")]), _vm._v(" "), _c("td", [_vm._v(_vm._s(_vm.selectedFeature.service.title))])]) : _vm._e(), _vm._v(" "), _c("tr", [_c("th", [_vm._v("Status")]), _vm._v(" "), _c("td", [_vm._v(_vm._s(_vm.selectedFeature.status))])]), _vm._v(" "), _c("tr", [_c("th", [_vm._v("Version")]), _vm._v(" "), _c("td", [_vm._v(_vm._s(_vm.selectedFeature.version))])]), _vm._v(" "), _c("tr", [_c("th", [_vm._v("Releases")]), _vm._v(" "), _c("td", _vm._l(_vm.selectedFeature.releases, function (release, index) {
    return _c("el-tag", {
      key: index,
      staticClass: "mr-5",
      attrs: {
        size: "mini"
      }
    }, [_vm._v(_vm._s(release))]);
  }), 1)]), _vm._v(" "), _c("tr", [_c("th", [_vm._v("Enabler")]), _vm._v(" "), _c("td", [_c("el-checkbox", {
    attrs: {
      disabled: ""
    },
    model: {
      value: _vm.selectedFeature.enabler,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedFeature, "enabler", $$v);
      },
      expression: "selectedFeature.enabler"
    }
  }, [_vm.selectedFeature.enabler === "true" ? _c("span", [_vm._v("Enabled")]) : _c("span", [_vm._v("Disabled")])])], 1)])]) : _c("el-empty")], 2), _vm._v(" "), _c("el-collapse-item", {
    attrs: {
      title: "Feature competitive profile",
      name: "feature_competitive_profile"
    }
  }, [_c("el-table", {
    attrs: {
      data: _vm.selectedFeature.competitor_features,
      stripe: "",
      size: "mini"
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Competitor"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.edit ? _c("el-input", {
          attrs: {
            type: "text",
            size: "mini"
          },
          model: {
            value: scope.row.competitor.title,
            callback: function callback($$v) {
              _vm.$set(scope.row.competitor, "title", $$v);
            },
            expression: "scope.row.competitor.title"
          }
        }) : _c("span", [_vm._v(_vm._s(scope.row.competitor.title))])];
      }
    }], null, false, 485021136)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Maturity"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.edit ? _c("el-select", {
          attrs: {
            placeholder: "Maturity",
            size: "mini"
          },
          model: {
            value: scope.row.maturity,
            callback: function callback($$v) {
              _vm.$set(scope.row, "maturity", $$v);
            },
            expression: "scope.row.maturity"
          }
        }, [_c("el-option", {
          attrs: {
            label: "POC",
            value: "POC"
          }
        }), _vm._v(" "), _c("el-option", {
          attrs: {
            label: "MVP",
            value: "MVP"
          }
        }), _vm._v(" "), _c("el-option", {
          attrs: {
            label: "Researching",
            value: "Researching"
          }
        }), _vm._v(" "), _c("el-option", {
          attrs: {
            label: "Mature",
            value: "Mature"
          }
        })], 1) : _c("span", [_vm._v(_vm._s(scope.row.maturity))])];
      }
    }], null, false, 266283020)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Category"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.edit ? _c("el-select", {
          attrs: {
            placeholder: "Category",
            size: "mini"
          },
          model: {
            value: scope.row.category,
            callback: function callback($$v) {
              _vm.$set(scope.row, "category", $$v);
            },
            expression: "scope.row.category"
          }
        }, [_c("el-option", {
          attrs: {
            label: "Direct",
            value: "Direct"
          }
        }), _vm._v(" "), _c("el-option", {
          attrs: {
            label: "Near",
            value: "Near"
          }
        }), _vm._v(" "), _c("el-option", {
          attrs: {
            label: "Peripheral",
            value: "Peripheral"
          }
        })], 1) : _c("span", [_vm._v(_vm._s(scope.row.category))])];
      }
    }], null, false, 1166137058)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Threat"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.edit ? _c("el-select", {
          attrs: {
            placeholder: "Threat",
            size: "mini"
          },
          model: {
            value: scope.row.threat,
            callback: function callback($$v) {
              _vm.$set(scope.row, "threat", $$v);
            },
            expression: "scope.row.threat"
          }
        }, [_c("el-option", {
          attrs: {
            label: "High",
            value: "High"
          }
        }), _vm._v(" "), _c("el-option", {
          attrs: {
            label: "Medium",
            value: "Medium"
          }
        }), _vm._v(" "), _c("el-option", {
          attrs: {
            label: "Low",
            value: "Low"
          }
        }), _vm._v(" "), _c("el-option", {
          attrs: {
            label: "Very Low",
            value: "Very Low"
          }
        })], 1) : _c("span", [_vm._v(_vm._s(scope.row.threat))])];
      }
    }], null, false, 721582057)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "#"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [!scope.row.edit ? _c("el-button", {
          attrs: {
            type: "secondary",
            size: "micro",
            icon: "el-icon-edit"
          },
          on: {
            click: function click($event) {
              scope.row.edit = true;
            }
          }
        }) : _c("el-button", {
          attrs: {
            type: "primary",
            size: "micro",
            icon: "el-icon-check"
          },
          on: {
            click: function click($event) {
              scope.row.edit = false;
            }
          }
        })];
      }
    }], null, false, 1923868198)
  }, [_c("template", {
    slot: "header"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.openAddCompetitorFeatureDialog();
      }
    }
  }, [_vm._v("Add Competitor Feature")])], 1)], 2)], 1)], 1)], 1)], 1) : _vm._e()], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Landscape View",
      visible: _vm.landscapeViewDialog,
      width: "80%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.landscapeViewDialog = $event;
      }
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-card", [_c("div", {
    staticClass: "text-center"
  }, [_c("el-select", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.selectedOfferingId,
      callback: function callback($$v) {
        _vm.selectedOfferingId = $$v;
      },
      expression: "selectedOfferingId"
    }
  }, _vm._l(_vm.offerings, function (offering, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: offering.title,
        value: offering.id
      }
    });
  }), 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "text-center"
  }, [_c("svg", {
    staticStyle: {
      "user-select": "none"
    },
    attrs: {
      height: "500",
      width: "100%",
      xmlns: "http://www.w3.org/2000/svg",
      version: "1.1"
    }
  }, [_c("g", {
    attrs: {
      refs: "peripheral"
    }
  }, [_c("circle", {
    attrs: {
      cx: "350",
      cy: "250",
      r: "150",
      stroke: "lightgrey",
      "stroke-width": "1",
      fill: "white"
    }
  }), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "#000"
    },
    attrs: {
      x: 350,
      y: 120,
      "font-size": "1.0em",
      "text-anchor": "middle"
    }
  }, [_vm._v("\n                  Peripheral\n                ")])]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "near"
    }
  }, [_c("circle", {
    attrs: {
      cx: "350",
      cy: "250",
      r: "100",
      stroke: "lightgrey",
      "stroke-width": "1",
      fill: "white"
    }
  }), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "#000"
    },
    attrs: {
      x: 350,
      y: 170,
      "font-size": "1.0em",
      "text-anchor": "middle"
    }
  }, [_vm._v("\n                  Near\n                ")])]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "direct"
    }
  }, [_c("circle", {
    attrs: {
      cx: "350",
      cy: "250",
      r: "50",
      stroke: "lightgrey",
      "stroke-width": "1",
      fill: "white"
    }
  }), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "#000"
    },
    attrs: {
      x: 350,
      y: 220,
      "font-size": "1.0em",
      "text-anchor": "middle"
    }
  }, [_vm._v("\n                  Direct\n                ")])]), _vm._v(" "), _vm._l(_vm.selectedCompetitorLandscapeOffering.features, function (feature, index) {
    return _c("g", {
      key: index,
      attrs: {
        refs: "feature_points"
      }
    }, [_c("transition", {
      attrs: {
        name: "fade"
      }
    }, [feature.active ? _c("g", [_c("rect", {
      attrs: {
        x: feature.x - 8,
        y: feature.y - 8,
        width: 120,
        height: 16,
        rx: "5",
        fill: "#F4F4F4",
        stroke: "lightgrey",
        "stroke-width": "1"
      }
    }), _vm._v(" "), _c("text", {
      staticStyle: {
        fill: "#000"
      },
      attrs: {
        x: feature.x - 8 + 60,
        y: feature.y + 4,
        "font-size": "0.8em",
        "text-anchor": "middle"
      }
    }, [_vm._v("\n                      " + _vm._s(_vm.truncateString(feature.title, 20)) + "\n                    ")])]) : _vm._e()]), _vm._v(" "), _c("circle", {
      attrs: {
        cx: feature.x,
        cy: feature.y,
        stroke: feature.active ? "red" : "none",
        "stroke-width": feature.active ? 1 : 0,
        r: feature.active ? 8 : 6,
        fill: "red"
      },
      on: {
        mouseover: function mouseover($event) {
          feature.active = true;
        },
        mouseleave: function mouseleave($event) {
          feature.active = false;
        }
      }
    })], 1);
  })], 2)])])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-card", [_c("div", {
    staticClass: "text-center"
  }, [_c("el-select", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.selectedFeatureId,
      callback: function callback($$v) {
        _vm.selectedFeatureId = $$v;
      },
      expression: "selectedFeatureId"
    }
  }, _vm._l(_vm.features, function (feature, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: feature.title,
        value: feature.id
      }
    });
  }), 1)], 1), _vm._v(" "), _c("svg", {
    staticStyle: {
      "user-select": "none"
    },
    attrs: {
      height: "500",
      width: "100%",
      xmlns: "http://www.w3.org/2000/svg",
      version: "1.1"
    }
  }, [_c("g", {
    attrs: {
      refs: "peripheral"
    }
  }, [_c("circle", {
    attrs: {
      cx: "350",
      cy: "250",
      r: "150",
      stroke: "lightgrey",
      "stroke-width": "1",
      fill: "white"
    }
  }), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "#000"
    },
    attrs: {
      x: 350,
      y: 120,
      "font-size": "1.0em",
      "text-anchor": "middle"
    }
  }, [_vm._v("\n                Peripheral\n              ")])]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "near"
    }
  }, [_c("circle", {
    attrs: {
      cx: "350",
      cy: "250",
      r: "100",
      stroke: "lightgrey",
      "stroke-width": "1",
      fill: "white"
    }
  }), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "#000"
    },
    attrs: {
      x: 350,
      y: 170,
      "font-size": "1.0em",
      "text-anchor": "middle"
    }
  }, [_vm._v("\n                Near\n              ")])]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "direct"
    }
  }, [_c("circle", {
    attrs: {
      cx: "350",
      cy: "250",
      r: "50",
      stroke: "lightgrey",
      "stroke-width": "1",
      fill: "white"
    }
  }), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "#000"
    },
    attrs: {
      x: 350,
      y: 220,
      "font-size": "1.0em",
      "text-anchor": "middle"
    }
  }, [_vm._v("\n                Direct\n              ")])]), _vm._v(" "), _vm._l(_vm.selectedCompetitorLandscapeFeature.competitor_features, function (competitor, index) {
    return _c("g", {
      key: index,
      attrs: {
        refs: "competitor_points"
      }
    }, [_c("transition", {
      attrs: {
        name: "fade"
      }
    }, [competitor.active ? _c("g", [_c("rect", {
      attrs: {
        x: competitor.x - 8,
        y: competitor.y - 8,
        width: 120,
        height: 16,
        rx: "5",
        fill: "#F4F4F4",
        stroke: "lightgrey",
        "stroke-width": "1"
      }
    }), _vm._v(" "), _c("text", {
      staticStyle: {
        fill: "#000"
      },
      attrs: {
        x: competitor.x - 8 + 60,
        y: competitor.y + 4,
        "font-size": "0.8em",
        "text-anchor": "middle"
      }
    }, [_vm._v("\n                    " + _vm._s(_vm.truncateString(competitor.competitor.title, 20)) + "\n                  ")])]) : _vm._e()]), _vm._v(" "), _c("circle", {
      attrs: {
        cx: competitor.x,
        cy: competitor.y,
        stroke: competitor.active ? "red" : "none",
        "stroke-width": competitor.active ? 1 : 0,
        r: competitor.active ? 8 : 6,
        fill: "red"
      },
      on: {
        mouseover: function mouseover($event) {
          competitor.active = true;
        },
        mouseleave: function mouseleave($event) {
          competitor.active = false;
        }
      }
    })], 1);
  })], 2)])], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.landscapeViewDialog = false;
      }
    }
  }, [_vm._v("Cancel")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Add competitor feature",
      visible: _vm.addCompetitorFeatureDialog,
      width: "30%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addCompetitorFeatureDialog = $event;
      }
    }
  }, [_c("div", {
    staticClass: "text-right"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      icon: "el-icon-plus",
      size: "micro"
    },
    on: {
      click: function click($event) {
        return _vm.openAddCompetitorDialog();
      }
    }
  }, [_vm._v("Add Competitor")])], 1), _vm._v(" "), _c("el-form", {
    ref: "competitor_feature_form",
    attrs: {
      model: _vm.competitor_feature_form,
      "label-width": "100px",
      rules: _vm.competitorFeatureRules
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Competitor",
      prop: "title"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Competitor",
      size: "mini"
    },
    model: {
      value: _vm.competitor_feature_form.competitor.title,
      callback: function callback($$v) {
        _vm.$set(_vm.competitor_feature_form.competitor, "title", $$v);
      },
      expression: "competitor_feature_form.competitor.title"
    }
  }, _vm._l(_vm.competitors, function (competitor, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: competitor.title,
        value: competitor.title
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Feature",
      prop: "feature"
    }
  }, [_c("el-input", {
    attrs: {
      type: "text",
      size: "mini",
      disabled: ""
    },
    model: {
      value: _vm.competitor_feature_form.feature,
      callback: function callback($$v) {
        _vm.$set(_vm.competitor_feature_form, "feature", $$v);
      },
      expression: "competitor_feature_form.feature"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Maturity",
      prop: "maturity"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Maturity",
      size: "mini"
    },
    model: {
      value: _vm.competitor_feature_form.maturity,
      callback: function callback($$v) {
        _vm.$set(_vm.competitor_feature_form, "maturity", $$v);
      },
      expression: "competitor_feature_form.maturity"
    }
  }, [_c("el-option", {
    attrs: {
      label: "Researching",
      value: "Researching"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "POC",
      value: "POC"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "MVP",
      value: "MVP"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Mature",
      value: "Mature"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Retirement",
      value: "Retirement"
    }
  })], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Category",
      prop: "category"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Category",
      size: "mini"
    },
    model: {
      value: _vm.competitor_feature_form.category,
      callback: function callback($$v) {
        _vm.$set(_vm.competitor_feature_form, "category", $$v);
      },
      expression: "competitor_feature_form.category"
    }
  }, [_c("el-option", {
    attrs: {
      label: "Direct",
      value: "Direct"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Near",
      value: "Near"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Peripheral",
      value: "Peripheral"
    }
  })], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Threat",
      prop: "threat"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Threat",
      size: "mini"
    },
    model: {
      value: _vm.competitor_feature_form.threat,
      callback: function callback($$v) {
        _vm.$set(_vm.competitor_feature_form, "threat", $$v);
      },
      expression: "competitor_feature_form.threat"
    }
  }, [_c("el-option", {
    attrs: {
      label: "High",
      value: "High"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Medium",
      value: "Medium"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Low",
      value: "Low"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Very Low",
      value: "Very Low"
    }
  })], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Strength",
      prop: "strength"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Threat",
      size: "mini"
    },
    model: {
      value: _vm.competitor_feature_form.strength,
      callback: function callback($$v) {
        _vm.$set(_vm.competitor_feature_form, "strength", $$v);
      },
      expression: "competitor_feature_form.strength"
    }
  }, [_c("el-option", {
    attrs: {
      label: "Weak",
      value: "Weak"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Fair",
      value: "Fair"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Strong",
      value: "Strong"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Very Strong",
      value: "Very Strong"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.addCompetitorFeatureDialog = false;
      }
    }
  }, [_vm._v("Cancel")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.addCompetitorFeature
    }
  }, [_vm._v("Create")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Add competitor",
      visible: _vm.addCompetitorDialog,
      width: "70%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addCompetitorDialog = $event;
      }
    }
  }, [_c("el-form", {
    ref: "competitor_form",
    attrs: {
      model: _vm.competitor_form,
      "label-width": "200px",
      rules: _vm.competitorRules
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Name",
      prop: "title"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.competitor_form.title,
      callback: function callback($$v) {
        _vm.$set(_vm.competitor_form, "title", $$v);
      },
      expression: "competitor_form.title"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Head Office",
      prop: "head_office"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.competitor_form.head_office,
      callback: function callback($$v) {
        _vm.$set(_vm.competitor_form, "head_office", $$v);
      },
      expression: "competitor_form.head_office"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Category",
      prop: "category"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.competitor_form.category,
      callback: function callback($$v) {
        _vm.$set(_vm.competitor_form, "category", $$v);
      },
      expression: "competitor_form.category"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: "Direct"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Close"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Peripheral"
    }
  })], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Threat",
      prop: "risk"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.competitor_form.threat,
      callback: function callback($$v) {
        _vm.$set(_vm.competitor_form, "threat", $$v);
      },
      expression: "competitor_form.threat"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: "Low"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Medium"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "High"
    }
  })], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Active",
      prop: "active"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.competitor_form.active,
      callback: function callback($$v) {
        _vm.$set(_vm.competitor_form, "active", $$v);
      },
      expression: "competitor_form.active"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: true
    }
  }, [_vm._v("Yes")]), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: false
    }
  }, [_vm._v("No")])], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Acquired",
      prop: "acquired"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.competitor_form.acquired,
      callback: function callback($$v) {
        _vm.$set(_vm.competitor_form, "acquired", $$v);
      },
      expression: "competitor_form.acquired"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: true
    }
  }, [_vm._v("Yes")]), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: false
    }
  }, [_vm._v("No")])], 1)], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Strength",
      prop: "strength"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      size: "mini"
    },
    model: {
      value: _vm.competitor_form.strength,
      callback: function callback($$v) {
        _vm.$set(_vm.competitor_form, "strength", $$v);
      },
      expression: "competitor_form.strength"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "My Strength",
      prop: "my_strength"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      size: "mini"
    },
    model: {
      value: _vm.competitor_form.my_strength,
      callback: function callback($$v) {
        _vm.$set(_vm.competitor_form, "my_strength", $$v);
      },
      expression: "competitor_form.my_strength"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Users",
      prop: "users"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.competitor_form.users,
      callback: function callback($$v) {
        _vm.$set(_vm.competitor_form, "users", $$v);
      },
      expression: "competitor_form.users"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Segments",
      prop: "segment"
    }
  }, [_c("el-select", {
    attrs: {
      multiple: "",
      filterable: "",
      "allow-create": "",
      "default-first-option": "",
      placeholder: "Segment",
      size: "mini"
    },
    model: {
      value: _vm.competitor_form.segments,
      callback: function callback($$v) {
        _vm.$set(_vm.competitor_form, "segments", $$v);
      },
      expression: "competitor_form.segments"
    }
  }, [_c("el-option", {
    attrs: {
      label: "label1",
      value: "label1"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "label2",
      value: "label2"
    }
  })], 1)], 1), _vm._v(" "), _vm.competitor_form.acquired ? _c("el-form-item", {
    attrs: {
      label: "Date Acquired"
    }
  }, [_c("el-input", {
    attrs: {
      type: "date",
      size: "mini"
    },
    model: {
      value: _vm.competitor_form.date,
      callback: function callback($$v) {
        _vm.$set(_vm.competitor_form, "date", $$v);
      },
      expression: "competitor_form.date"
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.competitor_form.acquired ? _c("el-form-item", {
    attrs: {
      label: "Acquisition Details"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      size: "mini"
    },
    model: {
      value: _vm.competitor_form.acquitision_details,
      callback: function callback($$v) {
        _vm.$set(_vm.competitor_form, "acquitision_details", $$v);
      },
      expression: "competitor_form.acquitision_details"
    }
  })], 1) : _vm._e()], 1)], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.addCompetitorDialog = false;
      }
    }
  }, [_vm._v("Cancel")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.addCompetitor
    }
  }, [_vm._v("Create")])], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;