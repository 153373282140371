"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _axios = _interopRequireDefault(require("axios"));
var _default2 = exports.default = {
  name: 'OfferingExternalIntegration',
  components: {},
  props: {
    user: {
      type: Object,
      default: function _default() {
        return {
          first_name: '',
          last_name: '',
          emailAddress: '',
          businessRole: '',
          avatarColor: '#ff4081'
        };
      }
    }
  },
  data: function data() {
    return {
      loading: false,
      integrations: [],
      selectedTenancy: {},
      targetProjectName: '',
      tenancyName: '',
      integration_form: {
        tenant: '',
        tenancy: '',
        description: ''
      }
    };
  },
  computed: {
    activeIntegrations: function activeIntegrations() {
      return this.integrations.filter(function (integration) {
        return integration.isDeleted === false && integration.enabled === true;
      });
    }
  },
  mounted: function mounted() {
    this.retrieveIntegrations();
  },
  methods: {
    handleTenancySelectionChange: function handleTenancySelectionChange(tenancy_name) {
      for (var i = 0; i < this.integrations.length; i++) {
        if (this.integrations[i].appName === tenancy_name) {
          this.selectedTenancy = this.integrations[i];
          this.selectedTenancy.projects = [];
          this.selectedTenancy.enabled = false;
          this.loadProjects(this.selectedTenancy, i);
          this.isEnabled(this.selectedTenancy.id, i);
        }
      }
    },
    closeUI: function closeUI() {
      this.$emit('close');
    },
    updateIntegration: function updateIntegration() {
      for (var j = 0; j < this.selectedTenancy.projects.length; j++) {
        if (this.selectedTenancy.projects[j].id === this.targetProjectName) {
          this.updateTenancyIntegration(this.selectedTenancy, this.selectedTenancy.projects[j]);
        }
      }
    },
    updateTenancyIntegration: function updateTenancyIntegration(integration, project) {
      var _this = this;
      for (var i = 0; i < this.filteredProjectsByImport(integration.projects).length; i++) {
        this.loading = true;
        var headers = {
          'Content-Type': 'application/json'
        };
        var body = {
          'data': {
            'targetProjectId': project.id,
            'targetProjectName': project.name
          },
          'header': {
            'userId': this.$store.getters.id,
            'partyId': this.$store.getters.partyId
          }
        };
        _axios.default.patch(process.env.VUE_APP_REST_API_ENDPOINT + 'tprty/' + integration.id + '/project/' + this.filteredProjectsByImport(integration.projects)[i].id, body, {
          headers: headers
        }).then(function (response) {
          _this.loading = false;
          if (response.data.status === 'SUCCESS') {
            console.log('updated');
          }
        }).catch(function (error) {
          _this.loading = false;
          _this.$notify({
            title: 'Error',
            message: error,
            type: 'error',
            duration: 2000
          });
        });
      }
    },
    handleProjectSelectionChange: function handleProjectSelectionChange(val) {
      for (var i = 0; i < this.integrations.length; i++) {
        for (var j = 0; j < this.integrations[i].projects.length; j++) {
          if (this.integrations[i].projects[j].id === val) {
            this.updateTenancyIntegration(this.integrations[i], this.integrations[i].projects[j]);
          }
        }
      }
    },
    filteredProjectsByImport: function filteredProjectsByImport(projects) {
      var filtered_projects = [];
      for (var i = 0; i < projects.length; i++) {
        if (projects[i].isImported || projects[i].isLocalProject) {
          filtered_projects.push(projects[i]);
        }
      }
      return filtered_projects;
    },
    loadProjects: function loadProjects(tenancy, index) {
      var _this2 = this;
      this.loading = true;
      var headers = {
        'Content-Type': 'application/json',
        'x-party-id': this.$store.getters.partyId,
        'x-user-id': this.$store.getters.id
      };
      _axios.default.get(process.env.VUE_APP_REST_API_ENDPOINT + 'tprty/' + tenancy.id + '/project', {
        headers: headers
      }).then(function (response) {
        _this2.loading = false;
        if (response.data.status === 'SUCCESS') {
          _this2.selectedTenancy.projects = response.data.data;
          _this2.computeTragetProjectName(_this2.integrations[index].projects);
          _this2.$nextTick();
          _this2.$forceUpdate();
        }
      }).catch(function (error) {
        _this2.loading = false;
        console.log(error);
      });
    },
    computeTragetProjectName: function computeTragetProjectName(projects) {
      for (var i = 0; i < projects.length; i++) {
        if (projects[i].targetProjectName) {
          this.targetProjectName = projects[i].targetProjectName;
        }
      }
    },
    retrieveIntegrations: function retrieveIntegrations() {
      var _this3 = this;
      this.loading = true;
      var headers = {
        'Content-Type': 'application/json',
        'x-party-id': this.$store.getters.partyId,
        'x-user-id': this.$store.getters.id
      };
      _axios.default.get(process.env.VUE_APP_REST_API_ENDPOINT + 'tprty/setup?platform=true&extended=LEVEL0', {
        headers: headers
      }).then(function (response) {
        _this3.loading = false;
        _this3.integrations = [];
        if (response.data.status === 'SUCCESS') {
          _this3.integrations = response.data.data;
          for (var i = 0; i < _this3.integrations.length; i++) {
            _this3.integrations[i].projects = [];
            _this3.integrations[i].enabled = false;
            _this3.loadProjects(_this3.integrations[i], i);
            _this3.isEnabled(_this3.integrations[i].id, i);
          }
          // console.log(this.integrations)
        } else {
          _this3.loading = false;
          _this3.$notify({
            title: 'Error',
            message: response.data.data.description,
            type: 'error',
            duration: 2000
          });
        }
      }).catch(function (error) {
        _this3.integrations = [];
        _this3.loading = false;
        console.log(error);
      });
    },
    isEnabled: function isEnabled(integration_id, integration_index) {
      var _this4 = this;
      this.loading = true;
      var headers = {
        'Content-Type': 'application/json',
        'x-party-id': this.$store.getters.partyId,
        'x-user-id': this.$store.getters.id
      };
      _axios.default.get(process.env.VUE_APP_REST_API_ENDPOINT + 'tprty/' + integration_id + '/auth/isenabled', {
        headers: headers
      }).then(function (response) {
        _this4.loading = false;
        if (response.data.status === 'SUCCESS') {
          _this4.selectedTenancy.enabled = response.data.data.enabled;
          _this4.selectedTenancy.isTokenActive = response.data.data.isTokenActive;
          _this4.selectedTenancy.isSync = false;
          _this4.$nextTick();
          _this4.$forceUpdate();
          // this.enabledStatus = response.data.data
        }
      }).catch(function (error) {
        console.log(error);
        _this4.loading = false;
      });
    }
  }
};