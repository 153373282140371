"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/taggedTemplateLiteral.js"));
var _objectSpread2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _vuex = require("vuex");
var _templateObject;
var _default = exports.default = {
  name: 'RelatedOffering',
  components: {},
  props: {
    offering: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      loading: false,
      related_customer_types: []
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['offeringId'])),
  mounted: function mounted() {
    this.retrieveOfferings();
  },
  methods: {
    computeHumanReadableFormat: function computeHumanReadableFormat(date) {
      return this.$moment(date, 'YYYY-MM-DD HH:mm:ss').format('ll');
    },
    retrieveOfferings: function retrieveOfferings() {
      var _this = this;
      this.loading = true;
      this.related_customer_types = [];
      this.$apollo.query({
        // Query
        query: window._gql(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["query Offering($id: String, $customertypewhere: CustomerTypesCriteriaExpression)\n        {\n        Offering(id: $id)\n        {\n          id\n          title\n          description\n          offeringtype\n          offeringcategory\n          linkedCustomerTypes(optional: true) {\n            customerType(optional: true, where: $customertypewhere){\n              id\n              title\n              description\n              createdat\n              createdby\n            }\n          }\n        }\n      }"]))),
        fetchPolicy: 'no-cache',
        variables: {
          'id': this.offering.id,
          'customertypewhere': this.computeCustomerTypeWhereQuery()
        }
      }).then(function (data) {
        _this.$notify({
          title: 'Success',
          message: 'Retrieved Successfully',
          type: 'success',
          duration: 2000
        });
        _this.related_customer_types = data.data.Offering.linkedCustomerTypes;
        _this.loading = false;
      }).catch(function (error) {
        // Error
        _this.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this.loading = false;
      });
    },
    computeCustomerTypeWhereQuery: function computeCustomerTypeWhereQuery() {
      var query = {};
      query.id = {
        'LIKE': this.offering.linkedCustomerTypes[0].customerType.id
      };
      return query;
    }
  }
};