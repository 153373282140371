"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.number.constructor");
var _default2 = exports.default = {
  name: 'RoadmapSprintComponent',
  props: {
    x: {
      type: Number,
      required: true,
      default: 0
    },
    y: {
      type: Number,
      required: true,
      default: 0
    },
    width: {
      type: Number,
      required: true,
      default: 0
    },
    index: {
      type: Number,
      required: true,
      default: 0
    },
    sprint: {
      type: Object,
      default: function _default() {
        return {
          name: '',
          description: '',
          release: '',
          track: '',
          goal: '',
          mode: '',
          is_open: true,
          stage: '',
          range: [],
          daterange: {
            start: '',
            end: ''
          },
          features: [],
          enablers: []
        };
      }
    }
  },
  data: function data() {
    return {
      isHover: false
    };
  },
  methods: {
    computeSprintText: function computeSprintText() {
      if (this.sprint.name.length > 20) {
        return this.sprint.name.substring(0, 20) + '...';
      } else {
        return this.sprint.name;
      }
    },
    computeSprintDescription: function computeSprintDescription() {
      if (this.sprint.description.length > 30) {
        return this.sprint.description.substring(0, 30) + '...';
      } else {
        return this.sprint.description;
      }
    },
    emitClick: function emitClick() {
      this.$emit('clickSprint', this.goal);
    },
    generateTransform: function generateTransform() {
      var x = 0 + 35;
      var y = this.y + 75;
      return 'rotate(-90 ' + x + ',' + y + ')';
    },
    onMouseOver: function onMouseOver() {
      this.isHover = true;
      this.$emit('mouse-over');
    },
    onMouseLeave: function onMouseLeave() {
      this.isHover = false;
      this.$emit('mouse-leave');
    },
    removeSprint: function removeSprint() {
      this.$emit('delete', this.index);
    }
  }
};