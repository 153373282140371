"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.function.name");
var _taggedTemplateLiteral2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/taggedTemplateLiteral.js"));
require("core-js/modules/es6.number.constructor");
var _axios = _interopRequireDefault(require("axios"));
var _templateObject, _templateObject2, _templateObject3, _templateObject4;
var _default2 = exports.default = {
  name: 'ExternalTicketList',
  components: {},
  props: {
    offering: {
      type: Object,
      required: true,
      default: function _default() {
        return {
          id: '',
          title: '',
          description: '',
          offeringcategory: '',
          offeringcategorydesc: '',
          additionalattributes: {},
          linkedProducts: [],
          linkedServices: []
        };
      }
    },
    parent: {
      type: Object,
      required: true,
      default: function _default() {
        return {
          id: '',
          title: '',
          description: ''
        };
      }
    },
    parentX: {
      type: Number,
      required: true,
      default: 0
    },
    parentY: {
      type: Number,
      required: true,
      default: 0
    },
    parentType: {
      type: String,
      default: 'Product'
    }
  },
  data: function data() {
    return {
      loading: {
        status: false,
        message: ''
      },
      ticket_loading: {
        status: false,
        message: ''
      },
      isImporting: false,
      filter: {
        q: '',
        users: [],
        projects: [],
        ticket_status: ['MAPPED', 'UNMAPPED']
      },
      listLayout: 'compact',
      tickets: [],
      users: [],
      per_page_count: 0,
      total_count: 0,
      selectedTickets: [],
      integrations: []
    };
  },
  computed: {
    filteredTickets: function filteredTickets() {
      var self = this;
      return this.tickets.filter(function (ticket) {
        return self.filter.ticket_status.indexOf(ticket.mappedStatus) > -1;
      });
    }
  },
  watch: {
    parent: {
      handler: function handler(newValue) {
        if (newValue) {
          this.refreshData();
          this.retrieveIntegrations();
        }
      },
      deep: true
    }
  },
  created: function created() {},
  mounted: function mounted() {
    this.refreshData();
    this.retrieveIntegrations();
  },
  methods: {
    importTickets: function importTickets() {
      this.$emit('import');
    },
    computeMappedStatusType: function computeMappedStatusType(status) {
      switch (status) {
        case 'MAPPED':
          return 'success';
        case 'IGNORED':
          return 'warning';
        default:
          return '';
      }
    },
    updateTicketParent: function updateTicketParent(entity_id, parent_object) {
      for (var i = 0; i < this.tickets.length; i++) {
        if (this.tickets[i].entityId === entity_id) {
          this.tickets[i].mappedParentEntity = parent_object;
        }
      }
    },
    retrieveEntityFromSubEntity: function retrieveEntityFromSubEntity(entity_name, entity_id) {
      switch (entity_name) {
        case 'ProductFeature':
          this.retrieveProductTitleFromFeatureID(entity_id);
          break;
        case 'ServiceApplication':
          this.retrieveServiceTitleFromServiceApplicationID(entity_id);
          break;
      }
    },
    retrieveProductTitleFromFeatureID: function retrieveProductTitleFromFeatureID(feature_id) {
      var _this = this;
      this.$apollo.query({
        query: window._gql(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)([" query ProductFeature($id : String)\n        {\n        ProductFeature(id: $id)\n        {\n          id\n          linkedProducts(optional: true) {\n            product(optional: true) {\n              id\n              title\n            }\n          }\n        }\n      }"]))),
        fetchPolicy: 'no-cache',
        variables: {
          'id': feature_id
        }
      }).then(function (data) {
        if (data.data.ProductFeature && data.data.ProductFeature.linkedProducts.length > 0) {
          _this.updateTicketParent(feature_id, data.data.ProductFeature.linkedProducts[0].product);
        } else {
          _this.updateTicketParent(feature_id, {
            title: '',
            id: ''
          });
        }
      }).catch(function (error) {
        // Error
        _this.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
      });
    },
    retrieveServiceTitleFromServiceApplicationID: function retrieveServiceTitleFromServiceApplicationID(service_application_id) {
      var _this2 = this;
      this.$apollo.query({
        query: window._gql(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)([" query ServiceApplication($id : String)\n        {\n        ServiceApplication(id: $id)\n        {\n          id\n          linkedServices(optional: true) {\n            service(optional: true) {\n              id\n              title\n            }\n          }\n        }\n      }"]))),
        fetchPolicy: 'no-cache',
        variables: {
          'id': service_application_id
        }
      }).then(function (data) {
        if (data.data.ServiceApplication) {
          _this2.updateTicketParent(service_application_id, data.data.ServiceApplication.linkedServices[0].service);
        }
      }).catch(function (error) {
        // Error
        _this2.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
      });
    },
    reloadTickets: function reloadTickets() {
      var _this3 = this;
      if (this.filter.projects.length > 0) {
        for (var i = 0; i < this.integrations.length; i++) {
          for (var j = 0; j < this.integrations[i].projects.length; j++) {
            if (this.integrations[i].projects[j].name === this.filter.projects[0]) {
              this.ticket_loading.status = true;
              this.ticket_loading.message = 'Loading tickets...';
              var headers = {
                'Content-Type': 'application/json',
                'x-party-id': this.$store.getters.partyId,
                'x-user-id': this.$store.getters.id
              };
              _axios.default.get(process.env.VUE_APP_REST_API_ENDPOINT + 'tprty/' + this.integrations[i].id + '/sync/project/' + this.integrations[i].projects[j].id + '/ticket', {
                headers: headers
              }).then(function (response) {
                _this3.ticket_loading.status = false;
                if (response.data.status === 'SUCCESS') {
                  _this3.displayImportTicketsDialog = false;
                  _this3.ticket_loading.message = 'Loading tickets completed';
                  _this3.retrieveExternalTicketList(1);
                }
              }).catch(function (error) {
                _this3.ticket_loading.status = false;
                _this3.ticket_loading.message = 'Error loading tickets';
                console.log(error);
              });
            }
          }
        }
      }
    },
    filterByTicketStatus: function filterByTicketStatus(val) {
      this.retrieveExternalTicketList(1);
    },
    handleServiceApplicationImportCommand: function handleServiceApplicationImportCommand(command) {
      switch (command) {
        case 'automated':
          break;
        case 'semiautomated':
          break;
        case 'manual':
          break;
        case 'thirdparty':
          break;
      }
    },
    computeDateFormat: function computeDateFormat(date) {
      return this.$moment.utc(date).local().format('DD-MM-YYYY');
    },
    refreshData: function refreshData() {
      this.retrieveExternalTicketList(1);
      this.retrieveExternalUserList();
    },
    importSelectedTickets: function importSelectedTickets() {
      window._.forEach(this.selectedTickets, function (ticket, key) {
        this.importTicket(ticket);
      });
    },
    importTicket: function importTicket(ticket) {
      /*
        1. Create Product feature / Service Application
        2. Clone to ticket and attach to product feature / service application
        3. update readonly ticket to imported
      */
      // Events needs to be sync, so calling events after each api call
      // TODO handle frontend notification
      // - Loading icon, disabled import, text under import with status
      var ticket_params = Object.assign({}, ticket);
      ticket_params.slug = ticket_params.externalId;
      this.isImporting = true;
      ticket.loading.status = true;
      if (this.parentType === 'Product') {
        this.addFeature(ticket_params);
      } else {
        this.addServiceApplication(ticket_params);
      }
    },
    /*    updateParentTicketEntityName(parent_ticket) {
          parent_ticket.loading.message = 'Cleanup...'
          const headers = {
            'Content-Type': 'application/json'
            // 'Authorization': 'Bearer '+getJWTToken()
          }
    
          var body = {
            data: [
              {
                'id': parent_ticket.id,
                'entityName': 'JIRA-ISSUE-IMPORTED'
              }
            ],
            'header': {
              'userId': this.$store.getters.id,
              'partyId': this.$store.getters.partyId
            }
          }
          axios.patch(process.env.VUE_APP_REST_API_ENDPOINT + 'ticket', body, {
            headers: headers
          })
          .then((response) => {
            // Completed
            // Send emit reload event
            this.isImporting = false
            this.$emit('ticket-imported')
            parent_ticket.loading.message = 'Imported...'
            parent_ticket.loading.status = false
            this.retrieveExternalTicketList(1)
          })
          .catch((error) => {
            console.log(error)
            parent_ticket.loading.status = false
            this.isImporting = false
          })
        },*/
    updateTicketStatus: function updateTicketStatus(ticket, status) {
      var _this4 = this;
      ticket.loading.status = true;
      ticket.loading.message = 'Updating JIRA Ticket...';
      var headers = {
        'Content-Type': 'application/json'
        // 'Authorization': 'Bearer '+getJWTToken()
      };
      var body = {
        data: [{
          'id': ticket.id,
          'mappedStatus': status
        }],
        'header': {
          'userId': this.$store.getters.id,
          'partyId': this.$store.getters.partyId
        }
      };
      _axios.default.patch(process.env.VUE_APP_REST_API_ENDPOINT + 'ticket', body, {
        headers: headers
      }).then(function (response) {
        ticket.loading.status = false;
        if (response.data.data.status === 'SUCCESS') {
          ticket.loading.message = 'Ticket Updated...';
          _this4.retrieveExternalTicketList(1);
          // this.updateParentTicketEntityName(ticket)
        }
      }).catch(function (error) {
        ticket.loading.message = error.response.data.description;
        ticket.loading.status = false;
        _this4.isImporting = false;
      });
    },
    updateTicket: function updateTicket(ticket, type) {
      var _this5 = this;
      ticket.loading.message = 'Updating JIRA Ticket...';
      var headers = {
        'Content-Type': 'application/json'
        // 'Authorization': 'Bearer '+getJWTToken()
      };
      var body = {
        data: [{
          'id': ticket.id,
          'entityId': ticket.entityId,
          'entityName': ticket.entityName,
          'externalDescription': ticket.externalDescription,
          'type': type,
          'mappedStatus': 'MAPPED',
          'approvalVia': 'test'
        }],
        'header': {
          'userId': this.$store.getters.id,
          'partyId': this.$store.getters.partyId
        }
      };
      _axios.default.patch(process.env.VUE_APP_REST_API_ENDPOINT + 'ticket', body, {
        headers: headers
      }).then(function (response) {
        if (response.data.data.status === 'SUCCESS') {
          ticket.loading.message = 'Ticket Updated...';
          _this5.isImporting = false;
          _this5.$emit('ticket-imported');
          ticket.loading.message = 'Imported...';
          ticket.loading.status = false;
          _this5.retrieveExternalTicketList(1);

          // this.updateParentTicketEntityName(ticket)
        }
      }).catch(function (error) {
        ticket.loading.message = error.response.data.description;
        ticket.loading.status = false;
        _this5.isImporting = false;
      });
    },
    addServiceApplication: function addServiceApplication(ticket) {
      var _this6 = this;
      ticket.loading.status = true;
      ticket.loading.message = 'Creating Ticket...';
      this.$apollo.mutate({
        // Query
        mutation: window._gql(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["mutation createServiceApplication($serviceApplication: CreateServiceApplicationInput!)\n        {\n          createServiceApplication(input: $serviceApplication) {\n            id\n          }\n        }"]))),
        // Parameters
        variables: {
          'serviceApplication': {
            'serviceId': this.parent.id,
            'title': ticket.title,
            'description': ticket.slug,
            // JIRA HTML desc stored in slug for nows
            'priority': 'Medium',
            'status': ticket.status,
            'relations': {
              'type': null,
              'id': null
            },
            'additionalattributes': {
              'issueSource': ticket.externalSystemName,
              'type': 'undefined',
              'pos_x': this.computeServiceUnmappedIndex(this.parent.linkedServiceApplications.length) % 50 * 50 + 198,
              // Legacy code. to be removed
              'posX': this.computeServiceUnmappedIndex(this.parent.linkedServiceApplications.length) % 50 * 50 + 198,
              'posY': this.parentY + (this.serviceHasEnablerFeatures() ? 160 : 110),
              'minimized': false
            }
          }
        }
      }).then(function (data) {
        ticket.entityId = data.data.createServiceApplication.id;
        ticket.entityName = 'ServiceApplication';
        ticket.loading.message = 'Ticket Created...';
        var posx = _this6.computeServiceUnmappedIndex(_this6.parent.linkedServiceApplications.length) % 50 * 50 + 198;
        var posy = _this6.parentY + (_this6.serviceHasEnablerFeatures() ? 160 : 110);
        _this6.updateEntityToParentEntitySortOrder('service', _this6.parent.id, data.data.createServiceApplication.id, posx, posy);
        _this6.updateTicket(ticket, 'Process Epic');
        // this.loading.status = false
      }).catch(function (error) {
        // Error
        _this6.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        ticket.loading.status = false;
        _this6.isImporting = false;
        // this.loading.status = false
      });
    },
    addFeature: function addFeature(ticket) {
      var _this7 = this;
      ticket.loading.status = true;
      ticket.loading.message = 'Creating Ticket...';
      this.$apollo.mutate({
        // Query
        mutation: window._gql(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2.default)(["mutation createProductFeature($feature: CreateProductFeatureInput!)\n        {\n          createProductFeature(input: $feature) {\n            id\n          }\n        }"]))),
        // Parameters
        variables: {
          'feature': {
            'productId': this.parent.id,
            'offeringId': ticket.offeringId,
            'title': ticket.title,
            'description': ticket.slug,
            // JIRA HTML desc stored in slug for now
            'status': ticket.status,
            'priority': 'Medium',
            'relations': {
              'steps': [{
                'id': null
              }]
            },
            'additionalattributes': {
              'issueSource': ticket.externalSystemName,
              'posX': this.computeProductUnmappedIndex(this.parent.linkedFeatures.length) % 50 * 50 + 198 + 50,
              'posY': this.parentY + (this.productHasEnablerFeatures() ? 160 : 110)
            }
          }
        }
      }).then(function (data) {
        ticket.entityId = data.data.createProductFeature.id;
        ticket.entityName = 'ProductFeature';
        ticket.loading.message = 'Ticket Created...';
        var posx = _this7.computeProductUnmappedIndex(_this7.parent.linkedFeatures.length) % 50 * 50 + 198 + 50;
        var posy = _this7.parentY + (_this7.productHasEnablerFeatures() ? 160 : 110);
        _this7.updateEntityToParentEntitySortOrder('product', _this7.parent.id, data.data.createProductFeature.id, posx, posy);
        _this7.updateTicket(ticket, 'Product Epic');
        // this.loading.status = false
      }).catch(function (error) {
        // Error
        _this7.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        ticket.loading.status = false;
        _this7.isImporting = false;
        // this.loading.status = false
      });
    },
    updateEntityToParentEntitySortOrder: function updateEntityToParentEntitySortOrder(type, parent_entity_id, entity_id, posX, posY) {
      var flag = false;
      var additionalattributes = this.offering.additionalattributes;
      for (var i = 0; i < additionalattributes.entitiesSortOrder.length; i++) {
        // update y pos for all entities below current entity
        if (additionalattributes.entitiesSortOrder[i].type === 'product' && flag) {
          for (var j = 0; j < additionalattributes.entitiesSortOrder[i].features.length; j++) {
            additionalattributes.entitiesSortOrder[i].features[j].posY += 50;
          }
        }
        if (additionalattributes.entitiesSortOrder[i].type === 'service' && flag) {
          for (var k = 0; k < additionalattributes.entitiesSortOrder[i].service_applications.length; k++) {
            additionalattributes.entitiesSortOrder[i].service_applications[k].posY += 50;
          }
        }
        if (additionalattributes.entitiesSortOrder[i].id === parent_entity_id) {
          var sub_entity = {};
          sub_entity.id = entity_id;
          sub_entity.posX = posX;
          sub_entity.posY = posY;
          sub_entity.enabler = false;
          sub_entity.unmapped = true;
          if (!this.hasExistingUnmapped(type, parent_entity_id)) {
            flag = true;
          }
          if (type === 'product') {
            additionalattributes.entitiesSortOrder[i].features.push(sub_entity);
          }
          if (type === 'service') {
            additionalattributes.entitiesSortOrder[i].service_applications.push(sub_entity);
          }
        }
      }
      this.$emit('update-offering-additionalattributes', additionalattributes, true);
    },
    hasExistingUnmapped: function hasExistingUnmapped(type, parent_entity_id) {
      if (type === 'product') {
        for (var i = 0; i < this.offering.linkedProducts.length; i++) {
          if (this.offering.linkedProducts[i].product.id === parent_entity_id) {
            return this.productHasUnmappedFeatures(this.offering.linkedProducts[i].product);
          }
        }
      }
      if (type === 'service') {
        for (var j = 0; j < this.offering.linkedServices.length; j++) {
          if (this.offering.linkedServices[j].service.id === parent_entity_id) {
            return this.serviceHasUnmappedFeatures(this.offering.linkedServices[j].service);
          }
        }
      }
      return false;
    },
    productHasUnmappedFeatures: function productHasUnmappedFeatures(product) {
      var has_unmapped = false;
      for (var i = 0; i < product.linkedFeatures.length; i++) {
        if (product.linkedFeatures[i].productFeature.relations.steps[0].id === null) {
          has_unmapped = true;
        }
      }
      return has_unmapped;
    },
    serviceHasUnmappedFeatures: function serviceHasUnmappedFeatures(service) {
      var has_unmapped = false;
      for (var i = 0; i < service.linkedServiceApplications.length; i++) {
        if (service.linkedServiceApplications[i].serviceApplication.relations.id === null) {
          has_unmapped = true;
        }
      }
      return has_unmapped;
    },
    handleTicketListSelectionChange: function handleTicketListSelectionChange(val) {
      this.selectedTickets = val;
    },
    loadPage: function loadPage(page_number) {
      this.retrieveExternalTicketList(page_number);
    },
    retrieveExternalTicketList: function retrieveExternalTicketList(page_number) {
      var _this8 = this;
      this.loading.status = true;
      var headers = {
        'Content-Type': 'application/json',
        'x-party-id': this.$store.getters.partyId,
        'x-user-id': this.$store.getters.id
      };
      _axios.default.get(this.computeTicketRetrivalURL_v2(page_number), {
        headers: headers
      }).then(function (response) {
        _this8.tickets = [];
        if (response.data.status === 'SUCCESS') {
          _this8.tickets = response.data.data;
          var self = _this8;
          window._.forEach(_this8.tickets, function (ticket, key) {
            ticket.loading = {
              status: false,
              message: ''
            };
            ticket.mappedParentEntity = {};
            if (ticket.mappedStatus === 'MAPPED') {
              self.retrieveEntityFromSubEntity(ticket.entityName, ticket.entityId);
            }
          });
          _this8.per_page_count = response.data.pageInfo.itemsPerPage;
          _this8.total_count = response.data.pageInfo.totalPages * response.data.pageInfo.itemsPerPage;
          _this8.loading.status = false;
        }
      }).catch(function (error) {
        _this8.tickets = [];
        _this8.loading.status = false;
        console.log(error);
      });
    },
    retrieveExternalUserList: function retrieveExternalUserList() {
      var _this9 = this;
      var headers = {
        'Content-Type': 'application/json',
        'x-party-id': this.$store.getters.partyId,
        'x-user-id': this.$store.getters.id
      };
      _axios.default.get(process.env.VUE_APP_REST_API_ENDPOINT + 'ticket/external/users', {
        headers: headers
      }).then(function (response) {
        if (response.data.status === 'SUCCESS') {
          _this9.users = response.data.data;
        }
      }).catch(function (error) {
        _this9.loading.status = false;
        console.log(error);
      });
    },
    updateListLayout: function updateListLayout(layout) {
      this.listLayout = layout;
      this.$emit('layout-updated', layout);
    },
    // old search URL.
    // delete if server search design is finalised
    computeTicketRetrivalURL: function computeTicketRetrivalURL(page_number) {
      var request_url = process.env.VUE_APP_REST_API_ENDPOINT + 'ticket?externalType=Epic&page=' + page_number + '&';
      if (this.filter.q !== '') {
        request_url += 'q=' + this.filter.q + '&';
      }
      if (this.filter.ticket_status.length > 0) {
        request_url += 'mappedStatus=' + this.filter.ticket_status.join(',') + '&';
      }
      if (this.filter.projects.length > 0) {
        request_url += 'externalProjectName=' + this.filter.projects[0] + '&';
      }
      if (this.filter.users.length > 0) {
        request_url += 'externalReporterName=' + this.filter.users[0] + '&';
      }
      request_url = request_url.substring(0, request_url.length - 1);
      return request_url;
    },
    // temp search URL.
    computeTicketRetrivalURL_v2: function computeTicketRetrivalURL_v2(page_number) {
      var request_url = process.env.VUE_APP_REST_API_ENDPOINT + 'ticket?externalType=Epic&page=' + page_number + '&';
      if (this.filter.ticket_status.length > 0) {
        request_url += 'mappedStatus=' + this.filter.ticket_status.join(',') + '&';
      }
      request_url += 'q=';
      if (this.filter.q !== '') {
        request_url += this.filter.q + ' ';
      }
      if (this.filter.projects.length > 0) {
        request_url += this.filter.projects.join(' ') + ' ';
      }
      if (this.filter.users.length > 0) {
        request_url += this.filter.users.join(' ') + ' ';
      }
      request_url = request_url.substring(0, request_url.length - 1);
      return request_url;
    },
    filteredProjectsByImport: function filteredProjectsByImport(projects) {
      var filtered_projects = [];
      for (var i = 0; i < projects.length; i++) {
        if (projects[i].isImported || projects[i].isLocalProject) {
          filtered_projects.push(projects[i]);
        }
      }
      return filtered_projects;
    },
    loadProjects: function loadProjects(tenancy, index) {
      var _this10 = this;
      this.loading.status = true;
      var headers = {
        'Content-Type': 'application/json',
        'x-party-id': this.$store.getters.partyId,
        'x-user-id': this.$store.getters.id
      };
      _axios.default.get(process.env.VUE_APP_REST_API_ENDPOINT + 'tprty/' + tenancy.id + '/project', {
        headers: headers
      }).then(function (response) {
        _this10.loading.status = false;
        if (response.data.status === 'SUCCESS') {
          _this10.integrations[index].projects = response.data.data;
        }
      }).catch(function (error) {
        _this10.loading.status = false;
        console.log(error);
      });
    },
    retrieveIntegrations: function retrieveIntegrations() {
      var _this11 = this;
      this.loading.status = true;
      var headers = {
        'Content-Type': 'application/json',
        'x-party-id': this.$store.getters.partyId,
        'x-user-id': this.$store.getters.id
      };
      _axios.default.get(process.env.VUE_APP_REST_API_ENDPOINT + 'tprty/setup?platform=true&extended=LEVEL0', {
        headers: headers
      }).then(function (response) {
        _this11.loading.status = false;
        _this11.integrations = [];
        if (response.data.status === 'SUCCESS') {
          _this11.integrations = response.data.data;
          for (var i = 0; i < _this11.integrations.length; i++) {
            _this11.integrations[i].projects = [];
            _this11.integrations[i].enabled = false;
            _this11.loadProjects(_this11.integrations[i], i);
            _this11.isEnabled(_this11.integrations[i].id, i);
          }
          // console.log(this.integrations)
        } else {
          _this11.loading.status = false;
          _this11.$notify({
            title: 'Error',
            message: response.data.data.description,
            type: 'error',
            duration: 2000
          });
        }
      }).catch(function (error) {
        _this11.integrations = [];
        _this11.loading.status = false;
        console.log(error);
      });
    },
    isEnabled: function isEnabled(integration_id, integration_index) {
      var _this12 = this;
      this.loading.status = true;
      var headers = {
        'Content-Type': 'application/json',
        'x-party-id': this.$store.getters.partyId,
        'x-user-id': this.$store.getters.id
      };
      _axios.default.get(process.env.VUE_APP_REST_API_ENDPOINT + 'tprty/' + integration_id + '/auth/isenabled', {
        headers: headers
      }).then(function (response) {
        _this12.loading.status = false;
        if (response.data.status === 'SUCCESS') {
          _this12.integrations[integration_index].enabled = response.data.data.enabled;
          _this12.integrations[integration_index].isTokenActive = response.data.data.isTokenActive;
          _this12.integrations[integration_index].isSync = false;
          // this.enabledStatus = response.data.data
        }
      }).catch(function (error) {
        console.log(error);
        _this12.loading.status = false;
      });
    },
    computeProductUnmappedIndex: function computeProductUnmappedIndex(index) {
      var count = 0;
      for (var j = 0; j < index; j++) {
        if (this.parent.linkedFeatures[j].productFeature.relations.steps[0].id === null) {
          count++;
        }
      }
      return count;
    },
    computeServiceUnmappedIndex: function computeServiceUnmappedIndex(index) {
      var count = 0;
      for (var j = 0; j < index; j++) {
        if (this.parent.linkedServiceApplications[j].serviceApplication.relations.id === null) {
          count++;
        }
      }
      return count;
    },
    productHasEnablerFeatures: function productHasEnablerFeatures() {
      var has_enabler = false;
      for (var i = 0; i < this.parent.linkedFeatures.length; i++) {
        if (this.parent.linkedFeatures[i].productFeature.enabler) {
          return true;
        }
      }
      return has_enabler;
    },
    serviceHasEnablerFeatures: function serviceHasEnablerFeatures() {
      var has_enabler = false;
      for (var i = 0; i < this.parent.linkedServiceApplications.length; i++) {
        if (this.parent.linkedServiceApplications[i].serviceApplication.enabler) {
          return true;
        }
      }
      return has_enabler;
    }
  }
};