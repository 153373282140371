"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _visionAndGoals = _interopRequireDefault(require("./components/business_strategy/vision-and-goals"));
var _productRoadmap = _interopRequireDefault(require("./components/business_strategy/product-roadmap"));
var _ProblemHypothesis = _interopRequireDefault(require("@/components/ProblemHypothesis"));
var _ProcessSteps = _interopRequireDefault(require("@/components/ProcessSteps"));
var _default = exports.default = {
  name: 'ProductStrategy',
  components: {
    VisionGoalsComponent: _visionAndGoals.default,
    ProductRoadmapComponent: _productRoadmap.default,
    ProblemHypothesisComponent: _ProblemHypothesis.default,
    ProcessSteps: _ProcessSteps.default
  },
  data: function data() {
    return {
      active_step: 0,
      steps: [{
        name: ' Product Vision, Goals & Actions',
        shortDescription: 'Define vision, goals and actions',
        longDescription: 'Create a plan through goals and actions to execute the vision.'
      }, {
        name: 'High Level Product Roadmap',
        shortDescription: 'Organise product goals',
        longDescription: 'The product roadmap helps organise goals into phases (product horizons). The lanes further group goals by team, department, etc.'
      }, {
        name: 'Problem & Hypothesis',
        shortDescription: 'Understand and explore problem',
        longDescription: 'As explore the test the problem space, your problem will evolve.  Use this page to managing an evolving problem definition. '
      }]
    };
  },
  created: function created() {},
  mounted: function mounted() {
    // Auto Navigate to corresponding tab based on URL
    switch (this.$route.name) {
      case 'Vision & Goals':
        this.active_step = 0;
        break;
      case 'Business Roadmap':
        this.active_step = 1;
        break;
      case 'Value Proposition':
        this.active_step = 2;
        break;
    }
  },
  methods: {
    updateStep: function updateStep(active_step) {
      this.active_step = active_step;
    },
    navigateTo: function navigateTo(step) {
      if (step === 'business-roadmap') {
        this.active_step = 1;
      }
    }
  }
};