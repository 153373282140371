"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _default2 = exports.default = {
  name: 'Task',
  components: {},
  props: {
    x: {
      type: Number,
      default: 0
    },
    y: {
      type: Number,
      default: 0
    },
    index: {
      type: Number,
      default: 0
    },
    phaseIndex: {
      type: Number,
      default: 0
    },
    label: {
      type: String,
      default: ''
    },
    showLabel: {
      type: Boolean,
      default: false
    },
    priority: {
      type: String,
      default: 'Medium'
    },
    value: {
      type: String,
      default: 'Medium'
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    stepId: {
      type: String,
      default: ''
    },
    activeHoverId: {
      type: String,
      default: ''
    },
    hover: {
      type: Boolean,
      default: false
    },
    ideas: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    isTool: {
      type: Boolean,
      default: false
    },
    isRelationHover: {
      type: Boolean,
      default: false
    },
    highlight: {
      type: String,
      default: null
    }
  },
  data: function data() {
    return {
      isHover: false,
      isLongPress: false,
      isWorkflowLongPress: false
    };
  },
  watch: {
    hover: function hover(val) {
      if (!val) {
        this.isHover = false;
      }
    }
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    computePriority: function computePriority() {
      switch (this.priority) {
        case 'Very Low':
          return 1;
        case 'Low':
          return 2;
        case 'Medium':
          return 3;
        case 'High':
          return 4;
        case 'Very High':
          return 5;
      }
    },
    computeCustomerValueIcon: function computeCustomerValueIcon() {
      switch (this.value) {
        case 'Very Low':
          return 'dollar';
        case 'Low':
          return 'dollar';
        case 'Medium':
          return 'dollar-2';
        case 'High':
          return 'dollar-2';
        case 'Very High':
          return 'dollar-3';
        default:
          return 'dollar';
      }
    },
    onTaskDragged: function onTaskDragged(_ref) {
      var el = _ref.el,
        deltaX = _ref.deltaX,
        deltaY = _ref.deltaY,
        offsetX = _ref.offsetX,
        offsetY = _ref.offsetY,
        clientX = _ref.clientX,
        clientY = _ref.clientY,
        first = _ref.first,
        last = _ref.last;
      // this.isLongPress is set to false before taskdragged ends. so end event is not being captured.
      // As long as drag is started in longpress end event capture without longpress chk should be fine
      if (last && !this.readOnly) {
        this.$emit('drag-task', this.index, el, deltaX, deltaY, offsetX, offsetY, clientX, clientY, first, last);
      }
      if (!this.readOnly && this.isLongPress) {
        this.$emit('drag-task', this.index, el, deltaX, deltaY, offsetX, offsetY, clientX, clientY, first, last);
      }
    },
    onSubmit: function onSubmit() {},
    emitClick: function emitClick() {
      this.$emit('click', this.index);
    },
    emitOnMouseOver: function emitOnMouseOver() {
      this.isHover = true;
      this.$emit('mouseover', this.index);
    },
    emitOnToolMouseLeave: function emitOnToolMouseLeave() {
      if (this.isTool) {
        this.$emit('toolmouseover', this.index);
      }
    },
    emitOnMouseLeave: function emitOnMouseLeave(emit) {
      this.isHover = false;
      if (emit) {
        this.$emit('mouseleave', this.index);
      }
    },
    generateUniqueID: function generateUniqueID() {
      return 'task_' + this.index;
    },
    onTaskWorkFlowDragged: function onTaskWorkFlowDragged(_ref2) {
      var el = _ref2.el,
        deltaX = _ref2.deltaX,
        deltaY = _ref2.deltaY,
        offsetX = _ref2.offsetX,
        offsetY = _ref2.offsetY,
        clientX = _ref2.clientX,
        clientY = _ref2.clientY,
        first = _ref2.first,
        last = _ref2.last;
      if (!this.readOnly) {
        this.$emit('drag-workflow', this.index, el, deltaX, deltaY, offsetX, offsetY, clientX, clientY, first, last);
      }
    },
    onLongPressStart: function onLongPressStart() {
      this.isLongPress = true;
    },
    onLongPressStop: function onLongPressStop() {
      this.isLongPress = false;
    },
    onLongPressWorkflowStart: function onLongPressWorkflowStart() {
      this.isWorkflowLongPress = true;
    },
    onLongPressWorkflowStop: function onLongPressWorkflowStop() {
      this.isWorkflowLongPress = false;
    },
    computeArrowStyle: function computeArrowStyle() {
      var opacity = 'opacity:1;';
      if (this.highlight) {
        opacity = 'opacity:0.5;';
      }
      if (this.isWorkflowLongPress) {
        return 'font-size: 25px;color:red;' + opacity;
      } else {
        return 'font-size: 25px;color:#4672c4;' + opacity;
      }
    },
    /* This method determines the VIEW of the STEP  the user sees on the screen. Div sits inside RECT. */computeStyle: function computeStyle() {
      if (this.isTool) {
        if (this.isLongPress) {
          return 'border-radius: 5pt; height: 20pt; background-color: aliceblue; width:65pt; color:#4672c4;border-color: #99CFFF;border-style: solid;';
        } else {
          return 'border-radius: 5pt; height: 20pt; background-color: white; width:65pt; color:#4672c4';
        }
      } else {
        if (this.isLongPress) {
          return 'border-radius: 5pt; height: 20pt; background-color: aliceblue; width:95pt; color:#4672c4;border-color: #99CFFF;border-style: solid;';
        } else {
          return 'border-radius: 5pt; height: 20pt; background-color: white; width:95pt; color:#4672c4; border: solid 0.5pt #bfd4e2';
        }
      }
    },
    truncateString: function truncateString(string, length) {
      return this._.truncate(string, {
        'length': length
      });
    }
  }
};