"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _default = exports.default = {
  name: 'FeatureManagmentPainPointPleasers',
  data: function data() {
    return {
      loading: false,
      pain_point_pleasers_search: '',
      checked_met: false,
      checked_unmet: false,
      checked_pain: false,
      checked_pleaser: false,
      pain_point_pleasers: [{
        type: 'pain_point',
        feature: 'Individual must wait 2 hours to receive policy',
        met: true,
        value: true,
        primary: true,
        features: [{
          name: 'Mendix',
          has_parent: true,
          enabler: true,
          value: 1,
          priority: 1,
          is_stratergic: true,
          reason: '',
          criteria: 'usability',
          approver: 'Sponsor',
          status: 'Operational',
          first_added: '10-12-2016',
          improved_in: '10-12-2016',
          prime_user: {
            name: 'John Doe',
            design_goal: 'Lorem Ipsum',
            mockups: [{
              name: 'mockup 1'
            }, {
              name: 'mockup 2'
            }]
          },
          additional_users: [{
            name: 'Add John',
            design_goal: 'Lorem Design Ipsum'
          }, {
            name: 'Add John',
            design_goal: 'Lorem Design Ipsum'
          }],
          competitors: 'Dont Offer',
          stories: [{
            name: 'Story A',
            description: 'Lorem Ipsum',
            enabler: true,
            value: 2,
            priority: 1,
            is_stratergic: true,
            status: 'Operational',
            first_added: '10-12-2016',
            improved_in: '10-12-2016'
          }, {
            name: 'Story B',
            description: 'Lorem Ipsum',
            enabler: true,
            value: 2,
            priority: 1,
            is_stratergic: true,
            status: 'Operational',
            first_added: '10-12-2016',
            improved_in: '10-12-2016'
          }]
        }, {
          name: 'Story Teller',
          has_parent: true,
          enabler: true,
          value: 1,
          priority: 3,
          is_stratergic: true,
          reason: '',
          criteria: 'usability',
          approver: 'Sponsor',
          status: 'Operational',
          first_added: '10-12-2016',
          improved_in: '10-12-2016',
          prime_user: {
            name: 'John Doe',
            design_goal: 'Lorem Ipsum',
            mockups: [{
              name: 'mockup 1'
            }, {
              name: 'mockup 2'
            }]
          },
          additional_users: [{
            name: 'Add John',
            design_goal: 'Lorem Design Ipsum'
          }, {
            name: 'Add John',
            design_goal: 'Lorem Design Ipsum'
          }],
          competitors: 'Dont Offer',
          stories: [{
            name: 'Story C',
            description: 'Lorem Ipsum',
            enabler: true,
            value: 2,
            priority: 1,
            is_stratergic: true,
            status: 'Operational',
            first_added: '10-12-2016',
            improved_in: '10-12-2016'
          }, {
            name: 'Story D',
            description: 'Lorem Ipsum',
            enabler: true,
            value: 2,
            priority: 1,
            is_stratergic: true,
            status: 'Operational',
            first_added: '10-12-2016',
            improved_in: '10-12-2016'
          }]
        }]
      }, {
        type: 'pain_point',
        feature: 'Groups must add each individual manually, not in bulk',
        met: false,
        value: true,
        primary: true,
        features: [{
          name: 'Mendix',
          has_parent: true,
          enabler: true,
          value: 1,
          priority: 1,
          is_stratergic: true,
          reason: '',
          criteria: 'usability',
          approver: 'Sponsor',
          status: 'Operational',
          first_added: '10-12-2016',
          improved_in: '10-12-2016',
          prime_user: {
            name: 'John Doe',
            design_goal: 'Lorem Ipsum',
            mockups: [{
              name: 'mockup 1'
            }, {
              name: 'mockup 2'
            }]
          },
          additional_users: [{
            name: 'Add John',
            design_goal: 'Lorem Design Ipsum'
          }, {
            name: 'Add John',
            design_goal: 'Lorem Design Ipsum'
          }],
          competitors: 'Dont Offer',
          stories: [{
            name: 'Story A',
            description: 'Lorem Ipsum',
            enabler: true,
            value: 2,
            priority: 1,
            is_stratergic: true,
            status: 'Operational',
            first_added: '10-12-2016',
            improved_in: '10-12-2016'
          }, {
            name: 'Story B',
            description: 'Lorem Ipsum',
            enabler: true,
            value: 2,
            priority: 1,
            is_stratergic: true,
            status: 'Operational',
            first_added: '10-12-2016',
            improved_in: '10-12-2016'
          }]
        }, {
          name: 'Story Teller',
          has_parent: true,
          enabler: true,
          value: 1,
          priority: 3,
          is_stratergic: true,
          reason: '',
          criteria: 'usability',
          approver: 'Sponsor',
          status: 'Operational',
          first_added: '10-12-2016',
          improved_in: '10-12-2016',
          prime_user: {
            name: 'John Doe',
            design_goal: 'Lorem Ipsum',
            mockups: [{
              name: 'mockup 1'
            }, {
              name: 'mockup 2'
            }]
          },
          additional_users: [{
            name: 'Add John',
            design_goal: 'Lorem Design Ipsum'
          }, {
            name: 'Add John',
            design_goal: 'Lorem Design Ipsum'
          }],
          competitors: 'Dont Offer',
          stories: [{
            name: 'Story C',
            description: 'Lorem Ipsum',
            enabler: true,
            value: 2,
            priority: 1,
            is_stratergic: true,
            status: 'Operational',
            first_added: '10-12-2016',
            improved_in: '10-12-2016'
          }, {
            name: 'Story D',
            description: 'Lorem Ipsum',
            enabler: true,
            value: 2,
            priority: 1,
            is_stratergic: true,
            status: 'Operational',
            first_added: '10-12-2016',
            improved_in: '10-12-2016'
          }]
        }]
      }, {
        type: 'pleaser',
        feature: 'Individuals to low risk destinations should be given random discounts',
        met: true,
        value: true,
        primary: true,
        features: [{
          name: 'Mendix',
          has_parent: true,
          enabler: true,
          value: 1,
          priority: 1,
          is_stratergic: true,
          reason: '',
          criteria: 'usability',
          approver: 'Sponsor',
          status: 'Operational',
          first_added: '10-12-2016',
          improved_in: '10-12-2016',
          prime_user: {
            name: 'John Doe',
            design_goal: 'Lorem Ipsum',
            mockups: [{
              name: 'mockup 1'
            }, {
              name: 'mockup 2'
            }]
          },
          additional_users: [{
            name: 'Add John',
            design_goal: 'Lorem Design Ipsum'
          }, {
            name: 'Add John',
            design_goal: 'Lorem Design Ipsum'
          }],
          competitors: 'Dont Offer',
          stories: [{
            name: 'Story A',
            description: 'Lorem Ipsum',
            enabler: true,
            value: 2,
            priority: 1,
            is_stratergic: true,
            status: 'Operational',
            first_added: '10-12-2016',
            improved_in: '10-12-2016'
          }, {
            name: 'Story B',
            description: 'Lorem Ipsum',
            enabler: true,
            value: 2,
            priority: 1,
            is_stratergic: true,
            status: 'Operational',
            first_added: '10-12-2016',
            improved_in: '10-12-2016'
          }]
        }, {
          name: 'Story Teller',
          has_parent: true,
          enabler: true,
          value: 1,
          priority: 3,
          is_stratergic: true,
          reason: '',
          criteria: 'usability',
          approver: 'Sponsor',
          status: 'Operational',
          first_added: '10-12-2016',
          improved_in: '10-12-2016',
          prime_user: {
            name: 'John Doe',
            design_goal: 'Lorem Ipsum',
            mockups: [{
              name: 'mockup 1'
            }, {
              name: 'mockup 2'
            }]
          },
          additional_users: [{
            name: 'Add John',
            design_goal: 'Lorem Design Ipsum'
          }, {
            name: 'Add John',
            design_goal: 'Lorem Design Ipsum'
          }],
          competitors: 'Dont Offer',
          stories: [{
            name: 'Story C',
            description: 'Lorem Ipsum',
            enabler: true,
            value: 2,
            priority: 1,
            is_stratergic: true,
            status: 'Operational',
            first_added: '10-12-2016',
            improved_in: '10-12-2016'
          }, {
            name: 'Story D',
            description: 'Lorem Ipsum',
            enabler: true,
            value: 2,
            priority: 1,
            is_stratergic: true,
            status: 'Operational',
            first_added: '10-12-2016',
            improved_in: '10-12-2016'
          }]
        }]
      }],
      selectedPainPointPleaser: {}
    };
  },
  computed: {
    filteredPainPointPleasers: function filteredPainPointPleasers() {
      var pain_point_pleasers = '';
      pain_point_pleasers = this.pain_point_pleasers.filter(this.searchPainPointFilter);
      pain_point_pleasers = pain_point_pleasers.filter(this.metFilter);
      pain_point_pleasers = pain_point_pleasers.filter(this.unmetFilter);
      pain_point_pleasers = pain_point_pleasers.filter(this.painFilter);
      pain_point_pleasers = pain_point_pleasers.filter(this.pleaserFilter);
      return pain_point_pleasers;
    }
  },
  mounted: function mounted() {},
  methods: {
    getPriorityIcon: function getPriorityIcon(priority) {
      return 'priority-' + priority;
    },
    getValueIcon: function getValueIcon(value) {
      return 'dollar-' + value;
    },
    searchPainPointFilter: function searchPainPointFilter(data) {
      return !this.pain_point_pleasers_search || data.feature.toLowerCase().includes(this.pain_point_pleasers_search.toLowerCase());
    },
    metFilter: function metFilter(data) {
      if (this.checked_met) {
        return data.met === true;
      } else {
        return true;
      }
    },
    unmetFilter: function unmetFilter(data) {
      if (this.checked_unmet) {
        return data.met === false;
      } else {
        return true;
      }
    },
    painFilter: function painFilter(data) {
      if (this.checked_pain) {
        return data.type === 'pain_point';
      } else {
        return true;
      }
    },
    pleaserFilter: function pleaserFilter(data) {
      if (this.checked_pleaser) {
        return data.type === 'pleaser';
      } else {
        return true;
      }
    },
    selectPainPointPleaser: function selectPainPointPleaser(data) {
      this.selectedPainPointPleaser = data;
      this.$emit('clicked', data);
    }
  }
};