"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("item-list", {
    attrs: {
      title: "Item",
      "create-title": "",
      items: _vm.items,
      "fuzzy-search": true
    },
    on: {
      click: _vm.selectItem,
      create: _vm.createItem
    }
  })], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 18
    }
  }, [_vm._v("\n        " + _vm._s(_vm.selectedItem.title) + "\n      ")])], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Create Item",
      visible: _vm.createItemDialog,
      width: "30%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.createItemDialog = $event;
      }
    }
  }, [_c("span", [_vm._v("Create UI")]), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "micro"
    },
    on: {
      click: function click($event) {
        _vm.createItemDialog = false;
      }
    }
  }, [_vm._v("Cancel")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "micro",
      type: "primary"
    },
    on: {
      click: function click($event) {
        _vm.createItemDialog = false;
      }
    }
  }, [_vm._v("Create")])], 1)])], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;