"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-table", {
    attrs: {
      data: _vm.painPointPleaser.features,
      "default-sort": {
        prop: "name",
        order: "descending"
      }
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "name",
      label: "Feature Name",
      sortable: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.has_parent === false ? _c("span", [_vm._v("\n              (ORPHAN)\n            ")]) : _vm._e(), _vm._v(" "), _c("span", [_vm._v("\n              " + _vm._s(scope.row.name) + "\n            ")])];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "value",
      label: "Importance",
      sortable: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.is_stratergic ? _c("svg-icon", {
          attrs: {
            "icon-class": "chess-rook",
            title: "Stratergic"
          }
        }) : _vm._e(), _vm._v(" "), scope.row.value ? _c("svg-icon", {
          attrs: {
            "icon-class": _vm.getValueIcon(scope.row.value),
            title: "Value"
          }
        }) : _vm._e(), _vm._v(" "), scope.row.priority ? _c("svg-icon", {
          attrs: {
            "icon-class": _vm.getPriorityIcon(scope.row.priority),
            title: "Priority"
          }
        }) : _vm._e()];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "enabler",
      label: "Enabler",
      sortable: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.enabler ? _c("svg-icon", {
          attrs: {
            "icon-class": "gear"
          }
        }) : _vm._e()];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "status",
      label: "Status",
      sortable: ""
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "first_added",
      label: "First Added",
      sortable: ""
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "improved_in",
      label: "Improved In",
      sortable: ""
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "competitors",
      label: "Competitors",
      sortable: ""
    }
  })], 1)], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;