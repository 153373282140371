"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-row", {
    staticClass: "mt-10",
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-card", [_c("el-tabs", {
    attrs: {
      value: "Individual"
    },
    on: {
      "tab-click": _vm.handleClick
    }
  }, _vm._l(_vm.userTypes, function (user_type, index) {
    return _c("el-tab-pane", {
      key: index,
      attrs: {
        label: user_type.name,
        name: user_type.name
      }
    }, [_c("el-row", {
      attrs: {
        gutter: 20
      }
    }, [_c("el-col", {
      attrs: {
        span: 8
      }
    }, [_c("div", {
      staticClass: "sub-title-left"
    }, [_vm._v("Journey Map Diagram")]), _vm._v(" "), _c("div", {
      staticClass: "container text-center"
    }, [_c("img", {
      staticStyle: {
        "max-width": "100%",
        "max-height": "100%"
      },
      attrs: {
        src: "static/img/journeymap.png"
      }
    }), _vm._v(" "), _c("el-button", {
      attrs: {
        size: "mini"
      },
      on: {
        click: function click($event) {
          _vm.addUserJourneyMapDialog = true;
        }
      }
    }, [_vm._v(" Create / Edit")])], 1)]), _vm._v(" "), _c("el-col", {
      attrs: {
        span: 16
      }
    }, [_c("div", {
      staticClass: "sub-title-right"
    }, [_vm._v("Journey Summary")]), _vm._v(" "), _c("div", {
      staticClass: "container",
      staticStyle: {
        height: "265px",
        "overflow-y": "scroll"
      }
    }, [_c("el-row", {
      attrs: {
        gutter: 10
      }
    }, [_c("el-col", {
      attrs: {
        span: 6
      }
    }, [_c("el-card", {
      staticClass: "box-card"
    }, [_c("div", {
      staticClass: "clearfix",
      attrs: {
        slot: "header"
      },
      slot: "header"
    }, [_c("span", [_vm._v("Stage 1")])]), _vm._v(" "), _vm._l(4, function (o) {
      return _c("div", {
        key: o,
        staticClass: "text item"
      }, [_vm._v("\n                          " + _vm._s("Step item " + o) + "\n                        ")]);
    })], 2)], 1), _vm._v(" "), _c("el-col", {
      attrs: {
        span: 6
      }
    }, [_c("el-card", {
      staticClass: "box-card"
    }, [_c("div", {
      staticClass: "clearfix",
      attrs: {
        slot: "header"
      },
      slot: "header"
    }, [_c("span", [_vm._v("Stage 2")])]), _vm._v(" "), _vm._l(4, function (o) {
      return _c("div", {
        key: o,
        staticClass: "text item"
      }, [_vm._v("\n                          " + _vm._s("Step item " + o) + "\n                        ")]);
    })], 2)], 1), _vm._v(" "), _c("el-col", {
      attrs: {
        span: 6
      }
    }, [_c("el-card", {
      staticClass: "box-card"
    }, [_c("div", {
      staticClass: "clearfix",
      attrs: {
        slot: "header"
      },
      slot: "header"
    }, [_c("span", [_vm._v("Stage 3")])]), _vm._v(" "), _vm._l(4, function (o) {
      return _c("div", {
        key: o,
        staticClass: "text item"
      }, [_vm._v("\n                          " + _vm._s("Step item " + o) + "\n                        ")]);
    })], 2)], 1), _vm._v(" "), _c("el-col", {
      attrs: {
        span: 6
      }
    }, [_c("el-card", {
      staticClass: "box-card"
    }, [_c("div", {
      staticClass: "clearfix",
      attrs: {
        slot: "header"
      },
      slot: "header"
    }, [_c("span", [_vm._v("Stage 3")])]), _vm._v(" "), _vm._l(4, function (o) {
      return _c("div", {
        key: o,
        staticClass: "text item"
      }, [_vm._v("\n                          " + _vm._s("Step item " + o) + "\n                        ")]);
    })], 2)], 1)], 1), _vm._v(" "), _c("el-row", {
      staticClass: "mt-10",
      attrs: {
        gutter: 10
      }
    }, [_c("el-col", {
      attrs: {
        span: 6
      }
    }, [_c("el-card", {
      staticClass: "box-card"
    }, [_c("div", {
      staticClass: "clearfix",
      attrs: {
        slot: "header"
      },
      slot: "header"
    }, [_c("span", [_vm._v("Stage 1")])]), _vm._v(" "), _vm._l(4, function (o) {
      return _c("div", {
        key: o,
        staticClass: "text item"
      }, [_vm._v("\n                          " + _vm._s("Step item " + o) + "\n                        ")]);
    })], 2)], 1), _vm._v(" "), _c("el-col", {
      attrs: {
        span: 6
      }
    }, [_c("el-card", {
      staticClass: "box-card"
    }, [_c("div", {
      staticClass: "clearfix",
      attrs: {
        slot: "header"
      },
      slot: "header"
    }, [_c("span", [_vm._v("Stage 2")])]), _vm._v(" "), _vm._l(4, function (o) {
      return _c("div", {
        key: o,
        staticClass: "text item"
      }, [_vm._v("\n                          " + _vm._s("Step item " + o) + "\n                        ")]);
    })], 2)], 1), _vm._v(" "), _c("el-col", {
      attrs: {
        span: 6
      }
    }, [_c("el-card", {
      staticClass: "box-card"
    }, [_c("div", {
      staticClass: "clearfix",
      attrs: {
        slot: "header"
      },
      slot: "header"
    }, [_c("span", [_vm._v("Stage 3")])]), _vm._v(" "), _vm._l(4, function (o) {
      return _c("div", {
        key: o,
        staticClass: "text item"
      }, [_vm._v("\n                          " + _vm._s("Step item " + o) + "\n                        ")]);
    })], 2)], 1), _vm._v(" "), _c("el-col", {
      attrs: {
        span: 6
      }
    }, [_c("el-card", {
      staticClass: "box-card"
    }, [_c("div", {
      staticClass: "clearfix",
      attrs: {
        slot: "header"
      },
      slot: "header"
    }, [_c("span", [_vm._v("Stage 3")])]), _vm._v(" "), _vm._l(4, function (o) {
      return _c("div", {
        key: o,
        staticClass: "text item"
      }, [_vm._v("\n                          " + _vm._s("Step item " + o) + "\n                        ")]);
    })], 2)], 1)], 1)], 1)])], 1), _vm._v(" "), _c("el-row", [_c("el-col", {
      attrs: {
        span: 24
      }
    }, [_c("div", {
      staticClass: "sub-title-rounded mt-5"
    }, [_vm._v("Journey Details & Progress")]), _vm._v(" "), _c("div", {
      staticClass: "container"
    }, [_vm.journeyMapData[0].stages.length > 0 ? _c("el-table", {
      staticStyle: {
        width: "100%"
      },
      attrs: {
        data: _vm.journeyMapData[0].stages
      }
    }, [_c("el-table-column", {
      attrs: {
        label: "Name",
        prop: "name"
      }
    }), _vm._v(" "), _c("el-table-column", {
      attrs: {
        label: "Tasks"
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(scope) {
          return _vm._l(scope.row.tasks, function (task, sub_index) {
            return _c("el-popover", {
              key: sub_index,
              attrs: {
                trigger: "hover",
                placement: "top",
                "open-delay": 400,
                "close-delay": 400
              }
            }, [_c("p", [_vm._v("Task: " + _vm._s(task.task))]), _vm._v(" "), _c("p", [_vm._v("Intention: " + _vm._s(task.intention))]), _vm._v(" "), _c("div", {
              staticClass: "name-wrapper",
              attrs: {
                slot: "reference"
              },
              slot: "reference"
            }, [_c("el-tag", {
              attrs: {
                size: "mini"
              }
            }, [_vm._v(_vm._s(task.task))])], 1)]);
          });
        }
      }], null, true)
    }), _vm._v(" "), _c("el-table-column", {
      attrs: {
        label: "Pains"
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(scope) {
          return [_vm._l(scope.row.tasks, function (task, sub_index) {
            return _vm._l(task.pains, function (pain, sub_sub_index) {
              return _c("el-tag", {
                key: "pain" + sub_index + sub_sub_index,
                attrs: {
                  size: "mini"
                }
              }, [_vm._v(_vm._s(pain.pain_point))]);
            });
          })];
        }
      }], null, true)
    }), _vm._v(" "), _c("el-table-column", {
      attrs: {
        label: "Pleasers"
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(scope) {
          return [_vm._l(scope.row.tasks, function (task, sub_index) {
            return _vm._l(task.pleasers, function (pleaser, sub_sub_index) {
              return _c("el-tag", {
                key: "pleaser" + sub_index + sub_sub_index,
                attrs: {
                  size: "mini"
                }
              }, [_vm._v(_vm._s(pleaser.pleaser))]);
            });
          })];
        }
      }], null, true)
    })], 1) : _c("div", {
      staticClass: "text-center"
    }, [_vm._v("\n                    TBD\n                  ")])], 1)])], 1)], 1);
  }), 1)], 1)], 1)], 1), _vm._v(" "), _c("el-card", {
    staticClass: "mt-10"
  }, [_c("div", {
    staticClass: "text-right"
  }, [_c("el-button-group", {
    attrs: {
      size: "mini"
    }
  }, [_c("el-tooltip", {
    attrs: {
      effect: "dark",
      content: "Go To Define User Details",
      placement: "top-start"
    }
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      icon: "el-icon-arrow-left"
    },
    on: {
      click: _vm.goToDefineUserDetails
    }
  }, [_vm._v("Previous")])], 1), _vm._v(" "), _c("el-tooltip", {
    attrs: {
      effect: "dark",
      content: "Go To Mockup Storyboard",
      placement: "top-start"
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.goToMockupStoryboard
    }
  }, [_vm._v("Next"), _c("i", {
    staticClass: "el-icon-arrow-right"
  })])], 1)], 1)], 1)]), _vm._v(" "), _c("el-dialog", {
    attrs: {
      visible: _vm.addUserJourneyMapDialog,
      width: "97%",
      top: "1vh",
      center: "",
      "custom-class": "ujm-nearfull-modal"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addUserJourneyMapDialog = $event;
      }
    }
  }, [_c("div", {
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_vm._v("\n      " + _vm._s(_vm.computeJourneyMapTitle()) + "\n    ")]), _vm._v(" "), _vm.toggleJourneyMapSelection ? _c("user-journey-map-simple-component", {
    attrs: {
      "user-type": _vm.activeUserType
    }
  }) : _c("user-journey-map-matrix-component", {
    attrs: {
      "user-type": _vm.activeUserType
    }
  }), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "button"
    },
    slot: "button"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.addUserJourneyMapDialog = false;
      }
    }
  }, [_vm._v("Cancel")])], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;