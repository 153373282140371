"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getMockups = getMockups;
var _request = _interopRequireDefault(require("@/utils/request"));
function getMockups() {
  return (0, _request.default)({
    url: '/create_user_value/mockups',
    method: 'GET'
  });
}