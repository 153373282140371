"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getEmail = getEmail;
exports.getJWTToken = getJWTToken;
exports.getToken = getToken;
exports.getUserID = getUserID;
exports.getUserPartyID = getUserPartyID;
exports.removeEmail = removeEmail;
exports.removeJWTToken = removeJWTToken;
exports.removeToken = removeToken;
exports.removeUserID = removeUserID;
exports.removeUserPartyID = removeUserPartyID;
exports.setEmail = setEmail;
exports.setJWTToken = setJWTToken;
exports.setToken = setToken;
exports.setUserID = setUserID;
exports.setUserPartyID = setUserPartyID;
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var TokenKey = 'superadmin-token';
function getToken() {
  return _jsCookie.default.get(TokenKey);
}
function getJWTToken() {
  return _jsCookie.default.get('JWTToken');
}
function setToken(token) {
  return _jsCookie.default.set(TokenKey, token, {
    sameSite: 'None',
    secure: true
  });
}
function setJWTToken(token) {
  return _jsCookie.default.set('JWTToken', token, {
    sameSite: 'None',
    secure: true
  });
}
function removeJWTToken() {
  return _jsCookie.default.remove('JWTToken');
}
function removeToken() {
  return _jsCookie.default.remove(TokenKey);
}
function getEmail() {
  return _jsCookie.default.get('UserEmail');
}
function setEmail(email) {
  return _jsCookie.default.set('UserEmail', email, {
    sameSite: 'None',
    secure: true
  });
}
function removeEmail() {
  return _jsCookie.default.remove('UserEmail');
}
function getUserID() {
  return _jsCookie.default.get('UserID');
}
function setUserID(userID) {
  return _jsCookie.default.set('UserID', userID, {
    sameSite: 'None',
    secure: true
  });
}
function removeUserID() {
  return _jsCookie.default.remove('UserID');
}
function getUserPartyID() {
  return _jsCookie.default.get('UserPartyID');
}
function setUserPartyID(userPartyID) {
  return _jsCookie.default.set('UserPartyID', userPartyID, {
    sameSite: 'None',
    secure: true
  });
}
function removeUserPartyID() {
  return _jsCookie.default.remove('UserPartyID');
}