"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("g", {
    attrs: {
      refs: "task_details_popover_container"
    },
    on: {
      mouseover: _vm.emitOnMouseOver,
      mouseleave: function mouseleave($event) {
        return _vm.closeTaskDetailsPopover();
      }
    }
  }, [_c("g", {
    attrs: {
      refs: "task_details_popover_container_up_arrow"
    }
  }, [_c("path", {
    attrs: {
      d: _vm.generateMarker(),
      "stroke-width": "1",
      fill: "#447799"
    }
  })]), _vm._v(" "), _c("filter", {
    attrs: {
      id: "blur"
    }
  }, [_c("feDropShadow", {
    attrs: {
      dx: "2",
      gy: "2",
      "std-deviation": "3",
      "flood-opacity": "0.5"
    }
  })], 1), _vm._v(" "), _c("rect", {
    staticClass: "backdrop",
    attrs: {
      x: _vm.x - 22,
      y: _vm.y,
      rx: 3,
      width: _vm.width,
      height: _vm.computeHeight + 14,
      fill: "#304174",
      stroke: "rgba(0,112,192,0.2)",
      "stroke-width": "0"
    }
  }), _vm._v(" "), _c("rect", {
    attrs: {
      x: _vm.x - 22,
      y: _vm.y,
      width: _vm.width,
      height: _vm.computeHeight + 14,
      fill: "rgba(255,255,255)"
    }
  }), _vm._v(" "), _c("g", {
    attrs: {
      refs: "task_details_popover_title"
    }
  }, [_c("rect", {
    attrs: {
      x: _vm.x - 22,
      y: _vm.y,
      width: _vm.width,
      height: 30,
      fill: "#447799"
    }
  }), _vm._v(" "), _c("svg-icon", {
    staticStyle: {
      "font-size": "12px"
    },
    attrs: {
      "icon-class": "ujm-step-inv-w",
      x: _vm.x - 21,
      y: _vm.y,
      width: 30,
      height: "28"
    }
  }), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "#fff"
    },
    attrs: {
      x: _vm.x + 10,
      y: _vm.y + 20,
      "text-anchor": "start",
      "font-size": "1.05em",
      "font-weight": "normal"
    }
  }, [_vm._v(_vm._s(_vm.truncateString(_vm.step.title, 45)))]), _vm._v(" "), !_vm.readOnly ? _c("g", {
    attrs: {
      refs: "task_details_popover_title_detail"
    },
    on: {
      click: function click($event) {
        $event.preventDefault();
        return _vm.createStepIdea();
      }
    }
  }, [_c("rect", {
    staticClass: "buttonhover",
    attrs: {
      x: _vm.x + _vm.width - 243,
      y: _vm.y + 3,
      width: 78,
      height: 22,
      rx: 3,
      ry: 3,
      "stroke-width": "1",
      stroke: "lightgrey",
      fill: "ghostwhite"
    }
  }), _vm._v(" "), _c("text", {
    staticClass: "textpointer",
    staticStyle: {
      fill: "#304174"
    },
    attrs: {
      x: _vm.x + _vm.width - 205,
      y: _vm.y + 19,
      "text-anchor": "middle",
      "font-size": "0.9em",
      "font-weight": "normal"
    }
  }, [_vm._v("Create Idea")])]) : _vm._e(), _vm._v(" "), _c("g", {
    attrs: {
      refs: "task_details_popover_title_detail"
    },
    on: {
      click: function click($event) {
        $event.preventDefault();
        return _vm.displayTaskDetails();
      }
    }
  }, [_c("rect", {
    staticClass: "buttonhover",
    attrs: {
      x: _vm.x + _vm.width - 154,
      y: _vm.y + 3,
      width: 54,
      height: 22,
      rx: 2,
      ry: 2,
      "stroke-width": "1",
      stroke: "lightgrey",
      fill: "ghostwhite"
    }
  }), _vm._v(" "), _c("text", {
    staticClass: "textpointer",
    staticStyle: {
      fill: "#304174"
    },
    attrs: {
      x: _vm.x + _vm.width - 128,
      y: _vm.y + 19,
      "text-anchor": "middle",
      "font-size": "0.9em",
      "font-weight": "normal"
    }
  }, [_vm._v("Details")])]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "task_details_popover_title_close"
    }
  }, [_c("foreignObject", {
    attrs: {
      x: _vm.x + _vm.width - 60 - 22.5,
      y: _vm.y + 2.5,
      width: 24,
      height: 24
    }
  }, [_c("el-button", {
    staticStyle: {
      "background-color": "darkred",
      "border-color": "darkred",
      width: "18pt",
      height: "18pt",
      "font-size": "1.1em",
      padding: "1pt 0 0 0"
    },
    attrs: {
      type: "danger",
      size: "mini",
      circle: "",
      icon: "el-icon-delete"
    },
    on: {
      click: function click($event) {
        $event.preventDefault();
        return _vm.deleteStep();
      }
    }
  })], 1)]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "task_details_popover_title_close"
    },
    on: {
      click: function click($event) {
        $event.preventDefault();
        return _vm.closeTaskDetailsPopover.apply(null, arguments);
      }
    }
  }, [_c("rect", {
    attrs: {
      x: _vm.x + _vm.width - 30 - 22,
      y: _vm.y + 3,
      rx: 15,
      ry: 15,
      width: 24,
      height: 24,
      fill: "darkred"
    }
  }), _vm._v(" "), _c("foreignObject", {
    attrs: {
      x: _vm.x + _vm.width - 30 - 22,
      y: _vm.y + 6,
      width: 24,
      height: 24
    }
  }, [_c("div", {
    staticClass: "text-center textpointer",
    staticStyle: {
      color: "#FFF",
      "padding-top": "2pt",
      "padding-left": "-1pt"
    }
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "12px"
    },
    attrs: {
      "icon-class": "cross"
    }
  })], 1)])])], 1), _vm._v(" "), _c("g", {
    attrs: {
      refs: "task_details_popover_tools"
    }
  }, [_c("g", {
    attrs: {
      refs: "experience_details_popover_tools_experience_pain"
    }
  }, [_c("text", {
    staticStyle: {
      fill: "#0070c0"
    },
    attrs: {
      x: _vm.x + 10 - 22,
      y: _vm.y + 50,
      "text-anchor": "start",
      "font-size": "1.0em",
      "font-weight": "bold"
    }
  }, [_vm._v("Step Description:")]), _vm._v(" "), _c("foreignObject", {
    attrs: {
      x: _vm.x + 10 - 22,
      y: _vm.y + 60,
      width: _vm.width * 0.57,
      height: "90"
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "0.9em",
      fill: "#0070c0",
      "font-weight": "normal",
      color: "#0070c0",
      height: "70px",
      "max-height": "70pt",
      "overflow-y": "hidden"
    }
  }, [_vm._v("\n          " + _vm._s(_vm.step.description) + "\n        ")])])]), _vm._v(" "), _c("g", [_c("foreignObject", {
    staticStyle: {
      "text-align": "center",
      "font-size": "1.0em",
      "font-weight": "normal"
    },
    attrs: {
      x: _vm.x + _vm.width - 378,
      y: _vm.y + 35,
      width: 40,
      height: 22
    }
  }, [_vm.ideas.length > 0 ? _c("div", {
    staticStyle: {
      "margin-top": "0px",
      color: "#E6A23C",
      "font-size": "1.0em",
      "font-weight": "100"
    }
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "14px",
      color: "#E6A23C",
      "margin-top": "4px"
    },
    attrs: {
      "icon-class": "idea"
    }
  }), _vm._v(" "), _c("span", {
    staticStyle: {
      "margin-left": "2px",
      "font-size": "1.0em"
    }
  }, [_vm._v(_vm._s(_vm.ideas.length))])], 1) : _vm._e()]), _vm._v(" "), _c("foreignObject", {
    staticStyle: {
      "text-align": "center",
      "font-size": "1.0em",
      "font-weight": "normal"
    },
    attrs: {
      x: _vm.x + _vm.width - 337,
      y: _vm.y + 38,
      width: 48,
      height: 22
    }
  }, [_c("div", {
    staticStyle: {
      "border-radius": "10pt",
      border: "solid 1pt lightgrey",
      height: "14pt",
      "font-size": "0.8em",
      padding: "1pt"
    }
  }, [_vm._v(" " + _vm._s(_vm.step.priority) + " ")])]), _vm._v(" "), _c("foreignObject", {
    staticStyle: {
      "text-align": "center",
      "font-size": "1.3em",
      "font-weight": "normal"
    },
    attrs: {
      x: _vm.x + _vm.width - 293,
      y: _vm.y + 38,
      width: 40,
      height: 22
    }
  }, [_c("svg-icon", {
    class: _vm.computeValueFontSize(_vm.step.importance),
    staticStyle: {
      color: "blue",
      "margin-top": "1px"
    },
    attrs: {
      "icon-class": _vm.computeCustomerValueIcon(_vm.step.importance)
    }
  })], 1)]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "task_details_popover_tools_progress"
    }
  }, [_c("text", {
    staticStyle: {
      fill: "#0070c0"
    },
    attrs: {
      x: _vm.x + _vm.width * 2 / 3 - 25 - 22,
      y: _vm.y + 50,
      "text-anchor": "start",
      "font-size": "1em",
      "font-weight": "bolder"
    }
  }, [_vm._v("Progress")]), _vm._v(" "), _c("foreignObject", {
    attrs: {
      x: _vm.x + _vm.width * 0.8,
      y: _vm.y + 30,
      width: 80,
      height: 80
    }
  }, [_c("pie-chart", {
    attrs: {
      inprogress: 34,
      done: 10,
      pending: 56,
      height: "80px",
      width: "80px"
    }
  })], 1), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "#0070c0"
    },
    attrs: {
      x: _vm.x + _vm.width * 2 / 3 - 25 - 22,
      y: _vm.y + 70,
      "text-anchor": "start",
      "font-size": "0.9em",
      "font-weight": "normal"
    }
  }, [_vm._v("56% Not Started")]), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "#0070c0"
    },
    attrs: {
      x: _vm.x + _vm.width * 2 / 3 - 25 - 22,
      y: _vm.y + 86,
      "text-anchor": "start",
      "font-size": "0.9em",
      "font-weight": "normal"
    }
  }, [_vm._v("34% In Progress")]), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "#0070c0"
    },
    attrs: {
      x: _vm.x + _vm.width * 2 / 3 - 25 - 22,
      y: _vm.y + 102,
      "text-anchor": "start",
      "font-size": "0.9em",
      "font-weight": "normal"
    }
  }, [_vm._v("10% Done")])])]), _vm._v(" "), _c("line", {
    attrs: {
      x1: _vm.x - 22,
      y1: _vm.y + 140,
      x2: _vm.x + _vm.width - 22,
      y2: _vm.y + 140,
      fill: "#304174",
      "stroke-width": "1",
      stroke: "#304174"
    }
  }), _vm._v(" "), _c("g", {
    attrs: {
      refs: "task_details_popover_details"
    }
  }, [!_vm.readOnly ? _c("g", {
    attrs: {
      refs: "task_details_popover_details_experience"
    }
  }, [_c("text", {
    staticStyle: {
      fill: "#0070c0"
    },
    attrs: {
      x: _vm.x + 10 - 22,
      y: _vm.y + 167,
      "text-anchor": "start",
      "font-size": "1em",
      "font-weight": "bolder"
    }
  }, [_vm._v("Step Experiences")]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "task_details_popover_tools_experience"
    }
  }, [_c("g", {
    attrs: {
      refs: "task_details_popover_tools_experience_challenge"
    },
    on: {
      mouseover: function mouseover($event) {
        _vm.challengeHover = true;
      },
      mouseleave: function mouseleave($event) {
        _vm.challengeHover = false;
      },
      click: function click($event) {
        return _vm.createChallenge();
      }
    }
  }, [_c("rect", {
    staticClass: "buttonhover",
    attrs: {
      x: _vm.x + _vm.width * 0.2 + 50,
      y: _vm.y + 150,
      rx: 3,
      ry: 3,
      width: 105,
      height: 24,
      stroke: "lightgrey",
      fill: "ghostwhite"
    }
  }), _vm._v(" "), _c("circle", {
    attrs: {
      cx: _vm.x + _vm.width * 0.2 + 50,
      cy: _vm.y + 150 + 12,
      r: "12",
      fill: "white",
      "stroke-width": "1.5px",
      stroke: "lightgrey"
    }
  }), _vm._v(" "), _c("foreignObject", {
    attrs: {
      x: _vm.x + _vm.width * 0.2 + 5 + 30,
      y: _vm.y + 150,
      width: 30,
      height: 30
    }
  }, [_c("div", {
    staticClass: "text-center buttonhover",
    staticStyle: {
      color: "#4672c4"
    }
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "20px",
      color: "green"
    },
    attrs: {
      "icon-class": "oscurity"
    }
  })], 1)]), _vm._v(" "), _c("text", {
    staticClass: "textpointer",
    staticStyle: {
      fill: "#304174"
    },
    attrs: {
      x: _vm.x + _vm.width * 0.2 + 35 + 34,
      y: _vm.y + 167,
      "text-anchor": "start",
      "font-size": "0.9em",
      "font-weight": "normal"
    }
  }, [_vm._v("Add Obscurity")])]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "task_details_popover_tools_experience_gain"
    },
    on: {
      mouseover: function mouseover($event) {
        _vm.gainHover = true;
      },
      mouseleave: function mouseleave($event) {
        _vm.gainHover = false;
      },
      click: function click($event) {
        return _vm.createGain();
      }
    }
  }, [_c("rect", {
    staticClass: "buttonhover",
    attrs: {
      x: _vm.x + _vm.width * 0.4 + 58,
      y: _vm.y + 150,
      rx: 3,
      ry: 3,
      width: 75,
      height: 24,
      stroke: "lightgrey",
      fill: "ghostwhite"
    }
  }), _vm._v(" "), _c("foreignObject", {
    attrs: {
      x: _vm.x + _vm.width * 0.4 + 5 + 39,
      y: _vm.y + 150,
      width: 30,
      height: 30
    }
  }, [_c("div", {
    staticClass: "text-center buttonhover",
    staticStyle: {
      color: "#4672c4"
    }
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "24px",
      color: "green"
    },
    attrs: {
      "icon-class": "smiley"
    }
  })], 1)]), _vm._v(" "), _c("text", {
    staticClass: "textpointer",
    staticStyle: {
      fill: "#304174"
    },
    attrs: {
      x: _vm.x + _vm.width * 0.4 + 35 + 42,
      y: _vm.y + 167,
      "text-anchor": "start",
      "font-size": "0.9em",
      "font-weight": "normal"
    }
  }, [_vm._v("Add Gain")])]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "task_details_popover_tools_experience_pain"
    },
    on: {
      mouseover: function mouseover($event) {
        _vm.painHover = true;
      },
      mouseleave: function mouseleave($event) {
        _vm.painHover = false;
      },
      click: function click($event) {
        return _vm.createPain();
      }
    }
  }, [_c("rect", {
    staticClass: "buttonhover",
    attrs: {
      x: _vm.x + _vm.width * 0.6 + 32,
      y: _vm.y + 150,
      rx: 3,
      ry: 3,
      width: 75,
      height: 24,
      stroke: "lightgrey",
      fill: "ghostwhite"
    }
  }), _vm._v(" "), _c("foreignObject", {
    attrs: {
      x: _vm.x + _vm.width * 0.6 + 5 + 16,
      y: _vm.y + 150,
      width: 30,
      height: 30
    }
  }, [_c("div", {
    staticClass: "text-center buttonhover",
    staticStyle: {
      color: "#4672c4"
    }
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "24px"
    },
    attrs: {
      "icon-class": "danger-circle"
    }
  })], 1)]), _vm._v(" "), _c("text", {
    staticClass: "textpointer",
    staticStyle: {
      fill: "#304174"
    },
    attrs: {
      x: _vm.x + _vm.width * 0.6 + 35 + 19,
      y: _vm.y + 167,
      "text-anchor": "start",
      "font-size": "0.9em",
      "font-weight": "normal"
    }
  }, [_vm._v("Add Pain")])]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "task_details_popover_tools_experience_wow"
    },
    on: {
      mouseover: function mouseover($event) {
        _vm.wowHover = true;
      },
      mouseleave: function mouseleave($event) {
        _vm.wowHover = false;
      },
      click: function click($event) {
        return _vm.createWow();
      }
    }
  }, [_c("rect", {
    staticClass: "buttonhover",
    attrs: {
      x: _vm.x + _vm.width * 0.8 + 8,
      y: _vm.y + 150,
      rx: 3,
      ry: 3,
      width: 75,
      height: 24,
      stroke: "lightgrey",
      fill: "ghostwhite"
    }
  }), _vm._v(" "), _c("foreignObject", {
    attrs: {
      x: _vm.x + _vm.width * 0.8 + 5 - 10,
      y: _vm.y + 150,
      width: 30,
      height: 30
    }
  }, [_c("div", {
    staticClass: "text-center buttonhover",
    staticStyle: {
      color: "#4672c4"
    }
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "24px"
    },
    attrs: {
      "icon-class": "wow_blue"
    }
  })], 1)]), _vm._v(" "), _c("text", {
    staticClass: "textpointer",
    staticStyle: {
      fill: "#304174"
    },
    attrs: {
      x: _vm.x + _vm.width * 0.8 + 35 - 7,
      y: _vm.y + 167,
      "text-anchor": "start",
      "font-size": "0.9em",
      "font-weight": "normal"
    }
  }, [_vm._v("Add Wow")])])]), _vm._v(" "), _vm.experiencesList.length > 1 ? _c("g", {
    attrs: {
      refs: "step_details_popover_tools_experience_pagination"
    }
  }, _vm._l(_vm.experiencesList, function (experienceChunk, experience_chunk_index) {
    return _c("g", {
      key: experience_chunk_index,
      attrs: {
        refs: "step_details_popover_tools_experience_pagination_items"
      }
    }, [_c("g", {
      attrs: {
        refs: "step_details_popover_tools_experience_pagination_arrow_nav"
      }
    }, [_c("foreignObject", {
      attrs: {
        x: _vm.x + _vm.width / 2 - 50,
        y: _vm.y + 189,
        width: 15,
        height: 15
      }
    }, [_vm.activeExperienceChunk > 0 ? _c("div", {
      staticClass: "text-center",
      on: {
        click: function click($event) {
          $event.preventDefault();
          $event.stopPropagation();
          _vm.activeExperienceChunk = _vm.activeExperienceChunk - 1;
        }
      }
    }, [_c("i", {
      staticClass: "el-icon-caret-left",
      on: {
        click: function click($event) {
          $event.preventDefault();
          $event.stopPropagation();
          _vm.activeExperienceChunk = _vm.activeExperienceChunk - 1;
        }
      }
    })]) : _vm._e()]), _vm._v(" "), _c("text", {
      staticStyle: {
        cursor: "pointer"
      },
      attrs: {
        x: _vm.x + _vm.width / 2,
        y: _vm.y + 199,
        "text-anchor": "middle",
        "font-size": "1em",
        "font-weight": "normal"
      }
    }, [_vm._v("\n              Page " + _vm._s(_vm.activeExperienceChunk + 1) + " / " + _vm._s(_vm.experiencesList.length) + "\n            ")]), _vm._v(" "), _c("foreignObject", {
      attrs: {
        x: _vm.x + _vm.width / 2 + 50,
        y: _vm.y + 189,
        width: 15,
        height: 15
      }
    }, [_vm.activeExperienceChunk < _vm.experiencesList.length - 1 ? _c("div", {
      staticClass: "text-center",
      on: {
        click: function click($event) {
          $event.stopPropagation();
          $event.preventDefault();
          _vm.activeExperienceChunk = _vm.activeExperienceChunk + 1;
        }
      }
    }, [_c("i", {
      staticClass: "el-icon-caret-right",
      on: {
        click: function click($event) {
          $event.stopPropagation();
          $event.preventDefault();
          _vm.activeExperienceChunk = _vm.activeExperienceChunk + 1;
        }
      }
    })]) : _vm._e()])])]);
  }), 0) : _vm._e(), _vm._v(" "), _vm._l(_vm.experiencesList, function (experienceChunk, chunk_sub_index) {
    return _c("g", {
      key: chunk_sub_index,
      attrs: {
        refs: "task_details_popover_details_experience_chunk_list"
      }
    }, _vm._l(experienceChunk, function (experience, sub_index) {
      return chunk_sub_index === _vm.activeExperienceChunk ? _c("g", {
        key: chunk_sub_index + "_" + sub_index,
        attrs: {
          refs: "task_details_popover_details_experience_list"
        }
      }, [experienceChunk.length > 1 ? _c("line", {
        attrs: {
          x1: _vm.x + 45 - 22,
          y1: _vm.y + 209 + sub_index * 35,
          x2: _vm.x + _vm.width - 50 - 22,
          y2: _vm.y + 209 + sub_index * 35,
          fill: "#126661",
          "stroke-width": "0.25",
          stroke: "#126661"
        }
      }) : _vm._e(), _vm._v(" "), experience.journeyExperience.experiencetype === "WOW" ? _c("foreignObject", {
        attrs: {
          x: _vm.x + 10 - 22,
          y: _vm.y + 213 + sub_index * 35,
          width: 30,
          height: 30
        }
      }, [_c("div", {
        staticClass: "text-center",
        staticStyle: {
          color: "#4672c4"
        }
      }, [_c("svg-icon", {
        staticStyle: {
          "font-size": "16px"
        },
        attrs: {
          "icon-class": "wow_blue"
        }
      })], 1)]) : _vm._e(), _vm._v(" "), experience.journeyExperience.experiencetype === "GAIN" ? _c("foreignObject", {
        attrs: {
          x: _vm.x + 10 - 22,
          y: _vm.y + 213 + sub_index * 35,
          width: 30,
          height: 30
        }
      }, [_c("div", {
        staticClass: "text-center",
        staticStyle: {
          color: "#4672c4"
        }
      }, [_c("svg-icon", {
        staticStyle: {
          "font-size": "16px"
        },
        attrs: {
          "icon-class": "smiley"
        }
      })], 1)]) : _vm._e(), _vm._v(" "), experience.journeyExperience.experiencetype === "PAIN" ? _c("foreignObject", {
        attrs: {
          x: _vm.x + 10 - 22,
          y: _vm.y + 213 + sub_index * 35,
          width: 30,
          height: 30
        }
      }, [_c("div", {
        staticClass: "text-center",
        staticStyle: {
          color: "#4672c4"
        }
      }, [_c("svg-icon", {
        staticStyle: {
          "font-size": "16px"
        },
        attrs: {
          "icon-class": "danger-circle"
        }
      })], 1)]) : _vm._e(), _vm._v(" "), experience.journeyExperience.experiencetype === "CHALLENGE" ? _c("foreignObject", {
        attrs: {
          x: _vm.x + 10 - 22,
          y: _vm.y + 213 + sub_index * 35,
          width: 30,
          height: 30
        }
      }, [_c("div", {
        staticClass: "text-center",
        staticStyle: {
          color: "#4672c4"
        }
      }, [_c("svg-icon", {
        staticStyle: {
          "font-size": "16px"
        },
        attrs: {
          "icon-class": "oscurity"
        }
      })], 1)]) : _vm._e(), _vm._v(" "), _c("foreignObject", {
        staticStyle: {
          fill: "#0070c0"
        },
        attrs: {
          x: _vm.x + 45 - 22,
          y: _vm.y + 201 + sub_index * 35,
          width: _vm.width - 55,
          height: "40",
          "font-weight": "normal"
        }
      }, [_c("div", {
        staticStyle: {
          "margin-top": "10pt",
          color: "#0070c0",
          overflow: "hidden",
          "font-size": "0.9em"
        }
      }, [_vm._v("\n              " + _vm._s(_vm.truncateString(experience.journeyExperience.title, 200)) + "\n            ")])]), _vm._v(" "), _c("foreignObject", {
        attrs: {
          x: _vm.x + _vm.width - 53.5,
          y: _vm.y + 215 + sub_index * 35,
          width: 20,
          height: 20
        }
      }, [_c("el-button", {
        staticStyle: {
          "background-color": "white",
          "border-color": "darkred",
          color: "darkred",
          width: "15pt",
          height: "15pt",
          "font-size": "1.1em",
          padding: "1pt 0 0 0"
        },
        attrs: {
          type: "danger",
          size: "mini",
          circle: "",
          icon: "el-icon-delete"
        }
      })], 1), _vm._v(" "), _vm.experiencesList[_vm.activeExperienceChunk].length === sub_index + 1 ? _c("line", {
        attrs: {
          x1: _vm.x - 5,
          y1: _vm.y + 209 + (sub_index + 1) * 35,
          x2: _vm.x + _vm.width - 30,
          y2: _vm.y + 209 + (sub_index + 1) * 35,
          fill: "#fff",
          "stroke-width": "1.5",
          stroke: "#ccc"
        }
      }) : _vm._e()]) : _vm._e();
    }), 0);
  })], 2) : _vm._e()])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;