"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("g", [_c("rect", {
    attrs: {
      x: parseInt(_vm.x) + 2.5,
      y: parseInt(_vm.y) + 2.5,
      fill: _vm.bgFillColor,
      stroke: _vm.bgStrokeColor,
      rx: "2.5",
      ry: "2.5",
      width: "25",
      height: "30",
      "stroke-width": "1"
    }
  }), _vm._v(" "), _c("circle", {
    attrs: {
      cx: parseInt(_vm.x) + 15,
      cy: parseInt(_vm.y) + 15,
      fill: _vm.avatarFillColor,
      stroke: _vm.avatarStrokeColor,
      "stroke-width": "1",
      r: "6",
      "fill-opacity": "0.5"
    }
  }), _vm._v(" "), _c("path", {
    attrs: {
      d: _vm.describeArc(parseInt(_vm.x) + 15, parseInt(_vm.y) + 33, 12, 270, 90),
      fill: _vm.avatarFillColor,
      stroke: _vm.avatarStrokeColor,
      "stroke-width": "1",
      "fill-opacity": "0.5"
    }
  })]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;