"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _default2 = exports.default = {
  name: 'Touchpoint',
  components: {},
  props: {
    touchpoint: {
      type: Object,
      required: true,
      default: function _default() {
        return {
          title: '',
          description: '',
          type: ''
        };
      }
    },
    x: {
      type: Number,
      default: 0
    },
    y: {
      type: Number,
      default: 0
    },
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
    stageIndex: {
      type: Number,
      default: 0
    },
    touchpointIndex: {
      type: Number,
      default: 0
    }
  },
  data: function data() {
    return {
      isHover: false
    };
  },
  computed: {},
  watch: {},
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    onSelect: function onSelect() {
      this.$emit('click', this.stageIndex, this.touchpointIndex);
    },
    onMouseOver: function onMouseOver() {
      this.isHover = true;
      this.$emit('mouseover', this.stageIndex, this.touchpointIndex);
    },
    onMouseLeave: function onMouseLeave() {
      this.isHover = false;
      this.$emit('mouseleave', this.stageIndex, this.touchpointIndex);
    },
    truncateDescription: function truncateDescription(description, length) {
      return this._.truncate(description, {
        'length': length,
        'separator': ' '
      });
    }
  }
};