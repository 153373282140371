"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "icons-container"
  }, [_vm._m(0), _vm._v(" "), _c("el-tabs", {
    attrs: {
      type: "border-card"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "Icons"
    }
  }, _vm._l(_vm.svgIcons, function (item) {
    return _c("div", {
      key: item,
      on: {
        click: function click($event) {
          _vm.handleClipboard(_vm.generateIconCode(item), $event);
        }
      }
    }, [_c("el-tooltip", {
      attrs: {
        placement: "top"
      }
    }, [_c("div", {
      attrs: {
        slot: "content"
      },
      slot: "content"
    }, [_vm._v("\n            " + _vm._s(_vm.generateIconCode(item)) + "\n          ")]), _vm._v(" "), _c("div", {
      staticClass: "icon-item"
    }, [_c("svg-icon", {
      attrs: {
        "icon-class": item,
        "class-name": "disabled"
      }
    }), _vm._v(" "), _c("span", [_vm._v(_vm._s(item))])], 1)])], 1);
  }), 0), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: "Element-UI Icons"
    }
  }, _vm._l(_vm.elementIcons, function (item) {
    return _c("div", {
      key: item,
      on: {
        click: function click($event) {
          _vm.handleClipboard(_vm.generateElementIconCode(item), $event);
        }
      }
    }, [_c("el-tooltip", {
      attrs: {
        placement: "top"
      }
    }, [_c("div", {
      attrs: {
        slot: "content"
      },
      slot: "content"
    }, [_vm._v("\n            " + _vm._s(_vm.generateElementIconCode(item)) + "\n          ")]), _vm._v(" "), _c("div", {
      staticClass: "icon-item"
    }, [_c("i", {
      class: "el-icon-" + item
    }), _vm._v(" "), _c("span", [_vm._v(_vm._s(item))])])])], 1);
  }), 0)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("aside", [_c("a", {
    attrs: {
      href: "https://panjiachen.github.io/vue-element-admin-site/guide/advanced/icon.html",
      target: "_blank"
    }
  }, [_vm._v("Add and use\n    ")])]);
}];
render._withStripped = true;