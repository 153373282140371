"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/taggedTemplateLiteral.js"));
var _FileUpload = _interopRequireDefault(require("@/components/FileUpload"));
var _axios = _interopRequireDefault(require("axios"));
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;
var _default2 = exports.default = {
  name: 'JourneyIdea',
  components: {
    FileUpload: _FileUpload.default
  },
  props: {
    idea: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      loading: false,
      vote_loading: false,
      editMode: false,
      statuses: [{
        label: 'Proposed',
        value: 'Proposed'
      }, {
        label: 'Evaluation',
        value: 'Evaluation'
      }, {
        label: 'Approved',
        value: 'Approved'
      }, {
        label: 'Starting Up',
        value: 'Starting Up'
      }, {
        label: 'Launched',
        value: 'Launched'
      }, {
        label: 'Abandoned',
        value: 'Abandoned'
      }]
    };
  },
  computed: {},
  mounted: function mounted() {},
  methods: {
    retrieveVotes: function retrieveVotes() {
      var _this = this;
      this.vote_loading = true;
      _axios.default.get(process.env.VUE_APP_REST_API_ENDPOINT + 'social/reaction?entityId=' + this.idea.id + '&extended=LEVEL0', {
        crossdomain: true,
        headers: {
          'Content-Type': 'application/json',
          'x-party-id': this.$store.getters.partyId,
          'x-user-id': this.$store.getters.id,
          'x-entity-id': this.idea.id,
          'x-entity-name': this.idea.title
        }
      }).then(function (data) {
        // handle success
        _this.vote_loading = false;
        _this.idea.score = data.data.data.voteup;
        _this.updateJourneyScore();
        // this.idea.votesDown = data.data.data.votedown
        // this.idea.score = data.data.data.totalReactions
      }).catch(function (data) {
        // handle error
        _this.vote_loading = false;
      }).finally(function () {
        // always executed
        _this.vote_loading = false;
      });
    },
    voteUp: function voteUp() {
      var _this2 = this;
      this.vote_loading = true;
      var headers = {
        'Content-Type': 'application/json',
        'x-party-id': this.$store.getters.partyId,
        'x-user-id': this.$store.getters.id,
        'x-entity-id': this.idea.id,
        'x-entity-name': this.idea.title
      };
      var data = {
        header: {
          userId: this.$store.getters.id,
          partyId: this.$store.getters.partyId
        },
        data: {
          reactionType: 'VOTEUP',
          entityId: this.idea.id,
          entityName: 'JourneyIdeation'
        }
      };
      _axios.default.post(process.env.VUE_APP_REST_API_ENDPOINT + 'social/reaction', data, {
        headers: headers
      }).then(function (response) {
        _this2.vote_loading = false;
        _this2.$notify({
          title: 'Success',
          message: 'Voted',
          type: 'success',
          duration: 2000
        });
        _this2.retrieveVotes();
      }).catch(function (error) {
        if (error.response.status === 400) {
          _this2.vote_loading = false;
          _this2.$notify({
            title: 'Error',
            message: 'Already voted',
            type: 'error',
            duration: 2000
          });
        }
      });
    },
    voteDown: function voteDown() {
      var _this3 = this;
      this.vote_loading = true;
      var headers = {
        'Content-Type': 'application/json',
        'x-party-id': this.$store.getters.partyId,
        'x-user-id': this.$store.getters.id,
        'x-entity-id': this.idea.id,
        'x-entity-name': this.idea.title
      };
      var data = {
        header: {
          userId: this.$store.getters.id,
          partyId: this.$store.getters.partyId
        },
        data: {
          reactionType: 'VOTEDOWN',
          entityId: this.idea.id,
          entityName: 'JourneyIdeation'
        }
      };
      _axios.default.post(process.env.VUE_APP_REST_API_ENDPOINT + 'social/reaction', data, {
        headers: headers
      }).then(function (response) {
        _this3.vote_loading = false;
        _this3.$notify({
          title: 'Success',
          message: 'Voted',
          type: 'success',
          duration: 2000
        });
        _this3.retrieveVotes();
      }).catch(function (error) {
        _this3.vote_loading = false;
        if (error.response.status === 400) {
          _this3.$notify({
            title: 'Error',
            message: 'Already voted',
            type: 'error',
            duration: 2000
          });
        }
      });
    },
    addFile: function addFile(file) {
      var _this4 = this;
      var fileobject = this.idea.fileobject;
      fileobject.push(file);
      this.loading = true;
      this.$apollo.mutate({
        // Query
        mutation: window._gql(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["mutation updateBusinessIdea($busIdea: UpdateBusinessIdeaInput!)\n          {\n            updateBusinessIdea(input: $busIdea) {\n              id\n              title\n              description\n              problemstatement\n              solution\n              valueproposition\n              additionalattributes\n              fileobject\n              status\n              score\n              customer_type\n              scope\n              scopename\n              type\n              linkedCoreParties(optional: true){\n                coreParty(optional: true){\n                  id\n                  businessname\n                }\n              }\n            }\n          }"]))),
        // Parameters
        variables: {
          'busIdea': {
            'id': this.idea.id,
            'fileobject': fileobject
          }
        }
      }).then(function (data) {
        _this4.$notify({
          title: 'Success',
          message: 'Created Successfully',
          type: 'success',
          duration: 2000
        });
        var idea = data.data.updateBusinessIdea;
        _this4.idea.fileobject = idea.fileobject;
        // Avoid page reload if possible
        // this.$emit('update', idea)
        _this4.loading = false;
      }).catch(function (error) {
        // Error
        _this4.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this4.loading = false;
      });
    },
    deleteFile: function deleteFile(index) {
      var _this5 = this;
      this.idea.fileobject.splice(index, 1);
      this.loading = true;
      this.$apollo.mutate({
        // Query
        mutation: window._gql(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["mutation updateBusinessIdea($busIdea: UpdateBusinessIdeaInput!)\n          {\n            updateBusinessIdea(input: $busIdea) {\n              id\n              title\n              description\n              problemstatement\n              solution\n              valueproposition\n              additionalattributes\n              fileobject\n              status\n              score\n              customer_type\n              scope\n              scopename\n              type\n              linkedCoreParties(optional: true){\n                coreParty(optional: true){\n                  id\n                  businessname\n                }\n              }\n            }\n          }"]))),
        // Parameters
        variables: {
          'busIdea': {
            'id': this.idea.id,
            'fileobject': this.idea.fileobject
          }
        }
      }).then(function (data) {
        _this5.$notify({
          title: 'Success',
          message: 'Created Successfully',
          type: 'success',
          duration: 2000
        });
        var idea = data.data.updateBusinessIdea;
        _this5.idea.fileobject = idea.fileobject;
        // Avoid page reload if possible
        // this.$emit('update', idea)
        _this5.loading = false;
      }).catch(function (error) {
        // Error
        _this5.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this5.loading = false;
      });
    },
    updateJourneyScore: function updateJourneyScore() {
      var _this6 = this;
      this.loading = true;
      this.$apollo.mutate({
        // Query
        mutation: window._gql(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["mutation updateBusinessIdea($busIdea: UpdateBusinessIdeaInput!)\n          {\n            updateBusinessIdea(input: $busIdea) {\n              id\n              title\n              description\n              problemstatement\n              solution\n              valueproposition\n              additionalattributes\n              fileobject\n              status\n              score\n              customer_type\n              scope\n              scopename\n              type\n              linkedCoreParties(optional: true){\n                coreParty(optional: true){\n                  id\n                  businessname\n                }\n              }\n            }\n          }"]))),
        // Parameters
        variables: {
          'busIdea': {
            'id': this.idea.id,
            'score': this.idea.score
          }
        }
      }).then(function (data) {
        /* this.$notify({
          title: 'Success',
          message: 'Created Successfully',
          type: 'success',
          duration: 2000
        })*/
        // var idea = data.data.updateBusinessIdea
        // this.$emit('update', idea)
        _this6.loading = false;
      }).catch(function (error) {
        // Error
        _this6.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this6.loading = false;
      });
    },
    updateJourney: function updateJourney() {
      var _this7 = this;
      this.loading = true;
      this.$apollo.mutate({
        // Query
        mutation: window._gql(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2.default)(["mutation updateBusinessIdea($busIdea: UpdateBusinessIdeaInput!)\n          {\n            updateBusinessIdea(input: $busIdea) {\n              id\n              title\n              description\n              problemstatement\n              solution\n              valueproposition\n              additionalattributes\n              fileobject\n              status\n              score\n              customer_type\n              scope\n              scopename\n              type\n              linkedCoreParties(optional: true){\n                coreParty(optional: true){\n                  id\n                  businessname\n                }\n              }\n            }\n          }"]))),
        // Parameters
        variables: {
          'busIdea': {
            'id': this.idea.id,
            'title': this.idea.title,
            'description': this.idea.description,
            'problemstatement': this.idea.problemstatement,
            'solution': this.idea.solution,
            'valueproposition': this.idea.valueproposition,
            'status': this.idea.status,
            'scope': this.idea.scope,
            'scopename': this.idea.scopename
          }
        }
      }).then(function (data) {
        _this7.$notify({
          title: 'Success',
          message: 'Created Successfully',
          type: 'success',
          duration: 2000
        });
        var idea = data.data.updateBusinessIdea;
        _this7.$emit('update', idea);
        _this7.editMode = false;
        _this7.loading = false;
      }).catch(function (error) {
        // Error
        _this7.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this7.editMode = false;
        _this7.loading = false;
      });
    },
    deleteIdea: function deleteIdea() {
      var _this8 = this;
      this.$confirm('This will permanently delete the Idea. Continue?', 'Warning', {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(function () {
        _this8.loading = true;
        _this8.$apollo.mutate({
          // Query
          mutation: window._gql(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2.default)(["mutation deleteBusinessIdea($busIdea: DeleteBusinessIdeaInput!)\n          {\n            deleteBusinessIdea(input: $busIdea) {\n            id\n            }\n          }"]))),
          // Parameters
          variables: {
            'busIdea': {
              'id': _this8.idea.id
            }
          }
        }).then(function (data) {
          _this8.$notify({
            title: 'Success',
            message: 'Deleted Successfully',
            type: 'success',
            duration: 2000
          });
          _this8.$emit('delete', _this8.idea.id);
          _this8.loading = false;
        }).catch(function (error) {
          // Error
          _this8.$notify({
            title: 'Error',
            message: error,
            type: 'error',
            duration: 2000
          });
          _this8.loading = false;
        });
      }).catch(function () {
        _this8.$message({
          type: 'info',
          message: 'Delete canceled'
        });
      });
    }
  }
};