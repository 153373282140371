"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "no-padding app-container"
  }, [_c("el-row", [_c("el-col", {
    staticStyle: {
      "background-color": "white"
    },
    attrs: {
      span: 24
    }
  }, [_c("el-row", {
    staticClass: "text-center",
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-row", [_c("el-col", {
    staticClass: "sub-title-rounded",
    attrs: {
      span: 24
    }
  }, [_vm._v("\n              Pain Points\n            ")])], 1), _vm._v(" "), _c("div", {
    staticClass: "bordered-container no-padding"
  }, [_c("el-row", [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("pie-chart-component", {
    attrs: {
      completed: 21,
      pending: 5,
      height: "300pt",
      width: "300pt"
    }
  })], 1)], 1)], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-row", [_c("el-col", {
    staticClass: "sub-title-rounded",
    attrs: {
      span: 24
    }
  }, [_vm._v("\n              Pleasers\n            ")])], 1), _vm._v(" "), _c("div", {
    staticClass: "bordered-container no-padding"
  }, [_c("el-row", [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("pie-chart-component", {
    attrs: {
      completed: 15,
      pending: 4,
      height: "300pt",
      width: "300pt"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;