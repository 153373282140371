"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-row", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading.container,
      expression: "loading.container"
    }],
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: _vm.IntegrationSidebarCollapsed ? 20 : 24
    }
  }, [_c("canvas", {
    staticStyle: {
      display: "none"
    },
    attrs: {
      id: "canvas",
      width: _vm.computeJourneyMapWidth,
      height: "2000px"
    }
  }), _vm._v(" "), _c("div", {
    staticStyle: {
      width: "99vw",
      position: "fixed",
      "margin-left": "1pt",
      "margin-top": "-14px"
    }
  }, [_c("el-row", {
    staticClass: "shadow",
    style: _vm.computeHeaderStyle
  }, [_c("el-col", {
    staticStyle: {
      "margin-top": "-10px"
    },
    attrs: {
      span: 8
    }
  }, [_c("table", [_c("tr", [_c("td", [_c("div", {
    staticStyle: {
      "font-size": "1.5em",
      "font-variant-caps": "small-caps",
      "letter-spacing": "-0.2pt",
      "margin-top": "-4pt"
    },
    attrs: {
      refs: "customer-journey-title"
    }
  }, [_c("span", {
    staticStyle: {
      "font-weight": "bold",
      "font-size": "1.1em",
      color: "#26428b"
    }
  }, [_vm._v("Offering: ")]), _vm._v(" "), _c("span", {
    staticStyle: {
      color: "rebeccapurple"
    }
  }, [_vm._v(_vm._s(_vm.offering.title))])])])]), _vm._v(" "), _c("tr", [_c("el-button-group", {
    staticStyle: {
      "border-radius": "8pt",
      "margin-top": "-6px"
    }
  }, [_c("el-button", {
    staticStyle: {
      "border-radius": "4pt",
      padding: "2pt 6pt 2pt 6pt",
      "font-size": "0.9em",
      "margin-right": "4pt"
    },
    attrs: {
      type: "secondary",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.addProduct();
      }
    }
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "14px",
      color: "navy"
    },
    attrs: {
      "icon-class": "pdg-product"
    }
  }), _vm._v("\n                      Add Product\n                    ")], 1), _vm._v(" "), _c("el-button", {
    staticStyle: {
      "border-radius": "4pt",
      padding: "2pt 6pt 2pt 6pt",
      "font-size": "0.9em",
      "margin-right": "4pt"
    },
    attrs: {
      type: "secondary",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.addService();
      }
    }
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "14px",
      color: "navy"
    },
    attrs: {
      "icon-class": "pdg-service"
    }
  }), _vm._v("\n                      Add Service\n                    ")], 1)], 1)], 1)])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 1
    }
  }, [_c("span", {
    staticStyle: {
      color: "grey",
      "font-variant-caps": "small-caps",
      float: "right",
      padding: "5pt 6pt 0 0"
    }
  }, [_vm._v("Zoom")])]), _vm._v(" "), _c("el-col", {
    attrs: {
      xs: 5,
      sm: 7,
      md: 6,
      lg: 8,
      xl: 5
    }
  }, [_c("el-slider", {
    staticClass: "sliderZoom",
    attrs: {
      step: 1,
      min: 50,
      max: 200,
      "show-input": "",
      "input-size": "mini"
    },
    on: {
      change: function change($event) {
        return _vm.zoomJourney();
      }
    },
    model: {
      value: _vm.journeyZoom,
      callback: function callback($$v) {
        _vm.journeyZoom = $$v;
      },
      expression: "journeyZoom"
    }
  })], 1), _vm._v(" "), _c("el-col", {
    staticClass: "hidden-md-and-down text-right pr-10",
    attrs: {
      xs: 10,
      sm: 10,
      md: 9,
      lg: 7,
      xl: 10
    }
  }, [_c("div", {
    attrs: {
      stlye: "position:relative; top:0"
    }
  }, [_c("el-button-group", {
    staticStyle: {
      "margin-top": "-1px"
    },
    attrs: {
      size: "mini"
    }
  }, [_c("el-popover", {
    attrs: {
      placement: "bottom",
      width: "200",
      trigger: "hover"
    }
  }, [_c("span", {
    attrs: {
      slot: ""
    },
    slot: "default"
  }, [_c("small", [_vm._v("Sync Offering")])]), _vm._v(" "), _c("el-button", {
    attrs: {
      slot: "reference",
      size: "mini",
      type: "secondary",
      icon: _vm.syncTicketProgress.loading ? "el-icon-loading" : "el-icon-refresh",
      circle: ""
    },
    on: {
      click: function click($event) {
        return _vm.initialiseSyncDialog();
      }
    },
    slot: "reference"
  })], 1)], 1), _vm._v(" "), _c("el-button-group", {
    staticStyle: {
      "margin-top": "-1px"
    },
    attrs: {
      size: "mini"
    }
  }, [_c("el-popover", {
    attrs: {
      placement: "bottom",
      title: _vm.isEntityPopoverVisible ? "Disable popovers" : "Enable popovers",
      width: "200",
      trigger: "hover"
    }
  }, [_c("el-button", {
    staticStyle: {
      padding: "4px"
    },
    attrs: {
      slot: "reference",
      size: "mini",
      type: "secondary",
      circle: ""
    },
    on: {
      click: function click($event) {
        _vm.isEntityPopoverVisible = !_vm.isEntityPopoverVisible;
      }
    },
    slot: "reference"
  }, [_vm.isEntityPopoverVisible ? _c("svg-icon", {
    staticStyle: {
      "font-size": "19px"
    },
    attrs: {
      "icon-class": "popovers-on"
    }
  }) : _vm._e(), _vm._v(" "), !_vm.isEntityPopoverVisible ? _c("svg-icon", {
    staticStyle: {
      "font-size": "19px"
    },
    attrs: {
      "icon-class": "popovers-off"
    }
  }) : _vm._e()], 1)], 1)], 1), _vm._v(" "), _vm.variants.length > 0 ? _c("el-dropdown", {
    attrs: {
      "split-button": "",
      type: "secondary",
      size: "mini"
    },
    on: {
      command: _vm.handleVariantsDropDownCommand
    }
  }, [_vm._v("\n                  Variants\n                  "), _c("el-dropdown-menu", {
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, _vm._l(_vm.variants, function (variant, index) {
    return _c("el-dropdown-item", {
      key: index,
      attrs: {
        command: variant.id
      }
    }, [_vm._v(_vm._s(variant.title))]);
  }), 1)], 1) : _vm._e(), _vm._v(" "), _c("el-dropdown", {
    attrs: {
      "split-button": "",
      type: "secondary",
      size: "mini"
    },
    on: {
      command: _vm.handleMenuDropDownCommand
    }
  }, [_c("i", {
    staticClass: "el-icon-menu"
  }), _vm._v(" "), _c("el-dropdown-menu", {
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, [!_vm.isTouchpointVisible ? _c("el-dropdown-item", {
    staticStyle: {
      "font-size": "1.0em"
    },
    attrs: {
      icon: "el-icon-s-unfold",
      command: "showTouchpoints"
    }
  }, [_vm._v("Show Touchpoint")]) : _c("el-dropdown-item", {
    staticStyle: {
      "font-size": "1.0em"
    },
    attrs: {
      icon: "el-icon-s-fold",
      command: "hideTouchPoints"
    }
  }, [_vm._v("Hide Touchpoint")]), _vm._v(" "), !_vm.isProductsVisible ? _c("el-dropdown-item", {
    staticStyle: {
      "font-size": "1.0em"
    },
    attrs: {
      icon: "el-icon-s-unfold",
      command: "showProducts"
    }
  }, [_vm._v("Show Products")]) : _c("el-dropdown-item", {
    staticStyle: {
      "font-size": "1.0em"
    },
    attrs: {
      icon: "el-icon-s-fold",
      command: "hideProducts"
    }
  }, [_vm._v("Hide Products")]), _vm._v(" "), !_vm.isServicesVisible ? _c("el-dropdown-item", {
    staticStyle: {
      "font-size": "1.0em"
    },
    attrs: {
      icon: "el-icon-s-unfold",
      command: "showServices"
    }
  }, [_vm._v("Show Services")]) : _c("el-dropdown-item", {
    staticStyle: {
      "font-size": "1.0em"
    },
    attrs: {
      icon: "el-icon-s-fold",
      command: "hideServices"
    }
  }, [_vm._v("Hide Services")]), _vm._v(" "), !_vm.isUnmappedVisible ? _c("el-dropdown-item", {
    staticStyle: {
      "font-size": "1.0em"
    },
    attrs: {
      icon: "el-icon-s-unfold",
      command: "showUnmapped"
    }
  }, [_vm._v("Show Unmapped")]) : _c("el-dropdown-item", {
    staticStyle: {
      "font-size": "1.0em"
    },
    attrs: {
      icon: "el-icon-s-fold",
      command: "hideUnmapped"
    }
  }, [_vm._v("Hide Unmapped")]), _vm._v(" "), !_vm.isRelationsVisible ? _c("el-dropdown-item", {
    staticStyle: {
      "font-size": "1.0em"
    },
    attrs: {
      icon: "el-icon-s-unfold",
      command: "showRelations"
    }
  }, [_vm._v("Show Relations")]) : _c("el-dropdown-item", {
    staticStyle: {
      "font-size": "1.0em"
    },
    attrs: {
      icon: "el-icon-s-fold",
      command: "hideRelations"
    }
  }, [_vm._v("Hide Relations")]), _vm._v(" "), _c("el-dropdown-item", {
    staticStyle: {
      "font-size": "1.0em"
    },
    attrs: {
      divided: "",
      icon: "el-icon-check",
      command: "externalTicket"
    }
  }, [_vm._v("Manage imported tickets (tbc)")]), _vm._v(" "), _c("el-dropdown-item", {
    staticStyle: {
      "font-size": "1.0em"
    },
    attrs: {
      icon: "el-icon-setting",
      command: "addIdeas"
    }
  }, [_vm._v("Add ideas")]), _vm._v(" "), _c("el-dropdown-item", {
    staticStyle: {
      "font-size": "1.0em"
    },
    attrs: {
      divided: "",
      icon: "el-icon-refresh",
      command: "approve"
    }
  }, [_vm._v("Approve (tbc)")]), _vm._v(" "), _c("el-dropdown-item", {
    staticStyle: {
      "font-size": "1.0em"
    },
    attrs: {
      icon: "el-icon-brush",
      command: "version"
    }
  }, [_vm._v("Version History")]), _vm._v(" "), _c("el-dropdown-item", {
    staticStyle: {
      "font-size": "1.0em"
    },
    attrs: {
      divided: "",
      icon: "el-icon-view",
      command: "recomputePos"
    }
  }, [_vm._v("Recompute Position")]), _vm._v(" "), _c("el-dropdown-item", {
    staticStyle: {
      "font-size": "1.0em"
    },
    attrs: {
      icon: "el-icon-view",
      command: "resetLayout"
    }
  }, [_vm._v("Reset Layout")]), _vm._v(" "), _c("el-dropdown-item", {
    staticStyle: {
      "font-size": "1.0em"
    },
    attrs: {
      divided: "",
      icon: "el-icon-setting",
      command: "settings"
    }
  }, [_vm._v("Settings")])], 1)], 1)], 1)])], 1)], 1), _vm._v(" "), _c("div", {
    staticStyle: {
      "overflow-x": "scroll",
      "margin-top": "50px"
    },
    attrs: {
      id: "journeyMapContainer"
    }
  }, [_c("svg", {
    staticClass: "journeyZoomer",
    attrs: {
      id: "journeyMapSVG",
      height: _vm.computeOfferingHeight,
      width: _vm.computeJourneyMapWidth,
      xmlns: "http://www.w3.org/2000/svg",
      version: "1.1"
    },
    on: {
      click: function click($event) {
        $event.preventDefault();
        $event.stopPropagation();
        return _vm.resetCanvas();
      }
    }
  }, [_c("g", {
    attrs: {
      refs: "model-offering-canvas"
    }
  }, [_vm.journeyMap.id ? _c("g", {
    attrs: {
      refs: "journey-map"
    }
  }, [_c("journey-map-component", {
    attrs: {
      "journey-map": _vm.journeyMap,
      "journey-map-width": _vm.computeJourneyMapWidth,
      "journey-map-height": _vm.computeJourneyMapHeight,
      offering: _vm.offering,
      settings: _vm.settings,
      reset: _vm.resetJourneyMap
    },
    on: {
      phasemouseover: _vm.phaseMouseOver,
      phasemouseleave: _vm.phaseMouseLeave,
      phaseclick: _vm.displayPhaseDetails,
      taskmouseover: _vm.taskMouseOver,
      taskmouseleave: _vm.taskMouseLeave,
      taskclick: _vm.displayTaskDetails,
      computedphasex: _vm.updatePhaseX,
      computedphasewidth: _vm.updatePhaseWidth,
      computedtaskx: _vm.updateTaskX,
      computedtasky: _vm.updateTaskY,
      "drag-coefficient": _vm.updateJourneyMapDragCoefficient
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.journeyMap.id && _vm.isTouchpointVisible ? _c("g", {
    attrs: {
      refs: "touchpoint"
    }
  }, [_c("touchpoints-component", {
    attrs: {
      x: 5,
      y: _vm.computeJourneyMapHeight + 10,
      width: _vm.computeJourneyMapWidth - 5,
      height: _vm.computeTouchpointHeight + 5,
      stages: _vm.sortedStages,
      "phase-x": _vm.phase_pos_x,
      "is-compact-menubar": false,
      "journey-map": _vm.journeyMap
    },
    on: {
      mouseover: _vm.onTouchpointMouseOver,
      mouseleave: _vm.onTouchpointMouseLeave,
      click: _vm.selectTouchpoint,
      "drag-tool": _vm.onTouchPointToolDragged
    }
  })], 1) : _vm._e(), _vm._v(" "), _c("g", {
    attrs: {
      refs: "entities"
    }
  }, [_vm.offering ? _c("g", {
    attrs: {
      refs: "model-offering"
    }
  }, [_c("entity-list-component", {
    attrs: {
      offering: _vm.offering,
      "journey-map": _vm.journeyMap,
      x: _vm.settings.container.offering.entity.border_start_pos,
      y: _vm.computeEntityPosY,
      "phase-x": _vm.phase_pos_x,
      width: _vm.computeJourneyMapWidth,
      settings: _vm.settings,
      "is-unmapped-visible": _vm.isUnmappedVisible,
      "is-product-visible": _vm.isProductsVisible,
      "is-service-visible": _vm.isServicesVisible,
      "is-touch-point-visible": _vm.isTouchpointVisible,
      "is-touch-point-collapsed": _vm.touchpoints[0].touchpoint.additionalattributes.minimized,
      "recompute-feature-pos": _vm.recomputeFeaturePos,
      "recompute-service-application-pos": _vm.recomputeServiceApplicationPos
    },
    on: {
      "computed-entity-list": _vm.updateEntityList,
      "entity-settings": _vm.displayEntitySettings,
      "entity-menu": _vm.displayEntityMenu,
      "create-idea": _vm.createEntityIdea,
      "create-product": function createProduct($event) {
        return _vm.addProduct();
      },
      "create-service": function createService($event) {
        return _vm.addService();
      },
      "feature-process-click": _vm.openTicketDetailDialog,
      "feature-tool-dragged": _vm.onFeatureToolDragged,
      "feature-tool-dragged-start": _vm.onFeatureToolDraggedStart,
      "feature-tool-dragged-stop": _vm.onFeatureToolDraggedStop,
      "feature-dragged": _vm.onFeatureDragged,
      "feature-dragged-start": _vm.onFeatureDraggedStart,
      "feature-dragged-stop": _vm.onFeatureDraggedStop,
      "update-feature-feature-relation": _vm.updateFeatureFeatureRelation,
      "service-application-tool-dragged": _vm.onServiceApplicationToolDragged,
      "service-application-tool-dragged-start": _vm.onServiceApplicationToolDraggedStart,
      "service-application-tool-dragged-stop": _vm.onServiceApplicationToolDraggedStop,
      "feature-mouse-over": _vm.onFeatureMouseOver,
      "feature-mouse-leave": _vm.onFeatureMouseLeave,
      "service-application-mouse-over": _vm.onServiceApplicationMouseOver,
      "service-application-mouse-leave": _vm.onServiceApplicationMouseLeave,
      "service-application-dragged": _vm.onServiceApplicationDragged,
      "service-application-dragged-start": _vm.onServiceApplicationDraggedStart,
      "service-application-dragged-stop": _vm.onServiceApplicationDraggedStop,
      "update-service-application-service-application-relation": _vm.updateServiceApplicationServiceApplicationRelation,
      "product-risk-tool-dragged": _vm.onProductRiskToolDragged,
      "product-risk-tool-dragged-start": _vm.onProductRiskToolDraggedStart,
      "product-risk-tool-dragged-stop": _vm.onProductRiskToolDraggedStop,
      "service-risk-tool-dragged": _vm.onServiceRiskToolDragged,
      "service-risk-tool-dragged-start": _vm.onServiceRiskToolDraggedStart,
      "service-risk-tool-dragged-stop": _vm.onServiceRiskToolDraggedStop,
      "recompute-feature-service-application-pos": _vm.recomputeFeatureAndServiceApplicationPos,
      "update-offering-additionalattributes": _vm.updateOfferingAdditionalAttributes,
      "move-entity-up": _vm.moveUpEntitySortOrder,
      "move-entity-down": _vm.moveDownEntitySortOrder,
      reset: function reset($event) {
        return _vm.resetSettingsAndMenu();
      }
    }
  })], 1) : _c("g", {
    staticClass: "shadow",
    attrs: {
      refs: "model-offering_CTA"
    }
  }, [_c("s-v-g-c-t-a-component", {
    attrs: {
      display: _vm.offering.id,
      title: "No offerings Found",
      description: "Would you like to create new offering",
      buttonText: ["Create offering"],
      x: 5,
      y: _vm.computeJourneyMapHeight + (_vm.touchpoints[0].touchpoint.additionalattributes.minimized ? 30 : 90) + 20,
      width: _vm.computeJourneyMapWidth,
      height: 300
    },
    on: {
      response: function response($event) {
        _vm.createOfferingDialog = true;
      }
    }
  })], 1)])]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "relations"
    }
  }, [_vm.mergedEntities.length > 0 && _vm.isRelationsVisible ? _c("relations-component", {
    attrs: {
      relations: _vm.offering.additionalattributes.relations,
      "journey-map": _vm.journeyMap,
      offering: _vm.offering,
      "merged-entities": _vm.mergedEntities,
      "entity-y": _vm.computeEntityPosY,
      "journeymap-y": _vm.computeJourneyMapHeight,
      "canvas-drag-coefficient-x": _vm.canvasDragCoefficientX,
      "selected-entity-id": _vm.selectedEntityId,
      type: "constant",
      "relations-refresh": _vm.relationRefreshKey,
      "sub-entity-x": _vm.feature_pos_x,
      "sub-entity-y": _vm.feature_pos_y,
      "is-product-visible": _vm.isProductsVisible,
      "is-service-visible": _vm.isServicesVisible,
      "is-touch-point-visible": _vm.isTouchpointVisible
    }
  }) : _vm._e(), _vm._v(" "), _vm.mergedEntities.length > 0 ? _c("relations-component", {
    attrs: {
      relations: _vm.offering.additionalattributes.relations,
      "journey-map": _vm.journeyMap,
      offering: _vm.offering,
      "merged-entities": _vm.mergedEntities,
      "entity-y": _vm.computeEntityPosY,
      "journeymap-y": _vm.computeJourneyMapHeight,
      "canvas-drag-coefficient-x": _vm.canvasDragCoefficientX,
      "selected-entity-id": _vm.selectedEntityId,
      "sub-entity-x": _vm.feature_pos_x,
      "sub-entity-y": _vm.feature_pos_y,
      "relations-refresh": _vm.relationRefreshKey,
      type: "hover",
      "is-product-visible": _vm.isProductsVisible,
      "is-service-visible": _vm.isServicesVisible,
      "is-touch-point-visible": _vm.isTouchpointVisible
    }
  }) : _vm._e()], 1), _vm._v(" "), _c("g", {
    attrs: {
      refs: "highlight"
    }
  }, [_vm.phaseChannelGapHighlighter.length > 0 ? _c("g", {
    attrs: {
      refs: "phase_channel_gap_highlighter"
    }
  }, _vm._l(_vm.phaseChannelGapHighlighter, function (gap, index) {
    return _c("rect", {
      key: index,
      staticClass: "gaphighlighter-anim",
      attrs: {
        x: gap.x,
        y: gap.y,
        rx: 5,
        ry: 5,
        width: gap.width,
        height: gap.height,
        fill: "#eee",
        "fill-opacity": "0.1",
        "stroke-dasharray": "5,5",
        stroke: "lightseagreen",
        "stroke-width": 1,
        "stroke-opacity": "0.5"
      }
    });
  }), 0) : _vm._e(), _vm._v(" "), _vm.maxedPhaseChannelGapHighlighter.length > 0 ? _c("g", {
    attrs: {
      refs: "max_phase_channel_gap_highlighter"
    }
  }, _vm._l(_vm.maxedPhaseChannelGapHighlighter, function (gap, index) {
    return _c("g", {
      key: index
    }, [_c("rect", {
      staticClass: "gaphighlighter-anim",
      attrs: {
        x: gap.x,
        y: gap.y,
        rx: 5,
        ry: 5,
        width: gap.width,
        height: gap.height,
        fill: "#eee",
        "fill-opacity": "0.9",
        "stroke-dasharray": "5,5",
        stroke: "#eee",
        "stroke-width": 1,
        "stroke-opacity": "0.5"
      }
    }), _vm._v(" "), _c("text", {
      staticStyle: {
        "font-weight": "400",
        "font-variant-caps": "petite-caps"
      },
      attrs: {
        x: gap.x + gap.width / 2,
        y: gap.y + gap.height / 2,
        "text-anchor": "middle",
        "font-size": "0.9em",
        fill: "black"
      }
    }, [_c("tspan", {
      attrs: {
        x: gap.x + gap.width / 2
      }
    }, [_vm._v("Max touchpoints reached")]), _vm._v(" "), _c("tspan", {
      attrs: {
        x: gap.x + gap.width / 2,
        dy: "15"
      }
    }, [_vm._v("Delete a touchpoint to add new")])])]);
  }), 0) : _vm._e(), _vm._v(" "), _vm.stepHighlighter.length > 0 ? _c("g", {
    attrs: {
      refs: "step_highlighter"
    }
  }, _vm._l(_vm.stepHighlighter, function (gap, index) {
    return _c("rect", {
      key: index,
      staticClass: "gaphighlighter-anim",
      attrs: {
        x: gap.x,
        y: gap.y,
        rx: 5,
        ry: 5,
        width: gap.width,
        height: gap.height,
        fill: "#eee",
        "fill-opacity": "0.1",
        "stroke-dasharray": "5,5",
        stroke: "lightseagreen",
        "stroke-width": 1,
        "stroke-opacity": "0.5"
      }
    });
  }), 0) : _vm._e(), _vm._v(" "), _vm.featureHighlighter.length > 0 ? _c("g", {
    attrs: {
      refs: "feature_highlighter"
    }
  }, _vm._l(_vm.featureHighlighter, function (gap, index) {
    return _c("rect", {
      key: index,
      staticClass: "gaphighlighter-anim",
      attrs: {
        x: gap.x,
        y: gap.y,
        rx: 5,
        ry: 5,
        width: gap.width,
        height: gap.height,
        fill: "#eee",
        "fill-opacity": "0.1",
        "stroke-dasharray": "5,5",
        stroke: "lightseagreen",
        "stroke-width": 1,
        "stroke-opacity": "0.5"
      }
    });
  }), 0) : _vm._e(), _vm._v(" "), _vm.serviceApplicationHighlighter.length > 0 ? _c("g", {
    attrs: {
      refs: "service_application_highlighter"
    }
  }, _vm._l(_vm.serviceApplicationHighlighter, function (gap, index) {
    return _c("rect", {
      key: index,
      staticClass: "gaphighlighter-anim",
      attrs: {
        x: gap.x,
        y: gap.y,
        rx: 5,
        ry: 5,
        width: gap.width,
        height: gap.height,
        fill: "#eee",
        "fill-opacity": "0.1",
        "stroke-dasharray": "5,5",
        stroke: "lightseagreen",
        "stroke-width": 1,
        "stroke-opacity": "0.5"
      }
    });
  }), 0) : _vm._e(), _vm._v(" "), _vm.drop_hover_details.hover ? _c("g", {
    attrs: {
      id: "hover_highlight",
      refs: "hover_highlight"
    }
  }, [_c("rect", {
    staticClass: "gaphighlighter-anim",
    attrs: {
      id: "hover_highlight_rect",
      x: _vm.drop_hover_details.x,
      y: _vm.drop_hover_details.y,
      rx: "5",
      ry: "5",
      width: _vm.drop_hover_details.width,
      height: _vm.drop_hover_details.height,
      fill: "rgba(64,150,238, 0.5)",
      "fill-opacity": "0.5",
      "stroke-dasharray": "5,5",
      stroke: "#4096EE",
      "stroke-width": 1,
      "stroke-opacity": "0.9"
    }
  })]) : _vm._e()]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "popover"
    }
  }, [_c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.phaseHover ? _c("g", {
    attrs: {
      refs: "phase_details_popover"
    }
  }, [_c("phase-details-component", {
    attrs: {
      x: _vm.selectedPhaseX,
      y: 100,
      "journey-map": _vm.journeyMap,
      offering: _vm.offering,
      width: 600,
      phasex: _vm.selectedPhaseX,
      phasewidth: _vm.selectedPhaseWidth,
      phase: _vm.selectedPhase.journeyPhase,
      steps: _vm.computedTaskList,
      experiences: _vm.journeyMap.linkedJourneyExperiences,
      index: _vm.selectedPhaseIndex,
      ideas: _vm.computedIdeasForPhase(_vm.selectedPhase.journeyPhase),
      "read-only": true
    },
    on: {
      details: function details($event) {
        _vm.phaseDetailsDialog = true;
      },
      close: function close($event) {
        _vm.phaseHover = false;
      }
    }
  })], 1) : _vm._e()]), _vm._v(" "), _c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.taskHover ? _c("g", {
    attrs: {
      refs: "task_details_popover"
    }
  }, [_c("task-details-component", {
    attrs: {
      x: _vm.selectedTaskX,
      y: _vm.selectedTaskY,
      "journey-map": _vm.journeyMap,
      offering: _vm.offering,
      width: 600,
      step: _vm.selectedTask.journeyStep,
      experiences: _vm.journeyMap.linkedJourneyExperiences,
      index: _vm.selectedTaskIndex,
      ideas: _vm.computedIdeasForStep(_vm.selectedTask.journeyStep),
      "read-only": true
    },
    on: {
      details: function details($event) {
        _vm.taskDetailsDialog = true;
      },
      close: function close($event) {
        _vm.taskHover = false;
      }
    }
  })], 1) : _vm._e()]), _vm._v(" "), _c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.touchpointHover ? _c("g", {
    attrs: {
      refs: "touchpoint_details_popover"
    }
  }, [_c("touchpoint-popover-component", {
    attrs: {
      x: _vm.selectedTouchpointPos.x,
      y: _vm.selectedTouchpointPos.y,
      width: 300,
      touchpoint: _vm.selectedTouchpoint,
      "journey-map": _vm.journeyMap
    },
    on: {
      delete: _vm.deleteTouchpoint,
      close: function close($event) {
        _vm.touchpointHover = false;
      }
    }
  })], 1) : _vm._e()]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "touchpoint_context_menu"
    }
  }, [_c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.touchpoint_settings.visible ? _c("g", {
    attrs: {
      refs: "model-offering-touchpoint-settings"
    }
  }, [_c("path", {
    attrs: {
      d: _vm.touchpoint_settings.path,
      "stroke-width": "1",
      fill: "#447799"
    }
  }), _vm._v(" "), _c("rect", {
    attrs: {
      x: _vm.touchpoint_settings.x,
      y: _vm.touchpoint_settings.y,
      rx: "0",
      width: "210",
      height: 24,
      fill: "white",
      "stroke-width": "1",
      stroke: "#305497"
    }
  }), _vm._v(" "), _c("rect", {
    attrs: {
      x: _vm.touchpoint_settings.x,
      y: _vm.touchpoint_settings.y,
      rx: "0",
      width: "22",
      height: 24,
      fill: "rgba(200,200,200,0.15)",
      "stroke-width": "0",
      stroke: "#aaa"
    }
  }), _vm._v(" "), _c("foreignObject", {
    attrs: {
      x: _vm.touchpoint_settings.x,
      y: _vm.touchpoint_settings.y,
      width: 210,
      height: 216
    }
  }, [_c("el-table", {
    attrs: {
      data: _vm.touchpoint_settings.values,
      stripe: "",
      size: "micro",
      "show-header": false
    },
    on: {
      "row-click": _vm.selectTouchpointSetting
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "",
      width: "20"
    }
  }, [[_c("svg-icon", {
    staticStyle: {
      "font-size": "10px"
    },
    attrs: {
      "icon-class": "story"
    }
  })]], 2), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "title"
    }
  })], 1)], 1)]) : _vm._e()])], 1), _vm._v(" "), _c("g", {
    attrs: {
      refs: "product_context_menu"
    }
  }, [_c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.product_settings.visible ? _c("g", {
    attrs: {
      refs: "model-offering-product-settings"
    }
  }, [_c("path", {
    attrs: {
      d: _vm.product_settings.path,
      "stroke-width": "1",
      fill: "#447799"
    }
  }), _vm._v(" "), _c("rect", {
    attrs: {
      x: _vm.product_settings.x,
      y: _vm.product_settings.y,
      rx: "0",
      width: "210",
      height: 165,
      fill: "white",
      "stroke-width": "1",
      stroke: "#305497"
    }
  }), _vm._v(" "), _c("rect", {
    attrs: {
      x: _vm.product_settings.x,
      y: _vm.product_settings.y,
      rx: "0",
      width: "22",
      height: 165,
      fill: "rgba(200,200,200,0.15)",
      "stroke-width": "0",
      stroke: "#aaa"
    }
  }), _vm._v(" "), _c("foreignObject", {
    attrs: {
      x: _vm.product_settings.x,
      y: _vm.product_settings.y,
      width: 210,
      height: 165
    }
  }, [_c("el-table", {
    attrs: {
      data: _vm.product_settings.values,
      stripe: "",
      size: "micro",
      "show-header": false
    },
    on: {
      "row-click": _vm.selectProductSetting
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "",
      width: "20"
    }
  }, [[_c("svg-icon", {
    staticStyle: {
      "font-size": "10px"
    },
    attrs: {
      "icon-class": "story"
    }
  })]], 2), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "title"
    }
  })], 1)], 1)]) : _vm._e()]), _vm._v(" "), _c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.product_menu.visible ? _c("g", {
    attrs: {
      refs: "model-offering-product-settings"
    }
  }, [_c("path", {
    attrs: {
      d: _vm.product_menu.path,
      "stroke-width": "1",
      fill: "#447799"
    }
  }), _vm._v(" "), _c("rect", {
    attrs: {
      x: _vm.product_menu.x,
      y: _vm.product_menu.y,
      rx: "0",
      width: "210",
      height: 96,
      fill: "white",
      "stroke-width": "1",
      stroke: "#305497"
    }
  }), _vm._v(" "), _c("rect", {
    attrs: {
      x: _vm.product_menu.x,
      y: _vm.product_menu.y,
      rx: "0",
      width: "22",
      height: 96,
      fill: "rgba(200,200,200,0.15)",
      "stroke-width": "0",
      stroke: "#aaa"
    }
  }), _vm._v(" "), _c("foreignObject", {
    attrs: {
      x: _vm.product_menu.x,
      y: _vm.product_menu.y,
      width: 210,
      height: 96
    }
  }, [_c("el-table", {
    attrs: {
      data: _vm.product_menu.values,
      stripe: "",
      size: "micro",
      "show-header": false
    },
    on: {
      "row-click": _vm.selectProductMenu
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "",
      width: "20"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.title === "Mapped" ? _c("svg-icon", {
          style: _vm.product_menu.has_unmapped ? "font-size: 10px; color:#F56C6C;" : "font-size: 10px; color:#67C23A;",
          attrs: {
            "icon-class": "tree"
          }
        }) : _c("svg-icon", {
          staticStyle: {
            "font-size": "10px"
          },
          attrs: {
            "icon-class": "story"
          }
        })];
      }
    }], null, false, 672997840)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "title"
    }
  })], 1)], 1)]) : _vm._e()])], 1), _vm._v(" "), _c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.feature.hover && _vm.isEntityPopoverVisible ? _c("g", {
    attrs: {
      refs: "feature_details_popover"
    }
  }, [_c("feature-popover-component", {
    attrs: {
      x: _vm.feature.x + 20,
      y: _vm.feature.y,
      width: 300,
      feature: _vm.feature.entity
    },
    on: {
      close: function close($event) {
        _vm.feature.hover = false;
      }
    }
  })], 1) : _vm._e()]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "service_context_menu"
    }
  }, [_c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.service_settings.visible ? _c("g", {
    attrs: {
      refs: "model-offering-product-settings"
    }
  }, [_c("path", {
    attrs: {
      d: _vm.service_settings.path,
      "stroke-width": "1",
      fill: "#447799"
    }
  }), _vm._v(" "), _c("rect", {
    attrs: {
      x: _vm.service_settings.x,
      y: _vm.service_settings.y,
      rx: "0",
      width: "210",
      height: 165,
      fill: "white",
      "stroke-width": "1",
      stroke: "#305497"
    }
  }), _vm._v(" "), _c("rect", {
    attrs: {
      x: _vm.service_settings.x,
      y: _vm.service_settings.y,
      rx: "0",
      width: "22",
      height: 165,
      fill: "rgba(200,200,200,0.15)",
      "stroke-width": "0",
      stroke: "#aaa"
    }
  }), _vm._v(" "), _c("foreignObject", {
    attrs: {
      x: _vm.service_settings.x,
      y: _vm.service_settings.y,
      width: 210,
      height: 165
    }
  }, [_c("el-table", {
    attrs: {
      data: _vm.service_settings.values,
      stripe: "",
      size: "micro",
      "show-header": false
    },
    on: {
      "row-click": _vm.selectServiceSetting
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "",
      width: "20"
    }
  }, [[_c("svg-icon", {
    staticStyle: {
      "font-size": "10px"
    },
    attrs: {
      "icon-class": "story"
    }
  })]], 2), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "title"
    }
  })], 1)], 1)]) : _vm._e()]), _vm._v(" "), _c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.service_menu.visible ? _c("g", {
    attrs: {
      refs: "model-offering-service-settings"
    }
  }, [_c("path", {
    attrs: {
      d: _vm.service_menu.path,
      "stroke-width": "1",
      fill: "#447799"
    }
  }), _vm._v(" "), _c("rect", {
    attrs: {
      x: _vm.service_menu.x,
      y: _vm.service_menu.y,
      rx: "0",
      width: "210",
      height: 96,
      fill: "white",
      "stroke-width": "1",
      stroke: "#305497"
    }
  }), _vm._v(" "), _c("rect", {
    attrs: {
      x: _vm.service_menu.x,
      y: _vm.service_menu.y,
      rx: "0",
      width: "22",
      height: 96,
      fill: "rgba(200,200,200,0.15)",
      "stroke-width": "0",
      stroke: "#aaa"
    }
  }), _vm._v(" "), _c("foreignObject", {
    attrs: {
      x: _vm.service_menu.x,
      y: _vm.service_menu.y,
      width: 210,
      height: 96
    }
  }, [_c("el-table", {
    attrs: {
      data: _vm.service_menu.values,
      stripe: "",
      size: "micro",
      "show-header": false
    },
    on: {
      "row-click": _vm.selectServiceMenu
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "",
      width: "20"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.title === "Mapped" ? _c("svg-icon", {
          style: _vm.service_menu.has_unmapped ? "font-size: 10px; color:#F56C6C;" : "font-size: 10px; color:#67C23A;",
          attrs: {
            "icon-class": "tree"
          }
        }) : _c("svg-icon", {
          staticStyle: {
            "font-size": "10px"
          },
          attrs: {
            "icon-class": "story"
          }
        })];
      }
    }], null, false, 2624359846)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "title"
    }
  })], 1)], 1)]) : _vm._e()]), _vm._v(" "), _c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.service_application.hover && _vm.isEntityPopoverVisible ? _c("g", {
    attrs: {
      refs: "service_application_details_popover"
    }
  }, [_c("service-application-popover-component", {
    attrs: {
      x: _vm.service_application.x,
      y: _vm.service_application.y,
      width: 300,
      "service-application": _vm.service_application.entity
    },
    on: {
      close: function close($event) {
        _vm.service_application.hover = false;
      }
    }
  })], 1) : _vm._e()])], 1)], 1)])])])], 1), _vm._v(" "), _vm.phaseDetailsDialog ? _c("el-dialog", {
    attrs: {
      title: "Stage Details",
      visible: _vm.phaseDetailsDialog,
      width: "90%",
      top: "15vh"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.phaseDetailsDialog = $event;
      }
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 16
    }
  }, [_c("table", {
    attrs: {
      id: "model_offering_details"
    }
  }, [_c("tr", [_c("th", [_vm._v("Title")]), _vm._v(" "), _c("td", [_vm._v(_vm._s(_vm.trimString(_vm.selectedPhase.journeyPhase.title, 50)))])]), _vm._v(" "), _c("tr", [_c("th", [_vm._v("Description")]), _vm._v(" "), _c("td", [_vm._v(_vm._s(_vm.selectedPhase.journeyPhase.description))])]), _vm._v(" "), _c("tr", [_c("th", [_vm._v("Created by")]), _vm._v(" "), _c("td", [_c("el-tag", {
    attrs: {
      size: "mini"
    }
  }, [_vm._v(_vm._s(_vm.selectedPhase.journeyPhase.createdby))])], 1)])])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("table", {
    attrs: {
      id: "model_offering_details"
    }
  }, [_c("tr", [_c("th", [_vm._v("Priority")]), _vm._v(" "), _c("td", [_c("el-tag", {
    attrs: {
      size: "mini"
    }
  }, [_vm._v(_vm._s(_vm.selectedPhase.journeyPhase.priority))])], 1)]), _vm._v(" "), _c("tr", [_c("th", [_vm._v("Value")]), _vm._v(" "), _c("td", [_c("el-tag", {
    attrs: {
      size: "mini"
    }
  }, [_vm._v(_vm._s(_vm.selectedPhase.journeyPhase.importance))])], 1)]), _vm._v(" "), _c("tr", [_c("th", [_vm._v("Score")]), _vm._v(" "), _c("td", [_c("el-tag", {
    attrs: {
      size: "mini"
    }
  }, [_vm._v(_vm._s(_vm.selectedPhase.journeyPhase.rank))])], 1)]), _vm._v(" "), _c("tr", [_c("th", [_vm._v("Duration")]), _vm._v(" "), _c("td", [_c("el-tag", {
    attrs: {
      size: "mini"
    }
  }, [_vm._v(_vm._s(_vm.selectedPhase.journeyPhase.duration))])], 1)]), _vm._v(" "), _c("tr", [_c("th", [_vm._v("Goal")]), _vm._v(" "), _c("td", [_c("el-tag", {
    attrs: {
      size: "mini"
    }
  }, [_vm._v(_vm._s(_vm.selectedPhase.journeyPhase.goal))])], 1)])])])], 1), _vm._v(" "), _c("div", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      icon: "el-icon-close"
    },
    on: {
      click: function click($event) {
        _vm.phaseDetailsDialog = false;
      }
    }
  }, [_vm._v("Cancel")])], 1)], 1) : _vm._e(), _vm._v(" "), _vm.taskDetailsDialog ? _c("el-dialog", {
    attrs: {
      title: "Step Details",
      visible: _vm.taskDetailsDialog,
      width: "90%",
      top: "15vh"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.taskDetailsDialog = $event;
      }
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 16
    }
  }, [_c("table", {
    attrs: {
      id: "model_offering_details"
    }
  }, [_c("tr", [_c("th", [_vm._v("Title")]), _vm._v(" "), _c("td", [_vm._v(_vm._s(_vm.trimString(_vm.selectedTask.journeyStep.title, 50)))])]), _vm._v(" "), _c("tr", [_c("th", [_vm._v("Description")]), _vm._v(" "), _c("td", [_vm._v(_vm._s(_vm.selectedTask.journeyStep.description))])]), _vm._v(" "), _c("tr", [_c("th", [_vm._v("Created by")]), _vm._v(" "), _c("td", [_c("el-tag", {
    attrs: {
      size: "mini"
    }
  }, [_vm._v(_vm._s(_vm.selectedTask.journeyStep.createdby))])], 1)])])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("table", {
    attrs: {
      id: "model_offering_details"
    }
  }, [_c("tr", [_c("th", [_vm._v("Priority")]), _vm._v(" "), _c("td", [_c("el-tag", {
    attrs: {
      size: "mini"
    }
  }, [_vm._v(_vm._s(_vm.selectedTask.journeyStep.priority))])], 1)]), _vm._v(" "), _c("tr", [_c("th", [_vm._v("Value")]), _vm._v(" "), _c("td", [_c("el-tag", {
    attrs: {
      size: "mini"
    }
  }, [_vm._v(_vm._s(_vm.selectedTask.journeyStep.importance))])], 1)]), _vm._v(" "), _c("tr", [_c("th", [_vm._v("Intention")]), _vm._v(" "), _c("td", [_c("el-tag", {
    attrs: {
      size: "mini"
    }
  }, [_vm._v(_vm._s(_vm.selectedTask.journeyStep.intention))])], 1)]), _vm._v(" "), _c("tr", [_c("th", [_vm._v("Goal")]), _vm._v(" "), _c("td", [_c("el-tag", {
    attrs: {
      size: "mini"
    }
  }, [_vm._v(_vm._s(_vm.selectedTask.journeyStep.goal))])], 1)])])])], 1), _vm._v(" "), _c("div", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      icon: "el-icon-close"
    },
    on: {
      click: function click($event) {
        _vm.taskDetailsDialog = false;
      }
    }
  }, [_vm._v("Cancel")])], 1)], 1) : _vm._e(), _vm._v(" "), _vm.createOfferingDialog ? _c("el-dialog", {
    attrs: {
      title: "Create Offering",
      visible: _vm.createOfferingDialog,
      width: "90%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.createOfferingDialog = $event;
      }
    }
  }, [_vm.offering_form.type === "new" ? _c("el-form", {
    ref: "offering_form",
    attrs: {
      model: _vm.offering_form,
      "label-width": "180px",
      rules: _vm.offeringRules
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Name",
      prop: "title"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.offering_form.title,
      callback: function callback($$v) {
        _vm.$set(_vm.offering_form, "title", $$v);
      },
      expression: "offering_form.title"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Details",
      prop: "description"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      size: "mini"
    },
    model: {
      value: _vm.offering_form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.offering_form, "description", $$v);
      },
      expression: "offering_form.description"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Offering Type",
      prop: "offeringtype"
    }
  }, [_c("el-select", {
    staticStyle: {
      padding: "0"
    },
    attrs: {
      size: "mini",
      placeholder: "Type to search"
    },
    model: {
      value: _vm.offering_form.offeringtype,
      callback: function callback($$v) {
        _vm.$set(_vm.offering_form, "offeringtype", $$v);
      },
      expression: "offering_form.offeringtype"
    }
  }, [_c("el-option", {
    attrs: {
      label: "Internal",
      value: "Internal"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "External",
      value: "External"
    }
  })], 1)], 1), _vm._v(" "), _vm.offering.offeringcategory === "PRIMARY" ? _c("el-form-item", {
    attrs: {
      label: "Variant",
      prop: "variant"
    }
  }, [_c("el-radio-group", {
    model: {
      value: _vm.offering_form.variant,
      callback: function callback($$v) {
        _vm.$set(_vm.offering_form, "variant", $$v);
      },
      expression: "offering_form.variant"
    }
  }, [_c("el-radio", {
    attrs: {
      label: "variant"
    }
  }, [_vm._v("Create Variant")]), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "PRIMARY"
    }
  }, [_vm._v("Make Primary")])], 1)], 1) : _vm._e()], 1) : _vm._e(), _vm._v(" "), _vm.offering_form.type === "existing" ? _c("el-row", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading.offering,
      expression: "loading.offering"
    }]
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-table", {
    attrs: {
      size: "mini",
      data: _vm.offerings,
      stripe: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "title",
      label: "Title"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Products"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return _vm._l(scope.row.linkedProducts, function (product, index) {
          return _c("el-tag", {
            key: index,
            attrs: {
              size: "mini"
            }
          }, [_vm._v(_vm._s(product.product.title))]);
        });
      }
    }], null, false, 2241748927)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Services"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return scope.row.linkedServices.length > 0 ? _vm._l(scope.row.linkedServices, function (service, service_index) {
          return _c("g", {
            key: service_index
          }, _vm._l(service.service.linkedServiceApplications, function (serviceApplication, sub_index) {
            return _c("el-tag", {
              key: sub_index,
              attrs: {
                size: "mini"
              }
            }, [_vm._v(_vm._s(serviceApplication.serviceApplication.title))]);
          }), 1);
        }) : undefined;
      }
    }], null, true)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Operations"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-button", {
          attrs: {
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.importOffering(scope.$index, scope.row);
            }
          }
        }, [_vm._v("Import")])];
      }
    }], null, false, 1804855224)
  })], 1)], 1)], 1) : _vm._e(), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_vm.offering_form.type === "new" ? _c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading.offering,
      expression: "loading.offering"
    }],
    attrs: {
      icon: "el-icon-download",
      size: "mini",
      type: "info"
    },
    on: {
      click: function click($event) {
        return _vm.displayOfferingList();
      }
    }
  }, [_vm._v("Import from Existing")]) : _vm._e(), _vm._v(" "), _vm.offering_form.type === "existing" ? _c("el-button", {
    attrs: {
      size: "mini",
      type: "secondary"
    },
    on: {
      click: function click($event) {
        _vm.offering_form.type = "new";
      }
    }
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "create"
    }
  }), _vm._v(" Offering")], 1) : _vm._e(), _vm._v(" "), _vm.offering_form.type === "new" ? _c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.createOffering
    }
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "create"
    }
  }), _vm._v(" Create")], 1) : _vm._e(), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini",
      icon: "el-icon-close"
    },
    on: {
      click: function click($event) {
        _vm.createOfferingDialog = false;
      }
    }
  }, [_vm._v("Close")])], 1)], 1) : _vm._e(), _vm._v(" "), _vm.addFeatureDialog ? _c("el-dialog", {
    attrs: {
      title: _vm.computedAddFeatureDialogTitle,
      visible: _vm.addFeatureDialog,
      width: "90%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addFeatureDialog = $event;
      }
    }
  }, [_c("el-form", {
    ref: "feature_form",
    attrs: {
      model: _vm.feature_form,
      "label-width": "200px",
      rules: _vm.featureRules
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Name",
      prop: "title"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.feature_form.title,
      callback: function callback($$v) {
        _vm.$set(_vm.feature_form, "title", $$v);
      },
      expression: "feature_form.title"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Description",
      prop: "description"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      size: "mini"
    },
    model: {
      value: _vm.feature_form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.feature_form, "description", $$v);
      },
      expression: "feature_form.description"
    }
  })], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Priority",
      prop: "priority"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.feature_form.priority,
      callback: function callback($$v) {
        _vm.$set(_vm.feature_form, "priority", $$v);
      },
      expression: "feature_form.priority"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: "Low"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Medium"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "High"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Highest"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Status",
      prop: "status"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Select Status",
      size: "mini"
    },
    model: {
      value: _vm.feature_form.status,
      callback: function callback($$v) {
        _vm.$set(_vm.feature_form, "status", $$v);
      },
      expression: "feature_form.status"
    }
  }, _vm._l(_vm.statuses, function (status) {
    return _c("el-option", {
      key: status.value,
      attrs: {
        label: status.label,
        value: status.value
      }
    });
  }), 1)], 1)], 1)], 1), _vm._v(" "), !_vm.feature.product_id ? _c("div", [_vm.offering ? _c("el-form-item", {
    attrs: {
      label: "Add feature to Product"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Select Product",
      size: "mini"
    },
    model: {
      value: _vm.feature_form.productId,
      callback: function callback($$v) {
        _vm.$set(_vm.feature_form, "productId", $$v);
      },
      expression: "feature_form.productId"
    }
  }, _vm._l(_vm.offering.linkedProducts, function (product, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: product.product.title,
        value: product.product.id
      }
    });
  }), 1)], 1) : _vm._e()], 1) : _vm._e(), _vm._v(" "), !_vm.feature.step_id ? _c("div", [_vm.journeyMap.id && _vm.journeyMap.linkedJourneySteps.length > 0 ? _c("el-form-item", {
    attrs: {
      label: "Add feature to Step"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Select Step",
      size: "mini"
    },
    model: {
      value: _vm.feature_form.step_id,
      callback: function callback($$v) {
        _vm.$set(_vm.feature_form, "step_id", $$v);
      },
      expression: "feature_form.step_id"
    }
  }, _vm._l(_vm.journeyMap.linkedJourneySteps, function (step, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: step.journeyStep.title,
        value: step.journeyStep.id
      }
    });
  }), 1)], 1) : _vm._e()], 1) : _vm._e()], 1), _vm._v(" "), _c("div", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.createFeature
    }
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "create"
    }
  }), _vm._v(" Feature")], 1), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini",
      icon: "el-icon-close"
    },
    on: {
      click: function click($event) {
        _vm.addFeatureDialog = false;
      }
    }
  }, [_vm._v("Cancel")])], 1)], 1) : _vm._e(), _vm._v(" "), _vm.createOfferingIdeaDialog ? _c("create-offering-idea-component", {
    attrs: {
      open: _vm.createOfferingIdeaDialog,
      customer: _vm.customer,
      offering: _vm.offering,
      scope: _vm.idea.scope,
      scopename: _vm.idea.scopename,
      scopeid: _vm.idea.scopeid
    },
    on: {
      close: function close($event) {
        _vm.createOfferingIdeaDialog = false;
      }
    }
  }) : _vm._e(), _vm._v(" "), _vm.addProductDialog ? _c("el-dialog", {
    attrs: {
      title: _vm.showImportProductUI ? "Link Existing Product to Offering" : "Create New Product",
      visible: _vm.addProductDialog,
      width: "90%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addProductDialog = $event;
      }
    }
  }, [_c("transition", {
    attrs: {
      name: "fade"
    }
  }, [!_vm.showImportProductUI ? _c("el-form", {
    ref: "product_form",
    attrs: {
      model: _vm.product_form,
      "label-width": "180px",
      rules: _vm.productRules
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Name",
      prop: "title"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.product_form.title,
      callback: function callback($$v) {
        _vm.$set(_vm.product_form, "title", $$v);
      },
      expression: "product_form.title"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Description",
      prop: "description"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      size: "mini"
    },
    model: {
      value: _vm.product_form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.product_form, "description", $$v);
      },
      expression: "product_form.description"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Manager",
      prop: "manager"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Select Manager",
      size: "mini"
    },
    model: {
      value: _vm.product_form.manager,
      callback: function callback($$v) {
        _vm.$set(_vm.product_form, "manager", $$v);
      },
      expression: "product_form.manager"
    }
  }, _vm._l(_vm.users, function (user, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: _vm.computeUserName(user),
        value: _vm.computeUserName(user)
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Owner",
      prop: "owner"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Select Owner",
      size: "mini"
    },
    model: {
      value: _vm.product_form.owner,
      callback: function callback($$v) {
        _vm.$set(_vm.product_form, "owner", $$v);
      },
      expression: "product_form.owner"
    }
  }, _vm._l(_vm.users, function (user, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: _vm.computeUserName(user),
        value: _vm.computeUserName(user)
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item")], 1) : _vm._e()], 1), _vm._v(" "), _c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_c("div", [_vm.showImportProductUI ? _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading.product,
      expression: "loading.product"
    }],
    staticClass: "mt-10",
    attrs: {
      data: _vm.filteredProductList,
      stripe: "",
      size: "mini"
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "title",
      label: "Title",
      width: "350px"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "description",
      label: "Description",
      width: "700px"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Features"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.linkedFeatures.length > 0 ? _c("el-tag", {
          attrs: {
            size: "mini"
          }
        }, [_vm._v(_vm._s(scope.row.linkedFeatures.length))]) : _c("el-tag", {
          attrs: {
            type: "info",
            size: "mini"
          }
        }, [_vm._v("No Features found")])];
      }
    }], null, false, 3558606095)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      width: "130px",
      label: "Manager"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-tag", {
          attrs: {
            size: "mini"
          }
        }, [_vm._v(_vm._s(scope.row.keypersonnel.manager))])];
      }
    }], null, false, 404549486)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      width: "130px",
      label: "Owner"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-tag", {
          attrs: {
            size: "mini"
          }
        }, [_vm._v(_vm._s(scope.row.keypersonnel.owner))])];
      }
    }], null, false, 3584163612)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Action",
      width: "130px",
      "header-align": "center",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-button", {
          attrs: {
            type: "primary",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.importProduct("link", scope.$index, scope.row);
            }
          }
        }, [_c("i", {
          staticClass: "el-icon-link"
        }), _vm._v(" Re-use")])];
      }
    }], null, false, 3192538077)
  })], 1) : _vm._e()], 1)]), _vm._v(" "), _c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_c("div", {
    staticClass: "block text-center"
  }, [_vm.showImportProductUI ? _c("el-pagination", {
    attrs: {
      layout: "prev, pager, next",
      total: _vm.pagination.total,
      "page-size": 20,
      "hide-on-single-page": true
    },
    on: {
      "current-change": _vm.paginationProductCurrentChange
    }
  }) : _vm._e()], 1)]), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    staticStyle: {
      display: "flex",
      "align-items": "center",
      "justify-content": "center"
    },
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [!_vm.showImportProductUI ? _c("el-button", {
    staticStyle: {
      float: "left"
    },
    attrs: {
      size: "mini",
      type: "success"
    },
    on: {
      click: function click($event) {
        return _vm.loadProducts(1);
      }
    }
  }, [_vm._v("Existing Product")]) : _vm._e(), _vm._v(" "), _vm.showImportProductUI ? _c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: function click($event) {
        _vm.showImportProductUI = false;
      }
    }
  }, [_vm._v("Back")]) : _vm._e(), _vm._v(" "), !_vm.showImportProductUI ? _c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.createProduct
    }
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "create"
    }
  }), _vm._v(" New Product")], 1) : _vm._e(), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini",
      icon: "el-icon-close"
    },
    on: {
      click: function click($event) {
        _vm.addProductDialog = false;
      }
    }
  }, [_vm._v("Cancel")])], 1)], 1) : _vm._e(), _vm._v(" "), _vm.addServiceDialog ? _c("el-dialog", {
    attrs: {
      title: _vm.showImportServiceUI ? "Link Existing Service to Offering" : "Create New Service",
      visible: _vm.addServiceDialog,
      width: "90%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addServiceDialog = $event;
      }
    }
  }, [_c("transition", {
    attrs: {
      name: "fade"
    }
  }, [!_vm.showImportServiceUI ? _c("el-form", {
    ref: "service_form",
    attrs: {
      model: _vm.service_form,
      "label-width": "180px",
      rules: _vm.serviceRules
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Name",
      prop: "title"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.service_form.title,
      callback: function callback($$v) {
        _vm.$set(_vm.service_form, "title", $$v);
      },
      expression: "service_form.title"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Description",
      prop: "description"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      size: "mini"
    },
    model: {
      value: _vm.service_form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.service_form, "description", $$v);
      },
      expression: "service_form.description"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Manager",
      prop: "manager"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Select Manager",
      size: "mini"
    },
    model: {
      value: _vm.service_form.manager,
      callback: function callback($$v) {
        _vm.$set(_vm.service_form, "manager", $$v);
      },
      expression: "service_form.manager"
    }
  }, _vm._l(_vm.users, function (user, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: _vm.computeUserName(user),
        value: _vm.computeUserName(user)
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Owner",
      prop: "owner"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Select Owner",
      size: "mini"
    },
    model: {
      value: _vm.service_form.owner,
      callback: function callback($$v) {
        _vm.$set(_vm.service_form, "owner", $$v);
      },
      expression: "service_form.owner"
    }
  }, _vm._l(_vm.users, function (user, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: _vm.computeUserName(user),
        value: _vm.computeUserName(user)
      }
    });
  }), 1)], 1)], 1) : _vm._e()], 1), _vm._v(" "), _c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_c("div", [_vm.showImportServiceUI ? _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading.service,
      expression: "loading.service"
    }],
    staticClass: "mt-10",
    attrs: {
      data: _vm.filteredServiceList,
      stripe: "",
      size: "mini"
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "title",
      label: "Title",
      width: "350px"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "description",
      label: "Description",
      width: "700px"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Processes"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.linkedServiceApplications.length > 0 ? _c("el-tag", {
          attrs: {
            size: "mini"
          }
        }, [_vm._v(_vm._s(scope.row.linkedServiceApplications.length))]) : _c("el-tag", {
          attrs: {
            type: "info",
            size: "mini"
          }
        }, [_vm._v("No Process found")])];
      }
    }], null, false, 1251450595)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      width: "130px",
      label: "Manager"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.keypersonnel ? _c("el-tag", {
          attrs: {
            size: "mini"
          }
        }, [_vm._v(_vm._s(scope.row.keypersonnel.manager))]) : _vm._e()];
      }
    }], null, false, 4269202164)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      width: "130px",
      label: "Owner"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.keypersonnel ? _c("el-tag", {
          attrs: {
            size: "mini"
          }
        }, [_vm._v(_vm._s(scope.row.keypersonnel.owner))]) : _vm._e()];
      }
    }], null, false, 980880454)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Action",
      width: "130px",
      "header-align": "center",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-tooltip", {
          staticClass: "item",
          attrs: {
            effect: "dark",
            content: "Create link",
            placement: "top"
          }
        }, [_c("el-button", {
          attrs: {
            size: "mini",
            type: "primary"
          },
          on: {
            click: function click($event) {
              return _vm.importService("link", scope.$index, scope.row);
            }
          }
        }, [_c("i", {
          staticClass: "el-icon-link"
        }), _vm._v(" Re-Use")])], 1)];
      }
    }], null, false, 927977517)
  })], 1) : _vm._e()], 1)]), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    staticStyle: {
      display: "flex",
      "align-items": "center",
      "justify-content": "center"
    },
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [!_vm.showImportServiceUI ? _c("el-button", {
    staticStyle: {
      float: "left"
    },
    attrs: {
      size: "mini",
      type: "success"
    },
    on: {
      click: function click($event) {
        return _vm.loadServices(1);
      }
    }
  }, [_vm._v("Existing Service")]) : _vm._e(), _vm._v(" "), _vm.showImportServiceUI ? _c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: function click($event) {
        _vm.showImportServiceUI = false;
      }
    }
  }, [_vm._v("Back")]) : _vm._e(), _vm._v(" "), !_vm.showImportServiceUI ? _c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.createService
    }
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "create"
    }
  }), _vm._v(" New Service")], 1) : _vm._e(), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini",
      icon: "el-icon-close"
    },
    on: {
      click: function click($event) {
        _vm.addServiceDialog = false;
      }
    }
  }, [_vm._v("Cancel")])], 1)], 1) : _vm._e(), _vm._v(" "), _vm.productDetailsDialog ? _c("el-dialog", {
    attrs: {
      title: "Product Details",
      visible: _vm.productDetailsDialog,
      width: "90%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.productDetailsDialog = $event;
      }
    }
  }, [_c("el-row", [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("table", {
    staticClass: "ml-10",
    staticStyle: {
      "margin-bottom": "10pt",
      "margin-right": "20pt"
    },
    attrs: {
      width: "96%"
    }
  }, [_c("col", {
    staticStyle: {
      width: "30%"
    }
  }), _vm._v(" "), _c("col", {
    staticStyle: {
      width: "60%"
    }
  }), _vm._v(" "), _c("tr", {
    staticClass: "text-left"
  }, [_c("th", [_vm._v("Title")]), _vm._v(" "), _c("td", [_c("el-input", {
    staticStyle: {
      "margin-top": "1pt",
      "margin-bottom": "1pt"
    },
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.selectedEntity.title,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedEntity, "title", $$v);
      },
      expression: "selectedEntity.title"
    }
  })], 1)]), _vm._v(" "), _c("tr", {
    staticClass: "text-left"
  }, [_c("th", [_vm._v("Description")]), _vm._v(" "), _c("td", [_c("el-input", {
    staticStyle: {
      "margin-top": "1pt",
      "margin-bottom": "1pt"
    },
    attrs: {
      type: "textarea",
      size: "mini"
    },
    model: {
      value: _vm.selectedEntity.description,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedEntity, "description", $$v);
      },
      expression: "selectedEntity.description"
    }
  })], 1)]), _vm._v(" "), _c("tr", {
    staticClass: "text-left",
    staticStyle: {
      height: "28px"
    }
  }, [_c("th", [_vm._v("Created")]), _vm._v(" "), _c("td", [_vm._v(_vm._s(_vm.computeUserNameFromId(_vm.selectedEntity.createdby)) + " @ " + _vm._s(_vm.computeHumanFriendlyDate(_vm.selectedEntity.createdat)))])]), _vm._v(" "), _c("tr", {
    staticClass: "text-left",
    staticStyle: {
      height: "28px"
    }
  }, [_c("th", [_vm._v("Modified")]), _vm._v(" "), _c("td", [_vm._v(_vm._s(_vm.computeUserNameFromId(_vm.selectedEntity.modifiedby)) + " @ " + _vm._s(_vm.computeHumanFriendlyDate(_vm.selectedEntity.modifiedat)))])])])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("table", {
    staticClass: "ml-10",
    staticStyle: {
      "margin-bottom": "10pt",
      "margin-right": "20pt"
    },
    attrs: {
      width: "96%"
    }
  }, [_c("col", {
    staticStyle: {
      width: "30%"
    }
  }), _vm._v(" "), _c("col", {
    staticStyle: {
      width: "60%"
    }
  }), _vm._v(" "), _c("tr", {
    staticClass: "text-left"
  }, [_c("th", [_vm._v("Vision")]), _vm._v(" "), _c("td", [_c("el-input", {
    staticStyle: {
      "margin-top": "1pt",
      "margin-bottom": "1pt"
    },
    attrs: {
      type: "textarea",
      size: "mini"
    },
    model: {
      value: _vm.selectedEntity.vision,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedEntity, "vision", $$v);
      },
      expression: "selectedEntity.vision"
    }
  })], 1)])])])], 1), _vm._v(" "), _c("el-tabs", {
    attrs: {
      value: "features"
    },
    on: {
      "tab-click": _vm.handleSelectedProductDialogTabClick
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "Features",
      name: "features"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-table", {
    attrs: {
      data: _vm.selectedEntity.linkedFeatures,
      stripe: "",
      size: "micro"
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "productFeature.title",
      label: "Title"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "productFeature.description",
      label: "Description"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Priority"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("span", [_c("el-tag", {
          attrs: {
            size: "mini"
          }
        }, [_vm._v(_vm._s(scope.row.productFeature.priority))])], 1)];
      }
    }], null, false, 1397135571)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Status"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("span", [_c("el-tag", {
          attrs: {
            size: "mini"
          }
        }, [_vm._v(_vm._s(scope.row.productFeature.status))])], 1)];
      }
    }], null, false, 3751133141)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Enabler"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.productFeature.enabler ? _c("span", [_c("el-tag", {
          attrs: {
            size: "mini"
          }
        }, [_vm._v("Enabler")])], 1) : _vm._e()];
      }
    }], null, false, 4020841011)
  }), _vm._v(" "), _c("el-table-column", {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-button", {
          attrs: {
            type: "danger",
            circle: "",
            size: "mini",
            icon: "el-icon-delete"
          },
          on: {
            click: function click($event) {
              return _vm.deleteFeature(scope.row.productFeature.id);
            }
          }
        })];
      }
    }], null, false, 601617944)
  }, [_c("template", {
    slot: "header"
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.selectCreateFeatureFromProductDialog(_vm.selectedEntity.id);
      }
    }
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "create"
    }
  }), _vm._v(" Feature")], 1)], 1)], 2)], 1)], 1)], 1)], 1), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: "Ideas",
      name: "ideas"
    }
  }, [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading.idea,
      expression: "loading.idea"
    }],
    attrs: {
      data: _vm.selectedProductIdeas,
      stripe: "",
      size: "micro"
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "businessIdea.title",
      label: "Title"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "businessIdea.description",
      label: "Description"
    }
  }), _vm._v(" "), _c("el-table-column", {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-button", {
          attrs: {
            type: "danger",
            circle: "",
            size: "mini",
            icon: "el-icon-delete"
          },
          on: {
            click: function click($event) {
              return _vm.deleteIdea(scope.row.businessIdea.id);
            }
          }
        })];
      }
    }], null, false, 1375052213)
  }, [_c("template", {
    slot: "header"
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.createEntityIdea("Product", _vm.selectedEntity.id, _vm.selectedEntity.title);
      }
    }
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "create"
    }
  }), _vm._v(" Idea")], 1)], 1)], 2)], 1)], 1), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: "Risks",
      name: "risks"
    }
  }, [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading.risk,
      expression: "loading.risk"
    }],
    attrs: {
      data: _vm.selectedProductRisks,
      stripe: "",
      size: "micro"
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "risk.title",
      label: "Title"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "risk.description",
      label: "Description"
    }
  }), _vm._v(" "), _c("el-table-column", {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-button", {
          attrs: {
            type: "danger",
            circle: "",
            size: "mini",
            icon: "el-icon-delete"
          },
          on: {
            click: function click($event) {
              return _vm.deleteRisk(scope.row.risk.id);
            }
          }
        })];
      }
    }], null, false, 3378567251)
  }, [_c("template", {
    slot: "header"
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.selectCreateRiskFromProductDialog(_vm.selectedEntity.id);
      }
    }
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "create"
    }
  }), _vm._v(" Risk")], 1)], 1)], 2)], 1)], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini",
      icon: "el-icon-edit"
    },
    on: {
      click: function click($event) {
        return _vm.updateProductDetails();
      }
    }
  }, [_vm._v("Update")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini",
      icon: "el-icon-close"
    },
    on: {
      click: function click($event) {
        _vm.productDetailsDialog = false;
      }
    }
  }, [_vm._v("Close")])], 1)], 1) : _vm._e(), _vm._v(" "), _vm.touchpointDetailsDialog ? _c("el-dialog", {
    attrs: {
      title: "Touchpoint administration",
      visible: _vm.touchpointDetailsDialog,
      width: "90%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.touchpointDetailsDialog = $event;
      }
    }
  }, [_c("el-table", {
    attrs: {
      data: _vm.touchpointApplications,
      stripe: "",
      size: "mini"
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Icon",
      width: "100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("svg-icon", {
          staticStyle: {
            "font-size": "20px",
            color: "#304174"
          },
          attrs: {
            "icon-class": scope.row.icon
          }
        })];
      }
    }], null, false, 4085408727)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "name"
    }
  }, [_c("template", {
    slot: "header"
  }, [_c("span", [_vm._v("Name")])])], 2)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.touchpointDetailsDialog = false;
      }
    }
  }, [_vm._v("Close")])], 1)], 1) : _vm._e(), _vm._v(" "), _vm.addRiskDialog ? _c("el-dialog", {
    attrs: {
      title: _vm.computeAddRiskDialogTitle,
      visible: _vm.addRiskDialog,
      width: "90%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addRiskDialog = $event;
      }
    }
  }, [_c("el-form", {
    ref: "risk_form",
    attrs: {
      model: _vm.risk_form,
      "label-width": "140px",
      rules: _vm.riskRules
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Risk",
      prop: "title"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.risk_form.title,
      callback: function callback($$v) {
        _vm.$set(_vm.risk_form, "title", $$v);
      },
      expression: "risk_form.title"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Description",
      prop: "description"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      size: "mini"
    },
    model: {
      value: _vm.risk_form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.risk_form, "description", $$v);
      },
      expression: "risk_form.description"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Severity",
      prop: "severity"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.risk_form.severity,
      callback: function callback($$v) {
        _vm.$set(_vm.risk_form, "severity", $$v);
      },
      expression: "risk_form.severity"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: "Low",
      value: "Low"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Medium",
      value: "Medium"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "High",
      value: "High"
    }
  })], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Occurance",
      prop: "occuranceprobability"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.risk_form.occuranceprobability,
      callback: function callback($$v) {
        _vm.$set(_vm.risk_form, "occuranceprobability", $$v);
      },
      expression: "risk_form.occuranceprobability"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: "Low",
      value: "Low"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Medium",
      value: "Medium"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "High",
      value: "High"
    }
  })], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Impact",
      prop: "impactlevel"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.risk_form.impactlevel,
      callback: function callback($$v) {
        _vm.$set(_vm.risk_form, "impactlevel", $$v);
      },
      expression: "risk_form.impactlevel"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: "Low",
      value: "Low"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Medium",
      value: "Medium"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "High",
      value: "High"
    }
  })], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Mitigation Plan",
      prop: "mitigationplan"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      size: "mini"
    },
    model: {
      value: _vm.risk_form.mitigationplan,
      callback: function callback($$v) {
        _vm.$set(_vm.risk_form, "mitigationplan", $$v);
      },
      expression: "risk_form.mitigationplan"
    }
  })], 1)], 1), _vm._v(" "), _c("div", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      icon: "el-icon-close"
    },
    on: {
      click: function click($event) {
        _vm.addRiskDialog = false;
      }
    }
  }, [_vm._v("Cancel")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.createRisk
    }
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "create"
    }
  }), _vm._v(" Risk")], 1)], 1)], 1) : _vm._e(), _vm._v(" "), _vm.displayIntegrationSettingsDrawer ? _c("el-drawer", {
    attrs: {
      title: "Integration Settings",
      visible: _vm.displayIntegrationSettingsDrawer,
      direction: "rtl"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.displayIntegrationSettingsDrawer = $event;
      }
    }
  }, [_c("div", {
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_c("span", [_vm._v("Integration Settings: " + _vm._s(_vm.selectedParentDetailType) + " - " + _vm._s(_vm.selectedParent.title))]), _c("br"), _vm._v(" "), _c("span", {
    staticStyle: {
      "font-size": "0.6em"
    }
  }, [_vm._v("Map target " + _vm._s(_vm.selectedParentDetailType) + " to external project")])]), _vm._v(" "), _c("integration-component", {
    on: {
      close: function close($event) {
        _vm.displayIntegrationSettingsDrawer = false;
      }
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.displayImportedTicketsDrawer ? _c("el-drawer", {
    attrs: {
      title: "Manage imported tickets",
      visible: _vm.displayImportedTicketsDrawer,
      direction: "rtl",
      size: _vm.computeExternalTicketDrawerSize
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.displayImportedTicketsDrawer = $event;
      }
    }
  }, [_vm.displayImportedTicketsDrawer && _vm.selectedParentIndex !== null ? _c("external-ticket-list-component", {
    attrs: {
      offering: _vm.offering,
      parent: _vm.selectedParent,
      "parent-x": 125 + 28,
      "parent-y": _vm.computeEntityPosY + _vm.computeEntityLaneHeightCompounded(_vm.selectedParentIndex),
      "parent-type": _vm.selectedParentDetailType
    },
    on: {
      import: _vm.openImportTicketDialog,
      "ticket-imported": function ticketImported($event) {
        return _vm.externalTicketImported();
      },
      "layout-updated": _vm.updateExternalTicketDrawerLayout,
      "update-offering-additionalattributes": _vm.updateOfferingAdditionalAttributes
    }
  }) : _vm._e()], 1) : _vm._e(), _vm._v(" "), _vm.displayImportTicketsDialog ? _c("el-dialog", {
    attrs: {
      title: "Import Ticket",
      visible: _vm.displayImportTicketsDialog,
      width: "90%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.displayImportTicketsDialog = $event;
      }
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      offset: "8",
      span: "8"
    }
  }, [_c("el-form", {
    ref: "import_ticket_form",
    attrs: {
      model: _vm.import_ticket_form,
      "label-width": "120px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Project"
    }
  }, [_c("el-select", {
    staticClass: "tenancy",
    attrs: {
      placeholder: "Select project",
      size: "mini"
    },
    on: {
      change: _vm.updateProjectSelection
    },
    model: {
      value: _vm.import_ticket_form.external_ticket,
      callback: function callback($$v) {
        _vm.$set(_vm.import_ticket_form, "external_ticket", $$v);
      },
      expression: "import_ticket_form.external_ticket"
    }
  }, _vm._l(_vm.integrations, function (integration_datum) {
    return _c("el-option-group", {
      key: integration_datum.appName,
      attrs: {
        label: "Tenancy - " + integration_datum.appName
      }
    }, _vm._l(_vm.filteredProjectsByImport(integration_datum.projects), function (project) {
      return _c("el-option", {
        key: project.name,
        attrs: {
          label: project.name,
          value: project.name
        }
      });
    }), 1);
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Type"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Select project type",
      size: "mini"
    },
    model: {
      value: _vm.import_ticket_form.import_type,
      callback: function callback($$v) {
        _vm.$set(_vm.import_ticket_form, "import_type", $$v);
      },
      expression: "import_ticket_form.import_type"
    }
  }, [_c("el-option", {
    attrs: {
      label: "Both",
      value: "both",
      disabled: ""
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Epic",
      value: "epic"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Stories",
      value: "stories",
      disabled: ""
    }
  })], 1)], 1)], 1)], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button-group", {
    attrs: {
      size: "mini"
    }
  }, [!_vm.hasTicket ? _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini",
      disabled: _vm.loading.data_import,
      icon: _vm.loading.data_import ? "el-icon-loading" : "el-icon-download"
    },
    on: {
      click: _vm.importProjectTickets
    }
  }, [_vm._v("Import")]) : _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini",
      disabled: _vm.loading.data_import,
      icon: _vm.loading.data_import ? "el-icon-loading" : "el-icon-refresh"
    },
    on: {
      click: _vm.importProjectTickets
    }
  }, [_vm._v("Resync")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini",
      icon: "el-icon-close"
    },
    on: {
      click: function click($event) {
        _vm.displayImportTicketsDialog = false;
      }
    }
  }, [_vm._v("Close")])], 1), _vm._v(" "), _c("br"), _vm._v(" "), _c("el-link", {
    staticClass: "fr",
    attrs: {
      type: "info"
    },
    on: {
      click: _vm.openExternalTicketListDialog
    }
  }, [_c("small", {
    staticStyle: {
      "font-size": "70%"
    }
  }, [_vm._v("Skip")])])], 1)], 1) : _vm._e(), _vm._v(" "), _vm.productReportDialog ? _c("el-dialog", {
    attrs: {
      title: "Product Report",
      visible: _vm.productReportDialog,
      width: "90%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.productReportDialog = $event;
      }
    }
  }, [_c("div", {
    staticClass: "text-center"
  }, [_vm._v("Report to be updated")]), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.productReportDialog = false;
      }
    }
  }, [_vm._v("Close")])], 1)]) : _vm._e(), _vm._v(" "), _vm.serviceReportDialog ? _c("el-dialog", {
    attrs: {
      title: "Service Report",
      visible: _vm.serviceReportDialog,
      width: "90%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.serviceReportDialog = $event;
      }
    }
  }, [_c("div", {
    staticClass: "text-center"
  }, [_vm._v("Report to be updated")]), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      icon: "el-icon-close"
    },
    on: {
      click: function click($event) {
        _vm.serviceReportDialog = false;
      }
    }
  }, [_vm._v("Close")])], 1)]) : _vm._e(), _vm._v(" "), _vm.serviceDetailsDialog ? _c("el-dialog", {
    attrs: {
      title: "Service Details",
      visible: _vm.serviceDetailsDialog,
      width: "90%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.serviceDetailsDialog = $event;
      }
    }
  }, [_c("el-row", [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("table", {
    staticClass: "ml-10",
    staticStyle: {
      "margin-bottom": "10pt",
      "margin-right": "20pt"
    },
    attrs: {
      width: "96%"
    }
  }, [_c("col", {
    staticStyle: {
      width: "30%"
    }
  }), _vm._v(" "), _c("col", {
    staticStyle: {
      width: "60%"
    }
  }), _vm._v(" "), _c("tr", {
    staticClass: "text-left"
  }, [_c("th", [_vm._v("Title")]), _vm._v(" "), _c("td", [_c("el-input", {
    staticStyle: {
      "margin-top": "1pt",
      "margin-bottom": "1pt"
    },
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.selectedEntity.title,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedEntity, "title", $$v);
      },
      expression: "selectedEntity.title"
    }
  })], 1)]), _vm._v(" "), _c("tr", {
    staticClass: "text-left"
  }, [_c("th", [_vm._v("Description")]), _vm._v(" "), _c("td", [_c("el-input", {
    staticStyle: {
      "margin-top": "1pt",
      "margin-bottom": "1pt"
    },
    attrs: {
      type: "textarea",
      size: "mini"
    },
    model: {
      value: _vm.selectedEntity.description,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedEntity, "description", $$v);
      },
      expression: "selectedEntity.description"
    }
  })], 1)]), _vm._v(" "), _c("tr", {
    staticClass: "text-left",
    staticStyle: {
      height: "28px"
    }
  }, [_c("th", [_vm._v("Created")]), _vm._v(" "), _c("td", [_vm._v(_vm._s(_vm.selectedEntity.createdby) + " @ " + _vm._s(_vm.computeHumanFriendlyDate(_vm.selectedEntity.createdat)))])]), _vm._v(" "), _c("tr", {
    staticClass: "text-left",
    staticStyle: {
      height: "28px"
    }
  }, [_c("th", [_vm._v("Modified")]), _vm._v(" "), _c("td", [_vm._v(_vm._s(_vm.selectedEntity.modifiedby) + " @ " + _vm._s(_vm.computeHumanFriendlyDate(_vm.selectedEntity.modifiedat)))])])])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("table", {
    staticClass: "ml-10",
    staticStyle: {
      "margin-bottom": "10pt",
      "margin-right": "20pt"
    },
    attrs: {
      width: "96%"
    }
  }, [_c("col", {
    staticStyle: {
      width: "30%"
    }
  }), _vm._v(" "), _c("col", {
    staticStyle: {
      width: "60%"
    }
  }), _vm._v(" "), _c("tr", {
    staticClass: "text-left"
  }, [_c("th", [_vm._v("Service Costs")]), _vm._v(" "), _c("td", [_c("el-input", {
    staticStyle: {
      "margin-top": "1pt",
      "margin-bottom": "1pt"
    },
    attrs: {
      type: "textarea",
      size: "mini"
    },
    model: {
      value: _vm.selectedEntity.servicecosts,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedEntity, "servicecosts", $$v);
      },
      expression: "selectedEntity.servicecosts"
    }
  })], 1)]), _vm._v(" "), _c("tr", {
    staticClass: "text-left"
  }, [_c("th", [_vm._v("Revenue Details")]), _vm._v(" "), _c("td", [_c("el-input", {
    staticStyle: {
      "margin-top": "1pt",
      "margin-bottom": "1pt"
    },
    attrs: {
      type: "textarea",
      size: "mini"
    },
    model: {
      value: _vm.selectedEntity.revenuedetails,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedEntity, "revenuedetails", $$v);
      },
      expression: "selectedEntity.revenuedetails"
    }
  })], 1)]), _vm._v(" "), _c("tr", {
    staticClass: "text-left"
  }, [_c("th", [_vm._v("Service Type")]), _vm._v(" "), _c("td", [_c("el-input", {
    staticStyle: {
      "margin-top": "1pt",
      "margin-bottom": "1pt"
    },
    attrs: {
      type: "textarea",
      size: "mini"
    },
    model: {
      value: _vm.selectedEntity.servicetype,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedEntity, "servicetype", $$v);
      },
      expression: "selectedEntity.servicetype"
    }
  })], 1)])])])], 1), _vm._v(" "), _c("el-tabs", {
    attrs: {
      value: "service_application"
    },
    on: {
      "tab-click": _vm.handleSelectedServiceDialogTabClick
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "ServiceApplication",
      name: "service_application"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    staticClass: "text-center",
    staticStyle: {
      "background-color": "#eee",
      "padding-top": "10px",
      "padding-bottom": "10px"
    },
    attrs: {
      span: 24
    }
  }, [_c("strong", [_vm._v("Service Applications")])])], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("div", {
    staticClass: "text-center mt-5"
  }, [_c("el-button-group", [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini",
      icon: "el-icon-plus"
    },
    on: {
      click: function click($event) {
        return _vm.selectCreateProcessFromServiceDialog(_vm.selectedEntity.id, _vm.selectedEntity.title, "automated");
      }
    }
  }, [_vm._v("Automated")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini",
      icon: "el-icon-plus"
    },
    on: {
      click: function click($event) {
        return _vm.selectCreateProcessFromServiceDialog(_vm.selectedEntity.id, _vm.selectedEntity.title, "semi_automated");
      }
    }
  }, [_vm._v("SemiAutomated")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini",
      icon: "el-icon-plus"
    },
    on: {
      click: function click($event) {
        return _vm.selectCreateProcessFromServiceDialog(_vm.selectedEntity.id, _vm.selectedEntity.title, "manual");
      }
    }
  }, [_vm._v("Manual")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini",
      icon: "el-icon-plus"
    },
    on: {
      click: function click($event) {
        return _vm.selectCreateProcessFromServiceDialog(_vm.selectedEntity.id, _vm.selectedEntity.title, "thirdparty");
      }
    }
  }, [_vm._v("Thirdparty")])], 1)], 1), _vm._v(" "), _c("el-table", {
    attrs: {
      data: _vm.selectedEntity.linkedServiceApplications,
      stripe: "",
      size: "micro"
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "serviceApplication.title",
      label: "Title"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "serviceApplication.description",
      label: "Description"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "serviceApplication.priority",
      label: "Priority"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "serviceApplication.status",
      label: "Status"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "serviceApplication.complexity",
      label: "Complexity"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "#"
    }
  }, [[_c("el-button", {
    attrs: {
      type: "danger",
      size: "mini",
      icon: "el-icon-delete"
    }
  })]], 2)], 1)], 1)], 1)], 1), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: "Ideas",
      name: "ideas"
    }
  }, [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading.idea,
      expression: "loading.idea"
    }],
    attrs: {
      data: _vm.selectedServiceIdeas,
      stripe: "",
      size: "micro"
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "businessIdea.title",
      label: "Title"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "businessIdea.description",
      label: "Description"
    }
  }), _vm._v(" "), _c("el-table-column", [_c("template", {
    slot: "header"
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.createEntityIdea("Service", _vm.selectedEntity.id, _vm.selectedEntity.title);
      }
    }
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "create"
    }
  }), _vm._v(" Idea")], 1)], 1), _vm._v(" "), [_c("el-button", {
    attrs: {
      type: "danger",
      size: "mini",
      icon: "el-icon-delete"
    }
  })]], 2)], 1)], 1), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: "Risks",
      name: "risks"
    }
  }, [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading.risk,
      expression: "loading.risk"
    }],
    attrs: {
      data: _vm.selectedServiceRisks,
      stripe: "",
      size: "micro"
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "risk.title",
      label: "Title"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "risk.description",
      label: "Description"
    }
  }), _vm._v(" "), _c("el-table-column", [_c("template", {
    slot: "header"
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.selectCreateRiskFromServiceDialog(_vm.selectedEntity.id);
      }
    }
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "create"
    }
  }), _vm._v(" Risk")], 1)], 1), _vm._v(" "), [_c("el-button", {
    attrs: {
      type: "danger",
      size: "mini",
      icon: "el-icon-delete"
    }
  })]], 2)], 1)], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.updateServiceDetails();
      }
    }
  }, [_vm._v("Update")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini",
      icon: "el-icon-close"
    },
    on: {
      click: function click($event) {
        _vm.serviceDetailsDialog = false;
      }
    }
  }, [_vm._v("Close")])], 1)], 1) : _vm._e(), _vm._v(" "), _vm.ticketDetailsDialog ? _c("el-dialog", {
    staticStyle: {
      "margin-left": "3%",
      height: "95%"
    },
    attrs: {
      title: _vm.computeTicketDetailTitle(),
      visible: _vm.ticketDetailsDialog,
      width: "90%",
      top: "1vh",
      "custom-class": "ticket-details-dialog"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.ticketDetailsDialog = $event;
      }
    }
  }, [_vm.ticketDetailsDialog ? _c("ticket-component", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading.ticket,
      expression: "loading.ticket"
    }],
    attrs: {
      customer: _vm.customer,
      "journey-map": _vm.journeyMap,
      offering: _vm.offering,
      parent: _vm.activeTicketParent,
      child: _vm.selectedTicket,
      "parent-type": _vm.selectedParentDetailType
    },
    on: {
      updateParentType: _vm.updateTicketParentType,
      updateParent: _vm.updateTicketActiveParent,
      updateChildTitleDescription: _vm.updateChildTitleDescriptionFromTicket,
      updateChildType: _vm.updateChildTypeFromTicket,
      updateTicketDialogTitle: _vm.updateTicketDialogTitle,
      updateFeatureEnabler: _vm.updateFeatureEnabler,
      updateFeatureStepRelation: _vm.updateStepFeatureRelation,
      updateServiceApplicationRelation: _vm.updateServiceApplicationRelation,
      deleteFeatureFeatureRelation: _vm.deleteFeatureFeatureRelation,
      deleteServiceApplicationServiceApplicationRelation: _vm.deleteServiceApplicationServiceApplicationRelation,
      close: function close($event) {
        _vm.ticketDetailsDialog = false;
      }
    }
  }) : _vm._e()], 1) : _vm._e(), _vm._v(" "), _vm.addServiceApplicationDialog ? _c("el-dialog", {
    attrs: {
      title: "Add Process",
      visible: _vm.addServiceApplicationDialog,
      width: "90%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addServiceApplicationDialog = $event;
      }
    }
  }, [_c("el-form", {
    ref: "addServiceApplicationform",
    attrs: {
      model: _vm.service_application_form,
      rules: _vm.addServiceApplicationRules,
      "label-width": "100px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Name",
      prop: "title"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.service_application_form.title,
      callback: function callback($$v) {
        _vm.$set(_vm.service_application_form, "title", $$v);
      },
      expression: "service_application_form.title"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Description",
      prop: "description"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      size: "mini"
    },
    model: {
      value: _vm.service_application_form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.service_application_form, "description", $$v);
      },
      expression: "service_application_form.description"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Priority",
      prop: "priority"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.service_application_form.priority,
      callback: function callback($$v) {
        _vm.$set(_vm.service_application_form, "priority", $$v);
      },
      expression: "service_application_form.priority"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: "Very Low"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Low"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Medium"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "High"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Very High"
    }
  })], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Status",
      prop: "status"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Select Status",
      size: "mini"
    },
    model: {
      value: _vm.service_application_form.status,
      callback: function callback($$v) {
        _vm.$set(_vm.service_application_form, "status", $$v);
      },
      expression: "service_application_form.status"
    }
  }, _vm._l(_vm.statuses, function (status) {
    return _c("el-option", {
      key: status.value,
      attrs: {
        label: status.label,
        value: status.value
      }
    });
  }), 1)], 1), _vm._v(" "), _vm.service_application_form.featureId === "" && _vm.service_application_form.stepId === "" ? _c("div", [_c("el-form-item", {
    attrs: {
      label: "Enabler",
      prop: "enabler"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.service_application_form.enabler,
      callback: function callback($$v) {
        _vm.$set(_vm.service_application_form, "enabler", $$v);
      },
      expression: "service_application_form.enabler"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: true
    }
  }, [_vm._v("Yes")]), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: false
    }
  }, [_vm._v("No")])], 1)], 1), _vm._v(" "), _vm.service_application_form.enabler ? _c("el-form-item", {
    attrs: {
      label: "Feature",
      prop: "featureId"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Feature",
      size: "mini"
    },
    model: {
      value: _vm.service_application_form.featureId,
      callback: function callback($$v) {
        _vm.$set(_vm.service_application_form, "featureId", $$v);
      },
      expression: "service_application_form.featureId"
    }
  }, _vm._l(_vm.sortedProducts, function (product) {
    return _c("el-option-group", {
      key: product.product.id,
      attrs: {
        label: product.product.title,
        value: product.product.id
      }
    }, _vm._l(product.product.linkedFeatures, function (feature) {
      return _c("el-option", {
        key: feature.productFeature.id,
        attrs: {
          label: feature.productFeature.title,
          value: feature.productFeature.id
        }
      });
    }), 1);
  }), 1)], 1) : _c("el-form-item", {
    attrs: {
      label: "Step",
      prop: "stepId"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Step",
      size: "mini"
    },
    model: {
      value: _vm.service_application_form.stepId,
      callback: function callback($$v) {
        _vm.$set(_vm.service_application_form, "stepId", $$v);
      },
      expression: "service_application_form.stepId"
    }
  }, _vm._l(_vm.journeyMap.linkedJourneySteps, function (step) {
    return _c("el-option", {
      key: step.journeyStep.id,
      attrs: {
        label: step.journeyStep.title,
        value: step.journeyStep.id
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Service",
      prop: "serviceId"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Service",
      size: "mini"
    },
    model: {
      value: _vm.service_application_form.serviceId,
      callback: function callback($$v) {
        _vm.$set(_vm.service_application_form, "serviceId", $$v);
      },
      expression: "service_application_form.serviceId"
    }
  }, _vm._l(_vm.sortedServices, function (service) {
    return _c("el-option", {
      key: service.service.id,
      attrs: {
        label: service.service.title,
        value: service.service.id
      }
    });
  }), 1)], 1)], 1) : _vm._e()], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.createServiceApplication
    }
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "create"
    }
  }), _vm._v(" Process")], 1), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini",
      icon: "el-icon-close"
    },
    on: {
      click: function click($event) {
        _vm.addServiceApplicationDialog = false;
      }
    }
  }, [_vm._v("Close")])], 1)], 1) : _vm._e(), _vm._v(" "), _vm.settingsDialog ? _c("el-dialog", {
    attrs: {
      visible: _vm.settingsDialog,
      title: "Settings",
      width: "90%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.settingsDialog = $event;
      }
    }
  }, [_c("settings-component", {
    attrs: {
      offering: _vm.offering
    },
    on: {
      "rename-offering": _vm.renameOffering,
      "delete-offering": _vm.deleteOffering,
      close: _vm.closeSettingsDialog
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.addTouchpointDialog ? _c("el-dialog", {
    attrs: {
      visible: _vm.addTouchpointDialog,
      title: _vm.isTouchpointEdit ? "Touchpoint Details" : "Create Touchpoint",
      width: "90%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addTouchpointDialog = $event;
      }
    }
  }, [_c("touchpoint-create-component", {
    attrs: {
      type: _vm.touchpointType,
      edit: _vm.isTouchpointEdit,
      touchpoint: _vm.selectedTouchpoint,
      readonly: _vm.isTouchpointReadOnly
    },
    on: {
      "add-touchpoint": _vm.addTouchpoint,
      close: _vm.closeAddTouchpointDialog
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.displaySyncTicketsDialog ? _c("el-dialog", {
    attrs: {
      visible: _vm.displaySyncTicketsDialog,
      title: "Sync Tickets",
      width: "90%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.displaySyncTicketsDialog = $event;
      }
    }
  }, [_c("div", {
    staticClass: "text-center"
  }, [_vm.selectedParentDetailType ? _c("span", [_vm._v("This operation will sync all your JIRA tickets from "), _c("strong", [_vm._v(_vm._s(_vm.selectedParentDetailType) + " - " + _vm._s(_vm.selectedEntity.title))]), _vm._v(" to Atlassian.")]) : _c("span", [_vm._v("This operation will sync all your JIRA tickets to Atlassian.")]), _vm._v(" "), _c("div", {
    staticClass: "text-center"
  }, [_vm._v("Would you like to continue?")]), _vm._v(" "), _c("div", {
    staticClass: "text-center mt-10"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini",
      icon: _vm.syncTicketProgress.loading ? "el-icon-loading" : "el-icon-refresh",
      disabled: _vm.syncTicketProgress.loading
    },
    on: {
      click: _vm.syncTickets
    }
  }, [_vm._v("Sync")])], 1), _vm._v(" "), _vm.syncTicketProgress.loading ? _c("div", {
    staticClass: "text-center"
  }, [_c("i", [_c("small", [_vm._v(_vm._s(_vm.syncTicketProgress.loading_message))])])]) : _vm._e()]), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.displaySyncTicketsDialog = false;
      }
    }
  }, [_vm._v("Close")])], 1)]) : _vm._e()], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;