import { render, staticRenderFns } from "./dummy2.vue?vue&type=template&id=50e7fde2&scoped=true"
import script from "./dummy2.vue?vue&type=script&lang=js"
export * from "./dummy2.vue?vue&type=script&lang=js"
import style0 from "./dummy2.vue?vue&type=style&index=0&id=50e7fde2&rel=stylesheet%2Fscss&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50e7fde2",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\Sastha\\Documents\\GitHub\\prodiggiapp-frontend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('50e7fde2')) {
      api.createRecord('50e7fde2', component.options)
    } else {
      api.reload('50e7fde2', component.options)
    }
    module.hot.accept("./dummy2.vue?vue&type=template&id=50e7fde2&scoped=true", function () {
      api.rerender('50e7fde2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/processes/market_grow/dummy2.vue"
export default component.exports