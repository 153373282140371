"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("d3-network", {
    attrs: {
      "net-nodes": _vm.nodes,
      "net-links": _vm.links,
      options: _vm.options,
      "sim-cb": _vm.simCb
    }
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;