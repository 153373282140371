"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-form", {
    ref: "feature_form",
    attrs: {
      model: _vm.feature_form,
      "label-width": "200px",
      rules: _vm.featureRules
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Name",
      prop: "title"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.feature_form.title,
      callback: function callback($$v) {
        _vm.$set(_vm.feature_form, "title", $$v);
      },
      expression: "feature_form.title"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Description",
      prop: "description"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      size: "mini"
    },
    model: {
      value: _vm.feature_form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.feature_form, "description", $$v);
      },
      expression: "feature_form.description"
    }
  })], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Priority",
      prop: "priority"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.feature_form.priority,
      callback: function callback($$v) {
        _vm.$set(_vm.feature_form, "priority", $$v);
      },
      expression: "feature_form.priority"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: "Low"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Medium"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "High"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Highest"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Status",
      prop: "status"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Select Status",
      size: "mini"
    },
    model: {
      value: _vm.feature_form.status,
      callback: function callback($$v) {
        _vm.$set(_vm.feature_form, "status", $$v);
      },
      expression: "feature_form.status"
    }
  }, _vm._l(_vm.statuses, function (status) {
    return _c("el-option", {
      key: status.value,
      attrs: {
        label: status.label,
        value: status.value
      }
    });
  }), 1)], 1)], 1)], 1), _vm._v(" "), _c("div", [!_vm.selectedOffering.id ? _c("el-form-item", {
    attrs: {
      label: "Offering",
      prop: "offeringId"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Select Offering",
      size: "mini"
    },
    model: {
      value: _vm.feature_form.offeringId,
      callback: function callback($$v) {
        _vm.$set(_vm.feature_form, "offeringId", $$v);
      },
      expression: "feature_form.offeringId"
    }
  }, _vm._l(_vm.offerings, function (offering, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: offering.title,
        value: offering.id
      }
    });
  }), 1)], 1) : _vm._e()], 1), _vm._v(" "), _c("div", [_vm.feature_form.offeringId ? _c("el-form-item", {
    attrs: {
      label: "Add feature to Product",
      prop: "productId"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Select Product",
      size: "mini"
    },
    model: {
      value: _vm.feature_form.productId,
      callback: function callback($$v) {
        _vm.$set(_vm.feature_form, "productId", $$v);
      },
      expression: "feature_form.productId"
    }
  }, _vm._l(_vm.selectedOffering.linkedProducts, function (product, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: product.product.title,
        value: product.product.id
      }
    });
  }), 1)], 1) : _vm._e()], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "text-right"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini",
      disabled: _vm.loading.general
    },
    on: {
      click: _vm.createFeature
    }
  }, [_vm._v("Create Feature")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "secondary",
      size: "mini",
      disabled: _vm.loading.general
    },
    on: {
      click: _vm.emitCloseEvent
    }
  }, [_vm._v("Close")])], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;