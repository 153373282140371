"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_vm.showMVP() ? _c("el-tabs", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.tab_loading,
      expression: "tab_loading"
    }],
    attrs: {
      value: "goals_initiatives"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      id: "tab_strategy_goals_initiatives",
      label: "Goals & Objectives",
      name: "goals_initiatives"
    }
  }, [_c("goal-component", {
    attrs: {
      type: "offering"
    }
  })], 1), _vm._v(" "), _vm.showMVP() ? _c("el-tab-pane", {
    attrs: {
      id: "tab_strategy_competitors",
      name: "competitors"
    }
  }, [_c("span", {
    attrs: {
      slot: "label"
    },
    slot: "label"
  }, [_c("i", {
    staticClass: "el-icon-star-on"
  }), _vm._v(" Offering Competitiveness")]), _vm._v(" "), _c("competitors-component")], 1) : _vm._e()], 1) : _c("goal-component", {
    attrs: {
      type: "offering"
    }
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;