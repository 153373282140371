"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "navbar"
  }, [_c("hamburger", {
    staticClass: "hamburger-container",
    attrs: {
      id: "hamburger-container",
      "is-active": _vm.sidebar.opened
    },
    on: {
      toggleClick: _vm.toggleSideBar
    }
  }), _vm._v(" "), _c("breadcrumb", {
    staticClass: "breadcrumb-container",
    attrs: {
      id: "breadcrumb-container"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "right-menu"
  }, [_c("div", {
    staticClass: "right-menu-item"
  }, [_c("el-tag", {
    attrs: {
      type: "warning"
    }
  }, [_vm._v(_vm._s(_vm.active_env) + " ENVIRONMENT")])], 1), _vm._v(" "), _vm.device !== "mobile" ? [_c("search", {
    staticClass: "right-menu-item",
    attrs: {
      id: "header-search"
    }
  })] : _vm._e(), _vm._v(" "), _c("el-dropdown", {
    staticClass: "avatar-container right-menu-item hover-effect",
    attrs: {
      trigger: "click"
    }
  }, [_c("div", {
    staticClass: "avatar-wrapper",
    on: {
      mouseover: function mouseover($event) {
        return _vm.onHover();
      },
      mouseleave: function mouseleave($event) {
        return _vm.unHover();
      }
    }
  }, [_vm.hover ? _c("avatar", {
    attrs: {
      id: "prodiggi-nav-avatar",
      username: _vm.user_name,
      initials: _vm.user_name,
      size: 32,
      "background-color": "teal",
      "custom-style": {
        cursor: "pointer",
        height: "32px",
        "border-radius": "16px"
      },
      rounded: true
    }
  }) : _vm._e(), _vm._v(" "), !_vm.hover ? _c("avatar", {
    attrs: {
      username: _vm.user_name,
      size: 32,
      "background-color": "teal",
      "custom-style": {
        cursor: "pointer",
        height: "32px",
        width: "32px",
        "border-radius": "16px"
      },
      rounded: true,
      src: _vm.avatar
    }
  }) : _vm._e(), _vm._v(" "), _c("i", {
    staticClass: "el-icon-caret-bottom"
  })], 1), _vm._v(" "), _c("el-dropdown-menu", {
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, [_c("router-link", {
    attrs: {
      to: "/profile/index"
    }
  }, [_c("el-dropdown-item", {
    attrs: {
      icon: "el-icon-user"
    }
  }, [_vm._v("My Profile")])], 1), _vm._v(" "), _c("router-link", {
    attrs: {
      to: "/profile/tasks"
    }
  }, [_c("el-dropdown-item", {
    attrs: {
      icon: "el-icon-tickets"
    }
  }, [_vm._v("My Tasks")])], 1), _vm._v(" "), _c("el-dropdown-item", {
    attrs: {
      divided: ""
    }
  }, [_c("span", {
    staticStyle: {
      display: "block"
    },
    on: {
      click: _vm.logout
    }
  }, [_c("i", {
    staticClass: "el-icon-switch-button"
  }), _vm._v("Log Out")])])], 1)], 1)], 2)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;