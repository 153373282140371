"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _default = exports.default = {
  name: 'FeatureManagmentRefineFeatures',
  data: function data() {
    return {
      loading: false,
      features_search: '',
      checked_features: false,
      checked_enablers: false,
      checked_orphans: false,
      userAssignedHistoryDialog: false,
      addFeatureDialog: false,
      addStoryDialog: false,
      features: [{
        name: 'Mendix',
        description: 'Cauliflower cheese cheesy grin stinking bishop. Boursin cheese and biscuits rubber cheese stilton cauliflower cheese dolcelatte swiss cheese and biscuits. Squirty cheese monterey jack danish fontina taleggio cream cheese mozzarella when the cheese comes out everybodys happy monterey jack. Manchego cheese and biscuits cheeseburger everyone loves.Cheese triangles babybel stinking bishop. Cheese on toast airedale cut the cheese melted cheese who moved my cheese babybel cheesy grin paneer. Bavarian bergkase edam queso stilton cheese and biscuits cheese on toast croque monsieur mozzarella. Brie pecorino cheddar pepper jack cheesecake',
        has_parent: true,
        enabler: true,
        value: 1,
        priority: 1,
        is_stratergic: true,
        status: 'Operational',
        first_added: '10-12-2016',
        improved_in: '10-12-2016',
        prime_user: {
          name: 'John Doe',
          design_goal: 'Lorem Ipsum',
          mockups: [{
            name: 'mockup 1'
          }, {
            name: 'mockup 2'
          }]
        },
        additional_users: [{
          name: 'Add John',
          design_goal: 'Lorem Design Ipsum'
        }, {
          name: 'Add John',
          design_goal: 'Lorem Design Ipsum'
        }],
        competitors: 'Dont Offer',
        other_users: '',
        created_by: '',
        assigned_to: '',
        assign_history: [{
          name: 'John Doe',
          status: 'QC',
          assigned_on: '11-10-2019'
        }, {
          name: 'John Doe',
          status: 'Business Design',
          assigned_on: '11-10-2019'
        }],
        prioritisation: {
          moscow: '123',
          wsjf: '456',
          ark: '789'
        },
        business_details: {
          scarcity: 'scarce',
          offered_by: [{
            name: 'Competitor A',
            quality: 'High'
          }, {
            name: 'Competitor B',
            quality: 'Medium'
          }],
          revenue: {
            method: 'type',
            monetary_potential: 'high'
          }
        },
        stories: [{
          name: 'Story A',
          description: 'Lorem Ipsum',
          enabler: true,
          value: 2,
          priority: 1,
          is_stratergic: true,
          status: 'Operational',
          first_added: '10-12-2016',
          improved_in: '10-12-2016',
          prime_user: {
            name: 'John Doe',
            design_goal: 'Lorem Ipsum'
          },
          other_users: '',
          created_by: 'john doe',
          assigned_to: 'justin',
          assign_history: [{
            name: 'John Doe',
            status: 'QC',
            assigned_on: '11-10-2019'
          }, {
            name: 'John Doe',
            status: 'Business Design',
            assigned_on: '11-10-2019'
          }],
          steps: [{
            name: 'Step A'
          }, {
            name: 'Step B'
          }, {
            name: 'Step C'
          }]
        }]
      }, {
        name: 'CodeRobots',
        description: 'Cauliflower cheese cheesy grin stinking bishop. Boursin cheese and biscuits rubber cheese stilton cauliflower cheese dolcelatte swiss cheese and biscuits. Squirty cheese monterey jack danish fontina taleggio cream cheese mozzarella when the cheese comes out everybodys happy monterey jack. Manchego cheese and biscuits cheeseburger everyone loves.Cheese triangles babybel stinking bishop. Cheese on toast airedale cut the cheese melted cheese who moved my cheese babybel cheesy grin paneer. Bavarian bergkase edam queso stilton cheese and biscuits cheese on toast croque monsieur mozzarella. Brie pecorino cheddar pepper jack cheesecake',
        has_parent: false,
        enabler: true,
        value: 2,
        priority: 5,
        is_stratergic: false,
        status: 'Candidate',
        first_added: '10-12-2016',
        improved_in: '10-12-2016',
        prime_user: {
          name: 'John Doe',
          design_goal: 'Lorem Ipsum',
          mockups: [{
            name: 'mockup 1'
          }, {
            name: 'mockup 2'
          }]
        },
        additional_users: [{
          name: 'Add John',
          design_goal: 'Lorem Design Ipsum'
        }, {
          name: 'Add John',
          design_goal: 'Lorem Design Ipsum'
        }],
        competitors: 'Commodity',
        other_users: '',
        created_by: '',
        assigned_to: '',
        assign_history: [{
          name: 'John Doe',
          status: 'QC',
          assigned_on: '11-10-2019'
        }, {
          name: 'John Doe',
          status: 'Business Design',
          assigned_on: '11-10-2019'
        }],
        prioritisation: {
          moscow: '',
          wsjf: '',
          ark: ''
        },
        business_details: {
          scarcity: 'scarce',
          offered_by: [{
            name: 'Competitor A',
            quality: 'High'
          }, {
            name: 'Competitor B',
            quality: 'Medium'
          }],
          revenue: {
            method: 'type',
            monetary_potential: 'high'
          }
        },
        stories: [{
          name: '',
          description: '',
          enabler: true,
          value: 1,
          priority: 1,
          is_stratergic: true,
          status: 'Operational',
          first_added: '10-12-2016',
          improved_in: '10-12-2016',
          prime_user: {
            name: 'John Doe',
            design_goal: 'Lorem Ipsum'
          },
          other_users: '',
          created_by: '',
          assigned_to: '',
          assign_history: [{
            name: 'John Doe',
            status: 'QC',
            assigned_on: '11-10-2019'
          }, {
            name: 'John Doe',
            status: 'Business Design',
            assigned_on: '11-10-2019'
          }],
          steps: [{
            name: 'Step A'
          }, {
            name: 'Step B'
          }, {
            name: 'Step C'
          }]
        }]
      }, {
        name: 'Story Teller',
        description: 'Cauliflower cheese cheesy grin stinking bishop. Boursin cheese and biscuits rubber cheese stilton cauliflower cheese dolcelatte swiss cheese and biscuits. Squirty cheese monterey jack danish fontina taleggio cream cheese mozzarella when the cheese comes out everybodys happy monterey jack. Manchego cheese and biscuits cheeseburger everyone loves.Cheese triangles babybel stinking bishop. Cheese on toast airedale cut the cheese melted cheese who moved my cheese babybel cheesy grin paneer. Bavarian bergkase edam queso stilton cheese and biscuits cheese on toast croque monsieur mozzarella. Brie pecorino cheddar pepper jack cheesecake',
        has_parent: true,
        enabler: true,
        value: 1,
        priority: 3,
        is_stratergic: true,
        status: 'Build',
        first_added: '10-12-2016',
        improved_in: '10-12-2016',
        prime_user: {
          name: 'John Doe',
          design_goal: 'Lorem Ipsum',
          mockups: [{
            name: 'mockup 1'
          }, {
            name: 'mockup 2'
          }]
        },
        additional_users: [{
          name: 'Add John',
          design_goal: 'Lorem Design Ipsum'
        }, {
          name: 'Add John',
          design_goal: 'Lorem Design Ipsum'
        }],
        competitors: 'Scarce',
        other_users: '',
        created_by: '',
        assigned_to: '',
        assign_history: [{
          name: 'John Doe',
          status: 'QC',
          assigned_on: '11-10-2019'
        }, {
          name: 'John Doe',
          status: 'Business Design',
          assigned_on: '11-10-2019'
        }],
        prioritisation: {
          moscow: '',
          wsjf: '',
          ark: ''
        },
        business_details: {
          scarcity: 'scarce',
          offered_by: [{
            name: 'Competitor A',
            quality: 'High'
          }, {
            name: 'Competitor B',
            quality: 'Medium'
          }],
          revenue: {
            method: 'type',
            monetary_potential: 'high'
          }
        },
        stories: [{
          name: 'Story C',
          description: 'Lorem ipsum',
          enabler: true,
          value: 3,
          priority: 1,
          is_stratergic: true,
          status: 'Operational',
          first_added: '10-12-2016',
          improved_in: '10-12-2016',
          prime_user: {
            name: 'John Doe',
            design_goal: 'Lorem Ipsum'
          },
          other_users: '',
          created_by: '',
          assigned_to: '',
          assign_history: [{
            name: 'John Doe',
            status: 'QC',
            assigned_on: '11-10-2019'
          }, {
            name: 'John Doe',
            status: 'Business Design',
            assigned_on: '11-10-2019'
          }],
          steps: [{
            name: 'Step A'
          }, {
            name: 'Step B'
          }, {
            name: 'Step C'
          }]
        }]
      }],
      selectedFeature: {},
      selectedStory: {},
      story_step_form: {
        name: ''
      },
      activeAddFormStep: 0,
      feature_form: {
        name: '',
        description: '',
        has_parent: true,
        enabler: true,
        value: 1,
        priority: 1,
        is_stratergic: true,
        status: '',
        prime_user: {
          name: '',
          design_goal: '',
          mockups: []
        },
        additional_users: [{
          name: '',
          design_goal: ''
        }],
        competitors: '',
        prioritisation: {
          moscow: '0',
          wsjf: '0',
          ark: '0'
        },
        business_details: {
          scarcity: '',
          offered_by: [{
            name: '',
            quality: ''
          }],
          revenue: {
            method: 'type',
            monetary_potential: ''
          }
        }
      },
      story_form: {
        name: '',
        description: '',
        enabler: false,
        value: 0,
        priority: 0,
        is_stratergic: false,
        status: 'Operational',
        first_added: '',
        improved_in: '',
        prime_user: {
          name: '',
          design_goal: ''
        },
        other_users: [],
        created_by: '',
        assigned_to: '',
        steps: [{
          name: ''
        }]
      }
    };
  },
  computed: {
    filteredFeatures: function filteredFeatures() {
      var features = '';
      features = this.features.filter(this.searchFeatureFilter);
      features = features.filter(this.featureFilter);
      features = features.filter(this.enablerFilter);
      features = features.filter(this.orphanFilter);
      return features;
    }
  },
  mounted: function mounted() {
    this.selectedFeature = this.features[0];
  },
  methods: {
    getPriorityIcon: function getPriorityIcon(priority) {
      return 'priority-' + priority;
    },
    getValueIcon: function getValueIcon(value) {
      return 'dollar-' + value;
    },
    selectFeature: function selectFeature(data) {
      this.selectedFeature = data;
      this.$emit('clicked', data);
    },
    searchFeatureFilter: function searchFeatureFilter(data) {
      return !this.features_search || data.name.toLowerCase().includes(this.features_search.toLowerCase());
    },
    featureFilter: function featureFilter(data) {
      if (this.checked_features) {
        return data.enabler === false;
      } else {
        return true;
      }
    },
    enablerFilter: function enablerFilter(data) {
      if (this.checked_enablers) {
        return data.enabler === true;
      } else {
        return true;
      }
    },
    orphanFilter: function orphanFilter(data) {
      if (this.checked_orphans) {
        return data.has_parent === false;
      } else {
        return true;
      }
    },
    openAddFeatureModal: function openAddFeatureModal(type) {
      this.activeAddFormStep = 0;
      this.feature_form.enabler = type;
      this.addFeatureDialog = true;
    },
    openAddStoryModal: function openAddStoryModal() {
      this.addStoryDialog = true;
    },
    nextAddFeatureStepButton: function nextAddFeatureStepButton() {
      if (this.activeAddFormStep++ > 4) this.activeAddFormStep = 0;
    },
    addAdditionalUser: function addAdditionalUser() {
      this.feature_form.additional_users.push({
        name: '',
        design_goal: ''
      });
    },
    addAdditionalOfferedBy: function addAdditionalOfferedBy() {
      this.feature_form.business_details.offered_by.push({
        name: '',
        quality: ''
      });
    },
    addFeature: function addFeature() {
      var feature = Object.assign({}, this.feature_form);
      feature.key = this.features.length;
      this.features.push(feature);
      this.addFeatureDialog = false;
    },
    addFormStoryStep: function addFormStoryStep() {
      this.story_form.steps.push({
        name: ''
      });
    },
    removeStoryStep: function removeStoryStep(index) {
      this.selectedStory.steps.splice(index, 1);
    },
    addStory: function addStory() {
      this.selectedFeature.stories.push(this.story_form);
      this.addStoryDialog = false;
    }
  }
};