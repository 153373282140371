"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _offering = require("@/api/offering");
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var state = {
  offering_id: '',
  offering_sidebar: {
    opened: _jsCookie.default.get('offeringSidebarStatus') ? !!+_jsCookie.default.get('offeringSidebarStatus') : true,
    withoutAnimation: false
  },
  offering: {
    id: '',
    title: '',
    description: '',
    offeringtype: '',
    offeringcategory: '',
    offeringcategorydesc: '',
    additionalattributes: {},
    linkedProducts: [],
    linkedServices: []
  },
  journey_map: {
    id: '',
    title: '',
    description: '',
    additionalattributes: {},
    linkedBusinessIdeas: [],
    linkedJourneyExperiences: [],
    linkedJourneyPhases: [],
    linkedJourneySteps: []
  },
  mockups: ''
};
var mutations = {
  UPDATE_OFFERING_ID: function UPDATE_OFFERING_ID(state, offering_id) {
    state.offering_id = offering_id;
  },
  UPDATE_OFFERING: function UPDATE_OFFERING(state, offering) {
    state.offering = offering;
  },
  RESET_OFFERING_ID: function RESET_OFFERING_ID(state) {
    state.offering_id = '';
  },
  UPDATE_JOURNEY_MAP: function UPDATE_JOURNEY_MAP(state, journeyMap) {
    state.journey_map = journeyMap;
  },
  TOGGLE_OFFERING_SIDEBAR: function TOGGLE_OFFERING_SIDEBAR(state) {
    state.offering_sidebar.opened = !state.offering_sidebar.opened;
    state.offering_sidebar.withoutAnimation = false;
    if (state.offering_sidebar.opened) {
      _jsCookie.default.set('offeringSidebarStatus', 1, {
        sameSite: 'None',
        secure: true
      });
    } else {
      _jsCookie.default.set('offeringSidebarStatus', 0, {
        sameSite: 'None',
        secure: true
      });
    }
  },
  CLOSE_OFFERING_SIDEBAR: function CLOSE_OFFERING_SIDEBAR(state, withoutAnimation) {
    _jsCookie.default.set('offeringSidebarStatus', 0);
    state.offering_sidebar.opened = false;
    state.offering_sidebar.withoutAnimation = withoutAnimation;
  },
  SET_MOCKUPS: function SET_MOCKUPS(state, mockups) {
    state.mockups = mockups;
  }
};
var actions = {
  updateOfferingId: function updateOfferingId(_ref, offering_id) {
    var commit = _ref.commit;
    commit('UPDATE_OFFERING_ID', offering_id);
  },
  resetOfferingId: function resetOfferingId(_ref2) {
    var commit = _ref2.commit;
    commit('RESET_OFFERING_ID');
  },
  updateOffering: function updateOffering(_ref3, offering) {
    var commit = _ref3.commit;
    commit('UPDATE_OFFERING', offering);
  },
  resetOffering: function resetOffering(_ref4) {
    var commit = _ref4.commit;
    commit('UPDATE_OFFERING', {});
  },
  updateJourneyMap: function updateJourneyMap(_ref5, journeyMap) {
    var commit = _ref5.commit;
    commit('UPDATE_JOURNEY_MAP', journeyMap);
  },
  toggleSideBar: function toggleSideBar(_ref6) {
    var commit = _ref6.commit;
    commit('TOGGLE_OFFERING_SIDEBAR');
  },
  closeSideBar: function closeSideBar(_ref7, _ref8) {
    var commit = _ref7.commit;
    var withoutAnimation = _ref8.withoutAnimation;
    commit('CLOSE_OFFERING_SIDEBAR', withoutAnimation);
  },
  getMockups: function getMockups(_ref9) {
    var commit = _ref9.commit,
      state = _ref9.state;
    return new Promise(function (resolve, reject) {
      (0, _offering.getMockups)().then(function (response) {
        if (!response.data) {
          reject('Verification failed, please login again.');
        }
        var data = response.data;
        if (data) {
          commit('SET_MOCKUPS', data);
        } else {
          reject('getMockups: roles must be a non-null array!');
        }
        resolve(response);
      }).catch(function (error) {
        reject(error);
      });
    });
  }
};
var _default = exports.default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};