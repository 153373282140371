"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _default2 = exports.default = {
  name: 'DefineSingleUserDetails',
  components: {},
  props: {
    groups: {
      type: Array,
      default: function _default() {
        return [{
          name: 'Individual',
          description: 'Lorem Ipsum',
          customers: [],
          all_features_selected: false,
          is_visible: true
        }, {
          name: 'Family',
          description: 'Lorem Ipsum',
          customers: [],
          all_features_selected: false,
          is_visible: true
        }];
      }
    }
  },
  data: function data() {
    return {
      selectedGroup: {},
      checked: false,
      multi_user: false,
      is_edit: false,
      tab_active: 'type',
      feature_search: '',
      user_type_priority_list: [{
        upriority: 'Highest'
      }, {
        upriority: 'High'
      }, {
        upriority: 'Medium'
      }, {
        upriority: 'Low'
      }, {
        upriority: 'Lowest'
      }],
      selected_user_type: {},
      activeUserType: {},
      feature: [0],
      features: [{
        key: 0,
        label: 'Feature 1',
        users: [],
        all_users_selected: false,
        is_visible: true
      }, {
        key: 1,
        label: 'Feature 2',
        users: [],
        all_users_selected: false,
        is_visible: true
      }, {
        key: 2,
        label: 'Feature 3',
        users: [],
        all_users_selected: false,
        is_visible: true
      }, {
        key: 3,
        label: 'Feature 4',
        users: [],
        all_users_selected: false,
        is_visible: true
      }, {
        key: 4,
        label: 'Feature 5',
        users: [],
        all_users_selected: false,
        is_visible: true
      }, {
        key: 5,
        label: 'Feature 6',
        users: [],
        all_users_selected: false,
        is_visible: true
      }, {
        key: 6,
        label: 'Feature 7',
        users: [],
        all_users_selected: false,
        is_visible: true
      }],
      defaultProps: {
        children: 'customers',
        label: 'name'
      }
    };
  },
  computed: {
    filteredFeatures: function filteredFeatures() {
      var self = this;
      return this.features.filter(function (feature) {
        return feature.label.indexOf(self.feature_search) > -1;
      });
    }
  },
  watch: {
    multi_user: function multi_user(val) {
      if (!this.multi_user) {
        this.tab_active = 'type';
      }
    },
    features: {
      deep: true,
      handler: function handler() {
        // Check if feature has all users selected
        for (var i = 0; i < this.features.length; i++) {
          if (this.features[i].users.length === this.groups.length) {
            this.features[i].all_users_selected = true;
          } else {
            this.features[i].all_users_selected = false;
          }
        }

        // Check if an user has all feature selected
        for (var j = 0; j < this.groups.length; j++) {
          var all_features_selected = true;
          for (var k = 0; k < this.features.length; k++) {
            if (!this.features[k].users.includes(this.groups[j].name)) {
              all_features_selected = false;
            }
          }
          this.groups[j].all_features_selected = all_features_selected;
        }
      }
    }
  },
  mounted: function mounted() {
    this.$eventHub.$emit('process-step-load', 'define-user-details');
    this.selectedGroup = this.groups[0];
  },
  methods: {
    selectAllFeatures: function selectAllFeatures() {
      for (var i = 0; i < this.features.length; i++) {
        this.features[i].is_visible = true;
      }
    },
    setActiveUserType: function setActiveUserType(user_type) {
      this.activeUserType = user_type;
    },
    getPriorityIcon: function getPriorityIcon(priority) {
      return 'priority-white-' + priority;
    },
    goToCustomerLink: function goToCustomerLink() {
      this.$emit('go-next', this.groups);
    },
    goToGroupSimilarCustomer: function goToGroupSimilarCustomer() {
      this.$emit('go-previous', this.groups);
    },
    filterMethod: function filterMethod(query, item) {
      return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
    },
    generateUserMenuIndex: function generateUserMenuIndex(index) {
      return '1-' + index;
    },
    generateFeatureMenuIndex: function generateFeatureMenuIndex(index) {
      return '0-' + index;
    },
    save: function save() {
      this.is_edit = false;
    }
  }
};