"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _axios = _interopRequireDefault(require("axios"));
var _resolution = require("@/utils/resolution");
var _Actions = _interopRequireDefault(require("./components/Actions"));
var _default = exports.default = {
  name: 'ProfileTask',
  components: {
    ActionComponent: _Actions.default
  },
  data: function data() {
    return {
      loading: false,
      navExpanded: true,
      navigatorSize: 90,
      workItemSize: 10,
      data_scope: '',
      workItemSizeActiveMenuItem: 'My Actions',
      workItemSearch: '',
      selectedNav: {},
      columns_actions: {
        id: true,
        artefact: true,
        name: true,
        status: true,
        priority: true,
        due: true,
        created: true,
        assigned: true
      },
      actions: [],
      users: []
    };
  },
  computed: {
    computeDashboardHeight: function computeDashboardHeight() {
      var height = 860 * (0, _resolution.screenRatio)();
      return 'height:' + height + 'px;';
    }
  },
  mounted: function mounted() {
    this.retrieveUsers();
    this.retrieveActions(1);
  },
  created: function created() {},
  methods: {
    retrieveUsers: function retrieveUsers() {
      var _this = this;
      this.loading = true;
      var headers = {
        'Content-Type': 'application/json',
        'x-party-id': this.$store.getters.partyId,
        'x-user-id': this.$store.getters.id
      };
      _axios.default.get(process.env.VUE_APP_REST_API_ENDPOINT + 'user/?extended=LEVEL0&deleted=false', {
        headers: headers
      }).then(function (response) {
        _this.loading = false;
        _this.users = response.data.data;
      }).catch(function (error) {
        if (error.response.status === 400) {
          _this.loading = false;
          _this.$notify({
            title: 'Error',
            message: error.response.message,
            type: 'error',
            duration: 2000
          });
        }
      });
    },
    handleWorkItemMenuChange: function handleWorkItemMenuChange(menu_item) {
      this.workItemSizeActiveMenuItem = menu_item;
      switch (menu_item) {
        case 'My Actions':
          this.retrieveActions(1);
          break;
        case 'My Discussions':
          break;
      }
    },
    retrieveActions: function retrieveActions(page) {
      var _this2 = this;
      this.loading = true;
      var headers = {
        'Content-Type': 'application/json',
        'x-party-id': this.$store.getters.partyId,
        'x-user-id': this.$store.getters.id
      };
      _axios.default.get(process.env.VUE_APP_REST_API_ENDPOINT + 'action?extended=LEVEL0&createdBy=' + this.$store.getters.id, {
        headers: headers
      }).then(function (response) {
        _this2.loading = false;
        // console.log(response.data.data)
        _this2.actions = response.data.data;
      }).catch(function (error) {
        _this2.loading = false;
        if (error.response.status === 400) {
          _this2.actions = [];
        } else {
          _this2.$notify({
            title: 'Error',
            message: error.response.message,
            type: 'error',
            duration: 2000
          });
        }
      });
    },
    removeFilter: function removeFilter() {
      this.selectedNav = {};
    }
  }
};