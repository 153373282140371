"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-tabs", {
    attrs: {
      type: ""
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "Overview"
    }
  }, [_c("table", {
    staticClass: "mt-5",
    attrs: {
      width: "90%"
    }
  }, [_c("colgroup", [_c("col", {
    staticStyle: {
      width: "7%"
    },
    attrs: {
      span: "1"
    }
  }), _vm._v(" "), _c("col", {
    staticStyle: {
      width: "40%"
    },
    attrs: {
      span: "1"
    }
  }), _vm._v(" "), _c("col", {
    staticStyle: {
      width: "7%"
    },
    attrs: {
      span: "1"
    }
  }), _vm._v(" "), _c("col", {
    staticStyle: {
      width: "40%"
    },
    attrs: {
      span: "1"
    }
  })]), _vm._v(" "), _c("tr", {
    staticStyle: {
      "text-align": "left"
    }
  }, [_c("th", [_vm._v("Name")]), _vm._v(" "), _c("td", {
    staticStyle: {
      "padding-right": "10%"
    }
  }, [_c("el-input", {
    attrs: {
      type: "text",
      size: "mini"
    },
    model: {
      value: _vm.offering.title,
      callback: function callback($$v) {
        _vm.$set(_vm.offering, "title", $$v);
      },
      expression: "offering.title"
    }
  })], 1), _vm._v(" "), _c("th", [_vm._v("Customer Type")]), _vm._v(" "), _c("td", [_c("el-input", {
    attrs: {
      type: "text",
      size: "mini",
      disabled: ""
    },
    model: {
      value: _vm.computeCustomerType,
      callback: function callback($$v) {
        _vm.computeCustomerType = $$v;
      },
      expression: "computeCustomerType"
    }
  })], 1)]), _vm._v(" "), _c("tr", {
    staticStyle: {
      "text-align": "left"
    }
  }, [_c("th", [_vm._v("Offering Type")]), _vm._v(" "), _c("td", {
    staticStyle: {
      "padding-right": "10%"
    }
  }, [_c("el-tag", {
    attrs: {
      size: "mini",
      type: "primary"
    }
  }, [_vm._v(_vm._s(_vm.offering.offeringtype))])], 1), _vm._v(" "), _c("th", [_vm._v("Offering Description")]), _vm._v(" "), _c("td", [_c("el-input", {
    attrs: {
      type: "textarea",
      size: "mini"
    },
    model: {
      value: _vm.offering.description,
      callback: function callback($$v) {
        _vm.$set(_vm.offering, "description", $$v);
      },
      expression: "offering.description"
    }
  })], 1)]), _vm._v(" "), _vm.offering.iscloned ? _c("tr", {
    staticStyle: {
      "text-align": "left"
    }
  }, [_c("th", [_vm._v("Cloned From")]), _vm._v(" "), _c("td", [_c("el-tag", {
    attrs: {
      size: "mini",
      type: "primary"
    }
  }, [_vm._v(_vm._s(_vm.getOfferingTitleFromID(_vm.offering.clonedobjectid)))])], 1)]) : _vm._e()]), _vm._v(" "), _c("div", {
    staticClass: "fr mt-20",
    staticStyle: {
      "margin-bottom": "10px",
      "margin-right": "10%"
    }
  }, [_c("el-button-group", {
    attrs: {
      size: "mini"
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      disabled: _vm.loading,
      icon: _vm.loading ? "el-icon-loading" : "",
      size: "mini"
    },
    on: {
      click: _vm.updateOffering
    }
  }, [_vm._v("Update")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "danger",
      disabled: _vm.loading,
      size: "mini",
      icon: _vm.loading ? "el-icon-loading" : "el-icon-delete"
    },
    on: {
      click: _vm.openDeleteOfferingConfirmation
    }
  }, [_vm._v("Delete")])], 1)], 1)]), _vm._v(" "), _vm.offering.linkedCustomerTypes.length > 0 ? _c("el-tab-pane", {
    attrs: {
      label: "Offering Variants"
    }
  }, [_c("variant-component", {
    attrs: {
      offering: _vm.offering
    }
  })], 1) : _vm._e()], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Warning",
      visible: _vm.deleteOfferingConfirmationDialog,
      width: "40%",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.deleteOfferingConfirmationDialog = $event;
      }
    }
  }, [_c("el-card", [_c("div", [_c("strong", [_vm._v("The following will be deleted: ")]), _vm._v(" "), _c("i", {
    staticStyle: {
      color: "darkred"
    }
  })]), _vm._v(" "), _c("ul", {
    staticStyle: {
      "margin-top": "6pt"
    }
  }, [_c("li", [_vm._v("Offering description, details")]), _vm._v(" "), _c("li", [_vm._v("Offering products, features")]), _vm._v(" "), _c("li", [_vm._v("Offering services, processes")])]), _vm._v(" "), _c("div", {
    staticStyle: {
      "font-size": "0.9em"
    }
  }, [_vm._v("Customer journey map will not be deleted.")])]), _vm._v(" "), _c("div", {
    staticClass: "mt-10"
  }, [_vm._v("Type "), _c("span", {
    staticStyle: {
      color: "blue"
    }
  }, [_vm._v("'" + _vm._s(_vm.offeringForm.titleOriginal) + "'")]), _vm._v(" below to confirm deletion.")]), _vm._v(" "), _c("el-form", {
    attrs: {
      "label-position": "top",
      "label-width": "100px",
      model: _vm.offeringForm
    },
    nativeOn: {
      submit: function submit($event) {
        $event.preventDefault();
        return _vm.deleteOffering.apply(null, arguments);
      }
    }
  }, [_c("el-form-item", {
    attrs: {
      label: ""
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.offeringForm.title,
      callback: function callback($$v) {
        _vm.$set(_vm.offeringForm, "title", $$v);
      },
      expression: "offeringForm.title"
    }
  })], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "secondary"
    },
    on: {
      click: _vm.cancelOfferingDelete
    }
  }, [_vm._v("Cancel")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini",
      type: "danger",
      disabled: _vm.offeringForm.title !== _vm.offeringForm.titleOriginal || _vm.loading,
      icon: _vm.loading ? "el-icon-loading" : ""
    },
    on: {
      click: _vm.deleteOffering
    }
  }, [_vm._v("Delete")])], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;