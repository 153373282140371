"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/taggedTemplateLiteral.js"));
require("core-js/modules/es6.number.constructor");
var _templateObject;
var _default2 = exports.default = {
  name: 'Thoughts',
  components: {},
  props: {
    x: {
      type: Number,
      default: 0
    },
    y: {
      type: Number,
      default: 0
    },
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
    phaseX: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    phaseLength: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    stages: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    isCompactMenubar: {
      type: Boolean,
      default: true
    },
    journeyMap: {
      type: Object,
      required: true,
      default: function _default() {
        return {
          id: '',
          title: '',
          description: '',
          additionalattributes: {},
          linkedJourneyExperiences: [],
          linkedJourneyPhases: [],
          linkedJourneySteps: []
        };
      }
    }
  },
  data: function data() {
    return {
      isHover: false,
      loading: false,
      selectedThoughtIndex: null,
      label: 'Thoughts & Feelings',
      thoughts: []
    };
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    computeTextAreaStyle: function computeTextAreaStyle(index) {
      return 'width:' + (this.phaseLength[index] - 5) + 'px;height:135px;border: none;background: rgb(255 255 231);overflow: hidden;border-radius: 2px 2px 2px 2px;padding: 10px 10px;font-size:10px;box-shadow: 2px 2px 2px rgba(33,33,33,.5);';
    },
    computeStyle: function computeStyle() {
      if (this.isCompactMenubar) {
        return 'fill:grey; font-weight: 200 ;font-variant-caps: small-caps';
      }
      return 'fill:white; font-weight: 200 ;font-variant-caps: small-caps';
    },
    computeTransform: function computeTransform() {
      if (!this.isCompactMenubar) return '';
      var y = this.y + 130;
      var x = this.x + 15;
      return 'rotate(270, ' + x + ', ' + y + ')';
    },
    updateThought: function updateThought(index) {
      var _this = this;
      this.loading = true;
      this.selectedThoughtIndex = index;
      var additionalattributes = this.stages[index].journeyPhase.additionalattributes;
      // this.$store.dispatch('customerTypes/updateJourneyMapPhaseAdditionalAttributes', index, additionalattributes)
      this.$apollo.mutate({
        // Query
        mutation: window._gql(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["mutation updateJourneyPhase($journeyPhase: UpdateJourneyPhaseInput!)\n        {\n          updateJourneyPhase(input: $journeyPhase) {\n            id\n          }\n        }"]))),
        // Parameters
        variables: {
          'journeyPhase': {
            'id': this.stages[index].journeyPhase.id,
            'additionalattributes': additionalattributes
          }
        }
      }).then(function (data) {
        _this.loading = false;
        _this.selectedThoughtIndex = null;
        for (var i = 0; i < _this.journeyMap.linkedJourneyPhases.length; i++) {
          if (_this.journeyMap.linkedJourneyPhases[i].journeyPhase.id === _this.stages[index].journeyPhase.id) {
            _this.journeyMap.linkedJourneyPhases[i].journeyPhase = _this.stages[index].journeyPhase;
            // Add Update flag
            _this.journeyMap.linkedJourneyPhases[i].journeyPhase.isUpdated = true;
          }
        }
        _this.$store.dispatch('customerTypes/updateJourneyMap', _this.journeyMap);
      }).catch(function (error) {
        // Error
        console.log(error);
        _this.loading = false;
        _this.selectedThoughtIndex = null;
      });
    },
    displayLabelPrompt: function displayLabelPrompt() {
      var _this2 = this;
      this.$prompt('Please update title', 'Update lane title', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        inputValue: this.label
      }).then(function (_ref) {
        var value = _ref.value;
        _this2.label = value;
      }).catch(function (error) {
        console.log(error);
      });
    }
  }
};