"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("g", {
    attrs: {
      refs: "objective_lane"
    },
    on: {
      mouseover: function mouseover($event) {
        _vm.isHover = true;
      },
      mouseleave: function mouseleave($event) {
        _vm.isHover = false;
      }
    }
  }, [_c("g", {
    attrs: {
      refs: "objective_details"
    }
  }, [_c("rect", {
    class: _vm.calcInitClass(_vm.objective.status),
    attrs: {
      x: _vm.x + 2,
      y: _vm.y,
      width: _vm.width - 4,
      height: _vm.isMinimized ? _vm.height : _vm.height,
      rx: "4",
      "stroke-width": "0.5",
      stroke: _vm.objective.__typename === "ProductFeature" ? "#0D63BF" : "#4CA97E",
      fill: _vm.isHover ? _vm.objective.__typename === "ProductFeature" ? "#0D63BF" : "#4CA97E" : "whitesmoke"
    },
    on: {
      click: function click($event) {
        $event.stopPropagation();
        if ($event.target !== $event.currentTarget) return null;
        return _vm.displayObjectiveDialog();
      }
    }
  }), _vm._v(" "), _c("transition", {
    attrs: {
      name: "slide-fade"
    }
  }, [!_vm.isMinimized && _vm.isHover ? _c("g", {
    attrs: {
      refs: "objective_tools"
    }
  }, [_c("foreignObject", {
    staticStyle: {
      "min-height": "12pt",
      "min-width": "12pt"
    },
    attrs: {
      x: _vm.computeUnlinkIconX,
      y: _vm.y + 1,
      width: 15,
      height: 15,
      "font-size": "0.8em",
      "text-anchor": "middle"
    },
    on: {
      click: function click($event) {
        $event.preventDefault();
        return _vm.unlinkSubEntity();
      }
    }
  }, [_c("i", {
    staticClass: "fa fa-chain-broken left-right",
    style: _vm.computeUnlinkIconStyling,
    attrs: {
      title: "Unlink"
    }
  })]), _vm._v(" "), _c("foreignObject", {
    directives: [{
      name: "dragged",
      rawName: "v-dragged.prevent",
      value: _vm.onObjectiveDragged,
      expression: "onObjectiveDragged",
      modifiers: {
        prevent: true
      }
    }],
    staticStyle: {
      "min-height": "12pt",
      "min-width": "12pt",
      cursor: "grab"
    },
    attrs: {
      id: _vm.computeDragObjectiveID(),
      x: _vm.computeDragIconX,
      y: _vm.y + 1,
      width: 15,
      height: 15,
      "font-size": "0.8em",
      "text-anchor": "middle"
    }
  }, [_c("i", {
    staticClass: "fa fa-hand-rock-o left-right",
    style: _vm.computeDragIconStyling
  })]), _vm._v(" "), !_vm.isMinimized && _vm.objective.status ? _c("foreignObject", {
    staticStyle: {
      "min-height": "12pt",
      "min-width": "12pt"
    },
    attrs: {
      x: _vm.computeStatusIconX + 2,
      y: _vm.y + 1.5,
      width: 15,
      height: 15,
      "font-size": "0.8em",
      "text-anchor": "middle"
    }
  }, [_c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Proposed",
      placement: "top"
    }
  }, [_vm.objective.status === "Proposed" ? _c("svg-icon", {
    staticStyle: {
      "min-height": "9pt",
      "min-width": "9pt",
      "border-radius": "9pt",
      "padding-bottom": "2.5pt",
      "background-color": "white",
      border: "solid 1pt white",
      color: "#0D63BF",
      "font-size": "0.8em"
    },
    attrs: {
      "icon-class": "hand-holding-solid"
    }
  }) : _vm._e()], 1), _vm._v(" "), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Accepted",
      placement: "top"
    }
  }, [_vm.objective.status === "Accepted" ? _c("svg-icon", {
    staticStyle: {
      "min-height": "9pt",
      "min-width": "9pt",
      "border-radius": "9pt",
      "padding-bottom": "2.5pt",
      "background-color": "white",
      border: "solid 1pt white",
      color: "#0D63BF",
      "font-size": "0.8em"
    },
    attrs: {
      "icon-class": "thumbs-up"
    }
  }) : _vm._e()], 1), _vm._v(" "), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "In progress",
      placement: "top"
    }
  }, [_vm.objective.status === "In progress" ? _c("svg-icon", {
    staticStyle: {
      "min-height": "9pt",
      "min-width": "9pt",
      color: "#0D63BF",
      "background-color": "white",
      "border-radius": "10pt",
      "font-size": "0.7em",
      padding: "0pt"
    },
    attrs: {
      "icon-class": "delivery"
    }
  }) : _vm._e()], 1), _vm._v(" "), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "On hold",
      placement: "top"
    }
  }, [_vm.objective.status === "On hold" ? _c("svg-icon", {
    staticStyle: {
      "min-height": "9pt",
      "min-width": "9pt",
      color: "#ffa200",
      "font-size": "0.8em"
    },
    attrs: {
      "icon-class": "pause-circle-fill-w"
    }
  }) : _vm._e()], 1), _vm._v(" "), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Overdue",
      placement: "top"
    }
  }, [_vm.objective.status === "Overdue" ? _c("svg-icon", {
    staticStyle: {
      "min-height": "9pt",
      "min-width": "9pt",
      color: "darkred",
      "font-size": "0.8em"
    },
    attrs: {
      "icon-class": "exclamation-circle-fill"
    }
  }) : _vm._e()], 1), _vm._v(" "), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Achieved",
      placement: "top"
    }
  }, [_vm.objective.status === "Achieved" ? _c("svg-icon", {
    staticStyle: {
      "min-height": "9pt",
      "min-width": "9pt",
      color: "darkgreen",
      "font-size": "0.8em"
    },
    attrs: {
      "icon-class": "check-circle-fill"
    }
  }) : _vm._e()], 1), _vm._v(" "), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Backlog",
      placement: "top"
    }
  }, [_vm.objective.status === "Backlog" ? _c("svg-icon", {
    staticStyle: {
      "min-height": "9pt",
      "min-width": "9pt",
      "border-radius": "9pt",
      "padding-bottom": "2.5pt",
      "background-color": "white",
      border: "solid 1ptwhite",
      color: "#0D63BF",
      "font-size": "0.8em"
    },
    attrs: {
      "icon-class": "hand-holding-solid"
    }
  }) : _vm._e()], 1)], 1) : _vm._e()]) : _vm._e()]), _vm._v(" "), _c("text", {
    staticStyle: {
      cursor: "grab"
    },
    attrs: {
      x: _vm.x + 5,
      y: _vm.isMinimized ? _vm.y + 6 : _vm.y + 11,
      "font-size": _vm.isMinimized ? "0.5em" : "0.8em",
      "text-anchor": "start",
      "font-weight": "light",
      fill: _vm.computeTitleFill
    }
  }, [_vm._v("\n        " + _vm._s(_vm.truncateString(_vm.objective.title)) + "\n      ")]), _vm._v(" "), _c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.objective.childTickets && _vm.objective.childTickets.length > 0 && !_vm.isMinimized ? _c("g", {
    attrs: {
      refs: "child_tickets"
    }
  }, [_c("rect", {
    attrs: {
      x: _vm.isHover ? _vm.x + _vm.width - 65 : _vm.x + _vm.width - 25,
      y: _vm.y + 1.5,
      width: 20,
      height: 12,
      rx: "4",
      fill: "#FFF"
    }
  }), _vm._v(" "), _c("text", {
    attrs: {
      x: _vm.isHover ? _vm.x + _vm.width - 48 : _vm.x + _vm.width - 8,
      y: _vm.y + 10,
      "font-family": "Verdana",
      fill: _vm.objective.__typename === "ProductFeature" ? "#0D63BF" : "#4CA97E",
      "font-size": "0.5em",
      "text-anchor": "end",
      "font-weight": "normal"
    }
  }, [_vm._v("\n\n            " + _vm._s(_vm.objective.childTickets.length) + " / " + _vm._s(_vm.objective.childTickets.length) + "\n          ")])]) : _vm._e()]), _vm._v(" "), !_vm.showInitiatives && _vm.objective.type ? _c("g", {
    attrs: {
      refs: "initiatives_details_toggled"
    }
  }, [!_vm.isMinimized ? _c("foreignObject", {
    staticStyle: {
      "font-size": "0.8em"
    },
    attrs: {
      x: _vm.x + 5,
      y: _vm.y + 25,
      width: _vm.dynamicLabelLength(_vm.objective.type),
      height: 30
    }
  }, [_c("div", {
    staticStyle: {
      "background-color": "#27B97C",
      color: "white",
      "border-radius": "4pt",
      padding: "3pt 5pt 3pt 5pt",
      height: "13pt"
    },
    on: {
      click: function click($event) {
        return _vm.displayObjectiveDialog();
      }
    }
  }, [_vm._v(_vm._s(_vm.objective.type) + " ")])]) : _vm._e(), _vm._v(" "), !_vm.isMinimized ? _c("foreignObject", {
    attrs: {
      x: _vm.computeProgressX + 20,
      y: _vm.y + 30,
      width: 55,
      height: _vm.height
    },
    on: {
      click: function click($event) {
        return _vm.displayObjectiveDialog();
      }
    }
  }, [_c("div", {
    staticStyle: {
      width: "30pt",
      "margin-left": "10pt"
    }
  }, [_vm.objective.type === "Customer Facing" ? _c("el-progress", {
    attrs: {
      "show-text": false,
      percentage: 50
    }
  }) : _vm._e(), _vm._v(" "), _vm.objective.type === "Employee Facing" ? _c("el-progress", {
    attrs: {
      "show-text": false,
      percentage: 50
    }
  }) : _vm._e(), _vm._v(" "), _vm.objective.type === "Partner Facing" ? _c("el-progress", {
    attrs: {
      "show-text": false,
      percentage: 50
    }
  }) : _vm._e(), _vm._v(" "), _vm.objective.type === "Operational" ? _c("el-progress", {
    attrs: {
      "show-text": false,
      percentage: 50
    }
  }) : _vm._e(), _vm._v(" "), _vm.objective.type === "Enabler" ? _c("el-progress", {
    attrs: {
      "show-text": false,
      percentage: 50
    }
  }) : _vm._e()], 1), _vm._v(" "), _vm.objective.type === "Other" ? _c("span", {
    staticStyle: {
      "font-size": "7pt",
      overflow: "visible"
    }
  }, [_c("i", [_vm._v("No Measures")])]) : _vm._e()]) : _vm._e()]) : _vm._e(), _vm._v(" "), _c("g", {
    attrs: {
      refs: "initiatives"
    }
  }, [_vm._t("default")], 2)], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;