"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _default2 = exports.default = {
  name: 'Phase',
  components: {},
  props: {
    phase: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    phaseParam: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    phaseHeight: {
      type: Number,
      default: 0
    },
    index: {
      type: Number,
      default: 0
    },
    noteCount: {
      type: Number,
      default: 0
    },
    phaseProgress: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      loading: false
    };
  },
  computed: {},
  created: function created() {},
  methods: {
    computeTrimmedText: function computeTrimmedText(text) {
      if (text.length > 15) {
        return text.substring(0, 15) + ' ...';
      } else {
        return text;
      }
    },
    openNotesList: function openNotesList() {
      this.$emit('open-notes-list', 'phase', this.index);
    },
    editPhase: function editPhase() {
      this.$emit('edit-phase', this.index);
    },
    openPhaseProgressDetail: function openPhaseProgressDetail() {
      this.$emit('open-phase-progress-detail', this.index);
    },
    deletePhase: function deletePhase(index) {
      this.$emit('delete-phase', index);
    }
  }
};