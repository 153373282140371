"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/taggedTemplateLiteral.js"));
var _vueSwatches = _interopRequireDefault(require("vue-swatches"));
var _color = require("@/utils/color");
var _templateObject, _templateObject2;
var _default2 = exports.default = {
  name: 'AddRelease',
  components: {
    VSwatches: _vueSwatches.default
  },
  props: {
    roadmap: {
      type: Object,
      default: function _default() {
        return {
          title: '',
          description: '',
          style: {
            color: ''
          }
        };
      }
    },
    offeringRoadmapId: {
      type: String,
      required: true,
      default: ''
    },
    settings: {
      type: Object,
      required: true,
      default: function _default() {
        return {
          version: 1,
          roadmap: {},
          release: {
            timeline: 6,
            color: {
              title: 'rgba(51, 152, 219, 1.0)',
              column: 'rgba(51, 152, 219, 1.0)'
            }
          },
          increment: {
            prefix: 'increment',
            timeline: 1,
            delete: 'disabled',
            color: {
              title: 'rgba(189, 195, 200, 1.0)',
              column: 'rgba(189, 195, 200, 1.0)'
            }
          }
        };
      }
    }
  },
  data: function data() {
    var validateTitle = function validateTitle(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter title'));
      } else if (value.length > 50) {
        callback(new Error('Title should be less than 50 characters'));
      } else {
        callback();
      }
    };
    var validateDescription = function validateDescription(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter description'));
      } else {
        callback();
      }
    };
    var validateStartDate = function validateStartDate(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter date'));
      } else {
        callback();
      }
    };
    var validateEndDate = function validateEndDate(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter date'));
      } else {
        callback();
      }
    };
    return {
      releaseRules: {
        title: [{
          required: true,
          trigger: 'blur',
          validator: validateTitle
        }],
        description: [{
          required: true,
          trigger: 'blur',
          validator: validateDescription
        }],
        startdate: [{
          required: true,
          trigger: 'blur',
          validator: validateStartDate
        }],
        duedate: [{
          required: true,
          trigger: 'blur',
          validator: validateEndDate
        }]
      },
      loading: false,
      release_form: {
        title: '',
        description: '',
        startdate: '',
        duedate: '',
        style: {
          color: '#1fbc9c'
        }
      }
    };
  },
  computed: {},
  watch: {
    refresh: function refresh(val) {
      this.$refs['addReleaseForm'].resetFields();
    }
  },
  created: function created() {},
  mounted: function mounted() {
    this.$refs['addReleaseForm'].resetFields();
    this.release_form.startdate = this.$moment().startOf('month').format('YYYY-MM-DD HH:mm:ss');
    this.release_form.style.color = (0, _color.rgba2hex)(this.settings.release.color.title);
    this.updateDueDate();
  },
  methods: {
    updateDueDate: function updateDueDate(value) {
      this.release_form.duedate = this.$moment(this.release_form.startdate).add(this.settings.release.timeline, 'M');
    },
    closeDialog: function closeDialog() {
      this.$emit('close');
    },
    validateStartDate: function validateStartDate() {
      var validation = false;
      for (var i = 0; i < this.roadmap.linkedReleases.length; i++) {
        var startDate = this.$moment(this.roadmap.linkedReleases[i].offeringRoadmapRelease.startdate, 'YYYY-MM-DD HH:mm:ss');
        var endDate = this.$moment(this.roadmap.linkedReleases[i].offeringRoadmapRelease.duedate, 'YYYY-MM-DD HH:mm:ss');
        var valueStartDate = this.$moment(this.release_form.startdate, 'YYYY-MM-DD HH:mm:ss');
        validation = valueStartDate.isBetween(startDate, endDate, 'days', '[]');
        if (validation) {
          break;
        }
      }
      return validation;
    },
    validateEndDate: function validateEndDate() {
      var validation = false;
      for (var i = 0; i < this.roadmap.linkedReleases.length; i++) {
        var startDate = this.$moment(this.roadmap.linkedReleases[i].offeringRoadmapRelease.startdate, 'YYYY-MM-DD HH:mm:ss');
        var endDate = this.$moment(this.roadmap.linkedReleases[i].offeringRoadmapRelease.duedate, 'YYYY-MM-DD HH:mm:ss');
        var valueEndDate = this.$moment(this.release_form.duedate, 'YYYY-MM-DD HH:mm:ss');
        validation = valueEndDate.isBetween(startDate, endDate, 'days', '[]');
        if (validation) {
          break;
        }
      }
      return validation;
    },
    create: function create() {
      var _this = this;
      this.$refs['addReleaseForm'].validate(function (valid) {
        if (valid && !_this.validateStartDate() && !_this.validateEndDate()) {
          _this.loading = true;
          _this.$apollo.mutate({
            // Query
            mutation: window._gql(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["mutation createOfferingRoadmapRelease($release: CreateOfferingRoadmapReleaseInput!)\n            {\n              createOfferingRoadmapRelease(input: $release) {\n                id\n                title\n                description\n                additionalattributes\n                approvers\n                status\n                priority\n                startdate\n                duedate\n                createdby\n                createdat\n                modifiedby\n                modifiedat\n              }\n            }"]))),
            // Parameters
            variables: {
              'release': {
                'offeringRoadmapId': _this.offeringRoadmapId,
                'title': _this.release_form.title,
                'description': _this.release_form.description,
                'status': _this.release_form.status,
                'priority': _this.release_form.priority,
                'startdate': _this.$moment(_this.release_form.startdate, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
                'duedate': _this.$moment(_this.release_form.duedate, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
                'additionalattributes': {
                  'style': {
                    'color': _this.release_form.style.color
                  }
                }
              }
            }
          }).then(function (data) {
            _this.createIncrements(data.data.createOfferingRoadmapRelease);
            // this.$emit('create', data.data.createOfferingRoadmapRelease)
            _this.resetForm();
            _this.loading = false;
          }).catch(function (error) {
            // Error
            _this.$notify({
              title: 'Error',
              message: error,
              type: 'error',
              duration: 2000
            });
            _this.loading = false;
          });
        } else {
          if (_this.validateStartDate() || _this.validateEndDate()) {
            _this.$notify({
              title: 'Date validation error',
              message: 'Dates overlap with existing dates.',
              type: 'error',
              duration: 2000
            });
          }
          // this.loading = false
          return false;
        }
      });
    },
    createIncrements: function createIncrements(release) {
      var increment_count = this.settings.release.timeline / this.settings.increment.timeline;
      for (var i = 0; i < increment_count; i++) {
        var increment_start_date = this.$moment(release.startdate, 'YYYY-MM-DD HH:mm:ss').add(i, 'M');
        var increment_end_date = this.$moment(increment_start_date, 'YYYY-MM-DD HH:mm:ss').add(this.settings.increment.timeline, 'M');
        var increment_object = {};
        increment_object.title = this.settings.increment.prefix + '-' + i;
        increment_object.description = this.settings.increment.prefix + '-' + i;
        increment_object.status = this.release_form.status;
        increment_object.priority = this.release_form.priority;
        increment_object.startdate = this.$moment(increment_start_date, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
        increment_object.duedate = this.$moment(increment_end_date, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
        increment_object.style = {
          'color': (0, _color.rgba2hex)(this.settings.increment.color.title)
        };
        this.createIncrement(release, increment_object, i);
      }
    },
    createIncrement: function createIncrement(release, increment, count) {
      var _this2 = this;
      this.loading = true;
      this.$apollo.mutate({
        // Query
        mutation: window._gql(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["mutation createOfferingRdmRlIncrement($increment: CreateOfferingRdmRlIncrementInput!)\n        {\n          createOfferingRdmRlIncrement(input: $increment) {\n            id\n            title\n            description\n            additionalattributes\n            approvers\n            status\n            priority\n            startdate\n            duedate\n            createdby\n            createdat\n            modifiedby\n            modifiedat\n          }\n        }"]))),
        // Parameters
        variables: {
          'increment': {
            'offeringReleaseId': release.id,
            'title': increment.title,
            'description': increment.description,
            'status': increment.status,
            'priority': increment.priority,
            'startdate': this.$moment(increment.startdate, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
            'duedate': this.$moment(increment.duedate, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
            'additionalattributes': {
              'style': {
                'color': increment.style.color
              },
              'duration': 1
            }
          }
        }
      }).then(function (data) {
        _this2.loading = false;
        if (_this2.settings.release.timeline / _this2.settings.increment.timeline - 1 === count) {
          _this2.$emit('create', release);
        }
      }).catch(function (error) {
        // Error
        _this2.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this2.loading = false;
      });
    },
    truncateString: function truncateString(string, length) {
      return this._.truncate(string, {
        'length': length,
        'separator': '...'
      });
    },
    resetForm: function resetForm() {
      this.release_form = {
        title: '',
        description: '',
        startdate: '',
        duedate: '',
        style: {
          color: '#ffbf00'
        }
      };
    }
  }
};