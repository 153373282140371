"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-form", {
    ref: "addTouchpointform",
    attrs: {
      rules: _vm.addTouchpointrules,
      model: _vm.touchpoint_form,
      "label-width": "120px",
      size: "mini"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Name",
      prop: "title"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: _vm.readonly,
      size: "mini"
    },
    model: {
      value: _vm.touchpoint_form.title,
      callback: function callback($$v) {
        _vm.$set(_vm.touchpoint_form, "title", $$v);
      },
      expression: "touchpoint_form.title"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Description",
      prop: "description"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: _vm.readonly,
      size: "mini",
      type: "textarea"
    },
    model: {
      value: _vm.touchpoint_form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.touchpoint_form, "description", $$v);
      },
      expression: "touchpoint_form.description"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Type",
      prop: "type"
    }
  }, [_c("el-radio-group", {
    attrs: {
      disabled: _vm.readonly
    },
    model: {
      value: _vm.touchpoint_form.type,
      callback: function callback($$v) {
        _vm.$set(_vm.touchpoint_form, "type", $$v);
      },
      expression: "touchpoint_form.type"
    }
  }, [_c("el-radio", {
    attrs: {
      label: "email"
    }
  }), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "sms"
    }
  }), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "web"
    }
  }), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "chat"
    }
  }), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "phone"
    }
  }), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "social"
    }
  }), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "app"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "text-right mt-10"
  }, [!_vm.edit && !_vm.readonly ? _c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.createTouchpoint
    }
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "create"
    }
  }), _vm._v(" Create Touchpoint")], 1) : _vm._e(), _vm._v(" "), _vm.edit && !_vm.readonly ? _c("el-button", {
    attrs: {
      size: "mini",
      icon: "el-icon-edit",
      type: "primary"
    },
    on: {
      click: _vm.updateTouchpoint
    }
  }, [_vm._v("Update Touchpoint")]) : _vm._e(), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini",
      icon: "el-icon-close"
    },
    on: {
      click: _vm.closeUI
    }
  }, [_vm._v("Cancel")])], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;