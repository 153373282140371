"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticStyle: {
      margin: "6pt 6pt 6pt 10pt"
    }
  }, [_c("el-row", {
    attrs: {
      id: "bmc-first-row"
    }
  }, [_c("el-col", {
    staticClass: "pr-5",
    attrs: {
      span: 5
    }
  }, [_c("el-card", {
    staticClass: "width-normal height-1x bmc",
    attrs: {
      id: "partners"
    }
  }, [_c("div", {
    staticClass: "clearfix bmc_card_header",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("div", {
    staticClass: "bmc_header"
  }, [_vm._v("Partners\n            "), _c("el-button", {
    staticClass: "fr edit-button white-button",
    attrs: {
      type: "text",
      icon: "el-icon-edit"
    },
    on: {
      click: function click($event) {
        _vm.partnersDialog = true;
      }
    }
  })], 1)]), _vm._v(" "), _vm.business_model.partners ? _c("div", {
    staticClass: "text card-body",
    domProps: {
      innerHTML: _vm._s(_vm.business_model.partners)
    }
  }) : _c("div", {
    staticClass: "text card-body text-center"
  }, [_c("h3", [_c("p", [_vm._v("\n              To Be Completed\n            ")])])])])], 1), _vm._v(" "), _c("el-col", {
    staticClass: "pr-5",
    attrs: {
      span: 5
    }
  }, [_c("el-card", {
    staticClass: "width-normal height-1x",
    attrs: {
      id: "activities"
    }
  }, [_c("div", {
    staticClass: "clearfix bmc_card_header",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("div", {
    staticClass: "bmc_header"
  }, [_vm._v("Key Activities\n            "), _c("el-button", {
    staticClass: "fr edit-button white-button",
    attrs: {
      type: "text",
      icon: "el-icon-edit"
    },
    on: {
      click: function click($event) {
        _vm.keyActivitiesDialog = true;
      }
    }
  })], 1)]), _vm._v(" "), _vm.business_model.keyactivities ? _c("div", {
    staticClass: "text card-body",
    domProps: {
      innerHTML: _vm._s(_vm.business_model.keyactivities)
    }
  }) : _c("div", {
    staticClass: "text card-body text-center"
  }, [_c("h3", [_c("p", [_vm._v("\n              To Be Completed\n            ")])])])])], 1), _vm._v(" "), _c("el-col", {
    staticClass: "pr-5",
    attrs: {
      span: 5
    }
  }, [_c("el-card", {
    staticClass: "width-normal height-2x",
    attrs: {
      id: "value-proposition"
    }
  }, [_c("div", {
    staticClass: "clearfix bmc_card_header",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("div", {
    staticClass: "bmc_header"
  }, [_vm._v("Value Proposition\n            "), _c("el-button", {
    staticClass: "fr edit-button",
    attrs: {
      type: "text",
      icon: "el-icon-edit"
    },
    on: {
      click: function click($event) {
        _vm.valuePropositionDialog = true;
      }
    }
  })], 1)]), _vm._v(" "), _vm.business_model.valueproposition ? _c("div", {
    staticClass: "text card-body",
    domProps: {
      innerHTML: _vm._s(_vm.business_model.valueproposition)
    }
  }) : _c("div", {
    staticClass: "text card-body text-center"
  }, [_c("h3", [_c("p", [_vm._v("\n              To Be Completed\n            ")])])])])], 1), _vm._v(" "), _c("el-col", {
    staticClass: "pr-5",
    attrs: {
      span: 5
    }
  }, [_c("el-card", {
    staticClass: "width-normal height-1x",
    attrs: {
      id: "customer-relationships"
    }
  }, [_c("div", {
    staticClass: "clearfix bmc_card_header",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("div", {
    staticClass: "bmc_header"
  }, [_vm._v("Customer Relationships\n            "), _c("el-button", {
    staticClass: "fr edit-button",
    attrs: {
      type: "text",
      icon: "el-icon-edit"
    },
    on: {
      click: function click($event) {
        _vm.customerRelationsDialog = true;
      }
    }
  })], 1)]), _vm._v(" "), _vm.business_model.customerrelationship ? _c("div", {
    staticClass: "text card-body",
    domProps: {
      innerHTML: _vm._s(_vm.business_model.customerrelationship)
    }
  }) : _c("div", {
    staticClass: "text card-body text-center"
  }, [_c("h3", [_c("p", [_vm._v("\n              To Be Completed\n            ")])])])])], 1), _vm._v(" "), _c("el-col", {
    staticClass: "pr-5",
    attrs: {
      span: 4
    }
  }, [_c("el-card", {
    staticClass: "width-normal height-2x",
    attrs: {
      id: "customer-segments"
    }
  }, [_c("div", {
    staticClass: "clearfix bmc_card_header",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("div", {
    staticClass: "bmc_header"
  }, [_vm._v("Customer Segments\n            "), _c("el-button", {
    staticClass: "fr edit-button",
    attrs: {
      type: "text",
      icon: "el-icon-edit"
    },
    on: {
      click: function click($event) {
        _vm.customerSegmentsDialog = true;
      }
    }
  })], 1)]), _vm._v(" "), _vm.business_model.customersegment ? _c("div", {
    staticClass: "text card-body",
    domProps: {
      innerHTML: _vm._s(_vm.business_model.customersegment)
    }
  }) : _c("div", {
    staticClass: "text card-body text-center"
  }, [_c("h3", [_c("p", [_vm._v("\n              To Be Completed\n            ")])])])])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      id: "bmc-second-row"
    }
  }, [_c("el-col", {
    attrs: {
      span: 5
    }
  }, [_c("el-card", {
    staticClass: "width-normal height-1x",
    attrs: {
      id: "competitors"
    }
  }, [_c("div", {
    staticClass: "clearfix bmc_card_header",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("div", {
    staticClass: "bmc_header"
  }, [_vm._v("Competitors\n            "), _vm.competitors.length > 0 ? _c("el-button", {
    staticClass: "fr edit-button",
    attrs: {
      type: "text",
      icon: "el-icon-edit"
    },
    on: {
      click: _vm.gotoMonitorCompetitor
    }
  }) : _c("el-button", {
    staticClass: "fr edit-button",
    attrs: {
      type: "text",
      icon: "el-icon-edit"
    },
    on: {
      click: function click($event) {
        _vm.competitorsDialog = true;
      }
    }
  })], 1)]), _vm._v(" "), _vm.competitors.length > 0 ? _vm._l(_vm.competitors, function (bus_model_competitor, index) {
    return _c("div", {
      key: index,
      staticClass: "text card-body"
    }, [_c("p", [_c("strong", [_vm._v(_vm._s(bus_model_competitor.feature))])]), _vm._v(" "), _c("ul", _vm._l(bus_model_competitor.competitors, function (competitor, sub_index) {
      return _c("li", {
        key: sub_index
      }, [_vm._v(_vm._s(competitor.name))]);
    }), 0)]);
  }) : _vm.business_model.competitors ? _c("div", {
    staticClass: "text card-body",
    domProps: {
      innerHTML: _vm._s(_vm.business_model.competitors)
    }
  }) : _c("div", {
    staticClass: "text card-body text-center"
  }, [_c("h3", [_c("p", [_vm._v("\n              To Be Completed\n            ")])])])], 2)], 1), _vm._v(" "), _c("el-col", {
    staticClass: "pr-5",
    attrs: {
      id: "KeyResources",
      span: 5
    }
  }, [_c("el-card", {
    staticClass: "width-normal height-1x",
    attrs: {
      id: "key-resources"
    }
  }, [_c("div", {
    staticClass: "clearfix bmc_card_header",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("div", {
    staticClass: "bmc_header"
  }, [_vm._v("Key Resources\n            "), _c("el-button", {
    staticClass: "fr edit-button",
    attrs: {
      type: "text",
      icon: "el-icon-edit"
    },
    on: {
      click: function click($event) {
        _vm.keyResourcesDialog = true;
      }
    }
  })], 1)]), _vm._v(" "), _vm.business_model.keyresources ? _c("div", {
    staticClass: "text card-body",
    domProps: {
      innerHTML: _vm._s(_vm.business_model.keyresources)
    }
  }) : _c("div", {
    staticClass: "text card-body text-center"
  }, [_c("h3", [_c("p", [_vm._v("\n              To Be Completed\n            ")])])])])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 5
    }
  }, [_c("el-card", {
    staticClass: "width-normal height-1x",
    attrs: {
      id: "channel"
    }
  }, [_c("div", {
    staticClass: "clearfix bmc_card_header",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("div", {
    staticClass: "bmc_header"
  }, [_vm._v("Channels\n            "), _c("el-button", {
    staticClass: "fr edit-button",
    attrs: {
      type: "text",
      icon: "el-icon-edit"
    },
    on: {
      click: function click($event) {
        _vm.channelsDialog = true;
      }
    }
  })], 1)]), _vm._v(" "), _vm.business_model.channel ? _c("div", {
    staticClass: "text card-body",
    domProps: {
      innerHTML: _vm._s(_vm.business_model.channel)
    }
  }) : _c("div", {
    staticClass: "text card-body text-center"
  }, [_c("h3", [_c("p", [_vm._v("\n              To Be Completed\n            ")])])])])], 1)], 1), _vm._v(" "), _c("el-row", {
    staticClass: "container",
    attrs: {
      id: "bmc-third-row"
    }
  }, [_c("el-col", {
    staticClass: "pr-5",
    attrs: {
      span: 12
    }
  }, [_c("el-card", {
    staticClass: "width-superwide height-0_5x",
    attrs: {
      id: "cost-structure"
    }
  }, [_c("div", {
    staticClass: "clearfix bmc_card_header",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("div", {
    staticClass: "bmc_header"
  }, [_vm._v("Cost Structure\n            "), _c("el-button", {
    staticClass: "fr edit-button",
    attrs: {
      type: "text",
      icon: "el-icon-edit"
    },
    on: {
      click: function click($event) {
        _vm.costStructureDialog = true;
      }
    }
  })], 1)]), _vm._v(" "), _vm.business_model.coststructure ? _c("div", {
    staticClass: "text card-body",
    domProps: {
      innerHTML: _vm._s(_vm.business_model.coststructure)
    }
  }) : _c("div", {
    staticClass: "text card-body text-center"
  }, [_c("h3", [_c("p", [_vm._v("\n              To Be Completed\n            ")])])])])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-card", {
    staticClass: "width-superwide height-0_5x",
    attrs: {
      id: "revenue-streams"
    }
  }, [_c("div", {
    staticClass: "clearfix bmc_card_header",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("div", {
    staticClass: "bmc_header"
  }, [_vm._v("Revenue Streams\n            "), _c("el-button", {
    staticClass: "fr edit-button",
    attrs: {
      type: "text",
      icon: "el-icon-edit"
    },
    on: {
      click: function click($event) {
        _vm.revenueStreamDialog = true;
      }
    }
  })], 1)]), _vm._v(" "), _vm.business_model.revenuestreams ? _c("div", {
    staticClass: "text card-body",
    domProps: {
      innerHTML: _vm._s(_vm.business_model.revenuestreams)
    }
  }) : _c("div", {
    staticClass: "text card-body text-center"
  }, [_c("h3", [_c("p", [_vm._v("\n              To Be Completed\n            ")])])])])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Partners",
      visible: _vm.partnersDialog,
      width: "80%",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.partnersDialog = $event;
      }
    }
  }, [_c("tinymce", {
    attrs: {
      value: _vm.business_model.partners,
      height: 300
    },
    on: {
      update: _vm.updatePartner
    }
  }), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.partnersDialog = false;
      }
    }
  }, [_vm._v("Cancel")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini",
      icon: _vm.loading ? "el-icon-loading" : ""
    },
    on: {
      click: function click($event) {
        return _vm.updateBMC();
      }
    }
  }, [_vm._v("Save")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Competitors",
      visible: _vm.competitorsDialog,
      width: "80%",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.competitorsDialog = $event;
      }
    }
  }, [_c("tinymce", {
    attrs: {
      value: _vm.business_model.competitors,
      height: 300
    },
    on: {
      update: _vm.updateCompetitors
    }
  }), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.competitorsDialog = false;
      }
    }
  }, [_vm._v("Cancel")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini",
      icon: _vm.loading ? "el-icon-loading" : ""
    },
    on: {
      click: function click($event) {
        return _vm.updateBMC();
      }
    }
  }, [_vm._v("Save")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Key Resources",
      visible: _vm.keyResourcesDialog,
      width: "80%",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.keyResourcesDialog = $event;
      }
    }
  }, [_c("tinymce", {
    attrs: {
      value: _vm.business_model.keyresources,
      height: 300
    },
    on: {
      update: _vm.updateKeyResources
    }
  }), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.keyResourcesDialog = false;
      }
    }
  }, [_vm._v("Cancel")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini",
      icon: _vm.loading ? "el-icon-loading" : ""
    },
    on: {
      click: function click($event) {
        return _vm.updateBMC();
      }
    }
  }, [_vm._v("Save")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Key Activities",
      visible: _vm.keyActivitiesDialog,
      width: "80%",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.keyActivitiesDialog = $event;
      }
    }
  }, [_c("tinymce", {
    attrs: {
      value: _vm.business_model.keyactivities,
      height: 300
    },
    on: {
      update: _vm.updateKeyActivities
    }
  }), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.keyActivitiesDialog = false;
      }
    }
  }, [_vm._v("Cancel")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini",
      icon: _vm.loading ? "el-icon-loading" : ""
    },
    on: {
      click: function click($event) {
        return _vm.updateBMC();
      }
    }
  }, [_vm._v("Save")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Value Proposition",
      visible: _vm.valuePropositionDialog,
      width: "80%",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.valuePropositionDialog = $event;
      }
    }
  }, [_c("tinymce", {
    attrs: {
      value: _vm.business_model.valueproposition,
      height: 300
    },
    on: {
      update: _vm.updateValueProposition
    }
  }), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.valuePropositionDialog = false;
      }
    }
  }, [_vm._v("Cancel")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini",
      icon: _vm.loading ? "el-icon-loading" : ""
    },
    on: {
      click: function click($event) {
        return _vm.updateBMC();
      }
    }
  }, [_vm._v("Save")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Customer Relationships",
      visible: _vm.customerRelationsDialog,
      width: "80%",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.customerRelationsDialog = $event;
      }
    }
  }, [_c("tinymce", {
    attrs: {
      value: _vm.business_model.customerrelationship,
      height: 300
    },
    on: {
      update: _vm.updateCustomerRelationship
    }
  }), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.customerRelationsDialog = false;
      }
    }
  }, [_vm._v("Cancel")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini",
      icon: _vm.loading ? "el-icon-loading" : ""
    },
    on: {
      click: function click($event) {
        return _vm.updateBMC();
      }
    }
  }, [_vm._v("Save")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Channels",
      visible: _vm.channelsDialog,
      width: "80%",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.channelsDialog = $event;
      }
    }
  }, [_c("tinymce", {
    attrs: {
      value: _vm.business_model.channel,
      height: 300
    },
    on: {
      update: _vm.updateChannels
    }
  }), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.channelsDialog = false;
      }
    }
  }, [_vm._v("Cancel")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini",
      icon: _vm.loading ? "el-icon-loading" : ""
    },
    on: {
      click: function click($event) {
        return _vm.updateBMC();
      }
    }
  }, [_vm._v("Save")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Customer Segments",
      visible: _vm.customerSegmentsDialog,
      width: "80%",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.customerSegmentsDialog = $event;
      }
    }
  }, [_c("tinymce", {
    attrs: {
      value: _vm.business_model.customersegment,
      height: 300
    },
    on: {
      update: _vm.updateCustomerSegments
    }
  }), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.customerSegmentsDialog = false;
      }
    }
  }, [_vm._v("Cancel")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini",
      icon: _vm.loading ? "el-icon-loading" : ""
    },
    on: {
      click: function click($event) {
        return _vm.updateBMC();
      }
    }
  }, [_vm._v("Save")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Cost Structure",
      visible: _vm.costStructureDialog,
      width: "80%",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.costStructureDialog = $event;
      }
    }
  }, [_c("tinymce", {
    attrs: {
      value: _vm.business_model.coststructure,
      height: 300
    },
    on: {
      update: _vm.updateCostStructure
    }
  }), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.costStructureDialog = false;
      }
    }
  }, [_vm._v("Cancel")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini",
      icon: _vm.loading ? "el-icon-loading" : ""
    },
    on: {
      click: function click($event) {
        return _vm.updateBMC();
      }
    }
  }, [_vm._v("Save")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Revenue Stream",
      visible: _vm.revenueStreamDialog,
      width: "80%",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.revenueStreamDialog = $event;
      }
    }
  }, [_c("tinymce", {
    attrs: {
      value: _vm.business_model.revenuestreams,
      height: 300
    },
    on: {
      update: _vm.updateRevenueStream
    }
  }), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.revenueStreamDialog = false;
      }
    }
  }, [_vm._v("Cancel")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini",
      icon: _vm.loading ? "el-icon-loading" : ""
    },
    on: {
      click: function click($event) {
        return _vm.updateBMC();
      }
    }
  }, [_vm._v("Save")])], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;