"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-card", {
    staticClass: "mt-10"
  }, [_c("el-row", {
    staticClass: "mt-5",
    attrs: {
      gutter: 20,
      type: "flex",
      justify: "left"
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-row", [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("p", {
    staticClass: "sub-title-rounded"
  }, [_vm._v("Geography & Targets")]), _vm._v(" "), _c("table", {
    staticClass: "container",
    attrs: {
      width: "100%"
    }
  }, [_c("tr", [_c("td", {
    attrs: {
      width: "40%"
    }
  }, [_vm._v("Regions")]), _vm._v(" "), _c("td", {
    staticClass: "text-left"
  }, [_vm._l(_vm.activeSegment.geography, function (country_code, index) {
    return [_c("el-tag", {
      key: index
    }, [_vm._v(_vm._s(_vm.getCountryFromCode(country_code)))])];
  })], 2)]), _vm._v(" "), _c("tr", [_c("td", {
    attrs: {
      width: "40%"
    }
  }, [_vm._v("Sales Channels:")]), _vm._v(" "), _c("td", {
    staticClass: "text-left"
  }, [_vm._l(_vm.activeSegment.salesMethod, function (type_datum, index) {
    return [_c("el-tag", {
      key: index,
      staticClass: "mr-5",
      attrs: {
        size: "mini"
      }
    }, [_vm._v(_vm._s(type_datum))])];
  })], 2)]), _vm._v(" "), _c("tr", [_c("td", [_vm._v("Pricing Tier:")]), _vm._v(" "), _c("td", {
    staticClass: "text-left"
  }, [_vm._l(_vm.activeSegment.pricingTier, function (pricing, index) {
    return [_c("el-tag", {
      key: index
    }, [_vm._v(_vm._s(pricing))])];
  })], 2)]), _vm._v(" "), _c("tr", [_c("el-tooltip", {
    attrs: {
      effect: "dark",
      content: "These are the number of POTENTIAL customers in this segment",
      placement: "right"
    }
  }, [_c("td", [_vm._v("Total Segment Size")])]), _vm._v(" "), _c("td", {
    staticClass: "text-left"
  }, [_c("div", {
    staticClass: "transparent-input-box-centered el-input el-input--medium el-input--prefix"
  }, [_c("vue-numeric", {
    staticClass: "el-input__inner",
    attrs: {
      separator: ",",
      min: 0,
      max: 10000
    },
    model: {
      value: _vm.activeSegment.numCustomers,
      callback: function callback($$v) {
        _vm.$set(_vm.activeSegment, "numCustomers", $$v);
      },
      expression: "activeSegment.numCustomers"
    }
  })], 1)])], 1), _vm._v(" "), _c("tr", [_c("el-tooltip", {
    attrs: {
      effect: "dark",
      content: "Long term, what percentage of the market you wish to aim for?",
      placement: "right"
    }
  }, [_c("td", [_vm._v("Target Market Share:")])]), _vm._v(" "), _c("td", {
    staticClass: "text-left"
  }, [_c("div", {
    staticClass: "transparent-input-box-centered el-input el-input--medium el-input--prefix"
  }, [_c("vue-numeric", {
    staticClass: "el-input__inner",
    attrs: {
      separator: ",",
      min: 0,
      max: 100
    },
    model: {
      value: _vm.activeSegment.desiredMarketPercent,
      callback: function callback($$v) {
        _vm.$set(_vm.activeSegment, "desiredMarketPercent", $$v);
      },
      expression: "activeSegment.desiredMarketPercent"
    }
  }), _vm._v(" "), _c("span", {
    staticClass: "el-input__suffix"
  }, [_c("i", {
    staticClass: "el-input__icon"
  }, [_vm._v("%")])])], 1)])], 1), _vm._v(" "), _c("tr", {
    staticClass: "text-strong"
  }, [_c("el-tooltip", {
    attrs: {
      effect: "dark",
      content: "Forecast how many NEW customers, or NEW sales per month you will have",
      placement: "right"
    }
  }, [_c("td", [_vm._v("New Customers Per Month:")])]), _vm._v(" "), _c("td", {
    staticClass: "text-left"
  }, [_c("div", {
    staticClass: "transparent-input-box-centered el-input el-input--medium el-input--prefix"
  }, [_c("vue-numeric", {
    staticClass: "el-input__inner",
    attrs: {
      separator: ",",
      min: 0,
      max: 10000
    },
    model: {
      value: _vm.activeSegment.newCustomersPerMonth,
      callback: function callback($$v) {
        _vm.$set(_vm.activeSegment, "newCustomersPerMonth", $$v);
      },
      expression: "activeSegment.newCustomersPerMonth"
    }
  })], 1)])], 1), _vm._v(" "), _c("tr", {
    staticClass: "text-strong"
  }, [_c("el-tooltip", {
    attrs: {
      effect: "dark",
      content: "This your total number of customers if you reach your target market share.",
      placement: "right"
    }
  }, [_c("td", [_vm._v("Target Total Customers:")])]), _vm._v(" "), _c("td", {
    staticClass: "text-left"
  }, [_c("div", {
    staticClass: "transparent-input-box-centered el-input el-input--medium el-input--prefix is-disabled"
  }, [_c("vue-numeric", {
    staticClass: "el-input__inner",
    attrs: {
      separator: ",",
      disabled: ""
    },
    model: {
      value: _vm.activeSegment.numTargetCustomers,
      callback: function callback($$v) {
        _vm.$set(_vm.activeSegment, "numTargetCustomers", $$v);
      },
      expression: "activeSegment.numTargetCustomers"
    }
  })], 1)])], 1)])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("p", {
    staticClass: "sub-title-rounded"
  }, [_vm._v("Average Revenue Per Customer")]), _vm._v(" "), _c("table", {
    staticClass: "container",
    attrs: {
      width: "100%"
    }
  }, [_c("tr", [_c("td", {
    attrs: {
      width: "50%"
    }
  }, [_vm._v("Annual Licensing:")]), _vm._v(" "), _c("td", {
    staticClass: "text-center"
  }, [_c("div", {
    staticClass: "transparent-input-box-centered el-input el-input--medium el-input--prefix"
  }, [_c("vue-numeric", {
    staticClass: "el-input__inner",
    attrs: {
      separator: ","
    },
    model: {
      value: _vm.activeSegment.annualLicenseRevenuePC,
      callback: function callback($$v) {
        _vm.$set(_vm.activeSegment, "annualLicenseRevenuePC", $$v);
      },
      expression: "activeSegment.annualLicenseRevenuePC"
    }
  }), _vm._v(" "), _c("span", {
    staticClass: "el-input__prefix"
  }, [_c("i", {
    staticClass: "el-input__icon"
  }, [_vm._v("$")])])], 1)])]), _vm._v(" "), _c("tr", [_c("td", {
    attrs: {
      width: "50%"
    }
  }, [_vm._v("Annual Project Services:")]), _vm._v(" "), _c("td", {
    staticClass: "text-center"
  }, [_c("div", {
    staticClass: "transparent-input-box-centered el-input el-input--medium el-input--prefix"
  }, [_c("vue-numeric", {
    staticClass: "el-input__inner",
    attrs: {
      separator: ","
    },
    model: {
      value: _vm.activeSegment.annualProjectService,
      callback: function callback($$v) {
        _vm.$set(_vm.activeSegment, "annualProjectService", $$v);
      },
      expression: "activeSegment.annualProjectService"
    }
  }), _vm._v(" "), _c("span", {
    staticClass: "el-input__prefix"
  }, [_c("i", {
    staticClass: "el-input__icon"
  }, [_vm._v("$")])])], 1)])]), _vm._v(" "), _c("tr", [_c("td", {
    attrs: {
      width: "50%"
    }
  }, [_vm._v("Annual Support Fees")]), _vm._v(" "), _c("td", {
    staticClass: "text-center"
  }, [_c("div", {
    staticClass: "transparent-input-box-centered el-input el-input--medium el-input--prefix"
  }, [_c("vue-numeric", {
    staticClass: "el-input__inner",
    attrs: {
      separator: ","
    },
    model: {
      value: _vm.activeSegment.serviceFee,
      callback: function callback($$v) {
        _vm.$set(_vm.activeSegment, "serviceFee", $$v);
      },
      expression: "activeSegment.serviceFee"
    }
  }), _vm._v(" "), _c("span", {
    staticClass: "el-input__prefix"
  }, [_c("i", {
    staticClass: "el-input__icon"
  }, [_vm._v("$")])])], 1)])]), _vm._v(" "), _c("tr", {
    staticClass: "text-strong"
  }, [_c("td", {
    attrs: {
      width: "50%"
    }
  }, [_vm._v("Average Customer Value:")]), _vm._v(" "), _c("td", {
    staticClass: "text-center"
  }, [_c("div", {
    staticClass: "transparent-input-box-centered el-input el-input--medium el-input--prefix"
  }, [_c("vue-numeric", {
    staticClass: "el-input__inner",
    attrs: {
      separator: ","
    },
    model: {
      value: _vm.activeSegment.avgCustValue,
      callback: function callback($$v) {
        _vm.$set(_vm.activeSegment, "avgCustValue", $$v);
      },
      expression: "activeSegment.avgCustValue"
    }
  }), _vm._v(" "), _c("span", {
    staticClass: "el-input__prefix"
  }, [_c("i", {
    staticClass: "el-input__icon"
  }, [_vm._v("$")])])], 1)])])])])], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-row", [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("p", {
    staticClass: "sub-title-rounded"
  }, [_vm._v("Forecast annual growth")]), _vm._v(" "), _c("line-chart-component", {
    staticClass: "container",
    attrs: {
      "avg-customer-value": _vm.activeSegment.avgCustValue,
      "new-customers-per-month": _vm.activeSegment.newCustomersPerMonth,
      height: "300pt",
      width: "600px"
    }
  })], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("p", {
    staticClass: "sub-title-rounded"
  }, [_vm._v("Annual Revenue Breakdown")]), _vm._v(" "), _c("pie-chart-component", {
    staticClass: "container text-center",
    attrs: {
      support: _vm.activeSegment.annualSupportRevenueTotal,
      project: _vm.activeSegment.project,
      licensing: _vm.activeSegment.annualLicenseRevenueTotal,
      height: "300pt",
      width: "300pt"
    }
  })], 1)], 1)], 1)], 1), _vm._v(" "), _c("el-divider"), _vm._v(" "), _c("div", {
    staticClass: "text-right"
  }, [_c("el-button-group", {
    attrs: {
      size: "mini"
    }
  }, [_c("el-tooltip", {
    attrs: {
      effect: "dark",
      content: "Go To Define Segments",
      placement: "top-start"
    }
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      icon: "el-icon-arrow-left"
    },
    on: {
      click: _vm.onClickDefineSegments
    }
  }, [_vm._v("Previous")])], 1), _vm._v(" "), _c("el-tooltip", {
    attrs: {
      effect: "dark",
      content: "Go To Define Customer Types",
      placement: "top-start"
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.onClickDefineCustomerType
    }
  }, [_vm._v("Next"), _c("i", {
    staticClass: "el-icon-arrow-right"
  })])], 1)], 1)], 1)], 1)], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;