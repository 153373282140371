"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _default = exports.default = {
  name: 'SVGText',
  components: {},
  props: {
    x: {
      type: Number,
      default: 0
    },
    y: {
      type: Number,
      default: 0
    },
    length: {
      type: Number,
      default: 40
    },
    textAnchor: {
      type: String,
      default: 'start'
    },
    fontSize: {
      type: String,
      default: '.95em'
    },
    textStyle: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {};
  },
  computed: {},
  watch: {},
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    truncateString: function truncateString(string) {
      return this._.truncate(string, {
        'length': this.length
      });
    }
  }
};