"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _resolution = require("@/utils/resolution");
var _default = exports.default = {
  name: 'Timeline',
  components: {},
  props: {
    width: {
      type: Number,
      required: true,
      default: 0
    },
    height: {
      type: Number,
      required: true,
      default: 0
    },
    activeTimeline: {
      type: String,
      required: true,
      default: ''
    },
    posX: {
      type: Number,
      default: 0
    },
    timeframe: {
      type: Number,
      default: 0
    },
    nextYears: {
      type: Number,
      default: 3
    },
    previousYears: {
      type: Number,
      default: 1
    }
  },
  data: function data() {
    return {
      loading: false,
      x: 0,
      months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    };
  },
  computed: {
    computedStartX: function computedStartX() {
      var x = 215;
      // return x * screenRatio()
      return x;
    },
    computedTextStartX: function computedTextStartX() {
      var x = 215;
      return x + 20;
      // return x * screenRatio() + 20
    },
    computeTimeGap: function computeTimeGap() {
      var x = 32 / this.timeframe;
      return x;
      // return x * screenRatio()
    },
    computeMonthMultiplier: function computeMonthMultiplier() {
      var x = 128 / this.timeframe;
      return x;
      // return x * screenRatio()
    },
    computeMaskWidth: function computeMaskWidth() {
      var width = 150;
      return width * (0, _resolution.screenRatio)();
    },
    displayMonth: function displayMonth() {
      return this.timeframe < 4;
    }
  },
  watch: {
    posX: function posX(val) {
      if (val === 0) {
        this.x = 0;
      }
    }
  },
  mounted: function mounted() {},
  created: function created() {},
  methods: {
    computeYearBeforePrevYear: function computeYearBeforePrevYear() {
      return parseInt(this.activeTimeline) - 2;
    },
    computePrevYear: function computePrevYear() {
      return parseInt(this.activeTimeline) - 1;
    },
    computePreviousYear: function computePreviousYear(year) {
      return parseInt(this.activeTimeline) - parseInt(year);
    },
    computeYear: function computeYear(year) {
      return parseInt(this.activeTimeline) + parseInt(year);
    },
    onTimelineDragged: function onTimelineDragged(_ref) {
      var el = _ref.el,
        deltaX = _ref.deltaX,
        deltaY = _ref.deltaY,
        offsetX = _ref.offsetX,
        offsetY = _ref.offsetY,
        clientX = _ref.clientX,
        clientY = _ref.clientY,
        first = _ref.first,
        last = _ref.last;
      if (last !== undefined && last) {
        return null;
      }
      if (deltaX === undefined || deltaY === undefined) {
        return null;
      }
      this.x += parseInt(deltaX);
      this.$emit('drag', this.x, deltaX);
    }
  }
};