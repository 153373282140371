"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRoutes = exports.asyncRoutes = void 0;
exports.resetRouter = resetRouter;
var _interopRequireWildcard2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
var _layout = _interopRequireDefault(require("@/layout"));
var _security = _interopRequireDefault(require("./modules/security"));
var _process = _interopRequireDefault(require("./modules/process"));
/* eslint-disable */

_vue.default.use(_vueRouter.default);

/* Layout */

/* Router Modules */

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    noCache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
var constantRoutes = exports.constantRoutes = [{
  path: '/redirect',
  component: _layout.default,
  hidden: true,
  children: [{
    path: '/redirect/:path*',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/redirect/index'));
      });
    }
  }]
}, {
  path: '/login',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/auth/login/index'));
    });
  },
  hidden: true
}, {
  path: '/auth-redirect',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/auth/login/auth-redirect'));
    });
  },
  hidden: true
}, {
  path: '/reset-password',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/auth/reset-password/index'));
    });
  },
  hidden: true
}, {
  path: '/onboarding',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/onboarding/index'));
    });
  },
  hidden: true
}, {
  path: '/503',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/error-page/503'));
    });
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/error-page/404'));
    });
  },
  hidden: true
}, {
  path: '/401',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/error-page/401'));
    });
  },
  hidden: true
}, {
  path: '/integration/tprty/jira',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/integration/tprty_jira'));
    });
  },
  hidden: true
}, {
  path: '/status/:type',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/status'));
    });
  },
  hidden: true
},
/* {
    path: '/ideas',
    component: Layout,
    hidden: false,
    children: [
      {
        path: '',
        component: () => import('@/views/ideation/index'),
        name: 'Ideas',
        meta: { title: 'Ideas', icon: 'ideation', noCache: true, type: '' }
      },
      // Do not remove . Functionality handled in Ideation module
      {
        path: 'create',
        component: () => import('@/views/ideation/index'),
        name: 'Create Ideas',
        hidden: true,
        meta: { title: 'Create Ideas', icon: 'list', noCache: true, type: '' }
      }
    ]
  },*/
{
  path: '/my-business',
  name: 'Business Strategy',
  component: _layout.default,
  hidden: false,
  meta: {
    module: 'Business Strategy'
  },
  children: [{
    path: '',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/my_business/index'));
      });
    },
    name: 'Business Strategy',
    meta: {
      title: 'Business Strategy',
      icon: 'improvement',
      noCache: true,
      type: 'primary',
      module: 'Business Strategy'
    }
  }]
}, {
  path: '/customer-types',
  name: 'Customer Needs',
  component: _layout.default,
  hidden: false,
  meta: {
    title: 'Customer Needs',
    icon: 'peoples',
    module: 'Customer Needs'
  },
  children: [{
    path: 'overview',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/customer_types/index'));
      });
    },
    name: 'Overview',
    meta: {
      title: 'Overview',
      noCache: true,
      type: 'primary',
      module: 'Customer Needs'
    }
  }, {
    path: 'needs',
    redirect: 'jobs-to-be-done',
    hidden: true,
    name: 'Needs',
    meta: {
      title: 'Jobs To Be Done',
      noCache: true,
      type: 'primary',
      module: 'Customer Needs'
    }
  }, {
    path: 'jobs-to-be-done',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/customer_types/index'));
      });
    },
    name: 'Jobs To Be Done',
    meta: {
      title: 'Jobs To Be Done',
      noCache: true,
      type: 'primary',
      module: 'Customer Needs'
    }
  }, {
    path: 'journey1',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/customer_types/index_archive'));
      });
    },
    name: 'JourneyArchive',
    meta: {
      title: 'Journey Design Archive',
      noCache: true,
      type: 'primary',
      module: 'Customer Needs'
    }
  }, {
    path: 'journey',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/customer_types/index'));
      });
    },
    name: 'Journey',
    meta: {
      title: 'Journey Design',
      noCache: true,
      type: 'primary',
      module: 'Customer Needs'
    }
  }, {
    path: 'experience_backlog',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/customer_types/index'));
      });
    },
    name: 'Experience Backlog',
    meta: {
      title: 'Customer Priority',
      noCache: true,
      type: 'primary',
      module: 'Customer Needs'
    }
  }, {
    path: 'journey_ideas',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/customer_types/index'));
      });
    },
    name: 'Journey Ideas',
    meta: {
      title: 'Journey Ideas',
      noCache: true,
      type: 'addon',
      module: 'Customer Needs'
    }
  }, {
    path: 'create',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/customer_types/index'));
      });
    },
    hidden: true,
    name: 'Create New',
    meta: {
      title: 'Create New',
      noCache: true,
      type: 'primary',
      module: 'Customer Needs'
    }
  }]
}, {
  path: '/offerings',
  name: 'Offering',
  component: _layout.default,
  meta: {
    title: 'Digital Offerings',
    icon: 'product',
    module: 'Offering'
  },
  hidden: false,
  children: [{
    path: 'create',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/offering/create'));
      });
    },
    name: 'Create new offering',
    hidden: true,
    meta: {
      title: 'Create offering',
      noCache: true,
      type: 'primary'
    }
  }, {
    path: 'overview',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/offering/index'));
      });
    },
    name: 'Overview',
    meta: {
      title: 'Overview',
      noCache: true,
      type: 'primary',
      module: 'Offering'
    }
  },
  /*      {
          path: 'mockup',
          component: () => import('@/views/offering/index'),
          name: 'Mockup',
          meta: { title: 'Mockup', noCache: true, type: 'primary', module: 'Offering' }
        },*/
  {
    path: 'design',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/offering/index'));
      });
    },
    name: 'Design',
    meta: {
      title: 'Model Offering',
      noCache: true,
      type: 'primary',
      module: 'Offering'
    }
  }, {
    path: 'feature_backlog',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/offering/index'));
      });
    },
    name: 'FeatureBacklog',
    meta: {
      title: 'Offering Priorities',
      noCache: true,
      type: 'primary',
      module: 'Offering'
    }
  },
  /*      {
          path: 'need',
          component: () => import('@/views/offering/index'),
          name: 'Need',
          meta: { title: 'Needs Coverage', noCache: true, type: 'primary', module: 'Offering' }
        },*/
  {
    path: 'refine_features',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/offering/index'));
      });
    },
    name: 'RefineFeatures',
    meta: {
      title: 'Design Tickets',
      noCache: true,
      type: 'primary',
      module: 'Offering'
    }
  },
  /*remove the following when Refine Features is complete
    {
      path: 'feature',
      component: () => import('@/views/offering/index'),
      name: 'Feature',
      meta: { title: 'Requirements', noCache: true, type: 'primary', module: 'Offering' }
    },*/
  {
    path: 'external_tickets',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/offering/index'));
      });
    },
    name: 'ExternalDelivery',
    meta: {
      title: 'External Tickets',
      noCache: true,
      type: 'primary',
      module: 'Offering'
    }
  }, {
    path: 'delivery_progress',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/offering/index'));
      });
    },
    name: 'DeliveryProgress',
    meta: {
      title: 'Tracking & Reports',
      noCache: true,
      type: 'primary',
      module: 'Offering'
    }
  }, {
    path: 'strategy',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/offering/index'));
      });
    },
    name: 'Strategy',
    meta: {
      title: 'Strategy',
      noCache: true,
      type: 'addon',
      module: 'Business Strategy'
    }
  }, {
    path: 'risks',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/offering/index'));
      });
    },
    name: 'Risks',
    meta: {
      title: 'Risks',
      noCache: true,
      type: 'addon',
      module: 'Risk Managment'
    }
  }, {
    path: 'ideas',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/offering/index'));
      });
    },
    name: 'Ideas',
    meta: {
      title: 'Ideas',
      noCache: true,
      type: 'addon',
      module: 'Innovation Hub'
    }
  }]
}, {
  path: '/delivery',
  name: 'Delivery',
  component: _layout.default,
  meta: {
    title: 'Delivery',
    icon: 'delivery',
    module: 'Advanced Delivery'
  },
  hidden: false,
  children: [{
    path: 'roadmap',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/delivery/index'));
      });
    },
    name: 'OfferingRoadmap',
    meta: {
      title: 'Offering Roadmap',
      noCache: true,
      type: 'primary',
      module: 'Advanced Delivery'
    }
  },

    /*
      {
        path: 'current_release',
        component: () => import('@/views/delivery/index'),
        name: 'CurrentRelease',
        meta: { title: 'Increments', noCache: true, type: 'primary', module: 'Advanced Delivery' }
      },
      {
        path: 'past_release',
        component: () => import('@/views/delivery/index'),
        name: 'PastRelease',
        meta: { title: 'Releases', noCache: true, type: 'primary', module: 'Advanced Delivery' }
      }*/
  , {
    path: 'risks',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/delivery/index'));
      });
    },
    name: 'DeliverRisks',
    meta: {
      title: 'Risks',
      noCache: true,
      type: 'addon',
      module: 'Risk Managment'
    }
  }]
}, {
  path: '/digital-portfolio',
  component: _layout.default,
  hidden: false,
  meta: {
    title: 'Digital Portfolio',
    icon: 'portfolio',
    module: 'Portfolio Manager'
  },
  children: [{
    path: '',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/digital_portfolio/index'));
      });
    },
    name: 'Digital Portfolio',
    meta: {
      title: 'Digital Portfolio',
      icon: 'portfolio',
      noCache: true,
      type: '',
      module: 'Portfolio Manager'
    }
  }]
},

  /*  {
      path: '/reports',
      name: 'Reports',
      component: Layout,
      meta: { title: 'Reports', icon: 'chart-1', module: 'Report' },
      hidden: false,
      children: [
        {
          path: 'strategic_progress',
          component: () => import('@/views/dev/reports/strategic_progress'),
          name: 'strategic_progress',
          meta: { title: 'Progress', noCache: true, type: 'primary', module: 'Report' }
        },
        {
          path: 'traceability_ujm_priority',
          component: () => import('@/views/dev/reports/traceability_ujm_priority'),
          name: 'Priority',
          meta: { title: 'Priority', noCache: true, type: 'primary', module: 'Report' }
        },
        {
          path: 'traceability_ujm_bugs',
          component: () => import('@/views/dev/reports/traceability_ujm_bugs'),
          name: 'Value',
          meta: { title: 'Value', noCache: true, type: 'primary', module: 'Report' }
        },
        {
          path: 'sunburst',
          component: () => import('@/views/dev/reports/sunburst'),
          name: 'sunburst',
          meta: { title: 'Sunburst', noCache: true, type: 'primary', module: 'Report' }
        }
      ]
    },*/
  /*
  {
    path: '/portfolio-manager',
    component: Layout,
    hidden: false,
    meta: { title: 'Portfolio', icon: 'example', module: 'Portfolio Manager' },
    children: [
      {
        path: '',
        component: () => import('@/views/portfolio/index'),
        name: 'Portfolio Manager',
        meta: { title: 'Portfolio Manager', icon: 'example', noCache: true, type: '', module: 'Portfolio Manager' }
      }
    ]
  }
  */
, {
  path: '/innovation_hub',
  component: _layout.default,
  hidden: false,
  meta: {
    module: 'Innovation Hub'
  },
  children: [{
    path: '',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/innovation_hub/index'));
      });
    },
    name: 'Innovation Hub',
    meta: {
      title: 'Innovation Hub',
      icon: 'ideation',
      noCache: true,
      type: '',
      module: 'Innovation Hub'
    }
  }]
}, {
  path: '/risks',
  component: _layout.default,
  hidden: false,
  meta: {
    title: 'Risk',
    icon: 'warning_monochrome',
    module: 'Risk Managment'
  },
  children: [{
    path: '',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/risk_managment/index'));
      });
    },
    name: 'Risk Managment',
    meta: {
      title: 'Risk Management',
      icon: 'warning_monochrome',
      noCache: true,
      type: '',
      module: 'Risk Managment'
    }
  }]
}, {
  path: '/settings',
  component: _layout.default,
  hidden: false,
  name: 'Administration',
  meta: {
    title: 'Administration',
    icon: 'gear',
    module: 'Administration'
  },
  children: [{
    path: '',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/administration/index'));
      });
    },
    name: 'Administration',
    meta: {
      title: 'Administration',
      icon: 'gear',
      noCache: true,
      type: '',
      module: 'Administration'
    }
  }]
}, {
  path: '/dev',
  name: 'Dev',
  hidden: true,
  meta: {
    title: 'Dev',
    icon: 'setting',
    module: 'Dev'
  },
  component: _layout.default,
  children: [{
    path: 'adf',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/dev/adf'));
      });
    },
    name: 'ADF',
    meta: {
      title: 'ADF',
      icon: 'guide',
      noCache: true,
      module: 'Dev'
    }
  }, {
    path: 'postmvp',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/dev/postmvp'));
      });
    },
    name: 'PostMVP',
    meta: {
      title: 'PostMVP',
      icon: 'guide',
      noCache: true,
      module: 'Dev'
    }
  }, {
    path: 'contextmenu',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/dev/contextmenu'));
      });
    },
    name: 'ContextMenu',
    meta: {
      title: 'ContextMenu',
      icon: 'guide',
      noCache: true,
      module: 'Dev'
    }
  }, {
    path: 'icons',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/icons'));
      });
    },
    name: 'Icons',
    meta: {
      title: 'Icons',
      icon: 'guide',
      noCache: true,
      module: 'Dev'
    }
  }, {
    path: 'canvas',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/dev/konva_canvas'));
      });
    },
    name: 'Canvas',
    meta: {
      title: 'Canvas',
      icon: 'guide',
      noCache: true,
      module: 'Dev'
    }
  },
  /*      {
          path: 'gantt',
          component: () => import('@/views/dev/gantt'),
          name: 'Icons',
          meta: { title: 'Icons', icon: 'guide', noCache: true }
        },*/
  {
    path: 'templates/list',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/dev/templates/list'));
      });
    },
    name: 'List Template',
    meta: {
      title: 'List Template',
      icon: 'guide',
      noCache: true,
      module: 'Dev'
    }
  }, {
    path: 'product-manager',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/home/homescreen'));
      });
    },
    name: 'Product Manager',
    meta: {
      title: 'Product Manager',
      icon: 'guide',
      noCache: true,
      module: 'Dev'
    }
  }, {
    path: 'product-manager-future',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/home/index_old'));
      });
    },
    name: 'Product Manager - Future',
    meta: {
      title: 'Product Manager - Future',
      icon: 'guide',
      noCache: true,
      module: 'Dev'
    }
  }, {
    path: 'product-manager-prototype',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/home/index_new_prototype'));
      });
    },
    name: 'Product Manager - Proto',
    meta: {
      title: 'Product Manager - Proto',
      icon: 'guide',
      noCache: true,
      module: 'Dev'
    }
  }]
}, {
  path: '/',
  component: _layout.default,
  redirect: '/',
  hidden: true,
  meta: {
    module: 'Dashboard'
  },
  children: [{
    path: '',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/home/index_cards'));
      });
    },
    name: 'Prodiggi Dashboard',
    meta: {
      title: 'Dashboard',
      icon: 'guide',
      type: ''
    }
  }]
}, {
  path: '/profile',
  component: _layout.default,
  redirect: '/profile/index',
  hidden: true,
  meta: {
    title: 'Profile',
    icon: 'user',
    module: 'Profile'
  },
  children: [{
    path: 'index',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/profile/index'));
      });
    },
    name: 'My Profile',
    meta: {
      title: 'My Profile',
      icon: 'user',
      noCache: true
    }
  }, {
    path: 'tasks',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/profile/tasks'));
      });
    },
    name: 'My Tasks',
    meta: {
      title: 'My Tasks',
      icon: 'user',
      noCache: true
    }
  }]
}];

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
var asyncRoutes = exports.asyncRoutes = [_security.default, _process.default,
// 404 page must be placed at the end !!!
{
  path: '*',
  redirect: '/404',
  hidden: true
}];
var createRouter = function createRouter() {
  return new _vueRouter.default({
    mode: 'history',
    // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();
router.beforeEach(function (to, from, next) {
  /*if(from.name === 'Journey') 
   {
    let text = "Are you sure you want to navigate away from this page?\nYou will lose unsaved changes.";
      if (confirm(text) == true) {
        next()
      }
   } 
   else {
    next()  
   }*/
  next();
});
// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
var _default = exports.default = router;