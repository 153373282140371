"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "login-container"
  }, [_c("el-row", [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-form", {
    ref: "loginForm",
    staticClass: "login-form",
    attrs: {
      model: _vm.loginForm,
      rules: _vm.loginRules,
      autocomplete: "on",
      "label-position": "left"
    }
  }, [_c("div", {
    staticClass: "login-form-header"
  }, [_c("div", {
    staticClass: "title",
    staticStyle: {
      "font-size": "1.5em",
      display: "inline-block",
      "margin-bottom": "20pt",
      width: "92%"
    }
  }, [_c("img", {
    staticStyle: {
      "vertical-align": "middle"
    },
    attrs: {
      src: require("../../../assets/img/prodiggi.png"),
      height: "53",
      width: "55"
    }
  }), _vm._v(" "), _c("span", {
    staticStyle: {
      right: "25pt",
      position: "relative",
      "background-color": "rgba(255,255,255,0.7)",
      "border-radius": "4pt",
      display: "inline-block",
      "font-weight": "600",
      "vertical-align": "middle"
    }
  }, [_vm._v("Prodiggi ")]), _vm._v(" "), _c("span", {
    staticStyle: {
      display: "inline-block",
      right: "25pt",
      position: "relative",
      "vertical-align": "middle"
    }
  }, [_vm._v("Logon")]), _vm._v(" "), _c("div", {
    staticStyle: {
      top: "70px",
      left: "80px",
      position: "absolute"
    }
  }, [_c("el-tag", {
    attrs: {
      type: "warning"
    }
  }, [_vm._v(_vm._s(_vm.activeEnv) + " ENVIRONMENT")])], 1)])]), _vm._v(" "), _c("div", {
    staticClass: "login-form-body"
  }, [_c("el-form-item", {
    attrs: {
      prop: "username",
      size: "mini"
    }
  }, [_c("span", {
    staticClass: "svg-container"
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "user"
    }
  })], 1), _vm._v(" "), _c("el-input", {
    ref: "username",
    attrs: {
      id: "test_email",
      placeholder: "Email",
      name: "username",
      type: "text",
      tabindex: "1",
      autocomplete: "on",
      size: "mini"
    },
    model: {
      value: _vm.loginForm.username,
      callback: function callback($$v) {
        _vm.$set(_vm.loginForm, "username", $$v);
      },
      expression: "loginForm.username"
    }
  })], 1), _vm._v(" "), _c("el-tooltip", {
    attrs: {
      content: "Caps lock is On",
      placement: "right",
      manual: ""
    },
    model: {
      value: _vm.capsTooltip,
      callback: function callback($$v) {
        _vm.capsTooltip = $$v;
      },
      expression: "capsTooltip"
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "password",
      size: "mini"
    }
  }, [_c("span", {
    staticClass: "svg-container"
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "password"
    }
  })], 1), _vm._v(" "), _c("el-input", {
    key: _vm.passwordType,
    ref: "password",
    attrs: {
      id: "test_password",
      type: _vm.passwordType,
      placeholder: "Password",
      name: "password",
      tabindex: "2",
      autocomplete: "on",
      size: "mini"
    },
    on: {
      blur: function blur($event) {
        _vm.capsTooltip = false;
      }
    },
    nativeOn: {
      keyup: [function ($event) {
        return _vm.checkCapslock.apply(null, arguments);
      }, function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.handleLogin.apply(null, arguments);
      }]
    },
    model: {
      value: _vm.loginForm.password,
      callback: function callback($$v) {
        _vm.$set(_vm.loginForm, "password", $$v);
      },
      expression: "loginForm.password"
    }
  }), _vm._v(" "), _c("span", {
    staticClass: "show-pwd",
    on: {
      click: _vm.showPwd
    }
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": _vm.passwordType === "password" ? "eye" : "eye-open"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    staticStyle: {
      "text-align": "left"
    }
  }, [_c("el-col", {
    attrs: {
      span: 9
    }
  }, [_c("el-button", {
    staticStyle: {
      margin: "13px 0 4pt 0",
      padding: "0",
      color: "slategrey",
      "font-size": "0.95em"
    },
    attrs: {
      id: "test_forgot_password",
      type: "text"
    },
    nativeOn: {
      click: function click($event) {
        $event.preventDefault();
        _vm.showForgotPasswordDialog = true;
      }
    }
  }, [_vm._v("\n                Forgot Password\n              ")])], 1), _vm._v(" "), _c("el-col", {
    staticStyle: {
      float: "right"
    },
    attrs: {
      offset: 4,
      span: 9
    }
  }, [_c("el-button", {
    staticStyle: {
      width: "100%",
      "margin-bottom": "30px"
    },
    attrs: {
      id: "test_submit",
      loading: _vm.login_loading,
      type: "primary",
      size: "mini"
    },
    nativeOn: {
      click: function click($event) {
        $event.preventDefault();
        return _vm.handleLogin.apply(null, arguments);
      }
    }
  }, [_vm._v("\n                Login\n              ")])], 1)], 1), _vm._v(" "), _c("el-row", {
    staticClass: "row-bg",
    staticStyle: {
      "margin-top": "25pt"
    },
    attrs: {
      type: "flex",
      justify: "center"
    }
  }, [_c("el-col", {
    staticStyle: {
      "text-align": "center"
    },
    attrs: {
      span: 24
    }
  }, [_c("div", [_c("b", [_vm._v("Need an account?")])]), _vm._v(" "), _c("div", {
    staticClass: "mt-5"
  }, [_c("el-button", {
    staticStyle: {
      right: "0pt",
      "margin-bottom": "20px"
    },
    attrs: {
      id: "test_signup",
      size: "mini",
      type: "secondary"
    },
    nativeOn: {
      click: function click($event) {
        $event.preventDefault();
        return _vm.handleRegister.apply(null, arguments);
      }
    }
  }, [_vm._v("\n                  Sign Up\n                ")])], 1)])], 1)], 1)])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      visible: _vm.showForgotPasswordDialog,
      title: "Forgot Password",
      width: "25%",
      "custom-class": "forgot-password-dialog",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.showForgotPasswordDialog = $event;
      }
    }
  }, [_c("el-form", {
    ref: "passResetForm",
    attrs: {
      model: _vm.passResetForm,
      rules: _vm.forgotPasswordRules
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "email",
      size: "mini"
    }
  }, [_c("span", {
    staticClass: "svg-container"
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "user"
    }
  })], 1), _vm._v(" "), _c("el-input", {
    attrs: {
      id: "test_forgot_password_email",
      placeholder: "Email",
      name: "username",
      type: "text",
      "auto-complete": "on"
    },
    model: {
      value: _vm.passResetForm.email,
      callback: function callback($$v) {
        _vm.$set(_vm.passResetForm, "email", $$v);
      },
      expression: "passResetForm.email"
    }
  })], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      id: "test_send_reset_email",
      loading: _vm.forgotPasswordLoading,
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.sendResetEmail
    }
  }, [_vm._v("Send Reset Email")])], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;