"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _PieChart = _interopRequireDefault(require("./PieChart"));
var _default2 = exports.default = {
  name: 'PhaseDetail',
  components: {
    PieChartComponent: _PieChart.default
  },
  props: {
    journeyMap: {
      type: Object,
      required: true,
      default: function _default() {
        return {
          id: '',
          title: '',
          description: '',
          additionalattributes: {},
          linkedJourneyExperiences: [],
          linkedJourneyPhases: [],
          linkedJourneySteps: []
        };
      }
    },
    x: {
      type: Number,
      default: 0
    },
    y: {
      type: Number,
      default: 0
    },
    width: {
      type: Number,
      default: 0
    },
    phasex: {
      type: Number,
      default: 0
    },
    phasewidth: {
      type: Number,
      default: 0
    },
    index: {
      type: Number,
      default: 0
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    phase: {
      type: Object,
      default: function _default() {
        return {
          title: '',
          description: '',
          relations: []
        };
      }
    },
    steps: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    experiences: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    ideas: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    offering: {
      type: Object,
      required: true,
      default: function _default() {
        return {
          id: '',
          title: '',
          description: '',
          additionalattributes: {},
          linkedProducts: [],
          linkedServices: [],
          linkedCustomerTypes: []
        };
      }
    }
  },
  data: function data() {
    return {
      isHover: false,
      focusHover: false,
      stepHover: false,
      gainHover: false,
      painHover: false,
      wowHover: false,
      stepChunkSize: 5,
      activeStepChunk: 0,
      refreshKey: 0,
      progress: [0, 0, 0]
    };
  },
  computed: {
    computeComputedPhaseExternalStatuses: function computeComputedPhaseExternalStatuses() {
      for (var i = 0; i < this.journeyMap.linkedJourneyPhases.length; i++) {
        if (this.phase.id === this.journeyMap.linkedJourneyPhases[i].journeyPhase.id) {
          return this.journeyMap.linkedJourneyPhases[i].journeyPhase.external_status;
        }
      }
      return [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    },
    computeComputedPhaseProdiggiStatuses: function computeComputedPhaseProdiggiStatuses() {
      for (var i = 0; i < this.journeyMap.linkedJourneyPhases.length; i++) {
        if (this.phase.id === this.journeyMap.linkedJourneyPhases[i].journeyPhase.id) {
          return this.journeyMap.linkedJourneyPhases[i].journeyPhase.prodiggi_status;
        }
      }
      return [0, 0, 0, 0, 0, 0, 0, 0];
    },
    stepListActiveChunkLength: function stepListActiveChunkLength() {
      if (this.stepsList.length === 0) {
        return 0;
      }
      return this.stepsList[this.activeStepChunk].length;
    },
    stepsList: function stepsList() {
      // var step_ids = []
      this.refreshKey;
      var steps = [];

      // Sort Step list
      for (var k = 0; k < this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[this.index].steps.length; k++) {
        var step = this.retrieveStepFromStepID(this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[this.index].steps[k].id);
        if (step !== null) {
          steps.push(step);
        }
      }
      return this._.chunk(steps, this.stepChunkSize);
    },
    experiencesList: function experiencesList() {
      var experience_ids = [];
      var experiences = [];
      for (var i = 0; i < this.journeyMap.additionalattributes.relations.length; i++) {
        if (this.journeyMap.additionalattributes.relations[i].start_id === this.phase.id && this.journeyMap.additionalattributes.relations[i].end_type === 'Experience') {
          experience_ids.push(this.journeyMap.additionalattributes.relations[i].end_id);
        }
      }
      for (var j = 0; j < this.journeyMap.linkedJourneyExperiences.length; j++) {
        if (experience_ids.indexOf(this.journeyMap.linkedJourneyExperiences[j].journeyExperience.id) > -1 && !this._.some(experiences, {
          id: this.journeyMap.linkedJourneyExperiences[j].journeyExperience.id
        })) {
          experiences.push(this.journeyMap.linkedJourneyExperiences[j].journeyExperience);
        }
      }
      return experiences;
    },
    computeHeight: function computeHeight() {
      var step_height = 0;
      if (this.stepsList[this.activeStepChunk]) {
        step_height = this.stepsList[this.activeStepChunk].length * 35;
      }
      var experience_height = 0;
      if (this.experiencesList && !this.readOnly) {
        experience_height = this.experiencesList.length * 32 + 20;
      }
      return step_height + experience_height + 220;
    }
  },
  watch: {
    index: function index(old_val, new_val) {
      this.refreshKey++;
      this.recomputeProgress();
    }
  },
  created: function created() {},
  mounted: function mounted() {
    this.computeProgress();
  },
  methods: {
    hasFeaturesOrProcesses: function hasFeaturesOrProcesses() {
      for (var i = 0; i < this.offering.additionalattributes.relations.length; i++) {
        if (this.offering.additionalattributes.relations[i].start_id === this.phase.id) {
          return true;
        }
      }
    },
    recomputeProgress: function recomputeProgress() {},
    computeProgress: function computeProgress() {
      var first, second, third;
      var sum = 100;
      while (sum > 90) {
        first = Math.floor(Math.random() * 70 + 10);
        second = Math.floor(Math.random() * 70 + 10);
        sum = first + second;
      }
      third = 100 - sum;
      this.progress = [first, second, third];
    },
    retrieveStepFromStepID: function retrieveStepFromStepID(step_id) {
      for (var j = 0; j < this.journeyMap.linkedJourneySteps.length; j++) {
        if (this.journeyMap.linkedJourneySteps[j].journeyStep.id === step_id && !this.journeyMap.linkedJourneySteps[j].journeyStep.hasOwnProperty('isDeleted') || this.journeyMap.linkedJourneySteps[j].journeyStep.id === step_id && this.journeyMap.linkedJourneySteps[j].journeyStep.hasOwnProperty('isDeleted') && this.journeyMap.linkedJourneySteps[j].journeyStep.isDeleted === false) {
          return this.journeyMap.linkedJourneySteps[j];
        }
      }
    },
    generateMarker: function generateMarker() {
      var x_start = this.phasex + this.phasewidth / 2;
      var y_start = this.y - 10;
      var x_mid = this.phasex + this.phasewidth / 2 - 10;
      var y_mid = this.y;
      var x_last = this.phasex + this.phasewidth / 2 + 10;
      var y_last = this.y;
      return 'M ' + x_start + ' ' + y_start + ' L ' + x_mid + ' ' + y_mid + ' L ' + x_last + ' ' + y_last + '';
    },
    closePhaseDetailsPopover: function closePhaseDetailsPopover() {
      this.$emit('close');
    },
    displayPhaseDetails: function displayPhaseDetails() {
      this.$emit('details', this.index);
    },
    createPhaseIdea: function createPhaseIdea() {
      this.$emit('create-phase-idea', this.phase.title);
    },
    createTask: function createTask() {
      this.$emit('create-task', this.index);
    },
    deletePhase: function deletePhase() {
      this.$emit('delete', this.index);
    },
    deleteStep: function deleteStep(step_index) {
      this.$emit('delete-step', step_index);
    },
    deleteExperience: function deleteExperience(experience_id) {
      this.$emit('delete-experience', experience_id);
    },
    focusMode: function focusMode() {
      this.$emit('focus-mode', this.phase, this.index);
    },
    addChallenge: function addChallenge() {
      this.$emit('create-challenge', this.index);
    },
    addGain: function addGain() {
      this.$emit('create-gain', this.index);
    },
    addPain: function addPain() {
      this.$emit('create-pain', this.index);
    },
    addWow: function addWow() {
      this.$emit('create-wow', this.index);
    },
    truncateString: function truncateString(string, length) {
      return this._.truncate(string, {
        'length': length,
        'separator': ' '
      });
    }
  }
};