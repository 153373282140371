"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    style: _vm.getStyle
  }, [!_vm.preview ? _c("div", {
    staticClass: "preview",
    on: {
      click: function click($event) {
        _vm.editImage = !_vm.editImage;
      }
    }
  }, [!_vm.editImage ? _c("i", {
    staticClass: "el-icon-edit",
    attrs: {
      "aria-hidden": "true"
    }
  }) : _vm._e()]) : _vm._e(), _vm._v(" "), _vm.editImage ? _c("el-input", {
    attrs: {
      type: "text"
    },
    on: {
      blur: _vm.save
    },
    model: {
      value: _vm.item.location,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "location", $$v);
      },
      expression: "item.location"
    }
  }, [_c("el-button", {
    attrs: {
      slot: "append",
      icon: "el-icon-close"
    },
    on: {
      click: function click($event) {
        _vm.editImage = !_vm.editImage;
      }
    },
    slot: "append"
  })], 1) : _vm._e()], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;