"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-row", [_c("el-col", {
    attrs: {
      xl: 10,
      lg: 14,
      md: 14,
      sm: 16,
      xs: 16
    }
  }, [_c("el-tabs", {
    attrs: {
      "tab-position": "top"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "Description"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("div", {
    staticClass: "ml-10"
  }, [_c("strong", [_vm._v("Customer Type")])]), _vm._v(" "), _c("el-input", {
    attrs: {
      label: "Type",
      type: "",
      size: "mini"
    },
    model: {
      value: _vm.customer.title,
      callback: function callback($$v) {
        _vm.$set(_vm.customer, "title", $$v);
      },
      expression: "customer.title"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "mt-10 ml-10"
  }, [_c("strong", [_vm._v("Description")])]), _vm._v(" "), _c("el-input", {
    staticClass: "mt-5",
    attrs: {
      type: "textarea",
      rows: 14,
      size: "mini"
    },
    on: {
      blur: _vm.updateOverview
    },
    model: {
      value: _vm.customer.description,
      callback: function callback($$v) {
        _vm.$set(_vm.customer, "description", $$v);
      },
      expression: "customer.description"
    }
  })], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 24
    }
  })], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    staticClass: "text-left mt-10",
    staticStyle: {
      "padding-right": "30px"
    },
    attrs: {
      span: 14
    }
  }, [_c("el-button-group", {
    attrs: {
      size: "mini"
    }
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.goTo("jobs-to-be-done");
      }
    }
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "tree-table"
    }
  }), _vm._v(" View Jobs")], 1), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.goTo("journey");
      }
    }
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "phase"
    }
  }), _vm._v(" View Journey")], 1)], 1)], 1), _vm._v(" "), _c("el-col", {
    staticClass: "text-right mt-10",
    attrs: {
      span: 10
    }
  }, [_c("el-button-group", {
    attrs: {
      size: "mini"
    }
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      icon: "el-icon-edit",
      type: "primary",
      loading: _vm.overviewLoading
    },
    on: {
      click: _vm.updateOverview
    }
  }, [_vm._v("Update")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini",
      icon: "el-icon-delete",
      type: "danger"
    },
    on: {
      click: _vm.deleteCustomerType
    }
  }, [_vm._v("Delete")])], 1)], 1)], 1)], 1), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: "Problem"
    }
  }, [_c("div", {
    staticClass: "ml-10"
  }, [_c("strong", [_vm._v("Problem Statement")])]), _vm._v(" "), _c("el-input", {
    attrs: {
      type: "textarea",
      rows: 18,
      size: "mini"
    },
    on: {
      blur: _vm.updateOverview
    },
    model: {
      value: _vm.customer.problemstatement,
      callback: function callback($$v) {
        _vm.$set(_vm.customer, "problemstatement", $$v);
      },
      expression: "customer.problemstatement"
    }
  }), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    staticClass: "text-right mt-10 ml-20",
    staticStyle: {
      "padding-right": "30px"
    },
    attrs: {
      span: 24
    }
  }, [_c("el-button-group", {
    attrs: {
      size: "mini"
    }
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "primary",
      loading: _vm.overviewLoading
    },
    on: {
      click: _vm.updateOverview
    }
  }, [_vm._v("Update")])], 1)], 1)], 1)], 1), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: "Value"
    }
  }, [_c("div", {
    staticClass: "ml-10"
  }, [_c("strong", [_vm._v("Value Proposition")])]), _vm._v(" "), _c("el-input", {
    attrs: {
      type: "textarea",
      rows: 18,
      size: "mini"
    },
    on: {
      blur: _vm.updateOverview
    },
    model: {
      value: _vm.customer.valueproposition,
      callback: function callback($$v) {
        _vm.$set(_vm.customer, "valueproposition", $$v);
      },
      expression: "customer.valueproposition"
    }
  }), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_vm._v("s\n            "), _c("el-col", {
    staticClass: "text-right mt-10 ml-20",
    staticStyle: {
      "padding-right": "30px"
    },
    attrs: {
      span: 24
    }
  }, [_c("el-button-group", {
    attrs: {
      size: "mini"
    }
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "primary",
      loading: _vm.overviewLoading
    },
    on: {
      click: _vm.updateOverview
    }
  }, [_vm._v("Update")])], 1)], 1)], 1)], 1), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: "Offerings"
    }
  }, [_c("table", {
    staticClass: "mt-10"
  }, [_c("tr", [_c("td", {
    staticStyle: {
      width: "70pt",
      "font-size": "1.3em"
    }
  }, [_c("strong", [_vm._v("Primary")])]), _vm._v(" "), _c("td", [_vm.retrievePrimaryOfferingTitle() ? _c("div", [_c("el-tag", {
    attrs: {
      color: "lightblue",
      size: "mini",
      closable: ""
    },
    on: {
      close: function close($event) {
        return _vm.removePrimaryOffering();
      }
    }
  }, [_c("svg-icon", {
    staticStyle: {
      color: "black"
    },
    attrs: {
      "icon-class": "inherit-primary"
    }
  }), _vm._v("\n                    " + _vm._s(_vm.retrievePrimaryOfferingTitle()))], 1)], 1) : _c("div", [_c("el-select", {
    attrs: {
      size: "mini",
      placeholder: "Select Offering"
    },
    on: {
      change: _vm.updatePrimaryOffering
    },
    model: {
      value: _vm.selected_primary_offering,
      callback: function callback($$v) {
        _vm.selected_primary_offering = $$v;
      },
      expression: "selected_primary_offering"
    }
  }, _vm._l(_vm.orphan_offerings, function (offering) {
    return _c("el-option", {
      key: offering.id,
      attrs: {
        label: offering.title,
        value: offering.id
      }
    });
  }), 1), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "success",
      size: "mini",
      circle: "",
      label: "Save"
    }
  }, [_c("i", {
    staticClass: "fa fa-save"
  })])], 1)])])]), _vm._v(" "), _vm.retrievePrimaryOfferingTitle() ? _c("table", {
    staticClass: "mt-20",
    staticStyle: {
      "margin-bottom": "50pt"
    }
  }, [_c("tr", [_c("td", {
    staticClass: "mt-10",
    staticStyle: {
      width: "70pt",
      "font-size": "1.3em"
    }
  }, [_c("strong", [_vm._v("Secondary")])]), _vm._v(" "), _c("td", [_vm.secondaryOfferingList.length > 0 ? _c("div", [_c("el-select", {
    attrs: {
      size: "mini",
      placeholder: "Select Offering"
    },
    on: {
      change: _vm.updateSecondaryOffering
    },
    model: {
      value: _vm.selected_secondary_offering,
      callback: function callback($$v) {
        _vm.selected_secondary_offering = $$v;
      },
      expression: "selected_secondary_offering"
    }
  }, _vm._l(_vm.orphan_offerings, function (offering) {
    return _c("el-option", {
      key: offering.id,
      attrs: {
        label: offering.title,
        value: offering.id
      }
    });
  }), 1), _vm._v(" "), _vm._l(_vm.secondaryOfferingList, function (offering, offering_index) {
    return _c("el-tag", {
      key: offering_index,
      staticClass: "mt-5",
      attrs: {
        size: "mini",
        closable: ""
      },
      on: {
        close: function close($event) {
          return _vm.removeSecondaryOffering(offering_index);
        }
      }
    }, [_c("svg-icon", {
      staticStyle: {
        color: "#999999"
      },
      attrs: {
        "icon-class": "inherit-secondary"
      }
    }), _vm._v("\n                    " + _vm._s(offering.offering.title) + "\n                  ")], 1);
  })], 2) : _c("div", [_c("el-select", {
    attrs: {
      size: "mini",
      placeholder: "Select Offering"
    },
    on: {
      change: _vm.updateSecondaryOffering
    },
    model: {
      value: _vm.selected_secondary_offering,
      callback: function callback($$v) {
        _vm.selected_secondary_offering = $$v;
      },
      expression: "selected_secondary_offering"
    }
  }, _vm._l(_vm.orphan_offerings, function (offering) {
    return _c("el-option", {
      key: offering.id,
      attrs: {
        label: offering.title,
        value: offering.id
      }
    });
  }), 1), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "success",
      size: "mini",
      circle: "",
      label: "Save"
    }
  }, [_c("i", {
    staticClass: "fa fa-save"
  })])], 1)])])]) : _vm._e()])], 1)], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;