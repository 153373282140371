"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-row", {
    staticClass: "mt-10"
  }, [_c("el-col", {
    attrs: {
      offset: 4,
      span: 8
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini",
      "prefix-icon": "el-icon-search",
      "suffix-icon": _vm.loading.status ? "el-icon-loading" : "",
      placeholder: "Search"
    },
    on: {
      input: function input($event) {
        return _vm.loadPage(1);
      }
    },
    model: {
      value: _vm.filter.q,
      callback: function callback($$v) {
        _vm.$set(_vm.filter, "q", $$v);
      },
      expression: "filter.q"
    }
  })], 1), _vm._v(" "), _c("el-col", {
    staticClass: "text-center",
    attrs: {
      span: 4
    }
  }, [_vm.integrations.length > 0 ? _c("el-select", {
    staticClass: "tenancy",
    attrs: {
      placeholder: "Select Project",
      multiple: "",
      size: "mini"
    },
    on: {
      change: function change($event) {
        return _vm.loadPage(1);
      }
    },
    model: {
      value: _vm.filter.projects,
      callback: function callback($$v) {
        _vm.$set(_vm.filter, "projects", $$v);
      },
      expression: "filter.projects"
    }
  }, _vm._l(_vm.integrations, function (integration_datum) {
    return _c("el-option-group", {
      key: integration_datum.appName,
      attrs: {
        label: integration_datum.appName
      }
    }, _vm._l(_vm.filteredProjectsByImport(integration_datum.projects), function (project) {
      return _c("el-option", {
        key: project.name,
        attrs: {
          label: project.name,
          value: project.name
        }
      });
    }), 1);
  }), 1) : _vm._e(), _vm._v(" "), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Reload Tickets for project",
      placement: "bottom"
    }
  }, [_c("el-button", {
    attrs: {
      disabled: _vm.filter.projects.length === 0,
      icon: _vm.ticket_loading.status ? "el-icon-loading" : "el-icon-refresh",
      size: "mini",
      circle: ""
    },
    on: {
      click: _vm.reloadTickets
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    staticClass: "text-center",
    attrs: {
      span: 4
    }
  }, [_vm.users.length > 0 ? _c("el-select", {
    attrs: {
      placeholder: "Select User",
      multiple: "",
      size: "mini"
    },
    on: {
      change: function change($event) {
        return _vm.loadPage(1);
      }
    },
    model: {
      value: _vm.filter.users,
      callback: function callback($$v) {
        _vm.$set(_vm.filter, "users", $$v);
      },
      expression: "filter.users"
    }
  }, _vm._l(_vm.users, function (user) {
    return _c("el-option", {
      key: user.id,
      attrs: {
        label: user.displayName,
        value: user.displayName
      }
    }, [_c("span", {
      staticStyle: {
        float: "left"
      }
    }, [_vm._v(_vm._s(user.displayName))])]);
  }), 1) : _vm._e()], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 4
    }
  }, [_c("el-button-group", {
    staticClass: "fr"
  }, [_c("el-button", {
    attrs: {
      size: "micro",
      type: "text",
      icon: "el-icon-refresh",
      title: "Refresh",
      placeholder: "Refresh"
    },
    on: {
      click: function click($event) {
        return _vm.loadPage(1);
      }
    }
  }), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "micro",
      type: "text",
      icon: "el-icon-download",
      title: "Import",
      placeholder: "Refresh"
    },
    on: {
      click: function click($event) {
        return _vm.importTickets();
      }
    }
  }), _vm._v(" "), _vm.listLayout === "detailed" ? _c("el-button", {
    attrs: {
      size: "micro",
      type: "text",
      icon: "el-icon-minus",
      title: "Compact",
      placeholder: "Compact"
    },
    on: {
      click: function click($event) {
        return _vm.updateListLayout("compact");
      }
    }
  }) : _vm._e(), _vm._v(" "), _vm.listLayout === "compact" ? _c("el-button", {
    attrs: {
      size: "micro",
      type: "text",
      icon: "el-icon-tickets",
      title: "Detailed",
      placeholder: "Detailed"
    },
    on: {
      click: function click($event) {
        return _vm.updateListLayout("detailed");
      }
    }
  }) : _vm._e()], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    staticClass: "mt-5"
  }, [_c("el-col", {
    staticClass: "text-center",
    attrs: {
      offset: 8,
      span: 8
    }
  }, [_c("el-checkbox-group", {
    attrs: {
      size: "mini"
    },
    on: {
      change: _vm.filterByTicketStatus
    },
    model: {
      value: _vm.filter.ticket_status,
      callback: function callback($$v) {
        _vm.$set(_vm.filter, "ticket_status", $$v);
      },
      expression: "filter.ticket_status"
    }
  }, [_c("el-checkbox-button", {
    attrs: {
      label: "MAPPED"
    }
  }, [_vm._v("MAPPED")]), _vm._v(" "), _c("el-checkbox-button", {
    attrs: {
      label: "UNMAPPED"
    }
  }, [_vm._v("UNMAPPED")]), _vm._v(" "), _c("el-checkbox-button", {
    attrs: {
      label: "IGNORED"
    }
  }, [_vm._v("IGNORED")])], 1)], 1)], 1), _vm._v(" "), _vm.filteredTickets.length > 0 ? _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading.status,
      expression: "loading.status"
    }]
  }, [_c("el-table", {
    staticStyle: {
      width: "100%",
      "font-size": "11px"
    },
    attrs: {
      data: _vm.tickets,
      size: "micro",
      stripe: ""
    },
    on: {
      "selection-change": _vm.handleTicketListSelectionChange
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "selection",
      width: "55"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "External Project",
      prop: "externalProjectName",
      width: "130"
    }
  }), _vm._v(" "), _vm.listLayout === "detailed" ? _c("el-table-column", {
    attrs: {
      label: "Ticket"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-descriptions", {
          attrs: {
            size: "mini",
            border: ""
          }
        }, [_c("el-descriptions-item", {
          attrs: {
            label: "EPIC"
          }
        }, [_c("el-tag", {
          attrs: {
            size: "mini"
          }
        }, [_vm._v(_vm._s(scope.row.externalId))]), _vm._v(" - " + _vm._s(scope.row.title))], 1), _vm._v(" "), _c("el-descriptions-item", {
          attrs: {
            label: "Reporter"
          }
        }, [_c("el-tag", {
          attrs: {
            size: "mini"
          }
        }, [_vm._v(_vm._s(scope.row.externalReporterName))])], 1), _vm._v(" "), _c("el-descriptions-item", {
          attrs: {
            label: "Assignee"
          }
        }, [_c("el-tag", {
          attrs: {
            size: "mini"
          }
        }, [_vm._v(_vm._s(scope.row.externalAssigneeName))])], 1), _vm._v(" "), _c("el-descriptions-item", {
          attrs: {
            label: "Project"
          }
        }, [_c("el-tag", {
          attrs: {
            size: "mini"
          }
        }, [_vm._v(_vm._s(scope.row.externalProjectName))])], 1), _vm._v(" "), _c("el-descriptions-item", {
          attrs: {
            label: "Status"
          }
        }, [_c("el-tag", {
          attrs: {
            size: "mini"
          }
        }, [_vm._v(_vm._s(scope.row.status))])], 1), _vm._v(" "), _c("el-descriptions-item", {
          attrs: {
            label: "External status"
          }
        }, [_c("el-tag", {
          attrs: {
            size: "mini"
          }
        }, [_vm._v(_vm._s(scope.row.externalStatus))])], 1), _vm._v(" "), _c("el-descriptions-item", {
          attrs: {
            label: "Ticket Status"
          }
        }, [_c("el-tag", {
          attrs: {
            size: "mini",
            type: _vm.computeMappedStatusType(scope.row.mappedStatus)
          }
        }, [_vm._v(_vm._s(scope.row.mappedStatus))])], 1), _vm._v(" "), scope.row.mappedStatus === "MAPPED" ? _c("el-descriptions-item", {
          attrs: {
            label: "Mapped Status"
          }
        }, [scope.row.entityName === "ProductFeature" ? _c("el-tag", {
          attrs: {
            size: "mini"
          }
        }, [_c("svg-icon", {
          staticStyle: {
            "font-size": "16px"
          },
          attrs: {
            "icon-class": "pdg-product"
          }
        }), _vm._v(" " + _vm._s(scope.row.mappedParentEntity.title))], 1) : _vm._e(), _vm._v(" "), scope.row.entityName === "ServiceApplication" ? _c("el-tag", {
          attrs: {
            size: "mini"
          }
        }, [_c("svg-icon", {
          staticStyle: {
            "font-size": "16px"
          },
          attrs: {
            "icon-class": "pdg-service"
          }
        }), _vm._v(" " + _vm._s(scope.row.mappedParentEntity.title))], 1) : _vm._e()], 1) : _vm._e()], 1)];
      }
    }], null, false, 2464398797)
  }) : _vm._e(), _vm._v(" "), _vm.listLayout === "compact" ? _c("el-table-column", {
    attrs: {
      label: "EPIC"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-tag", {
          attrs: {
            size: "micro"
          }
        }, [_vm._v(_vm._s(scope.row.externalId))]), _vm._v(" - " + _vm._s(scope.row.title) + "\n        ")];
      }
    }], null, false, 1191733897)
  }) : _vm._e(), _vm._v(" "), _vm.listLayout === "compact" ? _c("el-table-column", {
    attrs: {
      label: "Status",
      sortable: "",
      width: "110"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-tag", {
          attrs: {
            size: "micro"
          }
        }, [_vm._v(_vm._s(scope.row.status))])];
      }
    }], null, false, 1248061275)
  }) : _vm._e(), _vm._v(" "), _vm.listLayout === "compact" ? _c("el-table-column", {
    attrs: {
      label: "External status",
      width: "130"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-tag", {
          attrs: {
            size: "micro"
          }
        }, [_vm._v(_vm._s(scope.row.externalStatus))])];
      }
    }], null, false, 3454081350)
  }) : _vm._e(), _vm._v(" "), _vm.listLayout === "compact" ? _c("el-table-column", {
    attrs: {
      label: "User",
      sortable: "",
      width: "110"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-tag", {
          attrs: {
            size: "micro"
          }
        }, [_vm._v(_vm._s(scope.row.externalReporterName))])];
      }
    }], null, false, 3512352460)
  }) : _vm._e(), _vm._v(" "), _vm.listLayout === "compact" ? _c("el-table-column", {
    attrs: {
      label: "Ticket Status",
      sortable: "",
      width: "140"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-tag", {
          attrs: {
            size: "micro",
            type: _vm.computeMappedStatusType(scope.row.mappedStatus)
          }
        }, [_vm._v(_vm._s(scope.row.mappedStatus))])];
      }
    }], null, false, 1500336084)
  }) : _vm._e(), _vm._v(" "), _vm.listLayout === "compact" ? _c("el-table-column", {
    attrs: {
      label: "Mapped to",
      width: "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.mappedStatus === "MAPPED" && scope.row.entityName === "ProductFeature" ? _c("el-tag", {
          attrs: {
            size: "micro"
          }
        }, [_c("svg-icon", {
          staticStyle: {
            "font-size": "16px"
          },
          attrs: {
            "icon-class": "pdg-product"
          }
        }), _vm._v(" " + _vm._s(scope.row.mappedParentEntity.title))], 1) : _vm._e(), _vm._v(" "), scope.row.mappedStatus === "MAPPED" && scope.row.entityName === "ServiceApplication" ? _c("el-tag", {
          attrs: {
            size: "micro"
          }
        }, [_c("svg-icon", {
          staticStyle: {
            "font-size": "16px"
          },
          attrs: {
            "icon-class": "pdg-service"
          }
        }), _vm._v(" " + _vm._s(scope.row.mappedParentEntity.title))], 1) : _vm._e()];
      }
    }], null, false, 540762462)
  }) : _vm._e(), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Last sync",
      sortable: "",
      width: "130"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v("\n          " + _vm._s(_vm.computeDateFormat(scope.row.externalsyncoutboundat)) + "\n        ")];
      }
    }], null, false, 3404387213)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      width: "100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-button-group", [scope.row.mappedStatus === "UNMAPPED" ? _c("el-button", {
          attrs: {
            type: "text",
            size: "micro",
            disabled: scope.row.loading.status,
            icon: scope.row.loading.status ? "el-icon-loading" : "el-icon-close",
            title: "Ignore"
          },
          on: {
            click: function click($event) {
              return _vm.updateTicketStatus(scope.row, "IGNORED");
            }
          }
        }) : _vm._e(), _vm._v(" "), scope.row.mappedStatus === "IGNORED" ? _c("el-button", {
          attrs: {
            type: "text",
            size: "micro",
            icon: scope.row.loading.status ? "el-icon-loading" : "",
            disabled: scope.row.loading.status,
            title: "Unmapped"
          },
          on: {
            click: function click($event) {
              return _vm.updateTicketStatus(scope.row, "UNMAPPED");
            }
          }
        }) : _vm._e(), _vm._v(" "), scope.row.mappedStatus !== "MAPPED" ? _c("el-button", {
          attrs: {
            type: "text",
            size: "micro",
            disabled: _vm.isImporting || scope.row.mappedStatus === "IGNORED",
            icon: scope.row.loading.status ? "el-icon-loading" : "el-icon-download",
            title: "Import"
          },
          on: {
            click: function click($event) {
              return _vm.importTicket(scope.row, "");
            }
          }
        }) : _vm._e()], 1), _vm._v(" "), scope.row.loading.status ? _c("div", [_vm._v(_vm._s(scope.row.loading.message))]) : _vm._e()];
      }
    }], null, false, 73063696)
  }, [_c("template", {
    slot: "header"
  }, [_vm.selectedTickets.length > 0 ? _c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.isImporting,
      expression: "isImporting"
    }],
    attrs: {
      size: "micro",
      type: "primary",
      disabled: _vm.isImporting,
      icon: _vm.isImporting ? "el-icon-loading" : "el-icon-download"
    },
    on: {
      click: function click($event) {
        return _vm.importSelectedTickets();
      }
    }
  }, [_vm._v(_vm._s(_vm.isImporting ? "Importing..." : "Import selected"))]) : _vm._e()], 1)], 2)], 1), _vm._v(" "), _c("el-pagination", {
    staticClass: "text-center",
    attrs: {
      layout: "prev, pager, next",
      small: true,
      "page-size": _vm.per_page_count,
      "hide-on-single-page": true,
      total: _vm.total_count
    },
    on: {
      "current-change": _vm.loadPage,
      "prev-click": _vm.loadPage,
      "next-click": _vm.loadPage
    }
  })], 1) : _c("el-empty", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading.status,
      expression: "loading.status"
    }],
    attrs: {
      description: "No tickets found"
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.importTickets();
      }
    }
  }, [_vm._v("Import")])], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;