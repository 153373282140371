"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    class: _vm.journeyMapStatus === "approved" ? "approved-background child-container" : "child-container"
  }, [_c("el-row", [_c("el-col", {
    staticClass: "text-center",
    attrs: {
      span: _vm.showSidebar ? 2 : 0
    }
  }, [_c("side-bar-component", {
    attrs: {
      "journey-map-status": _vm.journeyMapStatus
    },
    on: {
      "set-active-drawing-tool-drag-element": _vm.setActiveDrawingToolDragElement,
      "approve-journeymap": function approveJourneymap($event) {
        _vm.journeyMapStatus = "approved";
      },
      "revert-journeymap": function revertJourneymap($event) {
        _vm.journeyMapStatus = "unapproved";
      },
      "view-notes": function viewNotes($event) {
        return _vm.openNotesListDialog(null, 0);
      }
    }
  })], 1), _vm._v(" "), _c("el-col", {
    staticClass: "text-center pl-5",
    attrs: {
      span: _vm.showSidebar ? 22 : 24
    }
  }, [_c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      id: "journeyMapMatrixDiv"
    },
    on: {
      drop: _vm.handleDrop,
      dragover: _vm.handleDragOver
    }
  }, [_c("svg", {
    attrs: {
      height: _vm.lanes.length * 250,
      width: "100%"
    }
  }, [_c("g", {
    attrs: {
      refs: "sidebar_collapse_icon"
    }
  }, [_c("circle", {
    attrs: {
      cx: 21,
      cy: 21,
      r: "20",
      fill: "white",
      stroke: "#304174",
      "stroke-width": "2"
    }
  }), _vm._v(" "), _c("foreignObject", {
    attrs: {
      x: 2,
      y: 7,
      width: 40,
      height: 40
    }
  }, [_c("div", {
    attrs: {
      xmlns: "http://www.w3.org/1999/xhtml"
    }
  }, [_c("el-popover", {
    ref: "taskpopover",
    attrs: {
      placement: "top",
      title: "Sidebar Toggle",
      width: "380",
      trigger: "hover",
      "popper-class": "word-break-keep-all",
      "open-delay": 400,
      "close-delay": 400,
      content: "Toggle Sidebar"
    }
  }), _vm._v(" "), _c("i", {
    directives: [{
      name: "popover",
      rawName: "v-popover:taskpopover",
      arg: "taskpopover"
    }],
    class: _vm.showSidebar ? "el-icon-d-arrow-left" : "el-icon-d-arrow-right",
    staticStyle: {
      "font-size": "21pt",
      color: "#304174"
    },
    on: {
      click: function click($event) {
        _vm.showSidebar = !_vm.showSidebar;
      }
    }
  })], 1)])]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "lanes"
    }
  }, _vm._l(_vm.lanes, function (lane, index) {
    return _c("lane-component", {
      key: index,
      attrs: {
        lane: lane,
        index: index,
        "lane-param": _vm.layoutParam.lane,
        "lane-width": _vm.computeLaneWidth(),
        "note-count": _vm.computeNotesCountForLane(index)
      },
      on: {
        "open-notes-list": _vm.openNotesListDialog,
        "edit-lane": _vm.openEditLaneDialog,
        "delete-lane": _vm.deleteLane,
        "set-active-drawing-tool-drag-element": _vm.setActiveDrawingToolDragElement
      }
    });
  }), 1), _vm._v(" "), _c("g", {
    attrs: {
      refs: "phases"
    }
  }, _vm._l(_vm.phases, function (phase, index) {
    return _c("phase-component", {
      key: index,
      attrs: {
        phase: phase,
        index: index,
        "phase-param": _vm.layoutParam.phase,
        "note-count": _vm.computeNotesCountForPhase(index),
        "phase-height": _vm.computePhaseHeight(),
        "phase-progress": _vm.computeOverallPhaseProgress(index)
      },
      on: {
        "delete-phase": _vm.deletePhase,
        "edit-phase": _vm.openEditPhaseDialog,
        "open-notes-list": _vm.openNotesListDialog,
        "open-phase-progress-detail": _vm.openPhaseProgressDetailDialog
      }
    });
  }), 1), _vm._v(" "), _c("g", {
    attrs: {
      refs: "activities"
    }
  }, _vm._l(_vm.phases, function (phase, index) {
    return _c("g", {
      key: "phase_" + index
    }, _vm._l(phase.activities, function (activity, sub_index) {
      return _c("g", {
        key: "activity_" + sub_index,
        attrs: {
          refs: "activity"
        }
      }, [_c("activity-component", {
        attrs: {
          activity: activity,
          index: index,
          "sub-index": sub_index,
          width: _vm.layoutParam.phase.width / 1.05,
          height: _vm.layoutParam.phase.artefact_height,
          "activity-progress": _vm.computeOverallActivityProgress(index, sub_index),
          "bg-color": _vm.computeActivityBGColor(index, sub_index)
        },
        on: {
          "select-activity": _vm.selectActivity,
          "activity-progress": _vm.openActivityProgressDetailDialog,
          "create-goal": _vm.createGoal,
          "delete-activity": _vm.deleteActivity,
          "move-activity": _vm.startActivityMove,
          "mouse-over": _vm.activityMouseOver,
          "mouse-leave": _vm.activityMouseLeave
        }
      }), _vm._v(" "), _vm._l(activity.goals, function (goal, goal_sub_index) {
        return _c("g", {
          key: "goal_" + goal_sub_index,
          attrs: {
            refs: "goal"
          },
          on: {
            click: function click($event) {
              return _vm.openGoalDetailDialog(index, sub_index, goal_sub_index);
            }
          }
        }, [_c("goal-component", {
          attrs: {
            goal: goal,
            index: index,
            "sub-index": sub_index,
            "goal-sub-index": goal_sub_index,
            width: _vm.layoutParam.phase.width / 1.5,
            height: _vm.layoutParam.phase.title_height,
            "phase-param": _vm.layoutParam.phase,
            "selected-activity-index": _vm.selectedActivityIndex,
            "selected-phase-index": _vm.selectedPhaseIndex
          },
          on: {
            "delete-goal": _vm.deleteGoal
          }
        })], 1);
      }), _vm._v(" "), _vm._l(activity.experiences, function (experience, exp_sub_index) {
        return _c("g", {
          key: "exp_" + exp_sub_index,
          attrs: {
            refs: "experience"
          }
        }, [_c("experience-component", {
          attrs: {
            experience: experience,
            index: index,
            "sub-index": sub_index,
            "experience-sub-index": exp_sub_index,
            width: _vm.layoutParam.phase.width / 1.5,
            height: _vm.layoutParam.phase.title_height,
            "phase-param": _vm.layoutParam.phase,
            "selected-activity-index": _vm.selectedActivityIndex,
            "selected-phase-index": _vm.selectedPhaseIndex
          },
          on: {
            "delete-experience": _vm.deleteExperience
          }
        })], 1);
      }), _vm._v(" "), _vm._l(activity.process_areas, function (process_area, process_area_sub_index) {
        return _c("g", {
          key: "process_area_" + process_area_sub_index,
          attrs: {
            refs: "process_area"
          },
          on: {
            click: function click($event) {
              return _vm.openProcessAreaDetailDialog(index, sub_index, process_area_sub_index);
            }
          }
        }, [_c("process-area-component", {
          attrs: {
            "process-area": process_area,
            index: index,
            "sub-index": sub_index,
            "process-area-sub-index": process_area_sub_index,
            width: _vm.layoutParam.phase.width / 1.5,
            height: _vm.layoutParam.phase.title_height,
            "phase-param": _vm.layoutParam.phase,
            "selected-activity-index": _vm.selectedActivityIndex,
            "selected-phase-index": _vm.selectedPhaseIndex
          },
          on: {
            "delete-process-area": _vm.deleteProcessArea
          }
        })], 1);
      }), _vm._v(" "), _vm._l(activity.features, function (feature, feature_sub_index) {
        return _c("g", {
          key: "feature_" + feature_sub_index,
          attrs: {
            refs: "feature"
          }
        }, [_c("feature-component", {
          attrs: {
            feature: feature,
            index: index,
            "sub-index": sub_index,
            "feature-sub-index": feature_sub_index,
            width: _vm.layoutParam.phase.width / 1.5,
            height: _vm.layoutParam.phase.title_height,
            "phase-param": _vm.layoutParam.phase,
            "selected-activity-index": _vm.selectedActivityIndex,
            "selected-phase-index": _vm.selectedPhaseIndex
          },
          on: {
            "delete-feature": _vm.deleteFeature
          }
        })], 1);
      })], 2);
    }), 0);
  }), 0), _vm._v(" "), _c("g", {
    attrs: {
      refs: "notes"
    }
  }, _vm._l(_vm.unAttachedNotes, function (note, index) {
    return _c("note-component", {
      key: index,
      attrs: {
        note: note,
        index: index
      },
      on: {
        "note-move": _vm.moveNoteOnDrag
      }
    });
  }), 1)])])])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: _vm.generateNoteTitle(),
      visible: _vm.addNoteDialog,
      width: "30%",
      "append-to-body": "",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addNoteDialog = $event;
      }
    }
  }, [_c("el-form", {
    ref: "addNoteForm",
    staticClass: "mt-5 container",
    attrs: {
      model: _vm.note_form,
      "label-width": "120px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Note"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      placeholder: "Note"
    },
    model: {
      value: _vm.note_form.note,
      callback: function callback($$v) {
        _vm.$set(_vm.note_form, "note", $$v);
      },
      expression: "note_form.note"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Phase"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Phase"
    },
    model: {
      value: _vm.note_form.phase,
      callback: function callback($$v) {
        _vm.$set(_vm.note_form, "phase", $$v);
      },
      expression: "note_form.phase"
    }
  }, _vm._l(_vm.phases, function (phase) {
    return _c("el-option", {
      key: phase.name,
      attrs: {
        label: phase.name,
        value: phase.name
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Lane"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Lane"
    },
    model: {
      value: _vm.note_form.lane,
      callback: function callback($$v) {
        _vm.$set(_vm.note_form, "lane", $$v);
      },
      expression: "note_form.lane"
    }
  }, _vm._l(_vm.lanes, function (lane) {
    return _c("el-option", {
      key: lane.name,
      attrs: {
        label: lane.name,
        value: lane.name
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Note Color"
    }
  }, [_c("swatches", {
    attrs: {
      colors: "material-light"
    },
    model: {
      value: _vm.note_form.color,
      callback: function callback($$v) {
        _vm.$set(_vm.note_form, "color", $$v);
      },
      expression: "note_form.color"
    }
  })], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [!_vm.isNoteEditFlag ? _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.addNote
    }
  }, [_vm._v("Add")]) : _c("div", [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.closeNoteDialog
    }
  }, [_vm._v("Update")])], 1), _vm._v(" "), _c("el-button", {
    on: {
      click: _vm.closeNoteDialog
    }
  }, [_vm._v("Cancel")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Notes",
      visible: _vm.viewNotesDialog,
      width: "30%",
      "append-to-body": "",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.viewNotesDialog = $event;
      }
    }
  }, [_vm.notes.length > 0 ? _c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.activeNotes,
      stripe: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Color"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", {
          style: _vm.computeNoteTableBGStyle(scope.row.color)
        })];
      }
    }], null, false, 1191492461)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Note",
      prop: "note"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Phase",
      prop: "phase"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Lane",
      prop: "lane"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Operations"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-button", {
          attrs: {
            size: "mini",
            type: "danger"
          },
          on: {
            click: function click($event) {
              return _vm.deleteNoteByIndex(scope.$index, scope.row);
            }
          }
        }, [_vm._v("Delete")])];
      }
    }], null, false, 964990400)
  })], 1) : _c("div", {
    staticClass: "text-center"
  }, [_c("p", [_vm._v("No notes found")])]), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.viewNotesDialog = false;
      }
    }
  }, [_vm._v("Ok")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: _vm.computeLaneTitle(),
      visible: _vm.addLaneDialog,
      width: "30%",
      "append-to-body": "",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addLaneDialog = $event;
      }
    }
  }, [_c("el-form", {
    ref: "addLaneForm",
    staticClass: "mt-5 container",
    attrs: {
      model: _vm.lane_form,
      "label-width": "120px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Name"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "Enter Lane Name"
    },
    model: {
      value: _vm.lane_form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.lane_form, "name", $$v);
      },
      expression: "lane_form.name"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Description"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      placeholder: "Enter description"
    },
    model: {
      value: _vm.lane_form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.lane_form, "description", $$v);
      },
      expression: "lane_form.description"
    }
  })], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.addLaneDialog = false;
      }
    }
  }, [_vm._v("Cancel")]), _vm._v(" "), _vm.isLaneEdit ? _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.editLane
    }
  }, [_vm._v("Update")]) : _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.addLane
    }
  }, [_vm._v("Add")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: _vm.computePhaseTitle(),
      visible: _vm.addPhaseDialog,
      width: "30%",
      "append-to-body": "",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addPhaseDialog = $event;
      }
    }
  }, [_c("el-form", {
    ref: "addPhaseForm",
    staticClass: "mt-5 container",
    attrs: {
      model: _vm.phase_form,
      "label-width": "120px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Name"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "Enter Phase Name"
    },
    model: {
      value: _vm.phase_form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.phase_form, "name", $$v);
      },
      expression: "phase_form.name"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Description"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      placeholder: "Enter detailed description"
    },
    model: {
      value: _vm.phase_form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.phase_form, "description", $$v);
      },
      expression: "phase_form.description"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Goal"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "The end goal of this phase"
    },
    model: {
      value: _vm.phase_form.goal,
      callback: function callback($$v) {
        _vm.$set(_vm.phase_form, "goal", $$v);
      },
      expression: "phase_form.goal"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Challenges"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "Challenges faced"
    },
    model: {
      value: _vm.phase_form.challenges,
      callback: function callback($$v) {
        _vm.$set(_vm.phase_form, "challenges", $$v);
      },
      expression: "phase_form.challenges"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Emotions"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "Enter emotions"
    },
    model: {
      value: _vm.phase_form.emotions,
      callback: function callback($$v) {
        _vm.$set(_vm.phase_form, "emotions", $$v);
      },
      expression: "phase_form.emotions"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Priority"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.phase_form.priority,
      callback: function callback($$v) {
        _vm.$set(_vm.phase_form, "priority", $$v);
      },
      expression: "phase_form.priority"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: "Low",
      value: "Low"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Medium",
      value: "Medium"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "High",
      value: "High"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.addPhaseDialog = false;
      }
    }
  }, [_vm._v("Cancel")]), _vm._v(" "), _vm.isPhaseEdit ? _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.editPhase
    }
  }, [_vm._v("Update")]) : _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.addPhase
    }
  }, [_vm._v("Add")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Phase Progress",
      visible: _vm.phaseProgressDialog,
      width: "30%",
      "append-to-body": "",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.phaseProgressDialog = $event;
      }
    }
  }, [_c("table", {
    attrs: {
      width: "100%"
    }
  }, [_c("tr", [_c("td", {
    attrs: {
      width: "20%"
    }
  }, [_vm._v("Task")]), _vm._v(" "), _c("td", [_c("el-progress", {
    attrs: {
      "text-inside": true,
      "stroke-width": 20,
      percentage: _vm.computePhaseProgress("activity")
    }
  })], 1)]), _vm._v(" "), _c("tr", [_c("td", [_vm._v("Features")]), _vm._v(" "), _c("td", [_c("el-progress", {
    attrs: {
      "text-inside": true,
      "stroke-width": 20,
      percentage: _vm.computePhaseProgress("feature")
    }
  })], 1)]), _vm._v(" "), _c("tr", [_c("td", [_vm._v("Experiences")]), _vm._v(" "), _c("td", [_c("el-progress", {
    attrs: {
      "text-inside": true,
      "stroke-width": 20,
      percentage: _vm.computePhaseProgress("experience")
    }
  })], 1)]), _vm._v(" "), _c("tr", [_c("td", [_vm._v("Goals")]), _vm._v(" "), _c("td", [_c("el-progress", {
    attrs: {
      "text-inside": true,
      "stroke-width": 20,
      percentage: _vm.computePhaseProgress("goal")
    }
  })], 1)])]), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.phaseProgressDialog = false;
      }
    }
  }, [_vm._v("Ok")])], 1)]), _vm._v(" "), _c("el-dialog", {
    attrs: {
      visible: _vm.activityDetailsDialog,
      width: "50%",
      "append-to-body": "",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.activityDetailsDialog = $event;
      }
    }
  }, [_c("div", {
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_vm._v("\n      " + _vm._s(_vm.selectedActivity.task) + " Details\n    ")]), _vm._v(" "), _c("el-row", [_c("el-col", {
    attrs: {
      span: 20
    }
  }, [_c("h3", [_vm._v(_vm._s(_vm.selectedActivity.task) + " Details")])]), _vm._v(" "), _c("el-col", {
    staticClass: "text-right",
    attrs: {
      span: 4
    }
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "primary",
      icon: "el-icon-edit"
    },
    on: {
      click: function click($event) {
        $event.preventDefault();
        return _vm.openActivityDetailsDialog();
      }
    }
  }, [_vm._v("Edit Activity Details")])], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "bordered-container-fluid-height br-bottom-3pt"
  }, [_c("el-row", {
    staticClass: "pl-5 mt-5"
  }, [_c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("strong", [_vm._v("Intention")])]), _vm._v(" "), _c("el-col", {
    staticClass: "pr-5",
    attrs: {
      span: 18
    }
  }, [_vm._v("\n          " + _vm._s(_vm.selectedActivity.intention) + "\n        ")])], 1), _vm._v(" "), _c("el-row", {
    staticClass: "pl-5 mt-5"
  }, [_c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("strong", [_vm._v("Status")])]), _vm._v(" "), _c("el-col", {
    staticClass: "pr-5",
    attrs: {
      span: 18
    }
  }, [_c("el-tag", {
    attrs: {
      effect: "dark"
    }
  }, [_vm._v(_vm._s(_vm.selectedActivity.status))])], 1)], 1)], 1), _vm._v(" "), _c("el-divider"), _vm._v(" "), _c("el-row", [_c("el-col", {
    attrs: {
      span: 20
    }
  }, [_c("h3", [_vm._v(_vm._s(_vm.selectedActivity.task) + " Goals")])]), _vm._v(" "), _c("el-col", {
    staticClass: "text-right",
    attrs: {
      span: 4
    }
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "primary",
      icon: "el-icon-plus"
    },
    on: {
      click: function click($event) {
        _vm.addGoalDialog = true;
      }
    }
  }, [_vm._v("Add Goal")])], 1)], 1), _vm._v(" "), _vm.selectedActivity.goals.length > 0 ? _c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.selectedActivity.goals
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "name",
      label: "Name",
      width: "180"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "description",
      label: "Description"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Status",
      width: "180"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-tag", [_vm._v(_vm._s(scope.row.status))])];
      }
    }], null, false, 590099368)
  })], 1) : _c("p", [_vm._v("No Goals Found")]), _vm._v(" "), _c("el-divider"), _vm._v(" "), _c("el-row", [_c("el-col", {
    attrs: {
      span: 20
    }
  }, [_c("h3", [_vm._v(_vm._s(_vm.selectedActivity.task) + " Process Areas")])]), _vm._v(" "), _c("el-col", {
    staticClass: "text-right",
    attrs: {
      span: 4
    }
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "primary",
      icon: "el-icon-plus"
    },
    on: {
      click: function click($event) {
        _vm.addProcessAreaDialog = true;
      }
    }
  }, [_vm._v("Add Process Area")])], 1)], 1), _vm._v(" "), _vm.selectedActivity.process_areas && _vm.selectedActivity.process_areas.length > 0 ? _c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.selectedActivity.process_areas
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "name",
      label: "Name",
      width: "180"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "description",
      label: "Description"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Status",
      width: "180"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-tag", [_vm._v(_vm._s(scope.row.status))])];
      }
    }], null, false, 590099368)
  })], 1) : _c("p", [_vm._v("No Process Area Found")]), _vm._v(" "), _c("el-divider"), _vm._v(" "), _c("el-row", [_c("el-col", {
    attrs: {
      span: 20
    }
  }, [_c("h3", [_vm._v(_vm._s(_vm.selectedActivity.task) + " Experiences")])]), _vm._v(" "), _c("el-col", {
    staticClass: "text-right",
    attrs: {
      span: 4
    }
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "primary",
      icon: "el-icon-plus"
    },
    on: {
      click: function click($event) {
        return _vm.createExperience();
      }
    }
  }, [_vm._v("Add Experience")])], 1)], 1), _vm._v(" "), _vm.selectedActivity.experiences.length > 0 ? _c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.selectedActivity.experiences
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "name",
      label: "Name",
      width: "180"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "description",
      label: "Description"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "priority",
      label: "Priority"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "type",
      label: "Type"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Status",
      width: "180"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-tag", [_vm._v(_vm._s(scope.row.status))])];
      }
    }], null, false, 590099368)
  })], 1) : _c("p", [_vm._v("No Experience Found")]), _vm._v(" "), _c("el-divider"), _vm._v(" "), _c("el-row", [_c("el-col", {
    attrs: {
      span: 20
    }
  }, [_c("h3", [_vm._v(_vm._s(_vm.selectedActivity.task) + " Features")])]), _vm._v(" "), _c("el-col", {
    staticClass: "text-right",
    attrs: {
      span: 4
    }
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "primary",
      icon: "el-icon-plus"
    },
    on: {
      click: function click($event) {
        _vm.addFeatureDialog = true;
      }
    }
  }, [_vm._v("Add Feature")])], 1)], 1), _vm._v(" "), _vm.selectedActivity.features.length > 0 ? _c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.selectedActivity.features
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "name",
      label: "Name",
      width: "180"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "description",
      label: "Description"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "experience_type",
      label: "Experience",
      width: "180"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Status",
      width: "180"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-tag", [_vm._v(_vm._s(scope.row.status))])];
      }
    }], null, false, 590099368)
  })], 1) : _c("p", [_vm._v("No Feature Found")]), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.activityDetailsDialog = false;
      }
    }
  }, [_vm._v("Close")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Activity Progress",
      visible: _vm.activityProgressDialog,
      width: "30%",
      "append-to-body": "",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.activityProgressDialog = $event;
      }
    }
  }, [_c("table", {
    attrs: {
      width: "100%"
    }
  }, [_c("tr", [_c("td", {
    attrs: {
      width: "20%"
    }
  }, [_vm._v("Features")]), _vm._v(" "), _c("td", [_c("el-progress", {
    attrs: {
      "text-inside": true,
      "stroke-width": 20,
      percentage: _vm.computeActivityProgress("feature")
    }
  })], 1)]), _vm._v(" "), _c("tr", [_c("td", [_vm._v("Experiences")]), _vm._v(" "), _c("td", [_c("el-progress", {
    attrs: {
      "text-inside": true,
      "stroke-width": 20,
      percentage: _vm.computeActivityProgress("experience")
    }
  })], 1)]), _vm._v(" "), _c("tr", [_c("td", [_vm._v("Process Area")]), _vm._v(" "), _c("td", [_c("el-progress", {
    attrs: {
      "text-inside": true,
      "stroke-width": 20,
      percentage: _vm.computeActivityProgress("process_area")
    }
  })], 1)]), _vm._v(" "), _c("tr", [_c("td", [_vm._v("Goals")]), _vm._v(" "), _c("td", [_c("el-progress", {
    attrs: {
      "text-inside": true,
      "stroke-width": 20,
      percentage: _vm.computeActivityProgress("goal")
    }
  })], 1)])]), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.activityProgressDialog = false;
      }
    }
  }, [_vm._v("Ok")])], 1)]), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: _vm.computeGoalTitle(),
      visible: _vm.addGoalDialog,
      width: "30%",
      "append-to-body": "",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addGoalDialog = $event;
      }
    }
  }, [_c("el-form", {
    ref: "addGoalForm",
    staticClass: "mt-5 container",
    attrs: {
      model: _vm.goal_form,
      "label-width": "120px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Name"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "Enter Goal Name"
    },
    model: {
      value: _vm.goal_form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.goal_form, "name", $$v);
      },
      expression: "goal_form.name"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Description"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      placeholder: "Enter detailed description"
    },
    model: {
      value: _vm.goal_form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.goal_form, "description", $$v);
      },
      expression: "goal_form.description"
    }
  })], 1), _vm._v(" "), _vm.isActivityNull ? _c("el-form-item", {
    attrs: {
      label: "Activity"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Select"
    },
    model: {
      value: _vm.activeActivityIndex,
      callback: function callback($$v) {
        _vm.activeActivityIndex = $$v;
      },
      expression: "activeActivityIndex"
    }
  }, _vm._l(_vm.phases[_vm.activePhaseIndex].activities, function (activity, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: activity.task,
        value: index
      }
    });
  }), 1)], 1) : _vm._e(), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Status"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.goal_form.status,
      callback: function callback($$v) {
        _vm.$set(_vm.goal_form, "status", $$v);
      },
      expression: "goal_form.status"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: "Completed",
      value: "Completed"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "InProgress",
      value: "InProgress"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Pending",
      value: "Pending"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.addGoalDialog = false;
      }
    }
  }, [_vm._v("Cancel")]), _vm._v(" "), !_vm.isGoalModalEditMode ? _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.addGoal
    }
  }, [_vm._v("Add")]) : _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.updateGoal
    }
  }, [_vm._v("Update")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: _vm.computeTaskTitle(),
      visible: _vm.addTaskDialog,
      width: "30%",
      "append-to-body": "",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addTaskDialog = $event;
      }
    }
  }, [_c("el-form", {
    ref: "addTaskForm",
    staticClass: "mt-5 container",
    attrs: {
      model: _vm.task_form,
      "label-width": "120px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Name"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "Enter Task Name"
    },
    model: {
      value: _vm.task_form.task,
      callback: function callback($$v) {
        _vm.$set(_vm.task_form, "task", $$v);
      },
      expression: "task_form.task"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Description"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      placeholder: "Enter detailed description"
    },
    model: {
      value: _vm.task_form.intention,
      callback: function callback($$v) {
        _vm.$set(_vm.task_form, "intention", $$v);
      },
      expression: "task_form.intention"
    }
  })], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.addTaskDialog = false;
      }
    }
  }, [_vm._v("Cancel")]), _vm._v(" "), !_vm.isTaskModalEditMode ? _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.addTask
    }
  }, [_vm._v("Add")]) : _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.updateTask
    }
  }, [_vm._v("Update")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: _vm.computeProcessAreaTitle(),
      visible: _vm.addProcessAreaDialog,
      width: "30%",
      "append-to-body": "",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addProcessAreaDialog = $event;
      }
    }
  }, [_c("el-form", {
    ref: "addProcessAreaForm",
    staticClass: "mt-5 container",
    attrs: {
      model: _vm.process_area_form,
      "label-width": "120px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Name"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "Enter Process Area Name"
    },
    model: {
      value: _vm.process_area_form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.process_area_form, "name", $$v);
      },
      expression: "process_area_form.name"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Description"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      placeholder: "Enter detailed description"
    },
    model: {
      value: _vm.process_area_form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.process_area_form, "description", $$v);
      },
      expression: "process_area_form.description"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Status"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.process_area_form.status,
      callback: function callback($$v) {
        _vm.$set(_vm.process_area_form, "status", $$v);
      },
      expression: "process_area_form.status"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: "Completed",
      value: "Completed"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "InProgress",
      value: "InProgress"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Pending",
      value: "Pending"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.addProcessAreaDialog = false;
      }
    }
  }, [_vm._v("Cancel")]), _vm._v(" "), !_vm.isProcessAreaModalEditMode ? _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.addProcessArea
    }
  }, [_vm._v("Add")]) : _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.updateProcessArea
    }
  }, [_vm._v("Update")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Add Experience",
      visible: _vm.addExperienceDialog,
      width: "30%",
      "append-to-body": "",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addExperienceDialog = $event;
      }
    }
  }, [_c("el-form", {
    ref: "addExperienceForm",
    staticClass: "mt-5 container",
    attrs: {
      model: _vm.experience_form,
      "label-width": "120px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Name"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "Enter Goal Name"
    },
    model: {
      value: _vm.experience_form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.experience_form, "name", $$v);
      },
      expression: "experience_form.name"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Description"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      placeholder: "Enter detailed description"
    },
    model: {
      value: _vm.experience_form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.experience_form, "description", $$v);
      },
      expression: "experience_form.description"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Priority"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.experience_form.priority,
      callback: function callback($$v) {
        _vm.$set(_vm.experience_form, "priority", $$v);
      },
      expression: "experience_form.priority"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: "Low",
      value: "Low"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Medium",
      value: "Medium"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "High",
      value: "High"
    }
  })], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Type"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.experience_form.type,
      callback: function callback($$v) {
        _vm.$set(_vm.experience_form, "type", $$v);
      },
      expression: "experience_form.type"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: "pain",
      value: "pain"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "gain",
      value: "gain"
    }
  })], 1)], 1), _vm._v(" "), _vm.isActivityNull ? _c("el-form-item", {
    attrs: {
      label: "Activity"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Select"
    },
    model: {
      value: _vm.activeActivityIndex,
      callback: function callback($$v) {
        _vm.activeActivityIndex = $$v;
      },
      expression: "activeActivityIndex"
    }
  }, _vm._l(_vm.phases[_vm.activePhaseIndex].activities, function (activity, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: activity.task,
        value: index
      }
    });
  }), 1)], 1) : _vm._e(), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Status"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.experience_form.status,
      callback: function callback($$v) {
        _vm.$set(_vm.experience_form, "status", $$v);
      },
      expression: "experience_form.status"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: "Completed",
      value: "Completed"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "InProgress",
      value: "InProgress"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Pending",
      value: "Pending"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.addExperienceDialog = false;
      }
    }
  }, [_vm._v("Cancel")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.addExperience
    }
  }, [_vm._v("Add")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Add Feature",
      visible: _vm.addFeatureDialog,
      width: "30%",
      "append-to-body": "",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addFeatureDialog = $event;
      }
    }
  }, [_c("el-form", {
    ref: "addFeatureForm",
    staticClass: "mt-5 container",
    attrs: {
      model: _vm.feature_form,
      "label-width": "150px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Name"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "Enter Goal Name"
    },
    model: {
      value: _vm.feature_form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.feature_form, "name", $$v);
      },
      expression: "feature_form.name"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Description"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      placeholder: "Enter detailed description"
    },
    model: {
      value: _vm.feature_form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.feature_form, "description", $$v);
      },
      expression: "feature_form.description"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Experience Type"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.feature_form.experience_type,
      callback: function callback($$v) {
        _vm.$set(_vm.feature_form, "experience_type", $$v);
      },
      expression: "feature_form.experience_type"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: "pain",
      value: "pain"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "gain",
      value: "gain"
    }
  })], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Experience Name"
    }
  }, [_vm.phases[_vm.activePhaseIndex].activities.length > 0 ? _c("el-select", {
    attrs: {
      placeholder: "Select"
    },
    model: {
      value: _vm.feature_form.experience_name,
      callback: function callback($$v) {
        _vm.$set(_vm.feature_form, "experience_name", $$v);
      },
      expression: "feature_form.experience_name"
    }
  }, _vm._l(_vm.phases[_vm.activePhaseIndex].activities[_vm.activeActivityIndex].experiences, function (experience) {
    return _c("el-option", {
      key: experience.name,
      attrs: {
        label: experience.name,
        value: experience.name
      }
    });
  }), 1) : _vm._e()], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Status"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Select"
    },
    model: {
      value: _vm.feature_form.status,
      callback: function callback($$v) {
        _vm.$set(_vm.feature_form, "status", $$v);
      },
      expression: "feature_form.status"
    }
  }, _vm._l(_vm.featureStatus, function (status) {
    return _c("el-option", {
      key: status.value,
      attrs: {
        label: status.label,
        value: status.value
      }
    });
  }), 1)], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.addFeatureDialog = false;
      }
    }
  }, [_vm._v("Cancel")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.addFeature
    }
  }, [_vm._v("Add")])], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;