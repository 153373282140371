"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-container"
  }, [_vm.selectedOffering && _vm.selectedOffering.id && _vm.activeRoute === "roadmap" ? _c("roadmap-component", {
    attrs: {
      "offering-id": _vm.selectedOffering.id
    }
  }) : _vm._e(), _vm._v(" "), _vm.selectedOffering && _vm.selectedOffering.id && _vm.activeRoute === "currentrelease" ? _c("current-release-component", {
    attrs: {
      "offering-id": _vm.selectedOffering.id
    }
  }) : _vm._e(), _vm._v(" "), _vm.selectedOffering && _vm.selectedOffering.id && _vm.activeRoute === "pastrelease" ? _c("release-history-component", {
    attrs: {
      "offering-id": _vm.selectedOffering.id
    }
  }) : _vm._e(), _vm._v(" "), _vm.selectedOffering && _vm.selectedOffering.id && _vm.activeRoute === "integrateddata" ? _c("integrated-data-component", {
    attrs: {
      offering: _vm.selectedOffering
    }
  }) : _vm._e(), _vm._v(" "), _vm.selectedOffering && _vm.selectedOffering.id && _vm.activeRoute === "risk" ? _c("risk-component", {
    attrs: {
      offering: _vm.selectedOffering
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;