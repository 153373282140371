"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _axios = _interopRequireDefault(require("axios"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _default = exports.default = {
  name: 'Groups',
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      loading: false,
      createGroupDialog: false,
      editGroupDialog: false,
      addRoleDialog: false,
      addSubGroupDialog: false,
      activeGroup: {},
      activeGroupId: '',
      searchGroups: '',
      groups: [],
      roles: [],
      listQuery: {
        page: 1,
        limit: 30
      },
      total: 0,
      link_group_form: {
        groups: []
      },
      role_form: {
        roles: []
      },
      group_form: {
        title: '',
        description: '',
        roles: []
      }
    };
  },
  computed: {
    filteredGroupList: function filteredGroupList() {
      var self = this;
      return this.groups.filter(function (group) {
        return group.title.indexOf(self.searchGroups) > -1;
      });
    }
  },
  created: function created() {},
  mounted: function mounted() {
    this.getGroupList({
      page: 1,
      limit: 30
    });
    this.getRoleList();
  },
  methods: {
    linkSubGroupDialog: function linkSubGroupDialog(group_id) {
      this.activeGroupId = group_id;
      this.link_group_form.groups = [];
      this.addSubGroupDialog = true;
    },
    linkSubGroup: function linkSubGroup() {
      var _this = this;
      this.loading = true;
      var headers = {
        'Content-Type': 'application/json'
      };
      var body = {
        'data': this.generationGroupData(this.link_group_form.groups),
        'header': {
          'userId': this.$store.getters.id,
          'partyId': this.$store.getters.partyId
        }
      };
      _axios.default.patch(process.env.VUE_APP_REST_API_ENDPOINT + 'security/group/' + this.activeGroupId + '/links', body, {
        headers: headers
      }).then(function (response) {
        _this.loading = false;
        if (response.data.status === 'SUCCESS') {
          _this.$notify({
            title: 'Success',
            message: 'Roles Linked',
            type: 'success',
            duration: 2000
          });
          _this.getGroupList({
            page: 1,
            limit: 30
          });
          _this.addSubGroupDialog = false;
        } else {
          _this.$notify({
            title: 'Error',
            message: response.data.data.description,
            type: 'error',
            duration: 2000
          });
        }
      }).catch(function (error) {
        _this.loading = false;
        _this.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
      });
    },
    unlinkSubGroup: function unlinkSubGroup(group_id, sub_group_id) {
      this.activeGroupId = group_id;
      this.loading = true;
      var body = {
        'header': {
          'userId': this.$store.getters.id,
          'partyId': this.$store.getters.partyId
        },
        'data': [{
          'id': sub_group_id
        }]
      };
      var that = this;
      var xhr = new XMLHttpRequest();
      xhr.open('DELETE', process.env.VUE_APP_REST_API_ENDPOINT + 'security/group/' + this.activeGroupId + '/links', true);

      // Send the proper header information along with the request
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.send(JSON.stringify(body));
      xhr.onreadystatechange = function () {
        // Call a function when the state changes.
        if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
          // Request finished. Do processing here.
          that.$notify({
            title: 'Success',
            message: 'Group unlinked succesfully',
            type: 'success',
            duration: 2000
          });
          that.getGroupList({
            page: 1,
            limit: 30
          });
          that.loading = false;
        }
      };
      xhr.onload = function () {};
    },
    generationGroupData: function generationGroupData(groups) {
      var group_list = [];
      for (var i = 0; i < groups.length; i++) {
        group_list.push({
          id: groups[i]
        });
      }
      return group_list;
    },
    generationRoleData: function generationRoleData(roles) {
      var role_list = [];
      for (var i = 0; i < roles.length; i++) {
        role_list.push({
          id: roles[i]
        });
      }
      return role_list;
    },
    linkRole: function linkRole() {
      var _this2 = this;
      this.loading = true;
      var headers = {
        'Content-Type': 'application/json'
      };
      var body = {
        'data': this.generationRoleData(this.role_form.roles),
        'header': {
          'userId': this.$store.getters.id,
          'partyId': this.$store.getters.partyId
        }
      };
      _axios.default.patch(process.env.VUE_APP_REST_API_ENDPOINT + 'security/group/' + this.activeGroupId + '/roles', body, {
        headers: headers
      }).then(function (response) {
        _this2.loading = false;
        if (response.data.status === 'SUCCESS') {
          _this2.$notify({
            title: 'Success',
            message: 'Roles Linked',
            type: 'success',
            duration: 2000
          });
          _this2.getGroupList({
            page: 1,
            limit: 30
          });
          _this2.addRoleDialog = false;
        } else {
          _this2.$notify({
            title: 'Error',
            message: response.data.data.description,
            type: 'error',
            duration: 2000
          });
        }
      }).catch(function (error) {
        _this2.loading = false;
        _this2.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
      });
    },
    openAddRoleDialog: function openAddRoleDialog(role_id, role, index) {
      this.activeGroupId = role_id;
      this.group_form.roles = [];
      this.role_form.roles = [];
      this.addRoleDialog = true;
    },
    createGroup: function createGroup() {
      var _this3 = this;
      this.loading = true;
      var headers = {
        'Content-Type': 'application/json'
      };
      var body = {
        'data': [{
          'title': this.group_form.title,
          'description': this.group_form.description
        }],
        'header': {
          'userId': this.$store.getters.id,
          'partyId': this.$store.getters.partyId
        }
      };
      _axios.default.post(process.env.VUE_APP_REST_API_ENDPOINT + 'security/group', body, {
        headers: headers
      }).then(function (response) {
        _this3.loading = false;
        _this3.response = response.data;
        if (response.data.status === 'SUCCESS') {
          _this3.$notify({
            title: 'Success',
            message: 'Group Created',
            type: 'success',
            duration: 2000
          });
          _this3.attachRoleToGroup(response.data.data.id);
        } else {
          _this3.$notify({
            title: 'Error',
            message: response.data.data.description,
            type: 'error',
            duration: 2000
          });
        }
      }).catch(function (error) {
        _this3.loading = false;
        _this3.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
      });
      this.createGroupDialog = false;
    },
    attachRoleToGroup: function attachRoleToGroup(group_id) {
      var _this4 = this;
      this.activeGroupId = group_id;
      this.loading = true;
      var headers = {
        'Content-Type': 'application/json'
      };
      var body = {
        'data': this.generationRoleData(this.group_form.roles),
        'header': {
          'userId': this.$store.getters.id,
          'partyId': this.$store.getters.partyId
        }
      };
      _axios.default.patch(process.env.VUE_APP_REST_API_ENDPOINT + 'security/group/' + this.activeGroupId + '/roles', body, {
        headers: headers
      }).then(function (response) {
        _this4.loading = false;
        if (response.data.status === 'SUCCESS') {
          _this4.$notify({
            title: 'Success',
            message: 'Roles Linked',
            type: 'success',
            duration: 2000
          });
          _this4.getGroupList({
            page: 1,
            limit: 30
          });
          _this4.resetGroupForm();
        } else {
          _this4.$notify({
            title: 'Error',
            message: response.data.data.description,
            type: 'error',
            duration: 2000
          });
        }
      }).catch(function (error) {
        _this4.loading = false;
        _this4.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
      });
    },
    updateGroup: function updateGroup() {
      var _this5 = this;
      this.loading = true;
      var headers = {
        'Content-Type': 'application/json'
      };
      var body = {
        'data': {
          'title': this.group_form.title,
          'description': this.group_form.description
        },
        'header': {
          'userId': this.$store.getters.id,
          'partyId': this.$store.getters.partyId
        }
      };
      _axios.default.patch(process.env.VUE_APP_REST_API_ENDPOINT + 'security/group/' + this.activeGroup.id, body, {
        headers: headers
      }).then(function (response) {
        _this5.loading = false;
        if (response.data.status === 'SUCCESS') {
          _this5.$notify({
            title: 'Success',
            message: 'Group Updated',
            type: 'success',
            duration: 2000
          });
          _this5.getGroupList({
            page: 1,
            limit: 30
          });
          _this5.editGroupDialog = false;
        } else {
          _this5.$notify({
            title: 'Error',
            message: response.data.data.description,
            type: 'error',
            duration: 2000
          });
        }
      }).catch(function (error) {
        _this5.loading = false;
        _this5.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
      });
    },
    editRole: function editRole(group) {
      this.editGroupDialog = true;
      this.activeGroup = group;
      this.group_form.title = group.title;
      this.group_form.description = group.description;
    },
    deleteRole: function deleteRole(group, role) {
      this.activeGroupId = group.id;
      this.loading = true;
      var body = {
        'header': {
          'userId': this.$store.getters.id,
          'partyId': this.$store.getters.partyId
        },
        'data': [{
          'id': role.role.id
        }]
      };
      var that = this;
      var xhr = new XMLHttpRequest();
      xhr.open('DELETE', process.env.VUE_APP_REST_API_ENDPOINT + 'security/group/' + this.activeGroupId + '/roles', true);

      // Send the proper header information along with the request
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.send(JSON.stringify(body));
      xhr.onreadystatechange = function () {
        // Call a function when the state changes.
        if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
          // Request finished. Do processing here.
          that.$notify({
            title: 'Success',
            message: 'Role deleted succesfully',
            type: 'success',
            duration: 2000
          });
          that.getGroupList({
            page: 1,
            limit: 30
          });
          that.loading = false;
        }
      };
      xhr.onload = function () {};
    },
    deleteGroup: function deleteGroup(group_id) {
      this.loading = true;
      var body = {
        'header': {
          'userId': this.$store.getters.id,
          'partyId': this.$store.getters.partyId
        }
      };
      var that = this;
      var xhr = new XMLHttpRequest();
      xhr.open('DELETE', process.env.VUE_APP_REST_API_ENDPOINT + 'security/group/' + group_id, true);

      // Send the proper header information along with the request
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.send(JSON.stringify(body));
      xhr.onreadystatechange = function () {
        // Call a function when the state changes.
        if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
          // Request finished. Do processing here.
          that.$notify({
            title: 'Success',
            message: 'Group deleted succesfully',
            type: 'success',
            duration: 2000
          });
          that.getGroupList({
            page: 1,
            limit: 30
          });
          that.loading = false;
        }
      };
      xhr.onload = function () {};
    },
    getRoleList: function getRoleList() {
      var _this6 = this;
      this.loading = true;
      var headers = {
        'Content-Type': 'application/json',
        'x-party-id': this.$store.getters.partyId,
        'x-user-id': this.$store.getters.id
      };
      _axios.default.get(process.env.VUE_APP_REST_API_ENDPOINT + 'security/role', {
        headers: headers
      }).then(function (response) {
        _this6.loading = false;
        if (response.data.status === 'SUCCESS') {
          _this6.roles = response.data.data;
        } else {
          _this6.$notify({
            title: 'Error',
            message: response.data.data.description,
            type: 'error',
            duration: 2000
          });
        }
      }).catch(function (error) {
        _this6.loading = false;
        _this6.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
      });
    },
    getGroupList: function getGroupList(pagination) {
      var _this7 = this;
      var temp_pagination = Object.assign({}, pagination);
      var page_no = parseInt(temp_pagination.page) - 1;
      this.groups = [];
      this.loading = true;
      var headers = {
        'Content-Type': 'application/json',
        'x-party-id': this.$store.getters.partyId,
        'x-user-id': this.$store.getters.id
      };
      _axios.default.get(process.env.VUE_APP_REST_API_ENDPOINT + 'security/group?page=' + page_no, {
        headers: headers
      }).then(function (response) {
        _this7.loading = false;
        if (response.data.status === 'SUCCESS') {
          _this7.total = response.data.pageInfo.totalPages * response.data.pageInfo.itemsPerPage;
          _this7.groups = response.data.data;
        } else {
          _this7.$notify({
            title: 'Error',
            message: response.data.data.description,
            type: 'error',
            duration: 2000
          });
        }
      }).catch(function (error) {
        _this7.loading = false;
        _this7.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
      });
    },
    resetGroupForm: function resetGroupForm() {
      this.group_form = {
        title: '',
        description: '',
        roles: []
      };
    },
    resetRoleForm: function resetRoleForm() {
      this.group_form = {
        roles: []
      };
    }
  }
};