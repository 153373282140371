"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-tabs", {
    on: {
      "tab-click": _vm.onTabClick
    },
    model: {
      value: _vm.tabname,
      callback: function callback($$v) {
        _vm.tabname = $$v;
      },
      expression: "tabname"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "Step Details",
      name: "step_details"
    }
  }, [_c("el-form", {
    ref: "addStepform",
    attrs: {
      rules: _vm.addSteprules,
      model: _vm.step_form,
      "label-width": "150px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Title",
      prop: "title"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.step_form.title,
      callback: function callback($$v) {
        _vm.$set(_vm.step_form, "title", $$v);
      },
      expression: "step_form.title"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Description",
      prop: "description"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini",
      type: "textarea",
      rows: "6"
    },
    model: {
      value: _vm.step_form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.step_form, "description", $$v);
      },
      expression: "step_form.description"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Priority",
      prop: "priority"
    }
  }, [_c("el-radio-group", {
    model: {
      value: _vm.step_form.priority,
      callback: function callback($$v) {
        _vm.$set(_vm.step_form, "priority", $$v);
      },
      expression: "step_form.priority"
    }
  }, [_c("el-radio", {
    attrs: {
      label: "Very High"
    }
  }), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "High"
    }
  }), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "Medium"
    }
  }), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "Low"
    }
  }), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "Very Low"
    }
  })], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Value",
      prop: "importance"
    }
  }, [_c("el-radio-group", {
    model: {
      value: _vm.step_form.importance,
      callback: function callback($$v) {
        _vm.$set(_vm.step_form, "importance", $$v);
      },
      expression: "step_form.importance"
    }
  }, [_c("el-radio", {
    attrs: {
      label: "Very High"
    }
  }), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "High"
    }
  }), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "Medium"
    }
  }), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "Low"
    }
  }), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "Very Low"
    }
  })], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Step Goal",
      prop: "goal"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini",
      type: "textarea",
      rows: "4"
    },
    model: {
      value: _vm.step_form.goal,
      callback: function callback($$v) {
        _vm.$set(_vm.step_form, "goal", $$v);
      },
      expression: "step_form.goal"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: "Experiences",
      name: "step_experiences"
    }
  }, [_c("div", {
    staticStyle: {
      "background-color": "lightslategrey",
      color: "white",
      "border-radius": "4pt",
      height: "24pt",
      "padding-top": "4pt"
    }
  }, [_c("span", {
    staticStyle: {
      "padding-left": "8pt"
    }
  }, [_vm._v("Step Experience")]), _vm._v(" "), _c("el-dropdown", {
    staticClass: "fr",
    on: {
      command: _vm.handleDropDownCommand
    }
  }, [_c("el-button", {
    staticStyle: {
      padding: "3pt 5pt 3pt 8pt",
      top: "-4pt",
      "margin-right": "6pt"
    },
    attrs: {
      type: "Plain",
      size: "mini"
    }
  }, [_vm._v("\n            Add Experience "), _c("i", {
    staticClass: "el-icon-arrow-down el-icon--right"
  })]), _vm._v(" "), _c("el-dropdown-menu", {
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, [_c("el-dropdown-item", {
    attrs: {
      command: "createExperienceGainFromTask"
    }
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "14px",
      color: "green",
      "margin-right": "4pt"
    },
    attrs: {
      "icon-class": "smiley"
    }
  }), _vm._v("Gain")], 1), _vm._v(" "), _c("el-dropdown-item", {
    attrs: {
      command: "createExperiencePainFromTask"
    }
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "14px",
      color: "green",
      "margin-right": "4pt"
    },
    attrs: {
      "icon-class": "danger-circle"
    }
  }), _vm._v("Pain")], 1), _vm._v(" "), _c("el-dropdown-item", {
    attrs: {
      command: "createExperienceWowFromTask"
    }
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "14px",
      color: "green",
      "margin-right": "4pt"
    },
    attrs: {
      "icon-class": "wow_blue"
    }
  }), _vm._v("Wow")], 1), _vm._v(" "), _c("el-dropdown-item", {
    attrs: {
      command: "createExperienceChallengeFromTask"
    }
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "14px",
      color: "green",
      "margin-right": "4pt"
    },
    attrs: {
      "icon-class": "oscurity"
    }
  }), _vm._v("Obscurity")], 1)], 1)], 1)], 1), _vm._v(" "), _c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.filteredStepRelatedExperience,
      size: "mini",
      fit: true
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Type",
      width: "53"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.journeyExperience.experiencetype === "GAIN" ? _c("svg-icon", {
          staticStyle: {
            "font-size": "22px",
            color: "green"
          },
          attrs: {
            "icon-class": "smiley"
          }
        }) : _vm._e(), _vm._v(" "), scope.row.journeyExperience.experiencetype === "PAIN" ? _c("svg-icon", {
          staticStyle: {
            "font-size": "22px"
          },
          attrs: {
            "icon-class": "danger-circle"
          }
        }) : _vm._e(), _vm._v(" "), scope.row.journeyExperience.experiencetype === "WOW" ? _c("svg-icon", {
          staticStyle: {
            "font-size": "22px"
          },
          attrs: {
            "icon-class": "wow_blue"
          }
        }) : _vm._e(), _vm._v(" "), scope.row.journeyExperience.experiencetype === "CHALLENGE" ? _c("svg-icon", {
          staticStyle: {
            "font-size": "22px"
          },
          attrs: {
            "icon-class": "oscurity"
          }
        }) : _vm._e()];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "journeyExperience.title",
      label: "Title",
      width: "280"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "journeyExperience.description",
      label: "Experience Description"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "journeyExperience.additionalattributes.customer_value",
      label: "Value",
      width: "80"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "journeyExperience.priority",
      label: "Priority",
      width: "80"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "",
      width: "160"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-button", {
          staticStyle: {
            float: "right"
          },
          attrs: {
            size: "mini",
            type: "danger",
            icon: "el-icon-delete",
            circle: ""
          },
          on: {
            click: function click($event) {
              return _vm.deleteExperience(scope.row.journeyExperience.id);
            }
          }
        }), _vm._v(" "), _c("el-button", {
          staticClass: "mr-5 ml-5",
          staticStyle: {
            float: "right"
          },
          attrs: {
            size: "mini",
            type: "secondary",
            icon: "el-icon-edit",
            circle: ""
          },
          on: {
            click: function click($event) {
              return _vm.editExperienceDetails(scope.$index, scope.row.journeyExperience);
            }
          }
        })];
      }
    }])
  })], 1)], 1), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: "Tickets & Progress",
      name: "step_progress"
    }
  }, [_vm.offerings.length === 0 ? _c("el-empty", {
    attrs: {
      description: "Tracking unavailable"
    }
  }) : _vm.offerings.length === 1 ? _c("div", [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("table", {
    attrs: {
      id: "offering_details"
    }
  }, [_c("tr", [_c("th", [_vm._v("Title")]), _vm._v(" "), _c("td", [_c("el-tag", {
    attrs: {
      size: "mini"
    }
  }, [_vm._v(_vm._s(_vm.offerings[0].offering.title))])], 1)]), _vm._v(" "), _c("tr", [_c("th", [_vm._v("Category")]), _vm._v(" "), _c("td", [_c("el-tag", {
    attrs: {
      size: "mini"
    }
  }, [_vm._v(_vm._s(_vm.offerings[0].offering.offeringcategory))])], 1)])])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("div", {
    staticClass: "text-center"
  }, [_vm._v("Progress")]), _vm._v(" "), _c("div", {
    staticClass: "text-center"
  }, [_c("pie-chart-component", {
    staticClass: "text-center",
    attrs: {
      status: _vm.offerings[0].offering.external_status,
      height: "200px",
      width: "200px"
    }
  })], 1)])], 1)], 1) : _vm.offerings.length > 1 ? _c("el-tabs", {
    attrs: {
      "tab-position": "left"
    }
  }, _vm._l(_vm.offerings, function (offering, index) {
    return _c("el-tab-pane", {
      key: "offering" + index,
      attrs: {
        label: _vm.trimString(offering.offering.title, 20)
      }
    }, [_c("el-row", {
      attrs: {
        gutter: 20
      }
    }, [_c("el-col", {
      attrs: {
        span: 6
      }
    }, [_c("table", {
      attrs: {
        id: "offering_details"
      }
    }, [_c("tr", [_c("th", [_vm._v("Offering")]), _vm._v(" "), _c("td", [_c("el-tag", {
      attrs: {
        size: "mini"
      }
    }, [_vm._v(_vm._s(_vm.trimString(offering.offering.title, 50)))])], 1)]), _vm._v(" "), _c("tr", [_c("th", [_vm._v("Variant")]), _vm._v(" "), _c("td", [_c("el-tag", {
      attrs: {
        size: "mini"
      }
    }, [_vm._v(_vm._s(offering.offering.offeringcategory))])], 1)]), _vm._v(" "), _c("tr", [_c("th", [_vm._v("Created by")]), _vm._v(" "), _c("td", [_c("el-tag", {
      attrs: {
        size: "mini"
      }
    }, [_vm._v(_vm._s(offering.offering.createdby))])], 1)])])]), _vm._v(" "), _c("el-col", {
      attrs: {
        span: 12
      }
    }, [_c("table", {
      attrs: {
        id: "offering_details"
      }
    }, [_c("tr", [_c("th", {
      attrs: {
        colspan: "3"
      }
    }, [_vm._v("Progress")])]), _vm._v(" "), _c("tr", [_c("th", [_vm._v("Backlog")]), _vm._v(" "), _c("td", [_c("el-tag", {
      attrs: {
        size: "mini"
      }
    }, [_vm._v(_vm._s(offering.offering.external_status[0]))])], 1), _vm._v(" "), _c("td", {
      attrs: {
        rowspan: "12"
      }
    }, [_c("pie-chart-component", {
      staticClass: "text-center",
      attrs: {
        status: offering.offering.external_status,
        height: "200px",
        width: "200px"
      }
    })], 1)]), _vm._v(" "), _c("tr", [_c("th", [_vm._v("Candidate")]), _vm._v(" "), _c("td", [_c("el-tag", {
      attrs: {
        size: "mini"
      }
    }, [_vm._v(_vm._s(offering.offering.external_status[1]))])], 1)]), _vm._v(" "), _c("tr", [_c("th", [_vm._v("Approved")]), _vm._v(" "), _c("td", [_c("el-tag", {
      attrs: {
        size: "mini"
      }
    }, [_vm._v(_vm._s(offering.offering.external_status[2]))])], 1)]), _vm._v(" "), _c("tr", [_c("th", [_vm._v("Feature Design")]), _vm._v(" "), _c("td", [_c("el-tag", {
      attrs: {
        size: "mini"
      }
    }, [_vm._v(_vm._s(offering.offering.external_status[3]))])], 1)]), _vm._v(" "), _c("tr", [_c("th", [_vm._v("Functional Design")]), _vm._v(" "), _c("td", [_c("el-tag", {
      attrs: {
        size: "mini"
      }
    }, [_vm._v(_vm._s(offering.offering.external_status[4]))])], 1)]), _vm._v(" "), _c("tr", [_c("th", [_vm._v("Technical Design")]), _vm._v(" "), _c("td", [_c("el-tag", {
      attrs: {
        size: "mini"
      }
    }, [_vm._v(_vm._s(offering.offering.external_status[5]))])], 1)]), _vm._v(" "), _c("tr", [_c("th", [_vm._v("Build Planning")]), _vm._v(" "), _c("td", [_c("el-tag", {
      attrs: {
        size: "mini"
      }
    }, [_vm._v(_vm._s(offering.offering.external_status[6]))])], 1)]), _vm._v(" "), _c("tr", [_c("th", [_vm._v("Delivery")]), _vm._v(" "), _c("td", [_c("el-tag", {
      attrs: {
        size: "mini"
      }
    }, [_vm._v(_vm._s(offering.offering.external_status[7]))])], 1)]), _vm._v(" "), _c("tr", [_c("th", [_vm._v("Ready for Release")]), _vm._v(" "), _c("td", [_c("el-tag", {
      attrs: {
        size: "mini"
      }
    }, [_vm._v(_vm._s(offering.offering.external_status[8]))])], 1)]), _vm._v(" "), _c("tr", [_c("th", [_vm._v("Released")]), _vm._v(" "), _c("td", [_c("el-tag", {
      attrs: {
        size: "mini"
      }
    }, [_vm._v(_vm._s(offering.offering.external_status[9]))])], 1)]), _vm._v(" "), _c("tr", [_c("th", [_vm._v("Improvement")]), _vm._v(" "), _c("td", [_c("el-tag", {
      attrs: {
        size: "mini"
      }
    }, [_vm._v(_vm._s(offering.offering.external_status[10]))])], 1)]), _vm._v(" "), _c("tr", [_c("th", [_vm._v("Retired")]), _vm._v(" "), _c("td", [_c("el-tag", {
      attrs: {
        size: "mini"
      }
    }, [_vm._v(_vm._s(offering.offering.external_status[11]))])], 1)])])])], 1)], 1);
  }), 1) : _vm._e()], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "text-right mt-10"
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      icon: "el-icon-close"
    },
    on: {
      click: _vm.closeUI
    }
  }, [_vm._v("Close")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini",
      icon: "el-icon-edit",
      type: "primary"
    },
    on: {
      click: _vm.updateStepDetails
    }
  }, [_vm._v("Update Step")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini",
      icon: "el-icon-delete",
      type: "danger"
    },
    on: {
      click: _vm.deleteStep
    }
  }, [_vm._v("Delete Step")])], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;