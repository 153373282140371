"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  name: 'Step3',
  components: {},
  props: {},
  data: function data() {
    return {};
  },
  mounted: function mounted() {
    this.$eventHub.$emit('process-step-load', 'step-3');
  },
  methods: {
    goToPricePackages: function goToPricePackages() {
      this.$emit('go-prev');
    },
    goToStep4: function goToStep4() {
      this.$emit('go-next');
    }
  }
};