"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default2 = exports.default = {
  name: 'UMLComponent',
  components: {},
  props: {
    id: {
      type: String,
      default: ''
    },
    component: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      hover: false
    };
  },
  computed: {
    // a computed getter
    fillColor: function fillColor() {
      if (this.component.scope === 'beta') {
        return '#d4a7f145';
      } else if (this.component.type === 'parent') {
        return 'whitesmoke';
      } else {
        return 'white';
      }
    },
    strokeColor: function strokeColor() {
      if (this.component.scope === 'beta') {
        return '#d4a7f1fa';
      } else {
        return 'gray';
      }
    },
    fontWeight: function fontWeight() {
      if (this.component.type === 'parent') {
        return 'bold';
      } else {
        return 'normal';
      }
    },
    computeWidth: function computeWidth() {
      if (this.component.type === 'parent') {
        return this.component.width;
      } else {
        return 200;
      }
    },
    computeHeight: function computeHeight() {
      if (this.component.type === 'parent') {
        return this.component.height;
      } else {
        return 90;
      }
    }
  },
  mounted: function mounted() {},
  methods: {
    // Currently supporting max of 3 options
    computeOptionsCircleY: function computeOptionsCircleY(index) {
      switch (index) {
        case 0:
          return this.component.y + 26;
        case 1:
          return this.component.y + 52;
        case 2:
          return this.component.y + 78;
        default:
          return this.component.y + 12;
      }
    },
    computeOptionsTextY: function computeOptionsTextY(index) {
      switch (index) {
        case 0:
          return this.component.y + 28;
        case 1:
          return this.component.y + 54;
        case 2:
          return this.component.y + 80;
        default:
          return this.component.y + 14;
      }
    },
    clicked: function clicked(option) {
      this.$emit('clicked', this.component, this.id, option);
    },
    deleteComponent: function deleteComponent() {
      this.$emit('delete-component');
    },
    deleteService: function deleteService(index, service) {
      this.$emit('delete-service', this.component, service, index);
    },
    handleDrop: function handleDrop(event) {
      event.preventDefault(); // !important
      this.$emit('drop', event, this.id);
    },
    handleDragOver: function handleDragOver(event) {
      event.preventDefault(); // !important
      this.$emit('dragover', event, this.id);
    }
  }
};