"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _axios = _interopRequireDefault(require("axios"));
var _FileUpload = _interopRequireDefault(require("@/components/FileUpload"));
// import CommentComponent from '@/components/Comment'
var _default2 = exports.default = {
  name: 'Actions',
  components: {
    // CommentComponent,
    FileUploadComponent: _FileUpload.default
  },
  props: {
    actions: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    users: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    columns: {
      type: Object,
      default: function _default() {
        return {
          id: true,
          artefact: true,
          name: true,
          status: true,
          priority: true,
          due: true,
          created: true,
          assigned: true
        };
      }
    },
    searchNav: {
      type: Object,
      default: function _default() {
        return {
          domain: ''
        };
      }
    },
    workItemSearch: {
      type: String,
      default: ''
    },
    expanded: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      artefactDetailsDialog: false,
      fileLoading: false,
      edit: {
        mode: false,
        index: 0
      },
      selectedAction: {},
      comment: '',
      files: []
    };
  },
  computed: {
    filteredActions: function filteredActions() {
      var self = this;
      return this.actions.filter(function (action) {
        if (action.name === null) {
          action.name = '';
        }
        if (self.searchNav.domain) {
          return action.name.toLowerCase().indexOf(self.workItemSearch.toLowerCase()) > -1 && action.domain === self.searchNav.domain;
        } else {
          return action.name.toLowerCase().indexOf(self.workItemSearch.toLowerCase()) > -1;
        }
      });
    }
  },
  mounted: function mounted() {},
  created: function created() {},
  methods: {
    retrieveActionAttachements: function retrieveActionAttachements() {
      var _this = this;
      this.loading = true;
      // Retrieve Ticket and populate ticket modal
      var headers = {
        'Content-Type': 'application/json',
        'x-party-id': this.$store.getters.partyId,
        'x-user-id': this.$store.getters.id,
        'x-entity-id': this.selectedAction.id,
        'x-entity-name': this.selectedAction.name
      };
      _axios.default.get(process.env.VUE_APP_REST_API_ENDPOINT + 'file?entityId=' + this.selectedAction.id, {
        headers: headers
      }).then(function (response) {
        _this.loading = false;
        _this.files = [];
        if (response.data.data.status === 'FAILED') {
          _this.files = [];
        } else {
          _this.files = response.data.data;
          _this.$nextTick();
          _this.$forceUpdate();
        }
      }).catch(function (error) {
        _this.loading = false;
        if (error.response.status === 400) {
          _this.files = [];
        } else {
          _this.$notify({
            title: 'Error',
            message: error.response.message,
            type: 'error',
            duration: 2000
          });
        }
      });
    },
    computeUserName: function computeUserName(user) {
      return user.firstName + ' ' + user.lastName;
    },
    getUserFromUserID: function getUserFromUserID(user_id) {
      for (var i = 0; i < this.users.length; i++) {
        if (this.users[i].id === user_id) {
          return this.computeUserName(this.users[i]);
        }
      }
    },
    displayArtefactDetails: function displayArtefactDetails(action) {
      // To be confirmed.
      // Selection is action, displaying artefact details
      this.selectedAction = action;
      this.artefactDetailsDialog = true;
    },
    computePriorityTagType: function computePriorityTagType(priority) {
      switch (priority) {
        case 'High':
        case 'Critical':
          return 'danger';
        case 'Low':
        case 'Medium':
          return 'info';
      }
    },
    computeHumanFriendlyDate: function computeHumanFriendlyDate(date) {
      return this.$moment(date, 'YYYY-MM-DD HH:mm:ss').format('ll');
    },
    generateUrl: function generateUrl(file) {
      return process.env.VUE_APP_REST_API_ENDPOINT + 'file/' + file.fileName + '?versionId=' + file.versionId + '&size=50X50';
    }
  }
};