import { render, staticRenderFns } from "./data_design.vue?vue&type=template&id=195bdd5e&scoped=true"
import script from "./data_design.vue?vue&type=script&lang=js"
export * from "./data_design.vue?vue&type=script&lang=js"
import style0 from "./data_design.vue?vue&type=style&index=0&id=195bdd5e&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "195bdd5e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\Sastha\\Documents\\GitHub\\prodiggiapp-frontend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('195bdd5e')) {
      api.createRecord('195bdd5e', component.options)
    } else {
      api.reload('195bdd5e', component.options)
    }
    module.hot.accept("./data_design.vue?vue&type=template&id=195bdd5e&scoped=true", function () {
      api.rerender('195bdd5e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/processes/software_design/data_design.vue"
export default component.exports