"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.number.constructor");
var _Sticky = _interopRequireDefault(require("@/components/Sticky"));
var _default2 = exports.default = {
  name: 'ProcessSteps',
  components: {
    Sticky: _Sticky.default
  },
  props: {
    steps: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    currentStep: {
      type: Number,
      default: 0
    }
  },
  data: function data() {
    return {
      processStepDialog: false,
      activeStep: {}
    };
  },
  mounted: function mounted() {
    // Listen to event
    this.$eventHub.$on('process-step-load', this.togglePopover);
  },
  beforeDestroy: function beforeDestroy() {
    // Stop listening
    this.$eventHub.$off('process-step-load');
  },
  methods: {
    updateActiveStep: function updateActiveStep(index) {
      this.$emit('update-step', index);
      this.activeStep = this.steps[index];
    },
    togglePopover: function togglePopover(title) {
      for (var i = 0; i < this.steps.length; i++) {
        var step_title = this.steps[i].name.trim().toLowerCase().split(' ').join('-');
        if (step_title.trim() === title.trim()) {
          this.steps[i].visible = true;
          setTimeout(this.stepTimeout, 6000, i);
        }
      }
    },
    stepTimeout: function stepTimeout(index) {
      this.steps[index].visible = false;
    }
  }
};