"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _interopRequireWildcard2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _default2 = exports.default = {
  name: 'Relations',
  components: {
    RelationComponent: function RelationComponent() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./Relation.vue"));
      });
    }
  },
  props: {
    journeyMap: {
      type: Object,
      required: true,
      default: function _default() {
        return {
          id: '',
          title: '',
          description: '',
          additionalattributes: {},
          linkedJourneyExperiences: [],
          linkedJourneyPhases: [],
          linkedJourneySteps: []
        };
      }
    },
    relations: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    phaseX: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    phaseY: {
      type: Number,
      default: 90
    },
    taskX: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    taskY: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    experienceX: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    experienceY: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    canvasDragCoefficientX: {
      type: Number,
      default: 0
    },
    selectedEntityId: {
      type: String,
      default: null
    },
    relationType: {
      type: String,
      default: 'Journey'
    },
    update: {
      type: Boolean,
      default: false
    },
    stepLayout: {
      type: String,
      default: 'horizontal'
    }
  },
  data: function data() {
    return {
      isHover: false,
      relationKey: 0
    };
  },
  computed: {
    filteredRelations: function filteredRelations() {
      this.relationKey;
      var filteredRelations = [];
      for (var i = 0; i < this.relations.length; i++) {
        filteredRelations.push(this.relations[i]);
      }
      return filteredRelations;
    },
    sortedStages: function sortedStages() {
      var stagesSortOrder = [];
      if (this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages.length > 0 && this.journeyMap.linkedJourneyPhases.length > 0) {
        for (var i = 0; i < this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages.length; i++) {
          for (var j = 0; j < this.journeyMap.linkedJourneyPhases.length; j++) {
            if (this.journeyMap.linkedJourneyPhases[j].journeyPhase.id === this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[i].id && !this.journeyMap.linkedJourneyPhases[j].journeyPhase.isDeleted) {
              stagesSortOrder.push(this.journeyMap.linkedJourneyPhases[j]);
              break;
            }
          }
        }
      } else {
        for (var k = 0; k < this.journeyMap.linkedJourneyPhases.length; k++) {
          if (!this.journeyMap.linkedJourneyPhases[j].journeyPhase.hasOwnProperty('isDeleted')) {
            stagesSortOrder.push(this.journeyMap.linkedJourneyPhases[j]);
          } else if (this.journeyMap.linkedJourneyPhases[j].journeyPhase.hasOwnProperty('isDeleted') && this.journeyMap.linkedJourneyPhases[j].journeyPhase.isDeleted === false) {
            stagesSortOrder.push(this.journeyMap.linkedJourneyPhases[j]);
            break;
          }
        }
      }
      return stagesSortOrder;
    },
    computedTaskList: function computedTaskList() {
      this.relationKey;
      var stepList = [];
      if (this.journeyMap && this.journeyMap.linkedJourneySteps) {
        for (var i = 0; i < this.journeyMap.linkedJourneySteps.length; i++) {
          if (this.computeJourneyStepDisplayCondition(this.journeyMap.linkedJourneySteps[i], i)) {
            stepList.push(this.journeyMap.linkedJourneySteps[i]);
          }
        }
      }
      return stepList;
    },
    computedExperienceList: function computedExperienceList() {
      this.relationKey;
      var experienceList = [];
      if (this.journeyMap && this.journeyMap.linkedJourneyExperiences) {
        for (var j = 0; j < this.journeyMap.linkedJourneyExperiences.length; j++) {
          if (!this.journeyMap.linkedJourneyExperiences[j].journeyExperience.hasOwnProperty('isDeleted')) {
            experienceList.push(this.journeyMap.linkedJourneyExperiences[j]);
          } else if (this.journeyMap.linkedJourneyExperiences[j].journeyExperience.hasOwnProperty('isDeleted') && this.journeyMap.linkedJourneyExperiences[j].journeyExperience.isDeleted === false) {
            experienceList.push(this.journeyMap.linkedJourneyExperiences[j]);
            break;
          }
        }
      }
      return experienceList;
    }
  },
  watch: {
    update: function update(oldval, newval) {
      this.relationKey++;
      this.$forceUpdate();
    },
    relations: function relations(oldval, newval) {
      this.relationKey++;
    }
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    computeJourneyStepDisplayCondition: function computeJourneyStepDisplayCondition(task, index) {
      // Handle Focus Mode
      if (task.journeyStep.hasOwnProperty('isDeleted') && task.journeyStep.isDeleted === true) {
        return false;
      }
      return true;
    },
    selectRelation: function selectRelation(relation, relation_index) {
      this.$emit('select-relation', relation, relation_index, this.relationType);
    },
    computeRelationDisplayCondition: function computeRelationDisplayCondition(relation) {
      if (relation.start_type === 'Step' && relation.end_type === 'Step') {
        // Step - step relation cannot be toggled
        return true;
      } else if (this.stepLayout === 'matrix' && relation.start_type === 'Phase' && relation.end_type === 'Step') {
        // No phase step relation in matrix view
        return false;
      } else if (this.selectedEntityId && (this.selectedEntityId === relation.start_id || this.selectedEntityId === relation.end_id)) {
        // Display relation on hover
        return true;
      }
      return false;
    },
    generatePath: function generatePath(relation, start_id, start_type, end_id, end_type) {
      var pos_start = this.getPos(start_id, start_type, 'start');
      var pos_end = this.getPos(end_id, end_type, 'end');
      switch (start_type) {
        case 'Phase':
          if (end_type === 'Step') {
            return this.generateBezierPath1(relation, pos_start[0], pos_start[1], pos_end[0] + 10, pos_end[1]);
          } else {
            return this.generateBezierPath1(relation, pos_start[0], pos_start[1], pos_end[0], pos_end[1]);
          }
        case 'Step':
          if (this.stepLayout === 'horizontal') {
            if (this.relationType === 'Step') {
              return this.generateLinePath(relation, pos_start[0], pos_start[1], pos_end[0], pos_end[1]);
            } else {
              return this.generateBezierPath1(relation, pos_start[0] - 30, pos_start[1], pos_end[0], pos_end[1]);
            }
          } else {
            if (this.relationType === 'Step') {
              return this.generateLinePath(relation, pos_start[0], pos_start[1] + 5, pos_end[0], pos_end[1] + 5);
            } else {
              return this.generateBezierPath1(relation, pos_start[0] - 40, pos_start[1] + 15, pos_end[0], pos_end[1]);
            }
          }
        case 'Experience':
          return this.generateBezierPath1(relation, pos_start[0], pos_start[1], pos_end[0], pos_end[1]);
        default:
          return this.generateBezierPath1(relation, pos_start[0], pos_start[1], pos_end[0], pos_end[1]);
      }
    },
    getPos: function getPos(id, type, pos) {
      switch (type) {
        case 'Phase':
          var x = this.getPhaseX(id);
          var y = this.phaseY;
          return [x, y];
        case 'Step':
          var step_x = 0;
          var step_y = 0;
          if (this.stepLayout === 'horizontal') {
            step_x = this.getTaskX(id) + (this.relationType === 'Step' ? pos === 'start' ? 120 : -30 : pos === 'start' ? 5 : -30);
            step_y = this.getTaskY(id) + (this.relationType === 'Step' ? 0 : pos === 'start' ? 20 : 0);
          } else {
            step_x = this.getTaskX(id) + (this.relationType === 'Step' ? pos === 'start' ? 5 : -30 : pos === 'start' ? 5 : -30);
            step_y = this.getTaskY(id) + (this.relationType === 'Step' ? -20 : pos === 'start' ? -10 : -20);
          }
          return [step_x, step_y];
        case 'Experience':
          var experience_x = this.getExperienceX(id);
          var experience_y = this.getExperienceY(id);
          return [experience_x, experience_y];
      }
    },
    // Path Algorithm
    generateSinglePointBezierPath: function generateSinglePointBezierPath(relation, start_x1, start_y1, start_x2, start_y2) {
      if (start_x1 !== undefined) {
        return 'M' + start_x1 + ',' + start_y1 + ' C' + start_x1 + ',' + start_y2 + ' ' + start_x1 + ',' + start_y2 + ' ' + start_x2 + ',' + start_y2;
      } else {
        return 'M 0,0';
      }
    },
    generateBezierPath: function generateBezierPath(relation, start_x1, start_y1, start_x2, start_y2) {
      var end_x1 = start_x1 + (relation.pos.length > 0 ? relation.pos[0].x : 0);
      var end_y1 = start_y1 + (relation.pos.length > 0 ? relation.pos[0].y : (start_y2 - start_y1) / 2);
      var end_x2 = start_x2 + (relation.pos.length > 0 ? relation.pos[1].x : 0);
      var end_y2 = start_y2 + (relation.pos.length > 0 ? relation.pos[1].y : -(start_y2 - start_y1) / 2);
      if (start_x1 !== undefined) {
        return 'M' + start_x1 + ',' + start_y1 + ' C' + end_x1 + ',' + end_y1 + ' ' + end_x2 + ',' + end_y2 + ' ' + start_x2 + ',' + start_y2;
      } else {
        return 'M 0,0';
      }
    },
    generateQuadraticBezierPath: function generateQuadraticBezierPath(relation, start_x1, start_y1, start_x2, start_y2) {
      var mid_x = start_x1;
      var mid_y = start_y2;
      if (start_x1 !== undefined) {
        return 'M' + start_x1 + ',' + start_y1 + ' Q' + mid_x + ',' + mid_y + ' ' + start_x2 + ',' + start_y2;
      } else {
        return 'M 0,0';
      }
    },
    generateBezierPath1: function generateBezierPath1(relation, start_x1, start_y1, start_x2, start_y2) {
      var end_x1 = start_x1 - 50;
      var end_y1 = start_y1 + 30;
      var end_x2 = start_x2 - 80;
      var end_y2 = start_y2;
      if (start_x1 !== undefined) {
        return 'M' + start_x1 + ',' + (start_y1 - 20) + ' C' + end_x1 + ',' + end_y1 + ' ' + end_x2 + ',' + end_y2 + ' ' + (start_x2 - 10) + ',' + start_y2;
      } else {
        return 'M 0,0';
      }
    },
    generateBezierPathHorizontal: function generateBezierPathHorizontal(relation, start_x1, start_y1, start_x2, start_y2) {
      var end_x1 = start_x2;
      var end_y1 = start_y1;
      var end_x2 = start_x1;
      var end_y2 = start_y2;
      if (start_x1 !== undefined) {
        return 'M' + start_x1 + ',' + start_y1 + ' C' + end_x1 + ',' + end_y1 + ' ' + end_x2 + ',' + end_y2 + ' ' + start_x2 + ',' + start_y2;
      } else {
        return 'M 0,0';
      }
    },
    generateLinePath: function generateLinePath(relation, start_x1, start_y1, start_x2, start_y2) {
      // M 40 10 L 50 10 L 50 25 L 0 25 L 0 40 L 10 40
      var x1 = start_x1 + 20;
      var y1 = start_y1;
      var x2 = start_x1 + 20;
      var y2 = start_y1 + (start_y2 - start_y1) / 2;
      var x3 = start_x2 - 20;
      var y3 = start_y1 + (start_y2 - start_y1) / 2;
      var x4 = start_x2 - 20;
      var y4 = start_y2;
      if (start_x1 !== undefined) {
        return 'M ' + start_x1 + ' ' + start_y1 + ' L' + x1 + ' ' + y1 + ' L' + x2 + ' ' + y2 + ' L' + x3 + ' ' + y3 + ' L' + x4 + ' ' + y4 + ' L' + start_x2 + ' ' + start_y2;
      } else {
        return 'M 0,0';
      }
    },
    // Position Computations
    computePhasePosX: function computePhasePosX(index) {
      return this.phaseX[index];
    },
    computePhaseLength: function computePhaseLength(index) {
      var phase = this.sortedStages[index].journeyPhase;
      // get max number of features in product for given phase
      // if less than 2 use default 175, else use length off features
      var max_count = 0;

      // Get an array of pains/gains for current phase
      if (typeof phase === 'undefined') {
        return 225;
      }
      if (phase.relations !== null) {
        max_count = phase.relations.length;
      }
      var additional_columns = Math.floor(max_count / 7.5) - 1;
      var first_column = 150;
      return 225 + first_column + additional_columns * 150;
    },
    getPhaseX: function getPhaseX(phase_id) {
      for (var j = 0; j < this.sortedStages.length; j++) {
        if (this.sortedStages[j].journeyPhase.id === phase_id) {
          var x = this.computePhasePosX(j);
          return x;
        }
      }
    },
    getPhaseLength: function getPhaseLength(phase_id) {
      for (var j = 0; j < this.sortedStages.length; j++) {
        if (this.sortedStages[j].journeyPhase.id === phase_id) {
          var x = this.computePhaseLength(j);
          return x;
        }
      }
    },
    getTaskX: function getTaskX(task_id) {
      for (var j = 0; j < this.computedTaskList.length; j++) {
        if (this.computedTaskList[j].journeyStep.id === task_id) {
          var x = this.computeTaskPosX(j);
          return x + 17;
        }
      }
      return 0;
    },
    getTaskY: function getTaskY(task_id) {
      for (var j = 0; j < this.computedTaskList.length; j++) {
        if (this.computedTaskList[j].journeyStep.id === task_id) {
          var y = this.computeTaskPosY(j);
          return y + 15;
        }
      }
      return 0;
    },
    getExperienceX: function getExperienceX(experience_id) {
      for (var j = 0; j < this.computedExperienceList.length; j++) {
        if (this.computedExperienceList[j].journeyExperience.id === experience_id) {
          var x = this.computeExperiencePosX(j);
          return x;
        }
      }
    },
    getExperienceY: function getExperienceY(experience_id) {
      for (var j = 0; j < this.computedExperienceList.length; j++) {
        if (this.computedExperienceList[j].journeyExperience.id === experience_id) {
          var y = this.computeExperiencePosY(j);
          return y + 20;
        }
      }
    },
    computeTaskPosX: function computeTaskPosX(index) {
      return this.taskX[index];
    },
    computeTaskPosY: function computeTaskPosY(index) {
      return this.taskY[index];
    },
    computeExperiencePosX: function computeExperiencePosX(index) {
      return this.experienceX[index];
    },
    computeExperiencePosY: function computeExperiencePosY(index) {
      return this.experienceY[index];
    }
  }
};