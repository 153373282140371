"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("g", {
    attrs: {
      refs: "relations-component"
    }
  }, [_c("g", {
    attrs: {
      refs: "relations-path-markers"
    }
  }, [_c("marker", {
    attrs: {
      id: "dot",
      viewBox: "0 0 10 10",
      refX: "5",
      refY: "5",
      markerWidth: "12",
      markerHeight: "12",
      orient: "auto-start-reverse"
    }
  }, [_c("circle", {
    attrs: {
      cx: "5",
      cy: "5",
      r: "4",
      fill: "#447799",
      "fill-opacity": "1"
    }
  })]), _vm._v(" "), _c("marker", {
    attrs: {
      id: "triangle",
      viewBox: "0 0 10 10",
      refX: "5",
      refY: "5",
      fill: "#447799",
      markerUnits: "strokeWidth",
      markerWidth: "7",
      markerHeight: "7",
      orient: "auto"
    }
  }, [_c("path", {
    attrs: {
      d: "M 0 0 L 10 5 L 0 10 ",
      fill: "#888",
      "fill-opacity": "1"
    }
  })])]), _vm._v(" "), _vm._l(_vm.filteredRelations, function (relation, relation_index) {
    return _c("g", {
      key: "relation_" + relation_index,
      attrs: {
        refs: "relation"
      }
    }, [_c("transition", {
      attrs: {
        name: "fade"
      }
    }, [_vm.computeRelationDisplayCondition(relation) ? _c("relation-component", {
      attrs: {
        path: _vm.generatePath(relation, relation.start_id, relation.start_type, relation.end_id, relation.end_type),
        entity: relation
      },
      on: {
        click: function click($event) {
          return _vm.selectRelation(relation, relation_index);
        }
      }
    }) : _vm._e()], 1)], 1);
  })], 2);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;