"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "drawer-container"
  }, [_c("div", [_c("h2", {
    staticClass: "drawer-title"
  }, [_vm._v("Navigation options")]), _vm._v(" "), _c("div", {
    staticClass: "drawer-item"
  }, [_c("span", [_vm._v("Sidebar Logo")]), _vm._v(" "), _c("el-switch", {
    staticClass: "drawer-switch",
    model: {
      value: _vm.sidebarLogo,
      callback: function callback($$v) {
        _vm.sidebarLogo = $$v;
      },
      expression: "sidebarLogo"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "drawer-item"
  }, [_c("span", [_vm._v("Show Preview features")]), _vm._v(" "), _c("el-switch", {
    staticClass: "drawer-switch",
    model: {
      value: _vm.showMVP,
      callback: function callback($$v) {
        _vm.showMVP = $$v;
      },
      expression: "showMVP"
    }
  })], 1)])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;