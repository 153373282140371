import { render, staticRenderFns } from "./customer-types.vue?vue&type=template&id=0cf52971&scoped=true"
import script from "./customer-types.vue?vue&type=script&lang=js"
export * from "./customer-types.vue?vue&type=script&lang=js"
import style0 from "./customer-types.vue?vue&type=style&index=0&id=0cf52971&rel=stylesheet%2Fscss&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cf52971",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\Sastha\\Documents\\GitHub\\prodiggiapp-frontend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0cf52971')) {
      api.createRecord('0cf52971', component.options)
    } else {
      api.reload('0cf52971', component.options)
    }
    module.hot.accept("./customer-types.vue?vue&type=template&id=0cf52971&scoped=true", function () {
      api.rerender('0cf52971', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/processes/plan_product/components/segments_customer_types/customer-types.vue"
export default component.exports