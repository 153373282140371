"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default2 = exports.default = {
  name: 'FeatureManagmentFeatureDetails',
  props: {
    selectedFeature: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      addStoryDialog: false,
      storyUserAssignedHistoryDialog: false,
      story_form: {
        name: '',
        description: '',
        enabler: false,
        value: 0,
        priority: 0,
        is_stratergic: false,
        status: 'Operational',
        first_added: '',
        improved_in: '',
        prime_user: {
          name: '',
          design_goal: ''
        },
        other_users: [],
        created_by: '',
        assigned_to: '',
        steps: [{
          name: ''
        }]
      },
      selectedStory: {}
    };
  },
  computed: {},
  mounted: function mounted() {},
  methods: {
    getPriorityIcon: function getPriorityIcon(priority) {
      return 'priority-' + priority;
    },
    getValueIcon: function getValueIcon(value) {
      return 'dollar-' + value;
    },
    addStory: function addStory() {
      this.addStoryDialog = false;
    },
    selectStory: function selectStory(data) {
      this.selectedStory = data;
    },
    addFormStoryStep: function addFormStoryStep() {
      this.story_form.steps.push({
        name: ''
      });
    }
  }
};