"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/taggedTemplateLiteral.js"));
var _interopRequireWildcard2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _templateObject, _templateObject2;
var _default2 = exports.default = {
  name: 'Administer',
  components: {
    // ProductServiceComponent: () => import(/* webpackPrefetch: true */ './ProductService.vue'),
    // GovernanceComponent: () => import(/* webpackPrefetch: true */ './Governance.vue'),
    VariantComponent: function VariantComponent() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./Variant.vue"));
      });
    },
    RelatedComponent: function RelatedComponent() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./Related.vue"));
      });
    }
  },
  props: {
    offering: {
      type: Object,
      required: true
    },
    offerings: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      loading: false,
      deleteOfferingConfirmationDialog: false,
      offeringForm: {
        id: '',
        titleOriginal: '',
        title: ''
      }
    };
  },
  computed: {
    computeCustomerType: function computeCustomerType() {
      if (this.offering.linkedCustomerTypes.length === 0) {
        return '';
      }
      return this.offering.linkedCustomerTypes[0].customerType.title;
    }
  },
  mounted: function mounted() {},
  methods: {
    getOfferingTitleFromID: function getOfferingTitleFromID(offering_id) {
      for (var i = 0; i < this.offerings.length; i++) {
        if (this.offerings[i].id === offering_id) {
          return this.offerings[i].title;
        }
      }
      return '';
    },
    updateOffering: function updateOffering() {
      var _this = this;
      this.loading = true;
      this.$apollo.mutate({
        // Query
        mutation: window._gql(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["mutation updateOffering($updateOffering: UpdateOfferingInput!)\n        {\n          updateOffering(input: $updateOffering) {\n            id\n          }\n        }"]))),
        // Parameters
        variables: {
          'updateOffering': {
            'id': this.offering.id,
            'title': this.offering.title,
            'description': this.offering.description
          }
        }
      }).then(function (data) {
        _this.loading = false;
      }).catch(function (error) {
        // Error
        _this.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this.loading = false;
      });
    },
    openDeleteOfferingConfirmation: function openDeleteOfferingConfirmation() {
      this.deleteOfferingConfirmationDialog = true;
      this.offeringForm.id = this.offering.id;
      this.offeringForm.titleOriginal = this.offering.title;
      this.offeringForm.title = '';
    },
    cancelOfferingDelete: function cancelOfferingDelete() {
      this.deleteOfferingConfirmationDialog = false;
      this.offeringForm.id = '';
      this.offeringForm.title = '';
      this.offeringForm.titleOriginal = '';
    },
    deleteOffering: function deleteOffering() {
      var _this2 = this;
      if (this.offeringForm.title !== this.offeringForm.titleOriginal) {
        return;
      }
      this.loading = true;
      this.deleteOfferingConfirmationDialog = false;
      this.$apollo.mutate({
        // Query
        mutation: window._gql(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["mutation deleteOffering($deleteOffering: DeleteOfferingInput!)\n        {\n          deleteOffering(input: $deleteOffering) {\n            id\n          }\n        }"]))),
        // Parameters
        variables: {
          'deleteOffering': {
            'id': this.offering.id
          }
        }
      }).then(function (data) {
        _this2.loading = false;
        _this2.$store.dispatch('offerings/resetOfferingId');
        _this2.$store.dispatch('offerings/resetOffering');
        _this2.$router.push("/");
      }).catch(function (error) {
        // Error
        _this2.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this2.loading = false;
      });
    }
  }
};