"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {}, [_c("el-row", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      gutter: 2
    }
  }, [_c("el-col", {
    staticStyle: {
      "padding-right": "8px",
      "margin-bottom": "30px"
    },
    attrs: {
      xs: {
        span: 24
      },
      sm: {
        span: 24
      },
      md: {
        span: 24
      },
      lg: {
        span: 24
      },
      xl: {
        span: 24
      }
    }
  }, [_c("invite-list", {
    attrs: {
      invites: _vm.invites,
      total: _vm.total
    },
    on: {
      new: _vm.newInvite,
      copy: _vm.copyInvite,
      resend: _vm.resendInvite,
      invite: _vm.inviteUser,
      delete: _vm.deleteInvite,
      paginate: _vm.getInviteList
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;