"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("g", [_vm.processes.length > 0 ? _c("g", [_c("rect", {
    attrs: {
      x: _vm.computeIconPoints(_vm.index)[0] - 300,
      y: _vm.computeIconPoints(_vm.index)[1] - 120,
      width: 200,
      height: _vm.computeProcessesHeight(),
      stroke: "gray",
      "stroke-width": "2",
      fill: "ghostwhite",
      "fill-opacity": "1",
      "stroke-opacity": "0.9",
      rx: "2.5",
      ry: "2.5"
    }
  }), _vm._v(" "), _c("line", {
    attrs: {
      x1: _vm.computeIconPoints(_vm.index)[0] - 300,
      y1: _vm.computeIconPoints(_vm.index)[1] - 90,
      x2: _vm.computeIconPoints(_vm.index)[0] - 100,
      y2: _vm.computeIconPoints(_vm.index)[1] - 90,
      stroke: "gray",
      "stroke-width": "2",
      fill: "none",
      "fill-opacity": "1",
      "stroke-opacity": "0.9"
    }
  }), _vm._v(" "), _c("line", {
    attrs: {
      x1: _vm.computeIconPoints(_vm.index)[0] - 270,
      y1: _vm.computeIconPoints(_vm.index)[1] - 90,
      x2: _vm.computeIconPoints(_vm.index)[0] - 270,
      y2: _vm.computeIconPoints(_vm.index)[1] + _vm.computeProcessesHeight() - 120,
      stroke: "gray",
      "stroke-width": "1",
      fill: "none",
      "fill-opacity": "1",
      "stroke-opacity": "0.9"
    }
  }), _vm._v(" "), _c("text", {
    attrs: {
      x: _vm.computeIconPoints(_vm.index)[0] - 200,
      y: _vm.computeIconPoints(_vm.index)[1] - 105,
      fill: "black",
      "font-size": "14",
      "text-anchor": "middle",
      "font-weight": "bold"
    }
  }, [_c("tspan", {
    attrs: {
      x: _vm.computeIconPoints(_vm.index)[0] - 200,
      dy: ".6em"
    }
  }, [_vm._v("Process Documents")])]), _vm._v(" "), _c("icon-component", {
    attrs: {
      cx: _vm.computeIconPoints(_vm.index)[0] - 125,
      cy: _vm.computeIconPoints(_vm.index)[1] - 115,
      type: "edit"
    },
    on: {
      "domain-icon-selected": _vm.openDomainDocumentEditModal
    }
  }), _vm._v(" "), _vm._l(_vm.processes, function (process, loop_index) {
    return [_c("g", {
      key: loop_index,
      on: {
        click: function click($event) {
          return _vm.openDocument(process.documentation.url);
        }
      }
    }, [_c("line", {
      attrs: {
        x1: _vm.computeIconPoints(_vm.index)[0] - 300,
        y1: _vm.computeIconPoints(_vm.index)[1] - 90 + (loop_index + 1) * 30,
        x2: _vm.computeIconPoints(_vm.index)[0] - 100,
        y2: _vm.computeIconPoints(_vm.index)[1] - 90 + (loop_index + 1) * 30,
        stroke: "gray",
        "stroke-width": "1",
        fill: "none",
        "fill-opacity": "1",
        "stroke-opacity": "0.9"
      }
    }), _vm._v(" "), process.documentation.type === "Word" ? _c("icon-component", {
      key: loop_index,
      attrs: {
        cx: _vm.computeIconPoints(_vm.index)[0] - 300,
        cy: _vm.computeIconPoints(_vm.index)[1] - 90 + loop_index * 30,
        type: "word"
      }
    }) : _c("icon-component", {
      key: loop_index,
      attrs: {
        cx: _vm.computeIconPoints(_vm.index)[0] - 300,
        cy: _vm.computeIconPoints(_vm.index)[1] - 90 + loop_index * 30,
        type: "document"
      }
    }), _vm._v(" "), _c("text", {
      attrs: {
        x: _vm.computeIconPoints(_vm.index)[0] - 185,
        y: _vm.computeIconPoints(_vm.index)[1] - 75 + loop_index * 30,
        fill: "black",
        "font-size": "12",
        "text-anchor": "middle"
      }
    }, [_c("tspan", {
      attrs: {
        x: _vm.computeIconPoints(_vm.index)[0] - 185,
        dy: ".6em"
      }
    }, [_vm._v(_vm._s(process.documentation.name))])])], 1)];
  })], 2) : _vm._e(), _vm._v(" "), _vm.documents.length > 0 ? _c("g", [_c("rect", {
    attrs: {
      x: _vm.computeIconPoints(_vm.index)[0] - 300,
      y: _vm.computeIconPoints(_vm.index)[1] + _vm.computeProcessesHeight() - 80,
      width: 200,
      height: _vm.computeDocumentsHeight(),
      stroke: "gray",
      "stroke-width": "2",
      fill: "ghostwhite",
      "fill-opacity": "1",
      "stroke-opacity": "0.9",
      rx: "2.5",
      ry: "2.5"
    }
  }), _vm._v(" "), _c("line", {
    attrs: {
      x1: _vm.computeIconPoints(_vm.index)[0] - 300,
      y1: _vm.computeIconPoints(_vm.index)[1] + _vm.computeProcessesHeight() - 50,
      x2: _vm.computeIconPoints(_vm.index)[0] - 100,
      y2: _vm.computeIconPoints(_vm.index)[1] + _vm.computeProcessesHeight() - 50,
      stroke: "gray",
      "stroke-width": "2",
      fill: "none",
      "fill-opacity": "1",
      "stroke-opacity": "0.9"
    }
  }), _vm._v(" "), _c("line", {
    attrs: {
      x1: _vm.computeIconPoints(_vm.index)[0] - 270,
      y1: _vm.computeIconPoints(_vm.index)[1] + _vm.computeProcessesHeight() - 50,
      x2: _vm.computeIconPoints(_vm.index)[0] - 270,
      y2: _vm.computeIconPoints(_vm.index)[1] + _vm.computeProcessesHeight() + _vm.computeDocumentsHeight() - 80,
      stroke: "gray",
      "stroke-width": "1",
      fill: "none",
      "fill-opacity": "1",
      "stroke-opacity": "0.9"
    }
  }), _vm._v(" "), _c("text", {
    attrs: {
      x: _vm.computeIconPoints(_vm.index)[0] - 200,
      y: _vm.computeIconPoints(_vm.index)[1] + _vm.computeProcessesHeight() - 65,
      fill: "black",
      "font-size": "14",
      "text-anchor": "middle",
      "font-weight": "bold"
    }
  }, [_c("tspan", {
    attrs: {
      x: _vm.computeIconPoints(_vm.index)[0] - 200,
      dy: ".6em"
    }
  }, [_vm._v("Additional Documents")])]), _vm._v(" "), _vm._l(_vm.documents, function (document, loop_index) {
    return [_c("g", {
      key: loop_index
    }, [_c("line", {
      attrs: {
        x1: _vm.computeIconPoints(_vm.index)[0] - 300,
        y1: _vm.computeIconPoints(_vm.index)[1] + _vm.computeProcessesHeight() - 50 + (loop_index + 1) * 30,
        x2: _vm.computeIconPoints(_vm.index)[0] - 100,
        y2: _vm.computeIconPoints(_vm.index)[1] + _vm.computeProcessesHeight() - 50 + (loop_index + 1) * 30,
        stroke: "gray",
        "stroke-width": "1",
        fill: "none",
        "fill-opacity": "1",
        "stroke-opacity": "0.9"
      }
    }), _vm._v(" "), document.type === "Word" ? _c("icon-component", {
      key: loop_index,
      attrs: {
        cx: _vm.computeIconPoints(_vm.index)[0] - 300,
        cy: _vm.computeIconPoints(_vm.index)[1] + _vm.computeProcessesHeight() - 50 + loop_index * 30,
        type: "word"
      }
    }) : _c("icon-component", {
      key: loop_index,
      attrs: {
        cx: _vm.computeIconPoints(_vm.index)[0] - 300,
        cy: _vm.computeIconPoints(_vm.index)[1] + _vm.computeProcessesHeight() - 50 + loop_index * 30,
        type: "document"
      }
    }), _vm._v(" "), _c("text", {
      attrs: {
        x: _vm.computeIconPoints(_vm.index)[0] - 185,
        y: _vm.computeIconPoints(_vm.index)[1] + _vm.computeProcessesHeight() - 35 + loop_index * 30,
        fill: "black",
        "font-size": "12",
        "text-anchor": "middle"
      }
    }, [_c("tspan", {
      attrs: {
        x: _vm.computeIconPoints(_vm.index)[0] - 185,
        dy: ".6em"
      }
    }, [_vm._v(_vm._s(document.name))])])], 1)];
  })], 2) : _vm._e()]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;