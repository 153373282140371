import { render, staticRenderFns } from "./Feature.vue?vue&type=template&id=7de9a4d7"
import script from "./Feature.vue?vue&type=script&lang=js"
export * from "./Feature.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\Sastha\\Documents\\GitHub\\prodiggiapp-frontend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7de9a4d7')) {
      api.createRecord('7de9a4d7', component.options)
    } else {
      api.reload('7de9a4d7', component.options)
    }
    module.hot.accept("./Feature.vue?vue&type=template&id=7de9a4d7", function () {
      api.rerender('7de9a4d7', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/processes/software_design/components/define_user_interactions/optimise-interactions/Feature.vue"
export default component.exports