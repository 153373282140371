"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.string.fixed");
require("core-js/modules/es6.number.constructor");
var _echarts = _interopRequireDefault(require("echarts"));
var _utils = require("@/utils");
require('echarts/theme/macarons'); // echarts theme
var _default = exports.default = {
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '300px'
    },
    abandoned: {
      type: Number,
      default: 0
    },
    fixed: {
      type: Number,
      default: 0
    },
    releaseready: {
      type: Number,
      default: 0
    },
    backlog: {
      type: Number,
      default: 0
    },
    todo: {
      type: Number,
      default: 0
    },
    indesign: {
      type: Number,
      default: 0
    },
    indev: {
      type: Number,
      default: 0
    },
    inprogress: {
      type: Number,
      default: 0
    },
    uibuild: {
      type: Number,
      default: 0
    },
    backendbuild: {
      type: Number,
      default: 0
    },
    uicheck: {
      type: Number,
      default: 0
    },
    uiwiring: {
      type: Number,
      default: 0
    },
    uipolish: {
      type: Number,
      default: 0
    },
    integrationcheck: {
      type: Number,
      default: 0
    },
    uat: {
      type: Number,
      default: 0
    },
    uatpolish: {
      type: Number,
      default: 0
    }
  },
  data: function data() {
    return {
      chart: null
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.initChart();
    this.__resizeHandler = (0, _utils.debounce)(function () {
      if (_this.chart) {
        _this.chart.resize();
      }
    }, 100);
    window.addEventListener('resize', this.__resizeHandler);
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    window.removeEventListener('resize', this.__resizeHandler);
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart: function initChart() {
      this.chart = _echarts.default.init(this.$el, 'macarons');
      this.chart.setOption({
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
          left: 'center',
          bottom: '10',
          data: ['ABANDONED', 'FIXED', 'RELEASE READY', 'Backlog', 'To Do', 'IN DESIGN', 'IN DEV', 'In Progress', 'UI BUILD', 'BACKEND BUILD', 'UI CHECK', 'UI WIRING', 'UI POLISH', 'INTEGRATION CHECK', 'UAT', 'UAT POLISH']
        },
        calculable: true,
        series: [{
          name: 'Progress',
          type: 'pie',
          data: [{
            value: this.abandoned,
            name: 'ABANDONED'
          }, {
            value: this.fixed,
            name: 'FIXED'
          }, {
            value: this.releaseready,
            name: 'RELEASE READY'
          }, {
            value: this.backlog,
            name: 'Backlog'
          }, {
            value: this.todo,
            name: 'To Do'
          }, {
            value: this.indesign,
            name: 'IN DESIGN'
          }, {
            value: this.indev,
            name: 'IN DEV'
          }, {
            value: this.inprogress,
            name: 'In Progress'
          }, {
            value: this.uibuild,
            name: 'UI BUILD'
          }, {
            value: this.backendbuild,
            name: 'BACKEND BUILD'
          }, {
            value: this.uicheck,
            name: 'UI CHECK'
          }, {
            value: this.uiwiring,
            name: 'UI WIRING'
          }, {
            value: this.uipolish,
            name: 'UI POLISH'
          }, {
            value: this.integrationcheck,
            name: 'INTEGRATION CHECK'
          }, {
            value: this.uat,
            name: 'UAT'
          }, {
            value: this.uatpolish,
            name: 'UAT POLISH'
          }],
          animationEasing: 'cubicInOut',
          animationDuration: 2600
        }]
      });
    }
  }
};