"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  name: 'PageStatus',
  data: function data() {
    return {
      type: 'success',
      title: 'Lorem Ipsum',
      short_description: 'The webmaster said that you can not enter this page...',
      long_description: 'Please check that the URL you entered is correct, or click the button below to return to the homepage.'
    };
  },
  mounted: function mounted() {
    if (this.$route.params.type) {
      this.type = this.$route.params.type;
    }
    if (this.$route.query.title) {
      this.title = this.$route.query.title;
    }
    if (this.$route.query.short_description) {
      this.short_description = this.$route.query.short_description;
    }
    if (this.$route.query.long_description) {
      this.long_description = this.$route.query.long_description;
    }
  },
  methods: {
    computeStyle: function computeStyle() {
      if (this.type === 'success') {
        return 'background:-webkit-linear-gradient(bottom, #2d3a4b, #45566d)';
      } else {
        return 'background: -webkit-linear-gradient(bottom, #2d3a4b, #45566d)';
      }
    }
  }
};