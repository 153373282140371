"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/taggedTemplateLiteral.js"));
var _templateObject, _templateObject2, _templateObject3, _templateObject4;
var _default2 = exports.default = {
  name: 'JourneyIdeaCreate',
  components: {},
  props: {
    ideas: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    var validateTitle = function validateTitle(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter a valid title'));
      } else {
        callback();
      }
    };
    var validateDescription = function validateDescription(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter a valid description'));
      } else {
        callback();
      }
    };
    var validateProblemStatement = function validateProblemStatement(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter a valid background'));
      } else {
        callback();
      }
    };
    var validateSolution = function validateSolution(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter a valid justification'));
      } else {
        callback();
      }
    };
    var validateValueProposition = function validateValueProposition(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter a valid valueproposition'));
      } else {
        callback();
      }
    };
    var validateScope = function validateScope(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter a valid scope'));
      } else {
        callback();
      }
    };
    return {
      journeyRules: {
        title: [{
          required: true,
          trigger: 'blur',
          validator: validateTitle
        }],
        description: [{
          required: true,
          trigger: 'blur',
          validator: validateDescription
        }],
        problemstatement: [{
          required: true,
          trigger: 'blur',
          validator: validateProblemStatement
        }],
        solution: [{
          required: true,
          trigger: 'blur',
          validator: validateSolution
        }],
        valueproposition: [{
          required: true,
          trigger: 'blur',
          validator: validateValueProposition
        }],
        scope: [{
          required: true,
          trigger: 'blur',
          validator: validateScope
        }]
      },
      loading: false,
      activeStep: 'step1',
      customers: [],
      phases: [],
      tasks: [],
      form: {
        parent_journey_idea_id: '',
        parent_journey_idea: '',
        customer_type: '',
        customer_type_id: '',
        title: '',
        description: '',
        problemstatement: '',
        solution: '',
        valueproposition: '',
        status: '',
        scope: '',
        scopename: ''
      },
      statuses: [{
        label: 'Proposed',
        value: 'Proposed'
      }, {
        label: 'Evaluation',
        value: 'Evaluation'
      }, {
        label: 'Approved',
        value: 'Approved'
      }, {
        label: 'Starting Up',
        value: 'Starting Up'
      }, {
        label: 'Launched',
        value: 'Launched'
      }, {
        label: 'Abandoned',
        value: 'Abandoned'
      }]
    };
  },
  computed: {
    filteredCustomers: function filteredCustomers() {
      return this.customers.filter(function (customer) {
        return customer.linkedJourneyMaps.length > 0;
      });
    }
  },
  mounted: function mounted() {
    this.retrieveCustomerTypes(1);
    this.retrievePhases(1);
    this.retrieveTasks(1);
  },
  methods: {
    retrievePhases: function retrievePhases(page_number) {
      var _this = this;
      this.loading = true;
      this.phases = [];
      this.$apollo.query({
        // Query
        query: window._gql(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)([" query JourneyPhases($page: Page)\n          {\n          JourneyPhases(page: $page)\n          {\n            pages\n            total\n            select{\n              id\n              title\n              description\n            }\n          }\n        }"]))),
        fetchPolicy: 'no-cache',
        variables: {
          'page': {
            'limit': 20,
            'start': page_number
          }
        }
      }).then(function (data) {
        _this.phases = data.data.JourneyPhases.select;
        _this.loading = false;
      }).catch(function (error) {
        // Error
        _this.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this.loading = false;
      });
    },
    retrieveTasks: function retrieveTasks(page_number) {
      var _this2 = this;
      this.loading = true;
      this.tasks = [];
      this.$apollo.query({
        // Query
        query: window._gql(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)([" query JourneySteps($page: Page)\n          {\n          JourneySteps(page: $page)\n          {\n            pages\n            total\n            select{\n              id\n              title\n              description\n            }\n          }\n        }"]))),
        fetchPolicy: 'no-cache',
        variables: {
          'page': {
            'limit': 20,
            'start': page_number
          }
        }
      }).then(function (data) {
        _this2.tasks = data.data.JourneySteps.select;
        _this2.loading = false;
      }).catch(function (error) {
        // Error
        _this2.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this2.loading = false;
      });
    },
    retrieveCustomerTypes: function retrieveCustomerTypes(page_number) {
      var _this3 = this;
      this.loading = true;
      this.customers = [];
      this.$apollo.query({
        // Query
        query: window._gql(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)([" query CustomerTypes($page: Page)\n          {\n          CustomerTypes(page: $page)\n          {\n            pages\n            total\n            select{\n              id\n              title\n              description\n              linkedJourneyMaps(optional: true) {\n                journeyMap(optional: true) {\n                  id\n                  title\n                  description\n                  additionalattributes\n                }\n              }\n            }\n          }\n        }"]))),
        fetchPolicy: 'no-cache',
        variables: {
          'page': {
            'limit': 20,
            'start': page_number
          }
        }
      }).then(function (data) {
        _this3.$notify({
          title: 'Success',
          message: 'Retrieved Successfully',
          type: 'success',
          duration: 2000
        });
        _this3.customers = data.data.CustomerTypes.select;
        _this3.loading = false;
      }).catch(function (error) {
        // Error
        _this3.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this3.loading = false;
      });
    },
    createJourney: function createJourney() {
      var _this4 = this;
      this.loading = true;
      this.$apollo.mutate({
        // Query
        mutation: window._gql(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2.default)(["mutation createBusinessIdea($busIdea: CreateBusinessIdeaInput!)\n        {\n          createBusinessIdea(input: $busIdea) {\n            id\n            title\n            description\n            problemstatement\n            solution\n            valueproposition\n            additionalattributes\n            fileobject\n            status\n            score\n            customer_type\n            scope\n            scopename\n            type\n            linkedCoreParties(optional: true){\n              coreParty(optional: true){\n                id\n                businessname\n              }\n            }\n            linkedOfferings(optional: true){\n              offering(optional: true){\n                id\n                title\n                description\n              }\n            }\n            linkedJourneyMaps(optional: true){\n              journeyMap(optional: true){\n                id\n                title\n                description\n              }\n            }\n            linkedCustomerTypes(optional: true){\n              customerType(optional: true){\n                id\n                title\n                description\n              }\n            }\n          }\n        }"]))),
        // Parameters
        variables: {
          'busIdea': {
            'title': this.form.title,
            'description': this.form.description,
            'problemstatement': this.form.problemstatement,
            'solution': this.form.solution,
            'valueproposition': this.form.valueproposition,
            'fileobject': [],
            'status': 'Proposed',
            'scope': this.form.scope,
            'scopename': this.form.scopename,
            'score': 0,
            'customer_type': this.form.customer_type,
            'type': 'journey',
            'linkEntityId': this.form.parent_journey_idea_id,
            'linkEntityName': 'JOURNEY_MAP'
          }
        }
      }).then(function (data) {
        _this4.$notify({
          title: 'Success',
          message: 'Created Successfully',
          type: 'success',
          duration: 2000
        });
        var idea = data.data.createBusinessIdea;
        _this4.$emit('create', idea);
        _this4.resetForm();
        _this4.loading = false;
        _this4.$emit('close');
      }).catch(function (error) {
        // Error
        _this4.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this4.loading = false;
      });
    },
    selectCustomerType: function selectCustomerType(customer) {
      this.form.parent_journey_idea_id = customer.linkedJourneyMaps[0].journeyMap.id;
      this.form.parent_journey_idea = customer.linkedJourneyMaps[0].journeyMap.title;
      this.form.customer_type = customer.title;
      this.form.customer_type_id = customer.id;
      this.goToStep('step2');
    },
    goToStep: function goToStep(step) {
      var _this5 = this;
      switch (step) {
        case 'step1':
          this.$emit('title', 'Existing Journey Improvement', 'All journeys are shown below');
          this.activeStep = 'step1';
          break;
        case 'step2':
          this.$emit('title', 'Idea Details', 'How can this Journey be improved');
          this.activeStep = 'step2';
          break;
        case 'step3':
          this.$refs.form.validate(function (valid) {
            if (valid) {
              _this5.$emit('title', 'Confirm Journey Idea', 'Click Next to save your idea');
              _this5.activeStep = 'step3';
            } else {
              _this5.loading = false;
              return false;
            }
          });
          break;
      }
    },
    resetForm: function resetForm() {
      this.activeStep = 'step1';
      this.form = {
        parent_journey_idea_id: '',
        parent_journey_idea: '',
        customer_type: '',
        customer_type_id: '',
        title: '',
        description: '',
        problemstatement: '',
        solution: '',
        valueproposition: '',
        status: '',
        scope: '',
        scopename: ''
      };
    }
  }
};