"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.split");
var _UMLComponent = _interopRequireDefault(require("./components/UMLComponent"));
var _UMLComponentOption = _interopRequireDefault(require("./components/UMLComponentOption"));
var _vueSvgPanZoom = _interopRequireDefault(require("vue-svg-pan-zoom"));
var _utils = require("@/utils");
var _default = exports.default = {
  name: 'GenerateComponentDiagram',
  components: {
    UMLComponentDiagram: _UMLComponent.default,
    UMLComponentOptionDiagram: _UMLComponentOption.default,
    SvgPanZoom: _vueSvgPanZoom.default
  },
  props: {},
  data: function data() {
    return {
      loading: false,
      collapseSidebar: false,
      addComponentDialog: false,
      addCategoryDialog: false,
      selectedPathIndex: null,
      activeCategory: {},
      activeDragElement: {},
      activeComponent: {},
      activeComponentOption: {},
      activeComponentID: '0',
      activeComponentTool: null,
      modelLayers: {
        user_interface: true,
        integration: true,
        business_logic: true,
        tasks: true,
        quality: true
      },
      version: '2.0',
      activeDragElementIndex: 0,
      relationPaths: [],
      temp_relationPaths: [],
      component_form: {
        title: '',
        model: 'user_interface',
        scope: 'beta',
        key: 0,
        x: 0,
        y: 0,
        type: null,
        relations: [],
        services: [],
        options: []
      },
      category_form: {
        name: '',
        complete: false
      },
      defaultProps: {
        children: 'children',
        label: 'title'
      },
      candidate_services_2_0: {
        included: [{
          title: 'Included in model',
          children: [{
            title: 'managePersona'
          }, {
            title: 'manageCategories'
          }, {
            title: 'manageStories'
          }, {
            title: 'manageFeatures'
          }, {
            title: 'manageStatus'
          }, {
            title: 'managePainPoints'
          }, {
            title: 'manageGains'
          }, {
            title: 'ManageCandidateFeatures'
          }, {
            title: 'manageWireframes'
          }, {
            title: 'manageUCD'
          }, {
            title: 'manageNavigation'
          }, {
            title: 'ManageERDesign'
          }, {
            title: 'manageComponentDesign'
          }, {
            title: 'manageIntegrationServiceDesign'
          }, {
            title: 'ManageApplicationArch'
          }, {
            title: 'manageDeploymentArch'
          }]
        }],
        excluded: [{
          title: 'Excluded in model',
          children: [{
            title: 'defineRoadmap'
          }, {
            title: 'commentSocial'
          }, {
            title: 'coachProcess'
          }, {
            title: 'Manage dependencies'
          }, {
            title: 'showDiagram'
          }, {
            title: 'showStatechart'
          }, {
            title: 'manageComponent'
          }, {
            title: 'manageTask'
          }, {
            title: 'coachUCD'
          }, {
            title: 'generateWireframes'
          }, {
            title: 'generateNavigation'
          }, {
            title: 'generateUCD'
          }, {
            title: 'showTimeline'
          }, {
            title: 'showTraceability'
          }, {
            title: 'showTesting'
          }, {
            title: 'approveDesign'
          }, {
            title: 'reviewThirdParty'
          }, {
            title: 'showProgress'
          }, {
            title: 'showFeedback'
          }, {
            title: 'showPriorities'
          }, {
            title: 'runDemo'
          }, {
            title: 'generateDocument'
          }, {
            title: 'importModel'
          }, {
            title: 'importTicket'
          }, {
            title: 'manageIntegration'
          }, {
            title: 'manageWork'
          }, {
            title: 'manageTest'
          }, {
            title: 'manageQuality'
          }, {
            title: 'flagNoun'
          }, {
            title: 'listenFeedback'
          }, {
            title: 'listenRequirement'
          }, {
            title: 'generateAlDocument'
          }, {
            title: 'listenSession'
          }, {
            title: 'managePriority'
          }, {
            title: 'showDefect'
          }]
        }],
        not_found: [{
          title: 'Not found in model',
          children: [{
            title: 'ManageERDesign'
          }, {
            title: 'ManageApplicationArch'
          }, {
            title: 'ManageCategories'
          }, {
            title: 'ManageRole'
          }, {
            title: 'SyntaxCheckerNLP'
          }, {
            title: 'ManageVersions'
          }, {
            title: 'ManageStructure'
          }]
        }]
      },
      candidate_services_1_5: {
        included: [{
          title: 'Included in model',
          children: [{
            title: 'managePersona'
          }, {
            title: 'manageCategories'
          }, {
            title: 'manageStories'
          }, {
            title: 'manageFeatures'
          }]
        }],
        excluded: [{
          title: 'Excluded in model',
          children: [{
            title: 'defineRoadmap'
          }, {
            title: 'showTesting'
          }, {
            title: 'approveDesign'
          }, {
            title: 'reviewThirdParty'
          }, {
            title: 'showProgress'
          }, {
            title: 'showFeedback'
          }, {
            title: 'showPriorities'
          }, {
            title: 'runDemo'
          }, {
            title: 'generateDocument'
          }, {
            title: 'importModel'
          }, {
            title: 'importTicket'
          }, {
            title: 'manageIntegration'
          }]
        }],
        not_found: [{
          title: 'Not found in model',
          children: [{
            title: 'ManageERDesign'
          }, {
            title: 'ManageApplicationArch'
          }]
        }]
      },
      candidate_services_1_4: {
        included: [{
          title: 'Included in model',
          children: [{
            title: 'managePersona'
          }, {
            title: 'manageCategories'
          }, {
            title: 'manageStories'
          }]
        }],
        excluded: [{
          title: 'Excluded in model',
          children: [{
            title: 'defineRoadmap'
          }, {
            title: 'showTesting'
          }, {
            title: 'approveDesign'
          }, {
            title: 'reviewThirdParty'
          }, {
            title: 'showProgress'
          }, {
            title: 'showFeedback'
          }, {
            title: 'showPriorities'
          }]
        }],
        not_found: [{
          title: 'Not found in model',
          children: [{
            title: 'ManageERDesign'
          }, {
            title: 'ManageApplicationArch'
          }]
        }]
      },
      candidate_services_1_3: {
        included: [{
          title: 'Included in model',
          children: [{
            title: 'managePersona'
          }]
        }],
        excluded: [{
          title: 'Excluded in model',
          children: [{
            title: 'defineRoadmap'
          }, {
            title: 'showTesting'
          }, {
            title: 'approveDesign'
          }]
        }],
        not_found: [{
          title: 'Not found in model',
          children: [{
            title: 'ManageERDesign'
          }, {
            title: 'ManageApplicationArch'
          }]
        }]
      },
      candidate_services: {},
      components_2_0: [{
        key: 1,
        x: 360,
        y: 10,
        title: 'JourneyAndRoadMap',
        scope: 'beta',
        type: null,
        model: 'user_interface',
        relations: [],
        services: [{
          name: 'ManageJourneyMap'
        }, {
          name: 'ManageRoadMap'
        }],
        options: [{
          name: 'Info',
          tasks: [],
          data: [],
          bugs: []
        }, {
          name: 'Tech',
          cloud: [],
          server: [],
          ui: []
        }, {
          name: 'API',
          calls: []
        }]
      }, {
        key: 2,
        x: 610,
        y: 10,
        title: 'MarketingAndCampaign',
        scope: null,
        type: null,
        model: 'user_interface',
        relations: [],
        services: [],
        options: []
      }, {
        key: 3,
        x: 250,
        y: 210,
        title: 'ProductUserManagment',
        scope: 'beta',
        type: null,
        model: 'user_interface',
        relations: [{
          to: 4,
          type: 'association'
        }, {
          to: 26,
          type: 'dependency'
        }, {
          to: 10,
          type: 'dependency'
        }],
        services: [{
          name: 'ManagePersona'
        }, {
          name: 'ManageCategories'
        }],
        options: []
      }, {
        key: 4,
        x: 500,
        y: 210,
        title: 'FeatureNStories',
        scope: 'beta',
        type: null,
        model: 'integration',
        relations: [{
          to: 5,
          type: 'dependency'
        }, {
          to: 2,
          type: 'association'
        }, {
          to: 10,
          type: 'dependency'
        }],
        services: [{
          name: 'ManageStories'
        }, {
          name: 'ManageFeatures'
        }, {
          name: 'manageStatus'
        }, {
          name: 'ManagePainPoints'
        }, {
          name: 'ManageGains'
        }, {
          name: 'ManageCandidateFeatures'
        }],
        options: []
      }, {
        key: 5,
        x: 750,
        y: 210,
        title: 'InteractionDesign',
        scope: 'beta',
        type: null,
        model: 'business_logic',
        relations: [{
          to: 6,
          type: 'dependency'
        }, {
          to: 9,
          type: 'dependency'
        }],
        services: [{
          name: 'manageWireframes'
        }, {
          name: 'manageUCD'
        }, {
          name: 'manageNavigation'
        }],
        options: []
      }, {
        key: 6,
        x: 1000,
        y: 210,
        title: 'SoftwareDesign',
        scope: 'beta',
        type: null,
        model: 'tasks',
        relations: [{
          to: 22,
          type: 'association'
        }],
        services: [{
          name: 'ManageERDesign'
        }, {
          name: 'manageComponentDesign'
        }, {
          name: 'manageIntegrationServiceDesign'
        }, {
          name: 'ManageApplicationArch'
        }, {
          name: 'manageDeploymentArch'
        }],
        options: []
      }, {
        key: 7,
        x: 1250,
        y: 10,
        title: 'TransPriorityDependency',
        scope: null,
        type: null,
        model: 'user_interface',
        relations: [{
          to: 10,
          type: 'dependency'
        }],
        services: [],
        options: []
      }, {
        key: 8,
        x: 360,
        y: 410,
        title: 'Utility: NotificationAlerts',
        scope: null,
        type: null,
        model: 'user_interface',
        relations: [{
          to: 11,
          type: 'dependency'
        }, {
          to: 17,
          type: 'dependency'
        }],
        services: [],
        options: []
      }, {
        key: 9,
        x: 610,
        y: 410,
        title: 'DiagramLibrary',
        scope: 'beta',
        type: null,
        model: 'user_interface',
        relations: [{
          to: 11,
          type: 'dependency'
        }],
        services: [{
          name: 'ManageVersions'
        }, {
          name: 'ManageStructure'
        }],
        options: []
      }, {
        key: 10,
        x: 830,
        y: 410,
        title: 'TicketManagment',
        scope: 'beta',
        type: null,
        model: 'user_interface',
        relations: [{
          to: 18,
          type: 'dependency'
        }],
        services: [{
          name: 'ManageProject'
        }, {
          name: 'ManageTicket'
        }, {
          name: 'ManageStatus'
        }],
        options: [{
          name: 'Info',
          categories: [{
            name: 'tasks',
            values: [{
              name: 'Configure AWS API',
              complete: true
            }, {
              name: 'Build service A',
              complete: false
            }, {
              name: 'Custom logging',
              complete: false
            }]
          }, {
            name: 'data',
            values: []
          }, {
            name: 'bugs',
            values: []
          }]
        }, {
          name: 'Tech',
          categories: [{
            name: 'cloud',
            values: []
          }, {
            name: 'server',
            values: []
          }, {
            name: 'ui',
            values: []
          }]
        }, {
          name: 'API',
          categories: [{
            name: 'calls',
            values: [{
              name: 'Create Ticket',
              complete: true
            }, {
              name: 'Get Ticket',
              complete: false
            }, {
              name: 'Sync Ticket',
              complete: false
            }]
          }]
        }]
      }, {
        key: 10,
        x: 10,
        y: 210,
        title: 'PortfolioManager',
        scope: null,
        type: null,
        model: 'user_interface',
        relations: [],
        services: [],
        options: []
      }, {
        key: 11,
        x: 300,
        y: 610,
        height: 400,
        width: 800,
        title: 'ProductMgrAssistant',
        scope: null,
        type: 'parent',
        model: 'user_interface',
        relations: [],
        services: [],
        options: []
      }, {
        key: 12,
        x: 610,
        y: 650,
        title: 'DiagramRules',
        scope: null,
        type: null,
        model: 'user_interface',
        relations: [],
        services: [],
        options: []
      }, {
        key: 13,
        x: 860,
        y: 650,
        title: 'ProcessManager',
        scope: 'beta',
        type: null,
        model: 'user_interface',
        relations: [],
        services: [{
          name: 'ManageWorkFlow'
        }],
        options: []
      }, {
        key: 14,
        x: 360,
        y: 800,
        title: 'VerbNounHelper',
        scope: 'beta',
        type: null,
        model: 'user_interface',
        relations: [],
        services: [{
          name: 'ManageVerbs&Nouns'
        }],
        options: []
      }, {
        key: 15,
        x: 610,
        y: 800,
        title: 'SyntaxChecker',
        scope: 'beta',
        type: null,
        model: 'user_interface',
        relations: [],
        services: [{
          name: 'SyntaxCheckerNLP&Nouns'
        }],
        options: []
      }, {
        key: 15,
        x: 860,
        y: 800,
        title: 'Design Validator',
        scope: null,
        type: null,
        model: 'user_interface',
        relations: [],
        services: [],
        options: []
      }, {
        key: 16,
        x: 10,
        y: 500,
        title: 'IdentityManagment',
        scope: 'beta',
        type: null,
        model: 'user_interface',
        relations: [],
        services: [{
          name: 'ManageRoles'
        }, {
          name: 'ManageUsers'
        }],
        options: []
      }, {
        key: 17,
        x: 10,
        y: 700,
        title: 'Billing',
        scope: null,
        type: null,
        model: 'user_interface',
        relations: [{
          to: 16,
          type: 'dependency'
        }],
        services: [],
        options: []
      }, {
        key: 18,
        x: 1250,
        y: 610,
        height: 500,
        width: 300,
        title: 'External Services',
        scope: 'beta',
        type: 'parent',
        model: 'user_interface',
        relations: [],
        services: [],
        options: []
      }, {
        key: 19,
        x: 1300,
        y: 650,
        title: 'Ticketing',
        scope: null,
        type: null,
        model: 'user_interface',
        relations: [],
        services: [],
        options: []
      }, {
        key: 20,
        x: 1300,
        y: 800,
        title: 'Diagramming',
        scope: null,
        type: null,
        model: 'user_interface',
        relations: [],
        services: [],
        options: []
      }, {
        key: 21,
        x: 1300,
        y: 950,
        title: 'ImportExport',
        scope: null,
        type: null,
        model: 'user_interface',
        relations: [],
        services: [],
        options: []
      }, {
        key: 22,
        x: 1100,
        y: 1150,
        title: 'CodeGeneration',
        scope: null,
        type: null,
        model: 'user_interface',
        relations: [],
        services: [],
        options: []
      }, {
        key: 23,
        x: 1600,
        y: 50,
        height: 400,
        width: 300,
        title: 'MulitUser Space',
        scope: null,
        type: 'parent',
        model: 'user_interface',
        relations: [],
        services: [],
        options: []
      }, {
        key: 24,
        x: 1650,
        y: 150,
        title: 'MUDesign Space',
        scope: null,
        type: null,
        model: 'user_interface',
        relations: [],
        services: [],
        options: []
      }, {
        key: 25,
        x: 1650,
        y: 300,
        title: 'Concurrency Manager',
        scope: null,
        type: null,
        model: 'user_interface',
        relations: [],
        services: [],
        options: []
      }, {
        key: 26,
        x: 1600,
        y: 600,
        height: 600,
        width: 300,
        title: 'Collaboration',
        scope: null,
        type: 'parent',
        model: 'user_interface',
        relations: [],
        services: [],
        options: []
      }, {
        key: 27,
        x: 1650,
        y: 700,
        title: 'UserGrp Managment',
        scope: null,
        type: null,
        model: 'user_interface',
        relations: [],
        services: [],
        options: []
      }, {
        key: 28,
        x: 1650,
        y: 850,
        title: 'Contexual Social',
        scope: 'beta',
        type: null,
        model: 'user_interface',
        relations: [],
        services: [],
        options: []
      }, {
        key: 29,
        x: 1650,
        y: 1000,
        title: 'WebRTC Orchestrator',
        scope: 'beta',
        type: null,
        model: 'user_interface',
        relations: [],
        services: [],
        options: []
      }, {
        key: 30,
        x: 250,
        y: 1100,
        height: 200,
        width: 300,
        title: 'DocumentGeneration',
        scope: null,
        type: 'parent',
        model: 'user_interface',
        relations: [],
        services: [],
        options: []
      }, {
        key: 31,
        x: 300,
        y: 1150,
        title: 'Template Manager',
        scope: null,
        type: null,
        model: 'user_interface',
        relations: [],
        services: [],
        options: []
      }],
      components_1_5: [{
        key: 1,
        x: 360,
        y: 10,
        title: 'JourneyAndRoadMap',
        scope: 'beta',
        type: null,
        model: 'user_interface',
        relations: [],
        services: [{
          name: 'ManageJourneyMap'
        }, {
          name: 'ManageRoadMap'
        }],
        options: []
      }, {
        key: 2,
        x: 610,
        y: 10,
        title: 'MarketingAndCampaign',
        scope: null,
        type: null,
        model: 'user_interface',
        relations: [],
        services: [],
        options: []
      }, {
        key: 3,
        x: 250,
        y: 210,
        title: 'ProductUserManagment',
        scope: 'beta',
        type: null,
        model: 'user_interface',
        relations: [{
          to: 1,
          type: 'association'
        }],
        services: [{
          name: 'ManagePersona'
        }, {
          name: 'ManageCategories'
        }],
        options: []
      }],
      components_1_4: [{
        key: 1,
        x: 360,
        y: 10,
        title: 'JourneyAndRoadMap',
        scope: 'beta',
        type: null,
        model: 'user_interface',
        relations: [],
        services: [{
          name: 'ManageJourneyMap'
        }, {
          name: 'ManageRoadMap'
        }],
        options: []
      }, {
        key: 2,
        x: 610,
        y: 10,
        title: 'MarketingAndCampaign',
        scope: null,
        type: null,
        model: 'user_interface',
        relations: [],
        services: [],
        options: []
      }],
      components_1_3: [{
        key: 1,
        x: 360,
        y: 10,
        title: 'JourneyAndRoadMap',
        scope: 'beta',
        type: null,
        model: 'user_interface',
        relations: [],
        services: [{
          name: 'ManageJourneyMap'
        }, {
          name: 'ManageRoadMap'
        }],
        options: []
      }],
      components: []
    };
  },
  computed: {
    computeCompletePercentage: function computeCompletePercentage() {
      return Math.ceil(this.candidate_services.included[0].children.length / (this.candidate_services.included[0].children.length + this.candidate_services.excluded[0].children.length) * 100);
    }
  },
  watch: {
    modelLayers: {
      handler: function handler(val) {
        this.generateRelationPaths();
      },
      deep: true
    }
  },
  created: function created() {
    var _this = this;
    /* this.loading = true
    this.$store.dispatch('getComponentDiagram').then((data) => {
      this.loading = false
      this.components = data.data
      this.generateRelationPaths()
    }).catch((e) => {
      this.loading = false
      this.$message({
        type: 'error',
        message: e.message
      })
    })*/
    this.components = this.$data.components_2_0;
    this.candidate_services = this.$data.candidate_services_2_0;
    this.generateRelationPaths();
    window.addEventListener('keydown', function (e) {
      if (e.key === 'Delete') {
        if (_this.selectedPathIndex !== null) {
          _this.deleteSelectedPath();
        }
      }
    });
  },
  mounted: function mounted() {
    this.$eventHub.$emit('process-step-load', 'generate-component-diagram');
  },
  methods: {
    deleteSelectedPath: function deleteSelectedPath() {
      var path = this.relationPaths[this.selectedPathIndex];
      var del_index = 0;
      for (var i = 0; i < this.components.length; i++) {
        if (this.components[i].key === path.from) {
          for (var j = 0; j < this.components[i].relations.length; j++) {
            if (this.components[i].relations[j].to === path.to) {
              del_index = j;
            }
          }
          this.components[i].relations.splice(del_index, 1);
        }
      }
      this.generateRelationPaths();
    },
    computePathStroke: function computePathStroke(index) {
      if (index === this.selectedPathIndex) {
        return '#304174';
      } else {
        return 'gray';
      }
    },
    toggleSelectPath: function toggleSelectPath(index) {
      if (this.selectedPathIndex === index) {
        this.selectedPathIndex = null;
      } else {
        this.selectedPathIndex = index;
      }
    },
    computeselectedPathX: function computeselectedPathX(index) {
      if (this.selectedPathIndex !== null) {
        var path = this.relationPaths[this.selectedPathIndex].path.split(' ');
        return path[index + 1].substring(1).split(',')[0];
      }
    },
    computeselectedPathY: function computeselectedPathY(index) {
      if (this.selectedPathIndex !== null) {
        var path = this.relationPaths[this.selectedPathIndex].path.split(' ');
        return path[index + 1].split(',')[1];
      }
    },
    computeselectedPathRadius: function computeselectedPathRadius(index) {
      if (this.selectedPathIndex !== null) {
        return 4;
      } else {
        return 0;
      }
    },
    openAddCategoryDialog: function openAddCategoryDialog(category) {
      this.activeCategory = category;
      this.addCategoryDialog = true;
    },
    addCategory: function addCategory() {
      var item = Object.assign({}, this.category_form);
      for (var i = 0; i < this.activeComponentOption.categories.length; i++) {
        if (this.activeComponentOption.categories[i].name === this.activeCategory.name) {
          this.activeComponentOption.categories[i].values.push(item);
        }
      }
      this.addCategoryDialog = false;
    },
    addComponent: function addComponent() {
      var component = Object.assign({}, this.component_form);
      component.key = this.components.length;
      this.components.push(component);
      this.generateRelationPaths();
      this.addComponentDialog = false;
    },
    loadComponentDiagramVersion: function loadComponentDiagramVersion(version) {
      switch (version) {
        case '2.0':
          this.components = this.$data.components_2_0;
          this.candidate_services = this.$data.candidate_services_2_0;
          this.generateRelationPaths();
          break;
        case '1.5':
          this.components = this.$data.components_1_5;
          this.candidate_services = this.$data.candidate_services_1_5;
          this.generateRelationPaths();
          break;
        case '1.4':
          this.components = this.$data.components_1_4;
          this.candidate_services = this.$data.candidate_services_1_4;
          this.generateRelationPaths();
          break;
        case '1.3':
          this.components = this.$data.components_1_3;
          this.candidate_services = this.$data.candidate_services_1_3;
          this.generateRelationPaths();
          break;
        default:
          this.components = this.$data.components_2_0;
          this.candidate_services = this.$data.candidate_services_2_0;
          this.generateRelationPaths();
          break;
      }
    },
    optionClicked: function optionClicked(component, id, option) {
      if (this.activeComponentTool === option.name && this.activeComponentID === id) {
        this.activeComponentTool = null;
        this.activeComponent = {};
        this.activeComponentOption = {};
        this.activeComponentID = '0';
      } else {
        this.activeComponent = component;
        this.activeComponentID = id;
        this.activeComponentOption = option;
        this.activeComponentTool = option.name;
      }
    },
    deleteComponent: function deleteComponent(index) {
      var component_key = this.components[index].key;
      // remove reference of this component in any other relation
      for (var i = 0; i < this.components.length; i++) {
        var to_index = null;
        for (var j = 0; j < this.components[i].relations.length; j++) {
          if (this.components[i].relations[j].to === component_key) {
            to_index = j;
          }
        }
        this.components[i].relations.splice(to_index, 1);
      }

      // remove component
      this.components.splice(index, 1);
      this.generateRelationPaths();
    },
    deleteService: function deleteService(component, service, index) {
      var included_index = null;
      // Update candidate services tree
      for (var i = 0; i < this.candidate_services.included[0].children.length; i++) {
        if (this.candidate_services.included[0].children[i].title === service.title) {
          included_index = i;
        }
      }

      // remove from included
      if (included_index !== null) {
        this.candidate_services.included[0].children.splice(included_index, 1);
      }

      // add to excluded
      this.candidate_services.excluded[0].children.push({
        title: service.name
      });

      // delete
      this.activeComponent = component;
      this.activeComponent.services.splice(index, 1);
    },
    isVisible: function isVisible(model) {
      if (model === 'user_interface') {
        return this.modelLayers.user_interface;
      }
      if (model === 'integration') {
        return this.modelLayers.integration;
      }
      if (model === 'business_logic') {
        return this.modelLayers.business_logic;
      }
      if (model === 'tasks') {
        return this.modelLayers.tasks;
      }
      if (model === 'quality') {
        return this.modelLayers.quality;
      }
    },
    checkIsTouchDevice: function checkIsTouchDevice() {
      return (0, _utils.isTouchDevice)();
    },
    goToOrganiseLayers: function goToOrganiseLayers() {
      this.$emit('go-next');
    },
    generateComponentID: function generateComponentID(index) {
      return 'component_' + index;
    },
    generatePathID: function generatePathID(index) {
      return 'path_' + index;
    },
    startPathMove: function startPathMove(_ref) {
      var el = _ref.el,
        deltaX = _ref.deltaX,
        deltaY = _ref.deltaY,
        offsetX = _ref.offsetX,
        offsetY = _ref.offsetY,
        clientX = _ref.clientX,
        clientY = _ref.clientY,
        first = _ref.first,
        last = _ref.last;
      if (deltaX === undefined || deltaY === undefined) {
        return;
      }
      var index = parseInt(el.id.replace('path_', ''));
      var path = this.relationPaths[this.selectedPathIndex].path.split(' ');
      var point = path[index + 1].split(',');
      point[1] = parseInt(point[1]) + parseInt(deltaY);
      point[0] = 'L' + (parseInt(point[0].substring(1)) + parseInt(deltaX));
      var point_updated = point.join(',');
      path[index + 1] = point_updated;
      this.relationPaths[this.selectedPathIndex].path = path.join(' ');
      this.relationPaths[this.selectedPathIndex].custom = true;
      this.temp_relationPaths = this.$data.relationPaths;
    },
    startComponentMove: function startComponentMove(_ref2) {
      var el = _ref2.el,
        deltaX = _ref2.deltaX,
        deltaY = _ref2.deltaY,
        offsetX = _ref2.offsetX,
        offsetY = _ref2.offsetY,
        clientX = _ref2.clientX,
        clientY = _ref2.clientY,
        first = _ref2.first,
        last = _ref2.last;
      if (deltaX === undefined || deltaY === undefined) {
        return;
      }
      var index = el.id.replace('component_', '');
      if (this.components[index].type !== 'parent') {
        this.components[index].x += parseInt(deltaX);
        this.components[index].y += parseInt(deltaY);
        this.generateRelationPaths();
      }
    },
    allowDrop: function allowDrop(draggingNode, dropNode, type) {
      return false;
    },
    handleDragStart: function handleDragStart(node, ev) {
      if (node.data.hasOwnProperty('title')) {
        for (var i = 0; i < this.candidate_services.excluded[0].children.length; i++) {
          if (this.candidate_services.excluded[0].children[i].title === node.data.title) {
            this.activeDragElement = this.candidate_services.excluded[0].children[i];
            this.activeDragElementIndex = i;
          }
        }
      }
    },
    handleDrop: function handleDrop(event, id) {
      event.preventDefault(); // !important

      var index = id.replace('component_', '');
      this.components[index].services.push({
        name: this.activeDragElement.title
      });
      this.candidate_services.included[0].children.push(this.activeDragElement);
      this.candidate_services.excluded[0].children.splice(this.activeDragElementIndex, 1);
    },
    handleDragOver: function handleDragOver(event, id) {
      event.preventDefault(); // !important
    },
    checkIfCustomPathExists: function checkIfCustomPathExists(from, to) {
      for (var i = 0; i < this.temp_relationPaths.length; i++) {
        if (this.temp_relationPaths[i].custom && this.temp_relationPaths[i].from === from && this.temp_relationPaths[i].to === to) {
          return [true, i];
        }
      }
      return [false, 0];
    },
    generateRelationPaths: function generateRelationPaths() {
      this.relationPaths = [];
      for (var i = 0; i < this.components.length; i++) {
        // Check if model visible
        if (this.isVisible(this.components[i].model)) {
          for (var j = 0; j < this.components[i].relations.length; j++) {
            // Retrieve model and check if visible
            var to_component = this.getComponentFromKey(this.components[i].relations[j].to);
            if (this.isVisible(to_component.model)) {
              // Check if custom path exists
              var response = this.checkIfCustomPathExists(this.components[i].key, this.components[i].relations[j].to);
              // console.log(this.isVisible(to_component.model) +',' +this.components[i].key+','+this.components[i].relations[j].to+','+response[0])
              // console.log(response)
              if (response[0]) {
                this.relationPaths.push(response[1]);
                continue;
              }
              // Set stroke if type dependency
              var stroke = 0;
              if (this.components[i].relations[j].type === 'dependency') {
                stroke = 4;
              }
              if (this.components[i].x + 200 < to_component.x && this.components[i].y + 90 > to_component.y && this.components[i].y - 90 < to_component.y) {
                // To right and within height of component
                var start_x1 = this.components[i].x + 200;
                var start_y1 = this.components[i].y + 45;
                var start_x2 = to_component.x;
                var start_y2 = to_component.y + 45;
                var end_x1 = start_x1 + (start_x2 - start_x1) / 2;
                var end_y1 = start_y1;
                var end_x2 = start_x2 - (start_x2 - start_x1) / 2;
                var end_y2 = start_y2;
                var path = 'M' + start_x1 + ',' + start_y1 + ' L' + end_x1 + ',' + end_y1 + ' L' + end_x2 + ',' + end_y2 + ' L' + start_x2 + ',' + start_y2;
                this.relationPaths.push({
                  path: path,
                  stroke: stroke,
                  from: this.components[i].key,
                  to: to_component.key,
                  custom: false
                });
              } else if (this.components[i].x - 200 > to_component.x && this.components[i].y + 90 > to_component.y && this.components[i].y - 90 < to_component.y) {
                // To left within height of component
                start_x1 = this.components[i].x;
                start_y1 = this.components[i].y + 45;
                start_x2 = to_component.x + 200;
                start_y2 = to_component.y + 45;
                end_x1 = start_x1 + (start_x2 - start_x1) / 2;
                end_y1 = start_y1;
                end_x2 = start_x2 - (start_x2 - start_x1) / 2;
                end_y2 = start_y2;
                path = 'M' + start_x1 + ',' + start_y1 + ' L' + end_x1 + ',' + end_y1 + ' L' + end_x2 + ',' + end_y2 + ' L' + start_x2 + ',' + start_y2;
                this.relationPaths.push({
                  path: path,
                  stroke: stroke,
                  from: this.components[i].key,
                  to: to_component.key,
                  custom: false
                });
              } else if (this.components[i].x + 200 > to_component.x && this.components[i].x - 200 < to_component.x && this.components[i].y + 90 < to_component.y) {
                // to bottom within same width of component
                start_x1 = this.components[i].x + 100;
                start_y1 = this.components[i].y + 90;
                start_x2 = to_component.x + 100;
                start_y2 = to_component.y;
                end_x1 = start_x1;
                end_y1 = start_y1 + (start_y2 - start_y1) / 2;
                end_x2 = start_x2;
                end_y2 = start_y2 - (start_y2 - start_y1) / 2;
                path = 'M' + start_x1 + ',' + start_y1 + ' L' + end_x1 + ',' + end_y1 + ' L' + end_x2 + ',' + end_y2 + ' L' + start_x2 + ',' + start_y2;
                this.relationPaths.push({
                  path: path,
                  stroke: stroke,
                  from: this.components[i].key,
                  to: to_component.key,
                  custom: false
                });
              } else if (this.components[i].x + 200 > to_component.x && this.components[i].x - 200 < to_component.x && this.components[i].y > to_component.y + 90) {
                // to top within same width of component
                start_x1 = this.components[i].x + 100;
                start_y1 = this.components[i].y;
                start_x2 = to_component.x + 100;
                start_y2 = to_component.y + 90;
                end_x1 = start_x1;
                end_y1 = start_y1 + (start_y2 - start_y1) / 2;
                end_x2 = start_x2;
                end_y2 = start_y2 - (start_y2 - start_y1) / 2;
                path = 'M' + start_x1 + ',' + start_y1 + ' L' + end_x1 + ',' + end_y1 + ' L' + end_x2 + ',' + end_y2 + ' L' + start_x2 + ',' + start_y2;
                this.relationPaths.push({
                  path: path,
                  stroke: stroke,
                  from: this.components[i].key,
                  to: to_component.key,
                  custom: false
                });
              } else if (this.components[i].x + 100 < to_component.x && this.components[i].y + 45 < to_component.y) {
                // Condition 1 - to_component is to the right and below component
                start_x1 = this.components[i].x + 200;
                start_y1 = this.components[i].y + 45;
                start_x2 = to_component.x + 100;
                start_y2 = to_component.y;
                path = 'M' + start_x1 + ',' + start_y1 + ' L' + start_x2 + ',' + start_y1 + ' L' + start_x2 + ',' + start_y2;
                this.relationPaths.push({
                  path: path,
                  stroke: stroke,
                  from: this.components[i].key,
                  to: to_component.key,
                  custom: false
                });
              } else if (this.components[i].x + 100 < to_component.x && this.components[i].y + 45 > to_component.y) {
                // Condition 2 - to_component is to the right and above component
                start_x1 = this.components[i].x + 200;
                start_y1 = this.components[i].y + 45;
                start_x2 = to_component.x + 100;
                start_y2 = to_component.y + 90;
                path = 'M' + start_x1 + ',' + start_y1 + ' L' + start_x2 + ',' + start_y1 + ' L' + start_x2 + ',' + start_y2;
                this.relationPaths.push({
                  path: path,
                  stroke: stroke,
                  from: this.components[i].key,
                  to: to_component.key,
                  custom: false
                });
              } else if (this.components[i].x + 100 > to_component.x && this.components[i].y + 45 < to_component.y) {
                // Condition 3 - to_component to the left and below component
                start_x1 = this.components[i].x;
                start_y1 = this.components[i].y + 45;
                start_x2 = to_component.x + 100;
                start_y2 = to_component.y;
                path = 'M' + start_x1 + ',' + start_y1 + ' L' + start_x2 + ',' + start_y1 + ' L' + start_x2 + ',' + start_y2;
                this.relationPaths.push({
                  path: path,
                  stroke: stroke,
                  from: this.components[i].key,
                  to: to_component.key,
                  custom: false
                });
              } else if (this.components[i].x + 100 > to_component.x && this.components[i].y + 45 > to_component.y) {
                // Condition 4 - to_component to left and above component
                start_x1 = this.components[i].x;
                start_y1 = this.components[i].y + 45;
                start_x2 = to_component.x + 100;
                start_y2 = to_component.y + 90;
                path = 'M' + start_x1 + ',' + start_y1 + ' L' + start_x2 + ',' + start_y1 + ' L' + start_x2 + ',' + start_y2;
                this.relationPaths.push({
                  path: path,
                  stroke: stroke,
                  from: this.components[i].key,
                  to: to_component.key,
                  custom: false
                });
              }
            }
          }
        }
      }
    },
    getComponentFromKey: function getComponentFromKey(key) {
      for (var i = 0; i < this.components.length; i++) {
        if (this.components[i].key === key) {
          return this.components[i];
        }
      }
    }
  }
};