"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/taggedTemplateLiteral.js"));
var _Goals = _interopRequireDefault(require("@/components/Goals"));
var _competitors = _interopRequireDefault(require("./competitors"));
var _axios = _interopRequireDefault(require("axios"));
var _templateObject; // import BMCComponent from './bmc'
var _default = exports.default = {
  name: 'Strategy',
  components: {
    GoalComponent: _Goals.default,
    CompetitorsComponent: _competitors.default
    // BMCComponent
  },
  props: {
    offering: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      tab_loading: false,
      loading: false,
      goalDetailsDialog: false,
      addSubInitiativeDialog: false,
      strategic_goals: [],
      expandedInitiatives: [],
      users: [],
      initiatives: [{
        title: 'Initiative 1',
        description: 'Initiative Description',
        priority: 'High',
        owner: 'John Doe',
        org_unit: 'Information Technology',
        type: 'Product',
        context: 'Context',
        when: [{
          title: '2022'
        }, {
          title: '2021'
        }],
        oou: [{
          title: 'ou1'
        }, {
          title: 'ou2'
        }],
        start: '22/12/22',
        end: '22/12/22',
        linked: true,
        sub_initiatives: [{
          title: 'Sub Initiative 1',
          description: 'Sub Initiative Description',
          owner: 'John Doe',
          org_unit: 'Information Technology',
          type: 'Product',
          context: 'Context',
          when: [{
            title: '2022'
          }, {
            title: '2021'
          }],
          start: '22/12/22',
          end: '22/12/22',
          achieved: true,
          risks: [{
            title: 'Risk 1'
          }, {
            title: 'Risk 2'
          }]
        }, {
          title: 'Sub Initiative 2',
          description: 'Sub Initiative Description',
          owner: 'John Doe',
          org_unit: 'Information Technology',
          type: 'Product',
          context: 'Context',
          when: [{
            title: '2022'
          }, {
            title: '2021'
          }],
          start: '22/12/22',
          end: '22/12/22',
          achieved: false,
          risks: []
        }, {
          title: 'Sub Initiative 3',
          description: 'Sub Initiative Description',
          owner: 'John Doe',
          org_unit: 'Information Technology',
          type: 'Product',
          context: 'Context',
          when: [{
            title: '2022'
          }, {
            title: '2021'
          }],
          start: '22/12/22',
          end: '22/12/22',
          achieved: false,
          risks: [{
            title: 'Risk 1'
          }, {
            title: 'Risk 2'
          }]
        }]
      }, {
        title: 'Initiative 2',
        description: 'Initiative Description',
        priority: 'High',
        owner: 'John Doe',
        org_unit: 'Information Technology',
        type: 'Product',
        context: 'Context',
        when: [{
          title: '2022'
        }, {
          title: '2021'
        }],
        oou: [{
          title: 'ou1'
        }, {
          title: 'ou2'
        }],
        start: '22/12/22',
        end: '22/12/22',
        linked: true,
        sub_initiatives: [{
          title: 'Sub Initiative 1',
          description: 'Sub Initiative Description',
          owner: 'John Doe',
          org_unit: 'Information Technology',
          type: 'Product',
          context: 'Context',
          when: [{
            title: '2022'
          }, {
            title: '2021'
          }],
          start: '22/12/22',
          end: '22/12/22',
          achieved: true,
          risks: [{
            title: 'Risk 1'
          }, {
            title: 'Risk 2'
          }]
        }, {
          title: 'Sub Initiative 2',
          description: 'Sub Initiative Description',
          owner: 'John Doe',
          org_unit: 'Information Technology',
          type: 'Product',
          context: 'Context',
          when: [{
            title: '2022'
          }, {
            title: '2021'
          }],
          start: '22/12/22',
          end: '22/12/22',
          achieved: false,
          risks: []
        }, {
          title: 'Sub Initiative 3',
          description: 'Sub Initiative Description',
          owner: 'John Doe',
          org_unit: 'Information Technology',
          type: 'Product',
          context: 'Context',
          when: [{
            title: '2022'
          }, {
            title: '2021'
          }],
          start: '22/12/22',
          end: '22/12/22',
          achieved: false,
          risks: []
        }]
      }, {
        title: 'Initiative 3',
        description: 'Initiative Description',
        priority: 'High',
        owner: 'John Doe',
        org_unit: 'Information Technology',
        type: 'Product',
        context: 'Context',
        when: [{
          title: '2022'
        }, {
          title: '2021'
        }],
        oou: [{
          title: 'ou1'
        }, {
          title: 'ou2'
        }],
        start: '22/12/22',
        end: '22/12/22',
        linked: true,
        sub_initiatives: [{
          title: 'Sub Initiative 1',
          description: 'Sub Initiative Description',
          owner: 'John Doe',
          org_unit: 'Information Technology',
          type: 'Product',
          context: 'Context',
          when: [{
            title: '2022'
          }, {
            title: '2021'
          }],
          start: '22/12/22',
          end: '22/12/22',
          achieved: false,
          risks: []
        }, {
          title: 'Sub Initiative 2',
          description: 'Sub Initiative Description',
          owner: 'John Doe',
          org_unit: 'Information Technology',
          type: 'Product',
          context: 'Context',
          when: [{
            title: '2022'
          }, {
            title: '2021'
          }],
          start: '22/12/22',
          end: '22/12/22',
          achieved: false,
          risks: []
        }, {
          title: 'Sub Initiative 3',
          description: 'Sub Initiative Description',
          owner: 'John Doe',
          org_unit: 'Information Technology',
          type: 'Product',
          context: 'Context',
          when: [{
            title: '2022'
          }, {
            title: '2021'
          }],
          start: '22/12/22',
          end: '22/12/22',
          achieved: true,
          risks: []
        }]
      }, {
        title: 'Initiative 4',
        description: 'Initiative Description',
        priority: 'High',
        owner: 'John Doe',
        org_unit: 'Information Technology',
        type: 'Product',
        context: 'Context',
        when: [{
          title: '2022'
        }, {
          title: '2021'
        }],
        oou: [{
          title: 'ou1'
        }, {
          title: 'ou2'
        }],
        start: '22/12/22',
        end: '22/12/22',
        linked: true,
        sub_initiatives: [{
          title: 'Sub Initiative 1',
          description: 'Sub Initiative Description',
          owner: 'John Doe',
          org_unit: 'Information Technology',
          type: 'Product',
          context: 'Context',
          when: [{
            title: '2022'
          }, {
            title: '2021'
          }],
          start: '22/12/22',
          end: '22/12/22',
          achieved: false,
          risks: []
        }, {
          title: 'Sub Initiative 2',
          description: 'Sub Initiative Description',
          owner: 'John Doe',
          org_unit: 'Information Technology',
          type: 'Product',
          context: 'Context',
          when: [{
            title: '2022'
          }, {
            title: '2021'
          }],
          start: '22/12/22',
          end: '22/12/22',
          achieved: false,
          risks: []
        }, {
          title: 'Sub Initiative 3',
          description: 'Sub Initiative Description',
          owner: 'John Doe',
          org_unit: 'Information Technology',
          type: 'Product',
          context: 'Context',
          when: [{
            title: '2022'
          }, {
            title: '2021'
          }],
          start: '22/12/22',
          end: '22/12/22',
          achieved: false,
          risks: []
        }]
      }],
      selectedGoal: {},
      selectedGoalIndex: 0,
      selectedInitiativeIndex: 0,
      selectedInitiative: {},
      sub_initiative_form: {
        title: '',
        description: '',
        owner: '',
        org_unit: '',
        type: '',
        context: '',
        when: '',
        start: '',
        end: '',
        achieved: false,
        risks: []
      }
    };
  },
  mounted: function mounted() {
    this.$eventHub.$on('offering-changed', this.reloadOfferings);
    this.retrieveGoals(1);
  },
  methods: {
    showMVP: function showMVP() {
      return this.$store.state.settings.showMVP;
    },
    reloadOfferings: function reloadOfferings() {
      this.retrieveGoals(1);
    },
    subInitiativeTableRowClassName: function subInitiativeTableRowClassName(_ref) {
      var row = _ref.row,
        rowIndex = _ref.rowIndex;
      if (row.achieved) {
        return 'achieved-row mt-10';
      } else {
        return 'mt-10';
      }
    },
    updateExpandedInitiativesList: function updateExpandedInitiativesList(row, expandedRow) {
      this.expandedInitiatives = expandedRow;
    },
    createSubInitiative: function createSubInitiative() {
      var sub_initiative = Object.assign({}, this.sub_initiative_form);
      this.selectedInitiative.sub_initiatives.push(sub_initiative);
      this.addSubInitiativeDialog = false;
    },
    computeUserName: function computeUserName(user) {
      return user.firstName + ' ' + user.lastName;
    },
    retrieveUserList: function retrieveUserList() {
      var _this = this;
      this.loading = true;
      var headers = {
        'Content-Type': 'application/json',
        'x-party-id': this.$store.getters.partyId,
        'x-user-id': this.$store.getters.id
      };
      _axios.default.get(process.env.VUE_APP_REST_API_ENDPOINT + 'user/?extended=LEVEL0', {
        headers: headers
      }).then(function (response) {
        _this.loading = false;
        _this.users = response.data.data;
      }).catch(function (error) {
        if (error.response.status === 400) {
          _this.loading = false;
          _this.$notify({
            title: 'Error',
            message: error.response.message,
            type: 'error',
            duration: 2000
          });
        }
      });
    },
    isInitiativeExpanded: function isInitiativeExpanded(initiative) {
      for (var i = 0; i < this.expandedInitiatives.length; i++) {
        if (initiative.title === this.expandedInitiatives[i].title) {
          return true;
        }
      }
      return false;
    },
    openAddSubInitiativeDialog: function openAddSubInitiativeDialog(initiative, initiative_index) {
      this.retrieveUserList(1);
      this.selectedInitiative = initiative;
      this.selectedInitiativeIndex = initiative_index;
      this.addSubInitiativeDialog = true;
    },
    computeInitiativeLinkStyle: function computeInitiativeLinkStyle(initiative) {
      if (!initiative.linked) {
        return 'color:#F56C6C';
      } else {
        return 'color:#67C23A';
      }
    },
    computeInitiativeLinkIcon: function computeInitiativeLinkIcon(initiative) {
      if (initiative.linked) {
        return 'el-icon-connection';
      }
      return 'el-icon-link';
    },
    openGoalDetailsDialog: function openGoalDetailsDialog(goal) {
      this.selectedGoal = goal;
      this.goalDetailsDialog = true;
    },
    finishGoal: function finishGoal(initiative_index, sub_initiative_index) {
      this.initiatives[initiative_index].sub_initiatives[sub_initiative_index].achieved = true;
    },
    revertGoal: function revertGoal(initiative_index, sub_initiative_index) {
      this.initiatives[initiative_index].sub_initiatives[sub_initiative_index].achieved = false;
    },
    deleteGoal: function deleteGoal(initiative_index, sub_initiative_index) {
      var _this2 = this;
      this.$confirm('This will permanently delete the initiative. Continue?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(function () {
        _this2.initiatives[initiative_index].sub_initiatives.splice(sub_initiative_index, 1);
      }).catch(function () {
        _this2.$message({
          type: 'info',
          message: 'Delete canceled'
        });
      });
    },
    redirectBusinessGoal: function redirectBusinessGoal() {
      this.$router.push({
        name: 'Business Strategy',
        query: {
          name: 'Business Goals'
        }
      });
    },
    retrieveGoals: function retrieveGoals(page_number) {
      var _this3 = this;
      this.strategic_goals = [];
      this.loading = true;
      this.$apollo.query({
        // Query
        query: window._gql(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)([" query BusinessGoals($page: Page)\n        {\n        BusinessGoals(page: $page)\n        {\n          pages\n          total\n          select{\n            id\n            title\n            description\n            achieved\n            priority\n            status\n            strategy\n          }\n        }\n      }"]))),
        fetchPolicy: 'no-cache',
        variables: {
          'page': {
            'limit': 20,
            'start': page_number
          }
        }
      }).then(function (data) {
        _this3.strategic_goals = data.data.BusinessGoals.select.reverse();
        if (_this3.strategic_goals.length > 0) {
          _this3.selectedGoal = _this3.strategic_goals[0];
        }
        _this3.selectedGoalIndex = 0;
        _this3.loading = false;
      }).catch(function (error) {
        // Error
        _this3.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this3.loading = false;
      });
    }
  }
};