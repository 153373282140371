"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _default = exports.default = {
  name: 'UserAvatarComponent',
  props: {
    x: {
      type: Number,
      required: true,
      default: 0
    },
    y: {
      type: Number,
      required: true,
      default: 0
    },
    user: {
      type: String,
      required: true,
      default: ''
    }
  },
  data: function data() {
    return {
      isUserSelected: false
    };
  },
  methods: {
    describeArc: function describeArc(x, y, radius, startAngle, endAngle) {
      var start = this.polarToCartesian(x, y, radius, endAngle);
      var end = this.polarToCartesian(x, y, radius, startAngle);
      var largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';
      var d = ['M', start.x, start.y, 'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y].join(' ');
      return d;
    },
    polarToCartesian: function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
      var angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;
      return {
        x: centerX + radius * Math.cos(angleInRadians),
        y: centerY + radius * Math.sin(angleInRadians)
      };
    },
    addPainPoint: function addPainPoint() {
      this.$notify({
        title: 'Add PainPoint UI',
        message: 'Add PainPoint UI to be displayed at this event',
        type: 'info',
        duration: 2000
      });
    },
    addDesignGoals: function addDesignGoals() {
      this.$notify({
        title: 'Add Design Goal UI',
        message: 'Add Design Goal UI to be displayed at this event',
        type: 'info',
        duration: 2000
      });
    }
  }
};