"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _vuex = require("vuex");
var _Logo = _interopRequireDefault(require("./Logo"));
var _SidebarItem = _interopRequireDefault(require("./SidebarItem"));
var _variables2 = _interopRequireDefault(require("@/styles/variables.scss"));
var _default = exports.default = {
  components: {
    SidebarItem: _SidebarItem.default,
    Logo: _Logo.default
  },
  data: function data() {
    return {};
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['permission_routes', 'modules', 'sidebar'])), {}, {
    /* computedGlobalMenu() {
      if (this.modules !== undefined) {
        return this.permission_routes.filter(function(route) {
          if (route.meta) {
            return this.modules.includes(route.meta.module)
          } else {
            return true
          }
        })
      } else {
        return true
      }
    },*/
    computeGeneralMenu: function computeGeneralMenu() {
      if (this.modules !== undefined) {
        return this.permission_routes.filter(function (route) {
          if (!route.hasOwnProperty('meta')) {
            return route.path === '/portfolio-manager' || route.path === '/ideas' || route.path === '/innovation_hub' || route.path === '/risks' || route.path === '/reports';
          } else if (route.hasOwnProperty('meta') && !route.meta.hasOwnProperty('type')) {
            return route.path === '/portfolio-manager' || route.path === '/ideas' || route.path === '/innovation_hub' || route.path === '/risks' || route.path === '/reports';
          } else {
            return true;
          }
        });
      } else {
        return true;
      }
    },
    computeBusinessMenu: function computeBusinessMenu() {
      return this.permission_routes.filter(function (route) {
        if (!route.hasOwnProperty('meta')) {
          return route.path === '/my-business' || route.path === '/customer-types';
        } else if (route.hasOwnProperty('meta') && !route.meta.hasOwnProperty('type')) {
          return route.path === '/my-business' || route.path === '/customer-types';
        }
      });
    },
    computeOfferingMenu: function computeOfferingMenu() {
      return this.permission_routes.filter(function (route) {
        if (!route.hasOwnProperty('meta')) {
          return route.path === '/offerings' || route.path === '/delivery';
        } else if (route.hasOwnProperty('meta') && !route.meta.hasOwnProperty('type')) {
          return route.path === '/offerings' || route.path === '/delivery';
        }
      });
    },
    computeDigitalPortfolioMenu: function computeDigitalPortfolioMenu() {
      return this.permission_routes.filter(function (route) {
        if (!route.hasOwnProperty('meta')) {
          return route.path === '/digital-portfolio';
        } else if (route.hasOwnProperty('meta') && !route.meta.hasOwnProperty('type')) {
          return route.path === '/digital-portfolio';
        }
      });
    },
    computeProductMenu: function computeProductMenu() {
      return this.permission_routes.filter(function (route) {
        return route.hasOwnProperty('meta') && route.meta.hasOwnProperty('type') && route.meta.type === 'module';
      });
    },
    computeDefaultMenu: function computeDefaultMenu() {
      return this.permission_routes.filter(function (route) {
        if (!route.hasOwnProperty('meta')) {
          return route.path !== '/portfolio-manager' && route.path !== '/digital-portfolio' && route.path !== '/ideas' && route.path !== '/innovation_hub' && route.path !== '/my-business' && route.path !== '/customer-types' && route.path !== '/offerings' && route.path !== '/risks' && route.path !== '/delivery' && route.path !== '/reports';
        } else if (route.hasOwnProperty('meta') && !route.meta.hasOwnProperty('type')) {
          return route.path !== '/portfolio-manager' && route.path !== '/digital-portfolio' && route.path !== '/ideas' && route.path !== '/innovation_hub' && route.path !== '/my-business' && route.path !== '/customer-types' && route.path !== '/offerings' && route.path !== '/risks' && route.path !== '/delivery' && route.path !== '/reports';
        }
      });
    },
    /* computeActiveProductTitle() {
      return this.$store.getters.product.title
    },
    computeActiveProductTitleCondensed() {
      if(this.$store.getters.product.title.length > 0) {
        return this.$store.getters.product.title.charAt(0)
      }
      return ''
    },*/
    activeMenu: function activeMenu() {
      var route = this.$route;
      var meta = route.meta,
        path = route.path;
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
    showLogo: function showLogo() {
      return this.$store.state.settings.sidebarLogo;
    },
    variables: function variables() {
      return _variables2.default;
    },
    isCollapse: function isCollapse() {
      return !this.sidebar.opened;
    }
  })
};