"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-button", {
    staticClass: "fr",
    attrs: {
      icon: _vm.loading ? "el-icon-loading" : "el-icon-refresh",
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.syncProjects
    }
  }, [_vm._v("Sync")]), _vm._v(" "), _c("el-table", {
    staticStyle: {
      width: "100%",
      "font-size": "0.9em"
    },
    attrs: {
      data: _vm.filteredProjects,
      stripe: "",
      size: "micro"
    },
    on: {
      "selection-change": _vm.handleSelectionChange
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "selection",
      width: "55"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Prodiggi Project",
      prop: "name"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "External Project",
      prop: "name"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "text-right mt-10"
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.importProjects
    }
  }, [_vm._v("Add")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: _vm.closeUI
    }
  }, [_vm._v("Close")])], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;