"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  name: 'Step4',
  components: {},
  props: {},
  data: function data() {
    return {};
  },
  mounted: function mounted() {
    this.$eventHub.$emit('process-step-load', 'step-4');
  },
  methods: {
    goToStep3: function goToStep3() {
      this.$emit('go-prev');
    }
  }
};