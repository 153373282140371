"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.activeStep === "step1" ? _c("div", [_vm.filteredCustomers.length > 0 ? _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      data: _vm.filteredCustomers,
      stripe: "",
      size: "mini"
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "title",
      label: "Customer Type"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Journey"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.linkedJourneyMaps.length > 0 ? _c("span", [_vm._v(_vm._s(scope.row.linkedJourneyMaps[0].journeyMap.title))]) : _c("span", {
          staticStyle: {
            color: "darkred"
          }
        }, [_vm._v(" No Journey found. ")])];
      }
    }], null, false, 2189569007)
  }), _vm._v(" "), _c("el-table-column", {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-button", {
          attrs: {
            type: "primary",
            size: "mini",
            disabled: scope.row.linkedJourneyMaps.length === 0
          },
          on: {
            click: function click($event) {
              return _vm.selectCustomerType(scope.row);
            }
          }
        }, [_vm._v("Select")])];
      }
    }], null, false, 1623630763)
  })], 1) : _c("el-empty", {
    attrs: {
      description: "Once you have created a journey, you can create ideas"
    }
  })], 1) : _vm._e()]), _vm._v(" "), _c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      "label-width": "200px",
      rules: _vm.journeyRules
    }
  }, [_c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.activeStep === "step2" ? _c("div", {
    staticStyle: {
      "animation-fill-mode": "forwards"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Idea Name",
      prop: "title"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textfield",
      size: "small"
    },
    model: {
      value: _vm.form.title,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "title", $$v);
      },
      expression: "form.title"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Idea Description",
      prop: "description"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      size: "small"
    },
    model: {
      value: _vm.form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "description", $$v);
      },
      expression: "form.description"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Background",
      prop: "problemstatement"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      size: "small"
    },
    model: {
      value: _vm.form.problemstatement,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "problemstatement", $$v);
      },
      expression: "form.problemstatement"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Justification",
      prop: "solution"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      size: "small"
    },
    model: {
      value: _vm.form.solution,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "solution", $$v);
      },
      expression: "form.solution"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Value Proposition",
      prop: "valueproposition"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      size: "small"
    },
    model: {
      value: _vm.form.valueproposition,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "valueproposition", $$v);
      },
      expression: "form.valueproposition"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Idea Scope",
      prop: "scope"
    }
  }, [_c("el-select", {
    staticClass: "mr-5",
    attrs: {
      placeholder: "Select Scope",
      size: "mini"
    },
    model: {
      value: _vm.form.scope,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "scope", $$v);
      },
      expression: "form.scope"
    }
  }, [_c("el-option", {
    attrs: {
      label: "Journey",
      value: "Journey"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Stage",
      value: "Stage"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Step",
      value: "Step"
    }
  })], 1)], 1), _vm._v(" "), _vm.form.scope != "Journey" ? _c("el-form-item", {
    attrs: {
      label: _vm.form.scope,
      prop: "scopename"
    }
  }, [_c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.form.scope === "Step" ? _c("el-select", {
    staticClass: "mr-5",
    attrs: {
      placeholder: "Select Step Name",
      size: "mini"
    },
    model: {
      value: _vm.form.scopename,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "scopename", $$v);
      },
      expression: "form.scopename"
    }
  }, _vm._l(_vm.tasks, function (task, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: task.title,
        value: task.title
      }
    });
  }), 1) : _vm._e()], 1), _vm._v(" "), _c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.form.scope === "Stage" ? _c("el-select", {
    staticClass: "mr-5",
    attrs: {
      placeholder: "Select Stage Name",
      size: "mini"
    },
    model: {
      value: _vm.form.scopename,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "scopename", $$v);
      },
      expression: "form.scopename"
    }
  }, _vm._l(_vm.phases, function (phase, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: phase.title,
        value: phase.title
      }
    });
  }), 1) : _vm._e()], 1)], 1) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "text-right"
  }, [_c("el-button", {
    attrs: {
      type: "secondary",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.goToStep("step1");
      }
    }
  }, [_vm._v("Previous")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.goToStep("step3");
      }
    }
  }, [_vm._v("Next")])], 1)], 1) : _vm._e()]), _vm._v(" "), _c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.activeStep === "step3" ? _c("div", {
    staticStyle: {
      "animation-fill-mode": "forwards"
    }
  }, [_c("table", {
    staticStyle: {
      "table-layout": "fixed"
    },
    attrs: {
      width: "100%"
    }
  }, [_c("tr", {
    staticClass: "mt-10"
  }, [_c("th", {
    staticClass: "text-right summaryLabel pr-10"
  }, [_vm._v("Idea Title:")]), _vm._v(" "), _c("td", {
    staticClass: "summaryData"
  }, [_vm._v(_vm._s(_vm.form.title))])]), _vm._v(" "), _c("tr", {
    staticClass: "mt-10"
  }, [_c("th", {
    staticClass: "text-right summaryLabel pr-10"
  }, [_vm._v("Idea Description:")]), _vm._v(" "), _c("td", {
    staticClass: "summaryData"
  }, [_vm._v(_vm._s(_vm.form.description))])]), _vm._v(" "), _c("tr", {
    staticClass: "mt-10"
  }, [_c("th", {
    staticClass: "text-right summaryLabel pr-10"
  }, [_vm._v(" Customer Type:")]), _vm._v(" "), _c("td", {
    staticClass: "summaryData"
  }, [_vm._v(_vm._s(_vm.form.customer_type))])]), _vm._v(" "), _c("tr", {
    staticClass: "mt-10"
  }, [_c("th", {
    staticClass: "text-right summaryLabel pr-10"
  }, [_vm._v("Idea Scope:")]), _vm._v(" "), _c("td", {
    staticClass: "summaryData"
  }, [_vm._v(_vm._s(_vm.form.scope))])]), _vm._v(" "), _c("tr", {
    staticClass: "mt-10"
  }, [_c("th", {
    staticClass: "text-right summaryLabel pr-10"
  }, [_vm._v("Scope Name:")]), _vm._v(" "), _c("td", {
    staticClass: "summaryData"
  }, [_vm._v(_vm._s(_vm.form.scopename))])]), _vm._v(" "), _c("tr", {
    staticClass: "mt-10"
  }, [_c("th", {
    staticClass: "text-right summaryLabel pr-10"
  }, [_vm._v("Background:")]), _vm._v(" "), _c("td", {
    staticClass: "summaryData"
  }, [_vm._v(_vm._s(_vm.form.problemstatement))])]), _vm._v(" "), _c("tr", {
    staticClass: "mt-10"
  }, [_c("th", {
    staticClass: "text-right summaryLabel pr-10"
  }, [_vm._v("Justification:")]), _vm._v(" "), _c("td", {
    staticClass: "summaryData"
  }, [_vm._v(_vm._s(_vm.form.solution))])]), _vm._v(" "), _c("tr", {
    staticClass: "mt-10"
  }, [_c("th", {
    staticClass: "text-right summaryLabel pr-10"
  }, [_vm._v("Value Proposition:")]), _vm._v(" "), _c("td", {
    staticClass: "summaryData summaryDataLastLine"
  }, [_vm._v(_vm._s(_vm.form.valueproposition))])])]), _vm._v(" "), _c("div", {
    staticClass: "text-right mt-20",
    staticStyle: {
      "margin-right": "0"
    }
  }, [_c("el-button", {
    attrs: {
      type: "secondary",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.goToStep("step2");
      }
    }
  }, [_vm._v("Previous")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.createJourney();
      }
    }
  }, [_vm._v("Create")])], 1)]) : _vm._e()])], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;