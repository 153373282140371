"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("g", {
    style: _vm.computeOpacityStyle(),
    attrs: {
      refs: "experience-component"
    },
    on: {
      mouseover: _vm.onMouseOver,
      mouseleave: _vm.onMouseLeave,
      click: _vm.emitClick
    }
  }, [!_vm.isStageRelation || _vm.layout === "compact" ? _c("rect", {
    attrs: {
      x: _vm.x + 3,
      y: _vm.y + 5,
      rx: "3",
      width: "105",
      height: "25",
      fill: _vm.computeExperienceTypeColor,
      "stroke-width": "1",
      stroke: _vm.computeExperienceTypeColor
    }
  }) : _vm._e(), _vm._v(" "), _vm.isStageRelation && _vm.layout !== "compact" ? _c("polygon", {
    attrs: {
      id: _vm.generateUniqueID(),
      x: _vm.x + 3,
      y: _vm.y + 5,
      points: _vm.generatePath(),
      fill: _vm.computeExperienceTypeColor,
      "stroke-width": "1",
      stroke: _vm.computeExperienceTypeColor
    }
  }) : _vm._e(), _vm._v(" "), _c("foreignObject", {
    staticStyle: {
      overflow: "visible"
    },
    attrs: {
      x: _vm.x - 5,
      y: _vm.y + 10,
      width: 22,
      height: 22
    }
  }, [_c("div", {
    staticStyle: {
      overflow: "visible"
    },
    attrs: {
      draggable: ""
    },
    on: {
      drag: _vm.onDrag
    }
  }, [_c("svg-icon", {
    style: _vm.computeStyle(),
    attrs: {
      id: _vm.generateUniqueID(),
      "icon-class": _vm.computeIconClass
    }
  })], 1)]), _vm._v(" "), !_vm.isMinimized ? _c("foreignObject", {
    staticStyle: {
      "background-color": "white",
      "border-radius": "5px",
      color: "red",
      "text-align": "center",
      "font-size": "0.6em",
      "font-weight": "lighter",
      "padding-top": "1px"
    },
    attrs: {
      x: _vm.x + 15,
      y: _vm.y + 18,
      width: 20,
      height: 10
    }
  }, [_c("span", [_vm._v(_vm._s(_vm.computePriority()))])]) : _vm._e(), _vm._v(" "), !_vm.isMinimized ? _c("foreignObject", {
    staticStyle: {
      "background-color": "white",
      "border-radius": "5px",
      color: "red",
      "text-align": "center",
      "font-size": "0.6em",
      "font-weight": "lighter"
    },
    attrs: {
      x: _vm.x + 36,
      y: _vm.y + 18,
      width: 20,
      height: 10
    }
  }, [_c("svg-icon", {
    staticStyle: {
      color: "blue",
      "padding-top": "1px"
    },
    attrs: {
      "icon-class": _vm.computeCustomerValueIcon()
    }
  })], 1) : _vm._e(), _vm._v(" "), !_vm.isMinimized ? _c("foreignObject", {
    staticStyle: {
      color: "white",
      "font-size": "0.57em"
    },
    attrs: {
      x: _vm.x + 15,
      y: _vm.y + 6,
      width: 100,
      height: 10
    }
  }, [_c("span", [_vm._v(_vm._s(_vm.truncateString(_vm.label, 24)))])]) : _vm._e(), _vm._v(" "), _c("filter", {
    attrs: {
      id: "shadow",
      "color-interpolation-filters": "sRGB"
    }
  }, [_c("feDropShadow", {
    attrs: {
      dx: "2",
      dy: "2",
      "std-deviation": "3",
      "flood-opacity": "0.5"
    }
  })], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;