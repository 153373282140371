"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _interopRequireWildcard2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _default2 = exports.default = {
  name: 'Relations',
  components: {
    RelationComponent: function RelationComponent() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./Relation.vue"));
      });
    }
  },
  props: {
    offering: {
      type: Object,
      required: true,
      default: function _default() {
        return {
          id: '',
          title: '',
          description: '',
          offeringcategory: '',
          offeringcategorydesc: '',
          additionalattributes: {},
          linkedProducts: [],
          linkedServices: []
        };
      }
    },
    journeyMap: {
      type: Object,
      required: true,
      default: function _default() {
        return {
          id: '',
          title: '',
          description: '',
          additionalattributes: {},
          linkedJourneyExperiences: [],
          linkedJourneyPhases: [],
          linkedJourneySteps: []
        };
      }
    },
    relations: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    subEntityX: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    subEntityY: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    mergedEntities: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    canvasDragCoefficientX: {
      type: Number,
      default: 0
    },
    journeymapY: {
      type: Number,
      default: 0
    },
    entityY: {
      type: Number,
      default: 0
    },
    relationsRefresh: {
      type: Number,
      default: 0
    },
    isProductVisible: {
      type: Boolean,
      default: true
    },
    isServiceVisible: {
      type: Boolean,
      default: true
    },
    isTouchPointVisible: {
      type: Boolean,
      default: true
    },
    selectedEntityId: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: 'hover'
    }
  },
  data: function data() {
    return {
      isHover: false,
      filteredRelationsArray: [],
      phaseStepRelation: [],
      phase_pos_x: [],
      task_pos_x: [],
      task_pos_y: [],
      feature_pos_x: [],
      feature_pos_y: [],
      service_application_pos_x: [],
      service_application_pos_y: []
    };
  },
  computed: {
    computedRelations: function computedRelations() {
      var relations = [];
      for (var i = 0; i < this.journeyMap.additionalattributes.relations.length; i++) {
        if (!this.journeyMap.additionalattributes.relations[i].isDeleted) {
          relations.push(this.journeyMap.additionalattributes.relations[i]);
        }
      }
      return relations;
    },
    sortedStages: function sortedStages() {
      var stagesSortOrder = [];
      if (this._.isEmpty(this.journeyMap)) {
        return stagesSortOrder;
      }
      if (this.journeyMap && this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages.length > 0 && this.journeyMap.linkedJourneyPhases.length > 0) {
        for (var i = 0; i < this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages.length; i++) {
          for (var j = 0; j < this.journeyMap.linkedJourneyPhases.length; j++) {
            if (this.journeyMap.linkedJourneyPhases[j].journeyPhase.id === this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[i].id && !this.journeyMap.linkedJourneyPhases[j].journeyPhase.isDeleted) {
              stagesSortOrder.push(this.journeyMap.linkedJourneyPhases[j]);
              break;
            }
          }
        }
      } else {
        for (var k = 0; k < this.journeyMap.linkedJourneyPhases.length; k++) {
          if (!this.journeyMap.linkedJourneyPhases[j].journeyPhase.hasOwnProperty('isDeleted')) {
            stagesSortOrder.push(this.journeyMap.linkedJourneyPhases[j]);
          } else if (this.journeyMap.linkedJourneyPhases[j].journeyPhase.hasOwnProperty('isDeleted') && this.journeyMap.linkedJourneyPhases[j].journeyPhase.isDeleted === false) {
            stagesSortOrder.push(this.journeyMap.linkedJourneyPhases[j]);
            break;
          }
        }
      }
      return stagesSortOrder;
    },
    computedTaskList: function computedTaskList() {
      this.refreshKey;
      var stepList = [];
      if (this.journeyMap && this.journeyMap.linkedJourneySteps) {
        for (var i = 0; i < this.journeyMap.linkedJourneySteps.length; i++) {
          if (this.computeJourneyStepDisplayCondition(this.journeyMap.linkedJourneySteps[i], i)) {
            stepList.push(this.journeyMap.linkedJourneySteps[i]);
          }
        }
      }
      return stepList;
    }
  },
  watch: {
    mergedEntities: {
      handler: function handler(newValue) {
        this.computeSubEntityX();
        this.computeSubEntityY();
      },
      deep: true
    },
    relationsRefresh: function relationsRefresh(val) {
      this.feature_pos_x = [];
      this.service_application_pos_x = [];
      this.feature_pos_y = [];
      this.service_application_pos_y = [];
      for (var i = 0; i < this.mergedEntities.length; i++) {
        if (this.mergedEntities[i].__typename === 'LinkProductOffering') {
          this.feature_pos_x[i] = this.subEntityX[i].map(function (x) {
            return x;
          });
          this.feature_pos_y[i] = this.subEntityY[i].map(function (x) {
            return x;
          });
        }
        if (this.mergedEntities[i].__typename === 'LinkServiceOffering') {
          this.service_application_pos_x[i] = this.subEntityX[i].map(function (x) {
            return x;
          });
          this.service_application_pos_y[i] = this.subEntityY[i].map(function (x) {
            return x;
          });
        }
      }
      this.filteredRelations();
    },
    subEntityX: {
      handler: function handler(newValue) {
        this.computeSubEntityX();
        this.computeSubEntityY();
        this.$nextTick();
        this.$forceUpdate();
      },
      deep: true
    },
    subEntityY: {
      handler: function handler(newValue) {
        this.computeSubEntityX();
        this.computeSubEntityY();
        this.$nextTick();
        this.$forceUpdate();
      },
      deep: true
    },
    canvasDragCoefficientX: function canvasDragCoefficientX(newVal, oldVal) {
      // watch it
      this.computePhaseX();
      this.computePhaseStepRelationArray();
      this.computeTaskX();
    },
    entityY: function entityY(newVal, oldVal) {
      // watch it
      this.computePhaseStepRelationArray();
      this.computeSubEntityY();
    }
  },
  created: function created() {},
  mounted: function mounted() {
    this.filteredRelations();
    this.computePhaseX();
    this.computePhaseStepRelationArray();
    this.computeTaskX();
    this.computeTaskY();
    this.computeSubEntityX();
    this.computeSubEntityY();
  },
  methods: {
    isLaneMinimized: function isLaneMinimized(entity_id) {
      for (var i = 0; i < this.offering.additionalattributes.entitiesSortOrder.length; i++) {
        if (this.offering.additionalattributes.entitiesSortOrder[i].id === entity_id) {
          return this.offering.additionalattributes.entitiesSortOrder[i].minimized;
        }
      }
      return false;
    },
    filteredRelations: function filteredRelations() {
      this.filteredRelationsArray = [];
      for (var i = 0; i < this.relations.length; i++) {
        if (this.isRelationVisible(this.relations[i])) {
          this.filteredRelationsArray.push(this.relations[i]);
        }
      }
      this.$nextTick();
      this.$forceUpdate();
    },
    computeRelationDisplayCondition: function computeRelationDisplayCondition(relation) {
      if (this.type === 'constant') {
        return true;
      }
      if (this.selectedEntityId && (this.selectedEntityId === relation.start_id || this.selectedEntityId === relation.end_id)) {
        // Display relation on hover
        return true;
      }
      return false;
    },
    computeJourneyStepDisplayCondition: function computeJourneyStepDisplayCondition(task, index) {
      if (task.journeyStep.hasOwnProperty('isDeleted') && task.journeyStep.isDeleted === true) {
        return false;
      }
      return true;
    },
    filteredRelationWaypoints: function filteredRelationWaypoints(waypoints) {
      var filteredWaypoints = [];
      for (var i = 0; i < waypoints.length; i++) {
        if (this.isWaypointVisible(waypoints[i].type)) {
          filteredWaypoints.push(waypoints[i]);
        }
      }
      return filteredWaypoints;
    },
    isRelationVisible: function isRelationVisible(relation) {
      if (this.isParentLaneMinimized(relation)) {
        return false;
      } else if (this.isTouchPointVisible && this.isServiceVisible && this.isProductVisible) {
        return true;
      } else if (!this.isTouchPointVisible && (relation.start_type === 'Touchpoint' || relation.end_type === 'Touchpoint')) {
        return false;
      } else if (!this.isProductVisible && (relation.start_type === 'Feature' || relation.end_type === 'Feature')) {
        return false;
      } else if (!this.isServiceVisible && (relation.start_type === 'ServiceApplication' || relation.end_type === 'ServiceApplication')) {
        return false;
      } else {
        return true;
      }
    },
    isWaypointVisible: function isWaypointVisible(type) {
      if (this.isTouchPointVisible && this.isServiceVisible && this.isProductVisible) {
        return true;
      }
      if (!this.isTouchPointVisible && type === 'Touchpoint') {
        return false;
      }
      if (!this.isProductVisible && type === 'Feature') {
        return false;
      }
      if (!this.isServiceVisible && type === 'ServiceApplication') {
        return false;
      }
    },
    isParentLaneMinimized: function isParentLaneMinimized(relation) {
      if (relation.start_type === 'Feature' || relation.end_type === 'Feature') {
        for (var i = 0; i < this.offering.linkedProducts.length; i++) {
          for (var j = 0; j < this.offering.linkedProducts[i].product.linkedFeatures.length; j++) {
            if (this.offering.linkedProducts[i].product.linkedFeatures[j].productFeature.id === relation.start_id || this.offering.linkedProducts[i].product.linkedFeatures[j].productFeature.id === relation.end_id) {
              return this.isLaneMinimized(this.offering.linkedProducts[i].product.id);
            }
          }
        }
      } else if (relation.start_type === 'ServiceApplication' || relation.end_type === 'ServiceApplication') {
        for (var k = 0; k < this.offering.linkedServices.length; k++) {
          for (var l = 0; l < this.offering.linkedServices[k].service.linkedServiceApplications.length; l++) {
            if (this.offering.linkedServices[k].service.linkedServiceApplications[l].serviceApplication.id === relation.start_id || this.offering.linkedServices[k].service.linkedServiceApplications[l].serviceApplication.id === relation.end_id) {
              return this.isLaneMinimized(this.offering.linkedServices[k].service.id);
            }
          }
        }
      } else {
        return false;
      }
    },
    generatePath: function generatePath(start_id, start_type, end_id, end_type) {
      var pos_start = this.getPos(start_id, start_type, 'start');
      var pos_end = this.getPos(end_id, end_type, 'end');
      if (pos_start[0] <= 0 || pos_start[1] <= 0 || pos_end[0] <= 0 || pos_end[1] <= 0) {
        return '';
      }
      switch (start_type) {
        case 'Step':
          return this.generateBezierPath1(pos_start[0], pos_start[1], pos_end[0], pos_end[1]);
        case 'Feature':
          // special case for feature to feature relation
          if (end_type === 'Feature') {
            var start_x = pos_start[0];
            var end_x = pos_end[0];
            if (pos_start[0] > pos_end[0]) {
              start_x -= 20;
              end_x += 20;
            } else if (pos_start[0] === pos_end[0]) {
              start_x += 15;
              end_x += 15;
            } else {
              start_x += 35;
              end_x -= 13;
            }
            var start_y = pos_start[1] - 35;
            var end_y = pos_end[1] - 5;
            return this.generateBezierPathHorizontal(start_x, start_y, end_x, end_y);
          }
          return this.generateBezierPath1(pos_start[0], pos_start[1], pos_end[0], pos_end[1]);
        default:
          return this.generateBezierPath1(pos_start[0], pos_start[1], pos_end[0], pos_end[1]);
      }
    },
    getPos: function getPos(id, type, pos) {
      switch (type) {
        case 'Step':
          var x = this.getTaskX(id) - 25;
          var y = this.getTaskY(id);
          return [x, y];
        case 'Feature':
          var feature = this.getFeaturePos(id);
          feature[0] += 7;
          feature[1] += pos === 'end' ? -3 : 0;
          feature[1] += pos === 'start' ? 30 : 0;
          return feature;
        case 'ServiceApplication':
          var service_application = this.getServiceApplicationPos(id);
          service_application[0] += pos === 'end' ? -3 : 0;
          return service_application;
        case 'Touchpoint':
          var touchpoint = [];
          touchpoint = this.getTouchpointPos(id);
          touchpoint[1] += pos === 'end' ? 0 : 90;
          return touchpoint;
      }
    },
    getTouchpointPos: function getTouchpointPos(id) {
      // TODO
      return [500, this.journeymapY + 15];
    },
    getServiceApplicationPos: function getServiceApplicationPos(id) {
      for (var i = 0; i < this.mergedEntities.length; i++) {
        if (this.mergedEntities[i].__typename === 'LinkServiceOffering') {
          for (var j = 0; j < this.mergedEntities[i].service.linkedServiceApplications.length; j++) {
            if (this.mergedEntities[i].service.linkedServiceApplications[j].serviceApplication.id === id) {
              var x = this.computeServiceApplicationPosX(i, j, id) + 15;
              var y = this.computeServiceApplicationPosY(i, j, id) + 20;
              return [x, y];
            }
          }
        }
      }
      return [0, 0];
    },
    getFeaturePos: function getFeaturePos(id) {
      for (var i = 0; i < this.mergedEntities.length; i++) {
        if (this.mergedEntities[i].__typename === 'LinkProductOffering') {
          for (var j = 0; j < this.mergedEntities[i].product.linkedFeatures.length; j++) {
            if (this.mergedEntities[i].product.linkedFeatures[j].productFeature.id === id) {
              var x = this.computeFeaturePosX(i, j, id) + 10;
              var y = this.computeFeaturePosY(i, j, id) + 20;
              return [x, y];
            }
          }
        }
      }
      return [0, 0];
    },
    // Path Algorithm
    generateBezierPathHorizontal: function generateBezierPathHorizontal(start_x1, start_y1, start_x2, start_y2) {
      var mid_point_x = start_x2 - start_x1 === 0 ? 50 : start_x2 - start_x1;
      var end_x1 = start_x1 + mid_point_x / 2;
      var end_y1 = start_y1;
      var end_x2 = start_x1 + mid_point_x / 2;
      var end_y2 = start_y2;
      return 'M' + start_x1 + ',' + start_y1 + ' C' + end_x1 + ',' + end_y1 + ' ' + end_x2 + ',' + end_y2 + ' ' + start_x2 + ',' + start_y2;
    },
    generateBezierPath1: function generateBezierPath1(start_x1, start_y1, start_x2, start_y2) {
      var end_x1 = start_x1 - 50;
      var end_y1 = start_y1 + 30;
      var end_x2 = start_x2;
      var end_y2 = start_y2 - 100;
      if (start_x1 !== undefined) {
        return 'M' + start_x1 + ',' + (start_y1 - 25) + ' C' + end_x1 + ',' + end_y1 + ' ' + end_x2 + ',' + end_y2 + ' ' + start_x2 + ',' + (start_y2 - 20);
      } else {
        return 'M 0,0';
      }
    },
    generateBezierPathWithStartCurve: function generateBezierPathWithStartCurve(start_x1, start_y1, start_x2, start_y2) {
      var start_x1_5 = start_x1 + 5;
      var start_y1_5 = start_y1 + 5;
      var end_x1 = start_x1_5;
      var end_y1 = start_y1_5 + (start_y2 - start_y1_5) / 2;
      var end_x2 = start_x2;
      var end_y2 = start_y2 - (start_y2 - start_y1_5) / 2;
      if (start_x1 !== undefined) {
        return 'M' + start_x1 + ',' + start_y1 + ' Q' + start_x1_5 + ',' + start_y1 + ' ' + start_x1_5 + ',' + start_y1_5 + ' C' + end_x1 + ',' + end_y1 + ' ' + end_x2 + ',' + end_y2 + ' ' + start_x2 + ',' + start_y2;
      } else {
        return 'M 0,0';
      }
    },
    generateBezierPath: function generateBezierPath(start_x1, start_y1, start_x2, start_y2) {
      var end_x1 = start_x1;
      var end_y1 = start_y1 + (start_y2 - start_y1) / 2;
      var end_x2 = start_x2;
      var end_y2 = start_y2 - (start_y2 - start_y1) / 2;
      if (start_x1 !== undefined) {
        return 'M' + start_x1 + ',' + start_y1 + ' C' + end_x1 + ',' + end_y1 + ' ' + end_x2 + ',' + end_y2 + ' ' + start_x2 + ',' + start_y2;
      } else {
        return 'M 0,0';
      }
    },
    // Position Computations
    computeServiceApplicationPosX: function computeServiceApplicationPosX(service_index, service_application_index, service_application_id) {
      if (this.offering.additionalattributes.entitiesSortOrder && this.offering.additionalattributes.entitiesSortOrder.length > 0) {
        for (var i = 0; i < this.offering.additionalattributes.entitiesSortOrder.length; i++) {
          if (this.offering.additionalattributes.entitiesSortOrder[i].type === 'service') {
            for (var j = 0; j < this.offering.additionalattributes.entitiesSortOrder[i].service_applications.length; j++) {
              if (this.offering.additionalattributes.entitiesSortOrder[i].service_applications[j].id === service_application_id) {
                return this.offering.additionalattributes.entitiesSortOrder[i].service_applications[j].posX;
              }
            }
          }
        }
      }
      if (this.service_application_pos_x.length === 0 && this.mergedEntities[service_index] !== undefined && this.subEntityX.length === 0) {
        this.computeSubEntityX();
      }
      /* if (!this.mergedEntities[service_index].service.additionalattributes.minimized && this.mergedEntities[service_index].service.linkedServiceApplications[service_application_index].serviceApplication.additionalattributes.posX !== undefined) {
       return this.mergedEntities[service_index].service.linkedServiceApplications[service_application_index].serviceApplication.additionalattributes.posX
      }*/
      return this.service_application_pos_x[service_index][service_application_index];
    },
    computeServiceApplicationPosY: function computeServiceApplicationPosY(service_index, service_application_index, service_application_id) {
      if (this.offering.additionalattributes.entitiesSortOrder && this.offering.additionalattributes.entitiesSortOrder.length > 0) {
        for (var i = 0; i < this.offering.additionalattributes.entitiesSortOrder.length; i++) {
          if (this.offering.additionalattributes.entitiesSortOrder[i].type === 'service') {
            for (var j = 0; j < this.offering.additionalattributes.entitiesSortOrder[i].service_applications.length; j++) {
              if (this.offering.additionalattributes.entitiesSortOrder[i].service_applications[j].id === service_application_id) {
                return this.offering.additionalattributes.entitiesSortOrder[i].service_applications[j].posY;
              }
            }
          }
        }
      }
      if (this.service_application_pos_y.length === 0 && this.mergedEntities[service_index] !== undefined && this.subEntityY.length === 0) {
        this.computeSubEntityY();
      }
      /* if (!this.mergedEntities[service_index].service.additionalattributes.minimized && this.mergedEntities[service_index].service.linkedServiceApplications[service_application_index].serviceApplication.additionalattributes.posY !== undefined) {
        return this.mergedEntities[service_index].service.linkedServiceApplications[service_application_index].serviceApplication.additionalattributes.posY
      }*/
      return this.service_application_pos_y[service_index][service_application_index];
    },
    computeFeaturePosX: function computeFeaturePosX(product_index, feature_index, feature_id) {
      if (this.offering.additionalattributes.entitiesSortOrder && this.offering.additionalattributes.entitiesSortOrder.length > 0) {
        for (var i = 0; i < this.offering.additionalattributes.entitiesSortOrder.length; i++) {
          if (this.offering.additionalattributes.entitiesSortOrder[i].type === 'product') {
            for (var j = 0; j < this.offering.additionalattributes.entitiesSortOrder[i].features.length; j++) {
              if (this.offering.additionalattributes.entitiesSortOrder[i].features[j].id === feature_id) {
                return this.offering.additionalattributes.entitiesSortOrder[i].features[j].posX;
              }
            }
          }
        }
      }
      if (this.feature_pos_x.length === 0 && this.mergedEntities[product_index] !== undefined && this.subEntityX.length === 0) {
        this.computeSubEntityX();
      }
      /* if (!this.mergedEntities[product_index].product.additionalattributes.minimized && this.mergedEntities[product_index].product.linkedFeatures[feature_index].productFeature.additionalattributes.posX !== undefined) {
        return this.mergedEntities[product_index].product.linkedFeatures[feature_index].productFeature.additionalattributes.posX + 6
      }*/
      return this.feature_pos_x[product_index][feature_index];
    },
    computeSubEntityX: function computeSubEntityX() {
      this.feature_pos_x = [];
      this.service_application_pos_x = [];
      for (var i = 0; i < this.mergedEntities.length; i++) {
        if (this.mergedEntities[i].__typename === 'LinkProductOffering') {
          this.feature_pos_x[i] = new Array(this.mergedEntities[i].product.linkedFeatures.length);
          for (var j = 0; j < this.mergedEntities[i].product.linkedFeatures.length; j++) {
            if (this.offering.additionalattributes.entitiesSortOrder && this.offering.additionalattributes.entitiesSortOrder.length > 0) {
              for (var k = 0; k < this.offering.additionalattributes.entitiesSortOrder.length; k++) {
                if (this.offering.additionalattributes.entitiesSortOrder[k].type === 'product') {
                  for (var l = 0; l < this.offering.additionalattributes.entitiesSortOrder[k].features.length; l++) {
                    if (this.offering.additionalattributes.entitiesSortOrder[k].features[l].id === this.mergedEntities[i].product.linkedFeatures[j].productFeature.id) {
                      this.feature_pos_x[i][j] = this.offering.additionalattributes.entitiesSortOrder[k].features[l].posX;
                      this.feature_pos_y[i][j] = this.offering.additionalattributes.entitiesSortOrder[k].features[l].posY;
                    }
                  }
                }
              }
            }
          }

          /* for (var j = 0; j < this.mergedEntities[i].product.linkedFeatures.length; j++) {
            if (this.mergedEntities[i].product.additionalattributes.minimized) {
              this.feature_pos_x[i][j] = (j % 100) * 30 + 168
            } else {
              if (this.mergedEntities[i].product.linkedFeatures[j].productFeature.relations.steps[0].id === null && !this.hasFeatureRelation(this.mergedEntities[i].product.linkedFeatures[j].productFeature.id)) {
                this.feature_pos_x[i][j] = (this.computeProductUnmappedIndex(i, j) % 50) * 50 + 100
              } else if (this.mergedEntities[i].product.linkedFeatures[j].productFeature.enabler) {
                this.feature_pos_x[i][j] = this.computeProductEnablerParentX(this.mergedEntities[i].product.linkedFeatures[j].productFeature.id) + (this.computeProductEnablerIndex(i, j) % 50) * 50
              } else {
                this.feature_pos_x[i][j] = this.computePhaseXFromStepID(this.mergedEntities[i].product.linkedFeatures[j].productFeature.relations.steps[0].id) + (this.computeFilteredProducFeatureIndex(i, j) % 50) * 50
              }
            }
          }*/
        } else if (this.mergedEntities[i].__typename === 'LinkServiceOffering') {
          this.service_application_pos_x[i] = new Array(this.mergedEntities[i].service.linkedServiceApplications.length);
          for (var m = 0; m < this.mergedEntities[i].service.linkedServiceApplications.length; m++) {
            if (this.offering.additionalattributes.entitiesSortOrder && this.offering.additionalattributes.entitiesSortOrder.length > 0) {
              for (var n = 0; n < this.offering.additionalattributes.entitiesSortOrder.length; n++) {
                if (this.offering.additionalattributes.entitiesSortOrder[n].type === 'service') {
                  for (var o = 0; o < this.offering.additionalattributes.entitiesSortOrder[n].service_applications.length; o++) {
                    if (this.offering.additionalattributes.entitiesSortOrder[n].service_applications[o].id === this.mergedEntities[i].service.linkedServiceApplications[m].serviceApplication.id) {
                      this.service_application_pos_x[i][m] = this.offering.additionalattributes.entitiesSortOrder[n].service_applications[o].posX;
                      this.service_application_pos_y[i][m] = this.offering.additionalattributes.entitiesSortOrder[n].service_applications[o].posY;
                    }
                  }
                }
              }
            }
          }

          /* if (this.mergedEntities[i].service.additionalattributes.minimized) {
            this.service_application_pos_x[i][k] = (k % 100) * 30 + 168
          } else {
            if (this.mergedEntities[i].service.linkedServiceApplications[k].serviceApplication.relations.id === null) {
              this.service_application_pos_x[i][k] = 175 + (this.computeServiceUnmappedIndex(i, k) % 50) * 50 + 215
            } else if (this.mergedEntities[i].service.linkedServiceApplications[k].serviceApplication.relations.id !== null && this.mergedEntities[i].service.linkedServiceApplications[k].serviceApplication.enabler) {
              this.service_application_pos_x[i][k] = this.computePhaseXFromStepID(this.mergedEntities[i].service.linkedServiceApplications[k].serviceApplication.relations.id) + (this.computeServiceEnablerIndex(i, k) % 50) * 50
            } else {
              this.service_application_pos_x[i][k] = this.computePhaseXFromStepID(this.mergedEntities[i].service.linkedServiceApplications[k].serviceApplication.relations.id) + (this.computeFilteredServiceApplicationIndex(i, k) % 50) * 50
            }
          }
          }*/
        }
      }
    },
    computePhaseXFromStepID: function computePhaseXFromStepID(step_id) {
      if (this._.isEmpty(this.journeyMap)) {
        return 175;
      }
      if (this.phase_pos_x.length === 0 && this.journeyMap.linkedJourneyPhases.length > 0) {
        this.computePhaseX();
      }
      if (this.phase_pos_x.length === 0 && this.journeyMap.linkedJourneyPhases.length === 0) {
        return 175;
      }
      if (this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages.length > 0 && this.journeyMap.linkedJourneyPhases.length > 0) {
        for (var i = 0; i < this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages.length; i++) {
          for (var j = 0; j < this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[i].steps.length; j++) {
            if (this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[i].steps[j].id === step_id) {
              return this.phase_pos_x[i];
            }
          }
        }
      }
      return 175;
    },
    hasFeatureRelation: function hasFeatureRelation(feature_id) {
      for (var i = 0; i < this.relations.length; i++) {
        if (this.relations[i].start_id === feature_id && this.relations[i].end_type === 'Feature') {
          return true;
        }
      }
      return false;
    },
    hasServiceApplicationRelation: function hasServiceApplicationRelation(service_application_id) {
      for (var i = 0; i < this.relations.length; i++) {
        if (this.relations[i].end_id === service_application_id) {
          return true;
        }
      }
      return false;
    },
    computeFilteredProducFeatureIndex: function computeFilteredProducFeatureIndex(product_index, feature_index) {
      var temp_feature_ids = [];
      for (var j = 0; j <= feature_index; j++) {
        if (!this.isFeatureUnmapped(this.mergedEntities[product_index].product.linkedFeatures[j].productFeature.id) && !this.isFeatureEnabler(this.mergedEntities[product_index].product.linkedFeatures[j].productFeature.id)) {
          temp_feature_ids.push(this.mergedEntities[product_index].product.linkedFeatures[j].productFeature.id);
        }
      }
      return temp_feature_ids.indexOf(this.mergedEntities[product_index].product.linkedFeatures[feature_index].productFeature.id);
    },
    computeProductEnablerIndex: function computeProductEnablerIndex(product_index, feature_index) {
      var count = 0;
      for (var j = 0; j < feature_index; j++) {
        if (this.isFeatureEnabler(this.mergedEntities[product_index].product.linkedFeatures[j].productFeature.id)) {
          count++;
        }
      }
      return count;
    },
    computeProductUnmappedIndex: function computeProductUnmappedIndex(product_index, feature_index) {
      var count = 0;
      for (var j = 0; j < feature_index; j++) {
        if (this.isFeatureUnmapped(this.mergedEntities[product_index].product.linkedFeatures[j].productFeature.id) || !this.hasFeatureRelation(this.mergedEntities[product_index].product.linkedFeatures[j].productFeature.id)) {
          count++;
        }
      }
      return count;
    },
    computeProductEnablerParentX: function computeProductEnablerParentX(feature_id) {
      for (var i = 0; i < this.relations.length; i++) {
        if (this.relations[i].start_id === feature_id) {
          for (var j = 0; j < this.relations.length; j++) {
            if (this.relations[i].end_id === this.relations[j].end_id && this.relations[j].end_type === 'Feature') {
              return this.computePhaseXFromStepID(this.relations[j].start_id);
            }
          }
        }
      }
      return 0;
    },
    computeFilteredServiceApplicationIndex: function computeFilteredServiceApplicationIndex(service_index, service_application_index) {
      var temp_service_application_ids = [];
      for (var j = 0; j <= service_application_index; j++) {
        if (!this.isServiceApplicationUnmapped(this.mergedEntities[service_index].service.linkedServiceApplications[j].serviceApplication.id) && !this.isServiceApplicationEnabler(this.mergedEntities[service_index].service.linkedServiceApplications[j].serviceApplication.id)) {
          temp_service_application_ids.push(this.mergedEntities[service_index].service.linkedServiceApplications[j].serviceApplication.id);
        }
      }
      return temp_service_application_ids.indexOf(this.mergedEntities[service_index].service.linkedServiceApplications[service_application_index].serviceApplication.id);
    },
    computeServiceEnablerIndex: function computeServiceEnablerIndex(service_index, service_application_index) {
      var count = 0;
      for (var j = 0; j < service_application_index; j++) {
        if (this.isServiceApplicationEnabler(this.mergedEntities[service_index].service.linkedServiceApplications[j].serviceApplication.id)) {
          count++;
        }
      }
      return count;
    },
    computeServiceUnmappedIndex: function computeServiceUnmappedIndex(service_index, service_application_index) {
      var count = 0;
      for (var j = 0; j < service_application_index; j++) {
        if (this.isServiceApplicationUnmapped(this.mergedEntities[service_index].service.linkedServiceApplications[j].serviceApplication.id) || !this.hasServiceApplicationRelation(this.mergedEntities[service_index].service.linkedServiceApplications[j].serviceApplication.id)) {
          count++;
        }
      }
      return count;
    },
    computeFeaturePosY: function computeFeaturePosY(product_index, feature_index, feature_id) {
      if (this.offering.additionalattributes.entitiesSortOrder && this.offering.additionalattributes.entitiesSortOrder.length > 0) {
        for (var i = 0; i < this.offering.additionalattributes.entitiesSortOrder.length; i++) {
          if (this.offering.additionalattributes.entitiesSortOrder[i].type === 'product') {
            for (var j = 0; j < this.offering.additionalattributes.entitiesSortOrder[i].features.length; j++) {
              if (this.offering.additionalattributes.entitiesSortOrder[i].features[j].id === feature_id) {
                return this.offering.additionalattributes.entitiesSortOrder[i].features[j].posY;
              }
            }
          }
        }
      }
      if (this.feature_pos_y.length === 0 && this.mergedEntities[product_index] !== undefined && this.subEntityY.length === 0) {
        this.computeSubEntityY();
      }
      /* if (!this.mergedEntities[product_index].product.additionalattributes.minimized && this.mergedEntities[product_index].product.linkedFeatures[feature_index].productFeature.additionalattributes.posY !== undefined) {
        return this.mergedEntities[product_index].product.linkedFeatures[feature_index].productFeature.additionalattributes.posY
      }*/
      return this.feature_pos_y[product_index][feature_index];
    },
    computeSubEntityY: function computeSubEntityY() {
      this.feature_pos_y = [];
      this.service_application_pos_y = [];
      for (var i = 0; i < this.mergedEntities.length; i++) {
        if (this.mergedEntities[i].__typename === 'LinkProductOffering') {
          this.feature_pos_y[i] = new Array(this.mergedEntities[i].product.linkedFeatures.length);
          for (var j = 0; j < this.mergedEntities[i].product.linkedFeatures.length; j++) {
            if (this.offering.additionalattributes.entitiesSortOrder[i].minimized) {
              this.feature_pos_y[i][j] = this.entityY + this.computeEntityLaneHeight(i) + 5;
            } else {
              if (this.isFeatureEnabler(this.mergedEntities[i].product.linkedFeatures[j].productFeature.id)) {
                this.feature_pos_y[i][j] = this.entityY + this.computeEntityLaneHeight(i) + 5 + 105;
              } else if (this.isFeatureUnmapped(this.mergedEntities[i].product.linkedFeatures[j].productFeature.id)) {
                this.feature_pos_y[i][j] = this.entityY + this.computeEntityLaneHeight(i) + 5 + 155;
              } else {
                this.feature_pos_y[i][j] = this.entityY + this.computeEntityLaneHeight(i) + 5 + 5;
              }
            }
          }
        } else if (this.mergedEntities[i].__typename === 'LinkServiceOffering') {
          this.service_application_pos_y[i] = new Array(this.mergedEntities[i].service.linkedServiceApplications.length);
          for (var k = 0; k < this.mergedEntities[i].service.linkedServiceApplications.length; k++) {
            if (this.offering.additionalattributes.entitiesSortOrder[i].minimized) {
              this.service_application_pos_y[i][k] = this.entityY + this.computeEntityLaneHeight(i) + 5;
            } else {
              if (this.isServiceApplicationEnabler(this.mergedEntities[i].service.linkedServiceApplications[k].serviceApplication.id)) {
                this.service_application_pos_y[i][k] = this.entityY + this.computeEntityLaneHeight(i) + 5 + 110;
              } else if (this.isServiceApplicationUnmapped(this.mergedEntities[i].service.linkedServiceApplications[k].serviceApplication.id) || !this.hasServiceApplicationRelation(this.mergedEntities[i].service.linkedServiceApplications[k].serviceApplication.id)) {
                this.service_application_pos_y[i][k] = this.entityY + this.computeEntityLaneHeight(i) + 5 + 160;
              } else {
                this.service_application_pos_y[i][k] = this.entityY + this.computeEntityLaneHeight(i) + 5;
              }
            }
          }
        }
      }
    },
    computeEntityLaneHeight: function computeEntityLaneHeight(index) {
      var height = 0;
      for (var i = 0; i < index; i++) {
        if (this.offering.additionalattributes.entitiesSortOrder[i].minimized) {
          height += 25;
        } else {
          if (this.mergedEntities[i].__typename === 'LinkProductOffering') {
            height += this.productHasUnmappedFeatures(this.mergedEntities[i].product) ? 150 : 100;
            height += this.productHasEnablerFeatures(this.mergedEntities[i].product) ? 50 : 0;
          } else if (this.mergedEntities[i].__typename === 'LinkServiceOffering') {
            height += this.serviceHasUnmappedFeatures(this.mergedEntities[i].service) ? 150 : 100;
            height += this.serviceHasEnablerFeatures(this.mergedEntities[i].service) ? 50 : 0;
          } else {
            height += 100;
          }
        }
      }
      return height + index * 5;
    },
    // Needs Rework with new relation structure
    productHasUnmappedFeatures: function productHasUnmappedFeatures(product) {
      var has_unmapped = false;
      if (!this.isUnmappedVisible) {
        return has_unmapped;
      }
      for (var i = 0; i < this.offering.additionalattributes.entitiesSortOrder.length; i++) {
        if (this.offering.additionalattributes.entitiesSortOrder[i].id === product.id) {
          for (var j = 0; j < this.offering.additionalattributes.entitiesSortOrder[i].features.length; j++) {
            if (this.offering.additionalattributes.entitiesSortOrder[i].features[j].unmapped) {
              return true;
            }
          }
        }
      }
      return has_unmapped;
    },
    productHasEnablerFeatures: function productHasEnablerFeatures(product) {
      for (var i = 0; i < this.offering.additionalattributes.entitiesSortOrder.length; i++) {
        if (this.offering.additionalattributes.entitiesSortOrder[i].id === product.id) {
          for (var j = 0; j < this.offering.additionalattributes.entitiesSortOrder[i].features.length; j++) {
            if (this.offering.additionalattributes.entitiesSortOrder[i].features[j].enabler) {
              return true;
            }
          }
        }
      }
      return false;
    },
    serviceHasUnmappedFeatures: function serviceHasUnmappedFeatures(service) {
      var has_unmapped = false;
      if (!this.isUnmappedVisible) {
        return has_unmapped;
      }
      for (var i = 0; i < this.offering.additionalattributes.entitiesSortOrder.length; i++) {
        if (this.offering.additionalattributes.entitiesSortOrder[i].id === service.id) {
          for (var j = 0; j < this.offering.additionalattributes.entitiesSortOrder[i].service_applications.length; j++) {
            if (this.offering.additionalattributes.entitiesSortOrder[i].service_applications[j].unmapped) {
              return true;
            }
          }
        }
      }
      return has_unmapped;
    },
    serviceHasEnablerFeatures: function serviceHasEnablerFeatures(service) {
      for (var i = 0; i < this.offering.additionalattributes.entitiesSortOrder.length; i++) {
        if (this.offering.additionalattributes.entitiesSortOrder[i].id === service.id) {
          for (var j = 0; j < this.offering.additionalattributes.entitiesSortOrder[i].service_applications.length; j++) {
            if (this.offering.additionalattributes.entitiesSortOrder[i].service_applications[j].enabler) {
              return true;
            }
          }
        }
      }
      return false;
    },
    computePhaseStartX: function computePhaseStartX(phase, index) {
      if (this.phase_pos_x.length === 0) {
        this.computePhaseX();
      }
      return this.phase_pos_x[index];
    },
    computePhaseLength: function computePhaseLength(phase, index) {
      var max_step_count = 0;
      if (typeof phase === 'undefined') {
        return 225;
      }
      for (var i = 0; i < this.computedRelations.length; i++) {
        if (this.computedRelations[i].start_type === 'Phase' && this.computedRelations[i].end_type === 'Step' && this.computedRelations[i].start_id === phase.id) {
          /* if (this.computedRelations[i].start_type === 'Phase') {
            step_experience_list.push(this.computedRelations[i].end_id)
          }*/
          max_step_count++;
        }
      }
      var additional_columns = 0;
      var first_column = 0;
      additional_columns = Math.floor(max_step_count / 7) - 1;
      first_column = 150;
      return 225 + first_column + additional_columns * 150;
    },
    computePhaseX: function computePhaseX() {
      this.phase_pos_x = [];
      if (!this.sortedStages) {
        return;
      }
      for (var i = 0; i < this.sortedStages.length; i++) {
        if (i === 0) {
          this.phase_pos_x.push(this.canvasDragCoefficientX + 175);
        } else {
          var length = 0;
          for (var j = 0; j < i; j++) {
            length += this.computePhaseLength(this.sortedStages[j].journeyPhase, j);
            length += 50;
          }
          this.phase_pos_x.push(this.canvasDragCoefficientX + 175 + length);
        }
      }
    },
    getTaskX: function getTaskX(task_id) {
      for (var j = 0; j < this.computedTaskList.length; j++) {
        if (this.computedTaskList[j].journeyStep.id === task_id) {
          var x = this.computeTaskPosX(j);
          return x + 20;
        }
      }
    },
    getTaskY: function getTaskY(task_id) {
      for (var j = 0; j < this.computedTaskList.length; j++) {
        if (this.computedTaskList[j].journeyStep.id === task_id) {
          var y = this.computeTaskPosY(j);
          return y + 40;
        }
      }
    },
    computeTaskPosX: function computeTaskPosX(index) {
      var recompute = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      if (this.computedTaskList.length === 0) {
        return 0;
      }
      if (this.task_pos_x.length === 0 || recompute) {
        this.computeTaskX();
      }
      return this.task_pos_x[index];
    },
    computeTaskPosY: function computeTaskPosY(index) {
      if (this.task_pos_y.length === 0) {
        this.computeTaskY();
      }
      return this.task_pos_y[index];
    },
    computeTaskX: function computeTaskX() {
      this.task_pos_x = [];
      if (this.journeyMap && this.journeyMap.linkedJourneySteps) {
        for (var n = 0; n < this.computedTaskList.length; n++) {
          for (var m = 0; m < this.journeyMap.additionalattributes.relations.length; m++) {
            if (this.journeyMap.additionalattributes.relations[m].end_type === 'Step' && this.journeyMap.additionalattributes.relations[m].start_type === 'Phase' && this.journeyMap.additionalattributes.relations[m].end_id === this.computedTaskList[n].journeyStep.id) {
              var phase_index = this.getPhaseAndIndexFromID(this.journeyMap.additionalattributes.relations[m].start_id)[1];
              var phase = this.getPhaseAndIndexFromID(this.journeyMap.additionalattributes.relations[m].start_id)[0];
              var phase_start_x = this.computePhaseStartX(phase, phase_index) + 38;
              var relation_index = this.phaseStepRelation[phase_index].indexOf(this.journeyMap.additionalattributes.relations[m].end_id);
              this.task_pos_x.push(phase_start_x + 60 + Math.floor(relation_index / 7) * 7 * 21);
            }
          }
        }
      }
    },
    computeTaskY: function computeTaskY() {
      this.task_pos_y = [];
      var temp_relations = [];
      for (var n = 0; n < this.computedTaskList.length; n++) {
        for (var m = 0; m < this.journeyMap.additionalattributes.relations.length; m++) {
          // Filter out relations from phase to step.
          // This has all the steps and its corresponding phases. Easier to group under phases
          if (this.journeyMap.additionalattributes.relations[m].end_type === 'Step' && this.journeyMap.additionalattributes.relations[m].start_type === 'Phase' && this.journeyMap.additionalattributes.relations[m].end_id === this.computedTaskList[n].journeyStep.id && this.phaseStepRelation.length > 0) {
            temp_relations.push(this.journeyMap.additionalattributes.relations[m]);
          }
        }
      }
      for (var p = 0; p < temp_relations.length; p++) {
        var phase_index = this.getPhaseAndIndexFromID(temp_relations[p].start_id)[1];
        var relation_index = this.phaseStepRelation[phase_index].indexOf(temp_relations[p].end_id);
        this.task_pos_y.push(100 + relation_index % 7 * 35);
      }
    },
    getPhaseAndIndexFromID: function getPhaseAndIndexFromID(phase_id) {
      for (var i = 0; i < this.sortedStages.length; i++) {
        if (this.sortedStages[i].journeyPhase.id === phase_id) {
          return [this.sortedStages[i].journeyPhase, i];
        }
      }
      return [{}, null];
    },
    computePhaseStepRelationArray: function computePhaseStepRelationArray() {
      this.phaseStepRelation = [];
      for (var i = 0; i < this.sortedStages.length; i++) {
        var steps = this.computeStepsForPhase(this.sortedStages[i].journeyPhase.id);
        this.phaseStepRelation[i] = new Array(steps.length);
        this.phaseStepRelation[i] = steps;
      }
    },
    computeStepsForPhase: function computeStepsForPhase(phase_id) {
      var steps = [];
      for (var i = 0; i < this.computedRelations.length; i++) {
        // No need for end_id since phase is always start id
        if (this.computedRelations[i].start_id === phase_id && this.computedRelations[i].start_type === 'Phase' && this.computedRelations[i].end_type === 'Step') {
          steps.push(this.computedRelations[i].end_id);
        }
      }
      return steps;
    },
    isFeatureEnabler: function isFeatureEnabler(sub_entity_id) {
      return this.isEnabler('features', sub_entity_id);
    },
    isServiceApplicationEnabler: function isServiceApplicationEnabler(sub_entity_id) {
      return this.isEnabler('service_applications', sub_entity_id);
    },
    isEnabler: function isEnabler(type, sub_entity_id) {
      for (var i = 0; i < this.offering.additionalattributes.entitiesSortOrder.length; i++) {
        if (this.offering.additionalattributes.entitiesSortOrder[i].type === 'product') {
          for (var j = 0; j < this.offering.additionalattributes.entitiesSortOrder[i].features.length; j++) {
            if (this.offering.additionalattributes.entitiesSortOrder[i].features[j].id === sub_entity_id) {
              return this.offering.additionalattributes.entitiesSortOrder[i].features[j].enabler;
            }
          }
        }
        if (this.offering.additionalattributes.entitiesSortOrder[i].type === 'service') {
          for (var k = 0; k < this.offering.additionalattributes.entitiesSortOrder[i].service_applications.length; k++) {
            if (this.offering.additionalattributes.entitiesSortOrder[i].service_applications[k].id === sub_entity_id) {
              return this.offering.additionalattributes.entitiesSortOrder[i].service_applications[k].enabler;
            }
          }
        }
      }
      return false;
    },
    isFeatureUnmapped: function isFeatureUnmapped(sub_entity_id) {
      return this.isUnmapped('features', sub_entity_id);
    },
    isServiceApplicationUnmapped: function isServiceApplicationUnmapped(sub_entity_id) {
      return this.isUnmapped('service_applications', sub_entity_id);
    },
    isUnmapped: function isUnmapped(type, sub_entity_id) {
      for (var i = 0; i < this.offering.additionalattributes.entitiesSortOrder.length; i++) {
        if (this.offering.additionalattributes.entitiesSortOrder[i].type === 'product') {
          for (var j = 0; j < this.offering.additionalattributes.entitiesSortOrder[i].features.length; j++) {
            if (this.offering.additionalattributes.entitiesSortOrder[i].features[j].id === sub_entity_id) {
              return this.offering.additionalattributes.entitiesSortOrder[i].features[j].unmapped;
            }
          }
        }
        if (this.offering.additionalattributes.entitiesSortOrder[i].type === 'service') {
          for (var k = 0; k < this.offering.additionalattributes.entitiesSortOrder[i].service_applications.length; k++) {
            if (this.offering.additionalattributes.entitiesSortOrder[i].service_applications[k].id === sub_entity_id) {
              return this.offering.additionalattributes.entitiesSortOrder[i].service_applications[k].unmapped;
            }
          }
        }
      }
      return false;
    }
  }
};