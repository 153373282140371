"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }]
  }, [_vm.offering && !_vm.loading ? _c("model-offering-component", {
    staticStyle: {
      "user-select": "none"
    },
    attrs: {
      customer: _vm.selectedCustomer,
      "journey-map": _vm.selectedCustomerJourneyMap,
      offering: _vm.offering,
      "total-offerings-count": _vm.selectedCustomer && _vm.selectedCustomer.linkedOfferings && _vm.selectedCustomer.linkedOfferings.length > 0 ? _vm.selectedCustomer.linkedOfferings.length : 0
    },
    on: {
      "add-risk": _vm.addOfferingRisk,
      "add-offering": _vm.addJourneyMapOffering,
      "add-product": _vm.addProduct,
      "add-service": _vm.addService,
      "add-feature": _vm.addFeature,
      "add-service-application": _vm.addServiceApplication,
      "link-product": _vm.linkProduct,
      "clone-product": _vm.cloneProduct,
      "link-service": _vm.linkService,
      "clone-service": _vm.cloneService,
      "edit-product": _vm.editProduct,
      "edit-service": _vm.editService,
      "edit-feature": _vm.editFeature,
      "edit-feature-title-description": _vm.editFeatureTitleDescription,
      "edit-service-application-title-description": _vm.editServiceApplicationTitleDescription,
      "edit-service-application-type": _vm.editServiceApplicationType,
      "edit-feature-enabler": _vm.editFeatureEnabler,
      "update-feature-location": _vm.updateFeatureLocation,
      "update-service-application-location": _vm.updateServiceApplicationLocation,
      "update-feature-feature-relation": _vm.updateFeatureFeatureRelation,
      "delete-feature-feature-relation": _vm.deleteFeatureFeatureRelation,
      "delete-service-application-service-application-relation": _vm.deleteServiceApplicationServiceApplicationRelation,
      "update-service-application-service-application-relation": _vm.updateServiceApplicationServiceApplicationRelation,
      "rename-offering": _vm.renameOffering,
      "delete-journeymap": _vm.deleteJourneyMap,
      "delete-offering": _vm.deleteOffering,
      "delete-product": _vm.deleteProduct,
      "delete-service": _vm.deleteService,
      "unlink-product": _vm.unlinkProduct,
      "unlink-service": _vm.unlinkService,
      "delete-feature": _vm.deleteFeature,
      "delete-idea": _vm.deleteIdea,
      "delete-risk": _vm.deleteRisk,
      "delete-service-application": _vm.deleteServiceApplication,
      "rearrange-lanes": _vm.rearrangeLanes,
      "unlink-feature": _vm.unlinkFeature,
      "update-task-to-workflow": _vm.updateTaskToWorkflow,
      "update-step-to-feature-relation": _vm.updateStepToFeatureRelation,
      "update-service-application-relation": _vm.updateServiceApplicationRelation,
      "update-offering-additionalattributes": _vm.updateOfferingAdditionalAttributes,
      "update-journeymap-additionalattributes": _vm.updateJourneymapAdditionalAttributes,
      reload: function reload($event) {
        return _vm.retrieveCustomerJourneyMap(true);
      }
    }
  }) : _c("el-empty", {
    attrs: {
      description: "No offering found"
    }
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;