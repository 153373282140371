"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("g", {
    attrs: {
      refs: "task-component"
    }
  }, [_c("g", {
    attrs: {
      refs: "task-top-collider"
    }
  }, [_c("rect", {
    attrs: {
      x: _vm.isTool ? _vm.x - 30 : _vm.x - 15,
      y: _vm.y - 20,
      width: 30,
      height: 5,
      rx: 5,
      fill: "transparent"
    },
    on: {
      mouseover: function mouseover($event) {
        $event.preventDefault();
        return _vm.emitOnMouseLeave(true);
      }
    }
  })]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "task-bottom-collider"
    }
  }, [_c("rect", {
    attrs: {
      x: _vm.isTool ? _vm.x - 30 : _vm.x - 15,
      y: _vm.y + 15,
      width: 30,
      height: 5,
      rx: 5,
      fill: "transparent"
    },
    on: {
      mouseover: function mouseover($event) {
        $event.preventDefault();
        return _vm.emitOnMouseLeave(true);
      }
    }
  })]), _vm._v(" "), _c("transition", {
    attrs: {
      name: "fade",
      mode: "out-in"
    }
  }, [_vm.isHover && !_vm.readOnly && !_vm.isTool ? _c("g", [_c("foreignObject", {
    directives: [{
      name: "long-press",
      rawName: "v-long-press",
      value: 450,
      expression: "450"
    }],
    attrs: {
      x: _vm.x + 25,
      y: _vm.y - 12,
      width: 30,
      height: 30
    },
    on: {
      "long-press-start": _vm.onLongPressWorkflowStart,
      "long-press-stop": _vm.onLongPressWorkflowStop
    }
  }, [_c("i", {
    directives: [{
      name: "dragged",
      rawName: "v-dragged.prevent",
      value: _vm.onTaskWorkFlowDragged,
      expression: "onTaskWorkFlowDragged",
      modifiers: {
        prevent: true
      }
    }],
    staticClass: "fas fa-caret-right",
    style: _vm.computeArrowStyle(),
    attrs: {
      title: "Add new workflow"
    }
  })]), _vm._v(" "), _c("foreignObject", {
    directives: [{
      name: "long-press",
      rawName: "v-long-press",
      value: 450,
      expression: "450"
    }],
    attrs: {
      x: _vm.x - 25,
      y: _vm.y - 12,
      width: 30,
      height: 30
    },
    on: {
      "long-press-start": _vm.onLongPressWorkflowStart,
      "long-press-stop": _vm.onLongPressWorkflowStop
    }
  }, [_c("i", {
    directives: [{
      name: "dragged",
      rawName: "v-dragged.prevent",
      value: _vm.onTaskWorkFlowDragged,
      expression: "onTaskWorkFlowDragged",
      modifiers: {
        prevent: true
      }
    }],
    staticClass: "fas fa-caret-left",
    style: _vm.computeArrowStyle(),
    attrs: {
      title: "Add new workflow"
    }
  })])]) : _vm._e()]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "background"
    }
  }, [_c("rect", {
    attrs: {
      x: _vm.isTool ? _vm.x - 8 : _vm.x - 8,
      y: _vm.isTool ? _vm.y - 13 : _vm.y - 13,
      width: 26,
      height: 26,
      rx: 10,
      fill: "rgba(255,0,0,0.1)"
    }
  }), _vm._v(" "), _c("rect", {
    attrs: {
      x: _vm.isTool ? _vm.x - 5 : _vm.x - 5,
      y: _vm.isTool ? _vm.y - 10 : _vm.y - 10,
      width: 20,
      height: 20,
      rx: 9,
      fill: "white"
    }
  })]), _vm._v(" "), _vm.isHighlight || _vm.isRelationHover ? _c("rect", {
    staticClass: "gaphighlighter-anim",
    attrs: {
      x: _vm.x - 8,
      y: _vm.y - 13,
      width: 26,
      height: 26,
      rx: 10,
      fill: "rgba(38,66,139,0.1)",
      "stroke-width": "1",
      stroke: "rgba(0,128, 128, 0.65)",
      "stroke-dasharray": "4"
    }
  }) : _vm._e(), _vm._v(" "), _c("circle", {
    attrs: {
      cx: _vm.x + 5,
      cy: _vm.y,
      r: 7,
      fill: "transparent",
      "stroke-width": "0",
      stroke: "#26428B",
      "stroke-dasharray": "2"
    }
  }), _vm._v(" "), _c("g", {
    directives: [{
      name: "long-press",
      rawName: "v-long-press",
      value: 450,
      expression: "450"
    }],
    on: {
      "long-press-start": function longPressStart($event) {
        $event.preventDefault();
        $event.stopPropagation();
        return _vm.onLongPressStart.apply(null, arguments);
      },
      "long-press-stop": function longPressStop($event) {
        $event.preventDefault();
        $event.stopPropagation();
        return _vm.onLongPressStop.apply(null, arguments);
      },
      mouseleave: function mouseleave($event) {
        $event.preventDefault();
        $event.stopPropagation();
        return _vm.emitOnToolMouseLeave.apply(null, arguments);
      }
    }
  }, [_c("circle", {
    directives: [{
      name: "dragged",
      rawName: "v-dragged.prevent",
      value: _vm.onTaskDragged,
      expression: "onTaskDragged",
      modifiers: {
        prevent: true
      }
    }],
    attrs: {
      cx: _vm.x + 5,
      cy: _vm.y,
      r: 7,
      fill: "#fff",
      stroke: "#304174",
      "stroke-width": "1"
    },
    on: {
      mouseover: _vm.emitOnMouseOver,
      click: function click($event) {
        $event.preventDefault();
        return _vm.emitClick.apply(null, arguments);
      }
    }
  }), _vm._v(" "), _vm.isTopRendered ? _c("g", [_c("line", {
    staticStyle: {
      stroke: "rgb(0,0,0)",
      "stroke-width": "1"
    },
    attrs: {
      x1: _vm.x + 5,
      y1: _vm.y - 7,
      x2: _vm.x + 5,
      y2: _vm.y - 17,
      "stroke-dasharray": "3,3"
    }
  }), _vm._v(" "), _c("s-v-g-text-component", {
    attrs: {
      x: _vm.x + 2,
      y: _vm.y - 25,
      label: _vm.label,
      length: 30,
      "text-anchor": "start",
      "font-size": ".95em",
      "text-style": "fill:grey;"
    }
  })], 1) : _c("g", [_c("line", {
    staticStyle: {
      stroke: "rgb(0,0,0)",
      "stroke-width": "1"
    },
    attrs: {
      x1: _vm.x + 5,
      y1: _vm.y + 7,
      x2: _vm.x + 5,
      y2: _vm.y + 17,
      "stroke-dasharray": "3,3"
    }
  }), _vm._v(" "), _c("s-v-g-text-component", {
    attrs: {
      x: _vm.x + 2,
      y: _vm.y + 30,
      label: _vm.label,
      length: 30,
      "text-anchor": _vm.isTool ? "middle" : "start",
      "font-size": ".95em",
      "text-style": "fill:#4672c4;"
    }
  })], 1)])], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;