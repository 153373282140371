"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "wrapper",
    style: _vm.computeStyle()
  }, [_c("div", {
    staticClass: "wscn-httpstatus-container"
  }, [_c("div", {
    staticClass: "wscn-httpstatus"
  }, [_vm._m(0), _vm._v(" "), _c("div", {
    staticClass: "status"
  }, [_c("div", {
    staticClass: "status__info"
  }, [_vm._v(_vm._s(_vm.long_description))]), _vm._v(" "), _c("a", {
    staticClass: "status__return-home",
    attrs: {
      href: "/"
    }
  }, [_vm._v("Back to home")])]), _vm._v(" "), _c("div", {
    staticClass: "pic-status",
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm.type === "success" ? _c("img", {
    staticClass: "pic-status__parent",
    staticStyle: {
      height: "200px",
      width: "auto",
      padding: "25px 0 25px 0"
    },
    attrs: {
      src: require("@/assets/img/check.png"),
      alt: "status"
    }
  }) : _c("img", {
    staticClass: "pic-status__parent",
    staticStyle: {
      height: "200px",
      width: "auto",
      padding: "25px 0 25px 0"
    },
    attrs: {
      src: require("@/assets/img/close.png"),
      alt: "status"
    }
  })])])])]);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "logo"
  }, [_c("img", {
    staticClass: "logo__parent",
    attrs: {
      src: require("@/assets/img/prodiggi-logo.png"),
      alt: "status"
    }
  })]);
}];
render._withStripped = true;