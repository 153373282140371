"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _default = exports.default = {
  name: 'Search',
  props: {
    title: {
      type: String,
      default: 'Item'
    },
    createTitle: {
      type: String,
      default: ''
    },
    createItem: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      search_items: '',
      filter_entities: ['Product', 'Service']
    };
  },
  computed: {
    searchPlaceholderText: function searchPlaceholderText() {
      return 'Search ' + this.title;
    },
    styleProduct: function styleProduct() {
      if (this.filter_entities.indexOf('Product') === -1) {
        return 'font-size: 12px; font-weight:bold;color:#0D63BF';
      }
      return 'font-size: 12px; font-weight:bold;color:#FFF';
    },
    styleService: function styleService() {
      if (this.filter_entities.indexOf('Service') === -1) {
        return 'font-size: 12px; font-weight:bold;color:#4CA97E';
      }
      return 'font-size: 12px; font-weight:bold;color:#FFF';
    }
  },
  watch: {
    search_items: function search_items(val) {
      this.$emit('search', val, this.filter_entities);
    }
  },
  methods: {
    changeFilterEntities: function changeFilterEntities(filter) {
      this.$emit('search', this.search_items, filter);
    },
    toggleFilter: function toggleFilter(filter_item) {
      if (!this.filter_entities.includes(filter_item)) {
        this.filter_entities.push(filter_item);
      } else {
        this.filter_entities.splice(this.filter_entities.indexOf(filter_item), 1);
      }
      this.$emit('search', this.search_items, this.filter_entities);
    },
    openCreateItemDialog: function openCreateItemDialog() {
      this.$emit('create');
    }
  }
};