"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _CustomContextPad = _interopRequireDefault(require("./CustomContextPad"));
var _CustomPalette = _interopRequireDefault(require("./CustomPalette"));
var _default = exports.default = {
  __init__: ['customContextPad', 'customPalette'],
  customContextPad: ['type', _CustomContextPad.default],
  customPalette: ['type', _CustomPalette.default]
};