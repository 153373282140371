"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Process = _interopRequireDefault(require("./Process.vue"));
var _Avatar = _interopRequireDefault(require("./process_components/Avatar.vue"));
// import TaskComponent from './process_components/Task.vue'
var _default = exports.default = {
  name: 'Legend',
  components: {
    ProcessComponent: _Process.default,
    AvatarComponent: _Avatar.default
    // TaskComponent
  },
  data: function data() {
    return {};
  }
};