"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ListItems = _interopRequireDefault(require("@/components/ListItems"));
var _default = exports.default = {
  name: 'TemplateList',
  components: {
    ItemList: _ListItems.default
  },
  data: function data() {
    return {
      loading: false,
      createItemDialog: false,
      selectedItem: {},
      items: [{
        id: 1,
        title: 'Item 1',
        description: 'description'
      }, {
        id: 2,
        title: 'Item 2',
        description: 'description'
      }, {
        id: 3,
        title: 'Item 3',
        description: 'description'
      }, {
        id: 4,
        title: 'Item 4',
        description: 'description'
      }]
    };
  },
  computed: {},
  mounted: function mounted() {},
  methods: {
    selectItem: function selectItem(item, index) {
      this.selectedItem = item;
    },
    createItem: function createItem() {
      this.createItemDialog = true;
    }
  }
};