"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _SimpleGraph = _interopRequireDefault(require("@/components/Charts/SimpleGraph"));
var _default2 = exports.default = {
  name: 'CustomerLink',
  components: {
    SimpleGraphChart: _SimpleGraph.default
  },
  props: {
    groups: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      defaultProps: {
        children: 'customers',
        label: 'name'
      }
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.$eventHub.$emit('process-step-load', 'customer-link');
    window.addEventListener('mouseup', this.stopDrag);
    this.loading = true;
    this.$store.dispatch('getCustomerTypes').then(function (data) {
      _this.loading = false;
      _this.customer_types = data.data;
      // this.generateCustomerTypesDragElement()
    }).catch(function (e) {
      _this.loading = false;
      _this.$message({
        type: 'error',
        message: e.message
      });
    });
  },
  methods: {
    gotoDefineUserValueProposition: function gotoDefineUserValueProposition() {
      this.$emit('go-prev');
    }
  }
};