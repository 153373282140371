"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _default2 = exports.default = {
  name: 'Experience',
  components: {},
  props: {
    experience: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    phaseParam: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    index: {
      type: Number,
      default: 0
    },
    selectedActivityIndex: {
      type: Number,
      default: 0
    },
    selectedPhaseIndex: {
      type: Number,
      default: 0
    },
    subIndex: {
      type: Number,
      default: 0
    },
    experienceSubIndex: {
      type: Number,
      default: 0
    },
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    }
  },
  data: function data() {
    return {
      loading: false
    };
  },
  computed: {},
  created: function created() {},
  methods: {
    deleteExperience: function deleteExperience(index, activity_index, experience_index) {
      this.$emit('delete-experience', index, activity_index, experience_index);
    },
    generatePriorityPath: function generatePriorityPath(index, sub_index, priority, priority_index) {
      var start_point_x = 200 + index * (this.phaseParam.width + 25);
      var start_point_y = 0;
      if (priority === 'low') {
        start_point_y = 92 + 350 + sub_index * (this.phaseParam.title_height + 5);
        return 'm ' + start_point_x + ' ' + start_point_y + ' l 5 5 l 5 -5';
      }
      if (priority === 'medium') {
        start_point_y = 95 + 350 + sub_index * (this.phaseParam.title_height + 5);
        return 'm ' + start_point_x + ' ' + start_point_y + ' l 10 0';
      }
      if (priority === 'high') {
        start_point_y = 98 + 350 + sub_index * (this.phaseParam.title_height + 5);
        return 'm ' + start_point_x + ' ' + start_point_y + ' l 5 -5 l 5 5';
      }
    }
  }
};