"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return !_vm.item.hidden ? _c("div", {
    staticClass: "menu-wrapper"
  }, [_vm.hasOneShowingChild(_vm.item.children, _vm.item) && (!_vm.onlyOneChild.children || _vm.onlyOneChild.noShowingChildren) && !_vm.item.alwaysShow ? [_vm.onlyOneChild.meta ? _c("app-link", {
    attrs: {
      to: _vm.resolvePath(_vm.onlyOneChild.path)
    }
  }, [_vm.hasPermissionForRoute(_vm.item) ? _c("el-menu-item", {
    class: _vm.computeMenuItemClass,
    staticStyle: {
      color: "ghostwhite !important"
    },
    attrs: {
      index: _vm.resolvePath(_vm.onlyOneChild.path)
    }
  }, [_c("item", {
    attrs: {
      icon: _vm.onlyOneChild.meta.icon || _vm.item.meta && _vm.item.meta.icon,
      title: _vm.computeTitle(_vm.onlyOneChild.meta.title)
    }
  })], 1) : _vm._e()], 1) : _vm._e()] : _vm.item.name === "Customer Needs" ? _c("el-submenu", {
    ref: "subMenu",
    attrs: {
      index: _vm.resolvePath(_vm.item.path),
      "popper-append-to-body": ""
    }
  }, [_c("template", {
    slot: "title"
  }, [_vm.item.meta ? _c("item", {
    attrs: {
      icon: _vm.item.meta && _vm.item.meta.icon,
      title: _vm.item.meta.title
    }
  }) : _vm._e()], 1), _vm._v(" "), _vm.item.name === "Customer Needs" ? [_c("div", {
    staticClass: "customer-menu-container",
    staticStyle: {
      "background-color": "lightslategrey",
      margin: "0 6pt 0 6pt",
      color: "ghostwhite !important"
    }
  }, [_vm.selectedCustomerTypeId === "" ? _c("div", {
    staticStyle: {
      padding: "0 10px 0px 10px"
    }
  }, [_c("span", [_vm._v("No customer type found. Select a customer type")])]) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "flex text-center"
  }, [_c("el-select", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticStyle: {
      padding: "0"
    },
    attrs: {
      size: "mini",
      placeholder: "Type to search"
    },
    on: {
      change: _vm.updateCustomerType
    },
    model: {
      value: _vm.selectedCustomerTypeId,
      callback: function callback($$v) {
        _vm.selectedCustomerTypeId = $$v;
      },
      expression: "selectedCustomerTypeId"
    }
  }, [_c("el-option-group", [_c("el-option", {
    attrs: {
      label: "Create New Customer",
      value: "create_new_customer_type"
    }
  })], 1), _vm._v(" "), _c("el-option-group", _vm._l(_vm.customer_types, function (customer_type, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: _vm.trimString(customer_type.title, 20),
        value: customer_type.id
      }
    });
  }), 1)], 1)], 1), _vm._v(" "), _vm.selectedCustomerTypeId !== "" ? [_vm._l(_vm.filteredPrimary, function (child) {
    return [_vm.hasPermissionForRoute(child) ? _c("sidebar-item", {
      key: child.path,
      staticClass: "nest-menu customer-submenu",
      staticStyle: {
        "max-width": "180pt !important"
      },
      attrs: {
        "is-nest": true,
        item: child,
        "base-path": _vm.resolvePath(child.path)
      }
    }) : _vm._e()];
  })] : _vm._e()], 2), _vm._v(" "), _vm.selectedCustomerTypeId !== "" ? _c("div", {
    staticClass: "customer-addon-menu-container",
    staticStyle: {
      "background-color": "lightslategrey",
      margin: "0 6pt 0 6pt",
      color: "ghostwhite !important"
    }
  }, [_vm._l(_vm.filteredAddOn, function (child) {
    return [_vm.hasPermissionForRoute(child) ? _c("sidebar-item", {
      key: child.path,
      staticClass: "nest-menu customer-addon",
      staticStyle: {
        "max-width": "180pt !important"
      },
      attrs: {
        "is-nest": true,
        item: child,
        "base-path": _vm.resolvePath(child.path)
      }
    }) : _vm._e()];
  })], 2) : _vm._e()] : _vm.hasPermissionForRoute(_vm.child) ? _vm._l(_vm.item.children, function (child) {
    return _c("sidebar-item", {
      key: child.path,
      staticClass: "nest-menu",
      attrs: {
        "is-nest": true,
        item: child,
        "base-path": _vm.resolvePath(child.path)
      }
    });
  }) : _vm._e()], 2) : _vm.item.name === "Offering" ? _c("el-submenu", {
    ref: "subMenu",
    attrs: {
      index: _vm.resolvePath(_vm.item.path),
      "popper-append-to-body": ""
    }
  }, [_c("template", {
    slot: "title"
  }, [_vm.item.meta ? _c("item", {
    attrs: {
      icon: _vm.item.meta && _vm.item.meta.icon,
      title: _vm.item.meta.title
    }
  }) : _vm._e()], 1), _vm._v(" "), _vm.item.name === "Offering" ? [_c("div", {
    staticClass: "offering-menu-container",
    staticStyle: {
      "background-color": "lightslategrey",
      margin: "0 6pt 0 6pt",
      color: "ghostwhite"
    }
  }, [_vm.selectedOfferingId === "" ? _c("div", {
    staticStyle: {
      padding: "0 10px 0px 10px"
    }
  }, [_c("span", [_vm._v("No offering found. Select an offering")])]) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "text-center"
  }, [_c("el-select", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticStyle: {
      padding: "0"
    },
    attrs: {
      size: "mini",
      placeholder: "Type to search"
    },
    on: {
      change: _vm.updateOfferingID
    },
    model: {
      value: _vm.selectedOfferingId,
      callback: function callback($$v) {
        _vm.selectedOfferingId = $$v;
      },
      expression: "selectedOfferingId"
    }
  }, [_c("el-option-group", [_c("el-option", {
    attrs: {
      label: "Create New offering",
      value: "create_new_offering"
    }
  })], 1), _vm._v(" "), _c("el-option-group", _vm._l(_vm.offerings, function (offering, index) {
    return _c("el-option", {
      key: index,
      staticClass: "offering-dropdown-menu",
      attrs: {
        label: _vm.trimString(offering.title, 20),
        value: offering.id
      }
    }, [_c("el-tooltip", {
      staticClass: "item",
      attrs: {
        disabled: offering.title.length < 20,
        effect: "dark",
        content: offering.title,
        placement: "top"
      }
    }, [_c("span", {
      staticStyle: {
        float: "left"
      }
    }, [_vm._v(_vm._s(_vm.trimString(offering.title, 20)))])]), _vm._v(" "), _c("span", {
      staticClass: "offering-category",
      staticStyle: {
        float: "right",
        color: "#8492a6",
        "font-size": "11px"
      }
    }, [_c("el-tooltip", {
      attrs: {
        effect: "dark",
        content: "Cloned offering",
        placement: "top"
      }
    }, [offering.iscloned ? _c("svg-icon", {
      staticStyle: {
        color: "black"
      },
      attrs: {
        "icon-class": "clone"
      }
    }) : _vm._e()], 1), _vm._v(" "), _c("el-tooltip", {
      attrs: {
        effect: "dark",
        content: "Primary offering",
        placement: "top"
      }
    }, [offering.offeringcategory === "Primary" ? _c("svg-icon", {
      staticStyle: {
        color: "black"
      },
      attrs: {
        "icon-class": "inherit-primary"
      }
    }) : _vm._e()], 1), _vm._v(" "), _c("el-tooltip", {
      attrs: {
        effect: "dark",
        content: "Secondary offering",
        placement: "top"
      }
    }, [offering.offeringcategory === "Secondary" ? _c("svg-icon", {
      staticStyle: {
        color: "#999999"
      },
      attrs: {
        "icon-class": "inherit-secondary"
      }
    }) : _vm._e()], 1)], 1)], 1);
  }), 1)], 1)], 1), _vm._v(" "), _vm.selectedOfferingId !== "" ? [_vm._l(_vm.filteredPrimary, function (child) {
    return [_vm.hasPermissionForRoute(child) ? _c("sidebar-item", {
      key: child.path,
      staticClass: "nest-menu offering-submenu",
      attrs: {
        "is-nest": true,
        item: child,
        "base-path": _vm.resolvePath(child.path)
      }
    }) : _vm._e()];
  })] : _vm._e()], 2), _vm._v(" "), _vm.selectedOfferingId !== "" ? _c("div", {
    staticClass: "offering-addon-menu-container",
    staticStyle: {
      "background-color": "lightslategrey",
      margin: "0 6pt 0 6pt",
      color: "ghostwhite"
    }
  }, [_vm._l(_vm.filteredAddOn, function (child) {
    return [_vm.hasPermissionForRoute(child) ? _c("sidebar-item", {
      key: child.path,
      staticClass: "nest-menu offering-addon",
      attrs: {
        "is-nest": true,
        item: child,
        "base-path": _vm.resolvePath(child.path)
      }
    }) : _vm._e()];
  })], 2) : _vm._e()] : _vm.hasPermissionForRoute(_vm.child) ? _vm._l(_vm.item.children, function (child) {
    return _c("sidebar-item", {
      key: child.path,
      staticClass: "nest-menu",
      attrs: {
        "is-nest": true,
        item: child,
        "base-path": _vm.resolvePath(child.path)
      }
    });
  }) : _vm._e()], 2) : _vm.item.name === "Delivery" && _vm.selectedOfferingId !== "" && _vm.hasPermissionForRoute(_vm.item) ? _c("el-submenu", {
    ref: "subMenu",
    staticClass: "delivery-menu",
    attrs: {
      index: _vm.resolvePath(_vm.item.path),
      "popper-append-to-body": ""
    }
  }, [_c("template", {
    slot: "title"
  }, [_vm.item.meta ? _c("item", {
    staticClass: "delivery-menu-item",
    attrs: {
      icon: _vm.item.meta && _vm.item.meta.icon,
      title: _vm.item.meta.title
    }
  }) : _vm._e()], 1), _vm._v(" "), _vm.item.name === "Delivery" ? [_c("div", {
    staticClass: "deliver-menu-container",
    staticStyle: {
      "background-color": "lightslategrey",
      margin: "0 6pt 0 6pt",
      color: "ghostwhite"
    }
  }, [_vm.selectedOfferingId === "" ? _c("div", {
    staticStyle: {
      padding: "0 10px 0px 10px"
    }
  }, [_c("span", [_vm._v("No offering found. Select an offering")])]) : _vm._e(), _vm._v(" "), _vm.selectedOfferingId !== "" ? [_vm._l(_vm.filteredPrimary, function (child) {
    return [_vm.hasPermissionForRoute(child) ? _c("sidebar-item", {
      key: child.path,
      staticClass: "nest-menu deliver-submenu",
      attrs: {
        "is-nest": true,
        item: child,
        "base-path": _vm.resolvePath(child.path)
      }
    }) : _vm._e()];
  })] : _vm._e()], 2), _vm._v(" "), _vm.selectedOfferingId !== "" ? _c("div", {
    staticClass: "deliver-addon-menu-container",
    staticStyle: {
      "background-color": "lightslategrey",
      margin: "0 6pt 0 6pt",
      color: "ghostwhite"
    }
  }, [_vm._l(_vm.filteredAddOn, function (child) {
    return [_vm.hasPermissionForRoute(child) ? _c("sidebar-item", {
      key: child.path,
      staticClass: "nest-menu deliver-addon",
      attrs: {
        "is-nest": true,
        item: child,
        "base-path": _vm.resolvePath(child.path)
      }
    }) : _vm._e()];
  })], 2) : _vm._e()] : _vm.hasPermissionForRoute(_vm.child) ? _vm._l(_vm.item.children, function (child) {
    return _c("sidebar-item", {
      key: child.path,
      staticClass: "nest-menu",
      attrs: {
        "is-nest": true,
        item: child,
        "base-path": _vm.resolvePath(child.path)
      }
    });
  }) : _vm._e()], 2) : _vm.item.name === "Reports" && _vm.hasPermissionForRoute(_vm.item) ? _c("el-submenu", {
    ref: "subMenu",
    attrs: {
      index: _vm.resolvePath(_vm.item.path),
      "popper-append-to-body": ""
    }
  }, [_c("template", {
    slot: "title"
  }, [_vm.item.meta ? _c("item", {
    attrs: {
      icon: _vm.item.meta && _vm.item.meta.icon,
      title: _vm.item.meta.title
    }
  }) : _vm._e()], 1), _vm._v(" "), _vm.item.name === "Reports" ? [_c("div", {
    staticClass: "deliver-menu-container",
    staticStyle: {
      "background-color": "lightslategrey",
      margin: "0 6pt 0 6pt",
      color: "ghostwhite"
    }
  }, [_vm._l(_vm.filteredPrimary, function (child) {
    return [_vm.hasPermissionForRoute(child) ? _c("sidebar-item", {
      key: child.path,
      staticClass: "nest-menu deliver-submenu",
      attrs: {
        "is-nest": true,
        item: child,
        "base-path": _vm.resolvePath(child.path)
      }
    }) : _vm._e()];
  })], 2)] : _vm.hasPermissionForRoute(_vm.child) ? _vm._l(_vm.item.children, function (child) {
    return _c("sidebar-item", {
      key: child.path,
      staticClass: "nest-menu",
      attrs: {
        "is-nest": true,
        item: child,
        "base-path": _vm.resolvePath(child.path)
      }
    });
  }) : _vm._e()], 2) : _vm._e(), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Create Customer Type",
      visible: _vm.createCustomerTypeDialog,
      "append-to-body": true,
      width: "50%",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.createCustomerTypeDialog = $event;
      }
    }
  }, [_c("create-customer-component", {
    on: {
      "create-customer": _vm.createCustomer,
      close: function close($event) {
        return _vm.closeCustomerCreateDialog();
      }
    }
  })], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Create Offering",
      visible: _vm.createOfferingDialog,
      "append-to-body": true,
      width: "50%",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.createOfferingDialog = $event;
      },
      close: function close($event) {
        return _vm.closeOfferingCreateDialog();
      }
    }
  }, [_c("create-offering-component", {
    on: {
      "create-offering": _vm.createOffering,
      close: function close($event) {
        return _vm.closeOfferingCreateDialog();
      }
    }
  })], 1)], 2) : _vm._e();
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;