"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-row", {
    staticClass: "mt-10",
    attrs: {
      gutter: 20
    }
  }, [_c("div", {
    staticClass: "ml-10"
  }, [_c("a", {
    staticStyle: {
      "font-size": "1.5em",
      "font-weight": "bold"
    }
  }, [_vm._v("High Level Product Roadmap")]), _vm._v(" "), _c("br"), _vm._v(" "), _c("a", [_vm._v("Plan major milestones for this product (1-3 years)")]), _vm._v(" "), _c("br"), _vm._v(" "), _c("br")]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("div", {
    on: {
      dragover: _vm.handleDragOver
    }
  }, [_c("svg", {
    attrs: {
      height: "850px",
      width: "100%",
      xmlns: "http://www.w3.org/2000/svg",
      version: "1.1"
    }
  }, [_c("g", {
    attrs: {
      refs: "horizontal_lanes"
    }
  }, _vm._l(_vm.roadmap.lanes, function (lane, index) {
    return _c("roadmap-swimlane-component", {
      key: index,
      attrs: {
        x: 0,
        y: 100 + index * 175,
        index: index,
        lane: lane
      },
      on: {
        click: function click($event) {
          return _vm.openColorDialog(lane);
        }
      }
    });
  }), 1), _vm._v(" "), _c("g", {
    attrs: {
      refs: "vertical_lanes"
    }
  }, [_c("line", {
    staticStyle: {
      stroke: "gray",
      "stroke-width": "2",
      "stroke-opacity": "0.2"
    },
    attrs: {
      x1: "100",
      y1: "50",
      x2: "100",
      y2: _vm.computeVerticalLaneHeight()
    }
  }), _vm._v(" "), _c("line", {
    staticStyle: {
      stroke: "gray",
      "stroke-width": "2",
      "stroke-opacity": "0.2"
    },
    attrs: {
      x1: "175",
      y1: "50",
      x2: "175",
      y2: _vm.computeVerticalLaneHeight()
    }
  }), _vm._v(" "), _c("line", {
    staticStyle: {
      stroke: "gray",
      "stroke-width": "2",
      "stroke-opacity": "0.2"
    },
    attrs: {
      x1: "250",
      y1: "50",
      x2: "250",
      y2: _vm.computeVerticalLaneHeight()
    }
  }), _vm._v(" "), _c("line", {
    staticStyle: {
      stroke: "gray",
      "stroke-width": "2",
      "stroke-opacity": "0.2"
    },
    attrs: {
      x1: "325",
      y1: "50",
      x2: "325",
      y2: _vm.computeVerticalLaneHeight()
    }
  }), _vm._v(" "), _c("line", {
    staticStyle: {
      stroke: "gray",
      "stroke-width": "2",
      "stroke-opacity": "0.2"
    },
    attrs: {
      x1: "400",
      y1: "50",
      x2: "400",
      y2: _vm.computeVerticalLaneHeight()
    }
  }), _vm._v(" "), _c("line", {
    staticStyle: {
      stroke: "gray",
      "stroke-width": "2",
      "stroke-opacity": "0.2"
    },
    attrs: {
      x1: "475",
      y1: "50",
      x2: "475",
      y2: _vm.computeVerticalLaneHeight()
    }
  }), _vm._v(" "), _c("line", {
    staticStyle: {
      stroke: "gray",
      "stroke-width": "2",
      "stroke-opacity": "0.2"
    },
    attrs: {
      x1: "550",
      y1: "50",
      x2: "550",
      y2: _vm.computeVerticalLaneHeight()
    }
  }), _vm._v(" "), _c("line", {
    staticStyle: {
      stroke: "gray",
      "stroke-width": "2",
      "stroke-opacity": "0.2"
    },
    attrs: {
      x1: "625",
      y1: "50",
      x2: "625",
      y2: _vm.computeVerticalLaneHeight()
    }
  }), _vm._v(" "), _c("line", {
    staticStyle: {
      stroke: "gray",
      "stroke-width": "2",
      "stroke-opacity": "0.2"
    },
    attrs: {
      x1: "700",
      y1: "50",
      x2: "700",
      y2: _vm.computeVerticalLaneHeight()
    }
  }), _vm._v(" "), _c("line", {
    staticStyle: {
      stroke: "gray",
      "stroke-width": "2",
      "stroke-opacity": "0.2"
    },
    attrs: {
      x1: "775",
      y1: "50",
      x2: "775",
      y2: _vm.computeVerticalLaneHeight()
    }
  }), _vm._v(" "), _c("line", {
    staticStyle: {
      stroke: "gray",
      "stroke-width": "2",
      "stroke-opacity": "0.2"
    },
    attrs: {
      x1: "850",
      y1: "50",
      x2: "850",
      y2: _vm.computeVerticalLaneHeight()
    }
  }), _vm._v(" "), _c("line", {
    staticStyle: {
      stroke: "gray",
      "stroke-width": "2",
      "stroke-opacity": "0.2"
    },
    attrs: {
      x1: "925",
      y1: "50",
      x2: "925",
      y2: _vm.computeVerticalLaneHeight()
    }
  }), _vm._v(" "), _c("line", {
    staticStyle: {
      stroke: "gray",
      "stroke-width": "2",
      "stroke-opacity": "0.2"
    },
    attrs: {
      x1: "1000",
      y1: "50",
      x2: "1000",
      y2: _vm.computeVerticalLaneHeight()
    }
  }), _vm._v(" "), _c("line", {
    staticStyle: {
      stroke: "gray",
      "stroke-width": "2",
      "stroke-opacity": "0.2"
    },
    attrs: {
      x1: "1075",
      y1: "50",
      x2: "1075",
      y2: _vm.computeVerticalLaneHeight()
    }
  }), _vm._v(" "), _c("line", {
    staticStyle: {
      stroke: "gray",
      "stroke-width": "2",
      "stroke-opacity": "0.2"
    },
    attrs: {
      x1: "1150",
      y1: "50",
      x2: "1150",
      y2: _vm.computeVerticalLaneHeight()
    }
  }), _vm._v(" "), _c("line", {
    staticStyle: {
      stroke: "gray",
      "stroke-width": "2",
      "stroke-opacity": "0.2"
    },
    attrs: {
      x1: "1225",
      y1: "50",
      x2: "1225",
      y2: _vm.computeVerticalLaneHeight()
    }
  }), _vm._v(" "), _c("line", {
    staticStyle: {
      stroke: "gray",
      "stroke-width": "2",
      "stroke-opacity": "0.2"
    },
    attrs: {
      x1: "1300",
      y1: "50",
      x2: "1300",
      y2: _vm.computeVerticalLaneHeight()
    }
  }), _vm._v(" "), _vm._l(16, function (month, index) {
    return _c("text", {
      key: index,
      staticStyle: {
        fill: "#000000"
      },
      attrs: {
        x: 137 + index * 75,
        y: "75",
        "text-anchor": "middle",
        "font-weight": "bold"
      }
    }, [_vm._v("\n              " + _vm._s(_vm.computeMonthName(index)) + "\n            ")]);
  }), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "#000000"
    },
    attrs: {
      x: "137",
      y: "50",
      "text-anchor": "middle",
      "font-size": "1.5em"
    }
  }, [_vm._v("2020")]), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "#000000"
    },
    attrs: {
      x: "887",
      y: "50",
      "text-anchor": "middle",
      "font-size": "1.5em"
    }
  }, [_vm._v("2021")])], 2), _vm._v(" "), _c("g", {
    attrs: {
      refs: "phases"
    }
  }, _vm._l(_vm.roadmap.phases, function (phase, index) {
    return _c("roadmap-phase-component", {
      directives: [{
        name: "dragged",
        rawName: "v-dragged",
        value: _vm.startPhaseMove,
        expression: "startPhaseMove"
      }],
      key: _vm.generatePhaseID(index),
      attrs: {
        id: _vm.generatePhaseID(index),
        x: _vm.computePhaseX(index),
        width: _vm.computePhaseWidth(index),
        height: _vm.computeVerticalLaneHeight(),
        phase: phase
      },
      on: {
        "move-back": function moveBack($event) {
          return _vm.movePhaseBack(index);
        },
        "move-front": function moveFront($event) {
          return _vm.movePhaseFront(index);
        },
        click: function click($event) {
          return _vm.openColorDialog(phase);
        }
      }
    });
  }), 1), _vm._v(" "), _c("g", {
    attrs: {
      refs: "highlighter"
    }
  }, [_c("rect", {
    attrs: {
      x: _vm.highlighter.x,
      y: _vm.highlighter.y,
      width: _vm.highlighter.width,
      height: _vm.highlighter.height,
      fill: "#CDEB8B",
      "fill-opacity": "0.5"
    }
  })]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "goals"
    }
  }, _vm._l(_vm.roadmap.goals, function (goal, index) {
    return _c("roadmap-goal-component", {
      key: _vm.generateGoalID(index),
      attrs: {
        id: _vm.generateGoalID(index),
        x: _vm.getGoalX(goal, index),
        y: _vm.getGoalY(goal, index),
        width: 150,
        index: index,
        goal: goal.goal
      },
      on: {
        clickGoal: _vm.showGoalDetailsDialog
      }
    });
  }), 1), _vm._v(" "), _c("g", {
    attrs: {
      refs: "phase_lane_controls"
    }
  }, [_c("foreignObject", {
    attrs: {
      x: 1350,
      y: 0,
      width: "250",
      height: "35",
      fill: "black"
    }
  }, [_c("el-row", {
    staticClass: "text-center",
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.addPhaseDialog = true;
      }
    }
  }, [_vm._v("Add Phase")])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.addLaneDialog = true;
      }
    }
  }, [_vm._v("Add Lane")])], 1)], 1)], 1)]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "goal_list"
    }
  }, [_c("g", {
    attrs: {
      refs: "goal_list_container"
    }
  }, [_c("rect", {
    staticStyle: {
      fill: "none"
    },
    attrs: {
      x: 1350,
      y: 40,
      width: "250",
      height: "500",
      stroke: "#00BFBF",
      "stroke-width": "1px"
    }
  }), _vm._v(" "), _c("rect", {
    staticStyle: {
      fill: "#00BFBF"
    },
    attrs: {
      x: 1350,
      y: 40,
      width: "250",
      height: "25"
    }
  }), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "#EEEEEE"
    },
    attrs: {
      x: 1475,
      y: 59,
      "text-anchor": "middle",
      "font-weight": "bold",
      "font-size": "1.15em"
    }
  }, [_vm._v("Product Goals")])]), _vm._v(" "), _vm._l(_vm.strategic_goals_pos, function (goal, index) {
    return _c("g", {
      key: index,
      attrs: {
        refs: "goal_data"
      }
    }, [_c("g", {
      directives: [{
        name: "dragged",
        rawName: "v-dragged",
        value: _vm.onGoalDataDragged,
        expression: "onGoalDataDragged"
      }],
      attrs: {
        id: _vm.generateGoalDataID(index)
      }
    }, [_c("rect", {
      attrs: {
        x: goal.x,
        y: goal.y,
        width: "200",
        height: "30",
        rx: "5",
        fill: "white",
        stroke: "navy",
        "stroke-width": "2"
      }
    }), _vm._v(" "), _c("text", {
      attrs: {
        x: goal.x + 85,
        y: goal.y + 20,
        "text-anchor": "middle",
        "font-size": "0.9em",
        fill: "black"
      }
    }, [_vm._v(_vm._s(_vm.computeGoalDataText(_vm.strategic_goals[index].goal)))]), _vm._v(" "), _c("g", {
      attrs: {
        refs: "details-button"
      },
      on: {
        click: function click($event) {
          return _vm.showGoalDetailsDialog(_vm.strategic_goals[index]);
        }
      }
    }, [_c("line", {
      staticStyle: {
        stroke: "rgb(0,0,0)",
        "stroke-width": "1"
      },
      attrs: {
        x1: goal.x + 170,
        y1: goal.y,
        x2: goal.x + 170,
        y2: goal.y + 30
      }
    }), _vm._v(" "), _c("rect", {
      attrs: {
        x: goal.x + 180,
        y: goal.y + 10,
        width: "10",
        height: "10",
        fill: "white",
        stroke: "black",
        "stroke-width": "1"
      }
    }), _vm._v(" "), _c("line", {
      staticStyle: {
        stroke: "rgb(0,0,0)",
        "stroke-width": "1"
      },
      attrs: {
        x1: goal.x + 180,
        y1: goal.y + 13,
        x2: goal.x + 190,
        y2: goal.y + 13
      }
    })])])]);
  })], 2)])])])], 1), _vm._v(" "), _c("el-card", {
    staticClass: "mt-10"
  }, [_c("div", {
    staticClass: "text-right"
  }, [_c("el-button-group", {
    attrs: {
      size: "mini"
    }
  }, [_c("el-tooltip", {
    attrs: {
      effect: "dark",
      content: "Go To Define Business",
      placement: "top-start"
    }
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      icon: "el-icon-arrow-left"
    },
    on: {
      click: _vm.onClickDefineBusiness
    }
  }, [_vm._v("Previous")])], 1), _vm._v(" "), _c("el-tooltip", {
    attrs: {
      effect: "dark",
      content: "Go To Business Model Canvas",
      placement: "top-start"
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.onClickBusinessModelCanvas
    }
  }, [_vm._v("Next"), _c("i", {
    staticClass: "el-icon-arrow-right"
  })])], 1)], 1)], 1)]), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Goal: " + _vm.activeGoal.goal,
      visible: _vm.goalDetailsDialog,
      width: "50%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.goalDetailsDialog = $event;
      }
    }
  }, [_c("el-row", [_c("el-col", {
    staticClass: "text-right",
    attrs: {
      span: 6
    }
  }, [_c("strong", [_vm._v("Strategy: ")])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 18
    }
  }, [_vm._v("\n        " + _vm._s(_vm.activeGoal.strategy) + "\n      ")])], 1), _vm._v(" "), _c("el-row", {
    staticClass: "mt-10"
  }, [_c("el-col", {
    staticClass: "text-right",
    attrs: {
      span: 6
    }
  }, [_c("strong", [_vm._v("Priority: ")])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 18
    }
  }, [_c("el-tag", {
    attrs: {
      size: "small"
    }
  }, [_vm._v(_vm._s(_vm.activeGoal.priority))])], 1)], 1), _vm._v(" "), _c("el-row", {
    staticClass: "mt-10"
  }, [_c("el-col", {
    staticClass: "text-right",
    attrs: {
      span: 6
    }
  }, [_c("strong", [_vm._v("Actions: ")])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 18
    }
  }, [_c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.activeGoal.actions
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "action",
      label: "Action"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "text-center mt-10"
  }, [_c("el-button", {
    attrs: {
      size: "small"
    },
    on: {
      click: function click($event) {
        _vm.goalDetailsDialog = false;
      }
    }
  }, [_vm._v("Cancel")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Select Background Color",
      visible: _vm.colorDialog,
      width: "30%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.colorDialog = $event;
      }
    }
  }, [_c("el-form", {
    ref: "colorForm",
    staticClass: "mt-5 container text-center",
    attrs: {
      model: _vm.color_form
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Choose Color"
    }
  }, [_c("swatches", {
    attrs: {
      colors: "material-light"
    },
    model: {
      value: _vm.color_form.color,
      callback: function callback($$v) {
        _vm.$set(_vm.color_form, "color", $$v);
      },
      expression: "color_form.color"
    }
  })], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "small"
    },
    on: {
      click: function click($event) {
        _vm.colorDialog = false;
      }
    }
  }, [_vm._v("Cancel")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "small",
      type: "primary"
    },
    on: {
      click: function click($event) {
        _vm.colorDialog = false;
      }
    }
  }, [_vm._v("Update color")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Add new Phase",
      visible: _vm.addPhaseDialog,
      width: "30%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addPhaseDialog = $event;
      }
    }
  }, [_c("el-form", {
    ref: "phaseForm",
    staticClass: "mt-5 container text-center",
    attrs: {
      model: _vm.phase_form,
      "label-width": "150px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Name"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.phase_form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.phase_form, "name", $$v);
      },
      expression: "phase_form.name"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Description"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.phase_form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.phase_form, "description", $$v);
      },
      expression: "phase_form.description"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Background Color"
    }
  }, [_c("swatches", {
    attrs: {
      colors: "material-light"
    },
    model: {
      value: _vm.phase_form.color,
      callback: function callback($$v) {
        _vm.$set(_vm.phase_form, "color", $$v);
      },
      expression: "phase_form.color"
    }
  })], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "small"
    },
    on: {
      click: function click($event) {
        _vm.addPhaseDialog = false;
      }
    }
  }, [_vm._v("Cancel")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "small",
      type: "primary"
    },
    on: {
      click: _vm.addPhase
    }
  }, [_vm._v("Add Phase")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Add new Lane",
      visible: _vm.addLaneDialog,
      width: "30%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addLaneDialog = $event;
      }
    }
  }, [_c("el-form", {
    ref: "phaseForm",
    staticClass: "mt-5 container text-center",
    attrs: {
      model: _vm.lane_form,
      "label-width": "150px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Name"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.lane_form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.lane_form, "name", $$v);
      },
      expression: "lane_form.name"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Description"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.lane_form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.lane_form, "description", $$v);
      },
      expression: "lane_form.description"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Text Color"
    }
  }, [_c("swatches", {
    attrs: {
      colors: "material-light"
    },
    model: {
      value: _vm.lane_form.color,
      callback: function callback($$v) {
        _vm.$set(_vm.lane_form, "color", $$v);
      },
      expression: "lane_form.color"
    }
  })], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "small"
    },
    on: {
      click: function click($event) {
        _vm.addLaneDialog = false;
      }
    }
  }, [_vm._v("Cancel")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "small",
      type: "primary"
    },
    on: {
      click: _vm.addLane
    }
  }, [_vm._v("Add Lane")])], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;