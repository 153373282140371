"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.is4K = is4K;
exports.isHD = isHD;
exports.resolution = resolution;
exports.screenRatio = screenRatio;
function resolution() {
  var realWidth = window.screen.width / window.devicePixelRatio;
  var realHeight = window.screen.height / window.devicePixelRatio;
  return [realWidth, realHeight];
}
function isHD() {
  return resolution()[0] === 1920;
}
function is4K() {
  return resolution()[0] === 3840;
}
function screenRatio() {
  // Compute screen ration compared to a HD screen.
  // So its 2 for 4k and 1 for 720p(rounded)
  var ratio = window.devicePixelRatio;
  return ratio < 1 ? 1 : ratio; // Keep 1 as minimum value for now. (rounded off 0 would cause calculation issues)
}