"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
require("core-js/modules/es6.function.name");
var _taggedTemplateLiteral2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/taggedTemplateLiteral.js"));
require("core-js/modules/es6.array.sort");
var _venture = _interopRequireDefault(require("./venture"));
var _create = _interopRequireDefault(require("./venture/create"));
var _journey = _interopRequireDefault(require("./journey"));
var _create2 = _interopRequireDefault(require("./journey/create"));
var _list = _interopRequireDefault(require("./journey/list"));
var _offering = _interopRequireDefault(require("./offering"));
var _create3 = _interopRequireDefault(require("./offering/create"));
var _templateObject, _templateObject2;
var _default = exports.default = {
  name: 'InnovationHub',
  components: {
    VentureDetails: _venture.default,
    VentureCreate: _create.default,
    JourneyList: _list.default,
    JourneyDetails: _journey.default,
    JourneyCreate: _create2.default,
    OfferingDetails: _offering.default,
    OfferingCreate: _create3.default
  },
  data: function data() {
    return {
      loading: false,
      newVentureDialog: false,
      newJourneyDialog: false,
      newOfferingDialog: false,
      filterDialog: false,
      total_count: 0,
      search_scope: '',
      customers: [],
      selectedIdea: {},
      activeTab: 'journey_ideas',
      createIdeaDialogTitle: {
        title: 'Create',
        description: 'create description'
      },
      ideas: [],
      search_ideas: {
        q: '',
        filters: {
          title: '',
          title_operator: '',
          description: '',
          description_operator: '',
          status: [],
          customer_types: [],
          offerings: [],
          score: ''
        },
        sort: {
          title: 'a-z'
        }
      },
      statuses: [{
        label: 'All Statuses',
        value: 'All Statuses'
      }, {
        label: 'Proposed',
        value: 'Proposed'
      }, {
        label: 'Evaluation',
        value: 'Evaluation'
      }, {
        label: 'Approved',
        value: 'Approved'
      }, {
        label: 'Starting Up',
        value: 'Starting Up'
      }, {
        label: 'Launched',
        value: 'Launched'
      }, {
        label: 'Abandoned',
        value: 'Abandoned'
      }]
    };
  },
  computed: {
    ventureIdea: function ventureIdea() {
      var self = this;
      return this.ideas.filter(function (idea) {
        return idea.title.toLowerCase().indexOf(self.search_ideas.q.toLowerCase()) > -1 || idea.description.toLowerCase().indexOf(self.search_ideas.q.toLowerCase()) > -1;
      });
    },
    journeyIdea: function journeyIdea() {
      var self = this;
      return this.ideas.filter(function (idea) {
        return idea.title.toLowerCase().indexOf(self.search_ideas.q.toLowerCase()) > -1 || idea.description.toLowerCase().indexOf(self.search_ideas.q.toLowerCase()) > -1;
      });
    },
    offeringIdea: function offeringIdea() {
      var self = this;
      return this.ideas.filter(function (idea) {
        return idea.title.toLowerCase().indexOf(self.search_ideas.q.toLowerCase()) > -1 || idea.description.toLowerCase().indexOf(self.search_ideas.q.toLowerCase()) > -1;
      });
    }
  },
  mounted: function mounted() {
    this.retrieveIdeas(1);
    // this.selectedIdea = this.ventureIdea[0]
  },
  methods: {
    toggleSort: function toggleSort() {
      if (this.search_ideas.sort.title === 'a-z') {
        this.search_ideas.sort.title = 'z-a';
      } else {
        this.search_ideas.sort.title = 'a-z';
      }
      this.retrieveIdeas(1);
    },
    showFilterDialog: function showFilterDialog() {
      this.resetSearchFilter();
      this.filterDialog = true;
    },
    closeFilterDialog: function closeFilterDialog() {
      this.resetSearchFilter();
      this.filterDialog = false;
    },
    resetFilterSort: function resetFilterSort() {
      this.resetSearchFilter();
      this.resetSearchSort();
      this.retrieveIdeas(1);
    },
    resetSearchFilter: function resetSearchFilter() {
      this.search_ideas.filters = {
        title: '',
        title_operator: '',
        description: '',
        description_operator: '',
        status: [],
        customer_types: [],
        offerings: [],
        score: ''
      };
    },
    resetSearchSort: function resetSearchSort() {
      this.search_ideas.sort = {
        title: 'a-z'
      };
    },
    filterIdeaList: function filterIdeaList() {
      this.selectedIdea = {};
      this.retrieveIdeas(1);
      this.filterDialog = false;
    },
    retrieveIdeas: function retrieveIdeas(page_number) {
      var _this = this;
      this.loading = true;
      this.ideas = [];
      this.$apollo.query({
        // Query
        query: window._gql(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)([" query BusinessIdeas($page: Page, $where: BusinessIdeasCriteriaExpression)\n        {\n        BusinessIdeas(page: $page, where: $where)\n        {\n          pages\n          total\n          select{\n            id\n            title(orderBy: ", ")\n            description\n            problemstatement\n            solution\n            valueproposition\n            additionalattributes\n            fileobject\n            status\n            score\n            customer_type\n            scope\n            scopename\n            type\n            linkedCoreParties(optional: true){\n              coreParty(optional: true){\n                id\n                businessname\n              }\n            }\n            linkedOfferings(optional: true){\n              offering(optional: true){\n                id\n                title\n                description\n              }\n            }\n            linkedJourneyMaps(optional: true){\n              journeyMap(optional: true){\n                id\n                title\n                description\n              }\n            }\n            linkedCustomerTypes(optional: true){\n              customerType(optional: true){\n                id\n                title\n                description\n              }\n            }\n          }\n        }\n      }"])), this.computeSortQuery()),
        fetchPolicy: 'network-only',
        variables: {
          'page': {
            'limit': 10,
            'start': page_number
          },
          'where': this.computeSearchQuery()
        }
      }).then(function (data) {
        _this.ideas = data.data.BusinessIdeas.select;
        _this.total_count = data.data.BusinessIdeas.total;
        if (_this.ideas.length > 0) {
          if (_this.selected_idea_index !== 0) {
            _this.selectVenture(_this.ideas[_this.selected_idea_index], _this.selected_idea_index);
          } else {
            _this.selectVenture(_this.ideas[0], 0);
          }
        }
        _this.loading = false;
      }).catch(function (error) {
        // Error
        _this.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this.loading = false;
      });
    },
    loadPage: function loadPage(page_number) {
      this.retrieveIdeas(page_number);
    },
    selectVenture: function selectVenture(idea) {
      var _this2 = this;
      var index = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      this.loading = true;
      setTimeout(function () {
        _this2.selectedIdea = idea;
        _this2.loading = false;
      }, 100);
    },
    selectJourney: function selectJourney(idea) {
      var _this3 = this;
      var index = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      this.loading = true;
      setTimeout(function () {
        _this3.selectedIdea = idea;
        _this3.loading = false;
      }, 100);
    },
    selectOffering: function selectOffering(idea) {
      var _this4 = this;
      var index = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      this.loading = true;
      setTimeout(function () {
        _this4.selectedIdea = idea;
        _this4.loading = false;
      }, 100);
    },
    truncateDescription: function truncateDescription(description) {
      return this._.truncate(description, {
        'length': 150,
        'separator': ' '
      });
    },
    handleClick: function handleClick(tab, event) {
      this.selectedIdea = {};
      this.activeTab = tab.name;
      this.search_ideas.q = '';
      this.resetSearchFilter();
      this.resetSearchSort();
      switch (tab.name) {
        case 'new_ventures':
          this.retrieveIdeas(1);
          break;
        case 'journey_ideas':
          // Load customer types
          this.retrieveCustomerTypes(1);
          this.retrieveIdeas(1);
          break;
        case 'offering':
          this.retrieveIdeas(1);
          break;
      }
    },
    openNewVentureDialog: function openNewVentureDialog() {
      this.newVentureDialog = true;
      this.createIdeaDialogTitle.title = 'New Venture';
      this.createIdeaDialogTitle.description = 'A new venture means a new customer type';
    },
    openNewJourneyDialog: function openNewJourneyDialog() {
      this.newJourneyDialog = true;
      this.createIdeaDialogTitle.title = 'Existing Journey Improvement';
      this.createIdeaDialogTitle.description = 'All Journeys are shown below';
    },
    openNewOfferingDialog: function openNewOfferingDialog() {
      this.newOfferingDialog = true;
      this.createIdeaDialogTitle.title = 'Existing Offering Improvement';
      this.createIdeaDialogTitle.description = 'All Offerings are shown below';
    },
    retrieveCustomerTypes: function retrieveCustomerTypes(page_number) {
      var _this5 = this;
      this.loading = true;
      this.customers = [];
      this.$apollo.query({
        // Query
        query: window._gql(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)([" query CustomerTypes($page: Page)\n          {\n          CustomerTypes(page: $page)\n          {\n            pages\n            total\n            select{\n              id\n              title\n              description\n              linkedJourneyMaps(optional: true) {\n                journeyMap(optional: true) {\n                  id\n                  title\n                  description\n                  additionalattributes\n                }\n              }\n            }\n          }\n        }"]))),
        fetchPolicy: 'no-cache',
        variables: {
          'page': {
            'limit': 20,
            'start': page_number
          }
        }
      }).then(function (data) {
        _this5.customers = data.data.CustomerTypes.select;
        _this5.loading = false;
      }).catch(function (error) {
        // Error
        _this5.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this5.loading = false;
      });
    },
    createIdea: function createIdea(idea) {
      var _this6 = this;
      this.ideas.push(idea);
      this.loading = true;
      setTimeout(function () {
        _this6.selectedIdea = idea;
        _this6.loading = false;
      }, 100);
      this.newVentureDialog = false;
      this.newJourneyDialog = false;
      this.newOfferingDialog = false;
    },
    updateCreateIdeaDialogTitle: function updateCreateIdeaDialogTitle(title, description) {
      this.createIdeaDialogTitle.title = title;
      this.createIdeaDialogTitle.description = description;
    },
    updateIdea: function updateIdea(idea) {
      this.selectedIdea = idea;
    },
    deleteIdea: function deleteIdea(idea_id) {
      for (var i = 0; i < this.ideas.length; i++) {
        if (idea_id === this.ideas[i].id) {
          this.ideas.splice(i, 1);
          this.selectedIdea = {};
          break;
        }
      }
    },
    removeSearchFilterTag: function removeSearchFilterTag(type) {
      var index = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      switch (type) {
        case 'title':
          this.search_ideas.filters.title = '';
          this.search_ideas.filters.title_operator = '';
          break;
        case 'description':
          this.search_ideas.filters.description = '';
          this.search_ideas.filters.description_operator = '';
          break;
        case 'status':
          this.search_ideas.filters.status.splice(index, 1);
          break;
        case 'customer_types':
          this.search_ideas.filters.customer_types.splice(index, 1);
          break;
        case 'offerings':
          this.search_ideas.filters.offerings.splice(index, 1);
          break;
        case 'score':
          this.search_ideas.filters.score = '';
          break;
      }
      this.retrieveIdeas(1);
    },
    removeSearchSortTag: function removeSearchSortTag(type) {
      switch (type) {
        case 'title':
          this.search_ideas.sort.title = 'a-z';
          break;
      }
      this.retrieveIdeas(1);
    },
    computeSortQuery: function computeSortQuery() {
      if (this.search_ideas.sort.title === 'a-z') {
        return 'ASC';
      } else {
        return 'DESC';
      }
    },
    computeSearchQuery: function computeSearchQuery() {
      var query = {};
      // quick and dirty method to generate query.
      // TODO make this code intelligent
      switch (this.activeTab) {
        case 'new_ventures':
          query.type = {
            'EQ': 'venture'
          };
          break;
        case 'journey_ideas':
          query.type = {
            'EQ': 'journey'
          };
          break;
        case 'offering':
          query.type = {
            'EQ': 'offering'
          };
          break;
      }
      if (this.search_ideas.q !== '') {
        query.title = {
          'LIKE_': this.search_ideas.q
        };
      }
      if (this.search_ideas.filters.title !== '') {
        switch (this.search_ideas.filters.title_operator) {
          case 'EXACT':
            query.title = {
              'EQ_': this.search_ideas.filters.title
            };
            break;
          case 'LIKE':
            query.title = {
              'LIKE_': this.search_ideas.filters.title
            };
            break;
          case 'EMPTY':
            query.title = {
              'IS_NULL': this.search_ideas.filters.title
            };
            break;
          case 'NOT EMPTY':
            query.title = {
              'NOT_NULL': this.search_ideas.filters.title
            };
            break;
          case 'STARTS':
            query.title = {
              'STARTS_': this.search_ideas.filters.title
            };
            break;
          case 'ENDS':
            query.title = {
              'ENDS_': this.search_ideas.filters.title
            };
            break;
          default:
            query.title = {
              'LIKE_': this.search_ideas.filters.title
            };
            break;
        }
      }
      if (this.search_ideas.filters.description !== '') {
        switch (this.search_ideas.filters.description_operator) {
          case 'EXACT':
            query.description = {
              'EQ_': this.search_ideas.filters.description
            };
            break;
          case 'LIKE':
            query.description = {
              'LIKE_': this.search_ideas.filters.description
            };
            break;
          case 'EMPTY':
            query.description = {
              'IS_NULL': this.search_ideas.filters.description
            };
            break;
          case 'NOT EMPTY':
            query.description = {
              'NOT_NULL': this.search_ideas.filters.description
            };
            break;
          case 'STARTS':
            query.description = {
              'STARTS_': this.search_ideas.filters.description
            };
            break;
          case 'ENDS':
            query.description = {
              'ENDS_': this.search_ideas.filters.description
            };
            break;
          default:
            query.description = {
              'LIKE_': this.search_ideas.filters.description
            };
            break;
        }
      }
      if (this.search_ideas.filters.status.length > 0 && !this.search_ideas.filters.status.includes('All Statuses')) {
        query.status = {
          'IN': this.search_ideas.filters.status
        };
      }
      if (this.search_ideas.filters.offerings.length > 0) {
        query.offerings = {
          'IN': this.search_ideas.filters.offerings
        };
      }
      if (this.search_ideas.filters.customer_types.length > 0) {
        query.customer_types = {
          'IN': this.search_ideas.filters.customer_types
        };
      }
      if (this.search_ideas.filters.score !== '') {
        query.score = {
          'EQ': this.search_ideas.filters.score
        };
      }
      return query;
    }
  }
};