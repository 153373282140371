"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }]
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      offset: 8,
      span: 8
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "Search Users",
      size: "mini"
    },
    model: {
      value: _vm.user_name,
      callback: function callback($$v) {
        _vm.user_name = $$v;
      },
      expression: "user_name"
    }
  }, [_c("i", {
    staticClass: "el-input__icon el-icon-search",
    attrs: {
      slot: "prefix"
    },
    slot: "prefix"
  })])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("div", {
    staticClass: "text-right"
  }, [_c("el-checkbox", {
    attrs: {
      label: "Show Deleted Users",
      border: "",
      size: "mini"
    },
    model: {
      value: _vm.showDeletedUsers,
      callback: function callback($$v) {
        _vm.showDeletedUsers = $$v;
      },
      expression: "showDeletedUsers"
    }
  }), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "success",
      size: "mini",
      icon: "el-icon-plus"
    },
    on: {
      click: _vm.openCreateUserModal
    }
  }, [_vm._v("Add User")])], 1)])], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_vm.filteredUsers.length > 0 ? _c("el-table", {
    staticStyle: {
      width: "100%",
      "font-size": "0.9em",
      "text-align": "right"
    },
    attrs: {
      data: _vm.filteredUsers,
      stripe: "",
      size: "mini",
      "row-class-name": _vm.highlightDeletedUsers
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "User",
      width: "190px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("span", [_vm._v(_vm._s(scope.row.firstName) + " " + _vm._s(scope.row.lastName))])];
      }
    }], null, false, 3928732667)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Email",
      width: "190px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("small", [_c("i", [_vm._v(_vm._s(scope.row.emailAddress))])])];
      }
    }], null, false, 3431446097)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Activity",
      align: "left",
      width: "155px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v("\n            " + _vm._s(_vm.computeDate(scope.row.modifiedAt)) + " @ " + _vm._s(_vm.computeTime(scope.row.modifiedAt)) + "\n          ")];
      }
    }], null, false, 641468753)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Created",
      align: "left",
      width: "155px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v("\n            " + _vm._s(_vm.computeDate(scope.row.createdAt)) + " @ " + _vm._s(_vm.computeTime(scope.row.createdAt)) + "\n          ")];
      }
    }], null, false, 2228599665)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "address",
      label: "Access",
      align: "left",
      width: "110px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", [scope.row.userRole && scope.row.userRole.length > 0 ? _c("span", _vm._l(scope.row.userRole, function (role, index) {
          return _c("el-tag", {
            key: index,
            staticStyle: {
              "font-size": "0.9em"
            },
            attrs: {
              size: "mini"
            }
          }, [_vm._v(_vm._s(role.role.title))]);
        }), 1) : _vm._e(), _vm._v(" "), !_vm.isCurrentUser(scope.row.id) && _vm.isAdmin() ? _c("el-button", {
          staticClass: "el-icon-edit",
          staticStyle: {
            border: "none"
          },
          attrs: {
            title: "Access",
            size: "mini",
            circle: "",
            type: "plain"
          },
          on: {
            click: function click($event) {
              return _vm.openLinkSecurityDialog(scope.row);
            }
          }
        }) : _vm._e()], 1)];
      }
    }], null, false, 1644499560)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "address",
      label: "Team Role",
      align: "left",
      width: "140px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-tag", {
          staticStyle: {
            "font-size": "0.9em"
          },
          attrs: {
            size: "mini"
          }
        }, [_vm._v(_vm._s(scope.row.businessRole))]), _vm._v(" "), _c("el-button", {
          staticClass: "el-icon-edit",
          staticStyle: {
            border: "none"
          },
          attrs: {
            title: "Roles",
            size: "mini",
            type: "plain",
            circle: ""
          },
          on: {
            click: function click($event) {
              return _vm.openLinkRoleDialog(scope.row);
            }
          }
        })];
      }
    }], null, false, 1794022049)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "modules",
      label: "Modules",
      align: "left",
      width: "auto"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.additionalAttributes && scope.row.additionalAttributes.modules.length > 0 ? _vm._l(scope.row.additionalAttributes.modules, function (module, module_index) {
          return _c("el-tag", {
            key: module_index,
            staticClass: "mr-5 ml-5",
            staticStyle: {
              "font-size": "0.9em"
            },
            attrs: {
              size: "mini"
            }
          }, [_vm._v("\n                " + _vm._s(module))]);
        }) : _vm._e(), _vm._v(" "), _c("el-button", {
          staticClass: "el-icon-edit",
          staticStyle: {
            border: "none"
          },
          attrs: {
            title: "Modules",
            size: "mini",
            circle: "",
            type: "plain"
          },
          on: {
            click: function click($event) {
              return _vm.openLinkModuleDialog(scope.row, scope.$index);
            }
          }
        })];
      }
    }], null, false, 1273620526)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Actions",
      align: "right",
      width: "80px"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [!scope.row.isDeleted ? [!_vm.isCurrentUser(scope.row.id) ? _c("el-button", {
          attrs: {
            icon: "el-icon-delete",
            circle: "",
            title: "Delete",
            size: "mini",
            type: "danger"
          },
          on: {
            click: function click($event) {
              return _vm.deleteUser(scope.row);
            }
          }
        }) : _vm._e(), _vm._v(" "), _vm.isCurrentUser(scope.row.id) ? _c("el-button", {
          attrs: {
            icon: "el-icon-delete",
            circle: "",
            title: "Delete",
            size: "mini",
            disabled: ""
          }
        }) : _vm._e()] : _vm._e()];
      }
    }], null, false, 1632822264)
  })], 1) : _c("div", {
    staticClass: "card-left-side card-left-side-ui-design",
    staticStyle: {
      padding: "0"
    }
  }, [_c("div", {
    staticClass: "text-center"
  }, [_c("span", [_vm._v("No Users Found")])])])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Create User",
      visible: _vm.createUserDialog,
      width: "60%",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.createUserDialog = $event;
      }
    }
  }, [_c("create-user-component", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    on: {
      "create-user": _vm.createUser,
      cancel: function cancel($event) {
        _vm.createUserDialog = false;
      }
    }
  })], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Link Security",
      visible: _vm.addSecurityDialog,
      width: "35%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addSecurityDialog = $event;
      }
    }
  }, [_c("el-form", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      model: _vm.link_security_form,
      "label-width": "150px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Roles",
      prop: "roles"
    }
  }, [_vm.roles.length > 0 ? _c("el-select", {
    attrs: {
      placeholder: "Select Roles",
      multiple: "",
      size: "mini"
    },
    model: {
      value: _vm.link_security_form.roles,
      callback: function callback($$v) {
        _vm.$set(_vm.link_security_form, "roles", $$v);
      },
      expression: "link_security_form.roles"
    }
  }, _vm._l(_vm.roles, function (role) {
    return _c("el-option", {
      key: role.id,
      attrs: {
        label: role.title,
        value: role.id
      }
    }, [_c("span", {
      staticStyle: {
        float: "left"
      }
    }, [_vm._v(_vm._s(role.title))])]);
  }), 1) : _c("div", {
    staticClass: "text-center"
  }, [_c("span", [_vm._v("No Roles Found")])])], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.addSecurityDialog = false;
      }
    }
  }, [_vm._v("Cancel")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.linkSecurityToUser();
      }
    }
  }, [_vm._v("Update Security")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Link Team Role",
      visible: _vm.addTeamRoleDialog,
      width: "35%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addTeamRoleDialog = $event;
      }
    }
  }, [_c("el-form", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      model: _vm.link_role_form,
      "label-width": "150px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Roles",
      prop: "roles"
    }
  }, [_vm.team_roles.length > 0 ? _c("el-select", {
    attrs: {
      placeholder: "Select Roles",
      size: "mini"
    },
    model: {
      value: _vm.link_role_form.team_role,
      callback: function callback($$v) {
        _vm.$set(_vm.link_role_form, "team_role", $$v);
      },
      expression: "link_role_form.team_role"
    }
  }, _vm._l(_vm.team_roles, function (role) {
    return _c("el-option", {
      key: role.value,
      attrs: {
        label: role.label,
        value: role.value
      }
    }, [_c("span", {
      staticStyle: {
        float: "left"
      }
    }, [_vm._v(_vm._s(role.label))])]);
  }), 1) : _c("div", {
    staticClass: "text-center"
  }, [_c("span", [_vm._v("No Roles Found")])])], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.addTeamRoleDialog = false;
      }
    }
  }, [_vm._v("Cancel")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.linkRoleToUser();
      }
    }
  }, [_vm._v("Update Role")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Link Modules",
      visible: _vm.addModuleDialog,
      width: "35%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addModuleDialog = $event;
      }
    }
  }, [_c("el-form", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      model: _vm.link_modules_form,
      "label-width": "150px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Modules",
      prop: "roles"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Select Modules",
      size: "mini",
      multiple: ""
    },
    model: {
      value: _vm.link_modules_form.modules,
      callback: function callback($$v) {
        _vm.$set(_vm.link_modules_form, "modules", $$v);
      },
      expression: "link_modules_form.modules"
    }
  }, [_c("el-option", {
    attrs: {
      label: "Innovation Hub",
      value: "Innovation Hub"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Business Strategy",
      value: "Business Strategy"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Customer Needs",
      value: "Customer Needs"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Offering",
      value: "Offering"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Advanced Delivery",
      value: "Advanced Delivery"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Portfolio Manager",
      value: "Portfolio Manager"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Risk Management",
      value: "Risk Managment"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Report",
      value: "Report"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Administration",
      value: "Administration"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.addModuleDialog = false;
      }
    }
  }, [_vm._v("Cancel")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.linkModuleToUser();
      }
    }
  }, [_vm._v("Update Module")])], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;