"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "signup-container"
  }, [_vm.user_type === "new" ? _c("el-row", [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-form", {
    ref: "loginForm",
    staticClass: "signup-form",
    attrs: {
      autocomplete: "on",
      "label-position": "left"
    }
  }, [_c("div", {
    staticClass: "signup-form-header"
  }, [_c("div", {
    staticClass: "title",
    staticStyle: {
      "font-size": "1.5em",
      display: "inline-block",
      "margin-bottom": "20pt",
      width: "100%"
    }
  }, [_c("img", {
    staticStyle: {
      "vertical-align": "middle"
    },
    attrs: {
      src: require("../../assets/img/prodiggi.png"),
      height: "53",
      width: "55"
    }
  }), _vm._v(" "), _c("span", {
    staticStyle: {
      right: "25pt",
      position: "relative",
      "background-color": "rgba(255,255,255,0.7)",
      "border-radius": "4pt",
      display: "inline-block",
      "font-weight": "600",
      "vertical-align": "middle"
    }
  }, [_vm._v("Prodiggi ")]), _vm._v(" "), _c("span", {
    staticStyle: {
      display: "inline-block",
      right: "25pt",
      position: "relative",
      "vertical-align": "middle"
    }
  }, [_vm._v("Signup")]), _vm._v(" "), _c("div", {
    staticStyle: {
      top: "90px",
      left: "80px",
      position: "absolute",
      "z-index": "999"
    }
  }, [_c("el-tag", {
    attrs: {
      type: "warning"
    }
  }, [_vm._v(_vm._s(_vm.activeEnv) + " ENVIRONMENT")])], 1), _vm._v(" "), _c("div", {
    staticClass: "onboarding-steps"
  }, [_vm.invite_type === "user" ? _c("div", {
    staticClass: "text-center"
  }, [_c("span", [_vm._v("Please enter your details to get started...")])]) : _vm._e(), _vm._v(" "), _vm.invite_type === "party" ? _c("el-steps", {
    staticClass: "business-onboarding",
    attrs: {
      direction: "horizontal",
      active: _vm.activeStep,
      "finish-status": "success",
      "align-center": ""
    }
  }, [_c("el-step", {
    attrs: {
      icon: "el-icon-user"
    }
  }, [_c("div", {
    attrs: {
      slot: "title"
    },
    on: {
      click: function click($event) {
        return _vm.goToStep(0);
      }
    },
    slot: "title"
  }, [_vm._v("\n                    Your Details\n                  ")])]), _vm._v(" "), _c("el-step", {
    attrs: {
      icon: "el-icon-office-building"
    }
  }, [_c("div", {
    attrs: {
      slot: "title"
    },
    on: {
      click: function click($event) {
        return _vm.goToStep(1);
      }
    },
    slot: "title"
  }, [_vm._v("\n                    Company Details\n                  ")])]), _vm._v(" "), _c("el-step", {
    attrs: {
      icon: "el-icon-message"
    }
  }, [_c("div", {
    attrs: {
      slot: "title"
    },
    on: {
      click: function click($event) {
        return _vm.goToStep(2);
      }
    },
    slot: "title"
  }, [_vm._v("\n                    Invite Users\n                  ")])])], 1) : _vm._e()], 1)])]), _vm._v(" "), _c("div", {
    staticClass: "signup-form-body"
  }, [_c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.activeStep === 0 ? _c("div", [_c("el-form", {
    ref: "userSignUpForm",
    staticClass: "login100-form",
    attrs: {
      rules: _vm.userSignUpRules,
      model: _vm.onboarding.user
    }
  }, [_c("el-form-item", {
    staticClass: "wrap-input50 validate-input",
    attrs: {
      prop: "firstname",
      "data-validate": "First Name is required",
      label: "First Name"
    }
  }, [_c("el-input", {
    attrs: {
      id: "test_onboarding_new_company_first_name",
      type: "text",
      name: "firstname",
      placeholder: "First Name..."
    },
    model: {
      value: _vm.onboarding.user.firstname,
      callback: function callback($$v) {
        _vm.$set(_vm.onboarding.user, "firstname", $$v);
      },
      expression: "onboarding.user.firstname"
    }
  }), _vm._v(" "), _c("span", {
    staticClass: "focus-input100"
  })], 1), _vm._v(" "), _c("el-form-item", {
    staticClass: "wrap-input50 validate-input",
    attrs: {
      prop: "lastname",
      "data-validate": "Last Name is required",
      label: "Last Name"
    }
  }, [_c("el-input", {
    attrs: {
      id: "test_onboarding_new_company_last_name",
      type: "text",
      name: "lastname",
      placeholder: "Last Name..."
    },
    model: {
      value: _vm.onboarding.user.lastname,
      callback: function callback($$v) {
        _vm.$set(_vm.onboarding.user, "lastname", $$v);
      },
      expression: "onboarding.user.lastname"
    }
  }), _vm._v(" "), _c("span", {
    staticClass: "focus-input100"
  })], 1), _vm._v(" "), _c("el-form-item", {
    staticClass: "wrap-input33 validate-input",
    attrs: {
      prop: "businessRole",
      "data-validate": "Job Type is required",
      label: "Job Type"
    }
  }, [_c("el-select", {
    attrs: {
      id: "business_role",
      placeholder: "Select Job Type",
      required: "",
      size: "mini"
    },
    model: {
      value: _vm.onboarding.user.businessRole,
      callback: function callback($$v) {
        _vm.$set(_vm.onboarding.user, "businessRole", $$v);
      },
      expression: "onboarding.user.businessRole"
    }
  }, _vm._l(_vm.roles, function (item) {
    return _c("el-option", {
      key: item.value,
      attrs: {
        label: item.label,
        value: item.value
      }
    });
  }), 1), _vm._v(" "), _c("span", {
    staticClass: "focus-input100"
  })], 1), _vm._v(" "), _c("el-form-item", {
    staticClass: "wrap-input33 validate-input",
    attrs: {
      prop: "mobileNumber",
      "data-validate": "Mobile Number",
      label: "Mobile Number"
    }
  }, [_c("el-input", {
    attrs: {
      id: "test_onboarding_new_company_mobile_number",
      type: "text",
      name: "mobile_number",
      placeholder: "Mobile..."
    },
    model: {
      value: _vm.onboarding.user.mobileNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.onboarding.user, "mobileNumber", $$v);
      },
      expression: "onboarding.user.mobileNumber"
    }
  }), _vm._v(" "), _c("span", {
    staticClass: "focus-input100"
  })], 1), _vm._v(" "), _c("el-form-item", {
    staticClass: "wrap-input33 validate-input",
    attrs: {
      prop: "emailAddress",
      "data-validate": "Email is required",
      label: "Email"
    }
  }, [_c("el-input", {
    attrs: {
      id: "test_onboarding_new_company_email_address",
      type: "email",
      name: "email",
      placeholder: "Email..."
    },
    model: {
      value: _vm.onboarding.user.emailAddress,
      callback: function callback($$v) {
        _vm.$set(_vm.onboarding.user, "emailAddress", $$v);
      },
      expression: "onboarding.user.emailAddress"
    }
  }), _vm._v(" "), _c("span", {
    staticClass: "focus-input100"
  })], 1), _vm._v(" "), _c("el-popover", {
    ref: "popover",
    attrs: {
      placement: "bottom",
      title: "",
      width: "260",
      trigger: "focus"
    },
    model: {
      value: _vm.passwordRulesPopover,
      callback: function callback($$v) {
        _vm.passwordRulesPopover = $$v;
      },
      expression: "passwordRulesPopover"
    }
  }, [_c("el-table", {
    attrs: {
      data: _vm.passwordRules,
      size: "mini",
      stripe: true,
      "show-header": false
    }
  }, [_c("el-table-column", {
    attrs: {
      width: "40"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.valid ? _c("el-button", {
          staticClass: "button-micro",
          attrs: {
            type: "success",
            icon: "el-icon-check",
            circle: "",
            size: "mini"
          }
        }) : _c("el-button", {
          staticClass: "button-micro",
          attrs: {
            type: "danger",
            icon: "el-icon-close",
            circle: "",
            size: "mini"
          }
        })];
      }
    }], null, false, 1454765410)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      width: "190"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("strong", [_vm._v(_vm._s(scope.row.rule))])];
      }
    }], null, false, 272241527)
  })], 1)], 1), _vm._v(" "), _c("el-form-item", {
    staticClass: "wrap-input50 validate-input",
    attrs: {
      prop: "password",
      "data-validate": "Password is required",
      label: "Password"
    }
  }, [_c("el-input", {
    directives: [{
      name: "popover",
      rawName: "v-popover:popover",
      arg: "popover"
    }],
    attrs: {
      id: "test_onboarding_new_company_password",
      type: "password",
      name: "password",
      placeholder: "Password..."
    },
    model: {
      value: _vm.onboarding.user.password,
      callback: function callback($$v) {
        _vm.$set(_vm.onboarding.user, "password", $$v);
      },
      expression: "onboarding.user.password"
    }
  }), _vm._v(" "), _c("span", {
    staticClass: "focus-input100"
  })], 1), _vm._v(" "), _c("el-form-item", {
    staticClass: "wrap-input50 validate-input",
    attrs: {
      prop: "confirmPassword",
      "data-validate": "Confirm Password is required",
      label: "Confirm Password"
    }
  }, [_c("el-input", {
    attrs: {
      id: "test_onboarding_new_company_confirm_password",
      type: "password",
      name: "password",
      placeholder: "Confirm Password..."
    },
    model: {
      value: _vm.onboarding.user.confirmPassword,
      callback: function callback($$v) {
        _vm.$set(_vm.onboarding.user, "confirmPassword", $$v);
      },
      expression: "onboarding.user.confirmPassword"
    }
  }), _vm._v(" "), _c("span", {
    staticClass: "focus-input100"
  })], 1)], 1), _vm._v(" "), _c("el-row", {
    staticStyle: {
      top: "25px"
    },
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    staticClass: "text-left",
    attrs: {
      span: 14
    }
  }, [_c("el-button", {
    attrs: {
      id: "test_onboarding_new_company_cancel",
      type: "secondary",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.goToLoginPage();
      }
    }
  }, [_vm._v("\n                    Cancel\n                  ")])], 1), _vm._v(" "), _vm.invite_type === "party" ? _c("el-col", {
    staticClass: "text-right",
    attrs: {
      span: 10
    }
  }, [_c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "hov1 mr-10 test_onboarding_new_company_submit",
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.processUserSignup();
      }
    }
  }, [_c("i", [_vm._v("Next")]), _vm._v(" "), _c("i", {
    staticClass: "el-icon-right"
  })])], 1) : _vm._e(), _vm._v(" "), _vm.invite_type === "user" ? _c("el-col", {
    staticClass: "text-right",
    attrs: {
      span: 10
    }
  }, [_c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "test_onboarding_new_company_submit",
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.processUserSignup();
      }
    }
  }, [_vm._v("\n                    Create User\n                  ")])], 1) : _vm._e()], 1)], 1) : _vm._e()]), _vm._v(" "), _c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.activeStep === 1 ? _c("div", [_c("el-form", {
    ref: "organisationSignUpForm",
    staticClass: "login100-form",
    attrs: {
      rules: _vm.organisationSignUpRules,
      model: _vm.onboarding.organisation
    }
  }, [[_c("el-form-item", {
    staticClass: "wrap-input50 validate-input",
    attrs: {
      prop: "businessName",
      "data-validate": "Business Name is required",
      label: "Business Name"
    }
  }, [_c("el-input", {
    attrs: {
      id: "test_onboarding_new_company_business_name",
      type: "text",
      name: "businessname",
      placeholder: "Business Name..."
    },
    model: {
      value: _vm.onboarding.organisation.businessName,
      callback: function callback($$v) {
        _vm.$set(_vm.onboarding.organisation, "businessName", $$v);
      },
      expression: "onboarding.organisation.businessName"
    }
  }), _vm._v(" "), _c("span", {
    staticClass: "focus-input100"
  })], 1), _vm._v(" "), _c("el-form-item", {
    staticClass: "wrap-input50 validate-input",
    attrs: {
      prop: "primaryPartyContactId",
      "data-validate": "Business Contact Email",
      label: "Billing account email"
    }
  }, [_c("el-input", {
    attrs: {
      id: "test_onboarding_new_company_primary_contact_id",
      type: "email",
      name: "Contact Email",
      placeholder: "Billing account Email..."
    },
    model: {
      value: _vm.onboarding.organisation.primaryPartyContactId,
      callback: function callback($$v) {
        _vm.$set(_vm.onboarding.organisation, "primaryPartyContactId", $$v);
      },
      expression: "onboarding.organisation.primaryPartyContactId"
    }
  }), _vm._v(" "), _c("span", {
    staticClass: "focus-input100"
  })], 1), _vm._v(" "), _c("el-form-item", {
    staticClass: "wrap-input33 validate-input",
    attrs: {
      prop: "address",
      label: "Address"
    }
  }, [_c("el-input", {
    attrs: {
      id: "test_onboarding_new_company_address",
      type: "text",
      name: "address",
      placeholder: "Address..."
    },
    model: {
      value: _vm.onboarding.organisation.address,
      callback: function callback($$v) {
        _vm.$set(_vm.onboarding.organisation, "address", $$v);
      },
      expression: "onboarding.organisation.address"
    }
  }), _vm._v(" "), _c("span", {
    staticClass: "focus-input100"
  })], 1), _vm._v(" "), _c("el-form-item", {
    staticClass: "wrap-input33 validate-input",
    attrs: {
      prop: "city",
      label: "City"
    }
  }, [_c("el-input", {
    attrs: {
      id: "test_onboarding_new_company_city",
      type: "text",
      name: "city",
      placeholder: "City..."
    },
    model: {
      value: _vm.onboarding.organisation.city,
      callback: function callback($$v) {
        _vm.$set(_vm.onboarding.organisation, "city", $$v);
      },
      expression: "onboarding.organisation.city"
    }
  }), _vm._v(" "), _c("span", {
    staticClass: "focus-input100"
  })], 1), _vm._v(" "), _c("el-form-item", {
    staticClass: "wrap-input33 validate-input",
    attrs: {
      prop: "state",
      label: "State"
    }
  }, [_c("el-input", {
    attrs: {
      id: "test_onboarding_new_company_state",
      type: "text",
      name: "state",
      placeholder: "State..."
    },
    model: {
      value: _vm.onboarding.organisation.state,
      callback: function callback($$v) {
        _vm.$set(_vm.onboarding.organisation, "state", $$v);
      },
      expression: "onboarding.organisation.state"
    }
  }), _vm._v(" "), _c("span", {
    staticClass: "focus-input100"
  })], 1), _vm._v(" "), _c("el-form-item", {
    staticClass: "wrap-input50 validate-input",
    attrs: {
      prop: "country",
      label: "Country"
    }
  }, [_c("el-input", {
    attrs: {
      id: "test_onboarding_new_company_country",
      type: "text",
      name: "country",
      placeholder: "Country..."
    },
    model: {
      value: _vm.onboarding.organisation.country,
      callback: function callback($$v) {
        _vm.$set(_vm.onboarding.organisation, "country", $$v);
      },
      expression: "onboarding.organisation.country"
    }
  }), _vm._v(" "), _c("span", {
    staticClass: "focus-input100"
  })], 1), _vm._v(" "), _c("el-form-item", {
    staticClass: "wrap-input50 validate-input",
    attrs: {
      prop: "postCode",
      label: "Postcode"
    }
  }, [_c("el-input", {
    attrs: {
      id: "test_onboarding_new_company_postcode",
      type: "text",
      name: "postcode",
      placeholder: "Postcode..."
    },
    model: {
      value: _vm.onboarding.organisation.postCode,
      callback: function callback($$v) {
        _vm.$set(_vm.onboarding.organisation, "postCode", $$v);
      },
      expression: "onboarding.organisation.postCode"
    }
  }), _vm._v(" "), _c("span", {
    staticClass: "focus-input100"
  })], 1)]], 2), _vm._v(" "), _c("el-row", {
    staticStyle: {
      top: "25px"
    },
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    staticClass: "text-left",
    attrs: {
      span: 12
    }
  }, [_c("el-button", {
    staticClass: "hov1 left",
    attrs: {
      id: "test_onboarding_new_company_step1_back",
      size: "mini",
      type: "secondary",
      icon: "el-icon-arrow-back"
    },
    on: {
      click: function click($event) {
        _vm.activeStep = 0;
      }
    }
  }, [_vm._v("\n                    Back\n                  ")])], 1), _vm._v(" "), _c("el-col", {
    staticClass: "text-right",
    attrs: {
      span: 12
    }
  }, [_c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "hov1",
    attrs: {
      id: "test_onboarding_new_company_step1_submit",
      size: "mini",
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.processOrganizationUpdate();
      }
    }
  }, [_c("i", [_vm._v("Next")]), _vm._v(" "), _c("i", {
    staticClass: "el-icon-right"
  })])], 1)], 1)], 1) : _vm._e()]), _vm._v(" "), _c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.activeStep === 2 ? _c("div", [_c("div", {
    staticClass: "invite-container"
  }, [_vm.invites.length > 0 ? _c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.invites,
      stripe: "",
      size: "mini"
    }
  }, [_c("el-table-column", {
    attrs: {
      width: "140"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-input", {
          attrs: {
            size: "mini",
            placeholder: "First Name"
          },
          model: {
            value: scope.row.first_name,
            callback: function callback($$v) {
              _vm.$set(scope.row, "first_name", $$v);
            },
            expression: "scope.row.first_name"
          }
        })];
      }
    }], null, false, 3368403535)
  }, [_c("template", {
    slot: "header"
  }, [_c("span", [_vm._v("First Name")]), _c("span", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v("*")])])], 2), _vm._v(" "), _c("el-table-column", {
    attrs: {
      width: "140"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-input", {
          attrs: {
            size: "mini",
            placeholder: "Last Name"
          },
          model: {
            value: scope.row.last_name,
            callback: function callback($$v) {
              _vm.$set(scope.row, "last_name", $$v);
            },
            expression: "scope.row.last_name"
          }
        })];
      }
    }], null, false, 4056594927)
  }, [_c("template", {
    slot: "header"
  }, [_c("span", [_vm._v("Last Name")]), _c("span", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v("*")])])], 2), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "email",
      width: "250"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-input", {
          attrs: {
            size: "mini",
            placeholder: "Email"
          },
          model: {
            value: scope.row.email,
            callback: function callback($$v) {
              _vm.$set(scope.row, "email", $$v);
            },
            expression: "scope.row.email"
          }
        })];
      }
    }], null, false, 129003472)
  }, [_c("template", {
    slot: "header"
  }, [_c("span", [_vm._v("Email")]), _c("span", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v("*")])])], 2), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "#",
      width: "245"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [!scope.row.invite_sent ? _c("el-button", {
          directives: [{
            name: "loading",
            rawName: "v-loading",
            value: scope.row.loading,
            expression: "scope.row.loading"
          }],
          attrs: {
            size: "mini",
            type: "primary",
            icon: "el-icon-message"
          },
          on: {
            click: function click($event) {
              return _vm.inviteUser(scope.$index);
            }
          }
        }, [_vm._v("Invite")]) : _c("el-button", {
          directives: [{
            name: "loading",
            rawName: "v-loading",
            value: scope.row.loading,
            expression: "scope.row.loading"
          }],
          attrs: {
            size: "mini",
            type: "info",
            icon: "el-icon-message"
          },
          on: {
            click: function click($event) {
              return _vm.resendInvite(scope.$index);
            }
          }
        }, [_vm._v("Resend")]), _vm._v(" "), _c("el-button", {
          directives: [{
            name: "loading",
            rawName: "v-loading",
            value: scope.row.loading,
            expression: "scope.row.loading"
          }],
          attrs: {
            size: "mini",
            type: "danger",
            icon: "el-icon-delete"
          },
          on: {
            click: function click($event) {
              return _vm.deleteInvite(scope.$index);
            }
          }
        }, [_vm._v("Delete")])];
      }
    }], null, false, 3989146252)
  })], 1) : _c("div", {
    staticClass: "text-center"
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "primary",
      icon: "el-icon-plus"
    },
    on: {
      click: function click($event) {
        return _vm.addNewInvite();
      }
    }
  }, [_vm._v("Add user")])], 1)], 1), _vm._v(" "), _c("el-row", {
    staticClass: "mt-20",
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    staticClass: "text-left",
    attrs: {
      span: 6
    }
  }, [_c("el-button", {
    staticClass: "hov1 left",
    attrs: {
      size: "mini",
      type: "secondary",
      icon: "el-icon-back"
    },
    on: {
      click: function click($event) {
        _vm.activeStep = 1;
      }
    }
  }, [_vm._v("\n                    Back\n                  ")])], 1), _vm._v(" "), _c("el-col", {
    staticClass: "text-right",
    attrs: {
      span: 18
    }
  }, [_vm.invites.length > 0 ? _c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.new_invite_loading,
      expression: "new_invite_loading"
    }],
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.addNewInvite();
      }
    }
  }, [_vm._v("\n                    Invite More Users\n                  ")]) : _vm._e(), _vm._v(" "), _c("el-button", {
    attrs: {
      id: "test_onboarding_complete_signup",
      type: "primary",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.displayEmailInfo();
      }
    }
  }, [_vm._v("Complete Signup")])], 1)], 1)], 1) : _vm._e()])], 1)])], 1)], 1) : _vm._e(), _vm._v(" "), _vm.user_type === "existing" ? _c("el-row", [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("div", {
    staticClass: "signup-form-compact"
  }, [_c("div", {
    staticClass: "signup-form-header"
  }, [_c("div", {
    staticClass: "title",
    staticStyle: {
      "font-size": "1.5em",
      display: "inline-block",
      "margin-bottom": "20pt"
    }
  }, [_c("img", {
    staticStyle: {
      "vertical-align": "middle"
    },
    attrs: {
      src: require("../../assets/img/prodiggi.png"),
      height: "53",
      width: "55"
    }
  }), _vm._v(" "), _c("span", {
    staticStyle: {
      right: "25pt",
      position: "relative",
      "background-color": "rgba(255,255,255,0.7)",
      "border-radius": "4pt",
      display: "inline-block",
      "font-weight": "600",
      "vertical-align": "middle"
    }
  }, [_vm._v("Prodiggi ")]), _vm._v(" "), _c("span", {
    staticStyle: {
      display: "inline-block",
      right: "25pt",
      position: "relative",
      "vertical-align": "middle"
    }
  }, [_vm._v("Join company")])])]), _vm._v(" "), _c("div", {
    staticClass: "signup-form-body-compact"
  }, [_c("div", {
    staticStyle: {
      "text-align": "justify"
    }
  }, [_c("span", [_vm._v("If your company already has an account, request access for company administrator. If you know your company’s url, ("), _c("i", [_vm._v("mycompany")]), _vm._v(".prodiggi.com) simply navigate to it and look for the Invite button.")])]), _vm._v(" "), _c("el-row", {
    staticClass: "mt-10",
    staticStyle: {
      "font-weight": "bold"
    }
  }, [_c("el-col", {
    staticClass: "text-right mt-5",
    attrs: {
      span: 8
    }
  }, [_c("span", [_vm._v("https://")])]), _vm._v(" "), _c("el-col", {
    staticClass: "text-right",
    attrs: {
      span: 8
    }
  }, [_c("el-form", {
    ref: "joinExistingCompany",
    staticClass: "login100-form",
    attrs: {
      rules: _vm.existingOrganisationSignUpRules,
      model: _vm.existing_company
    }
  }, [_c("el-form-item", {
    staticClass: "wrap-input100 validate-input",
    attrs: {
      prop: "domain",
      size: "mini"
    }
  }, [_c("el-input", {
    attrs: {
      id: "test_onboarding_existing_company_input",
      placeholder: "mycompany",
      type: "text",
      size: "mini"
    },
    model: {
      value: _vm.existing_company.domain,
      callback: function callback($$v) {
        _vm.$set(_vm.existing_company, "domain", $$v);
      },
      expression: "existing_company.domain"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-col", {
    staticClass: "text-left mt-5",
    attrs: {
      span: 8
    }
  }, [_c("span", [_vm._v(".prodiggi.com")])])], 1), _vm._v(" "), _c("el-row", {
    staticClass: "mt-10",
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    staticClass: "text-left",
    attrs: {
      span: 6
    }
  }, [_c("a", {
    staticClass: "txt3 hov1 left",
    on: {
      click: function click($event) {
        return _vm.goToLoginPage();
      }
    }
  }, [_c("i", {
    staticClass: "el-icon-left"
  }), _vm._v("\n                Back\n              ")])]), _vm._v(" "), _c("el-col", {
    staticClass: "text-right",
    attrs: {
      span: 18
    }
  }, [_c("el-button", {
    attrs: {
      id: "test_onboarding_existing_company_submit",
      type: "primary",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.goToCompanyPage();
      }
    }
  }, [_vm._v("Request Invite")])], 1)], 1)], 1)])])], 1) : _vm._e(), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "JIRA Integration Settings",
      visible: _vm.integrationDialog,
      width: "30%",
      "custom-class": "jira_integration_settings_dialog"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.integrationDialog = $event;
      }
    }
  }, [_c("div", {
    staticClass: "text-right"
  }, [_c("el-button", {
    attrs: {
      icon: "el-icon-question",
      size: "mini"
    },
    on: {
      click: _vm.displayJIRAIntegrationDocumentation
    }
  }, [_vm._v(" JIRA Integration Documentation ")])], 1), _vm._v(" "), _c("el-steps", {
    attrs: {
      active: _vm.jira_active_step,
      "align-center": "",
      "finish-status": "success"
    }
  }, [_c("el-step", {
    attrs: {
      title: "Step 1",
      description: "Integration Details"
    }
  }), _vm._v(" "), _c("el-step", {
    attrs: {
      title: "Step 2",
      description: "Confirmation Code"
    }
  })], 1), _vm._v(" "), _c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.jira_active_step === 0 ? _c("div", [_c("el-form", {
    ref: "thirdPartyIntegration",
    staticClass: "login100-form",
    attrs: {
      rules: _vm.thirdPartyIntegrationRules,
      model: _vm.JIRAIntegrationform,
      "label-position": "top"
    }
  }, [_c("el-form-item", {
    staticClass: "wrap-input100 validate-input",
    attrs: {
      prop: "jiraHome",
      size: "mini",
      label: "JIRA URL"
    }
  }, [_c("input", {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.JIRAIntegrationform.jiraHome,
      expression: "JIRAIntegrationform.jiraHome"
    }],
    attrs: {
      id: "test_onboarding_jira_integration_url",
      type: "url",
      name: "jiraHome",
      placeholder: "https://demo.atlassian.net"
    },
    domProps: {
      value: _vm.JIRAIntegrationform.jiraHome
    },
    on: {
      input: function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.JIRAIntegrationform, "jiraHome", $event.target.value);
      }
    }
  })]), _vm._v(" "), _c("el-form-item", {
    staticClass: "wrap-input100 validate-input",
    attrs: {
      prop: "consumerKey",
      size: "mini",
      label: "Consumer Key"
    }
  }, [_c("input", {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.JIRAIntegrationform.consumerKey,
      expression: "JIRAIntegrationform.consumerKey"
    }],
    attrs: {
      id: "test_onboarding_jira_integration_consumerkey",
      type: "text",
      name: "consumerKey",
      placeholder: "OauthKey"
    },
    domProps: {
      value: _vm.JIRAIntegrationform.consumerKey
    },
    on: {
      input: function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.JIRAIntegrationform, "consumerKey", $event.target.value);
      }
    }
  })])], 1)], 1) : _vm._e()]), _vm._v(" "), _c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.jira_active_step === 1 ? _c("div", [_c("div", {
    staticClass: "wrap-input100 validate-input"
  }, [_c("span", {
    staticClass: "label-input100"
  }, [_vm._v("Verification Code")]), _c("span", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v("*")]), _vm._v(" "), _c("input", {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.JIRAIntegrationResponse.verificationCode,
      expression: "JIRAIntegrationResponse.verificationCode"
    }],
    staticClass: "input100",
    attrs: {
      id: "test_onboarding_jira_integration_verificationcode",
      type: "text",
      name: "verificationCode",
      placeholder: "zKf25Q"
    },
    domProps: {
      value: _vm.JIRAIntegrationResponse.verificationCode
    },
    on: {
      input: function input($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.JIRAIntegrationResponse, "verificationCode", $event.target.value);
      }
    }
  }), _vm._v(" "), _c("span", {
    staticClass: "focus-input100"
  })])]) : _vm._e()]), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.integrationDialog = false;
      }
    }
  }, [_vm._v("Cancel")]), _vm._v(" "), _vm.jira_active_step === 0 ? _c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.JIRATesting,
      expression: "JIRATesting"
    }],
    staticClass: "test_onboarding_jira_authourise",
    attrs: {
      type: "info",
      size: "mini"
    },
    on: {
      click: _vm.authouriseJIRAIntegration
    }
  }, [_vm._v("Authourise")]) : _vm._e(), _vm._v(" "), _vm.jira_active_step === 1 ? _c("el-button", {
    staticClass: "test_onboarding_jira_integrate",
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.saveIntegration
    }
  }, [_vm._v("Integrate")]) : _vm._e()], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Add Integration Settings",
      visible: _vm.integrationDialogDefault,
      width: "80"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.integrationDialogDefault = $event;
      }
    }
  }, [_c("div", {
    staticClass: "text-center"
  }, [_c("span", [_vm._v("To Be Updated")])]), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.integrationDialogDefault = false;
      }
    }
  }, [_vm._v("Cancel")])], 1)])], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;