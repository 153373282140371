"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.array.fill");
require("core-js/modules/es6.function.name");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "child-container"
  }, [_c("el-row", [_c("el-col", {
    staticClass: "text-center pr-5",
    attrs: {
      span: 2
    }
  }, [_c("el-row", [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("div", {
    staticClass: "title text-center"
  }, [_vm._v("Drawing Tools")])])], 1), _vm._v(" "), _c("div", {
    staticClass: "bordered-container quater-height"
  }, [_c("el-row", [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("div", {
    attrs: {
      id: "step",
      draggable: ""
    },
    on: {
      drag: function drag($event) {
        return _vm.setActiveDragElement("step");
      }
    }
  }, [_c("el-popover", {
    ref: "steppopover",
    attrs: {
      placement: "right",
      title: "Task",
      width: "380",
      trigger: "hover",
      "open-delay": 400,
      "close-delay": 400,
      "popper-class": "word-break-keep-all",
      content: "Tasks can be dragged on to phases. Be sure to fill out task details."
    }
  }), _vm._v(" "), _c("svg", {
    directives: [{
      name: "popover",
      rawName: "v-popover:steppopover",
      arg: "steppopover"
    }],
    attrs: {
      height: "44px",
      width: "40px",
      xmlns: "http://www.w3.org/2000/svg",
      version: "1.1"
    }
  }, [_c("circle", {
    attrs: {
      cx: "19",
      cy: "25",
      r: "18",
      fill: "#fffacc",
      stroke: "green",
      "stroke-width": "2"
    }
  }), _vm._v(" "), _c("text", {
    attrs: {
      x: "19",
      y: "30",
      "text-anchor": "middle",
      fill: "Black",
      "font-size": "14"
    }
  }, [_vm._v("Task")])])], 1)])], 1), _vm._v(" "), _c("el-row", [_c("el-col", {
    staticStyle: {
      "text-align": "center"
    },
    attrs: {
      span: 24
    }
  }, [_c("div", {
    attrs: {
      id: "phase",
      draggable: ""
    },
    on: {
      drag: function drag($event) {
        return _vm.setActiveDragElement("phase");
      }
    }
  }, [_c("el-popover", {
    ref: "phasepopover",
    attrs: {
      placement: "right",
      title: "Phase",
      width: "380",
      trigger: "hover",
      "open-delay": 400,
      "close-delay": 400,
      "popper-class": "word-break-keep-all",
      content: "Phases allow steps to be grouped into major steps of a journey.   "
    }
  }), _vm._v(" "), _c("svg", {
    directives: [{
      name: "popover",
      rawName: "v-popover:phasepopover",
      arg: "phasepopover"
    }],
    attrs: {
      height: "40px",
      width: "60px",
      xmlns: "http://www.w3.org/2000/svg",
      version: "1.1"
    }
  }, [_c("polygon", {
    attrs: {
      points: "0 10,50 10,60 20,50 30,0 30",
      stroke: "lightblue",
      fill: "aliceblue"
    }
  }), _vm._v(" "), _c("text", {
    attrs: {
      x: "25",
      y: "26",
      "text-anchor": "middle",
      fill: "black",
      "font-size": "14"
    }
  }, [_vm._v("Phase")])])], 1)])], 1)], 1), _vm._v(" "), _c("el-row", {
    staticClass: "mt-10"
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("div", {
    staticClass: "title text-center"
  }, [_vm._v("View Options")])])], 1), _vm._v(" "), _c("div", {
    staticClass: "bordered-container quater-height"
  }, [_c("el-button", {
    staticClass: "mt-10",
    attrs: {
      type: "primary",
      size: "mini",
      icon: "el-icon-s-grid"
    },
    on: {
      click: _vm.matrixView
    }
  }, [_vm._v("Matrix view")])], 1)], 1), _vm._v(" "), _c("el-col", {
    staticClass: "pl-5 pr-5",
    attrs: {
      span: _vm.isMinimisedRSB ? 21 : 16
    }
  }, [_c("div", {
    class: _vm.journeyMapStatus === "approved" ? "text-center approved-title" : "title text-center"
  }, [_vm._v("Journey Map Designer for "), _c("strong", [_vm._v(_vm._s(_vm.userType.name))]), _vm._v(" "), _c("el-select", {
    staticClass: "fr",
    staticStyle: {
      "margin-top": "-6px"
    },
    attrs: {
      placeholder: "Select",
      size: "mini"
    },
    on: {
      change: _vm.userTypeSelectionChange
    },
    model: {
      value: _vm.active_user_type_name,
      callback: function callback($$v) {
        _vm.active_user_type_name = $$v;
      },
      expression: "active_user_type_name"
    }
  }, _vm._l(_vm.user_types, function (user_type) {
    return _c("el-option", {
      key: user_type.id,
      attrs: {
        label: user_type.name,
        value: user_type.name
      }
    });
  }), 1)], 1), _vm._v(" "), _c("div", {
    class: _vm.journeyMapStatus === "approved" ? "bordered-container approved-border" : "bordered-container",
    attrs: {
      id: "myDiagramDiv"
    },
    on: {
      drop: _vm.handleDrop,
      dragover: _vm.handleDragOver
    }
  }, [_c("svg", {
    attrs: {
      height: "650px",
      width: "100%",
      xmlns: "http://www.w3.org/2000/svg",
      version: "1.1"
    }
  }, [_vm._l(_vm.stages, function (stage, index) {
    return [_c("polygon", {
      directives: [{
        name: "dragged",
        rawName: "v-dragged",
        value: _vm.startPhaseMove,
        expression: "startPhaseMove"
      }],
      key: _vm.generatePhaseID(index),
      attrs: {
        id: _vm.generatePhaseID(index),
        points: stage.points,
        fill: stage.fill,
        stroke: stage.stroke,
        "stroke-width": "1"
      },
      on: {
        mouseover: function mouseover($event) {
          return _vm.selectPhase(index, "mouseover");
        },
        click: function click($event) {
          return _vm.selectPhase(index, "click");
        }
      }
    }), _vm._v(" "), _c("text", {
      key: _vm.generateStageTextID(index),
      attrs: {
        "text-anchor": "middle",
        x: parseInt(_vm.generatePhaseTextPoints(stage.points)[0]) + 300,
        y: parseInt(_vm.generatePhaseTextPoints(stage.points)[1]) - 20,
        fill: "gray"
      }
    }, [_c("tspan", {
      attrs: {
        x: parseInt(_vm.generatePhaseTextPoints(stage.points)[0]) + 300,
        dy: "1em"
      }
    }, [_vm._v(_vm._s(_vm.stageData[index].name))])])];
  }), _vm._v(" "), _c("g", {
    attrs: {
      transform: "translate(30 85) scale(.1 .1)"
    }
  }, [_c("path", {
    attrs: {
      d: "M204.583,216.671c50.664,0,91.74-48.075,91.74-107.378c0-82.237-41.074-107.377-91.74-107.377\n              c-50.668,0-91.74,25.14-91.74,107.377C112.844,168.596,153.916,216.671,204.583,216.671z"
    }
  }), _vm._v(" "), _c("path", {
    attrs: {
      d: "M407.164,374.717L360.88,270.454c-2.117-4.771-5.836-8.728-10.465-11.138l-71.83-37.392\n              c-1.584-0.823-3.502-0.663-4.926,0.415c-20.316,15.366-44.203,23.488-69.076,23.488c-24.877,0-48.762-8.122-69.078-23.488\n              c-1.428-1.078-3.346-1.238-4.93-0.415L58.75,259.316c-4.631,2.41-8.346,6.365-10.465,11.138L2.001,374.717\n              c-3.191,7.188-2.537,15.412,1.75,22.005c4.285,6.592,11.537,10.526,19.4,10.526h362.861c7.863,0,15.117-3.936,19.402-10.527\n              C409.699,390.129,410.355,381.902,407.164,374.717z"
    }
  }), _vm._v(" "), _c("text", {
    attrs: {
      x: "22",
      y: "60",
      transform: "scale(9,9)",
      "text-anchor": "middle"
    }
  }, [_vm._v(_vm._s(_vm.userType.name))])]), _vm._v(" "), _vm._l(_vm.stagePaths, function (stagePath, index) {
    return _vm.stages.length > 1 ? [_c("path", {
      key: _vm.generateStageLinkID(index),
      attrs: {
        "stroke-dasharray": "10,10",
        "stroke-width": "1",
        stroke: "gray",
        fill: "none",
        d: stagePath.path
      }
    })] : _vm._e();
  }), _vm._v(" "), _vm._l(_vm.tasks, function (task, index) {
    return [_c("circle", _vm._b({
      directives: [{
        name: "dragged",
        rawName: "v-dragged",
        value: _vm.startTaskMove,
        expression: "startTaskMove"
      }],
      key: _vm.generateTaskID(index),
      attrs: {
        id: _vm.generateTaskID(index),
        fill: task.fill,
        stroke: task.stroke,
        "stroke-width": task.stroke_width
      },
      on: {
        mouseover: function mouseover($event) {
          return _vm.selectTask(index, "mouseover");
        },
        click: function click($event) {
          return _vm.selectTask(index, "click");
        }
      }
    }, "circle", task, false)), _vm._v(" "), _c("text", {
      key: _vm.generateTaskTextID(index),
      attrs: {
        "text-anchor": "middle",
        x: task.cx,
        y: index % 2 === 0 ? task.cy - 20 : task.cy + 25,
        fill: "gray"
      }
    }, [_c("tspan", {
      attrs: {
        x: task.cx,
        dy: ".1em"
      }
    }, [_vm._v(_vm._s(_vm.taskData[index].task))])])];
  }), _vm._v(" "), _c("transition", {
    attrs: {
      name: "fade-transform",
      mode: "in-out"
    }
  }, [_c("g", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isStageSelected,
      expression: "isStageSelected"
    }]
  }, [_c("foreignObject", {
    staticStyle: {
      color: "rgba(0,0,0,0.9)",
      "background-color": "rgba(255,255,255,1)"
    },
    attrs: {
      x: parseInt(_vm.stageModal.x),
      y: parseInt(_vm.stageModal.y) + 40,
      width: _vm.isMinimisedRSB ? 900 : 600,
      height: 350
    }
  }, [_c("div", {
    staticClass: "stage-details-container br-bottom-3pt",
    attrs: {
      xmlns: "http://www.w3.org/1999/xhtml"
    }
  }, [_c("el-row", {
    staticClass: "sub-title text-center p-5"
  }, [_c("el-col", {
    staticClass: "pl-5",
    attrs: {
      span: 22
    }
  }, [_c("strong", [_vm._v(_vm._s(_vm.selectedStage.name))])]), _vm._v(" "), _c("el-col", {
    staticClass: "fr",
    attrs: {
      span: 2
    }
  }, [_c("i", {
    staticClass: "el-icon-edit",
    on: {
      click: _vm.openStageEditModal
    }
  }), _vm._v(" "), _c("i", {
    staticClass: "el-icon-close",
    on: {
      click: _vm.closeStageDetailsModal
    }
  })])], 1), _vm._v(" "), _c("div", {
    staticClass: "bordered-container-fluid-height br-bottom-3pt"
  }, [_c("el-row", {
    staticClass: "pl-5 mt-5"
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("strong", [_vm._v("Description")])]), _vm._v(" "), _c("el-col", {
    staticClass: "pr-5",
    attrs: {
      span: 16
    }
  }, [_vm._v("\n                      " + _vm._s(_vm.selectedStage.description) + "\n                    ")])], 1), _vm._v(" "), _c("el-row", {
    staticClass: "pl-5 mt-5"
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("strong", [_vm._v("Goal")])]), _vm._v(" "), _c("el-col", {
    staticClass: "pr-5",
    attrs: {
      span: 16
    }
  }, [_vm._v("\n                      " + _vm._s(_vm.selectedStage.goal) + "\n                    ")])], 1), _vm._v(" "), _c("el-row", {
    staticClass: "pl-5 mt-5"
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("strong", [_vm._v("Challenges")])]), _vm._v(" "), _c("el-col", {
    staticClass: "pr-5",
    attrs: {
      span: 16
    }
  }, [_vm._v("\n                      " + _vm._s(_vm.selectedStage.challenges) + "\n                    ")])], 1), _vm._v(" "), _c("el-row", {
    staticClass: "pl-5 mt-5"
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("strong", [_vm._v("Emotions")])]), _vm._v(" "), _c("el-col", {
    staticClass: "pr-5",
    attrs: {
      span: 16
    }
  }, [_vm._v("\n                      " + _vm._s(_vm.selectedStage.emotions) + "\n                    ")])], 1), _vm._v(" "), _c("el-row", {
    staticClass: "pl-5 mt-5",
    staticStyle: {
      "padding-bottom": "10pt"
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("strong", [_vm._v("Priority")])]), _vm._v(" "), _c("el-col", {
    staticClass: "pr-5",
    attrs: {
      span: 16
    }
  }, [_vm._v("\n                      " + _vm._s(_vm.selectedStage.priority) + "\n                    ")])], 1), _vm._v(" "), _c("div", {
    staticClass: "text-right p-5"
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "danger",
      icon: "el-icon-delete"
    },
    on: {
      click: _vm.deleteStage
    }
  }, [_vm._v("Delete")])], 1)], 1)], 1)])])]), _vm._v(" "), _c("transition", {
    attrs: {
      name: "fade-transform",
      mode: "in-out"
    }
  }, [_c("g", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.taskDetailsVisible,
      expression: "taskDetailsVisible"
    }],
    on: {
      drop: _vm.handleArtefactDrop,
      dragover: _vm.handleArtefactDragOver
    }
  }, [_c("foreignObject", {
    staticStyle: {
      "background-color": "rgba(255,255,255,1)"
    },
    attrs: {
      width: _vm.taskModal.width,
      height: _vm.taskModal.height,
      x: _vm.computeTaskDetailStartPoint(),
      y: _vm.taskModal.y + 30
    }
  }, [_c("div", {
    staticClass: "br-bottom-3pt task-details-container",
    attrs: {
      xmlns: "http://www.w3.org/1999/xhtml"
    }
  }, [_vm.taskData.length > 0 ? _c("el-row", {
    staticClass: "sub-title text-center p-5"
  }, [_c("el-col", {
    staticClass: "pl-5 text-left",
    attrs: {
      span: 20
    }
  }, [_vm.taskData[_vm.activeTaskIndex].task ? _c("span", [_vm._v(_vm._s(_vm.taskData[_vm.activeTaskIndex].task) + " Details")]) : _vm._e()]), _vm._v(" "), _c("el-col", {
    staticClass: "text-right",
    attrs: {
      span: 4
    }
  }, [_c("i", {
    staticClass: "el-icon-delete",
    on: {
      click: _vm.deleteTask
    }
  }), _vm._v(" "), _c("i", {
    staticClass: "el-icon-close",
    on: {
      click: function click($event) {
        _vm.taskDetailsVisible = false;
      }
    }
  })])], 1) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "bordered-container-fluid-height br-bottom-3pt"
  }, [_vm.taskData.length > 0 ? _c("el-row", {
    staticClass: "mt-5"
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("strong", [_vm._v("Task")])]), _vm._v(" "), _c("el-col", {
    staticClass: "pr-5",
    attrs: {
      span: 16
    }
  }, [_vm._v(_vm._s(_vm.taskData[_vm.activeTaskIndex].task))])], 1) : _vm._e(), _vm._v(" "), _vm.taskData.length > 0 ? _c("el-row", {
    staticStyle: {
      "margin-top": "10px"
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("strong", [_vm._v("Intention")])]), _vm._v(" "), _c("el-col", {
    staticClass: "pr-5",
    attrs: {
      span: 16
    }
  }, [_vm._v(_vm._s(_vm.taskData[_vm.activeTaskIndex].intention))])], 1) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "text-right mt-10"
  }, [_c("el-button-group", [_c("el-button", {
    attrs: {
      type: "warning",
      size: "mini",
      icon: "el-icon-plus"
    },
    on: {
      click: function click($event) {
        return _vm.openPainPointsModal(true);
      }
    }
  }, [_vm._v("Pains")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "success",
      size: "mini",
      icon: "el-icon-plus"
    },
    on: {
      click: function click($event) {
        return _vm.openPleasersModal(true);
      }
    }
  }, [_vm._v("Gains")])], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "text-center mt-10",
    staticStyle: {
      "padding-bottom": "10px"
    }
  }, [_c("table", {
    attrs: {
      width: "100%",
      id: "pains-gains-features"
    }
  }, [_c("thead", [_c("tr", [_c("th", [_vm._v("Pains")]), _vm._v(" "), _c("th", [_vm._v("Gains")]), _vm._v(" "), _c("th", [_vm._v("Features")]), _vm._v(" "), _c("th", [_vm._v("#")])])]), _vm._v(" "), _c("tbody", [_vm.taskData.length > 0 ? [_vm._l(_vm.taskData[_vm.activeTaskIndex].pain_points, function (pain_point, index) {
    return _c("tr", {
      key: index
    }, [_c("td", [_vm._v("\n                            " + _vm._s(pain_point.pain_point) + "\n                          ")]), _vm._v(" "), _c("td"), _vm._v(" "), _c("td", [_c("el-button", {
      attrs: {
        type: "secondary",
        size: "mini",
        icon: "el-icon-plus"
      },
      on: {
        click: function click($event) {
          return _vm.openTaskCandidateFeaturesDialog("pain_point", index);
        }
      }
    }, [_vm._v("Add Feature")]), _vm._v(" "), _c("table", {
      attrs: {
        width: "100%"
      }
    }, [_c("thead", [_c("tr", [_c("th", [_vm._v("Feature")]), _vm._v(" "), _c("th", [_vm._v("Status")]), _vm._v(" "), _c("th", [_vm._v("Roadmap")]), _vm._v(" "), _c("th", [_vm._v("#")])])]), _vm._v(" "), _c("tbody", _vm._l(_vm.taskData[_vm.activeTaskIndex].pain_points[index].relations, function (candidate_feature, sub_index) {
      return _c("tr", {
        key: sub_index
      }, [_c("td", [_vm._v(_vm._s(candidate_feature.candidate_feature))]), _vm._v(" "), _c("td", [_c("el-tag", {
        attrs: {
          size: "mini"
        }
      }, [_vm._v(_vm._s(candidate_feature.status))])], 1), _vm._v(" "), _c("td", [_vm._v(_vm._s(candidate_feature.roadmap_version))]), _vm._v(" "), _c("td", [_c("el-button", {
        staticStyle: {
          "padding-right": "5px",
          "padding-left": "5px"
        },
        attrs: {
          type: "danger",
          size: "mini",
          icon: "el-icon-delete"
        },
        on: {
          click: function click($event) {
            return _vm.removeCandidateFeature(index);
          }
        }
      })], 1)]);
    }), 0)])], 1), _vm._v(" "), _c("td", [_c("el-button", {
      attrs: {
        type: "danger",
        size: "mini",
        icon: "el-icon-delete"
      },
      on: {
        click: function click($event) {
          return _vm.removePainPoint(index);
        }
      }
    })], 1)]);
  }), _vm._v(" "), _vm._l(_vm.taskData[_vm.activeTaskIndex].pleasers, function (pleaser, index) {
    return _c("tr", {
      key: index
    }, [_c("td"), _vm._v(" "), _c("td", [_vm._v("\n                            " + _vm._s(pleaser.pleaser) + "\n                          ")]), _vm._v(" "), _c("td", [_c("el-button", {
      attrs: {
        type: "secondary",
        size: "mini",
        icon: "el-icon-plus"
      },
      on: {
        click: function click($event) {
          return _vm.openTaskCandidateFeaturesDialog("pleaser", index);
        }
      }
    }, [_vm._v("Add Feature")]), _vm._v(" "), _c("table", {
      attrs: {
        width: "100%"
      }
    }, [_c("thead", [_c("tr", [_c("th", [_vm._v("Feature")]), _vm._v(" "), _c("th", [_vm._v("Status")]), _vm._v(" "), _c("th", [_vm._v("Roadmap")]), _vm._v(" "), _c("th", [_vm._v("#")])])]), _vm._v(" "), _c("tbody", _vm._l(_vm.taskData[_vm.activeTaskIndex].pleasers[index].relations, function (candidate_feature, sub_index) {
      return _c("tr", {
        key: sub_index
      }, [_c("td", [_vm._v(_vm._s(candidate_feature.candidate_feature))]), _vm._v(" "), _c("td", [_c("el-tag", {
        attrs: {
          size: "mini"
        }
      }, [_vm._v(_vm._s(candidate_feature.status))])], 1), _vm._v(" "), _c("td", [_vm._v(_vm._s(candidate_feature.roadmap_version))]), _vm._v(" "), _c("td", [_c("el-button", {
        staticStyle: {
          "padding-right": "5px",
          "padding-left": "5px"
        },
        attrs: {
          type: "danger",
          size: "mini",
          icon: "el-icon-delete"
        },
        on: {
          click: function click($event) {
            return _vm.removeCandidateFeature(index);
          }
        }
      })], 1)]);
    }), 0)])], 1), _vm._v(" "), _c("td", [_c("el-button", {
      attrs: {
        type: "danger",
        size: "mini",
        icon: "el-icon-delete"
      },
      on: {
        click: function click($event) {
          return _vm.removePleaser(index);
        }
      }
    })], 1)]);
  })] : _vm._e()], 2)])])], 1)], 1)])])])], 2)])]), _vm._v(" "), _c("el-col", {
    staticClass: "pl-5",
    attrs: {
      span: _vm.isMinimisedRSB ? 1 : 6
    }
  }, [_c("div", {
    staticClass: "title"
  }, [_c("el-row", [_c("el-col", {
    attrs: {
      span: 2
    }
  }, [!_vm.isMinimisedRSB ? _c("el-button", {
    attrs: {
      icon: "el-icon-arrow-right",
      circle: "",
      size: "micro"
    },
    on: {
      click: function click($event) {
        _vm.isMinimisedRSB = !_vm.isMinimisedRSB;
      }
    }
  }) : _c("el-button", {
    attrs: {
      icon: "el-icon-arrow-left",
      circle: "",
      size: "micro"
    },
    on: {
      click: function click($event) {
        _vm.isMinimisedRSB = !_vm.isMinimisedRSB;
      }
    }
  })], 1), _vm._v(" "), !_vm.isMinimisedRSB ? _c("el-col", {
    staticClass: "text-center",
    attrs: {
      span: 20
    }
  }, [_vm._v("\n            Actions\n          ")]) : _vm._e()], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "bordered-container no-padding"
  }, [!_vm.isMinimisedRSB ? _c("div", {
    staticClass: "text-center"
  }, [_vm.journeyMapStatus === "unapproved" ? _c("el-button", {
    staticClass: "mt-10",
    attrs: {
      size: "mini",
      type: "success",
      icon: "el-icon-check"
    },
    on: {
      click: function click($event) {
        _vm.journeyMapStatus = "approved";
      }
    }
  }, [_vm._v("Approve Journey Map")]) : _c("el-button", {
    staticClass: "mt-10",
    attrs: {
      size: "mini",
      type: "warning",
      icon: "el-icon-close"
    },
    on: {
      click: function click($event) {
        _vm.journeyMapStatus = "unapproved";
      }
    }
  }, [_vm._v("Reject Journey Map")])], 1) : _vm._e(), _vm._v(" "), _c("el-divider"), _vm._v(" "), !_vm.isMinimisedRSB ? _c("div", {
    staticClass: "title text-center"
  }, [_vm._v("\n          User Type Information\n        ")]) : _vm._e(), _vm._v(" "), _vm.user_type.id && !_vm.isMinimisedRSB ? _c("el-collapse", {
    staticClass: "design-tools-collapse",
    attrs: {
      accordion: ""
    }
  }, [_c("el-collapse-item", {
    staticClass: "mr-5",
    attrs: {
      title: "Journey Map Items",
      name: "1"
    }
  }, [_c("el-collapse", {
    staticStyle: {
      border: "solid 1px lightblue",
      "background-color": "aliceblue",
      padding: "5px"
    },
    attrs: {
      accordion: ""
    }
  }, [_c("el-collapse-item", {
    staticStyle: {
      "border-bottom": "solid 1px grey",
      "background-color": "lightpink"
    },
    attrs: {
      title: "PainPoints",
      name: "1"
    }
  }, [_c("table", {
    staticClass: "mr-10",
    staticStyle: {
      "background-color": "lightpink"
    },
    attrs: {
      width: "100%"
    }
  }, [_c("tr", [_c("th", [_vm._v("Pain Point")]), _vm._v(" "), _c("th", [_vm._v("Value")]), _vm._v(" "), _c("th", [_vm._v("Priority")])]), _vm._v(" "), _vm._l(_vm.user_type.pain_points, function (pain_point, index) {
    return _c("tr", {
      key: index,
      staticStyle: {
        "text-align": "center"
      },
      attrs: {
        draggable: ""
      },
      on: {
        drag: function drag($event) {
          return _vm.setActiveArtefactDragElement("pain_point", index);
        }
      }
    }, [_c("td", [_vm._v(_vm._s(pain_point.pain_point))]), _vm._v(" "), _c("td", [pain_point.value ? _c("svg-icon", {
      attrs: {
        "icon-class": _vm.getValueIcon(pain_point.value),
        title: "Value"
      }
    }) : _vm._e()], 1), _vm._v(" "), _c("td", [pain_point.priority ? _c("svg-icon", {
      attrs: {
        "icon-class": _vm.getPriorityIcon(pain_point.priority),
        title: "Priority"
      }
    }) : _vm._e()], 1)]);
  })], 2), _vm._v(" "), _c("i", {
    staticClass: "el-icon-plus",
    staticStyle: {
      float: "right",
      "padding-top": "5px"
    },
    on: {
      click: _vm.openPainPointsModal
    }
  })]), _vm._v(" "), _c("el-collapse-item", {
    staticStyle: {
      "border-bottom": "solid 1px grey",
      "background-color": "lightskyblue"
    },
    attrs: {
      title: "Pleasers",
      name: "2"
    }
  }, [_c("table", {
    staticStyle: {
      "background-color": "paleturquoise"
    },
    attrs: {
      width: "100%"
    }
  }, [_c("tr", [_c("th", [_vm._v("Pleaser")]), _vm._v(" "), _c("th", [_vm._v("Value")]), _vm._v(" "), _c("th", [_vm._v("Priority")])]), _vm._v(" "), _vm._l(_vm.user_type.pleasers, function (pleaser, index) {
    return _c("tr", {
      key: index,
      staticStyle: {
        "text-align": "center"
      },
      attrs: {
        draggable: ""
      },
      on: {
        drag: function drag($event) {
          return _vm.setActiveArtefactDragElement("pleaser", index);
        }
      }
    }, [_c("td", [_vm._v(_vm._s(pleaser.pleaser))]), _vm._v(" "), _c("td", [pleaser.value ? _c("svg-icon", {
      attrs: {
        "icon-class": _vm.getValueIcon(pleaser.value),
        title: "Value"
      }
    }) : _vm._e()], 1), _vm._v(" "), _c("td", [pleaser.priority ? _c("svg-icon", {
      attrs: {
        "icon-class": _vm.getPriorityIcon(pleaser.priority),
        title: "Priority"
      }
    }) : _vm._e()], 1)]);
  })], 2), _vm._v(" "), _c("i", {
    staticClass: "el-icon-plus",
    staticStyle: {
      float: "right",
      "padding-top": "5px"
    },
    on: {
      click: _vm.openPleasersModal
    }
  })]), _vm._v(" "), _c("el-collapse-item", {
    staticStyle: {
      "border-bottom": "solid 1px grey",
      "background-color": "lightskyblue"
    },
    attrs: {
      title: "Candidate Features"
    }
  }, [_c("table", {
    staticStyle: {
      "background-color": "lightskyblue"
    },
    attrs: {
      width: "100%"
    }
  }, [_c("tr", [_c("th", [_vm._v("Candidate Feature")]), _vm._v(" "), _c("th", [_vm._v("Value")]), _vm._v(" "), _c("th", [_vm._v("Priority")])]), _vm._v(" "), _vm._l(_vm.user_type.candidate_features, function (candidate_feature, index) {
    return _c("tr", {
      key: index,
      staticStyle: {
        "text-align": "center"
      },
      attrs: {
        draggable: ""
      },
      on: {
        drag: function drag($event) {
          return _vm.setActiveArtefactDragElement("candidate_feature", index);
        }
      }
    }, [_c("td", [_vm._v(_vm._s(candidate_feature.candidate_feature))]), _vm._v(" "), _c("td", [candidate_feature.value ? _c("svg-icon", {
      attrs: {
        "icon-class": _vm.getValueIcon(candidate_feature.value),
        title: "Value"
      }
    }) : _vm._e()], 1), _vm._v(" "), _c("td", [candidate_feature.priority ? _c("svg-icon", {
      attrs: {
        "icon-class": _vm.getPriorityIcon(candidate_feature.priority),
        title: "Priority"
      }
    }) : _vm._e()], 1)]);
  })], 2), _vm._v(" "), _c("i", {
    staticClass: "el-icon-plus",
    staticStyle: {
      float: "right",
      "padding-top": "5px"
    },
    on: {
      click: _vm.openCandidateFeaturesModal
    }
  })])], 1)], 1)], 1) : !_vm.isMinimisedRSB ? _c("p", {
    staticStyle: {
      "padding-left": "10px"
    }
  }, [_vm._v("Select a User Type to view design aids")]) : _vm._e(), _vm._v(" "), _vm.user_type.id && !_vm.isMinimisedRSB ? _c("el-collapse", {
    staticClass: "design-tools-collapse",
    attrs: {
      accordion: ""
    }
  }, [_c("el-collapse-item", {
    staticClass: "mr-5",
    attrs: {
      title: "Related Scenarios"
    }
  }, [_c("el-row", {
    staticStyle: {
      "padding-bottom": "5pt"
    }
  }, [_vm.user_type.id ? _c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-col", [_c("p", {
    staticStyle: {
      "padding-bottom": "0",
      "margin-bottom": "0"
    }
  }, [_vm._v("User Scenarios")])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-input", {
    attrs: {
      rows: 2,
      type: "textarea",
      placeholder: "Please input"
    }
  })], 1)], 1) : _vm._e()], 1)], 1), _vm._v(" "), _c("el-collapse-item", {
    staticClass: "mr-5",
    attrs: {
      title: "Design Goals"
    }
  }, [_c("el-row", {
    staticStyle: {
      "padding-bottom": "5pt"
    }
  }, [_vm.user_type.id ? _c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-col", [_c("p", {
    staticStyle: {
      "padding-bottom": "0",
      "margin-bottom": "0"
    }
  }, [_vm._v("Design Goals")])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-input", {
    attrs: {
      rows: 2,
      type: "textarea",
      placeholder: "Please input"
    }
  })], 1)], 1) : _vm._e()], 1)], 1), _vm._v(" "), _c("el-collapse-item", {
    staticClass: "mr-5",
    attrs: {
      title: "Problem & Opportunity"
    }
  }, [_c("el-row", {
    staticStyle: {
      "padding-bottom": "5pt"
    }
  }, [_vm.user_type.id ? _c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("p", {
    staticStyle: {
      "padding-bottom": "0",
      "margin-bottom": "0"
    }
  }, [_vm._v("Problem Statement Statements")]), _vm._v(" "), _c("el-input", {
    staticStyle: {
      "marin-top": "0pt",
      "padding-top": "0"
    },
    attrs: {
      type: "textarea",
      disabled: "",
      label: "Design Goals"
    },
    model: {
      value: _vm.user_type.problem_statement,
      callback: function callback($$v) {
        _vm.$set(_vm.user_type, "problem_statement", $$v);
      },
      expression: "user_type.problem_statement"
    }
  })], 1)], 1) : _vm._e(), _vm._v(" "), _vm.user_type.id ? _c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("p", {
    staticStyle: {
      "padding-bottom": "0",
      "margin-bottom": "0"
    }
  }, [_vm._v("Opportunity Statement")]), _vm._v(" "), _c("el-input", {
    staticStyle: {
      "marin-top": "0pt",
      "padding-top": "0"
    },
    attrs: {
      type: "textarea",
      disabled: ""
    },
    model: {
      value: _vm.user_type.opportunity_statement,
      callback: function callback($$v) {
        _vm.$set(_vm.user_type, "opportunity_statement", $$v);
      },
      expression: "user_type.opportunity_statement"
    }
  })], 1)], 1) : _vm._e()], 1)], 1)], 1) : _vm._e()], 1)])], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Add PainPoint",
      visible: _vm.addPainPointsDialog,
      width: "30%",
      "append-to-body": "",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addPainPointsDialog = $event;
      }
    }
  }, [_c("el-form", {
    ref: "addPainPointsForm",
    staticClass: "mt-5 container",
    attrs: {
      model: _vm.pains_form,
      "label-width": "120px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Pain"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "Enter Pain"
    },
    model: {
      value: _vm.pains_form.pain_point,
      callback: function callback($$v) {
        _vm.$set(_vm.pains_form, "pain_point", $$v);
      },
      expression: "pains_form.pain_point"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Description"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      placeholder: "Enter Description"
    },
    model: {
      value: _vm.pains_form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.pains_form, "description", $$v);
      },
      expression: "pains_form.description"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Value"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.pains_form.value,
      callback: function callback($$v) {
        _vm.$set(_vm.pains_form, "value", $$v);
      },
      expression: "pains_form.value"
    }
  }, [_c("el-radio", {
    attrs: {
      label: "1"
    }
  }, [_vm._v("Low")]), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "2"
    }
  }, [_vm._v("Medium")]), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "3"
    }
  }, [_vm._v("High")])], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Priority"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.pains_form.priority,
      callback: function callback($$v) {
        _vm.$set(_vm.pains_form, "priority", $$v);
      },
      expression: "pains_form.priority"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: "Low",
      value: "Low"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Medium",
      value: "Medium"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "High",
      value: "High"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.addPainPointsDialog = false;
      }
    }
  }, [_vm._v("Cancel")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.addPainPoint
    }
  }, [_vm._v("Add")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Add Pleaser",
      visible: _vm.addPleasersDialog,
      width: "30%",
      "append-to-body": "",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addPleasersDialog = $event;
      }
    }
  }, [_c("el-form", {
    ref: "addGainsForm",
    staticClass: "mt-5 container",
    attrs: {
      model: _vm.gains_form,
      "label-width": "120px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Gain"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "Enter Gain"
    },
    model: {
      value: _vm.gains_form.pleaser,
      callback: function callback($$v) {
        _vm.$set(_vm.gains_form, "pleaser", $$v);
      },
      expression: "gains_form.pleaser"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Description"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      placeholder: "Enter Description"
    },
    model: {
      value: _vm.gains_form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.gains_form, "description", $$v);
      },
      expression: "gains_form.description"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Value"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.gains_form.value,
      callback: function callback($$v) {
        _vm.$set(_vm.gains_form, "value", $$v);
      },
      expression: "gains_form.value"
    }
  }, [_c("el-radio", {
    attrs: {
      label: "1"
    }
  }, [_vm._v("Low")]), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "2"
    }
  }, [_vm._v("Medium")]), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "3"
    }
  }, [_vm._v("High")])], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Priority"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.gains_form.priority,
      callback: function callback($$v) {
        _vm.$set(_vm.gains_form, "priority", $$v);
      },
      expression: "gains_form.priority"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: "Low",
      value: "Low"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Medium",
      value: "Medium"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "High",
      value: "High"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.addPleasersDialog = false;
      }
    }
  }, [_vm._v("Cancel")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.addPleaser
    }
  }, [_vm._v("Add")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Add Candidate Feature",
      visible: _vm.addCandidateFeaturesDialog,
      width: "30%",
      "append-to-body": "",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addCandidateFeaturesDialog = $event;
      }
    }
  }, [_c("el-form", {
    ref: "addCandidateFeatureForm",
    staticClass: "mt-5 container",
    attrs: {
      model: _vm.candidate_features_form,
      "label-width": "150px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Feature"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "Enter Feature"
    },
    model: {
      value: _vm.candidate_features_form.candidate_feature,
      callback: function callback($$v) {
        _vm.$set(_vm.candidate_features_form, "candidate_feature", $$v);
      },
      expression: "candidate_features_form.candidate_feature"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Description"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      placeholder: "Enter Description"
    },
    model: {
      value: _vm.candidate_features_form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.candidate_features_form, "description", $$v);
      },
      expression: "candidate_features_form.description"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Status"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "Enter Status"
    },
    model: {
      value: _vm.candidate_features_form.status,
      callback: function callback($$v) {
        _vm.$set(_vm.candidate_features_form, "status", $$v);
      },
      expression: "candidate_features_form.status"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Roadmap Version"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "Enter Roadmap Version"
    },
    model: {
      value: _vm.candidate_features_form.roadmap_version,
      callback: function callback($$v) {
        _vm.$set(_vm.candidate_features_form, "roadmap_version", $$v);
      },
      expression: "candidate_features_form.roadmap_version"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Value"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.candidate_features_form.value,
      callback: function callback($$v) {
        _vm.$set(_vm.candidate_features_form, "value", $$v);
      },
      expression: "candidate_features_form.value"
    }
  }, [_c("el-radio", {
    attrs: {
      label: "1"
    }
  }, [_vm._v("Low")]), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "2"
    }
  }, [_vm._v("Medium")]), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "3"
    }
  }, [_vm._v("High")])], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Priority"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.candidate_features_form.priority,
      callback: function callback($$v) {
        _vm.$set(_vm.candidate_features_form, "priority", $$v);
      },
      expression: "candidate_features_form.priority"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: "Low",
      value: "Low"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Medium",
      value: "Medium"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "High",
      value: "High"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.addCandidateFeaturesDialog = false;
      }
    }
  }, [_vm._v("Cancel")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.addCandidateFeature
    }
  }, [_vm._v("Add")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: _vm.computePhaseTitle(),
      visible: _vm.addPhaseDialog,
      width: "30%",
      "append-to-body": "",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addPhaseDialog = $event;
      }
    }
  }, [_c("el-form", {
    ref: "addStageForm",
    staticClass: "mt-5 container",
    attrs: {
      model: _vm.phase_form,
      "label-width": "120px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Name"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "Enter Phase Name"
    },
    model: {
      value: _vm.phase_form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.phase_form, "name", $$v);
      },
      expression: "phase_form.name"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Description"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      placeholder: "Enter detailed description"
    },
    model: {
      value: _vm.phase_form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.phase_form, "description", $$v);
      },
      expression: "phase_form.description"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Goal"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "The end goal of this phase"
    },
    model: {
      value: _vm.phase_form.goal,
      callback: function callback($$v) {
        _vm.$set(_vm.phase_form, "goal", $$v);
      },
      expression: "phase_form.goal"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Challenges"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "Challenges faced"
    },
    model: {
      value: _vm.phase_form.challenges,
      callback: function callback($$v) {
        _vm.$set(_vm.phase_form, "challenges", $$v);
      },
      expression: "phase_form.challenges"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Emotions"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "Enter emotions"
    },
    model: {
      value: _vm.phase_form.emotions,
      callback: function callback($$v) {
        _vm.$set(_vm.phase_form, "emotions", $$v);
      },
      expression: "phase_form.emotions"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Priority"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.phase_form.priority,
      callback: function callback($$v) {
        _vm.$set(_vm.phase_form, "priority", $$v);
      },
      expression: "phase_form.priority"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: "Low",
      value: "Low"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Medium",
      value: "Medium"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "High",
      value: "High"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.addPhaseDialog = false;
      }
    }
  }, [_vm._v("Cancel")]), _vm._v(" "), !_vm.isPhaseModalEditMode ? _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.addPhase
    }
  }, [_vm._v("Add")]) : _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.updatePhase
    }
  }, [_vm._v("Update")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: _vm.computeTaskTitle(),
      visible: _vm.addTaskDialog,
      width: "30%",
      "append-to-body": "",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addTaskDialog = $event;
      }
    }
  }, [_c("el-form", {
    ref: "addTaskForm",
    staticClass: "mt-5 container",
    attrs: {
      model: _vm.task_form,
      "label-width": "120px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Name"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "Enter Task Name"
    },
    model: {
      value: _vm.task_form.task,
      callback: function callback($$v) {
        _vm.$set(_vm.task_form, "task", $$v);
      },
      expression: "task_form.task"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Description"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      placeholder: "Enter detailed description"
    },
    model: {
      value: _vm.task_form.intention,
      callback: function callback($$v) {
        _vm.$set(_vm.task_form, "intention", $$v);
      },
      expression: "task_form.intention"
    }
  })], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.addTaskDialog = false;
      }
    }
  }, [_vm._v("Cancel")]), _vm._v(" "), !_vm.isTaskModalEditMode ? _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.addTask
    }
  }, [_vm._v("Add")]) : _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.updateTask
    }
  }, [_vm._v("Update")])], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;