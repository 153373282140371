"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    staticClass: "text-center",
    staticStyle: {
      "border-right": "dotted gray 1px",
      "min-height": "280pt"
    },
    attrs: {
      xs: {
        span: 24
      },
      sm: {
        span: 24
      },
      md: {
        span: 10
      },
      lg: {
        span: 8
      },
      xl: {
        span: 5
      }
    }
  }, [_c("div", [_c("transition", {
    attrs: {
      name: "fade-transform",
      mode: "out-in"
    }
  }, [_c("div", {
    staticStyle: {
      "margin-bottom": "40px"
    }
  }, [_c("h3", {
    staticClass: "text-left ml-20",
    staticStyle: {
      display: "block",
      "font-size": "1.17em",
      "margin-block-start": "1em",
      "margin-block-end": "1em",
      "margin-inline-start": "0px",
      "margin-inline-end": "0px",
      "font-weight": "bold",
      "unicode-bidi": "isolate"
    }
  }, [_vm._v("New, Vary or Clone?")]), _vm._v(" "), _c("div", {
    staticClass: "text-left ml-20",
    staticStyle: {
      "font-size": "12px"
    }
  }, [_c("el-row", {
    staticClass: "mt-5"
  }, [_c("el-col", {
    attrs: {
      xs: {
        span: 24
      },
      sm: {
        span: 12
      },
      md: {
        span: 12
      },
      lg: {
        span: 10
      },
      xl: {
        span: 7
      }
    }
  }, [_c("el-popover", {
    ref: "newPop",
    attrs: {
      placement: "right",
      width: "300",
      trigger: "hover",
      content: "Create a new offering."
    }
  }, [_c("div", {
    staticClass: "popover-heading"
  }, [_c("strong", [_vm._v("Create New Offering")])]), _vm._v(" "), _c("div", {
    staticClass: "popover-content"
  }, [_vm._v("Link a "), _c("b", [_vm._v("New Offering")]), _vm._v(" to a customer journey.  Or, create an "), _c("b", [_vm._v("Enabling Offering")]), _vm._v(" not linked to a journey.")])]), _vm._v(" "), _c("el-button", {
    directives: [{
      name: "popover",
      rawName: "v-popover:newPop",
      arg: "newPop"
    }],
    staticStyle: {
      width: "120px"
    },
    attrs: {
      type: "secondary",
      size: "micro"
    },
    on: {
      click: function click($event) {
        return _vm.createManual();
      }
    }
  }, [_vm._v("\n                    New Offering\n                  ")])], 1)], 1), _vm._v(" "), _c("el-row", {
    staticClass: "mt-5"
  }, [_c("el-col", {
    attrs: {
      xs: {
        span: 24
      },
      sm: {
        span: 12
      },
      md: {
        span: 12
      },
      lg: {
        span: 10
      },
      xl: {
        span: 7
      }
    }
  }, [_c("el-popover", {
    ref: "varyPop",
    attrs: {
      placement: "right",
      width: "300",
      trigger: "hover",
      content: "Vary"
    }
  }, [_c("div", {
    staticClass: "popover-heading"
  }, [_c("strong", [_vm._v("Create a Variant")])]), _vm._v(" "), _c("div", {
    staticClass: "popover-content"
  }, [_vm._v("Vary an existing offering to give additional or reduced functionality (freemium, premium etc). The variant is linked to same customer type as the primary offering.")])]), _vm._v(" "), _c("el-button", {
    directives: [{
      name: "popover",
      rawName: "v-popover:varyPop",
      arg: "varyPop"
    }],
    staticStyle: {
      width: "120px"
    },
    attrs: {
      type: "secondary",
      size: "micro",
      autofocus: false
    },
    on: {
      click: function click($event) {
        return _vm.createVariant();
      }
    }
  }, [_vm._v("\n                    Vary Existing\n                  ")])], 1)], 1), _vm._v(" "), _c("el-row", {
    staticClass: "mt-5"
  }, [_c("el-col", {
    attrs: {
      xs: {
        span: 24
      },
      sm: {
        span: 12
      },
      md: {
        span: 12
      },
      lg: {
        span: 10
      },
      xl: {
        span: 7
      }
    }
  }, [_c("el-popover", {
    ref: "clonePop",
    attrs: {
      placement: "right",
      width: "300",
      trigger: "hover",
      content: "Clone"
    }
  }, [_c("div", {
    staticClass: "popover-heading"
  }, [_c("strong", [_vm._v("Create a Duplicate")])]), _vm._v(" "), _c("div", {
    staticClass: "popover-content"
  }, [_vm._v("Duplicate an offering to another customer type, or none at all (As an enabling offering).\n\n                      "), _c("ul", [_c("li", [_vm._v("Copying creates a full copy an existing offering/.")]), _vm._v(" "), _c("li", [_vm._v("Changes are made to the copied offering only.")])])])]), _vm._v(" "), _c("el-button", {
    directives: [{
      name: "popover",
      rawName: "v-popover:clonePop",
      arg: "clonePop"
    }],
    staticStyle: {
      width: "120px"
    },
    attrs: {
      type: "secondary",
      size: "micro"
    },
    on: {
      click: function click($event) {
        return _vm.copyOffering();
      }
    }
  }, [_vm._v("\n                    Duplicate\n                  ")])], 1)], 1)], 1)])])], 1)]), _vm._v(" "), _c("el-col", {
    staticClass: "text-left",
    attrs: {
      xs: {
        span: 24
      },
      sm: {
        span: 24
      },
      md: {
        span: 14
      },
      lg: {
        span: 16
      },
      xl: {
        span: 19
      }
    }
  }, [_c("transition-group", {
    attrs: {
      name: "fade-transform",
      mode: "out-in"
    }
  }, [_vm.createOption === "manual" ? _c("el-form", {
    key: "1",
    ref: "addOfferingForm",
    staticClass: "offering_form",
    attrs: {
      model: _vm.offering_form,
      "label-width": "180px",
      rules: _vm.addOfferingRules,
      "label-position": "left"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Name",
      prop: "title"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.offering_form.title,
      callback: function callback($$v) {
        _vm.$set(_vm.offering_form, "title", $$v);
      },
      expression: "offering_form.title"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Details",
      prop: "description"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      size: "mini"
    },
    model: {
      value: _vm.offering_form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.offering_form, "description", $$v);
      },
      expression: "offering_form.description"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Offering Type",
      prop: "offeringtype"
    }
  }, [_c("el-select", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticStyle: {
      padding: "0"
    },
    attrs: {
      size: "mini",
      placeholder: "Type to search",
      disabled: _vm.isVariant || _vm.customer_types.length === 0
    },
    model: {
      value: _vm.offering_form.offeringtype,
      callback: function callback($$v) {
        _vm.$set(_vm.offering_form, "offeringtype", $$v);
      },
      expression: "offering_form.offeringtype"
    }
  }, [_c("el-option", {
    attrs: {
      label: "Journey Driven",
      value: "Journey Driven"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "No Journey",
      value: "No Journey"
    }
  })], 1)], 1), _vm._v(" "), _vm.offering_form.offeringtype === "Journey Driven" ? _c("el-form-item", {
    attrs: {
      label: "Linked Customer Type",
      prop: "customerTypeId"
    }
  }, [_c("el-select", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticStyle: {
      padding: "0"
    },
    attrs: {
      size: "mini",
      placeholder: "Type to search",
      disabled: _vm.isVariant
    },
    on: {
      change: _vm.checkIfPrimaryOffering
    },
    model: {
      value: _vm.offering_form.customerTypeId,
      callback: function callback($$v) {
        _vm.$set(_vm.offering_form, "customerTypeId", $$v);
      },
      expression: "offering_form.customerTypeId"
    }
  }, _vm._l(_vm.groupedCustomerTypes, function (group) {
    return _c("el-option-group", {
      key: group.label,
      attrs: {
        label: group.label
      }
    }, _vm._l(group.options, function (customer_type, index) {
      return _c("el-option", {
        key: index,
        attrs: {
          label: _vm.trimString(customer_type.title, 20),
          value: customer_type.id
        }
      });
    }), 1);
  }), 1)], 1) : _vm._e(), _vm._v(" "), _c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.offering_form.offeringtype == "Journey Driven" && _vm.checkIfVariantVisible() ? _c("el-form-item", {
    attrs: {
      label: "Variant?",
      prop: "offeringcategory"
    }
  }, [_c("el-radio-group", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      size: "mini",
      disabled: _vm.isVariant
    },
    model: {
      value: _vm.offering_form.offeringcategory,
      callback: function callback($$v) {
        _vm.$set(_vm.offering_form, "offeringcategory", $$v);
      },
      expression: "offering_form.offeringcategory"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: "Primary"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Secondary"
    }
  })], 1)], 1) : _vm._e()], 1), _vm._v(" "), _c("el-form-item", [_c("el-button-group", {
    staticClass: "fr",
    attrs: {
      size: "mini"
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.createOffering
    }
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "create"
    }
  }), _vm._v(" Create")], 1), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini",
      icon: "el-icon-close"
    },
    on: {
      click: _vm.closeDialog
    }
  }, [_vm._v("Cancel")])], 1)], 1)], 1) : _vm._e(), _vm._v(" "), _vm.createOption === "variant" ? _c("el-row", {
    key: "2",
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    staticStyle: {
      "margin-top": "15pt",
      "margin-right": "30pt"
    },
    attrs: {
      span: 11
    }
  }, [_c("el-form", {
    ref: "addVariantOfferingForm",
    staticClass: "offering_form",
    attrs: {
      model: _vm.offering_variant_form,
      "label-width": "",
      "label-position": "left",
      rules: _vm.variantOfferingRules
    }
  }, [_c("el-form-item", {
    staticStyle: {
      "min-height": "60pt"
    },
    attrs: {
      label: "Choose Offering to vary",
      prop: "offering_id"
    }
  }, [_c("el-select", {
    staticStyle: {
      "padding-left": "20px"
    },
    attrs: {
      placeholder: "Select Offering",
      size: "mini"
    },
    on: {
      change: _vm.onVariantOfferingChange
    },
    model: {
      value: _vm.offering_variant_form.offering_id,
      callback: function callback($$v) {
        _vm.$set(_vm.offering_variant_form, "offering_id", $$v);
      },
      expression: "offering_variant_form.offering_id"
    }
  }, _vm._l(_vm.offerings, function (offering) {
    return _c("el-option", {
      key: offering.id,
      attrs: {
        label: _vm.trimString(offering.title, 20),
        value: offering.id
      }
    }, [_c("el-tooltip", {
      staticClass: "item",
      attrs: {
        disabled: offering.title.length < 20,
        effect: "dark",
        content: offering.title,
        placement: "top"
      }
    }, [_c("span", {
      staticStyle: {
        float: "left"
      }
    }, [_vm._v(_vm._s(_vm.trimString(offering.title, 20)))])]), _vm._v(" "), _c("span", {
      staticClass: "offering-category",
      staticStyle: {
        float: "right",
        color: "#8492a6",
        "font-size": "11px"
      }
    }, [_c("el-tooltip", {
      staticClass: "item",
      attrs: {
        effect: "dark",
        content: "Cloned offering",
        placement: "top"
      }
    }, [offering.iscloned ? _c("svg-icon", {
      staticStyle: {
        color: "black"
      },
      attrs: {
        "icon-class": "clone"
      }
    }) : _vm._e()], 1), _vm._v(" "), _c("el-tooltip", {
      staticClass: "item",
      attrs: {
        effect: "dark",
        content: "Primary offering",
        placement: "top"
      }
    }, [offering.offeringcategory === "Primary" ? _c("svg-icon", {
      staticStyle: {
        color: "black"
      },
      attrs: {
        "icon-class": "inherit-primary"
      }
    }) : _vm._e()], 1), _vm._v(" "), _c("el-tooltip", {
      staticClass: "item",
      attrs: {
        effect: "dark",
        content: "Secondary offering",
        placement: "top"
      }
    }, [offering.offeringcategory === "Secondary" ? _c("svg-icon", {
      staticStyle: {
        color: "#999999"
      },
      attrs: {
        "icon-class": "inherit-secondary"
      }
    }) : _vm._e()], 1)], 1)], 1);
  }), 1)], 1), _vm._v(" "), _c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.selectedOffering.id ? _c("el-form-item", {
    key: "14"
  }, [_c("el-divider"), _vm._v(" "), _c("el-button-group", {
    attrs: {
      size: "mini"
    }
  }, [_c("el-button", {
    staticStyle: {
      padding: "2.5pt 10pt 3pt 6pt",
      "vertical-align": "middle"
    },
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.createVariantOffering
    }
  }, [_c("svg-icon", {
    staticStyle: {
      color: "white",
      height: "14pt",
      width: "14pt",
      padding: "-6pt"
    },
    attrs: {
      "icon-class": "inherit-secondary-dyn"
    }
  }), _vm._v(" Create Variant")], 1), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini",
      icon: "el-icon-close"
    },
    on: {
      click: _vm.closeDialog
    }
  }, [_vm._v("Cancel")])], 1)], 1) : _vm._e()], 1)], 1)], 1), _vm._v(" "), _vm.selectedOffering && _vm.selectedOffering.linkedCustomerTypes ? _c("el-col", {
    staticClass: "p-5 mt-10",
    staticStyle: {
      "background-color": "ghostwhite",
      "border-radius": "5pt",
      "min-height": "50pt",
      border: "solid 0.5pt lightgrey"
    },
    attrs: {
      span: 11
    }
  }, [_vm.selectedOffering && _vm.selectedOffering.linkedCustomerTypes && _vm.selectedOffering.linkedCustomerTypes.length > 0 ? _c("table", {
    staticStyle: {
      "word-break": "keep-all",
      color: "lightslategrey"
    },
    attrs: {
      id: "offering_variant_customer_details"
    }
  }, [_c("tr", [_c("th", {
    staticClass: "text-left",
    staticStyle: {
      border: "none",
      "background-color": "ghostwhite"
    },
    attrs: {
      colspan: "2"
    }
  }, [_c("span", {
    staticStyle: {
      "font-size": "1.15em",
      "font-weight": "500",
      color: "darkslategrey"
    }
  }, [_vm._v("Related Customer Type")])])]), _vm._v(" "), _c("tr", {
    staticStyle: {
      "font-size": "9pt"
    }
  }, [_c("th", {
    attrs: {
      width: "80"
    }
  }, [_vm._v("Name")]), _vm._v(" "), _c("td", {
    staticStyle: {
      "background-color": "white"
    }
  }, [_vm._v(_vm._s(_vm.selectedOffering.linkedCustomerTypes[0].customerType.title))])]), _vm._v(" "), _c("tr", {
    staticStyle: {
      "font-size": "9pt"
    }
  }, [_c("th", {
    attrs: {
      width: "80"
    }
  }, [_vm._v("Description")]), _vm._v(" "), _c("td", {
    staticStyle: {
      "background-color": "white"
    }
  }, [_vm._v(_vm._s(_vm.selectedOffering.linkedCustomerTypes[0].customerType.description))])])]) : _vm._e(), _vm._v(" "), _vm.selectedOffering && _vm.selectedOffering.linkedCustomerTypes && _vm.selectedOffering.linkedCustomerTypes.length === 0 ? _c("div", {
    staticStyle: {
      color: "darkred",
      "padding-top": "10pt",
      "padding-left": "5pt"
    }
  }, [_c("span", [_c("strong", [_vm._v("Error")]), _vm._v(": This offering should not exist, contact support.")])]) : _vm._e()]) : _vm._e()], 1) : _c("div", {
    key: "3"
  }, [_vm.offering_clone_form.id && _vm.offerings.length === 0 && !_vm.loading ? _c("div", [_vm._v("This customer does not have any offering to copy from. Create new offering")]) : _vm.createOption === "copy" && _vm.offerings.length > 0 ? _c("el-form", {
    ref: "cloneOfferingForm",
    staticClass: "offering_form",
    attrs: {
      model: _vm.offering_clone_form,
      rules: _vm.cloneOfferingRules,
      "label-width": "180px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Existing Offering",
      prop: "offering_id"
    }
  }, [_c("el-select", {
    staticStyle: {
      "padding-left": "20px"
    },
    attrs: {
      placeholder: "Select Offering",
      size: "mini"
    },
    on: {
      change: _vm.onCopyOfferingChange
    },
    model: {
      value: _vm.offering_clone_form.offering_id,
      callback: function callback($$v) {
        _vm.$set(_vm.offering_clone_form, "offering_id", $$v);
      },
      expression: "offering_clone_form.offering_id"
    }
  }, _vm._l(_vm.offerings, function (offering) {
    return _c("el-option", {
      key: offering.id,
      attrs: {
        label: _vm.trimString(offering.title, 20),
        value: offering.id
      }
    }, [_c("el-tooltip", {
      staticClass: "item",
      attrs: {
        disabled: offering.title.length < 20,
        effect: "dark",
        content: offering.title,
        placement: "top"
      }
    }, [_c("span", {
      staticStyle: {
        float: "left"
      }
    }, [_vm._v(_vm._s(_vm.trimString(offering.title, 20)))])]), _vm._v(" "), _c("span", {
      staticClass: "offering-category",
      staticStyle: {
        float: "right",
        color: "#8492a6",
        "font-size": "11px"
      }
    }, [_c("el-tooltip", {
      staticClass: "item",
      attrs: {
        effect: "dark",
        content: "Cloned offering",
        placement: "top"
      }
    }, [offering.iscloned ? _c("svg-icon", {
      staticStyle: {
        color: "black"
      },
      attrs: {
        "icon-class": "clone"
      }
    }) : _vm._e()], 1), _vm._v(" "), _c("el-tooltip", {
      staticClass: "item",
      attrs: {
        effect: "dark",
        content: "Primary offering",
        placement: "top"
      }
    }, [offering.offeringcategory === "Primary" ? _c("svg-icon", {
      staticStyle: {
        color: "black"
      },
      attrs: {
        "icon-class": "inherit-primary"
      }
    }) : _vm._e()], 1), _vm._v(" "), _c("el-tooltip", {
      staticClass: "item",
      attrs: {
        effect: "dark",
        content: "Secondary offering",
        placement: "top"
      }
    }, [offering.offeringcategory === "Secondary" ? _c("svg-icon", {
      staticStyle: {
        color: "#999999"
      },
      attrs: {
        "icon-class": "inherit-secondary"
      }
    }) : _vm._e()], 1)], 1)], 1);
  }), 1)], 1), _vm._v(" "), _c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.offering_clone_form.offering_id !== "" ? _c("el-form-item", {
    attrs: {
      label: "Customer Type",
      prop: "customerTypeId"
    }
  }, [_c("el-select", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticStyle: {
      "padding-left": "20px"
    },
    attrs: {
      size: "mini",
      placeholder: "Type to search"
    },
    model: {
      value: _vm.offering_clone_form.customerTypeId,
      callback: function callback($$v) {
        _vm.$set(_vm.offering_clone_form, "customerTypeId", $$v);
      },
      expression: "offering_clone_form.customerTypeId"
    }
  }, _vm._l(_vm.filtered_customer_types, function (group) {
    return _c("el-option-group", {
      key: group.label,
      attrs: {
        label: group.label
      }
    }, _vm._l(group.options, function (customer_type, index) {
      return _c("el-option", {
        key: index,
        attrs: {
          label: _vm.trimString(customer_type.title, 20),
          value: customer_type.id
        }
      });
    }), 1);
  }), 1)], 1) : _vm._e()], 1), _vm._v(" "), _c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.offering_clone_form.offering_id !== "" ? _c("el-form-item", [_c("div", {
    staticClass: "text-right"
  }, [_c("el-button", {
    staticClass: "ml-20 mt-10",
    attrs: {
      type: "primary",
      icon: _vm.loading ? "el-icon-loading" : "el-icon-document-copy",
      size: "mini",
      disabled: _vm.offering_clone_form.offering_id === ""
    },
    on: {
      click: _vm.copyJourneyMap
    }
  }, [_vm._v("Duplicate")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini",
      icon: "el-icon-close"
    },
    on: {
      click: _vm.closeDialog
    }
  }, [_vm._v("Cancel")])], 1)]) : _vm._e()], 1)], 1) : _vm._e()], 1)], 1)], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;