"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _createForOfIteratorHelper2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/createForOfIteratorHelper.js"));
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.regexp.split");
var _taggedTemplateLiteral2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/taggedTemplateLiteral.js"));
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _objectSpread2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/objectSpread2.js"));
require("core-js/modules/es6.number.constructor");
var _vuex = require("vuex");
var _templateObject;
var _default2 = exports.default = {
  name: 'Product',
  components: {},
  props: {
    offering: {
      type: Object,
      required: true,
      default: function _default() {
        return {
          id: '',
          title: '',
          description: '',
          offeringcategory: '',
          offeringcategorydesc: '',
          additionalattributes: {},
          linkedProducts: [],
          linkedServices: []
        };
      }
    },
    product: {
      type: Object,
      required: true,
      default: function _default() {
        return {
          id: '',
          title: '',
          description: ''
        };
      }
    },
    index: {
      type: Number,
      required: true,
      default: 0
    },
    x: {
      type: Number,
      required: true,
      default: 0
    },
    y: {
      type: Number,
      required: true,
      default: 0
    },
    width: {
      type: Number,
      required: true,
      default: 0
    },
    height: {
      type: Number,
      required: true,
      default: 0
    },
    recompute: {
      type: Boolean,
      required: true,
      default: false
    },
    recomputeAndUpdate: {
      type: Boolean,
      required: true,
      default: false
    },
    isUnmappedVisible: {
      type: Boolean,
      default: true
    },
    relations: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    journeyMap: {
      type: Object,
      required: true,
      default: function _default() {
        return {
          id: '',
          title: '',
          description: '',
          additionalattributes: {},
          linkedJourneyExperiences: [],
          linkedJourneyPhases: [],
          linkedJourneySteps: []
        };
      }
    }
  },
  data: function data() {
    return {
      phaseX: [],
      feature_pos_x: [],
      feature_pos_y: [],
      feature_initial_drag_pos: {
        x: 0,
        y: 0
      },
      tools: {
        feature: {
          x: 25,
          y: 0
        },
        risk: {
          x: 75,
          y: 0
        },
        idea: {
          x: 128,
          y: 0
        }
      },
      drop_hover_grid: {
        x: -100,
        y: -100,
        fill: '#4096EE80',
        stroke: '#4096EE',
        hover: false
      },
      feature: {
        drag: false,
        hover: false,
        index: 0,
        feature_index: 0,
        product_id: null,
        offering_id: null,
        step_id: null
      }
    };
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['modules'])), {}, {
    isMinimized: function isMinimized() {
      for (var i = 0; i < this.offering.additionalattributes.entitiesSortOrder.length; i++) {
        if (this.offering.additionalattributes.entitiesSortOrder[i].id === this.product.id) {
          return this.offering.additionalattributes.entitiesSortOrder[i].minimized;
        }
      }
      return false;
    },
    sortedStages: function sortedStages() {
      var stagesSortOrder = [];
      if (this.journeyMap.additionalattributes && this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages.length > 0 && this.journeyMap.linkedJourneyPhases.length > 0) {
        for (var i = 0; i < this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages.length; i++) {
          for (var j = 0; j < this.journeyMap.linkedJourneyPhases.length; j++) {
            if (this.journeyMap.linkedJourneyPhases[j].journeyPhase.id === this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[i].id && !this.journeyMap.linkedJourneyPhases[j].journeyPhase.isDeleted) {
              stagesSortOrder.push(this.journeyMap.linkedJourneyPhases[j]);
              break;
            }
          }
        }
      } else if (this.journeyMap.linkedJourneyPhases) {
        for (var k = 0; k < this.journeyMap.linkedJourneyPhases.length; k++) {
          if (!this.journeyMap.linkedJourneyPhases[j].journeyPhase.hasOwnProperty('isDeleted')) {
            stagesSortOrder.push(this.journeyMap.linkedJourneyPhases[j]);
          } else if (this.journeyMap.linkedJourneyPhases[j].journeyPhase.hasOwnProperty('isDeleted') && this.journeyMap.linkedJourneyPhases[j].journeyPhase.isDeleted === false) {
            stagesSortOrder.push(this.journeyMap.linkedJourneyPhases[j]);
            break;
          }
        }
      }
      return stagesSortOrder;
    },
    productHasUnmappedFeatures: function productHasUnmappedFeatures() {
      var has_unmapped = false;
      if (!this.isUnmappedVisible) {
        return has_unmapped;
      }
      for (var i = 0; i < this.offering.additionalattributes.entitiesSortOrder.length; i++) {
        if (this.offering.additionalattributes.entitiesSortOrder[i].id === this.product.id) {
          for (var j = 0; j < this.offering.additionalattributes.entitiesSortOrder[i].features.length; j++) {
            if (this.offering.additionalattributes.entitiesSortOrder[i].features[j].unmapped) {
              return true;
            }
          }
        }
      }
      return has_unmapped;
    },
    productHasEnablerFeatures: function productHasEnablerFeatures() {
      for (var i = 0; i < this.offering.additionalattributes.entitiesSortOrder.length; i++) {
        if (this.offering.additionalattributes.entitiesSortOrder[i].id === this.product.id) {
          for (var j = 0; j < this.offering.additionalattributes.entitiesSortOrder[i].features.length; j++) {
            if (this.offering.additionalattributes.entitiesSortOrder[i].features[j].enabler) {
              return true;
            }
          }
        }
      }
      return false;
    },
    computeTotalGridLanes: function computeTotalGridLanes() {
      if (this.productHasUnmappedFeatures && this.productHasEnablerFeatures) {
        return 5;
      }
      if (this.productHasUnmappedFeatures && !this.productHasEnablerFeatures) {
        return 4;
      }
      if (!this.productHasUnmappedFeatures && this.productHasEnablerFeatures) {
        return 4;
      }
      return 3;
    }
  }),
  watch: {
    'product.linkedFeatures': {
      handler: function handler(newValue) {
        if (!this.feature.drag) {
          this.computePhaseX();
          this.computeFeatureX();
          this.computeFeatureY();
          this.computeToolPos();
        }
      },
      deep: false
    },
    recompute: function recompute(val) {
      if (!this.feature.drag) {
        this.computePhaseX();
        this.computeFeatureX();
        this.computeFeatureY();
        this.computeToolPos();
      }
    },
    recomputeAndUpdate: function recomputeAndUpdate(val) {
      if (!this.feature.drag) {
        this.computePhaseX();
        this.computeFeatureX();
        this.computeFeatureY();
        this.computeToolPos();
      }

      /* for (var j = 0; j < this.product.linkedFeatures.length; j++) {
        this.updateFeatureLocation(this.product.linkedFeatures[j].productFeature, this.feature_pos_x[j], this.feature_pos_y[j], true)
      }*/
    }
  },
  created: function created() {},
  mounted: function mounted() {
    this.computePhaseX();
    this.computeToolPos();
    this.computeFeatureX();
    this.computeFeatureY();
  },
  methods: {
    isVisible: function isVisible(feature) {
      if (!this.offering.additionalattributes.entityLane.isUnmappedVisible && this.isUnmapped(feature.productFeature.id) && !this.isEnabler(feature.productFeature.id)) {
        return false;
      }
      return true;
    },
    moveUpEntitySortOrder: function moveUpEntitySortOrder() {
      this.$emit('move-entity-up', this.product.id);
    },
    moveDownEntitySortOrder: function moveDownEntitySortOrder() {
      this.$emit('move-entity-down', this.product.id);
    },
    hasPermissionForModule: function hasPermissionForModule(entity) {
      return this.modules.includes(entity);
    },
    computePhaseX: function computePhaseX() {
      this.phaseX = [];
      if (!this.sortedStages) {
        return this.phaseX;
      }
      for (var i = 0; i < this.sortedStages.length; i++) {
        if (i === 0) {
          this.phaseX.push(175);
        } else {
          var length = 0;
          for (var j = 0; j < i; j++) {
            length += this.computePhaseLength(this.sortedStages[j].journeyPhase, j);
            length += 50;
          }
          this.phaseX.push(175 + length);
        }
      }
    },
    computePhaseLength: function computePhaseLength(phase, index) {
      // get max number of features in product for given phase
      // if less than 2 use default 175, else use length off features
      var max_count = 0;

      // Get an array of pains/gains for current phase
      if (typeof phase === 'undefined') {
        return 225;
      }
      for (var i = 0; i < this.journeyMap.additionalattributes.relations.length; i++) {
        if (this.journeyMap.additionalattributes.relations[i].start_type === 'Phase' && this.journeyMap.additionalattributes.relations[i].end_type === 'Step' && this.journeyMap.additionalattributes.relations[i].start_id === phase.id) {
          max_count++;
        }
      }
      var additional_columns = Math.floor(max_count / 7) - 1;
      var first_column = 150;
      return 225 + first_column + additional_columns * 150;
    },
    computeFeatureStrokeColor: function computeFeatureStrokeColor(feature) {
      if (this.isEnabler(feature.productFeature.id)) {
        return '#304174';
      } else if (this.isUnmapped(feature.productFeature.id)) {
        return '#F56C6C';
      } else {
        return '#304174';
      }
    },
    computeFeatureStrokeDasharray: function computeFeatureStrokeDasharray(feature) {
      if (this.isEnabler(feature.productFeature.id)) {
        return '2';
      } else if (this.isUnmapped(feature.productFeature.id)) {
        return '0';
      } else {
        return '0';
      }
    },
    updateFeatureLocation: function updateFeatureLocation(feature, pos_x, pos_y) {
      var _this = this;
      var silent = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
      var additionalattributes = feature.additionalattributes;
      additionalattributes.posX = pos_x;
      additionalattributes.posY = pos_y;
      this.$apollo.mutate({
        // Query
        mutation: window._gql(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["mutation updateProductFeature($productFeature: UpdateProductFeatureInput!)\n        {\n          updateProductFeature(input: $productFeature) {\n            id\n          }\n        }"]))),
        // Parameters
        variables: {
          'productFeature': {
            'id': feature.id,
            'additionalattributes': additionalattributes
          }
        }
      }).then(function (data) {
        if (!silent) {
          _this.$notify({
            title: 'Success',
            message: 'Updated Successfully',
            type: 'success',
            duration: 2000
          });
        }
      }).catch(function (error) {
        // Error
        if (!silent) {
          _this.$notify({
            title: 'Error',
            message: error,
            type: 'error',
            duration: 2000
          });
        }
      });
    },
    generateUniqueFeatureID: function generateUniqueFeatureID(feature_index) {
      return 'feature_' + this.index + '_' + feature_index;
    },
    generateUniqueProductToolID: function generateUniqueProductToolID(product_index) {
      return 'product_tool_' + product_index;
    },
    generateDiamondPath: function generateDiamondPath(x, y) {
      var x1 = x + 1;
      var y1 = y + 1;
      var x2 = x + 2;
      var y2 = y + 2;
      return 'M ' + x1 + ' ' + y + ' L ' + x + ' ' + y1 + ' L ' + x1 + ' ' + y2 + ' L ' + x2 + ' ' + y1 + ' Z';
    },
    trimString: function trimString(string, length) {
      if (string.length > length) {
        return string.substring(0, length) + '...';
      } else {
        return string;
      }
    },
    trimFeatureString: function trimFeatureString(string, length) {
      var trimString = [];
      trimString[0] = string.substring(0, length / 2);
      var trimLength = Math.min(trimString[0].length, trimString[0].lastIndexOf(' '));
      trimString[0] = string.substring(0, trimLength);
      trimString[1] = string.substring(trimLength, string.length);
      if (trimString[1].length > length / 2) {
        trimString[1] = trimString[1].substring(0, length / 2) + '...';
      }
      return trimString;
    },
    resizeProductSwimlane: function resizeProductSwimlane(type) {
      this.$emit('toggle', this.index);
    },
    computeFeaturePosX: function computeFeaturePosX(feature_index, feature_id) {
      if (this.offering.additionalattributes.entitiesSortOrder && this.offering.additionalattributes.entitiesSortOrder.length > 0) {
        for (var i = 0; i < this.offering.additionalattributes.entitiesSortOrder.length; i++) {
          if (this.offering.additionalattributes.entitiesSortOrder[i].id === this.product.id) {
            for (var j = 0; j < this.offering.additionalattributes.entitiesSortOrder[i].features.length; j++) {
              if (this.offering.additionalattributes.entitiesSortOrder[i].features[j].id === feature_id) {
                return this.offering.additionalattributes.entitiesSortOrder[i].features[j].posX;
              }
            }
          }
        }
      }
      if (this.feature_pos_x.length === 0) {
        this.computeFeatureX();
      }
      return this.feature_pos_x[feature_index];
    },
    computeProductEnablerIndex: function computeProductEnablerIndex(index) {
      var count = 0;
      for (var j = 0; j < index; j++) {
        if (this.product.linkedFeatures[j].productFeature.enabler) {
          count++;
        }
      }
      return count;
    },
    computeProductUnmappedIndex: function computeProductUnmappedIndex(index) {
      var count = 0;
      for (var j = 0; j < index; j++) {
        if (this.isUnmapped(this.product.linkedFeatures[j].productFeature.id)) {
          count++;
        }
        if (!this.hasFeatureRelation(this.product.linkedFeatures[j].productFeature.id)) {
          count++;
        }
      }
      return count;
    },
    computeProductEnablerParentX: function computeProductEnablerParentX(feature_id) {
      for (var i = 0; i < this.relations.length; i++) {
        if (this.relations[i].start_id === feature_id) {
          for (var j = 0; j < this.relations.length; j++) {
            if (this.relations[i].end_id === this.relations[j].end_id && this.relations[j].end_type === 'Feature') {
              return this.computePhaseXFromStepID(this.relations[j].start_id);
            }
          }
        }
      }
      return 0;
    },
    // Compute index of feature by filtering out unmapped and enabled features.
    computeFilteredProducFeatureIndex: function computeFilteredProducFeatureIndex(index) {
      var temp_feature_ids = [];
      for (var j = 0; j < this.product.linkedFeatures.length; j++) {
        if (!this.isUnmapped(this.product.linkedFeatures[j].productFeature.id) && !this.isEnabler(this.product.linkedFeatures[j].productFeature.id)) {
          temp_feature_ids.push(this.product.linkedFeatures[j].productFeature.id);
        }
      }
      return temp_feature_ids.indexOf(this.product.linkedFeatures[index].productFeature.id);
    },
    computeFeatureX: function computeFeatureX() {
      this.feature_pos_x = [];
      if (this.offering.additionalattributes.entitiesSortOrder && this.offering.additionalattributes.entitiesSortOrder.length > 0) {
        for (var i = 0; i < this.offering.additionalattributes.entitiesSortOrder.length; i++) {
          if (this.offering.additionalattributes.entitiesSortOrder[i].id === this.product.id) {
            for (var j = 0; j < this.offering.additionalattributes.entitiesSortOrder[i].features.length; j++) {
              for (var k = 0; k < this.product.linkedFeatures.length; k++) {
                if (this.offering.additionalattributes.entitiesSortOrder[i].features[j].id === this.product.linkedFeatures[k].productFeature.id) {
                  this.feature_pos_x[k] = this.offering.additionalattributes.entitiesSortOrder[i].features[j].posX;
                }
              }
            }
          }
        }
      }
      /* for (var j = 0; j < this.product.linkedFeatures.length; j++) {
        if (this.product.additionalattributes.minimized) {
          this.feature_pos_x[j] = this.x + (j % 100) * 30 + 168
        } else {
          if (this.product.linkedFeatures[j].productFeature.relations.steps[0].id === null && !this.hasFeatureRelation(this.product.linkedFeatures[j].productFeature.id)) {
            this.feature_pos_x[j] = ((this.computeProductUnmappedIndex(j) % 50) * 50) + 100
          } else if (this.product.linkedFeatures[j].productFeature.enabler) {
            this.feature_pos_x[j] = this.computeProductEnablerParentX(this.product.linkedFeatures[j].productFeature.id) + (this.computeProductEnablerIndex(j) % 50) * 50
          } else {
            this.feature_pos_x[j] = this.computePhaseXFromStepID(this.product.linkedFeatures[j].productFeature.relations.steps[0].id) + (this.computeFilteredProducFeatureIndex(j) % 50) * 50
          }
        }
      }*/
    },
    computeFeaturePosY: function computeFeaturePosY(feature_index, feature_id) {
      if (this.offering.additionalattributes.entitiesSortOrder && this.offering.additionalattributes.entitiesSortOrder.length > 0) {
        for (var i = 0; i < this.offering.additionalattributes.entitiesSortOrder.length; i++) {
          if (this.offering.additionalattributes.entitiesSortOrder[i].id === this.product.id) {
            for (var j = 0; j < this.offering.additionalattributes.entitiesSortOrder[i].features.length; j++) {
              if (this.offering.additionalattributes.entitiesSortOrder[i].features[j].id === feature_id) {
                return this.offering.additionalattributes.entitiesSortOrder[i].features[j].posY;
              }
            }
          }
        }
      }
      if (this.feature_pos_y.length === 0) {
        this.computeFeatureY();
      }
      return this.feature_pos_y[feature_index];
    },
    computeFeatureY: function computeFeatureY() {
      this.feature_pos_y = [];
      if (this.offering.additionalattributes.entitiesSortOrder && this.offering.additionalattributes.entitiesSortOrder.length > 0) {
        for (var i = 0; i < this.offering.additionalattributes.entitiesSortOrder.length; i++) {
          if (this.offering.additionalattributes.entitiesSortOrder[i].id === this.product.id) {
            for (var j = 0; j < this.offering.additionalattributes.entitiesSortOrder[i].features.length; j++) {
              for (var k = 0; k < this.product.linkedFeatures.length; k++) {
                if (this.offering.additionalattributes.entitiesSortOrder[i].features[j].id === this.product.linkedFeatures[k].productFeature.id) {
                  this.feature_pos_y[k] = this.offering.additionalattributes.entitiesSortOrder[i].features[j].posY;
                }
              }
            }
          }
        }
      }

      /* for (var j = 0; j < this.product.linkedFeatures.length; j++) {
        if (this.product.additionalattributes.minimized) {
          this.feature_pos_y[j] = this.y + 5
        } else {
          if (this.product.linkedFeatures[j].productFeature.enabler) {
            this.feature_pos_y[j] = this.y + 102
          } else if (this.product.linkedFeatures[j].productFeature.relations.steps[0].id === null) {
            this.feature_pos_y[j] = this.y + (this.productHasEnablerFeatures ? 152 : 102)
          } else {
            this.feature_pos_y[j] = this.y + 7
          }
        }
      }*/
    },
    computeFeatureIconStyle: function computeFeatureIconStyle(feature_index) {
      if (this.isMinimized) {
        if (this.feature.hover && this.feature.index === this.index && this.feature.feature_index === feature_index) {
          return 'font-size: 25px; color:#FFF;filter: drop-shadow(1px 1px 10px #304174);border-radius:5px;cursor:pointer;';
        } else {
          return 'font-size: 25px; color:#FFF;filter: drop-shadow(1px 1px 10px #FFF);border-radius:5px;';
        }
      } else {
        if (this.feature.hover && this.feature.index === this.index && this.feature.feature_index === feature_index) {
          return 'font-size: 25px; color:#FFF;filter: drop-shadow(1px 1px 10px #304174);border-radius:5px;cursor:pointer;';
        } else {
          if (this.isUnmapped(this.product.linkedFeatures[feature_index].productFeature.id)) {
            return 'font-size: 25px; color:#FFF;filter: drop-shadow(1px 1px 10px #FFF);border-radius:5px;';
          }
          return 'font-size: 25px; color:#FFF;filter: drop-shadow(1px 1px 10px #FFF);border-radius:5px;';
        }
      }
    },
    onFeatureMouseOver: function onFeatureMouseOver(feature_index, feature_id) {
      this.feature.hover = true;
      this.feature.index = this.index;
      this.feature.feature_index = feature_index;
      var x = this.computeFeaturePosX(feature_index, feature_id) + 17;
      var y = this.computeFeaturePosY(feature_index, feature_id) + 15;
      this.$emit('feature-mouse-over', this.product.linkedFeatures[feature_index].productFeature, feature_index, x, y);
    },
    onFeatureMouseLeave: function onFeatureMouseLeave(sub_index, feature_id) {
      this.$emit('feature-mouse-leave');
      this.feature.hover = false;
      this.feature.index = 0;
      this.feature.feature_index = 0;
    },
    displayProductSetting: function displayProductSetting() {
      this.$emit('settings', this.product, this.index, this.x, this.y);
    },
    displayProductMenu: function displayProductMenu() {
      this.$emit('menu', this.product, this.index, this.x, this.y, this.productHasUnmappedFeatures);
    },
    onProductRiskToolDragged: function onProductRiskToolDragged(_ref) {
      var el = _ref.el,
        deltaX = _ref.deltaX,
        deltaY = _ref.deltaY,
        offsetX = _ref.offsetX,
        offsetY = _ref.offsetY,
        clientX = _ref.clientX,
        clientY = _ref.clientY,
        first = _ref.first,
        last = _ref.last;
      if (last !== undefined && last) {
        this.$emit('product-risk-tool-dragged-stop', this.product, this.tools.risk.x, this.tools.risk.y, this.feature_pos_x, this.feature_pos_y);
        this.resetTools();
      }
      if (first !== undefined && first) {
        this.$emit('product-risk-tool-dragged-start', this.product, this.tools.risk.x, this.tools.risk.y, this.feature_pos_x, this.feature_pos_y);
      }
      if (deltaX === undefined || deltaY === undefined) {
        return;
      }
      this.tools.risk.x += deltaX;
      this.tools.risk.y += deltaY;
      this.$emit('product-risk-tool-dragged', this.product, this.tools.risk.x, this.tools.risk.y, this.feature_pos_x, this.feature_pos_y);
    },
    onFeatureToolDragged: function onFeatureToolDragged(_ref2) {
      var el = _ref2.el,
        deltaX = _ref2.deltaX,
        deltaY = _ref2.deltaY,
        offsetX = _ref2.offsetX,
        offsetY = _ref2.offsetY,
        clientX = _ref2.clientX,
        clientY = _ref2.clientY,
        first = _ref2.first,
        last = _ref2.last;
      if (last !== undefined && last) {
        this.$emit('feature-tool-dragged-stop', this.product.id, this.tools.feature.x, this.tools.feature.y);
        this.resetTools();
      }
      if (first !== undefined && first) {
        this.$emit('feature-tool-dragged-start', this.product.id, this.tools.feature.x, this.tools.feature.y);
      }
      if (deltaX === undefined || deltaY === undefined) {
        return;
      }
      this.tools.feature.x += deltaX;
      this.tools.feature.y += deltaY;
      this.$emit('feature-tool-dragged', this.product.id, this.tools.feature.x, this.tools.feature.y);
    },
    onFeatureDragged: function onFeatureDragged(_ref3) {
      var el = _ref3.el,
        deltaX = _ref3.deltaX,
        deltaY = _ref3.deltaY,
        offsetX = _ref3.offsetX,
        offsetY = _ref3.offsetY,
        clientX = _ref3.clientX,
        clientY = _ref3.clientY,
        first = _ref3.first,
        last = _ref3.last;
      var indexes = el.id.replace('feature_', '').split('_');
      // var index_product = parseInt(indexes[0])
      var index_feature = parseInt(indexes[1]);
      if (last !== undefined && last) {
        var feature1_x = [];
        var feature1_y = [];

        // Hybrid loop to loop thre possible feature pos in entire matrix
        // Loop logic from matrix code
        // feature_x pos logic from computefeatureposX code
        for (var i = 0; i < 100; i++) {
          feature1_x.push(120 + i * 50);
        }
        for (var j = 0; j < this.computeTotalGridLanes; j++) {
          feature1_y.push(this.y + (j - 1) * 50 - 1);
        }

        // Run additional code only for unmapped drag
        // Check for unmapped drag drop into mapped
        if (this.isUnmapped(this.product.linkedFeatures[index_feature].productFeature.id) || !this.hasFeatureRelation(this.product.linkedFeatures[index_feature].productFeature.id)) {
          for (var l = 0; l < this.product.linkedFeatures.length; l++) {
            if (l !== index_feature && !this.product.linkedFeatures[l].productFeature.enabler && this.feature_pos_x[index_feature] > this.feature_pos_x[l] - 15 && this.feature_pos_x[index_feature] < this.feature_pos_x[l] + 15 && this.feature_pos_y[index_feature] > this.feature_pos_y[l] - 15 && this.feature_pos_y[index_feature] < this.feature_pos_y[l] + 15) {
              this.feature_pos_x[index_feature] = this.feature_initial_drag_pos.x;
              this.feature_pos_y[index_feature] = this.feature_initial_drag_pos.y;
              this.$emit('update-feature-feature-relation', this.product.linkedFeatures[index_feature].productFeature.id, 'Feature', this.product.linkedFeatures[l].productFeature.id, 'Feature');
              this.feature_pos_x[index_feature] = this.findClosest(feature1_x, this.feature_pos_x[index_feature]);
              this.feature_pos_y[index_feature] = this.findClosest(feature1_y, this.feature_pos_y[index_feature]);
              // this.updateFeatureLocation(this.product.linkedFeatures[index_feature].productFeature, this.feature_pos_x[index_feature], this.feature_pos_y[index_feature], true)
              break;
            }
          }
        } else {
          this.feature_pos_x[index_feature] = this.findClosest(feature1_x, this.feature_pos_x[index_feature]);
          this.feature_pos_y[index_feature] = this.findClosest(feature1_y, this.feature_pos_y[index_feature]) + 5;

          // this.updateFeatureLocation(this.product.linkedFeatures[index_feature].productFeature,(this.feature_pos_x[index_feature] + 10),(this.feature_pos_y[index_feature] + 10), true)
        }
        this.$emit('feature-dragged-stop', this.product.id, this.index, this.product.linkedFeatures[index_feature].productFeature.id, index_feature, this.feature_pos_x[index_feature], this.feature_pos_y[index_feature]);
        this.feature.drag = false;
        this.resetDropGridHover();
      }
      if (first !== undefined && first) {
        this.feature_initial_drag_pos.x = parseInt(JSON.parse(JSON.stringify(this.feature_pos_x[index_feature])));
        this.feature_initial_drag_pos.y = parseInt(JSON.parse(JSON.stringify(this.feature_pos_y[index_feature])));
        // this.feature_initial_drag_pos.y = this.feature_pos_y[index_feature]
        this.$emit('feature-dragged-start', this.product.id, this.index, this.product.linkedFeatures[index_feature].productFeature.id, index_feature, this.feature_pos_x[index_feature], this.feature_pos_y[index_feature]);
        this.feature.drag = true;
      }
      if (deltaX === undefined || deltaY === undefined) {
        return;
      }
      if (this.feature_pos_x[index_feature] > this.x && this.feature_pos_x[index_feature] < this.width && this.feature_pos_y[index_feature] > this.y && this.feature_pos_y[index_feature] < this.y + this.height) {
        this.feature_pos_x[index_feature] += deltaX;
        this.feature_pos_y[index_feature] += deltaY;
        /* console.log(this.feature_pos_x[index_feature])
        console.log(this.feature_pos_y[index_feature])*/
        this.product.linkedFeatures[index_feature].productFeature.additionalattributes.posX += deltaX;
        this.product.linkedFeatures[index_feature].productFeature.additionalattributes.posY += deltaY;
        this.$emit('feature-dragged', this.product.id, this.index, this.product.linkedFeatures[index_feature].productFeature.id, index_feature, this.feature_pos_x[index_feature], this.feature_pos_y[index_feature]);
        // If unmapped feature
        if (this.isUnmapped(this.product.linkedFeatures[index_feature].productFeature.id) || !this.hasFeatureRelation(this.product.linkedFeatures[index_feature].productFeature.id)) {
          var overlap = false;
          for (var k = 0; k < this.product.linkedFeatures.length; k++) {
            if (k !== index_feature && !overlap && !this.product.linkedFeatures[k].productFeature.enabler && this.feature_pos_x[index_feature] > this.feature_pos_y[k] - 15 && this.feature_pos_x[index_feature] < this.feature_pos_y[k] + 15 && this.feature_pos_y[index_feature] > this.feature_pos_y[k] - 15 && this.feature_pos_y[index_feature] < this.feature_pos_y[k] + 15) {
              overlap = true;
              break;
            }
          }

          // Check if overlap with any mapped feature
          // this.product.linkedFeatures[j].productFeature.enabler
          if (overlap) {
            this.highlightGridCell(this.feature_pos_x[index_feature], this.feature_pos_y[index_feature], '#00916E80', '#00916E');
          } else {
            if (this.feature_pos_y[index_feature] > this.y && this.feature_pos_y[index_feature] < this.y + this.height) {
              this.highlightGridCell(this.feature_pos_x[index_feature], this.feature_pos_y[index_feature], '#FE900080', '#FE9000');
            } else {
              this.resetDropGridHover();
            }
          }
        } else {
          this.highlightGridCell(this.feature_pos_x[index_feature], this.feature_pos_y[index_feature]);
        }
      }
    },
    openFeatureDetailDialog: function openFeatureDetailDialog(feature, feature_index) {
      this.$emit('click', this.product.linkedFeatures[feature_index].productFeature, this.product, feature_index, 'Product');
    },
    createProductIdea: function createProductIdea() {
      this.$emit('create-idea', this.product.id, this.product.title);
    },
    computePhaseXFromStepID: function computePhaseXFromStepID(step_id) {
      if (this._.isEmpty(this.journeyMap)) {
        return 175;
      }
      if (this.phaseX.length === 0 && this.journeyMap.linkedJourneyPhases.length > 0) {
        this.computePhaseX();
      }
      if (this.phaseX.length === 0 && this.journeyMap.linkedJourneyPhases.length === 0) {
        return 175;
      }
      if (this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages.length > 0 && this.journeyMap.linkedJourneyPhases.length > 0) {
        for (var i = 0; i < this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages.length; i++) {
          for (var j = 0; j < this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[i].steps.length; j++) {
            if (this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[i].steps[j].id === step_id) {
              return this.phaseX[i];
            }
          }
        }
      }
      return 175;
    },
    highlightGridCell: function highlightGridCell(item_x, item_y) {
      var fill = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '#4096EE80';
      var stroke = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '#4096EE';
      var feature_x = [];
      var feature_y = [];

      // Hybrid loop to loop thre possible feature pos in entire matrix
      // Loop logic from matrix code
      // feature_x pos logic from computefeatureposX code
      for (var i = 0; i < 100; i++) {
        feature_x.push(170 + i * 50);
      }
      for (var j = 0; j < this.computeTotalGridLanes; j++) {
        feature_y.push(this.y + (j - 1) * 50 - 1);
      }
      this.drop_hover_grid.x = this.findClosest(feature_x, item_x);
      this.drop_hover_grid.y = this.findClosest(feature_y, item_y);
      this.drop_hover_grid.fill = fill;
      this.drop_hover_grid.stroke = stroke;
      this.drop_hover_grid.hover = true;
    },
    findClosest: function findClosest(arr, num) {
      if (arr == null) {
        return;
      }
      var closest = arr[0];
      var _iterator = (0, _createForOfIteratorHelper2.default)(arr),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var item = _step.value;
          if (Math.abs(item - num) < Math.abs(closest - num)) {
            closest = item;
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      return closest;
    },
    hasFeatureRelation: function hasFeatureRelation(feature_id) {
      for (var i = 0; i < this.relations.length; i++) {
        if (this.relations[i].start_id === feature_id && this.relations[i].end_type === 'Feature') {
          return true;
        }
      }
      return false;
    },
    isEnabler: function isEnabler(sub_entity_id) {
      for (var i = 0; i < this.offering.additionalattributes.entitiesSortOrder.length; i++) {
        if (this.offering.additionalattributes.entitiesSortOrder[i].type === 'product') {
          for (var j = 0; j < this.offering.additionalattributes.entitiesSortOrder[i].features.length; j++) {
            if (this.offering.additionalattributes.entitiesSortOrder[i].features[j].id === sub_entity_id) {
              return this.offering.additionalattributes.entitiesSortOrder[i].features[j].enabler;
            }
          }
        }
      }
      return false;
    },
    isUnmapped: function isUnmapped(sub_entity_id) {
      for (var i = 0; i < this.offering.additionalattributes.entitiesSortOrder.length; i++) {
        if (this.offering.additionalattributes.entitiesSortOrder[i].type === 'product') {
          for (var j = 0; j < this.offering.additionalattributes.entitiesSortOrder[i].features.length; j++) {
            if (this.offering.additionalattributes.entitiesSortOrder[i].features[j].id === sub_entity_id) {
              return this.offering.additionalattributes.entitiesSortOrder[i].features[j].unmapped;
            }
          }
        }
      }
      return false;
    },
    resetTools: function resetTools() {
      this.computeToolPos();
    },
    computeToolPos: function computeToolPos() {
      this.tools = {
        feature: {
          x: 47,
          y: this.y + 25
        },
        risk: {
          x: 101,
          y: this.y + 25
        },
        idea: {
          x: 47,
          y: this.y + 66
        }
      };
    },
    resetSettingsAndMenu: function resetSettingsAndMenu() {
      this.$emit('reset');
    },
    resetDropGridHover: function resetDropGridHover() {
      this.drop_hover_grid = {
        x: 0,
        y: 0,
        fill: '#4096EE80',
        stroke: '#4096EE',
        hover: false
      };
    },
    resetFeatureDetails: function resetFeatureDetails() {
      this.feature = {
        drag: false,
        hover: false,
        index: 0,
        feature_index: 0,
        product_id: null,
        offering_id: null,
        step_id: null
      };
    }
  }
};