"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
require("core-js/modules/es6.function.name");
var _axios = _interopRequireDefault(require("axios"));
var _default2 = exports.default = {
  name: 'FileUpload',
  props: {
    isEdit: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'image/*'
    },
    fileList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    displayType: {
      type: String,
      default: 'card'
    },
    entityName: {
      type: String,
      default: ''
    },
    entityId: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      fileLoading: false,
      uniqId: null
    };
  },
  computed: {},
  created: function created() {},
  methods: {
    attachFiles: function attachFiles() {
      this.$refs.fileInput.click();
    },
    uploadFile: function uploadFile() {
      var _this = this;
      for (var i = 0; i < this.$refs.fileInput.files.length; i++) {
        if (this.$refs.fileInput.files[i].size < process.env.VUE_APP_MAX_FILE_SIZE) {
          this.fileLoading = true;
          var formData = new FormData();
          formData.append('file', this.$refs.fileInput.files[i]);
          _axios.default.post(process.env.VUE_APP_REST_API_ENDPOINT + 'file', formData, {
            crossdomain: true,
            headers: {
              'Content-Type': 'multipart/form-data',
              'x-party-id': this.$store.getters.partyId,
              'x-user-id': this.$store.getters.id,
              'x-entity-id': this.entityId,
              'x-entity-name': this.entityName
            }
          }).then(function (data) {
            _this.$notify({
              title: 'Uploaded',
              message: 'File uploaded successfully',
              type: 'success',
              duration: 2000
            });
            _this.fileLoading = false;
            _this.$emit('file-uploaded', data.data.data);
            /* this.status_code = data.status
            this.status_text = data.statusText*/
          }).catch(function (error) {
            this.$notify({
              title: 'Error',
              message: error.response.data,
              type: 'error',
              duration: 2000
            });
            this.fileLoading = false;
          });
        } else {
          this.$notify.error({
            title: 'Upload failed',
            message: this.$refs.fileInput.files[i].name + ' is greater than the maximum file upload size.',
            duration: 10000
          });
        }
      }
    },
    computeExceededFileSize: function computeExceededFileSize(byte) {
      return this.formatBytes(parseInt(byte) - parseInt(process.env.VUE_APP_MAX_FILE_SIZE), 2);
    },
    formatBytes: function formatBytes(bytes) {
      var decimals = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
      if (bytes === 0) return '0 Bytes';
      var k = 1024;
      var dm = decimals < 0 ? 0 : decimals;
      var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      var i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    },
    generateUrl: function generateUrl(file) {
      if (file.contentType.includes('image') && !file.contentType.includes('svg')) {
        // return process.env.VUE_APP_REST_API_ENDPOINT + 'file/' + file.fileName + '?versionId=' + file.versionId + '&size=50X50' + '&x-user-id=' + this.$store.getters.id + '&x-party-id=' + this.$store.getters.partyId + '&x-entity-id=' + file.entityId + '&x-entity-name=' + file.entityName
        return process.env.VUE_APP_REST_API_ENDPOINT + 'file/' + file.fileName + '?versionId=' + file.versionId + '&x-user-id=' + this.$store.getters.id + '&x-party-id=' + this.$store.getters.partyId + '&x-entity-id=' + file.entityId + '&x-entity-name=' + file.entityName;
      } else {
        return process.env.VUE_APP_REST_API_ENDPOINT + 'file/' + file.fileName + '?versionId=' + file.versionId + '&x-user-id=' + this.$store.getters.id + '&x-party-id=' + this.$store.getters.partyId + '&x-entity-id=' + file.entityId + '&x-entity-name=' + file.entityName;
      }
    },
    downloadFile: function downloadFile(file) {
      return process.env.VUE_APP_REST_API_ENDPOINT + 'file/' + file.fileName + '?versionId=' + file.versionId;
    },
    deleteFile: function deleteFile(index, file) {
      var _this2 = this;
      this.$confirm('This will permanently delete the file. Continue?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(function () {
        (0, _axios.default)({
          method: 'DELETE',
          url: process.env.VUE_APP_REST_API_ENDPOINT + 'file/' + file.fileName + '?versionId=' + file.versionId,
          headers: {
            'x-party-id': '0000000071e8eaa80171ed762fc9001b',
            'x-user-id': '4028804373f1cd410173f1d138bb0001',
            'x-entity-id': file.entityId,
            'x-entity-name': file.entityName
          }
        }).then(function (data) {
          if (data.status === 200) {
            _this2.$emit('file-deleted', index);
          }
        }).catch(function (error) {
          _this2.$notify({
            title: 'Error',
            type: 'error',
            message: error.message
          });
        });
      }).catch(function () {
        _this2.$message({
          type: 'info',
          message: 'Delete canceled'
        });
      });
    }
  }
};