"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _PieChart = _interopRequireDefault(require("./components/PieChart"));
var _default = exports.default = {
  name: 'FeatureManagmentProgress',
  components: {
    PieChartComponent: _PieChart.default
  },
  data: function data() {
    return {};
  },
  computed: {},
  mounted: function mounted() {},
  methods: {}
};