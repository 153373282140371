"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  name: 'ResearchCreate',
  data: function data() {
    return {
      active_step: 0,
      research_form: {
        name: '',
        description: '',
        problem: '',
        research: '',
        tags: []
      },
      options: [{
        value: 'General',
        label: 'General'
      }, {
        value: 'Marketing',
        label: 'Marketing'
      }, {
        value: 'SME',
        label: 'SME'
      }, {
        value: 'Government',
        label: 'Government'
      }, {
        value: 'POC',
        label: 'POC'
      }, {
        value: 'Enterprise',
        label: 'Enterprise'
      }]
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.active_step = 0;
  },
  methods: {
    createResearch: function createResearch() {
      this.$emit('create-research', this.research_form);
    }
  }
};