import { render, staticRenderFns } from "./competitors.vue?vue&type=template&id=71774ff5"
import script from "./competitors.vue?vue&type=script&lang=js"
export * from "./competitors.vue?vue&type=script&lang=js"
import style0 from "./competitors.vue?vue&type=style&index=0&id=71774ff5&rel=stylesheet%2Fscss&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\Sastha\\Documents\\GitHub\\prodiggiapp-frontend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('71774ff5')) {
      api.createRecord('71774ff5', component.options)
    } else {
      api.reload('71774ff5', component.options)
    }
    module.hot.accept("./competitors.vue?vue&type=template&id=71774ff5", function () {
      api.rerender('71774ff5', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/offering/components/strategy/competitors.vue"
export default component.exports