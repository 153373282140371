"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.sort");
var _taggedTemplateLiteral2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/taggedTemplateLiteral.js"));
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8;
var _default = exports.default = {
  name: 'Competitors',
  components: {},
  props: {},
  data: function data() {
    var validateTitle = function validateTitle(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter a valid title'));
      } else {
        callback();
      }
    };
    var validateDescription = function validateDescription(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter a valid description'));
      } else {
        callback();
      }
    };
    var validateCategory = function validateCategory(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter a valid category'));
      } else {
        callback();
      }
    };
    var validateHeadOffice = function validateHeadOffice(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter a valid Head Office'));
      } else {
        callback();
      }
    };
    var validateStrength = function validateStrength(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter a valid strength'));
      } else {
        callback();
      }
    };
    var validateMyStrength = function validateMyStrength(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter a valid my strength'));
      } else {
        callback();
      }
    };
    var validateUsers = function validateUsers(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter a valid users'));
      } else {
        callback();
      }
    };
    var validateRisk = function validateRisk(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter a valid threat'));
      } else {
        callback();
      }
    };
    var validateMaturity = function validateMaturity(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter a valid maturity'));
      } else {
        callback();
      }
    };
    return {
      competitorRules: {
        title: [{
          required: true,
          trigger: 'blur',
          validator: validateTitle
        }],
        description: [{
          required: true,
          trigger: 'blur',
          validator: validateDescription
        }],
        category: [{
          required: true,
          trigger: 'blur',
          validator: validateCategory
        }],
        headoffice: [{
          required: true,
          trigger: 'blur',
          validator: validateHeadOffice
        }],
        strength: [{
          required: true,
          trigger: 'blur',
          validator: validateStrength
        }],
        mystrength: [{
          required: true,
          trigger: 'blur',
          validator: validateMyStrength
        }],
        users: [{
          required: true,
          trigger: 'blur',
          validator: validateUsers
        }],
        active: [{
          required: true,
          trigger: 'blur'
        }],
        risk: [{
          required: true,
          trigger: 'blur',
          validator: validateRisk
        }],
        acquired: [{
          required: true,
          trigger: 'blur'
        }]
      },
      featureRules: {
        title: [{
          required: true,
          trigger: 'blur',
          validator: validateTitle
        }],
        description: [{
          required: true,
          trigger: 'blur',
          validator: validateDescription
        }],
        challenged: [{
          required: true,
          trigger: 'blur'
        }],
        maturity: [{
          required: true,
          trigger: 'blur',
          validator: validateMaturity
        }],
        risk: [{
          required: true,
          trigger: 'blur',
          validator: validateRisk
        }],
        strength: [{
          required: true,
          trigger: 'blur',
          validator: validateStrength
        }]
      },
      loading: false,
      editFeatureMode: false,
      editMode: false,
      filterDialog: false,
      addFeatureDialog: false,
      addCompetitorDialog: false,
      competitorSearch: '',
      companyCategory: [],
      activeCompetitor: {},
      activeCompetitorIndex: 0,
      total_count: 0,
      activeFeatureTypeIndex: '',
      competitiveDetailsTab: 'first',
      offerings: [],
      features: [],
      search_competitor: {
        q: '',
        filters: {
          title: '',
          title_operator: '',
          description: '',
          description_operator: '',
          category: [],
          risk: [],
          active: '',
          acquired: ''
        },
        sort: {
          title: 'a-z'
        }
      },
      categories: [{
        title: 'Direct'
      }, {
        title: 'New'
      }, {
        title: 'Peripheral'
      }],
      threats: [{
        title: 'Lowest'
      }, {
        title: 'Low'
      }, {
        title: 'Medium'
      }, {
        title: 'High'
      }, {
        title: 'Highest'
      }],
      continents: [{
        name: 'Asia'
      }, {
        name: 'Europe'
      }, {
        name: 'Africa'
      }, {
        name: 'South America'
      }, {
        name: 'North America'
      }, {
        name: 'Australia'
      }, {
        name: 'Global'
      }],
      competitor_form: {
        title: '',
        description: '',
        category: 'Direct',
        headoffice: '',
        strength: '',
        mystrength: '',
        users: 0,
        risk: 'Low',
        theiroffering: '',
        acquisitiondetails: '',
        active: true,
        acquired: false,
        date: '12-12-2022',
        your_offerings: [{
          id: '1',
          title: 'Offering 1'
        }, {
          id: '2',
          title: 'Offering 2'
        }, {
          id: '3',
          title: 'Offering 3'
        }],
        features: []
      },
      feature_form: {
        title: '',
        description: '',
        challenged: false,
        maturity: '',
        risk: '',
        strength: ''
      },
      competitors: [],
      selectedFeature: {},
      selectedFeatureIndex: 0
    };
  },
  computed: {
    filteredCompetitors: function filteredCompetitors() {
      var self = this;
      if (this.competitors.length > 0) {
        return this.competitors.filter(function (competitor) {
          return competitor.title.toLowerCase().indexOf(self.search_competitor.q.toLowerCase()) > -1;
        });
      } else {
        return [];
      }
    }
  },
  mounted: function mounted() {
    this.retrieveCompetitors(1, true);
    this.retrieveOfferings(1, true);
  },
  methods: {
    loadPage: function loadPage(page_number) {
      this.retrieveCompetitors(page_number, false);
    },
    retrieveCompetitors: function retrieveCompetitors(page_number) {
      var _this = this;
      var silent = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      this.loading = true;
      this.competitors = [];
      this.$apollo.query({
        // Query
        query: window._gql(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)([" query Competitors($page: Page, $where: CompetitorsCriteriaExpression)\n        {\n        Competitors(page: $page, where: $where)\n        {\n          pages\n          total\n          select{\n            id\n            title(orderBy: ", ")\n            description\n            category\n            headoffice\n            strength\n            mystrength\n            risk\n            theiroffering\n            active\n            acquired\n            date\n            acquisitiondetails\n            users\n            additionalattributes\n            linkedFeatures(optional: true) {\n              competitorFeature(optional: true) {\n                id\n                title\n                description\n                additionalattributes\n                complexity\n                priority\n                relations\n                status\n                maturity\n                risk\n                challenged\n                strength\n                linkedProductFeatures(optional: true) {\n                  productFeature(optional: true) {\n                    id\n                    title\n                  }\n                }\n              }\n            }\n          }\n        }\n      }"])), this.computeSortQuery()),
        fetchPolicy: 'no-cache',
        variables: {
          'page': {
            'limit': 20,
            'start': page_number
          },
          'where': this.computeSearchQuery()
        }
      }).then(function (data) {
        if (!silent) {
          _this.$notify({
            title: 'Success',
            message: 'Retrieved Successfully',
            type: 'success',
            duration: 2000
          });
        }
        _this.competitors = data.data.Competitors.select;
        _this.selectCompetitor(_this.competitors[0], 0);
        _this.loading = false;
      }).catch(function (error) {
        // Error
        _this.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this.loading = false;
      });
    },
    computeSearchQuery: function computeSearchQuery() {
      var query = {};
      if (this.search_competitor.q !== '') {
        query.title = {
          'LIKE_': this.search_competitor.q
        };
      }
      if (this.search_competitor.filters.title !== '') {
        switch (this.search_competitor.filters.title_operator) {
          case 'EXACT':
            query.title = {
              'EQ_': this.search_competitor.filters.title
            };
            break;
          case 'LIKE':
            query.title = {
              'LIKE_': this.search_competitor.filters.title
            };
            break;
          case 'EMPTY':
            query.title = {
              'IS_NULL': this.search_competitor.filters.title
            };
            break;
          case 'NOT EMPTY':
            query.title = {
              'NOT_NULL': this.search_competitor.filters.title
            };
            break;
          case 'STARTS':
            query.title = {
              'STARTS_': this.search_competitor.filters.title
            };
            break;
          case 'ENDS':
            query.title = {
              'ENDS_': this.search_competitor.filters.title
            };
            break;
          default:
            query.title = {
              'LIKE_': this.search_competitor.filters.title
            };
            break;
        }
      }
      if (this.search_competitor.filters.description !== '') {
        switch (this.search_competitor.filters.description_operator) {
          case 'EXACT':
            query.description = {
              'EQ_': this.search_competitor.filters.description
            };
            break;
          case 'LIKE':
            query.description = {
              'LIKE_': this.search_competitor.filters.description
            };
            break;
          case 'EMPTY':
            query.description = {
              'IS_NULL': this.search_competitor.filters.description
            };
            break;
          case 'NOT EMPTY':
            query.description = {
              'NOT_NULL': this.search_competitor.filters.description
            };
            break;
          case 'STARTS':
            query.description = {
              'STARTS_': this.search_competitor.filters.description
            };
            break;
          case 'ENDS':
            query.description = {
              'ENDS_': this.search_competitor.filters.description
            };
            break;
          default:
            query.description = {
              'LIKE_': this.search_competitor.filters.description
            };
            break;
        }
      }
      if (this.search_competitor.filters.category.length > 0) {
        query.category = {
          'IN': this.search_competitor.filters.category
        };
      }
      if (this.search_competitor.filters.risk.length > 0) {
        query.risk = {
          'IN': this.search_competitor.filters.risk
        };
      }
      if (this.search_competitor.filters.active !== '') {
        query.active = {
          'EQ': this.search_competitor.filters.active === 'true'
        };
      }
      if (this.search_competitor.filters.acquired !== '') {
        query.acquired = {
          'EQ': this.search_competitor.filters.acquired === 'true'
        };
      }
      return query;
    },
    computeSortQuery: function computeSortQuery() {
      if (this.search_competitor.sort.title === 'a-z') {
        return 'ASC';
      } else {
        return 'DESC';
      }
    },
    retrieveOfferings: function retrieveOfferings(page_number) {
      var _this2 = this;
      var silent = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      this.loading = true;
      this.offerings = [];
      this.$apollo.query({
        // Query
        query: window._gql(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)([" query Offerings($page: Page)\n        {\n        Offerings(page: $page)\n        {\n          pages\n          total\n          select{\n            id\n            title\n            description\n            offeringtype\n          }\n        }\n      }"]))),
        fetchPolicy: 'no-cache',
        variables: {
          'page': {
            'limit': 20,
            'start': page_number
          }
        }
      }).then(function (data) {
        if (!silent) {
          _this2.$notify({
            title: 'Success',
            message: 'Retrieved Successfully',
            type: 'success',
            duration: 2000
          });
        }
        _this2.offerings = data.data.Offerings.select;
        _this2.loading = false;
      }).catch(function (error) {
        // Error
        _this2.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this2.loading = false;
      });
    },
    createCompetitor: function createCompetitor() {
      var _this3 = this;
      this.$refs.competitor_form.validate(function (valid) {
        if (valid) {
          _this3.loading = true;
          _this3.$apollo.mutate({
            // Query
            mutation: window._gql(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["mutation createCompetitor($competitor: CreateCompetitorInput!)\n            {\n              createCompetitor(input: $competitor) {\n                id\n                title\n                description\n                category\n                headoffice\n                strength\n                mystrength\n                risk\n                theiroffering\n                active\n                acquired\n                date\n                acquisitiondetails\n                users\n                additionalattributes\n              }\n            }"]))),
            // Parameters
            variables: {
              'competitor': {
                'title': _this3.competitor_form.title,
                'description': _this3.competitor_form.description,
                'category': _this3.competitor_form.category,
                'headoffice': _this3.competitor_form.headoffice,
                'strength': _this3.competitor_form.strength,
                'mystrength': _this3.competitor_form.mystrength,
                'users': _this3.competitor_form.users,
                'risk': _this3.competitor_form.risk,
                'theiroffering': _this3.competitor_form.theiroffering,
                'acquisitiondetails': _this3.competitor_form.acquisitiondetails,
                'active': _this3.competitor_form.active,
                'acquired': _this3.competitor_form.acquired,
                'date': _this3.competitor_form.date
              }
            }
          }).then(function (data) {
            _this3.$notify({
              title: 'Success',
              message: 'Created Successfully',
              type: 'success',
              duration: 2000
            });
            _this3.resetCompetitorForm();
            var competitor = data.data.createCompetitor;
            _this3.competitors.push(competitor);
            _this3.retrieveCompetitors(1);
            _this3.loading = false;
          }).catch(function (error) {
            // Error
            _this3.$notify({
              title: 'Error',
              message: error,
              type: 'error',
              duration: 2000
            });
            _this3.loading = false;
          });
          _this3.addCompetitorDialog = false;
        } else {
          _this3.loading = false;
          return false;
        }
      });
    },
    computeRisk: function computeRisk(risk) {
      switch (risk) {
        case 'low':
        case 'no-threat':
        case 'Low':
          return 'info';
        case 'medium':
        case 'Medium':
          return 'warning';
        case 'high':
        case 'High':
          return 'danger';
      }
    },
    computeStrength: function computeStrength(risk) {
      switch (risk) {
        case 'weak':
        case 'Weak':
          return 'info';
        case 'medium':
        case 'Medium':
          return 'warning';
        case 'strong':
        case 'Strong':
          return 'danger';
      }
    },
    selectCompetitor: function selectCompetitor(competitor, index) {
      this.activeCompetitor = competitor;
      this.activeCompetitorIndex = index;
    },
    truncateDescription: function truncateDescription(description, length) {
      return this._.truncate(description, {
        'length': length,
        'separator': ' '
      });
    },
    openEditFeatureDialog: function openEditFeatureDialog(feature, index) {
      this.resetFeatureForm();
      this.selectedFeature = feature.competitorFeature;
      this.selectedFeatureIndex = index;
      this.feature_form = this.selectedFeature;
      this.editFeatureMode = true;
      this.addFeatureDialog = true;
    },
    updateFeatureCollection: function updateFeatureCollection() {
      var _this4 = this;
      this.$refs.feature_form.validate(function (valid) {
        if (valid) {
          _this4.loading = true;
          _this4.$apollo.mutate({
            // Query
            mutation: window._gql(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2.default)(["mutation updateCompetitorFeature($competitorfeature: UpdateCompetitorFeatureInput!)\n            {\n              updateCompetitorFeature(input: $competitorfeature) {\n                id\n                title\n                description\n                additionalattributes\n                complexity\n                priority\n                relations\n                status\n                maturity\n                risk\n                challenged\n                strength\n                linkedProductFeatures(optional: true) {\n                  productFeature(optional: true) {\n                    id\n                    title\n                  }\n                }\n              }\n            }"]))),
            // Parameters
            variables: {
              'competitorfeature': {
                'id': _this4.selectedFeature.id,
                'title': _this4.feature_form.title,
                'description': _this4.feature_form.description,
                'challenged': _this4.feature_form.challenged,
                'maturity': _this4.feature_form.maturity,
                'strength': _this4.feature_form.strength,
                'risk': _this4.feature_form.risk,
                'productFeatureId': _this4.feature_form.my_feature
              }
            }
          }).then(function (data) {
            _this4.$notify({
              title: 'Success',
              message: 'Updated Successfully',
              type: 'success',
              duration: 2000
            });
            _this4.resetFeatureForm();
            _this4.editFeatureMode = false;
            _this4.addFeatureDialog = false;
            _this4.loading = false;
          }).catch(function (error) {
            // Error
            _this4.$notify({
              title: 'Error',
              message: error,
              type: 'error',
              duration: 2000
            });
            _this4.loading = false;
          });
        } else {
          _this4.loading = false;
          return false;
        }
      });
    },
    updateCompetitorCollection: function updateCompetitorCollection() {
      var _this5 = this;
      this.loading = true;
      this.$apollo.mutate({
        // Query
        mutation: window._gql(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2.default)(["mutation updateCompetitor($competitor: UpdateCompetitorInput!)\n        {\n          updateCompetitor(input: $competitor) {\n            id\n            title\n            description\n            category\n            headoffice\n            strength\n            mystrength\n            risk\n            theiroffering\n            active\n            acquired\n            date\n            acquisitiondetails\n            users\n            additionalattributes\n          }\n        }"]))),
        // Parameters
        variables: {
          'competitor': {
            'id': this.activeCompetitor.id,
            'title': this.activeCompetitor.title,
            'description': this.activeCompetitor.description,
            'category': this.activeCompetitor.category,
            'headoffice': this.activeCompetitor.headoffice,
            'strength': this.activeCompetitor.strength,
            'mystrength': this.activeCompetitor.mystrength,
            'users': this.activeCompetitor.users,
            'risk': this.activeCompetitor.risk,
            'theiroffering': this.activeCompetitor.theiroffering,
            'acquisitiondetails': this.activeCompetitor.acquisitiondetails,
            'active': this.activeCompetitor.active,
            'acquired': this.activeCompetitor.acquired,
            'date': this.activeCompetitor.date
          }
        }
      }).then(function (data) {
        _this5.$notify({
          title: 'Success',
          message: 'Updated Successfully',
          type: 'success',
          duration: 2000
        });
        _this5.editMode = false;
        _this5.loading = false;
      }).catch(function (error) {
        // Error
        _this5.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this5.loading = false;
      });
    },
    deleteCompetitor: function deleteCompetitor(index) {
      var _this6 = this;
      this.$confirm('This will permanently delete the competitor. Continue?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(function () {
        _this6.loading = true;
        _this6.$apollo.mutate({
          // Query
          mutation: window._gql(_templateObject6 || (_templateObject6 = (0, _taggedTemplateLiteral2.default)(["mutation deleteCompetitor($competitor: DeleteCompetitorInput!)\n            {\n              deleteCompetitor(input: $competitor) {\n                id,\n              }\n            }"]))),
          // Parameters
          variables: {
            'competitor': {
              'id': _this6.activeCompetitor.id
            }
          }
        }).then(function (data) {
          _this6.$notify({
            title: 'Success',
            message: 'Deleted Successfully',
            type: 'success',
            duration: 2000
          });
          _this6.retrieveCompetitors(1);
          _this6.loading = false;
        }).catch(function (error) {
          // Error
          _this6.$notify({
            title: 'Error',
            message: error,
            type: 'error',
            duration: 2000
          });
          _this6.loading = false;
        });
      }).catch(function () {
        _this6.$message({
          type: 'info',
          message: 'Delete canceled'
        });
      });
    },
    deleteCompetitorFeature: function deleteCompetitorFeature(id, index) {
      var _this7 = this;
      this.$confirm('This will permanently delete the feature. Continue?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(function () {
        _this7.loading = true;
        _this7.$apollo.mutate({
          // Query
          mutation: window._gql(_templateObject7 || (_templateObject7 = (0, _taggedTemplateLiteral2.default)(["mutation deleteCompetitorFeature($competitorfeature: DeleteCompetitorFeatureInput!)\n            {\n              deleteCompetitorFeature(input: $competitorfeature) {\n                id,\n              }\n            }"]))),
          // Parameters
          variables: {
            'competitorfeature': {
              'id': id
            }
          }
        }).then(function (data) {
          _this7.$notify({
            title: 'Success',
            message: 'Deleted Successfully',
            type: 'success',
            duration: 2000
          });
          _this7.activeCompetitor.linkedFeatures.splice(index, 1);
          _this7.loading = false;
        }).catch(function (error) {
          // Error
          _this7.$notify({
            title: 'Error',
            message: error,
            type: 'error',
            duration: 2000
          });
          _this7.loading = false;
        });
      }).catch(function () {
        _this7.$message({
          type: 'info',
          message: 'Delete canceled'
        });
      });
    },
    showFilterDialog: function showFilterDialog() {
      this.resetSearchFilter();
      this.filterDialog = true;
    },
    closeFilterDialog: function closeFilterDialog() {
      this.resetSearchFilter();
      this.filterDialog = false;
    },
    filterCompetitorList: function filterCompetitorList() {
      this.retrieveCompetitors(1);
      this.filterDialog = false;
    },
    resetSearchFilter: function resetSearchFilter() {
      this.search_competitor.filters = {
        title: '',
        title_operator: '',
        description: '',
        description_operator: '',
        risk: [],
        category: [],
        active: '',
        acquired: ''
      };
    },
    toggleSort: function toggleSort() {
      if (this.search_competitor.sort.title === 'a-z') {
        this.search_competitor.sort.title = 'z-a';
      } else {
        this.search_competitor.sort.title = 'a-z';
      }
      this.retrieveCompetitors(1);
    },
    removeSearchFilterTag: function removeSearchFilterTag(type) {
      var index = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      switch (type) {
        case 'title':
          this.search_competitor.filters.title = '';
          this.search_competitor.filters.title_operator = '';
          break;
        case 'description':
          this.search_competitor.filters.description = '';
          this.search_competitor.filters.description_operator = '';
          break;
        case 'category':
          this.search_competitor.filters.category.splice(index, 1);
          break;
        case 'threat':
          this.search_competitor.filters.risk.splice(index, 1);
          break;
        case 'active':
          this.search_competitor.filters.active = '';
          break;
        case 'acquired':
          this.search_competitor.filters.acquired = '';
          break;
      }
      this.retrieveCompetitors(1);
    },
    removeSearchSortTag: function removeSearchSortTag(type) {
      switch (type) {
        case 'title':
          this.search_competitor.sort.title = 'a-z';
          break;
      }
      this.retrieveCompetitors(1);
    },
    openAddCompetitorDialog: function openAddCompetitorDialog() {
      this.resetCompetitorForm();
      this.addCompetitorDialog = true;
    },
    openAddFeatureDialog: function openAddFeatureDialog() {
      this.resetFeatureForm();
      this.addFeatureDialog = true;
    },
    addFeature: function addFeature() {
      var _this8 = this;
      this.$refs.feature_form.validate(function (valid) {
        if (valid) {
          // this.activeCompetitor.features[this.activeFeatureTypeIndex].push(this.feature_form)
          _this8.loading = true;
          _this8.$apollo.mutate({
            // Query
            mutation: window._gql(_templateObject8 || (_templateObject8 = (0, _taggedTemplateLiteral2.default)(["mutation createCompetitorFeature($competitorfeature: CreateCompetitorFeatureInput!)\n            {\n              createCompetitorFeature(input: $competitorfeature) {\n                id\n                title\n              }\n            }"]))),
            // Parameters
            variables: {
              'competitorfeature': {
                'competitorId': _this8.activeCompetitor.id,
                'title': _this8.feature_form.title,
                'description': _this8.feature_form.description,
                'challenged': _this8.feature_form.challenged,
                'maturity': _this8.feature_form.maturity,
                'strength': _this8.feature_form.strength,
                'risk': _this8.feature_form.risk,
                'productFeatureId': _this8.feature_form.my_feature
              }
            }
          }).then(function (data) {
            _this8.$notify({
              title: 'Success',
              message: 'Created Successfully',
              type: 'success',
              duration: 2000
            });
            _this8.resetFeatureForm();
            // var competitor = data.data.createCompetitor
            // this.competitors.push(competitor)
            _this8.retrieveCompetitors(1);
            _this8.loading = false;
          }).catch(function (error) {
            // Error
            _this8.$notify({
              title: 'Error',
              message: error,
              type: 'error',
              duration: 2000
            });
            _this8.loading = false;
          });
          _this8.addFeatureDialog = false;
        } else {
          _this8.loading = false;
          return false;
        }
      });
    },
    resetCompetitorForm: function resetCompetitorForm() {
      this.competitor_form = {
        title: '',
        description: '',
        category: 'Direct',
        headoffice: '',
        strength: '',
        mystrength: '',
        users: 0,
        risk: 'Low',
        theiroffering: '',
        acquisitiondetails: '',
        active: true,
        acquired: false,
        date: '12-12-2022',
        your_offerings: [{
          id: '1',
          title: 'Offering 1'
        }, {
          id: '2',
          title: 'Offering 2'
        }, {
          id: '3',
          title: 'Offering 3'
        }],
        features: []
      };
    },
    resetFeatureForm: function resetFeatureForm() {
      this.feature_form = {
        title: '',
        description: '',
        challenged: false,
        maturity: '',
        risk: '',
        strength: '',
        my_feature: ''
      };
    }
  }
};