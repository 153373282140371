"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _color = require("@/utils/color");
// import { hexOpacityToRGBAlpha, getContrastYIQ } from '@/utils/color'
var _default2 = exports.default = {
  name: 'Increment',
  components: {},
  props: {
    x: {
      type: Number,
      required: true,
      default: 0
    },
    y: {
      type: Number,
      required: true,
      default: 0
    },
    width: {
      type: Number,
      required: true,
      default: 0
    },
    height: {
      type: Number,
      required: true,
      default: 0
    },
    type: {
      type: String,
      default: 'release'
    },
    parentIndex: {
      type: Number,
      required: true,
      default: 0
    },
    index: {
      type: Number,
      required: true,
      default: 0
    },
    increment: {
      type: Object,
      default: function _default() {
        return {
          title: '',
          description: ''
        };
      }
    },
    settings: {
      type: Object,
      required: true,
      default: function _default() {
        return {
          version: 1,
          roadmap: {},
          release: {
            timeline: 6,
            color: {
              title: 'rgba(189, 195, 200, 1.0)',
              column: 'rgba(189, 195, 200, 1.0)'
            }
          },
          increment: {
            prefix: 'increment',
            timeline: 1,
            delete: 'disabled',
            color: {
              title: 'rgba(189, 195, 200, 1.0)',
              column: 'rgba(189, 195, 200, 1.0)'
            }
          }
        };
      }
    },
    timeframe: {
      type: Number,
      default: 0
    }
  },
  data: function data() {
    return {
      isHover: false,
      isDeleteHover: false
    };
  },
  computed: {
    computeReleaseResizeControlWidth: function computeReleaseResizeControlWidth() {
      var width = 15;
      return width;
    },
    computeFillColor: function computeFillColor() {
      if (this.increment.additionalattributes.style.color.length === 8) {
        return '#' + this.increment.additionalattributes.style.color.slice(0, -2);
      }
      return this.increment.additionalattributes.style.color;
    },
    computeFillOpacity: function computeFillOpacity() {
      /* if (this.increment.additionalattributes.style.color.length === 8) {
        return hexOpacityToRGBAlpha(this.increment.additionalattributes.style.color.slice(-2))
      }
      return 0.7*/
      return 1;
    },
    computeTitleStyle: function computeTitleStyle() {
      return 'font-size:1em;font-weight:100; text-align: center; color:' + (0, _color.getContrastYIQ)(this.computeFillColor.substring(1)) + '; word-wrap: break-word;-webkit-font-smoothing: antialiased;letter-spacing: 1.5px;';
    },
    computeIconStyle: function computeIconStyle() {
      return 'font-size:1em;color:' + (0, _color.getContrastYIQ)(this.computeFillColor.substring(1));
    }
  },
  watch: {},
  mounted: function mounted() {},
  created: function created() {},
  methods: {
    deleteIncrement: function deleteIncrement() {
      var _this = this;
      if (this.settings.increment.delete !== 'disabled') {
        this.$confirm('This will permanently delete the increment. Continue?', 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(function () {
          _this.$emit('delete', _this.increment.id, _this.index, _this.parentIndex);
        }).catch(function () {
          _this.$message({
            type: 'info',
            message: 'Delete canceled'
          });
        });
      } else {
        this.$alert('Delete restricted in roadmap settings. Update settings to delete increment', 'Delete restricted', {
          confirmButtonText: 'OK'
        });
      }
    },
    truncateString: function truncateString(string, length) {
      return this._.truncate(string, {
        'length': length / this.timeframe
      });
    },
    onClick: function onClick() {
      this.$emit('click', this.parentIndex, this.index, this.increment);
    }
  }
};