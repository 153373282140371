"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _createForOfIteratorHelper2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/createForOfIteratorHelper.js"));
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.regexp.split");
var _taggedTemplateLiteral2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/taggedTemplateLiteral.js"));
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _objectSpread2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/objectSpread2.js"));
require("core-js/modules/es6.number.constructor");
var _interopRequireWildcard2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _vuex = require("vuex");
var _templateObject;
var _default2 = exports.default = {
  name: 'Service',
  components: {
    AutomatedComponent: function AutomatedComponent() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./Automated.vue"));
      });
    },
    SemiAutomatedComponent: function SemiAutomatedComponent() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./SemiAutomated.vue"));
      });
    },
    ManualComponent: function ManualComponent() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./Manual.vue"));
      });
    },
    ThirdPartyComponent: function ThirdPartyComponent() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./ThirdParty.vue"));
      });
    },
    OrphanServiceApplicationComponent: function OrphanServiceApplicationComponent() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./OrphanServiceApplication.vue"));
      });
    }
  },
  props: {
    offering: {
      type: Object,
      required: true,
      default: function _default() {
        return {
          id: '',
          title: '',
          description: '',
          offeringcategory: '',
          offeringcategorydesc: '',
          additionalattributes: {},
          linkedProducts: [],
          linkedServices: []
        };
      }
    },
    service: {
      type: Object,
      required: true,
      default: function _default() {
        return {
          id: '',
          title: '',
          description: ''
        };
      }
    },
    index: {
      type: Number,
      required: true,
      default: 0
    },
    x: {
      type: Number,
      required: true,
      default: 0
    },
    y: {
      type: Number,
      required: true,
      default: 0
    },
    width: {
      type: Number,
      required: true,
      default: 0
    },
    height: {
      type: Number,
      required: true,
      default: 0
    },
    recompute: {
      type: Boolean,
      required: true,
      default: false
    },
    recomputeAndUpdate: {
      type: Boolean,
      required: true,
      default: false
    },
    isUnmappedVisible: {
      type: Boolean,
      default: true
    },
    relations: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    journeyMap: {
      type: Object,
      required: true,
      default: function _default() {
        return {
          id: '',
          title: '',
          description: '',
          additionalattributes: {},
          linkedJourneyExperiences: [],
          linkedJourneyPhases: [],
          linkedJourneySteps: []
        };
      }
    }
  },
  data: function data() {
    return {
      phaseX: [],
      service_application_pos_x: [],
      service_application_pos_y: [],
      tools: {
        service_application: {
          x: 25,
          y: 0
        },
        service_application_automated: {
          x: 25,
          y: 0
        },
        service_application_semi_automated: {
          x: 25,
          y: 0
        },
        service_application_manual: {
          x: 25,
          y: 0
        },
        service_application_thirdparty: {
          x: 25,
          y: 0
        },
        risk: {
          x: 75,
          y: 0
        },
        idea: {
          x: 128,
          y: 0
        }
      },
      drop_hover_grid: {
        x: -100,
        y: -100,
        hover: false
      },
      service_application: {
        drag: false,
        hover: false,
        index: 0,
        sub_index: 0,
        service_id: null,
        service_application_index: null,
        offering_id: null,
        step_id: null
      }
    };
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['modules'])), {}, {
    isMinimized: function isMinimized() {
      for (var i = 0; i < this.offering.additionalattributes.entitiesSortOrder.length; i++) {
        if (this.offering.additionalattributes.entitiesSortOrder[i].id === this.service.id) {
          return this.offering.additionalattributes.entitiesSortOrder[i].minimized;
        }
      }
      return false;
    },
    sortedStages: function sortedStages() {
      var stagesSortOrder = [];
      if (this.journeyMap.additionalattributes && this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages.length > 0 && this.journeyMap.linkedJourneyPhases.length > 0) {
        for (var i = 0; i < this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages.length; i++) {
          for (var j = 0; j < this.journeyMap.linkedJourneyPhases.length; j++) {
            if (this.journeyMap.linkedJourneyPhases[j].journeyPhase.id === this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[i].id && !this.journeyMap.linkedJourneyPhases[j].journeyPhase.isDeleted) {
              stagesSortOrder.push(this.journeyMap.linkedJourneyPhases[j]);
              break;
            }
          }
        }
      } else if (this.journeyMap.linkedJourneyPhases) {
        for (var k = 0; k < this.journeyMap.linkedJourneyPhases.length; k++) {
          if (!this.journeyMap.linkedJourneyPhases[j].journeyPhase.hasOwnProperty('isDeleted')) {
            stagesSortOrder.push(this.journeyMap.linkedJourneyPhases[j]);
          } else if (this.journeyMap.linkedJourneyPhases[j].journeyPhase.hasOwnProperty('isDeleted') && this.journeyMap.linkedJourneyPhases[j].journeyPhase.isDeleted === false) {
            stagesSortOrder.push(this.journeyMap.linkedJourneyPhases[j]);
            break;
          }
        }
      }
      return stagesSortOrder;
    },
    serviceHasUnmappedFeatures: function serviceHasUnmappedFeatures() {
      var has_unmapped = false;
      if (!this.isUnmappedVisible) {
        return has_unmapped;
      }
      for (var i = 0; i < this.offering.additionalattributes.entitiesSortOrder.length; i++) {
        if (this.offering.additionalattributes.entitiesSortOrder[i].id === this.service.id) {
          for (var j = 0; j < this.offering.additionalattributes.entitiesSortOrder[i].service_applications.length; j++) {
            if (this.offering.additionalattributes.entitiesSortOrder[i].service_applications[j].unmapped) {
              return true;
            }
          }
        }
      }
      return has_unmapped;
    },
    serviceHasEnablerFeatures: function serviceHasEnablerFeatures() {
      for (var i = 0; i < this.offering.additionalattributes.entitiesSortOrder.length; i++) {
        if (this.offering.additionalattributes.entitiesSortOrder[i].id === this.service.id) {
          for (var j = 0; j < this.offering.additionalattributes.entitiesSortOrder[i].service_applications.length; j++) {
            if (this.offering.additionalattributes.entitiesSortOrder[i].service_applications[j].enabler) {
              return true;
            }
          }
        }
      }
      return false;
    },
    computeTotalGridLanes: function computeTotalGridLanes() {
      if (this.serviceHasUnmappedFeatures) {
        return 4;
      }
      return 3;
    }
  }),
  watch: {
    'service.linkedServiceApplications': {
      handler: function handler(newValue) {
        if (!this.service_application.drag) {
          this.computePhaseX();
          this.computeServiceApplicationX();
          this.computeServiceApplicationY();
          for (var l = 0; l < this.service.linkedServiceApplications.length; l++) {
            this.service.linkedServiceApplications[l].serviceApplication.additionalattributes.posX = this.service_application_pos_x[l];
            this.service.linkedServiceApplications[l].serviceApplication.additionalattributes.posY = this.service_application_pos_y[l];
            this.updateServiceApplicationLocation(this.service.linkedServiceApplications[l].serviceApplication.id, this.service.linkedServiceApplications[l].serviceApplication.additionalattributes, true);
          }
        }
      },
      deep: false
    },
    recompute: function recompute(val) {
      if (!this.service_application.drag) {
        this.computePhaseX();
        this.computeServiceApplicationX();
        this.computeServiceApplicationY();
        this.computeToolPos();
      }
    },
    recomputeAndUpdate: function recomputeAndUpdate(val) {
      if (!this.service_application.drag) {
        this.computePhaseX();
        this.computeServiceApplicationX();
        this.computeServiceApplicationY();
        this.computeToolPos();
        /* for (var l = 0; l < this.service.linkedServiceApplications.length; l++) {
          this.service.linkedServiceApplications[l].serviceApplication.additionalattributes.posX = this.service_application_pos_x[l]
          this.service.linkedServiceApplications[l].serviceApplication.additionalattributes.posY = this.service_application_pos_y[l]
          this.updateServiceApplicationLocation(this.service.linkedServiceApplications[l].serviceApplication.id, this.service.linkedServiceApplications[l].serviceApplication.additionalattributes, true)
        }*/
      }
    }
  },
  created: function created() {},
  mounted: function mounted() {
    this.computePhaseX();
    this.computeServiceApplicationX();
    this.computeServiceApplicationY();
    this.computeToolPos();
  },
  methods: {
    isVisible: function isVisible(service_application_datum) {
      if (!this.offering.additionalattributes.entityLane.isUnmappedVisible && this.isUnmapped(service_application_datum.serviceApplication.id) && !this.isEnabler(service_application_datum.serviceApplication.id)) {
        return false;
      }
      return true;
    },
    moveUpEntitySortOrder: function moveUpEntitySortOrder() {
      this.$emit('move-entity-up', this.service.id);
    },
    moveDownEntitySortOrder: function moveDownEntitySortOrder() {
      this.$emit('move-entity-down', this.service.id);
    },
    hasPermissionForModule: function hasPermissionForModule(entity) {
      return this.modules.includes(entity);
    },
    computePhaseX: function computePhaseX() {
      this.phaseX = [];
      if (!this.sortedStages) {
        return this.phaseX;
      }
      for (var i = 0; i < this.sortedStages.length; i++) {
        if (i === 0) {
          this.phaseX.push(175);
        } else {
          var length = 0;
          for (var j = 0; j < i; j++) {
            length += this.computePhaseLength(this.sortedStages[j].journeyPhase, j);
            length += 50;
          }
          this.phaseX.push(175 + length);
        }
      }
    },
    computePhaseLength: function computePhaseLength(phase, index) {
      // get max number of features in product for given phase
      // if less than 2 use default 175, else use length off features
      var max_count = 0;

      // Get an array of pains/gains for current phase
      if (typeof phase === 'undefined') {
        return 225;
      }
      for (var i = 0; i < this.journeyMap.additionalattributes.relations.length; i++) {
        if (this.journeyMap.additionalattributes.relations[i].start_type === 'Phase' && this.journeyMap.additionalattributes.relations[i].end_type === 'Step' && this.journeyMap.additionalattributes.relations[i].start_id === phase.id) {
          max_count++;
        }
      }
      var additional_columns = Math.floor(max_count / 7) - 1;
      var first_column = 150;
      return 225 + first_column + additional_columns * 150;
    },
    computeServiceApplicationStrokeColor: function computeServiceApplicationStrokeColor(service_application) {
      if (service_application.serviceApplication.enabler) {
        return '#304174';
      } else if (this.isUnmapped(service_application.serviceApplication.id) || !this.hasServiceApplicationRelation(service_application.serviceApplication.id)) {
        return '#F56C6C';
      } else {
        return '#304174';
      }
    },
    computeServiceApplicationStrokeDasharray: function computeServiceApplicationStrokeDasharray(service_application) {
      if (service_application.serviceApplication.enabler) {
        return '2';
      } else if (this.isUnmapped(service_application.serviceApplication.id)) {
        return '0';
      } else {
        return '0';
      }
    },
    updateServiceApplicationLocation: function updateServiceApplicationLocation(service_id, additionalattributes) {
      var _this = this;
      var silent = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      this.$apollo.mutate({
        // Query
        mutation: window._gql(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["mutation updateServiceApplication($serviceApplication: UpdateServiceApplicationInput!)\n        {\n          updateServiceApplication(input: $serviceApplication) {\n            id\n          }\n        }"]))),
        // Parameters
        variables: {
          'serviceApplication': {
            'id': service_id,
            'additionalattributes': additionalattributes
          }
        }
      }).then(function (data) {
        if (!silent) {
          _this.$notify({
            title: 'Success',
            message: 'Updated Successfully',
            type: 'success',
            duration: 2000
          });
        }
      }).catch(function (error) {
        // Error
        if (!silent) {
          _this.$notify({
            title: 'Error',
            message: error,
            type: 'error',
            duration: 2000
          });
        }
      });
    },
    generateUniqueServiceToolID: function generateUniqueServiceToolID(service_index) {
      return 'service_tool_' + service_index;
    },
    generateDiamondPath: function generateDiamondPath(x, y) {
      var x1 = x + 1;
      var y1 = y + 1;
      var x2 = x + 2;
      var y2 = y + 2;
      return 'M ' + x1 + ' ' + y + ' L ' + x + ' ' + y1 + ' L ' + x1 + ' ' + y2 + ' L ' + x2 + ' ' + y1 + ' Z';
    },
    trimString: function trimString(string, length) {
      if (string.length > length) {
        return string.substring(0, length) + '...';
      } else {
        return string;
      }
    },
    resizeServiceSwimlane: function resizeServiceSwimlane(type) {
      this.$emit('toggle', this.index);
    },
    generateUniqueServiceApplicationID: function generateUniqueServiceApplicationID(service_application_index) {
      return 'serviceapplication_' + this.index + '_' + service_application_index;
    },
    computeServiceEnablerIndex: function computeServiceEnablerIndex(index) {
      var count = 0;
      for (var j = 0; j < index; j++) {
        if (this.isEnabler(this.service.linkedServiceApplications[j].serviceApplication.id)) {
          count++;
        }
      }
      return count;
    },
    computeServiceUnmappedIndex: function computeServiceUnmappedIndex(index) {
      var count = 0;
      for (var j = 0; j < index; j++) {
        if (this.isUnmapped(this.service.linkedServiceApplications[j].serviceApplication.id)) {
          if (this.service.linkedServiceApplications[j].serviceApplication.relations.type === 'step' && this.journeyMap.linkedJourneySteps.length === 0) {
            return index;
          }
          if (this.service.linkedServiceApplications[j].serviceApplication.relations.type === 'phase' && this.journeyMap.linkedJourneyPhases.length === 0) {
            return index;
          }
          count++;
        }
        if (!this.hasServiceApplicationRelation(this.service.linkedServiceApplications[j].serviceApplication.id)) {
          count++;
        }
      }
      return count;
    },
    computeServiceApplicationPosX: function computeServiceApplicationPosX(service_application_index, service_application_id) {
      /* if (this.offering.additionalattributes.entitiesSortOrder && this.offering.additionalattributes.entitiesSortOrder.length > 0) {
        for (var i = 0; i < this.offering.additionalattributes.entitiesSortOrder.length; i++) {
          if (this.offering.additionalattributes.entitiesSortOrder[i].id === this.service.id) {
            for (var j = 0; j < this.offering.additionalattributes.entitiesSortOrder[i].service_applications.length; j++) {
              if (this.offering.additionalattributes.entitiesSortOrder[i].service_applications[j].id === service_application_id) {
                return this.offering.additionalattributes.entitiesSortOrder[i].service_applications[j].posX
              }
            }
          }
        }
      }*/
      if (this.service_application_pos_x.length === 0) {
        this.computeServiceApplicationX();
      }
      /* if (!this.service.additionalattributes.minimized && this.service.linkedServiceApplications[service_application_index].serviceApplication.additionalattributes.posX !== undefined) {
        return this.service.linkedServiceApplications[service_application_index].serviceApplication.additionalattributes.posX
      }*/
      return this.service_application_pos_x[service_application_index];
    },
    computeFilteredServiceApplicationIndex: function computeFilteredServiceApplicationIndex(index) {
      var temp_Service_application_ids = [];
      for (var j = 0; j < this.service.linkedServiceApplications.length; j++) {
        if (!this.isUnmapped(this.service.linkedServiceApplications[j].serviceApplication.id) && !this.isEnabler(this.service.linkedServiceApplications[j].serviceApplication.id)) {
          temp_Service_application_ids.push(this.service.linkedServiceApplications[j].serviceApplication.id);
        }
      }
      return temp_Service_application_ids.indexOf(this.service.linkedServiceApplications[index].serviceApplication.id);
    },
    computeServiceApplicationX: function computeServiceApplicationX() {
      this.service_application_pos_x = [];
      if (this.offering.additionalattributes.entitiesSortOrder && this.offering.additionalattributes.entitiesSortOrder.length > 0) {
        for (var i = 0; i < this.offering.additionalattributes.entitiesSortOrder.length; i++) {
          if (this.offering.additionalattributes.entitiesSortOrder[i].id === this.service.id) {
            for (var j = 0; j < this.offering.additionalattributes.entitiesSortOrder[i].service_applications.length; j++) {
              for (var k = 0; k < this.service.linkedServiceApplications.length; k++) {
                if (this.offering.additionalattributes.entitiesSortOrder[i].service_applications[j].id === this.service.linkedServiceApplications[k].serviceApplication.id) {
                  this.service_application_pos_x[k] = this.offering.additionalattributes.entitiesSortOrder[i].service_applications[j].posX;
                }
              }
            }
          }
        }
      }

      /* for (var j = 0; j < this.service.linkedServiceApplications.length; j++) {
        if (this.service.additionalattributes.minimized) {
          this.service_application_pos_x[j] = (j % 100) * 30 + 168
        } else {
            if (this.service.linkedServiceApplications[j].serviceApplication.relations.id === null) {
              this.service_application_pos_x[j] = (this.computeServiceUnmappedIndex(j) % 50) * 50 + 215
            } else if (this.service.linkedServiceApplications[j].serviceApplication.relations.id !== null && this.service.linkedServiceApplications[j].serviceApplication.enabler) {
              this.service_application_pos_x[j] = this.computePhaseXFromStepID(this.service.linkedServiceApplications[j].serviceApplication.relations.id, j) + (this.computeServiceEnablerIndex(j) % 50) * 50
            } else {
              this.service_application_pos_x[j] = this.computePhaseXFromStepID(this.service.linkedServiceApplications[j].serviceApplication.relations.id, j) + (this.computeFilteredServiceApplicationIndex(j) % 50) * 50
          }
        }
      }*/
    },
    computeServiceApplicationPosY: function computeServiceApplicationPosY(service_application_index, service_application_id) {
      /* if (this.offering.additionalattributes.entitiesSortOrder && this.offering.additionalattributes.entitiesSortOrder.length > 0) {
        for (var i = 0; i < this.offering.additionalattributes.entitiesSortOrder.length; i++) {
          if (this.offering.additionalattributes.entitiesSortOrder[i].id === this.service.id) {
            for (var j = 0; j < this.offering.additionalattributes.entitiesSortOrder[i].service_applications.length; j++) {
              if (this.offering.additionalattributes.entitiesSortOrder[i].service_applications[j].id === service_application_id) {
                return this.offering.additionalattributes.entitiesSortOrder[i].service_applications[j].posY
              }
            }
          }
        }
      }*/
      if (this.service_application_pos_y.length === 0) {
        this.computeServiceApplicationY();
      }

      /* if (!this.service.additionalattributes.minimized && this.service.linkedServiceApplications[service_application_index].serviceApplication.additionalattributes.posY !== undefined) {
        return this.service.linkedServiceApplications[service_application_index].serviceApplication.additionalattributes.posY
      }*/

      return this.service_application_pos_y[service_application_index];
    },
    computeServiceApplicationY: function computeServiceApplicationY() {
      this.service_application_pos_y = [];
      if (this.offering.additionalattributes.entitiesSortOrder && this.offering.additionalattributes.entitiesSortOrder.length > 0) {
        for (var i = 0; i < this.offering.additionalattributes.entitiesSortOrder.length; i++) {
          if (this.offering.additionalattributes.entitiesSortOrder[i].id === this.service.id) {
            for (var j = 0; j < this.offering.additionalattributes.entitiesSortOrder[i].service_applications.length; j++) {
              for (var k = 0; k < this.service.linkedServiceApplications.length; k++) {
                if (this.offering.additionalattributes.entitiesSortOrder[i].service_applications[j].id === this.service.linkedServiceApplications[k].serviceApplication.id) {
                  this.service_application_pos_y[k] = this.offering.additionalattributes.entitiesSortOrder[i].service_applications[j].posY;
                }
              }
            }
          }
        }
      }

      /* for (var j = 0; j < this.service.linkedServiceApplications.length; j++) {
        if (this.service.additionalattributes.minimized) {
          this.service_application_pos_y[j] = this.y + 5
        } else {
          if (this.service.linkedServiceApplications[j].serviceApplication.enabler) {
            this.service_application_pos_y[j] = this.y + 10
          } else if (!this.hasServiceApplicationRelation(this.service.linkedServiceApplications[j].serviceApplication.id)) {
            this.service_application_pos_y[j] = this.y + (this.serviceHasEnablerFeatures ? 55 : 10)
          } else if (this.service.linkedServiceApplications[j].serviceApplication.relations.id === null) {
            this.service_application_pos_y[j] = this.y + (this.serviceHasEnablerFeatures ? 55 : 10)
          } else {
            this.service_application_pos_y[j] = this.y + 10
          }
        }
      }*/
    },
    onServiceApplicationMouseOver: function onServiceApplicationMouseOver(service_application_index, service_application_id) {
      this.service_application.hover = true;
      this.service_application.index = this.index;
      this.service_application.service_application_index = service_application_index;
      var x = this.computeServiceApplicationPosX(service_application_index, service_application_id);
      var y = this.computeServiceApplicationPosY(service_application_index, service_application_id) + 3;
      this.$emit('service-application-mouse-over', this.service.linkedServiceApplications[service_application_index].serviceApplication, service_application_index, x, y);
    },
    onServiceApplicationMouseLeave: function onServiceApplicationMouseLeave(sub_index, service_application_id) {
      this.$emit('service-application-mouse-leave');
      this.service_application.hover = false;
      this.service_application.index = 0;
      this.service_application.service_application_index = 0;
    },
    displayServiceSetting: function displayServiceSetting() {
      this.$emit('settings', this.service, this.index, this.x, this.y);
    },
    displayServiceMenu: function displayServiceMenu() {
      this.$emit('menu', this.service, this.index, this.x, this.y, this.serviceHasUnmappedFeatures);
    },
    onServiceRiskDragged: function onServiceRiskDragged(_ref) {
      var el = _ref.el,
        deltaX = _ref.deltaX,
        deltaY = _ref.deltaY,
        offsetX = _ref.offsetX,
        offsetY = _ref.offsetY,
        clientX = _ref.clientX,
        clientY = _ref.clientY,
        first = _ref.first,
        last = _ref.last;
      if (last !== undefined && last) {
        this.$emit('service-risk-tool-dragged-stop', this.service, this.tools.risk.x, this.tools.risk.y, this.service_application_pos_x, this.service_application_pos_y);
        this.service_application.drag = false;
        this.resetTools();
      }
      if (first !== undefined && first) {
        this.$emit('service-risk-tool-dragged-start', this.service, this.tools.risk.x, this.tools.risk.y, this.service_application_pos_x, this.service_application_pos_y);
        this.service_application.drag = true;
      }
      if (deltaX === undefined || deltaY === undefined) {
        return;
      }
      this.tools.risk.x += deltaX;
      this.tools.risk.y += deltaY;
      this.$emit('service-risk-tool-dragged', this.service, this.tools.risk.x, this.tools.risk.y, this.service_application_pos_x, this.service_application_pos_y);
    },
    onServiceApplicationToolDragged: function onServiceApplicationToolDragged(_ref2) {
      var el = _ref2.el,
        deltaX = _ref2.deltaX,
        deltaY = _ref2.deltaY,
        offsetX = _ref2.offsetX,
        offsetY = _ref2.offsetY,
        clientX = _ref2.clientX,
        clientY = _ref2.clientY,
        first = _ref2.first,
        last = _ref2.last;
      if (last !== undefined && last) {
        this.$emit('service-application-tool-dragged-stop', this.service.id, this.tools.service_application.x, this.tools.service_application.y);
        this.resetTools();
      }
      if (first !== undefined && first) {
        this.$emit('service-application-tool-dragged-start', this.service.id, this.tools.service_application.x, this.tools.service_application.y);
      }
      if (deltaX === undefined || deltaY === undefined) {
        return;
      }
      this.tools.service_application.x += deltaX;
      this.tools.service_application.y += deltaY;
      this.$emit('service-application-tool-dragged', this.service.id, this.tools.service_application.x, this.tools.service_application.y);
    },
    onServiceApplicationAutomatedToolDragged: function onServiceApplicationAutomatedToolDragged(_ref3) {
      var el = _ref3.el,
        deltaX = _ref3.deltaX,
        deltaY = _ref3.deltaY,
        offsetX = _ref3.offsetX,
        offsetY = _ref3.offsetY,
        clientX = _ref3.clientX,
        clientY = _ref3.clientY,
        first = _ref3.first,
        last = _ref3.last;
      if (last !== undefined && last) {
        this.$emit('service-application-tool-dragged-stop', this.service.id, this.tools.service_application_automated.x, this.tools.service_application_automated.y, 'automated');
        this.resetTools();
      }
      if (first !== undefined && first) {
        this.$emit('service-application-tool-dragged-start', this.service.id, this.tools.service_application_automated.x, this.tools.service_application_automated.y, 'automated');
      }
      if (deltaX === undefined || deltaY === undefined) {
        return;
      }
      this.tools.service_application_automated.x += deltaX;
      this.tools.service_application_automated.y += deltaY;
      this.$emit('service-application-tool-dragged', this.service.id, this.tools.service_application_automated.x, this.tools.service_application_automated.y, 'automated');
    },
    onServiceApplicationSemiAutomatedToolDragged: function onServiceApplicationSemiAutomatedToolDragged(_ref4) {
      var el = _ref4.el,
        deltaX = _ref4.deltaX,
        deltaY = _ref4.deltaY,
        offsetX = _ref4.offsetX,
        offsetY = _ref4.offsetY,
        clientX = _ref4.clientX,
        clientY = _ref4.clientY,
        first = _ref4.first,
        last = _ref4.last;
      if (last !== undefined && last) {
        this.$emit('service-application-tool-dragged-stop', this.service.id, this.tools.service_application_semi_automated.x, this.tools.service_application_semi_automated.y, 'semi_automated');
        this.resetTools();
      }
      if (first !== undefined && first) {
        this.$emit('service-application-tool-dragged-start', this.service.id, this.tools.service_application_semi_automated.x, this.tools.service_application_semi_automated.y, 'semi_automated');
      }
      if (deltaX === undefined || deltaY === undefined) {
        return;
      }
      this.tools.service_application_semi_automated.x += deltaX;
      this.tools.service_application_semi_automated.y += deltaY;
      this.$emit('service-application-tool-dragged', this.service.id, this.tools.service_application_semi_automated.x, this.tools.service_application_semi_automated.y, 'semi_automated');
    },
    onServiceApplicationManualToolDragged: function onServiceApplicationManualToolDragged(_ref5) {
      var el = _ref5.el,
        deltaX = _ref5.deltaX,
        deltaY = _ref5.deltaY,
        offsetX = _ref5.offsetX,
        offsetY = _ref5.offsetY,
        clientX = _ref5.clientX,
        clientY = _ref5.clientY,
        first = _ref5.first,
        last = _ref5.last;
      if (last !== undefined && last) {
        this.$emit('service-application-tool-dragged-stop', this.service.id, this.tools.service_application_manual.x, this.tools.service_application_manual.y, 'manual');
        this.resetTools();
      }
      if (first !== undefined && first) {
        this.$emit('service-application-tool-dragged-start', this.service.id, this.tools.service_application_manual.x, this.tools.service_application_manual.y, 'manual');
      }
      if (deltaX === undefined || deltaY === undefined) {
        return;
      }
      this.tools.service_application_manual.x += deltaX;
      this.tools.service_application_manual.y += deltaY;
      this.$emit('service-application-tool-dragged', this.service.id, this.tools.service_application_manual.x, this.tools.service_application_manual.y, 'manual');
    },
    onServiceApplicationThirdpartyToolDragged: function onServiceApplicationThirdpartyToolDragged(_ref6) {
      var el = _ref6.el,
        deltaX = _ref6.deltaX,
        deltaY = _ref6.deltaY,
        offsetX = _ref6.offsetX,
        offsetY = _ref6.offsetY,
        clientX = _ref6.clientX,
        clientY = _ref6.clientY,
        first = _ref6.first,
        last = _ref6.last;
      if (last !== undefined && last) {
        this.$emit('service-application-tool-dragged-stop', this.service.id, this.tools.service_application_thirdparty.x, this.tools.service_application_thirdparty.y, 'thirdparty');
        this.resetTools();
      }
      if (first !== undefined && first) {
        this.$emit('service-application-tool-dragged-start', this.service.id, this.tools.service_application_thirdparty.x, this.tools.service_application_thirdparty.y, 'thirdparty');
      }
      if (deltaX === undefined || deltaY === undefined) {
        return;
      }
      this.tools.service_application_thirdparty.x += deltaX;
      this.tools.service_application_thirdparty.y += deltaY;
      this.$emit('service-application-tool-dragged', this.service.id, this.tools.service_application_thirdparty.x, this.tools.service_application_thirdparty.y, 'thirdparty');
    },
    onServiceApplicationDragged: function onServiceApplicationDragged(_ref7) {
      var el = _ref7.el,
        deltaX = _ref7.deltaX,
        deltaY = _ref7.deltaY,
        offsetX = _ref7.offsetX,
        offsetY = _ref7.offsetY,
        clientX = _ref7.clientX,
        clientY = _ref7.clientY,
        first = _ref7.first,
        last = _ref7.last;
      var indexes = el.id.replace('serviceapplication_', '').split('_');
      // var index_service = parseInt(indexes[0])
      var index_service_application = parseInt(indexes[1]);
      if (last !== undefined && last) {
        var service_application1_x = [];
        var service_application1_y = [];

        // Hybrid loop to loop thre possible feature pos in entire matrix
        // Loop logic from matrix code
        // feature_x pos logic from computefeatureposX code
        for (var i = 0; i < 100; i++) {
          service_application1_x.push(170 + i * 50);
        }
        for (var j = 0; j < this.computeTotalGridLanes; j++) {
          service_application1_y.push(this.y + (j - 1) * 50 - 1);
        }
        if (this.isUnmapped(this.service.linkedServiceApplications[index_service_application].serviceApplication.id) || !this.hasServiceApplicationRelation(this.service.linkedServiceApplications[index_service_application].serviceApplication.id)) {
          for (var l = 0; l < this.service.linkedServiceApplications.length; l++) {
            // console.log('Dragged:'+this.feature_pos_x[index_service_application]+' '+this.feature_pos_y[index_service_application])
            // console.log('feature:'+this.feature_pos_x[i]+' '+this.feature_pos_y[i] + ' ' + this.service.linkedServiceApplications[i].productFeature.title)
            if (l !== index_service_application && !this.service.linkedServiceApplications[l].serviceApplication.enabler && this.service_application_pos_x[index_service_application] > this.service_application_pos_x[l] - 15 && this.service_application_pos_x[index_service_application] < this.service_application_pos_x[l] + 15 && this.service_application_pos_y[index_service_application] > this.service_application_pos_y[l] - 15 && this.service_application_pos_y[index_service_application] < this.service_application_pos_y[l] + 15) {
              this.service_application_pos_x[index_service_application] = this.feature_initial_drag_pos.x;
              this.service_application_pos_y[index_service_application] = this.feature_initial_drag_pos.y;
              this.$emit('update-service-application-service-application-relation', this.service.linkedServiceApplications[index_service_application].serviceApplication.id, 'ServiceApplication', this.service.linkedServiceApplications[l].serviceApplication.id, 'ServiceApplication');
              this.updateServiceApplicationLocation(this.service.linkedServiceApplications[index_service_application].serviceApplication, this.service_application_pos_x[index_service_application], this.service_application_pos_y[index_service_application], true);
              break;
            }
          }
        } else {
          this.service_application_pos_x[index_service_application] = this.findClosest(service_application1_x, this.service_application_pos_x[index_service_application]) + 10;
          this.service_application_pos_y[index_service_application] = this.findClosest(service_application1_y, this.service_application_pos_y[index_service_application]) + 10;
          this.updateServiceApplicationLocation(this.service.linkedServiceApplications[index_service_application].serviceApplication, this.service_application_pos_x[index_service_application], this.service_application_pos_y[index_service_application], true);
        }
        this.$emit('service-application-dragged-stop', this.service.id, this.index, this.service.linkedServiceApplications[index_service_application].serviceApplication.id, index_service_application, this.service_application_pos_x[index_service_application], this.service_application_pos_y[index_service_application]);
        this.resetDropGridHover();
      }
      if (first !== undefined && first) {
        this.$emit('service-application-dragged-start', this.service.id, this.index, this.service.linkedServiceApplications[index_service_application].serviceApplication.id, index_service_application, this.service_application_pos_x[index_service_application], this.service_application_pos_y[index_service_application]);
      }
      if (deltaX === undefined || deltaY === undefined) {
        return;
      }
      if (this.service_application_pos_x[index_service_application] > this.x && this.service_application_pos_x[index_service_application] < this.width && this.service_application_pos_y[index_service_application] > this.y && this.service_application_pos_y[index_service_application] < this.y + this.height) {
        this.service_application_pos_x[index_service_application] += parseInt(deltaX);
        this.service_application_pos_y[index_service_application] += parseInt(deltaY);
        this.service_application_pos_x[index_service_application] += deltaX;
        this.service_application_pos_y[index_service_application] += deltaY;
        this.$emit('service-application-dragged', this.service.id, this.index, this.service.linkedServiceApplications[index_service_application].serviceApplication.id, index_service_application, this.service_application_pos_x[index_service_application], this.service_application_pos_y[index_service_application]);

        // If unmapped feature
        if (this.isUnmapped(this.service.linkedServiceApplications[index_service_application].serviceApplication.id) || !this.hasServiceApplicationRelation(this.service.linkedServiceApplications[index_service_application].serviceApplication.id)) {
          var overlap = false;
          for (var k = 0; k < this.service.linkedServiceApplications.length; k++) {
            // console.log('Dragged:'+this.feature_pos_x[index_service_application]+' '+this.feature_pos_y[index_service_application])
            // console.log('feature:'+this.feature_pos_x[i]+' '+this.feature_pos_y[i] + ' ' + this.service.linkedServiceApplications[i].serviceApplication.title)
            if (k !== index_service_application && !overlap && !this.service.linkedServiceApplications[k].serviceApplication.enabler && this.service_application_pos_x[index_service_application] > this.service_application_pos_x[index_service_application] - 15 && this.service_application_pos_x[index_service_application] < this.service_application_pos_x[index_service_application] + 15 && this.service_application_pos_y[index_service_application] > this.service_application_pos_y[index_service_application] - 15 && this.service_application_pos_y[index_service_application] < this.service_application_pos_y[index_service_application] + 15) {
              // console.log('inside')
              overlap = true;
              break;
            }
          }
          // Check if overlap with any mapped feature
          // this.service.linkedServiceApplications[j].serviceApplication.enabler
          if (overlap) {
            this.highlightGridCell(this.service_application_pos_x[index_service_application], this.service_application_pos_y[index_service_application], '#00916E80', '#00916E');
          } else {
            this.highlightGridCell(this.service_application_pos_x[index_service_application], this.service_application_pos_y[index_service_application], '#FE900080', '#FE9000');
          }
        } else {
          this.highlightGridCell(this.service_application_pos_x[index_service_application], this.service_application_pos_y[index_service_application]);
        }
      }
    },
    openServiceApplicationDetailDialog: function openServiceApplicationDetailDialog(service_index, service_application_index) {
      this.$emit('click', this.service.linkedServiceApplications[service_application_index].serviceApplication, this.service, service_application_index, 'Service');
    },
    createServiceIdea: function createServiceIdea() {
      this.$emit('create-idea', this.service.id, this.service.title);
    },
    computePhaseXFromStepID: function computePhaseXFromStepID(step_id, index) {
      if (this._.isEmpty(this.journeyMap) || this.journeyMap.linkedJourneySteps.length === 0) {
        return 170 + index * 50;
      }
      if (this.phaseX.length === 0 && this.journeyMap.linkedJourneyPhases.length > 0) {
        this.computePhaseX();
      }
      if (this.phaseX.length === 0 && this.journeyMap.linkedJourneyPhases.length === 0) {
        return 175 + index * 50;
      }
      if (this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages.length > 0 && this.journeyMap.linkedJourneyPhases.length > 0) {
        for (var i = 0; i < this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages.length; i++) {
          for (var j = 0; j < this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[i].steps.length; j++) {
            if (this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[i].steps[j].id === step_id) {
              return this.phaseX[i];
            }
          }
        }
      }
      return 170;
    },
    highlightGridCell: function highlightGridCell(item_x, item_y) {
      var service_application_x = [];
      var service_application_y = [];

      // Hybrid loop to loop thre possible feature pos in entire matrix
      // Loop logic from matrix code
      // service_application_x pos logic from computefeatureposX code
      for (var i = 0; i < 100; i++) {
        service_application_x.push(170 + i * 50);
      }
      for (var j = 0; j < this.computeTotalGridLanes; j++) {
        service_application_y.push(this.y + (j - 1) * 50 - 1);
      }
      this.drop_hover_grid.x = this.findClosest(service_application_x, item_x);
      this.drop_hover_grid.y = this.findClosest(service_application_y, item_y);
      this.drop_hover_grid.hover = true;
    },
    findClosest: function findClosest(arr, num) {
      if (arr == null) {
        return;
      }
      var closest = arr[0];
      var _iterator = (0, _createForOfIteratorHelper2.default)(arr),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var item = _step.value;
          if (Math.abs(item - num) < Math.abs(closest - num)) {
            closest = item;
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      return closest;
    },
    hasServiceApplicationRelation: function hasServiceApplicationRelation(service_application_id) {
      for (var i = 0; i < this.relations.length; i++) {
        if (this.relations[i].end_id === service_application_id) {
          return true;
        }
      }
      return false;
    },
    isEnabler: function isEnabler(sub_entity_id) {
      for (var i = 0; i < this.offering.additionalattributes.entitiesSortOrder.length; i++) {
        if (this.offering.additionalattributes.entitiesSortOrder[i].type === 'service') {
          for (var j = 0; j < this.offering.additionalattributes.entitiesSortOrder[i].service_applications.length; j++) {
            if (this.offering.additionalattributes.entitiesSortOrder[i].service_applications[j].id === sub_entity_id) {
              return this.offering.additionalattributes.entitiesSortOrder[i].service_applications[j].enabler;
            }
          }
        }
      }
      return false;
    },
    isUnmapped: function isUnmapped(sub_entity_id) {
      for (var i = 0; i < this.offering.additionalattributes.entitiesSortOrder.length; i++) {
        if (this.offering.additionalattributes.entitiesSortOrder[i].type === 'service') {
          for (var j = 0; j < this.offering.additionalattributes.entitiesSortOrder[i].service_applications.length; j++) {
            if (this.offering.additionalattributes.entitiesSortOrder[i].service_applications[j].id === sub_entity_id) {
              return this.offering.additionalattributes.entitiesSortOrder[i].service_applications[j].unmapped;
            }
          }
        }
      }
      return false;
    },
    resetDropGridHover: function resetDropGridHover() {
      this.drop_hover_grid = {
        x: 0,
        y: 0,
        hover: false
      };
    },
    resetTools: function resetTools() {
      this.computeToolPos();
    },
    computeToolPos: function computeToolPos() {
      this.tools = {
        service_application: {
          x: 40,
          y: this.y + 25
        },
        service_application_automated: {
          x: 40,
          y: this.y + 25
        },
        service_application_semi_automated: {
          x: 70,
          y: this.y + 25
        },
        service_application_manual: {
          x: 40,
          y: this.y + 66
        },
        service_application_thirdparty: {
          x: 70,
          y: this.y + 66
        },
        risk: {
          x: 104,
          y: this.y + 29
        },
        idea: {
          x: 113,
          y: this.y + 72
        }
      };
    },
    resetSettingsAndMenu: function resetSettingsAndMenu() {}
  }
};