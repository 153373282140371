"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      visible: _vm.open,
      "custom-class": "create-idea-dialog",
      width: "40%"
    },
    on: {
      close: function close($event) {
        return _vm.closeDialog();
      }
    }
  }, [_c("div", {
    staticClass: "text-center",
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_c("h1", [_vm._v(_vm._s(_vm.createIdeaDialogTitle.title))]), _vm._v(" "), _c("h3", [_vm._v(_vm._s(_vm.createIdeaDialogTitle.description))])]), _vm._v(" "), _c("el-form", {
    ref: "form_step1",
    attrs: {
      model: _vm.form,
      "label-width": "200px",
      rules: _vm.offeringRules
    }
  }, [_c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.activeStep === "step1" ? _c("div", {
    staticStyle: {
      "animation-fill-mode": "forwards"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Title",
      prop: "title"
    }
  }, [_c("el-input", {
    attrs: {
      size: "small"
    },
    model: {
      value: _vm.form.title,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "title", $$v);
      },
      expression: "form.title"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Description",
      prop: "description"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      size: "small"
    },
    model: {
      value: _vm.form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "description", $$v);
      },
      expression: "form.description"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Background",
      prop: "problemstatement"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      size: "small"
    },
    model: {
      value: _vm.form.problemstatement,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "problemstatement", $$v);
      },
      expression: "form.problemstatement"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Justification",
      prop: "solution"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      size: "small"
    },
    model: {
      value: _vm.form.solution,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "solution", $$v);
      },
      expression: "form.solution"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Value Proposition",
      prop: "valueproposition"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      size: "small"
    },
    model: {
      value: _vm.form.valueproposition,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "valueproposition", $$v);
      },
      expression: "form.valueproposition"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Scope",
      prop: "scope"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    on: {
      change: function change($event) {
        return _vm.updateFormTitle();
      }
    },
    model: {
      value: _vm.form.scope,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "scope", $$v);
      },
      expression: "form.scope"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: "Offering"
    }
  }), _vm._v(" "), _vm.offering.linkedProducts.length > 0 ? _c("el-radio-button", {
    attrs: {
      label: "Product"
    }
  }) : _vm._e(), _vm._v(" "), _vm.offering.linkedServices.length > 0 ? _c("el-radio-button", {
    attrs: {
      label: "Service"
    }
  }) : _vm._e()], 1)], 1), _vm._v(" "), _vm.form.scope === "Offering" ? _c("el-form-item", {
    attrs: {
      label: "ScopeName",
      prop: "scopename"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      size: "small",
      disabled: ""
    },
    model: {
      value: _vm.scopename,
      callback: function callback($$v) {
        _vm.scopename = $$v;
      },
      expression: "scopename"
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.form.scope === "Product" && _vm.offering.linkedProducts.length > 0 ? _c("el-form-item", {
    attrs: {
      label: "ScopeName",
      prop: "scopename"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      size: "mini"
    },
    model: {
      value: _vm.scopename,
      callback: function callback($$v) {
        _vm.scopename = $$v;
      },
      expression: "scopename"
    }
  }, _vm._l(_vm.offering.linkedProducts, function (product, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: product.product.title,
        value: product.product.title
      }
    });
  }), 1)], 1) : _vm._e(), _vm._v(" "), _vm.form.scope === "Service" ? _c("el-form-item", {
    attrs: {
      label: "ScopeName",
      prop: "scopename"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      size: "mini"
    },
    model: {
      value: _vm.scopename,
      callback: function callback($$v) {
        _vm.scopename = $$v;
      },
      expression: "scopename"
    }
  }, _vm._l(_vm.offering.linkedServices, function (service, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: service.service.title,
        value: service.service.title
      }
    });
  }), 1)], 1) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "text-right"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.goToStep("step2");
      }
    }
  }, [_vm._v("Next")])], 1)], 1) : _vm._e()])], 1), _vm._v(" "), _c("el-form", {
    ref: "form_step2",
    attrs: {
      model: _vm.form,
      "label-width": "200px"
    }
  }, [_c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.activeStep === "step2" ? _c("div", {
    staticStyle: {
      "animation-fill-mode": "forwards"
    }
  }, [_c("table", {
    staticStyle: {
      "table-layout": "fixed"
    },
    attrs: {
      width: "100%"
    }
  }, [_c("tr", [_c("th", {
    staticClass: "text-right"
  }, [_vm._v("Offering Title")]), _vm._v(" "), _c("td", [_vm._v(_vm._s(_vm.form.title))])]), _vm._v(" "), _c("tr", [_c("th", {
    staticClass: "text-right"
  }, [_vm._v("Offering Description")]), _vm._v(" "), _c("td", [_vm._v(_vm._s(_vm.form.description))])]), _vm._v(" "), _c("tr", [_c("th", {
    staticClass: "text-right"
  }, [_vm._v("Background")]), _vm._v(" "), _c("td", [_vm._v(_vm._s(_vm.form.problemstatement))])]), _vm._v(" "), _c("tr", [_c("th", {
    staticClass: "text-right"
  }, [_vm._v("Justification")]), _vm._v(" "), _c("td", [_vm._v(_vm._s(_vm.form.solution))])]), _vm._v(" "), _c("tr", [_c("th", {
    staticClass: "text-right"
  }, [_vm._v("Scope")]), _vm._v(" "), _c("td", [_vm._v(_vm._s(_vm.scope))])]), _vm._v(" "), _c("tr", [_c("th", {
    staticClass: "text-right"
  }, [_vm._v("Scopename")]), _vm._v(" "), _c("td", [_vm._v(_vm._s(_vm.scopename))])]), _vm._v(" "), _c("tr", [_c("th", {
    staticClass: "text-right"
  }, [_vm._v("Value Proposition")]), _vm._v(" "), _c("td", [_vm._v(_vm._s(_vm.form.valueproposition))])])]), _vm._v(" "), _c("div", {
    staticClass: "text-right"
  }, [_c("el-button", {
    attrs: {
      type: "secondary",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.goToStep("step1");
      }
    }
  }, [_vm._v("Previous")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.createOfferingIdea();
      }
    }
  }, [_vm._v("Create")])], 1)]) : _vm._e()])], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;