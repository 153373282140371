"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/taggedTemplateLiteral.js"));
var _vueSwatches = _interopRequireDefault(require("vue-swatches"));
var _color = require("@/utils/color");
var _templateObject, _templateObject2;
var _default2 = exports.default = {
  name: 'AddIncrement',
  components: {
    VSwatches: _vueSwatches.default
  },
  props: {
    offeringRoadmapId: {
      type: String,
      required: true,
      default: ''
    },
    release: {
      type: Object,
      required: true,
      default: function _default() {
        return {};
      }
    },
    settings: {
      type: Object,
      required: true,
      default: function _default() {
        return {
          version: 1,
          roadmap: {},
          release: {
            timeline: 6,
            color: {
              title: 'rgba(51, 152, 219, 1.0)',
              column: 'rgba(51, 152, 219, 1.0)'
            }
          },
          increment: {
            prefix: 'increment',
            timeline: 1,
            delete: 'disabled',
            color: {
              title: 'rgba(189, 195, 200, 1.0)',
              column: 'rgba(189, 195, 200, 1.0)'
            }
          }
        };
      }
    }
  },
  data: function data() {
    var validateTitle = function validateTitle(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter title'));
      } else if (value.length > 50) {
        callback(new Error('Title should be less than 50 characters'));
      } else {
        callback();
      }
    };
    var validateDescription = function validateDescription(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter description'));
      } else {
        callback();
      }
    };
    var validateDate = function validateDate(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter date'));
      } else {
        callback();
      }
    };
    return {
      incrementRules: {
        title: [{
          required: true,
          trigger: 'blur',
          validator: validateTitle
        }],
        description: [{
          required: true,
          trigger: 'blur',
          validator: validateDescription
        }],
        startdate: [{
          required: true,
          trigger: 'blur',
          validator: validateDate
        }],
        duedate: [{
          required: true,
          trigger: 'blur',
          validator: validateDate
        }]
      },
      loading: false,
      increment_form: {
        title: '',
        description: '',
        startdate: '',
        duedate: '',
        style: {
          color: '#ffbf00'
        }
      }
    };
  },
  computed: {},
  watch: {
    refresh: function refresh(val) {
      this.$refs['addIncrementForm'].resetFields();
    }
  },
  created: function created() {},
  mounted: function mounted() {
    this.$refs['addIncrementForm'].resetFields();
    this.increment_form.startdate = this.release.linkedIncrements[this.release.linkedIncrements.length - 1].offeringRoadmapReleaseIncrement.duedate;
    this.increment_form.duedate = this.$moment(this.increment_form.startdate).add(this.settings.increment.timeline, 'M');
    this.increment_form.style.color = (0, _color.rgba2hex)(this.settings.increment.color.title);
    this.updateDueDate();
  },
  methods: {
    updateDueDate: function updateDueDate(value) {},
    closeDialog: function closeDialog() {
      this.$emit('close');
    },
    create: function create() {
      var _this = this;
      this.$refs['addIncrementForm'].validate(function (valid) {
        if (valid) {
          _this.loading = true;
          _this.$apollo.mutate({
            // Query
            mutation: window._gql(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["mutation createOfferingRdmRlIncrement($increment: CreateOfferingRdmRlIncrementInput!)\n          {\n            createOfferingRdmRlIncrement(input: $increment) {\n              id\n              title\n              description\n              additionalattributes\n              approvers\n              status\n              priority\n              startdate\n              duedate\n              createdby\n              createdat\n              modifiedby\n              modifiedat\n            }\n          }"]))),
            // Parameters
            variables: {
              'increment': {
                'offeringReleaseId': _this.release.id,
                'title': _this.increment_form.title,
                'description': _this.increment_form.description,
                'status': _this.increment_form.status,
                'priority': _this.increment_form.priority,
                'startdate': _this.$moment(_this.increment_form.startdate, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
                'duedate': _this.$moment(_this.increment_form.duedate, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
                'additionalattributes': {
                  'duration': 1,
                  'style': {
                    'color': _this.increment_form.style.color
                  }
                }
              }
            }
          }).then(function (data) {
            _this.loading = false;
            _this.updateReleaseDueDate(data.data.createOfferingRdmRlIncrement);
          }).catch(function (error) {
            // Error
            _this.$notify({
              title: 'Error',
              message: error,
              type: 'error',
              duration: 2000
            });
            _this.loading = false;
          });
        } else {
          // this.loading = false
          return false;
        }
      });
    },
    updateReleaseDueDate: function updateReleaseDueDate(increment) {
      var _this2 = this;
      this.loading = true;
      this.$apollo.mutate({
        // Query
        mutation: window._gql(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["mutation updateOfferingRoadmapRelease($release: UpdateOfferingRoadmapReleaseInput!)\n        {\n          updateOfferingRoadmapRelease(input: $release) {\n            id\n            title\n            description\n            additionalattributes\n            approvers\n            status\n            priority\n            startdate\n            duedate\n            createdby\n            createdat\n            modifiedby\n            modifiedat\n          }\n        }"]))),
        // Parameters
        variables: {
          'release': {
            'id': this.release.id,
            'duedate': increment.duedate
          }
        }
      }).then(function (data) {
        _this2.loading = false;
        _this2.$emit('create', increment);
      }).catch(function (error) {
        // Error
        _this2.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this2.loading = false;
      });
    },
    truncateString: function truncateString(string, length) {
      return this._.truncate(string, {
        'length': length,
        'separator': '...'
      });
    },
    resetForm: function resetForm() {
      this.increment_form = {
        title: '',
        description: '',
        startdate: '',
        duedate: '',
        style: {
          color: '#ffbf00'
        }
      };
    }
  }
};