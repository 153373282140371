"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-tabs", {
    attrs: {
      value: "details"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "Details",
      name: "details"
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.release,
      "label-width": "120px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Title"
    }
  }, [_c("el-input", {
    attrs: {
      "show-word-limit": true,
      maxlength: 50,
      size: "mini"
    },
    model: {
      value: _vm.release.title,
      callback: function callback($$v) {
        _vm.$set(_vm.release, "title", $$v);
      },
      expression: "release.title"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Description"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      size: "mini"
    },
    model: {
      value: _vm.release.description,
      callback: function callback($$v) {
        _vm.$set(_vm.release, "description", $$v);
      },
      expression: "release.description"
    }
  })], 1), _vm._v(" "), _c("el-row", [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Start Date",
      prop: "startdate"
    }
  }, [_c("el-date-picker", {
    attrs: {
      type: "date",
      placeholder: "Pick a day",
      size: "mini"
    },
    model: {
      value: _vm.release.startdate,
      callback: function callback($$v) {
        _vm.$set(_vm.release, "startdate", $$v);
      },
      expression: "release.startdate"
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "End Date",
      prop: "duedate"
    }
  }, [_c("el-date-picker", {
    attrs: {
      type: "date",
      placeholder: "Pick a day",
      size: "mini",
      disabled: ""
    },
    model: {
      value: _vm.release.duedate,
      callback: function callback($$v) {
        _vm.$set(_vm.release, "duedate", $$v);
      },
      expression: "release.duedate"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Color"
    }
  }, [_c("v-swatches", {
    attrs: {
      inline: ""
    },
    model: {
      value: _vm.release.additionalattributes.style.color,
      callback: function callback($$v) {
        _vm.$set(_vm.release.additionalattributes.style, "color", $$v);
      },
      expression: "release.additionalattributes.style.color"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: "Increment",
      name: "increment"
    }
  }, [_c("el-table", {
    attrs: {
      data: _vm.release.linkedIncrements,
      size: "mini",
      stripe: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "expand"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-table", {
          attrs: {
            data: _vm.computeSubEntitiesForIncrements(scope.$index),
            size: "micro",
            stripe: ""
          }
        }, [_c("el-table-column", {
          attrs: {
            prop: "title",
            label: "Title"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(scope) {
              return [scope.row.__typename === "ProductFeature" ? _c("el-tag", {
                attrs: {
                  size: "mini"
                }
              }, [_vm._v("Feature")]) : _c("el-tag", {
                attrs: {
                  size: "mini"
                }
              }, [_vm._v("Process")]), _vm._v("\n                  " + _vm._s(scope.row.title) + "\n                ")];
            }
          }], null, true)
        }), _vm._v(" "), _c("el-table-column", {
          attrs: {
            label: "Priority"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(scope) {
              return [_c("el-tag", {
                attrs: {
                  size: "mini"
                }
              }, [_vm._v(_vm._s(scope.row.priority))])];
            }
          }], null, true)
        }), _vm._v(" "), _c("el-table-column", {
          attrs: {
            label: "Status"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(scope) {
              return [_c("el-tag", {
                attrs: {
                  size: "mini"
                }
              }, [_vm._v(_vm._s(scope.row.status))])];
            }
          }], null, true)
        }), _vm._v(" "), _c("el-table-column", {
          attrs: {
            label: "#"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(scope) {
              return [_c("i", {
                staticClass: "fa fa-chain-broken left-right",
                staticStyle: {
                  color: "black",
                  "font-size": "0.8em"
                },
                attrs: {
                  title: "Unlink"
                },
                on: {
                  click: function click($event) {
                    return _vm.unlinkSubEntity(scope.row);
                  }
                }
              })];
            }
          }], null, true)
        })], 1)];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "offeringRoadmapReleaseIncrement.title",
      label: "Title"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Date"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-tag", {
          attrs: {
            size: "mini"
          }
        }, [_vm._v(_vm._s(_vm.formatDate(scope.row.offeringRoadmapReleaseIncrement.startdate)))]), _vm._v(" To "), _c("el-tag", {
          attrs: {
            size: "mini"
          }
        }, [_vm._v(_vm._s(_vm.formatDate(scope.row.offeringRoadmapReleaseIncrement.duedate)))])];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Type"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-tag", {
          attrs: {
            size: "mini"
          }
        }, [_vm._v(_vm._s(_vm.computeSubEntitiesForIncrements(scope.$index).length))]), _vm._v(" Feature/Process\n          ")];
      }
    }])
  }), _vm._v(" "), _vm.settings.increment.delete !== "disabled" ? _c("el-table-column", {
    attrs: {
      label: "#"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("i", {
          staticClass: "el-icon-delete",
          staticStyle: {
            "font-size": "1em",
            color: "black"
          },
          on: {
            click: function click($event) {
              return _vm.deleteIncrement(scope.$index, scope.row.offeringRoadmapReleaseIncrement.id);
            }
          }
        })];
      }
    }], null, false, 1330241572)
  }) : _vm._e()], 1)], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "text-right"
  }, [_c("el-button-group", [_c("el-button", {
    attrs: {
      type: "danger",
      icon: "el-icon-delete",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.deleteRelease();
      }
    }
  }, [_vm._v("Delete")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary",
      icon: "el-icon-edit",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.update();
      }
    }
  }, [_vm._v("Update")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "secondary",
      icon: "el-icon-close",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.closeDialog();
      }
    }
  }, [_vm._v("Close")])], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;