"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _popper = _interopRequireDefault(require("popper.js"));
var _vueClickOutside = _interopRequireDefault(require("vue-click-outside"));
// @vue/component
var _default = exports.default = {
  components: {
    // Popper,
  },
  directives: {
    ClickOutside: _vueClickOutside.default
  },
  props: {
    boundariesElement: {
      type: String,
      default: 'body'
    }
  },
  data: function data() {
    return {
      opened: false,
      contextData: {}
    };
  },
  computed: {
    isVisible: function isVisible() {
      return this.opened;
    }
  },
  beforeDestroy: function beforeDestroy() {
    if (this.popper !== undefined) {
      this.popper.destroy();
    }
  },
  methods: {
    open: function open(evt, contextData) {
      var _this = this;
      this.opened = true;
      this.contextData = contextData;
      if (this.popper) {
        this.popper.destroy();
      }
      this.popper = new _popper.default(this.referenceObject(evt), this.$refs.popper, {
        placement: 'right-start',
        modifiers: {
          preventOverflow: {
            boundariesElement: document.querySelector(this.boundariesElement)
          }
        }
      });

      // Recalculate position
      this.$nextTick(function () {
        _this.popper.scheduleUpdate();
      });
    },
    close: function close() {
      this.opened = false;
      this.contextData = null;
    },
    referenceObject: function referenceObject(evt) {
      var left = evt.clientX;
      var top = evt.clientY;
      var right = left + 1;
      var bottom = top + 1;
      var clientWidth = 1;
      var clientHeight = 1;
      function getBoundingClientRect() {
        return {
          left: left,
          top: top,
          right: right,
          bottom: bottom
        };
      }
      var obj = {
        getBoundingClientRect: getBoundingClientRect,
        clientWidth: clientWidth,
        clientHeight: clientHeight
      };
      return obj;
    }
  }
};