"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-row", {
    staticClass: "mt-10",
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-card", [_vm.groups.length > 0 ? _c("el-tabs", {
    attrs: {
      value: "Individual"
    }
  }, _vm._l(_vm.groups, function (group, index) {
    return _c("el-tab-pane", {
      key: index,
      attrs: {
        label: group.name,
        name: group.name
      }
    }, [_c("div", {
      staticClass: "chart-container"
    }, [_c("simple-graph-chart", {
      attrs: {
        height: "100%",
        width: "100%"
      }
    })], 1)]);
  }), 1) : _c("div", {
    staticClass: "text-center"
  }, [_c("span", [_vm._v("No user types / groups created. Create a group to update details ")])])], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    staticClass: "mt-10"
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-card", [_c("div", {
    staticClass: "text-right"
  }, [_c("el-button-group", {
    attrs: {
      size: "mini"
    }
  }, [_c("el-tooltip", {
    attrs: {
      effect: "dark",
      content: "Go To Define User Value Proposition",
      placement: "top-start"
    }
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      icon: "el-icon-arrow-left"
    },
    on: {
      click: _vm.gotoDefineUserValueProposition
    }
  }, [_vm._v("Previous")])], 1)], 1)], 1)])], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;