"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _UserJourneyMapSimple = _interopRequireDefault(require("./UserJourneyMap/UserJourneyMapSimple.vue"));
var _UserJourneyMapMatrix = _interopRequireDefault(require("./UserJourneyMap/UserJourneyMapMatrix.vue"));
var _default2 = exports.default = {
  name: 'DefineUserJourney',
  components: {
    UserJourneyMapSimpleComponent: _UserJourneyMapSimple.default,
    UserJourneyMapMatrixComponent: _UserJourneyMapMatrix.default
  },
  props: {
    userTypes: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      activeUserType: {},
      addUserJourneyMapDialog: false,
      toggleJourneyMapSelection: true,
      journeyMapData: [{
        type: 'individual',
        description: 'description',
        stages: [{
          name: 'Stage 1',
          tasks: [{
            'name': '',
            'task': 'asd',
            'intention': 'asd',
            'pains': [{
              'pain_point': 'asd',
              'description': 'asd',
              'value': '1',
              'priority': 1,
              'relations': [{
                'key': 3,
                'candidate_feature': 'asd',
                'description': 'asd',
                'value': '2',
                'priority': 0
              }]
            }],
            'pleasers': [{
              'pleaser': 'asd',
              'description': 'asd',
              'value': '2',
              'priority': 1,
              'relations': [{
                'key': 3,
                'candidate_feature': 'asd',
                'description': 'asd',
                'value': '2',
                'priority': 0
              }]
            }]
          }]
        }, {
          name: 'Stage 2',
          tasks: [{
            'name': '',
            'task': 'asd',
            'intention': 'asd',
            'pains': [{
              'pain_point': 'asd',
              'description': 'asd',
              'value': '1',
              'priority': 1,
              'relations': [{
                'key': 3,
                'candidate_feature': 'asd',
                'description': 'asd',
                'value': '2',
                'priority': 0
              }]
            }],
            'pleasers': [{
              'pleaser': 'asd',
              'description': 'asd',
              'value': '2',
              'priority': 1,
              'relations': [{
                'key': 3,
                'candidate_feature': 'asd',
                'description': 'asd',
                'value': '2',
                'priority': 0
              }]
            }]
          }]
        }]
      }]
    };
  },
  mounted: function mounted() {
    this.$eventHub.$emit('process-step-load', 'define-user-journey');
    this.$eventHub.$on('switch-ujm-view', this.switchView);
  },
  beforeDestroy: function beforeDestroy() {
    // Stop listening
    this.$eventHub.$off('switch-ujm-view');
  },
  methods: {
    switchView: function switchView() {
      this.toggleJourneyMapSelection = !this.toggleJourneyMapSelection;
    },
    handleClick: function handleClick(tab, event) {
      this.setActiveUserType(this.userTypes[tab.index]);
    },
    objectSpanMethod: function objectSpanMethod(_ref) {
      var row = _ref.row,
        column = _ref.column,
        rowIndex = _ref.rowIndex,
        columnIndex = _ref.columnIndex;
      if (columnIndex === 0) {
        if (rowIndex % 2 === 0) {
          return {
            rowspan: 2,
            colspan: 1
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0
          };
        }
      }
    },
    setActiveUserType: function setActiveUserType(user_type) {
      this.activeUserType = user_type;
    },
    getPriorityIcon: function getPriorityIcon(priority) {
      return 'priority-white-' + priority;
    },
    computeJourneyMapTitle: function computeJourneyMapTitle() {
      if (this.toggleJourneyMapSelection) {
        return 'Add User Journey Map - Simple';
      } else {
        return 'Add User Journey Map - Matrix';
      }
    },
    generateToolTipContent: function generateToolTipContent() {
      if (this.toggleJourneyMapSelection) {
        return 'Switch Journey map to Matrix Mode';
      } else {
        return 'Switch Journey map to Simple Mode';
      }
    },
    goToDefineUserDetails: function goToDefineUserDetails() {
      this.$emit('go-prev');
    },
    goToMockupStoryboard: function goToMockupStoryboard() {
      this.$emit('go-next');
    }
  }
};