"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _resolution = require("@/utils/resolution");
var _default2 = exports.default = {
  name: 'EntityLane',
  components: {},
  props: {
    x: {
      type: Number,
      required: true,
      default: 0
    },
    y: {
      type: Number,
      required: true,
      default: 0
    },
    width: {
      type: Number,
      required: true,
      default: 0
    },
    height: {
      type: Number,
      required: true,
      default: 0
    },
    headerWidth: {
      type: Number,
      required: true,
      default: 0
    },
    index: {
      type: Number,
      required: true,
      default: 0
    },
    isMinimized: {
      type: Boolean,
      default: false
    },
    entity: {
      type: Object,
      default: function _default() {
        return {
          title: '',
          description: ''
        };
      }
    }
  },
  data: function data() {
    return {
      loading: false
    };
  },
  computed: {
    computeLeftEntityMaskWidth: function computeLeftEntityMaskWidth() {
      var width = 4;
      return width;
      // return width * screenRatio()
    },
    computeRightEntityMaskWidth: function computeRightEntityMaskWidth() {
      var width = 150;
      return width * (0, _resolution.screenRatio)();
    }
  },
  watch: {},
  mounted: function mounted() {},
  created: function created() {},
  methods: {
    toggleLane: function toggleLane() {
      this.$emit('toggle-lane', this.index, !this.isMinimized);
    },
    dynamicSvgTextLength: function dynamicSvgTextLength(string) {
      var pixelMultipler = 8;
      if (string.length < 4) {
        pixelMultipler = 13;
      } else if (string.length < 7) {
        pixelMultipler = 10;
      }
      return string.length * pixelMultipler;
    },
    truncateTitle: function truncateTitle(string) {
      return this._.truncate(string, {
        'length': this.isMinimized ? 22 : 52
      });
    },
    truncateString: function truncateString(string, length) {
      return this._.truncate(string, {
        'length': length
      });
    }
  }
};