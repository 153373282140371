"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("g", {
    style: _vm.computeOpacityStyle(),
    attrs: {
      refs: "wow-component"
    },
    on: {
      mouseover: _vm.onMouseOver,
      mouseleave: _vm.onMouseLeave,
      click: _vm.emitClick
    }
  }, [!_vm.isStageRelation ? _c("rect", {
    attrs: {
      x: _vm.x + 3,
      y: _vm.y + 5,
      rx: "3",
      width: "105",
      height: "25",
      fill: "#38aad9",
      "stroke-width": "1",
      stroke: "#38aad9"
    }
  }) : _vm._e(), _vm._v(" "), _vm.isStageRelation ? _c("polygon", {
    attrs: {
      id: _vm.generateUniqueID(),
      x: _vm.x + 3,
      y: _vm.y + 5,
      points: _vm.generatePath(),
      fill: "#38aad9",
      "stroke-width": "1",
      stroke: "#38aad9"
    }
  }) : _vm._e(), _vm._v(" "), _c("foreignObject", {
    staticStyle: {
      overflow: "visible"
    },
    attrs: {
      x: _vm.x - 5,
      y: _vm.y + 9,
      width: 22,
      height: 22
    }
  }, [_c("div", {
    staticStyle: {
      overflow: "visible"
    },
    attrs: {
      draggable: ""
    },
    on: {
      drag: _vm.onDrag
    }
  }, [_c("svg-icon", {
    style: _vm.computeStyle(),
    attrs: {
      id: _vm.generateUniqueID(),
      "icon-class": "wow_blue"
    }
  })], 1)]), _vm._v(" "), !_vm.isMinimized ? _c("foreignObject", {
    staticStyle: {
      "background-color": "white",
      "border-radius": "5px",
      color: "red",
      "text-align": "center",
      "font-size": "0.8em",
      "font-weight": "bold"
    },
    attrs: {
      x: _vm.x + 96,
      y: _vm.y + 6,
      width: 10,
      height: 10
    }
  }, [_c("span", [_vm._v(_vm._s(_vm.computePriority()))])]) : _vm._e(), _vm._v(" "), !_vm.isMinimized ? _c("foreignObject", {
    staticStyle: {
      "background-color": "white",
      "border-radius": "5px",
      color: "red",
      "text-align": "center",
      "font-size": "0.8em",
      "font-weight": "bold"
    },
    attrs: {
      x: _vm.x + 96,
      y: _vm.y + 18,
      width: 10,
      height: 10
    }
  }, [_c("svg-icon", {
    staticStyle: {
      color: "blue"
    },
    attrs: {
      "icon-class": _vm.computeCustomerValueIcon()
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.showLabel && !_vm.isMinimized ? _c("foreignObject", {
    staticStyle: {
      fill: "#4672c4",
      color: "white",
      "font-size": "0.57em"
    },
    attrs: {
      x: _vm.x + 15,
      y: _vm.y + 6,
      width: 78,
      height: 23
    }
  }, [_c("span", [_vm._v(_vm._s(_vm.truncateString(_vm.label, 57)))])]) : _vm._e(), _vm._v(" "), _c("filter", {
    attrs: {
      id: "shadow",
      "color-interpolation-filters": "sRGB"
    }
  }, [_c("feDropShadow", {
    attrs: {
      dx: "2",
      dy: "2",
      "std-deviation": "3",
      "flood-opacity": "0.5"
    }
  })], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;