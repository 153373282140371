"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }]
  }, [_c("el-row", {
    staticStyle: {
      "border-bottom": "2px solid #304156",
      "background-color": "#eee",
      padding: "10pt 10pt 5pt 10pt"
    },
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Filter",
      placement: "right"
    }
  }, [_c("div", {
    attrs: {
      slot: "content"
    },
    slot: "content"
  }, [_vm._v("\n          " + _vm._s(_vm.selectedEntityList.length) + " of " + _vm._s(_vm.totalCount) + " Entities shown\n        ")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary",
      size: "micro",
      icon: "el-icon-finished"
    },
    on: {
      click: function click($event) {
        _vm.entitiesSelectDialog = true;
      }
    }
  }, [_vm._v("Choose Entities")])], 1), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary",
      size: "micro",
      icon: "el-icon-refresh-left"
    },
    on: {
      click: function click($event) {
        return _vm.resetFilter();
      }
    }
  }, [_vm._v("Reset filter")]), _vm._v(" "), _c("el-tooltip", {
    attrs: {
      content: "Toggle Initiatives",
      placement: "top"
    }
  }, [_vm.allLanesExpanded ? _c("el-switch", {
    staticStyle: {
      "margin-left": "20pt"
    },
    model: {
      value: _vm.showInitiatives,
      callback: function callback($$v) {
        _vm.showInitiatives = $$v;
      },
      expression: "showInitiatives"
    }
  }) : _vm._e()], 1), _vm._v(" "), _vm.showInitiatives && _vm.allLanesExpanded ? _c("span", {
    staticStyle: {
      color: "#304174",
      "font-size": "1.1em",
      "padding-top": "6pt",
      "font-weight": "bold"
    }
  }, [_vm._v("\n          Initiatives Visible\n        ")]) : _vm._e(), _vm._v(" "), !_vm.showInitiatives && _vm.allLanesExpanded ? _c("span", {
    staticStyle: {
      color: "darkred",
      "font-size": "1.1em",
      "padding-top": "6pt"
    }
  }, [_vm._v("\n          Initiatives Hidden\n        ")]) : _vm._e()], 1), _vm._v(" "), _c("el-col", {
    staticClass: "text-center",
    attrs: {
      span: 7
    }
  }, [_c("el-dropdown", {
    attrs: {
      size: "mini",
      "split-button": "",
      type: "primary"
    }
  }, [_vm._v("\n        Timeline - " + _vm._s(_vm.timeFrameMarks[_vm.timeFrame]) + "\n        "), _c("el-dropdown-menu", {
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, _vm._l(_vm.timeFrameMarks, function (year, index) {
    return _c("el-dropdown-item", {
      key: index,
      staticClass: "text-center"
    }, [_c("el-button", {
      attrs: {
        type: _vm.computeTimeFrameButtonType(index),
        size: "mini"
      },
      on: {
        click: function click($event) {
          return _vm.updateTimeFrame(index);
        }
      }
    }, [_vm._v(_vm._s(year))])], 1);
  }), 1)], 1)], 1), _vm._v(" "), _c("el-col", {
    staticClass: "text-right",
    attrs: {
      span: 6
    }
  }, [_c("el-slider", {
    staticClass: "sliderZoomRoadmap",
    attrs: {
      step: 5,
      min: 50,
      max: 200,
      "show-input": "",
      "input-size": "mini"
    },
    on: {
      change: function change($event) {
        return _vm.zoomJourney();
      }
    },
    model: {
      value: _vm.roadmapZoom,
      callback: function callback($$v) {
        _vm.roadmapZoom = $$v;
      },
      expression: "roadmapZoom"
    }
  })], 1), _vm._v(" "), _c("el-col", {
    staticClass: "text-right",
    attrs: {
      span: 3
    }
  }, [_c("el-dropdown", {
    attrs: {
      size: "mini",
      "split-button": "",
      type: "primary"
    },
    on: {
      command: _vm.handleSettingsDropDownCommand
    }
  }, [_vm._v("\n        Settings\n        "), _c("el-dropdown-menu", {
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, [_c("el-dropdown-item", {
    attrs: {
      icon: _vm.loading ? "el-icon-loading" : "el-icon-refresh",
      command: "refreshRoadmap"
    }
  }, [_vm._v("Refresh Roadmap")]), _vm._v(" "), _c("el-dropdown-item", {
    attrs: {
      icon: "el-icon-refresh-left",
      command: "resetTimeline"
    }
  }, [_vm._v("Reset Timeline")])], 1)], 1)], 1)], 1), _vm._v(" "), _vm.entities.length > 0 ? _c("el-row", {
    staticClass: "roadmap-container",
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    staticClass: "no-padding",
    attrs: {
      span: 24
    }
  }, [_c("svg", {
    staticClass: "roadmapZoomer",
    staticStyle: {
      "user-select": "none",
      overflow: "auto"
    },
    attrs: {
      id: "roadmap",
      height: _vm.computeRoadmapHeight() + 400 + "px",
      width: _vm.computeCanvasWidth,
      xmlns: "http://www.w3.org/2000/svg",
      version: "1.1"
    }
  }, [_c("timeline-component", {
    attrs: {
      width: _vm.computeTimlineWidth,
      height: _vm.roadmap.entity.timeline.height,
      "active-timeline": _vm.activeTimeline,
      "next-years": 10,
      "previous-years": 1,
      "pos-x": _vm.timelineX,
      timeframe: _vm.timeFrame
    },
    on: {
      drag: _vm.onTimelineDragged
    }
  }), _vm._v(" "), _vm._l(_vm.selectedEntityList, function (entity, index) {
    return _c("g", {
      key: "entity_" + index,
      attrs: {
        refs: "entity_lane"
      }
    }, [_c("entity-lane-component", {
      attrs: {
        x: _vm.computeStartX,
        y: _vm.computeEntityY(index),
        width: _vm.computeEntityLaneWidth,
        height: _vm.computeEntityLaneHeight(entity, index),
        "header-width": _vm.computeEntityHeaderWidth,
        entity: entity,
        index: index,
        "is-minimized": _vm.selectedEntityList_pos[index].minimized
      },
      on: {
        "toggle-lane": _vm.toggleLane
      }
    }, _vm._l(entity.linkedInitiatives, function (objective, objective_index) {
      return _c("g", {
        key: "objective_" + objective_index,
        attrs: {
          refs: "objective_lane"
        }
      }, [_c("objective-component", {
        attrs: {
          x: _vm.selectedEntityList_pos[index].objectives[objective_index].x,
          y: _vm.computeEntityY(index) + _vm.computeObjectiveY(index, objective_index) + 2,
          width: _vm.selectedEntityList_pos[index].objectives[objective_index].width,
          height: _vm.computeObjectiveHeight(_vm.selectedEntityList_pos[index], index, _vm.selectedEntityList_pos[index].objectives[objective_index].initiatives.length),
          "entity-index": index,
          index: objective_index,
          objective: objective.initiative,
          "is-minimized": _vm.selectedEntityList_pos[index].minimized,
          "show-initiatives": _vm.showInitiatives
        },
        on: {
          click: _vm.displayInitiativeDialog,
          drag: _vm.onObjectiveDragged,
          "drag-stop": _vm.onObjectiveDragStop,
          resize: _vm.onObjectiveResize,
          "resize-stop": _vm.onObjectiveResizeStop
        }
      }, [!_vm.selectedEntityList_pos[index].minimized && _vm.showInitiatives ? _c("g", _vm._l(objective.initiative.linkedSubInitiatives, function (initiative, initiative_index) {
        return _c("g", {
          key: "initiative_" + initiative_index,
          attrs: {
            refs: "initiative_lane"
          }
        }, [_vm.timeFrame < 4 ? _c("g", {
          attrs: {
            refs: "display_initiatives"
          }
        }, [_c("initiative-component", {
          attrs: {
            x: _vm.selectedEntityList_pos[index].objectives[objective_index].initiatives[initiative_index].x,
            y: _vm.computeEntityY(index) + _vm.computeObjectiveY(index, objective_index) + 30 + initiative_index * 17,
            width: _vm.selectedEntityList_pos[index].objectives[objective_index].initiatives[initiative_index].width,
            height: 15,
            "entity-index": index,
            "objective-index": objective_index,
            index: initiative_index,
            "is-minimized": _vm.selectedEntityList_pos[index].minimized,
            initiative: initiative
          },
          on: {
            drag: _vm.onInitiativeDragged,
            "drag-stop": _vm.onInitiativeDragStop
          }
        })], 1) : _c("g", {
          attrs: {
            refs: "display_subinitiative_notification"
          }
        }, [_c("rect", {
          attrs: {
            x: _vm.timelineX + _vm.selectedEntityList_pos[index].objectives[objective_index].x + 5,
            y: _vm.computeEntityY(index) + _vm.computeObjectiveY(index, objective_index) + 25,
            width: 15,
            height: 15,
            rx: "5",
            fill: "none",
            stroke: "white",
            "stroke-width": "3"
          }
        }), _vm._v(" "), _c("rect", {
          attrs: {
            x: _vm.timelineX + _vm.selectedEntityList_pos[index].objectives[objective_index].x + 5,
            y: _vm.computeEntityY(index) + _vm.computeObjectiveY(index, objective_index) + 25,
            width: 15,
            height: 15,
            rx: "5",
            fill: "#68C1DE"
          }
        }), _vm._v(" "), _c("foreignObject", {
          staticClass: "text-left",
          staticStyle: {
            overflow: "hidden",
            "border-radius": "2pt"
          },
          attrs: {
            x: _vm.timelineX + _vm.selectedEntityList_pos[index].objectives[objective_index].x + 5,
            y: _vm.computeEntityY(index) + _vm.computeObjectiveY(index, objective_index) + 25,
            width: 15,
            height: 15
          }
        }, [_c("el-popover", {
          attrs: {
            placement: "top-start",
            width: "400",
            trigger: "hover",
            "open-delay": 400,
            "close-delay": 400,
            title: entity.linkedInitiatives[objective_index].initiative.linkedSubInitiatives.length + " sub initiative found",
            content: "Change timeline to 1Y, 2Y or 3Y to view sub initiatives"
          }
        }, [_c("div", {
          staticStyle: {
            "font-size": "0.7em",
            "font-weight": "600",
            "text-align": "center",
            color: "#fff",
            padding: "2pt",
            "word-wrap": "break-word"
          },
          attrs: {
            slot: "reference"
          },
          slot: "reference"
        }, [_vm._v("\n                              " + _vm._s(entity.linkedInitiatives[objective_index].initiative.linkedSubInitiatives.length) + "\n                            ")])])], 1)])]);
      }), 0) : _vm._e(), _vm._v("\n                // END TODO\n                ")])], 1);
    }), 0)], 1);
  }), _vm._v(" "), _c("g", {
    attrs: {
      refs: "global_toggle"
    }
  }, [_c("foreignObject", {
    staticStyle: {
      "background-color": "white",
      "text-align": "right",
      "vertical-align": "middle"
    },
    attrs: {
      x: 24,
      y: 5,
      width: "150",
      height: "45"
    }
  }, [!_vm.allLanesExpanded ? _c("div", [_c("div", {
    staticStyle: {
      "text-align": "left",
      "vertical-align": "middle",
      width: "80pt",
      "padding-left": "4pt",
      "padding-top": "5pt",
      "background-color": "#304174",
      color: "white",
      border: "solid 1pt darkslategray",
      "border-radius": "3pt",
      "margin-top": "5pt",
      height: "20pt"
    },
    on: {
      click: function click($event) {
        return _vm.toggleAllLanes("expand");
      }
    }
  }, [_vm._v("Expand All\n              "), _c("svg-icon", {
    staticStyle: {
      "font-size": "1.3em",
      "padding-top": "0pt",
      "padding-right": "4pt",
      color: "white",
      float: "right"
    },
    attrs: {
      "icon-class": "arrow-expand-w",
      title: "Shrink Lane"
    }
  })], 1)]) : _c("div", [_c("div", {
    staticStyle: {
      "text-align": "left",
      "vertical-align": "middle",
      width: "80pt",
      "padding-left": "4pt",
      "padding-top": "5pt",
      "background-color": "#304174",
      color: "white",
      border: "solid 1pt darkslategray",
      "border-radius": "3pt",
      "margin-top": "5pt",
      height: "20pt"
    },
    on: {
      click: function click($event) {
        return _vm.toggleAllLanes("collapse");
      }
    }
  }, [_vm._v("Collapse All\n                "), _c("svg-icon", {
    staticStyle: {
      "font-size": "1.3em",
      "padding-top": "0pt",
      "padding-right": "4pt",
      color: "white",
      float: "right"
    },
    attrs: {
      "icon-class": "arrow-shrink-w",
      title: "Expand Lane"
    }
  })], 1)])])]), _vm._v(" "), _vm.isCurrentYear() && _vm.currentDayToX() > _vm.computeStartX && _vm.currentDayToX() < _vm.computeStartX + _vm.computeProductDetailWidth ? _c("g", {
    attrs: {
      refs: "current_day"
    }
  }, [_c("path", {
    attrs: {
      d: _vm.computeCurrentDayPath(),
      fill: "#50B6F2",
      stroke: "#50B6F2",
      "stroke-width": "1"
    }
  }), _vm._v(" "), _c("line", {
    attrs: {
      x1: _vm.currentDayToX(),
      y1: _vm.roadmap.entity.timeline.height + 10,
      x2: _vm.currentDayToX(),
      y2: _vm.currentDayToY(),
      stroke: "#50B6F2",
      "stroke-dasharray": "4"
    }
  })]) : _vm._e()], 2)])], 1) : _c("div", [_c("el-empty", {
    attrs: {
      description: "No entities found"
    }
  })], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Entity List",
      visible: _vm.entitiesSelectDialog,
      width: "40%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.entitiesSelectDialog = $event;
      }
    }
  }, [_c("entity-filter-component", {
    attrs: {
      entities: _vm.entities
    },
    on: {
      "update-search-filter": _vm.updateSearchFilter,
      "filtered-list": _vm.updateSelectedEntityList,
      close: function close($event) {
        _vm.entitiesSelectDialog = false;
      }
    }
  })], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      visible: _vm.objectiveDetailDialog,
      width: "71%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.objectiveDetailDialog = $event;
      }
    }
  }, [_c("span", {
    staticStyle: {
      color: "white",
      "font-size": "1.3em"
    },
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_c("strong", [_vm._v("Objective: ")]), _vm._v(" " + _vm._s(_vm.computeObjectiveDetailsDialogTitle))]), _vm._v(" "), _c("objective-detail-component", {
    attrs: {
      objective: _vm.selectedObjective
    },
    on: {
      close: function close($event) {
        _vm.objectiveDetailDialog = false;
      }
    }
  })], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;