"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.string.link");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "mt-10"
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 3
    }
  }, [_c("el-card", {
    staticClass: "text-center"
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("span", [_vm._v("User Interaction")])]), _vm._v(" "), _vm._l(_vm.users, function (user, index) {
    return _c("div", {
      key: index,
      staticClass: "card-index no-padding",
      attrs: {
        draggable: ""
      },
      on: {
        click: function click($event) {
          _vm.activeUser = user;
        },
        drag: function drag($event) {
          return _vm.setActiveDragElement("user", user, index);
        }
      }
    }, [_c("div", {
      staticClass: "card-info",
      staticStyle: {
        width: "100%"
      }
    }, [_c("div", {
      staticClass: "card-head"
    }, [_c("el-row", [_c("el-col", {
      staticStyle: {
        margin: "auto",
        "padding-top": "7pt"
      },
      attrs: {
        span: 10
      }
    }, [_c("el-avatar", {
      attrs: {
        size: "small",
        src: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"
      }
    })], 1), _vm._v(" "), _c("el-col", {
      staticClass: "pl-10",
      attrs: {
        span: 14
      }
    }, [_c("h3", [_vm._v(_vm._s(user.title))])])], 1)], 1)])]);
  })], 2)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 18
    }
  }, [_c("el-card", {
    staticClass: "text-center",
    attrs: {
      id: "myDiagramDiv",
      header: "Interaction Canvas"
    }
  }, [_c("div", {
    on: {
      drop: _vm.handleDrop,
      dragover: _vm.handleDragOver
    }
  }, [_c("svg", {
    attrs: {
      height: "600px",
      width: "100%",
      xmlns: "http://www.w3.org/2000/svg",
      version: "1.1"
    }
  }, [_c("defs", [_c("marker", {
    attrs: {
      id: "arrow",
      viewBox: "0 0 10 10",
      refX: "5",
      refY: "5",
      markerWidth: "12",
      markerHeight: "12",
      orient: "auto-start-reverse"
    }
  }, [_c("circle", {
    attrs: {
      cx: "5",
      cy: "5",
      r: "2",
      fill: "gray"
    }
  })])]), _vm._v(" "), _vm._l(_vm.wireframes, function (wireframe, index) {
    return _c("g", {
      key: index
    }, [_c("wireframe-component", {
      directives: [{
        name: "dragged",
        rawName: "v-dragged",
        value: _vm.startWireframeMove,
        expression: "startWireframeMove"
      }],
      key: _vm.generateWireframeID(index),
      attrs: {
        id: _vm.generateWireframeID(index),
        layout: wireframe.layout,
        x: wireframe.x,
        y: wireframe.y,
        index: wireframe.key
      },
      on: {
        edit: _vm.editWireframe,
        delete: _vm.deleteWireframe
      }
    }), _vm._v(" "), _c("text", {
      attrs: {
        x: wireframe.x + wireframe.key * 200 + 50,
        y: wireframe.y + wireframe.key * 50 - 5,
        "font-family": "Verdana",
        "font-size": "10",
        fill: "gray",
        "text-anchor": "middle"
      }
    }, [_vm._v("\n                " + _vm._s(wireframe.title) + "\n              ")]), _vm._v(" "), _vm._l(wireframe.link, function (link, sub_index) {
      return _c("path", {
        key: sub_index,
        attrs: {
          d: _vm.computePathPoints(parseInt(wireframe.x + wireframe.key * 200), parseInt(wireframe.y + wireframe.key * 50), link),
          stroke: "gray",
          "stroke-width": "2",
          fill: "none",
          "marker-end": "url(#arrow)"
        }
      });
    })], 2);
  })], 2)])])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 3
    }
  }, [_c("el-card", {
    staticClass: "text-center"
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("span", [_vm._v("Design Layers")])]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    }
  }, [_vm._v("Feature")]), _c("br"), _vm._v(" "), _c("el-button", {
    staticClass: "mt-10",
    attrs: {
      type: "primary",
      size: "mini"
    }
  }, [_vm._v("Users")])], 1), _vm._v(" "), _c("el-card", {
    staticClass: "mt-10 text-center"
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("span", [_vm._v("Design Support")])]), _vm._v(" "), _c("el-input", {
    attrs: {
      size: "mini",
      value: "Design Goals",
      disabled: ""
    }
  }), _vm._v(" "), _c("el-input", {
    attrs: {
      size: "mini",
      value: "Pain Points",
      disabled: ""
    }
  })], 1), _vm._v(" "), _c("el-card", {
    staticClass: "mt-10 text-center"
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("span", [_vm._v("Navigation")])]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    }
  }, [_vm._v("Add Screen")]), _c("br"), _vm._v(" "), _c("el-button", {
    staticClass: "mt-10",
    attrs: {
      type: "primary",
      size: "mini"
    }
  }, [_vm._v("Add Popup")]), _c("br"), _vm._v(" "), _c("el-button", {
    staticClass: "mt-10",
    attrs: {
      type: "primary",
      size: "mini"
    }
  }, [_vm._v("Edit menu")])], 1)], 1)], 1), _vm._v(" "), _c("el-card", {
    staticClass: "mt-10"
  }, [_c("div", {
    staticClass: "text-right"
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.goToDataAndServiceRequirements
    }
  }, [_vm._v("\n        Data & Service Requirements\n      ")])], 1)]), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Edit Wireframe",
      visible: _vm.editWireframeDialog,
      width: "90%",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.editWireframeDialog = $event;
      }
    }
  }, [_c("wireframe-designer-component", {
    attrs: {
      layout: _vm.activeWireframe.layout
    },
    on: {
      "layout-updated": _vm.updateLayout
    }
  }), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      slot: "button",
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.editWireframeDialog = false;
      }
    },
    slot: "button"
  }, [_vm._v("Cancel")]), _vm._v(" "), _c("el-button", {
    attrs: {
      slot: "button",
      type: "primary",
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.editWireframeDialog = false;
      }
    },
    slot: "button"
  }, [_vm._v("Update")])], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;