"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    staticClass: "text-left",
    attrs: {
      offset: 1,
      span: 2
    }
  }, [_c("h4", {
    staticClass: "ml-5 mb-0 pb-0"
  }, [_vm._v("Choose your color")]), _vm._v(" "), _c("v-swatches", {
    attrs: {
      swatches: _vm.bg_swatches,
      "row-length": 4,
      shapes: "circles",
      "swatch-size": "24",
      inline: ""
    },
    model: {
      value: _vm.user.avatarColor,
      callback: function callback($$v) {
        _vm.$set(_vm.user, "avatarColor", $$v);
      },
      expression: "user.avatarColor"
    }
  })], 1), _vm._v(" "), _c("el-col", {
    staticClass: "text-left",
    attrs: {
      span: 3
    }
  }, [_c("avatar", {
    attrs: {
      username: _vm.user_name,
      size: 170,
      "background-color": _vm.user.avatarColor,
      lighten: 80,
      "custom-style": {
        cursor: "pointer",
        height: "170px",
        width: "170px",
        "border-radius": "100px",
        margin: "auto",
        "margin-top": "20px"
      },
      rounded: true,
      src: _vm.computeAvatarURL()
    }
  }), _vm._v(" "), _c("div", {
    staticStyle: {
      "margin-top": "30px",
      "text-align": "left"
    }
  })], 1), _vm._v(" "), _c("el-col", {
    staticStyle: {
      "margin-top": "50px"
    },
    attrs: {
      span: 14
    }
  }, [_c("el-form", {
    attrs: {
      model: _vm.user,
      "label-width": "120px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "First Name"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.user.first_name,
      callback: function callback($$v) {
        _vm.$set(_vm.user, "first_name", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "user.first_name"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Last Name"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.user.last_name,
      callback: function callback($$v) {
        _vm.$set(_vm.user, "last_name", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "user.last_name"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Email"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.user.emailAddress,
      callback: function callback($$v) {
        _vm.$set(_vm.user, "emailAddress", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "user.emailAddress"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Role"
    }
  }, [_c("el-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      id: "business_role",
      placeholder: "Select Role",
      required: "",
      size: "mini"
    },
    model: {
      value: _vm.user.businessRole,
      callback: function callback($$v) {
        _vm.$set(_vm.user, "businessRole", $$v);
      },
      expression: "user.businessRole"
    }
  }, _vm._l(_vm.roles, function (item) {
    return _c("el-option", {
      key: item.value,
      attrs: {
        label: item.label,
        value: item.value
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    staticClass: "text-right"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v("Update")])], 1)], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;