"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "no-padding app-container"
  }, [_c("el-row", [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-row", {
    staticClass: "sub-title-rounded"
  }, [_c("el-col", {
    attrs: {
      span: 4
    }
  }, [_vm._v("\n          Browse Features\n        ")]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("div", [_c("el-checkbox", {
    model: {
      value: _vm.checked_features,
      callback: function callback($$v) {
        _vm.checked_features = $$v;
      },
      expression: "checked_features"
    }
  }, [_vm._v("Features")]), _vm._v(" "), _c("el-checkbox", {
    model: {
      value: _vm.checked_enablers,
      callback: function callback($$v) {
        _vm.checked_enablers = $$v;
      },
      expression: "checked_enablers"
    }
  }, [_vm._v("Enablers")]), _vm._v(" "), _c("el-checkbox", {
    model: {
      value: _vm.checked_orphans,
      callback: function callback($$v) {
        _vm.checked_orphans = $$v;
      },
      expression: "checked_orphans"
    }
  }, [_vm._v("Show Orphans")])], 1)]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini",
      placeholder: "Search"
    },
    model: {
      value: _vm.features_search,
      callback: function callback($$v) {
        _vm.features_search = $$v;
      },
      expression: "features_search"
    }
  }, [_c("el-button", {
    attrs: {
      slot: "append",
      icon: "el-icon-search"
    },
    slot: "append"
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    staticClass: "fr",
    attrs: {
      span: 6
    }
  }, [_c("el-button", {
    staticClass: "blue_button",
    attrs: {
      size: "mini",
      icon: "el-icon-plus"
    },
    on: {
      click: function click($event) {
        return _vm.openAddFeatureModal(false);
      }
    }
  }, [_vm._v("Feature ")]), _vm._v(" "), _c("el-button", {
    staticClass: "blue_button",
    attrs: {
      size: "mini",
      icon: "el-icon-plus"
    },
    on: {
      click: function click($event) {
        return _vm.openAddFeatureModal(true);
      }
    }
  }, [_vm._v("Enabler")])], 1)], 1), _vm._v(" "), _c("el-row", [_c("el-table", {
    staticClass: "refineFeature-featureTable",
    attrs: {
      data: _vm.filteredFeatures,
      "default-sort": {
        prop: "name",
        order: "descending"
      }
    },
    on: {
      "row-click": _vm.selectFeature
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "name",
      label: "Feature Name",
      width: "300",
      sortable: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.has_parent === false ? _c("span", [_vm._v("\n                (ORPHAN)\n              ")]) : _vm._e(), _vm._v(" "), _c("span", [_vm._v("\n                " + _vm._s(scope.row.name) + "\n              ")])];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "value",
      label: "Importance",
      sortable: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.is_stratergic ? _c("svg-icon", {
          attrs: {
            "icon-class": "chess-rook",
            title: "Stratergic"
          }
        }) : _vm._e(), _vm._v(" "), scope.row.value ? _c("svg-icon", {
          attrs: {
            "icon-class": _vm.getValueIcon(scope.row.value),
            title: "Value"
          }
        }) : _vm._e(), _vm._v(" "), scope.row.priority ? _c("svg-icon", {
          attrs: {
            "icon-class": _vm.getPriorityIcon(scope.row.priority),
            title: "Priority"
          }
        }) : _vm._e()];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "enabler",
      label: "Enabler",
      sortable: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.enabler ? _c("svg-icon", {
          attrs: {
            "icon-class": "gear"
          }
        }) : _vm._e()];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "status",
      label: "Status",
      sortable: ""
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "first_added",
      label: "First Added",
      sortable: ""
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "improved_in",
      label: "Improved In",
      sortable: ""
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "prime_user.name",
      label: "Prime User",
      sortable: ""
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "competitors",
      label: "Competitors",
      sortable: ""
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "other_users",
      label: "Other Users",
      sortable: ""
    }
  })], 1)], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    staticStyle: {
      "padding-top": "10px",
      "background-color": "white",
      width: "99%"
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-tabs", {
    attrs: {
      type: "border-card",
      value: "feature_details"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "Feature Details",
      name: "feature_details"
    }
  }, [_c("div", {
    staticStyle: {
      padding: "10px"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-row", [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("span", {
    staticClass: "label"
  }, [_vm._v("Feature Description: ")]), _vm._v(" "), _c("el-input", {
    attrs: {
      autosize: {
        minRows: 4,
        maxRows: 8
      },
      type: "textarea"
    },
    model: {
      value: _vm.selectedFeature.description,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedFeature, "description", $$v);
      },
      expression: "selectedFeature.description"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 9
    }
  }, [_c("el-row", [_c("el-col", {
    attrs: {
      offset: 2,
      span: 12
    }
  }, [_c("span", {
    staticClass: "label"
  }, [_vm._v("Created By: " + _vm._s(_vm.selectedFeature.created_by))])])], 1), _vm._v(" "), _c("el-row", [_c("el-col", {
    attrs: {
      offset: 2,
      span: 12
    }
  }, [_c("span", {
    staticClass: "label"
  }, [_vm._v("Assigned: " + _vm._s(_vm.selectedFeature.assigned_to))])])], 1), _vm._v(" "), _c("el-row", [_c("el-col", {
    attrs: {
      offset: 2,
      span: 12
    }
  }, [_c("p", [_c("el-checkbox", {
    model: {
      value: _vm.selectedFeature.enabler,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedFeature, "enabler", $$v);
      },
      expression: "selectedFeature.enabler"
    }
  }, [_vm._v("Enabler?")])], 1)])], 1), _vm._v(" "), _c("el-row", [_c("el-col", {
    attrs: {
      offset: 2,
      span: 12
    }
  }, [_c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.userAssignedHistoryDialog = true;
      }
    }
  }, [_vm._v("History ")])], 1)], 1)], 1)], 1)], 1)]), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: "Priorities",
      name: "priority"
    }
  }, [_c("span", {
    staticClass: "design_note"
  }, [_vm._v("Product Priority Mode, WSJF Calc Screen")]), _vm._v(" "), _c("div", {
    staticClass: "sub-title-left"
  }, [_vm._v("Summary of Priorities")]), _vm._v(" "), _c("div", {
    staticStyle: {
      "margin-left": "20pt"
    }
  }, [_c("b", [_vm._v("Manual Priority: ")]), _vm._v(" #value     (Drop down list to change)")]), _vm._v(" "), _c("div", {
    staticStyle: {
      "margin-left": "20pt"
    }
  }, [_c("b", [_vm._v("MoSCow:")]), _vm._v(" #value ")]), _vm._v(" "), _c("div", {
    staticStyle: {
      "margin-left": "20pt"
    }
  }, [_c("b", [_vm._v("WSJF:   ")]), _vm._v(" #value ")]), _vm._v(" "), _c("div", {
    staticStyle: {
      "margin-left": "20pt"
    }
  }, [_c("b", [_vm._v("Proddigi: ")]), _vm._v(" #value ")]), _vm._v(" "), _c("br"), _vm._v(" "), _c("div", {
    staticClass: "sub-title-left"
  }, [_vm._v("Priority Calculations ")]), _vm._v(" "), _vm.selectedFeature.prioritisation ? _c("div", [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-tabs", {
    attrs: {
      "tab-position": "left"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "MoSCow"
    }
  }, [_c("el-col", {
    staticStyle: {
      border: "solid 1px black",
      "border-radius": "5px",
      padding: "10px",
      margin: "0 5pt 2pt 5pt"
    },
    attrs: {
      span: 11
    }
  }, [_c("span", [_vm._v("MoSCoW")]), _vm._v("\n\n                    " + _vm._s(_vm.selectedFeature.prioritisation.moscow) + "\n                  ")])], 1), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: "WSJF"
    }
  }, [_c("el-col", {
    staticStyle: {
      border: "solid 1px black",
      "border-radius": "5px",
      padding: "10px",
      margin: "0 5pt 2pt 5pt"
    },
    attrs: {
      span: 11
    }
  }, [_c("span", [_vm._v("WSJF")]), _vm._v("\n\n                    " + _vm._s(_vm.selectedFeature.prioritisation.wsjf) + "\n\n                    "), _c("el-button", {
    attrs: {
      size: "mini"
    }
  }, [_vm._v("Calculate")])], 1)], 1), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: "Proddigi Holistic"
    }
  }, [_c("el-col", {
    staticStyle: {
      border: "solid 1px rgba(0,0,97,0.75)",
      "border-radius": "5px",
      padding: "10px",
      margin: "0 5pt 2pt 5pt"
    },
    attrs: {
      span: 22
    }
  }, [_c("div", {
    staticStyle: {
      display: "block",
      "margin-bottom": "20pt"
    }
  }, [_c("b", [_vm._v("Prodiggi Priority: ")]), _vm._v("\n                      Rank: 1 of 54 Features  (" + _vm._s(_vm.selectedFeature.prioritisation.ark) + "/1000)\n                    ")]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 15
    }
  }, [_c("div", {
    staticClass: "priority-group"
  }, [_c("div", {
    staticClass: "priority-item"
  }, [_vm._v("Customer Type: ")]), _vm._v(" "), _c("div", {
    staticClass: "priority-line"
  }, [_vm._v("     ")]), _vm._v(" "), _c("div", {
    staticClass: "priority-item"
  }, [_vm._v("User Type: ")])]), _vm._v(" "), _c("div", {
    staticClass: "priority-line"
  }, [_vm._v("        ")]), _vm._v(" "), _c("div", {
    staticClass: "priority-group"
  }, [_c("div", {
    staticClass: "priority-item"
  }, [_vm._v("Phase")]), _vm._v(" "), _c("div", {
    staticClass: "priority-line"
  }, [_vm._v("     ")]), _vm._v(" "), _c("div", {
    staticClass: "priority-item"
  }, [_vm._v("Paint Point")]), _vm._v(" "), _c("div", {
    staticClass: "priority-line"
  }, [_vm._v("     ")]), _vm._v(" "), _c("div", {
    staticClass: "priority-item"
  }, [_vm._v("Pleaser")])]), _vm._v(" "), _c("div", {
    staticClass: "priority-line"
  }, [_vm._v("                    ")]), _vm._v(" "), _c("br"), _vm._v(" "), _c("div", {
    staticClass: "priority-group"
  }, [_c("div", {
    staticClass: "priority-item"
  }, [_vm._v("Strategic Feature:")]), _vm._v(" "), _c("div", {
    staticClass: "priority-line"
  }, [_vm._v("     ")]), _vm._v(" "), _c("div", {
    staticClass: "priority-item"
  }, [_vm._v("Competitive Pressure")])]), _vm._v(" "), _c("div", {
    staticClass: "priority-line"
  }, [_vm._v("                    ")])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("div", {
    staticClass: "priority-calc"
  }, [_vm._v(" Overall Priority")]), _vm._v(" "), _c("el-button", {
    staticStyle: {
      "margin-left": "15pt",
      padding: "3pt 6pt 3pt 6pt"
    }
  }, [_vm._v("What is this?")])], 1)], 1)], 1)], 1)], 1)], 1) : _vm._e()]), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: "User & Mockups",
      name: "user_mockups"
    }
  }, [_c("el-alert", {
    staticClass: "help_note",
    attrs: {
      type: "info",
      "show-icon": "",
      effect: "dark",
      description: "A feature can be used by many User Types. There can be only one primary User Type, determined by the Journey Map. Here you can identify secondary User Types, and also upload mockups."
    }
  }), _vm._v(" "), _c("div", {
    staticStyle: {
      padding: "10px"
    }
  }, [_vm.selectedFeature.prime_user ? _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("div", {
    staticClass: "sub-title-left"
  }, [_vm._v("Primary User\n                  "), _c("span", {
    staticStyle: {
      "margin-left": "15pt",
      "background-color": "aliceblue",
      padding: "3pt 6pt 3pt 6pt",
      border: "solid rgba(0,0,97,0.75) 2px",
      "border-radius": "12pt"
    }
  }, [_vm._v(_vm._s(_vm.selectedFeature.prime_user.name))])]), _vm._v(" "), _c("span", {
    staticStyle: {
      "margin-left": "20pt"
    }
  }, [_vm._v("User Summary")]), _vm._v(" "), _c("el-button", {
    staticStyle: {
      float: "right",
      "border-radius": "12pt"
    },
    attrs: {
      size: "mini"
    }
  }, [_vm._v("User Journey")]), _vm._v(" "), _c("br"), _c("div", {
    staticClass: "design_note",
    staticStyle: {
      "margin-left": "20pt"
    }
  }, [_vm._v("relevant user details to populate here")])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("div", {
    staticClass: "sub-title-right"
  }, [_vm._v("Secondary Users Types")]), _vm._v(" "), _c("div", {
    staticClass: "container"
  }, [_vm._l(_vm.selectedFeature.additional_users, function (user, index) {
    return _c("el-row", {
      key: index,
      attrs: {
        gutter: 20
      }
    }, [_c("el-col", {
      attrs: {
        span: 12
      }
    }, [_c("el-checkbox", [_vm._v(_vm._s(user.name))])], 1), _vm._v(" "), _c("el-col", {
      attrs: {
        span: 12
      }
    }, [_c("el-button", {
      attrs: {
        size: "mini"
      }
    }, [_vm._v("User Journey")])], 1)], 1);
  }), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini"
    }
  }, [_vm._v("Design Automation")])], 2)])], 1) : _vm._e(), _vm._v(" "), _vm.selectedFeature.prime_user ? _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("p", {
    staticClass: "sub-title-left"
  }, [_vm._v("Feature Mockups")]), _vm._v(" "), _vm._l(_vm.selectedFeature.prime_user.mockups, function (mockup, index) {
    return _c("el-row", {
      key: index,
      attrs: {
        gutter: 20
      }
    }, [_c("el-col", {
      attrs: {
        span: 24,
        offset: 1
      }
    }, [_c("span", [_vm._v(_vm._s(mockup.name))])])], 1);
  }), _vm._v(" "), _c("el-button", {
    staticStyle: {
      "margin-left": "20pt"
    },
    attrs: {
      icon: "el-icon-plus",
      size: "mini"
    }
  }, [_vm._v("Add Mockups")])], 2)], 1) : _vm._e()], 1)], 1), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: "Pricing",
      name: "pricing"
    }
  }, [_c("div", {
    staticClass: "design_note"
  }, [_vm._v("Competitive details removed. Simpler. May remove Pricing.")]), _vm._v(" "), _vm.selectedFeature.business_details ? _c("div", {
    staticStyle: {
      padding: "10px"
    }
  }, [_c("p", [_vm._v(" Revenue: ")]), _vm._v(" "), _c("el-row", [_c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("span", [_vm._v("Method")])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini",
      placeholder: "Type",
      disabled: ""
    },
    model: {
      value: _vm.selectedFeature.business_details.revenue.method,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedFeature.business_details.revenue, "method", $$v);
      },
      expression: "selectedFeature.business_details.revenue.method"
    }
  })], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("span", [_vm._v("Monetary Potential")])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini",
      placeholder: "Type",
      disabled: ""
    },
    model: {
      value: _vm.selectedFeature.business_details.revenue.monetary_potential,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedFeature.business_details.revenue, "monetary_potential", $$v);
      },
      expression: "selectedFeature.business_details.revenue.monetary_potential"
    }
  })], 1)], 1)], 1) : _vm._e()]), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: "Anayltics",
      name: "analytics"
    }
  }, [_c("span", [_vm._v(" Usage Analytics ")]), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("\n              TBA: Med/High\n            ")]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_vm._v("\n              Rank:\n            ")])], 1)], 1)], 1)], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "User Assigned History",
      visible: _vm.userAssignedHistoryDialog,
      width: "60%",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.userAssignedHistoryDialog = $event;
      }
    }
  }, [_c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.selectedFeature.assign_history
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "name",
      label: "Name"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "status",
      label: "Status"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "assigned_on",
      label: "Date"
    }
  })], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      slot: "button",
      name: "closeButton",
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.userAssignedHistoryDialog = true;
      }
    },
    slot: "button"
  }, [_vm._v("\n        Close\n      ")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Add Feature Components",
      visible: _vm.addFeatureDialog,
      width: "60%",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addFeatureDialog = $event;
      }
    }
  }, [_c("el-form", {
    ref: "addFeatureForm",
    staticClass: "mt-5 child-container",
    attrs: {
      model: _vm.feature_form,
      "label-width": "120px"
    }
  }, [_c("el-steps", {
    attrs: {
      active: _vm.activeAddFormStep,
      "align-center": ""
    }
  }, [_c("el-step", {
    attrs: {
      title: "Feature Details",
      description: "Some description"
    }
  }, [_vm._v("1")]), _vm._v(" "), _c("el-step", {
    attrs: {
      title: "Users",
      description: "Some description"
    }
  }, [_vm._v("2")]), _vm._v(" "), _c("el-step", {
    attrs: {
      title: "Prioritisation",
      description: "Some description"
    }
  }, [_vm._v("3")]), _vm._v(" "), _c("el-step", {
    attrs: {
      title: "Business Details",
      description: "Some description"
    }
  }, [_vm._v("4")]), _vm._v(" "), _c("el-step", {
    attrs: {
      title: "Stories",
      description: "Some description"
    }
  }, [_vm._v("5")])], 1), _vm._v(" "), _c("div", {
    staticClass: "container mt-10 add-feature-container"
  }, [_vm.activeAddFormStep === 0 ? [_c("el-row", [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Feature Name"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.feature_form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.feature_form, "name", $$v);
      },
      expression: "feature_form.name"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Description"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.feature_form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.feature_form, "description", $$v);
      },
      expression: "feature_form.description"
    }
  })], 1), _vm._v(" "), _c("el-row", [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Enabler"
    }
  }, [_c("el-checkbox", {
    model: {
      value: _vm.feature_form.enabler,
      callback: function callback($$v) {
        _vm.$set(_vm.feature_form, "enabler", $$v);
      },
      expression: "feature_form.enabler"
    }
  }, [_vm._v("Yes")])], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Stratergic"
    }
  }, [_c("el-checkbox", {
    model: {
      value: _vm.feature_form.is_stratergic,
      callback: function callback($$v) {
        _vm.$set(_vm.feature_form, "is_stratergic", $$v);
      },
      expression: "feature_form.is_stratergic"
    }
  }, [_vm._v("Yes")])], 1)], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Value"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.feature_form.value,
      callback: function callback($$v) {
        _vm.$set(_vm.feature_form, "value", $$v);
      },
      expression: "feature_form.value"
    }
  }, [_c("el-radio", {
    attrs: {
      label: "1"
    }
  }, [_vm._v("Low")]), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "2"
    }
  }, [_vm._v("Medium")]), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "3"
    }
  }, [_vm._v("High")])], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Priority"
    }
  }, [_c("el-slider", {
    attrs: {
      max: 5,
      "show-stops": ""
    },
    model: {
      value: _vm.feature_form.priority,
      callback: function callback($$v) {
        _vm.$set(_vm.feature_form, "priority", $$v);
      },
      expression: "feature_form.priority"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Competitors"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.feature_form.competitors,
      callback: function callback($$v) {
        _vm.$set(_vm.feature_form, "competitors", $$v);
      },
      expression: "feature_form.competitors"
    }
  }, [_c("el-radio", {
    attrs: {
      label: "Dont Offer"
    }
  }, [_vm._v("Dont Offer")]), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "Scarce"
    }
  }, [_vm._v("Scarce")]), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "Commodity"
    }
  }, [_vm._v("Commodity")])], 1)], 1)], 1)], 1)] : _vm._e(), _vm._v(" "), _vm.activeAddFormStep === 1 ? [_c("el-row", [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Prime User"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.feature_form.prime_user.name,
      callback: function callback($$v) {
        _vm.$set(_vm.feature_form.prime_user, "name", $$v);
      },
      expression: "feature_form.prime_user.name"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Prime User Design Goal"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.feature_form.design_goal,
      callback: function callback($$v) {
        _vm.$set(_vm.feature_form, "design_goal", $$v);
      },
      expression: "feature_form.design_goal"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Other Users"
    }
  }, [_c("table", {
    attrs: {
      width: "100%"
    }
  }, [_c("thead", [_c("td", [_vm._v("Name")]), _vm._v(" "), _c("td", [_vm._v("Design Goal")])]), _vm._v(" "), _c("tbody", _vm._l(_vm.feature_form.additional_users, function (additional_user, index) {
    return _c("tr", {
      key: index
    }, [_c("td", [_c("el-input", {
      model: {
        value: additional_user.name,
        callback: function callback($$v) {
          _vm.$set(additional_user, "name", $$v);
        },
        expression: "additional_user.name"
      }
    })], 1), _vm._v(" "), _c("td", [_c("el-input", {
      attrs: {
        type: "textarea"
      },
      model: {
        value: additional_user.design_goal,
        callback: function callback($$v) {
          _vm.$set(additional_user, "design_goal", $$v);
        },
        expression: "additional_user.design_goal"
      }
    })], 1)]);
  }), 0)]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: _vm.addAdditionalUser
    }
  }, [_vm._v("Add User")])], 1)], 1)], 1)] : _vm._e(), _vm._v(" "), _vm.activeAddFormStep === 2 ? [_c("el-row", [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "MoSCoW"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.feature_form.prioritisation.moscow,
      callback: function callback($$v) {
        _vm.$set(_vm.feature_form.prioritisation, "moscow", $$v);
      },
      expression: "feature_form.prioritisation.moscow"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "WSJF"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.feature_form.prioritisation.wsjf,
      callback: function callback($$v) {
        _vm.$set(_vm.feature_form.prioritisation, "wsjf", $$v);
      },
      expression: "feature_form.prioritisation.wsjf"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "ARK"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.feature_form.prioritisation.ark,
      callback: function callback($$v) {
        _vm.$set(_vm.feature_form.prioritisation, "ark", $$v);
      },
      expression: "feature_form.prioritisation.ark"
    }
  })], 1)], 1)], 1)] : _vm._e(), _vm._v(" "), _vm.activeAddFormStep === 3 ? [_c("el-row", [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Scarcity"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.feature_form.business_details.scarcity,
      callback: function callback($$v) {
        _vm.$set(_vm.feature_form.business_details, "scarcity", $$v);
      },
      expression: "feature_form.business_details.scarcity"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Revenue Method"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: ""
    },
    model: {
      value: _vm.feature_form.business_details.revenue.method,
      callback: function callback($$v) {
        _vm.$set(_vm.feature_form.business_details.revenue, "method", $$v);
      },
      expression: "feature_form.business_details.revenue.method"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Monetary Potential"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.feature_form.business_details.revenue.monetary_potential,
      callback: function callback($$v) {
        _vm.$set(_vm.feature_form.business_details.revenue, "monetary_potential", $$v);
      },
      expression: "feature_form.business_details.revenue.monetary_potential"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Offered By"
    }
  }, [_c("table", {
    attrs: {
      width: "100%"
    }
  }, [_c("thead", [_c("td", [_vm._v("Name")]), _vm._v(" "), _c("td", [_vm._v("Quality")])]), _vm._v(" "), _c("tbody", _vm._l(_vm.feature_form.business_details.offered_by, function (offered_by_datum, index) {
    return _c("tr", {
      key: index
    }, [_c("td", [_c("el-input", {
      model: {
        value: offered_by_datum.name,
        callback: function callback($$v) {
          _vm.$set(offered_by_datum, "name", $$v);
        },
        expression: "offered_by_datum.name"
      }
    })], 1), _vm._v(" "), _c("td", [_c("el-input", {
      model: {
        value: offered_by_datum.quality,
        callback: function callback($$v) {
          _vm.$set(offered_by_datum, "quality", $$v);
        },
        expression: "offered_by_datum.quality"
      }
    })], 1)]);
  }), 0)]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: _vm.addAdditionalOfferedBy
    }
  }, [_vm._v("Add another")])], 1)], 1)], 1)] : _vm._e(), _vm._v(" "), _vm.activeAddFormStep === 4 ? [_c("el-row", [_c("el-col", {
    staticClass: "text-center",
    attrs: {
      span: 24
    }
  }, [_c("span", [_vm._v(" Add stories under feature list ")])])], 1)] : _vm._e()], 2)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.addFeatureDialog = false;
      }
    }
  }, [_vm._v("Cancel")]), _vm._v(" "), _vm.activeAddFormStep === 4 ? _c("el-button", {
    attrs: {
      slot: "button",
      type: "primary",
      name: "saveButton",
      size: "mini"
    },
    on: {
      click: _vm.addFeature
    },
    slot: "button"
  }, [_vm._v("\n        Save\n      ")]) : _vm._e(), _vm._v(" "), _vm.activeAddFormStep < 4 ? _c("el-button", {
    attrs: {
      slot: "button",
      type: "primary",
      name: "nextAddFeatureStepButton",
      size: "mini"
    },
    on: {
      click: _vm.nextAddFeatureStepButton
    },
    slot: "button"
  }, [_vm._v("\n        Next step\n      ")]) : _vm._e()], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Add Story",
      visible: _vm.addStoryDialog,
      width: "60%",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addStoryDialog = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.story_form,
      "label-width": "120px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Name"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.story_form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.story_form, "name", $$v);
      },
      expression: "story_form.name"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Description"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.story_form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.story_form, "description", $$v);
      },
      expression: "story_form.description"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Options"
    }
  }, [_c("el-checkbox", {
    model: {
      value: _vm.story_form.enabler,
      callback: function callback($$v) {
        _vm.$set(_vm.story_form, "enabler", $$v);
      },
      expression: "story_form.enabler"
    }
  }, [_vm._v("Enabler")]), _vm._v(" "), _c("el-checkbox", {
    model: {
      value: _vm.story_form.is_stratergic,
      callback: function callback($$v) {
        _vm.$set(_vm.story_form, "is_stratergic", $$v);
      },
      expression: "story_form.is_stratergic"
    }
  }, [_vm._v("Stratergic")])], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Value"
    }
  }, [_c("el-slider", {
    attrs: {
      max: 10
    },
    model: {
      value: _vm.story_form.value,
      callback: function callback($$v) {
        _vm.$set(_vm.story_form, "value", $$v);
      },
      expression: "story_form.value"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Priority"
    }
  }, [_c("el-slider", {
    attrs: {
      max: 5
    },
    model: {
      value: _vm.story_form.priority,
      callback: function callback($$v) {
        _vm.$set(_vm.story_form, "priority", $$v);
      },
      expression: "story_form.priority"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Status"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Story status"
    },
    model: {
      value: _vm.story_form.status,
      callback: function callback($$v) {
        _vm.$set(_vm.story_form, "status", $$v);
      },
      expression: "story_form.status"
    }
  }, [_c("el-option", {
    attrs: {
      label: "Operational",
      value: "Operational"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Business Design",
      value: "Business Design"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Rejected",
      value: "Rejected"
    }
  })], 1)], 1), _vm._v(" "), _c("el-tabs", {
    attrs: {
      type: "card"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "User"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Prime User"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.story_form.prime_user.name,
      callback: function callback($$v) {
        _vm.$set(_vm.story_form.prime_user, "name", $$v);
      },
      expression: "story_form.prime_user.name"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Design Goal"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.story_form.prime_user.design_goal,
      callback: function callback($$v) {
        _vm.$set(_vm.story_form.prime_user, "design_goal", $$v);
      },
      expression: "story_form.prime_user.design_goal"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Other Users"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Other Users",
      multiple: ""
    },
    model: {
      value: _vm.story_form.other_users,
      callback: function callback($$v) {
        _vm.$set(_vm.story_form, "other_users", $$v);
      },
      expression: "story_form.other_users"
    }
  }, [_c("el-option", {
    attrs: {
      label: "User A",
      value: "User A"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "User B",
      value: "User B"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "User C",
      value: "User C"
    }
  })], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Assigned to"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Assigned to"
    },
    model: {
      value: _vm.story_form.assigned_to,
      callback: function callback($$v) {
        _vm.$set(_vm.story_form, "assigned_to", $$v);
      },
      expression: "story_form.assigned_to"
    }
  }, [_c("el-option", {
    attrs: {
      label: "User A",
      value: "User A"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "User B",
      value: "User B"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "User C",
      value: "User C"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: "Step"
    }
  }, [_c("el-row", [_c("el-col", {
    attrs: {
      offset: 18,
      span: 6
    }
  }, [_c("el-button", {
    attrs: {
      icon: "el-icon-plus",
      size: "mini"
    },
    on: {
      click: _vm.addFormStoryStep
    }
  }, [_vm._v("Add Step")])], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Steps"
    }
  }, [_vm._l(_vm.story_form.steps, function (step, index) {
    return [_c("el-input", {
      key: index,
      model: {
        value: step.name,
        callback: function callback($$v) {
          _vm.$set(step, "name", $$v);
        },
        expression: "step.name"
      }
    })];
  })], 2)], 1)], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      slot: "button",
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.addStory
    },
    slot: "button"
  }, [_vm._v("Add")]), _vm._v(" "), _c("el-button", {
    attrs: {
      slot: "button",
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.addStoryDialog = false;
      }
    },
    slot: "button"
  }, [_vm._v("Cancel")])], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;