"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  name: 'Search',
  props: {
    title: {
      type: String,
      default: 'Item'
    },
    createTitle: {
      type: String,
      default: ''
    },
    createItem: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      search_items: ''
    };
  },
  computed: {
    searchPlaceholderText: function searchPlaceholderText() {
      return 'Search ' + this.title;
    }
  },
  watch: {
    search_items: function search_items(val) {
      this.$emit('search', val);
    }
  },
  methods: {
    openCreateItemDialog: function openCreateItemDialog() {
      this.$emit('create');
    }
  }
};