"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _generateWireframes = _interopRequireDefault(require("./components/navigation_and_wireframes/generate-wireframes"));
var _ProcessSteps = _interopRequireDefault(require("@/components/ProcessSteps"));
// import DataServiceRequirementsComponent from './components/navigation_and_wireframes/data-service-requirements'
// import FinaliseDiagramVersionComponent from './components/navigation_and_wireframes/finalise-diagram-version'
// import CreateBacklogTaskComponent from './components/navigation_and_wireframes/create-backlog-task'
var _default = exports.default = {
  name: 'NavigationWireframe',
  components: {
    GenerateWireframesComponent: _generateWireframes.default,
    // DataServiceRequirementsComponent,
    // FinaliseDiagramVersionComponent,
    // CreateBacklogTaskComponent,
    ProcessSteps: _ProcessSteps.default
  },
  data: function data() {
    return {
      active_step: 0,
      steps: [{
        name: 'Generate & Tailor ',
        shortDescription: 'Generate & Tailor',
        longDescription: 'To save time, your wireframes are generated from your user interactions. Once generated you can tailor them by adding, editing, removing, or re-arrange wireframes.'
      }, {
        name: 'UI Data & Components',
        shortDescription: 'UI Data & Components',
        longDescription: 'For each wireframe, identify the data requirements.  By default you can see relevant data items from relevant stories, and can add additional data types. Software designers may also map wireframes to other system components.'
      }, {
        name: 'Versions & Approvals',
        shortDescription: 'Versions & Approvals',
        longDescription: 'Each set of wireframes can be managed as a single version, allowing backlog activities to be driven directly from a UI design.  Once approved, "task" tickets that map to UI components can be generated, and progress tracked.'
      }, {
        name: 'Progress & Quality',
        shortDescription: 'Ticketing & Progress',
        longDescription: 'View the progress of each version can be viewed on this screen. Also view defects related to tickets (requires ticketing systems to be setup properly).'
      }]
    };
  },
  mounted: function mounted() {},
  methods: {
    updateStep: function updateStep(active_step) {
      this.active_step = active_step;
    }
  }
};