"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _default2 = exports.default = {
  name: 'Lane',
  components: {},
  props: {
    lane: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    laneParam: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    laneWidth: {
      type: Number,
      default: 0
    },
    index: {
      type: Number,
      default: 0
    },
    noteCount: {
      type: Number,
      default: 0
    }
  },
  data: function data() {
    return {
      loading: false
    };
  },
  computed: {
    noteX: function noteX() {
      return 275 + this.laneParam.title_width / 2;
    },
    noteY: function noteY() {
      return 132 + this.index * 175;
    }
  },
  created: function created() {},
  methods: {
    generateLaneTextTransform: function generateLaneTextTransform(x, y) {
      return 'rotate(270 ' + x + ',' + y + ')';
    },
    computeLaneToolPoints: function computeLaneToolPoints(index) {
      var start_y = 100 + index * 175;
      var height = this.laneParam.height - 10;
      return 'm 215 ' + start_y + ' l -80 0 a 5 5 0 0 0 -5 5 l 0 ' + height + ' a 5 5 0 0 0 5 5 l 80 0';
    },
    deleteLane: function deleteLane(index) {
      this.$emit('delete-lane', index);
    },
    openNotesList: function openNotesList() {
      this.$emit('open-notes-list', 'lane', this.index);
    },
    editLane: function editLane() {
      this.$emit('edit-lane', this.index);
    },
    setActiveDragElement: function setActiveDragElement(element) {
      this.$emit('set-active-drawing-tool-drag-element', element);
    }
  }
};