"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-card", {
    staticClass: "mt-10"
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 16
    }
  }, [_c("p", {
    staticClass: "sub-title-left"
  }, [_vm._v("Strategic Criteria")]), _vm._v(" "), _c("div", {
    staticClass: "mt-5 pl-20 pr-20"
  }, [_c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.identityDifferentiators.selection_criterias
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Criteria",
      prop: "name",
      width: "200"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Description",
      prop: "description",
      width: "200"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Goals"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return _vm._l(scope.row.goals, function (goal, index) {
          return _c("el-tag", {
            key: index,
            attrs: {
              size: "mini"
            }
          }, [_vm._v(_vm._s(goal.goal))]);
        });
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "right",
      width: "40"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-button", {
          attrs: {
            size: "mini",
            type: "danger",
            icon: "el-icon-delete",
            circle: ""
          },
          on: {
            click: function click($event) {
              return _vm.handleDelete(scope.$index, scope.row);
            }
          }
        })];
      }
    }])
  })], 1), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: function click($event) {
        _vm.addStrategicSelectionCriteriaDialog = true;
      }
    }
  }, [_vm._v("Add\n              ")])], 1)]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("p", {
    staticClass: "sub-title-right"
  }, [_vm._v(" Vision & Value ")]), _vm._v(" "), _c("div", {
    staticClass: "mt-5 pl-20 pr-20"
  }, [_c("p", {
    staticClass: "sub-title-rounded"
  }, [_vm._v("Value Proposition")]), _vm._v(" "), _c("p", [_vm._v("\n                " + _vm._s(_vm.identityDifferentiators.value_proposition) + "\n              ")]), _vm._v(" "), _c("p", {
    staticClass: "sub-title-rounded"
  }, [_vm._v("Vision Statement")]), _vm._v(" "), _c("p", [_vm._v("\n                " + _vm._s(_vm.identityDifferentiators.vision_statement) + "\n              ")])])])], 1)], 1)], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Add Strategic selection criteria",
      visible: _vm.addStrategicSelectionCriteriaDialog,
      width: "40%",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addStrategicSelectionCriteriaDialog = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      "label-width": "120px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Activity name"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Activity form"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "description", $$v);
      },
      expression: "form.description"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Goals"
    }
  }, [_c("el-table", {
    ref: "multipleTable",
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.strategic_goals
    },
    on: {
      "selection-change": _vm.handleSelectionChange
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "selection",
      width: "55"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      property: "goal",
      label: "Goal"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.onStratergicSelectionSubmit
    }
  }, [_vm._v("Create")]), _vm._v(" "), _c("el-button", {
    on: {
      click: function click($event) {
        _vm.addStrategicSelectionCriteriaDialog = false;
      }
    }
  }, [_vm._v("Cancel")])], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;