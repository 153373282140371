"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _MockupStoryboardDetail = _interopRequireDefault(require("./components/MockupStoryboardDetail"));
var _default = exports.default = {
  name: 'MockupStoryboard',
  components: {
    MockupStoryboardDetailComponent: _MockupStoryboardDetail.default
  },
  data: function data() {
    return {
      hover: false,
      loading: false,
      mockupStoryboardDetailsDialog: false,
      addTaskDialog: false,
      resetAnnotation: false,
      activeIndex: 0,
      selectedVersionIndex: 0,
      tempAnnotation: {},
      mockups: [{
        id: 1,
        date: new Date(),
        name: 'Mockup',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        tags: '123',
        feature: {
          name: 'Feature Name'
        },
        created_by: 'John Doe',
        created_on: '12-12-2009',
        versions: [{
          id: 2,
          version: 2,
          url: 'static/img/define_user_interactions.png',
          tasks: [{
            id: 1,
            name: 'Change Color1',
            description: 'Lorem Ipsum',
            creator: {
              id: 1,
              first_name: 'John',
              last_name: 'Doe'
            },
            status: 'Pending',
            priority: 3,
            annotation: {
              x: 480,
              y: 130,
              width: 100,
              height: 20,
              style: 'fill:rgba(0,0,0);stroke-width:0;stroke:rgb(0,0,0);fill-opacity:0.2;'
            }
          }, {
            id: 2,
            name: 'Change Color2',
            description: 'Lorem Ipsum',
            creator: {
              id: 1,
              first_name: 'John',
              last_name: 'Doe'
            },
            status: 'Pending',
            priority: 3,
            annotation: {
              x: 480,
              y: 170,
              width: 100,
              height: 20,
              style: 'fill:rgba(0,0,0);stroke-width:0;stroke:rgb(0,0,0);fill-opacity:0.2;'
            }
          }],
          created_on: '12-12-2009'
        }, {
          id: 1,
          version: 1,
          url: 'static/img/define_user_interactions.png',
          tasks: [{
            id: 1,
            name: 'Change Color',
            description: 'Lorem Ipsum',
            creator: {
              id: 1,
              first_name: 'John',
              last_name: 'Doe'
            },
            status: 'Pending',
            priority: 5,
            annotation: {
              x: 480,
              y: 130,
              width: 100,
              height: 20,
              style: 'fill:rgba(0,0,0);stroke-width:0;stroke:rgb(0,0,0);fill-opacity:0.2;'
            }
          }],
          created_on: '12-12-2009'
        }]
      }, {
        id: 2,
        date: new Date(),
        name: 'Mockup',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        tags: '123',
        feature: {
          name: 'Feature Name'
        },
        created_by: 'John Doe',
        created_on: '12-12-2009',
        versions: [{
          id: 2,
          version: 2,
          url: 'static/img/identity_differentiators.png',
          tasks: [{
            id: 1,
            name: 'Change Color',
            description: 'Lorem Ipsum',
            creator: {
              id: 1,
              first_name: 'John',
              last_name: 'Doe'
            },
            status: 'Pending',
            priority: 3,
            annotation: {
              x: 480,
              y: 130,
              width: 100,
              height: 20,
              style: 'fill:rgba(0,0,0);stroke-width:0;stroke:rgb(0,0,0);fill-opacity:0.2;'
            }
          }],
          created_on: '12-12-2009'
        }, {
          id: 1,
          version: 1,
          url: 'static/img/identity_differentiators.png',
          tasks: [{
            id: 1,
            name: 'Change Color',
            description: 'Lorem Ipsum',
            creator: {
              id: 1,
              first_name: 'John',
              last_name: 'Doe'
            },
            status: 'Pending',
            priority: 5,
            annotation: {
              x: 480,
              y: 130,
              width: 100,
              height: 20,
              style: 'fill:rgba(0,0,0);stroke-width:0;stroke:rgb(0,0,0);fill-opacity:0.2;'
            }
          }],
          created_on: '12-12-2009'
        }]
      }, {
        id: 3,
        date: new Date(),
        name: 'Mockup',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        tags: '123',
        feature: {
          name: 'Feature Name'
        },
        created_by: 'John Doe',
        created_on: '12-12-2009',
        versions: [{
          id: 2,
          version: 2,
          url: 'static/img/monitor_competitors_competitors.png',
          tasks: [{
            id: 1,
            name: 'Change Color',
            description: 'Lorem Ipsum',
            creator: {
              id: 1,
              first_name: 'John',
              last_name: 'Doe'
            },
            status: 'Pending',
            priority: 3,
            annotation: {
              x: 480,
              y: 130,
              width: 100,
              height: 20,
              style: 'fill:rgba(0,0,0);stroke-width:0;stroke:rgb(0,0,0);fill-opacity:0.2;'
            }
          }],
          created_on: '12-12-2009'
        }, {
          id: 1,
          version: 1,
          url: 'static/img/monitor_competitors_competitors.png',
          tasks: [{
            id: 1,
            name: 'Change Color',
            description: 'Lorem Ipsum',
            creator: {
              id: 1,
              first_name: 'John',
              last_name: 'Doe'
            },
            status: 'Pending',
            priority: 5,
            annotation: {
              x: 480,
              y: 130,
              width: 100,
              height: 20,
              style: 'fill:rgba(0,0,0);stroke-width:0;stroke:rgb(0,0,0);fill-opacity:0.2;'
            }
          }],
          created_on: '12-12-2009'
        }]
      }, {
        id: 4,
        url: 'static/img/monitor_competitors.png',
        date: new Date(),
        name: 'Mockup',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        tags: '123',
        feature: {
          name: 'Feature Name'
        },
        created_by: 'John Doe',
        created_on: '12-12-2009',
        versions: [{
          id: 2,
          version: 2,
          url: 'static/img/monitor_competitors_competitors.png',
          tasks: [{
            id: 1,
            name: 'Change Color',
            description: 'Lorem Ipsum',
            creator: {
              id: 1,
              first_name: 'John',
              last_name: 'Doe'
            },
            status: 'Pending',
            priority: 3,
            annotation: {
              x: 480,
              y: 130,
              width: 100,
              height: 20,
              style: 'fill:rgba(0,0,0);stroke-width:0;stroke:rgb(0,0,0);fill-opacity:0.2;'
            }
          }],
          created_on: '12-12-2009'
        }, {
          id: 1,
          version: 1,
          url: 'static/img/monitor_competitors_competitors.png',
          tasks: [{
            id: 1,
            name: 'Change Color',
            description: 'Lorem Ipsum',
            creator: {
              id: 1,
              first_name: 'John',
              last_name: 'Doe'
            },
            status: 'Pending',
            priority: 5,
            annotation: {
              x: 480,
              y: 130,
              width: 100,
              height: 20,
              style: 'fill:rgba(0,0,0);stroke-width:0;stroke:rgb(0,0,0);fill-opacity:0.2;'
            }
          }],
          created_on: '12-12-2009'
        }]
      }, {
        id: 5,
        date: new Date(),
        name: 'Mockup',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        tags: '123',
        feature: {
          name: 'Feature Name'
        },
        created_by: 'John Doe',
        created_on: '12-12-2009',
        versions: [{
          id: 2,
          version: 2,
          url: 'static/img/product_roadmap.png',
          tasks: [{
            id: 1,
            name: 'Change Color',
            description: 'Lorem Ipsum',
            creator: {
              id: 1,
              first_name: 'John',
              last_name: 'Doe'
            },
            status: 'Pending',
            priority: 3,
            annotation: {
              x: 480,
              y: 130,
              width: 100,
              height: 20,
              style: 'fill:rgba(0,0,0);stroke-width:0;stroke:rgb(0,0,0);fill-opacity:0.2;'
            }
          }],
          created_on: '12-12-2009'
        }, {
          id: 1,
          version: 1,
          url: 'static/img/product_roadmap.png',
          tasks: [{
            id: 1,
            name: 'Change Color',
            description: 'Lorem Ipsum',
            creator: {
              id: 1,
              first_name: 'John',
              last_name: 'Doe'
            },
            status: 'Pending',
            priority: 5,
            annotation: {
              x: 480,
              y: 130,
              width: 100,
              height: 20,
              style: 'fill:rgba(0,0,0);stroke-width:0;stroke:rgb(0,0,0);fill-opacity:0.2;'
            }
          }],
          created_on: '12-12-2009'
        }]
      }, {
        id: 6,
        date: new Date(),
        name: 'Mockup',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        tags: '123',
        feature: {
          name: 'Feature Name'
        },
        created_by: 'John Doe',
        created_on: '12-12-2009',
        versions: [{
          id: 2,
          version: 2,
          url: 'static/img/refine_features_enablers.png',
          tasks: [{
            id: 1,
            name: 'Change Color',
            description: 'Lorem Ipsum',
            creator: {
              id: 1,
              first_name: 'John',
              last_name: 'Doe'
            },
            status: 'Pending',
            priority: 3,
            annotation: {
              x: 480,
              y: 130,
              width: 100,
              height: 20,
              style: 'fill:rgba(0,0,0);stroke-width:0;stroke:rgb(0,0,0);fill-opacity:0.2;'
            }
          }],
          created_on: '12-12-2009'
        }, {
          id: 1,
          version: 1,
          url: 'static/img/refine_features_enablers.png',
          tasks: [{
            id: 1,
            name: 'Change Color',
            description: 'Lorem Ipsum',
            creator: {
              id: 1,
              first_name: 'John',
              last_name: 'Doe'
            },
            status: 'Pending',
            priority: 5,
            annotation: {
              x: 480,
              y: 130,
              width: 100,
              height: 20,
              style: 'fill:rgba(0,0,0);stroke-width:0;stroke:rgb(0,0,0);fill-opacity:0.2;'
            }
          }],
          created_on: '12-12-2009'
        }]
      }, {
        id: 7,
        date: new Date(),
        name: 'Mockup',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        tags: '123',
        feature: {
          name: 'Feature Name'
        },
        created_by: 'John Doe',
        created_on: '12-12-2009',
        versions: [{
          id: 2,
          version: 2,
          url: 'static/img/segment_customer_types.png',
          tasks: [{
            id: 1,
            name: 'Change Color',
            description: 'Lorem Ipsum',
            creator: {
              id: 1,
              first_name: 'John',
              last_name: 'Doe'
            },
            status: 'Pending',
            priority: 3,
            annotation: {
              x: 480,
              y: 130,
              width: 100,
              height: 20,
              style: 'fill:rgba(0,0,0);stroke-width:0;stroke:rgb(0,0,0);fill-opacity:0.2;'
            }
          }],
          created_on: '12-12-2009'
        }, {
          id: 1,
          version: 1,
          url: 'static/img/segment_customer_types.png',
          tasks: [{
            id: 1,
            name: 'Change Color',
            description: 'Lorem Ipsum',
            creator: {
              id: 1,
              first_name: 'John',
              last_name: 'Doe'
            },
            status: 'Pending',
            priority: 5,
            annotation: {
              x: 480,
              y: 130,
              width: 100,
              height: 20,
              style: 'fill:rgba(0,0,0);stroke-width:0;stroke:rgb(0,0,0);fill-opacity:0.2;'
            }
          }],
          created_on: '12-12-2009'
        }]
      }, {
        id: 8,
        date: new Date(),
        name: 'Mockup',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        tags: '123',
        feature: {
          name: 'Feature Name'
        },
        created_by: 'John Doe',
        created_on: '12-12-2009',
        versions: [{
          id: 2,
          version: 2,
          url: 'static/img/user_type_journey.png',
          tasks: [{
            id: 1,
            name: 'Change Color',
            description: 'Lorem Ipsum',
            creator: {
              id: 1,
              first_name: 'John',
              last_name: 'Doe'
            },
            status: 'Pending',
            priority: 3,
            annotation: {
              x: 480,
              y: 130,
              width: 100,
              height: 20,
              style: 'fill:rgba(0,0,0);stroke-width:0;stroke:rgb(0,0,0);fill-opacity:0.2;'
            }
          }],
          created_on: '12-12-2009'
        }, {
          id: 1,
          version: 1,
          url: 'static/img/user_type_journey.png',
          tasks: [{
            id: 1,
            name: 'Change Color',
            description: 'Lorem Ipsum',
            creator: {
              id: 1,
              first_name: 'John',
              last_name: 'Doe'
            },
            status: 'Pending',
            priority: 5,
            annotation: {
              x: 480,
              y: 130,
              width: 100,
              height: 20,
              style: 'fill:rgba(0,0,0);stroke-width:0;stroke:rgb(0,0,0);fill-opacity:0.2;'
            }
          }],
          created_on: '12-12-2009'
        }]
      }],
      mockup_search: '',
      mockup_search_type: 'Both',
      task_form: {
        name: '',
        description: '',
        creator: {
          id: 1,
          first_name: 'John',
          last_name: 'Doe'
        },
        status: 'Pending',
        priority: 1
      },
      selectedMockup: {
        versions: [{
          url: ''
        }]
      },
      options: {
        responsive: [{
          end: 576,
          size: 1
        }, {
          start: 576,
          end: 768,
          size: 2
        }, {
          start: 768,
          end: 992,
          size: 3
        }, {
          size: 4
        }],
        list: {
          // 1200 because @media (min-width: 1200px) and therefore I want to switch to windowed mode
          windowed: 1200,
          // Because: #app {padding: 80px 24px;}
          padding: 24
        }
      }
    };
  },
  computed: {
    filteredMockups: function filteredMockups() {
      var self = this;
      return this.mockups.filter(function (mockup) {
        return mockup.name.indexOf(self.mockup_search) > -1;
      });
    }
  },
  mounted: function mounted() {
    this.selectedMockup = this.mockups[0];
  },
  created: function created() {},
  methods: {
    generateStyle: function generateStyle(mockup) {
      return 'background-image:url(' + mockup.versions[0].url + ');background-size: cover;background-repeat: no-repeat;';
    },
    mouseOver: function mouseOver(index) {
      this.hover = true;
      this.activeIndex = index;
    },
    mouseLeave: function mouseLeave(index) {
      this.hover = false;
      this.activeIndex = null;
    },
    selectMockup: function selectMockup(mockup) {
      this.selectedMockup = mockup;
    },
    showMockupDetails: function showMockupDetails() {
      this.mockupStoryboardDetailsDialog = true;
    },
    openAddTaskDialog: function openAddTaskDialog(version_index, tempAnnotation) {
      this.addTaskDialog = true;
      this.resetAnnotation = false;
      this.selectedVersionIndex = version_index;
      this.tempAnnotation = tempAnnotation;
    },
    cancelAddTask: function cancelAddTask() {
      this.addTaskDialog = false;
      this.resetAnnotation = true;
      this.resetTaskForm();
    },
    addTask: function addTask() {
      var task = Object.assign({}, this.task_form);
      task.annotation = this.tempAnnotation;
      this.selectedMockup.versions[this.selectedVersionIndex].tasks.push(task);
      this.addTaskDialog = false;
      this.resetAnnotation = true;
      this.resetTaskForm();
    },
    resetTaskForm: function resetTaskForm() {
      this.task_form = {
        name: '',
        description: '',
        creator: {
          id: 1,
          first_name: 'John',
          last_name: 'Doe'
        },
        status: 'Pending',
        priority: 0
      };
    }
  }
};