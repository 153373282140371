"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("main", [_c("Editor", {
    attrs: {
      id: _vm.id,
      "api-key": "zj6ovp29c106dssoh43efimhi6lfvdkqkhmmqlmm4h4pbaou",
      init: _vm.computeInit
    },
    on: {
      selectionChange: _vm.handlerFunction
    },
    model: {
      value: _vm.textvalue,
      callback: function callback($$v) {
        _vm.textvalue = $$v;
      },
      expression: "textvalue"
    }
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;