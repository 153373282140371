"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default2 = exports.default = {
  name: 'Relation',
  components: {},
  props: {
    entity: {
      type: Object,
      required: true,
      default: function _default() {
        return {};
      }
    },
    path: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      isHover: false
    };
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    emitOnMouseOver: function emitOnMouseOver() {
      this.isHover = true;
    },
    emitOnMouseLeave: function emitOnMouseLeave() {
      this.isHover = false;
    },
    emitClick: function emitClick() {
      this.$emit('click');
    },
    deleteRelation: function deleteRelation() {
      var _this = this;
      this.$confirm('This will permanently delete the relation. Continue?', 'Warning', {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(function () {
        // Delete Step Action
        _this.$emit('delete-relation', _this.index, _this.subIndex, _this.subSubIndex, _this.relationId);
      }).catch(function () {
        _this.$message({
          type: 'info',
          message: 'Delete canceled'
        });
      });
    }
  }
};