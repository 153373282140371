"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vueAvatar = _interopRequireDefault(require("vue-avatar"));
var _vueSwatches = _interopRequireDefault(require("vue-swatches"));
var _bunny = _interopRequireDefault(require("@/assets/avatar/bunny.gif"));
var _bunny2 = _interopRequireDefault(require("@/assets/avatar/bunny1.gif"));
var _guitarbunny = _interopRequireDefault(require("@/assets/avatar/guitarbunny.gif"));
var _guitarpanda = _interopRequireDefault(require("@/assets/avatar/guitarpanda.gif"));
var _lazypanda = _interopRequireDefault(require("@/assets/avatar/lazypanda.gif"));
var _moosa = _interopRequireDefault(require("@/assets/avatar/moosa.gif"));
var _ostrich = _interopRequireDefault(require("@/assets/avatar/ostrich.gif"));
var _default2 = exports.default = {
  name: 'Account',
  components: {
    Avatar: _vueAvatar.default,
    VSwatches: _vueSwatches.default
  },
  props: {
    user: {
      type: Object,
      default: function _default() {
        return {
          first_name: '',
          last_name: '',
          emailAddress: '',
          businessRole: '',
          avatarColor: '#ff4081',
          avatar: _bunny.default
        };
      }
    }
  },
  data: function data() {
    return {
      avatars: {
        bunny: _bunny.default,
        bunny1: _bunny2.default,
        guitarbunny: _guitarbunny.default,
        guitarpanda: _guitarpanda.default,
        lazypanda: _lazypanda.default,
        moosa: _moosa.default,
        ostrich: _ostrich.default
      },
      avatar_type: 'Text',
      bg_color: '#ff4081',
      bg_swatches: ['#ff4081', '#9c27b0', '#673ab7', '#3f51b5', '#2196f3', '#03a9f4', '#00bcd4', '#009688', '#4caf50', '#8bc34a', '#cddc39', '#ffc107', '#ff9800', '#ff5722', '#795548', '#9e9e9e', '#607d8b', '#f44336'],
      roles: [{
        value: 'Product Manager',
        label: 'Product Manager'
      }, {
        value: 'Project Manager',
        label: 'Project Manager'
      }, {
        value: 'Scrum Master',
        label: 'Scrum Master'
      }, {
        value: 'Developer',
        label: 'Developer'
      }, {
        value: 'UI Designer',
        label: 'UI Designer'
      }, {
        value: 'Engineering Manager',
        label: 'Engineering Manager'
      }, {
        value: 'Business Analyst',
        label: 'Business Analyst'
      }, {
        value: 'Solution Architect',
        label: 'Solution Architect'
      }]
    };
  },
  computed: {
    user_name: function user_name() {
      return this.user.first_name + ' ' + this.user.last_name;
    }
  },
  methods: {
    selectAvatar: function selectAvatar(url) {
      this.user.avatar = url;
    },
    computeAvatarURL: function computeAvatarURL() {
      if (this.avatar_type === 'Image') {
        return this.user.avatar;
      } else {
        return '';
      }
    },
    submit: function submit() {
      if (this.avatar_type === 'Text') {
        this.user.avatar = '';
      }
      this.$emit('update', this.user);
    }
  }
};