"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("g", {
    attrs: {
      refs: "task-component"
    }
  }, [_c("g", {
    attrs: {
      refs: "task-top-collider"
    }
  }, [_c("rect", {
    attrs: {
      x: _vm.x,
      y: _vm.y - 1,
      width: 120,
      height: 3,
      rx: 5,
      fill: "transparent"
    },
    on: {
      mouseover: function mouseover($event) {
        $event.preventDefault();
        return _vm.emitOnMouseLeave(true);
      }
    }
  })]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "task-bottom-collider"
    }
  }, [_c("rect", {
    attrs: {
      x: _vm.x,
      y: _vm.y + 36,
      width: 120,
      height: 3,
      rx: 5,
      fill: "transparent"
    },
    on: {
      mouseover: function mouseover($event) {
        $event.preventDefault();
        return _vm.emitOnMouseLeave(true);
      }
    }
  })]), _vm._v(" "), _vm.isRelationHover ? _c("rect", {
    staticClass: "gaphighlighter-anim",
    attrs: {
      x: _vm.x + 14,
      y: _vm.y,
      width: 138,
      height: 36,
      rx: 3,
      fill: "rgba(38,66,139,0.1)",
      "stroke-width": "1",
      stroke: "rgba(0,128, 128, 0.65)",
      "stroke-dasharray": "4"
    }
  }) : _vm._e(), _vm._v(" "), _c("rect", {
    attrs: {
      x: _vm.isTool ? _vm.x - 30 : _vm.x + 20,
      y: _vm.y + 4,
      width: _vm.isTool ? 90 : 140,
      height: 32,
      rx: 3,
      fill: "transparent",
      "stroke-width": "0",
      stroke: "#26428B",
      "stroke-dasharray": "2"
    }
  }), _vm._v(" "), _c("foreignObject", {
    directives: [{
      name: "long-press",
      rawName: "v-long-press",
      value: 450,
      expression: "450"
    }],
    staticClass: "text-left",
    attrs: {
      x: _vm.isTool ? _vm.x - 30 : _vm.x + 20,
      y: _vm.y + 4,
      width: _vm.isTool ? 100 : 140,
      height: 32
    },
    on: {
      "long-press-start": function longPressStart($event) {
        $event.preventDefault();
        $event.stopPropagation();
        return _vm.onLongPressStart.apply(null, arguments);
      },
      "long-press-stop": function longPressStop($event) {
        $event.preventDefault();
        $event.stopPropagation();
        return _vm.onLongPressStop.apply(null, arguments);
      },
      mouseover: _vm.emitOnMouseOver,
      mouseleave: function mouseleave($event) {
        $event.preventDefault();
        $event.stopPropagation();
        return _vm.emitOnToolMouseLeave.apply(null, arguments);
      }
    }
  }, [_c("div", {
    directives: [{
      name: "dragged",
      rawName: "v-dragged.prevent",
      value: _vm.onTaskDragged,
      expression: "onTaskDragged",
      modifiers: {
        prevent: true
      }
    }],
    class: {
      active: _vm.highlight === "pain_gain"
    },
    style: _vm.computeStyle(),
    on: {
      click: function click($event) {
        $event.preventDefault();
        return _vm.emitClick.apply(null, arguments);
      }
    }
  }, [!_vm.isTool ? _c("a", {
    staticStyle: {
      "border-radius": "10pt",
      margin: "2pt",
      padding: "0pt 2pt 0 2pt",
      "min-width": "15pt",
      "font-size": "0.8em",
      "background-color": "#224488",
      color: "white"
    }
  }, [_vm._v(_vm._s(_vm.phaseIndex))]) : _vm._e(), _vm._v(" "), _vm.isTool ? _c("svg-icon", {
    staticStyle: {
      position: "absolute",
      display: "inline-block",
      left: "0",
      "font-size": "25px"
    },
    attrs: {
      id: _vm.generateUniqueID(),
      "icon-class": "ujm-step-inv"
    }
  }) : _vm._e(), _vm._v(" "), _vm.showLabel && !_vm.isTool ? _c("span", {
    staticStyle: {
      "text-align": "left",
      "margin-left": "8pt",
      width: "62pt",
      "padding-top": "2pt",
      position: "absolute",
      top: "0pt",
      left: "7pt",
      "font-size": "0.7em",
      display: "inline-block",
      "vertical-align": "top",
      "max-height": "20pt",
      overflow: "hidden"
    }
  }, [_vm._v(_vm._s(_vm.truncateString(_vm.label, 40)))]) : _vm._e()], 1)]), _vm._v(" "), _vm.showLabel && _vm.isTool ? _c("foreignObject", {
    attrs: {
      x: _vm.x - 10,
      y: _vm.y + 40,
      width: 100,
      height: 20
    }
  }, [_c("div", {
    staticClass: "text-center",
    staticStyle: {
      color: "#4672c4",
      height: "20px",
      width: "40px"
    }
  }, [_c("span", {
    staticStyle: {
      "vertical-align": "top",
      "padding-left": "10pt",
      "font-size": "10px"
    }
  }, [_vm._v("Step")])])]) : _vm._e(), _vm._v(" "), !_vm.isTool ? _c("foreignObject", {
    staticStyle: {
      "background-color": "white",
      "border-radius": "5px",
      color: "red",
      "text-align": "center",
      "font-size": "0.8em",
      "font-weight": "bold"
    },
    attrs: {
      x: _vm.x + 120,
      y: _vm.y + 5,
      width: 14,
      height: 12
    }
  }, [_c("div", {
    staticStyle: {
      "padding-top": "1px"
    }
  }, [_vm._v(_vm._s(_vm.computePriority()))])]) : _vm._e(), _vm._v(" "), !_vm.isTool ? _c("foreignObject", {
    staticStyle: {
      "background-color": "white",
      "border-radius": "5px",
      color: "red",
      "text-align": "center",
      "font-size": "0.8em",
      "font-weight": "bold"
    },
    attrs: {
      x: _vm.x + 130,
      y: _vm.y + 5,
      width: 14,
      height: 12
    }
  }, [_c("svg-icon", {
    staticStyle: {
      color: "blue",
      "padding-top": "1px"
    },
    attrs: {
      "icon-class": _vm.computeCustomerValueIcon()
    }
  })], 1) : _vm._e(), _vm._v(" "), !_vm.isTool && _vm.ideas.length > 0 ? _c("foreignObject", {
    staticStyle: {
      "background-color": "white",
      "border-radius": "5px",
      color: "red",
      "text-align": "center",
      "font-size": "1.0em",
      "font-weight": "bold"
    },
    attrs: {
      x: _vm.x + 120,
      y: _vm.y + 16,
      width: 24,
      height: 14
    }
  }, [_c("div", {
    staticStyle: {
      color: "#E6A23C",
      "font-size": "1.0em",
      height: "10pt",
      "font-weight": "100",
      border: "solid 0.5pt gold",
      "border-radius": "10pt"
    }
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "10px",
      color: "#E6A23C",
      "margin-top": "0px"
    },
    attrs: {
      "icon-class": "idea"
    }
  }), _vm._v(" "), _c("span", {
    staticStyle: {
      padding: "1pt 2pt 1pt 0",
      "font-size": "0.8em"
    }
  }, [_vm._v(_vm._s(_vm.ideas.length))])], 1)]) : _vm._e(), _vm._v(" "), _c("linearGradient", {
    attrs: {
      id: "task-color-gradient",
      x2: "1",
      y2: "1"
    }
  }, [_c("stop", {
    attrs: {
      offset: "0%",
      "stop-color": "#447799"
    }
  }), _vm._v(" "), _c("stop", {
    attrs: {
      offset: "50%",
      "stop-color": "#224488"
    }
  }), _vm._v(" "), _c("stop", {
    attrs: {
      offset: "100%",
      "stop-color": "#112266"
    }
  })], 1), _vm._v(" "), _c("filter", {
    attrs: {
      id: "shadow",
      "color-interpolation-filters": "sRGB"
    }
  }, [_c("feDropShadow", {
    attrs: {
      dx: "2",
      gy: "2",
      "std-deviation": "3",
      "flood-opacity": "0.3",
      "flood-color": "lightslategrey"
    }
  })], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;