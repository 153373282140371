"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  name: 'MyProductFeatures',
  components: {},
  props: {},
  data: function data() {
    return {
      loading: false,
      otherOptionsDialog: false,
      backlog_search: '',
      backlogFeatureTableData: [{
        id: 1,
        feature: 'WOPL Collab',
        status: 'Candidate',
        version: 'V1',
        criteria: 'usability',
        approver: 'Sponsor',
        is_strategic: true,
        strategic_reason: 'Competitors do not offer this, and it is a high entry barrier, so we will invest in this.',
        revenue_strategy: 'Feature Group'
      }, {
        id: 2,
        feature: 'Assisted Nav',
        status: 'Proposed',
        version: 'V1',
        criteria: 'usability',
        approver: 'Sponsor',
        is_strategic: true,
        strategic_reason: 'Competitors do not offer this, and it is a high entry barrier, so we will invest in this.',
        revenue_strategy: 'Feature Group'
      }, {
        id: 3,
        feature: 'PERT Diagram',
        status: 'Design',
        version: 'V3',
        criteria: 'usability',
        approver: 'Sponsor',
        is_strategic: false,
        strategic_reason: 'Competitors do not offer this, and it is a high entry barrier, so we will invest in this.',
        revenue_strategy: 'Feature Group'
      }, {
        id: 4,
        feature: 'Social',
        status: 'Done',
        version: 'V3',
        criteria: 'usability',
        approver: 'Sponsor',
        is_strategic: true,
        strategic_reason: 'Competitors do not offer this, and it is a high entry barrier, so we will invest in this.',
        revenue_strategy: 'Feature Group'
      }, {
        id: 5,
        feature: 'NLP AI Assistance',
        status: 'Proposed',
        version: 'V9',
        criteria: 'usability',
        approver: 'Sponsor',
        is_strategic: false,
        strategic_reason: 'Competitors do not offer this, and it is a high entry barrier, so we will invest in this.',
        revenue_strategy: 'Feature Group'
      }]
    };
  },
  mounted: function mounted() {
    this.$eventHub.$emit('process-step-load', 'assess-against-competitors');
  },
  methods: {
    onClickMarketSell: function onClickMarketSell() {
      this.$message({
        type: 'info',
        message: 'Redirect to Market and Sell'
      });
    },
    onClickRefineFeatures: function onClickRefineFeatures() {
      this.$router.push({
        name: 'Refine Features'
      });
    }
  }
};