"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _axios = _interopRequireDefault(require("axios"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _default = exports.default = {
  name: 'Permissions',
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      loading: false,
      total: 0,
      searchPermission: '',
      permissions: [],
      listQuery: {
        page: 1,
        limit: 30
      }
    };
  },
  computed: {
    filteredPermissionList: function filteredPermissionList() {
      var self = this;
      return this.permissions.filter(function (permission) {
        return permission.title.indexOf(self.searchPermission) > -1 && !permission.isDeleted;
      });
    }
  },
  created: function created() {},
  mounted: function mounted() {
    this.getPermissionList({
      page: 1,
      limit: 30
    });
  },
  methods: {
    createPermission: function createPermission() {
      var _this = this;
      var headers = {
        'Content-Type': 'application/json'
      };
      var body = {
        'data': [{
          'title': 'Permission 2',
          'description': 'Permission 2 Desc',
          'action': 'READ'
        }],
        'header': {
          'userId': this.$store.getters.id,
          'partyId': this.$store.getters.partyId
        }
      };
      _axios.default.post(process.env.VUE_APP_REST_API_ENDPOINT + 'security/permission', body, {
        headers: headers
      }).then(function (response) {
        _this.loading = false;
        // this.response = response.data
        if (response.data.status === 'SUCCESS') {
          _this.$notify({
            title: 'Success',
            message: 'Permission Created',
            type: 'success',
            duration: 2000
          });
        } else {
          _this.$notify({
            title: 'Error',
            message: response.data.data.description,
            type: 'error',
            duration: 2000
          });
        }
      }).catch(function (error) {
        _this.loading = false;
        _this.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
      });
    },
    getPermissionList: function getPermissionList(pagination) {
      var _this2 = this;
      var temp_pagination = Object.assign({}, pagination);
      var page_no = parseInt(temp_pagination.page) - 1;
      this.loading = true;
      var headers = {
        'Content-Type': 'application/json',
        'x-party-id': this.$store.getters.partyId,
        'x-user-id': this.$store.getters.id
      };
      _axios.default.get(process.env.VUE_APP_REST_API_ENDPOINT + 'security/permission?page=' + page_no, {
        headers: headers
      }).then(function (response) {
        _this2.loading = false;
        if (response.data.status === 'SUCCESS') {
          _this2.total = response.data.pageInfo.totalPages * response.data.pageInfo.itemsPerPage;
          _this2.permissions = response.data.data;
        } else {
          _this2.$notify({
            title: 'Error',
            message: response.data.data.description,
            type: 'error',
            duration: 2000
          });
        }
      }).catch(function (error) {
        _this2.loading = false;
        _this2.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
      });
    }
  }
};