"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getInfo = getInfo;
exports.getUserInfo = getUserInfo;
exports.getUserInfoByID = getUserInfoByID;
exports.login = login;
exports.logout = logout;
var _request = _interopRequireDefault(require("@/utils/request"));
var _axios = _interopRequireDefault(require("axios"));
function login(data) {
  return (0, _request.default)({
    url: '/user/login',
    method: 'post',
    data: data
  });
}
function getUserInfo(email) {
  return new Promise(function (resolve, reject) {
    var headers = {
      'Content-Type': 'application/json',
      'x-user-id': 'userid-002',
      'x-party-id': '402880b273f49e830173f4af11d60000'
    };
    _axios.default.get(process.env.VUE_APP_REST_API_ENDPOINT + 'user/email/' + email, {
      headers: headers
    }).then(function (response) {
      resolve(response);
    }).catch(function (error) {
      reject(error);
    });
  });
}
function getUserInfoByID(user_id) {
  return new Promise(function (resolve, reject) {
    var headers = {
      'Content-Type': 'application/json',
      'x-user-id': 'userid-002',
      'x-party-id': '402880b273f49e830173f4af11d60000'
    };
    _axios.default.get(process.env.VUE_APP_REST_API_ENDPOINT + 'user/id/' + user_id, {
      headers: headers
    }).then(function (response) {
      resolve(response);
    }).catch(function (error) {
      reject(error);
    });
  });
}
function getInfo(token) {
  return (0, _request.default)({
    url: '/user/info',
    method: 'get',
    params: {
      token: token
    }
  });
}
function logout() {
  return (0, _request.default)({
    url: '/user/logout',
    method: 'post'
  });
}