"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.array.from");
require("core-js/modules/es6.function.name");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
require("core-js/modules/web.dom.iterable");
var _interopRequireWildcard2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _Action = _interopRequireDefault(require("@/components/Action"));
var _Comment = _interopRequireDefault(require("@/components/Comment"));
var _FileUpload = _interopRequireDefault(require("@/components/FileUpload"));
var _editorJsonTransformer = require("@atlaskit/editor-json-transformer");
var _editorJiraTransformer = require("@atlaskit/editor-jira-transformer");
var _schemaDefault = require("@atlaskit/adf-schema/schema-default");
var _axios = _interopRequireDefault(require("axios"));
// import { jiraSchema } from "@atlaskit/adf-schema/schema-jira"
// import TemplateList from "../../../../dev/templates/list";
var _default2 = exports.default = {
  name: 'Ticket',
  components: {
    // TemplateList,
    TinymceComponent: function TinymceComponent() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/components/Tinymce'));
      });
    },
    BPMNComponent: function BPMNComponent() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/components/BPMN'));
      });
    },
    ChildTicketComponent: function ChildTicketComponent() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/components/Ticket'));
      });
    },
    ActionComponent: _Action.default,
    CommentComponent: _Comment.default,
    FileUploadComponent: _FileUpload.default
  },
  props: {
    readOnly: {
      type: Boolean,
      default: false
    },
    customer: {
      type: Object,
      default: function _default() {
        return {
          title: '',
          description: ''
        };
      }
    },
    journeyMap: {
      type: Object,
      default: function _default() {
        return {
          title: '',
          description: '',
          linkedJourneyPhases: [],
          linkedJourneySteps: [],
          linkedJourneyExperiences: []
        };
      }
    },
    parentType: {
      type: String,
      default: 'Product'
    },
    parent: {
      type: Object,
      default: function _default() {
        return {
          id: '',
          title: '',
          description: ''
        };
      }
    },
    service: {
      type: Object,
      default: function _default() {
        return {
          title: '',
          description: ''
        };
      }
    },
    offering: {
      type: Object,
      default: function _default() {
        return {
          title: '',
          description: ''
        };
      }
    },
    child: {
      type: Object,
      default: function _default() {
        return {
          id: '',
          title: '',
          description: '',
          relations: []
        };
      }
    }
  },
  data: function data() {
    return {
      /** Importance has been renamed to value.*/
      description: '',
      collapseActiveNames: ['1', '2', '4'],
      loading: false,
      child_ticket_loading: false,
      retrieve_ticket_loading: false,
      update_processing: false,
      attachment_loading: false,
      step_relation_update_loading: false,
      isValueStreamActive: false,
      isEnabled: false,
      addTicketDialog: false,
      childTicketDetailsDialog: false,
      relation_step_id: null,
      relation_feature_id: null,
      activeTab: 'ticket_details',
      ticket: {},
      tickets: [],
      files: [],
      edit: {
        mode: false,
        index: 0
      },
      activeStreamItem: '',
      valueStream: {
        customer: {},
        customer_tickets: [],
        phase: {},
        phase_tickets: [],
        step: {},
        step_tickets: []
      },
      comment: '',
      users: [],
      child_tickets: [],
      activeTicketParent: {},
      selectedChildTicket: {},
      selectedChildTicketParentDetailType: '',
      customer_tickets: [],
      phase_tickets: [],
      step_tickets: [],
      customer_actions: [{
        title: 'Action 1'
      }, {
        title: 'Action 2'
      }, {
        title: 'Action 3'
      }, {
        title: 'Action 4'
      }, {
        title: 'Action 5'
      }],
      tickettypes: [
      // @sastha does this break anything in backend? Correct ticket types added below
      //  Story types should not be selectable here as they are children.
      {
        label: 'Product Epic',
        value: 'Product Epic'
      },
      /* {
       label: 'Product Story',
       value: 'Product Story'
      },*/
      {
        label: 'Process Epic',
        value: 'Process Epic'
      } /*,
        {
         label: 'Process Story',
         value: 'Process Story'
        }*/],
      statuses: [{
        label: 'Candidate',
        value: 'Candidate'
      }, {
        label: 'Approved',
        value: 'Approved'
      }, {
        label: 'Backlog',
        value: 'Backlog'
      }, {
        label: 'In-Progress',
        value: 'In-Progress'
      }, {
        label: 'Finalised',
        value: 'Finalised'
      }, {
        label: 'Released',
        value: 'Released'
      }, {
        label: 'Improvement',
        value: 'Improvement'
      }, {
        label: 'Retired',
        value: 'Retired'
      }],
      priorities: [{
        label: 'Highest',
        value: 'Highest'
      }, {
        label: 'High',
        value: 'High'
      }, {
        label: 'Medium',
        value: 'Medium'
      }, {
        label: 'Low',
        value: 'Low'
      }, {
        label: 'Lowest',
        value: 'Lowest'
      }],
      parsedData: null,
      deletedChildTicketlength: 0
    };
  },
  computed: {
    computedRelatedTicketsDesign: function computedRelatedTicketsDesign() {
      var design_tickets = [];
      if (this.child.__typename === 'ProductFeature' && this.offering && this.offering.additionalattributes && this.offering.additionalattributes.relations && this.offering.additionalattributes.relations.length > 0) {
        for (var i = 0; i < this.offering.additionalattributes.relations.length; i++) {
          if (this.offering.additionalattributes.relations[i].start_type === 'Feature' && this.offering.additionalattributes.relations[i].start_id === this.child.id && this.offering.additionalattributes.relations[i].end_type === 'Feature') {
            design_tickets.push(this.retrieveFeature(this.offering.additionalattributes.relations[i].end_id));
          } else if (this.offering.additionalattributes.relations[i].start_type === 'Feature' && this.offering.additionalattributes.relations[i].end_id === this.child.id && this.offering.additionalattributes.relations[i].end_type === 'Feature') {
            design_tickets.push(this.retrieveFeature(this.offering.additionalattributes.relations[i].start_id));
          }
        }
      }
      if (this.child.__typename === 'ServiceApplication' && this.offering && this.offering.additionalattributes && this.offering.additionalattributes.relations && this.offering.additionalattributes.relations.length > 0) {
        for (var j = 0; j < this.offering.additionalattributes.relations.length; j++) {
          if (this.offering.additionalattributes.relations[j].start_type === 'ServiceApplication' && this.offering.additionalattributes.relations[j].start_id === this.child.id && this.offering.additionalattributes.relations[j].end_type === 'ServiceApplication') {
            design_tickets.push(this.retrieveServiceApplication(this.offering.additionalattributes.relations[j].end_id));
          } else if (this.offering.additionalattributes.relations[j].start_type === 'ServiceApplication' && this.offering.additionalattributes.relations[j].end_id === this.child.id && this.offering.additionalattributes.relations[j].end_type === 'ServiceApplication') {
            design_tickets.push(this.retrieveServiceApplication(this.offering.additionalattributes.relations[j].start_id));
          }
        }
      }
      return design_tickets;
    },
    computeImportBPMNButtonTitle: function computeImportBPMNButtonTitle() {
      if (this.child_tickets.length > 0) {
        return 'Regenerate from prodiggi';
      }
      return 'Generate from prodiggi';
    },
    filterNonBPMNFiles: function filterNonBPMNFiles() {
      var file_list = [];
      this.files.forEach(function (file) {
        if (!file.entityName.includes('BPMN')) {
          file_list.push(file);
        }
      });
      return file_list;
    },
    filterBPMNFiles: function filterBPMNFiles() {
      var file_list = [];
      this.files.forEach(function (file) {
        if (file.entityName.includes('BPMN')) {
          file_list.push(file);
        }
      });
      return file_list;
    },
    computedParentId: function computedParentId() {
      return this.parent.id;
    },
    computeValueStreamTableData: function computeValueStreamTableData() {
      switch (this.activeStreamItem) {
        case 'customer':
          return this.valueStream.customer_tickets;
        case 'phase':
          return this.valueStream.phase_tickets;
        case 'step':
          return this.valueStream.step_tickets;
        case 'feature':
        default:
          return this.child_tickets;
      }
    },
    filteredTickets: function filteredTickets() {
      var child_ticket_ids = [];
      var filterdTickets = [];
      for (var i = 0; i < this.child_tickets.length; i++) {
        child_ticket_ids.push(this.child_tickets[i].id);
      }
      for (var j = 0; j < this.tickets.length; j++) {
        if (child_ticket_ids.indexOf(this.tickets[i].id) === -1) {
          filterdTickets.push(this.tickets[j]);
        }
      }
      return filterdTickets;
    }
  },
  watch: {
    child: {
      handler: function handler(newValue) {
        this.step_relation_update_loading = false;
        this.relation_step_id = null;
        this.relation_feature_id = null;
        this.selectTicket();
      },
      deep: true
    }
  },
  created: function created() {},
  mounted: function mounted() {
    this.selectTicket();
    this.getUserList();
  },
  methods: {
    updateTicketDescription: function updateTicketDescription(description) {
      this.ticket.description = description;
    },
    getUserList: function getUserList() {
      var _this = this;
      this.loading = true;
      var headers = {
        'Content-Type': 'application/json',
        'x-party-id': this.$store.getters.partyId,
        'x-user-id': this.$store.getters.id
      };
      _axios.default.get(process.env.VUE_APP_REST_API_ENDPOINT + 'user/?extended=LEVEL0', {
        headers: headers
      }).then(function (response) {
        _this.loading = false;
        _this.users = response.data.data;
      }).catch(function (error) {
        if (error.response.status === 400) {
          _this.loading = false;
          _this.$notify({
            title: 'Error',
            message: error.response.message,
            type: 'error',
            duration: 2000
          });
        }
      });
    },
    handleTicketSubTypeChange: function handleTicketSubTypeChange(type) {
      // Additional check to avoid crash
      if (this.parentType === 'Service') {
        var additionalattributes = Object.assign({}, this.child.additionalattributes);
        additionalattributes.type = type;
        this.$emit('updateChildType', additionalattributes, this.child.id);
      }
    },
    handleRelatedTicketSelection: function handleRelatedTicketSelection(val) {
      this.updateTicketActiveParent(val.id);
      this.selectRelatedTicket(val.id);
    },
    updateTicketActiveParent: function updateTicketActiveParent(sub_entity_id) {
      var flag = false;
      for (var i = 0; i < this.offering.linkedProducts.length; i++) {
        for (var k = 0; k < this.offering.linkedProducts[i].product.linkedFeatures.length; k++) {
          if (this.offering.linkedProducts[i].product.linkedFeatures[k].productFeature.id === sub_entity_id) {
            flag = true;
            this.activeTicketParent = this.offering.linkedProducts[i].product;
            this.selectedChildTicket = this.offering.linkedProducts[i].product.linkedFeatures[k].productFeature;
            this.selectedChildTicketParentDetailType = 'Product';
          }
        }
      }
      if (!flag) {
        for (var j = 0; j < this.offering.linkedServices.length; j++) {
          for (var l = 0; l < this.offering.linkedServices[j].service.linkedServiceApplications.length; l++) {
            if (this.offering.linkedServices[j].service.linkedServiceApplications[l].serviceApplication.id === sub_entity_id) {
              flag = true;
              this.activeTicketParent = this.offering.linkedServices[j].service;
              this.selectedChildTicket = this.offering.linkedServices[j].service.linkedServiceApplications[l].serviceApplication;
              this.selectedChildTicketParentDetailType = 'Service';
            }
          }
        }
      }
    },
    selectRelatedTicket: function selectRelatedTicket(feature_id) {
      var _this2 = this;
      this.loading = true;
      // Retrieve Ticket and populate ticket modal
      var headers = {
        'Content-Type': 'application/json',
        'x-party-id': this.$store.getters.partyId,
        'x-user-id': this.$store.getters.id
      };
      _axios.default.get(process.env.VUE_APP_REST_API_ENDPOINT + 'ticket?extended=LEVEL0&entityId=' + feature_id, {
        headers: headers
      }).then(function (response) {
        _this2.loading = false;
        // this.selectedChildTicket = response.data.data[0]
        _this2.childTicketDetailsDialog = true;
      }).catch(function (error) {
        _this2.loading = false;
        _this2.$notify({
          title: 'Error',
          message: error.response.message,
          type: 'error',
          duration: 2000
        });
      });
    },
    deleteRelation: function deleteRelation(type, entity_id, ticket_id) {
      var _this3 = this;
      this.$confirm('This will permanently unlink feature. Continue?', 'Warning', {
        confirmButtonText: 'Unlink',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(function () {
        if (type === 'ProductFeature') {
          _this3.$emit('deleteFeatureFeatureRelation', _this3.child);
        } else if (type === 'ServiceApplication') {
          _this3.$emit('deleteServiceApplicationServiceApplicationRelation', _this3.child);
        }
      }).catch(function () {
        _this3.$message({
          type: 'info',
          message: 'Unlink canceled'
        });
      });
    },
    retrieveFeature: function retrieveFeature(feature_id) {
      for (var i = 0; i < this.offering.linkedProducts.length; i++) {
        for (var j = 0; j < this.offering.linkedProducts[i].product.linkedFeatures.length; j++) {
          if (this.offering.linkedProducts[i].product.linkedFeatures[j].productFeature.id === feature_id) {
            return this.offering.linkedProducts[i].product.linkedFeatures[j].productFeature;
          }
        }
      }
      return {};
    },
    retrieveServiceApplication: function retrieveServiceApplication(service_application_id) {
      for (var i = 0; i < this.offering.linkedServices.length; i++) {
        for (var j = 0; j < this.offering.linkedServices[i].service.linkedServiceApplications.length; j++) {
          if (this.offering.linkedServices[i].service.linkedServiceApplications[j].serviceApplication.id === service_application_id) {
            return this.offering.linkedServices[i].service.linkedServiceApplications[j].serviceApplication;
          }
        }
      }
      return {};
    },
    handleTabClick: function handleTabClick(tab_val) {
      switch (tab_val.name) {
        case 'stories_tasks':
          this.retrieveTicketAttachements();
          break;
        case 'related_tickets':
          this.loadRelatedTickets();
          break;
      }
    },
    loadRelatedTickets: function loadRelatedTickets() {},
    deleteChildTicketNoConfirm: function deleteChildTicketNoConfirm(child_ticket_id, parent_ticket_id) {
      var _this4 = this;
      var headers = {
        'Content-Type': 'application/json'
        // 'Authorization': 'Bearer '+getJWTToken()
      };
      var create_body = {
        data: [{
          'ticketId': child_ticket_id,
          'relationship': 'childLink1'
        }],
        'header': {
          'userId': this.$store.getters.id,
          'partyId': this.$store.getters.partyId
        }
      };
      _axios.default.delete(process.env.VUE_APP_REST_API_ENDPOINT + 'ticket/' + parent_ticket_id + '/link', {
        headers: headers,
        data: create_body
      }).then(function (response) {
        if (response.data.status === 'SUCCESS') {
          var _headers = {
            'Content-Type': 'application/json'
            // 'Authorization': 'Bearer '+getJWTToken()
          };
          var create_body1 = {
            data: [{
              'id': child_ticket_id
            }],
            'header': {
              'userId': _this4.$store.getters.id,
              'partyId': _this4.$store.getters.partyId
            }
          };
          _axios.default.delete(process.env.VUE_APP_REST_API_ENDPOINT + 'ticket', {
            headers: _headers,
            data: create_body1
          }).then(function (response1) {
            _this4.deletedChildTicketlength++;
            if (_this4.child_tickets.length === _this4.deletedChildTicketlength) {
              // this.importFromBPMN()
              _this4.$refs.file.click();
            }
          }).catch(function (error) {
            console.log(error);
          });
        }
      }).catch(function (error) {
        console.log(error);
      });
    },
    deleteAllChildTickets: function deleteAllChildTickets() {
      this.deletedChildTicketlength = 0;
      for (var i = 0; i < this.child_tickets.length; i++) {
        this.deleteChildTicketNoConfirm(this.child_tickets[i].id, this.ticket.id);
      }
    },
    validateAndImportBPMN: function validateAndImportBPMN() {
      if (this.filterBPMNFiles.length > 0) {
        this.openFileFromS3(this.filterBPMNFiles[0]);
      } else {
        this.$notify({
          title: 'Error',
          message: 'No BPMN files found',
          type: 'error',
          duration: 2000
        });
      }
      /*      if (this.filterBPMNFiles.length > 0) {
              if (this.child_tickets.length > 0) {
                this.$confirm('This will replace existing stories. Continue?', 'Warning', {
                  confirmButtonText: 'Replace',
                  cancelButtonText: 'Cancel',
                  type: 'warning'
                }).then(() => {
                  this.deleteAllChildTickets(true)
                  this.openFileFromS3(this.filterBPMNFiles[0])
                }).catch(() => {
                })
              } else {
                // this.importFromBPMN()
                this.openFileFromS3(this.filterBPMNFiles[0])
              }
            } else {
              this.$notify({
                title: 'Error',
                message: 'No BPMN files found',
                type: 'error',
                duration: 2000
              })
            }*/
    },
    openFileFromS3: function openFileFromS3(file) {
      var _this5 = this;
      var url = process.env.VUE_APP_REST_API_ENDPOINT + 'file/' + file.fileName + '?versionId=' + file.versionId + '&x-user-id=' + this.$store.getters.id + '&x-party-id=' + this.$store.getters.partyId + '&x-entity-name=' + file.entityName + '&x-entity-id=' + file.entityId;
      // Fetch the XML file
      _axios.default.get(url).then(function (response) {
        // Extract XML content from the response
        var xmlContent = response.data;
        var parser = new DOMParser();
        var xmlDoc = parser.parseFromString(xmlContent, 'text/xml');
        _this5.importFromBPMN(xmlDoc);
      }).catch(function (error) {
        console.error('Error:', error);
      });
    },
    uploadBPMNFile: function uploadBPMNFile() {
      var _this6 = this;
      var file = this.$refs.file.files[0];
      var reader = new FileReader();
      reader.onload = function (res) {
        var parser = new DOMParser();
        var xmlDoc = parser.parseFromString(res.target.result, 'text/xml');
        _this6.importFromBPMN(xmlDoc);
      };
      reader.onerror = function (err) {
        return console.log(err);
      };
      reader.readAsText(file);
    },
    importFromBPMN: function importFromBPMN(xmlDoc) {
      try {
        var items = xmlDoc.getElementsByTagName('semantic:task');
        if (items.length === 0) {
          items = xmlDoc.getElementsByTagName('bpmn2:task');
        }
        // Process the parsed data as needed
        this.parsedData = Array.from(items).map(function (item) {
          return item.attributes.name.textContent;
        });
        for (var i = 0; i < this.parsedData.length; i++) {
          var child_ticket = {
            id: '',
            title: this.parsedData[i],
            type: '',
            status: 'Candidate',
            priority: 'MINOR'
          };
          this.child_tickets.push(child_ticket);
          this.addUpdateChildTicket(child_ticket);
        }
        console.log('Parsed XML data:', this.parsedData);
      } catch (error) {
        console.error('Error parsing XML:', error);
      }
    },
    bpmnUploadedToS3: function bpmnUploadedToS3() {
      this.retrieveTicketAttachements();
    },
    updateEntityStepRelation: function updateEntityStepRelation(step_id) {
      this.step_relation_update_loading = true;
      if (this.parentType === 'Product') {
        var relations = this.child.relations;
        relations.steps[0].id = step_id;
        this.$emit('updateFeatureStepRelation', this.child.id, relations);
      } else {
        var service_application_relations = this.child.relations;
        service_application_relations.type = 'step';
        service_application_relations.id = step_id;
        this.$emit('updateServiceApplicationRelation', this.child.id, service_application_relations);
      }
    },
    updateEntityFeatureRelation: function updateEntityFeatureRelation(feature_id) {
      var relations = this.child.relations;
      relations.type = 'feature';
      relations.id = feature_id;
      this.$emit('updateServiceApplicationRelation', this.child.id, relations);
    },
    displayAddTicketDialog: function displayAddTicketDialog() {
      var _this7 = this;
      this.retrieve_ticket_loading = true;
      // Retrieve Ticket List
      var headers = {
        'Content-Type': 'application/json',
        'x-party-id': this.$store.getters.partyId,
        'x-user-id': this.$store.getters.id
      };
      _axios.default.get(process.env.VUE_APP_REST_API_ENDPOINT + 'ticket?createdBy=' + this.$store.getters.id, {
        headers: headers
      }).then(function (response) {
        _this7.retrieve_ticket_loading = false;
        _this7.tickets = [];
        _this7.tickets = response.data.data;
        _this7.addTicketDialog = true;
      }).catch(function (error) {
        _this7.retrieve_ticket_loading = false;
        if (error.response.status === 400) {
          _this7.$notify({
            title: 'Error',
            message: error.response.message,
            type: 'error',
            duration: 2000
          });
        }
      });
    },
    computeValueStreamDetails: function computeValueStreamDetails() {
      /*
        - Customer
        - Phase
        - Step
          - Offering
      */
      this.loading = true;
      var step_id = null;
      if (this.parentType === 'Product') {
        step_id = this.child.relations.steps[0].id;
      } else {
        if (this.child.relations.type === 'step') {
          step_id = this.child.relations.id;
        }
      }
      this.valueStream.customer = this.customer;
      if (step_id !== null) {
        this.valueStream.phase = this.retrievePhaseFromStepID(step_id);
        this.valueStream.step = this.retrieveStepFromStepID(step_id);
        this.valueStream.phase_tickets = this.computePhaseTicketList(this.valueStream.phase.id);
        this.valueStream.step_tickets = this.computeStepTicketList(this.valueStream.step.id);
      }
      this.valueStream.customer_tickets = this.computeCustomerTicketList();
      this.loading = false;
    },
    updateEnabledStatus: function updateEnabledStatus(value) {
      this.$emit('updateFeatureEnabler', value);
    },
    emitCloseEvent: function emitCloseEvent() {
      this.$emit('close');
    },
    updateTicket: function updateTicket() {
      var _this8 = this;
      if (this.ticket.title !== this.child.title || this.ticket.description !== this.child.description) {
        this.$emit('updateChildTitleDescription', this.ticket.title, this.ticket.description, this.parentType);
      }
      this.update_processing = true;
      var headers = {
        'Content-Type': 'application/json'
        // 'Authorization': 'Bearer '+getJWTToken()
      };
      var body = {
        data: [{
          'id': this.ticket.id,
          'title': this.ticket.title,
          'description': this.ticket.description,
          'externalDescription': this.convertHTMLToADF(this.ticket.description),
          'type': this.ticket.type,
          'priority': this.ticket.priority,
          'status': this.ticket.status,
          'approvalVia': this.ticket.approvalVia
        }],
        'header': {
          'userId': this.$store.getters.id,
          'partyId': this.$store.getters.partyId
        }
      };
      _axios.default.patch(process.env.VUE_APP_REST_API_ENDPOINT + 'ticket', body, {
        headers: headers
      }).then(function (response) {
        _this8.update_processing = false;
        _this8.$notify({
          title: 'Success',
          message: 'Updated',
          type: 'success',
          duration: 2000
        });
        /* if (response.data.data.status === 'SUCCESS') {
           this.selectTicket()
         }
         */
      }).catch(function (error) {
        _this8.update_processing = false;
        _this8.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
      });
    },
    addUpdateChildTicket: function addUpdateChildTicket(child_ticket) {
      var _this9 = this;
      if (child_ticket.id === '') {
        // Create
        this.child_ticket_loading = true;
        var headers = {
          'Content-Type': 'application/json'
          // 'Authorization': 'Bearer '+getJWTToken()
        };
        var create_body = {
          data: [{
            'entityId': this.ticket.id,
            'entityName': 'TICKET',
            'title': child_ticket.title,
            'description': child_ticket.description,
            'type': 'FEATURE',
            'priority': child_ticket.priority,
            'status': child_ticket.status
          }],
          'header': {
            'userId': this.$store.getters.id,
            'partyId': this.$store.getters.partyId
          }
        };
        _axios.default.post(process.env.VUE_APP_REST_API_ENDPOINT + 'ticket', create_body, {
          headers: headers
        }).then(function (response) {
          _this9.child_ticket_loading = false;
          if (response.data.data.status === 'SUCCESS') {
            // Link Child ticket to parent
            _this9.linkChildTicketToParent(response.data.data.id, _this9.ticket.id);
            // this.retrieveChildTickets()
          }
        }).catch(function (error) {
          _this9.child_ticket_loading = false;
          console.log(error);
        });
      } else {
        // Update

        this.child_ticket_loading = true;
        var _headers2 = {
          'Content-Type': 'application/json'
        };
        var update_body = {
          'data': {
            'title': child_ticket.title,
            'description': child_ticket.description,
            'priority': child_ticket.priority,
            'status': child_ticket.status
          },
          'header': {
            'userId': this.$store.getters.id,
            'partyId': this.$store.getters.partyId
          }
        };
        _axios.default.patch(process.env.VUE_APP_REST_API_ENDPOINT + 'ticket/' + child_ticket.id, update_body, {
          headers: _headers2
        }).then(function (response) {
          _this9.child_ticket_loading = false;
          _this9.retrieveChildTickets();
        }).catch(function (error) {
          _this9.child_ticket_loading = false;
          console.log(error);
        });
      }
    },
    linkChildTicketToParent: function linkChildTicketToParent(child_ticket_id, parent_ticket_id) {
      var _this10 = this;
      this.child_ticket_loading = true;
      var headers = {
        'Content-Type': 'application/json'
        // 'Authorization': 'Bearer '+getJWTToken()
      };
      var create_body = {
        data: [{
          'ticketId': child_ticket_id,
          'relationship': 'childLink1'
        }],
        'header': {
          'userId': this.$store.getters.id,
          'partyId': this.$store.getters.partyId
        }
      };
      _axios.default.post(process.env.VUE_APP_REST_API_ENDPOINT + 'ticket/' + parent_ticket_id + '/link', create_body, {
        headers: headers
      }).then(function (response) {
        _this10.child_ticket_loading = false;
        if (response.data.data.status === 'SUCCESS') {
          _this10.addTicketDialog = false;
          _this10.retrieveChildTickets();
        }
      }).catch(function (error) {
        _this10.child_ticket_loading = false;
        console.log(error);
      });
    },
    selectTicket: function selectTicket() {
      var _this11 = this;
      this.loading = true;
      // Retrieve Ticket and populate ticket modal
      var headers = {
        'Content-Type': 'application/json',
        'x-party-id': this.$store.getters.partyId,
        'x-user-id': this.$store.getters.id
      };
      _axios.default.get(process.env.VUE_APP_REST_API_ENDPOINT + 'ticket?extended=LEVEL0&entityId=' + this.child.id, {
        headers: headers
      }).then(function (response) {
        _this11.loading = false;
        _this11.ticket = {};
        _this11.ticket = response.data.data[0]; // hardcoded 0 element, since its a one on one relation
        // Emit ticket title and id to update dialog popover
        _this11.$emit('updateTicketDialogTitle', _this11.ticket);
        _this11.computeUserNameFromUserID(_this11.ticket.createdBy, 'createdBy');
        _this11.computeUserNameFromUserID(_this11.ticket.modifiedBy, 'modifiedBy');
        _this11.ticket.smart_priority = 0;
        _this11.computeSmartPriority();
        if (_this11.ticket.externalSystemName) {
          _this11.retrieveExternalChildTickets();
        } else {
          _this11.retrieveChildTickets();
        }
        _this11.retrieveTicketAttachements();
        _this11.computeValueStreamDetails();
      }).catch(function (error) {
        _this11.loading = false;
        if (error.response.status === 400) {
          /* console.log(error.response.data.data.description)
          this.$notify({
            title: 'Error',
            message: error.response.data.data.description,
            type: 'error',
            duration: 2000
          })*/
        } else {
          _this11.$notify({
            title: 'Error',
            message: error.response.message,
            type: 'error',
            duration: 2000
          });
        }
      });
    },
    retrieveExternalChildTickets: function retrieveExternalChildTickets() {
      var _this12 = this;
      this.loading = true;
      // Retrieve Ticket and populate ticket modal
      var headers = {
        'Content-Type': 'application/json',
        'x-party-id': this.$store.getters.partyId,
        'x-user-id': this.$store.getters.id
      };
      _axios.default.get(process.env.VUE_APP_REST_API_ENDPOINT + 'ticket/' + this.ticket.externalId + '/link?external=true', {
        headers: headers
      }).then(function (response) {
        _this12.loading = false;
        _this12.child_tickets = [];
        _this12.child_tickets = response.data.data.tickets;
        if (_this12.child_tickets.length === 0) {
          _this12.addChildTicket();
        }
      }).catch(function (error) {
        _this12.loading = false;
        if (error.response.status === 400) {
          _this12.child_tickets = [];
          _this12.addChildTicket();
        } else {
          _this12.$notify({
            title: 'Error',
            message: error.response.message,
            type: 'error',
            duration: 2000
          });
        }
      });
    },
    retrieveChildTickets: function retrieveChildTickets() {
      var _this13 = this;
      this.child_ticket_loading = true;
      this.loading = true;
      // Retrieve Ticket and populate ticket modal
      var headers = {
        'Content-Type': 'application/json',
        'x-party-id': this.$store.getters.partyId,
        'x-user-id': this.$store.getters.id
      };
      _axios.default.get(process.env.VUE_APP_REST_API_ENDPOINT + 'ticket/' + this.ticket.id + '/link', {
        headers: headers
      }).then(function (response) {
        _this13.child_ticket_loading = false;
        _this13.loading = false;
        _this13.child_tickets = [];
        _this13.child_tickets = response.data.data.tickets;
        if (_this13.child_tickets.length === 0) {
          _this13.addChildTicket();
        }
      }).catch(function (error) {
        _this13.child_ticket_loading = false;
        _this13.loading = false;
        if (error.response.status === 400) {
          _this13.child_tickets = [];
          _this13.addChildTicket();
        } else {
          _this13.$notify({
            title: 'Error',
            message: error.response.message,
            type: 'error',
            duration: 2000
          });
        }
      });
    },
    retrieveTicketAttachements: function retrieveTicketAttachements() {
      var _this14 = this;
      this.attachment_loading = true;
      this.loading = true;
      // Retrieve Ticket and populate ticket modal
      var headers = {
        'Content-Type': 'application/json',
        'x-party-id': this.$store.getters.partyId,
        'x-user-id': this.$store.getters.id,
        'x-entity-id': this.ticket.id,
        'x-entity-name': this.ticket.name
      };
      _axios.default.get(process.env.VUE_APP_REST_API_ENDPOINT + 'file?entityId=' + this.ticket.id, {
        headers: headers
      }).then(function (response) {
        _this14.attachment_loading = false;
        _this14.loading = false;
        _this14.files = [];
        if (response.data.data.status === 'FAILED') {
          _this14.files = [];
        } else {
          _this14.files = response.data.data;
          _this14.$nextTick();
          _this14.$forceUpdate();
        }
      }).catch(function (error) {
        _this14.attachment_loading = false;
        _this14.loading = false;
        if (error.response.status === 400) {
          _this14.files = [];
        } else {
          _this14.$notify({
            title: 'Error',
            message: error.response.message,
            type: 'error',
            duration: 2000
          });
        }
      });
    },
    handleParentSelection: function handleParentSelection(selected_data) {
      var _this15 = this;
      for (var i = 0; i < this.offering.linkedProducts.length; i++) {
        if (this.offering.linkedProducts[i].product.id === selected_data) {
          if (this.parentType === 'Service') {
            this.$confirm('Do you want to switch type to Product', 'Warning', {
              confirmButtonText: 'OK',
              cancelButtonText: 'Cancel',
              type: 'warning'
            }).then(function () {
              _this15.$emit('updateParentType', 'Product');
            }).catch(function () {
              _this15.$message({
                type: 'info',
                message: 'Request canceled'
              });
            });
          }
        }
      }
      for (var j = 0; j < this.offering.linkedServices.length; j++) {
        if (this.offering.linkedServices[j].service.id === selected_data) {
          if (this.parentType === 'Product') {
            this.$confirm('Do you want to switch type to Product', 'Warning', {
              confirmButtonText: 'OK',
              cancelButtonText: 'Cancel',
              type: 'warning'
            }).then(function () {
              _this15.$emit('updateParentType', 'Service');
            }).catch(function () {
              _this15.$message({
                type: 'info',
                message: 'Request canceled'
              });
            });
          }
        }
      }
      this.$emit('updateParent', selected_data);
    },
    computeSmartPriority: function computeSmartPriority() {
      for (var i = 0; i < this.offering.additionalattributes.relations.length; i++) {
        if (this.offering.additionalattributes.relations[i].end_id === this.child.id && this.offering.additionalattributes.relations[i].start_type === 'Step') {
          this.getStepPriority(this.offering.additionalattributes.relations[i].start_id);
          this.getPhaseFromStepID(this.offering.additionalattributes.relations[i].start_id);
        }
      }
    },
    getStepPriority: function getStepPriority(step_id) {
      for (var i = 0; i < this.journeyMap.linkedJourneySteps.length; i++) {
        if (this.journeyMap.linkedJourneySteps[i].journeyStep.id === step_id) {
          this.ticket.smart_priority += this.computeNumericalPriority(this.journeyMap.linkedJourneySteps[i].journeyStep.priority);
        }
      }
    },
    getPhaseFromStepID: function getPhaseFromStepID(step_id) {
      for (var i = 0; i < this.journeyMap.additionalattributes.relations.length; i++) {
        if (this.journeyMap.additionalattributes.relations[i].end_id === step_id && this.journeyMap.additionalattributes.relations[i].start_type === 'Phase') {
          this.getPhasePriority(this.journeyMap.additionalattributes.relations[i].start_id);
        }
      }
    },
    getPhasePriority: function getPhasePriority(phase_id) {
      for (var i = 0; i < this.journeyMap.linkedJourneyPhases.length; i++) {
        if (this.journeyMap.linkedJourneyPhases[i].journeyPhase.id === phase_id) {
          this.ticket.smart_priority += this.computeNumericalPriority(this.journeyMap.linkedJourneyPhases[i].journeyPhase.priority);
          this.ticket.smart_priority = (this.ticket.smart_priority / 3).toFixed(2);
        }
      }
    },
    computeNumericalPriority: function computeNumericalPriority(priority) {
      switch (priority) {
        case 'Very Low':
          return 1;
        case 'Low':
          return 2;
        case 'Medium':
          return 3;
        case 'High':
          return 4;
        case 'Very High':
          return 5;
      }
    },
    addAction: function addAction(action) {
      // this.artefact_actions.push(action)
    },
    addComment: function addComment() {
      this.discussions.push({
        id: '2',
        author: this.$store.getters.firstName + ' ' + this.$store.getters.lastName,
        image_url: 'https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif',
        message: this.comment,
        date_time: '2021-07-20 12:12:12'
      });
      this.comment = '';
    },
    computeIcon: function computeIcon(type) {
      switch (type) {
        case 'PNG':
        case 'JPG':
          return 'el-icon-picture-outline';
        default:
          return 'el-icon-document';
      }
    },
    computeMessengerAvatar: function computeMessengerAvatar(image_url) {
      return 'background-image: url(' + image_url + ')';
    },
    deleteDiscussion: function deleteDiscussion(discussion, index) {
      var _this16 = this;
      this.$confirm('This will permanently delete the comment. Continue?', 'Warning', {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(function () {
        _this16.discussions.splice(index, 1);
      }).catch(function () {
        _this16.$message({
          type: 'info',
          message: 'Delete canceled'
        });
      });
    },
    editDiscussion: function editDiscussion(discussion, index) {
      this.edit.mode = true;
      this.edit.index = index;
    },
    updateDiscussion: function updateDiscussion(discussion, index) {
      this.edit.mode = false;
      this.edit.index = 0;
    },
    computeUserNameFromUserID: function computeUserNameFromUserID(user_id, type) {
      var _this17 = this;
      if (user_id !== undefined) {
        var headers = {
          'Content-Type': 'application/json',
          'x-party-id': this.$store.getters.partyId,
          'x-user-id': this.$store.getters.id
        };
        _axios.default.get(process.env.VUE_APP_REST_API_ENDPOINT + 'user/' + user_id, {
          headers: headers
        }).then(function (response) {
          if (type === 'createdBy') {
            _this17.ticket.createdBy = response.data.data.firstName + ' ' + response.data.data.lastName;
          }
          if (type === 'modifiedBy') {
            _this17.ticket.modifiedBy = response.data.data.firstName + ' ' + response.data.data.lastName;
          }
        }).catch(function (error) {
          if (error.response.status === 400) {
            console.log('error');
          } else {
            _this17.$notify({
              title: 'Error',
              message: error.response.message,
              type: 'error',
              duration: 2000
            });
          }
        });
      }
    },
    computeHumanFriendlyDate: function computeHumanFriendlyDate(date) {
      return this.$moment(date, 'YYYY-MM-DD HH:mm:ss').format('ll');
    },
    computeHumanFriendlyDateDifference: function computeHumanFriendlyDateDifference(date) {
      var today = this.$moment();
      return this.$moment.tz(date, 'Australia/Sydney').from(today);
    },
    checkIfUserIsAuthor: function checkIfUserIsAuthor(author) {
      var username = this.$store.getters.firstName + ' ' + this.$store.getters.lastName;
      if (username === author) {
        return true;
      }
    },
    attachFiles: function attachFiles() {
      this.$refs.fileInput.click();
    },
    addChildTicket: function addChildTicket() {
      var child_ticket = {
        id: '',
        title: '',
        description: '',
        type: '',
        status: '',
        priority: ''
      };
      this.child_tickets.push(child_ticket);
    },
    unlinkChildTicketFromParent: function unlinkChildTicketFromParent(child_ticket_id, parent_ticket_id) {
      var _this18 = this;
      this.child_ticket_loading = true;
      var headers = {
        'Content-Type': 'application/json'
        // 'Authorization': 'Bearer '+getJWTToken()
      };
      var create_body = {
        data: [{
          'ticketId': child_ticket_id,
          'relationship': 'childLink1'
        }],
        'header': {
          'userId': this.$store.getters.id,
          'partyId': this.$store.getters.partyId
        }
      };
      _axios.default.delete(process.env.VUE_APP_REST_API_ENDPOINT + 'ticket/' + parent_ticket_id + '/link', {
        headers: headers,
        data: create_body
      }).then(function (response) {
        _this18.child_ticket_loading = false;
        if (response.data.status === 'SUCCESS') {
          _this18.retrieveChildTickets();
        }
      }).catch(function (error) {
        _this18.child_ticket_loading = false;
        console.log(error);
      });
    },
    deleteChildTicket: function deleteChildTicket(child_ticket_id, parent_ticket_id) {
      var _this19 = this;
      this.$confirm('This will permanently delete ticket. Continue?', 'Warning', {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(function () {
        _this19.child_ticket_loading = true;
        var headers = {
          'Content-Type': 'application/json'
          // 'Authorization': 'Bearer '+getJWTToken()
        };
        var create_body = {
          data: [{
            'ticketId': child_ticket_id,
            'relationship': 'childLink1'
          }],
          'header': {
            'userId': _this19.$store.getters.id,
            'partyId': _this19.$store.getters.partyId
          }
        };
        _axios.default.delete(process.env.VUE_APP_REST_API_ENDPOINT + 'ticket/' + parent_ticket_id + '/link', {
          headers: headers,
          data: create_body
        }).then(function (response) {
          _this19.child_ticket_loading = false;
          if (response.data.status === 'SUCCESS') {
            // DELETE START*/
            _this19.child_ticket_loading = true;
            var _headers3 = {
              'Content-Type': 'application/json'
              // 'Authorization': 'Bearer '+getJWTToken()
            };
            var create_body1 = {
              data: [{
                'id': child_ticket_id
              }],
              'header': {
                'userId': _this19.$store.getters.id,
                'partyId': _this19.$store.getters.partyId
              }
            };
            _axios.default.delete(process.env.VUE_APP_REST_API_ENDPOINT + 'ticket', {
              headers: _headers3,
              data: create_body1
            }).then(function (response1) {
              _this19.child_ticket_loading = false;
              if (response1.data.data.status === 'SUCCESS') {
                _this19.retrieveChildTickets();
              }
            }).catch(function (error) {
              _this19.child_ticket_loading = false;
              console.log(error);
            });
            // DELETE END
          }
        }).catch(function (error) {
          _this19.child_ticket_loading = false;
          console.log(error);
        });
      }).catch(function () {
        _this19.$message({
          type: 'info',
          message: 'Delete canceled'
        });
      });
    },
    // Helper Functions
    retrievePhaseFromFeatureID: function retrievePhaseFromFeatureID(feature_id) {
      for (var i = 0; i < this.offering.linkedProducts.length; i++) {
        for (var j = 0; j < this.offering.linkedProducts[i].product.linkedFeatures.length; j++) {
          if (this.offering.linkedProducts[i].product.linkedFeatures[j].productFeature.id === feature_id) {
            if (this.offering.linkedProducts[i].product.linkedFeatures[j].productFeature.relations.steps.length > 0) {
              return this.retrievePhaseFromStepID(this.offering.linkedProducts[i].product.linkedFeatures[j].productFeature.relations.steps[0].id);
            }
          }
        }
      }
      return {
        title: ''
      };
    },
    retrieveStepFromFeatureID: function retrieveStepFromFeatureID(feature_id) {
      for (var i = 0; i < this.offering.linkedProducts.length; i++) {
        for (var j = 0; j < this.offering.linkedProducts[i].product.linkedFeatures.length; j++) {
          if (this.offering.linkedProducts[i].product.linkedFeatures[j].productFeature.id === feature_id) {
            if (this.offering.linkedProducts[i].product.linkedFeatures[j].productFeature.relations.steps.length > 0) {
              return this.retrieveStepFromStepID(this.offering.linkedProducts[i].product.linkedFeatures[j].productFeature.relations.steps[0].id);
            }
          }
        }
      }
      return {
        title: ''
      };
    },
    retrievePhaseFromStepID: function retrievePhaseFromStepID(step_id) {
      for (var i = 0; i < this.journeyMap.additionalattributes.relations.length; i++) {
        if (this.journeyMap.additionalattributes.relations[i].start_type === 'Phase' && this.journeyMap.additionalattributes.relations[i].end_type === 'Step' && this.journeyMap.additionalattributes.relations[i].end_id === step_id) {
          return this.retrievePhaseFromID(this.journeyMap.additionalattributes.relations[i].start_id);
        }
      }
    },
    retrievePhaseFromID: function retrievePhaseFromID(phase_id) {
      for (var i = 0; i < this.journeyMap.linkedJourneyPhases.length; i++) {
        if (this.journeyMap.linkedJourneyPhases[i].journeyPhase.id === phase_id) {
          return this.journeyMap.linkedJourneyPhases[i].journeyPhase;
        }
      }
    },
    retrieveStepFromStepID: function retrieveStepFromStepID(step_id) {
      for (var i = 0; i < this.journeyMap.linkedJourneySteps.length; i++) {
        if (this.journeyMap.linkedJourneySteps[i].journeyStep.id === step_id) {
          return this.journeyMap.linkedJourneySteps[i].journeyStep;
        }
      }
    },
    computeCustomerTicketList: function computeCustomerTicketList() {
      // get list of phases for each customer
      // get list of steps for each phase
      // get list of features for each step
      // query tickets for each feature and add to array
      var ticket_parent_ids = [];
      for (var i = 0; i < this.journeyMap.linkedJourneyPhases.length; i++) {
        ticket_parent_ids.push(this.journeyMap.linkedJourneyPhases[i].journeyPhase.id);
      }
      var tickets = [];
      for (var j = 0; j < ticket_parent_ids.length; j++) {
        if (tickets.length === 0) {
          tickets = this.computePhaseTicketList(ticket_parent_ids[j]);
        } else {
          tickets.push.apply(tickets, this.computePhaseTicketList(ticket_parent_ids[j]));
        }
      }
      return tickets;
    },
    computePhaseTicketList: function computePhaseTicketList(phase_id) {
      // get list of steps for selected phase
      // get list of features for each step
      // query tickets for each feature and add to array
      var ticket_parent_ids = [];
      for (var i = 0; i < this.journeyMap.linkedJourneyPhases.length; i++) {
        if (this.journeyMap.linkedJourneyPhases[i].journeyPhase.id === phase_id) {
          if (this.journeyMap.linkedJourneyPhases[i].journeyPhase.relations) {
            ticket_parent_ids = this.journeyMap.linkedJourneyPhases[i].journeyPhase.relations;
          }
        }
      }
      var tickets = [];
      for (var j = 0; j < ticket_parent_ids.length; j++) {
        if (tickets.length === 0) {
          tickets = this.computeStepTicketList(ticket_parent_ids[j]);
        } else {
          tickets.push.apply(tickets, this.computeStepTicketList(ticket_parent_ids[j]));
        }
      }
      return tickets;
    },
    computeStepTicketList: function computeStepTicketList(step_id) {
      var _this20 = this;
      // Get list of features with selected step id
      // query tickets for each feature and add to array
      var ticket_parent_ids = [];
      for (var i = 0; i < this.offering.linkedProducts.length; i++) {
        for (var j = 0; j < this.offering.linkedProducts[i].product.linkedFeatures.length; j++) {
          for (var k = 0; k < this.offering.linkedProducts[i].product.linkedFeatures[j].productFeature.relations.steps.length; k++) {
            if (this.offering.linkedProducts[i].product.linkedFeatures[j].productFeature.relations.steps[k].id === step_id) {
              ticket_parent_ids.push(this.offering.linkedProducts[i].product.linkedFeatures[j].productFeature.id);
            }
          }
        }
      }
      for (var l = 0; l < this.offering.linkedServices.length; l++) {
        for (var m = 0; m < this.offering.linkedServices[l].service.linkedServiceApplications.length; m++) {
          for (var n = 0; n < this.offering.linkedServices[l].service.linkedServiceApplications[m].serviceApplication.relations.steps.length; n++) {
            if (this.offering.linkedServices[l].service.linkedServiceApplications[m].serviceApplication.relations.steps[n].id === step_id) {
              ticket_parent_ids.push(this.offering.linkedServices[l].service.linkedServiceApplications[m].serviceApplication.id);
            }
          }
        }
      }
      var tickets = [];
      for (var o = 0; o < ticket_parent_ids.length; o++) {
        var headers = {
          'Content-Type': 'application/json',
          'x-party-id': this.$store.getters.partyId,
          'x-user-id': this.$store.getters.id
        };
        _axios.default.get(process.env.VUE_APP_REST_API_ENDPOINT + 'ticket?extended=LEVEL0&entityId=' + ticket_parent_ids[o], {
          headers: headers
        }).then(function (response) {
          tickets.push(response.data.data[0]); // hardcoded 0 element, since its a one on one relation
        }).catch(function (error) {
          if (error.response.status === 400) {
            console.log('error');
          } else {
            _this20.$notify({
              title: 'Error',
              message: error.response.message,
              type: 'error',
              duration: 2000
            });
          }
        });
      }
      return tickets;
    },
    selectChildTicket: function selectChildTicket(child_ticket) {
      this.selectedChildTicket = child_ticket;
      this.childTicketDetailsDialog = true;
    },
    computeChildTicketDetailTitle: function computeChildTicketDetailTitle() {
      if (this.selectedChildTicket.slug !== undefined) {
        return this.selectedChildTicket.slug + '-' + this.selectedChildTicket.title;
      }
      return this.selectedChildTicket.title;
    },
    convertHTMLToADF: function convertHTMLToADF(html_string) {
      var adfTransformer = new _editorJsonTransformer.JSONTransformer();
      var jiraTransformer = new _editorJiraTransformer.JIRATransformer(_schemaDefault.defaultSchema);
      var adfJIRAText = jiraTransformer.parse(html_string);
      return adfTransformer.encode(adfJIRAText);
    }
  }
};