"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _resolution = require("@/utils/resolution");
var _default2 = exports.default = {
  name: 'EntityLane',
  components: {},
  props: {
    x: {
      type: Number,
      required: true,
      default: 0
    },
    y: {
      type: Number,
      required: true,
      default: 0
    },
    width: {
      type: Number,
      required: true,
      default: 0
    },
    height: {
      type: Number,
      required: true,
      default: 0
    },
    headerWidth: {
      type: Number,
      required: true,
      default: 0
    },
    index: {
      type: Number,
      required: true,
      default: 0
    },
    isMinimized: {
      type: Boolean,
      default: false
    },
    entity: {
      type: Object,
      default: function _default() {
        return {
          title: '',
          description: ''
        };
      }
    }
  },
  data: function data() {
    return {
      loading: false,
      hasMapped: true,
      hasUnmapped: true
    };
  },
  computed: {
    computeTitleStyle: function computeTitleStyle() {
      /* if(this.entity.__typename === 'Product') {
        return 'font-size:0.8em;font-weight:600; text-align: left; color:#0D63BF; margin-right: 10pt;  padding:3pt; word-wrap: break-word'
      }
      return 'font-size:0.8em;font-weight:600; text-align: left; color:#4CA97E; margin-right: 10pt;  padding:3pt; word-wrap: break-word'*/
      return 'font-size:0.9em;font-weight:400;font-variant-caps: small-caps; text-align: left; color:white; margin-right: 10pt;  padding:1pt 3pt 4pt 3pt; word-wrap: break-word';
    },
    computeStyle: function computeStyle() {
      /* if(this.entity.__typename === 'Product') {
        return 'color:#0D63BF;'
      }
      return 'color:#4CA97E;'*/
      return 'color:white;';
    },
    computeLeftEntityMaskWidth: function computeLeftEntityMaskWidth() {
      var width = 5;
      return width;
      // return width * screenRatio()
    },
    computeRightEntityMaskWidth: function computeRightEntityMaskWidth() {
      var width = 150;
      return width * (0, _resolution.screenRatio)();
    }
  },
  watch: {},
  mounted: function mounted() {},
  created: function created() {},
  methods: {
    computeFilterStyle: function computeFilterStyle(type) {
      if (type === 'mapped' && this.hasMapped) {
        return 'font-size:14px;fill:none;stroke:lightgreen;';
      } else if (type === 'mapped' && !this.hasMapped) {
        return 'font-size:14px;fill:none;stroke:#FFF;';
      } else if (type === 'unmapped' && this.hasUnmapped) {
        return 'font-size:14px;fill:none;stroke:lightgreen  ;';
      } else if (type === 'unmapped' && !this.hasUnmapped) {
        return 'font-size:14px;fill:none;stroke:white;';
      }
      return 'font-size:14px;fill:none;stroke:#FFF;';
    },
    toggleUnmapped: function toggleUnmapped() {
      this.hasUnmapped = !this.hasUnmapped;
      this.$emit('toggle-unmapped', this.index, this.hasUnmapped);
    },
    toggleMapped: function toggleMapped() {
      this.hasMapped = !this.hasMapped;
      this.$emit('toggle-mapped', this.index, this.hasMapped);
    },
    selectEntity: function selectEntity() {
      console.log('click');
      this.$emit('click', this.entity, this.index);
    },
    toggleLane: function toggleLane() {
      this.$emit('toggle-lane', this.index, !this.isMinimized);
    },
    dynamicSvgTextLength: function dynamicSvgTextLength(string) {
      var pixelMultipler = 8;
      if (string.length < 4) {
        pixelMultipler = 13;
      } else if (string.length < 7) {
        pixelMultipler = 10;
      }
      return string.length * pixelMultipler;
    },
    truncateString: function truncateString(string, length) {
      return this._.truncate(string, {
        'length': length
      });
    }
  }
};