import { render, staticRenderFns } from "./release.vue?vue&type=template&id=0d83dc96"
import script from "./release.vue?vue&type=script&lang=js"
export * from "./release.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\Sastha\\Documents\\GitHub\\prodiggiapp-frontend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0d83dc96')) {
      api.createRecord('0d83dc96', component.options)
    } else {
      api.reload('0d83dc96', component.options)
    }
    module.hot.accept("./release.vue?vue&type=template&id=0d83dc96", function () {
      api.rerender('0d83dc96', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/processes/create_user_value/components/roadmap/release.vue"
export default component.exports