"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-row", {
    staticClass: "app-container"
  }, [_c("el-col", {
    staticClass: "pr-5",
    attrs: {
      span: 4
    }
  }, [_c("el-card", [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("strong", [_vm._v("PainPoint/Pleasers")])]), _vm._v(" "), _c("div", [_c("el-row", [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("table", {
    staticClass: "text-center",
    attrs: {
      width: "100%"
    }
  }, [_c("tr", [_c("th", [_vm._v("Pain Point")]), _vm._v(" "), _c("th", [_vm._v("Value")]), _vm._v(" "), _c("th", [_vm._v("Priority")]), _vm._v(" "), _c("th", [_vm._v("#")])]), _vm._v(" "), _vm._l(_vm.pain_points, function (pain_point, index) {
    return _c("tr", {
      key: index,
      attrs: {
        draggable: ""
      },
      on: {
        drag: function drag($event) {
          return _vm.setActiveArtefactDragElement("pain_point", index);
        }
      }
    }, [_c("td", [_vm._v(_vm._s(pain_point.pain_point))]), _vm._v(" "), _c("td", [pain_point.value ? _c("svg-icon", {
      attrs: {
        "icon-class": _vm.getValueIcon(pain_point.value),
        title: "Value"
      }
    }) : _vm._e()], 1), _vm._v(" "), _c("td", [pain_point.priority ? _c("svg-icon", {
      attrs: {
        "icon-class": _vm.getPriorityIcon(pain_point.priority),
        title: "Priority"
      }
    }) : _vm._e()], 1), _vm._v(" "), _c("td", [_vm._v(" " + _vm._s(pain_point.relations.length) + " ")])]);
  })], 2), _vm._v(" "), _c("table", {
    staticClass: "text-center mt-5 pt-5",
    attrs: {
      width: "100%"
    }
  }, [_c("tr", [_c("th", [_vm._v("Pleaser")]), _vm._v(" "), _c("th", [_vm._v("Value")]), _vm._v(" "), _c("th", [_vm._v("Priority")]), _vm._v(" "), _c("th", [_vm._v("#")])]), _vm._v(" "), _vm._l(_vm.pleasers, function (pleaser, index) {
    return _c("tr", {
      key: index,
      attrs: {
        draggable: ""
      },
      on: {
        drag: function drag($event) {
          return _vm.setActiveArtefactDragElement("pleaser", index);
        }
      }
    }, [_c("td", [_vm._v(_vm._s(pleaser.pleaser))]), _vm._v(" "), _c("td", [pleaser.value ? _c("svg-icon", {
      attrs: {
        "icon-class": _vm.getValueIcon(pleaser.value),
        title: "Value"
      }
    }) : _vm._e()], 1), _vm._v(" "), _c("td", [pleaser.priority ? _c("svg-icon", {
      attrs: {
        "icon-class": _vm.getPriorityIcon(pleaser.priority),
        title: "Priority"
      }
    }) : _vm._e()], 1), _vm._v(" "), _c("td", [_c("div", {
      staticClass: "feature-counter"
    }, [_vm._v("\n                      " + _vm._s(pleaser.relations.length) + "\n                    ")])])]);
  })], 2)])], 1)], 1)])], 1), _vm._v(" "), _c("el-col", {
    staticClass: "pl-5",
    attrs: {
      span: 20
    }
  }, [_c("el-card", [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("strong", [_vm._v("Feature Mapping")])]), _vm._v(" "), _c("div", [_c("el-row", {
    staticClass: "mt-5"
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("el-card", {
    staticClass: "item-new"
  }, [_c("div", {
    staticClass: "feature-title text-center",
    attrs: {
      slot: "header"
    },
    on: {
      click: function click($event) {
        _vm.addFeatureDialog = true;
      }
    },
    slot: "header"
  }, [_vm._v("\n                      Add new Feature\n                    ")]), _vm._v(" "), _c("div", {
    staticClass: "text-center",
    on: {
      click: function click($event) {
        _vm.addFeatureDialog = true;
      }
    }
  }, [_c("i", {
    staticClass: "el-icon-circle-plus-outline icon-large"
  })]), _vm._v(" "), _c("el-divider"), _vm._v(" "), _c("div", {
    staticClass: "text-center"
  }, [_vm._v("Create a new feature here")])], 1)], 1), _vm._v(" "), _vm._l(_vm.candidate_features, function (candidate_feature, index) {
    return _c("el-col", {
      key: candidate_feature.key,
      attrs: {
        span: 6
      }
    }, [_c("el-card", [_c("div", {
      attrs: {
        slot: "header"
      },
      slot: "header"
    }, [_c("strong", {
      staticClass: "pl-5"
    }, [_vm._v(" " + _vm._s(candidate_feature.candidate_feature) + " ")]), _vm._v(" "), _c("i", {
      staticClass: "el-icon-close fr",
      on: {
        click: function click($event) {
          return _vm.removeFeatureCard(index);
        }
      }
    })]), _vm._v(" "), _c("div", {
      on: {
        drop: _vm.handleDrop,
        dragover: _vm.handleDragOver
      }
    }, [candidate_feature.pain_points.length === 0 && candidate_feature.pleasers.length === 0 ? _c("div", {
      attrs: {
        id: index
      }
    }, [_vm._v("\n                        Drag drop PainPoints / Pleasers\n                      ")]) : _c("div", [candidate_feature.pain_points.length > 0 ? _vm._l(candidate_feature.pain_points, function (pain_point, pain_point_index) {
      return _c("el-tag", {
        key: pain_point_index,
        staticClass: "ml-5 mr-5 mt-5",
        attrs: {
          closable: "",
          size: "mini",
          type: "warning"
        },
        on: {
          close: function close($event) {
            return _vm.removePainPoint(index, pain_point_index);
          }
        }
      }, [_vm._v("\n                            " + _vm._s(pain_point.pain_point) + "\n                          ")]);
    }) : _vm._e(), _vm._v(" "), candidate_feature.pleasers.length > 0 ? _vm._l(candidate_feature.pleasers, function (pleaser, pleaser_index) {
      return _c("el-tag", {
        key: pleaser_index,
        staticClass: "ml-5 mr-5 mt-5",
        attrs: {
          closable: "",
          size: "mini",
          type: "success"
        },
        on: {
          close: function close($event) {
            return _vm.removePleaser(index, pleaser_index);
          }
        }
      }, [_vm._v("\n                            " + _vm._s(pleaser.pleaser) + "\n                          ")]);
    }) : _vm._e()], 2)])])], 1);
  })], 2)], 1)], 1)], 1)])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Add Feature",
      visible: _vm.addFeatureDialog,
      width: "90%",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addFeatureDialog = $event;
      }
    }
  }, [_c("el-form", {
    ref: "addFeatureForm",
    staticClass: "mt-5 container",
    attrs: {
      model: _vm.feature_form,
      "label-width": "120px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Feature Name"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.feature_form.candidate_feature,
      callback: function callback($$v) {
        _vm.$set(_vm.feature_form, "candidate_feature", $$v);
      },
      expression: "feature_form.candidate_feature"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Value"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.feature_form.value,
      callback: function callback($$v) {
        _vm.$set(_vm.feature_form, "value", $$v);
      },
      expression: "feature_form.value"
    }
  }, [_c("el-radio", {
    attrs: {
      label: "1"
    }
  }, [_vm._v("Low")]), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "2"
    }
  }, [_vm._v("Medium")]), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "3"
    }
  }, [_vm._v("High")])], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Priority"
    }
  }, [_c("el-slider", {
    attrs: {
      max: 5,
      "show-stops": ""
    },
    model: {
      value: _vm.feature_form.priority,
      callback: function callback($$v) {
        _vm.$set(_vm.feature_form, "priority", $$v);
      },
      expression: "feature_form.priority"
    }
  })], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.addFeature
    }
  }, [_vm._v("Add")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.addFeatureDialog = false;
      }
    }
  }, [_vm._v("Cancel")])], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;