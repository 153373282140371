"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    attrs: {
      visible: _vm.open,
      "custom-class": "create-idea-dialog",
      width: "40%"
    },
    on: {
      close: function close($event) {
        return _vm.closeDialog();
      }
    }
  }, [_c("div", {
    staticClass: "text-left",
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_c("div", {
    staticStyle: {
      "font-weight": "600"
    }
  }, [_vm._v(_vm._s(_vm.createIdeaDialogTitle.title) + " ")]), _vm._v(" "), _c("div", {
    staticStyle: {
      "font-size": "10pt",
      "font-weight": "400"
    }
  }, [_vm._v(_vm._s(_vm.createIdeaDialogTitle.description))])]), _vm._v(" "), _c("el-form", {
    ref: "form_step1",
    attrs: {
      model: _vm.form,
      "label-width": "200px",
      rules: _vm.journeyRules1
    }
  }, [_c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.activeStep === "step1" ? _c("div", [_c("el-form-item", {
    attrs: {
      label: "Scope",
      prop: "scope"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.form.scope,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "scope", $$v);
      },
      expression: "form.scope"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: "Journey"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Phase"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Step"
    }
  })], 1)], 1), _vm._v(" "), _vm.form.scope === "Phase" ? _c("el-form-item", {
    attrs: {
      label: "Phase"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      size: "mini"
    },
    model: {
      value: _vm.form.scopename,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "scopename", $$v);
      },
      expression: "form.scopename"
    }
  }, _vm._l(_vm.phases, function (phase, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: phase.journeyPhase.title,
        value: phase.journeyPhase.title
      }
    });
  }), 1)], 1) : _vm._e(), _vm._v(" "), _vm.form.scope === "Step" ? _c("el-form-item", {
    attrs: {
      label: "Step"
    }
  }, [_c("el-select", {
    attrs: {
      clearable: "",
      size: "mini"
    },
    model: {
      value: _vm.form.scopename,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "scopename", $$v);
      },
      expression: "form.scopename"
    }
  }, _vm._l(_vm.steps, function (step, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: step.journeyStep.title,
        value: step.journeyStep.title
      }
    });
  }), 1)], 1) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "text-right"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.goToStep("step2");
      }
    }
  }, [_vm._v("Next")])], 1)], 1) : _vm._e()])], 1), _vm._v(" "), _c("el-form", {
    ref: "form_step2",
    attrs: {
      model: _vm.form,
      "label-width": "200px",
      rules: _vm.journeyRules2
    }
  }, [_c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.activeStep === "step2" ? _c("div", {
    staticStyle: {
      "animation-fill-mode": "forwards"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Title",
      prop: "title"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.form.title,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "title", $$v);
      },
      expression: "form.title"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Description",
      prop: "description"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      size: "mini"
    },
    model: {
      value: _vm.form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "description", $$v);
      },
      expression: "form.description"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Problem",
      prop: "problemstatement"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      size: "mini"
    },
    model: {
      value: _vm.form.problemstatement,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "problemstatement", $$v);
      },
      expression: "form.problemstatement"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Solution",
      prop: "solution"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      size: "mini"
    },
    model: {
      value: _vm.form.solution,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "solution", $$v);
      },
      expression: "form.solution"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Value Proposition",
      prop: "valueproposition"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      size: "mini"
    },
    model: {
      value: _vm.form.valueproposition,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "valueproposition", $$v);
      },
      expression: "form.valueproposition"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "text-right"
  }, [_c("el-button", {
    attrs: {
      type: "secondary",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.goToStep("step1");
      }
    }
  }, [_vm._v("Previous")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.goToStep("step3");
      }
    }
  }, [_vm._v("Next")])], 1)], 1) : _vm._e()]), _vm._v(" "), _c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.activeStep === "step3" ? _c("div", {
    staticStyle: {
      "animation-fill-mode": "forwards"
    }
  }, [_c("table", {
    staticStyle: {
      "table-layout": "fixed"
    },
    attrs: {
      width: "100%"
    }
  }, [_c("tr", [_c("th", {
    staticClass: "text-right"
  }, [_vm._v("Journey Title")]), _vm._v(" "), _c("td", [_vm._v(_vm._s(_vm.form.title))])]), _vm._v(" "), _c("tr", [_c("th", {
    staticClass: "text-right"
  }, [_vm._v("Journey Description")]), _vm._v(" "), _c("td", [_vm._v(_vm._s(_vm.form.description))])]), _vm._v(" "), _c("tr", [_c("th", {
    staticClass: "text-right"
  }, [_vm._v("Customer Type")]), _vm._v(" "), _c("td", [_vm._v(_vm._s(_vm.customer.title))])]), _vm._v(" "), _c("tr", [_c("th", {
    staticClass: "text-right"
  }, [_vm._v("Background")]), _vm._v(" "), _c("td", [_vm._v(_vm._s(_vm.form.problemstatement))])]), _vm._v(" "), _c("tr", [_c("th", {
    staticClass: "text-right"
  }, [_vm._v("Justification")]), _vm._v(" "), _c("td", [_vm._v(_vm._s(_vm.form.solution))])]), _vm._v(" "), _c("tr", [_c("th", {
    staticClass: "text-right"
  }, [_vm._v("Scope")]), _vm._v(" "), _c("td", [_vm._v(_vm._s(_vm.form.scope))])]), _vm._v(" "), _c("tr", [_c("th", {
    staticClass: "text-right"
  }, [_vm._v("Scopename")]), _vm._v(" "), _c("td", [_vm._v(_vm._s(_vm.form.scopename))])]), _vm._v(" "), _c("tr", [_c("th", {
    staticClass: "text-right"
  }, [_vm._v("Value Proposition")]), _vm._v(" "), _c("td", [_vm._v(_vm._s(_vm.form.valueproposition))])])]), _vm._v(" "), _c("div", {
    staticClass: "text-right"
  }, [_c("el-button", {
    attrs: {
      type: "secondary",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.goToStep("step2");
      }
    }
  }, [_vm._v("Previous")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.createJourneyIdea();
      }
    }
  }, [_vm._v("Create")])], 1)]) : _vm._e()])], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;