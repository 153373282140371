"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 4
    }
  }, [_c("item-list", {
    attrs: {
      title: "Risks",
      items: _vm.risks,
      "fuzzy-search": true,
      total: _vm.risks_total,
      "create-item": false,
      "selected-item": _vm.selectedRisk
    },
    on: {
      click: _vm.selectRisk,
      "load-page": _vm.retrieveRisk
    }
  })], 1), _vm._v(" "), _vm.selectedRisk ? _c("el-col", {
    attrs: {
      span: 20
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-card", [_c("div", {
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("strong", [_vm._v("Title")])]), _vm._v("\n            " + _vm._s(_vm.selectedRisk.title) + "\n          ")])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-card", [_c("div", {
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("strong", [_vm._v("Description")])]), _vm._v("\n            " + _vm._s(_vm.selectedRisk.description) + "\n          ")])], 1)], 1), _vm._v(" "), _c("el-row", {
    staticClass: "mt-10",
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("el-card", [_c("div", {
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("strong", [_vm._v("Severity")])]), _vm._v("\n            " + _vm._s(_vm.selectedRisk.severity) + "\n          ")])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("el-card", [_c("div", {
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("strong", [_vm._v("Occurance Probability")])]), _vm._v("\n            " + _vm._s(_vm.selectedRisk.occuranceprobability) + "\n          ")])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("el-card", [_c("div", {
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("strong", [_vm._v("Mitigation Plan")])]), _vm._v("\n            " + _vm._s(_vm.selectedRisk.mitigationplan) + "\n          ")])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("el-card", [_c("div", {
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("strong", [_vm._v("Impact Level")])]), _vm._v("\n            " + _vm._s(_vm.selectedRisk.impactlevel) + "\n          ")])], 1)], 1)], 1) : _c("el-col", {
    attrs: {
      span: 20
    }
  }, [_c("div", {
    staticClass: "text-center"
  }, [_vm._v("\n        No risk selected.\n      ")])])], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;