"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getUserInfo = getUserInfo;
exports.login = login;
var _request = _interopRequireDefault(require("@/utils/request"));
var _axios = _interopRequireDefault(require("axios"));
/* eslint-disable */

function login(param) {
  return new Promise(function (resolve, reject) {
    var headers = {
      'Content-Type': 'application/json'
    };
    _axios.default.post(process.env.VUE_APP_REST_API_ENDPOINT + 'login', param, {
      headers: headers
    }).then(function (response) {
      resolve(response);
    }).catch(function (error) {
      reject(error);
    });
  });
}
function getUserInfo(token) {
  return (0, _request.default)({
    url: '/user/info',
    method: 'get',
    params: {
      token: token
    }
  });
}