"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-card", {
    staticClass: "mt-10"
  }, [_c("el-row", [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-row", {
    staticClass: "sub-title-rounded mt-5"
  }, [_c("el-col", {
    staticClass: "vertical-center",
    attrs: {
      span: 3
    }
  }, [_vm._v("\n            Product Features\n          ")]), _vm._v(" "), _c("el-col", {
    staticClass: "text-center",
    attrs: {
      span: 15
    }
  }, [_c("el-checkbox-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.companyCategory,
      callback: function callback($$v) {
        _vm.companyCategory = $$v;
      },
      expression: "companyCategory"
    }
  }, [_c("el-checkbox-button", {
    attrs: {
      label: "Strategic Only"
    }
  }), _vm._v(" "), _c("el-checkbox-button", {
    attrs: {
      label: "Operational"
    }
  }), _vm._v(" "), _c("el-checkbox-button", {
    attrs: {
      label: "Planned"
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "Search",
      "prefix-icon": "el-icon-search",
      size: "mini"
    },
    model: {
      value: _vm.featureSearch,
      callback: function callback($$v) {
        _vm.featureSearch = $$v;
      },
      expression: "featureSearch"
    }
  })], 1)], 1), _vm._v(" "), _c("el-row", {
    staticClass: "container"
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-table", {
    attrs: {
      data: _vm.product_features.filter(function (data) {
        return !_vm.featureSearch || data.feature.toLowerCase().includes(_vm.featureSearch.toLowerCase());
      })
    },
    on: {
      "row-click": _vm.rowClick
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Feature",
      prop: "feature"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Status",
      prop: "status"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Version",
      prop: "version"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Segment",
      prop: "segment"
    }
  }), _vm._v(" "), _c("el-table-column", {
    staticStyle: {
      "margin-left": "12pt"
    },
    attrs: {
      label: "Feature Competitor Link",
      width: "500"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._l(scope.row.competitors, function (competitor, index) {
          return _c("el-tag", {
            key: index,
            staticClass: "feature_tag",
            attrs: {
              type: _vm.computeRisk(competitor.risk),
              size: "mini"
            }
          }, [_vm._v("\n                    " + _vm._s(competitor.name) + "\n                  ")]);
        }), _vm._v(" "), _c("el-tag", {
          attrs: {
            type: "info",
            size: "mini"
          },
          on: {
            click: function click($event) {
              _vm.addCompetitorDialogVisible = true;
            }
          }
        }, [_c("i", {
          staticClass: "el-icon-plus"
        })])];
      }
    }])
  })], 1)], 1)], 1)], 1)], 1), _vm._v(" "), _c("el-row", [_c("el-col", {
    staticClass: "mt-10",
    attrs: {
      span: 24
    }
  }, [_c("el-tabs", {
    attrs: {
      type: "border-card",
      value: "feature-competitive-profile"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "Feature Competitive Profile",
      name: "feature-competitive-profile"
    }
  }, [!_vm.isEmpty() ? _c("el-row", [_c("el-col", {
    staticClass: "text-center",
    attrs: {
      span: 14
    }
  }, [_c("el-form", {
    attrs: {
      "label-width": "150px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Name"
    }
  }), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Description"
    }
  }), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Status"
    }
  }), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Version"
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.activeProductFeature.competitors
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Name",
      prop: "name"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Risk",
      prop: "risk",
      width: "100"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "text-right mt-10"
  }, [_vm.hasActiveFeature ? _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.addCompetitorDialogVisible = true;
      }
    }
  }, [_vm._v("\n                    Add Competitor\n                  ")]) : _vm._e()], 1)], 1)], 1) : _c("el-alert", {
    staticClass: "help_note",
    attrs: {
      effect: "dark",
      title: "No Feature selected",
      description: "Select a Feature to view details",
      type: "info",
      "show-icon": ""
    }
  })], 1)], 1)], 1)], 1), _vm._v(" "), _c("el-divider"), _vm._v(" "), _c("div", {
    staticClass: "text-right"
  }, [_c("el-button-group", {
    attrs: {
      size: "mini"
    }
  }, [_c("el-tooltip", {
    attrs: {
      effect: "dark",
      content: "Go To View Segment Competitor",
      placement: "top-start"
    }
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      icon: "el-icon-arrow-left"
    },
    on: {
      click: _vm.onClickViewSegmentCompetitor
    }
  }, [_vm._v("Previous")])], 1), _vm._v(" "), _c("el-tooltip", {
    attrs: {
      effect: "dark",
      content: "Go To Assess Trends",
      placement: "top-start"
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.onClickAssessTrends
    }
  }, [_vm._v("Next"), _c("i", {
    staticClass: "el-icon-arrow-right"
  })])], 1)], 1)], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      visible: _vm.addCompetitorDialogVisible,
      title: "Add Competitor",
      width: "30%",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addCompetitorDialogVisible = $event;
      },
      open: _vm.openCompetitorDialog
    }
  }, [_c("el-form", {
    ref: "competitor_form",
    attrs: {
      model: _vm.competitor_form,
      "label-width": "200px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Competitor Name"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Select"
    },
    model: {
      value: _vm.competitor_form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.competitor_form, "name", $$v);
      },
      expression: "competitor_form.name"
    }
  }, _vm._l(_vm.competitors, function (competitor) {
    return _c("el-option", {
      key: competitor.name,
      attrs: {
        label: competitor.name,
        value: competitor.name
      }
    });
  }), 1), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: function click($event) {
        _vm.addCompetitorDialog = true;
      }
    }
  }, [_vm._v("Create new competitor")])], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Competitors Risk"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "small"
    },
    model: {
      value: _vm.competitor_form.risk,
      callback: function callback($$v) {
        _vm.$set(_vm.competitor_form, "risk", $$v);
      },
      expression: "competitor_form.risk"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: "high"
    }
  }, [_vm._v(" High ")]), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "medium"
    }
  }, [_vm._v(" Medium ")]), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "low"
    }
  }, [_vm._v(" Low ")])], 1)], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.addCompetitorDialogVisible = false;
      }
    }
  }, [_vm._v("Cancel")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.addCompetitor
    }
  }, [_vm._v("Create")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Add Competitors",
      visible: _vm.addCompetitorDialog,
      width: "70%",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addCompetitorDialog = $event;
      }
    }
  }, [_c("el-form", {
    ref: "new_competitor_form",
    attrs: {
      model: _vm.new_competitor_form,
      "label-width": "200px"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Name"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.new_competitor_form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.new_competitor_form, "name", $$v);
      },
      expression: "new_competitor_form.name"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Head Office"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.new_competitor_form.head_office,
      callback: function callback($$v) {
        _vm.$set(_vm.new_competitor_form, "head_office", $$v);
      },
      expression: "new_competitor_form.head_office"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Category"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.new_competitor_form.category,
      callback: function callback($$v) {
        _vm.$set(_vm.new_competitor_form, "category", $$v);
      },
      expression: "new_competitor_form.category"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: "Direct"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Close"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Peripheral"
    }
  })], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Segment"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.new_competitor_form.segment,
      callback: function callback($$v) {
        _vm.$set(_vm.new_competitor_form, "segment", $$v);
      },
      expression: "new_competitor_form.segment"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: "Methodology"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "UX Tools"
    }
  })], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Markets"
    }
  }, [_c("el-select", {
    staticStyle: {
      "margin-left": "20px"
    },
    attrs: {
      multiple: "",
      placeholder: "Select"
    },
    model: {
      value: _vm.new_competitor_form.markets,
      callback: function callback($$v) {
        _vm.$set(_vm.new_competitor_form, "markets", $$v);
      },
      expression: "new_competitor_form.markets"
    }
  }, _vm._l(_vm.continents, function (continent) {
    return _c("el-option", {
      key: continent.name,
      attrs: {
        label: continent.name,
        value: continent.name
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Estimate Value"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.new_competitor_form.estimate_value,
      callback: function callback($$v) {
        _vm.$set(_vm.new_competitor_form, "estimate_value", $$v);
      },
      expression: "new_competitor_form.estimate_value"
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Strength"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.new_competitor_form.strengths,
      callback: function callback($$v) {
        _vm.$set(_vm.new_competitor_form, "strengths", $$v);
      },
      expression: "new_competitor_form.strengths"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Weakness"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.new_competitor_form.weaknesses,
      callback: function callback($$v) {
        _vm.$set(_vm.new_competitor_form, "weaknesses", $$v);
      },
      expression: "new_competitor_form.weaknesses"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "How we compete"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.new_competitor_form.how_we_compete,
      callback: function callback($$v) {
        _vm.$set(_vm.new_competitor_form, "how_we_compete", $$v);
      },
      expression: "new_competitor_form.how_we_compete"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Pricing"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.new_competitor_form.pricing,
      callback: function callback($$v) {
        _vm.$set(_vm.new_competitor_form, "pricing", $$v);
      },
      expression: "new_competitor_form.pricing"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Users"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.new_competitor_form.users,
      callback: function callback($$v) {
        _vm.$set(_vm.new_competitor_form, "users", $$v);
      },
      expression: "new_competitor_form.users"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Market Share"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.new_competitor_form.market_share,
      callback: function callback($$v) {
        _vm.$set(_vm.new_competitor_form, "market_share", $$v);
      },
      expression: "new_competitor_form.market_share"
    }
  })], 1)], 1)], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.addCompetitorDialog = false;
      }
    }
  }, [_vm._v("Cancel")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.createCompetitor
    }
  }, [_vm._v("Create")])], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;