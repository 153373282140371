"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.array.sort");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }]
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("h1", {
    staticStyle: {
      "margin-left": "-10pt"
    }
  }, [_vm._v("External Tickets")])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 10
    }
  }, [_c("div", [_c("el-input", {
    attrs: {
      type: "text",
      size: "mini",
      placeholder: "Type search string"
    },
    model: {
      value: _vm.search_tickets.q,
      callback: function callback($$v) {
        _vm.$set(_vm.search_tickets, "q", $$v);
      },
      expression: "search_tickets.q"
    }
  }, [_c("el-button", {
    attrs: {
      slot: "append",
      icon: "el-icon-search"
    },
    on: {
      click: _vm.displayFilterDialog
    },
    slot: "append"
  }), _c("el-button", {
    staticStyle: {
      "border-radius": "3pt"
    },
    attrs: {
      slot: "append",
      size: "micro",
      type: "secondary"
    },
    on: {
      click: function click($event) {
        return _vm.toggleSort();
      }
    },
    slot: "append"
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "1.2em"
    },
    attrs: {
      "icon-class": _vm.search_tickets.sort.title === "a-z" ? "sort-alpha-down" : "sort-alpha-up"
    }
  })], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "flex flex-wrap pt-5",
    staticStyle: {
      padding: "1pt 1pt 5pt 2pt",
      display: "inline-block",
      "background-color": "rgba(0,0,0,.03)",
      width: "100%"
    }
  }, [_vm.search_tickets.filters.title !== "" ? _c("el-tag", {
    attrs: {
      slot: "reference",
      size: "mini",
      closable: ""
    },
    on: {
      close: function close($event) {
        return _vm.removeSearchFilterTag("title");
      }
    },
    slot: "reference"
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "1.2em"
    },
    attrs: {
      "icon-class": "filter-left"
    }
  }), _vm._v("Name:" + _vm._s(_vm.search_tickets.filters.title_operator) + " " + _vm._s(_vm.search_tickets.filters.title) + "\n            ")], 1) : _vm._e(), _vm._v(" "), _vm.search_tickets.filters.description !== "" ? _c("el-tag", {
    attrs: {
      size: "mini",
      closable: ""
    },
    on: {
      close: function close($event) {
        return _vm.removeSearchFilterTag("description");
      }
    }
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "1.2em"
    },
    attrs: {
      "icon-class": "filter-left"
    }
  }), _vm._v("Description:" + _vm._s(_vm.search_tickets.filters.description_operator) + " " + _vm._s(_vm.search_tickets.filters.description))], 1) : _vm._e(), _vm._v(" "), _vm._l(_vm.search_tickets.filters.status, function (status, index) {
    return _c("el-tag", {
      key: index,
      staticStyle: {
        "vertical-align": "middle"
      },
      attrs: {
        size: "mini",
        closable: ""
      },
      on: {
        close: function close($event) {
          return _vm.removeSearchFilterTag("status", index);
        }
      }
    }, [_c("svg-icon", {
      staticStyle: {
        "font-size": "1.2em"
      },
      attrs: {
        "icon-class": "filter-left"
      }
    }), _vm._v("Status:\n              "), status === "Proposed" ? _c("svg-icon", {
      staticStyle: {
        color: "rgba(8,16,8,0.27)",
        "font-size": "1.0em",
        border: "solid 0.5pt lightgrey"
      },
      attrs: {
        "icon-class": "hand-holding-solid"
      }
    }) : _vm._e(), _vm._v(" "), status === "Accepted" ? _c("svg-icon", {
      staticStyle: {
        color: "rgba(8,16,8,0.27)",
        width: "16pt",
        "padding-bottom": "1pt",
        "border-radius": "10pt",
        "background-color": "cornflowerblue",
        "font-size": "1.0em"
      },
      attrs: {
        "icon-class": "thumbs-up"
      }
    }) : _vm._e(), _vm._v(" "), status === "In progress" ? _c("svg-icon", {
      staticStyle: {
        color: "white",
        "background-color": "#2c64b5",
        "border-radius": "10pt",
        width: "16pt",
        padding: "1pt",
        "font-size": "1.0em"
      },
      attrs: {
        "icon-class": "delivery"
      }
    }) : _vm._e(), _vm._v(" "), status === "On hold" ? _c("svg-icon", {
      staticStyle: {
        color: "#f09a0d",
        "font-size": "1.0em"
      },
      attrs: {
        "icon-class": "pause-circle-fill"
      }
    }) : _vm._e(), _vm._v(" "), status === "Overdue" ? _c("svg-icon", {
      staticStyle: {
        color: "#eb4a49",
        "font-size": "1.0em"
      },
      attrs: {
        "icon-class": "exclamation-circle-fill"
      }
    }) : _vm._e(), _vm._v(" "), status === "Achieved" ? _c("svg-icon", {
      staticStyle: {
        color: "#92b853",
        "font-size": "1.0em"
      },
      attrs: {
        "icon-class": "check-circle-fill"
      }
    }) : _vm._e(), _vm._v("\n              " + _vm._s(status) + "\n            ")], 1);
  }), _vm._v(" "), _vm._l(_vm.search_tickets.filters.priority, function (priority, index) {
    return _c("el-tag", {
      key: index,
      attrs: {
        size: "mini",
        closable: ""
      },
      on: {
        close: function close($event) {
          return _vm.removeSearchFilterTag("priority", index);
        }
      }
    }, [_c("svg-icon", {
      staticStyle: {
        "font-size": "1.2m"
      },
      attrs: {
        "icon-class": "filter-left"
      }
    }), _vm._v("Priority:" + _vm._s(priority) + "\n            ")], 1);
  }), _vm._v(" "), _vm._l(_vm.search_tickets.filters.type, function (type, index) {
    return _c("el-tag", {
      key: index,
      attrs: {
        size: "mini",
        closable: ""
      },
      on: {
        close: function close($event) {
          return _vm.removeSearchFilterTag("type", index);
        }
      }
    }, [_c("svg-icon", {
      staticStyle: {
        "font-size": "1.2m"
      },
      attrs: {
        "icon-class": "filter-left"
      }
    }), _vm._v("Type:" + _vm._s(type) + "\n            ")], 1);
  }), _vm._v(" "), _vm._l(_vm.search_tickets.filters.parent, function (parent, index) {
    return _c("el-tag", {
      key: index,
      attrs: {
        size: "mini",
        closable: ""
      },
      on: {
        close: function close($event) {
          return _vm.removeSearchFilterTag("parent", index);
        }
      }
    }, [_c("svg-icon", {
      staticStyle: {
        "font-size": "1.2m"
      },
      attrs: {
        "icon-class": "filter-left"
      }
    }), _vm._v("Parent:" + _vm._s(parent) + "\n            ")], 1);
  }), _vm._v(" "), _vm.search_tickets.sort.title !== "" ? _c("el-tag", {
    attrs: {
      type: "info",
      size: "mini",
      closable: ""
    },
    on: {
      close: function close($event) {
        return _vm.removeSearchSortTag("title");
      }
    }
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "1.2em"
    },
    attrs: {
      "icon-class": _vm.search_tickets.sort.title === "a-z" ? "sort-alpha-down" : "sort-alpha-up"
    }
  }), _vm._v("\n              Name:" + _vm._s(_vm.search_tickets.sort.title) + "\n            ")], 1) : _vm._e()], 2)], 1)]), _vm._v(" "), _c("el-col", {
    staticClass: "text-right",
    attrs: {
      span: 8
    }
  }, [_c("el-checkbox", {
    model: {
      value: _vm.showUnmapped,
      callback: function callback($$v) {
        _vm.showUnmapped = $$v;
      },
      expression: "showUnmapped"
    }
  }, [_vm._v("Show Unmapped")]), _vm._v(" "), _c("el-checkbox", {
    model: {
      value: _vm.showEnablers,
      callback: function callback($$v) {
        _vm.showEnablers = $$v;
      },
      expression: "showEnablers"
    }
  }, [_vm._v("Show Enablers")]), _vm._v(" "), _c("el-dropdown", {
    attrs: {
      size: "mini",
      "split-button": "",
      type: "primary"
    }
  }, [_c("span", [_c("svg-icon", {
    attrs: {
      "icon-class": "filter"
    }
  })], 1), _vm._v(" "), _c("el-dropdown-menu", {
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, [_c("el-dropdown-item", {
    attrs: {
      command: "Feature/Process"
    }
  }, [_c("el-checkbox", {
    model: {
      value: _vm.filter.feature_process,
      callback: function callback($$v) {
        _vm.$set(_vm.filter, "feature_process", $$v);
      },
      expression: "filter.feature_process"
    }
  }, [_vm._v("Feature/Process")])], 1), _vm._v(" "), _c("el-dropdown-item", {
    attrs: {
      command: "Status"
    }
  }, [_c("el-checkbox", {
    model: {
      value: _vm.filter.status,
      callback: function callback($$v) {
        _vm.$set(_vm.filter, "status", $$v);
      },
      expression: "filter.status"
    }
  }, [_vm._v("Status")])], 1), _vm._v(" "), _c("el-dropdown-item", {
    attrs: {
      command: "Type"
    }
  }, [_c("el-checkbox", {
    model: {
      value: _vm.filter.type,
      callback: function callback($$v) {
        _vm.$set(_vm.filter, "type", $$v);
      },
      expression: "filter.type"
    }
  }, [_vm._v("Type")])], 1), _vm._v(" "), _c("el-dropdown-item", {
    attrs: {
      command: "Priority"
    }
  }, [_c("el-checkbox", {
    model: {
      value: _vm.filter.priority,
      callback: function callback($$v) {
        _vm.$set(_vm.filter, "priority", $$v);
      },
      expression: "filter.priority"
    }
  }, [_vm._v("Priority")])], 1), _vm._v(" "), _c("el-dropdown-item", {
    attrs: {
      command: "Unmapped"
    }
  }, [_c("el-checkbox", {
    model: {
      value: _vm.filter.unmapped,
      callback: function callback($$v) {
        _vm.$set(_vm.filter, "unmapped", $$v);
      },
      expression: "filter.unmapped"
    }
  }, [_vm._v("Unmapped")])], 1), _vm._v(" "), _c("el-dropdown-item", {
    attrs: {
      command: "Enabler"
    }
  }, [_c("el-checkbox", {
    model: {
      value: _vm.filter.enabler,
      callback: function callback($$v) {
        _vm.$set(_vm.filter, "enabler", $$v);
      },
      expression: "filter.enabler"
    }
  }, [_vm._v("Enabler")])], 1), _vm._v(" "), _c("el-dropdown-item", {
    attrs: {
      command: "External projects"
    }
  }, [_c("el-checkbox", {
    model: {
      value: _vm.filter.external_projects,
      callback: function callback($$v) {
        _vm.$set(_vm.filter, "external_projects", $$v);
      },
      expression: "filter.external_projects"
    }
  }, [_vm._v("External projects")])], 1), _vm._v(" "), _c("el-dropdown-item", {
    attrs: {
      command: "External tickets"
    }
  }, [_c("el-checkbox", {
    model: {
      value: _vm.filter.external_tickets,
      callback: function callback($$v) {
        _vm.$set(_vm.filter, "external_tickets", $$v);
      },
      expression: "filter.external_tickets"
    }
  }, [_vm._v("External tickets")])], 1), _vm._v(" "), _c("el-dropdown-item", {
    attrs: {
      command: "External status"
    }
  }, [_c("el-checkbox", {
    model: {
      value: _vm.filter.external_statuses,
      callback: function callback($$v) {
        _vm.$set(_vm.filter, "external_statuses", $$v);
      },
      expression: "filter.external_statuses"
    }
  }, [_vm._v("External status")])], 1)], 1)], 1)], 1)], 1), _vm._v(" "), _c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.filteredTickets && _vm.filteredTickets.length > 0 ? _c("el-table", {
    staticClass: "value_priority_tables ml-10",
    attrs: {
      data: _vm.filteredTickets,
      "row-key": "id",
      stripe: "",
      size: "micro"
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "expand"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.child_tickets && scope.row.child_tickets.length > 0 ? _c("el-table", {
          attrs: {
            data: scope.row.child_tickets,
            "row-key": "id",
            stripe: "",
            size: "micro"
          }
        }, [_c("el-table-column", {
          staticStyle: {
            "vertical-align": "top"
          },
          attrs: {
            label: "Title",
            prop: "title",
            sortable: ""
          }
        }), _vm._v(" "), _c("el-table-column", {
          staticStyle: {
            "vertical-align": "top"
          },
          attrs: {
            label: "Type",
            prop: "type",
            sortable: ""
          }
        }), _vm._v(" "), _c("el-table-column", {
          staticStyle: {
            "vertical-align": "top"
          },
          attrs: {
            label: "Status",
            prop: "status",
            sortable: ""
          }
        }), _vm._v(" "), _c("el-table-column", {
          staticStyle: {
            "vertical-align": "top"
          },
          attrs: {
            label: "External ID",
            prop: "externalid",
            sortable: ""
          }
        })], 1) : _vm._e(), _vm._v(" "), scope.row.child_tickets.length === 0 ? _c("el-empty", {
          attrs: {
            description: "No Stories found"
          }
        }) : _vm._e()];
      }
    }], null, false, 1966699486)
  }), _vm._v(" "), _c("el-table-column", {
    staticStyle: {
      "vertical-align": "top"
    },
    attrs: {
      label: "Product/Service",
      prop: "parent",
      sortable: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.__typename === "ProductFeature" ? _c("el-tag", {
          attrs: {
            size: "mini"
          }
        }, [_c("svg-icon", {
          staticStyle: {
            "font-size": "10px",
            "font-weight": "600"
          },
          attrs: {
            "icon-class": "pdg-product"
          }
        }), _vm._v(" " + _vm._s(scope.row.parent))], 1) : _vm._e(), _vm._v(" "), scope.row.__typename === "ServiceApplication" ? _c("el-tag", {
          attrs: {
            size: "mini"
          }
        }, [_c("svg-icon", {
          staticStyle: {
            "font-size": "10px",
            "font-weight": "600"
          },
          attrs: {
            "icon-class": "pdg-service"
          }
        }), _vm._v(" " + _vm._s(scope.row.parent))], 1) : _vm._e()];
      }
    }], null, false, 1918733214)
  }), _vm._v(" "), _vm.filter.feature_process ? _c("el-table-column", {
    staticStyle: {
      "vertical-align": "top"
    },
    attrs: {
      label: "Feature/Process",
      prop: "title",
      sortable: ""
    }
  }) : _vm._e(), _vm._v(" "), _vm.filter.type ? _c("el-table-column", {
    staticStyle: {
      "vertical-align": "top"
    },
    attrs: {
      label: "Type",
      sortable: "",
      width: "100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.__typename === "ProductFeature" ? _c("el-tag", {
          attrs: {
            size: "mini"
          }
        }, [_vm._v("Feature")]) : _vm._e(), _vm._v(" "), scope.row.__typename === "ServiceApplication" ? _c("el-tag", {
          attrs: {
            size: "mini"
          }
        }, [_vm._v("Process")]) : _vm._e()];
      }
    }], null, false, 2136958519)
  }) : _vm._e(), _vm._v(" "), _vm.filter.status ? _c("el-table-column", {
    staticStyle: {
      "vertical-align": "top"
    },
    attrs: {
      label: "Status",
      sortable: "",
      width: "100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-tag", {
          attrs: {
            size: "mini"
          }
        }, [_vm._v(_vm._s(scope.row.status))])];
      }
    }], null, false, 811231746)
  }) : _vm._e(), _vm._v(" "), _vm.filter.priority ? _c("el-table-column", {
    staticStyle: {
      "vertical-align": "top"
    },
    attrs: {
      label: "Priority",
      prop: "priority",
      sortable: "",
      width: "100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-tag", {
          attrs: {
            size: "mini"
          }
        }, [_vm._v(_vm._s(scope.row.priority))])];
      }
    }], null, false, 555127428)
  }) : _vm._e(), _vm._v(" "), _vm.filter.unmapped ? _c("el-table-column", {
    staticStyle: {
      "vertical-align": "top"
    },
    attrs: {
      label: "Unmapped",
      prop: "unmapped",
      sortable: "",
      width: "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.unmapped ? _c("div", [_c("i", {
          staticClass: "el-icon-check"
        })]) : _c("div", [_c("i", {
          staticClass: "el-icon-close"
        })])];
      }
    }], null, false, 4257204922)
  }) : _vm._e(), _vm._v(" "), _vm.filter.enabler ? _c("el-table-column", {
    staticStyle: {
      "vertical-align": "top"
    },
    attrs: {
      label: "Enabler",
      prop: "enabler",
      sortable: "",
      width: "100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.enabler ? _c("div", [_c("i", {
          staticClass: "el-icon-check"
        })]) : _c("div", [_c("i", {
          staticClass: "el-icon-close"
        })])];
      }
    }], null, false, 607492351)
  }) : _vm._e(), _vm._v(" "), _vm.filter.external_statuses ? _c("el-table-column", {
    staticStyle: {
      "vertical-align": "top"
    },
    attrs: {
      label: "Ext Status",
      prop: "externalStatus",
      sortable: "",
      width: "120"
    }
  }) : _vm._e(), _vm._v(" "), _vm.filter.external_tickets ? _c("el-table-column", {
    staticStyle: {
      "vertical-align": "top"
    },
    attrs: {
      label: "Ext Ticket",
      prop: "externalId",
      sortable: ""
    }
  }) : _vm._e(), _vm._v(" "), _vm.filter.external_projects ? _c("el-table-column", {
    staticStyle: {
      "vertical-align": "top"
    },
    attrs: {
      label: "Ext Project",
      prop: "externalProjectName",
      sortable: ""
    }
  }) : _vm._e()], 1) : _vm._e(), _vm._v(" "), _vm.filteredTickets && _vm.filteredTickets.length === 0 ? _c("el-empty") : _vm._e()], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Filter List",
      visible: _vm.filterDialog,
      width: "40%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.filterDialog = $event;
      }
    }
  }, [_c("table", {
    staticStyle: {
      "table-layout": "fixed",
      width: "100%"
    }
  }, [_c("colgroup", [_c("col", {
    staticStyle: {
      width: "20%"
    },
    attrs: {
      span: "1"
    }
  }), _vm._v(" "), _c("col", {
    staticStyle: {
      width: "80%"
    },
    attrs: {
      span: "1"
    }
  })]), _vm._v(" "), _c("tr", [_c("th", {
    staticClass: "text-left"
  }, [_vm._v("Title")]), _vm._v(" "), _c("td", {
    staticClass: "flex"
  }, [_c("el-select", {
    staticStyle: {
      width: "150px"
    },
    attrs: {
      clearable: "",
      placeholder: "Select",
      size: "mini"
    },
    model: {
      value: _vm.search_tickets.filters.title_operator,
      callback: function callback($$v) {
        _vm.$set(_vm.search_tickets.filters, "title_operator", $$v);
      },
      expression: "search_tickets.filters.title_operator"
    }
  }, [_c("el-option", {
    attrs: {
      label: "EXACT",
      value: "EXACT"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "LIKE",
      value: "LIKE"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "NOT LIKE",
      value: "NOT LIKE"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "EMPTY",
      value: "EMPTY"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "NOT EMPTY",
      value: "NOT EMPTY"
    }
  })], 1), _vm._v(" "), _c("el-input", {
    staticClass: "ml-5",
    attrs: {
      type: "text",
      size: "mini"
    },
    model: {
      value: _vm.search_tickets.filters.title,
      callback: function callback($$v) {
        _vm.$set(_vm.search_tickets.filters, "title", $$v);
      },
      expression: "search_tickets.filters.title"
    }
  })], 1)]), _vm._v(" "), _c("tr", [_c("th", {
    staticClass: "text-left"
  }, [_vm._v("Description")]), _vm._v(" "), _c("td", {
    staticClass: "flex"
  }, [_c("el-select", {
    staticStyle: {
      width: "150px"
    },
    attrs: {
      clearable: "",
      placeholder: "Select",
      size: "mini"
    },
    model: {
      value: _vm.search_tickets.filters.description_operator,
      callback: function callback($$v) {
        _vm.$set(_vm.search_tickets.filters, "description_operator", $$v);
      },
      expression: "search_tickets.filters.description_operator"
    }
  }, [_c("el-option", {
    attrs: {
      label: "EXACT",
      value: "EXACT"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "LIKE",
      value: "LIKE"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "NOT LIKE",
      value: "NOT LIKE"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "EMPTY",
      value: "EMPTY"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "NOT EMPTY",
      value: "NOT EMPTY"
    }
  })], 1), _vm._v(" "), _c("el-input", {
    staticClass: "ml-5",
    attrs: {
      type: "text",
      size: "mini"
    },
    model: {
      value: _vm.search_tickets.filters.description,
      callback: function callback($$v) {
        _vm.$set(_vm.search_tickets.filters, "description", $$v);
      },
      expression: "search_tickets.filters.description"
    }
  })], 1)]), _vm._v(" "), _c("tr", [_c("th", {
    staticClass: "text-left"
  }, [_vm._v("Parent")]), _vm._v(" "), _c("td", [_c("el-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      clearable: "",
      placeholder: "Select",
      size: "mini",
      multiple: ""
    },
    model: {
      value: _vm.search_tickets.filters.parent,
      callback: function callback($$v) {
        _vm.$set(_vm.search_tickets.filters, "parent", $$v);
      },
      expression: "search_tickets.filters.parent"
    }
  }, [_c("el-option", {
    attrs: {
      label: "Product",
      value: "Product"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Service",
      value: "Service"
    }
  })], 1)], 1)]), _vm._v(" "), _c("tr", [_c("th", {
    staticClass: "text-left"
  }, [_vm._v("Type")]), _vm._v(" "), _c("td", [_c("el-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      clearable: "",
      placeholder: "Select",
      size: "mini",
      multiple: ""
    },
    model: {
      value: _vm.search_tickets.filters.type,
      callback: function callback($$v) {
        _vm.$set(_vm.search_tickets.filters, "type", $$v);
      },
      expression: "search_tickets.filters.type"
    }
  }, [_c("el-option", {
    attrs: {
      label: "Feature",
      value: "Feature"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Process",
      value: "Process"
    }
  })], 1)], 1)]), _vm._v(" "), _c("tr"), _c("tr", [_c("th", {
    staticClass: "text-left"
  }, [_vm._v("Status")]), _vm._v(" "), _c("td", [_c("el-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      clearable: "",
      placeholder: "Select",
      size: "mini",
      multiple: ""
    },
    model: {
      value: _vm.search_tickets.filters.status,
      callback: function callback($$v) {
        _vm.$set(_vm.search_tickets.filters, "status", $$v);
      },
      expression: "search_tickets.filters.status"
    }
  }, [_c("el-option", {
    attrs: {
      label: "Proposed",
      value: "Proposed"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Accepted",
      value: "Accepted"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "In progress",
      value: "In progress"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "On hold",
      value: "On hold"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Overdue",
      value: "Overdue"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Achieved",
      value: "Achieved"
    }
  })], 1)], 1)]), _vm._v(" "), _c("tr", [_c("th", {
    staticClass: "text-left"
  }, [_vm._v("Priority")]), _vm._v(" "), _c("td", [_c("el-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      clearable: "",
      placeholder: "Select",
      size: "mini",
      multiple: ""
    },
    model: {
      value: _vm.search_tickets.filters.priority,
      callback: function callback($$v) {
        _vm.$set(_vm.search_tickets.filters, "priority", $$v);
      },
      expression: "search_tickets.filters.priority"
    }
  }, [_c("el-option", {
    attrs: {
      label: "Highest",
      value: "Highest"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "High",
      value: "High"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Medium",
      value: "Medium"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Low",
      value: "Low"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Lowest",
      value: "Lowest"
    }
  })], 1)], 1)])]), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.filterTicketList
    }
  }, [_vm._v("Filter")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: _vm.closeFilterDialog
    }
  }, [_vm._v("Cancel")])], 1)])], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;