"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("g", {
    on: {
      mouseover: _vm.onMouseOver,
      mouseleave: _vm.onMouseLeave
    }
  }, [_c("defs", [_c("linearGradient", {
    attrs: {
      id: "lane_title_fill_gradient",
      x1: "0%",
      y1: "0%",
      x2: "100%",
      y2: "0%"
    }
  }, [_c("stop", {
    attrs: {
      offset: "0%",
      "stop-color": "#447799"
    }
  }), _vm._v(" "), _c("stop", {
    attrs: {
      offset: "50%",
      "stop-color": "#224488"
    }
  }), _vm._v(" "), _c("stop", {
    attrs: {
      offset: "100%",
      "stop-color": "#112266"
    }
  })], 1)], 1), _vm._v(" "), _c("rect", {
    attrs: {
      x: _vm.x,
      y: _vm.y,
      rx: "10px",
      ry: "10px",
      width: "50",
      height: _vm.height,
      fill: "url(#lane_title_fill_gradient)"
    },
    on: {
      click: _vm.emitClick
    }
  }), _vm._v(" "), _c("text", {
    attrs: {
      x: 20,
      y: _vm.y + _vm.height / 2,
      "font-size": "1.0em",
      transform: _vm.generateTransform(20, _vm.y + _vm.height / 2),
      fill: _vm.track.color,
      "font-weight": "bold",
      "text-anchor": "middle"
    }
  }, [_vm._v(_vm._s(_vm.track.name))]), _vm._v(" "), _c("text", {
    attrs: {
      x: 35,
      y: _vm.y + _vm.height / 2,
      "font-size": "0.7em",
      transform: _vm.generateTransform(35, _vm.y + _vm.height / 2),
      fill: _vm.track.color,
      "font-weight": "lighter",
      "text-anchor": "middle"
    }
  }, [_vm._v(_vm._s(_vm.track.description))]), _vm._v(" "), _c("rect", {
    attrs: {
      x: _vm.x + 50,
      y: _vm.y,
      width: "1250",
      height: _vm.height,
      fill: _vm.computeFill(),
      "fill-opacity": _vm.index % 2 === 0 ? 0.3 : 0.1
    }
  }), _vm._v(" "), _vm.isHover ? _c("g", {
    attrs: {
      refs: "delete_icon"
    },
    on: {
      click: function click($event) {
        $event.preventDefault();
        return _vm.removeTrack.apply(null, arguments);
      }
    }
  }, [_c("foreignObject", {
    attrs: {
      x: _vm.x + 17,
      y: _vm.y + 10,
      width: 15,
      height: 15
    }
  }, [_c("div", {
    staticClass: "text-center"
  }, [_c("svg-icon", {
    staticStyle: {
      "'background-color": "red",
      width: "15px",
      height: "15px"
    },
    attrs: {
      "icon-class": "gradient-circle-error"
    }
  })], 1)])]) : _vm._e()]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;