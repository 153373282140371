"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _axios = _interopRequireDefault(require("axios"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _default = exports.default = {
  name: 'Roles',
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      loading: false,
      createRoleDialog: false,
      editRoleDialog: false,
      addPermissionDialog: false,
      activeRole: {},
      activeRoleId: '',
      searchRole: '',
      roles: [],
      permissions: [],
      permission_form: {
        permissions: []
      },
      listQuery: {
        page: 1,
        limit: 30
      },
      total: 0,
      role_form: {
        title: '',
        description: '',
        permissions: []
      }
    };
  },
  computed: {
    filteredRoleList: function filteredRoleList() {
      var self = this;
      return this.roles.filter(function (role) {
        return role.title.indexOf(self.searchRole) > -1 && !role.isDeleted;
      });
    }
  },
  created: function created() {},
  mounted: function mounted() {
    this.getRoleList({
      page: 1,
      limit: 30
    });
    this.getPermissionList();
  },
  methods: {
    generationPermissionData: function generationPermissionData(permissions) {
      var permission_list = [];
      for (var i = 0; i < permissions.length; i++) {
        permission_list.push({
          id: permissions[i]
        });
      }
      return permission_list;
    },
    linkPermissions: function linkPermissions() {
      var _this = this;
      this.loading = true;
      var headers = {
        'Content-Type': 'application/json'
      };
      var body = {
        'data': this.generationPermissionData(this.permission_form.permissions),
        'header': {
          'userId': this.$store.getters.id,
          'partyId': this.$store.getters.partyId
        }
      };
      _axios.default.patch(process.env.VUE_APP_REST_API_ENDPOINT + 'security/role/' + this.activeRoleId + '/permissions', body, {
        headers: headers
      }).then(function (response) {
        _this.loading = false;
        if (response.data.status === 'SUCCESS') {
          _this.$notify({
            title: 'Success',
            message: 'Permissions Linked',
            type: 'success',
            duration: 2000
          });
          _this.getRoleList({
            page: 1,
            limit: 30
          });
          location.reload();
          _this.addPermissionDialog = false;
        } else {
          _this.$notify({
            title: 'Error',
            message: response.data.data.description,
            type: 'error',
            duration: 2000
          });
        }
      }).catch(function (error) {
        _this.loading = false;
        _this.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
      });
    },
    openAddPermissionDialog: function openAddPermissionDialog(role_id, role, index) {
      this.activeRoleId = role_id;
      this.permission_form.permissions = [];
      this.addPermissionDialog = true;
    },
    createRole: function createRole() {
      var _this2 = this;
      this.loading = true;
      var headers = {
        'Content-Type': 'application/json'
      };
      var body = {
        'data': [{
          'title': this.role_form.title,
          'description': this.role_form.description
        }],
        'header': {
          'userId': this.$store.getters.id,
          'partyId': this.$store.getters.partyId
        }
      };
      _axios.default.post(process.env.VUE_APP_REST_API_ENDPOINT + 'security/role', body, {
        headers: headers
      }).then(function (response) {
        _this2.loading = false;
        _this2.response = response.data;
        if (response.data.status === 'SUCCESS') {
          _this2.$notify({
            title: 'Success',
            message: 'Role Created',
            type: 'success',
            duration: 2000
          });
          _this2.attachPermissionToRole(response.data.data.id);
        } else {
          _this2.$notify({
            title: 'Error',
            message: response.data.data.description,
            type: 'error',
            duration: 2000
          });
        }
      }).catch(function (error) {
        _this2.loading = false;
        _this2.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
      });
      this.createRoleDialog = false;
    },
    attachPermissionToRole: function attachPermissionToRole(role_id) {
      var _this3 = this;
      this.activeRoleId = role_id;
      this.loading = true;
      var headers = {
        'Content-Type': 'application/json'
      };
      var body = {
        'data': this.generationPermissionData(this.role_form.permissions),
        'header': {
          'userId': this.$store.getters.id,
          'partyId': this.$store.getters.partyId
        }
      };
      _axios.default.patch(process.env.VUE_APP_REST_API_ENDPOINT + 'security/role/' + this.activeRoleId + '/permissions', body, {
        headers: headers
      }).then(function (response) {
        _this3.loading = false;
        if (response.data.status === 'SUCCESS') {
          _this3.$notify({
            title: 'Success',
            message: 'Permissions Linked',
            type: 'success',
            duration: 2000
          });
          _this3.getRoleList({
            page: 1,
            limit: 30
          });
          _this3.resetRoleForm();
        } else {
          _this3.$notify({
            title: 'Error',
            message: response.data.data.description,
            type: 'error',
            duration: 2000
          });
        }
      }).catch(function (error) {
        _this3.loading = false;
        _this3.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
      });
    },
    updateRole: function updateRole() {
      var _this4 = this;
      this.loading = true;
      var headers = {
        'Content-Type': 'application/json'
      };
      var body = {
        'data': {
          'title': this.role_form.title,
          'description': this.role_form.description
        },
        'header': {
          'userId': this.$store.getters.id,
          'partyId': this.$store.getters.partyId
        }
      };
      _axios.default.patch(process.env.VUE_APP_REST_API_ENDPOINT + 'security/role/' + this.activeRole.id, body, {
        headers: headers
      }).then(function (response) {
        _this4.loading = false;
        if (response.data.status === 'SUCCESS') {
          _this4.$notify({
            title: 'Success',
            message: 'Role Updated',
            type: 'success',
            duration: 2000
          });
          _this4.getRoleList({
            page: 1,
            limit: 30
          });
          _this4.editRoleDialog = false;
        } else {
          _this4.$notify({
            title: 'Error',
            message: response.data.data.description,
            type: 'error',
            duration: 2000
          });
        }
      }).catch(function (error) {
        _this4.loading = false;
        _this4.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
      });
    },
    editRole: function editRole(role) {
      this.editRoleDialog = true;
      this.activeRole = role;
      this.role_form.title = role.title;
      this.role_form.description = role.description;
    },
    deletePermission: function deletePermission(role_id, permission_id) {
      this.activeRoleId = role_id;
      this.loading = true;
      var body = {
        'header': {
          'userId': this.$store.getters.id,
          'partyId': this.$store.getters.partyId
        },
        'data': [{
          'id': permission_id
        }]
      };
      var that = this;
      var xhr = new XMLHttpRequest();
      xhr.open('DELETE', process.env.VUE_APP_REST_API_ENDPOINT + 'security/role/' + this.activeRoleId + '/permissions', true);

      // Send the proper header information along with the request
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.send(JSON.stringify(body));
      xhr.onreadystatechange = function () {
        // Call a function when the state changes.
        if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
          // Request finished. Do processing here.
          that.$notify({
            title: 'Success',
            message: 'Permission deleted succesfully',
            type: 'success',
            duration: 2000
          });
          that.getRoleList({
            page: 1,
            limit: 30
          });
          that.getPermissionList();
          location.reload();
          that.loading = false;
        }
      };
      xhr.onload = function () {};
    },
    deleteRole: function deleteRole(role_id) {
      this.loading = true;
      var body = {
        'header': {
          'userId': this.$store.getters.id,
          'partyId': this.$store.getters.partyId
        }
      };
      var that = this;
      var xhr = new XMLHttpRequest();
      xhr.open('DELETE', process.env.VUE_APP_REST_API_ENDPOINT + 'security/role/' + role_id, true);

      // Send the proper header information along with the request
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.send(JSON.stringify(body));
      xhr.onreadystatechange = function () {
        // Call a function when the state changes.
        if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
          // Request finished. Do processing here.
          that.$notify({
            title: 'Success',
            message: 'Role deleted succesfully',
            type: 'success',
            duration: 2000
          });
          that.getRoleList({
            page: 1,
            limit: 30
          });
          that.loading = false;
        }
      };
      xhr.onload = function () {};
    },
    getPermissionList: function getPermissionList() {
      var _this5 = this;
      this.loading = true;
      var headers = {
        'Content-Type': 'application/json',
        'x-party-id': this.$store.getters.partyId,
        'x-user-id': this.$store.getters.id
      };
      _axios.default.get(process.env.VUE_APP_REST_API_ENDPOINT + 'security/permission', {
        headers: headers
      }).then(function (response) {
        _this5.loading = false;
        if (response.data.status === 'SUCCESS') {
          _this5.permissions = response.data.data;
        } else {
          _this5.$notify({
            title: 'Error',
            message: response.data.data.description,
            type: 'error',
            duration: 2000
          });
        }
      }).catch(function (error) {
        _this5.loading = false;
        _this5.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
      });
    },
    getRoleList: function getRoleList(pagination) {
      var _this6 = this;
      var temp_pagination = Object.assign({}, pagination);
      var page_no = parseInt(temp_pagination.page) - 1;
      this.roles = [];
      this.loading = true;
      var headers = {
        'Content-Type': 'application/json',
        'x-party-id': this.$store.getters.partyId,
        'x-user-id': this.$store.getters.id
      };
      _axios.default.get(process.env.VUE_APP_REST_API_ENDPOINT + 'security/role?page=' + page_no, {
        headers: headers
      }).then(function (response) {
        _this6.loading = false;
        if (response.data.status === 'SUCCESS') {
          _this6.total = response.data.pageInfo.totalPages * response.data.pageInfo.itemsPerPage;
          _this6.roles = response.data.data;
        } else {
          _this6.$notify({
            title: 'Error',
            message: response.data.data.description,
            type: 'error',
            duration: 2000
          });
        }
      }).catch(function (error) {
        _this6.loading = false;
        _this6.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
      });
    },
    resetRoleForm: function resetRoleForm() {
      this.role_form = {
        title: '',
        description: '',
        permissions: []
      };
    },
    resetPermissionForm: function resetPermissionForm() {
      this.permission_form = {
        permissions: []
      };
    }
  }
};