"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _echarts = _interopRequireDefault(require("echarts"));
var _utils = require("@/utils");
require('echarts/theme/macarons'); // echarts theme
var _default = exports.default = {
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '300px'
    },
    completed: {
      type: Number,
      default: 0
    },
    pending: {
      type: Number,
      default: 0
    }
  },
  data: function data() {
    return {
      chart: null
    };
  },
  mounted: function mounted() {
    var _this = this;
    this.initChart();
    this.__resizeHandler = (0, _utils.debounce)(function () {
      if (_this.chart) {
        _this.chart.resize();
      }
    }, 100);
    window.addEventListener('resize', this.__resizeHandler);
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    window.removeEventListener('resize', this.__resizeHandler);
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart: function initChart() {
      this.chart = _echarts.default.init(this.$el, 'macarons');
      this.chart.setOption({
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
          left: 'center',
          bottom: '10',
          data: ['Completed', 'Pending']
        },
        calculable: true,
        series: [{
          name: 'Progress',
          type: 'pie',
          data: [{
            value: this.completed,
            name: 'Completed'
          }, {
            value: this.pending,
            name: 'Pending'
          }],
          animationEasing: 'cubicInOut',
          animationDuration: 2600
        }]
      });
    }
  }
};