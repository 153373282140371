"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("g", [_c("rect", {
    staticStyle: {
      fill: "whitesmoke",
      stroke: "black",
      "stroke-width": "1",
      opacity: "0.5"
    },
    attrs: {
      x: parseInt(_vm.cx) + 210,
      y: parseInt(_vm.cy) - 75,
      rx: "2",
      ry: "2",
      width: "110",
      height: "145"
    }
  }), _vm._v(" "), _c("text", {
    attrs: {
      x: parseInt(_vm.cx) + 265,
      y: parseInt(_vm.cy) - 62,
      "font-family": "Verdana",
      "font-size": "10",
      fill: "gray",
      "text-anchor": "middle",
      "font-weight": "bold"
    }
  }, [_vm._v("Stories")]), _vm._v(" "), _c("line", {
    attrs: {
      x1: parseInt(_vm.cx) + 210,
      y1: parseInt(_vm.cy) - 55,
      x2: parseInt(_vm.cx) + 320,
      y2: parseInt(_vm.cy) - 55,
      stroke: "gray",
      "stroke-width": "1"
    }
  }), _vm._v(" "), _c("text", {
    attrs: {
      x: parseInt(_vm.cx) + 265,
      y: parseInt(_vm.cy) - 42,
      "font-family": "Verdana",
      "font-size": "10",
      fill: "gray",
      "text-anchor": "middle"
    },
    on: {
      click: function click($event) {
        return _vm.selectStory("Story 1");
      }
    }
  }, [_vm._v("Story 1")]), _vm._v(" "), _c("line", {
    attrs: {
      x1: parseInt(_vm.cx) + 210,
      y1: parseInt(_vm.cy) - 35,
      x2: parseInt(_vm.cx) + 320,
      y2: parseInt(_vm.cy) - 35,
      stroke: "gray",
      "stroke-width": "1"
    }
  })]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;