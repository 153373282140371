"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _default2 = exports.default = {
  name: 'Pain',
  components: {},
  props: {
    x: {
      type: Number,
      default: 0
    },
    y: {
      type: Number,
      default: 0
    },
    index: {
      type: Number,
      default: 0
    },
    label: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    priority: {
      type: String,
      default: 'Low'
    },
    value: {
      type: String,
      default: 'Low'
    },
    showLabel: {
      type: Boolean,
      default: false
    },
    isStageRelation: {
      type: Boolean,
      default: false
    },
    isMinimized: {
      type: Boolean,
      default: false
    },
    toolbar: {
      type: Boolean,
      default: false
    },
    create: {
      type: Boolean,
      default: false
    },
    highlight: {
      type: String,
      default: null
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    isParentActive: {
      type: Boolean,
      default: false
    },
    relations: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      isHover: false
    };
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    computeOpacityStyle: function computeOpacityStyle() {
      if (this.isParentActive) {
        return 'opacity:15%;';
      }
      return 'opacity:100%;';
    },
    computePriority: function computePriority() {
      switch (this.priority) {
        case 'Very Low':
          return '1';
        case 'Low':
          return '2';
        case 'Medium':
          return '3';
        case 'High':
          return '4';
        case 'Very High':
          return '5';
      }
    },
    computeCustomerValueIcon: function computeCustomerValueIcon() {
      switch (this.priority) {
        case 'Very Low':
          return 'dollar';
        case 'Low':
          return 'dollar';
        case 'Medium':
          return 'dollar-2';
        case 'High':
          return 'dollar-2';
        case 'Very High':
          return 'dollar-3';
      }
    },
    computeStyle: function computeStyle() {
      if (this.isHover && !this.toolbar) {
        return 'font-size: 16px;color:red;background-color: red;border-radius:10px; box-shadow: 0 0 2px 1px white';
      } else {
        return 'font-size: 16px;color:red;background-color: red;border-radius:10px; box-shadow: 0 0 2px 1px white';
      }
    },
    generatePath: function generatePath() {
      var start_x = this.x;
      var start_y = this.y + 4;
      var point2_x = start_x + 106 + 0;
      var point3_x = start_x + 106 + 7;
      var point6_x = start_x + 7;
      var point3_y = this.y + 4 + 13;
      var point4_y = this.y + 4 + 26;
      return start_x + ',' + start_y + ' ' + point2_x + ',' + start_y + ' ' + point3_x + ',' + point3_y + ' ' + point2_x + ',' + point4_y + ' ' + start_x + ',' + point4_y + ' ' + point6_x + ',' + point3_y;
    },
    onMouseOver: function onMouseOver() {
      this.isHover = true;
      this.$emit('mouseover', this.x, this.y, this.label, this.relations, this.index);
    },
    onMouseLeave: function onMouseLeave() {
      this.isHover = false;
      this.$emit('mouseleave');
    },
    emitClick: function emitClick() {
      this.$emit('click');
    },
    onSubmit: function onSubmit() {},
    generateUniqueID: function generateUniqueID() {
      return 'pain_' + this.index;
    },
    onDrag: function onDrag() {
      this.$emit('on-drag');
    },
    truncateString: function truncateString(string, length) {
      return this._.truncate(string, {
        'length': length,
        'separator': '...'
      });
    }
  }
};