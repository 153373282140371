"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_vm.hasRoadmap ? _c("div", [_vm.filteredEntities.length > 0 ? _c("el-row", {
    style: _vm.computeHeaderStyle,
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "micro",
      icon: "el-icon-refresh-left"
    },
    on: {
      click: function click($event) {
        return _vm.resetFilter();
      }
    }
  }, [_vm._v("Reset filter")]), _vm._v(" "), !_vm.allLanesExpanded ? _c("el-button", {
    attrs: {
      type: "primary",
      size: "micro"
    },
    on: {
      click: function click($event) {
        return _vm.toggleAllLanes("expand");
      }
    }
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "1.3em",
      "padding-top": "0pt",
      "padding-right": "4pt",
      color: "white"
    },
    attrs: {
      "icon-class": "arrow-expand-w",
      title: "Shrink Lane"
    }
  }), _vm._v("Expand All")], 1) : _c("el-button", {
    attrs: {
      type: "primary",
      size: "micro"
    },
    on: {
      click: function click($event) {
        return _vm.toggleAllLanes("collapse");
      }
    }
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "1.3em",
      "padding-top": "0pt",
      "padding-right": "4pt",
      color: "white"
    },
    attrs: {
      "icon-class": "arrow-shrink-w",
      title: "Shrink Lane"
    }
  }), _vm._v("Collapse All")], 1)], 1), _vm._v(" "), _c("el-col", {
    staticClass: "text-center",
    attrs: {
      span: 3
    }
  }, [_c("el-dropdown", {
    attrs: {
      size: "mini",
      "split-button": "",
      type: "primary"
    },
    on: {
      command: _vm.handleTimelineDropdownSelection
    }
  }, [_vm._v("\n          Timeline - " + _vm._s(_vm.timeFrameMarks[_vm.timeFrame]) + "\n          "), _c("el-dropdown-menu", {
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, _vm._l(_vm.timeFrameMarks, function (year, index) {
    return _c("el-dropdown-item", {
      key: index,
      staticClass: "text-center",
      attrs: {
        command: index
      }
    }, [_vm._v(_vm._s(year))]);
  }), 1)], 1)], 1), _vm._v(" "), _c("el-col", {
    staticClass: "text-right",
    attrs: {
      span: 6
    }
  }, [_c("el-slider", {
    staticClass: "sliderZoomRoadmap",
    attrs: {
      step: 5,
      min: 50,
      max: 200,
      "show-input": "",
      "input-size": "mini"
    },
    on: {
      change: function change($event) {
        return _vm.zoomJourney();
      }
    },
    model: {
      value: _vm.roadmapZoom,
      callback: function callback($$v) {
        _vm.roadmapZoom = $$v;
      },
      expression: "roadmapZoom"
    }
  })], 1), _vm._v(" "), _c("el-col", {
    staticClass: "text-right",
    attrs: {
      span: 6
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.handleDropdownSelection("create_release");
      }
    }
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "create"
    }
  }), _vm._v(" Add Release")], 1), _vm._v(" "), _c("el-dropdown", {
    attrs: {
      size: "mini",
      "split-button": "",
      type: "primary"
    },
    on: {
      command: _vm.handleDropdownSelection
    }
  }, [_c("i", {
    staticClass: "el-icon-setting"
  }), _vm._v(" "), _c("el-dropdown-menu", {
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, [_c("el-dropdown-item", {
    attrs: {
      divided: "",
      command: "settings"
    }
  }, [_c("i", {
    staticClass: "el-icon-setting"
  }), _vm._v("Roadmap Settings")]), _vm._v(" "), _c("el-dropdown-item", {
    attrs: {
      divided: "",
      command: "delete_roadmap"
    }
  }, [_c("i", {
    staticClass: "el-icon-delete"
  }), _vm._v("Delete Roadmap")]), _vm._v(" "), _c("el-dropdown-item", {
    attrs: {
      divided: "",
      command: "refresh_roadmap"
    }
  }, [_c("i", {
    staticClass: "el-icon-refresh"
  }), _vm._v("Refresh Roadmap")]), _vm._v(" "), _c("el-dropdown-item", {
    attrs: {
      command: "reset_timeline"
    }
  }, [_c("i", {
    staticClass: "el-icon-refresh"
  }), _vm._v("Reset Timeline")]), _vm._v(" "), _c("el-dropdown-item", {
    attrs: {
      command: "reset_all_objectives"
    }
  }, [_c("i", {
    staticClass: "el-icon-refresh"
  }), _vm._v("Reset All Objectives")]), _vm._v(" "), _c("el-dropdown-item", {
    attrs: {
      command: "reset_objectives_position"
    }
  }, [_c("i", {
    staticClass: "el-icon-refresh"
  }), _vm._v("Reset Position")])], 1)], 1)], 1)], 1) : _vm._e(), _vm._v(" "), _vm.filteredEntities.length > 0 ? _c("el-row", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "roadmap-container",
    staticStyle: {
      padding: "20px 10px 0px"
    },
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    staticClass: "no-padding",
    attrs: {
      span: 24
    }
  }, [_c("svg", {
    staticClass: "roadmapZoomer",
    staticStyle: {
      "user-select": "none",
      overflow: "auto",
      "margin-top": "22px",
      position: "fixed"
    },
    attrs: {
      id: "roadmap",
      height: 35,
      width: _vm.computeCanvasWidth,
      xmlns: "http://www.w3.org/2000/svg",
      version: "1.1"
    }
  }, [_c("timeline-component", {
    attrs: {
      width: _vm.computeTimlineWidth,
      height: _vm.roadmapSettings.entity.timeline.height,
      "active-timeline": _vm.activeTimeline,
      "product-detail-width-per-year": _vm.computeProductDetailWidthPerYear,
      "next-years": 2,
      "previous-years": 1,
      "pos-x": _vm.timelineX,
      timeframe: _vm.timeFrame
    },
    on: {
      drag: _vm.onTimelineDragged
    }
  }), _vm._v(" "), _c("g", {
    attrs: {
      refs: "roadmap_title"
    }
  }, [_c("rect", {
    attrs: {
      x: 0,
      y: 0,
      width: 215,
      height: 60,
      rx: "2",
      fill: "#eee"
    }
  }), _vm._v(" "), _c("foreignObject", {
    staticClass: "text-left",
    staticStyle: {
      overflow: "hidden",
      "border-radius": "2pt",
      "background-color": "darkslategrey",
      color: "white"
    },
    attrs: {
      x: 0,
      y: 0,
      width: 215,
      height: 60
    }
  }, [_c("el-row", {
    staticClass: "mb-10",
    staticStyle: {
      "border-radius": "2pt"
    },
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 20
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "1.1em",
      "font-weight": "400",
      "font-variant-caps": "small-caps",
      "text-align": "left",
      color: "white",
      "word-wrap": "break-word",
      "margin-top": "7px",
      "margin-left": "10px"
    }
  }, [_vm._v("\n                  " + _vm._s(_vm.truncateString(_vm.roadmap.title, 35)) + "\n                ")]), _vm._v(" "), _c("div", {
    staticStyle: {
      "font-size": "0.85em",
      "font-weight": "200",
      "font-variant-caps": "small-caps",
      "text-align": "left",
      color: "white",
      "word-wrap": "break-word",
      "margin-top": "1px",
      "margin-left": "10px"
    }
  }, [_vm._v("\n                  " + _vm._s(_vm.truncateString(_vm.roadmap.description, 40)) + "\n                ")])]), _vm._v(" "), _c("el-col", {
    staticStyle: {
      "margin-top": "7px"
    },
    attrs: {
      span: 4
    }
  }, [!_vm.loading ? _c("i", {
    staticClass: "el-icon-refresh",
    on: {
      click: function click($event) {
        return _vm.retrieveOfferingRoadmap(1, false);
      }
    }
  }) : _vm._e(), _vm._v(" "), _vm.loading ? _c("i", {
    staticClass: "el-icon-loading"
  }) : _vm._e()])], 1)], 1)])], 1), _vm._v(" "), _c("svg", {
    staticClass: "roadmapZoomer",
    staticStyle: {
      "user-select": "none",
      overflow: "auto",
      "margin-top": "27px"
    },
    attrs: {
      id: "roadmap",
      height: _vm.computeRoadmapHeight() + 100 + "px",
      width: _vm.computeCanvasWidth,
      xmlns: "http://www.w3.org/2000/svg",
      version: "1.1"
    }
  }, [_c("linearGradient", {
    attrs: {
      id: "entityLaneGradient"
    }
  }, [_c("stop", {
    attrs: {
      offset: "0%",
      "stop-color": "#2793b7",
      "stop-opacity": "0.1"
    }
  }), _vm._v(" "), _c("stop", {
    attrs: {
      offset: "20%",
      "stop-color": "#2793b7",
      "stop-opacity": "0.3"
    }
  }), _vm._v(" "), _c("stop", {
    attrs: {
      offset: "50%",
      "stop-color": "#2793b7",
      "stop-opacity": "0.4"
    }
  }), _vm._v(" "), _c("stop", {
    attrs: {
      offset: "100%",
      "stop-color": "#2793b7",
      "stop-opacity": "0.5"
    }
  })], 1), _vm._v(" "), _c("g", {
    attrs: {
      refs: "releases"
    }
  }, [_vm._l(_vm.roadmap.linkedReleases, function (release, index) {
    return _vm.roadmap.linkedReleases.length > 0 ? _c("g", {
      key: index,
      attrs: {
        refs: "release"
      }
    }, [_c("release-component", {
      attrs: {
        x: _vm.computeReleaseX(index),
        y: 30,
        width: _vm.computeReleaseWidth(index),
        height: _vm.currentDayToY(),
        index: index,
        release: release.offeringRoadmapRelease,
        timeframe: _vm.timeFrame
      },
      on: {
        "create-increment": _vm.onIncrementCreate,
        "release-click": _vm.onReleaseSelect,
        drag: _vm.onReleaseDragged,
        "drag-stop": _vm.onReleaseDragStop,
        resize: _vm.onReleaseResize,
        "resize-stop": _vm.onReleaseResizeStop,
        delete: _vm.deleteRelease
      }
    }, _vm._l(release.offeringRoadmapRelease.linkedIncrements, function (increment, sub_index) {
      return _c("g", {
        key: sub_index,
        attrs: {
          refs: "increments"
        }
      }, [_c("increment-component", {
        attrs: {
          x: _vm.computeIncrementX(index, sub_index),
          y: 50,
          width: _vm.computeIncrementWidth(index, sub_index),
          height: _vm.currentDayToY(),
          "parent-index": index,
          index: sub_index,
          increment: increment.offeringRoadmapReleaseIncrement,
          settings: _vm.roadmap.additionalattributes.setting,
          timeframe: _vm.timeFrame
        },
        on: {
          click: _vm.onIncrementSelect,
          delete: _vm.deleteIncrement
        }
      })], 1);
    }), 0)], 1) : _vm._e();
  }), _vm._v(" "), _c("g", {
    attrs: {
      refs: "releases_header"
    }
  }, [_c("rect", {
    attrs: {
      x: 0,
      y: 30,
      width: 215,
      height: _vm.currentDayToY(),
      fill: "#eee"
    }
  })])], 2), _vm._v(" "), _vm._l(_vm.filteredEntities, function (entity, index) {
    return _c("g", {
      key: "entity_" + index,
      attrs: {
        refs: "entity_lane"
      }
    }, [entity.__typename === "LinkProductOffering" ? _c("entity-lane-component", {
      attrs: {
        x: _vm.computeStartX,
        y: _vm.computeEntityY(index),
        width: _vm.computeEntityWidth,
        height: _vm.computeEntityHeight(entity, index),
        "header-width": _vm.computeEntityHeaderWidth,
        entity: entity.product,
        index: index,
        "is-minimized": _vm.selectedMergedEntityListMinimized[index]
      },
      on: {
        click: _vm.onEntitySelect,
        "toggle-lane": _vm.toggleLane,
        "toggle-unmapped": _vm.toggleLaneUnmapped,
        "toggle-mapped": _vm.toggleLaneMapped
      },
      scopedSlots: _vm._u([{
        key: "header",
        fn: function fn() {
          return [_vm._l(_vm.filteredFeatures(entity.product.linkedFeatures, "list", index), function (feature, sub_index) {
            return _c("g", {
              key: "feature_" + sub_index,
              attrs: {
                refs: "feature_header_lane"
              }
            }, [_c("sub-entity-component", {
              attrs: {
                x: _vm.selectedMergedEntityList_pos[index].sub_entities[sub_index].x,
                y: _vm.selectedMergedEntityList_pos[index].sub_entities[sub_index].y,
                width: 90,
                height: 15,
                "entity-index": index,
                index: sub_index,
                objective: feature.productFeature
              },
              on: {
                click: _vm.onSubEntitySelect,
                drag: _vm.onObjectiveDragged,
                "drag-stop": _vm.onObjectiveDragStop
              }
            })], 1);
          }), _vm._v(" "), entity.product.linkedFeatures.length > 10 ? _c("g", {
            attrs: {
              refs: "pagination_inteface"
            }
          }, [_c("rect", {
            attrs: {
              x: 0,
              y: _vm.computeEntityY(index) + _vm.computeEntityHeight(entity, index) - 15,
              width: _vm.computeEntityHeaderWidth,
              height: 15,
              rx: "2",
              fill: "rgba(255,255,255,0.15)"
            }
          }), _vm._v(" "), _vm.selectedMergedEntityListPagination[index].isHover ? _c("g", {
            attrs: {
              refs: "left_pagination"
            }
          }, [_c("rect", {
            attrs: {
              x: _vm.computeEntityHeaderWidth / 2 - 35,
              y: _vm.computeEntityY(index) + _vm.computeEntityHeight(entity, index) - 15,
              width: 10,
              height: 15,
              rx: "2",
              fill: "rgba(255,255,255,0.15)"
            },
            on: {
              click: function click($event) {
                return _vm.prevPage("product", index);
              }
            }
          }), _vm._v(" "), _c("foreignObject", {
            staticClass: "text-center",
            attrs: {
              x: _vm.computeEntityHeaderWidth / 2 - 35,
              y: _vm.computeEntityY(index) + _vm.computeEntityHeight(entity, index) - 14,
              width: 13,
              height: 15
            },
            on: {
              click: function click($event) {
                return _vm.prevPage("product", index);
              }
            }
          }, [_c("i", {
            staticClass: "el-icon-arrow-left",
            staticStyle: {
              "margin-left": "-5px",
              "font-size": "1.0em",
              color: "#666A86"
            }
          })])]) : _vm._e(), _vm._v(" "), _vm.selectedMergedEntityListPagination[index].isHover ? _c("g", {
            attrs: {
              refs: "right_pagination"
            }
          }, [_c("rect", {
            attrs: {
              x: _vm.computeEntityHeaderWidth / 2 + 30,
              y: _vm.computeEntityY(index) + _vm.computeEntityHeight(entity, index) - 15,
              width: 10,
              height: 15,
              rx: "2",
              fill: "rgba(255,255,255,0.15)"
            },
            on: {
              click: function click($event) {
                return _vm.nextPage("product", index);
              }
            }
          }), _vm._v(" "), _c("foreignObject", {
            staticClass: "text-center",
            attrs: {
              x: _vm.computeEntityHeaderWidth / 2 + 30,
              y: _vm.computeEntityY(index) + _vm.computeEntityHeight(entity, index) - 14,
              width: 13,
              height: 15
            },
            on: {
              click: function click($event) {
                return _vm.nextPage("product", index);
              }
            }
          }, [_c("i", {
            staticClass: "el-icon-arrow-right",
            staticStyle: {
              "margin-left": "-5px",
              "font-size": "1.0em",
              color: "#666A86"
            }
          })])]) : _vm._e(), _vm._v(" "), _c("g", {
            attrs: {
              refs: "pagination_text"
            }
          }, [_c("foreignObject", {
            staticClass: "text-center",
            attrs: {
              x: _vm.computeEntityHeaderWidth / 2 - 25,
              y: _vm.computeEntityY(index) + _vm.computeEntityHeight(entity, index) - 17,
              width: 50,
              height: 20
            }
          }, [_c("span", {
            staticStyle: {
              "font-size": "0.7em",
              color: "#666A86"
            }
          }, [_vm._v("Page " + _vm._s(_vm.selectedMergedEntityListPagination[index].currentPage) + "/" + _vm._s(_vm.computeTotalPagesForPagination(entity.product.linkedFeatures.length)))])])])]) : _vm._e()];
        },
        proxy: true
      }], null, true)
    }, [_vm._v(" "), _vm._l(_vm.filteredFeatures(entity.product.linkedFeatures, "roadmap", index), function (feature, sub_index) {
      return _c("g", {
        key: "feature_" + sub_index,
        attrs: {
          refs: "feature_lane"
        }
      }, [_c("roadmap-sub-entity-component", {
        attrs: {
          x: _vm.selectedMergedEntityList_pos[index].roadmap_sub_entities[sub_index].x,
          y: _vm.selectedMergedEntityList_pos[index].roadmap_sub_entities[sub_index].y,
          width: _vm.selectedMergedEntityList_pos[index].roadmap_sub_entities[sub_index].width,
          height: _vm.computeSubEntityHeight(_vm.selectedMergedEntityList_pos[index], index),
          "entity-index": index,
          index: sub_index,
          objective: feature.productFeature,
          "is-minimized": _vm.selectedMergedEntityListMinimized[index]
        },
        on: {
          unlink: _vm.unlinkSubEntityFromRoadmap,
          click: _vm.onSubEntitySelect,
          drag: _vm.onRoadmapSubEntityDrag,
          "drag-start": _vm.onRoadmapSubEntityDragStart,
          "drag-stop": _vm.onRoadmapSubEntityDragStop,
          resize: _vm.onRoadmapSubEntityResize,
          "resize-stop": _vm.onRoadmapSubEntityResizeStop
        }
      }, [_vm._v("\n                // TODO add child entities (e.g. sub_features / inititatives)\n                ")])], 1);
    })], 2) : _vm._e(), _vm._v(" "), entity.__typename === "LinkServiceOffering" ? _c("entity-lane-component", {
      attrs: {
        x: _vm.computeStartX,
        y: _vm.computeEntityY(index),
        width: _vm.computeEntityWidth,
        height: _vm.computeEntityHeight(entity, index),
        "header-width": _vm.computeEntityHeaderWidth,
        entity: entity.service,
        index: index,
        "is-minimized": _vm.selectedMergedEntityListMinimized[index]
      },
      on: {
        "toggle-lane": _vm.toggleLane,
        "toggle-unmapped": _vm.toggleLaneUnmapped,
        "toggle-mapped": _vm.toggleLaneMapped
      },
      scopedSlots: _vm._u([{
        key: "header",
        fn: function fn() {
          return [_vm._l(_vm.filteredServiceApplications(entity.service.linkedServiceApplications, "list", index), function (service_application, sub_index) {
            return _c("g", {
              key: "service_application_" + sub_index,
              attrs: {
                refs: "service_application_header_lane"
              }
            }, [_c("sub-entity-component", {
              attrs: {
                x: _vm.selectedMergedEntityList_pos[index].sub_entities[sub_index].x,
                y: _vm.selectedMergedEntityList_pos[index].sub_entities[sub_index].y,
                width: 90,
                height: 15,
                "entity-index": index,
                index: sub_index,
                objective: service_application.serviceApplication
              },
              on: {
                click: _vm.onSubEntitySelect,
                drag: _vm.onObjectiveDragged,
                "drag-stop": _vm.onObjectiveDragStop
              }
            })], 1);
          }), _vm._v(" "), entity.service.linkedServiceApplications.length > 10 ? _c("g", {
            attrs: {
              refs: "pagination_inteface"
            }
          }, [_c("rect", {
            attrs: {
              x: 0,
              y: _vm.computeEntityY(index) + _vm.computeEntityHeight(entity, index) - 15,
              width: _vm.computeEntityHeaderWidth,
              height: 15,
              rx: "2",
              fill: "rgba(255,255,255,0.15)"
            }
          }), _vm._v(" "), _vm.selectedMergedEntityListPagination[index].isHover ? _c("g", {
            attrs: {
              refs: "left_pagination"
            }
          }, [_c("rect", {
            attrs: {
              x: _vm.computeEntityHeaderWidth / 2 - 35,
              y: _vm.computeEntityY(index) + _vm.computeEntityHeight(entity, index) - 15,
              width: 10,
              height: 15,
              rx: "2",
              fill: "rgba(255,255,255,0.15)"
            },
            on: {
              click: function click($event) {
                return _vm.prevPage("service", index);
              }
            }
          }), _vm._v(" "), _c("foreignObject", {
            staticClass: "text-center",
            attrs: {
              x: _vm.computeEntityHeaderWidth / 2 - 35,
              y: _vm.computeEntityY(index) + _vm.computeEntityHeight(entity, index) - 14,
              width: 13,
              height: 15
            },
            on: {
              click: function click($event) {
                return _vm.prevPage("service", index);
              }
            }
          }, [_c("i", {
            staticClass: "el-icon-arrow-left",
            staticStyle: {
              "margin-left": "-5px",
              "font-size": "1.0em",
              color: "#666A86"
            }
          })])]) : _vm._e(), _vm._v(" "), _vm.selectedMergedEntityListPagination[index].isHover ? _c("g", {
            attrs: {
              refs: "right_pagination"
            }
          }, [_c("rect", {
            attrs: {
              x: _vm.computeEntityHeaderWidth / 2 + 30,
              y: _vm.computeEntityY(index) + _vm.computeEntityHeight(entity, index) - 15,
              width: 10,
              height: 15,
              rx: "2",
              fill: "rgba(255,255,255,0.15)"
            },
            on: {
              click: function click($event) {
                return _vm.nextPage("service", index);
              }
            }
          }), _vm._v(" "), _c("foreignObject", {
            staticClass: "text-center",
            attrs: {
              x: _vm.computeEntityHeaderWidth / 2 + 30,
              y: _vm.computeEntityY(index) + _vm.computeEntityHeight(entity, index) - 14,
              width: 13,
              height: 15
            },
            on: {
              click: function click($event) {
                return _vm.nextPage("service", index);
              }
            }
          }, [_c("i", {
            staticClass: "el-icon-arrow-right",
            staticStyle: {
              "margin-left": "-5px",
              "font-size": "1.0em",
              color: "#666A86"
            }
          })])]) : _vm._e(), _vm._v(" "), _c("g", {
            attrs: {
              refs: "pagination_text"
            }
          }, [_c("foreignObject", {
            staticClass: "text-center",
            attrs: {
              x: _vm.computeEntityHeaderWidth / 2 - 25,
              y: _vm.computeEntityY(index) + _vm.computeEntityHeight(entity, index) - 17,
              width: 50,
              height: 20
            }
          }, [_c("span", {
            staticStyle: {
              "font-size": "0.7em",
              color: "#666A86"
            }
          }, [_vm._v("Page " + _vm._s(_vm.selectedMergedEntityListPagination[index].currentPage) + "/" + _vm._s(_vm.computeTotalPagesForPagination(entity.service.linkedServiceApplications.length)))])])])]) : _vm._e()];
        },
        proxy: true
      }], null, true)
    }, [_vm._v(" "), _vm._l(_vm.filteredServiceApplications(entity.service.linkedServiceApplications, "roadmap", index), function (service_applications, sub_index) {
      return _c("g", {
        key: "service_applications_" + sub_index,
        attrs: {
          refs: "service_application_lane"
        }
      }, [_c("roadmap-sub-entity-component", {
        attrs: {
          x: _vm.selectedMergedEntityList_pos[index].roadmap_sub_entities[sub_index].x,
          y: _vm.selectedMergedEntityList_pos[index].roadmap_sub_entities[sub_index].y,
          width: _vm.selectedMergedEntityList_pos[index].roadmap_sub_entities[sub_index].width,
          height: _vm.computeSubEntityHeight(_vm.selectedMergedEntityList_pos[index], index),
          "entity-index": index,
          index: sub_index,
          objective: service_applications.serviceApplication,
          "is-minimized": _vm.selectedMergedEntityListMinimized[index]
        },
        on: {
          unlink: _vm.unlinkSubEntityFromRoadmap,
          click: _vm.onSubEntitySelect,
          drag: _vm.onRoadmapSubEntityDrag,
          "drag-start": _vm.onRoadmapSubEntityDragStart,
          "drag-stop": _vm.onRoadmapSubEntityDragStop,
          resize: _vm.onRoadmapSubEntityResize,
          "resize-stop": _vm.onRoadmapSubEntityResizeStop
        }
      }, [_vm._v("\n                // TODO add child entities (e.g. sub_features / inititatives)\n                ")])], 1);
    })], 2) : _vm._e()], 1);
  }), _vm._v(" "), _c("g", {
    attrs: {
      refs: "increment_highligher"
    }
  }, [_c("rect", {
    staticClass: "gaphighlighter-anim",
    attrs: {
      x: _vm.activeIncrementHighlighter.x,
      y: _vm.activeIncrementHighlighter.y,
      rx: 5,
      ry: 5,
      width: _vm.activeIncrementHighlighter.width,
      height: _vm.activeIncrementHighlighter.height - 5,
      fill: "rgba(64,150,238, 0.5)",
      "fill-opacity": "0.5",
      "stroke-dasharray": "5,5",
      stroke: "#4096EE",
      "stroke-width": 1,
      "stroke-opacity": "0.9"
    }
  })]), _vm._v(" "), _vm.isCurrentYear() && _vm.currentDayToX() > _vm.computeStartX && _vm.currentDayToX() < _vm.computeStartX + _vm.computeProductDetailWidth ? _c("g", {
    attrs: {
      refs: "current_day"
    }
  }, [_c("path", {
    attrs: {
      d: _vm.computeCurrentDayPath(),
      fill: "#0D63BF",
      stroke: "#0D63BF",
      "stroke-width": "1"
    }
  }), _vm._v(" "), _c("line", {
    attrs: {
      x1: _vm.currentDayToX(),
      y1: _vm.roadmapSettings.entity.timeline.height + 40,
      x2: _vm.currentDayToX(),
      y2: _vm.currentDayToY() + 30,
      stroke: "#0D63BF",
      "stroke-dasharray": "4"
    }
  })]) : _vm._e()], 2)])], 1) : _vm._e(), _vm._v(" "), _vm.filteredEntities.length === 0 ? _c("el-row", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }]
  }, [_c("el-col", {
    staticClass: "no-padding",
    attrs: {
      span: 24
    }
  }, [_c("el-empty", {
    attrs: {
      description: "No products/services exist in this offering - complete your offering first "
    }
  })], 1)], 1) : _vm._e()], 1) : _c("el-row", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }]
  }, [_c("el-col", {
    staticClass: "no-padding",
    attrs: {
      span: 24
    }
  }, [_c("el-empty", {
    attrs: {
      description: "No roadmap exists."
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.displayRoadmapDialog
    }
  }, [_vm._v("Create Roadmap")])], 1)], 1)], 1), _vm._v(" "), _vm.productServiceSelectDialog ? _c("el-dialog", {
    attrs: {
      title: "Product/Service List",
      visible: _vm.productServiceSelectDialog,
      width: "90%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.productServiceSelectDialog = $event;
      }
    }
  }, [_c("product-service-filter-component", {
    attrs: {
      entities: _vm.entitiesList
    },
    on: {
      "filtered-list": _vm.updateSelectedProductServiceList,
      close: function close($event) {
        _vm.productServiceSelectDialog = false;
      }
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.releaseSelectDialog ? _c("el-dialog", {
    attrs: {
      title: "Release Detail",
      visible: _vm.releaseSelectDialog,
      width: "90%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.releaseSelectDialog = $event;
      }
    }
  }, [_c("release-detail-component", {
    attrs: {
      release: _vm.selectedRelease,
      entities: _vm.filteredEntities,
      index: _vm.selectedReleaseIndex,
      "entities-pos": _vm.selectedMergedEntityList_pos,
      "release-pos": _vm.releases_pos[_vm.selectedReleaseIndex],
      settings: _vm.roadmap.additionalattributes.setting
    },
    on: {
      update: _vm.updateSelectedRelease,
      delete: _vm.deleteRelease,
      "delete-increment": _vm.deleteIncrement,
      unlink: _vm.unlinkSubEntityFromRoadmap,
      close: function close($event) {
        return _vm.onReleaseDialogClose();
      }
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.incrementSelectDialog ? _c("el-dialog", {
    attrs: {
      title: "Increment Detail",
      visible: _vm.incrementSelectDialog,
      width: "90%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.incrementSelectDialog = $event;
      }
    }
  }, [_c("increment-detail-component", {
    attrs: {
      increment: _vm.selectedIncrement,
      index: _vm.selectedIncrementIndex,
      "parent-index": _vm.selectedReleaseIndex,
      entities: _vm.filteredEntities,
      "entities-pos": _vm.selectedMergedEntityList_pos,
      "increment-pos": _vm.releases_pos[_vm.selectedReleaseIndex].increments[_vm.selectedIncrementIndex],
      settings: _vm.roadmap.additionalattributes.setting
    },
    on: {
      unlink: _vm.unlinkSubEntityFromRoadmap,
      update: _vm.updateSelectedIncrement,
      delete: _vm.deleteIncrement,
      close: function close($event) {
        return _vm.onIncrementDialogClose();
      }
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.entitySelectDialog ? _c("el-dialog", {
    attrs: {
      visible: _vm.entitySelectDialog,
      width: "90%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.entitySelectDialog = $event;
      }
    }
  }, [_c("span", {
    staticStyle: {
      "font-size": "16px",
      color: "#fff"
    },
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_vm.selectedEntity && _vm.selectedEntity.__typename === "Product" ? _c("svg-icon", {
    staticStyle: {
      "font-size": "16px",
      color: "#fff"
    },
    attrs: {
      "icon-class": "pdg-product"
    }
  }) : _vm._e(), _vm._v(" "), _vm.selectedEntity && _vm.selectedEntity.__typename === "Service" ? _c("svg-icon", {
    staticStyle: {
      "font-size": "16px",
      color: "#fff"
    },
    attrs: {
      "icon-class": "pdg-service"
    }
  }) : _vm._e(), _vm._v("\n      " + _vm._s(_vm.computeEntityDetailTitle) + "\n    ")], 1), _vm._v(" "), _c("entity-detail-component", {
    attrs: {
      entity: _vm.selectedEntity
    },
    on: {
      close: function close($event) {
        return _vm.onEntityDialogClose();
      }
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.subEntitySelectDialog ? _c("el-dialog", {
    attrs: {
      visible: _vm.subEntitySelectDialog,
      width: "90%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.subEntitySelectDialog = $event;
      }
    }
  }, [_c("span", {
    staticStyle: {
      "font-size": "16px",
      color: "#fff"
    },
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_vm.selectedSubEntity && _vm.selectedSubEntity.__typename === "ProductFeature" ? _c("svg-icon", {
    staticStyle: {
      "font-size": "16px",
      color: "#fff"
    },
    attrs: {
      "icon-class": "pdg-product"
    }
  }) : _vm._e(), _vm._v(" "), _vm.selectedSubEntity && _vm.selectedSubEntity.__typename === "ServiceApplication" ? _c("svg-icon", {
    staticStyle: {
      "font-size": "16px",
      color: "#fff"
    },
    attrs: {
      "icon-class": "pdg-service"
    }
  }) : _vm._e(), _vm._v("\n      " + _vm._s(_vm.computeSubEntityDetailTitle) + "\n    ")], 1), _vm._v(" "), _c("sub-entity-detail-component", {
    attrs: {
      "sub-entity": _vm.selectedSubEntity
    },
    on: {
      unlink: _vm.unlinkSubEntityFromRoadmap,
      close: function close($event) {
        return _vm.onSubEntityDialogClose();
      }
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.releaseCreateDialog ? _c("el-dialog", {
    attrs: {
      title: "Create Release",
      visible: _vm.releaseCreateDialog,
      width: "90%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.releaseCreateDialog = $event;
      }
    }
  }, [_vm.hasRoadmap ? _c("add-release-component", {
    attrs: {
      "offering-roadmap-id": _vm.roadmap.id,
      roadmap: _vm.roadmap,
      settings: _vm.roadmap.additionalattributes.setting,
      refresh: _vm.releaseCreateDialog
    },
    on: {
      create: _vm.createRelease,
      close: function close($event) {
        return _vm.onReleaseCreateDialogClose();
      }
    }
  }) : _vm._e()], 1) : _vm._e(), _vm._v(" "), _vm.incrementCreateDialog ? _c("el-dialog", {
    attrs: {
      title: "Create Increment",
      visible: _vm.incrementCreateDialog,
      width: "90%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.incrementCreateDialog = $event;
      }
    }
  }, [_vm.hasRoadmap ? _c("add-increment-component", {
    attrs: {
      "offering-roadmap-id": _vm.roadmap.id,
      release: _vm.selectedRelease,
      settings: _vm.roadmap.additionalattributes.setting,
      refresh: _vm.incrementCreateDialog
    },
    on: {
      create: _vm.createIncrement,
      close: function close($event) {
        return _vm.onIncrementCreateDialogClose();
      }
    }
  }) : _vm._e()], 1) : _vm._e(), _vm._v(" "), _vm.roadmapCreateDialog ? _c("el-dialog", {
    attrs: {
      title: "Create Roadmap",
      visible: _vm.roadmapCreateDialog,
      width: "60%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.roadmapCreateDialog = $event;
      }
    }
  }, [_c("add-roadmap-component", {
    attrs: {
      "offering-id": _vm.offeringId
    },
    on: {
      create: _vm.createRoadmap,
      close: function close($event) {
        return _vm.onRoadmapCreateDialogClose();
      }
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.displaySyncTicketsDialog ? _c("el-dialog", {
    attrs: {
      visible: _vm.displaySyncTicketsDialog,
      title: "Sync Tickets",
      width: "90%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.displaySyncTicketsDialog = $event;
      }
    }
  }, [_c("div", {
    staticClass: "text-center"
  }, [_c("span", [_vm._v("This operation will sync all your JIRA tickets to Atlassian.")]), _vm._v(" "), _c("div", {
    staticClass: "text-center"
  }, [_vm._v("Would you like to continue?")]), _vm._v(" "), _c("div", {
    staticClass: "text-center mt-10"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini",
      icon: _vm.syncTicketProgress.loading ? "el-icon-loading" : "el-icon-refresh",
      disabled: _vm.syncTicketProgress.loading
    },
    on: {
      click: _vm.syncTickets
    }
  }, [_vm._v("Sync")])], 1), _vm._v(" "), _vm.syncTicketProgress.loading ? _c("div", {
    staticClass: "text-center"
  }, [_c("i", [_c("small", [_vm._v(_vm._s(_vm.syncTicketProgress.loading_message))])])]) : _vm._e()]), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.displaySyncTicketsDialog = false;
      }
    }
  }, [_vm._v("Close")])], 1)]) : _vm._e(), _vm._v(" "), _vm.settingsDialog ? _c("el-dialog", {
    attrs: {
      visible: _vm.settingsDialog,
      title: "Settings",
      width: "70%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.settingsDialog = $event;
      }
    }
  }, [_c("setting-component", {
    attrs: {
      roadmap: _vm.roadmap
    },
    on: {
      "rename-roadmap": _vm.renameRoadmapTitle,
      "update-roadmap": _vm.updateRoadmap,
      "delete-roadmap": _vm.deleteRoadmap,
      close: function close($event) {
        return _vm.onRoadmapSettingsDialogClose();
      }
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.displayStorySelectionDialog ? _c("el-dialog", {
    attrs: {
      visible: _vm.displayStorySelectionDialog,
      title: "Select Stories",
      width: "90%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.displayStorySelectionDialog = $event;
      }
    }
  }, [_c("story-list-component", {
    attrs: {
      stories: _vm.selectedSubEntity.childTickets
    },
    on: {
      close: function close($event) {
        return _vm.onRoadmapSelectStoryDialogClose();
      }
    }
  })], 1) : _vm._e()], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;