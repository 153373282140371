"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/taggedTemplateLiteral.js"));
var _templateObject;
var _default = exports.default = {
  name: 'CustomerCreate',
  data: function data() {
    var validateTitle = function validateTitle(rule, value, callback) {
      if (value === '') {
        callback(new Error('Please enter a valid customer name'));
      } else {
        callback();
      }
    };
    var validateDescription = function validateDescription(rule, value, callback) {
      if (value === '') {
        callback(new Error('Please enter a valid description'));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      customerRules: {
        title: [{
          required: true,
          trigger: 'none',
          validator: validateTitle
        }],
        description: [{
          required: true,
          trigger: 'none',
          validator: validateDescription
        }]
      },
      customer_form: {
        title: '',
        description: ''
      }
    };
  },
  created: function created() {},
  mounted: function mounted() {
    this.customer_form = {
      title: '',
      description: ''
    };
  },
  methods: {
    createCustomer: function createCustomer() {
      var _this = this;
      this.$refs.customerForm.validate(function (valid) {
        if (valid) {
          var customer_object = Object.assign({}, _this.customer_form);
          _this.loading = true;
          _this.$apollo.mutate({
            // Query
            mutation: window._gql(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["mutation createCustomerType($customerType: CreateCustomerTypeInput!)\n            {\n              createCustomerType(input: $customerType) {\n                id\n                title\n                description\n                tiers\n                priority\n                rationale\n                linkedProblemHypotheses(optional: true) {\n                  problemHypothesis(optional: true) {\n                    id\n                    problemstatement\n                    solution\n                    source\n                    whatischanging\n                    reasonforchange\n                    areaidentified\n                  }\n                }\n                additionalattributes\n              }\n            }"]))),
            // Parameters
            variables: {
              'customerType': {
                'title': customer_object.title,
                'description': customer_object.description
              }
            }
          }).then(function (data) {
            _this.$notify({
              title: 'Success',
              message: 'Created Successfully',
              type: 'success',
              duration: 2000
            });
            _this.$eventHub.$emit('reload-customer-list', data.data.createCustomerType);
            _this.$emit('create-customer', data.data.createCustomerType);
            _this.customer_form = {
              title: '',
              description: ''
            };
            _this.loading = false;
          }).catch(function (error) {
            // Error
            _this.$notify({
              title: 'Error',
              message: error,
              type: 'error',
              duration: 2000
            });
            _this.loading = false;
          });
        } else {
          return false;
        }
      });
    },
    cancelCreateCustomer: function cancelCreateCustomer() {
      this.$emit('close');
    }
  }
};