"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-form", {
    ref: "addExperienceform",
    attrs: {
      rules: _vm.addExperiencerules,
      model: _vm.experience_form,
      "label-width": "200px",
      mini: ""
    }
  }, [_c("el-form-item", {
    attrs: {
      label: _vm.computeLabelHeader + " name",
      prop: "title"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.experience_form.title,
      callback: function callback($$v) {
        _vm.$set(_vm.experience_form, "title", $$v);
      },
      expression: "experience_form.title"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: _vm.computeLabelHeader + " description",
      prop: "description"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      size: "mini"
    },
    model: {
      value: _vm.experience_form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.experience_form, "description", $$v);
      },
      expression: "experience_form.description"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: _vm.computeLabelHeader + " priority",
      prop: "priority"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.experience_form.priority,
      callback: function callback($$v) {
        _vm.$set(_vm.experience_form, "priority", $$v);
      },
      expression: "experience_form.priority"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: "Very Low"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Low"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Medium"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "High"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Very High"
    }
  })], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: _vm.computeLabelHeader + " value",
      prop: "customervalue"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.experience_form.customervalue,
      callback: function callback($$v) {
        _vm.$set(_vm.experience_form, "customervalue", $$v);
      },
      expression: "experience_form.customervalue"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: "Very Low"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Low"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Medium"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "High"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Very High"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "text-right mt-10"
  }, [_vm.edit ? _c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.updateExperience
    }
  }, [_vm._v("Update")]) : _vm._e(), _vm._v(" "), _vm.edit ? _c("el-button", {
    attrs: {
      size: "mini",
      type: "danger"
    },
    on: {
      click: _vm.deleteExperience
    }
  }, [_vm._v("Delete")]) : _c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.createExperience
    }
  }, [_vm._v("Create")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: _vm.closeUI
    }
  }, [_vm._v("Cancel")])], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;