"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
var _default = exports.default = {
  name: 'VisionGoals',
  props: {},
  data: function data() {
    return {
      addActionDialog: false,
      addGoalDialog: false,
      priorities: [{
        value: 'Highest',
        label: 'Highest'
      }, {
        value: 'High',
        label: 'High'
      }, {
        value: 'Medium',
        label: 'Medium'
      }, {
        value: 'Low',
        label: 'Low'
      }, {
        value: 'Lowest',
        label: 'Lowest'
      }],
      vision_and_goals: {
        product_vision: '',
        strategic_goals: [{
          goal: 'Prow scuttle parrel provost Sail ho shrouds spirits boom mizzenmast yardarm. Pinnace holystone mizzenmast quarter crows nest nipperkin grog yardarm hempen halter furl. Swab barque interloper chantey doubloon starboard grog black jack gangway rutters.',
          strategy: 'Prow scuttle parrel provost Sail ho shrouds spirits boom mizzenmast yardarm. Pinnace holystone mizzenmast quarter crows nest nipperkin grog yardarm hempen halter furl. Swab barque interloper chantey doubloon starboard grog black jack gangway rutters.',
          priority: 'High',
          actions: [{
            action: '123',
            description: '1234',
            assigned_to: 'John',
            priority: 'High',
            achieved: false
          }],
          achieved: false
        }, {
          goal: 'Trysail Sail ho Corsair red ensign hulk smartly boom jib rum gangway. Case shot Shiver me timbers gangplank crack Jennys tea cup ballast Blimey lee snow crows nest rutters. Fluke jib scourge of the seven seas boatswain schooner gaff booty Jack Tar transom spirits',
          strategy: 'Trysail Sail ho Corsair red ensign hulk smartly boom jib rum gangway. Case shot Shiver me timbers gangplank crack Jennys tea cup ballast Blimey lee snow crows nest rutters. Fluke jib scourge of the seven seas boatswain schooner gaff booty Jack Tar transom spirits',
          priority: 'Low',
          actions: [{
            action: '123',
            description: '1234',
            assigned_to: 'John',
            priority: 'High',
            achieved: false
          }],
          achieved: false
        }]
      },
      action_form: {
        action: '',
        description: '',
        assigned_to: '',
        priority: ''
      },
      goal_form: {
        goal: '',
        strategy: '',
        priority: 'Low',
        actions: [],
        achieved: false
      },
      selectedGoal: {}
    };
  },
  mounted: function mounted() {
    this.$eventHub.$emit('process-step-load', 'define-business');
    this.selectedGoal = this.vision_and_goals.strategic_goals[0];
  },
  methods: {
    addGoal: function addGoal() {
      var goal = Object.assign({}, this.goal_form);
      this.vision_and_goals.strategic_goals.push(goal);
      this.addGoalDialog = false;
      // @SASTHA - code here show the new goal in the details form
    },
    computeTableGoal: function computeTableGoal(goal) {
      return goal.split(' ').slice(0, 12).join(' ') + '...';
    },
    selectGoal: function selectGoal(row, column, event) {
      this.selectedGoal = row;
    },
    addAction: function addAction() {
      var action = Object.assign({}, this.action_form);
      this.selectedGoal.actions.push(action);
      this.addActionDialog = false;
    },
    finishAction: function finishAction(actionIndex) {
      this.selectedGoal.actions[actionIndex].achieved = true;
    },
    deleteAction: function deleteAction(actionIndex) {
      this.selectedGoal.actions.splice(actionIndex, 1);
    },
    revertAction: function revertAction(actionIndex) {
      this.selectedGoal.actions[actionIndex].achieved = false;
    },
    deleteGoal: function deleteGoal(index) {
      this.vision_and_goals.strategic_goals.splice(index, 1);
    },
    finishGoal: function finishGoal(index) {
      this.vision_and_goals.strategic_goals[index].achieved = true;
    },
    revertGoal: function revertGoal(index) {
      this.vision_and_goals.strategic_goals[index].achieved = false;
    },
    gotoBusinessRoadMap: function gotoBusinessRoadMap() {
      this.$emit('navigate', 'business-roadmap');
    },
    gotoBusinessModelCanvas: function gotoBusinessModelCanvas() {
      this.$emit('navigate', 'business-model-canvas');
    }
  }
};