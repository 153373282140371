"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _clipboard = _interopRequireDefault(require("@/utils/clipboard"));
var _svgIcons = _interopRequireDefault(require("./svg-icons"));
var _elementIcons = _interopRequireDefault(require("./element-icons"));
var _default = exports.default = {
  name: 'Icons',
  data: function data() {
    return {
      svgIcons: _svgIcons.default,
      elementIcons: _elementIcons.default
    };
  },
  methods: {
    generateIconCode: function generateIconCode(symbol) {
      return "<svg-icon icon-class=\"".concat(symbol, "\" />");
    },
    generateElementIconCode: function generateElementIconCode(symbol) {
      return "<i class=\"el-icon-".concat(symbol, "\" />");
    },
    handleClipboard: function handleClipboard(text, event) {
      (0, _clipboard.default)(text, event);
    }
  }
};