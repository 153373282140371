"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("g", {
    on: {
      click: _vm.emitClick,
      mouseover: _vm.onMouseOver,
      mouseleave: _vm.onMouseLeave
    }
  }, [_c("defs", [_c("linearGradient", {
    attrs: {
      id: "iteration_fill_gradient",
      x1: "0%",
      y1: "0%",
      x2: "100%",
      y2: "0%"
    }
  }, [_c("stop", {
    attrs: {
      offset: "0%",
      "stop-color": "#447799"
    }
  }), _vm._v(" "), _c("stop", {
    attrs: {
      offset: "50%",
      "stop-color": "#224488"
    }
  }), _vm._v(" "), _c("stop", {
    attrs: {
      offset: "100%",
      "stop-color": "#0C8EED"
    }
  })], 1)], 1), _vm._v(" "), _c("rect", {
    attrs: {
      x: _vm.x + 5,
      y: _vm.y + 5 + _vm.index * 35,
      width: _vm.width < 10 ? 0 : _vm.width - 10,
      height: "30",
      rx: "5",
      fill: "url(#iteration_fill_gradient)",
      stroke: "url(#iteration_fill_gradient)",
      "stroke-width": "1"
    }
  }), _vm._v(" "), _c("text", {
    attrs: {
      x: _vm.x + 5 + (_vm.width - 10) / 2,
      y: _vm.y + 17 + _vm.index * 35,
      "text-anchor": "middle",
      "font-size": "0.8em",
      fill: "white"
    }
  }, [_vm._v(_vm._s(_vm.computeSprintText()))]), _vm._v(" "), _c("text", {
    attrs: {
      x: _vm.x + 5 + (_vm.width - 10) / 2,
      y: _vm.y + 28 + _vm.index * 35,
      "text-anchor": "middle",
      "font-size": "0.6em",
      fill: "white"
    }
  }, [_vm._v(_vm._s(_vm.computeSprintDescription()))]), _vm._v(" "), _vm.isHover ? _c("g", {
    attrs: {
      refs: "details_icon"
    }
  }, [_c("foreignObject", {
    attrs: {
      x: _vm.x + _vm.width - 44,
      y: _vm.y + 12 + _vm.index * 35,
      width: 15,
      height: 15
    }
  }, [_c("el-popover", {
    attrs: {
      placement: "top-start",
      title: _vm.sprint.name,
      width: "500",
      trigger: "hover",
      content: _vm.sprint.description
    }
  }, [_c("svg-icon", {
    staticStyle: {
      color: "white",
      width: "15px",
      height: "15px"
    },
    attrs: {
      slot: "reference",
      "icon-class": "search"
    },
    slot: "reference"
  })], 1)], 1)]) : _vm._e(), _vm._v(" "), _vm.isHover ? _c("g", {
    attrs: {
      refs: "delete_icon"
    },
    on: {
      click: function click($event) {
        $event.preventDefault();
        return _vm.removeSprint.apply(null, arguments);
      }
    }
  }, [_c("foreignObject", {
    attrs: {
      x: _vm.x + _vm.width - 22,
      y: _vm.y + 12 + _vm.index * 35,
      width: 15,
      height: 15
    }
  }, [_c("div", {
    staticClass: "text-center"
  }, [_c("svg-icon", {
    staticStyle: {
      "'background-color": "red",
      width: "15px",
      height: "15px"
    },
    attrs: {
      "icon-class": "gradient-circle-error"
    }
  })], 1)])]) : _vm._e()]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;