"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      data: _vm.entities,
      stripe: "",
      size: "mini"
    },
    on: {
      "selection-change": _vm.handleSelectionChange
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "selection",
      width: "55"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Type"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.__typename === "LinkProductOffering" ? _c("svg-icon", {
          staticStyle: {
            "font-size": "16px",
            color: "#111"
          },
          attrs: {
            "icon-class": "pdg-product"
          }
        }) : _vm._e(), _vm._v(" "), scope.row.__typename === "LinkServiceOffering" ? _c("svg-icon", {
          staticStyle: {
            "font-size": "16px",
            color: "#111"
          },
          attrs: {
            "icon-class": "pdg-service"
          }
        }) : _vm._e()];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Title",
      sortable: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.__typename === "LinkProductOffering" ? _c("span", [_vm._v(_vm._s(_vm.truncateString(scope.row.product.title, 90)))]) : _vm._e(), _vm._v(" "), scope.row.__typename === "LinkServiceOffering" ? _c("span", [_vm._v(_vm._s(_vm.truncateString(scope.row.service.title, 90)))]) : _vm._e()];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Features/Services",
      sortable: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.__typename === "LinkProductOffering" ? _c("span", [scope.row.product.linkedFeatures.length > 1 ? _c("el-tag", {
          attrs: {
            size: "mini",
            effect: "dark"
          }
        }, [_vm._v(_vm._s(scope.row.product.linkedFeatures.length) + " Features")]) : _vm._e(), _vm._v(" "), scope.row.product.linkedFeatures.length === 1 ? _c("el-tag", {
          attrs: {
            size: "mini",
            effect: "dark"
          }
        }, [_vm._v(_vm._s(scope.row.product.linkedFeatures.length) + " Feature")]) : _vm._e(), _vm._v(" "), scope.row.product.linkedFeatures.length === 0 ? _c("el-tag", {
          attrs: {
            size: "mini",
            effect: "dark",
            type: "info"
          }
        }, [_vm._v("No Features")]) : _vm._e()], 1) : _vm._e(), _vm._v(" "), scope.row.__typename === "LinkServiceOffering" ? _c("span", [scope.row.service.linkedServiceApplications.length > 1 ? _c("el-tag", {
          attrs: {
            size: "mini",
            effect: "dark"
          }
        }, [_vm._v(_vm._s(scope.row.service.linkedServiceApplications.length) + " Service")]) : _vm._e(), _vm._v(" "), scope.row.service.linkedServiceApplications.length === 1 ? _c("el-tag", {
          attrs: {
            size: "mini",
            effect: "dark"
          }
        }, [_vm._v(_vm._s(scope.row.service.linkedServiceApplications.length) + " Service")]) : _vm._e(), _vm._v(" "), scope.row.service.linkedServiceApplications.length === 0 ? _c("el-tag", {
          attrs: {
            size: "mini",
            effect: "dark",
            type: "info"
          }
        }, [_vm._v("No Services")]) : _vm._e()], 1) : _vm._e()];
      }
    }])
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "text-right"
  }, [_c("el-button-group", [_c("el-button", {
    attrs: {
      type: "secondary",
      icon: "el-icon-close",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.closeFilterDialog();
      }
    }
  }, [_vm._v("Close")])], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;