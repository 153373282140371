"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.match");
var _validate = require("@/utils/validate");
var _default = exports.default = {
  name: 'UsersCreate',
  data: function data() {
    var validateFirstName = function validateFirstName(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter the First Name'));
      } else {
        callback();
      }
    };
    var validateLastName = function validateLastName(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter the Last Name'));
      } else {
        callback();
      }
    };
    var validateEmail = function validateEmail(rule, value, callback) {
      if (!(0, _validate.validEmail)(value)) {
        callback(new Error('Please enter a valid email address'));
      } else {
        callback();
      }
    };
    var validatePassword = function validatePassword(rule, value, callback) {
      if (value === '') {
        callback(new Error('Please enter a password'));
      } else if (value.length < 8) {
        callback(new Error('The password can not be less than 8 digits'));
      } else if (!value.match(/[a-z]/) || !value.match(/[A-Z]/)) {
        callback(new Error('The password should have atleast one Uppercase and Lowercase letter'));
      } else if (!value.match(/\d/)) {
        callback(new Error('The password should have atleast one number'));
      } else {
        callback();
      }
    };
    var validateConfirmPassword = function validateConfirmPassword(rule, value, callback) {
      if (value === '') {
        callback(new Error('Please enter a password'));
      } else if (value !== document.getElementById('password').value) {
        callback(new Error('The passwords do not match. Please re-enter password'));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      passwordRulesPopover: false,
      passwordRules: [{
        valid: false,
        rule: '8 or more characters'
      }, {
        valid: false,
        rule: 'Upper and lower case letters'
      }, {
        valid: false,
        rule: 'Numbers'
      }],
      user_form: {
        firstname: '',
        middlename: '',
        lastname: '',
        emailAddress: '',
        mobileNumber: '',
        telephoneNumber: '',
        password: '',
        confirmPassword: ''
      },
      userCreateRules: {
        firstname: [{
          required: true,
          trigger: 'blur',
          validator: validateFirstName
        }],
        lastname: [{
          required: true,
          trigger: 'blur',
          validator: validateLastName
        }],
        emailAddress: [{
          required: true,
          trigger: 'blur',
          validator: validateEmail
        }],
        password: [{
          required: true,
          trigger: 'blur',
          validator: validatePassword
        }],
        confirmPassword: [{
          required: true,
          trigger: 'change',
          validator: validateConfirmPassword
        }]
      }
    };
  },
  watch: {
    'user_form.password': function user_formPassword(newValue, oldValue) {
      if (newValue.length > 7) {
        this.passwordRules[0].valid = true;
      } else {
        this.passwordRules[0].valid = false;
      }
      if (newValue.match(/[a-z]/) && newValue.match(/[A-Z]/)) {
        this.passwordRules[1].valid = true;
      } else {
        this.passwordRules[1].valid = false;
      }
      if (newValue.match(/\d/)) {
        this.passwordRules[2].valid = true;
      } else {
        this.passwordRules[2].valid = false;
      }
    }
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    createUser: function createUser() {
      var _this = this;
      this.$refs.userCreateForm.validate(function (valid) {
        if (valid) {
          _this.$emit('create-user', _this.user_form);
        }
      });
    },
    cancelCreate: function cancelCreate() {
      this.$emit('cancel');
    }
  }
};