"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/taggedTemplateLiteral.js"));
var _templateObject, _templateObject2, _templateObject3, _templateObject4;
var _default2 = exports.default = {
  name: 'Setting',
  components: {},
  props: {
    journeyMap: {
      type: Object,
      required: true,
      default: function _default() {
        return {
          id: '',
          title: '',
          description: '',
          additionalattributes: {},
          linkedJourneyExperiences: [],
          linkedJourneyPhases: [],
          linkedJourneySteps: []
        };
      }
    }
  },
  data: function data() {
    return {
      loading: false,
      relationView: 'simple',
      activeTabName: 'journey',
      parentJourneyMapTitle: '',
      deleteConfirmationDialog: false,
      regenerateConfirmationDialog: false,
      journeyVersionsDialog: false,
      journeyVersion: [{
        title: 'Journey Name',
        version: '4'
      }, {
        title: 'Journey Name1',
        version: '3'
      }, {
        title: 'Journey Name2',
        version: '2'
      }, {
        title: 'Journey Name3',
        version: '1'
      }],
      journeySettings: [{
        title: 'Generate Journey',
        description: 'Generate Journey from Jobs To Be Done data',
        type: 'generate',
        note: {
          type: 'info',
          message: 'All existing work will be over-written.'
        }
      }, {
        title: 'Copy Journey',
        description: 'Create a new journey to create journey variants.',
        type: 'copy',
        note: {
          type: 'info',
          message: 'All existing work will be over-written however can be restored from a prior version.'
        }
      }, {
        title: 'Delete Journey',
        description: 'Delete the current Journey',
        type: 'delete',
        note: {
          type: 'info"',
          message: 'The current Journey will be saved as a prior version, and new blank version created.'
        }
      } /*,
        {
         title: 'Prior Version',
         description: 'Display list of prior versions',
         type: 'version',
         note: {}
        }*/],
      relationStyleSettings: [{
        title: 'Opacity',
        value: 1,
        value_on_hover: 1
      }, {
        title: 'Fill',
        value: 'none',
        value_on_hover: 'none'
      }, {
        title: 'Dasharray',
        value: '3,3',
        value_on_hover: '3,3'
      }, {
        title: 'Width',
        value: 1,
        value_on_hover: 2
      }, {
        title: 'Color',
        value: '#ccc',
        value_on_hover: '#ccc'
      }, {
        title: 'Path Algorithm',
        value: null,
        value_on_hover: null
      }],
      deleteConfirmationForm: {
        title: ''
      },
      generationConfirmationForm: {
        title: ''
      },
      settings: {
        sync_frequency: 1000
      }
    };
  },
  computed: {},
  watch: {},
  created: function created() {},
  mounted: function mounted() {
    this.resetUI();
    if (this.journeyMap.iscloned) {
      this.retrieveJourneyMapFromId();
    }
  },
  methods: {
    retrieveJourneyMapFromId: function retrieveJourneyMapFromId() {
      var _this = this;
      this.$apollo.query({
        // Query
        query: window._gql(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["query JourneyMap($id: String)\n        {\n          JourneyMap(id: $id)\n          {\n            id\n            title\n          }\n        }"]))),
        fetchPolicy: 'no-cache',
        variables: {
          'id': this.journeyMap.clonedobjectid
        }
      }).then(function (data) {
        _this.parentJourneyMapTitle = data.data.JourneyMap.title;
      }).catch(function (error) {
        // Error
        console.log(error);
      });
    },
    setRelationView: function setRelationView(view) {
      switch (view) {
        case 'simple':
          this.relationView = 'simple';
          this.activeTabName = 'relations';
          break;
        case 'advanced':
          this.relationView = 'advanced';
      }
    },
    openJourneyVersionsDialog: function openJourneyVersionsDialog() {
      this.journeyVersionsDialog = true;
    },
    copyJourneyMap: function copyJourneyMap() {
      this.$swal({
        title: 'TBD',
        text: 'To be updated',
        icon: 'info'
      }).then(function (response) {});
    },
    deleteJourneyConfirmation: function deleteJourneyConfirmation() {
      this.deleteConfirmationForm.title = '';
      this.deleteConfirmationDialog = true;
    },
    deleteJourney: function deleteJourney() {
      this.$emit('delete-journeymap', this.journeyMap.id);
      var cookieString = 'ProdiggiJourney_' + window._Cookies.get('UserPartyID') + '_' + window._Cookies.get('UserID') + '_' + this.journeyMap.id;
      window._Cookies.remove(cookieString);
      this.deleteConfirmationDialog = false;
      this.closeUI();
    },
    regenerateJourneyMapConfirmation: function regenerateJourneyMapConfirmation() {
      this.generationConfirmationForm.title = '';
      this.regenerateConfirmationDialog = true;
    },
    regenerateJourney: function regenerateJourney() {
      this.$emit('regenerate-journeymap', this.journeyMap);
      this.regenerateConfirmationDialog = false;
    },
    renameJourneyMapTitle: function renameJourneyMapTitle() {
      var journeyMap = Object.assign({}, this.journeyMap);
      this.$emit('rename-journeymap', journeyMap);
    },
    updateSpecificRelations: function updateSpecificRelations() {
      var _this2 = this;
      var additionalattributes = this.journeyMap.additionalattributes;
      this.loading = true;
      this.$apollo.mutate({
        // Query
        mutation: window._gql(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["mutation updateJourneyMap($journeymap: UpdateJourneyMapInput!)\n        {\n          updateJourneyMap(input: $journeymap) {\n            id\n          }\n        }"]))),
        // Parameters
        variables: {
          'journeymap': {
            'id': this.journeyMap.id,
            'additionalattributes': additionalattributes
          }
        }
      }).then(function (data) {
        _this2.loading = false;
      }).catch(function (error) {
        // Error
        _this2.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this2.loading = false;
      });
    },
    updateSyncTiming: function updateSyncTiming() {
      var _this3 = this;
      var additionalattributes = this.journeyMap.additionalattributes;
      additionalattributes.settings.sync_frequency = this.settings.sync_frequency;
      this.loading = true;
      this.$apollo.mutate({
        // Query
        mutation: window._gql(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["mutation updateJourneyMap($journeymap: UpdateJourneyMapInput!)\n        {\n          updateJourneyMap(input: $journeymap) {\n            id\n          }\n        }"]))),
        // Parameters
        variables: {
          'journeymap': {
            'id': this.journeyMap.id,
            'additionalattributes': additionalattributes
          }
        }
      }).then(function (data) {
        _this3.loading = false;
      }).catch(function (error) {
        // Error
        _this3.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this3.loading = false;
      });
    },
    updateAllRelations: function updateAllRelations() {
      var _this4 = this;
      var additionalattributes = this.journeyMap.additionalattributes;
      for (var i = 0; i < additionalattributes.relations.length; i++) {
        additionalattributes.relations[i].style.opacity = this.relationStyleSettings[0].value;
        additionalattributes.relations[i].style.fill = this.relationStyleSettings[1].value;
        additionalattributes.relations[i].style.dasharray = this.relationStyleSettings[2].value;
        additionalattributes.relations[i].style.width = this.relationStyleSettings[3].value;
        additionalattributes.relations[i].style.color = this.relationStyleSettings[4].value;
        additionalattributes.relations[i].style.algorithm = this.relationStyleSettings[5].value;
        additionalattributes.relations[i].styleonhover.opacity = this.relationStyleSettings[0].value_on_hover;
        additionalattributes.relations[i].styleonhover.fill = this.relationStyleSettings[1].value_on_hover;
        additionalattributes.relations[i].styleonhover.dasharray = this.relationStyleSettings[2].value_on_hover;
        additionalattributes.relations[i].styleonhover.width = this.relationStyleSettings[3].value_on_hover;
        additionalattributes.relations[i].styleonhover.color = this.relationStyleSettings[4].value_on_hover;
        additionalattributes.relations[i].styleonhover.algorithm = this.relationStyleSettings[5].value_on_hover;
      }
      for (var j = 0; j < additionalattributes.step_relations.length; j++) {
        additionalattributes.step_relations[j].style.opacity = this.relationStyleSettings[0].value;
        additionalattributes.step_relations[j].style.fill = this.relationStyleSettings[1].value;
        additionalattributes.step_relations[j].style.dasharray = this.relationStyleSettings[2].value;
        additionalattributes.step_relations[j].style.width = this.relationStyleSettings[3].value;
        additionalattributes.step_relations[j].style.color = this.relationStyleSettings[4].value;
        additionalattributes.step_relations[j].style.algorithm = this.relationStyleSettings[5].value;
        additionalattributes.step_relations[j].styleonhover.opacity = this.relationStyleSettings[0].value_on_hover;
        additionalattributes.step_relations[j].styleonhover.fill = this.relationStyleSettings[1].value_on_hover;
        additionalattributes.step_relations[j].styleonhover.dasharray = this.relationStyleSettings[2].value_on_hover;
        additionalattributes.step_relations[j].styleonhover.width = this.relationStyleSettings[3].value_on_hover;
        additionalattributes.step_relations[j].styleonhover.color = this.relationStyleSettings[4].value_on_hover;
        additionalattributes.step_relations[j].styleonhover.algorithm = this.relationStyleSettings[5].value_on_hover;
      }
      this.loading = true;
      this.$apollo.mutate({
        // Query
        mutation: window._gql(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2.default)(["mutation updateJourneyMap($journeymap: UpdateJourneyMapInput!)\n        {\n          updateJourneyMap(input: $journeymap) {\n            id\n          }\n        }"]))),
        // Parameters
        variables: {
          'journeymap': {
            'id': this.journeyMap.id,
            'additionalattributes': additionalattributes
          }
        }
      }).then(function (data) {
        _this4.loading = false;
      }).catch(function (error) {
        // Error
        _this4.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this4.loading = false;
      });
    },
    computeEntityTitle: function computeEntityTitle(type, id) {
      switch (type) {
        case 'Phase':
          return this.computePhaseTitleFromId(id);
        case 'Step':
          return this.computeStepTitleFromId(id);
        case 'Experience':
          return this.computeExperienceTitleFromId(id);
      }
    },
    computePhaseTitleFromId: function computePhaseTitleFromId(id) {
      for (var i = 0; i < this.journeyMap.linkedJourneyPhases.length; i++) {
        if (this.journeyMap.linkedJourneyPhases[i].journeyPhase.id === id) {
          return this.journeyMap.linkedJourneyPhases[i].journeyPhase.title;
        }
      }
    },
    computeStepTitleFromId: function computeStepTitleFromId(id) {
      for (var i = 0; i < this.journeyMap.linkedJourneySteps.length; i++) {
        if (this.journeyMap.linkedJourneySteps[i].journeyStep.id === id) {
          return this.journeyMap.linkedJourneySteps[i].journeyStep.title;
        }
      }
    },
    computeExperienceTitleFromId: function computeExperienceTitleFromId(id) {
      for (var i = 0; i < this.journeyMap.linkedJourneyExperiences.length; i++) {
        if (this.journeyMap.linkedJourneyExperiences[i].journeyExperience.id === id) {
          return this.journeyMap.linkedJourneyExperiences[i].journeyExperience.title;
        }
      }
    },
    closeUI: function closeUI() {
      this.$emit('close');
    },
    resetUI: function resetUI() {
      this.deleteConfirmationDialog = false;
      this.regenerateConfirmationDialog = false;
      this.journeyVersionsDialog = false;
      this.generationConfirmationForm.title = '';
      this.deleteConfirmationForm.title = '';
    }
  }
};