"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/taggedTemplateLiteral.js"));
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _templateObject, _templateObject2;
var _default = exports.default = {
  name: 'CompetitiveLandscape',
  components: {},
  props: {},
  data: function data() {
    var validateTitle = function validateTitle(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter a valid title'));
      } else {
        callback();
      }
    };
    var validateCategory = function validateCategory(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter a valid category'));
      } else {
        callback();
      }
    };
    var validateMaturity = function validateMaturity(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter a valid maturity'));
      } else {
        callback();
      }
    };
    var validateStrength = function validateStrength(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter a valid strength'));
      } else {
        callback();
      }
    };
    var validateThreat = function validateThreat(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter a valid threat'));
      } else {
        callback();
      }
    };
    var validateFeature = function validateFeature(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter a valid feature'));
      } else {
        callback();
      }
    };
    var validateTheirOffering = function validateTheirOffering(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter a valid offering'));
      } else {
        callback();
      }
    };
    var validateYourOffering = function validateYourOffering(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter a valid offering'));
      } else {
        callback();
      }
    };
    var validateSegment = function validateSegment(rule, value, callback) {
      if (!value.length === 0) {
        callback(new Error('Please enter a valid segment'));
      } else {
        callback();
      }
    };
    return {
      competitorFeatureRules: {
        category: [{
          required: true,
          trigger: 'blur',
          validator: validateCategory
        }],
        feature: [{
          required: true,
          trigger: 'blur',
          validator: validateFeature
        }],
        maturity: [{
          required: true,
          trigger: 'blur',
          validator: validateMaturity
        }],
        threat: [{
          required: true,
          trigger: 'blur',
          validator: validateThreat
        }],
        strength: [{
          required: true,
          trigger: 'blur',
          validator: validateStrength
        }]
      },
      competitorRules: {
        title: [{
          required: true,
          trigger: 'blur',
          validator: validateTitle
        }],
        their_offering: [{
          required: true,
          trigger: 'blur',
          validator: validateTheirOffering
        }],
        your_offerings: [{
          required: true,
          trigger: 'blur',
          validator: validateYourOffering
        }],
        category: [{
          required: true,
          trigger: 'blur',
          validator: validateCategory
        }],
        segments: [{
          required: true,
          trigger: 'blur',
          validator: validateSegment
        }],
        threat: [{
          required: true,
          trigger: 'blur',
          validator: validateThreat
        }]
      },
      loading: false,
      featureDetailDialog: false,
      addCompetitorFeatureDialog: false,
      addCompetitorDialog: false,
      landscapeViewDialog: false,
      selectedFeature: {},
      selectedCompetitorLandscapeFeature: {},
      selectedCompetitorLandscapeOffering: {},
      selectedFeatureId: '',
      selectedOfferingId: '',
      filter: {
        offering: '',
        product: '',
        release: '',
        version: '',
        status: '',
        phase: '',
        enabler: ''
      },
      competitor_feature_form: {
        competitor: {
          id: '1',
          title: ''
        },
        feature: '',
        category: '',
        maturity: '',
        threat: '',
        strength: ''
      },
      offerings: [{
        id: '123',
        title: 'offering 1',
        features: [{
          id: '1',
          title: 'Feature 1',
          active: false,
          competitor_features: [{
            competitor: {
              id: '123',
              title: 'Competitor A'
            },
            category: 'Direct',
            maturity: 'Mature',
            threat: 'Low',
            edit: false,
            active: false,
            x: 0,
            y: 0,
            feature: '',
            strength: ''
          }, {
            competitor: {
              id: '1234',
              title: 'Competitor B'
            },
            category: 'Direct',
            maturity: 'POC',
            threat: 'Medium',
            edit: false,
            active: false,
            x: 0,
            y: 0,
            feature: '',
            strength: ''
          }, {
            competitor: {
              id: '1235',
              title: 'Competitor C'
            },
            category: 'Near',
            maturity: 'POC',
            threat: 'High',
            edit: false,
            active: false,
            x: 0,
            y: 0,
            feature: '',
            strength: ''
          }, {
            competitor: {
              id: '1236',
              title: 'Competitor D'
            },
            category: 'Peripheral',
            maturity: 'Mature',
            threat: 'None',
            edit: false,
            active: false,
            x: 0,
            y: 0,
            feature: '',
            strength: ''
          }]
        }, {
          id: '2',
          title: 'Feature 2',
          active: false,
          competitor_features: [{
            competitor: {
              id: '123',
              title: 'Competitor A'
            },
            category: 'Near',
            maturity: 'Mature',
            threat: 'Low',
            edit: false,
            active: false,
            x: 0,
            y: 0,
            feature: '',
            strength: ''
          }, {
            competitor: {
              id: '1234',
              title: 'Competitor B'
            },
            category: 'Near',
            maturity: 'POC',
            threat: 'Medium',
            edit: false,
            active: false,
            x: 0,
            y: 0,
            feature: '',
            strength: ''
          }, {
            competitor: {
              id: '1235',
              title: 'Competitor C'
            },
            category: 'Near',
            maturity: 'POC',
            threat: 'High',
            edit: false,
            active: false,
            x: 0,
            y: 0,
            feature: '',
            strength: ''
          }, {
            competitor: {
              id: '1236',
              title: 'Competitor D'
            },
            category: 'Near',
            maturity: 'Mature',
            threat: 'None',
            edit: false,
            active: false,
            x: 0,
            y: 0,
            feature: '',
            strength: ''
          }]
        }, {
          id: '3',
          title: 'Feature 3',
          active: false,
          competitor_features: [{
            competitor: {
              id: '123',
              title: 'Competitor A'
            },
            category: 'Peripheral',
            maturity: 'Mature',
            threat: 'Low',
            edit: false,
            active: false,
            x: 0,
            y: 0,
            feature: '',
            strength: ''
          }, {
            competitor: {
              id: '1234',
              title: 'Competitor B'
            },
            category: 'Direct',
            maturity: 'POC',
            threat: 'Medium',
            edit: false,
            active: false,
            x: 0,
            y: 0,
            feature: '',
            strength: ''
          }, {
            competitor: {
              id: '1235',
              title: 'Competitor C'
            },
            category: 'Peripheral',
            maturity: 'POC',
            threat: 'High',
            edit: false,
            active: false,
            x: 0,
            y: 0,
            feature: '',
            strength: ''
          }, {
            competitor: {
              id: '1236',
              title: 'Competitor D'
            },
            category: 'Peripheral',
            maturity: 'Mature',
            threat: 'None',
            edit: false,
            active: false,
            x: 0,
            y: 0,
            feature: '',
            strength: ''
          }]
        }]
      }],
      features: [{
        id: '1',
        title: 'Feature 1',
        customer_types: [{
          id: '123',
          title: 'Customer Type 1'
        }, {
          id: '1234',
          title: 'Customer Type 2'
        }],
        phases: [{
          id: '123',
          title: 'Phase 1'
        }, {
          id: '1234',
          title: 'Phase 2'
        }],
        steps: [{
          id: '123',
          title: 'Step 1'
        }, {
          id: '1234',
          title: 'Step 2'
        }],
        offering: {
          id: '123',
          title: 'Offering Name'
        },
        product: {
          id: '123',
          title: 'Product Name'
        },
        service: {},
        status: 'In Development',
        releases: ['2022 H1'],
        version: '1.1',
        enabler: 'true',
        competitor_features: [{
          competitor: {
            id: '123',
            title: 'Competitor A'
          },
          category: 'Direct',
          maturity: 'Mature',
          threat: 'Low',
          edit: false,
          active: false,
          x: 0,
          y: 0,
          feature: '',
          strength: ''
        }, {
          competitor: {
            id: '1234',
            title: 'Competitor B'
          },
          category: 'Direct',
          maturity: 'POC',
          threat: 'Medium',
          edit: false,
          active: false,
          x: 0,
          y: 0,
          feature: '',
          strength: ''
        }, {
          competitor: {
            id: '1235',
            title: 'Competitor C'
          },
          category: 'Near',
          maturity: 'POC',
          threat: 'High',
          edit: false,
          active: false,
          x: 0,
          y: 0,
          feature: '',
          strength: ''
        }, {
          competitor: {
            id: '1236',
            title: 'Competitor D'
          },
          category: 'Peripheral',
          maturity: 'Mature',
          threat: 'None',
          edit: false,
          active: false,
          x: 0,
          y: 0,
          feature: '',
          strength: ''
        }]
      }, {
        id: '2',
        title: 'Feature 2',
        customer_types: [{
          id: '123',
          title: 'Customer Type 1'
        }, {
          id: '1234',
          title: 'Customer Type 2'
        }],
        phases: [{
          id: '123',
          title: 'Phase 1'
        }, {
          id: '1234',
          title: 'Phase 2'
        }],
        steps: [{
          id: '123',
          title: 'Step 1'
        }, {
          id: '1234',
          title: 'Step 2'
        }],
        offering: {
          id: '123',
          title: 'Offering Name'
        },
        product: {
          id: '123',
          title: 'Product Name'
        },
        service: {},
        status: 'In Development',
        releases: ['2022 Q1'],
        version: '1.1',
        enabler: 'true',
        competitor_features: [{
          competitor: {
            id: '123',
            title: 'Competitor A'
          },
          category: 'Direct',
          maturity: 'Mature',
          threat: 'Low',
          edit: false,
          active: false,
          x: 0,
          y: 0,
          feature: '',
          strength: ''
        }, {
          competitor: {
            id: '1234',
            title: 'Competitor B'
          },
          category: 'Direct',
          maturity: 'POC',
          threat: 'Medium',
          edit: false,
          active: false,
          x: 0,
          y: 0,
          feature: '',
          strength: ''
        }, {
          competitor: {
            id: '1235',
            title: 'Competitor C'
          },
          category: 'Near',
          maturity: 'POC',
          threat: 'High',
          edit: false,
          active: false,
          x: 0,
          y: 0,
          feature: '',
          strength: ''
        }, {
          competitor: {
            id: '1236',
            title: 'Competitor D'
          },
          category: 'Peripheral',
          maturity: 'Mature',
          threat: 'None',
          edit: false,
          active: false,
          x: 0,
          y: 0,
          feature: '',
          strength: ''
        }]
      }],
      competitor_form: {
        title: '',
        category: 'Direct',
        head_office: '',
        strength: '',
        my_strength: '',
        users: 0,
        threat: 'Low',
        their_offering: '',
        acquitision_details: '',
        active: false,
        acquired: false,
        date: '12-12-2022',
        your_offerings: '',
        segments: [],
        features: []
      },
      competitors: [{
        id: '1',
        title: 'Competitor A',
        their_offering: 'lorem ipsum',
        your_offering: 'lorem ipsum',
        category: 'Direct',
        segments: ['label 1', 'label 2', 'label 3'],
        threat: 'Low'
      }, {
        id: '2',
        title: 'Competitor B',
        their_offering: 'lorem ipsum',
        your_offering: 'lorem ipsum',
        category: 'Direct',
        segments: ['label 1', 'label 2', 'label 3'],
        threat: 'No Threat'
      }, {
        id: '3',
        title: 'Competitor C',
        their_offering: 'lorem ipsum',
        your_offering: 'lorem ipsum',
        category: 'Direct',
        segments: ['label 1', 'label 2', 'label 3'],
        threat: 'Medium'
      }]
    };
  },
  computed: {
    filteredFeatures: function filteredFeatures() {
      var self = this;
      return this.features.filter(function (feature) {
        return feature.offering.title.toLowerCase().indexOf(self.filter.offering.toLowerCase()) > -1 && feature.product.title.toLowerCase().indexOf(self.filter.product.toLowerCase()) > -1 && feature.status.toLowerCase().indexOf(self.filter.status.toLowerCase()) > -1 && feature.version.indexOf(self.filter.version) > -1 && feature.enabler.indexOf(self.filter.enabler) > -1 && (self.filter.release && feature.releases.length > 0 ? feature.releases.includes(self.filter.release) : true) && feature.phases.some(function (e) {
          return e.title.indexOf(self.filter.phase) > -1;
        });
      });
    }
  },
  watch: {
    selectedFeatureId: function selectedFeatureId(val) {
      for (var i = 0; i < this.features.length; i++) {
        if (this.features[i].id === val) {
          this.selectedCompetitorLandscapeFeature = this.features[i];
          this.recomputeCompetitorLandscapeViewFeature();
        }
      }
    },
    selectedOfferingId: function selectedOfferingId(val) {
      for (var i = 0; i < this.offerings.length; i++) {
        if (this.offerings[i].id === val) {
          this.selectedCompetitorLandscapeOffering = this.offerings[i];
          this.recomputeCompetitorLandscapeViewOffering();
        }
      }
    }
  },
  mounted: function mounted() {
    // this.retrieveOfferings('000000007be91a3d017c4ee072b20119')
    // this.retrieveFeatures(1)
    this.selectedFeature = this.filteredFeatures[0];
    this.selectedCompetitorLandscapeFeature = this.filteredFeatures[0];
    this.selectedFeatureId = this.filteredFeatures[0].id;
    this.selectedOffering = this.offerings[0];
    this.selectedCompetitorLandscapeOffering = this.offerings[0];
    this.selectedOfferingId = this.offerings[0].id;
  },
  methods: {
    truncateString: function truncateString(string, length) {
      return this._.truncate(string, {
        'length': length,
        'separator': ' '
      });
    },
    recomputeCompetitorLandscapeViewFeature: function recomputeCompetitorLandscapeViewFeature() {
      for (var i = 0; i < this.selectedCompetitorLandscapeFeature.competitor_features.length; i++) {
        var random = this.computeRandomPoint(350, 250, this.selectedCompetitorLandscapeFeature.competitor_features[i].category);
        this.selectedCompetitorLandscapeFeature.competitor_features[i].x = Math.floor(random[0]);
        this.selectedCompetitorLandscapeFeature.competitor_features[i].y = Math.floor(random[1]);
      }
    },
    recomputeCompetitorLandscapeViewOffering: function recomputeCompetitorLandscapeViewOffering() {
      for (var i = 0; i < this.selectedCompetitorLandscapeOffering.features.length; i++) {
        var category = this.computeAverageCategory(this.selectedCompetitorLandscapeOffering.features[i]);
        var random = this.computeRandomPoint(350, 250, category);
        this.selectedCompetitorLandscapeOffering.features[i].x = Math.floor(random[0]);
        this.selectedCompetitorLandscapeOffering.features[i].y = Math.floor(random[1]);
      }
    },
    computeAverageCategory: function computeAverageCategory(feature) {
      var score = 0;
      for (var i = 0; i < feature.competitor_features.length; i++) {
        switch (feature.competitor_features[i].category) {
          case 'Direct':
            score += 0;
            break;
          case 'Near':
            score += 1;
            break;
          case 'Peripheral':
            score += 2;
            break;
        }
      }
      var average_score = Math.floor(score / feature.competitor_features.length);
      switch (average_score) {
        case 0:
          return 'Direct';
        case 1:
          return 'Near';
        case 2:
          return 'Peripheral';
      }
    },
    computeRandomPoint: function computeRandomPoint(X, Y, category) {
      var min = 0;
      var max = 0;
      switch (category) {
        case 'Direct':
          min = 0;
          max = 40;
          break;
        case 'Near':
          min = 60;
          max = 90;
          break;
        case 'Peripheral':
          min = 110;
          max = 140;
          break;
      }
      var degrees = Math.random() * 360;
      var D = Math.random() * (max - min) + min;
      var pi = Math.PI;
      return [X + D * Math.cos(degrees * (pi / 180)), Y + D * Math.sin(degrees * (pi / 180))];
    },
    openAddCompetitorDialog: function openAddCompetitorDialog() {
      this.addCompetitorDialog = true;
    },
    addCompetitor: function addCompetitor() {
      var _this = this;
      this.$refs.competitor_form.validate(function (valid) {
        if (valid) {
          var competitor = Object.assign({}, _this.competitor_form);
          _this.competitors.push(competitor);
          _this.resetCompetitorForm();
          _this.addCompetitorDialog = false;
        } else {
          _this.loading = false;
          return false;
        }
      });
    },
    resetCompetitorForm: function resetCompetitorForm() {
      this.competitor_form = {
        title: '',
        their_offering: '',
        your_offering: '',
        category: '',
        segments: [],
        threat: ''
      };
    },
    addCompetitorFeature: function addCompetitorFeature() {
      var _this2 = this;
      this.$refs.competitor_feature_form.validate(function (valid) {
        if (valid) {
          var competitor_feature = Object.assign({}, _this2.competitor_feature_form);
          _this2.selectedFeature.competitor_features.push(competitor_feature);
          _this2.resetCompetitorFeatureForm();
          _this2.addCompetitorFeatureDialog = false;
        } else {
          _this2.loading = false;
          return false;
        }
      });
    },
    resetCompetitorFeatureForm: function resetCompetitorFeatureForm() {
      this.competitor_feature_form = {
        competitor: {
          id: '1',
          title: ''
        },
        feature: '',
        category: '',
        maturity: '',
        threat: '',
        strength: ''
      };
    },
    openAddCompetitorFeatureDialog: function openAddCompetitorFeatureDialog() {
      this.competitor_feature_form.feature = this.selectedFeature.title;
      this.addCompetitorFeatureDialog = true;
    },
    openCompetitorDialog: function openCompetitorDialog() {
      // TODO check with Justin before creating 'Feature competitive profile MODAL'
      // console.log('In')
    },
    resetFilter: function resetFilter() {
      this.filter = {
        offering: '',
        product: '',
        release: '',
        version: '',
        status: '',
        phase: '',
        enabler: ''
      };
    },
    selectFeature: function selectFeature(row, column, event) {
      this.selectedFeature = row;
      this.featureDetailDialog = !this.featureDetailDialog;
    },
    computeHighThreatStyle: function computeHighThreatStyle(threat) {
      if (threat.trim().toLowerCase() === 'high') {
        return 'red';
      }
    },
    computeMediumThreatStyle: function computeMediumThreatStyle(threat) {
      if (threat.trim().toLowerCase() === 'high' || threat.trim().toLowerCase() === 'medium') {
        return 'red';
      }
    },
    computeLowThreatStyle: function computeLowThreatStyle(threat) {
      if (threat.trim().toLowerCase() === 'high' || threat.trim().toLowerCase() === 'medium' || threat.trim().toLowerCase() === 'low') {
        return 'red';
      }
    },
    computeStyle: function computeStyle(category) {
      switch (category) {
        case 'Direct':
          return 'background-color:#7030a0;color:white;height:30px;font-size:10px;';
        case 'Near':
          return 'background-color:#2e75b5;color:white;height:30px;font-size:10px;';
        case 'Peripheral':
          return 'background-color:#d8d8d8;height:30px;font-size:10px;';
      }
    },
    retrieveOfferings: function retrieveOfferings(feature_id) {
      var _this3 = this;
      this.loading = true;
      this.offerings = [];
      this.$apollo.query({
        // Query to get all features of offering from given feature id
        // Feature->Product->Offering->Products->Features
        query: window._gql(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)([" query ProductFeature($id : String)\n        {\n        ProductFeature(id: $id)\n        {\n          id\n          linkedProducts(optional: true) {\n            product(optional: true) {\n              id\n              linkedOfferings(optional: true) {\n                offering(optional: true) {\n                  id\n                  linkedProducts(optional: true) {\n                    product(optional: true) {\n                      id\n                      linkedFeatures(optional: true) {\n                        productFeature(optional: true) {\n                          id\n                        }\n                      }\n                    }\n                  }\n                }\n              }\n            }\n          }\n        }\n      }"]))),
        fetchPolicy: 'no-cache',
        variables: {
          'id': feature_id
        }
      }).then(function (data) {
        _this3.$notify({
          title: 'Success',
          message: 'Retrieved Successfully',
          type: 'success',
          duration: 2000
        });
        _this3.offerings = data.data.ProductFeature.select;
        _this3.loading = false;
      }).catch(function (error) {
        // Error
        _this3.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this3.loading = false;
      });
    },
    retrieveFeatures: function retrieveFeatures(page_number) {
      var _this4 = this;
      this.loading = true;
      this.features = [];
      this.$apollo.query({
        // Query
        query: window._gql(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)([" query ProductFeatures($page: Page)\n        {\n        ProductFeatures(page: $page)\n        {\n          pages\n          total\n          select{\n            id\n            title\n            description\n            status\n            complexity\n            priority\n          }\n        }\n      }"]))),
        fetchPolicy: 'no-cache',
        variables: {
          'page': {
            'limit': 20,
            'start': page_number
          }
        }
      }).then(function (data) {
        _this4.$notify({
          title: 'Success',
          message: 'Retrieved Successfully',
          type: 'success',
          duration: 2000
        });
        _this4.features = data.data.ProductFeatures.select;
        _this4.loading = false;
      }).catch(function (error) {
        // Error
        _this4.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this4.loading = false;
      });
    }
  }
};