"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("el-row", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("transition", {
    attrs: {
      css: false
    },
    on: {
      "before-enter": _vm.beforeEnter,
      enter: _vm.enter,
      leave: _vm.leave
    }
  }, [_c("div", [_vm.selectedOffering && _vm.selectedOffering.id && _vm.active_tab === "strategy" ? _c("el-card", [_vm.selectedOffering && _vm.selectedOffering.id && _vm.active_tab === "strategy" ? _c("strategy-component", {
    attrs: {
      offering: _vm.selectedOffering
    }
  }) : _vm._e()], 1) : _vm._e(), _vm._v(" "), _vm.selectedOffering && _vm.selectedOffering.id && _vm.active_tab === "design" ? _c("design-component", {
    attrs: {
      offering: _vm.selectedOffering
    },
    on: {
      "refresh-offering": _vm.reloadOfferings
    }
  }) : _vm._e(), _vm._v(" "), _vm.selectedOffering && _vm.selectedOffering.id && _vm.active_tab === "refine_features" ? _c("refine-feature-component", {
    attrs: {
      offering: _vm.selectedOffering
    }
  }) : _vm._e(), _vm._v(" "), _vm.selectedOffering && _vm.selectedOffering.id && _vm.active_tab === "deliveryprogress" ? _c("delivery-progress-component", {
    attrs: {
      "offering-id": _vm.selectedOffering.id
    }
  }) : _vm._e(), _vm._v(" "), _vm.active_tab === "feature_backlog" || _vm.active_tab === "need" || _vm.active_tab === "feature" || _vm.active_tab === "offering_admin" || _vm.active_tab === "offering_risks" || _vm.active_tab === "ideas" || _vm.active_tab === "external_tickets" ? _c("el-card", [_vm.selectedOffering && _vm.selectedOffering.id && _vm.active_tab === "feature_backlog" ? _c("feature-backlog-component", {
    attrs: {
      offering: _vm.selectedOffering
    },
    on: {
      "refresh-offering": _vm.reloadOfferings
    }
  }) : _vm._e(), _vm._v(" "), _vm.selectedOffering && _vm.selectedOffering.id && _vm.active_tab === "need" ? _c("need-component", {
    attrs: {
      offering: _vm.selectedOffering
    }
  }) : _vm._e(), _vm._v(" "), _vm.selectedOffering && _vm.selectedOffering.id && _vm.active_tab === "feature" ? _c("feature-component", {
    attrs: {
      offering: _vm.selectedOffering
    }
  }) : _vm._e(), _vm._v(" "), _vm.selectedOffering && _vm.selectedOffering.id && _vm.active_tab === "offering_admin" ? _c("administer-component", {
    attrs: {
      offering: _vm.selectedOffering,
      offerings: _vm.offerings
    }
  }) : _vm._e(), _vm._v(" "), _vm.selectedOffering && _vm.selectedOffering.id && _vm.active_tab === "offering_risks" ? _c("risk-component", {
    attrs: {
      offering: _vm.selectedOffering
    }
  }) : _vm._e(), _vm._v(" "), _vm.selectedOffering && _vm.selectedOffering.id && _vm.active_tab === "ideas" ? _c("idea-component", {
    attrs: {
      offering: _vm.selectedOffering
    }
  }) : _vm._e(), _vm._v(" "), _vm.selectedOffering && _vm.selectedOffering.id && _vm.active_tab === "external_tickets" ? _c("external-data-component", {
    attrs: {
      offering: _vm.selectedOffering
    }
  }) : _vm._e()], 1) : _vm._e()], 1)])], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;