"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getContrastYIQ = getContrastYIQ;
exports.hexOpacityToRGBAlpha = hexOpacityToRGBAlpha;
exports.rgba2hex = rgba2hex;
exports.shadeRGBColor = shadeRGBColor;
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.regexp.match");
require("core-js/modules/es6.regexp.split");
// RGB Color lighten
// var color1 = "rbg(63,131,163)";
// var lighterColor = shadeRGBColor(color1, 0.5);  //  rgb(159,193,209)
// var darkerColor = shadeRGBColor(color1, -0.25); //  rgb(47,98,122)
function shadeRGBColor(color, percent) {
  var f = color.split(',');
  var t = percent < 0 ? 0 : 255;
  var p = percent < 0 ? percent * -1 : percent;
  var R = parseInt(f[0].slice(4));
  var G = parseInt(f[1]);
  var B = parseInt(f[2]);
  return 'rgb(' + (Math.round((t - R) * p) + R) + ',' + (Math.round((t - G) * p) + G) + ',' + (Math.round((t - B) * p) + B) + ')';
}
function rgba2hex(orig) {
  var a;
  var rgb = orig.replace(/\s/g, '').match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i);
  var alpha = (rgb && rgb[4] || '').trim();
  var hex = rgb ? (rgb[1] | 1 << 8).toString(16).slice(1) + (rgb[2] | 1 << 8).toString(16).slice(1) + (rgb[3] | 1 << 8).toString(16).slice(1) : orig;
  if (alpha !== '') {
    a = alpha;
  } else {
    a = 1;
  }
  // multiply before convert to HEX
  a = (a * 255 | 1 << 8).toString(16).slice(1);
  hex = hex + a;
  return hex;
}
function hexOpacityToRGBAlpha(hexOpacity) {
  // Convert the hex opacity to a decimal value
  var decimalOpacity = parseInt(hexOpacity, 16);

  // Normalize the decimal value to the range 0 to 1
  var alpha = decimalOpacity / 255.0;
  return alpha;
}
function getContrastYIQ(hexcolor) {
  var r = parseInt(hexcolor.substr(0, 2), 16);
  var g = parseInt(hexcolor.substr(2, 2), 16);
  var b = parseInt(hexcolor.substr(4, 2), 16);
  var yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? 'black' : 'white';
}