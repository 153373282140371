"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    attrs: {
      id: "ContextMenuContainer"
    },
    on: {
      contextmenu: function contextmenu($event) {
        $event.preventDefault();
        return _vm.$refs.menu.open($event, "Payload");
      }
    }
  }, [_c("ContextMenu", {
    ref: "menu",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var contextData = _ref.contextData;
        return [_c("ContextMenuItem", {
          nativeOn: {
            click: function click($event) {
              return _vm.$refs.menu.close.apply(null, arguments);
            }
          }
        }, [_vm._v("\n        Action 1 " + _vm._s(contextData) + "\n      ")]), _vm._v(" "), _c("ContextMenuItem", {
          nativeOn: {
            click: function click($event) {
              return _vm.$refs.menu.close.apply(null, arguments);
            }
          }
        }, [_vm._v("\n        Action 2 " + _vm._s(contextData) + "\n      ")]), _vm._v(" "), _c("ContextMenuItem", {
          nativeOn: {
            click: function click($event) {
              return _vm.$refs.menu.close.apply(null, arguments);
            }
          }
        }, [_vm._v("\n        Action 3 " + _vm._s(contextData) + "\n      ")]), _vm._v(" "), _c("ContextMenuItem", {
          nativeOn: {
            click: function click($event) {
              return _vm.$refs.menu.close.apply(null, arguments);
            }
          }
        }, [_vm._v("\n        Action 4 " + _vm._s(contextData) + "\n      ")])];
      }
    }])
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;