"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/taggedTemplateLiteral.js"));
var _templateObject;
var _default = exports.default = {
  name: 'VentureIdeaCreate',
  components: {},
  props: {},
  data: function data() {
    var validateTitle = function validateTitle(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter a valid title'));
      } else {
        callback();
      }
    };
    var validateDescription = function validateDescription(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter a valid description'));
      } else {
        callback();
      }
    };
    var validateCustomerType = function validateCustomerType(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter a valid customer type'));
      } else {
        callback();
      }
    };
    var validateCustomerTypeDescription = function validateCustomerTypeDescription(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter a valid description'));
      } else {
        callback();
      }
    };
    var validateProblemStatement = function validateProblemStatement(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter a valid problemstatement'));
      } else {
        callback();
      }
    };
    var validateSolution = function validateSolution(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter a valid solution'));
      } else {
        callback();
      }
    };
    var validateValueProposition = function validateValueProposition(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter a valid valueproposition'));
      } else {
        callback();
      }
    };
    return {
      ventureRulesStep1: {
        title: [{
          required: true,
          trigger: 'blur',
          validator: validateTitle
        }],
        description: [{
          required: true,
          trigger: 'blur',
          validator: validateDescription
        }],
        customer_type: [{
          required: true,
          trigger: 'blur',
          validator: validateCustomerType
        }],
        customer_type_description: [{
          required: true,
          trigger: 'blur',
          validator: validateCustomerTypeDescription
        }]
      },
      ventureRulesStep2: {
        problemstatement: [{
          required: true,
          trigger: 'blur',
          validator: validateProblemStatement
        }],
        solution: [{
          required: true,
          trigger: 'blur',
          validator: validateSolution
        }],
        valueproposition: [{
          required: true,
          trigger: 'blur',
          validator: validateValueProposition
        }]
      },
      loading: false,
      activeStep: 'step1',
      form: {
        title: '',
        description: '',
        customer_type: '',
        customer_type_description: '',
        problemstatement: '',
        solution: '',
        valueproposition: ''
      }
    };
  },
  computed: {},
  mounted: function mounted() {
    this.$emit('title', 'New Venture Idea', 'New ventures propose a new Customer Type, Journey and Offering.');
  },
  methods: {
    createVenture: function createVenture() {
      var _this = this;
      this.loading = true;
      this.$apollo.mutate({
        // Query
        mutation: window._gql(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["mutation createBusinessIdea($busIdea: CreateBusinessIdeaInput!)\n        {\n          createBusinessIdea(input: $busIdea) {\n            id\n            title\n            description\n            problemstatement\n            solution\n            valueproposition\n            additionalattributes\n            fileobject\n            status\n            score\n            customer_type\n            scope\n            scopename\n            type\n            linkedCoreParties(optional: true){\n              coreParty(optional: true){\n                id\n                businessname\n              }\n            }\n            linkedOfferings(optional: true){\n              offering(optional: true){\n                id\n                title\n                description\n              }\n            }\n            linkedJourneyMaps(optional: true){\n              journeyMap(optional: true){\n                id\n                title\n                description\n              }\n            }\n            linkedCustomerTypes(optional: true){\n              customerType(optional: true){\n                id\n                title\n                description\n              }\n            }\n          }\n        }"]))),
        // Parameters
        variables: {
          'busIdea': {
            'title': this.form.title,
            'description': this.form.description,
            'problemstatement': this.form.problemstatement,
            'solution': this.form.solution,
            'valueproposition': this.form.valueproposition,
            'fileobject': [],
            'status': 'Proposed',
            'score': 0,
            'customer_type': this.form.customer_type,
            'type': 'venture',
            'additionalattributes': {
              'status': false,
              'customer_type': {
                'title': this.form.customer_type,
                'description': this.form.customer_type_description
              }
            }
          }
        }
      }).then(function (data) {
        _this.$notify({
          title: 'Success',
          message: 'Created Successfully',
          type: 'success',
          duration: 2000
        });
        var idea = data.data.createBusinessIdea;
        _this.$emit('create', idea);
        _this.resetForm();
        _this.loading = false;
        _this.$emit('close');
      }).catch(function (error) {
        // Error
        _this.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this.loading = false;
      });
    },
    goToStep: function goToStep(step) {
      var _this2 = this;
      switch (step) {
        case 'step1':
          this.$emit('title', 'New Venture Idea', 'New ventures propose a new Customer Type, Journey and Offering.');
          this.activeStep = 'step1';
          break;
        case 'step2':
          this.$refs.form_step1.validate(function (valid) {
            if (valid) {
              _this2.$emit('title', 'New Venture', 'Describe the Problem, Solution & Value');
              _this2.activeStep = 'step2';
            } else {
              _this2.loading = false;
              return false;
            }
          });
          break;
        case 'step3':
          this.$refs.form_step2.validate(function (valid) {
            if (valid) {
              _this2.$emit('title', 'New Venture', 'Create the Idea');
              _this2.activeStep = 'step3';
            } else {
              _this2.loading = false;
              return false;
            }
          });
          break;
      }
    },
    resetForm: function resetForm() {
      this.activeStep = 'step1';
      this.form = {
        title: '',
        description: '',
        customer_type: '',
        status: 'Proposed',
        customer_type_description: '',
        problemstatement: '',
        solution: '',
        valueproposition: ''
      };
    }
  }
};