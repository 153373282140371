"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.replace");
var _swimlanes = _interopRequireDefault(require("./product-roadmap/swimlanes.vue"));
var _phases = _interopRequireDefault(require("./product-roadmap/phases.vue"));
var _goals = _interopRequireDefault(require("./product-roadmap/goals.vue"));
var _vueSwatches = _interopRequireDefault(require("vue-swatches"));
var _default = exports.default = {
  name: 'StrategyRoadmap',
  components: {
    RoadmapSwimlaneComponent: _swimlanes.default,
    RoadmapPhaseComponent: _phases.default,
    RoadmapGoalComponent: _goals.default,
    Swatches: _vueSwatches.default
  },
  props: {},
  data: function data() {
    return {
      colorDialog: false,
      highlighter: {
        x: 0,
        y: 0,
        width: 0,
        height: 0
      },
      color_form: {
        color: ''
      },
      phase_form: {
        name: '',
        description: '',
        color: ''
      },
      lane_form: {
        name: '',
        description: '',
        color: ''
      },
      goalDetailsDialog: false,
      addPhaseDialog: false,
      addLaneDialog: false,
      roadmap_form: {
        phase: '',
        lane: '',
        goal: {}
      },
      strategic_goals_pos: [],
      strategic_goals: [{
        goal: 'Prow scuttle parrel provost Sail.',
        strategy: 'Prow scuttle parrel provost Sail ho shrouds spirits boom mizzenmast yardarm. Pinnace holystone mizzenmast quarter crows nest nipperkin grog yardarm hempen halter furl. Swab barque interloper chantey doubloon starboard grog black jack gangway rutters.',
        priority: 'high',
        actions: [{
          action: '123',
          description: '1234',
          assigned_to: 'John',
          priority: 'high'
        }],
        achieved: false
      }, {
        goal: 'Trysail Sail ho Corsair red ensign.',
        strategy: 'Trysail Sail ho Corsair red ensign hulk smartly boom jib rum gangway. Case shot Shiver me timbers gangplank crack Jennys tea cup ballast Blimey lee snow crows nest rutters. Fluke jib scourge of the seven seas boatswain schooner gaff booty Jack Tar transom spirits',
        priority: 'low',
        actions: [{
          action: '123',
          description: '1234',
          assigned_to: 'John',
          priority: 'high'
        }],
        achieved: false
      }],
      defaultProps: {
        children: 'actions',
        label: 'name'
      },
      roadmap: {
        start: {
          month: 3,
          year: 2020
        },
        phases: [{
          name: 'Phase 1',
          description: 'lorem ipsum',
          color: '#00BFBF',
          highlight: false,
          start: {
            month: 3,
            year: 2020
          },
          end: {
            month: 6,
            year: 2020
          }
        }],
        lanes: [{
          name: 'Managment',
          description: 'lorem ipsum',
          color: '#ffffff',
          highlight: false
        }, {
          name: 'Services',
          description: 'lorem ipsum',
          color: '#ffffff',
          highlight: false
        }, {
          name: 'Build',
          description: 'lorem ipsum',
          color: '#ffffff',
          highlight: false
        }],
        goals: [{
          phase: 'Phase 1',
          lane: 'Managment',
          goal: {
            goal: 'Prow scuttle parrel provost Sail.',
            strategy: 'Prow scuttle parrel provost Sail ho shrouds spirits boom mizzenmast yardarm. Pinnace holystone mizzenmast quarter crows nest nipperkin grog yardarm hempen halter furl. Swab barque interloper chantey doubloon starboard grog black jack gangway rutters.',
            priority: 'high',
            actions: [{
              action: '123',
              description: '1234',
              assigned_to: 'John',
              priority: 'high'
            }],
            achieved: false
          }
        }]
      },
      activeDragElement: {},
      months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      activeGoal: {}
    };
  },
  mounted: function mounted() {
    this.$eventHub.$emit('process-step-load', 'business-roadmap');

    // Compute Strategic goal pos
    for (var i = 0; i < this.strategic_goals.length; i++) {
      var goal_pos = {};
      goal_pos.x = 1375;
      goal_pos.y = 75 + i * 40;
      this.strategic_goals_pos.push(goal_pos);
    }
  },
  methods: {
    openColorDialog: function openColorDialog(roadmap_component) {
      this.color_form = roadmap_component;
      this.colorDialog = true;
    },
    computeGoalDataText: function computeGoalDataText(goal_text) {
      return goal_text.substring(0, 20) + '...';
    },
    onGoalDataDragged: function onGoalDataDragged(_ref) {
      var el = _ref.el,
        deltaX = _ref.deltaX,
        deltaY = _ref.deltaY,
        offsetX = _ref.offsetX,
        offsetY = _ref.offsetY,
        clientX = _ref.clientX,
        clientY = _ref.clientY,
        first = _ref.first,
        last = _ref.last;
      // Should be before deltaX and deltaY undefined check.
      if (last !== undefined && last) {
        var index = el.id.replace('goal_data_', '');
        var swimlane_res = this.isSwimlaneIntersect(this.strategic_goals_pos[index]);
        var phase_res = this.isPhaseIntersect(this.strategic_goals_pos[index]);
        if (swimlane_res !== null && phase_res !== null) {
          this.roadmap_form.lane = this.roadmap.lanes[swimlane_res].name;
          this.roadmap_form.phase = this.roadmap.phases[phase_res].name;
          this.insertGoalIntoRoadmap();
          this.strategic_goals_pos.splice(index, 1);
        } else {
          // Reset goal to its container
          this.strategic_goals_pos[index].x = 1375;
          this.strategic_goals_pos[index].y = 75 + index * 40;
        }
        // If last, Check if the goal is inside any matrix. Else reset

        this.resetAllSwimlaneColor();
        this.resetAllPhaseColor();
        this.resetHighlightActiveBlock();
        return;
      }
      if (deltaX === undefined || deltaY === undefined) {
        return;
      }
      var goal_data_index = el.id.replace('goal_data_', '');
      this.activeGoal = this.strategic_goals[goal_data_index];
      this.strategic_goals_pos[goal_data_index].x += parseInt(deltaX);
      this.strategic_goals_pos[goal_data_index].y += parseInt(deltaY);

      // If in a swimlane, highlight swimlane
      var swimlane_res_index = this.isSwimlaneIntersect(this.strategic_goals_pos[goal_data_index]);
      var phase_res_index = this.isPhaseIntersect(this.strategic_goals_pos[goal_data_index]);
      this.resetAllPhaseColor();
      this.resetAllSwimlaneColor();
      this.resetHighlightActiveBlock();
      if (swimlane_res_index !== null && phase_res_index !== null) {
        this.highlightActiveBlock(swimlane_res_index, phase_res_index);
      }

      // If in both phase and a swimlane
      // Handled via the if(last)
    },
    highlightActiveBlock: function highlightActiveBlock(lane_index, phase_index) {
      this.highlighter = {
        x: this.computePhaseX(phase_index),
        y: 100 + lane_index * 175,
        width: this.computePhaseWidth(phase_index),
        height: 150
      };
    },
    resetAllSwimlaneColor: function resetAllSwimlaneColor() {
      window._.forEach(this.roadmap.lanes, function (lane) {
        lane.highlight = false;
      });
    },
    resetAllPhaseColor: function resetAllPhaseColor() {
      window._.forEach(this.roadmap.phases, function (phase) {
        phase.highlight = false;
      });
    },
    resetHighlightActiveBlock: function resetHighlightActiveBlock() {
      this.highlighter = {
        x: 0,
        y: 0,
        width: 0,
        height: 0
      };
    },
    isSwimlaneIntersect: function isSwimlaneIntersect(goal) {
      // check if start and end of goal rect inside a swimlane
      for (var i = 0; i < this.roadmap.lanes.length; i++) {
        if (goal.x > 0 && goal.x + 200 < 1250 && goal.y > 100 + i * 175 && goal.y + 30 < 250 + i * 175) {
          return i;
        }
      }
      return null;
    },
    isPhaseIntersect: function isPhaseIntersect(goal) {
      for (var i = 0; i < this.roadmap.phases.length; i++) {
        if (goal.y > 0 && goal.y + 30 < this.computeVerticalLaneHeight() && goal.x > this.computePhaseX(i) && goal.x + 200 < this.computePhaseX(i) + this.computePhaseWidth(i)) {
          return i;
        }
      }
      return null;
    },
    getGoalX: function getGoalX(goal, index) {
      for (var i = 0; i < this.roadmap.phases.length; i++) {
        if (this.roadmap.phases[i].name === goal.phase) {
          return this.computePhaseX(i);
        }
      }
    },
    getGoalY: function getGoalY(goal, index) {
      for (var i = 0; i < this.roadmap.lanes.length; i++) {
        if (this.roadmap.lanes[i].name === goal.lane) {
          return 100 + i * 175;
        }
      }
    },
    computeGoalWidth: function computeGoalWidth(goal, index) {
      for (var i = 0; i < this.roadmap.phases.length; i++) {
        if (this.roadmap.phases[i].name === goal.phase) {
          return this.computePhaseWidth(i);
        }
      }
    },
    insertGoalIntoRoadmap: function insertGoalIntoRoadmap() {
      var roadmap_goal = Object.assign({}, this.roadmap_form);
      roadmap_goal.goal = Object.assign({}, this.activeGoal);
      this.roadmap.goals.push(roadmap_goal);
      this.roadmapDropDialog = false;
    },
    setActiveDragElement: function setActiveDragElement(_ref2) {
      var el = _ref2.el,
        deltaX = _ref2.deltaX,
        deltaY = _ref2.deltaY,
        offsetX = _ref2.offsetX,
        offsetY = _ref2.offsetY,
        clientX = _ref2.clientX,
        clientY = _ref2.clientY,
        first = _ref2.first,
        last = _ref2.last;
      if (deltaX === undefined || deltaY === undefined) {
        return;
      }
      var index = el.id.replace('goal_tag_', '');
      this.activeGoal = this.strategic_goals[index];
    },
    handleDragOver: function handleDragOver(event) {
      event.preventDefault(); // !important
    },
    showGoalDetailsDialog: function showGoalDetailsDialog(goal) {
      this.activeGoal = goal;
      this.goalDetailsDialog = true;
    },
    handleDragStart: function handleDragStart(node, ev) {
      this.activeDragElement = node;
    },
    generateGoalTagID: function generateGoalTagID(index) {
      return 'goal_tag_' + index;
    },
    generateGoalID: function generateGoalID(index) {
      return 'goal_' + index;
    },
    generatePhaseID: function generatePhaseID(index) {
      return 'phase_' + index;
    },
    generateGoalDataID: function generateGoalDataID(index) {
      return 'goal_data_' + index;
    },
    computePhaseX: function computePhaseX(index) {
      var months = this.roadmap.phases[index].start.month - this.roadmap.start.month;
      return months * 75 + 100;
    },
    computePhaseWidth: function computePhaseWidth(index) {
      var months = this.roadmap.phases[index].end.month - this.roadmap.phases[index].start.month;
      return (months + 1) * 75;
    },
    startPhaseMove: function startPhaseMove(_ref3) {
      var el = _ref3.el,
        deltaX = _ref3.deltaX,
        deltaY = _ref3.deltaY,
        offsetX = _ref3.offsetX,
        offsetY = _ref3.offsetY,
        clientX = _ref3.clientX,
        clientY = _ref3.clientY,
        first = _ref3.first,
        last = _ref3.last;
      if (deltaX === undefined || deltaY === undefined) {
        return;
      }
      var index = el.id.replace('phase_', '');
      this.roadmap.phases[index].start.month += Math.round(offsetX / 75);
      this.roadmap.phases[index].end.month += Math.round(offsetX / 75);
    },
    computeMonthName: function computeMonthName(index) {
      if (this.roadmap.start.month - 1 + index > 11) {
        return this.months[this.roadmap.start.month - 1 + index - 12];
      }
      return this.months[this.roadmap.start.month - 1 + index];
    },
    computeVerticalLaneHeight: function computeVerticalLaneHeight() {
      return 175 * this.roadmap.lanes.length + 75;
    },
    onClickDefineBusiness: function onClickDefineBusiness() {
      this.$emit('go-prev');
    },
    onClickBusinessModelCanvas: function onClickBusinessModelCanvas() {
      this.$emit('go-next');
    },
    addLane: function addLane() {
      var lane_data = Object.assign({}, this.lane_form);
      var lane = {
        name: lane_data.name,
        description: lane_data.description,
        color: lane_data.color,
        highlight: false
      };
      this.roadmap.lanes.push(lane);
      this.lane_form.name = '';
      this.lane_form.description = '';
      this.addLaneDialog = false;
    },
    addPhase: function addPhase() {
      var phase_data = Object.assign({}, this.phase_form);
      var phase = {
        name: phase_data.name,
        description: phase_data.description,
        color: phase_data.color,
        highlight: false,
        start: {
          month: 8,
          year: 2020
        },
        end: {
          month: 10,
          year: 2020
        }
      };
      this.roadmap.phases.push(phase);
      this.phase_form.name = '';
      this.phase_form.description = '';
      this.addPhaseDialog = false;
    },
    movePhaseBack: function movePhaseBack(index) {
      this.roadmap.phases[index].start.month--;
      this.roadmap.phases[index].end.month--;
    },
    movePhaseFront: function movePhaseFront(index) {
      this.roadmap.phases[index].start.month++;
      this.roadmap.phases[index].end.month++;
    }
  }
};