"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ForceDirectedTree = _interopRequireDefault(require("@/components/Charts/ForceDirectedTree"));
var _default = exports.default = {
  name: 'CompetitiveLandscape',
  components: {
    ForceDirectedTreeComponent: _ForceDirectedTree.default
  },
  props: {},
  data: function data() {
    return {
      custom_forces: {
        Center: [200, 200],
        Collide: [1]
      },
      activeFeature: '',
      activeFeatureThreat: {},
      features: [{
        value: 'Feature 1',
        label: 'Feature 1',
        threats: {
          nodes: [{
            id: 1,
            name: 'Feature 1',
            _color: 'orange',
            _size: '15'
          }, {
            id: 2,
            name: 'Competitor 1'
          }, {
            id: 3,
            name: 'Competitor 2'
          }, {
            id: 4,
            name: 'Competitor 3'
          }],
          links: [{
            sid: 1,
            tid: 2
          }, {
            sid: 1,
            tid: 3
          }, {
            sid: 1,
            tid: 4
          }],
          options: {
            canvas: false,
            force: 6000,
            nodeSize: 5,
            nodeLabels: true,
            linkLabels: true,
            strLinks: true
          }
        }
      }, {
        value: 'Feature 2',
        label: 'Feature 2',
        threats: {
          nodes: [{
            id: 1,
            name: 'Feature 2',
            _color: 'orange',
            _size: '15'
          }, {
            id: 2,
            name: 'Competitor 4'
          }, {
            id: 3,
            name: 'Competitor 5'
          }, {
            id: 4,
            name: 'Competitor 6'
          }],
          links: [{
            sid: 1,
            tid: 2
          }, {
            sid: 1,
            tid: 3
          }, {
            sid: 1,
            tid: 4
          }],
          options: {
            canvas: false,
            force: 6000,
            nodeSize: 5,
            nodeLabels: true,
            linkLabels: true,
            strLinks: true
          }
        }
      }, {
        value: 'Feature 3',
        label: 'Feature 3',
        threats: {
          nodes: [{
            id: 1,
            name: 'Feature 3',
            _color: 'orange',
            _size: '15'
          }, {
            id: 2,
            name: 'Competitor 7'
          }, {
            id: 3,
            name: 'Competitor 8'
          }, {
            id: 4,
            name: 'Competitor 9'
          }],
          links: [{
            sid: 1,
            tid: 2
          }, {
            sid: 1,
            tid: 3
          }, {
            sid: 1,
            tid: 4
          }],
          options: {
            canvas: false,
            force: 6000,
            nodeSize: 5,
            nodeLabels: true,
            linkLabels: true,
            strLinks: true
          }
        }
      }],
      feature_threats1: {
        nodes: [{
          id: 1,
          name: 'Prodiggi',
          _color: 'orange',
          _size: '15'
        }, {
          id: 2,
          name: 'Feature 1',
          _color: 'green',
          _size: '10'
        }, {
          id: 3,
          name: 'Feature 2',
          _color: 'green',
          _size: '10'
        }, {
          id: 4,
          name: 'Feature 3',
          _color: 'green',
          _size: '10'
        }, {
          id: 5,
          name: 'Competitor 1'
        }, {
          id: 6,
          name: 'Competitor 2'
        }, {
          id: 7,
          name: 'Competitor 3'
        }, {
          id: 8,
          name: 'Competitor 4'
        }, {
          id: 9,
          name: 'Competitor 5'
        }, {
          id: 10,
          name: 'Competitor 6'
        }, {
          id: 11,
          name: 'Competitor 7'
        }, {
          id: 12,
          name: 'Competitor 8'
        }, {
          id: 13,
          name: 'Competitor 9'
        }, {
          id: 14,
          name: 'Competitor 10'
        }, {
          id: 15,
          name: 'Competitor 11'
        }, {
          id: 16,
          name: 'Competitor 12'
        }, {
          id: 17,
          name: 'Competitor 13'
        }, {
          id: 18,
          name: 'Competitor 14'
        }, {
          id: 19,
          name: 'Competitor 15'
        }, {
          id: 20,
          name: 'Competitor 16'
        }, {
          id: 21,
          name: 'Competitor 17'
        }, {
          id: 22,
          name: 'Competitor 18'
        }, {
          id: 23,
          name: 'Competitor 19'
        }, {
          id: 24,
          name: 'Competitor 20'
        }, {
          id: 25,
          name: 'Competitor 21'
        }],
        links: [{
          sid: 1,
          tid: 2,
          _color: 'red'
        }, {
          sid: 1,
          tid: 3,
          _color: 'red'
        }, {
          sid: 1,
          tid: 4,
          _color: 'red'
        }, {
          sid: 2,
          tid: 6
        }, {
          sid: 2,
          tid: 8
        }, {
          sid: 2,
          tid: 10
        }, {
          sid: 2,
          tid: 12
        }, {
          sid: 2,
          tid: 14
        }, {
          sid: 2,
          tid: 16
        }, {
          sid: 2,
          tid: 18
        }, {
          sid: 3,
          tid: 5
        }, {
          sid: 3,
          tid: 7
        }, {
          sid: 3,
          tid: 9
        }, {
          sid: 3,
          tid: 11
        }, {
          sid: 3,
          tid: 13
        }, {
          sid: 3,
          tid: 15
        }, {
          sid: 3,
          tid: 17
        }, {
          sid: 4,
          tid: 19
        }, {
          sid: 4,
          tid: 20
        }, {
          sid: 4,
          tid: 21
        }, {
          sid: 4,
          tid: 22
        }, {
          sid: 4,
          tid: 23
        }, {
          sid: 4,
          tid: 24
        }, {
          sid: 4,
          tid: 25
        }],
        options: {
          canvas: false,
          force: 1000,
          nodeSize: 5,
          nodeLabels: true,
          linkLabels: true,
          strLinks: true
        }
      },
      corporate_threats: {
        nodes: [{
          id: 1,
          name: 'Prodiggi',
          _color: 'orange',
          _size: '15',
          _width: '30'
        }, {
          id: 2,
          name: 'Competitor 1',
          _color: 'gray',
          _size: '10'
        }, {
          id: 3,
          name: 'Competitor 2',
          _color: 'gray',
          _size: '10'
        }, {
          id: 4,
          name: 'Competitor 3',
          _color: 'gray',
          _size: '10'
        }, {
          id: 5,
          name: 'Competitor 4',
          _color: 'gray',
          _size: '10'
        }, {
          id: 6,
          name: 'Feature 1',
          _color: '#bdbdbd'
        }, {
          id: 7,
          name: 'Feature 2',
          _color: '#bdbdbd'
        }, {
          id: 8,
          name: 'Feature 3',
          _color: '#bdbdbd'
        }, {
          id: 9,
          name: 'Feature 4',
          _color: '#bdbdbd'
        }, {
          id: 10,
          name: 'Feature 5',
          _color: '#bdbdbd'
        }, {
          id: 11,
          name: 'Feature 6',
          _color: '#bdbdbd'
        }, {
          id: 12,
          name: 'Feature 7',
          _color: '#bdbdbd'
        }, {
          id: 13,
          name: 'Feature 8',
          _color: '#bdbdbd'
        }, {
          id: 14,
          name: 'Feature 9',
          _color: '#bdbdbd'
        }, {
          id: 15,
          name: 'Feature 10',
          _color: '#bdbdbd'
        }, {
          id: 16,
          name: 'Feature 11',
          _color: '#bdbdbd'
        }, {
          id: 17,
          name: 'Feature 12',
          _color: '#bdbdbd'
        }, {
          id: 18,
          name: 'Feature 13',
          _color: '#bdbdbd'
        }, {
          id: 19,
          name: 'Feature 14',
          _color: '#bdbdbd'
        }, {
          id: 20,
          name: 'Feature 15',
          _color: '#bdbdbd'
        }, {
          id: 21,
          name: 'Feature 16',
          _color: '#bdbdbd'
        }, {
          id: 22,
          name: 'Feature 17',
          _color: '#bdbdbd'
        }, {
          id: 23,
          name: 'Feature 18',
          _color: '#bdbdbd'
        }, {
          id: 24,
          name: 'Feature 19',
          _color: '#bdbdbd'
        }, {
          id: 25,
          name: 'Feature 20',
          _color: '#bdbdbd'
        }, {
          id: 26,
          name: 'Feature 21',
          _color: '#bdbdbd'
        }, {
          id: 27,
          name: 'Feature 22',
          _color: '#bdbdbd'
        }, {
          id: 28,
          name: 'Feature 23',
          _color: '#bdbdbd'
        }, {
          id: 29,
          name: 'Feature 24',
          _color: '#bdbdbd'
        }, {
          id: 30,
          name: 'Feature 25',
          _color: '#bdbdbd'
        }, {
          id: 31,
          name: 'Feature 26',
          _color: '#bdbdbd'
        }, {
          id: 32,
          name: 'Feature 27',
          _color: '#bdbdbd'
        }],
        links: [{
          sid: 1,
          tid: 2,
          _color: 'gray'
        }, {
          sid: 1,
          tid: 3,
          _color: 'gray'
        }, {
          sid: 1,
          tid: 4,
          _color: 'gray'
        }, {
          sid: 1,
          tid: 5,
          _color: 'gray'
        }, {
          sid: 2,
          tid: 6
        }, {
          sid: 2,
          tid: 8
        }, {
          sid: 2,
          tid: 10
        }, {
          sid: 2,
          tid: 12
        }, {
          sid: 2,
          tid: 14
        }, {
          sid: 2,
          tid: 16
        }, {
          sid: 2,
          tid: 18
        }, {
          sid: 3,
          tid: 7
        }, {
          sid: 3,
          tid: 9
        }, {
          sid: 3,
          tid: 11
        }, {
          sid: 3,
          tid: 13
        }, {
          sid: 3,
          tid: 15
        }, {
          sid: 3,
          tid: 17
        }, {
          sid: 4,
          tid: 19
        }, {
          sid: 4,
          tid: 20
        }, {
          sid: 4,
          tid: 21
        }, {
          sid: 4,
          tid: 22
        }, {
          sid: 4,
          tid: 23
        }, {
          sid: 4,
          tid: 24
        }, {
          sid: 4,
          tid: 25
        }, {
          sid: 5,
          tid: 26
        }, {
          sid: 5,
          tid: 27
        }, {
          sid: 5,
          tid: 28
        }, {
          sid: 5,
          tid: 29
        }, {
          sid: 5,
          tid: 30
        }, {
          sid: 5,
          tid: 31
        }, {
          sid: 5,
          tid: 32
        }],
        options: {
          canvas: false,
          force: 1000,
          nodeSize: 5,
          nodeLabels: true,
          linkLabels: true,
          strLinks: true
        }
      }
    };
  },
  watch: {
    activeFeature: function activeFeature(val) {
      for (var i = 0; i < this.features.length; i++) {
        if (this.features[i].value === val) {
          this.activeFeatureThreat = this.features[i].threats;
        }
      }
    }
  },
  mounted: function mounted() {
    this.$eventHub.$emit('process-step-load', 'assess-trends');
    this.activeFeature = 'Feature 1';
    this.activeFeatureThreat = this.features[0].threats;
  },
  methods: {
    onClickAssessCompetitorFeatures: function onClickAssessCompetitorFeatures() {
      this.$emit('go-prev');
    },
    onClickCaptureResearch: function onClickCaptureResearch() {
      this.$emit('go-next');
    }
  }
};