"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-container"
  }, [_vm.business ? _c("div", [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 24,
      xs: 24
    }
  }, [_c("el-card", [_c("el-tabs", {
    model: {
      value: _vm.activeTab,
      callback: function callback($$v) {
        _vm.activeTab = $$v;
      },
      expression: "activeTab"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "Company Details",
      name: "account"
    }
  }, [_c("account", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      business: _vm.business
    },
    on: {
      "update-business": _vm.updateBusiness,
      "delete-business": _vm.deleteBusiness
    }
  })], 1), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: "Manage Users",
      name: "users"
    }
  }, [_c("user")], 1), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: "Integrations",
      name: "integrations"
    }
  }, [_c("integration")], 1), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: "Invitations",
      name: "invitations"
    }
  }, [_c("invites", {
    attrs: {
      "party-id": _vm.business.id
    }
  })], 1)], 1)], 1)], 1)], 1)], 1) : _vm._e()]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;