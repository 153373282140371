"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.array.includes");
var _objectSpread2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/objectSpread2.js"));
require("core-js/modules/es6.number.constructor");
var _vuex = require("vuex");
// import PieChart from './PieChart'
var _default2 = exports.default = {
  name: 'PhasePopover',
  components: {
    // PieChart
  },
  props: {
    x: {
      type: Number,
      default: 0
    },
    y: {
      type: Number,
      default: 0
    },
    width: {
      type: Number,
      default: 0
    },
    phasex: {
      type: Number,
      default: 0
    },
    phasewidth: {
      type: Number,
      default: 0
    },
    index: {
      type: Number,
      default: 0
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    phase: {
      type: Object,
      default: function _default() {
        return {
          title: '',
          priority: '',
          importance: '',
          description: '',
          relations: []
        };
      }
    },
    steps: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    experiences: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    ideas: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      isHover: false,
      focusHover: false,
      stepHover: false,
      gainHover: false,
      painHover: false,
      wowHover: false,
      hasConfirmOpen: false,
      stepChunkSize: 5,
      experienceChunkSize: 5,
      activeStepChunk: 0,
      activeExperienceChunk: 0,
      refreshKey: 0
    };
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['journeyMap'])), {}, {
    stepListActiveChunkLength: function stepListActiveChunkLength() {
      if (this.stepsList.length === 0) {
        return 0;
      }
      return this.stepsList[this.activeStepChunk].length;
    },
    stepsList: function stepsList() {
      // var step_ids = []
      this.refreshKey;
      var steps = [];

      // Sort Step list
      for (var k = 0; k < this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[this.index].steps.length; k++) {
        var step = this.retrieveStepFromStepID(this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[this.index].steps[k].id);
        if (step !== null) {
          steps.push(step);
        }
      }
      return this._.chunk(steps, this.stepChunkSize);
    },
    experiencesList: function experiencesList() {
      var experience_ids = [];
      var experiences = [];
      for (var i = 0; i < this.journeyMap.additionalattributes.relations.length; i++) {
        if (this.journeyMap.additionalattributes.relations[i].start_id === this.phase.id) {
          experience_ids.push(this.journeyMap.additionalattributes.relations[i].end_id);
        }
      }
      for (var j = 0; j < this.journeyMap.linkedJourneyExperiences.length; j++) {
        if (experience_ids.includes(this.journeyMap.linkedJourneyExperiences[j].journeyExperience.id)) {
          experiences.push(this.journeyMap.linkedJourneyExperiences[j]);
        }
      }
      return this._.chunk(experiences, this.experienceChunkSize);
    },
    computeHeight: function computeHeight() {
      var step_height = 0;
      var experience_height = 0;
      if (this.stepsList[this.activeStepChunk]) {
        step_height = this.stepsList[this.activeStepChunk].length * 35;
      }
      if (this.experiencesList[this.activeExperienceChunk]) {
        experience_height = this.experiencesList[this.activeExperienceChunk].length * 32 + 20;
      }
      return step_height + experience_height + 220;
    },
    computePhaseDetailsStepListHeight: function computePhaseDetailsStepListHeight() {
      var step_height = 0;
      if (this.stepsList[this.activeStepChunk]) {
        step_height = this.stepsList[this.activeStepChunk].length * 35;
      }
      return step_height;
    }
  }),
  watch: {
    journeyMap: {
      handler: function handler(newValue) {
        if (newValue.additionalattributes) {
          this.refreshKey++;
        }
      },
      deep: true
    }
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    trimString: function trimString(string, length) {
      if (string.length > length) {
        return string.substring(0, length) + '...';
      } else {
        return string;
      }
    },
    retrieveStepFromStepID: function retrieveStepFromStepID(step_id) {
      for (var j = 0; j < this.journeyMap.linkedJourneySteps.length; j++) {
        if (this.journeyMap.linkedJourneySteps[j].journeyStep.id === step_id && !this.journeyMap.linkedJourneySteps[j].journeyStep.hasOwnProperty('isDeleted') || this.journeyMap.linkedJourneySteps[j].journeyStep.id === step_id && this.journeyMap.linkedJourneySteps[j].journeyStep.hasOwnProperty('isDeleted') && this.journeyMap.linkedJourneySteps[j].journeyStep.isDeleted === false) {
          return this.journeyMap.linkedJourneySteps[j];
        }
      }
    },
    moveStepUp: function moveStepUp(step_index) {
      if (this.journeyMap.additionalattributes && this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages.length > 0 && this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[this.index].steps.length > 0) {
        var step_list = this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[this.index].steps;
        var temp = step_list[step_index];
        step_list[step_index] = step_list[step_index - 1];
        step_list[step_index - 1] = temp;
        this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[this.index].steps = step_list;
        this.$store.dispatch('customerTypes/updateJourneyMap', this.journeyMap);
        this.$emit('update-steps-sortorder');
      }
    },
    moveStepDown: function moveStepDown(step_index) {
      if (this.journeyMap.additionalattributes && this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages.length > 0 && this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[this.index].steps.length > 0) {
        var step_list = this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[this.index].steps;
        var temp = step_list[step_index];
        step_list[step_index] = step_list[step_index + 1];
        step_list[step_index + 1] = temp;
        this.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[this.index].steps = step_list;
        this.$store.dispatch('customerTypes/updateJourneyMap', this.journeyMap);
        this.$emit('update-steps-sortorder');
      }
    },
    generateMarker: function generateMarker() {
      var x_start = this.phasex + this.phasewidth / 2;
      var y_start = this.y - 10;
      var x_mid = this.phasex + this.phasewidth / 2 - 10;
      var y_mid = this.y;
      var x_last = this.phasex + this.phasewidth / 2 + 10;
      var y_last = this.y;
      return 'M ' + x_start + ' ' + y_start + ' L ' + x_mid + ' ' + y_mid + ' L ' + x_last + ' ' + y_last + '';
    },
    closePhaseDetailsPopover: function closePhaseDetailsPopover() {
      if (!this.hasConfirmOpen) {
        this.$emit('close');
      }
    },
    displayPhaseDetails: function displayPhaseDetails() {
      this.$emit('details', this.index);
    },
    displayPhaseProgress: function displayPhaseProgress() {
      this.$emit('details', this.index, 'phase_tracking');
    },
    initiateCreatePhaseIdea: function initiateCreatePhaseIdea() {
      this.$emit('initiate-create-phase-idea', this.phase.title);
    },
    computePriority: function computePriority(pri) {
      switch (pri) {
        case 'Very Low':
          return 1;
        case 'Low':
          return 2;
        case 'Medium':
          return 3;
        case 'High':
          return 4;
        case 'Very High':
          return 5;
      }
    },
    computeCustomerValueIcon: function computeCustomerValueIcon(imp) {
      switch (imp) {
        case 'Very Low':
          return 'dollar';
        case 'Low':
          return 'dollar';
        case 'Medium':
          return 'dollar-2';
        case 'High':
          return 'dollar-2';
        case 'Very High':
          return 'dollar-3';
        default:
          return 'dollar';
      }
    },
    computeValueFontSize: function computeValueFontSize(value1) {
      if (value1 === 'Very Low') {
        return 'lowestValue';
      } else if (value1 === 'Low') {
        return 'lowValue';
      }
    },
    createTask: function createTask() {
      this.$emit('create-task', this.index);
    },
    deletePhase: function deletePhase() {
      var _this = this;
      this.hasConfirmOpen = true;
      this.$confirm('This will permanently delete stage. Continue?', 'Warning', {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(function () {
        _this.hasConfirmOpen = false;
        _this.$emit('delete-phase', _this.phase.id);
      }).catch(function (e) {
        console.log(e);
        _this.hasConfirmOpen = false;
        _this.$message({
          type: 'info',
          message: 'Delete canceled'
        });
      });
    },
    deleteStep: function deleteStep(step_id) {
      var _this2 = this;
      this.hasConfirmOpen = true;
      this.$confirm('This will permanently delete step. Continue?', 'Warning', {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(function () {
        _this2.hasConfirmOpen = false;
        _this2.$emit('delete-step', step_id);
      }).catch(function (e) {
        _this2.hasConfirmOpen = false;
        console.log(e);
        _this2.$message({
          type: 'info',
          message: 'Delete canceled'
        });
      });
    },
    deleteExperience: function deleteExperience(experience_id) {
      var _this3 = this;
      this.hasConfirmOpen = true;
      this.$confirm('This will permanently delete experience. Continue?', 'Warning', {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(function () {
        _this3.hasConfirmOpen = false;
        _this3.$emit('delete-experience', experience_id);
      }).catch(function (e) {
        _this3.hasConfirmOpen = false;
        console.log(e);
        _this3.$message({
          type: 'info',
          message: 'Delete canceled'
        });
      });
    },
    focusMode: function focusMode() {
      this.$emit('focus-mode', this.phase, this.index);
    },
    createExperienceFromPhase: function createExperienceFromPhase(experience_type) {
      this.$emit('initiate-create-experience', experience_type);
    },
    truncateString: function truncateString(string, length) {
      return this._.truncate(string, {
        'length': length,
        'separator': ' '
      });
    }
  }
};