"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _segmentOverview = _interopRequireDefault(require("./components/segments_customer_types/segment-overview"));
var _segmentFinancials = _interopRequireDefault(require("./components/segments_customer_types/segment-financials"));
var _customerTypes = _interopRequireDefault(require("./components/segments_customer_types/customer-types"));
var _ProcessSteps = _interopRequireDefault(require("@/components/ProcessSteps"));
var _default = exports.default = {
  name: 'SegmentCustomerTypes',
  components: {
    SegmentOverviewComponent: _segmentOverview.default,
    SegmentFinancialsComponent: _segmentFinancials.default,
    CustomerTypesComponent: _customerTypes.default,
    ProcessSteps: _ProcessSteps.default
  },
  data: function data() {
    return {
      active_step: 0,
      loading: false,
      checkAllServices: false,
      isIndeterminate: false,
      addSegmentDialog: false,
      totalMarketSelected: false,
      marketSegmentsHelpDialog: false,
      activeSegment: {},
      activeSegmentIndex: null,
      addSegmentModalStepCounter: 0,
      segments: [],
      segment_form: {
        name: '',
        type: [],
        description: '',
        revenue_notes: '',
        geography: [],
        pricingTier: [],
        num_customers: 0,
        newCustomersPerMonth: 0,
        // NEW DATA@30-3-2020
        annualLicenseRevenuePC: 0,
        // NEW DATA@30-3-2020
        annualProjectServicer: 0,
        // NEW DATA@30-3-2020
        annualSupportRevenue: 0,
        // NEW DATA@30-3-2020
        avg_customer_value: 0,
        value: '',
        services: [],
        segment_needs: [{
          name: 'lorem ipsum',
          complete: true
        }]
      },
      countries: [{
        code: 'AF',
        name: 'Africa'
      }, {
        code: 'NA',
        name: 'North Asia'
      }, {
        code: 'SEA',
        name: 'South East Asia'
      }, {
        code: 'CR',
        name: 'Caribbean'
      }, {
        code: 'CA',
        name: 'Central America'
      }, {
        code: 'NoA',
        name: 'North America'
      }, {
        code: 'SA',
        name: 'South America'
      }, {
        code: 'ME',
        name: 'Middle East'
      }, {
        code: 'EU',
        name: 'Europe'
      }, {
        code: 'OA',
        name: 'Australia & Oceania'
      }],
      steps: [{
        name: 'Segment Overview',
        shortDescription: 'Segment Summary',
        longDescription: 'Create a summary of this segment. Include notes on how revenue will be achieved. Also create a list of needs for the segment.'
      }, {
        name: 'Segment Financials',
        shortDescription: '',
        longDescription: ''
      }, {
        name: 'Customer Types',
        shortDescription: 'Seperate purchasers from users',
        longDescription: 'Not all customers will use your product! Some will buy it for others to use. This section helps seperate purchasers from users, and helps build a sales and marketing strategy for purchasers. Candidate users type can be identified here, and refined in the "Define Users" screen.'
      }]
    };
  },
  computed: {
    computeSegmentValue: function computeSegmentValue() {
      return this.segment_form.num_customers * this.segment_form.avg_customer_value;
    }
  },
  mounted: function mounted() {
    var _this = this;
    // Listen to event
    this.loading = true;
    this.$store.dispatch('getSegments', this.$store.getters.product.id).then(function (data) {
      _this.loading = false;
      _this.segments = data.data;
      _this.activeSegment = _this.segments[0];
      _this.activeSegmentIndex = 0;
    }).catch(function (e) {
      _this.loading = false;
      _this.$message({
        type: 'error',
        message: e.message
      });
    });
  },
  methods: {
    handleCheckAllChange: function handleCheckAllChange(val) {
      this.segment_form.services = val ? ['Licensing', 'Services'] : [];
      this.isIndeterminate = false;
    },
    handleCheckedServicesChange: function handleCheckedServicesChange(value) {
      var checkedCount = value.length;
      this.checkAllServices = checkedCount === 2;
      this.isIndeterminate = checkedCount > 0 && checkedCount < 2;
    },
    addSegmentNeeds: function addSegmentNeeds() {
      this.segment_form.segment_needs.push({
        name: '',
        complete: false
      });
    },
    updateStep: function updateStep(active_step) {
      this.active_step = active_step;
    },
    addSegment: function addSegment() {
      var segment = Object.assign({}, this.segment_form);
      segment.value = segment.num_customers * segment.avg_customer_value;
      if (this.activeSegmentIndex === null) {
        this.segments.push(segment);
      }
      this.addSegmentDialog = false;
    },
    selectSegment: function selectSegment(segment, index) {
      this.totalMarketSelected = false;
      this.activeSegment = segment;
      this.activeSegmentIndex = index;
    },
    openAddSegmentModal: function openAddSegmentModal() {
      var type = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      var activeSegmentIndex = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      this.addSegmentModalStepCounter = 0;
      this.activeSegmentIndex = activeSegmentIndex;
      if (type === 'edit') {
        this.segment_form = this.activeSegment;
      } else {
        this.segment_form = {
          name: '',
          type: [],
          description: '',
          revenue_notes: '',
          geography: [],
          pricingTier: [],
          num_customers: 0,
          avg_customer_value: 0,
          value: '',
          services: [],
          segment_needs: [{
            name: 'lorem ipsum',
            complete: true
          }]
        };
      }
      this.addSegmentDialog = true;
    }
  }
};