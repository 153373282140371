"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("g", {
    directives: [{
      name: "dragged",
      rawName: "v-dragged.prevent",
      value: _vm.onObjectiveDragged,
      expression: "onObjectiveDragged",
      modifiers: {
        prevent: true
      }
    }],
    attrs: {
      id: _vm.computeDragObjectiveID(),
      refs: "objective"
    },
    on: {
      click: function click($event) {
        $event.preventDefault();
        return _vm.onClick.apply(null, arguments);
      },
      mouseover: function mouseover($event) {
        _vm.isHover = true;
      },
      mouseleave: function mouseleave($event) {
        _vm.isHover = false;
      }
    }
  }, [_c("rect", {
    class: _vm.computeSubEntityClass(_vm.objective),
    attrs: {
      x: _vm.x,
      y: _vm.y,
      width: _vm.width,
      height: _vm.height,
      rx: "2",
      "stroke-width": "1.0",
      stroke: _vm.computeStrokeColor,
      fill: _vm.isHover ? _vm.computeStrokeColor : _vm.computeFillColor
    }
  }), _vm._v(" "), _c("text", {
    attrs: {
      x: _vm.x + 5,
      y: _vm.y + 10,
      "font-family": "Verdana",
      fill: _vm.isHover ? "darkslateblue" : "darkslategrey",
      "font-size": "0.72em",
      "text-anchor": "start",
      "font-weight": "normal"
    }
  }, [_vm._v("\n    " + _vm._s(_vm.truncateString(_vm.objective.title, 20)) + "\n  ")]), _vm._v(" "), _vm.objective.childTickets && _vm.objective.childTickets.length > 0 ? _c("g", {
    attrs: {
      refs: "child_tickets"
    }
  }, [_c("rect", {
    attrs: {
      x: _vm.x + _vm.width - 16,
      y: _vm.y + 3,
      width: 14,
      height: _vm.height - 6,
      rx: "5",
      fill: "#FFF"
    }
  }), _vm._v(" "), _c("text", {
    attrs: {
      x: _vm.x + _vm.width - 5,
      y: _vm.y + 10,
      "font-family": "Verdana",
      fill: _vm.objective.__typename === "ProductFeature" ? "rgb(13, 99, 191)" : "#008080",
      "font-size": "0.7em",
      "text-anchor": "end",
      "font-weight": "normal"
    }
  }, [_vm._v("\n      " + _vm._s(_vm.objective.childTickets.length) + "\n    ")])]) : _vm._e()]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;