"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _default2 = exports.default = {
  name: 'Objective',
  components: {},
  props: {
    x: {
      type: Number,
      required: true,
      default: 0
    },
    y: {
      type: Number,
      required: true,
      default: 0
    },
    width: {
      type: Number,
      required: true,
      default: 0
    },
    height: {
      type: Number,
      required: true,
      default: 0
    },
    entityIndex: {
      type: Number,
      required: true,
      default: 0
    },
    index: {
      type: Number,
      required: true,
      default: 0
    },
    isMinimized: {
      type: Boolean,
      default: false
    },
    showInitiatives: {
      type: Boolean,
      default: false
    },
    objective: {
      type: Object,
      default: function _default() {
        return {
          title: '',
          description: ''
        };
      }
    }
  },
  data: function data() {
    return {
      loading: false,
      isHover: false,
      shadowWidth: 197
    };
  },
  computed: {
    computeObjectiveResizeControlWidth: function computeObjectiveResizeControlWidth() {
      var width = 15;
      return width;
    },
    computeDragIconX: function computeDragIconX() {
      if (this.width < 200) {
        return this.isMinimized ? this.x + 200 - 20 : this.x + 200 - 65;
      }
      return this.isMinimized ? this.x + this.width - 20 : this.x + this.width - 65;
    },
    computeStatusIconX: function computeStatusIconX() {
      if (this.width < 200) {
        return this.x + 200 - 35;
      }
      return this.x + this.width - 35;
    },
    computeDragIconStyling: function computeDragIconStyling() {
      if (this.isMinimized) {
        return 'cursor:grabbing; min-height:10pt; min-width:10pt; border-radius:12pt; padding-top:1.5pt; background-color: white; border: solid 1pt darkslategray; color:navy; font-size: 0.8em;padding-left: 1pt;';
      } else {
        return 'cursor:grabbing; min-height:15pt; min-width:15pt; border-radius:15pt; padding-top:1.5pt; background-color: white; border: solid 1pt darkslategray; color:navy; font-size: 1.4em;padding-left: 1pt;';
      }
    },
    computeProgressX: function computeProgressX() {
      if (this.width < 200) {
        return this.x + 200 - 90;
      }
      return this.x + this.width - 90;
    }
  },
  watch: {},
  mounted: function mounted() {},
  created: function created() {},
  methods: {
    computeDragObjectiveID: function computeDragObjectiveID() {
      return 'objective_drag_' + this.entityIndex + '_' + this.index;
    },
    truncateString: function truncateString(string, length) {
      return this._.truncate(string, {
        'length': length
      });
    },
    calcInitClass: function calcInitClass(status) {
      if (status === 'Proposed') {
        return 'init_Proposed';
      } else if (status === 'Accepted') {
        return 'init_Accepted';
      } else if (status === 'In progress') {
        return 'init_InProgress';
      } else if (status === 'On hold') {
        return 'init_OnHold';
      } else if (status === 'Overdue') {
        return 'init_Overdue';
      } else if (status === 'Achieved') {
        return 'init_Achieved';
      } else {
        return 'init_proposed';
      }
    },
    displayObjectiveDialog: function displayObjectiveDialog() {
      this.$emit('click', this.objective);
    },
    dynamicLabelLength: function dynamicLabelLength(string) {
      var pixelMultipler = 6;
      if (string.length < 8) {
        pixelMultipler = 8;
      }
      return string.length * pixelMultipler;
    },
    // START DRAG EVENTS
    onObjectiveDragged: function onObjectiveDragged(_ref) {
      var el = _ref.el,
        deltaX = _ref.deltaX,
        deltaY = _ref.deltaY,
        offsetX = _ref.offsetX,
        offsetY = _ref.offsetY,
        clientX = _ref.clientX,
        clientY = _ref.clientY,
        first = _ref.first,
        last = _ref.last;
      if (last !== undefined && last) {
        this.$emit('drag-stop', this.entityIndex, this.index, parseInt(deltaX));
        return;
      }
      if (deltaX === undefined || deltaY === undefined) {
        this.$emit('drag-start', this.entityIndex, this.index, parseInt(deltaX));
        return;
      }
      // this.x += parseInt(deltaX)
      this.$emit('drag', this.entityIndex, this.index, parseInt(deltaX));
    },
    onObjectiveLeftResized: function onObjectiveLeftResized(_ref2) {
      var el = _ref2.el,
        deltaX = _ref2.deltaX,
        deltaY = _ref2.deltaY,
        offsetX = _ref2.offsetX,
        offsetY = _ref2.offsetY,
        clientX = _ref2.clientX,
        clientY = _ref2.clientY,
        first = _ref2.first,
        last = _ref2.last;
      if (last !== undefined && last) {
        this.$emit('resize-stop', 'left', this.entityIndex, this.index, parseInt(deltaX));
        return;
      }
      if (deltaX === undefined || deltaY === undefined) {
        return;
      }
      this.$emit('resize', 'left', this.entityIndex, this.index, parseInt(deltaX));
    },
    onObjectiveRightResized: function onObjectiveRightResized(_ref3) {
      var el = _ref3.el,
        deltaX = _ref3.deltaX,
        deltaY = _ref3.deltaY,
        offsetX = _ref3.offsetX,
        offsetY = _ref3.offsetY,
        clientX = _ref3.clientX,
        clientY = _ref3.clientY,
        first = _ref3.first,
        last = _ref3.last;
      if (last !== undefined && last) {
        this.$emit('resize-stop', 'right', this.entityIndex, this.index, parseInt(deltaX));
        return;
      }
      if (deltaX === undefined || deltaY === undefined) {
        return;
      }
      this.$emit('resize', 'right', this.entityIndex, this.index, parseInt(deltaX));
    } // STOP DRAG EVENTS
  }
};