"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.find");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.replace");
var _default2 = exports.default = {
  name: 'GroupCustomers',
  components: {},
  props: {
    segments: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      createGroupDialog: false,
      group_form: {
        name: '',
        description: '',
        customers: []
      },
      groups: [{
        name: 'Individual',
        description: 'Lorem Ipsum',
        customers: [],
        all_features_selected: false,
        is_visible: true
      }, {
        name: 'Family',
        description: 'Lorem Ipsum',
        customers: [],
        all_features_selected: false,
        is_visible: true
      }],
      activeDragElement: {},
      defaultProps: {
        children: 'customers',
        label: 'name'
      }
    };
  },
  computed: {
    segmentsTree: function segmentsTree() {
      // Generate format which is compatible with Element Tree component
      return this.segments;
    }
  },
  mounted: function mounted() {
    this.$eventHub.$emit('process-step-load', 'group-customers');
  },
  methods: {
    createGroup: function createGroup() {
      var group_form = Object.assign({}, this.group_form);
      this.groups.push(group_form);
      this.resetGroupForm();
      this.createGroupDialog = false;
    },
    deleteGroup: function deleteGroup(index) {
      this.groups.splice(index, 1);
    },
    resetGroupForm: function resetGroupForm() {
      this.group_form = {
        name: '',
        description: '',
        customers: []
      };
    },
    allowDrop: function allowDrop(draggingNode, dropNode, type) {
      return false;
    },
    handleDragStart: function handleDragStart(node, ev) {
      this.activeDragElement = node;
    },
    handleDrop: function handleDrop(event) {
      event.preventDefault(); // !important
      var index = parseInt(event.srcElement.id.replace('group_', ''));
      // Check if customer already exists in group
      if (this.activeDragElement.data.hasOwnProperty('customers')) {
        for (var i = 0; i < this.activeDragElement.data.customers.length; i++) {
          var name = this.activeDragElement.data.name + ' - ' + this.activeDragElement.data.customers[i].name;
          if (!window._.find(this.groups[index].customers, ['name', name])) {
            this.groups[index].customers.push({
              'name': name
            });
          }
        }
      } else {
        // customer does not exist in group
        var name1 = this.activeDragElement.parent.data.name + ' - ' + this.activeDragElement.data.name;
        if (!window._.find(this.groups[index].customers, ['name', name1])) {
          this.groups[index].customers.push({
            'name': name1
          });
        }
      }
    },
    handleDragOver: function handleDragOver(event) {
      event.preventDefault(); // !important
    },
    removeCustomer: function removeCustomer(group_index, customer_index) {
      this.groups[group_index].customers.splice(customer_index, 1);
    },
    goToLinkCustomerToUser: function goToLinkCustomerToUser() {
      this.$emit('go-next', this.groups);
    },
    generateGroupID: function generateGroupID(index) {
      return 'group_' + index;
    }
  }
};