"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "app-container"
  }, [_c("transition", {
    attrs: {
      name: "fade-transform",
      mode: "out-in"
    }
  }, [_c("el-tabs", {
    attrs: {
      value: "strategic_features"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "Strategic Features",
      name: "strategic_features"
    }
  }, [_c("define-strategic-features-component")], 1), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: "Strategic Criteria",
      name: "strategic_criteria"
    }
  }, [_c("strategic-criteria-component")], 1), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: "My Product’s Features",
      name: "strategic_feature_competitors"
    }
  }, [_c("my-product-features-component")], 1)], 1)], 1)], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;