"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _UserCreate = _interopRequireDefault(require("./UserCreate.vue"));
var _axios = _interopRequireDefault(require("axios"));
var _default = exports.default = {
  name: 'Users',
  components: {
    CreateUserComponent: _UserCreate.default
  },
  data: function data() {
    return {
      loading: false,
      createUserDialog: false,
      showDeletedUsers: false,
      addSecurityDialog: false,
      addTeamRoleDialog: false,
      addModuleDialog: false,
      users: [],
      user_name: '',
      selected_user: {},
      selected_user_index: 0,
      roles: [],
      team_roles: [{
        value: 'Product Manager',
        label: 'Product Manager'
      }, {
        value: 'Project Manager',
        label: 'Project Manager'
      }, {
        value: 'Scrum Master',
        label: 'Scrum Master'
      }, {
        value: 'Developer',
        label: 'Developer'
      }, {
        value: 'UI Designer',
        label: 'UI Designer'
      }, {
        value: 'Engineering Manager',
        label: 'Engineering Manager'
      }, {
        value: 'Business Analyst',
        label: 'Business Analyst'
      }, {
        value: 'Solution Architect',
        label: 'Solution Architect'
      }],
      link_security_form: {
        roles: []
      },
      link_role_form: {
        team_role: {}
      },
      link_modules_form: {
        modules: []
      }
    };
  },
  computed: {
    filteredUsers: function filteredUsers() {
      var self = this;
      if (this.users.length > 0) {
        return this.users.filter(function (user) {
          return user.firstName.indexOf(self.user_name) > -1;
        });
      } else {
        return this.users;
      }
    }
  },
  watch: {
    showDeletedUsers: function showDeletedUsers(val) {
      this.getUserList();
    }
  },
  mounted: function mounted() {
    this.loading = true;
    this.getUserList();
  },
  methods: {
    handleRemoveModule: function handleRemoveModule(user_index, module_index) {
      var _this = this;
      var modules = this.users[user_index].additionalAttributes.modules;
      modules.splice(module_index, 1);
      var additionalAttributes = {
        modules: []
      };
      additionalAttributes.modules = modules;
      this.loading = true;
      var headers = {
        'Content-Type': 'application/json'
      };
      var body = {
        'data': {
          'additionalAttributes': additionalAttributes
        },
        'header': {
          'userId': this.$store.getters.id,
          'partyId': this.$store.getters.partyId
        }
      };
      _axios.default.patch(process.env.VUE_APP_REST_API_ENDPOINT + 'user/' + this.users[user_index].id, body, {
        headers: headers
      }).then(function (response) {
        _this.loading = false;
        _this.addModuleDialog = false;
        _this.$notify({
          title: 'Success',
          message: response.data.data.description,
          type: 'success',
          duration: 2000
        });
        _this.getUserList();
        if (_this.users[user_index].id === _this.$store.getters.id) {
          _this.$store.dispatch('user/changeModules', modules);
        }
      }).catch(function (error) {
        _this.loading = false;
        _this.$notify({
          title: 'Error',
          message: error.response.data.data.description,
          type: 'error',
          duration: 2000
        });
      });
    },
    computeDate: function computeDate(date) {
      return this.$moment(date).format('D MMM YYYY');
    },
    computeTime: function computeTime(date) {
      return this.$moment(date).format('HH:mm a');
    },
    highlightDeletedUsers: function highlightDeletedUsers(_ref) {
      var row = _ref.row,
        rowIndex = _ref.rowIndex;
      if (row.isDeleted) {
        return 'deleted-row';
      }
    },
    isCurrentUser: function isCurrentUser(id) {
      return this.$store.getters.id === id;
    },
    isAdmin: function isAdmin() {
      for (var j = 0; j < this.users.length; j++) {
        if (this.users[j].id === this.$store.getters.id) {
          for (var i = 0; i < this.users[j].userRole.length; i++) {
            if (this.users[j].userRole[i].role.title === 'Admin') {
              return true;
            }
          }
        }
      }
      return false;
    },
    computeIndex: function computeIndex(type, index) {
      return type + '_' + index;
    },
    openLinkSecurityDialog: function openLinkSecurityDialog(user) {
      this.link_security_form.roles = [];
      this.selected_user = user;
      this.getRoleList();
      this.addSecurityDialog = true;
    },
    openLinkRoleDialog: function openLinkRoleDialog(user) {
      var temp_user = Object.assign({}, user);
      this.link_role_form.team_role = temp_user.businessRole;
      this.selected_user = user;
      this.addTeamRoleDialog = true;
    },
    openLinkModuleDialog: function openLinkModuleDialog(user, index) {
      this.selected_user = user;
      this.selected_user_index = index;
      if (this.selected_user.additionalAttributes === undefined || this.selected_user.additionalAttributes.modules === undefined) {
        this.link_modules_form.modules = [];
      } else {
        this.link_modules_form.modules = this.selected_user.additionalAttributes.modules;
      }
      this.addModuleDialog = true;
    },
    linkRoleToUser: function linkRoleToUser() {
      var _this2 = this;
      this.loading = true;
      var headers = {
        'Content-Type': 'application/json'
      };
      var body = {
        'data': {
          'businessRole': this.link_role_form.team_role
        },
        'header': {
          'userId': this.$store.getters.id,
          'partyId': this.$store.getters.partyId
        }
      };
      _axios.default.patch(process.env.VUE_APP_REST_API_ENDPOINT + 'user/' + this.selected_user.id, body, {
        headers: headers
      }).then(function (response) {
        _this2.loading = false;
        _this2.addTeamRoleDialog = false;
        _this2.$notify({
          title: 'Success',
          message: response.data.data.description,
          type: 'success',
          duration: 2000
        });
        _this2.getUserList();
      }).catch(function (error) {
        _this2.loading = false;
        _this2.$notify({
          title: 'Error',
          message: error.response.data.data.description,
          type: 'error',
          duration: 2000
        });
      });
    },
    linkModuleToUser: function linkModuleToUser() {
      var _this3 = this;
      var additionalAttributes = this.selected_user.additionalAttributes;
      if (this.selected_user.additionalAttributes === null || this.selected_user.additionalAttributes === undefined) {
        additionalAttributes = {
          modules: []
        };
      } else if (this.selected_user.additionalAttributes.modules === null || this.selected_user.additionalAttributes.modules === undefined) {
        additionalAttributes = {
          modules: []
        };
      }
      additionalAttributes.modules = this.link_modules_form.modules;
      this.loading = true;
      var headers = {
        'Content-Type': 'application/json'
      };
      var body = {
        'data': {
          'additionalAttributes': additionalAttributes
        },
        'header': {
          'userId': this.$store.getters.id,
          'partyId': this.$store.getters.partyId
        }
      };
      _axios.default.patch(process.env.VUE_APP_REST_API_ENDPOINT + 'user/' + this.selected_user.id, body, {
        headers: headers
      }).then(function (response) {
        _this3.loading = false;
        _this3.addModuleDialog = false;
        _this3.$notify({
          title: 'Success',
          message: response.data.data.description,
          type: 'success',
          duration: 2000
        });
        _this3.getUserList();
        if (_this3.selected_user.id === _this3.$store.getters.id) {
          _this3.$store.dispatch('user/changeModules', additionalAttributes.modules);
        }
        _this3.resetModuleForm();
      }).catch(function (error) {
        _this3.loading = false;
        _this3.$notify({
          title: 'Error',
          message: error.response.data.data.description,
          type: 'error',
          duration: 2000
        });
      });
    },
    linkSecurityToUser: function linkSecurityToUser() {
      var _this4 = this;
      this.loading = true;
      var headers = {
        'Content-Type': 'application/json'
      };
      var body = {
        'data': this.generationRoleData(this.link_security_form.roles),
        'header': {
          'userId': this.$store.getters.id,
          'partyId': this.$store.getters.partyId
        }
      };
      _axios.default.patch(process.env.VUE_APP_REST_API_ENDPOINT + 'user/' + this.selected_user.id + '/roles', body, {
        headers: headers
      }).then(function (response) {
        _this4.loading = false;
        if (response.data.status === 'SUCCESS') {
          _this4.$notify({
            title: 'Success',
            message: 'Roles Linked',
            type: 'success',
            duration: 2000
          });
          _this4.getUserList();
          _this4.addSecurityDialog = false;
        } else {
          _this4.$notify({
            title: 'Error',
            message: response.data.data.description,
            type: 'error',
            duration: 2000
          });
        }
      }).catch(function (error) {
        _this4.loading = false;
        _this4.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
      });
    },
    generationRoleData: function generationRoleData(roles) {
      var role_list = [];
      for (var i = 0; i < roles.length; i++) {
        role_list.push({
          id: roles[i]
        });
      }
      return role_list;
    },
    unlinkRoleFromUser: function unlinkRoleFromUser(user_id, role_id) {
      this.loading = true;
      var body = {
        'header': {
          'userId': this.$store.getters.id,
          'partyId': this.$store.getters.partyId
        },
        'data': [{
          'id': role_id
        }]
      };
      var that = this;
      var xhr = new XMLHttpRequest();
      xhr.open('DELETE', process.env.VUE_APP_REST_API_ENDPOINT + 'user/' + user_id + '/roles', true);

      // Send the proper header information along with the request
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.send(JSON.stringify(body));
      xhr.onreadystatechange = function () {
        // Call a function when the state changes.
        if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
          // Request finished. Do processing here.
          that.$notify({
            title: 'Success',
            message: 'Role unlinked succesfully',
            type: 'success',
            duration: 2000
          });
          that.getUserList();
          that.loading = false;
        }
      };
      xhr.onload = function () {};
    },
    selectUser: function selectUser(user) {
      this.selected_user = user;
    },
    openCreateUserModal: function openCreateUserModal() {
      this.createUserDialog = true;
    },
    createUser: function createUser(user) {
      var _this5 = this;
      this.loading = true;
      var headers = {
        'Content-Type': 'application/json'
      };
      var body = {
        data: [{
          'firstName': user.firstname,
          'middleName': user.middlename,
          'lastName': user.lastname,
          'emailAddress': user.emailAddress,
          'mobileNumber': user.mobileNumber,
          'telephoneNumber': user.telephoneNumber,
          'password': user.password
        }],
        'header': {
          'userId': this.$store.getters.id,
          'partyId': this.$store.getters.partyId
        }
      };
      _axios.default.post(process.env.VUE_APP_REST_API_ENDPOINT + 'user', body, {
        headers: headers
      }).then(function (response) {
        _this5.loading = false;
        _this5.response = response.data;
        if (response.data.data.status === 'SUCCESS') {
          _this5.$notify({
            title: 'User Created',
            message: response.data.data.description,
            type: 'success',
            duration: 2000
          });
        }
        _this5.getUserList();
        _this5.createUserDialog = false;
      }).catch(function (error) {
        _this5.loading = false;
        _this5.$notify({
          title: 'Error',
          message: error.response.data.data.description,
          type: 'error',
          duration: 2000
        });
      });
      this.createUserDialog = false;
    },
    deleteUser: function deleteUser(user) {
      var _this6 = this;
      this.$confirm('This will permanently delete the User. Continue?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(function () {
        _this6.loading = true;
        var body = {
          'header': {
            'userId': _this6.$store.getters.id,
            'partyId': _this6.$store.getters.partyId
          }
        };
        var that = _this6;
        var xhr = new XMLHttpRequest();
        xhr.open('DELETE', process.env.VUE_APP_REST_API_ENDPOINT + 'user/' + user.id, true);

        // Send the proper header information along with the request
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.send(JSON.stringify(body));
        xhr.onreadystatechange = function () {
          // Call a function when the state changes.
          if (this.readyState === XMLHttpRequest.DONE) {
            if (this.status === 200) {
              // Request finished. Do processing here.
              that.$notify({
                title: 'Success',
                message: 'User deleted succesfully',
                type: 'success',
                duration: 2000
              });
              that.getUserList();
              that.loading = false;
            } else {
              that.loading = false;
              that.$notify({
                title: 'Error',
                message: 'User delete failed',
                type: 'error',
                duration: 2000
              });
            }
          }
        };
        xhr.onload = function () {};
      }).catch(function () {
        _this6.$message({
          type: 'info',
          message: 'Delete canceled'
        });
      });
    },
    getUserList: function getUserList() {
      var _this7 = this;
      this.loading = true;
      var headers = {
        'Content-Type': 'application/json',
        'x-party-id': this.$store.getters.partyId,
        'x-user-id': this.$store.getters.id
      };
      _axios.default.get(process.env.VUE_APP_REST_API_ENDPOINT + 'user/?extended=LEVEL0&deleted=' + this.showDeletedUsers, {
        headers: headers
      }).then(function (response) {
        _this7.loading = false;
        _this7.users = response.data.data;
      }).catch(function (error) {
        if (error.response.status === 400) {
          _this7.loading = false;
          _this7.$notify({
            title: 'Error',
            message: error.response.message,
            type: 'error',
            duration: 2000
          });
        }
      });
    },
    getRoleList: function getRoleList() {
      var _this8 = this;
      this.roles = [];
      this.loading = true;
      var headers = {
        'Content-Type': 'application/json',
        'x-party-id': this.$store.getters.partyId,
        'x-user-id': this.$store.getters.id
      };
      _axios.default.get(process.env.VUE_APP_REST_API_ENDPOINT + 'security/role', {
        headers: headers
      }).then(function (response) {
        _this8.loading = false;
        if (response.data.status === 'SUCCESS') {
          _this8.roles = response.data.data;
        } else {
          _this8.$notify({
            title: 'Error',
            message: response.data.data.description,
            type: 'error',
            duration: 2000
          });
        }
      }).catch(function (error) {
        _this8.loading = false;
        _this8.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
      });
    },
    resetModuleForm: function resetModuleForm() {
      this.link_modules_form = {
        modules: []
      };
    }
  }
};