"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-row", {
    staticClass: "no-padding",
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticStyle: {
      "padding-right": "0 !important",
      "border-top-right-radius": "3pt",
      height: "100%"
    },
    attrs: {
      span: 5
    }
  }, [_c("item-list", {
    attrs: {
      title: "Risks",
      items: _vm.risks,
      "fuzzy-search": true,
      total: _vm.total_count,
      "selected-item": _vm.selectedRisk
    },
    on: {
      click: _vm.selectRisk,
      create: _vm.openRiskDialog,
      "load-page": _vm.retrieveRisk
    }
  })], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 18
    }
  }, [_c("transition", {
    attrs: {
      name: "fade"
    }
  }, [!_vm.loading ? [_vm.selectedRisk.id ? _c("div", [_c("el-card", {
    staticStyle: {
      "border-bottom-left-radius": "9pt",
      "margin-left": "10px"
    },
    attrs: {
      id: "business_goal_details_container"
    }
  }, [_c("div", {
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 18
    }
  }, [_vm.isEditMode ? _c("el-input", {
    staticStyle: {
      "margin-top": "-5px"
    },
    attrs: {
      type: "text",
      size: "small"
    },
    model: {
      value: _vm.selectedRisk.title,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedRisk, "title", $$v);
      },
      expression: "selectedRisk.title"
    }
  }) : _c("strong", {
    staticStyle: {
      "font-variant-caps": "small-caps"
    }
  }, [_vm._v(_vm._s(_vm.selectedRisk.title) + " Details")])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "fr"
  }, [_c("el-button-group", {
    staticStyle: {
      "margin-top": "-5px"
    },
    attrs: {
      size: "mini"
    }
  }, [!_vm.isEditMode ? _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Edit",
      placement: "top"
    }
  }, [_c("el-button", {
    attrs: {
      type: "secondary",
      size: "micro",
      icon: "el-icon-edit"
    },
    on: {
      click: function click($event) {
        _vm.isEditMode = true;
      }
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.isEditMode ? _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Update",
      placement: "top"
    }
  }, [_c("el-button", {
    attrs: {
      type: "success",
      size: "micro",
      icon: "el-icon-check"
    },
    on: {
      click: _vm.updateRisk
    }
  })], 1) : _vm._e(), _vm._v(" "), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Delete",
      placement: "top"
    }
  }, [_c("el-button", {
    attrs: {
      type: "danger",
      size: "micro",
      icon: "el-icon-delete"
    },
    on: {
      click: _vm.deleteRisk
    }
  })], 1)], 1)], 1)])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 20,
      type: "flex",
      justify: "center"
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("div", [_c("strong", [_vm._v("Risk Description")])]), _vm._v(" "), _vm.isEditMode ? _c("el-input", {
    staticClass: "mt-10",
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.selectedRisk.description,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedRisk, "description", $$v);
      },
      expression: "selectedRisk.description"
    }
  }) : _c("div", {
    staticClass: "mt-5 pl-10 text-justify",
    staticStyle: {
      color: "rgb(96, 98, 102)",
      height: "54px"
    }
  }, [_vm._v(_vm._s(_vm.selectedRisk.description))])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("div", [_c("strong", [_vm._v("Mitigation Plan")])]), _vm._v(" "), _vm.isEditMode ? _c("el-input", {
    staticClass: "mt-10",
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.selectedRisk.mitigationplan,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedRisk, "mitigationplan", $$v);
      },
      expression: "selectedRisk.mitigationplan"
    }
  }) : _c("div", {
    staticClass: "mt-5 pl-10 text-justify",
    staticStyle: {
      color: "rgb(96, 98, 102)",
      height: "54px"
    }
  }, [_vm._v(_vm._s(_vm.selectedRisk.mitigationplan))])], 1)], 1), _vm._v(" "), _c("el-row", {
    staticClass: "mt-10",
    attrs: {
      gutter: 20,
      type: "flex",
      justify: "center"
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("div", [_c("strong", [_vm._v("Occurance Probability")])]), _vm._v(" "), _vm.isEditMode ? _c("div", {
    staticClass: "mt-10"
  }, [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.risk_form.occuranceprobability,
      callback: function callback($$v) {
        _vm.$set(_vm.risk_form, "occuranceprobability", $$v);
      },
      expression: "risk_form.occuranceprobability"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: "Low",
      value: "Low"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Medium",
      value: "Medium"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "High",
      value: "High"
    }
  })], 1)], 1) : _c("div", {
    staticClass: "mt-10"
  }, [_vm.selectedRisk.occuranceprobability === "High" ? _c("el-tag", {
    attrs: {
      type: "danger",
      size: "mini"
    }
  }, [_vm._v(_vm._s(_vm.selectedRisk.occuranceprobability) + " ")]) : _vm._e(), _vm._v(" "), _vm.selectedRisk.occuranceprobability === "Medium" ? _c("el-tag", {
    attrs: {
      type: "warning",
      size: "mini"
    }
  }, [_vm._v(_vm._s(_vm.selectedRisk.occuranceprobability) + " ")]) : _vm._e(), _vm._v(" "), _vm.selectedRisk.occuranceprobability === "Low" ? _c("el-tag", {
    attrs: {
      type: "info",
      size: "mini"
    }
  }, [_vm._v(_vm._s(_vm.selectedRisk.occuranceprobability) + " ")]) : _vm._e()], 1)]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("div", [_c("strong", [_vm._v("Impact Level")])]), _vm._v(" "), _vm.isEditMode ? _c("div", {
    staticClass: "mt-10"
  }, [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.selectedRisk.impactlevel,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedRisk, "impactlevel", $$v);
      },
      expression: "selectedRisk.impactlevel"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: "Low",
      value: "Low"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Medium",
      value: "Medium"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "High",
      value: "High"
    }
  })], 1)], 1) : _c("div", {
    staticClass: "mt-10"
  }, [_vm.selectedRisk.impactlevel === "High" ? _c("el-tag", {
    attrs: {
      type: "danger",
      size: "mini"
    }
  }, [_vm._v(_vm._s(_vm.selectedRisk.impactlevel) + " ")]) : _vm._e(), _vm._v(" "), _vm.selectedRisk.impactlevel === "Medium" ? _c("el-tag", {
    attrs: {
      type: "warning",
      size: "mini"
    }
  }, [_vm._v(_vm._s(_vm.selectedRisk.impactlevel) + " ")]) : _vm._e(), _vm._v(" "), _vm.selectedRisk.impactlevel === "Low" ? _c("el-tag", {
    attrs: {
      type: "info",
      size: "mini"
    }
  }, [_vm._v(_vm._s(_vm.selectedRisk.impactlevel) + " ")]) : _vm._e()], 1)])], 1), _vm._v(" "), _c("el-row", {
    staticClass: "mt-10",
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("div", [_c("strong", [_vm._v("Risk Strategy")])]), _vm._v(" "), _vm.isEditMode ? _c("el-radio-group", {
    staticClass: "mt-10",
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.selectedRisk.severity,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedRisk, "severity", $$v);
      },
      expression: "selectedRisk.severity"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: "Low",
      value: "Low"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Medium",
      value: "Medium"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "High",
      value: "High"
    }
  })], 1) : _c("div", {
    staticClass: "mt-10"
  }, [_vm.selectedRisk.severity === "High" ? _c("el-tag", {
    attrs: {
      type: "danger",
      size: "mini"
    }
  }, [_vm._v(_vm._s(_vm.selectedRisk.severity) + " ")]) : _vm._e(), _vm._v(" "), _vm.selectedRisk.severity === "Medium" ? _c("el-tag", {
    attrs: {
      type: "warning",
      size: "mini"
    }
  }, [_vm._v(_vm._s(_vm.selectedRisk.severity) + " ")]) : _vm._e(), _vm._v(" "), _vm.selectedRisk.severity === "Low" ? _c("el-tag", {
    attrs: {
      type: "info",
      size: "mini"
    }
  }, [_vm._v(_vm._s(_vm.selectedRisk.severity) + " ")]) : _vm._e()], 1)], 1)], 1)], 1)], 1) : _vm._e()] : _vm._e()], 2)], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      id: "test_my_business_add_risk_dialog",
      title: "Add new risk",
      visible: _vm.addRiskDialog,
      width: "35%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addRiskDialog = $event;
      }
    }
  }, [_c("el-form", {
    ref: "risk_form",
    attrs: {
      model: _vm.risk_form,
      "label-width": "140px",
      rules: _vm.riskRules
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Risk",
      prop: "title"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.risk_form.title,
      callback: function callback($$v) {
        _vm.$set(_vm.risk_form, "title", $$v);
      },
      expression: "risk_form.title"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Description",
      prop: "description"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.risk_form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.risk_form, "description", $$v);
      },
      expression: "risk_form.description"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Severity",
      prop: "severity"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "small"
    },
    model: {
      value: _vm.risk_form.severity,
      callback: function callback($$v) {
        _vm.$set(_vm.risk_form, "severity", $$v);
      },
      expression: "risk_form.severity"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: "Low",
      value: "Low"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Medium",
      value: "Medium"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "High",
      value: "High"
    }
  })], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Occurance",
      prop: "occuranceprobability"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "small"
    },
    model: {
      value: _vm.risk_form.occuranceprobability,
      callback: function callback($$v) {
        _vm.$set(_vm.risk_form, "occuranceprobability", $$v);
      },
      expression: "risk_form.occuranceprobability"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: "Low",
      value: "Low"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Medium",
      value: "Medium"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "High",
      value: "High"
    }
  })], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Impact",
      prop: "impactlevel"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "small"
    },
    model: {
      value: _vm.risk_form.impactlevel,
      callback: function callback($$v) {
        _vm.$set(_vm.risk_form, "impactlevel", $$v);
      },
      expression: "risk_form.impactlevel"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: "Low",
      value: "Low"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Medium",
      value: "Medium"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "High",
      value: "High"
    }
  })], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Association",
      prop: "association"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "small"
    },
    on: {
      change: _vm.onRiskAssociationChange
    },
    model: {
      value: _vm.risk_form.association,
      callback: function callback($$v) {
        _vm.$set(_vm.risk_form, "association", $$v);
      },
      expression: "risk_form.association"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: "Offering",
      value: "Offering",
      disabled: _vm.offerings.length === 0
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Feature",
      value: "ProductFeature",
      disabled: _vm.features.length === 0
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Business",
      value: "Business"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Initiative",
      value: "Initiative",
      disabled: _vm.initiatives.length === 0
    }
  })], 1)], 1), _vm._v(" "), _vm.risk_form.association === "Offering" ? _c("el-form-item", {
    attrs: {
      label: "Offering",
      prop: "linkEntityId"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Select offerings"
    },
    model: {
      value: _vm.risk_form.linkEntityId,
      callback: function callback($$v) {
        _vm.$set(_vm.risk_form, "linkEntityId", $$v);
      },
      expression: "risk_form.linkEntityId"
    }
  }, _vm._l(_vm.offerings, function (offering, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: offering.title,
        value: offering.id
      }
    });
  }), 1)], 1) : _vm._e(), _vm._v(" "), _vm.risk_form.association === "ProductFeature" ? _c("el-form-item", {
    attrs: {
      label: "Feature",
      prop: "linkEntityId"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Select Features"
    },
    model: {
      value: _vm.risk_form.linkEntityId,
      callback: function callback($$v) {
        _vm.$set(_vm.risk_form, "linkEntityId", $$v);
      },
      expression: "risk_form.linkEntityId"
    }
  }, _vm._l(_vm.features, function (feature, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: feature.title,
        value: feature.id
      }
    });
  }), 1)], 1) : _vm._e(), _vm._v(" "), _vm.risk_form.association === "Initiative" ? _c("el-form-item", {
    attrs: {
      label: "Initiative",
      prop: "linkEntityId"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Select Initiatives"
    },
    model: {
      value: _vm.risk_form.linkEntityId,
      callback: function callback($$v) {
        _vm.$set(_vm.risk_form, "linkEntityId", $$v);
      },
      expression: "risk_form.linkEntityId"
    }
  }, _vm._l(_vm.initiatives, function (initiative, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: initiative.title,
        value: initiative.id
      }
    });
  }), 1)], 1) : _vm._e(), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Mitigation Plan",
      prop: "mitigationplan"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.risk_form.mitigationplan,
      callback: function callback($$v) {
        _vm.$set(_vm.risk_form, "mitigationplan", $$v);
      },
      expression: "risk_form.mitigationplan"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "text-right"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.addRisk
    }
  }, [_vm._v("Create")]), _vm._v(" "), _c("el-button", {
    attrs: {
      id: "test_my_business_add_risk_dialog_cancel",
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.addRiskDialog = false;
      }
    }
  }, [_vm._v("Cancel")])], 1)], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;