"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-card", [_c("el-row", {
    staticClass: "mt-10",
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 4
    }
  }, [_vm.multi_user ? _c("el-card", [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("strong", [_vm._v("Features")]), _vm._v(" "), _c("el-button", {
    staticStyle: {
      float: "right",
      padding: "3px 0"
    },
    attrs: {
      type: "text"
    },
    on: {
      click: _vm.selectAllFeatures
    }
  }, [_vm._v("Select All")])], 1), _vm._v(" "), _c("el-input", {
    attrs: {
      size: "mini",
      placeholder: "Search Feature"
    },
    model: {
      value: _vm.feature_search,
      callback: function callback($$v) {
        _vm.feature_search = $$v;
      },
      expression: "feature_search"
    }
  }), _vm._v(" "), _c("el-menu", {
    staticClass: "el-menu-vertical-demo",
    attrs: {
      "default-active": "0-0"
    }
  }, _vm._l(_vm.filteredFeatures, function (feature_datum, index) {
    return _c("el-menu-item", {
      key: index,
      attrs: {
        index: _vm.generateFeatureMenuIndex(index)
      }
    }, [_c("el-checkbox", {
      staticStyle: {
        "margin-top": "-5px"
      },
      model: {
        value: feature_datum.is_visible,
        callback: function callback($$v) {
          _vm.$set(feature_datum, "is_visible", $$v);
        },
        expression: "feature_datum.is_visible"
      }
    }), _vm._v(" "), _c("span", [_vm._v(_vm._s(feature_datum.label))])], 1);
  }), 1)], 1) : _vm._e(), _vm._v(" "), _c("el-card", {
    staticClass: "mt-10"
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("strong", [_vm._v("Users")]), _vm._v(" "), _c("el-button", {
    staticStyle: {
      float: "right",
      padding: "3px 0"
    },
    attrs: {
      type: "text"
    },
    on: {
      click: _vm.goToGroupSimilarCustomer
    }
  }, [_vm._v("Add User Type")])], 1), _vm._v(" "), _c("el-menu", {
    staticClass: "el-menu-vertical-demo",
    attrs: {
      "default-active": "1-0"
    }
  }, _vm._l(_vm.groups, function (group, index) {
    return _c("el-menu-item", {
      key: index,
      attrs: {
        index: _vm.generateUserMenuIndex(index)
      },
      on: {
        click: function click($event) {
          _vm.selectedGroup = group;
        }
      }
    }, [!_vm.multi_user ? _c("i", {
      staticClass: "el-icon-menu"
    }) : _c("el-checkbox", {
      staticStyle: {
        "margin-top": "-5px"
      },
      model: {
        value: group.is_visible,
        callback: function callback($$v) {
          _vm.$set(group, "is_visible", $$v);
        },
        expression: "group.is_visible"
      }
    }), _vm._v(" "), _c("span", [_vm._v(_vm._s(group.name))])], 1);
  }), 1)], 1), _vm._v(" "), _c("el-card", {
    staticClass: "mt-10 text-center"
  }, [_c("span", [_vm._v("Is there just one user for your product, or will different user use different features? ")]), _vm._v(" "), _c("el-switch", {
    attrs: {
      "inactive-text": "Single User",
      "active-text": "Multi User"
    },
    model: {
      value: _vm.multi_user,
      callback: function callback($$v) {
        _vm.multi_user = $$v;
      },
      expression: "multi_user"
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 20
    }
  }, [_c("el-card", [_c("el-tabs", {
    model: {
      value: _vm.tab_active,
      callback: function callback($$v) {
        _vm.tab_active = $$v;
      },
      expression: "tab_active"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "Type",
      name: "type"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("div", {
    staticClass: "sub-title-left"
  }, [_vm._v("Detailed User Description")]), _vm._v(" "), _c("div", {
    staticClass: "pl-10 pr-10"
  }, [_c("span", {
    staticClass: "text_area_header",
    staticStyle: {
      "font-weight": "bold"
    }
  }, [_vm._v("Name:")]), _vm._v(" "), _vm.is_edit ? _c("el-input", {
    staticStyle: {
      "margin-bottom": "15pt"
    },
    attrs: {
      placeholder: "Short Description"
    },
    model: {
      value: _vm.selectedGroup.name,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedGroup, "name", $$v);
      },
      expression: "selectedGroup.name"
    }
  }) : _c("p", [_vm._v(_vm._s(_vm.selectedGroup.name) + " ")]), _vm._v(" "), _c("span", {
    staticClass: "text_area_header",
    staticStyle: {
      "font-weight": "bold"
    }
  }, [_vm._v("Description")]), _vm._v(" "), _vm.is_edit ? _c("el-input", {
    staticStyle: {
      "margin-bottom": "15pt"
    },
    attrs: {
      type: "textarea",
      rows: "3",
      placeholder: "# Customers"
    },
    model: {
      value: _vm.selectedGroup.description,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedGroup, "description", $$v);
      },
      expression: "selectedGroup.description"
    }
  }) : _c("p", [_vm._v(_vm._s(_vm.selectedGroup.description) + " ")])], 1)]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-row", {
    attrs: {
      span: 22
    }
  }, [_c("div", {
    staticClass: "sub-title-left"
  }, [_vm._v("\n\n                      User Type Priority\n                      "), _c("el-popover", {
    attrs: {
      title: "User Priority affects Backlog Prioritisation",
      placement: "bottom-start",
      width: "380",
      trigger: "hover",
      "open-delay": 400,
      "close-delay": 400,
      "popper-class": "popper-help"
    }
  }, [_c("span", {
    staticClass: "pop-over-text"
  }, [_vm._v("Backlog prioritisation is complex and expensive.  By assigning priorities to user types, the process of backlog prioritisation can be simplified.")]), _vm._v(" "), _c("el-icon", {
    staticClass: "el-icon-info help-icon",
    attrs: {
      slot: "reference"
    },
    slot: "reference"
  })], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "pl-10 pr-10",
    staticStyle: {
      "margin-bottom": "20pt"
    }
  }, [_c("div", {
    staticClass: "text-left"
  }, [_c("div", {
    staticClass: "align-middle",
    staticStyle: {
      "font-weight": "bold",
      display: "inline-block",
      "vertical-align": "middle"
    }
  }, [_c("span", [_vm._v(" Priority: ")])]), _vm._v(" "), _c("div", {
    staticStyle: {
      display: "inline-block"
    }
  }, [_c("el-radio-group", {
    staticStyle: {
      margin: "10pt 0 20pt 12pt"
    },
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.selectedGroup.priority,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedGroup, "priority", $$v);
      },
      expression: "selectedGroup.priority"
    }
  }, _vm._l(_vm.user_type_priority_list, function (user_type_priority, index) {
    return _c("el-radio-button", {
      key: index,
      staticStyle: {
        "margin-bottom": "0",
        "padding-top": "6pt"
      },
      attrs: {
        label: user_type_priority.upriority
      }
    });
  }), 1)], 1)]), _vm._v(" "), _c("span", {
    staticClass: "text_area_header",
    staticStyle: {
      "font-weight": "bold"
    }
  }, [_vm._v("Priority Rationale")]), _vm._v(" "), _vm.is_edit ? _c("el-input", {
    attrs: {
      type: "textarea",
      rows: "3",
      placeholder: "Describe why this level of priority has been set.  This will affect backlog prioritisation."
    },
    model: {
      value: _vm.selectedGroup.priority_rationale,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedGroup, "priority_rationale", $$v);
      },
      expression: "selectedGroup.priority_rationale"
    }
  }) : _c("p", [_vm._v(_vm._s(_vm.selectedGroup.priority_rationale) + " ")])], 1)]), _vm._v(" "), _c("div", {
    staticClass: "text-right"
  }, [!_vm.is_edit ? _c("el-button", {
    attrs: {
      size: "mini",
      type: "primary",
      icon: "el-icon-edit"
    },
    on: {
      click: function click($event) {
        _vm.is_edit = true;
      }
    }
  }, [_vm._v("Edit")]) : _c("el-button", {
    attrs: {
      size: "mini",
      type: "primary",
      icon: "el-icon-document-checked"
    },
    on: {
      click: function click($event) {
        return _vm.save();
      }
    }
  }, [_vm._v("Save")])], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-tabs", {
    attrs: {
      value: "feature"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "Feature",
      name: "feature"
    }
  }, [_c("el-transfer", {
    attrs: {
      filterable: "",
      "filter-method": _vm.filterMethod,
      "filter-placeholder": "Enter feature",
      titles: ["List 1", "List 2"],
      data: _vm.features
    },
    model: {
      value: _vm.feature,
      callback: function callback($$v) {
        _vm.feature = $$v;
      },
      expression: "feature"
    }
  })], 1), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: "User Research",
      name: "user_research"
    }
  }, [_c("el-row", [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("div", {
    staticClass: "pr-10 pl-10"
  }, [_c("span", {
    staticClass: "text_area_header",
    staticStyle: {
      "font-weight": "bold"
    }
  }, [_vm._v("Customer Persona")]), _vm._v(" "), _c("el-input", {
    staticStyle: {
      "margin-bottom": "15pt"
    },
    attrs: {
      type: "textarea",
      rows: "12",
      placeholder: "Describe a day in the life of this user.  Use 2-3 paragraphs."
    }
  }), _vm._v(" "), _c("span", {
    staticClass: "text_area_header",
    staticStyle: {
      "font-weight": "bold"
    }
  }, [_vm._v("Problem Statement")]), _vm._v(" "), _c("el-input", {
    staticStyle: {
      "margin-bottom": "15pt"
    },
    attrs: {
      type: "textarea",
      rows: "3",
      placeholder: "Describe the 'user problem' this product will solve, ie, catching a bus, ordering dinner, etc"
    }
  }), _vm._v(" "), _c("span", {
    staticClass: "text_area_header",
    staticStyle: {
      "font-weight": "bold"
    }
  }, [_vm._v("Solution")]), _vm._v(" "), _c("el-input", {
    staticStyle: {
      "margin-bottom": "15pt"
    },
    attrs: {
      type: "textarea",
      rows: "3",
      placeholder: "Describe what customer goals this product will meet. Eg, more efficient"
    }
  }), _vm._v(" "), _c("span", {
    staticClass: "text_area_header",
    staticStyle: {
      "font-weight": "bold"
    }
  }, [_vm._v("Innovation statement")]), _vm._v(" "), _c("el-input", {
    attrs: {
      type: "textarea",
      rows: "3",
      placeholder: "What will create a 'Wow - I didn't know that was possible' experience for this customer type?"
    }
  })], 1)]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("div", {
    staticClass: "sub-title-left"
  }, [_vm._v("\n                            Pains and Gains\n                            "), _c("el-popover", {
    attrs: {
      title: "User Journey Pains and Gains",
      placement: "bottom-start",
      width: "380",
      trigger: "hover",
      "open-delay": 400,
      "close-delay": 400,
      "popper-class": "popper-help"
    }
  }, [_c("span", {
    staticClass: "pop-over-text"
  }, [_vm._v("This section summarise the pains and gains from this user types customer journey.  If the journey is not yet defined, this list will be empty.")]), _vm._v(" "), _c("el-icon", {
    staticClass: "el-icon-info help-icon",
    attrs: {
      slot: "reference"
    },
    slot: "reference"
  })], 1)], 1), _vm._v(" "), _c("a", {
    staticClass: "pl-20 pr-20"
  }, [_vm._v(" ...empty..this list will be long so leave alot of space below")])])], 1)], 1)], 1)], 1)], 1)], 1), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      disabled: !_vm.multi_user,
      label: "Feature Mapping",
      name: "feature-mapping"
    }
  }, [_c("table", [_c("thead", [_c("tr", [_c("th", {
    attrs: {
      scope: "col"
    }
  }, [_vm._v(" Feature ")]), _vm._v(" "), _vm._l(_vm.groups, function (group, index) {
    return [group.is_visible ? _c("th", {
      key: index,
      attrs: {
        scope: "col"
      }
    }, [_vm._v(_vm._s(group.name))]) : _vm._e()];
  })], 2)]), _vm._v(" "), _c("tbody", [_vm._l(_vm.features, function (feature_datum, index) {
    return [feature_datum.is_visible ? _c("tr", {
      key: index,
      class: {
        bg_highlight: feature_datum.all_users_selected
      }
    }, [_c("td", [_vm._v(_vm._s(feature_datum.label))]), _vm._v(" "), _vm._l(_vm.groups, function (group, sub_index) {
      return [group.is_visible ? _c("td", {
        key: sub_index,
        class: {
          bg_highlight: group.all_features_selected
        },
        attrs: {
          scope: "col"
        }
      }, [_c("input", {
        directives: [{
          name: "model",
          rawName: "v-model",
          value: feature_datum.users,
          expression: "feature_datum.users"
        }],
        attrs: {
          id: "checkbox",
          type: "checkbox"
        },
        domProps: {
          value: group.name,
          checked: Array.isArray(feature_datum.users) ? _vm._i(feature_datum.users, group.name) > -1 : feature_datum.users
        },
        on: {
          change: function change($event) {
            var $$a = feature_datum.users,
              $$el = $event.target,
              $$c = $$el.checked ? true : false;
            if (Array.isArray($$a)) {
              var $$v = group.name,
                $$i = _vm._i($$a, $$v);
              if ($$el.checked) {
                $$i < 0 && _vm.$set(feature_datum, "users", $$a.concat([$$v]));
              } else {
                $$i > -1 && _vm.$set(feature_datum, "users", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
              }
            } else {
              _vm.$set(feature_datum, "users", $$c);
            }
          }
        }
      })]) : _vm._e()];
    })], 2) : _vm._e()];
  })], 2)])])], 1)], 1)], 1)], 1)], 1), _vm._v(" "), _c("el-card", {
    staticClass: "mt-10"
  }, [_c("div", {
    staticClass: "text-right"
  }, [_c("el-button-group", {
    attrs: {
      size: "mini"
    }
  }, [_c("el-tooltip", {
    attrs: {
      effect: "dark",
      content: "Go To Group customer to segments",
      placement: "top-start"
    }
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      icon: "el-icon-arrow-left"
    },
    on: {
      click: _vm.goToGroupSimilarCustomer
    }
  }, [_vm._v("Previous")])], 1), _vm._v(" "), _c("el-tooltip", {
    attrs: {
      effect: "dark",
      content: "Go To Define User Journey",
      placement: "top-start"
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.goToCustomerLink
    }
  }, [_vm._v("Next"), _c("i", {
    staticClass: "el-icon-arrow-right"
  })])], 1)], 1)], 1)])], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;