"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-form", {
    ref: "customerForm",
    attrs: {
      model: _vm.customer_form,
      "label-width": "150px",
      rules: _vm.customerRules
    }
  }, [_c("div", {
    staticClass: "no-word-breaks",
    staticStyle: {
      "margin-top": "15pt"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Customer Name",
      prop: "title"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "What is your customer name",
      size: "mini"
    },
    model: {
      value: _vm.customer_form.title,
      callback: function callback($$v) {
        _vm.$set(_vm.customer_form, "title", $$v);
      },
      expression: "customer_form.title"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Description",
      prop: "description"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      rows: "4",
      placeholder: "Describe your customer here",
      size: "mini"
    },
    model: {
      value: _vm.customer_form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.customer_form, "description", $$v);
      },
      expression: "customer_form.description"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "text-right no-word-breaks"
  }, [_c("el-button", {
    attrs: {
      icon: _vm.loading ? "el-icon-loading" : "",
      disabled: _vm.loading,
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.createCustomer
    }
  }, [_c("svg-icon", {
    staticStyle: {
      color: "white"
    },
    attrs: {
      "icon-class": "create"
    }
  }), _vm._v(" Create")], 1), _vm._v(" "), _c("el-button", {
    attrs: {
      icon: _vm.loading ? "el-icon-loading" : "el-icon-close",
      disabled: _vm.loading,
      size: "mini",
      type: "secondary"
    },
    on: {
      click: _vm.cancelCreateCustomer
    }
  }, [_vm._v("Cancel")])], 1)], 1)])], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;