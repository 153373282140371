"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "no-padding app-container"
  }, [_c("el-row", {
    staticClass: "title text-white"
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("span", [_vm._v(_vm._s(_vm.selectedFeature.name))])])], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-table", {
    attrs: {
      data: _vm.selectedFeature.stories,
      "default-sort": {
        prop: "name",
        order: "descending"
      },
      "highlight-current-row": true,
      size: "small"
    },
    on: {
      "row-click": _vm.selectStory
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "expand"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope_child) {
        return [_c("el-row", {
          staticStyle: {
            "padding-top": "0px"
          },
          attrs: {
            gutter: 20
          }
        }, [_c("el-col", {
          attrs: {
            span: 10
          }
        }, [_c("el-row", [_c("el-col", {
          attrs: {
            span: 12
          }
        }, [_c("span", [_vm._v("Story Details")])])], 1), _vm._v(" "), _c("div", [_c("el-row", [_c("el-col", {
          attrs: {
            span: 22
          }
        }, [_c("span", [_vm._v("Name:")]), _vm._v(" "), _c("el-input", {
          attrs: {
            size: "mini"
          },
          model: {
            value: scope_child.row.name,
            callback: function callback($$v) {
              _vm.$set(scope_child.row, "name", $$v);
            },
            expression: "scope_child.row.name"
          }
        })], 1)], 1), _vm._v(" "), _c("el-row", [_c("el-col", {
          attrs: {
            span: 22
          }
        }, [_c("span", [_vm._v("Description")]), _vm._v(" "), _c("el-input", {
          attrs: {
            type: "textarea",
            autosize: "",
            size: "mini"
          },
          model: {
            value: scope_child.row.description,
            callback: function callback($$v) {
              _vm.$set(scope_child.row, "description", $$v);
            },
            expression: "scope_child.row.description"
          }
        })], 1)], 1), _vm._v(" "), _c("el-row", {
          attrs: {
            gutter: 20
          }
        }, [_c("el-col", {
          attrs: {
            span: 24
          }
        }, [_c("el-row", [_c("el-col", {
          attrs: {
            span: 12
          }
        }, [_c("span", [_vm._v("Assigned: " + _vm._s(scope_child.row.assigned_to))])]), _vm._v(" "), _c("el-col", {
          attrs: {
            span: 12
          }
        }, [_c("Span", [_vm._v(" Story Type:")]), _vm._v(" "), _c("el-radio-group", {
          attrs: {
            disabled: "",
            size: "mini"
          },
          model: {
            value: scope_child.row.enabler,
            callback: function callback($$v) {
              _vm.$set(scope_child.row, "enabler", $$v);
            },
            expression: "scope_child.row.enabler"
          }
        }, [_c("el-radio-button", {
          attrs: {
            label: "User Story"
          }
        }), _vm._v(" "), _c("el-radio-button", {
          attrs: {
            label: "Enabling Story"
          }
        })], 1)], 1)], 1)], 1), _vm._v(" "), _c("el-col", {
          attrs: {
            span: 24
          }
        }, [_c("el-row", [_c("el-col", {
          attrs: {
            span: 12
          }
        }, [_c("span", [_vm._v("Created By: " + _vm._s(scope_child.row.created_by))])]), _vm._v(" "), _c("el-col", {
          attrs: {
            span: 12
          }
        }, [_c("el-button", {
          attrs: {
            size: "mini"
          },
          on: {
            click: function click($event) {
              _vm.storyUserAssignedHistoryDialog = true;
            }
          }
        }, [_vm._v("History")])], 1)], 1)], 1)], 1)], 1)], 1), _vm._v(" "), _c("el-col", {
          staticClass: "story-container-steps",
          attrs: {
            span: 12
          }
        }, [_c("el-row", [_c("el-col", {
          attrs: {
            span: 24
          }
        }, [_c("span", {
          staticClass: "story-container-main-column_header"
        }, [_vm._v("Story Steps")]), _vm._v(" "), _c("el-button", {
          staticClass: "blue_button_light",
          staticStyle: {
            float: "right"
          },
          attrs: {
            icon: "el-icon-edit",
            size: "mini"
          }
        })], 1)], 1), _vm._v(" "), _c("div", _vm._l(scope_child.row.steps, function (step, index) {
          return _c("div", {
            key: index,
            staticStyle: {
              margin: "5px",
              "background-color": "whitesmoke"
            }
          }, [_c("el-row", {
            attrs: {
              gutter: 20
            }
          }, [_c("el-col", {
            attrs: {
              span: 24
            }
          }, [_c("span", [_vm._v(_vm._s(step.name))])])], 1)], 1);
        }), 0)], 1)], 1)];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "name",
      label: "Story Name",
      width: "300",
      sortable: ""
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "value",
      label: "Importance",
      sortable: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope_child) {
        return [scope_child.row.is_stratergic ? _c("svg-icon", {
          attrs: {
            "icon-class": "chess-rook",
            title: "Stratergic"
          }
        }) : _vm._e(), _vm._v(" "), scope_child.row.value ? _c("svg-icon", {
          attrs: {
            "icon-class": _vm.getValueIcon(scope_child.row.value),
            title: "Value"
          }
        }) : _vm._e(), _vm._v(" "), scope_child.row.priority ? _c("svg-icon", {
          attrs: {
            "icon-class": _vm.getPriorityIcon(scope_child.row.priority),
            title: "Priority"
          }
        }) : _vm._e()];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "enabler",
      label: "Enabler",
      sortable: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope_child) {
        return [scope_child.row.enabler ? _c("svg-icon", {
          attrs: {
            "icon-class": "gear"
          }
        }) : _vm._e()];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "status",
      label: "Status",
      sortable: ""
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "first_added",
      label: "First Added",
      sortable: ""
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "improved_in",
      label: "Improved In",
      sortable: ""
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "prime_user.name",
      label: "Prime User",
      sortable: ""
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "other_users",
      label: "Other Users",
      sortable: ""
    }
  })], 1), _vm._v(" "), _c("el-row", [_c("el-col", {
    attrs: {
      span: 22
    }
  }, [_c("el-button", {
    staticClass: "blue_button",
    staticStyle: {
      float: "right",
      "margin-right": "20px"
    },
    attrs: {
      size: "mini",
      icon: "el-icon-plus"
    },
    on: {
      click: function click($event) {
        _vm.addStoryDialog = true;
      }
    }
  }, [_vm._v("Add Story")])], 1)], 1)], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Add Story",
      visible: _vm.addStoryDialog,
      width: "90%",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addStoryDialog = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.story_form,
      "label-width": "120px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Name"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.story_form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.story_form, "name", $$v);
      },
      expression: "story_form.name"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Description"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.story_form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.story_form, "description", $$v);
      },
      expression: "story_form.description"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Options"
    }
  }, [_c("el-checkbox", {
    model: {
      value: _vm.story_form.enabler,
      callback: function callback($$v) {
        _vm.$set(_vm.story_form, "enabler", $$v);
      },
      expression: "story_form.enabler"
    }
  }, [_vm._v("Enabler")]), _vm._v(" "), _c("el-checkbox", {
    model: {
      value: _vm.story_form.is_stratergic,
      callback: function callback($$v) {
        _vm.$set(_vm.story_form, "is_stratergic", $$v);
      },
      expression: "story_form.is_stratergic"
    }
  }, [_vm._v("Stratergic")])], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Value"
    }
  }, [_c("el-slider", {
    attrs: {
      max: 10
    },
    model: {
      value: _vm.story_form.value,
      callback: function callback($$v) {
        _vm.$set(_vm.story_form, "value", $$v);
      },
      expression: "story_form.value"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Priority"
    }
  }, [_c("el-slider", {
    attrs: {
      max: 5
    },
    model: {
      value: _vm.story_form.priority,
      callback: function callback($$v) {
        _vm.$set(_vm.story_form, "priority", $$v);
      },
      expression: "story_form.priority"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Status"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Story status"
    },
    model: {
      value: _vm.story_form.status,
      callback: function callback($$v) {
        _vm.$set(_vm.story_form, "status", $$v);
      },
      expression: "story_form.status"
    }
  }, [_c("el-option", {
    attrs: {
      label: "Operational",
      value: "Operational"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Business Design",
      value: "Business Design"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Rejected",
      value: "Rejected"
    }
  })], 1)], 1), _vm._v(" "), _c("el-tabs", {
    attrs: {
      type: "card"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "User"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Prime User"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.story_form.prime_user.name,
      callback: function callback($$v) {
        _vm.$set(_vm.story_form.prime_user, "name", $$v);
      },
      expression: "story_form.prime_user.name"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Design Goal"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.story_form.prime_user.design_goal,
      callback: function callback($$v) {
        _vm.$set(_vm.story_form.prime_user, "design_goal", $$v);
      },
      expression: "story_form.prime_user.design_goal"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Other Users"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Other Users",
      multiple: ""
    },
    model: {
      value: _vm.story_form.other_users,
      callback: function callback($$v) {
        _vm.$set(_vm.story_form, "other_users", $$v);
      },
      expression: "story_form.other_users"
    }
  }, [_c("el-option", {
    attrs: {
      label: "User A",
      value: "User A"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "User B",
      value: "User B"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "User C",
      value: "User C"
    }
  })], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Assigned to"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Assigned to"
    },
    model: {
      value: _vm.story_form.assigned_to,
      callback: function callback($$v) {
        _vm.$set(_vm.story_form, "assigned_to", $$v);
      },
      expression: "story_form.assigned_to"
    }
  }, [_c("el-option", {
    attrs: {
      label: "User A",
      value: "User A"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "User B",
      value: "User B"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "User C",
      value: "User C"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: "Step"
    }
  }, [_c("el-row", [_c("el-col", {
    attrs: {
      offset: 18,
      span: 6
    }
  }, [_c("el-button", {
    attrs: {
      icon: "el-icon-plus",
      size: "mini"
    },
    on: {
      click: _vm.addFormStoryStep
    }
  }, [_vm._v("Add Step")])], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Steps"
    }
  }, [_vm._l(_vm.story_form.steps, function (step, index) {
    return [_c("el-input", {
      key: index,
      model: {
        value: step.name,
        callback: function callback($$v) {
          _vm.$set(step, "name", $$v);
        },
        expression: "step.name"
      }
    })];
  })], 2)], 1)], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.addStory
    }
  }, [_vm._v("Add")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.addStoryDialog = false;
      }
    }
  }, [_vm._v("Cancel")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Story user assigned history",
      visible: _vm.storyUserAssignedHistoryDialog,
      width: "90%",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.storyUserAssignedHistoryDialog = $event;
      }
    }
  }, [_c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.selectedStory.assign_history
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "name",
      label: "Name"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "status",
      label: "Status"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "assigned_on",
      label: "Date"
    }
  })], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      slot: "button",
      name: "closeButton",
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.storyUserAssignedHistoryDialog = false;
      }
    },
    slot: "button"
  }, [_vm._v("\n        Close\n      ")])], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;