"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-form", {
    attrs: {
      inline: true,
      model: _vm.roadmap
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Roadmap Title"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "Title",
      size: "mini"
    },
    model: {
      value: _vm.roadmap.title,
      callback: function callback($$v) {
        _vm.$set(_vm.roadmap, "title", $$v);
      },
      expression: "roadmap.title"
    }
  })], 1), _vm._v(" "), _c("el-form-item", [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.renameRoadmapTitle
    }
  }, [_vm._v("Rename")])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-tabs", {
    model: {
      value: _vm.activeTabName,
      callback: function callback($$v) {
        _vm.activeTabName = $$v;
      },
      expression: "activeTabName"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "Roadmap",
      name: "roadmap"
    }
  }, [_c("el-table", {
    attrs: {
      data: _vm.roadmapSettings,
      stripe: "",
      size: "small"
    }
  }, [_c("el-table-column", {
    attrs: {
      width: "180"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.type === "delete" ? _c("el-button", {
          staticStyle: {
            width: "100px"
          },
          attrs: {
            type: "danger",
            size: "mini"
          },
          on: {
            click: _vm.deleteRoadmapConfirmation
          }
        }, [_vm._v("Delete")]) : _vm._e()];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      width: "400pt"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("i", {
          staticStyle: {
            "word-break": "keep-all"
          }
        }, [_vm._v(_vm._s(scope.row.description) + " ")])];
      }
    }])
  })], 1)], 1), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: "Release",
      name: "release"
    }
  }, [_c("el-table", {
    attrs: {
      data: _vm.releaseSettings,
      stripe: "",
      size: "small"
    }
  }, [_c("el-table-column", {
    attrs: {
      width: "180"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("Strong", [_vm._v(_vm._s(scope.row.title))])];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      width: "280"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.type === "delete" ? _c("el-button", {
          staticStyle: {
            width: "100px"
          },
          attrs: {
            type: "danger",
            size: "mini"
          },
          on: {
            click: _vm.deleteReleaseConfirmation
          }
        }, [_vm._v("Delete")]) : _vm._e(), _vm._v(" "), scope.row.type === "default" ? _c("el-input-number", {
          attrs: {
            size: "small",
            min: 4,
            max: 36
          },
          on: {
            change: _vm.handleChange
          },
          model: {
            value: _vm.setting.release.timeline,
            callback: function callback($$v) {
              _vm.$set(_vm.setting.release, "timeline", $$v);
            },
            expression: "setting.release.timeline"
          }
        }) : _vm._e(), _vm._v(" "), scope.row.type === "color" ? _c("div", [_c("table", [_c("tr", [_c("td", {
          staticStyle: {
            "background-color": "unset",
            padding: "5pt",
            "border-radius": "5pt",
            border: "solid 0.5pt lightgrey"
          }
        }, [_c("strong", [_vm._v("Title ")]), _c("el-color-picker", {
          staticStyle: {
            "vertical-align": "middle"
          },
          attrs: {
            "show-alpha": "",
            size: "mini"
          },
          model: {
            value: _vm.setting.release.color.title,
            callback: function callback($$v) {
              _vm.$set(_vm.setting.release.color, "title", $$v);
            },
            expression: "setting.release.color.title"
          }
        }), _vm._v(" "), _c("strong", [_vm._v("Column ")]), _c("el-color-picker", {
          staticStyle: {
            "vertical-align": "middle"
          },
          attrs: {
            "show-alpha": "",
            size: "mini"
          },
          model: {
            value: _vm.setting.release.color.column,
            callback: function callback($$v) {
              _vm.$set(_vm.setting.release.color, "column", $$v);
            },
            expression: "setting.release.color.column"
          }
        })], 1)])])]) : _vm._e()];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("span", [_vm._v(_vm._s(scope.row.description) + "  ")]), _vm._v(" "), _c("span", [_vm._v(_vm._s(scope.row.note.description) + " ")])];
      }
    }])
  })], 1)], 1), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: "Increment",
      name: "increment"
    }
  }, [_c("el-table", {
    attrs: {
      data: _vm.incrementSettings,
      stripe: "",
      size: "small"
    }
  }, [_c("el-table-column", {
    attrs: {
      width: "200"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("Strong", [_vm._v(_vm._s(scope.row.title))]), _vm._v(" "), _c("br")];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      width: "420"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.type === "color" ? _c("div") : _vm._e(), _vm._v(" "), scope.row.type === "prefix" ? _c("el-input", {
          attrs: {
            type: "text",
            size: "mini"
          },
          model: {
            value: _vm.setting.increment.prefix,
            callback: function callback($$v) {
              _vm.$set(_vm.setting.increment, "prefix", $$v);
            },
            expression: "setting.increment.prefix"
          }
        }) : _vm._e(), _vm._v(" "), scope.row.type === "color" ? _c("div", [_c("table", [_c("tr", [_c("td", {
          staticStyle: {
            "background-color": "unset",
            padding: "5pt",
            "border-radius": "5pt",
            border: "solid 0.5pt lightgrey"
          }
        }, [_c("strong", [_vm._v("Title ")]), _c("el-color-picker", {
          staticStyle: {
            "vertical-align": "middle"
          },
          attrs: {
            "show-alpha": "",
            size: "mini"
          },
          model: {
            value: _vm.setting.increment.color.title,
            callback: function callback($$v) {
              _vm.$set(_vm.setting.increment.color, "title", $$v);
            },
            expression: "setting.increment.color.title"
          }
        }), _vm._v(" "), _c("strong", [_vm._v("Column ")]), _c("el-color-picker", {
          staticStyle: {
            "vertical-align": "middle"
          },
          attrs: {
            "show-alpha": "",
            size: "mini"
          },
          model: {
            value: _vm.setting.increment.color.column,
            callback: function callback($$v) {
              _vm.$set(_vm.setting.increment.color, "column", $$v);
            },
            expression: "setting.increment.color.column"
          }
        })], 1)])])]) : _vm._e(), _vm._v(" "), scope.row.type === "default" ? _c("el-radio-group", {
          attrs: {
            size: "mini"
          },
          model: {
            value: _vm.setting.increment.timeline,
            callback: function callback($$v) {
              _vm.$set(_vm.setting.increment, "timeline", $$v);
            },
            expression: "setting.increment.timeline"
          }
        }, [_c("el-radio-button", {
          attrs: {
            size: "mini",
            label: "1"
          }
        }, [_vm._v("1")]), _vm._v(" "), _c("el-radio-button", {
          attrs: {
            size: "mini",
            label: "2"
          }
        }, [_vm._v("2")]), _vm._v(" "), _c("el-radio-button", {
          attrs: {
            size: "mini",
            label: "3"
          }
        }, [_vm._v("3")]), _vm._v(" "), _c("el-radio-button", {
          attrs: {
            size: "mini",
            label: "4"
          }
        }, [_vm._v("4")])], 1) : _vm._e(), _vm._v(" "), scope.row.type === "delete" ? _c("el-radio-group", {
          staticClass: "increment-delete",
          attrs: {
            size: "mini"
          },
          model: {
            value: _vm.setting.increment.delete,
            callback: function callback($$v) {
              _vm.$set(_vm.setting.increment, "delete", $$v);
            },
            expression: "setting.increment.delete"
          }
        }, [_c("el-radio-button", {
          attrs: {
            size: "mini",
            label: "shrink"
          }
        }, [_vm._v("Shrink")]), _vm._v(" "), _c("el-radio-button", {
          attrs: {
            size: "mini",
            label: "resize"
          }
        }, [_vm._v("Resize")]), _vm._v(" "), _c("el-radio-button", {
          attrs: {
            size: "mini",
            label: "disabled"
          }
        }, [_vm._v("Disabled")])], 1) : _vm._e()];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("span", {
          attrs: {
            slot: "title"
          },
          slot: "title"
        }, [_vm._v(_vm._s(scope.row.note.title))]), _vm._v(" "), _c("div", {
          domProps: {
            innerHTML: _vm._s(scope.row.note.description)
          }
        })];
      }
    }])
  })], 1)], 1)], 1)], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "text-right mt-10"
  }, [_c("el-button-group", [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.update();
      }
    }
  }, [_vm._v("Update")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "secondary",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.closeDialog();
      }
    }
  }, [_vm._v("Close")])], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;