"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }]
  }, [_c("h1", {
    staticStyle: {
      "margin-left": "-10pt"
    }
  }, [_vm._v("Ticket Priority")]), _vm._v(" "), _c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.featureAndProcessList && _vm.featureAndProcessList.length > 0 ? _c("el-table", {
    staticClass: "value_priority_tables ml-10",
    attrs: {
      data: _vm.featureAndProcessList,
      "row-key": "id",
      stripe: "",
      size: "micro",
      "default-sort": {
        prop: "priority",
        order: "descending"
      }
    }
  }, [_c("el-table-column", {
    staticStyle: {
      "vertical-align": "top"
    },
    attrs: {
      label: "#",
      sortable: "",
      width: "100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("span", {
          staticStyle: {
            "border-radius": "15pt",
            padding: "1pt 3pt 1pt 3pt",
            "nmargin-right": "5pt",
            "background-color": "lightslategrey",
            color: "white",
            width: "22pt",
            height: "22pt"
          }
        }, [_vm._v(" " + _vm._s(scope.$index + 1))])];
      }
    }], null, false, 2845929259)
  }), _vm._v(" "), _c("el-table-column", {
    staticStyle: {
      "vertical-align": "top"
    },
    attrs: {
      label: "Feature/Process",
      prop: "title"
    }
  }), _vm._v(" "), _c("el-table-column", {
    staticStyle: {
      "vertical-align": "top"
    },
    attrs: {
      label: "Description"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("span", {
          attrs: {
            title: scope.row.description
          }
        }, [_vm._v(_vm._s(_vm.trimString(scope.row.description, 40)))])];
      }
    }], null, false, 2229491956)
  }), _vm._v(" "), _c("el-table-column", {
    staticStyle: {
      "vertical-align": "top"
    },
    attrs: {
      label: "Status",
      prop: "status"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-tag", {
          attrs: {
            size: "mini"
          }
        }, [_vm._v(_vm._s(scope.row.status))])];
      }
    }], null, false, 811231746)
  }), _vm._v(" "), _c("el-table-column", {
    staticStyle: {
      "vertical-align": "top"
    },
    attrs: {
      label: "Priority",
      prop: "priority",
      sortable: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-tag", {
          attrs: {
            size: "mini"
          }
        }, [_vm._v(_vm._s(scope.row.priority))])];
      }
    }], null, false, 555127428)
  }), _vm._v(" "), _c("el-table-column", {
    staticStyle: {
      "vertical-align": "top"
    },
    attrs: {
      label: "Smart Priority",
      prop: "smart_priority",
      sortable: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-tag", {
          attrs: {
            size: "mini"
          }
        }, [_vm._v(_vm._s(scope.row.smart_priority))])];
      }
    }], null, false, 1341791266)
  })], 1) : _vm._e(), _vm._v(" "), _vm.featureAndProcessList && _vm.featureAndProcessList.length === 0 ? _c("el-empty") : _vm._e()], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;