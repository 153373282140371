"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
var _objectSpread2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/objectSpread2.js"));
require("core-js/modules/es6.number.constructor");
var _interopRequireWildcard2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _vuex = require("vuex");
var _default2 = exports.default = {
  name: 'Touchpoints',
  components: {
    TouchpointComponent: function TouchpointComponent() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./Touchpoint.vue"));
      });
    }
  },
  props: {
    x: {
      type: Number,
      default: 0
    },
    y: {
      type: Number,
      default: 0
    },
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
    phaseX: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    stages: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    isCompactMenubar: {
      type: Boolean,
      default: true
    },
    journeyMap: {
      type: Object,
      required: true,
      default: function _default() {
        return {
          id: '',
          title: '',
          description: '',
          additionalattributes: {},
          linkedJourneyExperiences: [],
          linkedJourneyPhases: [],
          linkedJourneySteps: []
        };
      }
    }
  },
  data: function data() {
    return {
      isHover: false,
      label: 'Touchpoints',
      touchpoints: [{
        'title': 'Email',
        'description': 'Email',
        'type': 'email'
      }, {
        'title': 'Web',
        'description': 'Web',
        'type': 'web'
      }, {
        'title': 'App',
        'description': 'App',
        'type': 'app'
      }, {
        'title': 'SMS',
        'description': 'SMS',
        'type': 'sms'
      }, {
        'title': 'Chat',
        'description': 'Chat',
        'type': 'chat'
      }, {
        'title': 'SocialMedia',
        'description': 'SocialMedia',
        'type': 'social'
      }, {
        'title': 'Phone',
        'description': 'Phone',
        'type': 'phone'
      }],
      tools_pos_x: [],
      tools_pos_y: []
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['journeyMapFetchStatus'])),
  watch: {
    y: function y(old_val, new_val) {
      this.computeToolsPOS();
    }
  },
  created: function created() {},
  mounted: function mounted() {
    this.computeToolsPOS();
  },
  methods: {
    truncateDescription: function truncateDescription(description, length) {
      return this._.truncate(description, {
        'length': length,
        'separator': ' '
      });
    },
    computeToolsPOS: function computeToolsPOS() {
      this.tools_pos_x = [];
      this.tools_pos_y = [];
      for (var i = 0; i < this.touchpoints.length; i++) {
        var x = 15 + Math.floor(i % 4) * 35;
        var y = this.y + 30 + Math.floor(i / 4) * 35;
        this.tools_pos_x.push(x);
        this.tools_pos_y.push(y);
      }
    },
    touchpointColIncrement: function touchpointColIncrement(tpIndex) {
      return tpIndex % 2;
    },
    touchpointRowIncrement: function touchpointRowIncrement(tpIndex) {
      return Math.floor(tpIndex / 2);
    },
    computeStyle: function computeStyle() {
      if (this.isCompactMenubar) {
        return 'fill:grey; font-weight: 200 ;font-variant-caps: small-caps';
      }
      return 'fill:white; font-weight: 200 ;font-variant-caps: small-caps';
    },
    computeTransform: function computeTransform() {
      if (!this.isCompactMenubar) return '';
      var y = this.y + 85;
      var x = this.x + 15;
      return 'rotate(270, ' + x + ', ' + y + ')';
    },
    displayLabelPrompt: function displayLabelPrompt() {
      var _this = this;
      this.$prompt('Please update title', 'Update lane title', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        inputValue: this.label
      }).then(function (_ref) {
        var value = _ref.value;
        _this.label = value;
      }).catch(function (error) {
        console.log(error);
      });
    },
    onTouchpointToolDragged: function onTouchpointToolDragged(_ref2) {
      var el = _ref2.el,
        deltaX = _ref2.deltaX,
        deltaY = _ref2.deltaY,
        offsetX = _ref2.offsetX,
        offsetY = _ref2.offsetY,
        clientX = _ref2.clientX,
        clientY = _ref2.clientY,
        first = _ref2.first,
        last = _ref2.last;
      var index = parseInt(el.id.split('_')[2]);
      var type = el.id.split('_')[1];
      if (last !== undefined && last) {
        this.$emit('drag-tool', index, type, this.tools_pos_x[index], this.tools_pos_y[index], first, last);
        this.computeToolsPOS();
      }
      if (first !== undefined && first) {
        this.$emit('drag-tool', index, type, this.tools_pos_x[index], this.tools_pos_y[index], first, last);
      }
      if (deltaX === undefined || deltaY === undefined) {
        return;
      }
      this.tools_pos_x[index] += deltaX;
      this.tools_pos_y[index] += deltaY;
      this.$forceUpdate();
      this.$emit('drag-tool', index, type, this.tools_pos_x[index], this.tools_pos_y[index], first, last);
    },
    onTouchpointMouseOver: function onTouchpointMouseOver(stage_index, touchpoint_index) {
      // @Sastha... Popover to place in second row better.
      this.$emit('mouseover', stage_index, touchpoint_index, this.phaseX[stage_index] + 80 + this.touchpointColIncrement(touchpoint_index) * 120, this.y + 15 + this.touchpointRowIncrement(touchpoint_index) * 34);
    },
    onTouchpointMouseLeave: function onTouchpointMouseLeave(stage_index, touchpoint_index) {
      this.$emit('mouseleave');
    },
    showTouchpointDetails: function showTouchpointDetails(stage_index, touchpoint_index) {
      this.$emit('click', stage_index, touchpoint_index);
    }
  }
};