"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _default = exports.default = {
  name: 'Manual',
  components: {},
  props: {
    x: {
      type: Number,
      default: 0
    },
    y: {
      type: Number,
      default: 0
    },
    index: {
      type: Number,
      default: 0
    },
    subIndex: {
      type: Number,
      default: 0
    },
    label: {
      type: String,
      default: ''
    },
    source: {
      type: String,
      default: 'prodiggi'
    },
    showLabel: {
      type: Boolean,
      default: false
    },
    minimized: {
      type: Boolean,
      default: false
    },
    highlight: {
      type: String,
      default: null
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    isTool: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      isHover: false
    };
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    computeY: function computeY() {
      if (this.minimized) {
        return this.y + 25;
      } else {
        return this.y + 35;
      }
    },
    computeStyle: function computeStyle() {
      if (this.isTool) {
        if (this.isHover) {
          return 'font-size: 40px;color:white;filter: url(#shadow);border-radius:5px;';
        }
        return 'font-size: 40px;color:blue';
      } else {
        if (this.minimized) {
          if (this.isHover) {
            return 'font-size: 30px;color:white;filter: url(#shadow);border-radius:10px;';
          }
          return 'font-size: 30px;color:blue';
        } else {
          if (this.isHover) {
            return 'font-size: 40px;color:white;filter: url(#shadow);border-radius:10px;';
          }
          return 'font-size: 40px;color:blue';
        }
      }
    },
    computeTextStyle: function computeTextStyle() {
      if (this.isHover) {
        return 'fill:#4672c4;';
      } else {
        return 'fill:#4672c4';
      }
    },
    onSubmit: function onSubmit() {},
    emitClick: function emitClick() {
      this.$emit('click', this.index, this.subIndex);
    },
    generateUniqueID: function generateUniqueID() {
      return 'manual_' + this.index + '_' + this.subIndex;
    },
    onDrag: function onDrag() {
      this.$emit('on-drag');
    },
    onMouseOver: function onMouseOver() {
      this.isHover = true;
      if (!this.isTool) {
        this.$emit('mouseover');
      }
    },
    onMouseLeave: function onMouseLeave() {
      this.isHover = false;
      this.$emit('mouseleave');
    }
  }
};