"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "app-container"
  }, [_c("transition", {
    attrs: {
      name: "fade-transform",
      mode: "out-in"
    }
  }, [_vm.active_step === 0 ? [_c("view-segment-competitors-component", {
    on: {
      "go-next": function goNext($event) {
        _vm.active_step = 1;
      }
    }
  })] : _vm._e(), _vm._v(" "), _vm.active_step === 1 ? [_c("compare-features-component", {
    on: {
      "go-next": function goNext($event) {
        _vm.active_step = 2;
      },
      "go-prev": function goPrev($event) {
        _vm.active_step = 0;
      }
    }
  })] : _vm._e(), _vm._v(" "), _vm.active_step === 2 ? [_c("competitive-landscape-component", {
    on: {
      "go-next": function goNext($event) {
        _vm.active_step = 3;
      },
      "go-prev": function goPrev($event) {
        _vm.active_step = 1;
      }
    }
  })] : _vm._e(), _vm._v(" "), _vm.active_step === 3 ? [_c("capture-research-component", {
    on: {
      "go-prev": function goPrev($event) {
        _vm.active_step = 2;
      }
    }
  })] : _vm._e()], 2)], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;