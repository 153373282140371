"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _default = exports.default = {
  name: 'StoryList',
  props: {
    cx: {
      type: Number,
      required: true,
      default: 0
    },
    cy: {
      type: Number,
      required: true,
      default: 0
    }
  },
  data: function data() {
    return {};
  },
  methods: {
    selectStory: function selectStory(story) {
      this.$notify({
        title: story + ' selected',
        message: story + ' UI to be displayed at this event',
        type: 'info',
        duration: 2000
      });
    }
  }
};