"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/taggedTemplateLiteral.js"));
var _templateObject, _templateObject2;
var _default2 = exports.default = {
  name: 'Risks',
  components: {},
  props: {
    association: {
      type: String,
      default: 'Offering'
    },
    entityId: {
      type: String,
      required: true,
      default: '123'
    },
    entityTitle: {
      type: String,
      required: true,
      default: '123'
    },
    entityName: {
      type: String,
      required: true,
      default: 'Goal'
    },
    visible: {
      type: Boolean,
      default: false
    },
    risks: {
      type: Array,
      required: true,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    var validateTitle = function validateTitle(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter a valid risk'));
      } else {
        callback();
      }
    };
    var validateDescription = function validateDescription(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter a valid description'));
      } else {
        callback();
      }
    };
    var validateSeverity = function validateSeverity(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter a valid severity'));
      } else {
        callback();
      }
    };
    var validateOccuranceProbability = function validateOccuranceProbability(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter a valid occuranceprobability'));
      } else {
        callback();
      }
    };
    var validateImpactLevel = function validateImpactLevel(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter a valid impactlevel'));
      } else {
        callback();
      }
    };
    var validateMitigationPlan = function validateMitigationPlan(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter a valid mitigation plan'));
      } else {
        callback();
      }
    };
    return {
      riskRules: {
        title: [{
          required: true,
          trigger: 'blur',
          validator: validateTitle
        }],
        description: [{
          required: true,
          trigger: 'blur',
          validator: validateDescription
        }],
        severity: [{
          required: true,
          trigger: 'blur',
          validator: validateSeverity
        }],
        occuranceprobability: [{
          required: true,
          trigger: 'blur',
          validator: validateOccuranceProbability
        }],
        impactlevel: [{
          required: true,
          trigger: 'blur',
          validator: validateImpactLevel
        }],
        mitigationplan: [{
          required: true,
          trigger: 'blur',
          validator: validateMitigationPlan
        }]
      },
      risk_form: {
        title: '',
        description: '',
        severity: 'Low',
        occuranceprobability: 'Low',
        impactlevel: 'Low',
        mitigationplan: ''
      },
      search_term: '',
      loading: false,
      addRiskDialog: false
    };
  },
  computed: {
    filteredRisks: function filteredRisks() {
      var self = this;
      return this.risks.filter(function (risk) {
        return risk.risk.title.toLowerCase().indexOf(self.search_term.toLowerCase()) > -1;
      });
    }
  },
  watch: {},
  mounted: function mounted() {
    this.resetRiskForm();
  },
  methods: {
    showAddRiskDialog: function showAddRiskDialog() {
      this.resetRiskForm();
      this.addRiskDialog = true;
    },
    closeDialog: function closeDialog() {
      this.$emit('close');
      this.visible = false;
    },
    truncateDescription: function truncateDescription(description, length) {
      return this._.truncate(description, {
        'length': length,
        'separator': ' '
      });
    },
    deleteRisk: function deleteRisk(index) {
      var _this = this;
      this.$confirm('This will permanently delete the risk. Continue?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(function () {
        _this.loading = true;
        _this.$apollo.mutate({
          // Query
          mutation: window._gql(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["mutation deleteRisk($risk: DeleteRiskInput!)\n          {\n            deleteRisk(input: $risk) {\n              id,\n            }\n          }"]))),
          // Parameters
          variables: {
            'risk': {
              'id': _this.risks[index].risk.id
            }
          }
        }).then(function (data) {
          _this.$notify({
            title: 'Success',
            message: 'Deleted Successfully',
            type: 'success',
            duration: 2000
          });
          _this.risks.splice(index, 1);
          _this.$emit('delete', index);
          _this.loading = false;
        }).catch(function (error) {
          // Error
          _this.$notify({
            title: 'Error',
            message: error,
            type: 'error',
            duration: 2000
          });
          _this.loading = false;
        });
      }).catch(function () {
        _this.$message({
          type: 'info',
          message: 'Delete canceled'
        });
      });
    },
    createRisk: function createRisk() {
      var _this2 = this;
      // Create Risk
      this.$refs.risk_form.validate(function (valid) {
        if (valid) {
          _this2.loading = true;
          _this2.$apollo.mutate({
            // Query
            mutation: window._gql(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["mutation createRisk($risk: CreateRiskInput!)\n            {\n              createRisk(input: $risk) {\n                id\n                title\n                description\n                severity\n                occuranceprobability\n                impactlevel\n                mitigationplan\n                additionalattributes\n              }\n            }"]))),
            // Parameters
            variables: {
              'risk': {
                'title': _this2.risk_form.title,
                'description': _this2.risk_form.description,
                'severity': _this2.risk_form.severity,
                'occuranceprobability': _this2.risk_form.occuranceprobability,
                'impactlevel': _this2.risk_form.impactlevel,
                'mitigationplan': _this2.risk_form.mitigationplan,
                'association': _this2.association,
                'linkEntityName': _this2.entityName,
                'linkEntityId': _this2.entityId
              }
            }
          }).then(function (data) {
            _this2.$notify({
              title: 'Success',
              message: 'Created Successfully',
              type: 'success',
              duration: 2000
            });
            var risk = data.data.createRisk;
            _this2.risks.push({
              risk: risk
            });

            // Event just to notify parent new item has been created.
            _this2.$emit('create');
            _this2.addRiskDialog = false;
            _this2.loading = false;
          }).catch(function (error) {
            // Error
            _this2.$notify({
              title: 'Error',
              message: error,
              type: 'error',
              duration: 2000
            });
            _this2.addRiskDialog = false;
            _this2.loading = false;
          });
        } else {
          _this2.addRiskDialog = false;
          _this2.loading = false;
          return false;
        }
      });
    },
    resetRiskForm: function resetRiskForm() {
      this.risk_form = {
        title: '',
        description: '',
        severity: 'Low',
        occuranceprobability: 'Low',
        impactlevel: 'Low',
        mitigationplan: ''
      };
    }
  }
};