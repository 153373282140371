"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/taggedTemplateLiteral.js"));
var _objectSpread2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _vuex = require("vuex");
var _resolution = require("@/utils/resolution");
var _PieChartExternalStatus = _interopRequireDefault(require("./components/home_dashboard_cards/PieChartExternalStatus"));
var _PieChartProdiggiStatus = _interopRequireDefault(require("./components/home_dashboard_cards/PieChartProdiggiStatus"));
var _HalfDoughnutProdiggiStatus = _interopRequireDefault(require("./components/home_dashboard_cards/HalfDoughnutProdiggiStatus"));
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8;
var _default = exports.default = {
  name: 'Cards',
  components: {
    PieChartExternalStatusComponent: _PieChartExternalStatus.default,
    PieChartProdiggiStatusComponent: _PieChartProdiggiStatus.default,
    HalfDoughnutProdiggiStatusComponent: _HalfDoughnutProdiggiStatus.default
  },
  data: function data() {
    return {
      loading: false,
      isExternalStatus: false,
      filter: {
        q: ''
      },
      customer_types: [],
      offerings: [],
      products: [],
      services: [],
      customer_types_reports: [],
      offerings_reports: [],
      products_reports: [],
      services_reports: [],
      selectedEntity: 'customertype',
      customer_type_mouseover: false,
      offering_mouseover: false,
      product_mouseover: false,
      service_mouseover: false,
      layout: {
        customer_type_compact: false,
        offering_compact: false,
        product_compact: false,
        service_compact: false
      }
    };
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['firstName', 'middleName', 'lastName'])), {}, {
    computeDashboardHeight: function computeDashboardHeight() {
      var height = 860 * (0, _resolution.screenRatio)();
      return 'height:' + height + 'px;';
    },
    filteredCustomerTypes: function filteredCustomerTypes() {
      var self = this;
      return this.customer_types.filter(function (customer_type) {
        return customer_type.title.toLowerCase().indexOf(self.filter.q.toLowerCase()) > -1;
      });
    },
    filteredOfferings: function filteredOfferings() {
      var self = this;
      return this.offerings.filter(function (offering) {
        return offering.title.toLowerCase().indexOf(self.filter.q.toLowerCase()) > -1;
      });
    },
    filteredProducts: function filteredProducts() {
      var self = this;
      return this.products.filter(function (product) {
        return product.title.toLowerCase().indexOf(self.filter.q.toLowerCase()) > -1;
      });
    },
    filteredServices: function filteredServices() {
      var self = this;
      return this.services.filter(function (service) {
        return service.title.toLowerCase().indexOf(self.filter.q.toLowerCase()) > -1;
      });
    }
  }),
  mounted: function mounted() {
    this.retrieveAllReports();
    this.retrieveAll();
  },
  created: function created() {},
  methods: {
    updateOfferingStatus: function updateOfferingStatus(offering, status) {
      for (var i = this.offerings.length - 1; i >= 0; i--) {
        if (this.offerings[i].id === offering.id) {
          this.offerings[i].active_status = status;
          this.$nextTick();
          this.$forceUpdate();
          break;
        }
      }
    },
    updateProductStatus: function updateProductStatus(product, status) {
      for (var i = this.products.length - 1; i >= 0; i--) {
        if (this.products[i].id === product.id) {
          this.products[i].active_status = status;
          this.$nextTick();
          this.$forceUpdate();
          break;
        }
      }
    },
    updateServiceStatus: function updateServiceStatus(service, status) {
      for (var i = this.services.length - 1; i >= 0; i--) {
        if (this.services[i].id === service.id) {
          this.services[i].active_status = status;
          this.$nextTick();
          this.$forceUpdate();
          break;
        }
      }
    },
    retrieveAllReports: function retrieveAllReports() {
      this.retrieveCustomerTypeReports();
      this.retrieveOfferingReports();
      this.retrieveProductReports();
      this.retrieveServiceReports();
    },
    retrieveCustomerTypeReports: function retrieveCustomerTypeReports() {
      var _this = this;
      this.loading = true;
      this.customer_types_reports = [];
      this.$apollo.query({
        // Query
        query: window._gql(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["query {ReportData(type: CustomerType)}"])))
      }).then(function (data) {
        _this.loading = false;
        _this.customer_types_reports = data.data.ReportData;
      }).catch(function (error) {
        _this.loading = false;
        // Error
        _this.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
      });
    },
    retrieveOfferingReports: function retrieveOfferingReports() {
      var _this2 = this;
      this.loading = true;
      this.offerings_reports = [];
      this.$apollo.query({
        // Query
        query: window._gql(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["query {ReportData(type: Offering)}"])))
      }).then(function (data) {
        _this2.loading = false;
        _this2.offerings_reports = data.data.ReportData;
      }).catch(function (error) {
        _this2.loading = false;
        // Error
        _this2.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
      });
    },
    retrieveProductReports: function retrieveProductReports() {
      var _this3 = this;
      this.loading = true;
      this.products_reports = [];
      this.$apollo.query({
        // Query
        query: window._gql(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)(["query {ReportData(type: Product)}"])))
      }).then(function (data) {
        _this3.loading = false;
        _this3.products_reports = data.data.ReportData;
      }).catch(function (error) {
        _this3.loading = false;
        // Error
        _this3.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
      });
    },
    retrieveServiceReports: function retrieveServiceReports() {
      var _this4 = this;
      this.loading = true;
      this.services_reports = [];
      this.$apollo.query({
        // Query
        query: window._gql(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2.default)(["query {ReportData(type: Service)}"])))
      }).then(function (data) {
        _this4.loading = false;
        _this4.services_reports = data.data.ReportData;
      }).catch(function (error) {
        _this4.loading = false;
        // Error
        _this4.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
      });
    },
    setActiveServiceStatus: function setActiveServiceStatus(index, status) {
      this.filteredServices[index].active_status = status;
    },
    selectCustomer: function selectCustomer(customer) {
      var _this5 = this;
      this.$store.dispatch('customerTypes/updateCustomerType', customer).then(function (res) {
        _this5.$router.push('/customer-types/overview');
      }).catch(function (err) {
        console.log(err);
      });
    },
    selectOffering: function selectOffering(offering) {
      var _this6 = this;
      this.$store.dispatch('offerings/updateOfferingId', offering.id).then(function (res) {
        _this6.$router.push('/offerings/design');
      }).catch(function (err) {
        console.log(err);
      });
    },
    switchEntities: function switchEntities(entity) {
      // this.filter.q = ''
      this.selectedEntity = entity;
      switch (entity) {
        case 'offering':
          // this.retrieveOfferings()
          break;
      }
    },
    compactLayout: function compactLayout() {
      switch (this.selectedEntity) {
        case 'customertype':
          this.layout.customer_type_compact = true;
          break;
        case 'offering':
          this.layout.offering_compact = true;
          break;
        case 'product':
          this.layout.product_compact = true;
          break;
        case 'service':
          this.layout.service_compact = true;
          break;
      }
    },
    expandedLayout: function expandedLayout() {
      switch (this.selectedEntity) {
        case 'customertype':
          this.layout.customer_type_compact = false;
          break;
        case 'offering':
          this.layout.offering_compact = false;
          break;
        case 'product':
          this.layout.product_compact = false;
          break;
        case 'service':
          this.layout.service_compact = false;
          break;
      }
    },
    truncateString: function truncateString(string, length) {
      return this._.truncate(string, {
        'length': length,
        'separator': ' '
      });
    },
    computeTotalCardStyle: function computeTotalCardStyle(type, color) {
      if (type === 'customertype' && this.selectedEntity === 'customertype') {
        return 'height:100px;width:100px;min-width:100px;background-color:rebeccapurple; border-radius:5px;text-align:center;border-style:ridge;';
      } else if (type === 'customertype' && this.selectedEntity !== 'customertype') {
        return 'height:100px;width:100px;min-width:100px;background-color:' + color + '; border-radius:5px;text-align:center;';
      }
      if (type === 'offering' && this.selectedEntity === 'offering') {
        return 'height:100px;width:100px;min-width:100px;background-color:' + color + '; border-radius:5px;text-align:center;border-style:ridge;';
      } else if (type === 'offering' && this.selectedEntity !== 'offering') {
        return 'height:100px;width:100px;min-width:100px;background-color:' + color + '; border-radius:5px;text-align:center;';
      }
      if (type === 'product' && this.selectedEntity === 'product') {
        return 'height:100px;width:100px;min-width:100px;background-color:' + color + '; border-radius:5px;text-align:center;border-style:ridge;';
      } else if (type === 'product' && this.selectedEntity !== 'product') {
        return 'height:100px;width:100px;min-width:100px;background-color:' + color + '; border-radius:5px;text-align:center;';
      }
      if (type === 'service' && this.selectedEntity === 'service') {
        return 'height:100px;width:100px;min-width:100px;background-color:' + color + '; border-radius:5px;text-align:center;border-style:ridge;';
      } else if (type === 'service' && this.selectedEntity !== 'service') {
        return 'height:100px;width:100px;min-width:100px;background-color:' + color + '; border-radius:5px;text-align:center;';
      }
    },
    computeStyle: function computeStyle(type, color) {
      if (type === 'customertype' && this.customer_type_mouseover) {
        return 'height:100px;background-color:' + color + '; border-radius:5px;margin:0px 7.5px 0px 15px;filter: drop-shadow(0px 8px 4px #00000077);';
      } else if (type === 'customertype' && !this.customer_type_mouseover) {
        return 'height:100px;background-color:' + color + '; border-radius:5px;margin:0px 7.5px 0px 15px;filter: drop-shadow(0px 4px 4px #00000077);';
      }
      if (type === 'offering' && this.offering_mouseover) {
        return 'height:100px;background-color:' + color + '; border-radius:5px;margin:0px 7.5px 0px 7.5px;filter: drop-shadow(0px 8px 4px #00000077);';
      } else if (type === 'offering' && !this.offering_mouseover) {
        return 'height:100px;background-color:' + color + '; border-radius:5px;margin:0px 7.5px 0px 7.5px;filter: drop-shadow(0px 4px 4px #00000077);';
      }
      if (type === 'product' && this.product_mouseover) {
        return 'height:100px;background-color:' + color + '; border-radius:5px;margin:0px 7.5px 0px 7.5px;filter: drop-shadow(0px 8px 4px #00000077);';
      } else if (type === 'product' && !this.product_mouseover) {
        return 'height:100px;background-color:' + color + '; border-radius:5px;margin:0px 7.5px 0px 7.5px;filter: drop-shadow(0px 4px 4px #00000077);';
      }
      if (type === 'service' && this.service_mouseover) {
        return 'height:100px;background-color:' + color + '; border-radius:5px;margin:0px 15px 0px 7.5px;filter: drop-shadow(0px 8px 4px #00000077);';
      } else if (type === 'service' && !this.service_mouseover) {
        return 'height:100px;background-color:' + color + '; border-radius:5px;margin:0px 15px 0px 7.5px;filter: drop-shadow(0px 4px 4px #00000077);';
      }
    },
    computeCardTitleStyle: function computeCardTitleStyle(type) {
      if (type === 'customertype' && this.layout.customer_type_compact) {
        return 'font-weight:500;font-size:18px;background-color: rebeccapurple;color: white;padding: 2px;padding-left: 10px;border-top-right-radius: 5px;border-top-left-radius: 5px;cursor:pointer;';
      } else if (type === 'customertype' && !this.layout.customer_type_compact) {
        return 'font-weight:500;font-size:18px;background-color: rebeccapurple;color: white;padding: 2px;padding-left: 10px;border-top-right-radius: 5px;border-top-left-radius: 5px;;cursor:pointer;';
      }
      if (type === 'offering' && this.layout.offering_compact) {
        return 'font-weight:500;font-size:18px;background-color: #2C7E7F;color: white;padding: 2px;padding-left: 10px;border-top-right-radius: 5px;border-top-left-radius: 5px;;cursor:pointer;';
      } else if (type === 'offering' && !this.layout.offering_compact) {
        return 'font-weight:500;font-size:18px;background-color: #2C7E7F;color: white;padding: 2px;padding-left: 10px;border-top-right-radius: 5px;border-top-left-radius: 5px;;cursor:pointer;';
      }
      if (type === 'product' && this.layout.product_compact) {
        return 'font-weight:500;font-size:18px;background-color: #FF6600;color: white;padding: 2px;padding-left: 10px;border-top-right-radius: 5px;border-top-left-radius: 5px;';
      } else if (type === 'product' && !this.layout.product_compact) {
        return 'font-weight:500;font-size:18px;background-color: #FF6600;color: white;padding: 2px;padding-left: 10px;border-top-right-radius: 5px;border-top-left-radius: 5px;';
      }
      if (type === 'service' && this.layout.service_compact) {
        return 'font-weight:500;font-size:18px;background-color: #FF6600;color: white;padding: 2px;padding-left: 10px;border-top-right-radius: 5px;border-top-left-radius: 5px;';
      } else if (type === 'service' && !this.layout.service_compact) {
        return 'font-weight:500;font-size:18px;background-color: #FF6600;color: white;padding: 2px;padding-left: 10px;border-top-right-radius: 5px;border-top-left-radius: 5px;';
      }
    },
    retrieveAll: function retrieveAll() {
      this.retrieveCustomerTypes();
      this.retrieveOfferings();
      this.retrieveProducts();
      this.retrieveServices();
    },
    retrieveCustomerTypes: function retrieveCustomerTypes() {
      var _this7 = this;
      this.loading = true;
      this.customer_types = [];
      this.$apollo.query({
        // Query
        query: window._gql(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2.default)([" query CustomerTypes($page: Page)\n        {\n        CustomerTypes(page: $page)\n        {\n          pages\n          total\n          select{\n            id\n            title\n            description\n            linkedOfferings(optional: true) {\n              offering(optional: true) {\n                id\n                title\n                offeringtype\n                offeringcategory\n                linkedProducts(optional: true) {\n                  product(optional: true) {\n                    id\n                    title\n                    linkedFeatures(optional: true) {\n                      productFeature(optional: true) {\n                        id\n                        title\n                        description\n                        status\n                      }\n                    }\n                  }\n                }\n                linkedServices(optional: true) {\n                  service(optional: true) {\n                    id\n                    title\n                    linkedServiceApplications(optional: true) {\n                      serviceApplication(optional: true) {\n                        id\n                        title\n                        description\n                        priority\n                        status\n                      }\n                    }\n                  }\n                }\n              }\n            }\n          }\n        }\n      }"]))),
        fetchPolicy: 'no-cache',
        variables: {
          'page': {
            'limit': 100,
            'start': 1
          }
        }
      }).then(function (data) {
        _this7.customer_types = data.data.CustomerTypes.select;
        var total_products = 0;
        var total_services = 0;
        for (var i = 0; i < _this7.customer_types.length; i++) {
          _this7.customer_types[i].active_status = 'prodiggi';
          _this7.customer_types[i].external_status = [];
          _this7.customer_types[i].external_offering_status = [];
          _this7.customer_types[i].external_product_status = [];
          _this7.customer_types[i].external_service_status = [];
          _this7.customer_types[i].prodiggi_status = [];
          _this7.customer_types[i].prodiggi_offering_status = [];
          _this7.customer_types[i].prodiggi_product_status = [];
          _this7.customer_types[i].prodiggi_service_status = [];
          _this7.customer_types[i].external_progress = 0;
          _this7.customer_types[i].external_progress_offering = 0;
          _this7.customer_types[i].external_progress_product = 0;
          _this7.customer_types[i].external_progress_service = 0;
          _this7.customer_types[i].prodiggi_progress = 0;
          _this7.customer_types[i].prodiggi_progress_offering = 0;
          _this7.customer_types[i].prodiggi_progress_product = 0;
          _this7.customer_types[i].prodiggi_progress_service = 0;
          for (var m = 0; m < _this7.customer_types_reports.length; m++) {
            if (_this7.customer_types_reports[m].id === _this7.customer_types[i].id) {
              _this7.customer_types[i].prodiggi_status = _this7.customer_types_reports[m].ticketStats.internal;
              _this7.customer_types[i].prodiggi_progress = _this7.computeProgress(_this7.customer_types_reports[m].ticketStats.internal);
              _this7.customer_types[i].external_status = _this7.customer_types_reports[m].ticketStats.external;
              _this7.customer_types[i].external_progress = _this7.computeProgress(_this7.customer_types_reports[m].ticketStats.external);
            }
          }
          for (var j = 0; j < _this7.customer_types[i].linkedOfferings.length; j++) {
            _this7.customer_types[i].linkedOfferings[j].offering.prodiggi_progress = _this7.computeProgress(_this7.retrieveOfferingProgress(_this7.customer_types[i].linkedOfferings[j].offering.id, 'prodiggi'));
            _this7.customer_types[i].prodiggi_progress_offering += _this7.computeProgress(_this7.retrieveOfferingProgress(_this7.customer_types[i].linkedOfferings[j].offering.id, 'prodiggi'));
            _this7.customer_types[i].linkedOfferings[j].offering.external_progress = _this7.computeProgress(_this7.retrieveOfferingProgress(_this7.customer_types[i].linkedOfferings[j].offering.id, 'external'));
            _this7.customer_types[i].external_progress_offering += _this7.computeProgress(_this7.retrieveOfferingProgress(_this7.customer_types[i].linkedOfferings[j].offering.id, 'external'));
            total_products += _this7.customer_types[i].linkedOfferings[j].offering.linkedProducts.length;
            total_services += _this7.customer_types[i].linkedOfferings[j].offering.linkedServices.length;
            for (var k = 0; k < _this7.customer_types[i].linkedOfferings[j].offering.linkedProducts.length; k++) {
              _this7.customer_types[i].prodiggi_progress_product += _this7.computeProgress(_this7.retrieveProductProgress(_this7.customer_types[i].linkedOfferings[j].offering.linkedProducts[k].product.id, 'prodiggi'));
              _this7.customer_types[i].external_progress_product += _this7.computeProgress(_this7.retrieveProductProgress(_this7.customer_types[i].linkedOfferings[j].offering.linkedProducts[k].product.id, 'external'));
            }
            for (var l = 0; l < _this7.customer_types[i].linkedOfferings[j].offering.linkedServices.length; l++) {
              _this7.customer_types[i].prodiggi_progress_service += _this7.computeProgress(_this7.retrieveProductProgress(_this7.customer_types[i].linkedOfferings[j].offering.linkedServices[l].service.id, 'prodiggi'));
              _this7.customer_types[i].external_progress_service += _this7.computeProgress(_this7.retrieveProductProgress(_this7.customer_types[i].linkedOfferings[j].offering.linkedServices[l].service.id, 'external'));
            }
          }
          if (_this7.customer_types[i].linkedOfferings.length > 0 && _this7.customer_types[i].prodiggi_progress_offering > 0) {
            _this7.customer_types[i].prodiggi_progress_offering = Math.floor(_this7.customer_types[i].prodiggi_progress_offering / _this7.customer_types[i].linkedOfferings.length * 100 / 100);
          }
          if (_this7.customer_types[i].linkedOfferings.length > 0 && _this7.customer_types[i].external_progress_offering > 0) {
            _this7.customer_types[i].external_progress_offering = Math.floor(_this7.customer_types[i].external_progress_offering / _this7.customer_types[i].linkedOfferings.length * 100 / 100);
          }
          if (total_products > 0 && _this7.customer_types[i].prodiggi_progress_product > 0) {
            _this7.customer_types[i].prodiggi_progress_product = Math.floor(_this7.customer_types[i].prodiggi_progress_product / total_products * 100 / 100);
          }
          if (total_products > 0 && _this7.customer_types[i].external_progress_product > 0) {
            _this7.customer_types[i].external_progress_product = Math.floor(_this7.customer_types[i].external_progress_product / total_products * 100 / 100);
          }
          if (total_services > 0 && _this7.customer_types[i].prodiggi_progress_service > 0) {
            _this7.customer_types[i].prodiggi_progress_service = Math.floor(_this7.customer_types[i].prodiggi_progress_service / total_services * 100 / 100);
          }
          if (total_services > 0 && _this7.customer_types[i].external_progress_service > 0) {
            _this7.customer_types[i].external_progress_service = Math.floor(_this7.customer_types[i].external_progress_service / total_services * 100 / 100);
          }
        }
        _this7.loading = false;
      }).catch(function (error) {
        console.log(error);
        _this7.loading = false;
      });
    },
    retrieveOfferings: function retrieveOfferings() {
      var _this8 = this;
      this.loading = true;
      this.offerings = [];
      this.$apollo.query({
        // Query
        query: window._gql(_templateObject6 || (_templateObject6 = (0, _taggedTemplateLiteral2.default)([" query Offerings($page: Page)\n        {\n        Offerings(page: $page)\n        {\n          pages\n          total\n          select{\n            id\n            title\n            description\n            linkedProducts(optional: true) {\n              product(optional: true) {\n                id\n                title\n                linkedFeatures(optional: true) {\n                  productFeature(optional: true) {\n                    id\n                    title\n                    description\n                    status\n                  }\n                }\n              }\n            }\n            linkedServices(optional: true) {\n              service(optional: true) {\n                id\n                title\n                linkedServiceApplications(optional: true) {\n                  serviceApplication(optional: true) {\n                    id\n                    title\n                    description\n                    priority\n                    status\n                  }\n                }\n              }\n            }\n          }\n        }\n      }"]))),
        fetchPolicy: 'no-cache',
        variables: {
          'page': {
            'limit': 100,
            'start': 1
          }
        }
      }).then(function (data) {
        _this8.offerings = data.data.Offerings.select;
        for (var i = 0; i < _this8.offerings.length; i++) {
          _this8.offerings[i].active_status = 'prodiggi';
          _this8.offerings[i].external_status = [];
          _this8.offerings[i].external_progress = 0;
          _this8.offerings[i].external_product_status = [];
          _this8.offerings[i].external_service_status = [];
          _this8.offerings[i].prodiggi_status = [];
          _this8.offerings[i].prodiggi_progress = 0;
          _this8.offerings[i].prodiggi_product_status = [];
          _this8.offerings[i].prodiggi_service_status = [];
          for (var l = 0; l < _this8.offerings_reports.length; l++) {
            if (_this8.offerings_reports[l].id === _this8.offerings[i].id) {
              _this8.offerings[i].prodiggi_status = _this8.offerings_reports[l].ticketStats.internal;
              _this8.offerings[i].prodiggi_progress = _this8.computeProgress(_this8.offerings_reports[l].ticketStats.internal);
              _this8.offerings[i].external_status = _this8.offerings_reports[l].ticketStats.external;
              _this8.offerings[i].external_progress = _this8.computeProgress(_this8.offerings_reports[l].ticketStats.external);
            }
          }
          for (var j = 0; j < _this8.offerings[i].linkedProducts.length; j++) {
            _this8.offerings[i].linkedProducts[j].product.prodiggi_progress = _this8.computeProgress(_this8.retrieveProductProgress(_this8.offerings[i].linkedProducts[j].product.id, 'prodiggi'));
            _this8.offerings[i].linkedProducts[j].product.external_progress = _this8.computeProgress(_this8.retrieveProductProgress(_this8.offerings[i].linkedProducts[j].product.id, 'external'));
          }
          for (var k = 0; k < _this8.offerings[i].linkedServices.length; k++) {
            _this8.offerings[i].linkedServices[k].service.prodiggi_progress = _this8.computeProgress(_this8.retrieveServiceProgress(_this8.offerings[i].linkedServices[k].service.id, 'prodiggi'));
            _this8.offerings[i].linkedServices[k].service.external_progress = _this8.computeProgress(_this8.retrieveServiceProgress(_this8.offerings[i].linkedServices[k].service.id, 'external'));
          }
        }
        _this8.loading = false;
      }).catch(function (error) {
        console.log(error);
        _this8.loading = false;
      });
    },
    retrieveProducts: function retrieveProducts() {
      var _this9 = this;
      this.loading = true;
      this.products = [];
      this.$apollo.query({
        // Query
        query: window._gql(_templateObject7 || (_templateObject7 = (0, _taggedTemplateLiteral2.default)(["query Products($page: Page)\n        {\n        Products(page: $page)\n        {\n          pages\n          total\n          select{\n            id\n            title\n            description\n            vision\n            roadmap\n            revenuenotes\n            productcosts\n            licensedetails\n            keypersonnel\n            additionalattributes\n            linkedFeatures(optional: true) {\n              productFeature(optional: true) {\n                id\n                title\n                description\n                status\n              }\n            }\n          }\n        }\n      }"]))),
        fetchPolicy: 'network-only',
        variables: {
          'page': {
            'limit': 200,
            'start': 1
          }
        }
      }).then(function (data) {
        _this9.products = data.data.Products.select;
        for (var i = 0; i < _this9.products.length; i++) {
          _this9.products[i].active_status = 'prodiggi';
          _this9.products[i].prodiggi_status = [];
          _this9.products[i].external_status = [];
          for (var j = 0; j < _this9.products_reports.length; j++) {
            if (_this9.products_reports[j].id === _this9.products[i].id) {
              _this9.products[i].prodiggi_status = _this9.products_reports[j].ticketStats.internal;
              _this9.products[i].external_status = _this9.products_reports[j].ticketStats.external;
            }
          }
        }
        _this9.loading = false;
      }).catch(function (error) {
        // Error
        console.log(error);
        _this9.loading = false;
      });
    },
    retrieveServices: function retrieveServices() {
      var _this10 = this;
      this.loading = true;
      this.services = [];
      this.$apollo.query({
        // Query
        query: window._gql(_templateObject8 || (_templateObject8 = (0, _taggedTemplateLiteral2.default)(["query Services($page: Page)\n        {\n        Services(page: $page)\n        {\n          pages\n          total\n          select{\n            id\n            title\n            description\n            servicetype\n            servicecosts\n            revenuedetails\n            relations\n            design\n            additionalattributes\n            keypersonnel\n            createdat\n            createdby\n            linkedServiceApplications(optional: true) {\n              serviceApplication(optional: true) {\n                id\n                title\n                description\n                priority\n                status\n                complexity\n                relations\n                enabler\n                startdate\n                enddate\n                additionalattributes\n              }\n            }\n          }\n        }\n      }"]))),
        fetchPolicy: 'network-only',
        variables: {
          'page': {
            'limit': 200,
            'start': 1
          }
        }
      }).then(function (data) {
        _this10.services = data.data.Services.select;
        for (var i = 0; i < _this10.services.length; i++) {
          _this10.services[i].active_status = 'prodiggi';
          _this10.services[i].prodiggi_status = [];
          _this10.services[i].external_status = [];
          for (var j = 0; j < _this10.services_reports.length; j++) {
            if (_this10.services_reports[j].id === _this10.services[i].id) {
              _this10.services[i].prodiggi_status = _this10.services_reports[j].ticketStats.internal;
              _this10.services[i].external_status = _this10.services_reports[j].ticketStats.external;
            }
          }
        }
        _this10.loading = false;
      }).catch(function (error) {
        // Error
        console.log(error);
        _this10.loading = false;
      });
    },
    retrieveProductTicketStatus: function retrieveProductTicketStatus(product_id) {
      for (var i = 0; i < this.product_reports.length; i++) {
        if (this.product_reports[i].id === product_id) {
          return [this.product_reports[i].ticketStats.internal, this.product_reports[i].ticketStats.external];
        }
      }
      return [null, null];
    },
    computeProgress: function computeProgress(status_list) {
      var length = status_list.length;
      var total_ticket_count = 0;
      var progress_count = 0;
      var max_progress = 0;
      for (var i = 0; i < status_list.length; i++) {
        total_ticket_count += status_list[i].noOfTickets;
        progress_count = (i + 1) * status_list[i].noOfTickets;
      }
      max_progress = total_ticket_count * length;
      if (total_ticket_count === 0 || max_progress === 0 || progress_count === 0) {
        return 0;
      }
      return Math.floor(progress_count / max_progress * 100);
    },
    retrieveOfferingProgress: function retrieveOfferingProgress(offering_id, type) {
      for (var i = 0; i < this.offerings.length; i++) {
        if (this.offerings[i].id === offering_id && type === 'prodiggi') {
          return this.offerings[i].prodiggi_status;
        }
        if (this.offerings[i].id === offering_id && type === 'external') {
          return this.offerings[i].external_status;
        }
      }
      return [];
    },
    retrieveProductProgress: function retrieveProductProgress(product_id, type) {
      for (var i = 0; i < this.products.length; i++) {
        if (this.products[i].id === product_id && type === 'prodiggi') {
          return this.products[i].prodiggi_status;
        }
        if (this.products[i].id === product_id && type === 'external') {
          return this.products[i].external_status;
        }
      }
      return [];
    },
    retrieveServiceProgress: function retrieveServiceProgress(service_id, type) {
      for (var i = 0; i < this.services.length; i++) {
        if (this.services[i].id === service_id && type === 'prodiggi') {
          return this.services[i].prodiggi_status;
        }
        if (this.services[i].id === service_id && type === 'external') {
          return this.services[i].external_status;
        }
      }
      return [];
    }
  }
};