"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _default2 = exports.default = {
  props: {
    preview: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    contenteditable: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    itemIndex: {
      type: Number,
      default: 0
    },
    item: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  methods: {
    pasteData: function pasteData(e) {
      this.item.content = e.clipboardData.getData('text/plain');
    },
    save: function save(e) {
      this.item.content = e.target.innerText;
      this.$emit('save-item', {
        item: this.item,
        index: this.itemIndex
      });
    }
  }
};