"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _interopRequireWildcard2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _default2 = exports.default = {
  name: 'OfferingEntityList',
  components: {
    ProductComponent: function ProductComponent() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./Product.vue"));
      });
    },
    ServiceComponent: function ServiceComponent() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./Service.vue"));
      });
    },
    SVGCTAComponent: function SVGCTAComponent() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/components/SVGCTA'));
      });
    }
  },
  props: {
    offering: {
      type: Object,
      required: true,
      default: function _default() {
        return {
          id: '',
          title: '',
          description: '',
          offeringcategory: '',
          offeringcategorydesc: '',
          additionalattributes: {},
          linkedProducts: [],
          linkedServices: []
        };
      }
    },
    x: {
      type: Number,
      required: true,
      default: 0
    },
    y: {
      type: Number,
      required: true,
      default: 0
    },
    isUnmappedVisible: {
      type: Boolean,
      default: true
    },
    isProductVisible: {
      type: Boolean,
      default: true
    },
    isServiceVisible: {
      type: Boolean,
      default: true
    },
    isTouchPointVisible: {
      type: Boolean,
      default: true
    },
    isTouchPointCollapsed: {
      type: Boolean,
      default: true
    },
    recomputeFeaturePos: {
      type: Boolean,
      default: true
    },
    recomputeServiceApplicationPos: {
      type: Boolean,
      default: true
    },
    phaseX: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    journeyMap: {
      type: Object,
      required: true,
      default: function _default() {
        return {
          id: '',
          title: '',
          description: '',
          additionalattributes: {},
          linkedJourneyExperiences: [],
          linkedJourneyPhases: [],
          linkedJourneySteps: []
        };
      }
    },
    width: {
      type: Number,
      required: true,
      default: 0
    },
    settings: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      recompute: false
    };
  },
  computed: {
    mergedEntities: function mergedEntities() {
      var sorted = [];
      var products = [];
      var services = [];
      if (this._.isEmpty(this.offering)) {
        this.$emit('computed-entity-list', sorted);
        return sorted;
      }
      products = this.offering.linkedProducts;
      services = this.offering.linkedServices;
      if (this.isProductVisible && this.isServiceVisible) {
        if (this.offering.additionalattributes.entitiesSortOrder) {
          for (var i = 0; i < this.offering.additionalattributes.entitiesSortOrder.length; i++) {
            if (this.offering.additionalattributes.entitiesSortOrder[i].type === 'product') {
              for (var j = 0; j < products.length; j++) {
                if (products[j].product.id === this.offering.additionalattributes.entitiesSortOrder[i].id) {
                  sorted.push(products[j]);
                }
              }
            } else if (this.offering.additionalattributes.entitiesSortOrder[i].type === 'service') {
              for (var k = 0; k < services.length; k++) {
                if (services[k].service.id === this.offering.additionalattributes.entitiesSortOrder[i].id) {
                  sorted.push(services[k]);
                }
              }
            }
          }
        } else {
          sorted = sorted.concat(products.concat(services));
        }
      }
      if (!this.isProductVisible) {
        sorted = sorted.concat(services);
      }
      if (!this.isServiceVisible) {
        sorted = sorted.concat(products);
      }
      this.$emit('computed-entity-list', sorted);
      return sorted;
    },
    computeLaneTotalHeight: function computeLaneTotalHeight() {
      var height = 0;
      for (var i = 0; i < this.mergedEntities.length; i++) {
        if (this.offering.additionalattributes.entitiesSortOrder[i].minimized) {
          height += 25;
        } else {
          if (this.mergedEntities[i].__typename === 'LinkProductOffering') {
            height += 100;
            height += this.productHasUnmappedFeatures(this.mergedEntities[i].product) ? 50 : 0;
            height += this.productHasEnablerFeatures(this.mergedEntities[i].product) ? 50 : 0;
          } else if (this.mergedEntities[i].__typename === 'LinkServiceOffering') {
            height += 100;
            height += this.serviceHasUnmappedFeatures(this.mergedEntities[i].service) ? 50 : 0;
            height += this.serviceHasEnablerFeatures(this.mergedEntities[i].service) ? 50 : 0;
          } else {
            height += 100;
          }
        }
      }
      var total_height = height + this.mergedEntities.length * 5 + 5;
      return total_height;
    }
  },
  watch: {
    mergedEntities: function mergedEntities(val) {
      this.$emit('computed-entity-list', this.mergedEntities);
    },
    isTouchPointVisible: function isTouchPointVisible(val) {
      this.recompute = !this.recompute;
    },
    isTouchPointCollapsed: function isTouchPointCollapsed(val) {
      this.recompute = !this.recompute;
    }
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    moveUpEntitySortOrder: function moveUpEntitySortOrder(entity_id) {
      this.$emit('move-entity-up', entity_id);
    },
    moveDownEntitySortOrder: function moveDownEntitySortOrder(entity_id) {
      this.$emit('move-entity-down', entity_id);
    },
    addEntity: function addEntity(title) {
      if (title.indexOf('product') !== -1) {
        this.$emit('create-product');
      } else {
        this.$emit('create-service');
      }
    },
    resizeEntitySwimlane: function resizeEntitySwimlane(entity_index) {
      switch (this.mergedEntities[entity_index].__typename) {
        case 'LinkProductOffering':
          this.mergedEntities[entity_index].product.additionalattributes.minimized = !this.mergedEntities[entity_index].product.additionalattributes.minimized;
          break;
        case 'LinkServiceOffering':
          this.mergedEntities[entity_index].service.additionalattributes.minimized = !this.mergedEntities[entity_index].service.additionalattributes.minimized;
          break;
      }
      var additionalattributes = this.offering.additionalattributes;
      additionalattributes.entitiesSortOrder[entity_index].minimized = !additionalattributes.entitiesSortOrder[entity_index].minimized;
      var minimized_entity_lane_height = 25;
      var maximized_entity_lane_height = this.computeEntityLaneHeight(entity_index);
      var height_diff = maximized_entity_lane_height - minimized_entity_lane_height;
      for (var i = entity_index + 1; i < additionalattributes.entitiesSortOrder.length; i++) {
        if (additionalattributes.entitiesSortOrder[i].type === 'product') {
          for (var j = 0; j < additionalattributes.entitiesSortOrder[i].feature.length; j++) {
            if (additionalattributes.entitiesSortOrder[entity_index].minimized) {
              additionalattributes.entitiesSortOrder[i].feature[j].posY -= height_diff;
            } else {
              additionalattributes.entitiesSortOrder[i].feature[j].posY += height_diff;
            }
          }
        }
        if (additionalattributes.entitiesSortOrder[i].type === 'service') {
          for (var k = 0; k < additionalattributes.entitiesSortOrder[i].service_applications.length; k++) {
            if (additionalattributes.entitiesSortOrder[entity_index].minimized) {
              additionalattributes.entitiesSortOrder[i].service_applications[k].posY -= height_diff;
            } else {
              additionalattributes.entitiesSortOrder[i].service_applications[k].posY += height_diff;
            }
          }
        }
      }
      this.$emit('recompute-feature-service-application-pos');
      this.$emit('update-offering-additionalattributes', additionalattributes, true);
      this.recompute = !this.recompute;
    },
    computeCompundedEntityLaneHeight: function computeCompundedEntityLaneHeight(index) {
      var height = 0;
      for (var i = 0; i < index; i++) {
        if (this.offering.additionalattributes.entitiesSortOrder[i].minimized) {
          height += 25;
        } else {
          if (this.mergedEntities[i].__typename === 'LinkProductOffering') {
            height += this.productHasUnmappedFeatures(this.mergedEntities[i].product) ? 150 : 100;
            height += this.productHasEnablerFeatures(this.mergedEntities[i].product) ? 50 : 0;
          } else if (this.mergedEntities[i].__typename === 'LinkServiceOffering') {
            height += this.serviceHasUnmappedFeatures(this.mergedEntities[i].service) ? 150 : 100;
            height += this.serviceHasEnablerFeatures(this.mergedEntities[i].service) ? 50 : 0;
          } else {
            height += 100;
          }
        }
      }
      return height + index * 5;
    },
    computeEntityLaneHeight: function computeEntityLaneHeight(index) {
      var height = 100;
      if (this.mergedEntities[index].__typename === 'LinkProductOffering') {
        height += this.productHasUnmappedFeatures(this.mergedEntities[index].product) ? 50 : 0;
        height += this.productHasEnablerFeatures(this.mergedEntities[index].product) ? 50 : 0;
      } else if (this.mergedEntities[index].__typename === 'LinkServiceOffering') {
        height += this.serviceHasUnmappedFeatures(this.mergedEntities[index].service) ? 50 : 0;
        height += this.serviceHasEnablerFeatures(this.mergedEntities[index].service) ? 50 : 0;
      }
      return height;
    },
    computeProductLaneHeight: function computeProductLaneHeight(product) {
      var height = 0;
      if (this.isLaneMinimized(product.product.id)) {
        height = 25;
      } else {
        if (this.productHasUnmappedFeatures(product.product)) {
          if (this.productHasEnablerFeatures(product.product)) {
            height = 200;
          } else {
            height = 150;
          }
        } else {
          if (this.productHasEnablerFeatures(product.product)) {
            height = 150;
          } else {
            height = 100;
          }
        }
      }
      return height;
    },
    computeServiceLaneHeight: function computeServiceLaneHeight(service) {
      var height = 0;
      if (this.isLaneMinimized(service.service.id)) {
        height = 25;
      } else {
        if (this.serviceHasUnmappedFeatures(service.service)) {
          if (this.serviceHasEnablerFeatures(service.service)) {
            height = 200;
          } else {
            height = 150;
          }
        } else {
          if (this.serviceHasEnablerFeatures(service.service)) {
            height = 150;
          } else {
            height = 100;
          }
        }
      }
      return height;
    },
    productHasUnmappedFeatures: function productHasUnmappedFeatures(product) {
      var has_unmapped = false;
      if (!this.isUnmappedVisible) {
        return has_unmapped;
      }
      for (var i = 0; i < product.linkedFeatures.length; i++) {
        if (product.linkedFeatures[i].productFeature.relations.steps[0].id === null && !product.linkedFeatures[i].productFeature.enabler) {
          has_unmapped = true;
        }
      }
      return has_unmapped;
    },
    productHasEnablerFeatures: function productHasEnablerFeatures(product) {
      var has_enabler = false;
      for (var i = 0; i < product.linkedFeatures.length; i++) {
        if (product.linkedFeatures[i].productFeature.enabler) {
          has_enabler = true;
        }
      }
      return has_enabler;
    },
    serviceHasUnmappedFeatures: function serviceHasUnmappedFeatures(service) {
      var has_unmapped = false;
      if (!this.isUnmappedVisible) {
        return has_unmapped;
      }
      for (var i = 0; i < service.linkedServiceApplications.length; i++) {
        if (service.linkedServiceApplications[i].serviceApplication.relations.id === null) {
          has_unmapped = true;
        }
      }
      return has_unmapped;
    },
    serviceHasEnablerFeatures: function serviceHasEnablerFeatures(service) {
      var has_enabler = false;
      for (var i = 0; i < service.linkedServiceApplications.length; i++) {
        if (service.linkedServiceApplications[i].serviceApplication.enabler) {
          has_enabler = true;
        }
      }
      return has_enabler;
    },
    isLaneMinimized: function isLaneMinimized(entity_id) {
      for (var i = 0; i < this.offering.additionalattributes.entitiesSortOrder.length; i++) {
        if (this.offering.additionalattributes.entitiesSortOrder[i].id === entity_id) {
          return this.offering.additionalattributes.entitiesSortOrder[i].minimized;
        }
      }
      return false;
    },
    displayProductSettings: function displayProductSettings(product, index, x, y) {
      this.$emit('entity-settings', 'product', product, index, x, y);
    },
    displayProductMenu: function displayProductMenu(product, index, x, y, has_unmapped) {
      this.$emit('entity-menu', 'product', product, index, x, y, has_unmapped);
    },
    displayServiceSettings: function displayServiceSettings(service, index, x, y) {
      this.$emit('entity-settings', 'service', service, index, x, y);
    },
    displayServiceMenu: function displayServiceMenu(service, index, x, y, has_unmapped) {
      this.$emit('entity-menu', 'service', service, index, x, y, has_unmapped);
    },
    reset: function reset() {
      this.$emit('reset');
    },
    createProductIdea: function createProductIdea(product_id, product_title) {
      this.$emit('create-idea', 'Product', product_id, product_title);
    },
    createServiceIdea: function createServiceIdea(service_id, service_title) {
      this.$emit('create-idea', 'Service', service_id, service_title);
    },
    onServiceApplicationToolDragged: function onServiceApplicationToolDragged(service_id, x, y, type) {
      this.$emit('service-application-tool-dragged', service_id, x, y, type);
    },
    onServiceApplicationToolDraggedStart: function onServiceApplicationToolDraggedStart(service_id, x, y, type) {
      this.$emit('service-application-tool-dragged-start', service_id, x, y, type);
    },
    onServiceApplicationToolDraggedStop: function onServiceApplicationToolDraggedStop(service_id, x, y, type) {
      this.$emit('service-application-tool-dragged-stop', service_id, x, y, type);
    },
    onServiceApplicationDragged: function onServiceApplicationDragged(service_id, service_index, service_application_id, service_application_index, x, y) {
      this.$emit('service-application-dragged', service_id, service_index, service_application_id, service_application_index, x, y);
    },
    onServiceApplicationDraggedStart: function onServiceApplicationDraggedStart(service_id, service_index, service_application_id, service_application_index, x, y) {
      this.$emit('service-application-dragged-start', service_id, service_index, service_application_id, service_application_index, x, y);
    },
    onServiceApplicationDraggedStop: function onServiceApplicationDraggedStop(service_id, service_index, service_application_id, service_application_index, x, y) {
      this.$emit('service-application-dragged-stop', service_id, service_index, service_application_id, service_application_index, x, y);
    },
    updateServiceApplicationServiceApplicationRelation: function updateServiceApplicationServiceApplicationRelation(start_id, start_type, end_id, end_type) {
      this.$emit('update-service-application-service-application-relation', start_id, start_type, end_id, end_type);
    },
    onServiceApplicationMouseOver: function onServiceApplicationMouseOver(service_application, service_application_index, x, y) {
      this.$emit('service-application-mouse-over', service_application, service_application_index, x, y);
    },
    onServiceApplicationMouseLeave: function onServiceApplicationMouseLeave() {
      this.$emit('service-application-mouse-leave');
    },
    onFeatureMouseOver: function onFeatureMouseOver(feature, feature_index, x, y) {
      this.$emit('feature-mouse-over', feature, feature_index, x, y);
    },
    onFeatureMouseLeave: function onFeatureMouseLeave() {
      this.$emit('feature-mouse-leave');
    },
    onFeatureToolDragged: function onFeatureToolDragged(product_id, x, y) {
      this.$emit('feature-tool-dragged', product_id, x, y);
    },
    onFeatureToolDraggedStart: function onFeatureToolDraggedStart(product_id, x, y) {
      this.$emit('feature-tool-dragged-start', product_id, x, y);
    },
    onFeatureToolDraggedStop: function onFeatureToolDraggedStop(product_id, x, y) {
      this.$emit('feature-tool-dragged-stop', product_id, x, y);
    },
    onFeatureDragged: function onFeatureDragged(product_id, product_index, feature_id, feature_index, x, y) {
      this.$emit('feature-dragged', product_id, product_index, feature_id, feature_index, x, y);
    },
    onFeatureDraggedStart: function onFeatureDraggedStart(product_id, product_index, feature_id, feature_index, x, y) {
      this.$emit('feature-dragged-start', product_id, product_index, feature_id, feature_index, x, y);
    },
    onFeatureDraggedStop: function onFeatureDraggedStop(product_id, product_index, feature_id, feature_index, x, y) {
      this.$emit('feature-dragged-stop', product_id, product_index, feature_id, feature_index, x, y);
    },
    updateFeatureFeatureRelation: function updateFeatureFeatureRelation(start_id, start_type, end_id, end_type) {
      this.$emit('update-feature-feature-relation', start_id, start_type, end_id, end_type);
    },
    onProductRiskToolDragged: function onProductRiskToolDragged(product, x, y, feature_pos_x, feature_pos_y) {
      this.$emit('product-risk-tool-dragged', product, x, y, feature_pos_x, feature_pos_y);
    },
    onProductRiskToolDraggedStart: function onProductRiskToolDraggedStart(product, x, y, feature_pos_x, feature_pos_y) {
      this.$emit('product-risk-tool-dragged-start', product, x, y, feature_pos_x, feature_pos_y);
    },
    onProductRiskToolDraggedStop: function onProductRiskToolDraggedStop(product, x, y, feature_pos_x, feature_pos_y) {
      this.$emit('product-risk-tool-dragged-stop', product, x, y, feature_pos_x, feature_pos_y);
    },
    onServiceRiskToolDragged: function onServiceRiskToolDragged(service, x, y, service_application_pos_x, service_application_pos_y) {
      this.$emit('service-risk-tool-dragged', service, x, y, service_application_pos_x, service_application_pos_y);
    },
    onServiceRiskToolDraggedStart: function onServiceRiskToolDraggedStart(service, x, y, service_application_pos_x, service_application_pos_y) {
      this.$emit('service-risk-tool-dragged-start', service, x, y, service_application_pos_x, service_application_pos_y);
    },
    onServiceRiskToolDraggedStop: function onServiceRiskToolDraggedStop(service, x, y, service_application_pos_x, service_application_pos_y) {
      this.$emit('service-risk-tool-dragged-stop', service, x, y, service_application_pos_x, service_application_pos_y);
    },
    selectFeatureOrProcess: function selectFeatureOrProcess(ticket, parent, ticket_index) {
      var type = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'Product';
      this.$emit('feature-process-click', ticket, parent, ticket_index, type);
    }
  }
};