"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("g", {
    attrs: {
      refs: "customer-journey-workspace"
    }
  }, [_c("g", {
    attrs: {
      refs: "customer-journey-workspace-container"
    }
  }, [_c("g", {
    attrs: {
      refs: "customer-journey-workspace-container-border"
    }
  }, [_c("rect", {
    staticClass: "shadow",
    attrs: {
      x: _vm.settings.container.journeymap.border_start_pos,
      y: "13",
      width: "2",
      height: _vm.journeyMapHeight - 7,
      fill: _vm.settings.container.journeymap.border,
      "stroke-width": "1",
      stroke: "#f1f4ff"
    }
  })]), _vm._v(" "), _c("rect", {
    directives: [{
      name: "dragged",
      rawName: "v-dragged.prevent",
      value: _vm.onJourneyContainerDragged,
      expression: "onJourneyContainerDragged",
      modifiers: {
        prevent: true
      }
    }],
    staticStyle: {
      cursor: "grab"
    },
    attrs: {
      x: "145",
      y: "14",
      width: _vm.journeyMapWidth - 10,
      height: "81",
      fill: "rgba(230,230,230,0.1)",
      "stroke-width": "1",
      stroke: "#f1f4ff"
    }
  }), _vm._v(" "), _c("rect", {
    attrs: {
      x: "5",
      y: "30",
      rx: "0",
      width: "140",
      height: 66,
      fill: "rgba(200,200,200,0.15)"
    }
  }), _vm._v(" "), _c("rect", {
    attrs: {
      x: "5",
      y: "14",
      width: "140",
      rx: "3",
      height: "20",
      fill: "rebeccapurple",
      "stroke-width": "1",
      stroke: "rebeccapurple"
    },
    on: {
      mouseover: function mouseover($event) {
        _vm.titleMouseHover = true;
      },
      mouseleave: function mouseleave($event) {
        _vm.titleMouseHover = false;
      }
    }
  }), _vm._v(" "), _c("rect", {
    directives: [{
      name: "dragged",
      rawName: "v-dragged.prevent",
      value: _vm.onJourneyContainerDragged,
      expression: "onJourneyContainerDragged",
      modifiers: {
        prevent: true
      }
    }],
    staticStyle: {
      cursor: "grab"
    },
    attrs: {
      x: "145",
      y: "100",
      rx: "0",
      width: _vm.journeyMapWidth - 10,
      height: _vm.journeyMapHeight - 95,
      fill: "rgba(254,254,255,0.9)",
      "stroke-width": "1.0",
      stroke: "#f1f4ff"
    }
  }), _vm._v(" "), _c("rect", {
    attrs: {
      x: "5",
      y: "100",
      rx: "3",
      width: "140",
      height: _vm.journeyMapHeight - 95,
      fill: "rgba(200,200,200,0.15)"
    }
  }), _vm._v(" "), _c("g", {
    on: {
      mouseover: function mouseover($event) {
        _vm.titleMouseHover = true;
      },
      mouseleave: function mouseleave($event) {
        _vm.titleMouseHover = false;
      }
    }
  }, [_c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.titleMouseHover ? _c("text", {
    staticStyle: {
      fill: "white",
      "font-weight": "600",
      "font-variant-caps": "small-caps"
    },
    attrs: {
      x: "75",
      y: 28,
      "text-anchor": "middle",
      "font-size": "1.05em"
    }
  }, [_vm._v(_vm._s(_vm.journeyMap.title))]) : _vm._e()]), _vm._v(" "), _c("transition", {
    attrs: {
      name: "fade"
    }
  }, [!_vm.titleMouseHover ? _c("text", {
    staticStyle: {
      fill: "white",
      "font-weight": "600",
      "font-variant-caps": "small-caps"
    },
    attrs: {
      x: "75",
      y: 28,
      "text-anchor": "middle",
      "font-size": "1.05em"
    }
  }, [_vm._v("Customer Journey")]) : _vm._e()])], 1), _vm._v(" "), _c("rect", {
    attrs: {
      x: 5,
      y: 35,
      rx: "3",
      width: "140",
      height: 22,
      fill: "#305497",
      "stroke-width": "1",
      stroke: "#305497"
    }
  }), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "white",
      "font-weight": "200",
      "font-variant-caps": "small-caps"
    },
    attrs: {
      x: "22",
      y: 50,
      "text-anchor": "start",
      "font-size": ".95em"
    }
  }, [_vm._v("Stages")]), _vm._v(" "), _c("rect", {
    attrs: {
      x: 5,
      y: 100,
      rx: "3",
      width: "140",
      height: 22,
      fill: "#305497",
      "stroke-width": "1",
      stroke: "#305497"
    }
  }), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "white",
      "font-weight": "200",
      "font-variant-caps": "small-caps"
    },
    attrs: {
      x: "22",
      y: 115,
      "text-anchor": "start",
      "font-size": ".95em"
    }
  }, [_vm._v("Steps")])]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "customer-journey-workspace-phase-tasks"
    }
  }, [_c("g", {
    attrs: {
      refs: "phase-lane-content"
    }
  }, _vm._l(_vm.sortedStages, function (phase, index) {
    return _c("phase-component", {
      key: index,
      attrs: {
        id: "phase_" + index,
        index: index,
        x: _vm.computePhaseStartX(phase.journeyPhase, index),
        y: 30,
        length: _vm.computePhaseLength(phase.journeyPhase, index),
        label: phase.journeyPhase.title,
        "show-label": true,
        hover: _vm.phaseHover,
        phase: phase,
        "read-only": true
      },
      on: {
        mouseover: function mouseover($event) {
          return _vm.onPhaseMouseOver(phase, index);
        },
        mouseleave: function mouseleave($event) {
          return _vm.onPhaseMouseLeave();
        },
        click: function click($event) {
          return _vm.displayPhaseDetails(phase, index);
        }
      }
    });
  }), 1), _vm._v(" "), _c("g", {
    attrs: {
      refs: "task-lane-content"
    }
  }, _vm._l(_vm.computedTaskList, function (task, index) {
    return _c("task-component", {
      key: index,
      attrs: {
        id: "task_" + index,
        x: _vm.computeTaskPosX(index),
        y: _vm.computeTaskPosY(index),
        index: index,
        "phase-index": _vm.computePhaseIndexforTask(task, index),
        "show-label": true,
        hover: _vm.taskHover,
        label: task.journeyStep.title,
        "read-only": true,
        task: task
      },
      on: {
        click: function click($event) {
          return _vm.displayTaskDetails(task, index);
        },
        mouseover: function mouseover($event) {
          return _vm.onTaskMouseOver(task, index);
        },
        mouseleave: function mouseleave($event) {
          return _vm.onTaskMouseLeave();
        }
      }
    });
  }), 1)])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;