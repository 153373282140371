"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
/** When your routing table is too long, you can split it into small modules**/

var securityRouter = {
  path: '/security',
  component: _layout.default,
  redirect: '/security/invites',
  alwaysShow: true,
  // will always show the root menu
  name: 'Security',
  meta: {
    title: 'Security',
    icon: 'lock',
    roles: ['superadmin'] // you can set roles in root nav
  },
  children: [{
    path: 'invites',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/party_invites'));
      });
    },
    name: 'Party Invites',
    meta: {
      title: 'Invitations',
      roles: ['superadmin']
    }
  }, {
    path: 'permissions',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/security/permissions'));
      });
    },
    name: 'Permissions',
    meta: {
      title: 'Permissions',
      roles: ['superadmin']
    }
  }, {
    path: 'roles',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/security/roles'));
      });
    },
    name: 'Roles',
    meta: {
      title: 'Roles',
      roles: ['superadmin']
    }
  }, {
    path: 'groups',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/security/groups'));
      });
    },
    name: 'Groups',
    meta: {
      title: 'Groups',
      roles: ['superadmin']
    }
  }]
};
var _default = exports.default = securityRouter;