"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Icon = _interopRequireDefault(require("./Icon.vue"));
var _Process = _interopRequireDefault(require("./Process.vue"));
var _DomainDocumentDetails = _interopRequireDefault(require("./process_components/DomainDocumentDetails.vue"));
var _default2 = exports.default = {
  name: 'CircleComponent',
  components: {
    IconComponent: _Icon.default,
    ProcessComponent: _Process.default,
    DomainDocumentDetailsComponent: _DomainDocumentDetails.default
  },
  props: {
    color: {
      type: String,
      required: true,
      default: ''
    },
    cx: {
      type: String,
      required: true,
      default: ''
    },
    cy: {
      type: String,
      required: true,
      default: ''
    },
    textLine1: {
      type: String,
      required: true,
      default: ''
    },
    textLine2: {
      type: String,
      required: true,
      default: ''
    },
    expand: {
      type: Boolean,
      default: false
    },
    processes: {
      type: Array,
      required: true,
      default: function _default() {
        return [];
      }
    },
    documents: {
      type: Array,
      required: true,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      isVisible: false,
      isHover: false,
      isOpenDomainIcon: false,
      radius: 50,
      activeDomainIcon: '',
      processAngles: [],
      domain_icons: [{
        name: 'analysis'
      }, {
        name: 'document'
      }, {
        name: 'social'
      }, {
        name: 'procedure'
      }, {
        name: 'checklist'
      }]
    };
  },
  created: function created() {
    // Listen to event
    this.$eventHub.$on('toggle-process', this.toggleProcess);
    this.$eventHub.$on('domain-document-edit', this.closeDomainDocumentDialog);
  },
  mounted: function mounted() {
    var angleDelta = 180 / (this.processes.length + 1);
    for (var i = 0; i < this.processes.length; i++) {
      // Start from 90 to limit all process to right side of domain
      var angle = 90 - angleDelta - angleDelta * i;
      this.processAngles.push(angle);
    }
  },
  beforeDestroy: function beforeDestroy() {
    // Stop listening
    this.$eventHub.$off('toggle-process');
  },
  methods: {
    closeDomainDocumentDialog: function closeDomainDocumentDialog() {
      this.isOpenDomainIcon = false;
    },
    domainIconSelected: function domainIconSelected(type) {
      this.activeDomainIcon = type;
      this.isOpenDomainIcon = !this.isOpenDomainIcon;
    },
    toggleProcess: function toggleProcess() {
      // Close all process
      this.isVisible = false;
    },
    displayProcess: function displayProcess() {
      this.$emit('click', this.cx, this.cy);
      if (this.processes.length === 0) {
        this.$eventHub.$emit('toggle-process');
        this.$message({
          message: 'No processes found',
          type: 'warning'
        });
      } else {
        // Calculate Angles
        var angleDelta = 180 / (this.processes.length + 1);
        for (var i = 0; i < this.processes.length; i++) {
          // Start from 90 to limit all process to right side of domain
          var angle = 90 - angleDelta - angleDelta * i;
          this.processAngles.push(angle);
        }
        if (this.isVisible) {
          // Emit event to close any open process
          this.$eventHub.$emit('toggle-process');
          // Reopen Process only for current journey
          this.isVisible = false;
        } else {
          // Emit event to close any open process
          this.$eventHub.$emit('toggle-process');
          // Reopen Process only for current journey
          this.isVisible = true;
        }
      }
    },
    computeX: function computeX(key) {
      return parseInt(this.cx) + 100;
    },
    computeY: function computeY(key) {
      // If even number of processess
      // Spaced equidistanct from center
      // if odd number of process, Middle process is set to center
      if (this.processes.length % 2 === 0) {
        var lower_mid_key = this.processes.length / 2;
        var higher_mid_key = this.processes.length / 2 + 1;
        if (key <= lower_mid_key) {
          return parseInt(this.cy) - 72.5 - (lower_mid_key - key) * 75;
        } else if (key >= higher_mid_key) {
          return parseInt(this.cy) + 12.5 + (key - higher_mid_key) * 75;
        }
      } else {
        // Is Odd
        var mid_key = this.processes.length / 2 + 0.5;
        if (key <= mid_key) {
          return parseInt(this.cy) - 25 - (mid_key - key) * 75;
        } else {
          return parseInt(this.cy) - 25 + (key - mid_key) * 75;
        }
      }
    },
    computeIconPoints: function computeIconPoints(index) {
      var angle = 360 / this.domain_icons.length;
      var angle_of_current_icon = angle * index;
      var point_x = parseInt(this.cx) + 70 * Math.cos(angle_of_current_icon * Math.PI / 180) - 14;
      var point_y = parseInt(this.cy) - 70 * Math.sin(angle_of_current_icon * Math.PI / 180) - 14;
      return [point_x, point_y];
    },
    computeIconPoints_old: function computeIconPoints_old(angle) {
      var point_x = parseInt(this.cx) + 35 * Math.cos(angle * Math.PI / 180) - 7;
      var point_y = parseInt(this.cy) - 35 * Math.sin(angle * Math.PI / 180) - 7;
      return [point_x, point_y];
    },
    computePathPoints: function computePathPoints(index) {
      var angle = parseInt(this.processAngles[index]);
      var point_a_x = parseInt(this.cx) + this.radius * Math.cos(angle * Math.PI / 180);
      var point_a_y = parseInt(this.cy) - this.radius * Math.sin(angle * Math.PI / 180);
      var point_b_x = parseInt(this.cx) + 75;
      var point_b_y = this.computeY(index + 1) + 25;
      var point_c_x = parseInt(this.cx) + 100;
      var point_c_y = this.computeY(index + 1) + 25;
      return 'M' + point_a_x + ',' + point_a_y + ' C' + point_b_x + ',' + point_b_y + ' ' + point_b_x + ',' + point_b_y + ' ' + point_c_x + ',' + point_c_y;
    },
    openWord: function openWord(domain_icon) {
      alert('Download / Preview / Redirect to word document');
    },
    openConfluence: function openConfluence(domain_icon) {
      alert('Redirect to Atlassian Confluence Page');
    },
    onMouseOver: function onMouseOver() {
      this.isHover = true;
      this.$emit('mouse-over');
    },
    onMouseLeave: function onMouseLeave() {
      this.isHover = false;
      this.$emit('mouse-leave');
    }
  }
};