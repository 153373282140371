"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var getters = {
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  customerTypeSidebar: function customerTypeSidebar(state) {
    return state.customerTypes.customer_type_sidebar;
  },
  customerType: function customerType(state) {
    return state.customerTypes.customer_type;
  },
  journeyMap: function journeyMap(state) {
    return state.customerTypes.journey_map;
  },
  journeyMapFetchStatus: function journeyMapFetchStatus(state) {
    return state.customerTypes.journey_map_fetch_status;
  },
  offeringId: function offeringId(state) {
    return state.offerings.offering_id;
  },
  offering: function offering(state) {
    return state.offerings.offering;
  },
  offeringSidebar: function offeringSidebar(state) {
    return state.offerings.offering_sidebar;
  },
  offeringJourneyMap: function offeringJourneyMap(state) {
    return state.offerings.journey_map;
  },
  size: function size(state) {
    return state.app.size;
  },
  device: function device(state) {
    return state.app.device;
  },
  visitedViews: function visitedViews(state) {
    return state.tagsView.visitedViews;
  },
  cachedViews: function cachedViews(state) {
    return state.tagsView.cachedViews;
  },
  product: function product(state) {
    return state.app.product;
  },
  productRole: function productRole(state) {
    return state.app.product_role;
  },
  token: function token(state) {
    return state.user.token;
  },
  avatar: function avatar(state) {
    return state.user.avatar;
  },
  firstName: function firstName(state) {
    return state.user.first_name;
  },
  middleName: function middleName(state) {
    return state.user.middle_name;
  },
  lastName: function lastName(state) {
    return state.user.last_name;
  },
  avatarColor: function avatarColor(state) {
    return state.user.avatar_color;
  },
  id: function id(state) {
    return state.user.id;
  },
  partyId: function partyId(state) {
    return state.user.party_id;
  },
  name: function name(state) {
    return state.user.name;
  },
  email: function email(state) {
    return state.user.email;
  },
  introduction: function introduction(state) {
    return state.user.introduction;
  },
  roles: function roles(state) {
    return state.user.roles;
  },
  modules: function modules(state) {
    return state.user.modules;
  },
  permission_routes: function permission_routes(state) {
    return state.permission.routes;
  },
  businessGoalSearchSort: function businessGoalSearchSort(state) {
    return state.business.goals;
  },
  showMVP: function showMVP(state) {
    return state.settings.showMVP;
  }
};
var _default = exports.default = getters;