"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  name: 'IntegratedData',
  data: function data() {
    return {};
  },
  mounted: function mounted() {},
  methods: {}
};