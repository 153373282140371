"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("g", {
    attrs: {
      refs: "relations-component"
    }
  }, [_c("marker", {
    attrs: {
      id: "dot",
      viewBox: "0 0 10 10",
      refX: "5",
      refY: "5",
      markerWidth: "12",
      markerHeight: "12",
      orient: "auto-start-reverse"
    }
  }, [_c("circle", {
    attrs: {
      cx: "5",
      cy: "5",
      r: "1",
      fill: "#447799",
      "fill-opacity": "1"
    }
  })]), _vm._v(" "), _c("marker", {
    attrs: {
      id: "triangle",
      viewBox: "0 0 5 5",
      refX: "2",
      refY: "2",
      fill: "#447799",
      markerUnits: "strokeWidth",
      markerWidth: "4",
      markerHeight: "4",
      orient: "auto"
    }
  }, [_c("path", {
    attrs: {
      d: "M 0 0 L 5 2.5 L 0 5 ",
      fill: "#888",
      "fill-opacity": "1"
    }
  })]), _vm._v(" "), _vm._l(_vm.filteredRelationsArray, function (relation, relation_index) {
    return _c("g", {
      key: "relation_" + relation_index,
      attrs: {
        refs: "relation"
      }
    }, [relation.waypoints.length === 0 && _vm.computeRelationDisplayCondition(relation) ? _c("relation-component", {
      attrs: {
        path: _vm.generatePath(relation.start_id, relation.start_type, relation.end_id, relation.end_type),
        entity: relation,
        type: _vm.type
      }
    }) : _vm.computeRelationDisplayCondition(relation) ? _c("g", _vm._l(_vm.filteredRelationWaypoints(relation.waypoints), function (waypoint, waypoint_index) {
      return _c("g", {
        key: "waypoint_" + waypoint_index,
        attrs: {
          refs: "waypoints"
        }
      }, [waypoint_index === 0 ? _c("relation-component", {
        attrs: {
          path: _vm.generatePath(relation.start_id, relation.start_type, waypoint.id, waypoint.type),
          entity: waypoint,
          type: _vm.type
        }
      }) : _c("relation-component", {
        attrs: {
          path: _vm.generatePath(_vm.waypoints[waypoint_index - 1].id, _vm.waypoints[waypoint_index - 1].type, waypoint.id, waypoint.type),
          entity: waypoint,
          type: _vm.type
        }
      }), _vm._v(" "), _c("relation-component", {
        attrs: {
          path: _vm.generatePath(waypoint.id, waypoint.type, relation.end_id, relation.end_type),
          entity: relation,
          type: _vm.type
        }
      })], 1);
    }), 0) : _vm._e()], 1);
  })], 2);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;