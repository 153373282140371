"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _default2 = exports.default = {
  name: 'SubInitiativeDetails',
  props: {
    goal: {
      type: Object,
      required: true
    },
    initiative: {
      type: Object,
      required: true
    },
    subInitiative: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      default: 0
    },
    goals: {
      type: Array,
      required: true,
      default: function _default() {
        return [];
      }
    },
    users: {
      type: Array,
      required: true,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      selectedGoal: {}
    };
  },
  watch: {
    goal: function goal(val) {
      if (val) {
        this.selectGoal(val);
      }
    }
  },
  mounted: function mounted() {},
  methods: {
    computeUserNameFromId: function computeUserNameFromId(user_id) {
      if (user_id === 'admin' || user_id === '' || user_id === null) {
        return user_id;
      }
      for (var i = 0; i < this.users.length; i++) {
        if (this.users[i].id === user_id) {
          return this.users[i].firstName + ' ' + this.users[i].lastName;
        }
      }
      return '';
    },
    selectGoal: function selectGoal(goal_id) {
      for (var i = 0; i < this.goals.length; i++) {
        if (this.goals[i].id === goal_id) {
          this.selectedGoal = this.goals[i];
        }
      }
    },
    updateSubInitiativeParent: function updateSubInitiativeParent(initiative_id) {},
    computeUserName: function computeUserName(user) {
      return user.firstName + ' ' + user.lastName;
    },
    deleteSubInitiative: function deleteSubInitiative() {
      this.$emit('delete', this.index);
    },
    updateSubInitiative: function updateSubInitiative() {
      this.$emit('update', this.subInitiative);
    },
    cancelSubInitiativeUpdate: function cancelSubInitiativeUpdate() {
      this.$emit('cancel');
    }
  }
};