"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-card", {
    staticClass: "mt-10"
  }, [_c("el-row", [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-row", {
    staticClass: "sub-title-rounded mt-5"
  }, [_c("el-col", {
    staticClass: "vertical-center",
    attrs: {
      span: 3
    }
  }, [_c("span", [_vm._v("Search Competitors")])]), _vm._v(" "), _c("el-col", {
    staticClass: "text-center",
    attrs: {
      span: 15
    }
  }, [_c("el-checkbox-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.companyCategory,
      callback: function callback($$v) {
        _vm.companyCategory = $$v;
      },
      expression: "companyCategory"
    }
  }, [_c("el-checkbox-button", {
    attrs: {
      label: "Direct"
    }
  }), _vm._v(" "), _c("el-checkbox-button", {
    attrs: {
      label: "Close"
    }
  }), _vm._v(" "), _c("el-checkbox-button", {
    attrs: {
      label: "Peripheral"
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "Search",
      "prefix-icon": "el-icon-search",
      size: "mini"
    },
    model: {
      value: _vm.competitorSearch,
      callback: function callback($$v) {
        _vm.competitorSearch = $$v;
      },
      expression: "competitorSearch"
    }
  })], 1)], 1), _vm._v(" "), _c("el-row", {
    staticClass: "container"
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-table", {
    attrs: {
      data: _vm.competitors.filter(function (data) {
        return !_vm.competitorSearch || data.name.toLowerCase().includes(_vm.competitorSearch.toLowerCase());
      }),
      stripe: ""
    },
    on: {
      "row-click": _vm.rowClick
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Company",
      prop: "name"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Category",
      prop: "category"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Segments",
      prop: "segment"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Threatening Features",
      prop: "features",
      width: "600"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return _vm._l(scope.row.features[0]["feature_list"], function (feature, index) {
          return _c("el-tag", {
            key: index,
            staticClass: "feature_tag",
            attrs: {
              type: _vm.computeRisk(feature.risk),
              size: "mini"
            }
          }, [_vm._v("\n                    " + _vm._s(feature.name) + "\n                  ")]);
        });
      }
    }])
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "text-right"
  }, [_c("el-button", {
    staticClass: "mt-10",
    attrs: {
      icon: "el-icon-plus",
      size: "mini",
      type: "primary"
    },
    on: {
      click: function click($event) {
        _vm.addCompetitorDialog = true;
      }
    }
  }, [_vm._v("Add Competitor")])], 1)], 1)], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    staticStyle: {
      "padding-top": "10px",
      "background-color": "white",
      width: "99%"
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-tabs", {
    attrs: {
      type: "border-card"
    },
    model: {
      value: _vm.competitiveDetailsTab,
      callback: function callback($$v) {
        _vm.competitiveDetailsTab = $$v;
      },
      expression: "competitiveDetailsTab"
    }
  }, [_c("el-tab-pane", {
    staticClass: "container",
    attrs: {
      label: "Features",
      name: "first"
    }
  }, [_c("div", [!_vm.hasActiveCompetitor ? _c("span", [_vm._v("Select a competitors to view feature list ")]) : _vm._e(), _vm._v(" "), _vm.hasActiveCompetitor ? _c("el-row", [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-form", {
    attrs: {
      "label-width": "120px"
    }
  }, [_vm.hasActiveCompetitor ? _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, _vm._l(_vm.activeCompetitor.features, function (feature, index) {
    return _c("el-col", {
      key: index,
      staticClass: "text-center",
      attrs: {
        span: 8
      }
    }, [_c("el-table", {
      staticStyle: {
        width: "100%"
      },
      attrs: {
        data: feature.feature_list
      }
    }, [_c("el-table-column", {
      attrs: {
        label: _vm.computeTitle(feature.type)
      }
    }, [_c("el-table-column", {
      attrs: {
        label: "Feature",
        prop: "name"
      }
    }), _vm._v(" "), _c("el-table-column", {
      attrs: {
        label: "Maturity",
        prop: "maturity"
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(scope) {
          return [_c("el-tag", {
            attrs: {
              type: "info",
              size: "mini"
            }
          }, [_vm._v("\n                                  " + _vm._s(scope.row.maturity) + "\n                                ")])];
        }
      }], null, true)
    }), _vm._v(" "), _c("el-table-column", {
      attrs: {
        label: "Risk",
        prop: "risk"
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(scope) {
          return [_c("el-tag", {
            attrs: {
              type: _vm.computeRisk(scope.row.risk),
              size: "mini"
            }
          }, [_vm._v("\n                                  " + _vm._s(scope.row.risk) + "\n                                ")])];
        }
      }], null, true)
    })], 1)], 1), _vm._v(" "), feature.type === "unique-competitor" ? _c("div", {
      staticClass: "text-right mt-10"
    }, [_c("el-button", {
      attrs: {
        type: "primary",
        icon: "el-icon-plus",
        size: "mini"
      },
      on: {
        click: function click($event) {
          return _vm.openAddFeatureDialog(index);
        }
      }
    }, [_vm._v("Add Feature")])], 1) : _vm._e()], 1);
  }), 1) : _vm._e()], 1)], 1)], 1) : _vm._e()], 1)]), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: "Strategy & Pricing",
      name: "second"
    }
  }, [_vm.hasActiveCompetitor ? _c("el-row", [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form", {
    attrs: {
      "label-width": "150px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "What they do well"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.activeCompetitor.strengths,
      callback: function callback($$v) {
        _vm.$set(_vm.activeCompetitor, "strengths", $$v);
      },
      expression: "activeCompetitor.strengths"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "What they do poorly"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.activeCompetitor.weaknesses,
      callback: function callback($$v) {
        _vm.$set(_vm.activeCompetitor, "weaknesses", $$v);
      },
      expression: "activeCompetitor.weaknesses"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "How we compete"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.activeCompetitor.how_we_compete,
      callback: function callback($$v) {
        _vm.$set(_vm.activeCompetitor, "how_we_compete", $$v);
      },
      expression: "activeCompetitor.how_we_compete"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form", {
    attrs: {
      "label-width": "150px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Category"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.activeCompetitor.category,
      callback: function callback($$v) {
        _vm.$set(_vm.activeCompetitor, "category", $$v);
      },
      expression: "activeCompetitor.category"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Segment"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.activeCompetitor.segment,
      callback: function callback($$v) {
        _vm.$set(_vm.activeCompetitor, "segment", $$v);
      },
      expression: "activeCompetitor.segment"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Market Share"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.activeCompetitor.market_share,
      callback: function callback($$v) {
        _vm.$set(_vm.activeCompetitor, "market_share", $$v);
      },
      expression: "activeCompetitor.market_share"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Market"
    }
  }, [_c("el-select", {
    attrs: {
      multiple: "",
      placeholder: "Select"
    },
    model: {
      value: _vm.activeCompetitor.markets,
      callback: function callback($$v) {
        _vm.$set(_vm.activeCompetitor, "markets", $$v);
      },
      expression: "activeCompetitor.markets"
    }
  }, _vm._l(_vm.activeCompetitor.markets, function (market) {
    return _c("el-option", {
      key: market,
      attrs: {
        label: market,
        value: market
      }
    });
  }), 1)], 1)], 1)], 1)], 1) : _vm._e()], 1), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: "Company Info",
      name: "third"
    }
  }, [_c("el-form", {
    attrs: {
      "label-width": "120px"
    }
  }, [_vm.hasActiveCompetitor ? _c("el-row", [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "HQ"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.activeCompetitor.head_office,
      callback: function callback($$v) {
        _vm.$set(_vm.activeCompetitor, "head_office", $$v);
      },
      expression: "activeCompetitor.head_office"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Pricing"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.activeCompetitor.pricing,
      callback: function callback($$v) {
        _vm.$set(_vm.activeCompetitor, "pricing", $$v);
      },
      expression: "activeCompetitor.pricing"
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Users"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.activeCompetitor.users,
      callback: function callback($$v) {
        _vm.$set(_vm.activeCompetitor, "users", $$v);
      },
      expression: "activeCompetitor.users"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Est Value"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.activeCompetitor.estimate_value,
      callback: function callback($$v) {
        _vm.$set(_vm.activeCompetitor, "estimate_value", $$v);
      },
      expression: "activeCompetitor.estimate_value"
    }
  })], 1)], 1)], 1) : _vm._e()], 1)], 1), _vm._v(" "), _vm.activeCompetitor ? _c("el-tab-pane", {
    attrs: {
      label: "Our Advantages",
      name: "fourth"
    }
  }, [_vm.activeCompetitor.features[2] ? _c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.activeCompetitor.features[2].feature_list
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Feature",
      prop: "name"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Description",
      prop: "description"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Maturity",
      prop: "maturity"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-tag", {
          attrs: {
            type: "info",
            size: "mini"
          }
        }, [_vm._v("\n                    " + _vm._s(scope.row.maturity) + "\n                  ")])];
      }
    }], null, false, 1387524941)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Risk",
      prop: "risk"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-tag", {
          attrs: {
            type: _vm.computeRisk(scope.row.risk),
            size: "mini"
          }
        }, [_vm._v("\n                    " + _vm._s(scope.row.risk) + "\n                  ")])];
      }
    }], null, false, 2976511375)
  })], 1) : _vm._e()], 1) : _vm._e()], 1)], 1)], 1), _vm._v(" "), _c("el-divider"), _vm._v(" "), _c("div", {
    staticClass: "text-right"
  }, [_c("el-button-group", {
    attrs: {
      size: "mini"
    }
  }, [_c("el-tooltip", {
    attrs: {
      effect: "dark",
      content: "",
      placement: "top-start"
    }
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      disabled: "",
      icon: "el-icon-arrow-left"
    }
  }, [_vm._v("Previous")])], 1), _vm._v(" "), _c("el-tooltip", {
    attrs: {
      effect: "dark",
      content: "Go To Assess Competitor Features",
      placement: "top-start"
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.onClickAssessCompetitorFeatures
    }
  }, [_vm._v("Next"), _c("i", {
    staticClass: "el-icon-arrow-right"
  })])], 1)], 1)], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Add Feature",
      visible: _vm.addFeatureDialog,
      width: "40%",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addFeatureDialog = $event;
      }
    }
  }, [_c("el-form", {
    ref: "feature_form",
    attrs: {
      model: _vm.feature_form,
      "label-width": "200px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Feature Name"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.feature_form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.feature_form, "name", $$v);
      },
      expression: "feature_form.name"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Description"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.feature_form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.feature_form, "description", $$v);
      },
      expression: "feature_form.description"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Feature Maturity"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "small"
    },
    model: {
      value: _vm.feature_form.maturity,
      callback: function callback($$v) {
        _vm.$set(_vm.feature_form, "maturity", $$v);
      },
      expression: "feature_form.maturity"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: "high"
    }
  }, [_vm._v(" High ")]), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "medium"
    }
  }, [_vm._v(" Medium ")]), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "low"
    }
  }, [_vm._v(" Low ")])], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Feature Risk"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "small"
    },
    model: {
      value: _vm.feature_form.risk,
      callback: function callback($$v) {
        _vm.$set(_vm.feature_form, "risk", $$v);
      },
      expression: "feature_form.risk"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: "high"
    }
  }, [_vm._v(" High ")]), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "medium"
    }
  }, [_vm._v(" Medium ")]), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "low"
    }
  }, [_vm._v(" Low ")])], 1)], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.addFeatureDialog = false;
      }
    }
  }, [_vm._v("Cancel")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.addFeature
    }
  }, [_vm._v("Create")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Add Competitors",
      visible: _vm.addCompetitorDialog,
      width: "70%",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addCompetitorDialog = $event;
      }
    }
  }, [_c("el-form", {
    ref: "competitor_form",
    attrs: {
      model: _vm.competitor_form,
      "label-width": "200px"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Name"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.competitor_form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.competitor_form, "name", $$v);
      },
      expression: "competitor_form.name"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Head Office"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.competitor_form.head_office,
      callback: function callback($$v) {
        _vm.$set(_vm.competitor_form, "head_office", $$v);
      },
      expression: "competitor_form.head_office"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Category"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.competitor_form.category,
      callback: function callback($$v) {
        _vm.$set(_vm.competitor_form, "category", $$v);
      },
      expression: "competitor_form.category"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: "Direct"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Close"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Peripheral"
    }
  })], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Segment"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.competitor_form.segment,
      callback: function callback($$v) {
        _vm.$set(_vm.competitor_form, "segment", $$v);
      },
      expression: "competitor_form.segment"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: "Methodology"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "UX Tools"
    }
  })], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Markets"
    }
  }, [_c("el-select", {
    staticStyle: {
      "margin-left": "20px"
    },
    attrs: {
      multiple: "",
      placeholder: "Select"
    },
    model: {
      value: _vm.competitor_form.markets,
      callback: function callback($$v) {
        _vm.$set(_vm.competitor_form, "markets", $$v);
      },
      expression: "competitor_form.markets"
    }
  }, _vm._l(_vm.continents, function (continent) {
    return _c("el-option", {
      key: continent.name,
      attrs: {
        label: continent.name,
        value: continent.name
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Estimate Value"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.competitor_form.estimate_value,
      callback: function callback($$v) {
        _vm.$set(_vm.competitor_form, "estimate_value", $$v);
      },
      expression: "competitor_form.estimate_value"
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Strength"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.competitor_form.strengths,
      callback: function callback($$v) {
        _vm.$set(_vm.competitor_form, "strengths", $$v);
      },
      expression: "competitor_form.strengths"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Weakness"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.competitor_form.weaknesses,
      callback: function callback($$v) {
        _vm.$set(_vm.competitor_form, "weaknesses", $$v);
      },
      expression: "competitor_form.weaknesses"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "How we compete"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.competitor_form.how_we_compete,
      callback: function callback($$v) {
        _vm.$set(_vm.competitor_form, "how_we_compete", $$v);
      },
      expression: "competitor_form.how_we_compete"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Pricing"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.competitor_form.pricing,
      callback: function callback($$v) {
        _vm.$set(_vm.competitor_form, "pricing", $$v);
      },
      expression: "competitor_form.pricing"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Users"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.competitor_form.users,
      callback: function callback($$v) {
        _vm.$set(_vm.competitor_form, "users", $$v);
      },
      expression: "competitor_form.users"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Market Share"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.competitor_form.market_share,
      callback: function callback($$v) {
        _vm.$set(_vm.competitor_form, "market_share", $$v);
      },
      expression: "competitor_form.market_share"
    }
  })], 1)], 1)], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.addCompetitorDialog = false;
      }
    }
  }, [_vm._v("Cancel")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.createCompetitor
    }
  }, [_vm._v("Create")])], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;