"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-form", {
    ref: "addStepform",
    attrs: {
      rules: _vm.addSteprules,
      model: _vm.task_form,
      "label-width": "120px",
      size: "mini"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Name",
      prop: "title"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.task_form.title,
      callback: function callback($$v) {
        _vm.$set(_vm.task_form, "title", $$v);
      },
      expression: "task_form.title"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Description",
      prop: "description"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini",
      type: "textarea"
    },
    model: {
      value: _vm.task_form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.task_form, "description", $$v);
      },
      expression: "task_form.description"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Thoughts",
      prop: "intention"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini",
      type: "textarea"
    },
    model: {
      value: _vm.task_form.intention,
      callback: function callback($$v) {
        _vm.$set(_vm.task_form, "intention", $$v);
      },
      expression: "task_form.intention"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Priority",
      prop: "priority"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.task_form.priority,
      callback: function callback($$v) {
        _vm.$set(_vm.task_form, "priority", $$v);
      },
      expression: "task_form.priority"
    }
  }, [_c("el-radio", {
    attrs: {
      label: "Very Low"
    }
  }), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "Low"
    }
  }), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "Medium"
    }
  }), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "High"
    }
  }), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "Very High"
    }
  })], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Value",
      prop: "importance"
    }
  }, [_c("el-radio-group", {
    model: {
      value: _vm.task_form.importance,
      callback: function callback($$v) {
        _vm.$set(_vm.task_form, "importance", $$v);
      },
      expression: "task_form.importance"
    }
  }, [_c("el-radio", {
    attrs: {
      label: "Very Low"
    }
  }), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "Low"
    }
  }), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "Medium"
    }
  }), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "High"
    }
  }), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "Very High"
    }
  })], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Goal",
      prop: "goal"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini",
      type: "textarea",
      rows: "4"
    },
    model: {
      value: _vm.task_form.goal,
      callback: function callback($$v) {
        _vm.$set(_vm.task_form, "goal", $$v);
      },
      expression: "task_form.goal"
    }
  })], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "text-right mt-10"
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.createTask
    }
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "create"
    }
  }), _vm._v(" Create Step")], 1), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini",
      icon: "el-icon-close"
    },
    on: {
      click: _vm.closeUI
    }
  }, [_vm._v("Cancel")])], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;