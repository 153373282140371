"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-row", {
    staticClass: "mt-10",
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 4
    }
  }, [_c("el-card", [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("el-tooltip", {
    attrs: {
      content: "Drag customers from the segments below and drop them into groups. Make sure you have created all your customer groups. ",
      theme: "dark",
      placement: "right"
    }
  }, [_c("strong", [_vm._v("Segments & Customers")])])], 1), _vm._v(" "), _c("el-tree", {
    attrs: {
      data: _vm.segments,
      props: _vm.defaultProps,
      draggable: "",
      "allow-drop": _vm.allowDrop,
      "default-expand-all": true,
      "empty-text": "No segments exist. Make sure you create a segment and at least one customer in that segment."
    },
    on: {
      "node-drag-start": _vm.handleDragStart
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 20
    }
  }, [_c("el-card", [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("el-row", [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-popover", {
    ref: "groupTip",
    attrs: {
      width: "400",
      trigger: "hover",
      title: "Different user types have different feature-sets",
      placement: "bottom-start",
      "open-delay": 400,
      "close-delay": 400
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "0.9em",
      "word-break": "keep-all"
    }
  }, [_c("p", [_vm._v("\n                    User types give access to different groups of product features. This step focuses on Customers User Types. Some user types should be customers (Premium customers, free customers, etc) whilst others maybe internal to your company (admin, support, etc).\n                  ")]), _vm._v(" "), _c("p", [_vm._v("\n                    The features available to each user type is defined in the Design Product module\n                  ")])])]), _vm._v(" "), _c("strong", {
    directives: [{
      name: "popover",
      rawName: "v-popover:groupTip",
      arg: "groupTip"
    }]
  }, [_vm._v("Customer User Types")])], 1), _vm._v(" "), _c("el-col", {
    staticClass: "text-right",
    attrs: {
      span: 12
    }
  }, [_c("el-button", {
    attrs: {
      type: "text",
      icon: "el-icon-plus"
    },
    on: {
      click: function click($event) {
        _vm.createGroupDialog = true;
      }
    }
  }, [_vm._v("Create Group")])], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_vm.groups.length > 0 ? _vm._l(_vm.groups, function (group, index) {
    return _c("el-col", {
      key: index,
      attrs: {
        span: 6
      }
    }, [_c("el-card", {
      staticClass: "mt-10"
    }, [_c("div", {
      staticClass: "clearfix",
      attrs: {
        slot: "header",
        id: index
      },
      slot: "header"
    }, [_c("el-row", {
      staticClass: "group-header"
    }, [_c("el-col", {
      attrs: {
        span: 18
      }
    }, [_c("el-tooltip", {
      attrs: {
        placement: "top-start",
        content: group.description
      }
    }, [_c("div", {
      attrs: {
        contenteditable: "true"
      }
    }, [_vm._v(_vm._s(group.name))])])], 1), _vm._v(" "), _c("el-col", {
      staticClass: "text-right",
      attrs: {
        span: 6
      }
    }, [_c("i", {
      staticClass: "el-icon-delete",
      on: {
        click: function click($event) {
          return _vm.deleteGroup(index);
        }
      }
    })])], 1)], 1), _vm._v(" "), _c("div", {
      staticClass: "drop-area",
      attrs: {
        id: _vm.generateGroupID(index)
      },
      on: {
        drop: _vm.handleDrop,
        dragover: _vm.handleDragOver
      }
    }, _vm._l(group.customers, function (customer, sub_index) {
      return _c("el-tag", {
        key: sub_index,
        staticClass: "group-tag",
        attrs: {
          closable: ""
        },
        on: {
          close: function close($event) {
            return _vm.removeCustomer(index, sub_index);
          }
        }
      }, [_vm._v("\n                    " + _vm._s(customer.name) + "\n                  ")]);
    }), 1)])], 1);
  }) : _vm._e()], 2)], 1)], 1)], 1), _vm._v(" "), _c("el-card", {
    staticClass: "mt-10"
  }, [_c("div", {
    staticClass: "text-right"
  }, [_c("el-button-group", {
    attrs: {
      size: "mini"
    }
  }, [_c("el-tooltip", {
    attrs: {
      effect: "dark",
      content: "",
      placement: "top-start"
    }
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      disabled: "",
      icon: "el-icon-arrow-left"
    }
  }, [_vm._v("Previous")])], 1), _vm._v(" "), _c("el-tooltip", {
    attrs: {
      effect: "dark",
      content: "Go To Link Customer to User",
      placement: "top-start"
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.goToLinkCustomerToUser
    }
  }, [_vm._v("Next"), _c("i", {
    staticClass: "el-icon-arrow-right"
  })])], 1)], 1)], 1)]), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Create Group",
      visible: _vm.createGroupDialog,
      width: "30%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.createGroupDialog = $event;
      }
    }
  }, [_c("el-form", {
    ref: "groupForm",
    attrs: {
      model: _vm.group_form,
      "label-width": "120px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Name"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.group_form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.group_form, "name", $$v);
      },
      expression: "group_form.name"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Description"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.group_form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.group_form, "description", $$v);
      },
      expression: "group_form.description"
    }
  })], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "small"
    },
    on: {
      click: function click($event) {
        _vm.createGroupDialog = false;
      }
    }
  }, [_vm._v("Cancel")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "small",
      type: "primary"
    },
    on: {
      click: _vm.createGroup
    }
  }, [_vm._v("Create Group")])], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;