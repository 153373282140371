"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("g", {
    attrs: {
      id: "process"
    },
    on: {
      click: _vm.openDialog
    }
  }, [_c("circle", {
    style: _vm.updateStyle(),
    attrs: {
      cx: parseInt(_vm.x),
      cy: parseInt(_vm.y),
      r: 30
    }
  }), _vm._v(" "), _c("text", {
    attrs: {
      x: parseInt(_vm.x),
      y: parseInt(_vm.y),
      fill: "black",
      "font-size": "8",
      "font-weight": "lighter",
      "letter-spacing": "1",
      dy: "0",
      "text-anchor": "middle"
    }
  }, [_c("tspan", {
    attrs: {
      x: parseInt(_vm.x),
      dy: "0"
    }
  }, [_vm._v(_vm._s(_vm.text1))]), _vm._v(" "), _c("tspan", {
    attrs: {
      x: parseInt(_vm.x),
      dy: "0.7em"
    }
  }, [_vm._v(_vm._s(_vm.text2))])])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;