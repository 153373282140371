"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _axios = _interopRequireDefault(require("axios"));
var _default = exports.default = {
  data: function data() {
    return {
      $_isExternalStatus: false,
      $_offering: {},
      $_offerings: [],
      $_journeymap: {}
    };
  },
  mounted: function mounted() {},
  beforeDestroy: function beforeDestroy() {},
  methods: {
    $_computeJourneymapProgress: function $_computeJourneymapProgress(offering, journeymap) {
      // this.journeyMap = Object.assign({}, journeymap)
      // this.offering = Object.assign({}, offering)
      for (var i = this.journeyMap.linkedJourneyPhases.length - 1; i >= 0; i--) {
        this.journeyMap.linkedJourneyPhases[i].journeyPhase.prodiggi_status = [0, 0, 0, 0, 0, 0, 0, 0];
        this.journeyMap.linkedJourneyPhases[i].journeyPhase.external_status = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        this.$_retrieveFeatureProcessRelationForPhase(this.journeyMap.linkedJourneyPhases[i].journeyPhase.id);
      }
      for (var j = this.journeyMap.linkedJourneySteps.length - 1; j >= 0; j--) {
        this.journeyMap.linkedJourneySteps[j].journeyStep.prodiggi_status = [0, 0, 0, 0, 0, 0, 0, 0];
        this.journeyMap.linkedJourneySteps[j].journeyStep.external_status = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        this.$_retrieveFeatureProcessRelationForStep(this.journeyMap.linkedJourneySteps[j].journeyStep.id);
      }
    },
    $_retrieveFeatureProcessRelationForPhase: function $_retrieveFeatureProcessRelationForPhase(phase_id) {
      var subentity_ids = [];
      for (var i = this.offering.additionalattributes.relations.length - 1; i >= 0; i--) {
        if (this.offering.additionalattributes.relations[i].start_id === phase_id && this.offering.additionalattributes.relations[i].end_type === 'Feature') {
          subentity_ids.push({
            'id': this.offering.additionalattributes.relations[i].end_id,
            'type': 'ProductFeature'
          });
          this.$_retrieveTicketStatus('ProductFeature', this.offering.additionalattributes.relations[i].end_id, 'Phase', 0, 0, null);
        }
        if (this.offering.additionalattributes.relations[i].start_id === phase_id && this.offering.additionalattributes.relations[i].end_type === 'ServiceApplication') {
          subentity_ids.push({
            'id': this.offering.additionalattributes.relations[i].end_id,
            'type': 'ServiceApplication'
          });
          this.$_retrieveTicketStatus('ServiceApplication', this.offering.additionalattributes.relations[i].end_id, 'Phase', 0, 0, null);
        }
      }
    },
    $_retrieveFeatureProcessRelationForStep: function $_retrieveFeatureProcessRelationForStep(step_id) {
      var subentity_ids = [];
      for (var i = this.offering.additionalattributes.relations.length - 1; i >= 0; i--) {
        if (this.offering.additionalattributes.relations[i].start_id === step_id && this.offering.additionalattributes.relations[i].end_type === 'Feature') {
          subentity_ids.push({
            'id': this.offering.additionalattributes.relations[i].end_id,
            'type': 'ProductFeature'
          });
          this.$_retrieveTicketStatus('ProductFeature', this.offering.additionalattributes.relations[i].end_id, 'Step', 0, 0, null);
        }
        if (this.offering.additionalattributes.relations[i].start_id === step_id && this.offering.additionalattributes.relations[i].end_type === 'ServiceApplication') {
          subentity_ids.push({
            'id': this.offering.additionalattributes.relations[i].end_id,
            'type': 'ServiceApplication'
          });
          this.$_retrieveTicketStatus('ServiceApplication', this.offering.additionalattributes.relations[i].end_id, 'Step', 0, 0, null);
        }
      }
    },
    $_computeOfferingsProgress: function $_computeOfferingsProgress(offerings) {
      // this.offerings = offerings
      for (var k = 0; k < this.offerings.length; k++) {
        for (var i = 0; i < this.offerings[k].offering.linkedProducts.length; i++) {
          this.offerings[k].offering.linkedProducts[i].product.prodiggi_status = [0, 0, 0, 0, 0, 0, 0, 0];
          this.offerings[k].offering.linkedProducts[i].product.external_status = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
          this.offerings[k].offering.prodiggi_status = [0, 0, 0, 0, 0, 0, 0, 0];
          this.offerings[k].offering.external_status = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
          this.$_computeProductStatus(this.offerings[k].offering.linkedProducts[i].product, i, 'Offerings', k);
        }
        for (var j = this.offerings[k].offering.linkedServices.length - 1; j >= 0; j--) {
          this.offerings[k].offering.linkedServices[j].service.prodiggi_status = [0, 0, 0, 0, 0, 0, 0, 0];
          this.offerings[k].offering.linkedServices[j].service.external_status = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
          this.offerings[k].offering.prodiggi_status = [0, 0, 0, 0, 0, 0, 0, 0];
          this.offerings[k].offering.external_status = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
          this.$_computeServiceStatus(this.offerings[k].offering.linkedServices[j].service, j, 'Offerings', k);
        }
      }
    },
    $_computeOfferingProgress: function $_computeOfferingProgress(offering) {
      // this.offering = Object.assign({}, offering)
      for (var i = 0; i < this.offering.linkedProducts.length; i++) {
        this.offering.linkedProducts[i].product.prodiggi_status = [0, 0, 0, 0, 0, 0, 0, 0];
        this.offering.linkedProducts[i].product.external_status = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        this.$_computeProductStatus(this.offering.linkedProducts[i].product, i, 'Offering', null);
      }
      for (var j = this.offering.linkedServices.length - 1; j >= 0; j--) {
        this.offering.linkedServices[j].service.prodiggi_status = [0, 0, 0, 0, 0, 0, 0, 0];
        this.offering.linkedServices[j].service.external_status = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        this.$_computeServiceStatus(this.offering.linkedServices[j].service, j, 'Offering', null);
      }
    },
    $_computeProductStatus: function $_computeProductStatus(product, index, type) {
      var offering_index = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
      for (var i = 0; i < product.linkedFeatures.length; i++) {
        this.$_retrieveTicketStatus(product.linkedFeatures[i].productFeature.__typename, product.linkedFeatures[i].productFeature.id, type, index, i, offering_index);
      }
    },
    $_computeServiceStatus: function $_computeServiceStatus(service, index, type) {
      var offering_index = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
      for (var i = 0; i < service.linkedServiceApplications.length; i++) {
        this.$_retrieveTicketStatus(service.linkedServiceApplications[i].serviceApplication.__typename, service.linkedServiceApplications[i].serviceApplication.id, type, index, i, offering_index);
      }
    },
    $_retrieveTicketStatus: function $_retrieveTicketStatus(entity_type, entity_id, type, parent_index, index, offering_index) {
      var _this = this;
      // Retrieve Ticket and populate ticket modal
      var headers = {
        'Content-Type': 'application/json',
        'x-party-id': this.$store.getters.partyId,
        'x-user-id': this.$store.getters.id
      };
      _axios.default.get(process.env.VUE_APP_REST_API_ENDPOINT + 'ticket?entityId=' + entity_id, {
        headers: headers
      }).then(function (response) {
        var ticket = {};
        ticket = response.data.data[0];
        if (ticket.externalId !== undefined && ticket.externalStatus && ticket.status) {
          if (entity_type === 'ProductFeature') {
            if (type === 'Offerings') {
              _this.offerings[offering_index].offering.linkedProducts[parent_index].product.linkedFeatures[index].productFeature.external_status = ticket.externalStatus;
              _this.offerings[offering_index].offering.linkedProducts[parent_index].product.linkedFeatures[index].productFeature.prodiggi_status = ticket.status;
              _this.$_updateStatusToOfferingArrayProduct(parent_index, ticket.externalStatus, ticket.status, offering_index);
            }
            if (type === 'Offering') {
              _this.offering.linkedProducts[parent_index].product.linkedFeatures[index].productFeature.external_status = ticket.externalStatus;
              _this.offering.linkedProducts[parent_index].product.linkedFeatures[index].productFeature.prodiggi_status = ticket.status;
              _this.$_updateStatusToProduct(parent_index, ticket.externalStatus, ticket.status);
            }
            if (type === 'Phase') {
              _this.$_updateStatusToPhase(entity_id, ticket.externalStatus, ticket.status);
            }
            if (type === 'Step') {
              _this.$_updateStatusToStep(entity_id, ticket.externalStatus, ticket.status);
            }
          }
          if (entity_type === 'ServiceApplication') {
            if (type === 'Offerings') {
              _this.offerings[offering_index].offering.linkedServices[parent_index].service.linkedServiceApplications[index].serviceApplication.external_status = ticket.externalStatus;
              _this.offerings[offering_index].offering.linkedServices[parent_index].service.linkedServiceApplications[index].serviceApplication.prodiggi_status = ticket.status;
              _this.$_updateStatusToOfferingArrayService(parent_index, ticket.externalStatus, ticket.status, offering_index);
            }
            if (type === 'Offering') {
              _this.offering.linkedServices[parent_index].service.linkedServiceApplications[index].serviceApplication.external_status = ticket.externalStatus;
              _this.offering.linkedServices[parent_index].service.linkedServiceApplications[index].serviceApplication.prodiggi_status = ticket.status;
              _this.$_updateStatusToService(parent_index, ticket.externalStatus, ticket.status);
            }
            if (type === 'Phase') {
              _this.$_updateStatusToPhase(entity_id, ticket.externalStatus, ticket.status);
            }
            if (type === 'Step') {
              _this.$_updateStatusToStep(entity_id, ticket.externalStatus, ticket.status);
            }
          }
        }
        if (!ticket.externalId === undefined && ticket.externalStatus && ticket.status) {
          if (entity_type === 'ProductFeature') {
            if (type === 'Offerings') {
              _this.offerings[offering_index].offering.linkedProducts[parent_index].product.linkedFeatures[index].productFeature.external_status = ticket.externalStatus;
              _this.offerings[offering_index].offering.linkedProducts[parent_index].product.linkedFeatures[index].productFeature.prodiggi_status = ticket.status;
              _this.$_updateStatusToOfferingArrayProduct(parent_index, ticket.externalStatus, ticket.status, offering_index);
            }
            if (type === 'Offering') {
              _this.offering.linkedProducts[parent_index].product.linkedFeatures[index].productFeature.external_status = ticket.externalStatus;
              _this.offering.linkedProducts[parent_index].product.linkedFeatures[index].productFeature.prodiggi_status = ticket.status;
              _this.$_updateStatusToProduct(parent_index, ticket.externalStatus, ticket.status);
            }
            if (type === 'Phase') {
              _this.$_updateStatusToPhase(entity_id, ticket.externalStatus, ticket.status);
            }
            if (type === 'Step') {
              _this.$_updateStatusToStep(entity_id, ticket.externalStatus, ticket.status);
            }
          }
          if (entity_type === 'ServiceApplication') {
            if (type === 'Offerings') {
              _this.offerings[offering_index].offering.linkedServices[parent_index].service.linkedServiceApplications[index].serviceApplication.external_status = ticket.externalStatus;
              _this.offerings[offering_index].offering.linkedServices[parent_index].service.linkedServiceApplications[index].serviceApplication.prodiggi_status = ticket.status;
              _this.$_updateStatusToOfferingArrayService(parent_index, ticket.externalStatus, ticket.status, offering_index);
            }
            if (type === 'Offering') {
              _this.offering.linkedServices[parent_index].service.linkedServiceApplications[index].serviceApplication.external_status = ticket.externalStatus;
              _this.offering.linkedServices[parent_index].service.linkedServiceApplications[index].serviceApplication.prodiggi_status = ticket.status;
              _this.$_updateStatusToService(parent_index, ticket.externalStatus, ticket.status);
            }
            if (type === 'Phase') {
              _this.$_updateStatusToPhase(entity_id, ticket.externalStatus, ticket.status);
            }
            if (type === 'Step') {
              _this.$_updateStatusToStep(entity_id, ticket.externalStatus, ticket.status);
            }
          }
        }
      }).catch(function (error) {
        console.log(error);
      });
    },
    $_updateStatusToOfferingArrayProduct: function $_updateStatusToOfferingArrayProduct(product_index, external_status, prodiggi_status, offering_index) {
      switch (external_status) {
        case 'Backlog':
          this.offerings[offering_index].offering.linkedProducts[product_index].product.external_status[0]++;
          this.offerings[offering_index].offering.external_status[0]++;
          break;
        case 'Candidate':
          this.offerings[offering_index].offering.linkedProducts[product_index].product.external_status[1]++;
          this.offerings[offering_index].offering.external_status[1]++;
          break;
        case 'Approved':
          this.offerings[offering_index].offering.linkedProducts[product_index].product.external_status[2]++;
          this.offerings[offering_index].offering.external_status[2]++;
          break;
        case 'Feature Design':
          this.offerings[offering_index].offering.linkedProducts[product_index].product.external_status[3]++;
          this.offerings[offering_index].offering.external_status[3]++;
          break;
        case 'Functional Design':
          this.offerings[offering_index].offering.linkedProducts[product_index].product.external_status[4]++;
          this.offerings[offering_index].offering.external_status[4]++;
          break;
        case 'Technical Design':
          this.offerings[offering_index].offering.linkedProducts[product_index].product.external_status[5]++;
          this.offerings[offering_index].offering.external_status[5]++;
          break;
        case 'Build Planning':
          this.offerings[offering_index].offering.linkedProducts[product_index].product.external_status[6]++;
          this.offerings[offering_index].offering.external_status[6]++;
          break;
        case 'Delivery':
          this.offerings[offering_index].offering.linkedProducts[product_index].product.external_status[7]++;
          this.offerings[offering_index].offering.external_status[7]++;
          break;
        case 'Ready for Release':
          this.offerings[offering_index].offering.linkedProducts[product_index].product.external_status[8]++;
          this.offerings[offering_index].offering.external_status[8]++;
          break;
        case 'Released':
          this.offerings[offering_index].offering.linkedProducts[product_index].product.external_status[9]++;
          this.offerings[offering_index].offering.external_status[9]++;
          break;
        case 'Improvement':
          this.offerings[offering_index].offering.linkedProducts[product_index].product.external_status[10]++;
          this.offerings[offering_index].offering.external_status[10]++;
          break;
        case 'Retired':
          this.offerings[offering_index].offering.linkedProducts[product_index].product.external_status[11]++;
          this.offerings[offering_index].offering.external_status[11]++;
          break;
        default:
          this.offerings[offering_index].offering.linkedProducts[product_index].product.external_status[0]++;
          this.offerings[offering_index].offering.external_status[0]++;
          break;
      }
      switch (prodiggi_status) {
        case 'Candidate':
          this.offerings[offering_index].offering.linkedProducts[product_index].product.prodiggi_status[0]++;
          this.offerings[offering_index].offering.prodiggi_status[0]++;
          break;
        case 'Approved':
          this.offerings[offering_index].offering.linkedProducts[product_index].product.prodiggi_status[1]++;
          this.offerings[offering_index].offering.prodiggi_status[1]++;
          break;
        case 'Backlog':
          this.offerings[offering_index].offering.linkedProducts[product_index].product.prodiggi_status[2]++;
          this.offerings[offering_index].offering.prodiggi_status[2]++;
          break;
        case 'In Progress':
          this.offerings[offering_index].offering.linkedProducts[product_index].product.prodiggi_status[3]++;
          this.offerings[offering_index].offering.prodiggi_status[3]++;
          break;
        case 'Finalised':
          this.offerings[offering_index].offering.linkedProducts[product_index].product.prodiggi_status[4]++;
          this.offerings[offering_index].offering.prodiggi_status[4]++;
          break;
        case 'Released':
          this.offerings[offering_index].offering.linkedProducts[product_index].product.prodiggi_status[5]++;
          this.offerings[offering_index].offering.prodiggi_status[5]++;
          break;
        case 'Improvement':
          this.offerings[offering_index].offering.linkedProducts[product_index].product.prodiggi_status[6]++;
          this.offerings[offering_index].offering.prodiggi_status[6]++;
          break;
        case 'Retired':
          this.offerings[offering_index].offering.linkedProducts[product_index].product.prodiggi_status[7]++;
          this.offerings[offering_index].offering.prodiggi_status[7]++;
          break;
        default:
          this.offerings[offering_index].offering.linkedProducts[product_index].product.prodiggi_status[0]++;
          this.offerings[offering_index].offering.prodiggi_status[0]++;
          break;
      }
    },
    $_updateStatusToOfferingArrayService: function $_updateStatusToOfferingArrayService(service_index, external_status, prodiggi_status, offering_index) {
      switch (external_status) {
        case 'Backlog':
          this.offerings[offering_index].offering.linkedServices[service_index].service.external_status[0]++;
          this.offerings[offering_index].offering.external_status[0]++;
          break;
        case 'Candidate':
          this.offerings[offering_index].offering.linkedServices[service_index].service.external_status[1]++;
          this.offerings[offering_index].offering.external_status[1]++;
          break;
        case 'Approved':
          this.offerings[offering_index].offering.linkedServices[service_index].service.external_status[2]++;
          this.offerings[offering_index].offering.external_status[2]++;
          break;
        case 'Feature Design':
          this.offerings[offering_index].offering.linkedServices[service_index].service.external_status[3]++;
          this.offerings[offering_index].offering.external_status[3]++;
          break;
        case 'Functional Design':
          this.offerings[offering_index].offering.linkedServices[service_index].service.external_status[4]++;
          this.offerings[offering_index].offering.external_status[4]++;
          break;
        case 'Technical Design':
          this.offerings[offering_index].offering.linkedServices[service_index].service.external_status[5]++;
          this.offerings[offering_index].offering.external_status[5]++;
          break;
        case 'Build Planning':
          this.offerings[offering_index].offering.linkedServices[service_index].service.external_status[6]++;
          this.offerings[offering_index].offering.external_status[6]++;
          break;
        case 'Delivery':
          this.offerings[offering_index].offering.linkedServices[service_index].service.external_status[7]++;
          this.offerings[offering_index].offering.external_status[7]++;
          break;
        case 'Ready for Release':
          this.offerings[offering_index].offering.linkedServices[service_index].service.external_status[8]++;
          this.offerings[offering_index].offering.external_status[8]++;
          break;
        case 'Released':
          this.offerings[offering_index].offering.linkedServices[service_index].service.external_status[9]++;
          this.offerings[offering_index].offering.external_status[9]++;
          break;
        case 'Improvement':
          this.offerings[offering_index].offering.linkedServices[service_index].service.external_status[10]++;
          this.offerings[offering_index].offering.external_status[10]++;
          break;
        case 'Retired':
          this.offerings[offering_index].offering.linkedServices[service_index].service.external_status[11]++;
          this.offerings[offering_index].offering.external_status[11]++;
          break;
        default:
          this.offerings[offering_index].offering.linkedServices[service_index].service.external_status[0]++;
          this.offerings[offering_index].offering.external_status[0]++;
          break;
      }
      switch (prodiggi_status) {
        case 'Candidate':
          this.offerings[offering_index].offering.linkedServices[service_index].service.prodiggi_status[0]++;
          this.offerings[offering_index].offering.prodiggi_status[0]++;
          break;
        case 'Approved':
          this.offerings[offering_index].offering.linkedServices[service_index].service.prodiggi_status[1]++;
          this.offerings[offering_index].offering.prodiggi_status[1]++;
          break;
        case 'Backlog':
          this.offerings[offering_index].offering.linkedServices[service_index].service.prodiggi_status[2]++;
          this.offerings[offering_index].offering.prodiggi_status[2]++;
          break;
        case 'In Progress':
          this.offerings[offering_index].offering.linkedServices[service_index].service.prodiggi_status[3]++;
          this.offerings[offering_index].offering.prodiggi_status[3]++;
          break;
        case 'Finalised':
          this.offerings[offering_index].offering.linkedServices[service_index].service.prodiggi_status[4]++;
          this.offerings[offering_index].offering.prodiggi_status[4]++;
          break;
        case 'Released':
          this.offerings[offering_index].offering.linkedServices[service_index].service.prodiggi_status[5]++;
          this.offerings[offering_index].offering.prodiggi_status[5]++;
          break;
        case 'Improvement':
          this.offerings[offering_index].offering.linkedServices[service_index].service.prodiggi_status[6]++;
          this.offerings[offering_index].offering.prodiggi_status[6]++;
          break;
        case 'Retired':
          this.offerings[offering_index].offering.linkedServices[service_index].service.prodiggi_status[7]++;
          this.offerings[offering_index].offering.prodiggi_status[7]++;
          break;
        default:
          this.offerings[offering_index].offering.linkedServices[service_index].service.prodiggi_status[0]++;
          this.offerings[offering_index].offering.prodiggi_status[0]++;
          break;
      }
    },
    $_updateStatusToProduct: function $_updateStatusToProduct(product_index, external_status, prodiggi_status) {
      switch (external_status) {
        case 'Backlog':
          this.offering.linkedProducts[product_index].product.external_status[0]++;
          break;
        case 'Candidate':
          this.offering.linkedProducts[product_index].product.external_status[1]++;
          break;
        case 'Approved':
          this.offering.linkedProducts[product_index].product.external_status[2]++;
          break;
        case 'Feature Design':
          this.offering.linkedProducts[product_index].product.external_status[3]++;
          break;
        case 'Functional Design':
          this.offering.linkedProducts[product_index].product.external_status[4]++;
          break;
        case 'Technical Design':
          this.offering.linkedProducts[product_index].product.external_status[5]++;
          break;
        case 'Build Planning':
          this.offering.linkedProducts[product_index].product.external_status[6]++;
          break;
        case 'Delivery':
          this.offering.linkedProducts[product_index].product.external_status[7]++;
          break;
        case 'Ready for Release':
          this.offering.linkedProducts[product_index].product.external_status[8]++;
          break;
        case 'Released':
          this.offering.linkedProducts[product_index].product.external_status[9]++;
          break;
        case 'Improvement':
          this.offering.linkedProducts[product_index].product.external_status[10]++;
          break;
        case 'Retired':
          this.offering.linkedProducts[product_index].product.external_status[11]++;
          break;
        default:
          this.offering.linkedProducts[product_index].product.external_status[0]++;
          break;
      }
      switch (prodiggi_status) {
        case 'Candidate':
          this.offering.linkedProducts[product_index].product.prodiggi_status[0]++;
          break;
        case 'Approved':
          this.offering.linkedProducts[product_index].product.prodiggi_status[1]++;
          break;
        case 'Backlog':
          this.offering.linkedProducts[product_index].product.prodiggi_status[2]++;
          break;
        case 'In Progress':
          this.offering.linkedProducts[product_index].product.prodiggi_status[3]++;
          break;
        case 'Finalised':
          this.offering.linkedProducts[product_index].product.prodiggi_status[4]++;
          break;
        case 'Released':
          this.offering.linkedProducts[product_index].product.prodiggi_status[5]++;
          break;
        case 'Improvement':
          this.offering.linkedProducts[product_index].product.prodiggi_status[6]++;
          break;
        case 'Retired':
          this.offering.linkedProducts[product_index].product.prodiggi_status[7]++;
          break;
        default:
          this.offering.linkedProducts[product_index].product.prodiggi_status[0]++;
          break;
      }
    },
    $_updateStatusToService: function $_updateStatusToService(service_index, external_status, prodiggi_status) {
      switch (external_status) {
        case 'Backlog':
          this.offering.linkedServices[service_index].service.external_status[0]++;
          break;
        case 'Candidate':
          this.offering.linkedServices[service_index].service.external_status[1]++;
          break;
        case 'Approved':
          this.offering.linkedServices[service_index].service.external_status[2]++;
          break;
        case 'Feature Design':
          this.offering.linkedServices[service_index].service.external_status[3]++;
          break;
        case 'Functional Design':
          this.offering.linkedServices[service_index].service.external_status[4]++;
          break;
        case 'Technical Design':
          this.offering.linkedServices[service_index].service.external_status[5]++;
          break;
        case 'Build Planning':
          this.offering.linkedServices[service_index].service.external_status[6]++;
          break;
        case 'Delivery':
          this.offering.linkedServices[service_index].service.external_status[7]++;
          break;
        case 'Ready for Release':
          this.offering.linkedServices[service_index].service.external_status[8]++;
          break;
        case 'Released':
          this.offering.linkedServices[service_index].service.external_status[9]++;
          break;
        case 'Improvement':
          this.offering.linkedServices[service_index].service.external_status[10]++;
          break;
        case 'Retired':
          this.offering.linkedServices[service_index].service.external_status[11]++;
          break;
        default:
          this.offering.linkedServices[service_index].service.external_status[0]++;
          break;
      }
      switch (prodiggi_status) {
        case 'Candidate':
          this.offering.linkedServices[service_index].service.prodiggi_status[0]++;
          break;
        case 'Approved':
          this.offering.linkedServices[service_index].service.prodiggi_status[1]++;
          break;
        case 'Backlog':
          this.offering.linkedServices[service_index].service.prodiggi_status[2]++;
          break;
        case 'In Progress':
          this.offering.linkedServices[service_index].service.prodiggi_status[3]++;
          break;
        case 'Finalised':
          this.offering.linkedServices[service_index].service.prodiggi_status[4]++;
          break;
        case 'Released':
          this.offering.linkedServices[service_index].service.prodiggi_status[5]++;
          break;
        case 'Improvement':
          this.offering.linkedServices[service_index].service.prodiggi_status[6]++;
          break;
        case 'Retired':
          this.offering.linkedServices[service_index].service.prodiggi_status[7]++;
          break;
        default:
          this.offering.linkedServices[service_index].service.prodiggi_status[0]++;
          break;
      }
    },
    $_updateStatusToPhase: function $_updateStatusToPhase(phase_id, external_status, prodiggi_status) {
      var phase_index = this.$_computePhaseIndexFromPhaseId(phase_id);
      if (phase_index !== null) {
        switch (external_status) {
          case 'Backlog':
            this.journeyMap.linkedJourneyPhases[phase_index].journeyPhase.external_status[0]++;
            break;
          case 'Candidate':
            this.journeyMap.linkedJourneyPhases[phase_index].journeyPhase.external_status[1]++;
            break;
          case 'Approved':
            this.journeyMap.linkedJourneyPhases[phase_index].journeyPhase.external_status[2]++;
            break;
          case 'Feature Design':
            this.journeyMap.linkedJourneyPhases[phase_index].journeyPhase.external_status[3]++;
            break;
          case 'Functional Design':
            this.journeyMap.linkedJourneyPhases[phase_index].journeyPhase.external_status[4]++;
            break;
          case 'Technical Design':
            this.journeyMap.linkedJourneyPhases[phase_index].journeyPhase.external_status[5]++;
            break;
          case 'Build Planning':
            this.journeyMap.linkedJourneyPhases[phase_index].journeyPhase.external_status[6]++;
            break;
          case 'Delivery':
            this.journeyMap.linkedJourneyPhases[phase_index].journeyPhase.external_status[7]++;
            break;
          case 'Ready for Release':
            this.journeyMap.linkedJourneyPhases[phase_index].journeyPhase.external_status[8]++;
            break;
          case 'Released':
            this.journeyMap.linkedJourneyPhases[phase_index].journeyPhase.external_status[9]++;
            break;
          case 'Improvement':
            this.journeyMap.linkedJourneyPhases[phase_index].journeyPhase.external_status[10]++;
            break;
          case 'Retired':
            this.journeyMap.linkedJourneyPhases[phase_index].journeyPhase.external_status[11]++;
            break;
          default:
            this.journeyMap.linkedJourneyPhases[phase_index].journeyPhase.external_status[0]++;
            break;
        }
        switch (prodiggi_status) {
          case 'Candidate':
            this.journeyMap.linkedJourneyPhases[phase_index].journeyPhase.prodiggi_status[0]++;
            break;
          case 'Approved':
            this.journeyMap.linkedJourneyPhases[phase_index].journeyPhase.prodiggi_status[1]++;
            break;
          case 'Backlog':
            this.journeyMap.linkedJourneyPhases[phase_index].journeyPhase.prodiggi_status[2]++;
            break;
          case 'In Progress':
            this.journeyMap.linkedJourneyPhases[phase_index].journeyPhase.prodiggi_status[3]++;
            break;
          case 'Finalised':
            this.journeyMap.linkedJourneyPhases[phase_index].journeyPhase.prodiggi_status[4]++;
            break;
          case 'Released':
            this.journeyMap.linkedJourneyPhases[phase_index].journeyPhase.prodiggi_status[5]++;
            break;
          case 'Improvement':
            this.journeyMap.linkedJourneyPhases[phase_index].journeyPhase.prodiggi_status[6]++;
            break;
          case 'Retired':
            this.journeyMap.linkedJourneyPhases[phase_index].journeyPhase.prodiggi_status[7]++;
            break;
          default:
            this.journeyMap.linkedJourneyPhases[phase_index].journeyPhase.prodiggi_status[0]++;
            break;
        }
      }
    },
    $_updateStatusToStep: function $_updateStatusToStep(step_id, external_status, prodiggi_status) {
      var step_index = this.$_computeStepIndexFromStepId(step_id);
      if (step_index !== null) {
        switch (external_status) {
          case 'Backlog':
            this.journeyMap.linkedJourneySteps[step_index].journeyStep.external_status[0]++;
            break;
          case 'Candidate':
            this.journeyMap.linkedJourneySteps[step_index].journeyStep.external_status[1]++;
            break;
          case 'Approved':
            this.journeyMap.linkedJourneySteps[step_index].journeyStep.external_status[2]++;
            break;
          case 'Feature Design':
            this.journeyMap.linkedJourneySteps[step_index].journeyStep.external_status[3]++;
            break;
          case 'Functional Design':
            this.journeyMap.linkedJourneySteps[step_index].journeyStep.external_status[4]++;
            break;
          case 'Technical Design':
            this.journeyMap.linkedJourneySteps[step_index].journeyStep.external_status[5]++;
            break;
          case 'Build Planning':
            this.journeyMap.linkedJourneySteps[step_index].journeyStep.external_status[6]++;
            break;
          case 'Delivery':
            this.journeyMap.linkedJourneySteps[step_index].journeyStep.external_status[7]++;
            break;
          case 'Ready for Release':
            this.journeyMap.linkedJourneySteps[step_index].journeyStep.external_status[8]++;
            break;
          case 'Released':
            this.journeyMap.linkedJourneySteps[step_index].journeyStep.external_status[9]++;
            break;
          case 'Improvement':
            this.journeyMap.linkedJourneySteps[step_index].journeyStep.external_status[10]++;
            break;
          case 'Retired':
            this.journeyMap.linkedJourneySteps[step_index].journeyStep.external_status[11]++;
            break;
          default:
            this.journeyMap.linkedJourneySteps[step_index].journeyStep.external_status[0]++;
            break;
        }
        switch (prodiggi_status) {
          case 'Candidate':
            this.journeyMap.linkedJourneySteps[step_index].journeyStep.prodiggi_status[0]++;
            break;
          case 'Approved':
            this.journeyMap.linkedJourneySteps[step_index].journeyStep.prodiggi_status[1]++;
            break;
          case 'Backlog':
            this.journeyMap.linkedJourneySteps[step_index].journeyStep.prodiggi_status[2]++;
            break;
          case 'In Progress':
            this.journeyMap.linkedJourneySteps[step_index].journeyStep.prodiggi_status[3]++;
            break;
          case 'Finalised':
            this.journeyMap.linkedJourneySteps[step_index].journeyStep.prodiggi_status[4]++;
            break;
          case 'Released':
            this.journeyMap.linkedJourneySteps[step_index].journeyStep.prodiggi_status[5]++;
            break;
          case 'Improvement':
            this.journeyMap.linkedJourneySteps[step_index].journeyStep.prodiggi_status[6]++;
            break;
          case 'Retired':
            this.journeyMap.linkedJourneySteps[step_index].journeyStep.prodiggi_status[7]++;
            break;
          default:
            this.journeyMap.linkedJourneySteps[step_index].journeyStep.prodiggi_status[0]++;
            break;
        }
      }
    },
    $_computePhaseIndexFromPhaseId: function $_computePhaseIndexFromPhaseId(phase_id) {
      for (var i = this.journeyMap.linkedJourneyPhases.length - 1; i >= 0; i--) {
        if (this.journeyMap.linkedJourneyPhases[i].journeyPhase.id === phase_id) {
          return i;
        }
      }
      return null;
    },
    $_computeStepIndexFromStepId: function $_computeStepIndexFromStepId(step_id) {
      for (var i = this.journeyMap.linkedJourneySteps.length - 1; i >= 0; i--) {
        if (this.journeyMap.linkedJourneySteps[i].journeyStep.id === step_id) {
          return i;
        }
      }
      return null;
    }
  }
};