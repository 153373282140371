"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _TextWidget = _interopRequireDefault(require("./WireframeDesigner/TextWidget"));
var _TextAreaWidget = _interopRequireDefault(require("./WireframeDesigner/TextAreaWidget"));
var _ImageWidget = _interopRequireDefault(require("./WireframeDesigner/ImageWidget"));
var _default2 = exports.default = {
  name: 'WireframeDesigner',
  components: {
    TextWidget: _TextWidget.default,
    TextAreaWidget: _TextAreaWidget.default,
    ImageWidget: _ImageWidget.default
  },
  props: {
    layout: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      loading: false,
      isDraggable: false,
      isResizable: false,
      preview: true,
      contenteditable: false,
      activeDragElement: null,
      layout_data: []
    };
  },
  created: function created() {
    // Listen to event
    this.$eventHub.$on('save-heading', this.saveHeadings);
    this.$eventHub.$on('save-item', this.saveItem);
  },
  methods: {
    layoutUpdatedEvent: function layoutUpdatedEvent(event) {
      this.$emit('layout-updated', this.layout);
    },
    setActiveDragElement: function setActiveDragElement(type) {
      this.activeDragElement = type;
    },
    handleDrop: function handleDrop(event) {
      event.preventDefault(); // !important
      if (this.activeDragElement === 'text') {
        this.layout.push({
          'x': 0,
          'y': 0,
          'w': 4,
          'h': 2,
          'i': this.layout.length,
          'type': 'title',
          'title': 'Heading 1',
          'headings': {
            'h1': false,
            'h2': false,
            'h3': false
          }
        });
      } else if (this.activeDragElement === 'textarea') {
        this.layout.push({
          'x': 0,
          'y': 0,
          'w': 4,
          'h': 3,
          'i': this.layout.length,
          'type': 'content',
          'content': 'Content goes here'
        });
      } else if (this.activeDragElement === 'image') {
        this.layout.push({
          'x': 0,
          'y': 0,
          'w': 4,
          'h': 4,
          'i': this.layout.length,
          'type': 'image',
          'location': 'http://via.placeholder.com/600x350'
        });
      }
    },
    handleDragOver: function handleDragOver(event) {
      event.preventDefault(); // !important
    },
    saveHeadings: function saveHeadings(payload) {
      var currentItem = this.layout[payload.itemIndex];
      currentItem.headings.h1 = payload.values[0];
      currentItem.headings.h2 = payload.values[1];
      currentItem.headings.h3 = payload.values[2];
      this.layout[payload.itemIndex] = currentItem;
    },
    saveItem: function saveItem(payload) {
      this.layout[payload.itemIndex] = payload.item;
    },
    disableGrid: function disableGrid() {
      this.isDraggable = !this.isDraggable;
      this.isResizable = !this.isResizable;
      this.preview = !this.preview;
      this.contenteditable = !this.contenteditable;
    },
    removeItem: function removeItem(payload) {
      if (payload.key > -1) {
        this.layout.splice(payload.key, 1);
      }
    }
  }
};