"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.number.constructor");
var _PieChart = _interopRequireDefault(require("./PieChart"));
var _default2 = exports.default = {
  name: 'TaskPopover',
  components: {
    PieChartComponent: _PieChart.default
  },
  props: {
    journeyMap: {
      type: Object,
      required: true,
      default: function _default() {
        return {
          id: '',
          title: '',
          description: '',
          additionalattributes: {},
          linkedJourneyExperiences: [],
          linkedJourneyPhases: [],
          linkedJourneySteps: []
        };
      }
    },
    x: {
      type: Number,
      default: 0
    },
    y: {
      type: Number,
      default: 0
    },
    width: {
      type: Number,
      default: 0
    },
    index: {
      type: Number,
      default: 0
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    step: {
      type: Object,
      default: function _default() {
        return {
          title: '',
          description: '',
          relations: []
        };
      }
    },
    experiences: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    ideas: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    offering: {
      type: Object,
      required: true,
      default: function _default() {
        return {
          id: '',
          title: '',
          description: '',
          additionalattributes: {},
          linkedProducts: [],
          linkedServices: [],
          linkedCustomerTypes: []
        };
      }
    }
  },
  data: function data() {
    return {
      isHover: false,
      gainHover: false,
      painHover: false,
      wowHover: false,
      challengeHover: false,
      experienceChunkSize: 5,
      activeExperienceChunk: 0,
      progress: [0, 0, 0]
    };
  },
  computed: {
    computeComputedStepExternalStatuses: function computeComputedStepExternalStatuses() {
      for (var i = 0; i < this.journeyMap.linkedJourneySteps.length; i++) {
        if (this.step.id === this.journeyMap.linkedJourneySteps[i].journeyStep.id) {
          return this.journeyMap.linkedJourneySteps[i].journeyStep.external_status;
        }
      }
      return [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    },
    computeComputedStepProdiggiStatuses: function computeComputedStepProdiggiStatuses() {
      for (var i = 0; i < this.journeyMap.linkedJourneySteps.length; i++) {
        if (this.step.id === this.journeyMap.linkedJourneySteps[i].journeyStep.id) {
          return this.journeyMap.linkedJourneySteps[i].journeyStep.prodiggi_status;
        }
      }
      return [0, 0, 0, 0, 0, 0, 0, 0];
    },
    experiencesList: function experiencesList() {
      var experience_ids = [];
      var experiences = [];
      for (var i = 0; i < this.journeyMap.additionalattributes.relations.length; i++) {
        if (this.journeyMap.additionalattributes.relations[i].start_id === this.step.id) {
          experience_ids.push(this.journeyMap.additionalattributes.relations[i].end_id);
        }
      }
      for (var j = 0; j < this.journeyMap.linkedJourneyExperiences.length; j++) {
        if (experience_ids.includes(this.journeyMap.linkedJourneyExperiences[j].journeyExperience.id)) {
          experiences.push(this.journeyMap.linkedJourneyExperiences[j]);
        }
      }
      return this._.chunk(experiences, this.experienceChunkSize);
    },
    computeHeight: function computeHeight() {
      if (this.step && this.step.linkedJourneySubsteps && this.experiencesList[this.activeExperienceChunk]) {
        var sub_step_height = this.step.linkedJourneySubsteps.length * 35;
        var experience_height = 0;
        if (!this.readonly) {
          experience_height = this.experiencesList[this.activeExperienceChunk].length * 35;
        }
        return sub_step_height + experience_height + 220;
      } else {
        return 220;
      }
    }
  },
  created: function created() {},
  mounted: function mounted() {
    this.computeProgress();
  },
  methods: {
    hasFeaturesOrProcesses: function hasFeaturesOrProcesses() {
      for (var i = 0; i < this.offering.additionalattributes.relations.length; i++) {
        if (this.offering.additionalattributes.relations[i].start_id === this.step.id) {
          return true;
        }
      }
    },
    computeProgress: function computeProgress() {
      var first, second, third;
      var sum = 100;
      while (sum > 90) {
        first = Math.floor(Math.random() * 70 + 10);
        second = Math.floor(Math.random() * 70 + 10);
        sum = first + second;
      }
      third = 100 - sum;
      this.progress = [first, second, third];
    },
    generateMarker: function generateMarker() {
      var x_start = this.x - 32;
      var y_start = this.y + 113;
      var x_mid = this.x - 22;
      var y_mid = this.y + 108;
      var x_last = this.x - 22;
      var y_last = this.y + 118;
      return 'M ' + x_start + ' ' + y_start + ' L ' + x_mid + ' ' + y_mid + ' L ' + x_last + ' ' + y_last + '';
    },
    computeCustomerValueIcon: function computeCustomerValueIcon(imp) {
      switch (imp) {
        case 'Very Low':
          return 'low-value';
        case 'Low':
          return 'low-value';
        case 'Medium':
          return 'dollar-1';
        case 'High':
          return 'dollar-2';
        case 'Very High':
          return 'dollar-3';
      }
    },
    computeValueFontSize: function computeValueFontSize(value1) {
      if (value1 === 'Very Low') {
        return 'lowestValue';
      } else if (value1 === 'Low') {
        return 'lowValue';
      }
    },
    closeTaskDetailsPopover: function closeTaskDetailsPopover() {
      this.ishover = false;
      this.$emit('close');
    },
    displayTaskDetails: function displayTaskDetails() {
      this.$emit('details', this.index);
    },
    initiateCreateStepIdea: function initiateCreateStepIdea() {
      this.$emit('initiate-create-step-idea', this.step.title);
    },
    createExperienceFromStep: function createExperienceFromStep(experience_type) {
      this.$emit('initiate-create-experience', experience_type);
    },
    deleteStep: function deleteStep() {
      var _this = this;
      this.$confirm('This will permanently delete step. Continue?', 'Warning', {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(function () {
        _this.$emit('delete-step', _this.step.id);
      }).catch(function (e) {
        console.log(e);
        _this.$message({
          type: 'info',
          message: 'Delete canceled'
        });
      });
    },
    emitOnMouseOver: function emitOnMouseOver() {
      this.$emit('mouseover', this.index);
    },
    emitOnMouseLeave: function emitOnMouseLeave() {
      this.$emit('mouseleave', this.index);
    },
    truncateString: function truncateString(string, length) {
      return this._.truncate(string, {
        'length': length,
        'separator': '...'
      });
    }
  }
};