"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "mt-10"
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 4
    }
  }, [_vm.activeUsers.length > 0 ? _c("el-card", {
    staticClass: "card-highlighted"
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("span", [_vm._v("Active Users")])]), _vm._v(" "), _vm._l(_vm.activeUsers, function (user, index) {
    return _c("div", {
      key: index,
      staticClass: "card-index no-padding"
    }, [_c("div", {
      staticClass: "card-info",
      staticStyle: {
        width: "100%"
      }
    }, [_c("div", {
      staticClass: "card-head"
    }, [_c("h3", [_vm._v(_vm._s(user.title))]), _vm._v(" "), _c("div", {
      staticClass: "card-status"
    }, [_c("el-tag", {
      attrs: {
        type: "danger",
        size: "mini"
      },
      on: {
        click: function click($event) {
          return _vm.removeActiveUser(user, index);
        }
      }
    }, [_vm._v("X")])], 1)])])]);
  })], 2) : _vm._e(), _vm._v(" "), _vm.activeFeatures.length > 0 ? _c("el-card", {
    staticClass: "mt-10 card-highlighted"
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("span", [_vm._v("Active Features")])]), _vm._v(" "), _vm._l(_vm.activeFeatures, function (feature, index) {
    return _c("div", {
      key: index,
      staticClass: "card-index no-padding"
    }, [_c("div", {
      staticClass: "card-info",
      staticStyle: {
        width: "100%"
      }
    }, [_c("div", {
      staticClass: "card-head"
    }, [_c("h3", [_vm._v(_vm._s(feature.feature))]), _vm._v(" "), _c("div", {
      staticClass: "card-status fr"
    }, [_c("el-tag", {
      attrs: {
        type: "danger",
        size: "mini"
      },
      on: {
        click: function click($event) {
          return _vm.removeActiveFeature(feature, index);
        }
      }
    }, [_vm._v("X")])], 1)])])]);
  })], 2) : _vm._e(), _vm._v(" "), _vm.users.length > 0 ? _c("el-card", {
    staticClass: "mt-10"
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("span", [_vm._v("Users")]), _vm._v(" "), _c("el-button", {
    staticStyle: {
      float: "right",
      padding: "3px 0"
    },
    attrs: {
      type: "text"
    },
    on: {
      click: _vm.addAllUsers
    }
  }, [_vm._v("Add all Users")])], 1), _vm._v(" "), _vm._l(_vm.users, function (user, index) {
    return _c("div", {
      key: index,
      staticClass: "card-index no-padding",
      attrs: {
        draggable: ""
      },
      on: {
        click: function click($event) {
          _vm.activeUser = user;
        },
        drag: function drag($event) {
          return _vm.setActiveDragElement("user", user, index);
        }
      }
    }, [_c("div", {
      staticClass: "card-info",
      staticStyle: {
        width: "100%"
      }
    }, [_c("div", {
      staticClass: "card-head"
    }, [_c("el-row", [_c("el-col", {
      staticStyle: {
        margin: "auto",
        "padding-top": "7pt"
      },
      attrs: {
        span: 10
      }
    }, [_c("el-avatar", {
      attrs: {
        size: "small",
        src: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"
      }
    })], 1), _vm._v(" "), _c("el-col", {
      staticClass: "pl-10",
      attrs: {
        span: 14
      }
    }, [_c("h3", [_vm._v(_vm._s(user.title))])])], 1)], 1)])]);
  })], 2) : _vm._e(), _vm._v(" "), _vm.activeUser && _vm.activeUser.candidate_features.length > 0 ? _c("el-card", {
    staticClass: "mt-10"
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("span", [_vm._v("Features")]), _vm._v(" "), _c("el-button", {
    staticStyle: {
      float: "right",
      padding: "3px 0"
    },
    attrs: {
      type: "text"
    },
    on: {
      click: _vm.addAllFeatures
    }
  }, [_vm._v("Add all Features")])], 1), _vm._v(" "), _vm._l(_vm.activeUser.candidate_features, function (feature, index) {
    return _c("div", {
      key: index,
      staticClass: "card-index no-padding",
      attrs: {
        draggable: ""
      },
      on: {
        click: function click($event) {
          _vm.activeUser = _vm.user;
        },
        drag: function drag($event) {
          return _vm.setActiveDragElement("feature", feature, index);
        }
      }
    }, [_c("div", {
      staticClass: "card-info",
      staticStyle: {
        width: "100%"
      }
    }, [_c("div", {
      staticClass: "card-head"
    }, [_c("h3", [_vm._v(_vm._s(feature.feature))])]), _vm._v(" "), _c("div", {
      staticClass: "card-para",
      on: {
        click: function click($event) {
          _vm.addDescriptionDialog = true;
        }
      }
    }, [_c("el-button", {
      attrs: {
        icon: "el-icon-plus",
        size: "mini"
      }
    }, [_vm._v("Short description")])], 1)])]);
  })], 2) : _vm._e()], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 16
    }
  }, [_c("el-card", {
    staticClass: "text-center",
    attrs: {
      id: "myDiagramDiv",
      header: "Interaction Canvas"
    }
  }, [_c("div", {
    on: {
      drop: _vm.handleDrop,
      dragover: _vm.handleDragOver
    }
  }, [_c("svg", {
    attrs: {
      height: "600px",
      width: "100%",
      xmlns: "http://www.w3.org/2000/svg",
      version: "1.1"
    }
  }, [_c("defs", [_c("marker", {
    attrs: {
      id: "arrow",
      viewBox: "0 0 10 10",
      refX: "5",
      refY: "5",
      markerWidth: "12",
      markerHeight: "12",
      orient: "auto-start-reverse"
    }
  }, [_c("circle", {
    attrs: {
      cx: "5",
      cy: "5",
      r: "2",
      fill: "gray"
    }
  })])]), _vm._v(" "), _vm._l(_vm.userInteractions, function (userInteraction, index) {
    return _c("user-component", {
      directives: [{
        name: "dragged",
        rawName: "v-dragged",
        value: _vm.startUserMove,
        expression: "startUserMove"
      }],
      key: _vm.generateUserInterationID(index),
      attrs: {
        id: _vm.generateUserInterationID(index),
        x: userInteraction.x,
        y: userInteraction.y,
        user: userInteraction.title
      }
    });
  }), _vm._v(" "), _vm._l(_vm.featureInteractions, function (featureInteraction, index) {
    return _c("feature-component", {
      directives: [{
        name: "dragged",
        rawName: "v-dragged",
        value: _vm.startFeatureMove,
        expression: "startFeatureMove"
      }],
      key: _vm.generateFeatureInterationID(index),
      attrs: {
        id: _vm.generateFeatureInterationID(index),
        cx: featureInteraction.cx,
        cy: featureInteraction.cy,
        feature: featureInteraction.feature
      }
    });
  }), _vm._v(" "), _vm._l(_vm.userFeatureLinks, function (userFeatureLink, index) {
    return _c("path", {
      key: index,
      attrs: {
        d: _vm.computePathPoints(userFeatureLink),
        stroke: "gray",
        "stroke-width": "2",
        fill: "none",
        "marker-start": "url(#arrow)",
        "marker-end": "url(#arrow)"
      }
    });
  })], 2)])])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 4
    }
  }, [_c("el-card", {
    staticClass: "text-center",
    attrs: {
      header: "Design Layer"
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    }
  }, [_vm._v("Feature")]), _c("br"), _vm._v(" "), _c("el-button", {
    staticClass: "mt-10",
    attrs: {
      type: "primary",
      size: "mini"
    }
  }, [_vm._v("Users")])], 1), _vm._v(" "), _c("el-card", {
    staticClass: "mt-10 text-center"
  }, [_c("el-button", {
    attrs: {
      type: "info",
      size: "mini"
    }
  }, [_vm._v("Reconcile design")]), _vm._v(" "), _c("br"), _vm._v(" "), _c("el-button", {
    staticClass: "mt-10",
    attrs: {
      type: "info",
      size: "mini"
    }
  }, [_vm._v("Generate App Nav")])], 1), _vm._v(" "), _c("el-card", {
    staticClass: "mt-10 text-center"
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("span", [_vm._v("Design ToolBox")])]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    }
  }, [_vm._v("Add User")]), _c("br"), _vm._v(" "), _c("el-button", {
    staticClass: "mt-10",
    attrs: {
      type: "primary",
      size: "mini"
    }
  }, [_vm._v("Add Feature")]), _c("br")], 1), _vm._v(" "), _c("el-card", {
    staticClass: "mt-10 text-center"
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("span", [_vm._v("User Info")])]), _vm._v(" "), _c("el-input", {
    attrs: {
      size: "mini",
      value: "Design Goals",
      disabled: ""
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-card", {
    staticClass: "mt-10"
  }, [_c("div", {
    staticClass: "text-right"
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.goToRefineStories
    }
  }, [_vm._v("Refine Stories")])], 1)]), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Add Description",
      visible: _vm.addDescriptionDialog,
      width: "30%",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addDescriptionDialog = $event;
      }
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      rows: 2,
      placeholder: "Enter Feature description"
    },
    model: {
      value: _vm.featureDescription,
      callback: function callback($$v) {
        _vm.featureDescription = $$v;
      },
      expression: "featureDescription"
    }
  }), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      slot: "button"
    },
    on: {
      click: function click($event) {
        _vm.addDescriptionDialog = false;
      }
    },
    slot: "button"
  }, [_vm._v("Cancel")]), _vm._v(" "), _c("el-button", {
    attrs: {
      slot: "button",
      type: "primary"
    },
    on: {
      click: function click($event) {
        _vm.addDescriptionDialog = false;
      }
    },
    slot: "button"
  }, [_vm._v("Add description")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Add Data Types",
      visible: _vm.addDataTypeDialog,
      width: "30%",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addDataTypeDialog = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.dataTypeForm,
      "label-width": "120px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Title"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "Data Type"
    },
    model: {
      value: _vm.dataTypeForm.title,
      callback: function callback($$v) {
        _vm.$set(_vm.dataTypeForm, "title", $$v);
      },
      expression: "dataTypeForm.title"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Description"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      rows: 2,
      placeholder: "Enter Data Type description"
    },
    model: {
      value: _vm.dataTypeForm.description,
      callback: function callback($$v) {
        _vm.$set(_vm.dataTypeForm, "description", $$v);
      },
      expression: "dataTypeForm.description"
    }
  })], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      slot: "button",
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.addDataTypeDialog = false;
      }
    },
    slot: "button"
  }, [_vm._v("Cancel")]), _vm._v(" "), _vm.dataTypeDialogType == "create" ? _c("el-button", {
    attrs: {
      slot: "button",
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.addNewDataType
    },
    slot: "button"
  }, [_vm._v("Add data type")]) : _c("el-button", {
    attrs: {
      slot: "button",
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.updateDataType
    },
    slot: "button"
  }, [_vm._v("Update data type")])], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;