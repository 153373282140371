"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/taggedTemplateLiteral.js"));
var _createForOfIteratorHelper2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/createForOfIteratorHelper.js"));
var _templateObject, _templateObject2;
var _default2 = exports.default = {
  name: 'JourneyMapCreate',
  props: {
    customerTypes: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    customerId: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    var validateTitle = function validateTitle(rule, value, callback) {
      if (value === '') {
        callback(new Error('Please enter a valid journeymap name'));
      } else {
        callback();
      }
    };
    var validateDescription = function validateDescription(rule, value, callback) {
      if (value === '') {
        callback(new Error('Please enter a valid description'));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      needs: {},
      JourneyMapRules: {
        title: [{
          required: true,
          trigger: 'none',
          validator: validateTitle
        }],
        description: [{
          required: true,
          trigger: 'none',
          validator: validateDescription
        }],
        type: [{
          required: true,
          trigger: 'none'
        }]
      },
      journey_map_form: {
        title: '',
        description: '',
        type: 'current'
      },
      journey_maps: [],
      selectedCustomerId: '',
      selectedJourneyMapId: '',
      selectedCloneOptions: ['JOURNEY_EXPERIENCE', 'JOURNEY_PHASE', 'JOURNEY_STEP'],
      createOption: 'generate'
    };
  },
  computed: {
    // remove current active customer type and customer without journey map
    filteredCustomers: function filteredCustomers() {
      var filtered = [];
      var _iterator = (0, _createForOfIteratorHelper2.default)(this.customerTypes),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var customerType = _step.value;
          if (customerType.id !== this.customerId && customerType.linkedJourneyMaps.length > 0) {
            filtered.push(customerType);
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      return filtered;
    }
  },
  created: function created() {},
  mounted: function mounted() {
    this.resetForm();
    this.retrieveNeeds(1);
  },
  methods: {
    retrieveJourneyMapListForCustomer: function retrieveJourneyMapListForCustomer(customer_id) {
      var _this = this;
      this.loading = true;
      this.$apollo.query({
        // Query
        query: window._gql(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["query CustomerType($id: String)\n        {\n          CustomerType(id: $id)\n          {\n            id\n            title\n            linkedJourneyMaps(optional: true) {\n              journeyMap(optional: true) {\n                id\n                title\n                description\n                additionalattributes\n              }\n            }\n          }\n        }"]))),
        fetchPolicy: 'no-cache',
        variables: {
          'id': customer_id
        }
      }).then(function (data) {
        _this.selectedCustomer = data.data.CustomerType;
        if (data.data.CustomerType.linkedJourneyMaps.length > 0) {
          _this.journey_maps = data.data.CustomerType.linkedJourneyMaps;
        }
        _this.loading = false;
      }).catch(function (error) {
        // Error
        console.log(error);
        _this.loading = false;
      });
    },
    redirectToJTBD: function redirectToJTBD() {
      this.$router.push('/customer-types/jobs-to-be-done');
    },
    generateJourney: function generateJourney() {
      var _this2 = this;
      this.$refs.journey_map_form.validate(function (valid) {
        if (valid) {
          var journey_map_object = Object.assign({}, _this2.journey_map_form);
          _this2.$emit('generate-journey-map', _this2.needs, journey_map_object);
          _this2.resetForm();
        } else {
          return false;
        }
      });
    },
    displayGenerateJourneyUI: function displayGenerateJourneyUI() {
      this.createOption = 'generate';
      this.resetForm();
      this.retrieveNeeds(1);
    },
    createManual: function createManual() {
      this.createOption = 'manual';
      this.resetForm();
    },
    copyJourney: function copyJourney() {
      this.resetCopyUI();
      this.createOption = 'copy';
    },
    resetCopyUI: function resetCopyUI() {
      this.journey_maps = [];
      this.selectedCustomerId = '';
      this.selectedJourneyMapId = '';
    },
    showCustomerTypeList: function showCustomerTypeList() {},
    retrieveNeeds: function retrieveNeeds(page_number) {
      var _this3 = this;
      this.loading = true;
      this.$apollo.query({
        // Query
        query: window._gql(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["query NeedDefinitions($page: Page, $needdefinitionscriteriaExpression: NeedDefinitionsCriteriaExpression!, $customertypescriteriaexpression: CustomerTypesCriteriaExpression!)\n        {\n        NeedDefinitions(page: $page, where: $needdefinitionscriteriaExpression)\n        {\n          pages\n          total\n          select{\n            id\n            title\n            description\n            additionalattributes\n            goal\n            needtype\n            linkedCustomerType(optional: true) {\n              customerType(optional: true, where: $customertypescriteriaexpression) {\n                id\n                title\n              }\n            }\n            linkedStages(optional: true) {\n              stage(optional: true) {\n                id\n                title\n                description\n                linkedSteps(optional: true) {\n                  step(optional: true) {\n                    id\n                    title\n                    description\n                    linkedNeeds(optional: true) {\n                      need(optional: true) {\n                        id\n                        title\n                        description\n                        action\n                        met\n                        value\n                      }\n                    }\n                  }\n                }\n              }\n            }\n          }\n        }\n      }"]))),
        fetchPolicy: 'no-cache',
        variables: {
          'page': {
            'limit': 20,
            'start': page_number
          },
          'needdefinitionscriteriaExpression': {
            'needtype': {
              'EQ': 'functional'
            }
          },
          'customertypescriteriaexpression': {
            'id': {
              'EQ': this.$store.getters.customerType.id
            }
          }
        }
      }).then(function (data) {
        var needs_data = data.data.NeedDefinitions.select;
        _this3.needs = needs_data[needs_data.length - 1];
        if (_this3.needs && _this3.needs.linkedStages && _this3.needs.linkedStages.length === 0) {
          _this3.createOption = 'manual';
        }
        if (_this3.createOption === 'generate') {
          _this3.journey_map_form.title = _this3.$store.getters.customerType.title;
          _this3.journey_map_form.description = '';
          _this3.journey_map_form.type = 'current';
        }
        _this3.loading = false;
      }).catch(function (error) {
        // Error
        _this3.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this3.loading = false;
      });
    },
    createJourneyMap: function createJourneyMap() {
      var _this4 = this;
      this.$refs.journey_map_form.validate(function (valid) {
        if (valid) {
          var journey_map_object = Object.assign({}, _this4.journey_map_form);
          _this4.$emit('create-journey-map', journey_map_object);
          _this4.resetForm();
        } else {
          return false;
        }
      });
    },
    copyJourneyMap: function copyJourneyMap() {
      this.$emit('copy-journey-map', this.selectedJourneyMapId, this.selectedCloneOptions);
    },
    resetForm: function resetForm() {
      this.journey_map_form = {
        title: '',
        description: '',
        type: 'current'
      };
    }
  }
};