"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "mt-10"
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: _vm.collapseSidebar ? 0 : 4
    }
  }, [_c("el-card", [_c("div", {
    staticClass: "text-center",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_vm._v("\n          Tool Box\n        ")]), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("div", {
    staticClass: "text-center",
    attrs: {
      draggable: ""
    }
  }, [_c("el-button", {
    attrs: {
      icon: "el-icon-cpu",
      size: "mini",
      type: "primary",
      title: "Add Component"
    },
    on: {
      click: function click($event) {
        _vm.addComponentDialog = true;
      }
    }
  })], 1)]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("div", {
    staticClass: "text-center",
    attrs: {
      draggable: ""
    }
  }, [_c("el-button", {
    attrs: {
      icon: "el-icon-house",
      size: "mini",
      type: "primary",
      disabled: ""
    }
  })], 1)])], 1)], 1), _vm._v(" "), _c("el-card", {
    staticClass: "mt-10"
  }, [_c("div", {
    staticClass: "text-center",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_vm._v("\n          Candidate Services\n        ")]), _vm._v(" "), _c("el-popover", {
    attrs: {
      placement: "right",
      title: "Progress",
      width: "500",
      "open-delay": 400,
      "close-delay": 400,
      trigger: "hover"
    }
  }, [_c("el-row", [_c("el-col", {
    staticClass: "text-center",
    attrs: {
      span: 8
    }
  }, [_c("el-progress", {
    attrs: {
      type: "dashboard",
      percentage: 10
    }
  }), _vm._v(" "), _c("div", [_vm._v("Completed")])], 1), _vm._v(" "), _c("el-col", {
    staticClass: "text-center",
    attrs: {
      span: 8
    }
  }, [_c("el-progress", {
    attrs: {
      type: "dashboard",
      percentage: 25
    }
  }), _vm._v(" "), _c("div", [_vm._v("In - Progress")])], 1), _vm._v(" "), _c("el-col", {
    staticClass: "text-center",
    attrs: {
      span: 8
    }
  }, [_c("el-progress", {
    attrs: {
      type: "dashboard",
      percentage: 33
    }
  }), _vm._v(" "), _c("div", [_vm._v("Pending")])], 1)], 1), _vm._v(" "), _c("el-progress", {
    attrs: {
      slot: "reference",
      percentage: _vm.computeCompletePercentage
    },
    slot: "reference"
  })], 1), _vm._v(" "), _c("el-divider"), _vm._v(" "), _c("el-tree", {
    attrs: {
      data: _vm.candidate_services.included,
      props: _vm.defaultProps,
      "default-expand-all": true
    }
  }), _vm._v(" "), _c("el-tree", {
    attrs: {
      data: _vm.candidate_services.excluded,
      props: _vm.defaultProps,
      "default-expand-all": true,
      draggable: "",
      "allow-drop": _vm.allowDrop
    },
    on: {
      "node-drag-start": _vm.handleDragStart
    }
  }), _vm._v(" "), _c("el-tree", {
    attrs: {
      data: _vm.candidate_services.not_found,
      props: _vm.defaultProps,
      "default-expand-all": true
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: _vm.collapseSidebar ? 24 : 16
    }
  }, [_c("el-card", [_c("div", {
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("el-row", [_c("el-col", {
    attrs: {
      span: 20
    }
  }, [_c("div", {
    staticClass: "text-center"
  }, [_vm._v("\n                Generate Component Diagram\n              ")])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 4
    }
  }, [_c("span", {
    staticClass: "fr",
    on: {
      click: function click($event) {
        _vm.collapseSidebar = !_vm.collapseSidebar;
      }
    }
  }, [_vm.collapseSidebar ? [_c("i", {
    staticClass: "el-icon-arrow-right"
  }), _c("i", {
    staticClass: "el-icon-arrow-left"
  })] : [_c("i", {
    staticClass: "el-icon-arrow-left"
  }), _c("i", {
    staticClass: "el-icon-arrow-right"
  })]], 2)])], 1)], 1), _vm._v(" "), _c("svg-pan-zoom", {
    staticStyle: {
      width: "100%",
      height: "600pt",
      top: "50pt"
    },
    attrs: {
      "zoom-enabled": !_vm.checkIsTouchDevice(),
      "pan-enabled": !_vm.checkIsTouchDevice(),
      "control-icons-enabled": !_vm.checkIsTouchDevice(),
      fit: true,
      center: true
    }
  }, [_c("svg", {
    attrs: {
      height: "600px",
      width: "100%",
      xmlns: "http://www.w3.org/2000/svg",
      version: "1.1"
    }
  }, [_c("defs", [_c("marker", {
    attrs: {
      id: "dot",
      viewBox: "0 0 10 10",
      refX: "5",
      refY: "5",
      markerWidth: "12",
      markerHeight: "12",
      orient: "auto-start-reverse"
    }
  }, [_c("circle", {
    attrs: {
      cx: "5",
      cy: "5",
      r: "1",
      fill: "gray"
    }
  })]), _vm._v(" "), _c("marker", {
    attrs: {
      id: "triangle",
      viewBox: "0 0 10 10",
      refX: "5",
      refY: "5",
      markerUnits: "strokeWidth",
      markerWidth: "5",
      markerHeight: "5",
      orient: "auto"
    }
  }, [_c("path", {
    attrs: {
      d: "M 0 0 L 10 5 L 0 10 ",
      fill: "gray"
    }
  })])]), _vm._v(" "), _c("g", {
    ref: "RelationPaths"
  }, _vm._l(_vm.relationPaths, function (relationPath, index) {
    return _c("path", {
      key: "relation_path_" + index,
      attrs: {
        id: "relation_path_" + index,
        d: relationPath.path,
        stroke: _vm.computePathStroke(index),
        "stroke-width": "2",
        fill: "none",
        "marker-start": "url(#dot)",
        "marker-end": "url(#triangle)",
        "stroke-dasharray": relationPath.stroke
      },
      on: {
        click: function click($event) {
          return _vm.toggleSelectPath(index);
        }
      }
    });
  }), 0), _vm._v(" "), _c("g", {
    ref: "SelectedRelationPath"
  }, _vm._l(2, function (item, index) {
    return _c("circle", {
      directives: [{
        name: "dragged",
        rawName: "v-dragged",
        value: _vm.startPathMove,
        expression: "startPathMove"
      }],
      key: index,
      attrs: {
        id: _vm.generatePathID(index),
        cx: _vm.computeselectedPathX(index),
        cy: _vm.computeselectedPathY(index),
        stroke: "#304174",
        fill: "#304174",
        r: _vm.computeselectedPathRadius(index)
      }
    });
  }), 0), _vm._v(" "), _c("g", {
    attrs: {
      refs: "components"
    }
  }, _vm._l(_vm.components, function (component, index) {
    return _c("g", {
      key: index,
      attrs: {
        refs: "component"
      }
    }, [_vm.isVisible(component.model) ? _c("u-m-l-component-diagram", {
      directives: [{
        name: "dragged",
        rawName: "v-dragged",
        value: _vm.startComponentMove,
        expression: "startComponentMove"
      }],
      attrs: {
        id: _vm.generateComponentID(index),
        component: component
      },
      on: {
        drop: _vm.handleDrop,
        dragover: _vm.handleDragOver,
        clicked: _vm.optionClicked,
        "delete-service": _vm.deleteService,
        "delete-component": function deleteComponent($event) {
          return _vm.deleteComponent(index);
        }
      }
    }) : _vm._e()], 1);
  }), 0), _vm._v(" "), _c("g", {
    attrs: {
      refs: "componentTools"
    }
  }, [_vm.activeComponentTool !== null ? _c("u-m-l-component-option-diagram", {
    attrs: {
      id: _vm.activeComponentID,
      component: _vm.activeComponent,
      option: _vm.activeComponentOption
    },
    on: {
      "add-category-detail": _vm.openAddCategoryDialog
    }
  }) : _vm._e()], 1)])])], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: _vm.collapseSidebar ? 0 : 4
    }
  }, [_c("el-card", [_c("div", {
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_vm._v("\n          View Model Layers\n        ")]), _vm._v(" "), _c("div", {
    staticClass: "text-center"
  }, [_c("div", {
    staticClass: "mt-5"
  }, [_c("el-checkbox", {
    staticStyle: {
      width: "120px"
    },
    attrs: {
      label: "User Interface",
      size: "mini",
      border: ""
    },
    model: {
      value: _vm.modelLayers.user_interface,
      callback: function callback($$v) {
        _vm.$set(_vm.modelLayers, "user_interface", $$v);
      },
      expression: "modelLayers.user_interface"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "mt-5"
  }, [_c("el-checkbox", {
    staticStyle: {
      width: "120px"
    },
    attrs: {
      label: "Integration",
      size: "mini",
      border: ""
    },
    model: {
      value: _vm.modelLayers.integration,
      callback: function callback($$v) {
        _vm.$set(_vm.modelLayers, "integration", $$v);
      },
      expression: "modelLayers.integration"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "mt-5"
  }, [_c("el-checkbox", {
    staticStyle: {
      width: "120px"
    },
    attrs: {
      label: "Business Logic",
      size: "mini",
      border: ""
    },
    model: {
      value: _vm.modelLayers.business_logic,
      callback: function callback($$v) {
        _vm.$set(_vm.modelLayers, "business_logic", $$v);
      },
      expression: "modelLayers.business_logic"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "mt-5"
  }, [_c("el-checkbox", {
    staticStyle: {
      width: "120px"
    },
    attrs: {
      label: "Tasks",
      size: "mini",
      border: ""
    },
    model: {
      value: _vm.modelLayers.tasks,
      callback: function callback($$v) {
        _vm.$set(_vm.modelLayers, "tasks", $$v);
      },
      expression: "modelLayers.tasks"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "mt-5"
  }, [_c("el-checkbox", {
    staticStyle: {
      width: "120px"
    },
    attrs: {
      label: "Quality",
      size: "mini",
      border: ""
    },
    model: {
      value: _vm.modelLayers.quality,
      callback: function callback($$v) {
        _vm.$set(_vm.modelLayers, "quality", $$v);
      },
      expression: "modelLayers.quality"
    }
  })], 1)])]), _vm._v(" "), _c("el-card", {
    staticClass: "mt-10"
  }, [_c("div", {
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_vm._v("\n          Versions\n        ")]), _vm._v(" "), _c("div", {
    staticClass: "text-center"
  }, [_c("div", {
    staticClass: "mt-5"
  }, [_c("el-radio", {
    staticStyle: {
      width: "120px"
    },
    attrs: {
      label: "2.0",
      size: "mini",
      border: ""
    },
    on: {
      change: _vm.loadComponentDiagramVersion
    },
    model: {
      value: _vm.version,
      callback: function callback($$v) {
        _vm.version = $$v;
      },
      expression: "version"
    }
  }, [_vm._v("Version 2.0")])], 1), _vm._v(" "), _c("div", {
    staticClass: "mt-5"
  }, [_c("el-radio", {
    staticStyle: {
      width: "120px"
    },
    attrs: {
      label: "1.5",
      size: "mini",
      border: ""
    },
    on: {
      change: _vm.loadComponentDiagramVersion
    },
    model: {
      value: _vm.version,
      callback: function callback($$v) {
        _vm.version = $$v;
      },
      expression: "version"
    }
  }, [_vm._v("Version 1.5")])], 1), _vm._v(" "), _c("div", {
    staticClass: "mt-5"
  }, [_c("el-radio", {
    staticStyle: {
      width: "120px"
    },
    attrs: {
      label: "1.4",
      size: "mini",
      border: ""
    },
    on: {
      change: _vm.loadComponentDiagramVersion
    },
    model: {
      value: _vm.version,
      callback: function callback($$v) {
        _vm.version = $$v;
      },
      expression: "version"
    }
  }, [_vm._v("Version 1.4")])], 1), _vm._v(" "), _c("div", {
    staticClass: "mt-5"
  }, [_c("el-radio", {
    staticStyle: {
      width: "120px"
    },
    attrs: {
      label: "1.3",
      size: "mini",
      border: ""
    },
    on: {
      change: _vm.loadComponentDiagramVersion
    },
    model: {
      value: _vm.version,
      callback: function callback($$v) {
        _vm.version = $$v;
      },
      expression: "version"
    }
  }, [_vm._v("Version 1.3")])], 1), _vm._v(" "), _c("div", {
    staticClass: "mt-5"
  }, [_c("el-radio", {
    staticStyle: {
      width: "120px"
    },
    attrs: {
      label: "1.2",
      size: "mini",
      border: ""
    },
    on: {
      change: _vm.loadComponentDiagramVersion
    },
    model: {
      value: _vm.version,
      callback: function callback($$v) {
        _vm.version = $$v;
      },
      expression: "version"
    }
  }, [_vm._v("Version 1.2")])], 1), _vm._v(" "), _c("div", {
    staticClass: "mt-5"
  }, [_c("el-radio", {
    staticStyle: {
      width: "120px"
    },
    attrs: {
      label: "1.1",
      size: "mini",
      border: ""
    },
    on: {
      change: _vm.loadComponentDiagramVersion
    },
    model: {
      value: _vm.version,
      callback: function callback($$v) {
        _vm.version = $$v;
      },
      expression: "version"
    }
  }, [_vm._v("Version 1.1")])], 1), _vm._v(" "), _c("div", {
    staticClass: "mt-5"
  }, [_c("el-radio", {
    staticStyle: {
      width: "120px"
    },
    attrs: {
      label: "mvp",
      size: "mini",
      border: ""
    },
    on: {
      change: _vm.loadComponentDiagramVersion
    },
    model: {
      value: _vm.version,
      callback: function callback($$v) {
        _vm.version = $$v;
      },
      expression: "version"
    }
  }, [_vm._v("Version MVP")])], 1), _vm._v(" "), _c("div", {
    staticClass: "mt-5"
  }, [_c("el-radio", {
    staticStyle: {
      width: "120px"
    },
    attrs: {
      label: "beta",
      size: "mini",
      border: ""
    },
    on: {
      change: _vm.loadComponentDiagramVersion
    },
    model: {
      value: _vm.version,
      callback: function callback($$v) {
        _vm.version = $$v;
      },
      expression: "version"
    }
  }, [_vm._v("Version Beta")])], 1), _vm._v(" "), _c("div", {
    staticClass: "mt-5"
  }, [_c("el-radio", {
    staticStyle: {
      width: "120px"
    },
    attrs: {
      label: "alpha",
      size: "mini",
      border: ""
    },
    on: {
      change: _vm.loadComponentDiagramVersion
    },
    model: {
      value: _vm.version,
      callback: function callback($$v) {
        _vm.version = $$v;
      },
      expression: "version"
    }
  }, [_vm._v("Version Alpha")])], 1)])]), _vm._v(" "), _c("el-card", {
    staticClass: "mt-10"
  }, [_c("div", {
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_vm._v("\n          Legend\n        ")]), _vm._v(" "), _c("el-row", [_c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticStyle: {
      height: "40px",
      width: "40px",
      "background-color": "rgba(212,167,241,0.45)",
      border: "1px solid rgba(212,167,241,0.86)"
    }
  })]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 18
    }
  }, [_c("div", [_vm._v("In Scope for Beta")])])], 1), _vm._v(" "), _c("el-row", {
    staticClass: "mt-10"
  }, [_c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticStyle: {
      height: "40px",
      width: "40px",
      border: "1px solid rgba(200,200,200,0.86)"
    }
  })]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 18
    }
  }, [_c("div", [_vm._v("Out of scope for beta")])])], 1)], 1)], 1)], 1), _vm._v(" "), _c("el-card", {
    staticClass: "mt-10"
  }, [_c("el-divider"), _vm._v(" "), _c("div", {
    staticClass: "text-right"
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.goToOrganiseLayers
    }
  }, [_vm._v("Organise Layers")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Add component",
      visible: _vm.addComponentDialog,
      width: "70%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addComponentDialog = $event;
      }
    }
  }, [_c("el-form", {
    ref: "component_form",
    attrs: {
      model: _vm.component_form,
      "label-width": "150px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Title"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.component_form.title,
      callback: function callback($$v) {
        _vm.$set(_vm.component_form, "title", $$v);
      },
      expression: "component_form.title"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Model"
    }
  }, [_c("el-radio-group", {
    model: {
      value: _vm.component_form.model,
      callback: function callback($$v) {
        _vm.$set(_vm.component_form, "model", $$v);
      },
      expression: "component_form.model"
    }
  }, [_c("el-radio", {
    attrs: {
      label: "user_interface"
    }
  }, [_vm._v("User Interface")]), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "integration"
    }
  }, [_vm._v("Integration")]), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "business_logic"
    }
  }, [_vm._v("Business Logic")]), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "tasks"
    }
  }, [_vm._v("Tasks")]), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "quality"
    }
  }, [_vm._v("Quality")])], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Scope"
    }
  }, [_c("el-radio-group", {
    model: {
      value: _vm.component_form.scope,
      callback: function callback($$v) {
        _vm.$set(_vm.component_form, "scope", $$v);
      },
      expression: "component_form.scope"
    }
  }, [_c("el-radio", {
    attrs: {
      label: "beta"
    }
  }, [_vm._v("Beta")]), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "out_of_scope"
    }
  }, [_vm._v("Out of scope")])], 1)], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.addComponentDialog = false;
      }
    }
  }, [_vm._v("Cancel")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.addComponent
    }
  }, [_vm._v("Add Component")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Add new item",
      visible: _vm.addCategoryDialog,
      width: "70%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addCategoryDialog = $event;
      }
    }
  }, [_c("el-form", {
    ref: "category_form",
    attrs: {
      model: _vm.category_form,
      "label-width": "150px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Title"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.category_form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.category_form, "name", $$v);
      },
      expression: "category_form.name"
    }
  })], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.addCategoryDialog = false;
      }
    }
  }, [_vm._v("Cancel")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.addCategory
    }
  }, [_vm._v("Add Item")])], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;