"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-card", {
    staticClass: "mt-10"
  }, [_c("el-row", [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("div", {
    staticClass: "mt-5 pl-20 pr-20"
  }, [_c("el-table", {
    attrs: {
      data: _vm.backlogFeatureTableData.filter(function (data) {
        return !_vm.backlog_search || data.feature.toLowerCase().includes(_vm.backlog_search.toLowerCase());
      }),
      size: "mini",
      stripe: "",
      fit: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Feature",
      prop: "feature"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Criteria",
      prop: "criteria"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Status",
      prop: "status"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Strategic",
      prop: "is_strategic"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope_child) {
        return [scope_child.row.is_strategic ? _c("svg-icon", {
          attrs: {
            "icon-class": "chess-rook",
            title: "Stratergic"
          }
        }) : _vm._e()];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Approver",
      prop: "approver"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Strategic Reason",
      prop: "strategic_reason",
      width: "300"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Revenue Strategy",
      prop: "revenue_strategy"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Version",
      prop: "version"
    }
  })], 1)], 1)])], 1), _vm._v(" "), _c("el-divider"), _vm._v(" "), _c("div", {
    staticClass: "text-right"
  }, [_c("el-button", {
    attrs: {
      type: "text"
    },
    on: {
      click: function click($event) {
        _vm.otherOptionsDialog = true;
      }
    }
  }, [_c("small", [_vm._v("Other options")])])], 1)], 1)], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Navigation Options",
      visible: _vm.otherOptionsDialog,
      width: "30%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.otherOptionsDialog = $event;
      }
    }
  }, [_c("div", {
    staticClass: "text-center"
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.onClickMarketSell
    }
  }, [_vm._v("Market & Sell")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: _vm.onClickRefineFeatures
    }
  }, [_vm._v("Refine Features")])], 1)])], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;