"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.sort");
var _taggedTemplateLiteral2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/taggedTemplateLiteral.js"));
var _create = _interopRequireDefault(require("../../innovation_hub/journey/create"));
var _journey = _interopRequireDefault(require("../../innovation_hub/journey"));
var _templateObject, _templateObject2;
var _default = exports.default = {
  name: 'JourneyIdea',
  components: {
    JourneyCreate: _create.default,
    JourneyDetails: _journey.default
  },
  props: {},
  data: function data() {
    return {
      loading: false,
      sortDialog: false,
      filterDialog: false,
      newJourneyDialog: false,
      total_count: 0,
      createIdeaDialogTitle: {
        title: 'Create',
        description: 'create description'
      },
      search_ideas: {
        q: '',
        filters: {
          title: '',
          title_operator: '',
          description: '',
          description_operator: '',
          status: [],
          customer_types: [],
          offerings: [],
          score: ''
        },
        sort: {
          title: 'a-z',
          status: 'inprogress-achieved',
          score: 'high-low'
        }
      },
      selectedIdea: {},
      customers: [],
      ideas: [],
      statuses: [{
        label: 'Proposed',
        value: 'Proposed'
      }, {
        label: 'Evaluation',
        value: 'Evaluation'
      }, {
        label: 'Approved',
        value: 'Approved'
      }, {
        label: 'Customer Type Created',
        value: 'Customer Type Created'
      }, {
        label: 'Launched',
        value: 'Launched'
      }, {
        label: 'Completed',
        value: 'Completed'
      }, {
        label: 'Abandoned',
        value: 'Abandoned'
      }]
    };
  },
  computed: {
    journeyIdea: function journeyIdea() {
      var self = this;
      return this.ideas.filter(function (idea) {
        return idea.title.toLowerCase().indexOf(self.search_ideas.q) > -1;
      });
    }
  },
  mounted: function mounted() {
    this.$eventHub.$on('customer-types-changed', this.reloadCustomerTypesJourneyIdea);
    this.retrieveIdeas(1);
    this.retrieveCustomerTypes(1);
  },
  methods: {
    reloadCustomerTypesJourneyIdea: function reloadCustomerTypesJourneyIdea() {
      this.retrieveIdeas(1);
      this.retrieveCustomerTypes(1);
    },
    retrieveCustomerTypes: function retrieveCustomerTypes(page_number) {
      var _this = this;
      this.loading = true;
      this.customers = [];
      this.$apollo.query({
        // Query
        query: window._gql(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)([" query CustomerTypes($page: Page)\n        {\n        CustomerTypes(page: $page)\n        {\n          pages\n          total\n          select{\n            id\n            title\n          }\n        }\n      }"]))),
        fetchPolicy: 'no-cache',
        variables: {
          'page': {
            'limit': 20,
            'start': page_number
          }
        }
      }).then(function (data) {
        _this.customers = data.data.CustomerTypes.select;
        _this.loading = false;
      }).catch(function (error) {
        // Error
        _this.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this.loading = false;
      });
    },
    selectJourney: function selectJourney(idea) {
      var _this2 = this;
      this.loading = true;
      setTimeout(function () {
        _this2.selectedIdea = idea;
        _this2.loading = false;
      }, 100);
    },
    truncateDescription: function truncateDescription(description) {
      return this._.truncate(description, {
        'length': 150,
        'separator': ' '
      });
    },
    openNewJourneyDialog: function openNewJourneyDialog() {
      this.newJourneyDialog = true;
      this.createIdeaDialogTitle.title = 'Existing Journey Improvement';
      this.createIdeaDialogTitle.description = 'All Journeys are shown below';
    },
    updateCreateIdeaDialogTitle: function updateCreateIdeaDialogTitle(title, description) {
      this.createIdeaDialogTitle.title = title;
      this.createIdeaDialogTitle.description = description;
    },
    showSortDialog: function showSortDialog() {
      this.resetSearchSort();
      this.sortDialog = true;
    },
    closeSortDialog: function closeSortDialog() {
      this.resetSearchSort();
      this.sortDialog = false;
    },
    resetSearchSort: function resetSearchSort() {
      this.search_ideas.sort = {
        title: 'a-z',
        status: 'inprogress-achieved',
        score: 'high-low'
      };
    },
    resetFilterSort: function resetFilterSort() {
      this.resetSearchFilter();
      this.resetSearchSort();
      this.retrieveIdeas(1);
    },
    resetSearchFilter: function resetSearchFilter() {
      this.search_ideas.filters = {
        title: '',
        title_operator: '',
        description: '',
        description_operator: '',
        status: [],
        customer_types: [],
        offerings: [],
        score: ''
      };
    },
    showFilterDialog: function showFilterDialog() {
      this.resetSearchFilter();
      this.filterDialog = true;
    },
    closeFilterDialog: function closeFilterDialog() {
      this.resetSearchFilter();
      this.filterDialog = false;
    },
    filterIdeaList: function filterIdeaList() {
      this.selectedIdea = {};
      this.retrieveIdeas(1);
      this.filterDialog = false;
    },
    sortIdeaList: function sortIdeaList() {
      this.selectedIdea = {};
      this.retrieveIdeas(1);
      this.sortDialog = false;
    },
    retrieveIdeas: function retrieveIdeas(page_number) {
      var _this3 = this;
      this.loading = true;
      this.ideas = [];
      this.$apollo.query({
        // Query
        query: window._gql(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)([" query BusinessIdeas($page: Page, $where: BusinessIdeasCriteriaExpression)\n      {\n      BusinessIdeas(page: $page, where: $where)\n      {\n        pages\n        total\n        select{\n          id\n          title\n          description\n          problemstatement\n          solution\n          valueproposition\n          additionalattributes\n          fileobject\n          status\n          score\n          customer_type\n          scope\n          scopename\n          type\n          linkedCoreParties(optional: true){\n            coreParty(optional: true){\n              id\n              businessname\n            }\n          }\n          linkedOfferings(optional: true){\n            offering(optional: true){\n              id\n              title\n              description\n            }\n          }\n          linkedJourneyMaps(optional: true){\n            journeyMap(optional: true){\n              id\n              title\n              description\n            }\n          }\n          linkedCustomerTypes(optional: true){\n            customerType(optional: true){\n              id\n              title\n              description\n            }\n          }\n        }\n      }\n    }"]))),
        fetchPolicy: 'network-only',
        variables: {
          'page': {
            'limit': 10,
            'start': page_number
          },
          'where': this.computeSearchQuery()
        }
      }).then(function (data) {
        _this3.ideas = data.data.BusinessIdeas.select;
        _this3.total_count = data.data.BusinessIdeas.total;
        _this3.loading = false;
      }).catch(function (error) {
        // Error
        _this3.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this3.loading = false;
      });
    },
    computeSearchQuery: function computeSearchQuery() {
      var query = {};
      // quick and dirty method to generate query.
      // TODO make this code intelligent
      query.type = {
        'EQ': 'journey'
      };
      if (this.search_ideas.q !== '') {
        query.title = {
          'LIKE_': this.search_ideas.q
        };
      }
      if (this.search_ideas.filters.title !== '') {
        switch (this.search_ideas.filters.title_operator) {
          case 'EXACT':
            query.title = {
              'EQ_': this.search_ideas.filters.title
            };
            break;
          case 'LIKE':
            query.title = {
              'LIKE_': this.search_ideas.filters.title
            };
            break;
          case 'EMPTY':
            query.title = {
              'IS_NULL': this.search_ideas.filters.title
            };
            break;
          case 'NOT EMPTY':
            query.title = {
              'NOT_NULL': this.search_ideas.filters.title
            };
            break;
          case 'STARTS':
            query.title = {
              'STARTS_': this.search_ideas.filters.title
            };
            break;
          case 'ENDS':
            query.title = {
              'ENDS_': this.search_ideas.filters.title
            };
            break;
          default:
            query.title = {
              'LIKE_': this.search_ideas.filters.title
            };
            break;
        }
      }
      if (this.search_ideas.filters.description !== '') {
        switch (this.search_ideas.filters.description_operator) {
          case 'EXACT':
            query.description = {
              'EQ_': this.search_ideas.filters.description
            };
            break;
          case 'LIKE':
            query.description = {
              'LIKE_': this.search_ideas.filters.description
            };
            break;
          case 'EMPTY':
            query.description = {
              'IS_NULL': this.search_ideas.filters.description
            };
            break;
          case 'NOT EMPTY':
            query.description = {
              'NOT_NULL': this.search_ideas.filters.description
            };
            break;
          case 'STARTS':
            query.description = {
              'STARTS_': this.search_ideas.filters.description
            };
            break;
          case 'ENDS':
            query.description = {
              'ENDS_': this.search_ideas.filters.description
            };
            break;
          default:
            query.description = {
              'LIKE_': this.search_ideas.filters.description
            };
            break;
        }
      }
      if (this.search_ideas.filters.status.length > 0) {
        query.status = {
          'IN': this.search_ideas.filters.status
        };
      }
      if (this.search_ideas.filters.offerings.length > 0) {
        query.offerings = {
          'IN': this.search_ideas.filters.offerings
        };
      }
      if (this.search_ideas.filters.customer_types.length > 0) {
        query.customer_types = {
          'IN': this.search_ideas.filters.customer_types
        };
      }
      if (this.search_ideas.filters.score !== '') {
        query.score = {
          'EQ': this.search_ideas.filters.score
        };
      }
      return query;
    },
    loadPage: function loadPage(page_number) {
      this.retrieveIdeas(page_number);
    },
    createIdea: function createIdea(idea) {
      this.ideas.push(idea);
      this.newJourneyDialog = false;
    },
    deleteIdea: function deleteIdea(idea_id) {
      for (var i = 0; i < this.ideas.length; i++) {
        if (idea_id === this.ideas[i].id) {
          this.ideas.splice(i, 1);
          this.selectedIdea = {};
          break;
        }
      }
    },
    removeSearchFilterTag: function removeSearchFilterTag(type) {
      var index = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      switch (type) {
        case 'title':
          this.search_ideas.filters.title = '';
          this.search_ideas.filters.title_operator = '';
          break;
        case 'description':
          this.search_ideas.filters.description = '';
          this.search_ideas.filters.description_operator = '';
          break;
        case 'status':
          this.search_ideas.filters.status.splice(index, 1);
          break;
        case 'customer_types':
          this.search_ideas.filters.customer_types.splice(index, 1);
          break;
        case 'offerings':
          this.search_ideas.filters.offerings.splice(index, 1);
          break;
        case 'score':
          this.search_ideas.filters.score = '';
          break;
      }
      this.retrieveIdeas(1);
    },
    removeSearchSortTag: function removeSearchSortTag(type) {
      switch (type) {
        case 'title':
          this.search_ideas.sort.title = 'a-z';
          break;
        case 'status':
          this.search_ideas.sort.status = 'inprogress-achieved';
          break;
        case 'score':
          this.search_ideas.sort.score = 'low-high';
          break;
      }
      this.retrieveIdeas(1);
    }
  }
};