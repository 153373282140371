"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("g", {
    attrs: {
      id: "phase-tool-component",
      refs: "phase-tool-component"
    },
    on: {
      mouseover: _vm.onMouseOver,
      mouseleave: _vm.onMouseLeave
    }
  }, [_c("foreignObject", {
    attrs: {
      x: _vm.x - 10,
      y: _vm.y + 5,
      width: 90,
      height: 50
    }
  }, [_vm.position === "toolbox" ? _c("div", {
    staticClass: "text-center",
    staticStyle: {
      color: "#4672c4",
      height: "30px",
      width: "40px"
    }
  }, [_c("svg-icon", {
    staticClass: "phaseGradient",
    staticStyle: {
      "font-size": "4.8em"
    },
    attrs: {
      id: _vm.generateUniqueID(),
      "icon-class": "phase"
    }
  }), _vm._v(" "), _vm.showLabel ? _c("span", {
    staticStyle: {
      "vertical-align": "top",
      "padding-left": "10pt",
      "font-size": "10px"
    }
  }, [_vm._v(_vm._s(_vm.label))]) : _vm._e()], 1) : _vm._e(), _vm._v(" "), _vm.position === "menubar" ? _c("div", {
    staticClass: "text-center",
    style: _vm.computeBGStyle()
  }, [_c("svg-icon", {
    staticClass: "phaseGradient",
    style: _vm.computeStyle(),
    attrs: {
      id: _vm.generateUniqueID(),
      "icon-class": "phase"
    }
  }), _vm._v(" "), _vm.showLabel ? _c("span", {
    staticStyle: {
      "font-size": "10px",
      color: "#4672c4"
    }
  }, [_vm._v(_vm._s(_vm.label))]) : _vm._e()], 1) : _vm._e()]), _vm._v(" "), _c("defs", [_c("linearGradient", {
    attrs: {
      id: "phaseFillDef",
      x2: "1",
      y2: "1"
    }
  }, [_c("stop", {
    attrs: {
      offset: "0%",
      "stop-color": "#447799"
    }
  }), _vm._v(" "), _c("stop", {
    attrs: {
      offset: "50%",
      "stop-color": "#224488"
    }
  }), _vm._v(" "), _c("stop", {
    attrs: {
      offset: "100%",
      "stop-color": "#112266"
    }
  })], 1)], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;