"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.number.constructor");
var _default = exports.default = {
  name: 'ProcessPrototypeComponent',
  components: {},
  props: {
    process: {
      type: String,
      required: true,
      default: ''
    },
    text1: {
      type: String,
      required: true,
      default: ''
    },
    text2: {
      type: String,
      required: true,
      default: ''
    },
    x: {
      type: Number,
      required: true,
      default: 0
    },
    y: {
      type: Number,
      required: true,
      default: 0
    },
    path: {
      type: String,
      required: true,
      default: ''
    },
    owner: {
      type: String,
      required: true,
      default: ''
    }
  },
  computed: {
    /*
    points() {
      var point_a_x = 152 + parseInt(this.x)
      var point_c_x = 170 + parseInt(this.x)
        var point_a_y = 12 + parseInt(this.y)
      var point_b_y = 36 + parseInt(this.y)
      var point_c_y = 24 + parseInt(this.y)
        return point_a_x + ',' + point_a_y + ' ' + point_a_x + ',' + point_b_y + ' ' + point_c_x + ',' + point_c_y
    }*/
  },
  beforeDestroy: function beforeDestroy() {
    // Stop listening
    // this.$eventHub.$off('click-process')
  },
  methods: {
    updateStyleBG: function updateStyleBG() {
      switch (this.process) {
        case 'linear':
          return 'fill:#42b983;';
        case 'ongoing':
          return 'fill:#FF6600;';
        case 'no-start':
          return 'fill:red;';
      }
    },
    updateStyle: function updateStyle() {
      switch (this.process) {
        case 'linear':
          return 'fill:ghostwhite;stroke:gray;stroke-width:0.5';
        case 'ongoing':
          return 'fill:ghostwhite;stroke:gray;stroke-width:0.5';
        case 'urgent':
          return 'fill:ghostwhite;stroke:red;stroke-width:0.5';
      }
    },
    openDialog: function openDialog() {
      if (this.path !== '') {
        this.$router.push({
          path: '/' + this.path
        });
      } else {
        this.$message({
          message: 'No processes found',
          type: 'warning'
        });
      }
    },
    describeArc: function describeArc(x, y, radius, startAngle, endAngle) {
      var start = this.polarToCartesian(x, y, radius, endAngle);
      var end = this.polarToCartesian(x, y, radius, startAngle);
      var largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';
      var d = ['M', start.x, start.y, 'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y].join(' ');
      return d;
    },
    polarToCartesian: function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
      var angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;
      return {
        x: centerX + radius * Math.cos(angleInRadians),
        y: centerY + radius * Math.sin(angleInRadians)
      };
    },
    toUpper: function toUpper(string) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    },
    getInitials: function getInitials() {
      var names = this.owner.split(' ');
      var initials = names[0].substring(0, 1).toUpperCase();
      if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
      }
      return initials;
    }
  }
};