"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("refine-feature-component", {
    attrs: {
      customer: _vm.selectedCustomer,
      "journey-map": _vm.selectedCustomer.linkedJourneyMaps.length > 0 ? _vm.selectedCustomer.linkedJourneyMaps[0].journeyMap : {},
      offering: _vm.offering
    }
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;