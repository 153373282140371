"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 18
    }
  }, [_c("div", [_c("span", [_c("strong", [_vm._v("Primary Offering")])]), _vm._v(" "), _vm.primaryVariants.length > 0 ? _c("div", {
    staticClass: "mt-5 pl-10 text-justify",
    staticStyle: {
      color: "rgb(96, 98, 102)",
      height: "54px"
    }
  }, [_vm._v(_vm._s(_vm.primaryVariants[0].title))]) : _vm._e()]), _vm._v(" "), _c("div", [_c("span", [_c("strong", [_vm._v("Description")])]), _vm._v(" "), _vm.primaryVariants.length > 0 ? _c("div", {
    staticClass: "mt-5 pl-10 text-justify",
    staticStyle: {
      color: "rgb(96, 98, 102)",
      height: "54px"
    }
  }, [_vm._v(_vm._s(_vm.primaryVariants[0].description))]) : _vm._e()])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("table", {
    attrs: {
      id: "primary_variant_details"
    }
  }, [_c("tr", [_c("th", [_c("i", {
    staticClass: "el-icon-user"
  }), _vm._v(" Created By")]), _vm._v(" "), _vm.primaryVariants.length > 0 ? _c("td", [_c("el-tag", {
    attrs: {
      size: "mini"
    }
  }, [_vm._v(_vm._s(_vm.computeUserNameFromId(_vm.primaryVariants[0].createdby)))])], 1) : _vm._e()]), _vm._v(" "), _c("tr", [_c("th", [_vm._v("Created at")]), _vm._v(" "), _vm.primaryVariants.length > 0 ? _c("td", [_vm._v(_vm._s(_vm.computeHumanFriendlyDate(_vm.primaryVariants[0].createdat)))]) : _vm._e()]), _vm._v(" "), _c("tr", [_c("th", [_c("i", {
    staticClass: "el-icon-user"
  }), _vm._v(" Modified By")]), _vm._v(" "), _vm.primaryVariants.length > 0 ? _c("td", [_c("el-tag", {
    attrs: {
      size: "mini"
    }
  }, [_vm._v(_vm._s(_vm.computeUserNameFromId(_vm.primaryVariants[0].modifiedby)))])], 1) : _vm._e()]), _vm._v(" "), _c("tr", [_c("th", [_vm._v("Modified at")]), _vm._v(" "), _vm.primaryVariants.length > 0 ? _c("td", [_vm._v(_vm._s(_vm.computeHumanFriendlyDate(_vm.primaryVariants[0].modifiedat)))]) : _vm._e()])])])], 1), _vm._v(" "), _c("el-row", {
    staticClass: "mt-20",
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 5
    }
  }, [_c("el-card", [_c("div", {
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("table", {
    attrs: {
      width: "100%"
    }
  }, [_c("tr", [_c("td", [_c("el-popover", {
    ref: "varInfoPop",
    attrs: {
      placement: "right",
      width: "300",
      trigger: "hover",
      content: "Vary"
    }
  }, [_c("div", {
    staticClass: "popover-heading"
  }, [_vm._v("Variants")]), _vm._v(" "), _c("div", {
    staticClass: "popover-content"
  }, [_vm._v("Vary an existing offering to give additional or reduced functionality (freemium, premium etc). The variant is linked to same customer type as the primary offering.")])]), _vm._v(" "), _c("div", {}, [_c("span", {
    directives: [{
      name: "popover",
      rawName: "v-popover:varInfoPop",
      arg: "varInfoPop"
    }],
    staticStyle: {
      "font-size": "1.25em",
      "font-weight": "500"
    }
  }, [_vm._v("Variants  "), _c("i", {
    staticClass: "el-icon-info"
  })])])], 1), _vm._v(" "), _c("td", {
    staticClass: "text-right"
  }, [_c("div", {
    staticClass: "text-right"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.openNewVariantDialog
    }
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "create"
    }
  }), _vm._v(" New")], 1)], 1)])])])]), _vm._v(" "), _c("el-table", {
    attrs: {
      data: _vm.secondaryVariants,
      size: "mini",
      stripe: "",
      "show-header": false
    },
    on: {
      "row-click": _vm.selectVariant
    }
  }, [_c("el-table-column", {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v("\n              " + _vm._s(scope.row.title) + "\n            ")];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    staticClass: "fr",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-tooltip", {
          staticClass: "item",
          attrs: {
            effect: "dark",
            content: "Cloned offering",
            placement: "top"
          }
        }, [scope.row.iscloned ? _c("svg-icon", {
          staticStyle: {
            color: "black",
            float: "right"
          },
          attrs: {
            "icon-class": "clone"
          }
        }) : _vm._e()], 1)];
      }
    }])
  })], 1)], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 15
    }
  }, [_vm.selectedVariant && _vm.selectedVariant.title ? _c("el-card", [_c("table", [_c("tr", [_c("th", [_vm._v("Name")]), _vm._v(" "), _c("td", [_c("el-input", {
    attrs: {
      type: "text",
      size: "mini",
      disabled: ""
    },
    model: {
      value: _vm.selectedVariant.title,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedVariant, "title", $$v);
      },
      expression: "selectedVariant.title"
    }
  })], 1)]), _vm._v(" "), _c("tr", [_c("th", [_vm._v("Type")]), _vm._v(" "), _c("td", [_c("el-tag", {
    attrs: {
      size: "mini"
    }
  }, [_vm._v(_vm._s(_vm.selectedVariant.offeringtype))])], 1)]), _vm._v(" "), _c("tr", [_c("th", [_vm._v("Description")]), _vm._v(" "), _c("td", [_c("el-input", {
    attrs: {
      type: "text",
      size: "mini",
      disabled: ""
    },
    model: {
      value: _vm.selectedVariant.description,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedVariant, "description", $$v);
      },
      expression: "selectedVariant.description"
    }
  })], 1)]), _vm._v(" "), _vm.selectedVariant.iscloned ? _c("tr", [_c("th", [_vm._v("Cloned from")]), _vm._v(" "), _c("td", [_c("el-tag", {
    attrs: {
      size: "mini",
      type: "primary"
    }
  }, [_vm._v(_vm._s(_vm.getOfferingTitleFromID(_vm.selectedVariant.id)))])], 1)]) : _vm._e()])]) : _c("el-card", [_c("div", {
    staticClass: "text-center"
  }, [_vm._v("\n          Select a variant to view details\n        ")])])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 4
    }
  }, [_vm.selectedVariant && _vm.selectedVariant.title ? _c("el-card", [_c("table", {
    staticClass: "text-center",
    attrs: {
      width: "100%"
    }
  }, [_c("tr", [_c("td", [_c("el-button", {
    staticStyle: {
      width: "125px"
    },
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.openInDesigner();
      }
    }
  }, [_vm._v("Open in Designer")])], 1)]), _vm._v(" "), _c("tr", [_c("td", [_c("el-button", {
    staticStyle: {
      width: "125px"
    },
    attrs: {
      type: "danger",
      size: "mini"
    },
    on: {
      click: _vm.deleteVariant
    }
  }, [_vm._v("Delete")])], 1)])])]) : _vm._e()], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Create Offering variant",
      visible: _vm.createOfferingVariantDialog,
      "append-to-body": true,
      width: "50%",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.createOfferingVariantDialog = $event;
      }
    }
  }, [_c("create-offering-component", {
    attrs: {
      "is-variant": true,
      "customer-type-id": _vm.isUpdate ? _vm.customer_type.id : _vm.offering.linkedCustomerTypes[0].customerType.id
    },
    on: {
      "create-offering": _vm.createOffering,
      close: function close($event) {
        _vm.createOfferingVariantDialog = false;
      }
    }
  })], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;