"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "content",
    staticStyle: {
      padding: "5px 10px"
    },
    attrs: {
      contenteditable: _vm.contenteditable
    },
    on: {
      paste: function paste($event) {
        $event.preventDefault();
        return _vm.pasteData.apply(null, arguments);
      },
      blur: _vm.save
    }
  }, [_vm._v("\n  " + _vm._s(_vm.item.content) + "\n")]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;