"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-row", {
    staticClass: "mt-20 app-container",
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 18
    }
  }, [_c("el-card", [_c("div", {
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("el-row", [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("strong", [_vm._v("Roadmap")])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("div", {
    staticClass: "text-center"
  }, [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.roadmap_mode,
      callback: function callback($$v) {
        _vm.roadmap_mode = $$v;
      },
      expression: "roadmap_mode"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: "Weekly"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Monthly"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Quaterly"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Yearly"
    }
  })], 1)], 1)]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("div", {
    staticClass: "text-right"
  }, [_c("el-button-group", [_c("el-button", {
    attrs: {
      type: "secondary",
      size: "mini",
      icon: "el-icon-arrow-left"
    },
    on: {
      click: _vm.scrollLeft
    }
  }, [_vm._v("Left")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "secondary",
      size: "mini"
    },
    on: {
      click: _vm.resetScroll
    }
  }, [_vm._v("Reset")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "secondary",
      size: "mini"
    },
    on: {
      click: _vm.scrollRight
    }
  }, [_vm._v("Right "), _c("i", {
    staticClass: "el-icon-arrow-right"
  })])], 1)], 1)])], 1)], 1), _vm._v(" "), _c("div", {
    staticStyle: {
      "flex-grow": "1",
      height: "700px"
    },
    attrs: {
      id: "myDiagramDiv"
    },
    on: {
      drop: _vm.handleDrop,
      dragover: _vm.handleDragOver
    }
  }, [_c("svg", {
    attrs: {
      height: "850px",
      width: "100%",
      xmlns: "http://www.w3.org/2000/svg",
      version: "1.1"
    }
  }, [_c("g", {
    attrs: {
      refs: "horizontal_tracks"
    }
  }, _vm._l(_vm.roadmap.tracks, function (track, index) {
    return _c("roadmap-track-component", {
      key: index,
      attrs: {
        x: 0,
        y: _vm.computeHorizontalTrackY(index),
        height: _vm.computeTrackHeight(index),
        index: index,
        track: track
      },
      on: {
        delete: function _delete($event) {
          return _vm.deleteTrack(index);
        }
      }
    });
  }), 1), _vm._v(" "), _c("g", {
    attrs: {
      refs: "vertical_tracks"
    }
  }, [_vm._l(_vm.computeTrackCount, function (n, index) {
    return _c("line", {
      key: "v_track_" + index,
      staticStyle: {
        stroke: "gray",
        "stroke-width": "2",
        "stroke-opacity": "0.2"
      },
      attrs: {
        x1: _vm.computeTrackX1(n),
        y1: "50",
        x2: _vm.computeTrackX2(n),
        y2: _vm.computeReleaseHeight() + 20
      }
    });
  }), _vm._v(" "), _vm._l(_vm.track_names, function (track, index) {
    return _c("g", {
      key: "track_name_" + index,
      attrs: {
        refs: "week_month_year"
      }
    }, [_c("text", {
      staticStyle: {
        fill: "#000000"
      },
      attrs: {
        x: 137 + index * _vm.computeTrackWidth,
        y: "75",
        "text-anchor": "middle",
        "font-weight": "bold"
      }
    }, [_vm._v("\n                  " + _vm._s(track.line2) + "\n                ")]), _vm._v(" "), _c("text", {
      staticStyle: {
        fill: "#000000"
      },
      attrs: {
        x: 137 + index * 75,
        y: "50",
        "text-anchor": "middle",
        "font-size": "1.5em"
      }
    }, [_vm._v("\n                  " + _vm._s(track.line1) + "\n                ")])]);
  })], 2), _vm._v(" "), _c("g", {
    attrs: {
      refs: "releases"
    }
  }, _vm._l(_vm.roadmap.releases, function (release, index) {
    return _c("roadmap-release-component", {
      directives: [{
        name: "dragged",
        rawName: "v-dragged.prevent",
        value: _vm.startReleaseMove,
        expression: "startReleaseMove",
        modifiers: {
          prevent: true
        }
      }],
      key: _vm.generateReleaseID(index),
      attrs: {
        id: _vm.generateReleaseID(index),
        x: _vm.computeReleaseX(index),
        width: _vm.computeReleaseWidth(index),
        height: _vm.computeReleaseHeight(),
        release: release
      },
      on: {
        click: function click($event) {
          return _vm.openReleaseDetailDialog(release);
        },
        delete: function _delete($event) {
          return _vm.deleteRelease(index);
        }
      }
    });
  }), 1), _vm._v(" "), _c("g", {
    attrs: {
      refs: "sprints"
    }
  }, _vm._l(_vm.roadmap.sprints, function (sprint, index) {
    return _c("roadmap-sprint-component", {
      key: _vm.generateSprintID(index),
      attrs: {
        id: _vm.generateSprintID(index),
        x: _vm.getSprintX(sprint, index),
        y: _vm.getSprintY(sprint, index),
        width: _vm.getSprintWidth(sprint, index),
        index: index,
        sprint: sprint
      },
      on: {
        clickSprint: function clickSprint($event) {
          return _vm.showSprintDetailsDialog(sprint);
        },
        delete: _vm.deleteSprint
      }
    });
  }), 1)])])])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("el-card", [_c("div", {
    staticClass: "text-center"
  }, [_c("el-button-group", [_c("el-button", {
    attrs: {
      id: "release",
      type: "primary",
      size: "mini",
      icon: "el-icon-plus"
    },
    on: {
      click: function click($event) {
        _vm.addReleaseDialog = true;
      }
    }
  }, [_vm._v("Add Release")]), _vm._v(" "), _c("el-button", {
    attrs: {
      id: "track",
      type: "primary",
      size: "mini",
      icon: "el-icon-plus"
    },
    on: {
      click: function click($event) {
        _vm.addTrackDialog = true;
      }
    }
  }, [_vm._v("Add Track")])], 1)], 1)]), _vm._v(" "), _c("el-card", {
    staticClass: "mt-10"
  }, [_c("div", {
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("strong", [_vm._v("Tools")])]), _vm._v(" "), _c("div", {
    staticClass: "text-center"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini",
      icon: "el-icon-plus",
      draggable: ""
    },
    on: {
      click: function click($event) {
        _vm.addSprintDialog = true;
      }
    }
  }, [_vm._v("Add Sprint")])], 1)])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Add new Release",
      visible: _vm.addReleaseDialog,
      width: "30%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addReleaseDialog = $event;
      }
    }
  }, [_c("el-form", {
    ref: "ReleaseForm",
    staticClass: "mt-5 container text-center",
    attrs: {
      model: _vm.release_form,
      "label-width": "150px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Name"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.release_form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.release_form, "name", $$v);
      },
      expression: "release_form.name"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Description"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.release_form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.release_form, "description", $$v);
      },
      expression: "release_form.description"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Start TimeFrame"
    }
  }, [_c("el-date-picker", {
    attrs: {
      type: "daterange",
      "range-separator": "To",
      "start-placeholder": "Start date",
      "end-placeholder": "End date",
      "default-value": "2021-01-01"
    },
    model: {
      value: _vm.release_form.range,
      callback: function callback($$v) {
        _vm.$set(_vm.release_form, "range", $$v);
      },
      expression: "release_form.range"
    }
  })], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "small"
    },
    on: {
      click: function click($event) {
        _vm.addReleaseDialog = false;
      }
    }
  }, [_vm._v("Cancel")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "small",
      type: "primary"
    },
    on: {
      click: _vm.addRelease
    }
  }, [_vm._v("Add Release")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Add new Track",
      visible: _vm.addTrackDialog,
      width: "30%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addTrackDialog = $event;
      }
    }
  }, [_c("el-form", {
    ref: "trackForm",
    staticClass: "mt-5 container text-center",
    attrs: {
      model: _vm.track_form,
      "label-width": "150px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Name"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.track_form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.track_form, "name", $$v);
      },
      expression: "track_form.name"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Description"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.track_form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.track_form, "description", $$v);
      },
      expression: "track_form.description"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Text Color"
    }
  }, [_c("swatches", {
    attrs: {
      colors: "material-light"
    },
    model: {
      value: _vm.track_form.color,
      callback: function callback($$v) {
        _vm.$set(_vm.track_form, "color", $$v);
      },
      expression: "track_form.color"
    }
  })], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "small"
    },
    on: {
      click: function click($event) {
        _vm.addTrackDialog = false;
      }
    }
  }, [_vm._v("Cancel")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "small",
      type: "primary"
    },
    on: {
      click: _vm.addTrack
    }
  }, [_vm._v("Add Track")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Add new Sprint",
      visible: _vm.addSprintDialog,
      width: "40%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addSprintDialog = $event;
      }
    }
  }, [_c("el-form", {
    ref: "sprintForm",
    staticClass: "mt-5 container text-center",
    attrs: {
      model: _vm.sprint_form,
      "label-width": "150px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Name"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.sprint_form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.sprint_form, "name", $$v);
      },
      expression: "sprint_form.name"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Description"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.sprint_form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.sprint_form, "description", $$v);
      },
      expression: "sprint_form.description"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Release"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.sprint_form.release,
      callback: function callback($$v) {
        _vm.$set(_vm.sprint_form, "release", $$v);
      },
      expression: "sprint_form.release"
    }
  }, _vm._l(_vm.roadmap.releases, function (release, index) {
    return _c("el-radio-button", {
      attrs: {
        label: release.name
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Track"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.sprint_form.track,
      callback: function callback($$v) {
        _vm.$set(_vm.sprint_form, "track", $$v);
      },
      expression: "sprint_form.track"
    }
  }, _vm._l(_vm.roadmap.tracks, function (track, index) {
    return _c("el-radio-button", {
      attrs: {
        label: track.name
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Goal"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.sprint_form.goal,
      callback: function callback($$v) {
        _vm.$set(_vm.sprint_form, "goal", $$v);
      },
      expression: "sprint_form.goal"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Mode"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Select mode"
    },
    model: {
      value: _vm.sprint_form.mode,
      callback: function callback($$v) {
        _vm.$set(_vm.sprint_form, "mode", $$v);
      },
      expression: "sprint_form.mode"
    }
  }, [_c("el-option", {
    attrs: {
      label: "Manually choose features",
      value: "manual"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Journey Driven",
      value: "auto"
    }
  })], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Stage"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Stage"
    },
    model: {
      value: _vm.sprint_form.stage,
      callback: function callback($$v) {
        _vm.$set(_vm.sprint_form, "stage", $$v);
      },
      expression: "sprint_form.stage"
    }
  }, [_c("el-option", {
    attrs: {
      label: "Pre Alpha",
      value: "pre alpha"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Alpha",
      value: "alpha"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Beta",
      value: "beta"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Release Candidate",
      value: "rc"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Release To Marketing",
      value: "rtm"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "General Availability",
      value: "ga"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Live",
      value: "live"
    }
  })], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Date Range"
    }
  }, [_c("el-date-picker", {
    attrs: {
      type: "daterange",
      "range-separator": "To",
      "start-placeholder": "Start date",
      "end-placeholder": "End date",
      "default-value": "2021-01-01"
    },
    model: {
      value: _vm.sprint_form.range,
      callback: function callback($$v) {
        _vm.$set(_vm.sprint_form, "range", $$v);
      },
      expression: "sprint_form.range"
    }
  })], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "small"
    },
    on: {
      click: function click($event) {
        _vm.addSprintDialog = false;
      }
    }
  }, [_vm._v("Cancel")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "small",
      type: "primary"
    },
    on: {
      click: _vm.addSprint
    }
  }, [_vm._v("Add Sprint")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: _vm.activeSprint.name,
      visible: _vm.sprintDetailsDialog,
      width: "40%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.sprintDetailsDialog = $event;
      }
    }
  }, [_c("table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      id: "sprints"
    }
  }, [_c("colgroup", [_c("col", {
    staticStyle: {
      width: "25%"
    },
    attrs: {
      span: "1"
    }
  })]), _vm._v(" "), _c("tr", [_c("th", {
    staticClass: "text-right"
  }, [_vm._v("Name:")]), _vm._v(" "), _c("td", [_vm._v(_vm._s(_vm.activeSprint.name))])]), _vm._v(" "), _c("tr", [_c("th", {
    staticClass: "text-right"
  }, [_vm._v("Description:")]), _vm._v(" "), _c("td", [_vm._v(_vm._s(_vm.activeSprint.description))])]), _vm._v(" "), _c("tr", [_c("th", {
    staticClass: "text-right"
  }, [_vm._v("Release:")]), _vm._v(" "), _c("td", [_vm._v(_vm._s(_vm.activeSprint.release))])]), _vm._v(" "), _c("tr", [_c("th", {
    staticClass: "text-right"
  }, [_vm._v("Track:")]), _vm._v(" "), _c("td", [_vm._v(_vm._s(_vm.activeSprint.track))])]), _vm._v(" "), _c("tr", [_c("th", {
    staticClass: "text-right"
  }, [_vm._v("Goal:")]), _vm._v(" "), _c("td", [_vm._v(_vm._s(_vm.activeSprint.goal))])]), _vm._v(" "), _c("tr", [_c("th", {
    staticClass: "text-right"
  }, [_vm._v("Mode:")]), _vm._v(" "), _c("td", [_vm._v(_vm._s(_vm.activeSprint.mode))])]), _vm._v(" "), _c("tr", [_c("th", {
    staticClass: "text-right"
  }, [_vm._v("Stage:")]), _vm._v(" "), _c("td", [_vm._v(_vm._s(_vm.activeSprint.stage))])]), _vm._v(" "), _c("tr", [_c("th", {
    staticClass: "text-right"
  }, [_vm._v("Date Range:")]), _vm._v(" "), _vm.activeSprint.daterange !== undefined ? _c("td", [_vm._v(_vm._s(_vm.activeSprint.daterange.start) + " to " + _vm._s(_vm.activeSprint.daterange.end))]) : _vm._e()]), _vm._v(" "), _c("tr", [_c("th", {
    staticClass: "text-right"
  }, [_vm._v("Features:")]), _vm._v(" "), _c("td", [_vm._l(_vm.activeSprint.features, function (feature, index) {
    return _c("el-tag", {
      key: feature.name,
      attrs: {
        closable: "",
        type: "success"
      },
      on: {
        close: function close($event) {
          return _vm.removeFeature(index);
        }
      }
    }, [_vm._v("\n            " + _vm._s(feature.name) + "\n          ")]);
  }), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini",
      type: "secondary",
      icon: "el-icon-plus"
    },
    on: {
      click: function click($event) {
        _vm.addFeatureEnablerDialog = true;
      }
    }
  })], 2)]), _vm._v(" "), _c("tr", [_c("th", {
    staticClass: "text-right"
  }, [_vm._v("Enablers:")]), _vm._v(" "), _c("td", [_vm._l(_vm.activeSprint.enablers, function (enabler, index) {
    return _c("el-tag", {
      key: enabler.name,
      attrs: {
        closable: "",
        type: "success"
      },
      on: {
        close: function close($event) {
          return _vm.removeEnabler(index);
        }
      }
    }, [_vm._v("\n            " + _vm._s(enabler.name) + "\n          ")]);
  }), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini",
      type: "secondary",
      icon: "el-icon-plus"
    },
    on: {
      click: function click($event) {
        _vm.addFeatureEnablerDialog = true;
      }
    }
  })], 2)])]), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "small"
    },
    on: {
      click: function click($event) {
        _vm.sprintDetailsDialog = false;
      }
    }
  }, [_vm._v("Cancel")])], 1)]), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Add Feature Enabler to Sprint",
      visible: _vm.addFeatureEnablerDialog,
      width: "40%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addFeatureEnablerDialog = $event;
      }
    }
  }, [_c("span", [_vm._v(" Choose Features & Enablers ")]), _vm._v(" "), _c("el-input", {
    staticClass: "input-with-select",
    attrs: {
      placeholder: "Search"
    },
    model: {
      value: _vm.featureEnablersSearch,
      callback: function callback($$v) {
        _vm.featureEnablersSearch = $$v;
      },
      expression: "featureEnablersSearch"
    }
  }, [_c("el-button", {
    attrs: {
      slot: "append",
      icon: "el-icon-search"
    },
    slot: "append"
  })], 1), _vm._v(" "), _c("el-checkbox", {
    model: {
      value: _vm.showOnlyStratergicFeatures,
      callback: function callback($$v) {
        _vm.showOnlyStratergicFeatures = $$v;
      },
      expression: "showOnlyStratergicFeatures"
    }
  }, [_vm._v("Stratergic Features Only")]), _vm._v(" "), _c("table", {
    attrs: {
      id: "features-enablers-table"
    }
  }, [_c("tr", {
    staticClass: "features-enablers-table-row"
  }, [_c("th", [_vm._v("Feature/Enabler")])]), _vm._v(" "), _vm._l(_vm.filteredFeatures, function (filteredFeature, index) {
    return _c("tr", {
      key: index,
      staticClass: "features-enablers-table-row",
      on: {
        click: function click($event) {
          return _vm.addFeatureEnabler(index);
        }
      }
    }, [_c("td", [_vm._v(_vm._s(filteredFeature.name))]), _vm._v(" "), _c("td", [filteredFeature.is_stratergic ? _c("svg-icon", {
      attrs: {
        "icon-class": "chess-rook",
        title: "Stratergic"
      }
    }) : _vm._e()], 1), _vm._v(" "), _c("td", [filteredFeature.priority ? _c("svg-icon", {
      attrs: {
        "icon-class": _vm.getPriorityIcon(filteredFeature.priority),
        title: "Priority"
      }
    }) : _vm._e()], 1), _vm._v(" "), _c("td", [filteredFeature.value ? _c("svg-icon", {
      attrs: {
        "icon-class": _vm.getValueIcon(filteredFeature.value),
        title: "Value"
      }
    }) : _vm._e()], 1)]);
  })], 2), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "small"
    },
    on: {
      click: function click($event) {
        _vm.addFeatureEnablerDialog = false;
      }
    }
  }, [_vm._v("Cancel")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Release Details",
      visible: _vm.releaseDetailsDialog,
      width: "50%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.releaseDetailsDialog = $event;
      }
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    staticClass: "text-right",
    attrs: {
      span: 6
    }
  }, [_c("strong", [_vm._v("Name: ")])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 18
    }
  }, [_vm._v("\n        " + _vm._s(_vm.activeRelease.name) + "\n      ")])], 1), _vm._v(" "), _c("el-row", {
    staticClass: "mt-10",
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    staticClass: "text-right",
    attrs: {
      span: 6
    }
  }, [_c("strong", [_vm._v("Description: ")])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 18
    }
  }, [_vm._v("\n        " + _vm._s(_vm.activeRelease.description) + "\n      ")])], 1), _vm._v(" "), _c("el-row", {
    staticClass: "mt-10",
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    staticClass: "text-right",
    attrs: {
      span: 6
    }
  }, [_c("strong", [_vm._v("Start: ")])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 18
    }
  }, [_c("el-tag", {
    attrs: {
      size: "small"
    }
  }, [_vm._v(_vm._s(_vm.activeRelease.start_date))])], 1)], 1), _vm._v(" "), _c("el-row", {
    staticClass: "mt-10",
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    staticClass: "text-right",
    attrs: {
      span: 6
    }
  }, [_c("strong", [_vm._v("End: ")])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 18
    }
  }, [_c("el-tag", {
    attrs: {
      size: "small"
    }
  }, [_vm._v(_vm._s(_vm.activeRelease.end_date))])], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "text-center mt-10"
  }, [_c("el-button", {
    attrs: {
      size: "small"
    },
    on: {
      click: function click($event) {
        _vm.releaseDetailsDialog = false;
      }
    }
  }, [_vm._v("Cancel")])], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;