"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _default2 = exports.default = {
  name: 'WireframeComponent',
  props: {
    x: {
      type: Number,
      required: true,
      default: 0
    },
    y: {
      type: Number,
      required: true,
      default: 0
    },
    index: {
      type: Number,
      required: true,
      default: 0
    },
    layout: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      isHover: false
    };
  },
  methods: {
    onEdit: function onEdit(event) {
      this.$emit('edit', this.index);
    },
    onDelete: function onDelete(event) {
      this.$emit('delete', this.index);
    },
    computeX: function computeX(x, layout, index) {
      // Compute Location
      var computed_x = x + index * 200 + layout.x * 8 + layout.x * 2;
      return computed_x;
    },
    computeY: function computeY(y, layout, index) {
      // Compute Location
      var computed_y = y + index * 50 + layout.y * 8 + layout.y * 2;
      return computed_y;
    }
  }
};