"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default2 = exports.default = {
  name: 'TaskCreate',
  components: {},
  props: {
    task: {
      type: Object,
      default: function _default() {
        return {
          title: '',
          description: '',
          intention: '',
          goal: '',
          importance: 'Low',
          priority: 'Low',
          relations: []
        };
      }
    }
  },
  data: function data() {
    return {
      task_form: {
        title: '',
        description: '',
        intention: '',
        goal: '',
        importance: 'Low',
        priority: 'Low',
        relations: []
      },
      addSteprules: {
        title: [{
          required: true,
          message: 'Please input Step title',
          trigger: 'blur'
        }, {
          min: 5,
          max: 80,
          message: 'Length should be 5 to 80',
          trigger: 'blur'
        }],
        description: [{
          required: true,
          message: 'Please input Step description',
          trigger: 'blur'
        }],
        intention: [{
          required: true,
          message: 'Please input Step thought',
          trigger: 'blur'
        }],
        goal: [{
          required: true,
          message: 'Please input Step Goal',
          trigger: 'blur'
        }],
        priority: [{
          required: true,
          message: 'Please input Step Priority',
          trigger: 'blur'
        }],
        importance: [{
          required: true,
          message: 'Please select importance',
          trigger: 'change'
        }]
      }
    };
  },
  computed: {},
  watch: {},
  created: function created() {},
  mounted: function mounted() {
    this.resetForm();
  },
  methods: {
    createTask: function createTask() {
      var _this = this;
      this.$refs.addStepform.validate(function (valid) {
        if (valid) {
          var task = Object.assign({}, _this.task_form);
          _this.$emit('add-task', task);
          _this.resetForm();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    updatePhaseDetails: function updatePhaseDetails() {},
    deletePhase: function deletePhase() {},
    closeUI: function closeUI() {
      this.resetForm();
      this.$emit('close');
    },
    resetForm: function resetForm() {
      this.task_form = {
        title: '',
        description: '',
        intention: '',
        goal: '',
        importance: 'Low',
        priority: 'Low',
        relations: []
      };
    }
  }
};