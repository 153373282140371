"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-descriptions", {
    attrs: {
      direction: "vertical",
      column: 4,
      border: ""
    }
  }, [_c("el-descriptions-item", {
    attrs: {
      label: "Title"
    }
  }, [_vm._v(_vm._s(_vm.entity.title))]), _vm._v(" "), _c("el-descriptions-item", {
    attrs: {
      label: "Description"
    }
  }, [_vm._v(_vm._s(_vm.entity.description))])], 1), _vm._v(" "), _c("h5", [_vm._v("Features/Services")]), _vm._v(" "), _vm.entity.__typename === "Product" ? _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      data: _vm.entity.linkedFeatures,
      stripe: "",
      size: "mini"
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "productFeature.title",
      label: "Title",
      sortable: ""
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.entity.__typename === "Service" ? _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      data: _vm.entity.linkedServiceApplications,
      stripe: "",
      size: "mini"
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "serviceApplication.title",
      label: "Title",
      sortable: ""
    }
  })], 1) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "text-right"
  }, [_c("el-button-group", [_c("el-button", {
    attrs: {
      type: "secondary",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.closeDialog();
      }
    }
  }, [_vm._v("Close")])], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;