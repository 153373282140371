"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.function.name");
var _Lane = _interopRequireDefault(require("./components/Lane.vue"));
var _Phase = _interopRequireDefault(require("./components/Phase.vue"));
var _SideBar = _interopRequireDefault(require("./components/SideBar.vue"));
var _Note = _interopRequireDefault(require("./components/Note.vue"));
var _Activity = _interopRequireDefault(require("./components/Activity.vue"));
var _Goal = _interopRequireDefault(require("./components/Goal.vue"));
var _Experience = _interopRequireDefault(require("./components/Experience.vue"));
var _ProcessArea = _interopRequireDefault(require("./components/ProcessArea.vue"));
var _Feature = _interopRequireDefault(require("./components/Feature.vue"));
var _vueSwatches = _interopRequireDefault(require("vue-swatches"));
var _default = exports.default = {
  name: 'UserJourneyMapMatrix2',
  components: {
    LaneComponent: _Lane.default,
    PhaseComponent: _Phase.default,
    SideBarComponent: _SideBar.default,
    NoteComponent: _Note.default,
    ActivityComponent: _Activity.default,
    GoalComponent: _Goal.default,
    ExperienceComponent: _Experience.default,
    ProcessAreaComponent: _ProcessArea.default,
    FeatureComponent: _Feature.default,
    Swatches: _vueSwatches.default
  },
  data: function data() {
    return {
      loading: false,
      journeyMapStatus: 'unapproved',
      addNoteDialog: false,
      addLaneDialog: false,
      addPhaseDialog: false,
      viewNotesDialog: false,
      phaseProgressDialog: false,
      activityDetailsDialog: false,
      activityProgressDialog: false,
      addGoalDialog: false,
      addTaskDialog: false,
      addProcessAreaDialog: false,
      addExperienceDialog: false,
      addFeatureDialog: false,
      isNoteEditFlag: false,
      isLaneEdit: false,
      isPhaseEdit: false,
      isGoalModalEditMode: false,
      isTaskModalEditMode: false,
      isProcessAreaModalEditMode: false,
      showSidebar: false,
      isActivityNull: false,
      selectedActivityIndex: null,
      // split activeActivityIndex and selectedActivityIndex since once mouse leaves, the index reset and causes issue with addgoal function
      selectedPhaseIndex: null,
      // split activeActivityIndex and selectedPhaseIndex since once mouse leaves, the index reset and causes issue with addgoal function

      featureStatus: [{
        value: 'Candidate',
        label: 'Candidate'
      }, {
        value: 'Approved',
        label: 'Approved'
      }, {
        value: 'Design',
        label: 'Design'
      }, {
        value: 'Build',
        label: 'Build'
      }, {
        value: 'Ready for Release',
        label: 'Ready for Release'
      }, {
        value: 'Released',
        label: 'Released'
      }, {
        value: 'Improvement',
        label: 'Improvement'
      }, {
        value: 'Retired',
        label: 'Retired'
      }],
      activePhaseIndex: 0,
      activeDrawingToolDragElement: null,
      activeActivityIndex: 0,
      selectedActivity: {
        task: '',
        intention: '',
        status: '',
        goals: [],
        experiences: [],
        process_areas: [],
        features: []
      },
      notes: [],
      activeNotes: [],
      lane_form: {
        name: '',
        description: '',
        color: 'gray',
        default: false
      },
      phase_form: {
        name: '',
        description: '',
        goal: '',
        challenges: '',
        emotions: '',
        priority: '',
        activities: []
      },
      note_form: {
        note: '',
        color: '#FFF59D',
        phase: '',
        lane: '',
        x: 0,
        y: 0
      },
      goal_form: {
        name: '',
        description: '',
        status: ''
      },
      task_form: {
        x: 0,
        y: 0,
        task: '',
        intention: '',
        goals: [],
        experiences: [],
        process_areas: [],
        features: []
      },
      process_area_form: {
        name: '',
        description: '',
        status: ''
      },
      experience_form: {
        name: '',
        description: '',
        priority: '',
        type: '',
        status: ''
      },
      feature_form: {
        name: '',
        description: '',
        experience_type: 'pain',
        experience_name: '',
        status: 'Candidate'
      },
      layoutParam: {
        lane: {
          title_width: 30,
          height: 150,
          title_height: 30,
          title_bg_color: 'black'
        },
        phase: {
          width: 250,
          title_height: 42,
          artefact_height: 28,
          title_bg_color: 'black'
        }
      },
      phases: [{
        name: 'Phase 1',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        goal: 'Data Flow Design',
        challenges: 'Chalenge 1',
        emotions: 'Emotions',
        priority: 'Medium',
        status: 'completed',
        activities: [{
          task: 'Task name',
          intention: 'Task Intention',
          status: 'completed',
          goals: [{
            name: 'Goal 1',
            description: 'Goal 1 Desc',
            status: 'completed'
          }, {
            name: 'Goal 2',
            description: 'Goal 2',
            status: 'completed'
          }],
          experiences: [{
            name: 'Pain 1',
            description: 'Pain 1 Desc',
            priority: 'low',
            type: 'pain',
            status: 'completed'
          }, {
            name: 'Gain 2',
            description: 'Gain 2',
            priority: 'high',
            type: 'gain',
            status: 'completed'
          }],
          features: [{
            name: 'Feature 1',
            description: 'Feature 1 Desc',
            experience_type: 'pain',
            experience_name: 'Pain 1',
            status: 'Candidate'
          }],
          process_areas: [{
            name: 'Process Area 1',
            description: 'Process Area 1 Desc',
            status: 'completed'
          }]
        }, {
          task: 'Task name 2',
          intention: 'Task Intention 2',
          goals: [],
          experiences: [],
          features: [],
          process_areas: []
        }]
      }, {
        name: 'Phase 2',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        goal: 'Data Flow Design',
        challenges: 'Chalenge 1',
        emotions: 'Emotions',
        priority: 'Medium',
        status: 'completed',
        activities: []
      }, {
        name: 'Phase 3',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        goal: 'Data Flow Design',
        challenges: 'Chalenge 1',
        emotions: 'Emotions',
        priority: 'Medium',
        status: 'completed',
        activities: []
      }],
      lanes: [{
        name: 'Tasks',
        description: 'Lorem Ipsum',
        color: '#409EFF',
        default: true
      }, {
        name: 'Goals',
        description: 'Lorem Ipsum',
        color: '#67C23A',
        default: true
      }, {
        name: 'Experience',
        description: 'Lorem Ipsum',
        color: '#B10DC9',
        default: true
      }, {
        name: 'Process Area',
        description: 'Lorem Ipsum',
        color: 'black',
        default: true
      }, {
        name: 'Features',
        description: 'Lorem Ipsum',
        color: '#909399',
        default: true
      }]
    };
  },
  computed: {
    unAttachedNotes: function unAttachedNotes() {
      return this.notes.filter(function (note) {
        return note.phase === '' && note.lane === '';
      });
    }
  },
  created: function created() {
    this.renderActivityPosition();
  },
  methods: {
    addFeature: function addFeature() {
      var feature_form = Object.assign({}, this.feature_form);
      this.phases[this.activePhaseIndex].activities[this.activeActivityIndex].features.push(feature_form);
      this.resetFeatureForm();
      this.addFeatureDialog = false;
    },
    addExperience: function addExperience() {
      var experience_form = Object.assign({}, this.experience_form);
      this.phases[this.activePhaseIndex].activities[this.activeActivityIndex].experiences.push(experience_form);
      this.resetExperienceForm();
      this.addExperienceDialog = false;
    },
    createExperience: function createExperience() {
      this.isActivityNull = false;
      this.addExperienceDialog = true;
    },
    createExperienceWithoutActivityIndex: function createExperienceWithoutActivityIndex() {
      this.isActivityNull = true;
      this.addExperienceDialog = true;
    },
    addProcessArea: function addProcessArea() {
      var process_area_form = Object.assign({}, this.process_area_form);
      this.phases[this.activePhaseIndex].activities[this.activeActivityIndex].process_areas.push(process_area_form);
      this.resetProcessAreaForm();
      this.addProcessAreaDialog = false;
    },
    updateProcessArea: function updateProcessArea() {
      this.resetProcessAreaForm();
      this.addProcessAreaDialog = false;
    },
    addTask: function addTask() {
      var task_form = Object.assign({}, this.task_form);
      this.phases[this.activePhaseIndex].activities.push(task_form);
      this.resetTaskForm();
      this.addTaskDialog = false;
      this.renderActivityPosition();
    },
    updateTask: function updateTask() {
      this.resetTaskForm();
      this.addTaskDialog = false;
    },
    addGoal: function addGoal() {
      var goal_form = Object.assign({}, this.goal_form);
      this.phases[this.activePhaseIndex].activities[this.activeActivityIndex].goals.push(goal_form);
      this.resetGoalForm();
      this.addGoalDialog = false;
    },
    updateGoal: function updateGoal() {
      this.resetGoalForm();
      this.addGoalDialog = false;
    },
    selectActivity: function selectActivity(activity, phase_index, activity_index) {
      this.selectedActivity = activity;
      this.activePhaseIndex = phase_index;
      this.activeActivityIndex = activity_index;
      this.activityDetailsDialog = true;
    },
    activityMouseOver: function activityMouseOver(phase_index, activity_index) {
      this.selectedPhaseIndex = phase_index;
      this.selectedActivityIndex = activity_index;
    },
    activityMouseLeave: function activityMouseLeave(phase_index, activity_index) {
      this.selectedPhaseIndex = null;
      this.selectedActivityIndex = null;
    },
    openActivityProgressDetailDialog: function openActivityProgressDetailDialog(phase_index, activity_index) {
      this.activePhaseIndex = phase_index;
      this.activeActivityIndex = activity_index;
      this.activityProgressDialog = true;
    },
    openProcessAreaDetailDialog: function openProcessAreaDetailDialog(phase_index, activity_index, process_area_index) {
      this.activePhaseIndex = phase_index;
      this.activeActivityIndex = activity_index;
      this.process_area_form = this.phases[phase_index].activities[activity_index].process_areas[process_area_index];
      this.isProcessAreaModalEditMode = true;
      this.addProcessAreaDialog = true;
    },
    openActivityDetailsDialog: function openActivityDetailsDialog() {
      this.task_form = this.phases[this.activePhaseIndex].activities[this.activeActivityIndex];
      this.isTaskModalEditMode = true;
      this.addTaskDialog = true;
    },
    openCreateTaskModal: function openCreateTaskModal(phase_index, edit_mode) {
      this.isTaskModalEditMode = edit_mode;
      this.activePhaseIndex = phase_index;
      this.addTaskDialog = true;
    },
    createGoal: function createGoal(phase_index, activity_index) {
      this.activePhaseIndex = phase_index;
      this.activeActivityIndex = activity_index;
      this.isGoalModalEditMode = false;
      this.isActivityNull = false;
      this.addGoalDialog = true;
    },
    createGoalWithoutActivityIndex: function createGoalWithoutActivityIndex(phase_index) {
      this.activePhaseIndex = phase_index;
      this.isActivityNull = true;
      this.isGoalModalEditMode = false;
      this.addGoalDialog = true;
    },
    renderActivityPosition: function renderActivityPosition() {
      for (var i = 0; i < this.phases.length; i++) {
        for (var j = 0; j < this.phases[i].activities.length; j++) {
          this.phases[i].activities[j].x = i * (this.layoutParam.phase.width + 25);
          this.phases[i].activities[j].y = j * (this.layoutParam.phase.title_height + 5);
        }
      }
      this.loading = false;
    },
    computeLaneWidth: function computeLaneWidth() {
      return this.phases.length * this.layoutParam.phase.width + this.phases.length * 25 + 50;
    },
    computePhaseHeight: function computePhaseHeight() {
      return this.lanes.length * this.layoutParam.lane.height + this.lanes.length * 25 + 50;
    },
    computeNoteTableBGStyle: function computeNoteTableBGStyle(color) {
      return 'width:20px;padding:10px;background-color:' + color + ';';
    },
    computeNotesCountForPhase: function computeNotesCountForPhase(index) {
      var phase_name = this.phases[index].name;
      return window._.filter(this.notes, ['phase', phase_name]).length;
    },
    computeNotesCountForLane: function computeNotesCountForLane(index) {
      var lane_name = this.lanes[index].name;
      return window._.filter(this.notes, ['lane', lane_name]).length;
    },
    computeActivityProgress: function computeActivityProgress(type) {
      var complete = 0;
      var base = 0;
      var j = 0;
      if (this.phases[this.activePhaseIndex].activities.length > 0) {
        switch (type) {
          case 'goal':
            base = 0;
            if (this.phases[this.activePhaseIndex].activities[this.activeActivityIndex].hasOwnProperty('goals')) {
              base += this.phases[this.activePhaseIndex].activities[this.activeActivityIndex].goals.length;
              for (j = 0; j < this.phases[this.activePhaseIndex].activities[this.activeActivityIndex].goals.length; j++) {
                if (this.phases[this.activePhaseIndex].activities[this.activeActivityIndex].goals[j].status === 'completed') {
                  complete++;
                }
              }
            }
            if (complete === 0) {
              return 0;
            } else {
              return Math.round(complete / base * 100, 1);
            }
          case 'feature':
            base = 0;
            if (this.phases[this.activePhaseIndex].activities[this.activeActivityIndex].hasOwnProperty('features')) {
              base += this.phases[this.activePhaseIndex].activities[this.activeActivityIndex].features.length;
              for (j = 0; j < this.phases[this.activePhaseIndex].activities[this.activeActivityIndex].features.length; j++) {
                if (this.phases[this.activePhaseIndex].activities[this.activeActivityIndex].features[j].status === 'completed') {
                  complete++;
                }
              }
            }
            if (complete === 0) {
              return 0;
            } else {
              return Math.round(complete / base * 100, 1);
            }
          case 'experience':
            base = 0;
            if (this.phases[this.activePhaseIndex].activities[this.activeActivityIndex].hasOwnProperty('experiences')) {
              base += this.phases[this.activePhaseIndex].activities[this.activeActivityIndex].experiences.length;
              for (j = 0; j < this.phases[this.activePhaseIndex].activities[this.activeActivityIndex].experiences.length; j++) {
                if (this.phases[this.activePhaseIndex].activities[this.activeActivityIndex].experiences[j].status === 'completed') {
                  complete++;
                }
              }
            }
            if (complete === 0) {
              return 0;
            } else {
              return Math.round(complete / base * 100, 1);
            }
          case 'process_area':
            base = 0;
            if (this.phases[this.activePhaseIndex].activities[this.activeActivityIndex].hasOwnProperty('process_areas')) {
              base += this.phases[this.activePhaseIndex].activities[this.activeActivityIndex].process_areas.length;
              for (j = 0; j < this.phases[this.activePhaseIndex].activities[this.activeActivityIndex].process_areas.length; j++) {
                if (this.phases[this.activePhaseIndex].activities[this.activeActivityIndex].process_areas[j].status === 'completed') {
                  complete++;
                }
              }
            }
        }
      }
      if (complete === 0) {
        return 0;
      } else {
        return Math.round(complete / base * 100, 1);
      }
    },
    computePhaseProgress: function computePhaseProgress(type) {
      var complete = 0;
      var base = 0;
      var i = 0;
      var j = 0;
      switch (type) {
        case 'activity':
          base = this.phases[this.activePhaseIndex].activities.length;
          for (i = 0; i < this.phases[this.activePhaseIndex].activities.length; i++) {
            if (this.phases[this.activePhaseIndex].activities[i].status === 'completed') {
              complete++;
            }
          }
          if (complete === 0) {
            return 0;
          } else {
            return Math.round(complete / base * 100, 1);
          }
        case 'goal':
          base = 0;
          for (i = 0; i < this.phases[this.activePhaseIndex].activities.length; i++) {
            if (this.phases[this.activePhaseIndex].activities[i].hasOwnProperty('goals')) {
              base += this.phases[this.activePhaseIndex].activities[i].goals.length;
              for (j = 0; j < this.phases[this.activePhaseIndex].activities[i].goals.length; j++) {
                if (this.phases[this.activePhaseIndex].activities[i].goals[j].status === 'completed') {
                  complete++;
                }
              }
            }
          }
          if (complete === 0) {
            return 0;
          } else {
            return Math.round(complete / base * 100, 1);
          }
        case 'feature':
          base = 0;
          for (i = 0; i < this.phases[this.activePhaseIndex].activities.length; i++) {
            if (this.phases[this.activePhaseIndex].activities[i].hasOwnProperty('features')) {
              base += this.phases[this.activePhaseIndex].activities[i].features.length;
              for (j = 0; j < this.phases[this.activePhaseIndex].activities[i].features.length; j++) {
                if (this.phases[this.activePhaseIndex].activities[i].features[j].status === 'completed') {
                  complete++;
                }
              }
            }
          }
          if (complete === 0) {
            return 0;
          } else {
            return Math.round(complete / base * 100, 1);
          }
        case 'experience':
          base = 0;
          for (i = 0; i < this.phases[this.activePhaseIndex].activities.length; i++) {
            if (this.phases[this.activePhaseIndex].activities[i].hasOwnProperty('experiences')) {
              base += this.phases[this.activePhaseIndex].activities[i].experiences.length;
              for (j = 0; j < this.phases[this.activePhaseIndex].activities[i].experiences.length; j++) {
                if (this.phases[this.activePhaseIndex].activities[i].experiences[j].status === 'completed') {
                  complete++;
                }
              }
            }
          }
      }
      if (complete === 0) {
        return 0;
      } else {
        return Math.round(complete / base * 100, 1);
      }
    },
    computeOverallPhaseProgress: function computeOverallPhaseProgress(phase_index) {
      var base = this.phases[phase_index].activities.length;
      var complete = 0;
      for (var i = 0; i < this.phases[phase_index].activities.length; i++) {
        if (this.phases[phase_index].activities[i].status === 'completed') {
          complete++;
        }
        if (this.phases[phase_index].activities[i].hasOwnProperty('goals')) {
          base += this.phases[phase_index].activities[i].goals.length;
          for (var j = 0; j < this.phases[phase_index].activities[i].goals.length; j++) {
            if (this.phases[phase_index].activities[i].goals[j].status === 'completed') {
              complete++;
            }
          }
        }
        if (this.phases[phase_index].activities[i].hasOwnProperty('experiences')) {
          base += this.phases[phase_index].activities[i].experiences.length;
          for (var k = 0; k < this.phases[phase_index].activities[i].experiences.length; k++) {
            if (this.phases[phase_index].activities[i].experiences[k].status === 'completed') {
              complete++;
            }
          }
        }
        if (this.phases[phase_index].activities[i].hasOwnProperty('features')) {
          base += this.phases[phase_index].activities[i].features.length;
          for (var l = 0; l < this.phases[phase_index].activities[i].features.length; l++) {
            if (this.phases[phase_index].activities[i].features[l].status === 'completed') {
              complete++;
            }
          }
        }
        if (this.phases[phase_index].activities[i].hasOwnProperty('process_areas')) {
          base += this.phases[phase_index].activities[i].process_areas.length;
          for (var m = 0; m < this.phases[phase_index].activities[i].process_areas.length; m++) {
            if (this.phases[phase_index].activities[i].process_areas[m].status === 'completed') {
              complete++;
            }
          }
        }
      }
      if (complete === 0) {
        return '0%';
      } else {
        return Math.round(complete / base * 100, 1) + '%';
      }
    },
    computeOverallActivityProgress: function computeOverallActivityProgress(phase_index, activity_index) {
      var base = 0;
      var complete = 0;
      if (this.phases[phase_index].activities.length > 0) {
        if (this.phases[phase_index].activities[activity_index].hasOwnProperty('goals')) {
          base += this.phases[phase_index].activities[activity_index].goals.length;
          for (var j = 0; j < this.phases[phase_index].activities[activity_index].goals.length; j++) {
            if (this.phases[phase_index].activities[activity_index].goals[j].status === 'completed') {
              complete++;
            }
          }
        }
        if (this.phases[phase_index].activities[activity_index].hasOwnProperty('experiences')) {
          base += this.phases[phase_index].activities[activity_index].experiences.length;
          for (var k = 0; k < this.phases[phase_index].activities[activity_index].experiences.length; k++) {
            if (this.phases[phase_index].activities[activity_index].experiences[k].status === 'completed') {
              complete++;
            }
          }
        }
        if (this.phases[phase_index].activities[activity_index].hasOwnProperty('features')) {
          base += this.phases[phase_index].activities[activity_index].features.length;
          for (var l = 0; l < this.phases[phase_index].activities[activity_index].features.length; l++) {
            if (this.phases[phase_index].activities[activity_index].features[l].status === 'completed') {
              complete++;
            }
          }
        }
      }
      if (complete === 0) {
        return '0%';
      } else {
        return Math.round(complete / base * 100, 1) + '%';
      }
    },
    computeActivityBGColor: function computeActivityBGColor(phase_index, activity_index) {
      if (this.phases[phase_index].activities[activity_index].features.length === 0) {
        return 'gray';
      }
      var all_feature_released = true;
      var all_pain_feature_released = true;
      var feature_design_build_release_ready = false;
      var feature_candidate_approved = false;
      for (var i = 0; i < this.phases[phase_index].activities[activity_index].features.length; i++) {
        if (this.phases[phase_index].activities[activity_index].features[i].status !== 'Released') {
          all_feature_released = false;
        }
        if (this.phases[phase_index].activities[activity_index].features[i].status === 'Design' || this.phases[phase_index].activities[activity_index].features[i].status === 'Build' || this.phases[phase_index].activities[activity_index].features[i].status === 'Ready for Release') {
          feature_design_build_release_ready = true;
        }
        if (this.phases[phase_index].activities[activity_index].features[i].status === 'Candidate' || this.phases[phase_index].activities[activity_index].features[i].status === 'Approved') {
          feature_candidate_approved = true;
        }
        if (this.phases[phase_index].activities[activity_index].features[i].experience_type === 'pain' && this.phases[phase_index].activities[activity_index].features[i].status !== 'Released') {
          all_pain_feature_released = false;
        }
      }
      if (all_feature_released) {
        return '#13CE66';
      } else if (all_pain_feature_released) {
        return '#409EFF';
      } else if (feature_design_build_release_ready) {
        return '#FF7400';
      } else if (feature_candidate_approved) {
        return '#FA8072';
      }
    },
    startActivityMove: function startActivityMove(el, deltaX, deltaY, offsetX, offsetY, clientX, clientY, first, last) {
      if (last !== undefined && last) {
        // Get IDs
        var indices_temp = el.id.replace('activity_', '').split('_');

        // Update active index, to prevent crash in computing progress
        this.activePhaseIndex = indices_temp[0];
        this.activeActivityIndex = indices_temp[1];

        // This gets CPU intensive overtime
        // Loop thro all phases and check if current activity is in wrong phase wrt to position
        // If so, move the activity to corresponding phase else reset activity position

        var active_index = 0;
        for (var i = 0; i < this.phases.length; i++) {
          if (this.phases[indices_temp[0]].activities[indices_temp[1]].x > 75 + i * (this.layoutParam.phase.width + 25) && this.phases[indices_temp[0]].activities[indices_temp[1]].x < 75 + i * (this.layoutParam.phase.width + 25) + this.layoutParam.phase.width) {
            active_index = i;
            // break
          }
        }
        // Go into switching activity only if its in a different phase
        if (active_index !== indices_temp[0]) {
          var activity = this.phases[indices_temp[0]].activities[indices_temp[1]];
          this.phases[indices_temp[0]].activities.splice(indices_temp[1], 1);
          this.phases[active_index].activities.push(activity);

          // Update latest index into active index
          this.activePhaseIndex = active_index;
          this.activeActivityIndex = this.phases[active_index].activities.length - 1;
        }
        this.renderActivityPosition();
      }
      if (deltaX === undefined || deltaY === undefined) {
        return;
      }
      var indices = el.id.replace('activity_', '').split('_');

      // Update active index, to prevent crash in computing progress
      this.activePhaseIndex = indices[0];
      this.activeActivityIndex = indices[1];
      this.phases[indices[0]].activities[indices[1]].x += parseInt(deltaX);
      this.phases[indices[0]].activities[indices[1]].y += parseInt(deltaY);

      // Check if activiy position inside another phase
      // if()
    },
    handleDrop: function handleDrop(event) {
      event.preventDefault();
      if (this.activeDrawingToolDragElement === 'notes') {
        this.note_form.x = event.offsetX;
        this.note_form.y = event.offsetY;
        this.addNoteDialog = true;
      } else if (this.activeDrawingToolDragElement === 'phase') {
        this.openAddPhaseDialog();
      } else if (this.activeDrawingToolDragElement === 'lane') {
        this.openAddLaneDialog();
      } else if (this.activeDrawingToolDragElement === 'task') {
        // Check if mouse drop insde task lane
        var task_y = event.offsetY;
        var task_x = event.offsetX;
        if (this.isMouseInsideTaskLane(task_x, task_y)) {
          // Get Phase index of mouse position
          var phase_index = this.isMouseInsidePhase(task_x, task_y);
          if (phase_index != null) {
            // console.log('Inside')
            this.openCreateTaskModal(phase_index, false);
          } else {
            this.$notify.error({
              title: 'Error',
              message: 'Drop Activity inside a phase to create Activity'
            });
          }
        } else {
          this.$notify.error({
            title: 'Error',
            message: 'Activity/Task can only be dropped inside Activity lane'
          });
        }
      } else if (this.activeDrawingToolDragElement === 'goal') {
        // Check if mouse drop insde goal lane
        var goal_y = event.offsetY;
        var goal_x = event.offsetX;
        if (this.isMouseInsideGoalLane(goal_x, goal_y)) {
          // Get Phase index of mouse position
          var phase_goal_index = this.isMouseInsidePhase(goal_x, goal_y);
          // Check if phase exists at mouse location
          if (phase_goal_index != null) {
            if (this.phases[phase_goal_index].activities.length > 0) {
              this.createGoalWithoutActivityIndex(phase_goal_index);
            } else {
              this.$notify.error({
                title: 'Error',
                message: 'No Activities found for selected phase. Goal needs to attach to an activity'
              });
            }
          } else {
            this.$notify.error({
              title: 'Error',
              message: 'Drop Goal inside a phase to create Goal'
            });
          }
        } else {
          this.$notify.error({
            title: 'Error',
            message: 'Goal can only be dropped inside Goal lane'
          });
        }
      } else if (this.activeDrawingToolDragElement === 'pain') {
        var pain_y = event.offsetY;
        var pain_x = event.offsetX;
        if (this.isMouseInsideExperienceLane(pain_x, pain_y)) {
          // Get Phase index of mouse position
          var phase_pain_index = this.isMouseInsidePhase(pain_x, pain_y);
          // Check if phase exists at mouse location
          if (phase_pain_index != null) {
            if (this.phases[phase_pain_index].activities.length > 0) {
              this.createExperienceWithoutActivityIndex();
            } else {
              this.$notify.error({
                title: 'Error',
                message: 'No Activities found for selected phase. Pain needs to attach to an activity'
              });
            }
          } else {
            this.$notify.error({
              title: 'Error',
              message: 'Drop Pain inside a phase to create Pain'
            });
          }
        } else {
          this.$notify.error({
            title: 'Error',
            message: 'Pain can only be dropped inside Experience lane'
          });
        }
      } else if (this.activeDrawingToolDragElement === 'gain') {
        var gain_y = event.offsetY;
        var gain_x = event.offsetX;
        if (this.isMouseInsideExperienceLane(gain_x, gain_y)) {
          // Get Phase index of mouse position
          var phase_gain_index = this.isMouseInsidePhase(gain_x, gain_y);
          // Check if phase exists at mouse location
          if (phase_gain_index != null) {
            if (this.phases[phase_gain_index].activities.length > 0) {
              this.createExperienceWithoutActivityIndex();
            } else {
              this.$notify.error({
                title: 'Error',
                message: 'No Activities found for selected phase. Gain needs to attach to an activity'
              });
            }
          } else {
            this.$notify.error({
              title: 'Error',
              message: 'Drop Gain inside a phase to create Gain'
            });
          }
        } else {
          this.$notify.error({
            title: 'Error',
            message: 'Gain can only be dropped inside Experience lane'
          });
        }
      }
    },
    handleDragOver: function handleDragOver(event) {
      event.preventDefault();
    },
    setActiveDrawingToolDragElement: function setActiveDrawingToolDragElement(element) {
      this.activeDrawingToolDragElement = element;
    },
    openNotesListDialog: function openNotesListDialog(type, index) {
      var _this = this;
      this.activeNotes = [];
      if (type === null) {
        this.activeNotes = this.notes;
      } else if (type === 'phase') {
        this.activeNotes = this.notes.filter(function (note) {
          return note.phase === _this.phases[index].name;
        });
      } else if (type === 'lane') {
        this.activeNotes = this.notes.filter(function (note) {
          return note.lane === _this.lanes[index].name;
        });
      }
      this.viewNotesDialog = true;
    },
    openAddLaneDialog: function openAddLaneDialog() {
      this.resetLaneForm();
      this.addLaneDialog = true;
    },
    openAddPhaseDialog: function openAddPhaseDialog() {
      this.resetPhaseForm();
      this.addPhaseDialog = true;
    },
    openEditLaneDialog: function openEditLaneDialog(lane_index) {
      this.isLaneEdit = true;
      this.lane_form = this.lanes[lane_index];
      this.addLaneDialog = true;
    },
    openEditPhaseDialog: function openEditPhaseDialog(phase_index) {
      this.isPhaseEdit = true;
      this.activePhaseIndex = phase_index;
      this.phase_form = this.phases[phase_index];
      this.addPhaseDialog = true;
    },
    generateNoteTitle: function generateNoteTitle() {
      return 'Add Note';
    },
    computeLaneTitle: function computeLaneTitle() {
      if (this.isLaneEdit) {
        return 'Edit Lane';
      } else {
        return 'Add Lane';
      }
    },
    computePhaseTitle: function computePhaseTitle() {
      if (this.isPhaseEdit) {
        return 'Edit Phase';
      } else {
        return 'Add Phase';
      }
    },
    computeTaskTitle: function computeTaskTitle() {
      if (this.isTaskModalEditMode) {
        return 'Edit Task';
      } else {
        return 'Add Task';
      }
    },
    computeGoalTitle: function computeGoalTitle() {
      if (this.isGoalModalEditMode) {
        return 'Edit Goal';
      } else {
        return 'Add Goal';
      }
    },
    computeProcessAreaTitle: function computeProcessAreaTitle() {
      if (this.isProcessAreaModalEditMode) {
        return 'Edit Process Area';
      } else {
        return 'Add Process Area';
      }
    },
    editPhase: function editPhase() {
      this.resetPhaseForm();
      this.addPhaseDialog = false;
    },
    editLane: function editLane() {
      this.resetLaneForm();
      this.addLaneDialog = false;
    },
    moveNoteOnDrag: function moveNoteOnDrag(x, y, index) {
      this.notes[index].x += x;
      this.notes[index].y += y;
    },
    openPhaseProgressDetailDialog: function openPhaseProgressDetailDialog(index) {
      this.activePhaseIndex = index;
      this.phaseProgressDialog = true;
    },
    addLane: function addLane() {
      this.isLaneEdit = false;
      var lane_form = Object.assign({}, this.lane_form);
      this.lanes.push(lane_form);
      this.resetLaneForm();
      this.addLaneDialog = false;
    },
    addPhase: function addPhase() {
      var phase_form = Object.assign({}, this.phase_form);
      this.phases.push(phase_form);
      this.resetPhaseForm();
      this.addPhaseDialog = false;
    },
    addNote: function addNote() {
      var note_form = Object.assign({}, this.note_form);
      this.notes.push(note_form);
      this.closeNoteDialog();
    },
    closeNoteDialog: function closeNoteDialog() {
      this.resetNoteForm();
      this.addNoteDialog = false;
    },
    deleteLane: function deleteLane(index) {
      this.lanes.splice(index, 1);
    },
    deletePhase: function deletePhase(index) {
      this.phases.splice(index, 1);
    },
    deleteNoteByIndex: function deleteNoteByIndex(index, row) {
      this.notes.splice(index, 1);
    },
    deleteActivity: function deleteActivity(phase_index, activity_index) {
      this.phases[phase_index].activities.splice(activity_index, 1);
    },
    deleteGoal: function deleteGoal(phase_index, activity_index, goal_index) {
      this.phases[phase_index].activities[activity_index].goals.splice(goal_index, 1);
    },
    deleteProcessArea: function deleteProcessArea(phase_index, activity_index, process_area_index) {
      this.phases[phase_index].activities[activity_index].process_areas.splice(process_area_index, 1);
    },
    deleteExperience: function deleteExperience(phase_index, activity_index, experience_index) {
      this.phases[phase_index].activities[activity_index].experiences.splice(experience_index, 1);
    },
    deleteFeature: function deleteFeature(phase_index, activity_index, feature_index) {
      this.phases[phase_index].activities[activity_index].features.splice(feature_index, 1);
    },
    isMouseInsideTaskLane: function isMouseInsideTaskLane(x, y) {
      if (x > 50 && x < 50 + this.computeLaneWidth() && y > 75 && y < 75 + this.layoutParam.lane.height) {
        return true;
      } else {
        return false;
      }
    },
    isMouseInsideGoalLane: function isMouseInsideGoalLane(x, y) {
      if (x > 50 && x < 50 + this.computeLaneWidth() && y > 75 + 1 * 175 && y < 75 + 1 * 175 + this.layoutParam.lane.height) {
        return true;
      } else {
        return false;
      }
    },
    isMouseInsideExperienceLane: function isMouseInsideExperienceLane(x, y) {
      if (x > 50 && x < 50 + this.computeLaneWidth() && y > 75 + 2 * 175 && y < 75 + 2 * 175 + this.layoutParam.lane.height) {
        return true;
      } else {
        return false;
      }
    },
    isMouseInsideProcessAreaLane: function isMouseInsideProcessAreaLane(x, y) {
      if (x > 50 && x < 50 + this.computeLaneWidth() && y > 75 + 3 * 175 && y < 75 + 3 * 175 + this.layoutParam.lane.height) {
        return true;
      } else {
        return false;
      }
    },
    isMouseInsideFeatureLane: function isMouseInsideFeatureLane(x, y) {
      if (x > 50 && x < 50 + this.computeLaneWidth() && y > 75 + 4 * 175 && y < 75 + 4 * 175 + this.layoutParam.lane.height) {
        return true;
      } else {
        return false;
      }
    },
    isMouseInsidePhase: function isMouseInsidePhase(x, y) {
      for (var i = 0; i < this.phases.length; i++) {
        if (x > 75 + i * (this.layoutParam.phase.width + 25) && x < 75 + i * (this.layoutParam.phase.width + 25) + this.layoutParam.phase.width && y > 55 && y < 55 + this.computePhaseHeight()) {
          return i;
        }
      }
      return null;
    },
    resetNoteForm: function resetNoteForm() {
      this.note_form = {
        note: '',
        color: '#FFF59D',
        phase: '',
        lane: '',
        x: 0,
        y: 0
      };
    },
    resetLaneForm: function resetLaneForm() {
      this.lane_form = {
        name: '',
        description: '',
        color: 'gray',
        default: false
      };
    },
    resetPhaseForm: function resetPhaseForm() {
      this.phase_form = {
        name: '',
        description: '',
        goal: '',
        challenges: '',
        emotions: '',
        priority: '',
        activities: []
      };
    },
    resetGoalForm: function resetGoalForm() {
      this.goal_form = {
        name: '',
        description: '',
        status: ''
      };
    },
    resetTaskForm: function resetTaskForm() {
      this.task_form = {
        x: 0,
        y: 0,
        task: '',
        intention: '',
        goals: [],
        experiences: [],
        process_areas: [],
        features: []
      };
    },
    resetProcessAreaForm: function resetProcessAreaForm() {
      this.process_area_form = {
        name: '',
        description: '',
        status: ''
      };
    },
    resetExperienceForm: function resetExperienceForm() {
      this.experience_form = {
        name: '',
        description: '',
        priority: '',
        type: '',
        status: ''
      };
    },
    resetFeatureForm: function resetFeatureForm() {
      this.feature_form = {
        name: '',
        description: '',
        experience_type: 'pain',
        experience_name: '',
        status: ''
      };
    }
  }
};