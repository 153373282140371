"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.number.constructor");
var _PieChart = _interopRequireDefault(require("./PieChart"));
var _LineChart = _interopRequireDefault(require("./LineChart"));
var _default2 = exports.default = {
  name: 'SegmentFinancials',
  components: {
    PieChartComponent: _PieChart.default,
    LineChartComponent: _LineChart.default
  },
  props: {
    activeSegment: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    activeSegmentIndex: {
      type: Number,
      default: 0
    }
  },
  data: function data() {
    return {
      loading: false,
      countries: [{
        code: 'AF',
        name: 'Africa'
      }, {
        code: 'NA',
        name: 'North Asia'
      }, {
        code: 'SEA',
        name: 'South East Asia'
      }, {
        code: 'CR',
        name: 'Caribbean'
      }, {
        code: 'CA',
        name: 'Central America'
      }, {
        code: 'NoA',
        name: 'North America'
      }, {
        code: 'SA',
        name: 'South America'
      }, {
        code: 'ME',
        name: 'Middle East'
      }, {
        code: 'EU',
        name: 'Europe'
      }, {
        code: 'OA',
        name: 'Australia & Oceania'
      }]
    };
  },
  watch: {
    'activeSegment.numCustomers': function activeSegmentNumCustomers(newVal, oldVal) {
      // watch it
      this.recomputeValues();
    },
    'activeSegment.desiredMarketPercent': function activeSegmentDesiredMarketPercent(newVal, oldVal) {
      // watch it
      this.recomputeValues();
    },
    'activeSegment.avgCustValue': function activeSegmentAvgCustValue(newVal, oldVal) {
      // watch it
      this.recomputeValues();
    },
    'activeSegment.annualLicenseRevenuePC': function activeSegmentAnnualLicenseRevenuePC(newVal, oldVal) {
      this.recomputeValues();
    },
    'activeSegment.serviceFee': function activeSegmentServiceFee(newVal, oldVal) {
      // watch it
      this.recomputeValues();
    },
    'activeSegment.annualSupportRevenueTotal': function activeSegmentAnnualSupportRevenueTotal(newVal, oldVal) {
      // watch it
      this.recomputeValues();
    },
    'activeSegment.project': function activeSegmentProject(newVal, oldVal) {
      // watch it
      this.recomputeValues();
    }
  },
  mounted: function mounted() {
    this.$eventHub.$emit('process-step-load', 'define-revenue-types');
  },
  methods: {
    getCountryFromCode: function getCountryFromCode(country_code) {
      var country_name = '';
      window._.forEach(this.countries, function (value) {
        if (value.code === country_code) {
          country_name = value.name;
        }
      });
      return country_name;
    },
    recomputeValues: function recomputeValues() {
      this.activeSegment.numTargetCustomers = this.activeSegment.numCustomers * (this.activeSegment.desiredMarketPercent / 100);
      this.activeSegment.annualLicenseRevenueTotal = this.activeSegment.annualLicenseRevenuePC * this.activeSegment.numTargetCustomers;
      this.activeSegment.annualSupportRevenueTotal = this.activeSegment.serviceFee * this.activeSegment.numTargetCustomers;
      this.activeSegment.project = this.activeSegment.newCustomersPerMonth * this.activeSegment.annualProjectService;
      this.activeSegment.annualSegmentValue = this.activeSegment.project + this.activeSegment.annualSupportRevenueTotal + this.activeSegment.annualLicenseRevenueTotal;
      this.activeSegment.avgCustValue = this.activeSegment.annualLicenseRevenuePC + this.activeSegment.annualProjectService + this.activeSegment.serviceFee;
    },
    onClickDefineSegments: function onClickDefineSegments() {
      this.$emit('go-prev');
    },
    onClickDefineCustomerType: function onClickDefineCustomerType() {
      this.$emit('go-next');
    }
  }
};