"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.match");
var _teamsJs = require("@microsoft/teams-js");
var _validate = require("@/utils/validate");
var _utils = require("@/utils");
var _axios = _interopRequireDefault(require("axios"));
var _default = exports.default = {
  name: 'Register',
  data: function data() {
    var _this = this;
    var validateCity = function validateCity(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter the City'));
      } else {
        callback();
      }
    };
    var validateState = function validateState(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter the State'));
      } else {
        callback();
      }
    };
    var validateCountry = function validateCountry(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter the Country'));
      } else {
        callback();
      }
    };
    var validateFirstName = function validateFirstName(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter your First Name'));
      } else {
        callback();
      }
    };
    var validateLastName = function validateLastName(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter your Last Name'));
      } else {
        callback();
      }
    };
    var validateBusinessJobType = function validateBusinessJobType(rule, value, callback) {
      if (!value) {
        callback(new Error('Please select a Job Type'));
      } else {
        callback();
      }
    };
    var validateBusinessName = function validateBusinessName(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter a Business Name'));
      } else {
        callback();
      }
    };
    var validateBusinessDomain = function validateBusinessDomain(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter the Business Domain'));
      } else {
        callback();
      }
    };
    var validateEmail = function validateEmail(rule, value, callback) {
      if (!(0, _validate.validEmail)(value)) {
        callback(new Error('Please enter a valid email address'));
      } else {
        callback();
      }
    };
    var validateMobileNumber = function validateMobileNumber(rule, value, callback) {
      if (!(0, _validate.validPhoneNumber)(value)) {
        callback(new Error('Please enter a valid Mobile Number'));
      } else {
        callback();
      }
    };
    var validateJIRAURL = function validateJIRAURL(rule, value, callback) {
      if (!(0, _validate.validURL)(value)) {
        callback(new Error('Please enter a valid URL'));
      } else {
        callback();
      }
    };
    var validateJRIAConsumerKey = function validateJRIAConsumerKey(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter a valid Key'));
      } else {
        callback();
      }
    };
    var validatePassword = function validatePassword(rule, value, callback) {
      if (value === '') {
        callback(new Error('Please enter a password'));
      } else if (value.length < 8) {
        callback(new Error('The password can not be less than 8 digits'));
      } else if (!value.match(/[a-z]/) || !value.match(/[A-Z]/)) {
        callback(new Error('The password should have atleast one Uppercase and Lowercase letter'));
      } else if (!value.match(/\d/)) {
        callback(new Error('The password should have atleast one number'));
      } else {
        callback();
      }
    };
    var validateConfirmPassword = function validateConfirmPassword(rule, value, callback) {
      if (value === '') {
        callback(new Error('Please enter a password'));
      } else if (value !== _this.onboarding.user.password) {
        callback(new Error('The passwords do not match. Please re-enter password'));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      new_invite_loading: false,
      add_on_loading: false,
      inviteComplete: false,
      offering_type: null,
      invite_type: 'party',
      user_type: '',
      existing_company: {
        domain: ''
      },
      passwordRulesPopover: false,
      passwordRules: [{
        valid: false,
        rule: '8 or more characters'
      }, {
        valid: false,
        rule: 'Upper and lower case letters'
      }, {
        valid: false,
        rule: 'Numbers'
      }],
      activeStep: 0,
      jira_active_step: 0,
      integrationDialog: false,
      integrationDialogDefault: false,
      activeIntegration: {},
      roles: [{
        value: 'Product Manager',
        label: 'Product Manager'
      }, {
        value: 'Project Manager',
        label: 'Project Manager'
      }, {
        value: 'Scrum Master',
        label: 'Scrum Master'
      }, {
        value: 'Developer',
        label: 'Developer'
      }, {
        value: 'UI Designer',
        label: 'UI Designer'
      }, {
        value: 'Engineering Manager',
        label: 'Engineering Manager'
      }, {
        value: 'Business Analyst',
        label: 'Business Analyst'
      }, {
        value: 'Solution Architect',
        label: 'Solution Architect'
      }],
      invites: [{
        first_name: '',
        middle_name: '',
        last_name: '',
        email: '',
        invite_sent: false,
        loading: false
      }, {
        first_name: '',
        middle_name: '',
        last_name: '',
        email: '',
        invite_sent: false,
        loading: false
      }, {
        first_name: '',
        middle_name: '',
        last_name: '',
        email: '',
        invite_sent: false,
        loading: false
      }, {
        first_name: '',
        middle_name: '',
        last_name: '',
        email: '',
        invite_sent: false,
        loading: false
      }, {
        first_name: '',
        middle_name: '',
        last_name: '',
        email: '',
        invite_sent: false,
        loading: false
      }],
      integrationSearch: '',
      onboarding: {
        user: {
          id: '',
          firstname: '',
          middlename: '',
          lastname: '',
          mobileNumber: '',
          emailAddress: '',
          password: '',
          confirmPassword: '',
          businessRole: ''
        },
        organisation: {
          id: '',
          businessName: '',
          tradingName: '',
          legalName: '',
          businessId1: {
            name: '',
            value: ''
          },
          businessId2: {
            name: '',
            value: ''
          },
          businessId3: {
            name: '',
            value: ''
          },
          primaryPartyContactId: '',
          secondaryPartyContactId: '',
          address: '',
          suburb: '',
          city: '',
          state: '',
          postCode: '',
          country: ''
        },
        offering: null
      },
      addOnModules: [
        /*        {
          title: 'My Business',
          description: 'Organise and manage business goals, actions and risks.',
          slug: 'business',
          image: 'improvement',
          charge: '$30',
          recurring: true,
          recurring_frequency: 'month',
          charge_per_user: false,
          subscribed: false
        },
        {
          title: 'Ideas',
          description: 'Record and rank new business ideas.',
          slug: 'ideas',
          image: 'ideation',
          charge: '$20',
          recurring: true,
          recurring_frequency: 'month',
          charge_per_user: false,
          subscribed: false
        },
        {
          title: 'Customers & Offerings',
          description: 'Create and manage customer value using using journeys and offerings.',
          slug: 'customer_offering',
          image: 'peoples',
          charge: '$20',
          recurring: true,
          recurring_frequency: 'month',
          charge_per_user: true,
          subscribed: false
        },
        {
          title: 'Portfolio Manager',
          description: 'Simplify product strategy, design, and engineering.',
          slug: 'portfolio_manager',
          image: 'example',
          charge: '$30',
          recurring: true,
          recurring_frequency: 'month',
          charge_per_user: false,
          subscribed: false
        }*/
      ],
      JIRAIntegrationform: {
        jiraHome: '',
        consumerKey: ''
      },
      JIRAIntegrationResponse: {
        oauthtoken: '',
        tokenSecret: '',
        code: ''
      },
      JIRATesting: false,
      integrationType: ['Ticketing', 'Support Managment', 'UI Design'],
      integrations: [{
        type: 'Ticketing',
        name: 'JIRA',
        description: '',
        image_url: 'static/img/jira.png',
        integrated: false
      }
      /* {
        type: 'Ticketing',
        name: 'ZenDesk',
        description: 'Lorem Ipsum',
        image_url: 'static/img/zendesk.png',
        integrated: false
      },
      {
        type: 'Support Managment',
        name: 'OpsGenie',
        description: 'Lorem Ipsum',
        image_url: 'static/img/opsgenie.png',
        integrated: false
      },
      {
        type: 'Support Managment',
        name: 'Support Suite',
        description: 'Lorem Ipsum',
        image_url: 'static/img/support_suite.png',
        integrated: false
      },
      {
        type: 'UI Design',
        name: 'Balsamiq',
        description: 'Lorem Ipsum',
        image_url: 'static/img/balsamiq.png',
        integrated: false
      },
      {
        type: 'UI Design',
        name: 'InVision Prototype',
        description: 'Lorem Ipsum',
        image_url: 'static/img/prototype.png',
        integrated: false
      }*/],
      userSignUpRules: {
        firstname: [{
          required: true,
          trigger: 'none',
          validator: validateFirstName
        }],
        lastname: [{
          required: true,
          trigger: 'none',
          validator: validateLastName
        }],
        businessRole: [{
          required: true,
          trigger: 'none',
          validator: validateBusinessJobType
        }],
        emailAddress: [{
          required: true,
          trigger: 'none',
          validator: validateEmail
        }],
        mobileNumber: [{
          required: true,
          trigger: 'none',
          validator: validateMobileNumber
        }],
        password: [{
          required: true,
          trigger: 'none',
          validator: validatePassword
        }],
        confirmPassword: [{
          required: true,
          trigger: 'none',
          validator: validateConfirmPassword
        }]
      },
      organisationSignUpRules: {
        businessName: [{
          required: true,
          trigger: 'none',
          validator: validateBusinessName
        }],
        primaryPartyContactId: [{
          required: true,
          trigger: 'none',
          validator: validateEmail
        }],
        city: [{
          required: true,
          trigger: 'none',
          validator: validateCity
        }],
        state: [{
          required: true,
          trigger: 'none',
          validator: validateState
        }],
        country: [{
          required: true,
          trigger: 'none',
          validator: validateCountry
        }]
      },
      existingOrganisationSignUpRules: {
        domain: [{
          required: true,
          trigger: 'none',
          validator: validateBusinessDomain
        }]
      },
      thirdPartyIntegrationRules: {
        jiraHome: [{
          required: true,
          trigger: 'none',
          validator: validateJIRAURL
        }],
        consumerKey: [{
          required: true,
          trigger: 'none',
          validator: validateJRIAConsumerKey
        }]
      }
    };
  },
  computed: {
    filteredIntegrations: function filteredIntegrations() {
      var self = this;
      return this.integrations.filter(function (integration) {
        if (self.integrationType.indexOf(integration.type) > -1) {
          if (self.integrationSearch === '') {
            return integration;
          } else {
            if (integration.name.toLowerCase().indexOf(self.integrationSearch.toLowerCase()) > -1) {
              return integration;
            }
          }
        }
      });
    },
    country: function country() {
      return this.onboarding.organisation.country;
    },
    activeEnv: function activeEnv() {
      if (process.env.VUE_APP_ENV !== 'production') {
        return process.env.VUE_APP_ENV.toUpperCase();
      } else {
        return '';
      }
    }
  },
  watch: {
    /* activeStep: function(newVal, oldVal) {
      if (newVal === 2) {
        this.offering_type = null
      }
    },*/
    'onboarding.user.password': function onboardingUserPassword(newValue, oldValue) {
      if (newValue.length > 7) {
        this.passwordRules[0].valid = true;
      } else {
        this.passwordRules[0].valid = false;
      }
      if (newValue.match(/[a-z]/) && newValue.match(/[A-Z]/)) {
        this.passwordRules[1].valid = true;
      } else {
        this.passwordRules[1].valid = false;
      }
      if (newValue.match(/\d/)) {
        this.passwordRules[2].valid = true;
      } else {
        this.passwordRules[2].valid = false;
      }
    }
  },
  created: function created() {},
  mounted: function mounted() {
    var _this2 = this;
    if (this.$route.query.create_business) {
      this.$message({
        message: 'Business details are missing.',
        type: 'info',
        center: true,
        showClose: true
      });
      this.user_type = 'new';
      this.onboarding.user.id = this.$route.query.user_id;
      this.activeStep = 1;
    } else if (!this.$route.query.invite_hash) {
      this.$confirm('Do you want to create a new Company, or be invited to an existing one?', 'Existing Company?', {
        confirmButtonText: 'Request Invitation',
        cancelButtonText: 'New Account',
        cancelButtonClass: 'test_onboarding_messagebox_cancel',
        confirmButtonClass: 'test_onboarding_messagebox_confirm',
        type: 'info'
      }).then(function () {
        // - Remove Start
        _this2.user_type = 'existing';
        /* this.$prompt('If your company already has an account, request access for company administrator.  If you know you’re company’s url, (mycompany.prodiggi.com”) simply navigate to it and look for the Invite button.”', 'Request Invite', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel'
        }).then(({ value }) => {
          this.$message({
            type: 'success',
            message: 'Your email is:' + value
          })
        }).catch(() => {
          this.goToLoginPage()
        })*/

        // Remove End
      }).catch(function () {
        _this2.user_type = 'new';
      });
    } else {
      this.user_type = 'new';
    }
    var headers = {
      'Content-Type': 'application/json',
      'x-user-id': 'userid-002',
      'x-party-id': '402880b273f49e830173f4af11d60000'
    };
    _axios.default.get(process.env.VUE_APP_REST_API_ENDPOINT + 'plan', {
      headers: headers
    }).then(function (response) {
      _this2.add_on_loading = false;
      _this2.addOnModules = response.data.data;
      for (var i = 0; i < _this2.addOnModules.length; i++) {
        _this2.addOnModules[i].subscribed = false;
      }
    }).catch(function (error) {
      _this2.add_on_loading = false;
      _this2.$notify({
        title: 'Error',
        message: error.response.data.data.description,
        type: 'error',
        duration: 2000
      });
    });
    _axios.default.get('http://ip-api.com/json').then(function (response) {
      _this2.onboarding.organisation.country = response.data.country;
    }).catch(function (error) {
      // handle error
      console.log(error);
    });
    this.onboarding.user.firstname = this.$route.query.first_name;
    this.onboarding.user.lastname = this.$route.query.last_name;
    this.onboarding.user.emailAddress = this.$route.query.email;

    // Check if invited
    if (this.$route.query.invite_hash) {
      this.invite_type = 'user';
    }
    if (this.$route.query.organisation) {
      this.onboarding.organisation.businessName = this.$route.query.organisation;
      this.invite_type = 'party';
    }
    if (this.$route.query.department) {
      this.invite_type = 'user';
    }
    _teamsJs.app.getContext().then(function (context) {
      if (context) {
        _this2.onboarding.user.emailAddress = context.user.userPrincipalName;
      }
    });
  },
  destroyed: function destroyed() {},
  methods: {
    goToStep: function goToStep(step) {
      if (process.env.VUE_APP_ENV === 'development') {
        this.activeStep = step;
      }
    },
    computeSelectedOfferingCardStyle: function computeSelectedOfferingCardStyle(type) {
      if (type === 'new') {
        if (this.onboarding.offering === 'new') {
          return 'module-header integration_apps module-selected';
        } else {
          return 'module-header integration_apps';
        }
      }
      if (type === 'import') {
        if (this.onboarding.offering === 'existing') {
          return 'module-header integration_apps module-selected';
        } else {
          return 'module-header integration_apps';
        }
      }
    },
    allPasswordRulesPassed: function allPasswordRulesPassed() {
      return this.passwordRules[0].valid && this.passwordRules[1].valid && this.passwordRules[2].valid;
    },
    importOFfering: function importOFfering() {
      this.offering_type = 'existing';
      this.onboarding.offering = 'existing';
    },
    createNewOffering: function createNewOffering() {
      this.activeStep = 3;
      this.offering_type = 'new';
      this.onboarding.offering = 'new';
    },
    deleteInvite: function deleteInvite(index) {
      if (this.invites[index].id) {
        this.invites[index].loading = true;
        var body = {
          'header': {
            'userId': this.onboarding.user.id,
            'partyId': this.onboarding.organisation.id
          }
        };
        var that = this;
        var xhr = new XMLHttpRequest();
        xhr.open('DELETE', process.env.VUE_APP_REST_API_ENDPOINT + 'user/invite/' + this.invites[index].id, true);

        // Send the proper header information along with the request
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.send(JSON.stringify(body));
        xhr.onreadystatechange = function () {
          // Call a function when the state changes.
          if (this.readyState === XMLHttpRequest.DONE) {
            if (this.status === 200) {
              // Request finished. Do processing here.
              that.$notify({
                title: 'Success',
                message: 'Invite deleted succesfully',
                type: 'success',
                duration: 2000
              });
              that.invites[index].loading = false;
              that.invites.splice(index, 1);
            } else {
              that.$notify({
                title: 'Error',
                message: 'Invite delete failed',
                type: 'error',
                duration: 2000
              });
            }
          }
        };
        xhr.onload = function () {};
      } else {
        this.invites.splice(index, 1);
      }
    },
    addNewInvite: function addNewInvite() {
      this.new_invite_loading = true;
      var invite = {
        first_name: '',
        middle_name: '',
        last_name: '',
        email: '',
        invite_sent: false,
        loading: false
      };
      this.invites.push(Object.assign({}, invite));
      this.invites.push(Object.assign({}, invite));
      this.invites.push(Object.assign({}, invite));
      this.new_invite_loading = false;
    },
    capitalizeFirstChar: function capitalizeFirstChar(frequency_string) {
      return (0, _utils.capitalize)(frequency_string);
    },
    computeModuleHeaderClass: function computeModuleHeaderClass(module) {
      switch (module.slug) {
        case 'business':
          if (module.subscribed) {
            return 'module-header color-business color_business_selected';
          } else {
            return 'module-header color-business';
          }
        case 'ideas':
          if (module.subscribed) {
            return 'module-header color-ideas color_ideas_selected';
          } else {
            return 'module-header color-ideas';
          }
        case 'customer_offering':
          if (module.subscribed) {
            return 'module-header color-customer color_customer_selected';
          } else {
            return 'module-header color-customer';
          }
        case 'product_portfolio':
          if (module.subscribed) {
            return 'module-header color-product color_product_selected';
          } else {
            return 'module-header color-product';
          }
      }
    },
    computeModuleBodyClass: function computeModuleBodyClass(module_slug) {
      switch (module_slug) {
        case 'business':
          return 'module-body business-bgnd';
        case 'ideas':
          return 'module-body ideas-bgnd';
        case 'customer_offering':
          return 'module-body customer-bgnd';
        case 'product_portfolio':
          return 'module-body product-bgnd-bgnd';
      }
    },
    inviteUsers: function inviteUsers() {
      this.loading = true;
      for (var i = 0; i < this.invites.length; i++) {
        this.inviteUser(i);
      }
    },
    resendInvite: function resendInvite(index) {
      var _this3 = this;
      this.invites[index].loading = true;
      var headers = {
        'Content-Type': 'application/json'
      };
      var body = {
        data: {
          partyId: this.onboarding.organisation.id
        },
        header: {
          'userId': this.onboarding.user.id,
          'partyId': this.onboarding.organisation.id
        }
      };
      _axios.default.patch(process.env.VUE_APP_REST_API_ENDPOINT + 'user/invite/' + this.invites[index].id + '/resend', body, {
        headers: headers
      }).then(function (response) {
        _this3.invites[index].loading = false;
        if (response.data.data.status === 'SUCCESS') {
          _this3.$notify({
            title: 'Success',
            message: response.data.data.description,
            type: 'success',
            duration: 2000
          });
        } else {
          _this3.$notify({
            title: 'Error',
            message: response.data.data.description,
            type: 'error',
            duration: 2000
          });
        }
      }).catch(function (error) {
        _this3.invites[index].loading = false;
        _this3.$notify({
          title: 'Error',
          message: error.response.data.data.description,
          type: 'error',
          duration: 2000
        });
      });
    },
    inviteUser: function inviteUser(index) {
      var _this4 = this;
      if (this.invites[index].first_name === '' || this.invites[index].email === '') {
        this.$notify({
          title: 'Error',
          message: 'First name and email are required to send invites to user',
          type: 'error',
          duration: 2000
        });
      } else {
        this.invites[index].loading = true;
        var headers = {
          'Content-Type': 'application/json'
        };
        var body = {
          'data': [{
            'firstName': this.invites[index].first_name,
            // 'middleName': this.invites[index].middle_name,
            'lastName': this.invites[index].last_name,
            'emailAddress': this.invites[index].email
          }],
          'header': {
            'userId': this.onboarding.user.id,
            'partyId': this.onboarding.organisation.id
          }
        };
        _axios.default.post(process.env.VUE_APP_REST_API_ENDPOINT + 'user/invite', body, {
          headers: headers
        }).then(function (response) {
          _this4.invites[index].loading = false;
          _this4.invites[index].invite_sent = true;
          if (response.data.status === 'SUCCESS') {
            _this4.invites[index].id = response.data.data.id;
            _this4.$notify({
              title: 'Success',
              message: 'Invite sent to ' + _this4.invites[index].email + ' succesfully',
              type: 'success',
              duration: 2000
            });
          } else {
            _this4.$notify({
              title: 'Error',
              message: response.data.data.description,
              type: 'error',
              duration: 2000
            });
          }
        }).catch(function (error) {
          _this4.invites[index].loading = false;
          _this4.$notify({
            title: 'Error',
            message: error.response.data.data.description,
            type: 'error',
            duration: 2000
          });
        });
      }
    },
    updateOrganization: function updateOrganization() {
      var _this5 = this;
      this.loading = true;
      var headers = {
        'Content-Type': 'application/json'
      };
      var body = {
        data: [{
          businessName: this.onboarding.organisation.businessName,
          tradingName: this.onboarding.organisation.tradingName,
          legalName: this.onboarding.organisation.legalName,
          businessId1: {
            name: '',
            value: ''
          },
          businessId2: {
            name: '',
            value: ''
          },
          businessId3: {
            name: '',
            value: ''
          },
          primaryPartyContactId: this.onboarding.organisation.primaryPartyContactId,
          secondaryPartyContactId: this.onboarding.organisation.secondaryPartyContactId,
          address: this.onboarding.organisation.address,
          suburb: this.onboarding.organisation.suburb,
          city: this.onboarding.organisation.city,
          state: this.onboarding.organisation.state,
          postCode: this.onboarding.organisation.postCode,
          country: this.onboarding.organisation.country
        }],
        header: {
          userId: this.onboarding.user.id
        }
      };
      _axios.default.post(process.env.VUE_APP_REST_API_ENDPOINT + 'party/' + this.onboarding.organisation.id, body, {
        headers: headers
      }).then(function (response) {
        _this5.loading = false;
        if (response.data.data.status === 'SUCCESS') {
          _this5.activeStep = 2;
        } else {
          _this5.$notify({
            title: 'Error',
            message: 'Oops Error',
            type: 'error',
            duration: 2000
          });
        }
      }).catch(function (error) {
        _this5.loading = false;
        _this5.$notify({
          title: 'Error',
          message: error.response.data.data.description,
          type: 'error',
          duration: 2000
        });
      });
    },
    createOrganization: function createOrganization() {
      var _this6 = this;
      this.loading = true;
      var headers = {
        'Content-Type': 'application/json'
      };
      var body = {
        data: [{
          businessName: this.onboarding.organisation.businessName,
          tradingName: this.onboarding.organisation.tradingName,
          legalName: this.onboarding.organisation.legalName,
          businessId1: {
            name: '',
            value: ''
          },
          businessId2: {
            name: '',
            value: ''
          },
          businessId3: {
            name: '',
            value: ''
          },
          primaryPartyContactId: this.onboarding.organisation.primaryPartyContactId,
          secondaryPartyContactId: this.onboarding.organisation.secondaryPartyContactId,
          address: this.onboarding.organisation.address,
          suburb: this.onboarding.organisation.suburb,
          city: this.onboarding.organisation.city,
          state: this.onboarding.organisation.state,
          postCode: this.onboarding.organisation.postCode,
          country: this.onboarding.organisation.country
        }],
        header: {
          userId: this.onboarding.user.id
        }
      };
      _axios.default.post(process.env.VUE_APP_REST_API_ENDPOINT + 'party', body, {
        headers: headers
      }).then(function (response) {
        _this6.loading = false;
        _this6.response = response.data;
        if (response.data.data.status === 'SUCCESS') {
          _this6.$notify({
            title: 'Party created',
            message: 'Party created succesfully',
            type: 'success',
            duration: 2000
          });
        }
        _this6.activeStep = 2;
        _this6.onboarding.organisation.id = response.data.data.id;
        _this6.linkUserToParty();
        _this6.createPartyRoles();
      }).catch(function (error) {
        _this6.loading = false;
        _this6.$notify({
          title: 'Error',
          message: error.response.data.data.description,
          type: 'error',
          duration: 2000
        });
      });
    },
    linkUserToPartyWithID: function linkUserToPartyWithID() {
      var _this7 = this;
      this.loading = true;
      var headers = {
        'Content-Type': 'application/json'
      };
      var body = {
        data: {
          partyId: this.$route.query.organisationId
        },
        header: {
          userId: this.onboarding.user.id,
          partyId: this.$route.query.organisationId
        }
      };
      _axios.default.patch(process.env.VUE_APP_REST_API_ENDPOINT + 'user/' + this.onboarding.user.id, body, {
        headers: headers
      }).then(function (response) {
        _this7.loading = false;
        if (response.data.data.status === 'SUCCESS') {
          _this7.$router.push('login');
        } else {
          _this7.$router.push('login');
        }
      }).catch(function (error) {
        _this7.loading = false;
        _this7.$notify({
          title: 'Error',
          message: error.response.data.data.description,
          type: 'error',
          duration: 2000
        });
      });
    },
    linkUserToParty: function linkUserToParty() {
      var _this8 = this;
      this.loading = true;
      var headers = {
        'Content-Type': 'application/json'
      };
      var body = {
        data: {
          partyId: this.onboarding.organisation.id
        },
        header: {
          userId: this.onboarding.user.id,
          partyId: this.onboarding.organisation.id
        }
      };
      _axios.default.patch(process.env.VUE_APP_REST_API_ENDPOINT + 'user/' + this.onboarding.user.id, body, {
        headers: headers
      }).then(function (response) {
        _this8.loading = false;
        if (response.data.data.status === 'SUCCESS') {
          _this8.$notify({
            title: 'Success',
            message: response.data.data.description,
            type: 'success',
            duration: 2000
          });
        } else {
          _this8.$notify({
            title: 'Error',
            message: response.data.data.description,
            type: 'error',
            duration: 2000
          });
        }
      }).catch(function (error) {
        _this8.loading = false;
        _this8.$notify({
          title: 'Error',
          message: error.response.data.data.description,
          type: 'error',
          duration: 2000
        });
      });
    },
    createPartyRoles: function createPartyRoles() {
      var _this9 = this;
      this.loading = true;
      var headers = {
        'Content-Type': 'application/json'
      };
      var body = {
        data: [{
          title: 'Admin',
          description: 'Product Admin. Full Read write access and access to user and party details'
        }, {
          title: 'Manager',
          description: 'Full Read write access.'
        }, {
          title: 'Guest',
          description: 'Read only Access'
        }],
        header: {
          userId: this.onboarding.user.id,
          partyId: this.onboarding.organisation.id
        }
      };
      _axios.default.post(process.env.VUE_APP_REST_API_ENDPOINT + 'security/role', body, {
        headers: headers
      }).then(function (response) {
        _this9.loading = false;
        _this9.linkUserToPartyRole(response.data.data[0].id);
      }).catch(function (error) {
        _this9.loading = false;
        console.log(error);
      });
      this.createRoleDialog = false;
    },
    linkUserToPartyRole: function linkUserToPartyRole(role_id) {
      var _this10 = this;
      this.loading = true;
      var headers = {
        'Content-Type': 'application/json'
      };
      var body = {
        'data': [{
          id: role_id
        }],
        'header': {
          userId: this.onboarding.user.id,
          partyId: this.onboarding.organisation.id
        }
      };
      _axios.default.patch(process.env.VUE_APP_REST_API_ENDPOINT + 'user/' + this.onboarding.user.id + '/roles', body, {
        headers: headers
      }).then(function (response) {
        _this10.loading = false;
      }).catch(function (error) {
        _this10.loading = false;
        console.log(error);
      });
    },
    processOrganizationUpdate: function processOrganizationUpdate() {
      var _this11 = this;
      this.$refs.organisationSignUpForm.validate(function (valid) {
        if (valid) {
          if (_this11.onboarding.organisation.id !== '') {
            _this11.updateOrganization();
          } else {
            _this11.createOrganization();
          }
        }
      });
    },
    updateUser: function updateUser() {
      var _this12 = this;
      this.loading = true;
      var headers = {
        'Content-Type': 'application/json'
      };
      var body = {
        data: {
          firstName: this.onboarding.user.firstname,
          // middleName: this.onboarding.user.middlename,
          lastName: this.onboarding.user.lastname,
          emailAddress: this.onboarding.user.emailAddress,
          businessRole: this.onboarding.user.businessRole,
          mobileNumber: this.onboarding.user.mobileNumber,
          password: this.onboarding.user.password
        },
        header: {
          userId: this.onboarding.user.id
        }
      };
      _axios.default.patch(process.env.VUE_APP_REST_API_ENDPOINT + 'user/' + this.onboarding.user.id, body, {
        headers: headers
      }).then(function (response) {
        _this12.loading = false;
        if (response.data.data.status === 'SUCCESS') {
          if (_this12.invite_type === 'party') {
            _this12.activeStep = 1;
          } else {
            _this12.goToDashboard();
          }
        } else {
          _this12.$notify({
            title: 'Error',
            message: 'Oops Error',
            type: 'error',
            duration: 2000
          });
        }
      }).catch(function (error) {
        _this12.loading = false;
        _this12.$notify({
          title: 'Error',
          message: error.response.data.data.description,
          type: 'error',
          duration: 2000
        });
      });
    },
    createUser: function createUser() {
      var _this13 = this;
      this.loading = true;
      var headers = {
        'Content-Type': 'application/json',
        'x-user-id': 'default-001'
      };
      var body = {
        data: {
          firstName: this.onboarding.user.firstname,
          // middleName: this.onboarding.user.middlename,
          lastName: this.onboarding.user.lastname,
          emailAddress: this.onboarding.user.emailAddress,
          businessRole: this.onboarding.user.businessRole,
          mobileNumber: this.onboarding.user.mobileNumber,
          password: this.onboarding.user.password,
          additionalAttributes: {
            modules: ['Customer Needs', 'Offering', 'Innovation Hub', 'Business Strategy', 'Portfolio Manager', /* 'Risk Managment',*/'Digital Portfolio', 'Administration', 'Report', 'Portfolio Manager', 'Advanced Delivery']
          }
        },
        header: {
          userId: 'default-001'
        }
      };

      // If user created via invite URL. send invite hash along with create user link
      var endpoint = process.env.VUE_APP_REST_API_ENDPOINT + 'user';
      if (this.$route.query.invite_hash) {
        endpoint = endpoint + '?inviteCode=' + this.$route.query.invite_hash;
      }
      _axios.default.post(endpoint, body, {
        headers: headers
      }).then(function (response) {
        _this13.loading = false;
        _this13.response = response.data;
        if (response.data.data.status === 'SUCCESS') {
          _this13.$notify({
            title: 'User created',
            message: 'User created succesfully',
            type: 'success',
            duration: 2000
          });
          _this13.onboarding.user.id = response.data.data.id;
          if (_this13.$route.query.invite_hash && _this13.$route.query.organisationId) {
            _this13.linkUserToPartyWithID();
          } else if (_this13.$route.query.invite_hash) {
            // allow user workflow to proceed even if organisation ID is not present
            _this13.$router.push('login');
          }
          if (_this13.invite_type === 'party') {
            _this13.activeStep = 1;
          }
        } else {
          _this13.$notify({
            title: 'Error',
            message: response.data.data.description,
            type: 'error',
            duration: 2000
          });
        }
      }).catch(function (error) {
        _this13.loading = false;
        // Special case, capture existing user
        if (error.response.status === 409) {
          _this13.$message({
            message: 'Email already registered. Click <a href="#/login">here to Login</a>',
            type: 'info',
            center: true,
            showClose: true,
            dangerouslyUseHTMLString: true
          });
        } else {
          _this13.$notify({
            title: 'Error',
            message: error.response.data.data.description,
            type: 'error',
            duration: 2000
          });
        }
      });
    },
    processUserSignup: function processUserSignup() {
      var _this14 = this;
      this.$refs.userSignUpForm.validate(function (valid) {
        if (valid) {
          _this14.loading = true;
          if (_this14.onboarding.user.id !== '') {
            _this14.updateUser();
          } else {
            // Check if all password rules are satisifed
            if (_this14.allPasswordRulesPassed()) {
              _this14.createUser();
            } else {
              _this14.passwordRulesPopover = true;
              _this14.loading = false;
              _this14.$notify({
                title: 'Error',
                message: 'Password validation failed',
                type: 'error'
              });
            }
          }
        }
      });
    },
    notifyExistingBusiness: function notifyExistingBusiness() {
      var _this15 = this;
      this.$prompt('Please input your organization Domain', 'Organization Domain', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        inputPattern: /(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
        inputErrorMessage: 'Invalid Valid Domain'
      }).then(function (_ref) {
        var value = _ref.value;
        _this15.$notify({
          title: 'Success',
          message: 'Company Admin has be notified',
          type: 'success'
        });
      }).catch(function () {
        _this15.$message({
          type: 'info',
          message: 'Input canceled'
        });
      });
    },
    goToCompanyPage: function goToCompanyPage() {
      var _this16 = this;
      this.$refs.joinExistingCompany.validate(function (valid) {
        if (valid) {
          window.location.href = 'https://' + _this16.existing_company.domain + '.prodiggi.com';
        }
      });
    },
    goToLoginPage: function goToLoginPage() {
      this.$router.push('login');
    },
    displayEmailInfo: function displayEmailInfo() {
      var _this17 = this;
      this.$alert('Two confirmation emails have been sent to your supplied emails. Please confirm both.', 'Prodiggi', {
        confirmButtonText: 'OK',
        callback: function callback(action) {
          _this17.goToDashboard();
        }
      });
    },
    goToDashboard: function goToDashboard() {
      this.$router.push({
        path: "/"
      });
    },
    openIntegrationDialog: function openIntegrationDialog(index) {
      this.activeIntegration = this.integrations[index];
      if (this.activeIntegration.name === 'JIRA') {
        this.JIRAIntegrationform = {
          jiraHome: '',
          consumerKey: ''
        };
        this.integrationDialog = true;
      } else {
        this.integrationDialogDefault = true;
      }
    },
    saveIntegration: function saveIntegration() {
      this.activeIntegration.integrated = true;
      this.integrationDialog = false;
    },
    displayJIRAIntegrationDocumentation: function displayJIRAIntegrationDocumentation() {
      this.$notify({
        title: 'Info',
        message: 'To be redirected to integration confluence / webpage',
        type: 'info',
        duration: 2000
      });
    },
    authouriseJIRAIntegration: function authouriseJIRAIntegration() {
      var _this18 = this;
      this.$refs.thirdPartyIntegration.validate(function (valid) {
        if (valid) {
          _this18.JIRATesting = true;
          var headers = {
            'Content-Type': 'application/json',
            'x-user-id': _this18.onboarding.user.id,
            'x-party-id': 'hello'
          };
          _axios.default.post(process.env.VUE_APP_REST_API_ENDPOINT + '3rdparty/requestToken', _this18.JIRAIntegrationform, {
            headers: headers
          }).then(function (response) {
            _this18.JIRATesting = false;
            console.log(response.data);
            window.open(response.data.data.authorizationURL, '_blank');
            _this18.jira_active_step = 1;
            _this18.JIRAIntegrationResponse.oauthtoken = response.data.data.oauthtoken;
            _this18.JIRAIntegrationResponse.tokenSecret = response.data.data.tokenSecret;
          }).catch(function (error) {
            _this18.JIRATesting = false;
            _this18.$notify({
              title: 'Error',
              message: error.response.data.data.description,
              type: 'error',
              duration: 2000
            });
          });
        }
      });
    }
  }
};