"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("g", {
    attrs: {
      refs: "release_lane"
    },
    on: {
      mouseover: function mouseover($event) {
        $event.preventDefault();
        $event.stopPropagation();
        _vm.isHover = true;
      },
      mouseleave: function mouseleave($event) {
        $event.preventDefault();
        $event.stopPropagation();
        _vm.isHover = false;
      }
    }
  }, [_vm.isHover ? _c("g", {
    attrs: {
      refs: "release_hover"
    }
  }, [_c("g", {
    directives: [{
      name: "dragged",
      rawName: "v-dragged",
      value: _vm.onReleaseLeftResized,
      expression: "onReleaseLeftResized"
    }],
    attrs: {
      refs: "left_drag"
    }
  }, [_c("rect", {
    staticStyle: {
      cursor: "col-resize"
    },
    attrs: {
      x: _vm.x - 10,
      y: _vm.y,
      width: _vm.computeReleaseResizeControlWidth,
      height: _vm.headerHeight,
      rx: 2,
      fill: _vm.computeFillColor,
      "fill-opacity": _vm.computeFillOpacity,
      stroke: _vm.computeFillColor,
      "stroke-width": "1"
    }
  }), _vm._v(" "), _c("foreignObject", {
    staticClass: "text-center",
    attrs: {
      x: _vm.x - 10,
      y: _vm.y + 12,
      width: 13,
      height: 13
    }
  }, [_c("i", {
    staticClass: "el-icon-arrow-left",
    style: _vm.computeIconStyle
  })])]), _vm._v(" "), _c("g", {
    directives: [{
      name: "dragged",
      rawName: "v-dragged",
      value: _vm.onReleaseRightResized,
      expression: "onReleaseRightResized"
    }],
    attrs: {
      refs: "right_drag"
    }
  }, [_c("rect", {
    staticStyle: {
      cursor: "col-resize"
    },
    attrs: {
      x: _vm.x + _vm.width,
      y: _vm.y,
      width: _vm.computeReleaseResizeControlWidth,
      height: _vm.headerHeight,
      rx: 2,
      fill: _vm.computeFillColor,
      "fill-opacity": _vm.computeFillOpacity,
      stroke: _vm.computeFillColor,
      "stroke-width": "1"
    }
  }), _vm._v(" "), _c("foreignObject", {
    staticClass: "text-right",
    attrs: {
      x: _vm.x + _vm.width - 2,
      y: _vm.y + 12,
      width: 13,
      height: 13
    }
  }, [_c("i", {
    staticClass: "el-icon-arrow-right",
    style: _vm.computeIconStyle
  })])])]) : _vm._e(), _vm._v(" "), _c("g", {
    attrs: {
      refs: ""
    }
  }, [_c("rect", {
    attrs: {
      x: _vm.x,
      y: _vm.y,
      width: _vm.width + 2,
      height: _vm.headerHeight,
      rx: 2,
      fill: _vm.computeFillColor,
      "fill-opacity": _vm.computeFillOpacity,
      stroke: _vm.computeFillColor,
      "stroke-width": "1"
    }
  }), _vm._v(" "), _c("foreignObject", {
    directives: [{
      name: "dragged",
      rawName: "v-dragged",
      value: _vm.onReleaseDragged,
      expression: "onReleaseDragged"
    }],
    staticClass: "text-left",
    staticStyle: {
      overflow: "hidden",
      "border-radius": "2pt"
    },
    attrs: {
      x: _vm.x,
      y: _vm.y + 4,
      width: _vm.width,
      height: _vm.headerHeight
    }
  }, [_c("div", {
    style: _vm.computeTitleColor,
    attrs: {
      slot: "reference"
    },
    slot: "reference"
  }, [_vm._v("\n        " + _vm._s(_vm.truncateString(_vm.release.title, 52)) + "\n      ")])]), _vm._v(" "), _vm.isHover ? _c("foreignObject", {
    staticClass: "text-left",
    attrs: {
      x: _vm.x + _vm.width - 45,
      y: _vm.y + 2,
      width: 13,
      height: 13
    },
    on: {
      click: _vm.createIncrement
    }
  }, [_c("i", {
    staticClass: "el-icon-plus",
    style: _vm.computeIconStyle
  })]) : _vm._e(), _vm._v(" "), _vm.isHover ? _c("foreignObject", {
    staticClass: "text-left",
    attrs: {
      x: _vm.x + _vm.width - 30,
      y: _vm.y + 2,
      width: 13,
      height: 13
    },
    on: {
      click: _vm.onClick
    }
  }, [_c("i", {
    staticClass: "el-icon-edit",
    style: _vm.computeIconStyle
  })]) : _vm._e(), _vm._v(" "), _vm.isHover ? _c("foreignObject", {
    staticClass: "text-left",
    attrs: {
      x: _vm.x + _vm.width - 15,
      y: _vm.y + 2,
      width: 13,
      height: 13
    },
    on: {
      click: _vm.deleteRelease
    }
  }, [_c("i", {
    staticClass: "el-icon-delete",
    style: _vm.computeIconStyle
  })]) : _vm._e()]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "container"
    }
  }, [_c("rect", {
    attrs: {
      x: _vm.x,
      y: _vm.y + _vm.headerHeight,
      width: _vm.width,
      height: _vm.height - 10,
      rx: "2",
      fill: _vm.computeFillColor,
      "fill-opacity": "0.1"
    }
  })]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "release_details"
    }
  }, [_vm._t("default")], 2)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;