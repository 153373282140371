"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default2 = exports.default = {
  name: 'TouchpointCreate',
  components: {},
  props: {
    type: {
      type: String,
      default: 'SMS'
    },
    edit: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    touchpoint: {
      type: Object,
      default: function _default() {
        return {
          type: '',
          title: '',
          description: '',
          index: ''
        };
      }
    }
  },
  data: function data() {
    return {
      touchpoint_form: {
        title: '',
        description: '',
        type: ''
      },
      addTouchpointrules: {
        title: [{
          required: true,
          message: 'Please input touchpoint title',
          trigger: 'blur'
        }, {
          min: 5,
          max: 80,
          message: 'Length should be 5 to 80',
          trigger: 'blur'
        }],
        description: [{
          required: true,
          message: 'Please input touchpoint description',
          trigger: 'blur'
        }]
      }
    };
  },
  computed: {},
  watch: {
    type: function type(new_val) {
      this.touchpoint_form.type = new_val;
    },
    edit: function edit(new_val) {
      if (new_val === true) {
        this.touchpoint_form = Object.assign({}, this.touchpoint);
      } else {
        this.resetForm();
      }
    }
  },
  created: function created() {},
  mounted: function mounted() {
    if (this.edit) {
      this.touchpoint_form = Object.assign({}, this.touchpoint);
    } else {
      this.resetForm();
    }
  },
  methods: {
    createTouchpoint: function createTouchpoint() {
      var _this = this;
      this.$refs.addTouchpointform.validate(function (valid) {
        if (valid) {
          var touchpoint = Object.assign({}, _this.touchpoint_form);
          _this.$emit('add-touchpoint', touchpoint);
          _this.resetForm();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    updateTouchpoint: function updateTouchpoint() {
      var touchpoint = Object.assign({}, this.touchpoint_form);
      this.$emit('update-touchpoint', touchpoint, this.touchpoint.index);
      this.resetForm();
    },
    closeUI: function closeUI() {
      this.resetForm();
      this.$emit('close');
    },
    resetForm: function resetForm() {
      this.touchpoint_form = {
        title: '',
        description: '',
        type: this.type
      };
    }
  }
};