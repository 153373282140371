"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-row", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "mt-10",
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: _vm.IntegrationSidebarCollapsed ? 20 : 24
    }
  }, [_c("canvas", {
    staticStyle: {
      display: "none"
    },
    attrs: {
      id: "canvas",
      width: _vm.computeCanvasWidth,
      height: _vm.computeCanvasHeight
    }
  }), _vm._v(" "), _c("div", {
    staticStyle: {
      "overflow-x": "scroll"
    },
    attrs: {
      id: "journeyMapContainer"
    },
    on: {
      drop: _vm.handleDrop,
      dragover: _vm.handleDragOver
    }
  }, [_c("div", {
    staticStyle: {
      width: "100%",
      top: "-30pt",
      "margin-left": "1pt"
    }
  }, [_c("el-row", {
    staticClass: "shadow",
    staticStyle: {
      "background-color": "ghostwhite",
      padding: "10pt 0 0 6pt",
      height: "38pt",
      "border-radius": "4pt",
      "box-shadow": "3px 3px 3px 2px rgba(0,0,0,0.5)",
      width: "99.7%",
      "margin-top": "3pt"
    },
    attrs: {
      span: 24
    }
  }, [_c("el-col", {
    attrs: {
      xl: 8,
      lg: 9,
      md: 10,
      sm: 10,
      xs: 10,
      offset: 0
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "1.5em",
      "font-variant-caps": "small-caps",
      "letter-spacing": "-0.2pt",
      "padding-top": "3pt"
    },
    attrs: {
      refs: "customer-journey-title"
    }
  }, [_c("span", {
    staticStyle: {
      "font-weight": "bold",
      "font-size": "1.1em",
      color: "#26428b"
    }
  }, [_vm._v("Journey: ")]), _vm._v(" "), _c("span", {
    staticStyle: {
      color: "rebeccapurple"
    }
  }, [_vm._v(_vm._s(_vm.journeyMap.title))])])]), _vm._v(" "), _c("el-col", {
    attrs: {
      xl: 1,
      lg: 1,
      md: 1,
      sm: 1,
      xs: 1
    }
  }, [_c("span", {
    staticStyle: {
      color: "grey",
      "font-variant-caps": "small-caps",
      float: "right",
      padding: "0pt 6pt 0 0",
      "font-size": "1.8em"
    }
  }, [_c("i", {
    staticClass: "el-icon-zoom-in"
  })])]), _vm._v(" "), _c("el-col", {
    attrs: {
      xl: 4,
      lg: 6,
      md: 6,
      sm: 7,
      xs: 7,
      offset: 0
    }
  }, [_c("el-slider", {
    staticClass: "sliderZoom",
    attrs: {
      step: 1,
      min: 50,
      max: 200,
      "show-input": "",
      "input-size": "mini"
    },
    on: {
      change: function change($event) {
        return _vm.zoomJourney();
      }
    },
    model: {
      value: _vm.journeyZoom,
      callback: function callback($$v) {
        _vm.journeyZoom = $$v;
      },
      expression: "journeyZoom"
    }
  })], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      xl: 10,
      lg: 7,
      md: 6,
      sm: 6,
      xs: 6
    }
  }, [_c("div", {
    staticClass: "fr",
    attrs: {
      stlye: "position:relative; top:0"
    }
  }, [_c("el-button", {
    staticStyle: {
      "background-color": "ghostwhite",
      "border-color": "lightgray",
      "border-radius": "5pt"
    },
    attrs: {
      size: "mini",
      type: "secondary"
    },
    on: {
      click: function click($event) {
        return _vm.createJourneyIdea();
      }
    }
  }, [_vm._v("Add ideas")]), _vm._v(" "), _c("el-button", {
    staticStyle: {
      "background-color": "ghostwhite",
      "border-color": "lightgray",
      "border-radius": "5pt"
    },
    attrs: {
      size: "mini",
      type: "secondary"
    }
  }, [_vm._v("Approve")]), _vm._v(" "), _c("el-button", {
    staticStyle: {
      "background-color": "ghostwhite",
      "border-color": "lightgray",
      "border-radius": "5pt"
    },
    attrs: {
      size: "mini",
      type: "secondary",
      icon: "el-icon-menu",
      circle: ""
    },
    on: {
      click: function click($event) {
        return _vm.showSettingsDialog();
      }
    }
  }), _vm._v(" "), _c("el-button", {
    staticStyle: {
      "background-color": "ghostwhite",
      "border-color": "lightgray",
      "border-radius": "5pt"
    },
    attrs: {
      size: "mini",
      type: "secondary",
      icon: "el-icon-refresh",
      circle: ""
    },
    on: {
      click: function click($event) {
        return _vm.resetCanvasLayout();
      }
    }
  })], 1)])], 1)], 1), _vm._v(" "), _c("svg", {
    staticClass: "journeyZoomer",
    attrs: {
      id: "journeyMapSVG",
      height: _vm.computeCanvasHeight + 300,
      width: _vm.computeCanvasWidth,
      xmlns: "http://www.w3.org/2000/svg",
      version: "1.1"
    },
    on: {
      click: function click($event) {
        return _vm.canvasClick();
      }
    }
  }, [_vm.isLegacyVersion ? _c("g", [_c("defs", [_c("filter", {
    attrs: {
      id: "exp_relation_shadow"
    }
  }, [_vm._v('" flood-color="white">'), _vm._v(" "), _c("feDropShadow", {
    attrs: {
      dx: "-1",
      dy: "1",
      stdDeviation: "1",
      "flood-color": "white"
    }
  }), _vm._v(" "), _vm._v('" flood-color="white">'), _vm._v(" "), _c("feDropShadow", {
    attrs: {
      dx: "-1",
      dy: "-1",
      stdDeviation: "0.5",
      "flood-color": "white"
    }
  })], 1)]), _vm._v(" "), _c("marker", {
    attrs: {
      id: "dot",
      viewBox: "0 0 10 10",
      refX: "5",
      refY: "5",
      markerWidth: "12",
      markerHeight: "12",
      orient: "auto-start-reverse"
    }
  }, [_c("circle", {
    attrs: {
      cx: "5",
      cy: "5",
      r: "4",
      fill: "#447799",
      "fill-opacity": _vm.highlight === "pain_gain" ? 0.5 : 1
    }
  })]), _vm._v(" "), _c("marker", {
    attrs: {
      id: "triangle",
      viewBox: "0 0 10 10",
      refX: "5",
      refY: "5",
      fill: "#447799",
      markerUnits: "strokeWidth",
      markerWidth: "7",
      markerHeight: "7",
      orient: "auto"
    }
  }, [_c("path", {
    attrs: {
      d: "M 0 0 L 10 5 L 0 10 ",
      fill: "#888",
      "fill-opacity": _vm.highlight === "pain_gain" ? 0.5 : 1
    }
  })]), _vm._v(" "), _c("marker", {
    attrs: {
      id: "triangle225",
      viewBox: "0 0 10 10",
      refX: "5",
      refY: "5",
      fill: "#447799",
      markerUnits: "strokeWidth",
      markerWidth: "7",
      markerHeight: "7",
      orient: "225deg"
    }
  }, [_c("path", {
    attrs: {
      d: "M 0 0 L 10 5 L 0 10 ",
      fill: "#888",
      "fill-opacity": _vm.highlight === "pain_gain" ? 0.5 : 1
    }
  })]), _vm._v(" "), _c("marker", {
    attrs: {
      id: "dot_gray",
      viewBox: "0 0 10 10",
      refX: "5",
      refY: "5",
      markerWidth: "12",
      markerHeight: "12",
      orient: "auto-start-reverse"
    }
  }, [_c("circle", {
    attrs: {
      cx: "5",
      cy: "5",
      r: "1",
      fill: "gray",
      "fill-opacity": _vm.highlight === "pain_gain" ? 0.5 : 1
    }
  })]), _vm._v(" "), _c("marker", {
    attrs: {
      id: "triangle_gray",
      viewBox: "0 0 10 10",
      refX: "5",
      refY: "5",
      markerUnits: "strokeWidth",
      markerWidth: "5",
      markerHeight: "5",
      orient: "auto"
    }
  }, [_c("path", {
    attrs: {
      d: "M 0 0 L 10 5 L 0 10 ",
      fill: "gray",
      "fill-opacity": _vm.highlight === "pain_gain" ? 0.5 : 1
    }
  })]), _vm._v(" "), _c("marker", {
    attrs: {
      id: "dot_blue",
      viewBox: "0 0 10 10",
      refX: "5",
      refY: "5",
      markerWidth: "8",
      markerHeight: "8",
      orient: "auto-start-reverse"
    }
  }, [_c("circle", {
    attrs: {
      cx: "5",
      cy: "5",
      r: "1",
      fill: "lightblue",
      "fill-opacity": 0.9
    }
  })]), _vm._v(" "), _c("marker", {
    attrs: {
      id: "triangle_blue",
      viewBox: "0 0 6 6",
      refX: "3",
      refY: "3",
      markerUnits: "strokeWidth",
      markerWidth: "3",
      markerHeight: "3",
      orient: "auto"
    }
  }, [_c("path", {
    attrs: {
      d: "M 0 0 L 6 3 L 0 6 ",
      fill: "lightblue",
      "fill-opacity": 0.9
    }
  })]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "customer-journey-canvas"
    }
  }, [_c("g", {
    attrs: {
      refs: "customer-journey"
    }
  }, [_c("rect", {
    staticClass: "shadow",
    attrs: {
      id: "customer-journey-workspace_A",
      x: "120",
      y: "10",
      rx: "5",
      width: _vm.computeCanvasWidth,
      height: _vm.computeCanvasHeight * 0.915,
      fill: "white",
      "stroke-width": "1",
      stroke: "#eee"
    }
  }), _vm._v(" "), _vm.computedIdeasForJourney.length > 0 ? _c("g", {
    attrs: {
      refs: "customer-journey-idea-count"
    }
  }, [_c("foreignObject", {
    staticStyle: {
      "background-color": "white",
      "border-radius": "5px",
      color: "red",
      "text-align": "left",
      "font-size": "1.0em",
      "font-weight": "bold"
    },
    attrs: {
      x: "125",
      y: "13",
      width: "200",
      height: "22"
    }
  }, [_c("div", {
    staticStyle: {
      color: "#E6A23C",
      "margin-left": "-3px",
      "margin-top": "1pt"
    }
  }, [_c("div", {
    staticStyle: {
      display: "inline-block",
      "border-radius": "15pt",
      "background-color": "white",
      "box-shadow": "0 0 0.5px 0.5px lightgrey",
      border: "solid 1pt lightgrey",
      width: "27pt",
      "margin-left": "5pt",
      padding: "0 4pt 0 4pt"
    }
  }, [_c("svg-icon", {
    staticStyle: {
      "padding-left": "1pt",
      "font-size": "14px",
      color: "#E6A23C"
    },
    attrs: {
      "icon-class": "ideas"
    }
  }), _vm._v(" "), _c("span", {
    staticStyle: {
      "font-size": "0.9em"
    }
  }, [_vm._v(_vm._s(_vm.computedIdeasForJourney.length))])], 1), _vm._v(" "), _c("span", {
    staticStyle: {
      "margin-left": "1px",
      "font-weight": "100",
      "font-size": "0.9em"
    }
  }, [_vm._v(" whole of journey ideas exists")])])])]) : _vm._e(), _vm._v(" "), _c("rect", {
    attrs: {
      id: "customer-journey-workspace_0",
      x: "125",
      y: _vm.computedIdeasForJourney.length == 0 ? 14 : 35,
      rx: "0",
      width: _vm.computeCanvasWidth - 125,
      height: _vm.computedIdeasForJourney.length == 0 ? 81 : 60,
      fill: "rgba(230,230,230,0.1)",
      "stroke-width": "1",
      stroke: "#f1f4ff"
    }
  }), _vm._v(" "), _c("rect", {
    attrs: {
      id: "customer-journey-workspace_0",
      x: "125",
      y: _vm.computedIdeasForJourney.length == 0 ? 14 : 35,
      rx: "0",
      width: "28",
      height: _vm.computedIdeasForJourney.length == 0 ? 81 : 60,
      fill: "rgba(200,200,200,0.15)",
      "stroke-width": "0",
      stroke: "#aaa"
    }
  }), _vm._v(" "), _c("rect", {
    directives: [{
      name: "dragged",
      rawName: "v-dragged.prevent",
      value: _vm.onStepContainerDragged,
      expression: "onStepContainerDragged",
      modifiers: {
        prevent: true
      }
    }],
    staticStyle: {
      cursor: "grab"
    },
    attrs: {
      id: "customer-journey-workspace_0",
      x: "125",
      y: "100",
      rx: "0",
      width: _vm.computeCanvasWidth - 125,
      height: 260,
      fill: "rgba(254,254,255,0.9)",
      "stroke-width": "1.0",
      stroke: "#f1f4ff"
    }
  }), _vm._v(" "), _c("rect", {
    attrs: {
      id: "customer-journey-workspace_0",
      x: "125",
      y: "100",
      rx: "0",
      width: "28",
      height: 260,
      fill: "rgba(200,200,200,0.15)",
      "stroke-width": "0",
      stroke: "#aaa"
    }
  }), _vm._v(" "), _c("rect", {
    attrs: {
      id: "customer-journey-workspace_0",
      x: "125",
      y: "365",
      rx: "0",
      width: _vm.computeCanvasWidth - 125,
      height: _vm.isExperienceLaneMinimized ? 240 : _vm.computeCanvasHeight * 0.915 - 60 - 260 - 35 - 5,
      fill: "rgba(254,254,255,0.9)",
      "stroke-width": "1.0",
      stroke: "#f1f4ff"
    }
  }), _vm._v(" "), _c("rect", {
    attrs: {
      id: "customer-journey-workspace_0",
      x: "125",
      y: "365",
      rx: "0",
      width: "28",
      height: _vm.isExperienceLaneMinimized ? 240 : _vm.computeCanvasHeight * 0.915 - 60 - 260 - 35 - 5,
      fill: "rgba(200,200,200,0.15)",
      "stroke-width": "0",
      stroke: "#aaa"
    }
  }), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "grey",
      "font-weight": "600",
      "font-variant-caps": "small-caps"
    },
    attrs: {
      x: "140",
      y: _vm.computedIdeasForJourney.length === 0 ? 74 : 85,
      "text-anchor": "start",
      "font-size": ".95em",
      transform: _vm.computeStageTitleRotate
    }
  }, [_vm._v("Stages")]), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "grey",
      "font-weight": "600",
      "font-variant-caps": "small-caps"
    },
    attrs: {
      x: "140",
      y: "230",
      "text-anchor": "start",
      "font-size": ".95em",
      transform: "rotate(270, 140, 230)"
    }
  }, [_vm._v("Steps")]), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "grey",
      "font-weight": "600",
      "font-variant-caps": "small-caps"
    },
    attrs: {
      x: "140",
      y: "515",
      "text-anchor": "start",
      "font-size": ".95em",
      transform: "rotate(270, 140, 515)"
    }
  }, [_vm._v("Experience")]), _vm._v(" "), _c("foreignObject", {
    attrs: {
      x: "130",
      y: "370",
      width: 15,
      height: 15,
      fill: "none"
    }
  }, [_vm.isExperienceLaneMinimized ? _c("div", {
    staticStyle: {
      padding: "0px"
    }
  }, [_c("svg-icon", {
    staticStyle: {
      color: "#4672c4",
      "font-size": "1.0em"
    },
    attrs: {
      "icon-class": "arrow-expand",
      title: "Expand Lane"
    },
    on: {
      click: function click($event) {
        return _vm.expandExperienceLane();
      }
    }
  })], 1) : _c("div", [_c("svg-icon", {
    staticStyle: {
      color: "#4672c4"
    },
    attrs: {
      "icon-class": "arrow-shrink",
      title: "Shrink Lane"
    },
    on: {
      click: function click($event) {
        return _vm.collapseExperienceLane();
      }
    }
  })], 1)]), _vm._v(" "), _c("foreignObject", {
    attrs: {
      x: "130",
      y: "440",
      width: 15,
      height: 15,
      transform: "rotate(270, 130, 440)",
      fill: "none"
    }
  }, [_c("el-popover", {
    attrs: {
      placement: "right",
      title: "Experience Stats",
      width: "200",
      trigger: "hover",
      "open-delay": 400,
      "close-delay": 400
    },
    on: {
      show: _vm.showExperienceInfoPopover
    }
  }, [_c("el-table", {
    attrs: {
      data: _vm.experienceCount,
      stripe: "",
      size: "mini"
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "type"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v("\n                          " + _vm._s(_vm.formatExperienceInfoPopTableHeader(scope.row.type)) + "\n                        ")];
      }
    }], null, false, 3439546201)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "count"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-tag", {
          attrs: {
            size: "mini",
            type: "primary"
          }
        }, [_vm._v(_vm._s(scope.row.count))])];
      }
    }], null, false, 2320327415)
  })], 1), _vm._v(" "), _c("i", {
    staticClass: "el-icon-info",
    staticStyle: {
      color: "grey"
    },
    attrs: {
      slot: "reference"
    },
    slot: "reference"
  })], 1)], 1)]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "highlighter"
    }
  }, [_vm.phaseHighlighter.length > 0 ? _c("g", {
    attrs: {
      refs: "task_drag_highlighter"
    }
  }, _vm._l(_vm.phaseHighlighter, function (gap, index) {
    return _c("rect", {
      key: index,
      staticClass: "gaphighlighter-anim",
      attrs: {
        x: gap.x,
        y: gap.y,
        rx: 5,
        ry: 5,
        width: gap.width,
        height: gap.height,
        fill: "#eee",
        "fill-opacity": "0.1",
        "stroke-dasharray": "5,5",
        stroke: "lightseagreen",
        "stroke-width": 1,
        "stroke-opacity": "0.5"
      }
    });
  }), 0) : _vm._e(), _vm._v(" "), _c("g", {
    attrs: {
      refs: "task_drag_phase_highlighter"
    }
  }, [_c("rect", {
    staticClass: "gaphighlighter-anim",
    attrs: {
      x: _vm.activePhaseHighlighter.x,
      y: _vm.activePhaseHighlighter.y,
      rx: 5,
      ry: 5,
      width: _vm.activePhaseHighlighter.width + 20,
      height: _vm.activePhaseHighlighter.height,
      fill: "rgba(64,150,238, 0.5)",
      "fill-opacity": "0.5",
      "stroke-dasharray": "5,5",
      stroke: "lightseagreen",
      "stroke-width": 1,
      "stroke-opacity": "0.9"
    }
  })])]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "gap_highlighter"
    }
  }, [_vm.phaseGapHighlighter.length > 0 ? _c("g", {
    attrs: {
      refs: "gap_drag_highlighter"
    }
  }, _vm._l(_vm.phaseGapHighlighter, function (gap, index) {
    return _c("rect", {
      key: index,
      staticClass: "gaphighlighter-anim",
      attrs: {
        x: gap.x,
        y: gap.y,
        rx: 5,
        ry: 5,
        width: gap.width,
        height: gap.height - 5,
        fill: "#eee",
        "fill-opacity": "0.1",
        "stroke-dasharray": "5,5",
        stroke: "lightseagreen",
        "stroke-width": 1,
        "stroke-opacity": "0.5"
      }
    });
  }), 0) : _vm._e(), _vm._v(" "), _c("g", {
    attrs: {
      refs: "phase_gap_drag_hover_highligher"
    }
  }, [_c("rect", {
    staticClass: "gaphighlighter-anim",
    attrs: {
      x: _vm.activePhaseGapHighlighter.x,
      y: _vm.activePhaseGapHighlighter.y,
      rx: 5,
      ry: 5,
      width: _vm.activePhaseGapHighlighter.width,
      height: _vm.activePhaseGapHighlighter.height - 5,
      fill: "rgba(64,150,238, 0.5)",
      "fill-opacity": "0.5",
      "stroke-dasharray": "5,5",
      stroke: "#4096EE",
      "stroke-width": 1,
      "stroke-opacity": "0.9"
    }
  })])]), _vm._v(" "), _c("g", {
    staticClass: "shadow",
    attrs: {
      refs: "customer-journey-phase-list"
    }
  }, [!_vm.isPhaseFocusMode ? _c("g", {
    attrs: {
      refs: "phase-lane-content"
    }
  }, _vm._l(_vm.sortedStages, function (phase, index) {
    return _c("phase-component", {
      directives: [{
        name: "dragged",
        rawName: "v-dragged.prevent",
        value: _vm.onPhaseDragged,
        expression: "onPhaseDragged",
        modifiers: {
          prevent: true
        }
      }],
      key: index,
      attrs: {
        id: "phase_" + index,
        index: index,
        x: _vm.computePhaseStartX(phase.journeyPhase, index),
        y: 40,
        length: _vm.computePhaseLength(phase.journeyPhase, index),
        label: phase.journeyPhase.title,
        "show-label": true,
        hover: _vm.phaseHover,
        highlight: _vm.highlight,
        priority: phase.journeyPhase.priority,
        value: phase.journeyPhase.importance,
        collapse: phase.journeyPhase.additionalattributes.ui.isCollapse,
        phase: phase,
        ideas: _vm.computedIdeasForPhase(phase.journeyPhase),
        "is-idea-hover": _vm.phaseHoverIdeaID === phase.journeyPhase.id
      },
      on: {
        click: _vm.editPhaseDetails,
        focusmode: function focusmode($event) {
          return _vm.toggleFocusMode(phase, index);
        },
        mouseover: function mouseover($event) {
          return _vm.onPhaseMouseOver(phase, index);
        },
        mouseleave: function mouseleave($event) {
          return _vm.onPhaseMouseLeave();
        },
        longpressstart: function longpressstart($event) {
          return _vm.onPhaseLongPressStart(phase, index);
        },
        longpressstop: function longpressstop($event) {
          return _vm.onPhaseLongPressStop(phase, index);
        },
        collapsephase: function collapsephase($event) {
          return _vm.onPhaseCollapse(phase, index);
        },
        expandphase: function expandphase($event) {
          return _vm.onPhaseExpand(phase, index);
        },
        updatelayout: function updatelayout($event) {
          return _vm.computePhaseX();
        }
      }
    });
  }), 1) : _vm._e(), _vm._v(" "), _c("g", {
    attrs: {
      refs: "task-lane-content"
    }
  }, _vm._l(_vm.computedTaskList, function (task, index) {
    return _c("task-component", {
      key: index,
      attrs: {
        id: "task_" + index,
        x: _vm.task_pos_x[index],
        y: _vm.computeTaskPosY(index),
        index: index,
        "phase-index": _vm.computePhaseIndexforTask(task, index),
        showLabel: true,
        hover: _vm.taskHover,
        label: task.journeyStep.title,
        highlight: _vm.highlight,
        priority: task.journeyStep.priority,
        value: task.journeyStep.importance,
        ideas: _vm.computedIdeasForStep(task.journeyStep),
        "is-relation-hover": _vm.relationsHoverTaskID === task.journeyStep.id
      },
      on: {
        click: _vm.selectTask,
        mouseover: function mouseover($event) {
          return _vm.onTaskMouseOver(task, index);
        },
        mouseleave: function mouseleave($event) {
          return _vm.onTaskMouseLeave(task, index);
        },
        "create-pain": _vm.createPainFromTask,
        "create-gain": _vm.createGainFromTask,
        "create-wow": _vm.createWowFromTask,
        "drag-workflow": _vm.onTaskWorkFlowDrag,
        "drag-task": _vm.onTaskDragged
      }
    });
  }), 1), _vm._v(" "), _c("g", {
    attrs: {
      refs: "experience-lane-content"
    }
  }, _vm._l(_vm.journeyMap.linkedJourneyExperiences, function (experience, index) {
    return _c("g", {
      key: index
    }, [experience.journeyExperience.experiencetype === "PAIN" && _vm.isExperienceVisible(experience) ? _c("pain-component", {
      attrs: {
        x: _vm.computePainStartX(index),
        y: _vm.computeExperienceStartY(index),
        "is-stage-relation": _vm.isStageRelation(index),
        index: index,
        highlight: _vm.highlight,
        "show-label": true,
        label: experience.journeyExperience.title,
        description: experience.journeyExperience.description,
        priority: experience.journeyExperience.priority,
        value: experience.journeyExperience.additionalattributes.customer_value,
        "is-minimized": _vm.isExperienceLaneMinimized,
        "is-parent-active": !_vm.isParentTaskActive(experience.journeyExperience.id)
      },
      on: {
        mouseover: _vm.onPainMouseOver,
        mouseleave: _vm.onPainMouseLeave,
        click: function click($event) {
          return _vm.editExperienceDetails(index, experience);
        }
      }
    }) : _vm._e(), _vm._v(" "), _vm.isExperienceLaneMinimized && experience.journeyExperience.experiencetype === "PAIN" ? _c("g", {
      attrs: {
        refs: "pains-count-minimized"
      }
    }, [_c("text", {
      staticStyle: {
        fill: "darkred"
      },
      attrs: {
        x: _vm.computePainStartX(index) + 15,
        y: _vm.computeExperienceStartY(index) + 20,
        "text-anchor": "start",
        "font-size": "0.7em"
      }
    }, [_vm._v("Pains: " + _vm._s(_vm.computeTotalExperienceCountPerStage(experience, index)))])]) : _vm._e(), _vm._v(" "), experience.journeyExperience.experiencetype === "GAIN" && _vm.isExperienceVisible(experience) ? _c("gain-component", {
      attrs: {
        x: _vm.computeGainStartX(index),
        y: _vm.computeExperienceStartY(index),
        "is-stage-relation": _vm.isStageRelation(index),
        index: index,
        highlight: _vm.highlight,
        "show-label": true,
        label: experience.journeyExperience.title,
        description: experience.journeyExperience.description,
        priority: experience.journeyExperience.priority,
        value: experience.journeyExperience.additionalattributes.customer_value,
        "is-minimized": _vm.isExperienceLaneMinimized,
        "is-parent-active": !_vm.isParentTaskActive(experience.journeyExperience.id)
      },
      on: {
        mouseover: _vm.onGainMouseOver,
        mouseleave: _vm.onGainMouseLeave,
        click: function click($event) {
          return _vm.editExperienceDetails(index, experience);
        }
      }
    }) : _vm._e(), _vm._v(" "), _vm.isExperienceLaneMinimized && experience.journeyExperience.experiencetype === "GAIN" ? _c("g", {
      attrs: {
        refs: "gains-count-minimized"
      }
    }, [_c("text", {
      staticStyle: {
        fill: "green"
      },
      attrs: {
        x: _vm.computeGainStartX(index) + 15,
        y: _vm.computeExperienceStartY(index) + 20,
        "text-anchor": "start",
        "font-size": "0.7em"
      }
    }, [_vm._v("Gains: " + _vm._s(_vm.computeTotalExperienceCountPerStage(experience, index)))])]) : _vm._e(), _vm._v(" "), experience.journeyExperience.experiencetype === "WOW" && _vm.isExperienceVisible(experience) ? _c("wow-component", {
      attrs: {
        x: _vm.computeWowStartX(index),
        y: _vm.computeExperienceStartY(index),
        "is-stage-relation": _vm.isStageRelation(index),
        index: index,
        highlight: _vm.highlight,
        "show-label": true,
        label: experience.journeyExperience.title,
        description: experience.journeyExperience.description,
        priority: experience.journeyExperience.priority,
        value: experience.journeyExperience.additionalattributes.customer_value,
        "is-minimized": _vm.isExperienceLaneMinimized,
        "is-parent-active": !_vm.isParentTaskActive(experience.journeyExperience.id)
      },
      on: {
        mouseover: _vm.onWowMouseOver,
        mouseleave: _vm.onWowMouseLeave,
        click: function click($event) {
          return _vm.editExperienceDetails(index, experience);
        }
      }
    }) : _vm._e(), _vm._v(" "), _vm.isExperienceLaneMinimized && experience.journeyExperience.experiencetype === "WOW" ? _c("g", {
      attrs: {
        refs: "wows-count-minimized"
      }
    }, [_c("text", {
      staticStyle: {
        fill: "#579ffb"
      },
      attrs: {
        x: _vm.computeWowStartX(index) + 15,
        y: _vm.computeExperienceStartY(index) + 20,
        "text-anchor": "start",
        "font-size": "0.7em"
      }
    }, [_vm._v("Wows: " + _vm._s(_vm.computeTotalExperienceCountPerStage(experience, index)))])]) : _vm._e(), _vm._v(" "), experience.journeyExperience.experiencetype === "CHALLENGE" && _vm.isExperienceVisible(experience) ? _c("challenge-component", {
      attrs: {
        x: _vm.computeChallengeStartX(index),
        y: _vm.computeExperienceStartY(index),
        "is-stage-relation": _vm.isStageRelation(index),
        index: index,
        highlight: _vm.highlight,
        "show-label": true,
        label: experience.journeyExperience.title,
        description: experience.journeyExperience.description,
        priority: experience.journeyExperience.priority,
        value: experience.journeyExperience.additionalattributes.customer_value,
        "is-minimized": _vm.isExperienceLaneMinimized,
        "is-parent-active": !_vm.isParentTaskActive(experience.journeyExperience.id)
      },
      on: {
        mouseover: _vm.onChallengeMouseOver,
        mouseleave: _vm.onChallengeMouseLeave,
        click: function click($event) {
          return _vm.editExperienceDetails(index, experience);
        }
      }
    }) : _vm._e(), _vm._v(" "), _vm.isExperienceLaneMinimized && experience.journeyExperience.experiencetype === "CHALLENGE" ? _c("g", {
      attrs: {
        refs: "wows-count-minimized"
      }
    }, [_c("text", {
      staticStyle: {
        fill: "rebeccapurple"
      },
      attrs: {
        x: _vm.computeWowStartX(index) + 15,
        y: _vm.computeExperienceStartY(index) + 20,
        "text-anchor": "start",
        "font-size": "0.7em"
      }
    }, [_vm._v("Obscurities: " + _vm._s(_vm.computeTotalExperienceCountPerStage(experience, index)))])]) : _vm._e()], 1);
  }), 0), _vm._v(" "), _vm.isJourneyHighlight ? _c("rect", {
    staticClass: "gaphighlighter-anim",
    attrs: {
      id: "customer-journey-workspace-highlight_0",
      x: "116",
      y: "9",
      width: "2800",
      height: 28,
      fill: "rgba(38,66,139,0.1)",
      "stroke-width": "1",
      stroke: "rgba(0,128, 128, 0.65)",
      "stroke-dasharray": "4"
    }
  }) : _vm._e(), _vm._v(" "), _vm.isJourneyHighlight ? _c("rect", {
    attrs: {
      x: "122",
      y: "14",
      width: "180",
      height: 20,
      fill: "rgba(0,128, 128, 0.95)",
      rx: "5"
    }
  }) : _vm._e(), _vm._v(" "), _vm.isJourneyHighlight ? _c("text", {
    attrs: {
      id: "customer-journey-workspace-highlight_guidetext",
      x: "132",
      y: "27",
      width: "200",
      height: 30,
      fill: "white",
      "font-style": "italic",
      "font-size": "0.9em"
    }
  }, [_vm._v("\n                  Drag whole-of-journey ideas here.\n                ")]) : _vm._e()]), _vm._v(" "), _vm._l(_vm.journeyMap.linkedJourneyExperiences, function (experience, index) {
    return _c("g", {
      key: index,
      attrs: {
        filter: "url(#exp_relation_shadow)"
      }
    }, [_c("transition", {
      attrs: {
        name: "fade"
      }
    }, [experience.journeyExperience.experiencetype !== "" && _vm.isExperienceVisible(experience) ? _c("g", {
      attrs: {
        refs: "experience-task-relation"
      }
    }, [experience.journeyExperience.relations && experience.journeyExperience.relations.step && _vm.selectedTask && _vm.selectedTask.journeyStep && _vm.selectedTask.journeyStep.id === experience.journeyExperience.relations.step && _vm.taskHover ? _c("path", {
      attrs: {
        d: _vm.generateTaskExperienceRelationPath(experience.journeyExperience, index),
        "stroke-opacity": _vm.highlight === "pain_gain" ? 0.5 : 1,
        stroke: "navy",
        "stroke-width": "1",
        "stroke-dasharray": "1,1",
        fill: "none",
        "marker-start": "url(#dot)",
        "marker-end": "url(#triangle)"
      }
    }) : _vm._e(), _vm._v(" "), experience.journeyExperience.relations && experience.journeyExperience.relations.step && _vm.selectedExperience && _vm.selectedExperience.journeyExperience && _vm.selectedExperience.journeyExperience.id === experience.journeyExperience.id && _vm.experienceHover ? _c("path", {
      attrs: {
        d: _vm.generateTaskExperienceRelationPath(experience.journeyExperience, index),
        "stroke-opacity": _vm.highlight === "pain_gain" ? 0.5 : 1,
        stroke: "navy",
        "stroke-width": "1",
        "stroke-dasharray": "1,1",
        fill: "none",
        "marker-start": "url(#triangle225)",
        "marker-end": "url(#dot)"
      }
    }) : _vm._e(), _vm._v(" "), experience.journeyExperience.relations && experience.journeyExperience.relations.phase && _vm.selectedPhase && _vm.selectedPhase.journeyPhase && _vm.selectedPhase.journeyPhase.id === experience.journeyExperience.relations.phase && _vm.phaseHover || experience.journeyExperience.relations && experience.journeyExperience.relations.phase && _vm.selectedExperience && _vm.selectedExperience.journeyExperience && _vm.selectedExperience.journeyExperience.id === experience.journeyExperience.id && _vm.experienceHover ? _c("path", {
      attrs: {
        d: _vm.generatePhaseExperienceRelationPath(experience.journeyExperience, index),
        "stroke-opacity": _vm.highlight === "pain_gain" ? 0.5 : 1,
        stroke: "navy",
        "stroke-width": "1",
        "stroke-dasharray": "3,3",
        fill: "none",
        "marker-start": "url(#dot)",
        "marker-end": "url(#triangle)"
      }
    }) : _vm._e()]) : _vm._e()])], 1);
  })], 2), _vm._v(" "), _c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.phaseHover && !_vm.phaseLongPress ? _c("g", {
    attrs: {
      refs: "phase_details_popover"
    }
  }, [_c("phase-details-component", {
    attrs: {
      x: _vm.isPhaseFocusMode ? 575 : _vm.computePhaseStartX(_vm.selectedPhase.journeyPhase, _vm.selectedPhaseIndex),
      y: 100,
      width: 600,
      phasex: _vm.computePhaseStartX(_vm.selectedPhase.journeyPhase, _vm.selectedPhaseIndex),
      phasewidth: _vm.computePhaseLength(_vm.selectedPhase.journeyPhase, _vm.selectedPhaseIndex),
      phase: _vm.selectedPhase.journeyPhase,
      steps: _vm.computedTaskList,
      experiences: _vm.journeyMap.linkedJourneyExperiences,
      index: _vm.selectedPhaseIndex,
      ideas: _vm.computedIdeasForPhase(_vm.selectedPhase.journeyPhase)
    },
    on: {
      "create-task": _vm.createTaskFromPhase,
      "create-pain": _vm.createPainFromPhase,
      "create-gain": _vm.createGainFromPhase,
      "create-wow": _vm.createWowFromPhase,
      "create-challenge": _vm.createChallengeFromPhase,
      "focus-mode": _vm.toggleFocusMode,
      "move-stage-up": _vm.movePhaseRelationStageUp,
      "move-stage-down": _vm.movePhaseRelationStageDown,
      details: _vm.editPhaseDetails,
      close: function close($event) {
        _vm.phaseHover = false;
      },
      delete: _vm.deletePhase,
      "delete-step": _vm.deletePhaseDetailStep,
      "delete-experience": _vm.deleteExperience,
      "create-phase-idea": _vm.createPhaseIdea
    }
  })], 1) : _vm._e()]), _vm._v(" "), _c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.taskHover ? _c("g", {
    attrs: {
      refs: "task_details_popover"
    }
  }, [_c("task-details-component", {
    attrs: {
      x: _vm.computeTaskPosX(_vm.selectedTaskIndex) + 200,
      y: _vm.computeTaskPosY(_vm.selectedTaskIndex) - 95,
      width: 600,
      step: _vm.selectedTask.journeyStep,
      experiences: _vm.journeyMap.linkedJourneyExperiences,
      index: _vm.selectedTaskIndex,
      ideas: _vm.computedIdeasForStep(_vm.selectedTask.journeyStep)
    },
    on: {
      "create-sub-task": _vm.displayCreateSubTaskDialog,
      "create-pain": _vm.createPainFromTask,
      "create-gain": _vm.createGainFromTask,
      "create-wow": _vm.createWowFromTask,
      "create-challenge": _vm.createChallengeFromTask,
      "delete-step": _vm.deleteStepWithID,
      close: function close($event) {
        _vm.taskHover = false;
      },
      details: _vm.selectTask,
      "create-step-idea": _vm.createStepIdea
    }
  })], 1) : _vm._e()]), _vm._v(" "), _c("g", {
    staticClass: "shadow",
    attrs: {
      id: "toolboxselector",
      refs: "customer-journey-toolbox"
    }
  }, [_c("rect", {
    attrs: {
      x: "0",
      y: "10",
      width: "110",
      ry: "3",
      rx: "3",
      height: _vm.computeCanvasHeight * 0.915,
      fill: "rgba(255,255,255,1.0)",
      "stroke-width": "1",
      stroke: "#AAA"
    }
  }), _vm._v(" "), _c("rect", {
    attrs: {
      x: "1",
      y: "10",
      width: "108",
      ry: "3",
      rx: "3",
      height: "22",
      fill: "ghostwhite",
      "stroke-width": "0",
      stroke: "#AAA"
    }
  }), _vm._v(" "), _c("rect", {
    attrs: {
      x: "1",
      y: "20",
      width: "108",
      ry: "0",
      rx: "0",
      height: "2",
      fill: "ghostwhite",
      "stroke-width": "0",
      stroke: "#AAA"
    }
  }), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "grey",
      "font-weight": "400",
      "font-variant-caps": "petite-caps"
    },
    attrs: {
      x: "7",
      y: "25",
      "text-anchor": "start",
      "font-size": "0.95em"
    }
  }, [_vm._v("Journey Toolbox")]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "customer-journey-toolbox-tools"
    }
  }, [_c("g", {
    attrs: {
      refs: "task"
    }
  }, [_c("task-component", {
    directives: [{
      name: "dragged",
      rawName: "v-dragged.prevent",
      value: _vm.onTaskToolDragged,
      expression: "onTaskToolDragged",
      modifiers: {
        prevent: true
      }
    }],
    attrs: {
      id: "tool_task",
      x: _vm.toolbox.task.x - 5,
      y: _vm.toolbox.task.y + 15,
      label: "Step",
      "show-label": true,
      "is-tool": true
    },
    on: {
      mouseover: _vm.onTaskToolMouseOver,
      toolmouseover: _vm.onTaskToolMouseLeave
    }
  })], 1), _vm._v(" "), _c("g", {
    attrs: {
      refs: "phase"
    }
  }, [_c("phase-tool-component", {
    directives: [{
      name: "dragged",
      rawName: "v-dragged.prevent",
      value: _vm.onPhaseToolDragged,
      expression: "onPhaseToolDragged",
      modifiers: {
        prevent: true
      }
    }],
    attrs: {
      x: _vm.toolbox.phase.x - 5,
      y: _vm.toolbox.phase.y + 5,
      length: 120,
      label: "Stage",
      "show-label": true
    },
    on: {
      mouseover: _vm.onPhaseToolMouseOver,
      mouseleave: _vm.onPhaseToolMouseLeave
    }
  })], 1), _vm._v(" "), _c("g", {
    attrs: {
      refs: "idea"
    }
  }, [_c("idea-component", {
    directives: [{
      name: "dragged",
      rawName: "v-dragged.prevent",
      value: _vm.onJourneyIdeaToolDragged,
      expression: "onJourneyIdeaToolDragged",
      modifiers: {
        prevent: true
      }
    }],
    attrs: {
      x: _vm.toolbox.journey_idea.x - 5,
      y: _vm.toolbox.journey_idea.y + 25,
      label: "Idea",
      "show-label": true,
      toolbar: true
    },
    on: {
      mouseover: _vm.onIdeaToolMouseOver,
      mouseleave: _vm.onIdeaToolMouseLeave
    }
  })], 1)])]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "tools-popovers"
    }
  }, [_vm.phaseToolHover && !_vm.isPhaseFocusMode ? _c("g", {
    ref: "phase_tool_hover_popup"
  }, [_c("rect", {
    attrs: {
      x: 2,
      y: 28,
      rx: 5,
      ry: 5,
      width: 122,
      height: 25,
      fill: "rgba(255,255,255)",
      "stroke-width": "1",
      stroke: "#BBB"
    }
  }), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "#555"
    },
    attrs: {
      x: 5,
      y: 38,
      width: 122,
      "text-anchor": "start",
      "font-size": "0.8em",
      "font-weight": "normal"
    }
  }, [_vm._v("Start by dragging stages")]), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "#555"
    },
    attrs: {
      x: 5,
      y: 48,
      width: 122,
      "text-anchor": "start",
      "font-size": "0.8em",
      "font-weight": "normal"
    }
  }, [_vm._v("onto the journey.")])]) : _vm._e(), _vm._v(" "), _vm.taskToolHover ? _c("g", {
    ref: "step_tool_hover_popup"
  }, [_c("rect", {
    attrs: {
      x: 2,
      y: 115,
      rx: 5,
      ry: 5,
      width: 130,
      height: 25,
      fill: "rgba(255,255,255)",
      "stroke-width": "1",
      stroke: "#BBB"
    }
  }), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "#555"
    },
    attrs: {
      x: 5,
      y: 130,
      width: 130,
      "text-anchor": "start",
      "font-size": "0.8em",
      "font-weight": "normal"
    }
  }, [_vm._v("Drag steps inside of stages.")])]) : _vm._e(), _vm._v(" "), _vm.ideaToolHover ? _c("g", {
    ref: "experience_tool_hover_popup"
  }, [_c("rect", {
    attrs: {
      x: 2,
      y: 200,
      rx: 5,
      ry: 5,
      width: 170,
      height: 25,
      fill: "rgba(255,255,255)",
      "stroke-width": "1",
      stroke: "#BBB"
    }
  }), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "#555"
    },
    attrs: {
      x: 5,
      y: 215,
      width: 175,
      "ext-anchor": "start",
      "font-size": "0.8em",
      "font-weight": "normal"
    }
  }, [_vm._v("Add ideas to a stage, step, or journey.")])]) : _vm._e()])], 1) : _c("g", [_c("s-v-g-c-t-a-component", {
    attrs: {
      display: !_vm.isLegacyVersion,
      title: "New version of journeymap detected",
      description: "Would you switch to new journeymap",
      buttonText: ["Switch to Journeymap-2", "Create new Journeymap"],
      x: 5,
      y: 10,
      width: _vm.computeCanvasWidth,
      height: 300
    },
    on: {
      response: _vm.handleCTAResponse
    }
  })], 1)])])])], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Add Step",
      visible: _vm.addTaskDialog,
      width: "60%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addTaskDialog = $event;
      }
    }
  }, [_c("el-form", {
    ref: "addStepform",
    attrs: {
      rules: _vm.addSteprules,
      model: _vm.task_form,
      "label-width": "120px",
      size: "mini"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Step name",
      prop: "title"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.task_form.title,
      callback: function callback($$v) {
        _vm.$set(_vm.task_form, "title", $$v);
      },
      expression: "task_form.title"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Step desc",
      prop: "description"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini",
      type: "textarea"
    },
    model: {
      value: _vm.task_form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.task_form, "description", $$v);
      },
      expression: "task_form.description"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Priority",
      prop: "priority"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.task_form.priority,
      callback: function callback($$v) {
        _vm.$set(_vm.task_form, "priority", $$v);
      },
      expression: "task_form.priority"
    }
  }, [_c("el-radio", {
    attrs: {
      label: "Very High"
    }
  }), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "High"
    }
  }), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "Medium"
    }
  }), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "Low"
    }
  }), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "Very Low"
    }
  })], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Value",
      prop: "importance"
    }
  }, [_c("el-radio-group", {
    model: {
      value: _vm.task_form.importance,
      callback: function callback($$v) {
        _vm.$set(_vm.task_form, "importance", $$v);
      },
      expression: "task_form.importance"
    }
  }, [_c("el-radio", {
    attrs: {
      label: "Very High"
    }
  }), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "High"
    }
  }), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "Medium"
    }
  }), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "Low"
    }
  }), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "Very Low"
    }
  })], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Goal",
      prop: "goal"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini",
      type: "textarea",
      rows: "4"
    },
    model: {
      value: _vm.task_form.goal,
      callback: function callback($$v) {
        _vm.$set(_vm.task_form, "goal", $$v);
      },
      expression: "task_form.goal"
    }
  })], 1)], 1), _vm._v(" "), _c("div", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.addTaskDialog = false;
      }
    }
  }, [_vm._v("Cancel")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.createTask
    }
  }, [_vm._v("Create Step")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: _vm.isPhaseEdit ? "Stage Details" : "Add Stage",
      visible: _vm.addPhaseDialog,
      width: "65%",
      top: _vm.computePhaseDetailDialogTopPosition()
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addPhaseDialog = $event;
      }
    }
  }, [_c("el-tabs", {
    attrs: {
      value: "details"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "Stage Details",
      name: "details"
    }
  }, [_c("el-form", {
    ref: "addStageform",
    attrs: {
      rules: _vm.addStageRules,
      model: _vm.phase_form,
      "label-width": "120px",
      size: "mini"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Stage name",
      prop: "title"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.phase_form.title,
      callback: function callback($$v) {
        _vm.$set(_vm.phase_form, "title", $$v);
      },
      expression: "phase_form.title"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Description",
      prop: "description"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini",
      rows: "4",
      type: "textarea"
    },
    model: {
      value: _vm.phase_form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.phase_form, "description", $$v);
      },
      expression: "phase_form.description"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Stage goal",
      prop: "goal"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini",
      placeholder: "What's the goal to achieve for this stage?"
    },
    model: {
      value: _vm.phase_form.goal,
      callback: function callback($$v) {
        _vm.$set(_vm.phase_form, "goal", $$v);
      },
      expression: "phase_form.goal"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Duration",
      prop: "duration"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.phase_form.duration,
      callback: function callback($$v) {
        _vm.$set(_vm.phase_form, "duration", $$v);
      },
      expression: "phase_form.duration"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Priority",
      prop: "priority"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.phase_form.priority,
      callback: function callback($$v) {
        _vm.$set(_vm.phase_form, "priority", $$v);
      },
      expression: "phase_form.priority"
    }
  }, [_c("el-radio", {
    attrs: {
      label: "Very High"
    }
  }), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "High"
    }
  }), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "Medium"
    }
  }), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "Low"
    }
  }), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "Very Low"
    }
  })], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Value",
      prop: "importance"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.phase_form.importance,
      callback: function callback($$v) {
        _vm.$set(_vm.phase_form, "importance", $$v);
      },
      expression: "phase_form.importance"
    }
  }, [_c("el-radio", {
    attrs: {
      label: "Very High"
    }
  }), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "High"
    }
  }), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "Medium"
    }
  }), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "Low"
    }
  }), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "Very Low"
    }
  })], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "score"
    }
  }, [_c("template", {
    slot: "label"
  }, [_vm._v("\n                  Rank\n                  "), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Rank helps with prioritisation.  Range 1000 - 1",
      placement: "top"
    }
  }, [_c("i", {
    staticClass: "el-icon-question"
  })])], 1), _vm._v(" "), _c("el-input", {
    attrs: {
      size: "mini",
      type: "number",
      placeholder: "Rank help provides finer control of priorities"
    },
    model: {
      value: _vm.phase_form.score,
      callback: function callback($$v) {
        _vm.$set(_vm.phase_form, "score", $$v);
      },
      expression: "phase_form.score"
    }
  })], 2)], 1)], 1)], 1)], 1), _vm._v(" "), _vm.isPhaseEdit ? _c("el-tab-pane", {
    attrs: {
      label: "Steps",
      name: "steps"
    }
  }, [_c("el-table", {
    staticStyle: {
      "word-break": "break-word"
    },
    attrs: {
      data: _vm.selectedPhaseStepList,
      stripe: "",
      size: "mini"
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      width: "60",
      align: "center"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "journeyStep.title",
      label: "Title",
      width: "240"
    }
  }), _vm._v(" "), _c("el-table-column", {
    staticStyle: {
      "word-break": "break-word"
    },
    attrs: {
      prop: "journeyStep.description",
      label: "Description"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "journeyStep.importance",
      label: "Value",
      width: "90",
      align: "center"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "journeyStep.priority",
      label: "Priority",
      width: "90",
      align: "center"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      width: "158"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-button-group", {
          staticStyle: {
            float: "left"
          },
          attrs: {
            size: "mini"
          }
        }, [_c("el-button", {
          attrs: {
            size: "mini",
            circle: "",
            icon: "el-icon-top",
            disabled: scope.$index === 0
          },
          on: {
            click: function click($event) {
              return _vm.movePhaseRelationStageUp(scope.$index);
            }
          }
        }), _vm._v(" "), _c("el-button", {
          attrs: {
            size: "mini",
            circle: "",
            icon: "el-icon-bottom",
            disabled: scope.$index === _vm.selectedPhaseStepList.length - 1
          },
          on: {
            click: function click($event) {
              return _vm.movePhaseRelationStageDown(scope.$index);
            }
          }
        })], 1), _vm._v(" "), _c("el-button", {
          staticClass: "ml-5",
          attrs: {
            size: "mini",
            type: "secondary",
            circle: "",
            icon: "el-icon-edit"
          },
          on: {
            click: function click($event) {
              return _vm.selectTaskFromPhaseDialog(scope.$index, scope.row);
            }
          }
        }), _vm._v(" "), _c("el-button", {
          attrs: {
            size: "mini",
            type: "danger",
            circle: "",
            icon: "el-icon-delete"
          },
          on: {
            click: function click($event) {
              return _vm.initiateDeleteStep(scope.$index, scope.row.id);
            }
          }
        })];
      }
    }], null, false, 125080550)
  }, [_c("template", {
    slot: "header"
  }, [_c("el-button", {
    staticClass: "fr",
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.createTaskFromPhase(_vm.selectedPhaseIndex);
      }
    }
  }, [_vm._v("Add Step")])], 1)], 2)], 1)], 1) : _vm._e(), _vm._v(" "), _vm.isPhaseEdit ? _c("el-tab-pane", {
    attrs: {
      label: "Experiences",
      name: "experience"
    }
  }, [_vm.isPhaseEdit ? _c("el-row", {
    attrs: {
      gutter: 10
    }
  }, [_c("el-col", {
    staticClass: "text-left",
    attrs: {
      span: 24
    }
  }, [_c("div", {
    staticStyle: {
      "background-color": "darkslategrey",
      color: "white",
      "border-radius": "4pt",
      height: "24pt",
      "padding-top": "4pt"
    }
  }, [_c("span", {
    staticStyle: {
      "padding-left": "8pt"
    }
  }, [_vm._v("Stage Experiences")]), _vm._v(" "), _c("el-dropdown", {
    staticClass: "fr"
  }, [_c("el-button", {
    staticStyle: {
      padding: "3pt 5pt 3pt 8pt",
      top: "-4pt",
      "margin-right": "6pt"
    },
    attrs: {
      type: "secondary",
      size: "mini"
    }
  }, [_vm._v("\n                  Add Experience "), _c("i", {
    staticClass: "el-icon-arrow-down el-icon--right"
  })]), _vm._v(" "), _c("el-dropdown-menu", {
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, [_c("el-dropdown-item", [_c("svg-icon", {
    staticStyle: {
      "font-size": "14px",
      color: "green",
      "margin-right": "4pt"
    },
    attrs: {
      "icon-class": "smiley"
    }
  }), _c("el-button", {
    attrs: {
      type: "text",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.createGainFromPhase(_vm.selectedPhaseIndex);
      }
    }
  }, [_vm._v("Gain")])], 1), _vm._v(" "), _c("el-dropdown-item", [_c("svg-icon", {
    staticStyle: {
      "font-size": "14px",
      color: "green",
      "margin-right": "4pt"
    },
    attrs: {
      "icon-class": "danger-circle"
    }
  }), _c("el-button", {
    attrs: {
      type: "text",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.createPainFromPhase(_vm.selectedPhaseIndex);
      }
    }
  }, [_vm._v("Pain")])], 1), _vm._v(" "), _c("el-dropdown-item", [_c("svg-icon", {
    staticStyle: {
      "font-size": "14px",
      color: "green",
      "margin-right": "4pt"
    },
    attrs: {
      "icon-class": "wow_blue"
    }
  }), _c("el-button", {
    attrs: {
      type: "text",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.createWowFromPhase(_vm.selectedPhaseIndex);
      }
    }
  }, [_vm._v("Wow")])], 1), _vm._v(" "), _c("el-dropdown-item", [_c("svg-icon", {
    staticStyle: {
      "font-size": "14px",
      color: "green",
      "margin-right": "4pt"
    },
    attrs: {
      "icon-class": "oscurity"
    }
  }), _c("el-button", {
    attrs: {
      type: "text",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.createChallengeFromPhase(_vm.selectedPhaseIndex);
      }
    }
  }, [_vm._v("Obscurity")])], 1)], 1)], 1)], 1), _vm._v(" "), _c("el-table", {
    attrs: {
      data: _vm.selectedPhaseExperienceList,
      stripe: "",
      size: "mini"
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Type",
      width: "53"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.journeyExperience.experiencetype === "GAIN" ? _c("svg-icon", {
          staticStyle: {
            "font-size": "22px",
            color: "green"
          },
          attrs: {
            "icon-class": "smiley"
          }
        }) : _vm._e(), _vm._v(" "), scope.row.journeyExperience.experiencetype === "PAIN" ? _c("svg-icon", {
          staticStyle: {
            "font-size": "22px"
          },
          attrs: {
            "icon-class": "danger-circle"
          }
        }) : _vm._e(), _vm._v(" "), scope.row.journeyExperience.experiencetype === "WOW" ? _c("svg-icon", {
          staticStyle: {
            "font-size": "22px"
          },
          attrs: {
            "icon-class": "wow_blue"
          }
        }) : _vm._e(), _vm._v(" "), scope.row.journeyExperience.experiencetype === "CHALLENGE" ? _c("svg-icon", {
          staticStyle: {
            "font-size": "22px"
          },
          attrs: {
            "icon-class": "oscurity"
          }
        }) : _vm._e()];
      }
    }], null, false, 1074242314)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "journeyExperience.title",
      label: "Experience Title",
      width: "280"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "journeyExperience.description",
      label: "Experience Description"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "journeyExperience.importance",
      label: "Value",
      width: "80"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "journeyExperience.priority",
      label: "Priority",
      width: "80"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      width: "120"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-button", {
          staticStyle: {
            float: "right"
          },
          attrs: {
            size: "mini",
            type: "danger",
            icon: "el-icon-delete",
            circle: ""
          },
          on: {
            click: function click($event) {
              return _vm.deleteExperience(scope.row.id);
            }
          }
        }), _vm._v(" "), _c("el-button", {
          staticClass: "mr-5 ml-5",
          staticStyle: {
            float: "right"
          },
          attrs: {
            size: "mini",
            type: "secondary",
            icon: "el-icon-edit",
            circle: ""
          },
          on: {
            click: function click($event) {
              return _vm.editExperienceDetails(scope.$index, scope.row);
            }
          }
        })];
      }
    }], null, false, 1446498620)
  }, [_c("template", {
    slot: "header"
  })], 2)], 1)], 1)], 1) : _vm._e(), _vm._v(" "), _c("el-row", {
    staticClass: "mt-20"
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("div", {
    staticStyle: {
      "background-color": "lightslategrey",
      color: "white",
      "border-radius": "4pt",
      height: "24pt",
      "padding-top": "4pt"
    }
  }, [_c("span", {
    staticStyle: {
      "padding-left": "8pt"
    }
  }, [_vm._v("Steps & Step Experiences")])]), _vm._v(" "), _c("el-table", {
    attrs: {
      data: _vm.selectedPhaseStepList,
      size: "mini"
    }
  }, [_c("el-table-column", {
    staticStyle: {
      "vertical-align": "top"
    },
    attrs: {
      label: "Step",
      width: "60",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("span", {
          staticStyle: {
            "border-radius": "15pt",
            padding: "1pt 3pt 1pt 3pt",
            "nmargin-right": "5pt",
            "background-color": "lightslategrey",
            color: "white",
            width: "22pt",
            height: "22pt"
          }
        }, [_vm._v(" " + _vm._s(scope.$index + 1))])];
      }
    }], null, false, 2845929259)
  }), _vm._v(" "), _c("el-table-column", {
    staticStyle: {
      "vertical-align": "top"
    },
    attrs: {
      label: "Step Title",
      width: "600"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("span", [_c("strong", [_vm._v(_vm._s(scope.row.journeyStep.title))])]), _vm._v(" "), _c("el-dropdown", {
          staticClass: "fr"
        }, [_c("el-button", {
          staticStyle: {
            padding: "6pt 2pt 3pt 6pt",
            top: "-4pt",
            "margin-left": "2pt"
          },
          attrs: {
            type: "secondary",
            size: "mini"
          }
        }, [_vm._v("\n                          Add "), _c("i", {
          staticClass: "el-icon-arrow-down el-icon--right"
        })]), _vm._v(" "), _c("el-dropdown-menu", {
          attrs: {
            slot: "dropdown"
          },
          slot: "dropdown"
        }, [_c("el-dropdown-item", [_c("div", {
          on: {
            click: function click($event) {
              _vm.createGainFromTask(_vm.computeStepIndexFromID(scope.row.journeyStep.id));
            }
          }
        }, [_c("svg-icon", {
          staticStyle: {
            "font-size": "14px",
            color: "green",
            "margin-right": "4pt"
          },
          attrs: {
            "icon-class": "smiley"
          }
        }), _c("el-button", {
          attrs: {
            type: "text",
            size: "mini"
          }
        }, [_vm._v("Gain")])], 1)]), _vm._v(" "), _c("el-dropdown-item", [_c("div", {
          on: {
            click: function click($event) {
              _vm.createPainFromTask(_vm.computeStepIndexFromID(scope.row.journeyStep.id));
            }
          }
        }, [_c("svg-icon", {
          staticStyle: {
            "font-size": "14px",
            color: "green",
            "margin-right": "4pt"
          },
          attrs: {
            "icon-class": "danger-circle"
          }
        }), _c("el-button", {
          attrs: {
            type: "text",
            size: "mini"
          }
        }, [_vm._v("Pain")])], 1)]), _vm._v(" "), _c("el-dropdown-item", [_c("div", {
          on: {
            click: function click($event) {
              _vm.createWowFromTask(_vm.computeStepIndexFromID(scope.row.journeyStep.id));
            }
          }
        }, [_c("svg-icon", {
          staticStyle: {
            "font-size": "14px",
            color: "green",
            "margin-right": "4pt"
          },
          attrs: {
            "icon-class": "wow_blue"
          }
        }), _c("el-button", {
          attrs: {
            type: "text",
            size: "mini"
          }
        }, [_vm._v("Wow")])], 1)]), _vm._v(" "), _c("el-dropdown-item", [_c("div", {
          on: {
            click: function click($event) {
              _vm.createChallengeFromTask(_vm.computeStepIndexFromID(scope.row.journeyStep.id));
            }
          }
        }, [_c("svg-icon", {
          staticStyle: {
            "font-size": "14px",
            color: "green",
            "margin-right": "4pt"
          },
          attrs: {
            "icon-class": "oscurity"
          }
        }), _c("el-button", {
          attrs: {
            type: "text",
            size: "mini"
          }
        }, [_vm._v("Obscurity")])], 1)])], 1)], 1)];
      }
    }], null, false, 1948354644)
  }), _vm._v(" "), _c("el-table-column", {
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn(scope) {
        return [_c("el-row", {
          attrs: {
            gutter: 20
          }
        }, [_c("el-col", {
          attrs: {
            span: 1
          }
        }), _vm._v(" "), _c("el-col", {
          attrs: {
            span: 14
          }
        }, [_vm._v("Experience")]), _vm._v(" "), _c("el-col", {
          attrs: {
            span: 3
          }
        }, [_vm._v("Value")]), _vm._v(" "), _c("el-col", {
          attrs: {
            span: 3
          }
        }, [_vm._v("Priority")])], 1)];
      }
    }, {
      key: "default",
      fn: function fn(scope) {
        return [_vm.computeExperienceListForStep(scope.row.journeyStep.id).length > 0 ? _c("div", {}, _vm._l(_vm.computeExperienceListForStep(scope.row.journeyStep.id), function (experience, experience_index) {
          return _c("div", {
            staticClass: "phase-step-experience-item",
            attrs: {
              index: experience_index
            }
          }, [_c("el-row", {
            attrs: {
              gutter: 20
            }
          }, [_c("el-col", {
            attrs: {
              span: 1
            }
          }, [experience.journeyExperience.experiencetype === "GAIN" ? _c("svg-icon", {
            staticStyle: {
              "font-size": "22px",
              color: "green"
            },
            attrs: {
              "icon-class": "smiley"
            }
          }) : _vm._e(), _vm._v(" "), experience.journeyExperience.experiencetype === "PAIN" ? _c("svg-icon", {
            staticStyle: {
              "font-size": "22px"
            },
            attrs: {
              "icon-class": "danger-circle"
            }
          }) : _vm._e(), _vm._v(" "), experience.journeyExperience.experiencetype === "WOW" ? _c("svg-icon", {
            staticStyle: {
              "font-size": "22px"
            },
            attrs: {
              "icon-class": "wow_blue"
            }
          }) : _vm._e(), _vm._v(" "), experience.journeyExperience.experiencetype === "CHALLENGE" ? _c("svg-icon", {
            staticStyle: {
              "font-size": "22px"
            },
            attrs: {
              "icon-class": "oscurity"
            }
          }) : _vm._e()], 1), _vm._v(" "), _c("el-col", {
            attrs: {
              span: 14
            }
          }, [_vm._v("\n                          " + _vm._s(experience.journeyExperience.title) + "\n                        ")]), _vm._v(" "), _c("el-col", {
            attrs: {
              span: 3
            }
          }, [_vm._v("\n                          " + _vm._s(experience.journeyExperience.priority) + "\n                        ")]), _vm._v(" "), _c("el-col", {
            attrs: {
              span: 3
            }
          }, [_vm._v("\n                          " + _vm._s(experience.journeyExperience.additionalattributes.customer_value) + "\n                        ")]), _vm._v(" "), _c("el-col", {
            attrs: {
              span: 3
            }
          }, [_c("el-button", {
            staticStyle: {
              float: "right"
            },
            attrs: {
              size: "mini",
              type: "danger",
              icon: "el-icon-delete",
              circle: ""
            },
            on: {
              click: function click($event) {
                return _vm.deleteExperience(experience.journeyExperience.id);
              }
            }
          }), _vm._v(" "), _c("el-button", {
            staticClass: "mr-5 ml-5",
            staticStyle: {
              float: "right"
            },
            attrs: {
              size: "mini",
              type: "secondary",
              icon: "el-icon-edit",
              circle: ""
            },
            on: {
              click: function click($event) {
                return _vm.editExperienceDetails(experience_index, experience);
              }
            }
          })], 1)], 1)], 1);
        }), 0) : _c("div", [_c("span", {
          staticStyle: {
            "font-style": "italic",
            color: "grey"
          }
        }, [_vm._v("No experiences for this step")])])];
      }
    }], null, false, 131717688)
  })], 1)], 1)], 1)], 1) : _vm._e(), _vm._v(" "), _vm.isPhaseEdit ? _c("el-tab-pane", {
    attrs: {
      label: "Tickets & Progress",
      name: "phase_tracking"
    }
  }, [_vm._v('\n        If no mapped offering exists  - say this in the UI. "tracking unavailable"\n        '), _c("br"), _vm._v("          Shows progress of journey using Features and Story tracking as the dimensions.\n\n        "), _c("ul", [_c("i", [_vm._v("MVP tracks progress by")]), _vm._v(" "), _c("li", [_vm._v("\n            Stage progress is based on the progress of steps being achieved (by features being achieved).\n            Stage progress only shows step progress - not features\n          ")]), _vm._v(" "), _c("li", [_vm._v('\n            Step progress is measured by mapped features. .\n            Step progress shows feature completion, and stories within the feature.\n            If story mapped to actions, the action mapped to the story/ies is shown.\n            Use a table, columns "stage, step, action, feature, story,story/features status, percentage" at minimum\n          ')]), _vm._v(" "), _c("li", [_vm._v("\n            Stories can not map to steps, features do. Stories map to actions, if actions exist, other wise they implicity map through the feature.\n          ")]), _vm._v(" "), _c("li", [_vm._v("\n            MVP doesn't need upstream mapping to Needs Completion / satisifaction.  Cross-reporting module does that.\n          ")])])]) : _vm._e()], 1), _vm._v(" "), _c("div", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.addPhaseDialog = false;
      }
    }
  }, [_vm._v("Cancel")]), _vm._v(" "), !_vm.isPhaseEdit ? _c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.createPhase
    }
  }, [_vm._v("Create Stage")]) : _vm._e(), _vm._v(" "), _vm.isPhaseEdit ? _c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.updatePhaseDetails
    }
  }, [_vm._v("Update Stage")]) : _vm._e(), _vm._v(" "), _vm.isPhaseEdit ? _c("el-button", {
    attrs: {
      size: "mini",
      type: "danger"
    },
    on: {
      click: _vm.deletePhase
    }
  }, [_vm._v("Delete Stage")]) : _vm._e()], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Add Experience : Select Step",
      visible: _vm.displayCreateStepLevelExperienceSelectionDialog,
      width: "40%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.displayCreateStepLevelExperienceSelectionDialog = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.step_selection_form,
      "label-width": "150px"
    }
  }, [_vm.displayCreateStepLevelExperienceSelectionDialog ? _c("el-form-item", {
    attrs: {
      label: "Select step"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Select",
      size: "mini"
    },
    model: {
      value: _vm.step_selection_form.step_id,
      callback: function callback($$v) {
        _vm.$set(_vm.step_selection_form, "step_id", $$v);
      },
      expression: "step_selection_form.step_id"
    }
  }, _vm._l(_vm.sortedStages[_vm.selectedPhaseIndex].journeyPhase.relations, function (step_id) {
    return _c("el-option", {
      key: step_id,
      attrs: {
        label: _vm.getStepTitleFromID(step_id),
        value: step_id
      }
    });
  }), 1)], 1) : _vm._e(), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Experience type"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.step_selection_form.experience_type,
      callback: function callback($$v) {
        _vm.$set(_vm.step_selection_form, "experience_type", $$v);
      },
      expression: "step_selection_form.experience_type"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: "Pain"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Gain"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Wow"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("div", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.createStepLevelExperience
    }
  }, [_vm._v("Create Experience")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "secondary",
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.displayCreateStepLevelExperienceSelectionDialog = false;
      }
    }
  }, [_vm._v("Close")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Step Details",
      visible: _vm.taskDetailsDialog,
      width: "60%",
      top: "5vh",
      "custom-class": "step-details"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.taskDetailsDialog = $event;
      }
    }
  }, [_c("el-tabs", {
    attrs: {
      value: "step_details"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "Step Details",
      name: "step_details"
    }
  }, [_vm.taskDetailsDialog ? _c("el-form", {
    ref: "addStepform",
    attrs: {
      rules: _vm.addSteprules,
      model: _vm.step_form,
      "label-width": "150px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Title",
      prop: "title"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.step_form.title,
      callback: function callback($$v) {
        _vm.$set(_vm.step_form, "title", $$v);
      },
      expression: "step_form.title"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Description",
      prop: "description"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini",
      type: "textarea",
      rows: "6"
    },
    model: {
      value: _vm.step_form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.step_form, "description", $$v);
      },
      expression: "step_form.description"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Priority",
      prop: "priority"
    }
  }, [_c("el-radio-group", {
    model: {
      value: _vm.step_form.priority,
      callback: function callback($$v) {
        _vm.$set(_vm.step_form, "priority", $$v);
      },
      expression: "step_form.priority"
    }
  }, [_c("el-radio", {
    attrs: {
      label: "Very High"
    }
  }), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "High"
    }
  }), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "Medium"
    }
  }), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "Low"
    }
  }), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "Very Low"
    }
  })], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Value",
      prop: "importance"
    }
  }, [_c("el-radio-group", {
    model: {
      value: _vm.step_form.importance,
      callback: function callback($$v) {
        _vm.$set(_vm.step_form, "importance", $$v);
      },
      expression: "step_form.importance"
    }
  }, [_c("el-radio", {
    attrs: {
      label: "Very High"
    }
  }), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "High"
    }
  }), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "Medium"
    }
  }), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "Low"
    }
  }), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "Very Low"
    }
  })], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Step Goal",
      prop: "goal"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini",
      type: "textarea",
      rows: "4"
    },
    model: {
      value: _vm.step_form.goal,
      callback: function callback($$v) {
        _vm.$set(_vm.step_form, "goal", $$v);
      },
      expression: "step_form.goal"
    }
  })], 1)], 1) : _vm._e()], 1), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: "Experiences",
      name: "step_experiences"
    }
  }, [_c("div", {
    staticStyle: {
      "background-color": "lightslategrey",
      color: "white",
      "border-radius": "4pt",
      height: "24pt",
      "padding-top": "4pt"
    }
  }, [_c("span", {
    staticStyle: {
      "padding-left": "8pt"
    }
  }, [_vm._v("Step Experience")]), _vm._v(" "), _c("el-dropdown", {
    staticClass: "fr"
  }, [_c("el-button", {
    staticStyle: {
      padding: "3pt 5pt 3pt 8pt",
      top: "-4pt",
      "margin-right": "6pt"
    },
    attrs: {
      type: "Plain",
      size: "mini"
    }
  }, [_vm._v("\n              Add Experience "), _c("i", {
    staticClass: "el-icon-arrow-down el-icon--right"
  })]), _vm._v(" "), _c("el-dropdown-menu", {
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, [_c("el-dropdown-item", [_c("svg-icon", {
    staticStyle: {
      "font-size": "14px",
      color: "green",
      "margin-right": "4pt"
    },
    attrs: {
      "icon-class": "smiley"
    }
  }), _c("el-button", {
    attrs: {
      type: "text",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.createGainFromTask(_vm.selectedTaskIndex);
      }
    }
  }, [_vm._v("Gain")])], 1), _vm._v(" "), _c("el-dropdown-item", [_c("svg-icon", {
    staticStyle: {
      "font-size": "14px",
      color: "green",
      "margin-right": "4pt"
    },
    attrs: {
      "icon-class": "danger-circle"
    }
  }), _c("el-button", {
    attrs: {
      type: "text",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.createPainFromTask(_vm.selectedTaskIndex);
      }
    }
  }, [_vm._v("Pain")])], 1), _vm._v(" "), _c("el-dropdown-item", [_c("svg-icon", {
    staticStyle: {
      "font-size": "14px",
      color: "green",
      "margin-right": "4pt"
    },
    attrs: {
      "icon-class": "wow_blue"
    }
  }), _c("el-button", {
    attrs: {
      type: "text",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.createWowFromTask(_vm.selectedTaskIndex);
      }
    }
  }, [_vm._v("Wow")])], 1), _vm._v(" "), _c("el-dropdown-item", [_c("svg-icon", {
    staticStyle: {
      "font-size": "14px",
      color: "green",
      "margin-right": "4pt"
    },
    attrs: {
      "icon-class": "oscurity"
    }
  }), _c("el-button", {
    attrs: {
      type: "text",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.createChallengeFromTask(_vm.selectedTaskIndex);
      }
    }
  }, [_vm._v("Obscurity")])], 1)], 1)], 1)], 1), _vm._v(" "), _vm.taskDetailsDialog ? _c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.filteredStepRelatedExperience,
      size: "mini",
      fit: true
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Type",
      width: "53"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.journeyExperience.experiencetype === "GAIN" ? _c("svg-icon", {
          staticStyle: {
            "font-size": "22px",
            color: "green"
          },
          attrs: {
            "icon-class": "smiley"
          }
        }) : _vm._e(), _vm._v(" "), scope.row.journeyExperience.experiencetype === "PAIN" ? _c("svg-icon", {
          staticStyle: {
            "font-size": "22px"
          },
          attrs: {
            "icon-class": "danger-circle"
          }
        }) : _vm._e(), _vm._v(" "), scope.row.journeyExperience.experiencetype === "WOW" ? _c("svg-icon", {
          staticStyle: {
            "font-size": "22px"
          },
          attrs: {
            "icon-class": "wow_blue"
          }
        }) : _vm._e(), _vm._v(" "), scope.row.journeyExperience.experiencetype === "CHALLENGE" ? _c("svg-icon", {
          staticStyle: {
            "font-size": "22px"
          },
          attrs: {
            "icon-class": "oscurity"
          }
        }) : _vm._e()];
      }
    }], null, false, 1074242314)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "journeyExperience.title",
      label: "Title",
      width: "280"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "journeyExperience.description",
      label: "Experience Description"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "journeyExperience.additionalattributes.customer_value",
      label: "Value",
      width: "80"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "journeyExperience.priority",
      label: "Priority",
      width: "80"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "",
      width: "160"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-button", {
          staticStyle: {
            float: "right"
          },
          attrs: {
            size: "mini",
            type: "danger",
            icon: "el-icon-delete",
            circle: ""
          },
          on: {
            click: function click($event) {
              return _vm.deleteExperience(scope.row.journeyExperience.id);
            }
          }
        }), _vm._v(" "), _c("el-button", {
          staticClass: "mr-5 ml-5",
          staticStyle: {
            float: "right"
          },
          attrs: {
            size: "mini",
            type: "secondary",
            icon: "el-icon-edit",
            circle: ""
          },
          on: {
            click: function click($event) {
              return _vm.editExperienceDetails(scope.$index, scope.row);
            }
          }
        })];
      }
    }], null, false, 3279515836)
  })], 1) : _vm._e()], 1), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: "Tickets & Progress",
      name: "step_progress"
    }
  }, [_vm._v("\n        To be Updated\n      ")])], 1), _vm._v(" "), _c("div", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.taskDetailsDialog = false;
      }
    }
  }, [_vm._v("Close")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.updateStepDetails
    }
  }, [_vm._v("Update Step")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini",
      type: "danger"
    },
    on: {
      click: _vm.deleteStep
    }
  }, [_vm._v("Delete Step")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      visible: _vm.createExperienceFromTaskDialog,
      width: "50%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.createExperienceFromTaskDialog = $event;
      }
    }
  }, [_c("div", {
    staticClass: "text-left",
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_c("div", {
    staticStyle: {
      "font-weight": "600"
    }
  }, [_vm._v("Add " + _vm._s(_vm.experience_form.header_title) + " ")]), _vm._v(" "), _c("div", {
    staticStyle: {
      "font-size": "10pt",
      "font-weight": "400"
    }
  }, [_vm._v(_vm._s(_vm.experience_form.header_description))])]), _vm._v(" "), _c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.experience_form,
      "label-width": "250px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: _vm.experience_form.type + " name"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.experience_form.title,
      callback: function callback($$v) {
        _vm.$set(_vm.experience_form, "title", $$v);
      },
      expression: "experience_form.title"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: _vm.experience_form.type + " description"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      size: "mini"
    },
    model: {
      value: _vm.experience_form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.experience_form, "description", $$v);
      },
      expression: "experience_form.description"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: _vm.experience_form.type + " priority"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.experience_form.priority,
      callback: function callback($$v) {
        _vm.$set(_vm.experience_form, "priority", $$v);
      },
      expression: "experience_form.priority"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: "Very Low"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Low"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Medium"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "High"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Very High"
    }
  })], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: _vm.experience_form.type + " value"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.experience_form.customer_value,
      callback: function callback($$v) {
        _vm.$set(_vm.experience_form, "customer_value", $$v);
      },
      expression: "experience_form.customer_value"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: "Very Low"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Low"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Medium"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "High"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Very High"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("div", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.createExperienceFromTaskDialog = false;
      }
    }
  }, [_vm._v("Cancel")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.createExperienceFromTask
    }
  }, [_vm._v("Create")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Create sub step",
      visible: _vm.createSubTaskDialog,
      width: "50%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.createSubTaskDialog = $event;
      }
    }
  }, [_c("el-form", {
    ref: "addStepform",
    attrs: {
      rules: _vm.addSteprules,
      model: _vm.task_form,
      "label-width": "120px",
      size: "mini"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Step name",
      prop: "title"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.task_form.title,
      callback: function callback($$v) {
        _vm.$set(_vm.task_form, "title", $$v);
      },
      expression: "task_form.title"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Step desc",
      prop: "description"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      size: "mini"
    },
    model: {
      value: _vm.task_form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.task_form, "description", $$v);
      },
      expression: "task_form.description"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Step goal",
      prop: "goal"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      size: "mini"
    },
    model: {
      value: _vm.task_form.goal,
      callback: function callback($$v) {
        _vm.$set(_vm.task_form, "goal", $$v);
      },
      expression: "task_form.goal"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Priority",
      prop: "priority"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.task_form.priority,
      callback: function callback($$v) {
        _vm.$set(_vm.task_form, "priority", $$v);
      },
      expression: "task_form.priority"
    }
  }, [_c("el-radio", {
    attrs: {
      label: "Very High"
    }
  }), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "High"
    }
  }), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "Medium"
    }
  }), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "Low"
    }
  }), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "Very Low"
    }
  })], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Value",
      prop: "importance"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.task_form.importance,
      callback: function callback($$v) {
        _vm.$set(_vm.task_form, "importance", $$v);
      },
      expression: "task_form.importance"
    }
  }, [_c("el-radio", {
    attrs: {
      label: "Very High"
    }
  }), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "High"
    }
  }), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "Medium"
    }
  }), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "Low"
    }
  }), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "Very Low"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("div", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.createSubTaskDialog = false;
      }
    }
  }, [_vm._v("Cancel")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.createSubTask
    }
  }, [_vm._v("Create")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      visible: _vm.createExperienceFromPhaseDialog,
      width: "40%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.createExperienceFromPhaseDialog = $event;
      }
    }
  }, [_c("div", {
    staticClass: "text-left",
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_c("div", {
    staticStyle: {
      "font-weight": "600"
    }
  }, [_vm._v("Add " + _vm._s(_vm.experience_form.header_title) + " ")]), _vm._v(" "), _c("div", {
    staticStyle: {
      "font-size": "10pt",
      "font-weight": "400"
    }
  }, [_vm._v(_vm._s(_vm.experience_form.header_description))])]), _vm._v(" "), _c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.experience_form,
      "label-width": "150px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Name"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.experience_form.title,
      callback: function callback($$v) {
        _vm.$set(_vm.experience_form, "title", $$v);
      },
      expression: "experience_form.title"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Description"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini",
      type: "textarea"
    },
    model: {
      value: _vm.experience_form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.experience_form, "description", $$v);
      },
      expression: "experience_form.description"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Priority"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.experience_form.priority,
      callback: function callback($$v) {
        _vm.$set(_vm.experience_form, "priority", $$v);
      },
      expression: "experience_form.priority"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: "Very Low"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Low"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Medium"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "High"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Very High"
    }
  })], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Value"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.experience_form.customer_value,
      callback: function callback($$v) {
        _vm.$set(_vm.experience_form, "customer_value", $$v);
      },
      expression: "experience_form.customer_value"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: "Very Low"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Low"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Medium"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "High"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Very High"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("div", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.createExperienceFromPhaseDialog = false;
      }
    }
  }, [_vm._v("Cancel")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.createExperienceFromPhase
    }
  }, [_vm._v("Create")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Actions",
      visible: _vm.actionDetailsDialog,
      width: "95%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.actionDetailsDialog = $event;
      }
    }
  }, [_c("action-component", {
    attrs: {
      entity: {
        id: _vm.journeyMap.id,
        type: "JOURNEYMAP"
      }
    }
  }), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.actionDetailsDialog = false;
      }
    }
  }, [_vm._v("Close")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Journey Menu",
      visible: _vm.settingsDialog,
      width: "50%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.settingsDialog = $event;
      }
    }
  }, [_c("el-form", {
    attrs: {
      inline: true,
      model: _vm.journey_map_form
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Journey Map Title"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "Title",
      size: "mini"
    },
    model: {
      value: _vm.journey_map_form.title,
      callback: function callback($$v) {
        _vm.$set(_vm.journey_map_form, "title", $$v);
      },
      expression: "journey_map_form.title"
    }
  })], 1), _vm._v(" "), _c("el-form-item", [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.renameJourneyMapTitle
    }
  }, [_vm._v("Rename")])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-table", {
    attrs: {
      data: _vm.journeySettings,
      stripe: "",
      size: "small"
    }
  }, [_c("el-table-column", {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-row", [_c("el-col", {
          attrs: {
            span: 4
          }
        }, [_c("Strong", [_vm._v(_vm._s(scope.row.title))])], 1), _vm._v(" "), _c("el-col", {
          staticStyle: {
            "word-break": "keep-all"
          },
          attrs: {
            span: 11
          }
        }, [_vm._v(_vm._s(scope.row.description) + " ")]), _vm._v(" "), _c("el-col", {
          attrs: {
            span: 7
          }
        }, [scope.row.note.type ? _c("el-alert", {
          staticStyle: {
            "margin-left": "20pt",
            "font-size": "10pt",
            "word-break": "keep-all",
            padding: "2pt"
          },
          attrs: {
            title: scope.row.note.message,
            type: scope.row.note.type,
            closable: false,
            "show-icon": ""
          }
        }) : _vm._e()], 1)], 1)];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      width: "180"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.type === "copy" ? _c("el-button", {
          staticStyle: {
            width: "100px"
          },
          attrs: {
            type: "primary",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.openJourneyListDialog();
            }
          }
        }, [_vm._v("Copy")]) : _vm._e(), _vm._v(" "), scope.row.type === "generate" ? _c("el-button", {
          staticStyle: {
            width: "100px"
          },
          attrs: {
            type: "primary",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.regenerateJourneyMapConfirmation();
            }
          }
        }, [_vm._v("Generate")]) : _vm._e(), _vm._v(" "), scope.row.type === "delete" ? _c("el-button", {
          staticStyle: {
            width: "100px"
          },
          attrs: {
            type: "danger",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.deleteJourneyConfirmation();
            }
          }
        }, [_vm._v("Delete")]) : _vm._e(), _vm._v(" "), scope.row.type === "version" ? _c("el-button", {
          staticStyle: {
            width: "100px"
          },
          attrs: {
            type: "primary",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.openJourneyVersionsDialog();
            }
          }
        }, [_vm._v("Versions")]) : _vm._e()];
      }
    }])
  })], 1)], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.settingsDialog = false;
      }
    }
  }, [_vm._v("Close")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Select Journey to Copy",
      visible: _vm.journeyListDialog,
      width: "50%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.journeyListDialog = $event;
      }
    }
  }, [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      data: _vm.journeyList,
      stripe: "",
      size: "mini"
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "title",
      label: "Title"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "description",
      label: "Description"
    }
  }), _vm._v(" "), _c("el-table-column", {
    staticClass: "text-center",
    attrs: {
      label: "#",
      width: "150"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-dropdown", {
          attrs: {
            size: "mini",
            "split-button": "",
            type: "primary"
          }
        }, [_vm._v("\n            Copy\n            "), _c("el-dropdown-menu", {
          attrs: {
            slot: "dropdown"
          },
          slot: "dropdown"
        }, [_c("el-dropdown-item", [_c("el-button", {
          staticStyle: {
            width: "180px"
          },
          attrs: {
            type: "primary",
            size: "mini",
            "el-icon": scope.row.loading ? "el-icon-loading" : "el-icon-document-copy"
          },
          on: {
            click: function click($event) {
              return _vm.cloneJourneyMap(scope.$index, scope.row.id, ["JOURNEY_PHASE"]);
            }
          }
        }, [_vm._v("Phase")])], 1), _vm._v(" "), _c("el-dropdown-item", [_c("el-button", {
          staticStyle: {
            width: "180px"
          },
          attrs: {
            type: "primary",
            size: "mini",
            "el-icon": scope.row.loading ? "el-icon-loading" : "el-icon-document-copy"
          },
          on: {
            click: function click($event) {
              return _vm.cloneJourneyMap(scope.$index, scope.row.id, ["JOURNEY_PHASE", "JOURNEY_STEP"]);
            }
          }
        }, [_vm._v("Phase & Step")])], 1), _vm._v(" "), _c("el-dropdown-item", [_c("el-button", {
          staticStyle: {
            width: "180px"
          },
          attrs: {
            type: "primary",
            size: "mini",
            "el-icon": scope.row.loading ? "el-icon-loading" : "el-icon-document-copy"
          },
          on: {
            click: function click($event) {
              return _vm.cloneJourneyMap(scope.$index, scope.row.id, ["JOURNEY_EXPERIENCE", "JOURNEY_PHASE", "JOURNEY_STEP"]);
            }
          }
        }, [_vm._v("Phase, Step & Experience")])], 1)], 1)], 1)];
      }
    }])
  })], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.journeyListDialog = false;
      }
    }
  }, [_vm._v("Close")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Select Journey version",
      visible: _vm.journeyVersionsDialog,
      width: "50%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.journeyVersionsDialog = $event;
      }
    }
  }, [_c("el-table", {
    attrs: {
      data: _vm.journeyVersion,
      stripe: "",
      size: "mini"
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "title",
      label: "Title"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "version",
      label: "Version"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-tag", {
          attrs: {
            size: "mini",
            type: "primary"
          }
        }, [_vm._v(_vm._s(scope.row.version))])];
      }
    }])
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "#"
    }
  }, [[_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    }
  }, [_vm._v("Select")])]], 2)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.journeyVersionsDialog = false;
      }
    }
  }, [_vm._v("Close")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Experience details",
      visible: _vm.editExperienceDetailDialog,
      width: "50%",
      left: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.editExperienceDetailDialog = $event;
      }
    }
  }, [_c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.experience_form,
      "label-width": "150px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Name"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.experience_form.title,
      callback: function callback($$v) {
        _vm.$set(_vm.experience_form, "title", $$v);
      },
      expression: "experience_form.title"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Description"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini",
      type: "textarea",
      rows: 6
    },
    model: {
      value: _vm.experience_form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.experience_form, "description", $$v);
      },
      expression: "experience_form.description"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Priority"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.experience_form.priority,
      callback: function callback($$v) {
        _vm.$set(_vm.experience_form, "priority", $$v);
      },
      expression: "experience_form.priority"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: "Very High"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "High"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Medium"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Low"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Very Low"
    }
  })], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Customer value"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.experience_form.customer_value,
      callback: function callback($$v) {
        _vm.$set(_vm.experience_form, "customer_value", $$v);
      },
      expression: "experience_form.customer_value"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: "Very High"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "High"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Medium"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Low"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Very Low"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "secondary"
    },
    on: {
      click: function click($event) {
        _vm.editExperienceDetailDialog = false;
      }
    }
  }, [_vm._v("Close")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.updateExperienceDetails();
      }
    }
  }, [_vm._v("Update")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini",
      type: "danger"
    },
    on: {
      click: function click($event) {
        return _vm.deleteExperience(_vm.selectedExperience.id);
      }
    }
  }, [_vm._v("Delete")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Regenerate Confirmation",
      visible: _vm.regenerateJourneyMapConfirmationDialog,
      width: "30%",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.regenerateJourneyMapConfirmationDialog = $event;
      }
    }
  }, [_c("el-card", [_c("div", [_c("strong", [_vm._v("Are you sure you want to regenerate the Journey "), _c("el-tag", {
    attrs: {
      size: "mini",
      type: "danger"
    }
  }, [_vm._v(_vm._s(_vm.journeyMap.title))])], 1)]), _vm._v(" "), _c("ul", [_c("li", [_vm._v("All related entities will be overwritten")]), _vm._v(" "), _c("li", [_vm._v("Phase, Steps and Experience will be overwritten")])])]), _vm._v(" "), _c("el-form", {
    attrs: {
      "label-position": "top",
      "label-width": "100px",
      model: _vm.journeyMapGenerationConfirmationForm
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Type the journey name to confirm regeneration"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.journeyMapGenerationConfirmationForm.title,
      callback: function callback($$v) {
        _vm.$set(_vm.journeyMapGenerationConfirmationForm, "title", $$v);
      },
      expression: "journeyMapGenerationConfirmationForm.title"
    }
  })], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "secondary"
    },
    on: {
      click: function click($event) {
        _vm.regenerateJourneyMapConfirmationDialog = false;
      }
    }
  }, [_vm._v("Cancel")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini",
      type: "primary",
      disabled: _vm.journeyMapGenerationConfirmationForm.title !== _vm.journeyMap.title
    },
    on: {
      click: _vm.regenerateJourney
    }
  }, [_vm._v("Regenerate")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Delete Confirmation",
      visible: _vm.deleteJourneyMapConfirmationDialog,
      width: "30%",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.deleteJourneyMapConfirmationDialog = $event;
      }
    }
  }, [_c("el-card", [_c("div", [_c("strong", [_vm._v("Are you sure you want to delete the Journey "), _c("el-tag", {
    attrs: {
      size: "mini",
      type: "danger"
    }
  }, [_vm._v(_vm._s(_vm.journeyMap.title))])], 1)]), _vm._v(" "), _c("ul", [_c("li", [_vm._v("All related entities will be deleted")]), _vm._v(" "), _c("li", [_vm._v("Phase, Steps and Experience will be deleted")]), _vm._v(" "), _c("li", [_vm._v("Offering, Product and Services are preserved ")])])]), _vm._v(" "), _c("el-form", {
    attrs: {
      "label-position": "top",
      "label-width": "100px",
      model: _vm.journeyMapDeleteConfirmationForm
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Type the journey name to confirm deletion"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.journeyMapDeleteConfirmationForm.title,
      callback: function callback($$v) {
        _vm.$set(_vm.journeyMapDeleteConfirmationForm, "title", $$v);
      },
      expression: "journeyMapDeleteConfirmationForm.title"
    }
  })], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "secondary"
    },
    on: {
      click: function click($event) {
        _vm.deleteJourneyMapConfirmationDialog = false;
      }
    }
  }, [_vm._v("Cancel")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini",
      type: "danger",
      disabled: _vm.journeyMapDeleteConfirmationForm.title !== _vm.journeyMap.title
    },
    on: {
      click: _vm.deleteJourney
    }
  }, [_vm._v("Delete")])], 1)], 1), _vm._v(" "), _c("create-journey-idea-component", {
    attrs: {
      open: _vm.createJourneyIdeaDialog,
      customer: _vm.customer,
      "journey-map": _vm.journeyMap,
      phases: _vm.journeyMap.linkedJourneyPhases,
      steps: _vm.journeyMap.linkedJourneySteps,
      scope: _vm.idea.scope,
      scopename: _vm.idea.scopename
    },
    on: {
      "add-idea": _vm.addJourneyMapIdea,
      close: function close($event) {
        _vm.createJourneyIdeaDialog = false;
      }
    }
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;