"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("g", {
    attrs: {
      refs: "timeline"
    }
  }, [_c("g", {
    directives: [{
      name: "dragged",
      rawName: "v-dragged.prevent",
      value: _vm.onTimelineDragged,
      expression: "onTimelineDragged",
      modifiers: {
        prevent: true
      }
    }],
    attrs: {
      refs: "timeline_scale"
    }
  }, [_c("rect", {
    attrs: {
      x: _vm.x + _vm.computedStartX,
      y: 10,
      width: _vm.width,
      height: _vm.height,
      rx: "5",
      fill: "white"
    }
  }), _vm._v(" "), _vm._l(_vm.previousYears, function (year, year_index) {
    return _c("g", {
      key: "previousYears_" + year_index
    }, [_vm._l(48, function (n, index) {
      return _c("line", {
        key: "line_next_" + index,
        attrs: {
          x1: _vm.x + (n - 1) * _vm.computeTimeGap + _vm.computedStartX - 48 * year * _vm.computeTimeGap,
          y1: 10,
          x2: _vm.x + (n - 1) * _vm.computeTimeGap + _vm.computedStartX - 48 * year * _vm.computeTimeGap,
          y2: (n - 1) % 4 === 0 ? 30 : 20,
          stroke: "lightgrey",
          "stroke-width": "1"
        }
      });
    }), _vm._v(" "), _c("rect", {
      attrs: {
        x: _vm.x + _vm.computedStartX - 48 * year * _vm.computeTimeGap,
        y: 14,
        width: 48 * _vm.computeTimeGap - 2,
        height: "17",
        fill: "lightgrey",
        stroke: "lightgrey",
        "stroke-width": "0.5",
        rx: "3"
      }
    }), _vm._v(" "), _c("text", {
      staticStyle: {
        fill: "navy"
      },
      attrs: {
        x: _vm.x + _vm.computedTextStartX - 48 * year * _vm.computeTimeGap,
        y: 28,
        "font-size": "1.1em",
        "text-anchor": "middle",
        "font-weight": "bold"
      }
    }, [_vm._v("\n        " + _vm._s(_vm.computePreviousYear(year)) + "\n      ")]), _vm._v(" "), _vm.displayMonth ? _c("g", _vm._l(_vm.months, function (month, index) {
      return _c("text", {
        key: "text_" + index,
        staticStyle: {
          fill: "#7f7f7f"
        },
        attrs: {
          x: _vm.x + index * _vm.computeMonthMultiplier + _vm.computedStartX - 48 * year * _vm.computeTimeGap,
          y: 40,
          "font-size": "0.7em",
          "text-anchor": "middle",
          "font-weight": "bold"
        }
      }, [_vm._v("\n          " + _vm._s(month) + "\n        ")]);
    }), 0) : _vm._e()], 2);
  }), _vm._v(" "), _c("g", {
    attrs: {
      refs: "current_year"
    }
  }, [_vm._l(48, function (n, index) {
    return _c("line", {
      key: "line_" + index,
      attrs: {
        x1: _vm.x + (n - 1) * _vm.computeTimeGap + _vm.computedStartX,
        y1: 10,
        x2: _vm.x + (n - 1) * _vm.computeTimeGap + _vm.computedStartX,
        y2: (n - 1) % 4 === 0 ? 30 : 20,
        stroke: "lightgrey",
        "stroke-width": "1"
      }
    });
  }), _vm._v(" "), _c("rect", {
    attrs: {
      x: _vm.x + _vm.computedStartX,
      y: 14,
      width: 48 * _vm.computeTimeGap - 2,
      height: "17",
      fill: "rgba(248,248,255,0.7)",
      stroke: "lightgrey",
      "stroke-width": "0.5",
      rx: "3"
    }
  }), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "navy"
    },
    attrs: {
      x: _vm.x + _vm.computedTextStartX,
      y: 28,
      "font-size": "1.1em",
      "text-anchor": "middle",
      "font-weight": "bold"
    }
  }, [_vm._v("\n        " + _vm._s(_vm.activeTimeline) + "\n      ")]), _vm._v(" "), _vm.displayMonth ? _c("g", _vm._l(_vm.months, function (month, index) {
    return _c("text", {
      key: "text_" + index,
      staticStyle: {
        fill: "#7f7f7f"
      },
      attrs: {
        x: _vm.x + index * _vm.computeMonthMultiplier + _vm.computedStartX,
        y: 40,
        "font-size": "0.7em",
        "text-anchor": "middle",
        "font-weight": "bold"
      }
    }, [_vm._v("\n          " + _vm._s(month) + "\n        ")]);
  }), 0) : _vm._e()], 2), _vm._v(" "), _vm._l(_vm.nextYears, function (year, year_index) {
    return _c("g", {
      key: "nextYears_" + year_index
    }, [_vm._l(48, function (n, index) {
      return _c("line", {
        key: "line_next_" + index,
        attrs: {
          x1: _vm.x + (n - 1) * _vm.computeTimeGap + _vm.computedStartX + 48 * year * _vm.computeTimeGap,
          y1: 10,
          x2: _vm.x + (n - 1) * _vm.computeTimeGap + _vm.computedStartX + 48 * year * _vm.computeTimeGap,
          y2: (n - 1) % 4 === 0 ? 30 : 20,
          stroke: "lightgrey",
          "stroke-width": "1"
        }
      });
    }), _vm._v(" "), _c("rect", {
      attrs: {
        x: _vm.x + _vm.computedStartX + 48 * year * _vm.computeTimeGap,
        y: 14,
        width: 48 * _vm.computeTimeGap - 2,
        height: "17",
        fill: "rgba(248,248,255,0.7)",
        stroke: "lightgrey",
        "stroke-width": "0.5",
        rx: "3"
      }
    }), _vm._v(" "), _c("text", {
      staticStyle: {
        fill: "navy"
      },
      attrs: {
        x: _vm.x + _vm.computedTextStartX + 48 * year * _vm.computeTimeGap,
        y: 28,
        "font-size": "1.1em",
        "text-anchor": "middle",
        "font-weight": "bold"
      }
    }, [_vm._v("\n        " + _vm._s(_vm.computeYear(year)) + "\n      ")]), _vm._v(" "), _vm.displayMonth ? _c("g", _vm._l(_vm.months, function (month, index) {
      return _c("text", {
        key: "text_" + index,
        staticStyle: {
          fill: "#7f7f7f"
        },
        attrs: {
          x: _vm.x + index * _vm.computeMonthMultiplier + _vm.computedStartX + 48 * year * _vm.computeTimeGap,
          y: 40,
          "font-size": "0.7em",
          "text-anchor": "middle",
          "font-weight": "bold"
        }
      }, [_vm._v("\n          " + _vm._s(month) + "\n        ")]);
    }), 0) : _vm._e()], 2);
  })], 2), _vm._v(" "), _c("g", {
    attrs: {
      refs: "mask"
    }
  }, [_c("rect", {
    attrs: {
      x: _vm.computedStartX,
      y: 10,
      width: _vm.width,
      height: _vm.height,
      rx: "5",
      fill: "none",
      stroke: "lightgrey",
      "stroke-width": "1"
    }
  }), _vm._v(" "), _c("rect", {
    attrs: {
      x: 0,
      y: 10,
      width: _vm.computedStartX,
      height: _vm.height,
      rx: "5",
      fill: "white",
      stroke: "white",
      "stroke-width": "1"
    }
  }), _vm._v(" "), _c("rect", {
    attrs: {
      x: _vm.computedStartX + _vm.width,
      y: 10,
      width: _vm.computeMaskWidth,
      height: _vm.height,
      rx: "5",
      fill: "white",
      stroke: "white",
      "stroke-width": "1"
    }
  })])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;