"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-row", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("canvas", {
    staticStyle: {
      display: "none"
    },
    attrs: {
      id: "canvas",
      width: _vm.computeCanvasWidth,
      height: _vm.computeCanvasHeight
    }
  }), _vm._v(" "), _c("div", {
    staticStyle: {
      "overflow-x": "scroll"
    },
    attrs: {
      id: "journeyMapContainer"
    },
    on: {
      drop: _vm.handleDrop,
      dragover: _vm.handleDragOver
    }
  }, [_c("div", {
    staticStyle: {
      width: "100%",
      top: "-30pt",
      "margin-left": "1pt"
    }
  }, [_c("el-row", {
    staticClass: "shadow",
    style: _vm.computeHeaderStyle,
    attrs: {
      span: 24
    }
  }, [_c("el-col", {
    attrs: {
      xl: 8,
      lg: 9,
      md: 10,
      sm: 10,
      xs: 10,
      offset: 0
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "1.5em",
      "font-variant-caps": "small-caps",
      "letter-spacing": "-0.2pt",
      "padding-top": "3pt"
    },
    attrs: {
      refs: "customer-journey-title"
    }
  }, [_c("span", {
    staticStyle: {
      "font-weight": "bold",
      "font-size": "1.1em",
      color: "rebeccapurple"
    }
  }, [_vm._v("Journey: ")]), _vm._v(" "), _c("span", {
    staticStyle: {
      color: "rebeccapurple"
    },
    attrs: {
      title: _vm.journeyMap.title
    }
  }, [_vm._v(_vm._s(_vm.trimString(_vm.journeyMap.title, 50)))]), _vm._v(" "), _c("span", {
    attrs: {
      title: _vm.journeyMap.additionalattributes.type
    }
  }, [_c("el-tag", {
    attrs: {
      size: "mini",
      type: "primary"
    }
  }, [_vm._v(_vm._s(_vm.journeyMap.additionalattributes.type))])], 1)])]), _vm._v(" "), _c("el-col", {
    attrs: {
      xl: 1,
      lg: 1,
      md: 1,
      sm: 1,
      xs: 1
    }
  }, [_c("span", {
    staticStyle: {
      color: "grey",
      "font-variant-caps": "small-caps",
      float: "right",
      padding: "0pt 6pt 0 0",
      "font-size": "1.8em"
    }
  }, [_c("i", {
    staticClass: "el-icon-zoom-in"
  })])]), _vm._v(" "), _c("el-col", {
    attrs: {
      xl: 4,
      lg: 6,
      md: 6,
      sm: 7,
      xs: 7,
      offset: 0
    }
  }, [_c("el-slider", {
    staticClass: "sliderZoom",
    attrs: {
      step: 1,
      min: 50,
      max: 200,
      "show-input": "",
      "input-size": "mini"
    },
    on: {
      change: function change($event) {
        return _vm.zoomJourney();
      }
    },
    model: {
      value: _vm.journeyZoom,
      callback: function callback($$v) {
        _vm.journeyZoom = $$v;
      },
      expression: "journeyZoom"
    }
  })], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      xl: 10,
      lg: 7,
      md: 6,
      sm: 6,
      xs: 6
    }
  }, [_c("div", {
    staticClass: "fr",
    staticStyle: {
      position: "relative",
      top: "0"
    }
  }, [_c("el-button-group", {
    staticStyle: {
      "margin-top": "-1px"
    },
    attrs: {
      size: "mini"
    }
  }, [_c("el-popover", {
    attrs: {
      placement: "bottom",
      title: _vm.isEntityPopoverVisible ? "Disable popovers" : "Enable popovers",
      width: "200",
      trigger: "hover"
    }
  }, [_c("el-button", {
    staticStyle: {
      padding: "4px"
    },
    attrs: {
      slot: "reference",
      size: "mini",
      type: "secondary",
      circle: ""
    },
    on: {
      click: function click($event) {
        _vm.isEntityPopoverVisible = !_vm.isEntityPopoverVisible;
      }
    },
    slot: "reference"
  }, [_vm.isEntityPopoverVisible ? _c("svg-icon", {
    staticStyle: {
      "font-size": "16px"
    },
    attrs: {
      "icon-class": "popovers-on"
    }
  }) : _vm._e(), _vm._v(" "), !_vm.isEntityPopoverVisible ? _c("svg-icon", {
    staticStyle: {
      "font-size": "16px"
    },
    attrs: {
      "icon-class": "popovers-off"
    }
  }) : _vm._e()], 1)], 1)], 1), _vm._v(" "), _c("el-dropdown", {
    attrs: {
      "split-button": "",
      type: "secondary",
      size: "mini"
    },
    on: {
      command: _vm.handleDropDownCommand
    }
  }, [_c("i", {
    staticClass: "el-icon-menu"
  }), _vm._v(" "), _c("el-dropdown-menu", {
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, [_c("el-dropdown-item", {
    staticStyle: {
      "font-size": "1.0em"
    },
    attrs: {
      icon: "el-icon-star-off",
      command: "createJourneyIdea"
    }
  }, [_vm._v("Add Journey Idea")]), _vm._v(" "), _c("el-dropdown-item", {
    staticStyle: {
      "font-size": "1.0em"
    },
    attrs: {
      icon: "el-icon-setting",
      command: "showSettingsDialog"
    }
  }, [_vm._v("Journey Details")]), _vm._v(" "), _c("el-dropdown-item", {
    staticStyle: {
      "font-size": "1.0em"
    },
    attrs: {
      divided: "",
      icon: "el-icon-refresh",
      command: "resetCanvasLayout"
    }
  }, [_vm._v("Reset layout")]), _vm._v(" "), _vm.isThoughtVisible ? _c("el-dropdown-item", {
    staticStyle: {
      "font-size": "1.0em"
    },
    attrs: {
      divided: "",
      icon: "el-icon-view",
      command: "hideThoughts"
    }
  }, [_vm._v("Hide Thougths")]) : _c("el-dropdown-item", {
    staticStyle: {
      "font-size": "1.0em"
    },
    attrs: {
      divided: "",
      icon: "el-icon-view",
      command: "showThoughts"
    }
  }, [_vm._v("Show Thougths")]), _vm._v(" "), _vm.isTouchPointLaneVisible ? _c("el-dropdown-item", {
    staticStyle: {
      "font-size": "1.0em"
    },
    attrs: {
      icon: "el-icon-view",
      command: "hideTouchPoints"
    }
  }, [_vm._v("Hide Touchpoint")]) : _c("el-dropdown-item", {
    staticStyle: {
      "font-size": "1.0em"
    },
    attrs: {
      icon: "el-icon-view",
      command: "showTouchPoints"
    }
  }, [_vm._v("Show Touchpoint")]), _vm._v(" "), _c("el-dropdown-item", {
    staticStyle: {
      "font-size": "1.0em"
    },
    attrs: {
      icon: "el-icon-picture",
      command: "exportPNG"
    }
  }, [_vm._v("Export PNG")])], 1)], 1), _vm._v(" "), _c("el-popover", {
    attrs: {
      placement: "bottom",
      title: _vm.fetchTimeStamp,
      width: "270",
      trigger: "hover"
    }
  }, [_c("el-button", {
    attrs: {
      slot: "reference",
      type: "secondary",
      size: "mini",
      circle: "",
      disabled: _vm.journeyMapFetchStatus.loading,
      icon: _vm.journeyMapFetchStatus.loading ? "el-icon-loading" : "el-icon-refresh"
    },
    slot: "reference"
  })], 1)], 1)])], 1)], 1), _vm._v(" "), _c("svg", {
    staticClass: "journeyZoomer",
    attrs: {
      id: "journeyMapSVG",
      height: _vm.computeCanvasHeight + 300,
      width: _vm.computeCanvasWidth,
      xmlns: "http://www.w3.org/2000/svg",
      version: "1.1"
    }
  }, [_c("defs", [_c("filter", {
    attrs: {
      id: "exp_relation_shadow"
    }
  }, [_c("feDropShadow", {
    attrs: {
      dx: "1",
      dy: "-1",
      stdDeviation: "1",
      "flood-color": "white"
    }
  }), _vm._v(" "), _c("feDropShadow", {
    attrs: {
      dx: "-1",
      dy: "1",
      stdDeviation: "1",
      "flood-color": "white"
    }
  }), _vm._v(" "), _c("feDropShadow", {
    attrs: {
      dx: "1",
      dy: "1",
      stdDeviation: "0.5",
      "flood-color": "white"
    }
  }), _vm._v(" "), _c("feDropShadow", {
    attrs: {
      dx: "-1",
      dy: "-1",
      stdDeviation: "0.5",
      "flood-color": "white"
    }
  })], 1)]), _vm._v(" "), _vm.isSupportedVersion[0] ? _c("g", [_c("g", {
    attrs: {
      refs: "journey_map_horizontal"
    }
  }, [_c("journey-horizontal-component", {
    attrs: {
      customer: _vm.customer,
      "journey-zoom": _vm.journeyZoom,
      "is-thought-lane-visible": _vm.isThoughtVisible,
      "is-touch-point-lane-visible": _vm.isTouchPointLaneVisible,
      "is-entity-popover-visible": _vm.isEntityPopoverVisible,
      "reset-layout": _vm.resetLayoutFlag,
      "step-layout": _vm.stepLayoutType
    },
    on: {
      "initiate-create-phase": _vm.showAddPhaseDialog,
      "initiate-create-task": _vm.showAddTaskDialog,
      "initiate-create-touchpoint": _vm.showAddTouchPointDialog,
      "initiate-edit-phase": _vm.showEditPhaseDialog,
      "initiate-edit-experience": _vm.showEditExperienceDialog,
      "initiate-create-experience-from-phase": _vm.showAddExperienceFromPhaseDialog,
      "initiate-create-experience-from-step": _vm.showAddExperienceFromStepDialog,
      "initiate-create-journey-idea": _vm.showJourneyIdeaDialog,
      "initiate-create-phase-idea": _vm.showPhaseIdeaDialog,
      "initiate-create-step-idea": _vm.showStepIdeaDialog,
      "initiate-create-journey-risk": _vm.showJourneyRiskDialog,
      "initiate-create-phase-risk": _vm.showPhaseRiskDialog,
      "initiate-create-step-risk": _vm.showStepRiskDialog,
      "select-task": _vm.showTaskDetailsDialog,
      "select-touchpoint": _vm.showTouchPointDetailsDialog,
      "edit-phase-sort-order": _vm.editJourneyMapPhaseSortOrder,
      "update-task-to-workflow": _vm.updateTaskToWorkflow,
      "swap-phase-step-relation": _vm.swapPhaseStepRelation,
      "delete-phase": _vm.deletePhase,
      "delete-step": _vm.deleteStep,
      "delete-experience": _vm.deleteExperience,
      "select-relation": _vm.selectRelation
    }
  })], 1)]) : _c("g", [_c("s-v-g-c-t-a-component", {
    attrs: {
      display: !_vm.isSupportedVersion[0] && _vm.isSupportedVersion[1] === "legacy",
      title: "Legacy version of journeymap detected",
      description: "Would you like to update the journeymap",
      buttonText: ["Update Journeymap", "Switch to legacy version"],
      x: 5,
      y: 10,
      width: _vm.computeCanvasWidth,
      height: 300
    },
    on: {
      response: _vm.handleCTAResponse
    }
  }), _vm._v(" "), _c("s-v-g-c-t-a-component", {
    attrs: {
      display: !_vm.isSupportedVersion[0] && _vm.isSupportedVersion[1] === "version",
      title: "JourneyMap data structure has been updated.",
      description: "Would you like to create new journeymap with updated data structure",
      buttonText: ["Create new Journeymap"],
      x: 5,
      y: 10,
      width: _vm.computeCanvasWidth,
      height: 300
    },
    on: {
      response: _vm.handleCTAResponse
    }
  })], 1)])])])], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: _vm.phase.edit ? "Stage Details" : "Add Stage",
      visible: _vm.addPhaseDialog,
      width: "90%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addPhaseDialog = $event;
      }
    }
  }, [_vm.addPhaseDialog ? _c("phase-create-component", {
    attrs: {
      edit: _vm.phase.edit,
      phase: _vm.phase.selected,
      index: _vm.phase.selected_index,
      "journey-map": _vm.journeyMap,
      customer: _vm.customer,
      tabname: _vm.phase.selected_tab
    },
    on: {
      "add-phase": _vm.addJourneyMapPhase,
      "edit-phase": _vm.editJourneyMapPhase,
      "add-task": function addTask($event) {
        _vm.addTaskDialog = true;
      },
      "edit-task": _vm.showTaskDetailsDialog,
      "initiate-create-experience": _vm.createExperienceFromEntity,
      "initiate-edit-experience": _vm.showEditExperienceDialog,
      delete: _vm.deletePhase,
      "delete-experience": _vm.deleteExperience,
      close: _vm.closeAddPhaseDialog
    }
  }) : _vm._e()], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Add Step",
      visible: _vm.addTaskDialog,
      width: "90%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addTaskDialog = $event;
      }
    }
  }, [_c("task-create-component", {
    on: {
      "add-task": _vm.addJourneyMapTask,
      close: _vm.closeAddTaskDialog
    }
  })], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Step Details",
      visible: _vm.taskDetailsDialog,
      width: "90%",
      top: "5vh",
      "custom-class": "step-details"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.taskDetailsDialog = $event;
      }
    }
  }, [_c("task-detail-component", {
    attrs: {
      task: _vm.task.selected,
      "journey-map": _vm.journeyMap,
      customer: _vm.customer,
      tabname: _vm.task.selected_tab
    },
    on: {
      "initiate-create-experience": _vm.createExperienceFromEntity,
      "initiate-edit-experience": _vm.showEditExperienceDialog,
      "edit-step": _vm.updateStep,
      "delete-experience": _vm.deleteExperience,
      "delete-step": _vm.deleteStep,
      close: _vm.closeTaskDetailsDialog
    }
  })], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      visible: _vm.createExperienceDialog,
      width: "90%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.createExperienceDialog = $event;
      }
    }
  }, [_c("div", {
    staticClass: "text-left",
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_vm.experience.edit ? _c("div", {
    staticStyle: {
      "font-weight": "600"
    }
  }, [_vm._v("Edit experience ")]) : _c("div", {
    staticStyle: {
      "font-weight": "600"
    }
  }, [_vm._v("Add " + _vm._s(_vm.experience.selected.dialog_title) + " ")]), _vm._v(" "), _c("div", {
    staticStyle: {
      "font-size": "10pt",
      "font-weight": "400"
    }
  }, [_vm._v(_vm._s(_vm.experience.selected.dialog_description))])]), _vm._v(" "), _c("experience-create-component", {
    attrs: {
      edit: _vm.experience.edit,
      experience: _vm.experience.selected
    },
    on: {
      "add-experience": _vm.addExperience,
      "update-experience": _vm.updateExperience,
      "delete-experience": _vm.deleteExperience,
      close: _vm.closeAddExperienceDialog
    }
  })], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      visible: _vm.createIdeaDialog,
      title: "Create Idea",
      width: "90%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.createIdeaDialog = $event;
      }
    }
  }, [_c("idea-create-component", {
    attrs: {
      customer: _vm.customer,
      "journey-map": _vm.journeyMap,
      phases: _vm.sortedStages,
      steps: _vm.journeyMap.linkedJourneySteps,
      scope: _vm.idea.scope,
      scopename: _vm.idea.scopename
    },
    on: {
      "add-idea": _vm.addIdea,
      close: _vm.closeAddIdeaDialog
    }
  })], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      visible: _vm.createRiskDialog,
      title: "Create Risk",
      width: "90%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.createRiskDialog = $event;
      }
    }
  }, [_c("risk-create-component", {
    attrs: {
      customer: _vm.customer,
      "journey-map": _vm.journeyMap,
      phases: _vm.journeyMap.linkedJourneyPhases,
      steps: _vm.journeyMap.linkedJourneySteps,
      scope: _vm.risk.scope,
      scopename: _vm.risk.scopename
    },
    on: {
      "add-risk": _vm.addRisk,
      close: _vm.closeAddRiskDialog
    }
  })], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      visible: _vm.addTouchpointDialog,
      title: _vm.isTouchpointEdit ? "Update Touchpoint" : "Create Touchpoint",
      width: "90%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addTouchpointDialog = $event;
      }
    }
  }, [_c("touchpoint-create-component", {
    attrs: {
      type: _vm.touchpoint.type,
      edit: _vm.isTouchpointEdit,
      touchpoint: _vm.isTouchpointEdit ? _vm.touchpoint : {}
    },
    on: {
      "add-touchpoint": _vm.addTouchpoint,
      "update-touchpoint": _vm.updateTouchpoint,
      close: _vm.closeAddTouchpointDialog
    }
  })], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      visible: _vm.settingsDialog,
      title: "Journey Details",
      width: "90%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.settingsDialog = $event;
      }
    }
  }, [_c("settings-component", {
    attrs: {
      "journey-map": _vm.journeyMap
    },
    on: {
      "rename-journeymap": _vm.renameJourneymap,
      "regenerate-journeymap": _vm.regenerateJourneymap,
      "delete-journeymap": _vm.deleteJourney,
      close: _vm.closeSettingsDialog
    }
  })], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      visible: _vm.relationDetailsDialog,
      title: "Relation Details",
      width: "90%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.relationDetailsDialog = $event;
      }
    }
  }, [_c("relation-details-component", {
    attrs: {
      "journey-map": _vm.journeyMap,
      relation: _vm.selectedRelation,
      "relation-index": _vm.selectedRelationIndex,
      "relation-type": _vm.selectedRelationType
    },
    on: {
      close: _vm.closeRelationDetailsDialog
    }
  })], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;