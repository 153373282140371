"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vueD3Network = _interopRequireDefault(require("vue-d3-network"));
require("vue-d3-network/dist/vue-d3-network.css");
var _default2 = exports.default = {
  name: 'Sunburst',
  components: {
    D3Network: _vueD3Network.default
  },
  props: {
    nodes: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    links: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    options: {
      type: Object,
      default: function _default() {
        return {
          force: 1000,
          forces: {
            Center: false,
            X: 5,
            Y: 5,
            ManyBody: true,
            Link: true
          },
          nodeSize: 10,
          nodeLabels: true,
          linkWidth: 2
        };
      }
    }
  },
  data: function data() {
    return {};
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    linkCb: function linkCb(link) {
      link.name = 'Link ' + link.id;
      return link;
    },
    simCb: function simCb(link) {
      console.log(link);
      return link;
    }
  }
};