"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-row", {
    staticClass: "no-padding",
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading.general,
      expression: "loading.general"
    }],
    staticStyle: {
      "padding-right": "0 !important",
      "border-top-right-radius": "3pt",
      height: "100%"
    },
    attrs: {
      span: 4
    }
  }, [_c("item-list", {
    attrs: {
      title: "Products & Services",
      items: _vm.filteredEntities,
      "selected-item": _vm.selectedProductService,
      "fuzzy-search": true
    },
    on: {
      search: _vm.searchItemList,
      create: _vm.openProductServiceDialog,
      "load-page": _vm.retrieveProductsAndServices
    }
  }, [_vm.filteredEntities.length > 0 ? _c("div", {
    staticStyle: {
      height: "850px",
      "overflow-y": "scroll"
    }
  }, _vm._l(_vm.filteredEntities, function (item, index) {
    return _c("div", {
      key: index,
      class: _vm.computeItemContainerStyle(item, _vm.selectedProductService),
      staticStyle: {
        padding: "3pt 5pt 3pt 5pt"
      },
      on: {
        click: function click($event) {
          return _vm.selectProductService(item, index);
        }
      }
    }, [_c("table", {
      staticStyle: {
        width: "100%",
        "font-variant-caps": "small-caps"
      }
    }, [_c("tr", {
      staticStyle: {
        padding: "0pt"
      }
    }, [item.__typename === "Product" ? _c("td", {
      staticClass: "dot-cont"
    }, [_c("div", {
      staticClass: "dot"
    }, [_c("svg-icon", {
      staticClass: "svg-center",
      staticStyle: {
        "font-size": "15px",
        height: "22px",
        width: "22px",
        "font-weight": "bold",
        color: "#0D63BF"
      },
      attrs: {
        "icon-class": "pdg-product"
      }
    })], 1)]) : _vm._e(), _vm._v(" "), item.__typename === "Service" ? _c("td", {
      staticClass: "dot-cont"
    }, [_c("div", {
      staticClass: "dot"
    }, [_c("svg-icon", {
      staticClass: "svg-center",
      staticStyle: {
        "font-size": "15px",
        height: "22px",
        width: "22px",
        "font-weight": "bold",
        color: "#4CA97E"
      },
      attrs: {
        "icon-class": "pdg-service"
      }
    })], 1)]) : _vm._e(), _vm._v(" "), _c("td", {
      staticClass: "text-left"
    }, [_c("strong", [_vm._v(_vm._s(_vm.truncateDescription(item.title, 40)))]), _c("br"), _vm._v(" "), _c("a", [_vm._v(_vm._s(_vm.truncateDescription(item.description, 50)))])])])])]);
  }), 0) : _vm._e()])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 19
    }
  }, [_c("transition", {
    attrs: {
      name: "fade"
    }
  }, [!_vm.loading.general ? [_vm.selectedProductService !== null && _vm.selectedProductService !== undefined && _vm.selectedProductService.id ? _c("div", [_c("el-card", {
    staticStyle: {
      "margin-left": "10px",
      "margin-bottom": "6pt"
    },
    attrs: {
      id: "portfolio_details_container"
    }
  }, [_c("div", {
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 18
    }
  }, [_vm.isEditMode ? _c("el-input", {
    staticStyle: {
      "margin-top": "-5px"
    },
    attrs: {
      type: "text",
      size: "small"
    },
    model: {
      value: _vm.selectedProductService.title,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedProductService, "title", $$v);
      },
      expression: "selectedProductService.title"
    }
  }) : _c("strong", {
    staticStyle: {
      "font-variant-caps": "small-caps"
    }
  }, [_vm._v(_vm._s(_vm.selectedProductService.title) + " Details")])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "fr"
  }, [_c("el-button-group", {
    staticStyle: {
      "margin-top": "-5px"
    },
    attrs: {
      size: "mini"
    }
  }, [!_vm.isEditMode ? _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Edit",
      placement: "top"
    }
  }, [_c("el-button", {
    attrs: {
      type: "secondary",
      size: "micro",
      icon: "el-icon-edit"
    },
    on: {
      click: function click($event) {
        _vm.isEditMode = true;
      }
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.isEditMode ? _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Update",
      placement: "top"
    }
  }, [_c("el-button", {
    attrs: {
      type: "success",
      size: "micro"
    },
    on: {
      click: _vm.updateSelectedProductService
    }
  }, [_c("i", {
    staticClass: "fa fa-save"
  })])], 1) : _vm._e(), _vm._v(" "), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Delete",
      placement: "top"
    }
  }, [_c("el-button", {
    attrs: {
      type: "danger",
      size: "micro",
      icon: "el-icon-delete"
    },
    on: {
      click: _vm.deleteSelectedProductService
    }
  })], 1)], 1)], 1)])], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    staticStyle: {
      "align-content": "end"
    },
    attrs: {
      span: 12
    }
  }, [_c("div", [_c("span", [_c("strong", [_vm._v("Description")])]), _vm._v(" "), _vm.isEditMode ? _c("el-input", {
    staticClass: "mt-10",
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.selectedProductService.description,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedProductService, "description", $$v);
      },
      expression: "selectedProductService.description"
    }
  }) : _c("div", {
    staticClass: "mt-5 text-justify",
    staticStyle: {
      color: "rgb(96, 98, 102)",
      height: "54px"
    }
  }, [_vm._v(_vm._s(_vm.selectedProductService.description))])], 1)]), _vm._v(" "), _c("el-col", {
    attrs: {
      offset: 2,
      span: 5
    }
  }, [_c("table", {
    attrs: {
      id: "portfolio_details"
    }
  }, [_c("tr", [_c("th", [_c("i", {
    staticClass: "el-icon-user"
  }), _vm._v(" Manager")]), _vm._v(" "), _c("td", [_vm.isEditMode ? _c("el-select", {
    attrs: {
      placeholder: "Select Manager",
      size: "mini"
    },
    model: {
      value: _vm.selectedProductService.keypersonnel.manager,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedProductService.keypersonnel, "manager", $$v);
      },
      expression: "selectedProductService.keypersonnel.manager"
    }
  }, _vm._l(_vm.users, function (user, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: _vm.computeUserName(user),
        value: _vm.computeUserName(user)
      }
    });
  }), 1) : _c("el-tag", {
    attrs: {
      size: "mini"
    }
  }, [_vm._v(_vm._s(_vm.selectedProductService.keypersonnel.manager))])], 1)]), _vm._v(" "), _c("tr", [_c("th", [_c("i", {
    staticClass: "el-icon-user"
  }), _vm._v(" Owner")]), _vm._v(" "), _c("td", [_vm.isEditMode ? _c("el-select", {
    attrs: {
      placeholder: "Select Owner",
      size: "mini"
    },
    model: {
      value: _vm.selectedProductService.keypersonnel.owner,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedProductService.keypersonnel, "owner", $$v);
      },
      expression: "selectedProductService.keypersonnel.owner"
    }
  }, _vm._l(_vm.users, function (user, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: _vm.computeUserName(user),
        value: _vm.computeUserName(user)
      }
    });
  }), 1) : _c("el-tag", {
    attrs: {
      size: "mini"
    }
  }, [_vm._v(_vm._s(_vm.selectedProductService.keypersonnel.owner))])], 1)])])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 5
    }
  }, [_c("table", {
    attrs: {
      id: "governance_details"
    }
  }, [_c("tr", [_c("th", [_vm._v("Created By")]), _vm._v(" "), _c("td", [_c("el-tag", {
    attrs: {
      size: "mini"
    }
  }, [_vm._v(_vm._s(_vm.computeUserNameFromId(_vm.selectedProductService.createdby)))])], 1)]), _vm._v(" "), _c("tr", [_c("th", [_vm._v("Created Date")]), _vm._v(" "), _c("td", [_c("el-tag", {
    attrs: {
      size: "mini"
    }
  }, [_vm._v(_vm._s(_vm.computeHumanFriendlyDate(_vm.selectedProductService.createdat)))])], 1)]), _vm._v(" "), _c("tr", [_c("th", [_vm._v("Modified By:")]), _vm._v(" "), _c("td", [_c("el-tag", {
    attrs: {
      size: "mini"
    }
  }, [_vm._v(_vm._s(_vm.computeUserNameFromId(_vm.selectedProductService.modifiedby)))])], 1)]), _vm._v(" "), _c("tr", [_c("th", [_vm._v("Modified Date:")]), _vm._v(" "), _c("td", [_c("el-tag", {
    attrs: {
      size: "mini"
    }
  }, [_vm._v(_vm._s(_vm.computeHumanFriendlyDate(_vm.selectedProductService.modifiedat)))])], 1)])])])], 1)], 1), _vm._v(" "), _c("el-card", {
    staticClass: "mt-10 sub_entity_container",
    staticStyle: {
      "margin-left": "8pt",
      "border-top": "none",
      "border-left": "none"
    },
    attrs: {
      id: "portfolio_details_subentity"
    }
  }, [_c("el-tabs", {
    attrs: {
      value: "sub_entity"
    },
    on: {
      "tab-click": _vm.handleTabClick
    },
    model: {
      value: _vm.activeTab,
      callback: function callback($$v) {
        _vm.activeTab = $$v;
      },
      expression: "activeTab"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "Offerings",
      name: "offeringsTab"
    }
  }, [_c("table", {
    staticStyle: {
      width: "98%"
    },
    attrs: {
      id: "offering_x_details"
    }
  }, [_vm.selectedProductService.linkedOfferings.length > 0 ? _c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.selectedProductService.linkedOfferings,
      size: "mini",
      stripe: "",
      "highlight-current-row": "",
      width: "100%"
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Offering",
      prop: "linkedOfferings .title",
      "min-width": "180pt"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v("\n                          " + _vm._s(scope.row.offering.title) + "\n                        ")];
      }
    }], null, false, 3260809195)
  }, [_vm._v(">\n\n                      ")]), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Description",
      prop: "status",
      "min-width": "400pt"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm._v("\n\n                          " + _vm._s(scope.row.offering.description) + "\n                        ")];
      }
    }], null, false, 2344938495)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Type",
      prop: "offerintype",
      width: "150pt"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-tag", {
          attrs: {
            size: "mini"
          }
        }, [_vm._v("\n                            " + _vm._s(scope.row.offering.offeringtype))])];
      }
    }], null, false, 2937904921)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Variant",
      prop: "offeringcategory",
      width: "120pt"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-tag", {
          attrs: {
            size: "mini"
          }
        }, [_vm._v("\n                            " + _vm._s(scope.row.offering.offeringcategory))])];
      }
    }], null, false, 2554514513)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Actions",
      width: "100pt"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_vm.selectedProductService.__typename === "Product" ? _c("el-button", {
          attrs: {
            "icon-class": "unlink-chain",
            title: "Unlink from offering and journey",
            type: "danger",
            size: "mini",
            plain: ""
          },
          on: {
            click: function click($event) {
              return _vm.unlinkProduct(scope.row.offering.id, scope.row.$index);
            }
          }
        }, [_vm._v(" Remove")]) : _vm._e(), _vm._v(" "), _vm.selectedProductService.__typename === "Service" ? _c("el-button", {
          attrs: {
            "icon-class": "unlink-chain",
            title: "Unlink from offering and journey",
            type: "danger",
            size: "mini",
            plain: ""
          },
          on: {
            click: function click($event) {
              return _vm.unlinkService(scope.row.offering.id, scope.row.$index);
            }
          }
        }, [_vm._v(" Remove")]) : _vm._e()];
      }
    }], null, false, 888335792)
  })], 1) : _vm._e()], 1)]), _vm._v(" "), _vm.selectedProductService.__typename === "Product" ? _c("el-tab-pane", {
    attrs: {
      label: "Design Tickets",
      name: "sub_entity"
    }
  }, [_vm.selectedProductService.linkedFeatures.length > 0 ? _c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.selectedProductService.linkedFeatures,
      size: "mini",
      stripe: "",
      "highlight-current-row": ""
    },
    on: {
      "row-click": _vm.selectFeature
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Title",
      prop: "productFeature.title"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Status",
      prop: "productFeature.status",
      width: "140pt"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-tag", {
          attrs: {
            size: "mini"
          }
        }, [_vm._v(_vm._s(scope.row.productFeature.status))])];
      }
    }], null, false, 395414387)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Value",
      prop: "serviceApplication.importance",
      width: "140pt"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Priority",
      prop: "productFeature.priority",
      width: "100pt"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-tag", {
          attrs: {
            size: "mini"
          }
        }, [_vm._v(_vm._s(scope.row.productFeature.priority))])];
      }
    }], null, false, 3504852405)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Enabler",
      prop: "productFeature.enabler",
      width: "100pt",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-checkbox", {
          attrs: {
            disabled: ""
          },
          model: {
            value: scope.row.productFeature.enabler,
            callback: function callback($$v) {
              _vm.$set(scope.row.productFeature, "enabler", $$v);
            },
            expression: "scope.row.productFeature.enabler"
          }
        })];
      }
    }], null, false, 2948147540)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Actions",
      width: "100pt"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("i", {
          staticClass: "el-icon-edit",
          on: {
            click: function click($event) {
              return _vm.selectTicket(scope.row);
            }
          }
        }), _vm._v(" "), _c("i", {
          staticClass: "el-icon-delete",
          on: {
            click: function click($event) {
              return _vm.deleteFeatureConfirmation(scope.row.productFeature.id, scope.row.$index);
            }
          }
        })];
      }
    }], null, false, 1966815103)
  })], 1) : _c("el-empty", {
    attrs: {
      description: "No features found"
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.openCreateFeatureDialog
    }
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "create"
    }
  }), _vm._v(" Ticket")], 1)], 1)], 1) : _vm._e(), _vm._v(" "), _vm.selectedProductService.__typename === "Service" ? _c("el-tab-pane", {
    attrs: {
      label: "Design Tickets",
      name: "sub_entity"
    }
  }, [_vm.selectedProductService.linkedServiceApplications.length > 0 ? _c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.selectedProductService.linkedServiceApplications,
      size: "mini",
      stripe: "",
      "highlight-current-row": ""
    },
    on: {
      "row-click": _vm.selectProcess
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Title",
      prop: "serviceApplication.title"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Status",
      prop: "serviceApplication.status",
      width: "140pt"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-tag", {
          attrs: {
            size: "mini"
          }
        }, [_vm._v(_vm._s(scope.row.serviceApplication.status))])];
      }
    }], null, false, 3662522283)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Value",
      prop: "serviceApplication.importance",
      width: "140pt"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Priority",
      prop: "serviceApplication.priority",
      width: "100pt"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-tag", {
          attrs: {
            size: "mini"
          }
        }, [_vm._v(_vm._s(scope.row.serviceApplication.priority))])];
      }
    }], null, false, 3970971629)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Enabler",
      prop: "serviceApplication.enabler",
      width: "100pt",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-checkbox", {
          attrs: {
            disabled: ""
          },
          model: {
            value: scope.row.serviceApplication.importance,
            callback: function callback($$v) {
              _vm.$set(scope.row.serviceApplication, "importance", $$v);
            },
            expression: "scope.row.serviceApplication.importance"
          }
        })];
      }
    }], null, false, 3427007339)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Actions",
      width: "100pt"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("i", {
          staticClass: "el-icon-edit",
          on: {
            click: function click($event) {
              return _vm.selectTicket(scope.row);
            }
          }
        }), _vm._v(" "), _c("i", {
          staticClass: "el-icon-delete",
          on: {
            click: function click($event) {
              return _vm.deleteProcessConfirmation(scope.row.serviceApplication.id, scope.row.$index);
            }
          }
        })];
      }
    }], null, false, 2524777688)
  })], 1) : _c("el-empty", {
    attrs: {
      description: "No design tickets. Add them via Model Offering"
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.hasPermissionForModule("Risk Managment") ? _c("el-tab-pane", {
    attrs: {
      label: "Risks",
      name: "risks"
    }
  }, [_vm.risks.length > 0 ? _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading.risk,
      expression: "loading.risk"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.risks,
      size: "mini",
      stripe: "",
      "highlight-current-row": ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Title",
      prop: "title"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Severity",
      prop: "severity"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-tag", {
          attrs: {
            size: "mini"
          }
        }, [_vm._v(_vm._s(scope.row.severity))])];
      }
    }], null, false, 3570102341)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Occuranceprobability",
      prop: "occuranceprobability"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-tag", {
          attrs: {
            size: "mini"
          }
        }, [_vm._v(_vm._s(scope.row.occuranceprobability))])];
      }
    }], null, false, 1048027098)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Impact",
      prop: "impactlevel"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-tag", {
          attrs: {
            size: "mini"
          }
        }, [_vm._v(_vm._s(scope.row.impactlevel))])];
      }
    }], null, false, 3950207106)
  })], 1) : _c("el-empty", {
    attrs: {
      description: "No risks found"
    }
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    }
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "create"
    }
  }), _vm._v(" Risk")], 1)], 1)], 1) : _vm._e(), _vm._v(" "), _c("el-tab-pane", {
    staticStyle: {
      "justify-content": "left"
    },
    attrs: {
      label: "Discussion"
    }
  }, [_c("comment-component", {
    attrs: {
      "entity-id": _vm.selectedProductService.id,
      "entity-name": _vm.selectedProductService.title
    }
  })], 1), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: "Actions"
    }
  }, [_c("action-component", {
    attrs: {
      entity: {
        id: _vm.selectedProductService.id,
        type: _vm.selectedProductService.__typename
      }
    }
  })], 1)], 1)], 1)], 1) : _vm._e()] : _vm._e()], 2)], 1)], 1), _vm._v(" "), _c("el-dialog", {
    staticStyle: {
      "margin-left": "3%"
    },
    attrs: {
      title: _vm.computeTicketDetailTitle(),
      visible: _vm.ticketDetailsDialog,
      width: "94%",
      top: "1vh",
      "custom-class": "ticket-details-dialog"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.ticketDetailsDialog = $event;
      }
    }
  }, [_vm.ticketDetailsDialog ? _c("ticket-component", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading.ticket,
      expression: "loading.ticket"
    }],
    attrs: {
      customer: _vm.selectedCustomer,
      "journey-map": _vm.selectedCustomerJourneyMap,
      offering: _vm.offering,
      parent: _vm.activeTicketParent,
      child: _vm.selectedTicket,
      "parent-type": _vm.selectedParentDetailType
    },
    on: {
      updateChildTitleStatus: _vm.updateChildTitleStatusFromTicket,
      close: function close($event) {
        return _vm.closeTicketDetailDialog();
      }
    }
  }) : _vm._e()], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Add Product/Service",
      visible: _vm.addProductServiceDialog,
      width: "50%",
      top: "1vh"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addProductServiceDialog = $event;
      }
    }
  }, [_vm.addProductServiceDialog ? _c("add-product-service-component", {
    on: {
      "add-product": _vm.addProduct,
      "add-service": _vm.addService,
      close: function close($event) {
        return _vm.closeProductServiceDialog();
      }
    }
  }) : _vm._e()], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Add Feature",
      visible: _vm.addFeatureDialog,
      width: "60%",
      top: "1vh"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addFeatureDialog = $event;
      }
    }
  }, [_vm.addFeatureDialog ? _c("add-feature-component", {
    attrs: {
      product: _vm.selectedProductService
    },
    on: {
      create: _vm.createFeature,
      close: function close($event) {
        return _vm.closeCreateFeatureDialog();
      }
    }
  }) : _vm._e()], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Add Process",
      visible: _vm.addProcessDialog,
      width: "60%",
      top: "1vh"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addProcessDialog = $event;
      }
    }
  }, [_vm.addProcessDialog ? _c("add-process-component", {
    attrs: {
      service: _vm.selectedProductService
    },
    on: {
      create: _vm.createProcess,
      close: function close($event) {
        return _vm.closeCreateProcessDialog();
      }
    }
  }) : _vm._e()], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;