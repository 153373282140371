"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _default = exports.default = {
  name: 'DataTypeDetails',
  props: {
    cx: {
      type: Number,
      required: true,
      default: 0
    },
    cy: {
      type: Number,
      required: true,
      default: 0
    }
  },
  data: function data() {
    return {
      data_types: [{
        key: 1,
        title: 'Noun',
        description: 'Description'
      }, {
        key: 1,
        title: 'Noun1',
        description: 'Description1'
      }],
      showDataDetailsOptionsFlag: false,
      isOptionsIconHover: false,
      activeDataDetailIndex: null,
      dataDetailsOptionPostion: {
        x: 0,
        y: 0
      }
    };
  },
  created: function created() {
    // Listen to event
    this.$eventHub.$on('add-data-type', this.addDataType);
    this.$eventHub.$on('update-data-type', this.updateDataType);
  },
  methods: {
    updateDataType: function updateDataType(data, index) {
      this.data_types[index] = data;
    },
    addDataType: function addDataType(data) {
      this.data_types.push(data);
    },
    selectData: function selectData(data) {
      this.$notify({
        title: data + ' selected',
        message: data + ' UI to be displayed at this event',
        type: 'info',
        duration: 2000
      });
    },
    createDataType: function createDataType() {
      this.$eventHub.$emit('create-data-type');
    },
    editDataType: function editDataType(data, index) {
      this.$eventHub.$emit('edit-data-type', data, index);
    },
    showDataDetailsOptions: function showDataDetailsOptions(x, y, index) {
      this.dataDetailsOptionPostion.x = x;
      this.dataDetailsOptionPostion.y = y;
      this.activeDataDetailIndex = index;
      this.showDataDetailsOptionsFlag = !this.showDataDetailsOptionsFlag;
    },
    deleteDataDetailsOptions: function deleteDataDetailsOptions(index) {
      this.data_types.splice(index, 1);
      this.showDataDetailsOptionsFlag = false;
    },
    updateOptionIconMouseOver: function updateOptionIconMouseOver(flag, index) {
      this.isOptionsIconHover = flag;
      if (flag) {
        this.activeDataDetailIndex = index;
      }
    }
  }
};