"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }]
  }, [_vm.selectedCustomer && _vm.selectedCustomer.linkedJourneyMaps && _vm.selectedCustomer.linkedJourneyMaps.length > 0 && !_vm.loading ? _c("needs-coverage-component", {
    attrs: {
      customer: _vm.selectedCustomer,
      "journey-map": _vm.selectedCustomer.linkedJourneyMaps.length > 0 ? _vm.selectedCustomer.linkedJourneyMaps[0].journeyMap : {},
      offering: _vm.selectedCustomer.linkedOfferings.length > 0 ? _vm.selectedCustomer.linkedOfferings[0].offering : {}
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;