"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _packageFeatures = _interopRequireDefault(require("./components/model_revenue/package-features"));
var _pricePackages = _interopRequireDefault(require("./components/model_revenue/price-packages"));
var _step = _interopRequireDefault(require("./components/model_revenue/step-3"));
var _step2 = _interopRequireDefault(require("./components/model_revenue/step-4"));
var _ProcessSteps = _interopRequireDefault(require("@/components/ProcessSteps"));
var _default = exports.default = {
  name: 'MonitorCompetitors',
  components: {
    PackageFeatureComponent: _packageFeatures.default,
    PricePackageComponent: _pricePackages.default,
    Step3Component: _step.default,
    Step4Component: _step2.default,
    ProcessSteps: _ProcessSteps.default
  },
  data: function data() {
    return {
      active_step: 0,
      steps: [{
        name: 'Package Features',
        description: 'Package Features Description'
      }, {
        name: 'Price Packages',
        description: 'Price Packages Description'
      }, {
        name: 'Step 3',
        description: 'Step 3 Description'
      }, {
        name: 'Step 4',
        description: 'Step 4 Description'
      }]
    };
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    updateStep: function updateStep(active_step) {
      this.active_step = active_step;
    }
  }
};