"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-form", {
    ref: "product_service_form",
    attrs: {
      model: _vm.product_service_form,
      "label-width": "180px",
      rules: _vm.productServiceRules
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Type",
      prop: "type"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.product_service_form.type,
      callback: function callback($$v) {
        _vm.$set(_vm.product_service_form, "type", $$v);
      },
      expression: "product_service_form.type"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: "Product"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Service"
    }
  })], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Offering",
      prop: "offeringId"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Select Offering",
      size: "mini"
    },
    model: {
      value: _vm.product_service_form.offeringId,
      callback: function callback($$v) {
        _vm.$set(_vm.product_service_form, "offeringId", $$v);
      },
      expression: "product_service_form.offeringId"
    }
  }, _vm._l(_vm.offerings, function (offering, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: offering.title,
        value: offering.id
      }
    });
  }), 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: _vm.product_service_form.type + " name",
      prop: "title"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.product_service_form.title,
      callback: function callback($$v) {
        _vm.$set(_vm.product_service_form, "title", $$v);
      },
      expression: "product_service_form.title"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: _vm.product_service_form.type + " Description",
      prop: "description"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      size: "mini"
    },
    model: {
      value: _vm.product_service_form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.product_service_form, "description", $$v);
      },
      expression: "product_service_form.description"
    }
  })], 1), _vm._v(" "), _vm.product_service_form.type === "Product" ? _c("el-form-item", {
    attrs: {
      label: _vm.product_service_form.type + " Manager",
      prop: "manager"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Select Manager",
      size: "mini"
    },
    model: {
      value: _vm.product_service_form.manager,
      callback: function callback($$v) {
        _vm.$set(_vm.product_service_form, "manager", $$v);
      },
      expression: "product_service_form.manager"
    }
  }, _vm._l(_vm.users, function (user, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: _vm.computeUserName(user),
        value: _vm.computeUserName(user)
      }
    });
  }), 1)], 1) : _vm._e(), _vm._v(" "), _vm.product_service_form.type === "Product" ? _c("el-form-item", {
    attrs: {
      label: _vm.product_service_form.type + " Owner",
      prop: "owner"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "Select Owner",
      size: "mini"
    },
    model: {
      value: _vm.product_service_form.owner,
      callback: function callback($$v) {
        _vm.$set(_vm.product_service_form, "owner", $$v);
      },
      expression: "product_service_form.owner"
    }
  }, _vm._l(_vm.users, function (user, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: _vm.computeUserName(user),
        value: _vm.computeUserName(user)
      }
    });
  }), 1)], 1) : _vm._e(), _vm._v(" "), _c("el-form-item")], 1), _vm._v(" "), _c("div", {
    staticClass: "text-right"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini",
      icon: _vm.loading.general ? "el-icon-loading" : "",
      disabled: _vm.loading.general
    },
    on: {
      click: _vm.createProductService
    }
  }, [_vm._v("Create " + _vm._s(_vm.product_service_form.type))]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "secondary",
      size: "mini",
      disabled: _vm.loading.general
    },
    on: {
      click: _vm.emitCloseEvent
    }
  }, [_vm._v("Close")])], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;