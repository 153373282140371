"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _axios = _interopRequireDefault(require("axios"));
var _elementUi = require("element-ui");
var _store = _interopRequireDefault(require("@/store"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
// import { getToken } from '@/utils/auth'

var router = new _vueRouter.default();

// create an axios instance
var service = _axios.default.create({
  baseURL: 'https://prodiggi-dev-api.azurewebsites.net:443/api/',
  // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000 // request timeout
});

// request interceptor
service.interceptors.request.use(function (config) {
  // do something before request is sent

  if (_store.default.getters.token) {
    // let each request carry token
    // ['X-Token'] is a custom headers key
    // please modify it according to the actual situation
    // config.headers['Authorization'] = getToken()
    config.headers['x-api-key'] = 'PMAK-5daea9c7182729002ac1cb5c-f34eeedf1f8dc6ff3f6ed630deb9b3843e';
    /* config.headers['x-party-id'] = getToken()
    config.headers['x-product-id'] = getToken()
    config.headers['access_token'] = getToken()*/
  }
  return config;
}, function (error) {
  // do something with request error
  console.log(error); // for debug
  return Promise.reject(error);
});

// response interceptor
service.interceptors.response.use(
/**
 * If you want to get http information such as headers or status
 * Please return  response => response
*/

/**
 * Determine the request status by custom code
 * Here is just an example
 * You can also judge the status by HTTP Status Code
 */
function (response) {
  var res = {};
  // Handle both success response and error response
  if (typeof response.data !== 'undefined') {
    // Success Response
    res = response.data;
  } else {
    // Error Response
    res = response.response;
    res.message = res.data.error.message;
    res.data['data'] = [res.data.error.message];
  }

  // Handle Error responses first

  // 503
  if (res.status === 503) {
    router.push({
      path: "/503"
    });
    location.reload();
  }
  if (res.status === 404 || res.status === 401 || res.status === 403 || res.status === 400) {
    Notification({
      title: 'Error',
      message: res.message || res.statusText,
      type: 'error'
    });
  }
  return Promise.resolve(res);
}, function (error) {
  console.log('err' + error); // for debug
  (0, _elementUi.Message)({
    message: error.message,
    type: 'error',
    duration: 5 * 1000
  });
  return Promise.reject(error);
});
var _default = exports.default = service;