"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "block"
  }, [_vm.integrations.length > 0 ? _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      offset: 8,
      span: 8
    }
  }, [_c("el-input", {
    attrs: {
      "prefix-icon": "el-icon-search",
      type: "text",
      size: "mini",
      placeholder: "Search integration"
    },
    model: {
      value: _vm.search_integrations.q,
      callback: function callback($$v) {
        _vm.$set(_vm.search_integrations, "q", $$v);
      },
      expression: "search_integrations.q"
    }
  })], 1), _vm._v(" "), _c("el-col", {
    staticClass: "text-right",
    attrs: {
      span: 8
    }
  }, [_c("el-button", {
    attrs: {
      type: "success",
      size: "mini",
      icon: "el-icon-plus"
    },
    on: {
      click: _vm.showAddServiceDialog
    }
  }, [_vm._v("Add Integration")])], 1)], 1) : _vm._e(), _vm._v(" "), _vm.activeIntegrations.length > 0 ? _c("div", {
    staticClass: "mt-10"
  }, _vm._l(_vm.activeIntegrations, function (integration, index) {
    return _c("el-card", {
      key: index,
      staticClass: "integration-card mt-5"
    }, [_c("div", {
      staticClass: "clearfix",
      attrs: {
        slot: "header"
      },
      slot: "header"
    }, [_c("el-row", [_c("el-col", {
      attrs: {
        span: 18
      }
    }, [integration.externalSystemName === "JIRA" ? _c("svg-icon", {
      attrs: {
        "icon-class": "jira"
      }
    }) : _vm._e(), _vm._v(" "), _c("span", [_c("strong", [_vm._v(_vm._s(integration.appName))])])], 1), _vm._v(" "), _c("el-col", {
      attrs: {
        span: 6
      }
    }, [_c("el-button", {
      staticClass: "fr",
      attrs: {
        type: "danger",
        icon: "el-icon-delete",
        circle: "",
        size: "mini"
      },
      on: {
        click: function click($event) {
          return _vm.revokeAccess(integration);
        }
      }
    }), _vm._v(" "), !integration.enabled && !integration.isTokenActive ? _c("el-button", {
      staticClass: "fr",
      attrs: {
        type: "primary",
        icon: "el-icon-paperclip",
        circle: "",
        size: "mini"
      },
      on: {
        click: function click($event) {
          return _vm.authouriseAccess(integration);
        }
      }
    }) : _vm._e()], 1)], 1)], 1), _vm._v(" "), _c("el-descriptions", {
      attrs: {
        column: 2,
        size: "mini",
        border: ""
      }
    }, [_c("template", {
      slot: "extra"
    }), _vm._v(" "), _c("el-descriptions-item", [_c("template", {
      slot: "label"
    }, [_c("i", {
      staticClass: "el-icon-user"
    }), _vm._v("\n            Provider\n          ")]), _vm._v(" "), integration.externalSystemName === "JIRA" ? _c("img", {
      staticStyle: {
        height: "20px"
      },
      attrs: {
        src: "/static/img/jira.png"
      }
    }) : _vm._e()], 2), _vm._v(" "), _c("el-descriptions-item", [_c("template", {
      slot: "label"
    }, [_c("i", {
      staticClass: "el-icon-link"
    }), _vm._v("\n            URL\n          ")]), _vm._v(" "), _c("i", [_vm._v(_vm._s(_vm.computeIntegrationURL(integration)))])], 2), _vm._v(" "), _c("el-descriptions-item", [_c("template", {
      slot: "label"
    }, [_c("i", {
      staticClass: "el-icon-location-outline"
    }), _vm._v("\n            Client\n          ")]), _vm._v(" "), _c("table", [_c("tr", [_c("th", [_vm._v("ID")]), _vm._v(" "), _c("td", [_c("el-tag", {
      staticStyle: {
        "font-size": "0.9em",
        height: "16pt",
        "line-height": "16pt"
      }
    }, [_vm._v(_vm._s(_vm.computeDisplayable(integration.clientId)))]), _c("i", {
      staticClass: "pl-5 el-icon-document-copy",
      on: {
        click: function click($event) {
          return _vm.copyToClipboard(integration.clientId);
        }
      }
    })], 1)]), _vm._v(" "), _c("tr", [_c("th", [_vm._v("Secret")]), _vm._v(" "), _c("td", [_c("el-tag", {
      staticStyle: {
        "font-size": "0.9em",
        height: "16pt",
        "line-height": "16pt"
      }
    }, [_vm._v(_vm._s(_vm.computeDisplayable(integration.clientSecret)))]), _c("i", {
      staticClass: "pl-5 el-icon-document-copy",
      on: {
        click: function click($event) {
          return _vm.copyToClipboard(integration.clientSecret);
        }
      }
    })], 1)])])], 2)], 2), _vm._v(" "), _c("el-tabs", {
      attrs: {
        value: "projects"
      }
    }, [_c("el-tab-pane", {
      attrs: {
        label: "Projects",
        name: "projects"
      }
    }, [_c("el-row", {
      staticClass: "mt-5",
      attrs: {
        gutter: 20
      }
    }, [_c("el-col", {
      attrs: {
        offset: 12,
        span: 12
      }
    }, [_c("el-button-group", {
      staticClass: "fr"
    }, [_c("el-button", {
      attrs: {
        type: "primary",
        icon: integration.isSync ? "el-icon-loading" : "el-icon-refresh-right",
        size: "micro"
      },
      on: {
        click: function click($event) {
          return _vm.loadProjects(integration, index);
        }
      }
    }, [_vm._v("Reload")]), _vm._v(" "), _c("el-button", {
      attrs: {
        type: "primary",
        icon: "el-icon-plus",
        size: "micro"
      },
      on: {
        click: function click($event) {
          return _vm.displayImportProjectDialog(integration, index);
        }
      }
    }, [_vm._v("Add Project")])], 1)], 1)], 1), _vm._v(" "), _c("el-table", {
      staticStyle: {
        width: "100%",
        "font-size": "0.9em"
      },
      attrs: {
        data: _vm.filteredProjectsByImport(integration.projects),
        stripe: "",
        size: "micro"
      }
    }, [_c("div", {
      attrs: {
        slot: "empty"
      },
      slot: "empty"
    }, [_c("span", [_vm._v("No projects imported")]), _vm._v(" "), _c("br"), _vm._v(" "), _c("el-button", {
      attrs: {
        type: "primary",
        icon: "el-icon-download",
        size: "mini"
      },
      on: {
        click: function click($event) {
          return _vm.displayImportProjectDialog(integration, index);
        }
      }
    }, [_vm._v("Import")])], 1), _vm._v(" "), _c("el-table-column", {
      attrs: {
        label: "Project",
        prop: "name"
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(scope) {
          return [scope.row.picture ? _c("img", {
            staticStyle: {
              height: "16px",
              "border-radius": "8px"
            },
            attrs: {
              src: scope.row.picture["16x16"]
            }
          }) : _c("i", {
            staticClass: "el-icon-takeaway-box",
            staticStyle: {
              height: "16px"
            }
          }), _vm._v("\n                " + _vm._s(scope.row.name) + "\n              ")];
        }
      }], null, true)
    }), _vm._v(" "), _c("el-table-column", {
      attrs: {
        label: "Mapping"
      }
    }, [[_vm._v("\n                TBA\n              ")]], 2), _vm._v(" "), _c("el-table-column", {
      attrs: {
        label: "#",
        width: "80"
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(scope) {
          return [scope.row.loading ? _c("i", {
            staticClass: "integration-project-list el-icon-loading",
            staticStyle: {
              color: "#409EFF"
            }
          }) : [_c("el-tooltip", {
            attrs: {
              effect: "dark",
              content: "Delete Project",
              placement: "top-start"
            }
          }, [_c("i", {
            staticClass: "integration-project-list el-icon-delete",
            staticStyle: {
              color: "#F56C6C"
            },
            on: {
              click: function click($event) {
                return _vm.deleteProject(integration, index, scope.row, scope.$index);
              }
            }
          })])]];
        }
      }], null, true)
    })], 1)], 1), _vm._v(" "), _c("el-tab-pane", {
      attrs: {
        label: "Users",
        name: "users"
      }
    }, [_c("el-row", {
      staticClass: "mt-5",
      attrs: {
        gutter: 20
      }
    }, [_c("el-col", {
      attrs: {
        span: 12
      }
    }, [_c("h4", [_vm._v("External Users")]), _vm._v(" "), _c("el-table", {
      staticStyle: {
        width: "100%",
        "font-size": "0.9em"
      },
      attrs: {
        data: integration.users,
        stripe: "",
        size: "micro"
      }
    }, [_c("el-table-column", {
      attrs: {
        label: "#"
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(scope) {
          return [scope.row.picture ? _c("img", {
            staticStyle: {
              height: "16px",
              "border-radius": "8px"
            },
            attrs: {
              src: scope.row.picture["16x16"]
            }
          }) : _vm._e()];
        }
      }], null, true)
    }), _vm._v(" "), _c("el-table-column", {
      attrs: {
        label: "User",
        prop: "displayName"
      }
    })], 1)], 1), _vm._v(" "), _c("el-col", {
      attrs: {
        span: 12
      }
    }, [_c("el-button-group", {
      staticClass: "fr"
    }, [_c("el-button", {
      attrs: {
        type: "primary",
        icon: integration.isSync ? "el-icon-loading" : "el-icon-refresh-right",
        size: "micro"
      },
      on: {
        click: function click($event) {
          return _vm.loadUsers(integration, index);
        }
      }
    }, [_vm._v("Reload")]), _vm._v(" "), _c("el-button", {
      attrs: {
        type: "primary",
        icon: integration.isSync ? "el-icon-loading" : "el-icon-refresh",
        size: "micro"
      },
      on: {
        click: function click($event) {
          return _vm.syncUsers(integration, index);
        }
      }
    }, [_vm._v("Sync")])], 1)], 1)], 1)], 1)], 1)], 1);
  }), 1) : _c("div", {
    staticClass: "text-center"
  }, [_c("span", [_vm._v(" No Services Integrated ")]), _c("br"), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.showAddServiceDialog
    }
  }, [_vm._v("Integration Manager")])], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Add 3rd Party Integration",
      visible: _vm.serviceDialog,
      width: "60%",
      left: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.serviceDialog = $event;
      },
      close: function close($event) {
        return _vm.retrieveIntegrations();
      }
    }
  }, [_c("el-row", {
    staticStyle: {
      "border-top": "solid 1pt lightgrey",
      "border-bottom": "solid 1pt lightgrey",
      padding: "2pt"
    },
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 5
    }
  }, [_c("img", {
    staticStyle: {
      height: "42px"
    },
    attrs: {
      src: "/static/img/jira.png"
    }
  })]), _vm._v(" "), _c("el-col", {
    staticClass: "text-left",
    attrs: {
      span: 14
    }
  }, [_c("div", {
    staticStyle: {
      "word-break": "break-word",
      "font-size": "0.9em"
    }
  }, [_vm._v("Integrate Product Features, Product Stories, Processes, and Process Stories with Jira Epics & Stories.\n          "), _c("strong", [_vm._v("Advanced Delivery")]), _vm._v(" integrates Major and Minor releases and Delivery Tasks with Jira Releases/Versions and tasks. The "), _c("b", [_vm._v("Software Designer's")]), _vm._v(" technical tasks and components integrate with Jira components and tasks.\n\n        ")])]), _vm._v(" "), _c("el-col", {
    staticClass: "text-right",
    attrs: {
      span: 5
    }
  }, [_c("el-button", {
    staticStyle: {
      margin: "6pt 0 6pt 0"
    },
    attrs: {
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.selectService("JIRA");
      }
    }
  }, [_vm._v(" Add Tenant")])], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.serviceDialog = false;
      }
    }
  }, [_vm._v("Cancel")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "JIRA Service Integration",
      visible: _vm.JIRAServiceSettingDialog,
      width: "40%",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.JIRAServiceSettingDialog = $event;
      }
    }
  }, [_c("div", {
    staticClass: "text-right"
  }, [_c("el-popover", {
    attrs: {
      placement: "top-start",
      title: "JIRA Integration Steps",
      width: "350",
      trigger: "hover",
      "open-delay": 400,
      "close-delay": 400
    }
  }, [_c("div", [_c("ul", [_c("li", [_vm._v("Login into you Atlassian "), _c("b", [_c("a", {
    staticStyle: {
      color: "navy"
    },
    attrs: {
      href: "https://developer.atlassian.com/console/myapps"
    }
  }, [_vm._v("Developer account")])])]), _vm._v(" "), _c("li", [_vm._v('Navigate to "settings" tab in sidepanel. ')]), _vm._v(" "), _c("li", [_vm._v("Scroll to end of page for ClientID and Secret")])])]), _vm._v(" "), _c("span", {
    attrs: {
      slot: "reference"
    },
    slot: "reference"
  }, [_vm._v("Instructions")])])], 1), _vm._v(" "), _c("el-form", {
    ref: "jira_integration_form",
    attrs: {
      model: _vm.jira_integration_form,
      "label-width": "120px",
      rules: _vm.jiraIntegrationRules
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "App Name",
      prop: "app_name"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.jira_integration_form.app_name,
      callback: function callback($$v) {
        _vm.$set(_vm.jira_integration_form, "app_name", $$v);
      },
      expression: "jira_integration_form.app_name"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Client ID",
      prop: "client_id"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.jira_integration_form.client_id,
      callback: function callback($$v) {
        _vm.$set(_vm.jira_integration_form, "client_id", $$v);
      },
      expression: "jira_integration_form.client_id"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Client Secret",
      prop: "client_secret"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.jira_integration_form.client_secret,
      callback: function callback($$v) {
        _vm.$set(_vm.jira_integration_form, "client_secret", $$v);
      },
      expression: "jira_integration_form.client_secret"
    }
  })], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer text-right",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button-group", [_c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.authouriseJIRAIntegration
    }
  }, [_vm._v("Authorise")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.JIRAServiceSettingDialog = false;
      }
    }
  }, [_vm._v("Cancel")])], 1)], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Service Integration",
      visible: _vm.serviceSettingDialog,
      width: "50%",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.serviceSettingDialog = $event;
      }
    }
  }, [_c("div", {
    staticClass: "text-center"
  }, [_vm._v("\n      JIRA integration details to be updated here\n    ")]), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    staticStyle: {
      "padding-bottom": "50px"
    },
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button-group", [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.serviceSettingDialog = false;
      }
    }
  }, [_vm._v("Cancel")])], 1)], 1)]), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Add Project",
      visible: _vm.importProjectDialog,
      width: "35%",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.importProjectDialog = $event;
      }
    }
  }, [_c("import-project-component", {
    attrs: {
      projects: _vm.selectedIntegration.projects,
      integration: _vm.selectedIntegration,
      "integration-index": _vm.selectedIntegrationIndex
    },
    on: {
      import: _vm.importProjects,
      close: _vm.closeImportProjectDialog
    }
  })], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;