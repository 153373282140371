"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.ticket && _vm.ticket.id ? _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "ticket-modal ticket-modal-font-size"
  }, [_c("el-tabs", {
    staticStyle: {
      "margin-top": "-20pt"
    },
    attrs: {
      id: "ticketDetailsTabs"
    },
    on: {
      "tab-click": _vm.handleTabClick
    },
    model: {
      value: _vm.activeTab,
      callback: function callback($$v) {
        _vm.activeTab = $$v;
      },
      expression: "activeTab"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "Ticket Details",
      name: "ticket_details"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    staticClass: "text-left ml-10 mr-10",
    staticStyle: {
      padding: "5pt"
    },
    attrs: {
      xs: 12,
      sm: 12,
      md: 12,
      lg: 15,
      xl: 12
    }
  }, [_c("table", {
    staticStyle: {
      width: "100%"
    }
  }, [_c("colgroup", [_c("col", {
    staticStyle: {
      width: "80pt"
    },
    attrs: {
      span: "1"
    }
  })]), _vm._v(" "), _c("tr", {
    staticClass: "text-left"
  }, [_c("th", [_vm._v("Ticket Name")]), _vm._v(" "), _c("td", [_c("el-input", {
    staticStyle: {
      "margin-top": "1pt",
      "margin-bottom": "1pt"
    },
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.ticket.title,
      callback: function callback($$v) {
        _vm.$set(_vm.ticket, "title", $$v);
      },
      expression: "ticket.title"
    }
  })], 1)])]), _vm._v(" "), _c("table", [_c("colgroup", [_c("col", {
    staticStyle: {
      width: "80pt"
    },
    attrs: {
      span: "1"
    }
  })]), _vm._v(" "), _c("tr", {
    staticClass: "text-left"
  }, [_c("th", [_vm._v("Ticket ID")]), _vm._v(" "), _c("td", [_c("el-input", {
    staticClass: "mt-5",
    staticStyle: {
      "margin-top": "1pt",
      "margin-bottom": "1pt",
      width: "140pt"
    },
    attrs: {
      size: "mini",
      disabled: ""
    },
    model: {
      value: _vm.ticket.slug,
      callback: function callback($$v) {
        _vm.$set(_vm.ticket, "slug", $$v);
      },
      expression: "ticket.slug"
    }
  })], 1)]), _vm._v(" "), _vm.parentType === "Product" && _vm.child.relations.steps[0].id === null || _vm.parentType === "Service" && _vm.child.relations.id === null ? _c("tr", {
    staticClass: "text-left"
  }, [_c("th", [_vm._v("Step")]), _vm._v(" "), _c("td", [_c("el-select", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.step_relation_update_loading,
      expression: "step_relation_update_loading"
    }],
    staticStyle: {
      "margin-top": "1pt",
      "margin-bottom": "1pt"
    },
    attrs: {
      placeholder: "Step",
      size: "mini"
    },
    on: {
      change: _vm.updateEntityStepRelation
    },
    model: {
      value: _vm.relation_step_id,
      callback: function callback($$v) {
        _vm.relation_step_id = $$v;
      },
      expression: "relation_step_id"
    }
  }, _vm._l(_vm.journeyMap.linkedJourneySteps, function (step) {
    return _c("el-option", {
      key: step.journeyStep.id,
      attrs: {
        label: step.journeyStep.title,
        value: step.journeyStep.id
      }
    });
  }), 1)], 1)]) : _vm._e(), _vm._v(" "), _vm.parentType === "Service" && _vm.child.relations && _vm.child.relations.id === null ? _c("tr", {
    staticClass: "text-left"
  }, [_c("th", [_vm._v("Feature")]), _vm._v(" "), _c("td", [_c("el-select", {
    staticStyle: {
      "margin-top": "1pt",
      "margin-bottom": "1pt"
    },
    attrs: {
      placeholder: "Feature",
      size: "mini"
    },
    on: {
      change: _vm.updateEntityFeatureRelation
    },
    model: {
      value: _vm.relation_feature_id,
      callback: function callback($$v) {
        _vm.relation_feature_id = $$v;
      },
      expression: "relation_feature_id"
    }
  }, _vm._l(_vm.offering.linkedProducts, function (product) {
    return _c("el-option-group", {
      key: product.product.id,
      attrs: {
        label: product.product.title,
        value: product.product.id
      }
    }, _vm._l(product.product.linkedFeatures, function (feature) {
      return _c("el-option", {
        key: feature.productFeature.id,
        attrs: {
          label: feature.productFeature.title,
          value: feature.productFeature.id
        }
      });
    }), 1);
  }), 1)], 1)]) : _vm._e(), _vm._v(" "), _c("tr", [_c("th", [_vm._v("Ticket Type")]), _vm._v(" "), _c("td", [_c("el-select", {
    staticClass: "mr-20",
    staticStyle: {
      "margin-top": "1pt",
      "margin-bottom": "1pt",
      width: "140pt"
    },
    attrs: {
      placeholder: "Ticket Type",
      size: "mini",
      disabled: ""
    },
    model: {
      value: _vm.ticket.type,
      callback: function callback($$v) {
        _vm.$set(_vm.ticket, "type", $$v);
      },
      expression: "ticket.type"
    }
  }, _vm._l(_vm.tickettypes, function (type) {
    return _c("el-option", {
      key: type.value,
      attrs: {
        label: type.label,
        value: type.value
      }
    });
  }), 1)], 1), _vm._v(" "), _vm.ticket.type === "Process Epic" ? _c("th", [_vm._v("Ticket Process Type")]) : _vm._e(), _vm._v(" "), _vm.ticket.type === "Process Epic" ? _c("td", [_c("el-select", {
    staticClass: "mr-20",
    staticStyle: {
      "margin-top": "1pt",
      "margin-bottom": "1pt",
      width: "140pt"
    },
    attrs: {
      placeholder: "Ticket Process Type",
      size: "mini"
    },
    on: {
      change: _vm.handleTicketSubTypeChange
    },
    model: {
      value: _vm.child.additionalattributes.type,
      callback: function callback($$v) {
        _vm.$set(_vm.child.additionalattributes, "type", $$v);
      },
      expression: "child.additionalattributes.type"
    }
  }, [_c("el-option", {
    attrs: {
      label: "Automated",
      value: "automated"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Semi-Automated",
      value: "semi_automated"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Manual",
      value: "manual"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Thirdparty",
      value: "thirdparty"
    }
  })], 1)], 1) : _vm._e(), _vm._v(" "), _c("th", [_vm._v("Enabler")]), _vm._v(" "), _c("td", [_c("el-checkbox", {
    attrs: {
      disabled: ""
    },
    on: {
      change: _vm.updateEnabledStatus
    },
    model: {
      value: _vm.child.enabler,
      callback: function callback($$v) {
        _vm.$set(_vm.child, "enabler", $$v);
      },
      expression: "child.enabler"
    }
  })], 1)])]), _vm._v(" "), _c("div", {
    staticClass: "mt-10",
    staticStyle: {
      margin: "15pt 0 5pt 2pt"
    }
  }, [_c("strong", [_vm._v(_vm._s(_vm.ticket.type) + " Description")])]), _vm._v(" "), _c("tinymce-component", {
    staticStyle: {
      "margin-left": "2pt"
    },
    attrs: {
      value: _vm.ticket.description,
      height: 550,
      upload: false,
      menubar: "",
      toolbar: ["bold italic underline | alignleft aligncenter alignright | bullist numlist | outdent indent"]
    },
    on: {
      update: _vm.updateTicketDescription
    }
  })], 1), _vm._v(" "), _c("el-col", {
    staticClass: "mt-10",
    staticStyle: {
      border: "solid 1pt lightgrey",
      "box-shadow": "3px 3px 3px 3px lightgrey"
    },
    attrs: {
      "xs:": 11,
      sm: 11,
      md: 11,
      lg: 8,
      xl: 11,
      offset: 0.5
    }
  }, [_c("el-collapse", {
    model: {
      value: _vm.collapseActiveNames,
      callback: function callback($$v) {
        _vm.collapseActiveNames = $$v;
      },
      expression: "collapseActiveNames"
    }
  }, [_c("el-collapse-item", {
    attrs: {
      name: "1"
    }
  }, [_c("template", {
    slot: "title"
  }, [_c("span", [_c("b", [_vm._v("Priority & Status")])])]), _vm._v(" "), _c("table", {
    attrs: {
      width: "100%"
    }
  }, [_c("col", {
    staticStyle: {
      width: "32%"
    }
  }), _vm._v(" "), _c("col", {
    staticStyle: {
      width: "58%"
    }
  }), _vm._v(" "), _c("tr", {
    staticClass: "text-left"
  }, [_c("th", {
    staticClass: "non-bold-th"
  }, [_vm._v("External status")]), _vm._v(" "), _c("td", [_c("el-tag", {
    attrs: {
      size: "mini",
      type: "primary"
    }
  }, [_vm._v(_vm._s(_vm.ticket.externalStatus))])], 1)]), _vm._v(" "), _c("tr", {
    staticClass: "text-left"
  }, [_c("th", {
    staticClass: "non-bold-th"
  }, [_vm._v("Status")]), _vm._v(" "), _c("td", [_c("el-select", {
    attrs: {
      placeholder: "Select Status",
      size: "mini"
    },
    model: {
      value: _vm.ticket.status,
      callback: function callback($$v) {
        _vm.$set(_vm.ticket, "status", $$v);
      },
      expression: "ticket.status"
    }
  }, _vm._l(_vm.statuses, function (status) {
    return _c("el-option", {
      key: status.value,
      staticClass: "slim-list",
      attrs: {
        label: status.label,
        value: status.value
      }
    });
  }), 1)], 1)]), _vm._v(" "), _c("tr", {
    staticClass: "text-left"
  }, [_c("th", {
    staticClass: "non-bold-th"
  }, [_vm._v("Priority")]), _vm._v(" "), _c("td", [_c("el-select", {
    attrs: {
      placeholder: "Select Priority",
      size: "mini"
    },
    model: {
      value: _vm.ticket.priority,
      callback: function callback($$v) {
        _vm.$set(_vm.ticket, "priority", $$v);
      },
      expression: "ticket.priority"
    }
  }, _vm._l(_vm.priorities, function (priority) {
    return _c("el-option", {
      key: priority.value,
      staticClass: "slim-list",
      attrs: {
        label: priority.label,
        value: priority.value
      }
    });
  }), 1)], 1)]), _vm._v(" "), _c("tr", {
    staticClass: "text-left"
  }, [_c("th", {
    staticClass: "non-bold-th"
  }, [_vm._v("Technical Priority")]), _vm._v(" "), _c("td", [_c("el-select", {
    attrs: {
      placeholder: "Select Priority",
      size: "mini"
    },
    model: {
      value: _vm.ticket.priority,
      callback: function callback($$v) {
        _vm.$set(_vm.ticket, "priority", $$v);
      },
      expression: "ticket.priority"
    }
  }, _vm._l(_vm.priorities, function (priority) {
    return _c("el-option", {
      key: priority.value,
      staticClass: "slim-list",
      attrs: {
        label: priority.label,
        value: priority.value
      }
    });
  }), 1)], 1)]), _vm._v(" "), _c("tr", {
    staticClass: "text-left",
    staticStyle: {
      height: "28px",
      "margin-top": "1pt",
      "margin-bottom": "1pt"
    }
  }, [_c("th", {
    staticClass: "non-bold-th"
  }, [_vm._v("Smart Priority")]), _vm._v(" "), _c("td", {
    staticStyle: {
      "font-size": "9pt"
    }
  }, [_c("el-input", {
    staticClass: "mt-5",
    staticStyle: {
      "margin-top": "1pt",
      "margin-bottom": "1pt",
      width: "100pt"
    },
    attrs: {
      size: "mini",
      disabled: ""
    },
    model: {
      value: _vm.ticket.smart_priority,
      callback: function callback($$v) {
        _vm.$set(_vm.ticket, "smart_priority", $$v);
      },
      expression: "ticket.smart_priority"
    }
  }), _vm._v(" "), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Smart Priority automatically orders tickets based on business and customer value",
      placement: "right"
    }
  }, [_c("i", {
    staticClass: "fa fa-question pl-5",
    staticStyle: {
      color: "#ff6600"
    }
  })])], 1)]), _vm._v(" "), _c("tr", {
    staticClass: "text-left",
    staticStyle: {
      height: "28px"
    }
  }, [_c("th", {
    staticClass: "non-bold-th"
  }, [_vm._v("Value")]), _vm._v(" "), _c("td", [_c("el-select", {
    attrs: {
      placeholder: "Select Value",
      size: "mini"
    },
    model: {
      value: _vm.ticket.importance,
      callback: function callback($$v) {
        _vm.$set(_vm.ticket, "importance", $$v);
      },
      expression: "ticket.importance"
    }
  }, [_c("el-option", {
    staticClass: "slim-list",
    staticStyle: {
      "font-size": "9pt"
    },
    attrs: {
      label: "Lowest",
      value: "Lowest"
    }
  }), _vm._v(" "), _c("el-option", {
    staticClass: "slim-list",
    staticStyle: {
      "font-size": "9pt"
    },
    attrs: {
      label: "Low",
      value: "Low"
    }
  }), _vm._v(" "), _c("el-option", {
    staticClass: "slim-list",
    staticStyle: {
      "font-size": "9pt"
    },
    attrs: {
      label: "Medium",
      value: "Medium"
    }
  }), _vm._v(" "), _c("el-option", {
    staticClass: "slim-list",
    staticStyle: {
      "font-size": "9pt"
    },
    attrs: {
      label: "High",
      value: "High"
    }
  }), _vm._v(" "), _c("el-option", {
    staticClass: "slim-list",
    staticStyle: {
      "font-size": "9pt"
    },
    attrs: {
      label: "Highest",
      value: "Highest"
    }
  })], 1)], 1)])])], 2), _vm._v(" "), _c("el-collapse-item", {
    attrs: {
      name: "2"
    }
  }, [_c("template", {
    slot: "title"
  }, [_c("span", [_c("b", [_vm._v("Governance")])])]), _vm._v(" "), _c("table", {
    attrs: {
      width: "100%"
    }
  }, [_c("col", {
    staticStyle: {
      width: "32%"
    }
  }), _vm._v(" "), _c("col", {
    staticStyle: {
      width: "58%"
    }
  }), _vm._v(" "), _c("tr", {
    staticClass: "text-left",
    staticStyle: {
      height: "28px"
    }
  }, [_c("th", {
    staticClass: "non-bold-th"
  }, [_vm._v("Approved By")]), _vm._v(" "), _c("td", [_c("el-select", {
    attrs: {
      size: "mini",
      placeholder: "Select Approvers"
    },
    model: {
      value: _vm.ticket.approvalVia,
      callback: function callback($$v) {
        _vm.$set(_vm.ticket, "approvalVia", $$v);
      },
      expression: "ticket.approvalVia"
    }
  }, _vm._l(_vm.users, function (user) {
    return _c("el-option", {
      key: user.id,
      attrs: {
        label: user.firstName,
        value: user.id
      }
    }, [_c("span", {
      staticStyle: {
        float: "left"
      }
    }, [_vm._v(_vm._s(user.firstName))])]);
  }), 1)], 1)]), _vm._v(" "), _c("tr", {
    staticClass: "text-left",
    staticStyle: {
      height: "28px"
    }
  }, [_c("th", {
    staticClass: "non-bold-th"
  }, [_vm._v("Created")]), _vm._v(" "), _c("td", [_vm._v(_vm._s(_vm.ticket.createdBy) + " @ " + _vm._s(_vm.computeHumanFriendlyDate(_vm.ticket.createdAt)))])]), _vm._v(" "), _c("tr", {
    staticClass: "text-left",
    staticStyle: {
      height: "28px"
    }
  }, [_c("th", {
    staticClass: "non-bold-th"
  }, [_vm._v("Last Modified")]), _vm._v(" "), _c("td", [_vm._v(_vm._s(_vm.ticket.modifiedBy) + " @ " + _vm._s(_vm.computeHumanFriendlyDate(_vm.ticket.modifiedAt)))])])])], 2), _vm._v(" "), _c("el-collapse-item", {
    attrs: {
      name: "3"
    }
  }, [_c("template", {
    slot: "title"
  }, [_c("span", [_c("b", [_vm._v("Releases")])])])], 2), _vm._v(" "), _c("el-collapse-item", {
    attrs: {
      name: "4"
    }
  }, [_c("template", {
    slot: "title"
  }, [_c("span", [_c("b", [_vm._v("Traceability")])])]), _vm._v(" "), _c("table", {
    staticClass: "text-left",
    staticStyle: {
      width: "100%"
    }
  }, [_c("tr", {
    staticClass: "text-left"
  }, [_c("th", {
    staticClass: "non-bold-th"
  }, [_vm._v("Customer Type")]), _vm._v(" "), _c("td", [_c("el-input", {
    staticClass: "mt-5",
    staticStyle: {
      "margin-top": "1pt",
      "margin-bottom": "1pt"
    },
    attrs: {
      size: "mini",
      disabled: ""
    },
    model: {
      value: _vm.customer.title,
      callback: function callback($$v) {
        _vm.$set(_vm.customer, "title", $$v);
      },
      expression: "customer.title"
    }
  })], 1)]), _vm._v(" "), _c("tr", {
    staticClass: "text-left non-bold-th"
  }, [_c("th", {
    staticClass: "non-bold-th"
  }, [_vm._v("Journey Stage")]), _vm._v(" "), _c("td", [_vm.parentType === "Product" && _vm.child.relations.steps[0].id !== null ? _c("el-input", {
    staticClass: "mt-5",
    staticStyle: {
      "margin-top": "1pt",
      "margin-bottom": "1pt"
    },
    attrs: {
      size: "mini",
      disabled: ""
    },
    model: {
      value: _vm.retrievePhaseFromStepID(_vm.child.relations.steps[0].id).title,
      callback: function callback($$v) {
        _vm.$set(_vm.retrievePhaseFromStepID(_vm.child.relations.steps[0].id), "title", $$v);
      },
      expression: "retrievePhaseFromStepID(child.relations.steps[0].id).title"
    }
  }) : _vm.parentType === "Service" && _vm.child.relations.type === "step" ? _c("el-input", {
    staticClass: "mt-5",
    staticStyle: {
      "margin-top": "1pt",
      "margin-bottom": "1pt"
    },
    attrs: {
      size: "mini",
      disabled: ""
    },
    model: {
      value: _vm.retrievePhaseFromStepID(_vm.child.relations.id).title,
      callback: function callback($$v) {
        _vm.$set(_vm.retrievePhaseFromStepID(_vm.child.relations.id), "title", $$v);
      },
      expression: "retrievePhaseFromStepID(child.relations.id).title"
    }
  }) : _vm.parentType === "Service" && _vm.child.relations.type === "feature" ? _c("el-input", {
    staticClass: "mt-5",
    staticStyle: {
      "margin-top": "1pt",
      "margin-bottom": "1pt"
    },
    attrs: {
      size: "mini",
      disabled: ""
    },
    model: {
      value: _vm.retrievePhaseFromFeatureID(_vm.child.relations.id).title,
      callback: function callback($$v) {
        _vm.$set(_vm.retrievePhaseFromFeatureID(_vm.child.relations.id), "title", $$v);
      },
      expression: "retrievePhaseFromFeatureID(child.relations.id).title"
    }
  }) : _vm._e()], 1)]), _vm._v(" "), _c("tr", {
    staticClass: "text-left"
  }, [_c("th", {
    staticClass: "non-bold-th"
  }, [_vm._v("Journey Step")]), _vm._v(" "), _c("td", [_vm.parentType === "Product" && _vm.child.relations.steps[0].id !== null ? _c("el-input", {
    staticClass: "mt-5",
    staticStyle: {
      "margin-top": "1pt",
      "margin-bottom": "1pt"
    },
    attrs: {
      size: "mini",
      disabled: ""
    },
    model: {
      value: _vm.retrieveStepFromStepID(_vm.child.relations.steps[0].id).title,
      callback: function callback($$v) {
        _vm.$set(_vm.retrieveStepFromStepID(_vm.child.relations.steps[0].id), "title", $$v);
      },
      expression: "retrieveStepFromStepID(child.relations.steps[0].id).title"
    }
  }) : _vm.parentType === "Service" && _vm.child.relations.type === "step" ? _c("el-input", {
    staticClass: "mt-5",
    staticStyle: {
      "margin-top": "1pt",
      "margin-bottom": "1pt"
    },
    attrs: {
      size: "mini",
      disabled: ""
    },
    model: {
      value: _vm.retrieveStepFromStepID(_vm.child.relations.id).title,
      callback: function callback($$v) {
        _vm.$set(_vm.retrieveStepFromStepID(_vm.child.relations.id), "title", $$v);
      },
      expression: "retrieveStepFromStepID(child.relations.id).title"
    }
  }) : _vm.parentType === "Service" && _vm.child.relations.type === "feature" ? _c("el-input", {
    staticClass: "mt-5",
    staticStyle: {
      "margin-top": "1pt",
      "margin-bottom": "1pt"
    },
    attrs: {
      size: "mini",
      disabled: ""
    },
    model: {
      value: _vm.retrieveStepFromFeatureID(_vm.child.relations.id).title,
      callback: function callback($$v) {
        _vm.$set(_vm.retrieveStepFromFeatureID(_vm.child.relations.id), "title", $$v);
      },
      expression: "retrieveStepFromFeatureID(child.relations.id).title"
    }
  }) : _vm._e()], 1)]), _vm._v(" "), _c("tr", {
    staticClass: "text-left"
  }, [_c("th", {
    staticClass: "non-bold-th"
  }, [_vm._v("Offering")]), _vm._v(" "), _c("td", [_c("el-input", {
    staticClass: "mt-5",
    staticStyle: {
      "margin-top": "1pt",
      "margin-bottom": "1pt"
    },
    attrs: {
      size: "mini",
      disabled: ""
    },
    model: {
      value: _vm.offering.title,
      callback: function callback($$v) {
        _vm.$set(_vm.offering, "title", $$v);
      },
      expression: "offering.title"
    }
  })], 1)]), _vm._v(" "), _c("tr", {
    staticClass: "text-left"
  }, [_c("th", {
    staticClass: "non-bold-th"
  }, [_vm._v("Parent " + _vm._s(_vm.parentType) + " ")]), _vm._v(" "), _c("td", [_c("el-input", {
    staticClass: "mt-5",
    staticStyle: {
      "margin-top": "1pt",
      "margin-bottom": "1pt",
      width: "140pt"
    },
    attrs: {
      size: "mini",
      disabled: ""
    },
    model: {
      value: _vm.parent.title,
      callback: function callback($$v) {
        _vm.$set(_vm.parent, "title", $$v);
      },
      expression: "parent.title"
    }
  })], 1)])])], 2), _vm._v(" "), _c("el-collapse-item", {
    attrs: {
      name: "5"
    }
  }, [_c("template", {
    slot: "title"
  }, [_c("span", [_c("b", [_vm._v("Attachments")])])]), _vm._v(" "), _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.attachment_loading,
      expression: "attachment_loading"
    }]
  }, [_c("div", {
    staticClass: "mt-10",
    staticStyle: {
      border: "solid 1pt lightgrey",
      "border-radius": "5pt",
      "margin-bottom": "20pt"
    }
  }, [_c("file-upload-component", {
    attrs: {
      "is-edit": true,
      "file-list": _vm.filterNonBPMNFiles,
      "entity-id": _vm.ticket.id,
      "entity-name": "TICKET",
      "display-type": "table"
    },
    on: {
      "file-uploaded": _vm.retrieveTicketAttachements
    }
  })], 1)])], 2)], 1)], 1)], 1)], 1), _vm._v(" "), _vm.parentType === "Service" ? _c("el-tab-pane", {
    attrs: {
      label: "BPMN",
      name: "bpmn"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("b-p-m-n-component", {
    attrs: {
      "entity-id": _vm.ticket.id,
      "entity-name": "TICKET"
    },
    on: {
      "file-uploaded": _vm.bpmnUploadedToS3
    }
  })], 1)], 1) : _vm._e(), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: "Stories & Tasks",
      name: "stories_tasks"
    }
  }, [_c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.child_ticket_loading,
      expression: "child_ticket_loading"
    }]
  }, [_c("div", {
    staticClass: "ml-5 mr-5"
  }, [_vm.child_tickets.length > 0 ? _c("el-table", {
    attrs: {
      data: _vm.child_tickets,
      size: "mini",
      stripe: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Title"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-input", {
          attrs: {
            type: "text",
            size: "mini"
          },
          model: {
            value: scope.row.title,
            callback: function callback($$v) {
              _vm.$set(scope.row, "title", $$v);
            },
            expression: "scope.row.title"
          }
        })];
      }
    }], null, false, 644953238)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Description"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-input", {
          attrs: {
            type: "text",
            size: "mini"
          },
          model: {
            value: scope.row.description,
            callback: function callback($$v) {
              _vm.$set(scope.row, "description", $$v);
            },
            expression: "scope.row.description"
          }
        })];
      }
    }], null, false, 1759765744)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Status",
      width: "140"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-select", {
          attrs: {
            placeholder: "Select Status",
            size: "mini"
          },
          model: {
            value: scope.row.status,
            callback: function callback($$v) {
              _vm.$set(scope.row, "status", $$v);
            },
            expression: "scope.row.status"
          }
        }, _vm._l(_vm.statuses, function (status) {
          return _c("el-option", {
            key: status.value,
            staticClass: "slim-list",
            attrs: {
              label: status.label,
              value: status.value,
              width: "60%"
            }
          });
        }), 1)];
      }
    }], null, false, 1963372486)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Priority",
      width: "100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-select", {
          attrs: {
            placeholder: "Select Priority",
            size: "mini"
          },
          model: {
            value: scope.row.priority,
            callback: function callback($$v) {
              _vm.$set(scope.row, "priority", $$v);
            },
            expression: "scope.row.priority"
          }
        }, _vm._l(_vm.priorities, function (priority) {
          return _c("el-option", {
            key: priority.value,
            staticClass: "slim-list",
            attrs: {
              label: priority.label,
              value: priority.value
            }
          });
        }), 1)];
      }
    }], null, false, 696847523)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      width: "320"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-button", {
          staticClass: "slim-button",
          attrs: {
            type: "primary",
            icon: "far fa-save"
          },
          on: {
            click: function click($event) {
              return _vm.addUpdateChildTicket(scope.row);
            }
          }
        }), _vm._v(" "), _c("el-button", {
          staticClass: "slim-button",
          attrs: {
            type: "warning",
            icon: "fas fa-minus-circle",
            title: "Unlink"
          },
          on: {
            click: function click($event) {
              return _vm.unlinkChildTicketFromParent(scope.row.id, _vm.ticket.id);
            }
          }
        }), _vm._v(" "), _c("el-button", {
          staticClass: "slim-button",
          attrs: {
            type: "danger",
            icon: "el-icon-delete",
            title: "Delete"
          },
          on: {
            click: function click($event) {
              return _vm.deleteChildTicket(scope.row.id, _vm.ticket.id, false);
            }
          }
        }), _vm._v(" "), _c("el-button", {
          staticClass: "slim-button",
          attrs: {
            type: "primary",
            icon: "el-icon-document",
            title: "Details"
          },
          on: {
            click: function click($event) {
              return _vm.selectChildTicket(scope.row);
            }
          }
        })];
      }
    }], null, false, 1005641139)
  }, [_c("template", {
    slot: "header"
  }, [_c("div", [_c("el-button-group", {
    attrs: {
      size: "mini"
    }
  }, [_vm.child.__typename === "ServiceApplication" ? _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.validateAndImportBPMN();
      }
    }
  }, [_vm._v(_vm._s(_vm.computeImportBPMNButtonTitle))]) : _vm._e(), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.addChildTicket
    }
  }, [_vm._v("Add story")])], 1)], 1)])], 2)], 1) : _c("el-empty", [_c("el-button-group", {
    attrs: {
      size: "mini"
    }
  }, [_vm.child.__typename === "ServiceApplication" ? _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.validateAndImportBPMN();
      }
    }
  }, [_vm._v(_vm._s(_vm.computeImportBPMNButtonTitle))]) : _vm._e(), _vm._v(" "), _c("el-button", {
    staticClass: "fr mr-5",
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.addChildTicket
    }
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "create"
    }
  }), _vm._v("Add ")], 1)], 1)], 1)], 1)])]), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: "Related Tickets",
      name: "related_tickets"
    }
  }, [_c("el-tabs", {
    attrs: {
      "tab-position": "left"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "Design tickets"
    }
  }, [_c("div", [_c("el-table", {
    attrs: {
      data: _vm.computedRelatedTicketsDesign,
      stripe: "",
      size: "micro"
    },
    on: {
      "current-change": _vm.handleRelatedTicketSelection
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Title",
      prop: "title"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Description",
      prop: "description"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Status",
      prop: "status",
      width: "80"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Priority",
      prop: "priority",
      width: "80"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "#",
      width: "175"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("i", {
          staticClass: "el-icon-link",
          on: {
            click: function click($event) {
              return _vm.deleteRelation.stop.prevent(scope.row.__typename, scope.row.id, _vm.ticket.id);
            }
          }
        })];
      }
    }], null, false, 903243223)
  })], 1)], 1)]), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: "External tickets"
    }
  }, [_c("div", [_c("span", [_vm._v("TODO Integrate with external sync project ")])])])], 1)], 1), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: "Actions & Comments",
      name: "actions_comments"
    }
  }, [_c("el-tabs", {
    attrs: {
      "tab-position": "left"
    }
  }, [_c("el-tab-pane", {
    staticStyle: {
      "justify-content": "left"
    },
    attrs: {
      label: "Discussion"
    }
  }, [_c("comment-component", {
    attrs: {
      "entity-id": _vm.ticket.id,
      "entity-name": _vm.ticket.title
    }
  })], 1), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: "Actions"
    }
  }, [_c("action-component", {
    attrs: {
      entity: {
        id: _vm.ticket.id,
        type: "EPIC"
      }
    },
    on: {
      add_action: _vm.addAction
    }
  })], 1)], 1)], 1)], 1), _vm._v(" "), _c("div", {
    staticStyle: {
      "padding-top": "10px",
      "text-align": "right"
    }
  }, [_c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.update_processing,
      expression: "update_processing"
    }],
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.updateTicket
    }
  }, [_vm._v("Update")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "secondary",
      size: "mini"
    },
    on: {
      click: _vm.emitCloseEvent
    }
  }, [_vm._v("Close")])], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Add Tickets",
      visible: _vm.addTicketDialog,
      "append-to-body": true,
      width: "40%",
      top: "1vh"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addTicketDialog = $event;
      }
    }
  }, [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.retrieve_ticket_loading,
      expression: "retrieve_ticket_loading"
    }],
    staticStyle: {
      width: "99%",
      margin: "10pt"
    },
    attrs: {
      data: _vm.filteredTickets,
      height: "550",
      border: "",
      stripe: "",
      size: "mini"
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "title",
      label: "Title"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "type",
      label: "Type"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "#"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-button", {
          attrs: {
            type: "primary",
            size: "mini"
          },
          on: {
            click: function click($event) {
              return _vm.linkChildTicketToParent(scope.row.id, _vm.ticket.id);
            }
          }
        }, [_vm._v("Add")])];
      }
    }], null, false, 3811669872)
  })], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: _vm.computeChildTicketDetailTitle(),
      visible: _vm.childTicketDetailsDialog,
      "append-to-body": true,
      width: "80%",
      top: "1vh"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.childTicketDetailsDialog = $event;
      }
    }
  }, [_vm.childTicketDetailsDialog ? _c("child-ticket-component", {
    attrs: {
      customer: _vm.customer,
      "journey-map": _vm.journeyMap,
      offering: _vm.offering,
      parent: _vm.activeTicketParent,
      child: _vm.selectedChildTicket,
      "parent-type": _vm.selectedChildTicketParentDetailType
    },
    on: {
      close: function close($event) {
        _vm.childTicketDetailsDialog = false;
      }
    }
  }) : _vm._e()], 1)], 1) : _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "text-center"
  }, [_c("div", [_vm._v(" No ticket linked to this entity. Contact Administrator for details")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "secondary",
      size: "mini"
    },
    on: {
      click: _vm.emitCloseEvent
    }
  }, [_vm._v("Close")])], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;