"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.array.sort");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "offering-idea-tab-pane-header"
  }, [_c("div", {
    staticClass: "flex",
    staticStyle: {
      "border-bottom": "2pt solid #304156",
      "padding-left": "10pt",
      "margin-bottom": "0",
      "background-color": "rgba(222,235,246,.5)"
    }
  }, [_c("el-row", [_c("div", {
    staticClass: "text-left ml-10"
  }, [_c("div", {
    staticClass: "title-caps mt-10"
  }, [_c("strong", [_vm._v("Offering Ideas")])])])]), _vm._v(" "), _c("el-row", [_c("div", {
    staticClass: "pt-5 ml-5 text-right",
    staticStyle: {
      "margin-bottom": "5pt",
      "margin-right": "8px"
    }
  }, [_c("el-button-group", [_c("el-button", {
    staticStyle: {
      top: "1pt",
      "border-radius": "3pt"
    },
    attrs: {
      type: "primary",
      size: "micro"
    },
    on: {
      click: function click($event) {
        return _vm.openNewOfferingDialog();
      }
    }
  }, [_vm._v("Improve a Offering")])], 1)], 1)])], 1), _vm._v(" "), _c("div", {
    staticClass: "flex",
    staticStyle: {
      padding: "10px 5px 5px 10px",
      "background-color": "rgba(0,0,0,.03)"
    }
  }, [_c("div", {
    staticClass: "ml-5 mr-5"
  }, [_c("el-input", {
    staticClass: "mr-5",
    attrs: {
      type: "text",
      size: "mini",
      placeholder: "Search Ideas",
      "prefix-icon": "el-icon-search"
    },
    model: {
      value: _vm.search_ideas.q,
      callback: function callback($$v) {
        _vm.$set(_vm.search_ideas, "q", $$v);
      },
      expression: "search_ideas.q"
    }
  })], 1)])]), _vm._v(" "), _vm.offeringIdea.length > 0 ? _vm._l(_vm.offeringIdea, function (idea, index) {
    return _c("div", {
      key: index,
      class: _vm.selectedIdea && _vm.selectedIdea.id === idea.id ? "list_item is-item-active" : "list_item",
      on: {
        click: function click($event) {
          return _vm.selectOffering(idea);
        }
      }
    }, [_c("table", {
      staticStyle: {
        width: "100%",
        "font-variant-caps": "small-caps"
      }
    }, [_c("tr", [_c("td", [_c("strong", [_vm._v(_vm._s(idea.title))])]), _vm._v(" "), _c("td", {
      staticClass: "text-right"
    }, [_c("strong", [_vm._v(_vm._s(idea.status))])])]), _vm._v(" "), _c("tr", [idea.linkedOfferings && idea.linkedOfferings.length > 0 ? _c("td", {
      staticClass: "text-left",
      staticStyle: {
        color: "#FF6600"
      }
    }, [_c("b", [_vm._v(_vm._s(idea.linkedOfferings[0].offering.title))])]) : _c("td", {
      staticClass: "text-left",
      staticStyle: {
        color: "#FF6600"
      }
    }), _vm._v(" "), _c("td", {
      staticClass: "fr"
    }, [_c("el-tag", {
      attrs: {
        type: "primary",
        size: "mini"
      }
    }, [_vm._v("(" + _vm._s(idea.scope) + ") " + _vm._s(idea.scopename))])], 1)])]), _vm._v(" "), _c("table", {
      staticStyle: {
        width: "100%"
      }
    }, [_c("tr", [_c("td", {
      staticStyle: {
        "text-align": "justify",
        "text-justify": "inter-word",
        "font-size": "12px"
      }
    }, [_c("i", [_vm._v(_vm._s(_vm.truncateDescription(idea.description)))])])])])]);
  }) : _c("el-empty", [_c("p", {
    attrs: {
      slot: "description"
    },
    on: {
      click: function click($event) {
        return _vm.openNewOfferingDialog();
      }
    },
    slot: "description"
  }, [_vm._v("No Data")])]), _vm._v(" "), _c("div", {
    staticClass: "text-center",
    staticStyle: {
      width: "calc(100%/24 * 4)",
      position: "fixed",
      bottom: "0px",
      "background-color": "rgba(255,255,255,1)"
    }
  }, [_c("el-pagination", {
    attrs: {
      layout: "prev, pager, next",
      small: true,
      "hide-on-single-page": true,
      total: _vm.total_count
    },
    on: {
      "current-change": _vm.loadPage,
      "prev-click": _vm.loadPage,
      "next-click": _vm.loadPage
    }
  })], 1)], 2), _vm._v(" "), _vm.selectedIdea.title ? _c("el-col", {
    attrs: {
      span: 18
    }
  }, [_c("transition", {
    attrs: {
      name: "fade"
    }
  }, [!_vm.loading ? [_c("offering-details", {
    attrs: {
      idea: _vm.selectedIdea
    },
    on: {
      delete: _vm.deleteIdea
    }
  })] : _vm._e()], 2)], 1) : _c("el-col", {
    attrs: {
      span: 18
    }
  }, [_c("div", {
    staticClass: "idea-details-header-empty text-center"
  }, [_c("h3", [_vm._v("Select an idea to view details")])])])], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Add new Idea",
      visible: _vm.newOfferingDialog,
      "custom-class": "create-idea-dialog",
      width: "40%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.newOfferingDialog = $event;
      }
    }
  }, [_c("div", {
    staticClass: "text-center",
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_c("h1", [_vm._v(_vm._s(_vm.createIdeaDialogTitle.title))]), _vm._v(" "), _c("h3", [_vm._v(_vm._s(_vm.createIdeaDialogTitle.description))])]), _vm._v(" "), _c("offering-create", {
    attrs: {
      ideas: _vm.offeringIdea
    },
    on: {
      title: _vm.updateCreateIdeaDialogTitle,
      create: _vm.createIdea,
      close: function close($event) {
        _vm.newOfferingDialog = false;
      }
    }
  })], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Filter List",
      visible: _vm.filterDialog,
      width: "30%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.filterDialog = $event;
      }
    }
  }, [_c("table", {
    staticStyle: {
      "table-layout": "fixed",
      width: "100%"
    }
  }, [_c("colgroup", [_c("col", {
    staticStyle: {
      width: "20%"
    },
    attrs: {
      span: "1"
    }
  }), _vm._v(" "), _c("col", {
    staticStyle: {
      width: "80%"
    },
    attrs: {
      span: "1"
    }
  })]), _vm._v(" "), _c("tr", [_c("th", {
    staticClass: "text-left"
  }, [_vm._v("Title")]), _vm._v(" "), _c("td", {
    staticClass: "flex"
  }, [_c("el-select", {
    staticStyle: {
      width: "150px"
    },
    attrs: {
      clearable: "",
      placeholder: "Select",
      size: "mini"
    },
    model: {
      value: _vm.search_ideas.filters.title_operator,
      callback: function callback($$v) {
        _vm.$set(_vm.search_ideas.filters, "title_operator", $$v);
      },
      expression: "search_ideas.filters.title_operator"
    }
  }, [_c("el-option", {
    attrs: {
      label: "EXACT",
      value: "EXACT"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "LIKE",
      value: "LIKE"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "STARTS",
      value: "STARTS"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "ENDS",
      value: "ENDS"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "EMPTY",
      value: "EMPTY"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "NOT EMPTY",
      value: "NOT EMPTY"
    }
  })], 1), _vm._v(" "), _c("el-input", {
    staticClass: "ml-5",
    attrs: {
      type: "text",
      size: "mini"
    },
    model: {
      value: _vm.search_ideas.filters.title,
      callback: function callback($$v) {
        _vm.$set(_vm.search_ideas.filters, "title", $$v);
      },
      expression: "search_ideas.filters.title"
    }
  })], 1)]), _vm._v(" "), _c("tr", [_c("th", {
    staticClass: "text-left"
  }, [_vm._v("Description")]), _vm._v(" "), _c("td", {
    staticClass: "flex"
  }, [_c("el-select", {
    staticStyle: {
      width: "150px"
    },
    attrs: {
      clearable: "",
      placeholder: "Select",
      size: "mini"
    },
    model: {
      value: _vm.search_ideas.filters.description_operator,
      callback: function callback($$v) {
        _vm.$set(_vm.search_ideas.filters, "description_operator", $$v);
      },
      expression: "search_ideas.filters.description_operator"
    }
  }, [_c("el-option", {
    attrs: {
      label: "EXACT",
      value: "EXACT"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "LIKE",
      value: "LIKE"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "STARTS",
      value: "STARTS"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "ENDS",
      value: "ENDS"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "EMPTY",
      value: "EMPTY"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "NOT EMPTY",
      value: "NOT EMPTY"
    }
  })], 1), _vm._v(" "), _c("el-input", {
    staticClass: "ml-5",
    attrs: {
      type: "text",
      size: "mini"
    },
    model: {
      value: _vm.search_ideas.filters.description,
      callback: function callback($$v) {
        _vm.$set(_vm.search_ideas.filters, "description", $$v);
      },
      expression: "search_ideas.filters.description"
    }
  })], 1)]), _vm._v(" "), _c("tr", [_c("th", {
    staticClass: "text-left"
  }, [_vm._v("Offering")]), _vm._v(" "), _c("td", [_c("el-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      clearable: "",
      placeholder: "Select",
      size: "mini",
      multiple: ""
    },
    model: {
      value: _vm.search_ideas.filters.offerings,
      callback: function callback($$v) {
        _vm.$set(_vm.search_ideas.filters, "offerings", $$v);
      },
      expression: "search_ideas.filters.offerings"
    }
  }, [_c("el-option", {
    attrs: {
      label: "In progress",
      value: "In progress"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "On hold",
      value: "On hold"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Overdue",
      value: "Overdue"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "Achieved",
      value: "Achieved"
    }
  })], 1)], 1)]), _vm._v(" "), _c("tr", [_c("th", {
    staticClass: "text-left"
  }, [_vm._v("Status")]), _vm._v(" "), _c("td", [_c("el-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      clearable: "",
      placeholder: "Select",
      size: "mini",
      multiple: ""
    },
    model: {
      value: _vm.search_ideas.filters.status,
      callback: function callback($$v) {
        _vm.$set(_vm.search_ideas.filters, "status", $$v);
      },
      expression: "search_ideas.filters.status"
    }
  }, _vm._l(_vm.statuses, function (status) {
    return _c("el-option", {
      key: status.id,
      attrs: {
        label: status.label,
        value: status.value
      }
    });
  }), 1)], 1)]), _vm._v(" "), _c("tr", [_c("th", {
    staticClass: "text-left"
  }, [_vm._v("Score")]), _vm._v(" "), _c("td", [_c("el-input", {
    attrs: {
      type: "number",
      size: "mini"
    },
    model: {
      value: _vm.search_ideas.filters.score,
      callback: function callback($$v) {
        _vm.$set(_vm.search_ideas.filters, "score", $$v);
      },
      expression: "search_ideas.filters.score"
    }
  })], 1)])]), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.filterIdeaList
    }
  }, [_vm._v("Filter")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: _vm.closeFilterDialog
    }
  }, [_vm._v("Cancel")])], 1)]), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Sort List",
      visible: _vm.sortDialog,
      width: "30%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.sortDialog = $event;
      }
    }
  }, [_c("table", {
    staticStyle: {
      "table-layout": "fixed",
      width: "100%"
    }
  }, [_c("colgroup", [_c("col", {
    staticStyle: {
      width: "20%"
    },
    attrs: {
      span: "1"
    }
  }), _vm._v(" "), _c("col", {
    staticStyle: {
      width: "80%"
    },
    attrs: {
      span: "1"
    }
  })]), _vm._v(" "), _c("tr", [_c("th", {
    staticClass: "text-left"
  }, [_vm._v("Title")]), _vm._v(" "), _c("td", [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.search_ideas.sort.title,
      callback: function callback($$v) {
        _vm.$set(_vm.search_ideas.sort, "title", $$v);
      },
      expression: "search_ideas.sort.title"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: "a-z"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "z-a"
    }
  })], 1)], 1)]), _vm._v(" "), _c("tr", [_c("th", {
    staticClass: "text-left"
  }, [_vm._v("Status")]), _vm._v(" "), _c("td", [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.search_ideas.sort.status,
      callback: function callback($$v) {
        _vm.$set(_vm.search_ideas.sort, "status", $$v);
      },
      expression: "search_ideas.sort.status"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: "inprogress-achieved"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "achieved-inprogress"
    }
  })], 1)], 1)]), _vm._v(" "), _c("tr", [_c("th", {
    staticClass: "text-left"
  }, [_vm._v("Score")]), _vm._v(" "), _c("td", [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.search_ideas.sort.score,
      callback: function callback($$v) {
        _vm.$set(_vm.search_ideas.sort, "score", $$v);
      },
      expression: "search_ideas.sort.score"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: "low-high"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "high-low"
    }
  })], 1)], 1)])]), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.sortIdeaList
    }
  }, [_vm._v("Sort")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: _vm.closeSortDialog
    }
  }, [_vm._v("Cancel")])], 1)])], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;