"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.sort");
var _taggedTemplateLiteral2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/taggedTemplateLiteral.js"));
var _objectSpread2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _interopRequireWildcard2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _FileUpload = _interopRequireDefault(require("@/components/FileUpload"));
var _Comment = _interopRequireDefault(require("@/components/Comment"));
var _Action = _interopRequireDefault(require("@/components/Action"));
var _ChildTicket = _interopRequireDefault(require("@/components/ChildTicket"));
var _axios = _interopRequireDefault(require("axios"));
var _utils = require("@/utils");
var _vuex = require("vuex");
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10;
var _default = exports.default = {
  name: 'RefineFeature',
  components: {
    TinymceComponent: function TinymceComponent() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/components/Tinymce'));
      });
    },
    BPMNComponent: function BPMNComponent() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/components/BPMN'));
      });
    },
    FileUploadComponent: _FileUpload.default,
    CommentComponent: _Comment.default,
    ActionComponent: _Action.default,
    ChildTicketComponent: _ChildTicket.default
  },
  props: {
    offering: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    var validateTitle = function validateTitle(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter a valid title'));
      } else {
        callback();
      }
    };
    var validateDescription = function validateDescription(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter a valid description'));
      } else {
        callback();
      }
    };
    var validateStatus = function validateStatus(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter a valid status'));
      } else {
        callback();
      }
    };
    var validatePriority = function validatePriority(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter a valid priority'));
      } else {
        callback();
      }
    };
    var validateImportance = function validateImportance(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter a valid importance'));
      } else {
        callback();
      }
    };
    return {
      tab_loading: false,
      child_ticket_loading: false,
      attachment_loading: false,
      loading: false,
      isSidebarCollapse: false,
      update_processing: false,
      filterDialog: false,
      addProductFeatureDialog: false,
      actionDiscussionDrawer: false,
      childTicketDetailsDialog: false,
      features: [],
      service_applications: [],
      products: [],
      services: [],
      total_service_applications_count: 0,
      total_features_count: 0,
      total_products_count: 0,
      total_services_count: 0,
      page_size: 5,
      active_page: 1,
      selectedEntity: {},
      selectedChildTicket: {},
      ticket: {},
      child_tickets: [],
      files: [],
      search_term: '',
      searchPool: [],
      search_entities: {
        q: '',
        filters: {
          title: '',
          title_operator: '',
          description: '',
          description_operator: '',
          status: [],
          priority: [],
          products: [],
          services: []
        },
        sort: {
          title: 'a-z'
        }
      },
      feature_form: {
        parent_type: 'Product',
        key: '',
        title: '',
        description: '',
        status: 'Candidate',
        priority: 'Medium',
        enabler: false,
        importance: 'Medium',
        productName: '',
        productId: '',
        serviceId: '',
        offeringId: '',
        posX: 0,
        issue_source: 'prodiggi',
        step_id: '',
        entityId: '',
        entityName: ''
      },
      statuses: [{
        label: 'Candidate',
        value: 'Candidate'
      }, {
        label: 'Approved',
        value: 'Approved'
      }, {
        label: 'Backlog',
        value: 'Backlog'
      }, {
        label: 'In Progress',
        value: 'In Progress'
      }, {
        label: 'Finalised',
        value: 'Finalised'
      }, {
        label: 'Released',
        value: 'Released'
      }, {
        label: 'Improvement',
        value: 'Improvement'
      }, {
        label: 'Retired',
        value: 'Retired'
      }],
      priorities: [{
        label: 'Highest',
        value: 'Highest'
      }, {
        label: 'High',
        value: 'High'
      }, {
        label: 'Medium',
        value: 'Medium'
      }, {
        label: 'Low',
        value: 'Low'
      }, {
        label: 'Lowest',
        value: 'Lowest'
      }],
      addFeatureRules: {
        title: [{
          required: true,
          trigger: 'blur',
          validator: validateTitle
        }],
        description: [{
          required: true,
          trigger: 'blur',
          validator: validateDescription
        }],
        priority: [{
          required: true,
          trigger: 'blur',
          validator: validatePriority
        }],
        status: [{
          required: true,
          trigger: 'blur',
          validator: validateStatus
        }],
        importance: [{
          required: true,
          trigger: 'blur',
          validator: validateImportance
        }]
      },
      load: {
        features: false,
        serviceapplications: false
      }
    };
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['offeringId'])), {}, {
    filteredEntities: function filteredEntities() {
      var self = this;
      return this.features.concat(this.service_applications).filter(function (entity) {
        return entity.title.toLowerCase().indexOf(self.search_entities.q) > -1;
      });
    },
    computedRelatedTicketsDesign: function computedRelatedTicketsDesign() {
      var design_tickets = [];
      if (this.selectedEntity.__typename === 'ProductFeature' && this.offering.additionalattributes.relations && this.offering.additionalattributes.relations.length > 0) {
        for (var i = 0; i < this.offering.additionalattributes.relations.length; i++) {
          if (this.offering.additionalattributes.relations[i].start_type === 'Feature' && this.offering.additionalattributes.relations[i].start_id === this.selectedEntity.id && this.offering.additionalattributes.relations[i].end_type === 'Feature') {
            design_tickets.push(this.retrieveFeature(this.offering.additionalattributes.relations[i].end_id));
          } else if (this.offering.additionalattributes.relations[i].start_type === 'Feature' && this.offering.additionalattributes.relations[i].end_id === this.selectedEntity.id && this.offering.additionalattributes.relations[i].end_type === 'Feature') {
            design_tickets.push(this.retrieveFeature(this.offering.additionalattributes.relations[i].start_id));
          }
        }
      }
      if (this.selectedEntity.__typename === 'ServiceApplication' && this.offering.additionalattributes.relations && this.offering.additionalattributes.relations.length > 0) {
        for (var j = 0; j < this.offering.additionalattributes.relations.length; j++) {
          if (this.offering.additionalattributes.relations[j].start_type === 'ServiceApplication' && this.offering.additionalattributes.relations[j].start_id === this.selectedEntity.id && this.offering.additionalattributes.relations[j].end_type === 'ServiceApplication') {
            design_tickets.push(this.retrieveServiceApplication(this.offering.additionalattributes.relations[j].end_id));
          } else if (this.offering.additionalattributes.relations[j].start_type === 'ServiceApplication' && this.offering.additionalattributes.relations[j].end_id === this.selectedEntity.id && this.offering.additionalattributes.relations[j].end_type === 'ServiceApplication') {
            design_tickets.push(this.retrieveServiceApplication(this.offering.additionalattributes.relations[j].start_id));
          }
        }
      }
      return design_tickets;
    }
  }),
  mounted: function mounted() {
    this.$eventHub.$on('offering-changed', this.reloadOfferings);
    this.reloadOfferings();
    this.loadProducts(1);
    this.loadServices(1);
  },
  methods: {
    loadInitialEntity: function loadInitialEntity() {
      if (this.load.features && this.load.serviceapplications) {
        this.selectEntity(this.filteredEntities[0], 0);
      }
    },
    trimString: function trimString(string, length) {
      if (string.length > length) {
        return string.substring(0, length) + '...';
      } else {
        return string;
      }
    },
    updateProductServiceDropDown: function updateProductServiceDropDown(is_dropdown_visible) {
      if (!is_dropdown_visible) {
        this.retrieveProductFeatures(1);
        this.retrieveServiceApplications(1);
      }
    },
    getProductTitleFromId: function getProductTitleFromId(product_id) {
      for (var i = 0; i < this.products.length; i++) {
        if (this.products[i].id === product_id) {
          return this.products[i].title;
        }
      }
      return '';
    },
    getServiceTitleFromId: function getServiceTitleFromId(service_id) {
      for (var i = 0; i < this.services.length; i++) {
        if (this.services[i].id === service_id) {
          return this.services[i].title;
        }
      }
      return '';
    },
    handleRelatedTicketSelection: function handleRelatedTicketSelection(val) {
      this.selectRelatedTicket(val.id);
    },
    selectRelatedTicket: function selectRelatedTicket(feature_id) {
      var _this = this;
      this.loading = true;
      // Retrieve Ticket and populate ticket modal
      var headers = {
        'Content-Type': 'application/json',
        'x-party-id': this.$store.getters.partyId,
        'x-user-id': this.$store.getters.id
      };
      _axios.default.get(process.env.VUE_APP_REST_API_ENDPOINT + 'ticket?extended=LEVEL0&entityId=' + feature_id, {
        headers: headers
      }).then(function (response) {
        _this.loading = false;
        _this.selectedChildTicket = response.data.data[0];
        _this.childTicketDetailsDialog = true;
      }).catch(function (error) {
        _this.loading = false;
        _this.$notify({
          title: 'Error',
          message: error.response.message,
          type: 'error',
          duration: 2000
        });
      });
    },
    selectChildTicket: function selectChildTicket(child_ticket) {
      this.selectedChildTicket = child_ticket;
      this.childTicketDetailsDialog = true;
    },
    computeChildTicketDetailTitle: function computeChildTicketDetailTitle() {
      if (this.selectedChildTicket.slug !== undefined) {
        return this.selectedChildTicket.slug + '-' + this.selectedChildTicket.title;
      }
      return this.selectedChildTicket.title;
    },
    updateTicketDescription: function updateTicketDescription(description) {
      this.ticket.description = description;
    },
    handleProductListChange: function handleProductListChange(value) {
      console.log(value); // Need API
    },
    handleServiceListChange: function handleServiceListChange(value) {
      console.log(value); // Need API
    },
    loadServices: function loadServices(page_number) {
      var _this2 = this;
      this.loading = true;
      this.$apollo.query({
        // Query
        query: window._gql(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)([" query Services($page: Page)\n        {\n        Services(page: $page)\n        {\n          pages\n          total\n          select{\n            id\n            title\n            description\n          }\n        }\n      }"]))),
        fetchPolicy: 'no-cache',
        variables: {
          'page': {
            'limit': 100,
            'start': page_number
          }
        }
      }).then(function (data) {
        _this2.services = data.data.Services.select;
        _this2.total_services_count = data.data.Services.total;
        _this2.loading = false;
      }).catch(function (error) {
        // Error
        _this2.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this2.loading = false;
      });
    },
    loadProducts: function loadProducts(page_number) {
      var _this3 = this;
      this.loading = true;
      this.$apollo.query({
        // Query
        query: window._gql(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)([" query Products($page: Page)\n        {\n        Products(page: $page)\n        {\n          pages\n          total\n          select{\n            id\n            title\n            description\n          }\n        }\n      }"]))),
        fetchPolicy: 'no-cache',
        variables: {
          'page': {
            'limit': 100,
            'start': page_number
          }
        }
      }).then(function (data) {
        _this3.products = data.data.Products.select;
        _this3.total_products_count = data.data.Products.total;
        _this3.loading = false;
      }).catch(function (error) {
        // Error
        _this3.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this3.loading = false;
      });
    },
    closeProductFeatureDialog: function closeProductFeatureDialog() {
      this.resetProductFeatureForm();
      this.addProductFeatureDialog = false;
    },
    reloadOfferings: function reloadOfferings() {
      this.resetLoadFlag();
      this.retrieveProductFeatures(this.active_page);
      this.retrieveServiceApplications(this.active_page);
    },
    retrieveServiceApplications: function retrieveServiceApplications(page_number) {
      var _this4 = this;
      this.loading = true;
      this.$apollo.query({
        // Query
        query: window._gql(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)([" query ServiceApplications($page: Page, $where: ServiceApplicationsCriteriaExpression)\n        {\n        ServiceApplications(page: $page, where: $where)\n        {\n          pages\n          total\n          select{\n            id\n            title(orderBy: ", ")\n            description\n            complexity\n            enabler\n            status\n            priority\n            additionalattributes\n            linkedServices(optional: true) {\n              service(optional: true) {\n                id\n                title\n              }\n            }\n          }\n        }\n      }"])), this.computeSortQuery()),
        fetchPolicy: 'no-cache',
        variables: {
          'page': {
            'limit': this.page_size,
            'start': page_number
          },
          'where': this.computeSearchQuery()
        }
      }).then(function (data) {
        _this4.service_applications = data.data.ServiceApplications.select;
        _this4.total_service_applications_count = data.data.ServiceApplications.total;
        _this4.active_page = page_number;
        _this4.loading = false;
        _this4.load.serviceapplications = true;
        _this4.loadInitialEntity();
      }).catch(function (error) {
        // Error
        _this4.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this4.loading = false;
      });
    },
    retrieveProductFeatures: function retrieveProductFeatures(page_number) {
      var _this5 = this;
      this.loading = true;
      this.$apollo.query({
        // Query
        query: window._gql(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2.default)([" query ProductFeatures($page: Page, $where: FeaturesCriteriaExpression, $offering_where: OfferingsCriteriaExpression, $product_where: ProductsCriteriaExpression)\n        {\n        ProductFeatures(page: $page, where: $where)\n        {\n          pages\n          total\n          select{\n            id\n            title(orderBy: ", ")\n            description\n            complexity\n            status\n            priority\n            enabler\n            additionalattributes\n            linkedOfferings(optional: true) {\n              offering(optional: true, where: $offering_where) {\n                id\n              }\n            }\n            linkedProducts(optional: true) {\n              product(optional: true, where: $product_where) {\n                id\n                title\n              }\n            }\n          }\n        }\n      }"])), this.computeSortQuery()),
        fetchPolicy: 'no-cache',
        variables: {
          'page': {
            'limit': this.page_size,
            'start': page_number
          },
          'where': this.computeSearchQuery(),
          'offering_where': this.computeOfferingCriteria(),
          'product_where': this.computeProductCriteria()
        }
      }).then(function (data) {
        _this5.features = data.data.ProductFeatures.select;
        _this5.total_features_count = data.data.ProductFeatures.total;
        _this5.active_page = page_number;
        _this5.loading = false;
        _this5.load.features = true;
        _this5.loadInitialEntity();
      }).catch(function (error) {
        // Error
        _this5.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this5.loading = false;
      });
    },
    computeOfferingCriteria: function computeOfferingCriteria() {
      var query = {};
      query.id = {
        'LIKE_': this.offeringId
      };
      return query;
    },
    computeProductCriteria: function computeProductCriteria() {
      var query = {};
      if (this.search_entities.filters.products.length > 0) {
        query.id = {
          'IN': this.search_entities.filters.products
        };
      }
      return query;
    },
    computeSearchQuery: function computeSearchQuery() {
      var query = {};
      if (this.search_entities.q !== '') {
        query.title = {
          'LIKE_': this.search_entities.q
        };
      }
      if (this.search_entities.filters.title !== '') {
        switch (this.search_entities.filters.title_operator) {
          case 'EXACT':
            query.title = {
              'EQ_': this.search_entities.filters.title
            };
            break;
          case 'LIKE':
            query.title = {
              'LIKE_': this.search_entities.filters.title
            };
            break;
          case 'EMPTY':
            query.title = {
              'IS_NULL': this.search_entities.filters.title
            };
            break;
          case 'NOT EMPTY':
            query.title = {
              'NOT_NULL': this.search_entities.filters.title
            };
            break;
          case 'STARTS':
            query.title = {
              'STARTS_': this.search_entities.filters.title
            };
            break;
          case 'ENDS':
            query.title = {
              'ENDS_': this.search_entities.filters.title
            };
            break;
          default:
            query.title = {
              'LIKE_': this.search_entities.filters.title
            };
            break;
        }
      }
      if (this.search_entities.filters.description !== '') {
        switch (this.search_entities.filters.description_operator) {
          case 'EXACT':
            query.description = {
              'EQ_': this.search_entities.filters.description
            };
            break;
          case 'LIKE':
            query.description = {
              'LIKE_': this.search_entities.filters.description
            };
            break;
          case 'EMPTY':
            query.description = {
              'IS_NULL': this.search_entities.filters.description
            };
            break;
          case 'NOT EMPTY':
            query.description = {
              'NOT_NULL': this.search_entities.filters.description
            };
            break;
          case 'STARTS':
            query.description = {
              'STARTS_': this.search_entities.filters.description
            };
            break;
          case 'ENDS':
            query.description = {
              'ENDS_': this.search_entities.filters.description
            };
            break;
          default:
            query.description = {
              'LIKE_': this.search_entities.filters.description
            };
            break;
        }
      }
      if (this.search_entities.filters.status.length > 0) {
        query.status = {
          'IN': this.search_entities.filters.status
        };
      }
      if (this.search_entities.filters.priority.length > 0) {
        query.priority = {
          'IN': this.search_entities.filters.priority
        };
      }
      return query;
    },
    computeSortQuery: function computeSortQuery() {
      if (this.search_entities.sort.title === 'a-z') {
        return 'ASC';
      } else {
        return 'DESC';
      }
    },
    loadPage: function loadPage(page_number) {
      this.active_page = page_number;
      this.reloadOfferings();
    },
    truncateDescription: function truncateDescription(description) {
      var length = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 50;
      return this._.truncate(description, {
        'length': length,
        'separator': ' '
      });
    },
    searchItem: function searchItem(search_string) {
      this.search_term = search_string;
    },
    selectEntity: function selectEntity(entity, index) {
      // retrieve corresponding ticket
      this.selectedEntity = entity;
      this.retrieveTicket(entity.id);
    },
    toggleSort: function toggleSort() {
      if (this.search_entities.sort.title === 'a-z') {
        this.search_entities.sort.title = 'z-a';
      } else {
        this.search_entities.sort.title = 'a-z';
      }
      this.retrieveProductFeatures(1);
      this.retrieveServiceApplications(1);
    },
    showFilterDialog: function showFilterDialog() {
      this.resetSearchFilter();
      this.filterDialog = true;
    },
    closeFilterDialog: function closeFilterDialog() {
      this.resetSearchFilter();
      this.filterDialog = false;
    },
    filterFeatureProcessList: function filterFeatureProcessList() {
      this.retrieveProductFeatures(1);
      this.retrieveServiceApplications(1);
      this.filterDialog = false;
    },
    resetSearchFilter: function resetSearchFilter() {
      this.search_entities.filters = {
        title: '',
        title_operator: '',
        description: '',
        description_operator: '',
        status: [],
        priority: [],
        products: [],
        services: []
      };
    },
    resetSearchSort: function resetSearchSort() {
      this.search_entities.sort = {
        title: 'a-z',
        status: 'inprogress-achieved',
        priority: 'high-low'
      };
    },
    collapseSidebar: function collapseSidebar() {
      this.isSidebarCollapse = true;
    },
    expandSidebar: function expandSidebar() {
      this.isSidebarCollapse = false;
    },
    removeSearchFilterTag: function removeSearchFilterTag(type) {
      var index = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      switch (type) {
        case 'title':
          this.search_entities.filters.title = '';
          this.search_entities.filters.title_operator = '';
          break;
        case 'description':
          this.search_entities.filters.description = '';
          this.search_entities.filters.description_operator = '';
          break;
        case 'status':
          this.search_entities.filters.status.splice(index, 1);
          break;
        case 'priority':
          this.search_entities.filters.priority.splice(index, 1);
          break;
        case 'products':
          this.search_entities.filters.products.splice(index, 1);
          break;
        case 'services':
          this.search_entities.filters.services.splice(index, 1);
          break;
      }
      this.retrieveProductFeatures(1);
      this.retrieveServiceApplications(1);
    },
    // Currently Not in use, since sorting is set to default.
    removeSearchSortTag: function removeSearchSortTag(type) {
      switch (type) {
        case 'title':
          this.search_entities.sort.title = 'a-z';
          break;
      }
    },
    retrieveTicket: function retrieveTicket(entity_id) {
      var _this6 = this;
      this.tab_loading = true;
      // Retrieve Ticket and populate ticket modal
      var headers = {
        'Content-Type': 'application/json',
        'x-party-id': this.$store.getters.partyId,
        'x-user-id': this.$store.getters.id
      };
      _axios.default.get(process.env.VUE_APP_REST_API_ENDPOINT + 'ticket?extended=LEVEL0&entityId=' + entity_id, {
        headers: headers
      }).then(function (response) {
        _this6.tab_loading = false;
        _this6.ticket = {};
        _this6.ticket = response.data.data[0];
        _this6.ticket.priority = (0, _utils.capitalize)(_this6.ticket.priority.toLowerCase());
        _this6.computeUserNameFromUserID(_this6.ticket.createdBy, 'createdBy');
        _this6.computeUserNameFromUserID(_this6.ticket.modifiedBy, 'modifiedBy');
        _this6.retrieveChildTickets();
        _this6.retrieveTicketAttachements();
      }).catch(function (error) {
        _this6.tab_loading = false;
        if (error.response.status === 400) {
          _this6.$notify({
            title: 'Error',
            message: error.response.data.data.description,
            type: 'error',
            duration: 2000
          });
        } else {
          _this6.$notify({
            title: 'Error',
            message: error.response.message,
            type: 'error',
            duration: 2000
          });
        }
      });
    },
    retrieveChildTickets: function retrieveChildTickets() {
      var _this7 = this;
      this.child_ticket_loading = true;
      // Retrieve Ticket and populate ticket modal
      var headers = {
        'Content-Type': 'application/json',
        'x-party-id': this.$store.getters.partyId,
        'x-user-id': this.$store.getters.id
      };
      _axios.default.get(process.env.VUE_APP_REST_API_ENDPOINT + 'ticket/' + this.ticket.id + '/link', {
        headers: headers
      }).then(function (response) {
        _this7.child_ticket_loading = false;
        _this7.child_tickets = [];
        _this7.child_tickets = response.data.data.tickets;
      }).catch(function (error) {
        _this7.child_ticket_loading = false;
        if (error.response.status === 400) {
          _this7.child_tickets = [];
        }
      });
    },
    retrieveTicketAttachements: function retrieveTicketAttachements() {
      var _this8 = this;
      this.attachment_loading = true;
      // Retrieve Ticket and populate ticket modal
      var headers = {
        'Content-Type': 'application/json',
        'x-party-id': this.$store.getters.partyId,
        'x-user-id': this.$store.getters.id,
        'x-entity-id': this.ticket.id
      };
      _axios.default.get(process.env.VUE_APP_REST_API_ENDPOINT + 'file?entityId=' + this.ticket.id, {
        headers: headers
      }).then(function (response) {
        _this8.attachment_loading = false;
        _this8.files = response.data.data;
      }).catch(function (error) {
        _this8.attachment_loading = false;
        if (error.response.status === 400) {
          _this8.files = [];
        }
      });
    },
    computeUserNameFromUserID: function computeUserNameFromUserID(user_id, type) {
      var _this9 = this;
      if (user_id !== undefined) {
        var headers = {
          'Content-Type': 'application/json',
          'x-party-id': this.$store.getters.partyId,
          'x-user-id': this.$store.getters.id
        };
        _axios.default.get(process.env.VUE_APP_REST_API_ENDPOINT + 'user/' + user_id, {
          headers: headers
        }).then(function (response) {
          if (type === 'createdBy') {
            _this9.ticket.createdBy = response.data.data.firstName + ' ' + response.data.data.lastName;
          }
          if (type === 'modifiedBy') {
            _this9.ticket.modifiedBy = response.data.data.firstName + ' ' + response.data.data.lastName;
          }
        }).catch(function (error) {
          if (error.response.status === 400) {
            _this9.$notify({
              title: 'Error',
              message: error.response.message,
              type: 'error',
              duration: 2000
            });
          }
        });
      }
    },
    computeStatusIcon: function computeStatusIcon(status) {
      switch (status) {
        case 'Abandoned':
        case 'Backlog':
        case 'Build Planning':
        case 'Feature Backend Build':
        case 'Feature Design':
        case 'Feature UI Build':
        case 'Integration Testing':
          return 'warning';
        case 'Release Ready':
        case 'UAT':
          return 'gradient-circle-success';
        default:
          return 'warning';
      }
    },
    computePriorityIcon: function computePriorityIcon(priority) {
      switch (priority) {
        case 'Low':
          return 'priority-1';
        case 'Medium':
          return 'priority-2';
        case 'High':
          return 'priority-3';
        case 'Highest':
          return 'priority-4';
        default:
          return 'priority-1';
      }
    },
    computePriorityStyle: function computePriorityStyle(priority) {
      switch (priority) {
        case 'Low':
          return 'color: #92b853; font-size: 1.5em;';
        case 'Medium':
          return 'color: #E6A23C; font-size: 1.5em;';
        case 'High':
          return 'color: #F56C6C; font-size: 1.5em;';
        case 'Highest':
          return 'color: #F56C6C; font-size: 1.5em;';
        default:
          return 'color: #92b853; font-size: 1.5em;';
      }
    },
    addChildTicket: function addChildTicket() {
      var child_ticket = {
        id: '',
        title: '',
        type: '',
        status: '',
        priority: ''
      };
      this.child_tickets.push(child_ticket);
    },
    addUpdateChildTicket: function addUpdateChildTicket(child_ticket) {
      var _this10 = this;
      if (child_ticket.id === '') {
        // Create
        this.child_ticket_loading = true;
        var headers = {
          'Content-Type': 'application/json'
          // 'Authorization': 'Bearer '+getJWTToken()
        };
        var create_body = {
          data: [{
            'entityId': this.ticket.id,
            'entityName': 'TICKET',
            'title': child_ticket.title,
            'type': 'FEATURE',
            'priority': child_ticket.priority,
            'status': child_ticket.status
          }],
          'header': {
            'userId': this.$store.getters.id,
            'partyId': this.$store.getters.partyId
          }
        };
        _axios.default.post(process.env.VUE_APP_REST_API_ENDPOINT + 'ticket', create_body, {
          headers: headers
        }).then(function (response) {
          _this10.child_ticket_loading = false;
          if (response.data.data.status === 'SUCCESS') {
            // Link Child ticket to parent
            _this10.linkChildTicketToParent(response.data.data.id, _this10.ticket.id);
            // this.retrieveChildTickets()
          }
        }).catch(function (error) {
          _this10.child_ticket_loading = false;
          console.log(error);
        });
      } else {
        // Update

        this.child_ticket_loading = true;
        var _headers = {
          'Content-Type': 'application/json'
        };
        var update_body = {
          'data': {
            'title': child_ticket.title,
            'priority': child_ticket.priority,
            'status': child_ticket.status
          },
          'header': {
            'userId': this.$store.getters.id,
            'partyId': this.$store.getters.partyId
          }
        };
        _axios.default.patch(process.env.VUE_APP_REST_API_ENDPOINT + 'ticket/' + child_ticket.id, update_body, {
          headers: _headers
        }).then(function (response) {
          _this10.child_ticket_loading = false;
          _this10.retrieveChildTickets();
        }).catch(function (error) {
          _this10.child_ticket_loading = false;
          console.log(error);
        });
      }
    },
    linkChildTicketToParent: function linkChildTicketToParent(child_ticket_id, parent_ticket_id) {
      var _this11 = this;
      this.child_ticket_loading = true;
      var headers = {
        'Content-Type': 'application/json'
        // 'Authorization': 'Bearer '+getJWTToken()
      };
      var create_body = {
        data: [{
          'ticketId': child_ticket_id,
          'relationship': 'childLink1'
        }],
        'header': {
          'userId': this.$store.getters.id,
          'partyId': this.$store.getters.partyId
        }
      };
      _axios.default.post(process.env.VUE_APP_REST_API_ENDPOINT + 'ticket/' + parent_ticket_id + '/link', create_body, {
        headers: headers
      }).then(function (response) {
        _this11.child_ticket_loading = false;
        if (response.data.data.status === 'SUCCESS') {
          _this11.retrieveChildTickets();
        }
      }).catch(function (error) {
        _this11.child_ticket_loading = false;
        console.log(error);
      });
    },
    deleteChildTicket: function deleteChildTicket(child_ticket_id, parent_ticket_id) {
      var _this12 = this;
      this.$confirm('This will permanently delete ticket. Continue?', 'Warning', {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(function () {
        _this12.child_ticket_loading = true;
        var headers = {
          'Content-Type': 'application/json'
          // 'Authorization': 'Bearer '+getJWTToken()
        };
        var create_body = {
          data: [{
            'ticketId': child_ticket_id,
            'relationship': 'childLink1'
          }],
          'header': {
            'userId': _this12.$store.getters.id,
            'partyId': _this12.$store.getters.partyId
          }
        };
        _axios.default.delete(process.env.VUE_APP_REST_API_ENDPOINT + 'ticket/' + parent_ticket_id + '/link', {
          headers: headers,
          data: create_body
        }).then(function (response) {
          _this12.child_ticket_loading = false;
          if (response.data.status === 'SUCCESS') {
            // DELETE START*/
            _this12.child_ticket_loading = true;
            var _headers2 = {
              'Content-Type': 'application/json'
              // 'Authorization': 'Bearer '+getJWTToken()
            };
            var create_body1 = {
              data: [{
                'id': child_ticket_id
              }],
              'header': {
                'userId': _this12.$store.getters.id,
                'partyId': _this12.$store.getters.partyId
              }
            };
            _axios.default.delete(process.env.VUE_APP_REST_API_ENDPOINT + 'ticket', {
              headers: _headers2,
              data: create_body1
            }).then(function (response1) {
              _this12.child_ticket_loading = false;
              if (response1.data.data.status === 'SUCCESS') {
                _this12.retrieveChildTickets();
              }
            }).catch(function (error) {
              _this12.child_ticket_loading = false;
              console.log(error);
            });
            // DELETE END
          }
        }).catch(function (error) {
          _this12.child_ticket_loading = false;
          console.log(error);
        });
      }).catch(function () {
        _this12.$message({
          type: 'info',
          message: 'Delete canceled'
        });
      });
    },
    computeHumanFriendlyDate: function computeHumanFriendlyDate(date) {
      return this.$moment(date, 'YYYY-MM-DD HH:mm:ss').format('ll');
    },
    updateEnabledStatus: function updateEnabledStatus(value) {
      if (this.selectedEntity.__typename === 'ProductFeature') {
        this.$apollo.mutate({
          // Query
          mutation: window._gql(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2.default)(["mutation updateProductFeature($productFeature: UpdateProductFeatureInput!)\n          {\n            updateProductFeature(input: $productFeature) {\n              id\n            }\n          }"]))),
          // Parameters
          variables: {
            'productFeature': {
              'id': this.selectedEntity.id,
              'enabler': value
            }
          }
        }).then(function (data) {
          console.log('updated');
        }).catch(function (error) {
          console.log(error);
        });
      } else if (this.selectedEntity.__typename === 'ServiceApplication') {
        this.$apollo.mutate({
          // Query
          mutation: window._gql(_templateObject6 || (_templateObject6 = (0, _taggedTemplateLiteral2.default)(["mutation updateServiceApplication($serviceApplication: UpdateServiceApplicationInput!)\n          {\n            updateServiceApplication(input: $serviceApplication) {\n              id\n            }\n          }"]))),
          // Parameters
          variables: {
            'serviceApplication': {
              'id': this.selectedEntity.id,
              'enabler': value
            }
          }
        }).then(function (data) {
          console.log('updated');
        }).catch(function (error) {
          console.log(error);
        });
      }
    },
    updateTicket: function updateTicket() {
      var _this13 = this;
      this.update_processing = true;
      var headers = {
        'Content-Type': 'application/json'
        // 'Authorization': 'Bearer '+getJWTToken()
      };
      var body = {
        data: [{
          'id': this.ticket.id,
          'title': this.ticket.title,
          'description': this.ticket.description,
          'type': this.ticket.type,
          'priority': this.ticket.priority,
          'status': this.ticket.status,
          'importance': this.ticket.importance,
          'approvalVia': this.ticket.approvalVia
        }],
        'header': {
          'userId': this.$store.getters.id,
          'partyId': this.$store.getters.partyId
        }
      };
      _axios.default.patch(process.env.VUE_APP_REST_API_ENDPOINT + 'ticket', body, {
        headers: headers
      }).then(function (response) {
        _this13.update_processing = false;
        _this13.$notify({
          title: 'Success',
          message: 'Updated',
          type: 'success',
          duration: 2000
        });
        // sync ticket and feature
        if (_this13.ticket.title !== _this13.selectedEntity.title) {
          _this13.updateFeatureToTicket();
        }
        /* if (response.data.data.status === 'SUCCESS') {
           this.selectTicket()
         }
         */
      }).catch(function (error) {
        _this13.update_processing = false;
        _this13.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
      });
    },
    deleteTicket: function deleteTicket() {
      var _this14 = this;
      this.$confirm('This will permanently delete ticket. Continue?', 'Warning', {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(function () {
        // DELETE START*/
        _this14.loading = true;
        var headers = {
          'Content-Type': 'application/json'
          // 'Authorization': 'Bearer '+getJWTToken()
        };
        var create_body = {
          data: [{
            'id': _this14.ticket.id
          }],
          'header': {
            'userId': _this14.$store.getters.id,
            'partyId': _this14.$store.getters.partyId
          }
        };
        _axios.default.delete(process.env.VUE_APP_REST_API_ENDPOINT + 'ticket', {
          headers: headers,
          data: create_body
        }).then(function (response) {
          _this14.loading = false;
          if (response.data.data.status === 'SUCCESS') {
            _this14.loading = true;
            _this14.$apollo.mutate({
              // Query
              mutation: window._gql(_templateObject7 || (_templateObject7 = (0, _taggedTemplateLiteral2.default)(["mutation deleteProductFeature($deleteProductFeature: DeleteProductFeatureInput!)\n                {\n                  deleteProductFeature(input: $deleteProductFeature) {\n                    id\n                  }\n                }"]))),
              // Parameters
              variables: {
                'deleteProductFeature': {
                  'id': _this14.selectedEntity.id
                }
              }
            }).then(function (data) {
              _this14.reloadOfferings();
              _this14.loading = false;
            }).catch(function (error) {
              // Error
              _this14.$notify({
                title: 'Error',
                message: error,
                type: 'error',
                duration: 2000
              });
              _this14.loading = false;
            });
          }
        }).catch(function (error) {
          _this14.loading = false;
          console.log(error);
        });
      }).catch(function () {
        _this14.$message({
          type: 'info',
          message: 'Delete canceled'
        });
      });
    },
    updateFeatureToTicket: function updateFeatureToTicket() {
      var _this15 = this;
      this.$apollo.mutate({
        // Query
        mutation: window._gql(_templateObject8 || (_templateObject8 = (0, _taggedTemplateLiteral2.default)(["mutation updateProductFeature($productFeature: UpdateProductFeatureInput!)\n        {\n          updateProductFeature(input: $productFeature) {\n            id\n          }\n        }"]))),
        // Parameters
        variables: {
          'productFeature': {
            'id': this.selectedEntity.id,
            'title': this.ticket.title,
            'description': this.ticket.description,
            'status': this.ticket.status,
            'priority': this.ticket.priority
          }
        }
      }).then(function (data) {
        _this15.reloadOfferings();
      }).catch(function (error) {
        // Error
        _this15.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
      });
    },
    computePriorityTagType: function computePriorityTagType(priority) {
      switch (priority) {
        case 'Low':
          return 'secondary';
        case 'Medium':
          return 'secondary';
        case 'High':
          return 'warning';
        case 'Highest':
          return 'danger';
      }
    },
    addSubEntity: function addSubEntity() {
      if (this.feature_form.parent_type === 'Product') {
        this.addFeature();
      } else if (this.feature_form.parent_type === 'Service') {
        this.addProcess();
      }
    },
    addProcess: function addProcess() {
      var _this16 = this;
      this.$refs.addFeatureform.validate(function (valid) {
        if (valid) {
          _this16.loading = true;
          _this16.$apollo.mutate({
            // Query
            mutation: window._gql(_templateObject9 || (_templateObject9 = (0, _taggedTemplateLiteral2.default)(["mutation createServiceApplication($serviceApplication: CreateServiceApplicationInput!)\n            {\n              createServiceApplication(input: $serviceApplication) {\n                id\n              }\n            }"]))),
            // Parameters
            variables: {
              'serviceApplication': {
                'serviceId': _this16.feature_form.serviceId,
                'title': _this16.feature_form.title,
                'description': _this16.feature_form.description,
                'status': _this16.feature_form.status,
                'enabler': _this16.feature_form.enabler,
                'priority': _this16.feature_form.priority,
                'relations': {
                  'type': null,
                  'id': null
                },
                'additionalattributes': {
                  'issueSource': _this16.feature_form.issue_source,
                  'pos_x': _this16.feature_form.posX,
                  'posX': _this16.feature_form.posX,
                  'posY': _this16.feature_form.posY,
                  'minimized': false
                }
              }
            }
          }).then(function (data) {
            _this16.feature_form.entityId = data.data.createServiceApplication.id;
            _this16.feature_form.entityName = 'ServiceApplication';
            _this16.createTicket(_this16.feature_form);

            // this.retrieveCustomerJourneyMap(true)
            _this16.loading = false;
          }).catch(function (error) {
            // Error
            _this16.$notify({
              title: 'Error',
              message: error,
              type: 'error',
              duration: 2000
            });
            _this16.loading = false;
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    addFeature: function addFeature() {
      var _this17 = this;
      this.$refs.addFeatureform.validate(function (valid) {
        if (valid) {
          _this17.loading = true;
          _this17.$apollo.mutate({
            // Query
            mutation: window._gql(_templateObject10 || (_templateObject10 = (0, _taggedTemplateLiteral2.default)(["mutation createProductFeature($feature: CreateProductFeatureInput!)\n            {\n              createProductFeature(input: $feature) {\n                id\n              }\n            }"]))),
            // Parameters
            variables: {
              'feature': {
                'productId': _this17.feature_form.productId,
                'offeringId': _this17.offeringId,
                'title': _this17.feature_form.title,
                'description': _this17.feature_form.description,
                'status': _this17.feature_form.status,
                'enabler': _this17.feature_form.enabler,
                'priority': _this17.feature_form.priority,
                'relations': {
                  'steps': [{
                    'id': _this17.feature_form.step_id
                  }]
                },
                'additionalattributes': {
                  'issueSource': _this17.feature_form.issue_source,
                  'posX': _this17.feature_form.posX,
                  'posY': _this17.feature_form.posY
                }
              }
            }
          }).then(function (data) {
            _this17.feature_form.entityId = data.data.createProductFeature.id;
            _this17.feature_form.entityName = 'ProductFeature';
            _this17.createTicket(_this17.feature_form);

            // this.retrieveCustomerJourneyMap(true)
            _this17.loading = false;
          }).catch(function (error) {
            // Error
            _this17.$notify({
              title: 'Error',
              message: error,
              type: 'error',
              duration: 2000
            });
            _this17.loading = false;
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    createTicket: function createTicket(ticket) {
      var _this18 = this;
      var headers = {
        'Content-Type': 'application/json'
        // 'Authorization': 'Bearer '+getJWTToken()
      };
      var body = {
        data: [{
          'entityId': ticket.entityId,
          'entityName': ticket.entityName,
          'title': ticket.title,
          'description': ticket.description,
          'type': 'EPIC',
          'priority': ticket.priority.toUpperCase(),
          'status': ticket.status,
          'importance': ticket.importance,
          'slug': this.generateUniqueSlug(ticket),
          'approvalVia': 'test'
        }],
        'header': {
          'userId': this.$store.getters.id,
          'partyId': this.$store.getters.partyId
        }
      };
      this.loading = true;
      _axios.default.post(process.env.VUE_APP_REST_API_ENDPOINT + 'ticket', body, {
        headers: headers
      }).then(function (response) {
        _this18.loading = false;
        if (response.data.data.status === 'SUCCESS') {
          _this18.closeProductFeatureDialog();
          _this18.reloadOfferings();
        }
      }).catch(function (error) {
        _this18.loading = false;
        console.log(error);
      });
    },
    generateUniqueSlug: function generateUniqueSlug(ticket) {
      // Temp Algo - To be updated to better algo similar to JIRA
      var entity = ticket.entityName.slice(0, 3).toUpperCase();
      var title = ticket.title.slice(0, 3).toUpperCase();
      var unique = (0, _utils.createUniqueString)().slice(0, 3).toUpperCase();
      return entity + '-' + title + '-' + unique;
    },
    resetProductFeatureForm: function resetProductFeatureForm() {
      this.feature_form = {
        parent_type: 'Product',
        key: '',
        title: '',
        description: '',
        status: 'Backlog',
        priority: 'Medium',
        enabler: false,
        importance: 'Medium',
        productName: '',
        productId: '',
        serviceId: '',
        offeringId: '',
        posX: 0,
        issue_source: 'prodiggi',
        step_id: '',
        entityId: '',
        entityName: ''
      };
    },
    addAction: function addAction(action) {},
    resetLoadFlag: function resetLoadFlag() {
      this.load = {
        features: false,
        serviceapplications: false
      };
    }
  }
};