"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _echarts = _interopRequireDefault(require("echarts"));
var _utils = require("@/utils");
require('echarts/theme/macarons'); // echarts theme
var _default = exports.default = {
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '300px'
    },
    licensing: {
      type: Number,
      default: 0
    },
    support: {
      type: Number,
      default: 0
    },
    project: {
      type: Number,
      default: 0
    }
  },
  data: function data() {
    return {
      chart: null
    };
  },
  watch: {
    'support': function support(newVal, oldVal) {
      // watch it
      this.initChart();
    },
    'licensing': function licensing(newVal, oldVal) {
      // watch it
      this.initChart();
    },
    'project': function project(newVal, oldVal) {
      // watch it
      this.initChart();
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.initChart();
    this.__resizeHandler = (0, _utils.debounce)(function () {
      if (_this.chart) {
        _this.chart.resize();
      }
    }, 100);
    window.addEventListener('resize', this.__resizeHandler);
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    window.removeEventListener('resize', this.__resizeHandler);
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart: function initChart() {
      this.chart = _echarts.default.init(this.$el, 'macarons');
      this.chart.setOption({
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
          left: 'center',
          bottom: '10',
          data: ['Support', 'Licensing', 'Project']
        },
        calculable: true,
        series: [{
          name: 'Annual Segment Value',
          type: 'pie',
          roseType: 'radius',
          radius: [15, 95],
          center: ['50%', '38%'],
          data: [{
            value: this.support,
            name: 'Support'
          }, {
            value: this.licensing,
            name: 'Licensing'
          }, {
            value: this.project,
            name: 'Project'
          }],
          animationEasing: 'cubicInOut',
          animationDuration: 2600
        }]
      });
    }
  }
};