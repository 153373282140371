"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isVisible,
      expression: "isVisible"
    }, {
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.close,
      expression: "close"
    }],
    ref: "popper",
    staticClass: "context-menu",
    attrs: {
      tabindex: "-1"
    },
    on: {
      "!contextmenu": function contextmenu($event) {
        $event.preventDefault();
      }
    }
  }, [_c("ul", [_vm._t("default", null, {
    contextData: _vm.contextData
  })], 2)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;