"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    staticClass: "dashboard-editor-container"
  }, [_c("el-row", {
    attrs: {
      gutter: 8
    }
  }, [_c("el-col", {
    staticStyle: {
      "padding-right": "8px",
      "margin-bottom": "30px"
    },
    attrs: {
      xs: {
        span: 24
      },
      sm: {
        span: 24
      },
      md: {
        span: 24
      },
      lg: {
        span: 24
      },
      xl: {
        span: 24
      }
    }
  }, [_c("invite-list", {
    attrs: {
      invites: _vm.invites,
      total: _vm.total
    },
    on: {
      new: _vm.newInvite,
      copy: _vm.copyInvite,
      resend: _vm.resendInvite,
      delete: _vm.deleteInvite,
      paginate: _vm.getInviteList
    }
  })], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Create Invite",
      visible: _vm.newInviteDialog,
      width: "40%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.newInviteDialog = $event;
      }
    }
  }, [_c("el-form", {
    attrs: {
      model: _vm.invite_form,
      "label-width": "150px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "First Name",
      prop: "first_name"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "First Name"
    },
    model: {
      value: _vm.invite_form.first_name,
      callback: function callback($$v) {
        _vm.$set(_vm.invite_form, "first_name", $$v);
      },
      expression: "invite_form.first_name"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Last Name",
      prop: "last_name"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "Last Name"
    },
    model: {
      value: _vm.invite_form.last_name,
      callback: function callback($$v) {
        _vm.$set(_vm.invite_form, "last_name", $$v);
      },
      expression: "invite_form.last_name"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Email",
      prop: "email"
    }
  }, [_c("el-input", {
    attrs: {
      type: "email",
      placeholder: "Email"
    },
    model: {
      value: _vm.invite_form.email,
      callback: function callback($$v) {
        _vm.$set(_vm.invite_form, "email", $$v);
      },
      expression: "invite_form.email"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Organisation",
      prop: "organisation"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "Organisation"
    },
    model: {
      value: _vm.invite_form.organisation,
      callback: function callback($$v) {
        _vm.$set(_vm.invite_form, "organisation", $$v);
      },
      expression: "invite_form.organisation"
    }
  })], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.newInviteDialog = false;
      }
    }
  }, [_vm._v("Cancel")]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.generateAndSendInvite($event);
      }
    }
  }, [_vm._v("Invite")])], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;