"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _refine_features = _interopRequireDefault(require("./components/FeatureManagment/refine_features"));
var _feature_details = _interopRequireDefault(require("./components/FeatureManagment/feature_details"));
var _pain_point_pleasers = _interopRequireDefault(require("./components/FeatureManagment/pain_point_pleasers"));
var _pain_point_pleasers_details = _interopRequireDefault(require("./components/FeatureManagment/pain_point_pleasers_details"));
var _progress = _interopRequireDefault(require("./components/FeatureManagment/progress"));
/* eslint-disable */
var _default = exports.default = {
  name: 'FeatureManagment',
  components: {
    RefineFeatureComponent: _refine_features.default,
    FeatureDetailsComponent: _feature_details.default,
    FeatureManagmentProgressComponent: _progress.default,
    FeatureManagmentPainPointPleasersComponent: _pain_point_pleasers.default,
    FeatureManagmentPainPointPleasersDetailsComponent: _pain_point_pleasers_details.default
  },
  data: function data() {
    return {
      loading: false,
      painPointsPleasersDetailsDialog: false,
      featureDetailsDialog: false,
      selectedPainPointPleaser: {},
      selectedFeature: {}
    };
  },
  mounted: function mounted() {},
  created: function created() {},
  methods: {
    onPainPointPleaserSelect: function onPainPointPleaserSelect(value) {
      this.selectedPainPointPleaser = value;
      this.painPointsPleasersDetailsDialog = true;
    },
    onFeatureSelect: function onFeatureSelect(value) {
      this.selectedFeature = value;
      this.featureDetailsDialog = true;
    }
  }
};