"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/taggedTemplateLiteral.js"));
require("core-js/modules/es6.number.constructor");
var _templateObject, _templateObject2;
var _default2 = exports.default = {
  name: 'RelationDetails',
  components: {},
  props: {
    journeyMap: {
      type: Object,
      required: true,
      default: function _default() {
        return {
          id: '',
          title: '',
          description: '',
          additionalattributes: {},
          linkedJourneyExperiences: [],
          linkedJourneyPhases: [],
          linkedJourneySteps: []
        };
      }
    },
    relation: {
      type: Object,
      required: true,
      default: function _default() {
        return {
          id: '',
          title: '',
          description: '',
          start_id: '',
          start_type: '',
          end_id: '',
          end_type: '',
          style: {},
          styleonhover: {},
          pos: [],
          waypoints: []
        };
      }
    },
    relationIndex: {
      type: Number,
      required: true,
      default: null
    },
    relationType: {
      type: String,
      default: 'Journey'
    }
  },
  data: function data() {
    return {
      loading: false
    };
  },
  computed: {},
  watch: {},
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    computeEntityTitle: function computeEntityTitle(type, id) {
      switch (type) {
        case 'Phase':
          return this.computePhaseTitleFromId(id);
        case 'Step':
          return this.computeStepTitleFromId(id);
        case 'Experience':
          return this.computeExperienceTitleFromId(id);
      }
    },
    computePhaseTitleFromId: function computePhaseTitleFromId(id) {
      for (var i = 0; i < this.journeyMap.linkedJourneyPhases.length; i++) {
        if (this.journeyMap.linkedJourneyPhases[i].journeyPhase.id === id) {
          return this.journeyMap.linkedJourneyPhases[i].journeyPhase.title;
        }
      }
    },
    computeStepTitleFromId: function computeStepTitleFromId(id) {
      for (var i = 0; i < this.journeyMap.linkedJourneySteps.length; i++) {
        if (this.journeyMap.linkedJourneySteps[i].journeyStep.id === id) {
          return this.journeyMap.linkedJourneySteps[i].journeyStep.title;
        }
      }
    },
    computeExperienceTitleFromId: function computeExperienceTitleFromId(id) {
      for (var i = 0; i < this.journeyMap.linkedJourneyExperiences.length; i++) {
        if (this.journeyMap.linkedJourneyExperiences[i].journeyExperience.id === id) {
          return this.journeyMap.linkedJourneyExperiences[i].journeyExperience.title;
        }
      }
    },
    updateSpecificRelations: function updateSpecificRelations() {
      var _this = this;
      var additionalattributes = this.journeyMap.additionalattributes;
      this.loading = true;
      this.$apollo.mutate({
        // Query
        mutation: window._gql(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["mutation updateJourneyMap($journeymap: UpdateJourneyMapInput!)\n        {\n          updateJourneyMap(input: $journeymap) {\n            id\n          }\n        }"]))),
        // Parameters
        variables: {
          'journeymap': {
            'id': this.journeyMap.id,
            'additionalattributes': additionalattributes
          }
        }
      }).then(function (data) {
        _this.loading = false;
      }).catch(function (error) {
        // Error
        _this.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this.loading = false;
      });
    },
    updateRelation: function updateRelation() {
      var _this2 = this;
      var additionalattributes = this.journeyMap.additionalattributes;
      if (this.relationType === 'Journey') {
        additionalattributes.relations[this.relationIndex] = this.relation;
      } else {
        additionalattributes.step_relations[this.relationIndex] = this.relation;
      }
      this.loading = true;
      this.$apollo.mutate({
        // Query
        mutation: window._gql(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["mutation updateJourneyMap($journeymap: UpdateJourneyMapInput!)\n        {\n          updateJourneyMap(input: $journeymap) {\n            id\n          }\n        }"]))),
        // Parameters
        variables: {
          'journeymap': {
            'id': this.journeyMap.id,
            'additionalattributes': additionalattributes
          }
        }
      }).then(function (data) {
        _this2.loading = false;
        _this2.$emit('close');
      }).catch(function (error) {
        // Error
        _this2.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this2.loading = false;
      });
    },
    closeUI: function closeUI() {
      this.$emit('close');
    }
  }
};