"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _default = exports.default = {
  name: 'ProcessAvatarComponent',
  props: {
    x: {
      type: Number,
      required: true,
      default: 0
    },
    y: {
      type: Number,
      required: true,
      default: 0
    },
    owner: {
      type: String,
      required: true,
      default: ''
    },
    myProcess: {
      type: Boolean,
      required: true,
      default: false
    },
    process: {
      type: String,
      default: ''
    }
  },
  computed: {
    bgFillColor: function bgFillColor() {
      if (this.myProcess) {
        return 'yellow';
      } else {
        return 'silver';
      }
    },
    bgStrokeColor: function bgStrokeColor() {
      switch (this.process) {
        case 'linear':
          return 'gray';
        case 'ongoing':
          return '#98FCC9';
        case 'urgent':
          return 'red';
        default:
          return 'gray';
      }
    },
    avatarFillColor: function avatarFillColor() {
      if (this.myProcess) {
        return 'blue';
      } else {
        return 'silver';
      }
    },
    avatarStrokeColor: function avatarStrokeColor() {
      if (this.myProcess) {
        return 'blue';
      } else {
        return 'white';
      }
    },
    avatarTextColor: function avatarTextColor() {
      if (this.myProcess) {
        return 'blue';
      } else {
        return 'white';
      }
    }
  },
  methods: {
    describeArc: function describeArc(x, y, radius, startAngle, endAngle) {
      var start = this.polarToCartesian(x, y, radius, endAngle);
      var end = this.polarToCartesian(x, y, radius, startAngle);
      var largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';
      var d = ['M', start.x, start.y, 'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y].join(' ');
      return d;
    },
    polarToCartesian: function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
      var angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;
      return {
        x: centerX + radius * Math.cos(angleInRadians),
        y: centerY + radius * Math.sin(angleInRadians)
      };
    }
    /*
    getInitials() {
      var names = this.owner.split(' ')
      var initials = names[0].substring(0, 1).toUpperCase()
      if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase()
      }
      return initials
    }*/
  }
};