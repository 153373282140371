"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _taggedTemplateLiteral2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/taggedTemplateLiteral.js"));
require("core-js/modules/es6.array.sort");
var _objectSpread2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _vuex = require("vuex");
var _resolution = require("@/utils/resolution");
var _Risks = _interopRequireDefault(require("@/components/Risks"));
var _Action = _interopRequireDefault(require("@/components/Action"));
var _templateObject, _templateObject2;
var _default2 = exports.default = {
  name: 'RefineFeatures',
  components: {
    RiskDetails: _Risks.default,
    ActionComponent: _Action.default
  },
  props: {
    customer: {
      type: Object,
      required: true,
      default: function _default() {
        return {
          id: '',
          title: '',
          description: '',
          tiers: '',
          rationale: '',
          priority: '',
          persona: '',
          additionalattributes: {},
          linkedJourneyMaps: []
        };
      }
    },
    offering: {
      type: Object,
      required: true,
      default: function _default() {
        return {
          id: '',
          title: '',
          description: '',
          additionalattributes: {},
          linkedProducts: [],
          linkedServices: []
        };
      }
    },
    journeyMap: {
      type: Object,
      required: true,
      default: function _default() {
        return {
          id: '',
          title: '',
          description: '',
          additionalattributes: {},
          linkedJourneyExperiences: [],
          linkedJourneyPhases: [],
          linkedJourneySteps: []
        };
      }
    }
  },
  data: function data() {
    var validateTitle = function validateTitle(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter title'));
      } else {
        callback();
      }
    };
    var validateDescription = function validateDescription(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter description'));
      } else {
        callback();
      }
    };
    var validatePriority = function validatePriority(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter priority'));
      } else {
        callback();
      }
    };
    var validateProduct = function validateProduct(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter a product'));
      } else {
        callback();
      }
    };
    var validateStep = function validateStep(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter a step'));
      } else {
        callback();
      }
    };
    return {
      featureRules: {
        title: [{
          required: true,
          trigger: 'blur',
          validator: validateTitle
        }],
        description: [{
          required: true,
          trigger: 'blur',
          validator: validateDescription
        }],
        priority: [{
          required: true,
          trigger: 'blur',
          validator: validatePriority
        }],
        stepId: [{
          required: true,
          trigger: 'blur',
          validator: validateStep
        }],
        productId: [{
          required: true,
          trigger: 'blur',
          validator: validateProduct
        }]
      },
      createOrphan: false,
      createEnabler: false,
      loading: false,
      fileLoading: false,
      isSidebarCollapse: false,
      filterDialog: false,
      displayRiskDialog: false,
      addFeatureDialog: false,
      total_count: 0,
      page_size: 20,
      active_page: 1,
      feature_form: {
        title: '',
        description: '',
        priority: 'Low',
        status: 'Backlog',
        productId: '',
        stepId: ''
      },
      search_features: {
        q: '',
        filters: {
          title: '',
          title_operator: '',
          description: '',
          description_operator: '',
          status: [],
          priority: []
        },
        sort: {
          title: 'a-z'
        }
      },
      features: [],
      products: [],
      offerings: [],
      selectedFeature: {
        title: '',
        description: '',
        status: '',
        priority: ''
      },
      selectedFeatureIndex: 0,
      edit: {
        feature: false,
        discussion: {
          mode: false,
          index: 0
        }
      },
      selectedEntity: {
        id: '',
        name: '',
        title: '',
        risks: []
      },
      comment: '',
      linkedTickets: [{
        linkedTicketID: 'DPM-432',
        linkedTicketName: 'Value Stream Visualisation',
        linkedTicketLinkType: 'Blocked by'
      }, {
        linkedTicketID: 'DPM-456',
        linkedTicketName: 'Automated roadmapping',
        linkedTicketLinkType: 'Dependancy On'
      }],
      external_tickets: [{
        id: '1234',
        type: 'Task',
        title: 'Ticket Title',
        status: 'Medium'
      }],
      feature_stories: [{
        title: 'Story 1',
        description: 'Story Description',
        status: 'Assigned',
        release: 'V 2.1',
        priority: 'High'
      }, {
        title: 'Story 2',
        description: 'Story Description',
        status: 'Released',
        release: 'V 2.0',
        priority: 'Low'
      }, {
        title: 'Story 3',
        description: 'Story Description',
        status: 'Assigned',
        release: 'V 2.2',
        priority: 'Medium'
      }, {
        title: 'Story 4',
        description: 'Story Description',
        status: 'Assigned',
        release: 'V 3.1',
        priority: 'Low'
      }],
      discussions: [{
        id: 'c32d8b45-92fe-44f6-8b61',
        author: 'John Doe',
        image_url: 'https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif',
        message: 'Lorem ipsum ador elemena Lorem ipsum ador elemena Lorem ipsum ador elemena Lorem ipsum ador elemena Lorem ipsum ador elemena',
        date_time: '2021-07-20 12:12:12',
        files: [{
          id: 1,
          name: 'image.png',
          type: 'PNG',
          url: 'http://api-dev.prodiggi.com/api/file/image.png?ver…nId=Lzp83xRRqtJblldC1J_0sGRt_.9kLugs&size=150X150'
        }, {
          id: 2,
          name: 'document.pdf',
          type: 'PDF',
          url: 'http://api-dev.prodiggi.com/api/file/image.png?ver…nId=Lzp83xRRqtJblldC1J_0sGRt_.9kLugs&size=150X150'
        }, {
          id: 3,
          name: 'screenshot.jpg',
          type: 'JPG',
          url: 'http://api-dev.prodiggi.com/api/file/image.png?ver…nId=Lzp83xRRqtJblldC1J_0sGRt_.9kLugs&size=150X150'
        }]
      }, {
        id: 'c32d8b45-92fe-44f6-8b62',
        author: 'John Doe',
        image_url: 'https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif',
        message: 'Lorem ipsum ador elemena Lorem ipsum ador elemena Lorem ipsum ador elemena Lorem ipsum ador elemena Lorem ipsum ador elemena',
        date_time: '2021-07-20 12:12:12',
        files: [{
          id: 1,
          name: 'image.png',
          type: 'PNG',
          url: 'http://api-dev.prodiggi.com/api/file/image.png?ver…nId=Lzp83xRRqtJblldC1J_0sGRt_.9kLugs&size=150X150'
        }, {
          id: 2,
          name: 'document.pdf',
          type: 'PDF',
          url: 'http://api-dev.prodiggi.com/api/file/image.png?ver…nId=Lzp83xRRqtJblldC1J_0sGRt_.9kLugs&size=150X150'
        }, {
          id: 3,
          name: 'screenshot.jpg',
          type: 'JPG',
          url: 'http://api-dev.prodiggi.com/api/file/image.png?ver…nId=Lzp83xRRqtJblldC1J_0sGRt_.9kLugs&size=150X150'
        }]
      }, {
        id: 'c32d8b45-92fe-44f6-8b62',
        author: 'Sastha K  L',
        image_url: 'https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif',
        message: 'Lorem ipsum ador elemena Lorem ipsum ador elemena Lorem ipsum ador elemena Lorem ipsum ador elemena Lorem ipsum ador elemena',
        date_time: '2021-07-20 12:12:12',
        files: [{
          id: 1,
          name: 'image.png',
          type: 'PNG',
          url: 'http://api-dev.prodiggi.com/api/file/image.png?ver…nId=Lzp83xRRqtJblldC1J_0sGRt_.9kLugs&size=150X150'
        }, {
          id: 2,
          name: 'document.pdf',
          type: 'PDF',
          url: 'http://api-dev.prodiggi.com/api/file/image.png?ver…nId=Lzp83xRRqtJblldC1J_0sGRt_.9kLugs&size=150X150'
        }, {
          id: 3,
          name: 'screenshot.jpg',
          type: 'JPG',
          url: 'http://api-dev.prodiggi.com/api/file/image.png?ver…nId=Lzp83xRRqtJblldC1J_0sGRt_.9kLugs&size=150X150'
        }]
      }, {
        id: 'c32d8b45-92fe-44f6-8b63',
        author: 'John Doe',
        image_url: 'https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif',
        message: 'Lorem ipsum ador elemena Lorem ipsum ador elemena Lorem ipsum ador elemena Lorem ipsum ador elemena Lorem ipsum ador elemena',
        date_time: '2021-07-20 12:12:12',
        files: [{
          id: 1,
          name: 'image.png',
          type: 'PNG',
          url: 'http://api-dev.prodiggi.com/api/file/image.png?ver…nId=Lzp83xRRqtJblldC1J_0sGRt_.9kLugs&size=150X150'
        }, {
          id: 2,
          name: 'document.pdf',
          type: 'PDF',
          url: 'http://api-dev.prodiggi.com/api/file/image.png?ver…nId=Lzp83xRRqtJblldC1J_0sGRt_.9kLugs&size=150X150'
        }, {
          id: 3,
          name: 'screenshot.jpg',
          type: 'JPG',
          url: 'http://api-dev.prodiggi.com/api/file/image.png?ver…nId=Lzp83xRRqtJblldC1J_0sGRt_.9kLugs&size=150X150'
        }]
      }],
      history: [{
        id: 'c32d8b45-92fe-44f6-8b61',
        date_time: '2021-07-20 12:12:12',
        artefact: 'JourneyMap',
        event: 'Andrew mentioned you in an Action'
      }],
      statuses: [{
        label: 'Abandoned',
        value: 'Abandoned'
      }, {
        label: 'Backlog',
        value: 'Backlog'
      }, {
        label: 'Build Planning',
        value: 'Build Planning'
      }, {
        label: 'Feature Backend Build',
        value: 'Feature Backend Build'
      }, {
        label: 'Feature Design',
        value: 'Feature Design'
      }, {
        label: 'Feature UI Build',
        value: 'Feature UI Build'
      }, {
        label: 'Integration Testing',
        value: 'Integration Testing'
      }, {
        label: 'Release Ready',
        value: 'Release Ready'
      }, {
        label: 'UAT',
        value: 'UAT'
      }]
    };
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['modules'])), {}, {
    filteredFeatures: function filteredFeatures() {
      var self = this;
      return this.features.filter(function (feature) {
        return feature.title.toLowerCase().indexOf(self.search_features.q.toLowerCase()) > -1;
      });
    },
    computeFeatureListStyle: function computeFeatureListStyle() {
      var ratio = (0, _resolution.screenRatio)();
      if (ratio === 2) {
        return 'height:1450px;overflow-y:auto;';
      }
      return 'height:600px;overflow-y:auto;';
    }
  }),
  watch: {},
  mounted: function mounted() {
    this.retrieveFeatures(1);
  },
  methods: {
    showFilterDialog: function showFilterDialog() {
      this.resetSearchFilter();
      this.filterDialog = true;
    },
    collapseSidebar: function collapseSidebar() {
      this.isSidebarCollapse = true;
      this.$store.dispatch('app/closeSideBar', {
        withoutAnimation: false
      });
    },
    expandSidebar: function expandSidebar() {
      this.isSidebarCollapse = false;
    },
    toggleSort: function toggleSort() {
      if (this.search_features.sort.title === 'a-z') {
        this.search_features.sort.title = 'z-a';
      } else {
        this.search_features.sort.title = 'a-z';
      }
      this.retrieveFeatures(1);
    },
    selectFeature: function selectFeature(feature, index) {
      this.selectedFeatureIndex = index;
      this.selectedFeature = feature;
    },
    loadPage: function loadPage(page_number) {
      this.retrieveFeatures(page_number);
    },
    retrieveFeatures: function retrieveFeatures(page_number) {
      var _this = this;
      this.loading = true;
      this.$apollo.query({
        // Query
        query: window._gql(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["query ProductFeatures($page: Page, $where: FeaturesCriteriaExpression)\n          {\n          ProductFeatures(page: $page, where: $where)\n          {\n            pages\n            total\n            select{\n              id\n              title(orderBy: ", ")\n              description\n              status\n              priority\n              relations\n              additionalattributes\n              linkedRisks(optional : true) {\n                risk(optional : true) {\n                  id\n                  title\n                  description\n                  severity\n                  occuranceprobability\n                  impactlevel\n                  mitigationplan\n                  additionalattributes\n                }\n              }\n            }\n          }\n        }"])), this.computeSortQuery()),
        fetchPolicy: 'no-cache',
        variables: {
          'page': {
            'limit': this.page_size,
            'start': 1
          },
          'where': this.computeSearchQuery()
        }
      }).then(function (data) {
        _this.total_count = data.data.ProductFeatures.total;
        _this.active_page = page_number;
        _this.features = [];
        _this.features = data.data.ProductFeatures.select;
        _this.loading = false;
      }).catch(function (error) {
        // Error
        _this.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this.loading = false;
      });
    },
    computeSearchQuery: function computeSearchQuery() {
      var query = {};
      if (this.search_features.q !== '') {
        query.title = {
          'LIKE_': this.search_features.q
        };
      }
      if (this.search_features.filters.title !== '') {
        switch (this.search_features.filters.title_operator) {
          case 'EXACT':
            query.title = {
              'EQ_': this.search_features.filters.title
            };
            break;
          case 'LIKE':
            query.title = {
              'LIKE_': this.search_features.filters.title
            };
            break;
          case 'EMPTY':
            query.title = {
              'IS_NULL': this.search_features.filters.title
            };
            break;
          case 'NOT EMPTY':
            query.title = {
              'NOT_NULL': this.search_features.filters.title
            };
            break;
          case 'STARTS':
            query.title = {
              'STARTS_': this.search_features.filters.title
            };
            break;
          case 'ENDS':
            query.title = {
              'ENDS_': this.search_features.filters.title
            };
            break;
          default:
            query.title = {
              'LIKE_': this.search_features.filters.title
            };
            break;
        }
      }
      if (this.search_features.filters.description !== '') {
        switch (this.search_features.filters.description_operator) {
          case 'EXACT':
            query.description = {
              'EQ_': this.search_features.filters.description
            };
            break;
          case 'LIKE':
            query.description = {
              'LIKE_': this.search_features.filters.description
            };
            break;
          case 'EMPTY':
            query.description = {
              'IS_NULL': this.search_features.filters.description
            };
            break;
          case 'NOT EMPTY':
            query.description = {
              'NOT_NULL': this.search_features.filters.description
            };
            break;
          case 'STARTS':
            query.description = {
              'STARTS_': this.search_features.filters.description
            };
            break;
          case 'ENDS':
            query.description = {
              'ENDS_': this.search_features.filters.description
            };
            break;
          default:
            query.description = {
              'LIKE_': this.search_features.filters.description
            };
            break;
        }
      }
      if (this.search_features.filters.status.length > 0) {
        query.status = {
          'IN': this.search_features.filters.status
        };
      }
      if (this.search_features.filters.priority.length > 0) {
        query.priority = {
          'IN': this.search_features.filters.priority
        };
      }
      return query;
    },
    computeSortQuery: function computeSortQuery() {
      if (this.search_features.sort.title === 'a-z') {
        return 'ASC';
      } else {
        return 'DESC';
      }
    },
    addFeature: function addFeature() {
      var _this2 = this;
      this.$refs.feature_form.validate(function (valid) {
        if (valid) {
          // this.loading = true
          _this2.$apollo.mutate({
            // Query
            mutation: window._gql(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["mutation createProductFeature($productFeature: CreateProductFeatureInput!)\n            {\n              createProductFeature(input: $productFeature) {\n                id\n                title\n                description\n                status\n                priority\n                relations\n                additionalattributes\n                linkedRisks(optional : true) {\n                  risk(optional : true) {\n                    id\n                    title\n                    description\n                    severity\n                    occuranceprobability\n                    impactlevel\n                    mitigationplan\n                    additionalattributes\n                  }\n                }\n              }\n            }"]))),
            // Parameters
            variables: {
              'productFeature': {
                'title': _this2.feature_form.title,
                'description': _this2.feature_form.description,
                'priority': _this2.feature_form.priority,
                'status': _this2.feature_form.status,
                'productId': _this2.feature_form.productId,
                'offeringId': _this2.offering.id,
                'relations': {
                  'steps': [{
                    'id': _this2.feature_form.stepId
                  }]
                },
                'additionalattributes': {
                  'issueSource': 'prodiggi',
                  'posX': 0,
                  // To be updated
                  'posY': 0 // To be updated
                }
              }
            }
          }).then(function (data) {
            _this2.$notify({
              title: 'Success',
              message: 'Created Successfully',
              type: 'success',
              duration: 2000
            });
            _this2.resetFeatureForm();
            var feature = data.data.createProductFeature;
            _this2.features.push(feature);
            _this2.selectedFeature(feature, _this2.features.length);
          }).catch(function (error) {
            // Error
            _this2.$notify({
              title: 'Error',
              message: error,
              type: 'error',
              duration: 2000
            });
          });
          _this2.addFeatureDialog = false;
        } else {
          return false;
        }
      });
    },
    openCreateFeatureDialog: function openCreateFeatureDialog() {
      this.resetFeatureForm();
      this.addFeatureDialog = true;
    },
    truncateDescription: function truncateDescription(description, length) {
      return this._.truncate(description, {
        'length': length,
        'separator': ' '
      });
    },
    closeFilterDialog: function closeFilterDialog() {
      this.resetSearchFilter();
      this.filterDialog = false;
    },
    filterFeatureList: function filterFeatureList() {
      this.retrieveFeatures(1);
      this.filterDialog = false;
    },
    addAction: function addAction(action) {
      this.actions.push(action);
    },
    removeSearchFilterTag: function removeSearchFilterTag(type) {
      var index = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      switch (type) {
        case 'title':
          this.search_features.filters.title = '';
          this.search_features.filters.title_operator = '';
          break;
        case 'description':
          this.search_features.filters.description = '';
          this.search_features.filters.description_operator = '';
          break;
        case 'status':
          this.search_features.filters.status.splice(index, 1);
          break;
        case 'priority':
          this.search_features.filters.priority.splice(index, 1);
          break;
      }
      this.retrieveFeatures(1);
    },
    removeSearchSortTag: function removeSearchSortTag(type) {
      switch (type) {
        case 'title':
          this.search_features.sort.title = 'a-z';
          break;
      }
    },
    resetSearchFilter: function resetSearchFilter() {
      this.search_features.filters = {
        title: '',
        title_operator: '',
        description: '',
        description_operator: '',
        status: [],
        priority: []
      };
    },
    resetSearchSort: function resetSearchSort() {
      this.search_features.sort = {
        title: 'a-z',
        status: 'inprogress-achieved',
        priority: 'high-low'
      };
    },
    resetFeatureForm: function resetFeatureForm() {
      this.feature_form = {
        title: '',
        description: '',
        priority: 'Low',
        status: 'Backlog',
        productId: '',
        stepId: ''
      };
    },
    hasPermissionForModule: function hasPermissionForModule(entity) {
      return this.modules.includes(entity);
    },
    openRiskDialog: function openRiskDialog(entity, entity_name) {
      this.selectedEntity.id = entity.id;
      this.selectedEntity.name = entity_name;
      this.selectedEntity.title = entity.title;
      this.selectedEntity.risks = entity.linkedRisks;
      this.displayRiskDialog = true;
    },
    deleteEntityRisk: function deleteEntityRisk(index) {
      this.selectedEntity.risks.splice(index, 1);
    },
    computeHumanFriendlyDateDifference: function computeHumanFriendlyDateDifference(date) {
      var today = this.$moment();
      return this.$moment.tz(date, 'Australia/Sydney').from(today);
    },
    computeHumanFriendlyDate: function computeHumanFriendlyDate(date) {
      return this.$moment(date, 'YYYY-MM-DD HH:mm:ss').format('ll');
    },
    checkIfUserIsAuthor: function checkIfUserIsAuthor(author) {
      var username = this.$store.getters.firstName + ' ' + this.$store.getters.lastName;
      if (username === author) {
        return true;
      }
    },
    computeMessengerAvatar: function computeMessengerAvatar(image_url) {
      return 'background-image: url(' + image_url + ')';
    },
    deleteDiscussion: function deleteDiscussion(discussion, index) {
      var _this3 = this;
      this.$confirm('This will permanently delete the comment. Continue?', 'Warning', {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(function () {
        _this3.discussions.splice(index, 1);
      }).catch(function () {
        _this3.$message({
          type: 'info',
          message: 'Delete canceled'
        });
      });
    },
    editDiscussion: function editDiscussion(discussion, index) {
      this.edit.discussion.mode = true;
      this.edit.discussion.index = index;
    },
    updateDiscussion: function updateDiscussion(discussion, index) {
      this.edit.discussion.mode = false;
      this.edit.discussion.index = 0;
    },
    computeIcon: function computeIcon(type) {
      switch (type) {
        case 'PNG':
        case 'JPG':
          return 'el-icon-picture-outline';
        default:
          return 'el-icon-document';
      }
    },
    addComment: function addComment() {
      this.discussions.push({
        id: '2',
        author: this.$store.getters.firstName + ' ' + this.$store.getters.lastName,
        image_url: 'https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif',
        message: this.comment,
        date_time: '2021-07-20 12:12:12'
      });
      this.comment = '';
    },
    attachFiles: function attachFiles() {
      this.$refs.fileInput.click();
    }
  }
};