"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("g", {
    attrs: {
      refs: "activity-container"
    }
  }, [_c("g", {
    attrs: {
      refs: "activity"
    },
    on: {
      mouseover: function mouseover($event) {
        $event.preventDefault();
        return _vm.activityMouseOver(_vm.index, _vm.subIndex);
      },
      mouseleave: function mouseleave($event) {
        $event.preventDefault();
        return _vm.activityMouseLeave(_vm.index, _vm.subIndex);
      }
    }
  }, [_c("rect", {
    directives: [{
      name: "dragged",
      rawName: "v-dragged",
      value: _vm.startActivityMove,
      expression: "startActivityMove"
    }],
    attrs: {
      id: _vm.generateActivityID(_vm.index, _vm.subIndex),
      x: 315 + _vm.activity.x,
      y: 110 + _vm.activity.y,
      rx: "10",
      ry: "10",
      width: _vm.width,
      height: _vm.height,
      fill: _vm.bgColor,
      stroke: _vm.bgColor,
      "fill-opacity": "0.8",
      "stroke-opacity": "1",
      "stroke-width": "2"
    },
    on: {
      mouseover: function mouseover($event) {
        $event.preventDefault();
        return _vm.activityMouseOver(_vm.index, _vm.subIndex);
      },
      mouseleave: function mouseleave($event) {
        $event.preventDefault();
        return _vm.activityMouseLeave(_vm.index, _vm.subIndex);
      },
      click: function click($event) {
        $event.preventDefault();
        return _vm.selectActivity(_vm.activity, _vm.index, _vm.subIndex);
      }
    }
  }), _vm._v(" "), _c("text", {
    attrs: {
      x: 320 + _vm.activity.x,
      y: 130 + _vm.activity.y,
      "font-size": "0.9em",
      fill: "white",
      "font-weight": "normal",
      "text-anchor": "start"
    }
  }, [_vm._v(_vm._s(_vm.subIndex + 1) + ". " + _vm._s(_vm.activity.task))]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "progress"
    }
  }, [_c("g", {
    attrs: {
      refs: "progress_percentage"
    }
  }, [_c("text", {
    staticStyle: {
      fill: "#EEEEEE"
    },
    attrs: {
      x: 500 + _vm.activity.x,
      y: 130 + _vm.activity.y,
      fill: "black",
      "text-anchor": "end",
      "font-size": "1.2em",
      "font-weight": "lighter"
    }
  }, [_vm._v("\n          " + _vm._s(_vm.activityProgress) + "\n        ")])]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "progress_report"
    },
    on: {
      click: function click($event) {
        $event.preventDefault();
        return _vm.openActivityProgressDetailDialog(_vm.index, _vm.subIndex);
      }
    }
  }, [_c("foreignObject", {
    staticStyle: {
      color: "#EEEEEE"
    },
    attrs: {
      x: 482 + _vm.activity.x,
      y: 115 + _vm.activity.y,
      width: 60,
      height: 60
    }
  }, [_c("div", {
    attrs: {
      xmlns: "http://www.w3.org/1999/xhtml"
    }
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "chart-1"
    }
  })], 1)])])]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "createGoalIcon"
    },
    on: {
      click: function click($event) {
        $event.preventDefault();
        return _vm.createGoal(_vm.index, _vm.subIndex);
      }
    }
  }, [_c("foreignObject", {
    staticStyle: {
      color: "#13ce66"
    },
    attrs: {
      x: 521 + _vm.activity.x,
      y: 117 + _vm.activity.y,
      width: 15,
      height: 15
    }
  }, [_c("div", {
    attrs: {
      xmlns: "http://www.w3.org/1999/xhtml"
    }
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "goal",
      title: "Create Goal"
    }
  })], 1)])]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "deleteActivityIcon"
    },
    on: {
      click: function click($event) {
        $event.preventDefault();
        return _vm.deleteActivity(_vm.index, _vm.subIndex);
      }
    }
  }, [_c("circle", {
    attrs: {
      cx: 545 + _vm.activity.x,
      cy: 125 + _vm.activity.y,
      r: "6",
      stroke: "white",
      "stroke-width": "1",
      fill: "#F56C6C"
    }
  })])])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;