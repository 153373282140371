"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _InviteList = _interopRequireDefault(require("./components/InviteList"));
var _clipboard = _interopRequireDefault(require("@/utils/clipboard"));
var _axios = _interopRequireDefault(require("axios"));
// use clipboard directly
var _default = exports.default = {
  name: 'Invites',
  components: {
    InviteList: _InviteList.default
  },
  props: {
    partyId: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    return {
      loading: false,
      total: 0,
      invite_form: {
        hash: '',
        first_name: '',
        last_name: '',
        email: '',
        department: '',
        url: '',
        status: 'pending'
      },
      invites: [{
        first_name: '',
        middle_name: '',
        last_name: '',
        department: '',
        status: '',
        url: '',
        email: '',
        invite_sent: false,
        loading: false
      }]
    };
  },
  mounted: function mounted() {
    this.getInviteList(0);
  },
  methods: {
    getInviteList: function getInviteList(page_no) {
      var _this = this;
      this.loading = true;
      var headers = {
        'Content-Type': 'application/json',
        'x-party-id': this.partyId,
        'x-user-id': this.$store.getters.id
      };
      _axios.default.get(process.env.VUE_APP_REST_API_ENDPOINT + 'user/invite?page=' + page_no, {
        headers: headers
      }).then(function (response) {
        _this.total = response.data.pageInfo.totalPages * response.data.pageInfo.itemsPerPage;
        var invites = response.data.data;
        for (var i = 0; i < invites.length; i++) {
          // Push new record and update record for each new invite in list
          var invite = {
            first_name: '',
            middle_name: '',
            last_name: '',
            department: '',
            status: '',
            url: '',
            email: '',
            invite_sent: false,
            loading: false
          };
          var temp_invite = Object.assign({}, invite);
          _this.invites.push(temp_invite);
          if (invites[i]['firstName'] !== undefined) {
            _this.invites[i].first_name = invites[i]['firstName'];
          }
          if (invites[i]['middleName'] !== undefined) {
            _this.invites[i].middle_name = invites[i]['middleName'];
          }
          if (invites[i]['lastName'] !== undefined) {
            _this.invites[i].last_name = invites[i]['lastName'];
          }
          if (invites[i]['emailAddress'] !== undefined) {
            _this.invites[i].email = invites[i]['emailAddress'];
          }
          if (invites[i]['department'] !== undefined) {
            _this.invites[i].department = invites[i]['department'];
          }
          if (invites[i]['id'] !== undefined) {
            _this.invites[i].id = invites[i]['id'];
          }
          if (invites[i]['isUsed'] !== undefined) {
            _this.invites[i].invite_sent = invites[i]['isUsed'];
          }
        }
        _this.loading = false;
        _this.newInvite();
      }).catch(function (error) {
        _this.loading = false;
        _this.$notify({
          title: 'Error',
          message: error.response.data.data.description,
          type: 'error',
          duration: 2000
        });
      });
    },
    newInvite: function newInvite() {
      var invite = {
        first_name: '',
        middle_name: '',
        last_name: '',
        email: '',
        invite_sent: false,
        loading: false,
        department: '',
        status: '',
        url: ''
      };
      var temp_invite = Object.assign({}, invite);
      this.invites.push(temp_invite);
      var temp_invite1 = Object.assign({}, invite);
      this.invites.push(temp_invite1);
      var temp_invite2 = Object.assign({}, invite);
      this.invites.push(temp_invite2);
    },
    deleteInvite: function deleteInvite(index) {
      if (this.invites[index].id) {
        this.invites[index].loading = true;
        var request_body = {
          'header': {
            'userId': this.$store.getters.id,
            'partyId': this.partyId
          }
        };

        /* const url = process.env.VUE_APP_REST_API_ENDPOINT + 'user/invite/' + this.invites[index].id;
        fetch(url, {
            method : "DELETE",
            body: JSON.stringify(request_body),
        }).then(
            response => {
              this.invites[index].loading = false
              this.invites.splice(index, 1)
            }
        ).then(
            html => {
              this.$notify({
                title: 'Error',
                message: 'Invite delete failed',
                type: 'error',
                duration: 2000
              })
            }
          )*/
        var that = this;
        var xhr = new XMLHttpRequest();
        xhr.open('DELETE', process.env.VUE_APP_REST_API_ENDPOINT + 'user/invite/' + this.invites[index].id, true);

        // Send the proper header information along with the request
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.send(JSON.stringify(request_body));
        xhr.onreadystatechange = function () {// Call a function when the state changes.
        };
        xhr.onload = function () {
          // const responseObj = xhr.response
          if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
            // Request finished. Do processing here.
            that.$notify({
              title: 'Success',
              message: 'Invite deleted succesfully',
              type: 'success',
              duration: 2000
            });
            that.invites[index].loading = false;
            that.invites.splice(index, 1);
          } else {
            that.$notify({
              title: 'Error',
              message: 'Invite delete failed',
              type: 'error',
              duration: 2000
            });
          }
        };
      } else {
        this.invites.splice(index, 1);
      }
    },
    resendInvite: function resendInvite(index) {
      var _this2 = this;
      this.invites[index].loading = true;
      var headers = {
        'Content-Type': 'application/json'
      };
      var body = {
        'header': {
          'userId': this.$store.getters.id,
          'partyId': this.partyId
        }
      };
      _axios.default.patch(process.env.VUE_APP_REST_API_ENDPOINT + 'user/invite/' + this.invites[index].id + '/resend', body, {
        headers: headers
      }).then(function (response) {
        _this2.invites[index].loading = false;
        if (response.data.status === 'SUCCESS') {
          _this2.$notify({
            title: 'Success',
            message: response.data.data.description,
            type: 'success',
            duration: 2000
          });
        } else {
          _this2.$notify({
            title: 'Error',
            message: response.data.data.description,
            type: 'error',
            duration: 2000
          });
        }
      }).catch(function (error) {
        _this2.invites[index].loading = false;
        _this2.$notify({
          title: 'Error',
          message: error.response.data.data.description,
          type: 'error',
          duration: 2000
        });
      });
    },
    inviteUser: function inviteUser(index) {
      var _this3 = this;
      if (this.invites[index].first_name === '' || this.invites[index].email === '') {
        this.$notify({
          title: 'Error',
          message: 'First name and email are required to send invites to user',
          type: 'error',
          duration: 2000
        });
      } else {
        this.invites[index].invite_sent = true;
        this.invites[index].loading = true;
        var headers = {
          'Content-Type': 'application/json'
        };
        var body = {
          'data': [{
            'firstName': this.invites[index].first_name,
            'middleName': this.invites[index].middle_name,
            'lastName': this.invites[index].last_name,
            'department': this.invites[index].department,
            'emailAddress': this.invites[index].email
          }],
          'header': {
            'userId': this.$store.getters.id,
            'partyId': this.$store.getters.partyId
          }
        };
        _axios.default.post(process.env.VUE_APP_REST_API_ENDPOINT + 'user/invite', body, {
          headers: headers
        }).then(function (response) {
          _this3.invites[index].loading = false;
          _this3.invites[index].id = response.data.data.id;
          _this3.response = response.data;
          _this3.$notify({
            title: 'Success',
            message: 'Invite sent to ' + _this3.invites[index].email + ' succesfully',
            type: 'success',
            duration: 2000
          });
        }).catch(function (error) {
          _this3.invites[index].loading = false;
          _this3.$notify({
            title: 'Error',
            message: error.response.data.data.description,
            type: 'error',
            duration: 2000
          });
        });
      }
    },
    copyInvite: function copyInvite(index, event) {
      (0, _clipboard.default)(this.generateInviteURL(index), event);
    },
    generateInviteURL: function generateInviteURL(index) {
      var inviteCode = this.invites[index].inviteCode;
      return inviteCode;
    }
  }
};