"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.array.fill");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("g", {
    attrs: {
      refs: "generated-path-relation",
      filter: "url(#exp_relation_shadow)"
    },
    on: {
      mouseover: _vm.emitOnMouseOver,
      mouseleave: _vm.emitOnMouseLeave,
      click: _vm.emitClick
    }
  }, [_c("path", {
    attrs: {
      d: _vm.path,
      stroke: "rgba(1,1,1,0)",
      "stroke-width": "20",
      "stroke-dasharray": _vm.isHover ? _vm.entity.styleonhover.dasharray : _vm.entity.style.dasharray,
      fill: _vm.entity.style.fill,
      "stroke-opacity": "0.1"
    }
  }), _vm._v(" "), _c("path", {
    attrs: {
      d: _vm.path,
      stroke: _vm.isHover ? _vm.entity.styleonhover.color : _vm.entity.style.color,
      "stroke-opacity": "1",
      "stroke-width": _vm.isHover ? _vm.entity.styleonhover.width : _vm.entity.style.width,
      "stroke-dasharray": _vm.isHover ? _vm.entity.styleonhover.dasharray : _vm.entity.style.dasharray,
      fill: _vm.isHover ? _vm.entity.styleonhover.fill : _vm.entity.style.fill,
      "marker-start": "url(#dot)",
      "marker-end": "url(#triangle)"
    }
  })]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;