"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _Icon = _interopRequireDefault(require("./../Icon.vue"));
var _default2 = exports.default = {
  name: 'DomainDocumentDetails',
  components: {
    IconComponent: _Icon.default
  },
  props: {
    index: {
      type: Number,
      required: true,
      default: 0
    },
    cx: {
      type: String,
      required: true,
      default: ''
    },
    cy: {
      type: String,
      required: true,
      default: ''
    },
    domainIcons: {
      type: Array,
      required: true,
      default: function _default() {
        return {};
      }
    },
    processes: {
      type: Array,
      required: true,
      default: function _default() {
        return {};
      }
    },
    documents: {
      type: Array,
      required: true,
      default: function _default() {
        return {};
      }
    }
  },
  computed: {},
  beforeDestroy: function beforeDestroy() {},
  methods: {
    computeIconPoints: function computeIconPoints(index) {
      var angle = 360 / this.domainIcons.length;
      var angle_of_current_icon = angle * index;
      var point_x = parseInt(this.cx) + 70 * Math.cos(angle_of_current_icon * Math.PI / 180) - 14;
      var point_y = parseInt(this.cy) - 70 * Math.sin(angle_of_current_icon * Math.PI / 180) - 14;
      if (point_y < 200) {
        point_y = 200;
      }
      if (point_y + (this.processes.length * 30 + 30) > 800) {
        point_y = point_y - 50;
      }
      return [point_x, point_y];
    },
    computeProcessesHeight: function computeProcessesHeight() {
      return this.processes.length * 30 + 30;
    },
    computeDocumentsHeight: function computeDocumentsHeight() {
      return this.documents.length * 30 + 30;
    },
    openDocument: function openDocument(url) {
      window.open(url, '_blank');
    },
    openDomainDocumentEditModal: function openDomainDocumentEditModal() {
      var documents = {};
      documents.processes = this.processes;
      documents.additional_documents = this.documents;
      this.$eventHub.$emit('domain-document-edit', documents);
    }
  }
};