"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _echarts = _interopRequireDefault(require("echarts"));
var _utils = require("@/utils");
require('echarts/theme/macarons'); // echarts theme
var _default2 = exports.default = {
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '20px'
    },
    height: {
      type: String,
      default: '20px'
    },
    status: {
      type: Array,
      default: function _default() {
        return [0, 0, 0, 0, 0, 0, 0, 0];
      }
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      chart: null
    };
  },
  watch: {
    'status': {
      handler: function handler(after, before) {
        this.initChart();
      },
      deep: true
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.initChart();
    this.__resizeHandler = (0, _utils.debounce)(function () {
      if (_this.chart) {
        _this.chart.resize();
      }
    }, 100);
    window.addEventListener('resize', this.__resizeHandler, {
      passive: true
    });
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    window.removeEventListener('resize', this.__resizeHandler);
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart: function initChart() {
      this.chart = _echarts.default.init(this.$el, 'macarons');
      var status_data = [{
        value: 0,
        name: 'Pending'
      }, {
        value: 0,
        name: 'InProgress'
      }, {
        value: 0,
        name: 'Completed'
      }];
      for (var i = 0; i < this.status.length; i++) {
        switch (this.status[i].status) {
          case 'Approved':
          case 'Backlog':
          case 'Candidate':
            status_data[0].value += this.status[i].noOfTickets;
            break;
          case 'In Progress':
            status_data[1].value += this.status[i].noOfTickets;
            break;
          case 'Retired':
          case 'Released':
          case 'Finalised':
          case 'Improvement':
            status_data[2].value += this.status[i].noOfTickets;
            break;
        }
      }
      this.chart.setOption({
        tooltip: {
          trigger: 'item'
        },
        series: [{
          name: 'Status',
          type: 'pie',
          radius: ['40%', '70%'],
          center: ['50%', '50%'],
          label: {
            show: false
          },
          // adjust the start and end angle
          startAngle: 180,
          endAngle: 360,
          data: status_data
        }]
      });
    }
  }
};