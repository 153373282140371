"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("g", {
    directives: [{
      name: "long-press",
      rawName: "v-long-press",
      value: 450,
      expression: "450"
    }],
    style: _vm.computeStyle(),
    attrs: {
      refs: "phase-component"
    },
    on: {
      "long-press-start": _vm.onLongPressStart,
      "long-press-stop": _vm.onLongPressStop
    }
  }, [_vm.isHighlight ? _c("polygon", {
    staticClass: "gaphighlighter-anim",
    attrs: {
      points: _vm.generatePathHighlighter(),
      "stroke-width": "1px",
      fill: "rgba(38,66,139,0.1)",
      stroke: "rgba(0,128, 128, 0.65",
      "stroke-dasharray": "4"
    }
  }) : _vm._e(), _vm._v(" "), _c("g", {
    attrs: {
      refs: "top_collider"
    },
    on: {
      mouseover: _vm.onMouseLeave
    }
  }, [_c("rect", {
    attrs: {
      x: _vm.x,
      y: _vm.y - 5,
      rx: 3,
      width: _vm.length,
      height: 5,
      fill: "rgba(200,200,200,0)",
      stroke: "rgba(200,200,200,0)",
      "stroke-width": "2"
    }
  })]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "left"
    },
    on: {
      mouseover: _vm.onMouseLeave
    }
  }, [_c("rect", {
    attrs: {
      x: _vm.x,
      y: _vm.y + 52,
      rx: 3,
      width: _vm.length * 0.22,
      height: 3,
      fill: "rgba(200,200,200,0)",
      stroke: "rgba(200,200,200,0)",
      "stroke-width": "2"
    }
  })]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "bottom_right_collider"
    },
    on: {
      mouseover: _vm.onMouseLeave
    }
  }, [_c("rect", {
    attrs: {
      x: _vm.x + _vm.length * 1.1,
      y: _vm.y + 52,
      rx: 3,
      width: _vm.length * 0.2,
      height: 3,
      fill: "rgba(200,200,200,0)",
      stroke: "rgba(200,200,200,0)",
      "stroke-width": "2"
    }
  })]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "left_vertical_collider"
    },
    on: {
      mouseover: _vm.onMouseLeave
    }
  }, [_c("rect", {
    attrs: {
      x: _vm.x - 10,
      y: _vm.y,
      rx: 3,
      width: 5,
      height: 52,
      fill: "rgba(200,200,200,0)",
      stroke: "rgba(200,200,200,0)",
      "stroke-width": "2"
    }
  })]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "right_vertical_collider"
    },
    on: {
      mouseover: _vm.onMouseLeave
    }
  }, [_c("rect", {
    attrs: {
      x: _vm.x + _vm.length + 35,
      y: _vm.y,
      rx: 3,
      width: 5,
      height: 52,
      fill: "rgba(200,200,200,0)",
      stroke: "rgba(200,200,200,0)",
      "stroke-width": "2"
    }
  })]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "phase_container"
    },
    on: {
      mouseover: _vm.onMouseOver
    }
  }, [_c("polygon", {
    class: _vm.computePhaseStyle(),
    attrs: {
      id: _vm.generateUniqueID(),
      points: _vm.generatePath(),
      stroke: _vm.isLongPress ? "#1f57b2" : "",
      "stroke-width": "3px"
    },
    on: {
      click: function click($event) {
        $event.preventDefault();
        $event.stopPropagation();
        return _vm.showPhaseDetails();
      }
    }
  }, [_c("filter", {
    attrs: {
      id: "shadow",
      "color-interpolation-filters": "sRGB"
    }
  }, [_c("feDropShadow", {
    attrs: {
      dx: "2",
      gy: "2",
      "std-deviation": "3",
      "flood-opacity": "0.5"
    }
  })], 1)]), _vm._v(" "), _vm.showLabel && _vm.label.length < 25 ? _c("text", {
    staticStyle: {
      fill: "#FFF",
      "font-weight": "lighter",
      "letter-spacing": "-0.1pt"
    },
    attrs: {
      x: _vm.x + 18,
      y: _vm.y + 13,
      "text-anchor": "start",
      "font-size": "0.90em"
    },
    on: {
      click: function click($event) {
        return _vm.showPhaseDetails();
      }
    }
  }, [_vm._v(_vm._s(_vm.label))]) : _vm._e(), _vm._v(" "), _vm.showLabel && _vm.label.length > 25 ? _c("text", {
    staticStyle: {
      fill: "#FFF",
      "font-weight": "lighter",
      "letter-spacing": "-0.1pt"
    },
    attrs: {
      x: _vm.x + 18,
      y: _vm.y + 13,
      "text-anchor": "start",
      "font-size": "0.9em"
    },
    on: {
      click: function click($event) {
        return _vm.showPhaseDetails();
      }
    }
  }, [_c("tspan", {
    attrs: {
      x: _vm.x + 18,
      dy: 0
    }
  }, [_vm._v(_vm._s(_vm.computedLabel[0]))]), _vm._v(" "), _c("tspan", {
    attrs: {
      x: _vm.x + 18,
      dy: 15
    }
  }, [_vm._v(_vm._s(_vm.computedLabel[1]))])]) : _vm._e(), _vm._v(" "), _vm.priority ? _c("g", {
    attrs: {
      refs: "priority"
    }
  }, [_c("foreignObject", {
    staticStyle: {
      "background-color": "white",
      "border-radius": "5px",
      color: "red",
      "text-align": "center",
      "font-size": "0.8em",
      "font-weight": "lighter"
    },
    attrs: {
      x: _vm.x + 18,
      y: _vm.y + 32,
      width: 26,
      height: 12
    }
  }, [_c("div", {
    staticStyle: {
      "margin-top": "1px"
    }
  }, [_vm._v(_vm._s(_vm.computePriority()))])])]) : _vm._e(), _vm._v(" "), _vm.value ? _c("g", {
    attrs: {
      refs: "value"
    }
  }, [_c("foreignObject", {
    staticStyle: {
      "background-color": "white",
      "border-radius": "5px",
      color: "red",
      "text-align": "center",
      "font-size": "0.8em",
      "font-weight": "lighter"
    },
    attrs: {
      x: _vm.x + 46,
      y: _vm.y + 32,
      width: 26,
      height: 12
    }
  }, [_c("svg-icon", {
    staticStyle: {
      color: "blue",
      "margin-top": "1px"
    },
    attrs: {
      "icon-class": _vm.computeCustomerValueIcon()
    }
  })], 1)]) : _vm._e(), _vm._v(" "), _vm.ideas.length > 0 ? _c("g", {
    attrs: {
      refs: "value"
    }
  }, [_c("foreignObject", {
    staticStyle: {
      "background-color": "white",
      "border-radius": "5px",
      color: "red",
      "text-align": "center",
      "font-size": "0.8em",
      "font-weight": "lighter"
    },
    attrs: {
      x: _vm.x + 74,
      y: _vm.y + 32,
      width: 26,
      height: 12
    }
  }, [_c("div", {
    staticStyle: {
      color: "#E6A23C",
      "margin-left": "-3px",
      "margin-top": "1px"
    }
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "10px",
      color: "#E6A23C"
    },
    attrs: {
      "icon-class": "ideas"
    }
  }), _c("span", {
    staticStyle: {
      "margin-left": "1px",
      "margin-top": "1px",
      "font-weight": "100"
    }
  }, [_vm._v(_vm._s(_vm.ideas.length))])], 1)])]) : _vm._e(), _vm._v(" "), _c("linearGradient", {
    attrs: {
      id: "phaseGradient",
      x2: "1",
      y2: "1"
    }
  }, [_c("stop", {
    attrs: {
      offset: "0%",
      "stop-color": "#447799"
    }
  }), _vm._v(" "), _c("stop", {
    attrs: {
      offset: "50%",
      "stop-color": "#224488"
    }
  }), _vm._v(" "), _c("stop", {
    attrs: {
      offset: "100%",
      "stop-color": "#112266"
    }
  })], 1)], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;