"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("g", [_c("rect", {
    attrs: {
      x: _vm.x,
      y: _vm.y,
      width: "50",
      height: "150",
      fill: "#00BFBF"
    },
    on: {
      click: _vm.emitClick
    }
  }), _vm._v(" "), _c("text", {
    attrs: {
      x: 5,
      y: _vm.y + 65,
      "font-size": "1.0em",
      transform: _vm.generateTransform(),
      fill: _vm.lane.color,
      "font-weight": "bold"
    }
  }, [_vm._v(_vm._s(_vm.lane.name))]), _vm._v(" "), _c("text", {
    attrs: {
      x: 5,
      y: _vm.y + 85,
      "font-size": "0.7em",
      transform: _vm.generateTransform(),
      fill: _vm.lane.color,
      "font-weight": "lighter"
    }
  }, [_vm._v(_vm._s(_vm.lane.description))]), _vm._v(" "), _c("rect", {
    attrs: {
      x: _vm.x + 50,
      y: _vm.y,
      width: "1250",
      height: "150",
      fill: _vm.computeFill(),
      "fill-opacity": _vm.index % 2 === 0 ? 0.3 : 0.1
    }
  })]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;