"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  name: 'SyncWithBacklog',
  components: {},
  data: function data() {
    return {};
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    onSubmit: function onSubmit() {}
  }
};