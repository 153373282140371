"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "components-container"
  }, [_c("el-card", [_c("el-row", [_c("el-col", {
    staticClass: "text-center",
    attrs: {
      span: 24
    }
  }, [_c("el-row", [_c("el-col", {
    staticClass: "text-center",
    attrs: {
      span: 12
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.mockup_search_type,
      callback: function callback($$v) {
        _vm.mockup_search_type = $$v;
      },
      expression: "mockup_search_type"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: "Storyboards",
      size: "mini"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Mockups",
      size: "mini"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "Both",
      size: "mini"
    }
  })], 1)], 1), _vm._v(" "), _c("el-col", {
    staticClass: "text-center",
    attrs: {
      span: 12
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "Search",
      "prefix-icon": "el-icon-search",
      size: "mini"
    },
    model: {
      value: _vm.mockup_search,
      callback: function callback($$v) {
        _vm.mockup_search = $$v;
      },
      expression: "mockup_search"
    }
  })], 1)], 1)], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("el-card", {
    staticClass: "mt-10",
    staticStyle: {
      height: "500px"
    }
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("strong", [_vm._v(" Mockup Details ")])]), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("strong", [_vm._v("Name: ")])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 16
    }
  }, [_vm._v("\n              " + _vm._s(_vm.selectedMockup.name) + "\n            ")])], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("strong", [_vm._v("Description: ")])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 16
    }
  }, [_vm._v("\n              " + _vm._s(_vm.selectedMockup.description) + "\n            ")])], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("strong", [_vm._v("Tag: ")])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 16
    }
  }, [_c("el-tag", {
    attrs: {
      size: "mini"
    }
  }, [_vm._v(_vm._s(_vm.selectedMockup.tags))])], 1)], 1)], 1)], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 12
    }
  }, [_c("el-image", {
    staticStyle: {
      width: "100%",
      height: "500px"
    },
    attrs: {
      src: _vm.selectedMockup.versions[0].url,
      fit: "contain"
    },
    on: {
      click: _vm.showMockupDetails
    }
  })], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 6
    }
  }, [_c("el-card", {
    staticClass: "mt-10",
    staticStyle: {
      height: "500px",
      "overflow-y": "scroll"
    }
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("strong", [_vm._v(" Version Details ")])]), _vm._v(" "), _c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.selectedMockup.versions,
      stripe: "",
      size: "mini"
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "id",
      label: "ID"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "version",
      label: "Version"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      type: "expand"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(props) {
        return [_c("el-table", {
          staticStyle: {
            width: "100%"
          },
          attrs: {
            data: props.row.tasks,
            stripe: "",
            size: "mini"
          }
        }, [_c("el-table-column", {
          attrs: {
            prop: "name",
            label: "Name"
          }
        }), _vm._v(" "), _c("el-table-column", {
          attrs: {
            prop: "description",
            label: "Description"
          }
        }), _vm._v(" "), _c("el-table-column", {
          attrs: {
            prop: "status",
            label: "Status"
          }
        }), _vm._v(" "), _c("el-table-column", {
          attrs: {
            prop: "priority",
            label: "Priority"
          }
        })], 1)];
      }
    }])
  })], 1)], 1)], 1)], 1), _vm._v(" "), _c("el-card", {
    staticClass: "mt-10"
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("strong", [_vm._v("Mockups & Storyboards ")])]), _vm._v(" "), _vm.mockups.length > 0 ? _c("vue-horizontal-list", {
    attrs: {
      items: _vm.filteredMockups,
      options: _vm.options
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c("div", {
          on: {
            click: function click($event) {
              return _vm.selectMockup(item);
            }
          }
        }, [_c("div", {
          staticClass: "aspect r-10-12 border-3 overflow-hidden"
        }, [_c("div", {
          staticClass: "relative wh-100 lh-0"
        }, [_c("img", {
          attrs: {
            src: item.versions[0].url
          }
        }), _vm._v(" "), _c("div", {
          staticClass: "bg-overlay absolute-0 wh-100 p-24 flex-end"
        }, [_c("h4", {
          staticClass: "m-0 text-ellipsis-2l text-center white"
        }, [_vm._v(_vm._s(item.name))])])])]), _vm._v(" "), _c("div", {
          staticClass: "mt-8 tiny-bold text-center text-uppercase"
        }, [_vm._v("Tags: "), _c("el-tag", {
          attrs: {
            size: "mini"
          }
        }, [_vm._v(_vm._s(item.tags))]), _vm._v(" • Version: "), _c("el-tag", {
          attrs: {
            size: "mini"
          }
        }, [_vm._v(_vm._s(item.versions[0].version))])], 1), _vm._v(" "), _c("p", {
          staticClass: "mt-4 text-ellipsis-2l"
        }, [_vm._v(_vm._s(item.description))])])];
      }
    }], null, false, 2371091969)
  }) : _vm._e()], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: _vm.selectedMockup.name + " Details",
      visible: _vm.mockupStoryboardDetailsDialog,
      width: "90%",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.mockupStoryboardDetailsDialog = $event;
      }
    }
  }, [_c("mockup-storyboard-detail-component", {
    attrs: {
      "mockup-storyboard": _vm.selectedMockup,
      "reset-annotation": _vm.resetAnnotation
    },
    on: {
      "add-task": _vm.openAddTaskDialog
    }
  }), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      slot: "button",
      name: "closeButton",
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.mockupStoryboardDetailsDialog = false;
      }
    },
    slot: "button"
  }, [_vm._v("\n        Close\n      ")])], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Add Task",
      visible: _vm.addTaskDialog,
      width: "30%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addTaskDialog = $event;
      }
    }
  }, [_c("el-form", {
    ref: "task_form",
    attrs: {
      model: _vm.task_form,
      "label-width": "120px"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Name"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.task_form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.task_form, "name", $$v);
      },
      expression: "task_form.name"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Description"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.task_form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.task_form, "description", $$v);
      },
      expression: "task_form.description"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Priority"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "small"
    },
    model: {
      value: _vm.task_form.priority,
      callback: function callback($$v) {
        _vm.$set(_vm.task_form, "priority", $$v);
      },
      expression: "task_form.priority"
    }
  }, [_c("el-radio-button", {
    attrs: {
      label: "1"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "2"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "3"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "4"
    }
  }), _vm._v(" "), _c("el-radio-button", {
    attrs: {
      label: "5"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("div", {
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.addTask
    }
  }, [_vm._v("Add")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: _vm.cancelAddTask
    }
  }, [_vm._v("Cancel")])], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;