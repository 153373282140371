"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("g", {
    attrs: {
      refs: "entity_lane"
    }
  }, [_c("g", {
    attrs: {
      refs: "entity_details"
    }
  }, [_c("rect", {
    attrs: {
      x: _vm.x,
      y: _vm.y,
      width: _vm.width,
      height: _vm.height,
      rx: "2",
      fill: "rgba(252, 252, 255, 0.5)",
      stroke: "#191970",
      "stroke-width": "0.5"
    }
  }), _vm._v(" "), _vm._t("default")], 2), _vm._v(" "), _c("g", {
    attrs: {
      refs: "entity_header"
    }
  }, [_c("rect", {
    attrs: {
      x: 10,
      y: _vm.y,
      width: _vm.headerWidth,
      height: _vm.height,
      rx: "3",
      fill: "#191970",
      stroke: "#5e5e9a",
      "stroke-width": "1"
    }
  }), _vm._v(" "), _c("foreignObject", {
    staticClass: "text-left",
    staticStyle: {
      overflow: "hidden",
      "border-radius": "2pt"
    },
    attrs: {
      x: 20,
      y: _vm.y + 5,
      width: _vm.headerWidth - 40,
      height: 35
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "1.0em",
      "font-weight": "600",
      "text-align": "left",
      color: "#fff",
      "margin-right": "10pt",
      padding: "3pt",
      "word-wrap": "break-word"
    },
    attrs: {
      slot: "reference"
    },
    slot: "reference"
  }, [_vm._v("\n          " + _vm._s(_vm.truncateTitle(_vm.entity.title)) + "\n        ")])]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "max_min"
    }
  }, [_c("foreignObject", {
    attrs: {
      x: _vm.headerWidth - 15,
      y: _vm.y + 10,
      width: 15,
      height: 15
    },
    on: {
      click: function click($event) {
        return _vm.toggleLane();
      }
    }
  }, [_vm.isMinimized ? _c("div", [_c("svg-icon", {
    staticStyle: {
      color: "white"
    },
    attrs: {
      "icon-class": "arrow-shrink-w",
      title: "Shrink Lane"
    }
  })], 1) : _c("div", [_c("svg-icon", {
    staticStyle: {
      color: "white"
    },
    attrs: {
      "icon-class": "arrow-expand-w",
      title: "Expand Lane"
    }
  })], 1)])]), _vm._v(" "), !_vm.isMinimized ? _c("g", {
    attrs: {
      refs: "status"
    }
  }, [_c("foreignObject", {
    attrs: {
      x: _vm.headerWidth - 17,
      y: _vm.y + 38,
      width: 25,
      height: 25
    }
  }, [_c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Proposed",
      placement: "top"
    }
  }, [_vm.entity.status === "Proposed" ? _c("svg-icon", {
    staticStyle: {
      height: "15pt",
      width: "15pt",
      "border-radius": "15pt",
      "padding-bottom": "4pt",
      "background-color": "white",
      color: "grey",
      "font-size": "1.0em"
    },
    attrs: {
      "icon-class": "hand-holding-solid"
    }
  }) : _vm._e()], 1), _vm._v(" "), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Accepted",
      placement: "top"
    }
  }, [_vm.entity.status === "Accepted" ? _c("svg-icon", {
    staticStyle: {
      height: "15pt",
      width: "18pt",
      color: "white",
      "background-color": "cornflowerblue",
      padding: "1pt 1pt 2pt 1pt",
      "border-radius": "10pt",
      "font-size": "1.0em"
    },
    attrs: {
      "icon-class": "thumbs-up"
    }
  }) : _vm._e()], 1), _vm._v(" "), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "In progress",
      placement: "top"
    }
  }, [_vm.entity.status === "In progress" ? _c("svg-icon", {
    staticStyle: {
      height: "15pt",
      width: "18pt",
      color: "white",
      "background-color": "#2c64b5",
      padding: "0",
      "border-radius": "10pt",
      "font-size": "1.0em"
    },
    attrs: {
      "icon-class": "delivery"
    }
  }) : _vm._e()], 1), _vm._v(" "), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "On hold",
      placement: "top"
    }
  }, [_vm.entity.status === "On hold" ? _c("svg-icon", {
    staticStyle: {
      height: "15pt",
      width: "15pt",
      color: "#ffa200",
      "font-size": "1.0em"
    },
    attrs: {
      "icon-class": "pause-circle-fill-w"
    }
  }) : _vm._e()], 1), _vm._v(" "), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Overdue",
      placement: "top"
    }
  }, [_vm.entity.status === "Overdue" ? _c("svg-icon", {
    staticStyle: {
      height: "15pt",
      width: "15pt",
      color: "darkred",
      "font-size": "1.0em"
    },
    attrs: {
      "icon-class": "exclamation-circle-fill"
    }
  }) : _vm._e()], 1), _vm._v(" "), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Achieved",
      placement: "top"
    }
  }, [_vm.entity.status === "Achieved" ? _c("svg-icon", {
    staticStyle: {
      height: "15pt",
      width: "15pt",
      color: "darkgreen",
      "font-size": "1.0em"
    },
    attrs: {
      "icon-class": "check-circle-fill"
    }
  }) : _vm._e()], 1)], 1)]) : _vm._e(), _vm._v(" "), !_vm.isMinimized ? _c("g", {
    attrs: {
      refs: "priority"
    }
  }, [_c("rect", {
    attrs: {
      x: 25,
      y: _vm.y + 40,
      rx: 5,
      ry: 5,
      width: _vm.dynamicSvgTextLength(_vm.entity.priority),
      height: "17",
      fill: "cornflowerblue",
      stroke: "#00273E",
      "stroke-width": "1"
    }
  }), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "#fff"
    },
    attrs: {
      x: 33,
      y: _vm.y + 52.5,
      "font-size": "0.9em",
      "text-anchor": "left"
    }
  }, [_vm._v("\n          " + _vm._s(_vm.entity.priority) + "\n        ")])]) : _vm._e(), _vm._v(" "), _c("g", {
    attrs: {
      refs: "description"
    }
  }, [!_vm.isMinimized ? _c("foreignObject", {
    staticClass: "text-left",
    staticStyle: {
      overflow: "hidden",
      "border-radius": "2pt"
    },
    attrs: {
      x: 20,
      y: _vm.y + 65,
      width: _vm.headerWidth - 20,
      height: _vm.height - 75
    }
  }, [_c("el-popover", {
    attrs: {
      placement: "top-start",
      width: "400",
      trigger: "hover",
      "open-delay": 400,
      "close-delay": 400,
      title: _vm.entity.title,
      content: _vm.entity.description
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "11px",
      "text-align": "left",
      color: "#f6f6f6",
      padding: "3pt",
      "word-wrap": "break-word"
    },
    attrs: {
      slot: "reference"
    },
    slot: "reference"
  }, [_vm._v("\n              " + _vm._s(_vm.truncateString(_vm.entity.description, _vm.y / 19 * 28)) + "\n            ")])])], 1) : _vm._e()]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "entity_mask"
    }
  }, [_c("rect", {
    attrs: {
      x: _vm.x - 5,
      y: _vm.y,
      width: _vm.computeLeftEntityMaskWidth,
      height: _vm.height,
      rx: "5",
      fill: "white",
      stroke: "white",
      "stroke-width": "1"
    }
  }), _vm._v(" "), _c("rect", {
    attrs: {
      x: _vm.x + _vm.width,
      y: _vm.y,
      width: _vm.computeRightEntityMaskWidth,
      height: _vm.height,
      rx: "5",
      fill: "#FFF",
      stroke: "white",
      "stroke-width": "1"
    }
  })])])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;