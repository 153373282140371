"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("g", {
    attrs: {
      refs: "note-container"
    }
  }, [_c("g", {
    directives: [{
      name: "dragged",
      rawName: "v-dragged",
      value: _vm.startNoteMove,
      expression: "startNoteMove"
    }],
    attrs: {
      id: _vm.generateNoteID(_vm.index),
      refs: "note"
    }
  }, [_c("foreignObject", {
    style: _vm.generateNoteColor(_vm.note.color),
    attrs: {
      x: _vm.note.x,
      y: _vm.note.y,
      width: 40,
      height: 40
    }
  }, [_c("div", {
    attrs: {
      xmlns: "http://www.w3.org/1999/xhtml"
    }
  }, [_c("i", {
    staticClass: "el-icon-document"
  })])])])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;