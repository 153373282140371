"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
var _taggedTemplateLiteral2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/taggedTemplateLiteral.js"));
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
require("core-js/modules/es7.object.values");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
var _interopRequireWildcard2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _Pain = _interopRequireDefault(require("./customer-journey/Pain.vue"));
var _Gain = _interopRequireDefault(require("./customer-journey/Gain.vue"));
var _Wow = _interopRequireDefault(require("./customer-journey/Wow1.vue"));
var _Idea = _interopRequireDefault(require("./customer-journey/Idea.vue"));
var _Task = _interopRequireDefault(require("./customer-journey/Task.vue"));
var _Phase = _interopRequireDefault(require("./customer-journey/Phase.vue"));
var _Challenge = _interopRequireDefault(require("./customer-journey/Challenge.vue"));
var _PhaseTool = _interopRequireDefault(require("./customer-journey/PhaseTool.vue"));
var _CreateJourneyIdea = _interopRequireDefault(require("./customer-journey/CreateJourneyIdea"));
var _Action = _interopRequireDefault(require("@/components/Action"));
var _utils = require("@/utils");
var _resolution = require("@/utils/resolution");
var _templateObject, _templateObject2, _templateObject3;
// import SvgPanZoom from 'vue-svg-pan-zoom'
var _default2 = exports.default = {
  name: 'CustomerJourneyArchive',
  components: {
    PainComponent: _Pain.default,
    GainComponent: _Gain.default,
    TaskComponent: _Task.default,
    TaskDetailsComponent: function TaskDetailsComponent() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./customer-journey/TaskDetails.vue"));
      });
    },
    ChallengeComponent: _Challenge.default,
    PhaseComponent: _Phase.default,
    PhaseDetailsComponent: function PhaseDetailsComponent() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./customer-journey/PhaseDetails.vue"));
      });
    },
    PhaseToolComponent: _PhaseTool.default,
    WowComponent: _Wow.default,
    IdeaComponent: _Idea.default,
    ActionComponent: _Action.default,
    CreateJourneyIdeaComponent: _CreateJourneyIdea.default,
    SVGCTAComponent: function SVGCTAComponent() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/components/SVGCTA'));
      });
    }
    // SvgPanZoom
  },
  props: {
    customer: {
      type: Object,
      required: true,
      default: function _default() {
        return {
          id: '',
          title: '',
          description: '',
          tiers: '',
          rationale: '',
          priority: '',
          persona: '',
          additionalattributes: {},
          linkedJourneyMaps: []
        };
      }
    },
    journeyMap: {
      type: Object,
      required: true,
      default: function _default() {
        return {
          id: '',
          title: '',
          description: '',
          additionalattributes: {},
          linkedJourneyExperiences: [],
          linkedJourneyPhases: [],
          linkedJourneySteps: []
        };
      }
    }
  },
  data: function data() {
    var validateTitle = function validateTitle(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter the Title'));
      } else {
        callback();
      }
    };
    var validateDescription = function validateDescription(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter the Description'));
      } else {
        callback();
      }
    };
    var validatePriority = function validatePriority(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter the Priority'));
      } else {
        callback();
      }
    };
    var validateImportance = function validateImportance(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter the Importance'));
      } else {
        callback();
      }
    };
    var validateGoal = function validateGoal(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter the Goal'));
      } else {
        callback();
      }
    };
    var validateDuration = function validateDuration(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter the Duration'));
      } else {
        callback();
      }
    };
    /* const validateScore = (rule, value, callback) => {
      if (!value) {
        callback(new Error('Please enter rank between 1 and 1000'))
      } else if (value < 1 || value > 1000) {
        callback(new Error('Please enter a rank between 1000 and 1'))
      } else {
        callback()
      }
    }*/
    return {
      journeyZoom: 100,
      highlight: null,
      svgpanzoom: null,
      selectedPhaseIndex: null,
      selectedTaskIndex: null,
      selectedExperienceIndex: null,
      selectedProductIndex: null,
      selectedFeatureIndex: null,
      selectedExperienceId: null,
      selectedPhase: {},
      selectedTask: {},
      selectedExperience: {},
      selectedExperienceList: {
        pain: [],
        gain: [],
        wow: []
      },
      selectedPhaseStepList: [],
      selectedPhaseIdeaList: [],
      selectedPhaseExperienceList: [],
      features_with_relations: [],
      temp_task_pos_x: [],
      temp_task_pos_y: [],
      stepIndexDragOriginalPos: null,
      stepIndexDragNewPos: null,
      journeyMapDeleteConfirmationForm: {
        title: ''
      },
      journeyMapGenerationConfirmationForm: {
        title: ''
      },
      // Flag to make journey and all its components compatible with journey offering component
      readonly: false,
      loading: false,
      displayCreateStepLevelExperienceSelectionDialog: false,
      createJourneyIdeaDialog: false,
      settingsDialog: false,
      journeyListDialog: false,
      journeyVersionsDialog: false,
      addPhaseDialog: false,
      addTaskDialog: false,
      taskDetailsDialog: false,
      IntegrationSidebarCollapsed: false,
      phaseToolHover: false,
      phaseHover: false,
      taskToolHover: false,
      ideaToolHover: false,
      taskHover: false,
      phaseLongPress: false,
      experienceHover: false,
      createExperienceFromTaskDialog: false,
      createExperienceFromPhaseDialog: false,
      isPhaseEdit: false,
      isPhaseFocusMode: false,
      deleteJourneyMapConfirmationDialog: false,
      regenerateJourneyMapConfirmationDialog: false,
      actionDetailsDialog: false,
      editExperienceDetailDialog: false,
      isJourneyHighlight: false,
      isExperienceLaneMinimized: false,
      createSubTaskDialog: false,
      relationsHoverTaskID: '',
      phaseHoverIdeaID: '',
      phaseDropDetails: {
        index: 0,
        isBefore: false // Only used to insert before first phase
      },
      activePhaseHighlighter: {
        x: 0,
        y: 0,
        width: 0,
        height: 0
      },
      activePhaseGapHighlighter: {
        x: 0,
        y: 0,
        width: 0,
        height: 0
      },
      phaseGapHighlighter: [],
      phaseHighlighter: [],
      toolbox: {
        task: {
          x: 10,
          y: 38
        },
        phase: {
          x: 15,
          y: 43
        },
        gain: {
          x: 10,
          y: 122
        },
        pain: {
          x: 75,
          y: 124
        },
        wow: {
          x: 10,
          y: 206
        },
        journey_idea: {
          x: 75,
          y: 215
        }
      },
      idea: {
        scope: '',
        scopename: ''
      },
      drop_hover_details: {
        x: -100,
        y: -100,
        hover: false
      },
      tempTaskRelationPath: {
        x1: 0,
        y1: 0,
        x2: 0,
        y2: 0
      },
      drag_drop_details: {
        drag_element: '',
        drop_element: '',
        index1: 0,
        index2: 0
      },
      pagination: {
        pages: 0,
        total: 0
      },
      journeyList: [],
      journeyVersion: [{
        title: 'Journey Name',
        version: '4'
      }, {
        title: 'Journey Name1',
        version: '3'
      }, {
        title: 'Journey Name2',
        version: '2'
      }, {
        title: 'Journey Name3',
        version: '1'
      }],
      statuses: [{
        label: 'Abandoned',
        value: 'Abandoned'
      }, {
        label: 'Backlog',
        value: 'Backlog'
      }, {
        label: 'Build Planning',
        value: 'Build Planning'
      }, {
        label: 'Feature Backend Build',
        value: 'Feature Backend Build'
      }, {
        label: 'Feature Design',
        value: 'Feature Design'
      }, {
        label: 'Feature UI Build',
        value: 'Feature UI Build'
      }, {
        label: 'Integration Testing',
        value: 'Integration Testing'
      }, {
        label: 'Release Ready',
        value: 'Release Ready'
      }, {
        label: 'UAT',
        value: 'UAT'
      }],
      journeySettings: [{
        title: 'Generate Journey',
        description: 'Generate Journey from Needs data',
        type: 'generate',
        note: {
          type: 'warning',
          message: 'All existing work will be over-written.'
        }
      }, {
        title: 'Copy Journey',
        description: 'Create a new Journey by copying an existing Journey. Useful to create journey variants, however does not leverage the Needs Analysis functionality.',
        type: 'copy',
        note: {
          type: 'warning',
          message: 'All existing work will be over-written however can be restored from a prior version.'
        }
      }, {
        title: 'Delete Journey',
        description: 'Delete the current Journey',
        type: 'delete',
        note: {
          type: 'info"',
          message: 'The current Journey will be saved as a prior version, and new blank version created.'
        }
      }, {
        title: 'Prior Version',
        description: 'Display list of prior versions',
        type: 'version',
        note: {}
      }],
      orphan_lane: {
        title: 'Orphans',
        description: 'Lane with orphan Features',
        linkedFeatures: [],
        additionalattributes: {
          minimized: false
        }
      },
      enabler_lane: {
        title: 'Enablers',
        description: 'Lane with enabler Features',
        linkedFeatures: [],
        additionalattributes: {
          minimized: false
        }
      },
      journey_map_form: {
        title: ''
      },
      step_selection_form: {
        step_id: null,
        experience_type: 'Pain'
      },
      step_form: {
        title: '',
        description: '',
        goal: '',
        intention: '',
        importance: 'Low',
        priority: 'Low',
        linkedJourneySubsteps: []
      },
      task_form: {
        title: '',
        description: '',
        goal: '',
        importance: 'Low',
        priority: 'Low',
        relations: []
      },
      phase_form: {
        id: '',
        title: '',
        priority: 'Low',
        description: '',
        importance: 'Low',
        duration: '',
        score: '',
        goal: '',
        tasks: [],
        experience: []
      },
      experience_form: {
        title: '',
        description: '',
        type: '',
        priority: 'Low',
        customer_value: 'Medium'
      },
      product_form: {
        title: '',
        description: '',
        offeringId: '',
        keypersonnel: {
          manager: '',
          owner: ''
        }
      },
      phase_pos_x: [],
      task_pos_x: [],
      task_pos_y: [],
      users: [],
      selectedExperienceType: '',
      type: 'Design',
      experienceCount: [{
        type: 'pain',
        count: 0
      }, {
        type: 'gain',
        count: 0
      }, {
        type: 'wow',
        count: 0
      }, {
        type: 'challenge',
        count: 0
      }],
      // Form Rules
      addStageRules: {
        title: [{
          required: true,
          trigger: 'blur',
          validator: validateTitle
        }],
        description: [{
          required: true,
          trigger: 'blur',
          validator: validateDescription
        }],
        priority: [{
          required: true,
          trigger: 'blur',
          validator: validatePriority
        }],
        duration: [{
          required: true,
          trigger: 'blur',
          validator: validateDuration
        }],
        goal: [{
          required: true,
          trigger: 'blur',
          validator: validateGoal
        }],
        importance: [{
          required: true,
          trigger: 'blur',
          validator: validateImportance
        }]
        // score: [{ required: true, trigger: 'blur', validator: validateScore }]
      },
      addSteprules: {
        title: [{
          required: true,
          message: 'Please input Step title',
          trigger: 'blur'
        }],
        description: [{
          required: true,
          message: 'Please input Step description',
          trigger: 'blur'
        }],
        goal: [{
          required: true,
          message: 'Please input Step Goal',
          trigger: 'blur'
        }],
        priority: [{
          required: true,
          message: 'Please input Step Priority',
          trigger: 'blur'
        }],
        importance: [{
          required: true,
          message: 'Please select importance',
          trigger: 'change'
        }]
      },
      canvasDragCoefficientX: 0,
      // Temp Data
      stepValue: [{
        value: 'High',
        label: 'High'
      }, {
        value: 'Medium',
        label: 'Medium'
      }, {
        value: 'Low',
        label: 'Low'
      }]
    };
  },
  computed: {
    isLegacyVersion: function isLegacyVersion() {
      if (!this.journeyMap.additionalattributes.layouts && !this.journeyMap.additionalattributes.relations) {
        return true;
      }
      return false;
    },
    computeStageTitleRotate: function computeStageTitleRotate() {
      return this.computedIdeasForJourney.length === 0 ? 'rotate(270, 140, 74)' : 'rotate(270, 140, 85)';
    },
    computedIdeasForJourney: function computedIdeasForJourney() {
      var ideas = [];
      if (this.journeyMap.title) {
        for (var i = 0; i < this.journeyMap.linkedBusinessIdeas.length; i++) {
          if (this.journeyMap.linkedBusinessIdeas[i].businessIdea.scope === 'Journey' && this.journeyMap.linkedBusinessIdeas[i].businessIdea.scopename === this.journeyMap.title) {
            ideas.push(this.journeyMap.linkedBusinessIdeas[i].businessIdea);
          }
        }
      }
      return ideas;
    },
    selectedProductExperiencesList: function selectedProductExperiencesList() {
      var experienceArray = [];
      if (this.sortedStages.length === 0 || this.selectedPhaseIndex === null || !this.sortedStages[this.selectedPhaseIndex] || this.sortedStages[this.selectedPhaseIndex].journeyPhase.relations === null) {
        return experienceArray;
      } else {
        for (var i = 0; i < this.journeyMap.linkedJourneyExperiences.length; i++) {
          if (this.sortedStages[this.selectedPhaseIndex].journeyPhase.relations.indexOf(this.journeyMap.linkedJourneyExperiences[i].journeyExperience.relations.step) > -1) {
            experienceArray.push(this.journeyMap.linkedJourneyExperiences[i]);
          }
        }
        return experienceArray;
      }
    },
    sortedStages: function sortedStages() {
      var stagesSortOrder = [];
      if (this.validateSortArray(this.journeyMap.additionalattributes) && Object.keys(this.journeyMap.additionalattributes.stagesSortOrder).length > 0 && this.journeyMap.linkedJourneyPhases.length > 0) {
        for (var i = 0; i < Object.keys(this.journeyMap.additionalattributes.stagesSortOrder).length; i++) {
          for (var j = 0; j < this.journeyMap.linkedJourneyPhases.length; j++) {
            if (this.journeyMap.linkedJourneyPhases[j].journeyPhase.id === this.journeyMap.additionalattributes.stagesSortOrder[i]) {
              stagesSortOrder.push(this.journeyMap.linkedJourneyPhases[j]);
              break;
            }
          }
        }
      } else {
        stagesSortOrder = this.journeyMap.linkedJourneyPhases;
      }
      return stagesSortOrder;
    },
    filteredTasks: function filteredTasks() {
      var tasks = [];
      if (this.journeyMap.hasOwnProperty('linkedJourneySteps')) {
        for (var i = 0; i < this.journeyMap.linkedJourneySteps.length; i++) {
          // Filter condition
          if (i === this.selectedTaskIndex) {
            continue;
          } else {
            tasks.push(this.journeyMap.linkedJourneySteps[i].journeyStep);
          }
        }
      }
      return tasks;
    },
    computeJourneyMapToolsX: function computeJourneyMapToolsX() {
      if (this.IntegrationSidebarCollapsed) {
        return 1100;
      } else {
        return 1500;
      }
    },
    computedTaskList: function computedTaskList() {
      var stepList = [];
      if (this.journeyMap && this.journeyMap.linkedJourneySteps) {
        for (var i = 0; i < this.journeyMap.linkedJourneySteps.length; i++) {
          if (this.computeJourneyStepDisplayCondition(this.journeyMap.linkedJourneySteps[i], i)) {
            stepList.push(this.journeyMap.linkedJourneySteps[i]);
          }
        }
      }
      return stepList;
    },
    computedExperienceList: function computedExperienceList() {
      var experienceList = [];
      if (this.journeyMap && this.journeyMap.linkedJourneyExperiences) {
        for (var i = 0; i < this.journeyMap.linkedJourneyExperiences.length; i++) {
          if (this.taskHover && this.journeyMap.linkedJourneyExperiences[i].journeyExperience.relations && this.journeyMap.linkedJourneyExperiences[i].journeyExperience.relations.step && this.selectedTask.journeyStep.id === this.journeyMap.linkedJourneyExperiences[i].journeyExperience.relations.step) {
            experienceList.push(this.journeyMap.linkedJourneyExperiences[i]);
          } else if (this.journeyMap.linkedJourneyExperiences[i].journeyExperience.relations && this.journeyMap.linkedJourneyExperiences[i].journeyExperience.relations.phase) {
            experienceList.push(this.journeyMap.linkedJourneyExperiences[i]);
          } else if (!this.taskHover) {
            experienceList.push(this.journeyMap.linkedJourneyExperiences[i]);
          }
        }
      }
      return experienceList;
    },
    computeTotalPainsCount: function computeTotalPainsCount() {
      return this._.filter(this.journeyMap.linkedJourneyExperiences, function (experience) {
        return experience.journeyExperience.experiencetype === 'PAIN';
      }).length;
    },
    computeTotalGainsCount: function computeTotalGainsCount() {
      return this._.filter(this.journeyMap.linkedJourneyExperiences, function (experience) {
        return experience.journeyExperience.experiencetype === 'GAIN';
      }).length;
    },
    computeTotalWowsCount: function computeTotalWowsCount() {
      return this._.filter(this.journeyMap.linkedJourneyExperiences, function (experience) {
        return experience.journeyExperience.experiencetype === 'WOW';
      }).length;
    },
    computeTotalChallengesCount: function computeTotalChallengesCount() {
      return this._.filter(this.journeyMap.linkedJourneyExperiences, function (experience) {
        return experience.journeyExperience.experiencetype === 'CHALLENGE';
      }).length;
    },
    filteredStepRelatedExperience: function filteredStepRelatedExperience() {
      var experienceArray = [];
      if (this.journeyMap) {
        for (var i = 0; i < this.journeyMap.linkedJourneyExperiences.length; i++) {
          if (this.journeyMap.linkedJourneyExperiences[i].journeyExperience.relations && this.taskDetailsDialog && this.journeyMap.linkedJourneyExperiences[i].journeyExperience.relations.step === this.journeyMap.linkedJourneySteps[this.selectedTaskIndex].journeyStep.id) {
            experienceArray.push(this.journeyMap.linkedJourneyExperiences[i]);
          }
        }
      }
      return experienceArray;
    },
    computeCanvasWidth: function computeCanvasWidth() {
      var length = 0;
      var buffer = 500;
      for (var i = 0; i < this.journeyMap.linkedJourneyPhases.length; i++) {
        length += this.computePhaseLength(this.journeyMap.linkedJourneyPhases[i].journeyPhase, i);
        length += 75;
      }
      length += buffer;
      if (length > 3000) {
        return length;
      }
      return 3000;
      /* switch (screenRatio()) {
        case 2:
          return 3100
        default:
          return 2000
      }*/
    },
    computeCanvasHeight: function computeCanvasHeight() {
      // TODO Refactor
      if (this.isExperienceLaneMinimized || this.journeyMap.linkedJourneyPhases === undefined) {
        return 650;
      }

      // Pseudo code
      // 1. Create an object array of all stages and step IDs with count set to 0
      // 2. Loop thro Experience array relations and increment corresponding stage/step id
      // 3. Get max count from array and use the number for height calc

      var size_array = [];
      var size_object = {};
      for (var j = 0; j < this.journeyMap.linkedJourneyPhases.length; j++) {
        size_object = {};
        size_object.id = this.journeyMap.linkedJourneyPhases[j].journeyPhase.id;
        size_object.count = 0;
        size_array.push(size_object);
      }
      for (var k = 0; k < this.journeyMap.linkedJourneySteps.length; k++) {
        size_object = {};
        size_object.id = this.journeyMap.linkedJourneySteps[k].journeyStep.id;
        size_object.count = 0;
        size_array.push(size_object);
      }
      for (var i = 0; i < this.journeyMap.linkedJourneyExperiences.length; i++) {
        if (this.journeyMap.linkedJourneyExperiences[i].journeyExperience.relations && this.journeyMap.linkedJourneyExperiences[i].journeyExperience.relations.phase) {
          for (var l = 0; l < size_array.length; l++) {
            if (size_array[l].id === this.journeyMap.linkedJourneyExperiences[i].journeyExperience.relations.phase) {
              size_array[l].count++;
            }
          }
        } else if (this.journeyMap.linkedJourneyExperiences[i].journeyExperience.relations && this.journeyMap.linkedJourneyExperiences[i].journeyExperience.relations.step) {
          for (var m = 0; m < size_array.length; m++) {
            if (size_array[m].id === this.journeyMap.linkedJourneyExperiences[i].journeyExperience.relations.step) {
              size_array[m].count++;
            }
          }
        }
      }
      var max_count = 0;
      for (var n = 0; n < size_array.length; n++) {
        if (size_array[n].count > max_count) {
          max_count = size_array[n].count;
        }
      }
      return 650 + max_count * 30;
    }
  },
  watch: {
    customer: {
      handler: function handler(newValue) {
        if (newValue.additionalattributes && newValue.additionalattributes.source !== 'prodiggi') {
          this.IntegrationSidebarCollapsed = true;
        } else {
          this.IntegrationSidebarCollapsed = false;
        }
      },
      deep: true
    },
    journeyMap: {
      handler: function handler(newValue) {
        if (newValue.id) {
          this.computePhaseX();
          this.computeTaskX();
          this.computeTaskY();
        }
        if (newValue.linkedJourneyPhases) {
          this.computePhaseX();
          this.computeTaskX();
        }
        if (newValue.linkedJourneySteps) {
          this.computeTaskY();
        }
        this.checkAndInitiateJourneyGeneration();
        if (this.selectedPhaseIndex !== null && this.sortedStages.length > 0) {
          this.selectedPhase = this.sortedStages[this.selectedPhaseIndex];
          this.computePhaseStepList(this.selectedPhaseIndex, this.sortedStages[this.selectedPhaseIndex].journeyPhase.id);
          this.computePhaseExperienceList(this.selectedPhaseIndex, this.sortedStages[this.selectedPhaseIndex].journeyPhase.id);
        }
        /* if (this.selectedTaskIndex !== null) {
          this.selectTask(this.selectedTaskIndex)
        }*/
      },
      deep: true
    }
  },
  created: function created() {},
  mounted: function mounted() {
    // this.deleteJourney()
    this.$eventHub.$on('customer-types-changed', this.reloadCustomerTypeJourney);
    window._jquery('#hover_highlight > rect').attr('x', -100);
    this.resetTools();
    this.computePhaseX();
    this.computeTaskX();
    this.computeScreenZoom();
    this.checkAndInitiateJourneyGeneration();
  },
  methods: {
    computeExperienceListForStep: function computeExperienceListForStep(step_id) {
      var experiences = [];
      for (var i = 0; i < this.journeyMap.linkedJourneyExperiences.length; i++) {
        if (this.journeyMap.linkedJourneyExperiences[i].journeyExperience.relations.step === step_id) {
          experiences.push(this.journeyMap.linkedJourneyExperiences[i]);
        }
      }
      return experiences;
    },
    isParentTaskActive: function isParentTaskActive(experience_id) {
      if (this.journeyMap.linkedJourneyExperiences.length === this.computedExperienceList.length) {
        return true;
      }
      for (var i = 0; i < this.journeyMap.linkedJourneyExperiences.length; i++) {
        if (this.taskHover && this.journeyMap.linkedJourneyExperiences[i].journeyExperience.id === experience_id && this.journeyMap.linkedJourneyExperiences[i].journeyExperience.relations.step === this.selectedTask.journeyStep.id) {
          return true;
        }
      }
      return false;
    },
    movePhaseRelationStageUp: function movePhaseRelationStageUp(step_index) {
      if (this.selectedPhase.journeyPhase.relations && this.selectedPhase.journeyPhase.relations.length) {
        var relations = Object.values(this.selectedPhase.journeyPhase.relations);
        var temp = relations[step_index];
        relations[step_index] = relations[step_index - 1];
        relations[step_index - 1] = temp;
        this.$emit('update-phase-step-sort-order', this.selectedPhase.journeyPhase.id, relations);
      }
    },
    movePhaseRelationStageDown: function movePhaseRelationStageDown(step_index) {
      if (this.selectedPhase.journeyPhase.relations && this.selectedPhase.journeyPhase.relations.length) {
        var relations = Object.values(this.selectedPhase.journeyPhase.relations);
        var temp = relations[step_index];
        relations[step_index] = relations[step_index + 1];
        relations[step_index + 1] = temp;
        this.$emit('update-phase-step-sort-order', this.selectedPhase.journeyPhase.id, relations);
      }
    },
    computedIdeaFromPhaseIndex: function computedIdeaFromPhaseIndex(phase_index) {
      if (phase_index !== null && phase_index !== undefined) {
        return this.computedIdeasForPhase(this.sortedStages[phase_index].journeyPhase);
      }
    },
    computedIdeaFromStepIndex: function computedIdeaFromStepIndex(step_index) {
      if (step_index !== null && step_index !== undefined) {
        return this.computedIdeasForStep(this.computedTaskList[step_index].journeyStep);
      }
    },
    computedIdeasForPhase: function computedIdeasForPhase(phase) {
      var ideas = [];
      if (phase.title) {
        for (var i = 0; i < this.journeyMap.linkedBusinessIdeas.length; i++) {
          if (this.journeyMap.linkedBusinessIdeas[i].businessIdea.scope === 'Phase' && this.journeyMap.linkedBusinessIdeas[i].businessIdea.scopename === phase.title) {
            ideas.push(this.journeyMap.linkedBusinessIdeas[i].businessIdea);
          }
        }
      }
      return ideas;
    },
    computedIdeasForStep: function computedIdeasForStep(step) {
      var ideas = [];
      if (step.title) {
        for (var i = 0; i < this.journeyMap.linkedBusinessIdeas.length; i++) {
          if (this.journeyMap.linkedBusinessIdeas[i].businessIdea.scope === 'Step' && this.journeyMap.linkedBusinessIdeas[i].businessIdea.scopename === step.title) {
            ideas.push(this.journeyMap.linkedBusinessIdeas[i].businessIdea);
          }
        }
      }
      return ideas;
    },
    registerSvgPanZoom: function registerSvgPanZoom(svgpanzoom) {
      this.svgpanzoom = svgpanzoom;
    },
    computeScreenZoom: function computeScreenZoom() {
      switch ((0, _resolution.screenRatio)()) {
        case 2:
          this.journeyZoom = 120;
          break;
        default:
          this.journeyZoom = 100;
          break;
      }
      this.zoomJourney();
    },
    renameJourneyMapTitle: function renameJourneyMapTitle() {
      var journeyMap = Object.assign({}, this.journey_map_form);
      this.$emit('rename-journeymap', journeyMap);
    },
    regenerateJourney: function regenerateJourney() {
      this.$emit('regenerate-journeymap', this.journeyMap);
      this.regenerateJourneyMapConfirmationDialog = false;
    },
    regenerateJourneyMapConfirmation: function regenerateJourneyMapConfirmation() {
      this.regenerateJourneyMapConfirmationDialog = true;
    },
    reloadCustomerTypeJourney: function reloadCustomerTypeJourney() {
      window._jquery('#hover_highlight > rect').attr('x', -100);
      this.resetTools();
      this.computePhaseX();
      this.computeTaskX();
      this.checkAndInitiateJourneyGeneration();
    },
    computePhaseStepList: function computePhaseStepList(phase_index, phase_id) {
      this.selectedPhaseStepList = [];
      if (this.selectedPhase.journeyPhase.relations) {
        var related_step_ids = this.selectedPhase.journeyPhase.relations;
        for (var i = 0; i < related_step_ids.length; i++) {
          for (var j = 0; j < this.journeyMap.linkedJourneySteps.length; j++) {
            if (related_step_ids[i] === this.journeyMap.linkedJourneySteps[j].journeyStep.id) {
              this.selectedPhaseStepList.push(this.journeyMap.linkedJourneySteps[j]);
            }
          }
        }
      }
    },
    computePhaseExperienceList: function computePhaseExperienceList(phase_index, phase_id) {
      this.selectedPhaseExperienceList = [];
      for (var i = 0; i < this.journeyMap.linkedJourneyExperiences.length; i++) {
        if (this.journeyMap.linkedJourneyExperiences[i].journeyExperience.relations && this.journeyMap.linkedJourneyExperiences[i].journeyExperience.relations.phase && this.journeyMap.linkedJourneyExperiences[i].journeyExperience.relations.phase === phase_id) {
          this.selectedPhaseExperienceList.push(this.journeyMap.linkedJourneyExperiences[i]);
        }
      }
    },
    computeTotalExperienceCountPerStage: function computeTotalExperienceCountPerStage(experience, index) {
      var experiences = {
        pains: [],
        gains: [],
        wows: [],
        challengez: []
      };
      if (experience.journeyExperience.relations.step) {
        var step_id = experience.journeyExperience.relations.step;
        for (var i = 0; i < this.journeyMap.linkedJourneyExperiences.length; i++) {
          if (this.journeyMap.linkedJourneyExperiences[i].journeyExperience && this.journeyMap.linkedJourneyExperiences[i].journeyExperience.relations && this.journeyMap.linkedJourneyExperiences[i].journeyExperience.relations.step && this.journeyMap.linkedJourneyExperiences[i].journeyExperience.relations.step === step_id) {
            if (this.journeyMap.linkedJourneyExperiences[i].journeyExperience.experiencetype === 'PAIN') {
              experiences.pains.push(this.journeyMap.linkedJourneyExperiences[i]);
            } else if (this.journeyMap.linkedJourneyExperiences[i].journeyExperience.experiencetype === 'GAIN') {
              experiences.gains.push(this.journeyMap.linkedJourneyExperiences[i]);
            } else if (this.journeyMap.linkedJourneyExperiences[i].journeyExperience.experiencetype === 'WOW') {
              experiences.wows.push(this.journeyMap.linkedJourneyExperiences[i]);
            } else {
              experiences.challengez.push(this.journeyMap.linkedJourneyExperiences[i]);
            }
          }
        }
      } else if (experience.journeyExperience.relations.phase) {
        var phase_id = experience.journeyExperience.relations.phase;
        for (var j = 0; j < this.journeyMap.linkedJourneyExperiences.length; j++) {
          if (this.journeyMap.linkedJourneyExperiences[j].journeyExperience && this.journeyMap.linkedJourneyExperiences[j].journeyExperience.relations && this.journeyMap.linkedJourneyExperiences[j].journeyExperience.relations.phase && this.journeyMap.linkedJourneyExperiences[j].journeyExperience.relations.phase === phase_id) {
            if (this.journeyMap.linkedJourneyExperiences[j].journeyExperience.experiencetype === 'PAIN') {
              experiences.pains.push(this.journeyMap.linkedJourneyExperiences[j]);
            } else if (this.journeyMap.linkedJourneyExperiences[j].journeyExperience.experiencetype === 'GAIN') {
              experiences.challengez.push(this.journeyMap.linkedJourneyExperiences[j]);
            } else if (this.journeyMap.linkedJourneyExperiences[i].journeyExperience.experiencetype === 'WOW') {
              experiences.wows.push(this.journeyMap.linkedJourneyExperiences[i]);
            } else {
              experiences.challengez.push(this.journeyMap.linkedJourneyExperiences[j]);
            }
          }
        }
      }
      if (experience.journeyExperience.experiencetype === 'PAIN') {
        return experiences.pains.length;
      } else if (experience.journeyExperience.experiencetype === 'GAIN') {
        return experiences.gains.length;
      } else if (experience.journeyExperience.experiencetype === 'WOW') {
        return experiences.wows.length;
      } else {
        return experiences.challengez.length;
      }
    },
    computeExperienceListForParentStage: function computeExperienceListForParentStage() {
      this.resetSelectedExperienceList();
      if (this.selectedExperience && this.selectedExperience.journeyExperience && this.selectedExperience.journeyExperience.relations && this.selectedExperience.journeyExperience.relations.step) {
        var step_id = this.selectedExperience.journeyExperience.relations.step;
        if (this.selectedExperience.journeyExperience.relations && this.selectedExperience.journeyExperience.relations.step && step_id) {
          for (var i = 0; i < this.journeyMap.linkedJourneyExperiences.length; i++) {
            if (this.journeyMap.linkedJourneyExperiences[i].journeyExperience && this.journeyMap.linkedJourneyExperiences[i].journeyExperience.relations && this.journeyMap.linkedJourneyExperiences[i].journeyExperience.relations.step && this.journeyMap.linkedJourneyExperiences[i].journeyExperience.relations.step === step_id) {
              if (this.journeyMap.linkedJourneyExperiences[i].journeyExperience.experiencetype === 'PAIN') {
                this.selectedExperienceList.pain.push(this.journeyMap.linkedJourneyExperiences[i]);
              } else if (this.journeyMap.linkedJourneyExperiences[i].journeyExperience.experiencetype === 'GAIN') {
                this.selectedExperienceList.gain.push(this.journeyMap.linkedJourneyExperiences[i]);
              } else if (this.journeyMap.linkedJourneyExperiences[i].journeyExperience.experiencetype === 'WOW') {
                this.selectedExperienceList.gain.push(this.journeyMap.linkedJourneyExperiences[i]);
              } else {
                this.selectedExperienceList.wow.push(this.journeyMap.linkedJourneyExperiences[i]);
              }
            }
          }
        }
      } else if (this.selectedExperience && this.selectedExperience.journeyExperience && this.selectedExperience.journeyExperience.relations && this.selectedExperience.journeyExperience.relations.phase) {
        var phase_id = this.selectedExperience.journeyExperience.relations.phase;
        if (this.selectedExperience.journeyExperience.relations && this.selectedExperience.journeyExperience.relations.phase && phase_id) {
          for (var j = 0; j < this.journeyMap.linkedJourneyExperiences.length; j++) {
            if (this.journeyMap.linkedJourneyExperiences[j].journeyExperience && this.journeyMap.linkedJourneyExperiences[j].journeyExperience.relations && this.journeyMap.linkedJourneyExperiences[j].journeyExperience.relations.phase && this.journeyMap.linkedJourneyExperiences[j].journeyExperience.relations.phase === phase_id) {
              if (this.journeyMap.linkedJourneyExperiences[j].journeyExperience.experiencetype === 'PAIN') {
                this.selectedExperienceList.pain.push(this.journeyMap.linkedJourneyExperiences[j]);
              } else if (this.journeyMap.linkedJourneyExperiences[j].journeyExperience.experiencetype === 'GAIN') {
                this.selectedExperienceList.gain.push(this.journeyMap.linkedJourneyExperiences[j]);
              } else if (this.journeyMap.linkedJourneyExperiences[i].journeyExperience.experiencetype === 'WOW') {
                this.selectedExperienceList.gain.push(this.journeyMap.linkedJourneyExperiences[i]);
              } else {
                this.selectedExperienceList.wow.push(this.journeyMap.linkedJourneyExperiences[j]);
              }
            }
          }
        }
      }
      return this.selectedExperienceList;
    },
    collapseExperienceLane: function collapseExperienceLane() {
      this.isExperienceLaneMinimized = true;
      this.computeExperienceListForParentStage();
    },
    expandExperienceLane: function expandExperienceLane() {
      this.isExperienceLaneMinimized = false;
    },
    toggleExperienceLane: function toggleExperienceLane() {
      this.isExperienceLaneMinimized = !this.isExperienceLaneMinimized;
      if (this.isExperienceLaneMinimized) {
        this.computeExperienceListForParentStage();
      }
    },
    checkAndInitiateJourneyGeneration: function checkAndInitiateJourneyGeneration() {
      this.retrieveNeeds(1);
    },
    // Temp Methods
    zoomJourney: function zoomJourney() {
      var root = document.querySelector(':root');
      // var rootStyles = getComputedStyle(root)
      // var zoom = rootStyles.getPropertyValue('--cssZoomVar')
      root.style.setProperty('--cssZoomVar', this.journeyZoom / 100);
    },
    handleCTAResponse: function handleCTAResponse(title) {
      if (title.indexOf('Switch') !== -1) {
        this.$router.push({
          name: "Journey2"
        });
      } else {
        this.$emit('create-new-journeymap');
      }
    },
    // UI METHODS
    computeUserName: function computeUserName(user) {
      return user.firstName + ' ' + user.lastName;
    },
    deleteStepRelation: function deleteStepRelation(index, sub_index) {
      var relations = this.journeyMap.linkedJourneySteps[index].journeyStep.relations;
      relations.splice(sub_index, 1);
      this.$emit('update-task-to-workflow', this.journeyMap.linkedJourneySteps[index].journeyStep.id, relations);
    },
    deleteSubStep: function deleteSubStep(sub_step_id) {
      var _this = this;
      this.$confirm('This will permanently delete the substep. Continue?', 'Warning', {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(function () {
        // Delete Step Action
        _this.$emit('delete-sub-step', sub_step_id);
      }).catch(function () {
        _this.$message({
          type: 'info',
          message: 'Delete canceled'
        });
      });
    },
    computePhaseDetailDialogTopPosition: function computePhaseDetailDialogTopPosition() {
      if (this.isPhaseEdit && this.selectedProductExperiencesList.length > 5) {
        return '5vh';
      }
      return '15vh';
    },
    formatExperienceInfoPopTableHeader: function formatExperienceInfoPopTableHeader(type) {
      return (0, _utils.capitalize)(type);
    },
    showExperienceInfoPopover: function showExperienceInfoPopover() {
      this.experienceCount = [{
        type: 'pain',
        count: this.computeTotalPainsCount
      }, {
        type: 'gain',
        count: this.computeTotalGainsCount
      }, {
        type: 'wow',
        count: this.computeTotalWowsCount
      }, {
        type: 'challenge',
        count: this.computeTotalChallengesCount
      }];
    },
    isExperienceVisible: function isExperienceVisible(experience) {
      if (this.isPhaseFocusMode) {
        return this.selectedPhase.journeyPhase.relations.includes(experience.journeyExperience.relations.step) || this.selectedPhase.journeyPhase.id === experience.journeyExperience.relations.phase;
      }
      return true;
    },
    toggleFocusMode: function toggleFocusMode(phase, index) {
      this.resetHoverDetailsDialog();
      this.selectedPhaseIndex = index;
      this.selectedPhase = phase;
      this.isPhaseFocusMode = !this.isPhaseFocusMode;
    },
    canvasClick: function canvasClick() {
      // this.resetHoverDetailsDialog()
    },
    deleteJourneyConfirmation: function deleteJourneyConfirmation() {
      this.settingsDialog = false;
      this.journeyMapDeleteConfirmationForm.title = '';
      this.deleteJourneyMapConfirmationDialog = true;
    },
    deleteJourney: function deleteJourney() {
      this.$emit('delete-journeymap', this.journeyMap.id);
      var cookieString = 'ProdiggiJourney_' + window._Cookies.get('UserPartyID') + '_' + window._Cookies.get('UserID') + '_' + this.journeyMap.id;
      window._Cookies.remove(cookieString);
      this.deleteJourneyMapConfirmationDialog = false;
    },
    retrieveNeeds: function retrieveNeeds(page_number) {
      var _this2 = this;
      this.settingsDialog = false;
      // this.loading = true
      this.$apollo.query({
        // Query
        query: window._gql(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["query NeedDefinitions($page: Page, $customertypescriteriaexpression: CustomerTypesCriteriaExpression!, $needscriteriaexpression: NeedDefinitionsCriteriaExpression)\n        {\n        NeedDefinitions(page: $page, where: $needscriteriaexpression)\n        {\n          pages\n          total\n          select{\n            id\n            title\n            description\n            additionalattributes\n            goal\n            needtype\n            linkedCustomerType(optional: true) {\n              customerType(optional: true, where: $customertypescriteriaexpression) {\n                id\n                title\n              }\n            }\n            linkedNeeds(optional: true) {\n              need(optional: true) {\n                id\n                title\n                description\n              }\n            }\n            linkedStages(optional: true) {\n              stage(optional: true) {\n                id\n                title\n                description\n                linkedSteps(optional: true) {\n                  step(optional: true) {\n                    id\n                    title\n                    description\n                    linkedNeeds(optional: true) {\n                      need(optional: true) {\n                        id\n                        title\n                        description\n                        action\n                        met\n                        value\n                      }\n                    }\n                  }\n                }\n              }\n            }\n          }\n        }\n      }"]))),
        fetchPolicy: 'no-cache',
        variables: {
          'page': {
            'limit': 20,
            'start': page_number
          },
          'needscriteriaexpression': {
            'needtype': {
              'EQ': 'functional'
            }
          },
          'customertypescriteriaexpression': {
            'id': {
              'EQ': this.$store.getters.customerType.id
            }
          }
        }
      }).then(function (data) {
        // this.loading = false
        if (data.data.NeedDefinitions.select[0].linkedNeeds.length > 0) {
          var cookieString = 'ProdiggiJourney_' + window._Cookies.get('UserPartyID') + '_' + window._Cookies.get('UserID') + '_' + _this2.journeyMap.id;
          if (window._Cookies.get(cookieString) === undefined || !window._Cookies.get(cookieString)) {
            if (_this2.journeyMap.linkedJourneyPhases.length === 0) {
              _this2.$confirm('Generate a Journey from Customer Needs?', 'Auto generate Journey Map?', {
                confirmButtonText: 'Generate',
                cancelButtonText: 'No Thanks',
                type: 'info'
              }).then(function () {
                window._Cookies.set(cookieString, true);
                var needs_data = data.data.NeedDefinitions.select;
                _this2.generateJourneyFromNeeds(needs_data[needs_data.length - 1]);
              }).catch(function () {
                window._Cookies.set(cookieString, true);
                console.log('Cancel');
              });
            }
          }
        }
      }).catch(function (error) {
        // Error
        _this2.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        // this.loading = false
      });
    },
    generateJourneyFromNeeds: function generateJourneyFromNeeds(needs) {
      var _this3 = this;
      this.settingsDialog = false;
      this.loading = true;
      /* var needs = {
        functional: {
          goal: 'goal description',
          stages: [
            {
              title: 'stage1',
              description: 'Stage Description',
              priority: 'High',
              importance: 'Medium',
              steps: [
                {
                  title: 'step1',
                  description: 'Step Description',
                  importance: 'Medium',
                  needs: [
                    {
                      action: 'Action',
                      met: 'Met',
                      value: 'High'
                    },
                    {
                      action: 'Action',
                      met: 'Partly',
                      value: 'Med'
                    },
                    {
                      action: 'Action',
                      met: 'Unmet',
                      value: 'Low'
                    }
                  ]
                },
                {
                  title: 'step2',
                  description: 'Step Description',
                  importance: 'Medium',
                  needs: [
                    {
                      action: 'Action',
                      met: 'Met',
                      value: 'High'
                    },
                    {
                      action: 'Action',
                      met: 'Partly',
                      value: 'Med'
                    },
                    {
                      action: 'Action',
                      met: 'Unmet',
                      value: 'Low'
                    }
                  ]
                }
              ]
            },
            {
              title: 'stage2',
              description: 'Stage Description',
              priority: 'High',
              importance: 'Medium',
              steps: [
                {
                  title: 'step21',
                  description: 'Step Description',
                  importance: 'Medium',
                  needs: [
                    {
                      action: 'Action',
                      met: 'Met',
                      value: 'High'
                    },
                    {
                      action: 'Action',
                      met: 'Partly',
                      value: 'Med'
                    },
                    {
                      action: 'Action',
                      met: 'Unmet',
                      value: 'Low'
                    }
                  ]
                }
              ]
            },
            {
              title: 'stage3',
              description: 'Stage Description',
              priority: 'High',
              importance: 'Medium',
              steps: [
                {
                  title: 'step31',
                  description: 'Step Description',
                  importance: 'Medium',
                  needs: [
                    {
                      action: 'Action',
                      met: 'Met',
                      value: 'High'
                    },
                    {
                      action: 'Action',
                      met: 'Partly',
                      value: 'Med'
                    },
                    {
                      action: 'Action',
                      met: 'Unmet',
                      value: 'Low'
                    }
                  ]
                }
              ]
            }
          ]
        },
        emotional: {
          needs: [
            {
              title: 'List Item 1'
            },
            {
              title: 'List Item 2'
            }
          ]
        },
        social: {
          needs: [
            {
              title: 'List Item 1'
            },
            {
              title: 'List Item 2'
            }
          ]
        }
      }*/
      for (var i = 0; i < needs.linkedStages.length; i++) {
        // Create Phase
        this.$apollo.mutate({
          // Query
          mutation: window._gql(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["mutation createJourneyPhase($journeyPhase: CreateJourneyPhaseInput!)\n          {\n            createJourneyPhase(input: $journeyPhase) {\n              id\n              title\n              description\n              priority\n              importance\n              goal\n              emotions\n              duration\n              challenges\n              additionalattributes\n              relations\n            }\n          }"]))),
          // Parameters
          variables: {
            'journeyPhase': {
              'journeyMapId': this.journeyMap.id,
              'title': needs.linkedStages[i].stage.title,
              'description': needs.linkedStages[i].stage.description,
              'priority': '',
              'importance': '',
              'additionalattributes': {
                'ui': {
                  'isCollapse': false
                }
              }
            }
          }
        }).then(function (data) {
          for (var k = 0; k < needs.linkedStages.length; k++) {
            if (needs.linkedStages[k].stage.title === data.data.createJourneyPhase.title) {
              for (var j = 0; j < needs.linkedStages[k].stage.linkedSteps.length; j++) {
                // Create Step
                var task = Object.assign({}, {
                  title: needs.linkedStages[k].stage.linkedSteps[j].step.title,
                  description: needs.linkedStages[k].stage.linkedSteps[j].step.description,
                  importance: ''
                });
                _this3.$emit('add-step', task, data.data.createJourneyPhase);
              }
            }
          }
        }).catch(function (error) {
          console.log(error);
        });
      }
      this.loading = false;
    },
    deletePhaseDetailStep: function deletePhaseDetailStep(step_index) {
      this.selectedTaskIndex = step_index;
      this.deleteStep();
    },
    deleteStepWithID: function deleteStepWithID(step_index) {
      this.selectedTaskIndex = step_index;
      this.deleteStep();
    },
    deletePhase: function deletePhase() {
      var _this4 = this;
      this.$confirm('This will permanently delete phase. Continue?', 'Warning', {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(function () {
        // Comment out below code until version is completed
        // This is to avoid rendering crashes due to child relations
        //
        // this.$emit('delete-phase', this.sortedStages[this.selectedPhaseIndex].journeyPhase.id)
        // this.sortedStages.splice(this.selectedPhaseIndex, 1)

        // this.selectedPhaseIndex = null
        _this4.addPhaseDialog = false;
      }).catch(function () {
        _this4.$message({
          type: 'info',
          message: 'Delete canceled'
        });
      });
    },
    initiateDeleteStep: function initiateDeleteStep(step_index, step_id) {
      this.selectedTaskIndex = step_index;
      this.deleteStep();
    },
    deleteStep: function deleteStep() {
      var _this5 = this;
      this.$confirm('This will permanently delete step. Continue?', 'Warning', {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(function () {
        // Comment out below code until version is completed
        // This is to avoid rendering crashes due to child relations
        //
        /* this.$emit('delete-step', this.computedTaskList[this.selectedTaskIndex].journeyStep.id)
        this.selectedTaskIndex = null
        this.taskDetailsDialog = false
        this.taskHover = false*/
      }).catch(function (e) {
        console.log(e);
        _this5.$message({
          type: 'info',
          message: 'Delete canceled'
        });
      });
    },
    deleteExperience: function deleteExperience(experience_id) {
      var _this6 = this;
      this.$confirm('This will permanently delete experience. Continue?', 'Warning', {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(function () {
        _this6.$emit('delete-experience', experience_id);
      }).catch(function () {
        _this6.$message({
          type: 'info',
          message: 'Delete canceled'
        });
      });
    },
    cloneJourneyMap: function cloneJourneyMap(index, journey_map_id, clone_entities) {
      this.journeyList[index].loading = true;
      this.$emit('clone-journeymap', journey_map_id, clone_entities);
      this.journeyList[index].loading = false;
      this.journeyListDialog = false;
    },
    openJourneyListDialog: function openJourneyListDialog() {
      var _this7 = this;
      this.settingsDialog = false;
      this.journeyListDialog = true;
      this.loading = true;
      this.customers = [];
      this.$apollo.query({
        // Query
        query: window._gql(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)([" query JourneyMaps($page: Page)\n        {\n        JourneyMaps(page: $page)\n        {\n          pages\n          total\n          select{\n            id\n            title\n            description\n          }\n        }\n      }"]))),
        fetchPolicy: 'no-cache',
        variables: {
          'page': {
            'limit': 20,
            'start': 1
          }
        }
      }).then(function (data) {
        _this7.journeyList = data.data.JourneyMaps.select;
        _this7.journeyList.forEach(function (journey, index) {
          journey.loading = false;
        });
        _this7.loading = false;
      }).catch(function (error) {
        // Error
        _this7.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this7.loading = false;
      });
    },
    openJourneyVersionsDialog: function openJourneyVersionsDialog() {
      this.settingsDialog = false;
      this.journeyVersionsDialog = true;
    },
    showSettingsDialog: function showSettingsDialog() {
      this.journey_map_form.title = this.journeyMap.title;
      this.settingsDialog = true;
    },
    createJourneyIdea: function createJourneyIdea() {
      // Event to open create journey map dialog
      this.createJourneyIdeaDialog = true;
    },
    addJourneyMapIdea: function addJourneyMapIdea() {
      // Event trigerred after idea creation via emit from component
      // Currently its a passthro event to just reload the journeymap,
      // Can be used for future flexibility
      this.$emit('add-idea');
    },
    createPhaseIdea: function createPhaseIdea(phase_title) {
      this.idea.scope = 'Phase';
      this.idea.scopename = phase_title;
      this.createJourneyIdeaDialog = true;
    },
    createStepIdea: function createStepIdea(step_title) {
      this.idea.scope = 'Step';
      this.idea.scopename = step_title;
      this.createJourneyIdeaDialog = true;
    },
    generateTaskExperienceRelationPath: function generateTaskExperienceRelationPath(experience, index) {
      var x1 = this.computeTaskPosXFromTaskID(experience.relations.step) + 12;
      var y1 = this.computeTaskPosYFromTaskID(experience.relations.step) + 28;
      var x2 = 0;
      var y2 = this.computeExperienceStartY(index);
      if (experience.experiencetype === 'PAIN') {
        x2 = this.computePainStartX(index) + 15;
      } else if (experience.experiencetype === 'GAIN') {
        x2 = this.computeGainStartX(index) + 15;
      } else {
        x2 = this.computeWowStartX(index) + 15;
      }
      return this.generateBezierPath(x1, y1, x2, y2);
    },
    generatePhaseExperienceRelationPath: function generatePhaseExperienceRelationPath(experience, index) {
      var x1 = this.computePhasePosXFromPhaseID(experience.relations.phase) + 5;
      var y1 = this.computePhasePosYFromPhaseID(experience.relations.phase) + 35;
      var x2 = 0;
      var y2 = this.computeExperienceStartY(index);
      if (experience.experiencetype === 'PAIN') {
        x2 = this.computePainStartX(index) + 10;
      } else if (experience.experiencetype === 'GAIN') {
        x2 = this.computeGainStartX(index) + 10;
      } else {
        x2 = this.computeWowStartX(index) + 10;
      }
      return this.generateBezierPath(x1, y1, x2, y2);
    },
    hasTaskInWorkflow: function hasTaskInWorkflow(index, task) {
      if (this.journeyMap.linkedJourneySteps[this.selectedTaskIndex].journeyStep.relations) {
        for (var i = 0; i < this.journeyMap.linkedJourneySteps[this.selectedTaskIndex].journeyStep.relations.length; i++) {
          if (this.journeyMap.linkedJourneySteps[this.selectedTaskIndex].journeyStep.relations[i] === task.id) {
            return true;
          }
        }
      }
      return false;
    },
    generateTaskWorkflowPathAndPoints: function generateTaskWorkflowPathAndPoints(task, relational_id, index, sub_index) {
      var start_x = this.computeTaskPosX(index) + 50;
      var start_y = this.computeTaskPosY(index) + 17;
      var end_x = 0;
      var end_y = 0;
      for (var j = 0; j < task.journeyStep.relations.length; j++) {
        // console.log(task.journeyStep.relations[j])
        // console.log(relational_id)
        if (task.journeyStep.relations[j] === relational_id) {
          end_x = this.computeTaskPosX(this.getStepFromID(task.journeyStep.relations[j])[1]) + 50;
          end_y = this.computeTaskPosY(this.getStepFromID(task.journeyStep.relations[j])[1]) + 17;

          /* console.log('inside')
          console.log(this.getStepFromID(this.computedTaskList[i].journeyStep.relations[j])[1])
          console.log(this.computeTaskPosX(this.getStepFromID(this.computedTaskList[i].journeyStep.relations[j])[1]))
          console.log(end_x)
          console.log(end_y)*/
        }
      }
      // console.log('outside')
      // console.log(end_x)
      // console.log(end_y)
      // handle forward and backward connection
      if (start_x < end_x) {
        start_x += 110;
        end_x -= 30;
      } else {
        start_x -= 30;
        end_x += 110;
      }
      var mid1_x = (start_x + end_x) / 2;
      // var mid1_y = 110

      if (end_x > 0 && end_y > 0) {
        return ['M ' + start_x + ' ' + start_y + ' C ' + mid1_x + ' ' + start_y + ' ' + mid1_x + ' ' + end_y + ' ' + end_x + ' ' + end_y, start_x, start_y, mid1_x, end_x, end_y];
      } else {
        return ['M 0 0', start_x, start_y, mid1_x, end_x, end_y];
      }
    },
    computeTempTaskRelation: function computeTempTaskRelation() {
      return this.generateBezierPath(this.tempTaskRelationPath.x1, this.tempTaskRelationPath.y1, this.tempTaskRelationPath.x2, this.tempTaskRelationPath.y2);
    },
    createPainFromPhase: function createPainFromPhase(index) {
      this.resetExperienceForm();
      this.selectedPhaseIndex = index;
      this.experience_form.type = 'Pain';
      this.experience_form.header_title = 'Pain';
      this.experience_form.header_description = 'Negative experiences felt by the customer.';
      this.createExperienceFromPhaseDialog = true;
    },
    createGainFromPhase: function createGainFromPhase(index) {
      this.resetExperienceForm();
      this.selectedPhaseIndex = index;
      this.experience_form.type = 'Gain';
      this.experience_form.header_title = 'Gain';
      this.experience_form.header_description = 'Positive experiences felt by the customer.';
      this.createExperienceFromPhaseDialog = true;
    },
    createWowFromPhase: function createWowFromPhase(index) {
      this.resetExperienceForm();
      this.selectedPhaseIndex = index;
      this.experience_form.type = 'Wow';
      this.experience_form.header_title = 'Wow';
      this.experience_form.header_description = 'Breakthrough experiences that show new possibilities to the customer.';
      this.createExperienceFromPhaseDialog = true;
    },
    createChallengeFromPhase: function createChallengeFromPhase(index) {
      this.resetExperienceForm();
      this.selectedPhaseIndex = index;
      this.experience_form.type = 'Challenge';
      this.experience_form.header_title = 'Obscurity';
      this.experience_form.header_description = 'Hidden complexities the customer is not aware of but must overcome (expert knowledge)';
      this.createExperienceFromPhaseDialog = true;
    },
    displayCreateSubTaskDialog: function displayCreateSubTaskDialog(index) {
      this.resetTaskForm();
      this.selectedTaskIndex = index;
      this.createSubTaskDialog = true;
    },
    createPainFromTask: function createPainFromTask(index) {
      this.resetExperienceForm();
      this.selectedTaskIndex = index;
      this.experience_form.type = 'Pain';
      this.experience_form.header_title = 'Pain';
      this.experience_form.header_description = 'Negative experiences felt by the customer.';
      this.createExperienceFromTaskDialog = true;
    },
    createGainFromTask: function createGainFromTask(index) {
      this.resetExperienceForm();
      this.selectedTaskIndex = index;
      this.experience_form.type = 'Gain';
      this.experience_form.header_title = 'Gain';
      this.experience_form.header_description = 'Positive experiences felt by the customer.';
      this.createExperienceFromTaskDialog = true;
    },
    createWowFromTask: function createWowFromTask(index) {
      this.resetExperienceForm();
      this.selectedTaskIndex = index;
      this.experience_form.type = 'Wow';
      this.experience_form.header_title = 'Wow';
      this.experience_form.header_description = 'Breakthrough experiences that show new possibilities to the customer.';
      this.createExperienceFromTaskDialog = true;
    },
    createChallengeFromTask: function createChallengeFromTask(index) {
      this.resetExperienceForm();
      this.selectedTaskIndex = index;
      this.experience_form.type = 'Challenge';
      this.experience_form.header_title = 'Obscurity';
      this.experience_form.header_description = 'Hidden complexities the customer is not aware of but must overcome (expert knowledge)';
      this.createExperienceFromTaskDialog = true;
    },
    createStepLevelExperience: function createStepLevelExperience() {
      if (this.step_selection_form.step_id === null) {
        return;
      }
      for (var i = 0; i < this.journeyMap.linkedJourneySteps.length; i++) {
        if (this.journeyMap.linkedJourneySteps[i].journeyStep.id === this.step_selection_form.step_id) {
          switch (this.step_selection_form.experience_type) {
            case 'Pain':
              this.createPainFromTask(i);
              break;
            case 'Gain':
              this.createGainFromTask(i);
              break;
            case 'Wow':
              this.createWowFromTask(i);
              break;
            case 'Challenge':
              this.createChallengeFromTask(i);
              break;
          }
        }
      }
      this.displayCreateStepLevelExperienceSelectionDialog = false;
      this.resetStepLevelExperienceForm();
    },
    createExperienceFromTask: function createExperienceFromTask() {
      this.$emit('add-step-experience', this.journeyMap.linkedJourneySteps[this.selectedTaskIndex].journeyStep.id, this.experience_form);
      this.createExperienceFromTaskDialog = false;
      this.resetExperienceForm();
    },
    createExperienceFromPhase: function createExperienceFromPhase() {
      this.$emit('add-phase-experience', this.sortedStages[this.selectedPhaseIndex].journeyPhase.id, this.experience_form);
      this.createExperienceFromPhaseDialog = false;
      this.resetExperienceForm();
    },
    editExperienceDetails: function editExperienceDetails(experience_index, experience) {
      this.selectedExperienceIndex = experience_index;
      this.selectedExperience = experience.journeyExperience;
      this.experience_form = Object.assign({}, experience.journeyExperience);
      this.experience_form.customer_value = experience.journeyExperience.additionalattributes.customer_value;
      this.resetHoverDetailsDialog();
      this.editExperienceDetailDialog = true;
    },
    updateExperienceDetails: function updateExperienceDetails() {
      this.$emit('edit-experience', this.experience_form);
      this.editExperienceDetailDialog = false;
      this.resetExperienceForm();
    },
    // Additional function similar to selectTask, since selecting step from stage details has differnt index
    selectTaskFromPhaseDialog: function selectTaskFromPhaseDialog(index, step) {
      for (var i = 0; i < this.journeyMap.linkedJourneySteps.length; i++) {
        if (this.journeyMap.linkedJourneySteps[i].journeyStep.id === step.journeyStep.id) {
          this.selectTask(i);
        }
      }
    },
    selectTask: function selectTask(index) {
      this.selectedTaskIndex = index;
      this.step_form = this.journeyMap.linkedJourneySteps[this.selectedTaskIndex].journeyStep;
      this.taskDetailsDialog = true;
    },
    editPhaseDetails: function editPhaseDetails(phase_index) {
      this.selectedPhaseIndex = phase_index;
      var phase = Object.assign({}, this.sortedStages[this.selectedPhaseIndex].journeyPhase);
      phase.id = this.sortedStages[this.selectedPhaseIndex].journeyPhase.id;
      this.computePhaseStepList(phase_index, this.sortedStages[this.selectedPhaseIndex].journeyPhase.id);
      this.computePhaseExperienceList(phase_index, this.sortedStages[this.selectedPhaseIndex].journeyPhase.id);
      this.phaseHover = false;
      this.isPhaseEdit = true;
      this.phase_form = phase;
      this.addPhaseDialog = true;
    },
    updatePhaseDetails: function updatePhaseDetails() {
      var phase = Object.assign({}, this.phase_form);
      this.$emit('edit-phase', phase);
      this.isPhaseEdit = false;
      this.addPhaseDialog = false;
      this.resetPhaseForm();
    },
    updateStepDetails: function updateStepDetails() {
      var _this8 = this;
      this.$refs.addStepform.validate(function (valid) {
        if (valid) {
          var step = Object.assign({}, _this8.step_form);
          _this8.$emit('edit-step', step);
          _this8.taskDetailsDialog = false;
          _this8.resetTaskForm();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    createTaskFromPhase: function createTaskFromPhase(phase_index) {
      this.selectedPhaseIndex = phase_index;
      this.phaseHover = false;
      this.addTaskDialog = true;
    },
    createPhase: function createPhase() {
      var _this9 = this;
      this.$refs.addStageform.validate(function (valid) {
        if (valid) {
          var phase = Object.assign({}, _this9.phase_form);
          var phase_drop_details = Object.assign({}, _this9.phaseDropDetails);
          _this9.$emit('add-phase', phase, phase_drop_details);
          _this9.addPhaseDialog = false;
          _this9.phaseDropDetails.index = 0;
          _this9.phaseDropDetails.isBefore = false;
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    createSubTask: function createSubTask() {
      var _this10 = this;
      this.$refs.addStepform.validate(function (valid) {
        if (valid) {
          var task = Object.assign({}, _this10.task_form);
          _this10.resetTaskForm();
          _this10.createSubTaskDialog = false;
          _this10.$emit('add-sub-step', task, _this10.journeyMap.linkedJourneySteps[_this10.selectedTaskIndex].journeyStep.id);
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    createTask: function createTask() {
      var _this11 = this;
      this.$refs.addStepform.validate(function (valid) {
        if (valid) {
          var task = Object.assign({}, _this11.task_form);
          _this11.resetTaskForm();
          _this11.addTaskDialog = false;
          _this11.$emit('add-step', task, _this11.sortedStages[_this11.selectedPhaseIndex].journeyPhase);
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    highlightActivePhase: function highlightActivePhase(phase_index) {
      this.activePhaseHighlighter = {
        x: this.computePhaseStartX(this.sortedStages[phase_index].journeyPhase, phase_index) - 3,
        y: 100,
        width: this.computePhaseLength(this.sortedStages[phase_index].journeyPhase, phase_index) + 26,
        height: 260
      };
    },
    highlightAllPhase: function highlightAllPhase() {
      this.phaseHighlighter = [];
      for (var i = 0; i < this.sortedStages.length; i++) {
        var highlight_object = {};
        highlight_object = {
          x: this.computePhaseStartX(this.sortedStages[i].journeyPhase, i) - 5,
          y: 17,
          width: this.computePhaseLength(this.sortedStages[i].journeyPhase, i) + 50,
          height: this.computeCanvasHeight * 0.9
        };
        this.phaseHighlighter.push(highlight_object);
      }
    },
    highlightActivePhaseGap: function highlightActivePhaseGap(start_x) {
      var width = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 25;
      this.activePhaseGapHighlighter = {
        x: start_x + 20,
        y: 17,
        width: width,
        height: this.computeCanvasHeight * 0.9
      };
    },
    highlightAllPhaseGap: function highlightAllPhaseGap() {
      if (this.phaseGapHighlighter.length === 0) {
        var start_x = this.computePhaseStartX(this.sortedStages[0].journeyPhase, 0);
        var gap_start_object = {};
        gap_start_object = {
          x: start_x - 30,
          y: 17,
          width: 25,
          height: this.computeCanvasHeight * 0.9
        };
        this.phaseGapHighlighter.push(gap_start_object);
        for (var i = 0; i < this.sortedStages.length; i++) {
          var phase_x = this.computePhaseStartX(this.sortedStages[i].journeyPhase, i);
          var phase_length = this.computePhaseLength(this.sortedStages[i].journeyPhase, i);
          var gap_object = {};
          gap_object = {
            x: phase_x + phase_length + 45,
            y: 17,
            width: i === this.sortedStages.length - 1 ? 1000 : 25,
            height: this.computeCanvasHeight * 0.9
          };
          this.phaseGapHighlighter.push(gap_object);
        }
      }
    },
    updateStepRelation: function updateStepRelation(source_step, relation_step_id) {
      var relations = [];
      if (source_step.relations !== null) {
        relations = source_step.relations;
      }
      relations.push(relation_step_id);
      this.$emit('update-task-to-workflow', source_step.id, relations);
    },
    // END UI METHODS
    // COMPUTED HELPERS
    isStageRelation: function isStageRelation(experience_index) {
      if (this.sortedStages.length === 0) {
        return false;
      }
      if (this.journeyMap.linkedJourneyExperiences[experience_index].journeyExperience.relations && this.journeyMap.linkedJourneyExperiences[experience_index].journeyExperience.relations.phase) {
        return true;
      }
      return false;
    },
    computePhaseStartX: function computePhaseStartX(phase, index) {
      if (this.isPhaseFocusMode) {
        return 175;
      }
      if (this.phase_pos_x.length === 0) {
        this.computePhaseX();
      }
      return this.phase_pos_x[index];
    },
    computeExperienceStartX: function computeExperienceStartX(index) {
      if (this.sortedStages.length === 0) {
        return 0;
      }
      if (this.journeyMap.linkedJourneyExperiences[index].journeyExperience.relations && this.journeyMap.linkedJourneyExperiences[index].journeyExperience.relations.phase) {
        var phase = this.computePhaseFromExperienceIndex(index);
        var phase_start_x = this.computePhaseStartX(phase[0], phase[1]);
        return phase_start_x - 20;
      } else if (this.journeyMap.linkedJourneyExperiences[index].journeyExperience.relations && this.journeyMap.linkedJourneyExperiences[index].journeyExperience.relations.step) {
        var task = this.getStepFromID(this.journeyMap.linkedJourneyExperiences[index].journeyExperience.relations.step);
        if (this.journeyMap.linkedJourneyExperiences[index].journeyExperience.experiencetype === 'PAIN') {
          return this.task_pos_x[task[1]] - 40;
        } else if (this.journeyMap.linkedJourneyExperiences[index].journeyExperience.experiencetype === 'GAIN') {
          return this.task_pos_x[task[1]] - 40;
        } else if (this.journeyMap.linkedJourneyExperiences[index].journeyExperience.experiencetype === 'WOW') {
          return this.task_pos_x[task[1]] - 40;
        } else {
          return this.task_pos_x[task[1]] - 40;
        }
      }
    },
    computePainStartX: function computePainStartX(index) {
      if (this.sortedStages.length === 0) {
        return 0;
      }
      if (this.journeyMap.linkedJourneyExperiences[index].journeyExperience.relations && this.journeyMap.linkedJourneyExperiences[index].journeyExperience.relations.phase) {
        var phase = this.computePhaseFromExperienceIndex(index);
        var phase_start_x = this.computePhaseStartX(phase[0], phase[1]);
        return phase_start_x;
      } else if (this.journeyMap.linkedJourneyExperiences[index].journeyExperience.relations && this.journeyMap.linkedJourneyExperiences[index].journeyExperience.relations.step) {
        var task = this.getStepFromID(this.journeyMap.linkedJourneyExperiences[index].journeyExperience.relations.step);
        return this.task_pos_x[task[1]] + 30;
      }
    },
    computeGainStartX: function computeGainStartX(index) {
      if (this.sortedStages.length === 0) {
        return 0;
      }
      if (this.journeyMap.linkedJourneyExperiences[index].journeyExperience.relations && this.journeyMap.linkedJourneyExperiences[index].journeyExperience.relations.phase) {
        var phase = this.computePhaseFromExperienceIndex(index);
        var phase_start_x = this.computePhaseStartX(phase[0], phase[1]);
        return phase_start_x;
      } else if (this.journeyMap.linkedJourneyExperiences[index].journeyExperience.relations && this.journeyMap.linkedJourneyExperiences[index].journeyExperience.relations.step) {
        var task = this.getStepFromID(this.journeyMap.linkedJourneyExperiences[index].journeyExperience.relations.step);
        return this.task_pos_x[task[1]] + 30;
      }
    },
    computeWowStartX: function computeWowStartX(index) {
      if (this.sortedStages.length === 0) {
        return 0;
      }
      if (this.journeyMap.linkedJourneyExperiences[index].journeyExperience.relations && this.journeyMap.linkedJourneyExperiences[index].journeyExperience.relations.phase) {
        var phase = this.computePhaseFromExperienceIndex(index);
        var phase_start_x = this.computePhaseStartX(phase[0], phase[1]);
        return phase_start_x;
      } else if (this.journeyMap.linkedJourneyExperiences[index].journeyExperience.relations && this.journeyMap.linkedJourneyExperiences[index].journeyExperience.relations.step) {
        var task = this.getStepFromID(this.journeyMap.linkedJourneyExperiences[index].journeyExperience.relations.step);
        return this.task_pos_x[task[1]] + 30;
      }
    },
    computeChallengeStartX: function computeChallengeStartX(index) {
      if (this.sortedStages.length === 0) {
        return 0;
      }
      if (this.journeyMap.linkedJourneyExperiences[index].journeyExperience.relations && this.journeyMap.linkedJourneyExperiences[index].journeyExperience.relations.phase) {
        var phase = this.computePhaseFromExperienceIndex(index);
        var phase_start_x = this.computePhaseStartX(phase[0], phase[1]);
        return phase_start_x;
      } else if (this.journeyMap.linkedJourneyExperiences[index].journeyExperience.relations && this.journeyMap.linkedJourneyExperiences[index].journeyExperience.relations.step) {
        var task = this.getStepFromID(this.journeyMap.linkedJourneyExperiences[index].journeyExperience.relations.step);
        return this.task_pos_x[task[1]] + 30;
      }
    },
    computeExperienceStartY: function computeExperienceStartY(index) {
      var experience_id = this.journeyMap.linkedJourneyExperiences[index].journeyExperience.id;

      // Split experience array to two. This is to differentiate two different rendering algo
      var phase_experiences = [];
      var step_experiences = [];
      var steps = [];
      var phase_steps = [];
      var phase_steps_chunked = [];
      if (this.journeyMap.linkedJourneyExperiences[index].journeyExperience.relations && this.journeyMap.linkedJourneyExperiences[index].journeyExperience.relations.phase) {
        var phase_id = this.journeyMap.linkedJourneyExperiences[index].journeyExperience.relations.phase;
        for (var j = 0; j < this.journeyMap.linkedJourneyExperiences.length; j++) {
          if (this.journeyMap.linkedJourneyExperiences[j].journeyExperience && this.journeyMap.linkedJourneyExperiences[j].journeyExperience.relations && this.journeyMap.linkedJourneyExperiences[j].journeyExperience.relations.phase && this.journeyMap.linkedJourneyExperiences[j].journeyExperience.relations.phase === phase_id) {
            phase_experiences.push(this.journeyMap.linkedJourneyExperiences[j]);
          }
        }
      } else if (this.journeyMap.linkedJourneyExperiences[index].journeyExperience.relations && this.journeyMap.linkedJourneyExperiences[index].journeyExperience.relations.step) {
        phase_steps = this.getPhaseFromStepID(this.journeyMap.linkedJourneyExperiences[index].journeyExperience.relations.step)[0].relations;
        var step_id = this.journeyMap.linkedJourneyExperiences[index].journeyExperience.relations.step;
        for (var i = 0; i < this.journeyMap.linkedJourneyExperiences.length; i++) {
          if (this.taskHover && this.selectedTask && this.selectedTask.journeyStep && this.selectedTask.journeyStep.id === this.journeyMap.linkedJourneyExperiences[i].journeyExperience.relations.step) {
            if (this.journeyMap.linkedJourneyExperiences[i].journeyExperience && this.journeyMap.linkedJourneyExperiences[i].journeyExperience.relations && this.journeyMap.linkedJourneyExperiences[i].journeyExperience.relations.step) {
              steps.push(this.journeyMap.linkedJourneyExperiences[i].journeyExperience.relations.step);
            }
            if (this.journeyMap.linkedJourneyExperiences[i].journeyExperience && this.journeyMap.linkedJourneyExperiences[i].journeyExperience.relations && this.journeyMap.linkedJourneyExperiences[i].journeyExperience.relations.step && this.journeyMap.linkedJourneyExperiences[i].journeyExperience.relations.step === step_id) {
              step_experiences.push(this.journeyMap.linkedJourneyExperiences[i]);
            }
          } else {
            if (this.journeyMap.linkedJourneyExperiences[i].journeyExperience && this.journeyMap.linkedJourneyExperiences[i].journeyExperience.relations && this.journeyMap.linkedJourneyExperiences[i].journeyExperience.relations.step) {
              steps.push(this.journeyMap.linkedJourneyExperiences[i].journeyExperience.relations.step);
            }
            if (this.journeyMap.linkedJourneyExperiences[i].journeyExperience && this.journeyMap.linkedJourneyExperiences[i].journeyExperience.relations && this.journeyMap.linkedJourneyExperiences[i].journeyExperience.relations.step && this.journeyMap.linkedJourneyExperiences[i].journeyExperience.relations.step === step_id) {
              step_experiences.push(this.journeyMap.linkedJourneyExperiences[i]);
            }
          }
        }
      }

      // Chunk step list for given phase into chunks of 5. (total number of rows in steps)
      // This will be used to find index of
      phase_steps_chunked = (0, _utils.sliceArrayIntoChunks)(phase_steps, 7);

      // Experience for a phase are as is, since its just one column
      for (var k = 0; k < phase_experiences.length; k++) {
        if (phase_experiences[k].journeyExperience.id === experience_id) {
          if (this.isExperienceLaneMinimized) {
            if (phase_experiences[k].journeyExperience.experiencetype === 'PAIN') {
              return 375;
            } else if (phase_experiences[k].journeyExperience.experiencetype === 'GAIN') {
              return 405;
            } else if (phase_experiences[k].journeyExperience.experiencetype === 'WOW') {
              return 435;
            } else if (phase_experiences[k].journeyExperience.experiencetype === 'CHALLENGE') {
              return 465;
            }
          } else {
            if (this.journeyMap.linkedJourneyExperiences[index].journeyExperience && this.journeyMap.linkedJourneyExperiences[index].journeyExperience.relations && this.journeyMap.linkedJourneyExperiences[index].journeyExperience.relations.phase) {
              return k * 30 + 375;
            }
          }
        }
      }

      // Experience for a step is compared with phase_steps_chunked to find which column to use
      // Pseudo Code
      /*
        If lane minimised handle seperate
        else
          chk which phase_step_chunked sub array has the step id
          get the experience list of tat chunk
          use tat chunk index to render
      */
      for (var m = 0; m < step_experiences.length; m++) {
        if (step_experiences[m].journeyExperience.id === experience_id) {
          if (this.isExperienceLaneMinimized) {
            if (step_experiences[m].journeyExperience.experiencetype === 'PAIN') {
              return 375;
            } else if (step_experiences[m].journeyExperience.experiencetype === 'GAIN') {
              return 405;
            } else if (step_experiences[m].journeyExperience.experiencetype === 'WOW') {
              return 435;
            } else if (step_experiences[m].journeyExperience.experiencetype === 'CHALLENGE') {
              return 465;
            }
          } else {
            if (this.journeyMap.linkedJourneyExperiences[index].journeyExperience && this.journeyMap.linkedJourneyExperiences[index].journeyExperience.relations && this.journeyMap.linkedJourneyExperiences[index].journeyExperience.relations.step) {
              for (var l = 0; l < phase_steps_chunked.length; l++) {
                if (phase_steps_chunked[l].indexOf(this.journeyMap.linkedJourneyExperiences[index].journeyExperience.relations.step) > -1) {
                  var phase_steps_chunk_experience_list = this.computeExperienceListFromStepChunk(phase_steps_chunked[l]);
                  return 375 + phase_steps_chunk_experience_list.indexOf(this.journeyMap.linkedJourneyExperiences[index].journeyExperience.id) * 30;
                }
              }
            }
          }
        }
      }
    },
    computeExperienceListFromStepChunk: function computeExperienceListFromStepChunk(step_list) {
      var exp_arr = [];
      for (var i = 0; i < step_list.length; i++) {
        for (var j = 0; j < this.journeyMap.linkedJourneyExperiences.length; j++) {
          if (this.journeyMap.linkedJourneyExperiences[j].journeyExperience.relations && this.journeyMap.linkedJourneyExperiences[j].journeyExperience.relations.step && this.journeyMap.linkedJourneyExperiences[j].journeyExperience.relations.step === step_list[i]) {
            exp_arr.push(this.journeyMap.linkedJourneyExperiences[j].journeyExperience.id);
          }
        }
      }
      return exp_arr;
    },
    computePhaseLengthFromPhaseID: function computePhaseLengthFromPhaseID(phase_id) {
      var phase = this.getPhaseFromID(phase_id);
      return this.computePhaseLength(phase[0], phase[1]);
    },
    computePhaseLength: function computePhaseLength(phase, index) {
      // full width phase if focus mode is active
      if (this.isPhaseFocusMode) {
        return 1250;
      }
      // get max number of features in product for given phase
      // if less than 2 use default 175, else use length off features
      var max_count = 0;

      // Get an array of pains/gains for current phase
      if (typeof phase === 'undefined') {
        return 225;
      }
      if (phase.additionalattributes && phase.additionalattributes.ui.isCollapse) {
        return 50;
      }
      if (phase.relations !== null) {
        max_count = phase.relations.length;
      }

      // Add experience to phase relations
      /* this._.forEach(this.journeyMap.linkedJourneyExperiences, function(experience) {
        if (experience.journeyExperience.relations && experience.journeyExperience.relations.phase === phase.id) {
          max_count++
        }
      })*/
      // TODO handle products and features once update apis are up

      /* for (var j = 0; j < this.journey.products.length; j++) {
        // Quicken search by searching only first feature of the product.
        // Can update the algo if required.
        if (this.journey.products[j].features.length === 0) {
          break
        } else if (experience_arr.includes(this.journey.products[j].features[0].type_name)) {
          if (max_count < this.journey.products[j].features.length) { max_count = this.journey.products[j].features.length }
        }
      }*/
      var additional_columns = Math.floor(max_count / 7.5) - 1;
      var first_column = 150;
      return 225 + first_column + additional_columns * 150;
      /* if (max_count < 8) {
        return 225
      } else {
        return 225 + (Math.floor(max_count / 7)) * 150
      }*/
    },
    computeTaskPosX: function computeTaskPosX(index) {
      var recompute = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      if (this.computedTaskList.length === 0) {
        return 0;
      }
      if (this.task_pos_x.length === 0 || recompute) {
        this.computeTaskX();
      }
      return this.task_pos_x[index];
    },
    computeTaskPosY: function computeTaskPosY(index) {
      if (this.task_pos_y.length === 0) {
        this.computeTaskY();
      }
      return this.task_pos_y[index];
    },
    computeTaskPosXFromTaskID: function computeTaskPosXFromTaskID(step_id) {
      var step = this.getStepFromID(step_id);
      if (step[1] !== null) {
        return this.computeTaskPosX(step[1]);
      }
      return 0;
    },
    computeTaskPosYFromTaskID: function computeTaskPosYFromTaskID(step_id) {
      var step = this.getStepFromID(step_id);
      if (step[1] !== null) {
        return this.computeTaskPosY(step[1]);
      }
      return 0;
    },
    computePhasePosXFromPhaseID: function computePhasePosXFromPhaseID(phase_id) {
      var phase = this.getPhaseFromID(phase_id);
      if (phase[1] !== null) {
        return this.computePhaseStartX(phase[0], phase[1]);
      }
      return 0;
    },
    computePhasePosYFromPhaseID: function computePhasePosYFromPhaseID(phase_id) {
      return 40;
    },
    computePainGainRelationDetailsPosX: function computePainGainRelationDetailsPosX(x) {
      if (x - 150 < 0) {
        return 150;
      } else {
        return x;
      }
    },
    computeJourneyStepDisplayCondition: function computeJourneyStepDisplayCondition(task, index) {
      // Handle Focus Mode
      if (this.isPhaseFocusMode) {
        return this.selectedPhase.journeyPhase.relations.includes(task.journeyStep.id);
      }

      // Phase Collapse Mode
      for (var i = 0; i < this.sortedStages.length; i++) {
        if (this.sortedStages[i].journeyPhase.additionalattributes.ui.isCollapse && this.sortedStages[i].journeyPhase.relation && this.sortedStages[i].journeyPhase.relations.includes(task.journeyStep.id)) {
          return false;
        }
      }
      return true;
    },
    // END COMPUTED HELPERS
    // DRAG DROP METHODS
    handleDrop: function handleDrop(event) {
      event.preventDefault(); // !important
      this.highlight = null;
    },
    handleDragOver: function handleDragOver(event) {
      event.preventDefault(); // !important
    },
    onStepContainerDragged: function onStepContainerDragged(_ref) {
      var el = _ref.el,
        deltaX = _ref.deltaX,
        deltaY = _ref.deltaY,
        offsetX = _ref.offsetX,
        offsetY = _ref.offsetY,
        clientX = _ref.clientX,
        clientY = _ref.clientY,
        first = _ref.first,
        last = _ref.last;
      if (last !== undefined && last) {
        return;
      }
      if (deltaX === undefined || deltaY === undefined) {
        return;
      }
      this.canvasDragCoefficientX += deltaX;
      this.computePhaseX();
    },
    onPhaseDragged: function onPhaseDragged(_ref2) {
      var el = _ref2.el,
        deltaX = _ref2.deltaX,
        deltaY = _ref2.deltaY,
        offsetX = _ref2.offsetX,
        offsetY = _ref2.offsetY,
        clientX = _ref2.clientX,
        clientY = _ref2.clientY,
        first = _ref2.first,
        last = _ref2.last;
      var index = parseInt(el.id.replace('phase_', ''));
      if (last !== undefined && last) {
        this.resetHighlightActivePhaseBlockGap();
        var isPosChange = false;
        var newPos = 0;
        // if dragged phase is between any other phase
        // replace position
        var originalPhaseX = [];
        for (var i = 0; i < this.sortedStages.length; i++) {
          if (i === 0) {
            originalPhaseX.push(190);
          } else {
            var length = 0;
            for (var j = 0; j < i; j++) {
              length += this.computePhaseLength(this.sortedStages[j].journeyPhase, j);
              length += 75;
            }
            originalPhaseX.push(190 + length);
          }
        }
        for (var k = 0; k < originalPhaseX.length; k++) {
          if (this.phase_pos_x[index] > originalPhaseX[k] + this.computePhaseLength(this.sortedStages[k].journeyPhase, k) && this.phase_pos_x[index] < originalPhaseX[k + 1] && k < originalPhaseX.length - 1) {
            isPosChange = true;
            newPos = k;
          } else if (this.phase_pos_x[index] > originalPhaseX[k] + this.computePhaseLength(this.sortedStages[k].journeyPhase, k) && k === originalPhaseX.length - 1) {
            isPosChange = true;
            newPos = k;
          }
        }
        if (isPosChange) {
          var stagesSortOrder = [];
          if (this.journeyMap.additionalattributes) {
            stagesSortOrder = Object.values(this.journeyMap.additionalattributes.stagesSortOrder);
            var temp = stagesSortOrder[index];
            if (newPos > index) {
              stagesSortOrder.splice(index, 1);
              stagesSortOrder.splice(newPos, 0, temp);
            } else {
              stagesSortOrder.splice(index, 1);
              stagesSortOrder.splice(newPos + 1, 0, temp);
            }
          } else {
            stagesSortOrder.push(this.sortedStages[newPos].journeyPhase.id);
            stagesSortOrder.push(this.sortedStages[index].journeyPhase.id);
          }
          this.$emit('edit-phase-sort-order', this.journeyMap, stagesSortOrder);
        } else {
          // else reset position
          this.computePhaseX();
        }
      }

      // Recompute task posX on drag event
      this.computeTaskX();

      // if long press is active handle code
      if (this.phaseLongPress) {
        if (deltaX === undefined || deltaY === undefined) {
          return;
        }
        this.phase_pos_x[index] += this.computeZoomCoefficient(deltaX);

        // if dragged phase is between any other phase
        // replace position
        var originalPhaseXTemp = [];
        for (var l = 0; l < this.sortedStages.length; l++) {
          if (l === 0) {
            originalPhaseXTemp.push(190);
          } else {
            var lengthTemp = 0;
            for (var m = 0; m < l; m++) {
              lengthTemp += this.computePhaseLength(this.sortedStages[m].journeyPhase, m);
              lengthTemp += 75;
            }
            originalPhaseXTemp.push(190 + lengthTemp);
          }
        }
        for (var n = 0; n < originalPhaseXTemp.length; n++) {
          if (this.phase_pos_x[index] > originalPhaseXTemp[n] + this.computePhaseLength(this.sortedStages[n].journeyPhase, n) && this.phase_pos_x[index] < originalPhaseXTemp[n + 1] && n < originalPhaseXTemp.length - 1) {
            this.highlightActivePhaseGap(originalPhaseXTemp[n] + this.computePhaseLength(this.sortedStages[n].journeyPhase, n) + 25, 25);
          } else if (this.phase_pos_x[index] > originalPhaseXTemp[n] + this.computePhaseLength(this.sortedStages[n].journeyPhase, n) && n === originalPhaseXTemp.length - 1) {
            this.highlightActivePhaseGap(originalPhaseXTemp[n] + this.computePhaseLength(this.sortedStages[n].journeyPhase, n) + 25, 1000);
          }
        }

        /*
          // Active highlight code to highlight on phase gaps
        // Logic needs to be refined to highlight gaps other than adjacent gaps to current selected phase
          var start_x = 0
        if (this.sortedStages.length > 0) {
          start_x = this.computePhaseStartX(this.sortedStages[0].journeyPhase, 0)
        }
          if (this.phase_pos_x[index] > (start_x - 50) && this.phase_pos_x[index] < start_x) {
          this.highlightActivePhaseGap(start_x - 50, 35)
        }
          for (var l = 0; l < this.sortedStages.length; l++) {
          var phase_x = this.computePhaseStartX(this.sortedStages[l].journeyPhase, l)
          var phase_length = this.computePhaseLength(this.sortedStages[l].journeyPhase, l)
            if (this.phase_pos_x[index] > (phase_x + phase_length) && this.phase_pos_x[index] < (phase_x + phase_length + 50)) {
            if (l < this.sortedStages.length - 1) {
              this.highlightActivePhaseGap(phase_x + phase_length + 25, 35)
            }
          }
            if (this.phase_pos_x[index] > (phase_x + phase_length) && this.phase_pos_x[index] < (phase_x + phase_length + 1000)) {
            if (l === this.sortedStages.length - 1) {
              this.highlightActivePhaseGap(phase_x + phase_length + 25, 1000)
            }
          }
        }
        */
      }
    },
    onPhaseToolDragged: function onPhaseToolDragged(_ref3) {
      var el = _ref3.el,
        deltaX = _ref3.deltaX,
        deltaY = _ref3.deltaY,
        offsetX = _ref3.offsetX,
        offsetY = _ref3.offsetY,
        clientX = _ref3.clientX,
        clientY = _ref3.clientY,
        first = _ref3.first,
        last = _ref3.last;
      if (last !== undefined && last) {
        this.resetHighlightActivePhaseBlockGap();
        this.resetHighlightAllPhaseBlockGap();
        if (this.toolbox.phase.x > 120 && this.toolbox.phase.x < 2920 && this.toolbox.phase.y > 0 && this.toolbox.phase.y < 600) {
          this.resetPhaseForm();
          // Read position from phase gap
          // Add position of phase to be added
          this.selectedPhaseIndex = null;
          this.selectedPhase = {};
          this.selectedPhaseStepList = [];
          this.isPhaseEdit = false;
          this.addPhaseDialog = true;
        }
        this.resetTools();
      }
      if (deltaX === undefined || deltaY === undefined) {
        return;
      }
      this.resetHoverDetailsDialog();
      this.toolbox.phase.x += this.computeZoomCoefficient(deltaX);
      this.toolbox.phase.y += this.computeZoomCoefficient(deltaY);
      var start_x = 0;
      if (this.sortedStages.length > 0) {
        start_x = this.computePhaseStartX(this.sortedStages[0].journeyPhase, 0);
        this.highlightAllPhaseGap();
        if (this.toolbox.phase.x > start_x - 50 && this.toolbox.phase.x < start_x) {
          if (this.toolbox.phase.y > 0 && this.toolbox.phase.y < 600) {
            this.highlightActivePhaseGap(start_x - 50, 25);
            this.phaseDropDetails.index = 0;
            this.phaseDropDetails.isBefore = true;
          }
        }
        for (var i = 0; i < this.sortedStages.length; i++) {
          var phase_x = this.computePhaseStartX(this.sortedStages[i].journeyPhase, i);
          var phase_y = 0;
          var phase_length = this.computePhaseLength(this.sortedStages[i].journeyPhase, i);
          if (this.toolbox.phase.x > phase_x + phase_length && this.toolbox.phase.x < phase_x + phase_length + 50) {
            if (this.toolbox.phase.y > phase_y && this.toolbox.phase.y < 600 && i < this.sortedStages.length - 1) {
              this.highlightActivePhaseGap(phase_x + phase_length + 25, 25);
              this.phaseDropDetails.index = i;
              this.phaseDropDetails.isBefore = false;
            }
          }
          if (this.toolbox.phase.x > phase_x + phase_length && this.toolbox.phase.x < phase_x + phase_length + 1000) {
            if (this.toolbox.phase.y > phase_y && this.toolbox.phase.y < 600 && i === this.sortedStages.length - 1) {
              this.highlightActivePhaseGap(phase_x + phase_length + 25, 1000);
              this.phaseDropDetails.index = i;
              this.phaseDropDetails.isBefore = false;
            }
          }
        }
      } else if (this.toolbox.phase.x > 120 && this.toolbox.phase.x < 2920 && this.toolbox.phase.y > 0 && this.toolbox.phase.y < 600) {
        this.highlightActivePhaseGap(105, this.computeCanvasWidth);
        this.phaseDropDetails.index = 0;
        this.phaseDropDetails.isBefore = false;
      }
    },
    onTaskToolDragged: function onTaskToolDragged(_ref4) {
      var el = _ref4.el,
        deltaX = _ref4.deltaX,
        deltaY = _ref4.deltaY,
        offsetX = _ref4.offsetX,
        offsetY = _ref4.offsetY,
        clientX = _ref4.clientX,
        clientY = _ref4.clientY,
        first = _ref4.first,
        last = _ref4.last;
      if (last !== undefined && last) {
        this.resetHighlightAllPhaseHighlighter();
        this.resetHighlightActivePhaseBlock();
        var step_creation_flag = false;
        for (var j = 0; j < this.sortedStages.length; j++) {
          var phase_end_x = this.computePhaseStartX(this.sortedStages[j].journeyPhase, j);
          var phase_end_y = 40;
          var phase_end_length = this.computePhaseLength(this.sortedStages[j].journeyPhase, j);
          if (this.toolbox.task.x > phase_end_x && this.toolbox.task.x < phase_end_x + phase_end_length) {
            if (this.toolbox.task.y > phase_end_y && this.toolbox.task.y < 300) {
              step_creation_flag = true;
              this.createTaskFromPhase(j);
            }
          }
        }
        if (!step_creation_flag) {
          this.$message('Please drag the step below a stage, not outside of one');
        }
        this.resetTools();
      }
      if (deltaX === undefined || deltaY === undefined) {
        return;
      }
      this.resetHoverDetailsDialog();
      this.toolbox.task.x += this.computeZoomCoefficient(deltaX);
      this.toolbox.task.y += this.computeZoomCoefficient(deltaY);
      if (this.sortedStages.length > 0) {
        this.highlightAllPhase();
      }
      for (var i = 0; i < this.sortedStages.length; i++) {
        var phase_x = this.computePhaseStartX(this.sortedStages[i].journeyPhase, i);
        var phase_y = 40;
        var phase_length = this.computePhaseLength(this.sortedStages[i].journeyPhase, i);
        if (this.toolbox.task.x > phase_x && this.toolbox.task.x < phase_x + phase_length) {
          if (this.toolbox.task.y > phase_y && this.toolbox.task.y < 300) {
            this.highlightActivePhase(i);
          }
        }
      }
    },
    onTaskDragged: function onTaskDragged(index, el, deltaX, deltaY, offsetX, offsetY, clientX, clientY, first, last) {
      if (last !== undefined && last) {
        // check if x pos has changed to new stage. if yes, update relation

        // Get current related phase_id
        var currentPhase = this.getPhaseFromStepID(this.journeyMap.linkedJourneySteps[index].journeyStep.id);
        var activePhase = this.getPhaseFromCursorX(this.task_pos_x[index]);
        if (currentPhase[1] !== null && activePhase[1] !== null && currentPhase[1] !== activePhase[1]) {
          this.$emit('swap-phase-step-relation', this.journeyMap.linkedJourneySteps[index].journeyStep.id, currentPhase[0], activePhase[0]);
        }
        this.computeTaskY();
        this.computeTaskX();
        this.resetHighlightActivePhaseBlock();
      }
      if (deltaX === undefined || deltaY === undefined) {
        return;
      }
      this.resetHoverDetailsDialog();

      // TODO prevent it moving out of phase. last phase is the limit of x movement
      this.task_pos_x[index] += this.computeZoomCoefficient(deltaX);
    },
    onTaskWorkFlowDrag: function onTaskWorkFlowDrag(index, el, deltaX, deltaY, offsetX, offsetY, clientX, clientY, first, last) {
      if (first !== undefined && first) {
        this.relationsHoverTaskID = '';
        this.resetTempTaskRelation();
      }
      if (last !== undefined && last) {
        if (this.tempTaskRelationPath.x1 > 0 && this.tempTaskRelationPath.y1 > 0) {
          // check if overlap with any existing task
          for (var i = 0; i < this.computedTaskList.length; i++) {
            if (this.tempTaskRelationPath.x2 > this.computeTaskPosX(i) && this.tempTaskRelationPath.x2 < this.computeTaskPosX(i) + 50 && this.tempTaskRelationPath.y2 > this.computeTaskPosY(i) && this.tempTaskRelationPath.y2 < this.computeTaskPosY(i) + 50 && this.computedTaskList[i].journeyStep.id !== this.computedTaskList[index].journeyStep.id) {
              this.updateStepRelation(this.computedTaskList[index].journeyStep, this.computedTaskList[i].journeyStep.id);
            }
          }
        }
        this.relationsHoverTaskID = '';
        this.resetTempTaskRelation();
      }
      if (deltaX === undefined || deltaY === undefined) {
        return;
      }
      this.tempTaskRelationPath = {
        x1: this.computeTaskPosX(index) + 110,
        y1: this.computeTaskPosY(index) + 15,
        x2: this.computeTaskPosX(index) + offsetX,
        y2: this.computeTaskPosY(index) + offsetY
      };
      if (this.tempTaskRelationPath.x1 > 0 && this.tempTaskRelationPath.y1 > 0) {
        // check if overlap with any existing task
        for (var j = 0; j < this.computedTaskList.length; j++) {
          if (this.tempTaskRelationPath.x2 > this.computeTaskPosX(j) && this.tempTaskRelationPath.x2 < this.computeTaskPosX(j) + 50 && this.tempTaskRelationPath.y2 > this.computeTaskPosY(j) && this.tempTaskRelationPath.y2 < this.computeTaskPosY(j) + 50 && this.computedTaskList[j].journeyStep.id !== this.computedTaskList[index].journeyStep.id) {
            this.relationsHoverTaskID = this.computedTaskList[j].journeyStep.id;
          } else {
            this.relationsHoverTaskID = '';
          }
        }

        //
      }
      this.computeTempTaskRelation();
    },
    /*
      onGainToolDragged({ el, deltaX, deltaY, offsetX, offsetY, clientX, clientY, first, last }) {
      if (last !== undefined && last) {
        var isStepOverlap = false
        for (var j = 0; j < this.journeyMap.linkedJourneySteps.length; j++) {
          if (this.toolbox.gain.x > this.computeTaskPosX(j) &&
              this.toolbox.gain.x < this.computeTaskPosX(j) + 50 &&
              this.toolbox.gain.y > this.computeTaskPosY(j) &&
              this.toolbox.gain.y < this.computeTaskPosY(j) + 50) {
            isStepOverlap = true
            this.createGainFromTask(j)
          }
        }
          // Run code only if step overlap does not occur
        // Save some CPU
        if (!isStepOverlap) {
          for (var k = 0; k < this.sortedStages.length; k++) {
            if (this.toolbox.gain.x > this.computePhaseStartX(this.sortedStages[k].journeyPhase, k) &&
                this.toolbox.gain.x < this.computePhaseStartX(this.sortedStages[k].journeyPhase, k) + this.computePhaseLength(this.sortedStages[k].journeyPhase, k) &&
                this.toolbox.gain.y > 40 &&
                this.toolbox.gain.y < 90) {
              this.createGainFromPhase(k)
            }
          }
        }
        this.resetTools()
      }
      if (deltaX === undefined || deltaY === undefined) {
        return
      }
        this.resetHoverDetailsDialog()
        this.toolbox.gain.x += this.computeZoomCoefficient(deltaX)
      this.toolbox.gain.y += this.computeZoomCoefficient(deltaY)
    },
    onPainToolDragged({ el, deltaX, deltaY, offsetX, offsetY, clientX, clientY, first, last }) {
      if (last !== undefined && last) {
        var isStepOverlap = false
        for (var j = 0; j < this.journeyMap.linkedJourneySteps.length; j++) {
          if (this.toolbox.pain.x > this.computeTaskPosX(j) &&
              this.toolbox.pain.x < this.computeTaskPosX(j) + 50 &&
              this.toolbox.pain.y > this.computeTaskPosY(j) &&
              this.toolbox.pain.y < this.computeTaskPosY(j) + 50) {
            isStepOverlap = true
            this.createPainFromTask(j)
          }
        }
        // Run code only if step overlap does not occur
        // Save some CPU
        if (!isStepOverlap) {
          for (var k = 0; k < this.sortedStages.length; k++) {
            if (this.toolbox.pain.x > this.computePhaseStartX(this.sortedStages[k].journeyPhase, k) &&
                this.toolbox.pain.x < this.computePhaseStartX(this.sortedStages[k].journeyPhase, k) + this.computePhaseLength(this.sortedStages[k].journeyPhase, k) &&
                this.toolbox.pain.y > 40 &&
                this.toolbox.pain.y < 90) {
              this.createGainFromPhase(k)
            }
          }
        }
        this.resetTools()
      }
      if (deltaX === undefined || deltaY === undefined) {
        return
      }
        this.resetHoverDetailsDialog()
        this.toolbox.pain.x += this.computeZoomCoefficient(deltaX)
      this.toolbox.pain.y += this.computeZoomCoefficient(deltaY)
    },*/
    onJourneyIdeaToolDragged: function onJourneyIdeaToolDragged(_ref5) {
      var el = _ref5.el,
        deltaX = _ref5.deltaX,
        deltaY = _ref5.deltaY,
        offsetX = _ref5.offsetX,
        offsetY = _ref5.offsetY,
        clientX = _ref5.clientX,
        clientY = _ref5.clientY,
        first = _ref5.first,
        last = _ref5.last;
      if (first !== undefined && first) {
        this.relationsHoverTaskID = '';
        this.resetTempTaskRelation();
      }
      if (last !== undefined && last) {
        var ideaHover = '';
        this.relationsHoverTaskID = '';
        this.phaseHoverIdeaID = '';
        this.isJourneyHighlight = false;
        for (var j = 0; j < this.journeyMap.linkedJourneySteps.length; j++) {
          if (this.toolbox.journey_idea.x > this.computeTaskPosX(j) && this.toolbox.journey_idea.x < this.computeTaskPosX(j) + 100 && this.toolbox.journey_idea.y > this.computeTaskPosY(j) - 50 && this.toolbox.journey_idea.y < this.computeTaskPosY(j)) {
            ideaHover = 'step';
            this.idea.scope = 'Step';
            this.idea.scopename = this.journeyMap.linkedJourneySteps[j].journeyStep.title;
            this.createJourneyIdeaDialog = true;
          }
        }
        // Run code only if step overlap does not occur
        // Save some CPU
        if (ideaHover === '') {
          for (var k = 0; k < this.sortedStages.length; k++) {
            if (this.toolbox.journey_idea.x > this.computePhaseStartX(this.sortedStages[k].journeyPhase, k) && this.toolbox.journey_idea.x < this.computePhaseStartX(this.sortedStages[k].journeyPhase, k) + this.computePhaseLength(this.sortedStages[k].journeyPhase, k) && this.toolbox.journey_idea.y > 40 && this.toolbox.journey_idea.y < 90) {
              ideaHover = 'phase';
              this.idea.scope = 'Phase';
              this.idea.scopename = this.sortedStages[k].journeyPhase.title;
              this.createJourneyIdeaDialog = true;
            }
          }
        }
        if (ideaHover === '') {
          this.idea.scope = 'Journey';
          this.idea.scopename = this.journeyMap.title;
          this.createJourneyIdeaDialog = true;
        }
        this.resetTools();
      }
      if (deltaX === undefined || deltaY === undefined) {
        return;
      }
      this.resetHoverDetailsDialog();
      this.toolbox.journey_idea.x += this.computeZoomCoefficient(deltaX);
      this.toolbox.journey_idea.y += this.computeZoomCoefficient(deltaY);
      var ideaDynamicHover = '';
      this.relationsHoverTaskID = '';
      this.phaseHoverIdeaID = '';
      this.isJourneyHighlight = false;
      this.resetTempTaskRelation();
      for (var l = 0; l < this.journeyMap.linkedJourneySteps.length; l++) {
        if (this.toolbox.journey_idea.x > this.computeTaskPosX(l) && this.toolbox.journey_idea.x < this.computeTaskPosX(l) + 100 && this.toolbox.journey_idea.y > this.computeTaskPosY(l) - 50 && this.toolbox.journey_idea.y < this.computeTaskPosY(l)) {
          ideaDynamicHover = 'step';
          this.relationsHoverTaskID = this.journeyMap.linkedJourneySteps[l].journeyStep.id;
        }
      }
      // Run code only if step overlap does not occur
      // Save some CPU
      if (ideaDynamicHover === '') {
        for (var m = 0; m < this.sortedStages.length; m++) {
          if (this.toolbox.journey_idea.x > this.computePhaseStartX(this.sortedStages[m].journeyPhase, m) && this.toolbox.journey_idea.x < this.computePhaseStartX(this.sortedStages[m].journeyPhase, m) + this.computePhaseLength(this.sortedStages[m].journeyPhase, m) && this.toolbox.journey_idea.y > 40 && this.toolbox.journey_idea.y < 90) {
            ideaDynamicHover = 'phase';
            this.phaseHoverIdeaID = this.sortedStages[m].journeyPhase.id;
          }
        }
      }
      if (ideaDynamicHover === '') {
        this.isJourneyHighlight = true;
      }
    },
    onWowToolDragged: function onWowToolDragged(_ref6) {
      var el = _ref6.el,
        deltaX = _ref6.deltaX,
        deltaY = _ref6.deltaY,
        offsetX = _ref6.offsetX,
        offsetY = _ref6.offsetY,
        clientX = _ref6.clientX,
        clientY = _ref6.clientY,
        first = _ref6.first,
        last = _ref6.last;
      if (last !== undefined && last) {
        var isStepOverlap = false;
        for (var j = 0; j < this.journeyMap.linkedJourneySteps.length; j++) {
          if (this.toolbox.wow.x > this.computeTaskPosX(j) && this.toolbox.wow.x < this.computeTaskPosX(j) + 50 && this.toolbox.wow.y > this.computeTaskPosY(j) && this.toolbox.wow.y < this.computeTaskPosY(j) + 50) {
            isStepOverlap = true;
            this.createWowFromTask(j);
          }
        }
        // Run code only if step overlap does not occur
        // Save some CPU
        if (!isStepOverlap) {
          for (var k = 0; k < this.sortedStages.length; k++) {
            if (this.toolbox.wow.x > this.computePhaseStartX(this.sortedStages[k].journeyPhase, k) && this.toolbox.wow.x < this.computePhaseStartX(this.sortedStages[k].journeyPhase, k) + this.computePhaseLength(this.sortedStages[k].journeyPhase, k) && this.toolbox.wow.y > 40 && this.toolbox.wow.y < 90) {
              this.createGainFromPhase(k);
            }
          }
        }
        this.resetTools();
      }
      if (deltaX === undefined || deltaY === undefined) {
        return;
      }
      this.resetHoverDetailsDialog();
      this.toolbox.wow.x += this.computeZoomCoefficient(deltaX);
      this.toolbox.wow.y += this.computeZoomCoefficient(deltaY);
    },
    // END DRAG DROP METHODS
    // HELPER METHODS
    computePhaseIndexforTask: function computePhaseIndexforTask(task, index) {
      if (this.isPhaseFocusMode && this.journeyMap && this.journeyMap.linkedJourneySteps) {
        for (var k = 0; k < this.selectedPhase.relations.length; k++) {
          if (this.selectedPhase.relations[k] === task.journeyStep.id) {
            return this.selectedPhase.relations.indexOf(task.journeyStep.id) + 1;
          }
        }
      } else if (this.journeyMap && this.journeyMap.linkedJourneySteps) {
        for (var m = 0; m < this.sortedStages.length; m++) {
          if (this.sortedStages[m].journeyPhase.relations && this.sortedStages[m].journeyPhase.relations.length > 0) {
            for (var j = 0; j < this.sortedStages[m].journeyPhase.relations.length; j++) {
              if (this.sortedStages[m].journeyPhase.relations[j] === task.journeyStep.id) {
                return this.sortedStages[m].journeyPhase.relations.indexOf(task.journeyStep.id) + 1;
              }
            }
          }
        }
      }
    },
    computeTaskX: function computeTaskX() {
      this.task_pos_x = [];
      if (this.isPhaseFocusMode && this.journeyMap && this.journeyMap.linkedJourneySteps) {
        for (var i = 0; i < this.journeyMap.linkedJourneySteps.length; i++) {
          if (this.selectedPhase.relations && this.selectedPhase.relations.length > 0) {
            var focus_mode_phase_start_x = this.computePhaseStartX(this.selectedPhase, this.selectedPhaseIndex) + 38;
            for (var k = 0; k < this.selectedPhase.relations.length; k++) {
              if (this.selectedPhase.relations[k] === this.computedTaskList[i].journeyStep.id) {
                this.task_pos_x.push(focus_mode_phase_start_x + 50 + k * 135);
              }
            }
          }
        }
      } else if (this.journeyMap && this.journeyMap.linkedJourneySteps) {
        for (var l = 0; l < this.journeyMap.linkedJourneySteps.length; l++) {
          for (var m = 0; m < this.sortedStages.length; m++) {
            if (this.sortedStages[m].journeyPhase.relations && this.sortedStages[m].journeyPhase.relations.length > 0) {
              var phase_start_x = this.computePhaseStartX(this.sortedStages[m].journeyPhase, m) + 38;
              for (var j = 0; j < this.sortedStages[m].journeyPhase.relations.length; j++) {
                if (this.sortedStages[m].journeyPhase.relations[j] === this.computedTaskList[l].journeyStep.id) {
                  this.task_pos_x.push(phase_start_x + 60 + Math.floor(j / 7) * 7 * 21);
                }
              }
            }
          }
        }
      }
    },
    computeTaskY: function computeTaskY() {
      this.task_pos_y = [];
      for (var l = 0; l < this.journeyMap.linkedJourneySteps.length; l++) {
        for (var m = 0; m < this.sortedStages.length; m++) {
          if (this.sortedStages[m].journeyPhase.relations && this.sortedStages[m].journeyPhase.relations.length > 0) {
            for (var j = 0; j < this.sortedStages[m].journeyPhase.relations.length; j++) {
              if (this.sortedStages[m].journeyPhase.relations[j] === this.computedTaskList[l].journeyStep.id) {
                this.task_pos_y.push(110 + j % 7 * 35);
              }
            }
          }
        }
      }
    },
    computePhaseX: function computePhaseX() {
      this.phase_pos_x = [];
      if (this.sortedStages) {
        for (var i = 0; i < this.sortedStages.length; i++) {
          if (i === 0) {
            this.phase_pos_x.push(this.canvasDragCoefficientX + 190);
          } else {
            var length = 0;
            for (var j = 0; j < i; j++) {
              length += this.computePhaseLength(this.sortedStages[j].journeyPhase, j);
              length += 75;
            }
            this.phase_pos_x.push(this.canvasDragCoefficientX + 190 + length);
          }
        }
      }
      this.computeTaskX();
    },
    computeCellIsStep: function computeCellIsStep(obj) {
      return obj.columnIndex < 2 ? 'step-column cell' : '';
    },
    getExperienceX: function getExperienceX(feature, experience_id) {
      for (var j = 0; j < this.computedExperienceList.length; j++) {
        if (this.computedExperienceList[j].journeyExperience.id === experience_id) {
          var x = this.computePainStartX(j);
          return x + 25;
        }
      }
    },
    getExperienceY: function getExperienceY(feature, experience_id) {
      for (var j = 0; j < this.computedExperienceList.length; j++) {
        if (this.computedExperienceList[j].journeyExperience.id === experience_id) {
          var y = j % 2 === 0 ? 270 : 320;
          return y;
        }
      }
    },
    onPhaseMouseOver: function onPhaseMouseOver(phase, index) {
      this.selectedPhaseIndex = index;
      this.selectedPhase = phase;
      this.resetHoverDetailsDialog();
      // setTimeout(() => {
      this.phaseHover = true;
      // }, 600)
    },
    onPhaseMouseLeave: function onPhaseMouseLeave() {
      this.selectedPhaseIndex = null;
      this.selectedPhase = {};
      this.resetHoverDetailsDialog();
      // setTimeout(() => {
      this.phaseHover = false;
      // }, 600)
    },
    onPhaseLongPressStart: function onPhaseLongPressStart(phase, index) {
      this.selectedPhaseIndex = index;
      this.selectedPhase = phase;
      this.resetHoverDetailsDialog();
      if (this.sortedStages.length > 0) {
        this.highlightAllPhaseGap();
      }
      this.phaseLongPress = true;
    },
    onPhaseLongPressStop: function onPhaseLongPressStop(phase, index) {
      this.resetHoverDetailsDialog();
      this.resetHighlightAllPhaseBlockGap();
      this.phaseLongPress = false;
    },
    onPhaseCollapse: function onPhaseCollapse(phase, index) {
      this.$emit('collapse-phase', phase);
      this.resetHoverDetailsDialog();
    },
    onPhaseExpand: function onPhaseExpand(phase, index) {
      this.$emit('expand-phase', phase);
      this.resetHoverDetailsDialog();
    },
    onTaskMouseOver: function onTaskMouseOver(task, index) {
      this.selectedTaskIndex = index;
      this.selectedTask = task;
      this.resetHoverDetailsDialog();
      // setTimeout(() => {
      this.taskHover = true;
      // }, 600)
    },
    onTaskMouseLeave: function onTaskMouseLeave(task, index) {
      this.taskHover = false;
    },
    onPhaseToolMouseOver: function onPhaseToolMouseOver(index) {
      this.phaseToolHover = true;
    },
    onPhaseToolMouseLeave: function onPhaseToolMouseLeave(index) {
      this.phaseToolHover = false;
    },
    onIdeaToolMouseOver: function onIdeaToolMouseOver(index) {
      this.ideaToolHover = true;
    },
    onIdeaToolMouseLeave: function onIdeaToolMouseLeave(index) {
      this.ideaToolHover = false;
    },
    onTaskToolMouseOver: function onTaskToolMouseOver() {
      this.taskToolHover = true;
    },
    onTaskToolMouseLeave: function onTaskToolMouseLeave() {
      this.taskToolHover = false;
    },
    onPainMouseOver: function onPainMouseOver(x, y, title, pain_relations, index) {
      this.selectedExperienceIndex = index;
      this.selectedExperience = this.computedExperienceList[index];
      this.resetHoverDetailsDialog();
      if (this.isExperienceLaneMinimized) {
        this.computeExperienceListForParentStage();
      }
      this.experienceHover = true;
    },
    onPainMouseLeave: function onPainMouseLeave() {
      this.experienceHover = false;
    },
    onGainMouseOver: function onGainMouseOver(x, y, title, pain_relations, index) {
      this.selectedExperienceIndex = index;
      this.selectedExperience = this.computedExperienceList[index];
      this.resetHoverDetailsDialog();
      if (this.isExperienceLaneMinimized) {
        this.computeExperienceListForParentStage();
      }
      this.experienceHover = true;
    },
    onGainMouseLeave: function onGainMouseLeave() {
      this.experienceHover = false;
    },
    onWowMouseOver: function onWowMouseOver(x, y, title, pain_relations, index) {
      this.selectedExperienceIndex = index;
      this.selectedExperience = this.computedExperienceList[index];
      this.resetHoverDetailsDialog();
      if (this.isExperienceLaneMinimized) {
        this.computeExperienceListForParentStage();
      }
      this.experienceHover = true;
    },
    onWowMouseLeave: function onWowMouseLeave() {
      this.experienceHover = false;
    },
    onChallengeMouseOver: function onChallengeMouseOver(x, y, title, pain_relations, index) {
      this.selectedExperienceIndex = index;
      this.selectedExperience = this.computedExperienceList[index];
      this.resetHoverDetailsDialog();
      if (this.isExperienceLaneMinimized) {
        this.computeExperienceListForParentStage();
      }
      this.experienceHover = true;
    },
    onChallengeMouseLeave: function onChallengeMouseLeave() {
      this.experienceHover = false;
    },
    resetPhaseForm: function resetPhaseForm() {
      this.phase_form = {
        id: '',
        title: '',
        priority: 'Low',
        description: '',
        importance: 'Low',
        duration: '',
        score: '',
        goal: '',
        challenges: '',
        emotions: '',
        tasks: [],
        experience: []
      };
    },
    resetTaskForm: function resetTaskForm() {
      this.task_form = {
        title: '',
        description: '',
        goal: '',
        priority: 'Low',
        importance: 'Low',
        relations: []
      };
    },
    resetHighlightActivePhaseBlock: function resetHighlightActivePhaseBlock() {
      this.activePhaseHighlighter = {
        x: 0,
        y: 0,
        width: 0,
        height: 0
      };
    },
    resetHighlightAllPhaseHighlighter: function resetHighlightAllPhaseHighlighter() {
      this.phaseHighlighter = [];
    },
    resetHighlightActivePhaseBlockGap: function resetHighlightActivePhaseBlockGap() {
      this.activePhaseGapHighlighter = {
        x: 0,
        y: 0,
        width: 0,
        height: 0
      };
    },
    resetHighlightAllPhaseBlockGap: function resetHighlightAllPhaseBlockGap() {
      this.phaseGapHighlighter = [];
    },
    resetTools: function resetTools() {
      this.toolbox = {
        task: {
          x: 45,
          y: 130
        },
        phase: {
          x: 45,
          y: 43
        },
        journey_idea: {
          x: 42,
          y: 215
        }
        /*,
        gain: {
          x: 10,
          y: 122
        },
        pain: {
          x: 75,
          y: 124
        },
        wow: {
          x: 10,
          y: 206
        }
        */
      };
    },
    resetTempTaskRelation: function resetTempTaskRelation() {
      this.tempTaskRelationPath = {
        x1: 0,
        y1: 0,
        x2: 0,
        y2: 0
      };
    },
    resetExperienceForm: function resetExperienceForm() {
      this.experience_form = {
        title: '',
        description: '',
        type: '',
        priority: 'Low',
        customer_value: 'Medium'
      };
    },
    resetJourneyMapForm: function resetJourneyMapForm() {
      this.journey_map_form = {
        title: ''
      };
    },
    resetStepLevelExperienceForm: function resetStepLevelExperienceForm() {
      this.step_selection_form = {
        step_id: null,
        experience_type: 'Pain'
      };
    },
    resetSelectedExperience: function resetSelectedExperience() {
      this.selectedExperienceId = null;
      this.selectedExperienceType = '';
    },
    resetSelectedExperienceList: function resetSelectedExperienceList() {
      this.selectedExperienceList = {
        pain: [],
        gain: [],
        wow: []
      };
    },
    resetDragDropDetails: function resetDragDropDetails() {
      this.drag_drop_details = {
        drag_element: '',
        drop_element: '',
        index1: 0,
        index2: 0
      };
    },
    resetDropHover: function resetDropHover() {
      window._jquery('#hover_highlight > rect').attr('x', -100);
      window._jquery('#hover_highlight > rect').attr('y', -100);
      this.drop_hover_details = {
        x: 0,
        y: 0,
        hover: false
      };
    },
    resetHoverDetailsDialog: function resetHoverDetailsDialog() {
      this.phaseHover = false;
      this.taskHover = false;
      this.experienceHover = false;
    },
    getPhaseFromStepID: function getPhaseFromStepID(step_id) {
      for (var i = 0; i < this.sortedStages.length; i++) {
        if (this.sortedStages[i].journeyPhase.relations && this.sortedStages[i].journeyPhase.relations.length > 0 && this.sortedStages[i].journeyPhase.relations.indexOf(step_id) > -1) {
          return [this.sortedStages[i].journeyPhase, i];
        }
      }
      return [{}, null];
    },
    getPhaseFromCursorX: function getPhaseFromCursorX(pos_x) {
      for (var i = 0; i < this.sortedStages.length; i++) {
        if (pos_x > this.computePhaseStartX(this.sortedStages[i].journeyPhase, i) && pos_x < this.computePhaseStartX(this.sortedStages[i].journeyPhase, i) + this.computePhaseLength(this.sortedStages[i].journeyPhase, i)) {
          return [this.sortedStages[i].journeyPhase, i];
        }
      }
    },
    getPhaseFromID: function getPhaseFromID(phase_id) {
      for (var i = 0; i < this.sortedStages.length; i++) {
        if (this.sortedStages[i].journeyPhase.id === phase_id) {
          return [this.sortedStages[i].journeyPhase, i];
        }
      }
      return [{}, null];
    },
    getStepFromID: function getStepFromID(step_id) {
      for (var i = 0; i < this.computedTaskList.length; i++) {
        if (this.computedTaskList[i].journeyStep.id === step_id) {
          return [this.computedTaskList[i].journeyStep, i];
        }
      }
      return [{}, null];
    },
    getStepTitleFromID: function getStepTitleFromID(step_id) {
      var step = this.getStepFromID(step_id)[0];
      if (!step) {
        return '';
      }
      return step.title;
    },
    computePhaseFromExperienceIndex: function computePhaseFromExperienceIndex(experience_index) {
      if (this.journeyMap.linkedJourneyExperiences[experience_index].journeyExperience.relations && this.journeyMap.linkedJourneyExperiences[experience_index].journeyExperience.relations.step) {
        var step_id = this.journeyMap.linkedJourneyExperiences[experience_index].journeyExperience.relations.step;
        for (var i = 0; i < this.sortedStages.length; i++) {
          if (this.sortedStages[i].journeyPhase.relations && this.sortedStages[i].journeyPhase.relations.length > 0 && this.sortedStages[i].journeyPhase.relations.includes(step_id)) {
            return [this.sortedStages[i].journeyPhase, i];
          }
        }
      } else if (this.journeyMap.linkedJourneyExperiences[experience_index].journeyExperience.relations && this.journeyMap.linkedJourneyExperiences[experience_index].journeyExperience.relations.phase) {
        for (var j = 0; j < this.sortedStages.length; j++) {
          if (this.sortedStages[j].journeyPhase.id === this.journeyMap.linkedJourneyExperiences[experience_index].journeyExperience.relations.phase) {
            return [this.sortedStages[j].journeyPhase, j];
          }
        }
      }
    },
    computeExperienceIDListForPhase: function computeExperienceIDListForPhase(phase) {
      var experienceIDArray = [];
      if (phase) {
        for (var i = 0; i < this.journeyMap.linkedJourneyExperiences.length; i++) {
          if (phase.relations && phase.relations.includes(this.journeyMap.linkedJourneyExperiences[i].journeyExperience.relations.step)) {
            experienceIDArray.push(this.journeyMap.linkedJourneyExperiences[i].journeyExperience.id);
          } else if (this.journeyMap.linkedJourneyExperiences[i].journeyExperience.relations.phase && this.journeyMap.linkedJourneyExperiences[i].journeyExperience.relations.phase === phase.id) {
            experienceIDArray.push(this.journeyMap.linkedJourneyExperiences[i].journeyExperience.id);
          }
        }
      }
      return experienceIDArray;
    },
    generateBezierPath: function generateBezierPath(start_x1, start_y1, start_x2, start_y2) {
      var end_x1 = start_x1 - 50;
      var end_y1 = start_y1 + 30;
      var end_x2 = start_x2 - 80;
      var end_y2 = start_y2;
      if (start_x1 !== undefined) {
        return 'M' + start_x1 + ',' + (start_y1 - 10) + ' C' + end_x1 + ',' + end_y1 + ' ' + end_x2 + ',' + end_y2 + ' ' + (start_x2 - 25) + ',' + (start_y2 + 15);
      } else {
        return 'M 0,0';
      }
    },
    generateTaskRelationPath: function generateTaskRelationPath(task_id, x2, y2) {
      var x1 = this.getTaskX(task_id);
      var y1 = this.getTaskY(task_id);
      return this.generateBezierPath(x1, y1, x2, y2);
    },
    getTaskX: function getTaskX(task_id) {
      for (var j = 0; j < this.journeyMap.linkedJourneySteps.length; j++) {
        if (this.journeyMap.linkedJourneySteps[j].journeyStep.id === task_id) {
          var x = this.computeTaskPosX(j);
          return x + 20;
        }
      }
    },
    getTaskY: function getTaskY(task_id) {
      for (var j = 0; j < this.journeyMap.linkedJourneySteps.length; j++) {
        if (this.journeyMap.linkedJourneySteps[j].journeyStep.id === task_id) {
          var y = this.computeTaskPosY(j);
          return y + 40;
        }
      }
    },
    getExperienceTitleFromID: function getExperienceTitleFromID(experience_id) {
      for (var i = 0; i < this.journeyMap.linkedJourneyExperiences.length; i++) {
        if (this.journeyMap.linkedJourneyExperiences[i].journeyExperience.id === experience_id) {
          return this.journeyMap.linkedJourneyExperiences[i].journeyExperience.title;
        }
      }
      return '';
    },
    computeZoomCoefficient: function computeZoomCoefficient(pos) {
      return pos + Math.round(pos * ((100 - this.journeyZoom) / 100));
    },
    computeStepIndexFromID: function computeStepIndexFromID(step_id) {
      for (var i = 0; i < this.journeyMap.linkedJourneySteps.length; i++) {
        if (this.journeyMap.linkedJourneySteps[i].journeyStep.id === step_id) {
          return i;
        }
      }
      return null;
    },
    resetCanvasLayout: function resetCanvasLayout() {
      this.canvasDragCoefficientX = 0;
      this.computePhaseX();
    },
    validateSortArray: function validateSortArray(attributes) {
      if (attributes && attributes.stagesSortOrder && attributes.stagesSortOrder.length === this.journeyMap.linkedJourneyPhases.length) {
        return true;
      } else {
        return false;
      }
    } // END HELPER METHODS
  }
};