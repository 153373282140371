"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.array.sort");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }]
  }, [_c("div", {
    staticClass: "innovation-tab-pane-header pt-5",
    staticStyle: {
      "min-height": "60pt"
    }
  }, [_c("div", {
    staticClass: "flex"
  }, [_c("div", {
    staticClass: "text-left ml-10",
    staticStyle: {
      width: "150px"
    }
  }, [_c("el-button", {
    staticStyle: {
      "margin-top": "1px"
    },
    attrs: {
      type: "primary",
      size: "micro"
    },
    on: {
      click: function click($event) {
        return _vm.openNewJourneyDialog();
      }
    }
  }, [_vm._v("Create Idea")])], 1), _vm._v(" "), _c("div", {
    staticClass: "ml-5 mr-5 text-center"
  }, [_c("el-input", {
    attrs: {
      type: "text",
      size: "mini",
      placeholder: "Search Ideas",
      "prefix-icon": "el-icon-search"
    },
    model: {
      value: _vm.search_ideas.q,
      callback: function callback($$v) {
        _vm.$set(_vm.search_ideas, "q", $$v);
      },
      expression: "search_ideas.q"
    }
  })], 1)]), _vm._v(" "), _c("div", {
    staticClass: "ml-5 mr-5 text-right fr mt-5",
    staticStyle: {
      width: "200px"
    }
  }, [_c("el-button-group", [_c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Filter",
      placement: "top"
    }
  }, [_c("el-button", {
    staticStyle: {
      "border-radius": "3pt"
    },
    attrs: {
      size: "micro",
      type: "secondary"
    },
    on: {
      click: _vm.showFilterDialog
    }
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "1.2em"
    },
    attrs: {
      "icon-class": "filter"
    }
  })], 1)], 1), _vm._v(" "), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Sort",
      placement: "top"
    }
  }, [_c("el-button", {
    staticStyle: {
      "border-radius": "3pt",
      "margin-left": "2pt"
    },
    attrs: {
      size: "micro",
      type: "secondary"
    },
    on: {
      click: function click($event) {
        return _vm.toggleSort();
      }
    }
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "1.2em"
    },
    attrs: {
      "icon-class": _vm.search_ideas.sort.title === "a-z" ? "sort-alpha-down" : "sort-alpha-up"
    }
  })], 1)], 1), _vm._v(" "), _c("el-tooltip", {
    staticClass: "item",
    attrs: {
      effect: "dark",
      content: "Reset Filter",
      placement: "top"
    }
  }, [_c("el-button", {
    staticStyle: {
      "border-radius": "3pt",
      "font-size": "15px",
      "margin-left": "2pt"
    },
    attrs: {
      size: "micro",
      type: "secondary",
      icon: "el-icon-refresh"
    },
    on: {
      click: function click($event) {
        return _vm.resetFilterSort();
      }
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "flex flex-wrap pt-10",
    staticStyle: {
      padding: "8pt 1pt 5pt 10pt",
      display: "inline-block"
    }
  }, [_vm.search_ideas.filters.title !== "" ? _c("el-tag", {
    attrs: {
      slot: "reference",
      size: "mini",
      closable: ""
    },
    on: {
      close: function close($event) {
        return _vm.removeSearchFilterTag("title");
      }
    },
    slot: "reference"
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "1.2em"
    },
    attrs: {
      "icon-class": "filter-left"
    }
  }), _vm._v("Name:" + _vm._s(_vm.search_ideas.filters.title_operator) + " " + _vm._s(_vm.search_ideas.filters.title) + "\n      ")], 1) : _vm._e(), _vm._v(" "), _vm.search_ideas.filters.description !== "" ? _c("el-tag", {
    attrs: {
      size: "mini",
      closable: ""
    },
    on: {
      close: function close($event) {
        return _vm.removeSearchFilterTag("description");
      }
    }
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "1.2em"
    },
    attrs: {
      "icon-class": "filter-left"
    }
  }), _vm._v("Description:" + _vm._s(_vm.search_ideas.filters.description_operator) + " " + _vm._s(_vm.search_ideas.filters.description))], 1) : _vm._e(), _vm._v(" "), _vm._l(_vm.search_ideas.filters.status, function (status, index) {
    return _c("el-tag", {
      key: index,
      attrs: {
        size: "mini",
        closable: ""
      },
      on: {
        close: function close($event) {
          return _vm.removeSearchFilterTag("status", index);
        }
      }
    }, [_c("svg-icon", {
      staticStyle: {
        "font-size": "1.2em"
      },
      attrs: {
        "icon-class": "filter-left"
      }
    }), _vm._v("Status:\n        "), status === "In progress" ? _c("svg-icon", {
      staticStyle: {
        color: "white",
        "background-color": "#2c64b5",
        "border-radius": "50%",
        "font-size": "1.0em"
      },
      attrs: {
        "icon-class": "three-dots"
      }
    }) : _vm._e(), _vm._v(" "), status === "On hold" ? _c("svg-icon", {
      staticStyle: {
        color: "#f09a0d",
        "font-size": "1.0em"
      },
      attrs: {
        "icon-class": "pause-circle-fill"
      }
    }) : _vm._e(), _vm._v(" "), status === "Overdue" ? _c("svg-icon", {
      staticStyle: {
        color: "#eb4a49",
        "font-size": "1.0em"
      },
      attrs: {
        "icon-class": "exclamation-circle-fill"
      }
    }) : _vm._e(), _vm._v(" "), status === "Achieved" ? _c("svg-icon", {
      staticStyle: {
        color: "#92b853",
        "font-size": "1.0em"
      },
      attrs: {
        "icon-class": "check-circle-fill"
      }
    }) : _vm._e(), _vm._v("\n        " + _vm._s(status) + "\n      ")], 1);
  }), _vm._v(" "), _vm._l(_vm.search_ideas.filters.customer_types, function (customer_type, index) {
    return _c("el-tag", {
      key: index,
      attrs: {
        size: "mini",
        closable: ""
      },
      on: {
        close: function close($event) {
          return _vm.removeSearchFilterTag("customer_types", index);
        }
      }
    }, [_vm._v("\n        Customer Type:" + _vm._s(customer_type) + "\n      ")]);
  }), _vm._v(" "), _vm._l(_vm.search_ideas.filters.offerings, function (offering, index) {
    return _c("el-tag", {
      key: index,
      attrs: {
        size: "mini",
        closable: ""
      },
      on: {
        close: function close($event) {
          return _vm.removeSearchFilterTag("offerings", index);
        }
      }
    }, [_vm._v("\n        Offering:" + _vm._s(offering) + "\n      ")]);
  }), _vm._v(" "), _vm.search_ideas.filters.score > 0 ? _c("el-tag", {
    attrs: {
      size: "mini",
      closable: ""
    },
    on: {
      close: function close($event) {
        return _vm.removeSearchFilterTag("score");
      }
    }
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "1.2em"
    },
    attrs: {
      "icon-class": "filter-left"
    }
  }), _vm._v("Votes:" + _vm._s(_vm.search_ideas.filters.score) + "\n      ")], 1) : _vm._e(), _vm._v(" "), _vm.search_ideas.sort.title !== "" ? _c("el-tag", {
    attrs: {
      type: "info",
      size: "mini",
      closable: ""
    },
    on: {
      close: function close($event) {
        return _vm.removeSearchSortTag("title");
      }
    }
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "1.2em"
    },
    attrs: {
      "icon-class": _vm.search_ideas.sort.title === "a-z" ? "sort-alpha-down" : "sort-alpha-up"
    }
  }), _vm._v("\n        Name:" + _vm._s(_vm.search_ideas.sort.title) + "\n      ")], 1) : _vm._e()], 2)]), _vm._v(" "), _vm.journeyIdea.length > 0 ? _vm._l(_vm.journeyIdea, function (idea, index) {
    return _c("div", {
      key: index,
      class: _vm.selectedIdea && _vm.selectedIdea.id === idea.id ? "list_item is-item-active" : "list_item",
      on: {
        click: function click($event) {
          return _vm.selectJourney(idea);
        }
      }
    }, [_c("table", {
      staticStyle: {
        width: "100%",
        "font-variant-caps": "small-caps"
      }
    }, [_c("tr", [_c("td", [_c("strong", [_vm._v(_vm._s(idea.title))])]), _vm._v(" "), _c("td", {
      staticClass: "text-right"
    }, [idea.status === "Proposed" ? _c("el-tag", {
      attrs: {
        size: "small",
        color: "Gainsboro"
      }
    }, [_vm._v(_vm._s(idea.status))]) : _vm._e(), _vm._v(" "), idea.status === "Evaluation" ? _c("el-tag", {
      attrs: {
        size: "small",
        color: "PaleTurquoise"
      }
    }, [_vm._v(_vm._s(idea.status))]) : _vm._e(), _vm._v(" "), idea.status === "Approved" ? _c("el-tag", {
      attrs: {
        size: "small",
        color: "DarkTurquoise"
      }
    }, [_vm._v(_vm._s(idea.status))]) : _vm._e(), _vm._v(" "), idea.status === "Starting Up" ? _c("el-tag", {
      attrs: {
        size: "small",
        color: "lightskyblue"
      }
    }, [_vm._v(_vm._s(idea.status))]) : _vm._e(), _vm._v(" "), idea.status === "Launched" ? _c("el-tag", {
      attrs: {
        size: "small",
        color: "MediumSeaGreen"
      }
    }, [_vm._v(_vm._s(idea.status))]) : _vm._e(), _vm._v(" "), idea.status === "Abandoned" ? _c("el-tag", {
      attrs: {
        size: "small",
        color: "rosybrown"
      }
    }, [_vm._v(_vm._s(idea.status))]) : _vm._e()], 1)])]), _vm._v(" "), _c("table", {
      staticStyle: {
        width: "100%"
      }
    }, [_c("tr", [_c("td", {
      staticClass: "text-left",
      staticStyle: {
        color: "#7626a5"
      }
    }, [_c("svg-icon", {
      staticStyle: {
        color: "rgb(70, 114, 196)"
      },
      attrs: {
        "icon-class": "peoples"
      }
    }), _c("b", [_vm._v(" " + _vm._s(idea.customer_type))])], 1), _vm._v(" "), _c("td", {
      staticClass: "text-right"
    }, [idea.scope === "Journey" ? _c("span", [_c("svg-icon", {
      staticStyle: {
        color: "rgb(70, 114, 196)",
        "font-size": "1.8em"
      },
      attrs: {
        "icon-class": "cjm"
      }
    })], 1) : _vm._e(), _vm._v(" "), idea.scope === "Phase" ? _c("svg-icon", {
      staticStyle: {
        color: "rgb(70, 114, 196)",
        "font-size": "1.4em"
      },
      attrs: {
        "icon-class": "phase"
      }
    }) : _vm._e(), _vm._v(" "), idea.scope === "Step" ? _c("svg-icon", {
      staticStyle: {
        color: "rgb(70, 114, 196)",
        "font-size": "1.4em"
      },
      attrs: {
        "icon-class": "ujm-step"
      }
    }) : _vm._e(), _vm._v(" "), _c("span", {
      staticStyle: {
        "font-size": "0.92em"
      }
    }, [_vm._v(" " + _vm._s(idea.scopename))])], 1)])]), _vm._v(" "), _c("table", {
      staticStyle: {
        width: "100%"
      }
    }, [_c("tr", [_c("div", {
      staticStyle: {
        "text-align": "justify",
        "text-justify": "inter-word",
        "font-size": "0.88em"
      }
    }, [_c("i", [_vm._v(_vm._s(_vm.truncateDescription(idea.description)))])])])])]);
  }) : _c("el-empty", [_c("p", {
    attrs: {
      slot: "description"
    },
    on: {
      click: function click($event) {
        return _vm.openNewJourneyDialog();
      }
    },
    slot: "description"
  }, [_vm._v("No Data")])]), _vm._v(" "), _c("div", {
    staticClass: "text-center",
    staticStyle: {
      width: "calc(100%/24 * 4)",
      position: "fixed",
      bottom: "0px",
      "background-color": "rgba(255,255,255,1)"
    }
  }, [_c("el-pagination", {
    attrs: {
      layout: "prev, pager, next",
      small: true,
      "hide-on-single-page": true,
      total: _vm.total_count
    },
    on: {
      "current-change": _vm.loadPage,
      "prev-click": _vm.loadPage,
      "next-click": _vm.loadPage
    }
  })], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Filter List",
      visible: _vm.filterDialog,
      width: "30%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.filterDialog = $event;
      }
    }
  }, [_c("table", {
    staticStyle: {
      "table-layout": "fixed",
      width: "100%"
    }
  }, [_c("colgroup", [_c("col", {
    staticStyle: {
      width: "20%"
    },
    attrs: {
      span: "1"
    }
  }), _vm._v(" "), _c("col", {
    staticStyle: {
      width: "80%"
    },
    attrs: {
      span: "1"
    }
  })]), _vm._v(" "), _c("tr", [_c("th", {
    staticClass: "text-left"
  }, [_vm._v("Name")]), _vm._v(" "), _c("td", {
    staticClass: "flex"
  }, [_c("el-select", {
    staticStyle: {
      width: "150px"
    },
    attrs: {
      clearable: "",
      placeholder: "Select",
      size: "mini"
    },
    model: {
      value: _vm.search_ideas.filters.title_operator,
      callback: function callback($$v) {
        _vm.$set(_vm.search_ideas.filters, "title_operator", $$v);
      },
      expression: "search_ideas.filters.title_operator"
    }
  }, [_c("el-option", {
    attrs: {
      label: "EXACT",
      value: "EXACT"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "LIKE",
      value: "LIKE"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "STARTS",
      value: "STARTS"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "ENDS",
      value: "ENDS"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "EMPTY",
      value: "EMPTY"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "NOT EMPTY",
      value: "NOT EMPTY"
    }
  })], 1), _vm._v(" "), _c("el-input", {
    staticClass: "ml-5",
    attrs: {
      type: "text",
      size: "mini"
    },
    model: {
      value: _vm.search_ideas.filters.title,
      callback: function callback($$v) {
        _vm.$set(_vm.search_ideas.filters, "title", $$v);
      },
      expression: "search_ideas.filters.title"
    }
  })], 1)]), _vm._v(" "), _c("tr", [_c("th", {
    staticClass: "text-left"
  }, [_vm._v("Description")]), _vm._v(" "), _c("td", {
    staticClass: "flex"
  }, [_c("el-select", {
    staticStyle: {
      width: "150px"
    },
    attrs: {
      clearable: "",
      placeholder: "Select",
      size: "mini"
    },
    model: {
      value: _vm.search_ideas.filters.description_operator,
      callback: function callback($$v) {
        _vm.$set(_vm.search_ideas.filters, "description_operator", $$v);
      },
      expression: "search_ideas.filters.description_operator"
    }
  }, [_c("el-option", {
    attrs: {
      label: "EXACT",
      value: "EXACT"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "LIKE",
      value: "LIKE"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "STARTS",
      value: "STARTS"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "ENDS",
      value: "ENDS"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "EMPTY",
      value: "EMPTY"
    }
  }), _vm._v(" "), _c("el-option", {
    attrs: {
      label: "NOT EMPTY",
      value: "NOT EMPTY"
    }
  })], 1), _vm._v(" "), _c("el-input", {
    staticClass: "ml-5",
    attrs: {
      type: "text",
      size: "mini"
    },
    model: {
      value: _vm.search_ideas.filters.description,
      callback: function callback($$v) {
        _vm.$set(_vm.search_ideas.filters, "description", $$v);
      },
      expression: "search_ideas.filters.description"
    }
  })], 1)]), _vm._v(" "), _c("tr", [_c("th", {
    staticClass: "text-left"
  }, [_vm._v("Customer Type")]), _vm._v(" "), _c("td", [_c("el-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      clearable: "",
      placeholder: "Select",
      size: "mini",
      multiple: ""
    },
    model: {
      value: _vm.search_ideas.filters.customer_types,
      callback: function callback($$v) {
        _vm.$set(_vm.search_ideas.filters, "customer_types", $$v);
      },
      expression: "search_ideas.filters.customer_types"
    }
  }, _vm._l(_vm.customers, function (customer) {
    return _c("el-option", {
      key: customer.id,
      attrs: {
        label: customer.title,
        value: customer.title
      }
    });
  }), 1)], 1)]), _vm._v(" "), _c("tr", [_c("th", {
    staticClass: "text-left"
  }, [_vm._v("Status")]), _vm._v(" "), _c("td", [_c("el-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      clearable: "",
      placeholder: "Select",
      size: "mini",
      multiple: ""
    },
    model: {
      value: _vm.search_ideas.filters.status,
      callback: function callback($$v) {
        _vm.$set(_vm.search_ideas.filters, "status", $$v);
      },
      expression: "search_ideas.filters.status"
    }
  }, _vm._l(_vm.statuses, function (status) {
    return _c("el-option", {
      key: status.id,
      attrs: {
        label: status.label,
        value: status.value
      }
    });
  }), 1)], 1)]), _vm._v(" "), _c("tr", [_c("th", {
    staticClass: "text-left"
  }, [_vm._v("Score")]), _vm._v(" "), _c("td", [_c("el-input", {
    attrs: {
      type: "number",
      size: "mini"
    },
    model: {
      value: _vm.search_ideas.filters.score,
      callback: function callback($$v) {
        _vm.$set(_vm.search_ideas.filters, "score", $$v);
      },
      expression: "search_ideas.filters.score"
    }
  })], 1)])]), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: _vm.filterIdeaList
    }
  }, [_vm._v("Filter")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: _vm.closeFilterDialog
    }
  }, [_vm._v("Cancel")])], 1)])], 2);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;