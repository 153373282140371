"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("g", {
    attrs: {
      refs: "feature_details_popover_container"
    },
    on: {
      mouseover: _vm.emitOnMouseOver,
      mouseleave: function mouseleave($event) {
        return _vm.closeFeatureDetailsPopover();
      }
    }
  }, [_c("g", {
    attrs: {
      refs: "feature_details_popover_container_up_arrow"
    }
  }, [_c("path", {
    attrs: {
      d: _vm.generateMarker(),
      "stroke-width": "1",
      fill: "#447799"
    }
  })]), _vm._v(" "), _c("filter", {
    attrs: {
      id: "blur"
    }
  }, [_c("feDropShadow", {
    attrs: {
      dx: "2",
      gy: "2",
      "std-deviation": "3",
      "flood-opacity": "0.5"
    }
  })], 1), _vm._v(" "), _c("rect", {
    staticClass: "backdrop",
    attrs: {
      x: _vm.x,
      y: _vm.y - 50,
      rx: 3,
      width: _vm.width,
      height: _vm.computeHeight,
      fill: "#304174",
      stroke: "rgba(0,112,192,0.2)",
      "stroke-width": "0"
    }
  }), _vm._v(" "), _c("rect", {
    attrs: {
      x: _vm.x,
      y: _vm.y - 50,
      width: _vm.width,
      height: _vm.computeHeight,
      fill: "rgba(255,255,255)"
    }
  }), _vm._v(" "), _c("linearGradient", {
    attrs: {
      id: "featureGradient",
      x2: "1",
      y2: "1"
    }
  }, [_c("stop", {
    attrs: {
      offset: "0%",
      "stop-color": "#447799"
    }
  }), _vm._v(" "), _c("stop", {
    attrs: {
      offset: "50%",
      "stop-color": "#224488"
    }
  }), _vm._v(" "), _c("stop", {
    attrs: {
      offset: "100%",
      "stop-color": "#112266"
    }
  })], 1), _vm._v(" "), _c("g", {
    attrs: {
      refs: "feature_details_popover_title"
    }
  }, [_c("rect", {
    staticClass: "feature_title",
    attrs: {
      x: _vm.x,
      y: _vm.y - 50,
      width: _vm.width,
      height: 30,
      fill: "#112266"
    }
  }), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "#fff"
    },
    attrs: {
      x: _vm.x + 10,
      y: _vm.y - 32,
      "text-anchor": "start",
      "font-size": "1.05em",
      "font-weight": "bold"
    }
  }, [_vm._v(_vm._s(_vm.truncateString(_vm.feature.title, 45)))]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "feature_details_popover_title_close"
    },
    on: {
      click: function click($event) {
        $event.preventDefault();
        return _vm.closeFeatureDetailsPopover.apply(null, arguments);
      }
    }
  }, [_c("rect", {
    attrs: {
      x: _vm.x + _vm.width - 30,
      y: _vm.y - 47,
      rx: 15,
      ry: 15,
      width: 24,
      height: 24,
      fill: "darkred"
    }
  }), _vm._v(" "), _c("foreignObject", {
    attrs: {
      x: _vm.x + _vm.width - 30,
      y: _vm.y - 45,
      width: 24,
      height: 24
    }
  }, [_c("div", {
    staticClass: "text-center textpointer",
    staticStyle: {
      color: "#FFF",
      "padding-top": "2pt",
      "padding-left": "-1pt"
    }
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "12px"
    },
    attrs: {
      "icon-class": "cross"
    }
  })], 1)])])]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "feature_details_popover_tools"
    }
  }, [_c("g", {
    attrs: {
      refs: "experience_details_popover_tools_experience_pain"
    }
  }, [_c("foreignObject", {
    attrs: {
      x: _vm.x + 10,
      y: _vm.y - 15,
      width: _vm.width * 0.57,
      height: "20"
    }
  }, [_c("div", {
    staticStyle: {
      "font-size": "0.9em",
      fill: "#0070c0",
      "font-weight": "normal",
      color: "#0070c0",
      height: "70px",
      "max-height": "70pt",
      "overflow-y": "hidden"
    }
  }, [_vm._v("\n          " + _vm._s(_vm.truncateString(_vm.feature.description, 45)) + "\n        ")])])])]), _vm._v(" "), _c("line", {
    attrs: {
      x1: _vm.x,
      y1: _vm.y + 5,
      x2: _vm.x + _vm.width,
      y2: _vm.y + 5,
      fill: "#126661",
      "stroke-width": "1",
      stroke: "#126661"
    }
  }), _vm._v(" "), _vm.child_tickets.length > 0 ? _c("g", {
    attrs: {
      refs: "feature_detail_popover_stories"
    }
  }, [_c("text", {
    staticStyle: {
      fill: "#112266"
    },
    attrs: {
      x: _vm.x + 10,
      y: _vm.y + 20,
      "text-anchor": "start",
      "font-size": "1.0em",
      "font-weight": "bolder"
    }
  }, [_vm._v("Stories")]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "feature_detail_popover_stories_header"
    }
  }, [_c("g", {
    attrs: {
      refs: "feature_detail_popover_stories_header_title"
    }
  }, [_c("rect", {
    staticClass: "feature_title",
    attrs: {
      x: _vm.x,
      y: _vm.y + 25,
      width: _vm.width / 2,
      height: 20,
      fill: "#112266",
      stroke: "rgba(255,255,255,1)",
      "stroke-width": "1"
    }
  }), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "#fff"
    },
    attrs: {
      x: _vm.x + _vm.width / 4,
      y: _vm.y + 37,
      "text-anchor": "middle",
      "font-size": "0.8em"
    }
  }, [_vm._v("Title")])]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "feature_detail_popover_stories_header_status"
    }
  }, [_c("rect", {
    staticClass: "feature_title",
    attrs: {
      x: _vm.x + _vm.width / 2,
      y: _vm.y + 25,
      width: _vm.width / 4,
      height: 20,
      fill: "#112266",
      stroke: "rgba(255,255,255,1)",
      "stroke-width": "1"
    }
  }), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "#fff"
    },
    attrs: {
      x: _vm.x + _vm.width / 2 + _vm.width / 8,
      y: _vm.y + 37,
      "text-anchor": "middle",
      "font-size": "0.8em"
    }
  }, [_vm._v("Status")])]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "feature_detail_popover_stories_header_priority"
    }
  }, [_c("rect", {
    staticClass: "feature_title",
    attrs: {
      x: _vm.x + 3 * _vm.width / 4,
      y: _vm.y + 25,
      width: _vm.width / 4,
      height: 20,
      fill: "#112266",
      stroke: "rgba(255,255,255,1)",
      "stroke-width": "1"
    }
  }), _vm._v(" "), _c("text", {
    staticStyle: {
      fill: "#fff"
    },
    attrs: {
      x: _vm.x + 3 * _vm.width / 4 + _vm.width / 8,
      y: _vm.y + 37,
      "text-anchor": "middle",
      "font-size": "0.8em"
    }
  }, [_vm._v("Priority")])])]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "feature_detail_popover_stories_list"
    }
  }, _vm._l(_vm.child_tickets, function (story, index) {
    return _c("g", {
      key: index,
      attrs: {
        refs: "feature_detail_popover_stories_list_datum"
      }
    }, [_c("text", {
      staticStyle: {
        fill: "#111"
      },
      attrs: {
        x: _vm.x + _vm.width / 4,
        y: _vm.y + 54 + index * 20,
        "text-anchor": "middle",
        "font-size": "0.8em"
      }
    }, [_vm._v(_vm._s(_vm.truncateString(story.title, 25)))]), _vm._v(" "), _c("text", {
      staticStyle: {
        fill: "#111"
      },
      attrs: {
        x: _vm.x + _vm.width / 2 + _vm.width / 8,
        y: _vm.y + 54 + index * 20,
        "text-anchor": "middle",
        "font-size": "0.8em"
      }
    }, [_vm._v(_vm._s(story.status))]), _vm._v(" "), _c("text", {
      staticStyle: {
        fill: "#111"
      },
      attrs: {
        x: _vm.x + 3 * _vm.width / 4 + _vm.width / 8,
        y: _vm.y + 54 + index * 20,
        "text-anchor": "middle",
        "font-size": "0.8em"
      }
    }, [_vm._v(_vm._s(story.priority))]), _vm._v(" "), _c("line", {
      attrs: {
        x1: _vm.x,
        y1: _vm.y + 57 + index * 20,
        x2: _vm.x + _vm.width,
        y2: _vm.y + 57 + index * 20,
        fill: "#126661",
        "stroke-width": "1",
        stroke: "#126661"
      }
    })]);
  }), 0)]) : _vm._e()], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;