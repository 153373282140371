"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.number.constructor");
var _default2 = exports.default = {
  name: 'DefineCustomerTypes',
  components: {},
  props: {
    activeSegment: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    activeSegmentIndex: {
      type: Number,
      default: 0
    }
  },
  data: function data() {
    return {
      loading: false,
      otherOptionsDialog: false,
      addCustomerDialog: false,
      activeCustomer: {},
      activeCustomerTypeTab: '',
      customer_form: {
        name: '',
        description: '',
        problem_statement: '',
        opportunity_statement: '',
        psychometric_notes: '',
        sales_strategy: '',
        product_user: false,
        purchaser: false,
        map_persona: false
      },
      addCustomerModalStepCounter: 0
    };
  },
  mounted: function mounted() {
    this.$eventHub.$emit('process-step-load', 'define-customer-types');
    this.activeCustomerTypeTab = this.activeSegment.customers[0].name;
  },
  methods: {
    initializeAddCustomerModal: function initializeAddCustomerModal() {
      this.addCustomerModalStepCounter = 0;
    },
    openCustomerTypeDetailsModal: function openCustomerTypeDetailsModal(customer) {
      this.activeCustomer = customer;
      this.customerTypeDetailsDialog = true;
    },
    openAddCustomerDialog: function openAddCustomerDialog() {
      this.customer_form = {
        name: '',
        description: '',
        problem_statement: '',
        opportunity_statement: '',
        psychometric_notes: '',
        sales_strategy: '',
        product_user: false,
        purchaser: false,
        map_persona: false
      };
      this.addCustomerDialog = true;
    },
    addCustomer: function addCustomer() {
      this.activeSegment.customers.push(this.customer_form);
      this.addCustomerDialog = false;
    },
    removeCustomerType: function removeCustomerType(target) {
      var tabs = this.activeSegment.customers;
      var activeName = this.activeCustomerTypeTab;
      if (activeName === target) {
        tabs.forEach(function (tab, index) {
          if (tab.name === target) {
            var nextTab = tabs[index + 1] || tabs[index - 1];
            if (nextTab) {
              activeName = nextTab.name;
            }
          }
        });
      }
      this.activeCustomerTypeTab = activeName;
      this.activeSegment.customers = tabs.filter(function (tab) {
        return tab.name !== target;
      });
    },
    onClickDefineUsers: function onClickDefineUsers() {
      this.$message({
        type: 'info',
        message: 'Go to Define Users'
      });
    },
    onClickDefineRevenueTypes: function onClickDefineRevenueTypes() {
      this.$emit('go-prev');
    }
  }
};