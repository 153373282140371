"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-tabs", {
    on: {
      "tab-click": _vm.onTabClick
    },
    model: {
      value: _vm.computedTabname,
      callback: function callback($$v) {
        _vm.computedTabname = $$v;
      },
      expression: "computedTabname"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "Stage Details",
      name: "details"
    }
  }, [_c("el-form", {
    ref: "addStageform",
    attrs: {
      rules: _vm.addStageRules,
      model: _vm.phase_form,
      "label-width": "120px",
      size: "mini"
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Name",
      prop: "title"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini",
      autofocus: ""
    },
    model: {
      value: _vm.phase_form.title,
      callback: function callback($$v) {
        _vm.$set(_vm.phase_form, "title", $$v);
      },
      expression: "phase_form.title"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Description",
      prop: "description"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini",
      rows: "4",
      type: "textarea"
    },
    model: {
      value: _vm.phase_form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.phase_form, "description", $$v);
      },
      expression: "phase_form.description"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Goal",
      prop: "goal"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini",
      placeholder: "What's the goal to achieve for this stage?"
    },
    model: {
      value: _vm.phase_form.goal,
      callback: function callback($$v) {
        _vm.$set(_vm.phase_form, "goal", $$v);
      },
      expression: "phase_form.goal"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Duration",
      prop: "duration"
    }
  }, [_c("el-input", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.phase_form.duration,
      callback: function callback($$v) {
        _vm.$set(_vm.phase_form, "duration", $$v);
      },
      expression: "phase_form.duration"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Priority",
      prop: "priority"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.phase_form.priority,
      callback: function callback($$v) {
        _vm.$set(_vm.phase_form, "priority", $$v);
      },
      expression: "phase_form.priority"
    }
  }, [_c("el-radio", {
    attrs: {
      label: "Very Low"
    }
  }), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "Low"
    }
  }), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "Medium"
    }
  }), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "High"
    }
  }), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "Very High"
    }
  })], 1)], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Value",
      prop: "importance"
    }
  }, [_c("el-radio-group", {
    attrs: {
      size: "mini"
    },
    model: {
      value: _vm.phase_form.importance,
      callback: function callback($$v) {
        _vm.$set(_vm.phase_form, "importance", $$v);
      },
      expression: "phase_form.importance"
    }
  }, [_c("el-radio", {
    attrs: {
      label: "Very Low"
    }
  }), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "Low"
    }
  }), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "Medium"
    }
  }), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "High"
    }
  }), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: "Very High"
    }
  })], 1)], 1)], 1)], 1), _vm._v(" "), _vm.edit ? _c("el-tab-pane", {
    attrs: {
      label: "Steps",
      name: "steps"
    }
  }, [_c("el-table", {
    staticStyle: {
      "word-break": "break-word"
    },
    attrs: {
      data: _vm.computedSteps,
      stripe: "",
      size: "micro"
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "#",
      type: "index",
      width: "60",
      align: "center"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "title",
      label: "Title",
      width: "240"
    }
  }), _vm._v(" "), _c("el-table-column", {
    staticStyle: {
      "word-break": "break-word"
    },
    attrs: {
      prop: "description",
      label: "Description"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Value",
      width: "90",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-tag", {
          attrs: {
            size: "mini"
          }
        }, [_vm._v(_vm._s(scope.row.importance))])];
      }
    }], null, false, 4221577666)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Priority",
      width: "90",
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-tag", {
          attrs: {
            size: "mini"
          }
        }, [_vm._v(_vm._s(scope.row.priority))])];
      }
    }], null, false, 555127428)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      width: "158"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.$index > 0 ? _c("i", {
          staticClass: "el-icon-top",
          on: {
            click: function click($event) {
              return _vm.movePhaseRelationStepUp(scope.$index);
            }
          }
        }) : _vm._e(), _vm._v(" "), scope.$index < _vm.computedSteps.length - 1 ? _c("i", {
          staticClass: "el-icon-bottom",
          on: {
            click: function click($event) {
              return _vm.movePhaseRelationStepDown(scope.$index);
            }
          }
        }) : _vm._e(), _vm._v(" "), _c("i", {
          staticClass: "el-icon-delete",
          staticStyle: {
            color: "#F56C6C",
            float: "right"
          },
          on: {
            click: function click($event) {
              return _vm.deleteStep(scope.$index, scope.row.id);
            }
          }
        }), _vm._v(" "), _c("i", {
          staticClass: "el-icon-edit mr-5 ml-5",
          staticStyle: {
            float: "right"
          },
          on: {
            click: function click($event) {
              return _vm.selectTaskFromPhaseDialog(scope.$index, scope.row);
            }
          }
        })];
      }
    }], null, false, 3685070002)
  }, [_c("template", {
    slot: "header"
  }, [_c("el-button", {
    staticClass: "fr",
    attrs: {
      type: "primary",
      size: "mini"
    },
    on: {
      click: function click($event) {
        return _vm.createTaskFromPhase();
      }
    }
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "create"
    }
  }), _vm._v(" Add Step")], 1)], 1)], 2)], 1)], 1) : _vm._e(), _vm._v(" "), _vm.edit ? _c("el-tab-pane", {
    attrs: {
      label: "Experiences",
      name: "experience"
    }
  }, [_c("div", {
    staticStyle: {
      "background-color": "darkslategrey",
      color: "white",
      "border-radius": "4pt",
      height: "24pt",
      "padding-top": "4pt"
    }
  }, [_c("span", {
    staticStyle: {
      "padding-left": "8pt"
    }
  }, [_vm._v("Stage Experiences")]), _vm._v(" "), _c("el-dropdown", {
    staticClass: "fr",
    on: {
      command: _vm.handleDropDownCommand
    }
  }, [_c("el-button", {
    staticStyle: {
      padding: "3pt 5pt 3pt 8pt",
      top: "-4pt",
      "margin-right": "6pt"
    },
    attrs: {
      type: "plain",
      size: "mini"
    }
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "create"
    }
  }), _vm._v("\n            Add Experience "), _c("i", {
    staticClass: "el-icon-arrow-down el-icon--right"
  })], 1), _vm._v(" "), _c("el-dropdown-menu", {
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, [_c("el-dropdown-item", {
    attrs: {
      command: "createExperienceGainFromPhase"
    }
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "14px",
      color: "green",
      "margin-right": "4pt"
    },
    attrs: {
      "icon-class": "smiley"
    }
  }), _vm._v("Gain")], 1), _vm._v(" "), _c("el-dropdown-item", {
    attrs: {
      command: "createExperiencePainFromPhase"
    }
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "14px",
      color: "green",
      "margin-right": "4pt"
    },
    attrs: {
      "icon-class": "danger-circle"
    }
  }), _vm._v("Pain")], 1), _vm._v(" "), _c("el-dropdown-item", {
    attrs: {
      command: "createExperienceWowFromPhase"
    }
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "14px",
      color: "green",
      "margin-right": "4pt"
    },
    attrs: {
      "icon-class": "wow_blue"
    }
  }), _vm._v("Wow")], 1), _vm._v(" "), _c("el-dropdown-item", {
    attrs: {
      command: "createExperienceChallengeFromPhase"
    }
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "14px",
      color: "green",
      "margin-right": "4pt"
    },
    attrs: {
      "icon-class": "oscurity"
    }
  }), _vm._v("Obscurity")], 1)], 1)], 1)], 1), _vm._v(" "), _c("el-table", {
    staticStyle: {
      "word-break": "break-word"
    },
    attrs: {
      data: _vm.computedExperiences,
      stripe: "",
      size: "micro"
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index",
      width: "60",
      align: "center"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "title",
      label: "Title",
      width: "240"
    }
  }), _vm._v(" "), _c("el-table-column", {
    staticStyle: {
      "word-break": "break-word"
    },
    attrs: {
      prop: "description",
      label: "Description"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "",
      width: "160"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("i", {
          staticClass: "el-icon-delete",
          staticStyle: {
            color: "#F56C6C",
            float: "right"
          },
          on: {
            click: function click($event) {
              return _vm.deleteExperience(scope.row.id);
            }
          }
        }), _vm._v(" "), _c("i", {
          staticClass: "el-icon-edit mr-5 ml-5",
          staticStyle: {
            float: "right"
          },
          on: {
            click: function click($event) {
              return _vm.selectExperienceFromPhaseDialog(scope.$index, scope.row);
            }
          }
        })];
      }
    }], null, false, 2690091137)
  })], 1)], 1) : _vm._e(), _vm._v(" "), _vm.edit ? _c("el-tab-pane", {
    attrs: {
      label: "Touchpoints",
      name: "touchpoints"
    }
  }, [_c("el-table", {
    staticStyle: {
      "word-break": "break-word"
    },
    attrs: {
      data: _vm.journeyMap.additionalattributes.layouts.horizontal.sortOrder.stages[_vm.index].touchpoints,
      stripe: "",
      size: "micro"
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "",
      width: "40",
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [scope.row.type === "sms" ? _c("svg-icon", {
          staticStyle: {
            "font-size": "20px",
            color: "#304174"
          },
          attrs: {
            "icon-class": "sms"
          }
        }) : _vm._e(), _vm._v(" "), scope.row.type === "web" ? _c("svg-icon", {
          staticStyle: {
            "font-size": "20px",
            color: "#304174"
          },
          attrs: {
            "icon-class": "international"
          }
        }) : _vm._e(), _vm._v(" "), scope.row.type === "chat" ? _c("svg-icon", {
          staticStyle: {
            "font-size": "20px",
            color: "#304174"
          },
          attrs: {
            "icon-class": "wechat"
          }
        }) : _vm._e(), _vm._v(" "), scope.row.type === "social" ? _c("svg-icon", {
          staticStyle: {
            "font-size": "20px",
            color: "#304174"
          },
          attrs: {
            "icon-class": "channel"
          }
        }) : _vm._e(), _vm._v(" "), scope.row.type === "phone" ? _c("i", {
          staticClass: "el-icon-phone",
          staticStyle: {
            "font-size": "20px",
            color: "#304174"
          }
        }) : _vm._e(), _vm._v(" "), scope.row.type === "app" ? _c("svg-icon", {
          staticStyle: {
            "font-size": "20px",
            color: "#304174"
          },
          attrs: {
            "icon-class": "app"
          }
        }) : _vm._e(), _vm._v(" "), scope.row.type === "email" ? _c("svg-icon", {
          staticStyle: {
            "font-size": "20px",
            color: "#304174"
          },
          attrs: {
            "icon-class": "email"
          }
        }) : _vm._e()];
      }
    }], null, false, 3438958379)
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "type",
      label: "Channel",
      align: "left",
      width: "110"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "title",
      label: "Name",
      align: "left"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "description",
      label: "Description",
      align: "left",
      width: "auto"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      align: "right"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("i", {
          staticClass: "el-icon-delete",
          staticStyle: {
            color: "#F56C6C"
          },
          on: {
            click: function click($event) {
              return _vm.deleteTouchPoint(scope.row.title);
            }
          }
        })];
      }
    }], null, false, 3869064490)
  }, [_c("template", {
    slot: "header"
  }, [_c("el-dropdown", {
    attrs: {
      size: "mini",
      "split-button": "",
      type: "primary"
    },
    on: {
      command: _vm.handleAddTouchpointCommand
    }
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "create"
    }
  }), _vm._v("\n              Add Touchpoint\n              "), _c("el-dropdown-menu", {
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, [!_vm.checkIfExists("sms") ? _c("el-dropdown-item", {
    attrs: {
      command: "sms"
    }
  }, [_vm._v("SMS")]) : _vm._e(), _vm._v(" "), !_vm.checkIfExists("web") ? _c("el-dropdown-item", {
    attrs: {
      command: "web"
    }
  }, [_vm._v("WEB")]) : _vm._e(), _vm._v(" "), !_vm.checkIfExists("chat") ? _c("el-dropdown-item", {
    attrs: {
      command: "chat"
    }
  }, [_vm._v("CHAT")]) : _vm._e(), _vm._v(" "), !_vm.checkIfExists("social") ? _c("el-dropdown-item", {
    attrs: {
      command: "social"
    }
  }, [_vm._v("SOCIAL")]) : _vm._e(), _vm._v(" "), !_vm.checkIfExists("phone") ? _c("el-dropdown-item", {
    attrs: {
      command: "phone"
    }
  }, [_vm._v("PHONE")]) : _vm._e(), _vm._v(" "), !_vm.checkIfExists("app") ? _c("el-dropdown-item", {
    attrs: {
      command: "app"
    }
  }, [_vm._v("APP")]) : _vm._e(), _vm._v(" "), !_vm.checkIfExists("email") ? _c("el-dropdown-item", {
    attrs: {
      command: "email"
    }
  }, [_vm._v("EMAIL")]) : _vm._e()], 1)], 1)], 1)], 2)], 1)], 1) : _vm._e(), _vm._v(" "), _vm.edit ? _c("el-tab-pane", {
    attrs: {
      label: "Tickets & Progress",
      name: "phase_tracking"
    }
  }, [_vm.offerings.length === 0 ? _c("el-empty", {
    attrs: {
      description: "Tracking unavailable"
    }
  }) : _vm.offerings.length === 1 ? _c("div", [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("table", {
    attrs: {
      id: "offering_details"
    }
  }, [_c("tr", [_c("th", [_vm._v("Title")]), _vm._v(" "), _c("td", [_c("el-tag", {
    attrs: {
      size: "mini"
    }
  }, [_vm._v(_vm._s(_vm.offerings[0].offering.title))])], 1)]), _vm._v(" "), _c("tr", [_c("th", [_vm._v("Category")]), _vm._v(" "), _c("td", [_c("el-tag", {
    attrs: {
      size: "mini"
    }
  }, [_vm._v(_vm._s(_vm.offerings[0].offering.offeringcategory))])], 1)])])]), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("div", {
    staticClass: "text-center"
  }, [_vm._v("Progress")]), _vm._v(" "), _c("div", {
    staticClass: "text-center"
  }, [_c("pie-chart-component", {
    staticClass: "text-center",
    attrs: {
      status: _vm.offerings[0].offering.external_status,
      height: "200px",
      width: "200px"
    }
  })], 1)])], 1)], 1) : _vm.offerings.length > 1 ? _c("el-tabs", {
    attrs: {
      "tab-position": "left"
    }
  }, _vm._l(_vm.offerings, function (offering, offering_index) {
    return _c("el-tab-pane", {
      key: "offering" + offering_index,
      attrs: {
        label: _vm.trimString(offering.offering.title, 20)
      }
    }, [_c("el-row", {
      attrs: {
        gutter: 20
      }
    }, [_c("el-col", {
      attrs: {
        span: 6
      }
    }, [_c("table", {
      attrs: {
        id: "offering_details"
      }
    }, [_c("tr", [_c("th", [_vm._v("Offering")]), _vm._v(" "), _c("td", [_c("el-tag", {
      attrs: {
        size: "mini"
      }
    }, [_vm._v(_vm._s(_vm.trimString(offering.offering.title, 50)))])], 1)]), _vm._v(" "), _c("tr", [_c("th", [_vm._v("Variant")]), _vm._v(" "), _c("td", [_c("el-tag", {
      attrs: {
        size: "mini"
      }
    }, [_vm._v(_vm._s(offering.offering.offeringcategory))])], 1)]), _vm._v(" "), _c("tr", [_c("th", [_vm._v("Created by")]), _vm._v(" "), _c("td", [_c("el-tag", {
      attrs: {
        size: "mini"
      }
    }, [_vm._v(_vm._s(offering.offering.createdby))])], 1)])])]), _vm._v(" "), _c("el-col", {
      attrs: {
        span: 12
      }
    }, [_c("table", {
      attrs: {
        id: "offering_details"
      }
    }, [_c("tr", [_c("th", {
      attrs: {
        colspan: "3"
      }
    }, [_vm._v("Progress")])]), _vm._v(" "), _c("tr", [_c("th", [_vm._v("Backlog")]), _vm._v(" "), _c("td", [_c("el-tag", {
      attrs: {
        size: "mini"
      }
    }, [_vm._v(_vm._s(offering.offering.external_status[0]))])], 1), _vm._v(" "), _c("td", {
      attrs: {
        rowspan: "12"
      }
    }, [_c("pie-chart-component", {
      staticClass: "text-center",
      attrs: {
        status: offering.offering.external_status,
        height: "200px",
        width: "200px"
      }
    })], 1)]), _vm._v(" "), _c("tr", [_c("th", [_vm._v("Candidate")]), _vm._v(" "), _c("td", [_c("el-tag", {
      attrs: {
        size: "mini"
      }
    }, [_vm._v(_vm._s(offering.offering.external_status[1]))])], 1)]), _vm._v(" "), _c("tr", [_c("th", [_vm._v("Approved")]), _vm._v(" "), _c("td", [_c("el-tag", {
      attrs: {
        size: "mini"
      }
    }, [_vm._v(_vm._s(offering.offering.external_status[2]))])], 1)]), _vm._v(" "), _c("tr", [_c("th", [_vm._v("Feature Design")]), _vm._v(" "), _c("td", [_c("el-tag", {
      attrs: {
        size: "mini"
      }
    }, [_vm._v(_vm._s(offering.offering.external_status[3]))])], 1)]), _vm._v(" "), _c("tr", [_c("th", [_vm._v("Functional Design")]), _vm._v(" "), _c("td", [_c("el-tag", {
      attrs: {
        size: "mini"
      }
    }, [_vm._v(_vm._s(offering.offering.external_status[4]))])], 1)]), _vm._v(" "), _c("tr", [_c("th", [_vm._v("Technical Design")]), _vm._v(" "), _c("td", [_c("el-tag", {
      attrs: {
        size: "mini"
      }
    }, [_vm._v(_vm._s(offering.offering.external_status[5]))])], 1)]), _vm._v(" "), _c("tr", [_c("th", [_vm._v("Build Planning")]), _vm._v(" "), _c("td", [_c("el-tag", {
      attrs: {
        size: "mini"
      }
    }, [_vm._v(_vm._s(offering.offering.external_status[6]))])], 1)]), _vm._v(" "), _c("tr", [_c("th", [_vm._v("Delivery")]), _vm._v(" "), _c("td", [_c("el-tag", {
      attrs: {
        size: "mini"
      }
    }, [_vm._v(_vm._s(offering.offering.external_status[7]))])], 1)]), _vm._v(" "), _c("tr", [_c("th", [_vm._v("Ready for Release")]), _vm._v(" "), _c("td", [_c("el-tag", {
      attrs: {
        size: "mini"
      }
    }, [_vm._v(_vm._s(offering.offering.external_status[8]))])], 1)]), _vm._v(" "), _c("tr", [_c("th", [_vm._v("Released")]), _vm._v(" "), _c("td", [_c("el-tag", {
      attrs: {
        size: "mini"
      }
    }, [_vm._v(_vm._s(offering.offering.external_status[9]))])], 1)]), _vm._v(" "), _c("tr", [_c("th", [_vm._v("Improvement")]), _vm._v(" "), _c("td", [_c("el-tag", {
      attrs: {
        size: "mini"
      }
    }, [_vm._v(_vm._s(offering.offering.external_status[10]))])], 1)]), _vm._v(" "), _c("tr", [_c("th", [_vm._v("Retired")]), _vm._v(" "), _c("td", [_c("el-tag", {
      attrs: {
        size: "mini"
      }
    }, [_vm._v(_vm._s(offering.offering.external_status[11]))])], 1)])])])], 1)], 1);
  }), 1) : _vm._e(), _vm._v(" "), _c("div", {
    staticStyle: {
      display: "none"
    }
  }, [_vm._v('\n        If no mapped offering exists  - say this in the UI. "tracking unavailable"\n        '), _c("br"), _vm._v("          Shows progress of journey using Features and Story tracking as the dimensions.\n\n        "), _c("ul", [_c("i", [_vm._v("MVP tracks progress by")]), _vm._v(" "), _c("li", [_vm._v("\n            Stage progress is based on the progress of steps being achieved (by features being achieved).\n            Stage progress only shows step progress - not features\n          ")]), _vm._v(" "), _c("li", [_vm._v('\n            Step progress is measured by mapped features. .\n            Step progress shows feature completion, and stories within the feature.\n            If story mapped to actions, the action mapped to the story/ies is shown.\n            Use a table, columns "stage, step, action, feature, story,story/features status, percentage" at minimum\n          ')]), _vm._v(" "), _c("li", [_vm._v("\n            Stories can not map to steps, features do. Stories map to actions, if actions exist, other wise they implicity map through the feature.\n          ")]), _vm._v(" "), _c("li", [_vm._v("\n            MVP doesn't need upstream mapping to Needs Completion / satisifaction.  Cross-reporting module does that.\n          ")])])])], 1) : _vm._e(), _vm._v(" "), _c("div", {
    staticClass: "fr mt-10"
  }, [!_vm.edit ? _c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.createPhase
    }
  }, [_c("svg-icon", {
    attrs: {
      "icon-class": "create"
    }
  }), _vm._v(" Create Stage")], 1) : _vm._e(), _vm._v(" "), _vm.edit ? _c("el-button", {
    attrs: {
      icon: "el-icon-edit",
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.updatePhaseDetails
    }
  }, [_vm._v("Update Stage")]) : _vm._e(), _vm._v(" "), _vm.edit ? _c("el-button", {
    attrs: {
      icon: "el-icon-delete",
      size: "mini",
      type: "danger"
    },
    on: {
      click: _vm.deletePhase
    }
  }, [_vm._v("Delete Stage")]) : _vm._e(), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini",
      icon: "el-icon-close"
    },
    on: {
      click: _vm.closeUI
    }
  }, [_vm._v("Cancel")])], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;