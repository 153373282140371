"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("g", {
    attrs: {
      refs: "lane-container"
    }
  }, [_c("g", {
    attrs: {
      refs: "lane-tools"
    }
  }, [_c("path", {
    attrs: {
      d: _vm.computeLaneToolPoints(_vm.index),
      fill: "none",
      "fill-opacity": "0.3",
      stroke: _vm.lane.color,
      "stroke-opacity": 0.3,
      "stroke-width": 3
    }
  }), _vm._v(" "), _vm.lane.name === "Tasks" ? _c("g", [_c("foreignObject", {
    attrs: {
      x: 160,
      y: 100 + _vm.index * 175,
      width: 40,
      height: 40
    }
  }, [_c("div", {
    attrs: {
      xmlns: "http://www.w3.org/1999/xhtml"
    }
  }, [_c("el-popover", {
    ref: "taskpopover",
    attrs: {
      placement: "top",
      title: "Add a Task",
      width: "380",
      trigger: "hover",
      "popper-class": "word-break-keep-all",
      "open-delay": 400,
      "close-delay": 400,
      content: "Tasks can be added to the activity lane"
    }
  }), _vm._v(" "), _c("i", {
    directives: [{
      name: "popover",
      rawName: "v-popover:taskpopover",
      arg: "taskpopover"
    }],
    staticClass: "el-icon-edit-outline mt-10",
    staticStyle: {
      "font-size": "21pt",
      color: "mediumblue"
    },
    attrs: {
      draggable: ""
    },
    on: {
      drag: function drag($event) {
        return _vm.setActiveDragElement("task");
      }
    }
  })], 1)]), _vm._v(" "), _c("text", {
    attrs: {
      x: 180,
      y: 150 + _vm.index * 175,
      "font-size": "0.8em",
      fill: "black",
      "text-anchor": "middle"
    }
  }, [_vm._v("Add Task")])]) : _vm._e(), _vm._v(" "), _vm.lane.name === "Goals" ? _c("g", [_c("foreignObject", {
    attrs: {
      x: 160,
      y: 100 + _vm.index * 175,
      width: 40,
      height: 40
    }
  }, [_c("el-popover", {
    ref: "goalpopover",
    attrs: {
      placement: "top",
      title: "Add User Goals",
      width: "380",
      trigger: "hover",
      "popper-class": "word-break-keep-all",
      "open-delay": 400,
      "close-delay": 400,
      content: "Define goals and add them to Goals lane"
    }
  }), _vm._v(" "), _c("div", {
    staticClass: "mt-5",
    attrs: {
      draggable: ""
    },
    on: {
      drag: function drag($event) {
        return _vm.setActiveDragElement("goal");
      }
    }
  }, [_c("svg-icon", {
    directives: [{
      name: "popover",
      rawName: "v-popover:goalpopover",
      arg: "goalpopover"
    }],
    staticStyle: {
      "font-size": "21pt",
      color: "forestgreen"
    },
    attrs: {
      "icon-class": "goal",
      title: "Create Goal"
    }
  })], 1)], 1), _vm._v(" "), _c("text", {
    attrs: {
      x: 180,
      y: 150 + _vm.index * 175,
      "font-size": "0.8em",
      fill: "black",
      "text-anchor": "middle"
    }
  }, [_vm._v("Add Goal")])]) : _vm._e(), _vm._v(" "), _vm.lane.name === "Experience" ? _c("g", [_vm.lane.name === "Experience" ? _c("foreignObject", {
    attrs: {
      x: 160,
      y: 100 + _vm.index * 175,
      width: 40,
      height: 80
    }
  }, [_c("el-popover", {
    ref: "painpopover",
    attrs: {
      placement: "top",
      title: "Add User Pain point",
      width: "380",
      trigger: "hover",
      "popper-class": "word-break-keep-all",
      "open-delay": 400,
      "close-delay": 400,
      content: "Use pain points to describe negative experiences relating to a Task or Phase. Add them to the experience lane."
    }
  }), _vm._v(" "), _c("i", {
    directives: [{
      name: "popover",
      rawName: "v-popover:painpopover",
      arg: "painpopover"
    }],
    staticClass: "el-icon-star-on mt-5",
    staticStyle: {
      "font-size": "21pt",
      color: "red"
    },
    attrs: {
      draggable: ""
    },
    on: {
      drag: function drag($event) {
        return _vm.setActiveDragElement("pain");
      }
    }
  }), _vm._v(" "), _c("el-popover", {
    ref: "gainpopover",
    attrs: {
      placement: "top",
      title: "Add Gain",
      width: "380",
      trigger: "hover",
      "popper-class": "word-break-keep-all",
      "open-delay": 400,
      "close-delay": 400,
      content: "Use Gains to describe an opportunity or positive experience relating to a Task or Phase. Add them to the experience lane."
    }
  }), _vm._v(" "), _c("i", {
    directives: [{
      name: "popover",
      rawName: "v-popover:gainpopover",
      arg: "gainpopover"
    }],
    staticClass: "el-icon-star-on mt-10",
    staticStyle: {
      "font-size": "21pt",
      color: "forestgreen"
    },
    attrs: {
      draggable: ""
    },
    on: {
      drag: function drag($event) {
        return _vm.setActiveDragElement("gain");
      }
    }
  })], 1) : _vm._e(), _vm._v(" "), _c("text", {
    attrs: {
      x: 180,
      y: 140 + _vm.index * 175,
      "font-size": "0.8em",
      fill: "black",
      "text-anchor": "middle"
    }
  }, [_vm._v("Add Pain")]), _vm._v(" "), _c("text", {
    attrs: {
      x: 180,
      y: 180 + _vm.index * 175,
      "font-size": "0.8em",
      fill: "black",
      "text-anchor": "middle"
    }
  }, [_vm._v("Add Gain")])]) : _vm._e()]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "lane"
    }
  }, [_c("rect", {
    attrs: {
      x: 225,
      y: 100 + _vm.index * 175,
      width: _vm.laneParam.title_width * 1.4,
      height: _vm.laneParam.height,
      fill: "#000000",
      "stroke-width": 3,
      stroke: _vm.lane.color
    },
    on: {
      click: function click($event) {
        return _vm.editLane();
      }
    }
  }), _vm._v(" "), _c("text", {
    attrs: {
      x: 225,
      y: 205 + _vm.index * 175,
      "font-size": "1.0em",
      transform: _vm.generateLaneTextTransform(250, 215 + _vm.index * 175),
      fill: "white",
      "font-weight": "bold",
      "text-anchor": "start"
    }
  }, [_vm._v(_vm._s(_vm.lane.name))]), _vm._v(" "), _c("text", {
    attrs: {
      x: 235,
      y: 205 + _vm.index * 175,
      "font-size": "0.7em",
      transform: _vm.generateLaneTextTransform(260, 215 + _vm.index * 175),
      fill: "white",
      "font-weight": "lighter",
      "text-anchor": "start"
    }
  }, [_vm._v(_vm._s(_vm.lane.description))]), _vm._v(" "), _c("rect", {
    attrs: {
      x: 265,
      y: 100 + _vm.index * 175,
      width: _vm.laneWidth,
      height: _vm.laneParam.height,
      fill: "gray",
      "fill-opacity": _vm.index % 2 === 0 ? 0.3 : 0.1,
      "stroke-width": 2,
      stroke: "gray",
      "stroke-opacity": 0.2
    }
  }), _vm._v(" "), _c("g", {
    attrs: {
      refs: "notes_list_icon"
    },
    on: {
      click: function click($event) {
        $event.preventDefault();
        return _vm.openNotesList.apply(null, arguments);
      }
    }
  }, [_c("text", {
    staticClass: "element-icon",
    attrs: {
      x: _vm.noteX,
      y: _vm.noteY,
      fill: "gold",
      "text-anchor": "middle",
      "font-size": "2.8em",
      "font-weight": "lighter"
    }
  }, [_vm._v("\n        🗎\n      ")]), _vm._v(" "), _c("text", {
    attrs: {
      x: _vm.noteX,
      y: _vm.noteY - 6,
      fill: "black",
      "text-anchor": "middle",
      "font-size": "1.0em",
      "font-weight": "bold"
    }
  }, [_vm._v("\n      " + _vm._s(_vm.noteCount) + "\n    ")])]), _vm._v(" "), !_vm.lane.default ? _c("g", {
    attrs: {
      refs: "deleteLaneIcon"
    },
    on: {
      click: function click($event) {
        return _vm.deleteLane(_vm.index);
      }
    }
  }, [_c("circle", {
    attrs: {
      cx: 230 + _vm.laneParam.title_width / 2,
      cy: 115 + _vm.index * 175,
      r: "12",
      stroke: "black",
      "stroke-width": "1",
      fill: "#F56C6C"
    }
  }), _vm._v(" "), _c("line", {
    attrs: {
      x1: 230 + _vm.laneParam.title_width / 2,
      y1: 110 + _vm.index * 175,
      x2: 230 + _vm.laneParam.title_width / 2,
      y2: 120 + _vm.index * 175,
      stroke: "white",
      "stroke-width": "2",
      fill: "none",
      "fill-opacity": "1",
      "stroke-opacity": "0.9"
    }
  })]) : _vm._e()])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;