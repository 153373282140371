"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _axios = _interopRequireDefault(require("axios"));
var _default = exports.default = {
  name: 'Comment',
  props: {
    entityId: {
      type: String,
      required: true
    },
    entityName: {
      type: String,
      required: true,
      default: ''
    }
  },
  data: function data() {
    return {
      users: [],
      discussions: [],
      comment: ''
    };
  },
  watch: {
    entityId: {
      handler: function handler(newValue) {
        this.refresh();
      },
      deep: true
    }
  },
  mounted: function mounted() {
    this.refresh();
  },
  methods: {
    refresh: function refresh() {
      this.retrieveUserList();
      this.retrieveEntityComments();
    },
    addComment: function addComment() {
      var _this = this;
      var headers = {
        'Content-Type': 'application/json'
        // 'Authorization': 'Bearer '+getJWTToken()
      };
      var create_body = {
        data: [{
          'entityId': this.entityId,
          'entityName': this.entityName,
          'comment': this.comment
        }],
        'header': {
          'userId': this.$store.getters.id,
          'partyId': this.$store.getters.partyId
        }
      };
      _axios.default.post(process.env.VUE_APP_REST_API_ENDPOINT + 'social/comment', create_body, {
        headers: headers
      }).then(function (response) {
        if (response.data.data.status === 'SUCCESS') {
          _this.comment = '';
          _this.retrieveEntityComments();
        }
      }).catch(function (error) {
        console.log(error);
      });
    },
    computeMessengerAvatar: function computeMessengerAvatar(user_id) {
      for (var i = 0; i < this.users.length; i++) {
        if (this.users[i].id === user_id) {
          if (this.users[i].imageURL) {
            return 'background-image: url(' + this.users[i].imageURL + ')';
          }
        }
      }
      return '';
    },
    computeHumanFriendlyDateDifference: function computeHumanFriendlyDateDifference(date) {
      var today = this.$moment();
      return this.$moment.tz(date, 'Australia/Sydney').from(today);
    },
    getUserFromUserID: function getUserFromUserID(user_id) {
      for (var i = 0; i < this.users.length; i++) {
        if (this.users[i].id === user_id) {
          return this.computeUserName(this.users[i]);
        }
      }
    },
    computeUserName: function computeUserName(user) {
      return user.firstName + ' ' + user.lastName;
    },
    computeHumanFriendlyDate: function computeHumanFriendlyDate(date) {
      return this.$moment(date, 'YYYY-MM-DD HH:mm:ss').format('ll');
    },
    checkIfUserIsAuthor: function checkIfUserIsAuthor(author) {
      var username = this.$store.getters.firstName + ' ' + this.$store.getters.lastName;
      if (username === author) {
        return true;
      }
      return false;
    },
    retrieveEntityComments: function retrieveEntityComments() {
      var _this2 = this;
      this.loading = true;
      var headers = {
        'Content-Type': 'application/json',
        'x-party-id': this.$store.getters.partyId,
        'x-user-id': this.$store.getters.id
      };
      _axios.default.get(process.env.VUE_APP_REST_API_ENDPOINT + 'social/comment/entity/' + this.entityId + '?extended=LEVEL0&page=1', {
        headers: headers
      }).then(function (response) {
        _this2.loading = false;
        _this2.discussions = _this2._.reverse(response.data.data);
      }).catch(function (error) {
        if (error.response.status === 400) {
          _this2.loading = false;
          _this2.discussions = [];
        } else {
          _this2.$notify({
            title: 'Error',
            message: error.response.message,
            type: 'error',
            duration: 2000
          });
        }
      });
    },
    retrieveUserList: function retrieveUserList() {
      var _this3 = this;
      this.loading = true;
      var headers = {
        'Content-Type': 'application/json',
        'x-party-id': this.$store.getters.partyId,
        'x-user-id': this.$store.getters.id
      };
      _axios.default.get(process.env.VUE_APP_REST_API_ENDPOINT + 'user/?extended=LEVEL0', {
        headers: headers
      }).then(function (response) {
        _this3.loading = false;
        _this3.users = response.data.data;
      }).catch(function (error) {
        if (error.response.status === 400) {
          _this3.loading = false;
          _this3.$notify({
            title: 'Error',
            message: error.response.message,
            type: 'error',
            duration: 2000
          });
        }
      });
    }
  }
};