"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _validate = require("@/utils/validate");
var _default2 = exports.default = {
  props: {
    business: {
      type: Object,
      default: function _default() {
        return {
          businessName: '',
          tradingName: '',
          legalName: '',
          businessId1: {
            name: '',
            value: ''
          },
          businessId2: {
            name: '',
            value: ''
          },
          businessId3: {
            name: '',
            value: ''
          },
          primaryPartyContactId: '',
          secondaryPartyContactId: '',
          address: '',
          suburb: '',
          city: '',
          state: '',
          country: '',
          postCode: ''
        };
      }
    }
  },
  data: function data() {
    var validateEmail = function validateEmail(rule, value, callback) {
      // Uncomment once backend auth service is complete
      if (!(0, _validate.validEmail)(value)) {
        callback(new Error('Please enter a valid email address'));
      } else {
        callback();
      }
    };
    var softValidateEmail = function softValidateEmail(rule, value, callback) {
      // Uncomment once backend auth service is complete
      if (value !== '' && !(0, _validate.validEmail)(value)) {
        callback(new Error('Please enter a valid email address'));
      } else {
        callback();
      }
    };
    var validateBusinessName = function validateBusinessName(rule, value, callback) {
      // Uncomment once backend auth service is complete
      if (value === '') {
        callback(new Error('Please enter a valid business name'));
      } else {
        callback();
      }
    };
    var validateAddress = function validateAddress(rule, value, callback) {
      // Uncomment once backend auth service is complete
      if (value === '') {
        callback(new Error('Please enter a valid Address'));
      } else {
        callback();
      }
    };
    var validateCity = function validateCity(rule, value, callback) {
      // Uncomment once backend auth service is complete
      if (value === '') {
        callback(new Error('Please enter a valid City'));
      } else {
        callback();
      }
    };
    var validateCountry = function validateCountry(rule, value, callback) {
      // Uncomment once backend auth service is complete
      if (value === '') {
        callback(new Error('Please enter a valid Country'));
      } else {
        callback();
      }
    };
    var validateState = function validateState(rule, value, callback) {
      // Uncomment once backend auth service is complete
      if (value === '') {
        callback(new Error('Please enter a valid State'));
      } else {
        callback();
      }
    };
    return {
      companyRules: {
        businessName: [{
          required: true,
          trigger: 'none',
          validator: validateBusinessName
        }],
        address: [{
          required: true,
          trigger: 'none',
          validator: validateAddress
        }],
        city: [{
          required: true,
          trigger: 'none',
          validator: validateCity
        }],
        country: [{
          required: true,
          trigger: 'none',
          validator: validateCountry
        }],
        state: [{
          required: true,
          trigger: 'none',
          validator: validateState
        }],
        primaryPartyContactId: [{
          required: true,
          trigger: 'none',
          validator: validateEmail
        }],
        secondaryPartyContactId: [{
          trigger: 'none',
          validator: softValidateEmail
        }]
      }
    };
  },
  created: function created() {},
  methods: {
    deleteCompany: function deleteCompany() {
      var _this = this;
      this.$confirm('This will permanently delete all details related to company including users. Continue?', 'Warning', {
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(function () {
        _this.$emit('delete-business', _this.business);
      }).catch(function () {
        _this.$message({
          type: 'info',
          message: 'Delete canceled'
        });
      });
    },
    submit: function submit() {
      var _this2 = this;
      this.$refs.companyForm.validate(function (valid) {
        if (valid) {
          _this2.$emit('update-business', _this2.business);
        } else {
          return false;
        }
      });
    }
  }
};