"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _PieChart = _interopRequireDefault(require("./PieChart"));
var _default2 = exports.default = {
  name: 'PhaseDetail',
  components: {
    PieChart: _PieChart.default
  },
  props: {
    x: {
      type: Number,
      default: 0
    },
    y: {
      type: Number,
      default: 0
    },
    width: {
      type: Number,
      default: 0
    },
    phasex: {
      type: Number,
      default: 0
    },
    phasewidth: {
      type: Number,
      default: 0
    },
    index: {
      type: Number,
      default: 0
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    phase: {
      type: Object,
      default: function _default() {
        return {
          title: '',
          priority: '',
          importance: '',
          description: '',
          relations: []
        };
      }
    },
    steps: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    experiences: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    ideas: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      isHover: false,
      focusHover: false,
      stepHover: false,
      gainHover: false,
      painHover: false,
      wowHover: false,
      stepChunkSize: 5,
      experienceChunkSize: 5,
      activeStepChunk: 0,
      activeExperienceChunk: 0
    };
  },
  computed: {
    stepsList: function stepsList() {
      var stepArray = [];
      if (this.phase.relations === null) {
        return this._.chunk(stepArray, this.stepChunkSize);
      } else {
        var related_step_ids = this.phase.relations;
        for (var i = 0; i < related_step_ids.length; i++) {
          for (var j = 0; j < this.steps.length; j++) {
            if (related_step_ids[i] === this.steps[j].journeyStep.id) {
              stepArray.push(this.steps[j]);
            }
          }
        }
        return this._.chunk(stepArray, this.stepChunkSize);
      }
    },
    experiencesList: function experiencesList() {
      var experienceArray = [];
      for (var i = 0; i < this.experiences.length; i++) {
        if (this.experiences[i].journeyExperience.relations.phase === this.phase.id) {
          experienceArray.push(this.experiences[i]);
        }
      }
      return this._.chunk(experienceArray, this.experienceChunkSize);
    },
    computeHeight: function computeHeight() {
      var step_height = 0;
      var experience_height = 0;
      if (this.stepsList[this.activeStepChunk]) {
        step_height = this.stepsList[this.activeStepChunk].length * 35;
      }
      if (this.experiencesList[this.activeExperienceChunk]) {
        experience_height = this.experiencesList[this.activeExperienceChunk].length * 32 + 20;
      }
      return step_height + experience_height + 220;
    },
    computePhaseDetailsStepListHeight: function computePhaseDetailsStepListHeight() {
      var step_height = 0;
      if (this.stepsList[this.activeStepChunk]) {
        step_height = this.stepsList[this.activeStepChunk].length * 35;
      }
      return step_height;
    }
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    moveStepUp: function moveStepUp(step_index) {
      this.$emit('move-stage-up', step_index);
    },
    moveStepDown: function moveStepDown(step_index) {
      this.$emit('move-stage-down', step_index);
    },
    generateMarker: function generateMarker() {
      var x_start = this.phasex + this.phasewidth / 2;
      var y_start = this.y - 10;
      var x_mid = this.phasex + this.phasewidth / 2 - 10;
      var y_mid = this.y;
      var x_last = this.phasex + this.phasewidth / 2 + 10;
      var y_last = this.y;
      return 'M ' + x_start + ' ' + y_start + ' L ' + x_mid + ' ' + y_mid + ' L ' + x_last + ' ' + y_last + '';
    },
    closePhaseDetailsPopover: function closePhaseDetailsPopover() {
      this.$emit('close');
    },
    displayPhaseDetails: function displayPhaseDetails() {
      this.$emit('details', this.index);
    },
    createPhaseIdea: function createPhaseIdea() {
      this.$emit('create-phase-idea', this.phase.title);
    },
    computePriority: function computePriority(pri) {
      switch (pri) {
        case 'Very Low':
          return 1;
        case 'Low':
          return 2;
        case 'Medium':
          return 3;
        case 'High':
          return 4;
        case 'Very High':
          return 5;
      }
    },
    computeCustomerValueIcon: function computeCustomerValueIcon(imp) {
      switch (imp) {
        case 'Very Low':
          return 'dollar';
        case 'Low':
          return 'dollar';
        case 'Medium':
          return 'dollar-2';
        case 'High':
          return 'dollar-2';
        case 'Very High':
          return 'dollar-3';
        default:
          return 'dollar';
      }
    },
    computeValueFontSize: function computeValueFontSize(value1) {
      if (value1 === 'Very Low') {
        return 'lowestValue';
      } else if (value1 === 'Low') {
        return 'lowValue';
      }
    },
    createTask: function createTask() {
      this.$emit('create-task', this.index);
    },
    deletePhase: function deletePhase() {
      this.$emit('delete', this.index);
    },
    deleteStep: function deleteStep(step_index) {
      this.$emit('delete-step', step_index);
    },
    deleteExperience: function deleteExperience(experience_id) {
      this.$emit('delete-experience', experience_id);
    },
    focusMode: function focusMode() {
      this.$emit('focus-mode', this.phase, this.index);
    },
    addChallenge: function addChallenge() {
      this.$emit('create-challenge', this.index);
    },
    addGain: function addGain() {
      this.$emit('create-gain', this.index);
    },
    addPain: function addPain() {
      this.$emit('create-pain', this.index);
    },
    addWow: function addWow() {
      this.$emit('create-wow', this.index);
    },
    truncateString: function truncateString(string, length) {
      return this._.truncate(string, {
        'length': length,
        'separator': ' '
      });
    }
  }
};