"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default2 = exports.default = {
  name: 'EntityFilter',
  components: {},
  props: {
    entities: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      loading: false,
      filterDisplay: false,
      search_entities: {
        filters: {
          status: ['All Statuses']
        }
      },
      statuses: [{
        label: 'All Statuses',
        value: 'All Statuses'
      }, {
        label: 'Proposed',
        value: 'Proposed'
      }, {
        label: 'Accepted',
        value: 'Accepted'
      }, {
        label: 'In Progress',
        value: 'In progress'
      }, {
        label: 'On Hold',
        value: 'On hold'
      }, {
        label: 'Starting Up',
        value: 'Starting Up'
      }, {
        label: 'Overdue',
        value: 'Overdue'
      }, {
        label: 'Achieved',
        value: 'Achieved'
      }]
    };
  },
  computed: {},
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    filterList: function filterList() {
      this.$emit('update-search-filter', this.search_entities.filters.status);
    },
    closeFilterDialog: function closeFilterDialog() {
      this.$emit('close');
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.$emit('filtered-list', val);
    },
    removeSearchFilterTag: function removeSearchFilterTag(type) {
      var index = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      switch (type) {
        case 'status':
          this.search_entities.filters.status.splice(index, 1);
          break;
      }
    },
    truncateString: function truncateString(string, length) {
      return this._.truncate(string, {
        'length': length,
        'separator': '...'
      });
    }
  }
};