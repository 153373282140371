"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _default = exports.default = {
  name: 'Idea',
  components: {},
  props: {
    x: {
      type: Number,
      default: 0
    },
    y: {
      type: Number,
      default: 0
    },
    index: {
      type: Number,
      default: 0
    },
    label: {
      type: String,
      default: ''
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    showLabel: {
      type: Boolean,
      default: false
    },
    toolbar: {
      type: Boolean,
      default: false
    },
    create: {
      type: Boolean,
      default: false
    },
    highlight: {
      type: String,
      default: null
    }
  },
  data: function data() {
    return {
      isHover: false
    };
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    computeBGStyle: function computeBGStyle() {
      if (this.isHover) {
        return 'background-color:rgba(200,200,200,0.1);border-radius:15px;border:1px solid #4672c4;';
      } else {
        return 'background-color:white;border-radius:15px;';
      }
    },
    computeStyle: function computeStyle() {
      if (this.isHover && !this.toolbar) {
        return 'font-size: 30px;color:#E6A23C;background-color: #DDD;border-radius:10px';
      } else {
        return 'font-size: 16px; padding: 1pt; color:#E6A23C; border-radius:15px; width: 27px; min-height: 20px';
      }
    },
    onMouseOver: function onMouseOver() {
      this.isHover = true;
      this.$emit('mouseover', this.x, this.y, this.label, this.relations, this.index);
    },
    onMouseLeave: function onMouseLeave() {
      this.isHover = false;
      this.$emit('mouseleave');
    },
    onSubmit: function onSubmit() {},
    generateUniqueID: function generateUniqueID() {
      return 'idea_' + this.index;
    }
  }
};