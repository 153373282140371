"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _StoryList = _interopRequireDefault(require("./StoryList"));
var _DataTypeDetails = _interopRequireDefault(require("./DataTypeDetails"));
var _default = exports.default = {
  name: 'FeatureComponent',
  components: {
    StoryListComponent: _StoryList.default,
    DataTypeDetailsComponent: _DataTypeDetails.default
  },
  props: {
    cx: {
      type: Number,
      required: true,
      default: 0
    },
    cy: {
      type: Number,
      required: true,
      default: 0
    },
    feature: {
      type: String,
      required: true,
      default: ''
    }
  },
  data: function data() {
    return {
      showDetails: false,
      showStories: false,
      showDataTypeDetails: false
    };
  },
  methods: {
    computePathPoints: function computePathPoints(x2, y2) {
      var start_x1 = this.cx + 50;
      var start_y1 = this.cy;
      var start_x2 = x2;
      var start_y2 = y2 + 12;
      var end_x1 = start_x1 + (start_x2 - start_x1) / 2;
      var end_y1 = start_y1;
      var end_x2 = start_x2 - (start_x2 - start_x1) / 2;
      var end_y2 = start_y2;
      return 'M' + start_x1 + ',' + start_y1 + ' C' + end_x1 + ',' + end_y1 + ' ' + end_x2 + ',' + end_y2 + ' ' + start_x2 + ',' + start_y2;
    },
    clickAddMockup: function clickAddMockup() {
      this.$notify({
        title: 'Add Mockup Event',
        message: 'Add Mockup UI to be displayed at this event',
        type: 'info',
        duration: 2000
      });
    },
    clickAddWireframe: function clickAddWireframe() {
      this.$notify({
        title: 'Add Wireframe Event',
        message: 'Add Wireframe UI to be displayed at this event',
        type: 'info',
        duration: 2000
      });
    },
    clickAddComponents: function clickAddComponents() {
      this.$notify({
        title: 'Add Components Event',
        message: 'Add Components UI to be displayed at this event',
        type: 'info',
        duration: 2000
      });
    },
    selectStory: function selectStory(story) {
      this.$notify({
        title: story + ' selected',
        message: story + ' UI to be displayed at this event',
        type: 'info',
        duration: 2000
      });
    },
    openDataTypeDetails: function openDataTypeDetails() {
      this.showStories = false;
      this.showDataTypeDetails = !this.showDataTypeDetails;
    },
    openStoryList: function openStoryList() {
      this.showDataTypeDetails = false;
      this.showStories = !this.showStories;
    }
  }
};