"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "home-dashboard-workitems-container",
    style: _vm.computeDashboardHeight
  }, [_c("el-row", {
    staticStyle: {
      "border-bottom": "2px solid #304174",
      "background-color": "#304174",
      "border-top-left-radius": "5pt",
      "border-bottom-left-radius": "5pt",
      "margin-left": "3pt",
      height: "28pt"
    }
  }, [_c("el-col", {
    staticClass: "pl-5",
    staticStyle: {
      "margin-top": "1px",
      "padding-top": "8pt"
    },
    attrs: {
      span: 5
    }
  }, [_c("span", {
    staticStyle: {
      color: "white",
      "font-size": "12pt"
    },
    on: {
      click: function click($event) {
        return _vm.collapseNavigator();
      }
    }
  }, [_vm._v("Work Items")]), _vm._v(" "), _vm.selectedNav.domain ? _c("el-tag", {
    attrs: {
      size: "mini",
      type: "secondary",
      closable: ""
    },
    on: {
      close: function close($event) {
        return _vm.removeFilter();
      }
    }
  }, [_vm._v("Filter by: " + _vm._s(_vm.selectedNav.domain))]) : _vm._e()], 1), _vm._v(" "), _c("el-col", {
    staticClass: "text-center",
    staticStyle: {
      "margin-top": "5px",
      "padding-top": "4pt"
    },
    attrs: {
      span: 12
    }
  }, [_c("el-dropdown", {
    staticStyle: {
      height: "18pt"
    },
    on: {
      command: _vm.handleWorkItemMenuChange
    }
  }, [_c("span", {
    staticClass: "el-dropdown-link",
    staticStyle: {
      color: "white"
    }
  }, [_vm._v("\n          " + _vm._s(_vm.workItemSizeActiveMenuItem)), _c("i", {
    staticClass: "el-icon-arrow-down el-icon--right"
  })]), _vm._v(" "), _c("el-dropdown-menu", {
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, [_c("el-dropdown-item", {
    attrs: {
      command: "My Actions"
    }
  }, [_vm._v("My Actions")]), _vm._v(" "), _c("el-dropdown-item", {
    attrs: {
      command: "My Discussions"
    }
  }, [_vm._v("My Discussions")])], 1)], 1)], 1), _vm._v(" "), _c("el-col", {
    staticClass: "text-center",
    staticStyle: {
      "padding-top": "2pt"
    },
    attrs: {
      span: 4
    }
  }, [_c("el-input", {
    staticClass: "width85",
    staticStyle: {
      "margin-top": "2px"
    },
    attrs: {
      type: "text",
      size: "mini",
      placeholder: "Search...",
      "prefix-icon": "el-icon-search"
    },
    model: {
      value: _vm.workItemSearch,
      callback: function callback($$v) {
        _vm.workItemSearch = $$v;
      },
      expression: "workItemSearch"
    }
  }), _vm._v(" "), _c("el-dropdown", {
    attrs: {
      trigger: "click"
    }
  }, [_c("div", {
    staticClass: "ml-10"
  }, [_c("svg-icon", {
    staticStyle: {
      stroke: "white"
    },
    attrs: {
      "icon-class": "columns"
    }
  })], 1), _vm._v(" "), _c("el-dropdown-menu", {
    staticClass: "column_filter",
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, [_vm.workItemSizeActiveMenuItem === "My Actions" ? [_c("el-dropdown-item", {
    staticClass: "filter_dropdown_item"
  }, [_c("el-checkbox", {
    staticClass: "column_filter_label",
    model: {
      value: _vm.columns_actions.id,
      callback: function callback($$v) {
        _vm.$set(_vm.columns_actions, "id", $$v);
      },
      expression: "columns_actions.id"
    }
  }, [_vm._v("ID")])], 1), _vm._v(" "), _c("el-dropdown-item", {
    staticClass: "filter_dropdown_item"
  }, [_c("el-checkbox", {
    staticClass: "column_filter_label",
    model: {
      value: _vm.columns_actions.artefact,
      callback: function callback($$v) {
        _vm.$set(_vm.columns_actions, "artefact", $$v);
      },
      expression: "columns_actions.artefact"
    }
  }, [_vm._v("Artefact")])], 1), _vm._v(" "), _c("el-dropdown-item", {
    staticClass: "filter_dropdown_item"
  }, [_c("el-checkbox", {
    staticClass: "column_filter_label",
    model: {
      value: _vm.columns_actions.name,
      callback: function callback($$v) {
        _vm.$set(_vm.columns_actions, "name", $$v);
      },
      expression: "columns_actions.name"
    }
  }, [_vm._v("Name")])], 1), _vm._v(" "), _c("el-dropdown-item", {
    staticClass: "filter_dropdown_item"
  }, [_c("el-checkbox", {
    staticClass: "column_filter_label",
    model: {
      value: _vm.columns_actions.status,
      callback: function callback($$v) {
        _vm.$set(_vm.columns_actions, "status", $$v);
      },
      expression: "columns_actions.status"
    }
  }, [_vm._v("Status")])], 1), _vm._v(" "), _c("el-dropdown-item", {
    staticClass: "filter_dropdown_item"
  }, [_c("el-checkbox", {
    staticClass: "column_filter_label",
    model: {
      value: _vm.columns_actions.priority,
      callback: function callback($$v) {
        _vm.$set(_vm.columns_actions, "priority", $$v);
      },
      expression: "columns_actions.priority"
    }
  }, [_vm._v("Priority")])], 1), _vm._v(" "), _c("el-dropdown-item", {
    staticClass: "filter_dropdown_item"
  }, [_c("el-checkbox", {
    staticClass: "column_filter_label",
    model: {
      value: _vm.columns_actions.due,
      callback: function callback($$v) {
        _vm.$set(_vm.columns_actions, "due", $$v);
      },
      expression: "columns_actions.due"
    }
  }, [_vm._v("Due")])], 1), _vm._v(" "), _c("el-dropdown-item", {
    staticClass: "filter_dropdown_item"
  }, [_c("el-checkbox", {
    staticClass: "column_filter_label",
    model: {
      value: _vm.columns_actions.created,
      callback: function callback($$v) {
        _vm.$set(_vm.columns_actions, "created", $$v);
      },
      expression: "columns_actions.created"
    }
  }, [_vm._v("Created")])], 1), _vm._v(" "), _c("el-dropdown-item", {
    staticClass: "filter_dropdown_item"
  }, [_c("el-checkbox", {
    staticClass: "column_filter_label",
    model: {
      value: _vm.columns_actions.assigned,
      callback: function callback($$v) {
        _vm.$set(_vm.columns_actions, "assigned", $$v);
      },
      expression: "columns_actions.assigned"
    }
  }, [_vm._v("Assigned")])], 1)] : _vm._e()], 2)], 1)], 1)], 1), _vm._v(" "), _c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.workItemSizeActiveMenuItem === "My Actions" ? _c("el-row", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    staticClass: "text-center",
    attrs: {
      span: 24
    }
  }, [_c("action-component", {
    attrs: {
      actions: _vm.actions,
      users: _vm.users,
      entity: {
        assignedTo: _vm.$store.getters.id,
        createdBy: _vm.$store.getters.id
      },
      "search-nav": _vm.selectedNav,
      "work-item-search": _vm.workItemSearch,
      columns: _vm.columns_actions,
      expanded: _vm.workItemSize > 90 ? true : false
    }
  })], 1)], 1) : _vm._e()], 1), _vm._v(" "), _c("transition", {
    attrs: {
      name: "fade"
    }
  }, [_vm.workItemSizeActiveMenuItem === "My Discussions" ? _c("el-row", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  })], 1) : _vm._e()], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;