"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("g", {
    attrs: {
      id: "process"
    },
    on: {
      click: _vm.openDialog
    }
  }, [_c("rect", {
    staticStyle: {
      fill: "none"
    },
    attrs: {
      x: parseInt(_vm.x),
      y: parseInt(_vm.y),
      rx: "2.5",
      ry: "2.5",
      width: "165",
      height: "50"
    }
  }), _vm._v(" "), _c("rect", {
    style: _vm.updateStyle(),
    attrs: {
      x: parseInt(_vm.x) + 2,
      y: parseInt(_vm.y) + 2.5,
      rx: "2.5",
      ry: "2.5",
      width: "150",
      height: "45"
    }
  }), _vm._v(" "), _c("text", {
    attrs: {
      x: parseInt(_vm.x),
      y: parseInt(_vm.y) + 15,
      fill: "black",
      "font-size": "12",
      "font-weight": "lighter",
      "letter-spacing": "2",
      dy: "0",
      "text-anchor": "left"
    }
  }, [_c("tspan", {
    attrs: {
      x: parseInt(_vm.x) + 12,
      dy: ".7em"
    }
  }, [_vm._v(_vm._s(_vm.text1))]), _vm._v(" "), _c("tspan", {
    attrs: {
      x: parseInt(_vm.x) + 12,
      dy: "1.4em"
    }
  }, [_vm._v(_vm._s(_vm.text2))])]), _vm._v(" "), _c("rect", {
    style: _vm.updateStyleBG(),
    attrs: {
      x: parseInt(_vm.x) + 95,
      y: parseInt(_vm.y) + 5,
      rx: "2.5",
      ry: "2.5",
      width: "55",
      height: "12.5"
    }
  }), _vm._v(" "), _vm.process === "linear" ? _c("text", {
    attrs: {
      x: parseInt(_vm.x) + 102,
      y: parseInt(_vm.y) + 15,
      fill: "black",
      "font-size": "10",
      "font-weight": "bold"
    }
  }, [_vm._v("Done ")]) : _vm._e(), _vm._v(" "), _vm.process === "ongoing" ? _c("text", {
    attrs: {
      x: parseInt(_vm.x) + 102,
      y: parseInt(_vm.y) + 15,
      fill: "black",
      "font-size": "10",
      "font-weight": "bold"
    }
  }, [_vm._v("Ongoing")]) : _vm._e(), _vm._v(" "), _vm.process === "urgent" ? _c("text", {
    attrs: {
      x: parseInt(_vm.x) + 102,
      y: parseInt(_vm.y) + 15,
      fill: "black",
      "font-size": "10",
      "font-weight": "bold"
    }
  }, [_vm._v("Urgent")]) : _vm._e()]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;