"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _ = _interopRequireDefault(require("@/assets/503_images/503.png"));
var _default = exports.default = {
  name: 'Page503',
  data: function data() {
    return {
      errPng: _.default
    };
  },
  methods: {
    back: function back() {
      if (this.$route.query.noGoBack) {
        this.$router.push({
          path: '/'
        });
      } else {
        this.$router.go(-1);
      }
    }
  }
};