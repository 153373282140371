"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _axios = _interopRequireDefault(require("axios"));
var _default2 = exports.default = {
  name: 'ImportProject',
  components: {},
  props: {
    projects: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    integration: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    integrationIndex: {
      type: Number,
      required: true
    }
  },
  data: function data() {
    return {
      loading: false,
      selectedProjects: []
    };
  },
  computed: {
    filteredProjects: function filteredProjects() {
      return this.projects.filter(function (project) {
        return !project.isImported && !project.isLocalProject;
      });
    }
  },
  watch: {},
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    handleSelectionChange: function handleSelectionChange(val) {
      this.selectedProjects = val;
    },
    closeUI: function closeUI() {
      this.selectedProjects = [];
      this.$emit('close');
    },
    importProjects: function importProjects() {
      this.$emit('import', this.selectedProjects);
    },
    syncProjects: function syncProjects() {
      var _this = this;
      this.loading = true;
      var headers = {
        'Content-Type': 'application/json',
        'x-party-id': this.$store.getters.partyId,
        'x-user-id': this.$store.getters.id
      };
      _axios.default.get(process.env.VUE_APP_REST_API_ENDPOINT + 'tprty/' + this.integration.id + '/sync/project', {
        headers: headers
      }).then(function (response) {
        _this.loading = false;
        if (response.data.status === 'SUCCESS') {
          console.log('Synced');
        }
      }).catch(function (error) {
        _this.loading = false;
        console.log(error);
      });
    }
  }
};