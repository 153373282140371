"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("g", {
    attrs: {
      refs: "entity_lane"
    }
  }, [_c("g", {
    attrs: {
      refs: "entity_details"
    }
  }, [_c("rect", {
    attrs: {
      x: 1,
      y: _vm.y,
      width: _vm.width,
      height: _vm.height,
      rx: "2",
      stroke: "#4ca6a6",
      "stroke-width": "0.5",
      fill: "#b2d8d8",
      "fill-opacity": "0.1"
    }
  }), _vm._v(" "), _vm._t("default"), _vm._v(" "), _c("rect", {
    attrs: {
      x: _vm.x - 1,
      y: _vm.y + 2,
      width: 1,
      height: _vm.height - 4,
      rx: "2",
      "stroke-width": "0.5",
      fill: "#dfdfdf"
    }
  })], 2), _vm._v(" "), _c("g", {
    attrs: {
      refs: "entity_header"
    }
  }, [_c("g", {
    attrs: {
      refs: "entity_header_border"
    }
  }), _vm._v(" "), _c("rect", {
    attrs: {
      x: 1,
      y: _vm.y,
      width: _vm.headerWidth,
      height: _vm.height,
      rx: "1",
      fill: "#e5f2f2",
      stroke: "#4ca6a6",
      "stroke-width": "0.5"
    }
  }), _vm._v(" "), _c("rect", {
    attrs: {
      x: 4,
      y: _vm.y,
      width: _vm.headerWidth - 3,
      height: 15,
      rx: "1",
      fill: _vm.entity.__typename === "Product" ? "rgb(13, 99, 191)" : "#008080"
    }
  }), _vm._v(" "), _vm.entity.__typename === "Product" ? _c("foreignObject", {
    attrs: {
      x: 0,
      y: _vm.y - 4,
      width: 28,
      height: 28
    },
    on: {
      click: _vm.selectEntity
    }
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "22px",
      "font-weight": "600",
      color: "rgb(13, 99, 191)",
      "background-color": "White",
      "border-radius": "16pt",
      overflow: "visible",
      border: "1pt solid rgb(13, 99, 191)",
      padding: "1.5pt"
    },
    attrs: {
      "icon-class": "pdg-product"
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.entity.__typename === "Service" ? _c("foreignObject", {
    attrs: {
      x: 0,
      y: _vm.y - 4,
      width: 28,
      height: 28
    },
    on: {
      click: _vm.selectEntity
    }
  }, [_c("svg-icon", {
    staticStyle: {
      "font-size": "22px",
      "font-weight": "600",
      color: "teal",
      "background-color": "White",
      "border-radius": "16pt",
      overflow: "visible",
      border: "1pt solid teal",
      padding: "1.5pt"
    },
    attrs: {
      "icon-class": "pdg-service"
    }
  })], 1) : _vm._e(), _vm._v(" "), _c("foreignObject", {
    staticClass: "text-left",
    staticStyle: {
      overflow: "hidden",
      "border-radius": "2pt"
    },
    attrs: {
      x: 20,
      y: _vm.y - 1,
      width: _vm.headerWidth - 40,
      height: 15
    }
  }, [_c("div", {
    style: _vm.computeTitleStyle,
    attrs: {
      slot: "reference"
    },
    slot: "reference"
  }, [_vm._v("\n          " + _vm._s(_vm.truncateString(_vm.entity.title, 52)) + "\n        ")])]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "max_min"
    }
  }, [_c("foreignObject", {
    attrs: {
      x: _vm.headerWidth - 15,
      y: _vm.y + 2.5,
      width: 15,
      height: 15
    },
    on: {
      click: function click($event) {
        return _vm.toggleLane();
      }
    }
  }, [_vm.isMinimized ? _c("div", [_c("svg-icon", {
    style: _vm.computeStyle,
    attrs: {
      "icon-class": "arrow-shrink",
      title: "Shrink Lane"
    }
  })], 1) : _c("div", [_c("svg-icon", {
    style: _vm.computeStyle,
    attrs: {
      "icon-class": "arrow-expand",
      title: "Expand Lane"
    }
  })], 1)])]), _vm._v(" "), !_vm.isMinimized ? _c("g", {
    attrs: {
      refs: "description"
    }
  }, [_vm._t("header")], 2) : _vm._e(), _vm._v(" "), _c("g", {
    attrs: {
      refs: "entity_mask"
    }
  }, [_c("rect", {
    attrs: {
      x: _vm.x + _vm.width,
      y: _vm.y,
      width: _vm.computeRightEntityMaskWidth,
      height: _vm.height,
      fill: "white"
    }
  })])])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;