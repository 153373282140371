"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  name: 'ArrowGroupComponent',
  props: {
    count: {
      type: String,
      required: true,
      default: ''
    },
    incrementSize: {
      type: String,
      required: true,
      default: ''
    }
  },
  data: function data() {
    return {};
  },
  methods: {
    clicked: function clicked() {
      console.log('Clicked');
    },
    computeArrowPoints: function computeArrowPoints(arrow_count) {
      var index = arrow_count - 1;
      var point_a = index * parseInt(this.incrementSize) + 0 + ',' + 0;
      var point_b = index * parseInt(this.incrementSize) + 50 + ',' + 0;
      var point_c = index * parseInt(this.incrementSize) + 75 + ',' + 25;
      var point_d = index * parseInt(this.incrementSize) + 50 + ',' + 50;
      var point_e = index * parseInt(this.incrementSize) + 0 + ',' + 50;
      var point_f = index * parseInt(this.incrementSize) + 25 + ',' + 25;
      return point_a + ' ' + point_b + ' ' + point_c + ' ' + point_d + ' ' + point_e + ' ' + point_f;
    }
  }
};