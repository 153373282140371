"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/taggedTemplateLiteral.js"));
var _templateObject;
var _default2 = exports.default = {
  name: 'CustomerOfferingIdeaCreate',
  components: {},
  props: {
    customer: {
      type: Object,
      required: true,
      default: function _default() {
        return {
          id: '',
          title: '',
          description: '',
          tiers: '',
          rationale: '',
          priority: '',
          persona: '',
          additionalattributes: {},
          linkedJourneyMaps: []
        };
      }
    },
    offering: {
      type: Object,
      required: true,
      default: function _default() {
        return {
          id: '',
          title: '',
          description: '',
          linkedProducts: [],
          linkedServices: []
        };
      }
    },
    open: {
      type: Boolean,
      default: false
    },
    scope: {
      type: String,
      default: 'Offering'
    },
    scopename: {
      type: String,
      default: ''
    },
    scopeid: {
      type: String,
      default: ''
    }
  },
  data: function data() {
    var validateTitle = function validateTitle(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter a valid title'));
      } else {
        callback();
      }
    };
    var validateDescription = function validateDescription(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter a valid description'));
      } else {
        callback();
      }
    };
    var validateProblemStatement = function validateProblemStatement(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter a valid background'));
      } else {
        callback();
      }
    };
    var validateSolution = function validateSolution(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter a valid justification'));
      } else {
        callback();
      }
    };
    var validateValueProposition = function validateValueProposition(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter a valid valueproposition'));
      } else {
        callback();
      }
    };
    return {
      offeringRules: {
        title: [{
          required: true,
          trigger: 'blur',
          validator: validateTitle
        }],
        description: [{
          required: true,
          trigger: 'blur',
          validator: validateDescription
        }],
        problemstatement: [{
          required: true,
          trigger: 'blur',
          validator: validateProblemStatement
        }],
        solution: [{
          required: true,
          trigger: 'blur',
          validator: validateSolution
        }],
        valueproposition: [{
          required: true,
          trigger: 'blur',
          validator: validateValueProposition
        }]
      },
      loading: false,
      activeStep: 'step1',
      selectedCustomerType: '',
      form: {
        title: '',
        description: '',
        background: '',
        scope: '',
        valueproposition: '',
        status: 'Proposed',
        type: 'offering'
      },
      createIdeaDialogTitle: {
        title: 'New Idea',
        description: 'How can this offering be improved'
      }
    };
  },
  computed: {},
  watch: {
    scope: function scope(val) {
      this.form.scope = val;
    }
  },
  mounted: function mounted() {
    this.form.scope = this.scope;
    this.createIdeaDialogTitle.description = 'How can this ' + this.form.scope + ' be improved';
  },
  methods: {
    updateFormTitle: function updateFormTitle() {
      this.createIdeaDialogTitle.description = 'How can this ' + this.form.scope + ' be improved';
    },
    goToStep: function goToStep(step) {
      var _this = this;
      switch (step) {
        case 'step1':
          this.createIdeaDialogTitle = {
            title: 'New Idea',
            description: 'How can this ' + this.form.scope + ' be improved'
          };
          this.activeStep = 'step1';
          break;
        case 'step2':
          this.$refs.form_step1.validate(function (valid) {
            if (valid) {
              _this.createIdeaDialogTitle = {
                title: 'New Idea',
                description: 'Create the Idea'
              };
              _this.activeStep = 'step2';
            } else {
              _this.loading = false;
              return false;
            }
          });
          break;
      }
    },
    createOfferingIdea: function createOfferingIdea() {
      var _this2 = this;
      this.loading = true;
      this.$apollo.mutate({
        // Query
        mutation: window._gql(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["mutation createBusinessIdea($busIdea: CreateBusinessIdeaInput!)\n        {\n          createBusinessIdea(input: $busIdea) {\n            id\n            title\n            description\n            problemstatement\n            solution\n            valueproposition\n            additionalattributes\n            fileobject\n            status\n            score\n            customer_type\n            scope\n            scopename\n            type\n            linkedCoreParties(optional: true){\n              coreParty(optional: true){\n                id\n                businessname\n              }\n            }\n          }\n        }"]))),
        // Parameters
        variables: {
          'busIdea': {
            'title': this.form.title,
            'description': this.form.description,
            'problemstatement': this.form.problemstatement,
            'solution': this.form.solution,
            'valueproposition': this.form.valueproposition,
            'fileobject': [],
            'status': 'Proposed',
            'scope': this.form.scope,
            'scopename': this.scopename,
            'score': 0,
            'type': 'offering',
            'linkEntityId': this.scopeid,
            'linkEntityName': this.scope
          }
        }
      }).then(function (data) {
        _this2.$notify({
          title: 'Success',
          message: 'Created Successfully',
          type: 'success',
          duration: 2000
        });
        _this2.closeDialog();
        _this2.loading = false;
      }).catch(function (error) {
        // Error
        _this2.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this2.loading = false;
      });
    },
    closeDialog: function closeDialog() {
      this.activeStep = 'step1';
      this.resetForm();
      this.$emit('close');
    },
    resetForm: function resetForm() {
      this.form = {
        title: '',
        description: '',
        problemstatement: '',
        status: 'Proposed',
        solution: '',
        valueproposition: '',
        scope: '',
        scopename: '',
        type: 'offering'
      };
    }
  }
};