"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
/* import GenerateEntityDiagramComponent from './components/design_data/generate-entity-diagram'
import AddKnownEntitiesComponent from './components/design_data/add-known-entities'
import AddAdditionalEntitiesComponent from './components/design_data/add-additional-entities'
import OrganiseRelationshipsComponent from './components/design_data/organise-relationships'
import GenerateAttributesComponent from './components/design_data/generate-attributes'
import FinaliseDiagramVersionComponent from './components/design_data/finalise-diagram-version'
import CreateBacklogTasksComponent from './components/design_data/create-backlog-tasks'
import ProcessSteps from '@/components/ProcessSteps'*/
var _default = exports.default = {
  name: 'DesignData',
  components: {
    /* GenerateEntityDiagramComponent,
    AddKnownEntitiesComponent,
    AddAdditionalEntitiesComponent,
    OrganiseRelationshipsComponent,
    GenerateAttributesComponent,
    FinaliseDiagramVersionComponent,
    CreateBacklogTasksComponent,
    ProcessSteps*/
  },
  data: function data() {
    return {
      active_step: 0,
      steps: [{
        name: 'Generate Entity Diagram',
        shortDescription: '',
        longDescription: ''
      }, {
        name: 'Add Known Entities',
        shortDescription: '',
        longDescription: ''
      }, {
        name: 'Add Known Entities',
        shortDescription: '',
        longDescription: ''
      }, {
        name: 'Add Additional Entities',
        shortDescription: '',
        longDescription: ''
      }, {
        name: 'Organise Relationships',
        shortDescription: '',
        longDescription: ''
      }, {
        name: 'General Attributes',
        shortDescription: '',
        longDescription: ''
      }, {
        name: 'Finalise Diagram Version',
        shortDescription: '',
        longDescription: ''
      }, {
        name: 'Create Backlog Tasks',
        shortDescription: '',
        longDescription: ''
      }]
    };
  },
  mounted: function mounted() {},
  methods: {
    updateStep: function updateStep(active_step) {
      this.active_step = active_step;
    }
  }
};