"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _objectSpread2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/objectSpread2.js"));
var _interopRequireWildcard2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _vuex = require("vuex");
var _auth = require("@/utils/auth");
var _axios = _interopRequireDefault(require("axios"));
var _default = exports.default = {
  name: 'Profile',
  components: {
    // MessageComponent: () => import(/* webpackPrefetch: true */ './components/Message'),
    AccountComponent: function AccountComponent() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("./components/Account"));
      });
    }
  },
  data: function data() {
    return {
      loading: false,
      user: {},
      activeTab: 'account'
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['firstName', 'middleName', 'lastName', 'name', 'avatar', 'roles'])),
  mounted: function mounted() {
    this.getUserInfo();
    if (this.$route.query.tab && this.$route.query.tab === 'message') {
      if (this.isDevelopmentEnv()) {
        this.activeTab = 'message';
      }
    }
  },
  methods: {
    isDevelopmentEnv: function isDevelopmentEnv() {
      return process.env.VUE_APP_ENV === 'development';
    },
    getUserInfo: function getUserInfo() {
      var _this = this;
      this.loading = true;
      this.$store.dispatch('user/getUserInfo').then(function (response) {
        _this.loading = false;
        _this.user = {
          id: response.data.data.id,
          first_name: response.data.data.firstName,
          last_name: response.data.data.lastName,
          emailAddress: response.data.data.emailAddress,
          businessRole: response.data.data.businessRole,
          avatarColor: response.data.data.avatarColor,
          role: _this.roles.join(' | '),
          avatar: response.data.data.imageURL
        };
      }).catch(function (error) {
        _this.loading = false;
        console.log(error);
      });
    },
    updateUser: function updateUser(user) {
      var _this2 = this;
      this.loading = true;
      var headers = {
        'Content-Type': 'application/json'
      };
      var body = {
        'data': {
          'firstName': this.user.first_name,
          'lastName': this.user.last_name,
          'emailAddress': this.user.emailAddress,
          'businessRole': this.user.businessRole,
          'avatarColor': this.user.avatarColor,
          'imageURL': this.user.avatar
        },
        'header': {
          'userId': this.$store.getters.id,
          'partyId': this.$store.getters.partyId
        }
      };
      _axios.default.patch(process.env.VUE_APP_REST_API_ENDPOINT + 'user/' + this.user.id, body, {
        headers: headers
      }).then(function (response) {
        _this2.loading = false;
        _this2.$notify({
          title: 'Success',
          message: response.data.data.description,
          type: 'success',
          duration: 2000
        });
        (0, _auth.setEmail)(_this2.user.emailAddress);
        _this2.getUserInfo();
      }).catch(function (error) {
        _this2.loading = false;
        _this2.$notify({
          title: 'Error',
          message: error.response.data.data.description,
          type: 'error',
          duration: 2000
        });
      });
    }
  }
};