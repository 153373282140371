"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 4
    }
  }, [_c("el-card", {
    staticClass: "text-center"
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("strong", [_vm._v("Components")])]), _vm._v(" "), _c("div", [_c("table", {
    attrs: {
      width: "100%"
    }
  }, [_c("tr", [_c("td", {
    attrs: {
      draggable: ""
    },
    on: {
      drag: function drag($event) {
        return _vm.setActiveDragElement("text");
      }
    }
  }, [_c("el-avatar", [_c("svg-icon", {
    attrs: {
      "icon-class": "icon"
    }
  })], 1)], 1), _vm._v(" "), _c("td", {
    attrs: {
      draggable: ""
    },
    on: {
      drag: function drag($event) {
        return _vm.setActiveDragElement("textarea");
      }
    }
  }, [_c("el-avatar", [_c("svg-icon", {
    attrs: {
      "icon-class": "list"
    }
  })], 1)], 1), _vm._v(" "), _c("td", {
    attrs: {
      draggable: ""
    },
    on: {
      drag: function drag($event) {
        return _vm.setActiveDragElement("image");
      }
    }
  }, [_c("el-avatar", {
    attrs: {
      icon: "el-icon-picture-outline"
    }
  })], 1)])])])])], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 20
    }
  }, [_c("el-card", {
    staticClass: "text-center"
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("strong", [_vm._v("Wireframe Designer")]), _vm._v(" "), _vm.preview ? _c("el-button", {
    staticClass: "fr",
    attrs: {
      icon: "el-icon-edit",
      title: "Edit",
      size: "mini"
    },
    on: {
      click: _vm.disableGrid
    }
  }, [_vm._v("Edit")]) : _c("el-button", {
    staticClass: "fr",
    attrs: {
      icon: "el-icon-view",
      title: "Preview",
      size: "mini"
    },
    on: {
      click: _vm.disableGrid
    }
  }, [_vm._v("Preview")])], 1), _vm._v(" "), _c("div", {
    on: {
      drop: _vm.handleDrop,
      dragover: _vm.handleDragOver
    }
  }, [_c("grid-layout", {
    attrs: {
      layout: _vm.layout,
      "col-num": 12,
      "row-height": 30,
      "is-draggable": _vm.isDraggable,
      "is-resizable": _vm.isResizable,
      "is-mirrored": false,
      "vertical-compact": true,
      margin: [10, 10],
      "use-css-transforms": true
    },
    on: {
      "update:layout": function updateLayout($event) {
        _vm.layout = $event;
      },
      "layout-updated": _vm.layoutUpdatedEvent
    }
  }, _vm._l(_vm.layout, function (item, index) {
    return _c("grid-item", {
      key: index,
      class: {
        editMode: !_vm.preview
      },
      attrs: {
        x: item.x,
        y: item.y,
        w: item.w,
        h: item.h,
        i: item.i,
        "auto-size": true
      }
    }, [!_vm.preview ? _c("div", {
      staticStyle: {
        position: "absolute",
        bottom: "0px",
        left: "4px"
      },
      on: {
        click: function click($event) {
          return _vm.removeItem({
            key: index
          });
        }
      }
    }, [_c("i", {
      staticClass: "el-icon-delete",
      attrs: {
        "aria-hidden": "true"
      }
    })]) : _vm._e(), _vm._v(" "), item.type == "title" ? _c("text-widget", {
      attrs: {
        preview: _vm.preview,
        contenteditable: _vm.contenteditable,
        item: item,
        "item-index": index
      },
      on: {
        "save-heading": _vm.saveHeadings,
        "save-item": _vm.saveItem
      }
    }) : _vm._e(), _vm._v(" "), item.type == "content" ? _c("text-area-widget", {
      attrs: {
        preview: _vm.preview,
        contenteditable: _vm.contenteditable,
        item: item,
        "item-index": index
      },
      on: {
        "save-item": _vm.saveItem
      }
    }) : _vm._e(), _vm._v(" "), item.type == "image" ? _c("image-widget", {
      attrs: {
        preview: _vm.preview,
        contenteditable: _vm.contenteditable,
        item: item,
        "item-index": index
      },
      on: {
        "save-item": _vm.saveItem
      }
    }) : _vm._e()], 1);
  }), 1)], 1)])], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;