"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "fixed-header business-ui",
    staticStyle: {
      position: "fixed",
      "z-index": "9",
      width: "100%",
      "background-color": "white",
      top: "50px"
    }
  }, [_c("el-tabs", {
    on: {
      "tab-click": _vm.tabClicked
    },
    model: {
      value: _vm.activeName,
      callback: function callback($$v) {
        _vm.activeName = $$v;
      },
      expression: "activeName"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      name: "vision"
    }
  }, [_c("span", {
    attrs: {
      slot: "label"
    },
    slot: "label"
  }, [_vm._v("Vision")])]), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      id: "tab-goals",
      name: "goals"
    }
  }, [_c("span", {
    attrs: {
      slot: "label"
    },
    slot: "label"
  }, [_vm._v("Goals & Objectives")])]), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      id: "tab-strategy_goal_roadmap",
      name: "strategy_goal_roadmap"
    }
  }, [_c("span", {
    attrs: {
      slot: "label"
    },
    slot: "label"
  }, [_vm._v("Goals Roadmap")])]), _vm._v(" "), _vm.showMVP() ? _c("el-tab-pane", {
    attrs: {
      id: "tab-value-stream",
      name: "value_chain"
    }
  }, [_c("span", {
    attrs: {
      slot: "label"
    },
    slot: "label"
  }, [_vm._v("Value Stream")])]) : _vm._e(), _vm._v(" "), _vm.showMVP() ? _c("el-tab-pane", {
    attrs: {
      id: "tab-competitors",
      name: "competitors"
    }
  }, [_c("span", {
    attrs: {
      slot: "label"
    },
    slot: "label"
  }, [_vm._v("Competitors")])]) : _vm._e(), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      id: "tab-bmc",
      name: "bmc"
    }
  }, [_c("span", {
    attrs: {
      slot: "label"
    },
    slot: "label"
  }, [_vm._v("Business Model Canvas")])])], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "app-container",
    staticStyle: {
      "margin-top": "34px"
    }
  }, [_c("el-row", [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_vm.activeName === "vision" ? _c("vision-component") : _vm._e(), _vm._v(" "), _vm.activeName === "goals" ? _c("goals-component", {
    attrs: {
      loaded: _vm.activeName === "goals"
    }
  }) : _vm._e(), _vm._v(" "), _vm.activeName === "strategy_goal_roadmap" ? _c("business-goal-roadmap-component", {
    attrs: {
      loaded: _vm.activeName === "strategy_goal_roadmap"
    }
  }) : _vm._e(), _vm._v(" "), _vm.activeName === "value_chain" ? _c("value-stream-component") : _vm._e(), _vm._v(" "), _vm.activeName === "competitors" ? _c("competitors-component") : _vm._e(), _vm._v(" "), _vm.activeName === "bmc" ? _c("business-model-canvas-component") : _vm._e()], 1)], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      id: "test_my_business_create_roadmap_dialog",
      title: "Create Roadmap",
      visible: _vm.createRoadmapDialog,
      width: "40%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.createRoadmapDialog = $event;
      }
    }
  }, [_c("el-form", {
    ref: "createRoadmap",
    attrs: {
      rules: _vm.createRoadmapRules,
      model: _vm.roadmap_form,
      "label-position": "top"
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "title",
      size: "mini",
      label: "Title"
    }
  }, [_c("el-input", {
    attrs: {
      type: "text",
      name: "Title",
      placeholder: ""
    },
    model: {
      value: _vm.roadmap_form.title,
      callback: function callback($$v) {
        _vm.$set(_vm.roadmap_form, "title", $$v);
      },
      expression: "roadmap_form.title"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      prop: "startdate",
      size: "mini",
      label: "Start Date"
    }
  }, [_c("el-date-picker", {
    attrs: {
      type: "month",
      placeholder: "Pick a month"
    },
    model: {
      value: _vm.roadmap_form.startdate,
      callback: function callback($$v) {
        _vm.$set(_vm.roadmap_form, "startdate", $$v);
      },
      expression: "roadmap_form.startdate"
    }
  })], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.createRoadmapDialog = false;
      }
    }
  }, [_vm._v("Cancel")]), _vm._v(" "), _c("el-button", {
    attrs: {
      id: "test_my_business_create_roadmap_dialog_cancel",
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.createRoadmap
    }
  }, [_vm._v("Create")])], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;