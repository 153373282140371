"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _default = exports.default = {
  name: 'IconComponent',
  props: {
    cx: {
      type: Number,
      required: true,
      default: 0
    },
    cy: {
      type: Number,
      required: true,
      default: 0
    },
    type: {
      type: String,
      required: true,
      default: ''
    }
  },
  methods: {
    selected: function selected(type) {
      this.$eventHub.$emit('toggle-process');
      this.$emit('domain-icon-selected', type);
    }
  }
};