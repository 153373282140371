"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-card", {
    staticClass: "mt-10"
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("p", {
    staticClass: "sub-title-left"
  }, [_vm._v("Strategic Features")]), _vm._v(" "), _c("div", {
    staticClass: "mt-5 container"
  }, [_c("el-table", {
    attrs: {
      data: _vm.identityDifferentiators.strategic_differentiating_feature,
      stripe: ""
    },
    on: {
      "row-click": _vm.selectFeature
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "index"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Feature",
      prop: "name",
      width: "230"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Description",
      prop: "description"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Selling Proposition",
      prop: "selling_proposition"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Tags",
      prop: "tags"
    }
  })], 1), _vm._v(" "), _c("el-button", {
    staticClass: "mt-5 fr",
    attrs: {
      type: "primary",
      size: "small"
    },
    on: {
      click: _vm.openAddFeatureDialog
    }
  }, [_vm._v("\n                Add Feature\n              ")])], 1)])], 1), _vm._v(" "), _c("el-tabs", {
    attrs: {
      value: "feature_details"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "Feature Details",
      name: "feature_details"
    }
  }, [_c("el-row", {
    staticClass: "container"
  }, [_c("el-col", {
    attrs: {
      span: 8
    }
  }, [_c("strong", [_vm._v("Reason why strategic")]), _c("br"), _vm._v(" "), _c("el-input", {
    staticClass: "pr-20 mt-5",
    attrs: {
      autosize: {
        minRows: 3,
        maxRows: 5
      },
      type: "textarea",
      placeholder: "What makes this strategic?"
    },
    model: {
      value: _vm.selectedFeature.strategic_reason,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedFeature, "strategic_reason", $$v);
      },
      expression: "selectedFeature.strategic_reason"
    }
  })], 1), _vm._v(" "), _c("el-col", {
    attrs: {
      span: 16
    }
  }, [_c("table", [_c("tr", [_c("th", [_vm._v("Strategic Criteria Met")])]), _vm._v(" "), _c("tr", [_c("td", _vm._l(_vm.selectedFeature.barriers, function (barrier, index) {
    return _c("el-tag", {
      key: index,
      attrs: {
        size: "mini"
      }
    }, [_vm._v(_vm._s(barrier))]);
  }), 1)])])])], 1)], 1), _vm._v(" "), _c("el-tab-pane", {
    attrs: {
      label: "Competing Products",
      name: "competing_products"
    }
  }, [_c("el-table", {
    staticClass: "container",
    attrs: {
      data: _vm.selectedFeature.competing_products
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "Product",
      prop: "product"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Company",
      prop: "company"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      label: "Description",
      prop: "description"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Add Feature",
      visible: _vm.addFeatureDialog,
      width: "40%",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addFeatureDialog = $event;
      }
    }
  }, [_c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-steps", {
    attrs: {
      active: _vm.featureStepActive,
      "finish-status": "success",
      "align-center": ""
    }
  }, [_c("el-step", {
    attrs: {
      title: "Feature"
    }
  }), _vm._v(" "), _c("el-step", {
    attrs: {
      title: "Barriers"
    }
  }), _vm._v(" "), _c("el-step", {
    attrs: {
      title: "Complexity"
    }
  }), _vm._v(" "), _c("el-step", {
    attrs: {
      title: "Competitors"
    }
  }), _vm._v(" "), _c("el-step", {
    attrs: {
      title: "Revenue Strategy"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c("el-row", {
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-form", {
    ref: "feature_form",
    attrs: {
      model: _vm.feature_form,
      "label-width": "180px",
      size: "mini"
    }
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.featureStepActive === 0,
      expression: "featureStepActive === 0"
    }]
  }, [_c("el-form-item", {
    attrs: {
      label: "Name"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.feature_form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.feature_form, "name", $$v);
      },
      expression: "feature_form.name"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Description"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.feature_form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.feature_form, "description", $$v);
      },
      expression: "feature_form.description"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Strategic Reason"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.feature_form.strategic_reason,
      callback: function callback($$v) {
        _vm.$set(_vm.feature_form, "strategic_reason", $$v);
      },
      expression: "feature_form.strategic_reason"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Selling Proposition"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.feature_form.selling_proposition,
      callback: function callback($$v) {
        _vm.$set(_vm.feature_form, "selling_proposition", $$v);
      },
      expression: "feature_form.selling_proposition"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Add Tags"
    }
  }, [_c("el-select", {
    attrs: {
      multiple: "",
      placeholder: "Select Tags"
    },
    model: {
      value: _vm.feature_form.tags,
      callback: function callback($$v) {
        _vm.$set(_vm.feature_form, "tags", $$v);
      },
      expression: "feature_form.tags"
    }
  }, _vm._l(_vm.tags, function (tag) {
    return _c("el-option", {
      key: tag.value,
      attrs: {
        label: tag.label,
        value: tag.value
      }
    });
  }), 1)], 1)], 1), _vm._v(" "), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.featureStepActive === 1,
      expression: "featureStepActive === 1"
    }]
  }, [_c("el-form-item", {
    attrs: {
      label: "Entry barriers"
    }
  }, [_c("el-select", {
    attrs: {
      multiple: "",
      filterable: "",
      "allow-create": "",
      "default-first-option": "",
      placeholder: "Add barriers"
    },
    model: {
      value: _vm.feature_form.barriers,
      callback: function callback($$v) {
        _vm.$set(_vm.feature_form, "barriers", $$v);
      },
      expression: "feature_form.barriers"
    }
  }, _vm._l(_vm.barriers, function (barrier) {
    return _c("el-option", {
      key: barrier.value,
      attrs: {
        label: barrier.label,
        value: barrier.value
      }
    });
  }), 1)], 1)], 1), _vm._v(" "), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.featureStepActive === 2,
      expression: "featureStepActive === 2"
    }]
  }, [_c("el-form-item", {
    attrs: {
      label: "Complexity"
    }
  }, [_c("el-radio-group", {
    model: {
      value: _vm.feature_form.complexity,
      callback: function callback($$v) {
        _vm.$set(_vm.feature_form, "complexity", $$v);
      },
      expression: "feature_form.complexity"
    }
  }, [_c("el-radio", {
    attrs: {
      label: 1
    }
  }, [_vm._v("Simple")]), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: 2
    }
  }, [_vm._v("Slightly complex")]), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: 3
    }
  }, [_vm._v("Complex")]), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: 4
    }
  }, [_vm._v("Very Complex")]), _vm._v(" "), _c("el-radio", {
    attrs: {
      label: 5
    }
  }, [_vm._v("Not Feasible")])], 1)], 1)], 1), _vm._v(" "), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.featureStepActive === 3,
      expression: "featureStepActive === 3"
    }]
  }, [_c("div", {
    staticClass: "text-center"
  }, [_c("strong", [_vm._v("Select Competing Products")])]), _vm._v(" "), _c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.competingProductsData
    },
    on: {
      "selection-change": _vm.handleSelectionChange
    }
  }, [_c("el-table-column", {
    attrs: {
      type: "selection",
      width: "55"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "product",
      label: "Product"
    }
  }), _vm._v(" "), _c("el-table-column", {
    attrs: {
      prop: "company",
      label: "Company"
    }
  })], 1), _vm._v(" "), _c("div", {
    staticClass: "text-right mt-10"
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.openAddFeatureCompetingProductDialog
    }
  }, [_vm._v("Add new Product")]), _vm._v(" "), _c("p", {
    staticStyle: {
      "margin-top": "0px"
    }
  }, [_c("small", [_c("i", [_vm._v("Only add the company here if they have an  equivalent feature")])])])], 1)], 1), _vm._v(" "), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.featureStepActive === 4,
      expression: "featureStepActive === 4"
    }]
  }, [_c("el-form-item", {
    attrs: {
      label: "Revenue Strategy"
    }
  }, [_c("el-checkbox-group", {
    model: {
      value: _vm.feature_form.revenue_strategy,
      callback: function callback($$v) {
        _vm.$set(_vm.feature_form, "revenue_strategy", $$v);
      },
      expression: "feature_form.revenue_strategy"
    }
  }, [_c("el-checkbox", {
    attrs: {
      label: "None",
      name: "type"
    }
  }), _vm._v(" "), _c("el-checkbox", {
    attrs: {
      label: "Feature",
      name: "type"
    }
  }), _vm._v(" "), _c("el-checkbox", {
    attrs: {
      label: "Feature Group",
      name: "type"
    }
  })], 1)], 1)], 1)])], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_vm.featureStepActive < 4 ? _c("el-button", {
    on: {
      click: _vm.featureNextStep
    }
  }, [_vm._v("Next")]) : _vm._e(), _vm._v(" "), _vm.featureStepActive === 4 ? _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.onFeatureSubmit
    }
  }, [_vm._v("Create")]) : _vm._e(), _vm._v(" "), _vm.featureStepActive === 4 ? _c("el-button", {
    on: {
      click: function click($event) {
        _vm.addFeatureDialog = false;
      }
    }
  }, [_vm._v("Cancel")]) : _vm._e()], 1)], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      title: "Add Product",
      visible: _vm.addFeatureCompetingProductDialog,
      width: "40%",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addFeatureCompetingProductDialog = $event;
      }
    }
  }, [_c("el-form", {
    ref: "feature_form",
    attrs: {
      model: _vm.feature_form,
      "label-width": "180px",
      size: "mini"
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "Product"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.feature_product_form.product,
      callback: function callback($$v) {
        _vm.$set(_vm.feature_product_form, "product", $$v);
      },
      expression: "feature_product_form.product"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Description"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea"
    },
    model: {
      value: _vm.feature_product_form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.feature_product_form, "description", $$v);
      },
      expression: "feature_product_form.description"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      label: "Company"
    }
  }, [_c("el-input", {
    model: {
      value: _vm.feature_product_form.company,
      callback: function callback($$v) {
        _vm.$set(_vm.feature_product_form, "company", $$v);
      },
      expression: "feature_product_form.company"
    }
  })], 1)], 1), _vm._v(" "), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    attrs: {
      size: "mini",
      type: "primary"
    },
    on: {
      click: _vm.addFeatureCompetingProduct
    }
  }, [_vm._v("Add")]), _vm._v(" "), _c("el-button", {
    attrs: {
      size: "mini"
    },
    on: {
      click: function click($event) {
        _vm.addFeatureCompetingProductDialog = false;
      }
    }
  }, [_vm._v("Cancel")])], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;