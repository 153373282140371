"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _default = exports.default = {
  name: 'PhaseTool',
  components: {},
  props: {
    x: {
      type: Number,
      default: 0
    },
    y: {
      type: Number,
      default: 0
    },
    index: {
      type: Number,
      default: 0
    },
    length: {
      type: Number,
      default: 0
    },
    label: {
      type: String,
      default: ''
    },
    position: {
      type: String,
      default: 'toolbox'
    },
    showLabel: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      isHover: false
    };
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    computeBGStyle: function computeBGStyle() {
      if (this.isHover) {
        return 'background-color:rgba(200,200,200,0.1);border-radius:15px;border:1px solid #4672c4;';
      } else {
        return 'background-color:white;border-radius:15px;';
      }
    },
    computeStyle: function computeStyle() {
      return 'font-size: 16px; padding: 1pt; width: 27px; min-height: 20px';
    },
    generatePath: function generatePath() {
      var point2_x = this.x + this.length;
      var point3_x = this.x + this.length + 25;
      var point6_x = this.x + 25;
      var point3_y = this.y + 25;
      var point4_y = this.y + 50;
      return this.x + ',' + this.y + ' ' + point2_x + ',' + this.y + ' ' + point3_x + ',' + point3_y + ' ' + point2_x + ',' + point4_y + ' ' + this.x + ',' + point4_y + ' ' + point6_x + ',' + point3_y;
    },
    generateUniqueID: function generateUniqueID() {
      return 'phase_' + this.index + '_0';
    },
    onMouseOver: function onMouseOver() {
      this.isHover = true;
      this.$emit('mouseover');
    },
    onMouseLeave: function onMouseLeave() {
      this.isHover = false;
      this.$emit('mouseleave');
    }
  }
};