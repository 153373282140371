"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("g", [_c("g", {
    attrs: {
      refs: "thoughts-lane-background"
    }
  }, [_c("g", {
    attrs: {
      refs: "thoughts-lane-background-border"
    }
  }, [_c("rect", {
    staticClass: "shadow",
    attrs: {
      x: _vm.x - 2,
      y: _vm.y,
      width: 10,
      height: _vm.height,
      fill: "#447799",
      "stroke-width": "1",
      stroke: "#eee"
    }
  }), _vm._v(" "), _c("rect", {
    staticClass: "shadow",
    attrs: {
      x: _vm.x,
      y: _vm.y,
      width: _vm.width,
      height: _vm.height,
      fill: "white",
      "stroke-width": "1",
      stroke: "#eee"
    }
  })]), _vm._v(" "), _c("rect", {
    attrs: {
      x: _vm.x,
      y: _vm.y,
      width: _vm.width - 5,
      height: _vm.height,
      fill: "rgba(254,254,255,0.9)",
      "stroke-width": "1.0",
      stroke: "#f1f4ff"
    }
  }), _vm._v(" "), _c("g", {
    attrs: {
      refs: "thoughts-lane-header"
    }
  }, [_c("rect", {
    attrs: {
      x: _vm.x,
      y: _vm.y,
      width: _vm.isCompactMenubar ? 28 : 140,
      height: _vm.height,
      fill: "rgba(200,200,200,0.15)",
      "stroke-width": "0",
      stroke: "#aaa"
    }
  }), _vm._v(" "), !_vm.isCompactMenubar ? _c("rect", {
    attrs: {
      x: _vm.x,
      y: _vm.y,
      rx: "3",
      width: 140,
      height: 22,
      fill: "rebeccapurple",
      "stroke-width": "0",
      stroke: "#aaa"
    }
  }) : _vm._e(), _vm._v(" "), _c("text", {
    style: _vm.computeStyle(),
    attrs: {
      x: _vm.x + 10,
      y: _vm.isCompactMenubar ? _vm.y + 130 : _vm.y + 15,
      "text-anchor": "start",
      "font-size": ".95em",
      transform: _vm.computeTransform()
    },
    on: {
      click: _vm.displayLabelPrompt
    }
  }, [_vm._v("\n        " + _vm._s(_vm.label) + "\n      ")])])]), _vm._v(" "), _c("g", {
    attrs: {
      refs: "thoughts"
    }
  }, _vm._l(_vm.stages, function (stage, index) {
    return _c("g", {
      key: index,
      attrs: {
        refs: "thought"
      }
    }, [_c("foreignObject", {
      attrs: {
        x: _vm.phaseX[index],
        y: _vm.y + 5,
        width: _vm.phaseLength[index],
        height: _vm.height - 10
      }
    }, [_c("div", {
      attrs: {
        xmlns: "http://www.w3.org/1999/xhtml"
      }
    }, [_c("textarea", {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: stage.journeyPhase.additionalattributes.thoughts,
        expression: "stage.journeyPhase.additionalattributes.thoughts"
      }],
      staticClass: "journeymap-thoughts",
      style: _vm.computeTextAreaStyle(index),
      attrs: {
        placeholder: ""
      },
      domProps: {
        value: stage.journeyPhase.additionalattributes.thoughts
      },
      on: {
        input: [function ($event) {
          if ($event.target.composing) return;
          _vm.$set(stage.journeyPhase.additionalattributes, "thoughts", $event.target.value);
        }, function ($event) {
          return _vm.updateThought(index);
        }]
      }
    })])])]);
  }), 0)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;