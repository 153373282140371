import { render, staticRenderFns } from "./Increment.vue?vue&type=template&id=0c027afb&scoped=true"
import script from "./Increment.vue?vue&type=script&lang=js"
export * from "./Increment.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c027afb",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\Sastha\\Documents\\GitHub\\prodiggiapp-frontend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0c027afb')) {
      api.createRecord('0c027afb', component.options)
    } else {
      api.reload('0c027afb', component.options)
    }
    module.hot.accept("./Increment.vue?vue&type=template&id=0c027afb&scoped=true", function () {
      api.rerender('0c027afb', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/delivery/roadmap/Increment.vue"
export default component.exports