"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/defineProperty.js"));
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.function.name");
var _methods;
/* eslint-disable */
var _default2 = exports.default = {
  name: 'UserJourneyMapSimple',
  props: {
    userType: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      journeyMapStatus: 'unapproved',
      isMinimisedRSB: false,
      addPainPointsDialog: false,
      addPleasersDialog: false,
      addCandidateFeaturesDialog: false,
      addPhaseDialog: false,
      addTaskDialog: false,
      activeArtefactDrag: {
        element: '',
        index: 0
      },
      activeDragElement: '',
      selectedTask: {},
      selectedStage: {},
      stagePaths: [],
      phaseWidth: 600,
      phaseWidthNormal: 600,
      phaseWidthExtended: 900,
      phaseHeight: 20,
      phaseDelta: 125,
      pathLength: 100,
      phaseLimit: 5,
      taskModal: {
        height: 500,
        width: 900,
        x: 10,
        y: 10
      },
      stageModal: {
        height: 350,
        width: 600,
        x: 10,
        y: 10
      },
      activeTaskIndex: 0,
      activePleaserPainPointIndex: 0,
      taskDetailsVisible: false,
      isStageSelected: false,
      isSourcePleaser: false,
      isSourcePainPoint: false,
      isPhaseModalEditMode: false,
      isTaskModalEditMode: false,
      selectedStageIndex: null,
      activeTaskPos: {
        x: 0,
        y: 0
      },
      tasks: [],
      stages: [{
        points: "100 100,700 100,710 110,700 120,100 120",
        stroke: "lightblue",
        fill: "aliceblue"
      }],
      stageData: [{
        name: 'Phase 1',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        goal: 'Data Flow Design',
        challenges: 'Chalenge 1',
        emotions: 'Emotions',
        priority: 'Medium'
      }],
      stageTasks: [],
      // Seperate Object from StageData to support for orphan Tasks
      taskData: [],
      pains_form: {
        pain_point: '',
        description: '',
        value: '',
        priority: 'Medium',
        relations: []
      },
      gains_form: {
        pleaser: '',
        description: '',
        value: '',
        priority: 'Medium',
        relations: []
      },
      candidate_features_form: {
        key: 0,
        candidate_feature: '',
        description: '',
        value: '',
        status: '',
        roadmap_version: '',
        priority: 'Medium'
      },
      phase_form: {
        name: '',
        description: '',
        goal: '',
        challenges: '',
        emotions: '',
        priority: 'Medium'
      },
      task_form: {
        task: '',
        intention: '',
        pain_points: [],
        pleasers: []
      },
      active_user_type_name: 'Individual',
      user_type: '',
      user_types: [{
        id: 1,
        name: 'Individual',
        customer_type: 'Type A',
        problem_statement: 'Lorem Ipsum',
        opportunity_statement: 'Lorem Ipsum',
        pain_points: [{
          key: 0,
          pain_point: 'Pain Point A',
          description: 'Description',
          value: 3,
          priority: 'Low',
          relations: [{
            key: 0,
            candidate_feature: 'Candidate Feature G',
            value: 3,
            priority: 'Low'
          }, {
            key: 1,
            candidate_feature: 'Candidate Feature H',
            value: 2,
            priority: 'Low'
          }]
        }, {
          key: 1,
          pain_point: 'Pain Point B',
          description: 'Description',
          value: 2,
          priority: 'Low',
          relations: []
        }, {
          key: 2,
          pain_point: 'Pain Point C',
          description: 'Description',
          value: 1,
          priority: 'Low',
          relations: []
        }],
        pleasers: [{
          key: 0,
          pleaser: 'Pleaser D',
          description: 'Description',
          value: 3,
          priority: 'Low',
          relations: []
        }, {
          key: 1,
          pleaser: 'Pleaser E',
          description: 'Description',
          value: 2,
          priority: 'Low',
          relations: []
        }, {
          key: 2,
          pleaser: 'Pleaser F',
          description: 'Description',
          value: 1,
          priority: 'Low',
          relations: []
        }],
        candidate_features: [{
          key: 0,
          candidate_feature: 'Candidate Feature G',
          value: 3,
          priority: 'Low'
        }, {
          key: 1,
          candidate_feature: 'Candidate Feature H',
          value: 2,
          priority: 'Low'
        }, {
          key: 2,
          candidate_feature: 'Candidate Feature I',
          value: 1,
          priority: 'Low'
        }]
      }, {
        id: 2,
        name: 'Group',
        customer_type: 'Type B',
        problem_statement: 'Lorem Ipsum',
        opportunity_statement: 'Lorem Ipsum',
        pain_points: [{
          key: 0,
          pain_point: 'Pain Point A',
          description: 'Description',
          value: 3,
          priority: 'Low',
          relations: []
        }, {
          key: 1,
          pain_point: 'Pain Point B',
          description: 'Description',
          value: 2,
          priority: 'Low',
          relations: []
        }, {
          key: 2,
          pain_point: 'Pain Point C',
          description: 'Description',
          value: 1,
          priority: 'Low',
          relations: []
        }],
        pleasers: [{
          key: 0,
          pleaser: 'Pleaser D',
          description: 'Description',
          value: 3,
          priority: 'Low',
          relations: []
        }, {
          key: 1,
          pleaser: 'Pleaser E',
          description: 'Description',
          value: 2,
          priority: 'Low',
          relations: []
        }, {
          key: 2,
          pleaser: 'Pleaser F',
          description: 'Description',
          value: 1,
          priority: 'Low',
          relations: []
        }],
        candidate_features: [{
          key: 0,
          candidate_feature: 'Feature G',
          value: 3,
          priority: 'Low'
        }, {
          key: 1,
          candidate_feature: 'Feature H',
          value: 2,
          priority: 'Low'
        }, {
          key: 2,
          candidate_feature: 'Feature I',
          value: 1,
          priority: 'Low'
        }]
      }
      /*Removed for clearer scenario,
      {
        id: 3,
        name: 'Designer',
        customer_type: 'Type A',
        problem_statement: 'Lorem Ipsum',
        opportunity_statement: 'Lorem Ipsum',
        pain_points: [
          {
            pain_point: 'Pain Point A',
            value: 3,
            priority: 1
          },
          {
            pain_point: 'Pain Point B',
            value: 2,
            priority: 1
          },
          {
            pain_point: 'Pain Point C',
            value: 1,
            priority: 1
          }
        ],
        pleasers: [
          {
            pleaser: 'Pleaser D',
            value: 3,
            priority: 1
          },
          {
            pleaser: 'Pleaser E',
            value: 2,
            priority: 1
          },
          {
            pleaser: 'Pleaser F',
            value: 1,
            priority: 1
          }
        ],
        candidate_features: [
          {
            candidate_feature: 'Feature G',
            value: 3,
            priority: 1
          },
          {
            candidate_feature: 'Feature H',
            value: 2,
            priority: 1
          },
          {
            candidate_feature: 'Feature I',
            value: 1,
            priority: 1
          }
        ]
      },
      {
        id: 4,
        name: 'Developer',
        customer_type: 'Type A',
        problem_statement: 'Lorem Ipsum',
        opportunity_statement: 'Lorem Ipsum',
        pain_points: [
          {
            pain_point: 'Pain Point A',
            value: 3,
            priority: 1
          },
          {
            pain_point: 'Pain Point B',
            value: 2,
            priority: 1
          },
          {
            pain_point: 'Pain Point C',
            value: 1,
            priority: 1
          }
        ],
        pleasers: [
          {
            pleaser: 'Pleaser D',
            value: 3,
            priority: 1
          },
          {
            pleaser: 'Pleaser E',
            value: 2,
            priority: 1
          },
          {
            pleaser: 'Pleaser F',
            value: 1,
            priority: 1
          }
        ],
        candidate_features: [
          {
            candidate_feature: 'Feature G',
            value: 3,
            priority: 1
          },
          {
            candidate_feature: 'Feature H',
            value: 2,
            priority: 1
          },
          {
            candidate_feature: 'Feature I',
            value: 1,
            priority: 1
          }
        ]
      },
      {
        id: 5,
        name: 'Marketer',
        customer_type: 'Type A',
        problem_statement: 'Lorem Ipsum',
        opportunity_statement: 'Lorem Ipsum',
        pain_points: [
          {
            pain_point: 'Pain Point A',
            value: 3,
            priority: 1
          },
          {
            pain_point: 'Pain Point B',
            value: 2,
            priority: 1
          },
          {
            pain_point: 'Pain Point C',
            value: 1,
            priority: 1
          }
        ],
        pleasers: [
          {
            pleaser: 'Pleaser D',
            value: 3,
            priority: 1
          },
          {
            pleaser: 'Pleaser E',
            value: 2,
            priority: 1
          },
          {
            pleaser: 'Pleaser F',
            value: 1,
            priority: 1
          }
        ],
        candidate_features: [
          {
            candidate_feature: 'Feature G',
            value: 3,
            priority: 1
          },
          {
            candidate_feature: 'Feature H',
            value: 2,
            priority: 1
          },
          {
            candidate_feature: 'Feature I',
            value: 1,
            priority: 1
          }
        ]
      },
      {
        id: 6,
        name: 'Change Manager',
        customer_type: 'Type A',
        problem_statement: 'Lorem Ipsum',
        opportunity_statement: 'Lorem Ipsum',
        pain_points: [
          {
            pain_point: 'Pain Point A',
            value: 3,
            priority: 1
          },
          {
            pain_point: 'Pain Point B',
            value: 2,
            priority: 1
          },
          {
            pain_point: 'Pain Point C',
            value: 1,
            priority: 1
          }
        ],
        pleasers: [
          {
            pleaser: 'Pleaser D',
            value: 3,
            priority: 1
          },
          {
            pleaser: 'Pleaser E',
            value: 2,
            priority: 1
          },
          {
            pleaser: 'Pleaser F',
            value: 1,
            priority: 1
          }
        ],
        candidate_features: [
          {
            candidate_feature: 'Feature G',
            value: 3,
            priority: 1
          },
          {
            candidate_feature: 'Feature H',
            value: 2,
            priority: 1
          },
          {
            candidate_feature: 'Feature I',
            value: 1,
            priority: 1
          }
        ]
      },
      {
        id: 7,
        name: 'KFX Advisor',
        customer_type: 'Type A',
        problem_statement: 'Lorem Ipsum',
        opportunity_statement: 'Lorem Ipsum',
        pain_points: [
          {
            pain_point: 'Pain Point A',
            value: 3,
            priority: 1
          },
          {
            pain_point: 'Pain Point B',
            value: 2,
            priority: 1
          },
          {
            pain_point: 'Pain Point C',
            value: 1,
            priority: 1
          }
        ],
        pleasers: [
          {
            pleaser: 'Pleaser D',
            value: 3,
            priority: 1
          },
          {
            pleaser: 'Pleaser E',
            value: 2,
            priority: 1
          },
          {
            pleaser: 'Pleaser F',
            value: 1,
            priority: 1
          }
        ],
        candidate_features: [
          {
            candidate_feature: 'Feature G',
            value: 3,
            priority: 1
          },
          {
            candidate_feature: 'Feature H',
            value: 2,
            priority: 1
          },
          {
            candidate_feature: 'Feature I',
            value: 1,
            priority: 1
          }
        ]
        }*/],
      myDiagram: {},
      myPalette: {}
    };
  },
  mounted: function mounted() {
    this.user_type = this.user_types[0];
    this.userType = this.user_types[0];
  },
  watch: {
    isMinimisedRSB: function isMinimisedRSB(val) {
      this.phaseWidth = this.phaseWidthNormal;
      if (val) {
        this.phaseWidth = this.phaseWidthExtended;
      }
      this.renderPhaseUI();
    }
  },
  methods: (_methods = {
    deleteTask: function deleteTask() {
      this.taskDetailsVisible = false;
      this.taskData.splice(this.activeTaskIndex, 1);
      this.tasks.splice(this.activeTaskIndex, 1);
    },
    stageTasksExists: function stageTasksExists() {
      if (this.stageTasks.length === 0) {
        return false;
      }
      for (var i = 0; i < this.stageTasks.length; i++) {
        if (this.stageTasks[i].stage_index === this.selectedStageIndex) {
          return true;
        }
      }
      return false;
    },
    removeStageTaskRelation: function removeStageTaskRelation(task_list) {
      if (task_list.length > 0) {
        for (var i = 0; i < task_list.length; i++) {
          this.stageTasks.splice(i, 1);
        }
      }
    },
    getCurrentStageTaskList: function getCurrentStageTaskList() {
      var stage_task_list = [];
      for (var i = 0; i < this.stageTasks.length; i++) {
        if (this.stageTasks[i].stage_index === this.selectedStageIndex) {
          stage_task_list.push(this.stageTasks[i]);
        }
      }
      return stage_task_list;
    },
    rearrangeOrphanTasks: function rearrangeOrphanTasks(task_list) {
      for (var i = 0; i < task_list.length; i++) {
        this.tasks[task_list[i].task_index].cx = 800;
        this.tasks[task_list[i].task_index].cy = 110 + i * 10;
      }
      return;
    },
    deleteStage: function deleteStage() {
      var _this = this;
      // Confirmation on deletion
      this.$confirm('This will permanently delete the stage. Continue?', 'Warning', {
        confirmButtonText: 'Delete Stage',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(function () {
        // Check if orphans exists
        if (_this.stageTasksExists()) {
          // Confirmation on orphaning
          _this.$confirm('This will create orphan tasks. Continue?', 'Warning', {
            confirmButtonText: 'Make Orphan and Delete',
            cancelButtonText: 'Cancel',
            type: 'warning'
          }).then(function () {
            var task_list = _this.getCurrentStageTaskList();
            console.log(1);
            // Remove all task from current stage relation
            _this.removeStageTaskRelation(task_list);
            console.log(2);
            // Rearrage orphan tasks
            _this.rearrangeOrphanTasks(task_list);
            console.log(3);

            // Delete
            _this.stages.splice(_this.selectedStageIndex, 1);
            _this.stageData.splice(_this.selectedStageIndex, 1);
            _this.isStageSelected = false;

            // Re Render
            _this.renderPhaseUI();
          }).catch(function () {});
        } else {
          // Delete
          _this.stages.splice(_this.selectedStageIndex, 1);
          _this.stageData.splice(_this.selectedStageIndex, 1);
          _this.isStageSelected = false;

          // Re Render
          _this.renderPhaseUI();
        }
      }).catch(function () {});
    },
    matrixView: function matrixView() {
      this.$eventHub.$emit('switch-ujm-view');
    },
    computeTaskDetailStartPoint: function computeTaskDetailStartPoint() {
      return parseInt(this.stageModal.x);
      var startPointX = this.taskModal.x - this.taskModal.width / 2;
      if (startPointX <= 0) {
        return 0;
      } else {
        return startPointX;
      }
    },
    computePhaseTitle: function computePhaseTitle() {
      if (this.isPhaseModalEditMode) {
        return 'Edit Phase';
      } else {
        return 'Add Phase';
      }
    },
    closeStageDetailsModal: function closeStageDetailsModal() {
      this.isStageSelected = false;
      this.resetPhaseColor();
    },
    openStageModal: function openStageModal(edit_mode) {
      this.isPhaseModalEditMode = edit_mode;
      this.addPhaseDialog = true;
    },
    openStageEditModal: function openStageEditModal() {
      this.phase_form = this.selectedStage;
      this.openStageModal(true);
    },
    computeTaskTitle: function computeTaskTitle() {
      if (this.isTaskModalEditMode) {
        return 'Edit Task';
      } else {
        return 'Add Task';
      }
    },
    openTaskModal: function openTaskModal(edit_mode) {
      this.isTaskModalEditMode = edit_mode;
      this.addTaskDialog = true;
    }
  }, (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)(_methods, "openStageEditModal", function openStageEditModal() {
    this.task_form = this.selectedTask;
    this.openTaskModal(true);
  }), "openPainPointsModal", function openPainPointsModal(flag) {
    this.addPainPointsDialog = true;
  }), "closePainPointsModal", function closePainPointsModal() {
    this.addPainPointsDialog = false;
  }), "openPleasersModal", function openPleasersModal(flag) {
    this.addPleasersDialog = true;
  }), "closePleasersModal", function closePleasersModal() {
    this.addPleasersDialog = false;
  }), "openCandidateFeaturesModal", function openCandidateFeaturesModal(type, flag) {
    this.isSourcePleaser = false;
    this.isSourcePainPoint = false;
    if (flag) {
      if (type === 'pain_point') {
        this.isSourcePainPoint = true;
      }
      if (type === 'pleaser') {
        this.isSourcePleaser = true;
      }
    }
    this.addCandidateFeaturesDialog = true;
  }), "closeCandidateFeaturesModal", function closeCandidateFeaturesModal() {
    this.addCandidateFeaturesDialog = false;
  }), "getPriorityIcon", function getPriorityIcon(priority) {
    switch (priority) {
      case 'Low':
        return 'priority-1';
      case 'Medium':
        return 'priority-3';
      case 'High':
        return 'priority-5';
    }
  }), "getValueIcon", function getValueIcon(value) {
    return 'dollar-' + value;
  }), "userTypeSelectionChange", function userTypeSelectionChange(user_type_name) {
    for (var i = 0; i < this.user_types.length; i++) {
      if (this.user_types[i].name === user_type_name) {
        this.userType = this.user_types[i];
        this.user_type = this.user_types[i];
      }
    }
  }), (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)(_methods, "addPainPoint", function addPainPoint() {
    var pain_point = Object.assign({}, this.pains_form);
    this.user_type.pain_points.push(pain_point);
    this.taskData[this.activeTaskIndex].pain_points.push(pain_point);
    this.resetPainPointForm();
    this.closePainPointsModal();
  }), "addPleaser", function addPleaser() {
    var pleaser = Object.assign({}, this.gains_form);
    this.user_type.pleasers.push(pleaser);
    this.taskData[this.activeTaskIndex].pleasers.push(pleaser);
    this.resetPleaserForm();
    this.closePleasersModal();
  }), "addCandidateFeature", function addCandidateFeature() {
    var candidate_feature = Object.assign({}, this.candidate_features_form);
    candidate_feature.key = this.user_type.candidate_features.length;
    this.user_type.candidate_features.push(candidate_feature);
    if (this.isSourcePainPoint) {
      this.taskData[this.activeTaskIndex].pain_points[this.activePleaserPainPointIndex].relations.push(candidate_feature);
    }
    if (this.isSourcePleaser) {
      this.taskData[this.activeTaskIndex].pleasers[this.activePleaserPainPointIndex].relations.push(candidate_feature);
    }
    this.closeCandidateFeaturesModal();
  }), "computeStepZoomButtonCircleCoordinates", function computeStepZoomButtonCircleCoordinates() {
    return {
      cx: this.taskModal.x + this.taskModal.width / 2 - 50,
      cy: this.taskModal.y + 40,
      r: 10,
      fill: "black"
    };
  }), "transformStepZoomOut", function transformStepZoomOut() {
    var x = this.taskModal.x + this.taskModal.width / 2 - 56;
    var y = this.taskModal.y + 34;
    return "translate(" + x + " " + y + ") scale(.5,.5)";
  }), "transformStepClose", function transformStepClose() {
    var x = this.taskModal.x + this.taskModal.width / 2 - 30;
    var y = this.taskModal.y + 30;
    return "translate(" + x + " " + y + ") scale(.4,.4)";
  }), "transformPhaseClose", function transformPhaseClose() {
    var x = parseInt(this.stageModal.x) + parseInt(this.stageModal.width) - 40;
    var y = parseInt(this.stageModal.y) + 30;
    return "translate(" + x + " " + y + ") scale(.5,.5)";
  }), "resetPhaseColor", function resetPhaseColor() {
    this.stages.forEach(function (phase) {
      phase.stroke = "lightblue";
      phase.fill = "aliceblue";
    });
  }), "resetTaskColor", function resetTaskColor() {
    this.tasks.forEach(function (step) {
      step.stroke = "green";
      step.fill = "#FFFACC";
    });
  }), "resetPainPointForm", function resetPainPointForm() {
    this.pains_form = {
      pain_point: '',
      description: '',
      value: '',
      priority: 'Medium',
      relations: []
    };
  }), (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)(_methods, "resetPleaserForm", function resetPleaserForm() {
    this.gains_form = {
      pleaser: '',
      description: '',
      value: '',
      priority: 'Medium',
      relations: []
    };
  }), "resetTaskForm", function resetTaskForm() {
    this.task_form = {
      task: '',
      intention: '',
      pain_points: [],
      pleasers: []
    };
  }), "selectPhase", function selectPhase(index, event) {
    this.resetPhaseColor();
    this.resetTaskColor();
    this.stages[index].stroke = "dodgerblue";
    this.stages[index].fill = "dodgerblue";
    var points = this.stages[index].points.split(',');
    var first_point = points[0].split(' ');
    this.stageModal.x = parseInt(first_point[0]);
    this.stageModal.y = parseInt(first_point[1]);
    this.taskDetailsVisible = false;
    // Toggle stage details only on click event.
    // Toggling stage details on mouse over doesnt have good UX.
    // User can activate stage details with mouseover, closing has to be done manually
    if (event === 'click') {
      this.isStageSelected = !this.isStageSelected;
    } else {
      this.isStageSelected = true;
    }
    if (this.isStageSelected) {
      this.selectedStage = this.stageData[index];
      this.selectedStageIndex = index;
    } else {
      this.selectedStage = {};
      this.selectedStageIndex = null;
    }
  }), "selectTask", function selectTask(index, event) {
    this.activeTaskIndex = index;
    this.resetPhaseColor();
    this.resetTaskColor();
    this.tasks[index].stroke = "green";
    this.tasks[index].fill = "green";
    var res = this.isStepOverPhase(this.tasks[index]);
    // Display step details only if attached to a phase,
    // Else display error
    // When the event is click
    if (res[0] && event === 'click') {
      this.taskDetailsVisible = !this.taskDetailsVisible;
      if (this.taskDetailsVisible) {
        this.taskModal.x = this.tasks[index].cx;
        this.taskModal.y = this.tasks[index].cy;
        this.isStageSelected = false;
      }
    } else if (res[0] && event === 'mouseover') {
      // Might need some tweaking
      this.isStageSelected = false;
      this.taskModal.x = this.tasks[index].cx;
      this.taskModal.y = this.tasks[index].cy;
      this.taskDetailsVisible = true;
    } else {
      this.taskDetailsVisible = false;
      this.$notify({
        title: 'Oops!',
        message: 'Add the step to a phase to edit it.',
        type: 'Error',
        duration: 2000
      });
    }
  }), "generatePhaseID", function generatePhaseID(index) {
    return "phase_" + index;
  }), "generateStageLinkID", function generateStageLinkID(index) {
    return "phase_link_" + index;
  }), "generateStageTextID", function generateStageTextID(index) {
    return "phase_text_" + index;
  }), "generateTaskID", function generateTaskID(index) {
    return "step_" + index;
  }), "generateTaskTextID", function generateTaskTextID(index) {
    return "step_text_" + index;
  }), "generatePhaseTextPoints", function generatePhaseTextPoints(phase_points) {
    var first_point = phase_points.split(',')[0];
    return first_point.split(' ');
  }), (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)(_methods, "storeTaskDetails", function storeTaskDetails() {
    var task_form = Object.assign({}, this.task_form);
    this.taskData.push(task_form);
    this.resetTaskForm();
  }), "addPhase", function addPhase() {
    var phase_form = Object.assign({}, this.phase_form);
    this.stageData.push(phase_form);
    this.renderPhaseUI();
    this.resetPhaseForm();
    this.addPhaseDialog = false;
  }), "updatePhase", function updatePhase() {
    this.resetPhaseForm();
    this.addPhaseDialog = false;
  }), "addTask", function addTask() {
    var task_form = Object.assign({}, this.task_form);
    this.tasks.push({
      cx: this.activeTaskPos.x,
      cy: this.activeTaskPos.y,
      r: 15,
      fill: "green",
      stroke: "#FFFACC",
      stroke_width: 1
    });
    var index = this.tasks.length - 1;
    var res = this.isStepOverPhase(this.tasks[index]);
    if (res[0]) {
      this.tasks[index].stroke = 'green';
      this.tasks[index].fill = '#FFFACC';
      this.tasks[index].cy = parseInt(res[1][1]) + this.phaseHeight / 2;
      this.stageTasks.push({
        'task_index': index,
        stage_index: res[2]
      });
    } else {
      this.tasks[index].stroke = 'green';
      this.tasks[index].fill = '#FFFACC';
    }
    this.storeTaskDetails();
    this.addTaskDialog = false;
  }), "updateTask", function updateTask() {
    this.resetTaskForm();
    this.addTaskDialog = false;
  }), "renderPhaseUI", function renderPhaseUI() {
    this.stages = [];
    // Initial Start points
    var pointX = 100;
    var pointY = 100;
    for (var i = 0; i < this.stageData.length; i++) {
      var updatedPhaseY = parseInt(pointY) + i * this.phaseDelta;
      if (i % 2 == 0) {
        var pointA = parseInt(pointX) + ' ' + updatedPhaseY;
        var pointB = parseInt(pointX) + this.phaseWidth + ' ' + updatedPhaseY;
        var pointCY = updatedPhaseY + parseInt(this.phaseHeight / 2);
        var pointC = parseInt(pointX) + this.phaseWidth + parseInt(this.phaseHeight / 2) + ' ' + pointCY;
        var pointDY = updatedPhaseY + this.phaseHeight;
        var pointD = parseInt(pointX) + this.phaseWidth + ' ' + pointDY;
        var pointEY = updatedPhaseY + this.phaseHeight;
        var pointE = parseInt(pointX) + ' ' + pointEY;
        var path = pointA + ',' + pointB + ',' + pointC + ',' + pointD + ',' + pointE;
        this.stages.push({
          points: path,
          stroke: "lightblue",
          fill: "aliceblue"
        });
      } else {
        var pointA = parseInt(pointX) + ' ' + updatedPhaseY;
        var pointB = parseInt(pointX) + this.phaseWidth + ' ' + updatedPhaseY;
        var pointCY = updatedPhaseY + this.phaseHeight;
        var pointC = parseInt(pointX) + this.phaseWidth + ' ' + pointCY;
        var pointDY = updatedPhaseY + this.phaseHeight;
        var pointD = parseInt(pointX) + ' ' + pointDY;
        var pointEY = updatedPhaseY + parseInt(this.phaseHeight / 2);
        var pointE = pointX - parseInt(this.phaseHeight / 2) + ' ' + pointEY;
        var path = pointA + ',' + pointB + ',' + pointC + ',' + pointD + ',' + pointE;
        this.stages.push({
          points: path,
          stroke: "lightblue",
          fill: "aliceblue"
        });
      }
      pointX = this.stages[this.stages.length - 1].points.split(',')[0].split(' ')[0];
      pointY = this.stages[this.stages.length - 1].points.split(',')[0].split(' ')[1];
    }
    this.updatePhaseLinkPaths();
  }), "resetPhaseForm", function resetPhaseForm() {
    this.phase_form = {
      name: '',
      description: '',
      goal: '',
      challenges: '',
      emotions: '',
      priority: 'Low'
    };
  }), "updatePhaseLinkPaths", function updatePhaseLinkPaths() {
    this.stagePaths = [];
    for (var i = 0; i < this.stages.length - 1; i++) {
      var startPointsArr = this.stages[i].points.split(',');
      var endPointsArr = this.stages[i + 1].points.split(',');
      if (i % 2 === 0) {
        //path: "M131,78 C403,72 394,203 122,206"
        var startPointArr = startPointsArr[2].split(' ');
        var endPointArr = endPointsArr[2].split(' ');
        var curvePoint1X = parseInt(startPointArr[0]) + this.pathLength;
        var curvePoint2X = parseInt(endPointArr[0]) + this.pathLength;
        var endPoint2Y = endPointArr[1] - parseInt(this.phaseHeight / 2);
        var path = "M" + startPointArr[0] + ',' + startPointArr[1] + ' C' + curvePoint1X + ',' + startPointArr[1] + ' ' + curvePoint2X + ',' + endPointArr[1] + ' ' + endPointArr[0] + ',' + endPoint2Y;
        this.stagePaths.push({
          path: path,
          stroke: "gray",
          fill: "white"
        });
      } else {
        var startPointArr = startPointsArr[4].split(' ');
        var endPointArr = endPointsArr[4].split(' ');
        var curvePoint1X = parseInt(startPointArr[0]) - this.pathLength;
        var curvePoint2X = parseInt(endPointArr[0]) - this.pathLength;
        var endPoint2Y = endPointArr[1] - parseInt(this.phaseHeight / 2);
        var path = "M" + startPointArr[0] + ',' + startPointArr[1] + ' C' + curvePoint1X + ',' + startPointArr[1] + ' ' + curvePoint2X + ',' + endPointArr[1] + ' ' + endPointArr[0] + ',' + endPoint2Y;
        this.stagePaths.push({
          path: path,
          stroke: "gray",
          fill: "white"
        });
      }
    }
  }), "buildPhasePoints", function buildPhasePoints(startPointX, startPointY, index) {
    startPointX = parseInt(startPointX);
    startPointY = parseInt(startPointY);
    this.phaseWidth = parseInt(this.phaseWidth);
    this.phaseHeight = parseInt(this.phaseHeight);
    var halfHeight = this.phaseHeight / 2;
    if (index % 2 === 0) {
      var pointA = startPointX + ' ' + startPointY;
      var pointB = startPointX + this.phaseWidth + ' ' + startPointY;
      var pointC = startPointX + this.phaseWidth + halfHeight + ' ' + parseInt(startPointY + halfHeight);
      var pointD = startPointX + this.phaseWidth + ' ' + parseInt(startPointY + this.phaseHeight);
      var pointE = startPointX + ' ' + parseInt(startPointY + this.phaseHeight);
      return pointA + ',' + pointB + ',' + pointC + ',' + pointD + ',' + pointE;
    } else {
      var pointA = startPointX + ' ' + startPointY;
      var pointB = startPointX + this.phaseWidth + ' ' + startPointY;
      var pointC = startPointX + this.phaseWidth + ' ' + parseInt(startPointY + this.phaseHeight);
      var pointD = startPointX + ' ' + parseInt(startPointY + this.phaseHeight);
      var pointE = startPointX - parseInt(halfHeight) + ' ' + parseInt(startPointY + halfHeight);
      return pointA + ',' + pointB + ',' + pointC + ',' + pointD + ',' + pointE;
    }
  }), "startPhaseMove", function startPhaseMove(_ref) {
    var el = _ref.el,
      deltaX = _ref.deltaX,
      deltaY = _ref.deltaY,
      offsetX = _ref.offsetX,
      offsetY = _ref.offsetY,
      clientX = _ref.clientX,
      clientY = _ref.clientY,
      first = _ref.first,
      last = _ref.last;
    if (deltaX === undefined || deltaY === undefined) return;
    var index = el.id.replace('phase_', '');
    var newPt = {
      x: '',
      y: ''
    };
    var initial_point = this.stages[index].points.split(',')[0];
    var initial_point_x = initial_point.split(' ')[0];
    var initial_point_y = initial_point.split(' ')[1];
    var newPt = {
      x: '',
      y: ''
    };
    newPt.x = parseInt(initial_point_x) + parseInt(deltaX);
    newPt.y = parseInt(initial_point_y) + parseInt(deltaY);
    this.stages[index].points = this.buildPhasePoints(newPt.x, newPt.y, index);
    if (this.stages.length > 1) {
      this.updatePhaseLinkPaths();
    }
  }), (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)(_methods, "startTaskMove", function startTaskMove(_ref2) {
    var el = _ref2.el,
      deltaX = _ref2.deltaX,
      deltaY = _ref2.deltaY,
      offsetX = _ref2.offsetX,
      offsetY = _ref2.offsetY,
      clientX = _ref2.clientX,
      clientY = _ref2.clientY,
      first = _ref2.first,
      last = _ref2.last;
    if (deltaX === undefined || deltaY === undefined) return;
    var index = el.id.replace('step_', '');
    this.tasks[index].cx += parseInt(deltaX);
    this.tasks[index].cy += parseInt(deltaY);
    var res = this.isStepOverPhase(this.tasks[index]);
    if (res[0]) {
      this.tasks[index].stroke = 'green';
      this.tasks[index].fill = '#FFFACC';
      this.tasks[index].cy = parseInt(res[1][1]) + this.phaseHeight / 2;
    } else {
      this.tasks[index].stroke = 'green';
      this.tasks[index].fill = '#FFFACC';
    }
    this.closeAllStepDetails();
  }), "closeAllStepDetails", function closeAllStepDetails() {
    this.taskDetailsVisible = false;
  }), "isStepOverPhase", function isStepOverPhase(step) {
    for (var i = 0; i < this.stages.length; i++) {
      var path_arr = this.stages[i].points.split(",");
      var first_point = path_arr[0].split(' ');
      var second_point = path_arr[1].split(' ');
      // bottom-left and top-right
      // corners of rectangle
      if (isPointInsideRect(parseInt(first_point[0]), parseInt(first_point[1]) + this.phaseHeight, parseInt(second_point[0]), parseInt(second_point[1]), parseInt(step.cx), parseInt(step.cy), 15)) return [true, first_point, i];
    }
    return [false];
  }), "handleDrop", function handleDrop(event) {
    event.preventDefault(); // !important
    if (this.activeDragElement === 'step') {
      this.activeTaskPos = {
        x: event.offsetX,
        y: event.offsetY
      };
      this.openTaskModal(false);
    } else if (this.activeDragElement === 'phase') {
      this.openStageModal(false);
      //this.addStage()
    }
  }), "handleDragOver", function handleDragOver(event) {
    event.preventDefault(); // !important
  }), "setActiveDragElement", function setActiveDragElement(element) {
    this.activeArtefactDrag.element = null;
    this.activeArtefactDrag.index = null;
    this.activeDragElement = element;
  }), "setActiveArtefactDragElement", function setActiveArtefactDragElement(element, index) {
    this.activeDragElement = null;
    this.activeArtefactDrag.element = element;
    this.activeArtefactDrag.index = index;
  }), "handleArtefactDrop", function handleArtefactDrop(event) {
    event.preventDefault(); // !important
    if (this.activeArtefactDrag.element === 'pain_point') {
      var pain_point = Object.assign({}, this.user_type.pain_points[this.activeArtefactDrag.index]);
      this.taskData[this.activeTaskIndex].pain_points.push(pain_point);
    } else if (this.activeArtefactDrag.element === 'pleaser') {
      var pleaser = Object.assign({}, this.user_type.pleasers[this.activeArtefactDrag.index]);
      this.taskData[this.activeTaskIndex].pleasers.push(pleaser);
    } else if (this.activeArtefactDrag.element === 'candidate_feature') {
      var candidate_feature = Object.assign({}, this.user_type.candidate_features[this.activeArtefactDrag.index]);
      this.taskData[this.activeTaskIndex].candidate_features.push(candidate_feature);
    }
  }), "handleArtefactDragOver", function handleArtefactDragOver(event) {
    event.preventDefault(); // !important
  }), "removePainPoint", function removePainPoint(index) {
    this.taskData[this.activeTaskIndex].pain_points.splice(index, 1);
    // this.task_form.pain_points.splice(index, 1)
  }), (0, _defineProperty2.default)((0, _defineProperty2.default)((0, _defineProperty2.default)(_methods, "removePleaser", function removePleaser(index) {
    this.taskData[this.activeTaskIndex].pleasers.splice(index, 1);
    // this.task_form.pleasers.splice(index, 1)
  }), "removeCandidateFeature", function removeCandidateFeature(index) {
    if (this.isSourcePleaser) {
      this.taskData[this.activeTaskIndex].pleasers[this.activePleaserPainPointIndex].relations.splice(index, 1);
    }
    if (this.isSourcePainPoint) {
      this.taskData[this.activeTaskIndex].pain_points[this.activePleaserPainPointIndex].relations.splice(index, 1);
    }
  }), "openTaskCandidateFeaturesDialog", function openTaskCandidateFeaturesDialog(type, index) {
    this.isSourcePleaser = false;
    this.isSourcePainPoint = false;
    this.activePleaserPainPointIndex = 0;
    if (type == 'pleaser') {
      this.isSourcePleaser = true;
    }
    if (type == 'pain_point') {
      this.isSourcePainPoint = true;
    }
    this.activePleaserPainPointIndex = index;
    this.addCandidateFeaturesDialog = true;
  }))
};
function isPointInsideRect(x1, y1, x2, y2, x, y, r) {
  if (x + r > x1 && x - r < x2 && y - r < y1 && y + r > y2) {
    return true;
  }
  return false;
}