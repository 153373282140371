"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default2 = exports.default = {
  name: 'Setting',
  components: {},
  props: {
    offering: {
      type: Object,
      required: true,
      default: function _default() {
        return {
          id: '',
          title: '',
          description: '',
          offeringcategory: '',
          offeringcategorydesc: '',
          additionalattributes: {},
          linkedProducts: [],
          linkedServices: []
        };
      }
    }
  },
  data: function data() {
    return {
      loading: false,
      activeTabName: 'offering',
      deleteConfirmationDialog: false,
      offeringVersionsDialog: false,
      offeringVersion: [{
        title: 'Offering Name',
        version: '4'
      }, {
        title: 'Offering Name1',
        version: '3'
      }, {
        title: 'Offering Name2',
        version: '2'
      }, {
        title: 'Offering Name3',
        version: '1'
      }],
      offeringSettings: [{
        title: 'Delete Offering',
        description: 'Delete the current Offering',
        type: 'delete',
        note: {
          type: 'info"',
          message: 'All related entities will be deleted. Products and Services will be deleted. Journeymap, Phase, Steps and Experiences are preserved '
        }
      }, {
        title: 'Prior Version',
        description: 'Display list of prior versions',
        type: 'version',
        note: {}
      }],
      deleteConfirmationForm: {
        title: ''
      }
    };
  },
  computed: {},
  watch: {},
  created: function created() {},
  mounted: function mounted() {
    this.resetUI();
  },
  methods: {
    renameOfferingDetails: function renameOfferingDetails() {
      var offering = Object.assign({}, this.offering);
      this.$emit('rename-offering', offering);
    },
    openJourneyVersionsDialog: function openJourneyVersionsDialog() {
      this.offeringVersionsDialog = true;
    },
    deleteOfferingConfirmation: function deleteOfferingConfirmation() {
      this.deleteConfirmationForm.title = '';
      this.deleteConfirmationDialog = true;
    },
    deleteOffering: function deleteOffering() {
      this.$emit('delete-offering', this.offering.id);
      this.deleteConfirmationDialog = false;
      this.closeUI();
    },
    closeUI: function closeUI() {
      this.$emit('close');
    },
    resetUI: function resetUI() {
      this.deleteConfirmationDialog = false;
      this.offeringVersionsDialog = false;
      this.deleteConfirmationForm.title = '';
    }
  }
};