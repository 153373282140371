"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/taggedTemplateLiteral.js"));
var _ListItems = _interopRequireDefault(require("@/components/ListItems"));
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7;
var _default = exports.default = {
  name: 'Risks',
  components: {
    ItemList: _ListItems.default
  },
  data: function data() {
    var validateTitle = function validateTitle(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter a valid risk'));
      } else {
        callback();
      }
    };
    var validateDescription = function validateDescription(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter a valid description'));
      } else {
        callback();
      }
    };
    var validateSeverity = function validateSeverity(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter a valid severity'));
      } else {
        callback();
      }
    };
    var validateOccuranceProbability = function validateOccuranceProbability(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter a valid occuranceprobability'));
      } else {
        callback();
      }
    };
    var validateImpactLevel = function validateImpactLevel(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter a valid impactlevel'));
      } else {
        callback();
      }
    };
    var validateMitigationPlan = function validateMitigationPlan(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter a valid mitigation plan'));
      } else {
        callback();
      }
    };
    var validateAssociationPlan = function validateAssociationPlan(rule, value, callback) {
      if (!value) {
        callback(new Error('Please enter a valid association'));
      } else {
        callback();
      }
    };
    return {
      riskRules: {
        title: [{
          required: true,
          trigger: 'blur',
          validator: validateTitle
        }],
        description: [{
          required: true,
          trigger: 'blur',
          validator: validateDescription
        }],
        severity: [{
          required: true,
          trigger: 'blur',
          validator: validateSeverity
        }],
        occuranceprobability: [{
          required: true,
          trigger: 'blur',
          validator: validateOccuranceProbability
        }],
        impactlevel: [{
          required: true,
          trigger: 'blur',
          validator: validateImpactLevel
        }],
        mitigationplan: [{
          required: true,
          trigger: 'blur',
          validator: validateMitigationPlan
        }],
        association: [{
          required: true,
          trigger: 'blur',
          validator: validateAssociationPlan
        }]
      },
      loading: false,
      addRiskDialog: false,
      isEditMode: false,
      search_risks: '',
      selectedRiskIndex: null,
      total_count: 0,
      selectedRisk: {},
      risk_form: {
        title: '',
        description: '',
        severity: 'Low',
        occuranceprobability: 'Low',
        impactlevel: 'Low',
        mitigationplan: '',
        association: 'Business',
        linkEntityName: '',
        linkEntityId: ''
      },
      risks: [],
      offerings: [],
      initiatives: [],
      features: []
    };
  },
  computed: {
    filteredRisks: function filteredRisks() {
      var self = this;
      return this.risks.filter(function (risk) {
        return risk.title.toLowerCase().indexOf(self.search_risks.toLowerCase()) > -1;
      });
    }
  },
  created: function created() {},
  mounted: function mounted() {
    this.retrieveRisk(1);
    this.retrieveOfferings(1);
    this.retrieveInitiatives(1);
    this.retrieveProductFeature(1);
  },
  methods: {
    retrieveInitiatives: function retrieveInitiatives(page_number) {
      var _this = this;
      this.loading = true;
      this.$apollo.query({
        // Query
        query: window._gql(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)([" query Initiatives($page: Page)\n        {\n        Initiatives(page: $page)\n        {\n          pages\n          total\n          select{\n            id\n            title\n          }\n        }\n      }"]))),
        fetchPolicy: 'no-cache',
        variables: {
          'page': {
            'limit': 100,
            'start': page_number
          }
        }
      }).then(function (data) {
        _this.initiatives = data.data.Initiatives.select;
        _this.loading = false;
      }).catch(function (error) {
        // Error
        _this.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this.loading = false;
      });
    },
    retrieveProductFeatures: function retrieveProductFeatures(page_number) {
      var _this2 = this;
      this.loading = true;
      this.$apollo.query({
        // Query
        query: window._gql(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)([" query ProductFeatures($page: Page)\n        {\n        ProductFeatures(page: $page)\n        {\n          pages\n          total\n          select{\n            id\n            title\n          }\n        }\n      }"]))),
        fetchPolicy: 'no-cache',
        variables: {
          'page': {
            'limit': 100,
            'start': page_number
          }
        }
      }).then(function (data) {
        _this2.features = data.data.ProductFeatures.select;
        _this2.loading = false;
      }).catch(function (error) {
        // Error
        _this2.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this2.loading = false;
      });
    },
    retrieveOfferings: function retrieveOfferings(page_number) {
      var _this3 = this;
      this.loading = true;
      this.$apollo.query({
        // Query
        query: window._gql(_templateObject3 || (_templateObject3 = (0, _taggedTemplateLiteral2.default)([" query Offerings($page: Page)\n        {\n        Offerings(page: $page)\n        {\n          pages\n          total\n          select{\n            id\n            title\n          }\n        }\n      }"]))),
        fetchPolicy: 'no-cache',
        variables: {
          'page': {
            'limit': 100,
            'start': page_number
          }
        }
      }).then(function (data) {
        _this3.offerings = data.data.Offerings.select;
        _this3.loading = false;
      }).catch(function (error) {
        // Error
        _this3.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this3.loading = false;
      });
    },
    onRiskAssociationChange: function onRiskAssociationChange(val) {
      switch (val) {
        case 'ProductFeature':
          break;
        case 'Offering':
          break;
        case 'Initiative':
          break;
        case 'Business':
          break;
      }
    },
    loadPage: function loadPage(page) {
      this.retrieveRisk(page);
    },
    truncateDescription: function truncateDescription(description, length) {
      return this._.truncate(description, {
        'length': length,
        'separator': ' '
      });
    },
    retrieveRisk: function retrieveRisk(page_number) {
      var _this4 = this;
      this.loading = true;
      this.$apollo.query({
        // Query
        query: window._gql(_templateObject4 || (_templateObject4 = (0, _taggedTemplateLiteral2.default)([" query Risks($page: Page)\n        {\n        Risks(page: $page)\n        {\n          pages\n          total\n          select{\n            id\n            title\n            description\n            severity\n            occuranceprobability\n            mitigationplan\n            impactlevel\n          }\n        }\n      }"]))),
        fetchPolicy: 'no-cache',
        variables: {
          'page': {
            'limit': 5,
            'start': page_number
          }
        }
      }).then(function (data) {
        _this4.risks = data.data.Risks.select;
        _this4.total_count = data.data.Risks.total;
        _this4.loading = false;
      }).catch(function (error) {
        // Error
        _this4.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this4.loading = false;
      });
    },
    deleteRisk: function deleteRisk() {
      var _this5 = this;
      this.$confirm('This will permanently delete the risk. Continue?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(function () {
        _this5.loading = true;
        _this5.$apollo.mutate({
          // Query
          mutation: window._gql(_templateObject5 || (_templateObject5 = (0, _taggedTemplateLiteral2.default)(["mutation deleteRisk($busRisk: DeleteRiskInput!)\n          {\n            deleteRisk(input: $busRisk) {\n              id,\n            }\n          }"]))),
          // Parameters
          variables: {
            'busRisk': {
              'id': _this5.risks[_this5.selectedRiskIndex].id
            }
          }
        }).then(function (data) {
          _this5.$notify({
            title: 'Success',
            message: 'Deleted Successfully',
            type: 'success',
            duration: 2000
          });
          _this5.risks.splice(_this5.selectedRiskIndex, 1);
          _this5.selectedRisk = {};
          _this5.selectedRiskIndex = null;
          _this5.loading = false;
        }).catch(function (error) {
          // Error
          _this5.$notify({
            title: 'Error',
            message: error,
            type: 'error',
            duration: 2000
          });
          _this5.loading = false;
        });
      }).catch(function () {
        _this5.$message({
          type: 'info',
          message: 'Delete canceled'
        });
      });
    },
    editRisk: function editRisk(index, row) {
      this.risk_form = row;
      this.selectedRiskIndex = index;
      this.isEditMode = true;
    },
    trimString: function trimString(str) {
      if (str) {
        return str.slice(0, 180);
      }
      return str;
    },
    openRiskDialog: function openRiskDialog() {
      this.resetRiskForm();
      this.addRiskDialog = true;
      this.isEditMode = false;
      // this.$refs.risk_form.resetFields()
    },
    addRisk: function addRisk() {
      var _this6 = this;
      this.$refs.risk_form.validate(function (valid) {
        if (valid) {
          _this6.loading = true;
          _this6.$apollo.mutate({
            // Query
            mutation: window._gql(_templateObject6 || (_templateObject6 = (0, _taggedTemplateLiteral2.default)(["mutation createRisk($risk: CreateRiskInput!)\n            {\n              createRisk(input: $risk) {\n                id\n                title\n                description\n                severity\n                occuranceprobability\n                mitigationplan\n                impactlevel\n                association\n              }\n            }"]))),
            // Parameters
            variables: {
              'risk': {
                'title': _this6.risk_form.title,
                'description': _this6.risk_form.description,
                'severity': _this6.risk_form.severity,
                'occuranceprobability': _this6.risk_form.occuranceprobability,
                'impactlevel': _this6.risk_form.impactlevel,
                'mitigationplan': _this6.risk_form.mitigationplan,
                'association': _this6.risk_form.association,
                'linkEntityName': _this6.risk_form.association,
                'linkEntityId': _this6.risk_form.linkEntityId
              }
            }
          }).then(function (data) {
            _this6.$notify({
              title: 'Success',
              message: 'Created Successfully',
              type: 'success',
              duration: 2000
            });
            var risk = data.data.createRisk;
            _this6.risks.push(risk);
            _this6.selectRisk(risk, _this6.risks.length);
            _this6.loading = false;
          }).catch(function (error) {
            // Error
            _this6.$notify({
              title: 'Error',
              message: error,
              type: 'error',
              duration: 2000
            });
            _this6.loading = false;
          });
          _this6.addRiskDialog = false;
        } else {
          _this6.loading = false;
          return false;
        }
      });
    },
    updateRisk: function updateRisk() {
      var _this7 = this;
      this.loading = true;
      this.$apollo.mutate({
        // Query
        mutation: window._gql(_templateObject7 || (_templateObject7 = (0, _taggedTemplateLiteral2.default)(["mutation updateRisk($busRisk: UpdateRiskInput!)\n          {\n            updateRisk(input: $busRisk) {\n              id\n              title\n              description\n              severity\n              occuranceprobability\n              mitigationplan\n              impactlevel\n              association\n            }\n          }"]))),
        // Parameters
        variables: {
          'busRisk': {
            'id': this.selectedRisk.id,
            'title': this.selectedRisk.title,
            'description': this.selectedRisk.description,
            'severity': this.selectedRisk.severity,
            'occuranceprobability': this.selectedRisk.occuranceprobability,
            'impactlevel': this.selectedRisk.impactlevel,
            'mitigationplan': this.selectedRisk.mitigationplan,
            'association': this.risk_form.association,
            'linkEntityName': this.risk_form.association,
            'linkEntityId': this.risk_form.linkEntityId
          }
        }
      }).then(function (data) {
        _this7.$notify({
          title: 'Success',
          message: 'Updated Successfully',
          type: 'success',
          duration: 2000
        });
        _this7.isEditMode = false;
        _this7.loading = false;
      }).catch(function (error) {
        // Error
        _this7.$notify({
          title: 'Error',
          message: error,
          type: 'error',
          duration: 2000
        });
        _this7.loading = false;
      });
    },
    selectRisk: function selectRisk(risk, index) {
      var _this8 = this;
      this.loading = true;
      setTimeout(function () {
        _this8.selectedRiskIndex = index;
        _this8.selectedRisk = risk;
        _this8.loading = false;
      }, 100);
    },
    resetRiskForm: function resetRiskForm() {
      this.risk_form = {
        title: '',
        description: '',
        severity: 'Low',
        occuranceprobability: 'Low',
        impactlevel: 'Low',
        mitigationplan: '',
        association: 'Business',
        linkEntityName: '',
        linkEntityId: ''
      };
    }
  }
};