"use strict";

var _interopRequireDefault = require("C:/Users/Sastha/Documents/GitHub/prodiggiapp-frontend/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _vueSwatches = _interopRequireDefault(require("vue-swatches"));
var _default2 = exports.default = {
  name: 'ReleaseDetail',
  components: {
    VSwatches: _vueSwatches.default
  },
  props: {
    release: {
      type: Object,
      default: function _default() {
        return {
          title: '',
          description: '',
          additionalattributes: {
            style: {
              color: ''
            }
          }
        };
      }
    },
    entities: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    releasePos: {
      type: Object,
      default: function _default() {
        return {
          increments: [],
          x: 0,
          width: 0
        };
      }
    },
    entitiesPos: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    index: {
      type: Number,
      required: true,
      default: 0
    },
    settings: {
      type: Object,
      required: true,
      default: function _default() {
        return {
          version: 1,
          roadmap: {},
          release: {
            timeline: 6,
            color: {
              title: 'rgba(189, 195, 200, 1.0)',
              column: 'rgba(189, 195, 200, 1.0)'
            }
          },
          increment: {
            prefix: 'increment',
            timeline: 1,
            delete: 'disabled',
            color: {
              title: 'rgba(189, 195, 200, 1.0)',
              column: 'rgba(189, 195, 200, 1.0)'
            }
          }
        };
      }
    }
  },
  data: function data() {
    return {
      loading: false
    };
  },
  computed: {},
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    unlinkSubEntity: function unlinkSubEntity(sub_entity) {
      var _this = this;
      this.$confirm('This will permanently unlink feature/process from timeline. Continue?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(function () {
        _this.$emit('unlink', sub_entity.id, sub_entity.__typename);
      }).catch(function () {
        _this.$message({
          type: 'info',
          message: 'Unlink canceled'
        });
      });
    },
    deleteIncrement: function deleteIncrement(increment_index, increment_id) {
      var _this2 = this;
      if (this.settings.increment.delete !== 'disabled') {
        this.$confirm('This will permanently delete the increment. Continue?', 'Warning', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(function () {
          _this2.$emit('delete-increment', increment_id, increment_index, _this2.index);
        }).catch(function () {
          _this2.$message({
            type: 'info',
            message: 'Delete canceled'
          });
        });
      } else {
        this.$alert('Delete restricted in roadmap settings. Update settings to delete increment', 'Delete restricted', {
          confirmButtonText: 'OK'
        });
      }
    },
    filteredRoadmapFeatures: function filteredRoadmapFeatures(entity_index) {
      var paginated_filtered_features = [];
      if (this.entities[entity_index].__typename === 'LinkProductOffering') {
        for (var i = 0; i < this.entities[entity_index].product.linkedFeatures.length; i++) {
          if (this.entities[entity_index].product.linkedFeatures[i].productFeature.startdate !== undefined && this.entities[entity_index].product.linkedFeatures[i].productFeature.enddate !== undefined && this.entities[entity_index].product.linkedFeatures[i].productFeature.enddate !== null && this.entities[entity_index].product.linkedFeatures[i].productFeature.startdate !== null && this.entities[entity_index].product.linkedFeatures[i].productFeature.enddate !== '' && this.entities[entity_index].product.linkedFeatures[i].productFeature.startdate !== '') {
            paginated_filtered_features.push(this.entities[entity_index].product.linkedFeatures[i]);
          }
        }
      }
      return paginated_filtered_features;
    },
    filteredServiceApplications: function filteredServiceApplications(entity_index) {
      var paginated_filtered_service_applications = [];
      if (this.entities[entity_index].__typename === 'LinkServiceOffering') {
        for (var i = 0; i < this.entities[entity_index].service.linkedServiceApplications.length; i++) {
          if (this.entities[entity_index].service.linkedServiceApplications[i].serviceApplication.startdate !== undefined && this.entities[entity_index].service.linkedServiceApplications[i].serviceApplication.enddate !== undefined && this.entities[entity_index].service.linkedServiceApplications[i].serviceApplication.startdate !== null && this.entities[entity_index].service.linkedServiceApplications[i].serviceApplication.enddate !== null && this.entities[entity_index].service.linkedServiceApplications[i].serviceApplication.startdate !== '' && this.entities[entity_index].service.linkedServiceApplications[i].serviceApplication.enddate !== '') {
            paginated_filtered_service_applications.push(this.entities[entity_index].service.linkedServiceApplications[i]);
          }
        }
      }
      return paginated_filtered_service_applications;
    },
    computeSubEntitiesForIncrements: function computeSubEntitiesForIncrements(increment_index) {
      var increment_x = this.releasePos.increments[increment_index].x;
      var sub_entities = [];
      for (var i = 0; i < this.entities.length; i++) {
        if (this.entities[i].__typename === 'LinkProductOffering') {
          var filteredRoadmapFeatures = this.filteredRoadmapFeatures(i);
          for (var j = 0; j < filteredRoadmapFeatures.length; j++) {
            // Get roadmap features
            if (filteredRoadmapFeatures[j].productFeature.startdate !== undefined && filteredRoadmapFeatures[j].productFeature.enddate !== undefined && filteredRoadmapFeatures[j].productFeature.enddate !== null && filteredRoadmapFeatures[j].productFeature.startdate !== null && filteredRoadmapFeatures[j].productFeature.enddate !== '' && filteredRoadmapFeatures[j].productFeature.startdate !== '' && this.entitiesPos[i].roadmap_sub_entities.length > 0 && this.entitiesPos[i].roadmap_sub_entities[j].x === increment_x) {
              sub_entities.push(filteredRoadmapFeatures[j].productFeature);
            }
          }
        } else if (this.entities[i].__typename === 'LinkServiceOffering') {
          var filteredServiceApplications = this.filteredServiceApplications(i);
          for (var k = 0; k < filteredServiceApplications.length; k++) {
            if (filteredServiceApplications[k].serviceApplication.startdate !== undefined && filteredServiceApplications[k].serviceApplication.enddate !== undefined && filteredServiceApplications[k].serviceApplication.startdate !== null && filteredServiceApplications[k].serviceApplication.enddate !== null && filteredServiceApplications[k].serviceApplication.startdate !== '' && filteredServiceApplications[k].serviceApplication.enddate !== '' && this.entitiesPos[i].roadmap_sub_entities.length > 0 && this.entitiesPos[i].roadmap_sub_entities[k].x === increment_x) {
              sub_entities.push(filteredServiceApplications[j].serviceApplication);
            }
          }
        }
      }
      return sub_entities;
    },
    formatDate: function formatDate(date) {
      return this.$moment(date).format('ll');
    },
    closeDialog: function closeDialog() {
      this.$emit('close');
    },
    update: function update() {
      this.loading = true;
      this.$emit('update', this.release);
      this.loading = false;
    },
    deleteRelease: function deleteRelease() {
      var _this3 = this;
      this.$confirm('This will permanently delete the release. Continue?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(function () {
        _this3.$emit('delete', _this3.release.id, _this3.index);
      }).catch(function () {
        _this3.$message({
          type: 'info',
          message: 'Delete canceled'
        });
      });
    },
    truncateString: function truncateString(string, length) {
      return this._.truncate(string, {
        'length': length,
        'separator': '...'
      });
    }
  }
};